import styled from "styled-components";
import { typography } from "../../styles/typography";
import { colors } from "../../styles/colors";

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${colors.focusBackground};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6rem;

  > a {
    ${typography.regular}
  }
`;

export const ErrorTextHeader = styled.p`
  ${typography.h2}
  margin: 0 0 1.2rem 0;
  align-self: center;
`;
export const ErrorText = styled.p`
  ${typography.regular}
  margin:0;
`;

export const WEBGL_ERROR_MESSAGES = [
  "Failed to initialize WebGL.",
  "Error creating WebGL context.",
];

export const ErrorTextWrapper = styled.div`
  font-size: 2rem;
  text-align: center;
  width: 80%;
`;

export const BulletPointWrapper = styled.div`
  text-align: left;
  display: flex;
  justify-content: center;
`;
