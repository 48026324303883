/// <reference types="vite-plugin-svgr/client" />
import React, { useCallback, useState } from "react";
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import styled from "styled-components";
import Spinner from "@icons/spinner/Spinner";
import ArrowLeft from "@icons/24/ArrowLeft.svg?react";
import CloseIcon from "@icons/24/Close.svg?react";
import { postNewPrivateDataSource } from "../../services/gisDataAPIService";
import { addNewSourceTemporaryGisDataLayersFullMetadataSelector } from "../../state/layer";
import Button from "../General/Button";
import { ModalHeader } from "../RightSide/InfoModal/InfoModal.style";
import { CustomDataEntry, Layer } from "../../types/layers";
import { useTypedPath } from "../../state/pathParams";
import {
  ErrorBoundarySilent,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "../ErrorBoundaries/ErrorBoundaryLocal";
import { scream } from "../../utils/sentry";
import { privateGISSourceDataAPISelector } from "../../state/privateGISSource";
import { Mixpanel } from "../../mixpanel";
import { IconREMSize } from "../../styles/typography";
import { activeFiltersAtom } from "../DataLayersModal/layer-filter-state";
import { LayerIconMapper } from "./utils";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.3rem;
  outline: none;
  justify-content: center;
  gap: 1rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

const SourceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const LayersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 20rem;
  overflow-y: auto;
  gap: 0.5rem;
`;

const HeaderRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconWithName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  svg {
    width: 1.6rem;
    height: 3rem;
  }
`;
const RowWrapper = styled.div`
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  box-sizing: border-box;
  align-items: center;
  svg {
    fill-opacity: 0.7;
  }
  &:hover {
    svg {
      fill-opacity: 1;
    }
    div {
      color: #333;
    }
  }
`;
const RowName = styled.div`
  overflow-wrap: anywhere;
`;

const LayerRow = ({ layer }: { layer: Layer }) => {
  return (
    <RowWrapper>
      <IconWithName>
        <LayerIconMapper layer={layer} />
        <RowName>{layer.name}</RowName>
      </IconWithName>
    </RowWrapper>
  );
};

const DataSourceAttribute = ({
  name,
  value,
}: {
  name: string;
  value: string;
}) => {
  if (!value) {
    return null;
  }

  return (
    <div>
      <div style={{ fontWeight: "bold", marginRight: "0.5rem" }}>{name}:</div>
      <span style={{ overflowWrap: "anywhere" }}>{value}</span>
    </div>
  );
};

export const TestNewPrivateSourceModalInner = ErrorBoundaryWrapper(
  ({
    newCustomDataSource,
    showCloseControls = true,
    onDone,
    close,
    cancel,
  }: {
    newCustomDataSource: CustomDataEntry;
    showCloseControls?: boolean;
    onDone?(): void;
    cancel(): void;
    close(): void;
  }) => {
    const [error, setError] = useState(false);
    const { projectId } = useTypedPath("projectId");
    const resetPrivateGISSourceDataAPISelector = useRecoilRefresher_UNSTABLE(
      privateGISSourceDataAPISelector({ projectId }),
    );
    const [isLoading, setIsLoading] = useState(false);
    const gisDataLayerMetadata = useRecoilValue(
      addNewSourceTemporaryGisDataLayersFullMetadataSelector,
    );
    const resetActiveFilters = useResetRecoilState(activeFiltersAtom);
    const setActiveFilters = useSetRecoilState(activeFiltersAtom);

    const postNewSourceCallback = useCallback(async () => {
      if (!projectId) return;
      setIsLoading(true);
      Mixpanel.track("Added new source", {
        type: newCustomDataSource.type,
      });
      try {
        await postNewPrivateDataSource(
          newCustomDataSource.type,
          newCustomDataSource.name,
          newCustomDataSource.url,
          projectId,
        );
        resetActiveFilters();
        resetPrivateGISSourceDataAPISelector();
        setActiveFilters((curr) => ({
          ...curr,
          source: [newCustomDataSource.name],
        }));
        close();
        onDone?.();
      } catch (e) {
        setError(true);
        scream(JSON.stringify(e));
      }
    }, [
      newCustomDataSource,
      projectId,
      close,
      onDone,
      resetActiveFilters,
      setActiveFilters,
      resetPrivateGISSourceDataAPISelector,
    ]);

    const onBackClick = useCallback(() => {
      if (!newCustomDataSource.modalType) return;
      cancel();
    }, [newCustomDataSource, cancel]);

    if (error) {
      return (
        <Wrapper>
          Something went wrong when trying to post new layer, the Vind team has
          been notified
          <ButtonWrapper>
            <Button
              buttonType="text"
              icon={<ArrowLeft />}
              text="Back"
              onClick={onBackClick}
            />
          </ButtonWrapper>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <HeaderRowWrapper>
          <ModalHeader>Explore new data source</ModalHeader>
          {showCloseControls && (
            <IconREMSize
              height={1.5}
              width={1.5}
              style={{ cursor: "pointer" }}
              onClick={close}
            >
              <CloseIcon />
            </IconREMSize>
          )}
        </HeaderRowWrapper>
        <DataSourceAttribute name="Name" value={newCustomDataSource.name} />
        <DataSourceAttribute name="Url" value={newCustomDataSource.url} />
        <DataSourceAttribute name="Type" value={newCustomDataSource.type} />
        {Object.keys(gisDataLayerMetadata).map((source) => (
          <SourceWrapper key={source}>
            <div style={{ fontWeight: "bold", marginRight: "0.5rem" }}>
              Layers:
            </div>
            <LayersWrapper>
              {gisDataLayerMetadata[source].map((layer) => (
                <LayerRow key={layer.id} layer={layer} />
              ))}
            </LayersWrapper>
          </SourceWrapper>
        ))}
        {newCustomDataSource.urlWhereFound && (
          <DataSourceAttribute
            name="URL to where the source was found"
            value={newCustomDataSource.urlWhereFound}
          />
        )}
        {newCustomDataSource.licenseType && (
          <DataSourceAttribute
            name="License type"
            value={newCustomDataSource.licenseType}
          />
        )}
        {newCustomDataSource.attribution && (
          <DataSourceAttribute
            name="Attribution"
            value={newCustomDataSource.attribution}
          />
        )}
        <ButtonWrapper>
          <Button
            buttonType="text"
            icon={<ArrowLeft />}
            text="Back"
            onClick={onBackClick}
            disabled={isLoading}
          />
          <ButtonWrapper>
            <Button text="Cancel" buttonType="text" onClick={close} />
            <Button
              text="Save source"
              onClick={postNewSourceCallback}
              disabled={isLoading}
              icon={isLoading ? <Spinner size="1rem" /> : undefined}
            />
          </ButtonWrapper>
        </ButtonWrapper>
      </Wrapper>
    );
  },
  ErrorBoundarySilent,
  ScreamOnError,
);
