import {
  OrgFeatureAccess,
  _OrgFeatureAccess,
} from "components/OrganisationFeatureAccess/types";
import { fetchEnhancerWithToken } from "./utils";

export const fetchFeatureAccess = async (
  organisationId: string,
): Promise<OrgFeatureAccess[] | undefined> => {
  if (!organisationId) return undefined;
  const res = await fetchEnhancerWithToken(
    `/api/customer/featureaccess/organisation/${organisationId}`,
    {
      method: "get",
    },
  );
  try {
    const j = await res.json();
    return _OrgFeatureAccess.array().parse(j);
  } catch (e) {
    return;
  }
};
