import { atom, selector } from "recoil";
import { UserHintSettings, getUserHintSettings } from "./services";

export const userHintSettingsAtom = atom<UserHintSettings>({
  key: "userHintSettingsAtom",
  default: selector({
    key: "userHintSettingsAtom.default",
    get: () =>
      getUserHintSettings().catch(() => {
        return { hideAllTips: true };
      }),
  }),
});
