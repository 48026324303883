import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { getCablesSelectorFamily } from "../../../state/cable";
import { allCableTypesSelector } from "../../Cabling/Generate/state";
import { get3DCableLengthsInParkWithContingency } from "../../Cabling/Generate/utils";
import { CenterContainer, LoadingState, SafeCard } from "./Base";
import { useMemo } from "react";
import { DefaultMap } from "lib/DefaultMap";
import { isDefined } from "../../../utils/predicates";
import { undefMap } from "../../../utils/utils";
import { useDashboardContext } from "../Dashboard";
import { allExportCableTypesInParkSelectorFamily } from "components/FinancialAnalysis/Capex/exportCable/exportCableAmounts";
import { getInvalidTypesInParkAndBranch } from "state/layout";
import { InvalidTypes, InvalidTypesDisplayText } from "types/invalidTypes";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import Table from "components/Dashboard/Table";

const CablingDetails = () => {
  const { park, branch, configuration } = useDashboardContext();
  const cables = useRecoilValue(getCablesSelectorFamily({ parkId: park.id }));

  const cableTypes = useRecoilValue(allCableTypesSelector);

  const cableLengths = useRecoilValueLoadable(
    get3DCableLengthsInParkWithContingency({
      parkId: park.id,
      branchId: branch.id,
      analysisConfigurationId: configuration.id,
    }),
  ).valueMaybe();
  const { exportCableTypes, lengthPerCableType: exportCableLengths } =
    useRecoilValue(
      allExportCableTypesInParkSelectorFamily({
        parkId: park.id,
        branchId: branch.id,
      }),
    );

  const items = useMemo(() => {
    if (!cableLengths) return;

    let lengthByCableId = new DefaultMap(() => 0);
    let costByCableId = new DefaultMap(() => 0);

    for (const cable of cables) {
      const typeId = cable.properties.cableTypeId ?? "other";
      const type = cableTypes.find((t) => t.id === typeId);

      const length = cableLengths[cable.id] ?? 0;
      lengthByCableId.update(typeId, (n) => n + length);

      if (type && isDefined(type.costPerKilometer)) {
        const cost = type.costPerKilometer * length;
        costByCableId.update(typeId, (n) => n + cost);
      }
    }

    type Item = {
      id: string;
      name: string;
      powerRating?: number;
      length: number;
      cost?: number;
      voltage?: number;
    };
    const items: Item[] = cableTypes
      .map<Item>((t) => ({
        id: t.id,
        name: t.name,
        powerRating: t.powerRating / 1e6,
        length: lengthByCableId.get(t.id),
        cost: costByCableId.get(t.id),
        voltage: t.voltage,
      }))
      .concat(
        exportCableTypes.map<Item>((t) => ({
          id: t.id,
          name: t.name,
          powerRating: t.powerRating / 1e6,
          length: exportCableLengths[t.id] ?? 0,
          voltage: t.voltage,
        })),
      )
      .concat([
        {
          id: "__other",
          name: "Other",
          length: lengthByCableId.get("other"),
        },
      ]);

    items.sort((a, b) => a.name.localeCompare(b.name));

    return items
      .filter((i) => i.length > 0)
      .map((item) => ({
        id: item.id,
        name: item.name,
        powerRating: undefMap(item.powerRating, (pr) => pr.toFixed(0)) ?? "-",
        length: item.length.toFixed(2),
        cost: undefMap(item.cost, (c) => c.toFixed(0)) ?? "-",
        voltage: item.voltage ?? "-",
      }));
  }, [cableLengths, cableTypes, cables, exportCableLengths, exportCableTypes]);

  const invalidTypes = useRecoilValue(
    getInvalidTypesInParkAndBranch({ parkId: park.id, branchId: branch.id }),
  );

  const relevantInvalidTypes = invalidTypes.filter(
    (t) => t.type === InvalidTypes.CableTypeInvalid,
  );

  if (cables.length === 0) {
    return (
      <CenterContainer>
        <SimpleAlert text={"No cables in park."} />
      </CenterContainer>
    );
  }

  if (relevantInvalidTypes.length > 0)
    return (
      <CenterContainer style={{ margin: "3rem" }}>
        <InvalidTypesDisplayText invalidTypes={relevantInvalidTypes} />
      </CenterContainer>
    );

  if (!items) {
    return <LoadingState />;
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Power rating (MW)</th>
          <th>Total length (km)</th>
          <th>Voltage (kV)</th>
        </tr>
      </thead>
      <tbody>
        {items.map((i) => (
          <tr key={i.id}>
            <td>{i.name}</td>
            <td>{i.powerRating}</td>
            <td>{i.length}</td>
            <td>{i.voltage}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export const CableUsageWidget = () => (
  <SafeCard title="Cables usage" id="Cabling">
    <CablingDetails />
  </SafeCard>
);
