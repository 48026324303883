import React, { useCallback, useEffect, useRef } from "react";
import { Mixpanel } from "mixpanel";
import { sendWarning } from "utils/sentry";
import { Column, ModalFrame } from "components/General/Layout";
import { TextArea } from "components/General/Input";
import styled from "styled-components";
import Button from "components/General/Button";
import useTextInput from "hooks/useTextInput";
import { spacing8 } from "styles/space";
import useBooleanState from "hooks/useBooleanState";
import Spinner from "@icons/spinner/Spinner";
import { useClickOutside } from "hooks/useClickOutside";

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
`;

const EditDescriptionModalFrame = ({
  defaultValue,
  updateDescription,
  close,
  subtitle,
}: {
  defaultValue?: string | null;
  updateDescription(newDescription: string): Promise<any>;
  close(): void;
  subtitle: React.ReactNode;
}) => {
  const [description, onDescriptionChange] = useTextInput(defaultValue ?? "");
  const [isSaving, toggleIsSaving] = useBooleanState(false);
  const elementRef = useRef<HTMLDivElement>(null);
  useClickOutside(elementRef, close, undefined, {
    ignoreDragClicks: true,
  });
  const isMountedRef = useRef(true);
  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const onDoneClick = useCallback(async () => {
    toggleIsSaving();
    Mixpanel.track("Update organisation layer description", {
      length: description.length,
    });
    try {
      await updateDescription(description);
      if (isMountedRef.current) {
        close();
      }
    } catch (err) {
      if (isMountedRef.current) {
        sendWarning("Could not update organisation layer description.", {
          err,
        });
        console.error(err);
      }
    } finally {
      if (isMountedRef.current) {
        toggleIsSaving();
      }
    }
    return () => {};
  }, [description, close, toggleIsSaving, updateDescription]);

  return (
    <ModalFrame
      frameRef={elementRef}
      title="Add description"
      onExit={close}
      onClick={(e) => e.stopPropagation()}
    >
      <Column
        style={{
          gap: spacing8,
        }}
      >
        {subtitle}
        <TextArea
          autoFocus
          placeholder="Description"
          onChange={onDescriptionChange}
          value={description}
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              close();
            }
          }}
          style={{
            width: "100%",
            resize: "vertical",
            maxHeight: "20rem",
          }}
          rows={4}
        />
      </Column>
      <ButtonWrapper>
        <Button buttonType="secondary" text="Cancel" onClick={close} />
        <Button
          buttonType="primary"
          text="Done"
          onClick={onDoneClick}
          disabled={isSaving}
          icon={isSaving ? <Spinner size="1rem" /> : undefined}
        />
      </ButtonWrapper>
    </ModalFrame>
  );
};

export default EditDescriptionModalFrame;
