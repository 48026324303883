/// <reference types="vite-plugin-svgr/client" />
import React, { useEffect } from "react";
import { colors } from "../../../../styles/colors";
import ErrorRoundIcon from "@icons/24/ErrorRound.svg?react";
import ArrowLeftIcon from "@icons/24/ArrowLeft.svg?react";
import { spaceSmall } from "../../../../styles/space";
import { IconREMSize } from "../../../../styles/typography";
import Button from "../../../General/Button";
import { ButtonWrapper, TextNoMargin, UploadWrapper } from "../shared";

const ErrorMessage = ({
  errorText,
  resetErrorMessage,
}: {
  errorText: string;
  resetErrorMessage(): void;
}) => {
  useEffect(() => {
    return () => {
      resetErrorMessage();
    };
  }, [resetErrorMessage]);

  return (
    <UploadWrapper>
      <div
        style={{
          display: "flex",
          gap: spaceSmall,
          alignItems: "flex-start",
        }}
      >
        <IconREMSize height={1.4} width={1.4} style={{ marginTop: "0.2rem" }}>
          <ErrorRoundIcon />
        </IconREMSize>
        <TextNoMargin style={{ color: colors.redAlert }}>
          {errorText}
        </TextNoMargin>
      </div>
      <ButtonWrapper>
        <Button
          buttonType="text"
          text="Back"
          icon={<ArrowLeftIcon />}
          onClick={resetErrorMessage}
          style={{
            paddingLeft: 0,
          }}
        />
      </ButtonWrapper>
    </UploadWrapper>
  );
};

export default ErrorMessage;
