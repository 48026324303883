import React, { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useGoToFeatures } from "hooks/map";
import { mapRefAtom } from "state/map";
import { currentTurbineIdAtom } from "state/turbines";
import { useProjectElementsCrud } from "components/ProjectElements/useProjectElementsCrud";
import { Mixpanel } from "mixpanel";
import { parkIdSelector, projectIdSelector } from "state/pathParams";
import {
  UploadFileType,
  UploadModalType,
} from "components/UploadModal/UploadModal";
import { modalTypeOpenAtom } from "state/modal";
import UploadFeaturesFromCoordinatesGeneral from "./UploadFeaturesFromCoordinatesGeneral";

const UploadFeaturesFromCoordinates = ({
  onBackClick,
  onDoneClick,
}: {
  onBackClick(): void;
  onDoneClick(): void;
}) => {
  const map = useRecoilValue(mapRefAtom);
  const goToFeatures = useGoToFeatures(map);
  const parkId = useRecoilValue(parkIdSelector);
  const projectId = useRecoilValue(projectIdSelector);
  const { update: updateFeatures } = useProjectElementsCrud();
  const currentTurbineType = useRecoilValue(
    currentTurbineIdAtom({ projectId }),
  );
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);

  useEffect(() => {
    setModalTypeOpen({
      modalType: UploadModalType,
      metadata: {
        preSelectedFileType: UploadFileType.FEATURE_FROM_COORDINATES,
      },
    });
  }, [setModalTypeOpen]);

  return (
    <UploadFeaturesFromCoordinatesGeneral
      inContextOfProject={true}
      parkId={parkId}
      currentTurbineType={currentTurbineType}
      onDoneClick={onDoneClick}
      onBackClick={onBackClick}
      onAddClick={(tempFeatures) => {
        Mixpanel.track("Added feature from coordinates", {
          numberOfFeatures: tempFeatures.length,
        });
        updateFeatures({
          add: tempFeatures,
        });
        goToFeatures(tempFeatures);
      }}
    />
  );
};

export default UploadFeaturesFromCoordinates;
