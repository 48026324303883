import GeoTIFF, { TypedArray } from "geotiff";
import { Map } from "mapbox-gl";
import { useEffect, useMemo, useState } from "react";
import { fetchEnhancer } from "../../services/utils";
import { transformBBOXToWGS84Square } from "../../utils/proj4";
import mapboxgl, { CustomLayerInterface } from "mapbox-gl";
import { scream } from "utils/sentry";

export const GeotiffImage = ({
  layerId,
  map,
  geotiff,
  opacity = 1,
}: {
  layerId: string;
  map: Map;
  geotiff: GeoTIFF;
  opacity: number;
}) => {
  const [image, setImage] = useState<HTMLImageElement>();
  const [points, setPoints] = useState<number[]>();

  useEffect(() => {
    if (!geotiff) return;
    let isCancelled = false;
    const initBBOX = async () => {
      const image = await geotiff.getImage();
      const bbox = image.getBoundingBox() as [number, number, number, number]; // safety: okay according to docs.
      const epsg =
        image.geoKeys.ProjectedCSTypeGeoKey ||
        image.geoKeys.GeographicTypeGeoKey;

      let square = [
        { lng: bbox[0], lat: bbox[1] },
        { lng: bbox[2], lat: bbox[1] },
        { lng: bbox[2], lat: bbox[3] },
        { lng: bbox[0], lat: bbox[3] },
      ];

      if (epsg !== 4326) {
        const response = await fetchEnhancer(`https://epsg.io/${epsg}.proj4`, {
          method: "get",
        });
        const proj4String = await response.text();
        square = transformBBOXToWGS84Square(bbox, proj4String);
      }

      const lowerLeft = mapboxgl.MercatorCoordinate.fromLngLat(square[0]);
      const lowerRight = mapboxgl.MercatorCoordinate.fromLngLat(square[1]);
      const upperRight = mapboxgl.MercatorCoordinate.fromLngLat(square[2]);
      const upperLeft = mapboxgl.MercatorCoordinate.fromLngLat(square[3]);

      if (isCancelled) return;

      setPoints([
        upperLeft.x,
        upperLeft.y,
        upperRight.x,
        upperRight.y,
        lowerLeft.x,
        lowerLeft.y,

        lowerLeft.x,
        lowerLeft.y,
        upperRight.x,
        upperRight.y,
        lowerRight.x,
        lowerRight.y,
      ]);

      const data = (await image.readRGB({
        interleave: true,
        enableAlpha: true,
      })) as TypedArray & { height: number; width: number }; // NOTE: see https://geotiffjs.github.io/geotiff.js/module-geotiff.html#~ReadRasterResult

      const canvas = document.createElement("canvas");
      canvas.width = data.width;
      canvas.height = data.height;

      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      var imgData = ctx.createImageData(data.width, data.height);

      if (image.getSamplesPerPixel() === 4) {
        imgData.data.set(data);
      } else {
        let alphaSkip = 0;
        for (var i = 0; i < data.length; i += 3) {
          imgData.data[i + alphaSkip] = data[i]; //red
          imgData.data[i + 1 + alphaSkip] = data[i + 1]; //green
          imgData.data[i + 2 + alphaSkip] = data[i + 2]; //blue
          imgData.data[i + 3 + alphaSkip] = 255; //alpha
          alphaSkip++;
        }
      }

      ctx.putImageData(imgData, 0, 0);

      const rotatedImage = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      var imageTag = new Image();
      imageTag.src = rotatedImage;
      imageTag.onload = function () {
        if (isCancelled) return;
        setImage(imageTag);
      };
    };
    initBBOX();
    return () => {
      isCancelled = true;
    };
  }, [geotiff, setImage, setPoints]);

  const geotiffLayer = useMemo(() => {
    if (!map || !points || !image || !layerId) return;

    const geotiffLayer: CustomLayerInterface & {
      program?: WebGLProgram;
      positionLocation?: number;
      texcoordLocation?: number;
      positionBuffer?: WebGLBuffer;
      texcoordBuffer?: WebGLBuffer;
      texture?: WebGLTexture;
      opacity: number;
      setOpacity: (opacity: number) => void;
    } = {
      id: layerId,
      type: "custom" as const,
      opacity: 1,

      setOpacity: function (opacity: number) {
        this.opacity = opacity;
      },

      // method called when the layer is added to the map
      // https://docs.mapbox.com/mapbox-gl-js/api/#styleimageinterface#onadd
      onAdd: function (map, gl) {
        // create GLSL source for vertex shader
        const vertexSource = `
          attribute vec2 a_texCoord;
          varying vec2 v_texCoord;

          uniform mat4 u_matrix;
          attribute vec2 a_position;
          void main() {
            v_texCoord = a_texCoord;
            gl_Position = u_matrix * vec4(a_position, 0.0, 1.0);
          }`;

        // create GLSL source for fragment shader
        const fragmentSource = `
          precision mediump float;
          uniform sampler2D u_image;
          uniform float u_opacity;
          varying vec2 a_texCoord;
          varying vec2 v_texCoord;

          void main() {
            vec4 image = texture2D(u_image, v_texCoord);
            gl_FragColor = u_opacity * vec4(image.rgb, image.a);
          }`;

        // create a vertex shader
        const vertexShader = gl.createShader(gl.VERTEX_SHADER)!;
        gl.shaderSource(vertexShader, vertexSource);
        gl.compileShader(vertexShader);

        // create a fragment shader
        const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
        if (!fragmentShader)
          throw scream("Fragment shader failed to compile: ");
        gl.shaderSource(fragmentShader, fragmentSource);
        gl.compileShader(fragmentShader);

        // link the two shaders into a WebGL program
        this.program = gl.createProgram()!;
        gl.attachShader(this.program, vertexShader);
        gl.attachShader(this.program, fragmentShader);
        gl.linkProgram(this.program);

        this.positionLocation = gl.getAttribLocation(
          this.program,
          "a_position",
        );
        this.texcoordLocation = gl.getAttribLocation(
          this.program,
          "a_texCoord",
        );

        this.positionBuffer = gl.createBuffer()!;
        gl.bindBuffer(gl.ARRAY_BUFFER, this.positionBuffer);
        gl.bufferData(
          gl.ARRAY_BUFFER,
          new Float32Array(points),
          gl.STATIC_DRAW,
        );

        this.texcoordBuffer = gl.createBuffer()!;
        gl.bindBuffer(gl.ARRAY_BUFFER, this.texcoordBuffer);
        gl.bufferData(
          gl.ARRAY_BUFFER,
          new Float32Array([
            0.0, 0.0, 1.0, 0.0, 0.0, 1.0, 0.0, 1.0, 1.0, 0.0, 1.0, 1.0,
          ]),
          gl.STATIC_DRAW,
        );

        this.texture = gl.createTexture()!;
        gl.bindTexture(gl.TEXTURE_2D, this.texture);

        // Upload the image into the texture.
        gl.texImage2D(
          gl.TEXTURE_2D,
          0,
          gl.RGBA,
          gl.RGBA,
          gl.UNSIGNED_BYTE,
          image,
        );

        // Set the parameters so we can render any size image.
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
      },

      // method fired on each animation frame
      // https://docs.mapbox.com/mapbox-gl-js/api/#map.event:render
      render: function (gl, matrix) {
        // Tell WebGL we want to affect texture unit 0
        gl.activeTexture(gl.TEXTURE0);

        // Bind the texture to texture unit 0
        gl.bindTexture(gl.TEXTURE_2D, this.texture!);

        gl.useProgram(this.program!);

        gl.enable(gl.BLEND);
        gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

        gl.enableVertexAttribArray(this.positionLocation!);
        gl.bindBuffer(gl.ARRAY_BUFFER, this.positionBuffer!);

        gl.vertexAttribPointer(
          this.positionLocation!,
          2,
          gl.FLOAT,
          false,
          0,
          0,
        );

        gl.enableVertexAttribArray(this.texcoordLocation!);
        gl.bindBuffer(gl.ARRAY_BUFFER, this.texcoordBuffer!);

        gl.vertexAttribPointer(
          this.texcoordLocation!,
          2,
          gl.FLOAT,
          false,
          0,
          0,
        );

        gl.uniformMatrix4fv(
          gl.getUniformLocation(this.program!, "u_matrix"),
          false,
          matrix,
        );
        gl.uniform1f(
          gl.getUniformLocation(this.program!, "u_opacity"),
          this.opacity,
        );
        gl.drawArrays(gl.TRIANGLES, 0, 6);
      },
    };

    return geotiffLayer;
  }, [points, image, map, layerId]);

  useEffect(() => {
    if (!geotiffLayer) return;
    map.addLayer(geotiffLayer);
    return () => {
      map.removeLayer(geotiffLayer.id);
    };
  }, [geotiffLayer, map]);

  useEffect(() => {
    if (!geotiffLayer) return;
    geotiffLayer?.setOpacity(opacity);
    map.triggerRepaint();
  }, [geotiffLayer, opacity, map]);

  return null;
};

export type ColorBucket = {
  threshold: number;
  color: [number, number, number];
};

export const GeotiffArray = ({
  layerId,
  map,
  geotiff,
  colorBuckets,
  opacity = 255,
}: {
  layerId: string;
  map: Map;
  geotiff: GeoTIFF;
  colorBuckets: ColorBucket[];
  opacity: number;
}) => {
  const [image, setImage] = useState<HTMLImageElement>();
  const [points, setPoints] = useState<number[]>();

  useEffect(() => {
    if (!geotiff) return;
    let isCancelled = false;
    const initBBOX = async () => {
      const image = await geotiff.getImage();
      const bbox = image.getBoundingBox() as [number, number, number, number]; // safety: okay according to docs.
      const epsg =
        image.geoKeys.ProjectedCSTypeGeoKey ||
        image.geoKeys.GeographicTypeGeoKey;

      let square = [
        { lng: bbox[0], lat: bbox[1] },
        { lng: bbox[2], lat: bbox[1] },
        { lng: bbox[2], lat: bbox[3] },
        { lng: bbox[0], lat: bbox[3] },
      ];

      if (epsg !== 4326) {
        const response = await fetchEnhancer(`https://epsg.io/${epsg}.proj4`, {
          method: "get",
        });
        const proj4String = await response.text();
        square = transformBBOXToWGS84Square(bbox, proj4String);
      }

      const lowerLeft = mapboxgl.MercatorCoordinate.fromLngLat(square[0]);
      const lowerRight = mapboxgl.MercatorCoordinate.fromLngLat(square[1]);
      const upperRight = mapboxgl.MercatorCoordinate.fromLngLat(square[2]);
      const upperLeft = mapboxgl.MercatorCoordinate.fromLngLat(square[3]);

      if (isCancelled) return;

      setPoints([
        upperLeft.x,
        upperLeft.y,
        upperRight.x,
        upperRight.y,
        lowerLeft.x,
        lowerLeft.y,

        lowerLeft.x,
        lowerLeft.y,
        upperRight.x,
        upperRight.y,
        lowerRight.x,
        lowerRight.y,
      ]);

      const data = await image.readRasters();

      const canvas = document.createElement("canvas");
      canvas.width = data.width;
      canvas.height = data.height;

      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      var imgData = ctx.createImageData(data.width, data.height);

      const array = data[0] as TypedArray;

      let offset = 0;
      for (var i = 0; i < array.length; i++) {
        const value = array[i];
        if (value === 0) {
          imgData.data[i + offset] = 0;
          imgData.data[i + 1 + offset] = 0;
          imgData.data[i + 2 + offset] = 0;
          imgData.data[i + 3 + offset] = 0;
        } else {
          const colorRGB =
            colorBuckets.find((cb) => cb.threshold >= value)?.color ??
            colorBuckets[0].color; // default to first color if threshold is lower than all buckets
          imgData.data[i + offset] = colorRGB[0];
          imgData.data[i + 1 + offset] = colorRGB[1];
          imgData.data[i + 2 + offset] = colorRGB[2];
          imgData.data[i + 3 + offset] = 255;
        }
        offset += 3;
      }

      ctx.putImageData(imgData, 0, 0);

      const rotatedImage = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      var imageTag = new Image();
      imageTag.src = rotatedImage;
      imageTag.onload = function () {
        if (isCancelled) return;
        setImage(imageTag);
      };
    };
    initBBOX();
    return () => {
      isCancelled = true;
    };
  }, [geotiff, setImage, setPoints, colorBuckets]);

  const geotiffLayer = useMemo(() => {
    if (!map || !points || !image || !layerId) return;

    const geotiffLayer: CustomLayerInterface & {
      program?: WebGLProgram;
      positionLocation: number;
      texcoordLocation: number;
      positionBuffer?: WebGLBuffer;
      texcoordBuffer?: WebGLBuffer;
      texture?: WebGLTexture;
      opacity: number;
      setOpacity: (opacity: number) => void;
      loc_u_matrix: WebGLUniformLocation;
      loc_u_opacity: WebGLUniformLocation;
    } = {
      id: layerId,
      type: "custom" as const,
      opacity: 1.0,
      positionLocation: -1,
      texcoordLocation: -1,

      loc_u_matrix: -1,
      loc_u_opacity: -1,

      setOpacity: function (opacity: number) {
        this.opacity = opacity;
      },

      // method called when the layer is added to the map
      // https://docs.mapbox.com/mapbox-gl-js/api/#styleimageinterface#onadd
      onAdd: function (map, gl) {
        // create GLSL source for vertex shader
        const vertexSource = `
          attribute vec2 a_texCoord;
          varying vec2 v_texCoord;

          uniform mat4 u_matrix;
          uniform float u_opacity;
          attribute vec2 a_position;
          void main() {
            v_texCoord = a_texCoord;
            gl_Position = u_matrix * vec4(a_position, 0.0, 1.0);
          }`;

        // create GLSL source for fragment shader
        const fragmentSource = `
          precision highp float;
          uniform sampler2D u_image;
          uniform float u_opacity;
          varying vec2 a_texCoord;
          varying vec2 v_texCoord;

          void main() {
            vec4 image = texture2D(u_image, v_texCoord);
            gl_FragColor = u_opacity * vec4(image.rgb, image.a);
          }`;

        // create a vertex shader
        const vertexShader = gl.createShader(gl.VERTEX_SHADER);
        if (!vertexShader) throw scream("create vertex shader failed");
        gl.shaderSource(vertexShader, vertexSource);
        gl.compileShader(vertexShader);

        // create a fragment shader
        const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
        if (!fragmentShader) throw scream("create fragment shader failed");
        gl.shaderSource(fragmentShader, fragmentSource);
        gl.compileShader(fragmentShader);

        // link the two shaders into a WebGL program
        const program = gl.createProgram();
        if (!program) throw scream("failed to create program");
        this.program = program;
        gl.attachShader(this.program, vertexShader);
        gl.attachShader(this.program, fragmentShader);
        gl.linkProgram(this.program);
        if (!gl.getProgramParameter(this.program, gl.LINK_STATUS)) {
          console.error(`Link failed: ${gl.getProgramInfoLog(this.program)}`);
          console.error(`vs info-log: ${gl.getShaderInfoLog(vertexShader)}`);
          console.error(`fs info-log: ${gl.getShaderInfoLog(fragmentShader)}`);
        }

        this.loc_u_matrix =
          gl.getUniformLocation(this.program, "u_matrix") ?? -1;
        if (this.loc_u_matrix === -1)
          throw scream("Failed to find u_matrix location");
        this.loc_u_opacity =
          gl.getUniformLocation(this.program, "u_opacity") ?? -1;
        if (this.loc_u_opacity === -1)
          throw scream("Failed to find u_opacity location");

        this.positionLocation = gl.getAttribLocation(
          this.program,
          "a_position",
        );
        this.texcoordLocation = gl.getAttribLocation(
          this.program,
          "a_texCoord",
        );

        const positionBuffer = gl.createBuffer();
        if (!positionBuffer) throw scream("create buffer failed");
        this.positionBuffer = positionBuffer;
        gl.bindBuffer(gl.ARRAY_BUFFER, this.positionBuffer);
        gl.bufferData(
          gl.ARRAY_BUFFER,
          new Float32Array(points),
          gl.STATIC_DRAW,
        );

        const texcoordBuffer = gl.createBuffer();
        if (!texcoordBuffer) throw scream("create buffer failed");
        this.texcoordBuffer = texcoordBuffer;
        gl.bindBuffer(gl.ARRAY_BUFFER, this.texcoordBuffer);
        gl.bufferData(
          gl.ARRAY_BUFFER,
          new Float32Array([
            0.0, 0.0, 1.0, 0.0, 0.0, 1.0, 0.0, 1.0, 1.0, 0.0, 1.0, 1.0,
          ]),
          gl.STATIC_DRAW,
        );

        this.texture = gl.createTexture()!;
        gl.bindTexture(gl.TEXTURE_2D, this.texture);

        // Upload the image into the texture.
        gl.texImage2D(
          gl.TEXTURE_2D,
          0,
          gl.RGBA,
          gl.RGBA,
          gl.UNSIGNED_BYTE,
          image,
        );

        // Set the parameters so we can render any size image.
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
      },

      // method fired on each animation frame
      // https://docs.mapbox.com/mapbox-gl-js/api/#map.event:render
      render: function (gl, matrix) {
        if (!this.texture) throw scream("Geotiff layer missing texture");
        if (!this.program) throw scream("Geotiff layer missing program");
        if (!this.texcoordBuffer)
          throw scream("Geotiff layer missing texcoordBuffer");
        if (!this.positionBuffer)
          throw scream("Geotiff layer missing positionBuffer");
        // Tell WebGL we want to affect texture unit 0
        gl.activeTexture(gl.TEXTURE0);

        // Bind the texture to texture unit 0
        gl.bindTexture(gl.TEXTURE_2D, this.texture);

        gl.useProgram(this.program);

        gl.enable(gl.BLEND);
        gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

        gl.enableVertexAttribArray(this.positionLocation);
        gl.bindBuffer(gl.ARRAY_BUFFER, this.positionBuffer);

        gl.vertexAttribPointer(this.positionLocation, 2, gl.FLOAT, false, 0, 0);

        gl.enableVertexAttribArray(this.texcoordLocation);
        gl.bindBuffer(gl.ARRAY_BUFFER, this.texcoordBuffer);

        gl.vertexAttribPointer(this.texcoordLocation, 2, gl.FLOAT, false, 0, 0);

        gl.uniformMatrix4fv(this.loc_u_matrix, false, matrix);
        gl.uniform1f(this.loc_u_opacity, this.opacity);

        gl.drawArrays(gl.TRIANGLES, 0, 6);
      },
    };

    return geotiffLayer;
  }, [points, image, map, layerId]);

  useEffect(() => {
    if (!geotiffLayer) return;
    map.addLayer(geotiffLayer);
    return () => {
      map.removeLayer(geotiffLayer.id);
    };
  }, [geotiffLayer, map]);

  useEffect(() => {
    if (!geotiffLayer) return;
    geotiffLayer?.setOpacity(opacity);
    map.triggerRepaint();
  }, [geotiffLayer, opacity, map]);

  return null;
};
