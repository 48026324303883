import UserInfo from "components/Comments/MapModal/UserInfo";
import { dateToYearDateTime } from "utils/utils";
import styled from "styled-components";
import { colors } from "styles/colors";

const LastEditerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

const LastEditedTextWrapper = styled.div`
  color: ${colors.textDisabled};
`;

const ComponentLastChanged = ({
  component,
}: {
  component: { author?: string; createdAt?: number };
}) => {
  if (!component.author || !component.createdAt) return null;

  return (
    <ComponentLastChangedInner
      author={component.author}
      createdAt={component.createdAt}
    />
  );
};

const ComponentLastChangedInner = ({
  author,
  createdAt,
}: {
  author: string;
  createdAt: number;
}) => {
  return (
    <LastEditerWrapper>
      <LastEditedTextWrapper>Last edited:</LastEditedTextWrapper>
      {dateToYearDateTime(new Date(createdAt))}
      <UserInfo userId={author} size={2} />
    </LastEditerWrapper>
  );
};

export default ComponentLastChanged;
