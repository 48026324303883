import { useSetPathParams } from "App";
import { Outlet, useSearchParams } from "react-router-dom";
import { setAccessTokenGlobal } from "state/global";

export default function ArchiveProjectAccess() {
  useSetPathParams();
  const [sp] = useSearchParams();
  const accessToken = sp.get("access_token");

  if (!accessToken) {
    return <div>Invalid access token</div>;
  }
  setAccessTokenGlobal(accessToken);

  return (
    <div>
      <Outlet />
    </div>
  );
}
