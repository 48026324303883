import { RecoilValue, useRecoilValue } from "recoil";
import usePrevious from "./usePrevious";
import { useMemo } from "react";

export const didArrayChange = <T>(
  prev: T[] | undefined,
  curr: T[],
): boolean => {
  if (!prev) return true;
  const currSet = new Set(curr.map((item) => JSON.stringify(item)));
  const prevSet = new Set(prev.map((item) => JSON.stringify(item)));

  if (prevSet.size !== currSet.size) return true;

  for (const key of currSet) if (!prevSet.has(key)) return true;
  return false;
};

/**
 * Check that a list of things really did change.  Useful for when you want to
 * do something when a list of e.g. features changes, but since we `.filter` in selectors
 * the list is always a new array, but the features might not have changed.
 * @param atom
 * @returns
 */
export const useArrayAtomDidChange = <T>(atom: RecoilValue<T[]>): boolean => {
  const curr = useRecoilValue(atom);
  const prev = usePrevious(curr);
  const didChange = useMemo(() => didArrayChange(prev, curr), [curr, prev]);
  return didChange;
};

export const useArrayDidChange = <T>(array: T[]): boolean => {
  const prev = usePrevious(array);
  const didChange = useMemo(() => didArrayChange(prev, array), [array, prev]);
  return didChange;
};
