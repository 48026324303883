import styled from "styled-components";
import { spacing1, spacing7 } from "styles/space";
import { colors } from "styles/colors";
import { Column } from "components/General/Layout";

export const BranchTableItemWrapper = styled.span<{
  active: boolean;
}>`
  position: relative;
  padding: 1rem 0.5rem 1rem ${spacing1};
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  gap: ${spacing7};

  button {
    visibility: hidden;
  }
  button.is-loading-parks {
    visibility: visible;
  }
  ${({ active }) =>
    active && `&&&&& { background-color: ${colors.surfaceSelectedLight}; }`}

  &:hover {
    p {
      color: ${colors.blue600};
    }

    button {
      visibility: visible;
    }
  }
`;

export const BranchesTableWrapper = styled(Column)`
  overflow-y: auto;
  gap: 0;

  & > ${BranchTableItemWrapper}:nth-of-type(2n + 1) {
    background-color: ${colors.grey50};
  }

  > ${BranchTableItemWrapper} {
    cursor: pointer;

    &:hover {
      background-color: ${colors.grey100};
    }
  }
`;
