/// <reference types="vite-plugin-svgr/client" />
import { Row } from "components/General/Layout";
import Tabs from "components/General/Tabs";
import TeamMeeting from "@icons/24/TeamMeeting.svg?react";
import { organisationGroupsState } from "components/Organisation/Groups/state";
import { Suspense, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { useTypedPath } from "state/pathParams";
import { ContentHeader, ContentWrapper } from "../../style";
import { TablePlaceholder } from "../shared/TablePlaceholder";
import { adminInOrganisationSelectorFamily } from "state/user";
import { randomPastelColor } from "utils/image";
import { EditableText } from "components/General/EditableText";
import useGroupsCrud from "components/Organisation/Groups/useGroupsCrud";
import { GroupMembers } from "./tabs/GroupMembers";
import { GroupProjects } from "./tabs/GroupProjects";
import { OrganisationResourceGroupTable } from "./tabs/OrganisationGroupResourceTable";
import { ContentImageWrapper } from "components/Organisation/Image/ImageWrapper";

export default function GroupContent({ groupId }: { groupId: string }) {
  const { organisationId } = useTypedPath("organisationId");
  const { update } = useGroupsCrud();
  const group = useRecoilValue(
    organisationGroupsState({ organisationId }),
  ).find((g) => g.id === groupId);

  const isAdminInOrg = useRecoilValue(
    adminInOrganisationSelectorFamily({ organisationId }),
  );

  const [name, setName] = useState(group?.name ?? "");

  const tabs = useMemo(() => {
    if (!group) return [];
    const ts = [
      {
        name: "Members",
        data: (
          <Suspense fallback={<TablePlaceholder />}>
            <GroupMembers group={group} />
          </Suspense>
        ),
      },
    ];
    if (isAdminInOrg) {
      ts.push({
        name: "Project access",
        data: (
          <Suspense fallback={<TablePlaceholder />}>
            <GroupProjects groupId={group.id} />
          </Suspense>
        ),
      });
      ts.push({
        name: "Library access",
        data: (
          <Suspense fallback={<TablePlaceholder />}>
            <OrganisationResourceGroupTable groupId={group.id} />
          </Suspense>
        ),
      });
    }
    return ts;
  }, [group, isAdminInOrg]);

  const colors = useMemo(() => randomPastelColor(groupId), [groupId]);

  if (!group)
    return (
      <ContentWrapper>
        <h4>Group not found</h4>
      </ContentWrapper>
    );

  return (
    <ContentWrapper>
      <Row style={{ gap: "2.4rem", alignItems: "center" }}>
        <ContentImageWrapper
          color={colors.backgroundColor}
          svgColor={colors.textColor}
        >
          <TeamMeeting />
        </ContentImageWrapper>
        <EditableText
          type="text"
          value={name}
          disabled={!isAdminInOrg}
          onChange={(e) => {
            setName(e.target.value);
          }}
          onEnter={() => {
            update(groupId, name);
          }}
          onCancel={() => {
            setName(group.name ?? "");
          }}
          textContainerStyle={{
            maxWidth: "20vw",
          }}
          editIconTitle="Rename"
          renderText={(title) => <ContentHeader>{title}</ContentHeader>}
        />
      </Row>
      <Tabs
        menuStyle={{ justifyContent: "start", gap: "5rem" }}
        tabs={tabs}
        wrapperStyle={{ overflowY: "hidden" }}
        contentWrapperStyle={{ height: "100%" }}
      />
    </ContentWrapper>
  );
}
