export const crsWhiteList = [
  { code: "2000", match: true },
  { code: "2001", match: false },
  { code: "2002", match: false },
  { code: "2003", match: true },
  { code: "2004", match: false },
  { code: "2005", match: false },
  { code: "2006", match: true },
  { code: "2007", match: false },
  { code: "2008", match: true },
  { code: "2009", match: true },
  { code: "2010", match: true },
  { code: "2011", match: true },
  { code: "2012", match: true },
  { code: "2013", match: true },
  { code: "2014", match: true },
  { code: "2015", match: true },
  { code: "2016", match: true },
  { code: "2017", match: true },
  { code: "2018", match: true },
  { code: "2019", match: true },
  { code: "2020", match: true },
  { code: "2021", match: true },
  { code: "2022", match: true },
  { code: "2023", match: true },
  { code: "2024", match: true },
  { code: "2025", match: true },
  { code: "2026", match: true },
  { code: "2027", match: true },
  { code: "2028", match: true },
  { code: "2029", match: true },
  { code: "2030", match: true },
  { code: "2031", match: true },
  { code: "2032", match: true },
  { code: "2033", match: true },
  { code: "2034", match: true },
  { code: "2035", match: true },
  { code: "2036", match: true },
  { code: "2037", match: true },
  { code: "2038", match: true },
  { code: "2039", match: true },
  { code: "2040", match: true },
  { code: "2041", match: false },
  { code: "2042", match: true },
  { code: "2043", match: false },
  { code: "2044", match: true },
  { code: "2045", match: true },
  { code: "2046", match: true },
  { code: "2047", match: true },
  { code: "2048", match: true },
  { code: "2049", match: true },
  { code: "2050", match: true },
  { code: "2051", match: true },
  { code: "2052", match: true },
  { code: "2053", match: true },
  { code: "2054", match: true },
  { code: "2055", match: true },
  { code: "2056", match: true },
  { code: "2057", match: true },
  { code: "2058", match: true },
  { code: "2059", match: true },
  { code: "2060", match: true },
  { code: "2061", match: true },
  { code: "2062", match: true },
  { code: "2063", match: true },
  { code: "2064", match: true },
  { code: "2065", match: false },
  { code: "2066", match: false },
  { code: "2067", match: true },
  { code: "2068", match: true },
  { code: "2069", match: true },
  { code: "2070", match: true },
  { code: "2071", match: true },
  { code: "2072", match: true },
  { code: "2073", match: true },
  { code: "2074", match: true },
  { code: "2075", match: true },
  { code: "2076", match: true },
  { code: "2077", match: true },
  { code: "2078", match: true },
  { code: "2079", match: true },
  { code: "2080", match: true },
  { code: "2081", match: true },
  { code: "2082", match: true },
  { code: "2083", match: true },
  { code: "2084", match: true },
  { code: "2085", match: true },
  { code: "2086", match: true },
  { code: "2087", match: true },
  { code: "2088", match: true },
  { code: "2089", match: true },
  { code: "2090", match: true },
  { code: "2091", match: true },
  { code: "2092", match: true },
  { code: "2093", match: true },
  { code: "2094", match: true },
  { code: "2095", match: true },
  { code: "2096", match: false },
  { code: "2097", match: false },
  { code: "2098", match: false },
  { code: "2099", match: true },
  { code: "2100", match: true },
  { code: "2101", match: true },
  { code: "2102", match: true },
  { code: "2103", match: true },
  { code: "2104", match: true },
  { code: "2105", match: true },
  { code: "2106", match: true },
  { code: "2107", match: true },
  { code: "2108", match: true },
  { code: "2109", match: true },
  { code: "2110", match: true },
  { code: "2111", match: true },
  { code: "2112", match: true },
  { code: "2113", match: true },
  { code: "2114", match: true },
  { code: "2115", match: true },
  { code: "2116", match: true },
  { code: "2117", match: true },
  { code: "2118", match: true },
  { code: "2119", match: true },
  { code: "2120", match: true },
  { code: "2121", match: true },
  { code: "2122", match: true },
  { code: "2123", match: true },
  { code: "2124", match: true },
  { code: "2125", match: true },
  { code: "2126", match: true },
  { code: "2127", match: true },
  { code: "2128", match: true },
  { code: "2129", match: true },
  { code: "2130", match: true },
  { code: "2131", match: true },
  { code: "2132", match: true },
  { code: "2133", match: true },
  { code: "2134", match: true },
  { code: "2135", match: true },
  { code: "2136", match: false },
  { code: "2137", match: false },
  { code: "2138", match: true },
  { code: "2139", match: true },
  { code: "2140", match: true },
  { code: "2141", match: true },
  { code: "2142", match: true },
  { code: "2143", match: true },
  { code: "2144", match: true },
  { code: "2145", match: true },
  { code: "2146", match: true },
  { code: "2147", match: true },
  { code: "2148", match: true },
  { code: "2149", match: true },
  { code: "2150", match: true },
  { code: "2151", match: true },
  { code: "2152", match: true },
  { code: "2153", match: true },
  { code: "2154", match: true },
  { code: "2155", match: false },
  { code: "2156", match: true },
  { code: "2157", match: true },
  { code: "2158", match: true },
  { code: "2159", match: true },
  { code: "2160", match: true },
  { code: "2161", match: true },
  { code: "2162", match: true },
  { code: "2163", match: false },
  { code: "2164", match: true },
  { code: "2165", match: false },
  { code: "2166", match: true },
  { code: "2167", match: true },
  { code: "2168", match: true },
  { code: "2169", match: true },
  { code: "2170", match: false },
  { code: "2171", match: true },
  { code: "2172", match: true },
  { code: "2173", match: true },
  { code: "2174", match: true },
  { code: "2175", match: true },
  { code: "2176", match: true },
  { code: "2177", match: true },
  { code: "2178", match: true },
  { code: "2179", match: true },
  { code: "2180", match: true },
  { code: "2188", match: true },
  { code: "2189", match: true },
  { code: "2190", match: true },
  { code: "2191", match: true },
  { code: "2192", match: true },
  { code: "2193", match: true },
  { code: "2194", match: false },
  { code: "2195", match: true },
  { code: "2196", match: true },
  { code: "2197", match: true },
  { code: "2198", match: true },
  { code: "2199", match: true },
  { code: "2200", match: true },
  { code: "2201", match: true },
  { code: "2202", match: true },
  { code: "2203", match: true },
  { code: "2204", match: true },
  { code: "2205", match: true },
  { code: "2206", match: true },
  { code: "2207", match: true },
  { code: "2208", match: false },
  { code: "2209", match: true },
  { code: "2210", match: true },
  { code: "2211", match: true },
  { code: "2212", match: true },
  { code: "2213", match: true },
  { code: "2214", match: true },
  { code: "2215", match: false },
  { code: "2216", match: false },
  { code: "2217", match: false },
  { code: "2218", match: false },
  { code: "2219", match: true },
  { code: "2220", match: true },
  { code: "2221", match: false },
  { code: "2222", match: true },
  { code: "2223", match: true },
  { code: "2224", match: true },
  { code: "2225", match: true },
  { code: "2226", match: true },
  { code: "2227", match: true },
  { code: "2228", match: true },
  { code: "2229", match: true },
  { code: "2230", match: true },
  { code: "2231", match: true },
  { code: "2232", match: true },
  { code: "2233", match: true },
  { code: "2234", match: true },
  { code: "2235", match: true },
  { code: "2236", match: true },
  { code: "2237", match: true },
  { code: "2238", match: true },
  { code: "2239", match: true },
  { code: "2240", match: true },
  { code: "2241", match: true },
  { code: "2242", match: true },
  { code: "2243", match: true },
  { code: "2244", match: true },
  { code: "2245", match: true },
  { code: "2246", match: true },
  { code: "2247", match: true },
  { code: "2248", match: true },
  { code: "2249", match: true },
  { code: "2250", match: true },
  { code: "2251", match: true },
  { code: "2252", match: true },
  { code: "2253", match: true },
  { code: "2254", match: true },
  { code: "2255", match: true },
  { code: "2256", match: true },
  { code: "2257", match: true },
  { code: "2258", match: true },
  { code: "2259", match: true },
  { code: "2260", match: true },
  { code: "2261", match: true },
  { code: "2262", match: true },
  { code: "2263", match: true },
  { code: "2264", match: true },
  { code: "2265", match: true },
  { code: "2266", match: true },
  { code: "2267", match: true },
  { code: "2268", match: true },
  { code: "2269", match: true },
  { code: "2270", match: true },
  { code: "2271", match: true },
  { code: "2272", match: true },
  { code: "2273", match: true },
  { code: "2274", match: true },
  { code: "2275", match: true },
  { code: "2276", match: true },
  { code: "2277", match: true },
  { code: "2278", match: true },
  { code: "2279", match: true },
  { code: "2280", match: true },
  { code: "2281", match: true },
  { code: "2282", match: true },
  { code: "2283", match: true },
  { code: "2284", match: true },
  { code: "2285", match: true },
  { code: "2286", match: true },
  { code: "2287", match: true },
  { code: "2288", match: true },
  { code: "2289", match: true },
  { code: "2290", match: true },
  { code: "2291", match: true },
  { code: "2292", match: true },
  { code: "2294", match: true },
  { code: "2295", match: true },
  { code: "2296", match: false },
  { code: "2297", match: false },
  { code: "2298", match: false },
  { code: "2299", match: false },
  { code: "2300", match: false },
  { code: "2301", match: false },
  { code: "2302", match: false },
  { code: "2303", match: false },
  { code: "2304", match: false },
  { code: "2305", match: false },
  { code: "2306", match: false },
  { code: "2307", match: false },
  { code: "2308", match: false },
  { code: "2309", match: true },
  { code: "2310", match: true },
  { code: "2311", match: true },
  { code: "2312", match: false },
  { code: "2313", match: true },
  { code: "2314", match: false },
  { code: "2315", match: true },
  { code: "2316", match: true },
  { code: "2317", match: true },
  { code: "2318", match: true },
  { code: "2319", match: true },
  { code: "2320", match: true },
  { code: "2321", match: false },
  { code: "2322", match: true },
  { code: "2323", match: true },
  { code: "2324", match: true },
  { code: "2325", match: true },
  { code: "2326", match: true },
  { code: "2327", match: true },
  { code: "2328", match: true },
  { code: "2329", match: true },
  { code: "2330", match: true },
  { code: "2331", match: true },
  { code: "2332", match: true },
  { code: "2333", match: true },
  { code: "2334", match: true },
  { code: "2335", match: true },
  { code: "2336", match: true },
  { code: "2337", match: true },
  { code: "2338", match: true },
  { code: "2339", match: true },
  { code: "2340", match: true },
  { code: "2341", match: true },
  { code: "2342", match: true },
  { code: "2343", match: true },
  { code: "2344", match: true },
  { code: "2345", match: true },
  { code: "2346", match: true },
  { code: "2347", match: true },
  { code: "2348", match: true },
  { code: "2349", match: true },
  { code: "2350", match: true },
  { code: "2351", match: true },
  { code: "2352", match: true },
  { code: "2353", match: true },
  { code: "2354", match: true },
  { code: "2355", match: true },
  { code: "2356", match: true },
  { code: "2357", match: true },
  { code: "2358", match: true },
  { code: "2359", match: true },
  { code: "2360", match: true },
  { code: "2361", match: true },
  { code: "2362", match: true },
  { code: "2363", match: true },
  { code: "2364", match: true },
  { code: "2365", match: true },
  { code: "2366", match: true },
  { code: "2367", match: true },
  { code: "2368", match: true },
  { code: "2369", match: true },
  { code: "2370", match: true },
  { code: "2371", match: true },
  { code: "2372", match: true },
  { code: "2373", match: true },
  { code: "2374", match: true },
  { code: "2375", match: true },
  { code: "2376", match: true },
  { code: "2377", match: true },
  { code: "2378", match: true },
  { code: "2379", match: true },
  { code: "2380", match: true },
  { code: "2381", match: true },
  { code: "2382", match: true },
  { code: "2383", match: true },
  { code: "2384", match: true },
  { code: "2385", match: true },
  { code: "2386", match: true },
  { code: "2387", match: true },
  { code: "2388", match: true },
  { code: "2389", match: true },
  { code: "2390", match: true },
  { code: "2391", match: true },
  { code: "2392", match: true },
  { code: "2393", match: true },
  { code: "2393", match: true },
  { code: "2394", match: true },
  { code: "2395", match: true },
  { code: "2396", match: true },
  { code: "2397", match: true },
  { code: "2398", match: true },
  { code: "2399", match: true },
  { code: "2400", match: true },
  { code: "2401", match: true },
  { code: "2402", match: true },
  { code: "2403", match: true },
  { code: "2404", match: true },
  { code: "2405", match: true },
  { code: "2406", match: true },
  { code: "2407", match: true },
  { code: "2408", match: true },
  { code: "2409", match: true },
  { code: "2410", match: true },
  { code: "2411", match: true },
  { code: "2412", match: true },
  { code: "2413", match: true },
  { code: "2414", match: true },
  { code: "2415", match: true },
  { code: "2416", match: true },
  { code: "2417", match: true },
  { code: "2418", match: true },
  { code: "2419", match: true },
  { code: "2420", match: true },
  { code: "2421", match: true },
  { code: "2422", match: true },
  { code: "2423", match: true },
  { code: "2424", match: true },
  { code: "2425", match: true },
  { code: "2426", match: true },
  { code: "2427", match: true },
  { code: "2428", match: true },
  { code: "2429", match: true },
  { code: "2430", match: true },
  { code: "2431", match: true },
  { code: "2432", match: true },
  { code: "2433", match: true },
  { code: "2434", match: true },
  { code: "2435", match: true },
  { code: "2436", match: true },
  { code: "2437", match: true },
  { code: "2438", match: true },
  { code: "2439", match: true },
  { code: "2440", match: true },
  { code: "2441", match: true },
  { code: "2442", match: true },
  { code: "2443", match: true },
  { code: "2444", match: true },
  { code: "2445", match: true },
  { code: "2446", match: true },
  { code: "2447", match: true },
  { code: "2448", match: true },
  { code: "2449", match: true },
  { code: "2450", match: true },
  { code: "2451", match: true },
  { code: "2452", match: true },
  { code: "2453", match: true },
  { code: "2454", match: true },
  { code: "2455", match: true },
  { code: "2456", match: true },
  { code: "2457", match: true },
  { code: "2458", match: true },
  { code: "2459", match: true },
  { code: "2460", match: true },
  { code: "2461", match: true },
  { code: "2462", match: true },
  { code: "2463", match: true },
  { code: "2464", match: true },
  { code: "2465", match: true },
  { code: "2466", match: true },
  { code: "2467", match: true },
  { code: "2468", match: true },
  { code: "2469", match: true },
  { code: "2470", match: true },
  { code: "2471", match: true },
  { code: "2472", match: true },
  { code: "2473", match: true },
  { code: "2474", match: true },
  { code: "2475", match: true },
  { code: "2476", match: true },
  { code: "2477", match: true },
  { code: "2478", match: true },
  { code: "2479", match: true },
  { code: "2480", match: true },
  { code: "2481", match: true },
  { code: "2482", match: true },
  { code: "2483", match: true },
  { code: "2484", match: true },
  { code: "2485", match: true },
  { code: "2486", match: true },
  { code: "2487", match: true },
  { code: "2488", match: true },
  { code: "2489", match: true },
  { code: "2490", match: true },
  { code: "2491", match: true },
  { code: "2492", match: true },
  { code: "2493", match: true },
  { code: "2494", match: true },
  { code: "2495", match: true },
  { code: "2496", match: true },
  { code: "2497", match: true },
  { code: "2498", match: true },
  { code: "2499", match: true },
  { code: "2500", match: true },
  { code: "2501", match: true },
  { code: "2502", match: true },
  { code: "2503", match: true },
  { code: "2504", match: true },
  { code: "2505", match: true },
  { code: "2506", match: false },
  { code: "2507", match: false },
  { code: "2508", match: false },
  { code: "2509", match: true },
  { code: "2510", match: true },
  { code: "2511", match: true },
  { code: "2512", match: true },
  { code: "2513", match: true },
  { code: "2514", match: true },
  { code: "2515", match: true },
  { code: "2516", match: true },
  { code: "2517", match: false },
  { code: "2518", match: false },
  { code: "2519", match: false },
  { code: "2520", match: false },
  { code: "2521", match: false },
  { code: "2522", match: false },
  { code: "2523", match: true },
  { code: "2524", match: true },
  { code: "2525", match: true },
  { code: "2526", match: true },
  { code: "2527", match: true },
  { code: "2528", match: true },
  { code: "2529", match: true },
  { code: "2530", match: true },
  { code: "2531", match: true },
  { code: "2532", match: true },
  { code: "2533", match: true },
  { code: "2534", match: true },
  { code: "2535", match: true },
  { code: "2536", match: true },
  { code: "2537", match: true },
  { code: "2538", match: true },
  { code: "2539", match: true },
  { code: "2540", match: true },
  { code: "2541", match: true },
  { code: "2542", match: true },
  { code: "2543", match: true },
  { code: "2544", match: true },
  { code: "2545", match: true },
  { code: "2546", match: true },
  { code: "2547", match: false },
  { code: "2548", match: false },
  { code: "2549", match: false },
  { code: "2550", match: false },
  { code: "2551", match: false },
  { code: "2552", match: false },
  { code: "2553", match: true },
  { code: "2554", match: true },
  { code: "2555", match: true },
  { code: "2556", match: true },
  { code: "2557", match: true },
  { code: "2558", match: true },
  { code: "2559", match: true },
  { code: "2560", match: true },
  { code: "2561", match: true },
  { code: "2562", match: true },
  { code: "2563", match: true },
  { code: "2564", match: true },
  { code: "2565", match: true },
  { code: "2566", match: true },
  { code: "2567", match: true },
  { code: "2568", match: true },
  { code: "2569", match: true },
  { code: "2570", match: false },
  { code: "2571", match: false },
  { code: "2572", match: false },
  { code: "2573", match: false },
  { code: "2574", match: false },
  { code: "2575", match: false },
  { code: "2576", match: false },
  { code: "2577", match: false },
  { code: "2578", match: false },
  { code: "2579", match: false },
  { code: "2580", match: false },
  { code: "2581", match: false },
  { code: "2582", match: true },
  { code: "2583", match: true },
  { code: "2584", match: true },
  { code: "2585", match: true },
  { code: "2586", match: true },
  { code: "2587", match: true },
  { code: "2588", match: true },
  { code: "2589", match: true },
  { code: "2590", match: true },
  { code: "2591", match: true },
  { code: "2592", match: true },
  { code: "2593", match: true },
  { code: "2594", match: true },
  { code: "2595", match: true },
  { code: "2596", match: true },
  { code: "2597", match: true },
  { code: "2598", match: true },
  { code: "2599", match: true },
  { code: "2600", match: true },
  { code: "2601", match: true },
  { code: "2602", match: true },
  { code: "2603", match: true },
  { code: "2604", match: true },
  { code: "2605", match: true },
  { code: "2606", match: true },
  { code: "2607", match: false },
  { code: "2608", match: false },
  { code: "2609", match: false },
  { code: "2610", match: false },
  { code: "2611", match: false },
  { code: "2612", match: true },
  { code: "2613", match: true },
  { code: "2614", match: true },
  { code: "2615", match: true },
  { code: "2616", match: true },
  { code: "2617", match: true },
  { code: "2618", match: true },
  { code: "2619", match: true },
  { code: "2620", match: true },
  { code: "2621", match: true },
  { code: "2622", match: true },
  { code: "2623", match: true },
  { code: "2624", match: true },
  { code: "2625", match: true },
  { code: "2626", match: true },
  { code: "2627", match: true },
  { code: "2628", match: true },
  { code: "2629", match: false },
  { code: "2630", match: false },
  { code: "2631", match: false },
  { code: "2632", match: false },
  { code: "2633", match: false },
  { code: "2634", match: false },
  { code: "2635", match: false },
  { code: "2636", match: false },
  { code: "2637", match: false },
  { code: "2638", match: false },
  { code: "2639", match: false },
  { code: "2640", match: false },
  { code: "2641", match: true },
  { code: "2642", match: true },
  { code: "2643", match: true },
  { code: "2644", match: true },
  { code: "2645", match: true },
  { code: "2646", match: true },
  { code: "2647", match: true },
  { code: "2648", match: true },
  { code: "2649", match: true },
  { code: "2650", match: true },
  { code: "2651", match: true },
  { code: "2652", match: true },
  { code: "2653", match: true },
  { code: "2654", match: true },
  { code: "2655", match: true },
  { code: "2656", match: true },
  { code: "2657", match: true },
  { code: "2658", match: true },
  { code: "2659", match: true },
  { code: "2660", match: true },
  { code: "2661", match: true },
  { code: "2662", match: true },
  { code: "2663", match: true },
  { code: "2664", match: true },
  { code: "2665", match: true },
  { code: "2666", match: true },
  { code: "2667", match: true },
  { code: "2668", match: true },
  { code: "2669", match: true },
  { code: "2670", match: true },
  { code: "2671", match: true },
  { code: "2672", match: true },
  { code: "2673", match: true },
  { code: "2674", match: true },
  { code: "2675", match: true },
  { code: "2676", match: true },
  { code: "2677", match: true },
  { code: "2678", match: true },
  { code: "2679", match: true },
  { code: "2680", match: true },
  { code: "2681", match: true },
  { code: "2682", match: true },
  { code: "2683", match: true },
  { code: "2684", match: true },
  { code: "2685", match: true },
  { code: "2686", match: true },
  { code: "2687", match: true },
  { code: "2688", match: true },
  { code: "2689", match: true },
  { code: "2690", match: true },
  { code: "2691", match: true },
  { code: "2692", match: true },
  { code: "2693", match: true },
  { code: "2694", match: true },
  { code: "2695", match: true },
  { code: "2696", match: true },
  { code: "2697", match: true },
  { code: "2698", match: true },
  { code: "2699", match: true },
  { code: "2700", match: true },
  { code: "2701", match: true },
  { code: "2702", match: true },
  { code: "2703", match: true },
  { code: "2704", match: true },
  { code: "2705", match: true },
  { code: "2706", match: true },
  { code: "2707", match: true },
  { code: "2708", match: true },
  { code: "2709", match: true },
  { code: "2710", match: true },
  { code: "2711", match: true },
  { code: "2712", match: true },
  { code: "2713", match: true },
  { code: "2714", match: true },
  { code: "2715", match: true },
  { code: "2716", match: true },
  { code: "2717", match: true },
  { code: "2718", match: true },
  { code: "2719", match: true },
  { code: "2720", match: true },
  { code: "2721", match: true },
  { code: "2722", match: true },
  { code: "2723", match: true },
  { code: "2724", match: true },
  { code: "2725", match: true },
  { code: "2726", match: true },
  { code: "2727", match: true },
  { code: "2728", match: true },
  { code: "2729", match: true },
  { code: "2730", match: true },
  { code: "2731", match: true },
  { code: "2732", match: true },
  { code: "2733", match: true },
  { code: "2734", match: true },
  { code: "2735", match: true },
  { code: "2736", match: false },
  { code: "2737", match: false },
  { code: "2738", match: true },
  { code: "2739", match: true },
  { code: "2740", match: true },
  { code: "2741", match: true },
  { code: "2742", match: true },
  { code: "2743", match: true },
  { code: "2744", match: true },
  { code: "2745", match: true },
  { code: "2746", match: true },
  { code: "2747", match: true },
  { code: "2748", match: true },
  { code: "2749", match: true },
  { code: "2750", match: true },
  { code: "2751", match: true },
  { code: "2752", match: true },
  { code: "2753", match: true },
  { code: "2754", match: true },
  { code: "2755", match: true },
  { code: "2756", match: true },
  { code: "2757", match: true },
  { code: "2758", match: true },
  { code: "2759", match: true },
  { code: "2760", match: true },
  { code: "2761", match: true },
  { code: "2762", match: true },
  { code: "2763", match: true },
  { code: "2764", match: true },
  { code: "2765", match: true },
  { code: "2766", match: true },
  { code: "2767", match: true },
  { code: "2768", match: true },
  { code: "2769", match: true },
  { code: "2770", match: true },
  { code: "2771", match: true },
  { code: "2772", match: true },
  { code: "2773", match: true },
  { code: "2774", match: true },
  { code: "2775", match: true },
  { code: "2776", match: true },
  { code: "2777", match: true },
  { code: "2778", match: true },
  { code: "2779", match: true },
  { code: "2780", match: true },
  { code: "2781", match: true },
  { code: "2782", match: true },
  { code: "2783", match: true },
  { code: "2784", match: true },
  { code: "2785", match: true },
  { code: "2786", match: true },
  { code: "2787", match: true },
  { code: "2788", match: true },
  { code: "2789", match: true },
  { code: "2790", match: true },
  { code: "2791", match: true },
  { code: "2792", match: true },
  { code: "2793", match: true },
  { code: "2794", match: true },
  { code: "2795", match: true },
  { code: "2796", match: true },
  { code: "2797", match: true },
  { code: "2798", match: true },
  { code: "2799", match: true },
  { code: "2800", match: true },
  { code: "2801", match: true },
  { code: "2802", match: true },
  { code: "2803", match: true },
  { code: "2804", match: true },
  { code: "2805", match: true },
  { code: "2806", match: true },
  { code: "2807", match: true },
  { code: "2808", match: true },
  { code: "2809", match: true },
  { code: "2810", match: true },
  { code: "2811", match: true },
  { code: "2812", match: true },
  { code: "2813", match: true },
  { code: "2814", match: true },
  { code: "2815", match: true },
  { code: "2816", match: true },
  { code: "2817", match: true },
  { code: "2818", match: true },
  { code: "2819", match: true },
  { code: "2820", match: true },
  { code: "2821", match: true },
  { code: "2822", match: true },
  { code: "2823", match: true },
  { code: "2824", match: true },
  { code: "2825", match: true },
  { code: "2826", match: true },
  { code: "2827", match: true },
  { code: "2828", match: true },
  { code: "2829", match: true },
  { code: "2830", match: true },
  { code: "2831", match: true },
  { code: "2832", match: true },
  { code: "2833", match: true },
  { code: "2834", match: true },
  { code: "2835", match: true },
  { code: "2836", match: true },
  { code: "2837", match: true },
  { code: "2838", match: true },
  { code: "2839", match: true },
  { code: "2840", match: true },
  { code: "2841", match: true },
  { code: "2842", match: true },
  { code: "2843", match: true },
  { code: "2844", match: true },
  { code: "2845", match: true },
  { code: "2846", match: true },
  { code: "2847", match: true },
  { code: "2848", match: true },
  { code: "2849", match: true },
  { code: "2850", match: true },
  { code: "2851", match: true },
  { code: "2852", match: true },
  { code: "2853", match: true },
  { code: "2854", match: true },
  { code: "2855", match: true },
  { code: "2856", match: true },
  { code: "2857", match: true },
  { code: "2858", match: true },
  { code: "2859", match: true },
  { code: "2860", match: true },
  { code: "2861", match: true },
  { code: "2862", match: true },
  { code: "2863", match: true },
  { code: "2864", match: true },
  { code: "2865", match: true },
  { code: "2866", match: true },
  { code: "2867", match: true },
  { code: "2868", match: true },
  { code: "2869", match: true },
  { code: "2870", match: true },
  { code: "2871", match: true },
  { code: "2872", match: true },
  { code: "2873", match: true },
  { code: "2874", match: true },
  { code: "2875", match: true },
  { code: "2876", match: true },
  { code: "2877", match: true },
  { code: "2878", match: true },
  { code: "2879", match: true },
  { code: "2880", match: true },
  { code: "2881", match: true },
  { code: "2882", match: true },
  { code: "2883", match: true },
  { code: "2884", match: true },
  { code: "2885", match: true },
  { code: "2886", match: true },
  { code: "2887", match: true },
  { code: "2888", match: true },
  { code: "2889", match: true },
  { code: "2890", match: true },
  { code: "2891", match: true },
  { code: "2892", match: true },
  { code: "2893", match: true },
  { code: "2894", match: true },
  { code: "2895", match: true },
  { code: "2896", match: true },
  { code: "2897", match: true },
  { code: "2898", match: true },
  { code: "2899", match: true },
  { code: "2900", match: true },
  { code: "2901", match: true },
  { code: "2902", match: true },
  { code: "2903", match: true },
  { code: "2904", match: true },
  { code: "2905", match: true },
  { code: "2906", match: true },
  { code: "2907", match: true },
  { code: "2908", match: true },
  { code: "2909", match: true },
  { code: "2910", match: true },
  { code: "2911", match: true },
  { code: "2912", match: true },
  { code: "2913", match: true },
  { code: "2914", match: true },
  { code: "2915", match: true },
  { code: "2916", match: true },
  { code: "2917", match: true },
  { code: "2918", match: true },
  { code: "2919", match: true },
  { code: "2920", match: true },
  { code: "2921", match: true },
  { code: "2922", match: true },
  { code: "2923", match: true },
  { code: "2924", match: true },
  { code: "2925", match: true },
  { code: "2926", match: true },
  { code: "2927", match: true },
  { code: "2928", match: true },
  { code: "2929", match: true },
  { code: "2930", match: true },
  { code: "2931", match: true },
  { code: "2932", match: true },
  { code: "2933", match: false },
  { code: "2934", match: false },
  { code: "2935", match: true },
  { code: "2936", match: true },
  { code: "2937", match: true },
  { code: "2938", match: true },
  { code: "2939", match: true },
  { code: "2940", match: true },
  { code: "2941", match: true },
  { code: "2942", match: true },
  { code: "2943", match: true },
  { code: "2944", match: true },
  { code: "2945", match: true },
  { code: "2946", match: true },
  { code: "2947", match: true },
  { code: "2948", match: true },
  { code: "2949", match: true },
  { code: "2950", match: true },
  { code: "2951", match: true },
  { code: "2952", match: true },
  { code: "2953", match: true },
  { code: "2954", match: true },
  { code: "2955", match: true },
  { code: "2956", match: true },
  { code: "2957", match: true },
  { code: "2958", match: true },
  { code: "2959", match: true },
  { code: "2960", match: true },
  { code: "2961", match: true },
  { code: "2962", match: true },
  { code: "2963", match: false },
  { code: "2964", match: true },
  { code: "2965", match: true },
  { code: "2966", match: true },
  { code: "2967", match: true },
  { code: "2968", match: true },
  { code: "2969", match: false },
  { code: "2970", match: false },
  { code: "2971", match: true },
  { code: "2972", match: true },
  { code: "2973", match: false },
  { code: "2975", match: true },
  { code: "2976", match: false },
  { code: "2977", match: false },
  { code: "2978", match: false },
  { code: "2979", match: true },
  { code: "2980", match: false },
  { code: "2981", match: false },
  { code: "2982", match: false },
  { code: "2983", match: true },
  { code: "2984", match: true },
  { code: "2985", match: false },
  { code: "2986", match: false },
  { code: "2987", match: true },
  { code: "2988", match: false },
  { code: "2989", match: true },
  { code: "2990", match: false },
  { code: "2991", match: true },
  { code: "2992", match: true },
  { code: "2993", match: true },
  { code: "2994", match: true },
  { code: "2995", match: false },
  { code: "2996", match: false },
  { code: "2997", match: false },
  { code: "2998", match: false },
  { code: "2999", match: false },
  { code: "3000", match: false },
  { code: "3001", match: false },
  { code: "3002", match: false },
  { code: "3003", match: true },
  { code: "3004", match: true },
  { code: "3005", match: true },
  { code: "3006", match: true },
  { code: "3007", match: true },
  { code: "3008", match: true },
  { code: "3009", match: true },
  { code: "3010", match: true },
  { code: "3011", match: true },
  { code: "3012", match: true },
  { code: "3013", match: true },
  { code: "3014", match: true },
  { code: "3015", match: true },
  { code: "3016", match: true },
  { code: "3017", match: true },
  { code: "3018", match: true },
  { code: "3019", match: true },
  { code: "3020", match: true },
  { code: "3021", match: true },
  { code: "3021", match: false },
  { code: "3022", match: true },
  { code: "3023", match: true },
  { code: "3024", match: true },
  { code: "3025", match: true },
  { code: "3026", match: true },
  { code: "3027", match: true },
  { code: "3027", match: true },
  { code: "3028", match: true },
  { code: "3029", match: true },
  { code: "3030", match: true },
  { code: "3031", match: true },
  { code: "3032", match: true },
  { code: "3033", match: true },
  { code: "3034", match: true },
  { code: "3035", match: true },
  { code: "3036", match: true },
  { code: "3037", match: true },
  { code: "3038", match: true },
  { code: "3039", match: true },
  { code: "3040", match: true },
  { code: "3041", match: true },
  { code: "3042", match: true },
  { code: "3043", match: true },
  { code: "3044", match: true },
  { code: "3045", match: true },
  { code: "3046", match: true },
  { code: "3047", match: true },
  { code: "3048", match: true },
  { code: "3049", match: true },
  { code: "3050", match: true },
  { code: "3051", match: true },
  { code: "3052", match: false },
  { code: "3053", match: false },
  { code: "3054", match: true },
  { code: "3055", match: true },
  { code: "3056", match: true },
  { code: "3057", match: true },
  { code: "3058", match: false },
  { code: "3059", match: true },
  { code: "3060", match: false },
  { code: "3061", match: true },
  { code: "3062", match: true },
  { code: "3063", match: true },
  { code: "3064", match: true },
  { code: "3065", match: true },
  { code: "3066", match: true },
  { code: "3067", match: true },
  { code: "3068", match: true },
  { code: "3069", match: true },
  { code: "3070", match: true },
  { code: "3071", match: true },
  { code: "3072", match: true },
  { code: "3073", match: true },
  { code: "3074", match: true },
  { code: "3075", match: true },
  { code: "3076", match: true },
  { code: "3077", match: true },
  { code: "3078", match: true },
  { code: "3079", match: true },
  { code: "3080", match: true },
  { code: "3081", match: true },
  { code: "3082", match: true },
  { code: "3083", match: true },
  { code: "3084", match: true },
  { code: "3085", match: true },
  { code: "3086", match: true },
  { code: "3087", match: true },
  { code: "3088", match: true },
  { code: "3089", match: true },
  { code: "3090", match: true },
  { code: "3091", match: true },
  { code: "3092", match: false },
  { code: "3093", match: false },
  { code: "3094", match: false },
  { code: "3095", match: false },
  { code: "3096", match: false },
  { code: "3097", match: true },
  { code: "3098", match: true },
  { code: "3099", match: true },
  { code: "3100", match: true },
  { code: "3101", match: true },
  { code: "3102", match: false },
  { code: "3103", match: false },
  { code: "3104", match: false },
  { code: "3105", match: false },
  { code: "3106", match: false },
  { code: "3106", match: false },
  { code: "3107", match: true },
  { code: "3108", match: true },
  { code: "3109", match: true },
  { code: "3110", match: true },
  { code: "3111", match: true },
  { code: "3112", match: true },
  { code: "3113", match: true },
  { code: "3114", match: true },
  { code: "3115", match: true },
  { code: "3116", match: true },
  { code: "3117", match: true },
  { code: "3118", match: true },
  { code: "3119", match: true },
  { code: "3120", match: true },
  { code: "3121", match: false },
  { code: "3122", match: false },
  { code: "3123", match: false },
  { code: "3124", match: false },
  { code: "3125", match: false },
  { code: "3126", match: true },
  { code: "3127", match: true },
  { code: "3128", match: true },
  { code: "3129", match: true },
  { code: "3130", match: true },
  { code: "3131", match: true },
  { code: "3132", match: true },
  { code: "3133", match: true },
  { code: "3134", match: true },
  { code: "3135", match: true },
  { code: "3136", match: true },
  { code: "3137", match: true },
  { code: "3138", match: true },
  { code: "3139", match: false },
  { code: "3140", match: false },
  { code: "3141", match: false },
  { code: "3142", match: false },
  { code: "3143", match: true },
  { code: "3144", match: false },
  { code: "3145", match: false },
  { code: "3146", match: true },
  { code: "3147", match: true },
  { code: "3148", match: true },
  { code: "3149", match: true },
  { code: "3150", match: true },
  { code: "3151", match: true },
  { code: "3152", match: true },
  { code: "3153", match: true },
  { code: "3154", match: true },
  { code: "3155", match: true },
  { code: "3156", match: true },
  { code: "3157", match: true },
  { code: "3158", match: true },
  { code: "3159", match: true },
  { code: "3160", match: true },
  { code: "3161", match: true },
  { code: "3162", match: true },
  { code: "3163", match: true },
  { code: "3164", match: true },
  { code: "3165", match: false },
  { code: "3166", match: false },
  { code: "3167", match: false },
  { code: "3168", match: false },
  { code: "3169", match: true },
  { code: "3170", match: true },
  { code: "3171", match: true },
  { code: "3172", match: false },
  { code: "3173", match: false },
  { code: "3174", match: true },
  { code: "3175", match: true },
  { code: "3176", match: false },
  { code: "3177", match: true },
  { code: "3178", match: true },
  { code: "3179", match: true },
  { code: "3180", match: true },
  { code: "3181", match: true },
  { code: "3182", match: true },
  { code: "3183", match: true },
  { code: "3184", match: true },
  { code: "3185", match: true },
  { code: "3186", match: true },
  { code: "3187", match: true },
  { code: "3188", match: true },
  { code: "3189", match: true },
  { code: "3190", match: true },
  { code: "3191", match: true },
  { code: "3192", match: true },
  { code: "3193", match: true },
  { code: "3194", match: true },
  { code: "3195", match: true },
  { code: "3196", match: true },
  { code: "3197", match: true },
  { code: "3198", match: true },
  { code: "3199", match: true },
  { code: "3200", match: true },
  { code: "3201", match: true },
  { code: "3202", match: true },
  { code: "3203", match: true },
  { code: "3204", match: true },
  { code: "3205", match: true },
  { code: "3206", match: true },
  { code: "3207", match: true },
  { code: "3208", match: true },
  { code: "3209", match: true },
  { code: "3210", match: true },
  { code: "3211", match: true },
  { code: "3212", match: true },
  { code: "3213", match: true },
  { code: "3214", match: true },
  { code: "3215", match: true },
  { code: "3216", match: true },
  { code: "3217", match: true },
  { code: "3218", match: true },
  { code: "3219", match: true },
  { code: "3220", match: true },
  { code: "3221", match: true },
  { code: "3222", match: true },
  { code: "3223", match: true },
  { code: "3224", match: true },
  { code: "3225", match: true },
  { code: "3226", match: true },
  { code: "3227", match: true },
  { code: "3228", match: true },
  { code: "3229", match: true },
  { code: "3230", match: true },
  { code: "3231", match: true },
  { code: "3232", match: true },
  { code: "3233", match: true },
  { code: "3234", match: true },
  { code: "3235", match: true },
  { code: "3236", match: true },
  { code: "3237", match: true },
  { code: "3238", match: true },
  { code: "3239", match: true },
  { code: "3240", match: true },
  { code: "3241", match: true },
  { code: "3242", match: true },
  { code: "3243", match: true },
  { code: "3244", match: true },
  { code: "3245", match: true },
  { code: "3246", match: true },
  { code: "3247", match: true },
  { code: "3248", match: true },
  { code: "3249", match: true },
  { code: "3250", match: true },
  { code: "3251", match: true },
  { code: "3252", match: true },
  { code: "3253", match: true },
  { code: "3254", match: true },
  { code: "3255", match: true },
  { code: "3256", match: true },
  { code: "3257", match: true },
  { code: "3258", match: true },
  { code: "3259", match: true },
  { code: "3260", match: true },
  { code: "3261", match: true },
  { code: "3262", match: true },
  { code: "3263", match: true },
  { code: "3264", match: true },
  { code: "3265", match: true },
  { code: "3266", match: true },
  { code: "3267", match: true },
  { code: "3268", match: true },
  { code: "3269", match: true },
  { code: "3270", match: true },
  { code: "3271", match: true },
  { code: "3272", match: true },
  { code: "3273", match: true },
  { code: "3274", match: true },
  { code: "3275", match: true },
  { code: "3276", match: true },
  { code: "3277", match: true },
  { code: "3278", match: true },
  { code: "3279", match: true },
  { code: "3280", match: true },
  { code: "3281", match: true },
  { code: "3282", match: true },
  { code: "3283", match: true },
  { code: "3284", match: true },
  { code: "3285", match: true },
  { code: "3286", match: true },
  { code: "3287", match: true },
  { code: "3288", match: true },
  { code: "3289", match: true },
  { code: "3290", match: true },
  { code: "3291", match: true },
  { code: "3292", match: true },
  { code: "3293", match: true },
  { code: "3294", match: true },
  { code: "3295", match: true },
  { code: "3296", match: true },
  { code: "3297", match: true },
  { code: "3298", match: true },
  { code: "3299", match: true },
  { code: "3300", match: true },
  { code: "3301", match: true },
  { code: "3302", match: true },
  { code: "3303", match: false },
  { code: "3304", match: false },
  { code: "3305", match: false },
  { code: "3306", match: false },
  { code: "3307", match: true },
  { code: "3308", match: true },
  { code: "3309", match: true },
  { code: "3310", match: true },
  { code: "3311", match: true },
  { code: "3312", match: true },
  { code: "3313", match: true },
  { code: "3314", match: true },
  { code: "3315", match: true },
  { code: "3316", match: true },
  { code: "3317", match: true },
  { code: "3318", match: true },
  { code: "3319", match: true },
  { code: "3320", match: true },
  { code: "3321", match: true },
  { code: "3322", match: true },
  { code: "3323", match: true },
  { code: "3324", match: true },
  { code: "3325", match: true },
  { code: "3326", match: true },
  { code: "3327", match: true },
  { code: "3328", match: true },
  { code: "3329", match: true },
  { code: "3330", match: true },
  { code: "3331", match: true },
  { code: "3332", match: true },
  { code: "3333", match: true },
  { code: "3334", match: true },
  { code: "3335", match: true },
  { code: "3336", match: true },
  { code: "3337", match: false },
  { code: "3338", match: true },
  { code: "3339", match: false },
  { code: "3340", match: false },
  { code: "3341", match: false },
  { code: "3342", match: false },
  { code: "3343", match: true },
  { code: "3344", match: true },
  { code: "3345", match: true },
  { code: "3346", match: true },
  { code: "3347", match: true },
  { code: "3348", match: true },
  { code: "3349", match: true },
  { code: "3350", match: true },
  { code: "3351", match: true },
  { code: "3352", match: true },
  { code: "3353", match: true },
  { code: "3354", match: true },
  { code: "3355", match: true },
  { code: "3356", match: false },
  { code: "3357", match: true },
  { code: "3358", match: true },
  { code: "3359", match: true },
  { code: "3360", match: true },
  { code: "3361", match: true },
  { code: "3362", match: true },
  { code: "3363", match: true },
  { code: "3364", match: true },
  { code: "3365", match: true },
  { code: "3366", match: false },
  { code: "3367", match: true },
  { code: "3368", match: true },
  { code: "3369", match: true },
  { code: "3370", match: true },
  { code: "3371", match: true },
  { code: "3372", match: true },
  { code: "3373", match: true },
  { code: "3374", match: true },
  { code: "3375", match: true },
  { code: "3376", match: true },
  { code: "3377", match: false },
  { code: "3378", match: false },
  { code: "3379", match: false },
  { code: "3380", match: false },
  { code: "3381", match: false },
  { code: "3382", match: false },
  { code: "3383", match: false },
  { code: "3384", match: false },
  { code: "3385", match: false },
  { code: "3386", match: true },
  { code: "3387", match: true },
  { code: "3388", match: true },
  { code: "3389", match: false },
  { code: "3390", match: true },
  { code: "3391", match: true },
  { code: "3392", match: true },
  { code: "3393", match: true },
  { code: "3394", match: true },
  { code: "3395", match: true },
  { code: "3396", match: false },
  { code: "3397", match: false },
  { code: "3398", match: false },
  { code: "3399", match: false },
  { code: "3400", match: true },
  { code: "3401", match: true },
  { code: "3402", match: true },
  { code: "3403", match: true },
  { code: "3404", match: true },
  { code: "3405", match: true },
  { code: "3406", match: true },
  { code: "3407", match: false },
  { code: "3408", match: true },
  { code: "3409", match: true },
  { code: "3410", match: true },
  { code: "3411", match: true },
  { code: "3412", match: true },
  { code: "3413", match: true },
  { code: "3414", match: true },
  { code: "3415", match: true },
  { code: "3416", match: true },
  { code: "3417", match: true },
  { code: "3418", match: true },
  { code: "3419", match: true },
  { code: "3420", match: true },
  { code: "3421", match: true },
  { code: "3422", match: true },
  { code: "3423", match: true },
  { code: "3424", match: true },
  { code: "3425", match: true },
  { code: "3426", match: true },
  { code: "3427", match: true },
  { code: "3428", match: true },
  { code: "3429", match: true },
  { code: "3430", match: true },
  { code: "3431", match: true },
  { code: "3432", match: true },
  { code: "3433", match: true },
  { code: "3434", match: true },
  { code: "3435", match: true },
  { code: "3436", match: true },
  { code: "3437", match: true },
  { code: "3438", match: true },
  { code: "3439", match: false },
  { code: "3440", match: false },
  { code: "3441", match: true },
  { code: "3442", match: true },
  { code: "3443", match: true },
  { code: "3444", match: true },
  { code: "3445", match: true },
  { code: "3446", match: true },
  { code: "3447", match: true },
  { code: "3448", match: true },
  { code: "3449", match: true },
  { code: "3450", match: true },
  { code: "3451", match: true },
  { code: "3452", match: true },
  { code: "3453", match: true },
  { code: "3454", match: true },
  { code: "3455", match: true },
  { code: "3456", match: true },
  { code: "3457", match: true },
  { code: "3458", match: true },
  { code: "3459", match: true },
  { code: "3460", match: true },
  { code: "3461", match: true },
  { code: "3462", match: true },
  { code: "3463", match: true },
  { code: "3464", match: true },
  { code: "3465", match: true },
  { code: "3466", match: true },
  { code: "3467", match: true },
  { code: "3468", match: true },
  { code: "3469", match: true },
  { code: "3470", match: true },
  { code: "3471", match: true },
  { code: "3472", match: true },
  { code: "3473", match: true },
  { code: "3474", match: true },
  { code: "3475", match: true },
  { code: "3476", match: true },
  { code: "3477", match: true },
  { code: "3478", match: true },
  { code: "3479", match: true },
  { code: "3480", match: true },
  { code: "3481", match: true },
  { code: "3482", match: true },
  { code: "3483", match: true },
  { code: "3484", match: true },
  { code: "3485", match: true },
  { code: "3486", match: true },
  { code: "3487", match: true },
  { code: "3488", match: true },
  { code: "3489", match: true },
  { code: "3490", match: true },
  { code: "3491", match: true },
  { code: "3492", match: true },
  { code: "3493", match: true },
  { code: "3494", match: true },
  { code: "3495", match: true },
  { code: "3496", match: true },
  { code: "3497", match: true },
  { code: "3498", match: true },
  { code: "3499", match: true },
  { code: "3500", match: true },
  { code: "3501", match: true },
  { code: "3502", match: true },
  { code: "3503", match: true },
  { code: "3504", match: true },
  { code: "3505", match: true },
  { code: "3506", match: true },
  { code: "3507", match: true },
  { code: "3508", match: true },
  { code: "3509", match: true },
  { code: "3510", match: true },
  { code: "3511", match: true },
  { code: "3512", match: true },
  { code: "3513", match: true },
  { code: "3514", match: true },
  { code: "3515", match: true },
  { code: "3516", match: true },
  { code: "3517", match: true },
  { code: "3518", match: true },
  { code: "3519", match: true },
  { code: "3520", match: true },
  { code: "3521", match: true },
  { code: "3522", match: true },
  { code: "3523", match: true },
  { code: "3524", match: true },
  { code: "3525", match: true },
  { code: "3526", match: true },
  { code: "3527", match: true },
  { code: "3528", match: true },
  { code: "3529", match: true },
  { code: "3530", match: true },
  { code: "3531", match: true },
  { code: "3532", match: true },
  { code: "3533", match: true },
  { code: "3534", match: true },
  { code: "3535", match: true },
  { code: "3536", match: true },
  { code: "3537", match: true },
  { code: "3538", match: true },
  { code: "3539", match: true },
  { code: "3540", match: true },
  { code: "3541", match: true },
  { code: "3542", match: true },
  { code: "3543", match: true },
  { code: "3544", match: true },
  { code: "3545", match: true },
  { code: "3546", match: true },
  { code: "3547", match: true },
  { code: "3548", match: true },
  { code: "3549", match: true },
  { code: "3550", match: true },
  { code: "3551", match: true },
  { code: "3552", match: true },
  { code: "3553", match: true },
  { code: "3554", match: true },
  { code: "3555", match: true },
  { code: "3556", match: true },
  { code: "3557", match: true },
  { code: "3558", match: true },
  { code: "3559", match: true },
  { code: "3560", match: true },
  { code: "3561", match: false },
  { code: "3562", match: false },
  { code: "3563", match: false },
  { code: "3564", match: false },
  { code: "3565", match: false },
  { code: "3566", match: true },
  { code: "3567", match: true },
  { code: "3568", match: true },
  { code: "3569", match: true },
  { code: "3570", match: true },
  { code: "3571", match: true },
  { code: "3572", match: true },
  { code: "3573", match: true },
  { code: "3574", match: true },
  { code: "3575", match: true },
  { code: "3576", match: true },
  { code: "3577", match: true },
  { code: "3578", match: true },
  { code: "3579", match: true },
  { code: "3580", match: true },
  { code: "3581", match: true },
  { code: "3582", match: true },
  { code: "3583", match: true },
  { code: "3584", match: true },
  { code: "3585", match: true },
  { code: "3586", match: true },
  { code: "3587", match: true },
  { code: "3588", match: true },
  { code: "3589", match: true },
  { code: "3590", match: true },
  { code: "3591", match: true },
  { code: "3592", match: true },
  { code: "3593", match: true },
  { code: "3594", match: true },
  { code: "3595", match: true },
  { code: "3596", match: true },
  { code: "3597", match: true },
  { code: "3598", match: true },
  { code: "3599", match: true },
  { code: "3600", match: true },
  { code: "3601", match: true },
  { code: "3602", match: true },
  { code: "3603", match: true },
  { code: "3604", match: true },
  { code: "3605", match: true },
  { code: "3606", match: true },
  { code: "3607", match: true },
  { code: "3608", match: true },
  { code: "3609", match: true },
  { code: "3610", match: true },
  { code: "3611", match: true },
  { code: "3612", match: true },
  { code: "3613", match: true },
  { code: "3614", match: true },
  { code: "3615", match: true },
  { code: "3616", match: true },
  { code: "3617", match: true },
  { code: "3618", match: true },
  { code: "3619", match: true },
  { code: "3620", match: true },
  { code: "3621", match: true },
  { code: "3622", match: true },
  { code: "3623", match: true },
  { code: "3624", match: true },
  { code: "3625", match: true },
  { code: "3626", match: true },
  { code: "3627", match: true },
  { code: "3628", match: true },
  { code: "3629", match: true },
  { code: "3630", match: true },
  { code: "3631", match: true },
  { code: "3632", match: true },
  { code: "3633", match: true },
  { code: "3634", match: true },
  { code: "3635", match: true },
  { code: "3636", match: true },
  { code: "3637", match: true },
  { code: "3638", match: true },
  { code: "3639", match: true },
  { code: "3640", match: true },
  { code: "3641", match: true },
  { code: "3642", match: true },
  { code: "3643", match: true },
  { code: "3644", match: true },
  { code: "3645", match: true },
  { code: "3646", match: true },
  { code: "3647", match: true },
  { code: "3648", match: true },
  { code: "3649", match: true },
  { code: "3650", match: true },
  { code: "3651", match: true },
  { code: "3652", match: true },
  { code: "3653", match: true },
  { code: "3654", match: true },
  { code: "3655", match: true },
  { code: "3656", match: true },
  { code: "3657", match: true },
  { code: "3658", match: true },
  { code: "3659", match: true },
  { code: "3660", match: true },
  { code: "3661", match: true },
  { code: "3662", match: true },
  { code: "3663", match: true },
  { code: "3664", match: true },
  { code: "3665", match: true },
  { code: "3666", match: true },
  { code: "3667", match: true },
  { code: "3668", match: true },
  { code: "3669", match: true },
  { code: "3670", match: true },
  { code: "3671", match: true },
  { code: "3672", match: true },
  { code: "3673", match: true },
  { code: "3674", match: true },
  { code: "3675", match: true },
  { code: "3676", match: true },
  { code: "3677", match: true },
  { code: "3678", match: true },
  { code: "3679", match: true },
  { code: "3680", match: true },
  { code: "3681", match: true },
  { code: "3682", match: true },
  { code: "3683", match: true },
  { code: "3684", match: true },
  { code: "3685", match: true },
  { code: "3686", match: true },
  { code: "3687", match: true },
  { code: "3688", match: true },
  { code: "3689", match: true },
  { code: "3690", match: true },
  { code: "3691", match: true },
  { code: "3692", match: true },
  { code: "3693", match: true },
  { code: "3694", match: true },
  { code: "3695", match: true },
  { code: "3696", match: true },
  { code: "3697", match: true },
  { code: "3698", match: true },
  { code: "3699", match: true },
  { code: "3700", match: true },
  { code: "3701", match: true },
  { code: "3702", match: true },
  { code: "3703", match: true },
  { code: "3704", match: true },
  { code: "3705", match: true },
  { code: "3706", match: true },
  { code: "3707", match: true },
  { code: "3708", match: true },
  { code: "3709", match: true },
  { code: "3710", match: true },
  { code: "3711", match: true },
  { code: "3712", match: true },
  { code: "3713", match: true },
  { code: "3714", match: true },
  { code: "3715", match: true },
  { code: "3716", match: true },
  { code: "3717", match: true },
  { code: "3718", match: true },
  { code: "3719", match: true },
  { code: "3720", match: true },
  { code: "3721", match: true },
  { code: "3722", match: true },
  { code: "3723", match: true },
  { code: "3724", match: true },
  { code: "3725", match: true },
  { code: "3726", match: true },
  { code: "3727", match: false },
  { code: "3728", match: true },
  { code: "3729", match: true },
  { code: "3730", match: true },
  { code: "3731", match: true },
  { code: "3732", match: true },
  { code: "3733", match: true },
  { code: "3734", match: true },
  { code: "3735", match: true },
  { code: "3736", match: true },
  { code: "3737", match: true },
  { code: "3738", match: true },
  { code: "3739", match: true },
  { code: "3740", match: true },
  { code: "3741", match: true },
  { code: "3742", match: true },
  { code: "3743", match: true },
  { code: "3744", match: true },
  { code: "3745", match: true },
  { code: "3746", match: true },
  { code: "3747", match: true },
  { code: "3748", match: true },
  { code: "3749", match: true },
  { code: "3750", match: true },
  { code: "3751", match: true },
  { code: "3752", match: true },
  { code: "3753", match: true },
  { code: "3754", match: true },
  { code: "3755", match: true },
  { code: "3756", match: true },
  { code: "3757", match: true },
  { code: "3758", match: true },
  { code: "3759", match: true },
  { code: "3760", match: true },
  { code: "3761", match: true },
  { code: "3762", match: true },
  { code: "3763", match: true },
  { code: "3764", match: true },
  { code: "3765", match: true },
  { code: "3766", match: true },
  { code: "3767", match: true },
  { code: "3768", match: true },
  { code: "3769", match: false },
  { code: "3770", match: true },
  { code: "3771", match: true },
  { code: "3772", match: true },
  { code: "3773", match: true },
  { code: "3774", match: true },
  { code: "3775", match: true },
  { code: "3776", match: true },
  { code: "3777", match: true },
  { code: "3778", match: true },
  { code: "3779", match: true },
  { code: "3780", match: true },
  { code: "3781", match: true },
  { code: "3782", match: true },
  { code: "3783", match: true },
  { code: "3784", match: false },
  { code: "3785", match: true },
  { code: "3786", match: true },
  { code: "3787", match: false },
  { code: "3788", match: true },
  { code: "3789", match: true },
  { code: "3790", match: true },
  { code: "3791", match: true },
  { code: "3793", match: true },
  { code: "3794", match: true },
  { code: "3795", match: true },
  { code: "3796", match: true },
  { code: "3797", match: true },
  { code: "3798", match: true },
  { code: "3799", match: true },
  { code: "3800", match: true },
  { code: "3801", match: true },
  { code: "3802", match: true },
  { code: "3812", match: true },
  { code: "3814", match: true },
  { code: "3815", match: true },
  { code: "3816", match: true },
  { code: "3819", match: true },
  { code: "3821", match: true },
  { code: "3822", match: false },
  { code: "3823", match: true },
  { code: "3824", match: true },
  { code: "3825", match: true },
  { code: "3826", match: true },
  { code: "3827", match: true },
  { code: "3828", match: true },
  { code: "3829", match: false },
  { code: "3832", match: true },
  { code: "3833", match: true },
  { code: "3834", match: true },
  { code: "3835", match: true },
  { code: "3836", match: true },
  { code: "3837", match: true },
  { code: "3838", match: true },
  { code: "3839", match: true },
  { code: "3840", match: true },
  { code: "3841", match: true },
  { code: "3842", match: true },
  { code: "3843", match: true },
  { code: "3844", match: true },
  { code: "3845", match: true },
  { code: "3846", match: true },
  { code: "3847", match: true },
  { code: "3848", match: true },
  { code: "3849", match: true },
  { code: "3850", match: true },
  { code: "3851", match: true },
  { code: "3852", match: true },
  { code: "3854", match: true },
  { code: "3855", match: false },
  { code: "3857", match: true },
  { code: "3873", match: true },
  { code: "3874", match: true },
  { code: "3875", match: true },
  { code: "3876", match: true },
  { code: "3877", match: true },
  { code: "3878", match: true },
  { code: "3879", match: true },
  { code: "3880", match: true },
  { code: "3881", match: true },
  { code: "3882", match: true },
  { code: "3883", match: true },
  { code: "3884", match: true },
  { code: "3885", match: true },
  { code: "3886", match: false },
  { code: "3887", match: false },
  { code: "3888", match: true },
  { code: "3889", match: true },
  { code: "3890", match: true },
  { code: "3891", match: true },
  { code: "3892", match: true },
  { code: "3893", match: true },
  { code: "3900", match: false },
  { code: "3901", match: true },
  { code: "3902", match: true },
  { code: "3903", match: true },
  { code: "3906", match: true },
  { code: "3907", match: true },
  { code: "3908", match: true },
  { code: "3909", match: true },
  { code: "3910", match: true },
  { code: "3911", match: false },
  { code: "3912", match: false },
  { code: "3920", match: true },
  { code: "3942", match: true },
  { code: "3943", match: true },
  { code: "3944", match: true },
  { code: "3945", match: true },
  { code: "3946", match: true },
  { code: "3947", match: true },
  { code: "3948", match: true },
  { code: "3949", match: true },
  { code: "3950", match: true },
  { code: "3968", match: true },
  { code: "3969", match: true },
  { code: "3970", match: true },
  { code: "3973", match: true },
  { code: "3974", match: true },
  { code: "3975", match: true },
  { code: "3976", match: true },
  { code: "3978", match: true },
  { code: "3979", match: true },
  { code: "3985", match: true },
  { code: "3986", match: true },
  { code: "3987", match: true },
  { code: "3988", match: true },
  { code: "3989", match: true },
  { code: "3991", match: true },
  { code: "3992", match: true },
  { code: "3993", match: false },
  { code: "3994", match: true },
  { code: "3995", match: true },
  { code: "3996", match: true },
  { code: "3997", match: true },
  { code: "4000", match: false },
  { code: "4001", match: true },
  { code: "4002", match: true },
  { code: "4003", match: true },
  { code: "4004", match: true },
  { code: "4005", match: true },
  { code: "4006", match: true },
  { code: "4007", match: true },
  { code: "4008", match: true },
  { code: "4009", match: true },
  { code: "4010", match: true },
  { code: "4011", match: true },
  { code: "4012", match: true },
  { code: "4013", match: true },
  { code: "4014", match: true },
  { code: "4015", match: true },
  { code: "4016", match: true },
  { code: "4017", match: true },
  { code: "4018", match: true },
  { code: "4019", match: true },
  { code: "4020", match: true },
  { code: "4021", match: true },
  { code: "4022", match: true },
  { code: "4023", match: true },
  { code: "4024", match: true },
  { code: "4025", match: true },
  { code: "4026", match: true },
  { code: "4027", match: true },
  { code: "4028", match: true },
  { code: "4029", match: true },
  { code: "4030", match: true },
  { code: "4031", match: true },
  { code: "4032", match: true },
  { code: "4033", match: true },
  { code: "4034", match: true },
  { code: "4035", match: true },
  { code: "4036", match: true },
  { code: "4037", match: true },
  { code: "4038", match: true },
  { code: "4039", match: false },
  { code: "4040", match: true },
  { code: "4041", match: true },
  { code: "4042", match: true },
  { code: "4043", match: true },
  { code: "4044", match: true },
  { code: "4045", match: true },
  { code: "4046", match: true },
  { code: "4047", match: true },
  { code: "4048", match: true },
  { code: "4049", match: true },
  { code: "4050", match: true },
  { code: "4051", match: true },
  { code: "4052", match: true },
  { code: "4053", match: true },
  { code: "4054", match: true },
  { code: "4055", match: true },
  { code: "4056", match: true },
  { code: "4057", match: true },
  { code: "4058", match: true },
  { code: "4059", match: true },
  { code: "4060", match: true },
  { code: "4061", match: true },
  { code: "4062", match: true },
  { code: "4063", match: true },
  { code: "4071", match: true },
  { code: "4073", match: false },
  { code: "4074", match: true },
  { code: "4075", match: true },
  { code: "4079", match: false },
  { code: "4080", match: true },
  { code: "4081", match: true },
  { code: "4082", match: true },
  { code: "4083", match: true },
  { code: "4087", match: true },
  { code: "4088", match: true },
  { code: "4093", match: true },
  { code: "4094", match: true },
  { code: "4095", match: true },
  { code: "4096", match: true },
  { code: "4097", match: true },
  { code: "4098", match: true },
  { code: "4099", match: true },
  { code: "4100", match: true },
  { code: "4120", match: true },
  { code: "4121", match: true },
  { code: "4122", match: true },
  { code: "4123", match: true },
  { code: "4124", match: true },
  { code: "4125", match: false },
  { code: "4126", match: true },
  { code: "4127", match: false },
  { code: "4128", match: true },
  { code: "4129", match: false },
  { code: "4130", match: true },
  { code: "4131", match: true },
  { code: "4132", match: true },
  { code: "4133", match: true },
  { code: "4134", match: false },
  { code: "4135", match: false },
  { code: "4136", match: true },
  { code: "4137", match: true },
  { code: "4138", match: true },
  { code: "4139", match: true },
  { code: "4140", match: true },
  { code: "4141", match: true },
  { code: "4142", match: true },
  { code: "4143", match: false },
  { code: "4144", match: true },
  { code: "4145", match: true },
  { code: "4146", match: false },
  { code: "4147", match: true },
  { code: "4148", match: true },
  { code: "4149", match: true },
  { code: "4150", match: true },
  { code: "4151", match: true },
  { code: "4152", match: true },
  { code: "4153", match: true },
  { code: "4154", match: true },
  { code: "4155", match: true },
  { code: "4156", match: true },
  { code: "4157", match: true },
  { code: "4158", match: true },
  { code: "4159", match: true },
  { code: "4160", match: true },
  { code: "4161", match: true },
  { code: "4162", match: false },
  { code: "4163", match: true },
  { code: "4164", match: true },
  { code: "4165", match: true },
  { code: "4166", match: true },
  { code: "4167", match: true },
  { code: "4168", match: false },
  { code: "4169", match: false },
  { code: "4170", match: true },
  { code: "4171", match: true },
  { code: "4172", match: true },
  { code: "4173", match: true },
  { code: "4174", match: true },
  { code: "4175", match: true },
  { code: "4176", match: true },
  { code: "4178", match: true },
  { code: "4179", match: true },
  { code: "4180", match: true },
  { code: "4181", match: true },
  { code: "4182", match: true },
  { code: "4183", match: true },
  { code: "4184", match: true },
  { code: "4185", match: true },
  { code: "4188", match: false },
  { code: "4189", match: true },
  { code: "4190", match: true },
  { code: "4191", match: true },
  { code: "4192", match: true },
  { code: "4193", match: false },
  { code: "4194", match: false },
  { code: "4195", match: false },
  { code: "4196", match: false },
  { code: "4197", match: false },
  { code: "4198", match: true },
  { code: "4199", match: true },
  { code: "4200", match: true },
  { code: "4201", match: true },
  { code: "4202", match: true },
  { code: "4203", match: true },
  { code: "4204", match: true },
  { code: "4205", match: true },
  { code: "4206", match: true },
  { code: "4207", match: true },
  { code: "4208", match: true },
  { code: "4209", match: false },
  { code: "4210", match: true },
  { code: "4211", match: true },
  { code: "4212", match: false },
  { code: "4213", match: true },
  { code: "4214", match: true },
  { code: "4215", match: true },
  { code: "4216", match: false },
  { code: "4217", match: true },
  { code: "4218", match: false },
  { code: "4219", match: false },
  { code: "2000", match: true },
  { code: "2001", match: false },
  { code: "2002", match: false },
  { code: "2003", match: true },
  { code: "2004", match: false },
  { code: "2005", match: false },
  { code: "2006", match: true },
  { code: "2007", match: false },
  { code: "2008", match: true },
  { code: "2009", match: true },
  { code: "2010", match: true },
  { code: "2011", match: true },
  { code: "2012", match: true },
  { code: "2013", match: true },
  { code: "2014", match: true },
  { code: "2015", match: true },
  { code: "2016", match: true },
  { code: "2017", match: true },
  { code: "4220", match: true },
  { code: "4221", match: true },
  { code: "4222", match: true },
  { code: "4223", match: true },
  { code: "4224", match: true },
  { code: "4225", match: true },
  { code: "4226", match: true },
  { code: "4227", match: true },
  { code: "4228", match: true },
  { code: "4229", match: true },
  { code: "4230", match: true },
  { code: "4231", match: true },
  { code: "4232", match: true },
  { code: "4233", match: true },
  { code: "4234", match: false },
  { code: "4235", match: true },
  { code: "4236", match: false },
  { code: "4237", match: true },
  { code: "4238", match: true },
  { code: "4239", match: true },
  { code: "4240", match: true },
  { code: "4241", match: true },
  { code: "4242", match: false },
  { code: "4243", match: true },
  { code: "4244", match: false },
  { code: "4245", match: false },
  { code: "4246", match: true },
  { code: "4247", match: true },
  { code: "4248", match: true },
  { code: "4249", match: true },
  { code: "4250", match: true },
  { code: "4251", match: true },
  { code: "4252", match: true },
  { code: "4253", match: true },
  { code: "4254", match: true },
  { code: "4255", match: true },
  { code: "4256", match: false },
  { code: "4257", match: false },
  { code: "4258", match: true },
  { code: "4259", match: true },
  { code: "4260", match: false },
  { code: "4261", match: true },
  { code: "4262", match: false },
  { code: "4263", match: true },
  { code: "4264", match: true },
  { code: "4265", match: true },
  { code: "4266", match: false },
  { code: "4267", match: true },
  { code: "4268", match: true },
  { code: "4269", match: true },
  { code: "4270", match: true },
  { code: "4271", match: true },
  { code: "4272", match: false },
  { code: "4273", match: false },
  { code: "4274", match: true },
  { code: "4275", match: true },
  { code: "4276", match: true },
  { code: "4277", match: true },
  { code: "4278", match: true },
  { code: "4279", match: true },
  { code: "4280", match: true },
  { code: "4281", match: true },
  { code: "4282", match: false },
  { code: "4283", match: true },
  { code: "4284", match: true },
  { code: "4285", match: true },
  { code: "4286", match: true },
  { code: "4287", match: false },
  { code: "4288", match: true },
  { code: "4289", match: true },
  { code: "4291", match: true },
  { code: "4292", match: true },
  { code: "4293", match: false },
  { code: "4294", match: false },
  { code: "4295", match: true },
  { code: "4297", match: true },
  { code: "4298", match: false },
  { code: "4299", match: true },
  { code: "4300", match: true },
  { code: "4301", match: false },
  { code: "4302", match: false },
  { code: "4303", match: true },
  { code: "4304", match: true },
  { code: "4306", match: true },
  { code: "4307", match: true },
  { code: "4308", match: true },
  { code: "4309", match: true },
  { code: "4310", match: true },
  { code: "4311", match: false },
  { code: "4312", match: false },
  { code: "4313", match: true },
  { code: "4314", match: true },
  { code: "4315", match: true },
  { code: "4316", match: false },
  { code: "4317", match: true },
  { code: "4318", match: true },
  { code: "4319", match: true },
  { code: "4322", match: true },
  { code: "4324", match: true },
  { code: "4326", match: true },
  { code: "4327", match: true },
  { code: "4328", match: false },
  { code: "4329", match: true },
  { code: "4330", match: false },
  { code: "4331", match: false },
  { code: "4332", match: false },
  { code: "4333", match: false },
  { code: "4334", match: false },
  { code: "4335", match: false },
  { code: "4336", match: false },
  { code: "4337", match: false },
  { code: "4338", match: false },
  { code: "4339", match: true },
  { code: "4340", match: false },
  { code: "4341", match: true },
  { code: "4342", match: false },
  { code: "4343", match: true },
  { code: "4344", match: false },
  { code: "4345", match: true },
  { code: "4346", match: false },
  { code: "4347", match: true },
  { code: "4348", match: false },
  { code: "4349", match: true },
  { code: "4350", match: false },
  { code: "4351", match: true },
  { code: "4352", match: false },
  { code: "4353", match: true },
  { code: "4354", match: false },
  { code: "4355", match: true },
  { code: "4356", match: false },
  { code: "4357", match: true },
  { code: "4358", match: false },
  { code: "4359", match: true },
  { code: "4360", match: false },
  { code: "4361", match: true },
  { code: "4362", match: false },
  { code: "4363", match: true },
  { code: "4364", match: false },
  { code: "4365", match: true },
  { code: "4366", match: false },
  { code: "4367", match: true },
  { code: "4368", match: false },
  { code: "4369", match: true },
  { code: "4370", match: false },
  { code: "4371", match: true },
  { code: "4372", match: false },
  { code: "4373", match: true },
  { code: "4374", match: false },
  { code: "4375", match: true },
  { code: "4376", match: false },
  { code: "4377", match: true },
  { code: "4378", match: false },
  { code: "4379", match: true },
  { code: "4380", match: false },
  { code: "4381", match: true },
  { code: "4382", match: false },
  { code: "4383", match: true },
  { code: "4384", match: false },
  { code: "4385", match: false },
  { code: "4386", match: true },
  { code: "4387", match: false },
  { code: "4388", match: true },
  { code: "4389", match: false },
  { code: "4390", match: false },
  { code: "4391", match: false },
  { code: "4392", match: false },
  { code: "4393", match: false },
  { code: "4394", match: false },
  { code: "4395", match: false },
  { code: "4396", match: false },
  { code: "4397", match: false },
  { code: "4398", match: false },
  { code: "4399", match: true },
  { code: "4400", match: true },
  { code: "4401", match: true },
  { code: "4402", match: true },
  { code: "4403", match: true },
  { code: "4404", match: true },
  { code: "4405", match: true },
  { code: "4406", match: true },
  { code: "4407", match: true },
  { code: "4408", match: true },
  { code: "4409", match: true },
  { code: "4410", match: true },
  { code: "4411", match: true },
  { code: "4412", match: true },
  { code: "4413", match: true },
  { code: "4414", match: true },
  { code: "4415", match: true },
  { code: "4417", match: true },
  { code: "4418", match: true },
  { code: "4419", match: true },
  { code: "4420", match: true },
  { code: "4421", match: true },
  { code: "4422", match: true },
  { code: "4423", match: true },
  { code: "4424", match: true },
  { code: "4425", match: true },
  { code: "4426", match: true },
  { code: "4427", match: true },
  { code: "4428", match: true },
  { code: "4429", match: true },
  { code: "4430", match: true },
  { code: "4431", match: true },
  { code: "4432", match: true },
  { code: "4433", match: true },
  { code: "4434", match: true },
  { code: "4437", match: true },
  { code: "4438", match: true },
  { code: "4439", match: true },
  { code: "4440", match: false },
  { code: "4455", match: true },
  { code: "4456", match: true },
  { code: "4457", match: true },
  { code: "4458", match: false },
  { code: "4462", match: true },
  { code: "4463", match: true },
  { code: "4465", match: false },
  { code: "4466", match: true },
  { code: "4467", match: true },
  { code: "4468", match: false },
  { code: "4469", match: true },
  { code: "4470", match: true },
  { code: "4471", match: true },
  { code: "4472", match: false },
  { code: "4473", match: false },
  { code: "4474", match: false },
  { code: "4475", match: false },
  { code: "4479", match: false },
  { code: "4480", match: true },
  { code: "4481", match: false },
  { code: "4482", match: true },
  { code: "4483", match: true },
  { code: "4484", match: true },
  { code: "4485", match: true },
  { code: "4486", match: true },
  { code: "4487", match: true },
  { code: "4488", match: true },
  { code: "4489", match: true },
  { code: "4490", match: true },
  { code: "4491", match: true },
  { code: "4492", match: true },
  { code: "4493", match: true },
  { code: "4494", match: true },
  { code: "4495", match: true },
  { code: "4496", match: true },
  { code: "4497", match: true },
  { code: "4498", match: true },
  { code: "4499", match: true },
  { code: "4500", match: true },
  { code: "4501", match: true },
  { code: "4502", match: true },
  { code: "4503", match: true },
  { code: "4504", match: true },
  { code: "4505", match: true },
  { code: "4506", match: true },
  { code: "4507", match: true },
  { code: "4508", match: true },
  { code: "4509", match: true },
  { code: "4510", match: true },
  { code: "4511", match: true },
  { code: "4512", match: true },
  { code: "4513", match: true },
  { code: "4514", match: true },
  { code: "4515", match: true },
  { code: "4516", match: true },
  { code: "4517", match: true },
  { code: "4518", match: true },
  { code: "4519", match: true },
  { code: "4520", match: true },
  { code: "4521", match: true },
  { code: "4522", match: true },
  { code: "4523", match: true },
  { code: "4524", match: true },
  { code: "4525", match: true },
  { code: "4526", match: true },
  { code: "4527", match: true },
  { code: "4528", match: true },
  { code: "4529", match: true },
  { code: "4530", match: true },
  { code: "4531", match: true },
  { code: "4532", match: true },
  { code: "4533", match: true },
  { code: "4534", match: true },
  { code: "4535", match: true },
  { code: "4536", match: true },
  { code: "4537", match: true },
  { code: "4538", match: true },
  { code: "4539", match: true },
  { code: "4540", match: true },
  { code: "4541", match: true },
  { code: "4542", match: true },
  { code: "4543", match: true },
  { code: "4544", match: true },
  { code: "4545", match: true },
  { code: "4546", match: true },
  { code: "4547", match: true },
  { code: "4548", match: true },
  { code: "4549", match: true },
  { code: "4550", match: true },
  { code: "4551", match: true },
  { code: "4552", match: true },
  { code: "4553", match: true },
  { code: "4554", match: true },
  { code: "4555", match: true },
  { code: "4556", match: false },
  { code: "4557", match: true },
  { code: "4558", match: true },
  { code: "4559", match: true },
  { code: "4568", match: true },
  { code: "4569", match: true },
  { code: "4570", match: true },
  { code: "4571", match: true },
  { code: "4572", match: true },
  { code: "4573", match: true },
  { code: "4574", match: true },
  { code: "4575", match: true },
  { code: "4576", match: true },
  { code: "4577", match: true },
  { code: "4578", match: true },
  { code: "4579", match: true },
  { code: "4580", match: true },
  { code: "4581", match: true },
  { code: "4582", match: true },
  { code: "4583", match: true },
  { code: "4584", match: true },
  { code: "4585", match: true },
  { code: "4586", match: true },
  { code: "4587", match: true },
  { code: "4588", match: true },
  { code: "4589", match: true },
  { code: "4600", match: true },
  { code: "4601", match: false },
  { code: "4602", match: false },
  { code: "4603", match: true },
  { code: "4604", match: false },
  { code: "4605", match: false },
  { code: "4606", match: true },
  { code: "4607", match: false },
  { code: "4608", match: true },
  { code: "4609", match: true },
  { code: "4610", match: true },
  { code: "4611", match: true },
  { code: "4612", match: true },
  { code: "4613", match: false },
  { code: "4614", match: true },
  { code: "4615", match: true },
  { code: "4616", match: true },
  { code: "4617", match: true },
  { code: "4618", match: true },
  { code: "4619", match: true },
  { code: "4620", match: true },
  { code: "4621", match: false },
  { code: "4622", match: false },
  { code: "4623", match: true },
  { code: "4624", match: true },
  { code: "4625", match: false },
  { code: "4626", match: false },
  { code: "4627", match: true },
  { code: "4628", match: false },
  { code: "4629", match: false },
  { code: "4630", match: false },
  { code: "4631", match: true },
  { code: "4632", match: false },
  { code: "4633", match: false },
  { code: "4634", match: false },
  { code: "4635", match: true },
  { code: "4636", match: false },
  { code: "4637", match: false },
  { code: "4638", match: true },
  { code: "4639", match: false },
  { code: "4640", match: true },
  { code: "4641", match: false },
  { code: "4642", match: false },
  { code: "4643", match: false },
  { code: "4644", match: false },
  { code: "4645", match: true },
  { code: "4646", match: false },
  { code: "4647", match: true },
  { code: "4652", match: true },
  { code: "4653", match: true },
  { code: "4654", match: true },
  { code: "4655", match: true },
  { code: "4656", match: true },
  { code: "4657", match: false },
  { code: "4658", match: true },
  { code: "4659", match: true },
  { code: "4660", match: false },
  { code: "4661", match: true },
  { code: "4662", match: false },
  { code: "4663", match: true },
  { code: "4664", match: true },
  { code: "4665", match: true },
  { code: "4666", match: true },
  { code: "4667", match: true },
  { code: "4668", match: true },
  { code: "4669", match: true },
  { code: "4670", match: true },
  { code: "4671", match: true },
  { code: "4672", match: false },
  { code: "4673", match: false },
  { code: "4674", match: true },
  { code: "4675", match: true },
  { code: "4676", match: true },
  { code: "4677", match: true },
  { code: "4678", match: true },
  { code: "4679", match: true },
  { code: "4680", match: true },
  { code: "4681", match: false },
  { code: "4682", match: false },
  { code: "4683", match: false },
  { code: "4684", match: true },
  { code: "4685", match: true },
  { code: "4686", match: true },
  { code: "4687", match: true },
  { code: "4688", match: false },
  { code: "4689", match: true },
  { code: "4690", match: false },
  { code: "4691", match: false },
  { code: "4692", match: false },
  { code: "4693", match: true },
  { code: "4694", match: true },
  { code: "4695", match: true },
  { code: "4696", match: true },
  { code: "4697", match: true },
  { code: "4698", match: true },
  { code: "4699", match: false },
  { code: "4700", match: true },
  { code: "4701", match: false },
  { code: "4702", match: true },
  { code: "4703", match: true },
  { code: "4704", match: true },
  { code: "4705", match: true },
  { code: "4706", match: true },
  { code: "4707", match: false },
  { code: "4708", match: false },
  { code: "4709", match: false },
  { code: "4710", match: false },
  { code: "4711", match: false },
  { code: "4712", match: true },
  { code: "4713", match: true },
  { code: "4714", match: false },
  { code: "4715", match: false },
  { code: "4716", match: false },
  { code: "4717", match: true },
  { code: "4718", match: true },
  { code: "4719", match: false },
  { code: "4720", match: true },
  { code: "4721", match: false },
  { code: "4722", match: false },
  { code: "4723", match: false },
  { code: "4724", match: false },
  { code: "4725", match: false },
  { code: "4726", match: true },
  { code: "4727", match: false },
  { code: "4728", match: true },
  { code: "4729", match: false },
  { code: "4730", match: false },
  { code: "4731", match: false },
  { code: "4732", match: false },
  { code: "4733", match: false },
  { code: "4734", match: false },
  { code: "4735", match: false },
  { code: "4736", match: false },
  { code: "4737", match: true },
  { code: "4738", match: true },
  { code: "4739", match: true },
  { code: "4740", match: true },
  { code: "4741", match: true },
  { code: "4742", match: true },
  { code: "4743", match: true },
  { code: "4744", match: true },
  { code: "4745", match: false },
  { code: "4746", match: false },
  { code: "4747", match: true },
  { code: "4748", match: false },
  { code: "4749", match: true },
  { code: "4750", match: true },
  { code: "4751", match: false },
  { code: "4752", match: false },
  { code: "4753", match: true },
  { code: "4754", match: true },
  { code: "4755", match: true },
  { code: "4756", match: true },
  { code: "4757", match: true },
  { code: "4758", match: true },
  { code: "4759", match: true },
  { code: "4760", match: true },
  { code: "4761", match: true },
  { code: "4762", match: true },
  { code: "4763", match: true },
  { code: "4764", match: true },
  { code: "4765", match: true },
  { code: "4766", match: true },
  { code: "4767", match: true },
  { code: "4768", match: true },
  { code: "4769", match: true },
  { code: "4770", match: true },
  { code: "4771", match: true },
  { code: "4772", match: true },
  { code: "4773", match: true },
  { code: "4774", match: true },
  { code: "4775", match: true },
  { code: "4776", match: true },
  { code: "4777", match: true },
  { code: "4778", match: true },
  { code: "4779", match: true },
  { code: "4780", match: true },
  { code: "4781", match: true },
  { code: "4782", match: true },
  { code: "4783", match: true },
  { code: "4784", match: true },
  { code: "4785", match: true },
  { code: "4786", match: true },
  { code: "4787", match: true },
  { code: "4788", match: true },
  { code: "4789", match: true },
  { code: "4790", match: true },
  { code: "4791", match: true },
  { code: "4792", match: true },
  { code: "4793", match: true },
  { code: "4794", match: true },
  { code: "4795", match: true },
  { code: "4796", match: true },
  { code: "4797", match: true },
  { code: "4798", match: true },
  { code: "4799", match: true },
  { code: "4800", match: true },
  { code: "4801", match: true },
  { code: "4802", match: false },
  { code: "4803", match: true },
  { code: "4804", match: false },
  { code: "4805", match: true },
  { code: "4806", match: true },
  { code: "4807", match: true },
  { code: "4808", match: false },
  { code: "4809", match: true },
  { code: "4810", match: true },
  { code: "4811", match: true },
  { code: "4812", match: true },
  { code: "4813", match: false },
  { code: "4814", match: true },
  { code: "4815", match: true },
  { code: "4816", match: true },
  { code: "4817", match: false },
  { code: "4818", match: true },
  { code: "4819", match: true },
  { code: "4820", match: false },
  { code: "4821", match: true },
  { code: "4822", match: true },
  { code: "4823", match: true },
  { code: "4824", match: true },
  { code: "4826", match: true },
  { code: "4839", match: true },
  { code: "4855", match: true },
  { code: "4856", match: true },
  { code: "4857", match: true },
  { code: "4858", match: true },
  { code: "4859", match: true },
  { code: "4860", match: true },
  { code: "4861", match: true },
  { code: "4862", match: true },
  { code: "4863", match: true },
  { code: "4864", match: true },
  { code: "4865", match: true },
  { code: "4866", match: true },
  { code: "4867", match: true },
  { code: "4868", match: true },
  { code: "4869", match: true },
  { code: "4870", match: true },
  { code: "4871", match: true },
  { code: "4872", match: true },
  { code: "4873", match: true },
  { code: "4874", match: true },
  { code: "4875", match: true },
  { code: "4876", match: true },
  { code: "4877", match: true },
  { code: "4878", match: true },
  { code: "4879", match: true },
  { code: "4880", match: true },
  { code: "4882", match: false },
  { code: "4883", match: true },
  { code: "4884", match: false },
  { code: "4885", match: true },
  { code: "4886", match: false },
  { code: "4887", match: true },
  { code: "4888", match: false },
  { code: "4889", match: true },
  { code: "4890", match: false },
  { code: "4891", match: true },
  { code: "4892", match: false },
  { code: "4893", match: true },
  { code: "4894", match: false },
  { code: "4895", match: true },
  { code: "4896", match: false },
  { code: "4897", match: false },
  { code: "4898", match: true },
  { code: "4899", match: false },
  { code: "4900", match: true },
  { code: "4901", match: true },
  { code: "4902", match: true },
  { code: "4903", match: true },
  { code: "4904", match: true },
  { code: "4906", match: false },
  { code: "4907", match: true },
  { code: "4908", match: false },
  { code: "4909", match: true },
  { code: "4910", match: false },
  { code: "4911", match: false },
  { code: "4912", match: false },
  { code: "4913", match: false },
  { code: "4914", match: false },
  { code: "4915", match: false },
  { code: "4916", match: false },
  { code: "4917", match: false },
  { code: "4918", match: false },
  { code: "4919", match: false },
  { code: "4920", match: false },
  { code: "4921", match: true },
  { code: "4922", match: false },
  { code: "4923", match: true },
  { code: "4924", match: false },
  { code: "4925", match: true },
  { code: "4926", match: false },
  { code: "4927", match: true },
  { code: "4928", match: false },
  { code: "4929", match: true },
  { code: "4930", match: false },
  { code: "4931", match: true },
  { code: "4932", match: false },
  { code: "4933", match: true },
  { code: "4934", match: false },
  { code: "4935", match: true },
  { code: "4936", match: false },
  { code: "4937", match: true },
  { code: "4938", match: false },
  { code: "4939", match: true },
  { code: "4940", match: false },
  { code: "4941", match: true },
  { code: "4942", match: false },
  { code: "4943", match: true },
  { code: "4944", match: false },
  { code: "4945", match: true },
  { code: "4946", match: false },
  { code: "4947", match: true },
  { code: "4948", match: false },
  { code: "4949", match: true },
  { code: "4950", match: false },
  { code: "4951", match: true },
  { code: "4952", match: false },
  { code: "4953", match: true },
  { code: "4954", match: false },
  { code: "4955", match: true },
  { code: "4956", match: false },
  { code: "4957", match: true },
  { code: "4958", match: false },
  { code: "4959", match: true },
  { code: "4960", match: false },
  { code: "4961", match: true },
  { code: "4962", match: false },
  { code: "4963", match: true },
  { code: "4964", match: false },
  { code: "4965", match: true },
  { code: "4966", match: false },
  { code: "4967", match: true },
  { code: "4968", match: false },
  { code: "4969", match: true },
  { code: "4970", match: false },
  { code: "4971", match: true },
  { code: "4972", match: false },
  { code: "4973", match: true },
  { code: "4974", match: false },
  { code: "4975", match: true },
  { code: "4976", match: false },
  { code: "4977", match: true },
  { code: "4978", match: false },
  { code: "4979", match: true },
  { code: "4980", match: false },
  { code: "4981", match: true },
  { code: "4982", match: false },
  { code: "4983", match: true },
  { code: "4984", match: false },
  { code: "4985", match: true },
  { code: "4986", match: false },
  { code: "4987", match: true },
  { code: "4988", match: false },
  { code: "4989", match: true },
  { code: "4990", match: false },
  { code: "4991", match: true },
  { code: "4992", match: false },
  { code: "4993", match: true },
  { code: "4994", match: false },
  { code: "4995", match: false },
  { code: "4996", match: false },
  { code: "4997", match: true },
  { code: "4998", match: false },
  { code: "4999", match: true },
  { code: "5011", match: false },
  { code: "5012", match: true },
  { code: "5013", match: true },
  { code: "5014", match: true },
  { code: "5015", match: true },
  { code: "5016", match: true },
  { code: "5017", match: false },
  { code: "5018", match: true },
  { code: "5041", match: true },
  { code: "5042", match: true },
  { code: "5048", match: true },
  { code: "5069", match: true },
  { code: "5070", match: true },
  { code: "5071", match: true },
  { code: "5072", match: true },
  { code: "5105", match: true },
  { code: "5106", match: true },
  { code: "5107", match: true },
  { code: "5108", match: true },
  { code: "5109", match: true },
  { code: "5110", match: true },
  { code: "5111", match: true },
  { code: "5112", match: true },
  { code: "5113", match: true },
  { code: "5114", match: true },
  { code: "5115", match: true },
  { code: "5116", match: true },
  { code: "5117", match: true },
  { code: "5118", match: true },
  { code: "5119", match: true },
  { code: "5120", match: true },
  { code: "5121", match: true },
  { code: "5122", match: true },
  { code: "5123", match: true },
  { code: "5124", match: true },
  { code: "5125", match: true },
  { code: "5126", match: true },
  { code: "5127", match: true },
  { code: "5128", match: true },
  { code: "5129", match: true },
  { code: "5130", match: true },
  { code: "5132", match: true },
  { code: "5167", match: false },
  { code: "5168", match: false },
  { code: "5169", match: true },
  { code: "5170", match: true },
  { code: "5171", match: true },
  { code: "5172", match: true },
  { code: "5173", match: false },
  { code: "5174", match: false },
  { code: "5175", match: false },
  { code: "5176", match: false },
  { code: "5177", match: false },
  { code: "5178", match: false },
  { code: "5179", match: true },
  { code: "5180", match: true },
  { code: "5181", match: true },
  { code: "5182", match: true },
  { code: "5183", match: true },
  { code: "5184", match: true },
  { code: "5185", match: true },
  { code: "5186", match: true },
  { code: "5187", match: true },
  { code: "5188", match: true },
  { code: "5193", match: false },
  { code: "5195", match: false },
  { code: "5214", match: false },
  { code: "5221", match: true },
  { code: "5223", match: true },
  { code: "5224", match: false },
  { code: "5225", match: false },
  { code: "5228", match: false },
  { code: "5229", match: false },
  { code: "5233", match: false },
  { code: "5234", match: false },
  { code: "5235", match: false },
  { code: "5237", match: false },
  { code: "5243", match: true },
  { code: "5244", match: false },
  { code: "5245", match: true },
  { code: "5246", match: true },
  { code: "5247", match: true },
  { code: "5250", match: false },
  { code: "5251", match: true },
  { code: "5252", match: true },
  { code: "5253", match: true },
  { code: "5254", match: true },
  { code: "5255", match: true },
  { code: "5256", match: true },
  { code: "5257", match: true },
  { code: "5258", match: true },
  { code: "5259", match: true },
  { code: "5262", match: false },
  { code: "5263", match: true },
  { code: "5264", match: true },
  { code: "5266", match: true },
  { code: "5269", match: true },
  { code: "5270", match: true },
  { code: "5271", match: true },
  { code: "5272", match: true },
  { code: "5273", match: true },
  { code: "5274", match: true },
  { code: "5275", match: true },
  { code: "5292", match: true },
  { code: "5293", match: true },
  { code: "5294", match: true },
  { code: "5295", match: true },
  { code: "5296", match: true },
  { code: "5297", match: true },
  { code: "5298", match: true },
  { code: "5299", match: true },
  { code: "5300", match: true },
  { code: "5301", match: true },
  { code: "5302", match: true },
  { code: "5303", match: true },
  { code: "5304", match: true },
  { code: "5305", match: true },
  { code: "5306", match: true },
  { code: "5307", match: true },
  { code: "5308", match: true },
  { code: "5309", match: true },
  { code: "5310", match: true },
  { code: "5311", match: true },
  { code: "5316", match: true },
  { code: "5317", match: false },
  { code: "5318", match: true },
  { code: "5320", match: true },
  { code: "5321", match: true },
  { code: "5322", match: false },
  { code: "5323", match: true },
  { code: "5324", match: true },
  { code: "5325", match: true },
  { code: "5329", match: false },
  { code: "5330", match: false },
  { code: "5331", match: false },
  { code: "5332", match: false },
  { code: "5336", match: false },
  { code: "5337", match: true },
  { code: "5340", match: true },
  { code: "5341", match: false },
  { code: "5342", match: true },
  { code: "5343", match: true },
  { code: "5344", match: true },
  { code: "5345", match: true },
  { code: "5346", match: true },
  { code: "5347", match: true },
  { code: "5348", match: true },
  { code: "5349", match: true },
  { code: "5352", match: false },
  { code: "5353", match: true },
  { code: "5354", match: true },
  { code: "5355", match: true },
  { code: "5356", match: true },
  { code: "5357", match: true },
  { code: "5358", match: false },
  { code: "5359", match: true },
  { code: "5360", match: true },
  { code: "5361", match: true },
  { code: "5362", match: true },
  { code: "5363", match: false },
  { code: "5364", match: true },
  { code: "5365", match: true },
  { code: "5367", match: true },
  { code: "5368", match: false },
  { code: "5369", match: false },
  { code: "5370", match: true },
  { code: "5371", match: true },
  { code: "5372", match: true },
  { code: "5373", match: true },
  { code: "5379", match: false },
  { code: "5380", match: true },
  { code: "5381", match: true },
  { code: "5382", match: true },
  { code: "5383", match: true },
  { code: "5387", match: true },
  { code: "5388", match: true },
  { code: "5389", match: true },
  { code: "5391", match: false },
  { code: "5392", match: true },
  { code: "5393", match: true },
  { code: "5396", match: true },
  { code: "5451", match: false },
  { code: "5456", match: false },
  { code: "5457", match: false },
  { code: "5458", match: false },
  { code: "5459", match: false },
  { code: "5460", match: false },
  { code: "5461", match: false },
  { code: "5462", match: false },
  { code: "5463", match: true },
  { code: "5464", match: true },
  { code: "5466", match: true },
  { code: "5467", match: true },
  { code: "5469", match: true },
  { code: "5472", match: false },
  { code: "5479", match: true },
  { code: "5480", match: true },
  { code: "5481", match: true },
  { code: "5482", match: true },
  { code: "5487", match: false },
  { code: "5488", match: true },
  { code: "5489", match: true },
  { code: "5490", match: true },
  { code: "5498", match: true },
  { code: "5499", match: false },
  { code: "5500", match: true },
  { code: "5513", match: false },
  { code: "5514", match: true },
  { code: "5515", match: false },
  { code: "5516", match: false },
  { code: "5518", match: false },
  { code: "5519", match: false },
  { code: "5520", match: true },
  { code: "5523", match: true },
  { code: "5524", match: true },
  { code: "5527", match: true },
  { code: "5530", match: true },
  { code: "5531", match: true },
  { code: "5532", match: true },
  { code: "5533", match: true },
  { code: "5534", match: true },
  { code: "5535", match: true },
  { code: "5536", match: true },
  { code: "5537", match: true },
  { code: "5538", match: true },
  { code: "5539", match: true },
  { code: "5544", match: false },
  { code: "5545", match: true },
  { code: "5546", match: true },
  { code: "5550", match: true },
  { code: "5551", match: true },
  { code: "5552", match: true },
  { code: "5554", match: true },
  { code: "5555", match: true },
  { code: "5556", match: true },
  { code: "5558", match: false },
  { code: "5559", match: false },
  { code: "5560", match: true },
  { code: "5561", match: true },
  { code: "5562", match: true },
  { code: "5563", match: true },
  { code: "5564", match: true },
  { code: "5565", match: true },
  { code: "5566", match: true },
  { code: "5567", match: true },
  { code: "5568", match: true },
  { code: "5569", match: true },
  { code: "5570", match: true },
  { code: "5571", match: true },
  { code: "5572", match: true },
  { code: "5573", match: true },
  { code: "5574", match: true },
  { code: "5575", match: true },
  { code: "5576", match: true },
  { code: "5577", match: true },
  { code: "5578", match: true },
  { code: "5579", match: true },
  { code: "5580", match: true },
  { code: "5581", match: true },
  { code: "5582", match: true },
  { code: "5583", match: true },
  { code: "5588", match: true },
  { code: "5589", match: true },
  { code: "5591", match: false },
  { code: "5592", match: true },
  { code: "5593", match: true },
  { code: "5596", match: true },
  { code: "5597", match: false },
  { code: "5598", match: true },
  { code: "5600", match: false },
  { code: "5601", match: false },
  { code: "5602", match: false },
  { code: "5603", match: false },
  { code: "5604", match: false },
  { code: "5605", match: false },
  { code: "5606", match: false },
  { code: "5607", match: false },
  { code: "5608", match: false },
  { code: "5609", match: false },
  { code: "5610", match: false },
  { code: "5611", match: false },
  { code: "5612", match: false },
  { code: "5613", match: false },
  { code: "5614", match: false },
  { code: "5615", match: false },
  { code: "5616", match: false },
  { code: "5617", match: false },
  { code: "5618", match: false },
  { code: "5619", match: false },
  { code: "5620", match: false },
  { code: "5621", match: false },
  { code: "5623", match: true },
  { code: "5624", match: true },
  { code: "5625", match: true },
  { code: "5627", match: true },
  { code: "5628", match: true },
  { code: "5629", match: true },
  { code: "5631", match: true },
  { code: "5632", match: true },
  { code: "5633", match: true },
  { code: "5634", match: true },
  { code: "5635", match: true },
  { code: "5636", match: true },
  { code: "5637", match: true },
  { code: "5638", match: true },
  { code: "5639", match: true },
  { code: "5641", match: true },
  { code: "5643", match: true },
  { code: "5644", match: true },
  { code: "5646", match: true },
  { code: "5649", match: true },
  { code: "5650", match: true },
  { code: "5651", match: true },
  { code: "5652", match: true },
  { code: "5653", match: true },
  { code: "5654", match: true },
  { code: "5655", match: true },
  { code: "5659", match: true },
  { code: "5663", match: true },
  { code: "5664", match: true },
  { code: "5665", match: true },
  { code: "5666", match: false },
  { code: "5667", match: false },
  { code: "5668", match: false },
  { code: "5669", match: false },
  { code: "5670", match: true },
  { code: "5671", match: true },
  { code: "5672", match: true },
  { code: "5673", match: true },
  { code: "5674", match: true },
  { code: "5675", match: true },
  { code: "5676", match: true },
  { code: "5677", match: true },
  { code: "5678", match: true },
  { code: "5679", match: true },
  { code: "5680", match: true },
  { code: "5681", match: true },
  { code: "5682", match: true },
  { code: "5683", match: true },
  { code: "5684", match: true },
  { code: "5685", match: true },
  { code: "5698", match: true },
  { code: "5699", match: true },
  { code: "5700", match: true },
  { code: "5701", match: false },
  { code: "5702", match: false },
  { code: "5703", match: false },
  { code: "5704", match: false },
  { code: "5705", match: false },
  { code: "5706", match: false },
  { code: "5707", match: true },
  { code: "5708", match: true },
  { code: "5709", match: false },
  { code: "5710", match: false },
  { code: "5711", match: false },
  { code: "5712", match: false },
  { code: "5713", match: false },
  { code: "5714", match: false },
  { code: "5715", match: false },
  { code: "5716", match: false },
  { code: "5717", match: false },
  { code: "5718", match: false },
  { code: "5719", match: false },
  { code: "5720", match: false },
  { code: "5721", match: false },
  { code: "5722", match: false },
  { code: "5723", match: false },
  { code: "5724", match: false },
  { code: "5725", match: false },
  { code: "5726", match: false },
  { code: "5727", match: false },
  { code: "5728", match: false },
  { code: "5729", match: false },
  { code: "5730", match: false },
  { code: "5731", match: false },
  { code: "5732", match: false },
  { code: "5733", match: false },
  { code: "5734", match: false },
  { code: "5735", match: false },
  { code: "5736", match: false },
  { code: "5737", match: false },
  { code: "5738", match: false },
  { code: "5739", match: false },
  { code: "5740", match: false },
  { code: "5741", match: false },
  { code: "5742", match: false },
  { code: "5743", match: false },
  { code: "5744", match: false },
  { code: "5745", match: false },
  { code: "5746", match: false },
  { code: "5747", match: false },
  { code: "5748", match: false },
  { code: "5749", match: false },
  { code: "5750", match: false },
  { code: "5751", match: false },
  { code: "5752", match: false },
  { code: "5753", match: false },
  { code: "5754", match: false },
  { code: "5755", match: false },
  { code: "5756", match: false },
  { code: "5757", match: false },
  { code: "5758", match: false },
  { code: "5759", match: false },
  { code: "5760", match: false },
  { code: "5761", match: false },
  { code: "5762", match: false },
  { code: "5763", match: false },
  { code: "5764", match: false },
  { code: "5765", match: false },
  { code: "5766", match: false },
  { code: "5767", match: false },
  { code: "5768", match: false },
  { code: "5769", match: false },
  { code: "5770", match: false },
  { code: "5771", match: false },
  { code: "5772", match: false },
  { code: "5773", match: false },
  { code: "5774", match: false },
  { code: "5775", match: false },
  { code: "5776", match: false },
  { code: "5777", match: false },
  { code: "5778", match: false },
  { code: "5779", match: false },
  { code: "5780", match: false },
  { code: "5781", match: false },
  { code: "5782", match: false },
  { code: "5783", match: false },
  { code: "5784", match: false },
  { code: "5785", match: false },
  { code: "5786", match: false },
  { code: "5787", match: false },
  { code: "5788", match: false },
  { code: "5789", match: false },
  { code: "5790", match: false },
  { code: "5791", match: false },
  { code: "5792", match: false },
  { code: "5793", match: false },
  { code: "5794", match: false },
  { code: "5795", match: false },
  { code: "5796", match: false },
  { code: "5797", match: false },
  { code: "5798", match: false },
  { code: "5799", match: false },
  { code: "5825", match: true },
  { code: "5828", match: false },
  { code: "5829", match: false },
  { code: "5830", match: true },
  { code: "5831", match: false },
  { code: "5832", match: true },
  { code: "5833", match: true },
  { code: "5834", match: true },
  { code: "5835", match: true },
  { code: "5836", match: true },
  { code: "5837", match: true },
  { code: "5839", match: true },
  { code: "5842", match: true },
  { code: "5843", match: false },
  { code: "5844", match: true },
  { code: "5845", match: true },
  { code: "5846", match: true },
  { code: "5847", match: true },
  { code: "5848", match: true },
  { code: "5849", match: true },
  { code: "5850", match: true },
  { code: "5851", match: true },
  { code: "5852", match: true },
  { code: "5853", match: true },
  { code: "5854", match: true },
  { code: "5855", match: true },
  { code: "5856", match: true },
  { code: "5857", match: true },
  { code: "5858", match: true },
  { code: "5861", match: false },
  { code: "5862", match: false },
  { code: "5863", match: false },
  { code: "5864", match: false },
  { code: "5865", match: false },
  { code: "5866", match: false },
  { code: "5867", match: false },
  { code: "5868", match: false },
  { code: "5869", match: false },
  { code: "5870", match: false },
  { code: "5871", match: false },
  { code: "5872", match: false },
  { code: "5873", match: false },
  { code: "5874", match: false },
  { code: "5875", match: true },
  { code: "5876", match: true },
  { code: "5877", match: true },
  { code: "5879", match: false },
  { code: "5880", match: true },
  { code: "5884", match: false },
  { code: "5885", match: true },
  { code: "5886", match: true },
  { code: "5887", match: true },
  { code: "5890", match: true },
  { code: "5896", match: true },
  { code: "5897", match: true },
  { code: "5898", match: true },
  { code: "5899", match: true },
  { code: "5921", match: true },
  { code: "5922", match: true },
  { code: "5923", match: true },
  { code: "5924", match: true },
  { code: "5925", match: true },
  { code: "5926", match: true },
  { code: "5927", match: true },
  { code: "5928", match: true },
  { code: "5929", match: true },
  { code: "5930", match: true },
  { code: "5931", match: true },
  { code: "5932", match: true },
  { code: "5933", match: true },
  { code: "5934", match: true },
  { code: "5935", match: true },
  { code: "5936", match: true },
  { code: "5937", match: true },
  { code: "5938", match: true },
  { code: "5939", match: true },
  { code: "5940", match: true },
  { code: "5941", match: false },
  { code: "5942", match: true },
  { code: "5945", match: true },
  { code: "5946", match: true },
  { code: "5947", match: true },
  { code: "5948", match: true },
  { code: "5949", match: true },
  { code: "5950", match: true },
  { code: "5951", match: true },
  { code: "5952", match: true },
  { code: "5953", match: true },
  { code: "5954", match: true },
  { code: "5955", match: true },
  { code: "5956", match: true },
  { code: "5957", match: true },
  { code: "5958", match: true },
  { code: "5959", match: true },
  { code: "5960", match: true },
  { code: "5961", match: true },
  { code: "5962", match: true },
  { code: "5963", match: true },
  { code: "5964", match: true },
  { code: "5965", match: true },
  { code: "5966", match: true },
  { code: "5967", match: true },
  { code: "5968", match: true },
  { code: "5969", match: true },
  { code: "5970", match: true },
  { code: "5971", match: true },
  { code: "5972", match: true },
  { code: "5973", match: true },
  { code: "5974", match: true },
  { code: "5975", match: true },
  { code: "5976", match: true },
  { code: "6050", match: true },
  { code: "6051", match: true },
  { code: "6052", match: true },
  { code: "6053", match: true },
  { code: "6054", match: true },
  { code: "6055", match: true },
  { code: "6056", match: true },
  { code: "6057", match: true },
  { code: "6058", match: true },
  { code: "6059", match: true },
  { code: "6060", match: true },
  { code: "6061", match: true },
  { code: "6062", match: true },
  { code: "6063", match: true },
  { code: "6064", match: true },
  { code: "6065", match: true },
  { code: "6066", match: true },
  { code: "6067", match: true },
  { code: "6068", match: true },
  { code: "6069", match: true },
  { code: "6070", match: true },
  { code: "6071", match: true },
  { code: "6072", match: true },
  { code: "6073", match: true },
  { code: "6074", match: true },
  { code: "6075", match: true },
  { code: "6076", match: true },
  { code: "6077", match: true },
  { code: "6078", match: true },
  { code: "6079", match: true },
  { code: "6080", match: true },
  { code: "6081", match: true },
  { code: "6082", match: true },
  { code: "6083", match: true },
  { code: "6084", match: true },
  { code: "6085", match: true },
  { code: "6086", match: true },
  { code: "6087", match: true },
  { code: "6088", match: true },
  { code: "6089", match: true },
  { code: "6090", match: true },
  { code: "6091", match: true },
  { code: "6092", match: true },
  { code: "6093", match: true },
  { code: "6094", match: true },
  { code: "6095", match: true },
  { code: "6096", match: true },
  { code: "6097", match: true },
  { code: "6098", match: true },
  { code: "6099", match: true },
  { code: "6100", match: true },
  { code: "6101", match: true },
  { code: "6102", match: true },
  { code: "6103", match: true },
  { code: "6104", match: true },
  { code: "6105", match: true },
  { code: "6106", match: true },
  { code: "6107", match: true },
  { code: "6108", match: true },
  { code: "6109", match: true },
  { code: "6110", match: true },
  { code: "6111", match: true },
  { code: "6112", match: true },
  { code: "6113", match: true },
  { code: "6114", match: true },
  { code: "6115", match: true },
  { code: "6116", match: true },
  { code: "6117", match: true },
  { code: "6118", match: true },
  { code: "6119", match: true },
  { code: "6120", match: true },
  { code: "6121", match: true },
  { code: "6122", match: true },
  { code: "6123", match: true },
  { code: "6124", match: true },
  { code: "6125", match: true },
  { code: "6128", match: false },
  { code: "6129", match: true },
  { code: "6130", match: false },
  { code: "6131", match: false },
  { code: "6132", match: false },
  { code: "6133", match: false },
  { code: "6134", match: true },
  { code: "6135", match: true },
  { code: "6141", match: true },
  { code: "6144", match: true },
  { code: "6145", match: true },
  { code: "6146", match: true },
  { code: "6147", match: true },
  { code: "6148", match: true },
  { code: "6149", match: true },
  { code: "6150", match: true },
  { code: "6151", match: true },
  { code: "6152", match: true },
  { code: "6153", match: true },
  { code: "6154", match: true },
  { code: "6155", match: true },
  { code: "6156", match: true },
  { code: "6157", match: true },
  { code: "6158", match: true },
  { code: "6159", match: true },
  { code: "6160", match: true },
  { code: "6161", match: true },
  { code: "6162", match: true },
  { code: "6163", match: true },
  { code: "6164", match: true },
  { code: "6165", match: true },
  { code: "6166", match: true },
  { code: "6167", match: true },
  { code: "6168", match: true },
  { code: "6169", match: true },
  { code: "6170", match: true },
  { code: "6171", match: true },
  { code: "6172", match: true },
  { code: "6173", match: true },
  { code: "6174", match: true },
  { code: "6175", match: true },
  { code: "6176", match: true },
  { code: "6178", match: false },
  { code: "6179", match: false },
  { code: "6180", match: false },
  { code: "6181", match: false },
  { code: "6182", match: false },
  { code: "6183", match: false },
  { code: "6184", match: false },
  { code: "6185", match: false },
  { code: "6186", match: false },
  { code: "6187", match: false },
  { code: "6190", match: true },
  { code: "6200", match: true },
  { code: "6201", match: true },
  { code: "6202", match: true },
  { code: "6204", match: false },
  { code: "6207", match: false },
  { code: "6210", match: true },
  { code: "6211", match: true },
  { code: "6244", match: false },
  { code: "6245", match: false },
  { code: "6246", match: false },
  { code: "6247", match: false },
  { code: "6248", match: false },
  { code: "6249", match: false },
  { code: "6250", match: true },
  { code: "6251", match: false },
  { code: "6252", match: false },
  { code: "6253", match: false },
  { code: "6254", match: false },
  { code: "6255", match: false },
  { code: "6256", match: false },
  { code: "6257", match: false },
  { code: "6258", match: false },
  { code: "6259", match: false },
  { code: "6260", match: false },
  { code: "6261", match: false },
  { code: "6262", match: false },
  { code: "6263", match: false },
  { code: "6264", match: false },
  { code: "6265", match: false },
  { code: "6266", match: false },
  { code: "6267", match: false },
  { code: "6268", match: false },
  { code: "6269", match: false },
  { code: "6270", match: false },
  { code: "6271", match: false },
  { code: "6272", match: false },
  { code: "6273", match: false },
  { code: "6274", match: false },
  { code: "6275", match: false },
  { code: "6307", match: true },
  { code: "6309", match: false },
  { code: "6310", match: true },
  { code: "6311", match: true },
  { code: "6312", match: true },
  { code: "6316", match: true },
  { code: "6317", match: false },
  { code: "6318", match: true },
  { code: "6319", match: true },
  { code: "6320", match: false },
  { code: "6321", match: true },
  { code: "6322", match: true },
  { code: "6323", match: false },
  { code: "6324", match: true },
  { code: "6325", match: true },
  { code: "6328", match: true },
  { code: "6329", match: true },
  { code: "6330", match: true },
  { code: "6331", match: true },
  { code: "6332", match: true },
  { code: "6333", match: true },
  { code: "6334", match: true },
  { code: "6335", match: true },
  { code: "6336", match: true },
  { code: "6337", match: true },
  { code: "6338", match: true },
  { code: "6339", match: true },
  { code: "6340", match: true },
  { code: "6341", match: true },
  { code: "6342", match: true },
  { code: "6343", match: true },
  { code: "6344", match: true },
  { code: "6345", match: true },
  { code: "6346", match: true },
  { code: "6347", match: true },
  { code: "6348", match: true },
  { code: "6349", match: true },
  { code: "6350", match: true },
  { code: "6351", match: true },
  { code: "6352", match: true },
  { code: "6353", match: true },
  { code: "6354", match: true },
  { code: "6355", match: true },
  { code: "6356", match: true },
  { code: "6357", match: false },
  { code: "6358", match: false },
  { code: "6359", match: false },
  { code: "6360", match: false },
  { code: "6362", match: true },
  { code: "6363", match: false },
  { code: "6364", match: true },
  { code: "6365", match: true },
  { code: "6366", match: true },
  { code: "6367", match: true },
  { code: "6368", match: true },
  { code: "6369", match: true },
  { code: "6370", match: true },
  { code: "6371", match: true },
  { code: "6372", match: true },
  { code: "6381", match: true },
  { code: "6382", match: true },
  { code: "6383", match: true },
  { code: "6384", match: true },
  { code: "6385", match: true },
  { code: "6386", match: true },
  { code: "6387", match: true },
  { code: "6391", match: true },
  { code: "6393", match: true },
  { code: "6394", match: true },
  { code: "6395", match: true },
  { code: "6396", match: true },
  { code: "6397", match: true },
  { code: "6398", match: true },
  { code: "6399", match: true },
  { code: "6400", match: true },
  { code: "6401", match: true },
  { code: "6402", match: true },
  { code: "6403", match: true },
  { code: "6404", match: true },
  { code: "6405", match: true },
  { code: "6406", match: true },
  { code: "6407", match: true },
  { code: "6408", match: true },
  { code: "6409", match: true },
  { code: "6410", match: true },
  { code: "6411", match: true },
  { code: "6412", match: true },
  { code: "6413", match: true },
  { code: "6414", match: true },
  { code: "6415", match: true },
  { code: "6416", match: true },
  { code: "6417", match: true },
  { code: "6418", match: true },
  { code: "6419", match: true },
  { code: "6420", match: true },
  { code: "6421", match: true },
  { code: "6422", match: true },
  { code: "6423", match: true },
  { code: "6424", match: true },
  { code: "6425", match: true },
  { code: "6426", match: true },
  { code: "6427", match: true },
  { code: "6428", match: true },
  { code: "6429", match: true },
  { code: "6430", match: true },
  { code: "6431", match: true },
  { code: "6432", match: true },
  { code: "6433", match: true },
  { code: "6434", match: true },
  { code: "6435", match: true },
  { code: "6436", match: true },
  { code: "6437", match: true },
  { code: "6438", match: true },
  { code: "6439", match: true },
  { code: "6440", match: true },
  { code: "6441", match: true },
  { code: "6442", match: true },
  { code: "6443", match: true },
  { code: "6444", match: true },
  { code: "6445", match: true },
  { code: "6446", match: true },
  { code: "6447", match: true },
  { code: "6448", match: true },
  { code: "6449", match: true },
  { code: "6450", match: true },
  { code: "6451", match: true },
  { code: "6452", match: true },
  { code: "6453", match: true },
  { code: "6454", match: true },
  { code: "6455", match: true },
  { code: "6456", match: true },
  { code: "6457", match: true },
  { code: "6458", match: true },
  { code: "6459", match: true },
  { code: "6460", match: true },
  { code: "6461", match: true },
  { code: "6462", match: true },
  { code: "6463", match: true },
  { code: "6464", match: true },
  { code: "6465", match: true },
  { code: "6466", match: true },
  { code: "6467", match: true },
  { code: "6468", match: true },
  { code: "6469", match: true },
  { code: "6470", match: true },
  { code: "6471", match: true },
  { code: "6472", match: true },
  { code: "6473", match: true },
  { code: "6474", match: true },
  { code: "6475", match: true },
  { code: "6476", match: true },
  { code: "6477", match: true },
  { code: "6478", match: true },
  { code: "6479", match: true },
  { code: "6480", match: true },
  { code: "6481", match: true },
  { code: "6482", match: true },
  { code: "6483", match: true },
  { code: "6484", match: true },
  { code: "6485", match: true },
  { code: "6486", match: true },
  { code: "6487", match: true },
  { code: "6488", match: true },
  { code: "6489", match: true },
  { code: "6490", match: true },
  { code: "6491", match: true },
  { code: "6492", match: true },
  { code: "6493", match: true },
  { code: "6494", match: true },
  { code: "6495", match: true },
  { code: "6496", match: true },
  { code: "6497", match: true },
  { code: "6498", match: true },
  { code: "6499", match: true },
  { code: "6500", match: true },
  { code: "6501", match: true },
  { code: "6502", match: true },
  { code: "6503", match: true },
  { code: "6504", match: true },
  { code: "6505", match: true },
  { code: "6506", match: true },
  { code: "6507", match: true },
  { code: "6508", match: true },
  { code: "6509", match: true },
  { code: "6510", match: true },
  { code: "6511", match: true },
  { code: "6512", match: true },
  { code: "6513", match: true },
  { code: "6514", match: true },
  { code: "6515", match: true },
  { code: "6516", match: true },
  { code: "6517", match: true },
  { code: "6518", match: true },
  { code: "6519", match: true },
  { code: "6520", match: true },
  { code: "6521", match: true },
  { code: "6522", match: true },
  { code: "6523", match: true },
  { code: "6524", match: true },
  { code: "6525", match: true },
  { code: "6526", match: true },
  { code: "6527", match: true },
  { code: "6528", match: true },
  { code: "6529", match: true },
  { code: "6530", match: true },
  { code: "6531", match: true },
  { code: "6532", match: true },
  { code: "6533", match: true },
  { code: "6534", match: true },
  { code: "6535", match: true },
  { code: "6536", match: true },
  { code: "6537", match: true },
  { code: "6538", match: true },
  { code: "6539", match: true },
  { code: "6540", match: true },
  { code: "6541", match: true },
  { code: "6542", match: true },
  { code: "6543", match: true },
  { code: "6544", match: true },
  { code: "6545", match: true },
  { code: "6546", match: true },
  { code: "6547", match: true },
  { code: "6548", match: true },
  { code: "6549", match: true },
  { code: "6550", match: true },
  { code: "6551", match: true },
  { code: "6552", match: true },
  { code: "6553", match: true },
  { code: "6554", match: true },
  { code: "6555", match: true },
  { code: "6556", match: true },
  { code: "6557", match: true },
  { code: "6558", match: true },
  { code: "6559", match: true },
  { code: "6560", match: true },
  { code: "6561", match: true },
  { code: "6562", match: true },
  { code: "6563", match: true },
  { code: "6564", match: true },
  { code: "6565", match: true },
  { code: "6566", match: true },
  { code: "6567", match: true },
  { code: "6568", match: true },
  { code: "6569", match: true },
  { code: "6570", match: true },
  { code: "6571", match: true },
  { code: "6572", match: true },
  { code: "6573", match: true },
  { code: "6574", match: true },
  { code: "6575", match: true },
  { code: "6576", match: true },
  { code: "6577", match: true },
  { code: "6578", match: true },
  { code: "6579", match: true },
  { code: "6580", match: true },
  { code: "6581", match: true },
  { code: "6582", match: true },
  { code: "6583", match: true },
  { code: "6584", match: true },
  { code: "6585", match: true },
  { code: "6586", match: true },
  { code: "6587", match: true },
  { code: "6588", match: true },
  { code: "6589", match: true },
  { code: "6590", match: true },
  { code: "6591", match: true },
  { code: "6592", match: true },
  { code: "6593", match: true },
  { code: "6594", match: true },
  { code: "6595", match: true },
  { code: "6596", match: true },
  { code: "6597", match: true },
  { code: "6598", match: true },
  { code: "6599", match: true },
  { code: "6600", match: true },
  { code: "6601", match: true },
  { code: "6602", match: true },
  { code: "6603", match: true },
  { code: "6604", match: true },
  { code: "6605", match: true },
  { code: "6606", match: true },
  { code: "6607", match: true },
  { code: "6608", match: true },
  { code: "6609", match: true },
  { code: "6610", match: true },
  { code: "6611", match: true },
  { code: "6612", match: true },
  { code: "6613", match: true },
  { code: "6614", match: true },
  { code: "6615", match: true },
  { code: "6616", match: true },
  { code: "6617", match: true },
  { code: "6618", match: true },
  { code: "6619", match: true },
  { code: "6620", match: true },
  { code: "6621", match: true },
  { code: "6622", match: true },
  { code: "6623", match: true },
  { code: "6624", match: true },
  { code: "6625", match: true },
  { code: "6626", match: true },
  { code: "6627", match: true },
  { code: "6628", match: true },
  { code: "6629", match: true },
  { code: "6630", match: true },
  { code: "6631", match: true },
  { code: "6632", match: true },
  { code: "6633", match: true },
  { code: "6634", match: true },
  { code: "6635", match: true },
  { code: "6636", match: true },
  { code: "6637", match: true },
  { code: "6638", match: false },
  { code: "6639", match: false },
  { code: "6640", match: false },
  { code: "6641", match: false },
  { code: "6642", match: false },
  { code: "6643", match: false },
  { code: "6644", match: false },
  { code: "6646", match: true },
  { code: "6647", match: false },
  { code: "6649", match: true },
  { code: "6650", match: true },
  { code: "6651", match: true },
  { code: "6652", match: true },
  { code: "6653", match: true },
  { code: "6654", match: true },
  { code: "6655", match: true },
  { code: "6656", match: true },
  { code: "6657", match: true },
  { code: "6658", match: true },
  { code: "6659", match: true },
  { code: "6660", match: true },
  { code: "6661", match: true },
  { code: "6662", match: true },
  { code: "6663", match: true },
  { code: "6664", match: true },
  { code: "6665", match: true },
  { code: "6666", match: false },
  { code: "6667", match: true },
  { code: "6668", match: true },
  { code: "6669", match: true },
  { code: "6670", match: true },
  { code: "6671", match: true },
  { code: "6672", match: true },
  { code: "6673", match: true },
  { code: "6674", match: true },
  { code: "6675", match: true },
  { code: "6676", match: true },
  { code: "6677", match: true },
  { code: "6678", match: true },
  { code: "6679", match: true },
  { code: "6680", match: true },
  { code: "6681", match: true },
  { code: "6682", match: true },
  { code: "6683", match: true },
  { code: "6684", match: true },
  { code: "6685", match: true },
  { code: "6686", match: true },
  { code: "6687", match: true },
  { code: "6688", match: true },
  { code: "6689", match: true },
  { code: "6690", match: true },
  { code: "6691", match: true },
  { code: "6692", match: true },
  { code: "6693", match: false },
  { code: "6694", match: false },
  { code: "6695", match: false },
  { code: "6696", match: true },
  { code: "6697", match: true },
  { code: "6700", match: false },
  { code: "6703", match: true },
  { code: "6704", match: false },
  { code: "6705", match: true },
  { code: "6706", match: true },
  { code: "6707", match: true },
  { code: "6708", match: true },
  { code: "6709", match: true },
  { code: "6720", match: true },
  { code: "6721", match: true },
  { code: "6722", match: true },
  { code: "6723", match: true },
  { code: "6732", match: true },
  { code: "6733", match: true },
  { code: "6734", match: true },
  { code: "6735", match: true },
  { code: "6736", match: true },
  { code: "6737", match: true },
  { code: "6738", match: true },
  { code: "6781", match: false },
  { code: "6782", match: true },
  { code: "6783", match: true },
  { code: "6784", match: true },
  { code: "6785", match: true },
  { code: "6786", match: true },
  { code: "6787", match: true },
  { code: "6788", match: true },
  { code: "6789", match: true },
  { code: "6790", match: true },
  { code: "6791", match: true },
  { code: "6792", match: true },
  { code: "6793", match: true },
  { code: "6794", match: true },
  { code: "6795", match: true },
  { code: "6796", match: true },
  { code: "6797", match: true },
  { code: "6798", match: true },
  { code: "6799", match: true },
  { code: "6800", match: true },
  { code: "6801", match: true },
  { code: "6802", match: true },
  { code: "6803", match: true },
  { code: "6804", match: true },
  { code: "6805", match: true },
  { code: "6806", match: true },
  { code: "6807", match: true },
  { code: "6808", match: true },
  { code: "6809", match: true },
  { code: "6810", match: true },
  { code: "6811", match: true },
  { code: "6812", match: true },
  { code: "6813", match: true },
  { code: "6814", match: true },
  { code: "6815", match: true },
  { code: "6816", match: true },
  { code: "6817", match: true },
  { code: "6818", match: true },
  { code: "6819", match: true },
  { code: "6820", match: true },
  { code: "6821", match: true },
  { code: "6822", match: true },
  { code: "6823", match: true },
  { code: "6824", match: true },
  { code: "6825", match: true },
  { code: "6826", match: true },
  { code: "6827", match: true },
  { code: "6828", match: true },
  { code: "6829", match: true },
  { code: "6830", match: true },
  { code: "6831", match: true },
  { code: "6832", match: true },
  { code: "6833", match: true },
  { code: "6834", match: true },
  { code: "6835", match: true },
  { code: "6836", match: true },
  { code: "6837", match: true },
  { code: "6838", match: true },
  { code: "6839", match: true },
  { code: "6840", match: true },
  { code: "6841", match: true },
  { code: "6842", match: true },
  { code: "6843", match: true },
  { code: "6844", match: true },
  { code: "6845", match: true },
  { code: "6846", match: true },
  { code: "6847", match: true },
  { code: "6848", match: true },
  { code: "6849", match: true },
  { code: "6850", match: true },
  { code: "6851", match: true },
  { code: "6852", match: true },
  { code: "6853", match: true },
  { code: "6854", match: true },
  { code: "6855", match: true },
  { code: "6856", match: true },
  { code: "6857", match: true },
  { code: "6858", match: true },
  { code: "6859", match: true },
  { code: "6860", match: true },
  { code: "6861", match: true },
  { code: "6862", match: true },
  { code: "6863", match: true },
  { code: "6867", match: true },
  { code: "6868", match: true },
  { code: "6870", match: true },
  { code: "6871", match: true },
  { code: "6875", match: true },
  { code: "6876", match: true },
  { code: "6879", match: true },
  { code: "6880", match: true },
  { code: "6881", match: true },
  { code: "6882", match: true },
  { code: "6883", match: false },
  { code: "6884", match: true },
  { code: "6885", match: true },
  { code: "6886", match: true },
  { code: "6887", match: true },
  { code: "6892", match: false },
  { code: "6893", match: true },
  { code: "6894", match: true },
  { code: "6915", match: false },
  { code: "6916", match: false },
  { code: "6917", match: true },
  { code: "6922", match: true },
  { code: "6923", match: true },
  { code: "6924", match: true },
  { code: "6925", match: true },
  { code: "6927", match: true },
  { code: "6931", match: true },
  { code: "6932", match: true },
  { code: "6933", match: true },
  { code: "6934", match: false },
  { code: "6956", match: true },
  { code: "6957", match: true },
  { code: "6958", match: true },
  { code: "6959", match: true },
  { code: "6962", match: true },
  { code: "6966", match: true },
  { code: "6978", match: false },
  { code: "6979", match: true },
  { code: "6980", match: true },
  { code: "6981", match: false },
  { code: "6982", match: true },
  { code: "6983", match: true },
  { code: "6984", match: true },
  { code: "6985", match: false },
  { code: "6986", match: true },
  { code: "6987", match: true },
  { code: "6988", match: false },
  { code: "6989", match: true },
  { code: "6990", match: true },
  { code: "6991", match: true },
  { code: "6996", match: true },
  { code: "6997", match: true },
  { code: "7005", match: true },
  { code: "7006", match: true },
  { code: "7007", match: true },
  { code: "7034", match: true },
  { code: "7035", match: true },
  { code: "7036", match: true },
  { code: "7037", match: true },
  { code: "7038", match: true },
  { code: "7039", match: true },
  { code: "7040", match: true },
  { code: "7041", match: true },
  { code: "7042", match: true },
  { code: "7057", match: true },
  { code: "7058", match: true },
  { code: "7059", match: true },
  { code: "7060", match: true },
  { code: "7061", match: true },
  { code: "7062", match: true },
  { code: "7063", match: true },
  { code: "7064", match: true },
  { code: "7065", match: true },
  { code: "7066", match: true },
  { code: "7067", match: true },
  { code: "7068", match: true },
  { code: "7069", match: true },
  { code: "7070", match: true },
  { code: "7071", match: false },
  { code: "7072", match: true },
  { code: "7073", match: true },
  { code: "7074", match: true },
  { code: "7075", match: true },
  { code: "7076", match: true },
  { code: "7077", match: true },
  { code: "7078", match: true },
  { code: "7079", match: true },
  { code: "7080", match: true },
  { code: "7081", match: true },
  { code: "7082", match: false },
  { code: "7084", match: true },
  { code: "7085", match: true },
  { code: "7086", match: true },
  { code: "7087", match: true },
  { code: "7088", match: true },
  { code: "7109", match: true },
  { code: "7110", match: true },
  { code: "7111", match: true },
  { code: "7112", match: true },
  { code: "7113", match: true },
  { code: "7114", match: true },
  { code: "7115", match: true },
  { code: "7116", match: true },
  { code: "7117", match: true },
  { code: "7118", match: true },
  { code: "7119", match: true },
  { code: "7120", match: true },
  { code: "7121", match: true },
  { code: "7122", match: true },
  { code: "7123", match: true },
  { code: "7124", match: true },
  { code: "7125", match: true },
  { code: "7126", match: true },
  { code: "7127", match: true },
  { code: "7128", match: true },
  { code: "7131", match: true },
  { code: "7132", match: true },
  { code: "7133", match: true },
  { code: "7134", match: false },
  { code: "7135", match: true },
  { code: "7136", match: true },
  { code: "7137", match: false },
  { code: "7138", match: true },
  { code: "7139", match: true },
  { code: "7142", match: true },
  { code: "7257", match: true },
  { code: "7258", match: true },
  { code: "7259", match: true },
  { code: "7260", match: true },
  { code: "7261", match: true },
  { code: "7262", match: true },
  { code: "7263", match: true },
  { code: "7264", match: true },
  { code: "7265", match: true },
  { code: "7266", match: true },
  { code: "7267", match: true },
  { code: "7268", match: true },
  { code: "7269", match: true },
  { code: "7270", match: true },
  { code: "7271", match: true },
  { code: "7272", match: true },
  { code: "7273", match: true },
  { code: "7274", match: true },
  { code: "7275", match: true },
  { code: "7276", match: true },
  { code: "7277", match: true },
  { code: "7278", match: true },
  { code: "7279", match: true },
  { code: "7280", match: true },
  { code: "7281", match: true },
  { code: "7282", match: true },
  { code: "7283", match: true },
  { code: "7284", match: true },
  { code: "7285", match: true },
  { code: "7286", match: true },
  { code: "7287", match: true },
  { code: "7288", match: true },
  { code: "7289", match: true },
  { code: "7290", match: true },
  { code: "7291", match: true },
  { code: "7292", match: true },
  { code: "7293", match: true },
  { code: "7294", match: true },
  { code: "7295", match: true },
  { code: "7296", match: true },
  { code: "7297", match: true },
  { code: "7298", match: true },
  { code: "7299", match: true },
  { code: "7300", match: true },
  { code: "7301", match: true },
  { code: "7302", match: true },
  { code: "7303", match: true },
  { code: "7304", match: true },
  { code: "7305", match: true },
  { code: "7306", match: true },
  { code: "7307", match: true },
  { code: "7308", match: true },
  { code: "7309", match: true },
  { code: "7310", match: true },
  { code: "7311", match: true },
  { code: "7312", match: true },
  { code: "7313", match: true },
  { code: "7314", match: true },
  { code: "7315", match: true },
  { code: "7316", match: true },
  { code: "7317", match: true },
  { code: "7318", match: true },
  { code: "7319", match: true },
  { code: "7320", match: true },
  { code: "7321", match: true },
  { code: "7322", match: true },
  { code: "7323", match: true },
  { code: "7324", match: true },
  { code: "7325", match: true },
  { code: "7326", match: true },
  { code: "7327", match: true },
  { code: "7328", match: true },
  { code: "7329", match: true },
  { code: "7330", match: true },
  { code: "7331", match: true },
  { code: "7332", match: true },
  { code: "7333", match: true },
  { code: "7334", match: true },
  { code: "7335", match: true },
  { code: "7336", match: true },
  { code: "7337", match: true },
  { code: "7338", match: true },
  { code: "7339", match: true },
  { code: "7340", match: true },
  { code: "7341", match: true },
  { code: "7342", match: true },
  { code: "7343", match: true },
  { code: "7344", match: true },
  { code: "7345", match: true },
  { code: "7346", match: true },
  { code: "7347", match: true },
  { code: "7348", match: true },
  { code: "7349", match: true },
  { code: "7350", match: true },
  { code: "7351", match: true },
  { code: "7352", match: true },
  { code: "7353", match: true },
  { code: "7354", match: true },
  { code: "7355", match: true },
  { code: "7356", match: true },
  { code: "7357", match: true },
  { code: "7358", match: true },
  { code: "7359", match: true },
  { code: "7360", match: true },
  { code: "7361", match: true },
  { code: "7362", match: true },
  { code: "7363", match: true },
  { code: "7364", match: true },
  { code: "7365", match: true },
  { code: "7366", match: true },
  { code: "7367", match: true },
  { code: "7368", match: true },
  { code: "7369", match: true },
  { code: "7370", match: true },
  { code: "7371", match: false },
  { code: "7372", match: false },
  { code: "7373", match: true },
  { code: "7374", match: true },
  { code: "7375", match: true },
  { code: "7376", match: true },
  { code: "7400", match: true },
  { code: "7401", match: true },
  { code: "7402", match: true },
  { code: "7403", match: true },
  { code: "7404", match: true },
  { code: "7405", match: true },
  { code: "7406", match: false },
  { code: "7407", match: true },
  { code: "7408", match: true },
  { code: "7409", match: true },
  { code: "7410", match: false },
  { code: "7411", match: true },
  { code: "7412", match: true },
  { code: "7413", match: true },
  { code: "7414", match: false },
  { code: "7415", match: true },
  { code: "7416", match: true },
  { code: "7417", match: true },
  { code: "7418", match: true },
  { code: "7419", match: true },
  { code: "7420", match: true },
  { code: "7421", match: true },
  { code: "7422", match: true },
  { code: "7423", match: true },
  { code: "7446", match: false },
  { code: "7447", match: false },
  { code: "7528", match: true },
  { code: "7529", match: true },
  { code: "7530", match: true },
  { code: "7531", match: true },
  { code: "7532", match: true },
  { code: "7533", match: true },
  { code: "7534", match: true },
  { code: "7535", match: true },
  { code: "7536", match: true },
  { code: "7537", match: true },
  { code: "7538", match: true },
  { code: "7539", match: true },
  { code: "7540", match: true },
  { code: "7541", match: true },
  { code: "7542", match: true },
  { code: "7543", match: true },
  { code: "7544", match: true },
  { code: "7545", match: true },
  { code: "7546", match: true },
  { code: "7547", match: true },
  { code: "7548", match: true },
  { code: "7549", match: true },
  { code: "7550", match: true },
  { code: "7551", match: true },
  { code: "7552", match: true },
  { code: "7553", match: true },
  { code: "7554", match: true },
  { code: "7555", match: true },
  { code: "7556", match: true },
  { code: "7557", match: true },
  { code: "7558", match: true },
  { code: "7559", match: true },
  { code: "7560", match: true },
  { code: "7561", match: true },
  { code: "7562", match: true },
  { code: "7563", match: true },
  { code: "7564", match: true },
  { code: "7565", match: true },
  { code: "7566", match: true },
  { code: "7567", match: true },
  { code: "7568", match: true },
  { code: "7569", match: true },
  { code: "7570", match: true },
  { code: "7571", match: true },
  { code: "7572", match: true },
  { code: "7573", match: true },
  { code: "7574", match: true },
  { code: "7575", match: true },
  { code: "7576", match: true },
  { code: "7577", match: true },
  { code: "7578", match: true },
  { code: "7579", match: true },
  { code: "7580", match: true },
  { code: "7581", match: true },
  { code: "7582", match: true },
  { code: "7583", match: true },
  { code: "7584", match: true },
  { code: "7585", match: true },
  { code: "7586", match: true },
  { code: "7587", match: true },
  { code: "7588", match: true },
  { code: "7589", match: true },
  { code: "7590", match: true },
  { code: "7591", match: true },
  { code: "7592", match: true },
  { code: "7593", match: true },
  { code: "7594", match: true },
  { code: "7595", match: true },
  { code: "7596", match: true },
  { code: "7597", match: true },
  { code: "7598", match: true },
  { code: "7599", match: true },
  { code: "7600", match: true },
  { code: "7601", match: true },
  { code: "7602", match: true },
  { code: "7603", match: true },
  { code: "7604", match: true },
  { code: "7605", match: true },
  { code: "7606", match: true },
  { code: "7607", match: true },
  { code: "7608", match: true },
  { code: "7609", match: true },
  { code: "7610", match: true },
  { code: "7611", match: true },
  { code: "7612", match: true },
  { code: "7613", match: true },
  { code: "7614", match: true },
  { code: "7615", match: true },
  { code: "7616", match: true },
  { code: "7617", match: true },
  { code: "7618", match: true },
  { code: "7619", match: true },
  { code: "7620", match: true },
  { code: "7621", match: true },
  { code: "7622", match: true },
  { code: "7623", match: true },
  { code: "7624", match: true },
  { code: "7625", match: true },
  { code: "7626", match: true },
  { code: "7627", match: true },
  { code: "7628", match: true },
  { code: "7629", match: true },
  { code: "7630", match: true },
  { code: "7631", match: true },
  { code: "7632", match: true },
  { code: "7633", match: true },
  { code: "7634", match: true },
  { code: "7635", match: true },
  { code: "7636", match: true },
  { code: "7637", match: true },
  { code: "7638", match: true },
  { code: "7639", match: true },
  { code: "7640", match: true },
  { code: "7641", match: true },
  { code: "7642", match: true },
  { code: "7643", match: true },
  { code: "7644", match: true },
  { code: "7645", match: true },
  { code: "7651", match: false },
  { code: "7652", match: false },
  { code: "7656", match: false },
  { code: "7657", match: true },
  { code: "7658", match: false },
  { code: "7659", match: true },
  { code: "7660", match: false },
  { code: "7661", match: true },
  { code: "7662", match: false },
  { code: "7663", match: true },
  { code: "7664", match: false },
  { code: "7665", match: true },
  { code: "7677", match: false },
  { code: "7678", match: true },
  { code: "7679", match: false },
  { code: "7680", match: true },
  { code: "7681", match: false },
  { code: "7682", match: true },
  { code: "7683", match: false },
  { code: "7684", match: false },
  { code: "7685", match: true },
  { code: "7686", match: true },
  { code: "7692", match: true },
  { code: "7693", match: true },
  { code: "7694", match: true },
  { code: "7695", match: true },
  { code: "7696", match: true },
  { code: "7699", match: false },
  { code: "7700", match: false },
  { code: "7707", match: false },
  { code: "7755", match: true },
  { code: "7756", match: true },
  { code: "7757", match: true },
  { code: "7758", match: true },
  { code: "7759", match: true },
  { code: "7760", match: true },
  { code: "7761", match: true },
  { code: "7762", match: true },
  { code: "7763", match: true },
  { code: "7764", match: true },
  { code: "7765", match: true },
  { code: "7766", match: true },
  { code: "7767", match: true },
  { code: "7768", match: true },
  { code: "7769", match: true },
  { code: "7770", match: true },
  { code: "7771", match: true },
  { code: "7772", match: true },
  { code: "7773", match: true },
  { code: "7774", match: true },
  { code: "7775", match: true },
  { code: "7776", match: true },
  { code: "7777", match: true },
  { code: "7778", match: true },
  { code: "7779", match: true },
  { code: "7780", match: true },
  { code: "7781", match: true },
  { code: "7782", match: true },
  { code: "7783", match: true },
  { code: "7784", match: true },
  { code: "7785", match: true },
  { code: "7786", match: true },
  { code: "7787", match: true },
  { code: "7789", match: false },
  { code: "7791", match: true },
  { code: "7792", match: true },
  { code: "7793", match: true },
  { code: "7794", match: true },
  { code: "7795", match: true },
  { code: "7796", match: false },
  { code: "7797", match: true },
  { code: "7798", match: true },
  { code: "7799", match: true },
  { code: "7800", match: true },
  { code: "7801", match: true },
  { code: "7803", match: true },
  { code: "7804", match: true },
  { code: "7805", match: true },
  { code: "7815", match: false },
  { code: "7816", match: true },
  { code: "7825", match: true },
  { code: "7826", match: true },
  { code: "7827", match: true },
  { code: "7828", match: true },
  { code: "7829", match: true },
  { code: "7830", match: true },
  { code: "7831", match: true },
  { code: "7832", match: false },
  { code: "7837", match: false },
  { code: "7839", match: false },
  { code: "7841", match: false },
  { code: "7842", match: false },
  { code: "7843", match: true },
  { code: "7844", match: true },
  { code: "7845", match: true },
  { code: "7846", match: true },
  { code: "7847", match: true },
  { code: "7848", match: true },
  { code: "7849", match: true },
  { code: "7850", match: true },
  { code: "7851", match: true },
  { code: "7852", match: true },
  { code: "7853", match: true },
  { code: "7854", match: true },
  { code: "7855", match: true },
  { code: "7856", match: true },
  { code: "7857", match: true },
  { code: "7858", match: true },
  { code: "7859", match: true },
  { code: "7877", match: false },
  { code: "7878", match: false },
  { code: "7879", match: false },
  { code: "7880", match: true },
  { code: "7881", match: true },
  { code: "7882", match: true },
  { code: "7883", match: true },
  { code: "7884", match: false },
  { code: "7885", match: true },
  { code: "7886", match: true },
  { code: "7887", match: true },
  { code: "7888", match: false },
  { code: "7889", match: false },
  { code: "7890", match: false },
  { code: "7899", match: true },
  { code: "7900", match: true },
  { code: "7901", match: true },
  { code: "7902", match: true },
  { code: "7903", match: true },
  { code: "7904", match: true },
  { code: "7905", match: true },
  { code: "7906", match: true },
  { code: "7907", match: true },
  { code: "7908", match: true },
  { code: "7909", match: true },
  { code: "7910", match: true },
  { code: "7911", match: true },
  { code: "7912", match: true },
  { code: "7914", match: false },
  { code: "7915", match: true },
  { code: "7916", match: false },
  { code: "7917", match: true },
  { code: "7918", match: false },
  { code: "7919", match: true },
  { code: "7920", match: false },
  { code: "7921", match: true },
  { code: "7922", match: false },
  { code: "7923", match: true },
  { code: "7924", match: false },
  { code: "7925", match: true },
  { code: "7926", match: false },
  { code: "7927", match: true },
  { code: "7928", match: false },
  { code: "7929", match: true },
  { code: "7930", match: false },
  { code: "7931", match: true },
  { code: "7954", match: false },
  { code: "7955", match: true },
  { code: "7956", match: true },
  { code: "7962", match: false },
  { code: "7968", match: false },
  { code: "7976", match: false },
  { code: "7979", match: false },
  { code: "7991", match: true },
  { code: "7992", match: true },
  { code: "8013", match: true },
  { code: "8014", match: true },
  { code: "8015", match: true },
  { code: "8016", match: true },
  { code: "8017", match: true },
  { code: "8018", match: true },
  { code: "8019", match: true },
  { code: "8020", match: true },
  { code: "8021", match: true },
  { code: "8022", match: true },
  { code: "8023", match: true },
  { code: "8024", match: true },
  { code: "8025", match: true },
  { code: "8026", match: true },
  { code: "8027", match: true },
  { code: "8028", match: true },
  { code: "8029", match: true },
  { code: "8030", match: true },
  { code: "8031", match: true },
  { code: "8032", match: true },
  { code: "8035", match: true },
  { code: "8036", match: true },
  { code: "8042", match: true },
  { code: "8043", match: true },
  { code: "8044", match: false },
  { code: "8045", match: false },
  { code: "8050", match: false },
  { code: "8051", match: false },
  { code: "8052", match: false },
  { code: "8053", match: false },
  { code: "8058", match: true },
  { code: "8059", match: true },
  { code: "8065", match: true },
  { code: "8066", match: true },
  { code: "8067", match: true },
  { code: "8068", match: true },
  { code: "8082", match: true },
  { code: "8083", match: true },
  { code: "8084", match: false },
  { code: "8085", match: true },
  { code: "8086", match: true },
  { code: "8088", match: true },
  { code: "8089", match: false },
  { code: "8090", match: true },
  { code: "8091", match: true },
  { code: "8092", match: true },
  { code: "8093", match: true },
  { code: "8095", match: true },
  { code: "8096", match: true },
  { code: "8097", match: true },
  { code: "8098", match: true },
  { code: "8099", match: true },
  { code: "8100", match: true },
  { code: "8101", match: true },
  { code: "8102", match: true },
  { code: "8103", match: true },
  { code: "8104", match: true },
  { code: "8105", match: true },
  { code: "8106", match: true },
  { code: "8107", match: true },
  { code: "8108", match: true },
  { code: "8109", match: true },
  { code: "8110", match: true },
  { code: "8111", match: true },
  { code: "8112", match: true },
  { code: "8113", match: true },
  { code: "8114", match: true },
  { code: "8115", match: true },
  { code: "8116", match: true },
  { code: "8117", match: false },
  { code: "8118", match: false },
  { code: "8119", match: true },
  { code: "8120", match: true },
  { code: "8121", match: true },
  { code: "8122", match: true },
  { code: "8123", match: true },
  { code: "8124", match: true },
  { code: "8125", match: true },
  { code: "8126", match: true },
  { code: "8127", match: true },
  { code: "8128", match: true },
  { code: "8129", match: true },
  { code: "8130", match: true },
  { code: "8131", match: true },
  { code: "8132", match: true },
  { code: "8133", match: true },
  { code: "8134", match: true },
  { code: "8135", match: false },
  { code: "8136", match: false },
  { code: "8137", match: true },
  { code: "8138", match: true },
  { code: "8139", match: true },
  { code: "8140", match: true },
  { code: "8141", match: true },
  { code: "8142", match: true },
  { code: "8143", match: true },
  { code: "8144", match: true },
  { code: "8145", match: true },
  { code: "8146", match: true },
  { code: "8147", match: true },
  { code: "8148", match: true },
  { code: "8149", match: true },
  { code: "8150", match: true },
  { code: "8151", match: true },
  { code: "8152", match: true },
  { code: "8153", match: true },
  { code: "8154", match: true },
  { code: "8155", match: true },
  { code: "8156", match: true },
  { code: "8157", match: true },
  { code: "8158", match: true },
  { code: "8159", match: false },
  { code: "8160", match: false },
  { code: "8161", match: true },
  { code: "8162", match: true },
  { code: "8163", match: true },
  { code: "8164", match: true },
  { code: "8165", match: true },
  { code: "8166", match: true },
  { code: "8167", match: true },
  { code: "8168", match: true },
  { code: "8169", match: true },
  { code: "8170", match: true },
  { code: "8171", match: true },
  { code: "8172", match: true },
  { code: "8173", match: true },
  { code: "8177", match: true },
  { code: "8179", match: true },
  { code: "8180", match: true },
  { code: "8181", match: true },
  { code: "8182", match: true },
  { code: "8184", match: true },
  { code: "8185", match: true },
  { code: "8187", match: true },
  { code: "8189", match: true },
  { code: "8191", match: true },
  { code: "8193", match: true },
  { code: "8196", match: true },
  { code: "8197", match: true },
  { code: "8198", match: true },
  { code: "8200", match: true },
  { code: "8201", match: true },
  { code: "8202", match: true },
  { code: "8203", match: true },
  { code: "8204", match: true },
  { code: "8205", match: true },
  { code: "8206", match: true },
  { code: "8207", match: true },
  { code: "8208", match: true },
  { code: "8209", match: true },
  { code: "8210", match: true },
  { code: "8212", match: true },
  { code: "8213", match: true },
  { code: "8214", match: true },
  { code: "8216", match: true },
  { code: "8218", match: true },
  { code: "8220", match: true },
  { code: "8222", match: true },
  { code: "8224", match: true },
  { code: "8225", match: true },
  { code: "8226", match: true },
  { code: "8227", match: false },
  { code: "8228", match: false },
  { code: "8230", match: false },
  { code: "8231", match: true },
  { code: "8232", match: true },
  { code: "8233", match: false },
  { code: "8235", match: true },
  { code: "8237", match: true },
  { code: "8238", match: false },
  { code: "8239", match: true },
  { code: "8240", match: true },
  { code: "8242", match: false },
  { code: "8244", match: true },
  { code: "8246", match: true },
  { code: "8247", match: false },
  { code: "8248", match: true },
  { code: "8249", match: true },
  { code: "8250", match: false },
  { code: "8251", match: true },
  { code: "8252", match: true },
  { code: "8253", match: false },
  { code: "8254", match: true },
  { code: "8255", match: true },
  { code: "8266", match: false },
  { code: "8267", match: false },
  { code: "8311", match: true },
  { code: "8312", match: true },
  { code: "8313", match: true },
  { code: "8314", match: true },
  { code: "8315", match: true },
  { code: "8316", match: true },
  { code: "8317", match: true },
  { code: "8318", match: true },
  { code: "8319", match: true },
  { code: "8320", match: true },
  { code: "8321", match: true },
  { code: "8322", match: true },
  { code: "8323", match: true },
  { code: "8324", match: true },
  { code: "8325", match: true },
  { code: "8326", match: true },
  { code: "8327", match: true },
  { code: "8328", match: true },
  { code: "8329", match: true },
  { code: "8330", match: true },
  { code: "8331", match: true },
  { code: "8332", match: true },
  { code: "8333", match: true },
  { code: "8334", match: true },
  { code: "8335", match: true },
  { code: "8336", match: true },
  { code: "8337", match: true },
  { code: "8338", match: true },
  { code: "8339", match: true },
  { code: "8340", match: true },
  { code: "8341", match: true },
  { code: "8342", match: true },
  { code: "8343", match: true },
  { code: "8344", match: true },
  { code: "8345", match: true },
  { code: "8346", match: true },
  { code: "8347", match: true },
  { code: "8348", match: true },
  { code: "8349", match: true },
  { code: "8350", match: true },
  { code: "8351", match: true },
  { code: "8352", match: false },
  { code: "8353", match: true },
  { code: "8357", match: false },
  { code: "8358", match: false },
  { code: "8360", match: true },
  { code: "8370", match: true },
  { code: "8378", match: false },
  { code: "8379", match: true },
  { code: "8380", match: true },
  { code: "8381", match: true },
  { code: "8382", match: true },
  { code: "8383", match: true },
  { code: "8384", match: true },
  { code: "8385", match: true },
  { code: "8387", match: true },
  { code: "8391", match: true },
  { code: "8395", match: true },
  { code: "8397", match: false },
  { code: "8399", match: true },
  { code: "8401", match: false },
  { code: "8403", match: true },
  { code: "8425", match: false },
  { code: "8426", match: true },
  { code: "8427", match: true },
  { code: "8428", match: false },
  { code: "8429", match: false },
  { code: "8430", match: true },
  { code: "8431", match: true },
  { code: "8433", match: false },
  { code: "8434", match: false },
  { code: "8441", match: false },
  { code: "8449", match: true },
  { code: "8455", match: true },
  { code: "8456", match: true },
  { code: "8518", match: true },
  { code: "8519", match: true },
  { code: "8520", match: true },
  { code: "8521", match: true },
  { code: "8522", match: true },
  { code: "8523", match: true },
  { code: "8524", match: true },
  { code: "8525", match: true },
  { code: "8526", match: true },
  { code: "8527", match: true },
  { code: "8528", match: true },
  { code: "8529", match: true },
  { code: "8531", match: true },
  { code: "8533", match: true },
  { code: "8534", match: true },
  { code: "8535", match: true },
  { code: "8536", match: true },
  { code: "8538", match: true },
  { code: "8539", match: true },
  { code: "8540", match: true },
  { code: "8541", match: false },
  { code: "8542", match: true },
  { code: "8543", match: false },
  { code: "8544", match: true },
  { code: "8545", match: true },
  { code: "8675", match: false },
  { code: "8677", match: true },
  { code: "8678", match: true },
  { code: "8679", match: true },
  { code: "8682", match: true },
  { code: "8683", match: false },
  { code: "8684", match: true },
  { code: "8685", match: true },
  { code: "8686", match: false },
  { code: "8687", match: true },
  { code: "8690", match: false },
  { code: "8691", match: false },
  { code: "8692", match: true },
  { code: "8693", match: true },
  { code: "8694", match: false },
  { code: "8697", match: false },
  { code: "8698", match: true },
  { code: "8699", match: true },
  { code: "8700", match: true },
  { code: "8701", match: true },
  { code: "8702", match: true },
  { code: "8703", match: true },
  { code: "8704", match: true },
  { code: "8705", match: true },
  { code: "8706", match: true },
  { code: "8707", match: true },
  { code: "8708", match: true },
  { code: "8709", match: true },
  { code: "8710", match: true },
  { code: "8711", match: true },
  { code: "8712", match: true },
  { code: "8713", match: true },
  { code: "8714", match: true },
  { code: "8715", match: true },
  { code: "8716", match: true },
  { code: "8717", match: true },
  { code: "8718", match: true },
  { code: "8719", match: true },
  { code: "8720", match: true },
  { code: "8721", match: true },
  { code: "8722", match: true },
  { code: "8723", match: true },
  { code: "8724", match: true },
  { code: "8725", match: true },
  { code: "8726", match: true },
  { code: "8727", match: true },
  { code: "8728", match: true },
  { code: "8729", match: true },
  { code: "8730", match: true },
  { code: "8731", match: true },
  { code: "8732", match: true },
  { code: "8733", match: true },
  { code: "8734", match: true },
  { code: "8735", match: true },
  { code: "8736", match: true },
  { code: "8737", match: true },
  { code: "8738", match: true },
  { code: "8739", match: true },
  { code: "8740", match: true },
  { code: "8741", match: true },
  { code: "8742", match: true },
  { code: "8743", match: true },
  { code: "8744", match: true },
  { code: "8745", match: true },
  { code: "8746", match: true },
  { code: "8747", match: true },
  { code: "8748", match: true },
  { code: "8749", match: true },
  { code: "8750", match: true },
  { code: "8751", match: true },
  { code: "8752", match: true },
  { code: "8753", match: true },
  { code: "8754", match: true },
  { code: "8755", match: true },
  { code: "8756", match: true },
  { code: "8757", match: true },
  { code: "8758", match: true },
  { code: "8759", match: true },
  { code: "8760", match: true },
  { code: "8761", match: true },
  { code: "8762", match: true },
  { code: "8763", match: true },
  { code: "8764", match: true },
  { code: "8765", match: true },
  { code: "8766", match: true },
  { code: "8767", match: true },
  { code: "8768", match: true },
  { code: "8769", match: true },
  { code: "8770", match: true },
  { code: "8771", match: true },
  { code: "8772", match: true },
  { code: "8773", match: true },
  { code: "8774", match: true },
  { code: "8775", match: true },
  { code: "8776", match: true },
  { code: "8777", match: true },
  { code: "8778", match: true },
  { code: "8779", match: true },
  { code: "8780", match: true },
  { code: "8781", match: true },
  { code: "8782", match: true },
  { code: "8783", match: true },
  { code: "8784", match: true },
  { code: "8785", match: true },
  { code: "8786", match: true },
  { code: "8787", match: true },
  { code: "8788", match: true },
  { code: "8789", match: true },
  { code: "8790", match: true },
  { code: "8791", match: true },
  { code: "8792", match: true },
  { code: "8793", match: true },
  { code: "8794", match: true },
  { code: "8795", match: true },
  { code: "8796", match: true },
  { code: "8797", match: true },
  { code: "8798", match: true },
  { code: "8799", match: true },
  { code: "8800", match: true },
  { code: "8801", match: true },
  { code: "8802", match: true },
  { code: "8803", match: true },
  { code: "8804", match: true },
  { code: "8805", match: true },
  { code: "8806", match: true },
  { code: "8807", match: true },
  { code: "8808", match: true },
  { code: "8809", match: true },
  { code: "8810", match: true },
  { code: "8811", match: true },
  { code: "8812", match: true },
  { code: "8813", match: true },
  { code: "8814", match: true },
  { code: "8815", match: true },
  { code: "8816", match: false },
  { code: "8817", match: true },
  { code: "8818", match: true },
  { code: "8826", match: true },
  { code: "8836", match: true },
  { code: "8837", match: true },
  { code: "8838", match: true },
  { code: "8839", match: true },
  { code: "8840", match: true },
  { code: "8841", match: false },
  { code: "8857", match: true },
  { code: "8858", match: true },
  { code: "8859", match: true },
  { code: "8860", match: true },
  { code: "8881", match: false },
  { code: "8888", match: true },
  { code: "8897", match: false },
  { code: "8898", match: false },
  { code: "8899", match: true },
  { code: "8900", match: true },
  { code: "8901", match: true },
  { code: "8902", match: true },
  { code: "8903", match: true },
  { code: "8904", match: false },
  { code: "8905", match: false },
  { code: "8906", match: true },
  { code: "8907", match: true },
  { code: "8908", match: true },
  { code: "8909", match: true },
  { code: "8910", match: true },
  { code: "8911", match: false },
  { code: "8912", match: true },
  { code: "8915", match: false },
  { code: "8916", match: true },
  { code: "8917", match: false },
  { code: "8918", match: true },
  { code: "8919", match: false },
  { code: "8920", match: true },
  { code: "8921", match: false },
  { code: "8922", match: true },
  { code: "8923", match: false },
  { code: "8924", match: true },
  { code: "8925", match: false },
  { code: "8926", match: true },
  { code: "8927", match: false },
  { code: "8928", match: true },
  { code: "8929", match: false },
  { code: "8930", match: true },
  { code: "8931", match: false },
  { code: "8932", match: true },
  { code: "8933", match: false },
  { code: "8934", match: true },
  { code: "8935", match: false },
  { code: "8936", match: true },
  { code: "8937", match: false },
  { code: "8938", match: true },
  { code: "8939", match: false },
  { code: "8940", match: true },
  { code: "8941", match: false },
  { code: "8942", match: true },
  { code: "8943", match: false },
  { code: "8944", match: true },
  { code: "8945", match: false },
  { code: "8946", match: true },
  { code: "8947", match: false },
  { code: "8948", match: true },
  { code: "8949", match: true },
  { code: "8950", match: true },
  { code: "8951", match: true },
  { code: "8972", match: true },
  { code: "8973", match: true },
  { code: "8974", match: true },
  { code: "8975", match: true },
  { code: "8976", match: true },
  { code: "8977", match: true },
  { code: "8978", match: true },
  { code: "8979", match: true },
  { code: "8980", match: true },
  { code: "8981", match: true },
  { code: "8982", match: true },
  { code: "8983", match: true },
  { code: "8984", match: true },
  { code: "8985", match: true },
  { code: "8986", match: true },
  { code: "8987", match: true },
  { code: "8988", match: true },
  { code: "8989", match: true },
  { code: "8990", match: true },
  { code: "8991", match: true },
  { code: "8992", match: true },
  { code: "8993", match: true },
  { code: "8994", match: true },
  { code: "8995", match: true },
  { code: "8996", match: true },
  { code: "8997", match: true },
  { code: "8998", match: true },
  { code: "8999", match: true },
  { code: "9000", match: true },
  { code: "9001", match: false },
  { code: "9002", match: true },
  { code: "9003", match: true },
  { code: "9004", match: false },
  { code: "9005", match: true },
  { code: "9006", match: true },
  { code: "9007", match: false },
  { code: "9008", match: true },
  { code: "9009", match: true },
  { code: "9010", match: false },
  { code: "9011", match: true },
  { code: "9012", match: true },
  { code: "9013", match: true },
  { code: "9014", match: true },
  { code: "9015", match: false },
  { code: "9016", match: true },
  { code: "9017", match: true },
  { code: "9018", match: true },
  { code: "9019", match: true },
  { code: "9039", match: true },
  { code: "9040", match: true },
  { code: "9053", match: true },
  { code: "9054", match: true },
  { code: "9055", match: true },
  { code: "9056", match: true },
  { code: "9057", match: true },
  { code: "9059", match: true },
  { code: "9060", match: true },
  { code: "9061", match: true },
  { code: "9062", match: true },
  { code: "9063", match: true },
  { code: "9064", match: true },
  { code: "9065", match: true },
  { code: "9066", match: true },
  { code: "9067", match: true },
  { code: "9068", match: true },
  { code: "9069", match: true },
  { code: "9070", match: false },
  { code: "9071", match: true },
  { code: "9072", match: true },
  { code: "9073", match: false },
  { code: "9074", match: true },
  { code: "9075", match: true },
  { code: "9130", match: false },
  { code: "9138", match: false },
  { code: "9139", match: true },
  { code: "9140", match: true },
  { code: "9141", match: true },
  { code: "9146", match: false },
  { code: "9147", match: true },
  { code: "9148", match: true },
  { code: "9149", match: true },
  { code: "9150", match: true },
  { code: "9151", match: false },
  { code: "9152", match: true },
  { code: "9153", match: true },
  { code: "9154", match: true },
  { code: "9155", match: true },
  { code: "9156", match: true },
  { code: "9157", match: true },
  { code: "9158", match: true },
  { code: "9159", match: true },
  { code: "9182", match: false },
  { code: "9183", match: true },
  { code: "9184", match: true },
  { code: "9191", match: true },
  { code: "9205", match: true },
  { code: "9206", match: true },
  { code: "9207", match: true },
  { code: "9208", match: true },
  { code: "9209", match: true },
  { code: "9210", match: true },
  { code: "9211", match: true },
  { code: "9212", match: true },
  { code: "9213", match: true },
  { code: "9214", match: true },
  { code: "9215", match: true },
  { code: "9216", match: true },
  { code: "9217", match: true },
  { code: "9218", match: true },
  { code: "9221", match: true },
  { code: "9222", match: true },
  { code: "9245", match: false },
  { code: "9248", match: false },
  { code: "9249", match: false },
  { code: "9250", match: false },
  { code: "9251", match: false },
  { code: "9252", match: true },
  { code: "9253", match: false },
  { code: "9254", match: true },
  { code: "9255", match: false },
  { code: "9265", match: true },
  { code: "9266", match: false },
  { code: "9267", match: false },
  { code: "9271", match: false },
  { code: "9272", match: false },
  { code: "9273", match: false },
  { code: "9274", match: false },
  { code: "9279", match: false },
  { code: "9284", match: true },
  { code: "9285", match: true },
  { code: "9286", match: true },
  { code: "9287", match: false },
  { code: "9288", match: false },
  { code: "9289", match: true },
  { code: "9290", match: true },
  { code: "9292", match: false },
  { code: "9293", match: true },
  { code: "9294", match: true },
  { code: "9295", match: true },
  { code: "9296", match: true },
  { code: "9297", match: true },
  { code: "9299", match: true },
  { code: "9300", match: true },
  { code: "9303", match: false },
  { code: "9306", match: true },
  { code: "9307", match: false },
  { code: "9308", match: true },
  { code: "9309", match: true },
  { code: "9311", match: false },
  { code: "9331", match: false },
  { code: "9332", match: true },
  { code: "9333", match: false },
  { code: "9335", match: false },
  { code: "9351", match: false },
  { code: "9354", match: true },
  { code: "9356", match: true },
  { code: "9357", match: true },
  { code: "9358", match: true },
  { code: "9359", match: true },
  { code: "9360", match: true },
  { code: "9364", match: true },
  { code: "9367", match: true },
  { code: "9368", match: true },
  { code: "9372", match: true },
  { code: "9373", match: true },
  { code: "9374", match: true },
  { code: "9377", match: true },
  { code: "9378", match: false },
  { code: "9379", match: true },
  { code: "9380", match: true },
  { code: "9384", match: true },
  { code: "9387", match: true },
  { code: "9388", match: true },
  { code: "9389", match: false },
  { code: "9390", match: false },
  { code: "9391", match: true },
  { code: "9392", match: false },
  { code: "9393", match: false },
  { code: "9394", match: false },
  { code: "9395", match: false },
  { code: "9396", match: false },
  { code: "9397", match: false },
  { code: "9398", match: false },
  { code: "9399", match: false },
  { code: "9400", match: false },
  { code: "9401", match: false },
  { code: "9402", match: false },
  { code: "9403", match: false },
  { code: "9404", match: true },
  { code: "9405", match: true },
  { code: "9406", match: true },
  { code: "9407", match: true },
  { code: "9422", match: true },
  { code: "9423", match: true },
  { code: "9424", match: true },
  { code: "9425", match: true },
  { code: "9426", match: true },
  { code: "9427", match: true },
  { code: "9428", match: true },
  { code: "9429", match: true },
  { code: "9430", match: true },
  { code: "9449", match: true },
  { code: "9450", match: true },
  { code: "9451", match: false },
  { code: "9452", match: true },
  { code: "9453", match: true },
  { code: "9456", match: true },
  { code: "9457", match: true },
  { code: "9458", match: false },
  { code: "9462", match: true },
  { code: "9463", match: true },
  { code: "9464", match: true },
  { code: "9468", match: false },
  { code: "9469", match: true },
  { code: "9470", match: false },
  { code: "9471", match: false },
  { code: "9473", match: true },
  { code: "9474", match: true },
  { code: "9475", match: true },
  { code: "9476", match: true },
  { code: "9477", match: true },
  { code: "9478", match: true },
  { code: "9479", match: true },
  { code: "9480", match: true },
  { code: "9481", match: true },
  { code: "9482", match: true },
  { code: "9487", match: true },
  { code: "9488", match: true },
  { code: "9489", match: true },
  { code: "9490", match: true },
  { code: "9491", match: true },
  { code: "9492", match: true },
  { code: "9493", match: true },
  { code: "9494", match: true },
  { code: "9498", match: true },
  { code: "9500", match: true },
  { code: "9501", match: false },
  { code: "9502", match: true },
  { code: "9503", match: true },
  { code: "9504", match: true },
  { code: "9505", match: true },
  { code: "9506", match: true },
  { code: "9507", match: true },
  { code: "9508", match: true },
  { code: "9509", match: true },
  { code: "9510", match: true },
  { code: "9511", match: true },
  { code: "9512", match: true },
  { code: "9513", match: true },
  { code: "9514", match: true },
  { code: "9515", match: true },
  { code: "9516", match: true },
  { code: "9517", match: true },
  { code: "9518", match: true },
  { code: "9519", match: true },
  { code: "9520", match: false },
  { code: "9521", match: true },
  { code: "9522", match: true },
  { code: "9523", match: true },
  { code: "9524", match: true },
  { code: "9525", match: true },
  { code: "9526", match: true },
  { code: "9527", match: true },
  { code: "9528", match: true },
  { code: "9529", match: false },
  { code: "9530", match: true },
  { code: "9531", match: true },
  { code: "9532", match: true },
  { code: "9533", match: true },
  { code: "9534", match: true },
  { code: "9535", match: true },
  { code: "9536", match: true },
  { code: "9537", match: true },
  { code: "9538", match: true },
  { code: "9539", match: true },
  { code: "9540", match: true },
  { code: "9541", match: true },
  { code: "9542", match: true },
  { code: "9543", match: true },
  { code: "9544", match: true },
  { code: "9545", match: false },
  { code: "9546", match: true },
  { code: "9547", match: true },
  { code: "9549", match: false },
  { code: "9650", match: false },
  { code: "9651", match: false },
  { code: "9656", match: true },
  { code: "9657", match: true },
  { code: "9663", match: false },
  { code: "9666", match: false },
  { code: "9669", match: false },
  { code: "9672", match: false },
  { code: "9674", match: true },
  { code: "9675", match: false },
  { code: "9678", match: false },
  { code: "9680", match: true },
  { code: "9681", match: false },
  { code: "9694", match: false },
  { code: "9695", match: true },
  { code: "9696", match: true },
  { code: "9697", match: true },
  { code: "9698", match: true },
  { code: "9699", match: true },
  { code: "9700", match: false },
  { code: "9701", match: true },
  { code: "9702", match: true },
  { code: "9705", match: true },
  { code: "9707", match: true },
  { code: "9709", match: true },
  { code: "9711", match: true },
  { code: "9712", match: true },
  { code: "9713", match: true },
  { code: "9714", match: true },
  { code: "9715", match: true },
  { code: "9716", match: true },
  { code: "9721", match: false },
  { code: "9722", match: false },
  { code: "9723", match: true },
  { code: "9724", match: true },
  { code: "9725", match: true },
  { code: "9739", match: true },
  { code: "9741", match: true },
  { code: "9742", match: true },
  { code: "9748", match: true },
  { code: "9749", match: true },
  { code: "9753", match: false },
  { code: "9754", match: true },
  { code: "9755", match: true },
  { code: "9758", match: true },
  { code: "9761", match: true },
  { code: "9762", match: true },
  { code: "9763", match: true },
  { code: "9766", match: true },
  { code: "9767", match: true },
  { code: "9775", match: false },
  { code: "9776", match: true },
  { code: "9777", match: true },
  { code: "9778", match: true },
  { code: "9779", match: true },
  { code: "9780", match: false },
  { code: "9781", match: true },
  { code: "9782", match: true },
  { code: "9783", match: true },
  { code: "9784", match: true },
  { code: "9785", match: true },
  { code: "9793", match: true },
  { code: "9794", match: true },
  { code: "9821", match: true },
  { code: "9822", match: true },
  { code: "9823", match: true },
  { code: "9824", match: true },
  { code: "9825", match: true },
  { code: "9826", match: true },
  { code: "9827", match: true },
  { code: "9828", match: true },
  { code: "9829", match: true },
  { code: "9830", match: true },
  { code: "9831", match: true },
  { code: "9832", match: true },
  { code: "9833", match: true },
  { code: "9834", match: true },
  { code: "9835", match: true },
  { code: "9836", match: true },
  { code: "9837", match: true },
  { code: "9838", match: true },
  { code: "9839", match: true },
  { code: "9840", match: true },
  { code: "9841", match: true },
  { code: "9842", match: true },
  { code: "9843", match: true },
  { code: "9844", match: true },
  { code: "9845", match: true },
  { code: "9846", match: true },
  { code: "9847", match: true },
  { code: "9848", match: true },
  { code: "9849", match: true },
  { code: "9850", match: true },
  { code: "9851", match: true },
  { code: "9852", match: true },
  { code: "9853", match: true },
  { code: "9854", match: true },
  { code: "9855", match: true },
  { code: "9856", match: true },
  { code: "9857", match: true },
  { code: "9858", match: true },
  { code: "9859", match: true },
  { code: "9860", match: true },
  { code: "9861", match: true },
  { code: "9862", match: true },
  { code: "9863", match: true },
  { code: "9864", match: true },
  { code: "9865", match: true },
  { code: "9866", match: true },
  { code: "9869", match: true },
  { code: "9870", match: true },
  { code: "9871", match: true },
  { code: "9874", match: true },
  { code: "9875", match: true },
  { code: "9880", match: true },
  { code: "9881", match: true },
  { code: "9883", match: true },
  { code: "9892", match: false },
  { code: "9893", match: true },
  { code: "9895", match: true },
  { code: "9897", match: true },
  { code: "9907", match: true },
  { code: "9920", match: true },
  { code: "9922", match: true },
  { code: "9923", match: false },
  { code: "9924", match: true },
  { code: "9927", match: false },
  { code: "9928", match: true },
  { code: "9929", match: true },
  { code: "9930", match: true },
  { code: "9931", match: true },
  { code: "9932", match: true },
  { code: "9933", match: true },
  { code: "9934", match: true },
  { code: "9935", match: true },
  { code: "9939", match: true },
  { code: "9943", match: true },
  { code: "9944", match: true },
  { code: "9945", match: false },
  { code: "9947", match: true },
  { code: "9948", match: true },
  { code: "9949", match: true },
  { code: "9950", match: true },
  { code: "9951", match: true },
  { code: "9952", match: true },
  { code: "9953", match: true },
  { code: "9964", match: true },
  { code: "9967", match: true },
  { code: "9968", match: true },
  { code: "9969", match: true },
  { code: "9972", match: true },
  { code: "9973", match: true },
  { code: "9974", match: true },
  { code: "9977", match: true },
  { code: "9978", match: true },
  { code: "9988", match: false },
  { code: "9989", match: true },
  { code: "9990", match: true },
  { code: "10150", match: false },
  { code: "10151", match: false },
  { code: "10156", match: true },
  { code: "10157", match: true },
  { code: "10158", match: true },
  { code: "10160", match: true },
  { code: "10162", match: true },
  { code: "10163", match: true },
  { code: "10164", match: true },
  { code: "10165", match: true },
  { code: "10166", match: true },
  { code: "10167", match: true },
  { code: "10168", match: true },
  { code: "10169", match: true },
  { code: "10170", match: true },
  { code: "10171", match: true },
  { code: "10172", match: true },
  { code: "10173", match: true },
  { code: "10174", match: true },
  { code: "10175", match: true },
  { code: "10176", match: false },
  { code: "10177", match: true },
  { code: "10178", match: true },
  { code: "10183", match: true },
  { code: "10184", match: true },
  { code: "10185", match: true },
  { code: "10188", match: true },
  { code: "10189", match: true },
  { code: "10190", match: false },
  { code: "10191", match: true },
  { code: "10194", match: true },
  { code: "10195", match: true },
  { code: "10196", match: true },
  { code: "10199", match: true },
  { code: "10200", match: true },
  { code: "10204", match: true },
  { code: "10207", match: true },
  { code: "10208", match: true },
  { code: "10209", match: true },
  { code: "10212", match: true },
  { code: "10213", match: true },
  { code: "10214", match: true },
  { code: "10217", match: true },
  { code: "10218", match: true },
  { code: "10219", match: true },
  { code: "10222", match: true },
  { code: "10223", match: true },
  { code: "10224", match: true },
  { code: "10227", match: true },
  { code: "10228", match: true },
  { code: "10229", match: true },
  { code: "10235", match: true },
  { code: "10236", match: true },
  { code: "10237", match: true },
  { code: "10240", match: true },
  { code: "10241", match: true },
  { code: "10245", match: true },
  { code: "10246", match: true },
  { code: "10249", match: true },
  { code: "10250", match: true },
  { code: "10252", match: true },
  { code: "10254", match: true },
  { code: "10256", match: true },
  { code: "10258", match: false },
  { code: "10260", match: true },
  { code: "10262", match: false },
  { code: "10265", match: true },
  { code: "10266", match: false },
  { code: "10268", match: true },
  { code: "10270", match: true },
  { code: "10272", match: true },
  { code: "10275", match: true },
  { code: "10276", match: true },
  { code: "10277", match: true },
  { code: "10280", match: true },
  { code: "10281", match: true },
  { code: "10282", match: false },
  { code: "10283", match: true },
  { code: "10284", match: true },
  { code: "10285", match: true },
  { code: "10286", match: true },
  { code: "10287", match: true },
  { code: "10288", match: true },
  { code: "10289", match: true },
  { code: "10290", match: true },
  { code: "10291", match: true },
  { code: "10293", match: true },
  { code: "10297", match: false },
  { code: "10298", match: true },
  { code: "10299", match: true },
  { code: "10300", match: true },
  { code: "10303", match: false },
  { code: "10304", match: true },
  { code: "10305", match: true },
  { code: "10306", match: true },
  { code: "10307", match: true },
  { code: "10308", match: false },
  { code: "10309", match: true },
  { code: "10310", match: true },
  { code: "10311", match: true },
  { code: "10312", match: true },
  { code: "10314", match: true },
  { code: "10315", match: true },
  { code: "10316", match: true },
  { code: "10317", match: true },
  { code: "10318", match: true },
  { code: "10326", match: false },
  { code: "10327", match: true },
  { code: "10328", match: true },
  { code: "10329", match: true },
  { code: "10345", match: true },
  { code: "10346", match: true },
  { code: "10349", match: false },
  { code: "10352", match: false },
  { code: "10353", match: false },
  { code: "10354", match: false },
  { code: "10355", match: true },
  { code: "10356", match: true },
  { code: "10357", match: true },
  { code: "10364", match: false },
  { code: "10365", match: true },
  { code: "10370", match: false },
  { code: "10371", match: false },
  { code: "10372", match: false },
  { code: "10373", match: false },
  { code: "10374", match: false },
  { code: "10375", match: false },
  { code: "10376", match: false },
  { code: "10377", match: false },
  { code: "10378", match: false },
  { code: "10379", match: false },
  { code: "10412", match: false },
  { code: "10413", match: true },
  { code: "10414", match: true },
  { code: "10448", match: true },
  { code: "10449", match: true },
  { code: "10450", match: true },
  { code: "10451", match: true },
  { code: "10452", match: true },
  { code: "10453", match: true },
  { code: "10454", match: true },
  { code: "10455", match: true },
  { code: "10456", match: true },
  { code: "10457", match: true },
  { code: "10458", match: true },
  { code: "10459", match: true },
  { code: "10460", match: true },
  { code: "10461", match: true },
  { code: "10462", match: true },
  { code: "10463", match: true },
  { code: "10464", match: true },
  { code: "10465", match: true },
  { code: "10468", match: true },
  { code: "10471", match: true },
  { code: "10472", match: true },
  { code: "10473", match: false },
  { code: "10474", match: true },
  { code: "10475", match: true },
  { code: "10477", match: true },
  { code: "10480", match: true },
  { code: "10481", match: true },
  { code: "10482", match: false },
  { code: "10483", match: false },
  { code: "10484", match: false },
  { code: "10485", match: false },
  { code: "10486", match: true },
  { code: "10487", match: true },
  { code: "10488", match: true },
  { code: "10497", match: true },
  { code: "10498", match: true },
  { code: "10499", match: true },
  { code: "10500", match: true },
  { code: "10507", match: true },
  { code: "10516", match: true },
  { code: "10545", match: true },
  { code: "10731", match: true },
  { code: "10732", match: true },
  { code: "10733", match: true },
  { code: "11114", match: true },
  { code: "11115", match: true },
  { code: "11116", match: true },
  { code: "11117", match: true },
  { code: "11118", match: true },
  { code: "20000", match: false },
  { code: "20001", match: true },
  { code: "20002", match: true },
  { code: "20003", match: true },
  { code: "20004", match: true },
  { code: "20005", match: true },
  { code: "20006", match: true },
  { code: "20007", match: true },
  { code: "20008", match: true },
  { code: "20009", match: true },
  { code: "20010", match: true },
  { code: "20011", match: true },
  { code: "20012", match: true },
  { code: "20013", match: true },
  { code: "20014", match: true },
  { code: "20015", match: true },
  { code: "20016", match: true },
  { code: "20017", match: true },
  { code: "20018", match: true },
  { code: "20019", match: true },
  { code: "20020", match: true },
  { code: "20021", match: true },
  { code: "20022", match: true },
  { code: "20023", match: true },
  { code: "20024", match: true },
  { code: "20025", match: true },
  { code: "20026", match: true },
  { code: "20027", match: true },
  { code: "20028", match: true },
  { code: "20029", match: true },
  { code: "20030", match: true },
  { code: "20031", match: true },
  { code: "20032", match: true },
  { code: "20033", match: true },
  { code: "20034", match: false },
  { code: "20035", match: false },
  { code: "20036", match: false },
  { code: "20037", match: true },
  { code: "20038", match: true },
  { code: "20039", match: false },
  { code: "20040", match: true },
  { code: "20041", match: true },
  { code: "20042", match: true },
  { code: "20043", match: false },
  { code: "20044", match: false },
  { code: "20045", match: true },
  { code: "20046", match: true },
  { code: "20047", match: true },
  { code: "20048", match: true },
  { code: "20049", match: true },
  { code: "20050", match: true },
  { code: "20064", match: true },
  { code: "20065", match: true },
  { code: "20066", match: true },
  { code: "20067", match: true },
  { code: "20068", match: true },
  { code: "20069", match: true },
  { code: "20070", match: true },
  { code: "20071", match: true },
  { code: "20072", match: true },
  { code: "20073", match: true },
  { code: "20074", match: true },
  { code: "20075", match: true },
  { code: "20076", match: true },
  { code: "20077", match: true },
  { code: "20078", match: true },
  { code: "20079", match: true },
  { code: "20080", match: true },
  { code: "20081", match: true },
  { code: "20082", match: true },
  { code: "20083", match: true },
  { code: "20084", match: true },
  { code: "20085", match: true },
  { code: "20086", match: true },
  { code: "20087", match: true },
  { code: "20088", match: true },
  { code: "20089", match: true },
  { code: "20090", match: true },
  { code: "20091", match: true },
  { code: "20092", match: true },
  { code: "20135", match: true },
  { code: "20136", match: true },
  { code: "20137", match: true },
  { code: "20138", match: true },
  { code: "20248", match: true },
  { code: "20249", match: true },
  { code: "20250", match: true },
  { code: "20251", match: true },
  { code: "20252", match: true },
  { code: "20253", match: true },
  { code: "20254", match: true },
  { code: "20255", match: true },
  { code: "20256", match: true },
  { code: "20257", match: true },
  { code: "20258", match: true },
  { code: "20348", match: true },
  { code: "20349", match: true },
  { code: "20350", match: true },
  { code: "20351", match: true },
  { code: "20352", match: true },
  { code: "20353", match: true },
  { code: "20354", match: true },
  { code: "20355", match: true },
  { code: "20356", match: true },
  { code: "20357", match: true },
  { code: "20358", match: true },
  { code: "20436", match: true },
  { code: "20437", match: true },
  { code: "20438", match: true },
  { code: "20439", match: true },
  { code: "20440", match: true },
  { code: "20499", match: true },
  { code: "20538", match: true },
  { code: "20539", match: true },
  { code: "20790", match: true },
  { code: "20791", match: true },
  { code: "20822", match: true },
  { code: "20823", match: true },
  { code: "20824", match: true },
  { code: "20904", match: true },
  { code: "20905", match: true },
  { code: "20906", match: true },
  { code: "20907", match: true },
  { code: "20908", match: true },
  { code: "20909", match: true },
  { code: "20910", match: true },
  { code: "20911", match: true },
  { code: "20912", match: true },
  { code: "20913", match: true },
  { code: "20914", match: true },
  { code: "20915", match: true },
  { code: "20916", match: true },
  { code: "20917", match: true },
  { code: "20918", match: true },
  { code: "20919", match: true },
  { code: "20920", match: true },
  { code: "20921", match: true },
  { code: "20922", match: true },
  { code: "20923", match: true },
  { code: "20924", match: true },
  { code: "20925", match: true },
  { code: "20926", match: true },
  { code: "20927", match: true },
  { code: "20928", match: true },
  { code: "20929", match: true },
  { code: "20930", match: true },
  { code: "20931", match: true },
  { code: "20932", match: true },
  { code: "20934", match: false },
  { code: "20935", match: false },
  { code: "20936", match: false },
  { code: "21004", match: true },
  { code: "21005", match: true },
  { code: "21006", match: true },
  { code: "21007", match: true },
  { code: "21008", match: true },
  { code: "21009", match: true },
  { code: "21010", match: true },
  { code: "21011", match: true },
  { code: "21012", match: true },
  { code: "21013", match: true },
  { code: "21014", match: true },
  { code: "21015", match: true },
  { code: "21016", match: true },
  { code: "21017", match: true },
  { code: "21018", match: true },
  { code: "21019", match: true },
  { code: "21020", match: true },
  { code: "21021", match: true },
  { code: "21022", match: true },
  { code: "21023", match: true },
  { code: "21024", match: true },
  { code: "21025", match: true },
  { code: "21026", match: true },
  { code: "21027", match: true },
  { code: "21028", match: true },
  { code: "21029", match: true },
  { code: "21030", match: true },
  { code: "21031", match: true },
  { code: "21032", match: true },
  { code: "21035", match: false },
  { code: "21036", match: false },
  { code: "21037", match: false },
  { code: "21095", match: false },
  { code: "21096", match: false },
  { code: "21097", match: false },
  { code: "21100", match: false },
  { code: "21148", match: false },
  { code: "21149", match: false },
  { code: "21150", match: true },
  { code: "21207", match: true },
  { code: "21208", match: true },
  { code: "21209", match: true },
  { code: "21210", match: true },
  { code: "21211", match: true },
  { code: "21212", match: true },
  { code: "21213", match: true },
  { code: "21214", match: true },
  { code: "21215", match: true },
  { code: "21216", match: true },
  { code: "21217", match: true },
  { code: "21218", match: true },
  { code: "21219", match: true },
  { code: "21220", match: true },
  { code: "21221", match: true },
  { code: "21222", match: true },
  { code: "21223", match: true },
  { code: "21224", match: true },
  { code: "21225", match: true },
  { code: "21226", match: true },
  { code: "21227", match: true },
  { code: "21228", match: true },
  { code: "21229", match: true },
  { code: "21230", match: true },
  { code: "21231", match: true },
  { code: "21232", match: true },
  { code: "21233", match: true },
  { code: "21234", match: true },
  { code: "21235", match: true },
  { code: "21236", match: true },
  { code: "21237", match: true },
  { code: "21238", match: true },
  { code: "21239", match: true },
  { code: "21240", match: true },
  { code: "21241", match: true },
  { code: "21242", match: true },
  { code: "21243", match: true },
  { code: "21244", match: true },
  { code: "21245", match: true },
  { code: "21246", match: true },
  { code: "21247", match: true },
  { code: "21248", match: true },
  { code: "21249", match: true },
  { code: "21250", match: true },
  { code: "21251", match: true },
  { code: "21252", match: true },
  { code: "21253", match: true },
  { code: "21254", match: true },
  { code: "21255", match: true },
  { code: "21256", match: true },
  { code: "21257", match: true },
  { code: "21258", match: true },
  { code: "21259", match: true },
  { code: "21260", match: true },
  { code: "21261", match: true },
  { code: "21262", match: true },
  { code: "21263", match: true },
  { code: "21264", match: true },
  { code: "21291", match: false },
  { code: "21292", match: false },
  { code: "21307", match: true },
  { code: "21308", match: true },
  { code: "21309", match: true },
  { code: "21310", match: true },
  { code: "21311", match: true },
  { code: "21312", match: true },
  { code: "21313", match: true },
  { code: "21314", match: true },
  { code: "21315", match: true },
  { code: "21316", match: true },
  { code: "21317", match: true },
  { code: "21318", match: true },
  { code: "21319", match: true },
  { code: "21320", match: true },
  { code: "21321", match: true },
  { code: "21322", match: true },
  { code: "21323", match: true },
  { code: "21324", match: true },
  { code: "21325", match: true },
  { code: "21326", match: true },
  { code: "21327", match: true },
  { code: "21328", match: true },
  { code: "21329", match: true },
  { code: "21330", match: true },
  { code: "21331", match: true },
  { code: "21332", match: true },
  { code: "21333", match: true },
  { code: "21334", match: true },
  { code: "21335", match: true },
  { code: "21336", match: true },
  { code: "21337", match: true },
  { code: "21338", match: true },
  { code: "21339", match: true },
  { code: "21340", match: true },
  { code: "21341", match: true },
  { code: "21342", match: true },
  { code: "21343", match: true },
  { code: "21344", match: true },
  { code: "21345", match: true },
  { code: "21346", match: true },
  { code: "21347", match: true },
  { code: "21348", match: true },
  { code: "21349", match: true },
  { code: "21350", match: true },
  { code: "21351", match: true },
  { code: "21352", match: true },
  { code: "21353", match: true },
  { code: "21354", match: true },
  { code: "21355", match: true },
  { code: "21356", match: true },
  { code: "21357", match: true },
  { code: "21358", match: true },
  { code: "21359", match: true },
  { code: "21360", match: true },
  { code: "21361", match: true },
  { code: "21362", match: true },
  { code: "21363", match: true },
  { code: "21364", match: true },
  { code: "21413", match: true },
  { code: "21414", match: true },
  { code: "21415", match: true },
  { code: "21416", match: true },
  { code: "21417", match: true },
  { code: "21418", match: true },
  { code: "21419", match: true },
  { code: "21420", match: true },
  { code: "21421", match: true },
  { code: "21422", match: true },
  { code: "21423", match: true },
  { code: "21453", match: true },
  { code: "21454", match: true },
  { code: "21455", match: true },
  { code: "21456", match: true },
  { code: "21457", match: true },
  { code: "21458", match: true },
  { code: "21459", match: true },
  { code: "21460", match: true },
  { code: "21461", match: true },
  { code: "21462", match: true },
  { code: "21463", match: true },
  { code: "21473", match: true },
  { code: "21474", match: true },
  { code: "21475", match: true },
  { code: "21476", match: true },
  { code: "21477", match: true },
  { code: "21478", match: true },
  { code: "21479", match: true },
  { code: "21480", match: true },
  { code: "21481", match: true },
  { code: "21482", match: true },
  { code: "21483", match: true },
  { code: "21500", match: true },
  { code: "21780", match: true },
  { code: "21781", match: true },
  { code: "21782", match: true },
  { code: "21818", match: false },
  { code: "21891", match: false },
  { code: "21892", match: false },
  { code: "21893", match: false },
  { code: "21894", match: false },
  { code: "21896", match: false },
  { code: "21897", match: false },
  { code: "21898", match: false },
  { code: "21899", match: false },
  { code: "22032", match: true },
  { code: "22033", match: true },
  { code: "22091", match: false },
  { code: "22092", match: true },
  { code: "22171", match: true },
  { code: "22172", match: true },
  { code: "22173", match: true },
  { code: "22174", match: true },
  { code: "22175", match: true },
  { code: "22176", match: true },
  { code: "22177", match: true },
  { code: "22181", match: true },
  { code: "22182", match: true },
  { code: "22183", match: true },
  { code: "22184", match: true },
  { code: "22185", match: true },
  { code: "22186", match: true },
  { code: "22187", match: true },
  { code: "22191", match: true },
  { code: "22192", match: true },
  { code: "22193", match: true },
  { code: "22194", match: true },
  { code: "22195", match: true },
  { code: "22196", match: true },
  { code: "22197", match: true },
  { code: "22207", match: true },
  { code: "22208", match: true },
  { code: "22209", match: true },
  { code: "22210", match: true },
  { code: "22211", match: true },
  { code: "22212", match: true },
  { code: "22213", match: true },
  { code: "22214", match: true },
  { code: "22215", match: true },
  { code: "22216", match: true },
  { code: "22217", match: true },
  { code: "22218", match: true },
  { code: "22219", match: true },
  { code: "22220", match: true },
  { code: "22221", match: true },
  { code: "22222", match: true },
  { code: "22229", match: true },
  { code: "22230", match: true },
  { code: "22231", match: true },
  { code: "22232", match: true },
  { code: "22234", match: true },
  { code: "22235", match: true },
  { code: "22236", match: true },
  { code: "22239", match: true },
  { code: "22240", match: true },
  { code: "22243", match: true },
  { code: "22244", match: true },
  { code: "22245", match: true },
  { code: "22246", match: true },
  { code: "22247", match: true },
  { code: "22248", match: true },
  { code: "22249", match: true },
  { code: "22250", match: true },
  { code: "22262", match: true },
  { code: "22263", match: true },
  { code: "22264", match: true },
  { code: "22265", match: true },
  { code: "22275", match: true },
  { code: "22277", match: false },
  { code: "22279", match: false },
  { code: "22281", match: false },
  { code: "22283", match: false },
  { code: "22285", match: false },
  { code: "22287", match: false },
  { code: "22289", match: false },
  { code: "22291", match: false },
  { code: "22293", match: false },
  { code: "22300", match: false },
  { code: "22307", match: true },
  { code: "22308", match: true },
  { code: "22309", match: true },
  { code: "22310", match: true },
  { code: "22311", match: true },
  { code: "22312", match: true },
  { code: "22313", match: true },
  { code: "22314", match: true },
  { code: "22315", match: true },
  { code: "22316", match: true },
  { code: "22317", match: true },
  { code: "22318", match: true },
  { code: "22319", match: true },
  { code: "22320", match: true },
  { code: "22321", match: true },
  { code: "22322", match: true },
  { code: "22332", match: true },
  { code: "22337", match: true },
  { code: "22338", match: true },
  { code: "22348", match: true },
  { code: "22349", match: true },
  { code: "22350", match: true },
  { code: "22351", match: true },
  { code: "22352", match: true },
  { code: "22353", match: true },
  { code: "22354", match: true },
  { code: "22355", match: true },
  { code: "22356", match: true },
  { code: "22357", match: true },
  { code: "22391", match: true },
  { code: "22392", match: true },
  { code: "22407", match: true },
  { code: "22408", match: true },
  { code: "22409", match: true },
  { code: "22410", match: true },
  { code: "22411", match: true },
  { code: "22412", match: true },
  { code: "22413", match: true },
  { code: "22414", match: true },
  { code: "22415", match: true },
  { code: "22416", match: true },
  { code: "22417", match: true },
  { code: "22418", match: true },
  { code: "22419", match: true },
  { code: "22420", match: true },
  { code: "22421", match: true },
  { code: "22422", match: true },
  { code: "22462", match: true },
  { code: "22463", match: true },
  { code: "22464", match: true },
  { code: "22465", match: true },
  { code: "22521", match: true },
  { code: "22522", match: true },
  { code: "22523", match: true },
  { code: "22524", match: true },
  { code: "22525", match: true },
  { code: "22607", match: true },
  { code: "22608", match: true },
  { code: "22609", match: true },
  { code: "22610", match: true },
  { code: "22611", match: true },
  { code: "22612", match: true },
  { code: "22613", match: true },
  { code: "22614", match: true },
  { code: "22615", match: true },
  { code: "22616", match: true },
  { code: "22617", match: true },
  { code: "22618", match: true },
  { code: "22619", match: true },
  { code: "22620", match: true },
  { code: "22621", match: true },
  { code: "22622", match: true },
  { code: "22639", match: true },
  { code: "22641", match: true },
  { code: "22642", match: true },
  { code: "22643", match: true },
  { code: "22644", match: true },
  { code: "22645", match: true },
  { code: "22646", match: true },
  { code: "22648", match: true },
  { code: "22649", match: true },
  { code: "22650", match: true },
  { code: "22651", match: true },
  { code: "22652", match: true },
  { code: "22653", match: true },
  { code: "22654", match: true },
  { code: "22655", match: true },
  { code: "22656", match: true },
  { code: "22657", match: true },
  { code: "22700", match: false },
  { code: "22707", match: true },
  { code: "22708", match: true },
  { code: "22709", match: true },
  { code: "22710", match: true },
  { code: "22711", match: true },
  { code: "22712", match: true },
  { code: "22713", match: true },
  { code: "22714", match: true },
  { code: "22715", match: true },
  { code: "22716", match: true },
  { code: "22717", match: true },
  { code: "22718", match: true },
  { code: "22719", match: true },
  { code: "22720", match: true },
  { code: "22721", match: true },
  { code: "22722", match: true },
  { code: "22739", match: true },
  { code: "22762", match: true },
  { code: "22763", match: true },
  { code: "22764", match: true },
  { code: "22765", match: true },
  { code: "22770", match: true },
  { code: "22780", match: true },
  { code: "22807", match: true },
  { code: "22808", match: true },
  { code: "22809", match: true },
  { code: "22810", match: true },
  { code: "22811", match: true },
  { code: "22812", match: true },
  { code: "22813", match: true },
  { code: "22814", match: true },
  { code: "22815", match: true },
  { code: "22816", match: true },
  { code: "22817", match: true },
  { code: "22818", match: true },
  { code: "22819", match: true },
  { code: "22820", match: true },
  { code: "22821", match: true },
  { code: "22822", match: true },
  { code: "22832", match: true },
  { code: "22991", match: true },
  { code: "22992", match: true },
  { code: "22993", match: true },
  { code: "22994", match: true },
  { code: "23028", match: true },
  { code: "23029", match: false },
  { code: "23030", match: false },
  { code: "23031", match: false },
  { code: "23032", match: true },
  { code: "23033", match: true },
  { code: "23034", match: true },
  { code: "23035", match: true },
  { code: "23036", match: false },
  { code: "23037", match: true },
  { code: "23038", match: true },
  { code: "23090", match: false },
  { code: "23095", match: false },
  { code: "23239", match: true },
  { code: "23240", match: true },
  { code: "23301", match: true },
  { code: "23302", match: true },
  { code: "23303", match: true },
  { code: "23304", match: true },
  { code: "23305", match: true },
  { code: "23306", match: true },
  { code: "23307", match: true },
  { code: "23308", match: true },
  { code: "23309", match: true },
  { code: "23310", match: true },
  { code: "23311", match: true },
  { code: "23312", match: true },
  { code: "23313", match: true },
  { code: "23314", match: true },
  { code: "23315", match: true },
  { code: "23316", match: true },
  { code: "23317", match: true },
  { code: "23318", match: true },
  { code: "23319", match: true },
  { code: "23320", match: true },
  { code: "23321", match: true },
  { code: "23322", match: true },
  { code: "23323", match: true },
  { code: "23324", match: true },
  { code: "23325", match: true },
  { code: "23326", match: true },
  { code: "23327", match: true },
  { code: "23328", match: true },
  { code: "23329", match: true },
  { code: "23330", match: true },
  { code: "23331", match: true },
  { code: "23332", match: true },
  { code: "23333", match: true },
  { code: "23433", match: false },
  { code: "23700", match: true },
  { code: "23830", match: true },
  { code: "23831", match: true },
  { code: "23832", match: true },
  { code: "23833", match: true },
  { code: "23834", match: true },
  { code: "23835", match: true },
  { code: "23836", match: true },
  { code: "23837", match: true },
  { code: "23838", match: true },
  { code: "23839", match: true },
  { code: "23840", match: true },
  { code: "23841", match: true },
  { code: "23842", match: true },
  { code: "23843", match: true },
  { code: "23844", match: true },
  { code: "23845", match: true },
  { code: "23846", match: true },
  { code: "23847", match: true },
  { code: "23848", match: true },
  { code: "23849", match: true },
  { code: "23850", match: true },
  { code: "23851", match: true },
  { code: "23852", match: true },
  { code: "23866", match: true },
  { code: "23867", match: true },
  { code: "23868", match: true },
  { code: "23869", match: true },
  { code: "23870", match: true },
  { code: "23871", match: true },
  { code: "23872", match: true },
  { code: "23877", match: true },
  { code: "23878", match: true },
  { code: "23879", match: true },
  { code: "23880", match: true },
  { code: "23881", match: true },
  { code: "23882", match: true },
  { code: "23883", match: true },
  { code: "23884", match: true },
  { code: "23887", match: true },
  { code: "23888", match: true },
  { code: "23889", match: true },
  { code: "23890", match: true },
  { code: "23891", match: true },
  { code: "23892", match: true },
  { code: "23893", match: true },
  { code: "23894", match: true },
  { code: "23946", match: true },
  { code: "23947", match: true },
  { code: "23948", match: false },
  { code: "24047", match: true },
  { code: "24048", match: true },
  { code: "24100", match: true },
  { code: "24200", match: false },
  { code: "24305", match: false },
  { code: "24306", match: false },
  { code: "24311", match: false },
  { code: "24312", match: false },
  { code: "24313", match: false },
  { code: "24342", match: false },
  { code: "24343", match: false },
  { code: "24344", match: false },
  { code: "24345", match: false },
  { code: "24346", match: false },
  { code: "24347", match: false },
  { code: "24370", match: true },
  { code: "24371", match: true },
  { code: "24372", match: true },
  { code: "24373", match: true },
  { code: "24374", match: true },
  { code: "24375", match: false },
  { code: "24376", match: false },
  { code: "24377", match: false },
  { code: "24378", match: false },
  { code: "24379", match: false },
  { code: "24380", match: false },
  { code: "24381", match: false },
  { code: "24382", match: true },
  { code: "24383", match: false },
  { code: "24500", match: false },
  { code: "24547", match: false },
  { code: "24548", match: false },
  { code: "24571", match: false },
  { code: "24600", match: true },
  { code: "24718", match: true },
  { code: "24719", match: true },
  { code: "24720", match: true },
  { code: "24817", match: false },
  { code: "24818", match: false },
  { code: "24819", match: true },
  { code: "24820", match: true },
  { code: "24821", match: false },
  { code: "24877", match: false },
  { code: "24878", match: false },
  { code: "24879", match: false },
  { code: "24880", match: false },
  { code: "24881", match: false },
  { code: "24882", match: false },
  { code: "24891", match: false },
  { code: "24892", match: false },
  { code: "24893", match: false },
  { code: "25000", match: true },
  { code: "25231", match: true },
  { code: "25391", match: false },
  { code: "25392", match: false },
  { code: "25393", match: true },
  { code: "25394", match: true },
  { code: "25395", match: true },
  { code: "25700", match: false },
  { code: "25828", match: true },
  { code: "25829", match: true },
  { code: "25830", match: true },
  { code: "25831", match: true },
  { code: "25832", match: true },
  { code: "25833", match: true },
  { code: "25834", match: true },
  { code: "25835", match: true },
  { code: "25836", match: true },
  { code: "25837", match: true },
  { code: "25838", match: true },
  { code: "25884", match: true },
  { code: "25932", match: true },
  { code: "26191", match: true },
  { code: "26192", match: true },
  { code: "26193", match: true },
  { code: "26194", match: true },
  { code: "26195", match: true },
  { code: "26237", match: false },
  { code: "26331", match: true },
  { code: "26332", match: true },
  { code: "26391", match: true },
  { code: "26392", match: true },
  { code: "26393", match: true },
  { code: "26432", match: true },
  { code: "26591", match: true },
  { code: "26592", match: true },
  { code: "26632", match: false },
  { code: "26692", match: false },
  { code: "26701", match: true },
  { code: "26702", match: true },
  { code: "26703", match: true },
  { code: "26704", match: true },
  { code: "26705", match: true },
  { code: "26706", match: true },
  { code: "26707", match: true },
  { code: "26708", match: true },
  { code: "26709", match: true },
  { code: "26710", match: true },
  { code: "26711", match: true },
  { code: "26712", match: true },
  { code: "26713", match: true },
  { code: "26714", match: true },
  { code: "26715", match: true },
  { code: "26716", match: true },
  { code: "26717", match: true },
  { code: "26718", match: true },
  { code: "26719", match: true },
  { code: "26720", match: true },
  { code: "26721", match: true },
  { code: "26722", match: true },
  { code: "26729", match: true },
  { code: "26730", match: true },
  { code: "26731", match: true },
  { code: "26732", match: true },
  { code: "26733", match: true },
  { code: "26734", match: true },
  { code: "26735", match: true },
  { code: "26736", match: true },
  { code: "26737", match: true },
  { code: "26738", match: true },
  { code: "26739", match: true },
  { code: "26740", match: true },
  { code: "26741", match: true },
  { code: "26742", match: true },
  { code: "26743", match: true },
  { code: "26744", match: true },
  { code: "26745", match: true },
  { code: "26746", match: true },
  { code: "26747", match: true },
  { code: "26748", match: true },
  { code: "26749", match: true },
  { code: "26750", match: true },
  { code: "26751", match: true },
  { code: "26752", match: true },
  { code: "26753", match: true },
  { code: "26754", match: true },
  { code: "26755", match: true },
  { code: "26756", match: true },
  { code: "26757", match: true },
  { code: "26758", match: true },
  { code: "26759", match: true },
  { code: "26760", match: true },
  { code: "26766", match: true },
  { code: "26767", match: true },
  { code: "26768", match: true },
  { code: "26769", match: true },
  { code: "26770", match: true },
  { code: "26771", match: true },
  { code: "26772", match: true },
  { code: "26773", match: true },
  { code: "26774", match: true },
  { code: "26775", match: true },
  { code: "26776", match: true },
  { code: "26777", match: true },
  { code: "26778", match: true },
  { code: "26779", match: true },
  { code: "26780", match: true },
  { code: "26781", match: true },
  { code: "26782", match: true },
  { code: "26783", match: true },
  { code: "26784", match: true },
  { code: "26785", match: true },
  { code: "26786", match: true },
  { code: "26787", match: true },
  { code: "26791", match: true },
  { code: "26792", match: true },
  { code: "26793", match: true },
  { code: "26794", match: true },
  { code: "26795", match: true },
  { code: "26796", match: true },
  { code: "26797", match: true },
  { code: "26798", match: true },
  { code: "26799", match: true },
  { code: "26801", match: true },
  { code: "26802", match: true },
  { code: "26803", match: true },
  { code: "26811", match: false },
  { code: "26812", match: true },
  { code: "26813", match: true },
  { code: "26814", match: true },
  { code: "26815", match: true },
  { code: "26819", match: true },
  { code: "26820", match: true },
  { code: "26821", match: true },
  { code: "26822", match: true },
  { code: "26823", match: true },
  { code: "26824", match: true },
  { code: "26825", match: true },
  { code: "26826", match: true },
  { code: "26830", match: true },
  { code: "26831", match: true },
  { code: "26832", match: true },
  { code: "26833", match: true },
  { code: "26834", match: true },
  { code: "26835", match: true },
  { code: "26836", match: true },
  { code: "26837", match: true },
  { code: "26841", match: true },
  { code: "26842", match: true },
  { code: "26843", match: true },
  { code: "26844", match: true },
  { code: "26845", match: true },
  { code: "26846", match: true },
  { code: "26847", match: true },
  { code: "26848", match: true },
  { code: "26849", match: true },
  { code: "26850", match: true },
  { code: "26851", match: true },
  { code: "26852", match: true },
  { code: "26853", match: true },
  { code: "26854", match: true },
  { code: "26855", match: true },
  { code: "26856", match: true },
  { code: "26857", match: true },
  { code: "26858", match: true },
  { code: "26859", match: true },
  { code: "26860", match: true },
  { code: "26861", match: true },
  { code: "26862", match: true },
  { code: "26863", match: true },
  { code: "26864", match: true },
  { code: "26865", match: true },
  { code: "26866", match: true },
  { code: "26867", match: true },
  { code: "26868", match: true },
  { code: "26869", match: true },
  { code: "26870", match: true },
  { code: "26891", match: true },
  { code: "26892", match: true },
  { code: "26893", match: true },
  { code: "26894", match: true },
  { code: "26895", match: true },
  { code: "26896", match: true },
  { code: "26897", match: true },
  { code: "26898", match: true },
  { code: "26899", match: true },
  { code: "26901", match: true },
  { code: "26902", match: true },
  { code: "26903", match: true },
  { code: "26904", match: true },
  { code: "26905", match: true },
  { code: "26906", match: true },
  { code: "26907", match: true },
  { code: "26908", match: true },
  { code: "26909", match: true },
  { code: "26910", match: true },
  { code: "26911", match: true },
  { code: "26912", match: true },
  { code: "26913", match: true },
  { code: "26914", match: true },
  { code: "26915", match: true },
  { code: "26916", match: true },
  { code: "26917", match: true },
  { code: "26918", match: true },
  { code: "26919", match: true },
  { code: "26920", match: true },
  { code: "26921", match: true },
  { code: "26922", match: true },
  { code: "26923", match: true },
  { code: "26929", match: true },
  { code: "26930", match: true },
  { code: "26931", match: true },
  { code: "26932", match: true },
  { code: "26933", match: true },
  { code: "26934", match: true },
  { code: "26935", match: true },
  { code: "26936", match: true },
  { code: "26937", match: true },
  { code: "26938", match: true },
  { code: "26939", match: true },
  { code: "26940", match: true },
  { code: "26941", match: true },
  { code: "26942", match: true },
  { code: "26943", match: true },
  { code: "26944", match: true },
  { code: "26945", match: true },
  { code: "26946", match: true },
  { code: "26948", match: true },
  { code: "26949", match: true },
  { code: "26950", match: true },
  { code: "26951", match: true },
  { code: "26952", match: true },
  { code: "26953", match: true },
  { code: "26954", match: true },
  { code: "26955", match: true },
  { code: "26956", match: true },
  { code: "26957", match: true },
  { code: "26958", match: true },
  { code: "26959", match: true },
  { code: "26960", match: true },
  { code: "26961", match: true },
  { code: "26962", match: true },
  { code: "26963", match: true },
  { code: "26964", match: true },
  { code: "26965", match: true },
  { code: "26966", match: true },
  { code: "26967", match: true },
  { code: "26968", match: true },
  { code: "26969", match: true },
  { code: "26970", match: true },
  { code: "26971", match: true },
  { code: "26972", match: true },
  { code: "26973", match: true },
  { code: "26974", match: true },
  { code: "26975", match: true },
  { code: "26976", match: true },
  { code: "26977", match: true },
  { code: "26978", match: true },
  { code: "26979", match: true },
  { code: "26980", match: true },
  { code: "26981", match: true },
  { code: "26982", match: true },
  { code: "26983", match: true },
  { code: "26984", match: true },
  { code: "26985", match: true },
  { code: "26986", match: true },
  { code: "26987", match: true },
  { code: "26988", match: true },
  { code: "26989", match: true },
  { code: "26990", match: true },
  { code: "26991", match: true },
  { code: "26992", match: true },
  { code: "26993", match: true },
  { code: "26994", match: true },
  { code: "26995", match: true },
  { code: "26996", match: true },
  { code: "26997", match: true },
  { code: "26998", match: true },
  { code: "27037", match: true },
  { code: "27038", match: true },
  { code: "27039", match: true },
  { code: "27040", match: true },
  { code: "27120", match: true },
  { code: "27200", match: false },
  { code: "27205", match: false },
  { code: "27206", match: false },
  { code: "27207", match: false },
  { code: "27208", match: false },
  { code: "27209", match: false },
  { code: "27210", match: false },
  { code: "27211", match: false },
  { code: "27212", match: false },
  { code: "27213", match: false },
  { code: "27214", match: false },
  { code: "27215", match: false },
  { code: "27216", match: false },
  { code: "27217", match: false },
  { code: "27218", match: false },
  { code: "27219", match: false },
  { code: "27220", match: false },
  { code: "27221", match: false },
  { code: "27222", match: false },
  { code: "27223", match: false },
  { code: "27224", match: false },
  { code: "27225", match: false },
  { code: "27226", match: false },
  { code: "27227", match: false },
  { code: "27228", match: false },
  { code: "27229", match: false },
  { code: "27230", match: false },
  { code: "27231", match: false },
  { code: "27232", match: false },
  { code: "27258", match: false },
  { code: "27259", match: false },
  { code: "27260", match: false },
  { code: "27291", match: false },
  { code: "27292", match: false },
  { code: "27391", match: false },
  { code: "27392", match: false },
  { code: "27393", match: false },
  { code: "27394", match: false },
  { code: "27395", match: false },
  { code: "27396", match: false },
  { code: "27397", match: false },
  { code: "27398", match: false },
  { code: "27429", match: true },
  { code: "27492", match: true },
  { code: "27493", match: true },
  { code: "27500", match: true },
  { code: "27561", match: true },
  { code: "27562", match: true },
  { code: "27563", match: true },
  { code: "27564", match: true },
  { code: "27571", match: true },
  { code: "27572", match: true },
  { code: "27573", match: true },
  { code: "27574", match: true },
  { code: "27581", match: true },
  { code: "27582", match: true },
  { code: "27583", match: true },
  { code: "27584", match: true },
  { code: "27591", match: true },
  { code: "27592", match: true },
  { code: "27593", match: true },
  { code: "27594", match: true },
  { code: "27700", match: true },
  { code: "27701", match: true },
  { code: "27702", match: true },
  { code: "27703", match: true },
  { code: "27704", match: true },
  { code: "27705", match: true },
  { code: "27706", match: true },
  { code: "27707", match: true },
  { code: "28191", match: true },
  { code: "28192", match: true },
  { code: "28193", match: false },
  { code: "28232", match: false },
  { code: "28348", match: true },
  { code: "28349", match: true },
  { code: "28350", match: true },
  { code: "28351", match: true },
  { code: "28352", match: true },
  { code: "28353", match: true },
  { code: "28354", match: true },
  { code: "28355", match: true },
  { code: "28356", match: true },
  { code: "28357", match: true },
  { code: "28358", match: true },
  { code: "28402", match: true },
  { code: "28403", match: true },
  { code: "28404", match: true },
  { code: "28405", match: true },
  { code: "28406", match: true },
  { code: "28407", match: true },
  { code: "28408", match: true },
  { code: "28409", match: true },
  { code: "28410", match: true },
  { code: "28411", match: true },
  { code: "28412", match: true },
  { code: "28413", match: true },
  { code: "28414", match: true },
  { code: "28415", match: true },
  { code: "28416", match: false },
  { code: "28417", match: false },
  { code: "28418", match: false },
  { code: "28419", match: true },
  { code: "28420", match: true },
  { code: "28421", match: true },
  { code: "28422", match: true },
  { code: "28423", match: true },
  { code: "28424", match: true },
  { code: "28425", match: true },
  { code: "28426", match: true },
  { code: "28427", match: false },
  { code: "28428", match: false },
  { code: "28429", match: false },
  { code: "28430", match: false },
  { code: "28431", match: false },
  { code: "28432", match: false },
  { code: "28462", match: true },
  { code: "28463", match: true },
  { code: "28464", match: true },
  { code: "28465", match: true },
  { code: "28466", match: true },
  { code: "28467", match: true },
  { code: "28468", match: true },
  { code: "28469", match: true },
  { code: "28470", match: true },
  { code: "28471", match: true },
  { code: "28472", match: true },
  { code: "28473", match: true },
  { code: "28474", match: true },
  { code: "28475", match: true },
  { code: "28476", match: false },
  { code: "28477", match: false },
  { code: "28478", match: false },
  { code: "28479", match: true },
  { code: "28480", match: true },
  { code: "28481", match: true },
  { code: "28482", match: true },
  { code: "28483", match: true },
  { code: "28484", match: true },
  { code: "28485", match: true },
  { code: "28486", match: true },
  { code: "28487", match: false },
  { code: "28488", match: false },
  { code: "28489", match: false },
  { code: "28490", match: false },
  { code: "28491", match: false },
  { code: "28492", match: false },
  { code: "28600", match: true },
  { code: "28991", match: true },
  { code: "28992", match: true },
  { code: "29100", match: true },
  { code: "29101", match: true },
  { code: "29122", match: true },
  { code: "29168", match: true },
  { code: "29169", match: true },
  { code: "29170", match: true },
  { code: "29171", match: true },
  { code: "29172", match: true },
  { code: "29181", match: true },
  { code: "29182", match: true },
  { code: "29183", match: true },
  { code: "29184", match: true },
  { code: "29185", match: true },
  { code: "29187", match: true },
  { code: "29188", match: true },
  { code: "29189", match: true },
  { code: "29190", match: true },
  { code: "29191", match: true },
  { code: "29192", match: true },
  { code: "29193", match: true },
  { code: "29194", match: true },
  { code: "29195", match: true },
  { code: "29220", match: true },
  { code: "29221", match: true },
  { code: "29333", match: false },
  { code: "29371", match: false },
  { code: "29373", match: false },
  { code: "29375", match: false },
  { code: "29377", match: false },
  { code: "29379", match: false },
  { code: "29381", match: false },
  { code: "29383", match: false },
  { code: "29385", match: false },
  { code: "29700", match: true },
  { code: "29701", match: false },
  { code: "29702", match: true },
  { code: "29738", match: true },
  { code: "29739", match: true },
  { code: "29849", match: false },
  { code: "29850", match: false },
  { code: "29871", match: false },
  { code: "29872", match: false },
  { code: "29873", match: false },
  { code: "29874", match: false },
  { code: "29900", match: true },
  { code: "29901", match: false },
  { code: "29902", match: true },
  { code: "29903", match: true },
  { code: "30161", match: false },
  { code: "30162", match: false },
  { code: "30163", match: false },
  { code: "30164", match: false },
  { code: "30165", match: false },
  { code: "30166", match: false },
  { code: "30167", match: false },
  { code: "30168", match: false },
  { code: "30169", match: false },
  { code: "30170", match: false },
  { code: "30171", match: false },
  { code: "30172", match: false },
  { code: "30173", match: false },
  { code: "30174", match: false },
  { code: "30175", match: false },
  { code: "30176", match: false },
  { code: "30177", match: false },
  { code: "30178", match: false },
  { code: "30179", match: false },
  { code: "30200", match: false },
  { code: "30339", match: true },
  { code: "30340", match: true },
  { code: "30491", match: true },
  { code: "30492", match: true },
  { code: "30493", match: true },
  { code: "30494", match: true },
  { code: "30729", match: true },
  { code: "30730", match: true },
  { code: "30731", match: true },
  { code: "30732", match: true },
  { code: "30791", match: true },
  { code: "30792", match: true },
  { code: "30800", match: true },
  { code: "31028", match: true },
  { code: "31121", match: false },
  { code: "31154", match: false },
  { code: "31170", match: false },
  { code: "31171", match: false },
  { code: "31251", match: false },
  { code: "31252", match: false },
  { code: "31253", match: false },
  { code: "31254", match: false },
  { code: "31255", match: false },
  { code: "31256", match: false },
  { code: "31257", match: false },
  { code: "31258", match: false },
  { code: "31259", match: false },
  { code: "31265", match: true },
  { code: "31266", match: true },
  { code: "31267", match: true },
  { code: "31268", match: true },
  { code: "31275", match: true },
  { code: "31276", match: true },
  { code: "31277", match: true },
  { code: "31278", match: true },
  { code: "31279", match: true },
  { code: "31281", match: false },
  { code: "31282", match: false },
  { code: "31283", match: false },
  { code: "31284", match: false },
  { code: "31285", match: false },
  { code: "31286", match: false },
  { code: "31287", match: false },
  { code: "31288", match: false },
  { code: "31289", match: false },
  { code: "31290", match: false },
  { code: "31291", match: false },
  { code: "31292", match: false },
  { code: "31293", match: false },
  { code: "31294", match: false },
  { code: "31295", match: false },
  { code: "31296", match: false },
  { code: "31297", match: false },
  { code: "31300", match: true },
  { code: "31370", match: true },
  { code: "31462", match: true },
  { code: "31463", match: true },
  { code: "31464", match: true },
  { code: "31465", match: true },
  { code: "31466", match: true },
  { code: "31467", match: true },
  { code: "31468", match: true },
  { code: "31469", match: true },
  { code: "31528", match: true },
  { code: "31529", match: true },
  { code: "31600", match: false },
  { code: "31700", match: true },
  { code: "31838", match: true },
  { code: "31839", match: true },
  { code: "31900", match: true },
  { code: "31901", match: true },
  { code: "31965", match: true },
  { code: "31966", match: true },
  { code: "31967", match: true },
  { code: "31968", match: true },
  { code: "31969", match: true },
  { code: "31970", match: true },
  { code: "31971", match: true },
  { code: "31972", match: true },
  { code: "31973", match: true },
  { code: "31974", match: true },
  { code: "31975", match: true },
  { code: "31976", match: true },
  { code: "31977", match: true },
  { code: "31978", match: true },
  { code: "31979", match: true },
  { code: "31980", match: true },
  { code: "31981", match: true },
  { code: "31982", match: true },
  { code: "31983", match: true },
  { code: "31984", match: true },
  { code: "31985", match: true },
  { code: "31986", match: true },
  { code: "31987", match: true },
  { code: "31988", match: true },
  { code: "31989", match: true },
  { code: "31990", match: true },
  { code: "31991", match: true },
  { code: "31992", match: true },
  { code: "31993", match: true },
  { code: "31994", match: true },
  { code: "31995", match: true },
  { code: "31996", match: true },
  { code: "31997", match: true },
  { code: "31998", match: true },
  { code: "31999", match: true },
  { code: "32000", match: true },
  { code: "32001", match: true },
  { code: "32002", match: true },
  { code: "32003", match: true },
  { code: "32005", match: true },
  { code: "32006", match: true },
  { code: "32007", match: true },
  { code: "32008", match: true },
  { code: "32009", match: true },
  { code: "32010", match: true },
  { code: "32011", match: true },
  { code: "32012", match: true },
  { code: "32013", match: true },
  { code: "32014", match: true },
  { code: "32015", match: true },
  { code: "32016", match: true },
  { code: "32017", match: true },
  { code: "32018", match: true },
  { code: "32019", match: true },
  { code: "32020", match: true },
  { code: "32021", match: true },
  { code: "32022", match: true },
  { code: "32023", match: true },
  { code: "32024", match: true },
  { code: "32025", match: true },
  { code: "32026", match: true },
  { code: "32027", match: true },
  { code: "32028", match: true },
  { code: "32029", match: true },
  { code: "32030", match: true },
  { code: "32031", match: true },
  { code: "32033", match: true },
  { code: "32034", match: true },
  { code: "32035", match: true },
  { code: "32036", match: true },
  { code: "32037", match: true },
  { code: "32038", match: true },
  { code: "32039", match: true },
  { code: "32040", match: true },
  { code: "32041", match: true },
  { code: "32042", match: true },
  { code: "32043", match: true },
  { code: "32044", match: true },
  { code: "32045", match: true },
  { code: "32046", match: true },
  { code: "32047", match: true },
  { code: "32048", match: true },
  { code: "32049", match: true },
  { code: "32050", match: true },
  { code: "32051", match: true },
  { code: "32052", match: true },
  { code: "32053", match: true },
  { code: "32054", match: true },
  { code: "32055", match: true },
  { code: "32056", match: true },
  { code: "32057", match: true },
  { code: "32058", match: true },
  { code: "32061", match: true },
  { code: "32062", match: false },
  { code: "32064", match: true },
  { code: "32065", match: true },
  { code: "32066", match: true },
  { code: "32067", match: true },
  { code: "32074", match: true },
  { code: "32075", match: true },
  { code: "32076", match: true },
  { code: "32077", match: true },
  { code: "32081", match: true },
  { code: "32082", match: true },
  { code: "32083", match: true },
  { code: "32084", match: true },
  { code: "32085", match: true },
  { code: "32086", match: true },
  { code: "32098", match: true },
  { code: "32099", match: true },
  { code: "32100", match: true },
  { code: "32104", match: true },
  { code: "32107", match: true },
  { code: "32108", match: true },
  { code: "32109", match: true },
  { code: "32110", match: true },
  { code: "32111", match: true },
  { code: "32112", match: true },
  { code: "32113", match: true },
  { code: "32114", match: true },
  { code: "32115", match: true },
  { code: "32116", match: true },
  { code: "32117", match: true },
  { code: "32118", match: true },
  { code: "32119", match: true },
  { code: "32120", match: true },
  { code: "32121", match: true },
  { code: "32122", match: true },
  { code: "32123", match: true },
  { code: "32124", match: true },
  { code: "32125", match: true },
  { code: "32126", match: true },
  { code: "32127", match: true },
  { code: "32128", match: true },
  { code: "32129", match: true },
  { code: "32130", match: true },
  { code: "32133", match: true },
  { code: "32134", match: true },
  { code: "32135", match: true },
  { code: "32136", match: true },
  { code: "32137", match: true },
  { code: "32138", match: true },
  { code: "32139", match: true },
  { code: "32140", match: true },
  { code: "32141", match: true },
  { code: "32142", match: true },
  { code: "32143", match: true },
  { code: "32144", match: true },
  { code: "32145", match: true },
  { code: "32146", match: true },
  { code: "32147", match: true },
  { code: "32148", match: true },
  { code: "32149", match: true },
  { code: "32150", match: true },
  { code: "32151", match: true },
  { code: "32152", match: true },
  { code: "32153", match: true },
  { code: "32154", match: true },
  { code: "32155", match: true },
  { code: "32156", match: true },
  { code: "32157", match: true },
  { code: "32158", match: true },
  { code: "32159", match: true },
  { code: "32161", match: true },
  { code: "32164", match: true },
  { code: "32165", match: true },
  { code: "32166", match: true },
  { code: "32167", match: true },
  { code: "32180", match: true },
  { code: "32181", match: true },
  { code: "32182", match: true },
  { code: "32183", match: true },
  { code: "32184", match: true },
  { code: "32185", match: true },
  { code: "32186", match: true },
  { code: "32187", match: true },
  { code: "32188", match: true },
  { code: "32189", match: true },
  { code: "32190", match: true },
  { code: "32191", match: true },
  { code: "32192", match: true },
  { code: "32193", match: true },
  { code: "32194", match: true },
  { code: "32195", match: true },
  { code: "32196", match: true },
  { code: "32197", match: true },
  { code: "32198", match: true },
  { code: "32199", match: true },
  { code: "32201", match: true },
  { code: "32202", match: true },
  { code: "32203", match: true },
  { code: "32204", match: true },
  { code: "32205", match: true },
  { code: "32206", match: true },
  { code: "32207", match: true },
  { code: "32208", match: true },
  { code: "32209", match: true },
  { code: "32210", match: true },
  { code: "32211", match: true },
  { code: "32212", match: true },
  { code: "32213", match: true },
  { code: "32214", match: true },
  { code: "32215", match: true },
  { code: "32216", match: true },
  { code: "32217", match: true },
  { code: "32218", match: true },
  { code: "32219", match: true },
  { code: "32220", match: true },
  { code: "32221", match: true },
  { code: "32222", match: true },
  { code: "32223", match: true },
  { code: "32224", match: true },
  { code: "32225", match: true },
  { code: "32226", match: true },
  { code: "32227", match: true },
  { code: "32228", match: true },
  { code: "32229", match: true },
  { code: "32230", match: true },
  { code: "32231", match: true },
  { code: "32232", match: true },
  { code: "32233", match: true },
  { code: "32234", match: true },
  { code: "32235", match: true },
  { code: "32236", match: true },
  { code: "32237", match: true },
  { code: "32238", match: true },
  { code: "32239", match: true },
  { code: "32240", match: true },
  { code: "32241", match: true },
  { code: "32242", match: true },
  { code: "32243", match: true },
  { code: "32244", match: true },
  { code: "32245", match: true },
  { code: "32246", match: true },
  { code: "32247", match: true },
  { code: "32248", match: true },
  { code: "32249", match: true },
  { code: "32250", match: true },
  { code: "32251", match: true },
  { code: "32252", match: true },
  { code: "32253", match: true },
  { code: "32254", match: true },
  { code: "32255", match: true },
  { code: "32256", match: true },
  { code: "32257", match: true },
  { code: "32258", match: true },
  { code: "32259", match: true },
  { code: "32260", match: true },
  { code: "32301", match: true },
  { code: "32302", match: true },
  { code: "32303", match: true },
  { code: "32304", match: true },
  { code: "32305", match: true },
  { code: "32306", match: true },
  { code: "32307", match: true },
  { code: "32308", match: true },
  { code: "32309", match: true },
  { code: "32310", match: true },
  { code: "32311", match: true },
  { code: "32312", match: true },
  { code: "32313", match: true },
  { code: "32314", match: true },
  { code: "32315", match: true },
  { code: "32316", match: true },
  { code: "32317", match: true },
  { code: "32318", match: true },
  { code: "32319", match: true },
  { code: "32320", match: true },
  { code: "32321", match: true },
  { code: "32322", match: true },
  { code: "32323", match: true },
  { code: "32324", match: true },
  { code: "32325", match: true },
  { code: "32326", match: true },
  { code: "32327", match: true },
  { code: "32328", match: true },
  { code: "32329", match: true },
  { code: "32330", match: true },
  { code: "32331", match: true },
  { code: "32332", match: true },
  { code: "32333", match: true },
  { code: "32334", match: true },
  { code: "32335", match: true },
  { code: "32336", match: true },
  { code: "32337", match: true },
  { code: "32338", match: true },
  { code: "32339", match: true },
  { code: "32340", match: true },
  { code: "32341", match: true },
  { code: "32342", match: true },
  { code: "32343", match: true },
  { code: "32344", match: true },
  { code: "32345", match: true },
  { code: "32346", match: true },
  { code: "32347", match: true },
  { code: "32348", match: true },
  { code: "32349", match: true },
  { code: "32350", match: true },
  { code: "32351", match: true },
  { code: "32352", match: true },
  { code: "32353", match: true },
  { code: "32354", match: true },
  { code: "32355", match: true },
  { code: "32356", match: true },
  { code: "32357", match: true },
  { code: "32358", match: true },
  { code: "32359", match: true },
  { code: "32360", match: true },
  { code: "32401", match: true },
  { code: "32402", match: true },
  { code: "32403", match: true },
  { code: "32404", match: true },
  { code: "32405", match: true },
  { code: "32406", match: true },
  { code: "32407", match: true },
  { code: "32408", match: true },
  { code: "32409", match: true },
  { code: "32410", match: true },
  { code: "32411", match: true },
  { code: "32412", match: true },
  { code: "32413", match: true },
  { code: "32414", match: true },
  { code: "32415", match: true },
  { code: "32416", match: true },
  { code: "32417", match: true },
  { code: "32418", match: true },
  { code: "32419", match: true },
  { code: "32420", match: true },
  { code: "32421", match: true },
  { code: "32422", match: true },
  { code: "32423", match: true },
  { code: "32424", match: true },
  { code: "32425", match: true },
  { code: "32426", match: true },
  { code: "32427", match: true },
  { code: "32428", match: true },
  { code: "32429", match: true },
  { code: "32430", match: true },
  { code: "32431", match: true },
  { code: "32432", match: true },
  { code: "32433", match: true },
  { code: "32434", match: true },
  { code: "32435", match: true },
  { code: "32436", match: true },
  { code: "32437", match: true },
  { code: "32438", match: true },
  { code: "32439", match: true },
  { code: "32440", match: true },
  { code: "32441", match: true },
  { code: "32442", match: true },
  { code: "32443", match: true },
  { code: "32444", match: true },
  { code: "32445", match: true },
  { code: "32446", match: true },
  { code: "32447", match: true },
  { code: "32448", match: true },
  { code: "32449", match: true },
  { code: "32450", match: true },
  { code: "32451", match: true },
  { code: "32452", match: true },
  { code: "32453", match: true },
  { code: "32454", match: true },
  { code: "32455", match: true },
  { code: "32456", match: true },
  { code: "32457", match: true },
  { code: "32458", match: true },
  { code: "32459", match: true },
  { code: "32460", match: true },
  { code: "32501", match: true },
  { code: "32502", match: true },
  { code: "32503", match: true },
  { code: "32504", match: true },
  { code: "32505", match: true },
  { code: "32506", match: true },
  { code: "32507", match: true },
  { code: "32508", match: true },
  { code: "32509", match: true },
  { code: "32510", match: true },
  { code: "32511", match: true },
  { code: "32512", match: true },
  { code: "32513", match: true },
  { code: "32514", match: true },
  { code: "32515", match: true },
  { code: "32516", match: true },
  { code: "32517", match: true },
  { code: "32518", match: true },
  { code: "32519", match: true },
  { code: "32520", match: true },
  { code: "32521", match: true },
  { code: "32522", match: true },
  { code: "32523", match: true },
  { code: "32524", match: true },
  { code: "32525", match: true },
  { code: "32526", match: true },
  { code: "32527", match: true },
  { code: "32528", match: true },
  { code: "32529", match: true },
  { code: "32530", match: true },
  { code: "32531", match: true },
  { code: "32532", match: true },
  { code: "32533", match: true },
  { code: "32534", match: true },
  { code: "32535", match: true },
  { code: "32536", match: true },
  { code: "32537", match: true },
  { code: "32538", match: true },
  { code: "32539", match: true },
  { code: "32540", match: true },
  { code: "32541", match: true },
  { code: "32542", match: true },
  { code: "32543", match: true },
  { code: "32544", match: true },
  { code: "32545", match: true },
  { code: "32546", match: true },
  { code: "32547", match: true },
  { code: "32548", match: true },
  { code: "32549", match: true },
  { code: "32550", match: true },
  { code: "32551", match: true },
  { code: "32552", match: true },
  { code: "32553", match: true },
  { code: "32554", match: true },
  { code: "32555", match: true },
  { code: "32556", match: true },
  { code: "32557", match: true },
  { code: "32558", match: true },
  { code: "32559", match: true },
  { code: "32560", match: true },
  { code: "32600", match: false },
  { code: "32601", match: true },
  { code: "32602", match: true },
  { code: "32603", match: true },
  { code: "32604", match: true },
  { code: "32605", match: true },
  { code: "32606", match: true },
  { code: "32607", match: true },
  { code: "32608", match: true },
  { code: "32609", match: true },
  { code: "32610", match: true },
  { code: "32611", match: true },
  { code: "32612", match: true },
  { code: "32613", match: true },
  { code: "32614", match: true },
  { code: "32615", match: true },
  { code: "32616", match: true },
  { code: "32617", match: true },
  { code: "32618", match: true },
  { code: "32619", match: true },
  { code: "32620", match: true },
  { code: "32621", match: true },
  { code: "32622", match: true },
  { code: "32623", match: true },
  { code: "32624", match: true },
  { code: "32625", match: true },
  { code: "32626", match: true },
  { code: "32627", match: true },
  { code: "32628", match: true },
  { code: "32629", match: true },
  { code: "32630", match: true },
  { code: "32631", match: true },
  { code: "32632", match: true },
  { code: "32633", match: true },
  { code: "32634", match: true },
  { code: "32635", match: true },
  { code: "32636", match: true },
  { code: "32637", match: true },
  { code: "32638", match: true },
  { code: "32639", match: true },
  { code: "32640", match: true },
  { code: "32641", match: true },
  { code: "32642", match: true },
  { code: "32643", match: true },
  { code: "32644", match: true },
  { code: "32645", match: true },
  { code: "32646", match: true },
  { code: "32647", match: true },
  { code: "32648", match: true },
  { code: "32649", match: true },
  { code: "32650", match: true },
  { code: "32651", match: true },
  { code: "32652", match: true },
  { code: "32653", match: true },
  { code: "32654", match: true },
  { code: "32655", match: true },
  { code: "32656", match: true },
  { code: "32657", match: true },
  { code: "32658", match: true },
  { code: "32659", match: true },
  { code: "32660", match: true },
  { code: "32661", match: true },
  { code: "32662", match: true },
  { code: "32663", match: true },
  { code: "32664", match: true },
  { code: "32665", match: true },
  { code: "32666", match: true },
  { code: "32667", match: true },
  { code: "32700", match: false },
  { code: "32701", match: true },
  { code: "32702", match: true },
  { code: "32703", match: true },
  { code: "32704", match: true },
  { code: "32705", match: true },
  { code: "32706", match: true },
  { code: "32707", match: true },
  { code: "32708", match: true },
  { code: "32709", match: true },
  { code: "32710", match: true },
  { code: "32711", match: true },
  { code: "32712", match: true },
  { code: "32713", match: true },
  { code: "32714", match: true },
  { code: "32715", match: true },
  { code: "32716", match: true },
  { code: "32717", match: true },
  { code: "32718", match: true },
  { code: "32719", match: true },
  { code: "32720", match: true },
  { code: "32721", match: true },
  { code: "32722", match: true },
  { code: "32723", match: true },
  { code: "32724", match: true },
  { code: "32725", match: true },
  { code: "32726", match: true },
  { code: "32727", match: true },
  { code: "32728", match: true },
  { code: "32729", match: true },
  { code: "32730", match: true },
  { code: "32731", match: true },
  { code: "32732", match: true },
  { code: "32733", match: true },
  { code: "32734", match: true },
  { code: "32735", match: true },
  { code: "32736", match: true },
  { code: "32737", match: true },
  { code: "32738", match: true },
  { code: "32739", match: true },
  { code: "32740", match: true },
  { code: "32741", match: true },
  { code: "32742", match: true },
  { code: "32743", match: true },
  { code: "32744", match: true },
  { code: "32745", match: true },
  { code: "32746", match: true },
  { code: "32747", match: true },
  { code: "32748", match: true },
  { code: "32749", match: true },
  { code: "32750", match: true },
  { code: "32751", match: true },
  { code: "32752", match: true },
  { code: "32753", match: true },
  { code: "32754", match: true },
  { code: "32755", match: true },
  { code: "32756", match: true },
  { code: "32757", match: true },
  { code: "32758", match: true },
  { code: "32759", match: true },
  { code: "32760", match: true },
  { code: "32761", match: true },
  { code: "32766", match: true },
];
