import { Row } from "components/General/Layout";
import Tabs from "components/General/Tabs";
import { Suspense, useMemo } from "react";
import { ContentHeader, ContentWrapper } from "../../style";
import { TablePlaceholder } from "../shared/TablePlaceholder";
import { libraryResourceSelector } from "components/Organisation/Library/state";
import { useRecoilValue } from "recoil";
import { useTypedPath } from "state/pathParams";
import {
  AnalysisProjects,
  CableProjects,
  DataPackageProjects,
  FinancialProjects,
  FoundationProjects,
  TurbineProjects,
} from "./tabs/Projects";
import {
  AnalysisManagersOuter,
  CableManagersOuter,
  DataLayerManagersOuter,
  FinancialManagersOuter,
  FoundationManagersOuter,
  TurbineManagersOuter,
} from "./tabs/Managers";
import { InitialsImageRound } from "components/UserImage/UserImageRound";
import { TurbineType } from "types/turbines";
import { FoundationType } from "types/foundations";
import { Configuration } from "services/configurationService";
import { CableType } from "services/cableTypeService";
import { CostConfiguration } from "services/costService";
import { DataLibraryPackage } from "components/Organisation/Library/dataLibrary/types";

export default function ResourceContent({
  resourceId,
}: {
  resourceId: string;
}) {
  return (
    <ContentWrapper>
      <Suspense fallback={<TablePlaceholder />}>
        <ResourceContentInner resourceId={resourceId} />
      </Suspense>
    </ContentWrapper>
  );
}

const turbineTabs = (resource: TurbineType) => [
  {
    name: "Projects",
    data: (
      <Suspense fallback={<TablePlaceholder />}>
        <TurbineProjects resource={resource} />
      </Suspense>
    ),
  },
  {
    name: "Turbine managers",
    data: (
      <Suspense fallback={<TablePlaceholder />}>
        <TurbineManagersOuter />
      </Suspense>
    ),
  },
];
const foundationTabs = (resource: FoundationType) => [
  {
    name: "Projects",
    data: (
      <Suspense fallback={<TablePlaceholder />}>
        <FoundationProjects resource={resource} />
      </Suspense>
    ),
  },
  {
    name: "Foundation managers",
    data: (
      <Suspense fallback={<TablePlaceholder />}>
        <FoundationManagersOuter />
      </Suspense>
    ),
  },
];
const analysisTabs = (resource: Configuration) => [
  {
    name: "Projects",
    data: (
      <Suspense fallback={<TablePlaceholder />}>
        <AnalysisProjects resource={resource} />
      </Suspense>
    ),
  },
  {
    name: "Analysis managers",
    data: (
      <Suspense fallback={<TablePlaceholder />}>
        <AnalysisManagersOuter />
      </Suspense>
    ),
  },
];

const cableTabs = (resource: CableType) => [
  {
    name: "Projects",
    data: (
      <Suspense fallback={<TablePlaceholder />}>
        <CableProjects resource={resource} />
      </Suspense>
    ),
  },
  {
    name: "Cable managers",
    data: (
      <Suspense fallback={<TablePlaceholder />}>
        <CableManagersOuter />
      </Suspense>
    ),
  },
];

const financialTabs = (resource: CostConfiguration) => [
  {
    name: "Projects",
    data: (
      <Suspense fallback={<TablePlaceholder />}>
        <FinancialProjects resource={resource} />
      </Suspense>
    ),
  },
  {
    name: "Financial managers",
    data: (
      <Suspense fallback={<TablePlaceholder />}>
        <FinancialManagersOuter />
      </Suspense>
    ),
  },
];

const datapackageTabs = (resource: DataLibraryPackage) => [
  {
    name: "Projects",
    data: (
      <Suspense fallback={<TablePlaceholder />}>
        <DataPackageProjects resource={resource} />
      </Suspense>
    ),
  },
  {
    name: "Data layer managers",
    data: (
      <Suspense fallback={<TablePlaceholder />}>
        <DataLayerManagersOuter />
      </Suspense>
    ),
  },
];

function ResourceContentInner({ resourceId }: { resourceId: string }) {
  const { organisationId } = useTypedPath("organisationId");
  const resource = useRecoilValue(
    libraryResourceSelector({ organisationId, resourceId }),
  );

  const tabs = useMemo(() => {
    if (!resource) return [];
    switch (resource.type) {
      case "foundation":
        return foundationTabs(resource.resource);
      case "turbine":
        return turbineTabs(resource.resource);
      case "analysis":
        return analysisTabs(resource.resource);
      case "cable":
        return cableTabs(resource.resource);
      case "financial":
        return financialTabs(resource.resource);
      case "datapackage":
        return datapackageTabs(resource.resource);
      default:
        return [];
    }
  }, [resource]);

  if (!resource) return <></>;

  return (
    <>
      <Row style={{ gap: "2.4rem", alignItems: "center" }}>
        <InitialsImageRound name={resource.resource?.name ?? ""} size={9.1} />
        <ContentHeader>{resource.resource.name}</ContentHeader>
      </Row>
      <Tabs
        menuStyle={{ justifyContent: "start", gap: "5rem" }}
        tabs={tabs}
        wrapperStyle={{ overflowY: "hidden" }}
        contentWrapperStyle={{ height: "100%" }}
      />
    </>
  );
}
