import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { useTypedPath } from "../../../state/pathParams";
import { SkeletonRound, SkeletonText } from "../../Loading/Skeleton";
import UserImageRound from "../../UserImage/UserImageRound";
import { Row } from "../../General/Layout";
import { typography } from "../../../styles/typography";
import { OtherUserMetaInfo } from "types/user";
import { usersInOrganisationState } from "components/Organisation/state";

const Name = styled.p`
  ${typography.contentAndButtons}
  margin: 0;
  text-transform: capitalize;
  weight: 600;
`;

export function UserInfoLoading() {
  return (
    <Row>
      <SkeletonRound size={24} />
      <SkeletonText style={{ width: "6rem", height: "1.6rem" }} />
    </Row>
  );
}

export const UserInfoRender = ({
  user,
  size,
}: {
  user: OtherUserMetaInfo | undefined;
  size?: number;
}) =>
  user ? (
    <Row style={{ alignItems: "center" }}>
      <UserImageRound size={size ?? 2.4} user={user} />
      <Name>{user.nickname}</Name>
    </Row>
  ) : null;

export default function UserInfo({
  userId,
  size,
}: {
  userId: string;
  size?: number;
}) {
  const { organisationId } = useTypedPath("organisationId");
  const users = useRecoilValue(usersInOrganisationState(organisationId));
  const user = users?.find((cm) => cm.user_id === userId);

  return <UserInfoRender size={size} user={user} />;
}
