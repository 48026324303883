/// <reference types="vite-plugin-svgr/client" />
import { ChevronIcon } from "components/ToggleableList/ToggleableList";
import {
  CSSProperties,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";
import {
  IDEAL_MW_PER_AREA,
  MAX_MIN_SPACING,
  MAX_MW_PER_AREA,
  MAX_PARK_TURBINES,
  MAX_TURBINES_OPTIMIZE,
  MIN_MIN_SPACING,
  MIN_MW_PER_AREA,
  MIN_TURBINES_OPTIMIZE,
} from "../../constants/park";
import { State } from "../../constants/webworker";
import { Mixpanel } from "../../mixpanel";

import ConfigurationIcon from "@icons/14/Configuration.svg?react";
import { getTurbinesSelectorFamily } from "../../state/layout";
import { modalTypeOpenAtom } from "../../state/modal";
import { projectIdSelector, useTypedPath } from "../../state/pathParams";
import {
  allSimpleTurbineTypesSelector,
  currentTurbineIdAtom,
  defaultEdgeParamsWithUnits,
  defaultOptimizeParametersWithUnits,
  defaultRegularParamsWithUnits,
  generationStateAtom,
  previewTurbinesState,
} from "../../state/turbines";
import { colors } from "../../styles/colors";
import { spaceLarge, spaceMedium, spaceSmall } from "../../styles/space";
import {
  ParkFeature,
  ProjectFeature,
  SubAreaFeature,
  TurbineFeature,
} from "../../types/feature";
import {
  DEFAULT_TURBINES,
  EdgeParametersWithUnit,
  GenerationMethod,
  GenerationMethodAndParameters,
  GenerationMethodAndParametersWithUnit,
  OptimizeParametersWithUnit,
  RegularParameters,
  RegularParametersWithUnit,
  SimpleTurbineType,
  _GenerationMethod,
} from "../../types/turbines";
import { xy2lonlat } from "../../utils/geometry";
import Dropdown from "../Dropdown/Dropdown";
import Button from "../General/Button";
import { Grid2, Label } from "../General/Form";
import { Column, Row } from "../General/Layout";
import { SkeletonText } from "../Loading/Skeleton";
import { Angle, Convert, TurbineDistance } from "../Units/units";
import { AxisLines } from "./AxisLines";
import { PreviousOptimizationsList, openOptListItem } from "./OptimizeList";
import { TurbineControl } from "./TurbineSettings";
import inferTurbineParameters from "./infer";
import { isCompleted } from "./predicates";
import {
  setTurbineNames,
  useOptimizeTurbines,
  useTurbineGeneration,
} from "./useGenerateAndSave";

import InfoIcon from "@icons/24/Information.svg?react";
import WindMeasure from "@icons/24/WindMeasure.svg?react";
import * as turf from "@turf/turf";
import {
  InputTitle,
  OverlineText,
  OverlineTextWrapper,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import { InputDimensioned } from "components/General/Input";
import { HighlightStep } from "components/OnboardingTours/HighlightWrapper";
import { useTrackEvent } from "components/OnboardingTours/state";
import { useProjectElementsCrud } from "components/ProjectElements/useProjectElementsCrud";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import React from "react";
import { WakeModel } from "services/configurationService";
import { windConfigForOptimization } from "state/windConfigForOptimizationAtom";
import { windSourceConfigurationsAtomFamily } from "state/windSourceConfiguration";
import styled from "styled-components";
import { throttle } from "throttle-debounce";
import { OptProblem, OptResult } from "../../functions/optimize";
import { ProjectConfigModalTypeV2 } from "../../state/configuration";
import { mapRefAtom } from "../../state/map";
import {
  WindDataSource,
  mostProbableWindDirectionSelector,
  windDatasetsSelectorFamily,
} from "../../state/windStatistics";
import { isDefined } from "../../utils/predicates";
import { clamp, fastMin, isNever } from "../../utils/utils";
import { RangeWithDimInput } from "../General/RangeWithDimInput";
import Toggle, { ToggleSize } from "../General/Toggle";
import Tooltip from "../General/Tooltip";
import { TurbineEllipses } from "./TurbineEllipses";
import { meterToCoords, xDistAtLat } from "./generateLayout";
import { ReferenceType } from "@floating-ui/react";
import {
  Ids,
  combinedGenMooringParameters,
  methodAndParametersForRegionAtomFamily,
  turbinesAndFoundationsGenerationIsLiveAtom,
} from "./state";

type OptimizeTurbinesContextType = {
  park: ParkFeature;
  region: ParkFeature | SubAreaFeature;
  coordsToFeatures: (points: [number, number][]) => TurbineFeature[];
};

export const OptimizeTurbinesContext = React.createContext<
  OptimizeTurbinesContextType | undefined
>(undefined);

const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: ${spaceSmall};
`;

const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
  padding: 0 0 0 1rem;
  svg {
    width: 1.5rem;
    height: 1.5rem;
    path {
      stroke: ${colors.primaryText};
    }
  }
`;

const columnStyle: CSSProperties = {
  overflowY: "auto",
};

const TurbineMinorAxisSpacing = ({
  value: { value, unit },
  set,
  convert,
}: {
  value: TurbineDistance.Of<number>;
  set: (n: TurbineDistance.Of<number>) => void;
  convert: Convert<TurbineDistance.Unit>;
}) => {
  return (
    <InputBox>
      <InputTitle>Minor axis spacing</InputTitle>
      <RangeWithDimInput
        min={unit === "m" ? 500 : 2}
        max={unit === "m" ? 4000 : 16}
        rangeStep={unit === "m" ? 50 : 0.01}
        value={value}
        unit={unit}
        units={TurbineDistance.units}
        onChange={(n) => {
          set({ value: n, unit });
        }}
        onUnitChange={(u) => {
          set({
            value: convert(convert(value, { from: unit }), { to: u }),
            unit: u,
          });
        }}
        format={TurbineDistance.format}
      />
    </InputBox>
  );
};

const TurbineMajorAxisSpacing = ({
  value: { value, unit },
  set,
  convert,
}: {
  value: TurbineDistance.Of<number>;
  set: (n: TurbineDistance.Of<number>) => void;
  convert: Convert<TurbineDistance.Unit>;
}) => {
  return (
    <InputBox>
      <InputTitle>Major axis spacing</InputTitle>
      <RangeWithDimInput
        min={unit === "m" ? 500 : 2}
        max={unit === "m" ? 4000 : 16}
        rangeStep={unit === "m" ? 50 : 0.01}
        value={value}
        unit={unit}
        units={TurbineDistance.units}
        onChange={(n) => {
          set({ value: n, unit });
        }}
        onUnitChange={(u) => {
          set({
            value: convert(convert(value, { from: unit }), { to: u }),
            unit: u,
          });
        }}
        format={TurbineDistance.format}
      />
    </InputBox>
  );
};

const TurbineEdgeSpacing = ({
  value: { value, unit },
  set,
  convert,
}: {
  value: TurbineDistance.Of<number>;
  set: (n: TurbineDistance.Of<number>) => void;
  convert: Convert<TurbineDistance.Unit>;
}) => {
  return (
    <InputBox>
      <p>Turbine edge spacing</p>
      <RangeWithDimInput
        min={unit === "m" ? 500 : 2}
        max={unit === "m" ? 4000 : 16}
        rangeStep={unit === "m" ? 50 : 0.01}
        value={value}
        unit={unit}
        units={TurbineDistance.units}
        onChange={(n) => {
          set({ value: n, unit });
        }}
        onUnitChange={(u) => {
          set({
            value: convert(convert(value, { from: unit }), { to: u }),
            unit: u,
          });
        }}
        format={TurbineDistance.format}
      />
    </InputBox>
  );
};

const LayoutRotation = ({
  value: { value, unit },
  set,
  convert,
  meanWindDirection,
}: {
  value: Angle.Of<number>;
  set: (n: Angle.Of<number>) => void;
  convert: Convert<Angle.Unit>;
  meanWindDirection?: number;
}) => {
  return (
    <InputBox>
      <div style={{ display: "flex", gap: "1rem" }}>
        <InputTitle>Major axis direction</InputTitle>
        {isDefined(meanWindDirection) && (
          <Button
            icon={<WindMeasure />}
            onClick={() => set({ value: meanWindDirection, unit })}
            tooltip="Set to most probable direction (ERA5)"
            buttonType="text"
            text={`${meanWindDirection} deg`}
            style={{
              fontSize: "1rem",
              height: "1rem",
              textDecoration: "underline",
            }}
          />
        )}
      </div>
      <RangeWithDimInput
        min={Angle.range(unit)[0] + convert(180, { to: unit })}
        max={Angle.range(unit)[1] + convert(180, { to: unit })}
        value={value}
        unit={unit}
        units={Angle.units}
        onChange={(n) => {
          set({ value: n, unit });
        }}
        onUnitChange={(u) => {
          set({
            value: convert(convert(value, { from: unit }), { to: u }),
            unit: u,
          });
        }}
        format={Angle.format}
      />
    </InputBox>
  );
};

const Obliquity = ({
  value: { value, unit },
  set,
  convert,
  min,
  max,
}: {
  value: Angle.Of<number>;
  set: (n: Angle.Of<number>) => void;
  convert: Convert<Angle.Unit>;
  min: number;
  max: number;
}) => {
  return (
    <InputBox>
      <InputTitle>Obliquity</InputTitle>
      <RangeWithDimInput
        min={convert(min, { to: unit })}
        max={convert(max, { to: unit })}
        value={value}
        unit={unit}
        units={Angle.units}
        onChange={(n) => {
          set({ value: n, unit });
        }}
        onUnitChange={(u) => {
          set({
            value: convert(convert(value, { from: unit }), { to: u }),
            unit: u,
          });
        }}
        format={Angle.format}
      />
    </InputBox>
  );
};

const ShiftParkX = ({
  value: { value, unit },
  set,
  convert,
}: {
  value: TurbineDistance.Of<number>;
  set: (n: TurbineDistance.Of<number>) => void;
  convert: Convert<TurbineDistance.Unit>;
}) => {
  return (
    <InputBox>
      <InputTitle>East/West offset</InputTitle>
      <RangeWithDimInput
        min={unit === "m" ? -4000 : -14}
        max={unit === "m" ? 4000 : 14}
        rangeStep={unit === "m" ? 100 : 0.1}
        value={value}
        unit={unit}
        units={TurbineDistance.units}
        onChange={(n) => {
          set({ value: n, unit });
        }}
        onUnitChange={(u) => {
          set({
            value: convert(convert(value, { from: unit }), { to: u }),
            unit: u,
          });
        }}
        format={TurbineDistance.format}
      />
    </InputBox>
  );
};

const ShiftParkY = ({
  value: { value, unit },
  set,
  convert,
}: {
  value: TurbineDistance.Of<number>;
  set: (n: TurbineDistance.Of<number>) => void;
  convert: Convert<TurbineDistance.Unit>;
}) => {
  return (
    <InputBox>
      <InputTitle>North/South offset</InputTitle>
      <RangeWithDimInput
        min={unit === "m" ? -4000 : -14}
        max={unit === "m" ? 4000 : 14}
        rangeStep={unit === "m" ? 100 : 0.1}
        value={value}
        unit={unit}
        units={TurbineDistance.units}
        onChange={(n) => {
          set({ value: n, unit });
        }}
        onUnitChange={(u) => {
          set({
            value: convert(convert(value, { from: unit }), { to: u }),
            unit: u,
          });
        }}
        format={TurbineDistance.format}
      />
    </InputBox>
  );
};

const NumberOfTurbines = ({
  value,
  set,
  disabled,
  max,
  min,
}: {
  value: number;
  set: (n: number) => void;
  max: number;
  min: number;
  disabled?: boolean;
}) => {
  return (
    <InputBox>
      <RangeWithDimInput
        min={min}
        max={max}
        rangeStep={1}
        value={value}
        disabled={disabled}
        onChange={(n) => {
          set(n);
        }}
      />
    </InputBox>
  );
};

const NumberOfTurbinesOptimize = ({
  value,
  set,
  disabled,
  max,
  min,
}: {
  value: number;
  set: (n: number) => void;
  max: number;
  min: number;
  disabled?: boolean;
}) => {
  return (
    <InputBox>
      <InputTitle>Number of turbines</InputTitle>
      <RangeWithDimInput
        min={min}
        max={max}
        rangeStep={1}
        value={value}
        disabled={disabled}
        onChange={(n) => {
          set(n);
        }}
      />
    </InputBox>
  );
};

const IncludeNumberOfTurbines = ({
  value,
  set,
  disabled,
  numTurbines,
  capacityDensity,
}: {
  value: boolean;
  set: (n: boolean) => void;
  disabled?: boolean;
  numTurbines: number;
  capacityDensity: number;
}) => {
  return (
    <Grid2
      style={{
        gridTemplateColumns: "auto 5fr",
      }}
    >
      <Toggle
        checked={value}
        onChange={() => !disabled && set(!value)}
        size={ToggleSize.SMALL}
      />
      <div style={{ alignContent: "start" }}>
        <InputTitle>Limit number of turbines</InputTitle>
        <p style={{ color: colors.textDisabled }}>
          {`Turbines: ${numTurbines}, density: ${capacityDensity.toFixed(1)} MW/km²`}
        </p>
      </div>
    </Grid2>
  );
};

const OptimizeMinSpacing = ({
  value,
  set,
  disabled,
  max,
  min,
}: {
  value: number;
  set: (n: number) => void;
  max: number;
  min: number;
  disabled?: boolean;
}) => {
  return (
    <Grid2
      style={{
        gridTemplateColumns: "1fr auto",
      }}
    >
      <p>Minimum spacing</p>
      <InputDimensioned
        style={{ width: "6rem" }}
        compact={true}
        unit={"D"}
        value={value}
        step={0.1}
        disabled={disabled}
        validate={(n) => n <= max && n >= min}
        validationMessage={`Must be between ${min} and ${max}D`}
        onChange={(n) => {
          set(n);
        }}
      />
    </Grid2>
  );
};

const OptimizeIncludeEdge = ({
  value,
  set,
  disabled,
}: {
  value: boolean;
  set: (n: boolean) => void;
  disabled?: boolean;
}) => {
  return (
    <Grid2
      style={{
        gridTemplateColumns: "1fr auto",
      }}
    >
      <p>Add edge turbines</p>
      <Toggle
        checked={value!}
        onChange={() => !disabled && set(!value)}
        size={ToggleSize.SMALL}
      />
    </Grid2>
  );
};

const OptimizeIncludeNeighbours = ({
  value,
  set,
  disabled,
}: {
  value?: boolean;
  set: (n: boolean) => void;
  disabled?: boolean;
}) => {
  return (
    <Grid2
      style={{
        gridTemplateColumns: "1fr auto",
      }}
    >
      <div
        style={{
          display: "flex",
        }}
      >
        <p>Neighbour wake loss</p>
        <Tooltip text="Account for neighbour wake loss during the optimization. This will include neighbour turbines within 20km of the park boundary.">
          <Wrapper>
            <InfoIcon />
          </Wrapper>
        </Tooltip>
      </div>
      <Toggle
        checked={value!}
        onChange={() => !disabled && set(!value)}
        size={ToggleSize.SMALL}
      />
    </Grid2>
  );
};

const OptimizeWakeModel = ({
  value,
  set,
  disabled,
}: {
  value?: WakeModel;
  set: (v: WakeModel) => void;
  disabled?: boolean;
}) => {
  return (
    <Grid2
      style={{
        gridTemplateColumns: "1fr auto",
      }}
    >
      <div
        style={{
          display: "flex",
        }}
      >
        <p>Wake model</p>
        <Tooltip text="Wake model used when calculating the losses during optimization">
          <Wrapper>
            <InfoIcon />
          </Wrapper>
        </Tooltip>
      </div>
      <Dropdown
        small
        value={value}
        disabled={disabled}
        onChange={(e) => {
          set(e.target.value as WakeModel);
        }}
      >
        <option value="jensen">Jensen</option>
        <option value="turbopark">TurbOPark</option>
      </Dropdown>
    </Grid2>
  );
};

type Props = {
  setGenParam: (field: string) => <T>(value: T) => void;
  convertD: Convert<TurbineDistance.Unit>;
  region: ParkFeature | SubAreaFeature;
  turbineType: SimpleTurbineType;
  capacityDensity: number;
};

const OptimizeSelectDataSource = ({
  value,
  set,
  disabled,
}: {
  value?: boolean;
  set: (n: boolean) => void;
  disabled?: boolean;
}) => {
  const { projectId } = useTypedPath("projectId");
  const windConfigs = useRecoilValue(
    windSourceConfigurationsAtomFamily({ projectId }),
  );
  const [selectedWindConfigForOpt, setWindConfigForOpt] = useRecoilState(
    windConfigForOptimization,
  );
  const setModalType = useSetRecoilState(modalTypeOpenAtom);
  useEffect(() => {
    if (value && windConfigs.length > 0 && !selectedWindConfigForOpt) {
      setWindConfigForOpt(windConfigs[0]);
    }
  }, [value, windConfigs, setWindConfigForOpt, selectedWindConfigForOpt]);

  return (
    <>
      <Grid2
        style={{
          gridTemplateColumns: "1fr auto",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <p>Specify wind configuration</p>
          <Tooltip text="If turned off, we select the best available wind data at this location. No spatial calibration is used in the optimization.">
            <Wrapper>
              <InfoIcon />
            </Wrapper>
          </Tooltip>
        </div>
        <Toggle
          checked={value!}
          onChange={() => !disabled && set(!value)}
          size={ToggleSize.SMALL}
        />
      </Grid2>
      {value && (
        <Column>
          <Label left>
            <Dropdown
              style={{ flex: 1 }}
              small
              aria-label="wind-config"
              onChange={(e) => {
                const selectedId = e.target.value;
                const selectedWindConfig = windConfigs.find(
                  (config) => config.id === selectedId,
                );
                setWindConfigForOpt(selectedWindConfig);
              }}
              value={selectedWindConfigForOpt?.id}
            >
              {windConfigs.map((c) => (
                <option key={c.id} value={c.id}>
                  {c.name}
                </option>
              ))}
            </Dropdown>
            <Button
              buttonType="secondary"
              icon={<ConfigurationIcon />}
              size="small"
              onClick={() => {
                setModalType({
                  modalType: ProjectConfigModalTypeV2,
                  metadata: {
                    selectedMenuId: "wind",
                    selectedConfigId: selectedWindConfigForOpt?.id,
                  },
                });
              }}
            />
          </Label>
        </Column>
      )}
    </>
  );
};

const RegularGenerationSettings = ({
  params,
  setGenParam,
  convertD,
  region,
  turbineType,
  maxNumberOfTurbines,
  capacityDensity,
  area,
}: {
  params: RegularParametersWithUnit;
  maxNumberOfTurbines: number;
  area: number;
} & Props) => {
  const lonlat = useMemo(
    () => turf.centerOfMass(region).geometry.coordinates,
    [region],
  );
  const mwd = useRecoilValueLoadable(
    mostProbableWindDirectionSelector({
      lon: lonlat[0],
      lat: lonlat[1],
      height: turbineType.hubHeight,
    }),
  ).valueMaybe();

  const numTurbines = params.setNumberOfTurbines
    ? params.numberOfTurbines
    : maxNumberOfTurbines;

  const max = useMemo(
    () =>
      Math.min(
        Math.ceil((area * MAX_MW_PER_AREA) / (turbineType.ratedPower / 1e3)),
        MAX_PARK_TURBINES,
      ),
    [turbineType, area],
  );

  const obliquityMax = useMemo(() => {
    const shiftMax = params.majorAxisSpacing.value / 2;
    return Math.ceil(
      Math.atan(shiftMax / params.minorAxisSpacing.value) * (180 / Math.PI),
    );
  }, [params.majorAxisSpacing, params.minorAxisSpacing]);

  useEffect(() => {
    if (params.obliquity.value > obliquityMax) {
      setGenParam("obliquity")({ value: obliquityMax, unit: "deg" });
    } else if (params.obliquity.value < -obliquityMax) {
      setGenParam("obliquity")({ value: -obliquityMax, unit: "deg" });
    }
  }, [obliquityMax, params.obliquity, setGenParam]);

  const [c, C] = useState(0);
  useEffect(() => {
    if (c === 0 && params.setNumberOfTurbines) {
      C(c + 1);
      setGenParam("numberOfTurbines")(maxNumberOfTurbines);
    }
  }, [c, maxNumberOfTurbines, params.setNumberOfTurbines, setGenParam]);

  return (
    <Column style={columnStyle}>
      <OverlineText style={{ paddingTop: "1.2rem" }}>Spacing</OverlineText>
      <LayoutRotation
        value={params.rotate}
        set={setGenParam("rotate")}
        convert={Angle.convert}
        meanWindDirection={mwd}
      />
      <TurbineMajorAxisSpacing
        value={params.majorAxisSpacing}
        set={setGenParam("majorAxisSpacing")}
        convert={convertD}
      />
      <TurbineMinorAxisSpacing
        value={params.minorAxisSpacing}
        set={setGenParam("minorAxisSpacing")}
        convert={convertD}
      />
      <OverlineText style={{ paddingTop: "1.6rem" }}>Grid</OverlineText>
      <Obliquity
        value={params.obliquity}
        set={setGenParam("obliquity")}
        convert={Angle.convert}
        min={-obliquityMax}
        max={obliquityMax}
      />
      <ShiftParkX
        value={params.shiftX}
        set={setGenParam("shiftX")}
        convert={convertD}
      />
      <ShiftParkY
        value={params.shiftY}
        set={setGenParam("shiftY")}
        convert={convertD}
      />
      <OverlineText style={{ paddingTop: "1.6rem" }}>Options</OverlineText>
      <IncludeNumberOfTurbines
        value={params.setNumberOfTurbines}
        set={(v) => {
          setGenParam("setNumberOfTurbines")(v);
          C(0);
        }}
        numTurbines={numTurbines}
        capacityDensity={capacityDensity}
      />
      {params.setNumberOfTurbines && (
        <NumberOfTurbines
          disabled={!params.setNumberOfTurbines}
          value={numTurbines}
          set={(v) => {
            setGenParam("numberOfTurbines")(v);
          }}
          max={max}
          min={1}
        />
      )}
      {numTurbines > maxNumberOfTurbines && (
        <SimpleAlert
          text={`Cannot fit more than ${maxNumberOfTurbines} turbines`}
        />
      )}
    </Column>
  );
};

const EdgeGenerationSettings = ({
  params,
  setGenParam,
  convertD,
  region,
  turbineType,
  maxNumberOfTurbines,
  capacityDensity,
  area,
}: {
  params: EdgeParametersWithUnit;
  maxNumberOfTurbines: number;
  area: number;
} & Props) => {
  const lonlat = useMemo(
    () => turf.centerOfMass(region).geometry.coordinates,
    [region],
  );
  const mwd = useRecoilValueLoadable(
    mostProbableWindDirectionSelector({
      lon: lonlat[0],
      lat: lonlat[1],
      height: turbineType.hubHeight,
    }),
  ).valueMaybe();

  const numTurbines = params.setNumberOfTurbines
    ? params.numberOfTurbines
    : maxNumberOfTurbines;

  const max = useMemo(
    () =>
      Math.min(
        Math.ceil((area * MAX_MW_PER_AREA) / (turbineType.ratedPower / 1e3)),
        MAX_PARK_TURBINES,
      ),
    [turbineType, area],
  );

  const obliquityMax = useMemo(() => {
    const shiftMax = params.majorAxisSpacing.value / 2;
    return Math.ceil(
      Math.atan(shiftMax / params.minorAxisSpacing.value) * (180 / Math.PI),
    );
  }, [params.majorAxisSpacing, params.minorAxisSpacing]);

  useEffect(() => {
    if (params.obliquity.value > obliquityMax) {
      setGenParam("obliquity")({ value: obliquityMax, unit: "deg" });
    } else if (params.obliquity.value < -obliquityMax) {
      setGenParam("obliquity")({ value: -obliquityMax, unit: "deg" });
    }
  }, [obliquityMax, params.obliquity, setGenParam]);

  const [c, C] = useState(0);
  useEffect(() => {
    if (c === 0 && params.setNumberOfTurbines) {
      C(c + 1);
      setGenParam("numberOfTurbines")(maxNumberOfTurbines);
    }
  }, [c, maxNumberOfTurbines, params.setNumberOfTurbines, setGenParam]);

  return (
    <Column style={columnStyle}>
      <OverlineText style={{ paddingTop: "1.2rem" }}>Spacing</OverlineText>
      <LayoutRotation
        value={params.rotate}
        set={setGenParam("rotate")}
        convert={Angle.convert}
        meanWindDirection={mwd}
      />
      <TurbineMajorAxisSpacing
        value={params.majorAxisSpacing}
        set={setGenParam("majorAxisSpacing")}
        convert={convertD}
      />
      <TurbineMinorAxisSpacing
        value={params.minorAxisSpacing}
        set={setGenParam("minorAxisSpacing")}
        convert={convertD}
      />
      <TurbineEdgeSpacing
        value={params.edgeSpacing}
        set={setGenParam("edgeSpacing")}
        convert={convertD}
      />
      <OverlineText style={{ paddingTop: "1.6rem" }}>Grid</OverlineText>
      <Obliquity
        value={params.obliquity}
        set={setGenParam("obliquity")}
        convert={Angle.convert}
        min={-obliquityMax}
        max={obliquityMax}
      />
      <ShiftParkX
        value={params.shiftX}
        set={setGenParam("shiftX")}
        convert={convertD}
      />
      <ShiftParkY
        value={params.shiftY}
        set={setGenParam("shiftY")}
        convert={convertD}
      />
      <OverlineText style={{ paddingTop: "1.6rem" }}>Options</OverlineText>
      <IncludeNumberOfTurbines
        value={params.setNumberOfTurbines}
        set={(v) => {
          setGenParam("setNumberOfTurbines")(v);
          C(0);
        }}
        numTurbines={numTurbines}
        capacityDensity={capacityDensity}
      />
      {params.setNumberOfTurbines && (
        <NumberOfTurbines
          disabled={!params.setNumberOfTurbines}
          value={numTurbines}
          set={(v) => {
            setGenParam("numberOfTurbines")(v);
          }}
          max={max}
          min={1}
        />
      )}
      {numTurbines > maxNumberOfTurbines && (
        <SimpleAlert
          text={`Cannot fit more than ${maxNumberOfTurbines} turbines`}
        />
      )}
    </Column>
  );
};

function isBufferedPolygonValid(
  region: ParkFeature | SubAreaFeature,
  bufferDistance: number,
) {
  const polygon = region.geometry;
  const buffered = turf.buffer(polygon, bufferDistance, {
    units: "meters",
  });
  return buffered?.geometry?.type === "Polygon";
}

const OptimizeGenerationSettings = ({
  params,
  turbineType,
  park,
  region,
  setGenParam,
  makeTurbineFeatures,
  selectedZoneSize,
  selectedRegion,
  capacityDensity,
}: {
  park: ParkFeature;
  region: ParkFeature | SubAreaFeature;
  params: OptimizeParametersWithUnit;
  turbineType: SimpleTurbineType;
  selectedZoneSize: number | undefined;
  selectedRegion: ParkFeature | SubAreaFeature | undefined;
  makeTurbineFeatures: (lonlats: [number, number][]) => TurbineFeature[];
} & Props) => {
  const bufferDistance = -turbineType.diameter;

  const invalidPolygon =
    params.includeEdge && !isBufferedPolygonValid(region, bufferDistance);

  const { projectId, branchId } = useTypedPath("projectId", "branchId");
  const selectedWindConfigForOpt = useRecoilValue(windConfigForOptimization);

  const shouldCheckForAvailability =
    params.overrideDataSource &&
    (selectedWindConfigForOpt?.source.id === WindDataSource.NORA3 ||
      selectedWindConfigForOpt?.source.id === WindDataSource.CERRA);

  const lonlat = useMemo(() => {
    return turf.centroid(region).geometry.coordinates;
  }, [region]);

  const availableDatasetsAtLocation = useRecoilValueLoadable(
    windDatasetsSelectorFamily({
      lon: lonlat[0],
      lat: lonlat[1],
    }),
  );

  const selectedDatasetIsAvailable = useMemo(() => {
    if (!shouldCheckForAvailability) return true;

    if (
      availableDatasetsAtLocation.state !== "hasValue" ||
      !selectedWindConfigForOpt
    )
      return false;
    return availableDatasetsAtLocation.contents.some(
      (dataset: any) =>
        dataset.source.id === selectedWindConfigForOpt.source.id,
    );
  }, [
    availableDatasetsAtLocation,
    selectedWindConfigForOpt,
    shouldCheckForAvailability,
  ]);

  const createOptProblem = useOptimizeTurbines(park, region.id, params);
  const setOpenOptItem = useSetRecoilState(openOptListItem);
  const [buttonLoads, setButtonLoads] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  const regionSize = useMemo(() => turf.area(region) / 1e6, [region]);
  const area = selectedZoneSize ?? regionSize;
  const ids: Ids | undefined = useMemo(
    () =>
      projectId
        ? {
            nodeId: projectId,
            branchId: branchId,
            zoneId: region.id,
          }
        : undefined,
    [projectId, branchId, region.id],
  );

  const maxTurbines = useMemo(
    () =>
      Math.min(
        Math.ceil((area * MAX_MW_PER_AREA) / (turbineType.ratedPower / 1e3)),
        MAX_TURBINES_OPTIMIZE,
        Math.ceil(
          (4 * area) /
            (Math.PI * ((turbineType.diameter / 1e3) * params.minSpacing) ** 2),
        ),
      ),
    [turbineType, area, params.minSpacing],
  );

  const minTurbines = useMemo(
    () =>
      clamp(
        MIN_TURBINES_OPTIMIZE,
        Math.floor((area * MIN_MW_PER_AREA) / (turbineType.ratedPower / 1e3)),
        maxTurbines,
      ),
    [area, turbineType.ratedPower, maxTurbines],
  );

  const initialTurbines = useMemo(
    () =>
      clamp(
        MIN_TURBINES_OPTIMIZE,
        Math.floor((area * IDEAL_MW_PER_AREA) / (turbineType.ratedPower / 1e3)),
        maxTurbines,
      ),
    [area, turbineType.ratedPower, maxTurbines],
  );

  if (params.numberOfTurbines === 0) {
    setTimeout(() => setGenParam("numberOfTurbines")(initialTurbines), 0);
  }

  const { update: updateFeatures } = useProjectElementsCrud();

  const { getFeaturesToSave } = useTurbineGeneration(
    park,
    selectedRegion ?? park,
    //We dont really need a turbine type since we are not using any generation methods
    DEFAULT_TURBINES[0] as SimpleTurbineType,
  );

  const coordsToFeatures = useCallback(
    (points: [number, number][]) => {
      const [reflon, reflat] = region.geometry.coordinates[0][0];
      const lonlats = points.map<[number, number]>(([x, y]) =>
        xy2lonlat(x, y, reflon, reflat),
      );
      const features = makeTurbineFeatures(lonlats);
      setTurbineNames(features);
      return features;
    },
    [makeTurbineFeatures, region.geometry.coordinates],
  );

  const onOptionClick: Parameters<
    typeof PreviousOptimizationsList
  >[0]["onOptionClick"] = useCallback(
    async (res: OptResult, _: OptProblem, isOutdated: boolean) => {
      if (!isCompleted(res)) return;

      if (
        isOutdated &&
        !window.confirm(
          "This optimization was run on another version of the park.  Do you still want to use the resulting turbines?",
        )
      )
        return;

      const [reflon, reflat] = region.geometry.coordinates[0][0];
      const lonlats = res.positions.map<[number, number]>(([x, y]) =>
        xy2lonlat(x, y, reflon, reflat),
      );
      const features = makeTurbineFeatures(lonlats);
      setTurbineNames(features);

      let featuresToSave: {
        add: ProjectFeature[];
        remove: string[];
      } = getFeaturesToSave(features);

      await updateFeatures(featuresToSave);
    },
    [makeTurbineFeatures, region.geometry, updateFeatures, getFeaturesToSave],
  );
  const trackEvent = useTrackEvent();
  return (
    <OptimizeTurbinesContext.Provider
      value={{
        region,
        park,
        coordsToFeatures,
      }}
    >
      <Column style={{ rowGap: spaceMedium, padding: "0 0 1.2rem 0" }}>
        <OverlineText>Input</OverlineText>
        <NumberOfTurbinesOptimize
          value={params.numberOfTurbines}
          set={(v) => {
            setGenParam("numberOfTurbines")(v);
          }}
          max={maxTurbines}
          min={minTurbines}
        />

        <p style={{ color: colors.textDisabled }}>
          {`Capacity density: ${capacityDensity.toFixed(1)} MW/km²`}
        </p>
        {params.numberOfTurbines > maxTurbines && (
          <SimpleAlert
            text={`Cannot fit more than ${maxTurbines} turbines`}
            type={"error"}
          />
        )}
      </Column>
      <OverlineTextWrapper onClick={() => setShowOptions(!showOptions)}>
        <OverlineText>Options</OverlineText>
        <ChevronIcon
          open={showOptions}
          chevronSize={"1rem"}
          style={{
            alignSelf: "center",
          }}
        />
      </OverlineTextWrapper>
      {showOptions && (
        <Column style={{ rowGap: spaceMedium, padding: "1.2rem 0 2rem 0" }}>
          <OptimizeMinSpacing
            value={params.minSpacing}
            set={setGenParam("minSpacing")}
            max={MAX_MIN_SPACING}
            min={MIN_MIN_SPACING}
          />
          <OptimizeIncludeEdge
            value={params.includeEdge}
            set={(v) => {
              setGenParam("includeEdge")(v);
            }}
          />
          <OptimizeIncludeNeighbours
            value={params.includeNeighbours}
            set={(v) => {
              setGenParam("includeNeighbours")(v);
            }}
          />
          <OptimizeSelectDataSource
            value={params.overrideDataSource}
            set={(v) => {
              setGenParam("overrideDataSource")(v);
            }}
          />
          <OptimizeWakeModel
            value={params.wakeModel}
            set={(v) => {
              setGenParam("wakeModel")(v);
            }}
          />
        </Column>
      )}

      {!selectedDatasetIsAvailable && (
        <SimpleAlert text={"Not available at this location"} type={"error"} />
      )}
      {invalidPolygon && (
        <SimpleAlert text={"Invalid polygon"} type={"error"} />
      )}

      <SubtitleWithLine text={"Results"} />
      <Suspense
        fallback={
          <SkeletonText
            style={{
              width: "initial",
              height: "2rem",
              margin: `${spaceSmall} ${spaceLarge}`,
            }}
          />
        }
      >
        {ids && (
          <PreviousOptimizationsList
            ids={ids}
            selectedRegion={region}
            onOptionClick={onOptionClick}
          />
        )}
      </Suspense>

      <Row style={{ alignItems: "center", paddingTop: "2rem" }}>
        <SkeletonText
          style={{
            opacity: buttonLoads ? 1 : 0,
            flex: 1,
          }}
        />
        <HighlightStep stepId="optimise" tourId="general-intro-tour">
          <Button
            text={"Start"}
            onClick={async () => {
              trackEvent("optimisationStarted");
              Mixpanel.track("Triggered optimization", {
                projectId,
                branchId,
              });
              setButtonLoads(true);
              const result = await createOptProblem(selectedWindConfigForOpt);
              setButtonLoads(false);
              if (!result) return;
              setOpenOptItem(result.id);
            }}
            disabled={
              !selectedDatasetIsAvailable ||
              buttonLoads ||
              params.numberOfTurbines === 0 ||
              invalidPolygon
            }
          />
        </HighlightStep>
      </Row>
    </OptimizeTurbinesContext.Provider>
  );
};

function removeFarthestTurbines(
  turbineFeatures: TurbineFeature[],
  layoutSettings: RegularParameters,
  regionGeometry: ParkFeature["geometry"],
): TurbineFeature[] {
  const centerOfMass = turf.centerOfMass(regionGeometry);
  const [centerLon, centerLat] = centerOfMass.geometry.coordinates;
  const shiftedCenterLon =
    centerLon + meterToCoords(xDistAtLat(centerLat, layoutSettings.shiftX));
  const shiftedCenterLat = centerLat + meterToCoords(layoutSettings.shiftY);

  const distances: Map<number, number> = new Map();

  turbineFeatures.forEach((turbine, index) => {
    const distance = turf.distance(
      [shiftedCenterLon, shiftedCenterLat],
      turbine.geometry.coordinates,
    );
    distances.set(index, distance);
  });

  const sortedTurbines = turbineFeatures.slice().sort((a, b) => {
    const distanceA = distances.get(turbineFeatures.indexOf(a)) || 0;
    const distanceB = distances.get(turbineFeatures.indexOf(b)) || 0;
    return distanceA - distanceB;
  });

  const slicedTurbineFeatures = sortedTurbines.slice(
    0,
    layoutSettings.numberOfTurbines,
  );

  return slicedTurbineFeatures;
}

export const GenerationSettings = ({
  park,
  region,
  selectedZoneSize,
  selectedRegion,
  live,
  innerRef,
}: {
  park: ParkFeature;
  region: ParkFeature | SubAreaFeature;
  selectedZoneSize: number | undefined;
  selectedRegion: ParkFeature | SubAreaFeature | undefined;
  live: boolean;
  innerRef?: ((node: ReferenceType | null) => void) &
    ((node: ReferenceType | null) => void);
}) => {
  const map = useRecoilValue(mapRefAtom);
  const projectId = useRecoilValue(projectIdSelector);
  const generationState = useRecoilValue(generationStateAtom);
  const setLive = useSetRecoilState(turbinesAndFoundationsGenerationIsLiveAtom);
  const [methodAndParameters, setMethodAndParameters] = useRecoilState(
    methodAndParametersForRegionAtomFamily(region.id),
  );

  const turbines = useRecoilValue(
    getTurbinesSelectorFamily({ parkId: park.id }),
  );

  const currentTurbineTypeId = useRecoilValue(
    currentTurbineIdAtom({ projectId }),
  );
  const allTurbineTypes = useRecoilValue(allSimpleTurbineTypesSelector);
  const turbineType = useMemo(
    () =>
      allTurbineTypes.find((tt) => tt.id === currentTurbineTypeId) ??
      (DEFAULT_TURBINES[0] as SimpleTurbineType),
    [allTurbineTypes, currentTurbineTypeId],
  );

  const turbineDistanceConvert = useMemo(
    () => TurbineDistance.makeConvert(turbineType?.diameter ?? 0),
    [turbineType],
  );
  const turbineDistCannonical = useMemo(() => {
    return ({ value, unit }: TurbineDistance.Of<number>): number =>
      turbineDistanceConvert(value, { from: unit });
  }, [turbineDistanceConvert]);
  const setPreviewState = useSetRecoilState(previewTurbinesState);

  const toCannonical = useCallback(
    (
      settings: GenerationMethodAndParametersWithUnit,
    ): GenerationMethodAndParameters => {
      switch (settings.method) {
        case "regular":
          return {
            method: "regular",
            params: {
              setNumberOfTurbines: settings.params.setNumberOfTurbines,
              numberOfTurbines: settings.params.numberOfTurbines,
              minorAxisSpacing: turbineDistCannonical(
                settings.params.minorAxisSpacing,
              ),
              majorAxisSpacing: turbineDistCannonical(
                settings.params.majorAxisSpacing,
              ),
              rotate: Angle.to(settings.params.rotate, "deg"),
              obliquity: Angle.to(settings.params.obliquity, "deg"),
              shiftX: turbineDistCannonical(settings.params.shiftX),
              shiftY: turbineDistCannonical(settings.params.shiftY),
            },
          };
        case "edge":
          return {
            method: "edge",
            params: {
              setNumberOfTurbines: settings.params.setNumberOfTurbines,
              numberOfTurbines: settings.params.numberOfTurbines,
              minorAxisSpacing: turbineDistCannonical(
                settings.params.minorAxisSpacing,
              ),
              majorAxisSpacing: turbineDistCannonical(
                settings.params.majorAxisSpacing,
              ),
              rotate: Angle.to(settings.params.rotate, "deg"),
              obliquity: Angle.to(settings.params.obliquity, "deg"),
              shiftX: turbineDistCannonical(settings.params.shiftX),
              shiftY: turbineDistCannonical(settings.params.shiftY),
              edgeSpacing: turbineDistCannonical(settings.params.edgeSpacing),
            },
          };
        case "regular_auto":
          return settings;
      }
    },
    [turbineDistCannonical],
  );

  const { computeTurbines, makeTurbineFeatures, setPreviewTurbines } =
    useTurbineGeneration(park, region ?? park, turbineType);

  const [maxNumberOfTurbines, setMaxNumberOfTurbines] =
    useState<number>(MAX_PARK_TURBINES);

  const mooringParams = useRecoilValue(combinedGenMooringParameters);
  const generateFromUnitSettings = useMemo(() => {
    return throttle(50, (withUnit: GenerationMethodAndParametersWithUnit) => {
      if (!live) return;
      const noUnit = toCannonical(withUnit);
      const turbineFeatures = computeTurbines(noUnit, mooringParams);

      if (turbineFeatures) {
        setMaxNumberOfTurbines(turbineFeatures.length);
      }

      if (
        noUnit.method === "regular" &&
        noUnit.params.setNumberOfTurbines &&
        turbineFeatures &&
        noUnit.params.numberOfTurbines < turbineFeatures.length
      ) {
        const newTurbineFeatures = removeFarthestTurbines(
          turbineFeatures,
          noUnit.params,
          region.geometry,
        );

        setPreviewTurbines(newTurbineFeatures);
        return;
      } else if (
        noUnit.method === "edge" &&
        noUnit.params.setNumberOfTurbines &&
        turbineFeatures &&
        noUnit.params.numberOfTurbines < turbineFeatures.length
      ) {
        const spacing =
          0.5 *
          (noUnit.params.minorAxisSpacing + noUnit.params.majorAxisSpacing);
        const distanceToBoundary = Math.min(spacing, noUnit.params.edgeSpacing);
        const innerTurbines = turbineFeatures.filter(
          (t) =>
            distanceToBoundary <
            fastMin(
              region.geometry.coordinates.map((ring) =>
                turf.pointToLineDistance(
                  t.geometry.coordinates,
                  turf.lineString(ring),
                  {
                    units: "meters",
                  },
                ),
              ),
            ),
        );
        const outerTurbines = turbineFeatures.filter(
          (t) => !innerTurbines.map((it) => it.id).includes(t.id),
        );

        if (noUnit.params.numberOfTurbines <= outerTurbines.length) {
          const newTurbineFeatures = outerTurbines.slice(
            0,
            noUnit.params.numberOfTurbines,
          );
          setPreviewTurbines(newTurbineFeatures);
          return;
        } else {
          const remainingTurbines =
            noUnit.params.numberOfTurbines - outerTurbines.length;

          const newTurbineFeatures = removeFarthestTurbines(
            innerTurbines,
            {
              ...noUnit.params,
              numberOfTurbines: remainingTurbines,
            },
            region.geometry,
          );
          setPreviewTurbines([...newTurbineFeatures, ...outerTurbines]);
          return;
        }
      }

      if (turbineFeatures) {
        setPreviewTurbines(turbineFeatures);
      }
    });
  }, [
    computeTurbines,
    live,
    mooringParams,
    region.geometry,
    setPreviewTurbines,
    toCannonical,
  ]);

  useEffect(() => {
    if (live && methodAndParameters) {
      generateFromUnitSettings(methodAndParameters);
    } else setPreviewState(undefined);
  }, [generateFromUnitSettings, methodAndParameters, live, setPreviewState]);

  /** Set a single field for the generation parameters. This is what's called
   * when a slider is dragged.
   */
  const setGenParam = useCallback(
    (field: string) =>
      <T,>(value: T) => {
        if (!methodAndParameters) {
          return;
        }
        let cfg = methodAndParameters;
        // Weird TS things here: removeing the `if`s doesn't typecheck, even
        // though all of the bodies are the same.
        let next: GenerationMethodAndParametersWithUnit;
        if (cfg.method === "regular")
          next = {
            ...cfg,
            params: {
              ...cfg.params,
              [field]: value,
            },
          };
        else if (cfg.method === "edge")
          next = {
            ...cfg,
            params: {
              ...cfg.params,
              [field]: value,
            },
          };
        else if (cfg.method === "regular_auto") {
          const params = {
            ...cfg.params,
            [field]: value,
          };
          next = {
            ...cfg,
            params,
          };
        } else {
          throw isNever(cfg);
        }

        setMethodAndParameters(next);
        generateFromUnitSettings(next);
      },
    [generateFromUnitSettings, methodAndParameters, setMethodAndParameters],
  );

  /** Change the method of generation. Tries to transfer over generation
   * settings at a best effort.  */
  const changeGenerationMethod = useCallback(
    (newMethod: GenerationMethod) => {
      if (!methodAndParameters) {
        return;
      }
      setLive(false);
      // NOTE: mp.method is the current (to-be previous) method.
      const nextMethodAndParam = ((): GenerationMethodAndParametersWithUnit => {
        if (methodAndParameters.method === "regular_auto") {
          if (newMethod === "regular") {
            return {
              method: "regular",
              params:
                (inferTurbineParameters(turbines, region, "regular") as any)[
                  "value"
                ] ?? defaultRegularParamsWithUnits(),
            };
          } else if (newMethod === "edge") {
            return {
              method: "edge",
              params:
                (inferTurbineParameters(turbines, region, "edge") as any)[
                  "value"
                ] ?? defaultEdgeParamsWithUnits(),
            };
          } else {
            return methodAndParameters;
          }
        } else if (newMethod === "regular") {
          return {
            method: "regular",
            params:
              methodAndParameters.method === "edge"
                ? {
                    setNumberOfTurbines:
                      methodAndParameters.params.setNumberOfTurbines,
                    numberOfTurbines:
                      methodAndParameters.params.numberOfTurbines,
                    minorAxisSpacing:
                      methodAndParameters.params.minorAxisSpacing,
                    majorAxisSpacing:
                      methodAndParameters.params.majorAxisSpacing,
                    obliquity: methodAndParameters.params.obliquity,
                    shiftX: methodAndParameters.params.shiftX,
                    shiftY: methodAndParameters.params.shiftY,
                    rotate: methodAndParameters.params.rotate,
                  }
                : defaultRegularParamsWithUnits(),
          };
        } else if (newMethod === "edge") {
          const _default = defaultEdgeParamsWithUnits();
          return {
            method: "edge",
            params:
              methodAndParameters.method === "regular"
                ? {
                    ...methodAndParameters.params,
                    edgeSpacing: _default.edgeSpacing,
                  }
                : _default,
          };
        } else if (newMethod === "regular_auto") {
          setPreviewState(undefined);
          return {
            method: newMethod,
            params: defaultOptimizeParametersWithUnits(),
          };
        } else {
          throw isNever(newMethod);
        }
      })();

      setMethodAndParameters(nextMethodAndParam);
    },
    [
      setLive,
      methodAndParameters,
      region,
      setMethodAndParameters,
      setPreviewState,
      turbines,
    ],
  );

  const regionSize = useMemo(() => turf.area(region) / 1e6, [region]);
  const area = selectedZoneSize ?? regionSize;
  const numTurbines =
    methodAndParameters?.method === "regular_auto" ||
    methodAndParameters?.params.setNumberOfTurbines
      ? methodAndParameters.params.numberOfTurbines
      : maxNumberOfTurbines;
  const capacityDensity = (numTurbines * turbineType.ratedPower) / 1e3 / area;

  return (
    <>
      <SubtitleWithLine text={"Turbines"} />
      <Column>
        <TurbineControl />
        <Label left>
          <InputTitle id="tour-generate-layout-method">Method</InputTitle>
          <Dropdown
            small
            aria-label="Method"
            onChange={(e) => {
              changeGenerationMethod(_GenerationMethod.parse(e.target.value));
            }}
            value={methodAndParameters?.method}
          >
            <option value="regular">{"Regular"}</option>
            <option value="edge">{"Edge + regular"}</option>
            <option value="regular_auto">{"Optimize (regular)"}</option>
          </Dropdown>
        </Label>
      </Column>

      {!turbineType ? (
        <div>
          <SimpleAlert text={"Missing turbine type"} type={"error"} />
        </div>
      ) : (
        <>
          <SubtitleWithLine
            style={{ paddingTop: "2rem" }}
            text={"Controls"}
            innerRef={innerRef}
          />
          <div>
            {generationState === State.Failed && (
              <SimpleAlert
                text={"Generation failed - the Vind team is notified"}
                type={"error"}
              />
            )}
            {generationState === State.Stopped && (
              <SimpleAlert text={"Generation was stopped"} type={"error"} />
            )}
          </div>

          {methodAndParameters?.method === "regular" ? (
            <RegularGenerationSettings
              region={region}
              params={methodAndParameters.params}
              turbineType={turbineType}
              setGenParam={setGenParam}
              convertD={turbineDistanceConvert}
              maxNumberOfTurbines={maxNumberOfTurbines}
              capacityDensity={capacityDensity}
              area={area}
            />
          ) : methodAndParameters?.method === "edge" ? (
            <EdgeGenerationSettings
              region={region}
              params={methodAndParameters.params}
              turbineType={turbineType}
              setGenParam={setGenParam}
              convertD={turbineDistanceConvert}
              maxNumberOfTurbines={maxNumberOfTurbines}
              capacityDensity={capacityDensity}
              area={area}
            />
          ) : (
            methodAndParameters && (
              <OptimizeGenerationSettings
                park={park}
                region={region}
                selectedZoneSize={selectedZoneSize}
                selectedRegion={selectedRegion}
                params={methodAndParameters.params}
                turbineType={turbineType}
                setGenParam={setGenParam}
                convertD={turbineDistanceConvert}
                makeTurbineFeatures={makeTurbineFeatures}
                capacityDensity={capacityDensity}
              />
            )
          )}
        </>
      )}
      {region &&
        methodAndParameters &&
        (methodAndParameters.method === "regular" ||
          methodAndParameters.method === "edge") && (
          <>
            <AxisLines
              region={region}
              minorAxisSpacing={turbineDistCannonical(
                methodAndParameters.params.minorAxisSpacing,
              )}
              majorAxisSpacing={turbineDistCannonical(
                methodAndParameters.params.majorAxisSpacing,
              )}
              rotation={Angle.toCannonical(methodAndParameters.params.rotate)}
              obliquity={Angle.toCannonical(
                methodAndParameters.params.obliquity,
              )}
              shiftX={turbineDistCannonical(methodAndParameters.params.shiftX)}
              shiftY={turbineDistCannonical(methodAndParameters.params.shiftY)}
            />
            {map && (
              <TurbineEllipses
                map={map}
                region={region}
                parkId={park.id}
                minorAxisSpacing={turbineDistCannonical(
                  methodAndParameters.params.minorAxisSpacing,
                )}
                majorAxisSpacing={turbineDistCannonical(
                  methodAndParameters.params.majorAxisSpacing,
                )}
                rotation={Angle.toCannonical(methodAndParameters.params.rotate)}
                edgeSpacing={
                  methodAndParameters.method === "edge"
                    ? turbineDistCannonical(
                        methodAndParameters.params.edgeSpacing,
                      )
                    : undefined
                }
                turbineType={
                  methodAndParameters.method === "edge"
                    ? turbineType
                    : undefined
                }
              />
            )}
          </>
        )}
    </>
  );
};
