import { useTypedPath } from "state/pathParams";
import { addGroupMember, removeGroupMember } from "./service";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { groupMembersState, userGroupsMembershipState } from "./state";
import {
  loggedInUserSelector,
  orgTurbineManageAccessSelector,
  orgFoundationManageAccessSelector,
  orgAnalysisManageAccessSelector,
  orgCableManageAccessSelector,
  orgFinanicalManageAccessSelector,
} from "state/user";

const useMemberInGroupCrud = () => {
  const { organisationId } = useTypedPath("organisationId");
  const currentUserId = useRecoilValue(loggedInUserSelector)?.user_id;

  const add = useRecoilCallback(
    ({ set, snapshot, refresh }) =>
      async (groupId: string, userId: string) => {
        const fallback = await snapshot.getPromise(
          groupMembersState({ organisationId, groupId }),
        );
        return addGroupMember(organisationId, groupId, userId)
          .then((newMember) => {
            set(groupMembersState({ organisationId, groupId }), (cur) => [
              ...cur,
              newMember,
            ]);
            refresh(userGroupsMembershipState({ organisationId, userId }));
            if (currentUserId === userId) {
              refresh(orgTurbineManageAccessSelector);
              refresh(orgFoundationManageAccessSelector);
              refresh(orgAnalysisManageAccessSelector);
              refresh(orgCableManageAccessSelector);
              refresh(orgFinanicalManageAccessSelector);
            }
          })
          .catch(() => {
            set(groupMembersState({ organisationId, groupId }), fallback);
          });
      },
    [currentUserId, organisationId],
  );

  const remove = useRecoilCallback(
    ({ set, snapshot, refresh }) =>
      async (groupId: string, userId: string) => {
        const fallback = await snapshot.getPromise(
          groupMembersState({ organisationId, groupId }),
        );
        return removeGroupMember(organisationId, groupId, userId)
          .then(() => {
            set(groupMembersState({ organisationId, groupId }), (cur) =>
              cur.filter((u) => u.user_id !== userId),
            );
            refresh(userGroupsMembershipState({ organisationId, userId }));
            currentUserId === userId && refresh(orgTurbineManageAccessSelector);
            currentUserId === userId &&
              refresh(orgFoundationManageAccessSelector);
          })
          .catch(() => {
            set(groupMembersState({ organisationId, groupId }), fallback);
          });
      },
    [currentUserId, organisationId],
  );

  return { add, remove };
};

export default useMemberInGroupCrud;
