/// <reference types="vite-plugin-svgr/client" />
import { ReactNode, useCallback, useRef, useState } from "react";
import styled, { CSSProperties } from "styled-components";
import { colors } from "../../../styles/colors";
import Chevron from "@icons/24/ArrowRight.svg?react";
import { typography } from "../../../styles/typography";
import { DropDownItem, DropDownItems } from "./DropdownItems";
import { useClickOutside } from "../../../hooks/useClickOutside";
import { isDefined } from "../../../utils/predicates";
import { Anchor } from "../Anchor";
import { spaceMedium } from "styles/space";

const ChevronWrapper = styled.div<{
  open: boolean;
  position?: "top" | "bottom";
}>`
  transform: rotate(${(p) => (p.open ? "-90deg" : "90deg")});
  transition: 0.1s;
  display: flex;
  align-items: center;
  justify-self: end;
  svg {
    width: 1rem;
    height: 1rem;
  }
`;

// I'm not sure why we had this in the first place.  Mabye to avoid having
// `position: relative` on the outermost element?
const DropdownContainer2 = styled.div`
  display: flex;
  jusify-content: center;
  width: 100%;

  &.hide-for-export {
    ${ChevronWrapper} {
      display: none;
    }
  }
`;

export const DropDownContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

const DropDownButton = styled.button<{
  open: boolean;
  size: "small" | "large" | "fixed";
}>`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  height: ${(p) => (p.size === "small" ? "2.6rem" : "3.2rem")};
  padding: ${(p) => (p.size === "small" ? "0.2rem 0.8rem" : "0.6rem 0.8rem")};
  cursor: pointer;
  box-sizing: border-box;
  background: initial;
  border-radius: 0.4rem;
  border: 1px solid ${colors.borderDefault};

  overflow: hidden;
  width: ${(p) => (p.size === "fixed" ? "20rem" : "100%")};

  justify-content: space-between;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    border: 1px solid ${colors.borderSelected};
  }
`;

export const ButtonText = styled.div`
  ${typography.body}
  color: ${colors.textPrimary};
  white-space: nowrap;
  margin: 0;
  overflow-x: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 100%;
  text-align: left;
  gap: 0.8rem;
`;

export const ButtonTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spaceMedium};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default function DropdownButton<T extends string | number = string>({
  items,
  onSelectItem,
  selectedItemValue,
  position = "bottom",
  size = "large",
  disabled,
  buttonText,
  renderText,
  style = {},
  actionItems = [],
  className,
  ignoreClickFn,
  showChevron = true,
  scrollToActiveItem = true,
  icon,
  onDragItem,
  listContainerStyle,
  chevronStyle,
  keepOpen,
}: {
  items: DropDownItem<T>[];
  onSelectItem: (val: T) => void;
  buttonText: string;
  renderText?: (text: string) => ReactNode;
  position?: "top" | "bottom";
  size?: "large" | "small" | "fixed";
  selectedItemValue?: string;
  disabled?: boolean;
  style?: CSSProperties;
  actionItems?: DropDownItem<T>[];
  className?: string;
  ignoreClickFn?: Parameters<typeof useClickOutside>[2];
  showChevron?: boolean;
  scrollToActiveItem?: boolean;
  icon?: ReactNode;
  listContainerStyle?: CSSProperties;
  chevronStyle?: CSSProperties;
  keepOpen?: boolean;
  onDragItem?(item: DropDownItem<T>, index: number): void;
}) {
  const [_isOpen, setIsOpen] = useState(false);

  const dropDownWrapperRef = useRef<HTMLDivElement>(null);

  const onOptionClicked = useCallback(
    (item: DropDownItem<T>, keepOpen?: boolean) => {
      onSelectItem(item.value);
      if (!keepOpen) {
        setIsOpen(false);
      }
    },
    [onSelectItem],
  );

  return (
    <DropdownContainer2 className={className}>
      <DropDownContainer ref={dropDownWrapperRef}>
        <DropDownButton
          open={_isOpen}
          size={size}
          disabled={disabled}
          onClick={(e) => {
            if (e.detail === 0) {
              return;
            }
            setIsOpen(!_isOpen);
          }}
          style={style}
        >
          {renderText ? (
            renderText(buttonText)
          ) : (
            <>
              {isDefined(icon) ? <>{icon}</> : null}
              <ButtonText>{buttonText}</ButtonText>
            </>
          )}
          {showChevron && (
            <ChevronWrapper open={_isOpen} position={position}>
              <Chevron style={chevronStyle} />
            </ChevronWrapper>
          )}
        </DropDownButton>
        {_isOpen && (
          <Anchor
            baseRef={dropDownWrapperRef}
            basePlace={position === "top" ? "topLeft" : "bottomLeft"}
            floatPlace={position === "top" ? "bottomLeft" : "topLeft"}
          >
            <DropDownItems
              isOpen={_isOpen}
              items={items}
              size={size}
              selectedOption={selectedItemValue}
              keepOpen={keepOpen}
              onOptionClicked={onOptionClicked}
              position={position}
              style={listContainerStyle}
              actionItems={actionItems}
              setIsOpen={setIsOpen}
              ignoreClickFn={(target, source, event) => {
                if (ignoreClickFn?.(target, source, event)) return true;
                if (target === dropDownWrapperRef.current) return true;
                return false;
              }}
              scrollToActiveItem={scrollToActiveItem}
              onDragItem={onDragItem}
            />
          </Anchor>
        )}
      </DropDownContainer>
    </DropdownContainer2>
  );
}
