/// <reference types="vite-plugin-svgr/client" />
import debounce from "debounce";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { TopRightMenuOptions } from "../../../../constants/canvas";
import Production from "../../../../icons/24/SideBarIcons/Production.svg?react";
import NoiseIcon from "../../../../icons/24/SideBarIcons/Noise.svg?react";
import Financial from "../../../../icons/24/SideBarIcons/Financial.svg?react";
import ViewFromShore from "../../../../icons/24/SideBarIcons/ViewFromShore.svg?react";
import Viewshed from "../../../../icons/24/SideBarIcons/Viewshed.svg?react";
import Electrical from "../../../../icons/24/SideBarIcons/Electrical.svg?react";
import Bathymetry from "../../../../icons/24/SideBarIcons/Bathymetry.svg?react";
import Foundation from "../../../../icons/24/SideBarIcons/Foundation.svg?react";
import { getAllTurbinesSelector } from "../../../../state/layout";
import { getParkFeatureSelectorFamily } from "../../../../state/park";
import {
  allSimpleTurbineTypesSelector,
  noiseLevelSelectorFamily,
  showNoiseAtom,
} from "../../../../state/turbines";
import {
  editorAccessProjectSelector,
  viewerAccessProjectSelector,
} from "../../../../state/user";
import { viewFromShoreVisibleAtom } from "../../../../state/viewToPark";
import { ParkFeature, ProjectFeature } from "../../../../types/feature";
import {
  NoiseTemperatures,
  TurbineNoiseSettings,
} from "../../../../types/turbines";
import {
  isSubArea,
  isPark,
  isPolygonFeature,
  isTurbine,
  isBathymetry,
  isGeotiff,
} from "../../../../utils/predicates";
import { Divider, IconMenu } from "../../../General/Icons";
import { FinancialAnalysisV2 } from "../../../FinancialAnalysis/FinancialAnalysis";
import { Grid2 } from "../../../General/Form";
import { InputDimensioned } from "../../../General/Input";
import {
  ARTICLE_NOISE_ANALYSIS,
  HelpLink,
} from "../../../HelpTooltip/HelpTooltip";
import {
  MenuFrame,
  RightMenuPositionWrapper,
} from "../../../MenuPopup/CloseableMenuPopup";
import { ProductionV2 } from "../../../ProductionV2/Productionv2";
import { projectFeaturesSelector } from "../../../ProjectElements/state";
import { useProjectElementsCrud } from "../../../ProjectElements/useProjectElementsCrud";
import { ViewToPark } from "../../../ViewToPark/ViewToPark";
import { ElectricalStatistics } from "../ElectricalStatistics/ElectricalStatistics";
import FoundationModal from "../FoundationModal/FoundationModal";
import TurbineModal from "../TurbineModal/TurbineModal";
import BathymetryAnalysis from "./BathymetryAnalysis";
import { MenuOptionV2 } from "./InfoModalV2.style";
import MapContextProvider from "./MapContextProvider";
import ViewshedAnalysis from "./ViewshedAnalysis";
import { TopRightModeActiveAtom, TopRightModeType } from "./state";
import { branchIdSelector, parkIdSelector } from "state/pathParams";
import { Slider } from "components/General/Slider";
import Dropdown from "components/Dropdown/Dropdown";
import { ChevronIcon } from "components/ToggleableList/ToggleableList";
import { versionHistoryOpenStateAtom } from "components/Design/ProjectHistory/state";
import { typography } from "styles/typography";
import {
  InputTitle,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import { TourStep } from "components/OnboardingTours/TourStep";
import { HighlightStep } from "components/OnboardingTours/HighlightWrapper";
import { useTrackEvent } from "components/OnboardingTours/state";
import { FloatingFocusManager, useFloating, offset } from "@floating-ui/react";
import { lowerRightMenuActiveModeAtom } from "state/layer";

export const Noise = ({ onClose }: { onClose(): void }) => {
  const editorAccessProject = useRecoilValue(editorAccessProjectSelector);
  const setShowNoise = useSetRecoilState(showNoiseAtom);
  const parkId = useRecoilValue(parkIdSelector);
  const turbineNoise = useRecoilValue(
    noiseLevelSelectorFamily({ parkId: parkId! }),
  );
  const projectFeatures = useRecoilValue(projectFeaturesSelector);
  const { update: updateFeatures } = useProjectElementsCrud();

  const updateNoise = useCallback(
    (newNoise: Partial<TurbineNoiseSettings>) => {
      const park = projectFeatures.find((l) => l.id === parkId) as ParkFeature;

      const noiseSettings = { ...turbineNoise, ...newNoise };
      const updatedPark: ParkFeature = {
        ...park,
        properties: { ...park.properties, noiseSettings },
      };
      updateFeatures({ update: [updatedPark] });
    },
    [projectFeatures, turbineNoise, updateFeatures, parkId],
  );

  const debouncedUpdateNoise = debounce(updateNoise, 100);
  const [assumptionsOpen, setAssumptionsOpen] = useState(true);

  useEffect(() => {
    setShowNoise(true);
    return () => setShowNoise(false);
  }, [setShowNoise]);

  return (
    <MenuFrame
      title={"Noise"}
      icon={<HelpLink article={ARTICLE_NOISE_ANALYSIS} />}
      onExit={onClose}
    >
      <p>
        The noise analysis uses the ISO-9613 standard. It assumes a flat, hard
        surface, and a humidity of 70%.
      </p>
      <Grid2 style={{ paddingTop: "2.4rem", paddingBottom: "1.6rem" }}>
        <InputTitle>Sound level at turbine</InputTitle>
        <InputDimensioned
          compact
          value={turbineNoise.source}
          onChange={(n) => debouncedUpdateNoise({ source: n })}
          readOnly={!editorAccessProject}
          validate={(n) => 90 <= n && n <= 120}
          unit={"dB"}
          style={{ minWidth: "8rem" }}
        />

        <InputTitle>Red boundary</InputTitle>
        <InputDimensioned
          compact
          value={turbineNoise.red}
          onChange={(n) => debouncedUpdateNoise({ red: n })}
          readOnly={!editorAccessProject}
          validate={(n) => 40 <= n && n <= 80}
          unit={"dB(A)"}
          style={{ minWidth: "8rem" }}
        />

        <InputTitle>Yellow boundary</InputTitle>
        <InputDimensioned
          compact
          value={turbineNoise.yellow}
          onChange={(n) => debouncedUpdateNoise({ yellow: n })}
          readOnly={!editorAccessProject}
          validate={(n) => 20 <= n && n <= 60}
          unit={"dB(A)"}
          style={{ minWidth: "8rem" }}
        />
      </Grid2>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          setAssumptionsOpen(!assumptionsOpen);
        }}
      >
        <SubtitleWithLine
          text={"Noise analysis assumptions"}
          expandButton={
            <ChevronIcon
              open={assumptionsOpen}
              chevronSize={"1.4rem"}
              style={{
                alignSelf: "center",
              }}
            />
          }
        />
      </div>
      {assumptionsOpen && (
        <Grid2 style={{ marginTop: "1rem" }}>
          <InputTitle>Temperature</InputTitle>
          <Dropdown
            small
            style={{ flex: 1 }}
            onChange={(n) => {
              const temp = parseFloat(n.target.value) as NoiseTemperatures;
              debouncedUpdateNoise({ temperature: temp });
            }}
            value={turbineNoise.temperature}
          >
            <option value={10}>10°</option>
            <option value={20}>20°</option>
            <option value={30}>30°</option>
          </Dropdown>
          <InputTitle>Coloring opacity</InputTitle>
          <Slider
            onChange={(n) => debouncedUpdateNoise({ opacity: n })}
            min={0}
            step={0.1}
            value={turbineNoise.opacity}
            max={1}
            label
            renderLabel={(n) => `${(n * 100).toFixed(0)}`}
          />
        </Grid2>
      )}
    </MenuFrame>
  );
};

export const ViewFromShoreAnalysis = ({ onClose }: { onClose(): void }) => {
  const parkId = useRecoilValue(parkIdSelector);
  const branchId = useRecoilValue(branchIdSelector);
  const park = useRecoilValue(
    getParkFeatureSelectorFamily({ parkId: parkId ?? "" }),
  );

  const projectFeatures = useRecoilValue(projectFeaturesSelector);
  const setViewFromShoreVisible = useSetRecoilState(viewFromShoreVisibleAtom);

  const allTurbineTypes = useRecoilValue(allSimpleTurbineTypesSelector);
  const turbines = useRecoilValue(getAllTurbinesSelector);

  const turbineTypeIdToHubHeight = useMemo(
    () => new Map(allTurbineTypes.map((t) => [t.id, t.hubHeight])),
    [allTurbineTypes],
  );
  const turbineTypeIdToDiameter = useMemo(
    () => new Map(allTurbineTypes.map((t) => [t.id, t.diameter])),
    [allTurbineTypes],
  );

  const turbineIdToHeight = useMemo(
    () =>
      new Map(
        turbines.map((f) => [
          f.id,
          turbineTypeIdToHubHeight.get(f.properties.turbineTypeId) ?? 0,
        ]),
      ),
    [turbines, turbineTypeIdToHubHeight],
  );
  const turbineIdToDiameter = useMemo(
    () =>
      new Map(
        turbines.map((f) => [
          f.id,
          turbineTypeIdToDiameter.get(f.properties.turbineTypeId) ?? 0,
        ]),
      ),
    [turbines, turbineTypeIdToDiameter],
  );

  useEffect(() => {
    setViewFromShoreVisible(true);
    return () => {
      setViewFromShoreVisible(false);
    };
  }, [setViewFromShoreVisible]);

  if (!park || !branchId) return null;

  return (
    <ViewToPark
      park={park}
      branchId={branchId}
      projectData={projectFeatures}
      turbineHeights={turbineIdToHeight}
      turbineDiameters={turbineIdToDiameter}
      onClose={onClose}
    />
  );
};

const ChosenContent = ({
  chosenMenu,
  canvasFeature,
  onClose,
}: {
  chosenMenu: TopRightModeType;
  canvasFeature: ProjectFeature;
  onClose(): void;
}) => {
  const parkId = useRecoilValue(parkIdSelector);
  const InnerChosenContent = useMemo(() => {
    switch (chosenMenu) {
      case TopRightMenuOptions.productionStatistics:
        return <ProductionV2 onClose={onClose} />;
      case TopRightMenuOptions.cableStatistics:
        if (!parkId) return null;
        return <ElectricalStatistics onClose={onClose} parkId={parkId} />;
      case TopRightMenuOptions.turbineStatistics:
        if (!isTurbine(canvasFeature)) return null;
        return <TurbineModal canvasFeature={canvasFeature} onClose={onClose} />;
      case TopRightMenuOptions.foundationStatistics:
        return <FoundationModal onClose={onClose} />;
      case TopRightMenuOptions.noise:
        return <Noise onClose={onClose} />;
      case TopRightMenuOptions.bathymetry:
        if (!isPolygonFeature(canvasFeature)) return null;
        return (
          <BathymetryAnalysis canvasFeature={canvasFeature} onClose={onClose} />
        );
      case TopRightMenuOptions.view:
        return <ViewFromShoreAnalysis onClose={onClose} />;
      case TopRightMenuOptions.viewshed:
        return <ViewshedAnalysis onClose={onClose} />;
      case TopRightMenuOptions.cost:
        if (!isPark(canvasFeature) || !parkId) return null;
        return <FinancialAnalysisV2 park={canvasFeature} onClose={onClose} />;
      default:
        return null;
    }
  }, [canvasFeature, chosenMenu, onClose, parkId]);
  if (!chosenMenu) return null;
  if (!InnerChosenContent) return null;
  return InnerChosenContent;
};

const ProjectFeatureInfoModalV2 = ({
  featureId,
  initialMenuOption,
}: {
  featureId: string;
  initialMenuOption?: TopRightModeType;
}) => {
  const viewerAccessProject = useRecoilValue(viewerAccessProjectSelector);
  const projectFeatures = useRecoilValue(projectFeaturesSelector);

  const [topRightModeActive, setTopRightModeActive] = useRecoilState(
    TopRightModeActiveAtom,
  );
  const lowerRightModeOpen = !!useRecoilValue(lowerRightMenuActiveModeAtom);
  const versionHistoryOpenState = useRecoilValue(versionHistoryOpenStateAtom);
  const trackEvent = useTrackEvent();
  useEffect(() => {
    if (initialMenuOption) {
      setTopRightModeActive(initialMenuOption);
    }
  }, [initialMenuOption, setTopRightModeActive]);

  const onSelectMenu = useCallback(
    (menu: TopRightModeType) => {
      setTopRightModeActive(menu);
    },
    [setTopRightModeActive],
  );

  const onClose = useCallback(() => {
    setTopRightModeActive(undefined);
  }, [setTopRightModeActive]);

  const projectFeature = useMemo(
    () => projectFeatures.find((cl) => cl.id === featureId),
    [projectFeatures, featureId],
  );

  const menuList: string[] = useMemo(() => {
    if (isPark(projectFeature)) {
      return [
        TopRightMenuOptions.productionStatistics,
        TopRightMenuOptions.cableStatistics,
        TopRightMenuOptions.foundationStatistics,
        TopRightMenuOptions.noise,
        TopRightMenuOptions.bathymetry,
        TopRightMenuOptions.view,
        TopRightMenuOptions.viewshed,
        TopRightMenuOptions.cost,
      ];
    }

    if (isTurbine(projectFeature)) {
      return [TopRightMenuOptions.turbineStatistics];
    }

    if (isSubArea(projectFeature)) {
      return [
        TopRightMenuOptions.productionStatistics,
        TopRightMenuOptions.cableStatistics,
        TopRightMenuOptions.foundationStatistics,
        TopRightMenuOptions.bathymetry,
        TopRightMenuOptions.viewshed,
      ];
    }

    if (isBathymetry(projectFeature)) {
      return [];
    }

    if (isGeotiff(projectFeature)) {
      return [];
    }

    if (isPolygonFeature(projectFeature)) {
      return [TopRightMenuOptions.bathymetry];
    }

    return [];
  }, [projectFeature]);

  useEffect(() => {
    if (!topRightModeActive) return;
    if (topRightModeActive === TopRightMenuOptions.cost) return;
    if (!menuList.includes(topRightModeActive))
      setTopRightModeActive(undefined);
  }, [projectFeature, topRightModeActive, setTopRightModeActive, menuList]);

  const { refs, floatingStyles, context } = useFloating({
    placement: "left",
    middleware: [offset({ mainAxis: 10, crossAxis: 20 })],
  });

  if (!projectFeature || versionHistoryOpenState) return null;

  return (
    <>
      <RightMenuPositionWrapper lowerRightModeOpen={lowerRightModeOpen}>
        <MapContextProvider>
          <Suspense fallback={null}>
            <ChosenContent
              onClose={onClose}
              chosenMenu={topRightModeActive}
              canvasFeature={projectFeature}
            />
          </Suspense>
        </MapContextProvider>
      </RightMenuPositionWrapper>

      {menuList.length > 0 && (
        <IconMenu vertical sideBar iconSize="2.4rem">
          <p style={typography.sub2}>Analysis</p>
          {menuList.includes(TopRightMenuOptions.turbineStatistics) &&
            viewerAccessProject && (
              <MenuOptionV2
                Icon={Production}
                chosenMenu={topRightModeActive}
                setChosenMenu={onSelectMenu}
                option={TopRightMenuOptions.turbineStatistics}
                title={"Turbine"}
                searchObject={{
                  title: "Open turbine analysis",
                  id: "search-turbine-stats",
                  tags: ["numbers", "stats", "turbine information"],
                  icon: <Production />,
                  onSelect: () => {
                    onSelectMenu(TopRightMenuOptions.turbineStatistics);
                  },
                }}
              />
            )}

          {menuList.includes(TopRightMenuOptions.productionStatistics) &&
            viewerAccessProject && (
              <>
                <HighlightStep
                  tourId="general-intro-tour"
                  stepId="seeStatistics"
                >
                  <MenuOptionV2
                    id={`button-${TopRightMenuOptions.productionStatistics}`}
                    innerRef={refs.setReference}
                    Icon={Production}
                    chosenMenu={topRightModeActive}
                    setChosenMenu={(m: TopRightModeType) => {
                      trackEvent("clickedProductionStatistics");
                      return onSelectMenu(m);
                    }}
                    option={TopRightMenuOptions.productionStatistics}
                    title={"Production"}
                    searchObject={{
                      title: "Open production analysis",
                      id: "search-production-stats",
                      tags: ["numbers", "stats"],
                      icon: <Production />,
                      onSelect: () => {
                        onSelectMenu(TopRightMenuOptions.productionStatistics);
                      },
                    }}
                  />
                </HighlightStep>
                <FloatingFocusManager context={context} modal={false}>
                  <TourStep
                    tourId="general-intro-tour"
                    stepId="seeStatistics"
                    innerRef={refs.setFloating}
                    style={floatingStyles}
                  />
                </FloatingFocusManager>
              </>
            )}
          {menuList.includes(TopRightMenuOptions.cost) && (
            <>
              <MenuOptionV2
                id={`button-${TopRightMenuOptions.cost}`}
                Icon={Financial}
                chosenMenu={topRightModeActive}
                setChosenMenu={onSelectMenu}
                option={TopRightMenuOptions.cost}
                title={"Financial"}
                searchObject={{
                  title: "Open financial analysis",
                  id: "search-financial-stats",
                  tags: ["numbers", "stats", "overview", "money"],
                  icon: <Financial />,
                  onSelect: () => {
                    onSelectMenu(TopRightMenuOptions.cost);
                  },
                }}
              />
            </>
          )}
          {menuList.includes(TopRightMenuOptions.foundationStatistics) &&
            viewerAccessProject && (
              <>
                <MenuOptionV2
                  Icon={Foundation}
                  chosenMenu={topRightModeActive}
                  setChosenMenu={onSelectMenu}
                  option={TopRightMenuOptions.foundationStatistics}
                  title={"Foundation"}
                  searchObject={{
                    title: "Open foundation analysis",
                    id: "search-foundation-stats",
                    tags: ["numbers", "stats", "overview"],
                    icon: <Foundation />,
                    onSelect: () => {
                      onSelectMenu(TopRightMenuOptions.foundationStatistics);
                    },
                  }}
                />
              </>
            )}
          {menuList.includes(TopRightMenuOptions.cableStatistics) &&
            viewerAccessProject && (
              <>
                <MenuOptionV2
                  id={`button-${TopRightMenuOptions.cableStatistics}`}
                  Icon={Electrical}
                  chosenMenu={topRightModeActive}
                  setChosenMenu={onSelectMenu}
                  option={TopRightMenuOptions.cableStatistics}
                  title={"Electrical"}
                  searchObject={{
                    title: "Open electrical analysis",
                    id: "search-electrical-stats",
                    tags: ["numbers", "stats", "overview", "power", "cable"],
                    icon: <Electrical />,
                    onSelect: () => {
                      onSelectMenu(TopRightMenuOptions.cableStatistics);
                    },
                  }}
                />
              </>
            )}

          <Divider />
          {menuList.includes(TopRightMenuOptions.bathymetry) &&
            viewerAccessProject && (
              <>
                <MenuOptionV2
                  Icon={Bathymetry}
                  chosenMenu={topRightModeActive}
                  setChosenMenu={onSelectMenu}
                  option={TopRightMenuOptions.bathymetry}
                  title={"Bathymetry"}
                  searchObject={{
                    title: "Open bathymetry analysis",
                    id: "search-bathymetry-analysis",
                    tags: ["ground", "slope analysis", "depth analysis"],
                    icon: <Bathymetry />,
                    onSelect: () => {
                      onSelectMenu(TopRightMenuOptions.bathymetry);
                    },
                  }}
                />
              </>
            )}
          {menuList.includes(TopRightMenuOptions.view) &&
            viewerAccessProject && (
              <>
                <MenuOptionV2
                  Icon={ViewFromShore}
                  chosenMenu={topRightModeActive}
                  setChosenMenu={onSelectMenu}
                  option={TopRightMenuOptions.view}
                  title={"View from shore"}
                  id="view_from_shore"
                  searchObject={{
                    title: "Open view from shore",
                    id: "search-view-from-shore",
                    tags: ["view analysis", "3D"],
                    icon: <ViewFromShore />,
                    onSelect: () => {
                      onSelectMenu(TopRightMenuOptions.view);
                    },
                  }}
                />
              </>
            )}
          {menuList.includes(TopRightMenuOptions.viewshed) &&
            viewerAccessProject && (
              <>
                <MenuOptionV2
                  Icon={Viewshed}
                  chosenMenu={topRightModeActive}
                  setChosenMenu={onSelectMenu}
                  option={TopRightMenuOptions.viewshed}
                  title={"Viewshed"}
                  searchObject={{
                    title: "Open viewshed analysis",
                    id: "search-viewshed-analysis",
                    tags: ["gdal", "visible turbines"],
                    icon: <Viewshed />,
                    onSelect: () => {
                      onSelectMenu(TopRightMenuOptions.viewshed);
                    },
                  }}
                />
              </>
            )}
          {menuList.includes(TopRightMenuOptions.noise) &&
            viewerAccessProject && (
              <>
                <MenuOptionV2
                  Icon={NoiseIcon}
                  chosenMenu={topRightModeActive}
                  setChosenMenu={onSelectMenu}
                  option={TopRightMenuOptions.noise}
                  title={"Noise"}
                  searchObject={{
                    title: "Open noise analysis",
                    id: "search-noise-analysis",
                    tags: ["surroundings"],
                    icon: <NoiseIcon />,
                    onSelect: () => {
                      onSelectMenu(TopRightMenuOptions.noise);
                    },
                  }}
                />
              </>
            )}
        </IconMenu>
      )}
    </>
  );
};

export default ProjectFeatureInfoModalV2;
