import { useEffect } from "react";
import { selector, useRecoilValue } from "recoil";
import { ValidationWarningTypes } from "./utils";
import { useValidationWarnings } from "./ValidationWarnings";
import { existingTurbineSourceId } from "components/Mapbox/constants";
import { existingTurbinesFeaturesSelector } from "state/projectLayers";

export const existingTurbinesWithoutPowerWarningSelector = selector<
  | {
      type: ValidationWarningTypes.ExistingTurbinesWithoutPower;
      featureIds: string[];
    }
  | undefined
>({
  key: "existingTurbinesWithoutPowerWarningSelector",
  get: ({ get }) => {
    const existingTurbines = get(existingTurbinesFeaturesSelector);
    const incompleteTurbines = existingTurbines.filter(
      (t) => !t.properties?.power,
    );

    return incompleteTurbines.length === 0
      ? undefined
      : {
          type: ValidationWarningTypes.ExistingTurbinesWithoutPower,
          featureIds: incompleteTurbines.map((t) => t.id),
        };
  },
});

export const ExistingTurbinesWithoutPower = ({
  map,
  source = existingTurbineSourceId,
}: {
  map: mapboxgl.Map;
  source?: string;
}) => {
  const existingTurbinesWithoutPowerWarning = useRecoilValue(
    existingTurbinesWithoutPowerWarningSelector,
  );

  const { warn, remove } = useValidationWarnings();

  useEffect(() => {
    if (!existingTurbinesWithoutPowerWarning) return;
    warn(existingTurbinesWithoutPowerWarning);
    if (map.getSource(source))
      for (const id of existingTurbinesWithoutPowerWarning.featureIds)
        map.setFeatureState({ source, id }, { error: true });

    return () => {
      remove(ValidationWarningTypes.ExistingTurbinesWithoutPower);
      if (map.getSource(source))
        for (const id of existingTurbinesWithoutPowerWarning.featureIds)
          map.removeFeatureState({ source, id }, "error");
    };
  }, [existingTurbinesWithoutPowerWarning, map, remove, source, warn]);

  return null;
};
