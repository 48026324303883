import { z } from "zod";

export const _LngLat = z.string().transform((str, ctx) => {
  const s = str.trim();
  let [lng, lat, ...rest] = s.split(/[ \t]/);
  if (lat === undefined) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Does not contain two coordinates",
    });
    return z.NEVER;
  }
  if (0 < rest.length) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Contains more than two coordinates",
    });
    return z.NEVER;
  }
  return {
    lng: z.coerce.number().parse(lng),
    lat: z.coerce.number().parse(lat),
  };
});

const _Degree = z.union([z.literal("°"), z.literal("º")]);
const _Minute = z.union([z.literal("'"), z.literal("′"), z.literal("’")]);
const _Second = z.union([
  z.literal("''"),
  z.literal('"'),
  z.literal("″"),
  z.literal("’’"),
]);

const _Direction = z.union([
  z.literal("N"),
  z.literal("S"),
  z.literal("E"),
  z.literal("W"),
]);

export type DMSDirection = z.infer<typeof _Direction>;

export const _DMS = z.string().transform((str, ctx) => {
  const groups = str.trim().split(/[ \t]/);

  let i: number | undefined = 0;

  const numRe = /[^0-9.,]/;

  i = numRe.exec(groups[0])?.index;
  if (i === undefined) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Coordinate does not contain degree, minute, or second marker",
    });
    return z.NEVER;
  }
  const degree = z.coerce
    .number()
    .parse(groups[0].slice(0, i).replace(",", "."));
  _Degree.parse(groups[0].slice(i));

  i = numRe.exec(groups[1])?.index;
  if (i === undefined) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Coordinate does not contain degree, minute, or second marker",
    });
    return z.NEVER;
  }
  const minute = z.coerce
    .number()
    .parse(groups[1].slice(0, i).replace(",", "."));
  _Minute.parse(groups[1].slice(i));

  i = numRe.exec(groups[2])?.index;
  if (i === undefined) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Coordinate does not contain degree, minute, or second marker",
    });
    return z.NEVER;
  }
  const second = z.coerce
    .number()
    .parse(groups[2].slice(0, i).replace(",", "."));
  _Second.parse(groups[2].slice(i));

  const direction = _Direction.parse(groups[3]);

  return {
    degree,
    minute,
    second,
    direction,
  };
});

/**
 * Parse a pair of DMS coordinates.
 * This should work even if the coordinate contains `,` and `,` is used as a separator.
 *
 * Returns the N/S coordinate first, and the E/W second. Error if both are N/S or E/W.
 */
export const _DMSPair = z.string().transform((str, ctx) => {
  // The only difficult thing here is to figure out where one coordinate stops
  // and the other starts. Look for the first NSWE (this is where the first
  // stops), and then look for the first digit after that (that's where the
  // second starts).
  const s = str.trim();
  let i = /[NWSE]/.exec(s)?.index;
  if (i === undefined) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Missing direction marker",
    });
    return z.NEVER;
  }

  i++;
  let first = s.slice(0, i);
  i += /\d/.exec(s.slice(i))?.index ?? 0;
  let second = s.slice(i);

  const fst = _DMS.parse(first);
  const snd = _DMS.parse(second);

  const fstNS = ["N", "S"].includes(fst.direction);
  const sndNS = ["N", "S"].includes(snd.direction);

  if (fstNS === sndNS) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Both coordinates are the same direction",
    });
    return z.NEVER;
  }

  if (fstNS) return [fst, snd];
  return [snd, fst];
});
