import { useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { constants } from "@vind-ai/mapbox-gl-draw";
import { editFeaturesAtom, mapControlsAtom } from "../state/map";
import { resetListIfNotAlreadyEmpty } from "../utils/resetList";
import { sendWarning } from "../utils/sentry";
import { currentExternalLayerSelection } from "state/externalLayerSelection";
import { currentSelectionArrayAtom } from "state/selection";

const useResetMapControls = () => {
  const mapControls = useRecoilValue(mapControlsAtom);
  const setEditFeatures = useSetRecoilState(editFeaturesAtom);

  const setCurrentSelectionArray = useSetRecoilState(currentSelectionArrayAtom);
  const setExternalLayerSelection = useSetRecoilState(
    currentExternalLayerSelection,
  );

  const resetCurrentSelectionArray = useCallback(() => {
    setCurrentSelectionArray(resetListIfNotAlreadyEmpty);
  }, [setCurrentSelectionArray]);

  const resetExternalLayerSelection = useCallback(() => {
    setExternalLayerSelection(resetListIfNotAlreadyEmpty);
  }, [setExternalLayerSelection]);

  return useCallback(() => {
    try {
      mapControls?.changeMode(constants.modes.SIMPLE_SELECT).deleteAll();
      setEditFeatures(resetListIfNotAlreadyEmpty);
      resetCurrentSelectionArray();
      resetExternalLayerSelection();
    } catch (e) {
      sendWarning("failed to reset map controls", { e });
    }
  }, [
    mapControls,
    resetCurrentSelectionArray,
    resetExternalLayerSelection,
    setEditFeatures,
  ]);
};

export default useResetMapControls;
