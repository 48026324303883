import { DataSource } from "../state/dataSource";
import { ProjectFeature } from "../types/feature";

const SQS_BATCH_LIMIT = 262144; // Ably batches messages when sending them to the SQS queue
const SQS_BATCH_MESSAGE_LIMIT = 10;
const SAFETY_MARGIN = 4000;
const ABLY_SIZE_LIMIT =
  SQS_BATCH_LIMIT / SQS_BATCH_MESSAGE_LIMIT - SAFETY_MARGIN;

export function prepareProjectUpdateMessages(
  dataSource: DataSource,
  changedFeatures: {
    add?: ProjectFeature[];
    remove?: string[];
    update?: ProjectFeature[];
  },
) {
  const dataString = JSON.stringify(changedFeatures);
  const recordByteLength = Buffer.byteLength(dataString, "utf8");

  if (recordByteLength < ABLY_SIZE_LIMIT) {
    return [{ dataSource, ...changedFeatures }];
  }

  const messages: {
    add: ProjectFeature[];
    remove: string[];
    update: ProjectFeature[];
  }[] = [];

  let accumulatedSize = 0;
  let tempMessage: {
    add: ProjectFeature[];
    remove: string[];
    update: ProjectFeature[];
  } = { add: [], remove: [], update: [] };

  const getElementSize = (element: ProjectFeature | string) =>
    new TextEncoder().encode(JSON.stringify(element)).length;

  for (const element of changedFeatures.remove ?? []) {
    const size = getElementSize(element);
    if (accumulatedSize + size > ABLY_SIZE_LIMIT) {
      messages.push({ ...tempMessage });
      tempMessage = { add: [], remove: [], update: [] };
      accumulatedSize = 0;
    }
    tempMessage.remove.push(element);
    accumulatedSize += size;
  }

  for (const element of changedFeatures.add ?? []) {
    const size = getElementSize(element);
    if (accumulatedSize + size > ABLY_SIZE_LIMIT) {
      messages.push({ ...tempMessage });
      tempMessage = { add: [], remove: [], update: [] };
      accumulatedSize = 0;
    }
    tempMessage.add.push(element);
    accumulatedSize += size;
  }

  for (const element of changedFeatures.update ?? []) {
    const size = getElementSize(element);
    if (accumulatedSize + size > ABLY_SIZE_LIMIT) {
      messages.push({ ...tempMessage });
      tempMessage = { add: [], remove: [], update: [] };
      accumulatedSize = 0;
    }
    tempMessage.update.push(element);
    accumulatedSize += size;
  }

  if (
    tempMessage.add.length +
      tempMessage.remove.length +
      tempMessage.update.length >
    0
  ) {
    messages.push({ ...tempMessage });
  }

  return messages.map((m) => ({ dataSource, ...m }));
}
