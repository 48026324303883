import React, { useMemo, useEffect, useRef, Suspense } from "react";
import * as turf from "@turf/turf";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { foundations3dLayerId } from "../../constants/projectMapView";
import {
  allFoundationTypesSelector,
  foundationScale,
  isFloatingFoundationSelector,
} from "../../state/foundations";
import {
  getAnchorsSelectorFamily,
  getMooringLinesSelector,
  getTurbinesSelectorFamily,
} from "../../state/layout";
import { TurbineFeature } from "../../types/feature";
import {
  MooringCoords,
  mooringSegmentVisualization,
} from "../../state/mooring";
import { currentMooringLineTypesState } from "../../state/mooringLineType";
import { getParkFeatureSelectorFamily } from "../../state/park";
import {
  allSimpleTurbineTypesSelector,
  previewTurbinesState,
} from "../../state/turbines";
import { parkIdSelector, projectIdSelector } from "../../state/pathParams";
import groupBy from "../../utils/groupBy";
import {
  isDefined,
  isMooringLineMultiple,
  isNumber,
} from "../../utils/predicates";
import { dedup, sum } from "../../utils/utils";
import { previewMooringAndFoundationState } from "../GenerateFoundationsAndAnchors/state";
import { addLayer, getBeforeLayer } from "../Mapbox/utils";
import { isFloater } from "../RightSide/InfoModal/FoundationModal/utils";
import { ThreeDFoundations } from "./3Dfoundations";
import { ThreeDMooring } from "./3Dmooring";
import { ThreeDTurbines } from "./3Dturbines";
import { safeRemoveLayer } from "../../utils/map";
import { ThreeDCables } from "./3Dcables";
import { cableVisualization, getCablesSelectorFamily } from "../../state/cable";
import {
  threedCableLayerId,
  threedMooringLayerId,
  threedTurbineLayerId,
} from "components/Mapbox/constants";
import { useBathymetryRaster } from "hooks/bathymetry";

export const Feature3DRender = ({
  features,
  map,
}: {
  features: TurbineFeature[];
  map: mapboxgl.Map;
}) => {
  const allTurbines = useRecoilValue(allSimpleTurbineTypesSelector);

  const turbineTypes = useMemo(
    () => new Map(allTurbines.map((t) => [t.id, t])),
    [allTurbines],
  );

  const threeTurbinesLayer = useMemo(() => {
    return new ThreeDTurbines(threedTurbineLayerId);
  }, []);

  useEffect(() => {
    if (!turbineTypes) return;
    const lonlats = features.map(
      (f) => f.geometry.coordinates as [number, number],
    );
    const diameters = features.map(
      (t) => turbineTypes.get(t.properties.turbineTypeId)?.diameter ?? 0,
    );
    const heights = features.map(
      (t) => turbineTypes.get(t.properties.turbineTypeId)?.hubHeight ?? 0,
    );
    threeTurbinesLayer.setPositions(lonlats, heights, diameters);
  }, [features, threeTurbinesLayer, turbineTypes]);

  useEffect(() => {
    if (!map || !threeTurbinesLayer) return;

    safeRemoveLayer(map, threedTurbineLayerId);
    map.addLayer(
      threeTurbinesLayer,
      getBeforeLayer(map, threeTurbinesLayer.id),
    );

    return () => {
      safeRemoveLayer(map, threedTurbineLayerId);
    };
  }, [map, threeTurbinesLayer]);

  return (
    <Suspense fallback={null}>
      <Foundation3DRender map={map} />
      <Mooring3DRender features={features} map={map} />
      <Cables3DRender features={features} map={map} />
    </Suspense>
  );
};
export const Foundation3DRender = ({ map }: { map: mapboxgl.Map }) => {
  const projectId = useRecoilValue(projectIdSelector) ?? "";
  const parkId = useRecoilValue(parkIdSelector) ?? "";
  const park = useRecoilValue(getParkFeatureSelectorFamily({ parkId }));
  const allTurbinesTypes = useRecoilValue(allSimpleTurbineTypesSelector);
  const parkTurbines = useRecoilValue(getTurbinesSelectorFamily({ parkId }));
  const previewParkTurbines = useRecoilValue(previewTurbinesState);

  const turbineTypes = useMemo(
    () => new Map(allTurbinesTypes.map((t) => [t.id, t])),
    [allTurbinesTypes],
  );

  const allFoundationTypes = useRecoilValue(allFoundationTypesSelector);

  const raster = useBathymetryRaster({
    projectId,
    featureId: parkId,
  }).valueMaybe();

  const foundationIds = useMemo(() => {
    return dedup(
      parkTurbines.map((f) => f.properties.foundationId).filter(isDefined),
    );
  }, [parkTurbines]);

  const previewMooringAndFoundations = useRecoilValue(
    previewMooringAndFoundationState,
  );

  const previewFoundations = useMemo(
    () =>
      previewMooringAndFoundations
        ? previewMooringAndFoundations.preview.foundations
        : [],
    [previewMooringAndFoundations],
  );

  const turbinesByFoundationId = groupBy(
    [...parkTurbines, ...(previewParkTurbines?.preview ?? [])].filter(
      (f) =>
        isDefined(f.properties.foundationId) ||
        previewFoundations.find((p) => p.turbineId === f.id),
    ),
    (f) =>
      previewFoundations.find((p) => p.turbineId === f.id)?.foundationId ??
      f.properties.foundationId!,
  );

  const threeFoundations = useMemo(
    () =>
      Object.entries(turbinesByFoundationId).map(([foundationId, features]) => {
        if (!raster) return undefined;
        const foundation = allFoundationTypes.find(
          (f) => f.id === foundationId,
        );

        const lonlats = features.map(
          (f) => f.geometry.coordinates as [number, number],
        );

        const turbines = features
          .map((t) => turbineTypes.get(t.properties.turbineTypeId))
          .filter(isDefined);

        const numFeatures = features.length;

        const waterDepthSum = -sum(
          features.filter((f) =>
            raster.contains(
              f.geometry.coordinates[0],
              f.geometry.coordinates[1],
            ),
          ),
          (f) =>
            raster.latLngToValue(
              f.geometry.coordinates[0],
              f.geometry.coordinates[1],
              0,
            ),
        );

        const waterDepth = numFeatures !== 0 ? waterDepthSum / numFeatures : -1;

        return new ThreeDFoundations(
          lonlats,
          turbines,
          waterDepth,
          foundation,
          parkId ?? "",
        );
      }),
    [allFoundationTypes, raster, parkId, turbineTypes, turbinesByFoundationId],
  ).filter(isDefined);

  const foundations3dLayerParkId = foundations3dLayerId + (park?.id ?? "");

  useEffect(() => {
    if (!threeFoundations) return;

    foundationIds.forEach((id) => {
      const layerId = foundations3dLayerParkId + id;
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }
    });

    threeFoundations.forEach((layer) => {
      map.addLayer(layer, getBeforeLayer(map, foundations3dLayerParkId));
    });

    return () => {
      threeFoundations.forEach((layer) => {
        if (map.getLayer(layer.id)) {
          map.removeLayer(layer.id);
        }
      });
    };
  }, [map, threeFoundations, foundationIds, park, foundations3dLayerParkId]);

  return null;
};

const Mooring3DRender = ({
  features,
  map,
}: {
  features: TurbineFeature[];
  map: mapboxgl.Map;
}) => {
  const mooringLineCache = useRef(new Map());
  const threeMooringRef = useRef<ThreeDMooring | null>(null);

  const allTurbines = useRecoilValue(allSimpleTurbineTypesSelector);
  const allFoundationTypes = useRecoilValue(allFoundationTypesSelector);
  const previewMooringAndFoundations = useRecoilValue(
    previewMooringAndFoundationState,
  );
  const previewFoundations = useMemo(
    () =>
      previewMooringAndFoundations
        ? previewMooringAndFoundations.preview.foundations
        : [],
    [previewMooringAndFoundations],
  );

  const parkId = useRecoilValue(parkIdSelector) ?? "";

  const parkAnchors = useRecoilValue(getAnchorsSelectorFamily(parkId));
  const parkMooringLines = useRecoilValue(getMooringLinesSelector(parkId));

  const anchors = useMemo(
    () =>
      previewMooringAndFoundations
        ? [
            ...previewMooringAndFoundations.preview.anchors,
            ...previewMooringAndFoundations.existing.anchors,
          ]
        : parkAnchors,
    [previewMooringAndFoundations, parkAnchors],
  );

  const turbineMap = useMemo(
    () => new Map(features.map((a) => [a.id, a])),
    [features],
  );
  const anchorMap = useMemo(
    () => new Map(anchors.map((a) => [a.id, a])),
    [anchors],
  );

  const mooringLines = useMemo(
    () =>
      previewMooringAndFoundations
        ? [
            ...previewMooringAndFoundations.preview.mooringLines,
            ...previewMooringAndFoundations.existing.mooringLines,
          ]
        : parkMooringLines,
    [previewMooringAndFoundations, parkMooringLines],
  );

  const lineTypes = useRecoilValue(currentMooringLineTypesState);

  const projectId = useRecoilValue(projectIdSelector) ?? "";
  const raster = useBathymetryRaster({
    projectId,
    featureId: parkId,
  }).valueMaybe();

  useEffect(() => {
    if (!threeMooringRef.current) {
      threeMooringRef.current = new ThreeDMooring([], threedMooringLayerId);
      addLayer(map, threeMooringRef.current);
    }
    return () => {
      if (threeMooringRef.current) safeRemoveLayer(map, threedMooringLayerId);
    };
  }, [map]);

  useEffect(() => {
    if (!anchors || !mooringLines || !raster) {
      return;
    }

    const availableLines = mooringLines.filter((line) => {
      const t = turbineMap.get(line.properties.target);
      if (!t) return false;
      const a = anchorMap.get(line.properties.anchor);
      if (!a) return false;

      if (isMooringLineMultiple(line))
        return line.properties.lineTypes.find((id) =>
          lineTypes.some((lt) => lt.id === id),
        );
      return lineTypes.find((lt) => lt.id === line.properties.lineType);
    });
    if (availableLines.length === 0) return;

    const lonLatAndmooringCoords: [
      [number, number],
      MooringCoords,
      MooringCoords,
      MooringCoords,
    ][] = availableLines.map((line) => {
      const lineId = line.id;
      if (mooringLineCache.current.has(lineId)) {
        return mooringLineCache.current.get(lineId);
      }

      const turbine = turbineMap.get(line.properties.target)!;
      const currentTurbineType = allTurbines.find(
        (t) => t.id === turbine.properties.turbineTypeId,
      );
      const anchor = anchorMap.get(line.properties.anchor)!;

      const lonlat = turbine.geometry.coordinates as [number, number];

      const previewFoundationId = previewFoundations.find(
        (t) => t.turbineId === turbine.id,
      )?.foundationId;

      const foundation = previewFoundationId
        ? allFoundationTypes.find((f) => f.id === previewFoundationId)
        : allFoundationTypes.find(
            (f) => f.id === turbine.properties.foundationId,
          );

      const scale = isFloater(foundation)
        ? foundationScale({
            foundation: foundation,
            turbine: currentTurbineType,
          })
        : 1;
      const fairRadius =
        (scale ?? 1) *
        (isFloater(foundation) && foundation.fairRadius
          ? foundation.fairRadius
          : 0);
      const fairZ =
        (scale ?? 1) *
        (isFloater(foundation) && foundation.fairZ ? foundation.fairZ : 0);

      const bearing = turf.bearing(
        turbine.geometry.coordinates,
        anchor.geometry.coordinates,
      );

      const anchorRadius = turf.distance(
        turbine.geometry.coordinates,
        anchor.geometry.coordinates,
        { units: "meters" },
      );

      const latLngToValueVal = raster.latLngToValue(
        turbine.geometry.coordinates[0],
        turbine.geometry.coordinates[1],
      );
      const waterDepth = isNumber(latLngToValueVal) ? -latLngToValueVal : 0;

      let lineLengths: number[] = [];
      let EAs: number[] = [];
      let wetWeights: number[] = [];
      let attachments: number[] = [];
      if (isMooringLineMultiple(line)) {
        for (let i = 0; i < line.properties["lineLengths"].length; i++) {
          lineLengths.push(1000 * line.properties["lineLengths"][i]);
          const lineType = lineTypes.find(
            (lt) => lt.id === line.properties["lineTypes"][i],
          );
          EAs.push(lineType?.EA ?? 1e2);
          wetWeights.push(lineType?.wetWeight ?? 1);
          attachments.push(line.properties["attachments"][i]);
        }
      } else if (line.properties.lineLength) {
        lineLengths.push(1000 * line.properties["lineLength"]);
        const lineType = lineTypes.find(
          (lt) => lt.id === line.properties["lineType"],
        );
        EAs.push(lineType?.EA ?? 1e2);
        wetWeights.push(lineType?.wetWeight ?? 1);
        attachments.push(0);
      }

      const { mooringCoords, clumpWeightCoords, buoyCoords } =
        mooringSegmentVisualization({
          lineLengths: lineLengths,
          anchorRadius: anchorRadius,
          bearing: bearing + 90,
          waterDepth: waterDepth,
          fairRadius: fairRadius,
          fairZ: fairZ,
          EAs: EAs,
          wetWeights: wetWeights,
          attachments: attachments,
        });

      const coords = [lonlat, mooringCoords, clumpWeightCoords, buoyCoords];
      mooringLineCache.current.set(lineId, coords);

      return coords;
    });

    if (threeMooringRef.current) {
      threeMooringRef.current.updateData(lonLatAndmooringCoords);
    }
  }, [
    allFoundationTypes,
    allTurbines,
    anchorMap,
    anchors,
    raster,
    lineTypes,
    mooringLines,
    previewFoundations,
    turbineMap,
  ]);

  return null;
};

const Cables3DRender = ({
  features,
  map,
}: {
  features: TurbineFeature[];
  map: mapboxgl.Map;
}) => {
  const cableCache = useRef(new Map());
  const threeCableRef = useRef<ThreeDCables | null>(null);

  const parkId = useRecoilValue(parkIdSelector) ?? "";
  const cables = useRecoilValue(getCablesSelectorFamily({ parkId }));
  const turbines = useRecoilValue(getTurbinesSelectorFamily({ parkId }));
  const projectId = useRecoilValue(projectIdSelector) ?? "";
  const raster = useBathymetryRaster({
    projectId,
    featureId: parkId,
  }).valueMaybe();

  const getIsFloatingFoundation = useRecoilCallback(
    ({ snapshot }) =>
      (foundationId: string) => {
        return snapshot
          .getLoadable(
            isFloatingFoundationSelector({ foundationTypeId: foundationId }),
          )
          .valueMaybe();
      },
    [],
  );

  useEffect(() => {
    if (!threeCableRef.current) {
      threeCableRef.current = new ThreeDCables([], threedCableLayerId);

      addLayer(map, threeCableRef.current);
    }
    return () => {
      if (threeCableRef.current) {
        if (map.getLayer(threeCableRef.current.id)) {
          map.removeLayer(threeCableRef.current.id);
        }
      }
    };
  }, [map]);

  useEffect(() => {
    if (!cables || !raster) {
      return undefined;
    }

    const lonLatAndCableCoords: [[number, number], MooringCoords][] = cables
      .map((cable) => {
        const cableId = cable.id;
        const pos = cable.geometry.coordinates;
        const lonlat = pos[0] as [number, number];

        const [startLon, startLat] = pos[0];
        const [endLon, endLat] = pos[pos.length - 1];
        if (
          !(
            raster.contains(startLon, startLat) &&
            raster.contains(endLon, endLat)
          )
        )
          return undefined;

        const distance: number[] = [];
        const bearing: number[] = [];
        const waterDepth: number[] = [];
        for (let i = 0; i < pos.length - 1; i++) {
          distance.push(
            turf.distance(pos[i], pos[i + 1], {
              units: "meters",
            }),
          );
          bearing.push(turf.rhumbBearing(pos[i], pos[i + 1]));
          const latLngToValueVal = raster.latLngToValue(pos[i][0], pos[i][1]);
          const thisWaterDepth = isNumber(latLngToValueVal)
            ? -latLngToValueVal
            : 0;
          waterDepth.push(thisWaterDepth);
        }

        const lastLatLngValueVal = raster.latLngToValue(
          pos[pos.length - 1][0],
          pos[pos.length - 1][1],
        );
        const lastWaterDepth = isNumber(lastLatLngValueVal)
          ? -lastLatLngValueVal
          : 0;
        waterDepth.push(lastWaterDepth);

        const floaterFrom = turbines.some(
          (t) =>
            t.id === cable.properties.fromId &&
            t.properties.foundationId &&
            getIsFloatingFoundation(t.properties.foundationId),
        );
        const floaterTo = turbines.some(
          (t) =>
            t.id === cable.properties.toId &&
            t.properties.foundationId &&
            getIsFloatingFoundation(t.properties.foundationId),
        );

        const cableCoords = cableVisualization({
          bearing,
          waterDepth,
          distance,
          floaterFrom,
          floaterTo,
        });

        const coords = [lonlat, cableCoords];
        cableCache.current.set(cableId, coords);

        return coords as [[number, number], MooringCoords];
      })
      .filter(isDefined);

    if (threeCableRef.current) {
      threeCableRef.current.updateData(lonLatAndCableCoords);
    }
  }, [raster, cables, features, getIsFloatingFoundation, turbines]);

  return null;
};
