import React, {
  ErrorInfo,
  ReactNode,
  Suspense,
  useEffect,
  useMemo,
  useState,
} from "react";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { debounce } from "debounce";
import { getTurbinesSelectorFamily } from "../../../../state/layout";
import {
  diagonalDistanceWithinAllowedRange,
  getViewShedMultipleResultSelectorFamily,
} from "../../../../state/viewshedAnalysis";
import { Position } from "geojson";
import { mapRefAtom } from "../../../../state/map";
import { allSimpleTurbineTypesSelector } from "../../../../state/turbines";
import { ColorBucket, GeotiffArray } from "../../../MapFeatures/Geotiff";
import { ColoredGrid, Label } from "../../../General/Form";
import { RangeSlider, Slider } from "../../../General/Slider";
import { MenuFrame } from "../../../MenuPopup/CloseableMenuPopup";
import { SkeletonText } from "../../../Loading/Skeleton";
import { Column } from "../../../General/Layout";
import { ARTICLE_VIEWSHED, HelpLink } from "../../../HelpTooltip/HelpTooltip";
import DownloadIcon from "@icons/24/Download.svg?react";
import Tooltip from "../../../General/Tooltip";
import { scream } from "../../../../utils/sentry";
import { parkIdSelector } from "../../../../state/pathParams";
import { getTurbinesWithinDivisionSelectorFamily } from "../../../../state/division";
import { currentSelectedProjectFeatures } from "../../../../state/selection";
import { isSubArea } from "../../../../utils/predicates";
import { viewshedLayerId } from "components/Mapbox/constants";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import {
  InputTitle,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import Button from "components/General/Button";
import { RangeWithDimInput } from "components/General/RangeWithDimInput";
import { roundToDecimal } from "utils/utils";
import { useShowScrollShadow } from "hooks/useShowScrollShadow";
import { rgbToHex } from "utils/image";

type Props = {
  onClose?(): void;
};

type ErrorProps = {
  children: ReactNode;
};

class ViewShedErrorBoundary extends React.Component<
  ErrorProps,
  { hasError: boolean; error?: Error }
> {
  constructor(props: ErrorProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    scream(error, { errorInfo, message: "ViewShedErrorBoundary caught" });
    this.setState({ error });
  }

  render() {
    if (this.state.hasError) {
      return <h5>Something went wrong when running viewshed analysis...</h5>;
    }

    return this.props.children;
  }
}

export const viewshedBucketsAtom = atom<
  { color: string; from: number; to: number }[]
>({
  key: "viewshedBuckets",
  default: [],
});

const ViewShedAnalysisResult = ({
  coords,
  observerHeight,
  maxTurbines,
}: {
  coords: Position[];
  observerHeight: number;
  maxTurbines: number;
}) => {
  const {
    geotiff: viewshedResult,
    viewshedResultUrl,
    originalTerrainUrl,
    pixelSize,
  } = useRecoilValue(
    getViewShedMultipleResultSelectorFamily({ coords, observerHeight }),
  );
  const map = useRecoilValue(mapRefAtom);
  const [colorGradient, setColorGradient] = useState<ColorBucket[]>([
    { color: [255, 255, 190], threshold: Math.floor(maxTurbines / 3) },
    {
      color: [255, 211, 128],
      threshold: Math.floor((maxTurbines * 2) / 3),
    },
    { color: [243, 145, 54], threshold: Math.floor(maxTurbines) },
  ]);

  const setViewshedBuckets = useSetRecoilState(viewshedBucketsAtom);

  useEffect(() => {
    const newBuckets = [
      {
        color: rgbToHex(...colorGradient[2].color),
        from: colorGradient[1].threshold,
        to: maxTurbines,
      },
      {
        color: rgbToHex(...colorGradient[1].color),
        from: colorGradient[0].threshold,
        to: colorGradient[1].threshold - 1,
      },
      {
        color: rgbToHex(...colorGradient[0].color),
        from: 0,
        to: colorGradient[0].threshold - 1,
      },
    ];

    setViewshedBuckets(newBuckets);
  });

  const [opacity, setOpacity] = useState(1.0);

  if (!map) return null;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "0.8rem",
      }}
    >
      <SubtitleWithLine
        style={{
          paddingTop: "0.8rem",
        }}
        text="Terrain data"
      />
      <ColoredGrid>
        <p>Number of turbines</p>
        <p>{coords.length}</p>

        <p>Method</p>
        <p>
          <a
            href={"https://gdal.org/programs/gdal_viewshed.html"}
            target={"_blank"}
            rel="noopener noreferrer"
          >
            Gdal viewshed
          </a>
        </p>
        <p>Terrain source</p>

        <p>
          <a
            href={
              "https://docs.mapbox.com/data/tilesets/reference/mapbox-terrain-dem-v1/"
            }
            target={"_blank"}
            rel="noopener noreferrer"
          >
            Mapbox
          </a>
        </p>

        <p>Source resolution</p>
        <p>{`${pixelSize[0]}x${pixelSize[1]}m`}</p>
      </ColoredGrid>
      <Tooltip text={"Download terrain as geotiff"}>
        <Button
          size="small"
          text="Terrain"
          icon={<DownloadIcon />}
          buttonType="secondary"
          onClick={() => {
            window.open(originalTerrainUrl, "_blank", "noopener");
          }}
        />
      </Tooltip>
      <SubtitleWithLine
        style={{
          paddingTop: "0.8rem",
        }}
        text="Visalisations"
      />
      <Label>
        <InputTitle>Visible turbines</InputTitle>

        <RangeSlider
          min={0}
          max={colorGradient[2].threshold}
          values={[colorGradient[0].threshold, colorGradient[1].threshold]}
          inside
          outside
          labels
          extremesLabels
          step={1}
          renderLabel={(v) => `${roundToDecimal(v, 1)}`}
          onChange={function (f: [number, number]): void {
            const newColorGradients = [...colorGradient];
            newColorGradients[0].threshold = f[0];
            newColorGradients[1].threshold = f[1];
            setColorGradient(newColorGradients);
          }}
          style={{ flex: 1 }}
          inputColors={colorGradient.map((c) => `rgb(${c.color.join(",")})`)}
        />
      </Label>

      <Label>
        <InputTitle>Coloring opacity</InputTitle>
        <Slider
          min={0}
          max={1}
          step={0.01}
          value={opacity}
          onChange={(newOpacity) => {
            setOpacity(newOpacity);
          }}
          renderLabel={(opacity) => `${(opacity * 100).toFixed(0)}`}
          label={true}
        />
      </Label>
      <GeotiffArray
        opacity={opacity}
        map={map}
        layerId={viewshedLayerId}
        geotiff={viewshedResult}
        colorBuckets={colorGradient}
      />
      <Tooltip text={"Download viewshed as geotiff"}>
        <Button
          size="small"
          text="Viewshed"
          icon={<DownloadIcon />}
          buttonType="secondary"
          onClick={() => {
            window.open(viewshedResultUrl, "_blank", "noopener");
          }}
        />
      </Tooltip>
    </div>
  );
};

const ViewShedAnalysisInner = ({
  coords,
  maxTurbines,
}: {
  coords: Position[];
  maxTurbines: number;
}) => {
  const map = useRecoilValue(mapRefAtom);
  const [height, setHeight] = useState(1.75);
  const [analysisHeight, setAnalysisHeight] = useState(height);

  const debouncedSetAnalysisHeight = useMemo(
    () =>
      debounce((_height: number) => {
        setAnalysisHeight(_height);
      }, 500),
    [setAnalysisHeight],
  );

  if (!map) return null;

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "0.8rem" }}>
      <SubtitleWithLine text="View height" />
      <InputTitle>Height of observer</InputTitle>
      <RangeWithDimInput
        min={1}
        max={2}
        inputStep={0.25}
        value={height}
        onChange={(newHeight) => {
          setHeight(newHeight);
          debouncedSetAnalysisHeight(newHeight);
        }}
        unit={"m"}
      />
      <Suspense
        fallback={
          <Column>
            <SkeletonText text={"Loading terrain..."} />
          </Column>
        }
      >
        <ViewShedAnalysisResult
          maxTurbines={maxTurbines}
          coords={coords}
          observerHeight={analysisHeight}
        />
      </Suspense>
    </div>
  );
};

const ViewshedAnalysisOuter = () => {
  const parkId = useRecoilValue(parkIdSelector) ?? "";
  const selectedRawFeatures = useRecoilValue(currentSelectedProjectFeatures);
  const selectedRawSubAreas = useMemo(
    () => selectedRawFeatures.filter(isSubArea),
    [selectedRawFeatures],
  );
  const selectedSubAreaIds = useMemo(
    () => selectedRawSubAreas.map((f) => f.id).map(String),
    [selectedRawSubAreas],
  );
  const turbinesInDivision = useRecoilValue(
    getTurbinesWithinDivisionSelectorFamily({
      parkId,
      selectedSubAreaIds,
    }),
  );
  const turbines = useRecoilValue(getTurbinesSelectorFamily({ parkId }));
  const turbinesToUse = turbinesInDivision ?? turbines;
  const allTurbines = useRecoilValue(allSimpleTurbineTypesSelector);
  const turbineTypes = useMemo(
    () => Object.fromEntries(allTurbines.map((t) => [t.id, t])),
    [allTurbines],
  );

  const turbinesMissingTypes = useMemo(
    () =>
      turbinesToUse.find((t) => !(t.properties.turbineTypeId in turbineTypes)),
    [turbinesToUse, turbineTypes],
  );

  const coords = useMemo(
    () =>
      turbinesToUse
        .filter((t) => t.properties.turbineTypeId in turbineTypes)
        .map((t) => [
          ...t.geometry.coordinates,
          turbineTypes[t.properties.turbineTypeId].hubHeight,
        ]),
    [turbinesToUse, turbineTypes],
  );

  const diagonalDistanceTooFar = !diagonalDistanceWithinAllowedRange(coords);

  return (
    <div>
      {turbinesMissingTypes && (
        <SimpleAlert
          text={
            "Some turbines are using a turbine type that does no longer exist"
          }
          type={"error"}
        />
      )}
      {coords.length === 0 ? (
        <SimpleAlert
          text={"Turbines needed for viewshed analysis"}
          type={"error"}
        />
      ) : diagonalDistanceTooFar ? (
        <SimpleAlert
          text={"Distances between turbines are too great"}
          type={"error"}
        />
      ) : (
        <ViewShedErrorBoundary>
          <ViewShedAnalysisInner
            coords={coords}
            maxTurbines={turbines.length}
          />
        </ViewShedErrorBoundary>
      )}
    </div>
  );
};

const ViewshedAnalysis = ({ onClose }: Props) => {
  const { scrollBodyRef } = useShowScrollShadow(true);

  return (
    <MenuFrame
      title={"Viewshed"}
      headerId={"viewShed"}
      onExit={onClose}
      icon={<HelpLink article={ARTICLE_VIEWSHED} />}
    >
      <Suspense
        fallback={
          <Column>
            <SkeletonText />
          </Column>
        }
      >
        <div
          ref={scrollBodyRef}
          style={{
            maxHeight: "calc(100vh - 26rem)",
            overflowY: "auto",
          }}
        >
          <ViewshedAnalysisOuter />
        </div>
      </Suspense>
    </MenuFrame>
  );
};

export default ViewshedAnalysis;
