/// <reference types="vite-plugin-svgr/client" />
import Button from "components/General/Button";
import { InitialsImageRound } from "components/UserImage/UserImageRound";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useTypedPath } from "state/pathParams";
import { organisationFoundationResourceState } from "../state";
import {
  ContentColumn,
  ContentHeader,
  ContentHeaderButtonsContainer,
  ContentHeaderRow,
  MainRow,
  ResourceColumn,
  ResourceItem,
  ResourceName,
  TabContainer,
} from "../style";
import AddIcon from "@icons/24/Add.svg?react";
import Trashcan from "@icons/24/Bin.svg?react";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";
import { useEffect, useMemo, useRef, useState } from "react";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { Column } from "components/General/Layout";
import { FoundationType } from "types/foundations";
import FoundationSettings from "components/ConfigurationModal/FoundationSettings";
import { showNewFoundationWizardAtom } from "state/foundations";
import useOrgFoundationCrud from "./useOrgFoundationCrud";
import { CreateFoundationMenuOrganisation } from "components/SettingsV2/FeatureSettings/FoundationSubMenu";
import { DotMenu } from "components/General/MenuButton";
import { MenuItem } from "components/General/Menu";
import OpenMoreInfo from "components/General/MoreInfoButton";
import { SkeletonBlock } from "components/Loading/Skeleton";

export default function FoundationTab() {
  const { organisationId } = useTypedPath("organisationId");
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [deleteInProgress, setDeleteInProgress] = useState<
    string | undefined
  >();
  const foundations = useRecoilValue(
    organisationFoundationResourceState({ organisationId }),
  );
  const [selectedFoundationId, setSelectedFoundationId] = useState<
    string | undefined
  >(undefined);

  const setContent = useSetRecoilState(
    organisationRightSideModal(organisationId),
  );

  const isLoading = useMemo(
    () => saveInProgress || !!deleteInProgress,
    [saveInProgress, deleteInProgress],
  );

  useEffect(() => {
    if (!selectedFoundationId && foundations.length > 0 && !saveInProgress) {
      setContent({ type: "resource", id: foundations[0].id });
      setSelectedFoundationId(foundations[0].id);
    }
  }, [selectedFoundationId, setContent, foundations, saveInProgress]);

  const selectedFoundation = useMemo(
    () => foundations.find((f) => f.id === selectedFoundationId),
    [foundations, selectedFoundationId],
  );

  const newItemRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const newItem = newItemRef.current;
    if (saveInProgress && newItem) {
      newItem.scrollIntoView();
    }
  }, [saveInProgress]);

  const [showNewFoundationWizard, setShowNewFoundationWizard] = useRecoilState(
    showNewFoundationWizardAtom,
  );

  const { remove, duplicate } = useOrgFoundationCrud();

  return (
    <TabContainer style={{ position: "relative" }}>
      <MainRow>
        <ResourceColumn>
          <div style={{ position: "relative" }}>
            <Button
              buttonType="secondary"
              icon={<AddIcon />}
              text={"New foundation"}
              disabled={isLoading}
              onClick={() => setShowNewFoundationWizard(true)}
            />
            {showNewFoundationWizard && (
              <CreateFoundationMenuOrganisation
                setSaveInProgess={setSaveInProgress}
                onCreate={(foundation) => {
                  setSelectedFoundationId(foundation.id);
                }}
              />
            )}
          </div>
          <Column style={{ gap: "0.8rem", overflowY: "auto" }}>
            {foundations.map((f) => {
              return (
                <ResourceItem
                  key={f.id}
                  onClick={() => {
                    setSelectedFoundationId(f.id);
                    setContent({ type: "resource", id: f.id });
                  }}
                  selected={selectedFoundationId === f.id}
                  disabled={deleteInProgress === f.id}
                >
                  <InitialsImageRound name={f.name} size={2.2} />
                  <ResourceName>{f.name}</ResourceName>
                  {!(deleteInProgress === f.id) && (
                    <DotMenu
                      divStyle={{
                        marginLeft: "auto",
                        width: "2.5rem",
                      }}
                    >
                      <MenuItem
                        name={"Duplicate"}
                        onClick={async () => {
                          setSaveInProgress(true);
                          setSelectedFoundationId(undefined);
                          const res = await duplicate({ foundation: f });
                          if (res) {
                            setSelectedFoundationId(res.id);
                            setSaveInProgress(false);
                          }
                        }}
                        icon={<DuplicateIcon />}
                      />
                      <MenuItem
                        name={"Delete"}
                        onClick={async () => {
                          setDeleteInProgress(f.id);
                          await remove(f.id);
                          setDeleteInProgress(undefined);
                          setSelectedFoundationId(foundations[0]?.id);
                        }}
                        icon={<Trashcan />}
                      />
                    </DotMenu>
                  )}
                </ResourceItem>
              );
            })}
            {saveInProgress && (
              <ResourceItem
                key={"save-in-progress"}
                selected={true}
                ref={newItemRef}
              >
                <SkeletonBlock
                  style={{ height: "4rem", marginTop: "0.8rem" }}
                />
              </ResourceItem>
            )}
          </Column>
        </ResourceColumn>
        {selectedFoundation && (
          <FoundationContent foundation={selectedFoundation} />
        )}
      </MainRow>
    </TabContainer>
  );
}

function FoundationContent({ foundation }: { foundation: FoundationType }) {
  const { organisationId } = useTypedPath("organisationId");
  const [, setContent] = useRecoilState(
    organisationRightSideModal(organisationId),
  );

  const { update } = useOrgFoundationCrud();

  return (
    <ContentColumn>
      <ContentHeaderRow>
        <ContentHeaderButtonsContainer>
          <OpenMoreInfo
            onClick={() => {
              setContent((cur) => {
                if (cur && cur.id === foundation.id) return undefined;
                return {
                  type: "resource",
                  id: foundation.id,
                };
              });
            }}
          />
        </ContentHeaderButtonsContainer>
        <ContentHeader>{foundation.name}</ContentHeader>
      </ContentHeaderRow>
      <div
        style={{
          overflow: "hidden",
          flex: 1,
          position: "relative",
          width: "100%",
        }}
      >
        <FoundationSettings
          foundation={foundation}
          isDefault={false}
          onSave={update}
          duplicate={() => {}}
          nodeId={organisationId}
          hasEditAccess={true}
        />
      </div>
    </ContentColumn>
  );
}
