/// <reference types="vite-plugin-svgr/client" />
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useRecoilCallback, useRecoilValue } from "recoil";
import InformationIcon from "@icons/24/Information.svg?react";
import { Organisation } from "components/Organisation/service";
import {
  CreatedOrganisationInvitation,
  useAddMultipleToOrganisation,
  UserAlreadyExistedInvitation,
} from "hooks/useUser";
import { usersInOrganisationState } from "components/Organisation/state";
import { useToast } from "hooks/useToast";
import MultiInputWithSuggestions, {
  MultiInputRef,
  MultiInputValue,
} from "components/General/MultiInputWithSuggestions";
import useTextInput from "hooks/useTextInput";
import { _OrganisationAccessRole, OrganisationAccessRole } from "types/user";
import { scream } from "utils/sentry";
import { capitalize, dedup } from "utils/utils";
import { emailRegex } from "@constants/regex";
import { Row } from "components/General/Layout";
import { spaceLarge, spaceSmall, spaceTiny } from "styles/space";
import { StyledDimensionedInput } from "components/General/Input";
import DropdownButton from "components/General/Dropdown/DropdownButton";
import Button from "components/General/Button";
import Spinner from "@icons/spinner/Spinner";
import { IconREMSize, typography } from "styles/typography";
import { colors } from "styles/colors";
import { WarningIconWrapper } from "./shared";
import { refreshInvitationsAtom } from "state/customer";

const dropdownItemsWrapperStyle: React.CSSProperties = {
  flexDirection: "column",
  alignItems: "flex-start",
};

const getOrgRoleDropdownItems = (isOrgAdmin: boolean) => {
  const items = [
    {
      value: "member",
      name: "Member",
      info: "Access projects and resources they are invited to.",
      wrapperStyle: dropdownItemsWrapperStyle,
    },
    {
      value: "guest",
      name: "Guest",
      info: "Restricted access typically for viewing projects and users outside the organisation.",
      wrapperStyle: dropdownItemsWrapperStyle,
    },
  ];

  if (isOrgAdmin) {
    items.unshift({
      value: "admin",
      name: "Admin",
      info: "Admins does not have automatic access to all projects, but can give themselves access to any project, group or library component. They can also invite new members.",
      wrapperStyle: dropdownItemsWrapperStyle,
    });
  }
  return items;
};

const BeforeInvite = ({
  organisation,
  isOrgAdmin,
  setInviteResult,
}: {
  organisation?: Organisation;
  isOrgAdmin: boolean;
  setInviteResult: React.Dispatch<
    React.SetStateAction<
      (UserAlreadyExistedInvitation | CreatedOrganisationInvitation)[]
    >
  >;
}) => {
  const allMembersRaw = useRecoilValue(
    usersInOrganisationState(organisation?.id ?? ""),
  );
  const inviteUsers = useAddMultipleToOrganisation(organisation?.id ?? "");
  const { error: showError } = useToast();
  const [isInviting, setIsInviting] = useState(false);
  const multiInputRef = useRef<MultiInputRef>(null);
  const [value, onValueChange, setValue] = useTextInput("");
  const [selectedUserAccessRole, setSelectedUserAccessRole] =
    useState<OrganisationAccessRole>("member");

  const [emailsAndValidity, setEmailsAndValidity] = useState<MultiInputValue[]>(
    [],
  );

  const onInviteClick = useRecoilCallback(
    ({ refresh, set }) =>
      async () => {
        const emailToAdd =
          multiInputRef.current?.checkValidityAndEnterIfValid();
        if (emailToAdd === false) {
          return;
        }

        let emailsWithAdded = [...emailsAndValidity];
        if (emailToAdd) {
          emailsWithAdded.push({
            value: emailToAdd,
            valid: !allMembersRaw.some((member) => member.email === emailToAdd),
          });
        }

        if (emailsWithAdded.filter((f) => !f.valid).length > 0) {
          return;
        }

        if (emailsWithAdded.length === 0) {
          return;
        }

        const emails = emailsWithAdded.map((e) => e.value);
        setIsInviting(true);
        try {
          const response = await inviteUsers(emails, selectedUserAccessRole);
          if (organisation) {
            refresh(usersInOrganisationState(organisation.id));
            set(refreshInvitationsAtom, (cur) => cur + 1);
          }
          setInviteResult(response);
        } catch (error) {
          showError(
            "Something went wrong when inviting users, please try again.",
            {
              timeout: 5000,
            },
          );

          if (error instanceof Error) {
            scream(error, {
              emails: emailsWithAdded,
            });
          } else {
            scream("Something went wrong when inviting users", {
              emails: emailsWithAdded,
            });
          }
        } finally {
          setIsInviting(false);
        }
      },
    [
      allMembersRaw,
      emailsAndValidity,
      inviteUsers,
      organisation,
      selectedUserAccessRole,
      setInviteResult,
      showError,
    ],
  );

  const onEnterNewMail = useCallback(() => {
    setEmailsAndValidity((curr) =>
      dedup(
        [
          ...curr,
          {
            value,
            valid: !allMembersRaw.some((member) => member.email === value),
          },
        ],
        (row) => row.value,
      ),
    );
    setValue("");
  }, [allMembersRaw, setValue, value]);

  const removeAlreadyExistingMembers = useCallback(() => {
    setEmailsAndValidity((curr) => curr.filter((e) => e.valid));
  }, []);

  const invalidEmails = emailsAndValidity.filter((e) => !e.valid);
  const validEmails = emailsAndValidity.filter((e) => e.valid);

  const enteredEmailIsValid = emailRegex.test(value);
  const inviteButtonDisabled =
    isInviting ||
    (validEmails.length === 0 && !enteredEmailIsValid) ||
    invalidEmails.length > 0;

  const orgRoleDropdownItems = useMemo(
    () => getOrgRoleDropdownItems(isOrgAdmin),
    [isOrgAdmin],
  );

  return (
    <>
      {/* Every margin-bottom is different in the figma sketches so we cant use gap :') */}
      <Row style={{ marginBottom: spaceLarge }}>
        <p style={{ margin: 0 }}>
          The user(s) will be added to the organisation{" "}
          {organisation?.name ?? ""}
        </p>
      </Row>
      <Row style={{ marginBottom: spaceSmall }}>
        <StyledDimensionedInput style={{ flex: 1, maxHeight: "unset" }}>
          <MultiInputWithSuggestions
            autoFocus
            ref={multiInputRef}
            value={value}
            onChange={onValueChange}
            onRemoveValue={(value, isFromBackspace) => {
              setEmailsAndValidity((curr) =>
                curr.filter((e) => e.value !== value),
              );

              if (isFromBackspace) {
                setValue(value);
              }
            }}
            type="email"
            pattern={emailRegex.source}
            enteredValues={emailsAndValidity}
            onEnter={onEnterNewMail}
            style={{ flexGrow: 1, border: "none" }}
            inputStyle={{ padding: 0, height: "unset" }}
            placeholder="email@example.com, email2@example.com ..."
          />
          <div>
            <DropdownButton
              buttonText={capitalize(selectedUserAccessRole)}
              items={orgRoleDropdownItems}
              renderText={(text) => {
                return <span>{text}</span>;
              }}
              style={{
                border: "none",
                width: "fit-content",
                ...typography.contentAndButtons,
              }}
              listContainerStyle={{
                right: 0,
                width: "40rem",
              }}
              onSelectItem={(newValue) => {
                setSelectedUserAccessRole(
                  _OrganisationAccessRole.parse(newValue),
                );
              }}
            />
          </div>
        </StyledDimensionedInput>
        <Button
          buttonType="primary"
          text="Invite"
          disabled={inviteButtonDisabled}
          icon={isInviting ? <Spinner size="0.5rem" /> : undefined}
          style={{ flexShrink: 0 }}
          onClick={onInviteClick}
        />
      </Row>
      <Row>
        <p
          style={{
            ...typography.label,
            margin: 0,
            color: colors.secondaryText,
          }}
        >
          You can add multiple emails, separated by commas
        </p>
      </Row>
      {invalidEmails.length > 0 && (
        <Row style={{ marginTop: spaceSmall, alignItems: "center" }}>
          <WarningIconWrapper>
            <IconREMSize height={1.6} width={1.6}>
              <InformationIcon />
            </IconREMSize>
          </WarningIconWrapper>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: spaceTiny,
            }}
          >
            <p
              style={{
                color: colors.primaryText,
                margin: 0,
                ...typography.contentAndButtons,
              }}
            >
              Member{invalidEmails.length !== 1 ? "s" : ""} already exist
              {invalidEmails.length === 1 ? "s" : ""}.
            </p>
            <Button
              style={{
                padding: 0,
                color: colors.primary,
              }}
              buttonType="text"
              text={`Remove item${
                invalidEmails.length !== 1 ? "s" : ""
              } to continue`}
              onClick={removeAlreadyExistingMembers}
            />
          </div>
        </Row>
      )}
    </>
  );
};

export default BeforeInvite;
