/// <reference types="vite-plugin-svgr/client" />
import { useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { CABLE_CORRIDOR_PROPERTY_TYPE } from "../../../constants/cabling";
import {
  DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
  SUB_AREA_PROPERTY_TYPE,
} from "../../../constants/division";
import { PARK_PROPERTY_TYPE } from "../../../constants/park";
import { useSetPropertyOnProjectFeatures } from "../../../hooks/useSetPropertyOnProjectFeatures";
import ArrowDownIcon from "@icons/24/ArrowDown.svg";
import ArrowRightIcon from "@icons/24/ArrowRight.svg";
import SubAreaIcon from "@icons/24/SideBarIcons/SubArea.svg";
import { colors } from "../../../styles/colors";
import {
  ExclusionDomainDefault,
  PolygonFeatureType,
  ProjectFeature,
} from "../../../types/feature";
import { isPark } from "../../../utils/predicates";
import { MenuItem } from "../../General/Menu";
import { MenuButton } from "../../General/MenuButton";
import { trackCanvasOption } from "../MenuTracking";
import { findFeatureChildren } from "../../../state/projectLayers";
import { projectFeaturesInBranchSelectorFamily } from "../../ProjectElements/state";
import { projectIdSelector, branchIdSelector } from "state/pathParams";
import Tooltip from "components/General/Tooltip";
import { TypeAreaDot, TypeDot } from "components/General/Icons";

const polygonTypeToName: Record<PolygonFeatureType | "other", string> = {
  [PARK_PROPERTY_TYPE]: "Park",
  [SUB_AREA_PROPERTY_TYPE]: "Sub area",
  [DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE]: "Exclusion zone",
  [CABLE_CORRIDOR_PROPERTY_TYPE]: "Cable corridor",
  other: "Other",
  "slack-region": "Slack Region",
  "park-cable-partition-polygon": "Cable partition",
  "park-cable-chain-polygon": "Cable chain",
  BathymetryUserUploadedType: "Bathymetry",
  GeoTiffUserUploadedImageType: "Geotiff",
};

export default function MultiPolygonTypeSelector({
  selections,
  disabled,
  selectedType,
}: {
  selections: ProjectFeature[];
  disabled: boolean;
  selectedType?: PolygonFeatureType | "other";
}) {
  const projectId = useRecoilValue(projectIdSelector);
  const branchId = useRecoilValue(branchIdSelector);

  const projectFeatures = useRecoilValue(
    projectFeaturesInBranchSelectorFamily({ branchId: branchId ?? "" }),
  );

  const setProperties = useSetPropertyOnProjectFeatures();

  const ids = useMemo(() => selections.map((s) => s.id), [selections]);
  const onSelectItem = useCallback(
    (
      item:
        | { type: "other" }
        | { type: "park-polygon" }
        | { type: typeof DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE }
        | { type: PolygonFeatureType; parkId: string },
    ) => {
      trackCanvasOption("change-feature-type", {
        projectId,
        branchId,
      });
      switch (item.type) {
        case "other": {
          const selectedParks = selections.filter(isPark);

          if (
            selectedParks.some(
              (park) =>
                findFeatureChildren(projectFeatures, park.id).length > 0,
            ) &&
            !window.confirm(
              "When converting a park to Other, all features related to the park (turbines etc.) will be removed. Continue?",
            )
          ) {
            return;
          }

          setProperties(ids, {
            type: undefined,
            color: colors.defaultCanvasLayer,
            parentIds: undefined,
          });
          return;
        }
        case DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE: {
          setProperties(ids, {
            type: DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
            color: colors.exclusionZone,
            domain: ExclusionDomainDefault,
          });
          return;
        }
      }
    },
    [projectId, branchId, setProperties, ids, selections, projectFeatures],
  );

  return (
    <MenuButton
      icon={<ArrowRightIcon />}
      iconOpen={<ArrowDownIcon />}
      iconPosition="right"
      buttonStyle={{
        border: "none",
        height: "fit-content",
        justifyContent: "space-between",
        padding: 0,
      }}
      buttonType="dropdown"
      buttonText={polygonTypeToName[selectedType ?? "other"]}
      disabled={disabled}
    >
      <MenuItem
        name={"Other"}
        onClick={() => onSelectItem({ type: "other" })}
        icon={<TypeDot dotColor={colors.other} />}
      />
      <Tooltip
        text={`Split multipolygon into polygons to convert it to parks`}
        position="right"
        innerDivStyle={{
          width: "100%",
        }}
      >
        <MenuItem
          disabled
          name={polygonTypeToName[PARK_PROPERTY_TYPE]}
          icon={<TypeDot dotColor={colors.park} />}
          style={{
            width: "100%",
          }}
        />
      </Tooltip>
      <MenuItem
        name={polygonTypeToName[DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE]}
        onClick={() =>
          onSelectItem({
            type: DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
          })
        }
        icon={<TypeDot dotColor={colors.exclusionZone} />}
        disabled={selectedType === PARK_PROPERTY_TYPE}
      />
      <Tooltip
        text={`Split multipolygon into polygons to convert it to sub areas`}
        position="right"
        innerDivStyle={{
          width: "100%",
        }}
      >
        <MenuItem
          name={polygonTypeToName[SUB_AREA_PROPERTY_TYPE]}
          disabled
          icon={<SubAreaIcon />}
          style={{
            width: "100%",
          }}
        />
      </Tooltip>
      <Tooltip
        text={`Split multipolygon into polygons to convert it to cable corridors`}
        position="right"
        innerDivStyle={{
          width: "100%",
        }}
      >
        <MenuItem
          style={{
            width: "100%",
          }}
          disabled
          name={polygonTypeToName[CABLE_CORRIDOR_PROPERTY_TYPE]}
          icon={<TypeAreaDot dotColor={colors.cableCorridor} />}
        />
      </Tooltip>
    </MenuButton>
  );
}
