import { selectorFamily, useRecoilValue } from "recoil";
import { ValidationWarningStateType } from "state/validationWarnings";
import { ValidationWarningTypes } from "./utils";
import { getCablesInBranchSelectorFamily } from "state/cable";
import { projectFeatureMapInBranch } from "state/projectLayers";
import { isSubstation, isTurbine } from "utils/predicates";
import * as turf from "@turf/turf";
import { Sector } from "lib/sector";
import { branchIdSelector } from "state/pathParams";
import { WarningProps } from "./ValidationWarnings";
import { useEffect } from "react";
import { deg2rad } from "utils/geometry";

export const cableIntersectsSectorSelectorFamily = selectorFamily<
  | (ValidationWarningStateType & {
      type: ValidationWarningTypes.CableIntersectsSector;
    })
  | undefined,
  { branchId: string; parkId: string }
>({
  key: "cableIntersectsSector",
  get:
    ({ branchId, parkId }) =>
    ({ get }) => {
      const cables = get(getCablesInBranchSelectorFamily({ branchId, parkId }));
      const map = get(
        projectFeatureMapInBranch({
          branchId,
        }),
      );

      const badCables = [];

      cableLoop: for (const c of cables) {
        const from = map.get(c.properties.fromId);
        if (!isTurbine(from) && !isSubstation(from)) continue;
        const fromAngle = deg2rad(
          turf.bearing(c.geometry.coordinates[0], c.geometry.coordinates[1]),
        );
        for (const sec of from.properties.cableFreeSectors ?? []) {
          const s = new Sector(sec.middle, sec.span);
          // If the angular distance is negative, it is inside the span.
          if (s.distance(fromAngle) < -0.001) {
            badCables.push(c);
            continue cableLoop;
          }
        }

        const to = map.get(c.properties.toId);
        if (!isTurbine(to) && !isSubstation(to)) continue;
        const toAngle = deg2rad(
          turf.bearing(
            c.geometry.coordinates.at(-1)!,
            c.geometry.coordinates.at(-2)!,
          ),
        );
        for (const sec of to.properties.cableFreeSectors ?? []) {
          const s = new Sector(sec.middle, sec.span);
          // If the angular distance is negative, it is inside the span.
          if (s.distance(toAngle) < -0.001) {
            badCables.push(c);
            continue cableLoop;
          }
        }
      }

      if (badCables.length)
        return {
          type: ValidationWarningTypes.CableIntersectsSector,
          featureIds: badCables.map((c) => c.id),
        };

      return undefined;
    },
});

export const CableIntersectsSector = ({
  parkId,
  warn,
  remove,
}: { parkId: string } & WarningProps) => {
  const branchId = useRecoilValue(branchIdSelector) ?? "";

  const errors = useRecoilValue(
    cableIntersectsSectorSelectorFamily({ parkId: parkId, branchId }),
  );

  // Update validation warning
  useEffect(() => {
    if (!errors) return;
    warn(errors);
    return () => {
      remove(ValidationWarningTypes.CableIntersectsSector);
    };
  }, [remove, errors, warn]);

  return null;
};
