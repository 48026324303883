/// <reference types="vite-plugin-svgr/client" />
import DownloadIcon from "@icons/24/Download.svg?react";
import {
  getAnalysisProgress,
  getAnalysisTimeseries,
  getAverageMonthlyEnergy,
  getConfiguration,
  getStoppedReason,
  getStoppedReasonFromAnalysis,
} from "components/ProductionV2/state";
import {
  AnalysisStoppedTypes,
  analysisStoppedText,
} from "components/ProductionV2/types";
import { stringify } from "csv-stringify/sync";
import Plotly from "plotly.js-dist-min";
import { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { useToast } from "../../../hooks/useToast";
import { colors } from "../../../styles/colors";
import { downloadText } from "../../../utils/utils";
import { MenuItem } from "../../General/Menu";
import { formatPercent } from "../../ProductionV2/format";
import { CenterContainer, LoadingState, SafeCard } from "./Base";
import Spinner from "@icons/spinner/Spinner";
import { SkeletonText } from "components/Loading/Skeleton";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useDashboardContext } from "../Dashboard";

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const useNumbers = () => {
  const { triggerId } = useDashboardContext();
  const monthlyEnergy = useRecoilValueLoadable(
    getAverageMonthlyEnergy(triggerId),
  ).valueMaybe();
  const config = useRecoilValue(getConfiguration(triggerId));
  const timeseries = useRecoilValueLoadable(
    getAnalysisTimeseries(triggerId),
  ).valueMaybe();
  const analysisProgress = useRecoilValue(getAnalysisProgress(triggerId));

  return {
    productionPerMonth: monthlyEnergy,
    powerPerTimePostWake: timeseries?.powerPerTimePostWake,
    energyPerDate: timeseries?.energyPerDate,
    date: timeseries?.date,
    timestamp: timeseries?.time,
    precision: config.wakeAnalysis.precision,
    analysisProgress: analysisProgress,
  };
};

const MonthlyGraph = () => {
  const { triggerId } = useDashboardContext();
  const stoppedReason = useRecoilValue(getStoppedReason(triggerId));
  const analysisStoppedReason = useRecoilValueLoadable(
    getStoppedReasonFromAnalysis(triggerId),
  ).valueMaybe();

  if (stoppedReason) {
    return (
      <CenterContainer>
        <SimpleAlert
          title="Analysis stopped"
          text={analysisStoppedText[stoppedReason]}
          type={"error"}
        />
      </CenterContainer>
    );
  }
  if (analysisStoppedReason) {
    return (
      <SimpleAlert
        title="Analysis stopped"
        text={
          analysisStoppedText[analysisStoppedReason as AnalysisStoppedTypes]
        }
        type={"error"}
      />
    );
  }

  return <MonthlyGraphInner />;
};

const MonthlyGraphInner = () => {
  const graphRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const { analysisProgress, productionPerMonth } = useNumbers();

  useEffect(() => {
    const current = graphRef.current;
    if (!isLoaded || !current) return;
    const observer = new ResizeObserver(() => {
      if (!current.clientWidth) return;
      Plotly.Plots.resize(current);
    });
    if (current) observer.observe(current);
    return () => observer.disconnect();
  }, [isLoaded]);

  useEffect(() => {
    if (!graphRef.current || !productionPerMonth) return;
    setIsLoaded(false);

    const total = productionPerMonth.reduce((acc, n) => acc + n);
    const labels = productionPerMonth
      .map((n) => ({ production: Math.round(n), fraction: n / total }))
      .map(
        ({ production, fraction }) =>
          `${production} (${formatPercent(fraction)})`,
      );

    var data = [
      {
        x: MONTHS,
        y: productionPerMonth,
        type: "bar" as const,
        text: labels,
        textposition: "auto" as const,
        hoverinfo: "none" as const,
        opacity: 0.8,
        marker: {
          color: colors.hoverSelected,
          line: {
            color: colors.brand,
            width: 1.5,
          },
        },
      },
    ];
    var layout = {
      font: { size: 10, family: "Open Sans" },
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
      autosize: true,
      height: 200,
      yaxis: {
        title: "GWh",
      },
      margin: {
        l: 60,
        r: 30,
        b: 30,
        t: 30,
      },
    };

    Plotly.newPlot(graphRef.current, data, layout, {
      displayModeBar: false,
      responsive: true,
      staticPlot: true,
    }).then(() => setIsLoaded(true));
  }, [graphRef, productionPerMonth]);

  if (!productionPerMonth) {
    if (analysisProgress && isLoaded)
      return (
        <SkeletonText
          style={{ margin: "2rem", height: "2rem" }}
          text={`${Math.round(analysisProgress * 100)}%`}
        />
      );
    return <LoadingState />;
  }

  return <div ref={graphRef} />;
};

const MonthlyProductionWidgetMenu = () => {
  const { warning } = useToast();
  const {
    productionPerMonth,
    energyPerDate,
    date,
    precision,
    powerPerTimePostWake,
    timestamp,
  } = useNumbers();

  const monthlyLoading = !productionPerMonth;
  const dailyLoading = !powerPerTimePostWake;
  const hourlyLoading = !powerPerTimePostWake;

  const downloadMonthly = useCallback(() => {
    if (!productionPerMonth) {
      warning("Data is still loading, please wait a few seconds.");
      return;
    }
    const header = ["month", "gwh"];
    const rows = productionPerMonth.map((v, i) => [MONTHS[i], v.toFixed(1)]);
    const csvString = stringify([header, ...rows]);
    downloadText(csvString, "monthly_production.csv");
  }, [productionPerMonth, warning]);

  const downloadDaily = useCallback(() => {
    if (!energyPerDate || !date) {
      warning("Data is still loading, please wait a few seconds.");
      return;
    }
    const rows = date.map((d, i) => [d, (energyPerDate[i] / 1e3).toFixed(2)]);
    const csvString = stringify([["day", "gwh"], ...rows]);
    downloadText(csvString!, "daily_production.csv");
  }, [warning, energyPerDate, date]);

  const downloadPostWakeHourly = useCallback(() => {
    if (!powerPerTimePostWake || !timestamp) {
      warning("Data is still loading, please wait a few seconds.");
      return;
    }
    const rows = timestamp.map((d, i) => [
      d,
      powerPerTimePostWake[i].toFixed(2),
    ]);
    const csvString = stringify([["timestamp", "mw"], ...rows]);
    downloadText(csvString!, "postwake_power.csv");
  }, [warning, powerPerTimePostWake, timestamp]);

  return (
    <>
      <MenuItem
        name={"Monthly energy"}
        disabled={monthlyLoading}
        icon={monthlyLoading ? <Spinner size="0.8rem" /> : <DownloadIcon />}
        onClick={() => downloadMonthly()}
      />
      <MenuItem
        name={
          precision !== "fast"
            ? "Daily energy"
            : "Daily energy (Default or High precision required)"
        }
        icon={dailyLoading ? <Spinner size="0.8rem" /> : <DownloadIcon />}
        disabled={precision === "fast" || dailyLoading}
        onClick={() => downloadDaily()}
      />
      <MenuItem
        name={
          precision !== "high"
            ? "Post-wake timeseries (High precision required)"
            : "Post-wake timeseries"
        }
        icon={hourlyLoading ? <Spinner size="0.8rem" /> : <DownloadIcon />}
        disabled={precision !== "high" || hourlyLoading}
        onClick={() => downloadPostWakeHourly()}
      />
    </>
  );
};

export const MonthlyProductionWidget = () => (
  <SafeCard
    title="Monthly production graph"
    id="Monthly graph"
    menuItems={<MonthlyProductionWidgetMenu />}
  >
    <MonthlyGraph />
  </SafeCard>
);
