import { useEffect, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { mapRefAtom } from "../../state/map";
import { fetchEnhancer } from "../../services/utils";
import { toastMessagesAtom } from "../../state/toast";
import { layerSettingSelectorFamily } from "../../components/LayerList/LayerSettings/state";
import { useTypedPath } from "../../state/pathParams";
import { getLegendGraphicsUrl } from "../../components/LayerList/LayerListV2";
import { externalWFSAnchorId } from "../../components/Mapbox/utils";
import { ExternalDataSourceLinkLayerWithSourceWMS } from "../../types/layers";
import mapboxgl from "mapbox-gl";
import { getVisibleLayers } from "../../components/LayerList/LayerSettings/utils";
import {
  ErrorBoundarySilent,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "../../components/ErrorBoundaries/ErrorBoundaryLocal";
import { getWmsPath, isWMSLayer } from "../../state/wms";
import { addCorsAndCacheProxyURL } from "../../state/gisSourceCorsProxy";
import { getZoomLevels, layerToSourceId } from "./utils";

const DynamicWMSLayers = ErrorBoundaryWrapper(
  () => {
    const { projectId } = useTypedPath("projectId");
    const visibleLayers = useRecoilValue(getVisibleLayers({ projectId }));
    const selectedDynamicWMSLayers = visibleLayers.filter(isWMSLayer);

    return (
      <>
        {selectedDynamicWMSLayers.map((layer) => {
          return <DynamicWMSLayer key={layer.id} layer={layer} />;
        })}
      </>
    );
  },
  ErrorBoundarySilent,
  ScreamOnError,
);

const useCheckThatLayerIsWorkingProperly = (
  layer: ExternalDataSourceLinkLayerWithSourceWMS,
) => {
  const setToastMessages = useSetRecoilState(toastMessagesAtom);
  const { sourceLayerId, sourceLink } = layer;
  const isPrivate = "private" in layer.source ? layer.source["private"] : false;
  const urlWithProxy = !isPrivate
    ? addCorsAndCacheProxyURL(sourceLink.url)
    : sourceLink.url;

  useEffect(() => {
    const asyncCheckWMSLayer = async () => {
      const queryUrl = getLegendGraphicsUrl(sourceLayerId, urlWithProxy);
      try {
        const res = await fetchEnhancer(queryUrl, {
          method: "get",
        });

        if (!res.ok) {
          throw new Error();
        }
      } catch (error) {
        setToastMessages((tm) => [
          ...tm,
          {
            text: "Selected WMS layer source might have problems...",
            timeout: 7000,
          },
        ]);
      }
    };
    asyncCheckWMSLayer();
  }, [sourceLayerId, sourceLink.url, setToastMessages, urlWithProxy]);

  return null;
};

const DynamicWMSLayer = ErrorBoundaryWrapper(
  ({ layer }: { layer: ExternalDataSourceLinkLayerWithSourceWMS }) => {
    useCheckThatLayerIsWorkingProperly(layer);
    const map = useRecoilValue(mapRefAtom);
    const { projectId } = useTypedPath("projectId");
    const settings = useRecoilValue(
      layerSettingSelectorFamily({
        projectId,
        layerId: layer.id,
      }),
    );

    const zoomLevels = settings.layerStyle?.zoomLevels;

    const dynamicWMSLayersId = useMemo(
      () => `dynamic-wms-layer-${layer.source.name}-${layer.name}`,
      [layer],
    );
    const dynamicWMSLayersSource = useMemo(
      () => layerToSourceId(layer, "-wms"),
      [layer],
    );

    const dynamicWMSLayer: mapboxgl.RasterLayer = useMemo(
      () => ({
        id: dynamicWMSLayersId,
        type: "raster",
        source: dynamicWMSLayersSource, // reference the data source
        paint: {},
        ...getZoomLevels(zoomLevels),
      }),
      [dynamicWMSLayersId, dynamicWMSLayersSource, zoomLevels],
    );

    useEffect(() => {
      if (!map) return;
      map.addSource(dynamicWMSLayersSource, {
        type: "raster",
        tiles: [`${getWmsPath(layer)}&bbox={bbox-epsg-3857}`],
        tileSize: 256,
      });
      map.addLayer(
        dynamicWMSLayer,
        map.getLayer(externalWFSAnchorId) ? externalWFSAnchorId : undefined,
      );
      return () => {
        map.removeLayer(dynamicWMSLayer.id);
        map.removeSource(dynamicWMSLayersSource);
      };
    }, [
      map,
      dynamicWMSLayersId,
      layer,
      dynamicWMSLayer,
      dynamicWMSLayersSource,
    ]);

    useEffect(() => {
      if (!map) return;

      if (settings.layerStyle?.opacity !== undefined) {
        map.setPaintProperty(
          dynamicWMSLayer.id,
          "raster-opacity",
          settings.layerStyle.opacity,
        );
      }
    }, [map, dynamicWMSLayersId, dynamicWMSLayer, layer, settings.layerStyle]);

    return null;
  },
  ErrorBoundarySilent,
  ScreamOnError,
);

export default DynamicWMSLayers;
