/// <reference types="vite-plugin-svgr/client" />
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { LngLatBoundsLike } from "mapbox-gl";
import { CustomLayer, Layer } from "../../../types/layers";
import { LayerCollection } from "../LayerSettings/types";
import useSystemSpecificUnicode from "../../../hooks/useSystemSpecificUnicode";
import { useTypedPath } from "../../../state/pathParams";
import {
  layerSettingSelectorFamily,
  selectedSourceNameAtom,
} from "../LayerSettings/state";
import AddIcon from "@icons/24/Add.svg";
import BinIcon from "@icons/24/Bin.svg";
import DownloadIcon from "@icons/24/Download.svg";
import MoveToFolderIcon from "@icons/14/MoveToFolder.svg";
import FilterIcon from "@icons/24/Filter.svg";
import LayersIcon from "@icons/24/Layers.svg";
import RemoveIcon from "@icons/24/Remove.svg";
import SearchIcon from "@icons/24/Search.svg";
import SectionIcon from "@icons/24/Section.svg";
import StylingIcon from "@icons/14/Styling.svg";
import { useForceReloadCustomLayers } from "../../../state/customLayers";
import { toastMessagesAtom } from "../../../state/toast";
import { useLayerSettingsCrud } from "../LayerSettings/useLayerSettingsCrud";
import { useCollectionCrud } from "../Collections/useCollectionCrud";
import {
  deleteCustomLayer,
  downloadCustomLayerAsBlob,
} from "../../../services/customLayersAPIService";
import { downloadBlob } from "../../../utils/utils";
import { scream } from "../../../utils/sentry";
import { getSourceTitle, isCustomLayer, isCustomUploadedLayer } from "../utils";
import { DotMenu, MenuButtonRef } from "../../General/MenuButton";
import { MenuItem } from "../../General/Menu";
import LayerStyling from "../../LayerStyling/LayerStyling";
import { mapRefAtom } from "../../../state/map";
import { externalLayerFilterPropertyAtom } from "../../../state/layer";
import { layerToSourceId } from "../../../layers/ExternalLayers/utils";
import { SkeletonBlock } from "../../Loading/Skeleton";
import { selectedLayerIdsWithCollectionIdAtom } from "../state";
import LayerItem from "./LayerItem";

export const SourceNameComponent = ({ layer }: { layer: Layer }) => {
  const { language: selectedSourceName } = useRecoilValue(
    selectedSourceNameAtom,
  );

  const title = getSourceTitle(layer, selectedSourceName);

  return <>{title}</>;
};

const ProjectLayerItem = ({
  layer,
  collections,
  editable,
  nameEditable,
  onToggleMultiSelect,
  onShiftSelect,
  onAddAllToCollectionClick,
  onRemoveMultiple,
}: {
  layer: Layer;
  collections: LayerCollection[];
  editable: boolean;
  depth: number;
  nameEditable: boolean;
  onToggleMultiSelect: (
    layerId: string,
    collectionId: string | undefined,
  ) => void;
  onShiftSelect: (layerId: string, collectionId: string | undefined) => void;
  onAddAllToCollectionClick(collection?: LayerCollection): void;
  onRemoveMultiple(): void;
}) => {
  const map = useRecoilValue(mapRefAtom);
  const stringToUnicode = useSystemSpecificUnicode();
  const { projectId } = useTypedPath("projectId");
  const layerSettings = useRecoilValue(
    layerSettingSelectorFamily({ projectId, layerId: layer.id }),
  );
  const [externalLayerFilterProperty, setExternalLayerFilterProperty] =
    useRecoilState(externalLayerFilterPropertyAtom);
  const dotMenuRef = useRef<MenuButtonRef>(null);
  const selectedLayerIdsWithCollection = useRecoilValue(
    selectedLayerIdsWithCollectionIdAtom,
  );

  const forceReloadCustomLayers = useForceReloadCustomLayers();
  const setToastMessages = useSetRecoilState(toastMessagesAtom);
  const [_isLoadingLayer, setIsLoadingLayer] = useState<boolean>(false);
  const { del: delLayerSettings } = useLayerSettingsCrud();
  const { put: putCollection, post: postCollection } = useCollectionCrud();

  const layerSourceId = useMemo(
    () => layerToSourceId(layer, `-${layer.type}`),
    [layer],
  );

  const activeLayerFilters = useMemo(() => {
    return externalLayerFilterProperty[layerSourceId];
  }, [externalLayerFilterProperty, layerSourceId]);

  const onMultiSelect = useCallback(() => {
    onToggleMultiSelect(layer.id, undefined);
  }, [layer, onToggleMultiSelect]);

  const removeLayer = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();
      if (!projectId) return;
      if (isCustomUploadedLayer(layer)) {
        setIsLoadingLayer(true);
        try {
          await deleteCustomLayer(projectId, layer.id);
        } catch (error) {
          scream("Could not delete custom layer", { error });
          setToastMessages((tm) => [
            ...tm,
            {
              text: "Could not delete layer, try again.",
              timeout: 5000,
              type: "error",
            },
          ]);
        } finally {
          setIsLoadingLayer(false);
          forceReloadCustomLayers();
        }
      } else {
        return delLayerSettings([layerSettings.id]);
      }
    },
    [
      projectId,
      forceReloadCustomLayers,
      layer,
      layerSettings,
      delLayerSettings,
      setToastMessages,
    ],
  );

  const downloadCustomLayer = useCallback(async () => {
    const path = (layer as CustomLayer).url;
    setIsLoadingLayer(true);
    const blob = await downloadCustomLayerAsBlob(path);
    setIsLoadingLayer(false);
    if (blob) {
      downloadBlob(blob, layer.name);
    } else {
      setToastMessages((curr) => [
        ...curr,
        {
          text: "Could not download layer, please refresh the page and try again",
          type: "error",
          timeout: 8000,
        },
      ]);
    }
  }, [layer, setToastMessages]);

  const isSelected = selectedLayerIdsWithCollection.some((row) => {
    return row.layerId === layer.id && row.collectionId === undefined;
  });

  const isMultiSelect = selectedLayerIdsWithCollection.length > 1;

  return (
    <LayerItem
      layer={layer}
      datasetLayerId={layer.id}
      editable={editable}
      depth={0}
      nameEditable={nameEditable}
      isSelected={isSelected}
      onContextMenu={(e) => {
        e.preventDefault();
        dotMenuRef.current?.setIsOpen(true);
      }}
      onToggleMultiSelect={onToggleMultiSelect}
      onShiftSelect={onShiftSelect}
      dotMenu={
        editable &&
        !Boolean(layer.dateDeleted) && (
          <DotMenu
            ref={dotMenuRef}
            side="right"
            buttonStyle={{
              height: "2.2rem",
            }}
          >
            {isSelected && isMultiSelect ? (
              <>
                <MenuItem
                  name="Add all to folder"
                  icon={<AddIcon />}
                  direction="right"
                >
                  {collections.map((collection) => {
                    return (
                      <MenuItem
                        key={collection.id}
                        name={collection.name}
                        icon={<LayersIcon />}
                        onClick={() => onAddAllToCollectionClick(collection)}
                        direction="right"
                      />
                    );
                  })}
                  <MenuItem
                    name="Create new folder"
                    icon={<AddIcon />}
                    onClick={() => onAddAllToCollectionClick()}
                  />
                </MenuItem>

                <MenuItem
                  name={isSelected ? "Deselect" : "Multiselect"}
                  icon={<SectionIcon />}
                  onClick={onMultiSelect}
                  shortcut={`${stringToUnicode("command")} + Click`}
                />

                <MenuItem
                  name="Remove layers"
                  icon={<RemoveIcon />}
                  onClick={onRemoveMultiple}
                />
              </>
            ) : (
              <>
                <MenuItem
                  name="Set styling"
                  icon={<StylingIcon />}
                  direction="right"
                >
                  <React.Suspense
                    fallback={
                      <SkeletonBlock
                        style={{ width: "20rem", height: "15rem" }}
                      />
                    }
                  >
                    <LayerStyling layer={layer} />
                  </React.Suspense>
                </MenuItem>
                <MenuItem
                  name="Move to folder"
                  icon={<MoveToFolderIcon />}
                  direction="right"
                >
                  {collections.map((collection) => {
                    const isInCollection =
                      collection.layers.find(
                        (collectionLayer) => collectionLayer.id === layer.id,
                      ) !== undefined;
                    return (
                      <MenuItem
                        key={collection.id}
                        name={collection.name}
                        icon={<LayersIcon />}
                        onClick={
                          !isInCollection
                            ? async () => {
                                await putCollection({
                                  ...collection,
                                  layers: [...collection.layers, layer],
                                });
                              }
                            : undefined
                        }
                        direction="right"
                      >
                        {isInCollection && (
                          <MenuItem
                            name="Remove from folder"
                            icon={<RemoveIcon />}
                            onClick={async () => {
                              await putCollection({
                                ...collection,
                                layers: collection.layers.filter(
                                  (l) => l !== layer,
                                ),
                              });
                            }}
                          />
                        )}
                      </MenuItem>
                    );
                  })}
                  <MenuItem
                    name="Create new folder"
                    icon={<AddIcon />}
                    onClick={() =>
                      postCollection({
                        layers: [layer],
                      })
                    }
                  />
                </MenuItem>
                {layer.bbox && map && (
                  <MenuItem
                    name="Zoom to layer"
                    icon={<SearchIcon />}
                    onClick={() => {
                      map.fitBounds([
                        layer.bbox!.slice(0, 2),
                        layer.bbox!.slice(2, 4),
                      ] as LngLatBoundsLike);
                    }}
                  />
                )}
                {isCustomLayer(layer) && (
                  <MenuItem
                    name="Download"
                    icon={<DownloadIcon />}
                    onClick={downloadCustomLayer}
                  />
                )}

                <MenuItem
                  name={isSelected ? "Deselect" : "Multiselect"}
                  icon={<SectionIcon />}
                  onClick={onMultiSelect}
                  shortcut={`${stringToUnicode("command")} + Click`}
                />

                {activeLayerFilters && (
                  <MenuItem
                    name="Clear filters"
                    icon={<FilterIcon />}
                    onClick={() => {
                      setExternalLayerFilterProperty((curr) => {
                        const clone = { ...curr };
                        delete clone[layerSourceId];
                        return clone;
                      });
                    }}
                  />
                )}

                {isCustomUploadedLayer(layer) ? (
                  <MenuItem
                    name={"Delete layer"}
                    icon={<BinIcon />}
                    onClick={removeLayer}
                  />
                ) : (
                  <MenuItem
                    name={"Remove layer"}
                    icon={<RemoveIcon />}
                    onClick={removeLayer}
                  />
                )}
              </>
            )}
          </DotMenu>
        )
      }
    />
  );
};

export default ProjectLayerItem;
