/// <reference types="vite-plugin-svgr/client" />
import TurbineIcon from "@icons/24/WindTurbine.svg?react";
import CableIcon from "@icons/24/ExportCable.svg?react";
import MooringIcon from "@icons/24/Anchor.svg?react";
import SubstationIcon from "@icons/24/Substation.svg?react";
import BuildZone from "@icons/24/AddCircle.svg?react";
import BuildZoneForbidden from "@icons/24/SubtractCircle.svg?react";
import ShapesIcon from "@icons/24/Shapes.svg?react";
import {
  CABLE_PROPERTY_TYPE,
  SUBSTATION_PROPERTY_TYPE,
} from "../../../constants/cabling";
import {
  SUB_AREA_PROPERTY_TYPE,
  DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
} from "../../../constants/division";
import {
  TURBINE_PROPERTY_TYPE,
  MOORING_LINE_PROPERTY_TYPE,
  ANCHOR_PROPERTY_TYPE,
} from "../../../constants/projectMapView";
import {
  anchorLayerId,
  cableLayerId,
  divisionLayerId,
  divisionOutlineLayerId,
  exportCableLayerId,
  mooringLineLayerId,
  otherLineStringLayerId,
  otherPointLayerId,
  otherPolygonLayerId,
  substationLayerId,
  turbineLayerId,
} from "components/Mapbox/constants";

export const selectionNames = [
  "All",
  "Turbines",
  "Cables",
  "Mooring",
  "Substations",
  "Sub areas",
  "Exclusion zones",
  "Other",
];

export const selectionNameToTypes: Record<string, (string | undefined)[]> = {
  Turbines: [TURBINE_PROPERTY_TYPE],
  Cables: [CABLE_PROPERTY_TYPE],
  Mooring: [MOORING_LINE_PROPERTY_TYPE, ANCHOR_PROPERTY_TYPE],
  Substations: [SUBSTATION_PROPERTY_TYPE],
  "Sub areas": [SUB_AREA_PROPERTY_TYPE],
  "Exclusion zones": [DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE],
  Other: [undefined],
};
export const selectionNameToIcon = {
  All: <p style={{ margin: 0, fontSize: 14, fontWeight: 500 }}>All</p>,
  Turbines: <TurbineIcon />,
  Cables: <CableIcon />,
  Mooring: <MooringIcon />,
  Substations: <SubstationIcon />,
  "Sub areas": <BuildZone />,
  "Exclusion zones": <BuildZoneForbidden />,
  Other: <ShapesIcon />,
};

export const basicProjectFeatureLayers = [
  otherPointLayerId,
  otherLineStringLayerId,
  otherPolygonLayerId,
  substationLayerId,
  cableLayerId,
  exportCableLayerId,
  divisionLayerId,
  mooringLineLayerId,
  anchorLayerId,
];

export const layersToExcludeInSelectWithSmallZoom = [
  substationLayerId,
  cableLayerId,
  exportCableLayerId,
  divisionLayerId,
  divisionOutlineLayerId,
  mooringLineLayerId,
  anchorLayerId,
  turbineLayerId,
];

export const ZOOM_LEVEL_CUT_OFF_FOR_SMALL_FEATURES = 8;
