import * as turf from "@turf/turf";
import { Point } from "@turf/turf";
import { getTurbineResourcesOnNode } from "components/Organisation/Library/service";
import { Feature } from "geojson";
import { atom, atomFamily, selector, selectorFamily } from "recoil";
import { fetchEnhancerWithToken } from "services/utils";
import {
  PROJECT_SERVICE_API_PATH,
  PROJECT_SERVICE_API_VERSION,
} from "../components/ProjectElements/service";
import { topLevelFolderIdFromOrgIdAndProjectIdSelectorFamily } from "../components/Projects/useOrganisationFolderCrud";
import { DEFAULT_MIN_SPACING } from "../constants/park";
import { State } from "../constants/webworker";
import {
  getProjectTurbineKeyVersions,
  getProjectTurbines,
} from "../services/turbineAPIService";
import { TurbineFeature } from "../types/feature";
import {
  AdditionalEdgeParameters,
  DEFAULT_TURBINES,
  EdgeParameters,
  EdgeParametersWithUnit,
  GenerationMethod,
  GenerationParameters,
  OptimizeParameters,
  OptimizeParametersWithUnit,
  RegularParameters,
  RegularParametersWithUnit,
  SimpleTurbineType,
  SimpleTurbineTypeWithLevel,
  TurbineNoiseSettings,
  TurbineTypeKeyEvents,
  TurbineTypeUsageType,
  _TurbineTypeUsage,
} from "../types/turbines";
import { CreateSerializableParam } from "../types/utils";
import { count, dedup, isNever } from "../utils/utils";
import { _TurbineLevel } from "./../types/turbines";
import {
  getTurbinesInBranchSelectorFamily,
  getTurbinesSelectorFamily,
} from "./layout";
import { getParkFeatureSelectorFamily, inPark } from "./park";
import { parkIdSelector, projectIdSelector } from "./pathParams";
import { currentSelectedProjectFeatures } from "./selection";
import { isTurbine } from "utils/predicates";
import { pointInPolygon } from "utils/geometry";
import { getSelectedAreaStrict } from "./division";

export const DEFAULT_NOISE_PARAMS = {
  source: 110,
  red: 50,
  yellow: 40,
  opacity: 0.7,
  temperature: 10,
} as const;

export const showNoiseAtom = atom({
  key: "show-noise",
  default: false,
});

export const noiseLevelSelectorFamily = selectorFamily<
  TurbineNoiseSettings,
  { parkId: string }
>({
  key: "noiseLevelSelectorFamily",
  get:
    ({ parkId }) =>
    ({ get }) => {
      if (!parkId) return DEFAULT_NOISE_PARAMS;
      const park = get(getParkFeatureSelectorFamily({ parkId }));
      if (!park) return DEFAULT_NOISE_PARAMS;
      return park.properties?.noiseSettings ?? DEFAULT_NOISE_PARAMS;
    },
});

/**
 * When generating turbines we store the "preview" turbines in this state. Since
 * generating new turbines for a zone replaces the existing ones, we also store
 * the existing turbines from *other* zones, in the same park.
 */
export const previewTurbinesState = atom<
  | undefined
  | {
      preview: TurbineFeature[];
      existing: TurbineFeature[];
    }
>({
  key: "previewTurbinesState",
  default: undefined,
});

const defaultRegularParameters: RegularParameters = {
  setNumberOfTurbines: false,
  numberOfTurbines: 0,
  minorAxisSpacing: 7,
  majorAxisSpacing: 7,
  obliquity: 0,
  shiftX: 0,
  shiftY: 0,
  rotate: 180,
};
export const defaultRegularParamsWithUnits = (): RegularParametersWithUnit => {
  const p = defaultRegularParameters;
  return {
    setNumberOfTurbines: p.setNumberOfTurbines,
    numberOfTurbines: p.numberOfTurbines,
    minorAxisSpacing: { value: p.minorAxisSpacing, unit: "D" },
    majorAxisSpacing: { value: p.majorAxisSpacing, unit: "D" },
    obliquity: { value: p.obliquity, unit: "deg" },
    shiftX: { value: p.shiftX, unit: "m" },
    shiftY: { value: p.shiftY, unit: "m" },
    rotate: { value: p.rotate, unit: "deg" },
  };
};

const defaultEdgeParameters: AdditionalEdgeParameters = {
  edgeSpacing: 7,
};

export const defaultEdgeParamsWithUnits = (): EdgeParametersWithUnit => {
  const p = defaultEdgeParameters;
  return {
    ...defaultRegularParamsWithUnits(),
    edgeSpacing: { value: p.edgeSpacing, unit: "D" },
  };
};

const defaultOptimizeParameters: OptimizeParameters = {
  numberOfTurbines: 0,
  minSpacing: DEFAULT_MIN_SPACING,
  includeEdge: false,
  includeNeighbours: true,
  overrideDataSource: false,
  wakeModel: "jensen",
  seed: 0,
};

export const defaultOptimizeParametersWithUnits =
  (): OptimizeParametersWithUnit => {
    return {
      numberOfTurbines: 0,
      minSpacing: DEFAULT_MIN_SPACING,
      includeEdge: false,
      includeNeighbours: true,
      overrideDataSource: false,
      wakeModel: "jensen",
      seed: 0,
    };
  };

export function defaultParameters(p: "regular"): RegularParameters;
export function defaultParameters(p: "edge"): EdgeParameters;
export function defaultParameters(p: "regular_auto"): OptimizeParameters;
export function defaultParameters(p: GenerationMethod): GenerationParameters;
export function defaultParameters(p: GenerationMethod): GenerationParameters {
  if (p === "regular") return defaultRegularParameters;
  if (p === "edge")
    return { ...defaultEdgeParameters, ...defaultRegularParameters };
  if (p === "regular_auto") return defaultOptimizeParameters;
  throw isNever(p);
}

export const generationStateAtom = atom<State | undefined>({
  key: "generationStateAtom",
  default: undefined,
});

export const selectedMethodForRegionAtomFamily = atomFamily<
  undefined | GenerationMethod,
  string
>({
  key: "selectedMethodForRegionAtomFamily",
  default: undefined,
});

export const autoSettingsForRegionAtomFamily = atomFamily<
  undefined | OptimizeParameters,
  string
>({
  key: "autoSettingsForRegionAtomFamily",
  default: undefined,
});

export const projectTurbinesRefreshAtom = atom({
  key: "projectTurbinesRefreshAtom",
  default: 1,
});

export const libraryTurbinesRefreshAtom = atom({
  key: "libraryTurbinesRefreshAtom",
  default: 1,
});

export const projectTurbineTypesSelectorFamily = selectorFamily<
  SimpleTurbineType[],
  { nodeId: string | undefined }
>({
  key: "project-turbine-types-selector-family",
  get:
    ({ nodeId }) =>
    async ({ get }) => {
      get(projectTurbinesRefreshAtom);
      if (!nodeId) return [];
      const turbines = await getProjectTurbines(nodeId);
      return turbines
        .filter((t) => !t.archived)
        .sort((a, b) =>
          a.name.localeCompare(b.name, undefined, { sensitivity: "accent" }),
        );
    },
});

export const libraryTurbinesOnNodeState = selectorFamily<
  SimpleTurbineType[],
  { nodeId: string | undefined }
>({
  key: "libraryTurbinesOnNodeState",
  get:
    ({ nodeId }) =>
    async ({ get }) => {
      get(libraryTurbinesRefreshAtom);
      if (!nodeId) return [];
      try {
        const allTurbineResourcesOnNode =
          await getTurbineResourcesOnNode(nodeId);
        const uniqueTurbineResourcesOnNode = allTurbineResourcesOnNode.filter(
          (tr, i) =>
            allTurbineResourcesOnNode.findIndex(
              (t) => t.turbine.id === tr.turbine.id,
            ) === i,
        );
        return uniqueTurbineResourcesOnNode
          .map((tr) => tr.turbine)
          .filter((t) => !t.archived)
          .sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { sensitivity: "accent" }),
          );
      } catch {
        return [];
      }
    },
});

export const allSimpleTurbineTypesWithLevelSelector = selector<
  SimpleTurbineTypeWithLevel[]
>({
  key: "allSimpleTurbineTypesWithLevelSelector",
  get: ({ get }) => {
    const projectId = get(projectIdSelector);
    const defaults = get(defaultSimpleTurbinesAtom);

    const projectTurbines = get(
      projectTurbineTypesSelectorFamily({ nodeId: projectId }),
    );

    const libraryTurbines = get(
      libraryTurbinesOnNodeState({ nodeId: projectId }),
    );

    return [
      ...defaults.map((t) => ({
        level: _TurbineLevel.Values.project,
        turbine: t,
      })),
      ...projectTurbines.map((t) => ({
        level: _TurbineLevel.Values.project,
        turbine: t,
      })),
      ...libraryTurbines.map((t) => ({
        level: _TurbineLevel.Values.library,
        turbine: t,
      })),
    ];
  },
});

export const allSimpleTurbineTypesSelector = selector<SimpleTurbineType[]>({
  key: "allSimpleTurbineTypesSelector",
  get: ({ get }) => {
    const turbinesWithLevel = get(allSimpleTurbineTypesWithLevelSelector);
    return turbinesWithLevel.map((t) => t.turbine);
  },
});

export const currentTurbineIdAtom = atomFamily<string, { projectId?: string }>({
  key: "currentTurbineIdAtom",
  default: "iea_15MW",
});

export const defaultSimpleTurbinesAtom = atom<SimpleTurbineType[]>({
  key: "available-simple-turbines",
  default: DEFAULT_TURBINES.map((t) => ({
    ...t,
    ratedPower: t.ratedPower ?? Math.max(...t.power),
  })).sort((a, b) =>
    a.name.localeCompare(b.name, undefined, { sensitivity: "accent" }),
  ),
});

export const turbineTypeUsageRefresh = atomFamily<
  number,
  { nodeId: string; turbineTypeId: string }
>({
  key: "turbineTypeUsageRefresh",
  default: () => {
    return 0;
  },
});

export async function fetchTurbineTypeUsage(
  nodeId: string,
  turbineTypeId: string,
) {
  const headers = {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "x-project-data-client-version": PROJECT_SERVICE_API_VERSION,
    },
  };
  const res = await fetchEnhancerWithToken(
    `${PROJECT_SERVICE_API_PATH}/stats/node/${nodeId}/turbineTypeId/${turbineTypeId}`,
    headers,
  );
  const j = await res.json();
  return _TurbineTypeUsage.array().parse(j);
}

export const turbineTypeUsageAtomFamily = atomFamily<
  TurbineTypeUsageType[],
  { nodeId: string | undefined; turbineTypeId: string }
>({
  key: "turbineTypeUsageAtomFamily",
  default: selectorFamily<
    TurbineTypeUsageType[],
    { nodeId: string | undefined; turbineTypeId: string }
  >({
    key: "turbineTypeUsageSelectorFamily",
    get:
      ({ nodeId, turbineTypeId }) =>
      ({ get }) => {
        if (!nodeId) return [];
        get(turbineTypeUsageRefresh({ nodeId, turbineTypeId }));
        return fetchTurbineTypeUsage(nodeId, turbineTypeId);
      },
  }),
});

export const getTurbineKeyVersionsSelectorFamily = selectorFamily<
  TurbineTypeKeyEvents,
  { organisationId: string; projectId: string }
>({
  key: "getTurbineKeyVersionsSelectorFamily",
  get:
    ({ organisationId, projectId }) =>
    ({ get }) => {
      const nodeId = get(
        topLevelFolderIdFromOrgIdAndProjectIdSelectorFamily({
          organisationId,
          projectId,
        }),
      );
      return getProjectTurbineKeyVersions(nodeId!);
    },
});

export const turbineOutlineFeature = selectorFamily<
  Feature | undefined,
  { point: CreateSerializableParam<Point>; diameter: number }
>({
  key: "turbineOutlineFeature",
  get:
    ({ point, diameter }) =>
    () => {
      return (
        turf.buffer(point, diameter / 2, { units: "meters", steps: 10 }) ??
        undefined
      );
    },
});

export const getInvalidTurbineTypeTurbinesInParkandBranch = selectorFamily<
  TurbineFeature[],
  { parkId: string; branchId: string }
>({
  key: "getInvalidTurbineTypeTurbinesInParkandBranch",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const turbines = get(
        getTurbinesInBranchSelectorFamily({ parkId, branchId }),
      );
      const allTurbineTypes = get(allSimpleTurbineTypesSelector);
      const invalidTurbineTypeTurbines = turbines.filter((t) =>
        allTurbineTypes.every((tt) => tt.id !== t.properties.turbineTypeId),
      );

      return invalidTurbineTypeTurbines;
    },
});

/**
 * Get turbines from the current selection, following these rules:
 *  - Include turbines in the selection,
 *  - Include turbines in all selected sub-areas,
 *  - Include turbines in all selected parks,
 *  - If no turbines are included yet, and there is a parkID in the url,
 *    include all turbines in that park
 */
export const getSelectedTurbines = selector<TurbineFeature[]>({
  key: "getSelectedTurbines",
  get: ({ get }) => {
    const parkId = get(parkIdSelector);
    if (!parkId) return [];

    const turbinesInPark = get(getTurbinesSelectorFamily({ parkId }));
    const area = get(getSelectedAreaStrict);
    if (!area) {
      const selected = get(currentSelectedProjectFeatures);
      if (selected.length === 0) return turbinesInPark;
      return selected.filter(isTurbine).filter(inPark(parkId));
    }

    if (Array.isArray(area)) {
      const subareaTurbines = turbinesInPark.filter((t) =>
        area.some((a) => pointInPolygon(t.geometry, a.geometry)),
      );
      const selected = get(currentSelectedProjectFeatures)
        .filter(isTurbine)
        .filter(inPark(parkId));
      return dedup(subareaTurbines.concat(selected), (f) => f.id);
    }

    return turbinesInPark;
  },
});

export const getSelectedTurbineTypes = selector<SimpleTurbineType[]>({
  key: "getSelectedTurbineTypes",
  get: ({ get }) => {
    const turbines = get(getSelectedTurbines);
    const typeIds = new Set(turbines.map((t) => t.properties.turbineTypeId));
    const turbineTypes = get(allSimpleTurbineTypesSelector);
    return turbineTypes.filter((tt) => typeIds.has(tt.id));
  },
});

export const getMostSelectedOrDefaultTurbineType = selector<SimpleTurbineType>({
  key: "getMostSelectedOrDefaultTurbineType",
  get: ({ get }) => {
    const turbines = get(getSelectedTurbines);
    const turbineTypes = get(allSimpleTurbineTypesSelector);

    const turbineTypesIdCounts = count(
      turbines.map((t) => t.properties.turbineTypeId),
    );

    const max = Math.max(...turbineTypesIdCounts.values());
    const id = [...turbineTypesIdCounts.entries()].find(
      ([, count]) => count === max,
    )?.[0];

    return (
      turbineTypes.find((tt) => tt.id === id) ??
      (DEFAULT_TURBINES[0] as SimpleTurbineType)
    );
  },
});
