import Spinner from "@icons/spinner/Spinner";
import Button from "components/General/Button";
import useBooleanState from "hooks/useBooleanState";
import { Suspense } from "react";
import InviteToOrganisationFrame from "./InviteToOrganisationFrame";

export default function InviteToOrganisationButton({
  buttonType = "secondary",
}: {
  buttonType: "primary" | "secondary";
}) {
  const [inviteOrganisationOpen, toggleInviteOrganisationOpen] =
    useBooleanState(false);

  return (
    <div style={{ position: "relative" }}>
      <Suspense
        fallback={
          <Button
            text="Invite"
            disabled={true}
            icon={<Spinner size="1rem" />}
            buttonType={buttonType}
          />
        }
      >
        <Button
          text="Invite"
          onClick={toggleInviteOrganisationOpen}
          buttonType={buttonType}
        />
        {inviteOrganisationOpen && (
          <InviteToOrganisationFrame onClose={toggleInviteOrganisationOpen} />
        )}
      </Suspense>
    </div>
  );
}
