import { useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import { ProjectFeature } from "types/feature";
import {
  getPopupPlacement,
  getPopupPlacementMultiFeature,
} from "components/CanvasSelectOption/Canvas.style";
import { getTopCenter } from "utils/turf";
import { getCenterOfFeatures } from "utils/geometry";
import usePrevious from "hooks/usePrevious";

const getDefaultPosition = (selectedProjectFeatures: ProjectFeature[]) => {
  return selectedProjectFeatures.length === 1
    ? getPopupPlacement(getTopCenter(selectedProjectFeatures[0]))
    : getPopupPlacementMultiFeature(
        getCenterOfFeatures(selectedProjectFeatures),
      );
};

const useMoveablePopup = (
  selectedProjectFeatures: ProjectFeature[],
  map?: mapboxgl.Map,
) => {
  const [mouseDownPosition, setMouseDownPosition] = useState<
    { x: number; y: number } | undefined
  >(undefined);
  const [startPosition, setStartPosition] = useState(
    getDefaultPosition(selectedProjectFeatures),
  );
  const [hasMoved, setHasMoved] = useState(false);
  const [popupPlacement, setPopupPlacement] = useState(startPosition);
  const previousSelectedProjectFeatures = usePrevious(selectedProjectFeatures);

  // Reset position if its new features that are selected
  useEffect(() => {
    const featureIds = selectedProjectFeatures.map((feature) => feature.id);
    const previousSelectedProjectFeatureIds =
      previousSelectedProjectFeatures?.map((feature) => feature.id) ?? [];

    if (
      hasMoved &&
      previousSelectedProjectFeatureIds.some((id) =>
        featureIds.find((featureId) => featureId === id),
      )
    ) {
      return;
    }

    const pos = getDefaultPosition(selectedProjectFeatures);
    setPopupPlacement(pos);
    setStartPosition(pos);
  }, [hasMoved, previousSelectedProjectFeatures, selectedProjectFeatures]);

  useEffect(() => {
    if (!mouseDownPosition || !map) return;

    const onMouseMove = (e: MouseEvent) => {
      const project = map.project(startPosition);
      project.x = project.x + (e.clientX - mouseDownPosition.x);
      project.y = project.y + (e.clientY - mouseDownPosition.y);
      setPopupPlacement(map.unproject(project));
    };

    const onMouseUp = (_e: MouseEvent) => {
      setMouseDownPosition(undefined);
      setStartPosition(popupPlacement);
      setHasMoved(true);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [map, mouseDownPosition, popupPlacement, startPosition]);

  return {
    popupPlacement,
    isMoving: !!mouseDownPosition,
    setMouseDownPosition,
  };
};

export default useMoveablePopup;
