import { ABLY_SNAPSHOT_DELETE, ABLY_SNAPSHOT_UPDATE } from "./../state/ably";
import {
  branchSnapshotsAtomFamily,
  useUpdateSnapshot,
} from "./../state/timeline";
import { _SnapshotDeleteMessage, _SnapshotMeta } from "../types/api";
import { useCallback, useMemo } from "react";
import { Types } from "ably";
import { useAblyGeneric } from "./useAblyGeneric";
import { useRecoilCallback } from "recoil";

export function useAblySnapshot(projectId: string) {
  const { putLocal } = useUpdateSnapshot();

  const channelName = useMemo(() => `${projectId}:all`, [projectId]);

  const getSnapShot = useRecoilCallback(
    ({ snapshot }) =>
      async (branchId: string, snapshotId: string) => {
        const snapshots = await snapshot.getPromise(
          branchSnapshotsAtomFamily({ projectId, branchId }),
        );
        return snapshots.find((ss) => ss.id === snapshotId);
      },
    [projectId],
  );

  const onMessageReceived = useCallback(
    (message: Types.Message) => {
      const meta = _SnapshotMeta.parse(message.data.meta);
      putLocal(meta, projectId);
    },
    [putLocal, projectId],
  );

  const onMessageReceivedDelete = useCallback(
    async (message: Types.Message) => {
      const meta = _SnapshotDeleteMessage.parse(message.data.meta);
      const snapshot = await getSnapShot(meta.branchId, meta.snapshotId);
      if (!snapshot) return;
      putLocal({ ...snapshot, isArchived: true }, projectId);
    },
    [putLocal, getSnapShot, projectId],
  );

  const events = useMemo(
    () => [
      { eventName: ABLY_SNAPSHOT_UPDATE, onMessageReceived },
      {
        eventName: ABLY_SNAPSHOT_DELETE,
        onMessageReceived: onMessageReceivedDelete,
      },
    ],
    [onMessageReceived, onMessageReceivedDelete],
  );

  useAblyGeneric(channelName, events);
}
