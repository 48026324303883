import { organisationIdSelector } from "state/pathParams";
import { getAblyToken } from "./../services/ablyService";
import {
  ErrorBoundaryWrapper,
  SilentError,
} from "./../components/ErrorBoundaries/ErrorBoundaryLocal";
import { branchIdSelector, projectIdSelector } from "./../state/pathParams";
import Ably, { Types } from "ably";
import { useCallback, useEffect, useMemo, useRef } from "react";
import {
  atom,
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { ablyLoadedState, projectPresenceAtomFamily } from "../state/ably";
import { isInChecklyMode } from "../utils/utils";
import { validationWarningsOrganisationAtom } from "../state/validationWarnings";
import { z } from "zod";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";

function FatalAblyLostErrorBoundaryWrapper() {
  const organisationId = useRecoilValue(organisationIdSelector);
  const setValidationWarning = useSetRecoilState(
    validationWarningsOrganisationAtom({
      organisationId: organisationId ?? "",
    }),
  );

  useEffect(() => {
    setValidationWarning((vw) =>
      vw
        .filter((v) => v.type !== ValidationWarningTypes.FatalAblyLost)
        .concat([
          {
            type: ValidationWarningTypes.FatalAblyLost,
          },
        ]),
    );

    return () => {
      setValidationWarning((vw) =>
        vw.filter((v) => v.type !== ValidationWarningTypes.FatalAblyLost),
      );
    };
  }, [setValidationWarning]);

  return null;
}

const AblyConnect = ErrorBoundaryWrapper(
  ({ organisationId }: { organisationId: string }) => {
    const setAblyLoaded = useSetRecoilState(ablyLoadedState);
    const checkly = useMemo(() => isInChecklyMode(), []);

    const setValidationWarning = useSetRecoilState(
      validationWarningsOrganisationAtom({ organisationId }),
    );

    useEffect(() => {
      if (checkly) return;
      const client = new Ably.Realtime({
        authCallback: async (_, callback) => {
          try {
            const res = await getAblyToken(organisationId);
            if (res !== null) {
              const parsed = await JSON.parse(res);
              callback(null, z.any().parse(parsed));
            } else {
              callback(
                {
                  code: 40142,
                  statusCode: 500,
                  message: "Token expired",
                } as Types.ErrorInfo,
                null,
              );
            }
          } catch (e) {
            if (e instanceof Error) callback(e.message, null);
          }
        },
      });

      const onStateChange = (stateChange: Types.ConnectionStateChange) => {
        if (stateChange.current === "connected") {
          setAblyLoaded(client);
          setValidationWarning((vw) =>
            vw.filter((v) => v.type !== ValidationWarningTypes.FatalAblyLost),
          );
        } else if (stateChange.current !== "connecting") {
          //The timer avoids leaving the page triggers the warning
          let run = true;
          const timerId = setTimeout(() => {
            if (!run) return;
            setValidationWarning((vw) =>
              vw
                .filter((v) => v.type !== ValidationWarningTypes.FatalAblyLost)
                .concat([
                  {
                    type: ValidationWarningTypes.FatalAblyLost,
                    retryTimestamp: stateChange.retryIn
                      ? stateChange.retryIn + new Date().getTime()
                      : undefined,
                  },
                ]),
            );
          }, 1000);

          setAblyLoaded(undefined);
          return () => {
            run = false;
            clearTimeout(timerId);
          };
        }
      };

      client.connection.on(onStateChange);

      return () => {
        setAblyLoaded(undefined);
        client.connection.off(onStateChange);
        client.close();
        setValidationWarning((vw) =>
          vw.filter((v) => v.type !== ValidationWarningTypes.FatalAblyLost),
        );
      };
    }, [checkly, setAblyLoaded, setValidationWarning, organisationId]);

    return null;
  },
  FatalAblyLostErrorBoundaryWrapper,
  SilentError,
);

export function useUpdatePresence(projectId: string) {
  const ablyLoaded = useRecoilValue(ablyLoadedState);

  const setProjectPresence = useRecoilCallback(
    ({ set }) =>
      async (nodeId: string, data: Types.PresenceMessage[]) => {
        set(
          projectPresenceAtomFamily({
            nodeId,
          }),
          data,
        );
      },
    [],
  );

  const updatePresence = useCallback(
    async (
      channelName: string,
      save: (data: Types.PresenceMessage[]) => void,
    ) => {
      if (!ablyLoaded) return;
      const channel = ablyLoaded.channels.get(channelName);
      channel.presence.get((err, data) => save(data ?? []));
    },
    [ablyLoaded],
  );

  const onMount = useCallback(
    async (
      channelName: string,
      save: (data: Types.PresenceMessage[]) => void,
    ) => {
      if (!ablyLoaded) return;
      const channel = ablyLoaded.channels.get(channelName);
      channel.presence.subscribe("enter", () =>
        updatePresence(channelName, save),
      );
      channel.presence.subscribe("leave", () =>
        updatePresence(channelName, save),
      );
      channel.presence.subscribe("update", () =>
        updatePresence(channelName, save),
      );

      channel.presence.get((err, data) => save(data ?? []));
    },
    [ablyLoaded, updatePresence],
  );

  const onUnmount = useCallback(
    (channelName: string) => {
      if (!ablyLoaded || !ablyLoaded) return;
      const channel = ablyLoaded.channels.get(channelName);
      channel.presence.unsubscribe("enter");
      channel.presence.unsubscribe("leave");
      channel.presence.unsubscribe("update");
    },
    [ablyLoaded],
  );

  useEffect(() => {
    if (!ablyLoaded || !projectId) return;

    onMount(`${projectId}:all`, (data) => setProjectPresence(projectId, data));
    return () => {
      onUnmount(`${projectId}:all`);
    };
  }, [ablyLoaded, onMount, onUnmount, setProjectPresence, projectId]);
}

const TWO_MINUTES = 60 * 2 * 1000;
const IDLE_TIME = TWO_MINUTES;

export const presenceIdleState = atom({
  key: "presenceIdleState",
  default: false,
});

export function usePresenceEnter() {
  const projectId = useRecoilValue(projectIdSelector);
  const branchId = useRecoilValue(branchIdSelector);
  const ablyLoaded = useRecoilValue(ablyLoadedState);

  const projectChannelName = useMemo(
    () => (projectId ? `${projectId}:all` : null),
    [projectId],
  );

  useEffect(() => {
    if (!ablyLoaded || !projectChannelName) return;
    const channel = ablyLoaded.channels.get(projectChannelName);
    channel.presence.enter({ status: "active", branchId: branchId });
    return () => {
      channel.presence.leave();
    };
  }, [ablyLoaded, branchId, projectChannelName]);

  const [idle, setIdle] = useRecoilState(presenceIdleState);
  useEffect(() => {
    if (!ablyLoaded) return;

    if (projectChannelName) {
      const projectChannel = ablyLoaded.channels.get(projectChannelName);
      projectChannel.presence.update({
        status: idle ? "idle" : "active",
        branchId: branchId,
      });
    }
  }, [ablyLoaded, branchId, idle, projectChannelName]);

  const activity = useRef<{
    curr: number;
    last: number;
  }>({ curr: 0, last: 0 });
  useEffect(() => {
    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];

    const actionHappened = () => {
      // If the counters are different, we're already not idle, so no need to set it.
      if (activity.current.curr === activity.current.last) setIdle(false);
      activity.current.curr += 1;
    };

    for (let i in events) {
      window.addEventListener(events[i], actionHappened);
    }

    const interval = setInterval(() => {
      const { curr, last } = activity.current;
      // If nothing has happened since last time this interval ran, set idle to true
      if (curr === last) setIdle(true);
      activity.current.last = curr;
    }, IDLE_TIME);

    return () => {
      for (let i in events) {
        window.removeEventListener(events[i], actionHappened);
      }
      clearInterval(interval);
    };
  }, [setIdle]);
}
export default AblyConnect;
