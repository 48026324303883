/// <reference types="vite-plugin-svgr/client" />
import DownloadIcon from "@icons/24/Download.svg?react";
import WindMeasure from "@icons/24/WindMeasure.svg?react";
import Button from "components/General/Button";
import { InputDimensioned } from "components/General/Input";
import { Row } from "components/General/Layout";
import { Slider } from "components/General/Slider";
import Toggle, { ToggleSize } from "components/General/Toggle";
import { nDirectionsWindRoseVisualisation } from "components/ProductionV2/constants";
import { Mixpanel } from "mixpanel";
import { Suspense, useEffect, useState } from "react";
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
} from "recoil";
import { projectIdSelector } from "state/pathParams";
import {
  mostProbableWindDirectionSelector,
  showCableLossesAtom,
  showMeanSpeedGridAtom,
  showWakeAtom,
  showWakeLabelsAtom,
  wakeDirectionAtom,
} from "state/windStatistics";
import { isDefined } from "utils/predicates";
import { downloadText } from "utils/utils";
import {
  ProdId,
  getParkOrSubAreaCenter,
  analysisOverrideInputAtomFamily,
  getConfiguration,
  getAnalysisMeanSpeedGrid,
  getAverageHubHeight,
} from "./state";
import {
  InputTitle,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import { isMeanSpeedGrid, isWRG } from "functions/met";
import Dropdown from "components/Dropdown/Dropdown";
import WRGSpeedGrid from "layers/wrgSpeedGrid";
import SpeedGrid from "layers/speedGrid";

const DirectionalWake = ({ id }: { id: ProdId }) => {
  const [wakeDirection, setWakeDirection] = useRecoilState(wakeDirectionAtom);

  const averageHubHeight = useRecoilValue(getAverageHubHeight(id));

  const center = useRecoilValue(getParkOrSubAreaCenter(id));

  const mwd = useRecoilValueLoadable(
    mostProbableWindDirectionSelector({
      lon: center[0],
      lat: center[1],
      height: averageHubHeight,
    }),
  ).valueMaybe();

  return (
    <>
      <div style={{ display: "flex", gap: "1rem" }}>
        <InputTitle>{`Wind direction`}</InputTitle>
        {isDefined(mwd) && (
          <Button
            icon={<WindMeasure />}
            onClick={() => setWakeDirection(mwd)}
            tooltip="Set to most probable direction (ERA5)"
            buttonType="text"
            text={`${mwd} deg`}
            style={{
              fontSize: "1rem",
              height: "1rem",
              textDecoration: "underline",
            }}
          />
        )}
      </div>
      <Row style={{ alignItems: "center" }}>
        <InputDimensioned
          style={{ flex: 1, minWidth: "12rem" }}
          value={wakeDirection}
          unit={"deg"}
          units={["deg"]}
          onChange={(n) => setWakeDirection(n)}
          validate={(n) => 0 <= n && n <= 360}
          compact
        />
        <Slider
          min={0}
          max={360}
          step={360 / nDirectionsWindRoseVisualisation}
          value={wakeDirection}
          onChange={(n) => setWakeDirection(n)}
        />
      </Row>
    </>
  );
};

export const Visualizations = ({ id }: { id: ProdId }) => {
  const [showWake, setShowWake] = useRecoilState(showWakeAtom);
  const [showCableLoss, setShowCableLoss] = useRecoilState(showCableLossesAtom);
  const [showMeanSpeedGrid, setShowMeanSpeedGrid] = useRecoilState(
    showMeanSpeedGridAtom,
  );
  const [wrgSectorIndex, setWrgSectorIndex] = useState(0);
  const [showWakeLabels, setShowWakeLabels] =
    useRecoilState(showWakeLabelsAtom);
  const data = useRecoilValueLoadable(
    getAnalysisMeanSpeedGrid(id),
  ).valueMaybe();
  const { selectedSubAreas } = useRecoilValue(
    analysisOverrideInputAtomFamily(id),
  );
  const configuration = useRecoilValueLoadable(
    getConfiguration(id),
  ).valueMaybe();
  const hasSelectedZones = (selectedSubAreas?.length ?? 0) !== 0;

  useEffect(() => {
    return () => {
      setShowWake(false);
      setShowWakeLabels(false);
      setShowCableLoss(false);
      setShowMeanSpeedGrid(false);
    };
  }, [setShowWake, setShowWakeLabels, setShowCableLoss, setShowMeanSpeedGrid]);

  const downloadGrid = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const projectId = await snapshot.getPromise(projectIdSelector);
        if (!isMeanSpeedGrid(data)) return;

        const grid = data.grid;
        const n = grid.length;
        const content =
          `ncols ${data.ncols}\n` +
          `nrows ${data.nrows}\n` +
          `xllcorner ${data.xllcorner}\n` +
          `yllcorner ${data.yllcorner}\n` +
          `dx ${data.dx}\n` +
          `dy ${data.dy}\n` +
          `NODATA_value -999\n` +
          grid.map((_, i) => grid[n - i - 1].join(" ")).join("\n");
        downloadText(content, `speedgrid_${data.height}m.asc`);
        Mixpanel.track("Downloaded mean speed grid", { projectId });
      },
    [data],
  );

  return (
    <>
      <SubtitleWithLine text={"Visualisations"} />

      <Row>
        <Toggle
          checked={showWakeLabels}
          onChange={() => {
            setShowMeanSpeedGrid(false);
            setShowWakeLabels(!showWakeLabels);
          }}
          size={ToggleSize.SMALL}
        />
        <InputTitle style={{ flex: 1 }}>Wake loss labels</InputTitle>
      </Row>

      <Row>
        <Toggle
          checked={showMeanSpeedGrid}
          onChange={() => {
            setShowWakeLabels(false);
            setShowMeanSpeedGrid(!showMeanSpeedGrid);
          }}
          size={ToggleSize.SMALL}
        />
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <InputTitle>Mean speed</InputTitle>
          {isMeanSpeedGrid(data) && showMeanSpeedGrid && (
            <>
              <Button
                buttonType="secondary"
                size="small"
                onClick={downloadGrid}
                icon={<DownloadIcon />}
              />
              <SpeedGrid meanSpeedGrid={data} />
            </>
          )}
          {isWRG(data) && showMeanSpeedGrid && (
            <>
              <Dropdown
                small
                value={wrgSectorIndex}
                onChange={(e) => setWrgSectorIndex(parseInt(e.target.value))}
              >
                {data.points[0].sectors.map((_, i) => (
                  <option value={i} key={i}>
                    {`${(i * 360) / data.points[0].sectors.length}°`}
                  </option>
                ))}
              </Dropdown>
              <WRGSpeedGrid wrg={data} sectorIndex={wrgSectorIndex} />
            </>
          )}
        </div>
      </Row>

      <Row>
        <Toggle
          checked={showWake}
          onChange={() => setShowWake(!showWake)}
          size={ToggleSize.SMALL}
        />
        <InputTitle>Directional wake</InputTitle>
      </Row>

      {showWake && (
        <Suspense fallback={"loading"}>
          <DirectionalWake id={id} />
        </Suspense>
      )}

      {!hasSelectedZones && (
        <Row>
          <Toggle
            disabled={!configuration?.electrical.interArrayCableLoss}
            checked={showCableLoss}
            onChange={() => setShowCableLoss(!showCableLoss)}
            size={ToggleSize.SMALL}
          />
          <InputTitle disabled={!configuration?.electrical.interArrayCableLoss}>
            Annual cable losses
          </InputTitle>
          <HelpTooltip
            text={
              "Inter-array cable loss is disabled in the current analysis configuration."
            }
            size={10}
          ></HelpTooltip>
        </Row>
      )}
    </>
  );
};
