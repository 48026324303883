/// <reference types="vite-plugin-svgr/client" />
import React, { forwardRef } from "react";
import Close from "@icons/24/Close.svg?react";
import { Comp } from "../../types/utils";
import { Frame } from "../General/Layout";
import styled from "styled-components";
import { IconBtn } from "../General/Icons";
import { spaceLarge, spaceMedium } from "../../styles/space";
import { colors } from "styles/colors";
import { typography } from "styles/typography";

export const LeftMenuPositionWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: calc(var(--side-bars-width) + ${spaceMedium});
  height: 100%;
  pointer-events: none;
  > * {
    pointer-events: initial;
  }
`;

export const RightMenuPositionWrapper = styled.div<{
  lowerRightModeOpen: boolean;
}>`
  display: flex;
  height: ${(p) =>
    p.lowerRightModeOpen ? "calc(100vh - 40rem)" : "fit-content"};
  position: absolute;
  right: calc(var(--side-bars-width) + ${spaceMedium});
  top: 0;
  pointer-events: none;
  > * {
    pointer-events: initial;
  }
`;

export const BottomMenuPositionWrapper = styled.div<{ parkSelected?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: calc(var(--side-bars-width) + ${spaceMedium});
  right: ${(p) =>
    p.parkSelected ? `calc(var(--side-bars-width) + ${spaceMedium})` : "0"};
  transition: right 0.3s ease-in-out;
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${spaceLarge} ${spaceLarge} ${spaceLarge};
`;

const HeaderTitle = styled.h3`
  margin: 0;
`;

const HeaderSubtitle = styled.div`
  ${typography.sub2}
  color: ${colors.textDisabled};
  margin: 0;
`;

type MenuFrameProps = {
  title: string;
  subtitle?: string;
  icon?: JSX.Element;
  validationError?: JSX.Element;
  onExit?: React.MouseEventHandler<HTMLElement>;
  headerId?: string;
  isLoading?: boolean;
  blockWhenLoading?: boolean;
  headerStyle?: React.CSSProperties;
};

const LoadingBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  width: 100%;
  background-color: ${colors.indigo100};
  height: 5px;
`;

const Loader = styled.div`
  width: 50%;
  position: relative;
  height: 5px;
  top: 0;
  animation: loading 2s ease-in-out infinite;
  background-color: ${colors.indigo600};
  border-radius: 5px;

  @keyframes loading {
    0% {
      left: -50%;
    }
    75% {
      left: 100%;
    }
    100% {
      left: 100%;
    }
  }
`;

const Loading = ({ block }: { block: boolean }) => {
  if (!block) {
    return (
      <LoadingBar>
        <Loader />
      </LoadingBar>
    );
  }

  return (
    <div
      style={{
        position: "absolute",
        zIndex: 1,
        top: 0,
        left: 0,
        padding: 0,
        height: "100%",
        width: "100%",
        backgroundColor: `${colors.white}aa`,
      }}
    >
      <LoadingBar>
        <Loader />
      </LoadingBar>
    </div>
  );
};

export const MenuFrame = forwardRef<
  HTMLDivElement,
  Comp<"div", MenuFrameProps>
>(
  (
    {
      title,
      subtitle,
      icon,
      onExit,
      isLoading,
      validationError,
      style,
      headerStyle,
      children,
      headerId,
      blockWhenLoading = true,
      id,
    },
    ref,
  ) => (
    <Frame style={style} ref={ref} id={id}>
      {isLoading && <Loading block={blockWhenLoading} />}
      <HeaderWrapper style={headerStyle}>
        <HeaderRow>
          <HeaderTitle id={headerId}>
            {title}
            {validationError}
            {icon}
          </HeaderTitle>
          {onExit && (
            <>
              <IconBtn size="1.4rem" onClick={onExit}>
                <Close />
              </IconBtn>
            </>
          )}
        </HeaderRow>
        <HeaderSubtitle>{subtitle}</HeaderSubtitle>
      </HeaderWrapper>
      {children}
    </Frame>
  ),
);
