import { ReactNode, Suspense, useMemo, useRef, useState } from "react";
import { useRecoilValueLoadable } from "recoil";
import styled from "styled-components";
import { useTypedPath } from "../../../state/pathParams";
import { StandardBox } from "../../../styles/boxes/Boxes";
import { colors, hexToRgbA } from "../../../styles/colors";
import { getAuthIdFromMention, isMention } from "./TextareaWithMentions";
import UserInfo, { UserInfoLoading } from "./UserInfo";
import { nodeGroupUserAccessSelector } from "components/Organisation/Groups/state";
import {
  UserNodeAccessWithMeta,
  isUserNodeAccessWithMeta,
} from "components/Organisation/Groups/types";

const Wrapper = styled.div`
  display: inline-block;
  gap: 0.4rem;
  width: 84%;
  word-wrap: break-word;
  line-height: 1.2rem;
  > p {
    display: inline;
  }
  > div {
    display: inline-block;
  }
`;

const Text = styled.p`
  margin: 0;
`;

export default function TextWithMentions({ message }: { message: string }) {
  const { organisationId, projectId } = useTypedPath(
    "organisationId",
    "projectId",
  );
  const content = useMemo(() => {
    const words = message.split(" ");

    const components: ReactNode[] = [];

    let tempString = "";

    // split words to look for mentions
    words.forEach((word, wordIndex) => {
      if (!organisationId) return null;
      if (isMention(word)) {
        if (tempString.length > 0) {
          // split string to add line breaks
          tempString.split("\n").forEach((t, lineBreakIndex, all) => {
            components.push(
              <Text key={tempString + components.toString()}>{t}</Text>,
            );
            if (all.length > 1 && lineBreakIndex !== all.length - 1) {
              components.push(
                <br key={"break-" + wordIndex + lineBreakIndex} />,
              );
            }
          });

          tempString = "";
        }
        components.push(
          <MentionWrapper
            projectId={projectId}
            organisationId={organisationId}
            key={word + wordIndex}
            userId={getAuthIdFromMention(word)}
          />,
        );
      } else {
        tempString = tempString + ` ${word}`;
      }
    });

    if (tempString.length > 0) {
      // split string to add line breaks
      tempString.split("\n").forEach((t, lineBreakIndex, all) => {
        components.push(<p key={tempString + components.toString()}>{t}</p>);
        if (all.length > 1 && lineBreakIndex !== all.length - 1) {
          components.push(<br key={"break-" + lineBreakIndex} />);
        }
      });
      tempString = "";
    }
    return components;
  }, [message, organisationId, projectId]);

  return <Wrapper>{content}</Wrapper>;
}

const MentionContainer = styled.div`
  cursor: pointer;
  background-color: ${hexToRgbA(colors.hoverSelected, 0.2)};
  padding: 0.2rem 0.4rem;
  border-radius: 0.4rem;
  display: flex;
  position: relative;
  width: fit-content;
  margin: 0 0.4rem;
`;

const Name = styled.p`
  margin: 0;
  text-transform: capitalize;
`;

function MentionWrapper({
  userId,
  organisationId,
  projectId,
}: {
  userId: string;
  organisationId: string;
  projectId: string;
}) {
  const users = useRecoilValueLoadable(
    nodeGroupUserAccessSelector({ organisationId, nodeId: projectId }),
  ).valueMaybe();

  const user = users
    ?.filter(isUserNodeAccessWithMeta)
    .find((u: UserNodeAccessWithMeta) => u.user_id === userId);

  const [showMoreInfo, setShowMoreInfo] = useState(false);

  const hoverTimeout = useRef<NodeJS.Timeout | string | number | undefined>();

  if (!user) return <Name>Unknown user</Name>;

  return (
    <MentionContainer
      onMouseEnter={() => {
        setShowMoreInfo(true);
        clearTimeout(hoverTimeout.current);
      }}
      onMouseLeave={() => {
        hoverTimeout.current = setTimeout(() => {
          setShowMoreInfo(false);
        }, 80);
      }}
    >
      <Name>{user.nickname}</Name>
      {showMoreInfo && <MentionMoreInfo userId={userId} />}
    </MentionContainer>
  );
}

const Column = styled(StandardBox)`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  padding: 0.4rem;
  position: absolute;
  left: -50%;
  bottom: 120%;
  z-index: 1;
`;

function MentionMoreInfo({ userId }: { userId: string }) {
  return (
    <Column>
      <Suspense fallback={<UserInfoLoading />}>
        <UserInfo userId={userId} />
      </Suspense>
    </Column>
  );
}
