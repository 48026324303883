import { selectorFamily } from "recoil";
import { CostType } from "../../../../services/costService";
import { libraryAndProjectCostConfigurationsSelectorFamily } from "../../../../state/costConfigurations";
import {
  _CostUnit,
  CostUnit,
  unitToAmountUnit,
} from "../../../../types/financial";
import { Cost, costId } from "../capexNumbers";
import { amountId } from "../generalAmounts";
import { allCableTypesInParkSelectorFamily } from "./cableAmounts";

export const cableCapexEntriesSelectorFamily = selectorFamily<
  Cost[],
  {
    projectId: string;
    branchId: string;
    parkId: string;
    configurationId: string;
  }
>({
  key: "cableCapexEntriesSelectorFamily",
  get:
    ({ projectId, branchId, parkId, configurationId }) =>
    ({ get }) => {
      const configurations = get(
        libraryAndProjectCostConfigurationsSelectorFamily({
          nodeId: projectId,
        }),
      );

      const { cableTypes } = get(
        allCableTypesInParkSelectorFamily({ branchId, parkId }),
      );

      const configuration = configurations.find(
        (c) => c.id === configurationId,
      );

      if (!configuration) return [];

      const {
        capex: { custom, fixed },
      } = configuration;

      const flatCosts: Cost[] = custom
        .filter((c) => c.category === CostType.Cable)
        .filter((c) => c.unit === CostUnit.millionEuro)
        .map((c) => ({
          ...c,
          id: costId({
            category: CostType.Cable,
            costId: c.id,
          }),
          amountId: amountId({
            unit: unitToAmountUnit[c.unit],
            category: c.category,
          }),
        }));

      const customCosts: Cost[] = custom
        .filter((c) => c.category === CostType.Cable)
        .filter((c) => c.unit !== CostUnit.millionEuro)
        .flatMap((custom) => ({
          ...custom,
          name: `${custom.name}`,
          id: costId({
            category: CostType.Cable,
            costId: custom.id,
            featureTypeId: CostType.Cable,
          }),
          amountId: amountId({
            unit: unitToAmountUnit[custom.unit],
            category: custom.category,
          }),
        }));

      let fixedCosts: Cost[] = [];
      if (fixed.cables !== undefined) {
        const { cost, unit, confidenceLevel } = fixed.cables;

        fixedCosts = [
          {
            id: `cables_${unit}`,
            amountId: amountId({
              unit: unitToAmountUnit[unit],
              category: CostType.Cable,
            }),
            category: CostType.Cable,
            name: "Inter array cables",
            cost: cost,
            unit: unit,
            confidenceLevel: confidenceLevel,
          },
        ];
      } else {
        fixedCosts = cableTypes.map(
          ({ id, name, cost, costUnit: _costUnit }) => {
            const costUnit = _CostUnit.parse(_costUnit);
            return {
              id: `cables_${id}`,
              amountId: amountId({
                unit: unitToAmountUnit[costUnit],
                category: CostType.Cable,
                featureTypeId: id,
              }),
              category: CostType.Cable,
              name,
              cost: cost ?? 0,
              unit: costUnit,
              confidenceLevel: undefined,
            };
          },
        );
      }
      return [...fixedCosts, ...customCosts, ...flatCosts];
    },
});
