import styled from "styled-components";
import { colors } from "styles/colors";
import { typography } from "styles/typography";

export const ElementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

export const SubHeader = styled.div`
  ${typography.sub2}
  color: ${colors.textDisabled};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  width: fit-content;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;
