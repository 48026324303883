import { atom, atomFamily } from "recoil";
import Ably, { Types } from "ably";

export const ablyLoadedState = atom<Ably.Realtime | undefined>({
  key: "ablyState",
  default: undefined,
  dangerouslyAllowMutability: true,
});

export const projectPresenceAtomFamily = atomFamily<
  Types.PresenceMessage[],
  { nodeId: string }
>({
  key: "projectPresenceAtomFamily",
  default: () => [],
});

export const ABLY_RENAME_TEAM = "customer_rename_team";
export const ABLY_CREATE_NODE = "customer_create_node";
export const ABLY_REMOVE_NODE = "customer_remove_node";
export const ABLY_MOVE_NODE = "customer_mode_node";
export const ABLY_UPDATE_NODE = "customer_update_node";
export const ABLY_CREATE_CHILD_NODE = "create_child_node";
export const ABLY_ADD_REPLIES_FILTER = "replies_add";
export const ABLY_REMOVE_REPLIES_FILTER = "replies_remove";
export const ABLY_THREADS_ADD_FILTER = "threads_add";
export const ABLY_THREADS_REMOVE_FILTER = "threads_remove";
export const ABLY_THREAD_REACTIONS_ADD_FILTER = "thread_reactions_add";
export const ABLY_THREAD_REACTIONS_REMOVE_FILTER = "thread_reactions_remove";
export const ABLY_REPLY_REACTIONS_ADD_FILTER = "reply_reactions_add";
export const ABLY_REPLY_REACTIONS_REMOVE_FILTER = "reply_reactions_remove";
export const ABLY_RESOLVED_TOGGLE_FILTER = "thread_resolved_toggle";
export const ABLY_PROJECT_ELEMENTS = "project_elements_updates";
export const ABLY_PROJECT_ELEMENT_CREATE_FOLDER =
  "project_elements_create_folder";
export const ABLY_PROJECT_ELEMENT_DELETE_FOLDER =
  "project_elements_delete_folder";
export const ABLY_PROJECT_ELEMENT_UPDATE_FOLDER =
  "project_elements_update_folder";
export const ABLY_PROJECT_ELEMENT_SORT_ORDER = "project_elements_sort_order";
export const ABLY_CURSOR_POSITION = "cursor_position";
export const ABLY_FEATURE_SELECTION = "feature_selection";
export const ABLY_PROJECT_UPDATE = "project_update";
export const ABLY_PROJECT_DELETE = "project_delete";
export const ABLY_BRANCH_UPDATE = "branch_update";
export const ABLY_BRANCH_DELETE = "branch_delete";
export const ABLY_BRANCH_SORT = "branch_sort";
export const ABLY_BRANCH_LCOE_COST_UPDATE = "lcoe_cost_update";
export const ABLY_SNAPSHOT_UPDATE = "snapshot_update";
export const ABLY_SNAPSHOT_DELETE = "snapshot_delete";
export const ABLY_EXPERIMENT_UPDATE = "experiment_update";
export const ABLY_ALTERNATIVE_UPDATE = "alternative_update";
export const ABLY_COLLECTION_UPDATE = "collection_update";
export const ABLY_COLLECTION_DELETE = "collection_delete";
export const ABLY_LAYER_UPDATE = "layer_update";
export const ABLY_LAYER_DELETE = "layer_delete";
export const ABLY_CONFIGURATION_UPDATE = "configuration_update";
export const ABLY_UPLOAD_EXTERNAL_LAYER = "upload_custom_layer_service";
export const ABLY_BATHYMETRY_INCLUDING_CUSTOM =
  "bathymetry_including_custom_service";
export const ABLY_SLOPE_INCLUDING_CUSTOM = "slope_including_custom_service";
export const ABLY_DUPLICATE_PROJECT = "duplicate_project";
export const ABLY_TIMELINE_UPDATE = "new_timeline_event";
export const ABLY_WAKE_ANALYSIS_RESULT = "wake_analysis_result";
export const ABLY_COST_CONFIG_UPDATE = "cost_config_update";
export const ABLY_REMOVE_RESOURCE = "customer_remove_resource";
export const ABLY_MIGRATING_RESOURCE = "customer_migrating_resource";
export const ABLY_PORTFOLIO_PARK_ADDED = "portfolio-park-added";
export const ABLY_PORTFOLIO_PARK_REMOVED = "portfolio-park-removed";
export const ABLY_CHANGELOG_UPDATE = "new_changelog_event";
export const ABLY_ORGANISATION_DATA_PACKAGE_CREATE =
  "organisation_data_package_create";
export const ABLY_ORGANISATION_DATA_PACKAGE_UPDATE =
  "organisation_data_package_update";
export const ABLY_ORGANISATION_DATA_PACKAGE_DELETE =
  "organisation_data_package_delete";
export const ABLY_UPLOAD_CUSTOM_LAYER_CHANNEL_NAME =
  "upload_custom_layer_service";
export const ABLY_UPLOAD_CUSTOM_ORGANISATION_LAYER_CHANNEL_NAME =
  "upload_organisation_custom_layer_service";

export const ABLY_CREATE_ARCHIVED_VERSION = "create_archived_version";
export const ABLY_DELETE_ARCHIVED_VERSION = "delete_archived_version";
