/// <reference types="vite-plugin-svgr/client" />
import Button from "components/General/Button";
import Fuse from "fuse.js";
import { Input } from "components/General/Input";
import { Row } from "components/General/Layout";
import { usersInOrganisationState } from "components/Organisation/state";
import Close from "@icons/24/Close.svg?react";
import Add from "@icons/24/Add.svg?react";
import useTextInput from "hooks/useTextInput";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useTypedPath } from "state/pathParams";
import { OrganisationUser } from "types/customer";
import { GroupMembership } from "components/Organisation/Groups/types";
import { IconBtn } from "components/General/Icons";
import {
  ContentTableColumn,
  ContentTableRow,
  Divider,
  ResultContainer,
  SearchAndSelectContainer,
  SecondaryText,
  TextEllipsis,
} from "../style";
import SelectedLabel from "components/General/SelectedLabel";
import UserImageRound from "components/UserImage/UserImageRound";

export default function GroupMemberModal({
  members,
  onSave,
}: {
  members: GroupMembership[];
  onSave: (users: OrganisationUser[]) => void;
}) {
  const { organisationId } = useTypedPath("organisationId");
  const usersInOrg = useRecoilValue(usersInOrganisationState(organisationId));

  const [name, onNameChange] = useTextInput("");
  const [selectedUsers, setSelectedUsers] = useState<OrganisationUser[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<OrganisationUser[]>([]);

  useEffect(() => {
    const fuse = new Fuse(usersInOrg, {
      keys: ["nickname", "email"],
      includeScore: true,
      threshold: 0.2,
    });

    const results = fuse.search(name).map((result) => result.item);
    setFilteredUsers(name.length > 0 ? results : usersInOrg);
  }, [usersInOrg, name]);

  const toggleUserSelection = (user: OrganisationUser) => {
    setSelectedUsers((prevSelectedUsers) => {
      if (prevSelectedUsers.find((u) => u.user_id === user.user_id)) {
        return prevSelectedUsers.filter((u) => u.user_id !== user.user_id);
      } else {
        return [...prevSelectedUsers, user];
      }
    });
  };

  return (
    <SearchAndSelectContainer>
      <Row
        style={{
          alignItems: "center",
          gap: "0.8rem",
          padding: "1.6rem 1.2rem 0",
        }}
      >
        <Input
          autoFocus
          value={name}
          onChange={onNameChange}
          type="search"
          placeholder={`Search`}
          style={{ width: "100%" }}
        />
        <IconBtn
          size="1.4rem"
          onClick={() => onSave([])}
          style={{ marginLeft: "auto" }}
        >
          <Close />
        </IconBtn>
      </Row>
      <ResultContainer>
        <ContentTableColumn
          style={{
            overflowY: "auto",
            padding: "1.2rem 0",
          }}
        >
          {filteredUsers.map((u) => {
            const selected = selectedUsers.some(
              (su) => su.user_id === u.user_id,
            );
            const isMember = members.some((m) => m.user_id === u.user_id);
            return (
              <ContentTableRow
                key={u.user_id}
                style={{ cursor: "pointer", alignItems: "center" }}
                onClick={() => !isMember && !selected && toggleUserSelection(u)}
                disabled={selected || isMember}
              >
                <UserImageRound size={1.8} user={u} />
                <TextEllipsis style={{ margin: 0 }}>{u.nickname}</TextEllipsis>
                {!isMember && (
                  <SecondaryText style={{ margin: 0, marginRight: "auto" }}>
                    ({u.email})
                  </SecondaryText>
                )}
                {isMember ? (
                  <SecondaryText style={{ marginLeft: "auto" }}>
                    Already a member
                  </SecondaryText>
                ) : selected ? (
                  <SecondaryText style={{ marginLeft: "auto" }}>
                    Selected
                  </SecondaryText>
                ) : (
                  <></>
                )}
                {!isMember && !selected && (
                  <Button
                    buttonType="text"
                    text="Select"
                    icon={<Add />}
                    onClick={() => {}}
                    size="small"
                    style={{ padding: "0.2rem 1.2rem" }}
                  />
                )}
              </ContentTableRow>
            );
          })}
        </ContentTableColumn>
      </ResultContainer>
      {selectedUsers.length > 0 && (
        <>
          <Divider />
          <Row
            style={{
              padding: "1.6rem 1.2rem",
            }}
          >
            <Row style={{ flexWrap: "wrap", flex: 1, gap: "0.6rem" }}>
              {selectedUsers.map((u) => {
                return (
                  <SelectedLabel
                    key={u.user_id}
                    title={u.nickname}
                    onDeselect={() => toggleUserSelection(u)}
                  />
                );
              })}
            </Row>
            <Button
              text="Add"
              onClick={() => onSave(selectedUsers)}
              buttonType="primary"
            />
          </Row>
        </>
      )}
    </SearchAndSelectContainer>
  );
}
