import React, { useCallback, useMemo } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import Fuse from "fuse.js";
import { Mixpanel } from "mixpanel";
import AddIcon from "@icons/24/Add.svg";
import BinIcon from "@icons/24/Bin.svg";
import CoordinatesAltIcon from "@icons/24/CoordinatesAlt.svg";
import UploadIcon from "@icons/24/Upload.svg";
import { Column } from "components/General/Layout";
import Button from "components/General/Button";
import SplitButton from "components/General/SplitButton";
import { VerticalDivider } from "components/General/VerticalDivider";
import { SearchInput } from "components/General/Input";
import { MenuItem } from "components/General/Menu";
import useBooleanState from "hooks/useBooleanState";
import { typography } from "styles/typography";
import { colors } from "styles/colors";
import { spacing1, spacing4 } from "styles/space";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { useRecoilValueDef } from "utils/recoil";
import { organisationIdSelector } from "state/pathParams";
import { modalTypeOpenAtom } from "state/modal";
import { DataLibraryNewEntry } from "../DataLibraryTab.style";
import { UploadOrganisationDataLayerModalType } from "../modals/UploadOrganisationLayerModal";
import { UploadOrganisationLayerFromCoordinatesModalType } from "../modals/UploadOrganisationLayerFromCoordinatesModal";
import { useDataLibraryLayersCrud } from "../useDataLibraryLayersCrud";
import {
  allDataLayerSearchInputAtom,
  organisationLibraryLayersAtomFamily,
  selectedOrganisationLayerIdsSelector,
} from "../state";
import { SelectedLayersWrapper } from "../shared";
import DataTable from "./DataTable";
import AddToPackageButton from "./AddToPackageButton";

const AddDataSplitButton = () => {
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);

  return (
    <SplitButton
      buttonType="primary"
      text="Add"
      icon={<AddIcon />}
      onClick={() => {
        Mixpanel.track("Add library layer click DEFAULT", {});
        setModalTypeOpen({
          modalType: UploadOrganisationDataLayerModalType,
        });
      }}
    >
      <MenuItem
        name="From file"
        defaultButton={true}
        onClick={() => {
          Mixpanel.track("Add library layer click From file", {});
          setModalTypeOpen({
            modalType: UploadOrganisationDataLayerModalType,
          });
        }}
        icon={<UploadIcon />}
      />
      {/* Todo: Uncomment when external layers are addable */}
      {/*<MenuItem
        name="Connect external server"
        onClick={() => {
          Mixpanel.track(
            "Click upload library layer connect external server",
            {},
          );
          setConnectExternalModalOpen(true);
        }}
        icon={<DatabaseIcon />}
      />*/}
      <MenuItem
        name="From coordinates"
        onClick={() => {
          Mixpanel.track("Add library layer click From coordinates", {});
          setModalTypeOpen({
            modalType: UploadOrganisationLayerFromCoordinatesModalType,
          });
        }}
        icon={<CoordinatesAltIcon />}
      />
      {/* Todo: Uncomment when external layers are addable */}
      {/*<MenuItem
        name="From connected server"
        onClick={() => {
          Mixpanel.track(
            "Click upload library layer from connected server",
            {},
          );
        }}
        icon={<GlobeIcon />}
      />*/}
    </SplitButton>
  );
};

const DataSectionInner = ({
  organisationId,
  searchValue,
}: {
  organisationId: string;
  searchValue: string;
}) => {
  const layers = useRecoilValue(
    organisationLibraryLayersAtomFamily({ organisationId }),
  );

  const fuse = useMemo(
    () =>
      new Fuse(layers, {
        keys: ["name", "description"],
        includeScore: true,
        threshold: 0.3,
      }),
    [layers],
  );

  const searchResults = useMemo(() => {
    if (searchValue.length === 0) return layers;
    return fuse.search(searchValue).map((result) => result.item);
  }, [fuse, layers, searchValue]);

  if (layers.length > 0) {
    return <DataTable layers={searchResults} allLayers={layers} />;
  }

  return (
    <div style={{ width: "25rem" }}>
      <DataLibraryNewEntry
        icon={<UploadIcon />}
        title="Upload files"
        text="Uploaded data will appear here"
        actionButton={<AddDataSplitButton />}
      />
    </div>
  );
};

const SearchInputStyler = styled.div`
  cursor: pointer;

  input {
    width: 16px;
    transition: width 0.1s linear;

    &:focus,
    &:not([value=""]) {
      width: 40rem;
    }
  }
`;

const AllDataSection = () => {
  const organisationId = useRecoilValueDef(organisationIdSelector);
  const selectedLayerIds = useRecoilValue(
    selectedOrganisationLayerIdsSelector({ organisationId }),
  );
  const [isInFocus, toggleIsInFocus] = useBooleanState(false);
  const [searchValue, setSearchValue] = useRecoilState(
    allDataLayerSearchInputAtom,
  );
  const { deleteLayers } = useDataLibraryLayersCrud();

  const onDeleteClick = useCallback(() => {
    Mixpanel.track("Delete multiple library layers", {
      nrLayers: selectedLayerIds.length,
    });
    return deleteLayers(selectedLayerIds);
  }, [selectedLayerIds, deleteLayers]);

  return (
    <Column
      style={{
        gap: "0.8rem",
        overflowY: "hidden",
        flexGrow: 1,
        flexShrink: 0,
        height: "100%",
      }}
    >
      <div
        style={{
          height: "6rem",
          display: "flex",
          alignItems: "center",
          paddingLeft: spacing1,
          justifyContent: "space-between",
        }}
      >
        {selectedLayerIds.length > 0 ? (
          <SelectedLayersWrapper>
            <p style={{ ...typography.regular, color: colors.blue700 }}>
              {selectedLayerIds.length} selected
            </p>
            <VerticalDivider />
            <AddToPackageButton
              layerIds={selectedLayerIds}
              buttonText="Add to"
            />
            <VerticalDivider />
            <Button
              buttonType="text"
              onClick={onDeleteClick}
              icon={<BinIcon />}
              text="Delete"
            />
          </SelectedLayersWrapper>
        ) : (
          <AddDataSplitButton />
        )}
        <SearchInputStyler>
          <SearchInput
            placeholder="Search"
            value={searchValue}
            onFocus={toggleIsInFocus}
            onBlur={toggleIsInFocus}
            onChange={(e) => setSearchValue(e.target.value)}
            onClear={() => setSearchValue("")}
            style={{
              ...(!isInFocus &&
                searchValue === "" && {
                  paddingLeft: spacing4,
                }),
            }}
          />
        </SearchInputStyler>
      </div>
      <React.Suspense
        fallback={
          <SkeletonBlock
            style={{
              width: "100%",
              height: "30rem",
            }}
          />
        }
      >
        <DataSectionInner
          organisationId={organisationId}
          searchValue={searchValue}
        />
      </React.Suspense>
    </Column>
  );
};

export default AllDataSection;
