import { atom } from "recoil";

export enum LeftModalNames {
  History = "History",
  Comment = "Comment",
  ProjectElements = "ProjectElements",
}

export const leftModalOpenStateAtom = atom<LeftModalNames | undefined>({
  key: "leftModalOpenStateAtom",
  default: undefined,
});

export const versionHistoryOpenStateAtom = atom<boolean>({
  key: "versionHistoryOpenStateAtom",
  default: false,
});
