import { useCallback } from "react";
import { usingMac } from "utils/utils";

type Command = "command" | "option" | "control" | "shift" | "backspace";

export default function useSystemSpecificUnicode() {
  const isMac = usingMac();

  const stringToUnicode = useCallback(
    (cmd: Command) => {
      switch (cmd) {
        case "command":
          return isMac ? "\u2318" : "Ctrl";
        case "shift":
          return isMac ? "\u21E7" : "Shift";
        case "option":
          return isMac ? "\u2325" : "Alt";
        case "control":
          return isMac ? "\u2303" : "Ctrl";
        case "backspace":
          return isMac ? "\u232b" : "Backspace";
        default:
          return cmd;
      }
    },
    [isMac],
  );

  return stringToUnicode;
}
