import Button from "../../General/Button";
import { Thread } from "../types";
import CommentReactions from "../Reactions/CommentReactions";
import Replies from "./Replies";
import { Suspense, useMemo, useState } from "react";
import { useSetRecoilState } from "recoil";
import { selectedThreadAtomFamily } from "../state";
import UserInfo, { UserInfoLoading } from "../MapModal/UserInfo";
import TextWithMentions from "../MapModal/TextWithMentions";
import { useReadComments } from "../UnreadComments/useReadComments";
import { UnreadCommentDot } from "../UnreadComments/style";
import { useGoToFeatureCenter } from "../../../hooks/map";
import { Column, Row } from "../../General/Layout";
import ResolvedLabel from "./ResolvedLabel";

export default function Comment({
  thread,
  nodeId,
}: {
  thread: Thread;
  nodeId: string;
}) {
  const [showReplies, setShowReplies] = useState(false);

  const goToFeatureId = useGoToFeatureCenter();

  const setSelectedThread = useSetRecoilState(
    selectedThreadAtomFamily({ nodeId }),
  );
  const { isRead, setRead } = useReadComments();
  const showAsUnread = useMemo(() => !isRead(thread), [thread, isRead]);

  return (
    <Column>
      <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
        <Suspense fallback={<UserInfoLoading />}>
          <UserInfo userId={thread.userId} />
        </Suspense>
        {thread.resolved && <ResolvedLabel />}
        <Row>
          {showAsUnread && (
            <UnreadCommentDot
              style={{ cursor: "pointer" }}
              onClick={() => setRead(thread)}
            />
          )}
          <Button
            size="small"
            text="View in map"
            buttonType="secondary"
            onClick={() => {
              setSelectedThread((cur) =>
                cur === thread.threadId ? undefined : thread.threadId,
              );
              thread.featureId && goToFeatureId(thread.featureId);
            }}
          />
        </Row>
      </Row>
      <div
        style={{ cursor: "pointer", margin: 0 }}
        onClick={() => {
          setSelectedThread((cur) =>
            cur === thread.threadId ? undefined : thread.threadId,
          );
          thread.featureId && goToFeatureId(thread.featureId);
        }}
      >
        <TextWithMentions message={thread.message} />
      </div>
      <Row style={{ justifyContent: "flex-end", gap: "1.6rem" }}>
        <Row>
          <Button
            text={`${thread.replies} Replies`}
            size="small"
            buttonType="text"
            onClick={(e) => {
              e.stopPropagation();
              setShowReplies(showReplies ? false : true);
            }}
          />
        </Row>
        <CommentReactions thread={thread} nodeId={nodeId} />
      </Row>
      {showReplies && (
        <Suspense fallback={null}>
          <Replies thread={thread} nodeId={nodeId} />
        </Suspense>
      )}
    </Column>
  );
}
