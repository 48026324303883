import { DataSource } from "./../../state/dataSource";
import { ProjectFeature } from "../../types/feature";
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import { initializeAndSet } from "../Comments/hooks/useReplyReactionCrud";
import { projectFeaturesState, redoState, undoState } from "./state";
import { scream, sendWarning } from "../../utils/sentry";
import { v4 as uuid } from "uuid";
import { ablyLoadedState, ABLY_PROJECT_ELEMENTS } from "../../state/ably";
import { prepareProjectUpdateMessages } from "../../utils/ably";
import { addChangedFeaturesToAckMap } from "./updateAck";
import { toastMessagesAtom } from "../../state/toast";
import { useAblyPublish } from "../../hooks/useAblyPublish";
import { loggedInUserMetaInfoAtom } from "../../state/user";
import { Mixpanel } from "../../mixpanel";
import { _FeatureParser } from "../../types/feature";
import { branchIdSelector, projectIdSelector } from "../../state/pathParams";
import { wait } from "utils/utils";
import { useCallback } from "react";
import { UserMetaInfo } from "types/user";

const updateFeaturesInPlace = (
  changedFeatures: {
    add?: ProjectFeature[];
    remove?: string[];
    update?: ProjectFeature[];
  },
  cur: ProjectFeature[],
) => {
  if (
    changedFeatures.add?.length === 0 &&
    changedFeatures.remove?.length === 0 &&
    changedFeatures.update?.length === 0
  ) {
    return cur;
  }

  const currMap = new Map(cur.map((c) => [c.id, true]));
  const toAdd = (changedFeatures.add ?? []).concat(
    (changedFeatures.update ?? []).filter((uf) => !currMap.has(uf.id)),
  );
  const toRemove = [
    ...(changedFeatures.remove ?? []),
    ...(changedFeatures.add ?? []),
  ];
  const withoutRemoved = cur.filter((c) => !toRemove.includes(c.id));
  const toUpdate = changedFeatures.update ?? [];
  const toUpdateMap = new Map(toUpdate.map((f) => [f.id, f]));

  return withoutRemoved
    .map((c) => {
      return toUpdateMap.get(c.id) ?? c;
    })
    .concat(toAdd);
};

const RATE_LIMIT = 50; // messages per second rate limit in ably
const THRESHOLD = 45; // threshold below which throttling is not applied
const DELAY = 1000 / RATE_LIMIT; // milliseconds

export const useProjectElementsCrud = () => {
  const projectNodeId =
    useRecoilValue(projectIdSelector) ?? "undefined-recoil-dumb";
  const branchId = useRecoilValue(branchIdSelector);

  const user = useRecoilValue(loggedInUserMetaInfoAtom);
  const ablyLoaded = useRecoilValue(ablyLoadedState);
  const ablyPublish = useAblyPublish();

  const setToastMessagesAtom = useSetRecoilState(toastMessagesAtom);

  const publishMessage = useCallback(
    (
      m: {
        add?: ProjectFeature[] | undefined;
        remove?: string[] | undefined;
        update?: ProjectFeature[] | undefined;
        dataSource: DataSource;
      },
      projectNodeId: string,
      source: {
        projectId: string;
        branchId: string;
      },
      user: UserMetaInfo,
      uniqueUpdateId: string,
    ) => {
      return new Promise((res) => {
        ablyPublish(
          `project-update:node:${projectNodeId}:branch:${source.branchId}`,
          ABLY_PROJECT_ELEMENTS,
          { ...m, id: uniqueUpdateId, author: user.user_id },
          (error) => {
            if (error) {
              if (
                error.message.includes(
                  "Maximum size of messages that can be published",
                )
              ) {
                Mixpanel.track(
                  "Maximum size of messages that can be published",
                  {},
                );
                setToastMessagesAtom((cur) => [
                  ...cur,
                  {
                    type: "error",
                    text: "A single feature is above the maximum limit of ≈64KB, this is not supported at the moment.",
                    timeout: 10000,
                  },
                ]);
              } else {
                scream(error.message, {
                  feature: { ...m, id: uniqueUpdateId },
                  error,
                });
                setToastMessagesAtom((cur) => [
                  ...cur,
                  {
                    type: "error",
                    text: `Save failed: ${error.message}`,
                    timeout: 10000,
                  },
                ]);
              }
            } else {
              const { add, remove, update } = m;
              addChangedFeaturesToAckMap(uniqueUpdateId, {
                add,
                remove,
                update,
              });
            }
            res(error);
          },
        );
      });
    },
    [ablyPublish, setToastMessagesAtom],
  );

  const publishMessagesWithRateLimit = useCallback(
    async (
      messages: {
        add?: ProjectFeature[] | undefined;
        remove?: string[] | undefined;
        update?: ProjectFeature[] | undefined;
        dataSource: DataSource;
      }[],
      projectNodeId: string,
      source: {
        projectId: string;
        branchId: string;
      },
      user: UserMetaInfo,
      uniqueUpdateId: string,
    ) => {
      if (messages.length <= THRESHOLD) {
        // If number of messages is below the threshold, publish without delay
        await Promise.all(
          messages.map((m) =>
            publishMessage(m, projectNodeId, source, user, uniqueUpdateId),
          ),
        );
      } else {
        // If number of messages exceeds the threshold, publish with delay
        await Promise.all(
          messages.map(async (m, i) => {
            await wait(DELAY * i); // Delay to respect the rate limit
            await publishMessage(
              m,
              projectNodeId,
              source,
              user,
              uniqueUpdateId,
            );
          }),
        );
      }
    },
    [publishMessage],
  );

  const updateDataSource = useRecoilCallback(
    ({ set, snapshot }) =>
      async (
        ds: DataSource,
        changedFeatures: {
          add?: ProjectFeature[];
          remove?: string[];
          update?: ProjectFeature[];
        },
        isUndo?: boolean,
        isRedo?: boolean,
      ) => {
        if (!ablyLoaded) {
          setToastMessagesAtom((cur) => [
            ...cur,
            {
              type: "error",
              text: "Failed to connect & save. Please refresh your webpage and try again.",
              timeout: 10000,
            },
          ]);
          return;
        }
        const uniqueUpdateId = uuid();
        const source = {
          projectId: ds.projectId,
          branchId: ds.id,
        };

        const dataSourcefeatures = await snapshot.getPromise(
          projectFeaturesState(source),
        );

        if (!isUndo && !isRedo) {
          const removeIds = new Map(
            changedFeatures.remove?.map((f) => [f, true]),
          );
          const updateIds = new Map(
            changedFeatures.update?.map((f) => [f.id, true]),
          );
          initializeAndSet(snapshot, set, undoState(source), (cur) => [
            ...cur,
            {
              action: {
                remove: changedFeatures.add?.map((f) => f.id),
                add: dataSourcefeatures.filter((f) => removeIds.has(f.id)),
                update: dataSourcefeatures.filter((f) => updateIds.has(f.id)),
                id: uniqueUpdateId,
              },
              originAction: changedFeatures,
            },
          ]);
          initializeAndSet(snapshot, set, redoState(source), []);
        }

        initializeAndSet(snapshot, set, projectFeaturesState(source), (cur) =>
          updateFeaturesInPlace(changedFeatures, cur),
        );

        const messages = prepareProjectUpdateMessages(ds, changedFeatures);
        await publishMessagesWithRateLimit(
          messages,
          projectNodeId,
          source,
          user,
          uniqueUpdateId,
        );
      },
    [
      ablyLoaded,
      projectNodeId,
      publishMessagesWithRateLimit,
      setToastMessagesAtom,
      user,
    ],
  );

  const update = useRecoilCallback(
    () =>
      async (
        changedFeatures: {
          add?: ProjectFeature[];
          remove?: string[];
          update?: ProjectFeature[];
        },
        isUndo?: boolean,
        isRedo?: boolean,
      ) => {
        if (!branchId) return;

        for (const f of changedFeatures.add ?? []) {
          const res = _FeatureParser.safeParse(f);
          if (res.success) continue;
          sendWarning("Added feature doesn't parse", { f, error: res.error });
        }
        for (const f of changedFeatures.update ?? []) {
          const res = _FeatureParser.safeParse(f);
          if (res.success) continue;
          sendWarning("Updated feature doesn't parse", { f, error: res.error });
        }

        return updateDataSource(
          { projectId: projectNodeId, id: branchId, type: "branch" },
          changedFeatures,
          isUndo,
          isRedo,
        );
      },
    [branchId, projectNodeId, updateDataSource],
  );

  const localUpdate = useRecoilCallback(
    ({ set, snapshot }) =>
      async (
        dataSource: DataSource,
        changedFeatures: {
          add?: ProjectFeature[];
          remove?: string[];
          update?: ProjectFeature[];
        },
      ) => {
        initializeAndSet(
          snapshot,
          set,
          projectFeaturesState({
            projectId: dataSource.projectId,
            branchId: dataSource.id,
          }),
          (cur) => updateFeaturesInPlace(changedFeatures, cur),
        );
      },
    [],
  );

  return { localUpdate, update, updateDataSource };
};
