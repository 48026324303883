/// <reference types="vite-plugin-svgr/client" />
import React from "react";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import LayersIcon from "@icons/24/Layers.svg?react";
import { MenuItem } from "../../../General/Menu";
import { ElementToIcon } from "../../../ElementsToIcon/ElementsToIcon";
import { ElementIconWrapper } from "../SelectionMenu.style";
import {
  CABLE_CHAIN_POLYGON_PROPERTY_TYPE,
  CABLE_PARTITION_POLYGON_PROPERTY_TYPE,
} from "../../../../constants/cabling";
import { ProjectFeature } from "../../../../types/feature";
import { TURBINE_PROPERTY_TYPE } from "../../../../constants/projectMapView";

export const getFeatureName = (
  feature: MapboxGeoJSONFeature | ProjectFeature,
) => {
  if (feature.properties?.type === CABLE_CHAIN_POLYGON_PROPERTY_TYPE) {
    return "Chain";
  }

  if (feature.properties?.type === CABLE_PARTITION_POLYGON_PROPERTY_TYPE) {
    return "Partition";
  }

  if (feature.properties?.type === TURBINE_PROPERTY_TYPE) {
    return "Turbine";
  }

  if (feature.properties?.name) {
    return feature.properties.name;
  }
  return "[Unnamed element]";
};

const SelectElementMenuItem = ({
  features,
  closeMenu,
  onSelectFeature,
  onMouseEnterFeature,
  onMouseLeaveFeature,
}: {
  features: MapboxGeoJSONFeature[];
  closeMenu(): void;
  onSelectFeature(feature: MapboxGeoJSONFeature): void;
  onMouseEnterFeature(feature: MapboxGeoJSONFeature): void;
  onMouseLeaveFeature(feature: MapboxGeoJSONFeature): void;
}) => {
  const filteredFeatures = features.filter((feature) => Boolean(feature.id));
  if (filteredFeatures.length === 0) {
    return null;
  }

  return (
    <MenuItem name="Select element" icon={<LayersIcon />}>
      {filteredFeatures.map((feature) => (
        <MenuItem
          key={feature.id}
          name={getFeatureName(feature)}
          icon={
            <ElementIconWrapper>
              <ElementToIcon
                element={feature as any}
                fillPolygons={true}
                fillParks={true}
              />
            </ElementIconWrapper>
          }
          onClick={() => {
            onSelectFeature(feature);
            closeMenu();
          }}
          onMouseEnter={() => {
            onMouseEnterFeature(feature);
          }}
          onMouseLeave={() => {
            onMouseLeaveFeature(feature);
          }}
          stopPropagationOnClick={false}
        />
      ))}
    </MenuItem>
  );
};

export default SelectElementMenuItem;
