import {
  fetchEnhancerWithToken,
  fetchSchemaWithToken,
} from "../../services/utils";
import {
  ThreadFollow,
  Reaction,
  ReactionType,
  Reply,
  Thread,
  _ThreadFollow,
  _Reaction,
  _Reply,
  _Thread,
} from "./types";

export const getForumThreads = async (
  nodeId: string,
  branchId: string,
): Promise<Thread[]> => {
  return fetchSchemaWithToken(
    _Thread.array(),
    `/api/forums/node/${nodeId}/${branchId}/threads`,
    {
      method: "get",
    },
  );
};

export const getThreadReplies = async (
  nodeId: string,
  branchId: string,
  threadId: string,
): Promise<Reply[]> => {
  return fetchSchemaWithToken(
    _Reply.array(),
    `/api/forums/node/${nodeId}/${branchId}/threads/${threadId}/replies`,
    {
      method: "get",
    },
  );
};

export const postThread = async (
  nodeId: string,
  branchId: string,
  body: { message: string; featureId?: string; mapPosition?: [number, number] },
): Promise<Thread> => {
  return fetchSchemaWithToken(
    _Thread,
    `/api/forums/node/${nodeId}/${branchId}/threads`,
    {
      method: "post",
      body: JSON.stringify(body),
    },
  );
};
export const deleteThread = async (
  nodeId: string,
  branchId: string,
  threadId: string,
): Promise<boolean> => {
  const res = await fetchEnhancerWithToken(
    `/api/forums/node/${nodeId}/${branchId}/threads/${threadId}`,
    {
      method: "delete",
    },
  );
  if (res.status !== 204) throw new Error("Delete failed");
  return res.ok;
};

export const resolveThread = async (
  nodeId: string,
  branchId: string,
  threadId: string,
  resolved: boolean,
): Promise<Thread> => {
  return fetchSchemaWithToken(
    _Thread,
    `/api/forums/node/${nodeId}/${branchId}/threads/${threadId}/resolve`,
    {
      method: "put",
      body: JSON.stringify({ resolved }),
    },
  );
};

export const getThreadReactions = async (
  nodeId: string,
  branchId: string,
  threadId: string,
): Promise<Reaction[]> => {
  return fetchSchemaWithToken(
    _Reaction.array(),
    `/api/forums/node/${nodeId}/${branchId}/threads/${threadId}/reactions`,
    {
      method: "get",
    },
  );
};

export const getUserReactions = async (
  nodeId: string,
  branchId: string,
): Promise<Reaction[]> => {
  return fetchSchemaWithToken(
    _Reaction.array(),
    `/api/forums/node/${nodeId}/${branchId}/user/reactions`,
    {
      method: "get",
    },
  );
};
export const postThreadReaction = async (
  nodeId: string,
  branchId: string,
  threadId: string,
  body: { reaction: ReactionType },
): Promise<Reaction> => {
  return fetchSchemaWithToken(
    _Reaction,
    `/api/forums/node/${nodeId}/${branchId}/threads/${threadId}/reactions`,
    {
      method: "post",
      body: JSON.stringify(body),
    },
  );
};

export const deleteThreadReaction = async (
  nodeId: string,
  branchId: string,
  threadId: string,
  reactionId: string,
): Promise<boolean> => {
  const res = await fetchEnhancerWithToken(
    `/api/forums/node/${nodeId}/${branchId}/threads/${threadId}/reactions/${reactionId}`,
    {
      method: "delete",
    },
  );
  if (res.status !== 204) throw new Error("Delete failed");
  return res.ok;
};

export const postReply = async (
  nodeId: string,
  branchId: string,
  threadId: string,
  body: { message: string },
): Promise<Reply> => {
  return fetchSchemaWithToken(
    _Reply,
    `/api/forums/node/${nodeId}/${branchId}/threads/${threadId}/replies`,
    {
      method: "post",
      body: JSON.stringify(body),
    },
  );
};

export const deleteReply = async (
  nodeId: string,
  branchId: string,
  threadId: string,
  replyId: string,
): Promise<boolean> => {
  const res = await fetchEnhancerWithToken(
    `/api/forums/node/${nodeId}/${branchId}/threads/${threadId}/replies/${replyId}`,
    {
      method: "delete",
    },
  );
  if (res.status !== 204) throw new Error("Delete failed");
  return res.ok;
};

export const getReplyReactions = async (
  nodeId: string,
  branchId: string,
  threadId: string,
  replyId: string,
): Promise<Reaction[]> => {
  return fetchSchemaWithToken(
    _Reaction.array(),
    `/api/forums/node/${nodeId}/${branchId}/threads/${threadId}/replies/${replyId}/reactions`,
    {
      method: "get",
    },
  );
};
export const postReplyReaction = async (
  nodeId: string,
  branchId: string,
  threadId: string,
  replyId: string,
  body: { reaction: ReactionType },
): Promise<Reaction> => {
  return fetchSchemaWithToken(
    _Reaction,
    `/api/forums/node/${nodeId}/${branchId}/threads/${threadId}/replies/${replyId}/reactions`,
    {
      method: "post",
      body: JSON.stringify(body),
    },
  );
};
export const deleteReplyReaction = async (
  nodeId: string,
  branchId: string,
  threadId: string,
  replyId: string,
  reactionId: string,
): Promise<boolean> => {
  const res = await fetchEnhancerWithToken(
    `/api/forums/node/${nodeId}/${branchId}/threads/${threadId}/replies/${replyId}/reactions/${reactionId}`,
    {
      method: "delete",
    },
  );
  if (res.status !== 204) throw new Error("Delete failed");
  return res.ok;
};
export const getUserThreadFollows = async (
  nodeId: string,
  branchId: string,
): Promise<ThreadFollow[]> => {
  return fetchSchemaWithToken(
    _ThreadFollow.array(),
    `/api/forums/node/${nodeId}/${branchId}/user/follows`,
    {
      method: "get",
    },
  );
};
export const putThreadFollow = async (
  nodeId: string,
  branchId: string,
  threadId: string,
  body: { follow: boolean },
): Promise<ThreadFollow> => {
  return fetchSchemaWithToken(
    _ThreadFollow,
    `/api/forums/node/${nodeId}/${branchId}/threads/${threadId}/follow`,
    {
      method: "put",
      body: JSON.stringify(body),
    },
  );
};

// will follow only if the thread has not been followed before
export const postConditionalThreadFollow = async (
  nodeId: string,
  branchId: string,
  threadId: string,
): Promise<ThreadFollow> => {
  return fetchSchemaWithToken(
    _ThreadFollow,
    `/api/forums/node/${nodeId}/${branchId}/threads/${threadId}/follow/conditional`,
    {
      method: "post",
    },
  );
};
