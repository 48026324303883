import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useSetRecoilState, useRecoilCallback, useRecoilValue } from "recoil";
import { useGoToFeatures } from "../../../hooks/map";
import { mapRefAtom } from "../../../state/map";
import { branchIdSelector, projectIdSelector } from "../../../state/pathParams";
import { toastMessagesAtom } from "../../../state/toast";
import { projectFeatureMap } from "../../../state/projectLayers";
import {
  selectedThreadAtomFamily,
  threadsAtomFamily,
} from "../../Comments/state";

export default function ZoomToComment({
  setHasZoomed,
}: {
  setHasZoomed: (hasZoomed: boolean) => void;
}) {
  const projectId = useRecoilValue(projectIdSelector);
  const branchId = useRecoilValue(branchIdSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const setToastMessagesAtom = useSetRecoilState(toastMessagesAtom);

  const setSelectedThread = useSetRecoilState(
    selectedThreadAtomFamily({ nodeId: projectId }),
  );
  const map = useRecoilValue(mapRefAtom);
  const goToFeatures = useGoToFeatures(map);
  const zoomToComment = useRecoilCallback(
    ({ snapshot }) =>
      async (branchId: string, threadId: string) => {
        const threads = await snapshot.getPromise(
          threadsAtomFamily({ nodeId: projectId, branchId }),
        );
        const thread = threads.find((t) => t.threadId === threadId);

        if (!thread) {
          setToastMessagesAtom((state) => [
            ...state,
            {
              text: "Comment thread not found",
              type: "error",
              timeout: 6000,
            },
          ]);
        } else if (thread.featureId) {
          const featureMap = await snapshot.getPromise(projectFeatureMap);
          const feature = featureMap.get(thread.featureId);
          if (feature) {
            setTimeout(() => goToFeatures([feature]), 10); // avoid race condition on initialization
          }
        }
      },
    [projectId, goToFeatures, setToastMessagesAtom],
  );

  useEffect(() => {
    const commentId = searchParams.get("commentId");
    if (commentId && branchId && map) {
      const func = async () => {
        setSelectedThread(commentId);
        await zoomToComment(branchId, commentId);
        setHasZoomed(true);
        searchParams.delete("commentId");
        setSearchParams(searchParams);
      };
      func();
    }
  }, [
    branchId,
    searchParams,
    setHasZoomed,
    setSearchParams,
    setSelectedThread,
    zoomToComment,
    map,
  ]);

  return null;
}
