import { ProjectFeature, TurbineFeature } from "types/feature";
import {
  ANCHOR_PROPERTY_TYPE,
  EXISTING_TURBINE_PROPERTY_TYPE,
  GRID_CONNECTION_POINT_TYPE,
  MOORING_LINE_PROPERTY_TYPE,
  PORT_POINT_TYPE,
  SLACK_REGION_PROPERTY_TYPE,
  STATIC_OFFSET_REGION_PROPERTY_TYPE,
  TOUCHDOWN_PROPERTY_TYPE,
  TURBINE_PROPERTY_TYPE,
} from "@constants/projectMapView";
import {
  CABLE_CHAIN_POLYGON_PROPERTY_TYPE,
  CABLE_CORRIDOR_PROPERTY_TYPE,
  CABLE_PARTITION_POLYGON_PROPERTY_TYPE,
  CABLE_PROPERTY_TYPE,
  EXPORT_CABLE_PROPERTY_TYPE,
  SUBSTATION_PROPERTY_TYPE,
} from "@constants/cabling";
import {
  MAX_DEPTH_FIXED,
  MIN_DEPTH_FLOATING,
  PARK_PROPERTY_TYPE,
} from "@constants/park";
import {
  DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
  SUB_AREA_PROPERTY_TYPE,
} from "@constants/division";
import { VIEW_POINT_TYPE } from "@constants/viewPoint";
import { Raster } from "types/raster";
import {
  FoundationMaxDepths,
  FoundationMinDepths,
  FoundationType,
} from "types/foundations";
import { isDefined } from "utils/predicates";

export const featureTypeToReadableName = (
  type: ProjectFeature["properties"]["type"],
): string => {
  type Types = Exclude<ProjectFeature["properties"]["type"], undefined>;
  const map: Record<Types, string> = {
    [TURBINE_PROPERTY_TYPE]: "turbine",
    [SUBSTATION_PROPERTY_TYPE]: "substation",
    [CABLE_PROPERTY_TYPE]: "cable",
    [CABLE_CORRIDOR_PROPERTY_TYPE]: "cable corridor",
    [EXPORT_CABLE_PROPERTY_TYPE]: "export cable",
    [PARK_PROPERTY_TYPE]: "park",
    [DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE]: "exclusion zone",
    [SUB_AREA_PROPERTY_TYPE]: "sub area",
    BathymetryUserUploadedType: "bathymetry picture",
    GeoTiffUserUploadedImageType: "geotiff picture",
    [VIEW_POINT_TYPE]: "viewpoint",
    [EXISTING_TURBINE_PROPERTY_TYPE]: "existing turbine",
    [PORT_POINT_TYPE]: "port",
    [GRID_CONNECTION_POINT_TYPE]: "grid connection",
    [ANCHOR_PROPERTY_TYPE]: "anchor",
    [MOORING_LINE_PROPERTY_TYPE]: "mooring line",
    [SLACK_REGION_PROPERTY_TYPE]: "slack region",
    [CABLE_PARTITION_POLYGON_PROPERTY_TYPE]: "cable paritition",
    [CABLE_CHAIN_POLYGON_PROPERTY_TYPE]: "cable chain",
    [TOUCHDOWN_PROPERTY_TYPE]: "touchdown point",
    [STATIC_OFFSET_REGION_PROPERTY_TYPE]: "static offset region",
  };

  if (type === undefined) return "unknown";
  return map[type] ?? "unknown";
};

export const getFloatingTurbines = ({
  turbineFeatures,
  raster,
  currentFoundation,
}: {
  turbineFeatures: TurbineFeature[];
  raster: Raster;
  currentFoundation: FoundationType | undefined;
}): TurbineFeature[] => {
  return turbineFeatures.filter((t) => {
    const [lon, lat] = t.geometry.coordinates;
    if (!raster.contains(lon, lat)) return false;
    const depth = -raster.latLngToValue(lon, lat);
    const lowerLimit = isDefined(currentFoundation)
      ? FoundationMinDepths[currentFoundation.type]
      : MIN_DEPTH_FLOATING;
    return lowerLimit <= depth;
  });
};

export const getFixedTurbines = ({
  turbineFeatures,
  raster,
  currentFoundation,
}: {
  turbineFeatures: TurbineFeature[];
  raster: Raster;
  currentFoundation: FoundationType | undefined;
}): TurbineFeature[] => {
  return turbineFeatures.filter((t) => {
    const [lon, lat] = t.geometry.coordinates;
    if (!raster.contains(lon, lat)) return false;
    const depth = -raster.latLngToValue(lon, lat);
    const upperLimit = isDefined(currentFoundation)
      ? FoundationMaxDepths[currentFoundation.type]
      : MAX_DEPTH_FIXED;
    return depth <= upperLimit;
  });
};
