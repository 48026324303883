import stc from "string-to-color";

export function generateHSLGradientColors({
  n,
  startHue,
  endHue,
  saturation,
  lightness,
}: {
  n: number;
  startHue: number;
  endHue: number;
  saturation: number;
  lightness: number;
}): string[] {
  const result: string[] = [];

  const hueStep: number = n > 1 ? (endHue - startHue) / (n - 1) : 0;

  for (let i = 0; i < n; i++) {
    const hue: number = startHue + hueStep * i;
    const color: string = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    result.push(color);
  }

  return result;
}

const COLOR_LIBRARY = [
  "#00ff00",
  "#1E9600",
  "#c7d903",
  "#fff104",
  "#ffa302",
  "#ff5a01",
  "#FF0000",
  "#33363f",
];

export const preGenerateColorFromPrepickedColorLibrary = (
  n: number,
): string[] => {
  if (n >= COLOR_LIBRARY.length)
    return [...Array(n).keys()].map((i) =>
      i >= COLOR_LIBRARY.length ? stc(i.toString()) : COLOR_LIBRARY[i],
    );

  // This next part will ensure that you always spread out the selected colors if you have less needed colors than length of COLOR_LIBRARY
  const divider = Math.floor(COLOR_LIBRARY.length / n);
  const indices = [...Array(n).keys()].map((i) =>
    i % 2 === 0 ? i * divider : COLOR_LIBRARY.length - 1 - (i - 1) * divider,
  );
  const sortedIndixes = [...indices].sort();
  return sortedIndixes.map((i) => COLOR_LIBRARY[i]);
};

export const generateColorFromPrepickedColorLibrary = (i: number): string =>
  i >= COLOR_LIBRARY.length ? stc(i.toString()) : COLOR_LIBRARY[i];
