import { atom, atomFamily, selector, selectorFamily } from "recoil";
import {
  getExternalDataSourcesSelector,
  getAllNonDeletedLayersSelector,
  isHostedLayer,
  getAllPrivateSourcesSelector,
} from "state/layer";
import { LayerWithSearchRelevance, SourceTypesLayer } from "types/layers";
import { ProjectFeature } from "types/feature";
import { isWfsLayer } from "state/wfs";
import { isWMSLayer } from "state/wms";
import { isArcgisLayer } from "state/arcgisRestAPI";
import { getBBOXArrayFromFeatures } from "utils/geojson/validate";
import {
  bboxOverlaps,
  getFeatureLayersDataSelectorFamily,
  getTileJSONLayersOverlapSelectorFamily,
  getTileJSONMetadataLayersWithinBBOX,
  getWMSLayersDataSelectorFamily,
} from "./overlap/overlap-selectors";
import {
  filterByBBOXWeighted,
  filterByCountryWeighted,
  filterByDebugMissingCountriesWeighted,
  filterByDebugMissingTagsWeighted,
  filterBySearchWeighted,
  filterBySourceTypeWeighted,
  filterBySourceWeighted,
  filterByTagsWeighted,
  getUniqueCountries,
  getUniqueSortedTags,
  getUniqueSourceNames,
} from "./layer-filter-utils";

interface AvailableLayerFilterState {
  source: string[];
  tags: string[];
  country: string[];
  sourceTypes: string[];
}

export type LayerFilterState = {
  source: string[];
  tags: string[];
  sourceTypes: string[];
  overlappingGeometry?: ProjectFeature["geometry"];
  debug_missing_tags: boolean;
  debug_missing_countries: boolean;
  country: string[];
  searchString: string;
  filterOnBBOX: boolean;
};

export type FilterType = keyof AvailableLayerFilterState;

export const availableLayerListFiltersAtom = atomFamily<
  AvailableLayerFilterState,
  { projectId: string }
>({
  key: "availableLayerListFiltersAtom",
  default: selectorFamily({
    key: "availableLayerListFiltersAtomSelector",
    get:
      ({ projectId }) =>
      ({ get }) => {
        const layers = get(getAllNonDeletedLayersSelector({ projectId }));
        const sources = get(getExternalDataSourcesSelector);
        const privateSources = get(getAllPrivateSourcesSelector({ projectId }));
        const countries = getUniqueCountries(sources);
        const sourceNames = getUniqueSourceNames(
          sources.concat(privateSources),
        );
        const tags = getUniqueSortedTags(layers);

        return {
          country: countries,
          source: sourceNames,
          tags,
          sourceTypes: ["wms", "wfs", "tileJSON", "arcgis", "hosted", "xyz"],
        };
      },
  }),
});

export const activeFiltersAtom = atom<LayerFilterState>({
  key: "activeFiltersAtom",
  default: {
    source: [],
    tags: [],
    sourceTypes: [],
    debug_missing_tags: false,
    debug_missing_countries: false,
    overlappingGeometry: undefined,
    // layerType: [],
    // theme: [],
    country: [],
    searchString: "",
    filterOnBBOX: false,
  },
});

export const nrActiveFiltersSelector = selector<number>({
  key: "nrActiveFiltersSelector",
  get: ({ get }) => {
    const filters = get(activeFiltersAtom);
    return Object.values(filters)
      .filter(
        (val) => Array.isArray(val) || typeof val === "object" || val === true,
      )
      .flat().length;
  },
});

export const filteredLayerListSelector = selectorFamily<
  LayerWithSearchRelevance[],
  { projectId: string; mapBBOX?: number[] }
>({
  key: "filteredLayerListSelector",
  get:
    ({ projectId, mapBBOX }) =>
    ({ get }) => {
      const layers = get(getAllNonDeletedLayersSelector({ projectId }));

      const activeFilters = get(activeFiltersAtom);

      const result = layers.reduce<LayerWithSearchRelevance[]>((acc, layer) => {
        const searchResults = [
          filterBySourceWeighted(layer, activeFilters),
          filterByTagsWeighted(layer.tags, activeFilters),
          filterBySourceTypeWeighted(
            layer.sourceType as SourceTypesLayer,
            activeFilters,
          ),
          filterBySearchWeighted(layer, activeFilters),
          filterByBBOXWeighted(layer, activeFilters, mapBBOX),
          filterByCountryWeighted(layer.source, activeFilters),
          filterByDebugMissingTagsWeighted(layer.tags, activeFilters),
          filterByDebugMissingCountriesWeighted(layer.source, activeFilters),
        ];

        if (!searchResults.every((result) => !result.active || result.match)) {
          return acc;
        }

        const collectiveWeight = searchResults.reduce((accWeight, curr) => {
          if (curr.active && curr.match) {
            return accWeight + curr.weight;
          }
          return accWeight;
        }, 0);

        acc.push({ ...layer, searchRelevance: collectiveWeight });
        return acc;
      }, []);

      return result;
    },
});

export const filteredLayerListWithOverlapSelector = selectorFamily<
  {
    layers: LayerWithSearchRelevance[];
    loadingState?: { isLoading: boolean; maxLayers: number; nrLoading: number };
  },
  { projectId: string; mapBBOX?: number[] }
>({
  key: "filteredLayerListWithOverlapSelector",
  get:
    ({ projectId, mapBBOX }) =>
    ({ get }) => {
      const filteredLayers = get(
        filteredLayerListSelector({ projectId, mapBBOX }),
      );
      const filters = get(activeFiltersAtom);
      if (!filters.overlappingGeometry) {
        return {
          layers: filteredLayers,
        };
      }

      const filterBbox = getBBOXArrayFromFeatures([
        { geometry: filters.overlappingGeometry },
      ]);

      const featureLayerIds = filteredLayers
        .filter((f) => isHostedLayer(f) || isWfsLayer(f) || isArcgisLayer(f))
        .filter((f) => f.bbox && bboxOverlaps(filterBbox, f.bbox))
        .map((f) => f.id);

      const wmsLayerIds = filteredLayers
        .filter((f) => isWMSLayer(f))
        .filter((layer) => !layer.bbox || bboxOverlaps(filterBbox, layer.bbox))
        .map((f) => f.id);

      const tileJSONLayers = get(
        getTileJSONMetadataLayersWithinBBOX({
          bbox: filterBbox,
          projectId,
        }),
      );

      const maxNumberLayers =
        featureLayerIds.length + wmsLayerIds.length + tileJSONLayers.length;

      const overlappingFeatures = get(
        getFeatureLayersDataSelectorFamily({
          layerIds: featureLayerIds,
          geometry: filters.overlappingGeometry,
          projectId,
          bbox: filterBbox,
        }),
      );

      const overlappingWms = get(
        getWMSLayersDataSelectorFamily({
          layerIds: wmsLayerIds,
          // geometry: filters.overlappingGeometry,
          projectId,
          bbox: filterBbox,
        }),
      );

      const overlappingTileJSON = get(
        getTileJSONLayersOverlapSelectorFamily({
          tileJSONLayers: tileJSONLayers,
          geometry: filters.overlappingGeometry,
        }),
      );

      const allLayers = [
        ...overlappingFeatures,
        ...overlappingWms,
        ...overlappingTileJSON,
      ];

      const loadingLayers = allLayers.filter(
        (result) => result.state === "loading",
      );

      return {
        layers: allLayers
          .filter(
            (result) =>
              result.state === "hasValue" && result.getValue().result === true,
          )
          .map((result) => result.getValue().layer),
        loadingState: {
          isLoading: loadingLayers.length > 0,
          maxLayers: maxNumberLayers,
          nrLoading: loadingLayers.length,
        },
      };
    },
});

export const showSelectFeatureForOverlapHintAtom = atom({
  key: "showSelectFeatureForOverlapHintAtom",
  default: false,
});
