import { mapRefAtom } from "./../state/map";
import mapboxgl, { Map } from "mapbox-gl";
import { useCallback, useMemo } from "react";
import { BOUNDS_BUFFER } from "../constants/misc";
import { getBBOXArrayFromFeatures } from "../utils/geojson/validate";
import {
  ProjectFeature as OldProjectFeature,
  ProjectFeature,
} from "../types/feature";
import { externalLayerFilterPropertyAtom } from "../state/layer";
import { useRecoilValue } from "recoil";
import { FilterOperator } from "../components/FilterExternalDataLayers/FilterExternalDataLayers";
import { projectFeatureMap } from "../state/projectLayers";
import { isDefined } from "utils/predicates";
import * as Sentry from "@sentry/react";

const goToFeatures = (
  features: Pick<OldProjectFeature, "geometry">[],
  map: Map,
  options?: mapboxgl.FitBoundsOptions,
  boundBuffer?: number,
) => {
  const bbox = getBBOXArrayFromFeatures(features);
  const bounds = [
    [
      bbox[0] - (boundBuffer ?? BOUNDS_BUFFER),
      bbox[1] - (boundBuffer ?? BOUNDS_BUFFER),
    ],
    [
      bbox[2] + (boundBuffer ?? BOUNDS_BUFFER),
      bbox[3] + (boundBuffer ?? BOUNDS_BUFFER),
    ],
  ] as mapboxgl.LngLatBoundsLike;
  Sentry.addBreadcrumb({
    category: "map",
    message: "Bounds",
    level: "info",
    data: { bounds },
  });
  map.fitBounds(bounds, options);
};

export const useGoToFeatureCenter = () => {
  const map = useRecoilValue(mapRefAtom);
  const featureMap = useRecoilValue(projectFeatureMap);

  return useCallback(
    (featureId: string, options?: mapboxgl.FitBoundsOptions) => {
      const feature = featureMap.get(featureId);
      if (!feature || !map) return;
      goToFeatures([feature], map, options);
    },
    [featureMap, map],
  );
};
export const useGoToFeatures = (map?: Map) => {
  return useCallback(
    (
      features: Pick<ProjectFeature, "geometry">[],
      e?: any,
      options?: mapboxgl.FitBoundsOptions,
    ) => {
      if (e) e.stopPropagation();
      if (features.length === 0 || !map) return;
      goToFeatures(features, map, options);
    },
    [map],
  );
};
export const useGoToFeaturesIds = () => {
  const map = useRecoilValue(mapRefAtom);
  const featureMap = useRecoilValue(projectFeatureMap);
  return useCallback(
    (
      featureIds: string[],
      e?: any,
      options?: mapboxgl.FitBoundsOptions,
      boundBuffer?: number,
    ) => {
      const features = featureIds
        .map((fId) => featureMap.get(fId))
        .filter(isDefined);
      if (e) e.stopPropagation();
      if (features.length === 0 || !map) return;
      goToFeatures(features, map, options, boundBuffer);
    },
    [map, featureMap],
  );
};

export const useLayoutFilter = (sourceId: string) => {
  const externalLayerFilterProperty = useRecoilValue(
    externalLayerFilterPropertyAtom,
  );

  return useMemo(() => {
    if (!(sourceId in externalLayerFilterProperty)) return undefined;

    const keyValues = externalLayerFilterProperty[sourceId];
    const equalCases = Object.keys(keyValues)
      .filter((key) => keyValues[key].operator === FilterOperator.Equal)
      .map((key) => [
        [
          FilterOperator.Equal,
          ["get", key],
          parseInt(keyValues[key].value)
            ? parseInt(keyValues[key].value)
            : false,
        ],
        true,
        [FilterOperator.Equal, ["get", key], keyValues[key].value],
        true,
      ])
      .flat();
    const otherCases = Object.keys(keyValues)
      .filter((key) => keyValues[key].operator !== FilterOperator.Equal)
      .map((key) => [
        [
          keyValues[key].operator,
          ["to-number", ["get", key]],
          parseInt(keyValues[key].value)
            ? parseInt(keyValues[key].value)
            : false,
        ],
        true,
      ])
      .flat();
    return ["case", ...equalCases, ...otherCases, false];
  }, [sourceId, externalLayerFilterProperty]);
};
