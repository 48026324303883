import { useMemo } from "react";
import { getTurbinesSelectorFamily } from "../../state/layout";
import * as turf from "@turf/turf";
import { FillPaint, LinePaint } from "mapbox-gl";
import {
  turbineEllipsesFillLayerId,
  turbineEllipsesFillSourceId,
  turbineEllipsesLineLayerId,
  turbineEllipsesLineSourceId,
} from "./constants";
import { useRecoilValue } from "recoil";
import { previewTurbinesState } from "../../state/turbines";
import { colors } from "../../styles/colors";
import Polygon from "../MapFeatures/Polygon";
import LineString from "../MapFeatures/LineString";
import { SubAreaFeature } from "../../types/feature";
import { ParkFeature } from "../../types/feature";
import { distanceToZone, pointInPolygon } from "../../utils/geometry";
import { SimpleTurbineType } from "types/turbines";
import { partition } from "utils/utils";
import { sendWarning } from "utils/sentry";

const linePaint: LinePaint = {
  "line-color": colors.primary,
  "line-width": 1,
  "line-opacity": 0.25,
};

const fillPaint: FillPaint = {
  "fill-color": colors.primary,
  "fill-opacity": 0.15,
};

export const TurbineEllipses = ({
  map,
  region,
  parkId,
  minorAxisSpacing,
  majorAxisSpacing,
  edgeSpacing,
  rotation,
  turbineType,
}: {
  map: mapboxgl.Map;
  region: ParkFeature | SubAreaFeature;
  parkId: string;
  minorAxisSpacing: number;
  majorAxisSpacing: number;
  rotation: number;
  edgeSpacing: number | undefined;
  turbineType: SimpleTurbineType | undefined;
}) => {
  const previewState = useRecoilValue(previewTurbinesState);
  const turbines = useRecoilValue(getTurbinesSelectorFamily({ parkId }));
  const usedTurbines = useMemo(() => {
    if (previewState) {
      return previewState.preview.filter((t) =>
        pointInPolygon(t.geometry, region.geometry),
      );
    } else {
      return turbines.filter((t) =>
        pointInPolygon(t.geometry, region.geometry),
      );
    }
  }, [previewState, region.geometry, turbines]);

  const { sx, sy, angle } = useMemo(() => {
    // Turf samples an ellipse in a pretty bad way; if the primary axis is a lot
    // longer than the secondary axis, it looks real blockly at the ends of the
    // primary.  However, the opposite is not too bad, but also not great. See #1767
    // for a bug report on this.
    //
    // Our fix here is to ensure that the first axis is the shorter of the two,
    // and adjust the angle accordingly.   This leads to nicer elilpses.
    //
    // https://github.com/Turfjs/turf/issues/1767
    if (minorAxisSpacing < majorAxisSpacing) {
      return {
        sx: minorAxisSpacing / 2.0,
        sy: majorAxisSpacing / 2.0,
        angle: rotation,
      };
    } else {
      return {
        sx: majorAxisSpacing / 2.0,
        sy: minorAxisSpacing / 2.0,
        angle: rotation + 90,
      };
    }
  }, [rotation, minorAxisSpacing, majorAxisSpacing]);

  const turbineEllipses = useMemo(() => {
    if (sx == null || Math.abs(sx) < 1e-6)
      sendWarning("TurbineEllipses: sx bad", { sx });
    if (sy == null || Math.abs(sy) < 1e-6)
      sendWarning("TurbineEllipses: sy bad", { sy });
    // If not turbineType or edgeSpacing, all turbines are treated as regular (not edge) turbines.
    if (!turbineType || !edgeSpacing) {
      return usedTurbines.map((turbine) =>
        turf.ellipse(turbine.geometry.coordinates, sx, sy, {
          units: "meters",
          steps: 64,
          // @ts-ignore: Typing is wrong. See
          // https://github.com/Turfjs/turf/issues/1818
          angle,
        }),
      );
    }

    const [turbinesAlongEdge, turbinesInsidePark] = partition(
      usedTurbines,
      (t) => distanceToZone(t, region) < turbineType.diameter,
    );

    const regularTurbineEllipses = turbinesInsidePark.map((turbine) =>
      turf.ellipse(turbine.geometry.coordinates, sx, sy, {
        units: "meters",
        steps: 64,
        // @ts-ignore: Typing is wrong. See
        // https://github.com/Turfjs/turf/issues/1818
        angle,
      }),
    );

    const edgeTurbineEllipses = turbinesAlongEdge.map((turbine) =>
      turf.ellipse(
        turbine.geometry.coordinates,
        edgeSpacing / 2.0,
        edgeSpacing / 2.0,
        {
          units: "meters",
          steps: 64,
          // @ts-ignore: Typing is wrong. See
          // https://github.com/Turfjs/turf/issues/1818
          angle,
        },
      ),
    );

    return [...regularTurbineEllipses, ...edgeTurbineEllipses];
  }, [turbineType, edgeSpacing, usedTurbines, sx, sy, angle, region]);

  return (
    <>
      <Polygon
        features={turbineEllipses}
        sourceId={turbineEllipsesFillSourceId}
        layerId={turbineEllipsesFillLayerId}
        map={map}
        paint={fillPaint}
      />
      <LineString
        features={turbineEllipses}
        sourceId={turbineEllipsesLineSourceId}
        layerId={turbineEllipsesLineLayerId}
        map={map}
        paint={linePaint}
      />
    </>
  );
};
