import { z } from "zod";
import { WakeAnalysisConfiguration } from "../services/configurationService";
import { fetchEnhancerWithToken } from "../services/utils";
import { DEFAULT_TURBINES, SimpleTurbineType } from "../types/turbines";
import {
  ExistingTurbineFeature,
  ParkFeature,
  TurbineFeature,
} from "../types/feature";
import { reduceCoordinatePrecision } from "../utils/geojson/utils";
import {
  _SpatialCalibration,
  WindSourceConfiguration,
} from "services/windSourceConfigurationService";
import { Position } from "@turf/turf";
import { _MeanSpeedGrid } from "state/windStatistics";
import Semaphore from "utils/semaphore";

const _Status = z.enum(["running", "failed", "complete", "started", "stopped"]);
export type Status = z.infer<typeof _Status>;

const _ProductionHistograms = z.object({
  bins: z.number().array(),
  cumulativeGrossProduction: z.number().array(),
  cumulativeNetProduction: z.number().array(),
  netProduction: z.number().array(),
});

export type ProductionHistograms = z.infer<typeof _ProductionHistograms>;

const _YearlyNormalDistribution = z.object({
  mean: z.number(),
  std: z.number(),
});

const _Percentile = z.object({
  percentile: z.number(),
  value: z.number(),
});

export type Percentile = z.infer<typeof _Percentile>;

const _AnalysisResult = z.object({
  grossPerTurbine: z.number().array(),
  internalWakeLossPerTurbine: z.number().array(),
  totalWakeLossPerTurbine: z.number().array(),
  turbineLosses: z.number().array(),
  averageSpeedPerTurbine: z.number().array(),
  productionHistograms: _ProductionHistograms,
});

const _AnalysisPercentiles = z.object({
  preArrayEnergyPerYear: z.number().array(),
  preArrayEnergyPerYearDistribution: _YearlyNormalDistribution,
  preArrayEnergyPerYearPercentiles: _Percentile.array(),
  preArrayEnergyPerMonth: z.number().array(),
});

export const _AnalysisStatus = z.object({
  id: z.string(),
  status: z.enum(["running", "failed", "stopped", "complete", "started"]),
  version: z.string(),
  reason: z.string().nullish().optional(),
  progress: z.number().optional(), // 0 - 1
  stats: _AnalysisResult.optional(),
});

export const _AnalysisTimeseries = z.object({
  time: z.string().array(),
  date: z.string().array(),
  powerPerTime: z.number().array(),
  powerPerTimePostWake: z.number().array(), // Post wake, pre turbine loss
  energyPerDate: z.number().array(),
  rawSpeedPerTime: z.number().array().optional(),
  simulatedSpeedPerTime: z.number().array().optional(),
});

export const _PresignedUrlResponse = z.object({
  url: z.string(),
});

const GwaSpeedCalibration = z.object({
  type: z.literal("global_wind_atlas"),
  value: z.enum(["absolute", "relative"]),
});

const CustomSpeedCalibration = z.object({
  type: z.literal("custom"),
  value: z.enum(["absolute", "relative"]),
  id: z.string(),
});

// TODO: Verify format from backend
const SpatialCalibration = z.union([
  GwaSpeedCalibration,
  CustomSpeedCalibration,
]);

export const _AnalysisWindStats = z.object({
  meanSpeed: z.number(),
  speedBins: z.number().array(),
  speedBinProbabilities: z.number().array(),
  directionBins: z.number().array(),
  directionProbabilities: z.number().array(),
  meanSpeedPerDirection: z.number().array(),
  source: z.string(),
  spatialCalibration: SpatialCalibration.optional().nullish(),
  longitude: z.number(),
  latitude: z.number(),
  meanAlpha: z.number(),
  meanDensity: z.number(),
  height: z.number(),
  fromYear: z.number(),
  toYear: z.number(),
});

export type AnalysisStatus = z.infer<typeof _AnalysisStatus>;
export type AnalysisStatusDone = AnalysisStatus & {
  status: "failed" | "complete";
};
export type AnalysisResult = z.infer<typeof _AnalysisResult>;
export type AnalysisPercentiles = z.infer<typeof _AnalysisPercentiles>;
export type AnalysisTimeseries = z.infer<typeof _AnalysisTimeseries>;
export type PresignedUrlResponse = z.infer<typeof _PresignedUrlResponse>;
export type AnalysisWindStats = z.infer<typeof _AnalysisWindStats>;

const _AnalysisVersion = z.object({ version: z.string() });

export async function fetchLatestAnalysisVersion(): Promise<string> {
  return fetchEnhancerWithToken(`/api/octopus/layout/analysis/version`, {
    method: "get",
  })
    .then((res) => res.json())
    .then(_AnalysisVersion.parse)
    .then((v) => v.version);
}

// Only run 5 analyses at a time
const triggerAnalysisQueue = new Semaphore(5);
export async function triggerAnalysis({
  nodeId,
  turbines,
  neighbourTurbines,
  existingTurbines,
  wakeSettings,
  windConfiguration,
  hubHeightOverride,
  turbineTypeOverride,
  park,
  parkCenter,
  restart,
}: {
  nodeId: string;
  turbines: TurbineFeature[];
  neighbourTurbines: TurbineFeature[];
  existingTurbines: ExistingTurbineFeature[];
  wakeSettings: WakeAnalysisConfiguration;
  windConfiguration: WindSourceConfiguration;
  hubHeightOverride?: number;
  turbineTypeOverride?: SimpleTurbineType;
  park: ParkFeature;
  parkCenter: Position;
  restart?: boolean;
}): Promise<AnalysisStatus> {
  let turbineTypes;

  if (turbineTypeOverride) {
    const turbineIds = [turbineTypeOverride.id].concat(
      neighbourTurbines.map((t) => t.properties.turbineTypeId),
    );
    turbineTypes = Object.fromEntries(
      DEFAULT_TURBINES.filter((t) => turbineIds.includes(t.id)).map((t) => [
        t.id,
        t,
      ]),
    );
  } else {
    const turbineIds = turbines
      .map((t) => t.properties.turbineTypeId)
      .concat(neighbourTurbines.map((t) => t.properties.turbineTypeId));
    turbineTypes = Object.fromEntries(
      DEFAULT_TURBINES.filter((t) => turbineIds.includes(t.id)).map((t) => [
        t.id,
        t,
      ]),
    );
  }

  const settings = {
    wakeModel: wakeSettings.wakeModel,
    wakeExpansionFactor: wakeSettings.wakeExpansionFactor,
    turbulenceIntensity: wakeSettings.turbulenceIntensity,
    blockage: wakeSettings.blockage,
    density: wakeSettings.density,
    precision: wakeSettings.precision,
    turboparkWakeExpansionCalibration:
      wakeSettings.turboparkWakeExpansionCalibration,
    hubHeightOverride: hubHeightOverride ?? null,
  };

  const allNeighbours = neighbourTurbines
    .map((t) => {
      const [lon, lat] = reduceCoordinatePrecision(t.geometry.coordinates, 8);
      return {
        lon,
        lat,
        type: t.properties.turbineTypeId,
      };
    })
    .concat(
      existingTurbines.map((t) => {
        const [lon, lat] = reduceCoordinatePrecision(t.geometry.coordinates, 5);
        return {
          lon,
          lat,
          type: "generic",
          power: t.properties.power,
        };
      }),
    );

  const body = {
    turbines: turbines.map((t) => {
      const [lon, lat] = reduceCoordinatePrecision(t.geometry.coordinates, 8);
      return {
        lon,
        lat,
        type: turbineTypeOverride?.id ?? t.properties.turbineTypeId,
      };
    }),
    neighbourTurbines: allNeighbours,
    turbineTypes,
    settings,
    windConfiguration,
    parkPolygon: park.geometry.coordinates[0],
    parkCenter,
  };
  const params = new URLSearchParams();
  restart && params.set("restart", "true");

  await triggerAnalysisQueue.acquire();
  const response = await fetchEnhancerWithToken(
    `/api/octopus/layout/analysis/node/${nodeId}?${params}`,
    {
      method: "post",
      body: JSON.stringify(body),
      headers: {},
    },
  )
    .then((response) => response.json())
    .then((r) => _AnalysisStatus.parse(r));
  triggerAnalysisQueue.release();
  return response;
}

export async function getAnalysisResult({
  nodeId,
  id,
  version,
}: {
  nodeId: string;
  id: string;
  version: string;
}): Promise<AnalysisResult> {
  return fetchEnhancerWithToken(
    `/api/octopus/layout/analysis/node/${nodeId}/${id}/stats?version=${version}`,
    {
      method: "get",
      headers: {},
    },
  )
    .then((response) => response.json())
    .then((r) => _AnalysisResult.parse(r));
}

export async function fetchAnalysisPercentiles({
  nodeId,
  id,
  version,
}: {
  nodeId: string;
  id: string;
  version: string;
}): Promise<AnalysisPercentiles> {
  return fetchEnhancerWithToken(
    `/api/octopus/layout/analysis/node/${nodeId}/${id}/percentile?version=${version}`,
    {
      method: "get",
      headers: {},
    },
  )
    .then((response) => response.json())
    .then((r) => _AnalysisPercentiles.parse(r));
}

export async function fetchAnalysisTimeseries({
  nodeId,
  id,
  version,
}: {
  nodeId: string;
  id: string;
  version: string;
}): Promise<AnalysisTimeseries> {
  return fetchEnhancerWithToken(
    `/api/octopus/layout/analysis/node/${nodeId}/${id}/timeseries?version=${version}`,
    {
      method: "get",
      headers: {},
    },
  )
    .then((response) => response.json())
    .then((r) => _AnalysisTimeseries.parse(r));
}

export async function fetchAnalysisWindStats({
  nodeId,
  id,
  version,
}: {
  nodeId: string;
  id: string;
  version: string;
}): Promise<AnalysisWindStats> {
  return fetchEnhancerWithToken(
    `/api/octopus/layout/analysis/node/${nodeId}/${id}/wind_stats?version=${version}`,
    {
      method: "get",
      headers: {},
    },
  )
    .then((response) => response.json())
    .then((r) => _AnalysisWindStats.parse(r));
}

export async function fetchAnalysisWindData({
  nodeId,
  id,
  version,
}: {
  nodeId: string;
  id: string;
  version: string;
}): Promise<PresignedUrlResponse> {
  return fetchEnhancerWithToken(
    `/api/octopus/layout/analysis/node/${nodeId}/${id}/wind_data_file?version=${version}`,
    {
      method: "get",
      headers: {},
    },
  )
    .then((response) => response.json())
    .then((r) => {
      try {
        return _PresignedUrlResponse.parse(r);
      } catch (e) {
        console.log("Error parsing wind data file response", r, e);
        throw e;
      }
    });
}
