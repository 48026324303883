import { Row } from "components/General/Layout";
import { NumberOfTurbinesTag } from "components/GenerateWindTurbines/Tags";
import { SubAreaTag, Tag } from "components/SiteLocator/Tags";
import { useMemo } from "react";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { currentSelectedProjectFeatures } from "state/selection";
import { isSubArea } from "utils/predicates";
import {
  getAnalysisVersion,
  getCloseExistingTurbines,
  getSubAreas,
  getPark,
  getSurroundingTurbines,
  getTurbines,
  ProdId,
} from "./state";
import * as turf from "@turf/turf";
import { sum } from "utils/utils";
import {
  ErrorBoundaryWarningTriangle,
  ErrorBoundaryWrapper,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";
import { getHumanReadableArea } from "utils/geometry";

const ProductionTagList_ = ({ id }: { id: ProdId }) => {
  const selectedRawFeatures = useRecoilValue(currentSelectedProjectFeatures);
  const selectedRawSubAreas = useMemo(
    () => selectedRawFeatures.filter(isSubArea),
    [selectedRawFeatures],
  );

  const selectedSubAreaIds = useMemo(
    () => selectedRawSubAreas.map((f) => f.id).map(String),
    [selectedRawSubAreas],
  );

  const subAreas = useRecoilValue(getSubAreas(id));
  const selectedSubAreas = subAreas.filter((f) =>
    selectedSubAreaIds.includes(f.id),
  );

  const subArea = useMemo(() => {
    const zones = selectedSubAreas.length > 0 ? selectedSubAreas : subAreas;
    return getHumanReadableArea(sum(zones, (f) => turf.area(f)));
  }, [subAreas, selectedSubAreas]);

  const park = useRecoilValue(getPark(id));
  const parkAreaString = useMemo(
    () => getHumanReadableArea(turf.area(park)),
    [park],
  );

  const turbines = useRecoilValue(getTurbines(id));
  const neighbourTurbines = useRecoilValue(getSurroundingTurbines(id));
  const existingTurbines = useRecoilValue(getCloseExistingTurbines(id));
  const analysisVersion = useRecoilValueLoadable(
    getAnalysisVersion(id),
  ).valueMaybe();

  const totalNeighoburs = neighbourTurbines.length + existingTurbines.length;
  const numberOfTurbinesString =
    `${turbines.length}` +
    (totalNeighoburs > 0 ? ` (+${totalNeighoburs})` : "");

  return (
    <Row
      style={{
        justifyContent: "left",
        flexWrap: "wrap",
      }}
    >
      {0 < selectedSubAreaIds.length ? (
        <SubAreaTag tooltip="These analysis numbers are only for the selected zone." />
      ) : (
        <Tag>Park</Tag>
      )}
      <Tag>{selectedSubAreaIds.length ? subArea : parkAreaString}</Tag>
      <NumberOfTurbinesTag n={numberOfTurbinesString} />
      {analysisVersion && <Tag>v{analysisVersion}</Tag>}
    </Row>
  );
};

export const ProductionTagList = ErrorBoundaryWrapper(
  ProductionTagList_,
  ErrorBoundaryWarningTriangle,
  () => {},
);
