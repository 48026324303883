export type SelectedFileType = {
  file: File;
  fileName?: string;
  id: string;
  zippedFile?: File;
};

export type FixUpload = {
  message: string;
  callback: () => SelectedFileType;
};

export type LoadProgress = {
  id: string;
  serverFileName?: string;
  progress?: number;
  message?: string;
  waitingMessage?: string;
  error?: string;
  intercomHelpId?: number;
  fixUpload?: FixUpload;
};

export enum UploadFileType {
  PROJECT_FEATURE = "projectFeature",
  DATA_LAYER = "dataLayer",
  ADD_DATA_LAYER_SOURCE = "addDataLayerSource",
  WIND_DATA = "windData",
  FEATURE_FROM_COORDINATES = "featureFromCoordinates",
}
