import { Suspense, useEffect } from "react";
import {
  useSetRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useRecoilState,
} from "recoil";
import styled from "styled-components";
import { FullScreenModalSkeleton } from "../../FullScreenModal/FullScreenModal";
import TopBarModal, {
  TopBarModalHeader,
} from "components/FullScreenModal/TopBarModal";
import { FeatureSettingsModalTypeV2 } from "../../../state/configuration";
import { modalTypeOpenAtom } from "../../../state/modal";
import { showNewFoundationWizardAtom } from "state/foundations";
import { editorAccessProjectSelector } from "state/user";
import { projectIdSelector } from "state/pathParams";
import { ContentContainer } from "../Shared/styles";
import SettingsSubMenu from "../Shared/SettingsSubMenu";
import { selectedMenuItemState, unsavedSettingsState } from "../Shared/state";
import useFeatureSettings from "./Data/useFeatureSettings";
import { CreateFoundationMenuProject } from "./FoundationSubMenu";

const FeatureSettingsSubMenu = () => {
  const featureSettingsItems = useFeatureSettings();
  return (
    <SettingsSubMenu
      parentId={FeatureSettingsModalTypeV2}
      submenus={featureSettingsItems}
      depth={0}
    />
  );
};

const FeatureSettingsModalInner = () => {
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const unsavedSettings = useRecoilValue(unsavedSettingsState);

  const showNewFoundationWizard = useRecoilValue(showNewFoundationWizardAtom);

  return (
    <TopBarModal>
      <TopBarModalHeader
        title="Components"
        onClose={() => {
          if (unsavedSettings) {
            if (
              window.confirm("Are you sure you want to quit without saving?")
            ) {
              setModalTypeOpen(undefined);
            }
          } else {
            setModalTypeOpen(undefined);
          }
        }}
      />
      <ContentContainer>
        <Suspense fallback={<FullScreenModalSkeleton />}>
          <FeatureSettingsSubMenu />
        </Suspense>
      </ContentContainer>
      {showNewFoundationWizard && (
        <FoundationMenuWrapper>
          <CreateFoundationMenuProject />
        </FoundationMenuWrapper>
      )}
    </TopBarModal>
  );
};

const FeatureSettingsModal = () => {
  const [modalTypeOpen, setModalTypeOpen] = useRecoilState(modalTypeOpenAtom);
  const editorAccessProject = useRecoilValueLoadable(
    editorAccessProjectSelector,
  );
  const noAccess =
    editorAccessProject.state !== "hasValue" || !editorAccessProject.contents;
  const projectId = useRecoilValue(projectIdSelector);
  const [selectedItemId, setSelectedItemId] = useRecoilState(
    selectedMenuItemState({
      menuId: FeatureSettingsModalTypeV2,
      projectId: projectId ?? "",
    }),
  );

  useEffect(() => {
    if (modalTypeOpen?.modalType === FeatureSettingsModalTypeV2) {
      const defaultSelection = modalTypeOpen.metadata?.selectedMenuId;
      if (defaultSelection) {
        setModalTypeOpen((curr) => {
          if (curr?.modalType === FeatureSettingsModalTypeV2) {
            return {
              ...curr,
              metadata: {
                ...curr.metadata,
                selectedMenuId: undefined,
              },
            };
          }
          return curr;
        });
        setSelectedItemId(defaultSelection);
      } else if (!selectedItemId) {
        setSelectedItemId("turbines");
      }
    }
  }, [modalTypeOpen, selectedItemId, setModalTypeOpen, setSelectedItemId]);

  if (
    noAccess ||
    projectId == null ||
    modalTypeOpen?.modalType !== FeatureSettingsModalTypeV2
  )
    return null;

  return <FeatureSettingsModalInner />;
};

export default FeatureSettingsModal;

const FoundationMenuWrapper = styled.div`
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(1px);
`;
