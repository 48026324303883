/// <reference types="vite-plugin-svgr/client" />
import React from "react";
import { useRecoilValue } from "recoil";
import { _MooringLineFeature } from "../../types/feature";
import {
  allFoundationTypesSelector,
  defaultFoundationIds,
} from "../../state/foundations";
import Dropdown from "../Dropdown/Dropdown";

import { SubtitleWithLine } from "components/General/GeneralSideModals.style";
import { Row } from "components/General/Layout";

export const FoundationSettings = ({
  currentFoundationId,
  setCurrentFoundationId,
}: {
  currentFoundationId: string;
  setCurrentFoundationId: (id: string) => void;
}) => {
  const allFoundationTypes = useRecoilValue(allFoundationTypesSelector).filter(
    (t) => !t.archived,
  );

  return (
    <>
      <SubtitleWithLine text={"Foundation"} />
      <Row
        style={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Dropdown
          small
          id="foundation"
          value={currentFoundationId}
          onChange={(e) => {
            setCurrentFoundationId(e.target.value);
          }}
          style={{ flex: 1, width: "100%" }}
        >
          {allFoundationTypes.map((t) => (
            <option key={t.id} value={t.id}>
              {`${t.name}${
                defaultFoundationIds.includes(t.id) ? " (standard)" : ""
              }`}
            </option>
          ))}
        </Dropdown>
      </Row>
    </>
  );
};
