import React, { useEffect } from "react";
import styled from "styled-components";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { Outlet } from "react-router-dom";
import useAblyCostConfiguration from "../../hooks/useAblyCostConfiguration";
import DesignNavigation from "./DesignNavigation/DesignNavigation";
import { LayerControlWrapper } from "../RightSide/RightSide.style";
import { libraryLayersOpenAtom } from "../../state/layer";
import LowerRightToolsMenuV2 from "../LowerRight/LowerRightToolsMenuV2";
import MapNative from "../MapNative/MapNative";
import DynamicPolygonLayers from "../../layers/ExternalLayers/dynamicPolygonLayers";
import DynamicTileJSONLayers from "../../layers/ExternalLayers/dynamicTileJSONLayers";
import DynamicCircleLayers from "../../layers/ExternalLayers/dynamicCircleLayers";
import DynamicPointLayers from "../../layers/ExternalLayers/dynamicPointLayers";
import DynamicLineLayers from "../../layers/ExternalLayers/dynamicLineLayers";
import DynamicWMSLayers from "../../layers/ExternalLayers/dynamicWMSLayers";
import HasLoaded from "../HasLoaded/HasLoaded";
import Distance from "../Distance/Distance";
import Comments from "../Comments/Comments";
import ZoomToObject from "./ZoomTo/ZoomToObject";
import ExternalLayerOrderAnchorLayers from "../../layers/ExternalLayers/ExternalHandleLayers";
import DynamicArcgisRasterLayers from "../../layers/ExternalLayers/dynamicArcgisRasterLayers";
import { SaveSelectedBranch } from "../SelectCorrectBranch/SelectCorrectBranch";
import RightClickMenu from "../RightClickMenu/RightClickMenu";
import SelectionMenu from "../RightClickMenu/SelectionMenu";
import MapControls from "../MapControls/MapControls";
import useOpenModal from "../../hooks/useOpenModal";
import AblyProjectElements from "../../hooks/useAblyProjectElements";
import useOpenUploadModalOnDragFile from "../UploadModal/useOpenUploadModalOnDragFile";
import {
  branchIdSelector,
  organisationIdSelector,
  projectIdSelector,
} from "../../state/pathParams";
import { useAblyCursorPosition } from "../../hooks/useAblyCursorPosition";
import { useAblyBranch } from "../../hooks/useAblyBranch";
import { useAblySnapshot } from "../../hooks/useAblySnapshot";
import { useAblyLayer } from "../../hooks/useAblyLayer";
import AblyConfiguration from "../../hooks/useAblyConfiguration";
import AblyProjectElementsFolders from "../../hooks/useAblyProjectElementsFolders";
import { useAblySelection } from "../../hooks/useAblySelection";
import VersionSync from "../VersionSync/VersionSync";
import TutorialOpenedMixpanelFlag from "../TutorialOpenedMixpanelFlag/TutorialOpenedMixpanelFlag";
import { TimelineUpdateComponent } from "../../hooks/useAblyTimeline";
import { useAblyBathymetryListener } from "../Bathymetry/useAblyGetBathymetryIncludingCustom";
import DynamicWMTSLayers from "../../layers/ExternalLayers/dynamicWMTSLayers";
import DynamicXYZLayers from "../../layers/ExternalLayers/dynamicXYZLayers";
import { useAblyAnalysisStatus } from "../../hooks/useAblyAnalysisStatus";
import ShowSelectFeatureForOverlapHint from "components/DataLayersModal/components/ShowSelectFeatureForOverlapHint";
import SelectOverlapBufferModal from "components/SelectOverlapBufferModal/SelectOverlapBufferModal";
import DataLayersModal from "components/DataLayersModal/DataLayersModal";
import { SelectFeatureForOverlapContextProvider } from "components/DataLayersModal/selectFeatureForOverlapContext";
import { useAblySlopeBathymetryListener } from "components/Bathymetry/useAblyGetSlopeIncludingCustom";
import RefreshProjectState from "./RefreshProjectState";
import SearchBar from "components/Search/SearchBar";
import FullScreenLoaderWithDarkBackground from "components/FullScreenLoader/FullScreenLoaderWithDarkBackground";
import LowerLeftToolsMenuV2 from "components/LowerLeftV2/LowerLeftToolsMenuV2";
import VersionHistory from "./ProjectHistory/VersionHistory";
import { ReadOnlyInfo } from "components/ReadOnlyInfo/ReadOnlyInfo";
import UndoRedo from "components/MapControls/UndoRedo";
import { TourStep } from "components/OnboardingTours/TourStep";
import { TutorialProjectOnboarding } from "components/OnboardingTours/Tour";
import { UploadModalType } from "components/UploadModal/UploadModal";
import { currentParkTriggerId } from "components/Finance/Triggers";
import { analysisOverrideInputAtomFamily } from "components/ProductionV2/state";
import ArchiveProjectModal from "./ArchiveProjectVersion/Modal/ArchiveProjectModal";
import NewArchivedProjectVersionModal from "./ArchiveProjectVersion/Modal/NewModal";
import { useAblyArchivedVersion } from "hooks/useAblyArchivedVersion";
import SuccessModal from "./ArchiveProjectVersion/Modal/SuccessModal";
import {
  memberInOrganisationSelectorFamily,
  userNodeAccessSelectorFamily,
} from "state/user";

const MapContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ExternalLayers = () => {
  return (
    <>
      <ExternalLayerOrderAnchorLayers />
      <DynamicPolygonLayers />
      <DynamicTileJSONLayers />
      <DynamicCircleLayers />
      <DynamicPointLayers />
      <DynamicLineLayers />
      <DynamicWMSLayers />
      <DynamicWMTSLayers />
      <DynamicXYZLayers />
      <DynamicArcgisRasterLayers />
    </>
  );
};

const AblyBranch = () => {
  const projectId = useRecoilValue(projectIdSelector) ?? "";
  useAblyBranch(projectId);

  return null;
};

const AblyStuff = () => {
  const projectId = useRecoilValue(projectIdSelector) ?? "";
  useAblyCursorPosition(projectId);
  useAblyAnalysisStatus(projectId);
  useAblySnapshot(projectId);
  useAblyArchivedVersion(projectId);
  useAblyLayer(projectId);
  useAblySelection(projectId);
  useAblyBathymetryListener(projectId);
  useAblySlopeBathymetryListener(projectId);
  useAblyCostConfiguration(projectId);

  if (!projectId) return null;

  return <AblyBranch />;
};

// Reset analysisOverrideInputAtomFamily on unmount to avoid caching issue between projets
const ResetAnalysisOverrideConfigOnUnmount = () => {
  const resetAnalysisConfig = useResetRecoilState(
    analysisOverrideInputAtomFamily(currentParkTriggerId),
  );

  useEffect(() => {
    return () => {
      resetAnalysisConfig();
    };
  }, [resetAnalysisConfig]);

  return null;
};

const DesignWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export default function Design() {
  const open = useRecoilValue(libraryLayersOpenAtom);
  const { openUploadModal } = useOpenUploadModalOnDragFile({
    modalType: UploadModalType,
  });
  const organisationId = useRecoilValue(organisationIdSelector);
  const projectId = useRecoilValue(projectIdSelector);
  const branchId = useRecoilValue(branchIdSelector);

  const nodeAccess = useRecoilValue(
    userNodeAccessSelectorFamily({ nodeId: projectId }),
  );
  const isAdminForNode = nodeAccess >= 2;
  const isMemberInOrg = useRecoilValue(
    memberInOrganisationSelectorFamily({ organisationId }),
  );

  const showArchivedVersions = isAdminForNode && isMemberInOrg;

  useOpenModal();

  if (!branchId || !projectId || !organisationId) return <></>;

  return (
    <DesignWrapper>
      <ResetAnalysisOverrideConfigOnUnmount />
      <TutorialProjectOnboarding />
      <DesignNavigation />
      <RefreshProjectState />
      <React.Suspense fallback={<FullScreenLoaderWithDarkBackground />}>
        <AblyProjectElements projectId={projectId} />
        {branchId && <AblyProjectElementsFolders projectId={projectId} />}
        <AblyConfiguration projectId={projectId} />
        <VersionSync />
        <TimelineUpdateComponent />
        <MapControls />
        <SaveSelectedBranch />
      </React.Suspense>
      <React.Suspense fallback={null}>
        <UndoRedo />
        <TutorialOpenedMixpanelFlag />
      </React.Suspense>
      <React.Suspense fallback={null}>
        <RightClickMenu>
          {({ mapMouseEvent, closeMenu }) => (
            <SelectionMenu
              closeMenu={closeMenu}
              mapMouseEvent={mapMouseEvent}
            />
          )}
        </RightClickMenu>
      </React.Suspense>
      <MapContainer onDragOver={openUploadModal}>
        <TourStep
          tourId="general-intro-tour"
          stepId="clickPolygon"
          style={{ top: "6rem", left: "70rem" }}
        />
        <MapNative />
      </MapContainer>
      <React.Suspense fallback={null}>
        <Comments />
      </React.Suspense>
      <React.Suspense fallback={null}>
        <VersionHistory />
      </React.Suspense>
      {showArchivedVersions && (
        <>
          <React.Suspense fallback={null}>
            <ArchiveProjectModal />
          </React.Suspense>
          <React.Suspense fallback={null}>
            <NewArchivedProjectVersionModal />
          </React.Suspense>
          <React.Suspense fallback={null}>
            <SuccessModal />
          </React.Suspense>
        </>
      )}
      <React.Suspense fallback={null}>
        <ReadOnlyInfo />
      </React.Suspense>
      <React.Suspense fallback={null}>
        <LowerLeftToolsMenuV2 />
      </React.Suspense>
      <React.Suspense fallback={null}>
        <LayerControlWrapper open={open}>
          <LowerRightToolsMenuV2 />
        </LayerControlWrapper>
        <Distance />
        <SelectFeatureForOverlapContextProvider>
          <DataLayersModal />
          <SelectOverlapBufferModal />
          <ShowSelectFeatureForOverlapHint />
        </SelectFeatureForOverlapContextProvider>
        <Outlet />
      </React.Suspense>
      <React.Suspense fallback={null}>
        <ZoomToObject />
        <ExternalLayers />
        <HasLoaded />
      </React.Suspense>
      <React.Suspense fallback={null}>
        <AblyStuff />
      </React.Suspense>
      <SearchBar />
    </DesignWrapper>
  );
}
