import { useAblyNodes } from "hooks/useAblyNodes";
import { Suspense, useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";
import AblyConnect, {
  usePresenceEnter,
  useUpdatePresence,
} from "../hooks/useAbly";
import { organisationIdSelector, projectIdSelector } from "../state/pathParams";
import { loggedInUserSelector } from "../state/user";
import {
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "./ErrorBoundaries/ErrorBoundaryLocal";
import useAblyPortfolio from "hooks/useAblyPortfolio";
import {
  getNodeSelectorFamily,
  nodesInOrganisationSelectorFamily,
  useOrganisationNodeCrud,
} from "./Projects/useOrganisationFolderCrud";
import { Node, _Node, _RemoveNode } from "services/customerAPI";
import { Types } from "ably";
import {
  ABLY_CREATE_CHILD_NODE,
  ABLY_REMOVE_NODE,
  ABLY_UPDATE_NODE,
} from "state/ably";
import { useAblyGeneric } from "hooks/useAblyGeneric";
import useFolderId from "hooks/useFolderId";

const AblyWrapper = ErrorBoundaryWrapper(
  () => {
    const userData = useRecoilValue(loggedInUserSelector);
    const projectNodeId = useRecoilValue(projectIdSelector);
    const organisationId = useRecoilValue(organisationIdSelector);

    if (!organisationId || userData?.isTemporaryToken) return null;

    return (
      <Suspense fallback={null}>
        <AblyConnect organisationId={organisationId} />
        <AblyInnerOrganisationGlobal organisationId={organisationId} />
        {projectNodeId ? (
          <AblyInnerCustomer
            organisationId={organisationId}
            projectId={projectNodeId}
          />
        ) : organisationId ? (
          <AblyInnerOrganisation organisationId={organisationId} />
        ) : (
          <></>
        )}
      </Suspense>
    );
  },
  FatalErrorBoundaryWrapper,
  ScreamOnError,
);

function AblyInnerCustomer({
  organisationId,
  projectId,
}: {
  organisationId: string;
  projectId: string;
}) {
  usePresenceEnter();
  useUpdatePresence(projectId);
  useAblyNodes(organisationId);

  const node = useRecoilValue(
    getNodeSelectorFamily({ organisationId, nodeId: projectId }),
  );

  return <>{node && <AblyNodeMeta node={node} />}</>;
}

function AblyInnerOrganisationGlobal({
  organisationId,
}: {
  organisationId: string;
}) {
  useAblyPortfolio(organisationId);

  return <></>;
}

const MAX_CHANNELS = 170; // 200 minus a safety for other channels
function AblyInnerOrganisation({ organisationId }: { organisationId: string }) {
  useAblyNodes(organisationId);

  const folderId = useFolderId();
  const nodes = useRecoilValue(
    nodesInOrganisationSelectorFamily({ organisationId }),
  );

  // Making sure we don't exceed the ably channel limit per connection
  // Request for an increase has been sent to ably
  const nodesToSubTo = useMemo(() => {
    const childrenOfCurrentFolder = nodes.filter(
      (n) => n.parent_id === folderId || n.id === folderId,
    );
    const otherNodesToSubscribeTo = nodes
      .filter((n) => childrenOfCurrentFolder.every((c) => c.id !== n.id))
      .slice(0, MAX_CHANNELS - childrenOfCurrentFolder.length);

    return childrenOfCurrentFolder.concat(...otherNodesToSubscribeTo);
  }, [folderId, nodes]);

  return (
    <>
      {nodesToSubTo.map((n) => (
        <AblyNodeMeta key={n.id} node={n} />
      ))}
    </>
  );
}

function AblyNodeMeta({ node }: { node: Node }) {
  const channelName = useMemo(() => `${node.id}:meta`, [node.id]);
  const { updateLocal, removeLocal, createLocal } = useOrganisationNodeCrud();

  const onMessageReceivedRemove = useCallback(
    (message: Types.Message) => {
      const { nodeId } = _RemoveNode.parse(message.data.meta);
      removeLocal(nodeId);
    },
    [removeLocal],
  );

  const onMessageReceivedUpdate = useCallback(
    (message: Types.Message) => {
      const updatedNode = _Node.parse(message.data.meta);
      updateLocal(updatedNode);
    },
    [updateLocal],
  );
  const onMessageReceivedCreateChild = useCallback(
    async (message: Types.Message) => {
      const newNode = _Node.parse(message.data.meta);
      await createLocal(newNode);
    },
    [createLocal],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_REMOVE_NODE,
        onMessageReceived: onMessageReceivedRemove,
      },
      {
        eventName: ABLY_UPDATE_NODE,
        onMessageReceived: onMessageReceivedUpdate,
      },
      {
        eventName: ABLY_CREATE_CHILD_NODE,
        onMessageReceived: onMessageReceivedCreateChild,
      },
    ],

    [
      onMessageReceivedCreateChild,
      onMessageReceivedRemove,
      onMessageReceivedUpdate,
    ],
  );

  useAblyGeneric(channelName, events);

  return <></>;
}

export default AblyWrapper;
