export const MESH_TURBINE_NAME = "turbine";
export const MESH_ROTOR_NAME = "rotor";

export const FIXED_VIEW_POINT_ID = "fixed_view_point_id";

export const HEIGHT_OF_TURBINE_HUB_MODEL = 80;
export const DIAMETER_OF_TURBINE_BLADE_MODEL = 129;
export const ROTOR_OFFSET = 1.03;
export const DIVISION_FACTOR = 100;
export const DISTANCE_TO_WIRE_FRAME_SUN = 50000 / DIVISION_FACTOR;
export const WIRE_FRAME_SUN_RADIUS = 4;

export const ViewPositionMapBoxId = "viewPosition";
export const FOVLineMapBoxId = "FOVLine";
export const ViewOrigoPositionMapBoxId = "viewOrigo";

export const TerrainBBOXMapBoxLayerId = "TerrainBBOXLayer";
export const TerrainBBOXMapBoxSourceId = "TerrainBBOXSource";

export const OSMMapBoxLayerId = "OSMViewFromShoreLayer";
export const OSMMapBoxSourceId = "OSMViewFromShoreSource";

export const ViewPointPositionMapBoxId = "viewPoint";
