import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Link, useLocation } from "react-router-dom";
import LogoIcon from "@icons/24/VindLogo.svg?react";
import { organisationIdSelector, projectIdSelector } from "state/pathParams";
import { topLevelFolderIdFromOrgIdAndProjectIdSelectorFamily } from "components/Projects/useOrganisationFolderCrud";
import { useLocalStorage } from "hooks/useBrowserStorage";
import { IconREMSize } from "styles/typography";
import { colors } from "styles/colors";
import { userNodeAccessSelectorFamily } from "state/user";
import { selectedOrgTabState } from "components/Organisation/state";

const VindLogo = () => {
  const organisationId = useRecoilValue(organisationIdSelector);
  const projectId = useRecoilValue(projectIdSelector);

  const toplevelNodeId = useRecoilValue(
    topLevelFolderIdFromOrgIdAndProjectIdSelectorFamily({
      organisationId,
      projectId,
    }),
  );

  const nodeAccess = useRecoilValue(
    userNodeAccessSelectorFamily({ nodeId: toplevelNodeId }),
  );

  const [, setSelectedOrgTab] = useRecoilState(selectedOrgTabState);
  const goToFolder = nodeAccess >= 0 && toplevelNodeId;

  const [lastVisitedOrg] = useLocalStorage<string>(
    "vind:last-visited-organisation",
  );
  const { search } = useLocation();
  const link =
    (lastVisitedOrg && toplevelNodeId
      ? `/organisation/${lastVisitedOrg}${
          goToFolder ? `/${toplevelNodeId}` : ""
        }`
      : "/organisation") + search;

  return (
    <Link
      to={link}
      onClick={() => {
        !goToFolder && setSelectedOrgTab("Projects");
      }}
      style={{ textDecoration: "none" }}
    >
      <IconREMSize height={2.8} width={2.8} fill={colors.blue200}>
        <LogoIcon />
      </IconREMSize>
    </Link>
  );
};

export default VindLogo;
