import { useEffect } from "react";
import { selector, useRecoilValue } from "recoil";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { projectFeaturesSelector } from "../ProjectElements/state";
import { WarningProps } from "./ValidationWarnings";
import { sendInfo } from "../../utils/sentry";
const MAX_ALLOWED_SIZE = 56000000;

export const maxSizeExceededWarningSelector = selector<
  { type: ValidationWarningTypes.ProjectSize } | undefined
>({
  key: "maxSizeExceededWarningSelector",
  get: ({ get }) => {
    const projectData = get(projectFeaturesSelector);
    const sizeOfProjectData = new TextEncoder().encode(
      JSON.stringify(projectData),
    ).length;

    return sizeOfProjectData < MAX_ALLOWED_SIZE
      ? undefined
      : {
          type: ValidationWarningTypes.ProjectSize,
        };
  },
});

const MaxSizeExceeded = ({ warn, remove }: WarningProps) => {
  const projectDataWarning = useRecoilValue(maxSizeExceededWarningSelector);

  useEffect(() => {
    if (!projectDataWarning) return;
    sendInfo("Max size of branch shown for a user");
    warn({ type: ValidationWarningTypes.ProjectSize });

    return () => {
      remove(ValidationWarningTypes.ProjectSize);
    };
  }, [projectDataWarning, remove, warn]);

  return null;
};

export default MaxSizeExceeded;
