import React from "react";
import {
  ErrorBoundaryWarningTriangle,
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "../../ErrorBoundaries/ErrorBoundaryLocal";
import { Configuration } from "services/configurationService";
import { AnalysisSettingsInner } from "../AnalysisSettings";

const AnalysisSettingCustomAdmin = ErrorBoundaryWrapper(
  ({
    configuration,
    configurationName,
    onSave,
    onSaveName,
    onUnmount,
  }: {
    configuration: Configuration;
    configurationName: string;
    onSave: (config: Configuration) => Promise<any>;
    onSaveName: (id: string, name: string) => Promise<any>;
    onUnmount?: () => void;
    isLoading: boolean;
    nodeId: string;
    readOnly: boolean;
  }) => {
    return (
      <AnalysisSettingsInner
        configuration={configuration}
        configurationName={configurationName}
        save={onSave}
        saveName={onSaveName}
        onUnmount={onUnmount}
        isLibraryResource={true}
      />
    );
  },
  () => {
    return (
      <>
        <FatalErrorBoundaryWrapper />
        <ErrorBoundaryWarningTriangle />
      </>
    );
  },
  ScreamOnError,
);

export default AnalysisSettingCustomAdmin;
