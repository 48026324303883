import React from "react";
import styled from "styled-components";
import { spacing8 } from "styles/space";
import { Divider } from "../../shared";
import EditableProjectName from "./EditableProjectName";
import VindLogo from "./VindLogo";
import ReadOnly from "components/Design/DesignNavigation/ReadOnly";

const LeftSideWrapper = styled.div`
  display: flex;
  gap: ${spacing8};
  flex-basis: calc(100% / 3);
  align-items: center;
`;

const LeftSide = () => {
  return (
    <LeftSideWrapper>
      <VindLogo />
      <Divider />
      <EditableProjectName />
      <ReadOnly />
    </LeftSideWrapper>
  );
};

export default LeftSide;
