import { useRecoilCallback } from "recoil";
import {
  CableType,
  createNewOrgCable,
  deleteOrgCable,
  updateOrgCable,
} from "services/cableTypeService";
import { useTypedPath } from "state/pathParams";
import { organisationCableResourceState } from "../state";
import { Mixpanel } from "mixpanel";
import { libraryCableRefreshAtom } from "components/Cabling/Generate/state";
import { getCableTypeUsage } from "state/cable";

const useOrgCableCrud = () => {
  const { organisationId } = useTypedPath("organisationId");

  const create = useRecoilCallback(
    ({ set, snapshot }) =>
      async ({
        cable,
        name,
        projectAccess,
      }: {
        cable: Partial<CableType>;
        name?: string;
        projectAccess?: string[];
      }) => {
        const newCable = {
          ...cable,
          name: name ? name : `${cable.name} copy`,
        };

        const fallback = await snapshot.getPromise(
          organisationCableResourceState({ organisationId }),
        );

        try {
          const res = await createNewOrgCable(
            organisationId,
            newCable,
            projectAccess,
          );
          set(organisationCableResourceState({ organisationId }), (cur) => [
            ...cur,
            res,
          ]);

          Mixpanel.track("Created org cable", {
            cableId: cable.id,
            organisationId,
          });
          return res;
        } catch (e) {
          set(organisationCableResourceState({ organisationId }), fallback);
          throw e;
        }
      },
    [organisationId],
  );

  const update = useRecoilCallback(
    ({ set, snapshot }) =>
      async (cable: CableType) => {
        const fallback = await snapshot.getPromise(
          organisationCableResourceState({ organisationId }),
        );

        return updateOrgCable(organisationId, cable)
          .then((res) => {
            if (res) {
              set(organisationCableResourceState({ organisationId }), (cur) =>
                cur.map((c) => (c.id === cable.id ? res : c)),
              );

              set(libraryCableRefreshAtom, (cur) => cur + 1);

              Mixpanel.track("Update org cable", {
                cableId: cable.id,
                organisationId,
              });
            }
          })
          .catch((e) => {
            set(organisationCableResourceState({ organisationId }), fallback);
            throw e;
          });
      },
    [organisationId],
  );

  const remove = useRecoilCallback(
    ({ set, snapshot }) =>
      async (cableId: string) => {
        const fallback = await snapshot.getPromise(
          organisationCableResourceState({ organisationId }),
        );

        const usage = await getCableTypeUsage(organisationId, cableId);

        if (
          usage.length === 0 ||
          window.confirm(
            `Cable type is currently being used in ${usage.length} cables, are you sure you want to delete it?`,
          )
        ) {
          return deleteOrgCable(organisationId, cableId)
            .then((res) => {
              if (res) {
                set(organisationCableResourceState({ organisationId }), (cur) =>
                  [...cur].filter((c) => c.id !== cableId),
                );
                set(libraryCableRefreshAtom, (cur) => cur + 1);
                Mixpanel.track("Delete org cable", {
                  cableId: cableId,
                  organisationId,
                });
              }
            })
            .catch((e) => {
              set(organisationCableResourceState({ organisationId }), fallback);
              throw e;
            });
        }
      },
    [organisationId],
  );

  const duplicate = useRecoilCallback(
    () =>
      async (duplicate: {
        cable: CableType;
        name?: string;
        projectAccess?: string[];
      }) => {
        return create(duplicate);
      },
    [create],
  );

  return { create, update, remove, duplicate };
};

export default useOrgCableCrud;
