import { useRecoilCallback } from "recoil";
import {
  createArchivedProjectVersion,
  deleteArchivedProjectVersion,
} from "./service";
import { archivedProjectVersionsAtomFamily } from "./state";
import { ArchivedProjectVersion } from "./types";

export const useCreateArchivedVersions = () => {
  const create = useRecoilCallback(
    ({ set }) =>
      async (
        organisationId: string,
        projectId: string,
        branchId: string,
        parkId: string,
        name: string,
        description?: string,
      ) => {
        const meta = await createArchivedProjectVersion({
          organisationId,
          projectId,
          branchId,
          parkId,
          name,
          description,
        });

        set(
          archivedProjectVersionsAtomFamily({
            organisationId,
            projectId,
            branchId,
          }),
          (curr) => {
            const match = curr.find((b) => b.id === meta.id);
            if (match) return curr; // Ably beat us to it
            return [meta, ...curr];
          },
        );
      },
    [],
  );
  const updateLocal = useRecoilCallback(
    ({ set }) =>
      async (
        av: ArchivedProjectVersion,
        meta: { organisationId: string; projectId: string; branchId: string },
      ) => {
        const { organisationId, projectId, branchId } = meta;
        set(
          archivedProjectVersionsAtomFamily({
            organisationId,
            projectId,
            branchId,
          }),
          (curr) => {
            const match = curr.find((b) => b.id === av.id) ?? {};
            const filtered = curr.filter((b) => b.id !== av.id);
            return [...filtered, { ...match, ...av }];
          },
        );
      },
    [],
  );
  const deleteLocal = useRecoilCallback(
    ({ set }) =>
      async (meta: {
        organisationId: string;
        nodeId: string;
        branchId: string;
        versionId: string;
      }) => {
        const { organisationId, nodeId, branchId, versionId } = meta;
        set(
          archivedProjectVersionsAtomFamily({
            organisationId,
            projectId: nodeId,
            branchId,
          }),
          (curr) => {
            return curr.filter((b) => b.id !== versionId);
          },
        );
      },
    [],
  );
  return { create, updateLocal, deleteLocal };
};

export const useDeleteArchivedVersions = () => {
  const _delete = useRecoilCallback(
    ({ set, snapshot }) =>
      async (
        organisationId: string,
        nodeId: string,
        branchId: string,
        versionId: string,
      ) => {
        if (
          window.confirm(
            "Are you sure you want to delete this data? This cannot be undone.",
          ) === false
        )
          return;
        const match = await snapshot
          .getPromise(
            archivedProjectVersionsAtomFamily({
              organisationId,
              projectId: nodeId,
              branchId,
            }),
          )
          .then((res) => res.find((b) => b.id === versionId));
        set(
          archivedProjectVersionsAtomFamily({
            organisationId,
            projectId: nodeId,
            branchId,
          }),
          (curr) => {
            return curr.filter((b) => b.id !== versionId);
          },
        );
        try {
          await deleteArchivedProjectVersion({
            organisationId,
            projectId: nodeId,
            branchId,
            versionId,
          });
        } catch (e) {
          if (match) {
            set(
              archivedProjectVersionsAtomFamily({
                organisationId,
                projectId: nodeId,
                branchId,
              }),
              (curr) => [...curr, match],
            );
          }
        }
      },
    [],
  );

  const deleteLocal = useRecoilCallback(
    ({ set }) =>
      async (meta: {
        organisationId: string;
        nodeId: string;
        branchId: string;
        versionId: string;
      }) => {
        const { organisationId, nodeId, branchId, versionId } = meta;
        set(
          archivedProjectVersionsAtomFamily({
            organisationId,
            projectId: nodeId,
            branchId,
          }),
          (curr) => {
            return curr.filter((b) => b.id !== versionId);
          },
        );
      },
    [],
  );
  return { _delete, deleteLocal };
};
