import React, { useRef, useState } from "react";
import { DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import CloseIcon from "@icons/24/Close.svg?react";
import { IconBtn } from "components/General/Icons";
import { colors } from "styles/colors";
import { TabItemWrapper, TabsWrapper } from "./style";
import { cursorIsInRightHalfOfElement } from "utils/dragNDropUtils";

const DRAGGABLE_TAB_TYPE = "DRAGGABLE_TAB";
export const TabItem = ({
  selected,
  children,
  style,
  index,
  title,
  onClose,
  onClick,
  onDrag,
}: {
  selected?: boolean;
  index: number;
  style?: React.CSSProperties;
  title?: string;
  onClick(e: React.MouseEvent): void;
  onClose?(e: React.MouseEvent): void;
  onDrag?(oldIndex: number, newIndex: number): void;
} & React.PropsWithChildren) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [hoverState, setHoverState] = useState<undefined | "start" | "end">(
    undefined,
  );
  const [, dragRef] = useDrag(
    () => ({
      type: DRAGGABLE_TAB_TYPE,
      item: { index },
      canDrag: Boolean(onDrag),
    }),
    [index, onDrag],
  );

  const [dropCollection, dropRef] = useDrop(
    () => ({
      accept: DRAGGABLE_TAB_TYPE,
      canDrop: () => Boolean(onDrag),
      hover: (hoveredItem, monitor) => {
        if (!monitor.isOver() || hoveredItem.index === index) {
          return setHoverState(undefined);
        }
        const hoveringRight = cursorIsInRightHalfOfElement(
          elementRef.current,
          monitor.getClientOffset(),
        );
        setHoverState(hoveringRight ? "end" : "start");
      },
      collect: (monitor) => {
        const isHovered = monitor.isOver() && monitor.canDrop();
        return {
          isHovered,
        };
      },
      drop: (draggedItem: { index: number }, monitor: DropTargetMonitor) => {
        const isRight = cursorIsInRightHalfOfElement(
          elementRef.current,
          monitor.getClientOffset(),
        );
        const newIndex = isRight ? index + 1 : index;
        onDrag?.(draggedItem.index, newIndex);
      },
    }),
    [index, onDrag],
  );

  dragRef(dropRef(elementRef));
  return (
    <TabItemWrapper
      ref={elementRef}
      style={style}
      selected={selected}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick(e);
      }}
      title={title}
      onMouseUp={(e) => {
        // Clicking scroll button of mouse
        if (e.button === 1) {
          e.preventDefault();
          e.stopPropagation();
          onClose?.(e);
        }
      }}
      isHoveredStart={dropCollection.isHovered && hoverState === "start"}
      isHoveredEnd={dropCollection.isHovered && hoverState === "end"}
      staticSize={false}
    >
      {children}
      {onClose && (
        <IconBtn
          className="visible-on-hover"
          onClick={onClose}
          size="1rem"
          stroke={selected ? undefined : colors.white}
          hoverBackgroundColor="transparent"
          hoverStroke={colors.blue600}
          title="Close tab"
          style={{
            padding: "0.2rem",
          }}
        >
          <CloseIcon />
        </IconBtn>
      )}
    </TabItemWrapper>
  );
};

export type TabItemProps = {
  id: string;
  text: React.ReactNode;
  //createdAt: number;
  title?: string;
  disabled?: boolean;
};

const BrowserStyleTabs = ({
  selectedId,
  leftAction,
  items,
  rightAction,
  wrapperStyle,
  itemStyle,
  renderItem,
  onClickTab,
  onCloseTab,
  onDragTab,
}: {
  selectedId: string;
  leftAction?: React.ReactNode;
  items: TabItemProps[];
  rightAction?: React.ReactNode;
  wrapperStyle?: React.CSSProperties;
  itemStyle?: React.CSSProperties;
  renderItem(item: TabItemProps, active: boolean): React.ReactNode;
  onClickTab(item: TabItemProps, e: React.MouseEvent): void;
  onCloseTab?(
    item: TabItemProps,
    newSelectedIndex: number | undefined,
    e: React.MouseEvent,
  ): void;
  onDragTab?(oldIndex: number, newIndex: number): void;
}) => {
  return (
    <TabsWrapper style={wrapperStyle}>
      {leftAction}

      {items.map((item, index) => (
        <TabItem
          key={item.id}
          index={index}
          onDrag={onDragTab}
          onClick={(e) => {
            if (!item.disabled) {
              onClickTab(item, e);
            }
          }}
          title={item.title}
          style={itemStyle}
          selected={selectedId === item.id}
          onClose={
            onCloseTab
              ? (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  const closedItem = item;

                  const selectedIndex = items.findIndex(
                    (item) => item.id === selectedId,
                  );
                  const closedItemIndex = items.findIndex(
                    (item) => item.id === closedItem.id,
                  );
                  const selectedItem = items[selectedIndex];

                  const didCloseSelectedItem =
                    selectedItem && closedItem.id === selectedItem.id;

                  const didCloseLastItem = closedItemIndex === items.length - 1;

                  if (didCloseSelectedItem) {
                    const indexBeforeThisOne = selectedIndex - 1;

                    if (didCloseLastItem) {
                      const newIndex = Math.min(
                        indexBeforeThisOne,
                        items.length,
                      );
                      if (items[newIndex]) {
                        onCloseTab(closedItem, newIndex, e);
                      }
                    } else {
                      onCloseTab(closedItem, closedItemIndex + 1, e);
                    }
                  } else {
                    onCloseTab(closedItem, undefined, e);
                  }
                }
              : undefined
          }
        >
          {renderItem(item, selectedId === item.id)}
        </TabItem>
      ))}

      {rightAction}
    </TabsWrapper>
  );
};

export default BrowserStyleTabs;
