import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { DashboardModalType, modalTypeOpenAtom } from "state/modal";
import Tooltip from "components/General/Tooltip";
import {
  DISABLED_IN_READ_ONLY_MODE,
  inReadOnlyModeSelector,
} from "state/project";
import DashboardIcon from "@icons/12/Dashboard.svg";
import SearchWrapper from "components/Search/SearchWrapper";
import { mapInteractionSelectorProjectControl } from "state/map";
import { MiddleItemWrapper } from "components/Design/TopbarNavigation/components/Middle/style";
import RoundIcon from "components/Design/TopbarNavigation/components/Middle/RoundIcon";
import { Mixpanel } from "mixpanel";

const DashboardModalButtonV2 = () => {
  const [modalTypeOpen, setModalType] = useRecoilState(modalTypeOpenAtom);
  const projectControl = useRecoilValue(mapInteractionSelectorProjectControl);
  const readOnlyMode = useRecoilValue(inReadOnlyModeSelector);

  if (!projectControl) return null;

  return (
    <SearchWrapper
      title="Dashboard"
      id="search-dashboard"
      icon={<DashboardIcon />}
      tags={[
        "overview",
        "statistics",
        "download",
        "share",
        "export",
        "presentation",
      ]}
      onSelect={() => {
        setModalType({ modalType: DashboardModalType }),
          Mixpanel.track("Open full screen modal", {
            type: DashboardModalType,
          });
      }}
    >
      <Tooltip
        theme="light"
        position="bottom"
        text={readOnlyMode ? DISABLED_IN_READ_ONLY_MODE : "Dashboard"}
        disabled={!readOnlyMode}
      >
        <MiddleItemWrapper
          active={modalTypeOpen?.modalType === DashboardModalType}
          disabled={readOnlyMode}
          onClick={() => {
            setModalType((curr) =>
              curr?.modalType !== DashboardModalType
                ? { modalType: DashboardModalType }
                : undefined,
            );
            Mixpanel.track("Open full screen modal", {
              type: DashboardModalType,
            });
          }}
        >
          <RoundIcon fillOrStroke="fill">
            <DashboardIcon />
          </RoundIcon>
          Dashboard
        </MiddleItemWrapper>
        {/*<IconBtn
          disabled={readOnlyMode}
          id="button-open-dashboard"
          onClick={() => {
            trackProjectControlMenu("Dashboard", {
              projectId,
              branchId,
            });
            setModalType({ modalType: DashboardModalType });
          }}
        >
          <Graph />
        </IconBtn>*/}
      </Tooltip>
    </SearchWrapper>
  );
};

export default DashboardModalButtonV2;
