import { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { versionQueryName } from "hooks/useSetVersion";
import useResetMapControls from "hooks/useResetMapControls";
import { useRecoilValueDef } from "utils/recoil";
import {
  organisationIdSelector,
  parkIdSelector,
  projectIdSelector,
} from "state/pathParams";
import { dedup } from "utils/utils";
import { openedBranchIdsTabsAtomFamily } from "./state";

export const getPathToBranch = (
  organisationId: string,
  projectId: string,
  branchId: string,
  parkId?: string,
) => {
  return `/design/project/${organisationId}/${projectId}/${branchId}${
    parkId ? `/${parkId}` : ``
  }`;
};

const useNavigateToBranch = () => {
  const organisationId = useRecoilValueDef(organisationIdSelector);
  const nodeId = useRecoilValueDef(projectIdSelector);
  const parkId = useRecoilValue(parkIdSelector);
  const resetMapControls = useResetMapControls();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const setOpenedBranchIds = useSetRecoilState(
    openedBranchIdsTabsAtomFamily({ nodeId }),
  );

  return useCallback(
    (newBranchId: string, appendToBranchTabBar: boolean) => {
      resetMapControls();
      searchParams.delete(versionQueryName);
      navigate({
        pathname: getPathToBranch(organisationId, nodeId, newBranchId, parkId),
        search: searchParams.toString(),
      });

      if (appendToBranchTabBar) {
        setOpenedBranchIds((curr) => dedup([...curr, newBranchId]));
      }
    },
    [
      navigate,
      nodeId,
      organisationId,
      parkId,
      resetMapControls,
      searchParams,
      setOpenedBranchIds,
    ],
  );
};

export default useNavigateToBranch;
