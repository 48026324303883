// DnDStyles.ts
import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div.attrs<{ height: number }>((props) => ({
  style: {
    height: `${props.height}px`,
  },
}))<{ height: number }>`
  display: flex;
  flex-direction: column;
  min-height: 30rem;
`;

export const RowContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
  gap: 0.6rem;
`;

export const ElementWrapper = styled.div<{
  widthShare: number;
  resizeInProgress: boolean;
  disabled?: boolean;
}>`
  display: flex;
  flex: ${({ widthShare }) => widthShare};
  min-width: 0;
  gap: 0.6rem;

  ${(p) => !p.resizeInProgress && !p.disabled && "cursor: grab;"}
`;
export const Element = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
`;

export const DropIndicator = styled.div<{ show: boolean }>`
  width: 0.2rem;
  flex-shrink: 0;
  background-color: ${colors.primaryHover};
  height: 100%;
  opacity: ${(p) => (p.show ? 1 : 0)};
  transition: opacity 0.1s ease;
`;

export const Resizer = styled.div<{ isHovered: boolean }>`
  height: 0.4rem;
  flex: 1;
  background-color: ${colors.inputOutline};
  border-radius: 4px;
  opacity: ${(p) => (p.isHovered ? 1 : 0)};
  transition: opacity 0.1s ease;
`;

export const RowDropContainer = styled.div`
  height: 1.6rem;
  width: 100%;
  display: flex;
  align-items: center;
`;
export const ResizerContainer = styled(RowDropContainer)`
  cursor: ns-resize;
  &:hover ${Resizer} {
    opacity: 1;
  }
`;
