import { useEffect } from "react";
import { selectorFamily, useRecoilValue } from "recoil";
import {
  getParkFeatureInBranchSelector,
  getParkFeatureSelectorFamily,
} from "../../state/park";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { WarningProps } from "./ValidationWarnings";
import { ParkFeature } from "../../types/feature";
import { branchIdSelector } from "../../state/pathParams";
import { getTurbinesInBranchSelectorFamily } from "state/layout";
import {
  allFixedTypesSelector,
  isDetailedMonopileSelector,
} from "state/foundations";
import { isDetailedMonopile } from "components/RightSide/InfoModal/FoundationModal/utils";
import { allSimpleTurbineTypesSelector } from "state/turbines";

export const MAX_RELATIVE_TURBINE_DATA_ERROR = 0.2; //if some of the turbine data (rotor diameter, hub height, RNA weight) differs more than this from the reference values for the monopile, a warning is given

export const turbineAndMonopileSelectorFamily = selectorFamily<
  | {
      type: ValidationWarningTypes.TurbineAndMonopileTypeError;
      featureIds: string[];
      parkId: string;
    }
  | undefined,
  { parkId: string; branchId: string }
>({
  key: "turbineAndMonopileSelectorFamily",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const park = get(getParkFeatureInBranchSelector({ parkId, branchId }));
      if (!park) return;

      const turbines = get(
        getTurbinesInBranchSelectorFamily({
          parkId: parkId,
          branchId: branchId ?? "",
        }),
      );
      const allTurbineTypes = get(allSimpleTurbineTypesSelector);
      const allFixedFoundations = get(allFixedTypesSelector);
      const allDetailedMonopiles =
        allFixedFoundations.filter(isDetailedMonopile);

      const isDetailedMonopileId = get(isDetailedMonopileSelector);
      const turbinesWithDetailedMonopile = turbines.filter((t) =>
        isDetailedMonopileId(t.properties.foundationId ?? ""),
      );
      const turbineMonopileError = turbinesWithDetailedMonopile.filter((t) => {
        const turbineType = allTurbineTypes.find(
          (type) => type.id === t.properties.turbineTypeId,
        );
        const monopile = allDetailedMonopiles.find(
          (f) => f.id === t.properties.foundationId,
        );
        if (!turbineType || !monopile) return false;

        const { diameter, hubHeight, rnaMass } = turbineType;
        const diameterError = Math.abs(diameter / monopile.rotorDiameter - 1);
        const hubHeightError = Math.abs(hubHeight / monopile.hubHeight - 1);
        const rnaMassError = Math.abs(rnaMass / monopile.rnaMass - 1);

        return (
          Math.max(diameterError, hubHeightError, rnaMassError) >
          MAX_RELATIVE_TURBINE_DATA_ERROR
        );
      });

      return turbineMonopileError.length === 0
        ? undefined
        : {
            type: ValidationWarningTypes.TurbineAndMonopileTypeError,
            featureIds: turbineMonopileError.map((t) => t.id),
            parkId: parkId,
          };
    },
});

const TurbineAndMonopileTypeErrorInner = ({
  park,
  warn,
  remove,
}: { park: ParkFeature } & WarningProps) => {
  const branchId = useRecoilValue(branchIdSelector);

  const turbineMonopileWarning = useRecoilValue(
    turbineAndMonopileSelectorFamily({
      parkId: park.id,
      branchId: branchId ?? "",
    }),
  );

  useEffect(() => {
    if (!turbineMonopileWarning) return;
    warn(turbineMonopileWarning);
    return () => {
      remove(ValidationWarningTypes.TurbineAndMonopileTypeError);
    };
  }, [remove, turbineMonopileWarning, warn]);

  return null;
};

const TurbineAndMonopileTypeError = ({
  parkId,
  ...props
}: { parkId: string } & WarningProps) => {
  const park = useRecoilValue(getParkFeatureSelectorFamily({ parkId }));

  if (!park) return null;
  return <TurbineAndMonopileTypeErrorInner park={park} {...props} />;
};

export default TurbineAndMonopileTypeError;
