import { atom } from "recoil";
import { SourceTypesLayer } from "../types/layers";

export type LayerForDataCleaning = {
  sourceId: string;
  endpointUrl: string;
  endpointId: string;
  layerId: string;
  tags: string[];
  isHidden: boolean;
  customMetadata: Record<string, string | undefined>;
};

export type SourceForDataCleaning = {
  sourceId: string;
  alternativeNames?: string[];
  countries?: string[];
  name?: string;
  originalName?: string;
  endpoint: {
    sourceType: SourceTypesLayer;
    endpointUrl: string;
    licenseType?: string;
    licenseUrl?: string;
    attribution?: string;
    urlWhereFound?: string;
  };
};

export const changedDataLayersAtom = atom<Record<string, LayerForDataCleaning>>(
  {
    key: "changedDataLayersAtom",
    default: {},
  },
);
export const changedDataSourcesAtom = atom<
  Record<string, SourceForDataCleaning>
>({
  key: "changedDataSourcesAtom",
  default: {},
});

export const showHiddenLayersAtom = atom<boolean>({
  key: "showHiddenLayersAtom",
  default: false,
});

export const hasUnsavedChangesAtom = atom({
  key: "hasUnsavedChangesAtom",
  default: false,
});
