import { z } from "zod";
import { fetchEnhancerWithToken } from "./utils";
import * as spec from "api/configurations";

export const MOORING_LINE_DIAMETER_MIN = 50;
export const MOORING_LINE_DIAMETER_MAX = 300;
export const ANCHOR_RADIUS_MIN_KM = 0.5;
export const ANCHOR_RADIUS_MAX_KM = 1.5;
export const ANCHOR_RADIUS_MIN_DEPTH = 2.0;
export const ANCHOR_RADIUS_MAX_DEPTH = 6.0;
export const MOORING_LINE_LENGTH_MIN_KM = 0.2;
export const MOORING_LINE_LENGTH_MIN_DEPTH = 1;
export const MOORING_END_SEGMENT_LENGTH_MIN_KM = 0.05;
export const MOORING_END_SEGMENT_LENGTH_MAX_KM = 0.3;
export const MOORING_END_SEGMENT_LENGTH_MIN_DEPTH = 0.02;
export const MOORING_END_SEGMENT_LENGTH_MAX_DEPTH = 1.0;
export const TARGET_PRETENSION = 1500e3;
export const TARGET_FAIRLEAD_ANGLE = (60 * Math.PI) / 180;
export const MAX_BUFFER_DISTANCE_RASTER_ANCHOR = 2; //km

export const _MooringMaterialType = z.union([
  z.literal("chain_r3"),
  z.literal("chain_r4"),
  z.literal("polyester"),
  z.literal("nylon"),
]);
export type MooringMaterialType = z.infer<typeof _MooringMaterialType>;

export const _MooringLineType = spec.components.schemas.MooringLineType;
export type MooringLineType = z.infer<typeof _MooringLineType>;

export async function getMooringLineTypes(nodeId: string, version?: number) {
  return fetchEnhancerWithToken(
    `/api/mooring/node/${nodeId}${version ? `?version=${version}` : ""}`,
    {
      method: "get",
    },
  );
}

export async function createMooringLineType(
  nodeId: string,
  mooringLineType: Partial<MooringLineType>,
): Promise<MooringLineType> {
  return fetchEnhancerWithToken(`/api/mooring/node/${nodeId}`, {
    method: "post",
    body: JSON.stringify(mooringLineType),
    headers: {
      "Content-Type": "application/json",
    },
  }).then(async (res) => {
    if (!res.ok) throw new Error("Failed to create");
    const j = await res.json();
    const ml = _MooringLineType.parse(j);
    return ml;
  });
}
export async function updateMooringLineType(
  nodeId: string,
  mooringLineType: Partial<MooringLineType>,
) {
  return fetchEnhancerWithToken(
    `/api/mooring/node/${nodeId}/type/${mooringLineType.id}`,
    {
      method: "post",
      body: JSON.stringify(mooringLineType),
      headers: {
        "Content-Type": "application/json",
      },
    },
  ).then((res) => {
    if (!res.ok) throw new Error("Failed to update");
  });
}

export async function deleteMooringLineType(
  nodeId: string,
  mooringLineTypeId: string,
) {
  return fetchEnhancerWithToken(
    `/api/mooring/node/${nodeId}/type/${mooringLineTypeId}`,
    {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
    },
  ).then((res) => {
    if (!res.ok) throw new Error("Failed to delete");
  });
}
