/// <reference types="vite-plugin-svgr/client" />

import Tooltip from "../../General/Tooltip";
import {
  BottomTableWrapper,
  IconWrapper,
  InfoRow,
  ResultWrapper,
  RowKey,
  RowValue,
  SumTotalValue,
  TableWrapper,
} from "../styles";
import { CostUnit, unitToScalingMap } from "../../../types/financial";
import {
  ScrollBody,
  useShowScrollShadow,
} from "../../../hooks/useShowScrollShadow";
import LcoeFormula from "./LcoeFormula";
import { MILLION } from "../../../constants/financialAnalysis";
import InfoIcon from "@icons/24/Information.svg?react";
import { currentYear } from "utils/inflation";
import { FinanceId } from "components/Finance/state";
import { orLoader } from "components/Loading/Skeleton";
import NewToggleableList from "components/ToggleableList/NewToggleableList";
import { roundToDecimal } from "utils/utils";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { formatGWhConditional } from "components/ProductionV2/format";
import { useLcoe } from "components/Finance/lcoe";
import { DownloadLcoeDataButton } from "./DownloadLcoeData";
import { IconREMSize, typography } from "styles/typography";
import {
  Divider,
  OverlineText,
  OverlineTextWrapper,
  ResultValue,
} from "components/General/GeneralSideModals.style";
import { ButtonWrapper } from "components/UploadModal/components/shared";
import Button from "components/General/Button";
import Dashboard from "@icons/24/Dashboard.svg?react";
import { DashboardModalType, modalTypeOpenAtom } from "state/modal";
import { ColoredGrid } from "components/General/Form";
import { branchSelectedCostConfigurationAtom } from "state/costConfigurations";
import { branchIdSelector, projectIdSelector } from "state/pathParams";
import { useCapexMillionPV } from "components/Finance/capex";
import GeneralCostConfidenceBar from "../ConfidenceBar";

export const Lcoe = ({ id }: { id: FinanceId }) => {
  const { scrollBodyRef } = useShowScrollShadow(true);
  const projectId = useRecoilValue(projectIdSelector);
  const branchId = useRecoilValue(branchIdSelector);

  const setModalType = useSetRecoilState(modalTypeOpenAtom);
  const selectedCostConfig = useRecoilValue(
    branchSelectedCostConfigurationAtom({ projectId, branchId }),
  );
  const useConfidenceLevel = selectedCostConfig?.useConfidenceLevel;
  const { costs: capexCosts } = useCapexMillionPV(id);

  const {
    inputs,
    results: { devex, capex, opex, decom, lcoe },
  } = useLcoe(id);

  return (
    <>
      <ScrollBody ref={scrollBodyRef}>
        <TableWrapper>
          <InfoRow>
            <IconWrapper>
              <IconREMSize height={1.4} width={1.4}>
                <InfoIcon />
              </IconREMSize>
            </IconWrapper>

            <p>{`NPV for ${currentYear()}`}</p>
          </InfoRow>
          <NewToggleableList
            title="DEVEX"
            chevronSize="1rem"
            defaultOpen={false}
            value={
              <ResultValue>
                <span>
                  <strong>{devex.npv.valueMaybe()}</strong>{" "}
                  {CostUnit.millionEuro}
                </span>
              </ResultValue>
            }
            loading={devex.cashFlow.state === "loading"}
          >
            {orLoader(devex.cashFlow, (cashFlow) => (
              <>
                {cashFlow.map(({ year, value }) => (
                  <ColoredGrid key={`capex-${year}`}>
                    <ResultValue>{year}</ResultValue>
                    <ResultValue>
                      <strong>{roundToDecimal(value / MILLION, 1)}</strong>{" "}
                      {CostUnit.millionEuro}
                    </ResultValue>
                  </ColoredGrid>
                ))}
              </>
            ))}
          </NewToggleableList>

          <NewToggleableList
            title="CAPEX"
            chevronSize="1rem"
            defaultOpen={false}
            value={
              <ResultValue>
                <span>
                  <strong>{capex.npv.valueMaybe()}</strong>{" "}
                  {CostUnit.millionEuro}
                </span>
              </ResultValue>
            }
            loading={capex.cashFlow.state === "loading"}
          >
            {orLoader(capex.cashFlow, (cashFlow) => (
              <>
                {useConfidenceLevel && (
                  <GeneralCostConfidenceBar
                    key={"capex"}
                    generalCosts={capexCosts.valueMaybe()}
                  />
                )}
                {cashFlow.map(({ year, value }, index) => (
                  <ColoredGrid key={`capex-${year}`} noColor={index % 2 !== 0}>
                    <RowKey>{year}</RowKey>
                    <ResultValue>
                      <strong> {roundToDecimal(value / MILLION, 1)}</strong>{" "}
                      {CostUnit.millionEuro}
                    </ResultValue>
                  </ColoredGrid>
                ))}
                {
                  <ColoredGrid key={`capex-contingency`} noColor>
                    <p>{"Includes contingency"}</p>
                  </ColoredGrid>
                }
              </>
            ))}
          </NewToggleableList>
          <NewToggleableList
            title="OPEX"
            chevronSize="1rem"
            defaultOpen={false}
            value={
              <ResultValue>
                <span>
                  <strong>{opex.npv.valueMaybe()}</strong>{" "}
                  {CostUnit.millionEuro}
                </span>
              </ResultValue>
            }
            loading={opex.npv.state === "loading"}
          >
            <div>
              {orLoader(opex.distribution, (distribution) => (
                <>
                  {distribution.map(({ id, name, percent }, index) => (
                    <ColoredGrid key={id} noColor={index % 2 !== 0}>
                      <p>{name}</p>
                      <ResultValue>
                        <strong>{percent}</strong> {CostUnit.percent}
                      </ResultValue>
                    </ColoredGrid>
                  ))}
                </>
              ))}
            </div>
            <div>
              {orLoader(opex.cashFlow, (cashFlow) => (
                <>
                  {cashFlow.slice(0, 1).map(({ year, value }, index) => (
                    <ColoredGrid
                      style={{ gridTemplateColumns: "2fr 1fr" }}
                      key={`capex-${year}`}
                      noColor={index % 2 !== 1}
                    >
                      <p>First production year ({year}) </p>
                      <ResultValue>
                        <strong>{roundToDecimal(value / MILLION, 1)}</strong>{" "}
                        {CostUnit.millionEuro}
                      </ResultValue>
                    </ColoredGrid>
                  ))}
                </>
              ))}
            </div>
          </NewToggleableList>

          <NewToggleableList
            title="DECOM"
            chevronSize="1rem"
            defaultOpen={false}
            value={
              <ResultValue>
                <span>
                  <strong>{decom.npv.valueMaybe()}</strong>{" "}
                  {CostUnit.millionEuro}
                </span>
              </ResultValue>
            }
            loading={decom.npv.state === "loading"}
          >
            {orLoader(decom.cashFlow, (cashFlow) => (
              <>
                {cashFlow.map(({ year, value }, index) => (
                  <ColoredGrid key={`capex-${year}`} noColor={index % 2 !== 0}>
                    <p>{year}</p>
                    <ResultValue>
                      <strong>{roundToDecimal(value / MILLION, 1)}</strong>{" "}
                      {CostUnit.millionEuro}
                    </ResultValue>
                  </ColoredGrid>
                ))}
              </>
            ))}
          </NewToggleableList>
          <OverlineTextWrapper>
            <OverlineText>Net AEP</OverlineText>

            <RowValue>
              {orLoader(inputs.netAEP, (value) => (
                <ResultValue>
                  <strong>{formatGWhConditional(value, true)}</strong>{" "}
                  {CostUnit.gigaWattHours}
                </ResultValue>
              ))}
            </RowValue>
          </OverlineTextWrapper>
          <OverlineTextWrapper>
            <OverlineText>Discount rate</OverlineText>
            <RowValue>
              {orLoader(inputs.discountRate, (value) => (
                <ResultValue>
                  <strong>{`${roundToDecimal(
                    value / unitToScalingMap[CostUnit.percent],
                    1,
                  )}`}</strong>{" "}
                  {CostUnit.percent}
                </ResultValue>
              ))}
            </RowValue>
          </OverlineTextWrapper>
          <OverlineTextWrapper>
            <OverlineText>Inflation rate</OverlineText>
            <RowValue>
              {orLoader(inputs.inflationRate, (value) => (
                <ResultValue>
                  <strong>{`${roundToDecimal(
                    value / unitToScalingMap[CostUnit.percent],
                    1,
                  )} `}</strong>{" "}
                  {CostUnit.percent}
                </ResultValue>
              ))}
            </RowValue>
          </OverlineTextWrapper>
          <OverlineTextWrapper>
            <OverlineText>CAPEX Contingency</OverlineText>
            <RowValue>
              {orLoader(inputs.capexContingency, (value) => (
                <ResultValue>
                  <strong>{`${roundToDecimal(
                    value / unitToScalingMap[CostUnit.percent],
                    1,
                  )}`}</strong>{" "}
                  {CostUnit.percent}
                </ResultValue>
              ))}
            </RowValue>
          </OverlineTextWrapper>
          <OverlineTextWrapper>
            <OverlineText>Life time</OverlineText>
            <RowValue>
              {orLoader(inputs.lifeTime, (value) => (
                <ResultValue>
                  <strong>{value}</strong> years
                </ResultValue>
              ))}
            </RowValue>
          </OverlineTextWrapper>
        </TableWrapper>
      </ScrollBody>
      <Divider />
      <BottomTableWrapper>
        <ResultWrapper>
          <Tooltip
            text="LCoE"
            content={<LcoeFormula />}
            position="bottomLeft"
            maxWidth="40rem"
          >
            <span style={{ ...typography.sub1, paddingRight: "0.8rem" }}>
              LCoE
            </span>
            <IconREMSize height={1.4} width={1.4}>
              <InfoIcon />
            </IconREMSize>
          </Tooltip>

          {orLoader(lcoe, (l) => (
            <SumTotalValue>
              <strong id={"lcoe-value"}>{l.toFixed(1)}</strong> €/MWh
            </SumTotalValue>
          ))}
        </ResultWrapper>
        <ButtonWrapper style={{ padding: 0 }}>
          <DownloadLcoeDataButton id={id} buttonType="text" />
          <Button
            text="View in dashboard"
            buttonType="secondary"
            icon={<Dashboard />}
            onClick={() => {
              setModalType({
                modalType: DashboardModalType,
                metadata: { id: "vind-preset-dashboard-cost" },
              });
            }}
          />
        </ButtonWrapper>
      </BottomTableWrapper>
      {/* </Suspense> */}
    </>
  );
};
