import { useRecoilValue } from "recoil";
import { nodeIdSelector, organisationIdSelector } from "state/pathParams";

const useFolderId = () => {
  const nodeId = useRecoilValue(nodeIdSelector);
  const organisationId = useRecoilValue(organisationIdSelector);

  return nodeId ?? organisationId;
};

export default useFolderId;
