import mapboxgl from "mapbox-gl";
import React, { Suspense, useEffect, useMemo } from "react";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { getPaddedPark, getTurbinesSelectorFamily } from "../state/layout";
import { mapRefAtom } from "../state/map";
import { getSurroundingTurbineFeaturesWithZonesSelector } from "../state/layoutUtils";
import { allSimpleTurbineTypesSelector } from "../state/turbines";
import { showWakeAtom, wakeDirectionAtom } from "../state/windStatistics";
import {
  branchIdSelector_,
  parkIdSelector_,
  projectIdSelector_,
} from "../state/pathParams";
import { isDefined } from "../utils/predicates";
import { LAYER_DEBUG_PRINT } from "../state/debug";
import * as turf from "@turf/turf";
import { branchSelectedConfigurationAtomFamily } from "../state/configuration";
import Polygon from "../components/MapFeatures/Polygon";
import { colors } from "../styles/colors";
import {
  getBeforeLayer,
  wakePaddedParkLayerId,
} from "../components/Mapbox/utils";
import { scream } from "../utils/sentry";
import { existingTurbinesFeaturesSelector } from "state/projectLayers";

const wakePaddedParkSourceId = "wake-padded-park-source";

class WakeLayer {
  id: string;
  type: string;
  source: string;
  points: number[];
  scale: number;
  turbineLocations: number[];
  wakeDirection: number;
  rotorDiameters: number[];
  program: WebGLProgram | undefined;
  aPos: GLint | undefined;
  buffer: WebGLBuffer | undefined;
  scaleLocation: WebGLUniformLocation | null;
  turbinesLocation: WebGLUniformLocation | null;

  constructor(
    points: number[],
    scale: number,
    turbineLocations: number[],
    wakeDirection: number,
    rotorDiameters: number[],
  ) {
    this.id = "wake";
    this.type = "custom";
    this.source = "turbines";
    this.points = points;
    this.scale = scale;
    this.turbineLocations = turbineLocations;
    this.wakeDirection = wakeDirection;
    this.rotorDiameters = rotorDiameters;
    this.scaleLocation = null;
    this.turbinesLocation = null;
  }
  onAdd(map: mapboxgl.Map, gl: WebGLRenderingContext) {
    LAYER_DEBUG_PRINT && console.log("WakeLayer.onAdd");
    // create GLSL source for vertex shader
    const vertexSource = `
              precision highp float; 
              uniform mat4 u_matrix;
              attribute vec2 a_pos;
              varying vec2 cc;
              void main() {
                  cc = a_pos;
                  gl_Position = u_matrix * vec4(a_pos, 0.0, 1.0);
              }`;

    // create GLSL source for fragment shader
    const fragmentSource = `
              precision highp float;
              const int max_turbines = 500;
              const float max_distance = 7000.;
              const float wake_decay_coefficient = 0.05; // https://www.emd-international.com/files/windpro/March-19_Revised%20offshore%20PARK%20WDC%20recommendations_v1.pdf
              const float thrust_coefficient = 0.8; // Dependent on turbine type and wind speed 

              varying vec2 cc;
              uniform int u_number_of_turbines;
              uniform vec2 u_turbines[max_turbines];
              uniform float u_rotorDiameters[max_turbines];
              uniform float u_scale;
              uniform vec2 u_dir;

              float Jensen(vec2 turbine, vec2 point, float rotorDiameter) {
                vec2 vector = (point - turbine) / u_scale;
                vec2 unit = u_dir/ length(u_dir);
                bool behind_turbine =  dot(vector, unit) > 0.;
                if (!behind_turbine) {
                  return 0.;
                }

                vec2 projected_vector = unit * dot(vector, unit);
                float x = length(projected_vector);
                if (x > max_distance) {
                  return 0.;
                }

                float r = rotorDiameter * 0.5;
                float max_centerline_distance = r + x * wake_decay_coefficient;
                vec2 perpendicular_vector = projected_vector - vector;
                float centerline_distance = length(perpendicular_vector);
                
                float counter = 1. - sqrt(1. - thrust_coefficient);
                float denominator = pow(1. + wake_decay_coefficient * x / r, 2.);
                float sigma = 0.4 * max_centerline_distance;
                float gaussian = 1. / sigma / 2.507 * exp(-0.5 * pow(centerline_distance / sigma, 2.));
                return 100. * pow(counter / denominator, 2.) * gaussian;
              }

              // Jensen wake model

              void main() {
                float wake = 0.;
                for(int i=0;i<max_turbines;i++) {
                  wake += Jensen(u_turbines[i], cc, u_rotorDiameters[i]);
                  if (i > u_number_of_turbines) {
                    break;
                  }
                }
                gl_FragColor = vec4(1., 1., 1., sqrt(wake));
              }`;

    // create a vertex shader
    const vertexShader = gl.createShader(gl.VERTEX_SHADER);
    if (!vertexShader) throw scream("wake: could not create vertexShader");
    gl.shaderSource(vertexShader, vertexSource);
    gl.compileShader(vertexShader);

    // create a fragment shader
    const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
    if (!fragmentShader) throw scream("wake: could not create fragmentShader");
    gl.shaderSource(fragmentShader, fragmentSource);
    gl.compileShader(fragmentShader);

    // link the two shaders into a WebGL program
    const program = gl.createProgram();
    if (!program) throw scream("wake: could not create program");
    this.program = program;
    gl.attachShader(this.program, vertexShader);
    gl.attachShader(this.program, fragmentShader);
    gl.linkProgram(this.program);

    this.aPos = gl.getAttribLocation(this.program, "a_pos");
    // create and initialize a WebGLBuffer to store vertex and color data
    const buffer = gl.createBuffer();
    if (!buffer) throw scream("wake: could not create buffer");
    this.buffer = buffer;
    gl.bindBuffer(gl.ARRAY_BUFFER, this.buffer);
    gl.bufferData(
      gl.ARRAY_BUFFER,
      new Float32Array(this.points),
      gl.STATIC_DRAW,
    );

    this.scaleLocation = gl.getUniformLocation(this.program, "u_scale");
    this.turbinesLocation = gl.getUniformLocation(this.program, "u_turbines");
  }
  render(gl: WebGLRenderingContext, matrix: number[]) {
    if (!this.program || this.aPos === undefined || !this.buffer) return;
    LAYER_DEBUG_PRINT && console.time("WakeLayer.render");
    gl.useProgram(this.program);

    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

    gl.uniformMatrix4fv(
      gl.getUniformLocation(this.program, "u_matrix"),
      false,
      matrix,
    );

    gl.uniform1f(this.scaleLocation, this.scale);
    gl.uniform1fv(
      gl.getUniformLocation(this.program, "u_rotorDiameters"),
      this.rotorDiameters,
    );
    gl.uniform1i(
      gl.getUniformLocation(this.program, "u_number_of_turbines"),
      this.turbineLocations.length,
    );
    const direction = this.wakeDirection - 90;
    const uDir = [
      -Math.cos((direction * Math.PI) / 180),
      -Math.sin((direction * Math.PI) / 180),
    ];
    gl.uniform2fv(gl.getUniformLocation(this.program, "u_dir"), uDir);
    gl.uniform2fv(this.turbinesLocation, this.turbineLocations);

    gl.bindBuffer(gl.ARRAY_BUFFER, this.buffer);
    gl.enableVertexAttribArray(this.aPos);
    gl.vertexAttribPointer(this.aPos, 2, gl.FLOAT, false, 0, 0);

    gl.drawArrays(gl.TRIANGLE_FAN, 0, 4);
    LAYER_DEBUG_PRINT && console.timeEnd("WakeLayer.render");
  }
}

const Wake = () => {
  const showWake = useRecoilValue(showWakeAtom);
  if (!showWake) return null;
  return (
    <Suspense fallback={null}>
      <WakeActive />
    </Suspense>
  );
};

const WakeActive = () => {
  const parkId = useRecoilValue(parkIdSelector_);
  const projectId = useRecoilValue(projectIdSelector_);
  const branchId = useRecoilValue(branchIdSelector_);
  const turbines = useRecoilValue(getTurbinesSelectorFamily({ parkId }));
  const configuration = useRecoilValue(
    branchSelectedConfigurationAtomFamily({ projectId, branchId }),
  );
  const paddedPark = useRecoilValue(
    getPaddedPark({
      parkId,
      branchId,
      maxDistance: configuration?.wakeAnalysis.neighbourWakeMaxDistance,
    }),
  );
  const surroundingFeatures = useRecoilValue(
    getSurroundingTurbineFeaturesWithZonesSelector({
      parkId,
      branchId,
      maxDistance: configuration?.wakeAnalysis.neighbourWakeMaxDistance,
    }),
  );

  const wakeDirection = useRecoilValue(wakeDirectionAtom);
  const allTurbines = useRecoilValueLoadable(allSimpleTurbineTypesSelector);

  const validExistingTurbines = useRecoilValue(
    existingTurbinesFeaturesSelector,
  ).filter((t) => t.properties.power);

  const closeValidExistingTurbines = useMemo(() => {
    if (!paddedPark) return [];
    const paddedParkPolygon = turf.polygon(paddedPark);
    return validExistingTurbines.filter((t) =>
      turf.inside(t, paddedParkPolygon),
    );
  }, [paddedPark, validExistingTurbines]);

  const turbineTypes = useMemo(() => {
    if (allTurbines.state !== "hasValue") return;
    return new Map(allTurbines.contents.map((t) => [t.id, t]));
  }, [allTurbines]);

  const map = useRecoilValue(mapRefAtom);

  const features = useMemo(() => {
    const layoutFeatures = [...turbines];
    if (!configuration?.wakeAnalysis.neighbourWake) return layoutFeatures;
    return layoutFeatures.concat(surroundingFeatures);
  }, [
    turbines,
    surroundingFeatures,
    configuration?.wakeAnalysis.neighbourWake,
  ]);

  const turbineLonlats = useMemo(() => {
    const regularTurbines = features.map((f) => f.geometry.coordinates);
    if (!configuration?.wakeAnalysis.neighbourWake) return regularTurbines;
    const existingTurbines = closeValidExistingTurbines.map(
      (f) => f.geometry.coordinates,
    );
    return regularTurbines.concat(existingTurbines);
  }, [
    features,
    closeValidExistingTurbines,
    configuration?.wakeAnalysis.neighbourWake,
  ]);

  const rotorDiameters = useMemo(() => {
    if (!turbineTypes) return;
    const regular = features.map(
      (f) => turbineTypes.get(f.properties.turbineTypeId)?.diameter,
    );
    if (!configuration?.wakeAnalysis.neighbourWake) return regular;
    const existing = closeValidExistingTurbines.map((t) =>
      Math.exp((Math.log(t.properties.power! * 1e6) - Math.log(821)) / 1.79),
    );
    return regular.concat(existing);
  }, [
    features,
    turbineTypes,
    closeValidExistingTurbines,
    configuration?.wakeAnalysis.neighbourWake,
  ]);

  const wakeLayer = useMemo(() => {
    if (turbineLonlats.length === 0) return null;

    const turbinePoints = turbineLonlats.map((v) =>
      mapboxgl.MercatorCoordinate.fromLngLat({
        lng: v[0],
        lat: v[1],
      }),
    );
    const longitudes = turbineLonlats.map((t) => t[0]);
    const latitudes = turbineLonlats.map((t) => t[1]);

    const buffer = 0.4;
    const lonMin = Math.min(...longitudes);
    const latMin = Math.min(...latitudes);
    const lonMax = Math.max(...longitudes);
    const latMax = Math.max(...latitudes);
    const bbox = [
      lonMin - buffer,
      latMin - buffer,
      lonMax + buffer,
      latMax + buffer,
    ];
    const centerCoord = mapboxgl.MercatorCoordinate.fromLngLat({
      lng: (lonMin + lonMax) / 2,
      lat: (latMin + latMax) / 2,
    });
    const lowerLeft = mapboxgl.MercatorCoordinate.fromLngLat({
      lng: bbox[0],
      lat: bbox[1],
    });
    const lowerRight = mapboxgl.MercatorCoordinate.fromLngLat({
      lng: bbox[2],
      lat: bbox[1],
    });
    const upperRight = mapboxgl.MercatorCoordinate.fromLngLat({
      lng: bbox[2],
      lat: bbox[3],
    });
    const upperLeft = mapboxgl.MercatorCoordinate.fromLngLat({
      lng: bbox[0],
      lat: bbox[3],
    });

    const scale = centerCoord.meterInMercatorCoordinateUnits();

    const turbineLocations = turbinePoints.flatMap((v) => [v.x, v.y]);

    const points = [
      lowerLeft.x,
      lowerLeft.y,
      lowerRight.x,
      lowerRight.y,
      upperRight.x,
      upperRight.y,
      upperLeft.x,
      upperLeft.y,
    ];
    return new WakeLayer(
      points,
      scale,
      turbineLocations,
      wakeDirection,
      rotorDiameters?.filter(isDefined) ?? [],
    );
  }, [wakeDirection, rotorDiameters, turbineLonlats]);

  useEffect(() => {
    if (!map || !wakeLayer) return;
    map.addLayer(wakeLayer as any);

    return () => {
      map.removeLayer(wakeLayer.id);
    };
  }, [map, wakeLayer]);

  return map && paddedPark && configuration?.wakeAnalysis.neighbourWake ? (
    <Polygon
      features={[turf.polygon(paddedPark)]}
      paint={{
        "fill-color": colors.primary,
        "fill-opacity": 0.1,
      }}
      sourceId={wakePaddedParkSourceId}
      layerId={wakePaddedParkLayerId}
      map={map}
      beforeLayer={getBeforeLayer(map, wakePaddedParkLayerId)}
    />
  ) : null;
};

export default Wake;
