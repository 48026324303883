/// <reference types="vite-plugin-svgr/client" />
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import CloseIcon from "@icons/24/Close.svg?react";
import { IconBtn } from "components/General/Icons";
import { SubtitleWithLine } from "components/General/GeneralSideModals.style";
import { ColoredGrid } from "components/General/Form";
import Button from "components/General/Button";
import ProjectIcon from "@icons/24/ProjectGlobe.svg?react";
import { PreviewItem, showComponentsPreviewAtom } from "./state";
import {
  Divider,
  GridKey,
  GridValue,
  HeaderRow,
  Modal,
  Subtitle,
  Title,
  Wrapper,
} from "./style";
import { currentSubstationTypesState } from "state/substationType";
import { SUBSTATION_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useSubstationSettings";
import { FeatureSettingsShortcut } from "components/ConfigurationModal/Cost/Capex/FeatureSettingsShortcut";
import { useClickOutside } from "hooks/useClickOutside";

const SubstationComponentsPreview = () => {
  const [, setShowComponentsPreview] = useRecoilState(
    showComponentsPreviewAtom,
  );

  const onClose = useCallback(
    () => setShowComponentsPreview({ show: false }),
    [setShowComponentsPreview],
  );

  const containerRef = useRef<HTMLDivElement>(null);
  useClickOutside(containerRef, onClose, () => false, {
    ignoreDragClicks: true,
  });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  const allSubstationTypes = useRecoilValue(currentSubstationTypesState);

  const substationItems: PreviewItem[] = useMemo(
    () =>
      allSubstationTypes.map((s) => ({
        id: s.id,
        name: s.name,
        cost: s.cost,
        costUnit: s.costUnit,
      })),
    [allSubstationTypes],
  );

  return (
    <Wrapper>
      <Modal ref={containerRef}>
        <HeaderRow>
          <div>
            <Title>Substation costs</Title>
            <Subtitle>Specified in Components</Subtitle>
          </div>

          <IconBtn size="1.5rem" onClick={onClose}>
            <CloseIcon />
          </IconBtn>
        </HeaderRow>

        <SubtitleWithLine
          textIcon={<ProjectIcon />}
          text={"Project substations"}
        ></SubtitleWithLine>
        <ColoredGrid>
          {substationItems.map((s) => (
            <React.Fragment key={s.id}>
              <GridKey>{s.name}</GridKey>
              <GridValue>
                <strong>{s.cost}</strong>
                {s.costUnit}
                <FeatureSettingsShortcut
                  menuId={SUBSTATION_MENU_ID}
                  itemId={s.id}
                />
              </GridValue>
            </React.Fragment>
          ))}
        </ColoredGrid>
        <Divider />
        <div
          style={{ display: "flex", justifyContent: "end", paddingTop: "1rem" }}
        >
          <Button buttonType="secondary" text="Close" onClick={onClose} />
        </div>
      </Modal>
    </Wrapper>
  );
};

export default SubstationComponentsPreview;
