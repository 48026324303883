import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import {
  TurbineFeatureWithFoundation,
  allFixedTypesSelector,
  allFloaterTypesSelector,
  getAllScaledStatisticsForFloatingFoundationsInPark,
  hasFixedFoundationSelector,
  hasFloatingFoundationSelector,
  isTurbineFeatureWithFoundation,
  hasDetailedMonopileSelector,
} from "../../../state/foundations";
import {
  getInvalidTypesInParkAndBranch,
  getTurbinesSelectorFamily,
} from "../../../state/layout";
import { allSimpleTurbineTypesSelector } from "../../../state/turbines";
import { spaceLarge, spaceMedium } from "../../../styles/space";
import groupBy from "../../../utils/groupBy";
import minMax from "../../../utils/minMax";
import {
  getSimpleFixedMassesSelectorFamily,
  getDetailedMonopileMassesSelectorFamily,
} from "../../RightSide/InfoModal/FoundationModal/fixed/state";
import { valueRounding } from "../../RightSide/InfoModal/FoundationModal/utils";
import { useDashboardContext } from "../Dashboard";
import { CenterContainer, LoadingState, SafeCard } from "./Base";
import { dedup, undefMap } from "../../../utils/utils";
import { ReactNode } from "react";
import { isDefined } from "../../../utils/predicates";
import { SkeletonBlock } from "../../Loading/Skeleton";
import { pointInPolygon } from "utils/geometry";
import { InvalidTypes, InvalidTypesDisplayText } from "types/invalidTypes";
import { useBathymetry } from "hooks/bathymetry";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import Table from "components/Dashboard/Table";

export const TableHeader = styled.h4`
  margin: ${spaceMedium} ${spaceLarge} 0 ${spaceLarge};
`;

const undefToSkeleton = <T,>(t: T | undefined): T | ReactNode =>
  isDefined(t) ? (
    t
  ) : (
    <SkeletonBlock style={{ height: "1rem", width: "100%" }} />
  );

const FloatingFoundationStatistics = () => {
  const { park } = useDashboardContext();
  const parkId = park.id;
  const turbines = useRecoilValue(getTurbinesSelectorFamily({ parkId }));
  const data = useRecoilValue(
    getAllScaledStatisticsForFloatingFoundationsInPark(parkId),
  );
  const allFloaters = useRecoilValue(allFloaterTypesSelector);
  const allTurbineTypes = useRecoilValue(allSimpleTurbineTypesSelector);

  const formattedData = data.map((d) => {
    const currentFoundation = allFloaters.find((f) => f.id === d.foundationId);
    const currentTurbineType = allTurbineTypes.find(
      (t) => t.id === d.turbineTypeId,
    );
    const numberOfTurbines = turbines.filter(
      (t) =>
        t.properties.turbineTypeId === d.turbineTypeId &&
        t.properties.foundationId === d.foundationId,
    ).length;

    return {
      ...d,
      turbineTypeName: currentTurbineType?.name,
      foundationName: currentFoundation?.name,
      material: currentFoundation?.material,
      numberOfTurbines: numberOfTurbines,
      primaryWeight:
        d.scaledConcreteWeight && d.scaledConcreteWeight > 0
          ? undefMap(d.scaledConcreteWeight, (w) => `${w.toLocaleString()} t`)
          : undefMap(
              d.scaledPrimarySteelWeight,
              (w) => `${w.toLocaleString()} t`,
            ),
      displacement: undefMap(
        d.scaledDisplacementVolume,
        (d) => `${d.toLocaleString()} m³`,
      ),
      draft: undefMap(d.scaledDraft, (d) => `${d} m`),
    };
  });
  return (
    <Table>
      <thead>
        <tr>
          <th>Turbine</th>
          <th>Foundation</th>
          <th>Amount</th>
          <th>Material</th>
          <th>Primary weight</th>
          <th>Displacement</th>
          <th>Draft</th>
        </tr>
      </thead>
      <tbody>
        {formattedData.map((d) => (
          <tr key={d.turbineTypeId + d.foundationId}>
            <td>{undefToSkeleton(d.turbineTypeName)}</td>
            <td>{undefToSkeleton(d.foundationName)}</td>
            <td>{undefToSkeleton(d.numberOfTurbines)}</td>
            <td>{undefToSkeleton(d.material)}</td>
            <td>{undefToSkeleton(d.primaryWeight)}</td>
            <td>{undefToSkeleton(d.displacement)}</td>
            <td>{undefToSkeleton(d.draft)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export const useDetailedMonopileStatistics = (
  turbinesWithDetailedMonopile: TurbineFeatureWithFoundation[],
  rasterId: string,
): {
  turbineTypeId: string;
  foundationId: string;
  numberOfTurbines: number;
  weight: string;
}[] => {
  const monopileStats = useRecoilValue(
    getDetailedMonopileMassesSelectorFamily({
      turbinesWithDetailedMonopile,
      rasterId,
    }),
  );

  if (!monopileStats) return []; // TODO: Do we need to handle this better?

  const { foundationDetails } = monopileStats;

  const monopileDesignsByTurbineTypeId = groupBy(
    foundationDetails,
    ({ turbineTypeId }) => turbineTypeId,
  );

  const turbineMonopileCombinations = Array.from(
    new Set(
      turbinesWithDetailedMonopile.map(
        (t) => `${t.properties.turbineTypeId}-${t.properties.foundationId}`,
      ),
    ),
  );

  return turbineMonopileCombinations.map((combination) => {
    const [t_id, f_id] = combination.split("-");

    const monopileDesign = monopileDesignsByTurbineTypeId[t_id];

    const numberOfTurbines = monopileDesign.length;
    const pileMassStats = minMax(monopileDesign, (d) => d.pileMass);
    const primarySteelMin =
      pileMassStats && valueRounding(pileMassStats.min / 1000, 10);
    const primarySteelMax =
      pileMassStats && valueRounding(pileMassStats.max / 1000, 10);

    const weight =
      numberOfTurbines !== 1 && pileMassStats
        ? `${primarySteelMin?.toLocaleString()} - ${primarySteelMax?.toLocaleString()}`
        : `${primarySteelMax}`;

    return {
      turbineTypeId: t_id,
      foundationId: f_id,
      numberOfTurbines: numberOfTurbines,
      weight,
    };
  });
};

export const useSimpleFixedStatistics = (
  turbinesWithSimpleFixed: TurbineFeatureWithFoundation[],
  rasterId: string,
): {
  turbineTypeId: string;
  foundationId: string;
  numberOfTurbines: number;
  weight: string;
}[] => {
  const { foundationMasses } = useRecoilValue(
    getSimpleFixedMassesSelectorFamily({
      turbinesWithSimpleFixed,
      rasterId,
    }),
  );

  const simpleFixedDesignsByIds = groupBy(
    foundationMasses,
    ({ turbineTypeId, foundationId }) => `${turbineTypeId},${foundationId}`,
  );

  const turbineSimpleFixedCombinations = dedup(
    turbinesWithSimpleFixed?.map(
      (t) => `${t.properties.turbineTypeId}-${t.properties.foundationId}`,
    ) ?? [],
  );

  return turbineSimpleFixedCombinations.map((combination) => {
    const [t_id, f_id] = combination.split("-");
    const masses = simpleFixedDesignsByIds[`${t_id},${f_id}`];
    const numberOfTurbines = masses.length;

    const massStats = minMax(masses, (d) => d.mass);
    const massMin = massStats && valueRounding(massStats.min / 1000, 10);
    const massMax = massStats && valueRounding(massStats.max / 1000, 10);

    const weight =
      numberOfTurbines !== 1 && massStats
        ? `${massMin?.toLocaleString()} - ${massMax?.toLocaleString()}`
        : `${massMax}`;
    return {
      turbineTypeId: t_id,
      foundationId: f_id,
      numberOfTurbines: numberOfTurbines,
      weight,
    };
  });
};

const FixedFoundationStatistics = ({
  tempLayoutFoundations,
}: {
  tempLayoutFoundations: TurbineFeatureWithFoundation[];
}) => {
  const { park, projectId, branch } = useDashboardContext();

  const bath = useBathymetry({
    projectId,
    branchId: branch.id,
    featureId: park.id,
  }).getValue();

  if (!bath) return null;

  return (
    <FixedFoundationStatisticsInner
      rasterId={bath.id}
      tempLayoutFoundations={tempLayoutFoundations}
    />
  );
};

const FixedFoundationStatisticsInner = ({
  rasterId,
  tempLayoutFoundations,
}: {
  rasterId: string;
  tempLayoutFoundations: TurbineFeatureWithFoundation[];
}) => {
  const allFixed = useRecoilValue(allFixedTypesSelector);
  const allTurbineTypes = useRecoilValue(allSimpleTurbineTypesSelector);

  const hasDetailedMonopile = useRecoilValue(hasDetailedMonopileSelector);
  const turbinesWithDetailedMonopile =
    tempLayoutFoundations.filter(hasDetailedMonopile);
  const turbinesWithSimpleFixed = tempLayoutFoundations.filter(
    (t) => !turbinesWithDetailedMonopile.includes(t),
  );

  const monopileStatistics = useDetailedMonopileStatistics(
    turbinesWithDetailedMonopile,
    rasterId,
  );

  const jacketStatistics = useSimpleFixedStatistics(
    turbinesWithSimpleFixed,
    rasterId,
  );

  const allFixedFoundationStatistics =
    monopileStatistics.concat(jacketStatistics);

  const allFixedFoundationStatisticsFormatted =
    allFixedFoundationStatistics.map((ffs) => {
      const currentFoundation = allFixed.find((f) => f.id === ffs.foundationId);
      const currentTurbineType = allTurbineTypes.find(
        (t) => t.id === ffs.turbineTypeId,
      );
      return {
        ...ffs,
        turbineTypeName: currentTurbineType?.name,
        foundationName: currentFoundation?.name,
      };
    });

  return (
    <Table>
      <thead>
        <tr>
          <th>Turbine</th>
          <th>Foundation</th>
          <th>Amount</th>
          <th>Weight(s)</th>
        </tr>
      </thead>
      <tbody>
        {rasterId &&
          allFixedFoundationStatisticsFormatted.map((d) => (
            <tr key={d.turbineTypeId + d.foundationId}>
              <td>{d.turbineTypeName}</td>
              <td>{d.foundationName}</td>
              <td>{d.numberOfTurbines}</td>
              <td>{d.weight} t</td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

const FoundationKeyStatisticsDetails = () => {
  const { park, branch } = useDashboardContext();
  const turbines = useRecoilValue(
    getTurbinesSelectorFamily({ parkId: park.id }),
  );
  const validTurbines = turbines.filter((t) =>
    pointInPolygon(t.geometry, park.geometry),
  );
  const turbinesWithFoundation =
    validTurbines?.filter(isTurbineFeatureWithFoundation) ?? [];

  const hasFloatingFoundation = useRecoilValue(hasFloatingFoundationSelector);
  const hasFixedFoundation = useRecoilValue(hasFixedFoundationSelector);

  const turbinesWithFloatingFoundations = turbinesWithFoundation.filter(
    hasFloatingFoundation,
  );
  const turbinesWithFixedFoundations =
    turbinesWithFoundation.filter(hasFixedFoundation);

  const includesFloaters = turbinesWithFloatingFoundations.length > 0;
  const includesFixed = turbinesWithFixedFoundations.length > 0;

  const invalidTypes = useRecoilValue(
    getInvalidTypesInParkAndBranch({ parkId: park.id, branchId: branch.id }),
  );

  const relevantInvalidTypes = invalidTypes.filter(
    (t) =>
      t.type === InvalidTypes.FoundationTypeInvalid ||
      t.type === InvalidTypes.TurbineTypeInvalid,
  );

  if (!turbinesWithFoundation || turbinesWithFoundation.length === 0) {
    return (
      <CenterContainer>
        <SimpleAlert text={"No foundations in the park."} type={"error"} />
      </CenterContainer>
    );
  }
  if (relevantInvalidTypes.length > 0)
    return (
      <CenterContainer style={{ margin: "3rem" }}>
        <InvalidTypesDisplayText invalidTypes={relevantInvalidTypes} />
      </CenterContainer>
    );

  return (
    <>
      {includesFloaters && <FloatingFoundationStatistics />}
      {includesFixed && (
        <Suspense fallback={<LoadingState />}>
          <FixedFoundationStatistics
            tempLayoutFoundations={turbinesWithFixedFoundations}
          />
        </Suspense>
      )}
    </>
  );
};

export const FoundationKeyStatisticsWidget = () => (
  <SafeCard title="Foundation key statistics" id="Foundation key statistics">
    <FoundationKeyStatisticsDetails />
  </SafeCard>
);
