import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { roundToDecimal } from "utils/utils";
import { isDefined } from "utils/predicates";
import { typography } from "styles/typography";
import { colors } from "styles/colors";
import { CostUnit } from "types/financial";
import { allCableTypesSelector } from "../Cabling/Generate/state";
import type { CableType } from "services/cableTypeService";
import {
  formatElectricalLossToPercentDecimal,
  formatGW,
  formatGWConditional,
  formatGWh,
  formatGWhConditional,
  formatLossToPercent,
  formatLossToPercentDecimal,
  formatPercent,
  formatPercentDecimal,
  getFormatGWUnit,
} from "../ProductionV2/format";
import { Columns, ComparisonAttributeKey } from "./types";
import { valueRounding } from "components/RightSide/InfoModal/FoundationModal/utils";
import { BATHYMETRY_SOURCE_DESCRIPTION } from "business/bathymetry/utils";
import React from "react";

const Value = styled.p`
  ${typography.caption};
  font-weight: 600;
`;

const Unit = styled.p`
  ${typography.caption};
  color: ${colors.grey700};
`;

export function isFalsy<T>(arg: T | undefined | null): arg is undefined | null {
  return !isDefined(arg) || arg === null;
}

const getAbsolutePercentageNegativeComparison =
  (decimals: number) =>
  (
    baseline: number,
    thisValue: number,
  ): {
    type: "NEGATIVE_CHECK";
    result: number;
  } => {
    const result = roundToDecimal(thisValue * 100 - baseline * 100, decimals);
    return {
      type: "NEGATIVE_CHECK",
      result: result,
    };
  };

const getColumnTemplates = (cableTypes: CableType[]): Columns => ({
  [ComparisonAttributeKey.PARK]: {
    name: "Park",
    attributes: [
      {
        key: "area" as const,
        name: "Area",
        type: "item",
        unit: "km²",
        format: (value) => (
          <>
            <Value>{value}</Value>
            <Unit>km²</Unit>
          </>
        ),
        csvFormat: (value) => `${value}`,
        compareValues: (baseline: number, thisValue: number) => {
          // const result = thisValue / baseline;
          const result = thisValue - baseline;
          return {
            type: "POSITIVE_CHECK",
            result: result,
          };
        },
        // formatComparisonResult: (result) => `${result.toFixed(2)}%`,
        formatComparisonResult: (result) => `${result} km²`,
      },
      {
        key: "averageDepth" as const,
        name: "Avg. depth",
        type: "item",
        unit: "m",
        format: (value: number) => (
          <>
            <Value>{roundToDecimal(Math.abs(value), 1)}</Value>
            <Unit>m</Unit>
          </>
        ),
        csvFormat: (value: number) => `${roundToDecimal(Math.abs(value), 1)}`,
        compareValues: (baseline: number, thisValue: number) => {
          const result =
            roundToDecimal(baseline, 1) - roundToDecimal(thisValue, 1);
          return {
            type: "NEUTRAL_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${result.toFixed(1)} m`,
        infoMessage: BATHYMETRY_SOURCE_DESCRIPTION,
      },
      {
        key: "depthRange" as const,
        name: "Depth range",
        type: "item",
        unit: "m",
        format: ([min, max]) =>
          min && max ? (
            <>
              <Value>
                {roundToDecimal(min, 1)} - {roundToDecimal(max, 1)}
              </Value>
              <Unit>m</Unit>
            </>
          ) : (
            "-"
          ),
        csvFormat: ([min, max]) =>
          min && max
            ? `${roundToDecimal(min, 1)} - ${roundToDecimal(max, 1)}`
            : "-",
        infoMessage: BATHYMETRY_SOURCE_DESCRIPTION,
      },
      {
        key: "parkLocation" as const,
        name: "Location",
        type: "item",
        unit: "lat, lon",
        format: (value: { lat: number; lon: number }) => (
          <>
            <Unit>Lat:</Unit>
            <Value>{value.lat.toFixed(3)}</Value>
            <Unit>Lon:</Unit>
            <Value>{value.lon.toFixed(3)}</Value>
          </>
        ),
        csvFormat: (value: { lat: number; lon: number }) =>
          `${roundToDecimal(value.lat, 3)} ${roundToDecimal(value.lon, 3)}`,
      },
      {
        key: "nrTurbines" as const,
        name: "Number of turbines",
        type: "item",
        format: (value) => <Value>{value}</Value>,
        csvFormat: (value) => `${value}`,
        compareValues: (baseline: number, thisValue: number) => {
          const result = thisValue - baseline;

          return {
            type: "NEUTRAL_CHECK",
            result: result,
          };
        },
      },
      {
        key: "turbineTypesString" as const,
        name: "Turbine types",
        type: "item",
        format: (value) => <Value>{value}</Value>,
        csvFormat: (value) => `${value}`,
      },
      {
        key: "averageTurbineHeight" as const,
        name: "Hub height",
        type: "item",
        unit: "m",
        format: (value: number | undefined) =>
          value ? (
            <>
              <Value>{roundToDecimal(Math.abs(value), 1)}</Value>
              <Unit>m</Unit>
            </>
          ) : null,
        csvFormat: (value: number | undefined) =>
          value ? `${roundToDecimal(Math.abs(value), 1)}` : "Turbine-specific",
      },
    ],
  },
  [ComparisonAttributeKey.PRODUCTION]: {
    name: "Production",
    attributes: [
      {
        key: "capacity" as const,
        name: "Capacity",
        type: "item",
        unit: "GW",
        format: (value) => (
          <>
            <Value>{formatGWConditional(value / 1e3, true)}</Value>
            <Unit>{getFormatGWUnit(value / 1e3)}</Unit>
          </>
        ),
        csvFormat: (value) => formatGW(value / 1e3, true),
        compareValues: (baseline: number, thisValue: number) => {
          const result = thisValue - baseline;
          return {
            type: "POSITIVE_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${formatGWConditional(result)}`,
      },
      {
        key: "capacityDensity" as const,
        name: "Capacity density",
        type: "item",
        unit: "MW/km²",
        format: (value) => (
          <>
            <Value>{value}</Value>
            <Unit>MW/km²</Unit>
          </>
        ),
        csvFormat: (value) => `${value}`,
        compareValues: (baseline: number, thisValue: number) => {
          const result = thisValue - baseline;
          return {
            type: "POSITIVE_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) =>
          `${roundToDecimal(result, 3)} MW/km²`,
      },
      {
        key: "capacityFactor" as const,
        name: "Capacity factor",
        type: "item",
        format: (value) => (
          <>
            <Value>{formatPercent(value, 1, true)}</Value>
            <Unit>%</Unit>
          </>
        ),
        csvFormat: (value) => formatPercentDecimal(value, 3, true),
        compareValues: (baseline: number, thisValue: number) => {
          const result = roundToDecimal(thisValue * 100 - baseline * 100, 1);
          return {
            type: "POSITIVE_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${result}%`,
      },
      {
        key: "netEnergy" as const,
        name: "Net energy",
        type: "item",
        unit: "GWh",
        format: (value) => (
          <>
            <Value>{formatGWhConditional(value, true)}</Value>
            <Unit>{getFormatGWUnit(value)}h</Unit>
          </>
        ),
        csvFormat: (value) => formatGWh(value, true),
        compareValues: (baseline: number, thisValue: number) => {
          // const result = thisValue / baseline;
          const result = roundToDecimal(thisValue - baseline, 2);
          return {
            type: "POSITIVE_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${formatGWhConditional(result)}`,
      },
      {
        key: "grossEnergy" as const,
        name: "Gross energy",
        type: "item",
        unit: "GWh",
        format: (value) => (
          <>
            <Value>{formatGWhConditional(value, true)}</Value>
            <Unit>{getFormatGWUnit(value)}h</Unit>
          </>
        ),
        csvFormat: (value) => formatGWh(value, true),
        compareValues: (baseline: number, thisValue: number) => {
          // const result = thisValue / baseline;
          const result = thisValue - baseline;
          return {
            type: "POSITIVE_CHECK",
            // result: (result - 1) * 100,
            result: result,
          };
        },
        formatComparisonResult: (result) =>
          `${formatGWhConditional(result, true)} ${getFormatGWUnit(result)}h`,
      },
      {
        key: "turbineSpecificLoss" as const,
        name: "Turbine-specific loss",
        type: "item",
        format: (v) => (v ? formatLossToPercent(v) + "%" : "-"),
        csvFormat: (v) => (v ? formatLossToPercentDecimal(v) : "-"),
        compareValues: getAbsolutePercentageNegativeComparison(1),
        formatComparisonResult: (result) =>
          `${formatLossToPercent(result / 100)}%`,
      },
      {
        key: "otherLoss" as const,
        name: "Other loss",
        type: "item",
        format: (value) => (
          <>
            <Value>{formatPercent(value, 1, true)}</Value>
            <Unit>%</Unit>
          </>
        ),
        csvFormat: (value) => formatPercentDecimal(value, 3, true),
        compareValues: getAbsolutePercentageNegativeComparison(5),
        formatComparisonResult: (result) => `${result.toFixed(2)}%`,
      },
      {
        key: "internalWakeLoss" as const,
        name: "Internal wake loss",
        type: "item",
        format: (value) => (
          <>
            <Value>{formatLossToPercent(value)}</Value>
            <Unit>%</Unit>
          </>
        ),
        csvFormat: (v) => formatLossToPercentDecimal(v),
        compareValues: getAbsolutePercentageNegativeComparison(5),
        formatComparisonResult: (result) =>
          `${formatLossToPercent(result / 100)}%`,
      },
      {
        key: "totalWakeLoss" as const,
        name: "Total wake loss",
        type: "item",
        format: (value) => (
          <>
            <Value>{formatLossToPercent(value)}</Value>
            <Unit>%</Unit>
          </>
        ),
        csvFormat: (v) => formatLossToPercentDecimal(v),
        compareValues: getAbsolutePercentageNegativeComparison(5),
        formatComparisonResult: (result) =>
          `${formatLossToPercent(result / 100)}%`,
      },
      {
        key: "neighbourWakeLoss" as const,
        name: "Neighbour wake loss",
        type: "item",
        format: (value) => (
          <>
            <Value>{formatLossToPercent(value)}</Value>
            <Unit>%</Unit>
          </>
        ),
        csvFormat: (v) => (v ? formatLossToPercentDecimal(v) : "-"),
        compareValues: getAbsolutePercentageNegativeComparison(5),
        formatComparisonResult: (result) =>
          `${formatLossToPercent(result / 100)}%`,
      },
      {
        key: "wakeModel" as const,
        name: "Wake model",
        type: "item",
        format: (value) => <Value>{value}</Value>,
        csvFormat(value): string {
          return value;
        },
      },
    ],
  },
  [ComparisonAttributeKey.WIND]: {
    name: "Wind",
    attributes: [
      {
        key: "avgPowerLawCoefficient" as const,
        name: "Avg. power-law coeff.",
        type: "item",
        format: (value) => <Value>{value.toFixed(3)}</Value>,
        csvFormat: (value) => value.toFixed(3),
        compareValues: (baseline: number, thisValue: number) => {
          const result = roundToDecimal(thisValue - baseline, 3);
          return {
            type: "NEUTRAL_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${result}`,
      },
      {
        key: "avgTurbineWindSpeed" as const,
        name: "Avg. turbine wind speed",
        type: "item",
        unit: "m/s",
        format(value: number) {
          const averageWindSpeed = (Math.round(value * 100) / 100).toFixed(2);
          return (
            <>
              <Value>{averageWindSpeed}</Value>
              <Unit>m/s</Unit>
            </>
          );
        },
        csvFormat(value: number): string {
          const averageWindSpeed = (Math.round(value * 100) / 100).toFixed(2);
          return `${averageWindSpeed}`;
        },
        compareValues: (baseline: number, thisValue: number) => {
          const result = roundToDecimal(thisValue - baseline, 2);
          return {
            type: "POSITIVE_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${result} m/s`,
      },
      {
        key: "avgSourceWindSpeed" as const,
        name: "Avg. source wind speed",
        type: "item",
        unit: "m/s",
        format(value: number) {
          const averageWindSpeed = (Math.round(value * 100) / 100).toFixed(2);
          return (
            <>
              <Value>{averageWindSpeed}</Value>
              <Unit>m/s</Unit>
            </>
          );
        },
        csvFormat(value: number): string {
          const averageWindSpeed = (Math.round(value * 100) / 100).toFixed(2);
          return `${averageWindSpeed}`;
        },
        compareValues: (baseline: number, thisValue: number) => {
          const result = roundToDecimal(thisValue - baseline, 2);
          return {
            type: "POSITIVE_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${result} m/s`,
      },
      {
        key: "height" as const,
        name: "Height",
        type: "item",
        unit: "m",
        format: (value) => (
          <>
            <Value>{value}</Value>
            <Unit>m</Unit>
          </>
        ),
        csvFormat(value): string {
          return `${value}`;
        },
        compareValues: (baseline: number, thisValue: number) => {
          if (baseline === 0 || thisValue === 0) {
            return undefined;
          }

          const result = thisValue - baseline;
          return {
            type: "NEUTRAL_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${result.toFixed(0)} m`,
      },
      {
        key: "airDensity" as const,
        name: "Avg. air density",
        type: "item",
        unit: "kg/m3",
        format: (value) => (
          <>
            <Value>{roundToDecimal(value, 3)}</Value>
            <Unit>kg/m3</Unit>
          </>
        ),
        csvFormat(value): string {
          return `${roundToDecimal(value, 4)}`;
        },
        compareValues: (baseline: number, thisValue: number) => {
          if (baseline === 0 || thisValue === 0) {
            return undefined;
          }
          const result = thisValue - baseline;
          return {
            type: "NEUTRAL_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) =>
          `${roundToDecimal(result, 3)} kg/m3`,
      },
      {
        key: "windSource" as const,
        name: "Wind source",
        type: "item",
        format: (value) => <Value>{value}</Value>,
        csvFormat(value): string {
          return value;
        },
      },
      {
        key: "location" as const,
        name: "Wind rose location",
        type: "item",
        unit: "lat, lon",
        format: (value: { lat: number; lon: number }) => (
          <>
            <Unit>Lat:</Unit>
            <Value>{value.lat.toFixed(3)}</Value>
            <Unit>Lon:</Unit>
            <Value>{value.lon.toFixed(3)}</Value>
          </>
        ),
        csvFormat: (value: { lat: number; lon: number }) =>
          `${roundToDecimal(value.lat, 6)} ${roundToDecimal(value.lon, 6)}`,
      },
    ],
  },
  [ComparisonAttributeKey.CABLING]: {
    name: "Electrical",
    attributes: [
      {
        key: "cableTypes",
        name: "Cable types",
        type: "list",
        values: cableTypes.map(
          (cableType) =>
            ({
              key: cableType.id,
              name: `${cableType.name} (${cableType.voltage}kV)`,
              type: "item",
              unit: "km",
              format: (value) => (
                <>
                  <Value>{roundToDecimal(value, 1)}</Value>
                  <Unit>km</Unit>
                </>
              ),
              csvFormat(value) {
                return `${roundToDecimal(value, 1)}`;
              },
              compareValues: (baseline: number, thisValue: number) => {
                const result = roundToDecimal(thisValue - baseline, 1);
                return {
                  type: "NEGATIVE_CHECK",
                  result: result,
                };
              },
              formatComparisonResult: (result) => `${result.toFixed(1)} km`,
            }) satisfies Columns[ComparisonAttributeKey]["attributes"][0],
        ),
      },
      {
        key: "cableLengthContingency" as const,
        name: "Cable length contingency",
        type: "item",
        unit: "%",
        format: (value) => (
          <>
            <Value>{value * 100}</Value>
            <Unit>%</Unit>
          </>
        ),
        csvFormat(value) {
          return `${value * 100}`;
        },
        compareValues: (baseline: number, thisValue: number) => {
          const result = thisValue * 100 - baseline * 10;
          return {
            type: "NEUTRAL_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${result} %`,
      },
      {
        key: "interArrayCableVoltages" as const,
        name: "Inter array cable voltages",
        type: "item",
        unit: "kV",
        format: (value: number[]) =>
          value.map((voltage) => (
            <React.Fragment key={voltage}>
              <Value>{voltage}</Value>
              <Unit>kV</Unit>
            </React.Fragment>
          )),
        csvFormat(value: number[]) {
          return value.join(", ");
        },
        compareValues: (baseline: number[], thisValue: number[]) => {
          const listDifferences = baseline.filter(
            (v) => !thisValue.includes(v),
          );

          return {
            type: "LIST_CHECK",
            result: listDifferences,
          };
        },
        formatComparisonResult: (result: any) => (
          <>{result.map((kv: number) => `${kv} kV`)}</>
        ),
      },
      {
        key: "exportCableTypesString" as const,
        name: "Export cable types (offshore)",
        type: "item",
        format: (value) => <Value>{value}</Value>,
        csvFormat: (value) => `${value}`,
      },
      {
        key: "exportCableTypesOnshoreString" as const,
        name: "Export cable types (onshore)",
        type: "item",
        format: (value) => <Value>{value}</Value>,
        csvFormat: (value) => `${value}`,
      },
      {
        key: "exportCableVoltages" as const,
        name: "Export cable voltages",
        type: "item",
        unit: "kV",
        format: (value: number[]) =>
          value.map((voltage) => (
            <React.Fragment key={voltage}>
              <Value>{voltage}</Value>
              <Unit>kV</Unit>
            </React.Fragment>
          )),
        csvFormat(value: number[]) {
          return value.join(", ");
        },
        compareValues: (baseline: number[], thisValue: number[]) => {
          const listDifferences = baseline.filter(
            (v) => !thisValue.includes(v),
          );

          return {
            type: "LIST_CHECK",
            result: listDifferences,
          };
        },
        formatComparisonResult: (result: any) => (
          <>{result.map((kv: number) => `${kv} kV`)}</>
        ),
      },
      {
        key: "interArrayCableLength" as const,
        name: "Inter array cable length",
        type: "item",
        unit: "km",
        format: (value) => (
          <>
            <Value>{roundToDecimal(value, 1)}</Value>
            <Unit>km</Unit>
          </>
        ),
        csvFormat(value) {
          return `${roundToDecimal(value, 1)}`;
        },
        compareValues: (baseline: number, thisValue: number) => {
          if (baseline === 0 || thisValue === 0) {
            return undefined;
          }

          const result = roundToDecimal(thisValue - baseline, 1);
          return {
            type: "NEGATIVE_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${result.toFixed(1)} km`,
      },
      {
        key: "totalInterArrayLoss" as const,
        name: "Total inter array loss",
        type: "item",
        format: (v) =>
          v === null ? (
            "-"
          ) : (
            <>
              <Value>{formatElectricalLossToPercentDecimal(v, true)}</Value>
              <Unit>%</Unit>
            </>
          ),
        csvFormat: (v) => (v === null ? "-" : formatPercentDecimal(v, 4, true)),
        compareValues: getAbsolutePercentageNegativeComparison(2),
        formatComparisonResult: (result) => `${result.toFixed(2)}%`,
      },
      {
        key: "numberOffShoreSubstations" as const,
        name: "No. offshore substations",
        type: "item",
        format: (value) => <Value>{value}</Value>,
        csvFormat(value) {
          return `${value}`;
        },
        compareValues: (baseline: number, thisValue: number) => {
          const result = thisValue - baseline;
          return {
            type: "NEUTRAL_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => result,
      },
      {
        key: "totalExportSystemLoss" as const,
        name: "Total export system loss",
        type: "item",
        format: (v) =>
          v === null ? (
            "-"
          ) : (
            <>
              <Value>{formatElectricalLossToPercentDecimal(v, true)}</Value>
              <Unit>%</Unit>
            </>
          ),
        csvFormat: (v: number | null) =>
          v === null ? "-" : formatPercentDecimal(v, 4, true),
        compareValues: getAbsolutePercentageNegativeComparison(2),
        formatComparisonResult: (result) => `${result.toFixed(2)}%`,
      },
      {
        key: "maxExportCableUtilizationOffshore" as const,
        name: "Max export cable utilization (offhsore)",
        type: "item",
        format: (v) =>
          v === null ? (
            "-"
          ) : (
            <>
              <Value>{formatPercent(v, 1, true)}</Value>
              <Unit>%</Unit>
            </>
          ),
        csvFormat: (v) => (v === null ? "-" : formatPercentDecimal(v, 4, true)),
        compareValues: (baseline: number, thisValue: number) => {
          const result = roundToDecimal(thisValue * 100 - baseline * 100, 1);
          return {
            type: "NEUTRAL_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${result.toFixed(2)}%`,
      },
      {
        key: "maxExportCableUtilizationOnshore" as const,
        name: "Max export cable utilization (onshore)",
        type: "item",
        format: (v) =>
          v === null ? (
            "-"
          ) : (
            <>
              <Value>{formatPercent(v, 1, true)}</Value>
              <Unit>%</Unit>
            </>
          ),
        csvFormat: (v) => (v === null ? "-" : formatPercentDecimal(v, 4, true)),
        compareValues: (baseline: number, thisValue: number) => {
          const result = roundToDecimal(thisValue * 100 - baseline * 100, 1);
          return {
            type: "NEUTRAL_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${result.toFixed(2)}%`,
      },
      {
        key: "exportCableLength" as const,
        name: "Export cable length",
        type: "item",
        unit: "km",
        format: (value) => (
          <>
            <Value>{roundToDecimal(value, 1)}</Value>
            <Unit>km</Unit>
          </>
        ),
        csvFormat(value) {
          return `${roundToDecimal(value, 1)}`;
        },
        compareValues: (baseline: number, thisValue: number) => {
          if (baseline === 0 || thisValue === 0) {
            return undefined;
          }

          const result = roundToDecimal(thisValue - baseline, 1);
          return {
            type: "NEGATIVE_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${result} km`,
      },
      {
        key: "numberExportCables" as const,
        name: "No. export cables",
        type: "item",
        format: (value) => <Value>{value}</Value>,
        csvFormat(value) {
          return `${value}`;
        },
        compareValues: (baseline: number, thisValue: number) => {
          const result = thisValue - baseline;
          return {
            type: "NEUTRAL_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => result,
      },
      {
        key: "offshoreCableLength" as const,
        name: "Export cable length offshore",
        type: "item",
        unit: "km",
        format: (value) => (
          <>
            <Value>{roundToDecimal(value, 1)}</Value>
            <Unit>km</Unit>
          </>
        ),
        csvFormat(value) {
          return `${roundToDecimal(value, 1)}`;
        },
        compareValues: (baseline: number, thisValue: number) => {
          const result = roundToDecimal(thisValue - baseline, 1);
          return {
            type: "NEGATIVE_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${roundToDecimal(result, 1)} km`,
      },
      {
        key: "onshoreCableLength" as const,
        name: "Export cable length onshore",
        type: "item",
        unit: "km",
        format: (value) => (
          <>
            <Value>{roundToDecimal(value, 1)}</Value>
            <Unit>km</Unit>
          </>
        ),
        csvFormat(value) {
          return `${roundToDecimal(value, 1)}`;
        },
        compareValues: (baseline: number, thisValue: number) => {
          const result = roundToDecimal(thisValue - baseline, 1);
          return {
            type: "NEGATIVE_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${roundToDecimal(result, 1)} km`,
      },
    ],
  },
  [ComparisonAttributeKey.FINANCIAL]: {
    name: "Financial",
    attributes: [
      {
        key: "devex" as const,
        name: "DEVEX",
        type: "item",
        unit: CostUnit.millionEuro,
        format: (value) => (
          <>
            <Value>{value}</Value>
            <Unit>{CostUnit.millionEuro}</Unit>
          </>
        ),
        csvFormat(value) {
          return `${value}`;
        },
        compareValues: (baseline: number, thisValue: number) => {
          const result = thisValue - baseline;
          return {
            type: "NEGATIVE_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${result} ${CostUnit.millionEuro}`,
      },
      {
        key: "capex" as const,
        name: "CAPEX",
        type: "item",
        unit: CostUnit.millionEuro,
        format: (value) => (
          <>
            <Value>{value}</Value>
            <Unit>{CostUnit.millionEuro}</Unit>
          </>
        ),
        csvFormat(value) {
          return `${value}`;
        },
        compareValues: (baseline: number, thisValue: number) => {
          const result = thisValue - baseline;
          return {
            type: "NEGATIVE_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${result} ${CostUnit.millionEuro}`,
      },
      {
        key: "opex" as const,
        name: "OPEX",
        type: "item",
        unit: CostUnit.millionEuro,
        format: (value) => (
          <>
            <Value>{value}</Value>
            <Unit>{CostUnit.millionEuro}</Unit>
          </>
        ),
        csvFormat(value) {
          return `${value}`;
        },
        compareValues: (baseline: number, thisValue: number) => {
          const result = thisValue - baseline;
          return {
            type: "NEGATIVE_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${result} ${CostUnit.millionEuro}`,
      },
      {
        key: "decommissioning" as const,
        name: "DECOM",
        type: "item",
        unit: CostUnit.millionEuro,
        format: (value) => (
          <>
            <Value>{value}</Value>
            <Unit>{CostUnit.millionEuro}</Unit>
          </>
        ),
        csvFormat(value) {
          return `${value}`;
        },
        compareValues: (baseline: number, thisValue: number) => {
          const result = thisValue - baseline;
          return {
            type: "NEGATIVE_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${result} ${CostUnit.millionEuro}`,
      },
      {
        key: "irr" as const,
        name: "IRR",
        type: "item",
        unit: "%",
        format: (value) => (
          <>
            <Value>{(value * 100).toFixed(2)}</Value>
            <Unit>%</Unit>
          </>
        ),
        csvFormat(value) {
          return `${(value * 100).toFixed(2)}`;
        },
        compareValues: (baseline, thisValue) => {
          const result = thisValue * 100 - baseline * 100;
          return {
            type: "POSITIVE_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${roundToDecimal(result, 2)}%`,
      },
      {
        key: "lcoe" as const,
        name: "LCoE",
        type: "item",
        unit: CostUnit.euroPerMWh,
        format: (value) => (
          <>
            <Value>{value.toFixed(1)}</Value>
            <Unit>{CostUnit.euroPerMWh}</Unit>
          </>
        ),
        csvFormat(value) {
          return `${value.toFixed(1)}`;
        },
        compareValues: (baseline: number, thisValue: number) => {
          const result = roundToDecimal(thisValue - baseline, 1);
          return {
            type: "NEGATIVE_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) =>
          `${result.toFixed(1)} ${CostUnit.euroPerMWh}`,
      },
      {
        key: "turbineCost" as const,
        name: "Turbine cost",
        type: "item",
        unit: CostUnit.millionEuro,
        format: (value) => (
          <>
            <Value>{value}</Value>
            <Unit>{CostUnit.millionEuro}</Unit>
          </>
        ),
        csvFormat(value) {
          return `${value}`;
        },
        compareValues: (baseline: number, thisValue: number) => {
          const result = thisValue - baseline;
          return {
            type: "NEGATIVE_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${result} ${CostUnit.millionEuro}`,
      },
      {
        key: "cableCost" as const,
        name: "Inter array cabling cost",
        type: "item",
        unit: CostUnit.millionEuro,
        format: (value) => (
          <>
            <Value>{value}</Value>
            <Unit>{CostUnit.millionEuro}</Unit>
          </>
        ),
        csvFormat(value) {
          return `${value}`;
        },
        compareValues: (baseline: number, thisValue: number) => {
          const result = thisValue - baseline;
          return {
            type: "NEGATIVE_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${result} ${CostUnit.millionEuro}`,
      },
      {
        key: "foundationCost" as const,
        name: "Foundation cost",
        type: "item",
        unit: CostUnit.millionEuro,
        format: (value) => (
          <>
            <Value>{value}</Value>
            <Unit>{CostUnit.millionEuro}</Unit>
          </>
        ),
        csvFormat(value) {
          return `${value}`;
        },
        compareValues: (baseline: number, thisValue: number) => {
          const result = thisValue - baseline;
          return {
            type: "NEGATIVE_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${result} ${CostUnit.millionEuro}`,
      },
      {
        key: "mooringCost" as const,
        name: "Mooring cost",
        type: "item",
        unit: CostUnit.millionEuro,
        format: (value) => (
          <>
            <Value>{value}</Value>
            <Unit>{CostUnit.millionEuro}</Unit>
          </>
        ),
        csvFormat(value) {
          return `${value}`;
        },
        compareValues: (baseline: number, thisValue: number) => {
          const result = thisValue - baseline;
          return {
            type: "NEGATIVE_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${result} ${CostUnit.millionEuro}`,
      },
      {
        key: "substationCost" as const,
        name: "Substation cost",
        type: "item",
        unit: CostUnit.millionEuro,
        format: (value) => (
          <>
            <Value>{value}</Value>
            <Unit>{CostUnit.millionEuro}</Unit>
          </>
        ),
        csvFormat(value) {
          return `${value}`;
        },
        compareValues: (baseline: number, thisValue: number) => {
          const result = thisValue - baseline;
          return {
            type: "NEGATIVE_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${result} ${CostUnit.millionEuro}`,
      },
      {
        key: "exportCableCost" as const,
        name: "Export cable cost",
        type: "item",
        unit: CostUnit.millionEuro,
        format: (value) => (
          <>
            <Value>{value}</Value>
            <Unit>{CostUnit.millionEuro}</Unit>
          </>
        ),
        csvFormat(value) {
          return `${value}`;
        },
        compareValues: (baseline: number, thisValue: number) => {
          const result = thisValue - baseline;
          return {
            type: "NEGATIVE_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${result} ${CostUnit.millionEuro}`,
      },
      {
        key: "otherCapexCost" as const,
        name: "Other capex costs",
        type: "item",
        unit: CostUnit.millionEuro,
        format: (value) => (
          <>
            <Value>{value}</Value>
            <Unit>{CostUnit.millionEuro}</Unit>
          </>
        ),
        csvFormat(value) {
          return `${value}`;
        },
        compareValues: (baseline: number, thisValue: number) => {
          const result = thisValue - baseline;
          return {
            type: "NEGATIVE_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${result} ${CostUnit.millionEuro}`,
      },
    ],
  },
  [ComparisonAttributeKey.FOUNDATIONS]: {
    name: "Foundations",
    attributes: [
      {
        key: "primarySteelWeight" as const,
        name: "Primary steel weight",
        type: "item",
        unit: "t",
        format: (value) => (
          <>
            <Value>{valueRounding(value / 1000, 10).toLocaleString()}</Value>
            <Unit>t</Unit>
          </>
        ),
        csvFormat(value) {
          return `${value}`;
        },
        compareValues: (baseline: number, thisValue: number) => {
          const result = thisValue - baseline;
          return {
            type: "NEUTRAL_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) =>
          `${valueRounding(result / 1000, 10).toLocaleString()} t`,
      },
      {
        key: "minFoundationWeight" as const,
        name: "Min. foundation weight",
        type: "item",
        unit: "t",
        format: (value) => (
          <>
            <Value>{valueRounding(value / 1000, 10).toLocaleString()}</Value>
            <Unit>t</Unit>
          </>
        ),
        csvFormat(value) {
          return `${value}`;
        },
        compareValues: (baseline: number, thisValue: number) => {
          const result = thisValue - baseline;
          return {
            type: "NEUTRAL_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) =>
          `${valueRounding(result / 1000, 10).toLocaleString()} t`,
      },
      {
        key: "avgFoundationWeight" as const,
        name: "Avg. foundation weight",
        type: "item",
        unit: "t",
        format: (value) => (
          <>
            <Value>{valueRounding(value / 1000, 10).toLocaleString()}</Value>
            <Unit>t</Unit>
          </>
        ),
        csvFormat(value) {
          return `${value}`;
        },
        compareValues: (baseline: number, thisValue: number) => {
          const result = thisValue - baseline;
          return {
            type: "NEUTRAL_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) =>
          `${valueRounding(result / 1000, 10).toLocaleString()} t`,
      },
      {
        key: "maxFoundationWeight" as const,
        name: "Max. foundation weight",
        type: "item",
        unit: "t",
        format: (value) => (
          <>
            <Value>{valueRounding(value / 1000, 10).toLocaleString()}</Value>
            <Unit>t</Unit>
          </>
        ),
        csvFormat(value) {
          return `${value}`;
        },
        compareValues: (baseline: number, thisValue: number) => {
          const result = thisValue - baseline;
          return {
            type: "NEUTRAL_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) =>
          `${valueRounding(result / 1000, 10).toLocaleString()} t`,
      },
      {
        key: "minFoundationDepth" as const,
        name: "Min. foundation depth",
        type: "item",
        unit: "m",
        format: (value) => (
          <>
            <Value>{Math.abs(value).toFixed(1)}</Value>
            <Unit>m</Unit>
          </>
        ),
        csvFormat(value) {
          return `${value}`;
        },
        compareValues: (baseline: number, thisValue: number) => {
          const result = roundToDecimal(baseline - thisValue, 1);
          return {
            type: "NEUTRAL_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${result.toFixed(1)} m`,
        infoMessage: BATHYMETRY_SOURCE_DESCRIPTION,
      },
      {
        key: "avgFoundationDepth" as const,
        name: "Avg. foundation depth",
        type: "item",
        unit: "m",
        format: (value) => (
          <>
            <Value>{Math.abs(value).toFixed(1)}</Value>
            <Unit>m</Unit>
          </>
        ),
        csvFormat(value) {
          return `${value}`;
        },
        compareValues: (baseline: number, thisValue: number) => {
          const result = roundToDecimal(baseline - thisValue, 1);
          return {
            type: "NEUTRAL_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${result.toFixed(1)} m`,
        infoMessage: BATHYMETRY_SOURCE_DESCRIPTION,
      },
      {
        key: "maxFoundationDepth" as const,
        name: "Max. foundation depth",
        type: "item",
        unit: "m",
        format: (value) => (
          <>
            <Value>{Math.abs(value).toFixed(1)}</Value>
            <Unit>m</Unit>
          </>
        ),
        csvFormat(value) {
          return `${value}`;
        },
        compareValues: (baseline: number, thisValue: number) => {
          const result = roundToDecimal(baseline - thisValue, 1);
          return {
            type: "NEUTRAL_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${result.toFixed(1)} m`,
        infoMessage: BATHYMETRY_SOURCE_DESCRIPTION,
      },
    ],
  },
  [ComparisonAttributeKey.FINANCIAL_INPUTS]: {
    name: "Financial inputs",
    attributes: [
      {
        key: "lifeTime" as const,
        name: "Life time",
        type: "item",
        unit: "years",
        format: (value) => (
          <>
            <Value>{value}</Value>
            <Unit>years</Unit>
          </>
        ),
        csvFormat(value) {
          return `${value}`;
        },
        compareValues: (baseline: number, thisValue: number) => {
          const result = thisValue - baseline;
          return {
            type: "POSITIVE_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${result} years`,
      },
      {
        key: "aep" as const,
        name: "AEP",
        type: "item",
        unit: "GWh",
        format: (value) => (
          <>
            <Value>{formatGWhConditional(value, true)}</Value>
            <Unit>{getFormatGWUnit(value)}h</Unit>
          </>
        ),
        csvFormat: (value) => formatGWh(value, true),
        compareValues: (baseline: number, thisValue: number) => {
          const result = thisValue - baseline;
          return {
            type: "POSITIVE_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) =>
          `${formatGWhConditional(result, false)}`,
      },
      {
        key: "contingency" as const,
        name: "Contingency",
        type: "item",
        format: (value) => (
          <>
            <Value>{formatPercent(value, 1, true)}</Value>
            <Unit>%</Unit>
          </>
        ),
        csvFormat: (value) => formatPercentDecimal(value, 3, true),
        compareValues: (baseline: number, thisValue: number) => {
          const result = roundToDecimal(thisValue * 100 - baseline * 100, 1);
          return {
            type: "POSITIVE_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${result}%`,
      },
      {
        key: "discountRate" as const,
        name: "Discount rate",
        type: "item",
        format: (value) => (
          <>
            <Value>{formatPercent(value, 1, true)}</Value>
            <Unit>%</Unit>
          </>
        ),
        csvFormat: (value) => formatPercentDecimal(value, 3, true),
        compareValues: (baseline: number, thisValue: number) => {
          const result = roundToDecimal(thisValue * 100 - baseline * 100, 1);
          return {
            type: "POSITIVE_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${result}%`,
      },
      {
        key: "inflationRate" as const,
        name: "Inflation rate",
        type: "item",
        format: (value) => (
          <>
            <Value>{formatPercent(value, 1, true)}</Value>
            <Unit>%</Unit>
          </>
        ),
        csvFormat: (value) => formatPercentDecimal(value, 3, true),
        compareValues: (baseline: number, thisValue: number) => {
          const result = roundToDecimal(thisValue * 100 - baseline * 100, 1);
          return {
            type: "POSITIVE_CHECK",
            result: result,
          };
        },
        formatComparisonResult: (result) => `${result}%`,
      },
    ],
  },
});

export const useColumnTemplates = (): Columns => {
  const cableTypes = useRecoilValue(allCableTypesSelector);

  const columnTemplates = useMemo<Columns>(
    () => getColumnTemplates(cableTypes),
    [cableTypes],
  );

  return columnTemplates;
};
