import { UserAccessRole } from "../../../types/user";
import { useTypedPath } from "state/pathParams";
import {
  addOrUpdateNodeAccessInGroup,
  removeNodeAccessFromGroup,
} from "./service";
import { useRecoilCallback } from "recoil";
import { groupProjectsState, nodeGroupUserAccessSelector } from "./state";
import { initializeAndSet } from "components/Comments/hooks/useReplyReactionCrud";

const useGroupNodeAccessCrud = () => {
  const { organisationId } = useTypedPath("organisationId");

  const addOrUpdate = useRecoilCallback(
    ({ set, snapshot, refresh }) =>
      async (groupId: string, nodeId: string, role: UserAccessRole) => {
        const fallback = await snapshot.getPromise(
          groupProjectsState({ organisationId, groupId }),
        );
        return addOrUpdateNodeAccessInGroup(
          organisationId,
          groupId,
          nodeId,
          role,
        )
          .then((projectAccess) => {
            set(groupProjectsState({ organisationId, groupId }), (cur) => {
              const match = cur.find((c) => c.node_id === nodeId);
              if (match) {
                return cur.map((c) =>
                  c.node_id === nodeId ? projectAccess : c,
                );
              } else {
                return [...cur, projectAccess];
              }
            });
            refresh(nodeGroupUserAccessSelector({ organisationId, nodeId }));
          })
          .catch(() => {
            set(groupProjectsState({ organisationId, groupId }), fallback);
          });
      },
    [organisationId],
  );

  const addOrUpdateMultiple = useRecoilCallback(
    ({ set, snapshot, refresh }) =>
      async (groupIds: string[], nodeId: string, role: UserAccessRole) => {
        await Promise.all(
          groupIds.map((groupId) => {
            return addOrUpdateNodeAccessInGroup(
              organisationId,
              groupId,
              nodeId,
              role,
            ).then((projectAccess) => {
              return initializeAndSet(
                snapshot,
                set,
                groupProjectsState({ organisationId, groupId }),
                (cur) => {
                  const match = cur.find((c) => c.node_id === nodeId);
                  if (match) {
                    return cur.map((c) =>
                      c.node_id === nodeId ? projectAccess : c,
                    );
                  } else {
                    return [...cur, projectAccess];
                  }
                },
              );
            });
          }),
        );
        refresh(nodeGroupUserAccessSelector({ organisationId, nodeId }));
      },
    [organisationId],
  );

  const remove = useRecoilCallback(
    ({ set, snapshot, refresh }) =>
      async (groupId: string, nodeId: string) => {
        const fallback = await snapshot.getPromise(
          groupProjectsState({ organisationId, groupId }),
        );
        return removeNodeAccessFromGroup(organisationId, groupId, nodeId)
          .then(() => {
            set(groupProjectsState({ organisationId, groupId }), (cur) =>
              [...cur].filter((p) => p.node_id !== nodeId),
            );
            refresh(nodeGroupUserAccessSelector({ organisationId, nodeId }));
          })
          .catch(() => {
            set(groupProjectsState({ organisationId, groupId }), fallback);
          });
      },
    [organisationId],
  );

  return { addOrUpdate, addOrUpdateMultiple, remove };
};

export default useGroupNodeAccessCrud;
