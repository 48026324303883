import * as turf from "@turf/turf";
import { DivisionFeature } from "../types/feature";
import { ParkFeature } from "../types/feature";
import { Position } from "@turf/turf";

export function getParkCenter(
  park: ParkFeature,
  subAreas: DivisionFeature[],
): Position {
  const f =
    subAreas.length > 0
      ? { features: subAreas, type: "FeatureCollection" }
      : park;

  const centroid = turf.centerOfMass(f);
  return centroid.geometry.coordinates;
}
