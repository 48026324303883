import type { SetterOrUpdater } from "recoil";
import type { MapboxGeoJSONFeature, SymbolLayer } from "mapbox-gl";
import type { ExternalSelectionItem } from "../../state/externalLayerSelection";
import { Feature, FeatureCollection } from "geojson";
import { Layer } from "../../types/layers";
import { LayerStrokeStyle } from "../../components/LayerList/LayerSettings/types";

export const selectExternalLayerMethod = (
  append: boolean,
  features: MapboxGeoJSONFeature[],
  setCurrentSelectionArray: SetterOrUpdater<ExternalSelectionItem[]>,
  unsetOtherSelections: () => void,
) => {
  if (!append) {
    unsetOtherSelections();
  }

  setCurrentSelectionArray((cur) => {
    const id = features[0].id;

    if (!id) return cur;
    const selected = cur.find((s) => s.id === features[0].id);

    const newSelection = { ...features[0], id: String(id) };
    if (append) {
      if (selected) {
        return cur.filter((s) => s.id !== features[0].id);
      }
      return [...cur, newSelection];
    } else {
      if (selected && cur.length === 1) {
        return [];
      }
      return [newSelection];
    }
  });
};

export const filterFeatureCollectionAccordingToType = (
  featureCollection:
    | {
        [key: string]: any;
        features: Feature[];
      }
    | FeatureCollection,
  type: "Polygon" | "LineString" | "Point",
) => ({
  ...featureCollection,
  features: featureCollection.features.filter(
    (f) => f.geometry.type === type || f.geometry.type === "Multi" + type,
  ),
});

export const getLayerSymbols = (
  pinnedProperties?: string[],
  zoomLevels?: [number, number],
) => {
  if (!pinnedProperties) return;
  return {
    type: "symbol",
    minzoom: 5,
    layout: {
      "symbol-placement": "point",
      "text-field": pinnedProperties?.map((p) => `{${p}}`).join("\n"),
      "text-size": 12,
      "symbol-spacing": 300,
      "text-keep-upright": true,
    },
    paint: {
      "text-opacity": 1,
    },
    ...(zoomLevels ? getZoomLevels(zoomLevels) : {}),
  } as Omit<SymbolLayer, "id" | "source">;
};

export const layerToSourceId = (layer: Layer, suffix: string): string => {
  return `${layer.id}${suffix}`;
};

export const StrokeStyleToDashArray = {
  [LayerStrokeStyle.Solid]: [1, 0],
  [LayerStrokeStyle.Dashed]: [5, 5],
  [LayerStrokeStyle.Dotted]: [1, 2],
};

export const getZoomLevels = (zoomLevels: undefined | [number, number]) => ({
  maxzoom: zoomLevels != null ? zoomLevels[1] : 22,
  minzoom: zoomLevels != null ? zoomLevels[0] : 0,
});
