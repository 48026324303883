/// <reference types="vite-plugin-svgr/client" />
import { CenterContainer, SafeCard } from "./Base";
import { spaceMedium, spaceTiny } from "../../../styles/space";
import { colors } from "../../../styles/colors";
import { CostUnit } from "../../../types/financial";
import { useDashboardContext } from "../Dashboard";
import { orLoader } from "components/Loading/Skeleton";
import { Stat, Stats, Stat2, StatHeaderLoadable } from "./Base.style";
import { getInvalidTypesInParkAndBranch } from "state/layout";
import { useRecoilValue } from "recoil";
import { InvalidTypesDisplayText } from "types/invalidTypes";
import { currentParkTriggerId } from "components/Finance/Triggers";
import { useIRR } from "components/Finance/irr";
import { DownloadIRRData } from "components/FinancialAnalysis/IRR/DownloadIRRData";
import { getStoppedReason } from "components/ProductionV2/state";
import { ValidationAlert } from "components/ValidationWarnings/ValidationAlert";
import { analysisStoppedText } from "components/ProductionV2/types";

export const IRRWidget = () => {
  const { park, branch } = useDashboardContext();

  const invalidTypes = useRecoilValue(
    getInvalidTypesInParkAndBranch({ parkId: park.id, branchId: branch.id }),
  );

  if (invalidTypes.length > 0)
    return (
      <CenterContainer style={{ margin: "3rem" }}>
        <InvalidTypesDisplayText invalidTypes={invalidTypes} />
      </CenterContainer>
    );

  return (
    <SafeCard
      title="IRR"
      id="IRR table"
      menuItems={
        <DownloadIRRData id={currentParkTriggerId} buttonType={"menu"} />
      }
    >
      <IRRTableInner />
    </SafeCard>
  );
};

const IRRTableInner = () => {
  const {
    results: { irr, revenues, costs },
  } = useIRR(currentParkTriggerId);

  const analysisStoppedReason = useRecoilValue(
    getStoppedReason(currentParkTriggerId),
  );
  if (analysisStoppedReason) {
    return (
      <ValidationAlert
        type={"error"}
        title="Analysis stopped"
        description={analysisStoppedText[analysisStoppedReason]}
      />
    );
  }

  const irrValueMaybe = irr.valueMaybe();

  let irrValue = undefined;
  if (irrValueMaybe && "ok" in irrValueMaybe) {
    irrValue = `${Math.round(irrValueMaybe.ok * 10000) / 100} %`;
  } else if (irrValueMaybe && "error" in irrValueMaybe) {
    irrValue = "-";
  }

  return (
    <>
      <Stats>
        <StatHeaderLoadable
          title={"Revenues (nominal)"}
          value={revenues.total}
          unit={CostUnit.millionEuro}
        />
        {orLoader(revenues.guaranteed.years, (years) => (
          <>
            {years.startYear && years.endYear && (
              <Stat2
                bold={false}
                key={"guaranteed"}
                title={`${years.startYear}-${years.endYear}`}
                value={revenues.guaranteed.total}
                unit={CostUnit.millionEuro}
              ></Stat2>
            )}
          </>
        ))}
        {orLoader(revenues.market.years, (years) => (
          <>
            {years.startYear && years.endYear && (
              <Stat2
                bold={false}
                key={"market"}
                title={`${years.startYear}-${years.endYear}`}
                value={revenues.market.total}
                unit={CostUnit.millionEuro}
              ></Stat2>
            )}
          </>
        ))}

        <StatHeaderLoadable
          title={"Expenditures (nominal)"}
          value={costs.total}
          unit={CostUnit.millionEuro}
        ></StatHeaderLoadable>
        <Stat2
          bold={false}
          key={"CAPEX"}
          title={"CAPEX"}
          value={costs.capex}
          unit={CostUnit.millionEuro}
        ></Stat2>
        <Stat2
          bold={false}
          key={"DEVEX"}
          title={"DEVEX"}
          value={costs.devex}
          unit={CostUnit.millionEuro}
        ></Stat2>
        <Stat2
          bold={false}
          key={"OPEX"}
          title={"OPEX"}
          value={costs.opex}
          unit={CostUnit.millionEuro}
        ></Stat2>
        <Stat2
          bold={false}
          key={"DECOM"}
          title={"DECOM"}
          value={costs.decom}
          unit={CostUnit.millionEuro}
        ></Stat2>

        <div
          style={{
            borderTop: `1px solid ${colors.inputOutline}`,
            margin: `${spaceTiny} ${spaceMedium}`,
          }}
        />

        <Stat bold={true} title={"IRR"} value={irrValue} />
      </Stats>
    </>
  );
};
