"use client";
import Dashboard from "@icons/24/Dashboard.svg?react";
import { benchmarkingArticleHintType } from "components/ActiveTips/PositionHints/AnalysisBenchmarking";
import { PositionHintRef } from "components/ActiveTips/PositionHints/PositionHint";
import Button from "components/General/Button";
import { ColoredGrid, Grid2 } from "components/General/Form";
import HelpTooltip, {
  ARTICLE_YIELD_ASSESSMENT,
  HelpLink,
} from "components/HelpTooltip/HelpTooltip";
import {
  SkeletonBlock,
  orLoader,
  orTextLoader,
} from "components/Loading/Skeleton";
import {
  formatElectricalLossToPercentDecimal,
  formatGWhConditional,
  formatPercent,
  formatLossToPercent,
} from "components/ProductionV2/format";
import { Suspense, useEffect, useMemo, useRef, useState } from "react";
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";
import { DashboardModalType, modalTypeOpenAtom } from "state/modal";
import { branchIdSelector } from "state/pathParams";
import styled from "styled-components";
import { colors } from "styles/colors";
import { spaceLarge, spaceSmall } from "styles/space";
import { undefMap } from "utils/utils";
import { Column, Row } from "../General/Layout";
import { MenuFrame } from "../MenuPopup/CloseableMenuPopup";
import { MeanSpeedGridLabels } from "./MeanSpeedGridLabels";
import { ProductionTagList } from "./ProductionTagList";
import { SelectAnalysisConfiguration } from "./SelectAnalysisConfiguration";
import { SelectWindConfiguration } from "./SelectWindConfiguration";
import { currentSelectionProduction } from "./Triggers";
import { Visualizations } from "./Visualizations";
import { WakeLabels } from "./WakeLabels";
import { WindRoseWidget } from "./WindRose";
import { WindStatistics } from "./WindStatistics";
import {
  ProdId,
  getAEP,
  getAnalysis,
  getCapacity,
  getCapacityFactor,
  getConfiguration,
  getGrossEnergy,
  getTotalInterArrayLoss,
  getTurbineSpecificLoss,
  getNeighbourWakeLoss,
  getOtherLosses,
  getStoppedReason,
  getTotalExportSystemLoss,
  getTotalWakeLoss,
  analysisOverrideInputAtomFamily,
  getStoppedReasonFromAnalysis,
  getPostWakeEnergy,
  getTriggerAnalysisArgs,
  isOutdated,
  getWindSourceConfiguration,
  getPark,
} from "./state";
import { analysisStoppedText } from "./types";
import { AnnualCableLosses } from "./AnnualCableLosses";
import { triggerAnalysis } from "functions/production";
import { editorAccessProjectSelector } from "state/user";
import { AnalysisConfigNotFoundErrorBoundaryFallbackRender } from "./ErrorUtils";
import { ErrorBoundary } from "react-error-boundary";
import { ProductionError } from "components/ValidationWarnings/FeatureSpecificErrors";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import {
  Divider,
  OverlineText,
  OverlineTextWrapper,
  ResultValue,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import { ChevronIcon } from "components/ToggleableList/ToggleableList";
import { ButtonWrapper } from "components/RightSide/InfoModal/style";
import { useShowScrollShadow } from "hooks/useShowScrollShadow";
import { TourStep } from "components/OnboardingTours/TourStep";
import { FloatingFocusManager, offset, useFloating } from "@floating-ui/react";

const DisabledRow = styled(Row)`
  gap: ${spaceSmall};
  p {
    color: ${colors.primaryDisabled};
  }
  * > svg {
    height: 1.4rem;
    width: 1.4rem;
    padding: 0.1rem;
    path {
      stroke: ${colors.primaryDisabled};
    }
  }
`;

const GridRow = styled(Row)`
  * > svg {
    height: 1.4rem;
    width: 1.4rem;
  }
`;

export const Disabled = ({ text }: { text: string }) => (
  <DisabledRow>
    <ResultValue style={{ color: colors.textDisabled }}>Disabled</ResultValue>
    <HelpTooltip text={text} />
  </DisabledRow>
);

const ShowResults = ({ id }: { id: ProdId }) => {
  const [isProductionOpen, setProductionOpen] = useState<boolean>(true);
  const [isLossesOpen, setLossesOpen] = useState<boolean>(true);
  const { selectedSubAreas } = useRecoilValue(
    analysisOverrideInputAtomFamily(id),
  );
  const hasSelectedZones = (selectedSubAreas?.length ?? 0) !== 0;
  const capacity = useRecoilValueLoadable(getCapacity(id));
  const grossEnergy = useRecoilValueLoadable(getGrossEnergy(id));
  const postWakeEnergy = useRecoilValueLoadable(getPostWakeEnergy(id));
  const netEnergy = useRecoilValueLoadable(getAEP(id));
  const capacityFactor = useRecoilValueLoadable(getCapacityFactor(id));
  const totalWakeLoss = useRecoilValueLoadable(getTotalWakeLoss(id));
  const turbineSpecificLoss = useRecoilValueLoadable(
    getTurbineSpecificLoss(id),
  );
  const neighbourWakeLoss = useRecoilValueLoadable(getNeighbourWakeLoss(id));
  const totalInterArrayLoss = useRecoilValueLoadable(
    getTotalInterArrayLoss(id),
  );
  const totalExportSystemLoss = useRecoilValueLoadable(
    getTotalExportSystemLoss(id),
  );
  const otherLosses = useRecoilValueLoadable(getOtherLosses(id));
  const stoppedReason = useRecoilValueLoadable(
    getStoppedReason(id),
  ).valueMaybe();
  const analysisStoppedReason = useRecoilValueLoadable(
    getStoppedReasonFromAnalysis(id),
  ).valueMaybe();
  const didFail =
    useRecoilValueLoadable(getAnalysis(id)).valueMaybe()?.status === "failed";

  const configuration = useRecoilValueLoadable(
    getConfiguration(id),
  ).valueMaybe();
  const showNeighbourWakeLoss =
    configuration?.wakeAnalysis.neighbourWake ?? false;

  const interArrayLossEnabled =
    (configuration?.electrical.interArrayCableLoss ?? false) ||
    (configuration?.electrical.turbineTrafoLoss ?? false);

  const exportSystemEnabled =
    configuration?.electrical.exportSystemLoss ?? false;

  const analysis = useRecoilValueLoadable(getAnalysis(id)).valueMaybe();
  const progress = analysis?.progress;

  const gridRef = useRef<HTMLDivElement>(null);

  if (stoppedReason !== undefined) {
    return (
      <SimpleAlert
        title="Analysis stopped"
        text={analysisStoppedText[stoppedReason]}
        type={"error"}
      />
    );
  }
  if (analysisStoppedReason) {
    return (
      <SimpleAlert
        title="Analysis stopped"
        text={analysisStoppedText[analysisStoppedReason]}
        type={"error"}
      />
    );
  }
  if (didFail) {
    return (
      <SimpleAlert
        text={"Analysis failed - the Vind team is notified."}
        type={"error"}
      />
    );
  }

  if (!configuration) return null;

  return (
    <>
      <PositionHintRef
        around={gridRef}
        allowedHints={[benchmarkingArticleHintType]}
        position={"left"}
        offset={[`calc(-${spaceLarge} / 2)`, 0]}
      />
      <OverlineTextWrapper onClick={() => setProductionOpen(!isProductionOpen)}>
        <OverlineText>Production</OverlineText>
        <ChevronIcon
          open={isProductionOpen}
          chevronSize={"1rem"}
          style={{
            alignSelf: "center",
          }}
        />
      </OverlineTextWrapper>
      {isProductionOpen && (
        <ColoredGrid style={{ gridTemplateColumns: "4fr 2fr" }}>
          <ResultValue>Capacity</ResultValue>
          {orLoader(capacity, (c) => (
            <ResultValue>
              <strong>{c.toFixed(0)}</strong> MW
            </ResultValue>
          ))}

          <ResultValue>Gross energy</ResultValue>
          {orTextLoader(
            grossEnergy,
            undefMap(progress, (p) => `${Math.round(p * 100)} %`) ?? "",
            (c) => (
              <ResultValue id="gross-energy">
                <strong>{formatGWhConditional(c, true)}</strong> GWh
              </ResultValue>
            ),
          )}

          <ResultValue>Post-wake energy</ResultValue>
          {orTextLoader(
            postWakeEnergy,
            undefMap(progress, (p) => `${Math.round(p * 100)} %`) ?? "",
            (c) => (
              <ResultValue id="post-wake-energy">
                <strong>{formatGWhConditional(c, true)}</strong> GWh
              </ResultValue>
            ),
          )}

          <ResultValue>Net energy</ResultValue>
          {orLoader(netEnergy, (c) => (
            <ResultValue id="net-energy">
              <strong>{formatGWhConditional(c, true)}</strong> GWh
            </ResultValue>
          ))}

          <ResultValue>Capacity factor</ResultValue>
          {orLoader(capacityFactor, (c) => (
            <ResultValue>
              <strong>{formatPercent(c, 1, true)}</strong> %
            </ResultValue>
          ))}
        </ColoredGrid>
      )}
      <OverlineTextWrapper onClick={() => setLossesOpen(!isLossesOpen)}>
        <OverlineText>Losses</OverlineText>

        <ChevronIcon
          open={isLossesOpen}
          chevronSize={"1rem"}
          style={{
            alignSelf: "center",
          }}
        />
      </OverlineTextWrapper>
      {isLossesOpen && (
        <ColoredGrid style={{ gridTemplateColumns: "4fr 2fr" }}>
          <ResultValue>Total wake loss</ResultValue>
          {orLoader(totalWakeLoss, (c) => (
            <ResultValue>
              <strong>{formatLossToPercent(c)}</strong> %
            </ResultValue>
          ))}
          <GridRow>
            <ResultValue>Turbine-specific loss</ResultValue>
            <HelpTooltip
              text={
                "Proportion of the gross output that is lost to turbine-specific losses, which is specific on the turbine type."
              }
            />
          </GridRow>
          {orLoader(turbineSpecificLoss, (c) => (
            <ResultValue>
              <strong>{formatLossToPercent(c)}</strong> %
            </ResultValue>
          ))}

          {!hasSelectedZones && (
            <>
              <ResultValue>Neighbour wake loss</ResultValue>

              {showNeighbourWakeLoss ? (
                orLoader(neighbourWakeLoss, (c) => (
                  <ResultValue>
                    <strong>{formatLossToPercent(c)}</strong> %
                  </ResultValue>
                ))
              ) : (
                <Disabled
                  text={
                    "Neighbour wake loss is disabled in the current analysis configuration."
                  }
                />
              )}

              <ResultValue>Total inter array loss</ResultValue>
              {interArrayLossEnabled ? (
                orLoader(
                  totalInterArrayLoss.map((c) => c?.totalInterArrayLoss),
                  (c) => (
                    <ResultValue>
                      <strong>
                        {formatElectricalLossToPercentDecimal(Number(c), true)}
                      </strong>{" "}
                      %
                    </ResultValue>
                  ),
                )
              ) : (
                <Disabled
                  text={
                    "Inter-array cable loss and turbine trafo loss are disabled in the current analysis configuration."
                  }
                />
              )}

              <ResultValue>Total export system loss</ResultValue>
              {exportSystemEnabled ? (
                orLoader(
                  totalExportSystemLoss.map((c) => c?.totalExportSystemLoss),
                  (c) => (
                    <ResultValue>
                      <strong>
                        {formatElectricalLossToPercentDecimal(Number(c), true)}{" "}
                      </strong>{" "}
                      %
                    </ResultValue>
                  ),
                )
              ) : (
                <Disabled
                  text={
                    "Export system loss is disabled in the current analysis configuration."
                  }
                />
              )}

              <ResultValue>Other losses</ResultValue>
              {orLoader(otherLosses, (c) => (
                <ResultValue>
                  <strong>{formatPercent(c, 1, true)} </strong> %
                </ResultValue>
              ))}
            </>
          )}
        </ColoredGrid>
      )}
    </>
  );
};

const Loader = () => (
  <SkeletonBlock style={{ height: "1rem", width: "inherit" }} />
);

const ReloadAnalysisButton = () => {
  const analysisTriggerArgs = useRecoilValueLoadable(
    getTriggerAnalysisArgs(currentSelectionProduction),
  ).valueMaybe();
  const analysis = useRecoilValueLoadable(
    getAnalysis(currentSelectionProduction),
  ).valueMaybe();
  const stoppedReason = useRecoilValueLoadable(
    getStoppedReason(currentSelectionProduction),
  ).valueMaybe();
  const analysisStoppedReason = useRecoilValueLoadable(
    getStoppedReasonFromAnalysis(currentSelectionProduction),
  ).valueMaybe();

  const outdated = useRecoilValueLoadable(
    isOutdated(currentSelectionProduction),
  ).valueMaybe();

  const isProjectEditor = useRecoilValueLoadable(
    editorAccessProjectSelector,
  ).valueMaybe();

  const [restarted, setRestarted] = useState<boolean>(false);
  const [rerunWithKey, setRerunWithKey] = useState(false);

  const hasFailed = analysis?.status === "failed";

  useEffect(() => {
    const show = (event: KeyboardEvent) => {
      if (
        event.repeat ||
        event.isComposing ||
        !event.key ||
        event.key.toLowerCase() !== "r"
      ) {
        return;
      }
      setRerunWithKey(true);
    };
    const hide = (event: KeyboardEvent) => {
      if (
        event.repeat ||
        event.isComposing ||
        !event.key ||
        event.key.toLowerCase() !== "r"
      ) {
        return;
      }
      setRerunWithKey(false);
    };

    window.addEventListener("keydown", show);
    window.addEventListener("keyup", hide);

    return () => {
      window.removeEventListener("keydown", show);
      window.removeEventListener("keyup", hide);
    };
  }, [setRerunWithKey]);

  if (
    !(
      (outdated || rerunWithKey || hasFailed) &&
      !stoppedReason &&
      !analysisStoppedReason
    )
  )
    return null;
  return (
    <Grid2 style={{ gridTemplateColumns: "4fr 2fr" }}>
      <p style={{ color: colors.brand }}>
        {rerunWithKey
          ? "Force rerunning analysis"
          : hasFailed
            ? "You may try rerunning"
            : "New version available"}
      </p>
      <Button
        text={"Rerun"}
        style={{ height: "2rem" }}
        onClick={() => {
          if (!analysisTriggerArgs) return;
          setRestarted(true);
          triggerAnalysis({
            ...analysisTriggerArgs,
            restart: true,
          });
        }}
        disabled={!analysisTriggerArgs || restarted || !isProjectEditor}
      />
    </Grid2>
  );
};

export const ProductionV2 = ({ onClose }: { onClose(): void }) => {
  const parkL = useRecoilValueLoadable(getPark(currentSelectionProduction));
  const park = parkL.valueMaybe();
  const branchId = useRecoilValue(branchIdSelector);
  const setModalType = useSetRecoilState(modalTypeOpenAtom);
  const currentSelectionProductionLoadable = useRecoilValueLoadable(
    getConfiguration(currentSelectionProduction),
  );
  const { scrollBodyRef } = useShowScrollShadow(true);

  const currentWindSourceConfigurationLoadable = useRecoilValueLoadable(
    getWindSourceConfiguration(currentSelectionProduction),
  );
  const resetKeys = useMemo(
    () => [
      currentSelectionProductionLoadable.state,
      currentWindSourceConfigurationLoadable.state,
    ],
    [
      currentSelectionProductionLoadable,
      currentWindSourceConfigurationLoadable,
    ],
  );

  const { refs, floatingStyles, context } = useFloating({
    placement: "left-start",
    middleware: [offset({ mainAxis: 20, crossAxis: 20 })],
  });

  if (!park) return null;

  return (
    <>
      <FloatingFocusManager context={context} modal={false}>
        <TourStep
          tourId="general-intro-tour"
          stepId="productionStatistics"
          innerRef={refs.setFloating}
          style={floatingStyles}
        />
      </FloatingFocusManager>
      <MenuFrame
        icon={<HelpLink article={ARTICLE_YIELD_ASSESSMENT} />}
        title={"Production"}
        validationError={
          <ProductionError parkId={park.id} branchId={branchId ?? ""} />
        }
        onExit={onClose}
        style={{
          overflowY: "visible",
        }}
      >
        <Column style={{ padding: 0, gap: 0 }}>
          <div
            ref={scrollBodyRef}
            style={{
              maxHeight: "calc(100vh - 32rem)",
              overflowY: "auto",
              padding: "0 1.6rem 1.6rem 1.6rem",
            }}
          >
            <Column>
              <SubtitleWithLine text="Branch configurations" />
              <Suspense fallback={<Loader />}>
                <SelectAnalysisConfiguration />
                <SelectWindConfiguration />
              </Suspense>
            </Column>

            <ErrorBoundary
              fallbackRender={AnalysisConfigNotFoundErrorBoundaryFallbackRender}
              resetKeys={resetKeys}
            >
              <Column>
                <SubtitleWithLine
                  style={{ paddingTop: spaceLarge }}
                  text="Annual production statistics"
                  innerRef={refs.setReference}
                />

                <Suspense fallback={<Loader />}>
                  <ProductionTagList id={currentSelectionProduction} />
                </Suspense>

                <ReloadAnalysisButton />

                <ShowResults id={currentSelectionProduction} />
              </Column>

              <Column>
                <Suspense fallback={<Loader />}>
                  <WindRoseWidget id={currentSelectionProduction} />
                </Suspense>
              </Column>

              <Column>
                <Suspense fallback={<Loader />}>
                  <WindStatistics id={currentSelectionProduction} />
                </Suspense>
              </Column>

              <Column>
                <Suspense fallback={<Loader />}>
                  <Visualizations id={currentSelectionProduction} />
                </Suspense>
              </Column>
              <Column>
                <Suspense fallback={null}>
                  <MeanSpeedGridLabels id={currentSelectionProduction} />
                </Suspense>
                <Suspense fallback={null}>
                  <WakeLabels id={currentSelectionProduction} />
                </Suspense>
                <Suspense fallback={null}>
                  <AnnualCableLosses id={currentSelectionProduction} />
                </Suspense>
              </Column>
            </ErrorBoundary>
          </div>
          <Divider style={{ marginLeft: "-2rem", marginRight: "-2rem" }} />
          <ButtonWrapper style={{ paddingRight: "1.6rem" }}>
            <Button
              text="View in dashboard"
              icon={<Dashboard />}
              buttonType="secondary"
              onClick={() => {
                setModalType({
                  modalType: DashboardModalType,
                  metadata: { id: "vind-preset-dashboard-yield" },
                });
              }}
            />
          </ButtonWrapper>
        </Column>
      </MenuFrame>
    </>
  );
};
