import { useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import mapboxgl, { ImageSource } from "mapbox-gl";
import { mapRefAtom } from "../../state/map";
import { useTypedPath } from "../../state/pathParams";
import {
  ExternalDataSourceLinkLayerWithSourceArcgis,
  LayerType,
} from "../../types/layers";
import { getVisibleLayers } from "../../components/LayerList/LayerSettings/utils";
import {
  ErrorBoundarySilent,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "../../components/ErrorBoundaries/ErrorBoundaryLocal";
import { safeRemoveLayer } from "../../utils/map";
import { isArcgisLayer } from "../../state/arcgisRestAPI";
import { layerToSourceId } from "./utils";
import { addCorsAndCacheProxyURL } from "../../state/gisSourceCorsProxy";
import { externalWFSAnchorId } from "../../components/Mapbox/utils";
import { NATIVE_MAP_ID } from "components/MapNative/MapNative";

const getUrl = (
  layer: ExternalDataSourceLinkLayerWithSourceArcgis,
  bbox: number[],
) => {
  const isPrivate = "private" in layer.source ? layer.source["private"] : false;
  const mapElement = document.getElementById(NATIVE_MAP_ID);
  if (!mapElement) throw new Error("Could not find the map element");
  const windowWidth = mapElement.clientWidth;
  const windowHeight = mapElement.clientHeight;

  return `${addCorsAndCacheProxyURL(
    layer.sourceLink.url,
    isPrivate,
  )}/export?dpi=96&transparent=true&format=png32&layers=show%3A${
    layer.sourceLayerId
  }&bbox=${bbox}&bboxSR=4326&imageSR=3857&size=${windowWidth}%2C${windowHeight}&f=image`;
};

const getBBOXes = (bounds: mapboxgl.LngLatBounds) => {
  const bbox = [
    bounds.getNorthWest().toArray(),
    bounds.getNorthEast().toArray(),
    bounds.getSouthEast().toArray(),
    bounds.getSouthWest().toArray(),
  ];

  const xyMinMaxBBOX = [
    bounds.getSouthWest().toArray(),
    bounds.getNorthEast().toArray(),
  ].flat();

  return {
    bbox,
    xyMinMaxBBOX,
  };
};

const DynamicArcgisRasterLayer = ErrorBoundaryWrapper(
  ({ layer }: { layer: ExternalDataSourceLinkLayerWithSourceArcgis }) => {
    const map = useRecoilValue(mapRefAtom);
    const sourceId = useMemo(() => layerToSourceId(layer, "-raster"), [layer]);

    useEffect(() => {
      if (!map) {
        return;
      }
      const bounds = map.getBounds();
      const { bbox, xyMinMaxBBOX } = getBBOXes(bounds);

      map.addSource(sourceId, {
        type: "image",
        url: getUrl(layer, xyMinMaxBBOX),
        coordinates: bbox,
      });

      map.addLayer(
        {
          id: sourceId,
          type: "raster",
          source: sourceId,
          paint: {
            "raster-fade-duration": 0,
          },
        },
        map.getLayer(externalWFSAnchorId) ? externalWFSAnchorId : undefined,
      );

      map.on("moveend", ({ target: currentMap }) => {
        const bounds = currentMap.getBounds();
        const { bbox, xyMinMaxBBOX } = getBBOXes(bounds);

        const source = currentMap.getSource(sourceId) as
          | ImageSource
          | undefined;
        if (!source) {
          return;
        }

        source.updateImage({
          url: getUrl(layer, xyMinMaxBBOX),
          coordinates: bbox,
        });
      });

      return () => {
        safeRemoveLayer(map, sourceId);
        map.removeSource(sourceId);
      };
    }, [layer, map, sourceId]);

    return null;
  },
  ErrorBoundarySilent,
  ScreamOnError,
);

const DynamicArcgisRasterLayers = ErrorBoundaryWrapper(
  () => {
    const { projectId } = useTypedPath("projectId");
    const visibleLayers = useRecoilValue(getVisibleLayers({ projectId }));
    const selectedDynamicArcgisLayers = visibleLayers
      .filter(isArcgisLayer)
      .filter((layer) => layer.type === LayerType.Raster);

    return (
      <>
        {selectedDynamicArcgisLayers.map((layer) => {
          return <DynamicArcgisRasterLayer key={layer.id} layer={layer} />;
        })}
      </>
    );
  },
  ErrorBoundarySilent,
  ScreamOnError,
);

export default DynamicArcgisRasterLayers;
