import { useEffect, useMemo, useRef } from "react";
import { useRecoilValue } from "recoil";
import pako from "pako";
import { modalTypeOpenAtom } from "state/modal";
import { useDynamicStreamer } from "state/layer";
import { FeatureCollection } from "geojson";
import EditMap from "./EditMap";
import FullScreenLoader from "components/FullScreenLoader/FullScreenLoader";
import stc from "string-to-color";
import { mapLayerMetadataToCustomLayer } from "state/customLayers";
import { replaceCustomOrganisationLayer } from "services/customLayersAPIService";
import { organisationIdSelector } from "state/pathParams";
import { useDataLibraryLayersCrud } from "../useDataLibraryLayersCrud";
import { organisationLibraryLayerSelector } from "../state";
import { CUSTOM_LAYER_TYPE } from "@constants/customLayers";

export const EditVectordataType = "EditVectordata" as const;

const EditVectordataInner = ({ layerId }: { layerId: string }) => {
  const layer = useRecoilValue(organisationLibraryLayerSelector({ layerId }));
  const shouldRefreshLayers = useRef(false);
  const organisationId = useRecoilValue(organisationIdSelector);
  const { refreshLayers, editLayerMetadata } = useDataLibraryLayersCrud();
  const customLayer = useMemo(
    () =>
      mapLayerMetadataToCustomLayer(
        layer,
        CUSTOM_LAYER_TYPE.CUSTOM_LAYER_SOURCE_TYPE,
      ),
    [layer],
  );

  const { dynamicVectorLayerFeatures, resetDynamicVectorLayerFeatures } =
    useDynamicStreamer(customLayer);
  const featureCollection: FeatureCollection = useMemo(
    () => ({
      type: "FeatureCollection",
      features: dynamicVectorLayerFeatures.features,
    }),
    [dynamicVectorLayerFeatures],
  );

  const color = useMemo(() => stc(layer.id), [layer.id]);

  useEffect(() => {
    return () => {
      if (shouldRefreshLayers.current) {
        refreshLayers();
      }
    };
  }, [refreshLayers]);

  useEffect(() => {
    return () => {
      resetDynamicVectorLayerFeatures();
    };
  }, [resetDynamicVectorLayerFeatures]);

  if (!dynamicVectorLayerFeatures.isFinished) {
    return <FullScreenLoader />;
  }

  return (
    <EditMap
      featureCollection={featureCollection}
      layer={layer}
      onSaveNewName={(newName: string) =>
        editLayerMetadata(layer.id, { name: newName })
      }
      onSave={async (newFeatureCollection: FeatureCollection) => {
        if (!organisationId) return;
        const compressedUint8Array = pako.gzip(
          new TextEncoder().encode(JSON.stringify(newFeatureCollection)),
        );
        const gzipFileToUpload = new File(
          [compressedUint8Array],
          `${layer.name}.gz`,
        );
        await replaceCustomOrganisationLayer(
          organisationId,
          gzipFileToUpload,
          layer.id,
        );
        shouldRefreshLayers.current = true;
      }}
      color={color}
    />
  );
};

const EditVectordata = () => {
  const modalTypeOpen = useRecoilValue(modalTypeOpenAtom);

  if (modalTypeOpen?.modalType !== EditVectordataType) {
    return null;
  }

  return <EditVectordataInner layerId={modalTypeOpen.metadata.layerId} />;
};

export default EditVectordata;
