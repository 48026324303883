import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import Button from "../General/Button";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CostConfiguration } from "../../services/costService";
import { savingCostConfigurationInProgressAtom } from "../../state/costConfigurations";
import { inReadOnlyModeSelector } from "../../state/project";
import { toastMessagesAtom } from "../../state/toast";
import Tabs from "../General/Tabs";
import {
  ARTICLE_FINANCIAL_CONFIGURATIONS,
  HelpLink,
} from "../HelpTooltip/HelpTooltip";
import { unsavedSettingsState } from "../SettingsV2/Shared/state";
import {
  ContentWrapper,
  SettingsFooter,
  SettingsHeader,
} from "../SettingsV2/Shared/styles";
import { Capex } from "./Cost/Capex";
import { localCostConfigurationAtom } from "./Cost/state";
import { Lcoe } from "./Cost/LCOE/Lcoe";
import CostConfigurationUsageCurrentProject, {
  CostConfigurationUsageLibrary,
} from "./SettingsUsage/CostConfigurationUsage";
import { CashFlows } from "./Cost/CashFlows";
import useEnterToSave from "./useEnterToSave";
import { OtherCosts } from "./Cost/OtherCosts/OtherCosts";
import { useLocation, useNavigate } from "react-router-dom";
import { selectedOrgTabState } from "components/Organisation/state";
import { useTypedPath } from "state/pathParams";
import { useDuplicateFinancialConfigToProject } from "components/SettingsV2/FeatureSettings/Data/useDuplicateToProject";
import { orgFinanicalManageAccessSelector } from "state/user";
import { libraryTabState } from "components/Organisation/Library/state";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { EditableText } from "components/General/EditableText";
import {
  ContentContainer,
  HeaderContainer,
  Row,
  SubHeader,
  ElementsContainer,
} from "./shared";

export const CostSettingsForLibraryConfig = ({
  configuration,
}: {
  configuration: CostConfiguration;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const setOrgtab = useSetRecoilState(selectedOrgTabState);
  const { organisationId } = useTypedPath("organisationId");

  const { duplicateToProject, isLoading: isLoadingDuplicate } =
    useDuplicateFinancialConfigToProject();
  const setSelectedTab = useSetRecoilState(libraryTabState({ organisationId }));
  const setContent = useSetRecoilState(
    organisationRightSideModal(organisationId),
  );
  const orgFinancialManageAccess = useRecoilValue(
    orgFinanicalManageAccessSelector,
  );
  return (
    <>
      <SettingsHeader>
        {orgFinancialManageAccess && (
          <Button
            buttonType="text"
            text="Edit financial configuration in Library"
            onClick={() => {
              setOrgtab("Library");
              setSelectedTab("financial");
              setContent({ type: "resource", id: configuration.id });
              navigate(`/organisation/${organisationId}${location.search}`);
            }}
            style={{ marginLeft: "auto" }}
          />
        )}
        <Button
          text="Duplicate as project financial configuation"
          onClick={() => duplicateToProject(configuration)}
          disabled={isLoadingDuplicate}
        />
      </SettingsHeader>
      <CostSettingsInner
        configuration={configuration}
        configurationName={configuration.name}
        readOnly={true}
        isLibraryResource={true}
      />
    </>
  );
};

export const CostSettingsInner = ({
  configuration,
  configurationName,
  save,
  saveName,
  onUnmount,
  readOnly,
  isLibraryResource,
}: {
  configuration: CostConfiguration;
  configurationName: string;
  save?: (config: CostConfiguration) => Promise<any>;
  saveName?: (id: string, name: string) => Promise<any>;
  onUnmount?: () => void;
  readOnly?: boolean;
  isLibraryResource?: boolean;
}) => {
  const isSaving = useRecoilValue(savingCostConfigurationInProgressAtom);
  const isReadOnly = useRecoilValue(inReadOnlyModeSelector) || readOnly;
  const [localConfig, setLocalConfig] = useRecoilState(
    localCostConfigurationAtom,
  );
  const setUnsavedSettings = useSetRecoilState(unsavedSettingsState);
  const setToastMessages = useSetRecoilState(toastMessagesAtom);
  const [title, setTitle] = useState(configurationName);

  useEffect(() => {
    setTitle(configurationName);
  }, [configurationName]);

  const isLoading = localConfig.id !== configuration.id;
  const allChangesSaved = isReadOnly || localConfig === configuration;

  useEffect(() => {
    setUnsavedSettings(!isLoading && !allChangesSaved);
  }, [allChangesSaved, isLoading, setUnsavedSettings]);

  useEffect(() => {
    setLocalConfig(configuration);
  }, [configuration, setLocalConfig]);

  const onEnterSaveCallback = useCallback(() => {
    if (!save) return;
    save(localConfig);
  }, [localConfig, save]);
  useEnterToSave(onEnterSaveCallback, !allChangesSaved && !isSaving);

  const renameConfig = useCallback(() => {
    if (!saveName) return;

    const renameConfigAsync = async () => {
      await saveName(configuration.id, title);
    };

    renameConfigAsync();
  }, [saveName, configuration, title]);

  const tabs = useMemo(() => {
    return [
      {
        name: "CAPEX",
        data: <Capex isReadOnly={isReadOnly} />,
      },
      {
        name: "Other expenditures",
        data: <OtherCosts isReadOnly={isReadOnly} />,
      },
      {
        name: "Cost model",
        data: <Lcoe isReadOnly={isReadOnly} />,
      },
      {
        name: "Cash flows",
        data: <CashFlows isReadOnly={isReadOnly} />,
      },
    ];
  }, [isReadOnly]);

  useEffect(() => {
    return () => {
      if (onUnmount) {
        onUnmount();
      }
    };
  }, [onUnmount]);

  if (!localConfig) return null;

  return (
    <>
      {!allChangesSaved && !readOnly && save && (
        <SettingsHeader>
          <Button
            disabled={isLoading || isSaving || allChangesSaved}
            text="Cancel"
            buttonType="secondary"
            onClick={() => {
              setLocalConfig(configuration);
            }}
            style={{ marginLeft: "auto" }}
          />
          <Button
            disabled={isLoading || allChangesSaved || isSaving}
            text="Save changes"
            onClick={() => {
              setToastMessages((tm) => [
                ...tm,
                { text: "Saving...", timeout: 1000 },
              ]);
              save({ ...localConfig, name: title });
            }}
          />
        </SettingsHeader>
      )}
      <ContentWrapper
        style={{
          maxHeight: "calc(100% - 7.3rem)",
          overflowY: "auto",
          boxSizing: "border-box",
          paddingBottom: "7rem",
        }}
      >
        <ContentContainer>
          <HeaderContainer>
            <Row>
              <SubHeader style={{ margin: 0 }}>
                Financial configuration
              </SubHeader>
              <HelpLink article={ARTICLE_FINANCIAL_CONFIGURATIONS} />
            </Row>
            <EditableText
              type="text"
              smallInput={true}
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              onEnter={renameConfig}
              onCancel={() => {
                setTitle(configurationName);
              }}
              textContainerStyle={{
                maxWidth: "20vw",
                padding: 0,
              }}
              renderText={(title) => (
                <h3
                  style={{
                    margin: 0,
                    whiteSpace: "nowrap",
                    overflowX: "hidden",
                    textOverflow: "ellipsis",
                    display: "block",
                  }}
                  title={title}
                >
                  {title}
                </h3>
              )}
              disabled={isReadOnly}
            />
          </HeaderContainer>
          <ElementsContainer>
            <Tabs
              tabs={tabs}
              menuStyle={{ justifyContent: "start", gap: "5rem" }}
            />
          </ElementsContainer>
        </ContentContainer>
      </ContentWrapper>
      <SettingsFooter>
        {isLibraryResource ? (
          <CostConfigurationUsageLibrary
            costConfigurationId={configuration.id}
          />
        ) : (
          <CostConfigurationUsageCurrentProject
            costConfigurationId={configuration.id}
          />
        )}
      </SettingsFooter>
    </>
  );
};
