/// <reference types="vite-plugin-svgr/client" />
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import DropdownButton, {
  ButtonText,
  ButtonTextWrapper,
} from "../General/Dropdown/DropdownButton";
import { DropDownItem } from "../General/Dropdown/DropdownItems";
import { useTypedPath } from "../../state/pathParams";
import CostIcon from "@icons/24/Dollar.svg?react";
import {
  costConfigurationsAtomFamily,
  libraryFinancialConfigOnNodeTypesState,
} from "../../state/costConfigurations";
import { CostConfiguration } from "../../services/costService";
import { IconREMSize } from "styles/typography";
import { colors } from "styles/colors";
import ProjectIcon from "@icons/24/ProjectGlobe.svg?react";
import LibraryIcon from "@icons/24/Library.svg?react";

export const CostConfigurationSelector = ({
  configuration,
  setConfiguration,
}: {
  configuration?: CostConfiguration;
  setConfiguration: (cfg: CostConfiguration) => void;
}) => {
  const { projectId } = useTypedPath("projectId");

  const projectCostConfigurations = useRecoilValue(
    costConfigurationsAtomFamily({
      projectId,
    }),
  );

  const libraryCostConfigurations = useRecoilValue(
    libraryFinancialConfigOnNodeTypesState({ nodeId: projectId }),
  );

  const costConfigurations = useMemo(
    () => [...projectCostConfigurations, ...libraryCostConfigurations],
    [projectCostConfigurations, libraryCostConfigurations],
  );

  const items: DropDownItem[] = useMemo(() => {
    return [
      ...libraryCostConfigurations.map((cfg) => {
        return {
          value: cfg.id,
          name: cfg.name ?? "[no name]",
          icon: (
            <IconREMSize fill={colors.textPrimary} width={1.4} height={1.4}>
              <LibraryIcon />
            </IconREMSize>
          ),
        };
      }),
      ...projectCostConfigurations.map((cfg) => {
        return {
          value: cfg.id,
          name: cfg.name ?? "[no name]",
          icon: (
            <IconREMSize fill={colors.textPrimary} width={1.4} height={1.4}>
              <ProjectIcon />
            </IconREMSize>
          ),
        };
      }),
    ];
  }, [projectCostConfigurations, libraryCostConfigurations]);

  const selected = items.find((i) => i.value === configuration?.id);

  return (
    <DropdownButton
      items={items}
      onSelectItem={(e) => {
        const cfg = costConfigurations.find((c) => c.id === e);
        if (!cfg) throw new Error("Configuration not found");
        setConfiguration(cfg);
      }}
      buttonText={selected?.name ?? "Select cost configuration"}
      className="dashboard-dropdown"
      selectedItemValue={selected?.value}
      renderText={(e) => (
        <ButtonTextWrapper>
          <CostIcon />
          <ButtonText>{e}</ButtonText>
        </ButtonTextWrapper>
      )}
    />
  );
};
