import { selectorFamily } from "recoil";
import { getTurbinesTypeToTurbinesSelectorFamily } from "../../../../state/layout";
import { allSimpleTurbineTypesSelector } from "../../../../state/turbines";

import { AmountUnit } from "../../../../types/financial";
import { SimpleTurbineType } from "../../../../types/turbines";
import { CostType } from "../../../../services/costService";
import { Amount, amountId } from "../generalAmounts";
import { undefMap } from "../../../../utils/utils";
import {
  ProdId,
  analysisOverrideInputAtomFamily,
  getBranchId,
  getParkId,
} from "components/ProductionV2/state";

export const allTurbineTypesInParkSelectorFamily = selectorFamily<
  SimpleTurbineType[],
  { branchId: string; parkId: string; turbineTypeOverride?: SimpleTurbineType }
>({
  key: "allTurbineTypesInParkSelectorFamily",
  get:
    ({ branchId, parkId, turbineTypeOverride }) =>
    ({ get }) => {
      const turbineNameToTurbinesMap = get(
        getTurbinesTypeToTurbinesSelectorFamily({
          parkId,
          branchId,
          turbineTypeOverride,
        }),
      );
      const allTurbineTypes = get(allSimpleTurbineTypesSelector);
      const allTurbineTypesInPark = allTurbineTypes.filter(
        (tt) => tt.id in turbineNameToTurbinesMap,
      );
      return allTurbineTypesInPark;
    },
});

export const turbineAmountSelectorFamily = selectorFamily<Amount[], ProdId>({
  key: "turbineAmountSelectorFamily",
  get:
    (productionId) =>
    ({ get }) => {
      const branchId = get(getBranchId(productionId));
      const parkId = get(getParkId(productionId));

      const input = get(analysisOverrideInputAtomFamily(productionId));

      const allTurbineTypesInPark = get(
        allTurbineTypesInParkSelectorFamily({
          branchId,
          parkId,
          turbineTypeOverride: input.turbineTypeOverride,
        }),
      );
      const turbineNameToTurbinesMap = get(
        getTurbinesTypeToTurbinesSelectorFamily({
          parkId,
          branchId,
          turbineTypeOverride: input.turbineTypeOverride,
        }),
      );

      const perUnitAmount = allTurbineTypesInPark.map((turbineType) => {
        const amount = turbineNameToTurbinesMap[turbineType.id]?.length ?? 0;
        const unit = AmountUnit.unit;
        const category = CostType.Turbine;

        return {
          id: amountId({ unit, category, featureTypeId: turbineType.id }),
          unit,
          amount,
          category,
        };
      });

      const perMwAmount = allTurbineTypesInPark.map((turbineType) => {
        const amount =
          undefMap(
            turbineNameToTurbinesMap[turbineType.id]?.length,
            (len) => (len * turbineType.ratedPower) / 1000,
          ) ?? 0;
        const unit = AmountUnit.MW;
        const category = CostType.Turbine;

        return {
          id: amountId({ unit, category, featureTypeId: turbineType.id }),
          unit,
          amount,
          category,
        };
      });

      const fixedAmount = {
        id: amountId({
          unit: AmountUnit.fixed,
          category: CostType.Turbine,
        }),
        unit: AmountUnit.fixed,
        amount: 1,
        category: CostType.Turbine,
      };

      return [...perUnitAmount, ...perMwAmount, fixedAmount];
    },
});
