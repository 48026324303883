import { useRecoilCallback } from "recoil";
import { v4 as uuid4 } from "uuid";
import { ToastMessage, toastMessagesAtom } from "../state/toast";

export const useToast = () => {
  const success = useRecoilCallback(
    ({ set }) =>
      (
        text: ToastMessage["text"],
        {
          timeout,
          showCountdown,
          link,
        }: Pick<ToastMessage, "timeout" | "showCountdown" | "link"> = {
          timeout: 5000,
          showCountdown: true,
        },
      ) => {
        const t: ToastMessage = {
          id: uuid4(),
          text,
          type: "success",
          timeout,
          showCountdown: showCountdown ?? true,
          link,
        };
        set(toastMessagesAtom, (curr) => [...curr, t]);
      },
    [],
  );

  const info = useRecoilCallback(
    ({ set }) =>
      (
        text: ToastMessage["text"],
        {
          timeout,
          showCountdown,
          link,
        }: Pick<ToastMessage, "timeout" | "showCountdown" | "link"> = {
          timeout: 5000,
          showCountdown: false,
        },
      ) => {
        const t: ToastMessage = {
          // id: uuid4(),
          text,
          type: "info",
          timeout,
          showCountdown: showCountdown ?? false,
          link,
        };
        set(toastMessagesAtom, (curr) => [...curr, t]);
      },
    [],
  );

  const warning = useRecoilCallback(
    ({ set }) =>
      (
        text: ToastMessage["text"],
        {
          timeout,
          showCountdown,
          link,
        }: Pick<ToastMessage, "timeout" | "showCountdown" | "link"> = {
          timeout: 5000,
          showCountdown: true,
        },
      ) => {
        const t: ToastMessage = {
          id: uuid4(),
          text,
          type: "warning",
          timeout,
          showCountdown: showCountdown ?? true,
          link,
        };
        set(toastMessagesAtom, (curr) => [...curr, t]);
      },
    [],
  );

  const error = useRecoilCallback(
    ({ set }) =>
      (
        text: ToastMessage["text"],
        {
          timeout,
          showCountdown,
          link,
        }: Pick<ToastMessage, "timeout" | "showCountdown" | "link"> = {
          timeout: 5000,
          showCountdown: true,
        },
      ) => {
        const t: ToastMessage = {
          id: uuid4(),
          text,
          type: "error",
          timeout,
          showCountdown: showCountdown ?? true,
          link,
        };
        set(toastMessagesAtom, (curr) => [...curr, t]);
      },
    [],
  );

  return { success, info, warning, error };
};
