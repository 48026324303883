import { ablyLoadedState } from "./../state/ably";
import { useRecoilCallback } from "recoil";

export default function useAblyRefreshToken() {
  const callback = useRecoilCallback(({ snapshot }) => async () => {
    const client = await snapshot.getPromise(ablyLoadedState);
    if (!client) return;
    return new Promise((res, rej) =>
      client.auth.authorize(undefined, undefined, (error) => {
        if (error) {
          rej(error);
        }
        res("success");
      }),
    );
  });

  return callback;
}
