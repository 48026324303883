import Plain from "../assets/plain.jpg";
import Satellite from "../assets/satellite.jpg";
import Street from "../assets/street.jpg";
import BathBlank from "../assets/bath-blank.png";
import BathRaster from "../assets/bath-raster.png";
import BathContour from "../assets/bath-contour.png";

export const bathymetryStyles = [
  {
    id: "raster",
    name: "Raster",
    image: BathRaster,
  },
  {
    id: "contour",
    name: "Contours",
    image: BathContour,
  },
];
export type BathymetryStyle = (typeof bathymetryStyles)[number];
export type BathymetryStyleId = BathymetryStyle["id"];

export const getBathymetryStyle = (id: BathymetryStyleId | undefined) =>
  bathymetryStyles.find((style) => style.id === id);

export const availableMapStyles = [
  {
    id: "mapbox://styles/vindai/clnucl45400nm01plhzq2eja6",
    image: Street,
    text: "Street",
    useBathymetry: true,
  },
  {
    id: "mapbox://styles/vindai/cl7khk1rl000l16lnommr0cr3",
    image: Plain,
    text: "Plain",
    useBathymetry: true,
  },
  {
    id: "mapbox://styles/vindai/clnvfzne8000z01qvbhzthyoj",
    image: Satellite,
    text: "Satellite",
    useBathymetry: false,
  },
];
export type MapStyle = (typeof availableMapStyles)[number];
export type MapStyleId = MapStyle["id"];

export const getMapStyle = (
  mapStyleId: string,
): (typeof availableMapStyles)[0] | undefined => {
  return (
    availableMapStyles.find((style) => style.id === mapStyleId) ??
    availableMapStyles[0]
  );
};

export const otherMap = [
  {
    id: "gwa",
    name: "GWA",
    tooltip: "Global Wind Atlas",
    image: BathBlank,
  },
  {
    id: "lcoe-map",
    name: "LCoE",
    image: BathBlank,
  },
];

export type OtherMap = (typeof otherMap)[number];
export type OtherMapId = OtherMap["id"];

export const getOtherMap = (
  otherMapId: string | undefined,
): (typeof otherMap)[0] | undefined => {
  return otherMap.find((style) => style.id === otherMapId);
};
