import React, { useEffect, useRef } from "react";
import { useRecoilValue } from "recoil";
import {
  CABLE_PROPERTY_TYPE,
  EXPORT_CABLE_PROPERTY_TYPE,
  SUBSTATION_PROPERTY_TYPE,
} from "../../../../../constants/cabling";
import {
  DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
  SUB_AREA_PROPERTY_TYPE,
} from "../../../../../constants/division";
import { PARK_PROPERTY_TYPE } from "../../../../../constants/park";
import {
  ANCHOR_PROPERTY_TYPE,
  TURBINE_PROPERTY_TYPE,
  PORT_POINT_TYPE,
} from "../../../../../constants/projectMapView";
import { VIEW_POINT_TYPE } from "../../../../../constants/viewPoint";
import { ProjectFeature } from "../../../../../types/feature";
import { useTypedPath } from "../../../../../state/pathParams";
import { currentTurbineIdAtom } from "../../../../../state/turbines";
import { colors } from "../../../../../styles/colors";
import { FeatureWithAnyProperties } from "../../../../../types/feature";
import Dropdown from "../../../../Dropdown/Dropdown";
import { SelectedFileType } from "../types";
import { _FeatureParser } from "../../../../../types/feature";
import useCreateCableSlicesBetweenTurbinesAndSubstations from "components/MapControls/Edit/Callbacks/useCreateCableSlicesBetweenTurbinesAndSubstations";
import { isLineStringFeature } from "utils/predicates";

const ChangeTypeDropdown = ({
  selectedParkId,
  row,
  setUpdateStatement,
  preSelectedFeatureType,
}: {
  selectedParkId: string;
  row: {
    file: SelectedFileType;
    features: FeatureWithAnyProperties[];
    featureType: ProjectFeature["geometry"]["type"] | undefined;
  };
  setUpdateStatement: (updateStatement: {
    add?: ProjectFeature[];
    remove?: string[];
    update?: ProjectFeature[];
  }) => void;
  preSelectedFeatureType?: ProjectFeature["properties"]["type"];
}) => {
  const ref = useRef<HTMLSelectElement>(null);
  const { projectId } = useTypedPath("projectId");
  const currentTurbineType = useRecoilValue(
    currentTurbineIdAtom({ projectId }),
  );
  const createCableSlices = useCreateCableSlicesBetweenTurbinesAndSubstations();

  // Force onChange to run if preSelectedFeatureType is set
  useEffect(() => {
    if (preSelectedFeatureType && ref.current) {
      ref.current.dispatchEvent(new Event("change", { bubbles: true }));
    }
  }, [preSelectedFeatureType]);

  return (
    <Dropdown
      ref={ref}
      defaultValue={preSelectedFeatureType ?? ""}
      onChange={({ target }) => {
        const { value, selectedOptions } = target;
        const properties = selectedOptions.item(0)?.dataset;

        if (value === CABLE_PROPERTY_TYPE) {
          const linestrings = row.features.filter(isLineStringFeature);
          const createdCableSliceResults = createCableSlices(
            linestrings,
            selectedParkId,
          );

          const createdCables = createdCableSliceResults[0];
          const updatedCables = createdCableSliceResults[1];

          setUpdateStatement({
            add: createdCables,
            update: updatedCables,
          });
        } else {
          setUpdateStatement({
            add: row.features.map((feature) =>
              _FeatureParser.parse({
                ...feature,
                properties: {
                  ...feature.properties,
                  ...properties,
                  name: feature.properties?.name,
                  type: value,
                  parentIds:
                    Boolean(selectedParkId) &&
                    ![
                      PARK_PROPERTY_TYPE,
                      DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
                    ].includes(value)
                      ? [selectedParkId]
                      : undefined,
                },
              }),
            ),
          });
        }
      }}
    >
      <option value="">Other</option>
      {row.featureType === "Polygon" && (
        <>
          <option value={PARK_PROPERTY_TYPE} data-color={colors.park2}>
            Park
          </option>
          <option
            value={DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE}
            data-color={colors.exclusionZone}
          >
            Exclusion zone
          </option>
        </>
      )}
      {selectedParkId && row.featureType === "Polygon" && (
        <>
          <option value={SUB_AREA_PROPERTY_TYPE} data-color={colors.subArea}>
            Sub area
          </option>
        </>
      )}
      {selectedParkId && row.featureType === "LineString" && (
        <>
          <option
            value={EXPORT_CABLE_PROPERTY_TYPE}
            data-color={colors.exportCable}
          >
            Export cable
          </option>
        </>
      )}
      {selectedParkId && row.featureType === "LineString" && (
        <>
          <option value={CABLE_PROPERTY_TYPE} data-color={colors.cable}>
            Inter array cable
          </option>
        </>
      )}
      {row.featureType === "Point" && (
        <>
          <option value={VIEW_POINT_TYPE} data-color={colors.viewPoint}>
            Viewpoints
          </option>
          <option value={PORT_POINT_TYPE} data-color={colors.portPoint}>
            Ports
          </option>
          {selectedParkId && (
            <>
              <option
                value={TURBINE_PROPERTY_TYPE}
                data-turbine-type-id={currentTurbineType}
                data-color={colors.turbine}
              >
                Turbines
              </option>
              <option value={ANCHOR_PROPERTY_TYPE} data-color={colors.anchor}>
                Anchors
              </option>
              <option
                value={SUBSTATION_PROPERTY_TYPE}
                data-color={colors.substation}
              >
                Substation
              </option>
            </>
          )}
        </>
      )}
    </Dropdown>
  );
};

export default ChangeTypeDropdown;
