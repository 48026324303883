import {
  atom,
  atomFamily,
  selectorFamily,
  useRecoilCallback,
  useRecoilValue,
} from "recoil";
import * as spec from "api/dataLayerLibrary";
import { z } from "zod";
import { organisationIdSelector } from "state/pathParams";
import { fetchSchemaWithToken, fetchWithToken } from "services/utils";

export const _Error = spec.components.schemas.Error;
export type Error = z.infer<typeof _Error>;
export const _SourceType = spec.components.schemas.SourceType;
export type SourceType = z.infer<typeof _SourceType>;
export const _Source = spec.components.schemas.Source;
export type Source = z.infer<typeof _Source>;
export const _Layer = spec.components.schemas.Layer;
export type Layer = z.infer<typeof _Layer>;

export const _ListSources =
  spec.paths["/api/datalayerlibrary/{organisationId}/sources"].get;
const _ListP = _ListSources.pathParams;
const _ListRet = _ListSources.responses[200]["application/json"];
export const _PostSource =
  spec.paths["/api/datalayerlibrary/{organisationId}/sources"].post;
const _PostBody = _PostSource.requestBody["application/json"];
const _PostP = _PostSource.pathParams;
const _PostRet = _PostSource.responses[201]["application/json"];

export const _DeleteSource =
  spec.paths["/api/datalayerlibrary/{organisationId}/sources/{sourceId}"]
    .delete;
const _DeleteP = _DeleteSource.pathParams;

export const connectExternalModalOpen = atom<boolean>({
  key: "connectExternalModalOpen",
  default: false,
});

export const externalSourcesAtom = atomFamily<
  Source[],
  { organisationId: string }
>({
  key: "externalSourcesAtom",
  default: selectorFamily({
    key: "externalSourcesAtom.default",
    get:
      ({ organisationId }) =>
      () =>
        fetchSchemaWithToken(
          _ListRet,
          `/api/datalayerlibrary/${organisationId}/sources?layers=true`,
          {
            method: "get",
            headers: {
              "Content-Type": "application/json",
            },
          },
        ),
  }),
});

export const useSourceCrud = () => {
  const organisationId = useRecoilValue(organisationIdSelector) ?? "";

  const list = useRecoilCallback(
    ({ set }) =>
      async (p: z.infer<typeof _ListP>) => {
        const sources = await fetchSchemaWithToken(
          _ListRet,
          `/api/datalayerlibrary/${p.organisationId}/sources?layers=true`,
          {
            method: "get",
            headers: {
              "Content-Type": "application/json",
            },
          },
        );
        set(externalSourcesAtom({ organisationId }), sources);
      },
    [organisationId],
  );

  const post = useRecoilCallback(
    ({ set }) =>
      async (body: z.infer<typeof _PostBody>, p: z.infer<typeof _PostP>) => {
        const ret = await fetchSchemaWithToken(
          _PostRet,
          `/api/datalayerlibrary/${p.organisationId}/sources`,
          {
            method: "post",
            body: JSON.stringify(body),
            headers: {
              "Content-Type": "application/json",
            },
          },
        );
        set(externalSourcesAtom({ organisationId }), (c) => c.concat([ret]));
      },
    [organisationId],
  );

  const remove = useRecoilCallback(
    ({ set }) =>
      async (p: z.infer<typeof _DeleteP>) => {
        await fetchWithToken(
          `/api/datalayerlibrary/${p.organisationId}/sources/${p.sourceId}`,
          { method: "delete" },
        );
        set(externalSourcesAtom({ organisationId }), (c) =>
          c.filter((src) => src.id !== p.sourceId),
        );
      },
    [organisationId],
  );

  return { list, post, remove };
};
