import React from "react";
import { useRecoilState } from "recoil";
import { colors } from "styles/colors";
import { typography } from "styles/typography";
import Toggle, { ToggleSize } from "components/General/Toggle";
import { Label } from "components/General/Form";
import { comparisonModeAtom } from "../state";

const ComparisonModeToggle = () => {
  const [comparisonMode, setComparisonMode] =
    useRecoilState(comparisonModeAtom);

  return (
    <Label
      style={{
        flexDirection: "row",
        alignItems: "flex-start",
        cursor: "pointer",
      }}
    >
      <Toggle
        checked={comparisonMode === "percentage"}
        size={ToggleSize.SMALL}
        onChange={(e) => {
          setComparisonMode(e.target.checked ? "percentage" : "absolute");
        }}
        style={{
          marginTop: "0.5rem",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <p style={{ ...typography.body }}>Percentage</p>
        <p style={{ ...typography.caption, color: colors.grey600 }}>
          Compare numbers relatively
        </p>
      </div>
    </Label>
  );
};

export default ComparisonModeToggle;
