import { currentUserOrganisationResourcesSelectorFamily } from "components/Organisation/OrganisationRightSide/content/hooks/useUserOrganisationResourcesCrud";
import { atom, selector, selectorFamily } from "recoil";
import {
  UserNotificationSettings,
  getUserNotificationSettings,
} from "../services/userService";
import { fetchSchemaWithToken } from "../services/utils";
import {
  LoggedInUser,
  OrganisationAccessRole,
  UserAccessRole,
  UserMetaInfo,
  UserNodesAccess,
  _LoggedInUser,
  _OtherUserMetaInfo,
  _UserMetaInfo,
  _UserNodesAccess,
} from "../types/user";
import { parseJWT } from "../utils/jwt";
import { sendWarning } from "../utils/sentry";
import { isInChecklyMode } from "../utils/utils";
import {
  nodeIdSelector,
  organisationIdSelector,
  projectIdSelector,
} from "./pathParams";
import { topLevelFolderIdFromOrgIdAndProjectIdSelectorFamily } from "components/Projects/useOrganisationFolderCrud";

export const LOCAL_STORAGE_TOKEN_KEY = "access_token";

export const ORGANISATION_ACCESS_ROLE_TO_NUMBER: Record<
  OrganisationAccessRole | "null" | "undefined",
  number
> = {
  undefined: -10,
  null: -10,
  guest: 0,
  member: 1,
  admin: 2,
  owner: 3,
};

export const ACCESS_ROLE_TO_NUMBER: Record<
  UserAccessRole | "public" | "null" | "undefined",
  number
> = {
  undefined: -10,
  null: -10,
  public: -1,
  viewer: 0,
  editor: 1,
  admin: 2,
};

export const getTokenAtom = atom<string | undefined>({
  key: "getTokenAtom",
  default: undefined,
});

export const loggedInUserSelector = selector<undefined | LoggedInUser>({
  key: "userDataSelector",
  get: ({ get }) => {
    const token = get(getTokenAtom);
    if (!token) return;
    const parsedToken = parseJWT(token);

    const user: LoggedInUser = {
      nickname:
        parsedToken["https://app.vind.ai/nickname"] ??
        parsedToken["https://app.vind.ai/email"],
      picture: parsedToken["https://app.vind.ai/picture"] ?? undefined,
      hmac: parsedToken["https://app.vind.ai/hmac"],
      user_id: parsedToken["https://app.vind.ai/user_id"],
      email: parsedToken["https://app.vind.ai/email"],
      consents: parsedToken["https://app.vind.ai/consents"],
      interests: parsedToken["https://app.vind.ai/interests"],
      allow_news: parsedToken["https://app.vind.ai/allow_news"],
      accept_terms_of_use:
        parsedToken["https://app.vind.ai/accept_terms_of_use"],
      allow_interest_specific_news:
        parsedToken["https://app.vind.ai/allow_interest_specific_news"],
      internalRole: parsedToken["https://app.vind.ai/internal"] === true,
      isTemporaryToken:
        parsedToken["https://app.vind.ai/temporary_token"] === true,
      logins_count:
        parsedToken["https://app.vind.ai/metadata/logins_count"] ?? 0,
    };

    const res = _LoggedInUser.safeParse(user);
    if (!res.success && !isInChecklyMode()) {
      sendWarning("user object from token doesnt match our type", {
        error: res.error,
      });
    }

    return user;
  },
});

export const getCompanyNameFromEmail = (email?: string) => {
  return email?.split("@")?.[1]?.split(".")[0];
};

export const loggedInUserIsInternalSelector = selector<boolean>({
  key: "loggedInUserIsInternalSelector",
  get: ({ get }) => {
    const user = get(loggedInUserSelector);
    const emailDomain = user?.email?.split("@")?.[1];
    return user?.internalRole || emailDomain === "vind.ai";
  },
});

export const loggedInUserIsCheckly = selector<boolean>({
  key: "loggedInUserIsCheckly",
  get: ({ get }) => {
    const user = get(loggedInUserSelector);
    return user?.user_id === "auth0|639ad688a9d225d29157bdb6";
  },
});

// User node access

export const userAllNodesAccessAtom = atom<UserNodesAccess>({
  key: "userAllNodesAccessAtom",
  default: selector<UserNodesAccess>({
    key: "userAllNodesAccessSelector",
    get: () => {
      return fetchSchemaWithToken(_UserNodesAccess, `/api/user/access`, {
        method: "get",
        headers: {},
      });
    },
  }),
});

// Org access
export const userOrganisationAccessSelectorFamily = selectorFamily<
  number,
  { organisationId: string }
>({
  key: "userOrganisationAccessSelectorFamily",
  get:
    ({ organisationId }) =>
    ({ get }) => {
      const userAccess = get(userAllNodesAccessAtom);

      if (!userAccess) return ORGANISATION_ACCESS_ROLE_TO_NUMBER["null"];

      const orgAccess = userAccess["organisation_access"][organisationId];

      if (!orgAccess) return ORGANISATION_ACCESS_ROLE_TO_NUMBER["null"];

      return ORGANISATION_ACCESS_ROLE_TO_NUMBER[orgAccess];
    },
});

function hasCorrectOrgAccessLevel(
  accessInOrg: number,
  role: OrganisationAccessRole | "null" | "undefined",
) {
  return accessInOrg >= ORGANISATION_ACCESS_ROLE_TO_NUMBER[role];
}

export const ownerInOrganisationSelectorFamily = selectorFamily<
  boolean,
  { organisationId: string | undefined }
>({
  key: "ownerInOrganisationSelectorFamily",
  get:
    ({ organisationId }) =>
    ({ get }) => {
      if (!organisationId) return false;
      const accessInOrg = get(
        userOrganisationAccessSelectorFamily({ organisationId }),
      );
      return hasCorrectOrgAccessLevel(accessInOrg, "owner");
    },
});
export const adminInOrganisationSelectorFamily = selectorFamily<
  boolean,
  { organisationId: string | undefined }
>({
  key: "adminInOrganisationSelectorFamily",
  get:
    ({ organisationId }) =>
    ({ get }) => {
      if (!organisationId) return false;
      const accessInOrg = get(
        userOrganisationAccessSelectorFamily({ organisationId }),
      );
      return hasCorrectOrgAccessLevel(accessInOrg, "admin");
    },
});
export const memberInOrganisationSelectorFamily = selectorFamily<
  boolean,
  { organisationId: string | undefined }
>({
  key: "memberInOrganisationSelectorFamily",
  get:
    ({ organisationId }) =>
    ({ get }) => {
      if (!organisationId) return false;
      const accessInOrg = get(
        userOrganisationAccessSelectorFamily({ organisationId }),
      );
      return hasCorrectOrgAccessLevel(accessInOrg, "member");
    },
});

// Customer access

export const editorAccessCustomerSelector = selector<boolean>({
  key: "editorAccessCustomerSelector",
  get: ({ get }) => {
    const toplevelNodeIdOrgPage = get(nodeIdSelector);
    const toplevelNodeIdProjectPage = get(
      topLevelFolderIdFromOrgIdAndProjectIdSelectorFamily({
        organisationId: get(organisationIdSelector),
        projectId: get(projectIdSelector),
      }),
    );
    const toplevelNodeId = toplevelNodeIdOrgPage ?? toplevelNodeIdProjectPage;
    if (!toplevelNodeId) return false;
    return get(userNodeAccessSelectorFamily({ nodeId: toplevelNodeId })) >= 1;
  },
});

const userHaveNodeAccess = (userAccess: UserNodesAccess, nodeId?: string) => {
  if (!nodeId) return ACCESS_ROLE_TO_NUMBER["undefined"];
  if (!userAccess) return ACCESS_ROLE_TO_NUMBER["public"];
  const customerAccess = userAccess["node_access"][nodeId];
  if (!customerAccess) return ACCESS_ROLE_TO_NUMBER["public"];

  return ACCESS_ROLE_TO_NUMBER[customerAccess];
};

export const userHaveEditorNodeAccess = (
  userAccess: UserNodesAccess,
  nodeId?: string,
) => userHaveNodeAccess(userAccess, nodeId) >= ACCESS_ROLE_TO_NUMBER["editor"];

export const userHaveAdminNodeAccess = (
  userAccess: UserNodesAccess,
  nodeId?: string,
) => userHaveNodeAccess(userAccess, nodeId) >= ACCESS_ROLE_TO_NUMBER["admin"];

export const userNodeAccessSelectorFamily = selectorFamily<
  number,
  { nodeId: string | undefined }
>({
  key: "userCustomerAccessSelectorFamily",
  get:
    ({ nodeId }) =>
    ({ get }) => {
      const access = get(userAllNodesAccessAtom);
      return userHaveNodeAccess(access, nodeId);
    },
});

export const userNodeAccessNameSelectorFamily = selectorFamily<
  string | undefined,
  { nodeId: string | undefined }
>({
  key: "userNodeAccessNameSelectorFamily",
  get:
    ({ nodeId }) =>
    ({ get }) => {
      if (!nodeId) {
        return;
      }
      const userAccess = get(userAllNodesAccessAtom);

      if (!userAccess) {
        return;
      }

      const customerAccess = userAccess["node_access"][nodeId];
      if (!customerAccess) {
        return;
      }
      return customerAccess;
    },
});

// Project access
export const adminAccessProjectSelector = selector<boolean>({
  key: "adminAccessProjectSelector",
  get: ({ get }) => {
    const projectId = get(projectIdSelector);

    if (!projectId) return false;

    return (
      get(userNodeAccessSelectorFamily({ nodeId: projectId })) >=
      ACCESS_ROLE_TO_NUMBER["admin"]
    );
  },
});
export const editorAccessProjectSelector = selector<boolean>({
  key: "editorAccessProjectSelector",
  get: ({ get }) => {
    const projectId = get(projectIdSelector);

    if (!projectId) return false;
    return (
      get(userNodeAccessSelectorFamily({ nodeId: projectId })) >=
      ACCESS_ROLE_TO_NUMBER["editor"]
    );
  },
});
export const viewerAccessProjectSelector = selector<boolean>({
  key: "viewerAccessProjectSelector",
  get: ({ get }) => {
    const projectId = get(projectIdSelector);

    if (!projectId) return false;
    return (
      get(userNodeAccessSelectorFamily({ nodeId: projectId })) >=
      ACCESS_ROLE_TO_NUMBER["viewer"]
    );
  },
});

export const loggedInUserMetaInfoAtom = atom({
  key: "loggedInUserMetaInfoAtom",
  default: selector<UserMetaInfo>({
    key: "loggedInUserMetaInfoAtom.default",
    get: () => {
      return fetchSchemaWithToken(_UserMetaInfo, `/api/user/metadata`, {
        method: "get",
        headers: {},
      });
    },
  }),
});

// Org resource access for current user
export const orgTurbineManageAccessSelector = selector<boolean>({
  key: "orgTurbineManageAccessSelector",
  get: ({ get }) => {
    const organisationId = get(organisationIdSelector);

    if (!organisationId) return false;

    const resources = get(
      currentUserOrganisationResourcesSelectorFamily({
        organisationId,
      }),
    );

    return resources.some((r) => r["resource_name"] === "org_turbine_manage");
  },
});

export const orgFoundationManageAccessSelector = selector<boolean>({
  key: "orgFoundationManageAccessSelector",
  get: ({ get }) => {
    const organisationId = get(organisationIdSelector);

    if (!organisationId) return false;

    const resources = get(
      currentUserOrganisationResourcesSelectorFamily({
        organisationId,
      }),
    );
    return resources.some(
      (r) => r["resource_name"] === "org_foundation_manage",
    );
  },
});

export const orgAnalysisManageAccessSelector = selector<boolean>({
  key: "orgAnalysisManageAccessSelector",
  get: async ({ get }) => {
    const organisationId = get(organisationIdSelector);

    if (!organisationId) return false;

    const resources = get(
      currentUserOrganisationResourcesSelectorFamily({
        organisationId,
      }),
    );
    return resources.some((r) => r["resource_name"] === "org_analysis_manage");
  },
});

export const orgCableManageAccessSelector = selector<boolean>({
  key: "orgCableManageAccessSelector",
  get: async ({ get }) => {
    const organisationId = get(organisationIdSelector);

    if (!organisationId) return false;

    const resources = get(
      currentUserOrganisationResourcesSelectorFamily({
        organisationId,
      }),
    );
    return resources.some((r) => r["resource_name"] === "org_cable_manage");
  },
});

export const orgFinanicalManageAccessSelector = selector<boolean>({
  key: "orgFinanicalManageAccessSelector",
  get: async ({ get }) => {
    const organisationId = get(organisationIdSelector);

    if (!organisationId) return false;

    const resources = get(
      currentUserOrganisationResourcesSelectorFamily({
        organisationId,
      }),
    );
    return resources.some((r) => r["resource_name"] === "org_financial_manage");
  },
});

export const orgDataPackagesManageAccessSelector = selector<boolean>({
  key: "orgDataLayersManageAccessSelector",
  get: async ({ get }) => {
    const organisationId = get(organisationIdSelector);

    if (!organisationId) return false;

    const resources = get(
      currentUserOrganisationResourcesSelectorFamily({
        organisationId,
      }),
    );
    return resources.some(
      (r) => r["resource_name"] === "org_data_package_manage",
    );
  },
});

// True used as default value if a setting is not set yet
export const NotificationSettingsAtom = atom<
  UserNotificationSettings | undefined
>({
  key: "NotificationSettingsAtom",
  default: selector({
    key: "NotificationSettingsSelector",
    get: async () => {
      const settings = await getUserNotificationSettings();
      return {
        ...settings,
        email_on_mention: settings.email_on_mention ?? true,
        email_on_comment_in_followed_thread:
          settings.email_on_comment_in_followed_thread ?? true,
        email_on_snapshot_created: settings.email_on_snapshot_created ?? true,
        email_on_branch_created: settings.email_on_branch_created ?? true,
        email_on_project_created: settings.email_on_project_created ?? true,
      };
    },
  }),
});
