import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { useTypedPath } from "../../../state/pathParams";
import ReplyWrapper from "./Reply";
import { threadRepliesAtomFamily } from "../state";
import { Thread } from "../types";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding-left: 2.4rem;
`;

export default function Replies({
  thread,
  nodeId,
}: {
  thread: Thread;
  nodeId: string;
}) {
  const { branchId } = useTypedPath("branchId");
  const replies = useRecoilValue(
    threadRepliesAtomFamily({
      nodeId,
      branchId,
      threadId: thread.threadId,
    }),
  );

  return (
    <Container>
      {replies.map((r) => (
        <ReplyWrapper key={r.replyId} reply={r} />
      ))}
    </Container>
  );
}
