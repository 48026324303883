import { useEffect } from "react";
import { selectorFamily, useRecoilValue } from "recoil";
import { getParkFeatureSelectorFamily } from "../../state/park";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { WarningProps } from "./ValidationWarnings";
import { ParkFeature } from "../../types/feature";
import { branchIdSelector } from "../../state/pathParams";
import { getTurbinesInBranchSelectorFamily } from "../../state/layout";
import { mapRefAtom } from "../../state/map";
import { cableChainsInBranchSelectorFamily } from "../../state/cableEdit";
import { getCablesInBranchSelectorFamily } from "../../state/cable";
import { turbinesEditableSourceId } from "../../constants/draw";

export const turbinesNotConnectedSelectorFamily = selectorFamily<
  | {
      type: ValidationWarningTypes.TurbineNotConnected;
      featureIds: string[];
      parkId: string;
    }
  | undefined,
  { parkId: string; branchId: string }
>({
  key: "turbinesNotConnectedSelectorFamily",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const turbines = get(
        getTurbinesInBranchSelectorFamily({
          parkId: parkId,
          branchId: branchId,
        }),
      );
      const cables = get(
        getCablesInBranchSelectorFamily({ parkId: parkId, branchId: branchId }),
      );
      const cableChains = get(
        cableChainsInBranchSelectorFamily({
          parkId: parkId,
          branchId: branchId,
        }),
      );
      const turbinesInChains = new Set(
        cableChains.flatMap((cc) => cc.turbines),
      );

      const turbinesNotConnected =
        cables.length === 0
          ? []
          : turbines.filter((t) => !turbinesInChains.has(t.id));

      return turbinesNotConnected.length === 0
        ? undefined
        : {
            type: ValidationWarningTypes.TurbineNotConnected,
            featureIds: turbinesNotConnected.map((t) => t.id),
            parkId: parkId,
          };
    },
});

const TurbineNotConnectedInner = ({
  park,
  warn,
  remove,
}: { park: ParkFeature } & WarningProps) => {
  const map = useRecoilValue(mapRefAtom);
  const branchId = useRecoilValue(branchIdSelector);
  const turbinesNotConnectedWarning = useRecoilValue(
    turbinesNotConnectedSelectorFamily({
      parkId: park.id,
      branchId: branchId ?? "",
    }),
  );

  // update anchor styling in map
  useEffect(() => {
    if (map && turbinesNotConnectedWarning) {
      const source = map.getSource(turbinesEditableSourceId);

      turbinesNotConnectedWarning.featureIds.forEach((id) => {
        source &&
          map.setFeatureState(
            { source: turbinesEditableSourceId, id: id },
            { overlap: true },
          );
      });

      return () => {
        const sourceOnUnmount = map.getSource(turbinesEditableSourceId);
        turbinesNotConnectedWarning.featureIds.forEach((id) => {
          sourceOnUnmount &&
            map.removeFeatureState(
              { source: turbinesEditableSourceId, id: id },
              "overlap",
            );
        });
      };
    }
  }, [map, turbinesNotConnectedWarning]);

  // Update validation warning
  useEffect(() => {
    if (!turbinesNotConnectedWarning) return;
    warn(turbinesNotConnectedWarning);
    return () => {
      remove(ValidationWarningTypes.TurbineNotConnected);
    };
  }, [park, remove, turbinesNotConnectedWarning, warn]);

  return null;
};

const TurbineNotConnected = ({
  parkId,
  ...props
}: { parkId: string } & WarningProps) => {
  const park = useRecoilValue(getParkFeatureSelectorFamily({ parkId }));

  if (!park) return null;
  return <TurbineNotConnectedInner park={park} {...props} />;
};

export default TurbineNotConnected;
