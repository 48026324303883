import styled from "styled-components";
import { colors } from "../../../styles/colors";
import {
  borderRadiusLarge,
  spaceMedium,
  borderRadiusMedium,
  spaceTiny,
} from "../../../styles/space";
import { SkeletonText, orLoader } from "components/Loading/Skeleton";
import {
  typography,
  TextSemi,
  Text,
  UiErrorText,
} from "../../../styles/typography";
import { Loadable } from "recoil";

export const CardContainer = styled.div<{ preset?: boolean }>`
  border: 1px solid ${colors.inputOutline};
  border-radius: ${borderRadiusLarge};
  overflow: clip;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  user-select: text;

  .dnd-hand {
    padding-top: 0.4rem; // Manual alignment here, lol.
    margin-right: -${spaceMedium};
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
  }
  &:hover {
    .dnd-hand {
      ${(p) => !p.preset && `opacity: 1;`}
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spaceMedium};

  margin: 0;
  padding: ${spaceMedium} ${spaceMedium} ${spaceMedium} ${spaceTiny};
  border-bottom: 1px solid ${colors.inputOutline};
`;

export const DotMenuWrapper = styled.div`
  &:hover {
    background: ${colors.hover};
    border-radius: ${borderRadiusMedium};
  }
`;

export const CardDiv = styled(CardContainer)`
  hr {
    height: 1px;
    border: none;
    border-bottom: 1px solid ${colors.inputOutline};
  }

  .grid2 > {
    :nth-child(2n + 1) {
      font-weight: 700;
    }
  }
  .flex-grid2 > div > {
    :nth-child(2n + 1) {
      font-weight: 700;
    }
  }
`;

export const Scroll = styled.div`
  overflow: auto;
  min-height: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
`;
export const HoverCardCloseWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  svg {
    margin: ${spaceMedium};
    padding: ${spaceMedium};
    width: 1.4rem;
    height: 1.4rem;
    border-radius: ${borderRadiusLarge};
    z-index: 3; // Above 2, which is mapbox stuff
    position: relative;
    right: 0;

    opacity: 0.6;
    transition: opacity 0.1s ease-in-out;
    background: ${colors.focusBackground};
    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
`;

/**
 * Move the Mapbox logo to the lower left corner of the map. This is a little
 * ugly, because we're really moving a whole "top right" control to the lower
 * left. Let's hope nothing else is supposed to be placed in this div.
 */
export const MapboxMoveLogoToUpperLeft = styled.div`
  width: 100%;
  height: 100%;
  .mapboxgl-control-container {
    .mapboxgl-ctrl-top-right {
      right: initial;
      left: 0;
      .mapboxgl-ctrl {
        margin: 10px;
        .mapboxgl-ctrl-logo {
        }
      }
    }
  }
`;

// Components for widgets displaying rows of statistics
export const Stats = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaceMedium};
  margin: 0.75rem 1.5rem;
`;

export const StatWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
`;

export const ValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const H4 = styled.p`
  ${typography.h4}
`;

export const Stat = ({
  title,
  value = undefined,
  bold = true,
}: {
  title: string;
  value?: string | number;
  bold?: boolean;
}) => {
  return (
    <StatWrapper>
      {bold ? <TextSemi>{title}:</TextSemi> : <Text>{title}</Text>}

      <ValueWrapper>
        {value !== undefined ? (
          <p>{value}</p>
        ) : (
          <SkeletonText style={{ width: "7rem" }} />
        )}
      </ValueWrapper>
    </StatWrapper>
  );
};

export const Stat2 = ({
  title,
  value,
  bold = true,
  format = (v: number) => v,
  unit = "",
}: {
  title: string;
  value: Loadable<string | number | undefined>;
  bold?: boolean;
  format?: Function;
  unit?: string;
}) => {
  let output;

  if (value.state === "hasError") {
    output = (
      <UiErrorText>
        {value.errorMaybe()?.message ?? "An error occurred."}
      </UiErrorText>
    );
  } else {
    output = orLoader(value, (v) => (
      <p>
        {format(v)}
        {unit !== "" && ` ${unit}`}
      </p>
    ));
  }

  return (
    <StatWrapper>
      {bold ? <TextSemi>{title}:</TextSemi> : <Text>{title}</Text>}

      <ValueWrapper>{output}</ValueWrapper>
    </StatWrapper>
  );
};

export const StatHeaderLoadable = ({
  title,
  value,
  unit = "",
}: {
  title: string;
  value: Loadable<string | number | undefined>;
  unit?: string;
}) => {
  let output;

  if (value.state === "hasError") {
    output = (
      <UiErrorText>
        {value.errorMaybe()?.message ?? "An error occurred."}
      </UiErrorText>
    );
  } else {
    output = orLoader(value, (v) => (
      <H4>
        {v}
        {unit !== "" && ` ${unit}`}
      </H4>
    ));
  }

  return (
    <StatWrapper>
      <H4>{title}</H4>
      <ValueWrapper>{output}</ValueWrapper>
    </StatWrapper>
  );
};

export const StatHeader = ({
  title,
  value = undefined,
}: {
  title: string;
  value?: string | number;
}) => {
  return (
    <StatWrapper>
      <H4>{title}</H4>

      <ValueWrapper>
        {value !== undefined ? (
          <H4>{value}</H4>
        ) : (
          <SkeletonText style={{ width: "7rem" }} />
        )}
      </ValueWrapper>
    </StatWrapper>
  );
};
