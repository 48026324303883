import { parseJWT } from "../utils/jwt";

export let accessToken: string | undefined;

export const setAccessTokenGlobal = (newToken: string) => {
  accessToken = newToken;
};

export const getAccessTokenGlobal = () => {
  return accessToken;
};

export function getCurrentTokenUserId() {
  if (!accessToken) return undefined;
  const parsedToken = parseJWT(accessToken);
  return parsedToken["https://app.vind.ai/user_id"];
}
