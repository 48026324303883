import { RecoilSync } from "recoil-sync";
import type { PropsWithChildren } from "react";

export type Storage = "local-storage" | "session-storage";

export const RecoilSyncStorage = ({ children }: PropsWithChildren) => {
  return (
    <RecoilSync
      storeKey={"local-storage"}
      read={(item) => {
        return localStorage.getItem(item);
      }}
      write={({ diff }) => {
        for (const [key, str] of diff) {
          localStorage.setItem(key, str as string);
        }
      }}
    >
      <RecoilSync
        storeKey={"session-storage"}
        read={(item) => {
          return sessionStorage.getItem(item);
        }}
        write={({ diff }) => {
          for (const [key, str] of diff) {
            sessionStorage.setItem(key, str as string);
          }
        }}
      >
        {children}
      </RecoilSync>
    </RecoilSync>
  );
};
