import { atom, selectorFamily, atomFamily } from "recoil";
import {
  PROJECT_SERVICE_API_PATH,
  PROJECT_SERVICE_API_VERSION,
} from "../components/ProjectElements/service";
import {
  Configuration,
  _Configuration,
  listConfigurations,
  _ConfigurationUsageType,
  ConfigurationUsageType,
} from "../services/configurationService";
import { fetchEnhancerWithToken } from "../services/utils";
import { getBranchSelectorFamily } from "./timeline";
import { analysisResourceWithAccessOnNodeState } from "components/Organisation/Library/state";

export const ProjectConfigModalTypeV2 = "ProjectConfigModal";
export const FeatureSettingsModalTypeV2 = "FeatureSettingsModal";
export const PublicModeModalTypeV2 = "PublicModeModal";
export const ConfigurationModalType = "ConfigurationModal";
export const TeamConfigurationModalType = "TeamConfigurationModal";
export const ProjectOverviewModalType = "ProjectOverviewModal";

export enum MenuItems {
  Analysis = "Analysis",
  WindData = "Wind data",
  Turbines = "Turbines",
  Cabling = "Cabling",
  Foundation = "Foundations",
  Mooring = "Mooring",
  Other = "Maintenance",
  Notifications = "Notifications",
  TeamMembers = "Team",
  ProjectMembers = "Project",
  PublicMode = "Public mode",
  Warnings = "Warnings",
  Integrations = "Integrations",
}

export const ProjectLevelMenuItems = [
  MenuItems.Analysis,
  MenuItems.WindData,
  MenuItems.Cabling,
  MenuItems.Mooring,
  MenuItems.Warnings,
];

export const TeamLevelMenuItems = [
  MenuItems.Turbines,
  MenuItems.Foundation,
  MenuItems.Integrations,
];
export const ProfileLevelMenuItems = [MenuItems.Notifications];
export const UsersLevelMenuItems = [
  MenuItems.TeamMembers,
  MenuItems.ProjectMembers,
  MenuItems.PublicMode,
];

export const activeConfigurationMenuAtom = atom<MenuItems | undefined>({
  key: "activeConfigurationMenuAtom",
  default: undefined,
});

export const savingConfigurationInProgressAtom = atom<boolean>({
  key: "savingConfigurationInProgressAtom",
  default: false,
});

export const DEFAULT_CONFIG_ID = "99a066d4-32a9-11ee-be56-0242ac120002";

export const configurationsAtomFamily = atomFamily<
  Configuration[],
  { nodeId: string | undefined }
>({
  key: "projectConfigurationsAtomFamily",
  default: selectorFamily<Configuration[], { nodeId: string | undefined }>({
    key: "projectConfigurationsSelectorFamily",
    get:
      ({ nodeId }) =>
      async () => {
        if (!nodeId) return [];
        const response = await listConfigurations(nodeId);
        if (!response.ok) {
          throw new Error("Fetching configurations failed");
        }
        const j = await response.json();
        const configs = _Configuration.array().parse(j);

        return configs;
      },
  }),
});

export const configurationAtomFamily = selectorFamily<
  Configuration | undefined,
  { nodeId: string | undefined; analysisConfigurationId: string }
>({
  key: "configurationAtomFamily",
  get:
    ({ nodeId, analysisConfigurationId }) =>
    ({ get }) => {
      const configs = get(configurationsAtomFamily({ nodeId }));
      return configs.find((config) => config.id === analysisConfigurationId);
    },
});

export const libraryAndProjectConfigurationsSelectorFamily = selectorFamily<
  Configuration[],
  { nodeId: string | undefined }
>({
  key: "libraryAndProjectConfigurationsSelectorFamily",
  get:
    ({ nodeId }) =>
    ({ get }) => [
      ...get(configurationsAtomFamily({ nodeId })),
      ...get(analysisResourceWithAccessOnNodeState({ nodeId })).map(
        (c) => c.config,
      ),
    ],
});

export const branchSelectedConfigurationAtomFamily = atomFamily<
  Configuration | undefined,
  {
    projectId: string | undefined;
    branchId: string | undefined;
  }
>({
  key: "branchSelectedConfigurationAtomFamily",
  default: selectorFamily<
    Configuration | undefined,
    {
      projectId: string | undefined;
      branchId: string | undefined;
    }
  >({
    key: "branchSelectedConfigurationSelectorFamily",
    get:
      ({ projectId, branchId }) =>
      ({ get }) => {
        if (!projectId || !branchId) return undefined;
        const configs = get(
          libraryAndProjectConfigurationsSelectorFamily({ nodeId: projectId }),
        );
        const branchMeta = get(
          getBranchSelectorFamily({
            projectId,
            branchId,
          }),
        );

        const selectedConfigId = branchMeta?.analysisConfigurationId;
        if (!selectedConfigId) return undefined;

        return configs.find((c) => c.id === selectedConfigId);
      },
  }),
});

// This is used to store the config name while still editing the config
// to avoid rendering the component and loose the values that is stored in LocalConfig
export const configurationTempName = atomFamily<
  { [key: string]: string | undefined },
  { nodeId: string | undefined }
>({
  key: "configurationTempName",
  default: () => {
    return {};
  },
});

export const analysisConfigurationUsageRefresh = atomFamily<
  number,
  { nodeId: string; analysisConfigurationId: string }
>({
  key: "analysisConfigurationUsageRefresh",
  default: () => {
    return 0;
  },
});

export async function fetchAnalysisConfigurationUsage(
  nodeId: string,
  analysisConfigurationId: string,
) {
  const headers = {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "x-project-data-client-version": PROJECT_SERVICE_API_VERSION,
    },
  };
  const res = await fetchEnhancerWithToken(
    `${PROJECT_SERVICE_API_PATH}/stats/node/${nodeId}/analysis-configuration/${analysisConfigurationId}`,
    headers,
  );
  const j = await res.json();
  return _ConfigurationUsageType.array().parse(j);
}

export const analysisConfigurationUsageAtomFamily = atomFamily<
  ConfigurationUsageType[],
  { nodeId: string; analysisConfigurationId: string }
>({
  key: "analysisConfigurationUsageAtomFamily",
  default: selectorFamily<
    ConfigurationUsageType[],
    { nodeId: string; analysisConfigurationId: string }
  >({
    key: "analysisConfigurationUsageSelectorFamily",
    get:
      ({ nodeId, analysisConfigurationId }) =>
      async ({ get }) => {
        if (!nodeId) return [];
        get(
          analysisConfigurationUsageRefresh({
            nodeId,
            analysisConfigurationId,
          }),
        );
        const usage = await fetchAnalysisConfigurationUsage(
          nodeId,
          analysisConfigurationId,
        );
        return usage;
      },
  }),
});
