/// <reference types="vite-plugin-svgr/client" />
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { TextRaw, typography } from "../../styles/typography";
import {
  ReactElement,
  createContext,
  useContext,
  useRef,
  useState,
  useEffect,
} from "react";
import React from "react";
import ArrowRight from "@icons/24/ArrowRight.svg?react";
import { useClickOutside } from "../../hooks/useClickOutside";
import { Anchor } from "./Anchor";
import { SetterOrUpdater } from "recoil";
import { spacing6, spaceTiny } from "styles/space";

/** The number of MS that the cursor can be outside a menu before we close it. */
const EXIT_TIMEOUT_MS = 250;

const MenuContext = createContext<{
  setMenuOpen: (open: boolean) => void;
}>({ setMenuOpen: () => {} });

const SubMenuContext = createContext<{
  openItem: HTMLDivElement | null;
  setOpenItem: SetterOrUpdater<HTMLDivElement | null>;
}>({ openItem: null, setOpenItem: () => {} });

export const MenuItemDiv = styled.div<{
  nohover?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  padding: ${spaceTiny} ${spacing6};
  min-height: 2.5rem;
  min-width: 10rem;

  > :first-child {
    margin-inline-end: auto;
  }

  // border-bottom: 1px solid #ddd;
  :last-child {
    border-bottom: none;
  }

  color: ${({ disabled }) =>
    disabled ? colors.textDisabled : colors.textPrimary};

  background: ${colors.backgroundDefault};

  ${({ nohover }) =>
    nohover ? "" : `&:hover { background: ${colors.hover}; cursor: pointer; }`}

  :first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  :last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }
`;

const SmallIconWrap = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  min-width: 1.5rem;
  svg {
    overflow: visible;
    height: 1.5rem;
    width: 1.5rem;
    path {
      ${(p) => p.disabled && `stroke: ${colors.grey200}`}
    }
  }
`;

const ShortcutWrap = styled.p<{ disabled?: boolean }>`
  ${TextRaw};
  margin: 0;
  color: ${colors.secondaryText};
`;

const ItemLabel = styled.p<{ disabled?: boolean }>`
  ${TextRaw}
  flex: 1;
  margin: 0;
  color: inherit;
`;

const SubmenuDiv = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 30%);
  max-height: 60vh;
  overflow-y: auto;
`;

const DefaultText = styled.span`
  ${typography.sub3}
  color: ${colors.textSecondary};
`;

export const MenuDivider = styled.div`
  border-bottom: 1px solid ${colors.inputOutline};
`;

const Submenu = ({
  children,
  style,
  divRef,
  registerIsOpen,
}: React.HTMLAttributes<HTMLDivElement> & {
  style?: React.CSSProperties;
  divRef?: React.RefObject<HTMLDivElement>;
  registerIsOpen?: (open: boolean) => void;
}) => {
  const [openItem, setOpenItem] = useState<HTMLDivElement | null>(null);
  useEffect(() => {
    registerIsOpen?.(openItem !== null);
  }, [openItem, registerIsOpen]);

  return (
    <SubmenuDiv ref={divRef} style={style}>
      <SubMenuContext.Provider
        value={{
          openItem,
          setOpenItem,
        }}
      >
        {children}
      </SubMenuContext.Provider>
    </SubmenuDiv>
  );
};

/** A menu entry.  These can be nested. */
export const MenuItem = ({
  name,
  icon,
  children,
  onClick,
  direction = "right",
  shortcut,
  disabled,
  onMouseEnter,
  onMouseLeave,
  stopPropagationOnClick = true,
  title,
  style,
  id,
  nointeract,
  description,
  defaultButton,
}: {
  name: string;
  icon?: ReactElement;
  children?: React.ReactNode;
  /** Callback for clicking on the menu item.
   * This will automaticall close the menu after the callback is ran.
   * If you don't want this, run `event.stopPropagate()` on the argument event.
   */
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  /** The direction to which children menu items will be. */
  direction?: "left" | "right";
  shortcut?: React.ReactNode;
  disabled?: boolean;
  stopPropagationOnClick?: boolean;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
  title?: string;
  style?: React.CSSProperties;
  id?: string;
  nointeract?: boolean;
  description?: string;
  defaultButton?: boolean;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const hasChildren = 0 < React.Children.toArray(children).length;
  const { setMenuOpen } = useContext(MenuContext);
  const { openItem, setOpenItem } = useContext(SubMenuContext);
  const open = openItem && openItem === ref.current;

  let hoverTimeout = useRef<NodeJS.Timeout | string | number | undefined>();

  return (
    <MenuItemDiv
      data-type="menu-item"
      id={id}
      disabled={disabled}
      aria-disabled={disabled}
      nohover={disabled || nointeract}
      ref={ref}
      onMouseEnter={(e) => {
        if (disabled) return e.stopPropagation();
        onMouseEnter?.();
        if (!ref.current) return;
        if (hasChildren) {
          setOpenItem(ref.current);
          clearTimeout(hoverTimeout.current);
        }
      }}
      onMouseLeave={() => {
        if (disabled) {
          hoverTimeout.current = setTimeout(() => {
            setOpenItem((c) => (ref.current === c ? null : c));
          }, EXIT_TIMEOUT_MS);
          return;
        }
        onMouseLeave?.();
        if (!ref.current) return;
        if (hasChildren) {
          hoverTimeout.current = setTimeout(() => {
            setOpenItem((c) => (ref.current === c ? null : c));
          }, EXIT_TIMEOUT_MS);
        }
      }}
      onClick={(e) => {
        e.preventDefault();
        if (stopPropagationOnClick) {
          e.stopPropagation();
        }
        if (disabled) return;
        if (!ref.current) return;
        if (hasChildren) {
          setOpenItem((c) => (ref.current === c ? null : ref.current));
        } else {
          setOpenItem(null);
        }
        if (onClick) {
          onClick(e);
          setMenuOpen(false);
          return;
        }
      }}
      title={title}
      style={style}
    >
      <div
        style={{
          display: "grid",
          rowGap: "0.5rem",
          columnGap: icon ? "1rem" : 0,
        }}
      >
        {icon && (
          <SmallIconWrap
            style={{ gridRow: 1, gridColumn: 1 }}
            disabled={disabled}
          >
            {icon}
          </SmallIconWrap>
        )}
        {(description && (
          <>
            <ItemLabel
              style={{ gridRow: 1, gridColumn: icon ? 2 : 1 }}
              disabled={disabled}
            >
              <span style={typography.sub2}>
                {name} <DefaultText>{defaultButton && "(DEFAULT)"}</DefaultText>
              </span>
            </ItemLabel>
            <p style={{ gridRow: 2, gridColumn: icon ? 2 : 1 }}>
              {description}
            </p>
          </>
        )) || (
          <ItemLabel style={{ gridRow: 1, gridColumn: 2 }} disabled={disabled}>
            {name} <DefaultText>{defaultButton && "(DEFAULT)"}</DefaultText>
          </ItemLabel>
        )}
      </div>
      {shortcut && <ShortcutWrap disabled={disabled}>{shortcut}</ShortcutWrap>}
      {hasChildren && (
        <SmallIconWrap disabled={disabled}>
          <ArrowRight />
        </SmallIconWrap>
      )}
      {open && (
        <Anchor
          baseRef={ref}
          basePlace={direction === "left" ? "topLeft" : "topRight"}
          floatPlace={direction === "left" ? "topRight" : "topLeft"}
        >
          <Submenu>{children}</Submenu>
        </Anchor>
      )}
    </MenuItemDiv>
  );
};

/** Placement component for the menu. The menu will be anchored to where this element is placed. */
export const Menu = ({
  children,
  childRef,
  parentRef,
  onClick,
  setOpen,
  style,
}: {
  children: React.ReactNode;
  childRef?: React.RefObject<HTMLDivElement>;
  parentRef?: React.RefObject<HTMLDivElement>;
  onClick?(e: React.MouseEvent): void;
  setOpen?: (open: boolean) => void;
  style?: React.CSSProperties;
} & React.HTMLAttributes<HTMLDivElement>) => {
  // NOTE: Since we're using portals for the submenus it's hard to detect clicks outside the menu.
  // Here we just register if any of the submenus are open, and if not, we close the menu.
  // This makes sense because the submenus will close when hovered outside, so a click
  // with a submenu open means that the user clicked inside the menu.
  const [subIsOpen, setSubIsOpen] = useState(false);

  useClickOutside(
    childRef ?? undefined,
    () => {
      if (!subIsOpen) setOpen?.(false);
    },
    (elem) => {
      return elem === parentRef?.current;
    },
    { runCheckOnMouseDown: true },
  );

  return (
    <MenuContext.Provider
      value={{
        setMenuOpen: setOpen ?? (() => {}),
      }}
    >
      <Submenu
        divRef={childRef}
        style={style}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          return onClick?.(e);
        }}
        registerIsOpen={(open) => setSubIsOpen(open)}
      >
        {children}
      </Submenu>
    </MenuContext.Provider>
  );
};
