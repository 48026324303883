import React, { useEffect, useState } from "react";
import { IconREMSize, typography } from "styles/typography";
import ArrowDown from "@icons/24/ArrowDown.svg?react";
import ArrowRight from "@icons/24/ArrowRight.svg?react";
import ValidationError from "@icons/24/ValidationError.svg?react";
import ValidationWarning from "@icons/24/ValidationWarning.svg?react";
import ValidationInfo from "@icons/24/ValidationInfo.svg?react";
import ValidationSuccess from "@icons/24/ValidationSuccess.svg?react";
import styled from "styled-components";
import { colors } from "styles/colors";

const Wrapper = styled.div<{
  type: ValidationAlertType;
  isExpanded: boolean;
  disableColorOnCollapsed?: boolean;
}>`
  display: flex;
  padding: 0;
  margin: 0;
  align-items: flex-start;
  flex-direction: column;
  border-radius: var(--Radius, 0.4rem);

  background: ${({ type, disableColorOnCollapsed, isExpanded }) =>
    disableColorOnCollapsed && !isExpanded
      ? colors.grey50
      : validationSurface[type]};
  };

  &:hover {
    background: ${({ type, disableColorOnCollapsed, isExpanded }) =>
      disableColorOnCollapsed && !isExpanded
        ? colors.grey50
        : validationSurface[type]};
  }
`;

const HeaderRow = styled.div<{ isExpanded: boolean }>`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 0.8rem 0.8rem ${(p) => (p.isExpanded ? "0.4rem" : "0.8rem")} 0.8rem;
  cursor: pointer;
  box-sizing: border-box;
`;

const ClickableHeader = styled.div`
  ${typography.sub2}
  color: ${colors.textPrimary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
  cursor: pointer;
  gap: 1rem;
  width: 100%;
  svg {
    stroke: ${colors.iconDefault};
    width: 1rem;
    height: 1rem;
    overflow: visible;
    margin-right: 0.7rem;
  }
`;

const Details = styled.div`
  ${typography.caption}
  color: ${colors.textPrimary};
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding: 0 0.8rem 0.8rem 4.2rem;
`;

const IconContainer = styled.div<{ type: ValidationAlertType }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  width: 2.4rem;
  height: 2.4rem;
  cursor: auto;
  border-radius: 50%;
  background-color: ${({ type }) => validationBackground[type]};
`;

export type ValidationAlertType = "error" | "warning" | "info" | "success";

export const validationIcon = {
  error: <ValidationError />,
  warning: <ValidationWarning />,
  info: <ValidationInfo />,
  success: <ValidationSuccess />,
};

export const validationBackground = {
  error: colors.red100,
  warning: colors.orange100,
  info: colors.blue100,
  success: colors.green100,
};

export const validationSurface = {
  error: colors.surfaceError,
  warning: colors.surfaceWarning,
  info: colors.surfaceInfo,
  success: colors.surfaceSuccess,
};

export const ValidationAlert = ({
  title,
  description = "",
  renderDecription,
  type,
  actionButton,
  onHeaderClick,
  _isExpanded,
  disableColorOnCollapsed,
}: {
  title: string;
  description?: string;
  type: ValidationAlertType;
  renderDecription?: () => JSX.Element;
  actionButton?: JSX.Element;
  onHeaderClick?: () => void;
  _isExpanded?: boolean;
  disableColorOnCollapsed?: boolean;
}) => {
  const [isExpanded, setIsExpanded] = useState(!!_isExpanded);
  useEffect(() => {
    setIsExpanded(!!_isExpanded);
  }, [_isExpanded]);
  return (
    <Wrapper
      type={type}
      isExpanded={isExpanded}
      disableColorOnCollapsed={disableColorOnCollapsed}
    >
      <HeaderRow
        onClick={() => {
          onHeaderClick && !isExpanded && onHeaderClick();
          setIsExpanded(!isExpanded);
        }}
        isExpanded={isExpanded}
      >
        <IconContainer type={type} onClick={(e) => e.stopPropagation()}>
          <IconREMSize height={1.4} width={1.4}>
            {validationIcon[type]}
          </IconREMSize>
        </IconContainer>
        <ClickableHeader>
          {title}
          {isExpanded ? <ArrowDown /> : <ArrowRight />}
        </ClickableHeader>
      </HeaderRow>
      {isExpanded &&
        (renderDecription ? (
          renderDecription()
        ) : (
          <Details>
            {description} {actionButton}
          </Details>
        ))}
    </Wrapper>
  );
};
