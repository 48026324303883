export function newtonRaphson({
  guess,
  increment,
  iterations,
  eps,
  f,
}: {
  guess: number;
  increment: number;
  iterations: number;
  eps: number;
  f: (n: number) => number;
}): number {
  let rootFound = false;

  for (let i = 0; i < iterations + 1; i++) {
    let fPrime =
      (f(guess + increment / 2) - f(guess - increment / 2)) / increment;
    guess += -f(guess) / fPrime;
    if (Math.abs(f(guess)) <= eps) {
      rootFound = true;
      break;
    }
  }

  if (rootFound) {
    return guess;
  } else {
    return 0;
  }
}

export function newtonRaphson2D({
  guess,
  iterations,
  eps,
  f,
  jac,
  returnOnError,
}: {
  guess: [number, number];
  iterations: number;
  eps: number;
  f: (n: [number, number]) => [number, number];
  jac: (n: [number, number]) => [[number, number], [number, number]];
  returnOnError: [number, number];
}): [number, number] {
  let rootFound = false;

  for (let i = 0; i < iterations + 1; i++) {
    let fval = f(guess);
    let [[J11, J12], [J21, J22]] = jac(guess);

    let Jdet = J11 * J22 - J12 * J21;

    let Jinv11 = (1 / Jdet) * J22;
    let Jinv12 = (-1 / Jdet) * J12;
    let Jinv21 = (-1 / Jdet) * J21;
    let Jinv22 = (1 / Jdet) * J11;

    let dguess = [
      -Jinv11 * fval[0] - Jinv12 * fval[1],
      -Jinv21 * fval[0] - Jinv22 * fval[1],
    ];
    guess[0] += dguess[0];
    guess[1] += dguess[1];

    if (Math.abs(fval[0]) <= eps && Math.abs(fval[1]) <= eps) {
      rootFound = true;
      break;
    }
  }

  if (rootFound) {
    return guess;
  } else {
    return returnOnError;
  }
}
