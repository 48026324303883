import { useMemo } from "react";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { useTypedPath } from "../../../state/pathParams";
import { turbineTypeUsageAtomFamily } from "../../../state/turbines";
import {
  adminInOrganisationSelectorFamily,
  orgTurbineManageAccessSelector,
  userNodeAccessSelectorFamily,
} from "../../../state/user";
import { ProjectBranchInfo, UsageContainerInner } from "./common";
import { Header, SubHeader, UsageText } from "./style";
import { TurbineTypeUsageType } from "../../../types/turbines";

export function TurbineTypeUsageNode({
  turbineTypeId,
  nodeId,
}: {
  turbineTypeId: string;
  nodeId: string;
}) {
  const { organisationId } = useTypedPath("organisationId");
  const adminInOrg = useRecoilValue(
    adminInOrganisationSelectorFamily({ organisationId }),
  );
  const hasOrgTurbineAccess = useRecoilValue(orgTurbineManageAccessSelector);
  const nodeAccess = useRecoilValue(userNodeAccessSelectorFamily({ nodeId }));
  const isAdminForNode = nodeAccess >= 2;

  if (!adminInOrg && !hasOrgTurbineAccess && !isAdminForNode) return null;

  return (
    <TurbineTypeUsageInner turbineTypeId={turbineTypeId} nodeId={nodeId} />
  );
}

function TurbineTypeUsageInner({
  turbineTypeId,
  nodeId,
}: {
  turbineTypeId: string;
  nodeId: string;
}) {
  const usageLoadable = useRecoilValueLoadable(
    turbineTypeUsageAtomFamily({
      nodeId: nodeId,
      turbineTypeId,
    }),
  );

  return (
    <UsageContainerInner
      loadable={usageLoadable}
      text="This type is used in: "
      typeName="turbines"
    >
      <ExtraInfo usage={usageLoadable.contents} />
    </UsageContainerInner>
  );
}

function ExtraInfo({ usage }: { usage: TurbineTypeUsageType[] }) {
  const uniqueBranches = useMemo(
    () =>
      usage.reduce<TurbineTypeUsageType[]>(
        (pre, cur) =>
          pre.some((p) => p.branchId === cur.branchId) ? pre : pre.concat(cur),
        [],
      ),
    [usage],
  );

  return (
    <>
      <Header>Turbine type usage</Header>
      <div style={{ display: "flex", flexDirection: "column", gap: "0.8rem" }}>
        <SubHeader>Used in</SubHeader>
        <UsageText>{usage.length} turbines</UsageText>
      </div>
      {usage.length > 0 && (
        <div
          style={{ display: "flex", flexDirection: "column", gap: "0.8rem" }}
        >
          <SubHeader>Used in branches</SubHeader>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
          >
            {uniqueBranches.map((u) => (
              <ProjectBranchInfo key={u.branchId} usage={u} />
            ))}
          </div>
        </div>
      )}
    </>
  );
}
