import styled from "styled-components";
import { StandardBox } from "../../styles/boxes/Boxes";

export const PopupDiv = styled(StandardBox)`
  display: flex;
  flex-direction: row;
  padding: 0.5rem 1rem;
  align-items: center;
`;

export const SVGWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;

  * {
    line-height: 0;
  }
`;
