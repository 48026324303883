import { TurbineType } from "./../../../types/turbines";
import { atom, atomFamily, selectorFamily } from "recoil";
import { FoundationType } from "types/foundations";
import { OrgResource } from "../OrganisationRightSide/types";
import {
  getAnalysisResourceManagers,
  getAnalysisResourcesOnNode,
  getFoundationResourceManagers,
  getFoundationResourcesOnNode,
  getNodesWithAccessToAnalysis,
  getCableResourceManagers,
  getNodesWithAccessToCable,
  getNodesWithAccessToFoundation,
  getNodesWithAccessToTurbine,
  getOrganisationCableResources,
  getOrganisationFoundationResources,
  getOrganisationTurbineResources,
  getTurbineResourceManagers,
  getTurbineResourcesOnNode,
  getNodesWithAccessToFinancial,
  getFinancialResourcesOnNode,
  getFinancialResourceManagers,
  getDataPackageResourceManagers,
  getNodesWithAccessToDataPackage,
  getDataPackageResourcesOnNode,
  getDataLayerResourcesOnNode,
} from "./service";
import {
  NodeAnalysisAccess,
  NodeDataPackageAccess,
  NodeFinancialAccess,
  NodeFoundationAccess,
  NodeLibraryResource,
  NodeTurbineAccess,
  UploadedVectorDataLayer,
} from "./types";
import {
  Configuration,
  listOrgConfigurations,
} from "services/configurationService";
import { CableType } from "services/cableTypeService";
import { scream } from "utils/sentry";
import { dedup } from "utils/utils";
import {
  CostConfiguration,
  listOrgFinancialConfigurations,
} from "services/costService";
import { currentUserOrganisationResourcesSelectorFamily } from "../OrganisationRightSide/content/hooks/useUserOrganisationResourcesCrud";
import { DataLibraryPackage } from "./dataLibrary/types";
import { listOrganisationLibraryPackages } from "./dataLibrary/service";

// --------- Navigation state ---------------------

export type LibraryTab =
  | "turbine"
  | "foundation"
  | "analysis"
  | "cable"
  | "financial"
  | "datalayers"
  | undefined;

export const libraryTabState = atomFamily<
  LibraryTab,
  { organisationId: string }
>({
  key: "libraryTabState",
  default: undefined,
});

// ------------ Org resources ------------------------

export const organisationTurbineResourceState = atomFamily<
  TurbineType[],
  { organisationId: string | undefined }
>({
  key: "organisationTurbineResourceState",
  default: selectorFamily({
    key: "organisationTurbineResourceState.default",
    get:
      ({ organisationId }) =>
      async () => {
        if (!organisationId) return [];
        try {
          const allTurbines =
            await getOrganisationTurbineResources(organisationId);
          return allTurbines
            .filter((t) => !t.archived)
            .sort((a, b) =>
              a.name.localeCompare(b.name, undefined, {
                sensitivity: "accent",
              }),
            );
        } catch (e) {
          scream("Failed to get turbines", { e });
          return [];
        }
      },
  }),
});

export const organisationFoundationResourceState = atomFamily<
  FoundationType[],
  { organisationId: string | undefined }
>({
  key: "organisationFoundationResourceState",
  default: selectorFamily({
    key: "organisationFoundationResourceState.default",
    get:
      ({ organisationId }) =>
      async () => {
        if (!organisationId) return [];
        try {
          const foundations =
            await getOrganisationFoundationResources(organisationId);
          return foundations
            .filter((t) => !t.archived)
            .sort((a, b) =>
              a.name.localeCompare(b.name, undefined, {
                sensitivity: "accent",
              }),
            );
        } catch (e) {
          return [];
        }
      },
  }),
});

export const organisationAnalysisResourceState = atomFamily<
  Configuration[],
  { organisationId: string | undefined }
>({
  key: "organisationAnalysisResourceState",
  default: selectorFamily({
    key: "organisationAnalysisResourceState.default",
    get:
      ({ organisationId }) =>
      async () => {
        if (!organisationId) return [];
        return await listOrgConfigurations(organisationId).catch((error) => {
          scream("Error when listing configurations", { error });
          return [];
        });
      },
  }),
});

export const organisationCableResourceState = atomFamily<
  CableType[],
  { organisationId: string | undefined }
>({
  key: "organisationCableResourceState",
  default: selectorFamily({
    key: "organisationCableResourceState.default",
    get:
      ({ organisationId }) =>
      async () => {
        if (!organisationId) return [];
        return await getOrganisationCableResources(organisationId)
          .then((res) =>
            res.sort((a, b) =>
              a.name.localeCompare(b.name, undefined, {
                sensitivity: "accent",
              }),
            ),
          )
          .catch(() => []);
      },
  }),
});

export const organisationFinancialResourceState = atomFamily<
  CostConfiguration[],
  { organisationId: string | undefined }
>({
  key: "organisationFinancialResourceState",
  default: selectorFamily({
    key: "organisationFinancialResourceState.default",
    get:
      ({ organisationId }) =>
      async () => {
        if (!organisationId) return [];
        return await listOrgFinancialConfigurations(organisationId)
          .then((res) => {
            const { items } = res;
            return items.sort((a, b) =>
              a.name.localeCompare(b.name, undefined, {
                sensitivity: "accent",
              }),
            );
          })

          .catch(() => []);
      },
  }),
});

export const organisationLibraryDataPackageResourceState = atomFamily<
  DataLibraryPackage[],
  { organisationId: string | undefined }
>({
  key: "organisationLibraryDataPackageResourceState",
  default: selectorFamily({
    key: "organisationLibraryDataPackageResourceState.default",
    get:
      ({ organisationId }) =>
      async () => {
        if (!organisationId) return [];
        return await listOrganisationLibraryPackages(organisationId)
          .then((res) => {
            const items = res;
            return items.sort((a, b) =>
              a.name.localeCompare(b.name, undefined, {
                sensitivity: "accent",
              }),
            );
          })
          .catch(() => []);
      },
  }),
});

export const libraryResourceSelector = selectorFamily<
  | { type: "foundation"; resource: FoundationType }
  | { type: "turbine"; resource: TurbineType }
  | { type: "analysis"; resource: Configuration }
  | { type: "cable"; resource: CableType }
  | { type: "financial"; resource: CostConfiguration }
  | { type: "datapackage"; resource: DataLibraryPackage }
  | undefined,
  { organisationId: string; resourceId: string }
>({
  key: "libraryResourceSelector",
  get:
    ({ organisationId, resourceId }) =>
    ({ get }) => {
      const currentUser = get(
        currentUserOrganisationResourcesSelectorFamily({ organisationId }),
      );

      const turbines = currentUser.some(
        (r) => r.resource_name === "org_turbine_manage",
      )
        ? get(organisationTurbineResourceState({ organisationId }))
        : [];
      const foundations = currentUser.some(
        (r) => r.resource_name === "org_foundation_manage",
      )
        ? get(organisationFoundationResourceState({ organisationId }))
        : [];
      const analysis = currentUser.some(
        (r) => r.resource_name === "org_analysis_manage",
      )
        ? get(organisationAnalysisResourceState({ organisationId }))
        : [];

      const cables = currentUser.some(
        (r) => r.resource_name === "org_cable_manage",
      )
        ? get(organisationCableResourceState({ organisationId }))
        : [];

      const dataPackages = currentUser.some(
        (r) => r.resource_name === "org_data_package_manage",
      )
        ? get(organisationLibraryDataPackageResourceState({ organisationId }))
        : [];

      const financial = currentUser.some(
        (r) => r.resource_name === "org_financial_manage",
      )
        ? get(organisationFinancialResourceState({ organisationId }))
        : [];

      const turbineResource = turbines.find((t) => t.id === resourceId);
      if (turbineResource)
        return { type: "turbine", resource: turbineResource };

      const foundationResource = foundations.find((f) => f.id === resourceId);
      if (foundationResource)
        return { type: "foundation", resource: foundationResource };

      const analysisResource = analysis.find((f) => f.id === resourceId);
      if (analysisResource)
        return { type: "analysis", resource: analysisResource };

      const cableResource = cables.find((f) => f.id === resourceId);
      if (cableResource) return { type: "cable", resource: cableResource };

      const dataPackage = dataPackages.find((t) => t.id === resourceId);
      if (dataPackage) return { type: "datapackage", resource: dataPackage };

      const financialResource = financial.find((f) => f.id === resourceId);
      if (financialResource)
        return { type: "financial", resource: financialResource };

      return undefined;
    },
});

export const turbineResourceWithAccessOnNodeState = atomFamily<
  NodeTurbineAccess[],
  { nodeId: string | undefined }
>({
  key: "turbineResourceWithAccessOnNodeState",
  default: selectorFamily({
    key: "turbineResourceWithAccessOnNodeState.default",
    get:
      ({ nodeId }) =>
      async () => {
        if (!nodeId) return [];
        return await getTurbineResourcesOnNode(nodeId).catch(() => []);
      },
  }),
});

export const foundationResourceWithAccessOnNodeState = atomFamily<
  NodeFoundationAccess[],
  { nodeId: string | undefined }
>({
  key: "foundationResourceWithAccessOnNodeState",
  default: selectorFamily({
    key: "foundationResourceWithAccessOnNodeState.default",
    get:
      ({ nodeId }) =>
      async () => {
        if (!nodeId) return [];
        try {
          return (await getFoundationResourcesOnNode(nodeId))
            .filter((f) => !f.foundation.archived)
            .sort((a, b) =>
              a.foundation.name.localeCompare(b.foundation.name, undefined, {
                sensitivity: "accent",
              }),
            );
        } catch {
          return [];
        }
      },
  }),
});

export const analysisResourceWithAccessOnNodeState = atomFamily<
  NodeAnalysisAccess[],
  { nodeId: string | undefined }
>({
  key: "analysisResourceWithAccessOnNodeState",
  default: selectorFamily({
    key: "analysisResourceWithAccessOnNodeState.default",
    get:
      ({ nodeId }) =>
      async () => {
        if (!nodeId) return [];
        const analysis = await getAnalysisResourcesOnNode(nodeId).catch(
          () => [],
        );
        const uniqueAnalysisResourcesOnNode = dedup(
          analysis,
          (a) => a.config.id,
        );
        return uniqueAnalysisResourcesOnNode;
      },
  }),
});

export const dataPackageResourceWithAccessOnNodeStateRefreshAtom = atom<number>(
  {
    key: "dataPackageResourceWithAccessOnNodeStateRefreshAtom",
    default: 1,
  },
);

export const dataPackageResourceWithAccessOnNodeState = atomFamily<
  NodeDataPackageAccess[],
  { nodeId: string | undefined }
>({
  key: "dataPackageResourceWithAccessOnNodeState",
  default: selectorFamily({
    key: "dataPackageResourceWithAccessOnNodeState.default",
    get:
      ({ nodeId }) =>
      async ({ get }) => {
        get(dataPackageResourceWithAccessOnNodeStateRefreshAtom);
        if (!nodeId) return [];
        const config = await getDataPackageResourcesOnNode(nodeId).catch(
          () => [],
        );
        const uniqueDataPackageResourcesOnNode = dedup(
          config,
          (a) => a.config.id,
        );
        return uniqueDataPackageResourcesOnNode;
      },
  }),
});

export const dataLayerAccessOnNodeState = atomFamily<
  UploadedVectorDataLayer[],
  { nodeId: string | undefined }
>({
  key: "dataLayerAccessOnNodeState",
  default: selectorFamily({
    key: "dataLayerAccessOnNodeState.default",
    get:
      ({ nodeId }) =>
      async ({ get }) => {
        get(dataPackageResourceWithAccessOnNodeStateRefreshAtom);
        if (!nodeId) return [];
        return await getDataLayerResourcesOnNode(nodeId).catch(() => []);
      },
  }),
});

export const financialResourceWithAccessOnNodeState = atomFamily<
  NodeFinancialAccess[],
  { nodeId: string | undefined }
>({
  key: "financialResourceWithAccessOnNodeState",
  default: selectorFamily({
    key: "financialResourceWithAccessOnNodeState.default",
    get:
      ({ nodeId }) =>
      async () => {
        if (!nodeId) return [];
        const config = await getFinancialResourcesOnNode(nodeId).catch(
          () => [],
        );
        const uniqueFinancialResourcesOnNode = dedup(
          config,
          (a) => a.config.id,
        );
        return uniqueFinancialResourcesOnNode;
      },
  }),
});

export const nodesWithAccessToTurbine = selectorFamily<
  NodeLibraryResource[],
  { organisationId: string; resourceId: string }
>({
  key: "nodesWithAccessToTurbine",
  get:
    ({ organisationId, resourceId }) =>
    async () =>
      getNodesWithAccessToTurbine(organisationId, resourceId).catch(() => []),
});

export const nodesWithAccessToFoundation = selectorFamily<
  NodeLibraryResource[],
  { organisationId: string; resourceId: string }
>({
  key: "nodesWithAccessToFoundation",
  get:
    ({ organisationId, resourceId }) =>
    async () =>
      getNodesWithAccessToFoundation(organisationId, resourceId).catch(
        () => [],
      ),
});

export const nodesWithAccessToAnalysis = selectorFamily<
  NodeLibraryResource[],
  { organisationId: string; resourceId: string }
>({
  key: "nodesWithAccessToAnalysis",
  get:
    ({ organisationId, resourceId }) =>
    async () =>
      getNodesWithAccessToAnalysis(organisationId, resourceId).catch((_) => []),
});
export const nodesWithAccessToCable = selectorFamily<
  NodeLibraryResource[],
  { organisationId: string; resourceId: string }
>({
  key: "nodesWithAccessToCable",
  get:
    ({ organisationId, resourceId }) =>
    () =>
      getNodesWithAccessToCable(organisationId, resourceId).catch(() => []),
});

export const nodesWithAccessToFinancial = selectorFamily<
  NodeLibraryResource[],
  { organisationId: string; resourceId: string }
>({
  key: "nodesWithAccessToFinancial",
  get:
    ({ organisationId, resourceId }) =>
    () =>
      getNodesWithAccessToFinancial(organisationId, resourceId).catch(() => []),
});

export const nodesWithAccessToDataPackage = selectorFamily<
  NodeLibraryResource[],
  { organisationId: string; resourceId: string }
>({
  key: "nodesWithAccessToDataPackage",
  get:
    ({ organisationId, resourceId }) =>
    () =>
      getNodesWithAccessToDataPackage(organisationId, resourceId).catch(
        () => [],
      ),
});

// ---------------- Resource managers -------------------------

export const turbineManagersSelector = selectorFamily<
  OrgResource[],
  { organisationId: string }
>({
  key: "turbineManagersSelector",
  get:
    ({ organisationId }) =>
    async () =>
      getTurbineResourceManagers(organisationId).catch((_) => []),
});

export const foundationManagersSelector = selectorFamily<
  OrgResource[],
  { organisationId: string }
>({
  key: "foundationManagersSelector",
  get:
    ({ organisationId }) =>
    async () =>
      getFoundationResourceManagers(organisationId).catch((_) => []),
});

export const analysisManagersSelector = selectorFamily<
  OrgResource[],
  { organisationId: string }
>({
  key: "analysisManagersSelector",
  get:
    ({ organisationId }) =>
    async () =>
      getAnalysisResourceManagers(organisationId).catch((_) => []),
});

export const cableManagersSelector = selectorFamily<
  OrgResource[],
  { organisationId: string }
>({
  key: "cableManagersSelector",
  get:
    ({ organisationId }) =>
    async () =>
      getCableResourceManagers(organisationId).catch((_) => []),
});

export const financialManagersSelector = selectorFamily<
  OrgResource[],
  { organisationId: string }
>({
  key: "financialManagersSelector",
  get:
    ({ organisationId }) =>
    async () =>
      getFinancialResourceManagers(organisationId).catch((_) => []),
});

export const dataPackageManagersSelector = selectorFamily<
  OrgResource[],
  { organisationId: string }
>({
  key: "dataPackageManagersSelector",
  get:
    ({ organisationId }) =>
    async () =>
      getDataPackageResourceManagers(organisationId).catch((_) => []),
});
