import mixpanel from "mixpanel-browser";
mixpanel.init("63a3a6a3beca5b8ccf6c4cd666e6cb05", {
  api_host: "/api/lenapxim",
});

let inDevelopment = process.env.NODE_ENV === "development";

let actions = {
  identify: (id: string) => {
    if (!inDevelopment) mixpanel.identify(id);
  },
  alias: (id: string) => {
    if (!inDevelopment) mixpanel.alias(id);
  },
  track: (name: string, props: any) => {
    if (!inDevelopment) mixpanel.track(name, props);
  },
  people: {
    set: (props: any) => {
      if (!inDevelopment) mixpanel.people.set(props);
    },
    set_once: (props: any) => {
      if (!inDevelopment) mixpanel.people.set_once(props);
    },
  },
  register: (props: any) => {
    mixpanel.register(props);
  },
  stop_tracking: () => {
    mixpanel.opt_out_tracking();
  },
  start_tracking: () => {
    mixpanel.opt_in_tracking();
  },
};

export let Mixpanel = actions;
