import React from "react";
import CloseIcon from "@icons/24/Close.svg";
import { IconBtn } from "components/General/Icons";
import FullScreenModal from "components/FullScreenModal/FullScreenModal";
import UploadFeaturesFromCoordinatesGeneral from "components/UploadModal/components/NewUploadTab/UploadFeaturesFromCoordinates/UploadFeaturesFromCoordinatesGeneral";
import { ProjectFeature } from "types/feature";
import { HeaderRow, Modal, Title } from "./shared";

const AddFeatureFromCoordinatesToMapModal = ({
  onAddClick,
  onClose,
}: {
  onAddClick(newFeatures: ProjectFeature[]): void;
  onClose(): void;
}) => {
  return (
    <FullScreenModal onClick={onClose} onEscape={onClose}>
      <Modal>
        <HeaderRow>
          <h2>
            <Title>Add from coordinates</Title>
          </h2>
          <IconBtn size="1.5rem" onClick={onClose}>
            <CloseIcon />
          </IconBtn>
        </HeaderRow>
        <UploadFeaturesFromCoordinatesGeneral
          inContextOfProject={false}
          onDoneClick={onClose}
          onAddClick={onAddClick}
        />
      </Modal>
    </FullScreenModal>
  );
};

export default AddFeatureFromCoordinatesToMapModal;
