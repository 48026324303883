import { typography } from "styles/typography";
import { colors } from "styles/colors";
import styled from "styled-components";
import { Column, Row } from "components/General/Layout";
import { StandardBox } from "styles/boxes/Boxes";
import { borderRadiusMedium } from "styles/space";

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  width: 100%;
  height: 95%;
`;

export const ContentHeaderColumn = styled(Column)`
  gap: 0.4rem;
`;
export const ContentHeader = styled.p`
  ${typography.h3}
`;

export const ContentTableColumn = styled(Column)`
  & > :nth-child(2n + 1) {
    background-color: ${colors.focusBackground};
  }
  gap: 0;
`;

export const ContentTableRow = styled(Row)<{
  disabled?: boolean;
  disableHover?: boolean;
}>`
  padding: 0.7rem 1.5rem;
  align-items: center;
  min-height: 3.9rem;
  box-sizing: border-box;
  ${(p) => p.disabled && `opacity: 0.6`};
  ${(p) => !p.disabled && `cursor: pointer`};
  > button {
    opacity: 0;
    transition: opacity 0.15s;
  }
  &:hover {
    > button {
      opacity: 1;
    }
  }
`;

export const ContentTableRowNonHover = styled(ContentTableRow)`
  cursor: inherit;
  &:hover {
    background-color: inherit;
    cursor: inherit;
  }
`;

export const HoverableColumn = styled(Column)`
  gap: 0rem;
  height: 100%;
  &:hover {
    background-color: ${colors.hover};
  }
`;

export const GaplessRow = styled(Row)`
  gap: 0;

  > * {
    flex: 1;
  }
`;

export const GaplessRowPadding = styled(GaplessRow)`
  padding: 1rem;
`;

export const TabHeader = styled.p`
  ${typography.h4}
  text-transform: capitalize;
  text-wrap: nowrap;
  margin: 0;
`;

export const TabDescription = styled.p`
  ${typography.contentAndButtons}
  margin: 0;
`;
export const Text = styled.p`
  ${typography.contentAndButtons}
  text-wrap: nowrap;
`;
export const TextEllipsis = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
`;

export const SecondaryText = styled.p`
  ${typography.contentAndButtons}
  color: ${colors.secondaryText};
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
`;

export const GroupLinkText = styled(TextEllipsis)`
  cursor: pointer;
  color: ${colors.primary};
`;

export const SearchAndSelectContainer = styled(StandardBox)`
  display: flex;
  flex-direction: column;
  width: 35rem;
  height: fit-content;
  max-width: 35rem;
  gap: 1.2rem;
  background: white;
`;

export const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.8rem 0;
  height: fit-content;
  max-height: 30vh;
  gap: 1.2rem;
  overflow-y: auto;
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.inputOutline};
`;

export const TableHeader = styled.div`
  ${typography.semiBold}
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

export const NoGroupMemberWrapper = styled.div`
  width: 100%;
  flex-direction: row;
  justify-content: center;
  display: flex;
`;

export const NoGroupMember = styled.div`
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.lightBlue};
  border-radius: ${borderRadiusMedium};
  width: fit-content;
`;

export const SourceAccessRow = styled(Row)`
  flex: 0 0 30%;
  justify-content: left;
  align-items: center;
`;
