import { EXPORT_CABLE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useExportCableSettings";
import { SUBSTATION_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useSubstationSettings";
import { useSetRecoilState, useRecoilState, useRecoilValue } from "recoil";
import {
  FeatureSettingsModalTypeV2,
  ProjectConfigModalTypeV2,
} from "../../../../state/configuration";
import { modalTypeOpenAtom } from "../../../../state/modal";
import ArrowTopRight from "@icons/24/ArrowTopRight.svg?react";
import { CABLE_MENU_ID } from "../../../SettingsV2/FeatureSettings/Data/useCableSettings";
import { MOORING_MENU_ID } from "../../../SettingsV2/FeatureSettings/Data/useMooringSettings";
import { TURBINE_MENU_ID } from "../../../SettingsV2/FeatureSettings/Data/useTurbineSettings";
import { COST_MENU_ID } from "../../../SettingsV2/ProjectConfiguration/Data/useCostConfiguration";
import {
  selectedMenuItemState,
  unsavedSettingsState,
} from "../../../SettingsV2/Shared/state";
import { OpenPreviewButton } from "components/ComponentsPreview/style";
import { showComponentsPreviewAtom } from "components/ComponentsPreview/state";
import { projectIdSelector } from "state/pathParams";
import { useCallback } from "react";
import Tooltip from "components/General/Tooltip";

export const FeatureSettingsShortcut = ({
  menuId,
  itemId,
}: {
  menuId:
    | typeof TURBINE_MENU_ID
    | typeof MOORING_MENU_ID
    | typeof CABLE_MENU_ID
    | typeof SUBSTATION_MENU_ID
    | typeof EXPORT_CABLE_MENU_ID;
  itemId: string;
}) => {
  const projectId = useRecoilValue(projectIdSelector);
  const setModalType = useSetRecoilState(modalTypeOpenAtom);
  const [showComponentsPreview, setShowComponentsPreview] = useRecoilState(
    showComponentsPreviewAtom,
  );
  const [unsavedSettings, setUnsavedSettings] =
    useRecoilState(unsavedSettingsState);
  const [, setSelectedItemId] = useRecoilState(
    selectedMenuItemState({
      menuId: showComponentsPreview.componentType ?? "",
      projectId: projectId ?? "",
    }),
  );
  const onClose = useCallback(
    () => setShowComponentsPreview({ show: false }),
    [setShowComponentsPreview],
  );

  return (
    <OpenPreviewButton
      onClick={() => {
        if (unsavedSettings) {
          if (window.confirm("Are you sure you want to quit without saving?")) {
            setUnsavedSettings(false);
            setModalType({
              modalType: FeatureSettingsModalTypeV2,
              metadata: { selectedMenuId: menuId },
              backTo: {
                modalType: ProjectConfigModalTypeV2,
                metadata: {
                  selectedMenuId: COST_MENU_ID,
                },
              },
            });
            setSelectedItemId(itemId);
            onClose();
          }
        } else {
          setUnsavedSettings(false);
          setModalType({
            modalType: FeatureSettingsModalTypeV2,
            metadata: { selectedMenuId: menuId },
            backTo: {
              modalType: ProjectConfigModalTypeV2,
              metadata: {
                selectedMenuId: COST_MENU_ID,
              },
            },
          });
          setSelectedItemId(itemId);
          onClose();
        }
      }}
    >
      {" "}
      <Tooltip text={"Go to component"}>
        <ArrowTopRight />
      </Tooltip>
    </OpenPreviewButton>
  );
};
