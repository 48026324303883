import styled from "styled-components";
import { typography } from "styles/typography";
import { useRecoilValue } from "recoil";
import { usersInOrganisationState } from "components/Organisation/state";
import { OrganisationUser } from "types/customer";
import { Row } from "components/General/Layout";
import UserImageRound from "components/UserImage/UserImageRound";
import React from "react";
import { colors } from "styles/colors";
import { spacing4, spacing6, spacing8 } from "styles/space";
import { scream } from "utils/sentry";
import { customerProjectAtomFamily } from "state/timeline";

export const HiddenIfNotChecked = styled.div`
  display: flex;
  align-items: center;
`;

export const Table = styled.table`
  ${HiddenIfNotChecked}:not([data-checked=true]) {
    visibility: hidden;
  }

  tbody {
    tr:nth-child(odd) {
      background-color: ${colors.grey50};
    }
    tr:nth-child(even) {
      background-color: ${colors.white};
    }
    tr:hover {
      ${HiddenIfNotChecked} {
        visibility: visible;
      }
    }
  }
`;

export const HeaderColumn = styled.p`
  ${typography.sub2};
`;

export const StickyThead = styled.thead`
  position: sticky;
  top: -1px;
  background-color: white;
  z-index: 2;
`;

export const UserInfo = ({
  organisationId,
  userId,
}: {
  organisationId: string;
  userId: string;
}) => {
  const allMembersRaw = useRecoilValue(
    usersInOrganisationState(organisationId),
  );

  const user = allMembersRaw.find(
    (m: OrganisationUser) => m.user_id === userId,
  );

  return (
    <Row alignCenter>
      {user ? (
        <>
          <UserImageRound user={user} size={1.6} />
          <p>{user.nickname}</p>
        </>
      ) : (
        <p>User not found</p>
      )}
    </Row>
  );
};

export const SelectedLayersWrapper = styled.div`
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.32);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: ${spacing6} ${spacing8};
  gap: ${spacing4};
  background-color: ${colors.blue100};
  color: ${colors.white};

  svg {
    width: 1.4rem;
    height: 1.4rem;
  }
`;

export const ProjectInfo = ({ projectId }: { projectId: string }) => {
  const project = useRecoilValue(
    customerProjectAtomFamily({
      nodeId: projectId,
    }),
  );

  if (!project) {
    scream(
      "Did not find the project to find data layer usage using project id: " +
        projectId,
    );
    return "Unknown project";
  }

  return <div>{project.name}</div>;
};
