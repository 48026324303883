import styled from "styled-components";
import { colors } from "../../styles/colors";
import {
  spaceLarge,
  spaceTiny,
  spaceSmall,
  spaceMedium,
} from "../../styles/space";
import { IconBtn } from "../General/Icons";

export const SITELOCATOR_COLORS = [
  "#560153",
  "#7a0177",
  "#ae017e",
  "#dd3497",
  "#f768a1",
  "#fa9fb5",
  "#fcc5c0",
  "#feebe2",
  "#fef2ec",
  "#fffcfb",
  "#ffffff",
];

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const SelectedUnitsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

// NOTE: The `> * { margin }` part is so that the hover buttons are colored all
// the way to the edges, but margins for other elements are reasonable.
export const ProblemDiv = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  > * {
    margin: 0 ${spaceLarge};
  }
`;

export const ProblemEntry = styled.div<{ open?: boolean }>`
  display: flex;
  flex-direction: column;

  margin: 0 !important;

  > * {
    padding-left: ${spaceLarge};
    padding-right: ${spaceLarge};
  }

  p {
    margin: 0;
    padding: 0;
  }
`;

export const ProblemEntryHeader = styled.div`
  display: grid;
  grid-template-columns: 5fr 2fr;
  row-gap: ${spaceTiny};
  margin: 0 !important;
  &:hover {
    cursor: pointer;
  }

  p,
  h5 {
    margin: 0;
    padding: 0;
  }

  padding-top: ${spaceSmall};
  padding-bottom: ${spaceMedium};
`;

export const LCoEParamRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spaceMedium} 0;

  ${IconBtn} {
    padding: ${spaceTiny};
    svg {
      path {
        stroke: ${colors.iconDefault};
      }
    }
  }
`;
