import { useRecoilValue } from "recoil";
import { modalTypeOpenAtom } from "../../state/modal";
import RenameFeatureModal from "./RenameModalGeneric";
import { projectIdSelector, parkIdSelector } from "../../state/pathParams";

export const RenameParkModalType = "RenameParkModal";

const RenameParkModal = () => {
  const projectId = useRecoilValue(projectIdSelector);
  const parkId = useRecoilValue(parkIdSelector);
  const modalTypeOpen = useRecoilValue(modalTypeOpenAtom);

  if (modalTypeOpen?.modalType !== RenameParkModalType || !projectId || !parkId)
    return null;

  return (
    <RenameFeatureModal
      featureId={parkId}
      defaultName={"Untitled park"}
      header={"Rename park"}
    />
  );
};

export default RenameParkModal;
