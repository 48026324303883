import { useEffect, useRef } from "react";
import { useRecoilValue } from "recoil";
import {
  borderRadiusMedium,
  spaceDecent,
  spaceSmall,
  spacing4,
} from "styles/space";
import EarthIcon from "@icons/14/Earth.svg";
import { useClickOutside } from "hooks/useClickOutside";
import { IconREMSize, typography } from "styles/typography";
import { Mixpanel } from "mixpanel";
import { colors } from "styles/colors";
import useBooleanState from "hooks/useBooleanState";
import { projectIdSelector } from "state/pathParams";
import BranchProjectPicker from "components/CompareParksModal/ConfigurationBar/SelectParks/BranchProjectPicker";
import { selectedParksAtom } from "components/CompareParksModal/state";
import {
  activeTourStepState,
  lastEventState,
  useTrackEvent,
} from "components/OnboardingTours/state";
import { TUTORIAL_PROJECT_SEARCH_PARAM } from "components/OnboardingTours/Tour";
import { useSearchParams } from "react-router-dom";

const SelectParksButton = () => {
  const [isOpen, toggleIsOpen] = useBooleanState(false);
  const nodeId = useRecoilValue(projectIdSelector);
  const selectedParks = useRecoilValue(
    selectedParksAtom({ projectId: nodeId ?? "" }),
  );
  const frameRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  const trackEvent = useTrackEvent();
  const lastEvent = useRecoilValue(lastEventState);
  const [searchParams] = useSearchParams();
  const activeStep = useRecoilValue(activeTourStepState);
  const isTutorialProject = searchParams.get(TUTORIAL_PROJECT_SEARCH_PARAM);

  // Close dropdown when moving on in onboarding tour
  useEffect(() => {
    if (
      isTutorialProject &&
      lastEvent === "parkSelectedInCompare" &&
      activeStep === "selectParkInCompare"
    ) {
      toggleIsOpen();
    }
  }, [activeStep, isTutorialProject, lastEvent, toggleIsOpen]);

  useClickOutside(
    frameRef,
    toggleIsOpen,
    (target) => target === frameRef?.current || target === buttonRef?.current,
  );

  return (
    <div style={{ position: "relative", overflow: "visible" }}>
      <div
        ref={buttonRef}
        style={{
          display: "flex",
          gap: spaceDecent,
          borderRadius: borderRadiusMedium,
          padding: `${spaceSmall} ${spaceDecent}`,
          alignItems: "center",
          cursor: "pointer",
          position: "relative",
          border: isOpen
            ? `1px solid ${colors.borderSelected}`
            : `1px solid ${colors.borderDefault}`,
          boxSizing: "border-box",
        }}
        onClick={() => {
          toggleIsOpen();
          Mixpanel.track("Toggle Select comparison parks open", {});
          trackEvent("openParkSelector");
        }}
      >
        <IconREMSize height={1.6} width={1.6}>
          <EarthIcon />
        </IconREMSize>
        <p style={{ ...typography.regular, margin: 0 }}>Select parks</p>
        <span
          style={{
            ...typography.regular,
            margin: 0,
            backgroundColor: colors.blue100,
            color: colors.blue600,
            padding: `2px ${spacing4}`,
            borderRadius: typography.regular.fontSize,
          }}
        >
          {selectedParks.length}
        </span>
      </div>
      {isOpen && <BranchProjectPicker ref={frameRef} onExit={toggleIsOpen} />}
    </div>
  );
};

export default SelectParksButton;
