import { useCallback, useMemo } from "react";
import { ABLY_CONFIGURATION_UPDATE } from "../state/ably";
import { Types } from "ably";
import { useAblyGeneric } from "./useAblyGeneric";
import { _Configuration } from "../services/configurationService";
import useConfigurationCrud from "./useConfigurationCrud";
import {
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "../components/ErrorBoundaries/ErrorBoundaryLocal";

const AblyConfiguration = ErrorBoundaryWrapper(
  ({ projectId }: { projectId: string }) => {
    const { updateLocal, deleteLocal } = useConfigurationCrud();

    const channelName = useMemo(
      () => projectId && `${projectId}:all`,
      [projectId],
    );

    const onMessageReceived = useCallback(
      (message: Types.Message) => {
        const { partitionKey, isArchived } = message.data;

        const nodeId = partitionKey.split("#")[1];

        const configuration = _Configuration.parse(message.data.configuration);
        isArchived
          ? deleteLocal(nodeId, configuration.id)
          : updateLocal(nodeId, configuration);
      },
      [deleteLocal, updateLocal],
    );

    const events = useMemo(
      () => [{ eventName: ABLY_CONFIGURATION_UPDATE, onMessageReceived }],
      [onMessageReceived],
    );

    useAblyGeneric(channelName, events);

    return null;
  },
  FatalErrorBoundaryWrapper,
  ScreamOnError,
);

export default AblyConfiguration;
