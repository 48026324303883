import { fetchEnhancerWithToken, fetchSchemaWithToken } from "./utils";
import { BBOX } from "utils/geojson/validate";
import * as spec from "api/bathymetry";
import { ZodUnwrapRecord } from "types/utils";
import { toRecordString } from "utils/utils";

export const getSimpleGebcoBathymetryTile = async (
  x: number,
  y: number,
  z: number,
) =>
  fetchEnhancerWithToken(`/tiles/gebco-terrarium-2023/${z}/${x}/${y}.png`, {
    method: "get",
    headers: {},
  });

const _SlopeInPolygon = spec.paths["/api/bathymetry/v2/slope/{nodeId}"]["post"];
type SlopeInPolygon = ZodUnwrapRecord<typeof _SlopeInPolygon>;
export const fetchSlopeInPolygon = (
  path: SlopeInPolygon["pathParams"],
  body: SlopeInPolygon["requestBody"]["application/json"],
  search: SlopeInPolygon["searchParams"],
) => {
  const sp = new URLSearchParams(toRecordString(search));
  return fetchSchemaWithToken(
    _SlopeInPolygon.responses["200"]["application/json"],
    `/api/bathymetry/v2/slope/${path.nodeId}?${sp.toString()}`,
    {
      method: "post",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
};

const _Tile = spec.paths["/api/bathymetry/v2/tile/{x}/{y}/{z}"]["get"];
type Tile = ZodUnwrapRecord<typeof _Tile>;
export const fetchTile = (
  path: Tile["pathParams"],
  search: Tile["searchParams"],
): Promise<Response> => {
  const sp = new URLSearchParams(toRecordString(search));
  return fetchEnhancerWithToken(
    `/api/bathymetry/v2/tile/${path.x}/${path.y}/${path.z}?${sp.toString()}`,
    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
};

const _TileFromBBOX =
  spec.paths["/api/bathymetry/v2/tile/{nodeId}/{bbox}"]["post"];
type TileFromBBOX = ZodUnwrapRecord<typeof _TileFromBBOX>;
export const fetchTileFromBBOX = (
  boundingBox: BBOX,
  nodeId: TileFromBBOX["pathParams"]["nodeId"],
  bathymetryIds: TileFromBBOX["requestBody"]["application/json"]["bathymetryIds"],
) => {
  const bbox = boundingBox.join(",");
  return fetchSchemaWithToken(
    _TileFromBBOX.responses["200"]["application/json"],
    `/api/bathymetry/v2/tile/${nodeId}/${bbox}`,
    {
      method: "post",
      body: JSON.stringify({
        bathymetryIds,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
};
