import React, { useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalTypeOpenAtom } from "../../state/modal";
import FullScreenModal from "../FullScreenModal/FullScreenModal";
import Tabs from "../General/Tabs";
import AddSourceTab from "./components/AddSourceTab";
import AddSourceEndpointTab from "./components/AddSourceEndpointTab";
import { MenuFrame } from "components/MenuPopup/CloseableMenuPopup";
import { Column } from "components/General/Layout";

export const AddLayerSourceInternalModalType =
  "UplAddLayerSourceInternalModalTypeoadModal" as const;

const AddLayerSourceInternalModalInner = () => {
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const onClose = useCallback(
    () => setModalTypeOpen(undefined),
    [setModalTypeOpen],
  );

  return (
    <FullScreenModal>
      <MenuFrame
        title="Add layer source"
        onExit={onClose}
        style={{
          width: "50vw",
          maxWidth: "65rem",
        }}
      >
        <Column>
          <Tabs
            menuStyle={{
              margin: 0,
              justifyContent: "flex-start",
            }}
            tabs={[
              {
                name: "Add source",
                data: <AddSourceTab />,
              },
              {
                name: "Add source endpoint",
                data: <AddSourceEndpointTab />,
              },
            ]}
          />
        </Column>
      </MenuFrame>
    </FullScreenModal>
  );
};

const AddLayerSourceInternalModal = () => {
  const modalTypeOpen = useRecoilValue(modalTypeOpenAtom);

  if (
    !modalTypeOpen ||
    modalTypeOpen?.modalType !== AddLayerSourceInternalModalType
  ) {
    return null;
  }

  return <AddLayerSourceInternalModalInner />;
};

export default AddLayerSourceInternalModal;
