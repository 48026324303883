import { z } from "zod";
import {
  fetchEnhancerWithToken,
  fetchSchemaWithToken,
} from "../../services/utils";
import { _FeatureParser } from "../../types/feature";
import { BranchNotFoundError, NotFoundError } from "../ErrorBoundaries/types";
import { _Node } from "services/customerAPI";

export const PROJECT_SERVICE_API_PATH = "/api/project-service";
export const PROJECT_SERVICE_API_VERSION = "1.0.0";

const _KeyVersion = z.object({
  author: z.string().optional(),
  start: z.number(),
  end: z.number(),
  meta: z.object({ count: z.number() }),
});

export const getBranchFeatures = async (
  nodeId: string,
  branchId: string,
  version: number | null | undefined, // timestamp in seconds
) => {
  const sp: Record<string, string> = { features: "true" };
  if (version) {
    sp["version"] = version.toString();
  }

  const currentSearchParams = new URLSearchParams(window.location.search);
  const featuredbExists = currentSearchParams.has("featuredb");

  try {
    const res = await fetchEnhancerWithToken(
      `${PROJECT_SERVICE_API_PATH}/node/${nodeId}/branch/${branchId}${
        featuredbExists ? "/new" : ""
      }?` + new URLSearchParams(sp),
      {
        method: "get",
        headers: {
          "x-project-data-client-version": PROJECT_SERVICE_API_VERSION,
        },
      },
    );
    const data = await res.json();
    return data;
  } catch (err) {
    if (err instanceof NotFoundError) {
      throw new BranchNotFoundError(err.message);
    }
    throw err;
  }
};

export const _FeatureVersion = z.object({
  author: z.string().optional(),
  createdAt: z.number(),
  version: z.number(),
  features: _FeatureParser.array(),
  archivedFeatures: z.string().array(),
});

export type FeatureVersion = z.infer<typeof _FeatureVersion>;

export const getFeatureVersions = async (
  nodeId: string,
  branchId: string,
  featureId: string,
): Promise<FeatureVersion[]> => {
  return fetchEnhancerWithToken(
    `${PROJECT_SERVICE_API_PATH}/node/${nodeId}/branch/${branchId}/feature/${featureId}/key_versions`,
    {
      method: "get",
      headers: {
        "x-project-data-client-version": PROJECT_SERVICE_API_VERSION,
      },
    },
  )
    .then((res) => res.json())
    .then(_FeatureVersion.array().parse);
};

const _BranchKeyVersionArray = z.array(_KeyVersion);

export type BranchKeyVersions = z.infer<typeof _KeyVersion>;

export const getBranchKeyVersions = async (
  nodeId: string,
  branchId: string,
): Promise<BranchKeyVersions[]> =>
  fetchSchemaWithToken(
    _BranchKeyVersionArray,
    `/api/project-service/node/${nodeId}/branch/${branchId}/key_versions`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-project-data-client-version": PROJECT_SERVICE_API_VERSION,
      },
    },
  );

// -------- Tutorial --------

const _TutorialProjectResponse = z.object({
  meta: _Node,
  branch: z.object({}),
});

export const createTutorialProjectService = async (nodeId: string) => {
  const tutorialId = "2g5pfzNjvnfGFWkSxEudrHcBqQP";

  const res = await fetchEnhancerWithToken(
    `${PROJECT_SERVICE_API_PATH}/tutorial/${nodeId}/${tutorialId}`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-project-data-client-version": PROJECT_SERVICE_API_VERSION,
      },
    },
  );
  const j = await res.json();
  const parsed = _TutorialProjectResponse.parse(j);
  return parsed.meta;
};
