/// <reference types="vite-plugin-svgr/client" />

import { Suspense, useRef } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import {
  branchIdSelector,
  organisationIdSelector,
  parkIdSelector,
  projectIdSelector,
} from "../../../../state/pathParams";
import { getBranchSelectorFamily } from "../../../../state/timeline";
import { MenuFrame } from "../../../MenuPopup/CloseableMenuPopup";
import { spaceMedium } from "styles/space";
import { archivedProjectVersionsOpenStateAtom } from "../state";
import { Inner } from "./Inner";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { Column } from "components/General/Layout";

const VersionHistoryContainer = styled.div`
  display: flex;
  position: fixed;
  z-index: 1000;
  right: ${spaceMedium};
  top: calc(
    calc(var(--top-bar-menu-height) + var(--branch-tab-bar-height)) +
      ${spaceMedium}
  );
  height: calc(
    100vh - calc(
        var(--top-bar-menu-height) + var(--branch-tab-bar-height) +
          var(--side-bars-width)
      ) - 2.4rem
  );
  @media screen and (max-width: 1000px) {
    /* This is to not cover the mapbox logo */
    height: calc(
      100vh - calc(
          var(--top-bar-menu-height) + var(--branch-tab-bar-height) +
            var(--side-bars-width)
        ) - 6.8rem
    );
  }
`;

const ArchiveProjectModalOuterInner = () => {
  const frameRef = useRef<HTMLDivElement>(null);
  const organisationId = useRecoilValue(organisationIdSelector) ?? "";
  const projectId = useRecoilValue(projectIdSelector) ?? "";
  const branchId = useRecoilValue(branchIdSelector) ?? "";
  const parkId = useRecoilValue(parkIdSelector) ?? "";
  const branch = useRecoilValue(
    getBranchSelectorFamily({
      projectId,
      branchId: branchId,
    }),
  );
  const branchName = branch?.title ?? "Banch";

  const setVersionHistoryOpenState = useSetRecoilState(
    archivedProjectVersionsOpenStateAtom,
  );

  return (
    <VersionHistoryContainer>
      <MenuFrame
        ref={frameRef}
        title="Saved project data"
        subtitle={branchName}
        onExit={() => setVersionHistoryOpenState(false)}
        style={{
          flex: 1,
          overflow: "auto",
          width: "30rem",
        }}
      >
        <Suspense
          fallback={
            <Column style={{ gap: "1.2rem" }}>
              <SkeletonBlock style={{ height: "8rem" }} />
              <SkeletonBlock style={{ height: "8rem" }} />
            </Column>
          }
        >
          <Inner
            organisationId={organisationId}
            projectId={projectId}
            branchId={branchId}
            parkId={parkId}
          />
        </Suspense>
      </MenuFrame>
    </VersionHistoryContainer>
  );
};

export default function ArchiveProjectModal() {
  const archivedProjectVersionOpenState = useRecoilValue(
    archivedProjectVersionsOpenStateAtom,
  );
  if (!archivedProjectVersionOpenState) {
    return null;
  }

  return <ArchiveProjectModalOuterInner />;
}
