import {
  _WindTimeseries,
  WindTimeseries,
  _YearlyMonthlyWindRoses,
  SimpleWindRose,
  _SimpleWindRose,
  RawWindRose,
} from "../state/windStatistics";
import { fetchEnhancerWithToken, fetchSchemaWithToken } from "./utils";
import { z } from "zod";
import { GumbelParams, _GumbelParams } from "../functions/met";
import { WaveDataSource } from "../state/waveStatistics";
import { _SpatialCalibration } from "./windSourceConfigurationService";

export enum BuiltinWindDataSource {
  ERA5 = "era5",
  NORA3 = "nora3",
  CERRA = "cerra",
}

export enum WindDataSource {
  ERA5 = "era5",
  NORA3 = "nora3",
  CUSTOM = "custom",
  CERRA = "cerra",
  BEST = "best_available",
}

export enum WindSpeedCalibrationType {
  PERCENT = "percent",
}

export enum SpatialCalibrationType {
  GWA = "global_wind_atlas",
  CUSTOM = "custom",
}

export enum CustomWindFileType {
  TIMESERIES = "timeseries",
  MEAN_SPEED_GRID = "meanSpeedGrid",
  WRG = "wrg",
}

export const _WindSource = z.object({
  id: z.union([
    z.literal(WindDataSource.ERA5),
    z.literal(WindDataSource.NORA3),
    z.literal(WindDataSource.CUSTOM),
    z.literal(WindDataSource.CERRA),
  ]),
  name: z.string(),
});

export const _CustomWindSource = z.object({
  id: z.string(),
  name: z.string(),
});

export const _WindDataset = z.object({
  source: _WindSource,
  calibration: z.any().optional(), //TODO: does not work when using string
});

export type WindDataset = z.infer<typeof _WindDataset>;

export const _PercentCalibration = z
  .string()
  .regex(new RegExp(/^-?\d*\.?\d+%$/));

export async function fetchCustomWindRose({
  sourceId,
  nodeId,
  height,
  numberOfDirections,
}: {
  sourceId: string;
  nodeId: string;
  height?: number;
  numberOfDirections?: number;
}): Promise<SimpleWindRose> {
  const params = new URLSearchParams();
  height && params.set("height", height.toFixed(0));
  numberOfDirections &&
    params.set("n_directions", numberOfDirections.toString());

  const url = `/api/octopus/metocean/custom-wind-rose/${nodeId}/${sourceId}?${params}`;

  const res = await fetchEnhancerWithToken(url.toString(), {
    method: "get",
    headers: {},
  });
  const json = await res.json();

  return _SimpleWindRose.parse(json);
}

export async function fetchSimpleWindRose({
  sourceId,
  height,
  numberOfDirections,
  lon,
  lat,
}: {
  sourceId: string;
  lon: number;
  lat: number;
  height?: number;
  numberOfDirections?: number;
}): Promise<SimpleWindRose> {
  const params = new URLSearchParams();
  height && params.set("height", height.toFixed(0));
  numberOfDirections &&
    params.set("n_directions", numberOfDirections.toString());

  const url = `/api/octopus/metocean/simple-wind-rose/${sourceId}/${lon.toFixed(
    5,
  )}/${lat.toFixed(5)}?${params}`;

  const res = await fetchEnhancerWithToken(url.toString(), {
    method: "get",
    headers: {},
  });
  const json = await res.json();

  return _SimpleWindRose.parse(json);
}

export async function fetchWindRose({
  source,
  lon,
  lat,
  height,
  numberOfDirections,
  fromYear,
  toYear,
}: {
  source: WindDataSource;
  lon: number;
  lat: number;
  height: number;
  numberOfDirections?: number;
  fromYear?: number;
  toYear?: number;
}): Promise<RawWindRose> {
  const _lon = lon.toFixed(4);
  const _lat = lat.toFixed(4);

  const params = new URLSearchParams({
    height: height.toFixed(0),
  });
  numberOfDirections &&
    params.set("n_directions", numberOfDirections.toString());
  fromYear && params.set("from_year", fromYear.toFixed(0));
  toYear && params.set("to_year", toYear.toFixed(0));

  const url = `/api/octopus/metocean/wind-rose/${source.toLowerCase()}/${_lon}/${_lat}?${params}`;

  const res = await fetchEnhancerWithToken(url.toString(), {
    method: "get",
    headers: {},
  });
  const json = await res.json();

  return _YearlyMonthlyWindRoses.parse(json).yearly;
}

export async function fetchWindData({
  source,
  lon,
  lat,
  height,
  fromYear,
  toYear,
}: {
  source: WindDataSource;
  lon: number;
  lat: number;
  height: number;
  fromYear?: number;
  toYear?: number;
}): Promise<WindTimeseries> {
  const _lon = lon.toFixed(4);
  const _lat = lat.toFixed(4);

  const params = new URLSearchParams({
    height: height.toFixed(0),
  });

  fromYear && params.set("from_year", fromYear.toFixed(0));
  toYear && params.set("to_year", toYear.toFixed(0));

  const url = `/api/octopus/metocean/wind/${source.toLowerCase()}/${_lon}/${_lat}?${params}`;

  const res = await fetchEnhancerWithToken(url.toString(), {
    method: "get",
    headers: {},
  });
  const json = await res.json();

  return _WindTimeseries.parse(json);
}

export async function fetchAvailableWindDatasets({
  lon,
  lat,
}: {
  lon: number;
  lat: number;
}): Promise<WindDataset[]> {
  const _lon = lon.toFixed(4);
  const _lat = lat.toFixed(4);

  const url = `/api/octopus/metocean/sources/wind/${_lon}/${_lat}`;

  const res = await fetchEnhancerWithToken(url.toString(), {
    method: "get",
    headers: {},
  });
  const json = await res.json();

  return z
    .object({
      sources: _WindDataset.array(),
    })
    .parse(json).sources;
}

export async function fetchBestWaveSource({
  lon,
  lat,
}: {
  lon: number;
  lat: number;
}): Promise<string> {
  const _lon = lon.toFixed(4);
  const _lat = lat.toFixed(4);

  const url = `/api/octopus/metocean/sources/wave/${_lon}/${_lat}`;

  return (
    await fetchSchemaWithToken(
      z.object({
        source: z.string(),
      }),
      url.toString(),
      {
        method: "get",
        headers: {},
      },
    )
  ).source;
}

export async function fetchAvailableWaveSources({
  lon,
  lat,
}: {
  lon: number;
  lat: number;
}): Promise<string[]> {
  const _lon = lon.toFixed(4);
  const _lat = lat.toFixed(4);

  const url = `/api/octopus/metocean/all-sources/wave/${_lon}/${_lat}`;

  return (
    await fetchSchemaWithToken(
      z.object({
        sources: z.string().array(),
      }),
      url.toString(),
      {
        method: "get",
        headers: {},
      },
    )
  ).sources;
}

export async function fetchWindGumbelParameters({
  source,
  lon,
  lat,
  height,
  fromYear,
  toYear,
}: {
  source: WindDataSource;
  lon: number;
  lat: number;
  height: number;
  numberOfDirections?: number;
  fromYear?: number;
  toYear?: number;
}): Promise<GumbelParams> {
  const _lon = lon.toFixed(4);
  const _lat = lat.toFixed(4);

  const params = new URLSearchParams({
    height: height.toFixed(0),
  });

  fromYear && params.set("from_year", fromYear.toFixed(0));
  toYear && params.set("to_year", toYear.toFixed(0));

  const url = `/api/octopus/metocean/wind/gumbel/${source.toLowerCase()}/${_lon}/${_lat}?${params}`;

  return await fetchSchemaWithToken(_GumbelParams, url.toString(), {
    method: "get",
    headers: {},
  });
}

export async function fetchWaveGumbelParameters({
  lon,
  lat,
  source,
}: {
  lon: number;
  lat: number;
  source: WaveDataSource;
}): Promise<GumbelParams> {
  const _lon = lon.toFixed(4);
  const _lat = lat.toFixed(4);

  const url = `/api/octopus/metocean/wave/gumbel/${source.toLowerCase()}/${_lon}/${_lat}`;

  try {
    return await fetchSchemaWithToken(_GumbelParams, url.toString(), {
      method: "get",
      headers: {},
    });
  } catch {
    return {
      loc: null,
      scale: null,
    };
  }
}
