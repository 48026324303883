import React from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { modalTypeOpenAtom } from "state/modal";
import CompareAltIcon from "@icons/14/CompareAlt.svg?react";
import Tooltip from "components/General/Tooltip";
import {
  DISABLED_IN_READ_ONLY_MODE,
  inReadOnlyModeSelector,
} from "state/project";
import { CompareParksModalType } from "components/CompareParksModal/CompareParksModalType";
import SearchWrapper from "components/Search/SearchWrapper";
import { openInNewWindowAtom } from "components/WindowedHOC/state";
import RoundIcon from "components/Design/TopbarNavigation/components/Middle/RoundIcon";
import { MiddleItemWrapper } from "components/Design/TopbarNavigation/components/Middle/style";
import { TourStep } from "components/OnboardingTours/TourStep";
import { useTrackEvent } from "components/OnboardingTours/state";
import { HighlightStep } from "components/OnboardingTours/HighlightWrapper";
import { colors } from "styles/colors";
import { FloatingFocusManager, useFloating, offset } from "@floating-ui/react";
import { Mixpanel } from "mixpanel";

const CompareParksModalButtonV2 = () => {
  const [modalTypeOpen, setModalType] = useRecoilState(modalTypeOpenAtom);
  const setOpenInNewWindow = useSetRecoilState(openInNewWindowAtom);
  const readOnlyMode = useRecoilValue(inReadOnlyModeSelector);
  const trackEvent = useTrackEvent();

  const { refs, floatingStyles, context } = useFloating({
    placement: "bottom",
    middleware: [offset({ mainAxis: 40 })],
  });
  return (
    <SearchWrapper
      title="Compare parks"
      id="search-compare"
      tags={["alternative", "scenario", "base case", "presentation"]}
      icon={<CompareAltIcon />}
      onSelect={() => {
        setModalType({ modalType: CompareParksModalType }),
          Mixpanel.track("Open full screen modal", {
            type: CompareParksModalType,
          });
      }}
    >
      <FloatingFocusManager context={context} modal={false}>
        <TourStep
          tourId="general-intro-tour"
          stepId="compare"
          innerRef={refs.setFloating}
          style={floatingStyles}
        />
      </FloatingFocusManager>
      <HighlightStep
        tourId="general-intro-tour"
        stepId="compare"
        color={colors.blue100}
      >
        <Tooltip
          theme="light"
          id="compare-parks-button"
          text={readOnlyMode ? DISABLED_IN_READ_ONLY_MODE : "Compare parks"}
          disabled={!readOnlyMode}
          position="bottom"
        >
          <MiddleItemWrapper
            active={modalTypeOpen?.modalType === CompareParksModalType}
            disabled={readOnlyMode}
            ref={refs.setReference}
            onClick={() => {
              setModalType((curr) =>
                curr?.modalType !== CompareParksModalType
                  ? { modalType: CompareParksModalType }
                  : undefined,
              );
              setOpenInNewWindow((curr) => ({
                ...curr,
                [CompareParksModalType]: false,
              }));
              trackEvent("clickedCompare");
              Mixpanel.track("Open full screen modal", {
                type: CompareParksModalType,
              });
            }}
          >
            <RoundIcon fillOrStroke="fill">
              <CompareAltIcon />
            </RoundIcon>
            Compare
          </MiddleItemWrapper>
        </Tooltip>
      </HighlightStep>
    </SearchWrapper>
  );
};

export default CompareParksModalButtonV2;
