import {
  atom,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";
import { StandardBox } from "../../styles/boxes/Boxes";
import styled from "styled-components";
import {
  validationWarningsAtom,
  ValidationWarningStateType,
  validationWarningsSelectorFamily,
} from "../../state/validationWarnings";
import MaxSizeExceeded from "./MaxSizeExceeded";
import { OverlappingZones } from "./OverlappingZones";
import { AnchorOutsidePark } from "./AnchorOutsidePark";
import { branchSelectedConfigurationAtomFamily } from "../../state/configuration";
import React, { useCallback, useEffect, useState } from "react";
import {
  ErrorBoundarySilent,
  ScreamOnError,
} from "../ErrorBoundaries/ErrorBoundaryLocal";
import { ErrorBoundary } from "react-error-boundary";
import DifferentIAVoltageTypes from "./DifferentIAVoltageTypes";
import ValidationWarningBox from "./ValidationWarningBox";
import { colors } from "../../styles/colors";
import {
  branchIdSelector,
  organisationIdSelector,
  projectIdSelector,
  useTypedPath,
} from "../../state/pathParams";
import TurbineNotConnected from "./TurbineNotConnected";
import TurbineCableWrongVoltageType from "./TurbineCableWrongVoltageType";
import { recoilFilter } from "../../state/recoil";
import { TurbineOutsidePark } from "./TurbineOutsidePark";
import { mapRefAtom } from "state/map";
import { TurbineOverlap } from "./TurbineOverlap";
import { SubstationOverlap } from "./SubstationOverlap";
import TooShortCables from "./TooShortCables";
import TurbineAndMonopileTypeError from "./TurbineAndMonopileTypeError";
import SubstationNotConnected from "./SubstationNotConnected";
import ExportCableNotConnected from "./ExportCableNotConnected";
import DifferentExportVoltageTypes from "./DifferentExportVoltageTypes";
import { ExportCableLandfallFailed } from "./ExportCableLandfallFailed";
import { TurbineTypeInvalid } from "./TurbineTypeInvalid";
import { FoundationTypeInvalid } from "./FoundationTypeInvalid";
import { CableTypeInvalid } from "./CableTypeInvalid";
import OverlappingParks from "./OverlappingParks";
import { OffshoreSubstationOutsideParkCableCorridor } from "./SubstationOutsideParkCableCorridor";
import SubAreasOutsidePark from "./SubAreasOutsidePark";
import CableCorridorNotConnectedToPark from "./CableCorridorNotConnectedToPark";
import { ExistingTurbinesWithoutPower } from "./ExistingTurbinesWithoutPower";
import SubstationMissingType from "./SubstationMissingType";
import { getParkFeaturesInBranchSelector } from "state/park";
import { SubstationInsideNoCableExclusionZone } from "./SubstationInsideNoCableExclusionZone";
import { ExclusionZoneContainingExcludedFeature } from "./ExclusionZoneContainingExcludedFeature";
import HoleInPark from "./HoleInPark";
import {
  ValidationWarningTypes,
  ValidationSeverity,
  validationWarningSeverity,
  validationWarningSeverityColor,
  validationWarningBadgeSeverityColor,
  validationWarningBadgeSeverityFontColor,
} from "./utils";
import { modalTypeOpenAtom } from "state/modal";
import { ExportSystemInsufficientCapacityWarning } from "./ExportSystemInsufficientCapacity";
import { CableIntersectsSector } from "./CableFreeSector";
import { CableLoops } from "./CableLoops";
import { ExistingTurbineOverlap } from "./ExistingTurbineOverlap";
import ExportCableTooLong from "./ExportCableTooLongWarningError";

const ExclamationWrapper = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
`;

const Badge = styled.div<{ background: string; fontColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: ${({ fontColor }) => fontColor};
  position: absolute;
  top: -0.8rem;
  right: -0.8rem;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  background: ${(p) => p.background};
  z-index: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
`;

const CriticalErrorText = styled.p`
  font-size: 1.4rem;
  font-weight: 500;
  color: white;
  margin-left: 0.4rem;
`;

const RelativeWrapper = styled.div`
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-right: 2rem;
  padding-left: 2rem;
  cursor: pointer;
`;

const Wrapper = styled(StandardBox)<{
  background: string;
  show: boolean;
  animate: boolean;
}>`
  position: relative;
  opacity: ${(p) => (p.show ? 1 : 0.3)};
  height: 0.8em;
  min-width: 0.8em;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem;
  cursor: pointer;
  background: ${(p) => p.background};

  &:hover {
    opacity: ${(p) => (p.show ? 1 : 0.7)};
  }
  transition: opacity 0.2s linear;

  ${(p) => p.animate && "animation: pulse 2s 5;"};

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    10% {
      transform: scale(1);
    }
    20% {
      transform: scale(1.15);
    }
    30% {
      transform: scale(1);
    }
    40% {
      transform: scale(1.15);
    }
    50% {
      transform: scale(1);
    }
  }
`;

export const ShowWarningBoxAtom = atom<boolean>({
  key: "ShowWarningBoxAtom",
  default: false,
});

export type WarningProps = {
  warn: (validation: ValidationWarningStateType) => void;
  remove: (type: ValidationWarningTypes) => void;
};

export const useValidationWarnings = (): WarningProps => {
  const { projectId } = useTypedPath("projectId");
  const setValidationWarnings = useSetRecoilState(
    validationWarningsAtom({ projectId }),
  );

  const warn = useCallback(
    (w: ValidationWarningStateType) => {
      setValidationWarnings((validationWarnings) => {
        return validationWarnings.filter((curr) => curr !== w).concat(w);
      });
    },
    [setValidationWarnings],
  );

  const remove = useCallback(
    (w: ValidationWarningTypes) => {
      setValidationWarnings((validationWarnings) =>
        recoilFilter(validationWarnings, (curr) => curr.type !== w),
      );
    },
    [setValidationWarnings],
  );

  return {
    warn,
    remove,
  };
};

function FatalAblyMessage({
  warning,
}: {
  warning: {
    type: ValidationWarningTypes;
    retryTimestamp?: number;
  };
}) {
  const [countdown, setCountdown] = useState<string>();

  useEffect(() => {
    if (!warning.retryTimestamp) {
      setCountdown(undefined);
      return;
    }

    let run = true;
    const intervalId = setInterval(() => {
      if (!run) return;
      const now = Date.now();
      const timeLeft = warning.retryTimestamp! - now;

      if (timeLeft <= 0) {
        clearInterval(intervalId);
        setCountdown("0s");
      } else {
        const seconds = Math.floor(timeLeft / 1000);
        setCountdown(`${seconds}s`);
      }
    }, 1000);

    return () => {
      run = false;
      clearInterval(intervalId);
    };
  }, [warning.retryTimestamp]);

  return (
    <CriticalErrorText>
      {`Connection lost ${countdown ? "- retry in: " + countdown : ""}`}
    </CriticalErrorText>
  );
}

const ValidationWarnings = () => {
  const organisationId = useRecoilValue(organisationIdSelector);
  const projectId = useRecoilValue(projectIdSelector);
  const branchId = useRecoilValue(branchIdSelector);
  const validationWarnings = useRecoilValue(
    validationWarningsSelectorFamily({
      organisationId: organisationId ?? "",
      projectId: projectId ?? "",
    }),
  );
  const allValidationWarnings = useRecoilValue(
    validationWarningsAtom({ projectId: projectId ?? "" }),
  );
  const [showBox, setShowBox] = useRecoilState(ShowWarningBoxAtom);
  const [clicked, setClicked] = useState(false);

  const map = useRecoilValue(mapRefAtom);

  const someDismissedButCouldBeShown =
    validationWarnings.length !== allValidationWarnings.length;

  const props = useValidationWarnings();

  const configurationLoadable = useRecoilValueLoadable(
    branchSelectedConfigurationAtomFamily({ projectId, branchId }),
  );

  const modalTypeOpen = useRecoilValue(modalTypeOpenAtom);

  const configuration =
    configurationLoadable.state === "hasValue"
      ? configurationLoadable.contents
      : undefined;

  const highestSeverity = validationWarnings
    .map((w) => validationWarningSeverity[w.type])
    .reduce((a, e) => Math.max(a, e), 0 as ValidationSeverity);

  const color =
    validationWarnings.length > 0
      ? validationWarningSeverityColor[highestSeverity]
      : colors.secondaryText;

  const badgeColor =
    validationWarnings.length > 0
      ? validationWarningBadgeSeverityColor[highestSeverity]
      : colors.secondaryText;

  const badgeFontColor =
    validationWarnings.length > 0
      ? validationWarningBadgeSeverityFontColor[highestSeverity]
      : colors.secondaryText;

  const showFatalError = validationWarnings.some(
    (w) => w.type === ValidationWarningTypes.FatalGeneral,
  );

  const showFataAblyLostError = validationWarnings.find(
    (w) => w.type === ValidationWarningTypes.FatalAblyLost,
  );
  const parkIdsInBranch = useRecoilValue(
    getParkFeaturesInBranchSelector({ branchId: branchId ?? "" }),
  )
    .map((park) => park.id)
    .filter(Boolean);

  if (!configuration && configurationLoadable.state !== "hasError") return null;

  if (!map || modalTypeOpen) return null;

  return (
    <>
      <ErrorBoundary
        FallbackComponent={ErrorBoundarySilent}
        onError={ScreamOnError}
      >
        {
          //Show Error from other parks even if none is selected
          parkIdsInBranch.map((parkId) => (
            <React.Fragment key={parkId}>
              <HoleInPark parkId={parkId} {...props} />
              <SubstationInsideNoCableExclusionZone
                parkId={parkId}
                {...props}
              />
              <ExclusionZoneContainingExcludedFeature
                parkId={parkId}
                {...props}
              />
              <OverlappingZones parkId={parkId} {...props} />
              <AnchorOutsidePark parkId={parkId} {...props} />
              <DifferentIAVoltageTypes parkId={parkId} {...props} />
              <TurbineCableWrongVoltageType parkId={parkId} {...props} />
              <TurbineNotConnected parkId={parkId} {...props} />
              <SubstationNotConnected parkId={parkId} {...props} />
              <SubstationMissingType parkId={parkId} {...props} />
              <ExportCableNotConnected parkId={parkId} {...props} />
              <ExportCableTooLong parkId={parkId} {...props} />
              <DifferentExportVoltageTypes parkId={parkId} {...props} />
              <ExportCableLandfallFailed parkId={parkId} {...props} />
              <TooShortCables parkId={parkId} {...props} />
              <TurbineAndMonopileTypeError parkId={parkId} {...props} />
              <TurbineOverlap parkId={parkId} map={map} {...props} />
              <ExistingTurbineOverlap />
              <TurbineOutsidePark parkId={parkId} map={map} {...props} />
              <CableCorridorNotConnectedToPark parkId={parkId} {...props} />
              <OffshoreSubstationOutsideParkCableCorridor
                parkId={parkId}
                map={map}
                {...props}
              />
              <SubstationOverlap parkId={parkId} map={map} {...props} />
              <SubAreasOutsidePark parkId={parkId} {...props} />
              <ExportSystemInsufficientCapacityWarning
                parkId={parkId}
                {...props}
              />
              <CableIntersectsSector parkId={parkId} {...props} />
              <CableLoops parkId={parkId} {...props} />
            </React.Fragment>
          ))
        }
        <MaxSizeExceeded {...props} />
        <CableTypeInvalid map={map} {...props} />
        <TurbineTypeInvalid map={map} {...props} />
        <FoundationTypeInvalid map={map} {...props} />
        <OverlappingParks {...props} />
        <ExistingTurbinesWithoutPower map={map} {...props} />
      </ErrorBoundary>
      {(someDismissedButCouldBeShown || validationWarnings.length > 0) && (
        <RelativeWrapper
          onClick={() => {
            setShowBox(!showBox);
            setClicked(true);
          }}
        >
          <Wrapper
            background={color}
            show={showBox || validationWarnings.length > 0}
            animate={!clicked && validationWarnings.length > 0}
          >
            {!showFatalError && !showFataAblyLostError && (
              <>
                <ExclamationWrapper>!</ExclamationWrapper>
                <Badge background={badgeColor} fontColor={badgeFontColor}>
                  {" "}
                  {validationWarnings.length}
                </Badge>
              </>
            )}
            {showFatalError && (
              <ExclamationWrapper>
                !
                <CriticalErrorText>
                  System not responding correctly
                </CriticalErrorText>
              </ExclamationWrapper>
            )}

            {showFataAblyLostError && (
              <ExclamationWrapper>
                !
                <FatalAblyMessage warning={showFataAblyLostError} />
              </ExclamationWrapper>
            )}
          </Wrapper>
          {showBox && <ValidationWarningBox close={() => setShowBox(false)} />}
        </RelativeWrapper>
      )}
    </>
  );
};

export default ValidationWarnings;
