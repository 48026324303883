import { useCallback } from "react";
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import { savingConfigurationInProgressAtom } from "../state/configuration";
import { initializeAndSet } from "../components/Comments/hooks/useReplyReactionCrud";
import { cableTypeAtomFamily } from "../state/cableType";
import {
  createProjectCableType,
  CableType,
  updateProjectCableType,
  deleteProjectCableType,
} from "../services/cableTypeService";
import { projectIdSelector } from "../state/pathParams";
import { scream } from "utils/sentry";

const useCableTypeCrud = () => {
  const projectId = useRecoilValue(projectIdSelector);
  const setIsAutoSaving = useSetRecoilState(savingConfigurationInProgressAtom);
  const setCurrentCableTypes = useSetRecoilState(
    cableTypeAtomFamily({ projectId }),
  );

  const create = useCallback(
    (cableType: Partial<CableType>) => {
      if (!projectId) throw scream("Missing projectId");

      setIsAutoSaving(true);
      return createProjectCableType(projectId, cableType)
        .then((cable) => {
          setCurrentCableTypes((cur) => [...cur, cable]);
          return cable;
        })
        .finally(() => setIsAutoSaving(false));
    },
    [projectId, setCurrentCableTypes, setIsAutoSaving],
  );

  const update = useCallback(
    (cableType: CableType) => {
      if (!projectId) throw scream("Missing projectId");

      setIsAutoSaving(true);
      setCurrentCableTypes((cur) =>
        [...cur].map((c) =>
          c.id === cableType.id ? { ...c, ...cableType } : c,
        ),
      );
      return updateProjectCableType(projectId, cableType).finally(() =>
        setIsAutoSaving(false),
      );
    },
    [projectId, setCurrentCableTypes, setIsAutoSaving],
  );
  const deleteCable = useCallback(
    (cableId: string) => {
      if (!projectId) throw scream("Missing projectId");

      setIsAutoSaving(true);
      setCurrentCableTypes((cur) => [...cur].filter((c) => c.id !== cableId));
      return deleteProjectCableType(projectId, cableId).finally(() =>
        setIsAutoSaving(false),
      );
    },
    [projectId, setCurrentCableTypes, setIsAutoSaving],
  );

  const updateLocal = useRecoilCallback(
    ({ set, snapshot }) =>
      (projectId: string, cableType: CableType) => {
        return initializeAndSet(
          snapshot,
          set,
          cableTypeAtomFamily({ projectId }),
          (cur) => {
            const isUpdate = cur.some((c) => c.id === cableType.id);
            return isUpdate
              ? [...cur].map((c) =>
                  c.id === cableType.id ? { ...c, ...cableType } : c,
                )
              : [...cur, cableType];
          },
        );
      },
    [],
  );

  return { create, update, deleteCable, updateLocal };
};

export default useCableTypeCrud;
