import { useEffect } from "react";
import { selectorFamily, useRecoilValue } from "recoil";
import { getAllTurbinesInBranchSelector } from "state/layout";
import { useValidationWarnings } from "./ValidationWarnings";
import { turbineSourceId } from "components/Mapbox/constants";
import { allSimpleTurbineTypesSelector } from "state/turbines";
import { ValidationWarningTypes } from "./utils";
import { branchIdSelector } from "state/pathParams";

export const turbineTypeInvalidWarningSelector = selectorFamily<
  | { type: ValidationWarningTypes.TurbineTypeInvalid; featureIds: string[] }
  | undefined,
  {
    branchId: string;
  }
>({
  key: "turbineTypeInvalidWarningSelector",
  get:
    ({ branchId }) =>
    ({ get }) => {
      const turbineTypes = get(allSimpleTurbineTypesSelector);
      const allProjectTurbines = get(
        getAllTurbinesInBranchSelector({ branchId }),
      );
      const invalidTurbineTypeReferences = allProjectTurbines.filter((f) =>
        turbineTypes.every((tt) => tt.id !== f.properties.turbineTypeId),
      );
      return invalidTurbineTypeReferences.length === 0
        ? undefined
        : {
            type: ValidationWarningTypes.TurbineTypeInvalid,
            featureIds: invalidTurbineTypeReferences.map((t) => t.id),
          };
    },
});

export const TurbineTypeInvalid = ({
  map,
  source = turbineSourceId,
}: {
  map: mapboxgl.Map;
  source?: string;
}) => {
  const branchId = useRecoilValue(branchIdSelector);
  const invalidTurbineTypeReferences = useRecoilValue(
    turbineTypeInvalidWarningSelector({ branchId: branchId ?? "" }),
  );

  const { warn, remove } = useValidationWarnings();

  useEffect(() => {
    if (!invalidTurbineTypeReferences) return;

    warn(invalidTurbineTypeReferences);
    if (map.getSource(source))
      for (const id of invalidTurbineTypeReferences.featureIds)
        map.setFeatureState({ source, id }, { overlap: true });
    return () => {
      remove(ValidationWarningTypes.TurbineTypeInvalid);
      if (map.getSource(source))
        for (const id of invalidTurbineTypeReferences.featureIds)
          map.removeFeatureState({ source, id }, "overlap");
    };
  }, [invalidTurbineTypeReferences, map, remove, source, warn]);

  return null;
};
