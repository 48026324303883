import { listUsersInOrganisation, Organisation } from "./service";
import { atom, atomFamily, selector, selectorFamily } from "recoil";
import { OrganisationUser } from "../../types/customer";
import { organisationIdSelector } from "../../state/pathParams";
import { listOrganisations } from "services/customerAPI";
import { z } from "zod";
import { asyncInit } from "state/effects";

export const organisationsState = atom<Organisation[]>({
  key: "organisationsState",
  default: selector<Organisation[]>({
    key: "organisationsSelector",
    get: () => listOrganisations(),
  }),
});

export const customerIdToOrganisationIdSelectorFamily = selectorFamily<
  string | undefined,
  string | undefined
>({
  key: "customerIdToOrganisationIdSelectorFamily",
  get:
    (customerId) =>
    ({ get }) => {
      if (!customerId) {
        return undefined;
      }

      return get(organisationsState).find((org) =>
        org.teams.includes(customerId),
      )?.id;
    },
});

export const currentOrganisationSelector = selector<Organisation | undefined>({
  key: "currentOrganisationSelector",
  get: async ({ get }) => {
    const orgs = get(organisationsState);
    const organisationId = get(organisationIdSelector);
    return orgs.find((org) => org.id === organisationId);
  },
});

export const usersInOrganisationState = atomFamily<OrganisationUser[], string>({
  key: "usersInOrganisationState",
  effects: (orgId) => [asyncInit(() => listUsersInOrganisation(orgId))],
});

export const orgTabNames = [
  "Projects",
  "Members",
  "Library",
  "Portfolio",
  "Integration",
  "Learn",
] as const;

export const memberTabNames = ["Members", "Groups", "Pending"] as const;

export const _OrgTabName = z.enum(orgTabNames);

export type OrgTabName = (typeof orgTabNames)[number];

export const _MemberTabName = z.enum(memberTabNames);

export type MemberTabName = (typeof memberTabNames)[number];

export const selectedOrgTabState = atom<OrgTabName | undefined>({
  key: "selectedOrgTabState",
  default: undefined,
});

export const selectedMemberTabState = atom<MemberTabName | undefined>({
  key: "selectedMemeberTabState",
  default: undefined,
});
