import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { branchIdSelector, projectIdSelector } from "../../../state/pathParams";
import {
  filteredThreadsSelectorFamily,
  showCommentIconsInMapState,
  showResolvedCommentsAtom,
} from "../state";
import Comment from "./Comment";
import React, { Suspense, useEffect, useMemo, useState, useRef } from "react";
import {
  ANIMATION_DURATION,
  LeftModalContainer,
  CONTAINER_MIN_WIDTH,
} from "../../Design/ProjectHistory/styles";
import Button from "../../General/Button";
import { MenuFrame } from "../../MenuPopup/CloseableMenuPopup";
import { SkeletonText } from "../../Loading/Skeleton";
import { spaceLarge } from "../../../styles/space";
import { Column } from "../../General/Layout";
import { useHorizontalResize } from "../../ResizeBar/ResizeBarVertical";
import {
  LeftModalMenuTypes,
  leftModalMenuOpenStateAtom,
} from "components/LowerLeftV2/state";

const ButtonContainer = styled.div`
  padding-top: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.2rem;
  margin-top: auto;
  flex-wrap: wrap;
`;

export default function CommentsLeftModal() {
  const projectNodeId = useRecoilValue(projectIdSelector);
  const [hide, setHide] = useState(true);
  const frameRef = useRef<HTMLDivElement>(null);
  useHorizontalResize(frameRef, "--left-menu-width");
  const [showSmall, setShowSmall] = useState(true);
  const [leftModalMenuOpen, setLeftModalMenuOpen] = useRecoilState(
    leftModalMenuOpenStateAtom,
  );
  const [showResolved, setShowResolved] = useRecoilState(
    showResolvedCommentsAtom,
  );
  const [showCommentsInMap, setShowCommentsInMap] = useRecoilState(
    showCommentIconsInMapState,
  );

  useEffect(() => {
    if (leftModalMenuOpen === LeftModalMenuTypes.Comment) {
      setHide(false);
      const delay = setTimeout(() => setShowSmall(false), 30);
      return () => clearTimeout(delay);
    } else {
      const delay = setTimeout(() => {
        setHide(true);
        setShowSmall(true);
      }, ANIMATION_DURATION * 985);
      return () => clearTimeout(delay);
    }
  }, [leftModalMenuOpen]);

  if (hide) return null;

  return (
    <LeftModalContainer
      open={leftModalMenuOpen === LeftModalMenuTypes.Comment && !showSmall}
    >
      <MenuFrame
        title="Comments"
        onExit={() => {
          setLeftModalMenuOpen(undefined);
        }}
        style={{
          flex: 1,
          width: "var(--left-menu-width)",
          minWidth: CONTAINER_MIN_WIDTH,
          maxWidth: "40vw",
          resize: "horizontal",
        }}
        ref={frameRef}
      >
        <Suspense fallback={<SkeletonText style={{ margin: spaceLarge }} />}>
          {projectNodeId && <CommentsLeftModalInner nodeId={projectNodeId} />}
        </Suspense>
        <ButtonContainer>
          <Button
            text={
              showCommentsInMap
                ? "Hide comments in map"
                : "Show comments in map"
            }
            onClick={() => setShowCommentsInMap(!showCommentsInMap)}
            buttonType="secondary"
          />
          <Button
            text={showResolved ? "Hide resolved" : "Show resolved"}
            onClick={() => setShowResolved(!showResolved)}
            buttonType="secondary"
          />
        </ButtonContainer>
      </MenuFrame>
    </LeftModalContainer>
  );
}

function CommentsLeftModalInner({ nodeId }: { nodeId: string }) {
  const branchId = useRecoilValue(branchIdSelector) ?? "";
  const { threads, resolvedAndNotResolvedThreads } = useRecoilValue(
    filteredThreadsSelectorFamily({ nodeId, branchId }),
  );

  const showResolved = useRecoilValue(showResolvedCommentsAtom);

  const commentsToRender = useMemo(() => {
    return showResolved ? resolvedAndNotResolvedThreads : threads;
  }, [showResolved, resolvedAndNotResolvedThreads, threads]);

  return (
    <>
      <Column style={{ flex: 1, gap: spaceLarge, overflowY: "auto" }}>
        {commentsToRender.length === 0 ? (
          <p>
            No comments to show — click on a feature in the map to add a
            comment.
          </p>
        ) : (
          commentsToRender.map((t) => (
            <Comment nodeId={nodeId} key={t.threadId} thread={t} />
          ))
        )}
      </Column>
    </>
  );
}
