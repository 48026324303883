/// <reference types="vite-plugin-svgr/client" />
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import StatisticsIcon from "@icons/24/Statistics.svg?react";
import DeleteIcon from "@icons/24/Bin.svg?react";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";
import AddIcon from "@icons/24/Add.svg?react";
import {
  createConfiguration,
  deleteConfiguration,
  updateConfiguration,
  Configuration,
  createConfigurationWithValues,
} from "../../../../services/configurationService";
import {
  analysisConfigurationUsageAtomFamily,
  configurationTempName,
  configurationsAtomFamily,
  fetchAnalysisConfigurationUsage,
  savingConfigurationInProgressAtom,
} from "../../../../state/configuration";
import { useTypedPath } from "../../../../state/pathParams";
import { initializeAndSet } from "../../../Comments/hooks/useReplyReactionCrud";
import { AnalysisSettingsInner } from "../../../ConfigurationModal/AnalysisSettings";
import { selectedMenuItemState } from "../../Shared/state";
import { modalTypeOpenAtom } from "../../../../state/modal";
import { useToast } from "hooks/useToast";
import { SettingsSubMenuProp } from "components/SettingsV2/Shared/types";
import useConfigurationCrud from "hooks/useConfigurationCrud";
import { IconREMSize } from "styles/typography";
import Earth from "@icons/14/Earth.svg?react";
import {
  analysisResourceWithAccessOnNodeState,
  libraryTabState,
} from "components/Organisation/Library/state";
import Library from "@icons/24/Library.svg?react";
import {
  editorAccessProjectSelector,
  orgAnalysisManageAccessSelector,
} from "state/user";
import { selectedOrgTabState } from "components/Organisation/state";
import { useLocation, useNavigate } from "react-router-dom";
import { useDuplicateAnalysisToProject } from "components/SettingsV2/FeatureSettings/Data/useDuplicateToProject";
import { useDuplicateAnalysisToLibrary } from "components/SettingsV2/FeatureSettings/Data/useDuplicateToLibrary";
import Button from "components/General/Button";
import { Anchor } from "components/General/Anchor";
import AnalysisModal from "components/Organisation/OrganisationRightSide/content/ResourceContent/AnalysisModal";
import useNodeAnalysisResourcesCrud from "components/Organisation/OrganisationRightSide/content/NodeContent/useNodeAnalysisResourcesCrud";
import { SettingsHeader } from "components/SettingsV2/Shared/styles";

export const ANALYSIS_MENU_ID = "analysis";

const AnalysisSettingLibrary = ({
  configuration,
}: {
  configuration: Configuration;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const setOrgtab = useSetRecoilState(selectedOrgTabState);
  const { organisationId } = useTypedPath("organisationId");

  const { duplicateToProject, isLoading: isLoadingDuplicate } =
    useDuplicateAnalysisToProject();
  const setSelectedTab = useSetRecoilState(libraryTabState({ organisationId }));
  const orgAnalysisManageAccess = useRecoilValue(
    orgAnalysisManageAccessSelector,
  );
  return (
    <>
      <SettingsHeader>
        {orgAnalysisManageAccess && (
          <Button
            buttonType="text"
            text="Edit analysis in Library"
            onClick={() => {
              setOrgtab("Library");
              setSelectedTab("analysis");
              navigate(`/organisation/${organisationId}${location.search}`);
            }}
            style={{ marginLeft: "auto" }}
          />
        )}
        <Button
          text="Duplicate as project analysis"
          onClick={() => duplicateToProject(configuration)}
          disabled={isLoadingDuplicate}
        />
      </SettingsHeader>
      <AnalysisSettingsInner
        configuration={configuration}
        configurationName={configuration.name ?? "Default"}
        readOnly={true}
        isLibraryResource={true}
      />
    </>
  );
};

export default function useAnalysisConfiguration() {
  const { organisationId, projectId } = useTypedPath(
    "organisationId",
    "projectId",
  );
  const { error: showErrorToast } = useToast();
  const modalTypeOpen = useRecoilValue(modalTypeOpenAtom);
  const [configurations, setConfigurations] = useRecoilState(
    configurationsAtomFamily({ nodeId: projectId }),
  );
  const [isAutoSaving, setIsAutoSaving] = useRecoilState(
    savingConfigurationInProgressAtom,
  );
  const [menuSelection, setMenuSelection] = useRecoilState(
    selectedMenuItemState({ menuId: ANALYSIS_MENU_ID, projectId }),
  );
  const libraryAnalysis = useRecoilValue(
    analysisResourceWithAccessOnNodeState({ nodeId: projectId }),
  );
  const orgAnalysisManageAccess = useRecoilValue(
    orgAnalysisManageAccessSelector,
  );
  const editorAccessProject = useRecoilValue(editorAccessProjectSelector);
  const navigate = useNavigate();
  const location = useLocation();
  const setOrgtab = useSetRecoilState(selectedOrgTabState);
  const { duplicateToProject } = useDuplicateAnalysisToProject();
  const { duplicateToLibrary } = useDuplicateAnalysisToLibrary();

  useEffect(() => {
    if (!menuSelection) {
      const firstItem = configurations[0];
      if (firstItem) {
        setMenuSelection(firstItem.id);
      }
    }
  }, [configurations, menuSelection, setMenuSelection]);

  useEffect(() => {
    if (
      (modalTypeOpen as any)?.["metadata"]?.["selectedMenuId"] === "analysis" &&
      (modalTypeOpen as any)?.["metadata"]?.["selectedConfigId"]
    ) {
      setMenuSelection((modalTypeOpen as any)["metadata"]["selectedConfigId"]);
    }
  }, [modalTypeOpen, setMenuSelection]);

  const _create = useCallback(async () => {
    if (!projectId) return;
    setIsAutoSaving(true);
    try {
      const newConfig = await createConfiguration(projectId);

      setConfigurations((cur) => [...cur, newConfig]);
      setMenuSelection(newConfig.id);
    } catch {}
    setIsAutoSaving(false);
  }, [projectId, setConfigurations, setIsAutoSaving, setMenuSelection]);

  const _delete = useRecoilCallback(
    ({ set, snapshot }) =>
      async (id: string) => {
        if (!projectId) return;
        setIsAutoSaving(true);
        const usage = await fetchAnalysisConfigurationUsage(projectId, id);
        initializeAndSet(
          snapshot,
          set,
          analysisConfigurationUsageAtomFamily({
            nodeId: projectId,
            analysisConfigurationId: id,
          }),
          usage,
        );

        if (usage.length > 0) {
          showErrorToast(
            `Configuration is used in ${usage.length} branch(es) and can't be deleted`,
            {
              timeout: 6000,
            },
          );
        } else {
          try {
            const res = await deleteConfiguration(projectId, id);
            if ([204, 409].includes(res.status)) {
              setConfigurations((cur) => {
                return [...cur].filter((c) => c.id !== id);
              });
            } else {
              const body = await res.text();
              throw new Error(body);
            }
          } catch (error) {
            if (error instanceof Error) {
              const text = error.message;
              showErrorToast(text, {
                timeout: 4000,
              });
            }
          }
        }

        setIsAutoSaving(false);
      },
    [projectId, setConfigurations, setIsAutoSaving, showErrorToast],
  );

  const _duplicate = useCallback(
    async (id: string) => {
      if (!projectId) return;
      const configToDuplicate = configurations.find(
        (config) => config.id === id,
      );
      if (!configToDuplicate) {
        return;
      }
      const configClone = JSON.parse(
        JSON.stringify(configToDuplicate),
      ) as Configuration;

      setIsAutoSaving(true);
      try {
        const updatedConfig = {
          ...configClone,
          name: configToDuplicate.name!.concat(" copy"),
        };
        const newConfig = await createConfigurationWithValues(
          projectId,
          updatedConfig,
        );
        setConfigurations((cur) => [...cur, newConfig]);
        setMenuSelection(updatedConfig.id);
      } catch {
      } finally {
        setIsAutoSaving(false);
      }
    },
    [
      projectId,
      configurations,
      setIsAutoSaving,
      setConfigurations,
      setMenuSelection,
    ],
  );

  const { save, saveName, saveTempNameToLocal } = useConfigurationCrud();

  const tempConfigNames = useRecoilValue(
    configurationTempName({ nodeId: projectId }),
  );

  const onUnmount = useCallback(() => {
    configurations.forEach((c) => {
      if (saveTempNameToLocal) {
        saveTempNameToLocal(c.id);
      }
    });
  }, [configurations, saveTempNameToLocal]);

  const configSubMenus: SettingsSubMenuProp[] = useMemo(() => {
    const projectConfigurationList = configurations.map((c) => {
      const configName = tempConfigNames[c.id] ?? c.name;

      let dotMenuOptions:
        | {
            title: string;
            onSelect: (id: string) => void;
            icon: ReactElement;
          }[]
        | undefined = [];

      if (editorAccessProject) {
        dotMenuOptions.push({
          title: "Delete",
          onSelect: _delete,
          icon: <DeleteIcon />,
        });
        dotMenuOptions.push({
          title: "Duplicate",
          onSelect: _duplicate,
          icon: <DuplicateIcon />,
        });
      }
      if (orgAnalysisManageAccess) {
        dotMenuOptions.push({
          title: "Duplicate to library",
          onSelect: () => duplicateToLibrary(projectId, c),
          icon: <DuplicateIcon />,
        });
      }

      if (dotMenuOptions.length === 0) dotMenuOptions = undefined;

      return {
        id: c.id,
        label: configName ?? "Default configuration",
        loading: isAutoSaving,
        content: (
          <div style={{ height: "100%", position: "relative" }}>
            <AnalysisSettingsInner
              save={save}
              saveName={saveName}
              onUnmount={onUnmount}
              configuration={c}
              configurationName={configName ?? "Default configuration"}
            />
          </div>
        ),
        onDuplicate: _duplicate,
        onChangeName: async (newName: string) => {
          setIsAutoSaving(true);
          try {
            await updateConfiguration(projectId, { ...c, name: newName });
            setConfigurations((cur) => {
              return [...cur].map((config) => {
                if (config.id === c.id) {
                  return { ...config, name: newName };
                }
                return config;
              });
            });
          } catch {
            showErrorToast("Could not save configuration, please try again", {
              timeout: 4000,
            });
          } finally {
            setIsAutoSaving(false);
          }
        },
        dotMenuOptions,
      };
    });

    const libraryConfigurationList = libraryAnalysis
      .map((c) => c.config)
      .map((c) => {
        let dotMenuOptions:
          | {
              title: string;
              onSelect: (id: string) => void;
              icon: ReactElement;
            }[]
          | undefined = [];

        if (orgAnalysisManageAccess) {
          dotMenuOptions.push({
            title: "Edit in library",
            onSelect: () => {
              setOrgtab("Library");
              navigate(`/organisation/${organisationId}${location.search}`);
            },
            icon: <Library />,
          });
        }
        if (editorAccessProject) {
          dotMenuOptions.push({
            title: "Duplicate to project",
            onSelect: () => duplicateToProject(c),
            icon: <DuplicateIcon />,
          });
        }
        if (dotMenuOptions.length === 0) dotMenuOptions = undefined;

        return {
          id: c.id,
          label: c.name ?? "Default configuration",
          loading: isAutoSaving,
          content: (
            <div style={{ height: "100%", position: "relative" }}>
              <AnalysisSettingLibrary configuration={c} />
            </div>
          ),
          onChangeName: async (newName: string) => {
            setIsAutoSaving(true);
            try {
              await updateConfiguration(projectId, { ...c, name: newName });
              setConfigurations((cur) => {
                return [...cur].map((config) => {
                  if (config.id === c.id) {
                    return { ...config, name: newName };
                  }
                  return config;
                });
              });
            } catch {
              showErrorToast("Could not save configuration, please try again", {
                timeout: 4000,
              });
            } finally {
              setIsAutoSaving(false);
            }
          },
          dotMenuOptions,
        };
      });

    const libraryAnalysisMenu: SettingsSubMenuProp = {
      title: "Library analysis",
      items: libraryConfigurationList,
      create: {
        type: "element",
        element: <AddAnalysisResourceToNode nodeId={projectId} />,
      },
      icon: (
        <IconREMSize height={1.4} width={1.4}>
          <Library />
        </IconREMSize>
      ),
    };

    const projectAnalysisMenu: SettingsSubMenuProp = {
      title: "Project analysis",
      items: projectConfigurationList,
      create: {
        title: "New configuration",
        onCreate: _create,
        disabled: isAutoSaving,
        type: "action",
      },
      icon: (
        <IconREMSize height={1.4} width={1.4}>
          <Earth />
        </IconREMSize>
      ),
    };

    return [libraryAnalysisMenu, projectAnalysisMenu];
  }, [
    showErrorToast,
    _delete,
    _duplicate,
    _create,
    configurations,
    isAutoSaving,
    projectId,
    setConfigurations,
    setIsAutoSaving,
    save,
    orgAnalysisManageAccess,
    editorAccessProject,
    setOrgtab,
    navigate,
    location,
    duplicateToProject,
    duplicateToLibrary,
    libraryAnalysis,
    organisationId,
    onUnmount,
    saveName,
    tempConfigNames,
  ]);

  const analysisConfiguration = useMemo(() => {
    return {
      id: ANALYSIS_MENU_ID,
      label: "Analysis",
      icon: <StatisticsIcon />,
      submenus: configSubMenus,
    };
  }, [configSubMenus]);

  return analysisConfiguration;
}

function AddAnalysisResourceToNode({ nodeId }: { nodeId: string }) {
  const analysisResources = useRecoilValue(
    analysisResourceWithAccessOnNodeState({ nodeId }),
  );
  const ref = useRef<HTMLDivElement>(null);
  const { addOrUpdate: addOrUpdateAnalysis } = useNodeAnalysisResourcesCrud();
  const [showAddAnalysis, setShowAddAnalysis] = useState(false);
  const orgAnalysisManageAccess = useRecoilValue(
    orgAnalysisManageAccessSelector,
  );

  if (!orgAnalysisManageAccess) return <></>;

  return (
    <div ref={ref} style={{ position: "relative", marginLeft: "auto" }}>
      <Button
        buttonType="secondary"
        onClick={() => setShowAddAnalysis(true)}
        icon={<AddIcon />}
        size="small"
      />
      {showAddAnalysis && (
        <Anchor baseRef={ref} basePlace={"topRight"} floatPlace={"topLeft"}>
          <AnalysisModal
            onSave={async (analysis) => {
              await Promise.all(
                analysis.map((t) => addOrUpdateAnalysis(nodeId, t)),
              );

              setShowAddAnalysis(false);
            }}
            existingAnalysis={analysisResources.map((c) => c.config.id)}
          />
        </Anchor>
      )}
    </div>
  );
}
