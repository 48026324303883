import { useRecoilValue } from "recoil";
import { MoveNodeGroupAccess } from "components/Projects/useExplainNodeMove";
import TeamMeeting from "@icons/24/TeamMeeting.svg?react";
import AlertCircle from "@icons/24/AlertCircle.svg?react";
import KeyboardArrowRight from "@icons/24/KeyboardArrowRight.svg?react";
import { Row } from "components/General/Layout";
import { SVGWrapper } from "@icons/svgUtils";
import { ValidationAlert } from "components/ValidationWarnings/ValidationAlert";
import { organisationGroupsState } from "../../Groups/state";
import { organisationIdSelector } from "state/pathParams";
import { randomPastelColor } from "utils/image";
import { ContentImageWrapper } from "../../Image/ImageWrapper";
import { VerticalDivider } from "components/General/VerticalDivider";
import { ValidationDescription, AccessText, Text } from "../style";

export function GroupsWarning({
  groupAccess,
}: {
  groupAccess: MoveNodeGroupAccess[];
}) {
  const organisationId = useRecoilValue(organisationIdSelector);
  const groups = useRecoilValue(organisationGroupsState({ organisationId }));

  return (
    <ValidationAlert
      title={`${groupAccess.length} group${groupAccess.length > 1 ? "s" : ""}`}
      renderDecription={() => (
        <ValidationDescription>
          {groupAccess.map((groupAccess) => {
            const g = groups.find((g) => g.id === groupAccess.group_id);
            if (!g) return <></>;
            const color = randomPastelColor(g.id);
            return (
              <Row
                key={groupAccess.group_id}
                style={{ gap: "0.8rem", alignItems: "center" }}
              >
                <Row style={{ gap: "0.4rem", alignItems: "center" }}>
                  <ContentImageWrapper
                    color={color.backgroundColor}
                    svgColor={color.textColor}
                    size={2.2}
                  >
                    <TeamMeeting />
                  </ContentImageWrapper>
                  <Text>{g.name}</Text>
                </Row>

                <VerticalDivider height={1.4} />
                <Row style={{ gap: "0.4rem", alignItems: "center" }}>
                  <AccessText>{groupAccess.from}</AccessText>
                  <SVGWrapper size={1.2}>
                    <KeyboardArrowRight />
                  </SVGWrapper>
                  <AccessText>{groupAccess.to}</AccessText>
                  {groupAccess.to === "No access" && <AlertCircle />}
                </Row>
              </Row>
            );
          })}
        </ValidationDescription>
      )}
      type="info"
    />
  );
}
