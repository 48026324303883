import * as turf from "@turf/turf";
import { MultiPolygon, Polygon } from "geojson";
import { selector, selectorFamily } from "recoil";
import { v4 as uuidv4 } from "uuid";
import {
  DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
  EXCLUSION_ZONE_COLOR,
  SUB_AREA_COLOR,
  SUB_AREA_PROPERTY_TYPE,
} from "../constants/division";
import {
  AnchorFeature,
  CableChainFeature,
  CableFeature,
  ExclusionZoneFeature,
  ExportCableFeature,
  SubAreaFeature,
  MooringLineFeature,
  ParkFeature,
  SubstationFeature,
  TurbineFeature,
  exclusionDomainPack,
} from "../types/feature";
import { lineInPolygon, pointInPolygon } from "../utils/geometry";
import { isExclusionDivision, isPark, isSubArea } from "../utils/predicates";
import {
  projectFeaturesInBranchSelectorFamily,
  projectFeaturesSelector,
} from "./../components/ProjectElements/state";
import {
  getCablesSelectorFamily,
  getExportCablesSelectorFamily,
  getSubstationsSelectorFamily,
} from "./cable";
import { labelledCableChainsFeaturesSelectorFamily } from "./cableEdit";
import {
  getAnchorsSelectorFamily,
  getMooringLinesSelector,
  getTurbinesInBranchSelectorFamily,
  getTurbinesSelectorFamily,
} from "./layout";
import {
  getParkFeatureInBranchSelector,
  getParkFeatureSelectorFamily,
  inPark,
} from "./park";
import {
  currentSelectedProjectFeatures,
  currentSelectionArrayAtom,
} from "./selection";
import { parkIdSelector } from "./pathParams";
import { multiFeatureToFeatures } from "utils/geojson/utils";

export const makeExclusionDivisonToExclusionDivisionPolygon = (
  f: ExclusionZoneFeature[],
) => f.map(multiFeatureToFeatures).flat() as ExclusionZoneFeature[];

export const makeExclusionZone = (
  polygon: Polygon | MultiPolygon,
): ExclusionZoneFeature => {
  const id = uuidv4();
  return {
    type: "Feature",
    id,
    geometry: polygon,
    properties: {
      id,
      type: DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
      color: EXCLUSION_ZONE_COLOR,
      domain: exclusionDomainPack({
        turbine: true,
        cable: true,
        substation: true,
        anchor: true,
      }),
    },
  };
};

export const makeSubArea = (
  polygon: Polygon,
  parkId: string,
): SubAreaFeature => {
  const id = uuidv4();
  return {
    type: "Feature",
    id,
    geometry: polygon,
    properties: {
      id,
      type: SUB_AREA_PROPERTY_TYPE,
      color: SUB_AREA_COLOR,
      parentIds: [parkId],
    },
  };
};

export const allRawDivisionFeaturesSelectorFamily = selector<{
  subAreas: SubAreaFeature[];
  exclusionZones: ExclusionZoneFeature[];
}>({
  key: "allRawDivisionFeaturesSelectorFamily",
  get: ({ get }) => {
    const allFeatures = get(projectFeaturesSelector);
    const exclusionZones = allFeatures.filter(isExclusionDivision);
    const subAreas = allFeatures.filter(isSubArea);
    return {
      subAreas,
      exclusionZones,
    };
  },
});

export const exclusionZoneSelector = selector({
  key: "exclusionZoneSelector",
  get: async ({ get }) => {
    return get(projectFeaturesSelector).filter(isExclusionDivision);
  },
});

/**
 * Division featuers where the sub areas are constrained to the park layout.
 */
export const allDivisionFeaturesSelectorFamily = selector<{
  subAreas: SubAreaFeature[];
  exclusionZones: ExclusionZoneFeature[];
}>({
  key: "allDivisionFeaturesSelectorFamily",
  get: ({ get }) => {
    const allFeatures = get(projectFeaturesSelector);
    const exclusionZones = allFeatures.filter(isExclusionDivision);
    const subAreas = allFeatures.filter(isSubArea);
    return {
      subAreas: subAreas.flatMap((p) => {
        const parkId = p.properties.parentIds?.[0] ?? "";
        const park = get(getParkFeatureSelectorFamily({ parkId }));
        if (!park) return [];
        return constrainFeatureToParkLayout(p, park);
      }),
      exclusionZones,
    };
  },
});

export const getDivisionFeaturesSelectorFamily = selectorFamily<
  {
    subAreas: SubAreaFeature[];
    exclusionZones: ExclusionZoneFeature[];
  },
  { parkId: string; raw?: boolean }
>({
  key: "getDivisionFeaturesSelectorFamily",
  get:
    ({ parkId, raw }) =>
    ({ get }) => {
      if (!parkId) return { subAreas: [], exclusionZones: [] };
      const park = raw
        ? undefined
        : get(getParkFeatureSelectorFamily({ parkId }));
      if (!park) return { subAreas: [], exclusionZones: [] };

      const { subAreas, exclusionZones } = get(
        allDivisionFeaturesSelectorFamily,
      );
      return {
        subAreas: raw
          ? subAreas.filter(inPark(parkId))
          : subAreas
              .filter(inPark(parkId))
              .flatMap((p) => constrainFeatureToParkLayout(p, park)),
        exclusionZones: exclusionZones,
      };
    },
});

export const getSubAreaSelectorFamily = selectorFamily<
  SubAreaFeature | undefined,
  { divisionId: string }
>({
  key: "getSubAreaSelectorFamily",
  get:
    ({ divisionId }) =>
    ({ get }) => {
      const projectData = get(projectFeaturesSelector);
      const zone = projectData
        .filter(isSubArea)
        .find((p) => p.id === divisionId);
      return zone;
    },
});

export const getDivisionFeaturesInBranchSelectorFamily = selectorFamily<
  {
    subAreas: SubAreaFeature[];
    exclusionZones: ExclusionZoneFeature[];
  },
  { parkId: string; branchId: string; raw?: boolean }
>({
  key: "getDivisionFeaturesInBranchSelectorFamily",
  get:
    ({ parkId, branchId, raw }) =>
    ({ get }) => {
      if (!parkId) return { subAreas: [], exclusionZones: [] };
      const projectData = get(
        projectFeaturesInBranchSelectorFamily({ branchId }),
      );
      const park = get(getParkFeatureInBranchSelector({ parkId, branchId }));
      if (!park) return { subAreas: [], exclusionZones: [] };
      const noGoPolygons = projectData.filter(isExclusionDivision);
      const subAreaPolygons = projectData
        .filter(isSubArea)
        .filter((p) => p.properties?.parentIds?.includes(parkId));

      return {
        subAreas: raw
          ? subAreaPolygons
          : subAreaPolygons.flatMap((p) =>
              constrainFeatureToParkLayout(p, park),
            ),
        exclusionZones: noGoPolygons,
      };
    },
});

export const getTurbinesWithinDivisionSelectorFamily = selectorFamily<
  TurbineFeature[] | undefined,
  { parkId: string; selectedSubAreaIds: string[] }
>({
  key: "getTurbinesWithinDivisionSelectorFamily",
  get:
    ({ parkId, selectedSubAreaIds }) =>
    ({ get }) => {
      if (!parkId || !selectedSubAreaIds) return undefined;
      const { subAreas } = get(
        getDivisionFeaturesSelectorFamily({ parkId, raw: false }),
      );
      const divisions = subAreas.filter((zone) =>
        selectedSubAreaIds.includes(zone.id),
      );
      const turbines = get(getTurbinesSelectorFamily({ parkId }));
      if (divisions.length === 0) return turbines;
      const insideTurbines = turbines.filter((f) =>
        divisions.some((division) =>
          pointInPolygon(f.geometry, division.geometry),
        ),
      );
      if (insideTurbines.length === turbines.length) return turbines;
      return insideTurbines;
    },
});

export const getSelectedSubAreas = selectorFamily<
  SubAreaFeature[],
  { parkId: string }
>({
  key: "getSelectedSubAreas",
  get:
    ({ parkId }) =>
    ({ get }) => {
      const selectedIds = new Set(get(currentSelectionArrayAtom));
      const { subAreas } = get(getDivisionFeaturesSelectorFamily({ parkId }));
      return subAreas.filter((a) => selectedIds.has(a.id));
    },
});

/**
 * Get's the "selected area", i.e. either the park or one or more sub areas.
 * If the park is in the selection, return the park.
 */
export const getSelectedArea = selector<
  ParkFeature | SubAreaFeature[] | undefined
>({
  key: "getSelectedArea",
  get: ({ get }) => {
    const parkId = get(parkIdSelector);
    if (!parkId) return undefined;

    const selectedPark = get(currentSelectedProjectFeatures).find(
      (f) => f.id === parkId,
    );
    if (selectedPark && isPark(selectedPark)) return selectedPark;

    const subAreas = get(getSelectedSubAreas({ parkId }));
    if (subAreas.length) return subAreas;
    const park = get(getParkFeatureSelectorFamily({ parkId }));
    return park;
  },
});

/**
 * Only return an area if it is in the selection.  Only return sub-areas in the
 * current park.  If the park is also selected, return the park.
 *
 * Related: {@link getSelectedArea}
 */
export const getSelectedAreaStrict = selector<
  ParkFeature | SubAreaFeature[] | undefined
>({
  key: "getSelectedAreaStrict",
  get: ({ get }) => {
    const parkId = get(parkIdSelector);
    if (!parkId) return undefined;
    const selection = get(currentSelectedProjectFeatures);
    const park = selection.find((p) => p.id === parkId);
    if (isPark(park)) return park;

    const areas = selection.filter(isSubArea).filter(inPark(parkId));
    if (areas.length) return areas;
    return undefined;
  },
});

export const getTurbinesWithinDivisionAndBranchSelectorFamily = selectorFamily<
  TurbineFeature[] | undefined,
  { parkId: string; branchId: string; selectedSubAreaIds: string[] }
>({
  key: "getTurbinesWithinDivisionAndBranchSelectorFamily",
  get:
    ({ parkId, branchId, selectedSubAreaIds }) =>
    ({ get }) => {
      if (!parkId) return undefined;
      const { subAreas } = get(
        getDivisionFeaturesSelectorFamily({ parkId, raw: false }),
      );
      const divisions = subAreas.filter((zone) =>
        selectedSubAreaIds.includes(zone.id),
      );
      const turbines = get(
        getTurbinesInBranchSelectorFamily({ parkId, branchId }),
      );
      if (divisions.length === 0) return turbines;
      const insideTurbines = turbines.filter((f) =>
        divisions.some((division) =>
          pointInPolygon(f.geometry, division.geometry),
        ),
      );
      if (insideTurbines.length === turbines.length) return turbines;
      return insideTurbines;
    },
});

export const getAnchorsWithinDivisionSelectorFamily = selectorFamily<
  AnchorFeature[] | undefined,
  { parkId: string; selectedSubAreaIds: string[] }
>({
  key: "getAnchorsWithinDivisionSelectorFamily",
  get:
    ({ parkId, selectedSubAreaIds }) =>
    ({ get }) => {
      if (!parkId) return undefined;
      const insideMooringLines = get(
        getMooringLinesWithinDivisionSelectorFamily({
          parkId,
          selectedSubAreaIds,
        }),
      );

      const anchors = get(getAnchorsSelectorFamily(parkId));
      const insideAnchors = anchors.filter((f) =>
        insideMooringLines?.find(
          (insideMooringLines) =>
            insideMooringLines.properties.anchor === f.properties.id,
        ),
      );
      if (insideAnchors.length === anchors.length) return anchors;
      return insideAnchors;
    },
});

export const getMooringLinesWithinDivisionSelectorFamily = selectorFamily<
  MooringLineFeature[] | undefined,
  { parkId: string; selectedSubAreaIds: string[] }
>({
  key: "getMooringLinesWithinDivisionSelectorFamily",
  get:
    ({ parkId, selectedSubAreaIds }) =>
    ({ get }) => {
      if (!parkId) return undefined;
      const insideTurbines = get(
        getTurbinesWithinDivisionSelectorFamily({
          parkId,
          selectedSubAreaIds,
        }),
      );
      const mooringLines = get(getMooringLinesSelector(parkId));
      const insideMooringLines = mooringLines.filter((f) =>
        insideTurbines?.find(
          (insideTurbines) => insideTurbines.id === f.properties.target,
        ),
      );
      if (insideMooringLines.length === mooringLines.length)
        return mooringLines;
      return insideMooringLines;
    },
});

export const getExportCablesWithinDivisionSelectorFamily = selectorFamily<
  ExportCableFeature[] | undefined,
  { parkId: string; selectedSubAreaIds: string[] }
>({
  key: "getExportCableWithinDivisionSelectorFamily",
  get:
    ({ parkId, selectedSubAreaIds }) =>
    ({ get }) => {
      if (!parkId) return undefined;
      const { subAreas } = get(
        getDivisionFeaturesSelectorFamily({ parkId, raw: false }),
      );
      const divisions = subAreas.filter((zone) =>
        selectedSubAreaIds.includes(zone.id),
      );
      const exportCables = get(getExportCablesSelectorFamily({ parkId }));
      if (divisions.length === 0) return exportCables;
      const insideExportCables = exportCables.filter((f) =>
        divisions.some((division) =>
          lineInPolygon(f.geometry, division.geometry),
        ),
      );
      if (insideExportCables.length === exportCables.length)
        return exportCables;
      return insideExportCables;
    },
});

export const getSubstationsWithinDivisionSelectorFamily = selectorFamily<
  SubstationFeature[] | undefined,
  { parkId: string; selectedSubAreaIds: string[] }
>({
  key: "getSubstationsWithinDivisionSelectorFamily",
  get:
    ({ parkId, selectedSubAreaIds }) =>
    ({ get }) => {
      if (!parkId) return undefined;
      const { subAreas } = get(
        getDivisionFeaturesSelectorFamily({ parkId, raw: false }),
      );
      const divisions = subAreas.filter((zone) =>
        selectedSubAreaIds.includes(zone.id),
      );
      const substations = get(getSubstationsSelectorFamily({ parkId }));
      if (divisions.length === 0) return substations;
      const insideSubstations = substations.filter((f) =>
        divisions.some((division) =>
          pointInPolygon(f.geometry, division.geometry),
        ),
      );
      if (insideSubstations.length === substations.length) return substations;
      return insideSubstations;
    },
});

export const getCablesWithinDivisionSelectorFamily = selectorFamily<
  CableFeature[] | undefined,
  { parkId: string; selectedSubAreaIds: string[] }
>({
  key: "getCablesWithinDivisionSelectorFamily",
  get:
    ({ parkId, selectedSubAreaIds }) =>
    ({ get }) => {
      if (!parkId) return undefined;
      const { subAreas } = get(
        getDivisionFeaturesSelectorFamily({ parkId, raw: false }),
      );
      const divisions = subAreas.filter((zone) =>
        selectedSubAreaIds.includes(zone.id),
      );
      const cables = get(getCablesSelectorFamily({ parkId }));
      if (divisions.length === 0) return cables;
      const insideCables = cables.filter((f) =>
        divisions.some((division) =>
          lineInPolygon(f.geometry, division.geometry),
        ),
      );
      if (insideCables.length === cables.length) return cables;
      return insideCables;
    },
});

export const getChainsWithinDivisionSelectorFamily = selectorFamily<
  CableChainFeature[] | undefined,
  { parkId: string; substationsInDivisionIds: string[] | undefined }
>({
  key: "getChainsWithinDivisionSelectorFamily",
  get:
    ({ parkId, substationsInDivisionIds }) =>
    ({ get }) => {
      if (!parkId || !substationsInDivisionIds) return undefined;
      const chains = get(labelledCableChainsFeaturesSelectorFamily({ parkId }));
      return chains.filter((c) =>
        substationsInDivisionIds.some((sub) => sub === c.properties.substation),
      );
    },
});

const constrainFeatureToParkLayout = (
  f: SubAreaFeature,
  park: ParkFeature,
): SubAreaFeature[] => {
  if (!park) return [f];
  const parkPolygon = park.geometry;
  const intersectionFeature = turf.intersect(parkPolygon, f.geometry);
  if (!intersectionFeature) return [f];
  if (intersectionFeature.geometry.type === "MultiPolygon")
    return intersectionFeature.geometry.coordinates.map((c) => ({
      ...f,
      geometry: {
        type: "Polygon",
        coordinates: c,
      },
    }));
  return [
    {
      ...f,
      geometry: intersectionFeature.geometry,
    },
  ];
};
