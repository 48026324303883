/// <reference types="vite-plugin-svgr/client" />
import { SVGWrapper } from "@icons/svgUtils";
import Button from "components/General/Button";
import { Column, Row } from "components/General/Layout";
import Earth from "@icons/14/Earth.svg?react";
import Folder from "@icons/14/Folder.svg?react";
import Trashcan from "@icons/24/Bin.svg?react";
import AddIcon from "@icons/24/Add.svg?react";
import {
  nodesWithAccessToAnalysis,
  nodesWithAccessToCable,
  nodesWithAccessToDataPackage,
  nodesWithAccessToFinancial,
  nodesWithAccessToFoundation,
  nodesWithAccessToTurbine,
} from "components/Organisation/Library/state";
import {
  LibraryResourceType,
  NodeLibraryResource,
} from "components/Organisation/Library/types";
import {
  ContentTableColumn,
  ContentTableRow,
  TabDescription,
  TextEllipsis,
} from "components/Organisation/OrganisationRightSide/style";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { useTypedPath } from "state/pathParams";
import {
  adminInOrganisationSelectorFamily,
  orgAnalysisManageAccessSelector,
  orgCableManageAccessSelector,
  orgDataPackagesManageAccessSelector,
  orgFinanicalManageAccessSelector,
  orgFoundationManageAccessSelector,
  orgTurbineManageAccessSelector,
} from "state/user";
import { spaceTiny } from "styles/space";
import { TextIcon } from "styles/typography";
import { singleNodeAtomFamily } from "state/timeline";
import useNodeTurbineResourcesCrud from "../../NodeContent/useNodeTurbineResourcesCrud";
import useNodeFoundationResourcesCrud from "../../NodeContent/useNodeFoundationResourcesCrud";
import { useCallback, useState } from "react";
import {
  AdminNodeAccessModalWrapper,
  NodeAccessModalWrapper,
} from "../../shared/NodeAccessModal";
import { TurbineType } from "types/turbines";
import { FoundationType } from "types/foundations";
import Tooltip from "components/General/Tooltip";
import Checkbox from "components/General/Checkbox";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { Configuration } from "services/configurationService";
import useNodeAnalysisResourcesCrud from "../../NodeContent/useNodeAnalysisResourcesCrud";
import { CableType } from "services/cableTypeService";
import useNodeCableResourcesCrud from "../../NodeContent/useNodeCableResourcesCrud";
import { CostConfiguration } from "services/costService";
import useNodeFinancialResourcesCrud from "../../NodeContent/useNodeFinancialResourcesCrud";
import { DataLibraryPackage } from "components/Organisation/Library/dataLibrary/types";
import useNodeDataPackageResourcesCrud from "../../NodeContent/useNodeDataPackageResourcesCrud";

export function TurbineProjects({ resource }: { resource: TurbineType }) {
  const { organisationId } = useTypedPath("organisationId");

  const { addOrUpdate: addOrUpdateTurbine, remove } =
    useNodeTurbineResourcesCrud();

  const nodesWithAccess = useRecoilValue(
    nodesWithAccessToTurbine({
      organisationId,
      resourceId: resource.id,
    }),
  );

  const isAdminInOrg = useRecoilValue(
    adminInOrganisationSelectorFamily({ organisationId }),
  );

  const entireOrgHasAccessToResource = nodesWithAccess.some(
    (na) => na.node_information_id === organisationId,
  );
  const hasOrgTurbineAccess = useRecoilValue(orgTurbineManageAccessSelector);

  const [showAddProject, setShowAddProject] = useState(false);
  const [addInProgress, setAddInProgress] = useState<number>(0);
  const [removeInProgress, setRemoveInProgress] = useState(false);

  const onAddProjects = useCallback(
    async (nodes: { node_id: string }[]) => {
      setAddInProgress(nodes.length);
      setShowAddProject(false);
      try {
        await Promise.all(
          nodes.map((node) => {
            return addOrUpdateTurbine(node.node_id, resource.id).then(() =>
              setAddInProgress((cur) => cur - 1),
            );
          }),
        );
      } catch {
      } finally {
        setShowAddProject(false);
        setAddInProgress(0);
      }
    },
    [addOrUpdateTurbine, resource],
  );

  const onRemoveAccess = useCallback(
    async (nodes: { node_id: string }[]) => {
      setRemoveInProgress(true);
      try {
        await Promise.all(
          nodes.map((node) => {
            return remove(node.node_id, resource.id);
          }),
        );
        setShowAddProject(false);
      } catch {
      } finally {
        setShowAddProject(false);
        setRemoveInProgress(false);
      }
    },
    [remove, resource.id],
  );

  return (
    <Column style={{ gap: "2.4rem", height: "100%" }}>
      <Row>
        <Checkbox
          label={`Make ${resource.name} available in all projects`}
          labelPlacement="after"
          disabled={removeInProgress}
          checked={entireOrgHasAccessToResource}
          onChange={(e) => {
            e.target.checked
              ? onAddProjects([{ node_id: organisationId }])
              : onRemoveAccess([{ node_id: organisationId }]);
          }}
        />
      </Row>
      <Row style={{ alignItems: "center" }}>
        <TabDescription>
          {resource.name} can be used inside these projects.
        </TabDescription>
        {hasOrgTurbineAccess && (
          <div style={{ marginLeft: "auto", position: "relative" }}>
            <Tooltip
              text={
                entireOrgHasAccessToResource
                  ? "Entire organsiation can use this resource"
                  : ""
              }
              disabled={!entireOrgHasAccessToResource}
            >
              <Button
                text="Add projects"
                buttonType="secondary"
                onClick={() => setShowAddProject((cur) => !cur)}
                disabled={entireOrgHasAccessToResource || addInProgress > 0}
                icon={<AddIcon />}
              />
            </Tooltip>
            {showAddProject && (
              <div style={{ position: "absolute", right: "0.8rem" }}>
                {isAdminInOrg ? (
                  <AdminNodeAccessModalWrapper
                    onSave={onAddProjects}
                    existingAccess={nodesWithAccess.map(
                      (n) => n.node_information_id,
                    )}
                    disableAccessRole={true}
                  />
                ) : (
                  <NodeAccessModalWrapper
                    onSave={onAddProjects}
                    existingAccess={nodesWithAccess.map(
                      (n) => n.node_information_id,
                    )}
                    disableAccessRole={true}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </Row>

      <ContentTableColumn>
        {entireOrgHasAccessToResource ? (
          <ContentTableRow>
            <SVGWrapper size={1.4}>
              <Earth />
            </SVGWrapper>
            <TextEllipsis style={{ margin: 0 }}>
              This resource is available in all projects
            </TextEllipsis>
          </ContentTableRow>
        ) : (
          <>
            {nodesWithAccess.map((na) => {
              return (
                <SingleNodeView
                  key={na.node_information_id}
                  nodeAccess={na}
                  type={"TURBINE"}
                />
              );
            })}
            {Array.from({ length: addInProgress }, (_, index) => (
              <SkeletonBlock
                key={index}
                style={{ height: "4rem", marginTop: "0.8rem" }}
              />
            ))}
          </>
        )}
      </ContentTableColumn>
    </Column>
  );
}

export function FoundationProjects({ resource }: { resource: FoundationType }) {
  const { organisationId } = useTypedPath("organisationId");
  const { addOrUpdate: addOrUpdateFoundation, remove } =
    useNodeFoundationResourcesCrud();

  const nodesWithAccess = useRecoilValue(
    nodesWithAccessToFoundation({
      organisationId,
      resourceId: resource.id,
    }),
  );
  const entireOrgHasAccessToResource = nodesWithAccess.some(
    (na) => na.node_information_id === organisationId,
  );

  const hasOrgFoundationAccess = useRecoilValue(
    orgFoundationManageAccessSelector,
  );
  const isAdminInOrg = useRecoilValue(
    adminInOrganisationSelectorFamily({ organisationId }),
  );

  const [showAddProject, setShowAddProject] = useState(false);
  const [addInProgress, setAddInProgress] = useState<number>(0);
  const [removeInProgress, setRemoveInProgress] = useState(false);

  const onAddProjects = useCallback(
    async (nodes: { node_id: string }[]) => {
      setShowAddProject(false);
      setAddInProgress(nodes.length);
      try {
        await Promise.all(
          nodes.map((node) => {
            return addOrUpdateFoundation(node.node_id, resource).then(() =>
              setAddInProgress((cur) => cur - 1),
            );
          }),
        );
        setShowAddProject(false);
      } catch {
      } finally {
        setAddInProgress(0);
      }
    },
    [addOrUpdateFoundation, resource],
  );

  const onRemoveAccess = useCallback(
    async (nodes: { node_id: string }[]) => {
      setRemoveInProgress(true);
      setShowAddProject(false);
      try {
        await Promise.all(
          nodes.map((node) => {
            return remove(node.node_id, resource.id);
          }),
        );
      } catch {
      } finally {
        setShowAddProject(false);
        setRemoveInProgress(false);
      }
    },
    [remove, resource.id],
  );

  return (
    <Column style={{ gap: "2.4rem", height: "100%" }}>
      <Row>
        <Checkbox
          label={`Make ${resource.name} available in all projects`}
          labelPlacement="after"
          disabled={removeInProgress}
          checked={entireOrgHasAccessToResource}
          onChange={(e) => {
            e.target.checked
              ? onAddProjects([{ node_id: organisationId }])
              : onRemoveAccess([{ node_id: organisationId }]);
          }}
        />
      </Row>
      <Row style={{ alignItems: "center" }}>
        <TabDescription>
          {resource.name} can be used inside these projects.
        </TabDescription>
        {hasOrgFoundationAccess && (
          <div style={{ marginLeft: "auto", position: "relative" }}>
            <Tooltip
              text={
                entireOrgHasAccessToResource
                  ? "Entire organsiation can use this resource"
                  : ""
              }
              disabled={!entireOrgHasAccessToResource}
            >
              <Button
                text="Add projects"
                buttonType="secondary"
                onClick={() => setShowAddProject((cur) => !cur)}
                disabled={addInProgress > 0 || entireOrgHasAccessToResource}
              />
            </Tooltip>
            {showAddProject && (
              <div style={{ position: "absolute", right: "0.8rem" }}>
                {isAdminInOrg ? (
                  <AdminNodeAccessModalWrapper
                    onSave={onAddProjects}
                    existingAccess={nodesWithAccess.map(
                      (n) => n.node_information_id,
                    )}
                    disableAccessRole={true}
                  />
                ) : (
                  <NodeAccessModalWrapper
                    onSave={onAddProjects}
                    existingAccess={nodesWithAccess.map(
                      (n) => n.node_information_id,
                    )}
                    disableAccessRole={true}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </Row>
      <ContentTableColumn>
        {entireOrgHasAccessToResource ? (
          <ContentTableRow>
            <SVGWrapper size={1.4}>
              <Earth />
            </SVGWrapper>
            <TextEllipsis style={{ margin: 0 }}>
              This resource is available in all projects
            </TextEllipsis>
          </ContentTableRow>
        ) : (
          <>
            {nodesWithAccess.map((na) => {
              return (
                <SingleNodeView
                  key={na.node_information_id}
                  nodeAccess={na}
                  type={"FOUNDATION"}
                />
              );
            })}
            {Array.from({ length: addInProgress }, (_, index) => (
              <SkeletonBlock
                key={index}
                style={{ height: "4rem", marginTop: "0.8rem" }}
              />
            ))}
          </>
        )}
      </ContentTableColumn>
    </Column>
  );
}
export function AnalysisProjects({ resource }: { resource: Configuration }) {
  const { organisationId } = useTypedPath("organisationId");
  const { addOrUpdate: addOrUpdateAnalysis, remove } =
    useNodeAnalysisResourcesCrud();

  const nodesWithAccess = useRecoilValue(
    nodesWithAccessToAnalysis({
      organisationId,
      resourceId: resource.id,
    }),
  );
  const entireOrgHasAccessToResource = nodesWithAccess.some(
    (na) => na.node_information_id === organisationId,
  );

  const hasOrgAnalysisAccess = useRecoilValue(orgAnalysisManageAccessSelector);
  const isAdminInOrg = useRecoilValue(
    adminInOrganisationSelectorFamily({ organisationId }),
  );

  const [showAddProject, setShowAddProject] = useState(false);
  const [addInProgress, setAddInProgress] = useState<number>(0);
  const [removeInProgress, setRemoveInProgress] = useState(false);

  const onAddProjects = useCallback(
    async (nodes: { node_id: string }[]) => {
      setShowAddProject(false);
      setAddInProgress(nodes.length);
      try {
        await Promise.all(
          nodes.map((node) => {
            return addOrUpdateAnalysis(node.node_id, resource).then(() =>
              setAddInProgress((cur) => cur - 1),
            );
          }),
        );
        setShowAddProject(false);
      } catch {
      } finally {
        setAddInProgress(0);
      }
    },
    [addOrUpdateAnalysis, resource],
  );

  const onRemoveAccess = useCallback(
    async (nodes: { node_id: string }[]) => {
      setRemoveInProgress(true);
      try {
        await Promise.all(
          nodes.map((node) => {
            return remove(node.node_id, resource.id);
          }),
        );
        setShowAddProject(false);
      } catch {
      } finally {
        setShowAddProject(false);
        setRemoveInProgress(false);
      }
    },
    [remove, resource.id],
  );

  return (
    <Column style={{ gap: "2.4rem", height: "100%" }}>
      <Row>
        <Checkbox
          label={`Make ${resource.name} available in all projects`}
          labelPlacement="after"
          disabled={removeInProgress}
          checked={entireOrgHasAccessToResource}
          onChange={(e) => {
            e.target.checked
              ? onAddProjects([{ node_id: organisationId }])
              : onRemoveAccess([{ node_id: organisationId }]);
          }}
        />
      </Row>
      <Row style={{ alignItems: "center" }}>
        <TabDescription>
          {resource.name} can be used inside these projects.
        </TabDescription>
        {hasOrgAnalysisAccess && (
          <div style={{ marginLeft: "auto", position: "relative" }}>
            <Tooltip
              text={
                entireOrgHasAccessToResource
                  ? "Entire organsiation can use this resource"
                  : ""
              }
              disabled={!entireOrgHasAccessToResource}
            >
              <Button
                text="Add projects"
                buttonType="secondary"
                onClick={() => setShowAddProject((cur) => !cur)}
                disabled={addInProgress > 0 || entireOrgHasAccessToResource}
              />
            </Tooltip>
            {showAddProject && (
              <div style={{ position: "absolute", right: "0.8rem" }}>
                {isAdminInOrg ? (
                  <AdminNodeAccessModalWrapper
                    onSave={onAddProjects}
                    existingAccess={nodesWithAccess.map(
                      (n) => n.node_information_id,
                    )}
                    disableAccessRole={true}
                  />
                ) : (
                  <NodeAccessModalWrapper
                    onSave={onAddProjects}
                    existingAccess={nodesWithAccess.map(
                      (n) => n.node_information_id,
                    )}
                    disableAccessRole={true}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </Row>
      <ContentTableColumn>
        {entireOrgHasAccessToResource ? (
          <ContentTableRow>
            <SVGWrapper size={1.4}>
              <Earth />
            </SVGWrapper>
            <TextEllipsis style={{ margin: 0 }}>
              This resource is available in all projects
            </TextEllipsis>
          </ContentTableRow>
        ) : (
          <>
            {nodesWithAccess.map((na) => {
              return (
                <SingleNodeView
                  key={na.node_information_id}
                  nodeAccess={na}
                  type={"ANALYSIS"}
                />
              );
            })}
            {Array.from({ length: addInProgress }, (_, index) => (
              <SkeletonBlock
                key={index}
                style={{ height: "4rem", marginTop: "0.8rem" }}
              />
            ))}
          </>
        )}
      </ContentTableColumn>
    </Column>
  );
}

export function CableProjects({ resource }: { resource: CableType }) {
  const { organisationId } = useTypedPath("organisationId");

  const { addOrUpdate: addOrUpdateCable, remove } = useNodeCableResourcesCrud();

  const nodesWithAccess = useRecoilValue(
    nodesWithAccessToCable({
      organisationId,
      resourceId: resource.id,
    }),
  );

  const isAdminInOrg = useRecoilValue(
    adminInOrganisationSelectorFamily({ organisationId }),
  );

  const entireOrgHasAccessToResource = nodesWithAccess.some(
    (na) => na.node_information_id === organisationId,
  );
  const hasOrgCableAccess = useRecoilValue(orgCableManageAccessSelector);

  const [showAddProject, setShowAddProject] = useState(false);
  const [addInProgress, setAddInProgress] = useState<number>(0);
  const [removeInProgress, setRemoveInProgress] = useState(false);

  const onAddProjects = useCallback(
    async (nodes: { node_id: string }[]) => {
      setAddInProgress(nodes.length);
      setShowAddProject(false);
      try {
        await Promise.all(
          nodes.map((node) => {
            return addOrUpdateCable(node.node_id, resource.id).then(() =>
              setAddInProgress((cur) => cur - 1),
            );
          }),
        );
      } catch {
      } finally {
        setShowAddProject(false);
        setAddInProgress(0);
      }
    },
    [addOrUpdateCable, resource],
  );

  const onRemoveAccess = useCallback(
    async (nodes: { node_id: string }[]) => {
      setRemoveInProgress(true);
      try {
        await Promise.all(
          nodes.map((node) => {
            return remove(node.node_id, resource.id);
          }),
        );
        setShowAddProject(false);
      } catch {
      } finally {
        setShowAddProject(false);
        setRemoveInProgress(false);
      }
    },
    [remove, resource.id],
  );

  return (
    <Column style={{ gap: "2.4rem", height: "100%" }}>
      <Row>
        <Checkbox
          label={`Make ${resource.name} available in all projects`}
          labelPlacement="after"
          disabled={removeInProgress}
          checked={entireOrgHasAccessToResource}
          onChange={(e) => {
            e.target.checked
              ? onAddProjects([{ node_id: organisationId }])
              : onRemoveAccess([{ node_id: organisationId }]);
          }}
        />
      </Row>
      <Row style={{ alignItems: "center" }}>
        <TabDescription>
          {resource.name} can be used inside these projects.
        </TabDescription>
        {hasOrgCableAccess && (
          <div style={{ marginLeft: "auto", position: "relative" }}>
            <Tooltip
              text={
                entireOrgHasAccessToResource
                  ? "Entire organsiation can use this resource"
                  : ""
              }
              disabled={!entireOrgHasAccessToResource}
            >
              <Button
                text="Add projects"
                buttonType="secondary"
                onClick={() => setShowAddProject((cur) => !cur)}
                disabled={entireOrgHasAccessToResource || addInProgress > 0}
                icon={<AddIcon />}
              />
            </Tooltip>
            {showAddProject && (
              <div style={{ position: "absolute", right: "0.8rem" }}>
                {isAdminInOrg ? (
                  <AdminNodeAccessModalWrapper
                    onSave={onAddProjects}
                    existingAccess={nodesWithAccess.map(
                      (n) => n.node_information_id,
                    )}
                    disableAccessRole={true}
                  />
                ) : (
                  <NodeAccessModalWrapper
                    onSave={onAddProjects}
                    existingAccess={nodesWithAccess.map(
                      (n) => n.node_information_id,
                    )}
                    disableAccessRole={true}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </Row>

      <ContentTableColumn>
        {entireOrgHasAccessToResource ? (
          <ContentTableRow>
            <SVGWrapper size={1.4}>
              <Earth />
            </SVGWrapper>
            <TextEllipsis style={{ margin: 0 }}>
              This resource is available in all projects
            </TextEllipsis>
          </ContentTableRow>
        ) : (
          <>
            {nodesWithAccess.map((na) => {
              return (
                <SingleNodeView
                  key={na.node_information_id}
                  nodeAccess={na}
                  type={"CABLE"}
                />
              );
            })}
            {Array.from({ length: addInProgress }, (_, index) => (
              <SkeletonBlock
                key={index}
                style={{ height: "4rem", marginTop: "0.8rem" }}
              />
            ))}
          </>
        )}
      </ContentTableColumn>
    </Column>
  );
}

export function FinancialProjects({
  resource,
}: {
  resource: CostConfiguration;
}) {
  const { organisationId } = useTypedPath("organisationId");
  const { addOrUpdate: addOrUpdateFinancial, remove } =
    useNodeFinancialResourcesCrud();

  const nodesWithAccess = useRecoilValue(
    nodesWithAccessToFinancial({
      organisationId,
      resourceId: resource.id,
    }),
  );
  const entireOrgHasAccessToResource = nodesWithAccess.some(
    (na) => na.node_information_id === organisationId,
  );

  const hasOrgFinancialAccess = useRecoilValue(
    orgFinanicalManageAccessSelector,
  );
  const isAdminInOrg = useRecoilValue(
    adminInOrganisationSelectorFamily({ organisationId }),
  );

  const [showAddProject, setShowAddProject] = useState(false);
  const [addInProgress, setAddInProgress] = useState<number>(0);
  const [removeInProgress, setRemoveInProgress] = useState(false);

  const onAddProjects = useCallback(
    async (nodes: { node_id: string }[]) => {
      setShowAddProject(false);
      setAddInProgress(nodes.length);
      try {
        await Promise.all(
          nodes.map((node) => {
            return addOrUpdateFinancial(node.node_id, resource).then(() =>
              setAddInProgress((cur) => cur - 1),
            );
          }),
        );
        setShowAddProject(false);
      } catch {
      } finally {
        setAddInProgress(0);
      }
    },
    [addOrUpdateFinancial, resource],
  );

  const onRemoveAccess = useCallback(
    async (nodes: { node_id: string }[]) => {
      setRemoveInProgress(true);
      try {
        await Promise.all(
          nodes.map((node) => {
            return remove(node.node_id, resource.id);
          }),
        );
        setShowAddProject(false);
      } catch {
      } finally {
        setShowAddProject(false);
        setRemoveInProgress(false);
      }
    },
    [remove, resource.id],
  );

  return (
    <Column style={{ gap: "2.4rem", height: "100%" }}>
      <Row>
        <Checkbox
          label={`Make ${resource.name} available in all projects`}
          labelPlacement="after"
          disabled={removeInProgress}
          checked={entireOrgHasAccessToResource}
          onChange={(e) => {
            e.target.checked
              ? onAddProjects([{ node_id: organisationId }])
              : onRemoveAccess([{ node_id: organisationId }]);
          }}
        />
      </Row>
      <Row style={{ alignItems: "center" }}>
        <TabDescription>
          {resource.name} can be used inside these projects.
        </TabDescription>
        {hasOrgFinancialAccess && (
          <div style={{ marginLeft: "auto", position: "relative" }}>
            <Tooltip
              text={
                entireOrgHasAccessToResource
                  ? "Entire organsiation can use this resource"
                  : ""
              }
              disabled={!entireOrgHasAccessToResource}
            >
              <Button
                text="Add projects"
                buttonType="secondary"
                onClick={() => setShowAddProject((cur) => !cur)}
                disabled={addInProgress > 0 || entireOrgHasAccessToResource}
              />
            </Tooltip>
            {showAddProject && (
              <div style={{ position: "absolute", right: "0.8rem" }}>
                {isAdminInOrg ? (
                  <AdminNodeAccessModalWrapper
                    onSave={onAddProjects}
                    existingAccess={nodesWithAccess.map(
                      (n) => n.node_information_id,
                    )}
                    disableAccessRole={true}
                  />
                ) : (
                  <NodeAccessModalWrapper
                    onSave={onAddProjects}
                    existingAccess={nodesWithAccess.map(
                      (n) => n.node_information_id,
                    )}
                    disableAccessRole={true}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </Row>
      <ContentTableColumn>
        {entireOrgHasAccessToResource ? (
          <ContentTableRow>
            <SVGWrapper size={1.4}>
              <Earth />
            </SVGWrapper>
            <TextEllipsis style={{ margin: 0 }}>
              This resource is available in all projects
            </TextEllipsis>
          </ContentTableRow>
        ) : (
          <>
            {nodesWithAccess.map((na) => {
              return (
                <SingleNodeView
                  key={na.node_information_id}
                  nodeAccess={na}
                  type={"FINANCIAL"}
                />
              );
            })}
            {Array.from({ length: addInProgress }, (_, index) => (
              <SkeletonBlock
                key={index}
                style={{ height: "4rem", marginTop: "0.8rem" }}
              />
            ))}
          </>
        )}
      </ContentTableColumn>
    </Column>
  );
}

export function DataPackageProjects({
  resource,
}: {
  resource: DataLibraryPackage;
}) {
  const { organisationId } = useTypedPath("organisationId");
  const { addOrUpdate: addOrUpdateDataPackage, remove } =
    useNodeDataPackageResourcesCrud();

  const nodesWithAccess = useRecoilValue(
    nodesWithAccessToDataPackage({
      organisationId,
      resourceId: resource.id,
    }),
  );
  const entireOrgHasAccessToResource = nodesWithAccess.some(
    (na) => na.node_information_id === organisationId,
  );

  const hasOrgDataPackageAccess = useRecoilValue(
    orgDataPackagesManageAccessSelector,
  );
  const isAdminInOrg = useRecoilValue(
    adminInOrganisationSelectorFamily({ organisationId }),
  );

  const [showAddProject, setShowAddProject] = useState(false);
  const [addInProgress, setAddInProgress] = useState<number>(0);
  const [removeInProgress, setRemoveInProgress] = useState(false);

  const onAddProjects = useCallback(
    async (nodes: { node_id: string }[]) => {
      setShowAddProject(false);
      setAddInProgress(nodes.length);
      try {
        await Promise.all(
          nodes.map((node) => {
            return addOrUpdateDataPackage(node.node_id, resource).then(() =>
              setAddInProgress((cur) => cur - 1),
            );
          }),
        );
        setShowAddProject(false);
      } catch {
      } finally {
        setAddInProgress(0);
      }
    },
    [addOrUpdateDataPackage, resource],
  );

  const onRemoveAccess = useCallback(
    async (nodes: { node_id: string }[]) => {
      setRemoveInProgress(true);
      try {
        await Promise.all(
          nodes.map((node) => {
            return remove(node.node_id, resource.id);
          }),
        );
        setShowAddProject(false);
      } catch {
      } finally {
        setShowAddProject(false);
        setRemoveInProgress(false);
      }
    },
    [remove, resource.id],
  );

  return (
    <Column style={{ gap: "2.4rem", height: "100%" }}>
      <Row>
        <Checkbox
          label={`Make ${resource.name} available in all projects`}
          labelPlacement="after"
          disabled={removeInProgress}
          checked={entireOrgHasAccessToResource}
          onChange={(e) => {
            e.target.checked
              ? onAddProjects([{ node_id: organisationId }])
              : onRemoveAccess([{ node_id: organisationId }]);
          }}
        />
      </Row>
      <Row style={{ alignItems: "center" }}>
        <TabDescription>
          {resource.name} can be used inside these projects.
        </TabDescription>
        {hasOrgDataPackageAccess && (
          <div style={{ marginLeft: "auto", position: "relative" }}>
            <Tooltip
              text={
                entireOrgHasAccessToResource
                  ? "Entire organsiation can use this resource"
                  : ""
              }
              disabled={!entireOrgHasAccessToResource}
            >
              <Button
                text="Add projects"
                buttonType="secondary"
                onClick={() => setShowAddProject((cur) => !cur)}
                disabled={addInProgress > 0 || entireOrgHasAccessToResource}
              />
            </Tooltip>
            {showAddProject && (
              <div style={{ position: "absolute", right: "0.8rem" }}>
                {isAdminInOrg ? (
                  <AdminNodeAccessModalWrapper
                    onSave={onAddProjects}
                    existingAccess={nodesWithAccess.map(
                      (n) => n.node_information_id,
                    )}
                    disableAccessRole={true}
                  />
                ) : (
                  <NodeAccessModalWrapper
                    onSave={onAddProjects}
                    existingAccess={nodesWithAccess.map(
                      (n) => n.node_information_id,
                    )}
                    disableAccessRole={true}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </Row>
      <ContentTableColumn>
        {entireOrgHasAccessToResource ? (
          <ContentTableRow>
            <SVGWrapper size={1.4}>
              <Earth />
            </SVGWrapper>
            <TextEllipsis style={{ margin: 0 }}>
              This resource is available in all projects
            </TextEllipsis>
          </ContentTableRow>
        ) : (
          <>
            {nodesWithAccess.map((na) => {
              return (
                <SingleNodeView
                  key={na.node_information_id}
                  nodeAccess={na}
                  type={"DATAPACKAGE"}
                />
              );
            })}
            {Array.from({ length: addInProgress }, (_, index) => (
              <SkeletonBlock
                key={index}
                style={{ height: "4rem", marginTop: "0.8rem" }}
              />
            ))}
          </>
        )}
      </ContentTableColumn>
    </Column>
  );
}

function SingleNodeView({
  nodeAccess,
  type,
}: {
  nodeAccess: NodeLibraryResource;
  type: LibraryResourceType;
}) {
  const node = useRecoilValue(
    singleNodeAtomFamily({ nodeId: nodeAccess.node_information_id }),
  );

  const { remove: _removeTurbine } = useNodeTurbineResourcesCrud();
  const { remove: _removeFoundation } = useNodeFoundationResourcesCrud();
  const { remove: _removeAnalysis } = useNodeAnalysisResourcesCrud();
  const { remove: _removeCable } = useNodeCableResourcesCrud();
  const { remove: _removeFinancial } = useNodeFinancialResourcesCrud();
  const { remove: _removeDataPackage } = useNodeDataPackageResourcesCrud();

  const [saveInProgress, setSaveInProgress] = useState(false);

  const removeTurbine = useRecoilCallback(
    () => async (nodeId: string, turbineTypeId: string) => {
      try {
        setSaveInProgress(true);
        _removeTurbine(nodeId, turbineTypeId);
      } catch (e) {
      } finally {
        setSaveInProgress(false);
      }
    },
    [setSaveInProgress, _removeTurbine],
  );

  const removeFoundation = useRecoilCallback(
    () => async (nodeId: string, foundationId: string) => {
      try {
        setSaveInProgress(true);
        _removeFoundation(nodeId, foundationId);
      } catch (e) {
      } finally {
        setSaveInProgress(false);
      }
    },
    [setSaveInProgress, _removeFoundation],
  );

  const removeAnalysis = useRecoilCallback(
    () => async (nodeId: string, turbineTypeId: string) => {
      try {
        setSaveInProgress(true);
        _removeAnalysis(nodeId, turbineTypeId);
      } catch (e) {
      } finally {
        setSaveInProgress(false);
      }
    },
    [setSaveInProgress, _removeAnalysis],
  );

  const removeCable = useRecoilCallback(
    () => async (nodeId: string, cableId: string) => {
      try {
        setSaveInProgress(true);
        _removeCable(nodeId, cableId);
      } catch (e) {
      } finally {
        setSaveInProgress(false);
      }
    },
    [setSaveInProgress, _removeCable],
  );

  const removeFinancial = useRecoilCallback(
    () => async (nodeId: string, financialConfigId: string) => {
      try {
        setSaveInProgress(true);
        _removeFinancial(nodeId, financialConfigId);
      } catch (e) {
      } finally {
        setSaveInProgress(false);
      }
    },
    [setSaveInProgress, _removeFinancial],
  );

  const removeDataPackage = useRecoilCallback(
    () => async (nodeId: string, financialConfigId: string) => {
      try {
        setSaveInProgress(true);
        _removeDataPackage(nodeId, financialConfigId);
      } catch (e) {
      } finally {
        setSaveInProgress(false);
      }
    },
    [setSaveInProgress, _removeDataPackage],
  );

  if (!node) return <NoAccessNode />;
  const NodeIcon = node.type === "project" ? Earth : Folder;

  return (
    <ContentTableRow disabled={saveInProgress}>
      <SVGWrapper size={1.4}>
        <NodeIcon />
      </SVGWrapper>
      <TextEllipsis style={{ margin: 0 }}>{node.name}</TextEllipsis>
      <TextIcon
        onClick={(e) => {
          if (saveInProgress) return;
          e.stopPropagation();
          if (type === "FOUNDATION") {
            removeFoundation(
              nodeAccess.node_information_id,
              nodeAccess.resource_id,
            );
          } else if (type === "TURBINE") {
            removeTurbine(
              nodeAccess.node_information_id,
              nodeAccess.resource_id,
            );
          } else if (type === "ANALYSIS") {
            removeAnalysis(
              nodeAccess.node_information_id,
              nodeAccess.resource_id,
            );
          } else if (type === "CABLE") {
            removeCable(nodeAccess.node_information_id, nodeAccess.resource_id);
          } else if (type === "FINANCIAL") {
            removeFinancial(
              nodeAccess.node_information_id,
              nodeAccess.resource_id,
            );
          } else if (type === "DATAPACKAGE") {
            removeDataPackage(
              nodeAccess.node_information_id,
              nodeAccess.resource_id,
            );
          }
        }}
        scale={1.2}
        style={{
          padding: spaceTiny,
          alignSelf: "center",
          marginLeft: "auto",
        }}
      >
        <Trashcan />
      </TextIcon>
    </ContentTableRow>
  );
}

function NoAccessNode() {
  return (
    <Tooltip text="You don't have access to this project.">
      <ContentTableRow disabled={true}>
        <SVGWrapper size={1.4}>
          <Earth />
        </SVGWrapper>
        <TextEllipsis style={{ margin: 0 }}>No access</TextEllipsis>
      </ContentTableRow>
    </Tooltip>
  );
}
