import * as turf from "@turf/turf";
import { fromBlob } from "geotiff";
import { atomFamily, selectorFamily } from "recoil";
import { fetchEnhancer } from "services/utils";
import { branchIdSelector_ } from "state/pathParams";
import {
  canvasLayerBathymetryFilenamesSelector,
  projectFeatureMapInBranch,
} from "state/projectLayers";
import { Tile, tile2bbox } from "types/tile";
import { BBOX } from "utils/geojson/validate";
import { bbox } from "utils/geometry";
import { scream } from "utils/sentry";
import { Raster } from "../types/raster";
import {
  fetchSlopeInPolygon,
  fetchTile,
  fetchTileFromBBOX,
} from "../services/bathymertyService";
import {
  SlopeResponseError,
  SlopeResponseWithRaster,
  TileResponseError,
  TileResponseFinished,
  TileResponseStarted,
  TileResponseWithRaster,
} from "../types/bathymetryTypes";

/**
 * Decodes a depth encoded RGB value to a depth in meters. Depths are negative.
 */
const decodeBathymetryRGB = (sample: number[]): number => {
  return -32768 + (sample[0] * 256 + sample[1] + sample[2] / 256);
};

export const gebcoSlopeRasterIncludingCustomAtomFamily = atomFamily<
  { blob: Blob; usedCustomBathymetry: string[]; url: string } | undefined,
  string
>({
  key: "gebcoSlopeRasterIncludingCustomAtomFamily",
  default: undefined,
});

/**
 * Async responses from tile requests set by Ably.
 *
 * This will **block** if read before Ably has set the result of a request.
 */
export const tileResponseAtom = atomFamily<
  TileResponseError | TileResponseFinished,
  string
>({
  key: "tileResponseAtom",
});

/**
 * Bathymetry with {@link Raster} from the `id` of the Bathymetry request.
 */
export const bathymetryAtomFamily = atomFamily<
  TileResponseWithRaster | TileResponseError,
  string
>({
  key: "bathymetryAtomFamily",
});

/**
 * Get the bounding box for the given feautre.
 * This is only needed for recoil caching.
 */
const getBbox = selectorFamily<
  BBOX,
  { featureId: string; branchId: string; bufferKm?: number }
>({
  key: "bathymetry_state_getBbox",
  get:
    ({ featureId, bufferKm, branchId }) =>
    ({ get }) => {
      const featureMap = get(projectFeatureMapInBranch({ branchId }));
      const feature = featureMap.get(featureId);
      if (!feature) throw new Error(`No feature with id ${featureId}`);
      if (bufferKm === undefined) return bbox(feature);
      const buffered = turf.buffer(feature, bufferKm, { units: "kilometers" });
      if (!buffered)
        throw scream("failed to buffer polygon", { feature, bufferKm });
      return bbox(buffered);
    },
});

const geotiffResponseToFloat32Array = async (
  response: Response,
): Promise<{
  array: Float32Array;
  width: number;
  height: number;
}> => {
  const blob = await response.blob();
  const tiff = await fromBlob(blob);
  const image = await tiff.getImage();
  const values = (await image.readRasters())[0] as Float32Array;
  const height = image.getHeight();
  const width = image.getWidth();
  return {
    array: values,
    width,
    height,
  };
};

const pngResponseToDepthArray = async (
  response: Response,
): Promise<{ depth: Float32Array; width: number; height: number }> => {
  const blob = await response.blob();
  const img = new Image();
  const url = URL.createObjectURL(blob);
  img.src = url;
  await new Promise((resolve) => {
    img.onload = resolve;
  });
  const canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  const ctx = canvas.getContext("2d");
  if (!ctx) throw new Error("Could not get 2d context");
  ctx.drawImage(img, 0, 0);
  const imageData = ctx.getImageData(0, 0, img.width, img.height);
  const data = new Float32Array(imageData.data.length / 4);
  for (let i = 0, k = 0; i < imageData.data.length; i += 4) {
    data[k++] = decodeBathymetryRGB([
      imageData.data[i + 0],
      imageData.data[i + 1],
      imageData.data[i + 2],
    ]);
  }
  return {
    depth: data,
    width: img.width,
    height: img.height,
  };
};

const selectTileFromBBOX = selectorFamily<
  TileResponseFinished | TileResponseStarted,
  {
    bbox: [number, number, number, number];
    nodeId: string;
    bathymetryIds?: string[];
  }
>({
  key: "selectTileFromBBOX",
  get:
    ({ bbox, nodeId, bathymetryIds }) =>
    async () => {
      const [minLon, minLat, maxLon, maxLat] = bbox;
      if (minLon >= maxLon || minLat >= maxLat)
        throw new Error(
          `This is an illegal bbox, should not happen, nodeId: ${nodeId}`,
        );
      const res = await fetchTileFromBBOX(bbox, nodeId, bathymetryIds);
      return res;
    },
});

const getBathymetryBbox = selectorFamily<
  TileResponseWithRaster | TileResponseError,
  { projectId: string; bbox: BBOX }
>({
  key: "getBathymetryBbox",
  get:
    ({ projectId, bbox }) =>
    async ({ get }) => {
      const bathymetryIds = get(canvasLayerBathymetryFilenamesSelector);
      let tileResponse = get(
        selectTileFromBBOX({ bbox, nodeId: projectId, bathymetryIds }),
      );

      if (tileResponse.status === "started") {
        const ablyResponse = get(tileResponseAtom(tileResponse.id));
        if (ablyResponse.status === "failed") {
          return ablyResponse;
        }
        tileResponse = ablyResponse;
      }

      const [minLon, minLat, maxLon, maxLat] = bbox;
      const response = await fetchEnhancer(tileResponse.url, {
        method: "get",
      });
      const {
        width,
        height,
        array: values,
      } = await geotiffResponseToFloat32Array(response);
      const sizeLatitude = maxLat - minLat;
      const sizeLongitude = maxLon - minLon;
      const stepLat = sizeLatitude / height;
      const stepLon = sizeLongitude / width;
      return {
        ...tileResponse,
        raster: new Raster(
          Array.from(values),
          width,
          height,
          minLon,
          maxLat,
          stepLon,
          stepLat,
        ),
      };
    },
});

export const getBathymetryTile = selectorFamily<Raster, Tile>({
  key: "getBathymetryTile",
  get: (tile) => async () => {
    const TILE_SIZE = 256;
    const { x, y, z } = tile;
    const [minLon, minLat, maxLon, maxLat] = tile2bbox(tile);
    const res = await fetchTile({ x, y, z }, { tilesize: TILE_SIZE });
    const { depth, width, height } = await pngResponseToDepthArray(res);
    const sizeLatitude = maxLat - minLat;
    const sizeLongitude = maxLon - minLon;
    const stepLat = sizeLatitude / height;
    const stepLon = sizeLongitude / width;
    return new Raster(
      Array.from(depth),
      width,
      height,
      minLon,
      maxLat,
      stepLon,
      stepLat,
    );
  },
});

/**
 * Gets a bathymetry raster around the feature with the given `feautreId`.
 *
 * # Usage
 * You probably don't want to use this in a selector because it is very easy to
 * accidentally ask for a lot of bathymetry tiles.  A better pattern is to use
 * `useBathymetry` to fetch the tile, and pass around the raster `id` to
 * selectors.  The {@link Raster} object is is stored in
 * `bathymetryAtomFamily`.  This makes it easier to control the number of tiles
 * requested, as well as ensuring that different selectors use the same
 * bathymetry tile.
 */
export const getBathymetry = selectorFamily<
  TileResponseWithRaster | TileResponseError,
  {
    projectId: string;
    branchId?: string;
    featureId: string;
    bufferKm?: number;
  }
>({
  key: "getBathymetry",
  get:
    ({ projectId, featureId, bufferKm, branchId }) =>
    ({ get }) => {
      const branch = branchId ?? get(branchIdSelector_);
      const bounding = get(getBbox({ featureId, branchId: branch, bufferKm }));
      const response = get(getBathymetryBbox({ projectId, bbox: bounding }));
      return response;
    },
});

/**
 * New type so that we can stick it in a selector.
 */
type StrippedPolygon = {
  type: "Feature";
  geometry: {
    type: "Polygon";
    coordinates: number[][][];
  };
};

const selectSlopeTileFromBBOX = selectorFamily<
  TileResponseFinished | TileResponseStarted,
  {
    polygon: StrippedPolygon;
    nodeId: string;
    bathymetryIds: string[];
  }
>({
  key: "selectSlopeTileFromBBOX",
  get:
    ({ polygon, nodeId, bathymetryIds }) =>
    () =>
      fetchSlopeInPolygon(
        { nodeId },
        {
          polygon,
          bathymetryIds,
        },
        {},
      ),
});

const getSlopeBathymetryForPolygon = selectorFamily<
  SlopeResponseWithRaster | SlopeResponseError,
  { projectId: string; polygon: StrippedPolygon }
>({
  key: "getSlopeBathymetryBbox",
  get:
    ({ projectId, polygon }) =>
    async ({ get }) => {
      const bathymetryIds = get(canvasLayerBathymetryFilenamesSelector);

      let tileResponse = get(
        selectSlopeTileFromBBOX({ polygon, nodeId: projectId, bathymetryIds }),
      );

      if (tileResponse.status === "started") {
        const ablyResponse = get(tileResponseAtom(tileResponse.id));
        if (ablyResponse.status === "failed") return ablyResponse;
        tileResponse = ablyResponse;
      }

      const [minLon, minLat, maxLon, maxLat] = turf.bbox(polygon);
      const response = await fetchEnhancer(tileResponse.url, {
        method: "get",
      });
      const {
        width,
        height,
        array: values,
      } = await geotiffResponseToFloat32Array(response);
      const sizeLatitude = maxLat - minLat;
      const sizeLongitude = maxLon - minLon;
      const stepLat = sizeLatitude / height;
      const stepLon = sizeLongitude / width;
      return {
        ...tileResponse,
        raster: new Raster(
          Array.from(values),
          width,
          height,
          minLon,
          maxLat,
          stepLon,
          stepLat,
        ),
      };
    },
});

export const getSlopeBathymetry = selectorFamily<
  SlopeResponseWithRaster | SlopeResponseError,
  {
    projectId: string;
    branchId?: string;
    featureId: string;
    bufferKm?: number;
  }
>({
  key: "getSlopeBathymetry",
  get:
    ({ projectId, featureId, bufferKm, branchId }) =>
    ({ get }) => {
      const branch = branchId ?? get(branchIdSelector_);

      const featureMap = get(projectFeatureMapInBranch({ branchId: branch }));
      const feature = featureMap.get(featureId);
      if (!feature) throw new Error(`No feature with id ${featureId}`);

      const polygon =
        bufferKm === undefined
          ? feature
          : turf.buffer(feature, bufferKm, { units: "kilometers" });
      if (!polygon || polygon.geometry.type !== "Polygon")
        throw new Error("Can only find slope of a polygon");
      const stripped: StrippedPolygon = {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: polygon.geometry.coordinates,
        },
      };

      return get(
        getSlopeBathymetryForPolygon({ projectId, polygon: stripped }),
      );
    },
});
