import React, { useMemo } from "react";
import {
  ContentTableColumn,
  GaplessRow,
  GaplessRowPadding,
  GroupLinkText,
  TabDescription,
  TableHeader,
} from "../../../style";
import { Column, Row } from "components/General/Layout";
import { GroupedOrgResource, OrganisationResources } from "../../../types";
import useUserOrganisationResourcesCrud, {
  PERSONAL_GROUP,
  groupedUserOrgResourcesAccessSelectorFamily,
} from "../../hooks/useUserOrganisationResourcesCrud";
import { useRecoilValue } from "recoil";
import { useTypedPath } from "state/pathParams";
import {
  ErrorBoundaryPrintOnly,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";
import { Text } from "styles/typography";
import Tooltip from "components/General/Tooltip";
import { OrganisationUser } from "types/customer";
import Toggle, { ToggleSize } from "components/General/Toggle";
import { libraryAllSelectorFamily } from "state/featureAccess";

export const resourcesToNameMap: Record<OrganisationResources, string> = {
  org_turbine_manage: "Library turbines",
  org_foundation_manage: "Library foundations",
  org_cable_manage: "Library cables",
  org_analysis_manage: "Library analysis",
  org_financial_manage: "Library financial",
  org_data_package_manage: "Library data packages",
};

type SimpleGroupResource = {
  group_id: string;
  name: string;
  resources: OrganisationResources[];
};

function OrgResourceRow({
  groupedResources,
  userId,
  resourceName,
  organisationId,
}: {
  groupedResources: GroupedOrgResource;
  userId: string;
  resourceName: OrganisationResources;
  organisationId: string;
}) {
  const { add, remove } = useUserOrganisationResourcesCrud();

  const personalAccess = groupedResources[PERSONAL_GROUP]?.resources ?? [];

  const groupsWithResource = useMemo(
    () =>
      Object.keys(groupedResources)
        .filter((k) => groupedResources[k].resources.includes(resourceName))
        .map(
          (k) =>
            ({
              group_id: k,
              name: groupedResources[k].name,
              resources: groupedResources[k].resources,
            }) as SimpleGroupResource,
        ),
    [groupedResources, resourceName],
  );

  const disabled =
    groupsWithResource.filter((g) => g.group_id !== PERSONAL_GROUP).length !==
    0;

  return (
    <GaplessRowPadding>
      <Text style={{ flex: "3" }}>
        {resourcesToNameMap[resourceName]} access
      </Text>
      <Tooltip
        disabled={
          groupsWithResource.filter((g) => g.group_id !== PERSONAL_GROUP)
            .length === 0
        }
        text={"Edit group to change access"}
        position={"top"}
      >
        <Toggle
          checked={groupsWithResource.length !== 0}
          disabled={disabled}
          onChange={() => {
            personalAccess.includes(resourceName)
              ? remove(organisationId, userId, resourceName)
              : add(organisationId, userId, resourceName);
          }}
          size={ToggleSize.SMALL}
        />
      </Tooltip>
      <Row style={{ overflow: "auto", flex: "2" }}>
        {groupsWithResource
          .filter((g) => g.group_id !== PERSONAL_GROUP)
          .map((g) => (
            <GroupLinkText key={g.group_id}>{g.name}</GroupLinkText>
          ))}
      </Row>
    </GaplessRowPadding>
  );
}

const OrganisationResourceUserTable = ErrorBoundaryWrapper(
  ({ user }: { user: OrganisationUser }) => {
    const { organisationId } = useTypedPath("organisationId");
    const userId = user.user_id;
    const groupedResources = useRecoilValue(
      groupedUserOrgResourcesAccessSelectorFamily({ organisationId, userId }),
    );

    const allLibraryAccess = useRecoilValue(
      libraryAllSelectorFamily({ organisationId }),
    );

    const organisationalResources = allLibraryAccess
      ? ([
          "org_turbine_manage",
          "org_foundation_manage",
          "org_cable_manage",
          "org_analysis_manage",
          "org_financial_manage",
          "org_data_package_manage",
        ] as OrganisationResources[])
      : ([
          "org_turbine_manage",
          "org_foundation_manage",
        ] as OrganisationResources[]);

    return (
      <Column>
        <TabDescription>
          {user.nickname}`s access to the organisation Library. Access gives
          full manage and edit rights to those library resources.
        </TabDescription>
        <GaplessRow>
          <TableHeader style={{ flex: "3" }}>Access</TableHeader>
          <div style={{ flex: "1" }} />
          <TableHeader style={{ flex: "2" }}>Source of access</TableHeader>
        </GaplessRow>
        <ContentTableColumn>
          {organisationalResources.map((resourceName) => {
            return (
              <OrgResourceRow
                key={resourceName}
                userId={userId}
                resourceName={resourceName}
                groupedResources={groupedResources}
                organisationId={organisationId}
              />
            );
          })}
        </ContentTableColumn>
      </Column>
    );
  },
  ErrorBoundaryPrintOnly,
  ScreamOnError,
);

export default OrganisationResourceUserTable;
