import { atom, atomFamily, selector, selectorFamily } from "recoil";
import { getParkFeatureInBranchSelector } from "../../state/park";
import { isDefined } from "../../utils/predicates";
import { z } from "zod";
import {
  ComparisonAttributeKey,
  _ComparisonAttributesCabling,
  _ComparisonAttributesFINANCIAL,
  _ComparisonAttributesPark,
  _ComparisonAttributesProduction,
  _ComparisonAttributesWind,
  _ComparisonAttributesFoundations,
  _ComparisonAttributesFinancialInputs,
} from "./types";
import { ParkFeature } from "../../types/feature";
import { sendInfo } from "../../utils/sentry";
import { loggedInUserSelector } from "../../state/user";
import { syncLocalStorage } from "state/effects";
import { safeArray } from "utils/zod";
import { projectBranchesAtomFamily } from "../../state/timeline";
import type useParkComparisonValues from "components/CompareParksModal/ParkComparisonView/useParkComparisonValues";
import { _SimpleTurbineType } from "types/turbines";
import { _CableType } from "services/cableTypeService";

export const parkPickerDrawerOpenAtom = atom<boolean>({
  key: "parkPickerDrawerOpenAtom",
  default: true,
});

export const baselineComparisonValuesAtom = atom<
  | { comparisonId: string; values: ReturnType<typeof useParkComparisonValues> }
  | undefined
>({
  key: "baselineComparisonValuesAtom",
  default: undefined,
});

export const _SelectedParkCompare = z
  .object({
    id: z.string().optional(),
    comparisonId: z.string().optional(),
    parkId: z.string(),
    branchId: z.string(),
    selectedAnalysisConfigurationId: z.string().optional(),
    selectedWindConfigurationId: z.string().optional(),
    selectedCostConfigurationId: z.string().optional(),
    selectedHubHeight: z.number().optional(),
    selectedTurbineType: _SimpleTurbineType.optional(),
    selectedExportCableType: _CableType.optional(),
  })

  // Added 2024-02-20 to handle old data saved in LS
  .transform((data, ctx) => {
    const comparisonId = data.id ?? data.comparisonId;
    if (!comparisonId) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Missing comparisonId in _SelectedParkCompare`,
      });
      return z.NEVER;
    }

    return {
      ...data,
      comparisonId,
    };
  });

export type SelectedParkCompare = z.infer<typeof _SelectedParkCompare>;

const COMPARISON_PARKS_LS_KEY = "vind:selected-comparison-parks";
const getComparisonParksLocalStorageKey = (nodeId: string, userId: string) =>
  COMPARISON_PARKS_LS_KEY.concat("-", nodeId, "-", userId);

export const selectedParksAtom = atomFamily<
  SelectedParkCompare[],
  { projectId: string }
>({
  key: "selectedParksAtomFamily",
  default: selectorFamily({
    key: "selectedParksAtomFamily.default",
    get:
      ({ projectId }) =>
      ({ get }) => {
        const userId = get(loggedInUserSelector)?.user_id;
        const existingBranches = get(
          projectBranchesAtomFamily({ nodeId: projectId }),
        );

        if (
          typeof window === "undefined" ||
          !("localStorage" in window) ||
          !projectId ||
          !userId
        ) {
          return [];
        }

        const key = getComparisonParksLocalStorageKey(projectId, userId);

        try {
          const storageItem = localStorage.getItem(key);
          if (storageItem) {
            const result = JSON.parse(storageItem);
            return _SelectedParkCompare
              .array()
              .parse(result)
              .filter((row) => {
                return existingBranches.some(
                  (branch) => branch.id === row.branchId,
                );
              });
          }
        } catch (err) {
          sendInfo("Could not read local storage", {
            key: key,
            err,
          });
        }

        return [];
      },
  }),
  effects: ({ projectId }) => [
    ({ onSet, getPromise }) => {
      onSet(async (newValue) => {
        const userId = (await getPromise(loggedInUserSelector))?.user_id;

        if (
          typeof window === "undefined" ||
          !("localStorage" in window) ||
          !projectId ||
          !userId
        ) {
          return;
        }

        const key = getComparisonParksLocalStorageKey(projectId, userId);
        try {
          if (newValue.length === 0) {
            localStorage.removeItem(key);
            return;
          }

          localStorage.setItem(key, JSON.stringify(newValue));
        } catch (err) {
          sendInfo("Could not set local storage", {
            key: key,
            newValue,
          });
        }
      });
    },
  ],
});

export type EnrichedSelectedParkCompare = {
  park: ParkFeature;
} & SelectedParkCompare;

export const getSelectedParksForComparisonSelector = selectorFamily<
  Array<EnrichedSelectedParkCompare>,
  { nodeId: string }
>({
  key: "getSelectedParksForComparisonSelector",
  get:
    ({ nodeId }) =>
    ({ get }) => {
      const existingBranches = get(projectBranchesAtomFamily({ nodeId }));
      const selectedParks = get(selectedParksAtom({ projectId: nodeId }));
      return selectedParks
        .filter((selectedPark) => {
          return existingBranches.some(
            (branch) => branch.id === selectedPark.branchId,
          );
        })
        .map((selectedPark) => {
          const park = get(
            getParkFeatureInBranchSelector({
              parkId: selectedPark.parkId,
              branchId: selectedPark.branchId,
            }),
          );

          if (park) {
            return {
              park,
              ...selectedPark,
            };
          }

          return undefined;
        })
        .filter(isDefined);
    },
});

export const compareIsLoading = atom<Record<string, boolean>>({
  key: "compareIsLoading",
  default: {},
});

const _ComparisonAttributes = z.object({
  [ComparisonAttributeKey.PARK]: safeArray(_ComparisonAttributesPark),
  [ComparisonAttributeKey.PRODUCTION]: safeArray(
    _ComparisonAttributesProduction,
  ),
  [ComparisonAttributeKey.WIND]: safeArray(_ComparisonAttributesWind),
  [ComparisonAttributeKey.CABLING]: safeArray(_ComparisonAttributesCabling),
  [ComparisonAttributeKey.FINANCIAL]: safeArray(_ComparisonAttributesFINANCIAL),
  [ComparisonAttributeKey.FOUNDATIONS]: safeArray(
    _ComparisonAttributesFoundations,
  ),
  [ComparisonAttributeKey.FINANCIAL_INPUTS]: safeArray(
    _ComparisonAttributesFinancialInputs,
  ),
});

type ComparisonAttributes = z.infer<typeof _ComparisonAttributes>;

const SELECTED_COMPARISON_ATTRIBUTES_LS_KEY =
  "vind:selectedComparisonAttributes";
export const selectedComparisonAttributesAtom = atom<ComparisonAttributes>({
  key: "selectedComparisonAttributesAtom",
  default: selector<ComparisonAttributes>({
    key: "selectedComparisonAttributesAtomDefaultSelector",
    get: async () => {
      const defaultObject: z.infer<typeof _ComparisonAttributes> = {
        [ComparisonAttributeKey.PARK]: [
          "turbines",
          "turbineTypesString",
          "depthRange",
        ],
        [ComparisonAttributeKey.PRODUCTION]: [
          "capacity",
          "netEnergy",
          "capacityFactor",
          "totalWakeLoss",
          "otherLoss",
        ],
        [ComparisonAttributeKey.WIND]: ["avgSourceWindSpeed", "height"],
        [ComparisonAttributeKey.CABLING]: [
          "exportCableLength",
          "exportCableTypesString",
        ],
        [ComparisonAttributeKey.FINANCIAL]: ["lcoe"],
        [ComparisonAttributeKey.FOUNDATIONS]: ["primarySteelWeight"],
        [ComparisonAttributeKey.FINANCIAL_INPUTS]: [],
      };

      return defaultObject;
    },
  }),
  effects: [
    syncLocalStorage<ComparisonAttributes>(
      SELECTED_COMPARISON_ATTRIBUTES_LS_KEY,
      _ComparisonAttributes,
    ),
  ],
});

export type ComparisonData = Record<
  ParkComparisonId,
  {
    branchId: string;
    parkId: string;
    costConfigurationId?: string;
    analysisConfigurationId?: string;
    windConfigurationId?: string;
    analysisVersion?: string;
    comparisonData: Record<ComparisonAttributeKey, Record<string, unknown>>;
  }
>;
export type ParkComparisonId = string;
export const shownCompareDataAtom = atom<ComparisonData>({
  key: "shownCompareData",
  default: {},
});

export const visibleComparisonListRowsAtom = atom<Record<string, string[]>>({
  key: "visibleComparisonListRowsAtom",
  default: {},
});

const _ComparisonMode = z.enum(["absolute", "percentage"]);
export type ComparisonMode = z.infer<typeof _ComparisonMode>;
export const comparisonModeAtom = atom<ComparisonMode>({
  key: "comparisonModeAtom",
  default: "absolute",
  effects: [syncLocalStorage("vind:comparisonMode", _ComparisonMode)],
});
