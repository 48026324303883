import { atom, useSetRecoilState } from "recoil";
import { TourInfo, TourItemId } from "./TourStep";

export const lastEventState = atom<string | null>({
  key: "lastEventState",
  default: null,
});

export const activeTourState = atom<TourInfo | null | undefined>({
  key: "activeTourState",
  default: null,
});

export const activeTourStepState = atom<TourItemId | null | undefined>({
  key: "activeTourStepState",
  default: null,
});

export const stepOrderIndexMapState = atom<Map<string, number>>({
  key: "stepOrderIndexMapState",
  default: new Map(),
});

export function useTrackEvent() {
  const setLastEvent = useSetRecoilState(lastEventState);
  return (event: string) => setLastEvent(event);
}
