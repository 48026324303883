import { selectorFamily, selector, waitForAll, atom } from "recoil";
import { capitalize } from "../utils/utils";
import {
  CORS_AND_CACHE_PROXY_WITH_TOKEN_URL_PREFIX,
  addCorsAndCacheProxyURL,
} from "./gisSourceCorsProxy";
import {
  fetchEnhancer,
  fetchWithRetries,
  fetchWithToken,
} from "../services/utils";
import { newCustomDataSourceAtom } from "./newLayer";
import {
  ArcgisSourceEntries,
  ArcgisMetadataType,
  ArqisLayer,
  _ArcgisMetadataType,
  ArcgisFullLayersInfoType,
  SourceTypes,
  Layer,
  SourceTypesLayer,
  ExternalDataSourceLinkLayerWithSourceArcgis,
  LayerType,
} from "../types/layers";
import { getExternalLayerId } from "../utils/externalLayers";
import { isDefined } from "../utils/predicates";
import { z } from "zod";
import { transformNonWGS84BBOXToWGS84BBOX } from "../utils/proj4";
import { privateGISSourceDataArcgisRestAPISelector } from "./privateGISSource";
import { scream, sendWarning } from "../utils/sentry";

export const isArcgisLayer = (
  layer: Layer,
): layer is ExternalDataSourceLinkLayerWithSourceArcgis => {
  return layer.sourceType === SourceTypesLayer.arcgis;
};

const GeometryTypeToInternalType: Record<
  string,
  "polygon" | "line" | "circle"
> = {
  esriGeometryPolygon: "polygon",
  esriGeometryPolyline: "line",
  esriGeometryPoint: "circle",
};

const getProj4StringForSRIDSelector = selectorFamily<string, string | number>({
  key: "getProj4StringForSRIDSelector",
  get: (epsg) => async () =>
    (
      await fetchEnhancer(`https://epsg.io/${epsg}.proj4`, {
        method: "get",
      })
    ).text(),
});

const getAllLayersOrLoopIfFailsSelectorFamily = selectorFamily<
  ArcgisMetadataType[],
  { url: string; privateSource: boolean | undefined }
>({
  key: "getAllLayersOrLoopIfFailsSelectorFamily",
  get:
    ({ url, privateSource }) =>
    async ({ get }) => {
      try {
        const urlWithToken = `${url}/layers?f=json`;
        const res = urlWithToken.includes(
          CORS_AND_CACHE_PROXY_WITH_TOKEN_URL_PREFIX,
        )
          ? await fetchWithToken(urlWithToken, {
              method: "get",
            })
          : await fetchEnhancer(urlWithToken, {
              method: "get",
            });
        const j = await res.json();
        return z.object({ layers: _ArcgisMetadataType.array() }).parse(j)
          .layers;
      } catch (e) {
        console.error(e);
        const metadataJSON = get(
          arcgisRestAPIServerFullMetadataSelector({
            url,
            privateSource,
          }),
        );
        if (!metadataJSON || !metadataJSON.layers) return [];
        const layersInfo = metadataJSON.layers.map((l) =>
          get(
            arcgisRestAPIServerMetadataSelector({
              url: url,
              id: String(l.id),
              privateSource,
            }),
          ),
        );
        return layersInfo.filter(isDefined);
      }
    },
});

enum DrawingType {
  SIMPLE = "simple",
  CLASSBREAKS = "classBreaks",
  UNIQUEVALUE = "uniqueValue",
}
const _DrawingInfo = z.object({
  type: z.nativeEnum(DrawingType),
});

const _DrawingInfoClassBreakInfosRenderer = _DrawingInfo.extend({
  type: z.literal(DrawingType.CLASSBREAKS),
  classBreakInfos: z.array(
    z.object({
      classMaxValue: z.number(),
      label: z.string(),
      symbol: z.object({
        color: z.number().array(),
        outline: z.object({
          color: z.number().array(),
          width: z.number(),
          style: z.string().optional(),
          type: z.string().optional(),
        }),
        style: z.string(),
        type: z.string(),
      }),
    }),
  ),
  classificationMethod: z.string(),
  field: z.string(),
  minValue: z.number().optional(),
});

const _DrawingInfoSimpleColor = _DrawingInfo.extend({
  type: z.literal(DrawingType.SIMPLE),
  symbol: z.object({
    color: z.number().array(),
    outline: z
      .object({
        color: z.number().array(),
        width: z.number(),
        // style: z.string().optional(),
        // type: z.string().optional(),
      })
      .nullish(),
    // style: z.string().optional(),
    // type: z.literal("esriSFS"),
  }),
});

const _DrawingInfoUniqueValue = _DrawingInfo.extend({
  type: z.literal(DrawingType.UNIQUEVALUE),
  field1: z.string(),
  uniqueValueInfos: z.array(
    z.object({
      value: z.string(),
      symbol: z.object({
        color: z.number().array(),
        // width: z.number(),
        // style: z.string(),
        // type: z.string(),
      }),
    }),
  ),
});

// const _DrawingInfoSimpleImage = _DrawingInfo.extend({
//   type: z.literal(DrawingType.SIMPLE),
//   symbol: z.object({
//     contentType: z.literal("image/png"),
//     imageData: z.string(),
//     width: z.number(),
//     height: z.number(),
//     // color: z.number().array(),
//     // outline: z
//     //   .object({
//     //     color: z.number().array(),
//     //     width: z.number(),
//     //     style: z.string().optional(),
//     //     type: z.string().optional(),
//     //   })
//     //   .nullish(),
//     // style: z.string(),
//     type: z.literal("esriPMS"),
//   }),
// });

export type DrawingInfoClassBreakInfosRenderer = z.infer<
  typeof _DrawingInfoClassBreakInfosRenderer
>;
export type DrawingInfoSimple = z.infer<typeof _DrawingInfoSimpleColor>;
export type DrawingInfoUniqueValue = z.infer<typeof _DrawingInfoUniqueValue>;

export type DrawingInfo =
  | DrawingInfoClassBreakInfosRenderer
  | DrawingInfoSimple
  | DrawingInfoUniqueValue;

export const arcgisLayerDrawingInfoSelector = selectorFamily<
  DrawingInfo | undefined,
  {
    layer: Layer;
  }
>({
  key: "arcgisLayerDrawingInfoSelector",
  get:
    ({ layer }) =>
    async () => {
      if (!isArcgisLayer(layer)) {
        return undefined;
      }

      const isPrivate =
        "private" in layer.source ? layer.source["private"] : false;
      const url = addCorsAndCacheProxyURL(
        layer.sourceLink.url,
        isPrivate,
      ).concat(
        !layer.sourceLink.url.endsWith("/") ? "/" : "",
        layer.sourceLayerId.toString(),
        // "/legend",
        "?f=json",
      );

      let response: Response;
      try {
        response = await fetchWithToken(url, {
          method: "get",
        });
      } catch (error) {
        sendWarning("Could not fetch arcgis layer drawing info", {
          error,
        });
        return;
      }

      const json = await response.json();
      const rendererJson = (json as any).drawingInfo?.renderer;
      const renderer = _DrawingInfo.safeParse(rendererJson);

      if (!renderer.success) {
        return;
      }

      if (renderer.data.type === DrawingType.SIMPLE) {
        const parsed = _DrawingInfoSimpleColor.safeParse(rendererJson);
        if (parsed.success) {
          return parsed.data;
        }
      } else if (renderer.data.type === DrawingType.CLASSBREAKS) {
        const parsed =
          _DrawingInfoClassBreakInfosRenderer.safeParse(rendererJson);
        if (parsed.success) {
          return parsed.data;
        }
      } else if (renderer.data.type === DrawingType.UNIQUEVALUE) {
        const parsed = _DrawingInfoUniqueValue.safeParse(rendererJson);
        if (parsed.success) {
          return parsed.data;
        }
      }
      // console.log("could not parse drawing info type", rendererJson);
    },
});

export const arcgisRestAPIServerMetadataSelector = selectorFamily<
  ArcgisMetadataType | undefined,
  { url: string; id: string; privateSource: boolean | undefined }
>({
  key: "arcgisRestAPIServerMetadataSelector",
  get:
    ({ url, id, privateSource }) =>
    async () => {
      let response: Response;
      try {
        response = privateSource
          ? await fetchWithToken(`${url}/${id}?f=json`, {
              method: "get",
            })
          : await fetchWithRetries(
              `${url}/${id}?f=json`,
              {
                method: "get",
              },
              2,
            );
      } catch (err) {
        console.warn(`Could not read from arcGIS server: ${url}, ${err}`);
        return;
      }
      if (!response.ok) {
        sendWarning(`failed to fetch arcgis rest API`, {
          url,
          id,
          response,
        });
        return undefined;
      }

      const json = (await response.json()) as Record<string, any>;
      const abstract = json?.description;
      const parse = _ArcgisMetadataType.safeParse({ ...json, abstract });
      if (parse.success) {
        return parse.data;
      }
      if (typeof json === "object" && json !== null && "error" in json) {
        // Hack! Unfortunately, it happens that the server returns 200 OK, and
        // then an error object in the body. Have the same behaviour as if
        // (!response.ok)
        sendWarning(
          "failed to fetch arcgis rest API (200 OK response, but `error` in body).",
          {
            url,
            id,
            response,
            json,
          },
        );
        return undefined;
      }
      sendWarning("failed to parse arcgis rest API", {
        url,
        error: parse.error,
        json,
      });
    },
});

const _ArcgisMetadata = z.object({
  abstract: z.string().optional(),
  keywords: z.string().array(),
  layers: z
    .object({
      id: z.string().or(z.number()),
    })
    .array()
    .optional(),
  supportedImageFormatTypes: z.string().optional(),
});
type ArcgisMetadata = z.infer<typeof _ArcgisMetadata>;
const arcgisRestAPIServerFullMetadataSelector = selectorFamily<
  undefined | ArcgisMetadata,
  { url: string; privateSource: boolean | undefined }
>({
  key: "arcgisRestAPIServerFullMetadataSelector",
  get:
    ({ url, privateSource }) =>
    async () => {
      try {
        const response = privateSource
          ? await fetchWithToken(`${url}?f=json`, {
              method: "get",
            })
          : await fetchWithRetries(
              `${url}?f=json`,
              {
                method: "get",
              },
              2,
            );

        if (!response.ok) {
          return;
        }

        const json = (await response.json()) as Record<string, any>;
        const sourceKeywords = (json?.documentInfo?.Keywords ?? "")
          .split(",")
          .filter(Boolean)
          .map((str: string) => str.trim());
        const description =
          json?.description ||
          json?.documentInfo?.Subject ||
          json?.documentInfo?.Comments ||
          undefined;
        const parse = _ArcgisMetadata.safeParse({
          ...json,
          keywords: sourceKeywords,
          abstract: description,
        });
        if (parse.success) {
          return parse.data;
        }
        if (typeof json === "object" && json !== null && "error" in json) {
          // Hack!
          // Unfortunately, it happens that the server returns 200 OK, and then an error object in the body.
          // Have the same behaviour as if (!response.ok)
          return;
        }
        sendWarning("failed to parse arcgis rest API", {
          url,
          error: parse.error,
          json,
        });
      } catch (err) {
        sendWarning(`Could not read from arcGIS server`, { url, err });
      }
    },
});

export const resetArcGisDataStateAtom = atom<number>({
  key: "resetArcGisDataStateAtom",
  default: 1,
});

const getConcatinatedNameString = (
  layer: ArcgisMetadataType,
  allLayers: ArcgisMetadataType[],
) => {
  let name = layer.name;
  if (layer.parentLayer) {
    const parentLayer = allLayers.find((l) => l.id === layer.parentLayer?.id);
    if (parentLayer) {
      name = getConcatinatedNameString(parentLayer, allLayers).concat(
        "-",
        name,
      );
    }
  }
  return name;
};

export const getArcgisPath = (
  layer: ExternalDataSourceLinkLayerWithSourceArcgis,
) => {
  const isPrivate = "private" in layer.source ? layer.source["private"] : false;

  return `${addCorsAndCacheProxyURL(layer.sourceLink.url, isPrivate)}/${
    layer.sourceLayerId
  }/query?where=1%3D1&text=&objectIds=&time=&timeRelation=esriTimeRelationOverlaps&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=*&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=5&outSR=&havingClause=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultRecordCount=100&returnExtentOnly=false&sqlFormat=none&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=geojson`;
};

export const arcgisRestAPIFullMetadataSelector = selectorFamily<
  ArcgisSourceEntries,
  ArqisLayer
>({
  key: "arcgisRestAPIFullMetadataSelector",
  get:
    (source) =>
    ({ get }) => {
      if (!source.arcgis_rest_url)
        scream("URL is undefined for layer", {
          arcgis_rest_url: source.arcgis_rest_url,
        });
      const url = source?.skipProxy
        ? source.arcgis_rest_url
        : addCorsAndCacheProxyURL(source.arcgis_rest_url, source.private);

      const metadataJSON = get(
        arcgisRestAPIServerFullMetadataSelector({
          url,
          privateSource: source.private,
        }),
      );
      const fetchedLayers = get(
        getAllLayersOrLoopIfFailsSelectorFamily({
          url,
          privateSource: source.private,
        }),
      );
      const filteredLayers = source.filteredLayers || [];

      const expandedLayers = metadataJSON?.supportedImageFormatTypes
        ? [
            ...fetchedLayers,
            ...fetchedLayers
              .filter((l) => l.type !== "Raster Layer")
              .map((l) => ({
                ...l,
                type: "Raster Layer",
              })),
          ]
        : fetchedLayers;

      const layersInfo = expandedLayers
        .filter((layer) =>
          ["Feature Layer", "Raster Layer"].includes(layer?.type),
        )
        .filter((layer) => layer.extent || layer.fullExtent)
        .map<ArcgisFullLayersInfoType>((layer, index) => {
          const extent = layer.extent || layer.fullExtent;

          let bbox = [
            [extent.xmin, extent.ymin],
            [extent.xmax, extent.ymax],
          ];

          if (extent.spatialReference.wkid !== 4326) {
            const proj4String = get(
              getProj4StringForSRIDSelector(extent.spatialReference.wkid),
            );
            bbox = transformNonWGS84BBOXToWGS84BBOX(bbox, proj4String);
          }

          const salt = layer.type === "Raster Layer" ? "raster" : undefined;
          const concatinatedName = getConcatinatedNameString(
            layer,
            fetchedLayers,
          );
          const layerId = getExternalLayerId(
            source.arcgis_rest_url,
            concatinatedName,
            SourceTypesLayer.arcgis,
            {
              index,
            },
            salt,
          );
          return {
            id: layerId,
            type:
              layer.type === "Raster Layer"
                ? LayerType.Raster
                : GeometryTypeToInternalType[layer.geometryType ?? ""] ??
                  "polygon",
            name: layer.name,
            keywords: metadataJSON?.keywords ?? [],
            abstract: metadataJSON?.abstract ?? "",
            bbox: bbox.flat(),
            path: `${source.arcgis_rest_url}/${layer.id}/query?where=1%3D1&text=&objectIds=&time=&timeRelation=esriTimeRelationOverlaps&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=*&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=5&outSR=&havingClause=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultRecordCount=100&returnExtentOnly=false&sqlFormat=none&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=geojson`,
            internalId: layer.id,
            sourceType: SourceTypesLayer.arcgis,
            source: source.source,
            alias:
              source.layers?.find(
                (arcgisLayer) => arcgisLayer.id === layer.id.toString(),
              )?.alias || undefined,
            originalUrl: source.arcgis_rest_url,
            theme:
              source.layers?.find(
                (arcgisLayer) => arcgisLayer.id === layer.id.toString(),
              )?.theme || undefined,
            tags: source.layerSettingsGlobal?.[layerId]?.tags ?? [],
            editingInfo: layer.editingInfo,
          };
        })
        .filter((l) => !filteredLayers.includes(l.internalId));

      return {
        url: source.arcgis_rest_url,
        sourceType: SourceTypes.arcgis_rest_api,
        source: capitalize(source.source),
        layersInfo,
        fetchSucceeded: true,
        alternativeNames: new Set(source?.alternativeNames ?? []),
        keywords: metadataJSON?.keywords ?? [],
        abstract: metadataJSON?.abstract ?? "",
      };
    },
});

const arcgisRestAPILayersFullMetadataSelectorFamily = selectorFamily<
  ArcgisSourceEntries[],
  ArqisLayer[]
>({
  key: "arcgisRestAPILayersFullMetadataSelectorFamily",
  get:
    (arcgisRestAPILayers: ArqisLayer[]) =>
    ({ get }) => {
      const arcgisMetadataLayers = get(
        waitForAll(
          arcgisRestAPILayers.map((arcgisLayer) =>
            arcgisRestAPIFullMetadataSelector(arcgisLayer),
          ),
        ),
      );

      return arcgisMetadataLayers;
    },
});

export const arcgisPrivateRestAPILayersFullMetadataSucceededSelector =
  selectorFamily<ArcgisSourceEntries[], { projectId: string }>({
    key: "arcgisRestAPILayersFullMetadataSucceededSelector",
    get:
      ({ projectId }) =>
      ({ get }) => {
        const arcgisRestAPILayers = get(
          privateGISSourceDataArcgisRestAPISelector({ projectId }),
        );

        const arcgisMetadataLayers = get(
          arcgisRestAPILayersFullMetadataSelectorFamily(arcgisRestAPILayers),
        );
        return arcgisMetadataLayers.filter((l) => l.fetchSucceeded);
      },
  });

export const customArcgisRestAPILayersFullMetadataSucceededSelector = selector<
  ArcgisSourceEntries[]
>({
  key: "customArcgisRestAPILayersFullMetadataSucceededSelector",
  get: ({ get }) => {
    const customWMSDataSource = get(newCustomDataSourceAtom);
    if (
      !customWMSDataSource ||
      customWMSDataSource.type !== SourceTypes.arcgis_rest_api
    )
      return [];

    const arcgisLayers: ArqisLayer[] = [
      {
        arcgis_rest_url: customWMSDataSource.url,
        source: customWMSDataSource.name,
        private: true,
        alternativeNames: customWMSDataSource?.alternativeNames ?? [],
        sourceType: SourceTypesLayer.arcgis,
      },
    ];

    const arcgisMetadataLayers = get(
      arcgisRestAPILayersFullMetadataSelectorFamily(arcgisLayers),
    );

    return arcgisMetadataLayers.filter((l) => l.fetchSucceeded);
  },
});
