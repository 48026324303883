/// <reference types="vite-plugin-svgr/client" />
import InfoIcon from "@icons/24/Information.svg?react";
import {
  IconWrapper,
  TableWrapper,
  InfoRow,
  BottomTableWrapper,
} from "../styles";
import { amountUnitPrefixSuffix } from "../../../types/financial";
import {
  ScrollBody,
  useShowScrollShadow,
} from "../../../hooks/useShowScrollShadow";
import { CostType } from "../../../services/costService";
import { GeneralCost } from "./capexNumbers";
import { currentYear } from "utils/inflation";
import { orLoader } from "components/Loading/Skeleton";

import { useCapexMillionPV } from "components/Finance/capex";
import { FinanceId } from "components/Finance/state";
import { DownloadCapexData } from "./DownloadCapexData";
import {
  Divider,
  ResultValue,
} from "components/General/GeneralSideModals.style";
import { IconREMSize } from "styles/typography";
import Dashboard from "@icons/24/Dashboard.svg?react";
import { DashboardModalType, modalTypeOpenAtom } from "state/modal";
import Button from "components/General/Button";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { ButtonWrapper } from "components/UploadModal/components/shared";
import NewToggleableList from "components/ToggleableList/NewToggleableList";
import { ColoredGridFour } from "components/General/Form";
import styled from "styled-components";
import { ConfidenceLevelDot } from "components/ConfigurationModal/Cost/styles";
import Tooltip from "components/General/Tooltip";
import { branchSelectedCostConfigurationAtom } from "state/costConfigurations";
import { branchIdSelector, projectIdSelector } from "state/pathParams";
import GeneralCostConfidenceBar from "../ConfidenceBar";

const DotRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

const GeneralCostRow = ({
  generalCost,
  index,
  useConfidenceLevel,
}: {
  generalCost: GeneralCost;
  index: number;
  useConfidenceLevel: boolean | undefined;
}) => {
  const helpText = generalCost.confidenceLevel
    ? `Confidence level: ${generalCost.confidenceLevel}`
    : "From components. Confidence level: Not specified";
  return (
    <ColoredGridFour
      style={{ gridTemplateColumns: "3fr 4fr 3fr 2fr" }}
      noColor={index % 2 !== 0}
    >
      <ResultValue>
        <DotRow>
          {useConfidenceLevel && (
            <Tooltip text={helpText}>
              <ConfidenceLevelDot
                confidenceLevel={generalCost.confidenceLevel}
              />
            </Tooltip>
          )}
          {`${
            amountUnitPrefixSuffix[generalCost.amountUnit]?.prefix ?? ""
          }${generalCost.amount} ${
            amountUnitPrefixSuffix[generalCost.amountUnit]?.suffix ?? ""
          }`}
        </DotRow>
      </ResultValue>
      <ResultValue title={generalCost.name}>{generalCost.name}</ResultValue>
      <ResultValue>{`${generalCost.costPerAmount} ${generalCost.unit}`}</ResultValue>
      <ResultValue>
        <strong>{`${generalCost.cost} `}</strong>
      </ResultValue>
    </ColoredGridFour>
  );
};

const GeneralCostPerCategory = ({
  id,
  type,
  title,
}: {
  id: FinanceId;
  type: CostType;
  title: string;
}) => {
  const { useCosts } = useCapexMillionPV(id);
  const { costs, sum } = useCosts(type);

  const projectId = useRecoilValue(projectIdSelector);
  const branchId = useRecoilValue(branchIdSelector);
  const selectedCostConfig = useRecoilValue(
    branchSelectedCostConfigurationAtom({ projectId, branchId }),
  );

  const useConfidenceLevel = selectedCostConfig?.useConfidenceLevel;

  return (
    <NewToggleableList
      title={title}
      chevronSize="1rem"
      defaultOpen={false}
      value={
        <ResultValue>
          {sum.state === "hasValue" ? (
            <>
              <strong>{sum.valueOrThrow()}</strong> m€
            </>
          ) : (
            "-"
          )}
        </ResultValue>
      }
      loading={costs.state === "loading"}
    >
      {orLoader(costs, (costs) => (
        <>
          {useConfidenceLevel && (
            <GeneralCostConfidenceBar key={type} generalCosts={costs} />
          )}
          {costs.map((c, index) => (
            <GeneralCostRow
              key={c.id}
              generalCost={c}
              index={index}
              useConfidenceLevel={useConfidenceLevel}
            />
          ))}
        </>
      ))}
    </NewToggleableList>
  );
};

const TurbinesCAPEX = ({ id }: { id: FinanceId }) => {
  return (
    <GeneralCostPerCategory
      id={id}
      type={CostType.Turbine}
      title={"Turbines"}
    />
  );
};

const InterArrayCablingCAPEX = ({ id }: { id: FinanceId }) => {
  return (
    <GeneralCostPerCategory
      id={id}
      type={CostType.Cable}
      title={"Inter array cabling"}
    />
  );
};

const MooringCAPEX = ({ id }: { id: FinanceId }) => {
  return (
    <GeneralCostPerCategory id={id} type={CostType.Mooring} title={"Mooring"} />
  );
};

const SubstationCAPEX = ({ id }: { id: FinanceId }) => {
  return (
    <GeneralCostPerCategory
      id={id}
      type={CostType.Substation}
      title={"Substations"}
    />
  );
};

const ExportCableCAPEX = ({ id }: { id: FinanceId }) => {
  return (
    <GeneralCostPerCategory
      id={id}
      type={CostType.ExportCable}
      title={"Export cables"}
    />
  );
};

const FoundationsCAPEX = ({ id }: { id: FinanceId }) => {
  return (
    <GeneralCostPerCategory
      id={id}
      type={CostType.Foundation}
      title={"Foundations"}
    />
  );
};

const OtherCapex = ({ id }: { id: FinanceId }) => {
  return (
    <GeneralCostPerCategory id={id} type={CostType.Other} title={"Other"} />
  );
};

export const CAPEX = ({ id }: { id: FinanceId }) => {
  const { scrollBodyRef } = useShowScrollShadow(true);
  const setModalType = useSetRecoilState(modalTypeOpenAtom);
  const year = currentYear();
  return (
    <>
      <ScrollBody ref={scrollBodyRef}>
        <TableWrapper>
          <InfoRow>
            <IconWrapper>
              <IconREMSize height={1.4} width={1.4}>
                <InfoIcon />
              </IconREMSize>
            </IconWrapper>

            <p>{year} numbers in m€</p>
          </InfoRow>
          <TurbinesCAPEX id={id} />
          <InterArrayCablingCAPEX id={id} />
          <FoundationsCAPEX id={id} />
          <MooringCAPEX id={id} />
          <SubstationCAPEX id={id} />
          <ExportCableCAPEX id={id} />
          <OtherCapex id={id} />
        </TableWrapper>
      </ScrollBody>
      <Divider />
      <BottomTableWrapper>
        <ButtonWrapper style={{ paddingTop: 0 }}>
          <DownloadCapexData id={id} buttonType={"text"} />
          <Button
            text="View in dashboard"
            buttonType="secondary"
            icon={<Dashboard />}
            onClick={() => {
              setModalType({
                modalType: DashboardModalType,
                metadata: { id: "vind-preset-dashboard-cost" },
              });
            }}
          />
        </ButtonWrapper>
      </BottomTableWrapper>
    </>
  );
};
