import { selectorFamily } from "recoil";
import { CONCRETE_DENSITY } from "../../../../../constants/foundations";
import { allFloaterTypesSelector } from "../../../../../state/foundations";
import { TurbineFeature } from "../../../../../types/feature";
import { isConcreteFoundation, isSteelFoundation } from "../utils";
import { FloatingTotalMaterial } from "./FloatingFoundationGeneration";
import { isDefined } from "utils/predicates";

export const getFloatingFoundationDetailsSelectorFamily = selectorFamily<
  FloatingTotalMaterial[],
  // @ts-ignore
  {
    tempLayoutFoundations: TurbineFeature[];
    scales: Record<string, number>;
    turbineTypeIdAndFloatingFoundationIdCombinations: {
      turbineTypeId: string;
      foundationId: string;
    }[];
  }
>({
  key: "getFloatingFoundationDetailsSelectorFamily",
  get:
    ({
      tempLayoutFoundations,
      scales,
      turbineTypeIdAndFloatingFoundationIdCombinations,
    }) =>
    ({ get }) => {
      const allFloaters = get(allFloaterTypesSelector);

      return tempLayoutFoundations
        .map((t) => {
          const turbine = turbineTypeIdAndFloatingFoundationIdCombinations.find(
            (type) =>
              type.turbineTypeId === t.properties.turbineTypeId &&
              type.foundationId === t.properties.foundationId,
          );

          const t_id = turbine?.turbineTypeId;
          const f_id = turbine?.foundationId;

          if (!t_id || !f_id) {
            return undefined;
          }

          const floater = allFloaters.find((f) => f.id === f_id);

          if (!floater) {
            return undefined;
          }

          const powScales = Math.pow(scales[`${t_id},${f_id}`], 3);

          let totalPrimarySteelMass = 0;
          let totalPrimaryConcreteMass = 0;
          let totalPrimaryConcreteVolume = 0;
          let totalRebarMass = 0;
          let totalPostTensMass = 0;
          let totalSolidBallastMass = 0;
          let totalLiquidBallastMass = 0;
          if (isSteelFoundation(floater)) {
            totalPrimarySteelMass += powScales * floater.primaryMass;
          } else if (isConcreteFoundation(floater)) {
            totalPrimaryConcreteMass += powScales * floater.primaryMass;
            totalPrimaryConcreteVolume +=
              (powScales * floater.primaryMass) / CONCRETE_DENSITY;
            if (floater.reinforceDensity) {
              totalRebarMass +=
                powScales *
                (floater.primaryMass / CONCRETE_DENSITY) *
                floater.reinforceDensity;
            }
            if (floater.postTensDensity) {
              totalPostTensMass +=
                powScales *
                (floater.primaryMass / CONCRETE_DENSITY) *
                floater.postTensDensity;
            }
          }

          totalSolidBallastMass += powScales * floater.solidBallastMass;
          totalLiquidBallastMass += powScales * floater.liquidBallastMass;

          return {
            totalPrimarySteelMass,
            totalPrimaryConcreteMass,
            totalPrimaryConcreteVolume,
            totalSolidBallastMass,
            totalLiquidBallastMass,
            totalRebarMass,
            totalPostTensMass,
          };
        })
        .filter(isDefined);
    },
});

export const getFloatingFoundationTotalsSelectorFamily = selectorFamily<
  FloatingTotalMaterial,
  // @ts-ignore
  {
    tempLayoutFoundations: TurbineFeature[];
    scales: Record<string, number>;
    turbineTypeIdAndFloatingFoundationIdCombinations: {
      turbineTypeId: string;
      foundationId: string;
    }[];
  }
>({
  key: "getFloatingFoundationTotalsSelectorFamily",
  get:
    ({
      tempLayoutFoundations,
      scales,
      turbineTypeIdAndFloatingFoundationIdCombinations,
    }) =>
    ({ get }) => {
      const totalDetails = get(
        getFloatingFoundationDetailsSelectorFamily({
          tempLayoutFoundations,
          scales,
          turbineTypeIdAndFloatingFoundationIdCombinations,
        }),
      );

      return totalDetails.reduce(
        (acc, t) => {
          acc.totalPrimarySteelMass += t.totalPrimarySteelMass;
          acc.totalPrimaryConcreteMass += t.totalPrimaryConcreteMass;
          acc.totalPrimaryConcreteVolume += t.totalPrimaryConcreteVolume;
          acc.totalSolidBallastMass += t.totalSolidBallastMass;
          acc.totalLiquidBallastMass += t.totalLiquidBallastMass;
          acc.totalRebarMass += t.totalRebarMass;
          acc.totalPostTensMass += t.totalPostTensMass;
          return acc;
        },
        {
          totalPrimarySteelMass: 0,
          totalPrimaryConcreteMass: 0,
          totalPrimaryConcreteVolume: 0,
          totalSolidBallastMass: 0,
          totalLiquidBallastMass: 0,
          totalRebarMass: 0,
          totalPostTensMass: 0,
        },
      );
    },
});
