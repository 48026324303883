import styled from "styled-components";
import Item from "./Item";
import { colors } from "styles/colors";
import { Subtitle } from "components/General/GeneralSideModals.style";

const LegendItems = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0.75rem;
`;

const items = [
  { label: "Exclusion zone", color: colors.exclusionZone },
  { label: "Substation", color: colors.substation },
  { label: "Sub area", color: colors.subArea },
  { label: "Cables", color: colors.rainbow },
  { label: "Turbine", color: colors.turbine },
  { label: "Anchor", color: colors.anchor },
];

export const MapLegend = () => {
  return (
    <>
      <Subtitle>Project elements</Subtitle>
      <LegendItems>
        {items.map(({ label, color }) => (
          <Item key={color} label={label} color={color} />
        ))}
      </LegendItems>
    </>
  );
};
