/// <reference types="vite-plugin-svgr/client" />
import { useMemo, createContext, useContext } from "react";
import { Node } from "services/customerAPI";
import Folder from "@icons/14/Folder.svg?react";
import Earth from "@icons/14/Earth.svg?react";
import OpenFolder from "@icons/14/OpenFolder.svg?react";
import useBooleanState from "hooks/useBooleanState";
import { Chevron } from "components/General/Chevron";
import {
  Margin,
  NameChevronWrapper,
  ProjectFolderRow,
  ProjectFolderListWrapper,
  SVGWrapper,
  SVGWrapperLevelised,
} from "./style";

const StateContext = createContext<
  | undefined
  | {
      selectedProjectId?: string | undefined;
      setSelectedProjectId?: React.Dispatch<
        React.SetStateAction<string | undefined>
      >;
    }
>(undefined);

const ProjectElement = ({
  projectNode,
  level,
}: {
  projectNode: Node;
  level: number;
}) => {
  const context = useContext(StateContext);
  return (
    <div>
      <NameChevronWrapper
        selectable={context && !!context.setSelectedProjectId}
        level={level}
        selected={context?.selectedProjectId === projectNode.id}
        onClick={() =>
          context &&
          context.setSelectedProjectId &&
          context.setSelectedProjectId(projectNode.id)
        }
      >
        <ProjectFolderRow>
          <SVGWrapper>
            <Earth />
          </SVGWrapper>
          <div>{projectNode.name}</div>
        </ProjectFolderRow>
      </NameChevronWrapper>
    </div>
  );
};

const FolderWithChildren = ({
  folderNode,
  allNodes,
  level,
}: {
  folderNode: Node;
  level: number;
  allNodes: Node[];
}) => {
  const [open, toggleOpen] = useBooleanState(false);

  const topNodes = useMemo(
    () => allNodes.filter((n) => n.parent_id === folderNode.id),
    [folderNode, allNodes],
  );

  const FolderIcon = open === true ? OpenFolder : Folder;

  return (
    <div>
      <NameChevronWrapper selectable={true} level={level} onClick={toggleOpen}>
        <ProjectFolderRow>
          <SVGWrapperLevelised level={level}>
            <FolderIcon />
          </SVGWrapperLevelised>
          <div>{folderNode.name}</div>
        </ProjectFolderRow>

        <Chevron open={open} size={"1rem"} />
      </NameChevronWrapper>
      {open && (
        <ChildrenNodesForParent
          allNodes={allNodes}
          level={level + 1}
          topNodes={topNodes}
        />
      )}
    </div>
  );
};

const ChildrenNodesForParent = ({
  topNodes,
  allNodes,
  level,
}: {
  topNodes: Node[];
  allNodes: Node[];
  level: number;
}) => {
  const sortedPersonalFolders = useMemo(
    () =>
      topNodes
        .filter((n) => n.type === "personal_folder")
        .sort((a, b) => a.name.localeCompare(b.name)),
    [topNodes],
  );
  const sortedFolders = useMemo(
    () =>
      topNodes
        .filter((n) => n.type === "folder")
        .sort((a, b) => a.name.localeCompare(b.name)),
    [topNodes],
  );
  const sortedProjects = useMemo(
    () =>
      topNodes
        .filter((n) => n.type === "project")
        .sort((a, b) => a.name.localeCompare(b.name)),
    [topNodes],
  );

  return (
    <Margin>
      {sortedPersonalFolders.map((n) => (
        <FolderWithChildren
          allNodes={allNodes}
          level={level}
          key={n.id}
          folderNode={n}
        />
      ))}
      {sortedFolders.map((n) => (
        <FolderWithChildren
          allNodes={allNodes}
          level={level}
          key={n.id}
          folderNode={n}
        />
      ))}
      {sortedProjects.map((n) => (
        <ProjectElement level={level} projectNode={n} key={n.id} />
      ))}
    </Margin>
  );
};

const ProjectPicker = ({
  topNodes,
  allNodes,
  selectedProjectId,
  setSelectedProjectId,
}: {
  topNodes: Node[];
  allNodes: Node[];
  selectedProjectId?: string | undefined;
  setSelectedProjectId?: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}) => {
  return (
    <StateContext.Provider value={{ selectedProjectId, setSelectedProjectId }}>
      <ProjectFolderListWrapper>
        <ChildrenNodesForParent
          allNodes={allNodes}
          level={1}
          topNodes={topNodes}
        />
      </ProjectFolderListWrapper>
    </StateContext.Provider>
  );
};

export default ProjectPicker;
