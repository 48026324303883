/// <reference types="vite-plugin-svgr/client" />
import styled from "styled-components";
import { Suspense, useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";
import ArrowDownIcon from "@icons/24/ArrowDown.svg?react";
import ArrowRightIcon from "@icons/24/ArrowRight.svg?react";
import Tooltip from "../General/Tooltip";
import { DropDownItem } from "../General/Dropdown/DropdownItems";
import { useProjectElementsCrud } from "../ProjectElements/useProjectElementsCrud";
import {
  MooringLineFeature,
  MooringLineSingleFeature,
} from "../../types/feature";
import { isDefined, isMooringLine } from "../../utils/predicates";
import { currentMooringLineTypesState } from "../../state/mooringLineType";
import { ProjectFeature } from "../../types/feature";
import { MenuItem } from "../General/Menu";
import { MenuButton } from "../General/MenuButton";

const TypeSelectorWrapper = styled.div`
  position: relative;
  font-size: 1.6rem;
  font-weight: 500;
`;

const TypeSelector = ({
  selectedLines,
  editable,
}: {
  selectedLines: MooringLineFeature[];
  editable: boolean;
}) => {
  const { update: updateFeatures } = useProjectElementsCrud();
  const allLineTypes = useRecoilValue(currentMooringLineTypesState);

  const onSelectItem = useCallback(
    (val: string) => {
      const updatedFeatures = selectedLines.map((l) => ({
        ...l,
        properties: {
          ...l.properties,
          lineType: val,
        },
      }));

      updateFeatures({ update: updatedFeatures });
    },
    [selectedLines, updateFeatures],
  );

  const currentLineType = useMemo(() => {
    const selectedLineTypes = new Set(
      selectedLines
        .map((l) => (l as MooringLineSingleFeature).properties.lineType) // safety: `lineType` is optional anyways. Not 100% OK, but whatever
        .filter(isDefined),
    );

    if (selectedLineTypes.size === 1) {
      const selectedLineTypeId = selectedLineTypes.values().next().value;
      return allLineTypes.find((lt) => lt.id === selectedLineTypeId);
    } else return null;
  }, [allLineTypes, selectedLines]);

  const items: DropDownItem[] = useMemo(() => {
    return allLineTypes.map((lt) => ({
      value: lt.id,
      name: lt.name,
    }));
  }, [allLineTypes]);

  return (
    <TypeSelectorWrapper>
      <MenuButton
        disabled={
          !editable || selectedLines.some((l) => "lineLengths" in l.properties)
        }
        icon={<ArrowRightIcon />}
        iconOpen={<ArrowDownIcon />}
        buttonStyle={{
          border: "none",
          flexDirection: "row-reverse",
          height: "fit-content",
          justifyContent: "space-between",
          padding: 0,
        }}
        buttonType="dropdown"
        buttonText={currentLineType ? currentLineType.name : "..."}
      >
        {items.map((item) => (
          <MenuItem
            key={item.value}
            name={item.name}
            onClick={() => onSelectItem(item.value)}
            icon={item.icon}
          />
        ))}
      </MenuButton>
    </TypeSelectorWrapper>
  );
};

export default function MooringLineTypeSelector({
  editable,
  selectedProjectFeatures,
}: {
  editable: boolean;
  selectedProjectFeatures: ProjectFeature[];
}) {
  const selectedMooringLineFeatures = useMemo(() => {
    const selectedLines = selectedProjectFeatures.filter(isMooringLine);

    return selectedLines.length >= 1 ? selectedLines : null;
  }, [selectedProjectFeatures]);

  if (!selectedMooringLineFeatures) return null;

  return (
    <Suspense fallback={null}>
      <Tooltip position="top" text="Change type" readonlyAware>
        <TypeSelector
          selectedLines={selectedMooringLineFeatures}
          editable={editable}
        />
      </Tooltip>
    </Suspense>
  );
}
