import { FeatureCollection } from "geojson";
import { fetchEnhancer } from "./utils";
import { toWGS84 } from "../utils/proj4";
import { funcOnCoords } from "../utils/utils";
import { GeometryNoCollection, _Feature } from "../utils/geojson/geojson";

export const getProj4StringForEPSG = async (epsg: number) => {
  const res = await fetchEnhancer(`https://epsg.io/${epsg}.proj4`, {
    method: "get",
  });
  return res.text();
};

export const featureCollectionToWGS84 = async (
  featureCollection: FeatureCollection<GeometryNoCollection>,
  epsg: number,
): Promise<FeatureCollection> => {
  if (epsg === 4326) return featureCollection;
  const res = await fetchEnhancer(`https://epsg.io/${epsg}.proj4`, {
    method: "get",
  });
  const proj4String = await res.text();
  return {
    ...featureCollection,
    features: featureCollection.features.map((f) =>
      _Feature.parse({
        ...f,
        geometry: {
          ...f.geometry,
          coordinates: funcOnCoords(f.geometry.coordinates, (c) =>
            toWGS84(c, proj4String),
          ),
        },
      }),
    ),
  };
};
