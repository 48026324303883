import { useEffect, useMemo } from "react";
import { Loadable, useRecoilCallback } from "recoil";
import {
  bathymetryAtomFamily,
  getBathymetry,
  getSlopeBathymetry,
} from "../state/bathymetry";
import {
  SlopeResponseError,
  SlopeResponseWithRaster,
  TileResponseError,
  TileResponseWithRaster,
} from "../types/bathymetryTypes";
import { Raster } from "types/raster";
import { useRecoilValueLoadable2 } from "utils/recoil";

/**
 * Fetches bathymetry for the given parameters and stores it in
 * {@link bathymetryAtomFamily}.
 */
export const useBathymetry = ({
  projectId,
  branchId,
  featureId,
  bufferKm,
}: {
  projectId: string;
  branchId?: string;
  featureId: string;
  bufferKm?: number;
}) => {
  const bathymetry = useRecoilValueLoadable2(
    getBathymetry({
      projectId,
      branchId,
      featureId,
      bufferKm,
    }),
  );
  const set = useRecoilCallback(
    ({ set }) =>
      (bath: TileResponseWithRaster | TileResponseError) => {
        set(bathymetryAtomFamily(bath.id), bath);
      },
    [],
  );

  useEffect(() => {
    bathymetry.map((bath) => {
      if (bath === undefined) return;
      set(bath);
    });
  }, [bathymetry, set]);

  return bathymetry;
};

/**
 * Convenience wrapper around {@link useBathymetry} for when you just want the {@link Raster}.
 * The loaded value is `undefined` if we failed to fetch the bathymetry.
 */
export const useBathymetryRaster = (
  args: Parameters<typeof useBathymetry>[0],
): Loadable<Raster | undefined> => {
  const bathymetry = useBathymetry(args);

  return useMemo(() => {
    return bathymetry.map((bath) => {
      if (bath === undefined) return;
      if (bath.status === "failed") return undefined;
      return bath.raster;
    });
  }, [bathymetry]);
};

/**
 * Fetches bathymetry for the given parameters and stores it in
 * {@link bathymetryAtomFamily}.
 */
export const useSlopeBathymetry = (args: {
  projectId: string;
  branchId?: string;
  featureId: string;
  bufferKm?: number;
}) => {
  const bathymetry = useRecoilValueLoadable2(getSlopeBathymetry(args));
  const set = useRecoilCallback(
    ({ set }) =>
      (bath: SlopeResponseWithRaster | SlopeResponseError) => {
        set(bathymetryAtomFamily(bath.id), bath);
      },
    [],
  );

  useEffect(() => {
    bathymetry.map((bath) => {
      if (bath === undefined) return;
      set(bath);
    });
  }, [bathymetry, set]);

  return bathymetry;
};

/**
 * Convenience wrapper around {@link useBathymetry} for when you just want the {@link Raster}.
 * The loaded value is `undefined` if we failed to fetch the bathymetry.
 */
export const useSlopeBathymetryRaster = (
  args: Parameters<typeof useBathymetry>[0],
): Loadable<Raster | undefined> => {
  return useSlopeBathymetry(args).map((bath) => {
    if (bath === undefined) return;
    if (bath.status === "failed") return undefined;
    return bath.raster;
  });
};
