import { useEffect, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { mapRefAtom } from "../../state/map";
import {
  useDynamicStreamer,
  visibleDynamicLayersAtom,
} from "../../state/layer";
import Point from "../../components/MapFeatures/Point";
import { ProjectFeature } from "../../types/feature";
import useSelectionInMap from "../../hooks/useSelectionInMap";
import { layerSettingSelectorFamily } from "../../components/LayerList/LayerSettings/state";
import { useTypedPath } from "../../state/pathParams";
import { useLayoutFilter } from "../../hooks/map";
import {
  filterFeatureCollectionAccordingToType,
  getLayerSymbols,
  layerToSourceId,
} from "./utils";
import { externalWFSAnchorId } from "../../components/Mapbox/utils";
import { CustomLayer, Layer, LayerType } from "../../types/layers";
import { getVisibleLayers } from "../../components/LayerList/LayerSettings/utils";
import {
  ErrorBoundarySilent,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "../../components/ErrorBoundaries/ErrorBoundaryLocal";
import stc from "string-to-color";
import { sendInfo } from "../../utils/sentry";
import { isCustomLayer } from "../../components/LayerList/utils";
import { isTileJSONLayer } from "../../state/tileJSON";
import { arcgisLayerDrawingInfoSelector } from "../../state/arcgisRestAPI";
import { rgbToHex } from "../../utils/image";
import { _GeoJSONFeatureToOtherFeature } from "../../types/feature";
import { addIdOnFeatureCollectionIfNotUUID4 } from "utils/geojson/utils";

const POINT_TYPES = ["MultiPoint", "Point"];
export const CIRCLE_LAYERS_SUFFIX = "-circle";

const getCirclePaint = (
  color: mapboxgl.CirclePaint["circle-color"],
): mapboxgl.CirclePaint => ({
  "circle-color": color,
  "circle-radius": 3,
});

const DynamicCircleLayers = ErrorBoundaryWrapper(
  () => {
    const { projectId } = useTypedPath("projectId");
    const visibleLayers = useRecoilValue(getVisibleLayers({ projectId }));

    const selectedDynamicCircleLayers = visibleLayers.filter(
      (layer) =>
        !isTileJSONLayer(layer) &&
        (layer.type === LayerType.Circle ||
          isCustomLayer(layer) ||
          layer.type === LayerType.FeatureCollection),
    );

    return (
      <>
        {selectedDynamicCircleLayers.map((layer) => (
          <DynamicCircleLayer key={layer.id} layer={layer} />
        ))}
      </>
    );
  },
  ErrorBoundarySilent,
  ScreamOnError,
);

const DynamicCircleLayer = ({ layer }: { layer: Layer | CustomLayer }) => {
  const { onExternalLayerClick, getIdsOfSelectedExternalLayers } =
    useSelectionInMap();
  const { projectId } = useTypedPath("projectId");
  const setVisibleDynamicLayers = useSetRecoilState(visibleDynamicLayersAtom);
  const { dynamicVectorLayerFeatures } = useDynamicStreamer(layer);
  const filteredGeojson = useMemo(
    () =>
      isCustomLayer(layer)
        ? filterFeatureCollectionAccordingToType(
            dynamicVectorLayerFeatures,
            "Point",
          )
        : dynamicVectorLayerFeatures,
    [layer, dynamicVectorLayerFeatures],
  );
  const drawingInfo = useRecoilValue(arcgisLayerDrawingInfoSelector({ layer }));
  const features: ProjectFeature[] = useMemo(() => {
    const featuresFiltered = addIdOnFeatureCollectionIfNotUUID4({
      type: "FeatureCollection",
      features: filteredGeojson.features,
    }).features;
    if (layer.type === "circle")
      return _GeoJSONFeatureToOtherFeature.array().parse(featuresFiltered);

    const filtered = featuresFiltered.filter((f) => {
      if (f.geometry == null) {
        sendInfo("feature is missing geometry", { f, layer });
        return false;
      }
      return POINT_TYPES.includes(f.geometry.type);
    });
    return _GeoJSONFeatureToOtherFeature.array().parse(filtered);
  }, [filteredGeojson, layer]);
  const map = useRecoilValue(mapRefAtom);

  const layerId = layer.id + CIRCLE_LAYERS_SUFFIX;
  const sourceId = useMemo(
    () => layerToSourceId(layer, CIRCLE_LAYERS_SUFFIX),
    [layer],
  );
  const selectedIds = useMemo(
    () => getIdsOfSelectedExternalLayers(features),
    [getIdsOfSelectedExternalLayers, features],
  );

  useEffect(() => {
    setVisibleDynamicLayers((vdl) => [...vdl, layerId]);
    return () =>
      setVisibleDynamicLayers((vdl) => vdl.filter((l) => l !== layerId));
  }, [setVisibleDynamicLayers, layerId]);

  const filter = useLayoutFilter(sourceId);

  const layerSettings = useRecoilValue(
    layerSettingSelectorFamily({
      projectId,
      layerId: layer.id,
    }),
  );

  const color = useMemo(() => {
    if (
      !layerSettings.overrideLayerStyle &&
      drawingInfo?.type === "simple" &&
      drawingInfo.symbol.color
    ) {
      const [r, g, b] = drawingInfo.symbol.color;
      return rgbToHex(r, g, b);
    }
    if (layerSettings.layerStyle?.color) {
      return layerSettings.layerStyle.color;
    }
    return stc(layer.id);
  }, [layer, layerSettings, drawingInfo]);

  const opacity = useMemo(() => {
    if (
      !layerSettings.overrideLayerStyle &&
      drawingInfo?.type === "simple" &&
      drawingInfo.symbol.color
    ) {
      return (
        (1 / 255) *
        drawingInfo.symbol.color[drawingInfo.symbol.color.length - 1]
      );
    }
    return layerSettings.layerStyle?.opacity ?? 0.6;
  }, [layerSettings, drawingInfo]);

  const pinnedProperties = useMemo(() => {
    return layerSettings.layerStyle?.pinnedProperties;
  }, [layerSettings.layerStyle]);

  const pinnedSymbols = useMemo(
    () => getLayerSymbols(pinnedProperties),
    [pinnedProperties],
  );

  const paint = useMemo(() => {
    return getCirclePaint(color);
  }, [color]);

  if (!map) return null;

  return (
    <Point
      map={map}
      features={features}
      layerId={layerId}
      sourceId={sourceId}
      symbols={pinnedSymbols}
      onClickCallback={onExternalLayerClick}
      filter={filter}
      paint={paint}
      opacity={opacity}
      selectedIds={selectedIds}
      beforeLayer={externalWFSAnchorId}
      zoomLevels={layerSettings.layerStyle?.zoomLevels}
      cluster={true}
    />
  );
};

export default DynamicCircleLayers;
