import { useCallback, useMemo } from "react";
import { useMouseSampler } from "../../hooks/mouseSampler";
import { getSimpleGebcoBathymetryTile } from "../../services/bathymertyService";
import { fetchEnhancerWithToken } from "../../services/utils";
import { isDefined } from "../../utils/predicates";
import { BathymetryFeature } from "../../types/feature";
import { projectIdSelector } from "state/pathParams";
import { useRecoilValue } from "recoil";
import { NO_DATA_VAL } from "./constants";
import { decodeTerrariumDepth } from "utils/bathymetry";

const SAMPLE_ZOOM = 11;

export const decodeBathymetry = (sample: number[]): number => {
  return -32768 + (sample[0] * 256 + sample[1] + sample[2] / 256);
};

export const useCustomDataMouseSample = (
  layer: BathymetryFeature,
): { name?: string; depth?: number } => {
  const projectId = useRecoilValue(projectIdSelector);
  const getCustomBathymetrySource = useCallback(
    (x: number, y: number, z: number, tileSize: number) =>
      fetchEnhancerWithToken(
        `/api/bathymetry/custom/${projectId}/${layer.properties.filename}/${x}/${y}/${z}?include_land=true&tilesize=${tileSize}`,
        {
          method: "get",
          headers: {},
        },
      ),
    [layer, projectId],
  );

  const sample = useMouseSampler(
    getCustomBathymetrySource,
    SAMPLE_ZOOM,
    1024,
    layer.geometry.coordinates,
  );

  const depth = useMemo(
    () => (isDefined(sample) ? decodeBathymetry(sample) : NO_DATA_VAL),
    [sample],
  );

  const d = depth <= NO_DATA_VAL ? undefined : depth;
  return { name: layer.properties.name, depth: d };
};

export const useDepthUnderMouseSampler = (): {
  depth?: number;
  source?: string;
} => {
  const sample = useMouseSampler(getSimpleGebcoBathymetryTile, 7, 512);
  const depth = useMemo(
    () =>
      isDefined(sample)
        ? decodeTerrariumDepth({ r: sample[0], g: sample[1], b: sample[2] })
        : NO_DATA_VAL,
    [sample],
  );

  if (!sample) return {};
  return {
    depth: Math.round(depth / 5) * 5,
    source: "Gebco",
  };
};
