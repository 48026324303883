/// <reference types="vite-plugin-svgr/client" />
import React from "react";
import { useRecoilState } from "recoil";
import { Mixpanel } from "mixpanel";
import HyperlinkIcon from "@icons/24/Hyperlink.svg?react";
import WindowExpandIcon from "@icons/24/WindowExpand.svg?react";
import WindowMinimizeIcon from "@icons/24/WindowMinimize.svg?react";
import { useToast } from "hooks/useToast";
import { spaceDecent, spaceHuge, spaceLarge, spaceMedium } from "styles/space";
import { typography } from "styles/typography";
import { portfolioFullScreenAtom } from "state/portfolio";
import Button from "components/General/Button";
import TotalPortfolioCapacity from "./TotalPortfolioCapacity";
import SelectParksFilterButton from "./SelectParksFilterButton";
import Tooltip from "components/General/Tooltip";
import {
  ARTICLE_PORTFOLIO,
  HelpLink,
} from "components/HelpTooltip/HelpTooltip";

const PortfolioTopRow = () => {
  const [isFullScreen, setFullScreen] = useRecoilState(portfolioFullScreenAtom);
  const { success: showSuccess } = useToast();

  return (
    <div
      style={{
        display: "flex",
        marginBottom: spaceLarge,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: spaceHuge,
          alignItems: "center",
          overflow: "visible",
        }}
      >
        <p style={{ ...typography.regular, margin: 0 }}>Filters:</p>
        <React.Suspense fallback={null}>
          <SelectParksFilterButton />
        </React.Suspense>
        <div
          style={{ display: "flex", gap: spaceDecent, alignItems: "center" }}
        >
          <p style={{ ...typography.regular, margin: 0 }}>Total capacity:</p>
          <React.Suspense fallback={null}>
            <p style={{ ...typography.regular, margin: 0 }}>
              <TotalPortfolioCapacity />
            </p>
          </React.Suspense>
        </div>
      </div>
      <div style={{ display: "flex", gap: spaceMedium }}>
        <HelpLink article={ARTICLE_PORTFOLIO} />
        <Tooltip text="Toggle full screen">
          <Button
            buttonType="secondary"
            icon={isFullScreen ? <WindowMinimizeIcon /> : <WindowExpandIcon />}
            onClick={() => {
              Mixpanel.track("Toggle portfolio full screen", {
                isFullScreen: !isFullScreen,
              });
              setFullScreen((curr) => !curr);
            }}
          />
        </Tooltip>
        <Tooltip text="Copy link to portfolio">
          <Button
            buttonType="secondary"
            icon={<HyperlinkIcon />}
            onClick={() => {
              Mixpanel.track("Copy Portfolio link", {});
              navigator.clipboard.writeText(document.location.toString());
              showSuccess("Copied", { timeout: 2000 });
            }}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default PortfolioTopRow;
