export function binarySearchClosest(list: number[], value: number): number {
  let left = 0;
  let right = list.length - 1;
  let closest = 0;
  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    if (list[mid] === value) {
      return mid;
    } else if (list[mid] < value) {
      left = mid + 1;
    } else {
      right = mid - 1;
    }
    if (Math.abs(list[mid] - value) < Math.abs(list[closest] - value)) {
      closest = mid;
    }
  }
  return closest;
}

export function linearInterpolation(
  originalXY: [number, number][],
  newX: number[],
): number[] {
  const newY: number[] = [];

  const sortedXY = originalXY.sort((a, b) => a[0] - b[0]);
  const sortedX = sortedXY.map((vals) => vals[0]);
  const sortedY = sortedXY.map((vals) => vals[1]);

  for (let i = 0; i < newX.length; i++) {
    const x = newX[i];

    if (x <= sortedX[0]) {
      newY.push(sortedY[0]);
    } else if (x >= sortedX[sortedX.length - 1]) {
      newY.push(sortedY[sortedY.length - 1]);
    } else {
      let lowerIdx = binarySearchClosest(sortedX, x);

      if (x < sortedX[lowerIdx]) lowerIdx -= 1;

      const x0 = sortedX[lowerIdx];
      const y0 = sortedY[lowerIdx];
      const x1 = sortedX[lowerIdx + 1];
      const y1 = sortedY[lowerIdx + 1];

      const y = y0 + ((x - x0) * (y1 - y0)) / (x1 - x0);
      newY.push(y);
    }
  }

  return newY;
}
