import {
  ResultValue,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import minMax from "../../../../../utils/minMax";
import { ColoredGrid } from "../../../../General/Form";
import { valueRounding } from "../utils";

export const SimpleFixedSingleStats = ({
  name,
  masses,
}: {
  name: string;
  masses: { turbineTypeId: string; foundationId: string; mass: number }[];
}) => {
  const numberOfTurbines = masses.length;

  const massStats = minMax(masses, (d) => d.mass);
  const massMin = massStats && valueRounding(massStats.min / 1000, 10);
  const massMax = massStats && valueRounding(massStats.max / 1000, 10);

  return (
    <>
      <SubtitleWithLine text={name} />
      <ColoredGrid
        style={{
          gridTemplateColumns: "2.5fr 1.5fr",
        }}
      >
        {<ResultValue>Primary steel weight(s)</ResultValue>}
        {numberOfTurbines !== 1 && massStats && (
          <ResultValue>
            <strong>
              {massMin?.toLocaleString()} - {massMax?.toLocaleString()}
            </strong>{" "}
            t
          </ResultValue>
        )}
        {numberOfTurbines === 1 && massStats && (
          <ResultValue>
            <strong>{massMax}</strong> t
          </ResultValue>
        )}
      </ColoredGrid>
    </>
  );
};
