// RowResizer.tsx
import { DnDRow } from "./types";
import { Resizer, ResizerContainer, RowDropContainer } from "./DnDStyles";
import { useCallback, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { resizeInProgressState } from "./state";
import { useDrop } from "react-dnd";

type RowResizerProps = {
  row: DnDRow;
  adjustRowHeight: (rowId: string, newHeight: number) => void;
  rowRef: React.RefObject<HTMLDivElement>;
  onDrop: (draggedId: string) => void;
  disabled?: boolean;
};

export const RowResizer = ({
  row,
  adjustRowHeight,
  rowRef,
  onDrop,
  disabled,
}: RowResizerProps) => {
  const [isResizing, setIsResizing] = useState(false);
  const setResizeInProgress = useSetRecoilState(resizeInProgressState);

  const handleMouseDown = () => {
    setIsResizing(true);
    setResizeInProgress(true);
  };

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isResizing) {
        e.stopPropagation();
        e.preventDefault();
        if (rowRef.current) {
          const rect = rowRef.current.getBoundingClientRect();
          const gridScrollTop = rowRef.current.parentElement?.scrollTop ?? 0;
          const newHeight = e.clientY - rect.top - gridScrollTop;

          adjustRowHeight(row.id, newHeight);
        }
      }
    },
    [adjustRowHeight, isResizing, row.id, rowRef],
  );

  const handleMouseUp = useCallback(() => {
    setIsResizing(false);
    setResizeInProgress(false);
  }, [setResizeInProgress]);

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  const [isHovered, setIsHovered] = useState(false);
  const [{ isOver }, dropRef] = useDrop(() => ({
    accept: "element",
    drop: (item: { id: string; type: string }) => {
      onDrop(item.id);
    },
    hover: () => {
      setIsHovered(true);
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
      };
    },
  }));
  useEffect(() => {
    if (!isOver) {
      setIsHovered(false);
    }
  }, [isOver]);

  if (disabled) return <RowDropContainer />;

  return (
    <ResizerContainer
      ref={dropRef}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <Resizer isHovered={isHovered} />
    </ResizerContainer>
  );
};
export const RowDrop = ({
  onDrop,
  disabled,
}: {
  onDrop: (draggedId: string) => void;
  disabled?: boolean;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [{ isOver }, dropRef] = useDrop(() => ({
    accept: "element",
    drop: (item: { id: string; type: string }) => {
      onDrop(item.id);
    },
    hover: () => {
      setIsHovered(true);
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
      };
    },
  }));
  useEffect(() => {
    if (!isOver) {
      setIsHovered(false);
    }
  }, [isOver]);

  if (disabled) return <RowDropContainer />;

  return (
    <RowDropContainer ref={dropRef}>
      <Resizer isHovered={isHovered} />
    </RowDropContainer>
  );
};
