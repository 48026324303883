/// <reference types="vite-plugin-svgr/client" />
import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { mapInteractionSelector } from "../../../../state/map";
import PasteIcon from "@icons/24/Paste.svg?react";
import useSystemSpecificUnicode from "../../../../hooks/useSystemSpecificUnicode";
import useCloneFeaturesWithDefaultCanvasSource from "../../../../hooks/useCloneFeaturesWithDefaultCanvasSource";
import {
  getClipboardFeaturesIfContainingVindFeatures,
  paste,
} from "../../../MapControls/CopyPasteSelectedFeature";
import { MenuItem } from "../../../General/Menu";

const PasteRowMenuItem = ({ closeMenu }: { closeMenu: () => void }) => {
  const stringToUnicode = useSystemSpecificUnicode();
  const cloneFeatures = useCloneFeaturesWithDefaultCanvasSource();
  const [enabled, setEnabled] = useState(false);
  const mapInteractions = useRecoilValue(mapInteractionSelector);

  const pasteCallback = useCallback(() => {
    paste(cloneFeatures, () => {});
  }, [cloneFeatures]);

  useEffect(() => {
    const asyncFunc = async () => {
      const json = await getClipboardFeaturesIfContainingVindFeatures();
      setEnabled(!!json);
    };
    asyncFunc();
  }, []);

  return (
    <MenuItem
      disabled={!enabled || !mapInteractions.paste}
      name={"Paste"}
      icon={<PasteIcon />}
      shortcut={`${stringToUnicode("command")}+V`}
      onClick={() => {
        pasteCallback();
        closeMenu();
      }}
    />
  );
};

export default PasteRowMenuItem;
