/// <reference types="vite-plugin-svgr/client" />
import styled from "styled-components";
import { colors } from "styles/colors";
import { typography } from "styles/typography";
import { GridWithFiveColumns } from "../styles";

const Title = styled.div`
  ${typography.sub3}
`;

const BlueDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.blue500}};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 100%;
  padding: 2rem 0;
`;

export const CostTopRow = () => {
  return (
    <Wrapper>
      <GridWithFiveColumns style={{ padding: 0 }}>
        <Title>Type</Title>
        <Title>Cost</Title>
        <Title>Occurance</Title>
        <Title>Frequency</Title>
      </GridWithFiveColumns>
      <BlueDivider />
    </Wrapper>
  );
};
