import styled from "styled-components";
import { colors } from "../../styles/colors";
import { spaceMedium, spaceTiny } from "../../styles/space";
import { Container } from "../../styles/misc/Misc";
import { typography } from "styles/typography";

/** Container to hold the (?) Help pill and the lower right menu bars. */
export const LowerRightColumnWrapper = styled(Container)`
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
`;

export const RowWrapperV2 = styled(Container)`
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
  align-items: center;
`;

/** Wrapper for the whole array of icons. */
export const LowerRightWrapper = styled(Container)`
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
`;

export const LowerRightColumnWrapperV2 = styled(Container)`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;

  gap: ${spaceMedium};
`;

export const HelpToolsWrapper = styled(Container)`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  align-self: flex-end;
`;

export const BlueHelpPill = styled.div`
  ${typography.sub2}
  background-color: ${colors.surfaceBrand};
  border-radius: 2rem;
  padding: 0.6rem 0.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spaceTiny};
  justify-content: center;
  width: fit-content;

  cursor: pointer;

  p {
    color: white;
    margin: 0;
    padding: 0 0.4rem 0 0;
  }
`;

export const HelpText = styled.div`
  ${typography.sub2}
  color: ${colors.white};
`;
