import styled from "styled-components";
import { colors } from "../../styles/colors";
import { Map } from "mapbox-gl";
import { getBearingFromTwoPoints, insideBounds } from "../../utils/geometry";
import * as turf from "@turf/turf";

const MAP_POPUP_PLACEMENT_BUFFER = 0.45;

export const Wrapper = styled.div`
  display: none;
`;

export const Popup = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.6rem 1.2rem;
  align-items: center;

  > :not(:last-child) {
    border-right: 1px solid ${colors.inputOutline};
    margin-right: 1rem;
    padding-right: 1rem;
    min-height: 2.2rem;
  }
`;

export const SVGWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  path {
    opacity: ${(p) => (p.disabled ? "0.5" : "1.0")};
    stroke: ${colors.brand};
  }
`;

export const getPopupPlacementMultiFeature = (
  featureCenterPosition: number[],
  map?: Map,
) =>
  getPopupPlacement(
    { lng: featureCenterPosition[0], lat: featureCenterPosition[1] },
    map,
  );

export const getPopupPlacement = (
  featureCenterPosition: {
    lng: number;
    lat: number;
  },
  map?: Map,
) => {
  if (!map) return featureCenterPosition;
  const mapBounds = map.getBounds().toArray().flat() as [
    number,
    number,
    number,
    number,
  ];
  const featureTopCenterArray = [
    featureCenterPosition.lng,
    featureCenterPosition.lat,
  ] as [number, number];
  const insideMapBounds = insideBounds(featureTopCenterArray, mapBounds);
  if (insideMapBounds || map.isMoving()) return featureCenterPosition;

  const mapCenter = map.getCenter().toArray() as [number, number];
  const bearing = getBearingFromTwoPoints(mapCenter, featureTopCenterArray);

  const minDistance =
    Math.min(
      turf.distance(
        turf.point([mapBounds[0], mapBounds[1]]),
        turf.point([mapBounds[2], mapBounds[1]]),
        { units: "kilometers" },
      ),
      turf.distance(
        turf.point([mapBounds[0], mapBounds[1]]),
        turf.point([mapBounds[0], mapBounds[3]]),
        { units: "kilometers" },
      ),
    ) * MAP_POPUP_PLACEMENT_BUFFER;
  const movedPoint = turf.transformTranslate(
    turf.point([mapCenter[0], mapCenter[1]]),
    minDistance,
    bearing,
  );
  return {
    lng: movedPoint.geometry.coordinates[0],
    lat: movedPoint.geometry.coordinates[1],
  };
};

export const CommentWrapper = styled.div<{ disabled: boolean }>`
  opacity: ${(p) => (p.disabled ? "0.5" : "1.0")};
  display: flex;
`;
