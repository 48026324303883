import { useCallback } from "react";
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import { useRecoilValueDef } from "utils/recoil";
import {
  branchIdSelector,
  organisationIdSelector,
  projectIdSelector,
} from "state/pathParams";
import {
  portfolioItemsSimpleAtom,
  mappedPortfolioItemsStoreAtom,
  portfolioRefreshAtom,
} from "state/portfolio";
import { useToast } from "hooks/useToast";
import { getParkFeatureInBranchSelector } from "state/park";
import { initializeAndSet } from "components/Comments/hooks/useReplyReactionCrud";
import { sendWarning } from "utils/sentry";
import { fetchEnhancerWithToken } from "services/utils";
import { capitalize } from "utils/utils";

const addParkToPortfolio = (
  organisationId: string,
  projectId: string,
  branchId: string,
  parkId: string,
) => {
  return fetchEnhancerWithToken(
    `/api/portfolio/${organisationId}/${projectId}/${branchId}/${parkId}`,
    {
      method: "PUT",
    },
  );
};

const removeParkFromPortfolio = (
  organisationId: string,
  projectId: string,
  branchId: string,
  parkId: string,
) => {
  return fetchEnhancerWithToken(
    `/api/portfolio/${organisationId}/${projectId}/${branchId}/${parkId}`,
    {
      method: "DELETE",
    },
  );
};

const usePortfolioService = () => {
  const organisationId = useRecoilValueDef(organisationIdSelector);
  const _projectId = useRecoilValue<string | undefined>(projectIdSelector);
  const _branchId = useRecoilValue<string | undefined>(branchIdSelector);
  const { success: showSuccess, error: showError } = useToast();
  const setPortfolioRefresh = useSetRecoilState(portfolioRefreshAtom);

  const refreshPortfolio = useCallback(() => {
    setPortfolioRefresh((curr) => curr + 1);
  }, [setPortfolioRefresh]);

  const getParkName = useRecoilCallback(
    ({ snapshot }) =>
      async (parkId: string, branchId: string) => {
        const park = await snapshot.getPromise(
          getParkFeatureInBranchSelector({ parkId, branchId }),
        );
        return park?.properties.name;
      },
    [],
  );

  const create = useRecoilCallback(
    ({ snapshot, set }) =>
      async (
        parkId: string,
        projectId: string | undefined = _projectId,
        branchId: string | undefined = _branchId,
      ) => {
        if (!parkId || !projectId || !branchId) {
          sendWarning("usePortfolioService.remove: something was undefined", {
            parkId,
            projectId,
            branchId,
          });
          return;
        }

        const parkName = (await getParkName(parkId, branchId)) ?? "the park";
        const fallbackPortfolioItemsSimple = await snapshot.getPromise(
          portfolioItemsSimpleAtom,
        );
        try {
          initializeAndSet(snapshot, set, portfolioItemsSimpleAtom, (curr) => [
            ...curr,
            {
              projectId,
              branchId,
              parkId,
            },
          ]);
          await addParkToPortfolio(organisationId, projectId, branchId, parkId);
          refreshPortfolio();

          showSuccess(
            `${capitalize(parkName)} was added to the organisation portfolio.`,
            {
              timeout: 5000,
            },
          );
        } catch (error) {
          showError(
            `Something went wrong when adding ${parkName} to the organisation portfolio. Please try again.`,
            {
              timeout: 5000,
            },
          );
          set(portfolioItemsSimpleAtom, fallbackPortfolioItemsSimple);
        }
      },
    [
      organisationId,
      _projectId,
      _branchId,
      refreshPortfolio,
      getParkName,
      showSuccess,
      showError,
    ],
  );

  const remove = useRecoilCallback(
    ({ snapshot, set }) =>
      async (
        parkId: string,
        projectId: string | undefined = _projectId,
        branchId: string | undefined = _branchId,
      ) => {
        if (!parkId || !projectId || !branchId) {
          sendWarning("usePortfolioService.remove: something was undefined", {
            parkId,
            projectId,
            branchId,
          });
          return;
        }
        const parkName = (await getParkName(parkId, branchId)) ?? "the park";

        const fallbackPortfolioItemsSimple = await snapshot.getPromise(
          portfolioItemsSimpleAtom,
        );
        const fallbackPortfolioItemsStore = await snapshot.getPromise(
          mappedPortfolioItemsStoreAtom,
        );

        try {
          initializeAndSet(snapshot, set, portfolioItemsSimpleAtom, (curr) =>
            curr.filter((item) => {
              return (
                item.parkId !== parkId ||
                item.branchId !== branchId ||
                item.projectId !== projectId
              );
            }),
          );
          initializeAndSet(
            snapshot,
            set,
            mappedPortfolioItemsStoreAtom,
            (curr) =>
              curr.filter((item) => {
                return (
                  item.park.id !== parkId ||
                  item.branchId !== branchId ||
                  item.project.id !== projectId
                );
              }),
          );
          await removeParkFromPortfolio(
            organisationId,
            projectId,
            branchId,
            parkId,
          );
          showSuccess(
            `${capitalize(
              parkName,
            )} was removed from the organisation portfolio.`,
            {
              timeout: 5000,
            },
          );
        } catch (error) {
          showError(
            `Something went wrong when removing ${parkName} from the organisation portfolio. Please try again.`,
            {
              timeout: 5000,
            },
          );
          set(portfolioItemsSimpleAtom, fallbackPortfolioItemsSimple);
          set(mappedPortfolioItemsStoreAtom, fallbackPortfolioItemsStore);
        }
      },
    [
      showError,
      organisationId,
      _projectId,
      _branchId,
      getParkName,
      showSuccess,
    ],
  );

  const isPartOfPortfolio = useRecoilCallback(
    ({ snapshot }) =>
      (parkId: string) => {
        const portfolioItems = snapshot.getLoadable(portfolioItemsSimpleAtom);

        return portfolioItems
          .getValue()
          .some(
            (item) =>
              item.projectId === _projectId &&
              item.branchId === _branchId &&
              item.parkId === parkId,
          );
      },
    [_branchId, _projectId],
  );

  return {
    create,
    remove,
    isPartOfPortfolio,
    refreshPortfolio,
    organisationId,
  };
};

export default usePortfolioService;
