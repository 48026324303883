import styled from "styled-components";
import { spacing1, spacing7 } from "styles/space";
import { colors } from "styles/colors";
import { Column } from "components/General/Layout";

export const BranchesTableWrapper = styled(Column)`
  overflow-y: auto;
  gap: 0;

  & > :nth-child(2n + 1) {
    background-color: ${colors.grey50};
  }

  > div {
    cursor: pointer;

    &:hover {
      background-color: ${colors.grey100};
    }
  }
`;

export const VisibleOnHoverIfSortingEnabled = styled.div`
  display: flex;
  align-items: center;
  visibility: hidden;
`;

export const BranchTableItemWrapper = styled.div<{
  isHoveredBottom: boolean;
  isHoveredTop: boolean;
  sortingEnabled: boolean;
  active: boolean;
}>`
  position: relative;
  padding: 1rem 0.5rem 1rem ${spacing1};
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  gap: ${spacing7};

  &:hover {
    p.branch-name {
      color: ${colors.blue600};
    }
  }

  ${({ active }) =>
    active && `&&& { background-color: ${colors.surfaceSelectedLight}; }`}

  ${({ sortingEnabled }) =>
    sortingEnabled &&
    `
    &:hover {
      ${VisibleOnHoverIfSortingEnabled} {
        visibility: visible;
      }
    }
  `}

  ${({ isHoveredBottom, isHoveredTop }) => {
    if (isHoveredTop) {
      return `
        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: ${colors.blue900};
          top: 0;
          left: 0;
        }
      `;
    }

    if (isHoveredBottom) {
      return `
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: ${colors.blue900};
          bottom: 0;
          left: 0;
        }
      `;
    }
  }};
`;
