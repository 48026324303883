import { useRecoilValueLoadable } from "recoil";
import SinglePerson from "@icons/24/SinglePerson.svg?react";
import { allNodeUsersSelectorFamily } from "components/Projects/state";
import { Row } from "components/General/Layout";
import { Icon } from "components/General/Icons";
import { colors } from "styles/colors";
import { SkeletonRound } from "components/Loading/Skeleton";
import { SecondaryText } from "components/Projects/ProjectGrid.style";
import Tooltip from "components/General/Tooltip";

const NumberOfCollaboratorsV2 = ({
  nodeId,
  organisationId,
}: {
  nodeId: string;
  organisationId: string;
}) => {
  const projectMembersLoadable = useRecoilValueLoadable(
    allNodeUsersSelectorFamily({ organisationId, nodeId }),
  );

  if (projectMembersLoadable.state !== "hasValue")
    return (
      <Row style={{ alignItems: "center", gap: "0.4rem" }}>
        <Icon size={"1.4rem"} stroke={colors.secondaryText}>
          <SinglePerson />
        </Icon>
        <SkeletonRound size={14} />
      </Row>
    );

  const plural = projectMembersLoadable.contents.length !== 1;
  return (
    <Row style={{ alignItems: "center", gap: "0.4rem" }}>
      <Tooltip
        theme="light"
        text={`There ${plural ? "are" : "is"} ${projectMembersLoadable.contents.length} collaborator${plural ? "s" : ""} in this project`}
        position="bottom"
      >
        <Icon size={"1.4rem"} stroke={colors.secondaryText}>
          <SinglePerson />
        </Icon>
        <SecondaryText style={{ justifyContent: "flex-end" }}>
          {projectMembersLoadable.contents.length}
        </SecondaryText>
      </Tooltip>
    </Row>
  );
};

export default NumberOfCollaboratorsV2;
