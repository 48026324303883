/// <reference types="vite-plugin-svgr/client" />
import {
  findTopLevelNode,
  getNodesWithMissingParents,
  nodesInOrganisationSelectorFamily,
  useOrganisationNodeCrud,
} from "components/Projects/useOrganisationFolderCrud";
import { Node } from "services/customerAPI";
import useBooleanState from "hooks/useBooleanState";
import { useCallback, useEffect, useMemo, useRef } from "react";
import {
  useRecoilValue,
  useRecoilCallback,
  useSetRecoilState,
  useRecoilState,
} from "recoil";
import FolderOpenIcon from "@icons/20/FolderMenuOpen.svg";
import FolderClosedIcon from "@icons/20/FolderMenuClosed.svg";
import PersonalFolderOpenIcon from "@icons/20/PersonalFolderOpen.svg";
import PersonalFolderClosedIcon from "@icons/20/PersonalFolderClosed.svg";
import { organisationIdSelector, useTypedPath } from "state/pathParams";
import { useDrop, useDrag } from "react-dnd";
import { Link, useLocation } from "react-router-dom";
import useFolderId from "hooks/useFolderId";
import {
  Margin,
  NameChevronWrapper,
  ProjectFolderRow,
  ProjectFolderListWrapper,
  SVGWrapper,
  SVGWrapperLevelised,
  OuterWrapper,
  ProjectTitle,
} from "./style";
import {
  folderTreeSelectorFamily,
  TreeItem,
  FolderTreeItem,
} from "../Projects/state";
import {
  userAllNodesAccessAtom,
  userHaveEditorNodeAccess,
  userNodeAccessSelectorFamily,
} from "../../state/user";
import { organisationRightSideModal } from "./OrganisationRightSide/state";
import styled from "styled-components";
import { colors } from "styles/colors";
import { EditableTextInternalState } from "components/General/EditableText";
import { useToast } from "hooks/useToast";
import ChevronDownIcon from "@icons/14/ChevronDown.svg";
import ProjectIcon from "@icons/24/ProjectGlobe.svg?react";
import LibraryIcon from "@icons/24/Book.svg?react";
import BulbIcon from "@icons/24/Bulb.svg?react";
import IntegrationIcon from "@icons/24/Integration.svg?react";
import PortfolioIcon from "@icons/24/Portfolio.svg?react";
import Members from "@icons/24/Persons.svg?react";
import { IconREMSize, typography } from "styles/typography";
import { selectedOrgTabState } from "./state";
import { useLocalStorage } from "hooks/useBrowserStorage";

const Divider = styled.div`
  margin: 1.6rem auto;
  border-bottom: 1px solid ${colors.hover};
  width: 90%;
`;

const Wrapper = styled.div`
  ${typography.body}
  color: ${colors.textPrimary};
  padding: 0.6rem 2.4rem;
  cursor: pointer;
  display: flex;
  align-items: center;

  overflow-y: hidden;
  * > p {
    overflow: hidden;
  }
`;

const ExpandArrowWrapper = styled.div<{ open: boolean }>`
  padding: 0rem 0.6rem;
  cursor: pointer;
  transform: rotate(${({ open }) => (!open ? "-90deg" : "0deg")});
  transition: 0.1s;

  ${({ open }) =>
    !open &&
    `
    svg {
      path {
        fill: ${colors.grey500};
      }
    }`};
`;

const hasChildThatIsSelected = (
  selectedId: string,
  parentId: string,
  nodes: Node[],
): boolean => {
  const nodesForParent = nodes.filter((n) => n.parent_id === parentId);

  if (nodesForParent.find((n) => n.id === selectedId)) return true;

  const sortedPersonalFolders = nodesForParent.filter((n) =>
    ["personal_folder", "folder"].includes(n.type),
  );

  return sortedPersonalFolders.some(
    (n) => !!hasChildThatIsSelected(selectedId, n.id, nodes),
  );
};

const ProjectElement = ({
  projectNode,
  level,
}: {
  projectNode: Node;
  level: number;
}) => {
  const { organisationId } = useTypedPath("organisationId");
  const location = useLocation();
  const elementRef = useRef<HTMLDivElement>(null);
  const userAllNodesAccess = useRecoilValue(userAllNodesAccessAtom);
  const isCustomerEditor = useMemo(
    () => userHaveEditorNodeAccess(userAllNodesAccess, projectNode.id),
    [userAllNodesAccess, projectNode.id],
  );
  const { update: updateNode } = useOrganisationNodeCrud();

  const renameProject = useCallback(
    (newName: string) => {
      if (newName === "") return;
      const renameProjectAsync = async () => {
        await updateNode(projectNode.id, { name: newName });
      };

      renameProjectAsync();
    },
    [updateNode, projectNode.id],
  );

  const [, dragRef] = useDrag(() => ({
    type: "PROJECT-list",
    canDrag: () => isCustomerEditor,
    item: () => ({
      id: projectNode.id,
      parentId: projectNode.parent_id,
    }),
  }));

  dragRef(elementRef);
  return (
    <div ref={elementRef}>
      <Link
        style={{ textDecoration: "none" }}
        to={`/design/project/${organisationId}/${projectNode.id}${location.search}`}
      >
        <OuterWrapper>
          <NameChevronWrapper selected={false} level={level}>
            <ProjectFolderRow>
              {isCustomerEditor ? (
                <EditableTextInternalState
                  smallInput={true}
                  type="text"
                  renderText={(text) => <ProjectTitle>{text}</ProjectTitle>}
                  value={projectNode.name}
                  onEnter={(value) => renameProject(value)}
                  disabled={!isCustomerEditor}
                />
              ) : (
                <ProjectTitle>{projectNode.name}</ProjectTitle>
              )}
            </ProjectFolderRow>
          </NameChevronWrapper>
        </OuterWrapper>
      </Link>
    </div>
  );
};

const AllProjectsElement = () => {
  const organisationId = useRecoilValue(organisationIdSelector);
  const setContent = useSetRecoilState(
    organisationRightSideModal(organisationId ?? ""),
  );
  const [selectedOrgTab, setSelectedOrgTab] =
    useRecoilState(selectedOrgTabState);

  const isSelected = selectedOrgTab === "Projects";

  return (
    <Link
      to={`/organisation/${organisationId}`}
      style={{ textDecoration: "none" }}
      onClick={() => {
        setContent(undefined);
        setSelectedOrgTab("Projects");
      }}
    >
      <OuterWrapper>
        <NameChevronWrapper
          selected={isSelected}
          level={1}
          className="projects"
        >
          <ProjectFolderRow>
            <SVGWrapper>
              <ProjectIcon />
            </SVGWrapper>
            <div>All projects</div>
          </ProjectFolderRow>
        </NameChevronWrapper>
      </OuterWrapper>
    </Link>
  );
};

const LibraryElement = () => {
  const organisationId = useRecoilValue(organisationIdSelector);
  const setContent = useSetRecoilState(
    organisationRightSideModal(organisationId ?? ""),
  );

  const [selectedOrgTab, setSelectedOrgTab] =
    useRecoilState(selectedOrgTabState);

  const isSelected = selectedOrgTab === "Library";

  return (
    <Link
      to={`/organisation/${organisationId}`}
      style={{ textDecoration: "none" }}
      onClick={() => {
        setContent(undefined);
        setSelectedOrgTab("Library");
      }}
    >
      <OuterWrapper>
        <NameChevronWrapper selected={isSelected} level={1} className="library">
          <ProjectFolderRow>
            <SVGWrapper>
              <LibraryIcon />
            </SVGWrapper>
            <div>Library</div>
          </ProjectFolderRow>
        </NameChevronWrapper>
      </OuterWrapper>
    </Link>
  );
};

const MembersElement = () => {
  const organisationId = useRecoilValue(organisationIdSelector);

  const setContent = useSetRecoilState(
    organisationRightSideModal(organisationId ?? ""),
  );
  const [selectedOrgTab, setSelectedOrgTab] =
    useRecoilState(selectedOrgTabState);

  const isSelected = selectedOrgTab === "Members";

  return (
    <Link
      to={`/organisation/${organisationId}`}
      style={{ textDecoration: "none" }}
      onClick={() => {
        setContent(undefined);
        setSelectedOrgTab("Members");
      }}
    >
      <OuterWrapper>
        <NameChevronWrapper selected={isSelected} level={1} className="members">
          <ProjectFolderRow>
            <SVGWrapper>
              <Members />
            </SVGWrapper>
            <div>Members</div>
          </ProjectFolderRow>
        </NameChevronWrapper>
      </OuterWrapper>
    </Link>
  );
};

const LearnElement = () => {
  const organisationId = useRecoilValue(organisationIdSelector);
  const setContent = useSetRecoilState(
    organisationRightSideModal(organisationId ?? ""),
  );
  const [selectedOrgTab, setSelectedOrgTab] =
    useRecoilState(selectedOrgTabState);

  const isSelected = selectedOrgTab === "Learn";

  return (
    <Link
      to={`/organisation/${organisationId}`}
      style={{ textDecoration: "none" }}
      onClick={() => {
        setContent(undefined);
        setSelectedOrgTab("Learn");
      }}
    >
      <OuterWrapper>
        <NameChevronWrapper selected={isSelected} level={1} className="learn">
          <ProjectFolderRow>
            <SVGWrapper>
              <BulbIcon />
            </SVGWrapper>
            <div>Learn</div>
          </ProjectFolderRow>
        </NameChevronWrapper>
      </OuterWrapper>
    </Link>
  );
};
const PortfolioElement = () => {
  const organisationId = useRecoilValue(organisationIdSelector);

  const setContent = useSetRecoilState(
    organisationRightSideModal(organisationId ?? ""),
  );
  const [selectedOrgTab, setSelectedOrgTab] =
    useRecoilState(selectedOrgTabState);

  const isSelected = selectedOrgTab === "Portfolio";

  return (
    <Link
      to={`/organisation/${organisationId}`}
      style={{ textDecoration: "none" }}
      onClick={() => {
        setContent(undefined);
        setSelectedOrgTab("Portfolio");
      }}
    >
      <OuterWrapper>
        <NameChevronWrapper
          selected={isSelected}
          level={1}
          className="organisationHome"
        >
          <ProjectFolderRow>
            <SVGWrapper>
              <PortfolioIcon />
            </SVGWrapper>
            <div>Portfolio</div>
          </ProjectFolderRow>
        </NameChevronWrapper>
      </OuterWrapper>
    </Link>
  );
};

const IntegrationElement = () => {
  const organisationId = useRecoilValue(organisationIdSelector);
  const setContent = useSetRecoilState(
    organisationRightSideModal(organisationId ?? ""),
  );
  const [selectedOrgTab, setSelectedOrgTab] =
    useRecoilState(selectedOrgTabState);

  const isSelected = selectedOrgTab === "Integration";

  return (
    <Link
      to={`/organisation/${organisationId}`}
      style={{ textDecoration: "none" }}
      onClick={() => {
        setContent(undefined);
        setSelectedOrgTab("Integration");
      }}
    >
      <OuterWrapper>
        <NameChevronWrapper
          selected={isSelected}
          level={1}
          className="integration"
        >
          <ProjectFolderRow>
            <SVGWrapper>
              <IntegrationIcon />
            </SVGWrapper>
            <div>Integration</div>
          </ProjectFolderRow>
        </NameChevronWrapper>
      </OuterWrapper>
    </Link>
  );
};

const ShowMoreToggle = ({
  showMore,
  setShowMore,
}: {
  showMore: boolean;
  setShowMore(showMore: boolean): void;
}) => {
  return (
    <Wrapper
      onClick={() => {
        setShowMore(!showMore);
      }}
    >
      <ProjectFolderRow>
        <ExpandArrowWrapper open={showMore}>
          <ChevronDownIcon />
        </ExpandArrowWrapper>
        {showMore ? "Less" : "More"}
      </ProjectFolderRow>
    </Wrapper>
  );
};

const findChildInTrees = (
  nodes: (TreeItem | FolderTreeItem)[],
  id: string,
): boolean => {
  return nodes.some((n) => findChildInTree(n, id));
};

const findChildInTree = (
  node: TreeItem | FolderTreeItem,
  id: string,
): boolean => {
  if (node.id === id) {
    return true;
  }
  if (!("items" in node)) {
    return false;
  }

  return (node.items as TreeItem[]).some((n) => findChildInTree(n, id));
};

const FolderWithChildren = ({
  folderNode,
  level,
}: {
  folderNode: Node;
  level: number;
}) => {
  const [open, toggleOpen, setOpen] = useBooleanState(false);
  const location = useLocation();
  const { organisationId } = useTypedPath("organisationId");
  const elementRef = useRef<HTMLDivElement>(null);
  const { move: moveNode } = useOrganisationNodeCrud();
  const setContent = useSetRecoilState(
    organisationRightSideModal(organisationId),
  );
  const folderId = useFolderId() ?? "";
  const nodes = useRecoilValue(
    nodesInOrganisationSelectorFamily({ organisationId }),
  );

  const getFolderTree = useRecoilCallback(
    ({ snapshot }) =>
      async ({ id }: { id: string }) => {
        const folderTree = await snapshot.getPromise(
          folderTreeSelectorFamily({ organisationId, folderId: id }),
        );
        return folderTree;
      },
  );

  const [dropCollection, dropRef] = useDrop<
    {
      id: string;
      parentId?: string | null;
    },
    {
      handled: boolean;
    },
    {
      isHovering: boolean;
    }
  >(
    () => ({
      accept: [
        "PROJECT-list",
        "PROJECT",
        "FOLDER-list",
        "FOLDER",
        "PROJECT|FOLDER",
      ],
      collect: (monitor) => {
        return {
          isHovering: monitor.isOver({ shallow: true }),
        };
      },
      canDrop: () => true,
      drop: (droppedItem, monitor) => {
        // Its been dropped on something lower in the tree
        if (monitor.getDropResult()?.handled) {
          return {
            handled: true,
          };
        }

        const type = monitor.getItemType();
        switch (type) {
          case "PROJECT":
          case "PROJECT-list":
            // Its been dropped on the folder it already belongs to
            if (droppedItem.parentId === folderNode.id) {
              return {
                handled: true,
              };
            }

            moveNode(droppedItem.id, folderNode.id);
            return {
              handled: true,
            };

          case "FOLDER":
          case "FOLDER-list":
            if (droppedItem.id === folderNode.id) {
              return {
                handled: true,
              };
            }

            getFolderTree({ id: droppedItem.id }).then((folderTree) => {
              if (findChildInTrees(folderTree, folderNode.id)) {
                // Cannot move a folder into its own children
                return;
              }
              moveNode(droppedItem.id, folderNode.id);
            });
            return {
              handled: true,
            };
          case "PROJECT|FOLDER":
            if (droppedItem.id === folderNode.id) {
              return {
                handled: true,
              };
            }

            getFolderTree({ id: droppedItem.id }).then((folderTree) => {
              if (findChildInTrees(folderTree, folderNode.id)) {
                // Cannot move a folder into its own children
                return;
              }
              moveNode(droppedItem.id, folderNode.id);
            });
            return {
              handled: true,
            };
        }
      },
    }),
    [folderNode.id, getFolderTree, moveNode],
  );

  const [, dragRef] = useDrag(() => ({
    type: "FOLDER-list",
    canDrag: () => level > 1,
    item: () => ({
      id: folderNode.id,
    }),
  }));

  const selected = useMemo(
    () =>
      open
        ? folderId === folderNode.id
        : folderId === folderNode.id ||
          hasChildThatIsSelected(folderId, folderNode.id, nodes),
    [open, folderId, folderNode, nodes],
  );

  const topNodes = useMemo(
    () => nodes.filter((n) => n.parent_id === folderNode.id),
    [folderNode, nodes],
  );

  const { error } = useToast();

  const { update } = useOrganisationNodeCrud();

  useEffect(() => {
    if (
      folderNode.id !== folderId ||
      !hasChildThatIsSelected(folderId, folderNode.id, nodes)
    )
      return;
    setOpen(true);
  }, [folderNode.id, folderId, setOpen, nodes]);

  const FolderIcon = open === true ? FolderOpenIcon : FolderClosedIcon;
  const PersonalFolderIcon =
    open === true ? PersonalFolderOpenIcon : PersonalFolderClosedIcon;
  const [, setSelectedOrgTab] = useRecoilState(selectedOrgTabState);

  const nodeAccess = useRecoilValue(
    userNodeAccessSelectorFamily({ nodeId: folderNode.id }),
  );
  const isNodeAdmin = nodeAccess >= 2;

  const isPersonalFolder = folderNode?.type === "personal_folder";

  const chosenFolderHasPersonalTopFolder = folderNode
    ? findTopLevelNode(nodes, folderNode.id, organisationId ?? "")?.type ===
      "personal_folder"
    : false;

  dropRef(dragRef(elementRef));
  return (
    <div ref={elementRef}>
      <Link
        to={`/organisation/${organisationId}/${folderNode.id}${location.search}`}
        onClick={() => {
          setContent({ type: "project", id: folderNode.id });
          setSelectedOrgTab(undefined);
        }}
        style={{ textDecoration: "none" }}
      >
        <OuterWrapper>
          <NameChevronWrapper
            level={level}
            selected={selected}
            isDragHovering={dropCollection.isHovering}
          >
            <ExpandArrowWrapper onClick={toggleOpen} open={open}>
              <ChevronDownIcon />
            </ExpandArrowWrapper>
            <ProjectFolderRow>
              <SVGWrapperLevelised level={level}>
                <IconREMSize height={2} width={2}>
                  {isPersonalFolder || chosenFolderHasPersonalTopFolder ? (
                    <PersonalFolderIcon />
                  ) : (
                    <FolderIcon />
                  )}
                </IconREMSize>
              </SVGWrapperLevelised>
              {isNodeAdmin ? (
                <EditableTextInternalState
                  smallInput={true}
                  value={folderNode?.name}
                  renderText={(text) => <div>{text}</div>}
                  disabled={isPersonalFolder}
                  onEnter={(newName) => {
                    if (!folderNode) return;
                    update(folderNode.id, {
                      ...folderNode,
                      name: newName,
                    }).catch(() => error("Failed to update folder name"));
                  }}
                />
              ) : (
                <div>{folderNode?.name}</div>
              )}
            </ProjectFolderRow>
          </NameChevronWrapper>
        </OuterWrapper>
      </Link>
      {open && <ChildrenNodesForParent level={level + 1} topNodes={topNodes} />}
    </div>
  );
};

const ChildrenNodesForParent = ({
  topNodes,
  level,
}: {
  topNodes: Node[];
  level: number;
}) => {
  const sortedPersonalFolders = useMemo(
    () =>
      topNodes
        .filter((n) => n.type === "personal_folder")
        .sort((a, b) => a.name.localeCompare(b.name)),
    [topNodes],
  );
  const sortedFolders = useMemo(
    () =>
      topNodes
        .filter((n) => n.type === "folder")
        .sort((a, b) => a.name.localeCompare(b.name)),
    [topNodes],
  );
  const sortedProjects = useMemo(
    () =>
      topNodes
        .filter((n) => n.type === "project")
        .sort((a, b) => a.name.localeCompare(b.name)),
    [topNodes],
  );

  return (
    <Margin>
      {sortedPersonalFolders.map((n) => (
        <FolderWithChildren level={level} key={n.id} folderNode={n} />
      ))}
      {sortedFolders.map((n) => (
        <FolderWithChildren level={level} key={n.id} folderNode={n} />
      ))}
      <div style={{ paddingTop: sortedProjects.length > 0 ? "1.2rem" : "0" }}>
        {sortedProjects.map((n) => (
          <ProjectElement level={level} projectNode={n} key={n.id} />
        ))}
      </div>
    </Margin>
  );
};

const FolderProjectList = ({ organisationId }: { organisationId: string }) => {
  const [showMore, setShowMore] = useLocalStorage(
    "vind:show-more-org-menu",
    false,
  );
  const topNodes = useRecoilValue(
    getNodesWithMissingParents({ organisationId }),
  );

  const personalFolders = useMemo(
    () => topNodes.filter((n) => n.type === "personal_folder"),
    [topNodes],
  );

  const nonPersonalFolders = useMemo(
    () => topNodes.filter((n) => n.type === "folder"),
    [topNodes],
  );

  const orphanProjects = useMemo(
    () => topNodes.filter((n) => n.type === "project"),
    [topNodes],
  );

  return (
    <>
      <ProjectFolderListWrapper id={"project-list"}>
        <AllProjectsElement />
        <LibraryElement />
        <MembersElement />
        <ShowMoreToggle
          showMore={showMore ?? false}
          setShowMore={setShowMore}
        />
        {showMore && (
          <>
            <LearnElement />
            <PortfolioElement />
            <IntegrationElement />
          </>
        )}

        <Divider />
        <ChildrenNodesForParent level={1} topNodes={personalFolders} />
        <ChildrenNodesForParent level={1} topNodes={nonPersonalFolders} />
        <ChildrenNodesForParent level={1} topNodes={orphanProjects} />
      </ProjectFolderListWrapper>
    </>
  );
};

export default FolderProjectList;
