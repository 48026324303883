import { fetchSchemaWithToken } from "services/utils";
import { ChangelogEntry, _ChangelogEntry } from "./type";

export const getOrganisationResourceChangelog = async (
  organisationId: string,
  resourceId: string,
): Promise<ChangelogEntry[]> => {
  return fetchSchemaWithToken(
    _ChangelogEntry.array(),
    `/api/timeline/changelog/organisation/${organisationId}/${resourceId}`,
    {
      method: "get",
    },
  );
};

export const getOrganisationLatestUpdate = async (
  organisationId: string,
  resourceIds: string[],
): Promise<ChangelogEntry[]> => {
  return fetchSchemaWithToken(
    _ChangelogEntry.array(),
    `/api/timeline/changelog/organisation/${organisationId}`,
    {
      method: "post",
      body: JSON.stringify({ ids: resourceIds }),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
};
