import { useRecoilCallback } from "recoil";
import { useTypedPath } from "../../../state/pathParams";
import { LayerSettings } from "./types";
import {
  delLayerSetting,
  delLayerSettingDebounced,
  putLayerSetting,
  putLayerSettingDebounced,
} from "./service";
import { allLayerSettingsAtomFamily } from "./state";
import { Mixpanel } from "../../../mixpanel";
import { initializeAndSet } from "../../Comments/hooks/useReplyReactionCrud";

export const useLayerSettingsCrud = () => {
  const { projectId } = useTypedPath("projectId");

  const put = useRecoilCallback(
    ({ set }) =>
      async (settings: LayerSettings[], debounce = false) => {
        if (!projectId) return;
        set(allLayerSettingsAtomFamily({ projectId }), (cur) => {
          const rest = cur.filter(
            (l) => !settings.map((s) => s.id).includes(l.id),
          );
          return [...rest, ...settings];
        });
        let res;
        if (debounce) {
          res = await putLayerSettingDebounced(projectId, settings);
        } else {
          res = await putLayerSetting(projectId, settings);
        }
        Mixpanel.track("Update layer settings", {
          projectId,
          ...settings,
        });
        return res;
      },
    [projectId],
  );
  const putLocal = useRecoilCallback(
    ({ set, snapshot }) =>
      async (projectId: string, settings: LayerSettings[]) => {
        initializeAndSet(
          snapshot,
          set,
          allLayerSettingsAtomFamily({ projectId }),
          (cur) => {
            const rest = cur.filter(
              (l) => !settings.map((s) => s.id).includes(l.id),
            );
            return [...rest, ...settings];
          },
        );
      },
    [],
  );

  const del = useRecoilCallback(
    ({ set }) =>
      async (layerIds: string[], debounce = false) => {
        if (!projectId) return;
        set(allLayerSettingsAtomFamily({ projectId }), (cur) => {
          const rest = cur.filter((l) => !layerIds.includes(l.id));
          return rest;
        });
        let res;
        if (debounce) {
          res = await delLayerSettingDebounced(projectId, layerIds);
        } else {
          res = await delLayerSetting(projectId, layerIds);
        }
        Mixpanel.track("Update layer settings", {
          projectId,
          layerIds,
        });
        return res;
      },
    [projectId],
  );
  const delLocal = useRecoilCallback(
    ({ set, snapshot }) =>
      async (projectId: string, layerIds: string[]) => {
        initializeAndSet(
          snapshot,
          set,
          allLayerSettingsAtomFamily({ projectId }),
          (cur) => cur.filter((l) => !layerIds.includes(l.id)),
        );
      },
    [],
  );

  return { put, putLocal, del, delLocal };
};
