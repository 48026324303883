// Semaphore class for managing concurrency
class Semaphore {
  private count: number;
  private waiters: (() => void)[] = [];

  constructor(count: number) {
    this.count = count;
  }

  async acquire() {
    if (this.count > 0) {
      this.count--;
    } else {
      await new Promise<void>((resolve) => this.waiters.push(resolve));
    }
  }

  release() {
    if (this.waiters.length > 0) {
      const resolve = this.waiters.shift();
      if (resolve) {
        resolve();
      }
    } else {
      this.count++;
    }
  }
}

export default Semaphore;
