import { ProjectFeature } from "../../types/feature";

export const unAckedSaves: Map<string, string[]> = new Map();

export function removeChangedFeaturesFromAckMap(
  uniqueUpdateId: string,
  cf: {
    add?: ProjectFeature[];
    remove?: string[];
    update?: ProjectFeature[];
  },
) {
  const changedFeatureIds = getIdsOfChangedFeatures(cf);
  changedFeatureIds.forEach((id) => {
    if (unAckedSaves.has(id)) {
      unAckedSaves.set(
        id,
        unAckedSaves.get(id)!.filter((e) => e !== uniqueUpdateId),
      );
    }
  });
}

export function addChangedFeaturesToAckMap(
  uniqueUpdateId: string,
  cf: {
    add?: ProjectFeature[];
    remove?: string[];
    update?: ProjectFeature[];
  },
) {
  const changedFeatureIds = getIdsOfChangedFeatures(cf);
  changedFeatureIds.forEach((id) => {
    if (!unAckedSaves.has(id)) {
      unAckedSaves.set(id, [uniqueUpdateId]);
    } else {
      unAckedSaves.get(id)!.push(uniqueUpdateId);
    }
  });
}

export function filterUnAckedChangedFeatures(cf: {
  add?: ProjectFeature[];
  remove?: string[];
  update?: ProjectFeature[];
}) {
  return {
    ...cf,
    add: cf.add?.filter((f) => {
      const val = unAckedSaves.get(f.id);
      return !val || val.length === 0;
    }),
    remove: cf.remove?.filter((id) => {
      const val = unAckedSaves.get(id);
      return !val || val.length === 0;
    }),
    update: cf.update?.filter((f) => {
      const val = unAckedSaves.get(f.id);
      return !val || val.length === 0;
    }),
  };
}

export function getIdsOfChangedFeatures(cf: {
  add?: ProjectFeature[];
  remove?: string[];
  update?: ProjectFeature[];
}) {
  return [
    ...(cf.add ?? []).map((f) => f.id),
    ...(cf.update ?? []).map((f) => f.id),
    ...(cf.remove ?? []),
  ];
}
