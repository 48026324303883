/// <reference types="vite-plugin-svgr/client" />
import { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { SkeletonText } from "../Loading/Skeleton";
import { spaceSmall } from "../../styles/space";
import { replaceOrUndefined } from "../ControlPanels/utils";
import Button from "../General/Button";
import { Column, Row } from "../General/Layout";
import { useProjectElementsCrud } from "../ProjectElements/useProjectElementsCrud";
import { ChevronIcon } from "../ToggleableList/ToggleableList";
import { parkFeature } from "./utils";
import {
  siteLocatorProblemsAtom,
  siteLocatorProblemsMetaAtom,
  siteLocatorSettingState,
  selectedCandidateState,
  selectedPolygonIdsAtom,
  lcoeSliderState,
  SiteLocatorProblemMeta,
  openSiteLocatorListEntry,
  openSiteLocatorListProblem,
  SelectedCandidate,
  createdCandidateIDsState,
  SelectedCell,
  SiteLocatorProblem,
} from "./state";
import {
  TargetDensityTag,
  TargetParkCapacityTag,
  TargetTurbineTag,
} from "./Tags";
import { ProblemDiv, ProblemEntry, ProblemEntryHeader } from "./style";
import { getParkFeaturesSelector } from "../../state/park";
import { Mixpanel } from "../../mixpanel";
import { Polygon } from "geojson";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import {
  InputTitle,
  OverlineText,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";

const InfoGrid = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CollectionsResultsList = () => {
  const siteLocatorProblems = useRecoilValue(siteLocatorProblemsAtom);
  const siteLocatorProblemsMeta = useRecoilValue(siteLocatorProblemsMetaAtom);
  const setSettings = useSetRecoilState(siteLocatorSettingState);
  const [selectedCandidate, setSelectedCandidate] = useRecoilState(
    selectedCandidateState,
  );

  const [open, setOpen] = useRecoilState(openSiteLocatorListProblem);
  const [openEntry, setOpenEntry] = useRecoilState(openSiteLocatorListEntry);
  const setSelectedPolygonIds = useSetRecoilState(selectedPolygonIdsAtom);
  const setLcoeSlider = useSetRecoilState(lcoeSliderState);

  // TODO: cleanup this setting updates multiple places
  useEffect(() => {
    if (siteLocatorProblems.length) {
      const index = siteLocatorProblems.length - 1;
      setOpen(index);
      setOpenEntry(0);
      if (siteLocatorProblems[index].candidates.length) {
        const candidate = siteLocatorProblems[index].candidates[0];
        const polygonIDs = candidate!.collection
          .map((cell) => cell.polygonId)
          .filter((el) => el !== undefined);
        setSelectedCandidate(candidate);
        setSelectedPolygonIds(polygonIDs as number[]);
      }
    }
  }, [
    siteLocatorProblems,
    setOpen,
    setOpenEntry,
    setSelectedCandidate,
    setSelectedPolygonIds,
  ]);

  useEffect(() => {
    if (open === undefined) {
      setSelectedCandidate(undefined);
      setSelectedPolygonIds([]);
    }
  }, [open, setSelectedCandidate, setSelectedPolygonIds]);

  useEffect(() => {
    if (siteLocatorProblemsMeta.length > 0)
      setOpen(siteLocatorProblemsMeta.length - 1);
  }, [siteLocatorProblemsMeta, setOpen]);

  const updateHeatmapSettings = (problem: SiteLocatorProblemMeta) => {
    setSettings((prev) => ({
      ...prev,
      density: problem.density,
      turbineType: problem.turbineType,
      natura2000filter: problem.natura2000filter,
    }));
  };

  const handleButtonClick = (candidate: SelectedCandidate, index: number) => {
    const polygonIDs = candidate?.collection.map(
      (cell: SelectedCell) => cell.polygonId,
    );
    if (selectedCandidate === candidate) {
      setSelectedCandidate(undefined);
      setOpenEntry(undefined);
    } else {
      setSelectedCandidate(candidate);
      setSelectedPolygonIds(polygonIDs as number[]);
      setOpenEntry(index);
    }
  };

  return (
    <>
      {siteLocatorProblemsMeta.length > 0 && (
        <>
          <SubtitleWithLine text="Results" />
          <ProblemDiv style={{ padding: 0 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: 0,
              }}
            >
              {siteLocatorProblemsMeta.map((problem, index) => {
                return (
                  <ProblemEntry key={index} open={open === index}>
                    <ProblemEntryHeader
                      onClick={() => {
                        setOpen(replaceOrUndefined(index));
                        updateHeatmapSettings(problem);
                        setLcoeSlider({
                          min: problem.lcoeSlider.min,
                          max: problem.lcoeSlider.max,
                        });
                        if (
                          siteLocatorProblems[index] !== undefined &&
                          siteLocatorProblems[index].candidates.length
                        ) {
                          const candidate =
                            siteLocatorProblems[index].candidates[0];
                          const polygonIDs = candidate!.collection
                            .map((cell) => cell.polygonId)
                            .filter((el) => el !== undefined);
                          setSelectedCandidate(candidate);
                          setSelectedPolygonIds(polygonIDs as number[]);
                        }
                      }}
                    >
                      <OverlineText>Optimisation</OverlineText>
                      <ChevronIcon
                        open={open === index}
                        chevronSize={"1rem"}
                        style={{
                          alignSelf: "end",
                          justifySelf: "end",
                        }}
                      />
                      <Row
                        style={{
                          display: "flex",
                          gap: spaceSmall,
                        }}
                      >
                        <TargetParkCapacityTag n={problem.maxParkCapacity} />
                        <TargetTurbineTag n={problem.turbinePower} />
                        <TargetDensityTag n={problem.density} />
                      </Row>
                    </ProblemEntryHeader>
                    {index === open && (
                      <ProblemResults
                        problem={siteLocatorProblems[index]}
                        selectedIndex={openEntry ?? -1}
                        buttonClick={handleButtonClick}
                      />
                    )}
                  </ProblemEntry>
                );
              })}
            </div>
          </ProblemDiv>
        </>
      )}
    </>
  );
};

const ProblemResults = ({
  problem,
  selectedIndex,
  buttonClick,
}: {
  problem: SiteLocatorProblem;
  selectedIndex: number;
  buttonClick: (candidate: SelectedCandidate, index: number) => void;
}) => {
  if (!problem || !problem.candidates) {
    return <SkeletonText />;
  }

  return (
    <>
      {problem.candidates.length === 0 && (
        <ResultEntry>
          <SimpleAlert text={"No solutions were found."} type={"error"} />
        </ResultEntry>
      )}
      {problem.candidates
        .toSorted(
          (a: SelectedCandidate, b: SelectedCandidate) => a!.lcoe - b!.lcoe,
        )
        .map((candidate: SelectedCandidate, index: number) => (
          <ResultItem
            key={index}
            candidate={candidate}
            index={index}
            selected={selectedIndex === index}
            onClick={() => buttonClick(candidate, index)}
          />
        ))}
    </>
  );
};

const ResultItem = ({
  candidate,
  index,
  onClick,
  selected,
}: {
  candidate: SelectedCandidate;
  index: number;
  onClick: Parameters<typeof ResultEntry>[0]["onClick"];
  selected: boolean;
}) => {
  const parks = useRecoilValue(getParkFeaturesSelector);
  const { update: updateFeatures } = useProjectElementsCrud();
  const [createdCandidateIDs, setCreatedCandidateIDs] = useRecoilState(
    createdCandidateIDsState,
  );

  const handleButtonClick = () => {
    const newFeature = parkFeature(candidate!.hull.geometry as Polygon);
    updateFeatures({ add: [newFeature] });

    setCreatedCandidateIDs((prev) => ({
      ...prev,
      [candidate!.id]: newFeature.id,
    }));
  };

  const parkExists = (id: string) => {
    if (createdCandidateIDs.hasOwnProperty(id)) {
      if (parks.some((park) => park.id === createdCandidateIDs[id])) {
        return true;
      }
    }
    return false;
  };

  return (
    <div style={{ display: "flex", padding: "0 2rem" }}>
      <ResultEntry onClick={onClick}>
        <ResultHeader
          style={{
            alignItems: "center",
            backgroundColor:
              index % 2 === 0 ? `${colors.surfaceSecondary}` : "auto",
          }}
          selected={selected}
        >
          <ResultTitle>
            <p>Park {index + 1}</p> <p>{candidate!.lcoe.toFixed(1)} €/MWh</p>
          </ResultTitle>
          <Button
            style={{
              height: "2.4rem",
            }}
            size="small"
            text="Add"
            buttonType={"primary-dark"}
            disabled={parkExists(candidate!.id)}
            onClick={() => {
              Mixpanel.track("Site locator: create park", {});
              handleButtonClick();
            }}
            title={"Create park"}
          />
        </ResultHeader>
        {selected && <ItemDetails collection={candidate!.collection} />}
      </ResultEntry>
    </div>
  );
};

const ResultEntry = styled.div`
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`;

const ResultTitle = styled.div`
  display: flex;
  align-items: center;
  width: 12rem;
  justify-content: space-between;
`;

const ResultHeader = styled.div<{ selected?: boolean }>`
  display: flex;
  justify-content: space-between;
  min-height: 2.8rem;
  padding: 0.4rem 0.8rem;
  gap: 0.8rem;
  border-radius: 0.4rem 0.4rem 0 0;

  .primary-dark {
    display: none;
  }
  &:hover {
    .primary-dark {
      display: flex;
    }
  }

  ${(p) =>
    p.selected &&
    `background-color: ${colors.surfaceSelectedLight} !important;`}
`;

const ItemDetails = ({ collection }: { collection: SelectedCell[] }) => {
  // TODO: move these calculations further up the food-chain
  const calculateAverage = (property: keyof SelectedCell) =>
    collection.reduce((acc, cell) => acc + (cell[property] as number), 0) /
    collection.length;

  const shoreDistance = calculateAverage("shoreDist");
  const meanWindSpeed = calculateAverage("meanSpeed");
  const aep = collection.reduce((acc, cell) => acc + cell["aep"], 0);
  const depth = calculateAverage("depth");
  return (
    <Column
      style={{
        padding: "0.8rem",
        gap: "0.4rem",
        backgroundColor: colors.blue50,
        borderRadius: "0 0 0.4rem 0.4rem",
      }}
    >
      <InfoGrid>
        <InputTitle>Depth:</InputTitle>
        <div>{depth.toFixed(1)} m</div>
      </InfoGrid>
      <InfoGrid>
        <InputTitle>Wind:</InputTitle>
        <div>{meanWindSpeed.toFixed(1)} ms</div>
      </InfoGrid>
      <InfoGrid>
        <InputTitle>AEP:</InputTitle>
        <div>{aep.toFixed(1)} GWh</div>
      </InfoGrid>
      <InfoGrid>
        <InputTitle>Shore Distance:</InputTitle>
        <div>{shoreDistance.toFixed(1)} km</div>
      </InfoGrid>
    </Column>
  );
};

export default CollectionsResultsList;
