import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { adminAccessProjectSelector } from "state/user";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { trackProjectControlMenu } from "components/ProjectControl/MenuTracking";
import {
  organisationIdSelector,
  projectIdSelector,
  useTypedPath,
} from "state/pathParams";
import {
  getNodeSelectorFamily,
  topLevelNodeFromOrgIdAndNodeIdSelectorFamily,
} from "components/Projects/useOrganisationFolderCrud";
import SearchWrapper from "components/Search/SearchWrapper";
import Tooltip from "components/General/Tooltip";
import Button from "components/General/Button";
import Spinner from "@icons/spinner/Spinner";
import Share from "@icons/24/Share.svg";
import { spaceLarge, spaceMedium, spaceTiny } from "styles/space";
import { Anchor } from "components/General/Anchor";
import { nodeGroupUserAccessSelector } from "components/Organisation/Groups/state";
import {
  isGroupNodeAccessWithMeta,
  isUserNodeAccessWithMeta,
} from "components/Organisation/Groups/types";
import { useToast } from "hooks/useToast";
import { useClickOutside } from "hooks/useClickOutside";
import useGroupNodeAccessCrud from "components/Organisation/Groups/useGroupNodeAccessCrud";
import useUserNodeAccessCrud from "components/Organisation/Members/useUserNodeAccessCrud";
import { Frame } from "components/General/Layout";
import UserGroupAccessModal from "components/Organisation/OrganisationRightSide/content/UserGroupAccessModal";
import { colors } from "styles/colors";

const InviteFrame = ({
  buttonRef,
  setOpen,
}: {
  buttonRef: React.RefObject<HTMLDivElement>;
  setOpen: (b: boolean) => void;
}) => {
  const { organisationId, projectId } = useTypedPath(
    "organisationId",
    "projectId",
  );

  const groupsAndUsersWithAccess = useRecoilValueLoadable(
    nodeGroupUserAccessSelector({ organisationId, nodeId: projectId }),
  );
  const node = useRecoilValue(
    getNodeSelectorFamily({
      nodeId: projectId,
      organisationId,
    }),
  );
  const usersWithAccess = useMemo(
    () =>
      groupsAndUsersWithAccess.valueMaybe()?.filter(isUserNodeAccessWithMeta) ??
      [],
    [groupsAndUsersWithAccess],
  );
  const groupsWithAccess = useMemo(
    () =>
      groupsAndUsersWithAccess
        .valueMaybe()
        ?.filter(isGroupNodeAccessWithMeta) ?? [],
    [groupsAndUsersWithAccess],
  );

  const frameRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const { error: showError } = useToast();

  useClickOutside(
    frameRef,
    () => {
      setOpen(false);
    },
    (target) => {
      return target === buttonRef.current;
    },
  );

  const { addOrUpdateMultiple: addOrUpdateGroups } = useGroupNodeAccessCrud();
  const { addOrUpdateMultiple: addOrUpdateUsers } = useUserNodeAccessCrud();

  const invite = useCallback(
    async (
      access: { users: string[]; groups: string[] },
      accessRole: "admin" | "viewer" | "editor",
    ) => {
      setLoading(true);
      try {
        await Promise.all([
          addOrUpdateGroups(access.groups, projectId, accessRole),
          addOrUpdateUsers(access.users, projectId, accessRole),
        ]);
        setOpen(false);
      } catch {
        showError(
          "Something went wrong when inviting users, please try again.",
          {
            timeout: 5000,
          },
        );
      } finally {
        setLoading(false);
      }
    },
    [addOrUpdateGroups, projectId, addOrUpdateUsers, setOpen, showError],
  );

  return (
    <Frame
      style={{
        paddingTop: spaceMedium,
        marginTop: spaceLarge,
        width: "auto",
      }}
      ref={frameRef}
    >
      <UserGroupAccessModal
        resourceName={node?.name}
        style={{
          boxShadow: "none",
          width: "50rem",
          maxWidth: "unset",
        }}
        showAddExistingMembersMessage={true}
        existingUsers={usersWithAccess}
        existingGroups={groupsWithAccess}
        onCancel={() => setOpen(false)}
        onSave={invite}
        isSaving={loading}
      />
    </Frame>
  );
};

const ShareProjectButtonV2 = () => {
  const adminAccessToProject = useRecoilValueLoadable(
    adminAccessProjectSelector,
  );

  const [open, setOpen] = useState(false);
  const onClick = useCallback(() => {
    if (!open) trackProjectControlMenu("invite-button", {});
    setOpen(!open);
  }, [open]);

  const organisationId = useRecoilValue(organisationIdSelector);
  const projectId = useRecoilValue(projectIdSelector);
  const toplevelNode = useRecoilValue(
    topLevelNodeFromOrgIdAndNodeIdSelectorFamily({
      organisationId,
      nodeId: projectId,
    }),
  );
  const chosenProjectHasPersonalTopFolder =
    toplevelNode?.type === "personal_folder";

  const disable =
    adminAccessToProject.state !== "hasValue" ||
    !adminAccessToProject.contents ||
    chosenProjectHasPersonalTopFolder;
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div ref={ref}>
      <SearchWrapper
        title="Share project"
        id="search-share"
        icon={<Share />}
        tags={["share", "invite", "users", "members", "access"]}
        disabled={disable}
        onSelect={() => setOpen(true)}
      >
        <Tooltip
          theme="light"
          position="bottom"
          text={
            chosenProjectHasPersonalTopFolder
              ? "Projects in personal folders cannot be shared"
              : "Only project admins can invite"
          }
          disabled={!disable}
        >
          <React.Suspense
            fallback={
              <Button
                buttonType="primary-dark"
                text="Share"
                icon={<Spinner size="0.8rem" color={colors.textDisabled} />}
                disabled={true}
                style={{ marginLeft: spaceTiny }}
              />
            }
          >
            <Button
              buttonType="primary-dark"
              text="Share"
              disabled={disable}
              onClick={onClick}
              style={{ marginLeft: spaceTiny }}
            />
            {open && (
              <Anchor
                baseRef={ref}
                basePlace="bottomRight"
                floatPlace="topRight"
              >
                <InviteFrame buttonRef={ref} setOpen={setOpen} />
              </Anchor>
            )}
          </React.Suspense>
        </Tooltip>
      </SearchWrapper>
    </div>
  );
};

export default ShareProjectButtonV2;
