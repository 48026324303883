/// <reference types="vite-plugin-svgr/client" />
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { CABLE_CORRIDOR_PROPERTY_TYPE } from "../../../constants/cabling";
import {
  DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
  SUB_AREA_PROPERTY_TYPE,
} from "../../../constants/division";
import { PARK_PROPERTY_TYPE } from "../../../constants/park";
import { useSetPropertyOnProjectFeatures } from "../../../hooks/useSetPropertyOnProjectFeatures";
import ArrowDownIcon from "@icons/24/ArrowDown.svg";
import ArrowRightIcon from "@icons/24/ArrowRight.svg";
import SubAreaIcon from "@icons/24/SideBarIcons/SubArea.svg";
import { mapRefAtom } from "../../../state/map";
import { getParkFeaturesInBranchSelector } from "../../../state/park";
import { colors } from "../../../styles/colors";
import {
  ExclusionDomainDefault,
  PolygonFeatureType,
  ProjectFeature,
} from "../../../types/feature";
import { DEFAULT_PARK_NAME } from "../../../utils/geojson/utils";
import { isPark } from "../../../utils/predicates";
import { removeHover, setHover } from "components/Mapbox/utils";
import { MenuItem } from "../../General/Menu";
import { MenuButton } from "../../General/MenuButton";
import { parkSourceId } from "../../Mapbox/constants";
import { trackCanvasOption } from "../MenuTracking";
import { findFeatureChildren } from "../../../state/projectLayers";
import { projectFeaturesInBranchSelectorFamily } from "../../ProjectElements/state";
import { useRecoilValueDef } from "../../../utils/recoil";
import {
  projectIdSelector,
  branchIdSelector,
  parkIdSelector,
} from "state/pathParams";
import Tooltip from "components/General/Tooltip";
import { TypeAreaDot, TypeDot } from "components/General/Icons";
import { useTrackEvent } from "components/OnboardingTours/state";

const polygonTypeToName: Record<PolygonFeatureType | "other", string> = {
  [PARK_PROPERTY_TYPE]: "Park",
  [SUB_AREA_PROPERTY_TYPE]: "Sub area",
  [DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE]: "Exclusion zone",
  [CABLE_CORRIDOR_PROPERTY_TYPE]: "Cable corridor",
  other: "Other",
  "slack-region": "Slack Region",
  "park-cable-partition-polygon": "Cable partition",
  "park-cable-chain-polygon": "Cable chain",
  BathymetryUserUploadedType: "Bathymetry",
  GeoTiffUserUploadedImageType: "Geotiff",
};

export default function PolygonTypeSelector({
  selections,
  setCurrentSelectionArray,
  disabled,
  selectedType,
}: {
  selections: ProjectFeature[];
  disabled: boolean;
  setCurrentSelectionArray(ids: string[]): void;
  selectedType?: PolygonFeatureType | "other";
}) {
  const projectId = useRecoilValue(projectIdSelector);
  const branchId = useRecoilValue(branchIdSelector);
  const parkId = useRecoilValue(parkIdSelector);

  const map = useRecoilValueDef(mapRefAtom);
  const projectFeatures = useRecoilValue(
    projectFeaturesInBranchSelectorFamily({ branchId: branchId ?? "" }),
  );
  const parks = useRecoilValue(
    getParkFeaturesInBranchSelector({ branchId: branchId ?? "" }),
  );

  const [hoveredParkId, setHoveredParkId] = useState<string | undefined>();

  useEffect(() => {
    if (hoveredParkId) {
      setHover(map, parkSourceId, hoveredParkId);
      return () => {
        removeHover(map, parkSourceId, hoveredParkId);
      };
    }
  }, [hoveredParkId, map]);

  const setProperties = useSetPropertyOnProjectFeatures();

  const ids = useMemo(() => selections.map((s) => s.id), [selections]);
  const trackEvent = useTrackEvent();
  const onSelectItem = useCallback(
    (
      item:
        | { type: "other" }
        | { type: "park-polygon" }
        | { type: typeof DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE }
        | { type: PolygonFeatureType; parkId: string },
    ) => {
      trackCanvasOption("change-feature-type", {
        projectId,
        branchId,
      });
      switch (item.type) {
        case "other": {
          const selectedParks = selections.filter(isPark);

          if (
            selectedParks.some(
              (park) =>
                findFeatureChildren(projectFeatures, park.id).length > 0,
            ) &&
            !window.confirm(
              "When converting a park to Other, all features related to the park (turbines etc.) will be removed. Continue?",
            )
          ) {
            return;
          }

          setProperties(ids, {
            type: undefined,
            color: colors.defaultCanvasLayer,
            parentIds: undefined,
          });
          return;
        }
        case PARK_PROPERTY_TYPE: {
          setProperties(
            ids,
            {
              type: PARK_PROPERTY_TYPE,
              color: colors.park2,
            },
            selections.reduce(
              (acc, s) => ({
                ...acc,
                [s.id]: { name: s.properties.name || DEFAULT_PARK_NAME },
              }),
              {},
            ),
          );
          if (ids.length === 1) {
            setCurrentSelectionArray(ids);
          }
          return;
        }
        case SUB_AREA_PROPERTY_TYPE: {
          setProperties(ids, {
            type: SUB_AREA_PROPERTY_TYPE,
            color: colors.subArea,
            parentIds: [item.parkId],
          });
          return;
        }
        case DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE: {
          setProperties(ids, {
            type: DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
            color: colors.exclusionZone,
            domain: ExclusionDomainDefault,
          });
          return;
        }
        case CABLE_CORRIDOR_PROPERTY_TYPE: {
          setProperties(ids, {
            type: CABLE_CORRIDOR_PROPERTY_TYPE,
            color: colors.cableCorridor,
            parentIds: [item.parkId],
          });
          return;
        }
      }
    },
    [
      projectId,
      branchId,
      setProperties,
      ids,
      selections,
      projectFeatures,
      setCurrentSelectionArray,
    ],
  );

  return (
    <MenuButton
      icon={<ArrowRightIcon />}
      iconOpen={<ArrowDownIcon />}
      iconPosition="right"
      buttonStyle={{
        border: "none",
        height: "fit-content",
        justifyContent: "space-between",
        padding: 0,
      }}
      buttonType="dropdown"
      buttonText={polygonTypeToName[selectedType ?? "other"]}
      disabled={disabled}
    >
      <MenuItem
        name={"Other"}
        onClick={() => onSelectItem({ type: "other" })}
        icon={<TypeDot dotColor={colors.other} />}
      />
      <MenuItem
        name={polygonTypeToName[PARK_PROPERTY_TYPE]}
        onClick={() => {
          trackEvent("changedType");
          onSelectItem({ type: PARK_PROPERTY_TYPE });
        }}
        icon={<TypeDot dotColor={colors.park} />}
      />
      <MenuItem
        name={polygonTypeToName[DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE]}
        onClick={() =>
          onSelectItem({
            type: DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
          })
        }
        icon={<TypeDot dotColor={colors.exclusionZone} />}
        disabled={selectedType === PARK_PROPERTY_TYPE}
      />
      <>
        {parkId ? (
          <MenuItem
            name={polygonTypeToName[SUB_AREA_PROPERTY_TYPE]}
            onClick={() =>
              onSelectItem({
                type: SUB_AREA_PROPERTY_TYPE,
                parkId,
              })
            }
            icon={<SubAreaIcon />}
            disabled={selectedType === PARK_PROPERTY_TYPE}
          />
        ) : (
          <Tooltip
            text="You need at least one park to be able to convert the feature to this type"
            disabled={parks.length > 0}
            innerDivStyle={{
              width: "100%",
            }}
            position="right"
          >
            <MenuItem
              name={polygonTypeToName[SUB_AREA_PROPERTY_TYPE]}
              icon={<SubAreaIcon />}
              style={{
                width: "100%",
              }}
              disabled={
                selectedType === PARK_PROPERTY_TYPE || parks.length === 0
              }
            >
              {parks.map((park) => (
                <MenuItem
                  key={park.id}
                  name={park.properties.name ?? ""}
                  onClick={() =>
                    onSelectItem({
                      type: SUB_AREA_PROPERTY_TYPE,
                      parkId: park.id,
                    })
                  }
                  onMouseEnter={() => setHoveredParkId(park.id)}
                  onMouseLeave={() => setHoveredParkId(undefined)}
                />
              ))}
            </MenuItem>
          </Tooltip>
        )}
        {parkId ? (
          <MenuItem
            name={polygonTypeToName[CABLE_CORRIDOR_PROPERTY_TYPE]}
            onClick={() =>
              onSelectItem({ type: CABLE_CORRIDOR_PROPERTY_TYPE, parkId })
            }
            icon={<TypeAreaDot dotColor={colors.cableCorridor} />}
            disabled={selectedType === PARK_PROPERTY_TYPE}
          />
        ) : (
          <Tooltip
            text="You need at least one park to be able to convert the feature to this type"
            disabled={parks.length > 0}
            innerDivStyle={{
              width: "100%",
            }}
            position="right"
          >
            <MenuItem
              name={polygonTypeToName[CABLE_CORRIDOR_PROPERTY_TYPE]}
              icon={<TypeAreaDot dotColor={colors.cableCorridor} />}
              disabled={
                selectedType === PARK_PROPERTY_TYPE || parks.length === 0
              }
              style={{
                width: "100%",
              }}
            >
              {parks.map((park) => (
                <MenuItem
                  key={park.id}
                  name={park.properties.name ?? ""}
                  onClick={() =>
                    onSelectItem({
                      type: CABLE_CORRIDOR_PROPERTY_TYPE,
                      parkId: park.id,
                    })
                  }
                  onMouseEnter={() => setHoveredParkId(park.id)}
                  onMouseLeave={() => setHoveredParkId(undefined)}
                />
              ))}
            </MenuItem>
          </Tooltip>
        )}
      </>
    </MenuButton>
  );
}
