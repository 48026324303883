import { useMemo } from "react";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import {
  cableTypeUsageAtomFamily,
  CableTypeUsageType,
} from "../../../state/cable";
import {
  organisationIdSelector,
  useTypedPath,
} from "../../../state/pathParams";
import { projectBranchesAtomFamily } from "../../../state/timeline";

import {
  adminInOrganisationSelectorFamily,
  editorAccessProjectSelector,
  orgCableManageAccessSelector,
} from "../../../state/user";
import { UsageContainerInner } from "./common";
import { Header, Name, SubHeader, UsageText } from "./style";

export default function CableTypeUsageCurrentProject({
  cableTypeId,
}: {
  cableTypeId: string;
}) {
  const editorAccess = useRecoilValue(editorAccessProjectSelector);
  const { projectId } = useTypedPath("projectId");

  if (!editorAccess) return null;

  return <CableTypeUsageInner cableTypeId={cableTypeId} nodeId={projectId} />;
}

export function CableTypeUsageLibrary({
  cableTypeId,
}: {
  cableTypeId: string;
}) {
  const organisationId = useRecoilValue(organisationIdSelector);
  const adminInOrg = useRecoilValue(
    adminInOrganisationSelectorFamily({ organisationId }),
  );
  const hasOrgTurbineAccess = useRecoilValue(orgCableManageAccessSelector);

  if (!organisationId) return null;
  if (!adminInOrg && !hasOrgTurbineAccess) return null;

  return (
    <CableTypeUsageInner cableTypeId={cableTypeId} nodeId={organisationId} />
  );
}

function CableTypeUsageInner({
  cableTypeId,
  nodeId,
}: {
  cableTypeId: string;
  nodeId: string;
}) {
  const usageLoadable = useRecoilValueLoadable(
    cableTypeUsageAtomFamily({
      nodeId,
      cableTypeId,
    }),
  );

  return (
    <UsageContainerInner
      loadable={usageLoadable}
      text="This type is used in: "
      typeName="cables"
    >
      <ExtraInfo usage={usageLoadable.contents} />
    </UsageContainerInner>
  );
}

function ExtraInfo({ usage }: { usage: CableTypeUsageType[] }) {
  const uniqueBranches = useMemo(
    () =>
      usage.reduce<CableTypeUsageType[]>(
        (pre, cur) =>
          pre.some((p) => p.branchId === cur.branchId) ? pre : pre.concat(cur),
        [],
      ),
    [usage],
  );

  return (
    <>
      <Header>Cable type usage</Header>
      <div style={{ display: "flex", flexDirection: "column", gap: "0.8rem" }}>
        <SubHeader>Used in</SubHeader>
        <UsageText>{usage.length} cables</UsageText>
      </div>
      {usage.length > 0 && (
        <div
          style={{ display: "flex", flexDirection: "column", gap: "0.8rem" }}
        >
          <SubHeader>Used in branches</SubHeader>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
          >
            {uniqueBranches.map((u) => (
              <BranchInfo key={u.branchId} usage={u} />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

function BranchInfo({
  usage,
}: {
  usage: { projectId: string; branchId: string };
}) {
  const { organisationId } = useTypedPath("organisationId");
  const branchMetaObjects = useRecoilValue(
    projectBranchesAtomFamily({
      nodeId: usage.projectId,
    }),
  );

  return (
    <Name
      key={usage.branchId}
      to={`/design/project/${organisationId}/${usage.projectId}/${usage.branchId}`}
      target={"_blank"}
    >
      {branchMetaObjects.find((b) => b.id === usage.branchId)?.title}
    </Name>
  );
}
