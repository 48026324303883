import { ReactNode } from "react";
import styled, { css, keyframes } from "styled-components";
import { colors, hexToRgbA } from "styles/colors";
import { activeTourState, activeTourStepState } from "./state";
import { useRecoilValue } from "recoil";

const createGlow = (color: string) => keyframes`
  from {
    box-shadow: 0 0 0 5px ${hexToRgbA(color, 1.0)};
  }
  to {
    box-shadow: 0 0 0 8px ${hexToRgbA(color, 1.0)};
  }
`;

const HighlightWrapper = styled.div<{
  $active: boolean;
  $primary?: string;
  $padding?: string;
}>`
  ${({ $active, $primary }) => {
    const color = $primary ? $primary : colors.surfaceBrand;
    return (
      $active &&
      css`
        animation: ${createGlow(color)} 1s alternate infinite;
        border-radius: 4px;
        z-index: 9;
      `
    );
  }}

  ${({ $active, $padding }) => {
    return (
      $active &&
      css`
        border-radius: 4px;
        z-index: 9;
        padding: ${$padding ? $padding : "0px"};
      `
    );
  }}
`;

/// Higlight child components when a tour step is active
/// optionally adjusting color and padding
export const HighlightStep = ({
  stepId,
  tourId,
  children,
  color,
  padding,
}: {
  stepId: string;
  tourId: string;
  children: ReactNode;
  color?: string;
  padding?: string;
}) => {
  const activeTour = useRecoilValue(activeTourState);
  const activeStep = useRecoilValue(activeTourStepState);

  const active = tourId === activeTour?.tour_id && stepId === activeStep;

  return (
    <HighlightWrapper $active={active} $primary={color} $padding={padding}>
      {children}
    </HighlightWrapper>
  );
};
