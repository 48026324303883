import { CostConfiguration } from "services/costService";
import { CostSettingsInner } from "components/ConfigurationModal/CostSettings";
import {
  ErrorBoundaryWarningTriangle,
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";

const FinancialSettingInLibrary = ErrorBoundaryWrapper(
  ({
    configuration,
    configurationName,
    onSave,
    saveName,
    onUnmount,
  }: {
    configuration: CostConfiguration;
    configurationName: string;
    onSave: (config: CostConfiguration) => Promise<any>;
    saveName?: (id: string, name: string) => Promise<any>;
    onUnmount?: () => void;
    isLoading: boolean;
  }) => {
    return (
      <CostSettingsInner
        configuration={configuration}
        configurationName={configurationName}
        save={onSave}
        saveName={saveName}
        onUnmount={onUnmount}
        isLibraryResource={true}
        readOnly={false}
      />
    );
  },
  () => {
    return (
      <>
        <FatalErrorBoundaryWrapper />
        <ErrorBoundaryWarningTriangle />
      </>
    );
  },
  ScreamOnError,
);

export default FinancialSettingInLibrary;
