import { atom, useRecoilValue, useRecoilValueLoadable } from "recoil";
import { loggedInUserSelector } from "../../state/user";
import FullScreenModal from "../FullScreenModal/FullScreenModal";
import ProgressIndicator from "./ProgressIndicator/ProgressIndicator";
import { ModalContainer } from "./style";
import TermsOfUse from "./TermsOfUse";
import Interests from "./UserInterests";

export const wizardStepState = atom({ key: "wizardStepState", default: 0 });
export const acceptedTermsOfUseState = atom({
  key: "acceptedTermsOfUseState",
  default: false,
});

function WizardInner() {
  const step = useRecoilValue(wizardStepState);
  const currentUser = useRecoilValue(loggedInUserSelector);

  return (
    <>
      <ProgressIndicator currentStep={step} />
      {step === 0 && <TermsOfUse />}
      {step === 1 && (
        <Interests
          currentInterests={currentUser?.interests ?? {}}
          currentReceiveNews={!!currentUser?.allow_interest_specific_news}
        />
      )}
    </>
  );
}

export default function TermsOfUseWizard() {
  const userData = useRecoilValueLoadable(loggedInUserSelector).valueMaybe();

  if (!userData) return null;
  if (userData.interests && userData.accept_terms_of_use) return null;

  return (
    <FullScreenModal>
      <ModalContainer>
        <WizardInner />
      </ModalContainer>
    </FullScreenModal>
  );
}
