import { atom, selector } from "recoil";
import { Layer } from "types/layers";
import { projectIdSelector } from "state/pathParams";
import { getAllLayersSelector } from "state/layer";
import { Pane } from "./LayerListV2";
import { customLayersMetadataSelectorAsync } from "state/customLayers";

export const LayerListPaneAtom = atom<Pane>({
  key: "LayerListPaneAtom",
  default: { which: "list" },
});

export const selectedLayerForLayerInformationSelector = selector<
  Layer | undefined
>({
  key: "selectedLayerForLayerInformationSelector",
  get: ({ get }) => {
    const selectedLayers = get(selectedLayerIdsWithCollectionIdAtom);
    const projectId = get(projectIdSelector);

    if (selectedLayers.length === 1 && projectId) {
      const allExternalLayers = get(getAllLayersSelector({ projectId }));
      const allCustomLayers = get(
        customLayersMetadataSelectorAsync({ nodeId: projectId }),
      );

      const allLayers = [...allExternalLayers, ...allCustomLayers];
      return allLayers.find((layer) => layer.id === selectedLayers[0].layerId);
    }
  },
});

export const scrollToLayerIdInListAtom = atom<string | undefined>({
  key: "scrollToLayerIdInListAtom",
  default: undefined,
});

export const selectedLayerIdsWithCollectionIdAtom = atom<
  Array<{ layerId: string; collectionId?: string }>
>({
  key: "selectedLayerIdsWithCollectionIdAtom",
  default: [],
});
