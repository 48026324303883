/// <reference types="vite-plugin-svgr/client" />
import Close from "@icons/24/Close.svg?react";
import WarningTriangle from "@icons/24/WarningTriangle.svg?react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { defaultExportCables } from "../../constants/cabling";
import {
  CableType,
  ExportCableVoltageType,
  _ExportCableVoltageType,
} from "../../services/cableTypeService";
import { toastMessagesAtom } from "../../state/toast";
import { colors } from "../../styles/colors";
import { Divider } from "../../styles/misc/Misc";
import { spaceMedium, spaceSmall, spaceTiny } from "../../styles/space";
import { IconREMSize, TextIcon } from "../../styles/typography";
import { isNumber } from "../../utils/predicates";
import { showPowerRatingWarningAtomFamily } from "../Cabling/Generate/state";
import Dropdown from "../Dropdown/Dropdown";
import Button from "../General/Button";
import { Label } from "../General/Form";
import { Input, InputDimensioned, TextArea } from "../General/Input";
import { Row } from "../General/Layout";
import HelpTooltip from "../HelpTooltip/HelpTooltip";
import { valueRounding } from "../RightSide/InfoModal/FoundationModal/utils";
import { unsavedSettingsState } from "../SettingsV2/Shared/state";
import {
  ContentWrapper,
  SettingsFooter,
  SettingsHeader,
} from "../SettingsV2/Shared/styles";
import CableTypeUsageCurrentProject from "./SettingsUsage/CableTypeUsage";
import { SettingsColumn } from "./SubstationSettings";
import { ColumnContent } from "./TurbineSettings";
import useEnterToSave from "./useEnterToSave";
import { parseOr } from "utils/zod";
import {
  MAX_POWER_RATING_DEVIATION,
  POWER_RATING_MW_MAX,
  POWER_RATING_MW_MIN,
} from "types/cables";

export const wattToMegawatt = (watt: number) => watt / 1e6;

export const megawattToWatt = (megawatt: number) => megawatt * 1e6;

export const EXPORT_POWER_RATING_MW_DEFAULT = 432;
export const EXPORT_VOLTAGE_DEFAULT = ExportCableVoltageType.kV220;
export const EXPORT_RESISTANCE_DEFAULT =
  defaultExportCables[ExportCableVoltageType.kV220][
    "Offshore 1000mm2 Cu 220kV AC"
  ].resistance;
export const EXPORT_REACTANCE_DEFAULT =
  defaultExportCables[ExportCableVoltageType.kV220][
    "Offshore 1000mm2 Cu 220kV AC"
  ].reactance;
export const EXPORT_CAPACITANCE_DEFAULT =
  defaultExportCables[ExportCableVoltageType.kV220][
    "Offshore 1000mm2 Cu 220kV AC"
  ].capacitance;
export const EXPORT_AMPACITY_DEFAULT =
  defaultExportCables[ExportCableVoltageType.kV220][
    "Offshore 1000mm2 Cu 220kV AC"
  ].ampacity;
export const EXPORT_TYPE_DEFAULT =
  defaultExportCables[ExportCableVoltageType.kV220][
    "Offshore 1000mm2 Cu 220kV AC"
  ].exportCableType;

const ScaledWarningTriangle = () => {
  return (
    <IconREMSize height={1.4} width={1.4}>
      <WarningTriangle />
    </IconREMSize>
  );
};

const ScaledCloseIcon = () => {
  return (
    <IconREMSize height={1.2} width={1.2}>
      <Close />
    </IconREMSize>
  );
};

const Header = styled.div`
  background: ${colors.focusBackground};
  border-bottom: 1px solid ${colors.inputOutline};

  p {
    font-weight: bold;
    text-align: right;
    padding: 0.1rem 0.5rem;
  }

  p:first-child {
    text-align: left;
  }
`;

const CableListItem = styled.div`
  &:hover {
    background: ${colors.hover};
    cursor: pointer;
  }

  p {
    text-align: right;
    padding: 0.1rem 0.3rem;
  }

  p:first-child {
    text-align: left;
  }
`;
const WarningContainer = styled.div`
  padding: 0.8rem 0.3rem;
  border-radius: 0.4rem;
  background-color: #faf7f4;
`;

const between = (min: number, max: number) => (n: number) =>
  min <= n && n <= max;

export function SingleExportCable({
  cable,
  onSave,
  isLoading,
  disabled,
}: {
  cable: CableType;
  onSave: (cable: CableType) => void;
  isLoading: boolean;
  disabled: boolean;
}) {
  const [_cable, setCable] = useState(cable);
  const setUnsavedSettings = useSetRecoilState(unsavedSettingsState);
  const setToastMessages = useSetRecoilState(toastMessagesAtom);

  useEffect(() => {
    setCable(cable);
  }, [cable]);

  const hasChanged = useMemo(() => {
    return cable !== _cable;
  }, [_cable, cable]);

  const allChangesSaved = !hasChanged || isLoading;

  useEffect(() => {
    setUnsavedSettings(!isLoading && !allChangesSaved);
  }, [allChangesSaved, isLoading, setUnsavedSettings]);

  const onEnterSaveCallback = useCallback(
    () => onSave(_cable),
    [onSave, _cable],
  );
  useEnterToSave(
    onEnterSaveCallback,
    !allChangesSaved && !isLoading && !disabled,
  );

  const currentDefaultCables =
    defaultExportCables[
      parseOr(
        _ExportCableVoltageType,
        _cable.voltage,
        ExportCableVoltageType.kV220,
      )
    ];

  const estPowerRating = megawattToWatt(
    _cable.voltage * _cable.ampacity * Math.sqrt(3),
  );
  const diffPowerRating = Math.abs(estPowerRating / _cable.powerRating - 1);

  const [showPowerRatingWarning, setShowPowerRatingWarning] = useRecoilState(
    showPowerRatingWarningAtomFamily(cable.id),
  );

  return (
    <>
      {!allChangesSaved && (
        <SettingsHeader>
          <Button
            disabled={allChangesSaved}
            text="Cancel"
            buttonType="secondary"
            onClick={() => {
              setCable(cable);
            }}
            style={{ marginLeft: "auto" }}
          />
          <Button
            disabled={allChangesSaved}
            text="Save changes"
            onClick={() => {
              setToastMessages((tm) => [
                ...tm,
                { text: "Saving...", timeout: 1000 },
              ]);
              onSave(_cable);
            }}
          />
        </SettingsHeader>
      )}
      <ContentWrapper
        style={{
          maxHeight: "calc(100% - 7.3rem)",
          overflowY: "auto",
          boxSizing: "border-box",
        }}
      >
        <ColumnContent>
          <h4 style={{ margin: 0 }}>Cable settings</h4>

          <SettingsColumn>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "2fr 2fr",
                gap: spaceMedium,
              }}
            >
              <Label>
                <p>Name</p>
                <Input
                  disabled={disabled}
                  value={_cable.name}
                  type="string"
                  onChange={(e) =>
                    setCable({
                      ..._cable,
                      name: e.target.value,
                    })
                  }
                />
              </Label>
              <Label>
                <p>Cost</p>
                <InputDimensioned
                  disabled={disabled}
                  unit={_cable.costUnit}
                  value={_cable.cost}
                  step={0.1}
                  validate={(e) => 0 <= e}
                  validationMessage={`Needs to be greater than 0`}
                  onChange={(cost) => {
                    setCable({
                      ..._cable,
                      cost,
                    });
                  }}
                />
              </Label>
            </div>
            <Row>
              <Label>
                <p>Export cable type</p>
                <Dropdown
                  disabled
                  value={_cable.voltage}
                  onChange={(e) => {
                    setCable({
                      ..._cable,
                      voltage: Number.parseInt(e.target.value),
                    });
                  }}
                >
                  <option value="AC">AC</option>
                  <option value="DC">DC</option>
                </Dropdown>
              </Label>
              <Label>
                <p>Voltage</p>
                <Dropdown
                  disabled={disabled}
                  value={_cable.voltage}
                  onChange={(e) => {
                    setCable({
                      ..._cable,
                      voltage: _ExportCableVoltageType.parse(
                        Number.parseInt(e.target.value),
                      ),
                    });
                  }}
                >
                  <option
                    value={ExportCableVoltageType.kV150}
                  >{`${ExportCableVoltageType.kV150}kV`}</option>
                  <option
                    value={ExportCableVoltageType.kV220}
                  >{`${ExportCableVoltageType.kV220}kV`}</option>
                  <option value={ExportCableVoltageType.kV275}>
                    {`${ExportCableVoltageType.kV275}kV`}
                  </option>
                </Dropdown>
              </Label>
              <Label>
                <p>Power rating</p>
                <InputDimensioned
                  disabled={disabled}
                  value={wattToMegawatt(_cable.powerRating)}
                  validate={(n) =>
                    POWER_RATING_MW_MIN <= n && n <= POWER_RATING_MW_MAX
                  }
                  validationMessage={`Needs to be within ${POWER_RATING_MW_MIN} - ${POWER_RATING_MW_MAX}`}
                  unit={"MW"}
                  onChange={(e) => {
                    const powerRatingMegawatt = e;
                    if (!isNumber(powerRatingMegawatt)) return;

                    setCable({
                      ..._cable,
                      powerRating: megawattToWatt(powerRatingMegawatt),
                    });

                    setShowPowerRatingWarning(true);
                  }}
                />
              </Label>
            </Row>
            {diffPowerRating > MAX_POWER_RATING_DEVIATION &&
              showPowerRatingWarning && (
                <WarningContainer>
                  <Row
                    style={{
                      gap: spaceTiny,
                    }}
                  >
                    <TextIcon style={{ marginLeft: "auto" }}>
                      <ScaledWarningTriangle />
                    </TextIcon>
                    <p>
                      Power rating deviates &gt;{" "}
                      {MAX_POWER_RATING_DEVIATION * 100} % from value based on
                      voltage and ampacity
                    </p>

                    <TextIcon
                      onClick={() => setShowPowerRatingWarning(false)}
                      style={{ marginLeft: "auto" }}
                    >
                      <ScaledCloseIcon />
                    </TextIcon>
                  </Row>
                </WarningContainer>
              )}
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1.5fr 1.5fr 1.5fr 1.5fr",
                gap: spaceMedium,
              }}
            >
              <Label>
                <Row>
                  <p>Resistance</p>
                  <HelpTooltip text="Conductor AC resistance at 20 degrees." />
                </Row>
                <InputDimensioned
                  disabled={disabled}
                  value={_cable.resistance}
                  validate={between(0.001, 1)}
                  validationMessage={`Must be between 0.001 and 1`}
                  unit={"ohm per km"}
                  onChange={(e) => {
                    if (!isNumber(e)) return;

                    setCable({
                      ..._cable,
                      resistance: e,
                    });
                  }}
                />
              </Label>
              <Label>
                <p>Reactance</p>
                <InputDimensioned
                  disabled={disabled}
                  value={_cable.reactance}
                  validate={between(0.001, 1)}
                  validationMessage={`Must be between 0.001 and 1`}
                  unit={"ohm per km"}
                  onChange={(e) => {
                    if (!isNumber(e)) return;

                    setCable({
                      ..._cable,
                      reactance: e,
                    });
                  }}
                />
              </Label>
              <Label>
                <p>Capacitance</p>
                <InputDimensioned
                  disabled={disabled}
                  value={_cable.capacitance}
                  validate={between(10, 1000)}
                  validationMessage={`Must be between 10 and 1000`}
                  unit={"nF per km"}
                  onChange={(e) => {
                    if (!isNumber(e)) return;

                    setCable({
                      ..._cable,
                      capacitance: e,
                    });
                  }}
                />
              </Label>
              <Label>
                <p>Ampacity</p>
                <InputDimensioned
                  disabled={disabled}
                  value={_cable.ampacity}
                  validate={between(0.1, 10)}
                  validationMessage={`Must be between 0.1 and 10`}
                  unit={"kilo Ampere"}
                  onChange={(e) => {
                    if (!isNumber(e)) return;

                    setCable({
                      ..._cable,
                      ampacity: e,
                    });
                  }}
                />
              </Label>
            </div>

            <Label>
              <p>Description</p>
              <TextArea
                rows={4}
                style={{ resize: "vertical" }}
                disabled={disabled}
                placeholder={"Add a description"}
                value={_cable.note ?? ""}
                onChange={(e) => {
                  setCable({ ..._cable, note: e.target.value });
                }}
              />
            </Label>
            <Divider />
            <Label
              style={{
                marginTop: spaceSmall,
              }}
            >
              <p style={{ fontWeight: "bold" }}>
                Standard export cable library
              </p>
            </Label>
            <Label
              style={{
                border: `1px solid ${colors.inputOutline}`,
              }}
            >
              <Header>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1.5fr 1.5fr 1.5fr 1.5fr 1.5fr",
                  }}
                >
                  <p>Name</p>
                  <p>Resistance [ohm per km]</p>
                  <p>Reactance [ohm per km]</p>
                  <p>Capacitance [nF per km]</p>
                  <p>Ampacity [kilo Ampere]</p>
                </div>
              </Header>
              {Object.entries(currentDefaultCables).map(([name, prop]) => (
                <CableListItem
                  key={name}
                  onClick={() => {
                    const voltage = _cable.voltage;
                    const estPowerRatingMW = valueRounding(
                      voltage * prop.ampacity * Math.sqrt(3),
                      1,
                    );
                    setCable({
                      ..._cable,
                      powerRating: megawattToWatt(estPowerRatingMW),
                      reactance: prop.reactance,
                      resistance: prop.resistance,
                      capacitance: prop.capacitance,
                      ampacity: prop.ampacity,
                    });
                  }}
                >
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1.5fr 1.5fr 1.5fr 1.5fr 1.5fr",
                    }}
                  >
                    <p>{name}</p>
                    <p>{prop.resistance}</p>
                    <p>{prop.reactance}</p>
                    <p>{prop.capacitance}</p>
                    <p>{prop.ampacity}</p>
                  </div>
                </CableListItem>
              ))}
            </Label>
          </SettingsColumn>
        </ColumnContent>
      </ContentWrapper>
      <SettingsFooter>
        <CableTypeUsageCurrentProject cableTypeId={_cable.id} />
      </SettingsFooter>
    </>
  );
}
