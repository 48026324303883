import styled from "styled-components";
import { spaceSmall, spaceTiny, spacing4, spacing6 } from "../../styles/space";
import { Comp } from "../../types/utils";
import { colors } from "styles/colors";
import { typography } from "styles/typography";

type LabelProps = {
  left?: boolean;
  right?: boolean;
  top?: boolean;
  bottom?: boolean;
};

export const StyledLabel = styled.label<{ direction: string; order: number }>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  justify-content: stretch;
  align-items: ${(p) => (p.direction === "row" ? "center" : "stretch")};
  gap: ${(p) => (p.direction === "row" ? spacing6 : spacing4)};
  color: ${colors.grey700};

  > :first-child {
    order: ${(props) => props.order};
  }
  > :not(:first-child) {
    flex: 1;
  }

  p {
    margin: 0;
    ${typography.caption}
  }
`;

export const Label = ({
  left,
  right,
  bottom,
  children,
  ...props
}: Comp<"label", LabelProps>) => {
  const direction = left || right ? "row" : "column";
  const order = bottom || right ? 1 : 0;
  return (
    <StyledLabel direction={direction} order={order} {...props}>
      {children}
    </StyledLabel>
  );
};

const StyledLabelButDiv = styled.div<{ direction: string; order: number }>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  justify-content: stretch;
  align-items: ${(p) => (p.direction === "row" ? "center" : "stretch")};
  gap: ${(p) => (p.direction === "row" ? spaceSmall : spaceTiny)};

  > :first-child {
    order: ${(props) => props.order};
  }
  > :not(:first-child) {
    flex: 1;
  }

  p {
    margin: 0;
  }
`;

export const LabelDiv = ({
  left,
  right,
  bottom,
  children,
  ...props
}: Comp<"div", LabelProps>) => {
  const direction = left || right ? "row" : "column";
  const order = bottom || right ? 1 : 0;
  return (
    <StyledLabelButDiv direction={direction} order={order} {...props}>
      {children}
    </StyledLabelButDiv>
  );
};

/** Note that this doesn't play well with itemlac that wants to span both columns. */
export const Grid2 = styled.div.attrs(() => ({
  className: "grid2",
}))`
  display: grid;
  grid-template-columns: repeat(2, minmax(34%, 68%));
  grid-row-gap: 1.2rem;
  grid-column-gap: 1.2rem;

  align-items: baseline;

  > :nth-child(2n + 1) {
  }
  > :nth-child(2n) {
  }

  > p {
    margin: 0;
  }
`;

export const ColoredGrid = styled.div<{ noColor?: boolean }>`
  ${typography.caption}
  display: grid;
  grid-template-columns: repeat(2, minmax(34%, 68%));

  padding-bottom: ${spaceSmall};

  align-items: baseline;
  & > * {
    padding: ${spaceSmall};
  }
  & > :nth-child(2n) {
    text-align: right;
  }

  & > :nth-child(4n-3),
  & > :nth-child(4n-2) {
    background-color: ${(p) => !p.noColor && colors.surfaceSecondary};
  }

  > p {
    margin: 0;
  }
`;

export const ColoredGridFour = styled.div<{ noColor?: boolean }>`
  ${typography.caption}
  display: grid;
  grid-template-columns: 2fr 3fr 2fr 1fr;
  padding-bottom: ${spaceSmall};
  white-space: nowrap;

  align-items: baseline;
  & > * {
    padding: ${spaceSmall};
  }

  & > :nth-child(3n),
  & > :nth-child(4n) {
    text-align: right;
  }

  & > :nth-child(4n-2) {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  & > :nth-child(8n-7),
  & > :nth-child(8n-6),
  & > :nth-child(8n-5),
  & > :nth-child(8n-4) {
    background-color: ${(p) => !p.noColor && colors.surfaceSecondary};
  }
`;

/** This kinda sucks, but oh well. */
export const FlexGrid2 = styled.div.attrs(() => ({
  className: "flex-grid2",
}))`
  display: flex;
  flex-direction: column;
  gap: ${spaceSmall};
  > div {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    justify-content: space-between;
    align-items: center;
    gap: ${spaceSmall};
    > p {
      margin: 0;
    }
  }
`;
