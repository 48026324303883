import { useEffect } from "react";
import { selectorFamily, useRecoilValue } from "recoil";
import { useValidationWarnings } from "./ValidationWarnings";
import { cableSourceId } from "components/Mapbox/constants";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { allCableTypesSelector } from "components/Cabling/Generate/state";
import { projectFeaturesInBranchSelectorFamily } from "components/ProjectElements/state";
import { isCable } from "utils/predicates";
import { branchIdSelector } from "state/pathParams";

export const cableTypeInvalidWarningSelector = selectorFamily<
  | { type: ValidationWarningTypes.CableTypeInvalid; featureIds: string[] }
  | undefined,
  { branchId: string }
>({
  key: "cableTypeInvalidWarningSelector",
  get:
    ({ branchId }) =>
    ({ get }) => {
      const branchData = get(
        projectFeaturesInBranchSelectorFamily({ branchId }),
      );
      const allBranchCables = branchData.filter(isCable);
      const cableTypes = get(allCableTypesSelector);
      const invalidCableTypeReferences = allBranchCables.filter((f) =>
        cableTypes.every((tt) => tt.id !== f.properties.cableTypeId),
      );

      return invalidCableTypeReferences.length === 0
        ? undefined
        : {
            type: ValidationWarningTypes.CableTypeInvalid,
            featureIds: invalidCableTypeReferences.map((t) => t.id),
          };
    },
});

export const CableTypeInvalid = ({
  map,
  source = cableSourceId,
}: {
  map: mapboxgl.Map;
  source?: string;
}) => {
  const branchId = useRecoilValue(branchIdSelector);
  const invalidCableTypeReferences = useRecoilValue(
    cableTypeInvalidWarningSelector({ branchId: branchId ?? "" }),
  );
  const { warn, remove } = useValidationWarnings();

  useEffect(() => {
    if (!invalidCableTypeReferences) return;
    warn(invalidCableTypeReferences);
    if (map.getSource(source))
      for (const id of invalidCableTypeReferences.featureIds) {
        map.setFeatureState({ source, id }, { error: true });
      }
    return () => {
      remove(ValidationWarningTypes.CableTypeInvalid);
      if (map.getSource(source))
        for (const id of invalidCableTypeReferences.featureIds)
          map.removeFeatureState({ source, id }, "error");
    };
  }, [invalidCableTypeReferences, map, remove, source, warn]);

  return null;
};
