import { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import {
  libraryFoundationsRefreshAtom,
  projectFoundationsRefreshAtom,
} from "state/foundations";

export const useRefreshProjectFoundation = () => {
  const setRefresh = useSetRecoilState(projectFoundationsRefreshAtom);
  return useCallback(() => {
    setRefresh((currVal) => currVal + 1);
  }, [setRefresh]);
};

export const useRefreshLibraryFoundation = () => {
  const setRefresh = useSetRecoilState(libraryFoundationsRefreshAtom);
  return useCallback(() => {
    setRefresh((currVal) => currVal + 1);
  }, [setRefresh]);
};
