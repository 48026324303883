import { orTextLoader } from "components/Loading/Skeleton";
import {
  getConfiguration,
  getTotalInterArrayLoss,
  getNeighbourWakeLoss,
  getOtherLosses,
  getStoppedReason,
  getTotalExportSystemLoss,
  getTotalWakeLoss,
  getStoppedReasonFromAnalysis,
  getAnalysisProgress,
  getTurbineSpecificLoss,
} from "components/ProductionV2/state";
import {
  AnalysisStoppedTypes,
  analysisStoppedText,
} from "components/ProductionV2/types";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { getTurbinesSelectorFamily } from "../../../state/layout";
import { spaceMedium } from "../../../styles/space";
import { TurbineFeature } from "../../../types/feature";
import { FlexGrid2 } from "../../General/Form";
import { Row } from "../../General/Layout";
import {
  formatElectricalLossToPercentDecimal,
  formatPercent,
  formatLossToPercent,
} from "../../ProductionV2/format";
import { useDashboardContext } from "../Dashboard";
import { CenterContainer, SafeCard } from "./Base";
import { undefMap } from "utils/utils";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { colors } from "styles/colors";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";

const Disabled = () => (
  <p style={{ color: colors.primaryDisabled }}>Disabled</p>
);

const Loss = () => {
  const { park, triggerId } = useDashboardContext();
  const parkId = park.id;
  const turbines: TurbineFeature[] = useRecoilValue(
    getTurbinesSelectorFamily({ parkId }),
  );

  const otherLoss = useRecoilValueLoadable(getOtherLosses(triggerId));
  const totalInterArrayLoss = useRecoilValueLoadable(
    getTotalInterArrayLoss(triggerId),
  ).map((ia) => ia?.totalInterArrayLoss);
  const totalExportSystemLoss = useRecoilValueLoadable(
    getTotalExportSystemLoss(triggerId),
  ).map((l) => l?.totalExportSystemLoss);
  const totalWakeLoss = useRecoilValueLoadable(getTotalWakeLoss(triggerId));
  const turbineSpecificLoss = useRecoilValueLoadable(
    getTurbineSpecificLoss(triggerId),
  );
  const neighbourWakeLoss = useRecoilValueLoadable(
    getNeighbourWakeLoss(triggerId),
  );
  const stoppedReason = useRecoilValueLoadable(
    getStoppedReason(triggerId),
  ).valueMaybe();

  const analysisStoppedReason = useRecoilValueLoadable(
    getStoppedReasonFromAnalysis(triggerId),
  ).valueMaybe();
  const analysisProgress = useRecoilValueLoadable(
    getAnalysisProgress(triggerId),
  ).valueMaybe();

  const configuration = useRecoilValueLoadable(
    getConfiguration(triggerId),
  ).valueMaybe();
  const showNeighbourWakeLoss =
    configuration?.wakeAnalysis.neighbourWake ?? false;

  const showInterArrayLoss =
    (configuration?.electrical.interArrayCableLoss ?? false) ||
    (configuration?.electrical.turbineTrafoLoss ?? false);

  const exportSystemIsEnabled =
    configuration?.electrical.exportSystemLoss ?? false;

  if (turbines.length === 0) {
    return (
      <CenterContainer>
        <SimpleAlert text={"No turbines in the park."} type={"error"} />
      </CenterContainer>
    );
  }

  if (stoppedReason) {
    return (
      <CenterContainer>
        <SimpleAlert text={analysisStoppedText[stoppedReason]} type={"error"} />
      </CenterContainer>
    );
  }
  if (analysisStoppedReason) {
    return (
      <SimpleAlert
        text={
          analysisStoppedText[analysisStoppedReason as AnalysisStoppedTypes]
        }
        type={"error"}
      />
    );
  }

  return (
    <FlexGrid2 style={{ padding: spaceMedium }}>
      <Row>
        <p>Total wake loss</p>
        {orTextLoader(
          totalWakeLoss,
          undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ?? "",
          (wl) => (
            <p>{formatLossToPercent(wl)}%</p>
          ),
        )}
      </Row>

      <Row>
        <p>Neighbour wake loss</p>
        {showNeighbourWakeLoss ? (
          orTextLoader(
            neighbourWakeLoss,
            undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ?? "",
            (nwl) => <p>{formatLossToPercent(nwl)}%</p>,
          )
        ) : (
          <Disabled />
        )}
      </Row>

      <Row>
        <Row>
          <p style={{ display: "flex", gap: "0.8rem", fontWeight: 700 }}>
            Turbine-specific loss{" "}
          </p>
          <HelpTooltip
            text={
              "Proportion of the gross output that is lost to turbine-specific losses, which is specific on the turbine type."
            }
          />
        </Row>

        {orTextLoader(
          turbineSpecificLoss,
          undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ?? "",
          (wl) => (
            <p>{formatLossToPercent(wl)}%</p>
          ),
        )}
      </Row>

      <Row>
        <p>Total inter array loss</p>
        {showInterArrayLoss ? (
          orTextLoader(
            totalInterArrayLoss,
            undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ?? "",
            (ia) => <p>{formatElectricalLossToPercentDecimal(ia ?? 0)}</p>,
          )
        ) : (
          <Disabled />
        )}
      </Row>

      <Row>
        <p>Total export system loss</p>
        {exportSystemIsEnabled ? (
          orTextLoader(
            totalExportSystemLoss,
            undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ?? "",
            (ia) => <p>{formatElectricalLossToPercentDecimal(ia ?? 0)}</p>,
          )
        ) : (
          <Disabled />
        )}
      </Row>

      <Row>
        <p>Other losses</p>
        {orTextLoader(
          otherLoss,
          undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ?? "",
          (ol) => (
            <p>{formatPercent(ol)}</p>
          ),
        )}
      </Row>
    </FlexGrid2>
  );
};

export const LossWidget = () => (
  <SafeCard title="Losses" id="Losses">
    <Loss />
  </SafeCard>
);
