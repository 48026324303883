/// <reference types="vite-plugin-svgr/client" />
import React from "react";
import type { Column } from "react-table";
import styled from "styled-components";
import AddIcon from "@icons/24/Add.svg?react";
import BinIcon from "@icons/24/Bin.svg?react";
import ExpandVerticalIcon from "@icons/24/ExpandVertical.svg?react";
import { IconREMSize, typography } from "../../styles/typography";
import Button from "../General/Button";
import { isCustomLayer, LayerIconMapper } from "../LayerList/utils";
import SourceLogo from "./components/SourceLogo";
import { LayerWithSearchRelevance } from "../../types/layers";
import { Deprecated } from "../Deprecated/Deprecated";
import { TranslatedLayerName } from "../LayerList/LayerInformation/LayerInformation";
import { spaceSmall } from "styles/space";

const SourceLink = styled.a`
  display: inline-flex;
  flex-grow: 0;
  align-items: center;
  padding: 1rem 0;
  text-decoration: inherit;
  color: blue;

  &:visited {
    color: blue;
  }

  &:not([href]) {
    color: unset;

    :hover {
      text-decoration: unset;
    }
  }

  :hover {
    text-decoration: underline;
  }
`;

const ColoredIcon = styled(IconREMSize)`
  svg {
    path {
      stroke: #515867;
    }
  }
`;

export const columns: Array<Column<LayerWithSearchRelevance>> = [
  {
    accessor: "name",
    Header: "Layer name",
    width: "40%",
    Cell: ({ row }: { row: any }) => {
      return (
        <div
          style={{
            ...typography.semiBold,
            fontWeight: "bold",
            padding: "1.5rem 1rem",
            display: "flex",
            gap: spaceSmall,
          }}
        >
          <ColoredIcon height={1} width={1} className="show-on-hover">
            <ExpandVerticalIcon />
          </ColoredIcon>
          <Deprecated isDeprecated={row.original.deprecated != null}>
            <TranslatedLayerName layer={row.original} />
          </Deprecated>
        </div>
      );
    },
  },
  // @ts-ignore: yolo
  {
    accessor: (originalRow) =>
      isCustomLayer(originalRow) ? originalRow.source : originalRow.source.name,
    id: "sourceName",
    Header: "Source",
    width: "40%",
    // @ts-ignore: yolo
    Cell: ({
      row,
      getSourceName,
      removePrivateSource,
    }: {
      row: any;
      getSourceName: any;
      removePrivateSource: any;
    }) => {
      const sourceLink = row.original.sourceLink;
      const sourceName = getSourceName(row.original.source);

      return (
        <SourceLink
          href={sourceLink.urlWhereFound ?? undefined}
          target="_blank"
          rel="noreferrer"
          onClick={(e) =>
            sourceLink.urlWhereFound ? e.stopPropagation() : undefined
          }
        >
          <SourceLogo
            url={sourceLink.url}
            style={{
              width: "22px",
              height: "22px",
              marginRight: "1rem",
            }}
          />
          <span style={{ ...typography.label }}>{sourceName}</span>
          {row.original.source?.private && (
            <IconREMSize
              height={1.5}
              width={1.5}
              style={{ marginLeft: "0.5rem" }}
              title="Delete private layer source"
              onClick={(e) => {
                e.stopPropagation();
                if (
                  window.confirm(
                    "This will delete the source and all layers belonging to this source. Are you sure?",
                  )
                ) {
                  removePrivateSource(row.original.source);
                }
              }}
            >
              <BinIcon />
            </IconREMSize>
          )}
        </SourceLink>
      );
    },
  },
  {
    accessor: "sourceType",
    Header: "Type",
    Cell: ({ row }: { row: any }) => {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "0.3rem" }}>
          <IconREMSize height={1.5} width={1.5}>
            <LayerIconMapper layer={row.original} />
          </IconREMSize>
          <p style={{ ...typography.label }}>{row.original.sourceType}</p>
        </div>
      );
    },
  },
  {
    accessor: "searchRelevance",
  },
  {
    id: "addButton",
    width: 160,
    disableSortBy: true,
    // @ts-ignore
    Cell: ({ addLayer, removeLayer: _removeLayer, getIsAdded, row }) => {
      const isAdded = getIsAdded(row.original.id);

      return (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: "1rem",
          }}
        >
          {isAdded ? (
            <p style={typography.caption}>Added to map</p>
          ) : (
            <Button
              buttonType="text"
              text="Add to map"
              icon={<AddIcon />}
              onClick={(e) => {
                e.stopPropagation();
                addLayer(row.original.id);
              }}
            />
          )}
        </div>
      );
    },
  },
];
