import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Map, MapLayerMouseEvent } from "mapbox-gl";
import { Position } from "geojson";
import * as turf from "@turf/turf";
import {
  DEFAULT_CANVAS_LAYER_COLOR,
  DEFAULT_CANVAS_POINT_COLOR,
} from "../../../../constants/canvas";
import { _FeatureParser, ProjectFeature } from "../../../../types/feature";
import {
  addFeatureAtom,
  editFeaturesAtom,
  mapControlsAtom,
} from "../../../../state/map";
import { useTypedPath } from "../../../../state/pathParams";
import { currentSelectionArrayAtom } from "../../../../state/selection";
import {
  DECIMAL_PRECISION,
  addIdOnFeaturesIfNotUUID4,
  reduceCoordinatePrecisionFeature,
} from "../../../../utils/geojson/utils";
import {
  isAnchor,
  isCable,
  isDefined,
  isExportCable,
  isMooringLine,
  isNotGeometryCollection,
  isPark,
  isPointFeature,
  isSubstation,
  isTurbine,
} from "../../../../utils/predicates";
import { projectFeaturesSelector } from "../../../ProjectElements/state";
import { useProjectElementsCrud } from "../../../ProjectElements/useProjectElementsCrud";
import { AddFeatureOptions } from "../../../../types/map";
import { resetListIfNotAlreadyEmpty } from "../../../../utils/resetList";
import { partition } from "../../../../utils/utils";
import {
  AddSubStationMenuType,
  DrawExportCableMenuType,
  GenerateCablesMenuType,
} from "../../../../constants/cabling";
import { MeasureDistanceMode } from "../../../Distance/Distance";
import { DrawModeChangeEvent } from "@vind-ai/mapbox-gl-draw";
import { useDrawMode } from "components/MapControls/useActivateDrawMode";
import { isDependentOfFeature } from "state/projectLayers";
import { useToast } from "hooks/useToast";
import useCreateCableSlicesBetweenTurbinesAndSubstations from "./useCreateCableSlicesBetweenTurbinesAndSubstations";
import { SiteLocatorMenuType } from "@constants/projectMapView";
import { keepExportModalOpenAtom } from "state/cable";

/**
 * Hack!
 *
 * We use mapbox-gl-draw for placing features in the map, but its API doesn't
 * give us the original event that triggered the drawing.  For instance, if we
 * want to place a new substation we only get a callback with the PointFeature,
 * but no event.  This is a problem because the code that deselects the current
 * park if the user clicks outside doesn't know about mapbox-gl-draw, so we end
 * up deselecing the park when we place a substation.
 *
 * The solution is simple and ugly.  Set this flag when placing a feature, and
 * ignore clicks on the map as long as the flag is set.  Reset the flag after
 * some milliseconds.
 */
export let IGNORE_CLICK_ON_MAP = false;

export default function TopLevelFeatureCallbacks({ map }: { map: Map }) {
  const { projectId, branchId } = useTypedPath("projectId", "branchId");
  const [searchParams] = useSearchParams();
  const projectFeatures = useRecoilValue(projectFeaturesSelector);
  const { update: updateFeatures } = useProjectElementsCrud();
  const { error: showError } = useToast();
  const [addFeature, setAddFeature] = useRecoilState(addFeatureAtom);
  const setCurrentSelectionArray = useSetRecoilState(currentSelectionArrayAtom);
  const [leftMenuActiveMode, setLeftMenuActiveMode] = useDrawMode();
  const setEditFeatureIds = useSetRecoilState(editFeaturesAtom);
  const mapControls = useRecoilValue(mapControlsAtom);
  const sliceCableBetweenTurbines =
    useCreateCableSlicesBetweenTurbinesAndSubstations();

  const keepExportModalOpen = useRecoilValue(keepExportModalOpenAtom);

  useEffect(() => {
    const createUpdate =
      (createFeature: boolean) =>
      (e: MapboxDraw.DrawCreateEvent | MapboxDraw.DrawUpdateEvent) => {
        const mapboxFeatures = e.features ?? [];

        // Annoyingly the map-draw creates features with string ids, which does not play well with mapbox map.on("click", the feature will get undefined as id
        // https://github.com/mapbox/mapbox-gl-js/issues/2716
        const features = addIdOnFeaturesIfNotUUID4(mapboxFeatures).map((f) =>
          reduceCoordinatePrecisionFeature(f, DECIMAL_PRECISION),
        );

        const addedOptions: Partial<AddFeatureOptions> = {
          ...addFeature?.options,
        };
        const { source } = addedOptions;
        if (source) {
          delete addedOptions.source;
        }
        const maybeNewFeatures: ProjectFeature[] = features
          .filter(isNotGeometryCollection)
          .map((feature) => {
            if (feature.properties.color == null) {
              feature.properties.color = isPointFeature(feature)
                ? DEFAULT_CANVAS_POINT_COLOR
                : DEFAULT_CANVAS_LAYER_COLOR;
            }

            if (feature.properties.name == null) {
              const featureType = feature.geometry.type;
              const existingFeatures = projectFeatures.filter(
                (f) => f.geometry?.type === featureType,
              ).length;
              feature.properties.name = `${feature.geometry.type}#${existingFeatures}`;
            }

            feature.properties = { ...feature.properties, ...addedOptions };
            feature.id = feature.properties.id;

            if (addFeature?.getPropertiesFunc) {
              const newPropertiesOrError =
                addFeature.getPropertiesFunc(feature);

              if (newPropertiesOrError instanceof Error) {
                showError(newPropertiesOrError.message);
              } else {
                feature.properties = {
                  ...feature.properties,
                  ...newPropertiesOrError,
                };
              }
            }

            return {
              type: feature.type,
              id: feature.id,
              geometry: feature.geometry,
              properties: feature.properties,
              bbox: feature.bbox,
            };
          });

        let [newFeatures, featuresToRemove] = partition(
          maybeNewFeatures,
          (f) => _FeatureParser.safeParse(f).success,
        );

        const featuresToUpdate: ProjectFeature[] = [];

        if (createFeature) {
          const cables = newFeatures.filter(isCable);
          cables.forEach((cable) => {
            try {
              const convertedLines = sliceCableBetweenTurbines([cable]);
              if (convertedLines) {
                const [newCables, updatedCables] = convertedLines;

                newFeatures.push(...addIdOnFeaturesIfNotUUID4(newCables));
                featuresToUpdate.push(...updatedCables);
                newFeatures = newFeatures.filter((f) => f.id !== cable.id);
              }
            } catch (e) {
              if (e instanceof Error) {
                showError(e.message);
              }
              featuresToRemove.push(cable);
              newFeatures = newFeatures.filter((f) => f.id !== cable.id);
            }
          });
        }

        let remove: undefined | string[] = undefined;

        if (0 < featuresToRemove.length) {
          mapControls?.deleteAll();
          remove = featuresToRemove.map((f) => f.id);
        }

        const newFeaturesSemanticConnected = newFeatures.map((f) => {
          if (isExportCable(f)) {
            const start = f.geometry.coordinates.at(0);
            const end = f.geometry.coordinates.at(-1);
            if (!start || !end) return f;

            const substations = projectFeatures.filter(isSubstation);

            const substationsCloseToStart = substations
              .map((substation) => {
                return {
                  distance: turf.distance(substation, turf.point(start), {
                    units: "meters",
                  }),
                  substation,
                };
              })
              .filter(({ distance }) => distance < 50)
              .sort((a, b) => a.distance - b.distance);

            const substationsCloseToEnd = substations
              .map((substation) => {
                return {
                  distance: turf.distance(substation, turf.point(end), {
                    units: "meters",
                  }),
                  substation,
                };
              })
              .filter(({ distance }) => distance < 50)
              .sort((a, b) => a.distance - b.distance);

            return {
              ...f,
              properties: {
                ...f.properties,
                fromSubstationId: substationsCloseToStart[0]?.substation.id,
                toSubstationId: substationsCloseToEnd[0]?.substation?.id,
              },
            };
          }
          return f;
        });

        if (createFeature) {
          updateFeatures({
            add: newFeaturesSemanticConnected,
            remove,
            update: featuresToUpdate,
          });
        } else {
          const allUpdatedFeatures = getAllUpdatedFeatures(
            newFeaturesSemanticConnected,
            projectFeatures,
          );
          updateFeatures({
            update: allUpdatedFeatures,
            remove,
          });
        }

        if (createFeature) {
          setEditFeatureIds(resetListIfNotAlreadyEmpty);
          mapControls?.deleteAll();
          const newParkFeatureId =
            newFeaturesSemanticConnected.find(isPark)?.id;
          if (newParkFeatureId) {
            setCurrentSelectionArray([newParkFeatureId]);
          } else {
            const newIds = newFeaturesSemanticConnected.map((f) => f.id);
            map.once("idle", () => {
              setCurrentSelectionArray(newIds);
            });
          }
        }
        IGNORE_CLICK_ON_MAP = true;
        setTimeout(() => {
          IGNORE_CLICK_ON_MAP = false;
        }, 100);
      };

    const modeChange = (e: DrawModeChangeEvent) => {
      if (!!addFeature?.continuePlacing && mapControls) {
        // @ts-ignore: Bad typing in Mapbox.
        mapControls.changeMode(addFeature.mode);
        return;
      }
      if (e.mode === "simple_select") {
        mapControls && mapControls.deleteAll();
        setEditFeatureIds(resetListIfNotAlreadyEmpty);

        if (
          leftMenuActiveMode === AddSubStationMenuType &&
          keepExportModalOpen
        ) {
          setLeftMenuActiveMode(DrawExportCableMenuType);
        } else {
          setLeftMenuActiveMode((c) =>
            // We need to be able to click on the partitions and chains when the
            // cable gen menu is open.
            [
              MeasureDistanceMode,
              GenerateCablesMenuType,
              SiteLocatorMenuType,
            ].some((menuType) => menuType === c)
              ? c
              : undefined,
          );
        }
        return;
      } else if (e.mode.includes("draw_")) {
        return;
      } else if (e.mode === "direct_select") {
        return;
      }

      setAddFeature(undefined);

      if (addFeature?.mode === "draw_point") {
        setTimeout(() => setLeftMenuActiveMode(undefined), 0); //A bit ugly hack, fixes that placing a point does not at the same time click on feature
      } else {
        setLeftMenuActiveMode(undefined);
      }
    };

    const create = createUpdate(true);
    const update = createUpdate(false);

    const _onDelete = (e: MapLayerMouseEvent) => {
      const updatedFeatures = e.features ?? [];
      const ids = updatedFeatures
        .map((f) => f.id)
        .filter(isDefined)
        .map((id) => String(id));
      if (ids) {
        updateFeatures({ remove: ids });
        setCurrentSelectionArray(resetListIfNotAlreadyEmpty);
        setEditFeatureIds(resetListIfNotAlreadyEmpty);
      }
    };

    map.on("draw.create", create);
    map.on("draw.update", update);
    map.on("draw.modechange", modeChange);
    map.on("draw.delete", _onDelete);

    return () => {
      map.off("draw.create", create);
      map.off("draw.update", update);
      map.off("draw.modechange", modeChange);
      map.off("draw.delete", _onDelete);
    };
  }, [
    keepExportModalOpen,
    leftMenuActiveMode,
    addFeature,
    branchId,
    projectFeatures,
    map,
    mapControls,
    projectId,
    searchParams,
    setAddFeature,
    setCurrentSelectionArray,
    setEditFeatureIds,
    setLeftMenuActiveMode,
    updateFeatures,
    showError,
    sliceCableBetweenTurbines,
  ]);

  return null;
}

// Exported for testing
export const getAllUpdatedFeatures = (
  newFeaturesSemanticConnected: ProjectFeature[],
  projectFeatures: ProjectFeature[],
) => {
  const idsAlreadyUpdated = new Set(
    newFeaturesSemanticConnected.map((f) => f.id),
  );

  let updatedChildren: ProjectFeature[] = [];
  for (const updatedFeature of newFeaturesSemanticConnected) {
    const updated = findAndUpdateChildrenRecursive(
      updatedFeature,
      projectFeatures.map((feature) => {
        const alreadyUpdated = updatedChildren.find((c) => c.id === feature.id);
        return alreadyUpdated ?? feature;
      }),
      idsAlreadyUpdated,
    );

    updatedChildren = [
      ...updated,
      ...updatedChildren.filter(
        (c) => !updated.map((u) => u.id).includes(c.id),
      ),
    ];
  }

  return newFeaturesSemanticConnected.concat(updatedChildren);
};

// Exported for testing
export function findAndUpdateChildrenRecursive(
  updatedFeature: ProjectFeature,
  projectFeatures: ProjectFeature[],
  idsToSkip: Set<string>,
): ProjectFeature[] {
  const directChildren = projectFeatures
    .filter((f) => isDependentOfFeature(updatedFeature.id, f, true))
    .filter((f) => !idsToSkip.has(f.id));
  const updatedChildren = directChildren.map((c) =>
    updateChildGivenParentChange(updatedFeature, c),
  );

  const projectFeaturesWithUpdatedChildren = projectFeatures.map((f) => {
    if (idsToSkip.has(f.id)) {
      return f;
    }
    const updatedChild = updatedChildren.find((c) => c?.id === f.id);
    return updatedChild ?? f;
  });
  return [
    ...updatedChildren.filter(isDefined),
    ...updatedChildren
      .filter(isDefined)
      .flatMap((c) =>
        findAndUpdateChildrenRecursive(
          c,
          projectFeaturesWithUpdatedChildren,
          idsToSkip,
        ),
      ),
  ];
}

const posDiffer = (a: Position, b: Position) => a.some((n, i) => n !== b[i]);
function updateChildGivenParentChange(
  parent: ProjectFeature,
  child: ProjectFeature,
): ProjectFeature | undefined {
  if (isExportCable(child)) {
    if (!isSubstation(parent)) {
      return undefined;
    }
    const parentPos = parent.geometry.coordinates;
    let newCoords = [...child.geometry.coordinates];
    if (child.properties.fromSubstationId === parent.id) {
      newCoords = [parentPos].concat(newCoords.slice(1));
    }

    if (child.properties.toSubstationId === parent.id) {
      newCoords = newCoords.slice(0, -1).concat([parentPos]);
    }

    return {
      ...child,
      geometry: {
        ...child.geometry,
        coordinates: newCoords,
      },
    };
  } else if (isCable(child)) {
    if (!isTurbine(parent) && !isSubstation(parent)) {
      return undefined;
    }
    const { fromId, toId } = child.properties;
    const from = parent.id === fromId;
    const to = parent.id === toId;
    if (!from && !to) {
      return undefined;
    }
    const parentPos = parent.geometry.coordinates;
    let newCoords = [...child.geometry.coordinates];

    if (from && posDiffer(child.geometry.coordinates.at(0)!, parentPos)) {
      newCoords = [parentPos, ...newCoords.slice(1)];
    }
    if (to && posDiffer(child.geometry.coordinates.at(-1)!, parentPos)) {
      newCoords = [...newCoords.slice(0, -1), parentPos];
    }
    return {
      ...child,
      geometry: {
        ...child.geometry,
        coordinates: newCoords,
      },
    };
  } else if (isMooringLine(child)) {
    if (!isAnchor(parent) && !isTurbine(parent)) {
      return undefined;
    }
    const { target, anchor } = child.properties;
    const parentIsAnchor = parent.id === anchor;
    const parentIsTarget = parent.id === target;
    if (!parentIsTarget && !parentIsAnchor) {
      return undefined;
    }
    const parentPos = parent.geometry.coordinates;
    let newCoords = [...child.geometry.coordinates];
    if (
      parentIsAnchor &&
      posDiffer(child.geometry.coordinates.at(0)!, parentPos)
    ) {
      newCoords = [parentPos, ...newCoords.slice(1)];
    }
    if (
      parentIsTarget &&
      posDiffer(child.geometry.coordinates.at(-1)!, parentPos)
    ) {
      newCoords = [...newCoords.slice(0, -1), parentPos];
    }
    return {
      ...child,
      geometry: {
        ...child.geometry,
        coordinates: newCoords,
      },
    };
  } else {
    return undefined;
  }
}
