import { useCallback } from "react";
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import {
  SubstationType,
  createSubstationType,
  deleteSubstationType,
  updateSubstationType,
} from "services/substationService";
import { substationTypeAtomFamily } from "state/substationType";
import { initializeAndSet } from "../components/Comments/hooks/useReplyReactionCrud";
import { savingConfigurationInProgressAtom } from "../state/configuration";
import { projectIdSelector } from "../state/pathParams";
import { scream } from "utils/sentry";

const useSubstationTypeCrud = () => {
  const projectId = useRecoilValue(projectIdSelector);
  const setIsAutoSaving = useSetRecoilState(savingConfigurationInProgressAtom);
  const setCurrentSubstationTypes = useSetRecoilState(
    substationTypeAtomFamily({ projectId }),
  );

  const create = useCallback(
    (substationType: Partial<SubstationType>): Promise<SubstationType> => {
      if (!projectId) throw scream("Missing project id");
      setIsAutoSaving(true);
      return createSubstationType(projectId, substationType)
        .then((sub) => {
          setCurrentSubstationTypes((cur) => [...cur, sub]);
          return sub;
        })
        .finally(() => setIsAutoSaving(false));
    },
    [projectId, setCurrentSubstationTypes, setIsAutoSaving],
  );

  const update = useCallback(
    (substationType: SubstationType) => {
      if (!projectId) throw scream("Missing project id");

      setIsAutoSaving(true);
      setCurrentSubstationTypes((cur) =>
        [...cur].map((l) =>
          l.id === substationType.id ? { ...l, ...substationType } : l,
        ),
      );
      return updateSubstationType(projectId, substationType).finally(() =>
        setIsAutoSaving(false),
      );
    },
    [projectId, setCurrentSubstationTypes, setIsAutoSaving],
  );

  const deleteSubstation = useCallback(
    (substationId: string) => {
      if (!projectId) throw scream("Missing project id");

      setIsAutoSaving(true);
      setCurrentSubstationTypes((cur) =>
        [...cur].filter((l) => l.id !== substationId),
      );
      return deleteSubstationType(projectId, substationId).finally(() =>
        setIsAutoSaving(false),
      );
    },
    [projectId, setCurrentSubstationTypes, setIsAutoSaving],
  );

  const updateLocal = useRecoilCallback(
    ({ set, snapshot }) =>
      (projectId: string, substationType: SubstationType) => {
        return initializeAndSet(
          snapshot,
          set,
          substationTypeAtomFamily({ projectId }),
          (cur) => {
            const isUpdate = cur.some((l) => l.id === substationType.id);
            return isUpdate
              ? [...cur].map((l) =>
                  l.id === substationType.id ? { ...l, ...substationType } : l,
                )
              : [...cur, substationType];
          },
        );
      },
    [],
  );

  return { create, update, deleteSubstation, updateLocal };
};

export default useSubstationTypeCrud;
