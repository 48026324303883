import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { spaceDecent, spaceSmall } from "styles/space";
import { ModalFrame } from "components/General/Layout";
import { currentOrganisationSelector } from "components/Organisation/state";
import {
  CreatedOrganisationInvitation,
  UserAlreadyExistedInvitation,
} from "hooks/useUser";
import { adminInOrganisationSelectorFamily } from "state/user";
import AfterInviteResult from "./AfterInviteResult";
import BeforeInvite from "./BeforeInvite";

const InviteToOrganisationFrame = ({ onClose }: { onClose(): void }) => {
  const organisation = useRecoilValue(currentOrganisationSelector);
  const isOrgAdmin = useRecoilValue(
    adminInOrganisationSelectorFamily({
      organisationId: organisation?.id ?? "",
    }),
  );
  const [inviteResult, setInviteResult] = useState<
    (UserAlreadyExistedInvitation | CreatedOrganisationInvitation)[]
  >([]);

  if (!organisation) {
    return null;
  }

  return (
    <div style={{ position: "relative" }}>
      <ModalFrame
        title="Invite to organisation"
        h2style={{ marginBottom: spaceDecent }}
        fullscreen={true}
        closeOnWrapperClick={false}
        style={{
          position: "absolute",
          right: 0,
          minHeight: "10rem",
          minWidth: "50rem",
          top: spaceSmall,
          overflow: "visible",
        }}
        onExit={onClose}
      >
        {inviteResult.length > 0 ? (
          <AfterInviteResult
            isOrgAdmin={isOrgAdmin}
            inviteResult={inviteResult}
            organisation={organisation!}
            onClose={onClose}
          />
        ) : (
          <BeforeInvite
            organisation={organisation}
            isOrgAdmin={isOrgAdmin}
            setInviteResult={setInviteResult}
          />
        )}
      </ModalFrame>
    </div>
  );
};

export default InviteToOrganisationFrame;
