import { lib, constants } from "@vind-ai/mapbox-gl-draw";

import { area, centroid } from "@turf/turf";
import { kmPerPixel, snapToClosest, SNAP_PIXELS } from "./lib/snapping";
import { currentSnapLines, currentSnapPoints } from "../useUpdateSnapPoints";
import { getHumanReadableArea, sqMeterToSqKm } from "utils/geometry";

const { doubleClickZoom, CommonSelectors, isEventAtCoordinates, createVertex } =
  lib;

const getArea = (feature) => area(feature);
const getCenter = (feature) => centroid(feature);

const DrawPolygonWithArea = {};

DrawPolygonWithArea.onSetup = function (event) {
  this._warnIfTooBig = event?.warnIfTooBig ?? true;

  const polygon = this.newFeature({
    type: constants.geojsonTypes.FEATURE,
    properties: {},
    geometry: {
      type: constants.geojsonTypes.POLYGON,
      coordinates: [[]],
    },
  });

  this.addFeature(polygon);

  this.clearSelectedFeatures();
  doubleClickZoom.disable(this);
  this.updateUIClasses({ mouse: constants.cursors.ADD });
  this.activateUIButton(constants.types.POLYGON);
  this.setActionableState({
    trash: true,
  });

  return {
    polygon,
    currentVertexPosition: 0,
  };
};

DrawPolygonWithArea.clickAnywhere = function (state, e) {
  if (
    state.currentVertexPosition > 0 &&
    isEventAtCoordinates(
      e,
      state.polygon.coordinates[0][state.currentVertexPosition - 1],
    )
  ) {
    return this.changeMode(constants.modes.SIMPLE_SELECT, {
      featureIds: [state.polygon.id],
    });
  }

  const currentPoint = [e.lngLat.lng, e.lngLat.lat];
  const dynamicSnapDistance =
    SNAP_PIXELS * kmPerPixel(e.lngLat.lat, this.map.getZoom());

  const snappingDisabled = CommonSelectors.isShiftDown(e);
  const pointToUse = snappingDisabled
    ? currentPoint
    : snapToClosest(
        currentSnapPoints,
        currentSnapLines,
        currentPoint,
        dynamicSnapDistance,
      );

  this.updateUIClasses({ mouse: constants.cursors.ADD });
  state.polygon.updateCoordinate(
    `0.${state.currentVertexPosition}`,
    ...pointToUse,
  );
  state.currentVertexPosition++;
  state.polygon.updateCoordinate(
    `0.${state.currentVertexPosition}`,
    ...pointToUse,
  );
};

DrawPolygonWithArea.clickOnVertex = function (state) {
  return this.changeMode(constants.modes.SIMPLE_SELECT, {
    featureIds: [state.polygon.id],
  });
};

DrawPolygonWithArea.onMouseMove = function (state, e) {
  const currentPoint = [e.lngLat.lng, e.lngLat.lat];
  const dynamicSnapDistance =
    SNAP_PIXELS * kmPerPixel(e.lngLat.lat, this.map.getZoom());
  const snappingDisabled = CommonSelectors.isShiftDown(e);
  const pointToUse = snappingDisabled
    ? currentPoint
    : snapToClosest(
        currentSnapPoints,
        currentSnapLines,
        currentPoint,
        dynamicSnapDistance,
      );

  state.polygon.updateCoordinate(
    `0.${state.currentVertexPosition}`,
    ...pointToUse,
  );
  if (CommonSelectors.isVertex(e)) {
    this.updateUIClasses({ mouse: constants.cursors.POINTER });
  }
};

DrawPolygonWithArea.onTap = DrawPolygonWithArea.onClick = function (state, e) {
  if (CommonSelectors.isVertex(e)) return this.clickOnVertex(state, e);
  return this.clickAnywhere(state, e);
};

DrawPolygonWithArea.onKeyUp = function (state, e) {
  if (CommonSelectors.isEscapeKey(e)) {
    this.deleteFeature([state.polygon.id], { silent: true });
    this.changeMode(constants.modes.SIMPLE_SELECT);
  } else if (CommonSelectors.isEnterKey(e)) {
    this.changeMode(constants.modes.SIMPLE_SELECT, {
      featureIds: [state.polygon.id],
    });
  }
};

DrawPolygonWithArea.onStop = function (state) {
  this.updateUIClasses({ mouse: constants.cursors.NONE });
  doubleClickZoom.enable(this);
  this.activateUIButton();

  // check to see if we've deleted this feature
  if (this.getFeature(state.polygon.id) === undefined) return;

  //remove last added coordinate
  state.polygon.removeCoordinate(`0.${state.currentVertexPosition}`);
  if (state.polygon.isValid()) {
    this.map.fire(constants.events.CREATE, {
      features: [state.polygon.toGeoJSON()],
    });
  } else {
    this.deleteFeature([state.polygon.id], { silent: true });
    this.changeMode(constants.modes.SIMPLE_SELECT, {}, { silent: true });
  }
};

DrawPolygonWithArea.toDisplayFeatures = function (state, geojson, display) {
  const isActivePolygon = geojson.properties.id === state.polygon.id;
  geojson.properties.active = isActivePolygon
    ? constants.activeStates.ACTIVE
    : constants.activeStates.INACTIVE;
  if (!isActivePolygon) return display(geojson);

  // Don't render a polygon until it has two positions
  // (and a 3rd which is just the first repeated)
  if (geojson.geometry.coordinates.length === 0) return;

  const coordinateCount = geojson.geometry.coordinates[0].length;
  // 2 coordinates after selecting a draw type
  // 3 after creating the first point
  if (coordinateCount < 3) {
    return;
  }
  geojson.properties.meta = constants.meta.FEATURE;
  display(
    createVertex(
      state.polygon.id,
      geojson.geometry.coordinates[0][0],
      "0.0",
      false,
    ),
  );
  if (coordinateCount > 3) {
    // Add a start position marker to the map, clicking on this will finish the feature
    // This should only be shown when we're in a valid spot
    const endPos = geojson.geometry.coordinates[0].length - 3;
    display(
      createVertex(
        state.polygon.id,
        geojson.geometry.coordinates[0][endPos],
        `0.${endPos}`,
        false,
      ),
    );
  }

  if (coordinateCount <= 4) {
    // If we've only drawn two positions (plus the closer),
    // make a LineString instead of a Polygon
    const lineCoordinates = [
      [
        geojson.geometry.coordinates[0][0][0],
        geojson.geometry.coordinates[0][0][1],
      ],
      [
        geojson.geometry.coordinates[0][1][0],
        geojson.geometry.coordinates[0][1][1],
      ],
    ];
    // create an initial vertex so that we can track the first point on mobile devices
    display({
      type: constants.geojsonTypes.FEATURE,
      properties: geojson.properties,
      geometry: {
        coordinates: lineCoordinates,
        type: constants.geojsonTypes.LINE_STRING,
      },
    });
    if (coordinateCount === 3) {
      return;
    }
  }

  function calculateOffset(zoom) {
    const baseOffset = 0.000005; // Base offset at zoom level 0
    const maxZoom = 22; // Maximum zoom level in Mapbox
    return baseOffset * Math.pow(2, maxZoom - zoom); // Increase the offset exponentially as the zoom level decreases
  }

  const area = getArea(state.polygon);
  const center = getCenter({
    ...state.polygon,
    coordinates: geojson.geometry.coordinates,
  }).geometry.coordinates;
  const areaIstooBig = sqMeterToSqKm(area) > 1400;
  const zoom = this.map.getZoom();
  const offset = calculateOffset(zoom);
  const centerForMessage = [center[0], center[1] - offset];
  const messageTooBig = `The maximum area for generating turbine layout is 1400 km\u00b2`;

  const centerPointWithArea = {
    type: "Feature",
    properties: {
      ...geojson.properties,
      showInfo: true,
      area: getHumanReadableArea(area),
    },
    geometry: {
      type: "Point",
      coordinates: center,
    },
  };

  const centerPointWithBigArea = {
    type: "Feature",
    properties: {
      ...geojson.properties,
      showInfo: true,
      bigArea: `${sqMeterToSqKm(area).toFixed(1)}km\u00b2`,
    },
    geometry: {
      type: "Point",
      coordinates: center,
    },
  };

  const areaTooBigMessage = {
    type: "Feature",
    properties: {
      ...geojson.properties,
      showInfo: true,
      message: messageTooBig,
    },
    geometry: {
      type: "Point",
      coordinates: centerForMessage,
    },
  };

  if (this._warnIfTooBig && areaIstooBig) {
    display(centerPointWithBigArea);
    display(areaTooBigMessage);
  } else {
    display(centerPointWithArea);
  }

  // render the Polygon
  return display(geojson);
};

DrawPolygonWithArea.onTrash = function (state) {
  this.deleteFeature([state.polygon.id], { silent: true });
  this.changeMode(constants.modes.SIMPLE_SELECT);
};

export default DrawPolygonWithArea;
