import { useRecoilValue, useSetRecoilState } from "recoil";
import { validationWarningsFromParkSelectorFamily } from "state/validationWarnings";
import styled from "styled-components";
import { colors } from "styles/colors";
import { ShowWarningBoxAtom } from "./ValidationWarnings";
import Tooltip from "components/General/Tooltip";
import { modalTypeOpenAtom } from "state/modal";
import useNavigateToPark from "hooks/useNavigateToPark";
import { getParkFeatureInBranchSelector } from "state/park";
import { currentSelectionArrayAtom } from "state/selection";
import { mapRefAtom } from "state/map";
import { useGoToFeatures } from "hooks/map";
import {
  ValidationWarningTypes,
  ValidationSeverity,
  validationWarningSeverity,
} from "./utils";

const WarningWrapper = styled.div<{ type?: ValidationSeverity }>`
  border-radius: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  color: white;
  background-color: ${(p) =>
    p.type === ValidationSeverity.Warning
      ? colors.yellow
      : p.type === ValidationSeverity.Error
        ? colors.redAlert
        : colors.secondaryText};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  margin-left: 0.5rem;
`;

const foundationStatSpecificErrors = [
  ValidationWarningTypes.FoundationTypeInvalid,
  ValidationWarningTypes.TurbineAndMonopileTypeError,
  ValidationWarningTypes.OverlappingParks,
  ValidationWarningTypes.ProjectSize,
  ValidationWarningTypes.TurbineOutsidePark,
  ValidationWarningTypes.FatalGeneral,
  ValidationWarningTypes.FatalAblyLost,
];

const productionSpecificErrors = [
  ValidationWarningTypes.TurbineOverlap,
  ValidationWarningTypes.ExistingTurbineOverlap,
  ValidationWarningTypes.TurbineTypeInvalid,
  ValidationWarningTypes.OverlappingParks,
  ValidationWarningTypes.HoleInPark,
  ValidationWarningTypes.FatalGeneral,
  ValidationWarningTypes.OffshoreSubstationOutsideParkCableCorridor,
  ValidationWarningTypes.TurbineNotConnected,
  ValidationWarningTypes.SubstationNotConnected,
  ValidationWarningTypes.ProjectSize,
  ValidationWarningTypes.TurbineOutsidePark,
  ValidationWarningTypes.FatalAblyLost,
];

const electricalStatSpecificErrors = [
  ValidationWarningTypes.CableTypeInvalid,
  ValidationWarningTypes.CablesDifferentIAVoltageTypes,
  ValidationWarningTypes.CableDifferentExportVoltageTypes,
  ValidationWarningTypes.TurbinesAndCablesErroneousIAVoltageTypes,
  ValidationWarningTypes.TooShortCables,
  ValidationWarningTypes.OffshoreSubstationOutsideParkCableCorridor,
  ValidationWarningTypes.ExportCableLandfallFailed,
  ValidationWarningTypes.SubstationMissingType,
  ValidationWarningTypes.ExportCableNotConnected,
  ValidationWarningTypes.CableCorridorNotConnectedToPark,
  ValidationWarningTypes.SubstationOverlap,
  ValidationWarningTypes.SubstationInsideNoSubstationExclusionZone,
  ValidationWarningTypes.SubstationInsideNoCableExclusionZone,
  ValidationWarningTypes.TurbinesNotReachable,
  ValidationWarningTypes.ExportSystemInsufficientCapacity,
];

const notCostSpecificErrors = [
  ValidationWarningTypes.OverlappingSubAreas,
  ValidationWarningTypes.SubAreasOutsidePark,
  ValidationWarningTypes.AnchorsOutsidePark,
];

const WarningBox = ({
  parkId,
  branchId,
  highestSeverity,
}: {
  parkId: string;
  branchId: string;
  highestSeverity?: ValidationSeverity;
}) => {
  const map = useRecoilValue(mapRefAtom);
  const goToFeatures = useGoToFeatures(map);
  const setShowBox = useSetRecoilState(ShowWarningBoxAtom);
  const park = useRecoilValue(
    getParkFeatureInBranchSelector({ parkId, branchId }),
  );
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const setCurrentSelectionArray = useSetRecoilState(currentSelectionArrayAtom);

  const { navigateToPark } = useNavigateToPark();

  return (
    <Tooltip
      text={
        "Validation errors can give incorrect results. Click here to view the park and get more details."
      }
    >
      <WarningWrapper
        type={highestSeverity}
        onClick={(e) => {
          e.preventDefault();
          setModalTypeOpen(undefined);
          navigateToPark(parkId, branchId).then(() => {
            setCurrentSelectionArray([parkId]);
          });
          setShowBox(true);
          if (!park) return;
          goToFeatures([park]);
        }}
      >
        <div>!</div>
      </WarningWrapper>
    </Tooltip>
  );
};

export const ProductionError = ({
  parkId,
  branchId,
}: {
  parkId: string;
  branchId: string;
}) => {
  const allParkWarnings = useRecoilValue(
    validationWarningsFromParkSelectorFamily({
      parkId: parkId,
      branchId: branchId,
    }),
  );

  const productionWarnings = allParkWarnings.filter((vw) =>
    productionSpecificErrors.includes(vw!.type),
  );

  if (productionWarnings.length === 0) return null;

  const highestSeverity = productionWarnings
    .map((w) => validationWarningSeverity[w!.type])
    .reduce((a, e) => Math.max(a, e), 0 as ValidationSeverity);

  return (
    <WarningBox
      parkId={parkId}
      branchId={branchId}
      highestSeverity={highestSeverity}
    />
  );
};

export const FoundationStatError = ({
  parkId,
  branchId,
}: {
  parkId: string;
  branchId: string;
}) => {
  const allParkWarnings = useRecoilValue(
    validationWarningsFromParkSelectorFamily({
      parkId: parkId,
      branchId: branchId,
    }),
  );

  const foundationWarnings = allParkWarnings.filter((vw) =>
    foundationStatSpecificErrors.includes(vw!.type),
  );

  if (foundationWarnings.length === 0) return null;

  const highestSeverity = foundationWarnings
    .map((w) => validationWarningSeverity[w!.type])
    .reduce((a, e) => Math.max(a, e), 0 as ValidationSeverity);

  return (
    <WarningBox
      parkId={parkId}
      branchId={branchId}
      highestSeverity={highestSeverity}
    />
  );
};

export const ElectricalStatError = ({
  parkId,
  branchId,
}: {
  parkId: string;
  branchId: string;
}) => {
  const allParkWarnings = useRecoilValue(
    validationWarningsFromParkSelectorFamily({
      parkId: parkId,
      branchId: branchId,
    }),
  );
  const electricalWarnings = allParkWarnings.filter(
    (vw) =>
      electricalStatSpecificErrors.includes(vw!.type) ||
      productionSpecificErrors.includes(vw!.type),
  );

  if (electricalWarnings.length === 0) return null;

  const highestSeverity = electricalWarnings
    .map((w) => validationWarningSeverity[w!.type])
    .reduce((a, e) => Math.max(a, e), 0 as ValidationSeverity);

  return (
    <WarningBox
      parkId={parkId}
      branchId={branchId}
      highestSeverity={highestSeverity}
    />
  );
};

export const ParkHasAnyValidationError = ({
  parkId,
  branchId,
}: {
  parkId: string;
  branchId: string;
}) => {
  const allParkWarnings = useRecoilValue(
    validationWarningsFromParkSelectorFamily({
      parkId: parkId,
      branchId: branchId,
    }),
  );
  const costWarnings = allParkWarnings.filter(
    (vw) => !notCostSpecificErrors.includes(vw!.type),
  );

  if (costWarnings.length === 0) return null;

  const highestSeverity = costWarnings
    .map((w) => validationWarningSeverity[w!.type])
    .reduce((a, e) => Math.max(a, e), 0 as ValidationSeverity);

  return (
    <WarningBox
      parkId={parkId}
      branchId={branchId}
      highestSeverity={highestSeverity}
    />
  );
};
export const SubstationInsideNoCableExclusionZonesValidationError = ({
  parkId,
  branchId,
}: {
  parkId: string;
  branchId: string;
}) => {
  const allParkWarnings = useRecoilValue(
    validationWarningsFromParkSelectorFamily({
      parkId: parkId,
      branchId: branchId,
    }),
  );
  const substationInsideNoCableExclusionZoneWarning = allParkWarnings.some(
    (vw) =>
      vw.type === ValidationWarningTypes.SubstationInsideNoCableExclusionZone,
  );

  if (!substationInsideNoCableExclusionZoneWarning) return null;

  return (
    <WarningBox
      parkId={parkId}
      branchId={branchId}
      highestSeverity={
        validationWarningSeverity.substation_inside_no_cable_exclusion_zone
      }
    />
  );
};
