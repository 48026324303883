import { useRecoilValueLoadable2 } from "utils/recoil";
import {
  FinanceId,
  Inputs,
  DEVEX,
  CAPEX_STATE,
  OPEX,
  DECOM,
  IRRState,
  GuaranteedRevenues,
  MarketRevenues,
} from "./state";
import { selectorFamily } from "recoil";
import { CostUnit } from "types/financial";
import { roundToDecimal } from "utils/utils";

const adjustment = "inflated";

export const useIRR = (id: FinanceId) => {
  const irr = useRecoilValueLoadable2(IRRState.getIRR(id));
  const totalRevenue = useRecoilValueLoadable2(
    IRRState.getTotalRevenueMillion(id),
  );

  const totalGuaranteedRevenues = useRecoilValueLoadable2(
    GuaranteedRevenues.getTotalCostMillion({ id, adjustment }),
  );
  const guaranteedRevenuesYears = useRecoilValueLoadable2(
    GuaranteedRevenues.getYears(id),
  );
  const totalMarketRevenues = useRecoilValueLoadable2(
    MarketRevenues.getTotalCostMillion({ id, adjustment }),
  );
  const marketRevenuesYears = useRecoilValueLoadable2(
    MarketRevenues.getYears(id),
  );

  const totalCost = useRecoilValueLoadable2(IRRState.getTotalCostMillion(id));
  const totalCostCapex = useRecoilValueLoadable2(
    CAPEX_STATE.getTotalCostMillion({ id, adjustment }),
  );
  const totalCostOpex = useRecoilValueLoadable2(
    OPEX.getTotalCostMillion({ id, adjustment }),
  );
  const totalCostDevex = useRecoilValueLoadable2(
    DEVEX.getTotalCostMillion({ id, adjustment }),
  );
  const totalCostDecom = useRecoilValueLoadable2(
    DECOM.getTotalCostMillion({ id, adjustment }),
  );

  const projectStart = useRecoilValueLoadable2(Inputs.getProjectStart(id));
  const operationStart = useRecoilValueLoadable2(Inputs.getOperationStart(id));

  const exportData = useRecoilValueLoadable2(getExportData(id));

  return {
    inputs: {
      projectStart,
      operationStart,
    },
    results: {
      irr,
      exportData,
      revenues: {
        total: totalRevenue,
        guaranteed: {
          total: totalGuaranteedRevenues,
          years: guaranteedRevenuesYears,
        },
        market: {
          total: totalMarketRevenues,
          years: marketRevenuesYears,
        },
      },
      costs: {
        total: totalCost,
        capex: totalCostCapex,
        opex: totalCostOpex,
        devex: totalCostDevex,
        decom: totalCostDecom,
      },
    },
  };
};

const getExportData = selectorFamily<
  {
    type: string;
    value: string;
    unit: string;
  }[],
  FinanceId
>({
  key: "irr-export-data",
  get:
    (id: FinanceId) =>
    ({ get }) => {
      const irr = get(IRRState.getIRR(id));

      const guaranteedYears = get(GuaranteedRevenues.getYears(id));
      const marketYears = get(MarketRevenues.getYears(id));

      const guaranteedTotal = get(
        GuaranteedRevenues.getTotalCostMillion({ id, adjustment }),
      );
      const marketTotal = get(
        MarketRevenues.getTotalCostMillion({ id, adjustment }),
      );

      const devex = get(DEVEX.getTotalCostMillion({ id, adjustment }));
      const capex = get(CAPEX_STATE.getTotalCostMillion({ id, adjustment }));
      const opex = get(OPEX.getTotalCostMillion({ id, adjustment }));
      const decom = get(DECOM.getTotalCostMillion({ id, adjustment }));

      return [
        {
          type: `Revenues ${guaranteedYears?.startYear} - ${guaranteedYears?.endYear}`,
          value: `${guaranteedTotal}`,
          unit: CostUnit.millionEuro,
        },
        {
          type: `Revenues ${marketYears?.startYear} - ${marketYears?.endYear}`,
          value: `${marketTotal}`,
          unit: CostUnit.millionEuro,
        },
        { type: "DEVEX", value: `${devex}`, unit: CostUnit.millionEuro },
        { type: "CAPEX", value: `${capex}`, unit: CostUnit.millionEuro },
        { type: "OPEX", value: `${opex}`, unit: CostUnit.millionEuro },
        {
          type: "DECOM",
          value: `${decom}`,
          unit: CostUnit.millionEuro,
        },
        {
          type: "IRR",
          value: "ok" in irr ? `${roundToDecimal(irr.ok * 100, 2)}` : "-",
          unit: "%",
        },
      ];
    },
});
