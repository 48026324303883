import React from "react";

const WindowContext = React.createContext<{
  context: Window | undefined;
}>({
  context: window,
});

export const useWindowContext = () => {
  const context = React.useContext(WindowContext);
  if (!context) {
    throw new Error("WindowContext not set");
  }

  return context;
};

export const WindowContextProvider = ({
  context,
  children,
}: React.PropsWithChildren<{ context: Window | undefined }>) => {
  return (
    <WindowContext.Provider value={{ context }}>
      {children}
    </WindowContext.Provider>
  );
};
