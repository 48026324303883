import { fetchEnhancerWithToken, fetchSchemaWithToken } from "services/utils";
import {
  NewLayersLibraryPackage,
  _DataLibraryLayer,
  _DataLibraryPackage,
  _ProjectUsage,
  _ProjectUsages,
} from "./types";

export const getOrganisationCustomLayerResources = async (
  organisationId: string,
) => {
  return fetchSchemaWithToken(
    _DataLibraryLayer.array(),
    `/api/gis-data/custom-external-layers/organisation/${organisationId}`,
    {
      method: "GET",
    },
  );
};

export const deleteOrganisationCustomLayerResource = async (
  organisationId: string,
  fileId: string,
) => {
  try {
    return fetchEnhancerWithToken(
      `/api/upload-custom-layer/delete-custom-layer/organisation/${organisationId}/${fileId}`,
      {
        method: "DELETE",
      },
    );
  } catch (err) {
    console.log({ err });
    throw err;
  }
};

export const listOrganisationLibraryPackages = async (
  organisationId: string,
) => {
  return fetchSchemaWithToken(
    _DataLibraryPackage.array(),
    `/api/datalayerlibrary/${organisationId}/packages`,
    {
      method: "GET",
    },
  );
};

export const newOrganisationLibraryPackage = async (
  organisationId: string,
  newPackage: NewLayersLibraryPackage,
) => {
  return fetchSchemaWithToken(
    _DataLibraryPackage,
    `/api/datalayerlibrary/${organisationId}/packages`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(newPackage),
    },
  );
};

export const updateOrganisationLibraryPackage = async (
  organisationId: string,
  packageId: string,
  newPackage: NewLayersLibraryPackage,
) => {
  return fetchSchemaWithToken(
    _DataLibraryPackage,
    `/api/datalayerlibrary/${organisationId}/packages/${packageId}`,
    {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(newPackage),
    },
  );
};

export const deleteOrganisationLibraryPackage = async (
  organisationId: string,
  packageId: string,
) => {
  return fetchEnhancerWithToken(
    `/api/datalayerlibrary/${organisationId}/packages/${packageId}`,
    {
      method: "DELETE",
    },
  );
};

export const updateOrganisationLibraryPackageLayerIds = async (
  organisationId: string,
  packageId: string,
  layerIds: string[],
) => {
  return fetchEnhancerWithToken(
    `/api/datalayerlibrary/${organisationId}/packages/${packageId}`,
    {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ layerIds }),
    },
  );
};

export const dataLayersUsage = async (nodeId: string, layerIds: string[]) => {
  return fetchSchemaWithToken(
    _ProjectUsages,
    `/api/library/usage/datalayer/node/${nodeId}/layer`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(layerIds),
    },
  );
};

export const dataPackageUsage = async (
  organisationId: string,
  packageId: string,
) => {
  return fetchSchemaWithToken(
    _ProjectUsages,
    `/api/library/usage/datalayer/organisation/${organisationId}/package/${packageId}`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    },
  );
};
