/// <reference types="vite-plugin-svgr/client" />
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { v4 as uuid } from "uuid";
import { useToast } from "../../../../hooks/useToast";
import CostIcon from "@icons/24/Cost.svg?react";
import DeleteIcon from "@icons/24/Bin.svg?react";
import { savingConfigurationInProgressAtom } from "../../../../state/configuration";
import { useTypedPath } from "../../../../state/pathParams";
import { scream } from "../../../../utils/sentry";
import {
  CostSettingsForLibraryConfig,
  CostSettingsInner,
} from "../../../ConfigurationModal/CostSettings";
import { selectedMenuItemState } from "../../Shared/state";
import {
  costConfigurationTempName,
  costConfigurationsAtomFamily,
  libraryFinancialConfigOnNodeTypesState,
} from "../../../../state/costConfigurations";
import {
  CostConfiguration,
  createConfiguration,
  deleteConfiguration,
  fetchCostConfigurationUsage,
  _CostConfigurationInput,
} from "../../../../services/costService";
import useCostConfigurationCrud from "../../../../hooks/useCostConfigurationCrud";
import { getDefaultCostConfig } from "../../../ConfigurationModal/Cost/state";
import { modalTypeOpenAtom } from "../../../../state/modal";
import { SettingsSubMenuProp } from "components/SettingsV2/Shared/types";
import { IconREMSize } from "styles/typography";
import Library from "@icons/24/Library.svg?react";
import Earth from "@icons/14/Earth.svg?react";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";
import AddIcon from "@icons/24/Add.svg?react";
import {
  financialResourceWithAccessOnNodeState,
  libraryTabState,
} from "components/Organisation/Library/state";
import {
  editorAccessProjectSelector,
  orgFinanicalManageAccessSelector,
} from "state/user";
import { selectedOrgTabState } from "components/Organisation/state";
import { useDuplicateFinancialConfigToProject } from "components/SettingsV2/FeatureSettings/Data/useDuplicateToProject";
import { useDuplicateFinancialToLibrary } from "components/SettingsV2/FeatureSettings/Data/useDuplicateToLibrary";
import { useLocation, useNavigate } from "react-router-dom";
import useNodeFinancialResourcesCrud from "components/Organisation/OrganisationRightSide/content/NodeContent/useNodeFinancialResourcesCrud";
import Button from "components/General/Button";
import { Anchor } from "components/General/Anchor";
import FinancialModal from "components/Organisation/OrganisationRightSide/content/ResourceContent/FinancialModal";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";

export const COST_MENU_ID = "cost";

export default function useCostConfiguration() {
  const { organisationId, projectId } = useTypedPath(
    "organisationId",
    "projectId",
  );
  const modalTypeOpen = useRecoilValue(modalTypeOpenAtom);
  const { error: showErrorToast } = useToast();
  const projectConfigurations = useRecoilValue(
    costConfigurationsAtomFamily({ projectId }),
  );
  const libraryConfigurations = useRecoilValue(
    libraryFinancialConfigOnNodeTypesState({ nodeId: projectId }),
  );
  const [isAutoSaving, setIsAutoSaving] = useRecoilState(
    savingConfigurationInProgressAtom,
  );

  const [menuSelection, setMenuSelection] = useRecoilState(
    selectedMenuItemState({ menuId: COST_MENU_ID, projectId }),
  );
  const orgFinancialManageAccess = useRecoilValue(
    orgFinanicalManageAccessSelector,
  );
  const editorAccessProject = useRecoilValue(editorAccessProjectSelector);
  const {
    save: saveProjectConfig,
    saveName: saveProjectConfigName,
    updateLocal,
    deleteLocal,
    saveTempNameToLocal,
  } = useCostConfigurationCrud();

  const setOrgtab = useSetRecoilState(selectedOrgTabState);
  const setSelectedTab = useSetRecoilState(libraryTabState({ organisationId }));
  const setContent = useSetRecoilState(
    organisationRightSideModal(organisationId),
  );

  const tempConfigNames = useRecoilValue(
    costConfigurationTempName({ nodeId: projectId }),
  );

  const navigate = useNavigate();
  const location = useLocation();
  const { duplicateToProject } = useDuplicateFinancialConfigToProject();
  const { duplicateToLibrary } = useDuplicateFinancialToLibrary();

  useEffect(() => {
    if (!menuSelection) {
      const firstItem = projectConfigurations[0];
      if (firstItem) {
        setMenuSelection(firstItem.id);
      }
    }
  }, [projectConfigurations, menuSelection, setMenuSelection]);

  useEffect(() => {
    if (
      (modalTypeOpen as any)?.["metadata"]?.["selectedMenuId"] === "cost" &&
      (modalTypeOpen as any)?.["metadata"]?.["selectedConfigId"]
    ) {
      setMenuSelection((modalTypeOpen as any)["metadata"]["selectedConfigId"]);
    }
  }, [modalTypeOpen, setMenuSelection]);

  const _create = useCallback(async () => {
    if (!projectId) return;
    setIsAutoSaving(true);

    const config = getDefaultCostConfig({ id: false });
    const newConfig = await createConfiguration(projectId, config);

    updateLocal(newConfig);
    setMenuSelection(newConfig.id);
    setIsAutoSaving(false);
  }, [projectId, setIsAutoSaving, setMenuSelection, updateLocal]);

  const _delete = useCallback(
    async (id: string) => {
      if (!projectId) return;
      setIsAutoSaving(true);
      const usage = await fetchCostConfigurationUsage(projectId, id);
      if (usage.length > 0) {
        showErrorToast(
          `Configuration is used in ${usage.length} branch(es) and can't be deleted`,
          {
            timeout: 6000,
          },
        );
      } else {
        try {
          const res = await deleteConfiguration(projectId, id);
          if ([204, 409].includes(res.status)) {
            deleteLocal(id);
          } else {
            const body = await res.text();
            throw new Error(body);
          }
        } catch (error) {
          if (error instanceof Error)
            showErrorToast(error.message, {
              timeout: 4000,
            });
        }
      }
      setIsAutoSaving(false);
    },
    [projectId, deleteLocal, setIsAutoSaving, showErrorToast],
  );

  const _duplicate = useCallback(
    async (config: CostConfiguration) => {
      if (!projectId) return;
      const clonedConfig = _CostConfigurationInput.parse({
        ...config,
        name: `${config.name} copy`,
        capex: {
          ...config.capex,
          custom: config.capex.custom.map((entry) => ({
            ...entry,
            id: uuid(),
          })),
        },
        opex: {
          ...config.opex,
          custom: config.opex.custom.map((entry) => ({
            ...entry,
            id: uuid(),
          })),
        },
      });

      setIsAutoSaving(true);
      try {
        const newConfig = await createConfiguration(projectId, clonedConfig);

        updateLocal(newConfig);
        setMenuSelection(newConfig.id);
      } catch (error) {
        if (error instanceof Error)
          scream(error, {
            config: clonedConfig,
          });

        showErrorToast("Something went wrong when duplicating config", {
          timeout: 4000,
        });
      } finally {
        setIsAutoSaving(false);
      }
    },
    [projectId, setIsAutoSaving, setMenuSelection, showErrorToast, updateLocal],
  );

  const onUnmount = useCallback(() => {
    projectConfigurations.forEach((c) => {
      if (saveTempNameToLocal) {
        saveTempNameToLocal(c.id);
      }
    });
  }, [projectConfigurations, saveTempNameToLocal]);

  const configSubMenus: SettingsSubMenuProp[] = useMemo(() => {
    const projectConfigurationList = projectConfigurations.map((c) => {
      let dotMenuOptions:
        | {
            title: string;
            onSelect: (param: any) => void;
            icon: ReactElement;
          }[]
        | undefined = [];

      if (editorAccessProject) {
        dotMenuOptions.push({
          title: "Delete",
          onSelect: _delete,
          icon: <DeleteIcon />,
        });
        dotMenuOptions.push({
          title: "Duplicate",
          onSelect: () => _duplicate(c),
          icon: <DuplicateIcon />,
        });
      }
      if (orgFinancialManageAccess) {
        dotMenuOptions.push({
          title: "Duplicate to library",
          onSelect: () => duplicateToLibrary(projectId, c),
          icon: <DuplicateIcon />,
        });
      }
      if (dotMenuOptions.length === 0) dotMenuOptions = undefined;
      const configName = tempConfigNames[c.id] ?? c.name;
      return {
        id: c.id,
        label: configName ?? "Default",
        loading: isAutoSaving,
        content: (
          <div style={{ height: "100%", position: "relative" }}>
            <CostSettingsInner
              configuration={c}
              configurationName={configName}
              onUnmount={onUnmount}
              save={saveProjectConfig}
              saveName={saveProjectConfigName}
              readOnly={!editorAccessProject}
            />
          </div>
        ),
        onDuplicate: () => _duplicate(c),
        onChangeName: async (newName: string) => {
          setIsAutoSaving(true);
          try {
            await saveProjectConfig({ ...c, name: newName });
          } catch (err) {
            showErrorToast("Could not save configuration, please try again", {
              timeout: 4000,
            });
          } finally {
            setIsAutoSaving(false);
          }
        },
        dotMenuOptions,
      };
    });

    const libraryConfigurationList = libraryConfigurations.map((c) => {
      let dotMenuOptions:
        | {
            title: string;
            onSelect: (id: string) => void;
            icon: ReactElement;
          }[]
        | undefined = [];

      if (orgFinancialManageAccess) {
        dotMenuOptions.push({
          title: "Edit in library",
          onSelect: () => {
            setOrgtab("Library");
            setSelectedTab("financial");
            setContent({ type: "resource", id: c.id });
            navigate(`/organisation/${organisationId}${location.search}`);
          },
          icon: <Library />,
        });
      }
      if (editorAccessProject) {
        dotMenuOptions.push({
          title: "Duplicate to project",
          onSelect: () => duplicateToProject(c),
          icon: <DuplicateIcon />,
        });
      }
      if (dotMenuOptions.length === 0) dotMenuOptions = undefined;

      return {
        id: c.id,
        label: c.name ?? "Default configuration",
        loading: isAutoSaving,
        content: (
          <div style={{ height: "100%", position: "relative" }}>
            <CostSettingsForLibraryConfig configuration={c} />
          </div>
        ),
        onChangeName: () => {},
        dotMenuOptions,
      };
    });

    const libraryFinancialMenu: SettingsSubMenuProp = {
      title: "Library financial",
      items: libraryConfigurationList,
      create: {
        type: "element",
        element: <AddFinancialResourceToNode nodeId={projectId} />,
      },
      icon: (
        <IconREMSize height={1.4} width={1.4}>
          <Library />
        </IconREMSize>
      ),
    };

    const projectFinancialMenu: SettingsSubMenuProp = {
      title: "Project financial",
      items: projectConfigurationList,
      create: {
        title: "New configuration",
        onCreate: _create,
        disabled: isAutoSaving,
        type: "action",
      },
      icon: (
        <IconREMSize height={1.4} width={1.4}>
          <Earth />
        </IconREMSize>
      ),
    };

    return [libraryFinancialMenu, projectFinancialMenu];
  }, [
    projectConfigurations,
    libraryConfigurations,
    projectId,
    _create,
    isAutoSaving,
    editorAccessProject,
    orgFinancialManageAccess,
    saveProjectConfig,
    _delete,
    _duplicate,
    duplicateToLibrary,
    setIsAutoSaving,
    showErrorToast,
    setOrgtab,
    setSelectedTab,
    setContent,
    navigate,
    organisationId,
    location.search,
    duplicateToProject,
    saveProjectConfigName,
    tempConfigNames,
    onUnmount,
  ]);

  const costConfiguration = useMemo(() => {
    return {
      id: COST_MENU_ID,
      label: "Financial",
      icon: <CostIcon />,
      submenus: configSubMenus,
    };
  }, [configSubMenus]);

  return costConfiguration;
}

function AddFinancialResourceToNode({ nodeId }: { nodeId: string }) {
  const financialResources = useRecoilValue(
    financialResourceWithAccessOnNodeState({ nodeId }),
  );
  const ref = useRef<HTMLDivElement>(null);
  const { addOrUpdate: addOrUpdateConfig } = useNodeFinancialResourcesCrud();
  const [showAddCable, setShowAddCable] = useState(false);
  const orgFinancialManageAccess = useRecoilValue(
    orgFinanicalManageAccessSelector,
  );

  if (!orgFinancialManageAccess) return <></>;

  return (
    <div ref={ref} style={{ position: "relative", marginLeft: "auto" }}>
      <Button
        buttonType="secondary"
        onClick={() => setShowAddCable(true)}
        icon={<AddIcon />}
        size="small"
      />
      {showAddCable && (
        <Anchor baseRef={ref} basePlace={"topRight"} floatPlace={"topLeft"}>
          <FinancialModal
            onSave={async (config) => {
              await Promise.all(
                config.map((c) => addOrUpdateConfig(nodeId, c)),
              );

              setShowAddCable(false);
            }}
            existingFinancialConfig={financialResources.map((c) => c.config.id)}
          />
        </Anchor>
      )}
    </div>
  );
}
