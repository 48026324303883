import { useRecoilValue } from "recoil";
import { CenterContainer, SafeCard } from "./Base";
import {
  TurbineFeatureWithFoundation,
  allFloaterTypesSelector,
  hasFixedFoundationSelector,
  hasFloatingFoundationSelector,
  isTurbineFeatureWithFoundation,
  turbineTypeAndFloatingFoundationCombinations,
  getScalesForTurbineTypeIdsAndFoundationIds,
} from "../../../state/foundations";
import { spaceMedium } from "../../../styles/space";
import { FlexGrid2 } from "../../General/Form";
import { Row } from "../../General/Layout";
import { useDashboardContext } from "../Dashboard";
import {
  getInvalidTypesInParkAndBranch,
  getTurbinesSelectorFamily,
} from "../../../state/layout";
import { getFixedFoundationTotalsSelectorFamily } from "../../RightSide/InfoModal/FoundationModal/fixed/state";
import { valueRounding } from "../../RightSide/InfoModal/FoundationModal/utils";
import { isDefined, isNumber } from "../../../utils/predicates";
import { getFloatingFoundationTotalsSelectorFamily } from "../../RightSide/InfoModal/FoundationModal/floating/state";
import { pointInPolygon } from "utils/geometry";
import { InvalidTypes, InvalidTypesDisplayText } from "types/invalidTypes";
import { useBathymetry } from "hooks/bathymetry";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { ResultValue } from "components/General/GeneralSideModals.style";

const FloatingFoundationTotals = ({
  turbinesWithFloatingFoundation,
}: {
  turbinesWithFloatingFoundation: TurbineFeatureWithFoundation[];
}) => {
  const allFloaters = useRecoilValue(allFloaterTypesSelector);

  const turbineTypeIdAndFloatingFoundationIdCombinations =
    turbineTypeAndFloatingFoundationCombinations(
      turbinesWithFloatingFoundation,
      allFloaters,
    );

  const scales = useRecoilValue(
    getScalesForTurbineTypeIdsAndFoundationIds(
      turbineTypeIdAndFloatingFoundationIdCombinations,
    ),
  );

  const { totalPrimarySteelMass, totalPrimaryConcreteMass } = useRecoilValue(
    getFloatingFoundationTotalsSelectorFamily({
      tempLayoutFoundations: turbinesWithFloatingFoundation,
      scales,
      turbineTypeIdAndFloatingFoundationIdCombinations,
    }),
  );

  const foundationIds = [
    ...new Set(
      turbinesWithFloatingFoundation.map(
        (turbine) => turbine.properties.foundationId,
      ),
    ).values(),
  ];
  const foundationMaterials = foundationIds
    .map((id) => allFloaters.find((t) => t.id === id)?.material)
    .filter(isDefined)
    .filter((m, i, ar) => ar.indexOf(m) === i);

  const primarySteelWeight =
    foundationMaterials?.includes("Steel") && isNumber(totalPrimarySteelMass)
      ? valueRounding(totalPrimarySteelMass / 1000, 100)
      : undefined;

  const concreteWeight =
    foundationMaterials?.includes("Concrete") &&
    isNumber(totalPrimaryConcreteMass)
      ? valueRounding(totalPrimaryConcreteMass / 1000, 100)
      : undefined;

  return (
    <FlexGrid2 style={{ padding: spaceMedium }}>
      {isDefined(concreteWeight) && (
        <Row>
          <p>Total concrete weight</p>
          <ResultValue>
            <strong>{concreteWeight.toLocaleString()}</strong> t
          </ResultValue>
        </Row>
      )}
      {isDefined(primarySteelWeight) && (
        <Row>
          <p>Total primary steel weight</p>
          <ResultValue>
            <strong>{primarySteelWeight.toLocaleString()}</strong> t
          </ResultValue>
        </Row>
      )}
    </FlexGrid2>
  );
};

const FixedFoundationTotals = ({
  parkId,
  rasterId,
  turbinesWithFixedFoundation,
}: {
  parkId: string;
  rasterId: string;
  turbinesWithFixedFoundation: TurbineFeatureWithFoundation[];
}) => {
  const { totalPrimarySteelMass } = useRecoilValue(
    getFixedFoundationTotalsSelectorFamily({
      parkId,
      tempLayoutFoundations: turbinesWithFixedFoundation,
      rasterId,
    }),
  );

  return (
    <FlexGrid2 style={{ padding: spaceMedium }}>
      {totalPrimarySteelMass && (
        <Row>
          <p>Primary steel weight</p>
          <ResultValue>
            <strong>
              {valueRounding(
                totalPrimarySteelMass / 1000,
                100,
              ).toLocaleString()}
            </strong>
            t
          </ResultValue>
        </Row>
      )}
    </FlexGrid2>
  );
};

const FoundationTotalsDetails = () => {
  const { park, branch, projectId } = useDashboardContext();

  const parkId = park.id;
  const turbines = useRecoilValue(getTurbinesSelectorFamily({ parkId }));
  const validTurbines = turbines.filter((t) =>
    pointInPolygon(t.geometry, park.geometry),
  );
  const turbinesWithFoundation =
    validTurbines?.filter(isTurbineFeatureWithFoundation) ?? [];

  const hasFloatingFoundation = useRecoilValue(hasFloatingFoundationSelector);
  const hasFixedFoundation = useRecoilValue(hasFixedFoundationSelector);

  const turbinesWithFloatingFoundation = turbinesWithFoundation.filter(
    hasFloatingFoundation,
  );
  const turbinesWithFixedFoundation =
    turbinesWithFoundation.filter(hasFixedFoundation);

  const includesFloaters = turbinesWithFloatingFoundation.length > 0;
  const includesFixed = turbinesWithFixedFoundation.length > 0;

  const invalidTypes = useRecoilValue(
    getInvalidTypesInParkAndBranch({ parkId, branchId: branch.id }),
  );

  const bathymetry = useBathymetry({
    projectId,
    branchId: branch.id,
    featureId: park.id,
  }).getValue();

  if (!turbinesWithFoundation || turbinesWithFoundation.length === 0)
    return (
      <CenterContainer>
        <SimpleAlert text={"No foundations in the park."} type={"error"} />
      </CenterContainer>
    );

  if (!bathymetry || bathymetry.status === "failed")
    return <>Failed to load bathymetry.</>;

  const relevantInvalidTypes = invalidTypes.filter(
    (t) =>
      t.type === InvalidTypes.FoundationTypeInvalid ||
      t.type === InvalidTypes.TurbineTypeInvalid,
  );

  if (relevantInvalidTypes.length > 0)
    return (
      <CenterContainer style={{ margin: "3rem" }}>
        <InvalidTypesDisplayText invalidTypes={relevantInvalidTypes} />
      </CenterContainer>
    );

  return (
    <>
      {includesFixed && (
        <FixedFoundationTotals
          parkId={parkId}
          rasterId={bathymetry.id}
          turbinesWithFixedFoundation={turbinesWithFixedFoundation}
        />
      )}
      {includesFloaters && (
        <FloatingFoundationTotals
          turbinesWithFloatingFoundation={turbinesWithFloatingFoundation}
        />
      )}
    </>
  );
};

export const FoundationTotalsWidget = () => (
  <SafeCard title="Foundation totals" id="Foundation totals">
    <FoundationTotalsDetails />
  </SafeCard>
);
