import mapboxgl, { Map, MapboxGeoJSONFeature } from "mapbox-gl";
import { useEffect, useMemo } from "react";
import GeoJsonFeature from "./GeoJsonFeature";
import { Feature } from "geojson";
import { fillOpacityWithCallback } from "./utils";
import { getZoomLevels } from "../../layers/ExternalLayers/utils";
import {
  DEFAULT_IN_FOCUS_OPACITY,
  DEFAULT_NOT_IN_FOCUS_OPACITY,
  DEFAULT_SELECTED_OPACITY,
  lockedPropertyName,
} from "../../constants/canvas";
import {
  HIDDEN_CLICK_LAYER_SUFFIX,
  pointHiddenClickRadius,
} from "components/Mapbox/constants";

const DEFAULT_COLOR = "#27AE60";
export const defaultFixedPointCircleRadius: number = 6;

const Point = ({
  features,
  sourceId,
  layerId,
  map,
  symbols,
  paint,
  color,
  opacity,
  onClickCallback,
  onDbClickCallback,
  selectedIds,
  filter,
  beforeLayer,
  symbolsBeforeLayer,
  zoomLevels,
  cluster,
}: {
  features: Feature[];
  sourceId: string;
  layerId: string;
  map: Map;
  symbols?: Omit<mapboxgl.SymbolLayer, "id" | "source">;
  paint?: mapboxgl.CirclePaint;
  color?: string;
  opacity?: number;
  onClickCallback?: (
    features: MapboxGeoJSONFeature[],
    shiftClicked: boolean,
  ) => void;
  onDbClickCallback?: (features: any[]) => void;
  selectedIds?: (string | number)[];
  filter?: any[];
  beforeLayer?: string;
  symbolsBeforeLayer?: string;
  zoomLevels?: [number, number];
  cluster?: boolean;
}) => {
  const layers = useMemo(() => {
    return [
      {
        ...(zoomLevels ? getZoomLevels(zoomLevels) : {}),
        type: "circle",
        paint: {
          "circle-color": "rgba(0,0,0,0)",
          "circle-radius": 3,
          "circle-opacity": onClickCallback
            ? [
                "case",
                [
                  "all",
                  [
                    "boolean",
                    ["feature-state", "hover"],
                    ["feature-state", "selected"],
                    false,
                  ],
                  ["!=", ["get", lockedPropertyName], true],
                ],
                DEFAULT_SELECTED_OPACITY,
                ["boolean", ["feature-state", "inFocus"], false],
                DEFAULT_IN_FOCUS_OPACITY,
                DEFAULT_NOT_IN_FOCUS_OPACITY,
              ]
            : 1,
          ...paint,
        },
        id: layerId,
        source: sourceId, // reference the data source
      },
      ...(onClickCallback
        ? [
            {
              id: layerId + HIDDEN_CLICK_LAYER_SUFFIX,
              type: "circle",
              source: sourceId, // reference the data source
              paint: {
                "circle-color": "rgba(255,0,0,0.0)",
                "circle-radius": pointHiddenClickRadius,
              },
            },
          ]
        : []),
    ] as mapboxgl.CircleLayer[];
  }, [layerId, sourceId, paint, onClickCallback, zoomLevels]);

  const symbolsLayer = useMemo<mapboxgl.AnyLayer | undefined>(() => {
    return symbols
      ? {
          ...(zoomLevels ? getZoomLevels(zoomLevels) : {}),
          ...symbols,
          id: `${layerId}-symbolid`,
          source: sourceId,
          layout: {
            ...symbols.layout,
            "text-offset":
              symbols.layout && "text-offset" in symbols.layout
                ? symbols.layout["text-offset"]
                : [0, 1],
          },
        }
      : undefined;
  }, [layerId, sourceId, symbols, zoomLevels]);

  useEffect(() => {
    if (!map || (paint && "circle-color" in paint)) return;
    map.setPaintProperty(layerId, "circle-color", color ?? DEFAULT_COLOR);
  }, [layerId, map, color, layers, paint]);

  useEffect(() => {
    if (!map) return;
    if (opacity !== undefined) {
      map.setPaintProperty(
        layerId,
        "circle-opacity",
        fillOpacityWithCallback(opacity, opacity + 0.4),
      );
    }
  }, [layerId, map, opacity, layers]);

  return (
    <GeoJsonFeature
      layers={layers}
      symbolsLayer={symbolsLayer}
      features={features}
      sourceId={sourceId}
      layerId={layerId}
      map={map}
      onClickCallback={onClickCallback}
      onDbClickCallback={onDbClickCallback}
      selectedIds={selectedIds}
      filter={filter}
      beforeLayer={beforeLayer}
      symbolsBeforeLayer={symbolsBeforeLayer}
      cluster={cluster}
    />
  );
};

export default Point;
