import { useCallback, useMemo } from "react";
import type { Types } from "ably";
import {
  ABLY_PROJECT_ELEMENT_CREATE_FOLDER,
  ABLY_PROJECT_ELEMENT_DELETE_FOLDER,
  ABLY_PROJECT_ELEMENT_UPDATE_FOLDER,
  ABLY_PROJECT_ELEMENT_SORT_ORDER,
} from "state/ably";
import { useProjectElementsFoldersCrud } from "components/ProjectElementsV2/useProjectElementsFoldersCrud";
import { useAblyGeneric } from "./useAblyGeneric";
import { useTypedPath } from "state/pathParams";
import {
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";
import {
  _ProjectElementFolder,
  _ProjectElementSortOrder,
} from "components/ProjectElementsV2/service";
import { useProjectElementsSortOrder } from "components/ProjectElementsV2/useProjectElementsSortOrder";

const AblyProjectElementsFolders = ErrorBoundaryWrapper(
  ({ projectId }: { projectId: string }) => {
    const { localCreate, localDelete, localUpdate } =
      useProjectElementsFoldersCrud();
    const { localSortProjectElements } = useProjectElementsSortOrder();
    const { branchId: currentBranchId } = useTypedPath("branchId");

    const channelName = useMemo(
      () => projectId && `${projectId}:all`,
      [projectId],
    );

    const onPostMessageReceived = useCallback(
      (message: Types.Message) => {
        const { branchId } = message.data;
        if (branchId !== currentBranchId) {
          return;
        }

        localCreate(_ProjectElementFolder.parse(message.data.folder));
      },
      [currentBranchId, localCreate],
    );

    const onDeleteMessageReceived = useCallback(
      (message: Types.Message) => {
        const { branchId } = message.data;
        if (branchId !== currentBranchId) {
          return;
        }

        localDelete(message.data.folderId);
      },
      [currentBranchId, localDelete],
    );

    const onUpdateMessageReceived = useCallback(
      (message: Types.Message) => {
        const { branchId } = message.data;
        if (branchId !== currentBranchId) {
          return;
        }

        localUpdate(_ProjectElementFolder.parse(message.data.folder));
      },
      [currentBranchId, localUpdate],
    );

    const onProjectElementsSortOrderReceived = useCallback(
      (message: Types.Message) => {
        const { branchId } = message.data;
        if (branchId !== currentBranchId) {
          return;
        }

        localSortProjectElements(
          _ProjectElementSortOrder.array().parse(message.data.sortOrder),
        );
      },
      [currentBranchId, localSortProjectElements],
    );

    const events = useMemo(
      () => [
        {
          eventName: ABLY_PROJECT_ELEMENT_CREATE_FOLDER,
          onMessageReceived: onPostMessageReceived,
        },
        {
          eventName: ABLY_PROJECT_ELEMENT_UPDATE_FOLDER,
          onMessageReceived: onUpdateMessageReceived,
        },
        {
          eventName: ABLY_PROJECT_ELEMENT_DELETE_FOLDER,
          onMessageReceived: onDeleteMessageReceived,
        },
        {
          eventName: ABLY_PROJECT_ELEMENT_SORT_ORDER,
          onMessageReceived: onProjectElementsSortOrderReceived,
        },
      ],
      [
        onPostMessageReceived,
        onUpdateMessageReceived,
        onDeleteMessageReceived,
        onProjectElementsSortOrderReceived,
      ],
    );

    useAblyGeneric(channelName, events);

    return null;
  },
  FatalErrorBoundaryWrapper,
  ScreamOnError,
);

export default AblyProjectElementsFolders;
