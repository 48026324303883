import { atom, atomFamily, noWait, selector, selectorFamily } from "recoil";
import { Feature, MultiPolygon, Point, Position } from "geojson";
import { featureWGS84ToProjected } from "../utils/proj4";
import { getOSMBuildingsFromTile } from "../services/terrainAPIService";
import { DateTime } from "luxon";
import { ThreeCore } from "../components/ViewToPark/ThreeContext/useCreateThreeCore";
import { syncLocalStorage } from "./effects";
import { z } from "zod";

export const OSM_BUILDING_TILE_ZOOM = 16;

export enum VIEW_MODE {
  NATURAL_MODE = "natural",
  WIRE_FRAME_MODE = "wireframe",
}

export const viewFromShoreFreeCameraAtom = atom<boolean>({
  key: "viewFromShoreFreeCameraAtom",
  default: false,
});

export const viewFromShoreVisibleAtom = atom<boolean>({
  key: "viewFromShoreVisibleAtom",
  default: false,
});

export const viewFromShoreParkIdAtom = atom<string | undefined>({
  key: "viewFromShoreParkIdAtom",
  default: undefined,
});

export const threeCoreAtom = atom<ThreeCore | undefined>({
  key: "threeCoreAtom",
  default: undefined,
  dangerouslyAllowMutability: true,
});

export type LngLat = { lng: number; lat: number };

export const viewPositionAtom = atom<LngLat | undefined>({
  key: "viewPositionAtom",
  default: undefined,
});

export const cameraPositionAtom = atom<[number, number] | undefined>({
  key: "cameraPositionAtom",
  default: undefined,
});

export const viewDateAtom = atom<DateTime>({
  key: "viewDateAtom",
  default: DateTime.fromISO("2022-08-01 18:00"),
});

export const viewFovAtom = atom<number>({
  key: "viewFovAtom",
  default: 30,
  effects: [
    syncLocalStorage("vind:view-from-shore:fov", z.number().min(25).max(35)),
  ],
});

export const viewParkRotationAtom = atom<number>({
  key: "viewParkRotationAtom",
  default: 0,
  effects: [
    syncLocalStorage(
      "vind:view-from-shore:park-rotation",
      z.number().min(0).max(360),
    ),
  ],
});

export const viewTurbineHeightsAtom = atom<number[]>({
  key: "viewTurbineHeightsAtom",
  default: undefined,
});

export const viewTurbineDiameterAtom = atom<number[]>({
  key: "viewTurbineDiameterAtom",
  default: undefined,
});

export const viewHeightAtom = atom<number>({
  key: "viewHeightAtom",
  default: 2,
  effects: [
    syncLocalStorage(
      "vind:view-from-shore:view-height",
      z.number().min(1).max(100),
    ),
  ],
});

export const viewFromShoreTerrainColorAtom = atom<string>({
  key: "viewFromShoreTerrainColorAtom",
  default: "#77bb77",
});

export const viewFromShoreTerrainColorActiveAtom = atom<boolean>({
  key: "viewFromShoreTerrainColorActiveAtom",
  default: false,
});

export const viewFromShoreOSMBuildingsActiveAtom = atom<boolean>({
  key: "viewFromShoreOSMBuildingsActiveAtom",
  default: false,
});

export const viewFromShoreOSMBuildingTilesRequestedAtom = atom<
  [number, number][]
>({
  key: "viewFromShoreOSMBuildingTilesRequestedAtom",
  default: [],
});

export const viewFromShoreTerrainVisibleAtom = atom<boolean>({
  key: "viewFromShoreTerrainVisibleAtom",
  default: true,
});

export const turbineScalingAtom = atom<number>({
  key: "turbineScalingAtom",
  default: 1,
  effects: [
    syncLocalStorage(
      "vind:view-from-shore:turbine-scaling",
      z.number().min(0.5).max(1.5),
    ),
  ],
});

export const viewOrigoWGS84Atom = atom<Feature<Point> | null>({
  key: "viewOrigoWGS84Atom",
  default: null,
});

export const viewTowardsWGS84Atom = atom<Feature<Point> | null>({
  key: "viewTowardsWGS84Atom",
  default: null,
});

export const viewCameraAspectAtom = atom<any>({
  key: "viewCameraAspectAtom",
  default: undefined,
});

export const viewViewModeAtom = atom<VIEW_MODE>({
  key: "viewViewModeAtom",
  default: VIEW_MODE.NATURAL_MODE,
});

export const viewTurbineLightsAtom = atom<boolean>({
  key: "viewTurbineLightsAtom",
  default: false,
});

export const heightAboveGroundAtom = atom<number>({
  key: "heightAboveGroundAtom",
  default: 0,
});

export const viewProj4StringAtom = atom<undefined | string>({
  key: "viewProj4StringAtom",
  default: undefined,
});

export const visibleParksAtom = atomFamily<Record<string, boolean>, string>({
  key: "visibleParksAtom",
  default: (parkId) => ({
    [parkId]: true,
  }),
});

export const viewOrigoSelector = selector<Position | undefined>({
  key: "viewOrigoSelector",
  get: async ({ get }) => {
    const origoWGS84 = get(viewOrigoWGS84Atom);
    const proj4String = get(viewProj4StringAtom);
    if (!origoWGS84 || !proj4String) return;
    return featureWGS84ToProjected(origoWGS84, proj4String).geometry
      .coordinates as Position; // safety: This is a point.
  },
});

export const viewTowardsSelector = selector<Position | undefined>({
  key: "viewTowardsSelector",
  get: async ({ get }) => {
    const viewTowardsWGS84 = get(viewTowardsWGS84Atom);
    const proj4String = get(viewProj4StringAtom);
    if (!viewTowardsWGS84 || !proj4String) return;
    return featureWGS84ToProjected(viewTowardsWGS84, proj4String).geometry
      .coordinates as Position; // safety: This is a point.
  },
});

export const osmBuildingTileSelector = selectorFamily<
  Feature<MultiPolygon>[],
  { x: number; y: number }
>({
  key: "osmBuildingTileSelector",
  get:
    ({ x, y }) =>
    async () =>
      getOSMBuildingsFromTile(x, y, OSM_BUILDING_TILE_ZOOM),
});

export const osmBuildingTileStatusSelector = selector<{
  loading: boolean;
  error: boolean;
  finished: Feature<MultiPolygon>[];
}>({
  key: "osmBuildingTileStatusSelector",
  get: ({ get }) => {
    const viewFromShoreOSMBuildingTilesRequested = get(
      viewFromShoreOSMBuildingTilesRequestedAtom,
    );
    const requestsLoadable = viewFromShoreOSMBuildingTilesRequested.map(
      ([x, y]) => get(noWait(osmBuildingTileSelector({ x, y }))),
    );

    return {
      loading: requestsLoadable.some((r) => r.state === "loading"),
      error: requestsLoadable.some((r) => r.state === "hasError"),
      finished: requestsLoadable
        .filter((r) => r.state === "hasValue")
        .map((r) => r.contents)
        .flat(),
    };
  },
});

export const viewTurbineCoordsAtom = atom<undefined | [number, number][]>({
  key: "viewTurbineCoordsAtom",
  default: undefined,
});
