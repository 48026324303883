import shiftDragSelectHelpMovie from "./shiftDragSelectHelpMovie.mp4";
import CloseableHint from "./CloseableHint";

export const shiftDragSelectHelpHintType = "shiftDragSelectHelpHintType";

const ShiftDragSelectHelp = ({
  closeCallback,
  gotItCallback,
}: {
  closeCallback: () => void;
  gotItCallback?: () => void;
}) => {
  return (
    <CloseableHint
      title={"Multiselect features"}
      videosrc={shiftDragSelectHelpMovie}
      closeCallback={closeCallback}
      gotItCallback={gotItCallback}
      timeToDissapearMS={10000}
    >
      <p style={{ fontWeight: 500 }}>{`\u21E7 + click and drag`}</p>
      <p>{`Drag \u2190 selects everything you touch. Drag \u2192 selects everything inside`}</p>
    </CloseableHint>
  );
};

export default ShiftDragSelectHelp;
