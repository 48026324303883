import { CableType } from "../../../services/cableTypeService";
import {
  getCablesInBranchSelectorFamily,
  getSubstationsInBranchSelectorFamily,
  lazyWaveLengthCorrection,
} from "../../../state/cable";
import { CableFeature } from "../../../types/feature";
import { replaceEndpointsLineString } from "../../../types/turbines";
import { SimpleTurbineType } from "../../../types/turbines";
import { fastMax, sumKeys } from "../../../utils/utils";
import * as turf from "@turf/turf";
import { ProjectFeature } from "../../../types/feature";
import { isPointFeature, isTurbine } from "../../../utils/predicates";
import { GetRecoilValue, selectorFamily } from "recoil";
import { getTurbinesInBranchSelectorFamily } from "../../../state/layout";
import { isFloatingFoundationSelector } from "../../../state/foundations";
import { cableDepthAtEndpoints } from "./state";
import {
  branchSelectedConfigurationAtomFamily,
  configurationAtomFamily,
} from "state/configuration";
import { projectIdSelector } from "state/pathParams";

export const calculateMaxChainLength = ({
  maxTurbineRating,
  maxCableRating,
}: {
  maxTurbineRating: number;
  maxCableRating: number;
}) => {
  return Math.floor(maxCableRating / maxTurbineRating);
};

export const maxChainLengthFromTypes = (
  turbines: SimpleTurbineType[],
  cables: CableType[],
) => {
  const maxTurbineRating =
    fastMax(turbines.map((type) => type?.ratedPower ?? 0)) * 1e3;

  const maxCableRating = fastMax(cables.map((type) => type.powerRating));

  const maxChainLength = calculateMaxChainLength({
    maxCableRating,
    maxTurbineRating,
  });

  return maxChainLength;
};

const calculate3DCableLengths = async ({
  branchId,
  featureMap,
  cables,
  get,
}: {
  branchId: string;
  featureMap: Map<string, ProjectFeature>;
  cables: CableFeature[];
  get: GetRecoilValue;
}) => {
  const cableLengthMap = await Promise.all(
    cables.map(async (cable) => {
      const from = featureMap.get(cable.properties.fromId);
      const to = featureMap.get(cable.properties.toId);

      let fromCoords = cable.geometry.coordinates[0];
      let toCoords =
        cable.geometry.coordinates[cable.geometry.coordinates.length - 1];

      if (isPointFeature(from)) fromCoords = from.geometry.coordinates;
      if (isPointFeature(to)) toCoords = to.geometry.coordinates;

      const c = replaceEndpointsLineString(cable, fromCoords, toCoords);
      const horLength = turf.length(c, { units: "kilometers" });

      const depths = get(
        cableDepthAtEndpoints({ branchId, cableId: cable.id }),
      );
      const [fromDepth, toDepth] = depths ?? [0, 0];

      const fromFloater =
        isTurbine(from) &&
        from.properties.foundationId &&
        get(
          isFloatingFoundationSelector({
            foundationTypeId: from.properties.foundationId,
          }),
        );
      const toFloater =
        isTurbine(to) &&
        to.properties.foundationId &&
        get(
          isFloatingFoundationSelector({
            foundationTypeId: to.properties.foundationId,
          }),
        );

      const fromLengthCorr = fromFloater
        ? lazyWaveLengthCorrection({ waterDepth: fromDepth })
        : 0;
      const toLengthCorr = toFloater
        ? lazyWaveLengthCorrection({ waterDepth: toDepth })
        : 0;

      const totLength =
        horLength +
        (fromDepth + fromLengthCorr) / 1000 +
        (toDepth + toLengthCorr) / 1000;
      return { id: cable.id, totLength };
    }),
  );

  const cableLengths = sumKeys(cableLengthMap.map((c) => [c.id, c.totLength]));
  return cableLengths;
};

const get3DCableLengthsInPark = selectorFamily<
  Record<string, number> | undefined,
  { parkId: string; branchId: string }
>({
  key: "get3DCableLengthsInPark",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const cables = get(getCablesInBranchSelectorFamily({ parkId, branchId }));
      const turbines = get(
        getTurbinesInBranchSelectorFamily({ parkId, branchId }),
      );
      const substations = get(
        getSubstationsInBranchSelectorFamily({ parkId, branchId }),
      );

      const cableEndFeatures = [...turbines, ...substations];

      const featureMap = new Map<string, ProjectFeature>(
        cableEndFeatures.map((f) => [f.id, f]),
      );

      return calculate3DCableLengths({
        branchId,
        featureMap,
        cables,
        get,
      });
    },
});

export const get3DCableLengthsInParkWithContingency = selectorFamily<
  Record<string, number> | undefined,
  { parkId: string; branchId: string; analysisConfigurationId?: string }
>({
  key: "get3DCableLengthsInParkWithContingency",
  get:
    ({ parkId, branchId, analysisConfigurationId }) =>
    ({ get }) => {
      const cableLengths = get(get3DCableLengthsInPark({ parkId, branchId }));
      const projectId = get(projectIdSelector);
      if (!projectId) {
        return cableLengths;
      }

      let config;
      if (!analysisConfigurationId) {
        config = get(
          branchSelectedConfigurationAtomFamily({ branchId, projectId }),
        );
      } else {
        config = get(
          configurationAtomFamily({
            nodeId: projectId,
            analysisConfigurationId,
          }),
        );
      }

      if (config?.electrical.cableLengthContingencyEnabled) {
        const contingency = config.electrical.cableLengthContingency;
        return Object.fromEntries(
          Object.entries(cableLengths ?? {}).map(([id, length]) => [
            id,
            length * (1 + contingency),
          ]),
        );
      }

      return cableLengths;
    },
});
