import * as Sentry from "@sentry/browser";
import { v4 as uuidv4 } from "uuid";
import {
  EXPORT_CABLE_PROPERTY_TYPE,
  SUBSTATION_PROPERTY_TYPE,
} from "../../../../../constants/cabling";
import {
  DEFAULT_CANVAS_LAYER_COLOR,
  DEFAULT_CANVAS_POINT_COLOR,
} from "../../../../../constants/canvas";
import {
  DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
  SUB_AREA_PROPERTY_TYPE,
  EXCLUSION_ZONE_COLOR,
  SUB_AREA_COLOR,
} from "../../../../../constants/division";
import { PARK_PROPERTY_TYPE } from "../../../../../constants/park";
import { TURBINE_PROPERTY_TYPE } from "../../../../../constants/projectMapView";
import { _DMSPair, DMSDirection } from "../../../../../functions/dms";
import {
  ExclusionDomainDefault,
  ProjectFeature,
} from "../../../../../types/feature";
import { colors } from "../../../../../styles/colors";
import { DEFAULT_PARK_NAME } from "../../../../../utils/geojson/utils";

export const tempFeatureFromCoordinatesSourceId =
  "tempFeatureFromCoordinatesSourceId";
export const tempFeatureFromCoordinatesPolygonLayerId =
  "tempFeatureFromCoordinatesPolygonLayerId";
export const tempFeatureFromCoordinatesLineLayerId =
  "tempFeatureFromCoordinatesLineLayerId";
export const tempFeatureFromCoordinatesCircleLayerId =
  "tempFeatureFromCoordinatesCircleLayerId";
export const tempFeatureFromCoordinatesCircleSymbolId =
  "tempFeatureFromCoordinatesCircleSymbolId";

function convertDMStoDD(
  degrees: number,
  minutes: number,
  seconds: number,
  direction: DMSDirection,
) {
  var dd = degrees + minutes / 60 + seconds / 3600;
  if (direction === "S" || direction === "W") dd = dd * -1;
  return dd;
}

const DMSCoordinatesToDecimals = (coordinatesText: string) => {
  Sentry.addBreadcrumb({
    category: "DMSCoordinatesToDecimals",
    message: "Original coordinates",
    level: "info",
    data: {
      coordinatesText,
    },
  });
  return coordinatesText
    .trim()
    .split("\n")
    .map((line) => {
      const [a, b] = _DMSPair.parse(line);
      const aDD = convertDMStoDD(a.degree, a.minute, a.second, a.direction);
      const bDD = convertDMStoDD(b.degree, b.minute, b.second, b.direction);
      // NOTE: Reverse the order here, so that the first coordinate is E/W.
      return `${bDD} ${aDD}`;
    })
    .join("\n");
};

export const formatInput = (input: string) => {
  let formattedInput = input.replaceAll("\t", " ");
  try {
    return DMSCoordinatesToDecimals(formattedInput);
  } catch {
    return formattedInput;
  }
};

export const coordinateToFeature = (
  type: "Point" | "LineString" | "Polygon",
  coordinates: number[][],
  pointNames: Array<string | undefined>,
  properties: Record<string, unknown>,
): ProjectFeature[] => {
  const presetProps = properties || {};
  const id = uuidv4();
  const name = "Uploaded feature";

  switch (type) {
    case "Point":
      return coordinates.map((coord, index) => {
        const id = uuidv4();
        return {
          type: "Feature",
          id,
          properties: {
            name: pointNames[index] ?? name,
            ...presetProps,
            id,
          },
          geometry: {
            coordinates: coord,
            type: "Point",
          },
        };
      });
    case "LineString":
      return [
        {
          type: "Feature",
          id,
          properties: {
            name,
            ...presetProps,
            id,
          },
          geometry: {
            coordinates: coordinates,
            type: "LineString",
          },
        },
      ];
    case "Polygon":
      const polygonCoordinates =
        coordinates[0] === coordinates[coordinates.length - 1]
          ? coordinates
          : [...coordinates, coordinates[0]];
      return [
        {
          type: "Feature",
          id,
          properties: {
            name,
            ...presetProps,
            id,
          },
          geometry: {
            coordinates: [polygonCoordinates],
            type: "Polygon",
          },
        },
      ];
    default:
      throw new Error("Unknown type: " + type);
  }
};

export const getProperties = (
  projectFeatureType: string,
  geometryType: string,
  parkId?: string,
  currentTurbineType?: string,
) => {
  if (projectFeatureType === "other") {
    return {
      type: undefined,
      color:
        geometryType === "Point"
          ? DEFAULT_CANVAS_LAYER_COLOR
          : DEFAULT_CANVAS_POINT_COLOR,
    };
  } else if (projectFeatureType === PARK_PROPERTY_TYPE) {
    return {
      type: PARK_PROPERTY_TYPE,
      color: colors.park2,
      name: DEFAULT_PARK_NAME,
    };
  } else if (projectFeatureType === SUB_AREA_PROPERTY_TYPE && parkId) {
    return {
      type: SUB_AREA_PROPERTY_TYPE,
      color: SUB_AREA_COLOR,
      parentIds: [parkId],
    };
  } else if (projectFeatureType === DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE) {
    return {
      type: DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
      color: EXCLUSION_ZONE_COLOR,
      domain: ExclusionDomainDefault,
    };
  } else if (projectFeatureType === TURBINE_PROPERTY_TYPE && parkId) {
    return {
      type: TURBINE_PROPERTY_TYPE,
      turbineTypeId: currentTurbineType ?? "unknown",
      parentIds: [parkId],
      name: undefined,
    };
  } else if (projectFeatureType === SUBSTATION_PROPERTY_TYPE && parkId) {
    return {
      type: SUBSTATION_PROPERTY_TYPE,
      name: "Default substation",
      color: colors.substation,
      parentIds: [parkId],
    };
  } else if (projectFeatureType === EXPORT_CABLE_PROPERTY_TYPE && parkId) {
    return {
      type: EXPORT_CABLE_PROPERTY_TYPE,
      parentIds: [parkId],
      name: "Export cable",
    };
  }

  return {};
};
