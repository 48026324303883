import { useCallback, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { ablyLoadedState } from "../state/ably";
import { Realtime, Types } from "ably";
import { getCurrentTokenUserId } from "../state/global";
import useAblyTimelineRefreshCallback from "./useAblyTimeline";
import useAblyChangelogRefreshCallback from "components/Changelog/useChangelogAbly";

const useGenericEventHandling = () => {
  const timelineRefreshCallback = useAblyTimelineRefreshCallback();
  const changelogRefreshCallback = useAblyChangelogRefreshCallback();
  return useCallback(
    (event: AblyEventHandler, msg: Types.Message) => {
      timelineRefreshCallback(event, msg);
      changelogRefreshCallback(event, msg);
    },
    [timelineRefreshCallback, changelogRefreshCallback],
  );
};

export type AblyEventHandler = {
  eventName: string;
  onMessageReceived: (msg: Types.Message) => void;
};

function createCompositeName(channelName: string, eventName: string) {
  return channelName + "-" + eventName;
}

const lastUnsubscribeTime: Record<string, number> = {};

export function useAblyGeneric(
  channelName: string | undefined,
  events: AblyEventHandler[],
) {
  const ablyLoaded = useRecoilValue(ablyLoadedState);
  const genericCallback = useGenericEventHandling();

  const onMount = useCallback(
    async (channelName: string, ablyRealtime: Realtime) => {
      const channel = ablyRealtime.channels.get(channelName);

      function handleMessage(event: AblyEventHandler, message: Types.Message) {
        genericCallback(event, message);
        // Message is missing connectionId, might be a message from the backend where we do not add connectionId
        if (!message.data._connectionId) {
          if (message.data.clientId === getCurrentTokenUserId()) {
            return;
          }
        } else if (
          // do not apply your own changes
          message.data._connectionId === ablyRealtime.connection.id
        ) {
          return;
        }

        event.onMessageReceived(message);
      }

      events.forEach((event) => {
        // if we have unsubscribed earlier in the session we fetch the history for the time we haven't been subsribed
        // and we apply those messages to the local state so that we are up to date
        /*   if (
          lastUnsubscribeTime[createCompositeName(channelName, event.eventName)]
        ) {
          const sinceTime =
            lastUnsubscribeTime[
              createCompositeName(channelName, event.eventName)
            ];

          channel.history({ start: sinceTime }, async (err, resultPage) => {
            async function handlePage(
              page: Types.PaginatedResult<Types.Message>,
            ) {
              page?.items
                .filter((message) => message.name === event.eventName)
                .forEach((message) => {
                  handleMessage(event, message);
                });

              if (page.hasNext()) {
                const nextPage = await page.next();
                if (nextPage) {
                  handlePage(nextPage);
                }
              }
            }
            if (resultPage) {
              handlePage(resultPage);
            }
          });
        } */

        // subscribe for new messages
        channel.subscribe(event.eventName, (message) => {
          handleMessage(event, message);
        });
      });
    },
    [events, genericCallback],
  );

  const onUnmount = useCallback(
    async (channelName: string, ablyRealtime: Realtime) => {
      const channel = ablyRealtime.channels.get(channelName);
      events.forEach((event) => {
        // Store the current time as the last unsubscribe time
        lastUnsubscribeTime[createCompositeName(channelName, event.eventName)] =
          new Date().getTime();

        channel.unsubscribe(event.eventName);
      });
    },
    [events],
  );

  useEffect(() => {
    if (!ablyLoaded || !channelName) return;

    onMount(channelName, ablyLoaded);
    return () => {
      onUnmount(channelName, ablyLoaded);
    };
  }, [ablyLoaded, channelName, onMount, onUnmount]);
}
