export const currentYear = () => new Date().getFullYear();

export const presentValue = ({
  amount,
  inflationRate,
  presentYear = currentYear(),
  referenceYear,
}: {
  amount: number;
  inflationRate: number;
  presentYear?: number;
  referenceYear: number;
}): number =>
  futureValue({
    amount,
    inflationRate,
    years: presentYear - referenceYear,
  });

export const futureValue = ({
  inflationRate,
  amount,
  years,
}: {
  amount: number;
  inflationRate: number;
  years: number;
}): number => amount * Math.pow(1 + inflationRate, years);
