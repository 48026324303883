import styled from "styled-components";

export const ElementIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg,
  canvas {
    path,
    line {
      stroke: #333333;
    }
  }
`;
