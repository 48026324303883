/// <reference types="vite-plugin-svgr/client" />
import styled from "styled-components";
import { Suspense, useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";
import Tooltip from "../General/Tooltip";
import ArrowDownIcon from "@icons/24/ArrowDown.svg?react";
import ArrowRightIcon from "@icons/24/ArrowRight.svg?react";
import ProjectIcon from "@icons/24/ProjectGlobe.svg?react";
import LibraryIcon from "@icons/24/Library.svg?react";
import { isCable, isDefined } from "../../utils/predicates";
import { CableFeature } from "../../types/feature";
import { allCableTypesWithLevelSelector } from "../Cabling/Generate/state";
import { DropDownItem } from "../General/Dropdown/DropdownItems";
import { useProjectElementsCrud } from "../ProjectElements/useProjectElementsCrud";
import { useTypedPath } from "../../state/pathParams";
import { trackCanvasOption } from "./MenuTracking";
import { ProjectFeature } from "../../types/feature";
import { MenuButton } from "../General/MenuButton";
import { MenuItem } from "../General/Menu";
import {
  NamedTooltipContainer,
  NamedTooltipWrapper,
} from "./CanvasSelectOption.style";

const TypeSelectorWrapper = styled.div`
  position: relative;
  font-size: 1.6rem;
  font-weight: 500;
`;

const TypeSelector = ({
  selectedCables,
  editable,
}: {
  selectedCables: CableFeature[];
  editable: boolean;
}) => {
  const { projectId, branchId } = useTypedPath("projectId", "branchId");
  const { update: updateFeatures } = useProjectElementsCrud();
  const allCableTypes = useRecoilValue(allCableTypesWithLevelSelector);

  const onSelectItem = useCallback(
    (val: string) => {
      trackCanvasOption("change-cable-type", {
        projectId,
        branchId,
      });
      const updatedFeatures = selectedCables.map((c) => ({
        ...c,
        properties: {
          ...c.properties,
          cableTypeId: val,
        },
      }));

      updateFeatures({ update: updatedFeatures });
    },
    [branchId, projectId, selectedCables, updateFeatures],
  );

  const currentCableType = useMemo(() => {
    const selectedCableTypes = new Set(
      selectedCables.map((c) => c.properties.cableTypeId),
    );

    if (selectedCableTypes.size === 1) {
      const selectedCableTypeId = selectedCableTypes.values().next().value;
      return allCableTypes.find((t) => t.cable.id === selectedCableTypeId);
    } else return null;
  }, [allCableTypes, selectedCables]);

  const items: DropDownItem[] = useMemo(() => {
    const maxPowerLoad = Math.max(
      ...selectedCables.map((c) => c.properties.powerLoad).filter(isDefined),
    );

    return allCableTypes.map((t) => ({
      icon: ["project"].includes(t.level) ? (
        <Tooltip text={"Project foundation"}>
          <ProjectIcon />
        </Tooltip>
      ) : (
        <Tooltip text={"Library foundation"}>
          <LibraryIcon />
        </Tooltip>
      ),
      value: t.cable.id,
      name: `${t.cable.name} (${t.cable.voltage}kV)`,
      disabled: maxPowerLoad ? t.cable.powerRating / 1e6 <= maxPowerLoad : true,
    }));
  }, [allCableTypes, selectedCables]);

  return (
    <TypeSelectorWrapper>
      <MenuButton
        disabled={!editable}
        icon={<ArrowRightIcon />}
        iconOpen={<ArrowDownIcon />}
        buttonStyle={{
          border: "none",
          flexDirection: "row-reverse",
          height: "fit-content",
          justifyContent: "space-between",
          padding: 0,
        }}
        buttonType="dropdown"
        buttonText={currentCableType ? currentCableType.cable.name : "..."}
      >
        {items.map((item) => (
          <MenuItem
            key={item.value}
            name={item.name}
            onClick={() => onSelectItem(item.value)}
            icon={item.icon}
          />
        ))}
      </MenuButton>
    </TypeSelectorWrapper>
  );
};

export default function CableTypeSelector({
  editable,
  selectedProjectFeatures,
}: {
  editable: boolean;
  selectedProjectFeatures: ProjectFeature[];
}) {
  const selectedCableFeatures = useMemo(() => {
    const selectedCables = selectedProjectFeatures.filter(isCable);

    return selectedCables.length >= 1 ? selectedCables : null;
  }, [selectedProjectFeatures]);

  if (!selectedCableFeatures) return null;

  return (
    <Suspense fallback={null}>
      <Tooltip position="top" text="Change type" readonlyAware>
        <NamedTooltipContainer style={{ minWidth: "8rem" }}>
          <NamedTooltipWrapper>Cable type</NamedTooltipWrapper>
          <TypeSelector
            selectedCables={selectedCableFeatures}
            editable={editable}
          />
        </NamedTooltipContainer>
      </Tooltip>
    </Suspense>
  );
}
