import { useRecoilValue } from "recoil";
import { portfolioFilteredTotalCapacitySelector } from "state/portfolio";

const TotalPortfolioCapacity = () => {
  const totalCapacity = useRecoilValue(portfolioFilteredTotalCapacitySelector);

  return <>{(totalCapacity / 1000 / 1000).toFixed(2)} GW</>;
};

export default TotalPortfolioCapacity;
