import { selectorFamily } from "recoil";
import { CostType } from "../../../../services/costService";
import { libraryAndProjectCostConfigurationsSelectorFamily } from "../../../../state/costConfigurations";
import { unitToAmountUnit } from "../../../../types/financial";
import { Cost, costId } from "../capexNumbers";
import { amountId } from "../generalAmounts";

export const otherCostsSelectorFamily = selectorFamily<
  Cost[],
  {
    projectId: string;
    configurationId: string;
  }
>({
  key: "otherCostsSelectorFamily",
  get:
    ({ projectId, configurationId }) =>
    ({ get }) => {
      const configurations = get(
        libraryAndProjectCostConfigurationsSelectorFamily({
          nodeId: projectId,
        }),
      );

      const configuration = configurations.find(
        (c) => c.id === configurationId,
      );

      if (!configuration) return [];

      const {
        capex: { custom },
      } = configuration;

      const category = CostType.Other;

      const customCosts: Cost[] = custom
        .filter((c) => c.category === category)
        .flatMap((custom) => ({
          ...custom,
          name: `${custom.name}`,
          id: costId({
            category: category,
            costId: custom.id,
          }),
          amountId: amountId({
            unit: unitToAmountUnit[custom.unit],
            category: category,
          }),
        }));

      return customCosts;
    },
});
