import { z } from "zod";
import { ExternalDataSourceLink, Layer } from "../types/layers";
import { isHostedLayer } from "../state/layer";
import { fetchSchemaWithToken } from "./utils";

const LAYER_PREVIEW_S3_BASE_URL = "/preview-layer/" as const;
const LAYER_PREVIEW_BASE_URL = "/api/layer-preview/image/" as const;

const _Preview_Image = z.object({
  path: z.string(),
});
type Preview_Image = z.infer<typeof _Preview_Image>;

export const getUrlAndIdForImage = (
  layer: Layer,
  sourceLink: ExternalDataSourceLink,
): { url: string; id: string | number } => {
  if (isHostedLayer(layer)) {
    const url = new URL(sourceLink.url);
    return {
      url: url.origin,
      id: url.pathname.replace(/^\//g, ""),
    };
  }

  return {
    url: sourceLink.url,
    id: layer.sourceLayerId,
  };
};

export const getImagePreviewUrl = (
  layer: Layer,
  sourceLink: ExternalDataSourceLink,
) => {
  const urlAndId = getUrlAndIdForImage(layer, sourceLink);

  return LAYER_PREVIEW_BASE_URL.concat(
    layer.sourceType,
    "/",
    window.btoa(encodeURIComponent(urlAndId.url)),
    "/",
    window.btoa(encodeURIComponent(urlAndId.id)),
  );
};

export const getImageS3Url = (
  layer: Layer,
  sourceLink: ExternalDataSourceLink,
) => {
  const urlAndId = getUrlAndIdForImage(layer, sourceLink);
  if (!urlAndId) {
    return;
  }

  return LAYER_PREVIEW_S3_BASE_URL.concat(
    layer.sourceType,
    "/",
    window.btoa(encodeURIComponent(urlAndId.url)),
    "/",
    window.btoa(encodeURIComponent(urlAndId.id)),
    ".png",
  );
};

export const generatePreviewImage = async (
  lambdaUrl: string,
  abortSignal?: AbortSignal,
): Promise<Preview_Image> =>
  await fetchSchemaWithToken(_Preview_Image, lambdaUrl, {
    method: "GET",
    signal: abortSignal,
  });

export const postCustomPreviewImage = async (
  lambdaUrl: string,
  base64EncodedData: string | ArrayBuffer,
  abortSignal?: AbortSignal,
): Promise<Preview_Image> =>
  await fetchSchemaWithToken(_Preview_Image, lambdaUrl, {
    method: "POST",
    signal: abortSignal,
    body: base64EncodedData,
  });
