import { useCallback, useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { mapInteractionSelector } from "../../state/map";
import { useTypedPath } from "../../state/pathParams";

import { currentSelectionArrayAtom } from "../../state/selection";
import {
  UndoRedo as UndoRedoType,
  UndoRedoAction,
  projectFeaturesSelector,
  redoState,
  undoState,
} from "../ProjectElements/state";
import { useProjectElementsCrud } from "../ProjectElements/useProjectElementsCrud";
import {
  ErrorBoundarySilent,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "../ErrorBoundaries/ErrorBoundaryLocal";
import { ProjectFeature } from "types/feature";
import { objectEquals } from "utils/utils";
import { toastMessagesAtom } from "state/toast";
import { Mixpanel } from "mixpanel";
import { projectFeatureMap } from "state/projectLayers";

/**
 * Determines the validity of an undo/redo action based on the current state of project features.
 * An action is considered valid if the state of the project's features at the time of the action's
 * original execution matches the project's current feature state. This validation ensures that the
 * integrity of collaborative work is maintained, preventing one user's actions from inadvertently
 * reverting or conflicting with another user's subsequent modifications.
 *
 * Specifically, for an action to be valid:
 * - Each feature added or updated by the action must still exist in the current state with identical properties.
 * - This check prevents a user from undoing or redoing actions that would conflict with changes made by others.
 *   For example, if User 1 moves a turbine (Turbine X) and then User 2 moves the same turbine to a different
 *   location, User 1's move action becomes invalid for undo/redo because the current state no longer matches
 *   the state when User 1's action was originally performed. This mechanism effectively prevents the undoing
 *   of another user's work, ensuring collaborative edits are respected and preserved.
 *
 * @param action The action to validate, containing add and update operations.
 * @param currentState The current state of project features, used to compare against the action's effects.
 * @returns {boolean} True if the action's effects are still present in the current state, false otherwise.
 */
function checkIfActionIsValid(
  action: UndoRedoAction,
  currentState: ProjectFeature[],
): boolean {
  if (
    (action.add ?? []).every((f) =>
      currentState.some((cf) => objectEquals(f, cf)),
    ) &&
    (action.update ?? []).every((f) =>
      currentState.some((cf) => objectEquals(f, cf)),
    )
  ) {
    return true;
  }
  return false;
}

function getNextAction(
  stack: UndoRedoType[],
  projectFeatures: ProjectFeature[],
): { action: UndoRedoAction | undefined; rest: UndoRedoType[] } | undefined {
  // Base case: if the stack is empty
  if (stack.length === 0) {
    return undefined;
  }

  const { action, originAction } = stack[stack.length - 1];
  const actionIsValid = checkIfActionIsValid(originAction, projectFeatures);
  const rest = stack.slice(0, -1);

  if (actionIsValid) {
    return {
      action,
      rest,
    };
  } else {
    return {
      action: undefined,
      rest,
    };
  }
}

export function useUndoRedo() {
  const { projectId, branchId } = useTypedPath("projectId", "branchId");
  const projectFeatures = useRecoilValue(projectFeaturesSelector);
  const [undos, setUndos] = useRecoilState(undoState({ projectId, branchId }));
  const [redos, setRedos] = useRecoilState(redoState({ projectId, branchId }));
  const { update: updateFeatures } = useProjectElementsCrud();
  const setToastMessages = useSetRecoilState(toastMessagesAtom);

  const canUndo = undos.length > 0;
  const canRedo = redos.length > 0;

  const undo = useCallback(() => {
    if (undos.length > 0) {
      const nextAction = getNextAction(undos, projectFeatures);
      if (!nextAction) {
        return;
      }
      const { action: undo, rest } = nextAction;
      setUndos(rest);
      if (!undo) {
        setToastMessages((tm) => [
          ...tm,
          {
            type: "error",
            text: "The last change cannot be undone, another user has updated the feature",
            timeout: 5000,
          },
        ]);
        return;
      }
      updateFeatures(undo, true);

      const newRedo = {
        remove: undo.add?.map((f) => f.id),
        add: projectFeatures.filter((f) => undo.remove?.includes(f.id)),
        update: projectFeatures.filter((f) =>
          undo.update?.some((cf) => cf.id === f.id),
        ),
      };
      setRedos((cur) => [
        ...cur,
        {
          action: newRedo,
          originAction: undo,
        },
      ]);
    }
  }, [
    projectFeatures,
    setRedos,
    setToastMessages,
    setUndos,
    undos,
    updateFeatures,
  ]);

  const redo = useCallback(() => {
    if (redos.length > 0) {
      const nextAction = getNextAction(redos, projectFeatures);
      if (!nextAction) {
        setRedos([]);
        return;
      }
      const { action: redo, rest } = nextAction;
      setRedos(rest);
      if (!redo) {
        setToastMessages((tm) => [
          ...tm,
          {
            type: "error",
            text: "The last change cannot be redone, another user has updated the feature",
            timeout: 5000,
          },
        ]);
        return;
      }
      updateFeatures(redo, false, true);

      const newUndo = {
        remove: redo.add?.map((f) => f.id),
        add: projectFeatures.filter((f) => redo.remove?.includes(f.id)),
        update: projectFeatures.filter((f) =>
          redo.update?.some((cf) => cf.id === f.id),
        ),
      };
      setUndos((cur) => [
        ...cur,
        {
          action: newUndo,
          originAction: redo,
        },
      ]);
    }
  }, [
    projectFeatures,
    redos,
    setRedos,
    setToastMessages,
    setUndos,
    updateFeatures,
  ]);

  return { undo, redo, canUndo, canRedo };
}

const UndoRedo = ErrorBoundaryWrapper(
  () => {
    const projectFeatures = useRecoilValue(projectFeatureMap);
    const setCurrentSelectionArray = useSetRecoilState(
      currentSelectionArrayAtom,
    );
    const mapInteraction = useRecoilValue(mapInteractionSelector);

    const { undo, redo } = useUndoRedo();

    const onUndo = useCallback(
      (event: KeyboardEvent) => {
        if (
          event.isComposing ||
          event.code !== "KeyZ" ||
          (!event.metaKey && !event.ctrlKey) ||
          event.shiftKey
        ) {
          return;
        }
        Mixpanel.track("Undo", { source: "shortcut" });
        undo();
      },
      [undo],
    );
    const onRedo = useCallback(
      (event: KeyboardEvent) => {
        if (
          event.isComposing ||
          event.code !== "KeyZ" ||
          (!event.metaKey && !event.ctrlKey) ||
          !event.shiftKey
        ) {
          return;
        }
        Mixpanel.track("Redo", { source: "shortcut" });
        redo();
      },
      [redo],
    );
    useEffect(() => {
      if (!mapInteraction.undoredo) return;

      window.addEventListener("keydown", onUndo);
      window.addEventListener("keydown", onRedo);
      return () => {
        window.removeEventListener("keydown", onUndo);
        window.removeEventListener("keydown", onRedo);
      };
    }, [mapInteraction.undoredo, onRedo, onUndo]);

    useEffect(() => {
      setCurrentSelectionArray((csa) => {
        return csa.filter((c) => projectFeatures.has(c));
      });
    }, [setCurrentSelectionArray, projectFeatures]);

    return null;
  },
  ErrorBoundarySilent,
  ScreamOnError,
);

export default UndoRedo;
