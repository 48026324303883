import { projectVersionAtom } from "./../state/project";
import { ABLY_CURSOR_POSITION } from "./../state/ably";
import { useCallback, useEffect } from "react";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { ablyLoadedState } from "../state/ably";
import { Realtime } from "ably";
import { liveCursorState } from "../components/LiveCursor/state";
import { loggedInUserSelector } from "../state/user";
import { mapRefAtom } from "../state/map";
import { LngLatLike, MapMouseEvent } from "mapbox-gl";
import { getCurrentTokenUserId } from "../state/global";
import { initializeAndSet } from "../components/Comments/hooks/useReplyReactionCrud";
import { branchIdSelector } from "../state/pathParams";

let isThrottled = false;
export function useAblyCursorPosition(projectId: string) {
  const branchId = useRecoilValue(branchIdSelector);
  const version = useRecoilValue(projectVersionAtom({ projectId, branchId }));
  const ablyLoaded = useRecoilValue(ablyLoadedState);
  const currentUserId = useRecoilValue(loggedInUserSelector)?.user_id;
  const map = useRecoilValue(mapRefAtom);

  const onMouseMove = useCallback(
    (e: MapMouseEvent) => {
      if (!ablyLoaded) return;
      if (!isThrottled) {
        isThrottled = true;
        const channel = ablyLoaded.channels.get(`${projectId}:all`);
        const lngLat = Object.values(e.lngLat);
        channel.publish(ABLY_CURSOR_POSITION, {
          userId: currentUserId,
          position: lngLat,
          source: { projectId, branchId, version },
        });
        setTimeout(() => {
          isThrottled = false;
        }, 120); // Limit to one position update per second
      }
    },
    [ablyLoaded, branchId, currentUserId, projectId, version],
  );

  useEffect(() => {
    if (!map) return;
    map.on("mousemove", onMouseMove);
    return () => {
      map.off("mousemove", onMouseMove);
    };
  }, [map, onMouseMove]);

  const updateCursorState = useRecoilCallback(
    ({ set, snapshot }) =>
      async (
        source: {
          projectId: string;
          branchId: string;
          version?: number;
        },
        userId: string,
        position: LngLatLike,
      ) => {
        initializeAndSet(
          snapshot,
          set,
          liveCursorState({ ...source }),
          (prevPositions) => {
            const newPositions = new Map(prevPositions);
            if (position) {
              newPositions.set(userId, position);
            } else {
              newPositions.delete(userId);
            }
            return newPositions;
          },
        );
      },
    [],
  );

  const onMount = useCallback(
    async (channelName: string, ablyRealtime: Realtime) => {
      const channel = ablyRealtime.channels.get(channelName);
      channel.subscribe(ABLY_CURSOR_POSITION, (message) => {
        const { userId, position, source } = message.data;
        if (userId === getCurrentTokenUserId()) return;
        updateCursorState(source, userId, position);
      });
    },
    [updateCursorState],
  );

  const onUnmount = useCallback(
    (channelName: string, ablyRealtime: Realtime) => {
      const channel = ablyRealtime.channels.get(channelName);
      channel.unsubscribe(ABLY_CURSOR_POSITION);
      channel.publish(ABLY_CURSOR_POSITION, {
        userId: currentUserId,
        position: null,
      });
    },
    [currentUserId],
  );

  useEffect(() => {
    if (!ablyLoaded) return;
    onMount(`${projectId}:all`, ablyLoaded);
    return () => {
      onUnmount(`${projectId}:all`, ablyLoaded);
    };
  }, [ablyLoaded, projectId, onMount, onUnmount]);
}
