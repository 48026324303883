/// <reference types="vite-plugin-svgr/client" />
import { useRef } from "react";
import CostInput from "./CostInput";
import {
  CostConfiguration,
  CostType,
} from "../../../../../services/costService";
import { useRecoilValue } from "recoil";
import {
  localCostConfigurationAtom,
  useLocalCostConfigurationCrud,
} from "../../state";
import Button from "../../../../General/Button";
import { IconREMSize } from "../../../../../styles/typography";
import Add from "@icons/24/Add.svg?react";
import { v4 as uuid } from "uuid";
import { ConfidenceLevel, CostUnit } from "../../../../../types/financial";
import { CostDivider, DividerWrapper } from "../../styles";
import React from "react";
import { Row } from "components/General/Layout";

export const CustomCosts = ({
  category,
  units,
  confidenceLevel,
  isReadOnly,
}: {
  category: CostType;
  confidenceLevel: ConfidenceLevel;
  units: CostUnit[];
  isReadOnly?: boolean;
}) => {
  const localConfig: CostConfiguration = useRecoilValue(
    localCostConfigurationAtom,
  );
  const recentlyAddedId = useRef<string>();

  const { updateCustomCapex } = useLocalCostConfigurationCrud();

  const handleAddCost = () => {
    const newId = uuid();
    recentlyAddedId.current = newId;
    updateCustomCapex({
      id: newId,
      name: "Untitled",
      cost: 0,
      unit: units[0],
      category,
      confidenceLevel,
    });
  };

  return (
    <>
      {localConfig?.capex.custom
        .filter((c) => c.category === category)
        .map((entry) => {
          return (
            <React.Fragment key={entry.id}>
              <Row>
                <CostInput
                  entry={entry}
                  configuration={localConfig}
                  units={units}
                  autoFocus={recentlyAddedId.current === entry.id}
                  isReadOnly={isReadOnly}
                />
              </Row>
              <DividerWrapper>
                <CostDivider />
              </DividerWrapper>
            </React.Fragment>
          );
        })}

      <Row>
        <Button
          icon={
            <IconREMSize width={1.2} height={1.2}>
              <Add />
            </IconREMSize>
          }
          buttonType="text"
          text="Add cost"
          onClick={handleAddCost}
          disabled={isReadOnly}
        />
      </Row>
    </>
  );
};
