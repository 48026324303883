import { atom, atomFamily, selectorFamily } from "recoil";
import { z } from "zod";
import { loggedInUserSelector } from "state/user";
import { projectBranchesAtomFamily } from "state/timeline";
import { dedup } from "utils/utils";
import { sendInfo } from "utils/sentry";
import { BranchMeta } from "types/api";
import { isDefined } from "utils/predicates";
import { branchIdSelector } from "state/pathParams";

const OPENED_BRANCHES_LS_KEY = "vind:opened-branches";
const getOpenedBranchesLocalStorageKey = (nodeId: string, userId: string) =>
  OPENED_BRANCHES_LS_KEY.concat("-", nodeId, "-", userId);

export const openedBranchIdsTabsAtomFamily = atomFamily<
  string[],
  { nodeId: string }
>({
  key: "openedBranchIdsTabsAtomFamily",
  default: selectorFamily({
    key: "openedBranchIdsTabsAtomFamily.default",
    get:
      ({ nodeId }) =>
      ({ get }) => {
        const userId = get(loggedInUserSelector)?.user_id;
        const existingBranches = get(projectBranchesAtomFamily({ nodeId }));
        const currentBranchId = get(branchIdSelector);

        if (
          typeof window === "undefined" ||
          !("localStorage" in window) ||
          !nodeId ||
          !userId
        ) {
          return [];
        }

        const key = getOpenedBranchesLocalStorageKey(nodeId, userId);

        try {
          const storageItem = localStorage.getItem(key);
          if (storageItem) {
            const result = JSON.parse(storageItem);
            const openedItems = z
              .string()
              .array()
              .parse(result)
              .filter((row) => {
                return existingBranches.some((branch) => branch.id === row);
              });

            if (openedItems.length > 0) {
              if (currentBranchId) {
                return dedup(openedItems.concat(currentBranchId));
              }
              return openedItems;
            }
          }
        } catch (err) {
          sendInfo("Could not read local storage", {
            key: key,
            err,
          });
        }

        if (currentBranchId) {
          return [currentBranchId];
        }
        return [];
      },
  }),
  effects: ({ nodeId }) => [
    ({ onSet, getPromise }) => {
      onSet(async (newValue) => {
        const userId = (await getPromise(loggedInUserSelector))?.user_id;

        if (
          typeof window === "undefined" ||
          !("localStorage" in window) ||
          !nodeId ||
          !userId
        ) {
          return;
        }

        const key = getOpenedBranchesLocalStorageKey(nodeId, userId);
        try {
          if (newValue.length === 0) {
            localStorage.removeItem(key);
            return;
          }

          localStorage.setItem(key, JSON.stringify(newValue));
        } catch (err) {
          sendInfo("Could not set local storage", {
            key: key,
            newValue,
          });
        }
      });
    },
  ],
});

export const openedBranchesTabsSelectorFamily = selectorFamily<
  BranchMeta[],
  { nodeId: string }
>({
  key: "openedBranchesTabsSelectorFamily",
  get:
    ({ nodeId }) =>
    ({ get }) => {
      const existingBranches = get(projectBranchesAtomFamily({ nodeId }));
      const openedBranches = get(openedBranchIdsTabsAtomFamily({ nodeId }));

      return openedBranches
        .map((row) => {
          return existingBranches.find((branch) => branch.id === row);
        })
        .filter(isDefined);
    },
});

export const allBranchesFrameOpenedAtom = atom<boolean>({
  key: "allBranchesFrameOpenedAtom",
  default: false,
});

export const allBranchesFrameLoadingAtom = atom<boolean>({
  key: "allBranchesFrameLoadingAtom",
  default: false,
});
