import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { mapRefAtom } from "../state/map";

const useIsCameraIn3D = (): boolean => {
  const map = useRecoilValue(mapRefAtom);
  const [isCameraIn3DView, setisCameraIn3DView] = useState(
    map ? map.getPitch() > 0 : false,
  );

  useEffect(() => {
    if (!map) return;
    const onCameraPitch = () => {
      const pitch = map.getPitch();
      setisCameraIn3DView(pitch > 0);
    };
    map.on("pitch", onCameraPitch);

    return () => {
      map.off("pitch", onCameraPitch);
    };
  }, [map]);

  return isCameraIn3DView;
};

export default useIsCameraIn3D;
