/**
 * This file is generated from spec.yaml.  Do not edit it directly.
 * To regenerate it, run `npm run gen`.
 */
import * as z from "zod";

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Components {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  export namespace Schemas {
    export const Group = z
      .object({
        author: z.string().optional(),
        start: z.number(),
        end: z.number(),
        meta: z
          .object({
            count: z.number(),
          })
          .passthrough(),
      })
      .passthrough();
    export const PartialMooringLineType = z
      .object({
        id: z.string().optional(),
        name: z.string().optional(),
        material: z.string().optional(),
        diameter: z.number().optional(),
        EA: z.number().optional(),
        wetWeight: z.number().optional(),
        cost: z.number().optional(),
        costUnit: z.literal("m€/km").optional(),
        note: z.string().optional(),
      })
      .passthrough();
    export const MooringLineType = z
      .object({
        id: z.string(),
        name: z.string(),
        material: z.string(),
        diameter: z.number(),
        EA: z.number(),
        wetWeight: z.number(),
        cost: z.number(),
        costUnit: z.literal("m€/km"),
        note: z.string().optional(),
      })
      .passthrough()
      .and(z.object({}).passthrough());
    export const MooringLineVersion = z
      .object({
        author: z.string().optional(),
        createdAt: z.number(),
        version: z.number(),
        mooringLine: MooringLineType,
      })
      .passthrough();
    export const PartialSubstationType = z
      .object({
        id: z.string().optional(),
        name: z.string().optional(),
        cost: z.number().optional(),
        costUnit: z
          .union([z.literal("m€/unit"), z.literal("k€/MW")])
          .optional(),
        type: z.union([z.literal("onshore"), z.literal("offshore")]).optional(),
        impedance: z.number().optional(),
        resistance: z.number().optional(),
        ironLosses: z.number().optional(),
        noLoadCurrent: z.number().optional(),
        reactor: z.boolean().optional(),
        compensationShare: z.number().optional(),
        note: z.string().optional(),
      })
      .passthrough();
    export const SubstationType = z
      .object({
        id: z.string(),
        name: z.string(),
        cost: z.number(),
        costUnit: z.union([z.literal("m€/unit"), z.literal("k€/MW")]),
        type: z.union([z.literal("onshore"), z.literal("offshore")]),
        impedance: z.number(),
        resistance: z.number(),
        ironLosses: z.number(),
        noLoadCurrent: z.number(),
        reactor: z.boolean().optional(),
        compensationShare: z.number().optional(),
        note: z.string().optional(),
      })
      .passthrough()
      .and(z.object({}).passthrough());
    export const SubstationVersion = z
      .object({
        author: z.string().optional(),
        createdAt: z.number(),
        version: z.number(),
        substation: SubstationType,
      })
      .passthrough();
    export const PartialCableType = z
      .object({
        id: z.string().optional(),
        name: z.string().optional(),
        powerRating: z.number().optional(),
        /** @Deprecated */
        costPerKilometer: z.number().optional(),
        voltage: z.number().optional().default(66),
        reactance: z.number().optional(),
        capacitance: z.number().optional(),
        resistance: z.number().optional(),
        ampacity: z.number().optional(),
        note: z.string().optional(),
        cost: z.number().optional().default(0),
        costUnit: z.string().optional().default("m€/km"),
        /** If the cable is an export cable, this field should be set to either alternative. If it is an inter-array cable, it should not be set. */
        exportCableType: z
          .union([z.literal("HVAC"), z.literal("HVDC")])
          .optional(),
      })
      .passthrough();
    export const CableType = z
      .object({
        id: z.string(),
        name: z.string(),
        powerRating: z.number(),
        /** @Deprecated */
        costPerKilometer: z.number().optional(),
        voltage: z.number().default(66),
        reactance: z.number(),
        capacitance: z.number(),
        resistance: z.number(),
        ampacity: z.number(),
        note: z.string().optional(),
        cost: z.number().optional().default(0),
        costUnit: z.string().optional().default("m€/km"),
        /** If the cable is an export cable, this field should be set to either alternative. If it is an inter-array cable, it should not be set. */
        exportCableType: z
          .union([z.literal("HVAC"), z.literal("HVDC")])
          .optional(),
      })
      .passthrough()
      .and(z.object({}).passthrough());
    export const CableTypePost = z
      .object({
        id: z.string().optional(),
        name: z.string(),
        powerRating: z.number().optional(),
        /** @Deprecated */
        costPerKilometer: z.number().optional(),
        voltage: z.number().optional().default(66),
        reactance: z.number().optional(),
        capacitance: z.number().optional(),
        resistance: z.number().optional(),
        ampacity: z.number().optional(),
        note: z.string().optional(),
        cost: z.number().optional().default(0),
        costUnit: z.string().optional().default("m€/km"),
        /** If the cable is an export cable, this field should be set to either alternative. If it is an inter-array cable, it should not be set. */
        exportCableType: z
          .union([z.literal("HVAC"), z.literal("HVDC")])
          .optional(),
      })
      .passthrough()
      .and(z.object({}).passthrough());
    export const CableTypeOrgPost = z
      .object({
        cable: CableTypePost,
        projectAccess: z.array(z.string()).optional(),
      })
      .passthrough();
    export const CableTypeUpdate = PartialCableType;
    export const CableTypeVersion = z
      .object({
        author: z.string().optional(),
        createdAt: z.number(),
        version: z.number(),
        cable: CableType,
      })
      .passthrough();
    export const WakeAnalysisConfiguration = z
      .object({
        wakeModel: z.union([z.literal("jensen"), z.literal("turbopark")]),
        wakeExpansionFactor: z.number(),
        turbulenceIntensity: z.number(),
        turboparkWakeExpansionCalibration: z.number(),
        blockage: z.boolean(),
        neighbourWakeMaxDistance: z.number().optional().default(20),
        neighbourWake: z.boolean().optional().default(true),
        density: z.union([z.number(), z.string()]),
        precision: z
          .union([z.literal("high"), z.literal("default"), z.literal("fast")])
          .optional()
          .default("default"),
      })
      .passthrough();
    export const ElectricalConfig = z
      .object({
        interArrayCableLoss: z.boolean().optional().default(false),
        turbineTrafoLoss: z.boolean().optional().default(false),
        exportSystemLoss: z.boolean().optional().default(false),
        cableLengthContingencyEnabled: z.boolean().optional().default(false),
        cableLengthContingency: z.number().optional().default(0),
      })
      .passthrough();
    export const EnergyLoss = z
      .object({
        id: z.string(),
        name: z.string(),
        value: z.number(),
      })
      .passthrough();
    export const Configuration = z
      .object({
        id: z.string(),
        name: z.string().optional(),
        description: z.string().optional(),
        wakeAnalysis: WakeAnalysisConfiguration,
        electrical: z
          .object({
            interArrayCableLoss: z.boolean().optional().default(false),
            turbineTrafoLoss: z.boolean().optional().default(false),
            exportSystemLoss: z.boolean().optional().default(false),
            cableLengthContingencyEnabled: z
              .boolean()
              .optional()
              .default(false),
            cableLengthContingency: z.number().optional().default(0),
          })
          .passthrough()
          .default({
            interArrayCableLoss: false,
            turbineTrafoLoss: false,
            exportSystemLoss: false,
          }),
        energyLosses: z.array(EnergyLoss),
      })
      .passthrough();
    export const ConfigurationNew = z
      .object({
        name: z.string().optional(),
        description: z.string().optional(),
        wakeAnalysis: WakeAnalysisConfiguration.optional(),
        electrical: ElectricalConfig.optional(),
        energyLosses: z.array(EnergyLoss).optional(),
      })
      .passthrough();
    export const ConfigurationNewForOrg = z
      .object({
        configuration: ConfigurationNew.optional(),
        projectAccess: z.array(z.string()).optional(),
      })
      .passthrough();
  }
}
export const components = {
  schemas: {
    Group: Components.Schemas.Group,
    PartialMooringLineType: Components.Schemas.PartialMooringLineType,
    MooringLineType: Components.Schemas.MooringLineType,
    MooringLineVersion: Components.Schemas.MooringLineVersion,
    PartialSubstationType: Components.Schemas.PartialSubstationType,
    SubstationType: Components.Schemas.SubstationType,
    SubstationVersion: Components.Schemas.SubstationVersion,
    PartialCableType: Components.Schemas.PartialCableType,
    CableType: Components.Schemas.CableType,
    CableTypePost: Components.Schemas.CableTypePost,
    CableTypeOrgPost: Components.Schemas.CableTypeOrgPost,
    CableTypeUpdate: Components.Schemas.CableTypeUpdate,
    CableTypeVersion: Components.Schemas.CableTypeVersion,
    WakeAnalysisConfiguration: Components.Schemas.WakeAnalysisConfiguration,
    ElectricalConfig: Components.Schemas.ElectricalConfig,
    EnergyLoss: Components.Schemas.EnergyLoss,
    Configuration: Components.Schemas.Configuration,
    ConfigurationNew: Components.Schemas.ConfigurationNew,
    ConfigurationNewForOrg: Components.Schemas.ConfigurationNewForOrg,
  },
};

export const paths = {
  "/api/mooring/node/{nodeId}": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      responses: {
        200: {
          /** List of mooring lines */
          "application/json": z.array(Components.Schemas.MooringLineType),
        },
        404: {
          /** Illegal node Id */
          "application/json": z.string(),
        },
      },
    },
    post: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      /** Mooring line to create */
      requestBody: {
        "application/json": Components.Schemas.PartialMooringLineType,
      },
      responses: {
        200: {
          /** Newly created mooring line */
          "application/json": Components.Schemas.MooringLineType,
        },
        400: {
          /** Missing user ID */
          "application/json": z.string(),
        },
        404: {
          /** Illegal node Id */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/mooring/node/{nodeId}/key_versions": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      responses: {
        200: {
          /** List of groups */
          "application/json": z.array(Components.Schemas.Group),
        },
        400: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/mooring/node/{nodeId}/type/{mooringLineId}": {
    post: {
      pathParams: z.object({
        nodeId: z.string(),
        mooringLineId: z.string(),
      }),
      /** Mooring line to update */
      requestBody: {
        "application/json": Components.Schemas.PartialMooringLineType,
      },
      responses: {
        200: {
          /** List of mooring lines */
          "application/json": Components.Schemas.MooringLineType,
        },
        400: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
    delete: {
      pathParams: z.object({
        nodeId: z.string(),
        mooringLineId: z.string(),
      }),
      responses: {
        200: {
          /** Success string */
          "application/json": z.string(),
        },
        400: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
        404: {
          /** Mooring line not found */
          "application/json": z.string(),
        },
        409: {
          /** Mooring line already archived */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/mooring/node/{nodeId}/type/{mooringLineId}/key_versions": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
        mooringLineId: z.string(),
      }),
      responses: {
        200: {
          /** List of mooring lines */
          "application/json": z.array(Components.Schemas.MooringLineVersion),
        },
        400: {
          /** Something went wrong. */
          "application/json": z
            .object({
              message: z.string().optional(),
            })
            .passthrough(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/substation/node/{nodeId}": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      responses: {
        200: {
          /** List of substations */
          "application/json": z.array(Components.Schemas.SubstationType),
        },
        404: {
          /** Illegal node Id */
          "application/json": z.string(),
        },
      },
    },
    post: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      /** Substation to create */
      requestBody: {
        "application/json": Components.Schemas.PartialSubstationType,
      },
      responses: {
        200: {
          /** Newly created substation */
          "application/json": Components.Schemas.SubstationType,
        },
        400: {
          /** Missing user ID */
          "application/json": z.string(),
        },
        404: {
          /** Illegal node Id */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/substation/node/{nodeId}/key_versions": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      responses: {
        200: {
          /** List of groups */
          "application/json": z.array(Components.Schemas.Group),
        },
        400: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/substation/node/{nodeId}/type/{substationId}": {
    post: {
      pathParams: z.object({
        nodeId: z.string(),
        substationId: z.string(),
      }),
      /** Substation to update */
      requestBody: {
        "application/json": Components.Schemas.PartialSubstationType,
      },
      responses: {
        200: {
          /** List of substations */
          "application/json": Components.Schemas.SubstationType,
        },
        400: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
    delete: {
      pathParams: z.object({
        nodeId: z.string(),
        substationId: z.string(),
      }),
      responses: {
        200: {
          /** Success string */
          "application/json": z.string(),
        },
        400: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
        404: {
          /** Substation not found */
          "application/json": z.string(),
        },
        409: {
          /** Substation already archived */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/substation/node/{nodeId}/type/{substationId}/key_versions": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
        substationId: z.string(),
      }),
      responses: {
        200: {
          /** List of substations */
          "application/json": z.array(Components.Schemas.SubstationVersion),
        },
        400: {
          /** Something went wrong. */
          "application/json": z
            .object({
              message: z.string().optional(),
            })
            .passthrough(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/cable/node/{nodeId}": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      responses: {
        200: {
          /** List of cables */
          "application/json": z.array(Components.Schemas.CableType),
        },
        400: {
          /** Missing userId in headers */
          "application/json": z.string(),
        },
      },
    },
    post: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      /** Cable to create */
      requestBody: {
        "application/json": Components.Schemas.CableTypePost,
      },
      responses: {
        200: {
          /** Created cable */
          "application/json": Components.Schemas.CableType,
        },
        400: {
          /** Missing user ID */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/cable/organisation/{organisationId}": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      responses: {
        200: {
          /** List of org cables */
          "application/json": z.array(Components.Schemas.CableType),
        },
        400: {
          /** Missing userId in headers */
          "application/json": z.string(),
        },
      },
    },
    post: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      /** Cable to create */
      requestBody: {
        "application/json": Components.Schemas.CableTypeOrgPost,
      },
      responses: {
        200: {
          /** Created cable */
          "application/json": Components.Schemas.CableType,
        },
        400: {
          /** Missing user ID */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/cable/node/{nodeId}/key_versions": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      responses: {
        200: {
          /** List of groups */
          "application/json": z.array(Components.Schemas.Group),
        },
        400: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/cable/node/{nodeId}/type/{cableId}": {
    post: {
      pathParams: z.object({
        nodeId: z.string(),
        cableId: z.string(),
      }),
      /** Cable to update */
      requestBody: {
        "application/json": Components.Schemas.CableTypeUpdate,
      },
      responses: {
        200: {
          /** List of cables */
          "application/json": Components.Schemas.CableType,
        },
        400: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
    delete: {
      pathParams: z.object({
        nodeId: z.string(),
        cableId: z.string(),
      }),
      responses: {
        200: {
          /** Success string */
          "application/json": z.string(),
        },
        400: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
        404: {
          /** Cable not found */
          "application/json": z.string(),
        },
        409: {
          /** Cable already archived */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/cable/organisation/{organisationId}/type/{cableId}": {
    put: {
      pathParams: z.object({
        organisationId: z.string(),
        cableId: z.string(),
      }),
      /** Organisation cable to update */
      requestBody: {
        "application/json": Components.Schemas.CableTypeUpdate,
      },
      responses: {
        200: {
          /** Updated org cable */
          "application/json": Components.Schemas.CableType,
        },
        400: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
    delete: {
      pathParams: z.object({
        organisationId: z.string(),
        cableId: z.string(),
      }),
      responses: {
        200: {
          /** Success string */
          "application/json": z.string(),
        },
        400: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
        404: {
          /** Cable not found */
          "application/json": z.string(),
        },
        409: {
          /** Cable already archived */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/cable/node/{nodeId}/type/{cableId}/key_versions": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
        cableId: z.string(),
      }),
      responses: {
        200: {
          /** List of cables */
          "application/json": z.array(Components.Schemas.CableTypeVersion),
        },
        400: {
          /** Something went wrong. */
          "application/json": z
            .object({
              message: z.string().optional(),
            })
            .passthrough(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/configuration/node/{nodeId}": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      responses: {
        200: {
          /** List of configs */
          "application/json": z.array(Components.Schemas.Configuration),
        },
        400: {
          /** Something went wrong. */
          "application/json": z
            .object({
              message: z.string().optional(),
            })
            .passthrough(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/configuration/organisation/{organisationId}": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      responses: {
        200: {
          /** List of configs */
          "application/json": z.array(Components.Schemas.Configuration),
        },
        400: {
          /** Something went wrong. */
          "application/json": z
            .object({
              message: z.string().optional(),
            })
            .passthrough(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/configuration/node/{nodeId}/config": {
    post: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      responses: {
        200: {
          /** New config */
          "application/json": Components.Schemas.Configuration,
        },
        400: {
          /** Something went wrong. */
          "application/json": z
            .object({
              message: z.string().optional(),
            })
            .passthrough(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/configuration/organisation/{organisationId}/config": {
    post: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      responses: {
        200: {
          /** New config */
          "application/json": Components.Schemas.Configuration,
        },
        400: {
          /** Something went wrong. */
          "application/json": z
            .object({
              message: z.string().optional(),
            })
            .passthrough(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/configuration/node/{nodeId}/config/new": {
    post: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      /** Project config to create */
      requestBody: {
        "application/json": Components.Schemas.ConfigurationNew,
      },
      responses: {
        200: {
          /** New config */
          "application/json": Components.Schemas.Configuration,
        },
        400: {
          /** Something went wrong. */
          "application/json": z
            .object({
              message: z.string().optional(),
            })
            .passthrough(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/configuration/organisation/{organisationId}/config/new": {
    post: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      /** Library config to create */
      requestBody: {
        "application/json": Components.Schemas.ConfigurationNewForOrg,
      },
      responses: {
        200: {
          /** New config */
          "application/json": Components.Schemas.Configuration,
        },
        400: {
          /** Something went wrong. */
          "application/json": z
            .object({
              message: z.string().optional(),
            })
            .passthrough(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/configuration/node/{nodeId}/config/{configId}": {
    post: {
      pathParams: z.object({
        nodeId: z.string(),
        configId: z.string(),
      }),
      /** Library config update */
      requestBody: {
        "application/json": Components.Schemas.Configuration,
      },
      responses: {
        200: {
          /** Updated config */
          "application/json": Components.Schemas.Configuration,
        },
        400: {
          /** Something went wrong. */
          "application/json": z
            .object({
              message: z.string().optional(),
            })
            .passthrough(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
    delete: {
      pathParams: z.object({
        nodeId: z.string(),
        configId: z.string(),
      }),
      responses: {
        204: z.never(),
        400: {
          /** Something went wrong. */
          "application/json": z
            .object({
              message: z.string().optional(),
            })
            .passthrough(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/configuration/organisation/{organisationId}/config/{configId}": {
    post: {
      pathParams: z.object({
        organisationId: z.string(),
        configId: z.string(),
      }),
      /** Library config update */
      requestBody: {
        "application/json": Components.Schemas.Configuration,
      },
      responses: {
        200: {
          /** Updated config */
          "application/json": Components.Schemas.Configuration,
        },
        400: {
          /** Something went wrong. */
          "application/json": z
            .object({
              message: z.string().optional(),
            })
            .passthrough(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
    delete: {
      pathParams: z.object({
        organisationId: z.string(),
        configId: z.string(),
      }),
      responses: {
        204: z.never(),
        400: {
          /** Something went wrong. */
          "application/json": z
            .object({
              message: z.string().optional(),
            })
            .passthrough(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
};

// Generated using openapi-to-zod v0.1.47
