/// <reference types="vite-plugin-svgr/client" />
import HelpIcon from "@icons/24/Help.svg?react";
import Tooltip from "../General/Tooltip";
import { BlueHelpPill } from "./LowerRight.style";
import { LayerControlWrapper } from "../RightSide/RightSide.style";
import { Icon } from "components/General/Icons";

const LowerRightHelpOnly = () => {
  return (
    <LayerControlWrapper open={false}>
      <Tooltip text="Open help center" position="top" outerDivStyle={{}}>
        <BlueHelpPill id="help_icon_link">
          <Icon stroke="white">
            <HelpIcon />
          </Icon>
          <p>Help</p>
        </BlueHelpPill>
      </Tooltip>
    </LayerControlWrapper>
  );
};

export default LowerRightHelpOnly;
