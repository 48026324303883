/// <reference types="vite-plugin-svgr/client" />
import React, { useCallback, useEffect, useState } from "react";
import { useRecoilRefresher_UNSTABLE, useResetRecoilState } from "recoil";
import { v4 as uuid } from "uuid";
import { useToast } from "hooks/useToast";
import ArrowLeftIcon from "@icons/24/ArrowLeft.svg?react";
import { useTypedPath } from "state/pathParams";
import {
  isVortexGridFormat,
  useRenameUploadedWindData,
  useUploadWindTimeseriesData,
  useUploadWindGridData,
  useUploadWRGData,
  UploadWindDataFileSizeTooLargeError,
} from "state/uploadWindData";
import { customWindDataWithNodeIdSelector } from "state/windStatistics";
import { sendInfo } from "utils/sentry";
import Button from "components/General/Button";
import { InputNumber } from "components/General/Input";
import { getFileTypeFromFileName } from "../../../utils";
import { readFileAsTextOrArrayBuffer } from "components/UploadFile/fileUtils";
import { ButtonWrapper, DropFileToUpload, UploadWrapper } from "../../shared";
import { LoadProgress, SelectedFileType } from "../types";
import SelectedFile from "../SelectedFile";
import NoFileSelectedWrapper from "./NoFileSelectedWrapper";
import { ShowGridFile } from "./PreviewGrid";
import { ShowWRGFile } from "./PreviewWRG";
import { PreviewTimeSeries } from "./PreviewTimeseries";
import { Row } from "components/General/Layout";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";

const IMAGE_PROGRESS_BEFORE_HEIGHT = 10;
export const acceptedWindDataFileEndings = [".csv", ".txt", ".asc", ".wrg"];

const UploadWindData = ({
  initialFiles,
  resetInitialFiles,
  onBackClick,
  onDoneClick,
}: {
  initialFiles: File[];
  resetInitialFiles(): void;
  onBackClick(): void;
  onDoneClick(): void;
}) => {
  const [gridHeightInput, setGridHeightInput] = useState<number>(100);
  const [utmZone, setUtmZone] = useState<number>(31);
  const [info, setInfo] = useState<string>();
  const [fileToBeUploaded, setFileToBeUploaded] = useState<SelectedFileType>();
  const { projectId } = useTypedPath("projectId");
  const [selectedFiles, setSelectedFiles] = useState<SelectedFileType[]>([]);
  const [uploadState, setUploadState] = useState<LoadProgress>();
  // For some reason, after running set() on a state, the useRecoilRefresher_UNSTABLE hook does not work.
  // We need to reset the state before to make the refresher hook work.
  const resetWindData = useResetRecoilState(
    customWindDataWithNodeIdSelector({ nodeId: projectId }),
  );
  const refreshWindData = useRecoilRefresher_UNSTABLE(
    customWindDataWithNodeIdSelector({ nodeId: projectId }),
  );
  const { error: errorMessage } = useToast();
  const { uploadTimeseriesFile, processTimeseriesFile } =
    useUploadWindTimeseriesData();
  const { uploadGridFile, processGridFile } = useUploadWindGridData();
  const { processWRGFile, uploadWRGFile } = useUploadWRGData();
  const renameFile = useRenameUploadedWindData();

  const setUploadStateUsingId = useCallback(
    (
      fileId: string,
      progress: number | undefined,
      message?: string,
      error?: string,
      other?: Partial<LoadProgress>,
    ) => {
      setUploadState((curr) => {
        if (curr?.id !== fileId) {
          return {
            id: fileId,
            error,
            message,
            progress,
            ...other,
          };
        }
        if (curr?.id === fileId) {
          return {
            ...curr,
            error,
            message,
            progress,
            ...other,
          };
        }

        return curr;
      });
    },
    [],
  );

  const setSelectedFileUsingId = useCallback(
    (id: string, update: Partial<SelectedFileType>) => {
      setSelectedFiles((curr) => {
        return curr.map((row) => {
          if (row.id === id) {
            return {
              ...row,
              ...update,
            };
          }
          return row;
        });
      });
    },
    [],
  );

  const uploadFile = useCallback(
    async (file: SelectedFileType) => {
      const fileContents = await readFileAsTextOrArrayBuffer(file.file, "text");
      if (fileContents && isVortexGridFormat(fileContents)) {
        setFileToBeUploaded(file);
        setUploadStateUsingId(
          file.id,
          IMAGE_PROGRESS_BEFORE_HEIGHT,
          undefined,
          undefined,
          {
            waitingMessage: "Grid height (m):",
          },
        );
      } else {
        setFileToBeUploaded(file);
        setUploadStateUsingId(file.id, IMAGE_PROGRESS_BEFORE_HEIGHT);
      }

      resetWindData();
      refreshWindData();
    },
    [
      resetWindData,
      refreshWindData,
      setFileToBeUploaded,
      setUploadStateUsingId,
    ],
  );

  const handleNewFiles = useCallback(
    async (files: File[]) => {
      const acceptableFiles = files.filter((file) => {
        const fileSuffix = getFileTypeFromFileName(file.name);
        return acceptedWindDataFileEndings.includes(
          `.${fileSuffix.toLowerCase()}`,
        );
      });

      const newSelectedFiles = acceptableFiles.map((file) => ({
        file,
        fileName: file.name.replace(/\.csv$/g, ""),
        id: uuid(),
      }));

      setSelectedFiles(newSelectedFiles);
      newSelectedFiles.length === 1 && uploadFile(newSelectedFiles[0]);
    },
    [uploadFile],
  );

  useEffect(() => {
    if (initialFiles.length > 0) {
      handleNewFiles(initialFiles);
      resetInitialFiles();
    }
  }, [resetInitialFiles, handleNewFiles, initialFiles]);

  const getRenameWindDataCallback = useCallback(
    (loadProgress?: LoadProgress) => {
      if (loadProgress?.progress !== 100 || !loadProgress?.serverFileName) {
        return undefined;
      }

      return async (newName: string) => {
        setSelectedFileUsingId(loadProgress.id, {
          fileName: newName,
        });
        renameFile(loadProgress!.serverFileName!, newName).catch(() => {
          errorMessage("Failed to rename wind data file");
        });
      };
    },
    [errorMessage, renameFile, setSelectedFileUsingId],
  );

  if (selectedFiles.length === 0 || !fileToBeUploaded) {
    return (
      <UploadWrapper>
        <DropFileToUpload
          acceptedFileTypes={acceptedWindDataFileEndings}
          handleNewFiles={handleNewFiles}
        />
        <NoFileSelectedWrapper />
        <Button
          buttonType="text"
          text="Back"
          icon={<ArrowLeftIcon />}
          onClick={onBackClick}
          style={{
            paddingLeft: 0,
          }}
        />
      </UploadWrapper>
    );
  }

  if (selectedFiles.length > 1) {
    return (
      <UploadWrapper>
        <DropFileToUpload
          acceptedFileTypes={acceptedWindDataFileEndings}
          handleNewFiles={(files) => {
            resetInitialFiles();
            handleNewFiles(files);
          }}
        />
        <SimpleAlert type="info">
          We only support one wind data file for uploading at the time. Please
          select a single file.
        </SimpleAlert>
        <Button
          buttonType="text"
          text="Back"
          icon={<ArrowLeftIcon />}
          onClick={onBackClick}
          style={{
            paddingLeft: 0,
          }}
        />
      </UploadWrapper>
    );
  }

  const fileType = fileToBeUploaded.file.name.slice(-3);
  const isTimeSeries = fileType === "csv" || fileType === "txt";
  const isWRG = fileType === "wrg";
  const isGrid = fileType === "asc";

  return (
    <UploadWrapper>
      {isGrid && (
        <ShowGridFile
          data={processGridFile(fileToBeUploaded.file, gridHeightInput)}
        />
      )}
      {isWRG && (
        <ShowWRGFile
          data={processWRGFile(
            fileToBeUploaded.file,
            utmZone,
            errorMessage,
            setInfo,
          )}
        />
      )}
      {isTimeSeries && (
        <PreviewTimeSeries
          data={processTimeseriesFile(fileToBeUploaded.file)}
        />
      )}

      {info && (
        <Row>
          <SimpleAlert type="info">{info}</SimpleAlert>
        </Row>
      )}

      <SelectedFile
        key={fileToBeUploaded.id}
        fileName={fileToBeUploaded.fileName!}
        fileSize={fileToBeUploaded.file.size}
        loadProgress={uploadState}
        onRename={getRenameWindDataCallback(uploadState)}
      >
        {isGrid && (
          <>
            <InputNumber
              style={{ width: "10rem" }}
              placeholder="Height (m)"
              validate={(n) => 50 <= n && n <= 300}
              validationMessage={`Needs to be within 50-300 meters`}
              onChange={(n) => setGridHeightInput(n)}
              autoFocus
            />
            <Button
              buttonType="primary"
              text="Done"
              onClick={() => {
                setUploadStateUsingId(
                  fileToBeUploaded.id,
                  20,
                  "Uploading",
                  undefined,
                  {
                    waitingMessage: "",
                  },
                );
                uploadGridFile(fileToBeUploaded.file, gridHeightInput)
                  .then((value) => {
                    if (!value) return;
                    resetWindData();
                    refreshWindData();
                    setSelectedFileUsingId(fileToBeUploaded.id, {
                      fileName: value.file.name,
                    });
                    setUploadStateUsingId(
                      fileToBeUploaded.id,
                      100,
                      "Done",
                      undefined,
                      {
                        serverFileName: value.file.id,
                      },
                    );
                  })
                  .catch((error) => {
                    if (error instanceof UploadWindDataFileSizeTooLargeError) {
                      errorMessage(error.message);
                    } else {
                      sendInfo(
                        "Something went wrong when uploading wind data",
                        {
                          error,
                        },
                      );
                    }
                    setUploadStateUsingId(
                      fileToBeUploaded.id,
                      undefined,
                      undefined,
                      error.message,
                    );
                  });
              }}
            />
          </>
        )}
        {isWRG && (
          <>
            <InputNumber
              style={{ width: "10rem" }}
              placeholder="UTM zone"
              validate={(n) => 0 <= n && n <= 60}
              validationMessage={`Needs to be within 0-60`}
              onChange={(n) => setUtmZone(n)}
              autoFocus
            />
            <Button
              buttonType="primary"
              text="Upload"
              disabled={uploadState?.progress !== IMAGE_PROGRESS_BEFORE_HEIGHT}
              onClick={() => {
                setUploadStateUsingId(
                  fileToBeUploaded.id,
                  20,
                  "Uploading",
                  undefined,
                  {
                    waitingMessage: "",
                  },
                );
                // Upload file here
                uploadWRGFile(fileToBeUploaded.file, utmZone, errorMessage)
                  .then((value) => {
                    if (!value) return;
                    resetWindData();
                    refreshWindData();
                    setSelectedFileUsingId(fileToBeUploaded.id, {
                      fileName: value.file.name,
                    });
                    setUploadStateUsingId(
                      fileToBeUploaded.id,
                      100,
                      "Done",
                      undefined,
                      {
                        serverFileName: value.file.id,
                      },
                    );
                  })
                  .catch((error) => {
                    if (error instanceof UploadWindDataFileSizeTooLargeError) {
                      errorMessage(error.message);
                    } else {
                      sendInfo(
                        "Something went wrong when uploading wind data",
                        {
                          error,
                        },
                      );
                    }
                    setUploadStateUsingId(
                      fileToBeUploaded.id,
                      undefined,
                      undefined,
                      error.message,
                    );
                  });
              }}
            />
          </>
        )}
        {isTimeSeries && (
          <Button
            buttonType="primary"
            text="Upload"
            disabled={uploadState?.progress !== IMAGE_PROGRESS_BEFORE_HEIGHT}
            onClick={async () => {
              setUploadStateUsingId(
                fileToBeUploaded.id,
                20,
                "Uploading",
                undefined,
                {
                  waitingMessage: "",
                },
              );

              await uploadTimeseriesFile(fileToBeUploaded.file)
                .then((value) => {
                  if (!value) return;
                  setSelectedFileUsingId(fileToBeUploaded.id, {
                    fileName: value.file.name,
                  });
                  setUploadStateUsingId(
                    fileToBeUploaded.id,
                    100,
                    "Done",
                    undefined,
                    {
                      serverFileName: value.file.id,
                    },
                  );
                })
                .catch((error) => {
                  if (error instanceof UploadWindDataFileSizeTooLargeError) {
                    errorMessage(error.message);
                  } else {
                    sendInfo("Something went wrong when uploading wind data", {
                      error,
                    });
                  }
                  setUploadStateUsingId(
                    fileToBeUploaded.id,
                    undefined,
                    undefined,
                    error.message,
                  );
                });
            }}
          />
        )}
      </SelectedFile>
      <ButtonWrapper>
        <Button
          buttonType="text"
          text="Back"
          icon={<ArrowLeftIcon />}
          onClick={onBackClick}
          style={{
            paddingLeft: 0,
          }}
        />
        <ButtonWrapper>
          <Button text="Close" buttonType="text" onClick={onDoneClick} />
          <Button
            buttonType="primary"
            text="Done"
            onClick={onDoneClick}
            disabled={
              uploadState?.error !== undefined || uploadState?.progress !== 100
            }
          />
        </ButtonWrapper>
      </ButtonWrapper>
    </UploadWrapper>
  );
};

export default UploadWindData;
