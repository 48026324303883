import React from "react";
import {
  AnalysisPrecision,
  Configuration,
  WAKE_MODEL_NAME,
  WakeModel,
} from "services/configurationService";
import { InputDimensioned, InputNumber } from "components/General/Input";
import Toggle, { ToggleSize } from "components/General/Toggle";
import { Column } from "components/General/Layout";
import Radio, { RadioGroup } from "components/General/Radio";
import { isNumber } from "utils/predicates";
import { AIR_DENSITY } from "@constants/metocean";
import { Grid2, Label } from "components/General/Form";
import { Text } from "components/SettingsV2/Shared/styles";
import HelpTooltip, {
  ARTICLE_AIR_DENSITY,
  ARTICLE_WAKE_MODELS,
  ARTICLE_YIELD_ASSESSMENT,
  HelpLink,
} from "components/HelpTooltip/HelpTooltip";
import Dropdown from "components/Dropdown/Dropdown";
import { ElementsContainer, Row, SubHeader } from "../shared";
import { between } from "utils/validations";

const MAX_DISTANCE_NEIGHBOURS = 75;

const WakeSettingsTab = ({
  isReadOnly,
  localConfig,
  setLocalConfig,
}: {
  isReadOnly?: boolean;
  localConfig: Configuration;
  setLocalConfig: React.Dispatch<React.SetStateAction<Configuration>>;
}) => {
  const density = isNumber(localConfig.wakeAnalysis.density)
    ? localConfig.wakeAnalysis.density
    : AIR_DENSITY;

  return (
    <ElementsContainer>
      <SubHeader>Wake settings</SubHeader>
      <Label htmlFor="not-help-link">
        <Row>
          <Text>Wake model:</Text>
          <HelpLink article={ARTICLE_WAKE_MODELS} />
        </Row>
        <Dropdown
          disabled={isReadOnly}
          id="wake-model"
          value={localConfig.wakeAnalysis.wakeModel}
          onChange={(e) => {
            setLocalConfig((curr) => ({
              ...curr,
              wakeAnalysis: {
                ...curr.wakeAnalysis,
                wakeModel: e.target.value as WakeModel,
              },
            }));
          }}
          style={{ width: "16rem" }}
        >
          <option key={"jensen"} value={"jensen"}>
            {WAKE_MODEL_NAME.jensen}
          </option>
          <option key={"turbopark"} value={"turbopark"}>
            {WAKE_MODEL_NAME.turbopark}
          </option>
        </Dropdown>
      </Label>

      {["jensen"].includes(localConfig.wakeAnalysis.wakeModel) && (
        <Label>
          <Text>Wake decay constant:</Text>
          <InputNumber
            disabled={isReadOnly}
            blurOnEnter={false}
            value={localConfig.wakeAnalysis.wakeExpansionFactor}
            validate={(n) => 0.01 <= n && n <= 0.2}
            validationMessage={`Needs to be within 0.01 - 0.2`}
            step={0.01}
            onChange={(n) => {
              setLocalConfig((curr) => ({
                ...curr,
                wakeAnalysis: {
                  ...curr.wakeAnalysis,
                  wakeExpansionFactor: n,
                },
              }));
            }}
            style={{ width: "16rem" }}
          />
        </Label>
      )}
      {["turbopark"].includes(localConfig.wakeAnalysis.wakeModel) && (
        <Label>
          <Text>Turbulence intensity:</Text>
          <InputNumber
            disabled={isReadOnly}
            blurOnEnter={false}
            value={localConfig.wakeAnalysis.turbulenceIntensity}
            validate={(n) => 0.01 <= n && n <= 0.2}
            validationMessage={`Needs to be within 0.01 - 0.2`}
            step={0.01}
            onChange={(n) => {
              setLocalConfig((curr) => ({
                ...curr,
                wakeAnalysis: {
                  ...curr.wakeAnalysis,
                  turbulenceIntensity: n,
                },
              }));
            }}
            style={{ width: "16rem" }}
          />
        </Label>
      )}
      {["turbopark"].includes(localConfig.wakeAnalysis.wakeModel) && (
        <Label>
          <Text>Calibration parameter (A):</Text>
          <InputNumber
            disabled={isReadOnly}
            blurOnEnter={false}
            value={localConfig.wakeAnalysis.turboparkWakeExpansionCalibration}
            validate={(n) => 0.01 <= n && n <= 0.1}
            validationMessage={`Needs to be within 0.01 - 0.1`}
            step={0.01}
            onChange={(n) => {
              setLocalConfig((curr) => ({
                ...curr,
                wakeAnalysis: {
                  ...curr.wakeAnalysis,
                  turboparkWakeExpansionCalibration: n,
                },
              }));
            }}
            style={{ width: "16rem" }}
          />
        </Label>
      )}

      <Row style={{ width: "20rem", justifyContent: "space-between" }}>
        <Row>
          <Text>Include blockage:</Text>
          <HelpLink article={ARTICLE_WAKE_MODELS} />
        </Row>
        <Toggle
          checked={localConfig.wakeAnalysis.blockage}
          disabled={isReadOnly}
          onChange={() => {
            setLocalConfig((curr) => ({
              ...curr,
              wakeAnalysis: {
                ...curr.wakeAnalysis,
                blockage: !curr.wakeAnalysis.blockage,
              },
            }));
          }}
          size={ToggleSize.SMALL}
        />
      </Row>
      <Grid2
        style={{
          gridTemplateColumns: "20rem 10rem",
          gap: "1rem",
        }}
      >
        <Row style={{ width: "20rem", justifyContent: "space-between" }}>
          <Text>Neighbour wake loss:</Text>
          <Toggle
            checked={localConfig.wakeAnalysis.neighbourWake}
            disabled={isReadOnly}
            onChange={() =>
              setLocalConfig((curr) => ({
                ...curr,
                wakeAnalysis: {
                  ...curr.wakeAnalysis,
                  neighbourWake: !curr.wakeAnalysis.neighbourWake,
                },
              }))
            }
            size={ToggleSize.SMALL}
          />
        </Row>
        <Row>
          <InputDimensioned
            disabled={isReadOnly || !localConfig.wakeAnalysis.neighbourWake}
            validate={between(0, MAX_DISTANCE_NEIGHBOURS)}
            validationMessage={`Needs to be within 0 - ${MAX_DISTANCE_NEIGHBOURS}`}
            step="5"
            unit="km"
            type="number"
            value={localConfig.wakeAnalysis.neighbourWakeMaxDistance}
            onChange={(val) =>
              setLocalConfig((curr) => ({
                ...curr,
                wakeAnalysis: {
                  ...curr.wakeAnalysis,
                  neighbourWakeMaxDistance: val,
                },
              }))
            }
            style={{ width: "7rem" }}
          />
          <HelpTooltip text="The maximum distance between the park and neighbour/existing turbines" />
        </Row>
      </Grid2>
      <Label htmlFor="not-help-link">
        <Column style={{ gap: "0.4rem" }}>
          <Row>
            <Text>Air density:</Text>
            <HelpLink article={ARTICLE_AIR_DENSITY} />
          </Row>
          <Row>
            <RadioGroup>
              <Radio
                name="density_compensation"
                value=""
                disabled={isReadOnly}
                checked={isNumber(localConfig.wakeAnalysis.density)}
                onChange={() =>
                  setLocalConfig((curr) => ({
                    ...curr,
                    wakeAnalysis: {
                      ...curr.wakeAnalysis,
                      density: density,
                    },
                  }))
                }
              />
              <InputDimensioned
                disabled={isReadOnly}
                validate={between(0.7, 1.8)}
                step="0.001"
                unit="kg/m3"
                type="number"
                value={density}
                onChange={(val) =>
                  setLocalConfig((curr) => ({
                    ...curr,
                    wakeAnalysis: {
                      ...curr.wakeAnalysis,
                      density: val,
                    },
                  }))
                }
                style={{ width: "10rem" }}
              />
            </RadioGroup>
            <RadioGroup>
              <Radio
                name="density_compensation"
                value="mean_at_location"
                disabled={isReadOnly}
                checked={
                  localConfig.wakeAnalysis.density === "mean_at_location"
                }
                onChange={() =>
                  setLocalConfig((curr) => ({
                    ...curr,
                    wakeAnalysis: {
                      ...curr.wakeAnalysis,
                      density: "mean_at_location",
                    },
                  }))
                }
              />
              <p>Closest mean ERA5</p>
            </RadioGroup>
          </Row>
        </Column>
      </Label>
      <Label htmlFor="not-help-link">
        <Column style={{ gap: "0.4rem" }}>
          <Row>
            <Text>Analysis precision:</Text>
            <HelpLink article={ARTICLE_YIELD_ASSESSMENT} />
          </Row>
          <Dropdown
            disabled={isReadOnly}
            id="analysis-precision"
            value={localConfig.wakeAnalysis.precision}
            onChange={(e) => {
              setLocalConfig((curr) => ({
                ...curr,
                wakeAnalysis: {
                  ...curr.wakeAnalysis,
                  precision: e.target.value as AnalysisPrecision,
                },
              }));
            }}
            style={{ width: "16rem" }}
          >
            <option key={"fast"} value={"fast"}>
              {"Fast"}
            </option>
            <option key={"default"} value={"default"}>
              {"Default"}
            </option>
            <option key={"high"} value={"high"}>
              {"High"}
            </option>
          </Dropdown>
        </Column>
      </Label>
    </ElementsContainer>
  );
};

export default WakeSettingsTab;
