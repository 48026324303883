import { cableTypeAtomFamily } from "./../../../state/cableType";
import { atom, atomFamily, selector, selectorFamily } from "recoil";
import { z } from "zod";
import { _CableType, CableType } from "../../../services/cableTypeService";
import { currentVersionSelector } from "../../../state/project";
import { projectIdSelector } from "../../../state/pathParams";
import { TurbineFeature } from "types/feature";
import { getDivisionFeaturesSelectorFamily } from "state/division";
import { getTurbinesSelectorFamily } from "state/layout";
import { currentSelectedProjectFeatures } from "state/selection";
import { pointInPolygon } from "utils/geometry";
import { isCable, isSubArea, isPointFeature } from "utils/predicates";
import { projectFeatureMapInBranch } from "state/projectLayers";
import { lonLatToTile } from "types/tile";
import { getBathymetryTile } from "state/bathymetry";
import { CableTypeWithLevel, _CableLevel } from "types/cables";
import { dedup } from "utils/utils";
import { getCableResourcesOnNode } from "services/libraryService";

export const DEFAULT_SUBSTATION_BUFFER = 350;
export const DEFAULT_TURBINE_BUFFER = 250;

export type CableStrategy = "optimal" | "greedy";

export const _CableSettings = z.object({
  strategy: z.enum(["optimal", "greedy"]),
  maxChainLength: z.number(),
  routeAroundMooring: z.boolean().optional(),
  routeAroundTurbines: z.boolean().optional(),
  anchorBuffer: z.number().optional(),
  mooringLineBuffer: z.number().optional(),
  turbineBuffer: z.number().optional(),
  touchdownBuffer: z.number().optional(),
  cableTypes: _CableType.array().optional(),
  optimizeSubstation: z.boolean().optional(),
  numSubstations: z.enum(["one", "two"]),
  replaceTurbines: z.boolean().optional(),
  substationBuffer: z.number().optional(),
  maxTurbinesToSubstation: z.number().optional(),

  fixedChains: z.boolean().optional(),
  fixedChainsNum: z.number().optional(),
  fixedChainsLen: z.number().optional(),
  /**
   * If the arrays should be connected in a cycle instead of a line. If `true`,
   * the longest of the two cables touching the substation will be marked as a
   * redundancy cable.
   */
  cycle: z.boolean().optional(),
});
export type CableSettings = z.infer<typeof _CableSettings>;

export const generateCablesSettingState = atom<CableSettings>({
  key: "generateCablesSettingState",
  default: {
    maxChainLength: 5,
    strategy: "optimal",
    routeAroundMooring: false,
    anchorBuffer: 100,
    mooringLineBuffer: 100,
    touchdownBuffer: 50,
    numSubstations: "one",
    substationBuffer: DEFAULT_SUBSTATION_BUFFER,
  },
});

export const projectCableTypesState = selector<CableType[]>({
  key: "projectCableTypesState",
  get: ({ get }) => {
    const projectId = get(projectIdSelector);
    if (!projectId) return [];
    const version = get(currentVersionSelector);
    const cables = get(cableTypeAtomFamily({ projectId, version })).filter(
      (c) => !c.exportCableType,
    );
    return cables.sort((a, b) => a.powerRating - b.powerRating);
  },
});

export const libraryCableRefreshAtom = atom({
  key: "libraryCableRefreshAtom",
  default: 1,
});

export const libraryCableOnNodeTypesState = selectorFamily<
  CableType[],
  { nodeId: string | undefined }
>({
  key: "libraryCableOnNodeTypesState",
  get:
    ({ nodeId }) =>
    async ({ get }) => {
      get(libraryCableRefreshAtom);
      if (!nodeId) return [];
      try {
        const allCableResourcesOnNode = await getCableResourcesOnNode(nodeId);
        const uniqueCableResourcesOnNode = dedup(
          allCableResourcesOnNode,
          (cr) => cr.cable.id,
        );
        return uniqueCableResourcesOnNode.map((cr) => cr.cable);
      } catch {
        return [];
      }
    },
});

export const allCableTypesWithLevelSelector = selector<CableTypeWithLevel[]>({
  key: "allCableTypesWithLevelSelector",
  get: ({ get }) => {
    const projectId = get(projectIdSelector);

    const projectCables = get(projectCableTypesState);

    const libraryCables = get(
      libraryCableOnNodeTypesState({ nodeId: projectId }),
    );

    const list: CableTypeWithLevel[] = [
      ...projectCables.map((c) => ({
        level: _CableLevel.Values.project,
        cable: c,
      })),
      ...libraryCables.map((c) => ({
        level: _CableLevel.Values.library,
        cable: c,
      })),
    ];

    return list;
  },
});

export const allCableTypesSelector = selector<CableType[]>({
  key: "all-cable-types-selector",
  get: ({ get }) => {
    const withLevels = get(allCableTypesWithLevelSelector);
    return withLevels.map((wl) => wl.cable);
  },
});

export const currentExportCableTypesSelector = selector<CableType[]>({
  key: "currentExportCableTypesSelector",
  get: ({ get }) => {
    const projectId = get(projectIdSelector);
    if (!projectId) return [];
    const version = get(currentVersionSelector);
    const cables = get(cableTypeAtomFamily({ projectId, version })).filter(
      (c) => c.exportCableType,
    );
    return cables.sort((a, b) => a.powerRating - b.powerRating);
  },
});

export const selectedCableIdsState = atom<string[]>({
  key: "selectedCableIdsState",
  default: [],
});

export const filteredCableTypesState = selector<CableType[]>({
  key: "filteredCableTypesState",
  get: ({ get }) => {
    const selectedCableIds = get(selectedCableIdsState);
    const cableTypes: CableType[] = get(allCableTypesSelector);

    return cableTypes.filter(
      (cable) => selectedCableIds.indexOf(cable.id) !== -1,
    );
  },
});

export const getSelTurbinesSelectorFamily = selectorFamily<
  TurbineFeature[],
  { parkId: string }
>({
  key: "selectedTurbinesState",
  get:
    ({ parkId }) =>
    ({ get }) => {
      const parkTurbines = get(getTurbinesSelectorFamily({ parkId }));
      const { subAreas } = get(getDivisionFeaturesSelectorFamily({ parkId }));
      const selectedFeatures = get(currentSelectedProjectFeatures);
      const selectedInclusionZoneIds = selectedFeatures
        .filter(isSubArea)
        .map((f) => f.id);

      if ((subAreas.length ?? []) === 0) return parkTurbines;
      const selectedOrAllInclusionZones =
        (selectedInclusionZoneIds ?? []).length === 0
          ? subAreas
          : subAreas.filter((f) => selectedInclusionZoneIds.includes(f.id));

      if (selectedInclusionZoneIds.length > 0) {
        return parkTurbines.filter((f) =>
          selectedOrAllInclusionZones.some((zone) =>
            pointInPolygon(f.geometry, zone.geometry),
          ),
        );
      }
      return parkTurbines;
    },
});

export const showAnchorBufferZoneAtom = atom<boolean>({
  key: "showAnchorBufferZoneAtom",
  default: false,
});

export const showMooringBufferZoneAtom = atom<boolean>({
  key: "showMooringBufferZoneAtom",
  default: false,
});

export const showTurbineBufferZoneAtom = atom<boolean>({
  key: "showTurbineBufferZoneAtom",
  default: false,
});

export const showTouchdownBufferZoneAtom = atom<boolean>({
  key: "showTouchdownBufferZoneAtom",
  default: false,
});

export const generateCablesLoadingTimeAtom = atom<undefined | number>({
  key: "generateCablesLoadingTimeAtom",
  default: undefined,
});

export const cableEditModeAtom = atom<boolean>({
  key: "cableEditModeAtom",
  default: false,
});

export type Submenu =
  | "mooring"
  | "turbines"
  | "substation"
  | "chains"
  | "substation-buffer";
export const openSubmenuAtom = atom<Submenu | undefined>({
  key: "openSubmenuAtom",
  default: undefined,
});

export const showPowerRatingWarningAtomFamily = atomFamily<boolean, string>({
  key: "showPowerRatingWarningAtomFamily",
  default: true,
});

export const cableDepthAtEndpoints = selectorFamily<
  [number, number] | undefined,
  { branchId: string; cableId: string }
>({
  key: "cableDepthAtEndpoints",
  get:
    ({ branchId, cableId }) =>
    ({ get }) => {
      const ZOOM = 10;

      const featureMap = get(projectFeatureMapInBranch({ branchId }));
      const cable = featureMap.get(cableId);
      if (!cable) return undefined;

      if (!isCable(cable)) return undefined;

      const from = featureMap.get(cable.properties.fromId);
      if (!from || !isPointFeature(from)) return undefined;

      const to = featureMap.get(cable.properties.toId);
      if (!to || !isPointFeature(to)) return undefined;

      const fromTile = lonLatToTile(
        from.geometry.coordinates[0],
        from.geometry.coordinates[1],
        ZOOM,
      );

      const fromRaster = get(getBathymetryTile(fromTile));

      const toTile = lonLatToTile(
        to.geometry.coordinates[0],
        to.geometry.coordinates[1],
        ZOOM,
      );
      const toRaster = get(getBathymetryTile(toTile));

      const fromDepth = fromRaster.latLngToValue(
        from.geometry.coordinates[0],
        from.geometry.coordinates[1],
      );
      const toDepth = toRaster.latLngToValue(
        to.geometry.coordinates[0],
        to.geometry.coordinates[1],
      );
      return [-fromDepth, -toDepth];
    },
});
