import { useCallback, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { useTypedPath } from "../../../state/pathParams";
import { adminInOrganisationSelectorFamily } from "../../../state/user";
import { colors } from "../../../styles/colors";
import { SkeletonBlock } from "../../Loading/Skeleton";
import { spaceMedium } from "../../../styles/space";
import Tooltip from "../../General/Tooltip";
import { createOrganisationImageUploadUrl } from "../service";
import { currentOrganisationSelector } from "../state";
import OrganisationImageSquare from "./OrganisationImageRound";
import { orgImageCounterState } from "./state";

const UserImageWithUploadWrapper = styled.div<{ size: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  width: ${(p) => p.size}rem;
  height: ${(p) => p.size}rem;

  margin: ${spaceMedium};

  .overlay {
    margin: -${spaceMedium};
    border-radius: 0.6rem;
  }
  img {
    margin: ${spaceMedium};
  }
`;

const UploadOverlay = styled.div.attrs(() => ({ className: "overlay" }))`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  transition: background 0.1s ease-in;
  justify-content: center;
  align-items: center;
  justify-items: stretch;
  border-radius: 6px;
  cursor: pointer;

  :hover {
    background: ${colors.brand}30;
  }

  > input[type="file"] {
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
  }

  > input[type="file"]::file-selector-button {
    cursor: pointer;
  }
`;

export default function OrgImageUpload({ size }: { size: number }) {
  const { organisationId } = useTypedPath("organisationId");
  const currentOrganisation = useRecoilValue(currentOrganisationSelector);
  const [loading, setLoading] = useState<boolean>(false);
  const resetImage = useSetRecoilState(orgImageCounterState);

  const isAdminInOrg = useRecoilValue(
    adminInOrganisationSelectorFamily({ organisationId }),
  );

  const _onFile = useCallback(
    async (file: File) => {
      if (!currentOrganisation) return;
      setLoading(true);
      await createOrganisationImageUploadUrl(file, currentOrganisation.id);
      setTimeout(() => {
        resetImage((curr) => curr + 1);
        setLoading(false);
      }, 4000);
    },
    [currentOrganisation, resetImage],
  );

  if (!isAdminInOrg) {
    return (
      <UserImageWithUploadWrapper size={size}>
        {!currentOrganisation ? (
          <SkeletonBlock
            style={{
              width: `${size}rem`,
              height: `${size}rem`,
              borderRadius: `0.6rem`,
            }}
          />
        ) : (
          <OrganisationImageSquare
            organisation={currentOrganisation}
            size={size}
            thumbnailSize={64}
          />
        )}
      </UserImageWithUploadWrapper>
    );
  }

  return (
    <UserImageWithUploadWrapper size={size}>
      {!currentOrganisation || loading ? (
        <SkeletonBlock
          style={{
            width: `${size}rem`,
            height: `${size}rem`,
            borderRadius: `0.6rem`,
          }}
        />
      ) : (
        <Tooltip text="Upload picture" position="bottom">
          <UploadOverlay>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (!file) return;
                _onFile(file);
              }}
            />
          </UploadOverlay>
          <OrganisationImageSquare
            organisation={currentOrganisation}
            size={size}
            thumbnailSize={256}
          />
        </Tooltip>
      )}
    </UserImageWithUploadWrapper>
  );
}
