/*eslint @typescript-eslint/no-non-null-asserted-optional-chain: "off"*/
import { fetchWithToken } from "../services/utils";
import { selectorFamily, selector, waitForAll, atom } from "recoil";
import { appendQueryParamsSign, capitalize } from "../utils/utils";
import { addCorsAndCacheProxyURL } from "./gisSourceCorsProxy";
import { fetchWithRetries } from "../services/utils";
import { newCustomDataSourceAtom } from "./newLayer";
import {
  SourceTypes,
  WfsLayer,
  WfsMetadata,
  WfsSourceEntries,
  Layer,
  ExternalDataSourceLinkLayerWithSourceWFS,
  SourceTypesLayer,
} from "../types/layers";
import { getExternalLayerId } from "../utils/externalLayers";
import { isDefined } from "../utils/predicates";
import { privateGISSourceDataWFSAPISelector } from "./privateGISSource";
import { scream } from "../utils/sentry";

export const isWfsLayer = (
  layer: Layer,
): layer is ExternalDataSourceLinkLayerWithSourceWFS => {
  return layer.sourceType === SourceTypesLayer.wfs;
};

export const wfsGetCapabilitiesSuffix =
  "SERVICE=WFS&VERSION=2.0.0&request=GetCapabilities";
const wfsDescribeFeatureTypeSuffix =
  "SERVICE=WFS&VERSION=2.0.0&request=DescribeFeatureType";

const gmlTypeToInternalType = {
  "gml:PointPropertyType": "circle",
  "gml:MultiSurfacePropertyType": "polygon",
  "gml:MultiLineStringPropertyType": "line",
  "gml:CurvePropertyType": "line",
  "gml:SurfacePropertyType": "polygon",
  "gml:GeometryPropertyType": "featureCollection",
};

export const supportedOutputFormats = ["json", "GEOJSON"];

const validGmlTypes = Object.keys(gmlTypeToInternalType);

export const getAllowedValues = (xmlDocCapabilities: Document) => {
  const parameters = Array.of(
    ...xmlDocCapabilities.getElementsByTagNameNS("*", "Operation"),
  )
    .find((e) => e.getAttribute("name") === "GetFeature")
    ?.getElementsByTagNameNS("*", "Parameter");
  const outputFormats = Array.of(...(parameters ?? []))
    .find((e) => e.getAttribute("name") === "outputFormat")
    ?.getElementsByTagNameNS("*", "Value");
  return Array.of(...(outputFormats ?? []))
    .map((e) => e.textContent)
    .filter((v) => v != null) as string[];
};

const wfsServerCapabilitiesMetadataSelector = selectorFamily<
  undefined | string,
  { url: string; privateSource: boolean | undefined }
>({
  key: "wfsServerCapabilitiesMetadataSelector",
  get:
    ({ url, privateSource }) =>
    async () => {
      try {
        const response = privateSource
          ? await fetchWithToken(
              `${url}${appendQueryParamsSign(url)}${wfsGetCapabilitiesSuffix}`,
              {
                method: "get",
              },
            )
          : await fetchWithRetries(
              `${url}${appendQueryParamsSign(url)}${wfsGetCapabilitiesSuffix}`,
              {
                method: "get",
              },
              2,
            );

        if (!response.ok) {
          return;
        }

        const xml = await response.text();
        return xml;
      } catch (err) {
        console.warn(`Could not read from WFS server: ${url}, ${err}`);
      }
    },
});

const wfsServerDescribeFeatureTypeMetadataSelector = selectorFamily<
  undefined | string,
  { url: string; privateSource: boolean | undefined }
>({
  key: "wfsServerDescribeFeatureTypeMetadataSelector",
  get:
    ({ url, privateSource }) =>
    async () => {
      try {
        const response = privateSource
          ? await fetchWithToken(
              `${url}${appendQueryParamsSign(
                url,
              )}${wfsDescribeFeatureTypeSuffix}`,
              {
                method: "get",
              },
            )
          : await fetchWithRetries(
              `${url}${appendQueryParamsSign(
                url,
              )}${wfsDescribeFeatureTypeSuffix}`,
              {
                method: "get",
              },
              2,
            );

        if (!response.ok) {
          return;
        }

        const xml = await response.text();
        return xml;
      } catch (err) {
        console.warn(`Could not read from WFS server: ${url}, ${err}`);
        return;
      }
    },
});

export const resetWfsDataStateAtom = atom<number>({
  key: "resetWfsDataStateAtom",
  default: 1,
});

export const getWfsPath = (layer: ExternalDataSourceLinkLayerWithSourceWFS) => {
  const isPrivate = "private" in layer.source ? layer.source["private"] : false;

  return `${addCorsAndCacheProxyURL(
    layer.sourceLink.url,
    isPrivate,
  )}${appendQueryParamsSign(
    layer.sourceLink.url,
  )}SERVICE=WFS&VERSION=2.0.0&request=GetFeature&OUTPUTFORMAT=${
    layer.outputValue
  }&srsName=EPSG:4326&typeName=${layer.sourceLayerId}`;
};

const wfsMetadataLayersSelector = selectorFamily<
  WfsSourceEntries | undefined,
  WfsLayer
>({
  key: "wfsMetadataLayersSelector",
  get:
    (wfsLayer) =>
    ({ get }) => {
      if (!wfsLayer.wfs_url)
        scream("URL is undefined for layer", { wfs_url: wfsLayer.wfs_url });

      const url = wfsLayer?.skipProxy
        ? wfsLayer.wfs_url
        : addCorsAndCacheProxyURL(wfsLayer.wfs_url, wfsLayer.private);
      const capabilitiesMetadataXML = get(
        wfsServerCapabilitiesMetadataSelector({
          url,
          privateSource: wfsLayer.private,
        }),
      );
      const describeFeatureMetadataXML = get(
        wfsServerDescribeFeatureTypeMetadataSelector({
          url,
          privateSource: wfsLayer.private,
        }),
      );

      if (!capabilitiesMetadataXML || !describeFeatureMetadataXML)
        return {
          url: wfsLayer.wfs_url,
          sourceType: SourceTypes.wfs,
          source: capitalize(wfsLayer.source),
          layersInfo: [],
          fetchSucceeded: false,
          alternativeNames: new Set(wfsLayer?.alternativeNames ?? []),
          keywords: [],
        };

      const parser = new DOMParser();
      const xmlDocCapabilities = parser.parseFromString(
        capabilitiesMetadataXML,
        "text/xml",
      );

      const allowedValues = getAllowedValues(xmlDocCapabilities);
      const outputValue =
        allowedValues.find((v) => supportedOutputFormats.includes(v)) ?? "json";

      const xmlDocDescribeFeatures = parser.parseFromString(
        describeFeatureMetadataXML,
        "text/xml",
      );
      const nameToType = Array.of(
        ...xmlDocDescribeFeatures.getElementsByTagNameNS("*", "element"),
      )
        .map((e) => ({
          name: e.getAttribute("name")!,
          type: e.getAttribute("type")?.split(":")[1]!,
        }))
        .reduce((acc, { name, type }) => {
          const complexType = Array.of(
            ...xmlDocDescribeFeatures.getElementsByTagNameNS(
              "*",
              "complexType",
            ),
          ).find((e) => e.getAttribute("name") === type);

          if (!complexType) return acc;
          return {
            ...acc,
            // Indexing with `'null'` and `null` is the same
            [name]: Array.of(
              ...complexType.getElementsByTagNameNS("*", "element"),
            )
              .map((e2) => e2.getAttribute("type"))
              .find((e2) => validGmlTypes.includes(e2 ?? "null")),
          };
        }, {});

      const filteredLayers = wfsLayer.filteredLayers || [];

      const sourceKeywords = Array.from(
        xmlDocCapabilities.querySelectorAll("ServiceIdentification Keyword"),
      )
        .map((f) => f.textContent ?? undefined)
        .filter(Boolean)
        .filter(isDefined);

      const layersInfo: WfsMetadata[] = [
        ...xmlDocCapabilities.querySelectorAll("FeatureTypeList FeatureType"),
      ]
        .map((e, index) => {
          const title = e.querySelector("Title")?.textContent ?? "undefined";
          const name = e.querySelector("Name")?.textContent;
          const abstract =
            e.querySelector("Abstract")?.textContent ?? undefined;
          const keywords = Array.from(e.querySelectorAll("Keyword"))
            .map((f) => f.textContent ?? undefined)
            .filter(Boolean)
            .filter(isDefined);

          const layerId = getExternalLayerId(
            wfsLayer.wfs_url,
            name,
            SourceTypesLayer.wfs,
            {
              index,
            },
          );
          return {
            id: layerId,
            type: (gmlTypeToInternalType as any)[
              (nameToType as any)[
                e.querySelector("Name")?.textContent?.split(":")[1] + ""
              ]
            ],
            abstract,
            keywords,
            name: title,
            bbox: [
              ...(e
                .getElementsByTagNameNS("*", "LowerCorner")?.[0]
                ?.textContent?.split(" ")
                ?.map(parseFloat) ?? []),
              ...(e
                .getElementsByTagNameNS("*", "UpperCorner")?.[0]
                ?.textContent?.split(" ")
                ?.map(parseFloat) ?? []),
            ],
            path: `${url}${appendQueryParamsSign(
              url,
            )}SERVICE=WFS&VERSION=2.0.0&request=GetFeature&OUTPUTFORMAT=${outputValue}&srsName=EPSG:4326&typeName=${
              e.querySelector("Name")?.textContent
            }`,
            originalUrl: wfsLayer.wfs_url,
            typeName: e.querySelector("Name")?.textContent ?? "undefined",
            source: wfsLayer.source,
            alias:
              wfsLayer.layers?.find(
                (l) =>
                  l.featureTypeName === e.querySelector("Name")?.textContent,
              )?.alias || undefined,
            theme:
              wfsLayer.layers?.find(
                (l) =>
                  l.featureTypeName === e.querySelector("Name")?.textContent,
              )?.theme || undefined,
            sourceType: SourceTypesLayer.wfs as SourceTypesLayer.wfs, //??
            tags: wfsLayer.layerSettingsGlobal?.[layerId]?.tags ?? [],
            sourceLayerId: e.querySelector("Name")?.textContent ?? "",
            outputValue,
          };
        })
        .filter((l) => l.type != null)
        .filter((l) => !filteredLayers.includes(l.typeName ?? ""));

      const ret: WfsSourceEntries = {
        url: wfsLayer.wfs_url,
        sourceType: SourceTypes.wfs,
        source: capitalize(wfsLayer.source),
        layersInfo,
        alternativeNames: new Set(wfsLayer?.alternativeNames ?? []),
        fetchSucceeded: true,
        keywords: sourceKeywords,
      };

      return ret;
    },
});

const wfsDataLayersFullMetadataSelectorFamily = selectorFamily<
  WfsSourceEntries[],
  WfsLayer[]
>({
  key: "wfsDataLayersFullMetadataSelectorFamily",
  get:
    (wfsLayers: WfsLayer[]) =>
    ({ get }) => {
      const activeWfsLayers = wfsLayers.filter((l) => !l.hide);
      const layers = get(
        waitForAll(
          activeWfsLayers.map((wfsLayer) =>
            wfsMetadataLayersSelector(wfsLayer),
          ),
        ),
      );
      return layers.filter(isDefined);
    },
});

export const customWfsDataLayersFullMetadataSucceededSelector = selector<
  WfsSourceEntries[]
>({
  key: "customWfsDataLayersFullMetadataSucceededSelector",
  get: ({ get }) => {
    const customWFSDataSource = get(newCustomDataSourceAtom);
    if (!customWFSDataSource || customWFSDataSource.type !== SourceTypes.wfs)
      return [];

    const wfsLayers: WfsLayer[] = [
      {
        source: customWFSDataSource.name,
        wfs_url: customWFSDataSource.url,
        layers: [],
        private: true,
        alternativeNames: customWFSDataSource?.alternativeNames ?? [],
        sourceType: SourceTypesLayer.wfs,
      },
    ];

    return get(wfsDataLayersFullMetadataSelectorFamily(wfsLayers)).filter(
      (wfs) => wfs.fetchSucceeded,
    );
  },
});

export const wfsPrivateDataLayersFullMetadataSucceededSelector = selectorFamily<
  WfsSourceEntries[],
  { projectId: string }
>({
  key: "wfsPrivateDataLayersFullMetadataSucceededSelector",
  get:
    ({ projectId }) =>
    ({ get }) => {
      const privateWFSSources = get(
        privateGISSourceDataWFSAPISelector({ projectId }),
      );

      return get(wfsDataLayersFullMetadataSelectorFamily(privateWFSSources))
        .filter((wfs) => wfs != null)
        .filter((wfs) => wfs.fetchSucceeded);
    },
});
