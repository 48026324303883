import { useRecoilState } from "recoil";
import {
  existingTurbinesInputAtom,
  lowerRightMenuActiveModeAtom,
} from "../../state/layer";
import { MenuFrame } from "../MenuPopup/CloseableMenuPopup";
import { Grid2 } from "./styles";

import Toggle, { ToggleSize } from "components/General/Toggle";
import { Row } from "components/General/Layout";
import { TypeDot } from "components/General/Icons";
import { Tag } from "components/GenerateWindTurbines/Tags";

const ExistingTurbinesMenu = () => {
  const [lowerRightActiveMode, setLowerRightActiveMode] = useRecoilState(
    lowerRightMenuActiveModeAtom,
  );
  const [input, setInput] = useRecoilState(existingTurbinesInputAtom);
  if (lowerRightActiveMode !== "existingTurbines") return null;

  return (
    <MenuFrame
      title="Existing turbines"
      onExit={() => setLowerRightActiveMode(undefined)}
      style={{ gap: "0.8rem" }}
    >
      <p>
        The existing turbines datalayer is an aggregation from{" "}
        <a
          href="https://openinframap.org/#2/26/12"
          rel="noreferrer"
          target="_blank"
        >
          Open Infrastructure Map
        </a>
      </p>
      <Row
        style={{
          display: "flex",
          alignItems: "center",
          paddingBottom: "2.4rem",
        }}
      >
        <Tag>March 3rd 2024</Tag> Last updated.
      </Row>

      <Row
        style={{
          paddingBottom: "1.6rem",
        }}
      >
        <Toggle
          checked={input.showTurbinesWithoutPower}
          size={ToggleSize.SMALL}
          onChange={() =>
            setInput({
              showTurbinesWithoutPower: !input.showTurbinesWithoutPower,
            })
          }
        ></Toggle>
        <p>Show turbines without power-rating</p>
      </Row>
      <Grid2
        style={{
          gridTemplateColumns: "2rem 3fr",
          alignItems: "center",
          gap: "0.8rem",
        }}
      >
        <TypeDot
          dotColor={"#515867"}
          style={{ height: "1rem", width: "1rem" }}
        />
        <p>Turbines with power-rating available</p>
        {input.showTurbinesWithoutPower && (
          <>
            <TypeDot
              dotColor={"#ff7a00"}
              style={{ height: "1rem", width: "1rem" }}
            />
            <p>Turbines without power-rating</p>
          </>
        )}
      </Grid2>
    </MenuFrame>
  );
};

export default ExistingTurbinesMenu;
