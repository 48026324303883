import { useCallback } from "react";
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import debounce from "debounce";
import { projectIdSelector } from "../state/pathParams";
import {
  Configuration,
  updateConfiguration,
} from "../services/configurationService";
import {
  configurationTempName,
  configurationsAtomFamily,
  fetchAnalysisConfigurationUsage,
  savingConfigurationInProgressAtom,
} from "../state/configuration";
import { initializeAndSet } from "../components/Comments/hooks/useReplyReactionCrud";
import { toastMessagesAtom } from "../state/toast";
import { Mixpanel } from "mixpanel";

const saveToDB = async (
  nodeId: string,
  configuration: Configuration,
  onComplete: () => void,
) => {
  return updateConfiguration(nodeId, configuration).finally(() => {
    onComplete();
  });
};

const saveToDbDebounced = debounce(saveToDB, 600);

const useConfigurationCrud = () => {
  const projectId = useRecoilValue(projectIdSelector);
  const setIsSaving = useSetRecoilState(savingConfigurationInProgressAtom);
  const [currentConfigurations, setConfigurations] = useRecoilState(
    configurationsAtomFamily({ nodeId: projectId }),
  );
  const setToastMessages = useSetRecoilState(toastMessagesAtom);

  const save = useCallback(
    async (configuration: Configuration) => {
      if (!projectId) return;
      setIsSaving(true);

      const usage = await fetchAnalysisConfigurationUsage(
        projectId,
        configuration.id,
      );

      const currentConfig = currentConfigurations.find(
        (c) => c.id === configuration.id,
      );

      const { name: _, ...configurationWithoutName } = configuration;
      const { name: __, ...currentConfigWithoutName } = currentConfig || {};

      const changedSomethingElseThanName =
        JSON.stringify(configurationWithoutName) !==
        JSON.stringify(currentConfigWithoutName);

      const updatedConfiguration: Configuration = currentConfig
        ? { ...currentConfig, ...configuration }
        : configuration;

      if (
        !changedSomethingElseThanName ||
        usage.length === 0 ||
        window.confirm(
          `This configuration is used in ${usage.length} branch${
            usage.length > 1 ? "es" : ""
          }, are you sure you want to update it?`,
        )
      ) {
        setConfigurations((cur) =>
          cur.map((c) =>
            c.id === updatedConfiguration.id ? updatedConfiguration : c,
          ),
        );
        return saveToDbDebounced(projectId, updatedConfiguration, () => {
          setIsSaving(false);
          Mixpanel.track("Config saved", { type: "analysis" });
          setToastMessages((tm) => [
            ...tm,
            {
              text: "Saved",
              timeout: 3000,
              type: "success",
            },
          ]);
        });
      } else {
        setIsSaving(false);
      }
    },
    [
      currentConfigurations,
      projectId,
      setConfigurations,
      setIsSaving,
      setToastMessages,
    ],
  );

  const updateLocal = useRecoilCallback(
    ({ set, snapshot }) =>
      async (nodeId: string, configuration: Configuration) => {
        initializeAndSet(
          snapshot,
          set,
          configurationsAtomFamily({ nodeId }),
          (cur) => {
            const match = cur.some((c) => c.id === configuration.id);
            if (match) {
              return [...cur].map((c) =>
                c.id === configuration.id ? configuration : c,
              );
            } else {
              return [...cur, configuration];
            }
          },
        );
      },
    [],
  );
  const deleteLocal = useRecoilCallback(
    ({ set, snapshot }) =>
      async (nodeId: string, configurationId: string) => {
        initializeAndSet(
          snapshot,
          set,
          configurationsAtomFamily({ nodeId }),
          (cur) => {
            return [...cur].filter((c) => c.id !== configurationId);
          },
        );
      },
    [],
  );

  // This method saves the new name to the data base and the atom 'costConfigurationTempName'.
  // the new name is not updated in localConfig or costConfigurationsAtomFamily,
  // because it would result in loosing non-saved values in the config
  const saveName = useRecoilCallback(
    ({ set }) =>
      async (id: string, name: string) => {
        if (!projectId) return;
        setIsSaving(true);

        set(configurationTempName({ nodeId: projectId }), (cur) => ({
          ...cur,
          [id]: name,
        }));

        const currentConfig = currentConfigurations.find((c) => c.id === id);
        if (!currentConfig)
          throw Error("Could not find analysis config, config id: " + id);

        const updatedConfiguration: Configuration = {
          ...currentConfig,
          name: name,
        };

        return saveToDbDebounced(projectId, updatedConfiguration, () => {
          setIsSaving(false);
        });
      },
    [projectId, setIsSaving, currentConfigurations],
  );

  // This is used to transfer the new names stored in costConfigurationTempName to costConfigurationsAtomFamily,
  // so that costConfigurationsAtomFamily is up to date with the database
  // This is called when the component is unmounted
  const saveTempNameToLocal = useRecoilCallback(
    ({ snapshot, set }) =>
      async (configurationId: string) => {
        if (!projectId) return;

        const tempNames = await snapshot.getPromise(
          configurationTempName({ nodeId: projectId }),
        );
        const tempName = tempNames[configurationId];
        if (!tempName) return;

        setConfigurations((cur) =>
          cur.map((c) =>
            c.id === configurationId ? { ...c, name: tempName } : c,
          ),
        );

        set(configurationTempName({ nodeId: projectId }), (cur) => ({
          ...cur,
          [configurationId]: undefined,
        }));
      },
    [projectId, setConfigurations],
  );

  return { save, updateLocal, deleteLocal, saveName, saveTempNameToLocal };
};

export default useConfigurationCrud;
