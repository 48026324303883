import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { viewViewModeAtom, VIEW_MODE } from "../../../state/viewToPark";
import { disposeObject } from "../utils";
import { ThreeCore } from "./useCreateThreeCore";

export default function useEnvironment(threeCore: ThreeCore | undefined) {
  const viewMode = useRecoilValue(viewViewModeAtom);

  useEffect(() => {
    if (!threeCore) return;
    const { scene, water, sky, sun } = threeCore;
    if (viewMode === VIEW_MODE.NATURAL_MODE) {
      scene.add(water);
      scene.add(sky);
      return () => {
        scene.remove(water);
        disposeObject(water);
        scene.remove(sky);
        disposeObject(sky);
      };
    } else if (viewMode === VIEW_MODE.WIRE_FRAME_MODE) {
      scene.add(sun);
      return () => {
        scene.remove(sun);
        disposeObject(sun);
      };
    }
  }, [viewMode, threeCore]);
}
