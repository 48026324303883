import mapboxgl, { Marker } from "mapbox-gl";
import React, {
  useRef,
  useEffect,
  ReactNode,
  useMemo,
  useCallback,
} from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { mapRefAtom } from "../../../state/map";
import { projectFeatureMap } from "../../../state/projectLayers";
import { colors, hexToRgbA } from "../../../styles/colors";
import { getCenter } from "utils/turf";
import {
  selectedThreadAtomFamily,
  showCommentIconsInMapState,
  showResolvedCommentsAtom,
} from "../state";
import { Thread } from "../types";
import { UnreadCommentDot } from "../UnreadComments/style";
import { useReadComments } from "../UnreadComments/useReadComments";
import { allCollaboratorsInNodeSelectorFamily } from "../../../state/customer";
import UserImageRound from "../../UserImage/UserImageRound";
import { Popup } from "components/Mapbox/Popup";
import { useClickOutside } from "hooks/useClickOutside";
import { AnchorClassName } from "components/General/Anchor";

const MarkerWrapper = styled.div<{ showBorder: boolean }>`
  display: flex;
  padding: 0.3rem;
  background: ${hexToRgbA(colors.focusBackground, 0.8)};

  border-radius: 100rem 100rem 100rem 0;
`;

export default function PreviewModal({
  currentProjectId,
  thread,
  nodeId,
  children,
}: {
  currentProjectId: string;
  thread: Thread;
  nodeId: string;
  children: ReactNode;
}) {
  const markerContentRef = useRef<HTMLDivElement>(null);
  const markerRef = useRef<Marker | null>(null);
  const showCommentsInMap = useRecoilValue(showCommentIconsInMapState);
  const showResolvedCommentsInMap = useRecoilValue(showResolvedCommentsAtom);
  const [selectedThreadId, setSelectedThread] = useRecoilState(
    selectedThreadAtomFamily({ nodeId }),
  );
  const { isRepliesRead } = useReadComments();

  const featureMap = useRecoilValue(projectFeatureMap);
  const map = useRecoilValue(mapRefAtom);

  const showAsUnread = useMemo(() => {
    return !isRepliesRead(thread);
  }, [isRepliesRead, thread]);

  const onClose = useCallback(() => {
    setSelectedThread((cur) => (cur === thread.threadId ? undefined : cur));
  }, [setSelectedThread, thread.threadId]);

  const placement = useMemo(() => {
    if (!thread.featureId) return;
    const feature = featureMap.get(thread.featureId);
    if (!feature) return undefined;
    const placement = getCenter(feature);
    return placement;
  }, [featureMap, thread.featureId]);

  useEffect(() => {
    if (!placement || !map || !markerContentRef.current) return;
    const marker = new mapboxgl.Marker(markerContentRef.current, {
      offset: [15, -15],
    })
      .setLngLat(placement)
      .addTo(map);

    markerRef.current = marker;
    return () => {
      marker.remove();
    };
  }, [map, placement]);

  useEffect(() => {
    if (markerRef.current && map) {
      const shouldBeOpenButIsClosed =
        selectedThreadId === thread.threadId &&
        !markerRef.current.getPopup()?.isOpen();
      const shouldBeClosedButIsOpen =
        selectedThreadId !== thread.threadId &&
        markerRef.current.getPopup()?.isOpen();
      const outOfSync = shouldBeOpenButIsClosed || shouldBeClosedButIsOpen;
      if (outOfSync) {
        markerRef.current.togglePopup();
      }
    }
  }, [map, selectedThreadId, thread.threadId]);

  const { wrapperStyle, imageStyle } = useMemo(() => {
    const wrapperStyle: React.CSSProperties = {};
    const imageStyle: React.CSSProperties = {};
    if (selectedThreadId === thread.threadId) {
      wrapperStyle["border"] = `2px solid ${colors.primaryHover}`;
    }
    const isResolvedAndDontShowResolved =
      thread.resolved && !showResolvedCommentsInMap;
    if (!showCommentsInMap || isResolvedAndDontShowResolved) {
      wrapperStyle["visibility"] = "hidden";
    } else if (thread.resolved) {
      imageStyle["opacity"] = 0.3;
    }
    return { wrapperStyle, imageStyle };
  }, [
    selectedThreadId,
    showResolvedCommentsInMap,
    showCommentsInMap,
    thread.resolved,
    thread.threadId,
  ]);

  useClickOutside(
    markerContentRef,
    () => {
      onClose();
    },
    (target) =>
      target instanceof HTMLElement &&
      (target.classList.contains("thread-popup") ||
        target.classList.contains(AnchorClassName)),
    { ignoreDragClicks: true },
  );

  const usersInCustomer = useRecoilValue(
    allCollaboratorsInNodeSelectorFamily(currentProjectId),
  );
  const user = usersInCustomer?.find((u) => u.user_id === thread.userId);
  if (!user || !map) return null;

  return (
    <>
      <MarkerWrapper
        ref={markerContentRef}
        onClick={(e) => {
          e.stopPropagation();
          setSelectedThread((cur) =>
            cur === thread.threadId ? undefined : thread.threadId,
          );
        }}
        showBorder={selectedThreadId === thread.threadId}
        style={wrapperStyle}
      >
        <div style={{ position: "relative", display: "flex" }}>
          <UserImageRound user={user} style={imageStyle} />
          {showAsUnread && (
            <UnreadCommentDot
              style={{ position: "absolute", top: "-0.2rem", right: "-0.2rem" }}
            />
          )}
        </div>
      </MarkerWrapper>
      {placement && selectedThreadId === thread.threadId && (
        <Popup
          map={map}
          pos={placement}
          place="bottom"
          offsetPx={[0, -36]}
          className="thread-popup"
        >
          {children}
        </Popup>
      )}
    </>
  );
}
