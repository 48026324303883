import React, { useMemo, useRef } from "react";
import { useRecoilValue } from "recoil";
import {
  admin_nodesInOrgSelectorFamily,
  findTopLevelNode,
  nodesInOrganisationSelectorFamily,
} from "components/Projects/useOrganisationFolderCrud";
import { ProjectPicker } from "components/Organisation/OrganisationRightSide/content/shared/NodeAccessModal";
import { useClickOutside } from "hooks/useClickOutside";
import { Frame } from "components/General/Layout";
import { spaceMedium } from "styles/space";
import { userAllNodesAccessAtom } from "state/user";
import { UserAccessRole } from "types/user";
import { FrameLoader, InvitationNodeAccess } from "./shared";

const AdminProjectPicker = ({
  organisationId,
  selectedNodeIds,
  setNodesAccess,
  onClose,
}: {
  organisationId: string;
  selectedNodeIds: string[];
  setNodesAccess: React.Dispatch<React.SetStateAction<InvitationNodeAccess[]>>;
  onClose(): void;
}) => {
  const nodes = useRecoilValue(
    admin_nodesInOrgSelectorFamily({ organisationId }),
  );

  const filteredNodes = useMemo(
    () =>
      nodes.filter((n) => {
        const topFolder = findTopLevelNode(nodes, n.id, organisationId);
        return (
          n.type !== "personal_folder" && topFolder?.type !== "personal_folder"
        );
      }),
    [nodes, organisationId],
  );

  return (
    <ProjectPicker
      nodes={filteredNodes}
      selectedNodeIds={selectedNodeIds}
      onAddNodeId={(nodeId: string) => {
        setNodesAccess((curr) => [
          ...curr,
          {
            nodeId,
            role: undefined,
          },
        ]);
      }}
      existingAccess={[]}
      onClose={onClose}
    />
  );
};

const MemberProjectPicker = ({
  organisationId,
  selectedNodeIds,
  setNodesAccess,
  onClose,
}: {
  organisationId: string;
  selectedNodeIds: string[];
  setNodesAccess: React.Dispatch<React.SetStateAction<InvitationNodeAccess[]>>;
  onClose(): void;
}) => {
  const currentUserNodeAccess = useRecoilValue(userAllNodesAccessAtom)[
    "node_access"
  ];

  const nodes = useRecoilValue(
    nodesInOrganisationSelectorFamily({ organisationId }),
  );

  const teamsWithCurrentUserAccess = useMemo(() => {
    if (!currentUserNodeAccess) return [];
    return nodes
      .filter(
        (t) =>
          currentUserNodeAccess[t.id] &&
          currentUserNodeAccess[t.id] === "admin",
      )
      .filter((t) => t.type !== "personal_folder" && !("tutorial_id" in t))
      .map((t) => ({
        ...t,
        access: currentUserNodeAccess[t.id] as UserAccessRole,
      }));
  }, [currentUserNodeAccess, nodes]);

  return (
    <ProjectPicker
      nodes={teamsWithCurrentUserAccess}
      selectedNodeIds={selectedNodeIds}
      onAddNodeId={(nodeId: string) => {
        setNodesAccess((curr) => [
          ...curr,
          {
            nodeId,
            role: undefined,
          },
        ]);
      }}
      existingAccess={[]}
      onClose={onClose}
    />
  );
};
const ProjectPickerFrame = ({
  isOrgAdmin,
  organisationId,
  selectedNodeIds,
  setNodesAccess,
  onClose,
}: {
  isOrgAdmin: boolean;
  organisationId: string;
  selectedNodeIds: string[];
  setNodesAccess: React.Dispatch<React.SetStateAction<InvitationNodeAccess[]>>;
  onClose(): void;
}) => {
  const frameRef = useRef<HTMLDivElement>(null);
  useClickOutside(frameRef, onClose);

  return (
    <Frame
      ref={frameRef}
      style={{
        position: "absolute",
        right: spaceMedium,
        overflowY: "auto",
        minWidth: "40rem",
      }}
    >
      <React.Suspense fallback={<FrameLoader />}>
        {isOrgAdmin ? (
          <AdminProjectPicker
            organisationId={organisationId}
            selectedNodeIds={selectedNodeIds}
            setNodesAccess={setNodesAccess}
            onClose={onClose}
          />
        ) : (
          <MemberProjectPicker
            organisationId={organisationId}
            selectedNodeIds={selectedNodeIds}
            setNodesAccess={setNodesAccess}
            onClose={onClose}
          />
        )}
      </React.Suspense>
    </Frame>
  );
};

export default ProjectPickerFrame;
