/// <reference types="vite-plugin-svgr/client" />
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { spaceTiny, borderRadiusMedium } from "../../styles/space";
import Tooltip from "../General/Tooltip";
import IconPolygon from "@icons/24/Polygon.svg?react";
import IconDepth from "@icons/24/Depth.svg?react";
import IconWind from "@icons/24/Wind.svg?react";
import IconStatistics from "@icons/24/Statistics.svg?react";
import IconDistance from "@icons/24/Distance.svg?react";
import IconDollar from "@icons/24/Dollar.svg?react";
import IconTurbine from "@icons/24/Turbine.svg?react";
import { typography } from "styles/typography";

export const Tag = styled.div.attrs(
  ({ selected, className }: { selected?: boolean; className?: string }) => ({
    selected: selected ?? false,
    className: className ?? "tag-opt",
  }),
)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 1.8rem;
  white-space: nowrap;
  color: ${colors.textBrand};
  ${typography.caption}

  gap: ${spaceTiny};
  padding: ${spaceTiny} 0.8rem;
  background: ${colors.surfaceInfo};
  border-radius: ${borderRadiusMedium};

  svg {
    width: 1.2rem;
    height: 1.2rem;
    stroke: ${colors.iconBrand};
  }
`;

export const DepthTag = ({ n }: { n: number }) => {
  return (
    <Tooltip text="Depth">
      <Tag>
        <IconDepth />
        {`${n.toFixed(1)} m`}
      </Tag>
    </Tooltip>
  );
};

export const WindTag = ({ n }: { n: number }) => {
  return (
    <Tooltip text="Mean wind speed">
      <Tag>
        <IconWind />
        {`${n.toFixed(1)} ms`}
      </Tag>
    </Tooltip>
  );
};

export const AEPTag = ({ n }: { n: number }) => {
  return (
    <Tooltip text="AEP">
      <Tag>
        <IconStatistics />
        {`${n.toFixed(1)} GWh`}
      </Tag>
    </Tooltip>
  );
};

export const LCOETag = ({ n }: { n: number }) => {
  return (
    <Tooltip text="LCoE">
      <Tag>
        <IconDollar />
        {`${n.toFixed(1)} €/MWh`}
      </Tag>
    </Tooltip>
  );
};

export const ShoreDistanceTag = ({ n }: { n: number }) => {
  return (
    <Tooltip text="Distance to shore">
      <Tag>
        <IconDistance />
        {`${n.toFixed(1)} km`}
      </Tag>
    </Tooltip>
  );
};

export const TargetParkCapacityTag = ({ n }: { n: number }) => {
  return (
    <Tooltip text="Target park capacity">
      <Tag>
        <IconPolygon />
        {`${n} MW`}
      </Tag>
    </Tooltip>
  );
};

export const TargetTurbineTag = ({ n }: { n: number }) => {
  return (
    <Tooltip text="Turbine power rating">
      <Tag>
        <IconTurbine />
        {`${n} MW`}
      </Tag>
    </Tooltip>
  );
};

export const TargetDensityTag = ({ n }: { n: number }) => {
  return (
    <Tooltip text="Density">
      <Tag>
        <IconTurbine />
        {`${n} MW/km2`}
      </Tag>
    </Tooltip>
  );
};

export const SubAreaTag = ({ tooltip }: { tooltip?: string }) => {
  const style = {
    backgroundColor: colors.green50,
    color: colors.green600,
  };
  if (tooltip)
    return (
      <Tooltip text={tooltip}>
        <Tag style={style}>Selected sub area(s)</Tag>
      </Tooltip>
    );
  return <Tag style={style}>Selected sub area(s)</Tag>;
};
