import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useState,
  useRef,
  CSSProperties,
} from "react";
import styled from "styled-components";
import { useDrag, useDrop, DropTargetMonitor } from "react-dnd";
import { useDndScrolling } from "react-dnd-scrolling";
import { colors } from "../../../styles/colors";
import { useClickOutside } from "../../../hooks/useClickOutside";
import {
  ScrollBody,
  useShowScrollShadow,
} from "../../../hooks/useShowScrollShadow";
import { typography } from "../../../styles/typography";
import { cursorIsInBottomHalfOfElement } from "utils/dragNDropUtils";

export type DropDownItem<T extends string | number = string> = {
  value: T;
  name: string;
  info?: string;
  disabled?: boolean;
  icon?: ReactElement;
  renderItem?: (name: string) => ReactNode;
  wrapperStyle?: React.CSSProperties;
};

export const DropDownListContainer = styled.div<{
  position: "top" | "bottom";
  size: "small" | "large" | "fixed";
}>`
  z-index: 6;
  background: ${colors.background};
  padding: 0.4rem 0;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  width: ${(p) => (p.size === "fixed" ? "20rem" : "fit-content")};
  min-width: 100%;
`;

const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  max-height: 40vh;
`;

const ListItemWrapper = styled.div`
  padding: 0.4rem;
`;

const ListItem = styled.li<{
  disabled: boolean;
  selected?: boolean;
  isKeyboardSelectedItem?: boolean;
  isHoveredTop?: boolean;
  isHoveredBottom?: boolean;
  enableDrag?: boolean;
}>`
  padding: 0rem 2.3rem 0 0.8rem;
  height: 2.8rem;
  position: relative;
  list-style: none;
  display: flex;
  gap: 0.8rem;
  align-items: center;

  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 35rem;

  ${(p) => p.selected && `background-color: ${colors.surfaceSelectedLight};`}

  margin: 0;
  cursor: pointer;
  ${(p) => p.disabled && "cursor: not-allowed;"}

  :first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  :last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  > p {
    color: ${colors.textPrimary};
    margin: 0;
    ${(p) => p.disabled && `color: ${colors.secondaryText};`}
  }
  &:hover {
    background-color: ${colors.surfaceHover};
    ${(p) => p.disabled && `background-color: ${colors.primaryDisabled};`}
    ${(p) => p.selected && `background-color: ${colors.blue200};`}
  }

  ${({ isKeyboardSelectedItem, disabled }) =>
    isKeyboardSelectedItem && !disabled
      ? `
     background-color: ${colors.surfaceSelectedLight};
  `
      : isKeyboardSelectedItem &&
        disabled &&
        `
    background-color: ${colors.primaryDisabled};
  `}

  ${({ enableDrag }) =>
    enableDrag &&
    `
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
    `}

  ${({ enableDrag, isHoveredBottom, isHoveredTop }) => {
    if (!enableDrag) {
      return "";
    }

    if (isHoveredBottom) {
      return `
        border-bottom: 1px solid ${colors.brand};
      `;
    }

    if (isHoveredTop) {
      return `
        border-top: 1px solid ${colors.brand};
      `;
    }
  }}
  
  > svg {
    width: 1.6rem;
    height: 1.6rem;
  }
`;

export const DropDownItemHeader = styled.div<{
  position: "top" | "bottom";
}>`
  ${typography.body}
  white-space: ${(p) => (p.position === "top" ? "nowrap" : "inherit")};
  margin: 0;
  text-overflow: ellipsis;
  overflow-x: clip;
`;
export const DropDownItemHeaderSmall = styled.p<{
  position: "top" | "bottom";
}>`
  ${typography.body}
  white-space: ${(p) => (p.position === "top" ? "nowrap" : "inherit")};
  margin: 0;
`;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-top: 1px solid ${colors.inputOutline};
`;

const Info = styled.p`
  margin: 4px 0 0 0;
  padding: 0;
  word-break: break-word;
  white-space: break-spaces;
`;

function DraggableListItem<T extends string | number = string>({
  item,
  index,
  isKeyboardSelectedItem,
  onOptionClicked,
  size,
  isSelected,
  position,
  onDragItem,
}: {
  item: DropDownItem<T>;
  index: number;
  isKeyboardSelectedItem: boolean;
  onOptionClicked: (item: DropDownItem<T>) => void;
  size: "small" | "large" | "fixed";
  isSelected: boolean;
  position: "top" | "bottom";
  onDragItem?(item: DropDownItem<T>, index: number): void;
}) {
  const elementRef = React.useRef<HTMLLIElement>(null);
  const [hoverState, setHoverState] = useState<undefined | "bottom" | "top">(
    undefined,
  );

  const [dropCollection, dropRef] = useDrop({
    accept: "LIST_ITEM",
    hover: (hoveredItem, monitor) => {
      if (!monitor.isOver() || hoveredItem.item.value === item.value) {
        return setHoverState(undefined);
      }
      const hoveringBottom = cursorIsInBottomHalfOfElement(
        elementRef.current,
        monitor.getClientOffset(),
      );
      setHoverState(hoveringBottom ? "bottom" : "top");
    },
    collect: (monitor) => {
      const isHovered = monitor.isOver() && monitor.canDrop();
      return {
        isHovered,
      };
    },
    canDrop: (draggedItem) =>
      onDragItem !== undefined && draggedItem.item.value !== item.value,
    drop: (
      draggedItem: { item: DropDownItem<T>; index: number },
      monitor: DropTargetMonitor,
    ) => {
      const isBottom = cursorIsInBottomHalfOfElement(
        elementRef.current,
        monitor.getClientOffset(),
      );
      const newIndex = isBottom ? index + 1 : index;
      onDragItem?.(draggedItem.item, newIndex);
    },
  });

  const [, dragRef] = useDrag(() => ({
    type: "LIST_ITEM",
    item: { item, index },
    canDrag: onDragItem !== undefined,
  }));

  dragRef(dropRef(elementRef));

  return (
    <ListItem
      ref={elementRef}
      enableDrag={onDragItem !== undefined}
      isKeyboardSelectedItem={isKeyboardSelectedItem}
      key={item.value}
      data-type="list-item"
      onClick={() => {
        if (!item.disabled) {
          onOptionClicked(item);
        }
      }}
      disabled={item.disabled ?? false}
      selected={isSelected}
      style={item.wrapperStyle}
      isHoveredTop={dropCollection.isHovered && hoverState === "top"}
      isHoveredBottom={dropCollection.isHovered && hoverState === "bottom"}
    >
      {item.icon}
      {item.renderItem ? (
        item.renderItem(item.name)
      ) : (
        <>
          {size === "large" ? (
            <DropDownItemHeader position={position}>
              {item.name}
            </DropDownItemHeader>
          ) : (
            <DropDownItemHeaderSmall position={position}>
              {item.name}
            </DropDownItemHeaderSmall>
          )}
          {item.info && <Info>{item.info}</Info>}
        </>
      )}
    </ListItem>
  );
}

export function DropDownItems<T extends string | number = string>({
  items,
  onOptionClicked,
  selectedOption,
  isOpen,
  setIsOpen,
  keepOpen,
  position = "bottom",
  style,
  size = "large",
  actionItems = [],
  ignoreClickFn,
  scrollToActiveItem,
  onDragItem,
}: {
  items: DropDownItem<T>[];
  onOptionClicked: (item: DropDownItem<T>, keepOpen?: boolean) => void;
  selectedOption?: string;
  isOpen: boolean;
  setIsOpen: (b: boolean) => void;
  keepOpen?: boolean;
  position?: "top" | "bottom";
  size?: "large" | "small" | "fixed";
  actionItems?: DropDownItem<T>[];
  ignoreClickFn?: Parameters<typeof useClickOutside>[2];
  scrollToActiveItem: boolean;
  style?: CSSProperties;
  onDragItem?(item: DropDownItem<T>, index: number): void;
}) {
  const [keyboardSelectedIndex, setKeyboardSelectedIndex] = useState(
    items.findIndex((item) => item.value === selectedOption),
  );
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { scrollBodyRef } = useShowScrollShadow(true);
  useClickOutside(
    wrapperRef,
    () => {
      setIsOpen(false);
    },
    ignoreClickFn,
  );
  useDndScrolling(scrollBodyRef, {});
  // Items might update while the list is open,
  // so we need to keep track of if we've scrolled to the active item already or not, to prevent jumping in the list
  const didScrollToActiveItem = useRef(false);

  // Scroll to active item on mount
  useEffect(() => {
    if (
      !scrollToActiveItem ||
      !scrollBodyRef.current ||
      didScrollToActiveItem.current
    ) {
      return;
    }

    const indexOfActiveItem = items.findIndex(
      (item) => item.value === selectedOption,
    );

    const listItems = scrollBodyRef.current.querySelectorAll(
      '[data-type="list-item"]',
    );
    const item = listItems.item(indexOfActiveItem);
    if (item) {
      item.scrollIntoView({
        block: "center",
        inline: "nearest",
      });
      didScrollToActiveItem.current = true;
    }
  }, [items, scrollBodyRef, scrollToActiveItem, selectedOption]);

  // Add up/down/enter/escape key listeners
  useEffect(() => {
    // List is empty
    if (items.length === 0) {
      return;
    }

    const listener = (e: KeyboardEvent) => {
      if (["ArrowDown", "ArrowUp", "Enter", "Escape"].includes(e.key)) {
        e.preventDefault();
        e.stopPropagation();
      }

      if (e.key === "ArrowDown" || e.key === "ArrowUp") {
        if (typeof keyboardSelectedIndex === "undefined") {
          return;
        }
        let nextIndex =
          e.key === "ArrowDown"
            ? (keyboardSelectedIndex + 1) % items.length
            : keyboardSelectedIndex - 1;
        if (nextIndex < 0) {
          nextIndex = items.length - 1;
        }

        setKeyboardSelectedIndex(nextIndex);
      } else if (e.key === "Enter") {
        const nextItem = items[keyboardSelectedIndex];
        if (!nextItem || nextItem.disabled) {
          return;
        }
        onOptionClicked(items[keyboardSelectedIndex]);
      } else if (e.key === "Escape") {
        setIsOpen(false);
      }
    };

    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [
    items,
    keyboardSelectedIndex,
    onOptionClicked,
    selectedOption,
    setIsOpen,
  ]);

  return (
    <DropDownListContainer
      ref={wrapperRef}
      position={position}
      size={size}
      style={{ ...style, ...(!isOpen ? { display: "none" } : {}) }}
    >
      <ScrollBody ref={scrollBodyRef}>
        <DropDownList>
          {items.map((item, index) => (
            <ListItemWrapper key={item.value}>
              <DraggableListItem
                index={index}
                item={item}
                isKeyboardSelectedItem={keyboardSelectedIndex === index}
                onOptionClicked={() => {
                  if (!item.disabled) {
                    onOptionClicked(item, keepOpen);
                  }
                }}
                size={size}
                isSelected={selectedOption === item.value}
                position={position}
                onDragItem={onDragItem}
              />
            </ListItemWrapper>
          ))}
        </DropDownList>
      </ScrollBody>
      {actionItems.length > 0 && <Divider />}
      <DropDownList>
        {actionItems.map((a) => (
          <ListItem
            onClick={() => {
              onOptionClicked(a);
            }}
            key={a.value}
            disabled={a.disabled ?? false}
          >
            {a.icon ?? null}
            {a.renderItem ? (
              a.renderItem(a.name)
            ) : size === "large" ? (
              <DropDownItemHeader
                position={position}
                style={{ fontWeight: 500 }}
              >
                {a.name}
              </DropDownItemHeader>
            ) : (
              <DropDownItemHeaderSmall
                position={position}
                style={{ fontWeight: 500 }}
              >
                {a.name}
              </DropDownItemHeaderSmall>
            )}
            {a.info && <Info>{a.info}</Info>}
          </ListItem>
        ))}
      </DropDownList>
    </DropDownListContainer>
  );
}
