import { featureCollection } from "@turf/turf";
import { useEffect, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Anchor from "@icons/anchor/anchor.png";
import Electricity from "@icons/electricity/electricity.png";
import { currentExternalLayerSelection } from "../../state/externalLayerSelection";
import {
  useDynamicStreamer,
  visibleDynamicLayersAtom,
} from "../../state/layer";
import { mapInteractionSelector, mapRefAtom } from "../../state/map";
import { defaultMouseHandlerCallBackClickableFeature } from "../../state/selection";
import { getBeforeLayer } from "../../components/Mapbox/utils";
import { getVisibleLayers } from "../../components/LayerList/LayerSettings/utils";
import { useTypedPath } from "../../state/pathParams";
import { Layer, LayerType } from "../../types/layers";
import mapboxgl from "mapbox-gl";
import {
  ErrorBoundarySilent,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "../../components/ErrorBoundaries/ErrorBoundaryLocal";
import { isTileJSONLayer } from "../../state/tileJSON";
import { isCustomLayer } from "../../components/LayerList/utils";

const DynamicPointLayers = ErrorBoundaryWrapper(
  () => {
    const map = useRecoilValue(mapRefAtom);
    const { projectId } = useTypedPath("projectId");
    const visibleLayers = useRecoilValue(getVisibleLayers({ projectId }));
    const selectedDynamicPointsLayers = visibleLayers.filter(
      (layer) => !isTileJSONLayer(layer) && layer.type === LayerType.Point,
    );

    useEffect(() => {
      if (!map) return;

      map.loadImage(Anchor, (error, image) => {
        if (error) throw error;
        if (image && !map.hasImage("anchor")) map.addImage("anchor", image);
      });

      map.loadImage(Electricity, (error, image) => {
        if (error) throw error;
        if (image && !map.hasImage("electricity"))
          map.addImage("electricity", image);
      });

      return () => {
        if (map.hasImage("anchor")) map.removeImage("anchor");
        if (map.hasImage("electricity")) map.removeImage("electricity");
      };
    }, [map]);

    return (
      <>
        {selectedDynamicPointsLayers.map((layer) => (
          <DynamicPointLayer key={layer.id} layer={layer} />
        ))}
      </>
    );
  },
  ErrorBoundarySilent,
  ScreamOnError,
);

const DynamicPointLayer = ({ layer }: { layer: Layer }) => {
  const setVisibleDynamicLayers = useSetRecoilState(visibleDynamicLayersAtom);
  const { dynamicVectorLayerFeatures } = useDynamicStreamer(layer);
  const map = useRecoilValue(mapRefAtom);
  const setCurrentSelectionArray = useSetRecoilState(
    currentExternalLayerSelection,
  );
  const mapInteraction = useRecoilValue(mapInteractionSelector);
  const setLayerMouseHandling = useSetRecoilState(
    defaultMouseHandlerCallBackClickableFeature,
  );

  const dynamicPointLayersId = useMemo(() => {
    const sourceName = isCustomLayer(layer) ? layer.source : layer.source.name;

    return `${sourceName}-${layer.name}-point`;
  }, [layer]);

  const dynamicPointLayersSource = useMemo(() => {
    const sourceName = isCustomLayer(layer) ? layer.source : layer.source.name;
    return `${sourceName}-${layer.name}-point`;
  }, [layer]);

  const dynamicSelectionType = useMemo(() => layer.name, [layer.name]);

  useEffect(() => {
    setVisibleDynamicLayers((vdl) => [...vdl, dynamicPointLayersId]);
    return () =>
      setVisibleDynamicLayers((vdl) =>
        vdl.filter((l) => l !== dynamicPointLayersId),
      );
  }, [setVisibleDynamicLayers, dynamicPointLayersId]);

  const dynamicPointLayer: mapboxgl.SymbolLayer = useMemo(
    () => ({
      id: dynamicPointLayersId,
      type: "symbol",
      source: dynamicPointLayersSource, // reference the data source
      layout: {
        "icon-image": (layer as any)["icon"], // reference the image
        "icon-size": 0.05,
      },
      minzoom: 2,
    }),
    [dynamicPointLayersId, dynamicPointLayersSource, layer],
  );

  useEffect(() => {
    if (!map) return;

    map.addSource(dynamicPointLayersSource, {
      type: "geojson",
      promoteId: "id",
      data: featureCollection(dynamicVectorLayerFeatures.features),
    });
    map.addLayer(dynamicPointLayer, getBeforeLayer(map, dynamicPointLayer.id));
    const onMouseClick = (e: any) => {
      e.preventDefault();
      if (!mapInteraction.hover) return;
      setCurrentSelectionArray([e.features[0]]);
    };
    setLayerMouseHandling((l) => ({
      ...l,
      [dynamicPointLayersId]: {
        onClick: onMouseClick,
      },
    }));

    return () => {
      map.removeLayer(dynamicPointLayer.id);
      map.removeSource(dynamicPointLayersSource);

      setLayerMouseHandling((l) => {
        const cleanedL = { ...l };
        delete cleanedL[dynamicPointLayersId];
        return cleanedL;
      });
    };
  }, [
    mapInteraction.hover,
    map,
    setCurrentSelectionArray,
    dynamicVectorLayerFeatures,
    dynamicPointLayersId,
    dynamicPointLayer,
    dynamicPointLayersSource,
    dynamicSelectionType,
    setLayerMouseHandling,
  ]);

  return null;
};

export default DynamicPointLayers;
