import styled from "styled-components";
import { colors } from "../../styles/colors";

export const LineIconWrapper = styled.div<{
  disabled?: boolean;
  onClick?: () => void;
}>`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  svg,
  img {
    width: 1.6rem;
    height: 1.6rem;
    ${({ disabled }) =>
      disabled ? `path { stroke : ${colors.primaryDisabled} }` : ""};
  }
  ${({ onClick }) => onClick && ":hover { cursor: pointer; }"}
`;
