import React, { useCallback, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { useLocalStorage } from "hooks/useBrowserStorage";
import AddIcon from "@icons/24/Add.svg";
import BulbIcon from "@icons/24/Bulb.svg";
import CloseIcon from "@icons/24/Close.svg";
import DatabaseIcon from "@icons/24/Database.svg";
import DuplicatesIcon from "@icons/24/Duplicate.svg";
import GlobeIcon from "@icons/24/Globe.svg";
import HyperLinkIcon from "@icons/24/Hyperlink.svg";
import ViewIcon from "@icons/24/View.svg";
import WindowsIcon from "@icons/24/Windows.svg";
import { refreshLayerSources } from "services/layerSourceRefreshAPI";
import { showHiddenLayersAtom } from "state/internalDataCleaning";
import { modalTypeOpenAtom } from "state/modal";
import {
  editorAccessProjectSelector,
  loggedInUserIsInternalSelector,
} from "state/user";
import { StandardBox } from "styles/boxes/Boxes";
import { SkeletonBlock } from "../Loading/Skeleton";
import { spaceSmall } from "styles/space";
import { IconREMSize, Text } from "styles/typography";
import { Divider, IconBtn } from "components/General/Icons";
import Windowed from "components/WindowedHOC/WindowedHOC";
import { openInNewWindowAtom } from "components/WindowedHOC/state";
import { useSelectFeatureForOverlapContext } from "components/DataLayersModal/selectFeatureForOverlapContext";
import { AddLayerSourceInternalModalType } from "../AddLayerSourceInternalModal/AddLayerSourceInternalModal";
import { VDivider } from "../Dashboard/Dashboard.style";
import {
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "../ErrorBoundaries/ErrorBoundaryLocal";
import FullScreenModal from "../FullScreenModal/FullScreenModal";
import Button from "../General/Button";
import Checkbox from "../General/Checkbox";
import FeatureFlag, { SHOW_DATA_CLEANING } from "../General/FeatureFlag";
import { MenuItem } from "../General/Menu";
import { MenuButton } from "../General/MenuButton";
import Tooltip from "../General/Tooltip";
import SaveDataCleaningChanges from "../LayerList/LayerDataCleaning/SaveDataCleaningChanges";
import {
  selectedSourceNameAtom,
  SourceName,
} from "../LayerList/LayerSettings/state";
import { ModalHeader } from "../RightSide/InfoModal/InfoModal.style";
import { UploadFileType, UploadModalType } from "../UploadModal/UploadModal";
import ActiveFilterPills from "./components/DataLayerFilters/ActiveFilterPills";
import BrowseLayersFilters from "./components/DataLayerFilters/BrowseLayersFilters";
import SearchBox from "./components/DataLayerFilters/SearchBox";
import DataLayersTable from "./components/DataLayersTable";
import FrequentlyUsedDataLayers from "./components/FrequentlyUsedDataLayers/FrequentlyUsedDataLayers";
import { activeFiltersAtom } from "./layer-filter-state";
import { colors } from "styles/colors";
import LibraryDataLayersModal from "./components/LibraryDataLayersModal/LibraryDataLayersModal";

enum DataLayerTab {
  EXTERNAL = "External",
  LIBRARY = "Library",
}

const SelectedDataLayerTabContext = React.createContext<DataLayerTab>(
  DataLayerTab.EXTERNAL,
);

export const AddDataLayersModalType = "AddDataLayersModalType" as const;

export const Modal = styled(StandardBox)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  //padding: 1rem 1rem 0;
  width: 80vw;
  max-width: 140rem;
  height: 80vh;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  overflow: visible;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.3rem 2.4rem 1.4rem 2.4rem;
  background-color: ${colors.blue700};
`;

export const DataLayersTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
`;

const BrowseLayersFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  flex-grow: 0;
  padding: 0 1rem 0 2rem;
  width: 120rem;
`;

const LibraryExternalTabWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

function DataLayerErrorBoundaryComponent() {
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  return (
    <FullScreenModal>
      <Modal>
        <HeaderRow>
          <ModalHeader>Data layers</ModalHeader>
          <IconBtn size="1.5rem" onClick={() => setModalTypeOpen(undefined)}>
            <CloseIcon />
          </IconBtn>
        </HeaderRow>
        <DataLayersTableWrapper
          style={{
            padding: "0 2rem",
          }}
        >
          An error occured in the data layers modal
        </DataLayersTableWrapper>
      </Modal>
    </FullScreenModal>
  );
}

const DataLayersModalInner = ErrorBoundaryWrapper(
  () => {
    const selectedTab = React.useContext(SelectedDataLayerTabContext);
    if (selectedTab === DataLayerTab.EXTERNAL) {
      return <ExternalDataLayersModal />;
    } else if (selectedTab === DataLayerTab.LIBRARY) {
      return <LibraryDataLayersModal />;
    }

    return null;
  },
  DataLayerErrorBoundaryComponent,
  ScreamOnError,
);

const ExternalDataLayersModal = () => {
  const openChat = useCallback(() => window.Intercom("showNewMessage"), []);
  const canEditProject = useRecoilValue(editorAccessProjectSelector);
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const [selectedSourceName, setSelectedSourceName] = useRecoilState(
    selectedSourceNameAtom,
  );
  const isInternal = useRecoilValue(loggedInUserIsInternalSelector);
  const [showHiddenLayers, setShowHiddenLayers] =
    useRecoilState(showHiddenLayersAtom);
  const [
    savedInitialShowFrequentlyUsedLayers,
    setSavedInitialShowFrequentlyUsedLayers,
  ] = useLocalStorage("vind:show-frequently-used-layers", true);
  const [showFrequentlyUsedLayers, setShowFrequentlyUsedLayers] = useState(
    savedInitialShowFrequentlyUsedLayers,
  );
  const [activeFilters, setActiveFilters] = useRecoilState(activeFiltersAtom);
  const { activateSelectFeatureForOverlap } =
    useSelectFeatureForOverlapContext();
  const toggleFilterOnBBOX = useCallback(() => {
    setActiveFilters((state) => ({
      ...state,
      filterOnBBOX: !state.filterOnBBOX,
    }));
  }, [setActiveFilters]);

  const onPageChange = useCallback(
    (newPageIndex: number) => {
      if (newPageIndex !== 0) {
        setShowFrequentlyUsedLayers(false);
      }
    },
    [setShowFrequentlyUsedLayers],
  );

  return (
    <>
      <BrowseLayersFilterWrapper>
        <div
          style={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <SearchBox
            onChange={(searchString) => {
              setActiveFilters((state) => ({
                ...state,
                searchString,
              }));
            }}
            value={activeFilters.searchString}
            style={{
              maxWidth: "450px",
            }}
          />

          <MenuButton
            icon={<GlobeIcon />}
            buttonType="secondary"
            buttonText={selectedSourceName.language}
          >
            {Object.values(SourceName).map((sourceName) => (
              <MenuItem
                key={sourceName}
                name={sourceName}
                onClick={() => {
                  setSelectedSourceName({ language: sourceName });
                }}
              />
            ))}
          </MenuButton>
          {canEditProject && (
            <>
              <MenuButton
                icon={<DatabaseIcon />}
                buttonType="secondary"
                side="right"
                buttonText="Add source"
              >
                <MenuItem
                  name="From URL"
                  icon={<HyperLinkIcon />}
                  onClick={() => {
                    setModalTypeOpen((curr) => ({
                      modalType: UploadModalType,
                      metadata: {
                        preSelectedFileType:
                          UploadFileType.ADD_DATA_LAYER_SOURCE,
                      },
                      backTo: curr,
                    }));
                  }}
                />
                <MenuItem
                  name="Request new source"
                  icon={<BulbIcon />}
                  onClick={openChat}
                />
                {isInternal && (
                  <MenuItem
                    name="Add new source (Internal)"
                    icon={<AddIcon />}
                    onClick={() => {
                      setModalTypeOpen((curr) => ({
                        modalType: AddLayerSourceInternalModalType,
                        backTo: curr,
                      }));
                    }}
                  />
                )}
              </MenuButton>
            </>
          )}
          {!showFrequentlyUsedLayers && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: spaceSmall,
                cursor: "pointer",
              }}
              onClick={() => {
                setShowFrequentlyUsedLayers(true);
                setSavedInitialShowFrequentlyUsedLayers(true);
              }}
            >
              <IconREMSize height={1.2} width={1.2}>
                <ViewIcon />
              </IconREMSize>
              <Text>Show frequently used layers</Text>
            </div>
          )}
          <SaveDataCleaningChanges />
          <FeatureFlag name={SHOW_DATA_CLEANING}>
            <Button
              text="Refresh sources"
              onClick={() => {
                if (
                  window.confirm(
                    "Sources will be refreshed. If there are any new layers they will be visible in a few minutes.",
                  )
                ) {
                  refreshLayerSources();
                }
              }}
            />
            <Checkbox
              label="Show hidden layers"
              labelPlacement="after"
              checked={showHiddenLayers}
              onChange={() => setShowHiddenLayers((curr) => !curr)}
            />
          </FeatureFlag>
        </div>
        <div
          style={{
            display: "flex",
            gap: "1rem",
            zIndex: 3,
          }}
        >
          <BrowseLayersFilters />
          <VDivider />
          <Tooltip
            text="Only show layers that overlap with a selected feature."
            maxWidth="100%"
          >
            <Button
              data-popup-type="overlap"
              text="Overlap"
              onClick={activateSelectFeatureForOverlap}
              buttonType="primary"
              icon={<DuplicatesIcon />}
              size="small"
            />
          </Tooltip>
          <Checkbox
            label="Filter layers using map view"
            labelPlacement="after"
            checked={activeFilters.filterOnBBOX}
            onChange={toggleFilterOnBBOX}
            style={{ width: "fit-content" }}
          />
        </div>
      </BrowseLayersFilterWrapper>

      <React.Suspense fallback={null}>
        <ActiveFilterPills />
      </React.Suspense>
      <React.Suspense
        fallback={
          <>
            <SkeletonBlock style={{ height: "5rem" }} />
            <SkeletonBlock style={{ height: "5rem" }} />
            <SkeletonBlock style={{ height: "5rem" }} />
            <SkeletonBlock style={{ height: "5rem" }} />
            <SkeletonBlock style={{ height: "5rem" }} />
            <SkeletonBlock style={{ height: "5rem" }} />
            <SkeletonBlock style={{ height: "5rem" }} />
            <SkeletonBlock style={{ height: "5rem" }} />
            <SkeletonBlock style={{ height: "5rem" }} />
            <SkeletonBlock style={{ height: "5rem" }} />
            <SkeletonBlock style={{ height: "5rem" }} />
          </>
        }
      >
        <DataLayersTableWrapper>
          {showFrequentlyUsedLayers && (
            <FrequentlyUsedDataLayers
              onHideClick={() => {
                setShowFrequentlyUsedLayers(false);
                setSavedInitialShowFrequentlyUsedLayers(false);
              }}
            />
          )}
          <DataLayersTable onPageChange={onPageChange} />
        </DataLayersTableWrapper>
      </React.Suspense>
    </>
  );
};

const TabSelector = ({
  selectedTab,
  setSelectedTab,
}: {
  selectedTab: DataLayerTab;
  setSelectedTab: React.Dispatch<React.SetStateAction<DataLayerTab>>;
}) => {
  return (
    <LibraryExternalTabWrapper>
      <Button
        style={{
          color: selectedTab === DataLayerTab.EXTERNAL ? "inherit" : "white",
        }}
        buttonType={
          selectedTab === DataLayerTab.EXTERNAL ? "secondary" : "text"
        }
        text={"External"}
        onClick={() => setSelectedTab(DataLayerTab.EXTERNAL)}
      />
      <Divider />
      <Button
        style={{
          color: selectedTab === DataLayerTab.LIBRARY ? "inherit" : "white",
        }}
        buttonType={selectedTab === DataLayerTab.LIBRARY ? "secondary" : "text"}
        text={"Library"}
        onClick={() => setSelectedTab(DataLayerTab.LIBRARY)}
      />
    </LibraryExternalTabWrapper>
  );
};

const WindowedDataLayersModal = Windowed(
  DataLayersModalInner,
  ({ children, setOpenInNewWindow, closeModal }) => {
    const [selectedTab, setSelectedTab] = useState(DataLayerTab.EXTERNAL);
    return (
      <FullScreenModal>
        <Modal>
          <HeaderRow>
            <ModalHeader>Data layers</ModalHeader>
            <TabSelector
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
            <div
              style={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <Divider />
              <Tooltip text="Open in new window">
                <IconBtn
                  size="1.5rem"
                  onClick={setOpenInNewWindow}
                  stroke={colors.white}
                  hoverBackgroundColor="unset"
                >
                  <WindowsIcon />
                </IconBtn>
              </Tooltip>
              <IconBtn
                size="1.5rem"
                onClick={closeModal}
                stroke={colors.white}
                hoverBackgroundColor="unset"
              >
                <CloseIcon />
              </IconBtn>
            </div>
          </HeaderRow>
          <SelectedDataLayerTabContext.Provider value={selectedTab}>
            {children}
          </SelectedDataLayerTabContext.Provider>
        </Modal>
      </FullScreenModal>
    );
  },
  ({ children }) => {
    const [selectedTab, setSelectedTab] = useState(DataLayerTab.EXTERNAL);

    return (
      <div
        style={{
          gap: "2rem",
          display: "flex",
          flexDirection: "column",
          overflow: "visible",
          height: "100%",
          backgroundColor: colors.background,
        }}
      >
        <HeaderRow>
          <ModalHeader>Data layers</ModalHeader>
          <TabSelector
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
          <div />
        </HeaderRow>
        <SelectedDataLayerTabContext.Provider value={selectedTab}>
          {children}
        </SelectedDataLayerTabContext.Provider>
      </div>
    );
  },
  AddDataLayersModalType,
  {
    width: 1400,
    height: 810,
    title: "Data layers",
  },
);

const DataLayersModal = () => {
  const [modalTypeOpen] = useRecoilState(modalTypeOpenAtom);
  const openInNewWindow = useRecoilValue(openInNewWindowAtom);

  if (
    modalTypeOpen?.modalType !== AddDataLayersModalType &&
    !openInNewWindow[AddDataLayersModalType]
  ) {
    return null;
  }

  return <WindowedDataLayersModal />;
};

export default DataLayersModal;
