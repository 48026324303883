/**
 * This file is generated from spec.yaml.  Do not edit it directly.
 * To regenerate it, run `npm run gen`.
 */
import * as z from "zod";

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Components {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  export namespace Schemas {
    export const Error = z
      .object({
        error: z.string().optional(),
      })
      .passthrough();
    export const SignedUrl = z
      .object({
        fileName: z.string(),
        presignedUrl: z.string(),
      })
      .strict();
    export const SourceType = z.union([
      z.literal("wfs"),
      z.literal("wms"),
      z.literal("wmts"),
      z.literal("arcgis"),
    ]);
    export const Layer = z
      .object({
        id: z.string(),
        name: z.string(),
        url: z.string(),
        bbox: z.string(),
        path: z.string(),
        source: z.string(),
        type: z.union([z.literal("geojson"), z.literal("external")]).optional(),
        description: z.string().nullable().optional(),
        author: z.string(),
        nrPackageUsages: z.number().optional(),
        created_at: z.number(),
        updated_at: z.number().optional(),
      })
      .strict();
    export const DataPackagePartial = z
      .object({
        id: z.string().optional(),
        name: z.string().optional(),
        type: z.literal("package").optional(),
        description: z.string().optional(),
        layerIds: z.array(z.string()).optional(),
        author: z.string().optional(),
        created_at: z.number().optional(),
        isArchived: z.boolean().optional(),
      })
      .strict();
    export const DataPackage = z
      .object({
        id: z.string(),
        name: z.string(),
        type: z.literal("package").optional(),
        description: z.string(),
        layerIds: z.array(z.string()),
        author: z.string(),
        created_at: z.number(),
        isArchived: z.boolean(),
      })
      .strict();
    export const DataPackagePost = z
      .object({
        name: z.string().optional(),
        type: z.literal("package").optional(),
        description: z.string().optional(),
        layerIds: z.array(z.string()).optional(),
      })
      .strict();
    export const License = z
      .object({
        url: z.string().optional(),
        type: z.string().optional(),
        attribution: z.string().optional(),
      })
      .strict();
    export const SourcePartial = z
      .object({
        id: z.string().optional(),
        name: z.string().optional(),
        url: z.string().optional(),
        type: SourceType.optional(),
      })
      .strict();
    export const SourcePost = z
      .object({
        id: z.string().optional(),
        name: z.string(),
        url: z.string(),
        type: z.union([
          z.literal("wfs"),
          z.literal("wms"),
          z.literal("wmts"),
          z.literal("arcgis"),
        ]),
      })
      .strict()
      .and(z.object({}).passthrough());
    export const Source = z
      .object({
        id: z.string(),
        name: z.string(),
        url: z.string(),
        type: z.union([
          z.literal("wfs"),
          z.literal("wms"),
          z.literal("wmts"),
          z.literal("arcgis"),
        ]),
      })
      .strict()
      .and(z.object({}).passthrough());
  }
}
export const components = {
  schemas: {
    Error: Components.Schemas.Error,
    SignedUrl: Components.Schemas.SignedUrl,
    SourceType: Components.Schemas.SourceType,
    Layer: Components.Schemas.Layer,
    DataPackagePartial: Components.Schemas.DataPackagePartial,
    DataPackage: Components.Schemas.DataPackage,
    DataPackagePost: Components.Schemas.DataPackagePost,
    License: Components.Schemas.License,
    SourcePartial: Components.Schemas.SourcePartial,
    SourcePost: Components.Schemas.SourcePost,
    Source: Components.Schemas.Source,
  },
};

export const paths = {
  "/api/datalayerlibrary/signedurl/organisation/{organisationId}": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      responses: {
        200: {
          /** Get a signed url for file upload */
          "application/json": Components.Schemas.SignedUrl,
        },
      },
    },
  },
  "/api/datalayerlibrary/{organisationId}/packages": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      responses: {
        200: {
          /** Packages in the organisation. */
          "application/json": z.array(Components.Schemas.DataPackage),
        },
      },
    },
    post: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      /** Package data */
      requestBody: {
        "application/json": Components.Schemas.DataPackagePost,
      },
      responses: {
        201: {
          /** Newly created package */
          "application/json": Components.Schemas.DataPackage,
        },
      },
    },
  },
  "/api/datalayerlibrary/{organisationId}/packages/{packageId}": {
    delete: {
      pathParams: z.object({
        organisationId: z.string(),
        packageId: z.string(),
      }),
      responses: {
        204: z.never(),
        404: z.never(),
      },
    },
    patch: {
      pathParams: z.object({
        organisationId: z.string(),
        packageId: z.string(),
      }),
      /** Package data */
      requestBody: {
        "application/json": Components.Schemas.DataPackagePost,
      },
      responses: {
        200: {
          /** Ok */
          "application/json": Components.Schemas.DataPackage,
        },
        404: z.never(),
      },
    },
  },
  "/api/datalayerlibrary/{organisationId}/sources": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      responses: {
        200: {
          /** Sources in the organisation. */
          "application/json": z.array(Components.Schemas.Source),
        },
      },
    },
    post: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      /** Source data */
      requestBody: {
        "application/json": Components.Schemas.SourcePost,
      },
      responses: {
        201: {
          /** Newly added source */
          "application/json": Components.Schemas.Source,
        },
        400: {
          /** Bad request */
          "application/json": Components.Schemas.Error,
        },
      },
    },
  },
  "/api/datalayerlibrary/{organisationId}/sources/{sourceId}": {
    delete: {
      pathParams: z.object({
        organisationId: z.string(),
        sourceId: z.string(),
      }),
      responses: {
        204: z.never(),
        404: z.never(),
      },
    },
  },
};

// Generated using openapi-to-zod v0.1.47
