/// <reference types="vite-plugin-svgr/client" />
import { Suspense, useMemo, useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useTypedPath } from "../../../state/pathParams";

import { SkeletonBlock } from "../../Loading/Skeleton";
import { spaceHuge } from "../../../styles/space";
import {
  ErrorBoundaryWrapper,
  ErrorBoundaryWarningTriangle,
  ScreamOnError,
} from "../../ErrorBoundaries/ErrorBoundaryLocal";
import {
  MemberTabName,
  _MemberTabName,
  selectedMemberTabState,
} from "../state";
import { memberInOrganisationSelectorFamily } from "state/user";
import { organisationRightSideModal } from "../OrganisationRightSide/state";
import Tabs from "components/General/Tabs";
import Groups from "../Groups/Groups";
import OrganisationMembers from "./OrganisationMembers";

const MembersTabs = ErrorBoundaryWrapper(
  () => {
    const { organisationId } = useTypedPath("organisationId");
    const isMemberInOrg = useRecoilValue(
      memberInOrganisationSelectorFamily({ organisationId }),
    );

    const [selectedMemberTab, setSelectedMemberTab] = useRecoilState(
      selectedMemberTabState,
    );

    const setContent = useSetRecoilState(
      organisationRightSideModal(organisationId),
    );
    useEffect(() => {
      setContent((cur) => (cur?.type === "user" ? cur : undefined));
    }, [setContent]);

    const memberTabs = useMemo(() => {
      if (!organisationId) return [];

      const items: {
        id: string;
        name: MemberTabName;
        data: React.ReactNode;
        icon?: React.ReactNode;
      }[] = [
        {
          id: "members",
          name: _MemberTabName.Values.Members,
          data: (
            <Suspense
              fallback={
                <SkeletonBlock
                  style={{
                    margin: spaceHuge,
                    width: "inherit",
                    height: "5rem",
                  }}
                />
              }
            >
              <OrganisationMembers />
            </Suspense>
          ),
        },
      ];
      if (isMemberInOrg) {
        items.push({
          id: "groups",
          name: _MemberTabName.Values.Groups,
          data: (
            <Suspense
              fallback={
                <SkeletonBlock
                  style={{
                    margin: spaceHuge,
                    width: "inherit",
                    height: "5rem",
                  }}
                />
              }
            >
              <Groups />
            </Suspense>
          ),
        });
      }
      if (isMemberInOrg) {
        items.push({
          id: "pending",
          name: _MemberTabName.Values.Pending,
          data: (
            <Suspense
              fallback={
                <SkeletonBlock
                  style={{
                    margin: spaceHuge,
                    width: "inherit",
                    height: "5rem",
                  }}
                />
              }
            >
              <OrganisationMembers onlyPending={true} />
            </Suspense>
          ),
        });
      }

      return items;
    }, [organisationId, isMemberInOrg]);

    return (
      <Tabs
        menuStyle={{
          justifyContent: "start",
          gap: "5rem",
          display: "flex",
          position: "sticky",
          top: 0,
          backgroundColor: "white",
          zIndex: 2,
        }}
        tabs={memberTabs}
        wrapperStyle={{
          height: "100%",
        }}
        contentWrapperStyle={{
          height: "100%",
          paddingTop: 0,
          paddingBottom: 0,
          overflowY: "auto",
        }}
        initialTab={
          selectedMemberTab
            ? memberTabs.findIndex((t) => t.name === selectedMemberTab)
            : undefined
        }
        onAfterTabChange={(index) => {
          memberTabs[index];
          setSelectedMemberTab(memberTabs[index]?.name);
        }}
      />
    );
  },
  ErrorBoundaryWarningTriangle,
  ScreamOnError,
);

export default MembersTabs;
