import { useRecoilValue } from "recoil";
import { MoveNodeConsequences } from "components/Projects/useExplainNodeMove";
import { Column, Row } from "components/General/Layout";
import Button from "components/General/Button";
import FullScreenModal from "components/FullScreenModal/FullScreenModal";
import { singleNodeAtomFamily } from "state/timeline";
import { Suspense } from "react";
import { SkeletonBlock, SkeletonText } from "components/Loading/Skeleton";
import { MoveNodeModalAtom } from "./state";
import {
  ModalWrapper,
  SearchContainer,
  Container,
  Header,
  Text,
} from "./style";
import { GroupsWarning } from "./SubWarnings/GroupsWarning";
import { UsersWarning } from "./SubWarnings/UsersWarning";
import { ResourceWarning } from "./SubWarnings/ResourceWarning";
import Spinner from "@icons/spinner/Spinner";

export default function MoveNodeModal() {
  const moveNodeModal = useRecoilValue(MoveNodeModalAtom);

  if (!moveNodeModal) return <></>;

  if (moveNodeModal.state === "searching") {
    return (
      <FullScreenModal
        style={{
          zIndex: 1001,
          backdropFilter: "none",
          backgroundColor: "rgba(0, 7, 15, 0.40)",
        }}
      >
        <ModalWrapper>
          <SearchContainer>
            Moving {moveNodeModal.nodeType} <Spinner size="1.2rem" />
          </SearchContainer>
        </ModalWrapper>
      </FullScreenModal>
    );
  }

  return (
    <FullScreenModal
      style={{
        zIndex: 1001,
        backdropFilter: "none",
        backgroundColor: "rgba(0, 7, 15, 0.40)",
      }}
    >
      <ModalWrapper style={{ top: "25%" }}>
        <Container>
          <ActionNeededBody {...moveNodeModal} />
        </Container>
      </ModalWrapper>
    </FullScreenModal>
  );
}

function NodeText({ nodeId, parentId }: { nodeId: string; parentId: string }) {
  const node = useRecoilValue(
    singleNodeAtomFamily({
      nodeId,
    }),
  );
  const parentNode = useRecoilValue(
    singleNodeAtomFamily({
      nodeId: parentId,
    }),
  );

  return (
    <>
      <Text style={{ flexWrap: "wrap" }}>
        {`Are you sure you want to move `}
        <b>{node?.name}</b>
        {" to "}
        <b>{parentNode?.name}</b>?{" "}
      </Text>
      <Text>This will affect the access of:</Text>
    </>
  );
}

function ActionNeededBody({
  consequences,
  confirm,
  cancel,
  nodeId,
  parentId,
}: {
  nodeId: string;
  parentId: string;
  consequences: MoveNodeConsequences;
  confirm: () => void;
  cancel: () => void;
}) {
  return (
    <>
      <Header>Access changed</Header>
      <Column
        style={{ gap: "2.4rem", padding: "0 0 1.6rem 0", overflow: "scroll" }}
      >
        <Column style={{ gap: "0.2rem" }}>
          <Suspense
            fallback={
              <>
                <Text style={{ flexWrap: "wrap" }}>
                  Are you sure you want to move{" "}
                  <SkeletonText
                    style={{
                      height: "1.2rem",
                      width: "4rem",
                      fontWeight: 500,
                    }}
                  />{" "}
                  to{" "}
                  <SkeletonText
                    style={{
                      height: "1.2rem",
                      width: "4rem",
                      fontWeight: 500,
                    }}
                  />
                  ?
                </Text>
                <Text>This will affect the access of:</Text>
              </>
            }
          >
            <NodeText nodeId={nodeId} parentId={parentId} />
          </Suspense>
        </Column>
        <Column>
          <Suspense
            fallback={
              <SkeletonBlock style={{ width: "100%", height: "15rem" }} />
            }
          >
            <Column>
              {consequences.group_accesses.length > 0 && (
                <GroupsWarning groupAccess={consequences.group_accesses} />
              )}
              {consequences.user_accesses.length > 0 && (
                <UsersWarning userAccess={consequences.user_accesses} />
              )}
              {(consequences.library_resources.added.length > 0 ||
                consequences.library_resources.removed.length > 0) && (
                <ResourceWarning
                  resourceAccess={consequences.library_resources}
                />
              )}
            </Column>
          </Suspense>
        </Column>
      </Column>
      <Row style={{ marginLeft: "auto", gap: "1.2rem" }}>
        <Button text="No, cancel" onClick={cancel} buttonType="secondary" />
        <Button text="Yes, move" onClick={confirm} />
      </Row>
    </>
  );
}
