import { projectIdSelector } from "./../state/pathParams";
import { useRecoilValue } from "recoil";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Mixpanel } from "../mixpanel";

export default function TrackNavigation() {
  const location = useLocation();
  const projectId = useRecoilValue(projectIdSelector);
  useEffect(() => {
    const elements = location.pathname.split("/");
    Mixpanel.track("pageview", {
      app: elements[1],
      location: location.pathname,
      projectId: projectId,
    });
  }, [location.pathname, projectId]);
  return null;
}
