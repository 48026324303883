import { useEffect } from "react";
import { selectorFamily, useRecoilValue } from "recoil";
import * as turf from "@turf/turf";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { WarningProps } from "./ValidationWarnings";
import { getParkFeaturesInBranchSelector } from "state/park";
import { branchIdSelector } from "state/pathParams";

export const overlappingParksWarningSelector = selectorFamily<
  | { type: ValidationWarningTypes.OverlappingParks; featureIds: string[] }
  | undefined,
  { branchId: string }
>({
  key: "overlappingParksWarningSelector",
  get:
    ({ branchId }) =>
    ({ get }) => {
      const parks = get(getParkFeaturesInBranchSelector({ branchId }));
      const overlapIds: string[] = [];
      for (let i = 0; i < parks.length; i++) {
        const a = parks[i];
        for (let j = i + 1; j < parks.length; j++) {
          const b = parks[j];
          const result = turf.intersect(a, b);
          if (result) {
            overlapIds.push(a.id);
            overlapIds.push(b.id);
          }
        }
      }

      return overlapIds.length === 0
        ? undefined
        : {
            type: ValidationWarningTypes.OverlappingParks,
            featureIds: overlapIds,
          };
    },
});

const OverlappingParks = ({ warn, remove }: WarningProps) => {
  const branchId = useRecoilValue(branchIdSelector);
  const overlapWarning = useRecoilValue(
    overlappingParksWarningSelector({ branchId: branchId ?? "" }),
  );

  useEffect(() => {
    if (!overlapWarning) return;
    warn(overlapWarning);
    return () => {
      remove(ValidationWarningTypes.OverlappingParks);
    };
  }, [remove, warn, overlapWarning]);

  useEffect(
    () => () => {
      remove(ValidationWarningTypes.OverlappingParks);
    },
    [remove],
  );

  return null;
};

export default OverlappingParks;
