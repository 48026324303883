/// <reference types="vite-plugin-svgr/client" />
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { spaceMedium } from "../../styles/space";
import { Comp } from "../../types/utils";
import CloseIcon from "@icons/24/Close.svg?react";
import AddIcon from "@icons/24/Add.svg?react";
import { typography } from "../../styles/typography";

const RoundTagDiv = styled.div<{ selected?: boolean; disabled?: boolean }>`
  border-radius: 2rem;
  border: 1px solid ${colors.brand};
  ${(p) => !p.disabled && `cursor: pointer;`}

  color: ${({ selected }) => (selected ? "white" : colors.brand)};
  background: ${({ selected }) => (selected ? colors.brand : "white")};
  transition:
    background 0.2s ease-in-out,
    color 0.2s ease-in-out;

  padding: 0.2rem ${spaceMedium};
  display: flex;

  user-select: none;

  ${typography.contentAndButtons};

  svg {
    path {
      stroke: ${({ selected }) => (selected ? "white" : colors.brand)};
      stroke-width: 4px;
    }
    height: 0.6rem;
    width: 0.6rem;
    align-self: center;
    padding-left: ${spaceMedium};
  }
`;

type RoundTagProps = {
  text: string;
  selected?: boolean;
  disabled?: boolean;
};

export const RoundTag = ({
  text,
  selected,
  disabled,
  ...props
}: Comp<"div", RoundTagProps>) => {
  return (
    <RoundTagDiv selected={selected} disabled={disabled} {...props}>
      {text}
      {!disabled && (selected ? <CloseIcon /> : <AddIcon />)}
    </RoundTagDiv>
  );
};
