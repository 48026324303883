/// <reference types="vite-plugin-svgr/client" />
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import DropdownButton, {
  ButtonText,
  ButtonTextWrapper,
} from "../General/Dropdown/DropdownButton";
import { DropDownItem } from "../General/Dropdown/DropdownItems";
import { useTypedPath } from "../../state/pathParams";
import WindIcon from "@icons/24/Wind.svg?react";
import { WindSourceConfiguration } from "../../services/windSourceConfigurationService";
import { windSourceConfigurationsAtomFamily } from "../../state/windSourceConfiguration";

export const WindSourceConfigurationSelector = ({
  windConfiguration,
  setWindConfiguration,
}: {
  windConfiguration?: WindSourceConfiguration;
  setWindConfiguration: (cfg: WindSourceConfiguration) => void;
}) => {
  const { projectId } = useTypedPath("projectId");

  const configurations = useRecoilValue(
    windSourceConfigurationsAtomFamily({
      projectId,
    }),
  );

  const items: DropDownItem[] = useMemo(() => {
    return configurations.map((cfg) => {
      return {
        value: cfg.id,
        name: cfg.name ?? "[no name]",
      };
    });
  }, [configurations]);

  const selected = items.find((i) => i.value === windConfiguration?.id);

  return (
    <DropdownButton
      items={items}
      onSelectItem={(e) => {
        const cfg = configurations.find((c) => c.id === e);
        if (!cfg) throw new Error("Configuration not found");
        setWindConfiguration(cfg);
      }}
      buttonText={selected?.name ?? "Select configuration"}
      className="dashboard-dropdown"
      selectedItemValue={selected?.value}
      renderText={(e) => (
        <ButtonTextWrapper>
          <WindIcon />
          <ButtonText>{e}</ButtonText>
        </ButtonTextWrapper>
      )}
    />
  );
};
