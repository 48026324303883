import { lib, constants } from "@vind-ai/mapbox-gl-draw";

import { centroid, length } from "@turf/turf";
import { currentSnapLines, currentSnapPoints } from "../useUpdateSnapPoints";
import { kmPerPixel, snapToClosest, SNAP_PIXELS } from "./lib/snapping";
import { getHumanReadableDistance } from "utils/geometry";

const { doubleClickZoom, CommonSelectors, createVertex, isEventAtCoordinates } =
  lib;

const DrawLineString = {};

const getLength = (feature) => length(feature);
const getCenter = (feature) => centroid(feature);

DrawLineString.onSetup = function (opts) {
  opts = opts || {};
  const featureId = opts.featureId;

  let line, currentVertexPosition;
  let direction = "forward";
  let enableSnapping = true;
  if (featureId) {
    line = this.getFeature(featureId);
    if (!line) {
      throw new Error("Could not find a feature with the provided featureId");
    }
    let from = opts.from;
    if (
      from &&
      from.type === "Feature" &&
      from.geometry &&
      from.geometry.type === "Point"
    ) {
      from = from.geometry;
    }
    if (
      from &&
      from.type === "Point" &&
      from.coordinates &&
      from.coordinates.length === 2
    ) {
      from = from.coordinates;
    }
    if (!from || !Array.isArray(from)) {
      throw new Error(
        "Please use the `from` property to indicate which point to continue the line from",
      );
    }
    const lastCoord = line.coordinates.length - 1;
    if (
      line.coordinates[lastCoord][0] === from[0] &&
      line.coordinates[lastCoord][1] === from[1]
    ) {
      currentVertexPosition = lastCoord + 1;
      // add one new coordinate to continue from
      line.addCoordinate(currentVertexPosition, ...line.coordinates[lastCoord]);
    } else if (
      line.coordinates[0][0] === from[0] &&
      line.coordinates[0][1] === from[1]
    ) {
      direction = "backwards";
      currentVertexPosition = 0;
      // add one new coordinate to continue from
      line.addCoordinate(currentVertexPosition, ...line.coordinates[0]);
    } else {
      throw new Error(
        "`from` should match the point at either the start or the end of the provided LineString",
      );
    }
  } else {
    line = this.newFeature({
      type: constants.geojsonTypes.FEATURE,
      properties: {},
      geometry: {
        type: constants.geojsonTypes.LINE_STRING,
        coordinates: [],
      },
    });
    currentVertexPosition = 0;
    this.addFeature(line);
  }

  this.clearSelectedFeatures();
  doubleClickZoom.disable(this);
  this.updateUIClasses({ mouse: constants.cursors.ADD });
  this.activateUIButton(constants.types.LINE);
  this.setActionableState({
    trash: true,
  });

  return {
    line,
    currentVertexPosition,
    direction,
    enableSnapping,
  };
};

DrawLineString.clickAnywhere = function (state, e) {
  if (
    (state.currentVertexPosition > 0 &&
      isEventAtCoordinates(
        e,
        state.line.coordinates[state.currentVertexPosition - 1],
      )) ||
    (state.direction === "backwards" &&
      isEventAtCoordinates(
        e,
        state.line.coordinates[state.currentVertexPosition + 1],
      ))
  ) {
    return this.changeMode(constants.modes.SIMPLE_SELECT, {
      featureIds: [state.line.id],
    });
  }
  this.updateUIClasses({ mouse: constants.cursors.ADD });

  const currentPoint = [e.lngLat.lng, e.lngLat.lat];
  const dynamicSnapDistance =
    SNAP_PIXELS * kmPerPixel(e.lngLat.lat, this.map.getZoom());

  const snappingDisabled = CommonSelectors.isShiftDown(e);
  const pointToUse = snappingDisabled
    ? currentPoint
    : snapToClosest(
        currentSnapPoints,
        currentSnapLines,
        currentPoint,
        dynamicSnapDistance,
      );

  state.line.updateCoordinate(state.currentVertexPosition, ...pointToUse);
  if (state.direction === "forward") {
    state.currentVertexPosition++;
    state.line.updateCoordinate(state.currentVertexPosition, ...pointToUse);
  } else {
    state.line.addCoordinate(0, ...pointToUse);
  }
};

DrawLineString.clickOnVertex = function (state) {
  return this.changeMode(constants.modes.SIMPLE_SELECT, {
    featureIds: [state.line.id],
  });
};

DrawLineString.onMouseMove = function (state, e) {
  const currentPoint = [e.lngLat.lng, e.lngLat.lat];
  const dynamicSnapDistance =
    SNAP_PIXELS * kmPerPixel(e.lngLat.lat, this.map.getZoom());

  const snappingDisabled = CommonSelectors.isShiftDown(e);
  const pointToUse = snappingDisabled
    ? currentPoint
    : snapToClosest(
        currentSnapPoints,
        currentSnapLines,
        currentPoint,
        dynamicSnapDistance,
      );

  state.line.updateCoordinate(state.currentVertexPosition, ...pointToUse);
  if (CommonSelectors.isVertex(e)) {
    this.updateUIClasses({ mouse: constants.cursors.POINTER });
  }
};

DrawLineString.onTap = DrawLineString.onClick = function (state, e) {
  e.originalEvent.stopPropagation();
  if (CommonSelectors.isVertex(e)) return this.clickOnVertex(state, e);
  this.clickAnywhere(state, e);
};

DrawLineString.onKeyUp = function (state, e) {
  if (CommonSelectors.isEnterKey(e)) {
    this.changeMode(constants.modes.SIMPLE_SELECT, {
      featureIds: [state.line.id],
    });
  } else if (CommonSelectors.isEscapeKey(e)) {
    this.deleteFeature([state.line.id], { silent: true });
    this.changeMode(constants.modes.SIMPLE_SELECT);
  }
};

DrawLineString.onStop = function (state) {
  doubleClickZoom.enable(this);
  this.activateUIButton();

  // check to see if we've deleted this feature
  if (this.getFeature(state.line.id) === undefined) return;

  //remove last added coordinate
  state.line.removeCoordinate(`${state.currentVertexPosition}`);
  if (state.line.isValid()) {
    this.map.fire(constants.events.CREATE, {
      features: [state.line.toGeoJSON()],
    });
  } else {
    this.deleteFeature([state.line.id], { silent: true });
    this.changeMode(constants.modes.SIMPLE_SELECT, {}, { silent: true });
  }
};

DrawLineString.onTrash = function (state) {
  this.deleteFeature([state.line.id], { silent: true });
  this.changeMode(constants.modes.SIMPLE_SELECT);
};

DrawLineString.toDisplayFeatures = function (state, geojson, display) {
  const isActiveLine = geojson.properties.id === state.line.id;
  geojson.properties.active = isActiveLine
    ? constants.activeStates.ACTIVE
    : constants.activeStates.INACTIVE;
  if (!isActiveLine) return display(geojson);
  // Only render the line if it has at least one real coordinate
  if (geojson.geometry.coordinates.length < 2) return;
  geojson.properties.meta = constants.meta.FEATURE;
  display(
    createVertex(
      state.line.id,
      geojson.geometry.coordinates[
        state.direction === "forward"
          ? geojson.geometry.coordinates.length - 2
          : 1
      ],
      `${
        state.direction === "forward"
          ? geojson.geometry.coordinates.length - 2
          : 1
      }`,
      false,
    ),
  );

  display(geojson);

  const center = getCenter({
    ...geojson,
    coordinates: geojson.geometry.coordinates,
  }).geometry.coordinates;

  const extraProps = {
    showInfo: true,
    area: getHumanReadableDistance(getLength(geojson) * 1000),
  };

  const centerPoint = {
    type: "Feature",
    properties: {
      ...geojson.properties,
      ...extraProps,
    },
    geometry: {
      type: "Point",
      coordinates: center,
    },
  };

  center.toLocaleString(extraProps);

  display(centerPoint);
};

export default DrawLineString;
