import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import type mapboxgl from "mapbox-gl";
import { FeatureCollection } from "geojson";
import MapboxGlDraw from "@vind-ai/mapbox-gl-draw";
import { spacing4 } from "styles/space";
import Circle from "@icons/24/Circle.svg";
import FromCoordinates from "@icons/24/FromCoordinates.svg";
import Line from "@icons/24/Line.svg";
import Point from "@icons/24/Point.svg";
import Rectangle from "@icons/24/Rectangle.svg";
import Polygon from "@icons/24/Square.svg";
import UploadIcon from "@icons/24/File.svg";
import { addIdOnFeaturesIfNotUUID4 } from "utils/geojson/utils";
import DrawLineString from "components/MapControls/CustomModes/MapboxGlDrawLineWithLength";
import ClickCircleMode from "components/MapControls/CustomModes/DrawCircle";
import DrawRectangle from "components/MapControls/CustomModes/DrawRectangle";
import DirectSelectWithArea from "components/MapControls/CustomModes/DirectSelectWithArea";
import SimpleSelect from "components/MapControls/CustomModes/SimpleSelect";
import DrawWithArea from "components/MapControls/CustomModes/MapboxGlDrawWithArea";
import { drawThemes } from "components/MapControls/CustomModes/lib";
import useBooleanState from "hooks/useBooleanState";
import AddFeatureFromCoordinatesToMapModal from "../modals/AddFeatureFromCoordinatesToMapModal";
import { useGoToFeatures } from "hooks/map";
import SideBarIconBtn from "components/General/SideBarIconButton";
import { replaceOrUndefined } from "components/ControlPanels/utils";
import { Divider, IconMenu } from "components/General/Icons";
import { typography } from "styles/typography";

export const LeftSideButtonsContainer = styled.div`
  position: absolute;
  top: ${spacing4};
  left: ${spacing4};
  z-index: 1;
  display: flex;
  flex-direction: column;
  transition: left 0.3s ease-in-out;
`;

const DrawToolsEditMap = ({
  setLocalFeatureCollection,
  map,
  handleNewFiles,
  acceptedFileTypes,
}: {
  setLocalFeatureCollection: React.Dispatch<
    React.SetStateAction<FeatureCollection>
  >;
  map?: mapboxgl.Map;
  acceptedFileTypes: string[];
  handleNewFiles(files: File[]): void;
}) => {
  const [activeDrawMode, setActiveDrawMode] = useState<
    undefined | MapboxGlDraw.DrawMode | "draw_circle" | "draw_rectangle"
  >(undefined);
  const [isAddFromCoordinatesOpen, toggleAddFromCoordinatesOpen] =
    useBooleanState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [controls, setControls] = useState<MapboxGlDraw | undefined>();
  const goToFeatures = useGoToFeatures(map);
  const onFileChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      return handleNewFiles(Array.from(e.target.files ?? []));
    },
    [handleNewFiles],
  );

  useEffect(() => {
    if (!map) return;
    const controls = new MapboxGlDraw({
      controls: {
        point: false,
        line_string: false,
        polygon: false,
        trash: true,
        combine_features: false,
        uncombine_features: false,
      },
      modes: Object.assign(MapboxGlDraw.modes, {
        draw_line_string: DrawLineString,
        draw_polygon: DrawWithArea,
        draw_circle: ClickCircleMode,
        draw_rectangle: DrawRectangle,
        direct_select: DirectSelectWithArea,
        simple_select: SimpleSelect,
      }) as any,
      styles: drawThemes,
    });
    map.addControl(controls, "bottom-right");
    setControls(controls);
    return () => {
      setControls(undefined);
      map.removeControl(controls);
    };
  }, [setControls, map]);
  useEffect(() => {
    if (!map || !controls) return;

    const listener = function (e: MapboxGlDraw.DrawCreateEvent) {
      setActiveDrawMode(undefined);
      const features = addIdOnFeaturesIfNotUUID4(e.features);
      setLocalFeatureCollection((featureCollection) => ({
        ...featureCollection,
        features: [...featureCollection.features, ...features],
      }));
    };
    map.on("draw.create", listener);
    return () => {
      map.off("draw.create", listener);
    };
  }, [map, controls, setLocalFeatureCollection]);

  useEffect(() => {
    if (!controls) return;
    controls.deleteAll();
    controls.changeMode(
      // @ts-ignore: The overloads for MapControls.changeMode cause this to
      // not typecheck, even though it's fine.
      activeDrawMode === undefined ? "simple_select" : activeDrawMode,
      {
        warnIfTooBig: false,
      },
    );
  }, [activeDrawMode, controls]);

  return (
    <>
      <LeftSideButtonsContainer>
        <IconMenu vertical sideBar iconSize="2.4rem">
          <p style={typography.sub2}>Tools</p>
          <SideBarIconBtn
            stroke={true}
            icon={<Polygon />}
            title="Polygon"
            onClick={() =>
              setActiveDrawMode(replaceOrUndefined("draw_polygon"))
            }
            active={activeDrawMode === "draw_polygon"}
          />
          <SideBarIconBtn
            stroke={true}
            icon={<Line />}
            title="Line"
            onClick={() =>
              setActiveDrawMode(replaceOrUndefined("draw_line_string"))
            }
            active={activeDrawMode === "draw_line_string"}
          />
          <SideBarIconBtn
            stroke={true}
            icon={<Point />}
            title="Point"
            onClick={() => setActiveDrawMode(replaceOrUndefined("draw_point"))}
            active={activeDrawMode === "draw_point"}
          />
          <SideBarIconBtn
            icon={<Rectangle />}
            title="Rectangle"
            onClick={() =>
              setActiveDrawMode(replaceOrUndefined("draw_rectangle"))
            }
            active={activeDrawMode === "draw_rectangle"}
          />
          <SideBarIconBtn
            icon={<Circle />}
            title="Circle"
            onClick={() => setActiveDrawMode(replaceOrUndefined("draw_circle"))}
            active={activeDrawMode === "draw_circle"}
          />

          <Divider />
          <p style={typography.sub2}>Upload</p>
          <SideBarIconBtn
            icon={<FromCoordinates />}
            title="From coordinates"
            onClick={toggleAddFromCoordinatesOpen}
          />
          <SideBarIconBtn
            icon={<UploadIcon />}
            title="From file"
            onClick={() => fileInputRef.current?.click()}
            stroke={true}
          />
        </IconMenu>
        <input
          multiple
          ref={fileInputRef}
          onChange={onFileChange}
          type="file"
          accept={acceptedFileTypes.join(",")}
          style={{ display: "none" }}
        />
      </LeftSideButtonsContainer>

      {isAddFromCoordinatesOpen && (
        <AddFeatureFromCoordinatesToMapModal
          onClose={toggleAddFromCoordinatesOpen}
          onAddClick={(newFeatures) => {
            goToFeatures(newFeatures);
            setLocalFeatureCollection((featureCollection) => ({
              ...featureCollection,
              features: [...featureCollection.features, ...newFeatures],
            }));
          }}
        />
      )}
    </>
  );
};

export default DrawToolsEditMap;
