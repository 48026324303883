import {
  DEFAULT_CANVAS_LAYER_COLOR,
  DEFAULT_IN_FOCUS_OPACITY,
  DEFAULT_NOT_IN_FOCUS_OPACITY,
  DEFAULT_SELECTED_OPACITY,
  displayLabelPropertyName,
  lockedPropertyName,
} from "@constants/canvas";
import {
  DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
  SUB_AREA_PROPERTY_TYPE,
  EXCLUSION_ZONE_COLOR,
  SUB_AREA_COLOR,
} from "@constants/division";
import { SymbolLayer } from "mapbox-gl";
import { useEffect } from "react";
import { colors } from "styles/colors";
import { DivisionFeature } from "types/feature";
import { safeRemoveLayer } from "utils/map";
import { addLayer, removeCodepointsFromFeatures } from "./utils";
import {
  divisionSymbolLayerId,
  divisionSourceId,
  divisionOutlineLayerId,
  divisionLayerId,
  MIN_DIVISION_VISIBLE_ZOOM,
} from "./constants";

// NOTE: see docs `_ExclusionDomain` in feature.ts for these magic numbers.
const exclusion_zone_color_expression = [
  "case",

  ["==", ["get", "domain"], 7],
  colors.exclusionZone,

  ["==", ["get", "domain"], 3],
  colors.exclusionZone2,

  ["==", ["get", "domain"], 5],
  colors.exclusionZone2,

  ["==", ["get", "domain"], 6],
  colors.exclusionZone2,

  ["==", ["get", "domain"], 1],
  colors.exclusionZone3,

  ["==", ["get", "domain"], 2],
  colors.exclusionZone3,

  ["==", ["get", "domain"], 4],
  colors.exclusionZone3,

  ["==", ["get", "domain"], 8],
  colors.exclusionZone3,

  EXCLUSION_ZONE_COLOR,
];

export const paintDivisionFeature: mapboxgl.FillPaint = {
  "fill-color": [
    "case",
    ["==", ["get", "type"], SUB_AREA_PROPERTY_TYPE],
    SUB_AREA_COLOR,
    ["==", ["get", "type"], DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE],
    exclusion_zone_color_expression,
    DEFAULT_CANVAS_LAYER_COLOR,
  ],
  "fill-opacity": [
    "case",
    [
      "all",
      ["boolean", ["feature-state", "selected"], false],
      ["!=", ["get", lockedPropertyName], true],
    ],
    0.8,
    ["boolean", ["feature-state", "inFocus"], false],
    0.15,
    0.15,
  ],
};

export const linePaintDivisionFeature: mapboxgl.LinePaint = {
  "line-color": [
    "case",
    ["==", ["get", lockedPropertyName], true],
    colors.lockedFeatureOutline,
    ["==", ["feature-state", "selected"], true],
    "#ffffff",
    ["==", ["get", "type"], SUB_AREA_PROPERTY_TYPE],
    SUB_AREA_COLOR,
    ["==", ["get", "type"], DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE],
    exclusion_zone_color_expression,
    "#000000",
  ],
  "line-width": [
    "case",
    ["==", ["get", lockedPropertyName], true],
    4,
    [
      "all",
      [
        "boolean",
        ["feature-state", "hover"],
        ["feature-state", "selected"],
        false,
      ],
    ],
    4,
    4,
  ],
  "line-opacity": [
    "case",
    [
      "all",
      [
        "boolean",
        ["feature-state", "hover"],
        ["feature-state", "selected"],
        false,
      ],
      ["!=", ["get", lockedPropertyName], true],
    ],
    DEFAULT_SELECTED_OPACITY,
    ["boolean", ["feature-state", "inFocus"], false],
    DEFAULT_IN_FOCUS_OPACITY,
    DEFAULT_NOT_IN_FOCUS_OPACITY,
  ],
};

const divisionSymbolLayer: SymbolLayer = {
  id: divisionSymbolLayerId,
  source: divisionSourceId,
  type: "symbol",
  minzoom: 10,
  layout: {
    // prettier-ignore
    "text-field": [
      "case",
      ["==", ["get", "type"], DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE],
      [
        "match",
        ["get", "domain"],
        0, "Exclude nothing",
        1, "Exclude turbines",
        2, "Exclude cables",
        3, "Exclude turbines and cables",

        4, "Exclude substations",
        5, "Exclude turbines and substations",
        6, "Exclude cables and substations",
        7, "Exclude turbines, cables, and substations",

        8, "Exclude anchors",
        9, "Exclude turbines and anchors",
        10, "Exclude cables and anchors",
        11, "Exclude turbines, cables, and anchors",

        12, "Exclude substations and anchors",
        13, "Exclude turbines, substations, and anchors",
        14, "Exclude cables, substations, and anchors",
        15, "Exclude everything",
        "",
      ],
      "",
    ],
    "text-offset": [0, -2],
    "text-size": 10,
    "text-anchor": "center",
  },
  paint: {
    "text-color": [
      "case",
      ["==", ["feature-state", "selected"], true],
      "#ffffff",
      "#444",
    ],

    "text-halo-width": [
      "case",
      ["==", ["feature-state", "selected"], true],
      1,
      0,
    ],
    "text-halo-blur": [
      "case",
      ["==", ["feature-state", "selected"], true],
      1,
      0,
    ],
    "text-halo-color": [
      "case",
      ["==", ["feature-state", "selected"], true],
      "#000",
      "#ffffff",
    ],
  },
  filter: ["boolean", ["get", displayLabelPropertyName], true],
};

export const RenderDivisions = ({
  divisions,
  map,
}: {
  divisions: DivisionFeature[];
  map: mapboxgl.Map;
}) => {
  useEffect(() => {
    map.addSource(divisionSourceId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    return () => {
      safeRemoveLayer(map, divisionSymbolLayerId);
      safeRemoveLayer(map, divisionOutlineLayerId);
      safeRemoveLayer(map, divisionLayerId);
      map.removeSource(divisionSourceId);
    };
  }, [map]);

  useEffect(() => {
    addLayer(map, {
      id: divisionLayerId,
      type: "fill",
      source: divisionSourceId,
      paint: paintDivisionFeature,
      minzoom: MIN_DIVISION_VISIBLE_ZOOM,
    });
    addLayer(map, {
      id: divisionOutlineLayerId,
      type: "line",
      source: divisionSourceId,
      paint: linePaintDivisionFeature,
      minzoom: MIN_DIVISION_VISIBLE_ZOOM,
    });
    addLayer(map, divisionSymbolLayer);
  }, [map]);

  useEffect(() => {
    const source = map.getSource(divisionSourceId);
    if (source?.type !== "geojson") return;
    source.setData({
      type: "FeatureCollection",
      features: removeCodepointsFromFeatures(divisions),
    });
  }, [map, divisions]);

  return null;
};
