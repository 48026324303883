import Button from "components/General/Button";
import { InitialsImageRound } from "components/UserImage/UserImageRound";
import { useRecoilState, useRecoilValue } from "recoil";
import { useTypedPath } from "state/pathParams";
import { organisationFinancialResourceState } from "../state";
import {
  ContentColumn,
  ContentHeaderButtonsContainer,
  ContentHeaderRow,
  MainRow,
  ResourceColumn,
  ResourceItem,
  ResourceName,
  TabContainer,
} from "../style";
import AddIcon from "@icons/24/Add.svg?react";
import Trashcan from "@icons/24/Bin.svg?react";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { Column } from "components/General/Layout";
import { DotMenu } from "components/General/MenuButton";
import { MenuItem } from "components/General/Menu";
import OpenMoreInfo from "components/General/MoreInfoButton";
import { CostConfiguration } from "services/costService";
import useOrgFinancialCrud from "./useOrgFinancialCrud";
import { getDefaultCostConfig } from "components/ConfigurationModal/Cost/state";
import FinancialSettingInLibrary from "./FinancialSettingsInLibrary";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { costConfigurationTempName } from "state/costConfigurations";

export default function FinancialTab() {
  const { organisationId } = useTypedPath("organisationId");
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [deleteInProgress, setDeleteInProgress] = useState<
    string | undefined
  >();

  const financialConfigs = useRecoilValue(
    organisationFinancialResourceState({ organisationId }),
  );
  const [selectedItemId, setSelectedItemId] = useState<string | undefined>(
    undefined,
  );
  const [content, setContent] = useRecoilState(
    organisationRightSideModal(organisationId),
  );

  const isLoading = useMemo(
    () => saveInProgress || !!deleteInProgress,
    [saveInProgress, deleteInProgress],
  );

  useEffect(() => {
    if (!selectedItemId && financialConfigs.length > 0 && !saveInProgress) {
      if (
        content &&
        content.type === "resource" &&
        financialConfigs.some((c) => c.id === content.id)
      ) {
        setSelectedItemId(content.id);
      } else {
        setSelectedItemId(financialConfigs[0].id);
        setContent({ type: "resource", id: financialConfigs[0].id });
      }
    }
  }, [selectedItemId, setContent, financialConfigs, content, saveInProgress]);

  const selectedItem = useMemo(
    () => financialConfigs.find((t) => t.id === selectedItemId),
    [selectedItemId, financialConfigs],
  );

  const newItemRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const newItem = newItemRef.current;
    if (saveInProgress && newItem) {
      newItem.scrollIntoView();
    }
  }, [saveInProgress]);

  const { create, remove, duplicate, saveTempNameToLocal } =
    useOrgFinancialCrud();

  const tempConfigNames = useRecoilValue(
    costConfigurationTempName({ nodeId: organisationId }),
  );

  const onUnmount = useCallback(() => {
    financialConfigs.forEach((c) => {
      if (saveTempNameToLocal) {
        saveTempNameToLocal(c.id);
      }
    });
  }, [saveTempNameToLocal, financialConfigs]);

  return (
    <TabContainer>
      <MainRow>
        <ResourceColumn>
          <Button
            buttonType="secondary"
            icon={<AddIcon />}
            text={"New financial configuration"}
            disabled={isLoading}
            onClick={async () => {
              setSaveInProgress(true);
              setSelectedItemId(undefined);
              const config = getDefaultCostConfig({ id: false });
              const res = await create({ config, name: "Untitled" });
              if (res) {
                setSelectedItemId(res.id);
                setSaveInProgress(false);
                setContent({ type: "resource", id: res.id });
              }
            }}
          />
          <Column style={{ gap: "0.8rem", overflowY: "auto" }}>
            {financialConfigs.map((t) => {
              return (
                <ResourceItem
                  key={t.id}
                  onClick={() => {
                    setSelectedItemId(t.id);
                    setContent({ type: "resource", id: t.id });
                  }}
                  selected={selectedItemId === t.id}
                  disabled={deleteInProgress === t.id}
                >
                  <InitialsImageRound name={t.name ?? "Financial"} size={2.2} />
                  <ResourceName>{tempConfigNames[t.id] ?? t.name}</ResourceName>
                  {!(deleteInProgress === t.id) && (
                    <DotMenu
                      divStyle={{
                        marginLeft: "auto",
                        width: "2.5rem",
                      }}
                    >
                      <MenuItem
                        name={"Duplicate"}
                        onClick={async () => {
                          setSaveInProgress(true);
                          setSelectedItemId(undefined);
                          const res = await duplicate({ config: t });
                          if (res) {
                            setSaveInProgress(false);
                            setSelectedItemId(res.id);
                            setContent({ type: "resource", id: res.id });
                          }
                        }}
                        icon={<DuplicateIcon />}
                      />
                      <MenuItem
                        name={"Delete"}
                        onClick={async () => {
                          setDeleteInProgress(t.id);
                          const res = await remove(t.id);
                          if (res?.status === "success") {
                            setDeleteInProgress(undefined);
                            setSelectedItemId(financialConfigs[0]?.id);
                            setContent({
                              type: "resource",
                              id: financialConfigs[0]?.id,
                            });
                          }
                        }}
                        icon={<Trashcan />}
                      />
                    </DotMenu>
                  )}
                </ResourceItem>
              );
            })}
            {saveInProgress && (
              <ResourceItem
                key={"save-in-progress"}
                selected={true}
                ref={newItemRef}
              >
                <SkeletonBlock
                  style={{ height: "4rem", marginTop: "0.8rem" }}
                />
              </ResourceItem>
            )}
          </Column>
        </ResourceColumn>
        {selectedItem && (
          <FinancialContent config={selectedItem} onUnmount={onUnmount} />
        )}
      </MainRow>
    </TabContainer>
  );
}

function FinancialContent({
  config,
  onUnmount,
}: {
  config: CostConfiguration;
  onUnmount: () => void;
}) {
  const { organisationId } = useTypedPath("organisationId");
  const [, setContent] = useRecoilState(
    organisationRightSideModal(organisationId),
  );
  const { update, saveName } = useOrgFinancialCrud();
  const tempConfigNames = useRecoilValue(
    costConfigurationTempName({ nodeId: organisationId }),
  );
  const configName = tempConfigNames[config.id] ?? config.name;

  return (
    <ContentColumn>
      <ContentHeaderRow>
        <ContentHeaderButtonsContainer>
          <OpenMoreInfo
            onClick={() => {
              setContent((cur) => {
                if (cur && cur.id === config.id) return undefined;
                return {
                  type: "resource",
                  id: config.id,
                };
              });
            }}
          />
        </ContentHeaderButtonsContainer>
      </ContentHeaderRow>
      <div
        style={{
          overflow: "hidden",
          position: "relative",
          width: "100%",
          flex: 1,
        }}
      >
        <FinancialSettingInLibrary
          configuration={config}
          configurationName={configName}
          onSave={update}
          onUnmount={onUnmount}
          saveName={saveName}
          isLoading={false}
        />
      </div>
    </ContentColumn>
  );
}
