import { useContext } from "react";
import styled from "styled-components";

import { ViewToParkContext } from "./ThreeContext/ViewToParkContextProvider";

const ThreeSceneWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  background-color: white;
  z-index: 2;
`;

const ThreeScene = styled.div`
  width: calc(100%);
  height: calc((100vw) * 0.5625);
`;

export default function ViewToParkThree() {
  const { threeSceneRef } = useContext(ViewToParkContext);

  return (
    <ThreeSceneWrapper>
      <ThreeScene ref={threeSceneRef} />
    </ThreeSceneWrapper>
  );
}
