import { atom, atomFamily, selectorFamily, selector } from "recoil";
import {
  getProjectBranchCount,
  getUserProjectFollows,
} from "../services/projectDataAPIService";
import { ProjectFollow } from "../types/api";
import { branchIdSelector, projectIdSelector } from "./pathParams";

export const DISABLED_IN_READ_ONLY_MODE = "Disabled in read only mode";

export const savingNewProjectAtom = atom({
  key: "savingNewProjectAtom",
  default: false,
});

export const followProjectsAtom = atom<ProjectFollow[]>({
  key: "followProjectsAtom",
  default: selector({
    key: "followProjectsSelector",
    get: getUserProjectFollows,
  }),
});

export const projectVersionAtom = atomFamily<
  undefined | number,
  { projectId?: string; branchId?: string }
>({
  key: "projectVersionAtomFamily",
  default: undefined,
});

export const currentVersionSelector = selector<undefined | number>({
  key: "currentVersionSelector",
  get: ({ get }) => {
    const projectId = get(projectIdSelector);
    const branchId = get(branchIdSelector);
    return projectVersionAtom({ projectId, branchId });
  },
});

export const inReadOnlyModeSelector = selector({
  key: "inReadOnlyModeSelector",
  get: ({ get }) => {
    return !!get(currentVersionSelector);
  },
});

export const projectBranchCountAtom = atomFamily<number, { nodeId: string }>({
  key: "projectBranchCountAtom",
  default: selectorFamily({
    key: "projectBranchCountAtom.default",
    get:
      ({ nodeId }) =>
      () =>
        getProjectBranchCount(nodeId),
  }),
});
