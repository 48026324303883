import { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import {
  libraryTurbinesRefreshAtom,
  projectTurbinesRefreshAtom,
} from "../../../state/turbines";

export const useRefreshProjectTurbines = () => {
  const setRefresh = useSetRecoilState(projectTurbinesRefreshAtom);
  return useCallback(() => {
    setRefresh((currVal) => currVal + 1);
  }, [setRefresh]);
};

export const useRefreshLibraryTurbines = () => {
  const setRefresh = useSetRecoilState(libraryTurbinesRefreshAtom);
  return useCallback(() => {
    setRefresh((currVal) => currVal + 1);
  }, [setRefresh]);
};
