/// <reference types="vite-plugin-svgr/client" />
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import Flag from "@icons/24/WindMeasure.svg?react";
import { Anchor } from "./Anchor";
import { Column } from "./Layout";
import { useClickOutside } from "../../hooks/useClickOutside";
import Checkbox from "./Checkbox";
import { useSearchParams } from "react-router-dom";
import { spacing4, spacing17 } from "styles/space";
import Tooltip from "./Tooltip";
import Button from "./Button";
import { fetchEnhancerWithToken } from "../../services/utils";
import { MenuFrame } from "../MenuPopup/CloseableMenuPopup";
import { Label } from "./Form";
import { cw } from "../Cabling/Worker/cableWorker";
import { mapRefAtom } from "state/map";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { colors } from "styles/colors";
import { IconBtn } from "./Icons";

export const featureFlag = {
  dataCleaning: "datacleaning",
  featureProperties: "featureProperties",
  globeProjection: "globe",
  librarycable: "cable",
} as const;
export type FeatureFlagName = (typeof featureFlag)[keyof typeof featureFlag];
const ALL_FLAGS = Object.values(featureFlag);

export const SHOW_DATA_CLEANING = featureFlag.dataCleaning;
export const SHOW_ALL_FEATURE_PROPERTIES = featureFlag.featureProperties;

export function useUrlFlag(param: FeatureFlagName) {
  const [params] = useSearchParams();
  return params.has(param);
}

export const useAllFeatureFlags = () => {
  const [params] = useSearchParams();
  return ALL_FLAGS.filter((flag) => params.has(flag));
};

export const FeatureFlagSelectorInner = ({
  setIsOpen,
}: {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(
    ref,
    () => setIsOpen(false),
    (e) => (e as any)["id"] === "feature-flag-selector",
  );

  const [sp, setSp] = useSearchParams();

  const hasFlag = (flag: string) => {
    return sp.has(flag);
  };

  const setFlag = (flag: string, val: boolean) => {
    if (val) {
      sp.set(flag, "1");
    } else {
      sp.delete(flag);
    }
    setSp(sp);
  };

  const [cableLibVersion, setCableLibVersion] = useState<string | undefined>(
    undefined,
  );
  useEffect(() => {
    cw.version().then((v: string | { error: string }) => {
      if (typeof v === "string") setCableLibVersion(v);
    });
  });

  const map = useRecoilValue(mapRefAtom);
  const [numLayers, setNumLayers] = useState<number | undefined>(
    map?.getStyle().layers.length,
  );
  useEffect(() => {
    if (!map) return;
    const interval = setInterval(() => {
      setNumLayers(map.getStyle().layers.length);
    }, 5_000);

    return () => {
      clearInterval(interval);
    };
  }, [map]);

  return (
    <MenuFrame
      title="Internal Tools"
      ref={ref}
      style={{
        width: "25rem",
        position: "fixed",
        top: `calc(
          calc(var(--top-bar-menu-height) + var(--branch-tab-bar-height)) +
        ${spacing4}
        )`,
        right: spacing17,
        zIndex: 4,
      }}
      onExit={() => setIsOpen(false)}
    >
      <Column>
        <Label>
          <p>Feature flags</p>
          {ALL_FLAGS.map((flag) => (
            <Checkbox
              key={flag}
              label={flag}
              labelPlacement="after"
              checked={hasFlag(flag)}
              onChange={(e) => setFlag(flag, e.target.checked)}
            />
          ))}
        </Label>

        <p>
          <Switcharoo />
        </p>

        <Button
          text={"Print newsletter mail list"}
          onClick={async () => {
            const res = await fetchEnhancerWithToken("/api/user/newslist", {});
            const jsonRes = await res.json();
            console.log(jsonRes);
          }}
        />

        <p>
          <code>cables</code> version: {cableLibVersion}
        </p>

        <p
          onClick={() => {
            console.log(map?.getStyle().layers);
          }}
        >
          Number of layers: {numLayers}
        </p>
      </Column>
    </MenuFrame>
  );
};

const Switcharoo = () => {
  const isLocalHost = window.location.host === "localhost:3000";
  const allParameters = `${window.location.pathname}${window.location.search}${window.location.hash}`;
  return (
    <a
      href={
        isLocalHost
          ? `https://app.vindtech.com${allParameters}`
          : `http://localhost:3000${allParameters}`
      }
    >
      Take me to {`${isLocalHost ? "Production" : "Localhost"}`}
    </a>
  );
};

const Badge = styled.div<{ background: string }>`
  position: absolute;

  width: 1.5rem;
  height: 1.5rem;
  transform: translateX(-75%) translateY(-25%);

  text-align: center;
  font-size: 1rem;

  color: white;
  background: ${(p) => p.background};

  border-radius: 50%;
  z-index: 1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
`;

export const FeatureFlagSelector = () => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const numFlags = useAllFeatureFlags().length;
  return (
    <div style={{ display: "flex", alignItems: "center" }} ref={ref}>
      <Tooltip text="Internal tools" position="bottom">
        <IconBtn
          id="feature-flag-selector"
          onClick={() => setIsOpen(!isOpen)}
          active={isOpen}
        >
          <Flag />
        </IconBtn>
        {0 < numFlags && (
          <div
            style={{
              position: "relative",
              alignSelf: "start",
              pointerEvents: "none",
            }}
          >
            <Badge background={colors.orange}>{numFlags}</Badge>
          </div>
        )}
      </Tooltip>
      {isOpen && (
        <Anchor
          baseRef={ref}
          floatPlace="topRight"
          basePlace="bottomRight"
          offset={[0, "1.5rem"]}
        >
          <FeatureFlagSelectorInner setIsOpen={setIsOpen} />
        </Anchor>
      )}
    </div>
  );
};

export default function FeatureFlag({
  name,
  whenEnabled = true,
  children,
}: {
  name: FeatureFlagName;
  whenEnabled?: boolean;
  children: ReactNode;
}) {
  const active = useUrlFlag(name);
  const show = whenEnabled ? active : !active;

  if (!show) return null;

  return <>{children}</>;
}

export const FeatureFlagNot = ({
  name,
  whenEnabled = true,
  children,
}: {
  name: FeatureFlagName;
  whenEnabled?: boolean;
  children: ReactNode;
}) => {
  const active = useUrlFlag(name);
  const show = whenEnabled ? active : !active;

  if (show) return null;

  return <>{children}</>;
};
