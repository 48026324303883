import {
  CableType,
  ExportCableVoltageType,
  IAVoltageType,
} from "../services/cableTypeService";

export const SUBSTATION_PROPERTY_TYPE = "park-substation";
export const EXPORT_CABLE_PROPERTY_TYPE = "park-external-wiring";
export const CABLE_PROPERTY_TYPE = "park-wiring-connection";
export const CABLE_CORRIDOR_PROPERTY_TYPE = "park-cable-corridor";
export const CABLE_PARTITION_POLYGON_PROPERTY_TYPE =
  "park-cable-partition-polygon";
export const CABLE_CHAIN_POLYGON_PROPERTY_TYPE = "park-cable-chain-polygon";

export const CablesMenuType = "cables-menu-type";
export const AddSubStationMenuType = "add-substation-menu-type";
export const GenerateCablesMenuType = "generate-cables-menu-type";
export const DrawExportCableMenuType = "draw-export-cable-menu-type";
export const DrawCableCorridorMenuType = "draw-cable-corridor-menu-type";
export const DrawCableMenuType = "draw-cable-menu-type";
export const CableFreeZoneMenuType = "cable-free-zone-menu-type";

export const defaultIACables: Record<
  IAVoltageType,
  Record<
    string,
    Pick<CableType, "resistance" | "capacitance" | "reactance" | "ampacity">
  >
> = {
  [IAVoltageType.kV66]: {
    "Cu 300mm2 66kV": {
      resistance: 0.0615,
      capacitance: 240,
      reactance: 0.1162,
      ampacity: 0.53,
    },
    "Cu 400mm2 66kV": {
      resistance: 0.0489,
      capacitance: 260,
      reactance: 0.11,
      ampacity: 0.59,
    },
    "Cu 500mm2 66kV": {
      resistance: 0.039,
      capacitance: 290,
      reactance: 0.1068,
      ampacity: 0.655,
    },
    "Cu 630mm2 66kV": {
      resistance: 0.0315,
      capacitance: 320,
      reactance: 0.1037,
      ampacity: 0.715,
    },
    "Cu 800mm2 66kV": {
      resistance: 0.0261,
      capacitance: 350,
      reactance: 0.1005,
      ampacity: 0.775,
    },
    "Cu 1000mm2 66kV": {
      resistance: 0.0223,
      capacitance: 380,
      reactance: 0.0974,
      ampacity: 0.825,
    },
  },
  [IAVoltageType.kV132]: {
    "Cu 300mm2 132kV": {
      resistance: 0.0614,
      capacitance: 160,
      reactance: 0.1319,
      ampacity: 0.53,
    },
    "Cu 400mm2 132kV": {
      resistance: 0.0487,
      capacitance: 180,
      reactance: 0.1257,
      ampacity: 0.59,
    },
    "Cu 500mm2 132kV": {
      resistance: 0.0388,
      capacitance: 200,
      reactance: 0.1194,
      ampacity: 0.655,
    },
    "Cu 630mm2 132kV": {
      resistance: 0.0311,
      capacitance: 210,
      reactance: 0.1162,
      ampacity: 0.715,
    },
    "Cu 800mm2 132kV": {
      resistance: 0.0257,
      capacitance: 230,
      reactance: 0.1131,
      ampacity: 0.775,
    },
    "Cu 1000mm2 132kV": {
      resistance: 0.0219,
      capacitance: 250,
      reactance: 0.11,
      ampacity: 0.825,
    },
  },
};

export const defaultExportCables: Record<
  ExportCableVoltageType,
  Record<
    string,
    Pick<
      CableType,
      | "resistance"
      | "capacitance"
      | "reactance"
      | "ampacity"
      | "exportCableType"
    >
  >
> = {
  [ExportCableVoltageType.kV150]: {
    "Offshore 800mm2 Al 150kV AC": {
      resistance: 0.0393,
      capacitance: 210,
      reactance: 0.1162,
      ampacity: 0.8,
      exportCableType: "HVAC",
    },
    "Offshore 800mm2 Cu 150kV AC": {
      resistance: 0.0264,
      capacitance: 210,
      reactance: 0.1162,
      ampacity: 1,
      exportCableType: "HVAC",
    },
    "Onshore 1000mm2 Al 150kV AC": {
      resistance: 0.0318,
      capacitance: 205,
      reactance: 0.175,
      ampacity: 0.98,
      exportCableType: "HVAC",
    },
    "Onshore 1200mm2 Al 150kV AC": {
      resistance: 0.0249,
      capacitance: 230,
      reactance: 0.175,
      ampacity: 1.11,
      exportCableType: "HVAC",
    },
    "Onshore 1400mm2 Al 150kV AC": {
      resistance: 0.0214,
      capacitance: 235,
      reactance: 0.17,
      ampacity: 1.205,
      exportCableType: "HVAC",
    },
  },
  [ExportCableVoltageType.kV220]: {
    "Offshore 1000mm2 Cu 220kV AC": {
      resistance: 0.022,
      capacitance: 190,
      reactance: 0.1194,
      ampacity: 1.134,
      exportCableType: "HVAC",
    },
    "Offshore 1200mm2 Al 220kV AC": {
      resistance: 0.028,
      capacitance: 200,
      reactance: 0.1162,
      ampacity: 0.99,
      exportCableType: "HVAC",
    },
    "Offshore 1200mm2 Cu 220kV AC": {
      resistance: 0.0199,
      capacitance: 200,
      reactance: 0.1162,
      ampacity: 1.2,
      exportCableType: "HVAC",
    },
    "Onshore 1000mm2 Al 220kV AC": {
      resistance: 0.0318,
      capacitance: 190,
      reactance: 0.18,
      ampacity: 0.965,
      exportCableType: "HVAC",
    },
    "Onshore 1200mm2 Al 220kV AC": {
      resistance: 0.0249,
      capacitance: 210,
      reactance: 0.175,
      ampacity: 1.09,
      exportCableType: "HVAC",
    },
    "Onshore 1400mm2 Al 220kV AC": {
      resistance: 0.0214,
      capacitance: 220,
      reactance: 0.175,
      ampacity: 1.185,
      exportCableType: "HVAC",
    },
    "Onshore 1600mm2 Al 220kV AC": {
      resistance: 0.0189,
      capacitance: 230,
      reactance: 0.17,
      ampacity: 1.275,
      exportCableType: "HVAC",
    },
    "Onshore 1800mm2 Al 220kV AC": {
      resistance: 0.0168,
      capacitance: 245,
      reactance: 0.17,
      ampacity: 1.365,
      exportCableType: "HVAC",
    },
    "Onshore 2000mm2 Al 220kV AC": {
      resistance: 0.0152,
      capacitance: 250,
      reactance: 0.17,
      ampacity: 1.44,
      exportCableType: "HVAC",
    },
  },
  [ExportCableVoltageType.kV275]: {
    "Offshore 1000mm2 Cu 275kV AC": {
      resistance: 0.0218,
      capacitance: 180,
      reactance: 0.1194,
      ampacity: 1.1,
      exportCableType: "HVAC",
    },
    "Offshore 1200mm2 Al 275kV AC": {
      resistance: 0.0279,
      capacitance: 190,
      reactance: 0.1162,
      ampacity: 0.95,
      exportCableType: "HVAC",
    },
    "Offshore 1200mm2 Cu 275kV AC": {
      resistance: 0.0191,
      capacitance: 190,
      reactance: 0.1162,
      ampacity: 1.17,
      exportCableType: "HVAC",
    },
    "Onshore 1000mm2 Al 275kV AC": {
      resistance: 0.0318,
      capacitance: 175,
      reactance: 0.1875,
      ampacity: 0.925,
      exportCableType: "HVAC",
    },
    "Onshore 1200mm2 Al 275kV AC": {
      resistance: 0.0249,
      capacitance: 190,
      reactance: 0.18,
      ampacity: 1.045,
      exportCableType: "HVAC",
    },
    "Onshore 1400mm2 Al 275kV AC": {
      resistance: 0.0214,
      capacitance: 200,
      reactance: 0.1775,
      ampacity: 1.135,
      exportCableType: "HVAC",
    },
    "Onshore 1600mm2 Al 275kV AC": {
      resistance: 0.0189,
      capacitance: 210,
      reactance: 0.175,
      ampacity: 1.215,
      exportCableType: "HVAC",
    },
    "Onshore 1800mm2 Al 275kV AC": {
      resistance: 0.0168,
      capacitance: 220,
      reactance: 0.1725,
      ampacity: 1.3,
      exportCableType: "HVAC",
    },
    "Onshore 2000mm2 Al 275kV AC": {
      resistance: 0.0152,
      capacitance: 230,
      reactance: 0.1725,
      ampacity: 1.37,
      exportCableType: "HVAC",
    },
  },
};
