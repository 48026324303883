import React, { useCallback } from "react";
import {
  useRecoilState,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";
import {
  allSimpleTurbineTypesSelector,
  currentTurbineIdAtom,
} from "../../state/turbines";
import Spinner from "@icons/spinner/Spinner";
import Dropdown from "../Dropdown/Dropdown";
import { Label } from "../General/Form";
import { modalTypeOpenAtom } from "../../state/modal";
import { FeatureSettingsModalTypeV2 } from "../../state/configuration";
import { selectedMenuItemState } from "../SettingsV2/Shared/state";
import { TURBINE_MENU_ID } from "../SettingsV2/FeatureSettings/Data/useTurbineSettings";
import { useTypedPath } from "../../state/pathParams";
import { InputTitle } from "components/General/GeneralSideModals.style";

export const TurbineSettingsActiveType = "TurbineSettingsActiveType";

const NewTurbineValue = "addNewTurbineConfiguration";

export const TurbineControl = () => {
  const { projectId } = useTypedPath("projectId");
  const [currentTurbineId, setCurrentTurbineId] = useRecoilState(
    currentTurbineIdAtom({ projectId }),
  );
  const { state, contents } = useRecoilValueLoadable(
    allSimpleTurbineTypesSelector,
  );
  const setModalType = useSetRecoilState(modalTypeOpenAtom);
  const setSelectedItemId = useSetRecoilState(
    selectedMenuItemState({
      menuId: FeatureSettingsModalTypeV2,
      projectId,
    }),
  );

  const openNewTurbineConfig = useCallback(() => {
    setSelectedItemId(TURBINE_MENU_ID);
    setModalType({ modalType: FeatureSettingsModalTypeV2 });
  }, [setSelectedItemId, setModalType]);

  if (state !== "hasValue") return <Spinner />;
  const sortedTurbines = [...contents]
    .sort((left, right) => (left.archived ? 1 : right.archived ? -1 : 0))
    .filter((t) => !t.archived);

  return (
    <Label left>
      <InputTitle>Turbine</InputTitle>
      <Dropdown
        small
        id="turbine"
        value={currentTurbineId}
        style={{
          width: "100%",
        }}
        onChange={(e) => {
          if (e.target.value === NewTurbineValue) {
            openNewTurbineConfig();
            return;
          }
          setCurrentTurbineId(e.target.value);
        }}
      >
        {sortedTurbines.map((t) => (
          <option key={t.id} value={t.id} disabled={t.archived}>
            {`${t.name}${t.archived ? " (archived)" : ""}`}
          </option>
        ))}
        <option value={NewTurbineValue}>{`+ Add new turbine`}</option>
      </Dropdown>
    </Label>
  );
};
