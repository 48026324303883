/// <reference types="vite-plugin-svgr/client" />
import React, { ChangeEvent, useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import LayersIcon from "@icons/24/Layers.svg?react";
import ListIcon from "@icons/24/PaperList.svg?react";
import UploadIcon from "@icons/24/Upload.svg?react";
import WindIcon from "@icons/24/Wind.svg?react";
import { colors } from "styles/colors";
import { spaceLarge } from "styles/space";
import { IconREMSize, Text, typography } from "styles/typography";
import Button from "../../General/Button";
import { UploadFileType } from "./NewUploadTab/types";

export const TextNoMargin = styled(Text)`
  margin: 0;
`;

export const DropdownText = styled.span`
  ${typography.contentAndButtons};
`;

export const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: ${spaceLarge};
  gap: ${spaceLarge};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

const ActionButtonWrapper = styled.div<{ nrChildren: number }>`
  display: grid;
  grid-template-columns: repeat(${({ nrChildren }) => nrChildren + 1}, 1fr);
  width: 100%;
  gap: 1rem;

  ${({ nrChildren }) =>
    // Center the buttons (sorry)
    nrChildren > 0
      ? `
    > :first-child {
      justify-self: flex-end;
    }
  `
      : `
      > :first-child {
        justify-self: center;
      }
  `}
`;

const DropFile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 3px dashed ${colors.inputOutline};
  gap: 1.5rem;
  padding: 4rem 0;
  cursor: pointer;
  position: relative;
`;

export const DropFileToUpload = ({
  acceptedFileTypes,
  handleNewFiles,
  children,
}: {
  acceptedFileTypes: string[];
  handleNewFiles(files: File[]): void;
} & React.PropsWithChildren) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDrop = useCallback(async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.currentTarget.style.backgroundColor = "unset";
  }, []);

  const onFileChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      return handleNewFiles(Array.from(e.target.files ?? []));
    },
    [handleNewFiles],
  );

  useEffect(() => {
    const preventDefault = (e: DragEvent) => {
      e.preventDefault();
    };

    const onDrop = (e: DragEvent) => {
      if (!e.dataTransfer) {
        return;
      }
      e.preventDefault();

      if (!e.dataTransfer.types.includes("Files") || !fileInputRef.current) {
        return;
      }
      return handleNewFiles(Array.from(e.dataTransfer.files));
    };

    document.addEventListener("dragover", preventDefault);
    document.addEventListener("dragleave", preventDefault);
    document.addEventListener("drop", onDrop);
    return () => {
      document.removeEventListener("dragover", preventDefault);
      document.removeEventListener("dragleave", preventDefault);
      document.removeEventListener("drop", onDrop);
    };
  }, [handleNewFiles]);

  const triggerFileInput = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    fileInputRef.current?.click();
  }, []);

  return (
    <>
      <input
        multiple
        ref={fileInputRef}
        onChange={onFileChange}
        type="file"
        accept={acceptedFileTypes.join(",")}
        style={{ display: "none" }}
      />
      <DropFile
        onClick={triggerFileInput}
        onDragOver={(e) => {
          e.preventDefault();
          if (!e.dataTransfer.types.includes("Files")) {
            return;
          }

          e.currentTarget.style.backgroundColor = `${colors.selected}`;
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          e.currentTarget.style.backgroundColor = "unset";
        }}
        onDrop={handleDrop}
      >
        <UploadIcon />
        <TextNoMargin>Drag and drop files here</TextNoMargin>
        <TextNoMargin>OR</TextNoMargin>
        <ActionButtonWrapper nrChildren={React.Children.count(children)}>
          <Button
            buttonType="secondary"
            text="Choose file"
            onClick={triggerFileInput}
            size="small"
          />
          {children}
        </ActionButtonWrapper>
      </DropFile>
    </>
  );
};

export const ModalTitleMapper = ({
  fileType,
}: {
  fileType?: UploadFileType;
}) => {
  if (fileType === UploadFileType.PROJECT_FEATURE) {
    return (
      <>
        <IconREMSize height={1.7} width={1.7}>
          <ListIcon />
        </IconREMSize>
        <h2>Elements</h2>
      </>
    );
  }
  if (fileType === UploadFileType.FEATURE_FROM_COORDINATES) {
    return (
      <>
        <IconREMSize height={1.7} width={1.7}>
          <ListIcon />
        </IconREMSize>
        <h2>Feature from coordinates</h2>
      </>
    );
  }

  if (fileType === UploadFileType.DATA_LAYER) {
    return (
      <>
        <IconREMSize height={1.7} width={1.7}>
          <LayersIcon />
        </IconREMSize>
        <h2>Data layers</h2>
      </>
    );
  }

  if (fileType === UploadFileType.ADD_DATA_LAYER_SOURCE) {
    return (
      <>
        <IconREMSize height={1.7} width={1.7}>
          <LayersIcon />
        </IconREMSize>
        <h2>Data layer source</h2>
      </>
    );
  }

  if (fileType === UploadFileType.WIND_DATA) {
    return (
      <>
        <IconREMSize height={1.7} width={1.7}>
          <WindIcon />
        </IconREMSize>
        <h2>Wind data</h2>
      </>
    );
  }

  return <h2>Upload</h2>;
};
