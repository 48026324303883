import {
  DEFAULT_IN_FOCUS_OPACITY,
  DEFAULT_NOT_IN_FOCUS_OPACITY,
  DEFAULT_SELECTED_OPACITY,
  displayLabelPropertyName,
  lockedPropertyName,
} from "@constants/canvas";
import { Feature3DRender } from "components/MapFeatures/Feature3DRender";
import { defaultPointCircleRadius } from "components/MapFeatures/expressionUtils";
import { Feature } from "geojson";
import useIsCameraIn3D from "hooks/useIsCameraIn3D";
import { CirclePaint, FillPaint, LinePaint } from "mapbox-gl";
import { useEffect, useMemo, useState } from "react";
import { useRecoilCallback, useRecoilValue } from "recoil";
import {
  allSimpleTurbineTypesSelector,
  getMostSelectedOrDefaultTurbineType,
  turbineOutlineFeature,
} from "state/turbines";
import { colors } from "styles/colors";
import { TurbineFeature } from "types/feature";
import { safeRemoveLayer } from "utils/map";
import { isDefined } from "utils/predicates";
import * as turf from "@turf/turf";
import {
  MIN_TURBINES_VISIBLE_ZOOM,
  editmodePropertyName,
  generateFoundationWarningPropertyName,
  ghostPropertyName,
  turbineBufferLayerId,
  turbineDiameterLayerId,
  turbineDiameterSourceId,
  turbineLayerId,
  turbineSourceId,
  turbineSymbolLayerId,
} from "./constants";
import { addLayer, removeCodepointsFromFeatures } from "./utils";
import { sendWarning } from "utils/sentry";
import Polygon from "../MapFeatures/Polygon";
import LineString from "../MapFeatures/LineString";
import {
  turbineEllipsesCustomFillLayerId,
  turbineEllipsesCustomFillSourceId,
  turbineEllipsesCustomLineLayerId,
  turbineEllipsesCustomLineSourceId,
} from "components/GenerateWindTurbines/constants";
import { Angle, TurbineDistance } from "components/Units/units";
import { EllipsesFeature } from "components/TurbineEllipsesSettings/state";

const linePaint: LinePaint = {
  "line-color": colors.seagreen700,
  "line-width": 1,
  "line-opacity": 0.7,
};

const fillPaint: FillPaint = {
  "fill-color": colors.seagreen700,
  "fill-opacity": 0.3,
};

const turbinePaint: CirclePaint = {
  "circle-radius": defaultPointCircleRadius,
  "circle-color": [
    "case",
    ["==", ["get", editmodePropertyName], true],
    colors.yellow,
    [
      "boolean",
      ["feature-state", generateFoundationWarningPropertyName],
      false,
    ],
    colors.orange,
    [
      "boolean",
      ["feature-state", "overlap"],
      ["feature-state", "outside"],
      false,
    ],
    colors.redAlert,
    colors.turbine,
  ],
  "circle-opacity": [
    "case",
    ["==", ["get", ghostPropertyName], true],
    0.2,
    ["==", ["get", editmodePropertyName], true],
    1.0,
    ["==", ["get", lockedPropertyName], true],
    0.8,
    [
      "boolean",
      ["feature-state", "hover"],
      ["feature-state", "selected"],
      false,
    ],
    DEFAULT_SELECTED_OPACITY,
    ["boolean", ["feature-state", "inFocus"], false],
    DEFAULT_IN_FOCUS_OPACITY,
    DEFAULT_NOT_IN_FOCUS_OPACITY,
  ],
  "circle-stroke-color": [
    "case",
    ["==", ["get", editmodePropertyName], true],
    colors.lightText,
    ["==", ["get", lockedPropertyName], true],
    colors.lockedFeatureOutline,
    ["!=", ["feature-state", "borderColor"], null],
    ["feature-state", "borderColor"],
    "#fff",
  ],
  // prettier-ignore
  "circle-stroke-width": [
    "case",
    ["==", ["get", editmodePropertyName], true], 3,
    ["==", ["get", lockedPropertyName], true], 2,
    ["!=", ["feature-state", "borderColor"], null], 2.0,
    ["boolean", ["feature-state", "selected"], false], 2.0,
    ["boolean", ["feature-state", "hover"], false], 1.0,
    0.0,
  ],
};

const bufferPaint: FillPaint = {
  "fill-color": [
    "case",
    ["boolean", ["feature-state", "overlap"], false],
    "#ff0000",
    "#000000",
  ],
  "fill-outline-color": [
    "case",
    ["boolean", ["feature-state", "overlap"], false],
    "#ff0000",
    "#000000",
  ],
  "fill-opacity": 0.15,
};

export const RenderTurbines = ({
  turbines,
  map,
}: {
  turbines: TurbineFeature[];
  map: mapboxgl.Map;
}) => {
  useEffect(() => {
    map.addSource(turbineSourceId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    return () => {
      safeRemoveLayer(map, turbineSymbolLayerId);
      safeRemoveLayer(map, turbineBufferLayerId);
      safeRemoveLayer(map, turbineLayerId);
      map.removeSource(turbineSourceId);
    };
  }, [map]);

  useEffect(() => {
    addLayer(map, {
      id: turbineLayerId,
      type: "circle",
      source: turbineSourceId,
      paint: turbinePaint,
      minzoom: MIN_TURBINES_VISIBLE_ZOOM,
    });
    addLayer(map, {
      id: turbineBufferLayerId,
      type: "fill",
      source: turbineSourceId,
      paint: bufferPaint,
      minzoom: MIN_TURBINES_VISIBLE_ZOOM,
    });
    addLayer(map, {
      id: turbineSymbolLayerId,
      source: turbineSourceId,
      type: "symbol",
      minzoom: 11.5,
      layout: {
        "text-field": ["get", "name"],
        "text-offset": [0, -2],
        "text-size": 8,
        "text-anchor": "center",
      },
      filter: ["boolean", ["get", displayLabelPropertyName], true],
    });
  }, [map]);

  useEffect(() => {
    const source = map.getSource(turbineSourceId);
    if (source?.type !== "geojson") return;
    source.setData({
      type: "FeatureCollection",
      features: removeCodepointsFromFeatures(turbines),
    });
  }, [map, turbines]);

  return null;
};

const turbineDiameterPaint: FillPaint = {
  "fill-color": [
    "case",
    ["boolean", ["feature-state", "overlap"], false],
    "#ff0000",
    "#000000",
  ],
  "fill-outline-color": [
    "case",
    ["boolean", ["feature-state", "overlap"], false],
    "#ff0000",
    "#000000",
  ],
  "fill-opacity": 0.15,
};

export const RenderTurbineDiameterCircle = ({
  turbines,
  map,
}: {
  turbines: TurbineFeature[];
  map: mapboxgl.Map;
}) => {
  const turbineTypes = useRecoilValue(allSimpleTurbineTypesSelector);
  const turbineTypeMap = useMemo(
    () => new Map(turbineTypes.map((t) => [t.id, t])),
    [turbineTypes],
  );

  const getOutlineFeatures = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        return Promise.all(
          turbines.map(async (f) => {
            const diameter =
              turbineTypeMap.get(f.properties.turbineTypeId)?.diameter ?? 0;
            const buffer: Feature | undefined = await snapshot.getPromise(
              turbineOutlineFeature({
                point: f.geometry,
                diameter,
              }),
            );
            if (!buffer) return undefined;
            return {
              ...buffer,
              id: f.id,
            };
          }),
        );
      },
    [turbines, turbineTypeMap],
  );

  const [outlineFeatures, setOutlineFeatures] = useState<Feature[]>([]);
  useEffect(() => {
    if (!map || !turbineTypes) return;
    let run = true;
    getOutlineFeatures().then((f) => {
      if (run && f) setOutlineFeatures(f.filter(isDefined));
    });
    return () => {
      run = false;
    };
  }, [map, turbineTypes, getOutlineFeatures]);

  useEffect(() => {
    map.addSource(turbineDiameterSourceId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    return () => {
      safeRemoveLayer(map, turbineDiameterLayerId);
      map.removeSource(turbineDiameterSourceId);
    };
  }, [map]);

  useEffect(() => {
    addLayer(map, {
      id: turbineDiameterLayerId,
      type: "fill",
      source: turbineDiameterSourceId,
      paint: turbineDiameterPaint,
    });
  }, [map]);

  useEffect(() => {
    const source = map.getSource(turbineDiameterSourceId);
    if (source?.type !== "geojson") return;
    source.setData({
      type: "FeatureCollection",
      features: outlineFeatures,
    });
  }, [map, outlineFeatures]);

  const isCameraIn3DView = useIsCameraIn3D();

  if (isCameraIn3DView)
    return <Feature3DRender map={map} features={turbines} />;

  return null;
};

export const RenderTurbineEllipsis = ({
  turbines,
  visibleEllipses,
  map,
}: {
  turbines: TurbineFeature[];
  visibleEllipses: Record<string, EllipsesFeature>;
  map: mapboxgl.Map;
}) => {
  const mostUsedTurbineType = useRecoilValue(
    getMostSelectedOrDefaultTurbineType,
  );

  const turbineDistanceConvert = useMemo(
    () => TurbineDistance.makeConvert(mostUsedTurbineType?.diameter ?? 0),
    [mostUsedTurbineType],
  );

  const turbineDistCannonical = useMemo(() => {
    return ({ value, unit }: TurbineDistance.Of<number>): number =>
      turbineDistanceConvert(value, { from: unit });
  }, [turbineDistanceConvert]);

  const turbineEllipses = useMemo(() => {
    if (!visibleEllipses || !turbines) return [];

    const turbinesWithEllipses = turbines.filter(
      (turbine) =>
        visibleEllipses[turbine.id] && visibleEllipses[turbine.id].show,
    );

    const turbineEllipses = turbinesWithEllipses.map((turbine) => {
      const { majorAxis, minorAxis, orientation } = visibleEllipses[turbine.id];
      const majorAxisCannonical = turbineDistCannonical(majorAxis);
      const minorAxisCannonical = turbineDistCannonical(minorAxis);
      const orientationCannonical = Angle.to(orientation, "deg");

      let sx, sy, angle;
      if (minorAxisCannonical < majorAxisCannonical) {
        sx = minorAxis.value / 2.0;
        sy = majorAxisCannonical / 2.0;
        angle = orientationCannonical;
      } else {
        sx = majorAxisCannonical / 2.0;
        sy = minorAxisCannonical / 2.0;
        angle = orientationCannonical + 90;
      }

      if (sx == null || Math.abs(sx) < 1e-6)
        sendWarning("TurbineEllipses: sx bad", { sx });
      if (sy == null || Math.abs(sy) < 1e-6)
        sendWarning("TurbineEllipses: sy bad", { sy });

      return turf.ellipse(turbine.geometry.coordinates, sx, sy, {
        units: "meters",
        steps: 64,
        // @ts-ignore: Typing is wrong. See
        // https://github.com/Turfjs/turf/issues/1818
        angle,
      });
    });

    return turbineEllipses;
  }, [turbines, visibleEllipses, turbineDistCannonical]);

  return (
    <>
      <Polygon
        features={turbineEllipses}
        sourceId={turbineEllipsesCustomFillSourceId}
        layerId={turbineEllipsesCustomFillLayerId}
        map={map}
        paint={fillPaint}
      />
      <LineString
        features={turbineEllipses}
        sourceId={turbineEllipsesCustomLineSourceId}
        layerId={turbineEllipsesCustomLineLayerId}
        map={map}
        paint={linePaint}
      />
    </>
  );
};
