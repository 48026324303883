import styled from "styled-components";
import { StandardBox } from "../../styles/boxes/Boxes";

export const OpacityNumber = styled.div`
  flex-grow: 1;
  cursor: pointer;
  font-size: 1.4rem;
`;

export const ColorSelectorWrapper = styled.div`
  position: relative;
  display: flex;
`;

export const FeatureStylingPickerContainer = styled(StandardBox)<{
  position?: "left" | "right";
}>`
  position: absolute;
  ${(p) =>
    (p.position ?? "right") === "right" ? { left: "0" } : { right: "0" }}
  margin-top: ${(p) => (p.position === "left" ? "4.4rem" : "0rem")};
`;

export const OpacitySliderWrapper = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  font-size: 1.2rem;
`;

export const GridContainer = styled.div<{ gridSize: 2 | 3 }>`
  font-size: 1.2rem;
  display: grid;
  ${({ gridSize }) =>
    gridSize === 3
      ? `
  grid-template-columns: fit-content(1ch) fit-content(1ch) 1fr;
  `
      : `grid-template-columns: fit-content(1ch) fit-content(1ch);`}
  grid-row-gap: 1rem;
  grid-column-gap: 2rem;
  padding: 1rem;
`;

export const RowItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
