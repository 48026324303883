import { editorAccessProjectSelector } from "./../state/user";
import { useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { inReadOnlyModeSelector } from "../state/project";
import {
  createDependencyMap,
  findFeatureChildrenIdsUsingDependencyMap,
} from "../state/projectLayers";
import { currentSelectionArrayAtom } from "../state/selection";
import { toastMessagesAtom } from "../state/toast";
import { useProjectElementsCrud } from "../components/ProjectElements/useProjectElementsCrud";
import { projectFeaturesSelector } from "../components/ProjectElements/state";

export const useDeleteFeaturesCallback = () => {
  const editorAccessProject = useRecoilValue(editorAccessProjectSelector);
  const setToastMessages = useSetRecoilState(toastMessagesAtom);
  const projectData = useRecoilValue(projectFeaturesSelector);
  const setCurrentSelectionArray = useSetRecoilState(currentSelectionArrayAtom);
  const readOnlyMode = useRecoilValue(inReadOnlyModeSelector);
  const { update: updateFeatures } = useProjectElementsCrud();

  return useCallback(
    (featureIds: string[]) => {
      if (readOnlyMode) {
        setToastMessages((tm) => [
          ...tm,
          {
            text: "You are in a read only mode and can't delete this feature",
            timeout: 2000,
          },
        ]);
        return;
      }
      if (!editorAccessProject) {
        setToastMessages((tm) => [
          ...tm,
          {
            text: "You do not have permission to delete features",
            timeout: 2000,
          },
        ]);
        return;
      }

      const featureToDelete = projectData.find((p) =>
        featureIds.includes(p.id),
      );
      if (!featureToDelete) return;

      const dependencyMap = createDependencyMap(projectData);
      const IdsToDelete = findFeatureChildrenIdsUsingDependencyMap(
        dependencyMap,
        featureIds,
      );
      updateFeatures({ remove: IdsToDelete });
      setCurrentSelectionArray((curr) => {
        return curr.filter((featureId) => !IdsToDelete.includes(featureId));
      });
    },
    [
      readOnlyMode,
      editorAccessProject,
      projectData,
      updateFeatures,
      setCurrentSelectionArray,
      setToastMessages,
    ],
  );
};
