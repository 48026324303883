import React from "react";
import { Configuration } from "services/configurationService";
import { Grid2 } from "components/General/Form";
import Toggle, { ToggleSize } from "components/General/Toggle";
import { InputDimensioned } from "components/General/Input";
import { Text } from "components/SettingsV2/Shared/styles";
import HelpTooltip, {
  ARTICLE_INTER_ARRAY_LOSS,
  HelpLink,
} from "components/HelpTooltip/HelpTooltip";
import { ElementsContainer, Row, SubHeader } from "../shared";

const fractionToPercentage = (fraction: number) => fraction * 100;
const percentageToFraction = (percentage: number) => percentage / 100;

const ElectricalSettingsTab = ({
  isReadOnly,
  localConfig,
  setLocalConfig,
}: {
  isReadOnly?: boolean;
  localConfig: Configuration;
  setLocalConfig: React.Dispatch<React.SetStateAction<Configuration>>;
}) => {
  return (
    <ElementsContainer>
      <Row>
        <SubHeader>Inter array settings</SubHeader>
      </Row>
      <Grid2
        style={{
          gridTemplateColumns: "20rem 10rem",
          gap: "1rem",
        }}
      >
        <Row style={{ width: "20rem", justifyContent: "space-between" }}>
          <Text>Cable length contingency:</Text>
          <Toggle
            checked={localConfig.electrical.cableLengthContingencyEnabled}
            disabled={isReadOnly}
            onChange={() => {
              setLocalConfig((curr) => ({
                ...curr,
                electrical: {
                  ...curr.electrical,
                  cableLengthContingencyEnabled:
                    !curr.electrical.cableLengthContingencyEnabled,
                },
              }));
            }}
            size={ToggleSize.SMALL}
          />
        </Row>
        <Row>
          <InputDimensioned
            disabled={
              isReadOnly ||
              !localConfig.electrical.cableLengthContingencyEnabled
            }
            validate={(n) => n >= 0 && n <= 20}
            validationMessage="Value must be between 0 and 20"
            unit="%"
            min={0}
            max={20}
            type="number"
            value={fractionToPercentage(
              localConfig.electrical.cableLengthContingency,
            )}
            onChange={(val) =>
              setLocalConfig((curr) => ({
                ...curr,
                electrical: {
                  ...curr.electrical,
                  cableLengthContingency: percentageToFraction(val),
                },
              }))
            }
            style={{ width: "7rem" }}
          />
          <HelpTooltip text="Adds additional cable length to each inter-array cable segment. Will be included in CAPEX and electrical loss calculation." />
        </Row>
      </Grid2>
      <Row>
        <SubHeader>Electrical losses</SubHeader>
        <HelpLink article={ARTICLE_INTER_ARRAY_LOSS} />
      </Row>
      <Row style={{ width: "20rem", justifyContent: "space-between" }}>
        <Text> Turbine trafo loss:</Text>
        <Toggle
          checked={localConfig.electrical.turbineTrafoLoss}
          disabled={isReadOnly}
          onChange={() => {
            setLocalConfig((curr) => ({
              ...curr,
              electrical: {
                ...curr.electrical,
                turbineTrafoLoss: !curr.electrical.turbineTrafoLoss,
              },
            }));
          }}
          size={ToggleSize.SMALL}
        />
      </Row>
      <Row style={{ width: "20rem", justifyContent: "space-between" }}>
        <Text> Inter array cable loss:</Text>
        <Toggle
          checked={localConfig.electrical.interArrayCableLoss}
          disabled={isReadOnly}
          onChange={() => {
            setLocalConfig((curr) => ({
              ...curr,
              electrical: {
                ...curr.electrical,
                interArrayCableLoss: !curr.electrical.interArrayCableLoss,
              },
            }));
          }}
          size={ToggleSize.SMALL}
        />
      </Row>
      <Row style={{ width: "20rem", justifyContent: "space-between" }}>
        <Text> Export system loss:</Text>
        <Toggle
          checked={localConfig.electrical.exportSystemLoss}
          disabled={isReadOnly}
          onChange={() => {
            setLocalConfig((curr) => ({
              ...curr,
              electrical: {
                ...curr.electrical,
                exportSystemLoss: !curr.electrical.exportSystemLoss,
              },
            }));
          }}
          size={ToggleSize.SMALL}
        />
      </Row>
    </ElementsContainer>
  );
};

export default ElectricalSettingsTab;
