/// <reference types="vite-plugin-svgr/client" />
import {
  CapexEntry,
  CostConfiguration,
} from "../../../../../services/costService";
import { useRecoilState } from "recoil";
import { Input, InputDimensioned } from "../../../../General/Input";
import { localCostConfigurationAtom } from "../../state";
import { TextIcon } from "../../../../../styles/typography";
import Trashcan from "@icons/24/Bin.svg?react";
import { spaceTiny } from "../../../../../styles/space";
import { CostUnit } from "../../../../../types/financial";
import { isDefined } from "../../../../../utils/predicates";
import { CostDivider, DividerWrapper, GridWithFourColumns } from "../../styles";
import { ConfidenceLevelSelector } from "../../ConfidenceLevelSelector";

const CostInput = ({
  configuration,
  entry,
  autoFocus,
  units = [],
  isReadOnly,
}: {
  configuration: CostConfiguration;
  entry: CapexEntry;
  autoFocus: boolean;
  units?: CostUnit[];
  isReadOnly?: boolean;
}) => {
  const [localConfig, setLocalConfig] = useRecoilState(
    localCostConfigurationAtom,
  );
  const useConfidenceLevel = localConfig.useConfidenceLevel;

  return (
    <>
      <GridWithFourColumns>
        <div>
          <Input
            value={entry.name}
            autoFocus={autoFocus}
            disabled={isReadOnly}
            onChange={(e) => {
              setLocalConfig({
                ...configuration,
                capex: {
                  ...configuration.capex,
                  custom:
                    localConfig?.capex.custom.map((c) =>
                      c.id === entry.id ? { ...c, name: e.target.value } : c,
                    ) ?? [],
                },
              });
            }}
            style={{ width: "20rem" }}
          />
        </div>
        <div>
          <InputDimensioned
            style={{ minWidth: "20rem" }}
            unit={entry.unit}
            units={units}
            value={entry.cost}
            validate={(value) => isDefined(value) && value >= 0}
            validationMessage={"Must be greater than or equal to 0"}
            disabled={isReadOnly}
            onChange={(cost) => {
              setLocalConfig({
                ...configuration,
                capex: {
                  ...configuration.capex,
                  custom:
                    localConfig?.capex.custom.map((c) =>
                      c.id === entry.id ? { ...c, cost } : c,
                    ) ?? [],
                },
              });
            }}
            onUnitChange={(unit) => {
              setLocalConfig({
                ...configuration,
                capex: {
                  ...configuration.capex,
                  custom:
                    localConfig?.capex.custom.map((c) =>
                      c.id === entry.id ? { ...c, unit } : c,
                    ) ?? [],
                },
              });
            }}
          />
        </div>
        {useConfidenceLevel ? (
          <ConfidenceLevelSelector
            disabled={isReadOnly}
            onConfidenceLevelChange={(confidenceLevel) => {
              setLocalConfig({
                ...configuration,
                capex: {
                  ...configuration.capex,
                  custom:
                    localConfig?.capex.custom.map((c) =>
                      c.id === entry.id
                        ? {
                            ...c,
                            confidenceLevel: confidenceLevel,
                          }
                        : c,
                    ) ?? [],
                },
              });
            }}
            confidenceLevel={entry.confidenceLevel}
          />
        ) : (
          <div></div>
        )}
        {!isReadOnly && (
          <TextIcon
            onClick={() =>
              setLocalConfig({
                ...configuration,
                capex: {
                  ...configuration.capex,
                  custom:
                    localConfig?.capex.custom.filter(
                      (c) => c.id !== entry.id,
                    ) ?? [],
                },
              })
            }
            scale={1.2}
            style={{
              padding: spaceTiny,
              alignSelf: "center",
            }}
          >
            <Trashcan />
          </TextIcon>
        )}
      </GridWithFourColumns>
      <DividerWrapper>
        <CostDivider />
      </DividerWrapper>
    </>
  );
};

export default CostInput;
