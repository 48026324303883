import { atom } from "recoil";

export const activeHintAtom = atom<string | undefined>({
  key: "activeHintAtom",
  default: undefined,
});

export const activePositionHintAtom = atom<string | undefined>({
  key: "activePositionHintAtom",
  default: undefined,
});
