import React, { Suspense } from "react";
import RightSideV2 from "./InfoModal/RightSideV2";
import RightSideModals from "./RightSideModals";
import { RightSideButtonsContainer } from "./InfoModal/style";

const RightSideMapV2 = () => {
  return (
    <RightSideButtonsContainer>
      <Suspense fallback={null}>
        <RightSideV2 />
        <RightSideModals />
      </Suspense>
    </RightSideButtonsContainer>
  );
};

export default RightSideMapV2;
