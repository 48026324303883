import styled from "styled-components";
import { colors } from "styles/colors";
import { spaceLarge, spaceTiny } from "styles/space";

export const PortfolioWrapper = styled.div<{ fullScreen: boolean }>`
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  box-sizing: border-box;
  padding: ${spaceLarge} 0;
  ${({ fullScreen }) =>
    fullScreen &&
    `
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    background-color: white;
    padding: ${spaceLarge};
    `}
`;

export const MapPopupBoundary = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  overflow: hidden;
`;

export const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const MapCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.background}aa;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PopupWrapper = styled.div`
  display: flex;
  padding: ${spaceLarge};
  background-color: white;
  border-radius: 4px;
  gap: ${spaceLarge};
`;

export const ParkContent = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 25rem;
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spaceLarge};
  justify-content: space-between;
`;

export const EarthIconWrapper = styled.div`
  padding: ${spaceTiny};
  border-radius: 2px;
  background: rgba(180, 213, 255, 0.6);

  svg {
    path {
      fill: white;
    }
  }
`;
