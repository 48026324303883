interface Loaded<T, PT> extends LoadClass<T, PT> {
  state: "loaded";
  value: T;
  getLoaded: () => T;
  getLoading: () => undefined;
}

export const makeLoaded = <T, PT>(value: T): Loaded<T, PT> => ({
  state: "loaded",
  value,
  getLoaded: () => value,
  getLoading: () => undefined,
});

export const isLoaded = <T, PT>(l: Load<T, PT>): l is Loaded<T, PT> =>
  l.state === "loaded";

interface Loading<T, PT> extends LoadClass<T, PT> {
  state: "loading";
  value: PT;
  getLoaded: () => undefined;
  getLoading: () => PT;
}

export const makeLoading = <T, PT>(value: PT): Loading<T, PT> => ({
  state: "loading",
  value,
  getLoaded: () => undefined,
  getLoading: () => value,
});

export const isLoading = <T, PT>(l: Load<T, PT>): l is Loaded<T, PT> =>
  l.state === "loading";

/**
 * A type that represents a value that is either loaded or loading.
 *
 * The main difference between `Recoil.Loadable` and this is that we have a
 * second type parameter for the loading state. This allows us to have a
 * "partially" known state, for instance if we are creating a new object that we
 * know the name of, we don't need to wait for the server response before
 * displaying it.
 */
export type Load<T, PT> = Loaded<T, PT> | Loading<T, PT>;

/** Class representing partiall loaded data. */
class LoadClass<T, PT> {
  /**
   * Return the loaded value, if the current state is `"loaded"`. Otherwise
   * return `undefined`.
   */
  getLoaded: () => T | undefined = () => undefined;
  /**
   * Return the loading value, if the current state is `"loading"`. Otherwise
   * return `undefined`.
   */
  getLoading: () => PT | undefined = () => undefined;
}
