import { useProjectElementsCrud } from "components/ProjectElements/useProjectElementsCrud";
import React, { useContext } from "react";

export type SelectOptionsContextType = {
  updateFeatures: ReturnType<typeof useProjectElementsCrud>["update"];
};

export const SelectOptionsContext = React.createContext<
  SelectOptionsContextType | undefined
>(undefined);

export const useSelectOptionContext = () => {
  const ctx = useContext(SelectOptionsContext);
  if (!ctx) throw new Promise(() => {});
  return ctx;
};
