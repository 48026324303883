import { useEffect } from "react";
import { selector, useRecoilValue } from "recoil";
import {
  EXISTING_TURBINE_OVERLAP_DISTANCE,
  overlappingPointFeatures,
} from "state/layout";
import { ValidationWarningTypes } from "./utils";
import { useValidationWarnings } from "./ValidationWarnings";
import { existingTurbinesFeaturesSelector } from "state/projectLayers";

export const existingTurbineOverlapWarningSelector = selector<
  | {
      type: ValidationWarningTypes.ExistingTurbineOverlap;
      featureIds: string[];
    }
  | undefined
>({
  key: "existingTurbineOverlapWarningSelector",
  get: ({ get }) => {
    const existingTurbines = get(existingTurbinesFeaturesSelector).filter(
      (t) => t.properties.power,
    );
    const overlaps = overlappingPointFeatures(
      existingTurbines,
      EXISTING_TURBINE_OVERLAP_DISTANCE,
    );

    return overlaps.length === 0
      ? undefined
      : {
          type: ValidationWarningTypes.ExistingTurbineOverlap,
          featureIds: overlaps.map((t) => t.id),
        };
  },
});

export const ExistingTurbineOverlap = () => {
  const turbineOverlapWarning = useRecoilValue(
    existingTurbineOverlapWarningSelector,
  );
  const { warn, remove } = useValidationWarnings();
  useEffect(() => {
    if (!turbineOverlapWarning) return;
    warn(turbineOverlapWarning);
    return () => {
      remove(ValidationWarningTypes.ExistingTurbineOverlap);
    };
  }, [turbineOverlapWarning, remove, warn]);

  return null;
};
