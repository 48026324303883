import { CABLE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useCableSettings";
import { EXPORT_CABLE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useExportCableSettings";
import { MOORING_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useMooringSettings";
import { SUBSTATION_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useSubstationSettings";
import { TURBINE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useTurbineSettings";
import { atom } from "recoil";
import { z } from "zod";

export const showComponentsPreviewAtom = atom<{
  show: boolean;
  componentType?:
    | typeof TURBINE_MENU_ID
    | typeof MOORING_MENU_ID
    | typeof CABLE_MENU_ID
    | typeof SUBSTATION_MENU_ID
    | typeof EXPORT_CABLE_MENU_ID;
}>({
  key: "showComponentsPreviewAtom",
  default: { show: false },
});

export const _PreviewItem = z.object({
  id: z.string(),
  name: z.string(),
  cost: z.number(),
  costUnit: z.string(),
  level: z.enum(["project", "library"]).optional(),
});

export type PreviewItem = z.infer<typeof _PreviewItem>;
