/// <reference types="vite-plugin-svgr/client" />
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import AnchorIcon from "@icons/24/Anchor.svg?react";
import DeleteIcon from "@icons/24/Bin.svg?react";

import { inReadOnlyModeSelector } from "../../../../state/project";
import { selectedMenuItemState } from "../../Shared/state";
import { currentMooringLineTypesState } from "../../../../state/mooringLineType";
import {
  MooringLineType,
  _MooringLineType,
} from "../../../../services/mooringLineTypeService";
import useMooringLineTypeCrud from "../../../../hooks/useMooringLineTypeCrud";
import {
  DIAMETER_DEFAULT,
  EA_DEFAULT,
  MATERIAL_DEFAULT,
  SingleLineType,
  WETWEIGHT_DEFAULT,
} from "../../../ConfigurationModal/MooringSettings";
import { toastMessagesAtom } from "../../../../state/toast";
import { DefaultCosts } from "../../../ConfigurationModal/Cost/constants";
import { CostUnit } from "../../../../types/financial";
import { useTypedPath } from "../../../../state/pathParams";
import { SettingsSubMenuProp } from "components/SettingsV2/Shared/types";

export const MOORING_MENU_ID = "mooring";

export default function useMooringSettings() {
  const { projectId } = useTypedPath("projectId");
  const isReadOnly = useRecoilValue(inReadOnlyModeSelector);
  const { update, create, deleteMooringLine } = useMooringLineTypeCrud();
  const lineTypes = useRecoilValue(currentMooringLineTypesState);
  const setToastMessages = useSetRecoilState(toastMessagesAtom);
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);

  const [menuSelection, setMenuSelection] = useRecoilState(
    selectedMenuItemState({ menuId: MOORING_MENU_ID, projectId }),
  );

  useEffect(() => {
    if (!menuSelection) {
      const firstItem = lineTypes[0];
      if (firstItem) {
        setMenuSelection(firstItem.id);
      }
    }
  }, [lineTypes, menuSelection, setMenuSelection]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const addMooringLine = useCallback(
    async (mooringLine: Partial<MooringLineType>) => {
      setIsLoading(true);
      setSaveInProgress(true);
      await create(mooringLine)
        .then((result) => {
          if (result) {
            setMenuSelection(result.id);
          }
        })
        .finally(() => {
          setSaveInProgress(false);
          setIsLoading(false);
        });
    },
    [create, setMenuSelection, setSaveInProgress],
  );

  const _onDuplicate = useCallback(
    async (lineId: string) => {
      const line = lineTypes.find((mooringItem) => mooringItem.id === lineId);
      if (!line) {
        return;
      }
      setIsLoading(true);
      setSaveInProgress(true);
      const clone = JSON.parse(
        JSON.stringify(line),
      ) as Partial<MooringLineType>;
      clone.name = `${clone.name} copy`;
      delete clone.id;

      try {
        await create(clone);
      } catch {
      } finally {
        setSaveInProgress(false);
        setIsLoading(false);
      }
    },
    [create, lineTypes, setSaveInProgress],
  );

  const _onDelete = useCallback(
    async (lineId: string) => {
      setIsLoading(true);
      try {
        await deleteMooringLine(lineId);
      } catch {}
      setIsLoading(false);
    },
    [deleteMooringLine],
  );

  const _onUpdate = useCallback(
    async (newMooringLine: MooringLineType) => {
      setIsLoading(true);

      const valid = _MooringLineType.safeParse(newMooringLine).success;
      if (!valid) return;

      try {
        await update(newMooringLine);
        setToastMessages((tm) => [
          ...tm,
          {
            text: "Saved",
            timeout: 3000,
            type: "success",
          },
        ]);
      } catch {}
      setIsLoading(false);
    },
    [setToastMessages, update],
  );

  const mooringSubMenus: SettingsSubMenuProp[] = useMemo(() => {
    if (!lineTypes) return [];

    const lineList = lineTypes.map((line) => {
      return {
        id: line.id,
        label: line.name,
        loading: isLoading,
        content: (
          <div style={{ height: "100%", position: "relative" }}>
            <SingleLineType
              disabled={isReadOnly}
              key={line.id}
              line={line}
              onSave={_onUpdate}
              isLoading={isLoading}
            />
          </div>
        ),
        onDuplicate: _onDuplicate,
        onChangeName: (newName: string) => {
          return _onUpdate({ ...line, name: newName });
        },
        dotMenuOptions: [
          {
            title: "Delete",
            onSelect: _onDelete,
            icon: <DeleteIcon />,
          },
        ],
      };
    });
    return [
      {
        title: "Project mooring lines",
        items: lineList,
        create: {
          type: "action",
          title: "New line type",
          saveInProgress: saveInProgress,
          onCreate: () =>
            addMooringLine({
              name: `Line type ${lineTypes.length + 1}`,
              material: MATERIAL_DEFAULT,
              diameter: DIAMETER_DEFAULT,
              EA: EA_DEFAULT,
              wetWeight: WETWEIGHT_DEFAULT,
              cost: DefaultCosts.mooring.line[CostUnit.millionEuroPerKM],
              costUnit: CostUnit.millionEuroPerKM,
            }),
          disabled: isLoading,
        },
      },
    ];
  }, [
    lineTypes,
    isLoading,
    isReadOnly,
    _onUpdate,
    _onDuplicate,
    _onDelete,
    addMooringLine,
    saveInProgress,
  ]);

  const topLevelMenu = useMemo(() => {
    return {
      id: MOORING_MENU_ID,
      label: "Mooring lines",
      icon: <AnchorIcon />,
      submenus: mooringSubMenus,
    };
  }, [mooringSubMenus]);

  return topLevelMenu;
}
