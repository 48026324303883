/// <reference types="vite-plugin-svgr/client" />
import React, {
  ReactElement,
  useState,
  useRef,
  useCallback,
  useImperativeHandle,
  ReactNode,
} from "react";
import { Place, Anchor } from "./Anchor";
import Button from "./Button";
import { Menu } from "./Menu";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import MenuVertical from "@icons/24/MenuVertical.svg?react";

export type MenuButtonRef = {
  setIsOpen(isOpen: boolean): void;
};

type Offset = Parameters<typeof Anchor>[number]["offset"];

export const MenuButton = React.forwardRef<
  MenuButtonRef,
  {
    icon: ReactElement;
    iconOpen?: ReactElement;
    iconText?: ReactElement;
    iconPosition?: Parameters<typeof Button>[0]["iconPosition"];
    buttonText?: ReactNode;
    buttonStyle?: React.CSSProperties;
    divStyle?: React.CSSProperties;
    children?: React.ReactNode;
    buttonType?: "primary" | "secondary" | "text" | "dropdown";
    /** If the menu should pop up or drop down from the button. */
    direction?: "up" | "down";
    /** The side of the button the menu should extend to. */
    side?: "left" | "right";
    onChange?: (open: boolean) => void;
    disabled?: boolean;
    offset?: Offset;
    id?: string;
    maxWidth?: number;
    title?: string;
  }
>(
  (
    {
      icon,
      iconOpen,
      iconText,
      iconPosition,
      buttonStyle,
      buttonText,
      divStyle,
      children,
      buttonType,
      direction = "down",
      side = "left",
      onChange,
      disabled,
      offset,
      id,
      maxWidth,
      title,
    },
    ref,
  ) => {
    const [open, setOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);
    const mainRef = useRef<HTMLDivElement>(null);

    const _onChangeState = useCallback(
      (open: boolean) => {
        onChange && onChange(open);
        setOpen(open);
      },
      [onChange],
    );

    useImperativeHandle(ref, () => ({
      setIsOpen: (newIsOpen: boolean) => {
        setOpen(newIsOpen);
      },
    }));

    const { basePlace, floatPlace }: { basePlace: Place; floatPlace: Place } =
      (() => {
        if (direction === "up") {
          if (side === "left") {
            return {
              basePlace: "topRight",
              floatPlace: "bottomRight",
            };
          }
          return {
            basePlace: "topLeft",
            floatPlace: "bottomLeft",
          };
        } else {
          if (side === "left") {
            return {
              basePlace: "bottomRight",
              floatPlace: "topRight",
            };
          }
          return {
            basePlace: "bottomLeft",
            floatPlace: "topLeft",
          };
        }
      })();

    return (
      <div ref={mainRef} style={divStyle}>
        <Button
          id={id}
          text={buttonText}
          style={buttonStyle}
          buttonType={buttonType ?? "text"}
          icon={open && iconOpen ? iconOpen : icon}
          iconText={iconText}
          iconPosition={iconPosition}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            _onChangeState(!open);
          }}
          disabled={disabled}
          maxWidth={maxWidth}
          title={title}
        />
        {open && (
          <Anchor
            baseRef={mainRef}
            basePlace={basePlace}
            floatPlace={floatPlace}
            offset={offset}
          >
            <Menu
              setOpen={_onChangeState}
              onClick={(e) => {
                _onChangeState(!open);
                e.stopPropagation();
                e.preventDefault();
              }}
              childRef={menuRef}
              parentRef={mainRef}
            >
              {children}
            </Menu>
          </Anchor>
        )}
      </div>
    );
  },
);

type Size = "small" | "medium" | "large";
const sizeToRem = (size: Size) => {
  if (size === "small") return 1;
  if (size === "medium") return 1.5;
  if (size === "large") return 2;
};

const DotWrapper = styled.div<{
  color?: string;
  hoverColor?: string;
  hoverType: "stroke" | "fill";
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    path {
      stroke: ${(p) => p.color ?? colors.primaryText} !important;
    }
  }

  &:hover {
    ${(p) => {
      if (p.hoverType === "fill") {
        return `
          svg {
             fill: ${p.color ?? colors.primaryText};
          }
        `;
      } else if (p.hoverType === "stroke") {
        return `
            svg {
              path {
                stroke: ${p.hoverColor ?? p.color ?? colors.primaryText} !important;
              }
            }
          `;
      }
    }}
`;

export const DotMenu = React.forwardRef<
  MenuButtonRef,
  {
    size?: Size;
    buttonStyle?: React.CSSProperties;
    divStyle?: React.CSSProperties;
    iconStyle?: React.CSSProperties;
    children?: React.ReactNode;
    buttonType?: "primary" | "secondary" | "text";
    /** If the menu should pop up or drop down from the button. */
    direction?: "up" | "down";
    /** The side of the button the menu should extend to. */
    side?: "left" | "right";
    onChange?: (open: boolean) => void;
    color?: string;
    hoverType?: "stroke" | "fill";
    hoverColor?: string;
  }
>(({ size = "medium", ...props }, ref) => {
  return (
    <MenuButton
      ref={ref}
      icon={
        <DotWrapper
          style={{ flex: 1 }}
          color={props.color}
          hoverType={props.hoverType ?? "fill"}
          hoverColor={props.hoverColor}
        >
          <MenuVertical
            style={{
              height: `${sizeToRem(size)}rem`,
              width: `${sizeToRem(size)}rem`,
              ...props.iconStyle,
            }}
          />
        </DotWrapper>
      }
      {...props}
      buttonStyle={{
        padding: "0rem",
        display: "flex",
        alignItems: "stretch",
        ...props.buttonStyle,
      }}
    />
  );
});

export const MenuButtonSpace = () => {
  return (
    <div
      style={{
        minHeight: "3.2rem",
        minWidth: "3.2rem",
        margin: "0.2rem",
      }}
    />
  );
};
