import React, { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import useTextInput from "../../../hooks/useTextInput";
import useBooleanState from "../../../hooks/useBooleanState";
import { addNewSource } from "../../../services/gisDataAPIService";
import { useToast } from "../../../hooks/useToast";
import { Input } from "../../General/Input";
import Button from "../../General/Button";
import { allDataSourcesRefreshAtom } from "../../../state/layer";
import Spinner from "@icons/spinner/Spinner";

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
`;

const AddSourceTab = () => {
  const [isLoading, toggleIsLoading] = useBooleanState(false);
  const [sourceName, onSourceNameChange] = useTextInput("");
  const setAllDataSourcesRefresh = useSetRecoilState(allDataSourcesRefreshAtom);

  const { success, error: showError } = useToast();

  const onSubmit = useCallback(async () => {
    toggleIsLoading();
    try {
      const response = await addNewSource(sourceName);
      console.log(response);
      success("Source was added", { timeout: 5000 });
      setAllDataSourcesRefresh((curr) => curr + 1);
    } catch (err) {
      if (err instanceof Error) showError(err.message);
    } finally {
      toggleIsLoading();
    }
  }, [
    setAllDataSourcesRefresh,
    showError,
    sourceName,
    success,
    toggleIsLoading,
  ]);

  return (
    <div>
      <Input
        placeholder="Source name"
        value={sourceName}
        onChange={onSourceNameChange}
        style={{ width: "100%" }}
      />

      <ButtonWrapper>
        <Button
          text="Submit"
          buttonType="primary"
          onClick={onSubmit}
          disabled={isLoading || sourceName === ""}
          icon={isLoading ? <Spinner size="1rem" /> : undefined}
        />
      </ButtonWrapper>
    </div>
  );
};

export default AddSourceTab;
