import { useRecoilCallback } from "recoil";
import { editOrgName } from "../service";
import { organisationsState } from "../state";

export const useEditOrganisation = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      async (orgId: string, newName: string) => {
        const fallback = await snapshot.getPromise(organisationsState);

        set(organisationsState, (curr) =>
          [...curr].map((org) =>
            org.id === orgId ? { ...org, name: newName } : org,
          ),
        );

        return editOrgName(orgId, newName).catch(() => {
          set(organisationsState, fallback);
        });
      },
    [],
  );
};
