import { TurbineType } from "../../../../types/turbines";
import { isNumber } from "../../../../utils/predicates";

export function formatCurveToText(
  speed: number[],
  power: number[],
  thrust: number[],
) {
  return speed
    .map(
      (s, i) =>
        `${s.toFixed(2)} ${power[i].toFixed(0)} ${thrust[i].toFixed(3)}`,
    )
    .join("\n");
}

export const formatInput = (input: string) => input.replaceAll("\t", " ");

export function textToCurve(text: string) {
  const lines = text.trim().split("\n");
  const parsed = lines.map((l) =>
    l
      .replace(/\s+/g, " ")
      .replaceAll(",", ".")
      .replaceAll("\t", " ")
      .split(" "),
  );
  const speed = parsed.map((p) => parseFloat(p[0]));
  const power = parsed.map((p) => parseFloat(p[1]));
  const thrust = parsed.map((p) => parseFloat(p[2]));
  return { speed, power, thrust };
}

export function validCurves(turbine: TurbineType) {
  const n = turbine.windSpeed.length;
  const equalLengths =
    turbine.windSpeed.length === n && turbine.thrustCoefficient.length === n;
  const numbersOnly =
    turbine.windSpeed.reduce((a, s) => isNumber(s) && a, true) &&
    turbine.power.reduce((a, s) => isNumber(s) && a, true) &&
    turbine.thrustCoefficient.reduce((a, s) => isNumber(s) && a, true);
  return numbersOnly && equalLengths;
}
