import { useAuth0 } from "@auth0/auth0-react";
import { Anchor } from "components/General/Anchor";
import { Row } from "components/General/Layout";
import { Menu, MenuItem } from "components/General/Menu";
import VindLogo from "@icons/vindlogo/vindLogo.svg?react";
import SinglePersonIcon from "@icons/24/SinglePerson.svg?react";
import LogoutIcon from "@icons/14/Logout.svg?react";
import SettingsIcon from "@icons/24/Settings.svg?react";
import LearnIcon from "@icons/24/Learn.svg?react";
import ViewOffIcon from "@icons/24/ViewOff.svg?react";
import ArrowDownIcon from "@icons/24/ArrowDown.svg?react";
import ArrowRightIcon from "@icons/24/ArrowRight.svg?react";
import {
  TitleBarDiv,
  VindLogoWrapper,
  UserSettingsLink,
} from "components/Projects/styles";
import UserImageRound from "components/UserImage/UserImageRound";
import { UserProfileModalType } from "components/UserProfileModal/UserProfileModal";
import { useRef, useState } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { invalidateToken } from "services/invalidateTokenServiceAPI";
import { modalTypeOpenAtom } from "state/modal";
import {
  loggedInUserSelector,
  LOCAL_STORAGE_TOKEN_KEY,
  memberInOrganisationSelectorFamily,
} from "state/user";
import { organisationsState, selectedOrgTabState } from "./state";
import { Link, useNavigate } from "react-router-dom";
import { nodeIdSelector, organisationIdSelector } from "state/pathParams";
import { organisationRightSideModal } from "./OrganisationRightSide/state";
import OrganisationImageRound from "./Image/OrganisationImageRound";
import { colors } from "styles/colors";
import { VerticalDivider } from "components/General/VerticalDivider";
import { OrgSettingsModalType } from "./OrgSettingsModal";
import { MenuButton } from "components/General/MenuButton";
import { SVGStrokeWrapper } from "@icons/svgUtils";
import InviteToOrganisationButton from "./Invites/InviteToOrganisation/InviteToOrganisationButton";
import { usePutUserHintSettings } from "components/ActiveTips/CloseableHints/hooks";
import { userHintSettingsAtom } from "components/ActiveTips/CloseableHints/state";
import { LearningSessions } from "components/LearnVind/LearnVindGrid";
import { useToast } from "hooks/useToast";
import { topLevelNodeFromOrgIdAndNodeIdSelectorFamily } from "components/Projects/useOrganisationFolderCrud";
import { Mixpanel } from "mixpanel";
import { IconREMSize } from "styles/typography";

export const ProjectPageTitleBar = () => {
  const ref = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { logout } = useAuth0();
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const user = useRecoilValue(loggedInUserSelector);

  const userSettings = useRecoilValue(userHintSettingsAtom);
  const putUserHintSettings = usePutUserHintSettings();

  const organisationId = useRecoilValue(organisationIdSelector);
  const nodeId = useRecoilValue(nodeIdSelector);
  const topLevelFolder = useRecoilValue(
    topLevelNodeFromOrgIdAndNodeIdSelectorFamily({
      organisationId,
      nodeId: nodeId,
    }),
  );
  const toplevelNodeId = topLevelFolder?.id;

  const { info } = useToast();

  const toggleLearnVind = (show: boolean) => {
    Mixpanel.track("LearnVind toggle", { button: "profile", show });
    let updatedSettings: string[] = [];

    if (show) {
      updatedSettings =
        userSettings.hideLearningSessions?.filter(
          (key) => key !== LearningSessions.LearningVind,
        ) ?? [];
      if (toplevelNodeId !== undefined) {
        info("Learn is added to Home");
      }
    } else {
      updatedSettings = [
        ...(userSettings.hideLearningSessions ?? []),
        LearningSessions.LearningVind,
      ];
      if (toplevelNodeId !== undefined) {
        info("Learn is removed from Home");
      }
    }

    putUserHintSettings({
      hideLearningSessions: [...updatedSettings],
    });
  };

  const link = `/organisation/${organisationId}`;
  const setSelectedOrgTab = useSetRecoilState(selectedOrgTabState);

  return (
    <TitleBarDiv>
      <VindLogoWrapper>
        <Link
          to={link}
          onClick={() => {
            setSelectedOrgTab("Projects");
          }}
          style={{ textDecoration: "none" }}
        >
          <IconREMSize height={2} width={11}>
            <VindLogo />
          </IconREMSize>
        </Link>
      </VindLogoWrapper>
      <Row style={{ gap: "2.4rem", alignItems: "center" }}>
        <Row style={{ gap: "0.8rem", alignItems: "center" }}>
          <OrgSelection />
          <InviteToOrganisationButton buttonType="secondary" />
        </Row>
        <VerticalDivider />
        {user && (
          <div style={{ display: "flex", alignItems: "center" }} ref={ref}>
            {menuOpen && (
              <Anchor
                baseRef={ref}
                basePlace="bottomRight"
                floatPlace="topRight"
              >
                <Menu
                  childRef={menuRef}
                  setOpen={setMenuOpen}
                  style={{ minWidth: "15rem" }}
                >
                  <MenuItem
                    icon={<SinglePersonIcon />}
                    name="Profile settings"
                    onClick={() => {
                      setModalTypeOpen({
                        modalType: UserProfileModalType,
                        metadata: { user },
                      });
                    }}
                  />
                  {(userSettings.hideLearningSessions?.includes(
                    LearningSessions.LearningVind,
                  ) && (
                    <MenuItem
                      icon={<LearnIcon />}
                      name="Show Learn Vind"
                      onClick={() => toggleLearnVind(true)}
                    />
                  )) || (
                    <MenuItem
                      icon={<ViewOffIcon />}
                      name="Hide Learn Vind"
                      onClick={() => toggleLearnVind(false)}
                    />
                  )}
                  <MenuItem
                    icon={<LogoutIcon />}
                    name="Log out"
                    onClick={async () => {
                      localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
                      await invalidateToken();
                      logout({ returnTo: "https://vind.ai" });
                    }}
                  />
                </Menu>
              </Anchor>
            )}
            <UserSettingsLink
              onClick={() => {
                setMenuOpen(!menuOpen);
              }}
            >
              <UserImageRound user={user} />
              {user.nickname}
            </UserSettingsLink>
          </div>
        )}
      </Row>
    </TitleBarDiv>
  );
};

function OrgSelection() {
  const organisations = useRecoilValue(organisationsState);
  const organisationId = useRecoilValue(organisationIdSelector);
  const navigate = useNavigate();
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const currentOrg = organisations.find((org) => org.id === organisationId);
  const setContent = useSetRecoilState(
    organisationRightSideModal(organisationId ?? ""),
  );
  const isMemberInOrg = useRecoilValue(
    memberInOrganisationSelectorFamily({ organisationId }),
  );

  return (
    <Row
      style={{
        alignItems: "center",
        gap: "0",
      }}
    >
      {currentOrg && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Link
            style={{ textDecoration: "none" }}
            onClick={() => {
              setContent(undefined);
            }}
            to={`/organisation/${organisationId}/${location.search}`}
          >
            <OrganisationImageRound
              organisation={currentOrg}
              size={3.2}
              thumbnailSize={256}
              style={{ border: "1px solid white", borderRadius: "50%" }}
            />
          </Link>
        </div>
      )}
      {organisations.length > 1 ? (
        <MenuButton
          icon={
            <SVGStrokeWrapper size={1}>
              <ArrowRightIcon />
            </SVGStrokeWrapper>
          }
          iconOpen={
            <SVGStrokeWrapper size={1}>
              <ArrowDownIcon />
            </SVGStrokeWrapper>
          }
          buttonStyle={{
            border: "none",
            flexDirection: "row-reverse",
            backgroundColor: "transparent",
            color: "white",
          }}
          buttonType="dropdown"
          buttonText={currentOrg?.name ?? ""}
        >
          {isMemberInOrg && (
            <>
              <MenuItem
                name={"Organisation settings"}
                onClick={() =>
                  setModalTypeOpen({ modalType: OrgSettingsModalType })
                }
                icon={<SettingsIcon />}
              />
              <div
                style={{
                  width: "100%",
                  borderTop: `1px solid ${colors.inputOutline}`,
                }}
              />
            </>
          )}
          {organisations.map((org) => (
            <MenuItem
              key={org.id}
              name={org.name ?? ""}
              onClick={() => {
                const newPath = `/organisation/${org.id}${location.search}`;
                navigate(newPath);
              }}
              icon={
                <OrganisationImageRound
                  organisation={org}
                  size={1.6}
                  thumbnailSize={256}
                />
              }
            />
          ))}
        </MenuButton>
      ) : (
        <h4 style={{ margin: "0 0.8rem", color: "white" }}>
          {currentOrg?.name}
        </h4>
      )}
    </Row>
  );
}
