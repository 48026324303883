import React, { useCallback, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import AddIcon from "@icons/24/Add.svg?react";
import DuplicateAltIcon from "@icons/24/DuplicateAlt.svg?react";
import StackedIcon from "@icons/24/Stacked.svg?react";
import Spinner from "@icons/spinner/Spinner";
import { IconREMSize } from "styles/typography";
import { colors } from "styles/colors";
import { useRecoilValueDef } from "utils/recoil";
import { scream } from "utils/sentry";
import BrowserStyleTabs, {
  TabItemProps,
} from "components/General/BrowserStyleTabs/BrowserStyleTabs";
import {
  TabItemText,
  TabItemWrapper,
} from "components/General/BrowserStyleTabs/style";
import { editorAccessProjectSelector } from "state/user";
import {
  branchIdSelector,
  organisationIdSelector,
  projectIdSelector,
} from "state/pathParams";
import {
  projectBranchesAtomFamily,
  useCreateBranch,
  useDuplicateBranch,
  useUpdateBranch,
} from "state/timeline";
import { useToast } from "hooks/useToast";
import AllBranchesFrame from "./components/AllBranchesFrame";
import useNavigateToBranch, { getPathToBranch } from "./useNavigateToBranch";
import {
  allBranchesFrameLoadingAtom,
  allBranchesFrameOpenedAtom,
  openedBranchesTabsSelectorFamily,
  openedBranchIdsTabsAtomFamily,
} from "./state";
import { EditableTextInternalState } from "components/General/EditableText";
import { BranchMeta } from "types/api";
import { TourStep } from "components/OnboardingTours/TourStep";
import { useTrackEvent } from "components/OnboardingTours/state";
import { HighlightStep } from "components/OnboardingTours/HighlightWrapper";
import { TopRightModeActiveAtom } from "components/RightSide/InfoModal/ProjectFeatureInfoModal/state";
import {
  useFloating,
  offset,
  FloatingFocusManager,
  ReferenceType,
} from "@floating-ui/react";

const DuplicateBranchIcon = ({
  nodeId,
  branchId,
  active,
  innerRef,
}: {
  nodeId: string;
  branchId: string;
  active: boolean;
  innerRef: any;
}) => {
  const duplicateBranch = useDuplicateBranch();
  const navigateToBranch = useNavigateToBranch();
  const [, setIsCreatingBranch] = useRecoilState(allBranchesFrameLoadingAtom);
  const trackEvent = useTrackEvent();

  const onDuplicateBranch = useCallback(async () => {
    setIsCreatingBranch(true);
    const res = await duplicateBranch(nodeId, branchId);
    if (!res) return;
    const { meta } = res;
    setIsCreatingBranch(false);
    navigateToBranch(meta.id, true);
    trackEvent("branchDuplicated");
  }, [
    setIsCreatingBranch,
    duplicateBranch,
    nodeId,
    branchId,
    navigateToBranch,
    trackEvent,
  ]);

  return (
    <>
      <HighlightStep
        tourId="general-intro-tour"
        stepId="duplicateBranch"
        padding="3px"
      >
        <IconREMSize
          className="visible-on-hover"
          height={1.2}
          width={1.2}
          ref={innerRef}
          title="Duplicate branch"
          hoverFill={colors.blue600}
          fill={active ? undefined : colors.white}
          onClick={(e) => {
            e.stopPropagation();
            return onDuplicateBranch();
          }}
        >
          <DuplicateAltIcon />
        </IconREMSize>
      </HighlightStep>
    </>
  );
};

const BranchTabBarWrapper = styled.div`
  display: flex;
  position: relative;
  background-color: ${colors.blue800};
  z-index: 4;
`;

const TabBranchItem = ({
  item,
  active,
  organisationId,
  branchId,
  nodeId,
  renameBranch,
  editorAccessProject,
  innerRef,
}: {
  item: TabItemProps;
  active: boolean;
  organisationId: string;
  branchId: string;
  nodeId: string;
  renameBranch: (branchMeta: BranchMeta, name: string) => void;
  editorAccessProject: boolean;
  innerRef?: ((node: ReferenceType | null) => void) &
    ((node: ReferenceType | null) => void);
}) => {
  const [isRenaming, setIsRenaming] = useState(false);

  return (
    <>
      <EditableTextInternalState
        disabled={!editorAccessProject}
        smallInput={true}
        overlap={true}
        selected={branchId === item.id}
        value={item.title}
        renderText={(text) => (
          <a
            style={{
              textDecoration: "none",
            }}
            href={getPathToBranch(organisationId, nodeId, item.id)}
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <TabItemText>{text}</TabItemText>
          </a>
        )}
        editTextColor={
          branchId === item.id ? colors.textBrand : colors.textNegative
        }
        editIconHoverColor={colors.blue600}
        editIconColor={branchId === item.id ? colors.blue800 : colors.white}
        textContainerStyle={{
          padding: 0,
        }}
        isEditing={isRenaming}
        onEnter={(newName) => {
          renameBranch(item as any, newName);
        }}
        onAfter={() => {
          setIsRenaming(false);
        }}
      />
      {editorAccessProject && (
        <React.Suspense
          fallback={
            <IconREMSize
              height={1.2}
              width={1.2}
              title="Duplicate branch"
              hoverFill={colors.blue600}
            >
              <DuplicateAltIcon />
            </IconREMSize>
          }
        >
          <DuplicateBranchIcon
            active={active}
            nodeId={nodeId}
            branchId={item.id}
            innerRef={innerRef}
          />
        </React.Suspense>
      )}
    </>
  );
};

const BranchTabBar = ({ children }: React.PropsWithChildren) => {
  const organisationId = useRecoilValueDef(organisationIdSelector);
  const nodeId = useRecoilValueDef(projectIdSelector);
  const branchId = useRecoilValueDef(branchIdSelector);
  const [openedBranchIds, setOpenedBranchIds] = useRecoilState(
    openedBranchIdsTabsAtomFamily({ nodeId }),
  );
  const openedBranches = useRecoilValue(
    openedBranchesTabsSelectorFamily({ nodeId }),
  );
  const allProjectBranches = useRecoilValue(
    projectBranchesAtomFamily({ nodeId }),
  );
  const [isCreatingBranch, setIsCreatingBranch] = useRecoilState(
    allBranchesFrameLoadingAtom,
  );
  const [branchFrameVisible, setBranchFrameVisible] = useRecoilState(
    allBranchesFrameOpenedAtom,
  );
  const { error: showError } = useToast();
  const { create: createBranch } = useCreateBranch();
  const navigateToBranch = useNavigateToBranch();
  const editorAccessProject = useRecoilValue(editorAccessProjectSelector);

  const tabs = useMemo<TabItemProps[]>(() => {
    return openedBranches.map((branch) => ({
      id: branch.id,
      text: branch.title,
      title: branch.title,
    }));
  }, [openedBranches]);

  const updateBranch = useUpdateBranch();

  const renameBranch = useCallback(
    async (branchMeta: BranchMeta, name: string) => {
      if (!branchMeta) return;
      if (name === branchMeta?.title || name.length === 0) {
        return;
      }

      await updateBranch({ ...branchMeta, title: name });
    },
    [updateBranch],
  );

  const onCreateBranch = useCallback(async () => {
    setIsCreatingBranch(true);
    try {
      const { meta } = await createBranch(nodeId, {
        title: `Branch ${allProjectBranches.length + 1}`,
      });
      navigateToBranch(meta.id, true);
    } catch (err) {
      if (err instanceof Error) {
        scream(err, {
          nodeId,
        });
      } else {
        scream("Failed to create branch", {
          nodeId,
          err,
        });
      }
      showError("Failed to create branch, please try again.");
    } finally {
      setIsCreatingBranch(false);
    }
  }, [
    allProjectBranches.length,
    createBranch,
    navigateToBranch,
    nodeId,
    showError,
    setIsCreatingBranch,
  ]);

  const setTopRightModeActive = useSetRecoilState(TopRightModeActiveAtom);

  const { refs, floatingStyles, context } = useFloating({
    placement: "bottom",
    middleware: [offset({ mainAxis: 10, crossAxis: 20 })],
  });

  return (
    <BranchTabBarWrapper id="branch-bar">
      <FloatingFocusManager context={context} modal={false}>
        <TourStep
          tourId="general-intro-tour"
          stepId="duplicateBranch"
          innerRef={refs.setFloating}
          style={floatingStyles}
          stepAction={() => {
            setTopRightModeActive(undefined);
          }}
        />
      </FloatingFocusManager>
      {branchFrameVisible && (
        <React.Suspense fallback={null}>
          <AllBranchesFrame onExit={() => setBranchFrameVisible(false)} />
        </React.Suspense>
      )}
      <BrowserStyleTabs
        wrapperStyle={{
          width: "unset",
          flexGrow: 1,
        }}
        items={tabs}
        selectedId={branchId}
        onClickTab={(tab) => {
          navigateToBranch(tab.id, false);
        }}
        onCloseTab={
          openedBranchIds.length > 1
            ? (tab, newSelectedIndex) => {
                setOpenedBranchIds((curr) =>
                  curr.filter((id) => id !== tab.id),
                );
                if (newSelectedIndex !== undefined && tabs[newSelectedIndex]) {
                  navigateToBranch(tabs[newSelectedIndex].id, false);
                }
              }
            : undefined
        }
        onDragTab={(oldIndex, newIndex) => {
          const item = openedBranches[oldIndex] as BranchMeta;
          const newBranchIds = [...openedBranches.map((b) => b.id)];
          newBranchIds.splice(oldIndex, 1);
          const adjustedNewIndex =
            newIndex > oldIndex ? newIndex - 1 : newIndex;
          newBranchIds.splice(adjustedNewIndex, 0, item.id);
          return setOpenedBranchIds(newBranchIds);
        }}
        leftAction={
          <TabItemWrapper
            onClick={() => setBranchFrameVisible((curr) => !curr)}
            staticSize={true}
          >
            <IconREMSize
              height={1.2}
              width={1.2}
              fill={branchFrameVisible ? colors.blue300 : undefined}
            >
              <StackedIcon />
            </IconREMSize>
            <TabItemText
              style={{
                color: branchFrameVisible ? colors.blue300 : undefined,
              }}
            >
              All
            </TabItemText>
          </TabItemWrapper>
        }
        renderItem={(item, active) => {
          return (
            <TabBranchItem
              item={item}
              active={active}
              organisationId={organisationId}
              branchId={branchId}
              nodeId={nodeId}
              renameBranch={renameBranch}
              editorAccessProject={editorAccessProject}
              innerRef={refs.setReference}
            />
          );
        }}
        rightAction={
          editorAccessProject ? (
            <TabItemWrapper
              staticSize={true}
              onClick={onCreateBranch}
              disabled={isCreatingBranch}
            >
              <IconREMSize height={1.2} width={1.2} stroke={colors.white}>
                {isCreatingBranch ? (
                  <Spinner color={colors.grey300} size="1rem" />
                ) : (
                  <AddIcon />
                )}
              </IconREMSize>
              <TabItemText>New branch</TabItemText>
            </TabItemWrapper>
          ) : null
        }
      />
      {children}
    </BranchTabBarWrapper>
  );
};

export default BranchTabBar;
