import styled from "styled-components";
import { colors, hexToRgbA } from "../../../styles/colors";
import { Column, Row } from "../../General/Layout";

export const Form = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
  padding: 1.2rem 1.2rem 0;
  width: 100%;
  box-sizing: border-box;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  justify-content: flex-end;
  margin-top: auto;
`;

export const Divider = styled.hr`
  border: 0;
  border-top: 1px solid ${hexToRgbA(colors.primaryDisabled, 0.5)};
  padding: 0;
  opacity: 0.5;
  height: 1px;
  width: 100%;
  margin: 0;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.2rem;
  padding: 0 1.2rem 1.2rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding: 1.2rem;
  max-height: 40rem;
  overflow-y: auto;
`;

export const MentionTextWrapper = styled(Row)`
  margin-left: 3.2rem;
  > * {
    &:last-child {
      margin-bottom: auto;
    }
  }
`;

export const DeleteWrapper = styled.div`
  display: flex;
  padding: 0.4rem;
  opacity: 0;
  cursor: pointer;
  transition: all 0.2s;
  > svg {
    height: 1.6rem;
    width: 1.6rem;
  }
`;

export const ReplyWrapper = styled(Column)`
  &:hover ${DeleteWrapper} {
    opacity: 0.8;
  }
`;

export const AlarmIconWrapper = styled.div<{ active: boolean }>`
  display: flex;
  cursor: pointer;
  align-items: center;
  > svg {
    width: 2.4rem;
    height: 2.4rem;
    path {
      stroke: ${(p) => (p.active ? colors.brand : colors.primaryDisabled)};
    }
  }
`;
