import { lib, constants } from "@vind-ai/mapbox-gl-draw";

import { area, centroid, length } from "@turf/turf";
import {
  kmPerPixel,
  OFFSET_PIXELS,
  snapToClosest,
  SNAP_PIXELS,
} from "./lib/snapping";
import { currentSnapLines, currentSnapPoints } from "../useUpdateSnapPoints";
import { CABLE_PROPERTY_TYPE } from "../../../constants/cabling";
import { scream, sendWarning } from "../../../utils/sentry";
import { getHumanReadableArea, getHumanReadableDistance } from "utils/geometry";

const {
  moveFeatures,
  constrainFeatureMovement,
  doubleClickZoom,
  createSupplementaryPoints,
} = lib;

const {
  noTarget,
  isOfMetaType,
  isActiveFeature,
  isInactiveFeature,
  isShiftDown,
} = lib.CommonSelectors;

const isVertex = isOfMetaType(constants.meta.VERTEX);
const isMidpoint = isOfMetaType(constants.meta.MIDPOINT);
const getLength = (feature) => length(feature);
const getArea = (feature) => area(feature);
const getCenter = (feature) => centroid(feature);

const DirectSelectWithArea = {};

// INTERNAL FUCNTIONS

DirectSelectWithArea.fireUpdate = function () {
  this.map.fire(constants.events.UPDATE, {
    action: constants.updateActions.CHANGE_COORDINATES,
    features: this.getSelected().map((f) => f.toGeoJSON()),
  });
};

DirectSelectWithArea.fireActionable = function (state) {
  this.setActionableState({
    combineFeatures: false,
    uncombineFeatures: false,
    trash: state.selectedCoordPaths.length > 0,
  });
};

DirectSelectWithArea.startDragging = function (state, e) {
  this.map.dragPan.disable();
  state.canDragMove = true;
  state.dragMoveLocation = e.lngLat;
};

DirectSelectWithArea.stopDragging = function (state) {
  this.map.dragPan.enable();
  state.dragMoving = false;
  state.canDragMove = false;
  state.dragMoveLocation = null;
};

DirectSelectWithArea.onVertex = function (state, e) {
  this.startDragging(state, e);
  const about = e.featureTarget.properties;
  const selectedIndex = state.selectedCoordPaths.indexOf(about.coord_path);
  if (!isShiftDown(e) && selectedIndex === -1) {
    state.selectedCoordPaths = [about.coord_path];
  } else if (isShiftDown(e) && selectedIndex === -1) {
    state.selectedCoordPaths.push(about.coord_path);
  }

  const selectedCoordinates = this.pathsToCoordinates(
    state.featureId,
    state.selectedCoordPaths,
  );
  this.setSelectedCoordinates(selectedCoordinates);
};

DirectSelectWithArea.onMidpoint = function (state, e) {
  this.startDragging(state, e);
  const about = e.featureTarget.properties;
  state.feature.addCoordinate(about.coord_path, about.lng, about.lat);
  this.fireUpdate();
  state.selectedCoordPaths = [about.coord_path];
};

DirectSelectWithArea.pathsToCoordinates = function (featureId, paths) {
  return paths.map((coord_path) => ({ feature_id: featureId, coord_path }));
};

DirectSelectWithArea.onFeature = function (state, e) {
  if (state.selectedCoordPaths.length === 0) this.startDragging(state, e);
  else {
    this.stopDragging(state);
  }
};

DirectSelectWithArea.dragFeature = function (state, e, delta) {
  moveFeatures(this.getSelected(), delta);
  state.dragMoveLocation = e.lngLat;
};

DirectSelectWithArea.dragVertex = function (state, e, delta) {
  const selectedCoords = state.selectedCoordPaths.map((coord_path) =>
    state.feature.getCoordinate(coord_path),
  );
  const selectedCoordPoints = selectedCoords.map((coords) => ({
    type: constants.geojsonTypes.FEATURE,
    properties: {},
    geometry: {
      type: constants.geojsonTypes.POINT,
      coordinates: coords,
    },
  }));
  const isCable = state.feature?.properties?.type === CABLE_PROPERTY_TYPE;

  const constrainedDelta = constrainFeatureMovement(selectedCoordPoints, delta);
  for (let i = 0; i < selectedCoords.length; i++) {
    const coord = selectedCoords[i];

    const currentPoint = [
      coord[0] + constrainedDelta.lng,
      coord[1] + constrainedDelta.lat,
    ];
    const copy = [...currentPoint];
    const coordIndex = parseInt(state.selectedCoordPaths[i]);
    if (
      isCable &&
      (coordIndex === 0 || coordIndex === state.feature.coordinates.length - 1)
    ) {
      return;
    }

    const dynamicSnapDistance =
      SNAP_PIXELS * kmPerPixel(e.lngLat.lat, this.map.getZoom());
    const snappingDisabled = isShiftDown(e);
    const pointToUse = snappingDisabled
      ? currentPoint
      : snapToClosest(
          currentSnapPoints,
          currentSnapLines,
          copy,
          dynamicSnapDistance,
        );

    state.feature.updateCoordinate(state.selectedCoordPaths[i], ...pointToUse);
    state.dragMoveLocation = { lng: pointToUse[0], lat: pointToUse[1] };
  }
};

DirectSelectWithArea.clickNoTarget = function () {
  this.map.fire(constants.events.SELECTION_CHANGE, {
    features: [],
  });
};

DirectSelectWithArea.clickInactive = function () {
  this.changeMode(constants.modes.SIMPLE_SELECT);
};

DirectSelectWithArea.clickActiveFeature = function (state) {
  state.selectedCoordPaths = [];
  this.clearSelectedCoordinates();
  state.feature.changed();
};

// EXTERNAL FUNCTIONS

DirectSelectWithArea.onSetup = function (opts) {
  const featureId = opts.featureId;
  const feature = this.getFeature(featureId);

  if (!feature) {
    throw sendWarning(
      "You must provide a featureId to enter direct_select mode",
      {
        opts: JSON.stringify(opts),
        featureId,
        feature,
      },
    );
  }

  if (feature.type === constants.geojsonTypes.POINT)
    throw sendWarning("direct_select mode doesn't handle point features", {
      opts: JSON.stringify(opts),
      featureId,
      feature,
      featureType: feature.type,
    });

  const state = {
    featureId,
    feature,
    dragMoveLocation: opts.startPos || null,
    dragMoving: false,
    canDragMove: false,
    selectedCoordPaths: opts.coordPath ? [opts.coordPath] : [],
  };

  this.setSelectedCoordinates(
    this.pathsToCoordinates(featureId, state.selectedCoordPaths),
  );
  this.setSelected(featureId);
  doubleClickZoom.disable(this);

  this.setActionableState({
    trash: true,
  });

  return state;
};

DirectSelectWithArea.onStop = function () {
  doubleClickZoom.enable(this);
  this.clearSelectedCoordinates();
};

DirectSelectWithArea.toDisplayFeatures = function (state, geojson, push) {
  if (state.featureId === geojson.properties.id) {
    geojson.properties.active = constants.activeStates.ACTIVE;
    push(geojson);
    if (!state.dragMoving) {
      createSupplementaryPoints(geojson, {
        map: this.map,
        midpoints: true,
        selectedPaths: state.selectedCoordPaths,
      }).forEach(push);
    }
  } else {
    geojson.properties.active = constants.activeStates.INACTIVE;
    push(geojson);
  }
  const showLength = state.feature.type === "LineString";
  const showArea = state.feature.type === "Polygon";
  const showInfo = showArea || showLength;
  if (showInfo) {
    try {
      const center = getCenter({
        ...state.feature,
        coordinates: geojson.geometry.coordinates,
      }).geometry.coordinates;

      const areaProps = showInfo
        ? {
            showInfo: true,
            area: showArea
              ? `${getHumanReadableArea(getArea(state.feature))}`
              : `${getHumanReadableDistance(getLength(state.feature) * 1000)}`,
          }
        : { showInfo: false };

      const dynamicOffset =
        OFFSET_PIXELS * kmPerPixel(center[1], this.map.getZoom());

      const centerPoint = {
        type: "Feature",
        properties: {
          ...geojson.properties,
          ...areaProps,
        },
        geometry: {
          type: "Point",
          coordinates: [center[0], center[1] + dynamicOffset],
        },
      };

      push(centerPoint);
    } catch (err) {
      scream(err, {
        message: "Error when trying to display area info on feature",
        featureId: state.featureId,
        geojsonCoordinates: geojson.geometry.coordinates,
        featureCoordinates: state.feature.coordinates,
      });
    }
  }
  this.fireActionable(state);
};

DirectSelectWithArea.onTrash = function (state) {
  // Uses number-aware sorting to make sure '9' < '10'. Comparison is reversed because we want them
  // in reverse order so that we can remove by index safely.
  state.selectedCoordPaths
    .sort((a, b) => b.localeCompare(a, "en", { numeric: true }))
    .forEach((id) => state.feature.removeCoordinate(id));
  this.fireUpdate();
  state.selectedCoordPaths = [];
  this.clearSelectedCoordinates();
  this.fireActionable(state);
  if (state.feature.isValid() === false) {
    this.deleteFeature([state.featureId]);
    this.changeMode(constants.modes.SIMPLE_SELECT, {});
  }
};

DirectSelectWithArea.onMouseMove = function (state, e) {
  // On mousemove that is not a drag, stop vertex movement.
  const isFeature = isActiveFeature(e);
  const onVertex = isVertex(e);
  const noCoords = state.selectedCoordPaths.length === 0;
  if (isFeature && noCoords)
    this.updateUIClasses({ mouse: constants.cursors.MOVE });
  else if (onVertex && !noCoords)
    this.updateUIClasses({ mouse: constants.cursors.MOVE });
  else this.updateUIClasses({ mouse: constants.cursors.NONE });

  /* this.stopDragging(state); */

  // Skip render
  return true;
};

DirectSelectWithArea.onMouseOut = function (state) {
  // As soon as you mouse leaves the canvas, update the feature
  if (state.dragMoving) this.fireUpdate();

  // Skip render
  return true;
};

DirectSelectWithArea.onTouchStart = DirectSelectWithArea.onMouseDown =
  function (state, e) {
    if (isVertex(e)) return this.onVertex(state, e);
    if (isActiveFeature(e)) return this.onFeature(state, e);
    if (isMidpoint(e)) return this.onMidpoint(state, e);
  };

DirectSelectWithArea.onDrag = function (state, e) {
  if (state.canDragMove !== true) return;
  state.dragMoving = true;
  e.originalEvent.stopPropagation();

  const delta = {
    lng: e.lngLat.lng - state.dragMoveLocation.lng,
    lat: e.lngLat.lat - state.dragMoveLocation.lat,
  };
  if (state.selectedCoordPaths.length > 0) this.dragVertex(state, e, delta);
  else {
    const isCable = state.feature?.properties?.type === CABLE_PROPERTY_TYPE;
    if (isCable) return;
    this.dragFeature(state, e, delta);
    state.dragMoveLocation = e.lngLat;
  }
};

DirectSelectWithArea.handleClickNoTarget = function (_state, _e) {
  this.changeMode(constants.modes.SIMPLE_SELECT, {});
};

DirectSelectWithArea.onClick = function (state, e) {
  if (noTarget(e)) return this.handleClickNoTarget(state, e);
  if (isActiveFeature(e)) return this.clickActiveFeature(state, e);
  if (isInactiveFeature(e)) return this.clickInactive(state, e);
  this.stopDragging(state);
};

DirectSelectWithArea.onTap = function (state, e) {
  if (noTarget(e)) return this.clickNoTarget(state, e);
  if (isActiveFeature(e)) return this.clickActiveFeature(state, e);
  if (isInactiveFeature(e)) return this.clickInactive(state, e);
};

DirectSelectWithArea.onTouchEnd = DirectSelectWithArea.onMouseUp = function (
  state,
) {
  if (state.dragMoving) {
    this.fireUpdate();
  }
  this.stopDragging(state);
};

export default DirectSelectWithArea;
