import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import StackedUserImages from "components/UserImage/StackedUserImages";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { memberInOrganisationSelectorFamily } from "state/user";
import { allNodeUsersSelectorFamily } from "./state";
import { useVisibleObserver } from "hooks/useVisibleObserver";

function NodeMembersListInner({
  organisationId,
  nodeId,
}: {
  organisationId: string;
  nodeId: string;
}) {
  const projectMembers = useRecoilValue(
    allNodeUsersSelectorFamily({ organisationId, nodeId }),
  );
  const setContent = useSetRecoilState(
    organisationRightSideModal(organisationId),
  );
  const isMemberInOrg = useRecoilValue(
    memberInOrganisationSelectorFamily({ organisationId }),
  );

  return (
    <StackedUserImages
      onClick={
        isMemberInOrg
          ? () => setContent({ type: "project", id: nodeId })
          : undefined
      }
      users={projectMembers}
      size={2.5}
    />
  );
}
export function NodeMembersList({
  organisationId,
  nodeId,
}: {
  organisationId: string;
  nodeId: string;
}) {
  const { isVisible, elementRef } = useVisibleObserver();

  return (
    <div ref={elementRef} style={{ minHeight: "2.5rem" }}>
      {isVisible && (
        <NodeMembersListInner organisationId={organisationId} nodeId={nodeId} />
      )}
    </div>
  );
}
