import { atom, selector, useRecoilValue } from "recoil";
import { suspendThisSelector } from "utils/recoil";
import { sendWarning } from "utils/sentry";

/** Hook for getting an object with the IDs that we have stored in the path. All
 * requested fields are checked if they are present.  Intended usage is to
 * requetst fields you know are in the path, for instance, `parkId` when you are
 * in layout generation mode.  Example usage:
 * ```
 * const { projectId, parkId } = useTypedPath('projectId', 'parkId');
 * ```
 *
 * @deprecated This hook is no good because it is just a shortcut to spam sentry
 * and pass `""` ids around. New code should avoid using this, and rather use
 * `useRecoilValue(parkIdSelector)` and friends instead.
 *
 */
export const useTypedPath = <K extends keyof PathParams>(
  ...args: K[]
): { [k in K]: string } => {
  // Note: for components that are not in the route which sets the `useParams` we have used
  // this recoil state to store the IDs.
  const recoilParams = useRecoilValue(pathParamsAtom_DontUseThisDirectly);
  const ret: Partial<{ [k in K]: string }> = {};
  for (const field of args) {
    if (field in recoilParams) ret[field] = recoilParams[field];
    else {
      sendWarning("useTypedPath: something was undefined", {
        recoilParams,
        field,
      });
      ret[field] = "";
    }
  }
  return ret as { [k in K]: string };
};

type PathParams = Partial<
  Record<
    "organisationId" | "nodeId" | "projectId" | "branchId" | "parkId",
    string
  >
>;

/**
 * This atom contains an object that will change every time we change any path params.
 * This will probably lead to re-renders even though the field you looked at didn't change.
 * Prefer any of the selectors `projectIdSelector` and so on.
 */
export const pathParamsAtom_DontUseThisDirectly = atom<PathParams>({
  key: "pathParamsAtom",
  default: {},
});

export const parkIdSelector = selector<string | undefined>({
  key: "parkIdSelector",
  get: ({ get }) => get(pathParamsAtom_DontUseThisDirectly).parkId,
});

export const parkIdSelector_ = selector<string>({
  key: "parkIdSelector_",
  get: ({ get }) =>
    get(pathParamsAtom_DontUseThisDirectly).parkId ?? get(suspendThisSelector),
});

export const hasParkSelector = selector<boolean>({
  key: "hasParkSelector",
  get: ({ get }) => Boolean(get(pathParamsAtom_DontUseThisDirectly).parkId),
});

export const branchIdSelector = selector<string | undefined>({
  key: "branchIdSelector",
  get: ({ get }) => get(pathParamsAtom_DontUseThisDirectly).branchId,
});

export const branchIdSelector_ = selector<string>({
  key: "branchIdSelector_",
  get: ({ get }) =>
    get(pathParamsAtom_DontUseThisDirectly).branchId ??
    get(suspendThisSelector),
});

export const projectIdSelector = selector<string | undefined>({
  key: "projectIdSelector",
  get: ({ get }) => get(pathParamsAtom_DontUseThisDirectly).projectId,
});

export const projectIdSelector_ = selector<string>({
  key: "projectIdSelector_",
  get: ({ get }) =>
    get(pathParamsAtom_DontUseThisDirectly).projectId ??
    get(suspendThisSelector),
});

export const organisationIdSelector = selector<string | undefined>({
  key: "organisationIdSelector",
  get: ({ get }) => get(pathParamsAtom_DontUseThisDirectly).organisationId,
});

export const nodeIdSelector = selector<string | undefined>({
  key: "nodeIdSelector",
  get: ({ get }) => get(pathParamsAtom_DontUseThisDirectly).nodeId,
});
