import { z } from "zod";
import { fetchEnhancerWithToken } from "services/utils";
import { DEFAULT_TURBINES } from "types/turbines";
import { _ParkFeature } from "types/feature";
import { _ProjectMeta } from "types/api";

export const _PortfolioItemBase = z.object({
  branchId: z.string(),
  parkId: z.string(),
  projectId: z.string(),
});

const _PortfolioItemResponse = _PortfolioItemBase.extend({
  items: z.object({
    park: _ParkFeature,
    turbines: z.object({
      totalPower: z.number(),
      count: z.number(),
    }),
  }),
});

export type PortfolioItemBase = z.infer<typeof _PortfolioItemBase>;
export type PortfolioItemResponse = z.infer<typeof _PortfolioItemResponse>;

export const getPortfolioSimpleItems = async (
  organisationId: string,
): Promise<PortfolioItemBase[]> => {
  const response = await fetchEnhancerWithToken(
    `/api/portfolio/${organisationId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    },
  );
  const j = await response.json();
  return _PortfolioItemBase.array().parse(j);
};

export const getPortfolio = async (
  organisationId: string,
): Promise<PortfolioItemResponse[]> => {
  const response = await fetchEnhancerWithToken(
    `/api/portfolio/${organisationId}/load`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ defaultTurbines: DEFAULT_TURBINES }),
    },
  );
  const j = await response.json();
  return _PortfolioItemResponse.array().parse(j);
};

export const _MappedPortfolioItem = z.object({
  project: _ProjectMeta,
  park: _ParkFeature,
  branchId: z.string(),
  nrTurbines: z.number(),
  totalPower: z.number(),
  isFiltered: z.boolean().optional(),
});

export type MappedPortfolioItem = z.infer<typeof _MappedPortfolioItem>;
