import { Mixpanel } from "mixpanel";
import { useRecoilCallback } from "recoil";
import {
  CostConfiguration,
  CostConfigurationInput,
  _CostConfigurationInput,
  createOrgFinancialConfiguration,
  deleteOrgFinancialConfiguration,
  fetchCostConfigurationUsage,
  updateOrgFinancialConfiguration,
} from "services/costService";
import {
  costConfigurationTempName,
  libraryFinancialConfigRefreshAtom,
} from "state/costConfigurations";
import { useTypedPath } from "state/pathParams";
import { organisationFinancialResourceState } from "../state";
import { v4 as uuid } from "uuid";

const useOrgFinancialCrud = () => {
  const { organisationId } = useTypedPath("organisationId");

  const create = useRecoilCallback(
    ({ set, snapshot }) =>
      async ({
        config,
        name,
        projectAccess,
      }: {
        config: CostConfigurationInput;
        name?: string;
        projectAccess?: string[];
      }) => {
        const newConfig = {
          ...config,
          name: name ? name : `${config.name} copy`,
        };

        const fallback = await snapshot.getPromise(
          organisationFinancialResourceState({ organisationId }),
        );

        try {
          const res = await createOrgFinancialConfiguration(
            organisationId,
            newConfig,
            projectAccess,
          );
          set(organisationFinancialResourceState({ organisationId }), (cur) => [
            ...cur,
            res,
          ]);

          Mixpanel.track("Created org financial config", {
            configId: res.id,
            organisationId,
          });
          return res;
        } catch (e) {
          set(organisationFinancialResourceState({ organisationId }), fallback);
          throw e;
        }
      },
    [organisationId],
  );

  const update = useRecoilCallback(
    ({ set, snapshot }) =>
      async (config: CostConfiguration) => {
        const fallback = await snapshot.getPromise(
          organisationFinancialResourceState({ organisationId }),
        );

        return updateOrgFinancialConfiguration(organisationId, config)
          .then((res) => {
            if (res) {
              set(
                organisationFinancialResourceState({ organisationId }),
                (cur) => cur.map((c) => (c.id === config.id ? res : c)),
              );

              set(libraryFinancialConfigRefreshAtom, (cur) => cur + 1);

              Mixpanel.track("Update org financial config", {
                configId: config.id,
                organisationId,
              });
            }
          })
          .catch((e) => {
            set(
              organisationFinancialResourceState({ organisationId }),
              fallback,
            );
            throw e;
          });
      },
    [organisationId],
  );

  const remove = useRecoilCallback(
    ({ set, snapshot }) =>
      async (configId: string) => {
        const fallback = await snapshot.getPromise(
          organisationFinancialResourceState({ organisationId }),
        );

        const usage = await fetchCostConfigurationUsage(
          organisationId,
          configId,
        );

        if (
          usage.length === 0 ||
          window.confirm(
            `Financial configuration is currently being used in ${usage.length} branches, are you sure you want to delete it?`,
          )
        ) {
          return deleteOrgFinancialConfiguration(organisationId, configId)
            .then((res) => {
              if (res) {
                set(
                  organisationFinancialResourceState({ organisationId }),
                  (cur) => [...cur].filter((c) => c.id !== configId),
                );
                set(libraryFinancialConfigRefreshAtom, (cur) => cur + 1);
                Mixpanel.track("Delete org financial config", {
                  configId: configId,
                  organisationId,
                });
              }
              return {
                status: "success",
              };
            })
            .catch((e) => {
              set(
                organisationFinancialResourceState({ organisationId }),
                fallback,
              );
              throw e;
            });
        }
      },
    [organisationId],
  );

  const duplicate = useRecoilCallback(
    () =>
      async (duplicate: {
        config: CostConfiguration;
        name?: string;
        projectAccess?: string[];
      }) => {
        const config = duplicate.config;
        const clonedConfig = _CostConfigurationInput.parse({
          ...config,
          name: `${config.name} copy`,
          capex: {
            ...config.capex,
            custom: config.capex.custom.map((entry) => ({
              ...entry,
              id: uuid(),
            })),
          },
          opex: {
            ...config.opex,
            custom: config.opex.custom.map((entry) => ({
              ...entry,
              id: uuid(),
            })),
          },
        });
        return create({
          ...duplicate,
          config: clonedConfig,
        });
      },
    [create],
  );

  // This method saves the new name to the data base and the atom 'costConfigurationTempName'.
  // the new name is not updated in localConfig or costConfigurationsAtomFamily,
  // because it would result in loosing non-saved values in the config
  const saveName = useRecoilCallback(
    ({ set, snapshot }) =>
      async (id: string, name: string) => {
        set(
          costConfigurationTempName({
            nodeId: organisationId,
          }),
          (cur) => ({
            ...cur,
            [id]: name,
          }),
        );

        const currentConfigurations = await snapshot.getPromise(
          organisationFinancialResourceState({ organisationId }),
        );
        const currentConfig = currentConfigurations.find((c) => c.id === id);
        if (!currentConfig)
          throw Error("Could not find org cost config, config id: " + id);

        const updatedConfiguration: CostConfiguration = {
          ...currentConfig,
          name: name,
        };

        return updateOrgFinancialConfiguration(
          organisationId,
          updatedConfiguration,
        );
      },
    [organisationId],
  );

  // This is used to transfer the new names stored in costConfigurationTempName to costConfigurationsAtomFamily,
  // so that costConfigurationsAtomFamily is up to date with the database
  // This is called when the component is unmounted
  const saveTempNameToLocal = useRecoilCallback(
    ({ set, snapshot }) =>
      async (configurationId: string) => {
        const tempNames = await snapshot.getPromise(
          costConfigurationTempName({
            nodeId: organisationId,
          }),
        );

        const tempName = tempNames[configurationId];
        if (!tempName) return;

        set(organisationFinancialResourceState({ organisationId }), (cur) =>
          cur.map((c) =>
            c.id === configurationId ? { ...c, name: tempName } : c,
          ),
        );

        set(
          costConfigurationTempName({
            nodeId: organisationId,
          }),
          (cur) => ({
            ...cur,
            [configurationId]: undefined,
          }),
        );
      },
    [organisationId],
  );

  return { create, update, remove, duplicate, saveName, saveTempNameToLocal };
};

export default useOrgFinancialCrud;
