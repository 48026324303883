import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const versionQueryName = "version";

export const useSetVersion = () => {
  const navigate = useNavigate();
  return useCallback(
    (version: number | undefined) => {
      const searchParams = new URLSearchParams(window.location.search);
      if (!version) searchParams.delete(versionQueryName);
      if (version) searchParams.set(versionQueryName, `${version}`);

      const path =
        window.location.pathname +
        (searchParams.keys.length !== 0 ? "" : `?${searchParams.toString()}`) +
        window.location.hash;
      navigate(path);
    },
    [navigate],
  );
};
