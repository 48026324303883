import { useEffect } from "react";
import { selectorFamily, useRecoilValue } from "recoil";
import { ValidationWarningTypes } from "./utils";
import { useValidationWarnings } from "./ValidationWarnings";
import { branchIdSelector } from "state/pathParams";
import { getSubstationsInBranchSelectorFamily } from "state/cable";
import { getDivisionFeaturesInBranchSelectorFamily } from "state/division";
import { exclusionDomainUnpack } from "types/feature";
import { pointInPolygon } from "utils/geometry";

export const substationInsideNoCableExclusionZoneWarningSelectorFamily =
  selectorFamily<
    | {
        type: ValidationWarningTypes.SubstationInsideNoCableExclusionZone;
        featureIds: string[];
        parkId: string;
      }
    | undefined,
    {
      parkId: string;
      branchId: string;
    }
  >({
    key: "substationInsideNoCableExclusionZoneWarningSelectorFamily",
    get:
      ({ parkId, branchId }) =>
      ({ get }) => {
        const substations = get(
          getSubstationsInBranchSelectorFamily({ parkId, branchId }),
        );
        const { exclusionZones } = get(
          getDivisionFeaturesInBranchSelectorFamily({ parkId, branchId }),
        );

        const exclusionZonesWithoutSubstations = exclusionZones.filter(
          (e) => exclusionDomainUnpack(e.properties.domain).cable,
        );

        const overlappingSubstations = substations.filter((s) =>
          exclusionZonesWithoutSubstations.some((zone) =>
            pointInPolygon(s.geometry, zone.geometry),
          ),
        );

        return overlappingSubstations.length === 0
          ? undefined
          : {
              type: ValidationWarningTypes.SubstationInsideNoCableExclusionZone,
              featureIds: overlappingSubstations.map((s) => s.id),
              parkId: parkId,
            };
      },
  });

export const SubstationInsideNoCableExclusionZone = ({
  parkId,
}: {
  parkId: string;
}) => {
  const branchId = useRecoilValue(branchIdSelector);
  const substationInsideNoCableExclusionZoneWarning = useRecoilValue(
    substationInsideNoCableExclusionZoneWarningSelectorFamily({
      parkId: parkId,
      branchId: branchId ?? "",
    }),
  );

  const { warn, remove } = useValidationWarnings();
  useEffect(() => {
    if (!substationInsideNoCableExclusionZoneWarning) return;
    warn(substationInsideNoCableExclusionZoneWarning);

    return () => {
      remove(ValidationWarningTypes.SubstationInsideNoCableExclusionZone);
    };
  }, [substationInsideNoCableExclusionZoneWarning, remove, warn]);

  return null;
};
