/// <reference types="vite-plugin-svgr/client" />
import {
  ErrorBoundaryWrapper,
  ErrorBoundaryWarningTriangle,
  ScreamOnError,
} from "../../ErrorBoundaries/ErrorBoundaryLocal";
import styled from "styled-components";

import { Column, Row } from "components/General/Layout";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import Trashcan from "@icons/24/Bin.svg?react";
import { organisationRightSideModal } from "../OrganisationRightSide/state";
import { useTypedPath } from "state/pathParams";
import { groupMembersState, organisationGroupsState } from "./state";
import { Group } from "./types";
import { typography } from "styles/typography";
import Button from "components/General/Button";
import useGroupsCrud from "./useGroupsCrud";
import {
  ContentTableColumn,
  TableHeader,
} from "../OrganisationRightSide/style";
import { adminInOrganisationSelectorFamily } from "state/user";
import StackedUserImages from "components/UserImage/StackedUserImages";
import TeamMeeting from "@icons/24/TeamMeeting.svg?react";
import { SVGWrapper } from "@icons/svgUtils";
import AddIcon from "@icons/24/Add.svg?react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { usersInOrganisationState } from "../state";
import { isDefined } from "utils/predicates";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { IconBtn } from "components/General/Icons";
import { colors } from "styles/colors";

const StyledRow = styled(Row)`
  gap: 0;
  padding: 0 0.8rem;

  > * {
    flex: 1;
  }
`;

const Groups = ErrorBoundaryWrapper(
  () => {
    const { organisationId } = useTypedPath("organisationId");
    const groups = useRecoilValue(organisationGroupsState({ organisationId }));
    const { add } = useGroupsCrud();

    const setContent = useSetRecoilState(
      organisationRightSideModal(organisationId),
    );
    useEffect(() => {
      setContent((cur) => (cur?.type === "group" ? cur : undefined));
    }, [setContent]);

    const [saveInProgress, setSaveInProgress] = useState(false);

    const onCreate = useCallback(
      async (name: string) => {
        try {
          setSaveInProgress(true);
          const group = await add(name);
          if (group) {
            setContent({ type: "group", id: group.id });
          }
        } catch {
        } finally {
          setSaveInProgress(false);
        }
      },
      [add, setContent],
    );

    const isAdminInOrg = useRecoilValue(
      adminInOrganisationSelectorFamily({ organisationId }),
    );

    return (
      <Column style={{ gap: "2.4rem", padding: "2.4rem 0" }}>
        <StyledRow>
          <TableHeader>Name</TableHeader>
          <TableHeader>Members</TableHeader>
          {isAdminInOrg && (
            <Row style={{ alignItems: "center" }}>
              <Button
                text="Create group"
                onClick={() => onCreate(`Group ${groups.length + 1}`)}
                style={{ marginLeft: "auto" }}
                icon={<AddIcon />}
                disabled={saveInProgress}
              />
            </Row>
          )}
        </StyledRow>
        <ContentTableColumn>
          {groups.map((g) => {
            return <GroupRow key={g.id} group={g} />;
          })}
          {saveInProgress && (
            <GroupRowContainer>
              <SkeletonBlock style={{ height: "3.2rem" }} />
            </GroupRowContainer>
          )}
        </ContentTableColumn>
      </Column>
    );
  },
  ErrorBoundaryWarningTriangle,
  ScreamOnError,
);

const Text = styled.p`
  ${typography.contentAndButtons}
  margin: 0;
`;

export const GroupRowContainer = styled(Row)`
  padding: 0.7rem 1.5rem;
  align-items: center;
  cursor: pointer;
  min-height: 3.9rem;
  box-sizing: border-box;
  cursor: pointer;
  padding: 1.3rem 1.6rem;
`;

function GroupRow({ group }: { group: Group }) {
  const { organisationId } = useTypedPath("organisationId");
  const { remove } = useGroupsCrud();
  const isAdminInOrg = useRecoilValue(
    adminInOrganisationSelectorFamily({ organisationId }),
  );

  const [content, setContent] = useRecoilState(
    organisationRightSideModal(organisationId),
  );
  const isSelected = content?.id === group.id;

  const members = useRecoilValue(
    groupMembersState({ organisationId, groupId: group.id }),
  );

  const users = useRecoilValue(usersInOrganisationState(organisationId));

  const usersToShow = useMemo(
    () =>
      members
        .map((m) => m.user_id)
        .map((userId) => users.find((u) => u.user_id === userId))
        .filter(isDefined),
    [members, users],
  );

  const [saveInProgress, setSaveInProgress] = useState(false);
  const onRemove = useCallback(async () => {
    try {
      setSaveInProgress(true);
      await remove(group.id);
    } catch {
    } finally {
      setSaveInProgress(false);
    }
  }, [group.id, remove]);

  return (
    <GroupRowContainer
      key={group.id}
      style={{
        ...(saveInProgress ? { opacity: 0.6 } : {}),
        ...(isSelected ? { backgroundColor: colors.selected } : {}),
      }}
      onClick={() =>
        setContent((curr) =>
          curr && curr.id === group.id
            ? undefined
            : { type: "group", id: group.id },
        )
      }
    >
      <Row style={{ alignItems: "center", gap: "0.8rem", flex: 1 }}>
        <SVGWrapper size={1.6}>
          <TeamMeeting />
        </SVGWrapper>
        <Text>{group.name}</Text>
      </Row>
      <Row style={{ flex: 1 }}>
        <StackedUserImages users={usersToShow} size={2.2} />
      </Row>
      {isAdminInOrg && (
        <div style={{ flex: 1 }}>
          <IconBtn
            onClick={(e) => {
              e.stopPropagation();
              if (
                window.confirm("Are you sure you want to delete this group?")
              ) {
                onRemove();
                setContent(undefined);
              }
            }}
            disabled={saveInProgress}
            size={"1.4rem"}
            style={{
              marginLeft: "auto",
            }}
          >
            <Trashcan />
          </IconBtn>
        </div>
      )}
    </GroupRowContainer>
  );
}

export default Groups;
