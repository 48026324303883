import styled from "styled-components";
import { colors } from "../../styles/colors";
import { RegularRaw, typography } from "../../styles/typography";
import { borderRadius, spaceMedium } from "../../styles/space";

export type DropdownStyle = "regular" | "hidden";

type Props = {
  kind?: DropdownStyle;
  small?: boolean;
  _size?: "small" | "large";
  disabled?: boolean;
};

const Dropdown = styled.select.attrs<Props>(({ small, _size, kind }) => ({
  className: `${small || _size === "small" ? "small" : ""} ${
    kind === "hidden" ? "hidden" : ""
  }`,
}))<Props>`
  box-sizing: border-box;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  ${RegularRaw};
  ${typography.body};

  // right padding 1.2rem + chevron width 1.4rem + some space 0.4rem = 3.0rem;
  padding: 0.5rem 3rem 0.5rem 1.2rem;
  &.small {
    padding: 0.1rem 3rem 0.1rem 1.2rem;
  }

  cursor: pointer;
  color: ${colors.textPrimary};
  border: 1px solid ${colors.borderDefault};
  border-radius: ${borderRadius.small};

  overflow-x: hidden;
  text-overflow: ellipsis;

  &.hidden {
    border: none;
  }

  &:hover {
    border-color: ${colors.grey400};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${colors.textDisabled};
    background-color: ${colors.surfaceDisabled};
    border-color: ${colors.borderDefault};
  }

  &:invalid {
    border-color: ${colors.borderError};
  }

  background: transparent;
  background-image: url('data:image/svg+xml,<svg width="10" height="10" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23.25 7.31104L12.53 18.03C12.4604 18.0997 12.3778 18.1549 12.2869 18.1926C12.1959 18.2304 12.0984 18.2498 12 18.2498C11.9016 18.2498 11.8041 18.2304 11.7131 18.1926C11.6222 18.1549 11.5396 18.0997 11.47 18.03L0.75 7.31104" stroke="%23${(
    p,
  ) =>
    (p.disabled ? colors.iconDisabled : colors.textPrimary).slice(
      1,
    )}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  background-repeat: no-repeat;
  background-position: right 1.2rem center;
  &.hide-for-export {
    background-image: none;
  }

  // NOTE: This is to remove the default padding on the dropdown in Chrome. It has to go above, because we might set padding below.
  // A better solution is to use appearance: none, and add in the chevron, like we do in InputDimensioned
  // -webkit-padding-start: ${spaceMedium};
`;

export default Dropdown;
