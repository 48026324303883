import { WAKE_MODEL_NAME } from "../../../services/configurationService";
import { CenterContainer, SafeCard } from "./Base";
import { FlexGrid2 } from "../../General/Form";
import { Row } from "../../General/Layout";
import { spaceMedium } from "../../../styles/space";
import { useDashboardContext } from "../Dashboard";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import {
  getAnalysis,
  getAnalysisProgress,
  getAnalysisWindStats,
  getStoppedReason,
  getStoppedReasonFromAnalysis,
} from "components/ProductionV2/state";
import { orTextLoader } from "components/Loading/Skeleton";
import {
  analysisStoppedText,
  AnalysisStoppedTypes,
} from "components/ProductionV2/types";
import { undefMap } from "utils/utils";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";

const InputDetails = () => {
  const { configuration, triggerId } = useDashboardContext();
  const density = useRecoilValueLoadable(getAnalysisWindStats(triggerId)).map(
    (w) => w?.meanDensity,
  );
  const analysisVersion = useRecoilValueLoadable(getAnalysis(triggerId)).map(
    (a) => a?.version,
  );
  const stoppedReason = useRecoilValueLoadable(
    getStoppedReason(triggerId),
  ).valueMaybe();

  const analysisStoppedReason = useRecoilValueLoadable(
    getStoppedReasonFromAnalysis(triggerId),
  ).valueMaybe();
  const analysisProgress = useRecoilValue(getAnalysisProgress(triggerId));

  const includeBlockage = configuration?.wakeAnalysis?.blockage;
  const includeNeighbourWakeLoss = configuration?.wakeAnalysis?.neighbourWake;
  const neighbourWakeMaxDistance =
    configuration?.wakeAnalysis?.neighbourWakeMaxDistance;
  const analysisPrecision = configuration?.wakeAnalysis?.precision;

  if (stoppedReason !== undefined) {
    return (
      <CenterContainer>
        <SimpleAlert
          title="Analysis stopped"
          text={analysisStoppedText[stoppedReason]}
          type={"error"}
        />
      </CenterContainer>
    );
  }
  if (analysisStoppedReason) {
    return (
      <CenterContainer>
        <SimpleAlert
          title="Analysis stopped"
          text={
            analysisStoppedText[analysisStoppedReason as AnalysisStoppedTypes]
          }
          type={"error"}
        />
      </CenterContainer>
    );
  }

  const wakeModelName = configuration?.wakeAnalysis?.wakeModel
    ? WAKE_MODEL_NAME[configuration?.wakeAnalysis.wakeModel]
    : "-";
  return (
    <FlexGrid2 style={{ padding: spaceMedium }}>
      <Row>
        <p>Model</p>
        <p>{wakeModelName}</p>
      </Row>
      {configuration.wakeAnalysis.wakeModel === "turbopark" && (
        <>
          <Row>
            <p>Turbulence intensity</p>
            <p>{configuration.wakeAnalysis.turbulenceIntensity}</p>
          </Row>
          <Row>
            <p>Wake expansion calibration</p>
            <p>
              {configuration.wakeAnalysis.turboparkWakeExpansionCalibration}
            </p>
          </Row>
        </>
      )}
      {configuration.wakeAnalysis.wakeModel === "jensen" && (
        <Row>
          <p>Wake decay constant</p>
          <p>{configuration.wakeAnalysis.wakeExpansionFactor}</p>
        </Row>
      )}
      <Row>
        <p>Air density</p>
        {orTextLoader(
          density,
          undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ?? "",
          (a) => (
            <p>{a.toFixed(3)} kg/m3</p>
          ),
        )}
      </Row>
      <Row>
        <p>Blockage included</p>
        <p>{includeBlockage ? "Yes" : "No"}</p>
      </Row>
      <Row>
        <p>Neighbour wake loss included</p>
        <p>{includeNeighbourWakeLoss ? "Yes" : "No"}</p>
      </Row>
      {includeNeighbourWakeLoss && (
        <Row>
          <p>Neighbour wake loss max distance</p>
          <p>{neighbourWakeMaxDistance} km</p>
        </Row>
      )}
      <Row>
        <p>Analysis precision</p>
        <p>{analysisPrecision}</p>
      </Row>
      <Row>
        <p>Analysis version</p>
        {orTextLoader(
          analysisVersion,
          undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ?? "",
          (a) => (
            <p>{a}</p>
          ),
        )}
      </Row>
    </FlexGrid2>
  );
};

export const InputWidget = () => (
  <SafeCard title="Input" id="Input">
    <InputDetails />
  </SafeCard>
);
