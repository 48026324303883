import { BlockMath } from "react-katex";
import { colors } from "../../../styles/colors";
import "katex/dist/katex.min.css";
import styled from "styled-components";

function LcoeFormula() {
  return (
    <BlockMath>
      {String.raw`
        \color{${colors.lightText}}
        \Large{
          \frac {
            \left( \sum_{t=t_0}^{T-1}{\frac{DEVEX_t + CAPEX_t + OPEX_t}{(1 + r_\text{nominal})^t}} \right) + \frac{DECOM_T}{(1 + r_\text{nominal})^T}
          } {
            \sum_{t=t_{p0}}^{T-1}{\frac{AEP_{net}}{(1+r_\text{real})^t}}
          }
        }
        `}
    </BlockMath>
  );
}

const NoSelectDiv = styled.div`
  user-select: none;
`;

export function SpecificLcoeFormula({
  discountRate,
  inflationRate,
  projectStart,
  operationStart,
  decomissioning,
  currentYear,
}: {
  discountRate: number;
  inflationRate: number;
  projectStart: number;
  operationStart: number;
  decomissioning: number;
  currentYear: number;
}) {
  const r = (1 + discountRate).toFixed(3);
  const R = ((1 + discountRate) / (1 + inflationRate)).toFixed(3);

  return (
    <NoSelectDiv>
      <BlockMath>
        {String.raw`
        \LARGE{
          \text{LCoE}_{${currentYear}} = \frac {
           \left( \sum\limits_{y=${projectStart}}^{${
             decomissioning - 1
           }}{ \frac{DEVEX_y + CAPEX_y + OPEX_y}{${r}^{t}}} \right) +
        \frac{DECOM_{${decomissioning}}}{${r}^{${decomissioning - currentYear}}}
          } {
            \sum\limits_{y=${operationStart}}^{${
              decomissioning - 1
            }}{\frac{AEP_{net}}{${R}^{t}}}
          }, \quad \text{where } t = y - ${currentYear}
        }
      `}
      </BlockMath>
    </NoSelectDiv>
  );
}

export default LcoeFormula;
