/// <reference types="vite-plugin-svgr/client" />
import React from "react";
import { useRecoilValue } from "recoil";
import { getSuggestedLayersSelector } from "../../../../state/layer";
import ViewOffIcon from "@icons/24/ViewOff.svg?react";
import {
  spaceMedium,
  spaceLarge,
  spaceHuge,
  spaceSmall,
} from "../../../../styles/space";
import { ModalLayerName } from "../../../RightSide/InfoModal/InfoModal.style";
import { colors } from "../../../../styles/colors";
import { IconREMSize, Text } from "../../../../styles/typography";
import SuggestedLayer from "./SuggestedLayer";

const FrequentlyUsedDataLayers = ({ onHideClick }: { onHideClick(): void }) => {
  const suggestedLayers = useRecoilValue(getSuggestedLayersSelector);

  return (
    <div style={{ padding: "1rem 2rem" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          position: "relative",
          marginBottom: spaceMedium,
        }}
      >
        <ModalLayerName
          style={{
            backgroundColor: "white",
            display: "inline",
            zIndex: 2,
            paddingRight: spaceLarge,
          }}
        >
          Frequently used data layers
        </ModalLayerName>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
            position: "absolute",
            left: 0,
            width: "100%",
            zIndex: 1,
          }}
        >
          <div
            style={{
              flexGrow: 1,
              height: "1px",
              backgroundColor: colors.inputOutline,
              marginRight: spaceHuge,
            }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: spaceSmall,
              cursor: "pointer",
            }}
            onClick={onHideClick}
          >
            <IconREMSize height={1.2} width={1.2}>
              <ViewOffIcon />
            </IconREMSize>
            <Text>Hide layers</Text>
          </div>
          <div
            style={{
              flexGrow: 1,
              height: "1px",
              backgroundColor: colors.inputOutline,
              marginLeft: spaceHuge,
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "grid",
          gap: spaceLarge,
          overflow: "auto",
          gridTemplateColumns: `repeat(5, 1fr)`,
        }}
      >
        {suggestedLayers.map((layer) => (
          <SuggestedLayer key={layer.id} layer={layer} />
        ))}
      </div>
    </div>
  );
};

export default FrequentlyUsedDataLayers;
