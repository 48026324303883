import {
  DEFAULT_CANVAS_LAYER_COLOR,
  opacityPropertyName,
  DEFAULT_CANVAS_LAYER_OPACITY,
  displayLabelPropertyName,
  strokeWidthPropertyName,
  DEFAULT_CANVAS_LINE_STROKE_WIDTH,
} from "@constants/canvas";
import { MultiLineString, LineString } from "geojson";
import { LinePaint, SymbolLayer } from "mapbox-gl";
import { useEffect } from "react";
import { OtherFeature } from "types/feature";
import { safeRemoveLayer } from "utils/map";
import {
  getHiddenLargeLineClickLayer,
  addLayer,
  removeCodepointsFromFeatures,
} from "./utils";
import {
  otherLineStringSymbolLayerId,
  otherLineStringSourceId,
  otherLineStringLayerId,
  DEFAULT_ZOOM_THRESHOLD,
} from "./constants";

const linePaint: LinePaint = {
  "line-color": ["string", ["get", "color"], DEFAULT_CANVAS_LAYER_COLOR],
  "line-width": [
    "number",
    ["get", strokeWidthPropertyName],
    DEFAULT_CANVAS_LINE_STROKE_WIDTH,
  ],
  "line-opacity": [
    "case",
    [
      "boolean",
      ["feature-state", "hover"],
      ["feature-state", "selected"],
      false,
    ],
    [
      "+",
      ["number", ["get", opacityPropertyName], DEFAULT_CANVAS_LAYER_OPACITY],
      0.2,
    ],
    ["number", ["get", opacityPropertyName], DEFAULT_CANVAS_LAYER_OPACITY],
  ],
  "line-dasharray": [
    "case",
    ["==", ["get", "strokeStyle"], "dashed"],
    ["literal", [4, 4]],
    ["==", ["get", "strokeStyle"], "dotted"],
    ["literal", [1, 2]],
    ["literal", [1, 0]],
  ],
};

const hiddenClickLayer = getHiddenLargeLineClickLayer(
  otherLineStringLayerId,
  otherLineStringSourceId,
);

const symbolLayer: SymbolLayer = {
  id: otherLineStringSymbolLayerId,
  source: otherLineStringSourceId,
  type: "symbol",
  minzoom: DEFAULT_ZOOM_THRESHOLD,
  layout: {
    "symbol-placement": "line",
    "text-field": "{name}",
    "text-size": 12,
    "symbol-spacing": 300,
    "text-keep-upright": true,
    "text-offset": [0, 1],
  },
  paint: { "text-opacity": 0.6 },
  filter: ["boolean", ["get", displayLabelPropertyName], true],
};

export const RenderOtherLineStrings = ({
  features,
  map,
}: {
  features: OtherFeature<LineString | MultiLineString>[];
  map: mapboxgl.Map;
}) => {
  useEffect(() => {
    map.addSource(otherLineStringSourceId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    return () => {
      safeRemoveLayer(map, symbolLayer.id);
      safeRemoveLayer(map, otherLineStringLayerId);
      safeRemoveLayer(map, hiddenClickLayer.id);
      map.removeSource(otherLineStringSourceId);
    };
  }, [map]);

  useEffect(() => {
    addLayer(map, {
      id: otherLineStringLayerId,
      type: "line",
      source: otherLineStringSourceId,
      paint: linePaint,
    });
    addLayer(map, hiddenClickLayer);
    addLayer(map, symbolLayer);
  }, [map]);

  useEffect(() => {
    const source = map.getSource(otherLineStringSourceId);
    if (source?.type !== "geojson") return;
    source.setData({
      type: "FeatureCollection",
      features: removeCodepointsFromFeatures(features),
    });
  }, [map, features]);

  return null;
};
