/// <reference types="vite-plugin-svgr/client" />
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import DropdownButton, { ButtonText } from "../General/Dropdown/DropdownButton";
import { DropDownItem } from "../General/Dropdown/DropdownItems";
import { useTypedPath } from "../../state/pathParams";
import StatisticsIcon from "@icons/24/Statistics.svg?react";
import { Configuration } from "../../services/configurationService";
import { configurationsAtomFamily } from "../../state/configuration";
import { analysisResourceWithAccessOnNodeState } from "components/Organisation/Library/state";
import ProjectIcon from "@icons/24/ProjectGlobe.svg?react";
import LibraryIcon from "@icons/24/Library.svg?react";
import { IconREMSize } from "styles/typography";
import { colors } from "styles/colors";

export const ConfigurationSelector = ({
  configuration,
  setConfiguration,
}: {
  configuration?: Configuration;
  setConfiguration: (cfg: Configuration) => void;
}) => {
  const { projectId } = useTypedPath("projectId");
  const projectConfigurations = useRecoilValue(
    configurationsAtomFamily({
      nodeId: projectId,
    }),
  );
  const libraryConfigurations = useRecoilValue(
    analysisResourceWithAccessOnNodeState({ nodeId: projectId }),
  );

  const configurations = useMemo(
    () => [
      ...projectConfigurations,
      ...libraryConfigurations.map((c) => c.config),
    ],
    [projectConfigurations, libraryConfigurations],
  );

  const items: DropDownItem[] = useMemo(() => {
    return [
      ...libraryConfigurations.map((cfg) => {
        return {
          value: cfg.config.id,
          name: cfg.config.name ?? "[no name]",
          icon: (
            <IconREMSize fill={colors.textPrimary} width={1.4} height={1.4}>
              <LibraryIcon />
            </IconREMSize>
          ),
        };
      }),
      ...projectConfigurations.map((cfg) => {
        return {
          value: cfg.id,
          name: cfg.name ?? "[no name]",
          icon: (
            <IconREMSize fill={colors.textPrimary} width={1.4} height={1.4}>
              <ProjectIcon />
            </IconREMSize>
          ),
        };
      }),
    ];
  }, [projectConfigurations, libraryConfigurations]);

  const selected = items.find((i) => i.value === configuration?.id);

  return (
    <DropdownButton
      items={items}
      onSelectItem={(e) => {
        const cfg = configurations.find((c) => c.id === e);
        if (!cfg) throw new Error("Configuration not found");
        setConfiguration(cfg);
      }}
      buttonText={selected?.name ?? "Select configuration"}
      className="dashboard-dropdown"
      selectedItemValue={selected?.value}
      renderText={(e) => (
        <>
          <StatisticsIcon />
          <ButtonText>{e}</ButtonText>
        </>
      )}
    />
  );
};
