import React from "react";
import { TurbineControl } from "./TurbineSettings";
import { MenuFrame } from "../MenuPopup/CloseableMenuPopup";
import { SkeletonText } from "../Loading/Skeleton";
import { Column } from "../General/Layout";
import { useDrawMode } from "components/MapControls/useActivateDrawMode";

export const PlaceWindTurbinesMenuType = "PlaceWindTurbines";

const PlaceWindTurbines = () => {
  const [layoutControlActive, setLayoutControlActive] = useDrawMode();

  if (layoutControlActive !== PlaceWindTurbinesMenuType) return null;

  return (
    <MenuFrame
      id={PlaceWindTurbinesMenuType}
      title="Single turbine"
      onExit={() => setLayoutControlActive(undefined)}
    >
      <Column style={{ gap: "2.4rem" }}>
        <React.Suspense fallback={<SkeletonText />}>
          <p>Place single turbine by clicking in the map.</p>
          <TurbineControl />
        </React.Suspense>
      </Column>
    </MenuFrame>
  );
};

export default PlaceWindTurbines;
