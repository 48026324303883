import { OrganisationAccessRole } from "./../../types/user";
import { OrganisationUser, _OrganisationUser } from "./../../types/customer";
import { z } from "zod";
import {
  fetchEnhancerWithToken,
  fetchSchemaWithToken,
  presignedUpload,
} from "../../services/utils";

export const _Organisation = z.object({
  id: z.string(),
  name: z.string().optional(),
  teams: z.string().array(),
});

export const _UpdateOrganisation = z.object({
  id: z.string(),
  name: z.string().optional(),
});

export type Organisation = z.infer<typeof _Organisation>;
export type UpdateOrganisation = z.infer<typeof _UpdateOrganisation>;

export const listUsersInOrganisation = async (
  organisationId: string,
): Promise<OrganisationUser[]> => {
  return fetchSchemaWithToken(
    _OrganisationUser.array(),
    `/api/user/organisation/${organisationId}`,
    {
      method: "get",
    },
  );
};

export const createOrganisationImageUploadUrl = async (
  file: string | Blob,
  organisationId: string,
): Promise<{ url: string }> => {
  const { fileName } = await presignedUpload(
    `/api/customer/organisation/edit/${organisationId}/picture`,
    file,
  );

  return {
    url: `https://vind-ai-organisation-image.s3.amazonaws.com/${fileName}`,
  };
};

export function editOrgName(
  organisationId: string,
  name: string,
): Promise<UpdateOrganisation> {
  return fetchSchemaWithToken(
    _UpdateOrganisation,
    `/api/customer/organisation/edit/${organisationId}/name`,
    {
      method: "PUT",
      body: JSON.stringify({
        name,
      }),
    },
  );
}

export function updateOrgRole(
  organisationId: string,
  userId: string,
  role: OrganisationAccessRole,
): Promise<OrganisationUser> {
  return fetchSchemaWithToken(
    _OrganisationUser,
    `/api/user/organisation/${organisationId}/updateRole`,
    {
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
        organisation_role: role,
      }),
    },
  );
}
export function removeUserFromOrg(organisationId: string, userId: string) {
  return fetchEnhancerWithToken(
    `/api/user/organisation/${organisationId}/${userId}`,
    {
      method: "DELETE",
    },
  );
}

export function leaveOrg(organisationId: string) {
  return fetchEnhancerWithToken(
    `/api/user/organisation/${organisationId}/leave`,
    {
      method: "DELETE",
    },
  );
}
