import mapboxgl, {
  CustomLayerInterface,
  Map,
  MercatorCoordinate,
} from "mapbox-gl";
import * as THREE from "three";
import { Camera, Scene, WebGLRenderer } from "three";
import { MooringCoords } from "../../state/mooring";
import { dispose } from "../../utils/three";

export class ThreeDMooring implements CustomLayerInterface {
  id: string;
  type: "custom";
  renderingMode: "2d" | "3d" | undefined;
  data: [[number, number], MooringCoords, MooringCoords, MooringCoords][];
  camera: Camera | undefined;
  scene: Scene | undefined;
  renderer: WebGLRenderer | undefined;
  map: Map | undefined;

  constructor(
    data: [[number, number], MooringCoords, MooringCoords, MooringCoords][],
    layerId: string,
  ) {
    this.id = layerId;
    this.type = "custom";
    this.renderingMode = "3d";
    this.data = data;
  }

  onAdd(map: Map, gl: WebGLRenderingContext) {
    this.map = map;
    this.camera = new THREE.Camera();
    this.scene = new THREE.Scene();

    map.setLayerZoomRange(this.id, 13, 24);

    this.addMooringObjects();

    this.renderer = new THREE.WebGLRenderer({
      canvas: map.getCanvas(),
      context: gl,
      antialias: true,
    });
    this.renderer.autoClear = false;
  }

  onRemove(_map: mapboxgl.Map, _gl: WebGLRenderingContext): void {
    this.scene?.traverse((o) => dispose(o));
    this.renderer?.dispose();
  }

  addMooringObjects() {
    for (const [lonlat, mooringCoords, clumpWeightCoords, buoyCoords] of this
      .data) {
      const modelAsMercatorCoordinate =
        mapboxgl.MercatorCoordinate.fromLngLat(lonlat);
      const scale = modelAsMercatorCoordinate.meterInMercatorCoordinateUnits();
      this.addLine(modelAsMercatorCoordinate, mooringCoords, scale);
      this.addClumpWeights(modelAsMercatorCoordinate, clumpWeightCoords, scale);
      this.addBuoys(modelAsMercatorCoordinate, buoyCoords, scale);
    }
  }

  updateData(
    newData: [[number, number], MooringCoords, MooringCoords, MooringCoords][],
  ) {
    this.data = newData;
    this.clearScene();
    this.addMooringObjects();
  }

  clearScene() {
    if (this.scene) {
      while (this.scene.children.length) {
        this.scene.remove(this.scene.children[0]);
      }
    }
  }

  addLine(
    modelAsMercatorCoordinate: MercatorCoordinate,
    mooringCoords: MooringCoords,
    scale: number,
  ) {
    const points: THREE.Vector3[] = [];
    for (let i = 0; i < mooringCoords.x.length; i++) {
      points.push(
        new THREE.Vector3(
          mooringCoords.x[i],
          mooringCoords.y[i],
          mooringCoords.z[i],
        ),
      );
    }
    const lineMat = new THREE.LineBasicMaterial({ color: 0xd3d3d3 });
    const geometry = new THREE.BufferGeometry().setFromPoints(points);
    const line = new THREE.Line(geometry, lineMat);
    line.position.set(
      modelAsMercatorCoordinate.x,
      modelAsMercatorCoordinate.y,
      modelAsMercatorCoordinate.z ?? 0,
    );
    line.scale.set(scale, scale, scale);
    this.scene?.add(line);
  }

  addClumpWeights(
    modelAsMercatorCoordinate: MercatorCoordinate,
    clumpWeightCoords: MooringCoords,
    scale: number,
  ) {
    const cwMat = new THREE.MeshBasicMaterial({ color: 0x5a5a5a });
    for (let i = 0; i < clumpWeightCoords.x.length; i++) {
      const cwGeometry = new THREE.SphereGeometry(8, 32, 16);
      cwGeometry.translate(
        clumpWeightCoords.x[i],
        clumpWeightCoords.y[i],
        clumpWeightCoords.z[i],
      );
      cwGeometry.scale(scale, scale, scale);
      cwGeometry.translate(
        modelAsMercatorCoordinate.x ?? 0,
        modelAsMercatorCoordinate.y ?? 0,
        modelAsMercatorCoordinate.z ?? 0,
      );
      const cwMesh = new THREE.Mesh(cwGeometry, cwMat);
      cwMesh.matrixAutoUpdate = false;
      this.scene?.add(cwMesh);
    }
  }

  addBuoys(
    modelAsMercatorCoordinate: MercatorCoordinate,
    buoyCoords: { x: number[]; y: number[]; z: number[] },
    scale: number,
  ) {
    const buoyMat = new THREE.MeshBasicMaterial({ color: 0xffff00 });
    for (let i = 0; i < buoyCoords.x.length; i++) {
      const buoyGeometry = new THREE.SphereGeometry(6, 32, 16);
      buoyGeometry.translate(buoyCoords.x[i], buoyCoords.y[i], buoyCoords.z[i]);
      buoyGeometry.scale(scale, scale, scale);
      buoyGeometry.translate(
        modelAsMercatorCoordinate.x ?? 0,
        modelAsMercatorCoordinate.y ?? 0,
        modelAsMercatorCoordinate.z ?? 0,
      );
      const buoyMesh = new THREE.Mesh(buoyGeometry, buoyMat);
      buoyMesh.matrixAutoUpdate = false;
      this.scene?.add(buoyMesh);
    }
  }

  render(gl: WebGLRenderingContext, matrix: number[]) {
    if (!this.camera || !this.renderer || !this.scene || !this.map) return;
    const m = new THREE.Matrix4().fromArray(matrix);
    this.camera.projectionMatrix = m;
    this.renderer.resetState();
    this.renderer.render(this.scene, this.camera);
    this.map.triggerRepaint();
  }
}
