import React, { useCallback, useState } from "react";

type UseBooleanStateReturnType = [
  boolean,
  () => void,
  React.Dispatch<React.SetStateAction<boolean>>,
];

const useBooleanState = (defaultValue: boolean): UseBooleanStateReturnType => {
  const [state, setState] = useState(defaultValue);

  const toggleState = useCallback(() => {
    setState((state) => !state);
  }, []);

  return [state, toggleState, setState];
};

export default useBooleanState;
