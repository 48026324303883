import {
  DEFAULT_CANVAS_DOTTED_POLYGON_STROKE_WIDTH,
  DEFAULT_CANVAS_POLYGON_STROKE_WIDTH,
  displayLabelPropertyName,
  lockedPropertyName,
  opacityPropertyName,
  strokeWidthPropertyName,
  zoomPropertyName,
} from "@constants/canvas";
import { MultiPolygon, Polygon } from "geojson";
import { FillPaint, LinePaint, SymbolLayer } from "mapbox-gl";
import { useEffect } from "react";
import {
  GeoTiffUserUploadedImageType,
  BathymetryUserUploadedType,
} from "services/types";
import { colors, opacity } from "styles/colors";
import { OtherFeature } from "types/feature";
import { safeRemoveLayer } from "utils/map";
import { addLayer, removeCodepointsFromFeatures } from "./utils";
import {
  otherPolygonSymbolLayerId,
  otherPolygonSourceId,
  otherPolygonOutlineLayerId,
  otherPolygonLayerId,
  editmodePropertyName,
  DEFAULT_ZOOM_THRESHOLD,
} from "./constants";

const polygonFillNormalOpacityCase = [
  "case",
  ["==", ["get", "type"], GeoTiffUserUploadedImageType],
  0.0,
  ["==", ["get", "type"], BathymetryUserUploadedType],
  0.0,
  ["boolean", ["feature-state", "editing"], false],
  0.0,
  ["number", ["get", opacityPropertyName], opacity.default],
];

const polygonPaint: FillPaint = {
  "fill-color": ["string", ["get", "color"], colors.other],
  "fill-opacity": [
    "step",
    ["zoom"],
    [
      "case",
      ["==", ["get", zoomPropertyName], true],
      0,
      polygonFillNormalOpacityCase,
    ],
    DEFAULT_ZOOM_THRESHOLD,
    polygonFillNormalOpacityCase,
  ],
};

export const lineNormalOpacityCase = [
  "case",
  [
    "all",
    ["!=", ["feature-state", "borderColor"], ""],
    ["!=", ["get", "type"], GeoTiffUserUploadedImageType],
    ["!=", ["get", "type"], BathymetryUserUploadedType],
  ],
  1.0,
  ["boolean", ["feature-state", editmodePropertyName], false],
  1.0,
  ["boolean", ["feature-state", "selected"], false],
  1.0,
  ["==", ["get", "type"], GeoTiffUserUploadedImageType],
  0.0,
  ["==", ["get", "type"], BathymetryUserUploadedType],
  0.0,
  ["==", ["get", opacityPropertyName], 0.0],
  1.0,
  0.0,
];

export const outlinePaint: LinePaint = {
  "line-color": [
    "case",
    ["==", ["get", lockedPropertyName], true],
    colors.lockedFeatureOutline,
    ["boolean", ["feature-state", "selected"], false],
    "#ffffff",
    ["!=", ["feature-state", "borderColor"], null],
    ["feature-state", "borderColor"],
    ["string", ["get", "strokeColor"], colors.other],
  ],
  "line-width": [
    "case",
    ["==", ["get", lockedPropertyName], true],
    [
      "number",
      ["get", strokeWidthPropertyName],
      DEFAULT_CANVAS_POLYGON_STROKE_WIDTH,
    ],
    ["!=", ["feature-state", "borderColor"], null],
    [
      "number",
      ["get", strokeWidthPropertyName],
      DEFAULT_CANVAS_POLYGON_STROKE_WIDTH,
    ],
    ["boolean", ["feature-state", "editing"], false],
    // When not having a solid line, 4 line width is way to big and we get 2
    [
      "number",
      ["get", strokeWidthPropertyName],
      DEFAULT_CANVAS_POLYGON_STROKE_WIDTH,
    ],
    [
      "all",
      [
        "boolean",
        ["feature-state", "hover"],
        ["feature-state", "selected"],
        false,
      ],
      [
        "any",
        ["==", ["get", "strokeStyle"], "solid"],
        ["==", ["get", "strokeStyle"], null],
      ],
    ],
    [
      "+",
      [
        "number",
        ["get", strokeWidthPropertyName],
        DEFAULT_CANVAS_POLYGON_STROKE_WIDTH,
      ],
      2.0,
    ],
    [
      "all",
      [
        "boolean",
        ["feature-state", "hover"],
        ["feature-state", "selected"],
        false,
      ],
      [
        "all",
        ["!=", ["get", "strokeStyle"], "solid"],
        ["!=", ["get", "strokeStyle"], null],
      ],
    ],
    [
      "number",
      ["get", strokeWidthPropertyName],
      DEFAULT_CANVAS_DOTTED_POLYGON_STROKE_WIDTH,
    ],
    [
      "number",
      ["get", strokeWidthPropertyName],
      DEFAULT_CANVAS_POLYGON_STROKE_WIDTH,
    ],
  ],
  "line-opacity": [
    "step",
    ["zoom"],
    ["case", ["==", ["get", zoomPropertyName], true], 0, lineNormalOpacityCase],
    DEFAULT_ZOOM_THRESHOLD,
    lineNormalOpacityCase,
  ],
  "line-dasharray": [
    "case",
    ["==", ["get", "strokeStyle"], "dashed"],
    ["literal", [4, 4]],
    ["==", ["get", "strokeStyle"], "dotted"],
    ["literal", [1, 2]],
    ["literal", [1, 0]],
  ],
};

const symbolLayer: SymbolLayer = {
  id: otherPolygonSymbolLayerId,
  source: otherPolygonSourceId,
  type: "symbol",
  minzoom: DEFAULT_ZOOM_THRESHOLD,
  layout: {
    "symbol-placement": "point",
    "text-field": "{name}",
    "text-size": 12,
    "symbol-spacing": 300,
    "text-keep-upright": true,
  },
  paint: {
    "text-opacity": [
      "case",
      ["boolean", ["feature-state", "editing"], false],
      0.0,
      ["==", ["get", "type"], BathymetryUserUploadedType],
      0.0,
      ["==", ["get", "type"], GeoTiffUserUploadedImageType],
      0.0,
      0.6,
    ],
  },
  filter: ["boolean", ["get", displayLabelPropertyName], true],
};

export const RenderOtherPolygons = ({
  features,
  map,
}: {
  features: OtherFeature<Polygon | MultiPolygon>[];
  map: mapboxgl.Map;
}) => {
  useEffect(() => {
    map.addSource(otherPolygonSourceId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    return () => {
      safeRemoveLayer(map, symbolLayer.id);
      safeRemoveLayer(map, otherPolygonOutlineLayerId);
      safeRemoveLayer(map, otherPolygonLayerId);
      map.removeSource(otherPolygonSourceId);
    };
  }, [map]);

  useEffect(() => {
    addLayer(map, {
      id: otherPolygonLayerId,
      type: "fill",
      source: otherPolygonSourceId,
      paint: polygonPaint,
    });
    addLayer(map, {
      id: otherPolygonOutlineLayerId,
      type: "line",
      source: otherPolygonSourceId,
      paint: outlinePaint,
    });
    addLayer(map, symbolLayer);
  }, [map]);

  useEffect(() => {
    const source = map.getSource(otherPolygonSourceId);
    if (source?.type !== "geojson") return;
    source.setData({
      type: "FeatureCollection",
      features: removeCodepointsFromFeatures(features),
    });
  }, [map, features]);

  return null;
};
