import {
  ChangeEvent,
  useCallback,
  useState,
  Dispatch,
  SetStateAction,
  ChangeEventHandler,
} from "react";

type UseTextInputReturnType<T> = [
  T,
  ChangeEventHandler,
  Dispatch<SetStateAction<T>>,
];

const useTextInput = <T = string>(
  defaultValue = "",
  formatter?: (value: T) => T,
): UseTextInputReturnType<T> => {
  const [state, setState] = useState<T>(defaultValue as T);
  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setState(
        formatter ? formatter(e.target.value as T) : (e.target.value as T),
      );
    },
    [formatter],
  );

  return [state, onChange, setState];
};

export default useTextInput;
