import CloseableHint from "../CloseableHints/CloseableHint";

export const benchmarkingArticleHintType = "AnalysisBenchmarkingHintType";

const BenchmarkingArticles = ({
  closeCallback,
  gotItCallback,
}: {
  closeCallback: () => void;
  gotItCallback?: () => void;
}) => {
  return (
    <CloseableHint
      title={"Benchmarking"}
      closeCallback={closeCallback}
      gotItCallback={gotItCallback}
      timeToDissapearMS={10000}
    >
      <p>
        We have benchmarked the results in Vind AI against other industry tools.
      </p>
      <p>
        Read more{" "}
        <a
          href="https://help.vindtech.com/en/articles/6819707-wake-analysis-benchmarking"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
      </p>
    </CloseableHint>
  );
};

export default BenchmarkingArticles;
