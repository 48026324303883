import { useEffect } from "react";
import { PUBLIC_MODE_URL } from "../components/ConfigurationModal/ShareModeSettings";

export const RedirectToPublicModeApp = () => {
  useEffect(() => {
    const pathName = window.location.href;
    const suffix = pathName.split("/public")[1];
    window.location.replace(`${PUBLIC_MODE_URL}${suffix}`);
  }, []);
  return null;
};
