import { DIAMETER_OF_TURBINE_BLADE_MODEL } from "./../constants";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import * as THREE from "three";
import {
  MathUtils,
  Shader,
  Group,
  MeshStandardMaterial,
  Mesh,
  PlaneGeometry,
} from "three";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import {
  turbineScalingAtom,
  viewParkRotationAtom,
  viewTurbineCoordsAtom,
  viewTurbineDiameterAtom,
  viewTurbineHeightsAtom,
  viewTurbineLightsAtom,
  viewViewModeAtom,
  VIEW_MODE,
} from "../../../state/viewToPark";
import { disposeObject } from "../utils";
import {
  DIVISION_FACTOR,
  HEIGHT_OF_TURBINE_HUB_MODEL,
  MESH_ROTOR_NAME,
  MESH_TURBINE_NAME,
  ROTOR_OFFSET,
} from "../constants";
import { ThreeCore } from "./useCreateThreeCore";
import {
  createBlinkingLight,
  createLowIntensityLight,
  injectCurvatureIntoVertexShader,
  sampleTerrainMeshHeight,
} from "./utils";
import { fastMax } from "utils/utils";
import { terrainPatchesAddedToSceneRefresherAtom } from "./useDynamicTerrain";
export default function useTurbines(
  threeCore: ThreeCore | undefined,
  terrainPatches: Mesh<PlaneGeometry, MeshStandardMaterial>[],
) {
  const turbineCoords = useRecoilValue(viewTurbineCoordsAtom);
  const turbineHeights = useRecoilValue(viewTurbineHeightsAtom);
  const turbineDiameters = useRecoilValue(viewTurbineDiameterAtom);
  const viewMode = useRecoilValue(viewViewModeAtom);
  const turbineScaling = useRecoilValue(turbineScalingAtom);
  const parkRotation = useRecoilValue(viewParkRotationAtom);
  const turbineLights = useRecoilValue(viewTurbineLightsAtom);
  const terrainPatchesAddedToSceneRefresher = useRecoilValue(
    terrainPatchesAddedToSceneRefresherAtom,
  );

  const [windTurbineGroup, setWindTurbineGroup] = useState<Group>();
  const [turbineBladeGroup, setTurbineBladeGroup] = useState<Group>();

  const rotationOffset = useMemo(
    () =>
      turbineCoords ? turbineCoords.map(() => Math.random() * 360) : undefined,
    [turbineCoords],
  );

  const rotateAndScaleGltf = useCallback(
    (gltf: GLTF, coords: [number, number], scaleFactor: number) => {
      if (terrainPatchesAddedToSceneRefresher == null) return new THREE.Group();
      const newTurbine = gltf.scene.clone(true);

      const terrainHeight = fastMax(
        terrainPatches
          .filter((patch) =>
            patch.geometry.boundingBox?.containsPoint(
              new THREE.Vector3(
                coords[0],
                coords[1],
                (patch.geometry.boundingBox.max.z +
                  patch.geometry.boundingBox.min.z) /
                  2,
              ),
            ),
          )
          .map(
            (patchToSampleCameraFrom) =>
              sampleTerrainMeshHeight(
                [coords[0], coords[1]],
                patchToSampleCameraFrom,
              ) ?? 0,
          ),
        0,
      );
      newTurbine.translateX(-coords[0]);
      newTurbine.translateZ(coords[1]);
      newTurbine.translateY(terrainHeight / DIVISION_FACTOR);
      newTurbine.rotateX(MathUtils.degToRad(-90));

      newTurbine.scale.set(
        scaleFactor / DIVISION_FACTOR,
        scaleFactor / DIVISION_FACTOR,
        scaleFactor / DIVISION_FACTOR,
      );
      return newTurbine;
    },
    [terrainPatches, terrainPatchesAddedToSceneRefresher],
  );

  const blinkingLights = useMemo(() => {
    const group = new Group();
    if (!turbineCoords || !turbineLights) return group;

    turbineCoords.forEach((c, i) => {
      const turbineHeight = turbineHeights[i] * turbineScaling;
      const sphere = createBlinkingLight(c, turbineHeight);
      group.add(sphere);
    });
    return group;
  }, [turbineCoords, turbineHeights, turbineLights, turbineScaling]);

  const lowIntensityLights = useMemo(() => {
    const group = new Group();
    if (!turbineCoords || !turbineLights) return group;

    turbineCoords.forEach((c, i) => {
      const turbineHeight = turbineHeights[i] * turbineScaling;
      if (turbineHeight < 150) return;
      const sphere = createLowIntensityLight(c, turbineHeight);
      group.add(sphere);
    });
    return group;
  }, [turbineCoords, turbineHeights, turbineLights, turbineScaling]);

  useEffect(() => {
    if (!turbineCoords || !rotationOffset || !turbineHeights) return;

    const loader = new GLTFLoader();
    loader.load(
      "https://vind-public-files-eu-west-1.s3.eu-west-1.amazonaws.com/tower.gltf",
      function (gltf) {
        gltf.scene.traverse((node: any) => {
          if (!node.isMesh) return;
          if (viewMode === VIEW_MODE.WIRE_FRAME_MODE) {
            node.material.wireframe = true;
            node.material.color.setHex(0x000000);
          }
          node.material.onBeforeCompile = (shader: Shader) => {
            shader.vertexShader = injectCurvatureIntoVertexShader(
              shader.vertexShader,
            );
          };
        });
        const group = new Group();
        turbineCoords.forEach((coords, i) => {
          const turbineHeight = turbineHeights[i] * turbineScaling;
          const newTurbine = rotateAndScaleGltf(
            gltf,
            coords,
            (1 / HEIGHT_OF_TURBINE_HUB_MODEL) * turbineHeight,
          );
          newTurbine.translateZ((-0.1 * turbineHeight) / DIVISION_FACTOR);
          newTurbine.name = MESH_TURBINE_NAME;
          group.add(newTurbine);
        });
        setWindTurbineGroup(group);
      },
    );

    const loaderTurbine = new GLTFLoader();
    loaderTurbine.load(
      "https://vind-public-files-eu-west-1.s3.eu-west-1.amazonaws.com/rotor_rotated_center.glb",
      function (gltf) {
        gltf.scene.traverse((node: any) => {
          if (!node.isMesh) return;
          if (viewMode === VIEW_MODE.WIRE_FRAME_MODE) {
            node.material.wireframe = true;
            node.material.color.setHex(0x000000);
          }
          node.material.side = THREE.BackSide; // Seems like the triangles are flipped.
          node.material.onBeforeCompile = (shader: Shader) => {
            shader.vertexShader = injectCurvatureIntoVertexShader(
              shader.vertexShader,
            );
          };
        });

        const group = new Group();
        turbineCoords.forEach((coords, i) => {
          const turbineHeight = turbineHeights[i] * turbineScaling;
          const turbineDiameter = turbineDiameters[i] * turbineScaling;
          const newRotor = rotateAndScaleGltf(
            gltf,
            coords,
            (1 / DIAMETER_OF_TURBINE_BLADE_MODEL) * turbineDiameter,
          );

          newRotor.translateZ((turbineHeight * ROTOR_OFFSET) / DIVISION_FACTOR);
          newRotor.rotateX(MathUtils.degToRad(90));
          newRotor.name = MESH_ROTOR_NAME;

          newRotor.children.forEach((c) =>
            c.children.forEach(
              (c2) => (c2.rotation.x = MathUtils.degToRad(rotationOffset[i])),
            ),
          );

          group.add(newRotor);
        });
        setTurbineBladeGroup(group);
      },
    );
  }, [
    rotateAndScaleGltf,
    rotationOffset,
    turbineCoords,
    turbineDiameters,
    turbineHeights,
    turbineLights,
    turbineScaling,
    viewMode,
  ]);
  useEffect(() => {
    if (!turbineBladeGroup) return;
    turbineBladeGroup.children.forEach((c) => {
      c.rotation.y = MathUtils.degToRad(parkRotation);
    });
  }, [parkRotation, turbineBladeGroup]);

  useEffect(() => {
    if (!threeCore || !turbineBladeGroup) return;
    const { scene } = threeCore;
    scene.add(turbineBladeGroup);
    return () => {
      scene.remove(turbineBladeGroup);
      disposeObject(turbineBladeGroup);
    };
  }, [threeCore, turbineBladeGroup]);

  useEffect(() => {
    if (!threeCore || !windTurbineGroup) return;
    const { scene } = threeCore;
    scene.add(windTurbineGroup);
    return () => {
      scene.remove(windTurbineGroup);
      disposeObject(windTurbineGroup);
    };
  }, [threeCore, windTurbineGroup]);

  useEffect(() => {
    if (!threeCore || !blinkingLights) return;
    const { scene } = threeCore;

    scene.add(blinkingLights);
    return () => {
      scene.remove(blinkingLights);
      disposeObject(blinkingLights);
    };
  }, [threeCore, blinkingLights]);

  useEffect(() => {
    if (!threeCore || !lowIntensityLights) return;
    const { scene } = threeCore;

    scene.add(lowIntensityLights);
    return () => {
      scene.remove(lowIntensityLights);
      disposeObject(lowIntensityLights);
    };
  }, [threeCore, lowIntensityLights]);

  return { windTurbineBlinkingLightGroup: blinkingLights, turbineBladeGroup };
}
