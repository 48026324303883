import { CSSProperties, useEffect, useState } from "react";
import { getProjectImage } from "../../services/projectDataAPIService";
import { SkeletonBlock } from "../Loading/Skeleton";
import styled from "styled-components";
import { ProjectMeta } from "../../types/api";
import StaticImage from "./static_image.png";
import StaticImageEurope from "./static_image_europe.png";
import { colors } from "styles/colors";

export const projectImageWidth = "30rem";
export const projectImageHeight = "16rem";
export const ProjectImage = styled.img`
  width: ${projectImageWidth};
  height: ${projectImageHeight};
  object-fit: cover;
  object-position: center;
`;

const NOT_AVAILABLE = "not_available";

export const ProjectImageSkeleton = styled(SkeletonBlock)`
  width: ${projectImageWidth};
  height: ${projectImageHeight};
`;

export const TutorialProjectImage = styled.div`
  width: ${projectImageWidth};
  height: ${projectImageHeight};
  background: ${colors.blue100};
  border-radius: 0.6rem;
  object-fit: cover;
  object-position: center;
`;

const StaticMapboxProjectImage = ({
  project,
  branchId,
  parkId,
  style,
}: {
  project: ProjectMeta;
  branchId?: string;
  parkId?: string;
  style?: CSSProperties;
}) => {
  let [imgSrc, setImgSrc] = useState<string | undefined | typeof NOT_AVAILABLE>(
    undefined,
  );

  // Reset the image when the project changes, to show loader while the new image loads
  useEffect(() => {
    setImgSrc(undefined);
  }, [project]);

  useEffect(() => {
    let isSubmitted = false;
    const asyncFunc = async () => {
      if (!project.main_branch_id) return;
      const url = await getProjectImage(
        project.id,
        branchId ?? project.main_branch_id,
        parkId,
      );
      if (isSubmitted) return;
      if (!url) {
        setImgSrc(NOT_AVAILABLE);
        return;
      }
      setImgSrc(url);
    };

    asyncFunc();
    return () => {
      isSubmitted = true;
    };
  }, [parkId, branchId, project]);

  if (imgSrc === undefined) return <ProjectImageSkeleton style={style} />;

  if (imgSrc === NOT_AVAILABLE) imgSrc = StaticImage;

  return (
    <ProjectImage
      src={imgSrc}
      alt={"layout"}
      style={style}
      referrerPolicy="origin"
    />
  );
};

export default StaticMapboxProjectImage;

export const NewProjectImage = ({ style }: { style?: CSSProperties }) => (
  <ProjectImage
    src={StaticImageEurope}
    alt={"layout"}
    style={style}
    referrerPolicy="origin"
  />
);
