import React, { useCallback } from "react";
import { useDrawMode } from "components/MapControls/useActivateDrawMode";
import {
  AddSubStationMenuType,
  DrawExportCableMenuType,
} from "@constants/cabling";
import { MenuFrame } from "components/MenuPopup/CloseableMenuPopup";
import { Column } from "components/General/Layout";
import { SkeletonText } from "components/Loading/Skeleton";
import Dropdown from "components/Dropdown/Dropdown";
import { Label } from "components/General/Form";
import { selectedMenuItemState } from "components/SettingsV2/Shared/state";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { FeatureSettingsModalTypeV2 } from "state/configuration";
import { modalTypeOpenAtom } from "state/modal";
import { parkIdSelector, projectIdSelector } from "state/pathParams";
import {
  currentExportCableIdAtom,
  currentSubstationIdAtom,
  getSubstationsSelectorFamily,
  keepExportModalOpenAtom,
} from "state/cable";
import { currentExportCableTypesSelector } from "./state";
import { EXPORT_CABLE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useExportCableSettings";
import { currentSubstationTypesState } from "state/substationType";
import Button from "components/General/Button";
import { replaceOrUndefined } from "components/ControlPanels/utils";
import SubstationIcon from "@icons/24/Substation.svg?react";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import {
  InputTitle,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";

const AddExportCable = () => {
  const [layoutControlActive, setLayoutControlActive] = useDrawMode();
  const [, setKeepExportModalOpen] = useRecoilState(keepExportModalOpenAtom);

  if (layoutControlActive !== DrawExportCableMenuType) return null;

  return (
    <MenuFrame
      id={DrawExportCableMenuType}
      title="Export system"
      onExit={() => {
        setLayoutControlActive(undefined), setKeepExportModalOpen(false);
      }}
    >
      <Column>
        <React.Suspense fallback={<SkeletonText />}>
          <ExportCableControl />
        </React.Suspense>
      </Column>
    </MenuFrame>
  );
};

const NewExportCableValue = "addNewExportCableConfiguration";

export const ExportCableControl = () => {
  const [_, setLeftMenuActiveMode] = useDrawMode();
  const projectId = useRecoilValue(projectIdSelector) ?? "";
  const parkId = useRecoilValue(parkIdSelector) ?? "";
  const substations = useRecoilValue(getSubstationsSelectorFamily({ parkId }));
  const substationTypes = useRecoilValue(currentSubstationTypesState);
  const setSelectedSubstationId = useSetRecoilState(currentSubstationIdAtom);
  const offshoreSubstations = substations.filter(
    (s) =>
      substationTypes.find((st) => st.id === s.properties.substationTypeId)
        ?.type === "offshore",
  );
  const onshoreSubstations = substations.filter(
    (s) =>
      substationTypes.find((st) => st.id === s.properties.substationTypeId)
        ?.type === "onshore",
  );
  const [currentExportCableId, setCurrentExportCableId] = useRecoilState(
    currentExportCableIdAtom,
  );
  const [, setKeepExportModalOpen] = useRecoilState(keepExportModalOpenAtom);
  const exportCableTypes = useRecoilValue(currentExportCableTypesSelector);
  const setModalType = useSetRecoilState(modalTypeOpenAtom);
  const setSelectedItemId = useSetRecoilState(
    selectedMenuItemState({
      menuId: FeatureSettingsModalTypeV2,
      projectId,
    }),
  );

  const openNewExportCableConfig = useCallback(() => {
    setSelectedItemId(EXPORT_CABLE_MENU_ID);
    setModalType({ modalType: FeatureSettingsModalTypeV2 });
  }, [setSelectedItemId, setModalType]);

  if (!currentExportCableId || exportCableTypes.length === 0)
    return (
      <SimpleAlert
        text={
          "There are no export cable types in this project. Open feature settings to add export cable types."
        }
        type={"error"}
      ></SimpleAlert>
    );

  if (onshoreSubstations.length === 0 && offshoreSubstations.length > 0)
    return (
      <Column>
        <p>You need an onshore type substation to draw export cables.</p>
        <Button
          style={{ alignSelf: "end" }}
          icon={<SubstationIcon />}
          text={"Place substation"}
          buttonType={"primary"}
          onClick={() => {
            const defaultOnshore = substationTypes.find(
              (s) => s.type === "onshore",
            );
            if (defaultOnshore) setSelectedSubstationId(defaultOnshore.id);
            setLeftMenuActiveMode(replaceOrUndefined(AddSubStationMenuType));
            setKeepExportModalOpen(true);
          }}
        />
      </Column>
    );

  if (onshoreSubstations.length > 0 && offshoreSubstations.length === 0)
    return (
      <Column>
        <p>You need an offshore type substation to draw export cables.</p>
        <Button
          style={{ alignSelf: "end" }}
          icon={<SubstationIcon />}
          text={"Place substation"}
          buttonType={"primary"}
          onClick={() => {
            const defaultOffshore = substationTypes.find(
              (s) => s.type === "offshore",
            );
            if (defaultOffshore) setSelectedSubstationId(defaultOffshore.id);
            setLeftMenuActiveMode(replaceOrUndefined(AddSubStationMenuType));
            setKeepExportModalOpen(true);
          }}
        />
      </Column>
    );

  if (onshoreSubstations.length === 0 && offshoreSubstations.length === 0)
    return (
      <Column>
        <p>
          You need both an offshore type and an onshore type substation to draw
          export cables.
        </p>
        <Button
          style={{ alignSelf: "end" }}
          icon={<SubstationIcon />}
          text={"Place substations"}
          buttonType={"primary"}
          onClick={() => {
            setLeftMenuActiveMode(replaceOrUndefined(AddSubStationMenuType));
            setKeepExportModalOpen(true);
          }}
        />
      </Column>
    );

  return (
    <>
      <p>Draw export cable by clicking in the map.</p>
      <SubtitleWithLine text="Type" />

      <Label left>
        <InputTitle>Offshore</InputTitle>
        <Dropdown
          small
          id="offshore_export_cable"
          value={currentExportCableId.offshore}
          onChange={(e) => {
            setCurrentExportCableId({
              ...currentExportCableId,
              offshore: e.target.value,
            });
            if (e.target.value === NewExportCableValue) {
              openNewExportCableConfig();
              return;
            }
          }}
        >
          {exportCableTypes.map((c) => (
            <option key={c.id} value={c.id}>
              {`${c.name}`}
            </option>
          ))}
          <option
            value={NewExportCableValue}
          >{`+ Add export cable type`}</option>
        </Dropdown>
      </Label>
      <Label left>
        <InputTitle>Onshore</InputTitle>
        <Dropdown
          small
          id="onshore_export_cable"
          value={currentExportCableId.onshore}
          onChange={(e) => {
            setCurrentExportCableId({
              ...currentExportCableId,
              onshore: e.target.value,
            });
            if (e.target.value === NewExportCableValue) {
              openNewExportCableConfig();
              return;
            }
          }}
        >
          {exportCableTypes.map((c) => (
            <option key={c.id} value={c.id}>
              {`${c.name}`}
            </option>
          ))}
          <option
            value={NewExportCableValue}
          >{`+ Add export cable type`}</option>
        </Dropdown>
      </Label>
    </>
  );
};

export default AddExportCable;
