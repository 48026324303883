import * as turf from "@turf/turf";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { getSelectedArea } from "../../../../state/division";
import {
  TurbineFeatureWithFoundation,
  hasFixedFoundationSelector,
  hasFloatingFoundationSelector,
  isTurbineFeatureWithFoundation,
} from "../../../../state/foundations";
import { TextSemi } from "../../../../styles/typography";

import { branchIdSelector_ } from "../../../../state/pathParams";
import { ParkFeature, TurbineFeature } from "../../../../types/feature";
import { Tag } from "../../../GenerateWindTurbines/Tags";
import { Row } from "../InfoModal.style";
import { FixedFoundationGeneration } from "./fixed/FixedFoundationGeneration";
import { FloatingFoundationGeneration } from "./floating/FloatingFoundationGeneration";
import { pointInPolygon } from "utils/geometry";
import { getInvalidTypesInParkAndBranch } from "state/layout";
import { InvalidTypes, InvalidTypesDisplayText } from "types/invalidTypes";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { getSelectedTurbines } from "state/turbines";
import { spacing3 } from "styles/space";
import { sum } from "utils/utils";
import { SubAreaTag } from "components/SiteLocator/Tags";

const FoundationTab = ({ park }: { park: ParkFeature }) => {
  const branchId = useRecoilValue(branchIdSelector_);

  const areaFeatures = useRecoilValue(getSelectedArea) ?? park;
  const selectedArea = useMemo(() => {
    const area = Array.isArray(areaFeatures)
      ? sum(areaFeatures, (f) => turf.area(f))
      : turf.area(areaFeatures);
    return Math.round(area / 1e6);
  }, [areaFeatures]);

  const turbines = useRecoilValue(getSelectedTurbines);
  const hasFloatingFoundation = useRecoilValue(hasFloatingFoundationSelector);
  const hasFixedFoundation = useRecoilValue(hasFixedFoundationSelector);
  const { fixed, floating, nothing } = useMemo(() => {
    const fixed: TurbineFeatureWithFoundation[] = [];
    const floating: TurbineFeatureWithFoundation[] = [];
    const nothing: TurbineFeature[] = [];

    for (const t of turbines) {
      if (!pointInPolygon(t.geometry, park.geometry)) continue;
      if (!isTurbineFeatureWithFoundation(t)) nothing.push(t);
      else if (hasFixedFoundation(t)) fixed.push(t);
      else if (hasFloatingFoundation(t)) floating.push(t);
      else nothing.push(t);
    }

    return { fixed, floating, nothing };
  }, [turbines, park.geometry, hasFixedFoundation, hasFloatingFoundation]);

  const invalidTypes = useRecoilValue(
    getInvalidTypesInParkAndBranch({ parkId: park.id, branchId }),
  );

  if (fixed.length === 0 && floating.length === 0)
    return (
      <div>
        <SimpleAlert
          text={"No foundations in the park."}
          type={"error"}
        ></SimpleAlert>
      </div>
    );

  const relevantInvalidTypes = invalidTypes.filter(
    (t) =>
      t.type === InvalidTypes.FoundationTypeInvalid ||
      t.type === InvalidTypes.TurbineTypeInvalid,
  );

  if (relevantInvalidTypes.length > 0)
    return <InvalidTypesDisplayText invalidTypes={relevantInvalidTypes} />;

  if (0 < fixed.length && 0 < floating.length) {
    return (
      <TextSemi>
        Please select a park or zone with either only fixed or only floating
        foundations to see statistics.
      </TextSemi>
    );
  }
  return (
    <>
      <Row
        style={{
          justifyContent: "left",
          flexWrap: "wrap",
        }}
      >
        {Array.isArray(areaFeatures) ? (
          <SubAreaTag tooltip="These analysis numbers are only for the selected zone." />
        ) : (
          <Tag>Park</Tag>
        )}
        <Tag>{selectedArea} km²</Tag>
      </Row>
      {nothing.length > 0 && (
        <div style={{ padding: `${spacing3} 0` }}>
          <SimpleAlert
            text={`${nothing.length} turbine(s) are without foundation.`}
            type="info"
          />
        </div>
      )}
      {floating.length > 0 && (
        <FloatingFoundationGeneration tempLayoutFoundations={floating} />
      )}
      {fixed.length > 0 && (
        <FixedFoundationGeneration tempLayoutFoundations={fixed} park={park} />
      )}
    </>
  );
};

export default FoundationTab;
