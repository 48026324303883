import Plotly from "plotly.js-dist-min";

import { SimpleWindRose } from "../../state/windStatistics";
import { useEffect, useMemo, useRef, useState } from "react";
import { colors, hexToRgb } from "../../styles/colors";

const WindRose = ({
  windRose,
  width,
  height,
  radialStep,
  showMeanSpeed,
}: {
  windRose: SimpleWindRose;
  width?: number;
  height?: number;
  radialStep?: number;
  showMeanSpeed?: boolean;
}) => {
  const windRoseRef = useRef<HTMLDivElement>(null);

  const plot = useMemo(() => {
    const directionProbabilities = windRose.directions.map(
      (d) => d.probability * 100,
    );
    return [
      {
        r: directionProbabilities,
        theta: windRose.directions.map((d) => d.direction + "°"),
        customdata: windRose.directions.map((d) => [d.meanSpeed]),
        marker: { color: hexToRgb(colors.primary) },
        type: "barpolar" as const,
        name: "",
        hovertemplate:
          "%{theta}<br />Probability: %{r:,.2f}%" +
          (showMeanSpeed ? "<br />Mean speed: %{customdata[0]:,.2f} m/s" : ""),
      },
    ];
  }, [windRose, showMeanSpeed]);

  const [divWidth, setDivWidth] = useState(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!windRoseRef.current) return;
    const { width } = windRoseRef.current.getBoundingClientRect();
    if (divWidth !== width && width !== 0) setDivWidth(width);
  });

  useEffect(() => {
    const handleResize = () => {
      if (windRoseRef.current) {
        Plotly.relayout(windRoseRef.current, {
          width: windRoseRef.current.offsetWidth,
        });
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!windRoseRef.current) return;
    var layout = {
      font: { size: 8 },
      paper_bgcolor: "rgba(0,0,0,0)",
      legend: { font: { size: 7 }, title: { text: "m/s" } },
      polar: {
        bargap: 0.05,
        radialaxis: { ticksuffix: "%", angle: 45, dtick: radialStep || 2 },
        angularaxis: { direction: "clockwise", nticks: 12 },
      },
      width: width ?? divWidth,
      height: height ?? divWidth,
      margin: {
        l: 30,
        r: 30,
        b: 30,
        t: 30,
      },
    } as const;

    Plotly.newPlot(windRoseRef.current, plot, layout, {
      displayModeBar: false,
    });
  }, [windRoseRef, plot, width, height, divWidth, radialStep]);
  return <div ref={windRoseRef} />;
};

export default WindRose;
