import { useEffect, useMemo, useState } from "react";
import * as turf from "@turf/turf";
import {
  getAEP,
  getAnalysis,
  getAverageTurbineSpeed,
  getCapacity,
  getCapacityFactor,
  getGrossEnergy,
  getTotalInterArrayLoss,
  getNeighbourWakeLoss,
  getOtherLosses,
  getProjectId,
  getStoppedReason,
  getTotalExportSystemLoss,
  getTotalWakeLoss,
  getTurbines,
  getStoppedReasonFromAnalysis,
  getAnalysisWindStats,
  ProdId,
  getTurbineSpecificLoss,
  getFoundationStats,
  getConfiguration,
  getAverageHubHeight,
  getWakeSettings,
} from "components/ProductionV2/state";
import {
  Loadable,
  RecoilLoadable,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";
import {
  getCablesInBranchSelectorFamily,
  getExportCablesInBranchSelectorFamily,
  getOffShoreSubstationsInBranchSelectorFamily,
} from "../../../state/cable";
import {
  ExportCableFeature,
  ParkFeature,
  TurbineFeature,
} from "../../../types/feature";
import { SimpleTurbineType } from "../../../types/turbines";
import { isDefined } from "../../../utils/predicates";
import { dedup, sum, sumKeys } from "../../../utils/utils";
import { getRasterStats } from "../../Bathymetry/useGetRasterStats";
import {
  allCableTypesSelector,
  currentExportCableTypesSelector,
} from "../../Cabling/Generate/state";
import { get3DCableLengthsInParkWithContingency } from "../../Cabling/Generate/utils";
import { compareIsLoading } from "../state";
import { ComparisonAttributeKey } from "../types";
import { useBathymetry } from "hooks/bathymetry";
import { useLcoe } from "components/Finance/lcoe";
import { FinanceId } from "components/Finance/state";
import { useIRR } from "components/Finance/irr";
import { exportCableSplitsOk } from "functions/elevation";
import { Raster } from "types/raster";
import { allSimpleTurbineTypesSelector } from "state/turbines";
import { WAKE_MODEL_NAME } from "services/configurationService";
import { CableType } from "services/cableTypeService";
import { useCapexMillionPV } from "components/Finance/capex";
import { CostType } from "services/costService";
import { getExportCablesUtilizationSelectorFamily } from "state/electrical";

const turbineTypeString = (
  turbines: TurbineFeature[],
  allTurbineTypes: SimpleTurbineType[],
): string | undefined => {
  const types = dedup(turbines.map((f) => f.properties.turbineTypeId))
    .map((id) => allTurbineTypes.find((t) => t.id === id)?.name)
    .filter(isDefined)
    .sort();
  if (types.length === 0) return "-";
  return types.join(", ");
};

const exportCableTypeString = (
  exportCables: ExportCableFeature[],
  allExportCableTypes: CableType[],
  getCableType: (feature: ExportCableFeature) => string | undefined,
): string | undefined => {
  const types = dedup(exportCables.map(getCableType))
    .map((id) => allExportCableTypes.find((e) => e.id === id)?.name)
    .filter(isDefined)
    .sort();
  if (types.length === 0) return "-";
  return types.join(", ");
};

const useParkComparisonValues = (
  triggerId: ProdId & FinanceId,
  park: ParkFeature,
  branchId: string,
  selectedHubHeight?: number,
  selectedTurbineType?: SimpleTurbineType,
  selectedExportCableType?: CableType,
): Record<ComparisonAttributeKey, Record<string, unknown>> => {
  const projectId = useRecoilValue(getProjectId(triggerId));
  const analysisConfig = useRecoilValue(getConfiguration(triggerId));
  const turbines: TurbineFeature[] = useRecoilValue(getTurbines(triggerId));
  const cables = useRecoilValue(
    getCablesInBranchSelectorFamily({
      parkId: park.id,
      branchId: branchId,
    }),
  );
  const cableTypes = useRecoilValue(allCableTypesSelector);
  const exportCableTypes = useRecoilValue(currentExportCableTypesSelector);
  const exportCables = useRecoilValue(
    getExportCablesInBranchSelectorFamily({
      parkId: park.id,
      branchId,
      exportCableTypeOverrideId: selectedExportCableType?.id,
    }),
  );

  const numberExportCables = exportCables.length;

  // Park
  const area = useMemo(
    () => Math.round(turf.area(park) / (1000 * 1000)),
    [park],
  );
  const parkLocation = useMemo(() => turf.centerOfMass(park), [park]);

  const bathymetry = useBathymetry({
    projectId,
    branchId,
    featureId: park.id,
  }).valueMaybe();

  const [rasterStats, setRasterStats] =
    useState<Loadable<Awaited<ReturnType<typeof getRasterStats>>>>();

  const foundationTotals = useRecoilValueLoadable(
    getFoundationStats({ id: triggerId, rasterId: bathymetry?.id }),
  );

  useEffect(() => {
    if (!bathymetry?.raster || !(bathymetry.raster instanceof Raster)) return;
    const abortController = new AbortController();

    setRasterStats(RecoilLoadable.loading());
    getRasterStats(park, bathymetry.raster, abortController.signal).then(
      (d) => {
        setRasterStats(RecoilLoadable.of(d));
      },
    );
    return () => {
      abortController.abort("unmount");
    };
  }, [park, bathymetry?.raster]);

  const rasterStatsMaybe = rasterStats?.valueMaybe();
  const minValue = rasterStatsMaybe?.minValue ?? 0;
  const maxValue = rasterStatsMaybe?.maxValue ?? 0;
  const averageDepth = rasterStatsMaybe?.averageValue ?? 0;

  const allTurbineTypes = useRecoilValue(allSimpleTurbineTypesSelector);
  const turbineTypesString = useMemo<string | undefined>(() => {
    if (selectedTurbineType) return selectedTurbineType.name;
    return turbineTypeString(turbines, allTurbineTypes);
  }, [turbines, allTurbineTypes, selectedTurbineType]);

  const avgTurbineHeight = useRecoilValue(getAverageHubHeight(triggerId));

  const averageTurbineHeight = useMemo<number>(() => {
    if (selectedHubHeight) return selectedHubHeight;
    return avgTurbineHeight;
  }, [avgTurbineHeight, selectedHubHeight]);

  const windStatsLoadable = useRecoilValueLoadable(
    getAnalysisWindStats(triggerId),
  );
  const windStats = useMemo(
    () => windStatsLoadable.valueMaybe(),
    [windStatsLoadable],
  );
  const aep = useRecoilValueLoadable(getAEP(triggerId)).valueMaybe();
  const gross = useRecoilValueLoadable(getGrossEnergy(triggerId)).valueMaybe();

  const totalWakeLoss = useRecoilValueLoadable(
    getTotalWakeLoss(triggerId),
  ).valueMaybe();
  const turbineSpecificLoss = useRecoilValueLoadable(
    getTurbineSpecificLoss(triggerId),
  ).valueMaybe();
  const neighbourWakeLoss = useRecoilValueLoadable(
    getNeighbourWakeLoss(triggerId),
  ).valueMaybe();
  const internalWakeLoss =
    isDefined(totalWakeLoss) && isDefined(neighbourWakeLoss)
      ? totalWakeLoss - neighbourWakeLoss
      : undefined;
  const averageTurbineSpeed = useRecoilValueLoadable(
    getAverageTurbineSpeed(triggerId),
  ).valueMaybe();
  const otherLoss = useRecoilValueLoadable(
    getOtherLosses(triggerId),
  ).valueMaybe();
  const capacityFactor = useRecoilValueLoadable(
    getCapacityFactor(triggerId),
  ).valueMaybe();
  const totalInterArrayLoss = useRecoilValueLoadable(
    getTotalInterArrayLoss(triggerId),
  ).valueMaybe()?.totalInterArrayLoss;
  const analysis = useRecoilValueLoadable(getAnalysis(triggerId)).valueMaybe();
  const progress = analysis?.progress;
  const status = analysis?.status;
  const analysisVersion = analysis?.version;
  const stoppedReasonFrontEnd = useRecoilValueLoadable(
    getStoppedReason(triggerId),
  ).valueMaybe();
  const stoppedReasonBackend = useRecoilValueLoadable(
    getStoppedReasonFromAnalysis(triggerId),
  ).valueMaybe();
  const stoppedReason = stoppedReasonFrontEnd ?? stoppedReasonBackend;
  const capacity = useRecoilValue(getCapacity(triggerId));
  const totalExportSystemLoss = useRecoilValueLoadable(
    getTotalExportSystemLoss(triggerId),
  )
    // NOTE: this is bad. We need to change the electrical loss selectors to
    // better differentiate between errors, loading, values, and disabled
    // functionality.
    .map((e) => (e === undefined ? { totalExportSystemLoss: null } : e))
    .valueMaybe()?.totalExportSystemLoss;

  const capacityDensity = capacity
    ? Number((capacity / area).toPrecision(3))
    : undefined;

  // Cabling
  const exportCableLength = useMemo(
    () => sum(exportCables, (f) => turf.length(f, { units: "kilometers" })),
    [exportCables],
  );

  const cableLengthsLoadable = useRecoilValueLoadable(
    get3DCableLengthsInParkWithContingency({
      parkId: park.id,
      branchId,
      analysisConfigurationId: analysisConfig.id,
    }),
  );
  const cableLengths = useMemo(
    () => cableLengthsLoadable.valueMaybe(),
    [cableLengthsLoadable],
  );

  const interArrayCableLength = useMemo(
    () => sum(Object.values(cableLengths ?? {}), (item) => item),
    [cableLengths],
  );

  const cablesToAdd = useMemo(() => {
    if (!cableLengths)
      return cableTypes.reduce<Record<string, undefined>>((acc, curr) => {
        acc[curr.id] = undefined;
        return acc;
      }, {});

    const cableTypeIds = new Set(cableTypes.map((t) => t.id));
    const lengthById = sumKeys(
      cables.map((c) => {
        const len = cableLengths[c.id] ?? 0;
        const id =
          c.properties.cableTypeId && cableTypeIds.has(c.properties.cableTypeId)
            ? c.properties.cableTypeId
            : "other";
        return [id, len];
      }),
    );

    return cableTypes.reduce<Record<string, number>>((acc, curr) => {
      acc[curr.id] = lengthById[curr.id] ?? 0;
      return acc;
    }, {});
  }, [cableLengths, cableTypes, cables]);

  const cableTypesMap = useMemo(() => {
    return cableTypes.reduce<Record<string, CableType>>((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {});
  }, [cableTypes]);

  const exportCableTypesMap = useMemo(() => {
    return exportCableTypes.reduce<Record<string, CableType>>((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {});
  }, [exportCableTypes]);

  const interArrayCableVoltages = useMemo(() => {
    return dedup(
      cables
        .map(
          (cable) => cableTypesMap[cable.properties.cableTypeId ?? ""]?.voltage,
        )
        .filter(isDefined),
    );
  }, [cables, cableTypesMap]);

  const exportCableVoltages = useMemo(() => {
    return dedup(
      exportCables
        .map(
          (cable) =>
            exportCableTypesMap[cable.properties.cableTypeId ?? ""]?.voltage,
        )
        .filter(isDefined),
    );
  }, [exportCables, exportCableTypesMap]);

  const exportCableTypesString = useMemo<string | undefined>(() => {
    if (selectedExportCableType) return selectedExportCableType.name;
    return exportCableTypeString(
      exportCables,
      exportCableTypes,
      (f: ExportCableFeature) => f.properties.cableTypeId,
    );
  }, [exportCables, exportCableTypes, selectedExportCableType]);

  const exportCableTypesOnshoreString = useMemo<string | undefined>(() => {
    return exportCableTypeString(
      exportCables,
      exportCableTypes,
      (f: ExportCableFeature) => f.properties.onshoreCableTypeId,
    );
  }, [exportCables, exportCableTypes]);

  const offShoreSubstations = useRecoilValue(
    getOffShoreSubstationsInBranchSelectorFamily({ parkId: park.id, branchId }),
  );

  const numberOffShoreSubstations = offShoreSubstations.length;

  const exportCableSegments = useRecoilValue(
    exportCableSplitsOk({ parkId: park.id, branchId }),
  );

  const wakeSettings = useRecoilValue(getWakeSettings(triggerId));

  const offshoreCableLength = useMemo(
    () =>
      exportCableSegments
        .map((f) => f.offshore)
        .reduce((acc, f) => {
          const thisLength = turf.length(f, { units: "kilometers" });
          return acc + thisLength;
        }, 0),
    [exportCableSegments],
  );

  const onshoreCableLength = useMemo(
    () =>
      exportCableSegments
        .map((f) => f.onshore)
        .reduce((acc, f) => {
          const thisLength = turf.length(f, { units: "kilometers" });
          return acc + thisLength;
        }, 0),
    [exportCableSegments],
  );

  const maxExportCablesUtilizationLoadable = useRecoilValueLoadable(
    getExportCablesUtilizationSelectorFamily(triggerId),
  );

  // LCOE
  const { inputs: lcoeInputs, results: lcoeResults } = useLcoe(triggerId);
  const { results: irrResults } = useIRR(triggerId);

  const setIsLoading = useSetRecoilState(compareIsLoading);
  useEffect(() => {
    if (
      lcoeResults.lcoe.state !== "loading" &&
      aep &&
      cableLengthsLoadable.state !== "loading" &&
      averageTurbineSpeed !== undefined &&
      averageDepth !== undefined &&
      windStats !== undefined
    ) {
      setIsLoading((c) => ({ ...c, [triggerId]: false }));
    } else {
      setIsLoading((c) => ({ ...c, [triggerId]: true }));
    }
  }, [
    lcoeResults.lcoe.state,
    aep,
    windStats,
    setIsLoading,
    cableLengthsLoadable.state,
    averageTurbineSpeed,
    triggerId,
    averageDepth,
  ]);

  const windSource = useMemo(() => {
    if (!windStats) return;
    const name =
      windStats.source === "custom" ? "Custom" : windStats.source.toUpperCase();
    return `${name} (${windStats?.fromYear}-${windStats?.toYear})`;
  }, [windStats]);

  const lcoe = lcoeResults.lcoe.valueMaybe();
  const netAEP = lcoeInputs.netAEP.valueMaybe();
  const devexNPV = lcoeResults.devex.npv.valueMaybe();
  const capexNPV = lcoeResults.capex.npv.valueMaybe();
  const opexNPV = lcoeResults.opex.npv.valueMaybe();
  const decomNPV = lcoeResults.decom.npv.valueMaybe();
  const lifeTime = lcoeInputs.lifeTime.valueMaybe();
  const irr = irrResults.irr.valueMaybe();
  const maxExportCablesUtilization =
    maxExportCablesUtilizationLoadable.valueMaybe();
  const { useCosts } = useCapexMillionPV(triggerId);
  const { costs: _turbineCosts, sum: turbineSum } = useCosts(CostType.Turbine);
  const totalTurbineCost =
    turbineSum.state === "hasValue" ? turbineSum.contents : undefined;
  const { costs: _cableCosts, sum: cableSum } = useCosts(CostType.Cable);
  const totalCableCost =
    cableSum.state === "hasValue" ? cableSum.contents : undefined;
  const { costs: _foundationCosts, sum: foundationSum } = useCosts(
    CostType.Foundation,
  );
  const totalFoundationCost =
    foundationSum.state === "hasValue" ? foundationSum.contents : undefined;
  const { costs: _mooringCosts, sum: mooringSum } = useCosts(CostType.Mooring);
  const totalMooringCost =
    mooringSum.state === "hasValue" ? mooringSum.contents : undefined;
  const { costs: _substationCosts, sum: substationSum } = useCosts(
    CostType.Substation,
  );
  const totalSubstationCost =
    substationSum.state === "hasValue" ? substationSum.contents : undefined;
  const { costs: _exportCableCosts, sum: exportCableSum } = useCosts(
    CostType.ExportCable,
  );
  const totalExportCableCost =
    exportCableSum.state === "hasValue" ? exportCableSum.contents : undefined;
  const { costs: _otherCapexCosts, sum: otherSum } = useCosts(CostType.Other);
  otherSum;
  const totalOtherCapexCost =
    exportCableSum.state === "hasValue" ? otherSum.contents : undefined;

  const contingency = lcoeInputs.capexContingency.valueMaybe();
  const discountRate = lcoeInputs.discountRate.valueMaybe();
  const inflationRate = lcoeInputs.inflationRate.valueMaybe();

  const foundationsTotalsValueMaybe = foundationTotals.valueMaybe();
  const primarySteelWeight = foundationsTotalsValueMaybe?.totalPrimarySteelMass;
  const minFoundationWeight = foundationsTotalsValueMaybe?.minFoundationWeight;
  const avgFoundationWeight =
    foundationsTotalsValueMaybe?.averageFoundationWeight;
  const maxFoundationWeight = foundationsTotalsValueMaybe?.maxFoundationWeight;
  const minFoundationDepth = foundationsTotalsValueMaybe?.minimumDepth;
  const avgFoundationDepth = foundationsTotalsValueMaybe?.averageDepth;
  const maxFoundationDepth = foundationsTotalsValueMaybe?.maximumDepth;

  const nrTurbines = turbines.length;
  const cableLengthContingency = analysisConfig.electrical
    .cableLengthContingencyEnabled
    ? analysisConfig.electrical.cableLengthContingency
    : 0;
  // If you add any new fields here, remember to add the rows in /components/CompareParksModal/columnTemplates.tsx
  const toReturn = useMemo(
    () => ({
      [ComparisonAttributeKey.PARK]: {
        nrTurbines: nrTurbines,
        turbineTypesString,
        parkLocation: {
          lon: parkLocation.geometry.coordinates[0],
          lat: parkLocation.geometry.coordinates[1],
        },
        minValue,
        maxValue,
        depthRange: [minValue, maxValue],
        averageDepth,
        area,
        averageTurbineHeight,
      },
      [ComparisonAttributeKey.WIND]: {
        avgPowerLawCoefficient: windStats?.meanAlpha,
        height: windStats?.height,
        windSource,
        location: windStats
          ? {
              lat: windStats.latitude,
              lon: windStats.longitude,
            }
          : undefined,
        avgTurbineWindSpeed: averageTurbineSpeed,
        avgSourceWindSpeed: windStats?.meanSpeed,
        airDensity: windStats?.meanDensity,
      },
      [ComparisonAttributeKey.PRODUCTION]: {
        capacity,
        otherLoss,
        capacityDensity,
        netEnergy: aep,
        grossEnergy: gross,
        capacityFactor,
        totalWakeLoss,
        neighbourWakeLoss,
        internalWakeLoss,
        turbineSpecificLoss,
        status,
        stoppedReason,
        progress,
        analysisVersion,
        wakeModel: WAKE_MODEL_NAME[wakeSettings.wakeModel],
      },
      [ComparisonAttributeKey.CABLING]: {
        exportCableLength,
        interArrayCableLength,
        totalInterArrayLoss,
        totalExportSystemLoss,
        numberOffShoreSubstations,
        numberExportCables,
        offshoreCableLength,
        onshoreCableLength,
        cableTypes: cablesToAdd,
        interArrayCableVoltages,
        exportCableVoltages,
        cableLengthContingency,
        exportCableTypesString,
        exportCableTypesOnshoreString,
        maxExportCableUtilizationOnshore:
          maxExportCablesUtilization?.onshoreUtilization || null,
        maxExportCableUtilizationOffshore:
          maxExportCablesUtilization?.offshoreUtilization || null,
      },
      [ComparisonAttributeKey.FINANCIAL]: {
        lcoe: lcoe,
        devex: devexNPV,
        capex: capexNPV,
        opex: opexNPV,
        decommissioning: decomNPV,
        irr: irr && "ok" in irr ? irr.ok : undefined,
        turbineCost: totalTurbineCost,
        cableCost: totalCableCost,
        foundationCost: totalFoundationCost,
        mooringCost: totalMooringCost,
        substationCost: totalSubstationCost,
        exportCableCost: totalExportCableCost,
        otherCapexCost: totalOtherCapexCost,
      },
      [ComparisonAttributeKey.FOUNDATIONS]: {
        primarySteelWeight,
        minFoundationWeight,
        avgFoundationWeight,
        maxFoundationWeight,
        minFoundationDepth,
        avgFoundationDepth,
        maxFoundationDepth,
      },
      [ComparisonAttributeKey.FINANCIAL_INPUTS]: {
        lifeTime: lifeTime,
        aep: netAEP,
        contingency,
        discountRate,
        inflationRate,
      },
    }),
    [
      nrTurbines,
      turbineTypesString,
      parkLocation.geometry.coordinates,
      minValue,
      maxValue,
      averageDepth,
      area,
      averageTurbineHeight,
      windStats,
      windSource,
      averageTurbineSpeed,
      capacity,
      otherLoss,
      capacityDensity,
      aep,
      gross,
      capacityFactor,
      totalWakeLoss,
      neighbourWakeLoss,
      internalWakeLoss,
      turbineSpecificLoss,
      status,
      stoppedReason,
      progress,
      analysisVersion,
      exportCableLength,
      interArrayCableLength,
      totalInterArrayLoss,
      totalExportSystemLoss,
      numberOffShoreSubstations,
      numberExportCables,
      offshoreCableLength,
      onshoreCableLength,
      cablesToAdd,
      cableLengthContingency,
      lcoe,
      netAEP,
      devexNPV,
      capexNPV,
      opexNPV,
      decomNPV,
      lifeTime,
      irr,
      primarySteelWeight,
      minFoundationWeight,
      avgFoundationWeight,
      maxFoundationWeight,
      minFoundationDepth,
      avgFoundationDepth,
      maxFoundationDepth,
      wakeSettings.wakeModel,
      contingency,
      discountRate,
      inflationRate,
      interArrayCableVoltages,
      exportCableVoltages,
      totalTurbineCost,
      totalCableCost,
      totalFoundationCost,
      totalMooringCost,
      totalSubstationCost,
      totalExportCableCost,
      totalOtherCapexCost,
      exportCableTypesString,
      exportCableTypesOnshoreString,
      maxExportCablesUtilization?.offshoreUtilization,
      maxExportCablesUtilization?.onshoreUtilization,
    ],
  );

  return toReturn;
};

export default useParkComparisonValues;
