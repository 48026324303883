/// <reference types="vite-plugin-svgr/client" />
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ColorSelectorWrapper,
  FeatureCoordinateEditorWrapper,
} from "./FeatureCoordinateEditor.style";
import { useClickOutside } from "../../hooks/useClickOutside";
import CoordinatesAltIcon from "@icons/24/CoordinatesAlt.svg";
import styled from "styled-components";
import { _ProjectFeature, ProjectFeature } from "../../types/feature";
import Tooltip from "../General/Tooltip";
import { StandardBox } from "../../styles/boxes/Boxes";
import { Feature, LineString, Point, Polygon, Position } from "geojson";
import { colors } from "../../styles/colors";
import { validWGS84Coords } from "../../utils/geojson/utils";
import { TextArea } from "../General/Input";
import { useProjectElementsCrud } from "../ProjectElements/useProjectElementsCrud";
import { useTypedPath } from "../../state/pathParams";
import { trackCanvasOption } from "./MenuTracking";
import Button from "../General/Button";
import { Row } from "../General/Layout";
import { IconBtn } from "components/General/Icons";
import { useToast } from "hooks/useToast";
import CustomCRSDropdown, {
  selectedCRSAtom,
} from "components/CustomCRSDropdown/CustomCRSDropdown";
import { useRecoilValue } from "recoil";
import { toWGS84, wgs84ToProjected } from "utils/proj4";

const Header = styled.h2`
  padding: 0;
  margin: 0;
  white-space: nowrap;
`;

const HeaderWrapper = styled.div`
  padding: 1.2rem 0;
  border-bottom: 1px solid ${colors.hoverSelected};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  svg {
    height: 2.4rem;
    width: 2.4rem;
  }
`;

const FeatureCoordinatesContainer = styled(StandardBox)`
  position: fixed;
  left: 0;
  margin-top: 2rem;
  transform: translateX(-60%);
`;

const coordinatesAreEqual = (
  currCoordinates: ([number, number] | Position)[],
  nextCoordinates: ([number, number] | Position)[],
) => {
  if (currCoordinates.length !== nextCoordinates.length) return false;

  return currCoordinates.every(
    (n, i) => n[0] === nextCoordinates[i][0] && n[1] === nextCoordinates[i][1],
  );
};

const CoordinateBox = ({
  coords,
  setOpen,
  type,
  onSave,
}: {
  coords: Position[];
  setOpen: Dispatch<SetStateAction<boolean>>;
  type: "Polygon" | "LineString" | "Point";
  onSave: (newCoords: string, isAutoSave: boolean, proj4String: string) => void;
}) => {
  const selectedCrs = useRecoilValue(selectedCRSAtom);
  const formatInput = (input: string) => input.replaceAll("\t", " ");
  const originalCoords = useMemo(
    () =>
      coords
        .map((coord) => wgs84ToProjected(coord, selectedCrs.proj4string))
        .map((c) => `${c[0]} ${c[1]}`)
        .join("\n"),
    [coords, selectedCrs],
  );
  const [localCoords, setLocalCoords] = useState(originalCoords);
  useEffect(() => {
    setLocalCoords(originalCoords);
  }, [originalCoords]);
  const opacityContainerRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(opacityContainerRef, () => setOpen(false), undefined, {
    ignoreDragClicks: true,
  });

  return (
    <FeatureCoordinatesContainer ref={opacityContainerRef}>
      <FeatureCoordinateEditorWrapper>
        <HeaderWrapper>
          <Header>{`Feature from coordinates - ${type}`}</Header>
        </HeaderWrapper>
        <CustomCRSDropdown />
        <TextArea
          autoFocus
          style={{ resize: "none" }}
          cols={40}
          rows={17}
          placeholder={
            "Coordinates (lng lat/x y) with space separating, new line for next coordinate, eg \n\n10.409491 58.751193\n10.409491 58.669206\n10.58692 58.66920"
          }
          value={localCoords}
          onChange={(e) => {
            setLocalCoords(formatInput(e.target.value));
          }}
          onBlur={() => {
            if (originalCoords === localCoords) return;
            onSave(localCoords, true, selectedCrs.proj4string);
          }}
          onKeyDown={(e) => e.stopPropagation()}
        />
        <Row style={{ margin: "0 1rem 1rem", justifyContent: "flex-end" }}>
          <Button
            buttonType="secondary"
            text="Cancel"
            onClick={() => setOpen(false)}
          />
          <Button
            buttonType="primary"
            text="Save"
            disabled={originalCoords === localCoords}
            onClick={() => onSave(localCoords, false, selectedCrs.proj4string)}
          />
        </Row>
      </FeatureCoordinateEditorWrapper>
    </FeatureCoordinatesContainer>
  );
};

const getCoords = (
  coords: string,
  proj4String: string,
): [number, number][] | undefined => {
  try {
    const parsedCoords = coords
      .trim()
      .split("\n")
      .map((line) =>
        line
          .split(" ")
          .slice(0, 2)
          .map((n) => parseFloat(n)),
      )
      .map((c) => toWGS84(c, proj4String));

    if (parsedCoords.find((c) => c.length !== 2 || !validWGS84Coords(c)))
      return;
    return parsedCoords as [number, number][];
  } catch (e) {
    return;
  }
};

const CoordinatePoint = ({
  setOpen,
  feature,
}: {
  setOpen: Dispatch<SetStateAction<boolean>>;
  feature: Feature<Point>;
}) => {
  const { update: updateFeatures } = useProjectElementsCrud();
  const { warning: showWarning } = useToast();

  const _onSave = useCallback(
    (coords: string, isAutoSave: boolean, proj4String: string) => {
      const validCoords = getCoords(coords, proj4String);
      if (!validCoords) return;

      const updatedFeature = _ProjectFeature.safeParse({
        ...feature,
        geometry: { ...feature.geometry, coordinates: validCoords[0] },
      });

      if (!updatedFeature.success) {
        if (!isAutoSave) {
          showWarning("Could not save, are the coordinates valid?");
        }
        return;
      }

      updateFeatures({ update: [updatedFeature.data] });
    },
    [feature, updateFeatures, showWarning],
  );

  return (
    <CoordinateBox
      coords={[feature.geometry.coordinates]}
      setOpen={setOpen}
      type={"Point"}
      onSave={_onSave}
    />
  );
};

const CoordinateLineString = ({
  setOpen,
  feature,
}: {
  setOpen: Dispatch<SetStateAction<boolean>>;
  feature: Feature<LineString>;
}) => {
  const { warning: showWarning } = useToast();
  const { update: updateFeatures } = useProjectElementsCrud();

  const _onSave = useCallback(
    (newCoords: string, isAutoSave: boolean, proj4String: string) => {
      const validCoords = getCoords(newCoords, proj4String);
      const previousCoordinates = feature.geometry.coordinates;
      if (!validCoords || coordinatesAreEqual(validCoords, previousCoordinates))
        return;
      const updatedFeature = _ProjectFeature.safeParse({
        ...feature,
        geometry: { ...feature.geometry, coordinates: validCoords },
      });
      if (!updatedFeature.success) {
        if (!isAutoSave) {
          showWarning("Could not save, are the coordinates valid?");
        }
        return;
      }

      updateFeatures({ update: [updatedFeature.data] });
    },
    [feature, updateFeatures, showWarning],
  );

  return (
    <CoordinateBox
      coords={feature.geometry.coordinates}
      setOpen={setOpen}
      type={"LineString"}
      onSave={_onSave}
    />
  );
};

const CoordinateBoxPolygon = ({
  setOpen,
  feature,
}: {
  setOpen: Dispatch<SetStateAction<boolean>>;
  feature: Feature<Polygon>;
}) => {
  const { warning: showWarning } = useToast();
  const { update: updateFeatures } = useProjectElementsCrud();

  const _onSave = useCallback(
    (newCoords: string, isAutoSave: boolean, proj4String: string) => {
      const validCoords = getCoords(newCoords, proj4String);
      const previousCoordinates = feature.geometry.coordinates[0].slice(0, -1);
      if (!validCoords || coordinatesAreEqual(validCoords, previousCoordinates))
        return;

      const updatedFeature = _ProjectFeature.safeParse({
        ...feature,
        geometry: {
          ...feature.geometry,
          coordinates: [[...validCoords, validCoords[0]]],
        },
      });

      if (!updatedFeature.success) {
        if (!isAutoSave) {
          showWarning("Could not save, are the coordinates valid?");
        }
        return;
      }

      updateFeatures({ update: [updatedFeature.data] });
    },
    [feature, updateFeatures, showWarning],
  );

  return (
    <CoordinateBox
      coords={feature.geometry.coordinates[0].slice(0, -1)}
      setOpen={setOpen}
      type={"Polygon"}
      onSave={_onSave}
    />
  );
};

const CoordinateEditor = ({
  setOpen,
  feature,
}: {
  setOpen: Dispatch<SetStateAction<boolean>>;
  feature: ProjectFeature;
}) => {
  switch (feature.geometry.type) {
    case "Polygon":
      return (
        <CoordinateBoxPolygon
          setOpen={setOpen}
          feature={feature as Feature<Polygon>}
        />
      );
    case "LineString":
      return (
        <CoordinateLineString
          setOpen={setOpen}
          feature={feature as Feature<LineString>}
        />
      );
    case "Point":
      return (
        <CoordinatePoint
          setOpen={setOpen}
          feature={feature as Feature<Point>}
        />
      );
    default:
      return null;
  }
};

const FeatureCoordinateEditor = ({ feature }: { feature: ProjectFeature }) => {
  const { projectId, branchId } = useTypedPath("projectId", "branchId");
  const [open, setOpen] = useState(false);
  const disabled = useMemo(
    () => feature.geometry.type.includes("Multi"),
    [feature],
  );

  return (
    <Tooltip
      position="top"
      text={
        disabled
          ? `Can not edit features with holes in it`
          : `Edit feature coordinates`
      }
    >
      <ColorSelectorWrapper>
        <IconBtn
          active={open}
          $fill={true}
          onClick={() => {
            !disabled && setOpen(!open);
            trackCanvasOption("open-feature-coordinate-editor", {
              projectId,
              branchId,
            });
          }}
        >
          <CoordinatesAltIcon />
        </IconBtn>
        {open && <CoordinateEditor setOpen={setOpen} feature={feature} />}
      </ColorSelectorWrapper>
    </Tooltip>
  );
};

export default FeatureCoordinateEditor;
