import * as turf from "@turf/turf";
import { Feature, Point as GeoJsonPoint } from "geojson";
import { DateTime } from "luxon";
import mapboxgl, { LngLatBoundsLike } from "mapbox-gl";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import PlayIcon from "@icons/24/Play.svg?react";
import PauseIcon from "@icons/24/Pause.svg?react";
import {
  SetterOrUpdater,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import styled from "styled-components";

import tzlookup from "tz-lookup";
import * as utm from "utm";
import { v4 as uuidv4 } from "uuid";
import { VIEW_POINT_COLOR } from "@constants/viewPoint";
import Eye from "@icons/24/View.png";
import { ProjectFeature } from "types/feature";
import { libraryLayersOpenAtom } from "state/layer";
import {
  getAllTurbinesSelector,
  getInvalidTypesInParkAndBranch,
  getTurbinesSelectorFamily,
} from "state/layout";
import { getParkFeaturesSelector } from "state/park";
import { viewPointsFeaturesSelector } from "state/projectLayers";
import { allSimpleTurbineTypesSelector } from "state/turbines";
import {
  LngLat,
  VIEW_MODE,
  heightAboveGroundAtom,
  turbineScalingAtom,
  viewCameraAspectAtom,
  viewDateAtom,
  viewFovAtom,
  viewFromShoreParkIdAtom,
  viewHeightAtom,
  viewOrigoSelector,
  viewOrigoWGS84Atom,
  viewParkRotationAtom,
  viewPositionAtom,
  viewProj4StringAtom,
  viewTowardsSelector,
  viewTowardsWGS84Atom,
  viewTurbineCoordsAtom,
  viewTurbineHeightsAtom,
  viewTurbineLightsAtom,
  viewViewModeAtom,
  visibleParksAtom,
  viewTurbineDiameterAtom,
  viewFromShoreTerrainColorAtom,
  viewFromShoreTerrainVisibleAtom,
  viewFromShoreTerrainColorActiveAtom,
  threeCoreAtom,
  viewFromShoreOSMBuildingsActiveAtom,
  osmBuildingTileStatusSelector,
  viewFromShoreFreeCameraAtom,
} from "state/viewToPark";
import Toggle, { ToggleSize } from "../General/Toggle";
import { ToEastLatLng } from "utils/geojson/utils";
import { getBBOXArrayFromFeatures } from "utils/geojson/validate";
import { isDefined, isNumber } from "utils/predicates";
import { getDistanceFromLatLonInM, wgs84ToProjected } from "utils/proj4";
import { dedup, isInChecklyMode, usingMac } from "utils/utils";
import Dropdown from "../Dropdown/Dropdown";
import Button from "../General/Button";
import Checkbox from "../General/Checkbox";
import { ColoredGrid, Label } from "../General/Form";
import { Input } from "../General/Input";
import { Column, Row } from "../General/Layout";
import { Slider } from "../General/Slider";
import LineString from "../MapFeatures/LineString";
import Point from "../MapFeatures/Point";
import Polygon from "../MapFeatures/Polygon";
import { mapboxAccessToken } from "../MapNative/constants";
import { ChevronIcon } from "../ToggleableList/ToggleableList";
import { SkeletonText } from "components/Loading/Skeleton";
import { typography } from "styles/typography";
import { mapContext } from "../RightSide/InfoModal/ProjectFeatureInfoModal/MapContextProvider";
import { ParkFeature } from "types/feature";
import {
  DIVISION_FACTOR,
  ViewPositionMapBoxId,
  FIXED_VIEW_POINT_ID,
  ViewOrigoPositionMapBoxId,
  ViewPointPositionMapBoxId,
  FOVLineMapBoxId,
  TerrainBBOXMapBoxSourceId,
  TerrainBBOXMapBoxLayerId,
  OSMMapBoxLayerId,
  OSMMapBoxSourceId,
} from "./constants";
import { MenuFrame } from "../MenuPopup/CloseableMenuPopup";
import { borderRadiusMedium, spaceSmall, spaceTiny } from "styles/space";
import { colors } from "styles/colors";
import { activeMapStyleIdAtom } from "state/map";
import useTextInput from "../../hooks/useTextInput";
import { RenderParks } from "components/Mapbox/Parks";
import { RenderTurbines } from "components/Mapbox/Turbines";
import { parkIdSelector } from "state/pathParams";
import { verticalFovToHorizontalFov } from "./utils";
import ColorSelector from "components/ColorSelector/ColorSelector";
import useSystemSpecificUnicode from "hooks/useSystemSpecificUnicode";
import { InvalidTypes, InvalidTypesDisplayText } from "types/invalidTypes";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import {
  getDate,
  takePrintScreen,
} from "ScreenCaptureMode/ScreenCaptureWrapper";
import SplitButton from "components/General/SplitButton";
import { MenuItem } from "components/General/Menu";
import {
  Divider,
  InputTitle,
  OverlineText,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import { RangeWithDimInput } from "components/General/RangeWithDimInput";
import { Tag } from "components/GenerateWindTurbines/Tags";
import { loggedInUserIsInternalSelector } from "state/user";
import {
  getLoadingTerrainTilesFromMapbox,
  terrainPatchesRequestedAtom,
} from "./ThreeContext/useDynamicTerrain";
import { tile2bbox } from "types/tile";
import { getFOVLines } from "./ThreeContext/utils";

mapboxgl.accessToken = mapboxAccessToken;

const viewPointPaint: mapboxgl.CirclePaint = {
  "circle-radius": 6,
  "circle-color": VIEW_POINT_COLOR,
};

const viewTowardsPointPaint: mapboxgl.CirclePaint = {
  "circle-radius": 6,
  "circle-color": "#0000ff",
};

const fovPaint: mapboxgl.LinePaint = {
  "line-color": "#888",
  "line-width": 1,
};

const MapWrapper = styled.div`
  height: 100%;
  width: 100%;
  border-radius: ${borderRadiusMedium};
`;

const MapWithControlsWrapper = styled.div`
  position: relative;
  height: 43rem;
  width: 53rem;
  border-radius: ${borderRadiusMedium};
`;

const ControlsWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  z-index: 1;
  margin: 1rem 1rem;
`;

const ControlBasic = styled.div`
  background: ${colors.background};
  border-radius: ${borderRadiusMedium};
  ${typography.contentAndButtons}
  display: flex;
  align-items: center;
`;

const ControlText = styled(ControlBasic)`
  padding: 0 1rem;
`;

const TurbineTable = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  margin-top: 1rem;
`;

const TurbineHeader = styled.p`
  font-weight: 600;
`;

const CollapsableContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const MapAndControllerWrapper = styled.div`
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1rem;
`;

const HintPopupWrapper = styled.div`
  ${typography.body}
  padding: ${spaceSmall};
  background-color: ${colors.surfaceBrand};
  color: ${colors.surfacePrimary};
  border-radius: 4px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    margin: ${spaceTiny};
  }
`;

const TurbineInformation = () => {
  const parkId = useRecoilValue(parkIdSelector);
  const turbineScaling = useRecoilValue(turbineScalingAtom);
  const allTurbines = useRecoilValue(allSimpleTurbineTypesSelector);
  const turbines = useRecoilValue(
    getTurbinesSelectorFamily({ parkId: parkId ?? "" }),
  );

  const turbineTypes = useMemo(
    () => Object.fromEntries(allTurbines.map((t) => [t.id, t])),
    [allTurbines],
  );

  const usedTurbineTypes = useMemo(
    () =>
      dedup(turbines.map((t) => t.properties.turbineTypeId).filter(isDefined)),
    [turbines],
  );

  const usedTurbines = useMemo(
    () => usedTurbineTypes.map((t) => turbineTypes[t]),
    [usedTurbineTypes, turbineTypes],
  );

  const isScaled = turbineScaling !== 1;

  return (
    <>
      {usedTurbines.length === 1 && (
        <Row
          style={{
            flexWrap: "wrap",
          }}
        >
          <Tag style={{ whiteSpace: "nowrap" }}>{usedTurbines[0].name}</Tag>
          <Tag style={{ whiteSpace: "nowrap" }}>
            Height (m): {usedTurbines[0].hubHeight * turbineScaling}{" "}
            {isScaled ? "*" : ""}
          </Tag>
          <Tag style={{ whiteSpace: "nowrap" }}>
            Diameter (m): {usedTurbines[0].diameter * turbineScaling}{" "}
            {isScaled ? "*" : ""}
          </Tag>
        </Row>
      )}
      {usedTurbines.length > 1 && (
        <TurbineTable>
          <TurbineHeader>Turbine</TurbineHeader>
          <TurbineHeader>Height (m)</TurbineHeader>
          <TurbineHeader>Diameter (m)</TurbineHeader>
          {usedTurbines.map((t) => (
            <React.Fragment key={t.id}>
              <p>{t.name}</p>
              <p>
                {t.hubHeight * turbineScaling}
                {isScaled ? "*" : ""}
              </p>
              <p>
                {t.diameter * turbineScaling}
                {isScaled ? "*" : ""}
              </p>
            </React.Fragment>
          ))}
        </TurbineTable>
      )}
      {isScaled && <div>* scaled size</div>}
    </>
  );
};

export const ViewToPark = ({
  park,
  branchId,
  projectData,
  turbineHeights,
  turbineDiameters,
  onClose,
}: {
  park: ParkFeature;
  branchId: string;
  projectData: ProjectFeature[];
  turbineHeights: Map<string, number>;
  turbineDiameters: Map<string, number>;
  onClose(): void;
}) => {
  const [viewPosition, setViewPosition] = useRecoilState(viewPositionAtom);
  const setDate = useSetRecoilState(viewDateAtom);
  const [fov, setFov] = useRecoilState(viewFovAtom);
  const [parkRotation, setParkRotation] = useRecoilState(viewParkRotationAtom);
  const [viewHeight, setViewHeight] = useRecoilState(viewHeightAtom);
  const setOrigoWGS84 = useSetRecoilState(viewOrigoWGS84Atom);
  const [viewTowardsWGS84, setViewTowardsWGS84] =
    useRecoilState(viewTowardsWGS84Atom);
  const cameraAspect = useRecoilValue(viewCameraAspectAtom);
  const [viewMode, setViewMode] = useRecoilState(viewViewModeAtom);
  const [turbineLights, setTurbineLights] = useRecoilState(
    viewTurbineLightsAtom,
  );
  const setParkId = useSetRecoilState(viewFromShoreParkIdAtom);
  const allParks = useRecoilValue(getParkFeaturesSelector);
  const visibleParks = useRecoilValue(visibleParksAtom(park.id));
  const resetVisibleParksState = useResetRecoilState(visibleParksAtom(park.id));

  const allTurbines = useRecoilValue(getAllTurbinesSelector);
  const allVisibleTurbines = useMemo(
    () =>
      allTurbines.filter((t) => {
        const parkId = t.properties.parentIds?.[0];
        return parkId && visibleParks[parkId];
      }),
    [allTurbines, visibleParks],
  );
  const [proj4String, setProj4String] = useRecoilState(viewProj4StringAtom);
  const location = useLocation();
  const setLibraryLayersOpen = useSetRecoilState(libraryLayersOpenAtom);

  useEffect(() => {
    setParkId(park.id);
  }, [park, setParkId]);

  useEffect(() => {
    setLibraryLayersOpen(false);
  }, [setLibraryLayersOpen]);

  useEffect(() => {
    return () => {
      resetVisibleParksState();
    };
  }, [resetVisibleParksState]);

  useEffect(() => {
    if (!park) {
      setViewPosition(undefined);
      return;
    }

    const origo = turf.center(park);
    const origoUTM = utm.fromLatLon(
      origo.geometry.coordinates[1],
      origo.geometry.coordinates[0],
    );

    const timezone = tzlookup(
      origo.geometry.coordinates[1],
      origo.geometry.coordinates[0],
    );
    setDate(DateTime.fromObject({ month: 8, hour: 18 }, { zone: timezone }));

    setProj4String(
      `+proj=utm +zone=${origoUTM.zoneNum} +datum=WGS84 +units=m +no_defs +type=crs`,
    );
    const id = uuidv4();
    setOrigoWGS84({ ...origo, id, properties: { ...origo.properties, id } });
    setViewTowardsWGS84({
      ...origo,
      id,
      properties: { ...origo.properties, id },
    });
    setViewPosition(
      ToEastLatLng(
        {
          lng: origo.geometry.coordinates[0],
          lat: origo.geometry.coordinates[1],
        },
        20,
      ),
    );
  }, [
    projectData,
    setProj4String,
    setOrigoWGS84,
    setViewTowardsWGS84,
    setViewPosition,
    park,
    setDate,
  ]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get("viewposlng") && query.get("viewposlat")) {
      setViewPosition({
        lng: parseFloat(query.get("viewposlng")!),
        lat: parseFloat(query.get("viewposlat")!),
      });
    }
  }, [location, setViewPosition]);

  const origo = useRecoilValue(viewOrigoSelector);

  const setViewTurbineCoordsAtom = useSetRecoilState(viewTurbineCoordsAtom);
  const setViewTurbineHeightsAtom = useSetRecoilState(viewTurbineHeightsAtom);
  const setViewTurbineDiameterAtom = useSetRecoilState(viewTurbineDiameterAtom);

  useEffect(() => {
    if (!origo || !proj4String) {
      setViewTurbineCoordsAtom(undefined);
      return;
    }

    const coordinates = allVisibleTurbines.map((f) => {
      return wgs84ToProjected(f.geometry.coordinates, proj4String);
    });

    if (coordinates.length === 0) {
      setViewTurbineCoordsAtom([]);
      return;
    }

    setViewTurbineCoordsAtom(
      coordinates.map((coords) => [
        (coords[0] - origo[0]) / DIVISION_FACTOR,
        (coords[1] - origo[1]) / DIVISION_FACTOR,
      ]),
    );
    setViewTurbineHeightsAtom(
      allVisibleTurbines
        .map((f) => turbineHeights.get(f.properties.id))
        .filter(isDefined),
    );
    setViewTurbineDiameterAtom(
      allVisibleTurbines
        .map((f) => turbineDiameters.get(f.properties.id))
        .filter(isDefined),
    );
  }, [
    origo,
    proj4String,
    allVisibleTurbines,
    setViewTurbineCoordsAtom,
    setViewTurbineHeightsAtom,
    turbineHeights,
    setViewTurbineDiameterAtom,
    turbineDiameters,
  ]);

  if (!origo || !viewPosition) return null;

  return (
    <MapController
      onClose={onClose}
      park={park}
      branchId={branchId}
      allParks={allParks}
      viewPosition={viewPosition}
      setViewPosition={setViewPosition}
      fov={fov}
      setFov={setFov}
      parkRotation={parkRotation}
      setParkRotation={setParkRotation}
      viewTowardsWGS84={viewTowardsWGS84}
      setViewTowardsWGS84={setViewTowardsWGS84}
      cameraAspect={cameraAspect}
      viewMode={viewMode}
      setViewMode={setViewMode}
      viewHeight={viewHeight}
      setViewHeight={setViewHeight}
      turbineLights={turbineLights}
      setTurbineLights={setTurbineLights}
      proj4String={proj4String}
    />
  );
};

const TerrainInformation = () => {
  const terrainLoading = useRecoilValue(getLoadingTerrainTilesFromMapbox);
  const [terrainColor, setTerrainColor] = useRecoilState(
    viewFromShoreTerrainColorAtom,
  );
  const [terrainColorActive, setTerrainColorActive] = useRecoilState(
    viewFromShoreTerrainColorActiveAtom,
  );
  const [viewFromShoreTerrainVisible, setViewFromShoreTerrainVisible] =
    useRecoilState(viewFromShoreTerrainVisibleAtom);
  const [terrainInfoOpen, setTerrainInfoOpen] = useState(false);
  if (terrainLoading.length !== 0) {
    return (
      <Column>
        <p>{`${terrainLoading.length}`} terrain tiles loading...</p>
        <SkeletonText style={{ width: "100%" }} />
      </Column>
    );
  }

  return (
    <>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          setTerrainInfoOpen(!terrainInfoOpen);
        }}
      >
        <SubtitleWithLine
          text={"Terrain info"}
          expandButton={
            <ChevronIcon
              open={terrainInfoOpen}
              chevronSize={"1.4rem"}
              style={{
                alignSelf: "center",
              }}
            />
          }
        />
      </div>
      {terrainInfoOpen && (
        <>
          <ColoredGrid>
            <p>Terrain source:</p>
            <p>
              <a
                href={
                  "https://docs.mapbox.com/data/tilesets/reference/mapbox-terrain-dem-v1/"
                }
                target={"_blank"}
                rel="noopener noreferrer"
              >
                Mapbox
              </a>
            </p>
          </ColoredGrid>
          <OverlineText>Options</OverlineText>
          <Column
            style={{
              gap: "0.8rem",
              paddingBottom: "1.6rem",
            }}
          >
            <Label right>
              <InputTitle>Terrain visible</InputTitle>
              <Toggle
                style={{ flex: "initial" }}
                checked={viewFromShoreTerrainVisible}
                onChange={() =>
                  setViewFromShoreTerrainVisible(!viewFromShoreTerrainVisible)
                }
                size={ToggleSize.SMALL}
              />
            </Label>
            <Label right>
              <InputTitle>Terrain color</InputTitle>
              <Toggle
                style={{ flex: "initial" }}
                checked={terrainColorActive}
                onChange={() => setTerrainColorActive(!terrainColorActive)}
                size={ToggleSize.SMALL}
              />
            </Label>
            {terrainColorActive && (
              <Label right>
                <InputTitle>Set terrain color</InputTitle>
                <ColorSelector
                  color={terrainColor}
                  setColor={(e) => {
                    setTerrainColor(e as string);
                  }}
                  position={"left"}
                />
              </Label>
            )}
          </Column>
        </>
      )}
    </>
  );
};

const BuildingInformation = () => {
  const { loading, error } = useRecoilValue(osmBuildingTileStatusSelector);
  const [buildingInfoOpen, setBuildingInfoOpen] = useState(false);
  const [viewFromShoreOSMBuildingsActive, setViewFromShoreOSMBuildingsActive] =
    useRecoilState(viewFromShoreOSMBuildingsActiveAtom);

  return (
    <>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          setBuildingInfoOpen(!buildingInfoOpen);
        }}
      >
        <SubtitleWithLine
          text={"Buildings"}
          expandButton={
            <ChevronIcon
              open={buildingInfoOpen}
              chevronSize={"1.4rem"}
              style={{
                alignSelf: "center",
              }}
            />
          }
        />
      </div>
      {buildingInfoOpen && (
        <>
          <ColoredGrid>
            <p>Buildings source:</p>
            <p>
              <a
                href={
                  "https://docs.mapbox.com/data/tilesets/reference/mapbox-streets-v8#building"
                }
                target={"_blank"}
                rel="noopener noreferrer"
              >
                Mapbox
              </a>
            </p>
          </ColoredGrid>
          <OverlineText>Options</OverlineText>
          <Column
            style={{
              gap: "0.8rem",
            }}
          >
            <Label right>
              <InputTitle>Show buildings</InputTitle>
              <Toggle
                style={{ flex: "initial" }}
                checked={viewFromShoreOSMBuildingsActive}
                onChange={() =>
                  setViewFromShoreOSMBuildingsActive(
                    !viewFromShoreOSMBuildingsActive,
                  )
                }
                size={ToggleSize.SMALL}
              />
            </Label>
          </Column>
          {viewFromShoreOSMBuildingsActive && loading && (
            <div>
              <p>Loading buildings...</p>
              <SkeletonText style={{ width: "100%" }} />
            </div>
          )}
          {viewFromShoreOSMBuildingsActive && error && (
            <SimpleAlert text={"Buildings failed to load"} type={"error"} />
          )}
        </>
      )}
    </>
  );
};

const MapController = ({
  turbineLights,
  setTurbineLights,
  park,
  branchId,
  viewPosition,
  setViewPosition,
  fov,
  setFov,
  parkRotation,
  setParkRotation,
  viewTowardsWGS84,
  setViewTowardsWGS84,
  cameraAspect,
  viewMode,
  setViewMode,
  viewHeight,
  setViewHeight,
  proj4String,
  allParks,
  onClose,
}: {
  turbineLights: boolean;
  setTurbineLights: SetterOrUpdater<boolean>;
  viewPosition: LngLat;
  fov: number;
  setFov: SetterOrUpdater<number>;
  parkRotation: number;
  setParkRotation: SetterOrUpdater<number>;
  viewTowardsWGS84: Feature<GeoJsonPoint> | null;
  setViewTowardsWGS84: SetterOrUpdater<Feature<GeoJsonPoint> | null>;
  cameraAspect: any;
  viewMode: VIEW_MODE;
  setViewMode: SetterOrUpdater<VIEW_MODE>;
  viewHeight: number;
  setViewHeight: SetterOrUpdater<number>;
  proj4String: string | undefined;
  park: ParkFeature;
  branchId: string;
  setViewPosition: SetterOrUpdater<LngLat | undefined>;
  allParks: ParkFeature[];
  onClose(): void;
}) => {
  const [turbineScaling, setTurbineScaling] =
    useRecoilState(turbineScalingAtom);
  const isInternal = useRecoilValue(loggedInUserIsInternalSelector);
  const [freeCamera, setFreeCamera] = useRecoilState(
    viewFromShoreFreeCameraAtom,
  );
  const viewTowards = useRecoilValue(viewTowardsSelector);
  const viewPoints = useRecoilValue(viewPointsFeaturesSelector);
  const [date, setDate] = useRecoilState(viewDateAtom);
  const checkly = useMemo(() => isInChecklyMode(), []);
  const [visibleParks, setVisibleParks] = useRecoilState(
    visibleParksAtom(park.id),
  );
  const [hintPopupHidden, setHintPopupHidden] = useState(false);
  const [tempLat, onTempLatChange] = useTextInput(String(viewPosition.lat));
  const [tempLng, onTempLngChange] = useTextInput(String(viewPosition.lng));

  const [chosenViewpointId, setChosenViewpointId] = useState<
    string | undefined
  >();

  const mapContainer = useRef<HTMLDivElement>(null);
  const mapContextValue = useContext(mapContext);
  const hintPopup = useRef<mapboxgl.Popup>();
  const hintPopupContent = useRef<HTMLDivElement>(null);
  const map = mapContextValue?.map;
  const location = useLocation();
  const activeMapStyleId = useRecoilValue(activeMapStyleIdAtom);
  const threeCore = useRecoilValue(threeCoreAtom);

  const bounds: LngLatBoundsLike = useMemo(() => {
    const viewpoint = viewPoints.find((vp) => vp.id === chosenViewpointId);

    const bbox = getBBOXArrayFromFeatures(
      viewpoint ? [park, viewpoint] : [park],
    );
    const buffer = 0.05;
    return [
      [bbox[0] - buffer, bbox[1] - buffer],
      [bbox[2] + buffer, bbox[3] + buffer],
    ];
  }, [chosenViewpointId, park, viewPoints]);

  useEffect(() => {
    map?.fitBounds(bounds, {
      padding: 30,
      animate: false,
    });
  }, [bounds, map]);

  const sortedParksWithSelectedFirst = useMemo(
    () => [...allParks].sort((a) => (a.id === park.id ? -1 : 0)),
    [park.id, allParks],
  );

  const visibleSortedParksWithSelectedFirst = useMemo(
    () =>
      sortedParksWithSelectedFirst.filter((visiblePark) =>
        Boolean(visibleParks[visiblePark.id]),
      ),
    [sortedParksWithSelectedFirst, visibleParks],
  );

  const allTurbines = useRecoilValue(getAllTurbinesSelector);
  const turbinesInVisibleParks = useMemo(() => {
    const ids = new Set(visibleSortedParksWithSelectedFirst.map((f) => f.id));
    return allTurbines.filter((t) =>
      ids.has(t.properties.parentIds?.[0] ?? ""),
    );
  }, [allTurbines, visibleSortedParksWithSelectedFirst]);

  useEffect(() => {
    if (
      !mapContextValue ||
      mapContextValue.map ||
      checkly ||
      !mapContainer.current
    )
      return;

    const newMap = new mapboxgl.Map({
      container: mapContainer.current,
      style: activeMapStyleId,
      bounds,
    });
    newMap.dragRotate.disable();
    newMap.touchZoomRotate.disableRotation();

    const nav = new mapboxgl.NavigationControl({
      showZoom: true,
      showCompass: false,
      visualizePitch: false,
    });
    newMap.addControl(nav, "bottom-right");

    newMap.on("load", () => {
      newMap.resize();
      newMap.loadImage(Eye, (error, image) => {
        if (error) throw error;
        if (image) newMap.addImage("eye", image);
      });
      newMap.getCanvas().style.cursor = "crosshair";
      mapContextValue.setMap(newMap);
    });
  }, [mapContainer, bounds, checkly, mapContextValue, activeMapStyleId]);

  useEffect(() => {
    if (map) {
      // syncs the div ref <=> mapbox container ref
      document.getElementById("map")?.replaceWith(map.getContainer());
      map.getCanvas().style.cursor = "crosshair";
    }
  }, [map]);

  useEffect(() => {
    if (
      !map ||
      hintPopup.current ||
      !hintPopupContent.current ||
      !viewTowardsWGS84 ||
      hintPopupHidden
    ) {
      return;
    }

    hintPopup.current = new mapboxgl.Popup({
      anchor: "top",
      offset: [0, 20],
      focusAfterOpen: false,
      closeButton: true,
      closeOnClick: true,
    })
      .setDOMContent(hintPopupContent.current!)
      .setLngLat([
        viewTowardsWGS84.geometry.coordinates[0],
        viewTowardsWGS84.geometry.coordinates[1],
      ])
      .addTo(map);
  }, [hintPopupHidden, map, viewTowardsWGS84]);

  useEffect(() => {
    if (!map) return;
    const onClick = (e: mapboxgl.MapMouseEvent) => {
      const { metaKey, altKey } = e.originalEvent;
      if (metaKey || altKey) {
        setViewTowardsWGS84({
          ...viewTowardsWGS84!,
          geometry: {
            ...viewTowardsWGS84!.geometry,
            coordinates: [e.lngLat.lng, e.lngLat.lat],
          },
        });
      } else {
        setChosenViewpointId(undefined);
        setViewPosition(e.lngLat);
      }
    };
    map.on("click", onClick);
    return () => {
      map.off("click", onClick);
    };
  }, [setViewPosition, viewTowardsWGS84, setViewTowardsWGS84, location, map]);

  useEffect(() => {
    if (!map || !viewPosition) return;
    map.addSource(ViewPositionMapBoxId, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [viewPosition.lng, viewPosition.lat],
            },
            properties: {},
          },
        ],
      },
    });
    map.addLayer({
      id: ViewPositionMapBoxId,
      type: "symbol",
      source: ViewPositionMapBoxId, // reference the data source
      layout: {
        "icon-image": "eye", // reference the image
        "icon-size": 1,
      },
    });
    return () => {
      map.removeLayer(ViewPositionMapBoxId);
      map.removeSource(ViewPositionMapBoxId);
    };
  }, [map, viewPosition]);

  const viewTowardsPoint = useMemo<Feature[]>(() => {
    if (!viewTowardsWGS84) return [];
    return [viewTowardsWGS84];
  }, [viewTowardsWGS84]);

  const horizontalFOV = useMemo(
    () => verticalFovToHorizontalFov(cameraAspect, fov),
    [fov, cameraAspect],
  );

  const horizontalFOVFeatures = useMemo<Feature[]>(
    () => getFOVLines(viewTowards, horizontalFOV, proj4String, viewPosition),
    [viewTowards, viewPosition, horizontalFOV, proj4String],
  );

  const distanceToViewPoint = useMemo(
    () =>
      (
        getDistanceFromLatLonInM(viewTowardsWGS84!.geometry.coordinates, [
          viewPosition.lng,
          viewPosition.lat,
        ]) / 1000
      ).toFixed(1),
    [viewPosition, viewTowardsWGS84],
  );

  const onToggleLightsClick = useCallback(() => {
    setTurbineLights(!turbineLights);
  }, [setTurbineLights, turbineLights]);

  const heightAboveGround = useRecoilValue(heightAboveGroundAtom);

  const invalidTypes = useRecoilValue(
    getInvalidTypesInParkAndBranch({ parkId: park.id, branchId }),
  );

  const relevantInvalidTypes = invalidTypes.filter(
    (t) => t.type === InvalidTypes.TurbineTypeInvalid,
  );

  const download4K = () => {
    if (threeCore && park) {
      const formattedDate = getDate();
      takePrintScreen(
        [3840, 2160],
        threeCore.camera.aspect,
        threeCore,
        `${park.properties.name ?? "Unnamed_park"}_${formattedDate}.png`,
      );
    }
  };

  const downloadPanorama = async () => {
    if (!threeCore?.camera || !threeCore?.renderer || !fov || !park) return;

    const horizontalFOV = verticalFovToHorizontalFov(
      threeCore.camera.aspect,
      fov,
    );

    if (!horizontalFOV) return;

    const formattedDate = getDate();
    const resolution = [3840 * 3, 2160] as [number, number];
    takePrintScreen(
      resolution,
      resolution[0] / resolution[1],
      threeCore,
      `${park.properties.name ?? "Unnamed_park"}_${formattedDate}_${
        horizontalFOV * 3
      }_deg`,
    );
  };

  useEffect(() => {
    const val = parseFloat(tempLat);
    if (!isNumber(val)) {
      return;
    }

    setViewPosition((coords?: LngLat) =>
      coords
        ? {
            ...coords,
            lat: val,
          }
        : undefined,
    );
  }, [setViewPosition, tempLat]);

  useEffect(() => {
    const val = parseFloat(tempLng);
    if (!isNumber(val)) {
      return;
    }

    setViewPosition((coords?: LngLat) =>
      coords
        ? {
            ...coords,
            lng: val,
          }
        : undefined,
    );
  }, [setViewPosition, tempLng]);

  const [timeLapse, setTimeLapse] = useState<boolean>(false);

  const [visibleParksOpen, setVisibleParksOpen] = useState(false);

  const [settingsOpen, setSettingsOpen] = useState(false);

  const [otherOpen, setOtherOpen] = useState(false);

  useEffect(() => {
    if (!timeLapse) return;
    const thisDate = DateTime.fromObject({
      year: date.year,
      month: date.month,
      day: date.day,
    });
    let count = 0;
    let interval = setInterval(() => {
      setDate(thisDate.plus({ minutes: 10 * count }));
      count += 1;
    }, 100);
    return () => {
      clearInterval(interval);
      setTimeLapse(false);
    };
  }, [timeLapse, setDate, date.year, date.month, date.day]);

  const systemUnicode = useSystemSpecificUnicode();

  const patchesRequested = useRecoilValue(terrainPatchesRequestedAtom);
  const terrainLoading = useRecoilValue(getLoadingTerrainTilesFromMapbox);

  const patchesRequestedPolygons = useMemo(() => {
    return patchesRequested.map((patch) => {
      const tileBBOX = tile2bbox({ x: patch.x, y: patch.y, z: patch.z });
      const polygon = turf.bboxPolygon(tileBBOX);
      return {
        ...polygon,
        properties: {
          loading: terrainLoading.some(
            (t) => t.x === patch.x && t.y === patch.y && t.z === patch.z,
          ),
        },
      };
    });
  }, [patchesRequested, terrainLoading]);
  const { finished: osmBuildingFeatures } = useRecoilValue(
    osmBuildingTileStatusSelector,
  );

  return (
    <MapAndControllerWrapper>
      <MenuFrame title={"View from shore"} onExit={onClose}>
        {relevantInvalidTypes.length > 0 && (
          <InvalidTypesDisplayText invalidTypes={relevantInvalidTypes} />
        )}
        {relevantInvalidTypes.length === 0 && (
          <Column style={{ gap: 0 }}>
            <div style={{ maxHeight: "calc(72vh - 40rem)", overflowY: "auto" }}>
              <TurbineInformation />
              <div
                style={{ cursor: "pointer", paddingTop: `${spaceTiny}` }}
                onClick={() => {
                  setVisibleParksOpen(!visibleParksOpen);
                }}
              >
                <SubtitleWithLine
                  text={"Visible parks"}
                  expandButton={
                    <ChevronIcon
                      open={visibleParksOpen}
                      chevronSize={"1.4rem"}
                      style={{
                        alignSelf: "center",
                      }}
                    />
                  }
                />
              </div>
              {visibleParksOpen && (
                <CollapsableContentWrapper>
                  {sortedParksWithSelectedFirst.map((park) => {
                    const checked = visibleParks[park.id] ?? false;
                    return (
                      <Checkbox
                        key={park.id + checked}
                        checked={checked}
                        onChange={(e) => {
                          e.preventDefault();
                          setVisibleParks((state) => ({
                            ...state,
                            [park.id]: !checked,
                          }));
                        }}
                        label={park.properties.name}
                        labelPlacement="after"
                      />
                    );
                  })}
                </CollapsableContentWrapper>
              )}
              <div
                style={{ cursor: "pointer", paddingTop: `${spaceTiny}` }}
                onClick={() => {
                  setSettingsOpen(!settingsOpen);
                }}
              >
                <SubtitleWithLine
                  text={"Settings"}
                  expandButton={
                    <ChevronIcon
                      open={settingsOpen}
                      chevronSize={"1.4rem"}
                      style={{
                        alignSelf: "center",
                      }}
                    />
                  }
                />
              </div>
              {settingsOpen && (
                <CollapsableContentWrapper>
                  {viewPosition && (
                    <Label>
                      <InputTitle>Coordinates (lat/lng)</InputTitle>
                      <Row style={{ alignItems: "baseline" }}>
                        <Input
                          compact
                          style={{ width: "100%" }}
                          value={parseFloat(tempLat).toFixed(4)}
                          onChange={onTempLatChange}
                        />
                        <Input
                          compact
                          style={{ width: "100%" }}
                          value={parseFloat(tempLng).toFixed(4)}
                          onChange={onTempLngChange}
                        />
                      </Row>
                    </Label>
                  )}
                  <Label>
                    <InputTitle>Date</InputTitle>
                    <Input
                      compact
                      type="date"
                      value={date.toISO()?.split("T")[0]}
                      onChange={(e) => {
                        if (!date.zoneName || e.target.value === "") return;

                        const newDate = DateTime.fromISO(e.target.value)
                          .setZone(date.zoneName)
                          .set({ hour: date.hour, minute: date.minute });

                        setDate(newDate);
                      }}
                    />
                  </Label>
                  <Label>
                    <InputTitle>Time</InputTitle>
                    <Row style={{ alignItems: "baseline" }}>
                      <Input
                        compact
                        style={{ width: "70%" }}
                        type="time"
                        value={`${date.hour
                          .toString()
                          .padStart(2, "0")}:${date.minute
                          .toString()
                          .padStart(2, "0")}`}
                        onChange={(e) => {
                          const [hour, minute] = e.target.value
                            .split(":")
                            .map((t) => parseInt(t));
                          const newDate = date.set({
                            hour: hour,
                            minute: minute,
                          });
                          setDate(newDate);
                        }}
                      />
                      <Slider
                        min={0}
                        max={24 * 60 - 1}
                        step={1}
                        value={Math.round(date.hour * 60 + date.minute)}
                        onChange={(minutes) => {
                          const newDate = date.set({
                            hour: Math.floor(minutes / 60),
                            minute: minutes % 60,
                          });
                          setDate(newDate);
                        }}
                      />
                      <Button
                        text=""
                        icon={timeLapse ? <PauseIcon /> : <PlayIcon />}
                        buttonType="secondary"
                        onClick={() => {
                          if (!timeLapse) {
                            setTimeLapse(true);
                          } else {
                            setTimeLapse(false);
                          }
                        }}
                      />
                    </Row>
                  </Label>

                  <Label>
                    <InputTitle>Park rotation</InputTitle>
                    <RangeWithDimInput
                      min={0}
                      max={360}
                      inputStep={1}
                      value={parkRotation}
                      onChange={(r) => {
                        setParkRotation(r);
                      }}
                      unit={"deg"}
                    />
                  </Label>

                  <Label>
                    <InputTitle>View height</InputTitle>
                    <RangeWithDimInput
                      inputStep={1}
                      min={1}
                      max={100}
                      value={viewHeight}
                      onChange={(n) => {
                        setViewHeight(n);
                      }}
                      unit={"m"}
                    />
                  </Label>

                  <Label>
                    <InputTitle>Turbine size scaling</InputTitle>
                    <RangeWithDimInput
                      inputStep={0.1}
                      min={0.5}
                      max={1.5}
                      value={turbineScaling}
                      onChange={(n) => {
                        setTurbineScaling(n);
                      }}
                      unit={"x"}
                    />
                  </Label>
                  {cameraAspect && (
                    <Label>
                      <InputTitle>Field of view (vert/hor)</InputTitle>
                      <Slider
                        min={25}
                        max={35}
                        step={1}
                        value={fov}
                        onChange={(e) => {
                          setFov(Math.round(e));
                        }}
                        label
                        renderLabel={() => `${fov}° / ${horizontalFOV}°`}
                      />
                    </Label>
                  )}
                </CollapsableContentWrapper>
              )}

              <TerrainInformation />
              <BuildingInformation />
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setOtherOpen(!otherOpen)}
              >
                <SubtitleWithLine
                  text={"Other"}
                  expandButton={
                    <ChevronIcon
                      open={otherOpen}
                      chevronSize={"1.4rem"}
                      style={{
                        alignSelf: "center",
                      }}
                    />
                  }
                />
              </div>
              {otherOpen && (
                <Column
                  style={{
                    gap: "0.8rem",
                    paddingBottom: "1.2rem",
                  }}
                >
                  <Label right>
                    <InputTitle>Lights (BETA)</InputTitle>
                    <Toggle
                      style={{ flex: "initial" }}
                      checked={turbineLights}
                      onChange={onToggleLightsClick}
                      size={ToggleSize.SMALL}
                    />
                  </Label>
                  <Label right>
                    <InputTitle>Wireframe view</InputTitle>
                    <Toggle
                      style={{ flex: "initial" }}
                      checked={viewMode === VIEW_MODE.WIRE_FRAME_MODE}
                      onChange={() => {
                        if (viewMode === VIEW_MODE.WIRE_FRAME_MODE) {
                          setViewMode(VIEW_MODE.NATURAL_MODE);
                        } else {
                          setViewMode(VIEW_MODE.WIRE_FRAME_MODE);
                        }
                      }}
                      size={ToggleSize.SMALL}
                    />
                  </Label>
                  {isInternal && (
                    <Label right>
                      <InputTitle>Free camera</InputTitle>
                      <Toggle
                        style={{ flex: "initial" }}
                        checked={freeCamera}
                        onChange={() => setFreeCamera(!freeCamera)}
                        size={ToggleSize.SMALL}
                      />
                    </Label>
                  )}
                </Column>
              )}
            </div>
            <Divider
              style={{
                marginBottom: "1.6rem",
                marginLeft: "-2rem",
                marginRight: "-2rem",
              }}
            />

            <Row style={{ justifyContent: "end" }}>
              <SplitButton
                buttonType="secondary"
                text="Screenshot"
                onClick={download4K}
              >
                <MenuItem
                  name="4K Screenshot"
                  onClick={download4K}
                  defaultButton
                ></MenuItem>
                <MenuItem name="Panorama" onClick={downloadPanorama}></MenuItem>
              </SplitButton>
            </Row>
          </Column>
        )}
      </MenuFrame>
      <Column>
        <MapWithControlsWrapper>
          <ControlsWrapper>
            {viewPoints.length !== 0 && (
              <ControlBasic>
                <Dropdown
                  id="fixed-view-points"
                  value={chosenViewpointId ?? FIXED_VIEW_POINT_ID}
                  onChange={(e) => {
                    const selectedViewPoint = viewPoints.find(
                      (vp) => vp.id === e.target.value,
                    );
                    if (!selectedViewPoint) return;

                    setChosenViewpointId(selectedViewPoint.id);
                    setViewPosition({
                      lng: selectedViewPoint.geometry.coordinates[0],
                      lat: selectedViewPoint.geometry.coordinates[1],
                    });
                  }}
                >
                  <option value={FIXED_VIEW_POINT_ID}>Fixed viewpoints</option>
                  {viewPoints.map((vp) => (
                    <option key={vp.id} value={vp.id}>
                      {vp.properties?.name || "Undefined"}
                    </option>
                  ))}
                </Dropdown>
              </ControlBasic>
            )}
            <ControlText>
              Distance to viewpoint: {distanceToViewPoint} km
            </ControlText>
            <ControlText>
              Ground height: {Math.round(heightAboveGround)} m
            </ControlText>
          </ControlsWrapper>
          <MapWrapper id="map" ref={mapContainer} />
          {map && (
            <>
              <RenderParks
                map={map}
                parks={visibleSortedParksWithSelectedFirst}
              />
              <RenderTurbines map={map} turbines={turbinesInVisibleParks} />
              <HintPopupWrapper
                ref={hintPopupContent}
                style={{
                  display: hintPopupHidden ? "none" : undefined,
                }}
              >
                <div>
                  {`Hold '${
                    usingMac()
                      ? systemUnicode("command")
                      : systemUnicode("option")
                  }' and click left mouse button to edit sight
                  direction`}
                </div>

                <Button
                  text="Got it"
                  size="small"
                  onClick={() => {
                    hintPopup.current?.remove();
                    setHintPopupHidden(true);
                  }}
                />
              </HintPopupWrapper>
              <Point
                features={viewTowardsPoint}
                sourceId={ViewOrigoPositionMapBoxId}
                layerId={ViewOrigoPositionMapBoxId}
                map={map}
                paint={viewTowardsPointPaint}
              />
              <Point
                features={viewPoints}
                sourceId={ViewPointPositionMapBoxId}
                layerId={ViewPointPositionMapBoxId}
                map={map}
                paint={viewPointPaint}
              />
              <LineString
                features={horizontalFOVFeatures}
                map={map}
                layerId={FOVLineMapBoxId}
                sourceId={FOVLineMapBoxId}
                paint={fovPaint}
              />
              <Polygon
                features={osmBuildingFeatures}
                sourceId={OSMMapBoxSourceId}
                layerId={OSMMapBoxLayerId}
                map={map}
                paint={{
                  "fill-color": "#fff",
                  "fill-opacity": 1.0,
                }}
                linePaint={{
                  "line-color": "#000",
                  "line-width": 1,
                  "line-opacity": 0.5,
                }}
              />
              <Polygon
                features={patchesRequestedPolygons}
                sourceId={TerrainBBOXMapBoxSourceId}
                layerId={TerrainBBOXMapBoxLayerId}
                map={map}
                paint={{
                  "fill-color": [
                    "case",
                    ["boolean", ["get", "loading"], false],
                    colors.green200,
                    "#77bb77",
                  ],
                  "fill-opacity": 0.3,
                }}
              />
            </>
          )}
        </MapWithControlsWrapper>
      </Column>
    </MapAndControllerWrapper>
  );
};
