import { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  activeTourState,
  activeTourStepState,
  lastEventState,
  stepOrderIndexMapState,
} from "./state";
import * as Tours from "./tours.json";
import { useDrawMode } from "components/MapControls/useActivateDrawMode";
import { useParams, useSearchParams } from "react-router-dom";
import { customerProjectAtomFamily } from "state/timeline";
import { usePutUserHintSettings } from "components/ActiveTips/CloseableHints/hooks";
import { userHintSettingsAtom } from "components/ActiveTips/CloseableHints/state";
import { Mixpanel } from "mixpanel";
import { lowerRightMenuActiveModeAtom } from "state/layer";
import { TourStep } from "./TourStep";

export const TUTORIAL_PROJECT_SEARCH_PARAM = "tutorialProject";
export const SANDBOX_TUTORIAL_ID = "2g5pfzNjvnfGFWkSxEudrHcBqQP";

const useOnboardingTourInitializer = () => {
  const activeTour = useRecoilValue(activeTourState);
  const [activeStep, setActiveStep] = useRecoilState(activeTourStepState);
  const [lastEvent, setLastEvent] = useRecoilState(lastEventState);
  const setStepOrderIndexMap = useSetRecoilState(stepOrderIndexMapState);
  const setActiveTour = useSetRecoilState(activeTourState);

  useEffect(() => {
    if (!activeTour) return;

    const firstStep = activeTour.steps.find((s) => s.step === 0);
    if (firstStep) {
      setLastEvent(null); // reset state
      setActiveStep(firstStep.id);
      Mixpanel.track("Started tour", {
        tour: activeTour.tour_id,
        step: firstStep.id,
      });
    }
  }, [setActiveStep, activeTour, setLastEvent]);

  useEffect(() => {
    if (!activeTour) return;

    const steps = activeTour.steps;
    const map = new Map();
    let currentStepId = steps.find((step) => step.step === 0)?.id;
    let index = 0;

    while (currentStepId) {
      map.set(currentStepId, index);
      const nextStepId = steps.find(
        (step) => step.id === currentStepId,
      )?.nextStep;
      currentStepId = nextStepId;
      index++;
    }

    setStepOrderIndexMap(map);
  }, [activeTour, setStepOrderIndexMap]);

  useEffect(() => {
    if (!activeTour) return;

    const step = activeTour.steps.find((s) => s.id === activeStep);
    if (!step || !step.progressEvent) return;

    if (step.progressEvent === lastEvent) {
      setLastEvent(null); // reset state
      if (step.nextStep) {
        setActiveStep(step.nextStep);
        Mixpanel.track("Onboarding tour step", {
          step: step.id,
          tour: activeTour.tour_id,
          completed: false,
        });
      } else {
        // End of tour
        setActiveTour(undefined);
        Mixpanel.track("Onboarding tour step", {
          step: step.id,
          tour: activeTour.tour_id,
          completed: true,
        });
      }
    }
  }, [
    activeTour,
    activeStep,
    lastEvent,
    setLastEvent,
    setActiveStep,
    setActiveTour,
  ]);

  // Close modals on certain actions
  const [_, setLayoutControlActive] = useDrawMode();
  useEffect(() => {
    if (!activeTour) return;

    if (lastEvent === "optionSelected") setLayoutControlActive(undefined);
    if (lastEvent === "clickedApply") setLayoutControlActive(undefined);
  }, [lastEvent, setLayoutControlActive, activeTour]);
};

export const DEFAULT_ONBOARDING_TOUR = Tours.tours.find((t) => t.default);

const SetTutorialProjectInURLInner = ({ projectId }: { projectId: string }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const setActiveTour = useSetRecoilState(activeTourState);
  const putUserHintSettings = usePutUserHintSettings();
  const userSettings = useRecoilValue(userHintSettingsAtom);

  const project = useRecoilValue(
    customerProjectAtomFamily({ nodeId: projectId }),
  );

  useEffect(() => {
    if (!project) return;
    const { tutorial_id } = project;
    if (!tutorial_id || tutorial_id !== SANDBOX_TUTORIAL_ID) {
      searchParams.delete(TUTORIAL_PROJECT_SEARCH_PARAM);
      setSearchParams(searchParams);
      setActiveTour(undefined);
    }
    if (tutorial_id === SANDBOX_TUTORIAL_ID) {
      const activeTour = Tours.tours.find((t) => t.default);
      if (!activeTour) return;

      if (
        !userSettings.startedTours ||
        !userSettings.startedTours.includes(activeTour.tour_id)
      ) {
        setActiveTour(activeTour);
      }

      if (
        !userSettings.startedTours ||
        !userSettings.startedTours.includes(activeTour.tour_id)
      ) {
        const updatedSettings = [
          ...(userSettings.startedTours ?? []),
          activeTour?.tour_id,
        ];
        putUserHintSettings({
          startedTours: [...updatedSettings],
        });
      }

      if (!searchParams.get(TUTORIAL_PROJECT_SEARCH_PARAM)) {
        searchParams.append(TUTORIAL_PROJECT_SEARCH_PARAM, "true");
        setSearchParams(searchParams);
      }
    }
  }, [
    project,
    searchParams,
    setActiveTour,
    setSearchParams,
    putUserHintSettings,
    userSettings.startedTours,
  ]);
  return null;
};

const SetTutorialProjectInURL = () => {
  const { projectId } = useParams();
  if (!projectId) return null;
  return <SetTutorialProjectInURLInner projectId={projectId} />;
};

export function TutorialProjectOnboarding() {
  const tourId = "general-intro-tour";
  useOnboardingTourInitializer();

  const setLowerRightActiveMode = useSetRecoilState(
    lowerRightMenuActiveModeAtom,
  );

  return (
    <>
      <TourStep
        tourId={tourId}
        stepId="welcome"
        stepAction={() => setLowerRightActiveMode(undefined)}
      />
      <SetTutorialProjectInURL />
    </>
  );
}
