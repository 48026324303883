/// <reference types="vite-plugin-svgr/client" />
import Redo from "@icons/24/Redo.svg?react";
import Undo from "@icons/24/Undo.svg?react";
import { RoundIconBtn } from "components/General/Icons";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import useSystemSpecificUnicode from "../../hooks/useSystemSpecificUnicode";
import { inReadOnlyModeSelector } from "../../state/project";
import {
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "../ErrorBoundaries/ErrorBoundaryLocal";
import Tooltip from "../General/Tooltip";
import { useUndoRedo } from "../MapControls/UndoRedo";
import styled from "styled-components";
import { spaceMedium } from "styles/space";

export const UndoRedoContainer = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: row;
  padding-left: ${spaceMedium};
  gap: ${spaceMedium};
`;

const UndoRedoButtons = ErrorBoundaryWrapper(
  () => {
    return (
      <Suspense fallback={<UndoRedoButtonsPlaceholder />}>
        <UndoRedoButtonsInner />
      </Suspense>
    );
  },
  UndoRedoButtonsPlaceholder,
  ScreamOnError,
);

function UndoRedoButtonsInner() {
  const stringToUnicode = useSystemSpecificUnicode();
  const readOnlyMode = useRecoilValue(inReadOnlyModeSelector);

  const { undo, redo, canUndo, canRedo } = useUndoRedo();

  return (
    <UndoRedoContainer>
      <Tooltip
        position="right"
        text="Undo"
        shortcut={`${stringToUnicode("command")}+Z`}
        revealWithQKey
        readonlyAware
      >
        <RoundIconBtn
          disabled={readOnlyMode || !canUndo}
          onClick={undo}
          id={"undo"}
        >
          <Undo />
        </RoundIconBtn>
      </Tooltip>
      <Tooltip
        position="right"
        text="Redo"
        shortcut={`${stringToUnicode("shift")}+${stringToUnicode("command")}+Z`}
        revealWithQKey
        readonlyAware
      >
        <RoundIconBtn
          disabled={readOnlyMode || !canRedo}
          onClick={redo}
          id={"redo"}
        >
          <Redo />
        </RoundIconBtn>
      </Tooltip>
    </UndoRedoContainer>
  );
}

export function UndoRedoButtonsPlaceholder() {
  const stringToUnicode = useSystemSpecificUnicode();
  return (
    <>
      <Tooltip
        position="right"
        text="Undo"
        shortcut={`${stringToUnicode("command")}+Z`}
        readonlyAware
      >
        <RoundIconBtn disabled={true} onClick={() => {}} id={"undo"}>
          <Undo />
        </RoundIconBtn>
      </Tooltip>
      <Tooltip
        position="right"
        text="Redo"
        shortcut={`${stringToUnicode("shift")}+${stringToUnicode("command")}+Z`}
        readonlyAware
      >
        <RoundIconBtn disabled={true} onClick={() => {}} id={"redo"}>
          <Redo />
        </RoundIconBtn>
      </Tooltip>
    </>
  );
}

export default UndoRedoButtons;
