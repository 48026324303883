import { atom, atomFamily, selector, selectorFamily } from "recoil";
import { z } from "zod";
import {
  PROJECT_SERVICE_API_PATH,
  PROJECT_SERVICE_API_VERSION,
} from "../components/ProjectElements/service";
import {
  isConcreteFoundation,
  isDetailedMonopile,
  isFixed,
  isFloater,
  isJacket,
  isMonopile,
  isSemiCentral,
  isSemiPeripheral,
  isSpar,
  isSteelFoundation,
  valueRounding,
} from "../components/RightSide/InfoModal/FoundationModal/utils";
import { CONCRETE_DENSITY } from "../constants/foundations";
import { fetchEnhancerWithToken } from "../services/utils";
import {
  DEFAULT_JACKETS,
  DEFAULT_MONOPILES,
  DEFAULT_SEMI_CENTRAL_FLOATERS,
  DEFAULT_SEMI_PERIPHERAL_FLOATERS,
  DEFAULT_SPAR_FLOATERS,
  FixedType,
  FloaterType,
  FoundationType,
  FoundationTypeWithLevel,
  _FoundationLevel,
  _FoundationType,
} from "../types/foundations";
import { SimpleTurbineType } from "../types/turbines";
import { isDefined, isSubArea } from "../utils/predicates";
import {
  getTurbinesInBranchSelectorFamily,
  getTurbinesSelectorFamily,
} from "./layout";
import { TurbineFeature } from "../types/feature";
import { projectIdSelector } from "./pathParams";
import { allSimpleTurbineTypesSelector } from "./turbines";
import { currentSelectedProjectFeatures } from "./selection";
import { pointInPolygon } from "../utils/geometry";
import { count } from "../utils/utils";
import { foundationResourceWithAccessOnNodeState } from "components/Organisation/Library/state";
import { getCustomProjectFoundation } from "services/turbineAPIService";

export const defaultFloaters: FloaterType[] = [
  ...DEFAULT_SEMI_CENTRAL_FLOATERS,
  ...DEFAULT_SEMI_PERIPHERAL_FLOATERS,
  ...DEFAULT_SPAR_FLOATERS,
];

export const defaultFixed: FixedType[] = [
  ...DEFAULT_MONOPILES,
  ...DEFAULT_JACKETS,
];

export const defaultFoundations: FoundationType[] = [
  ...defaultFloaters,
  ...defaultFixed,
];

export const defaultFoundationIds = defaultFoundations.map((f) => f.id);

export const allFloaterTypesSelector = selector<FloaterType[]>({
  key: "all-floater-types-selector",
  get: async ({ get }) => {
    const allFoundations = get(allFoundationTypesSelector);
    const allFloaters: FloaterType[] = allFoundations.filter(isFloater);
    return allFloaters.filter((t) => !t.archived);
  },
});

export const allFixedTypesSelector = selector<FixedType[]>({
  key: "all-fixed-types-selector",
  get: async ({ get }) => {
    const allFoundations = get(allFoundationTypesSelector);
    const allFixed: FixedType[] = allFoundations.filter(isFixed);
    return allFixed.filter((t) => !t.archived);
  },
});

export const isFloatingFoundationSelector = selectorFamily<
  boolean,
  { foundationTypeId: string }
>({
  key: "isFloatingFoundationSelector",
  get:
    ({ foundationTypeId }: { foundationTypeId: string }) =>
    ({ get }) => {
      const allFloaters = get(allFloaterTypesSelector);
      const floatingFoundationTypeIds = new Set(
        allFloaters.map(({ id }) => id),
      );

      return foundationTypeId
        ? floatingFoundationTypeIds.has(foundationTypeId)
        : false;
    },
});

export const isFixedFoundationSelector = selectorFamily<
  boolean,
  { foundationTypeId: string }
>({
  key: "isFixedFoundationSelector",
  get:
    ({ foundationTypeId }: { foundationTypeId: string }) =>
    ({ get }) => {
      const allFixed = get(allFixedTypesSelector);
      const fixedFoundationTypeIds = new Set(allFixed.map(({ id }) => id));

      return fixedFoundationTypeIds.has(foundationTypeId);
    },
});

export const isMonopileSelector = selector<(foundationId: string) => boolean>({
  key: "is-monopile-selector",
  get: async ({ get }) => {
    const allFixed = get(allFixedTypesSelector);
    const allMonopiles = allFixed.filter(isMonopile);
    const monopileFoundationTypeIds = new Set(allMonopiles.map(({ id }) => id));

    const isMonopileFoundation = (foundationTypeId: string) =>
      monopileFoundationTypeIds.has(foundationTypeId);

    return isMonopileFoundation;
  },
});

export const isDetailedMonopileSelector = selector<
  (foundationId: string) => boolean
>({
  key: "is-detailed-monopile-selector",
  get: async ({ get }) => {
    const allFixed = get(allFixedTypesSelector);
    const allDetailedMonopiles = allFixed.filter(isDetailedMonopile);
    const detailedMonopileFoundationTypeIds = new Set(
      allDetailedMonopiles.map(({ id }) => id),
    );

    const isDetailedMonopileFoundation = (foundationTypeId: string) =>
      detailedMonopileFoundationTypeIds.has(foundationTypeId);

    return isDetailedMonopileFoundation;
  },
});

export const isJacketSelector = selector<(foundationId: string) => boolean>({
  key: "is-generic-fixed-foundation-selector",
  get: async ({ get }) => {
    const allFixed = get(allFixedTypesSelector);
    const allJackets = allFixed.filter(isJacket);
    const jacketFoundationTypeIds = new Set(allJackets.map(({ id }) => id));

    const isJacketFoundation = (foundationTypeId: string) =>
      jacketFoundationTypeIds.has(foundationTypeId);

    return isJacketFoundation;
  },
});

export const hasFloatingFoundationSelector = selector<
  ({ properties: { foundationId } }: TurbineFeatureWithFoundation) => boolean
>({
  key: "has-floating-foundation-selector",
  get: async ({ get }) => {
    const allFloaters = get(allFloaterTypesSelector);
    const floatingFoundationTypeIds = new Set(allFloaters.map(({ id }) => id));

    const isFloatingFoundation = (foundationTypeId: string) =>
      floatingFoundationTypeIds.has(foundationTypeId);

    const hasFloatingFoundation = ({
      properties: { foundationId },
    }: TurbineFeatureWithFoundation): boolean =>
      isFloatingFoundation(foundationId);

    return hasFloatingFoundation;
  },
});

export const hasFixedFoundationSelector = selector<
  ({ properties: { foundationId } }: TurbineFeatureWithFoundation) => boolean
>({
  key: "has-fixed-foundation-selector",
  get: async ({ get }) => {
    const allFixed = get(allFixedTypesSelector);
    const fixedFoundationTypeIds = new Set(allFixed.map(({ id }) => id));

    const isFixedFoundation = (foundationTypeId: string) =>
      fixedFoundationTypeIds.has(foundationTypeId);

    const hasFixedFoundation = ({
      properties: { foundationId },
    }: TurbineFeatureWithFoundation): boolean =>
      isFixedFoundation(foundationId);

    return hasFixedFoundation;
  },
});

export const hasMonopileSelector = selector<
  ({ properties: { foundationId } }: TurbineFeatureWithFoundation) => boolean
>({
  key: "has-monopile-selector",
  get: async ({ get }) => {
    const allFixed = get(allFixedTypesSelector);
    const allMonopiles = allFixed.filter(isMonopile);
    const monopileTypeIds = new Set(allMonopiles.map(({ id }) => id));

    const isMonopileFoundation = (foundationTypeId: string) =>
      monopileTypeIds.has(foundationTypeId);

    const hasMonopile = ({
      properties: { foundationId },
    }: TurbineFeatureWithFoundation): boolean =>
      isMonopileFoundation(foundationId);

    return hasMonopile;
  },
});

export const hasDetailedMonopileSelector = selector<
  ({ properties: { foundationId } }: TurbineFeatureWithFoundation) => boolean
>({
  key: "has-detailed-monopile-selector",
  get: async ({ get }) => {
    const allFixed = get(allFixedTypesSelector);
    const allDetailedMonopiles = allFixed.filter(isDetailedMonopile);
    const monopileTypeIds = new Set(allDetailedMonopiles.map(({ id }) => id));

    const isDetailedMonopileFoundation = (foundationTypeId: string) =>
      monopileTypeIds.has(foundationTypeId);

    const hasDetailedMonopile = ({
      properties: { foundationId },
    }: TurbineFeatureWithFoundation): boolean =>
      isDetailedMonopileFoundation(foundationId);

    return hasDetailedMonopile;
  },
});

export const hasJacketSelector = selector<
  ({ properties: { foundationId } }: TurbineFeatureWithFoundation) => boolean
>({
  key: "has-generic-fixed-selector",
  get: async ({ get }) => {
    const allFixed = get(allFixedTypesSelector);
    const allJackets = allFixed.filter(isJacket);
    const jacketTypeIds = new Set(allJackets.map(({ id }) => id));

    const isJacketFoundation = (foundationTypeId: string) =>
      jacketTypeIds.has(foundationTypeId);

    const hasJacket = ({
      properties: { foundationId },
    }: TurbineFeatureWithFoundation): boolean =>
      isJacketFoundation(foundationId);

    return hasJacket;
  },
});

export type TurbineFeatureWithFoundation = TurbineFeature & {
  properties: { foundationId: string };
};

export const isTurbineFeatureWithFoundation = (
  t: TurbineFeature,
): t is TurbineFeatureWithFoundation => isDefined(t.properties.foundationId);

export const _FoundationTypeUsage = z.object({
  foundationId: z.string(),
  projectId: z.string(),
  branchId: z.string(),
  featureId: z.string(),
});
export type FoundationTypeUsageType = z.infer<typeof _FoundationTypeUsage>;

export const foundationTypeUsageRefresh = atomFamily<
  number,
  { nodeId: string; foundationId: string }
>({
  key: "foundationTypeUsageRefresh",
  default: () => {
    return 0;
  },
});

export async function fetchFoundationTypeUsage(
  nodeId: string,
  foundationId: string,
) {
  const headers = {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "x-project-data-client-version": PROJECT_SERVICE_API_VERSION,
    },
  };
  const res = await fetchEnhancerWithToken(
    `${PROJECT_SERVICE_API_PATH}/stats/node/${nodeId}/foundationId/${foundationId}`,
    headers,
  );
  const j = await res.json();
  return _FoundationTypeUsage.array().parse(j);
}

export const foundationTypeUsageAtomFamily = atomFamily<
  FoundationTypeUsageType[],
  { nodeId: string; foundationId: string }
>({
  key: "foundationTypeUsageAtomFamily",
  default: selectorFamily<
    FoundationTypeUsageType[],
    { nodeId: string; foundationId: string }
  >({
    key: "foundationTypeUsageSelectorFamily",
    get:
      ({ nodeId, foundationId }) =>
      async ({ get }) => {
        get(foundationTypeUsageRefresh({ nodeId, foundationId }));
        const usage = await fetchFoundationTypeUsage(nodeId, foundationId);
        return usage;
      },
  }),
});

export const projectFoundationTypesSelectorFamily = selectorFamily<
  FoundationType[],
  { nodeId: string | undefined }
>({
  key: "project-foundation-types-selector-family",
  get:
    ({ nodeId }) =>
    async ({ get }) => {
      get(projectFoundationsRefreshAtom);
      if (!nodeId) return [];
      const headers = {
        method: "get",
        headers: {},
      };
      const res = await fetchEnhancerWithToken(
        `/api/turbines/v2/node/${nodeId}/foundations`,
        headers,
      );
      const j = await res.json();
      return _FoundationType
        .array()
        .parse(j)
        .filter((f) => !f.archived);
    },
});

export const advancedFoundationTypeSelectorFamily = atomFamily<
  FoundationType,
  { organisationId: string; foundationId: string }
>({
  key: "advancedFoundationTypeSelectorFamily",
  default: selectorFamily<
    FoundationType,
    { organisationId: string; foundationId: string }
  >({
    key: "advancedFoundationTypeSelectorFamily.default",
    get:
      ({ organisationId, foundationId }) =>
      async () => {
        const foundation = await getCustomProjectFoundation(
          organisationId,
          foundationId,
        );
        return foundation;
      },
  }),
});

export const allFoundationTypesWithLevelSelector = selector<
  FoundationTypeWithLevel[]
>({
  key: "allFoundationTypesWithLevelSelector",
  get: async ({ get }) => {
    const projectId = get(projectIdSelector);
    const projectFoundations = get(
      projectFoundationTypesSelectorFamily({ nodeId: projectId }),
    );

    const libraryFoundations = get(
      foundationResourceWithAccessOnNodeState({ nodeId: projectId }),
    );

    const list: FoundationTypeWithLevel[] = [
      ...defaultFoundations.map((f) => ({
        level: _FoundationLevel.Values.project,
        foundation: f,
      })),
      ...projectFoundations.map((f) => ({
        level: _FoundationLevel.Values.project,
        foundation: f,
      })),
      ...libraryFoundations.map((f) => ({
        level: _FoundationLevel.Values.library,
        foundation: f.foundation,
      })),
    ];

    return list;
  },
});
export const allFoundationTypesSelector = selector<FoundationType[]>({
  key: "all-foundation-types-selector",
  get: async ({ get }) => {
    const withLevels = get(allFoundationTypesWithLevelSelector);
    return withLevels.map((wl) => wl.foundation);
  },
});

export type FloatingFoundationScaledStatistics = {
  turbineTypeId: string;
  foundationId: string;
  scaledConcreteVolume?: number;
  scaledConcreteWeight?: number;
  scaledPrimarySteelWeight?: number;
  scaledSolidBallastWeight: number;
  scaledLiquidBallastWeight: number;
  scaledReinforcementWeight?: number;
  scaledPostTensionCableWeight?: number;
  scaledDisplacementVolume: number;
  scaledDraft?: number;
  scaledFootprintLength?: number;
  scaledFootprintBreath?: number;
  scaledMainDiameter?: number;
};

export const getScaledStatisticsForFloatingFoundation = ({
  currentFoundation,
  turbineTypeId,
  scale,
}: {
  currentFoundation: FloaterType;
  turbineTypeId: string;
  scale: number;
}): FloatingFoundationScaledStatistics => {
  const scaledConcreteVolume = isConcreteFoundation(currentFoundation)
    ? valueRounding(
        (Math.pow(scale, 3) * currentFoundation.primaryMass) / CONCRETE_DENSITY,
        10,
      )
    : undefined;

  const scaledConcreteWeight = isConcreteFoundation(currentFoundation)
    ? valueRounding(
        (Math.pow(scale, 3) * currentFoundation.primaryMass) / 1000,
        10,
      )
    : undefined;

  const scaledPrimarySteelWeight = isSteelFoundation(currentFoundation)
    ? valueRounding(
        (Math.pow(scale, 3) * currentFoundation.primaryMass) / 1000,
        10,
      )
    : undefined;

  const scaledSolidBallastWeight = valueRounding(
    (Math.pow(scale, 3) * currentFoundation.solidBallastMass) / 1000,
    10,
  );

  const scaledLiquidBallastWeight = valueRounding(
    (Math.pow(scale, 3) * currentFoundation.liquidBallastMass) / 1000,
    10,
  );

  const scaledReinforcementWeight =
    isConcreteFoundation(currentFoundation) &&
    currentFoundation.reinforceDensity
      ? valueRounding(
          (Math.pow(scale, 3) *
            currentFoundation.reinforceDensity *
            currentFoundation.primaryMass) /
            CONCRETE_DENSITY /
            1000,
          10,
        )
      : undefined;

  const scaledPostTensionCableWeight =
    isConcreteFoundation(currentFoundation) && currentFoundation.postTensDensity
      ? valueRounding(
          (Math.pow(scale, 3) *
            currentFoundation.postTensDensity *
            currentFoundation.primaryMass) /
            CONCRETE_DENSITY /
            1000,
          10,
        )
      : undefined;

  const scaledDisplacementVolume = valueRounding(
    Math.pow(scale, 3) * currentFoundation.displacement,
    10,
  );

  const scaledDraft = Math.round(scale * currentFoundation.draft);

  const scaledFootprintLength =
    isSemiCentral(currentFoundation) || isSemiPeripheral(currentFoundation)
      ? Math.round(1.5 * scale * currentFoundation.ccDistance)
      : undefined;

  const scaledFootprintBreath =
    isSemiCentral(currentFoundation) || isSemiPeripheral(currentFoundation)
      ? Math.round(Math.sqrt(3) * scale * currentFoundation.ccDistance)
      : undefined;

  const scaledMainDiameter = isSpar(currentFoundation)
    ? valueRounding(scale * currentFoundation.baseDiameter, 10)
    : undefined;

  return {
    turbineTypeId,
    foundationId: currentFoundation.id,
    scaledConcreteVolume,
    scaledConcreteWeight,
    scaledPrimarySteelWeight,
    scaledSolidBallastWeight,
    scaledLiquidBallastWeight,
    scaledReinforcementWeight,
    scaledPostTensionCableWeight,
    scaledDisplacementVolume,
    scaledDraft,
    scaledFootprintLength,
    scaledFootprintBreath,
    scaledMainDiameter,
  };
};

export const getAllScaledStatisticsForFloatingFoundationsInPark =
  selectorFamily<FloatingFoundationScaledStatistics[], string>({
    key: "getAllScaledStatisticsForFloatingFoundationsInPark",
    get:
      (parkId) =>
      ({ get }) => {
        const turbines = get(getTurbinesSelectorFamily({ parkId }));
        const allFloaters = get(allFloaterTypesSelector);
        const allTurbineTypes = get(allSimpleTurbineTypesSelector);

        const turbinesWithFoundation =
          turbines?.filter(isTurbineFeatureWithFoundation) ?? [];
        const hasFloatingFoundation = get(hasFloatingFoundationSelector);
        const turbinesWithFloatingFoundation = turbinesWithFoundation.filter(
          hasFloatingFoundation,
        );
        const turbineFoundationCombinations =
          turbineTypeAndFloatingFoundationCombinations(
            turbinesWithFloatingFoundation,
            allFloaters,
          );

        return turbineFoundationCombinations.map((turbineAndFoundationIds) => {
          const { turbineTypeId, foundationId } = turbineAndFoundationIds;

          const currentFoundation = allFloaters.find(
            (f) => f.id === foundationId,
          ) as FloaterType;

          const currentTurbineType = allTurbineTypes.find(
            (f) => f.id === turbineTypeId,
          ) as SimpleTurbineType;

          const scale =
            foundationScale({
              foundation: currentFoundation,
              turbine: currentTurbineType,
            }) ?? 1.0;

          return getScaledStatisticsForFloatingFoundation({
            currentFoundation,
            turbineTypeId,
            scale,
          });
        });
      },
  });

export const foundationScale = ({
  turbine,
  foundation,
}: {
  turbine: SimpleTurbineType | undefined;
  foundation: FloaterType | undefined;
}): number | undefined => {
  if (!turbine || !foundation) return;

  const scaledTowerMass =
    (((foundation.towerMass * turbine.diameter) / foundation.rotorDiameter) *
      turbine.hubHeight) /
    foundation.hubHeight;
  const scaledMass = turbine.rnaMass + scaledTowerMass;
  const baseMass = foundation.rnaMass + foundation.towerMass;

  return Math.cbrt(scaledMass / baseMass);
};

export const getScalesForTurbineTypeIdsAndFoundationIds = selectorFamily<
  Record<string, number>,
  { turbineTypeId: string; foundationId: string }[]
>({
  key: "getScalesForTurbineTypeIdsAndFoundationIds",
  get:
    (idList) =>
    ({ get }) => {
      const allTurbineTypes = get(allSimpleTurbineTypesSelector);
      const allFloaters = get(allFloaterTypesSelector);

      const turbineFoundationScaleCombinations = Object.fromEntries(
        idList.map((c) => {
          const { turbineTypeId, foundationId } = c;
          const turbineType = allTurbineTypes.find(
            (tt) => tt.id === turbineTypeId,
          );
          const foundation = allFloaters.find((f) => f.id === foundationId);
          const scale =
            foundationScale({
              foundation: foundation,
              turbine: turbineType,
            }) ?? 1.0;
          return [`${turbineTypeId},${foundationId}`, scale];
        }),
      );

      return turbineFoundationScaleCombinations;
    },
});

export const turbineTypeAndFloatingFoundationCombinations = (
  tempLayout: TurbineFeature[],
  allFloaters: FloaterType[],
): { turbineTypeId: string; foundationId: string }[] => {
  const combinations: { turbineTypeId: string; foundationId: string }[] = [];

  const turbinesWithFloatingFoundation = tempLayout.filter(
    (t) =>
      t.properties.foundationId &&
      allFloaters.find((f) => f.id === t.properties.foundationId),
  );

  const turbineTypesIds = Array.from(
    new Set(
      turbinesWithFloatingFoundation.map(
        (turbine) => turbine.properties.turbineTypeId,
      ),
    ),
  );

  turbineTypesIds.forEach((tti) => {
    turbinesWithFloatingFoundation
      .filter((t) => t.properties.turbineTypeId === tti)
      .forEach((t) => {
        const foundationId = t.properties.foundationId;
        if (
          foundationId &&
          !combinations.find((c) => {
            return c.foundationId === foundationId && c.turbineTypeId === tti;
          })
        ) {
          combinations.push({ turbineTypeId: tti, foundationId });
        }
      });
  });
  return combinations;
};

/**
 * Find the most common foundation type used by the turbines in the park. If no
 * turbines have a foundation, or if the id with the largest number of
 * references is not in `allFoundationTypesSelector`, return `undefined`.
 */
export const getMostCommonFoundationInPark = selectorFamily<
  FoundationType | undefined,
  { parkId: string }
>({
  key: "getMostCommonFoundationInPark",
  get:
    ({ parkId }) =>
    ({ get }) => {
      const turbines = get(getTurbinesSelectorFamily({ parkId }));
      const foundationIdCounts = count(
        turbines.map((t) => t.properties.foundationId).filter(isDefined),
      );
      const max = Math.max(...foundationIdCounts.values());
      const id = [...foundationIdCounts.entries()].find(
        ([, count]) => count === max,
      )?.[0];
      return get(allFoundationTypesSelector).find((f) => f.id === id);
    },
});

export const getMostCommonFoundationIdInZone = selectorFamily<
  string,
  { parkId: string }
>({
  key: "getMostCommonFoundationIdInZone",
  get:
    ({ parkId }) =>
    ({ get }) => {
      const selectedFeatures = get(currentSelectedProjectFeatures);
      const selectedSubAreas = selectedFeatures.filter(isSubArea);
      let turbines = get(getTurbinesSelectorFamily({ parkId }));
      if (selectedSubAreas.length !== 0) {
        const turbineIsInSelectedZone = (t: TurbineFeature) =>
          selectedSubAreas.some((z) => pointInPolygon(t.geometry, z.geometry));

        turbines = turbines.filter(turbineIsInSelectedZone);
      }
      const numberPerFoundationType = count(
        turbines.map((t) => t.properties.foundationId).filter(isDefined),
      );

      let parkFoundationId = defaultFoundations[0].id;
      let maxLength = 0;
      numberPerFoundationType.forEach((value: number, key: string) => {
        if (value > maxLength) {
          maxLength = value;
          parkFoundationId = key;
        }
      });
      return parkFoundationId;
    },
});

export const getInvalidFoundationTypeTurbinesInParkandBranch = selectorFamily<
  TurbineFeature[],
  { parkId: string; branchId: string }
>({
  key: "getInvalidFoundationTypeTurbinesInParkandBranch",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const turbines = get(
        getTurbinesInBranchSelectorFamily({ parkId, branchId }),
      );
      const allFoundationTypes = get(allFoundationTypesSelector);
      const invalidFoundationTypeTurbines = turbines.filter(
        (t) =>
          t.properties.foundationId &&
          allFoundationTypes.every((ft) => ft.id !== t.properties.foundationId),
      );

      return invalidFoundationTypeTurbines;
    },
});

export const showNewFoundationWizardAtom = atom<boolean>({
  key: "showNewFoundationWizard",
  default: false,
});

export const newFoundationNodeId = atom<string>({
  key: "newFoundationNodeId",
  default: undefined,
});

export const projectFoundationsRefreshAtom = atom({
  key: "projectFoundationsRefreshAtom",
  default: 1,
});

export const libraryFoundationsRefreshAtom = atom({
  key: "libraryFoundationsRefreshAtom",
  default: 1,
});
