import { completelyInsideBboxFilter } from "components/MapControls/MultiSelect/utils";
import { useShiftDragMultiSelect } from "hooks/shiftDragMultiSelect";
import { useCallback } from "react";
import { isDefined } from "utils/predicates";
import { dedup } from "utils/utils";

const DragSelect = ({
  map,
  setSelectedIds,
}: {
  map?: mapboxgl.Map;
  setSelectedIds: (value: React.SetStateAction<string[]>) => void;
}) => {
  const _getProjectFeatures = useCallback(
    (bbox: [mapboxgl.Point, mapboxgl.Point]) => {
      if (!map) return [];
      const allLayers = map.getStyle().layers;

      const filteredLayerNames = allLayers.map((layer) => layer.id);

      const renderedProjectFeatures = completelyInsideBboxFilter(
        map,
        bbox,
        map
          .queryRenderedFeatures(bbox, {
            layers: filteredLayerNames.filter((l) => map.getLayer(l)),
          })
          .filter((f) => f.id),
      );

      if (renderedProjectFeatures.length !== 0) {
        return dedup(renderedProjectFeatures, (f) => f.id);
      }

      return [];
    },
    [map],
  );

  const selectCallback = useCallback(
    (
      status: "drag" | "complete",
      bbox: [mapboxgl.Point, mapboxgl.Point],
      resetSelection?: boolean,
    ) => {
      const selectedFeatures = _getProjectFeatures(bbox);

      if (status === "complete") {
        const selectedFeatureIds = selectedFeatures
          .map((f) => f.id)
          .filter(isDefined)
          .map(String);

        setSelectedIds((curr) =>
          dedup([...selectedFeatureIds, ...(resetSelection ? [] : curr)]),
        );
      }
    },
    [setSelectedIds, _getProjectFeatures],
  );
  useShiftDragMultiSelect(map, selectCallback);

  return null;
};

export default DragSelect;
