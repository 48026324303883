import { organisationIdSelector } from "./../state/pathParams";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilCallback } from "recoil";
import {
  branchIdSelector,
  parkIdSelector,
  projectIdSelector,
} from "state/pathParams";

const useNavigateToPark = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToPark = useRecoilCallback(
    ({ snapshot }) =>
      async (parkId: string | undefined, _branchId?: string) => {
        const currentParkId = await snapshot.getPromise(parkIdSelector);
        const currentBranchId = await snapshot.getPromise(branchIdSelector);

        const branchId = _branchId ?? currentBranchId;
        if (parkId === currentParkId && currentBranchId === branchId) {
          return;
        }
        const organisationId = await snapshot.getPromise(
          organisationIdSelector,
        );
        const projectId = await snapshot.getPromise(projectIdSelector);

        if (parkId) {
          const navigateURL = `/design/project/${organisationId}/${projectId}/${branchId}/${parkId}`;
          navigate(
            {
              pathname: navigateURL,
              search: location.search,
              hash: document.location.hash,
            },
            {
              replace: true,
            },
          );
        } else {
          const navigateURL = `/design/project/${organisationId}/${projectId}/${branchId}`;
          navigate(
            {
              pathname: navigateURL,
              search: location.search,
              hash: document.location.hash,
            },
            {
              replace: true,
            },
          );
        }
      },
    [location.search, navigate],
  );

  return { navigateToPark, navigate };
};

export default useNavigateToPark;
