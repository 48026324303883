export default function groupBy<T>(
  array: T[],
  key: (t: T) => string,
): Record<string, T[]> {
  return array.reduce(
    (acc, t) => {
      const groupKey = key(t);

      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }
      acc[groupKey].push(t);

      return acc;
    },
    {} as Record<string, T[]>,
  );
}
