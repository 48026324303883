import { z } from "zod";
import {
  ProjectNodeInformation,
  _ProjectNodeInformation,
} from "../services/customerAPI";
import { ProjectFeature, _ProjectFeature } from "./feature";

// NOTE: These must be kept in sync with the types in the api-v2 repo.
const _CommonMeta = z.object({
  id: z.string(),
  title: z.string(),
  createdAt: z.number(),
  updatedAt: z.number().optional(),
  isArchived: z.boolean().optional(),
});

export const _ProjectMeta = _ProjectNodeInformation;
export type ProjectMeta = ProjectNodeInformation;

export const _BranchMeta = _CommonMeta.extend({
  author: z.string().optional(),
  analysisConfigurationId: z.string().optional(),
  windConfigurationId: z.string().optional(),
  costConfigurationId: z.string().optional(),
  sortOrder: z.number().optional(),
});
export type BranchMeta = z.infer<typeof _BranchMeta>;

export const _BranchDeleteMessage = z.object({
  branchId: z.string(),
});

export const _BranchesSortMessage = z.object({
  sortOrder: z.string().array(),
});

const _BranchMetaUpdate = _BranchMeta.pick({
  title: true,
  analysisConfigurationId: true,
  windConfigurationId: true,
  costConfigurationId: true,
  sortOrder: true,
});
export type BranchMetaUpdate = z.infer<typeof _BranchMetaUpdate>;

export const _ProjectFeatures = z
  .array(z.union([_ProjectFeature, z.unknown()]))
  .transform((a) =>
    a.filter((e): e is ProjectFeature => _ProjectFeature.safeParse(e).success),
  );

export const _BranchFeatures = _ProjectFeatures;

export const _SnapshotMeta = _CommonMeta.extend({
  body: z.string().optional(),
  updatedAt: z.number(),
  branchId: z.string(),
  author: z.string().optional(),
});
export type SnapshotMeta = z.infer<typeof _SnapshotMeta>;

export const _SnapshotDeleteMessage = z.object({
  branchId: z.string(),
  snapshotId: z.string(),
});

const _SnapshotMetaUpdate = _SnapshotMeta.pick({
  title: true,
  body: true,
});
export type SnapshotMetaUpdate = z.infer<typeof _SnapshotMetaUpdate>;

export type SignedUrlResponse = {
  fields: {
    Policy: string;
    ["X-Amz-Algorithm"]: string;
    ["X-Amz-Credential"]: string;
    ["X-Amz-Date"]: string;
    ["X-Amz-Security-Token"]: string;
    ["X-Amz-Signature"]: string;
    bucket: string;
    key: string;
  };
  url: string;
};

export const _BranchCount = z.object({ count: z.number() });

// ------------------ Public --------------------------
export const _PublicProjectBranchMeta = z.object({
  is_public: z.boolean(),
  greeting_title: z.string().optional(),
  greeting_text: z.string().optional(),
});
export type PublicProjectBranchMeta = z.infer<typeof _PublicProjectBranchMeta>;

export const _PublicProjectMeta = z.object({
  greeting_title: z.string().optional(),
  greeting_text: z.string().optional(),
  public_collections: z.string().array().optional(),
  public_collections_toggled_by_default: z.string().array().optional(),
  public_collections_renamed_layers: z
    .record(z.string(), z.string())
    .optional(),
  analysisVisible: z.boolean().optional(),
  startZoomArea: z.number().array().array().optional(),
  projectElementsVisible: z.boolean().optional(),
});
export type PublicProjectMeta = z.infer<typeof _PublicProjectMeta>;

export const _ProjectFollow = z.object({
  nodeId: z.string(),
  follow: z.boolean(),
});
export type ProjectFollow = z.infer<typeof _ProjectFollow>;
