import { MILLION, THOUSAND } from "@constants/financialAnalysis";
import { cableAmountsSelectorFamily } from "components/FinancialAnalysis/Capex/cables/cableAmounts";
import { cableCapexEntriesSelectorFamily } from "components/FinancialAnalysis/Capex/cables/cableCosts";
import {
  Cost,
  GeneralCost,
} from "components/FinancialAnalysis/Capex/capexNumbers";
import { exportCableAmountsSelectorFamily } from "components/FinancialAnalysis/Capex/exportCable/exportCableAmounts";
import { exportCableCostsSelectorFamily } from "components/FinancialAnalysis/Capex/exportCable/exportCableCosts";
import { foundationAmountSelectorFamily } from "components/FinancialAnalysis/Capex/foundations/foundationAmounts";
import { foundationCostsSelector } from "components/FinancialAnalysis/Capex/foundations/foundationCosts";
import { Amount } from "components/FinancialAnalysis/Capex/generalAmounts";
import { mooringAmountSelectorFamily } from "components/FinancialAnalysis/Capex/mooring/mooringAmounts";
import { mooringCostsSelectorFamily } from "components/FinancialAnalysis/Capex/mooring/mooringCosts";
import { otherAmountsSelectorFamily } from "components/FinancialAnalysis/Capex/other/otherAmounts";
import { otherCostsSelectorFamily } from "components/FinancialAnalysis/Capex/other/otherCosts";
import { substationAmountsSelectorFamily } from "components/FinancialAnalysis/Capex/substation/substationAmounts";
import { substationCostsSelectorFamily } from "components/FinancialAnalysis/Capex/substation/substationCosts";
import { turbineAmountSelectorFamily } from "components/FinancialAnalysis/Capex/turbines/turbineAmounts";
import { turbineCapexEntriesSelectorFamily } from "components/FinancialAnalysis/Capex/turbines/turbineCosts";
import {
  ProdId,
  analysisOverrideInputAtomFamily,
  getAEP,
  getBranchId,
  getCapacity,
  getPark,
  getProjectId,
} from "components/ProductionV2/state";
import { atomFamily, selectorFamily } from "recoil";
import { CostConfiguration, CostType } from "services/costService";
import { branchSelectedCostConfigurationAtom } from "state/costConfigurations";
import { CostUnit, OccuranceType, unitToScalingMap } from "types/financial";
import {
  CashFlowAdjustment,
  CashFlowType,
  ComputationResult,
  CostCategory,
  OpexCost,
  ParkFinance,
} from "utils/finances";
import { currentYear, presentValue } from "utils/inflation";
import { isDefined } from "utils/predicates";
import { sendWarning } from "utils/sentry";
import { roundToDecimal, sum } from "utils/utils";

export type FinanceId = string & { readonly __tag: unique symbol } & ProdId;

export type CashFlow = { year: number; value: number }[];

export type FinanceOverrideInput = {
  configuration?: CostConfiguration;
  rasterId: string;
};

export const financeOverrideInputState = atomFamily<
  FinanceOverrideInput,
  FinanceId
>({
  key: "finance-override-input",
});

export const configurationState = selectorFamily<CostConfiguration, FinanceId>({
  key: "finance-branchId",
  get:
    (id: FinanceId) =>
    ({ get }) => {
      const input = get(financeOverrideInputState(id));

      if (input.configuration) return input.configuration;

      const projectId = get(getProjectId(id));
      const branchId = get(getBranchId(id));

      const config = get(
        branchSelectedCostConfigurationAtom({
          projectId,
          branchId,
        }),
      );

      if (!config) {
        throw new Error("Cost configuration not found.");
      }

      return config;
    },
});

export namespace Inputs {
  export const getProjectStart = selectorFamily<number, FinanceId>({
    key: "inputs-project-start",
    get:
      (id: FinanceId) =>
      ({ get }) => {
        const configuration = get(configurationState(id));
        return configuration.lifeCycle.projectStart;
      },
  });

  export const getOperationStart = selectorFamily<number, FinanceId>({
    key: "inputs-operation-start",
    get:
      (id: FinanceId) =>
      ({ get }) => {
        const configuration = get(configurationState(id));
        return configuration.lifeCycle.operationStart;
      },
  });
}

const getCapacityMW = selectorFamily<number, FinanceId>({
  key: "finance-capacity",
  get:
    (id: FinanceId) =>
    ({ get }) => {
      const capacity = get(getCapacity(id));

      if (capacity === undefined) {
        throw new Error("Capacity not defined.");
      }

      return capacity;
    },
});

const getAepMWh = selectorFamily<number, FinanceId>({
  key: "finance-aep-mwh",
  get:
    (id: FinanceId) =>
    ({ get }) => {
      const aep = get(getAEP(id));

      if (aep === undefined) {
        throw new Error("AEP not found.");
      }

      return aep * THOUSAND;
    },
});

const parkFinanceState = selectorFamily<ParkFinance, FinanceId>({
  key: "finance-park-finance",
  get:
    (id: FinanceId) =>
    ({ get }) => {
      const capacityMW = get(getCapacityMW(id));
      const aepMWh = get(getAepMWh(id));
      const config = get(configurationState(id));

      const devexPV = get(DEVEX.totalPresentValueState(id));
      const decomPV = get(DECOM.totalPresentValueState(id));
      const capexPV = get(CAPEX_STATE.getTotalCostPV(id));

      const yearlyCostPV = get(OPEX.opexYearlyPresentValueState(id));

      const guaranteedPricePV = get(IRRState.getGuaranteedPV(id));
      const marketPricePV = get(IRRState.getMarketPV(id));

      const parkFinance = new ParkFinance({
        aepMWh,
        capacityMW,
        capexPV: capexPV,
        devexPV: devexPV,
        opexPV: yearlyCostPV,
        decomPV: decomPV,
        guaranteedPricePV: guaranteedPricePV,
        marketPricePV: marketPricePV,
        costConfig: config,
      });

      return parkFinance;
    },
});

export namespace IRRState {
  export const getIRR = selectorFamily<ComputationResult, FinanceId>({
    key: "irr-result",
    get:
      (id: FinanceId) =>
      ({ get }) => {
        const parkFinance = get(parkFinanceState(id));
        const irr = parkFinance.irr();

        return irr;
      },
  });

  export const getGuaranteedCashFlow = selectorFamily<CashFlowType, FinanceId>({
    key: "irr-guaranteed-cashflow",
    get:
      (id: FinanceId) =>
      ({ get }) => {
        const parkFinance = get(parkFinanceState(id));
        return parkFinance.guaranteedPrice.cashFlow(true);
      },
  });

  export const getMarketCashFlow = selectorFamily<CashFlowType, FinanceId>({
    key: "irr-market-cashflow",
    get:
      (id: FinanceId) =>
      ({ get }) => {
        const parkFinance = get(parkFinanceState(id));
        return parkFinance.marketPrice.cashFlow(true);
      },
  });

  export const getTotalRevenueMillion = selectorFamily<number, FinanceId>({
    key: "irr-total-revenue",
    get:
      (id: FinanceId) =>
      ({ get }) => {
        const parkFinance = get(parkFinanceState(id));
        return roundToDecimal(parkFinance.sum("in") / MILLION, 0);
      },
  });

  export const getTotalCostMillion = selectorFamily<number, FinanceId>({
    key: "irr-total-costs",
    get:
      (id: FinanceId) =>
      ({ get }) => {
        const parkFinance = get(parkFinanceState(id));
        // NOTE: we return as a positive number
        return -roundToDecimal(parkFinance.sum("out") / MILLION, 0);
      },
  });

  export const getMarketPV = selectorFamily<number, FinanceId>({
    key: "market-pv",
    get:
      (id: FinanceId) =>
      ({ get }) => {
        const aep = get(getAEP(id));
        const configuration = get(configurationState(id));

        const { marketPrice } = configuration.cashFlows;

        const { inflationRate, referenceYearRevenue: referenceYear } =
          configuration.inflation;

        const presentYear = currentYear();

        const amountPerUnit = aep ? aep * THOUSAND : 0;

        const pricePV = presentValue({
          amount: marketPrice.cost * amountPerUnit,
          inflationRate,
          referenceYear,
          presentYear,
        });

        return pricePV;
      },
  });

  export const getGuaranteedPV = selectorFamily<number, FinanceId>({
    key: "guaranteed-pv",
    get:
      (id: FinanceId) =>
      ({ get }) => {
        const aep = get(getAEP(id));
        const configuration = get(configurationState(id));

        const { guaranteedPrice } = configuration.cashFlows;

        const { inflationRate, referenceYearRevenue: referenceYear } =
          configuration.inflation;

        const presentYear = currentYear();

        const amountPerUnit = aep ? aep * THOUSAND : 0;

        const pricePV = presentValue({
          amount: guaranteedPrice.cost * amountPerUnit,
          inflationRate,
          referenceYear,
          presentYear,
        });

        return pricePV;
      },
  });
}

export namespace LCoE {
  export namespace Inputs {
    export const getDiscountRate = selectorFamily<number, FinanceId>({
      key: "lcoe-inputs-discount-rate",
      get:
        (id: FinanceId) =>
        ({ get }) => {
          const configuration = get(configurationState(id));

          return configuration.lcoe.discountRate;
        },
    });

    export const getInflationRate = selectorFamily<number, FinanceId>({
      key: "lcoe-inputs-inflation-rate",
      get:
        (id: FinanceId) =>
        ({ get }) => {
          const configuration = get(configurationState(id));

          return configuration.inflation.inflationRate;
        },
    });

    export const getCapexContingency = selectorFamily<number, FinanceId>({
      key: "lcoe-inputs-capex-contingency",
      get:
        (id: FinanceId) =>
        ({ get }) => {
          const configuration = get(configurationState(id));

          return configuration.contingency.capex.value;
        },
    });

    export const getParkLifeTime = selectorFamily<number, FinanceId>({
      key: "lcoe-inputs-park-lifetime",
      get:
        (id: FinanceId) =>
        ({ get }) => {
          const configuration = get(configurationState(id));

          return (
            configuration.lifeCycle.decomissioning -
            configuration.lifeCycle.operationStart
          );
        },
    });
  }
  export namespace Costs {
    export const getDevexNpvMillion = selectorFamily<number, FinanceId>({
      key: "lcoe-costs-devex",
      get:
        (id: FinanceId) =>
        ({ get }) => {
          const parkFinance = get(parkFinanceState(id));

          return roundToDecimal(
            parkFinance.cost(CostCategory.DEVEX).npv() / MILLION,
            0,
          );
        },
    });

    export const getDevexCashFlow = selectorFamily<CashFlow, FinanceId>({
      key: "lcoe-costs-devex-cashflow",
      get:
        (id: FinanceId) =>
        ({ get }) => {
          const parkFinance = get(parkFinanceState(id));
          const filterPositive = true;

          return parkFinance
            .cost(CostCategory.DEVEX)
            .cashFlow(filterPositive)
            .discounted();
        },
    });

    export const getCapexNpvMillion = selectorFamily<number, FinanceId>({
      key: "lcoe-costs-capex",
      get:
        (id: FinanceId) =>
        ({ get }) => {
          const parkFinance = get(parkFinanceState(id));

          return roundToDecimal(
            parkFinance.cost(CostCategory.CAPEX).npv() / MILLION,
            0,
          );
        },
    });

    export const getCapexCashFlow = selectorFamily<CashFlow, FinanceId>({
      key: "lcoe-costs-capex-cashflow",
      get:
        (id: FinanceId) =>
        ({ get }) => {
          const parkFinance = get(parkFinanceState(id));
          const filterPositive = true;

          return parkFinance
            .cost(CostCategory.CAPEX)
            .cashFlow(filterPositive)
            .discounted();
        },
    });

    export const getOpexNpvMillion = selectorFamily<number, FinanceId>({
      key: "lcoe-costs-opex",
      get:
        (id: FinanceId) =>
        ({ get }) => {
          const parkFinance = get(parkFinanceState(id));

          return roundToDecimal(
            parkFinance.cost(CostCategory.OPEX).npv() / MILLION,
            0,
          );
        },
    });

    export const getOpexDistribution = selectorFamily<
      { id: string; name: string; percent: number }[],
      FinanceId
    >({
      key: "lcoe-costs-opex-distribution",
      get:
        (id: FinanceId) =>
        ({ get }) => {
          const parkFinance = get(parkFinanceState(id));
          const filterPositive = true;

          return parkFinance
            .cost(CostCategory.OPEX)
            .cashFlow(filterPositive)
            .distributions();
        },
    });

    export const getOpexCashFlow = selectorFamily<CashFlow, FinanceId>({
      key: "lcoe-costs-opex-cashflow",
      get:
        (id: FinanceId) =>
        ({ get }) => {
          const parkFinance = get(parkFinanceState(id));
          const filterPositive = true;

          return parkFinance
            .cost(CostCategory.OPEX)
            .cashFlow(filterPositive)
            .discounted();
        },
    });

    export const getDecomNpvMillion = selectorFamily<number, FinanceId>({
      key: "lcoe-costs-decom",
      get:
        (id: FinanceId) =>
        ({ get }) => {
          const parkFinance = get(parkFinanceState(id));

          return roundToDecimal(
            parkFinance.cost(CostCategory.DECOM).npv() / MILLION,
            0,
          );
        },
    });

    export const getDecomCashFlow = selectorFamily<CashFlow, FinanceId>({
      key: "lcoe-costs-decom-cashflow",
      get:
        (id: FinanceId) =>
        ({ get }) => {
          const parkFinance = get(parkFinanceState(id));
          const filterPositive = true;

          return parkFinance
            .cost(CostCategory.DECOM)
            .cashFlow(filterPositive)
            .discounted();
        },
    });

    export const getNPV = selectorFamily<number, FinanceId>({
      key: "lcoe-costs-npv",
      get:
        (id: FinanceId) =>
        ({ get }) => {
          const parkFinance = get(parkFinanceState(id));

          const devex = parkFinance.cost(CostCategory.DEVEX).npv();
          const capex = parkFinance.cost(CostCategory.CAPEX).npv();
          const opex = parkFinance.cost(CostCategory.OPEX).npv();
          const decom = parkFinance.cost(CostCategory.DECOM).npv();

          return devex + capex + opex + decom;
        },
    });
  }
  export namespace Production {
    export const getNetAEP = selectorFamily<number, FinanceId>({
      key: "lcoe-production-aep",
      get:
        (id: FinanceId) =>
        ({ get }) => {
          const aepMWh = get(getAepMWh(id));

          return aepMWh / THOUSAND;
        },
    });

    export const getCashFlow = selectorFamily<CashFlow, FinanceId>({
      key: "lcoe-production-cashflow",
      get:
        (id: FinanceId) =>
        ({ get }) => {
          const parkFinance = get(parkFinanceState(id));
          const filterPositive = true;

          const aep = parkFinance.cost(CostCategory.PRODUCTION);
          return aep.cashFlow(filterPositive).discounted();
        },
    });
  }

  export const getLCoE = selectorFamily<number, FinanceId>({
    key: "lcoe-result",
    get:
      (id: FinanceId) =>
      ({ get }) => {
        const parkFinance = get(parkFinanceState(id));
        const devex = parkFinance.cost(CostCategory.DEVEX).npv();
        const capex = parkFinance.cost(CostCategory.CAPEX).npv();
        const opex = parkFinance.cost(CostCategory.OPEX).npv();
        const decom = parkFinance.cost(CostCategory.DECOM).npv();

        const production = parkFinance.cost(CostCategory.PRODUCTION).npv();

        return (devex + capex + opex + decom) / production;
      },
  });
}

export namespace GuaranteedRevenues {
  export const getTotalCostMillion = selectorFamily<
    number,
    { id: FinanceId; adjustment: CashFlowAdjustment }
  >({
    key: "guaranteedrevenues-total-cost",
    get:
      ({ id, adjustment }: { id: FinanceId; adjustment: CashFlowAdjustment }) =>
      ({ get }) => {
        const parkFinance = get(parkFinanceState(id));
        return roundToDecimal(
          parkFinance.cost(CostCategory.GUARANTEED_PRICE).sum(adjustment) /
            MILLION,
          0,
        );
      },
  });

  export const getYears = selectorFamily<
    { startYear: number | undefined; endYear: number | undefined },
    FinanceId
  >({
    key: "guaranteed-revenues-years",
    get:
      (id: FinanceId) =>
      ({ get }) => {
        const parkFinance = get(parkFinanceState(id));
        const years = parkFinance.guaranteedPrice
          .cashFlow(true)
          .raw()
          .map(({ year }) => year);

        return { startYear: years.at(0), endYear: years.at(-1) };
      },
  });
}

export namespace MarketRevenues {
  export const getTotalCostMillion = selectorFamily<
    number,
    { id: FinanceId; adjustment: CashFlowAdjustment }
  >({
    key: "marketrevenues-total-cost",
    get:
      ({ id, adjustment }: { id: FinanceId; adjustment: CashFlowAdjustment }) =>
      ({ get }) => {
        const parkFinance = get(parkFinanceState(id));
        return roundToDecimal(
          parkFinance.cost(CostCategory.MARKET_PRICE).sum(adjustment) / MILLION,
          0,
        );
      },
  });

  export const getYears = selectorFamily<
    { startYear: number | undefined; endYear: number | undefined },
    FinanceId
  >({
    key: "market-revenues-years",
    get:
      (id: FinanceId) =>
      ({ get }) => {
        const parkFinance = get(parkFinanceState(id));
        const years = parkFinance.marketPrice
          .cashFlow(true)
          .raw()
          .map(({ year }) => year);

        return { startYear: years.at(0), endYear: years.at(-1) };
      },
  });
}

export namespace DEVEX {
  export const getTotalCostMillion = selectorFamily<
    number,
    { id: FinanceId; adjustment: CashFlowAdjustment }
  >({
    key: "devex-total-cost",
    get:
      ({ id, adjustment }: { id: FinanceId; adjustment: CashFlowAdjustment }) =>
      ({ get }) => {
        const parkFinance = get(parkFinanceState(id));
        return roundToDecimal(
          parkFinance.cost(CostCategory.DEVEX).sum(adjustment) / MILLION,
          0,
        );
      },
  });

  export const totalPresentValueState = selectorFamily<number, FinanceId>({
    key: "devex-total-pv",
    get:
      (id: FinanceId) =>
      ({ get }) => {
        const capacity = get(getCapacityMW(id));
        const configuration = get(configurationState(id));

        let { cost: devexCost, unit } = configuration.devex;
        if (unit === CostUnit.percent) {
          const capexPV = get(CAPEX_STATE.getTotalCostPV(id));
          devexCost = capexPV * (devexCost / 100);
        }
        const { inflationRate, referenceYearOtherExpenditures: referenceYear } =
          configuration.inflation;

        const presentYear = currentYear();

        const costPV = presentValue({
          amount: devexCost,
          inflationRate,
          referenceYear,
          presentYear,
        });

        if (unit === CostUnit.percent) {
          return costPV;
        } else {
          return costPV * unitToScalingMap[unit] * capacity;
        }
      },
  });

  export const getNPV = selectorFamily<number, FinanceId>({
    key: "devex-npv",
    get:
      (id: FinanceId) =>
      ({ get }) => {
        const parkFinance = get(parkFinanceState(id));
        return parkFinance.cost(CostCategory.DEVEX).npv();
      },
  });

  export const getCashFlow = selectorFamily<CashFlowType, FinanceId>({
    key: "devex-cashflow",
    get:
      (id: FinanceId) =>
      ({ get }) => {
        const parkFinance = get(parkFinanceState(id));
        return parkFinance.devex.cashFlow(true);
      },
  });
}

export namespace OPEX {
  export const getTotalCostMillion = selectorFamily<
    number,
    { id: FinanceId; adjustment: CashFlowAdjustment }
  >({
    key: "opex-total-cost",
    get:
      ({ id, adjustment }: { id: FinanceId; adjustment: CashFlowAdjustment }) =>
      ({ get }) => {
        const parkFinance = get(parkFinanceState(id));
        return roundToDecimal(
          parkFinance.cost(CostCategory.OPEX).sum(adjustment) / MILLION,
          0,
        );
      },
  });

  export const opexCostsState = selectorFamily<
    {
      id: string;
      name: string;
      cost: number;
      occurance: OccuranceType;
      occuranceYear?: number;
    }[],
    FinanceId
  >({
    key: "opex-costs",
    get:
      (id: FinanceId) =>
      ({ get }) => {
        const capacity = get(getCapacityMW(id));
        const aepMWh = get(getAepMWh(id));

        const configuration = get(configurationState(id));
        const { custom } = configuration.opex;

        const amountPerUnit = {
          [CostUnit.thousandEuroPerMWPerYear]: capacity,
          [CostUnit.euroPerMWh]: aepMWh,
        };
        const costs = custom.map(
          ({
            id,
            name,
            cost: costPerAmount,
            unit,
            occurance,
            occuranceYear,
          }) => {
            const cost =
              costPerAmount * amountPerUnit[unit] * unitToScalingMap[unit];
            return { id, name, cost, occurance, occuranceYear };
          },
        );
        return costs;
      },
  });

  export const opexYearlyPresentValueState = selectorFamily<
    OpexCost[],
    FinanceId
  >({
    key: "opex-yearly-pv",
    get:
      (id: FinanceId) =>
      ({ get }) => {
        const costs = get(opexCostsState(id));
        const configuration = get(configurationState(id));
        const presentYear = currentYear();

        const referenceYear =
          configuration.inflation.referenceYearOtherExpenditures;
        const inflationRate = configuration.inflation.inflationRate;

        const costsPV = costs.map(
          ({ cost, occurance, occuranceYear, name, id }) => ({
            costPV: presentValue({
              amount: cost,
              inflationRate,
              referenceYear,
              presentYear,
            }),
            occurance,
            occuranceYear,
            name,
            id,
          }),
        );

        return costsPV;
      },
  });

  export const getCashFlow = selectorFamily<CashFlowType, FinanceId>({
    key: "opex-cashflow",
    get:
      (id: FinanceId) =>
      ({ get }) => {
        const parkFinance = get(parkFinanceState(id));
        return parkFinance.opex.cashFlow(true);
      },
  });
}

export namespace DECOM {
  export const getTotalCostMillion = selectorFamily<
    number,
    { id: FinanceId; adjustment: CashFlowAdjustment }
  >({
    key: "decom-total-cost",
    get:
      ({ id, adjustment }: { id: FinanceId; adjustment: CashFlowAdjustment }) =>
      ({ get }) => {
        const parkFinance = get(parkFinanceState(id));
        return roundToDecimal(
          parkFinance.cost(CostCategory.DECOM).sum(adjustment) / MILLION,
          0,
        );
      },
  });

  export const totalPresentValueState = selectorFamily<number, FinanceId>({
    key: "decom-total-pv",
    get:
      (id: FinanceId) =>
      ({ get }) => {
        const capacity = get(getCapacityMW(id));
        const configuration = get(configurationState(id));

        let { cost: decomCost, unit } = configuration.decom;

        if (unit === CostUnit.percent) {
          const capexPV = get(CAPEX_STATE.getTotalCostPV(id));
          decomCost = capexPV * (decomCost / 100);
        }
        const { inflationRate, referenceYearOtherExpenditures: referenceYear } =
          configuration.inflation;

        const presentYear = currentYear();

        const costPV = presentValue({
          amount: decomCost,
          inflationRate,
          referenceYear,
          presentYear,
        });
        if (unit === CostUnit.percent) {
          return costPV;
        } else {
          return costPV * unitToScalingMap[unit] * capacity;
        }
      },
  });

  export const getCashFlow = selectorFamily<CashFlowType, FinanceId>({
    key: "decom-cashflow",
    get:
      (id: FinanceId) =>
      ({ get }) => {
        const parkFinance = get(parkFinanceState(id));
        return parkFinance.decom.cashFlow(true);
      },
  });
}

export namespace CAPEX_STATE {
  export const getTotalCostMillion = selectorFamily<
    number,
    { id: FinanceId; adjustment: CashFlowAdjustment }
  >({
    key: "capex-total-cost",
    get:
      ({ id, adjustment }: { id: FinanceId; adjustment: CashFlowAdjustment }) =>
      ({ get }) => {
        const parkFinance = get(parkFinanceState(id));
        return roundToDecimal(
          parkFinance.cost(CostCategory.CAPEX).sum(adjustment) / MILLION,
          0,
        );
      },
  });

  export const getTotalCostPV = selectorFamily<number, FinanceId>({
    key: "capex-total-pv",
    get:
      (id: FinanceId) =>
      ({ get }) => {
        const turbine = get(
          getAggregatedCost({
            id,
            type: CostType.Turbine,
          }),
        );
        const cable = get(getAggregatedCost({ id, type: CostType.Cable }));
        const mooring = get(
          getAggregatedCost({
            id,
            type: CostType.Mooring,
          }),
        );
        const substation = get(
          getAggregatedCost({
            id,
            type: CostType.Substation,
          }),
        );
        const exportCable = get(
          getAggregatedCost({
            id,
            type: CostType.ExportCable,
          }),
        );
        const foundation = get(
          getAggregatedCost({
            id,
            type: CostType.Foundation,
          }),
        );
        const other = get(getAggregatedCost({ id, type: CostType.Other }));

        return sum([
          turbine,
          cable,
          mooring,
          substation,
          foundation,
          exportCable,
          other,
        ]);
      },
  });

  export const getAggregatedCost = selectorFamily<
    number,
    { id: FinanceId; type: CostType }
  >({
    key: "capex-costs-sum",
    get:
      ({ id, type }: { id: FinanceId; type: CostType }) =>
      ({ get }) => {
        const costs = get(getCostsOfType({ id, type }));

        return sum(costs.map(({ cost }) => cost));
      },
  });

  export const getAllCosts = selectorFamily<GeneralCost[], FinanceId>({
    key: "capex-all-costs",
    get:
      (id: FinanceId) =>
      ({ get }) => {
        const turbine = get(getCostsOfType({ id, type: CostType.Turbine }));
        const cable = get(getCostsOfType({ id, type: CostType.Cable }));
        const mooring = get(getCostsOfType({ id, type: CostType.Mooring }));
        const substation = get(
          getCostsOfType({ id, type: CostType.Substation }),
        );
        const exportCable = get(
          getCostsOfType({ id, type: CostType.ExportCable }),
        );
        const foundation = get(
          getCostsOfType({ id, type: CostType.Foundation }),
        );
        const other = get(getCostsOfType({ id, type: CostType.Other }));

        return [
          ...turbine,
          ...cable,
          ...mooring,
          ...substation,
          ...exportCable,
          ...foundation,
          ...other,
        ];
      },
  });

  export const getCostsOfType = selectorFamily<
    GeneralCost[],
    { id: FinanceId; type: CostType }
  >({
    key: "capex-costs-of-type",
    get:
      ({ id, type }: { id: FinanceId; type: CostType }) =>
      ({ get }) => {
        const configuration = get(configurationState(id));

        const projectId = get(getProjectId(id));
        const branchId = get(getBranchId(id));
        const park = get(getPark(id));

        const inflationRate = configuration.inflation.inflationRate;
        const referenceYear = configuration.inflation.referenceYearCapex;
        const presentYear = currentYear();

        const input = get(analysisOverrideInputAtomFamily(id));
        let amounts: Amount[];
        let entries: Cost[];

        switch (type) {
          case CostType.Turbine:
            amounts = get(turbineAmountSelectorFamily(id));
            entries = get(
              turbineCapexEntriesSelectorFamily({
                projectId,
                branchId,
                parkId: park.id,
                configurationId: configuration.id,
                turbineTypeOverride: input.turbineTypeOverride,
              }),
            );
            break;
          case CostType.Cable:
            amounts = get(cableAmountsSelectorFamily(id));
            entries = get(
              cableCapexEntriesSelectorFamily({
                projectId,
                branchId,
                parkId: park.id,
                configurationId: configuration.id,
              }),
            );
            break;
          case CostType.Mooring:
            amounts = get(mooringAmountSelectorFamily(id));
            entries = get(
              mooringCostsSelectorFamily({
                projectId,
                configurationId: configuration.id,
              }),
            );
            break;
          case CostType.Substation:
            amounts = get(substationAmountsSelectorFamily(id));
            entries = get(
              substationCostsSelectorFamily({
                projectId,
                branchId,
                parkId: park.id,
                configurationId: configuration.id,
              }),
            );
            break;
          case CostType.ExportCable:
            amounts = get(exportCableAmountsSelectorFamily(id));
            entries = get(
              exportCableCostsSelectorFamily({
                projectId,
                configurationId: configuration.id,
              }),
            );
            break;
          case CostType.Foundation:
            amounts = get(foundationAmountSelectorFamily(id));
            entries = get(
              foundationCostsSelector({
                projectId,
                configurationId: configuration.id,
              }),
            );
            break;
          case CostType.Other:
            amounts = get(otherAmountsSelectorFamily(id));
            entries = get(
              otherCostsSelectorFamily({
                projectId,
                configurationId: configuration.id,
              }),
            );
            break;
        }

        return entries
          .map((entry) => {
            const generalAmount = amounts.find(
              ({ id }) => entry.amountId === id,
            );

            if (generalAmount === undefined) {
              if (entry.category !== CostType.Foundation) {
                // TODO: figure out how to re-enable for foundations without too much noise
                sendWarning(`Missing financial amount: ${entry.amountId}`, {
                  amountId: entry.amountId,
                  unit: entry.unit,
                  category: entry.category,
                });
              }
              return undefined;
            }

            const costPerAmount = presentValue({
              amount: entry.cost,
              inflationRate,
              referenceYear,
              presentYear,
            });

            const cost =
              costPerAmount *
              generalAmount.amount *
              unitToScalingMap[entry.unit];

            return {
              ...entry,
              id: `${generalAmount.id}_${entry.id}`,
              name: `${entry.name}${
                generalAmount.amountName ? ` ${generalAmount.amountName}` : ""
              }`,
              amount: generalAmount.amount,
              amountUnit: generalAmount.unit,
              costPerAmount,
              cost,
              confidenceLevel: entry.confidenceLevel,
            };
          })
          .filter(isDefined);
      },
  });

  export const getCashFlow = selectorFamily<CashFlowType, FinanceId>({
    key: "capex-cashflow",
    get:
      (id: FinanceId) =>
      ({ get }) => {
        const parkFinance = get(parkFinanceState(id));
        return parkFinance.capex.cashFlow(true);
      },
  });
}
