import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import styled from "styled-components";
import PencilIcon from "@icons/24/Pencil.svg";
import TrashcanIcon from "@icons/24/Bin.svg";
import { Input } from "../General/Input";
import {
  displayLabelPropertyName,
  lockedPropertyName,
  opacityPropertyName,
  strokeWidthPropertyName,
  zoomPropertyName,
} from "../../constants/canvas";
import { colors } from "../../styles/colors";
import { mapToRecord } from "../../utils/utils";
import Button from "../General/Button";
import { inReadOnlyModeSelector } from "../../state/project";
import { loggedInUserIsInternalSelector } from "../../state/user";
import { _FeatureParser, ProjectFeature } from "../../types/feature";
import {
  SHOW_ALL_FEATURE_PROPERTIES,
  useUrlFlag,
} from "components/General/FeatureFlag";
import eventEmitter from "utils/eventEmitter";
import { FEATURE_PROPERTY_ADDED_EVENT } from "./events";

const HIDDEN_PROPERTIES = [
  "id",
  "name",
  "type",
  "color",
  "type",
  "noiseSettings",
  "parentIds",
  "turbineTypeId",
  "foundationId",
  "toId",
  "fromId",
  "turbineSettings",
  "Filename",
  "filename",
  "domain",
  opacityPropertyName,
  zoomPropertyName,
  lockedPropertyName,
  strokeWidthPropertyName,
  displayLabelPropertyName,
  "fromSubstationId",
  "toSubstationId",
  "cableTypeId",
  "onshoreCableTypeId",
  "powerLoad",
  "anchor",
  "target",
  "lineType",
  "substationTypeId",
];

const PropertyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  grid-column: span 2;
  justify-self: stretch;
  align-items: center;

  input {
    flex: 1;
  }
  button {
    flex: 0;
  }
`;

const EditPropertyRow = ({
  feature,
  propKey,
  propValue,
  setEditRow,
  updateFeatures,
}: {
  feature: ProjectFeature;
  propKey: string;
  propValue: string;
  setEditRow: React.Dispatch<React.SetStateAction<number | undefined>>;
  updateFeatures: (features: ProjectFeature[] | undefined) => void;
}) => {
  const [newKey, setNewKey] = useState(propKey);
  const [newVal, setNewVal] = useState(propValue);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleDone = useCallback(() => {
    if (newKey === "") {
      setEditRow(undefined);
      return;
    }

    if (HIDDEN_PROPERTIES.includes(newKey)) {
      alert(`"${newKey}" is a reserved property name`);
      setEditRow(undefined);
      return;
    }
    const newProperties: Record<string, unknown> = { ...feature.properties };
    if (newKey !== propKey) {
      delete newProperties[propKey];
    }

    newProperties[newKey] = newVal;

    const updatedFeature = {
      ...feature,
      properties: newProperties,
    };
    updateFeatures([_FeatureParser.parse(updatedFeature)]);
    setEditRow(undefined);
  }, [feature, newKey, newVal, propKey, setEditRow, updateFeatures]);

  return (
    <>
      <PropertyWrapper
        ref={wrapperRef}
        onBlur={(e) => {
          if (e.currentTarget.contains(e.relatedTarget)) {
            return;
          }

          handleDone();
        }}
      >
        <Input
          compact
          autoFocus
          value={newKey}
          onChange={(e) => setNewKey(e.target.value)}
          type="text"
          placeholder="Key"
          style={{ minWidth: 0 }}
          onEnter={handleDone}
          onCancel={() => setEditRow(undefined)}
        />
        <Input
          compact
          value={newVal}
          onChange={(e) => setNewVal(e.target.value)}
          type="text"
          placeholder="Value"
          style={{ minWidth: 0 }}
          onEnter={handleDone}
          onCancel={() => setEditRow(undefined)}
        />
        <Button
          buttonType="text"
          style={{ padding: 0, height: "unset", minWidth: "unset" }}
          icon={<TrashcanIcon />}
          onClick={() => {
            if (
              !window.confirm(
                `Are you sure you want to delete key "${propKey}"?`,
              )
            )
              return;

            const propMap = new Map(Object.entries(feature.properties));
            propMap.delete(propKey);
            const updatedFeature = {
              ...feature,
              properties: mapToRecord(propMap),
            };
            updateFeatures([_FeatureParser.parse(updatedFeature)]);
            setEditRow(undefined);
          }}
        />
      </PropertyWrapper>
      <span style={{ gridColumn: "span 2" }} />
    </>
  );
};

const KeyWithEditPencil = styled.p`
  word-break: break-all;

  svg {
    display: none;
    cursor: pointer;
    margin-left: 1rem;
    width: 1.3rem;
    height: 1.3rem;
  }
`;

const propertyDebugStyle = {
  fontStyle: "italic",
  color: colors.primaryHover,
};

const FeatureProperties = ({
  feature,
  updateFeatures,
}: {
  feature: ProjectFeature;
  updateFeatures: (features: ProjectFeature[] | undefined) => void;
}) => {
  const inReadOnlyMode = useRecoilValue(inReadOnlyModeSelector);
  const featureFlagActive = useUrlFlag(SHOW_ALL_FEATURE_PROPERTIES);
  const [editRow, setEditRow] = useState<number | undefined>();
  const isInternalUserLoadable = useRecoilValueLoadable(
    loggedInUserIsInternalSelector,
  );
  const isLocalHost = window.location.host === "localhost:3000";
  const showAllFeatureProps =
    (isInternalUserLoadable.state === "hasValue" &&
      isInternalUserLoadable.contents &&
      isLocalHost) ||
    featureFlagActive;

  const filteredProperties = useMemo<Record<string, string>>(
    () =>
      Object.fromEntries(
        Object.entries(feature.properties).filter(
          ([key]) => !HIDDEN_PROPERTIES.includes(key) || showAllFeatureProps,
        ),
        // Todo: Fix types?
      ) as Record<string, string>,
    [feature.properties, showAllFeatureProps],
  );

  const filteredEntries = useMemo(
    () => Object.entries(filteredProperties),
    [filteredProperties],
  );

  useEffect(() => {
    if (showAllFeatureProps) {
      console.log(feature);
    }
  }, [showAllFeatureProps, feature]);

  // Edit newly added row
  useEffect(() => {
    const cb = () => {
      setEditRow(filteredEntries.length);
    };
    eventEmitter.on(FEATURE_PROPERTY_ADDED_EVENT, cb);
    return () => {
      eventEmitter.off(FEATURE_PROPERTY_ADDED_EVENT, cb);
    };
  }, [filteredEntries.length]);

  return (
    <>
      {filteredEntries.map(([key, value], i) => {
        const shouldBeFiltered = HIDDEN_PROPERTIES.includes(key);
        return i === editRow ? (
          <EditPropertyRow
            key={key}
            feature={feature}
            setEditRow={setEditRow}
            propKey={key}
            propValue={value}
            updateFeatures={updateFeatures}
          />
        ) : (
          <div key={key}>
            <KeyWithEditPencil
              style={shouldBeFiltered ? propertyDebugStyle : undefined}
            >
              {key}
              {!inReadOnlyMode && <PencilIcon onClick={() => setEditRow(i)} />}
            </KeyWithEditPencil>
            <p
              style={{
                wordBreak: "break-all",
                ...(shouldBeFiltered && propertyDebugStyle),
              }}
            >{`${value}`}</p>
          </div>
        );
      })}
    </>
  );
};

export default FeatureProperties;
