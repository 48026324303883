import { TurbineFeature } from "../../../../../types/feature";
import { fastMax, fastMin } from "../../../../../utils/utils";
import { Raster } from "../../../../../types/raster";
import { MonopileDesign } from "./state";

export type MonopileStats = {
  totalMonopileMass: number;
  monopileDesigns: MonopileDesign[];
  soilCoeffSubReact: number;
};

export const useGetTurbineDepths = ({
  turbineFeatures,
  raster,
}: {
  turbineFeatures: TurbineFeature[];
  raster: Raster;
}): { maxDepth: number; minDepth: number } => {
  const depths = turbineFeatures
    .map(
      ({
        geometry: {
          coordinates: [lat, lng],
        },
      }) => -raster.latLngToValue(lat, lng),
    )
    .filter((depth) => depth > 0);

  const maxTurbineDepth = depths.length ? fastMax(depths) : 0;
  const minTurbineDepth = depths.length ? fastMin(depths) : 0;

  return {
    maxDepth: maxTurbineDepth,
    minDepth: minTurbineDepth,
  };
};
