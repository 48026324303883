import {
  DEFAULT_IN_FOCUS_OPACITY,
  DEFAULT_NOT_IN_FOCUS_OPACITY,
  DEFAULT_SELECTED_OPACITY,
  lockedPropertyName,
} from "@constants/canvas";
import { useEffect } from "react";
import { colors } from "styles/colors";
import { MooringLineFeature } from "types/feature";
import { safeRemoveLayer } from "utils/map";
import {
  MIN_MOORING_VISIBLE_ZOOM,
  editmodePropertyName,
  ghostPropertyName,
} from "./constants";
import {
  getHiddenLargeLineClickLayer,
  addLayer,
  removeCodepointsFromFeatures,
} from "./utils";
import { mooringLineSourceId, mooringLineLayerId } from "./constants";

const mooringLinePaint: mapboxgl.LinePaint = {
  "line-color": [
    "case",
    ["==", ["get", editmodePropertyName], true],
    colors.mooringLinePreview,
    ["!=", ["feature-state", "borderColor"], null],
    ["feature-state", "borderColor"],
    colors.mooringLine,
  ],
  "line-width": [
    "case",
    ["==", ["get", editmodePropertyName], true],
    4,
    ["==", ["get", lockedPropertyName], true],
    2,
    ["!=", ["feature-state", "borderColor"], null],
    4,
    [
      "boolean",
      ["feature-state", "hover"],
      ["feature-state", "selected"],
      false,
    ],
    4,
    2,
  ],
  "line-dasharray": [
    "case",
    ["==", ["get", editmodePropertyName], true],
    ["literal", [1.25, 2]],
    ["literal", [1, 0]],
  ],
  "line-opacity": [
    "case",
    ["==", ["get", editmodePropertyName], true],
    1.0,
    ["==", ["get", ghostPropertyName], true],
    0.1,
    [
      "all",
      [
        "boolean",
        ["feature-state", "hover"],
        ["feature-state", "selected"],
        false,
      ],
      ["!=", ["get", lockedPropertyName], true],
    ],
    DEFAULT_SELECTED_OPACITY,
    ["boolean", ["feature-state", "inFocus"], false],
    DEFAULT_IN_FOCUS_OPACITY,
    DEFAULT_NOT_IN_FOCUS_OPACITY,
  ],
};

const hiddenClickLayer = getHiddenLargeLineClickLayer(
  mooringLineLayerId,
  mooringLineSourceId,
);

export const RenderMooringLines = ({
  mooringLines,
  map,
}: {
  mooringLines: MooringLineFeature[];
  map: mapboxgl.Map;
}) => {
  useEffect(() => {
    map.addSource(mooringLineSourceId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    return () => {
      safeRemoveLayer(map, mooringLineLayerId);
      safeRemoveLayer(map, hiddenClickLayer.id);
      map.removeSource(mooringLineSourceId);
    };
  }, [map]);

  useEffect(() => {
    addLayer(map, {
      id: mooringLineLayerId,
      type: "line",
      source: mooringLineSourceId,
      paint: mooringLinePaint,
      minzoom: MIN_MOORING_VISIBLE_ZOOM,
    });
    addLayer(map, hiddenClickLayer);
  }, [map]);

  useEffect(() => {
    const source = map.getSource(mooringLineSourceId);
    if (source?.type !== "geojson") return;
    source.setData({
      type: "FeatureCollection",
      features: removeCodepointsFromFeatures(mooringLines),
    });
  }, [map, mooringLines]);

  return null;
};
