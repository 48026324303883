import { selectorFamily } from "recoil";
import { pointInPolygon } from "../utils/geometry";
import { isSubArea } from "../utils/predicates";
import {
  getSurroundingTurbineFeaturesInBranchSelector,
  getTurbinesSelectorFamily,
} from "./layout";
import { TurbineFeature } from "../types/feature";
import { currentSelectedProjectFeatures } from "./selection";

export const getSurroundingTurbineFeaturesWithZonesSelector = selectorFamily<
  TurbineFeature[],
  { parkId?: string; branchId?: string; maxDistance?: number }
>({
  key: "getSurroundingLayoutFeaturesSelector",
  get:
    ({ parkId, branchId, maxDistance }) =>
    async ({ get }) => {
      if (!parkId || !branchId || !maxDistance) return [];

      const closeTurbines = get(
        getSurroundingTurbineFeaturesInBranchSelector({
          parkId,
          branchId,
          maxDistance,
        }),
      );

      const selectedFeatures = get(currentSelectedProjectFeatures);
      const selectedSubAreas = selectedFeatures.filter(isSubArea);
      const selectedSubAreaIds = selectedSubAreas.map((f) => f.id);
      if (selectedSubAreaIds.length !== 0) {
        const layout = get(getTurbinesSelectorFamily({ parkId }));
        const turbineFeaturesOutsideSubAreas = layout.filter(
          (feature) =>
            !selectedSubAreas.some((e) =>
              pointInPolygon(feature.geometry, e.geometry),
            ),
        );
        return [...closeTurbines, ...turbineFeaturesOutsideSubAreas];
      }

      return closeTurbines;
    },
});
