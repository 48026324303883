import { atom } from "recoil";
import { UploadFileType } from "./components/NewUploadTab/types";

export const hideUploadModalAtom = atom<boolean>({
  key: "hideUploadModalAtom",
  default: false,
});

export const selectedUploadFileTypeAtom = atom<UploadFileType | undefined>({
  key: "selectedUploadFileTypeAtom",
  default: undefined,
});

export const droppedUploadFilesAtom = atom<File[] | undefined>({
  key: "droppedUploadFilesAtom",
  default: undefined,
});
