import { useRecoilCallback } from "recoil";

import {
  addOrUpdateDataPackageResourceOnNode,
  deleteDataPackageResourceOnNode,
  getDataPackageResourcesOnNode,
} from "components/Organisation/Library/service";
import {
  dataPackageResourceWithAccessOnNodeState,
  nodesWithAccessToDataPackage,
  dataPackageResourceWithAccessOnNodeStateRefreshAtom,
} from "components/Organisation/Library/state";
import { useTypedPath } from "state/pathParams";
import { DataLibraryPackage } from "components/Organisation/Library/dataLibrary/types";
import { getDataLayersUsage } from "components/Organisation/Library/dataLibrary/state";

const useNodeDataPackageResourcesCrud = () => {
  const { organisationId } = useTypedPath("organisationId");
  const addOrUpdate = useRecoilCallback(
    ({ snapshot, set, refresh }) =>
      async (nodeId: string, dataPackage: DataLibraryPackage) => {
        const fallback = await snapshot.getPromise(
          dataPackageResourceWithAccessOnNodeState({ nodeId }),
        );
        return addOrUpdateDataPackageResourceOnNode(nodeId, dataPackage.id)
          .then(async () => {
            const updatedConfigs = await getDataPackageResourcesOnNode(nodeId);
            set(
              dataPackageResourceWithAccessOnNodeState({ nodeId }),
              updatedConfigs,
            );
            refresh(
              nodesWithAccessToDataPackage({
                organisationId,
                resourceId: dataPackage.id,
              }),
            );
            set(
              dataPackageResourceWithAccessOnNodeStateRefreshAtom,
              (cur) => cur + 1,
            );
          })
          .catch((e) => {
            set(dataPackageResourceWithAccessOnNodeState({ nodeId }), fallback);
            throw e;
          });
      },
    [organisationId],
  );

  const remove = useRecoilCallback(
    ({ snapshot, set, refresh }) =>
      async (nodeId: string, resourceId: string) => {
        const fallback = await snapshot.getPromise(
          dataPackageResourceWithAccessOnNodeState({ nodeId }),
        );
        const currentConfig = fallback.find((c) => c.config.id === resourceId);
        if (currentConfig) {
          currentConfig?.config.layerIds;
          const usage = await snapshot.getPromise(
            getDataLayersUsage({
              nodeId,
              layerIds: currentConfig.config.layerIds,
            }),
          );

          const totalUsage = Object.values(usage).flat();
          if (
            totalUsage.length !== 0 &&
            !window.confirm(
              `Data package configuration is currently being used in ${totalUsage.length} projects, are you sure you want to remove access to it?`,
            )
          ) {
            return;
          }
        }

        return deleteDataPackageResourceOnNode(nodeId, resourceId)
          .then(() => {
            set(dataPackageResourceWithAccessOnNodeState({ nodeId }), (cur) =>
              cur.filter((c) => c.config.id !== resourceId),
            );
            refresh(
              nodesWithAccessToDataPackage({ organisationId, resourceId }),
            );
            set(
              dataPackageResourceWithAccessOnNodeStateRefreshAtom,
              (cur) => cur + 1,
            );
          })
          .catch((e) => {
            set(dataPackageResourceWithAccessOnNodeState({ nodeId }), fallback);
            throw e;
          });
      },
    [organisationId],
  );

  return { addOrUpdate, remove };
};

export default useNodeDataPackageResourcesCrud;
