import styled from "styled-components";
import { colors } from "../../styles/colors";
import { spaceDecent, spaceMedium } from "../../styles/space";
import { typography } from "styles/typography";
import { Article, HelpLink } from "components/HelpTooltip/HelpTooltip";
import { ReferenceType } from "@floating-ui/react";
import { MouseEventHandler } from "react";

export const InputTitle = styled.div<{ disabled?: boolean }>`
  ${typography.caption}
  ${(p) =>
    p.disabled
      ? `color: ${colors.textDisabled}`
      : `color: ${colors.textSecondary};`}
`;

export const InputTitleWrapper = styled.div`
  display: flex;
  gap: ${spaceMedium};
  align-items: center;
`;

export const OverlineText = styled.div`
  ${typography.sub3}
  color: ${colors.textSecondary};
  padding: ${spaceMedium} 0;
`;

export const OverlineTextWrapper = styled.div`
  display: flex;
  gap: ${spaceMedium};
  align-items: center;
  justify-content: space-between;
`;

export const Subtitle = styled.div`
  ${typography.sub1}
  color: ${colors.textPrimary};
  display: flex;
  align-items: center;
  gap: ${spaceMedium};
  white-space: nowrap;
  svg {
    height: 1.4rem;
    width: 1.4rem;
  }
`;

const Line = styled.div`
  margin: 2rem 0;
  border-bottom: 1px solid ${colors.grey200};
  width: 100%;
`;

const TextWithLineWrapper = styled.div`
  display: flex;
  gap: ${spaceDecent};
  padding-top: ${spaceMedium};
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${colors.borderSubtle};
`;

export const ResultValue = styled.div`
  ${typography.caption}
  strong {
    ${typography.sub2}
  }
`;

export function SubtitleWithLine({
  text,
  textIcon,
  article,
  style,
  iconButton,
  expandButton,
  innerRef,
  onClick,
}: {
  text: string;
  textIcon?: React.ReactNode;
  article?: Article | number;
  style?: React.CSSProperties;
  iconButton?: React.ReactNode;
  expandButton?: React.ReactNode;
  innerRef?: ((node: ReferenceType | null) => void) &
    ((node: ReferenceType | null) => void);
  onClick?: MouseEventHandler<HTMLDivElement>;
}) {
  return (
    <TextWithLineWrapper style={style} onClick={onClick}>
      <div style={{ display: "flex", gap: "0.4rem" }} ref={innerRef}>
        <Subtitle>
          {textIcon}
          {text}
        </Subtitle>
        {article && <HelpLink article={article} />}
      </div>
      <Line />
      {iconButton}
      {expandButton}
    </TextWithLineWrapper>
  );
}
