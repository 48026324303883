/// <reference types="vite-plugin-svgr/client" />
import { useRecoilValue } from "recoil";

import {
  localCostConfigurationAtom,
  useLocalCostConfigurationCrud,
} from "../state";
import { useRef } from "react";
import { IconREMSize, typography } from "../../../../styles/typography";
import Button from "../../../General/Button";
import { v4 as uuid } from "uuid";
import AddIcon from "../../../../icons/24/Add.svg?react";
import OpexRow from "./OpexRow";
import { CostUnit, OccuranceType } from "../../../../types/financial";
import {
  CostDivider,
  DividerWrapper,
  Header,
  Label,
  TableWrapper,
} from "../styles";
import { InputDimensioned, InputNumber } from "../../../General/Input";
import { inReadOnlyModeSelector } from "../../../../state/project";
import { Column, Row } from "../../../General/Layout";
import { roundToDecimal } from "utils/utils";
import { between } from "utils/validations";
import { CostTopRow } from "./CostTopRow";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import styled from "styled-components";
import Toggle, { ToggleSize } from "components/General/Toggle";
import { DefaultCosts } from "../constants";
import { spaceMedium } from "styles/space";
import { colors } from "styles/colors";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ToggleWrapper = styled.div`
  ${typography.caption}
  color: ${colors.textPrimary};
  padding: 0 2rem;
  display: flex;
  gap: ${spaceMedium};
  align-items: center;
`;

export const OtherCosts = ({ isReadOnly }: { isReadOnly?: boolean }) => {
  const inReadOnlyMode = useRecoilValue(inReadOnlyModeSelector) || isReadOnly;

  const {
    inflation: referenceYears,
    devex,
    decom,
    opex,
  } = useRecoilValue(localCostConfigurationAtom);
  const { updateDEVEX, updateDECOM, updateOPEX, updateInflation } =
    useLocalCostConfigurationCrud();

  const recentlyAddedId = useRef<string>();

  const handleAddCost = () => {
    const newId = uuid();
    recentlyAddedId.current = newId;
    updateOPEX({
      id: newId,
      name: "Untitled",
      unit: CostUnit.thousandEuroPerMWPerYear,
      cost: 0,
      occurance: OccuranceType.Yearly,
    });
  };

  return (
    <TableWrapper>
      <Row>
        <Label style={{ display: "flex", flexDirection: "row" }}>
          <InputNumber
            compact
            value={referenceYears.referenceYearOtherExpenditures}
            style={{ width: "6rem" }}
            validate={between(2010, 2050)}
            validationMessage={"Expected to be in the range 2010 to 2050"}
            disabled={isReadOnly}
            onChange={(year) => {
              const referenceYearOtherExpenditures = Math.round(year);
              updateInflation({
                referenceYearOtherExpenditures,
              });
            }}
          />
          <Row style={{ whiteSpace: "nowrap", alignItems: "center" }}>
            Reference year
            <HelpTooltip
              size={10}
              text="Numbers in this pane will be inflated / deflated with respect to this year."
            />
          </Row>
        </Label>
      </Row>

      <Column style={{ gap: "1.2rem", paddingTop: "3.2rem" }}>
        <Header>DEVEX</Header>
        <Row>
          <Label>
            <InputDimensioned
              disabled={inReadOnlyMode}
              value={devex.cost}
              validate={(value) => value >= 0}
              validationMessage={"Must be greater than or equal to 0"}
              onChange={(cost) => updateDEVEX({ cost })}
              unit={devex.unit}
            />
          </Label>
          <ToggleWrapper>
            <Toggle
              disabled={inReadOnlyMode}
              size={ToggleSize.SMALL}
              checked={devex.unit === CostUnit.percent}
              onChange={() =>
                updateDEVEX(
                  devex.unit !== CostUnit.percent
                    ? {
                        cost: DefaultCosts.devex[CostUnit.percent],
                        unit: CostUnit.percent,
                      }
                    : {
                        cost: DefaultCosts.devex[CostUnit.thousandEuroPerMW],
                        unit: CostUnit.thousandEuroPerMW,
                      },
                )
              }
            />
            Set to % of CAPEX
            <HelpTooltip
              text={`DEVEX as a percentage of CAPEX in the reference year (${referenceYears.referenceYearOtherExpenditures})`}
              size={10}
            ></HelpTooltip>
          </ToggleWrapper>
        </Row>
      </Column>
      <Column style={{ gap: "1.2rem", paddingTop: "3.2rem" }}>
        <Header>DECOM</Header>
        <Row>
          <Label>
            <InputDimensioned
              disabled={inReadOnlyMode}
              value={roundToDecimal(decom.cost, 1)}
              validate={(value) => value >= 0}
              validationMessage={"Must be greater than or equal to 0"}
              onChange={(cost) => updateDECOM({ cost })}
              unit={decom.unit}
            />
          </Label>
          <ToggleWrapper>
            <Toggle
              disabled={inReadOnlyMode}
              size={ToggleSize.SMALL}
              checked={decom.unit === CostUnit.percent}
              onChange={() =>
                updateDECOM(
                  decom.unit !== CostUnit.percent
                    ? {
                        cost: DefaultCosts.decom[CostUnit.percent],
                        unit: CostUnit.percent,
                      }
                    : {
                        cost: DefaultCosts.decom[CostUnit.thousandEuroPerMW],
                        unit: CostUnit.thousandEuroPerMW,
                      },
                )
              }
            />
            Set to % of CAPEX
            <HelpTooltip
              text={`DEVEX as a percentage of CAPEX in the reference year (${referenceYears.referenceYearOtherExpenditures})`}
              size={10}
            ></HelpTooltip>
          </ToggleWrapper>
        </Row>
      </Column>

      <Column style={{ gap: 0, paddingTop: "3.2rem" }}>
        <Header>OPEX</Header>
        <CostTopRow />
        {opex.custom.map((entry) => {
          return (
            <Wrapper key={entry.id}>
              <OpexRow
                key={entry.id}
                entry={entry}
                autoFocus={recentlyAddedId.current === entry.id}
                deletable={opex.custom.length > 1}
                isReadOnly={isReadOnly}
              />
              <DividerWrapper>
                <CostDivider />
              </DividerWrapper>
            </Wrapper>
          );
        })}

        <Row style={{ justifyContent: "flex-start" }}>
          <Button
            style={{ padding: 0 }}
            icon={
              <IconREMSize width={1.2} height={1.2}>
                <AddIcon />
              </IconREMSize>
            }
            buttonType="text"
            text="Add cost"
            onClick={handleAddCost}
            disabled={isReadOnly}
          />
        </Row>
      </Column>
    </TableWrapper>
  );
};
