import { atomFamily } from "recoil";

type OrganisationRightSideModalContent = {
  type: "group" | "user" | "project" | "resource" | "empty";
  id?: string;
  meta?: Record<string, unknown>;
};

export const organisationRightSideModal = atomFamily<
  undefined | OrganisationRightSideModalContent,
  string
>({
  key: "organisationRightSideModal",
  default: undefined,
});
