import * as THREE from "three";

/**
 * Clean up the memory of the object.
 *
 * ### Example
 *
 * ```ts
 * this.scene?.traverse((child) => {
 *  dispose(child);
 * });
 * ```
 */
export const dispose = (obj: THREE.Object3D) => {
  obj.traverse((child) => {
    if (child instanceof THREE.Mesh) {
      child.geometry.dispose();
      child.material.dispose();
    }
    if (child instanceof THREE.Material) child.dispose();
  });
};
