import { MultiPolygon, Point } from "geojson";
import { useCallback, useMemo } from "react";
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import {
  getDivisionFeaturesSelectorFamily,
  makeExclusionDivisonToExclusionDivisionPolygon,
} from "../../state/division";
import { DivisionFeature, ProjectFeature } from "../../types/feature";
import { SubAreaFeature } from "../../types/feature";
import {
  getAnchorsSelectorFamily,
  getMooringLinesSelector,
} from "../../state/layout";
import { getSurroundingTurbineFeaturesWithZonesSelector } from "../../state/layoutUtils";
import { useTypedPath } from "../../state/pathParams";
import {
  allSimpleTurbineTypesSelector,
  currentTurbineIdAtom,
  previewTurbinesState,
} from "../../state/turbines";
import { ParkFeature } from "../../types/feature";
import {
  DEFAULT_TURBINES,
  GenerationMethodAndParameters,
  OptimizeParameters,
  SimpleTurbineType,
} from "../../types/turbines";
import { pointInPolygon } from "../../utils/geometry";
import { featureIsLocked, isPolygonFeature } from "../../utils/predicates";
import { fastMax, partition } from "../../utils/utils";
import { projectFeaturesSelector } from "../ProjectElements/state";
import { useProjectElementsCrud } from "../ProjectElements/useProjectElementsCrud";
import { getTurbinesSelectorFamily } from "./../../state/layout";
import { TurbineFeature } from "../../types/feature";
import { edgeGeneration, regularGeneration } from "./generateLayout";
import { useOptimizationCrud } from "./useOptimizationCrud";
import { getCableIdsInPolygon, point2TurbineFeature } from "./utils";
import { loggedInUserIsInternalSelector } from "../../state/user";
import * as Sentry from "@sentry/react";
import { AIR_DENSITY } from "../../constants/metocean";
import { exclusionDomainUnpack } from "../../types/feature";
import { WindSourceConfiguration } from "services/windSourceConfigurationService";
import { MooringParameters } from "components/GenerateFoundationsAndAnchors/types";
import {
  makeAnchorPositions,
  mooringIntersectsPolygon,
} from "components/GenerateFoundationsAndAnchors/generate";
import { Raster } from "types/raster";
import { WakeModel } from "services/configurationService";

/**
 * Hooks related to optimization.
 */
export function useOptimizeTurbines(
  park: ParkFeature,
  id: string,
  params: OptimizeParameters,
) {
  const { projectId, branchId } = useTypedPath("projectId", "branchId");
  const isInternal = useRecoilValue(loggedInUserIsInternalSelector);

  const { subAreas, exclusionZones: exlusionZonesMultiPolygon } =
    useRecoilValue(getDivisionFeaturesSelectorFamily({ parkId: park.id }));

  const exclusionZones = useMemo(
    () =>
      makeExclusionDivisonToExclusionDivisionPolygon(exlusionZonesMultiPolygon),
    [exlusionZonesMultiPolygon],
  );

  const zone = useMemo(
    () =>
      id === park.id
        ? park
        : (subAreas.find((f) => id === f.id)! as DivisionFeature),
    [id, subAreas, park],
  );

  const turbineId = useRecoilValue(currentTurbineIdAtom({ projectId }));
  const allTurbines = useRecoilValue(allSimpleTurbineTypesSelector);

  const otherTurbines = useRecoilValue(
    getSurroundingTurbineFeaturesWithZonesSelector({
      parkId: zone.id,
      branchId,
      maxDistance: 20,
    }),
  );
  const refLon = isPolygonFeature(zone)
    ? zone.geometry.coordinates[0][0][0]
    : (zone as ProjectFeature<MultiPolygon>).geometry.coordinates[0][0][0][0];
  const refLat = isPolygonFeature(zone)
    ? zone.geometry.coordinates[0][0][1]
    : (zone as ProjectFeature<MultiPolygon>).geometry.coordinates[0][0][0][1];
  const neighbourTurbines = useMemo(() => {
    return otherTurbines.map((f) => {
      return {
        lon: f.geometry.coordinates[0],
        lat: f.geometry.coordinates[1],
        type: f.properties.turbineTypeId,
      };
    });
  }, [otherTurbines]);

  const { create } = useOptimizationCrud();

  return useCallback(
    (windConfiguration: WindSourceConfiguration | undefined) => {
      if (!projectId) return;

      const turbineType = allTurbines.find(
        (t) => t.id === turbineId,
      ) as SimpleTurbineType;

      const polygon = zone.geometry.coordinates[0].map(
        (c) => [c[0], c[1]] as [number, number],
      );
      const exclusionPolygons = exclusionZones.map((f) =>
        f.geometry.coordinates[0].map((c) => [c[0], c[1]] as [number, number]),
      );
      const wakeSettings = {
        wakeModel: params.wakeModel as WakeModel,
        turbulenceIntensity: 0.1,
        density: AIR_DENSITY,
        wakeExpansionFactor: 0.05,
        precision: "fast" as const,
        blockage: false,
        neighbourWake: params.includeNeighbours,
        turboparkWakeExpansionCalibration: 0.04,
        neighbourWakeMaxDistance: 20,
      };

      return create({
        ids: {
          nodeId: projectId,
          branchId,
          zoneId: zone.id,
        },
        args: {
          parkPolygon: polygon,
          exclusionPolygons,
          turbineId: turbineType.id,
          turbineTypes: Object.fromEntries(
            DEFAULT_TURBINES.map((t) => [t.id, t]),
          ),
          windConfiguration,
          wakeSettings,
          neighbourTurbines: params.includeNeighbours ? neighbourTurbines : [],
          internal: isInternal,
          lonlats: true,
          refLonLat: { lon: refLon, lat: refLat },
          ...params,
        },
      });
    },
    [
      allTurbines,
      branchId,
      create,
      projectId,
      exclusionZones,
      isInternal,
      params,
      neighbourTurbines,
      refLat,
      refLon,
      turbineId,
      zone,
    ],
  );
}

export const setTurbineNames = (ts: TurbineFeature[], n0: number = 1) => {
  ts.sort((a, b) => {
    let cmp = b.geometry.coordinates[1] - a.geometry.coordinates[1];
    if (Math.abs(cmp) < 1e-4)
      return a.geometry.coordinates[0] - b.geometry.coordinates[0];
    return cmp;
  });
  ts.forEach((t, i) => {
    t.properties.name = `#${n0 + i}`;
  });
};

export const useTurbineGeneration = (
  park: ParkFeature,
  selectedZone: ParkFeature | SubAreaFeature,
  currentTurbineType: SimpleTurbineType,
) => {
  const turbinesInPark = useRecoilValue(
    getTurbinesSelectorFamily({ parkId: park.id }),
  );

  const { exclusionZones } = useRecoilValue(
    getDivisionFeaturesSelectorFamily({ parkId: park.id }),
  );

  const { update: updateFeatures } = useProjectElementsCrud();
  const setPreviewState = useSetRecoilState(previewTurbinesState);

  const computeTurbines = useCallback(
    (
      parameters: GenerationMethodAndParameters,
      mooringParams?: MooringParameters & {
        raster: Raster;
      },
    ) => {
      return Sentry.startSpan({ name: "Generate turbines" }, () => {
        const anchorExclZones = exclusionZones.filter(
          (e) => exclusionDomainUnpack(e.properties.domain).anchor,
        );

        const turbineExclZones = exclusionZones.filter(
          (e) => exclusionDomainUnpack(e.properties.domain).turbine,
        );
        const pointIsOutsideExclusionZones = (pt: Point): boolean =>
          // NOTE: [].some() is false, so without any exclusion zones this is always true.
          !turbineExclZones.some((zone) => pointInPolygon(pt, zone.geometry));

        const turbineCanBePlaced = (pt: Point): boolean => {
          if (!mooringParams) return pointIsOutsideExclusionZones(pt);
          const turbineIllegal = !pointIsOutsideExclusionZones(pt);
          if (turbineIllegal) return false;

          let usedDistance = mooringParams.distance;
          if (mooringParams.distanceMode === "depth") {
            const depth = mooringParams.raster.latLngToValue(
              pt.coordinates[0],
              pt.coordinates[1],
            );
            if (depth !== undefined) usedDistance *= -depth / 1000;
          }

          const anchorPositions = makeAnchorPositions(
            { type: "Feature", geometry: pt, properties: {} },
            mooringParams.numberOfAnchors,
            usedDistance,
            mooringParams.bearing,
            mooringParams.doubleAnchors,
            mooringParams.doublingAngle,
          );
          const hasBadAnchor = anchorPositions.some((p) => {
            if (
              mooringParams.keepAnchorsInPark &&
              mooringIntersectsPolygon(pt, p.geometry, park.geometry)
            )
              return true;

            if (
              anchorExclZones.some((zone) =>
                mooringIntersectsPolygon(pt, p.geometry, zone.geometry),
              )
            )
              return true;
            return false;
          });

          if (mooringParams.requireAllAnchors) return !hasBadAnchor;
          return true;
        };

        let turbinePositions: [number, number][] = [];
        if (parameters.method === "regular") {
          turbinePositions = regularGeneration(
            selectedZone.geometry,
            currentTurbineType.diameter,
            parameters.params,
            turbineCanBePlaced,
          );
        } else if (parameters.method === "edge") {
          turbinePositions = edgeGeneration(
            selectedZone.geometry,
            currentTurbineType.diameter,
            parameters.params,
            pointIsOutsideExclusionZones,
          );
        } else {
          return undefined;
        }

        const turbineFeatures = turbinePositions.map((p) =>
          point2TurbineFeature(p, park.id, currentTurbineType.id),
        );
        setTurbineNames(turbineFeatures);

        return turbineFeatures;
      });
    },
    [currentTurbineType, exclusionZones, park, selectedZone.geometry],
  );

  const makeTurbineFeatures = useCallback(
    (turbinePositions: [number, number][]) => {
      const turbineFeatures = turbinePositions.map((p) =>
        point2TurbineFeature(p, park.id, currentTurbineType.id),
      );
      return turbineFeatures;
    },
    [currentTurbineType.id, park.id],
  );

  const setPreviewTurbines = useCallback(
    (turbines: TurbineFeature[]) => {
      const notInSelectedZone = (f: TurbineFeature) =>
        !pointInPolygon(f.geometry, selectedZone.geometry, 1e-3);

      const outsideZoneTurbines = turbinesInPark.filter(notInSelectedZone);
      setPreviewState({
        preview: turbines,
        existing: outsideZoneTurbines,
      });
    },
    [selectedZone.geometry, setPreviewState, turbinesInPark],
  );

  const getFeaturesToSave = useRecoilCallback(
    ({ snapshot }) =>
      (turbineFeatures: TurbineFeature[]) => {
        const turbinesInPark = snapshot
          .getLoadable(getTurbinesSelectorFamily({ parkId: park.id }))
          .getValue();
        const projectData = snapshot
          .getLoadable(projectFeaturesSelector)
          .getValue();
        const mooringLines = snapshot
          .getLoadable(getMooringLinesSelector(park.id))
          .getValue();
        const anchors = snapshot
          .getLoadable(getAnchorsSelectorFamily(park.id))
          .getValue();

        const inSelectedZoneNonLocked = (f: TurbineFeature) =>
          pointInPolygon(f.geometry, selectedZone.geometry, 1e-3) &&
          !featureIsLocked(f);

        const [insideZoneNonLockedTurbines, remainingTurbines] = partition(
          turbinesInPark,
          inSelectedZoneNonLocked,
        );
        const cableIdsToRemove = getCableIdsInPolygon(
          projectData,
          selectedZone,
        );
        const mooringLinesToRemove = mooringLines.filter((f) =>
          insideZoneNonLockedTurbines.find((t) => t.id === f.properties.target),
        );

        const anchorIdsToRemove = anchors
          .filter((f) =>
            mooringLinesToRemove.find((m) => m.properties.anchor === f.id),
          )
          .map((f) => f.id);

        const largestLabel = fastMax(
          remainingTurbines.map((f) =>
            parseInt(f.properties.name?.slice(1) ?? "0"),
          ),
          0,
        );
        const tf = turbineFeatures.map((t) => ({
          ...t,
          properties: { ...t.properties },
        }));
        setTurbineNames(tf, largestLabel + 1);

        return {
          remove: [
            ...insideZoneNonLockedTurbines.map((f) => f.id),
            ...cableIdsToRemove,
            ...mooringLinesToRemove.map((f) => f.id),
            ...anchorIdsToRemove,
          ],
          add: tf,
        };
      },
    [park.id, selectedZone],
  );

  /**
   * Save the given turbines to the project, and remove any turbines that are
   * inside the selected zone but not in the given turbines.  Also remove
   * cables, mooring lines and anchors that are connected to the removed
   * turbines.
   */
  const saveTurbines = useCallback(
    (turbineFeatures: TurbineFeature[]) => {
      updateFeatures(getFeaturesToSave(turbineFeatures));
    },
    [getFeaturesToSave, updateFeatures],
  );

  return {
    getFeaturesToSave,
    saveTurbines,
    setPreviewTurbines,
    computeTurbines,
    makeTurbineFeatures,
  };
};
