import { useEffect, useRef } from "react";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import InitAnimation from "./InitAnimation";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16rem;
  color: ${colors.brand};
  font-weight: 600;
  height: fit-content;
`;

const AnimatedLogoContainer = styled.div`
  height: 50rem;
  rect {
    fill: none;
  }
`;

const Logo = () => {
  const lottieContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    var animationData = {
      v: "5.9.6",
      fr: 25,
      ip: 0,
      op: 77,
      w: 1080,
      h: 1080,
      nm: "Comp 1",
      ddd: 0,
      assets: [],
      fonts: {
        list: [
          {
            fName: "DMSans-Medium",
            fFamily: "DMSans",
            fStyle: "Medium",
            ascent: 71.8994140625,
          },
        ],
      },
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 5,
          nm: "<empty text layer>",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [-1012, 276, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 80,
                    f: "DMSans-Medium",
                    t: "",
                    ca: 0,
                    j: 2,
                    tr: 0,
                    lh: 96,
                    ls: 0,
                    fc: [0.737, 0.431, 0.282],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 375,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "Offshore Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [575.265, 689.889, 0], ix: 2, l: 2 },
            a: { a: 0, k: [464.482, 210.31, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [173.274, 0.065],
                        [-13.883, 8.564],
                        [-60.423, 8.293],
                        [-57.655, 33.263],
                        [-74.752, 39.492],
                        [-2.59, -25.118],
                        [0.546, -71.25],
                        [12.983, -0.024],
                      ],
                      o: [
                        [-16.312, -0.006],
                        [58.039, -35.801],
                        [92.972, -12.761],
                        [57.656, -33.262],
                        [93.248, -49.263],
                        [2.147, 20.83],
                        [-0.099, 12.983],
                        [-117.756, 0.218],
                      ],
                      v: [
                        [-411.408, 179.349],
                        [-419.879, 149.389],
                        [-202.698, 49.132],
                        [52.819, 24.334],
                        [219.558, -130.327],
                        [431.615, -146.648],
                        [431.802, 155.891],
                        [408.196, 179.372],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [0.007843137255, 0.219607858097, 0.349019607843, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 30, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [464.482, 210.31], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tm",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 14,
                    s: [0],
                  },
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 34,
                    s: [25],
                  },
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 52,
                    s: [50],
                  },
                  { t: 73, s: [100] },
                ],
                ix: 1,
              },
              e: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 14,
                    s: [0],
                  },
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 34,
                    s: [50],
                  },
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 45,
                    s: [75],
                  },
                  { t: 73, s: [100] },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: "Trim Paths 1",
              mn: "ADBE Vector Filter - Trim",
              hd: false,
            },
          ],
          ip: 0,
          op: 375,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: "Vindmill Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [533.517, 497.233, 0], ix: 2, l: 2 },
            a: { a: 0, k: [508.295, 332.858, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-20.754, 24.668],
                        [-32.235, 52.108],
                        [-18.566, 11.323],
                        [-101.887, 38.83],
                        [-3.727, -18.344],
                        [33.774, -20.418],
                        [0, 0],
                        [10.659, -1.122],
                      ],
                      o: [
                        [0, 0],
                        [32.235, -52.108],
                        [18.567, -11.322],
                        [101.887, -38.829],
                        [3.727, 18.345],
                        [-33.775, 20.418],
                        [0, 0],
                        [-10.659, 1.122],
                      ],
                      v: [
                        [-456.821, 276.959],
                        [-366.874, 158.819],
                        [-289.927, 19.564],
                        [172.346, -178.986],
                        [473.848, -283.794],
                        [400.733, -210.52],
                        [-386.172, 282.638],
                        [-418.365, 301.016],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [0.007843137255, 0.219607858097, 0.349019607843, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 30, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [508.295, 332.858], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tm",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 4,
                    s: [0],
                  },
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 40,
                    s: [25],
                  },
                  { t: 75, s: [100] },
                ],
                ix: 1,
              },
              e: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 0,
                    s: [0],
                  },
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 53,
                    s: [79.333],
                  },
                  { t: 75, s: [100] },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: "Trim Paths 1",
              mn: "ADBE Vector Filter - Trim",
              hd: false,
            },
          ],
          ip: 0,
          op: 375,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 1,
          nm: "Pale Royal Blue Solid 1",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [540, 540, 0], ix: 2, l: 2 },
            a: { a: 0, k: [540, 540, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          sw: 1080,
          sh: 1080,
          sc: colors.focusBackground,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
      ],
      markers: [],
      chars: [],
    };
    var params = {
      container: lottieContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animationData,
    };

    (globalThis as any).lottie.loadAnimation(params);
  }, [lottieContainer]);

  return (
    <Wrapper>
      <AnimatedLogoContainer id="lottieContainer" ref={lottieContainer} />
    </Wrapper>
  );
};

const AnimatedLogo = () => {
  return (
    <>
      <InitAnimation />
      <Logo />
    </>
  );
};

export default AnimatedLogo;
