import { useEffect, useMemo, useRef, useState } from "react";
import mapboxgl, { CirclePaint, LngLatBoundsLike } from "mapbox-gl";
import { WindPointsInner } from "layers/windMeasurements";
import { WindDataSource } from "services/metoceanService";
import { WindSourceBorder } from "./DataSourceBorders";
import styled from "styled-components";
import { typography } from "styles/typography";
import {
  MeanSpeedGridCustom,
  UploadedWindData,
  WRG,
  WindData,
} from "state/windStatistics";
import Point from "components/MapFeatures/Point";
import { Feature } from "geojson";
import { v4 as uuidv4 } from "uuid";
import { fastMax, fastMin } from "utils/utils";
import {
  ShowGwaLayerOuter,
  ShowWRGFileOuter,
} from "components/UploadModal/components/NewUploadTab/UploadWindData/PreviewWRG";
import Spinner from "@icons/spinner/Spinner";
import { ShowGridFileInner } from "components/UploadModal/components/NewUploadTab/UploadWindData/PreviewGrid";
import { PreviewTimeSeriesInner } from "components/UploadModal/components/NewUploadTab/UploadWindData/PreviewTimeseries";
import { borderRadiusMedium, spaceDecent, spaceMedium } from "styles/space";

const uploadWindDataSourceId = "custom-wind-data-points2";
const uploadWindDataLayerId = "custom-wind-data-points2";
const defaultProjection: mapboxgl.Projection = {
  name: "mercator",
};

const Box = styled.div`
  height: 40rem;
  border: 0.5px solid lightgrey;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 8px;
  background-color: #fff;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.2rem;
`;

const SmallerBox = styled(Box)`
  height: 30rem;
`;

export const MapWrapper = styled.div`
  height: 95%;
`;

const Title = styled.div`
  ${typography.sub3}
`;

const ShowDataSourceOuter = ({
  selectedWindDataSource,
  timeSeries,
}: {
  selectedWindDataSource: WindDataSource;
  timeSeries: UploadedWindData | undefined;
}) => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<mapboxgl.Map>();
  const [_selected, setSelected] = useState<string | undefined>();

  useEffect(() => {
    if (!mapContainer.current) {
      return;
    }

    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/vindai/cl7khk1rl000l16lnommr0cr3",
      logoPosition: "top-right",
      preserveDrawingBuffer: true,
      dragRotate: false,
      projection: defaultProjection,
      zoom: 0,
      center: [0, 30],
    });

    map.on("load", (e) => {
      setMap(e.target);
    });

    return () => {
      setTimeout(() => map.remove(), 200);
    };
  }, []);

  if (!mapContainer) return null;

  return (
    <Box>
      <Title>Preview</Title>
      <MapWrapper id="grid-map" ref={mapContainer} />
      {selectedWindDataSource !== WindDataSource.CUSTOM && (
        <>
          <WindPointsInner
            map={map}
            id={undefined}
            selectedSource={selectedWindDataSource}
            onClick={() => console.log("Clicked on a data point")}
          />
          <WindSourceBorder
            map={map}
            selectedDataSource={selectedWindDataSource}
          />
        </>
      )}
      {selectedWindDataSource === WindDataSource.CUSTOM && timeSeries && (
        <>
          <ShowTimeSeriesInner
            map={map}
            setSelected={setSelected}
            timeSeries={timeSeries}
          />
        </>
      )}
    </Box>
  );
};

function isValidCoordinate(data: any): data is { lon: number; lat: number } {
  return typeof data?.lon === "number" && typeof data?.lat === "number";
}

function isNumber(value: unknown): value is number {
  return typeof value === "number";
}

const ShowTimeSeriesInner = ({
  timeSeries,
  map,
  setSelected,
}: {
  timeSeries: UploadedWindData;
  setSelected: (feature: any) => void;
  map?: mapboxgl.Map;
}) => {
  const bounds: LngLatBoundsLike | undefined = useMemo(() => {
    if (timeSeries && timeSeries.length > 0) {
      const lons = timeSeries.map((p) => p.data?.lon).filter(isNumber);
      const lats = timeSeries.map((p) => p.data?.lat).filter(isNumber);
      return [fastMin(lons), fastMin(lats), fastMax(lons), fastMax(lats)];
    } else return undefined;
  }, [timeSeries]);

  useEffect(() => {
    if (!bounds || !map) return;
    map.fitBounds(bounds, {
      padding: 50,
      animate: false,
      maxZoom: 9,
    });
  }, [bounds, map]);

  const features: Feature[] = useMemo(() => {
    // Bør vi bruke GenericFeatures som flagg gjør, vil det gi mer funksjonalitet vi trenger?
    return timeSeries
      .filter((d) => isValidCoordinate(d.data))
      .map((d) => {
        return {
          type: "Feature" as const,
          id: uuidv4(),
          geometry: {
            coordinates: [d.data!.lon, d.data!.lat] as [number, number],
            type: "Point",
          },
          properties: {},
        };
      });
  }, [timeSeries]);

  const paint: CirclePaint = {
    "circle-radius": 6,
    "circle-opacity": 0.5,
  };

  const symbols: Omit<mapboxgl.SymbolLayer, "id" | "source"> = {
    type: "symbol",
    layout: {
      "icon-image": "embassy", // reference the image
      "icon-size": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        7,
        0.7,
        10,
        1.2,
      ],
      "icon-allow-overlap": false,
    },
  };
  if (!map) return null;

  return (
    <>
      {map && paint && (
        <Point
          map={map}
          features={features}
          sourceId={uploadWindDataSourceId}
          layerId={uploadWindDataLayerId}
          paint={paint}
          symbols={symbols}
          onClickCallback={(features) => setSelected(features[0]?.properties)}
        />
      )}
    </>
  );
};

export const PreviewWindSource = ({
  selectedDataSourceType,
  selectedDataSourceId,
  timeSeries,
}: {
  selectedDataSourceType: string | undefined;
  selectedDataSourceId?: string | undefined;
  timeSeries: UploadedWindData | undefined;
}) => {
  if (!selectedDataSourceType) return;

  const windDataSource =
    selectedDataSourceType === "built_in"
      ? (selectedDataSourceId as WindDataSource)
      : WindDataSource.CUSTOM;

  return (
    <ShowDataSourceOuter
      selectedWindDataSource={windDataSource}
      timeSeries={timeSeries}
    />
  );
};

export const PreviewGrid = ({
  customWRGFiles,
  customMeanSpeedGridFiles,
}: {
  customWRGFiles: { id: string; wrg: WRG }[] | undefined;
  customMeanSpeedGridFiles:
    | { id: string; data: MeanSpeedGridCustom | undefined }[]
    | undefined;
}) => {
  if (
    (!customWRGFiles || customWRGFiles.length === 0) &&
    (!customMeanSpeedGridFiles || customMeanSpeedGridFiles.length === 0)
  )
    return <Spinner size="1rem" />;
  if (customWRGFiles && customWRGFiles?.length > 1)
    throw new Error("More than one wrg file with same id");
  if (customMeanSpeedGridFiles && customMeanSpeedGridFiles?.length > 1)
    throw new Error("More than one wrg file with same id");
  return (
    <Box>
      <Title>Preview</Title>
      {customWRGFiles && customWRGFiles.length === 1 && (
        <ShowWRGFileOuter wrg={customWRGFiles[0].wrg} />
      )}

      {customMeanSpeedGridFiles && customMeanSpeedGridFiles.length === 1 && (
        <ShowGridFileInner grid={customMeanSpeedGridFiles[0].data} />
      )}
    </Box>
  );
};

export const PreviewGwa = () => {
  return (
    <Box>
      <Title>Preview</Title>
      <ShowGwaLayerOuter />
    </Box>
  );
};

const GraphWrapper = styled.div`
  height: 80%;
  padding: ${spaceDecent};
  gap: ${spaceMedium};
  border-radius: ${borderRadiusMedium};
  display: flex;
  flex-direction: column;
`;

export const PreviewTimeseriesGraph = ({
  windData,
}: {
  windData: WindData;
}) => {
  return (
    <SmallerBox>
      <Title>Graph</Title>
      <GraphWrapper>
        <PreviewTimeSeriesInner windData={windData} />
      </GraphWrapper>
    </SmallerBox>
  );
};
