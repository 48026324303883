/// <reference types="vite-plugin-svgr/client" />
import Plotly from "plotly.js-dist-min";
import { CenterContainer, LoadingState, SafeCard } from "./Base";
import { useMemo, useRef, useState, useEffect, useCallback } from "react";
import { colors } from "../../../styles/colors";
import { MenuItem } from "../../General/Menu";
import DownloadIcon from "@icons/24/Download.svg?react";
import { useToast } from "../../../hooks/useToast";
import { stringify } from "csv-stringify/sync";
import { downloadText } from "../../../utils/utils";
import {
  AnalysisStoppedTypes,
  analysisStoppedText,
} from "components/ProductionV2/types";
import {
  getAnalysisProgress,
  getProductionHistograms,
  getStoppedReason,
  getStoppedReasonFromAnalysis,
} from "components/ProductionV2/state";
import { useRecoilValue } from "recoil";
import { SkeletonText } from "components/Loading/Skeleton";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useDashboardContext } from "../Dashboard";

const useNumbers = () => {
  const { triggerId } = useDashboardContext();
  const productionHistograms = useRecoilValue(
    getProductionHistograms(triggerId),
  );
  const stoppedReason = useRecoilValue(getStoppedReason(triggerId));
  const analysisStoppedReason = useRecoilValue(
    getStoppedReasonFromAnalysis(triggerId),
  );
  const analysisProgress = useRecoilValue(getAnalysisProgress(triggerId));
  const { gross, net } = productionHistograms ?? {};

  const [netDuration, netPower] = useMemo(() => {
    if (!net) return [undefined, undefined];
    const hours = [
      8760,
      ...net.cumulativeProduction.map((p) => (1 - p) * 8760),
    ];
    const midPoints = [
      0,
      ...net.bins
        .slice(1)
        .map((_, i) => (net.bins[i] + net.bins[i + 1]) * 0.5 * 1000),
    ];
    return [hours, midPoints];
  }, [net]);

  const [grossDuration, grossPower] = useMemo(() => {
    if (!gross) return [undefined, undefined];
    const hours = [
      8760,
      ...gross.cumulativeProduction.map((p) => (1 - p) * 8760),
    ];
    const midPoints = [
      0,
      ...gross.bins
        .slice(1)
        .map((_, i) => (gross.bins[i] + gross.bins[i + 1]) * 0.5 * 1000),
    ];
    return [hours, midPoints];
  }, [gross]);

  return {
    grossPower,
    netPower,
    grossDuration,
    netDuration,
    stoppedReason,
    analysisStoppedReason,
    analysisProgress,
  };
};

const GraphWrapper = () => {
  const {
    netPower,
    netDuration,
    stoppedReason,
    analysisStoppedReason,
    analysisProgress,
  } = useNumbers();

  if (stoppedReason) {
    return (
      <CenterContainer>
        <SimpleAlert
          title="Analysis stopped"
          text={analysisStoppedText[stoppedReason]}
          type={"error"}
        />
      </CenterContainer>
    );
  }
  if (analysisStoppedReason) {
    return (
      <SimpleAlert
        title="Analysis stopped"
        text={
          analysisStoppedText[analysisStoppedReason as AnalysisStoppedTypes]
        }
        type={"error"}
      />
    );
  }

  if (!netDuration || !netPower) {
    if (analysisProgress)
      return (
        <SkeletonText
          style={{ margin: "2rem", height: "2rem" }}
          text={`${Math.round(analysisProgress * 100)}%`}
        />
      );
    return <LoadingState />;
  }

  return <Inner netDuration={netDuration} netPower={netPower} />;
};

const Inner = ({
  netPower,
  netDuration,
}: {
  netDuration: number[];
  netPower: number[];
}) => {
  const graphRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!graphRef.current) return;
    setIsLoaded(false);
    const data = [
      {
        name: "",
        y: netPower,
        x: netDuration,
        line: { shape: "vh", color: colors.primary } as const,
        mode: "lines",
        type: "scatter" as const,
        hovertemplate:
          "The park outputs %{y:,.0f} MW or more<br />for %{x:,.0f} hours each year (on average)",
      } as const,
    ];

    const layout = {
      hovermode: "closest" as const,
      legend: { x: 0, y: 0.1, xanchor: "left" } as const,
      hoverlabel: { align: "left" } as const,
      font: { size: 10, family: "Open Sans" },
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
      autosize: true,
      height: 220,
      xaxis: {
        title: "Hours",
        range: [0, 8766],
      },
      yaxis: {
        title: "Net power [MW]",
      },
      margin: {
        l: 60,
        r: 30,
        b: 30,
        t: 30,
      },
    };

    Plotly.newPlot(graphRef.current, data, layout, {
      displayModeBar: false,
      responsive: true,
      showTips: false,
    }).then(() => setIsLoaded(true));
  }, [graphRef, netDuration, netPower]);

  return (
    <div style={{ flex: 1 }} ref={graphRef}>
      {isLoaded ?? <LoadingState />}
    </div>
  );
};

const CumulativeGraphMenu = () => {
  const { grossPower, grossDuration, netDuration, netPower } = useNumbers();

  const { warning } = useToast();

  const download = useCallback(() => {
    if (!netDuration || !grossDuration || !netPower || !grossPower) {
      warning(
        "Data is still loading, please wait a few seconds and try again.",
      );
      return;
    }

    const header = [
      "gross_power_mw",
      "gross_duration_hours",
      "net_power_mw",
      "net_duration_hours",
    ];

    const rows = grossPower.map((p, i) => {
      const _gross = [Math.round(p), Math.round(grossDuration[i])];

      const _net = [Math.round(netPower[i]), Math.round(netDuration[i])];

      return [..._gross, ..._net];
    });
    const csvString = stringify([header, ...rows]);
    downloadText(csvString, "duration_curves.csv");
  }, [grossDuration, grossPower, netDuration, netPower, warning]);

  return (
    <>
      <MenuItem
        name={"Download as .csv"}
        icon={<DownloadIcon />}
        onClick={() => download()}
      />
    </>
  );
};

export const CumulativeGraphWidget = () => (
  <SafeCard
    title="Production duration curve"
    id="Cumulative graph"
    menuItems={<CumulativeGraphMenu />}
  >
    <GraphWrapper />
  </SafeCard>
);
