import { useCallback } from "react";
import { useLocalStorage } from "../../../hooks/useBrowserStorage";
import { ReadComments, Thread } from "../types";

export function useReadComments() {
  const [readComments, setReadComments] =
    useLocalStorage<ReadComments>("vind:read-comments");

  const isRead = useCallback(
    (comment: Thread) => {
      return (
        readComments &&
        readComments.some((c) => c.threadId === comment.threadId)
      );
    },
    [readComments],
  );
  const isRepliesRead = useCallback(
    (comment: Thread) => {
      const data =
        readComments &&
        readComments.find((c) => c.threadId === comment.threadId);

      return data && comment.replies <= data.replies.length;
    },
    [readComments],
  );
  const setRead = useCallback(
    (comment: Thread, replyIds?: string[]) => {
      return setReadComments((cur) => {
        const filtered = cur
          ? cur.filter((t) => t.threadId !== comment.threadId)
          : [];
        return [
          ...filtered,
          { threadId: comment.threadId, replies: replyIds ? replyIds : [] },
        ];
      });
    },
    [setReadComments],
  );
  const removeRead = useCallback(
    (threadId: string) =>
      setReadComments((cur) => {
        const filtered = cur ? cur.filter((t) => t.threadId !== threadId) : [];
        return [...filtered];
      }),
    [setReadComments],
  );

  return { isRead, setRead, isRepliesRead, removeRead };
}
