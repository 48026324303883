import { useEffect, useMemo, useRef, useState } from "react";
import Plotly from "plotly.js-dist-min";
import { colors, hexToRgb } from "../../../styles/colors";
import { CenterContainer, LoadingState, SafeCard } from "./Base";
import { TurbineFeature } from "../../../types/feature";
import {
  getAdvancedOrgTurbine,
  getAdvancedProjectTurbine,
} from "../../../services/turbineAPIService";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { topLevelFolderIdFromOrgIdAndProjectIdSelectorFamily } from "../../Projects/useOrganisationFolderCrud";
import { useDashboardContext } from "../Dashboard";
import { DEFAULT_TURBINES, TurbineType } from "../../../types/turbines";
import { count, maxBy } from "../../../utils/utils";
import {
  getAnalysis,
  getAnalysisWindStats,
  getAverageSpeedPerTurbine,
  getStoppedReason,
  getStoppedReasonFromAnalysis,
  getTurbines,
} from "components/ProductionV2/state";
import { allSimpleTurbineTypesWithLevelSelector } from "state/turbines";
import { useToast } from "hooks/useToast";
import { NotAuthorizedError } from "components/ErrorBoundaries/types";
import { AnalysisWindStats } from "functions/production";
import { TurbineStat } from "components/ProductionV2/types";
import {
  analysisStoppedText,
  AnalysisStoppedTypes,
} from "components/ProductionV2/types";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";

const WindGraph = () => {
  const { triggerId } = useDashboardContext();

  const stoppedReason = useRecoilValueLoadable(
    getStoppedReason(triggerId),
  ).valueMaybe();

  const analysisStoppedReason = useRecoilValueLoadable(
    getStoppedReasonFromAnalysis(triggerId),
  ).valueMaybe();

  if (stoppedReason) {
    return (
      <CenterContainer>
        <SimpleAlert
          title="Analysis stopped"
          text={analysisStoppedText[stoppedReason]}
          type={"error"}
        />
      </CenterContainer>
    );
  }
  if (analysisStoppedReason) {
    return (
      <SimpleAlert
        title="Analysis stopped"
        text={
          analysisStoppedText[analysisStoppedReason as AnalysisStoppedTypes]
        }
        type={"error"}
      />
    );
  }

  return <Inner />;
};

const Inner = () => {
  const { triggerId } = useDashboardContext();
  const version = useRecoilValue(getAnalysis(triggerId))?.version;
  const windStats = useRecoilValue(getAnalysisWindStats(triggerId));
  const meanSpeedPerTurbine = useRecoilValue(
    getAverageSpeedPerTurbine(triggerId),
  );
  const turbines = useRecoilValue(getTurbines(triggerId));

  if (!windStats || turbines === undefined || !version || !meanSpeedPerTurbine)
    return <LoadingState />;

  return (
    <InnerDetails
      stats={windStats}
      turbines={turbines}
      speedPerTurbine={meanSpeedPerTurbine}
    />
  );
};

const InnerDetails = ({
  stats,
  turbines,
  speedPerTurbine,
}: {
  stats: AnalysisWindStats;
  turbines: TurbineFeature[];
  speedPerTurbine: TurbineStat[];
}) => {
  const graphRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const { organisationId, projectId } = useDashboardContext();
  const [turbine, setTurbine] = useState<TurbineType | undefined>();
  const topFolderNodeId = useRecoilValue(
    topLevelFolderIdFromOrgIdAndProjectIdSelectorFamily({
      organisationId,
      projectId,
    }),
  );

  const { error } = useToast();

  const allTurbines = useRecoilValue(allSimpleTurbineTypesWithLevelSelector);

  const mostFrequentTurbineId = useMemo(() => {
    const counts = [...count(turbines.map((t) => t.properties.turbineTypeId))];
    return maxBy(counts, (t) => t[1])?.[0];
  }, [turbines]);

  useEffect(() => {
    if (!topFolderNodeId || !mostFrequentTurbineId) {
      setTurbine(undefined);
      return;
    }
    const defaultTurbines = Object.fromEntries(
      DEFAULT_TURBINES.map((t) => [t.id, t]),
    );
    if (mostFrequentTurbineId in defaultTurbines) {
      setTurbine(defaultTurbines[mostFrequentTurbineId]);
      return;
    }

    const mostFrequentTurbine = allTurbines.find(
      (turbine) => turbine.turbine.id === mostFrequentTurbineId,
    );
    if (!mostFrequentTurbine) {
      error("Unable to show widget, turbine not found");
      throw new Error(
        `Was not able to find turbine with id: ${mostFrequentTurbineId} to show widget`,
      );
    }
    switch (mostFrequentTurbine.level) {
      case "library":
        getAdvancedOrgTurbine(
          organisationId,
          mostFrequentTurbine.turbine.id,
          undefined,
        )
          .then((res) => setTurbine(res))
          .catch((e) => {
            if (e instanceof NotAuthorizedError) {
              return;
            }
            throw e;
          });
        break;
      case "team":
        getAdvancedProjectTurbine(
          topFolderNodeId,
          mostFrequentTurbineId,
          undefined,
        )
          .then((res) => setTurbine(res))
          .catch((e) => {
            if (e instanceof NotAuthorizedError) {
              return;
            }
            throw e;
          });
        break;
      case "project":
        getAdvancedProjectTurbine(projectId, mostFrequentTurbineId, undefined)
          .then((res) => setTurbine(res))
          .catch((e) => {
            if (e instanceof NotAuthorizedError) {
              return;
            }
            throw e;
          });
        break;
      default:
        throw new Error("Unable to parse turbine level");
    }
    return () => setTurbine(undefined);
  }, [
    topFolderNodeId,
    mostFrequentTurbineId,
    projectId,
    allTurbines,
    error,
    organisationId,
  ]);

  useEffect(() => {
    const current = graphRef.current;
    if (!isLoaded || !current) return;
    const observer = new ResizeObserver(() => {
      if (!current.clientWidth) return;
      Plotly.Plots.resize(current);
    });

    if (current) observer.observe(current);

    return () => observer.disconnect();
  }, [isLoaded]);

  useEffect(() => {
    if (!graphRef.current) return;
    setIsLoaded(false);
    const maxSpeed =
      turbine?.windSpeed.reduce(
        (acc, s, i) => (turbine.power[i] > 0 ? s : acc),
        0,
      ) ?? 25;

    const meanTurbineMeanSpeed =
      speedPerTurbine.reduce((m, t) => (m += t.value), 0) /
      speedPerTurbine.length;
    const correction = meanTurbineMeanSpeed / stats.meanSpeed - 1;
    const sign = correction < 0 ? "-" : "+";
    const yearRange = `${stats.fromYear}-${stats.toYear}`;
    const correctionString =
      Math.abs(correction) > 1e-3
        ? ` ${sign} ${Math.abs(correction * 100).toFixed(1)}%`
        : "";
    const name = `${stats.source.toUpperCase()} (${yearRange})${correctionString}`;

    const x = stats.speedBins.map((s) => s * (correction + 1));

    const trace = {
      x,
      y: stats.speedBinProbabilities.map((p) => p * 100),
      type: "bar" as const,
      name,
      textposition: "none" as const,
      hovertemplate: "Speed: %{x}<br />Probability: %{y:,.2f}%",
      marker: {
        color: hexToRgb(colors.primary),
        line: {
          color: hexToRgb(colors.brand),
          width: 0.1,
        },
      },
    };

    const power = {
      x: turbine?.windSpeed,
      y: turbine?.power.map((p) => p / 1000),
      type: "scatter" as const,
      yaxis: "y2",
      name: `Power curve; ${turbine?.name}`,
      hovertemplate: "Speed: %{x}<br />Power: %{y:,.1f}MW",
      marker: {
        color: hexToRgb(colors.turbine),
      },
    };

    const layout = {
      font: { size: 8 },
      paper_bgcolor: "rgba(0,0,0,0)",
      autosize: true,
      xaxis: { title: "Speed [m/s]", range: [0, maxSpeed + 5] },
      yaxis: { title: "Probability [%]" },
      showlegend: true,
      yaxis2: {
        title: "Power [MW]",
        overlaying: "y",
        side: "right",
        rangemode: "tozero",
        showgrid: false,
        ticks: "outside",
      } as const,
      height: 200,
      bargap: 0.2,
      margin: {
        l: 60,
        r: 60,
        b: 30,
        t: 30,
      },
      legend: {
        xanchor: "right",
        yanchor: "bottom",
      } as const,
    };

    Plotly.newPlot(graphRef.current, [trace, power], layout, {
      displayModeBar: false,
      responsive: true,
      staticPlot: true,
    }).then(() => setIsLoaded(true));
  }, [graphRef, stats, turbines, turbine, speedPerTurbine]);

  return <div ref={graphRef} />;
};

export const WindDistributionWidget = () => (
  <SafeCard title="Wind speed distribution" id="Wind speed distribution">
    <WindGraph />
  </SafeCard>
);
