/// <reference types="vite-plugin-svgr/client" />
import React, { useCallback, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import ArrowLeftIcon from "@icons/24/ArrowLeft.svg?react";
import CloseIcon from "@icons/24/Close.svg?react";
import useTextInput from "../../../hooks/useTextInput";
import { getAllSourceEndpointUrls } from "../../../state/layer";
import { Input } from "../../General/Input";
import Button from "../../General/Button";
import Dropdown from "../../Dropdown/Dropdown";
import {
  checkArcgisRestApiIsValid,
  checkIsValidWMS,
  checkIsValidWMTS,
  checkWFSIsValid,
  formatSourceUrl,
  sourceEndpointUrlAlreadyExists,
} from "../../AddLayerSourceInternalModal/utils";
import { CustomDataEntry, SourceTypes } from "../../../types/layers";
import { newCustomDataSourceAtom } from "../../../state/newLayer";
import { useTypedPath } from "../../../state/pathParams";
import { IconBtn } from "components/General/Icons";

const ErrorText = styled.h5`
  color: #ff0000;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

export const AddNewDataSourceModalToProjectType =
  "AddNewDataSourceModalToProjectType";
export type AddNewDataModalTypes = typeof AddNewDataSourceModalToProjectType;

export const AddNewDataSourceToProjectModalInner = ({
  prefilledFields,
  modalType,
  close,
  cancel,
  showCloseControls = true,
}: {
  prefilledFields?: CustomDataEntry;
  modalType: AddNewDataModalTypes;
  showCloseControls?: boolean;
  cancel?(): void;
  close?(): void;
}) => {
  const { projectId } = useTypedPath("projectId");
  const [sourceUrl, onSourceUrlChange] = useTextInput(
    prefilledFields?.url,
    (text) => text.trim(),
  );
  const [sourceName, onSourceNameChange] = useTextInput(prefilledFields?.name);
  const [sourceType, onSourceTypeChange] = useTextInput<SourceTypes>(
    prefilledFields?.type ?? SourceTypes.wms,
  );
  const showSpacesBeingReplacedInfo =
    sourceUrl.includes(" ") || sourceUrl.endsWith("/");

  const [error, setError] = useState<string | undefined>();
  const setNewCustomDataSourceAtom = useSetRecoilState(newCustomDataSourceAtom);
  const allSourceUrls = useRecoilValue(getAllSourceEndpointUrls({ projectId }));

  const addSource = useCallback(async () => {
    setError("");
    if (sourceName === "") {
      setError("Source name can not be left empty");
      return;
    }

    if (!sourceUrl.toLowerCase().startsWith("https")) {
      setError("Url does not start with 'https'");
      return;
    }

    const formattedUrl = formatSourceUrl(sourceUrl);

    if (sourceEndpointUrlAlreadyExists(allSourceUrls, formattedUrl)) {
      setError("Source url already exists");
      return;
    }

    if (sourceType === SourceTypes.wms) {
      const valid = await checkIsValidWMS(formattedUrl);
      if (!valid) {
        setError("Url does not point to a valid WMS source...");
        return;
      }
    } else if (sourceType === SourceTypes.wmts) {
      const valid = await checkIsValidWMTS(formattedUrl);
      if (!valid) {
        setError("Url does not point to a valid WMTS source...");
        return;
      }
    } else if (sourceType === SourceTypes.arcgis_rest_api) {
      const valid = await checkArcgisRestApiIsValid(formattedUrl);
      if (!valid) {
        setError("Url does not point to a valid Arcgis rest api source...");
        return;
      }
    } else if (sourceType === SourceTypes.wfs) {
      const valid = await checkWFSIsValid(formattedUrl);
      if (!valid) {
        setError(
          "Url does not point to a valid wfs source or a wfs source that outputs geojson...",
        );
        return;
      }
    }

    setNewCustomDataSourceAtom({
      type: sourceType,
      name: sourceName,
      url: formattedUrl,
      modalType,
    });
    setError(undefined);
  }, [
    sourceName,
    sourceUrl,
    allSourceUrls,
    sourceType,
    setNewCustomDataSourceAtom,
    modalType,
  ]);

  return (
    <>
      {showCloseControls && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 0,
          }}
        >
          <h2 style={{ margin: 0, padding: 0 }}>Add source</h2>
          <IconBtn size="1.5rem" onClick={cancel}>
            <CloseIcon />
          </IconBtn>
        </div>
      )}
      <span>Sources added will only be accessible in this project.</span>
      <div style={{ display: "flex", flexDirection: "column", gap: "1.6rem" }}>
        <Dropdown onChange={onSourceTypeChange} style={{ padding: "1rem" }}>
          {Object.values(SourceTypes).map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </Dropdown>
        <Input
          value={sourceUrl}
          onChange={onSourceUrlChange}
          type="text"
          placeholder={`URL to ${sourceType} source *`}
          onCancel={cancel}
        />
        <Input
          value={sourceName}
          onChange={onSourceNameChange}
          type="text"
          placeholder={`Source name *`}
        />
        {showSpacesBeingReplacedInfo && (
          <>
            <div>
              Spaces in URL will be replaced by plus characters (+), and
              trailing slashes will be removed
            </div>
            <div>{formatSourceUrl(sourceUrl)}</div>
          </>
        )}
      </div>
      {error && <ErrorText>{error}</ErrorText>}
      <ButtonWrapper>
        <Button
          buttonType="text"
          text="Back"
          icon={<ArrowLeftIcon />}
          onClick={cancel}
          style={{
            paddingLeft: 0,
          }}
        />
        <ButtonWrapper>
          <Button text="Cancel" buttonType="text" onClick={close} />
          <Button
            text={error === "" ? "Validating..." : "Next"}
            buttonType="primary"
            onClick={addSource}
            disabled={error === ""}
          />
        </ButtonWrapper>
      </ButtonWrapper>
    </>
  );
};

export default AddNewDataSourceToProjectModalInner;
