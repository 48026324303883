/// <reference types="vite-plugin-svgr/client" />
import DnDIconSmall from "@icons/12/DnDsmall.svg?react";
import VersionHistoryIcon from "@icons/14/VersionHistoryAlt.svg?react";
import Buffer2 from "@icons/24/Buffer2.svg?react";
import Cabling2 from "@icons/24/Cabling-2.svg?react";
import Cabling from "@icons/24/Cabling.svg?react";
import CommentIcon from "@icons/24/Chat.svg?react";
import LockIcon from "@icons/24/Lock.svg?react";
import MenuVertical from "@icons/24/MenuVertical.svg?react";
import Paragraph from "@icons/24/Paragraph.svg?react";
import Pen from "@icons/24/Pencil.svg?react";
import Split from "@icons/24/Split.svg?react";
import UnlockIcon from "@icons/24/Unlock.svg?react";
import { Divider, IconBtn, IconMenu } from "components/General/Icons";
import { Menu, MenuItem } from "components/General/Menu";
import { Popup } from "components/Mapbox/Popup";
import { useTrackEvent } from "components/OnboardingTours/state";
import { FeaturePropertiesMenuFrame } from "components/FeatureProperties/FeaturePropertiesMenuFrame";
import {
  showFeaturePropertiesAtom,
  showMoreFeatureActionsAtom,
} from "components/RightSide/InfoModal/ProjectFeatureInfoModal/state";
import TurbineEllipsesSettings from "components/TurbineEllipsesSettings/TurbineEllipsesSettings";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { currentSelectionArrayAtom } from "state/selection";
import { colors } from "styles/colors";
import { IconREMSize } from "styles/typography";
import { v4 as uuidv4 } from "uuid";
import {
  CABLE_CHAIN_POLYGON_PROPERTY_TYPE,
  CABLE_PARTITION_POLYGON_PROPERTY_TYPE,
  CABLE_PROPERTY_TYPE,
} from "../../constants/cabling";
import { lockedPropertyName } from "../../constants/canvas";
import { useDeleteFeaturesCallback } from "../../hooks/deleteFeature";
import { useSetPropertyOnProjectFeatures } from "../../hooks/useSetPropertyOnProjectFeatures";
import { useToast } from "../../hooks/useToast";
import { DefaultMap } from "../../lib/DefaultMap";
import {
  BathymetryUserUploadedType,
  GeoTiffUserUploadedImageType,
} from "../../services/types";
import { getCablesSelectorFamily } from "../../state/cable";
import {
  editFeaturesAtom,
  mapInteractionSelector,
  mapRefAtom,
} from "../../state/map";
import { modalTypeOpenAtom } from "../../state/modal";
import {
  branchIdSelector,
  parkIdSelector,
  projectIdSelector,
} from "../../state/pathParams";
import { inReadOnlyModeSelector } from "../../state/project";
import { editorAccessProjectSelector } from "../../state/user";
import {
  CableChainFeature,
  CableFeature,
  ProjectFeature,
  _FeatureType,
} from "../../types/feature";
import { keyEventTargetIsInput } from "../../utils/keyboard";
import {
  featureIsDerived,
  featureIsLocked,
  isCable,
  isCableChain,
  isCablePartition,
  isExclusionDivision,
  isExportCable,
  isMooringLine,
  isTurbine,
} from "../../utils/predicates";
import { resetListIfNotAlreadyEmpty } from "../../utils/resetList";
import { parseOr } from "../../utils/zod";
import PositionHint from "../ActiveTips/PositionHints/PositionHint";
import { splitMultiFeatureHelpHintType } from "../ActiveTips/PositionHints/SplitMultiFeatureHelp";
import { BufferModalType } from "../BufferModal/BufferModal";
import {
  useConnectChains,
  useRecomputeChains,
  useSelectCableEdit,
} from "../Cabling/Generate/CableEdit";
import { cableEditModeAtom } from "../Cabling/Generate/state";
import { newThreadAtomFamily } from "../Comments/state";
import { openedFeatureIdForVersionHistoryAtom } from "../FeatureVersionsPopup/state";
import { EditableText } from "../General/EditableText";
import Tooltip from "../General/Tooltip";
import StylingSelector from "../StylingSelector/StylingSelector";
import { CableRedundancy } from "./CableRedundancy";
import CableTypeSelector from "./CableTypeSelector";
import {
  NamedTooltipContainer,
  NamedTooltipWrapper,
  ToolsWrapper,
} from "./CanvasSelectOption.style";
import ExistingTurbineTypeSelector from "./ExistingTurbineTypeSelector";
import { ExportCableTypeSelector } from "./ExportCableTypeSelector";
import FeatureCoordinateEditor from "./FeatureCoordinateEditor";
import FoundationTypeSelector from "./FoundationTypeSelector";
import { trackCanvasOption } from "./MenuTracking";
import MooringLineLengthEditor from "./MooringLineLengthEditor";
import MooringLineTypeSelector from "./MooringLineTypeSelector";
import SplitMultiPart from "./SplitMultiPart";
import { SubstationTypeSelector } from "./SubstationTypeSelector";
import TurbineTypeSelector from "./TurbineTypeSelector";
import ExclusionTypeSelector from "./TypeSelector/ExclusionTypeSelector";
import { TypeSelector } from "./TypeSelector/TypeSelector";
import useMoveablePopup from "./useMoveablePopup";

export const BufferSelector = () => {
  const projectId = useRecoilValue(projectIdSelector) ?? "";
  const branchId = useRecoilValue(branchIdSelector) ?? "";
  const setModalOpen = useSetRecoilState(modalTypeOpenAtom);
  const openBufferModal = useCallback(() => {
    trackCanvasOption("buffer-feature", {
      projectId,
      branchId,
    });
    setModalOpen({ modalType: BufferModalType });
  }, [branchId, projectId, setModalOpen]);
  return (
    <IconBtn onClick={openBufferModal}>
      <Buffer2 />
    </IconBtn>
  );
};

const SplitCableChain = ({ chain }: { chain: CableChainFeature }) => {
  const parkId = chain.properties.parentIds[0];
  const allCables = useRecoilValue(getCablesSelectorFamily({ parkId }));
  const connect = useConnectChains({ parkId });

  const onClick = useCallback(async () => {
    const isInChain = (c: CableFeature): boolean =>
      chain.properties.turbines.includes(c.properties.fromId) &&
      chain.properties.turbines.includes(c.properties.toId);
    const cables = allCables.filter(isInChain);
    const other = new DefaultMap<string, string[]>(() => []);
    const count = new DefaultMap<string, number>(() => 0);
    for (const {
      properties: { fromId, toId },
    } of cables) {
      other.get(fromId).push(toId);
      other.get(toId).push(fromId);
      count.update(fromId, (c) => c + 1);
      count.update(toId, (c) => c + 1);
    }

    let prev = "";
    let [endpoint] = [...count.entries()].find(([, c]) => c === 1) ?? [];
    let array: string[] = [];
    while (endpoint) {
      array.push(endpoint);
      const p = prev;
      const next = other.get(endpoint).filter((c) => c !== p)[0];
      prev = endpoint;
      endpoint = next;
    }
    const mid = Math.floor(array.length / 2);
    const left = array.slice(0, mid);
    const right = array.slice(mid);

    const chains = [left, right]
      .filter((a) => 0 < a.length)
      .map((a) => ({
        ...chain.properties,
        id: uuidv4(),
        turbines: a,
      }));

    await connect(chains);
  }, [allCables, chain.properties, connect]);

  return (
    <Tooltip position="top" text={"Split chain"}>
      <IconBtn onClick={onClick}>
        <Split />
      </IconBtn>
    </Tooltip>
  );
};

export const CanvasSingleSelectOption = ({
  selectedProjectFeature,
}: {
  selectedProjectFeature: ProjectFeature;
}) => {
  const projectId = useRecoilValue(projectIdSelector) ?? "";
  const branchId = useRecoilValue(branchIdSelector) ?? "";
  const parkId = useRecoilValue(parkIdSelector);
  const setCurrentSelectionArray = useSetRecoilState(currentSelectionArrayAtom);
  const setEditFeature = useSetRecoilState(editFeaturesAtom);
  const map = useRecoilValue(mapRefAtom);
  const mapInteraction = useRecoilValue(mapInteractionSelector);
  const isCustomerEditor = useRecoilValue(editorAccessProjectSelector);
  const isReadOnly = useRecoilValue(inReadOnlyModeSelector);
  const canEdit = isCustomerEditor && !isReadOnly;
  const isLocked = featureIsLocked(selectedProjectFeature);
  const isTurbineFeature = isTurbine(selectedProjectFeature);
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const setNewThreadOpen = useSetRecoilState(
    newThreadAtomFamily({ projectId }),
  );
  const setOpenedFeatureIdForVersionHistory = useSetRecoilState(
    openedFeatureIdForVersionHistoryAtom,
  );

  const trackEvent = useTrackEvent();
  useEffect(() => {
    if (selectedProjectFeature.properties.name === "Park A")
      trackEvent("clicked park");
  }, [trackEvent, selectedProjectFeature.properties.name]);

  const selectedProjectFeatureArr = useMemo(
    () => [selectedProjectFeature],
    [selectedProjectFeature],
  );
  const { setMouseDownPosition, isMoving, popupPlacement } = useMoveablePopup(
    selectedProjectFeatureArr,
    map,
  );

  const deleteFeatureWithChildren = useDeleteFeaturesCallback();

  const deleteFeature = useCallback(() => {
    if (!selectedProjectFeature || !canEdit) return;
    deleteFeatureWithChildren([selectedProjectFeature.id]);
  }, [canEdit, selectedProjectFeature, deleteFeatureWithChildren]);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (!["Backspace", "Delete"].includes(e.key)) {
        return;
      }

      if (
        !mapInteraction.deleteFeatureKeyboardShortcut ||
        keyEventTargetIsInput(e) ||
        isLocked
      ) {
        return;
      }
      deleteFeature();
      return;
    };

    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [deleteFeature, mapInteraction.deleteFeatureKeyboardShortcut, isLocked]);

  const onClickEditFeature = useCallback(() => {
    trackCanvasOption("edit-feature", {
      projectId,
      branchId,
    });
    setCurrentSelectionArray(resetListIfNotAlreadyEmpty);
    setEditFeature([selectedProjectFeature.id]);
  }, [
    branchId,
    projectId,
    selectedProjectFeature.id,
    setCurrentSelectionArray,
    setEditFeature,
  ]);

  const [name, setName] = useState("");
  useEffect(() => {
    if (!selectedProjectFeature) return;
    setName(selectedProjectFeature.properties?.name ?? "");
  }, [selectedProjectFeature, setName]);
  const updateFeatureProperties = useSetPropertyOnProjectFeatures(() =>
    setModalTypeOpen(undefined),
  );

  const onClickLockFeature = useCallback(() => {
    trackCanvasOption("lock-feature", {
      projectId,
      branchId,
    });
    updateFeatureProperties([selectedProjectFeature.id], {
      [lockedPropertyName]: !isLocked,
    });
  }, [
    branchId,
    projectId,
    selectedProjectFeature,
    updateFeatureProperties,
    isLocked,
  ]);

  const isOfTypeOther = useMemo(
    () =>
      selectedProjectFeature.properties.type == null &&
      ![BathymetryUserUploadedType, GeoTiffUserUploadedImageType].includes(
        selectedProjectFeature.properties.type ?? "",
      ),
    [selectedProjectFeature],
  );
  const setCableEditMode = useSetRecoilState(cableEditModeAtom);
  const [showFeatureProperties, setShowFeatureProperties] = useRecoilState(
    showFeaturePropertiesAtom,
  );
  const [showMoreFeatureActions, setShowMoreFeatureActions] = useRecoilState(
    showMoreFeatureActionsAtom,
  );

  const readonly =
    isCableChain(selectedProjectFeature) ||
    isCablePartition(selectedProjectFeature);

  const recomputeChains = useRecomputeChains({ parkId: parkId ?? "" });
  const { selectPartitionWithTurbine } = useSelectCableEdit({
    parkId: parkId ?? "",
  });
  const { error } = useToast();

  const moreMenuRef = useRef<HTMLButtonElement>(null);
  const featurePropRef = useRef<HTMLButtonElement>(null);

  if (!map) return null;
  return (
    <Popup
      map={map}
      pos={popupPlacement}
      offsetPx={[0, -20]}
      place="bottom"
      style={{
        pointerEvents: isMoving ? "none" : undefined,
      }}
    >
      <IconMenu iconSize="2.2rem">
        <div
          style={{
            cursor: "move",
          }}
          onMouseDown={(e) => {
            setMouseDownPosition({
              x: e.clientX,
              y: e.clientY,
            });
          }}
        >
          <NamedTooltipWrapper>
            <IconREMSize height={1.2} width={1.2}>
              <DnDIconSmall />
            </IconREMSize>
          </NamedTooltipWrapper>
        </div>
        {selectedProjectFeature && (
          <>
            {!isCable(selectedProjectFeature) &&
              !isCableChain(selectedProjectFeature) &&
              !isCablePartition(selectedProjectFeature) && (
                <>
                  <NamedTooltipContainer>
                    <NamedTooltipWrapper>Name</NamedTooltipWrapper>
                    <EditableText
                      type="text"
                      smallInput={true}
                      value={name}
                      disabled={!canEdit || isLocked}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      onEnter={() => {
                        updateFeatureProperties([selectedProjectFeature.id], {
                          name,
                        });
                        trackCanvasOption("change-feature-name", {
                          projectId,
                          branchId,
                        });
                      }}
                      onCancel={() => {
                        setName(selectedProjectFeature.properties?.name ?? "");
                      }}
                      textContainerStyle={{
                        maxWidth: "20vw",
                        padding: 0,
                      }}
                      editIconTitle="Rename"
                      renderText={(title) => (
                        <p
                          style={{
                            margin: 0,
                            whiteSpace: "nowrap",
                            overflowX: "hidden",
                            textOverflow: "ellipsis",
                            display: "block",
                            fontSize: "1.3rem",
                          }}
                          title={title}
                        >
                          {title}
                        </p>
                      )}
                    />
                  </NamedTooltipContainer>
                  <Divider />
                </>
              )}
            {![
              BathymetryUserUploadedType,
              GeoTiffUserUploadedImageType,
            ].includes(selectedProjectFeature.properties.type ?? "") &&
              !isCableChain(selectedProjectFeature) &&
              !isCablePartition(selectedProjectFeature) && (
                <>
                  <TypeSelector
                    selections={[selectedProjectFeature]}
                    setCurrentSelectionArray={setCurrentSelectionArray}
                    disabled={!canEdit || isLocked}
                    selectedType={parseOr(
                      _FeatureType,
                      selectedProjectFeature.properties.type,
                      undefined,
                    )}
                  />
                </>
              )}
            {isCable(selectedProjectFeature) && (
              <>
                <CableTypeSelector
                  selectedProjectFeatures={[selectedProjectFeature]}
                  editable={canEdit && !isLocked}
                />
                <Divider />
              </>
            )}
            {isMooringLine(selectedProjectFeature) && (
              <>
                <MooringLineTypeSelector
                  selectedProjectFeatures={[selectedProjectFeature]}
                  editable={canEdit && !isLocked}
                />
                <Divider />
              </>
            )}
            <TurbineTypeSelector
              selectedProjectFeatures={[selectedProjectFeature]}
              editable={canEdit && !isLocked}
            />
            <ExistingTurbineTypeSelector
              selectedProjectFeatures={[selectedProjectFeature]}
              editable={canEdit && !isLocked}
            />
            <SubstationTypeSelector
              selectedProjectFeatures={[selectedProjectFeature]}
              editable={canEdit && !isLocked}
            />
            {isExportCable(selectedProjectFeature) && (
              <ExportCableTypeSelector
                cables={[selectedProjectFeature]}
                editable={canEdit && !isLocked}
              />
            )}
            {isCable(selectedProjectFeature) && (
              <CableRedundancy
                cables={[selectedProjectFeature]}
                editable={canEdit && !isLocked}
              />
            )}
            <FoundationTypeSelector
              selectedProjectFeatures={[selectedProjectFeature]}
              editable={canEdit && !isLocked}
            />
            {canEdit &&
              !isLocked &&
              isExclusionDivision(selectedProjectFeature) && (
                <>
                  <ExclusionTypeSelector
                    divisions={[selectedProjectFeature]}
                    setCurrentSelectionArray={setCurrentSelectionArray}
                  />
                  <Divider />
                </>
              )}
            <ToolsWrapper>
              {canEdit &&
                !isLocked &&
                isCablePartition(selectedProjectFeature) && (
                  <>
                    <Tooltip position="top" text={`Recompute chains`}>
                      <IconBtn
                        onClick={async () => {
                          await recomputeChains(selectedProjectFeature).catch(
                            (e) => error(e.message ?? "Unknown error"),
                          );
                          setTimeout(() => {
                            selectPartitionWithTurbine(
                              selectedProjectFeature.properties.turbines[0],
                            );
                          }, 50);
                        }}
                      >
                        <Cabling2 />
                      </IconBtn>
                    </Tooltip>
                    <Divider />
                  </>
                )}
              {canEdit && !isLocked && isCableChain(selectedProjectFeature) && (
                <>
                  <SplitCableChain chain={selectedProjectFeature} />
                  <Divider />
                </>
              )}
              {canEdit &&
                !isLocked &&
                !readonly &&
                ![
                  BathymetryUserUploadedType,
                  GeoTiffUserUploadedImageType,
                ].includes(selectedProjectFeature.properties.type ?? "") && (
                  <>
                    <Tooltip position="top" text={`Edit feature`}>
                      <IconBtn onClick={onClickEditFeature}>
                        <Pen />
                      </IconBtn>
                    </Tooltip>
                  </>
                )}
              {canEdit && !readonly && (
                <Tooltip
                  position="top"
                  text={isLocked ? "Unlock feature" : "Lock feature"}
                >
                  <IconBtn onClick={onClickLockFeature}>
                    {isLocked ? <UnlockIcon /> : <LockIcon />}
                  </IconBtn>
                </Tooltip>
              )}
              {canEdit && !isLocked && isCable(selectedProjectFeature) && (
                <>
                  <Tooltip position="top" text={`Change cabling`}>
                    <IconBtn
                      onClick={() => {
                        setCableEditMode(true);
                        setCurrentSelectionArray([]);
                      }}
                    >
                      <Cabling />
                    </IconBtn>
                  </Tooltip>
                </>
              )}

              {canEdit &&
                !isLocked &&
                ![
                  BathymetryUserUploadedType,
                  GeoTiffUserUploadedImageType,
                  CABLE_CHAIN_POLYGON_PROPERTY_TYPE,
                  CABLE_PARTITION_POLYGON_PROPERTY_TYPE,
                ].includes(selectedProjectFeature.properties.type ?? "") && (
                  <>
                    <Tooltip position="top" text="Buffer">
                      <BufferSelector />
                    </Tooltip>
                  </>
                )}
              {canEdit &&
                !isLocked &&
                selectedProjectFeature.geometry.type.includes("Multi") && (
                  <>
                    <PositionHint
                      allowedHints={[splitMultiFeatureHelpHintType]}
                      position={"top"}
                    >
                      <Tooltip position="top" text="Split into parts">
                        <SplitMultiPart
                          featureIds={[selectedProjectFeature.id]}
                        />
                      </Tooltip>
                    </PositionHint>
                  </>
                )}
              {canEdit &&
                !isLocked &&
                isMooringLine(selectedProjectFeature) &&
                !readonly && (
                  <>
                    <MooringLineLengthEditor
                      feature={selectedProjectFeature}
                      parkId={parkId ?? ""}
                    />
                  </>
                )}
              {canEdit &&
                !isLocked &&
                ![
                  BathymetryUserUploadedType,
                  GeoTiffUserUploadedImageType,
                  CABLE_PROPERTY_TYPE,
                ].includes(selectedProjectFeature.properties.type ?? "") &&
                !readonly && (
                  <FeatureCoordinateEditor feature={selectedProjectFeature} />
                )}
              {canEdit && !isLocked && (
                <Tooltip position="top" text="Set styling">
                  <StylingSelector
                    selectedProjectFeatures={[selectedProjectFeature]}
                    enableColorStyling={isOfTypeOther}
                    enableOpacityStyling={
                      selectedProjectFeature.properties.type ===
                      GeoTiffUserUploadedImageType
                    }
                  />
                </Tooltip>
              )}
              {canEdit && isTurbineFeature && (
                <>
                  <Tooltip position="top" text="Display turbine ellipses">
                    <TurbineEllipsesSettings
                      selectedProjectFeatures={[selectedProjectFeature]}
                    />
                  </Tooltip>
                </>
              )}
            </ToolsWrapper>

            <Divider />
            {!featureIsDerived(selectedProjectFeature) && (
              <>
                <Tooltip position="top" text="Element properties" readonlyAware>
                  <div style={{ position: "relative", display: "flex" }}>
                    <IconBtn
                      active={showFeatureProperties}
                      ref={featurePropRef}
                      stroke={colors.iconBrand}
                      onClick={() => {
                        setShowFeatureProperties(!showFeatureProperties);
                        setShowMoreFeatureActions(false);
                      }}
                    >
                      <Paragraph />
                    </IconBtn>
                    {showFeatureProperties && (
                      <FeaturePropertiesMenuFrame
                        canvasFeature={selectedProjectFeature}
                        onClose={() => setShowFeatureProperties(false)}
                      />
                    )}
                  </div>
                </Tooltip>
                <Divider />
              </>
            )}
            {!featureIsDerived(selectedProjectFeature) && (
              <>
                <div style={{ position: "relative", display: "flex" }}>
                  <IconBtn
                    active={showMoreFeatureActions}
                    ref={moreMenuRef}
                    onClick={() => {
                      setShowMoreFeatureActions(!showMoreFeatureActions);
                      setShowFeatureProperties(false);
                    }}
                  >
                    <MenuVertical />
                  </IconBtn>
                  {showMoreFeatureActions && (
                    <Menu
                      style={{
                        position: "absolute",
                        top: "4.4rem",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <MenuItem
                        disabled={!canEdit}
                        name={"See element history"}
                        icon={<VersionHistoryIcon />}
                        onClick={() => {
                          setOpenedFeatureIdForVersionHistory(
                            selectedProjectFeature.id,
                          );
                          trackCanvasOption("feature-histoy", {
                            projectId,
                            branchId,
                          });
                          setCurrentSelectionArray([]);
                        }}
                      />
                      <MenuItem
                        disabled={isReadOnly}
                        name="Add comment"
                        icon={<CommentIcon />}
                        onClick={() => {
                          if (isReadOnly) return;
                          setNewThreadOpen({
                            featureId: selectedProjectFeature.id,
                          });
                          trackCanvasOption("comment", {
                            projectId,
                            branchId,
                          });
                          setCurrentSelectionArray([]);
                        }}
                      />
                    </Menu>
                  )}
                </div>

                <Divider />
              </>
            )}
          </>
        )}
      </IconMenu>
    </Popup>
  );
};

export default CanvasSingleSelectOption;
