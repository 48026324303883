import * as turf from "@turf/turf";
import { useBathymetryRaster } from "hooks/bathymetry";
import { FillPaint, Map } from "mapbox-gl";
import { useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";
import {
  anchorsBufferZoneLayerId,
  anchorsBufferZoneSourceId,
  mooringBufferZoneLayerId,
  mooringBufferZoneSourceId,
  touchdownBufferZoneLayerId,
  touchdownBufferZoneSourceId,
} from "../../constants/projectMapView";
import {
  getMooringLinesSelector,
  getTurbinesSelectorFamily,
  getVisibleAnchorsSelector,
  getVisibleMooringLinesSelector,
} from "../../state/layout";
import { branchIdSelector, useTypedPath } from "../../state/pathParams";
import { colors } from "../../styles/colors";
import { ParkFeature } from "../../types/feature";
import { pencilBufferLineString } from "../../utils/bufferSingleFeature";
import { isDefined, notUndefinedOrNull } from "../../utils/predicates";
import {
  generateCablesSettingState,
  showAnchorBufferZoneAtom,
  showMooringBufferZoneAtom,
  showTouchdownBufferZoneAtom,
} from "../Cabling/Generate/state";
import Polygon from "../MapFeatures/Polygon";
import { getTouchdownPointsInPark } from "./state";

const anchorBufferPaint: FillPaint = {
  "fill-color": `${colors.redAlert}`,
  "fill-opacity": 0.25,
};

const MooringBufferZoneInner = ({
  map,
  park,
  mooringLineBuffer,
}: {
  map: Map;
  park: ParkFeature;
  mooringLineBuffer?: number;
}) => {
  const branchId = useRecoilValue(branchIdSelector);
  const mooringLines = useRecoilValue(
    getVisibleMooringLinesSelector({ parkId: park.id, branchId }),
  );

  const addParkId = useCallback(
    (str: string) => {
      return `${str}-${park.id}`;
    },
    [park.id],
  );

  const zones = useMemo(() => {
    return mooringLines
      .map((ml) =>
        pencilBufferLineString(ml, (mooringLineBuffer ?? 250) / 1000.0, true),
      )
      .filter(isDefined);
  }, [mooringLines, mooringLineBuffer]);

  return (
    <Polygon
      features={zones}
      map={map}
      sourceId={addParkId(mooringBufferZoneSourceId)}
      layerId={addParkId(mooringBufferZoneLayerId)}
      paint={anchorBufferPaint}
    />
  );
};

const AnchorBufferZoneInner = ({
  map,
  park,
  anchorBuffer,
}: {
  map: Map;
  park: ParkFeature;
  anchorBuffer: number;
}) => {
  const branchId = useRecoilValue(branchIdSelector);
  const anchors = useRecoilValue(
    getVisibleAnchorsSelector({ parkId: park.id, branchId }),
  );

  const addParkId = useCallback(
    (str: string) => {
      return `${str}-${park.id}`;
    },
    [park.id],
  );

  const zones = useMemo(() => {
    return anchors
      .map((a) =>
        turf.buffer(a, anchorBuffer, {
          units: "meters",
          steps: 3,
        }),
      )
      .filter(notUndefinedOrNull);
  }, [anchors, anchorBuffer]);

  return (
    <Polygon
      features={zones}
      map={map}
      sourceId={addParkId(anchorsBufferZoneSourceId)}
      layerId={addParkId(anchorsBufferZoneLayerId)}
      paint={anchorBufferPaint}
    />
  );
};

const TouchdownBufferZoneInner = ({
  map,
  park,
  touchdownBuffer,
}: {
  map: Map;
  park: ParkFeature;
  touchdownBuffer: number;
}) => {
  const { projectId } = useTypedPath("projectId");
  const turbines = useRecoilValue(
    getTurbinesSelectorFamily({ parkId: park.id }),
  );
  const mooringLines = useRecoilValue(getMooringLinesSelector(park.id));
  const raster = useBathymetryRaster({
    projectId,
    featureId: park.id,
  }).valueMaybe();

  const addParkId = useCallback(
    (str: string) => {
      return `${str}-${park.id}`;
    },
    [park.id],
  );

  const waterDepths = Object.fromEntries(
    mooringLines.map((l) => {
      const turbine = turbines.find((a) => a.id === l.properties.target);
      const depth =
        raster && turbine
          ? -raster.latLngToValue(
              turbine.geometry.coordinates[0],
              turbine.geometry.coordinates[1],
            )
          : 0;
      return [l.id, depth];
    }),
  );

  const touchdownPoints = useRecoilValue(
    getTouchdownPointsInPark({ parkId: park.id, waterDepths }),
  );

  const zones = useMemo(() => {
    return touchdownPoints
      .map((p) =>
        turf.buffer(p, touchdownBuffer, {
          units: "meters",
          steps: 3,
        }),
      )
      .filter(notUndefinedOrNull);
  }, [touchdownPoints, touchdownBuffer]);

  return (
    <Polygon
      features={zones}
      map={map}
      sourceId={addParkId(touchdownBufferZoneSourceId)}
      layerId={addParkId(touchdownBufferZoneLayerId)}
      paint={anchorBufferPaint}
    />
  );
};

export const AnchorBufferZone = ({
  map,
  park,
}: {
  map: Map;
  park: ParkFeature;
}) => {
  const show = useRecoilValue(showAnchorBufferZoneAtom);
  const settings = useRecoilValue(generateCablesSettingState);
  if (!show || !settings.routeAroundMooring) return null;
  return (
    <AnchorBufferZoneInner
      park={park}
      map={map}
      anchorBuffer={settings.anchorBuffer ?? 250}
    />
  );
};

export const MooringBufferZone = ({
  map,
  park,
}: {
  map: Map;
  park: ParkFeature;
}) => {
  const show = useRecoilValue(showMooringBufferZoneAtom);
  const settings = useRecoilValue(generateCablesSettingState);
  if (!show || !settings.routeAroundMooring) return null;
  return (
    <MooringBufferZoneInner
      park={park}
      map={map}
      mooringLineBuffer={settings.mooringLineBuffer ?? 250}
    />
  );
};

export const TouchdownBufferZone = ({
  map,
  park,
}: {
  map: Map;
  park: ParkFeature;
}) => {
  const show = useRecoilValue(showTouchdownBufferZoneAtom);
  const settings = useRecoilValue(generateCablesSettingState);
  if (!show || !settings.routeAroundMooring) return null;
  return (
    <TouchdownBufferZoneInner
      park={park}
      map={map}
      touchdownBuffer={settings.touchdownBuffer ?? 100}
    />
  );
};
