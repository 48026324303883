import React, { useCallback, useEffect, useMemo, useRef } from "react";
import {
  RecoilLoadable,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";
import styled from "styled-components";
import { useDrag } from "react-dnd";
import {
  Column as ReactTableColumn,
  SortByFn,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { Mixpanel } from "mixpanel";
import BinIcon from "@icons/24/Bin.svg";
import DatabaseIcon from "@icons/24/Database.svg";
import ChatIcon from "@icons/24/Chat.svg";
import DownloadIcon from "@icons/24/Download.svg";
import EarthIcon from "@icons/14/Earth.svg";
import PencilIcon from "@icons/24/Pencil.svg";
import ReplaceIcon from "@icons/14/Replace.svg";
import SortDescendingIcon from "@icons/SortDescending.svg";
import SortAscendingIcon from "@icons/SortAscending.svg";
import SortNeutralIcon from "@icons/SortNeutral.svg";
import { ScrollBody } from "hooks/useShowScrollShadow";
import { Anchor } from "components/General/Anchor";
import { Row } from "components/General/Layout";
import { spacing4, spacing6, spacing8 } from "styles/space";
import { IconREMSize, typography } from "styles/typography";
import { colors } from "styles/colors";
import { IconBtn } from "components/General/Icons";
import { EditableTextInternalState } from "components/General/EditableText";
import { SkeletonText } from "components/Loading/Skeleton";
import { fetchEnhancer } from "services/utils";
import { useRecoilValueDef } from "utils/recoil";
import { modalTypeOpenAtom } from "state/modal";
import { organisationIdSelector } from "state/pathParams";
import { DotMenu } from "components/General/MenuButton";
import { MenuItem } from "components/General/Menu";
import Checkbox from "components/General/Checkbox";
import Tooltip from "components/General/Tooltip";
import { dateToYearDateTime, downloadText } from "utils/utils";
import useBooleanState from "hooks/useBooleanState";
import { DataLibraryLayer, DataLibraryPackage } from "../types";
import {
  HeaderColumn,
  HiddenIfNotChecked,
  StickyThead,
  UserInfo,
  Table,
  ProjectInfo,
} from "../shared";
import { EditVectordataType } from "../Edit/EditVectordata";
import { UploadOrganisationDataLayerModalType } from "../modals/UploadOrganisationLayerModal";
import { useDataLibraryLayersCrud } from "../useDataLibraryLayersCrud";
import EditDescriptionModalFrame from "../EditDescriptionModalFrame";
import {
  getDataLayersUsage,
  scrollToOrganisationLayerIdAtom,
  selectedOrganisationLayerIdsSelector,
} from "../state";
import AddToPackageButton from "./AddToPackageButton";
import { OrganisationResourceChangelogPopup } from "components/Changelog/Changelog";
import { organisationResourceLastUpdateSelectorFamily } from "components/Changelog/state";
import { UsageContainerInner2 } from "components/ConfigurationModal/SettingsUsage/common";
import { UsageContainerPlacement } from "components/ConfigurationModal/SettingsUsage/style";
import { organisationLibraryDataPackageResourceState } from "../../state";
import { usersInOrganisationState } from "components/Organisation/state";
import { getSortTypes } from "components/Organisation/Library/dataLibrary/sortTypes";

// UseSortByColumnProps
const columns: Array<ReactTableColumn<DataLibraryLayer>> = [
  {
    id: "name",
    width: "40%",
    // @ts-ignore
    canSort: true,
    sortType: (a, b) => a.original.name.localeCompare(b.original.name),
    Header: ({ toggleAllSelected, allIsSelected, someIsSelected }) => {
      return (
        <Row
          style={{
            paddingLeft: spacing8,
          }}
        >
          <Checkbox
            checked={
              allIsSelected ? true : someIsSelected ? "indeterminate" : false
            }
            onChange={toggleAllSelected}
          />
          <HeaderColumn>Name</HeaderColumn>
        </Row>
      );
    },
    Cell: ({ row, isSelected, onToggleLayerSelected, onChangeLayerName }) => {
      return (
        <Row
          alignCenter
          style={{ display: "flex", gap: spacing6, paddingLeft: spacing8 }}
        >
          <HiddenIfNotChecked data-checked={isSelected}>
            <Checkbox
              checked={isSelected}
              onChange={() => {
                onToggleLayerSelected(row.original.id);
              }}
            />
          </HiddenIfNotChecked>
          <EditableTextInternalState
            value={row.original.name}
            onEnter={(newValue) => {
              onChangeLayerName(row.original.id, newValue);
            }}
            inputStyle={{
              flex: 1,
            }}
            textContainerStyle={{
              padding: 0,
              margin: 0,
            }}
          />
        </Row>
      );
    },
  },
  // {
  //   id: "source",
  //   // @ts-ignore
  //   canSort: true,
  //   Header: <HeaderColumn style={typography.sub2}>Source</HeaderColumn>,
  //   Cell: () => <p>Upload</p>,
  // },
  // {
  //   id: "type",
  //   Header: <HeaderColumn style={typography.sub2}>Type</HeaderColumn>,
  //   Cell: ({ row }) => <p>{row.original.type}</p>,
  // },
  {
    id: "created_at",
    // @ts-ignore
    canSort: true,
    sortType: "lastChanged",
    Header: <HeaderColumn style={typography.sub2}>Last updated</HeaderColumn>,

    Cell: ({ row, organisationId, lastChanges }) =>
      typeof lastChanges === "undefined" ? (
        <SkeletonText />
      ) : (
        <OrganisationResourceChangelogPopup
          organisationId={organisationId}
          resourceId={row.original.id}
        >
          <p>
            {lastChanges[row.original.id] &&
              dateToYearDateTime(
                new Date(lastChanges[row.original.id].version),
              )}
          </p>
        </OrganisationResourceChangelogPopup>
      ),
  },
  {
    id: "author",
    // @ts-ignore
    canSort: true,
    sortType: "author",
    Header: <HeaderColumn>Created by</HeaderColumn>,
    // sortType: (a, b) => a.original.name.localeCompare(b.original.name),
    Cell: ({ row, organisationId }) => {
      return (
        <Row alignCenter>
          <UserInfo
            organisationId={organisationId}
            userId={row.original.author}
          />
        </Row>
      );
    },
  },
  {
    id: "nrPackageUsages",
    // @ts-ignore
    canSort: false,
    width: 100,
    Header: (
      <Row
        justifyCenter
        style={{
          width: "100%",
          paddingRight: spacing8,
        }}
      >
        <HeaderColumn>Used in</HeaderColumn>
      </Row>
    ),
    Cell: ({ row, layerUsagesInProjects, layerUsagesInPackages }) => {
      const projectUsage = (layerUsagesInProjects[row.original.id] ??
        []) as string[];
      const packetUsage = (layerUsagesInPackages[row.original.id] ??
        []) as DataLibraryPackage[];
      return (
        <Row
          alignCenter
          style={{
            paddingRight: spacing8,
          }}
        >
          <Row
            alignCenter
            style={{
              gap: "0",
            }}
          >
            <DatabaseIcon />
            <UsageContainerInner2
              wrapperStyle={{
                backgroundColor: "unset",
                alignItems: "center",
                gap: spacing4,
                padding: 0,
              }}
              numberStyle={{
                backgroundColor: colors.blue100,
                margin: 0,
                padding: `2px ${spacing4}`,
                borderRadius: typography.regular.fontSize,
              }}
              text={""}
              loadable={RecoilLoadable.of(packetUsage)}
              typeName=""
              placement={UsageContainerPlacement.BOTTOM}
            >
              {packetUsage &&
                packetUsage.map((packet) => (
                  <div key={packet.id}>{packet.name}</div>
                ))}
            </UsageContainerInner2>
          </Row>
          <Row
            alignCenter
            style={{
              gap: "0",
            }}
          >
            <EarthIcon />
            <UsageContainerInner2
              wrapperStyle={{
                backgroundColor: "unset",
                alignItems: "center",
                gap: spacing4,
                padding: 0,
              }}
              numberStyle={{
                backgroundColor: colors.blue100,
                margin: 0,
                padding: `2px ${spacing4}`,
                borderRadius: typography.regular.fontSize,
              }}
              text={""}
              loadable={RecoilLoadable.of(projectUsage)}
              typeName=""
              placement={UsageContainerPlacement.BOTTOM}
            >
              {projectUsage &&
                projectUsage.map((projectId) => (
                  <ProjectInfo key={projectId} projectId={projectId} />
                ))}
            </UsageContainerInner2>
          </Row>
        </Row>
      );
    },
  },
  {
    id: "actions",
    // @ts-ignore
    canSort: false,
    Header: (
      <Row
        justifyCenter
        style={{
          width: "100%",
        }}
      >
        <HeaderColumn>Actions</HeaderColumn>
      </Row>
    ),
    width: 150,
    Cell: ({ row, onEditClick, onReplaceClick, onDeleteClick }) => {
      const editDescriptionBtnRef = useRef<HTMLButtonElement>(null);
      const { editLayerMetadata } = useDataLibraryLayersCrud();
      const [isOpen, toggleIsOpen] = useBooleanState(false);

      return (
        <Row
          alignCenter
          justifyCenter
          style={{
            gap: spacing6,
          }}
        >
          <Tooltip text="Edit layer">
            <IconBtn
              onClick={(e) => {
                e.stopPropagation();
                onEditClick(row.original.id);
              }}
            >
              <IconREMSize height={1.4} width={1.4}>
                <PencilIcon />
              </IconREMSize>
            </IconBtn>
          </Tooltip>
          <Tooltip text="Add to package">
            <AddToPackageButton layerIds={[row.original.id]} />
          </Tooltip>
          <Tooltip
            text={["Descrption:", row.original.description ?? ""]}
            disabled={!row.original.description}
          >
            <IconBtn
              ref={editDescriptionBtnRef}
              onClick={(e) => {
                e.stopPropagation();
                toggleIsOpen();
              }}
              size="1.4rem"
              stroke={row.original.description ? colors.iconInfo : undefined}
              $fill={row.original.description ? colors.iconInfo : undefined}
            >
              <ChatIcon />
            </IconBtn>
          </Tooltip>
          {isOpen && (
            <Anchor baseRef={editDescriptionBtnRef} floatPlace={"topRight"}>
              <EditDescriptionModalFrame
                updateDescription={(description: string) =>
                  editLayerMetadata(row.original.id, { description })
                }
                defaultValue={row.original.description}
                close={toggleIsOpen}
                subtitle={
                  <div>
                    <p>Add a short description to the layer.</p>
                    <p>The description will be visible for project members.</p>
                  </div>
                }
              />
            </Anchor>
          )}
          <DotMenu buttonStyle={{ height: "unset" }}>
            <MenuItem
              name={"Replace layer"}
              icon={<ReplaceIcon />}
              onClick={() => {
                Mixpanel.track("Replace library layer click", {});
                onReplaceClick(row.original.id);
              }}
            />
            <MenuItem
              name={"Download"}
              icon={<DownloadIcon />}
              onClick={() => {
                let fileName = row.original.name.replaceAll(".gz", "");
                if (!fileName.endsWith(".geojson")) {
                  fileName = fileName.concat(".geojson");
                }
                fetchEnhancer(row.original.path, { method: "GET" })
                  .then((result) => result.text())
                  .then((text) => {
                    downloadText(text, fileName);
                  });
              }}
            />
            <MenuItem
              name={"Delete"}
              icon={<BinIcon />}
              onClick={() => {
                Mixpanel.track("Delete library layer click", {});
                onDeleteClick(row.original.id);
              }}
            />
          </DotMenu>
        </Row>
      );
    },
  },
];

const TR = styled.tr<{ isSelected: boolean; shouldFlash: boolean }>`
  @keyframes blink {
    50% {
      background-color: ${colors.blue400};
    }
  }

  cursor: pointer;
  ${({ isSelected }) =>
    isSelected ? `&&& { background-color: ${colors.blue200}; }` : ""}

  ${({ shouldFlash }) => (shouldFlash ? `animation: blink 1s;` : "")};
`;

export type DraggedLayersProps = {
  layerIds: string[];
};

const DraggableLayerRow = React.memo(
  ({
    Row,
    layerId,
    selectedLayers,
  }: {
    Row: React.ReactElement;
    layerId: string;
    selectedLayers: string[];
  }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [{ isDragging: _isDragging }, dragRef] = useDrag<
      DraggedLayersProps,
      void,
      { isDragging: boolean }
    >(
      () => ({
        type: "ORGANISATION_LAYER",
        canDrag: false,
        item: {
          layerIds:
            selectedLayers.length > 0 && selectedLayers.includes(layerId)
              ? selectedLayers
              : [layerId],
        },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
      }),
      [layerId, selectedLayers],
    );

    dragRef(ref);
    return React.cloneElement(Row, {
      ref,
      style: {
        ...Row.props.style,
        opacity: _isDragging ? 0.5 : 1,
      },
    });
  },
);

const DataTable = ({
  layers,
  allLayers,
}: {
  layers: DataLibraryLayer[];
  allLayers: DataLibraryLayer[];
}) => {
  const organisationId = useRecoilValueDef(organisationIdSelector);
  const { deleteLayer, editLayerMetadata } = useDataLibraryLayersCrud();
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const scrollBodyRef = useRef<HTMLDivElement>(null);
  const [selectedLayers, setSelectedLayers] = useRecoilState(
    selectedOrganisationLayerIdsSelector({ organisationId }),
  );
  const [scrollToLayerId, setScrollToLayerId] = useRecoilState(
    scrollToOrganisationLayerIdAtom,
  );

  const allMembersRaw = useRecoilValue(
    usersInOrganisationState(organisationId),
  );

  const getUsername = useCallback(
    (userId: string) => {
      return allMembersRaw.find((m) => m.user_id === userId)?.nickname;
    },
    [allMembersRaw],
  );

  const allIsSelected =
    layers.length > 0 && selectedLayers.length === layers.length;
  const someIsSelected = selectedLayers.length > 0;

  const layerUsagesInProjects = useRecoilValue(
    getDataLayersUsage({
      layerIds: allLayers.map((l) => l.id),
      nodeId: organisationId,
    }),
  );

  const dataPackages = useRecoilValue(
    organisationLibraryDataPackageResourceState({
      organisationId: organisationId,
    }),
  );

  const layerUsagesInPackages = useMemo(
    () =>
      layers.reduce((acc, layer) => {
        const dataPacakgesForLayer = dataPackages.filter((dp) =>
          dp.layerIds.includes(layer.id),
        );
        if (dataPacakgesForLayer.length === 0) return acc;
        return { ...acc, [layer.id]: dataPacakgesForLayer };
      }, {}),
    [dataPackages, layers],
  );

  const lastChanges = useRecoilValueLoadable(
    organisationResourceLastUpdateSelectorFamily({
      organisationId,
      resourceIds: allLayers.map((l) => l.id),
    }),
  );

  const toggleAllSelected = useCallback(() => {
    Mixpanel.track("Toggle all library layers selected", {});
    setSelectedLayers(() => {
      if (allIsSelected) {
        return [];
      }

      return layers.map((p) => p.id);
    });
  }, [setSelectedLayers, allIsSelected, layers]);

  const onEditClick = useCallback(
    (layerId: string) => {
      Mixpanel.track("Edit library layer click", {});
      setModalTypeOpen({
        modalType: EditVectordataType,
        metadata: {
          layerId: layerId,
        },
      });
    },
    [setModalTypeOpen],
  );

  const onReplaceClick = useCallback(
    (layerId: string) => {
      Mixpanel.track("Replace library layer click", {});
      setModalTypeOpen({
        modalType: UploadOrganisationDataLayerModalType,
        metadata: {
          fileIdToReplace: layerId,
        },
      });
    },
    [setModalTypeOpen],
  );

  const onDeleteClick = useCallback(
    (layerId: string) => {
      Mixpanel.track("Delete library layer click", {});
      return deleteLayer(layerId);
    },
    [deleteLayer],
  );

  const getLayerIsSelected = useCallback(
    (layerId: string) => {
      return selectedLayers.includes(layerId);
    },
    [selectedLayers],
  );

  const onToggleLayerSelected = useCallback(
    (layerId: string) => {
      Mixpanel.track("Toggle library layer selected", {});
      setSelectedLayers((curr) => {
        if (curr.includes(layerId)) {
          return curr.filter((p) => p !== layerId);
        } else {
          return [...curr, layerId];
        }
      });
    },
    [setSelectedLayers],
  );

  const onChangeLayerName = useCallback(
    (layerId: string, newName: string) => {
      Mixpanel.track("Change library layer name", {
        nameLength: newName.length,
      });
      return editLayerMetadata(layerId, { name: newName });
    },
    [editLayerMetadata],
  );

  const sortTypes = useMemo<Record<string, SortByFn<DataLibraryLayer>>>(
    () => getSortTypes(lastChanges, getUsername),
    [lastChanges, getUsername],
  );

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    page,
    prepareRow,
    getRowId,
    rows,
    rowsById,
  } = useTable<DataLibraryLayer>(
    {
      data: layers,
      columns: columns,
      sortTypes,
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetSortBy: false,
      initialState: {
        pageSize: 1000,
        pageIndex: 0,
        sortBy: [
          { id: "updated_at", desc: true },
          { id: "created_at", desc: true },
        ],
      },
    },
    useSortBy,
    usePagination,
  );

  // Deselect layers when unmounting
  useEffect(() => {
    return () => {
      setSelectedLayers([]);
      setScrollToLayerId(undefined);
    };
  }, [setSelectedLayers, setScrollToLayerId]);

  // Scroll to layer if scrollToLayerId is set
  useEffect(() => {
    if (scrollToLayerId) {
      const row = rows.find((row) => row.original.id === scrollToLayerId);
      if (!row) {
        return;
      }

      const element = document.querySelector(
        `#data-table tbody [data-layer-id="${scrollToLayerId}"]`,
      );
      if (!element) {
        return;
      }

      scrollBodyRef.current?.scrollTo({
        top:
          element.getBoundingClientRect().top -
          scrollBodyRef.current.getBoundingClientRect().top -
          50,
      });
    }
  }, [scrollToLayerId, rows, rowsById, getRowId, setSelectedLayers]);

  return (
    <ScrollBody ref={scrollBodyRef}>
      <Table
        id="data-table"
        {...getTableProps({
          style: {
            width: "100%",
            borderSpacing: 0,
          },
        })}
      >
        <StickyThead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({
                      onClick: (e) => {
                        if (
                          !column.canSort ||
                          e.nativeEvent
                            .composedPath()
                            .find(
                              (e) =>
                                e instanceof HTMLElement &&
                                e.dataset.type === "checkbox",
                            )
                        ) {
                          return;
                        }

                        Mixpanel.track("Sort library layers", {
                          id: column.id,
                          desc: !column.isSortedDesc,
                        });
                        column.toggleSortBy(!column.isSortedDesc);
                      },
                      style: {
                        textAlign: "unset",
                        cursor: column.canSort ? "pointer" : "unset",
                        width: column.width,
                        padding: "0.5rem 0",
                        borderBottom: `1px solid ${colors.blue400}`,
                      },
                    }),
                  )}
                >
                  <Row alignCenter>
                    {column.render("Header", {
                      allIsSelected,
                      toggleAllSelected,
                      someIsSelected,
                    })}
                    {column.canSort && (
                      <span style={{ marginLeft: "0.3rem" }}>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <SortDescendingIcon />
                          ) : (
                            <SortAscendingIcon />
                          )
                        ) : (
                          <SortNeutralIcon />
                        )}
                      </span>
                    )}
                  </Row>
                </th>
              ))}
            </tr>
          ))}
        </StickyThead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            const isSelected = getLayerIsSelected(row.original.id);

            return (
              <React.Fragment key={row.original.id}>
                <DraggableLayerRow
                  layerId={row.original.id}
                  selectedLayers={selectedLayers}
                  Row={
                    <TR
                      isSelected={isSelected}
                      shouldFlash={scrollToLayerId === row.original.id}
                      data-layer-id={row.original.id}
                      {...row.getRowProps({})}
                      onClick={() => {
                        onToggleLayerSelected(row.original.id);
                      }}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps({
                              style: {
                                wordWrap: "break-word",
                                whiteSpace: "pre-wrap",
                                padding: `${spacing4} 0`,
                              },
                            })}
                          >
                            <React.Suspense fallback={<SkeletonText />}>
                              {cell.render("Cell", {
                                lastChanges: lastChanges.valueMaybe(),
                                organisationId,
                                onEditClick,
                                onReplaceClick,
                                onDeleteClick,
                                isSelected,
                                selectedLayers,
                                onToggleLayerSelected,
                                onChangeLayerName,
                                layerUsagesInProjects,
                                layerUsagesInPackages,
                              })}
                            </React.Suspense>
                          </td>
                        );
                      })}
                    </TR>
                  }
                />
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    </ScrollBody>
  );
};

export default React.memo(DataTable);
