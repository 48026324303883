import styled from "styled-components";
import { StandardBox } from "styles/boxes/Boxes";
import { typography } from "../../../styles/typography";
import { colors } from "../../../styles/colors";
import { Column, Row } from "components/General/Layout";
import { borderRadiusMedium, spaceSmall, spacing4 } from "styles/space";

export const MigrationWarningBox = styled.div`
  border-radius: ${borderRadiusMedium};
  background-color: ${colors.lightYellow};
  padding: 1.8rem 2rem;
  width: fit-content;
  max-width: 55rem;
`;

export const MigrationWarningRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
`;

export const MigrationWarningColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

export const LevelInfoWrapper = styled.div`
  background-color: ${colors.lightBlue};
  padding: 1.5rem 1.2rem;
  border-radius: ${borderRadiusMedium};
  width: fit-content;
  display: flex;
  flex-direction: column;
`;

export const LevelInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
`;

export const LevelInfoTitleWrapper = styled.div`
  ${typography.semiBold}
  fontWeight: 600;
`;

export const ModalWrapper = styled.div`
  padding: 0 3.2rem;
  overflow: hidden;
`;

export const TableHeader = styled.div`
  ${typography.semiBold}
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

export const StyledRow = styled(Row)`
  gap: 0;
  flex: 1 1 1%;

  > * {
    flex: 1;
  }
`;

export const ContentTableColumn = styled(Column)`
  & > :nth-child(2n + 1) {
    background-color: ${colors.focusBackground};
  }
  gap: 0;
`;

export const ContentTableRow = styled(Row)`
  padding: 0.7rem 1.5rem;
  align-items: center;
  min-height: 3.9rem;
  box-sizing: border-box;

  > * {
    flex: 1;
  }
`;

export const MigrateButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
`;

export const ProjectPickerWrapper = styled(StandardBox)`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 1.5rem;
  left: 0;
  max-height: 30rem;
  overflow-y: auto;
  z-index: 1;
  width: fit-content;
  padding-bottom: 0.9rem;
`;

export const ProjectUsageWrapper = styled.div`
  ${typography.contentAndButtons}
  position: relative;
  display: flex;
  flex-direction: row;
  color: ${colors.primary};
  cursor: pointer;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2.2rem;
`;

export const ModalHeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 100%;
`;

export const ModalTitle = styled.div`
  ${typography.h3}
`;

export const ModalSubTitle = styled.div``;

export const Container = styled(Column)<{ disabled?: boolean }>`
  position: relative;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  gap: 1.2rem;
  button {
    opacity: 0;
  }

  p,
  .InnerContainer {
    opacity: ${(p) => (p.disabled ? "0.5" : "inherit")};
  }

  &:hover {
    button {
      opacity: 1;
    }
  }
  transition: all 0.1s ease-in-out;
`;
export const InnerContainer = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  align-items: end;

  border-radius: 0.4rem;
  background: ${colors.focusBackground};
  width: 26.2rem;
  height: 15.9rem;
`;

export const Title = styled.p`
  ${typography.h4}
  margin: 0;
`;

export const BoxText = styled.p`
  ${typography.contentAndButtons}
  margin: 0;
`;
export const Text = styled.p`
  ${typography.contentAndButtons}
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const StyledLi = styled.li`
  ${typography.contentAndButtons}
  margin: 0;
`;
export const StyledLink = styled.a`
  ${typography.contentAndButtons}
  margin: 0;
`;

export const TabContainer = styled(Column)`
  height: 100%;
  width: 100%;
  border-top: 1px solid ${colors.inputOutline};
  box-sizing: border-box;
`;

export const MainRow = styled(Row)`
  height: 100%;
  gap: 0;
`;
export const ResourceColumn = styled(Column)`
  width: 24rem;
  height: 100%;
  padding: 3.2rem 1.2rem 3.2rem 0;
  box-sizing: border-box;
  gap: 2.4rem;
  max-width: 50rem;
  min-width: 24rem;
  border-right: 1px solid ${colors.inputOutline};
`;

export const ResourceSectionDivider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colors.grey100};
  margin: ${spacing4} 0;
`;

export const ResourceSectionHeader = styled.p`
  ${typography.sub3};
`;

export const ResourceName = styled.p`
  ${typography.contentAndButtons}
  margin: 0
`;

export const ResourceItem = styled(Row)<{
  selected: boolean;
  disabled?: boolean;
  isDragHovered?: boolean;
}>`
  align-items: center;
  gap: 1.2rem;
  background: ${(p) =>
    p.selected || p.isDragHovered ? colors.selected : "#fff"};
  width: 100%;
  padding: 0.6rem 1.4rem;
  box-sizing: border-box;
  ${(p) => p.disabled && `opacity: 0.6`};
  ${(p) => !p.disabled && `cursor: pointer`};
  border-radius: 4px;
  &:hover {
    background: ${colors.hover};
  }

  ${({ selected }) =>
    selected &&
    `
    
      ${ResourceName} {
        color: ${colors.blue700};
        font-weight: 600;
      }
    `}
`;

export const ContentColumn = styled(Column)`
  width: 100%;
  height: 100%;
  flex: 1;
  box-sizing: border-box;
  padding: 3.2rem 0 0;
  overflow: hidden;
`;

export const ContentHeaderRow = styled(Row)`
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  padding: 0 1.6rem;
  box-sizing: border-box;
`;

export const ContentHeaderButtonsContainer = styled(Row)`
  align-items: center;
`;

export const ContentHeader = styled.p`
  ${typography.h4}
  margin: 0
`;

export const IconWrapper = styled.div<{ selected?: boolean }>`
  &:hover {
    background: ${(p) => (p.selected ? colors.hoverSelected : colors.hover)};
    cursor: pointer;
  }

  background: ${(p) => p.selected && colors.selected};
  border-radius: ${borderRadiusMedium};
  padding: ${spaceSmall};

  display: flex;
  flex-direction: column;

  svg {
    height: 1.6rem;
    width: 1.6rem;
  }
`;
