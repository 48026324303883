import { SubAreaFeature } from "../../types/feature";
import { TurbineFeature } from "../../types/feature";
import { ParkFeature } from "../../types/feature";
import {
  AdditionalEdgeParametersWithUnit,
  EdgeParametersWithUnit,
  GenerationMethod,
  GenerationMethodAndParametersWithUnit,
  RegularParametersWithUnit,
} from "../../types/turbines";
import * as turf from "@turf/turf";
import {
  calculateEllipseMajorAxisSpacing,
  distanceToZone,
  pointsAreInLine,
  rad2deg,
} from "../../utils/geometry";
import { partition, roundToDecimal, uniquePairs } from "../../utils/utils";
import { defaultRegularParamsWithUnits } from "../../state/turbines";
import { Angle, TurbineDistance } from "../Units/units";

export type Result<T, E> = { ok: true; value: T } | { ok: false; error: E };
export const ok = <T, E>(value: T): Result<T, E> => ({ ok: true, value });
export const err = <T, E>(error: E): Result<T, E> => ({ ok: false, error });

const m = (n: number): TurbineDistance.Of<number> => ({ value: n, unit: "m" });
const deg = (n: number): Angle.Of<number> => ({ value: n, unit: "deg" });

const inferEdgeParameters = (
  turbines: TurbineFeature[],
  zone: SubAreaFeature | ParkFeature,
): Result<AdditionalEdgeParametersWithUnit, string> => {
  // First find all turbines that are close enough to the edge.  Then we find
  // the closest pair of turbines along the edge.  This should be the spacing
  // along the edge.  Last, we confirm that the number of turbines that we
  // expect to be around the edge is there, and that the spacing is consistent.
  // If all of this is okay, we can infer the edge spacing.
  const distances = uniquePairs(turbines)
    .map(([a, b]) => turf.distance(a, b, { units: "meters" }))
    .sort((a, b) => a - b);
  if (2 < distances.length) {
    const probablyTheLengths = distances.slice(0, turbines.length - 1);
    const avg =
      probablyTheLengths.reduce((a, b) => a + b) / probablyTheLengths.length;
    const error =
      probablyTheLengths.map((l) => Math.abs(avg - l)).reduce((a, b) => a + b) /
      probablyTheLengths.length;
    const boundaryLength = zone.geometry.coordinates
      .map((ring) => turf.length(turf.lineString(ring), { units: "meters" }))
      .reduce((a, b) => a + b);
    const expectedTurbinesAlongBoundary = Math.floor(
      boundaryLength / distances[0],
    );
    const actualTurbinesAlongBoundary = turbines.length;

    if (
      error < 100 &&
      Math.abs(expectedTurbinesAlongBoundary - actualTurbinesAlongBoundary) < 3
    )
      return ok({
        edgeSpacing: m(Math.max(100, roundToDecimal(distances[0], 0))),
      });
  }
  return err("too few turbines along the edge");
};

const inferRegularParameters = (
  turbines: TurbineFeature[],
  zone: SubAreaFeature | ParkFeature,
): Result<RegularParametersWithUnit, string> => {
  if (turbines.length < 4) return err("too few turbines");
  const center = turf.centerOfMass(zone);

  const pivot = turbines.reduce((prev, curr) => {
    const prevDist = turf.distance(center, prev);
    const currDist = turf.distance(center, curr);
    return prevDist < currDist ? prev : curr;
  });

  const turbinesByProximity = turbines
    .filter((f) => f.id !== pivot.id)
    .map((t) => ({ t, dist: turf.distance(pivot, t) }))
    .sort((a, b) => a.dist - b.dist)
    .map(({ t }) => t);

  // Since the grid is always generated symetrically, the closest turbines
  // here will come in pairs.  We can use this to infer the grid spacing.
  // However, the third and fourth turbines can either be the first turbines
  // along the secondary axis, or the second turbines along the primary axis.
  // It's annoying to figure out which is which, since the distances could be
  // the same

  const primaryAxis = turbinesByProximity[0];
  let secondaryAxis: TurbineFeature | undefined = undefined;
  for (let i = 1; i < turbinesByProximity.length; i++) {
    secondaryAxis = turbinesByProximity[i];
    if (
      !pointsAreInLine(
        pivot.geometry.coordinates,
        primaryAxis.geometry.coordinates,
        secondaryAxis.geometry.coordinates,
        1e-3,
      )
    )
      break;
  }

  if (!secondaryAxis) return err("no secondary axis");

  const primaryAxisDist = turf.distance(pivot, primaryAxis, {
    units: "meters",
  });
  const secondaryAxisDist = turf.distance(pivot, secondaryAxis, {
    units: "meters",
  });

  if (primaryAxisDist === 0 || secondaryAxisDist === 0) {
    return err("primary or secondary axis too close to pivot");
  }

  let primaryBearing = turf.bearing(pivot, primaryAxis);
  if (primaryBearing < -90) primaryBearing += 180;
  if (90 < primaryBearing) primaryBearing -= 180;

  let secondaryBearing = turf.bearing(pivot, secondaryAxis);
  if (secondaryBearing < -90) secondaryBearing += 180;
  if (90 < secondaryBearing) secondaryBearing -= 180;

  let rotation = (primaryBearing - 90) % 180;
  if (rotation < 0) rotation += 180;

  let obliquity = (secondaryBearing - rotation) % 360;
  if (obliquity < -90) obliquity += 180;
  if (90 < obliquity) obliquity -= 180;

  rotation = (rotation - 90) % 180;
  if (rotation < 0) rotation += 180;

  const nsOffset =
    turf.distance(
      pivot,
      {
        ...pivot,
        geometry: {
          type: "Point",
          coordinates: [
            pivot.geometry.coordinates[0],
            center.geometry.coordinates[1],
          ],
        },
      },
      { units: "meters" },
    ) *
    (pivot.geometry.coordinates[1] < center.geometry.coordinates[1] ? -1 : 1);

  const ewOffset =
    turf.distance(
      pivot,
      {
        ...pivot,
        geometry: {
          type: "Point",
          coordinates: [
            center.geometry.coordinates[0],
            pivot.geometry.coordinates[1],
          ],
        },
      },
      { units: "meters" },
    ) *
    (pivot.geometry.coordinates[0] < center.geometry.coordinates[0] ? -1 : 1);

  if (60 < Math.abs(obliquity)) return err("obliquity too large");

  const shift = secondaryAxisDist * Math.sin(obliquity * (Math.PI / 180));
  const distanceMinorAxis =
    secondaryAxisDist * Math.cos(obliquity * (Math.PI / 180));
  const majorAxisSpacing = calculateEllipseMajorAxisSpacing(
    shift,
    primaryAxisDist,
    distanceMinorAxis,
  );
  if (isNaN(majorAxisSpacing)) {
    return err("could not calculate major axis spacing");
  }
  const angle = rad2deg(Math.atan(shift / majorAxisSpacing));

  return ok({
    setNumberOfTurbines: false,
    numberOfTurbines: 0,
    majorAxisSpacing: m(Math.max(100, roundToDecimal(primaryAxisDist, 0))),
    minorAxisSpacing: m(Math.max(100, roundToDecimal(majorAxisSpacing, 0))),
    obliquity: deg(roundToDecimal(angle, 1)),
    shiftX: m(roundToDecimal(ewOffset, 0)),
    shiftY: m(roundToDecimal(nsOffset, 0)),
    rotate: deg(roundToDecimal(rotation, 1)),
  });
};

export const inferResultToObj = (
  result: RegularParametersWithUnit | EdgeParametersWithUnit,
): GenerationMethodAndParametersWithUnit => {
  if ("edgeSpacing" in result) {
    return {
      method: "edge",
      params: result,
    };
  } else {
    return {
      method: "regular",
      params: result,
    };
  }
};

/**
 * Infers the generation pararmeters that was used to generate the given
 * turbines.
 * @param turbines Turbines to infer the parameters from.
 * @param zone The zone that the turbines are assumed to be generated in.
 *
 * We assume that the regions the turbines are generated from is "nice", meaning
 * that it looks like a real wind park.  We'll use this to assume that the
 * generation didn't have to skip a full row of turbines, and that the rows and
 * columns closest to the center have turbines on them close to the center.
 */
export default function inferTurbineParameters(
  turbines: TurbineFeature[],
  zone: SubAreaFeature | ParkFeature,
  method?: GenerationMethod,
): Result<RegularParametersWithUnit | EdgeParametersWithUnit, string> {
  // Rules about how the generation works:
  // - Rotation=0 means that primary axis is along the x axis, ie. 90 bearing.
  // - Rotation is clockwise
  // - The secondary axis starts as 0 bearing.
  // - Positive obliquity means that the secondary axis is rotated clockwise, making it closer to the primary axis.
  // - Spacing is from the mass center.
  // - Offsets are more to the east and north.
  if (turbines.length === 0) return err("no turbines");

  const [turbinesAlongEdge, turbinesInsidePark] = partition(
    turbines,
    // TODO: should look at turbine type to get the diameter and use this value instead of hard coded 200.
    (t) => distanceToZone(t, zone) < 200,
  );

  const edgeResult = inferEdgeParameters(turbinesAlongEdge, zone);
  if (method === "edge" && !edgeResult.ok)
    return err(
      `edge was forced but could not be inferred: ${edgeResult.error}`,
    );
  const regularResult = inferRegularParameters(turbinesInsidePark, zone);

  const useEdge = edgeResult.ok && (!method || method === "edge");

  if (useEdge && regularResult.ok) {
    const ret: EdgeParametersWithUnit = {
      ...regularResult.value,
      ...edgeResult.value,
    };
    return ok(ret);
  } else if (regularResult.ok) {
    const ret: RegularParametersWithUnit = {
      ...regularResult.value,
    };
    return ok(ret);
  } else if (useEdge) {
    // TODO: This isn't completely right; we should give some regular parameters
    // that are in line with the turbines inside the park.  For instance, if all
    // turbines are along the edge, we should give a offset and turbine spacing
    // so that the center is outside the park and the spacing is big enough for
    // no turbines to be added.  Here we don't to that though, and the park
    // center of mass will get a turbine.
    const ret: EdgeParametersWithUnit = {
      ...defaultRegularParamsWithUnits(),
      ...edgeResult.value,
    };
    return ok(ret);
  } else {
    return err(
      `could not infer parameters: regular error: "${
        regularResult.error
      }" edge error: "${(edgeResult as any)["error"]}"`,
    );
  }
}
