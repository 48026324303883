import React, { useCallback, useMemo, useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import { useDndScrolling } from "react-dnd-scrolling";
import BinIcon from "@icons/24/Bin.svg?react";
import DndSmallIcon from "@icons/12/DnDsmall.svg?react";
import DuplicateAltIcon from "@icons/24/DuplicateAlt.svg?react";
import { colors } from "styles/colors";
import { spacing1, spacing2, spacing7 } from "styles/space";
import { IconREMSize, typography } from "styles/typography";
import { Row } from "components/General/Layout";
import {
  customerProjectAtomFamily,
  projectBranchesAtomFamily,
  useDeleteBranch,
  useDuplicateBranch,
  useSortBranches,
  useUpdateBranch,
} from "state/timeline";
import { BranchMeta } from "types/api";
import { useToast } from "hooks/useToast";
import { useRecoilValueDef } from "utils/recoil";
import {
  branchIdSelector,
  organisationIdSelector,
  projectIdSelector,
} from "state/pathParams";
import { editorAccessProjectSelector } from "state/user";
import useResetMapControls from "hooks/useResetMapControls";
import { useOrganisationNodeCrud } from "components/Projects/useOrganisationFolderCrud";
import { scream } from "utils/sentry";
import { EditableTextInternalState } from "components/General/EditableText";
import Tooltip from "components/General/Tooltip";
import useNavigateToBranch, { getPathToBranch } from "../useNavigateToBranch";
import {
  allBranchesFrameLoadingAtom,
  allBranchesFrameOpenedAtom,
} from "../state";
import {
  BranchTableItemWrapper,
  BranchesTableWrapper,
  VisibleOnHoverIfSortingEnabled,
} from "./style";
import { cursorIsInBottomHalfOfElement } from "utils/dragNDropUtils";

const DuplicateBranchIcon = ({
  nodeId,
  branchId,
}: {
  nodeId: string;
  branchId: string;
}) => {
  const duplicateBranch = useDuplicateBranch();
  const navigateToBranch = useNavigateToBranch();
  const setBranchFrameVisible = useSetRecoilState(allBranchesFrameOpenedAtom);
  const [, setIsCreatingBranch] = useRecoilState(allBranchesFrameLoadingAtom);

  const onDuplicateBranch = useCallback(async () => {
    setIsCreatingBranch(true);
    const res = await duplicateBranch(nodeId, branchId);
    setIsCreatingBranch(false);
    if (!res) return;
    const { meta } = res;
    navigateToBranch(meta.id, true);
    setBranchFrameVisible(false);
  }, [
    setBranchFrameVisible,
    setIsCreatingBranch,
    duplicateBranch,
    nodeId,
    branchId,
    navigateToBranch,
  ]);

  return (
    <IconREMSize
      height={1.2}
      width={1.2}
      title="Duplicate branch"
      hoverFill={colors.blue700}
      onClick={(e) => {
        e.stopPropagation();
        return onDuplicateBranch();
      }}
    >
      <DuplicateAltIcon />
    </IconREMSize>
  );
};

const BRANCH_TABLE_LIST_ITEM = "BRANCH_TABLE_LIST_ITEM";
const BranchTableItem = ({
  branch,
  organisationId,
  nodeId,
  index,
  active,
  enableSorting,
  showControls,
  canDeleteBranch,
  onDragItem,
  onRenameBranch,
  onBranchClick,
  onArchiveBranch,
}: {
  branch: BranchMeta;
  organisationId: string;
  nodeId: string;
  index: number;
  active: boolean;
  enableSorting: boolean;
  showControls: boolean;
  canDeleteBranch: boolean;
  onDragItem(branch: BranchMeta, newIndex: number): void;
  onRenameBranch(branch: BranchMeta, newName: string): void;
  onArchiveBranch(branch: BranchMeta): void;
  onBranchClick(branch: BranchMeta): void;
}) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [hoverState, setHoverState] = useState<undefined | "top" | "bottom">(
    undefined,
  );
  const [isRenaming, setIsRenaming] = useState(false);

  const [, dragRef] = useDrag(
    () => ({
      type: BRANCH_TABLE_LIST_ITEM,
      item: branch,
      canDrag: enableSorting,
    }),
    [branch, enableSorting],
  );

  const [dropCollection, dropRef] = useDrop(
    () => ({
      accept: BRANCH_TABLE_LIST_ITEM,
      canDrop: () => enableSorting,
      hover: (hoveredItem, monitor) => {
        if (!monitor.isOver() || hoveredItem.id === branch.id) {
          return setHoverState(undefined);
        }
        const hoveringBottom = cursorIsInBottomHalfOfElement(
          elementRef.current,
          monitor.getClientOffset(),
        );
        setHoverState(hoveringBottom ? "bottom" : "top");
      },
      collect: (monitor) => {
        const isHovered = monitor.isOver() && monitor.canDrop();
        return {
          isHovered,
        };
      },
      drop: (draggedItem: BranchMeta, monitor: DropTargetMonitor) => {
        const isBottom = cursorIsInBottomHalfOfElement(
          elementRef.current,
          monitor.getClientOffset(),
        );
        const newIndex = isBottom ? index + 1 : index;
        onDragItem?.(draggedItem, newIndex);
      },
    }),
    [branch.id, enableSorting, index, onDragItem],
  );

  dragRef(dropRef(elementRef));

  return (
    <BranchTableItemWrapper
      key={branch.id}
      ref={elementRef}
      onClick={() => {
        onBranchClick(branch);
      }}
      isHoveredTop={dropCollection.isHovered && hoverState === "top"}
      isHoveredBottom={dropCollection.isHovered && hoverState === "bottom"}
      sortingEnabled={enableSorting}
      active={active}
    >
      <Row style={{ alignItems: "center", gap: spacing1 }}>
        <VisibleOnHoverIfSortingEnabled>
          <DndSmallIcon />
        </VisibleOnHoverIfSortingEnabled>
        <EditableTextInternalState
          value={branch.title}
          renderText={(text) => (
            <a
              style={{
                textDecoration: "none",
              }}
              href={getPathToBranch(organisationId, nodeId, branch.id)}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <p className="branch-name" style={typography.contentAndButtons}>
                {text}
              </p>
            </a>
          )}
          textContainerStyle={{
            padding: 0,
          }}
          isEditing={isRenaming}
          onEnter={(newName) => {
            onRenameBranch(branch, newName);
          }}
          onAfter={() => {
            setIsRenaming(false);
          }}
          disabled={!showControls}
        />
      </Row>
      {showControls && (
        <Row style={{ gap: spacing7 }}>
          {active && (
            <div
              style={{
                padding: `${spacing1} ${spacing2}`,
                borderRadius: "4px",
                backgroundColor: colors.blue200,
              }}
            >
              <p style={typography.graphics}>Active</p>
            </div>
          )}
          <Tooltip text="Duplicate branch">
            <React.Suspense
              fallback={
                <IconREMSize height={1.2} width={1.2}>
                  <DuplicateAltIcon />
                </IconREMSize>
              }
            >
              <DuplicateBranchIcon nodeId={nodeId} branchId={branch.id} />
            </React.Suspense>
          </Tooltip>
          <Tooltip
            text={
              canDeleteBranch ? "Delete branch" : "Can not delete last branch"
            }
          >
            <IconREMSize
              height={1.2}
              width={1.2}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (!canDeleteBranch) {
                  return;
                }
                onArchiveBranch(branch);
              }}
              hoverStroke={canDeleteBranch ? colors.blue700 : undefined}
              stroke={!canDeleteBranch ? colors.grey400 : undefined}
              style={{
                cursor: canDeleteBranch ? "pointer" : "not-allowed",
              }}
            >
              <BinIcon />
            </IconREMSize>
          </Tooltip>
        </Row>
      )}
    </BranchTableItemWrapper>
  );
};

const BranchesTable = ({
  enableSorting,
  branches,
}: {
  enableSorting: boolean;
  branches: BranchMeta[];
}) => {
  const tableElementRef = useRef<HTMLDivElement>(null);
  const setBranchFrameVisible = useSetRecoilState(allBranchesFrameOpenedAtom);
  const { info: showInfo, error: showError } = useToast();
  useDndScrolling(tableElementRef, {});

  const navigateToBranch = useNavigateToBranch();
  const { sortBranches } = useSortBranches();
  const organisationId = useRecoilValueDef(organisationIdSelector);
  const nodeId = useRecoilValueDef(projectIdSelector);
  const branchId = useRecoilValueDef(branchIdSelector);
  const branchMetaObjects = useRecoilValue(
    projectBranchesAtomFamily({ nodeId }),
  );
  const editorAccessProject = useRecoilValue(editorAccessProjectSelector);

  const resetMapControls = useResetMapControls();

  const selectedBranch = useMemo(
    () => branchMetaObjects.find((b) => b.id === branchId),
    [branchId, branchMetaObjects],
  );

  const project = useRecoilValue(customerProjectAtomFamily({ nodeId }));

  const [, setIsLoading] = useRecoilState(allBranchesFrameLoadingAtom);
  const deleteBranch = useDeleteBranch();
  const { update: updateNode } = useOrganisationNodeCrud();

  const updateBranch = useUpdateBranch();
  const renameBranch = useCallback(
    async (branchMeta: BranchMeta, name: string) => {
      if (!branchMeta) return;
      if (name === branchMeta?.title || name.length === 0) {
        return;
      }

      await updateBranch({ ...branchMeta, title: name });
    },
    [updateBranch],
  );

  const onArchiveBranch = useCallback(
    async (branchMeta: BranchMeta) => {
      if (branchMetaObjects.length < 2) {
        showInfo("Can not archive last branch", {
          timeout: 2000,
          showCountdown: false,
        });
        return;
      }

      if (
        window.confirm(
          `Are you sure you want to delete the branch ${branchMeta.title} with all its snapshots?`,
        )
      ) {
        setIsLoading(true);
        try {
          const promise = deleteBranch(branchMeta, nodeId);
          if (branchMeta.id === selectedBranch?.id) {
            resetMapControls();
            const selectedBranchId = branchMetaObjects.filter(
              (b) => b.id !== branchId,
            )[0].id;

            // update main branch id if that is the one that was deleted
            if (project?.main_branch_id === branchMeta.id) {
              await updateNode(project.id, {
                main_branch_id: selectedBranchId,
              });
            }

            navigateToBranch(selectedBranchId, false);
          }

          await promise;
        } catch (err) {
          if (err instanceof Error) {
            scream(err, {
              nodeId,
            });
          } else {
            scream("Failed to remove branch", {
              nodeId,
              err,
            });
          }
          showError("Failed to remove branch, please try again.");
        } finally {
          setIsLoading(false);
        }
      }
    },
    [
      branchMetaObjects,
      showInfo,
      setIsLoading,
      deleteBranch,
      nodeId,
      selectedBranch?.id,
      resetMapControls,
      project?.main_branch_id,
      project?.id,
      navigateToBranch,
      branchId,
      updateNode,
      showError,
    ],
  );

  const onDragItem = useCallback(
    (item: BranchMeta, newIndex: number) => {
      const currIndex = branchMetaObjects.findIndex((b) => b.id === item.id);
      if (currIndex === -1 || currIndex === newIndex) {
        return;
      }
      const newBranchIds = branchMetaObjects.map((b) => b.id);
      newBranchIds.splice(currIndex, 1);
      const adjustedNewIndex = newIndex > currIndex ? newIndex - 1 : newIndex;
      newBranchIds.splice(adjustedNewIndex, 0, item.id);
      return sortBranches(newBranchIds);
    },
    [branchMetaObjects, sortBranches],
  );

  return (
    <BranchesTableWrapper ref={tableElementRef}>
      {branches.map((branch, index) => (
        <BranchTableItem
          key={branch.id}
          index={index}
          active={branch.id === branchId}
          canDeleteBranch={branches.length > 1}
          organisationId={organisationId}
          nodeId={nodeId}
          branch={branch}
          enableSorting={enableSorting && editorAccessProject}
          showControls={editorAccessProject}
          onRenameBranch={renameBranch}
          onDragItem={onDragItem}
          onArchiveBranch={onArchiveBranch}
          onBranchClick={() => {
            navigateToBranch(branch.id, true);
            setBranchFrameVisible(false);
          }}
        />
      ))}
    </BranchesTableWrapper>
  );
};

export default BranchesTable;
