import { atomFamily, selector, selectorFamily } from "recoil";
import { z } from "zod";
import { branchIdSelector, projectIdSelector } from "../../state/pathParams";
import { ProjectFeature, _FeatureParser } from "../../types/feature";
import { projectVersionAtom } from "../../state/project";
import { findParkChildren } from "../../state/projectLayers";
import { isDefined } from "../../utils/predicates";
import {
  BranchKeyVersions,
  FeatureVersion,
  getBranchFeatures,
  getBranchKeyVersions,
  getFeatureVersions,
} from "./service";

export type UndoRedoAction = {
  add?: ProjectFeature[];
  remove?: string[];
  update?: ProjectFeature[];
};
export type UndoRedo = {
  action: UndoRedoAction;
  originAction: UndoRedoAction;
};

type HistorySource = {
  projectId: string;
  branchId: string;
};

export const undoState = atomFamily<UndoRedo[], HistorySource | undefined>({
  key: "undoState",
  default: [],
});
export const redoState = atomFamily<UndoRedo[], HistorySource | undefined>({
  key: "redoState",
  default: [],
});

export const _SafeParseObjectWithFeatureArray = z.object({
  features: z
    .unknown()
    .array()
    .transform((a) =>
      a
        .map((e) => {
          const res = _FeatureParser.safeParse(e);
          if (!res.success) return undefined;
          return res.data;
        })
        .filter(isDefined),
    ),
});

export const projectFeaturesState = atomFamily<
  ProjectFeature[],
  {
    projectId: string;
    branchId: string;
    version?: number;
  }
>({
  key: "projectFeaturesState",
  default: selectorFamily({
    key: "projectFeaturesDefaultSelector",
    get: (source) => async () => {
      if (!source) return [];

      const res = await getBranchFeatures(
        source.projectId,
        source.branchId,
        source.version,
      );
      return _SafeParseObjectWithFeatureArray.parse(res).features;
    },
  }),
  dangerouslyAllowMutability: true,
});

export const projectFeatureVersionsSelector = selectorFamily<
  FeatureVersion[],
  {
    projectId: string;
    branchId: string;
    featureId: string;
  }
>({
  key: "projectFeatureVersionsSelector",
  get:
    ({ projectId, branchId, featureId }) =>
    async () => {
      const res = await getFeatureVersions(projectId, branchId, featureId);
      return (res ?? []).sort((a, b) => b.createdAt - a.createdAt);
    },
});

export const projectFeatureSelector = selectorFamily<
  ProjectFeature | undefined,
  { featureId: string }
>({
  key: "projectFeatureSelector",
  get:
    ({ featureId }) =>
    ({ get }) => {
      return get(projectFeaturesSelector).find(
        (feature) => feature.id === featureId,
      );
    },
});

export const projectFeaturesSelector = selector<ProjectFeature[]>({
  key: "projectFeaturesSelector",
  get: ({ get }) => {
    const projectId = get(projectIdSelector);
    const branchId = get(branchIdSelector);
    if (!projectId || !branchId) return [];
    const version = get(projectVersionAtom({ projectId, branchId }));
    return get(projectFeaturesState({ projectId, branchId, version }));
  },
});

export const parkChildrenSelector = selectorFamily<
  ProjectFeature[],
  { parkId: string }
>({
  key: "parkChildrenSelector",
  get:
    ({ parkId }) =>
    ({ get }) => {
      const projectFeatures = get(projectFeaturesSelector);
      return findParkChildren(projectFeatures, parkId);
    },
});

export const projectFeaturesInBranchSelectorFamily = selectorFamily<
  ProjectFeature[],
  { branchId: string }
>({
  key: "projectFeaturesInBranchSelectorFamily",
  get:
    ({ branchId }) =>
    ({ get }) => {
      const projectId = get(projectIdSelector);
      if (!projectId) return [];
      const version = get(projectVersionAtom({ projectId, branchId }));
      return get(projectFeaturesState({ projectId, branchId, version }));
    },
});

export const listKeySnapshotsInBranch = selectorFamily<
  BranchKeyVersions[],
  {
    projectId: string;
    branchId: string;
  }
>({
  key: "listKeySnapshotsInBranch",
  get: (o) => () => {
    return getBranchKeyVersions(o.projectId, o.branchId);
  },
});
