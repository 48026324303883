import { getPark } from "components/ProductionV2/state";
import { useRecoilValue } from "recoil";
import { FinanceId } from "components/Finance/state";
import { DownloadCSV } from "components/CsvDownload/CsvDownloadButton";
import { useLcoe } from "components/Finance/lcoe";
import { CostUnit } from "types/financial";
import { currentYear } from "utils/inflation";

export const DownloadLcoeDataButton = ({
  id,
  buttonType,
}: {
  id: FinanceId;
  buttonType: "text" | "menu";
}) => {
  const {
    results: { cashFlow, lcoe },
  } = useLcoe(id);
  const park = useRecoilValue(getPark(id));

  const columns = [
    { header: "Type", field: "type" },
    { header: "Year", field: "year" },
    { header: "Value", field: "value" },
    { header: "Unit", field: "unit" },
  ];

  const getData = () =>
    Promise.all([cashFlow.toPromise(), lcoe.toPromise()]).then(([cf, lcoe]) => {
      // NOTE: loadables may be undefined when promises resolve
      if (!cf || !lcoe) return undefined;

      return [
        ...cf,
        {
          year: currentYear(),
          value: lcoe.toFixed(1),
          type: "LCoE",
          unit: CostUnit.euroPerMWh,
        },
      ];
    });

  const filename = `lcoe-${park.properties.name}-${new Date().toISOString()}.csv`;

  return (
    <DownloadCSV
      data={getData}
      columns={columns}
      filename={filename}
      buttonType={buttonType}
    />
  );
};
