/// <reference types="vite-plugin-svgr/client" />
import styled from "styled-components";
import MoveLeftIcon from "@icons/24/MoveLeft.svg?react";
import Tooltip from "./Tooltip";
import { IconBtn } from "./Icons";

const TRANSITION_TIME = "0.1s";

export const Container = styled.div<{ open: boolean }>`
  box-shadow: 0 3px 12px rgba(51, 51, 51, 0.08);
  z-index: 1;

  svg {
    transition: transform ${TRANSITION_TIME} linear;

    ${({ open }) => !open && `transform: rotate(-180deg);`}
  }
`;

export default function OpenOrCollapse({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
}) {
  return (
    <Container open={isOpen}>
      <Tooltip text={isOpen ? "Collapse" : "Expand"}>
        <IconBtn onClick={() => setIsOpen(!isOpen)}>
          <MoveLeftIcon />
        </IconBtn>
      </Tooltip>
    </Container>
  );
}
