import { SVGProps, useCallback, useEffect, useRef, useState } from "react";
import styled, { CSSProperties } from "styled-components";
import { colors } from "styles/colors";
import AngleIcon from "@icons/24/RotateAngle.svg?react";
import BinIcon from "@icons/24/Bin.svg";
import {
  EditableTextInternalState,
  TextContainer,
} from "components/General/EditableText";
import { borderRadius } from "styles/space";
import { typography } from "styles/typography";
import { deg2rad, rad2deg } from "utils/geometry";
import { min } from "utils/utils";
import { Sector } from "lib/sector";
import Button from "components/General/Button";
import React from "react";

const CIRCLE_RADIUS = 40;
const MOVE_TICK_THRESHOLD = 5;

type State =
  | { type: "default" }
  | { type: "drag"; middle: number; maxSpan: number }
  | { type: "move"; index: number; offset: number }
  | { type: "select"; index: number };

const Wrapper = styled.div<{
  $place: boolean;
  $drag: boolean;
}>`
  position: relative;
  aspect-ratio: 1 / 1;
  user-select: none; // disable text highlight when mouse dragging

  .sector:hover {
    fill: ${colors.red300};
    cursor: pointer;
  }

  .sector[data-selected="true"] {
    fill: ${colors.red400};
  }

  .textbox {
    position: absolute;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;

    ${TextContainer} {
      gap: 0;
      padding-right: 0;
    }

    background: ${colors.surfaceSelectedLight};
    border-radius: ${borderRadius.small};

    gap: 0.4rem;
    padding: 0.3rem 0.6rem;

    ${typography.graphics};
    font-variant-numeric: tabular-nums;
    min-width: 4ch;
    text-align: end;
    color: ${colors.textBrand};
    white-space: nowrap;

    svg {
      overflow: visible;
      height: 1rem;
      width: 1rem;
      path {
        stroke: ${colors.iconSubtle};
      }
    }
  }
`;

const SvgBackgroundCircle = (props: SVGProps<SVGCircleElement>) => (
  <circle
    {...props}
    cx="50"
    cy="50"
    r={CIRCLE_RADIUS}
    fill={colors.surfaceSecondary}
    strokeWidth="0.5"
    stroke={colors.borderDefault}
  />
);

const SvgMooring = ({ angles }: { angles: number[] }) => {
  return angles.map((a, i) => {
    const x = Math.cos(a - Math.PI / 2) * CIRCLE_RADIUS + 50;
    const y = Math.sin(a - Math.PI / 2) * CIRCLE_RADIUS + 50;
    return (
      <React.Fragment key={i}>
        <line
          x1={50}
          y1={50}
          x2={x}
          y2={y}
          strokeWidth={0.5}
          stroke={colors.mooringLine}
          strokeLinecap="round"
          style={{ opacity: 0.5 }}
        />
        <circle
          cx={x}
          cy={y}
          r={1.5}
          fill={colors.anchor}
          style={{ opacity: 0.5 }}
        />
      </React.Fragment>
    );
  });
};

const SvgHoverGuide = ({ angle }: { angle: number }) => {
  const x = Math.cos(angle - Math.PI / 2) * CIRCLE_RADIUS + 50;
  const y = Math.sin(angle - Math.PI / 2) * CIRCLE_RADIUS + 50;
  return (
    <line
      x1={50}
      y1={50}
      x2={x}
      y2={y}
      strokeWidth={0.5}
      stroke={colors.blue800}
      strokeLinecap="round"
    />
  );
};

const SvgSector = ({
  span,
  angle,
  preview,
  ghost,
  onClick,
  ...props
}: {
  span: number;
  angle: number;
  preview?: boolean;
  ghost?: boolean;
  onClick?: SVGProps<SVGPathElement>["onClick"];
} & SVGProps<SVGPathElement>) => {
  const large = Math.PI < span ? 1 : 0;

  const a0 = angle - Math.PI / 2 - span / 2;
  const a1 = angle - Math.PI / 2 + span / 2;

  const x0 = Math.cos(a0) * CIRCLE_RADIUS + 50;
  const x1 = Math.cos(a1) * CIRCLE_RADIUS + 50;
  const y0 = Math.sin(a0) * CIRCLE_RADIUS + 50;
  const y1 = Math.sin(a1) * CIRCLE_RADIUS + 50;

  let style: CSSProperties = {};
  if (preview) {
    style.pointerEvents = "none";
    style.opacity = 0.5;
  }
  if (ghost) {
    style.pointerEvents = "none";
    style.filter = "grayscale(100%)";
    style.opacity = 0.25;
  }

  return (
    <path
      {...props}
      d={`M 50,50 L ${x1},${y1} A ${CIRCLE_RADIUS} ${CIRCLE_RADIUS} 0 ${large} 0 ${x0},${y0} L 50,50`}
      className="sector"
      stroke={colors.red500}
      fill={colors.red200}
      strokeLinecap="round"
      onClick={onClick}
      style={style}
    />
  );
};

const SpanInputField = ({
  radius,
  offset,
  sector,
  onChange,
}: {
  radius: number;
  offset: number;
  sector: Sector;
  onChange: (s: Sector) => void;
}) => {
  const [text1, setText1] = useState(rad2deg(sector.span).toFixed(2));
  const x = Math.sin(sector.middle) * radius + offset;
  const y = -Math.cos(sector.middle) * radius + offset;

  return (
    <>
      <div className="textbox" style={{ top: y, left: x }}>
        <AngleIcon />
        <EditableTextInternalState
          renderText={(t) => `${t}°`}
          value={text1}
          onEnter={(a) => {
            let angle = parseFloat(a);
            if (isNaN(angle)) return;
            setText1(angle.toFixed(2));
            angle = deg2rad(angle);
            onChange(new Sector(sector.middle, angle));
          }}
        />
      </div>
    </>
  );
};

export const Editor = ({
  sectors,
  setSectors,
  ghostAngles,
  allGhost,
}: {
  sectors: Sector[];
  setSectors: (s: Sector[]) => void;
  ghostAngles?: number[] | undefined;
  allGhost: boolean;
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);

  const [previewSector, setPrevievSector] = useState<Sector | undefined>(
    undefined,
  );

  const [state, setState] = useState<State>({ type: "default" });
  const [shift, setShift] = useState<boolean>(false);
  const [mouseAngle, setMouseAngle] = useState<number>(0);
  const moveCounter = useRef<number>(0); // ticks we've been dragging. If this is small when onClick fires, we've clicked. If not, we've been dragging.

  const getMouseAngle = useCallback(
    (e: MouseEvent | React.MouseEvent): number => {
      if (!svgRef.current || !divRef.current) return 0;
      const divRect = divRef.current?.getBoundingClientRect();
      const inX = e.clientX - divRect.x;
      const inY = e.clientY - divRect.y;
      const svgX = (inX / divRect.width) * 100;
      const svgY = (inY / divRect.height) * 100;
      const y = svgY - 50;
      const x = svgX - 50;
      let angle = Math.atan2(y, x) + Math.PI / 2;
      if (angle < 0) angle += 2 * Math.PI;
      return angle;
    },
    [],
  );

  const mousemove = useCallback(
    (e: MouseEvent) => {
      if (state.type === "drag") {
        const angle = getMouseAngle(e);
        let diff = Math.abs(angle - mouseAngle);
        if (Math.PI < diff) diff = Math.PI * 2 - diff;
        let span = Math.min(diff * 2, state.maxSpan ?? Math.PI);
        setPrevievSector(new Sector(state.middle, span));
      } else if (state.type === "move") {
        const angle = getMouseAngle(e);
        const i = state.index;
        const s = new Sector(angle - state.offset, sectors[i].span);
        moveCounter.current += 1;
        setSectors(
          sectors
            .slice(0, i)
            .concat([s])
            .concat(sectors.slice(i + 1)),
        );
      } else {
        setMouseAngle(getMouseAngle(e));
      }
    },
    [getMouseAngle, mouseAngle, sectors, setSectors, state],
  );

  const mousedown = useCallback(
    (e: MouseEvent) => {
      if (state.type === "default" || state.type === "select") {
        const angle = getMouseAngle(e);
        const withDist = sectors.map((s) => ({ s, d: s.distance(angle) }));
        const closest = min(withDist, (o) => o.d);
        if (!closest || allGhost) {
          if (e.shiftKey)
            setState({
              type: "drag",
              middle: angle,
              maxSpan: 2 * Math.PI,
            });
        } else if (0 < closest.d) {
          if (e.shiftKey)
            setState({
              type: "drag",
              middle: angle,
              maxSpan: 2 * closest.d,
            });
        }
      }
    },
    [allGhost, getMouseAngle, sectors, state.type],
  );

  const mouseup = useCallback(
    (_: MouseEvent) => {
      if (state.type === "drag") {
        if (previewSector) {
          if (allGhost) setSectors([previewSector]);
          else setSectors(sectors.concat([previewSector]));
        }
        setState({ type: "default" });
      }
      setPrevievSector(undefined);
    },
    [state.type, previewSector, allGhost, setSectors, sectors],
  );

  const keydown = useCallback(
    (e: KeyboardEvent) => {
      if (
        state.type === "select" &&
        e.key === "Backspace" &&
        e.target === document.body
      ) {
        setSectors(
          sectors.slice(0, state.index).concat(sectors.slice(state.index + 1)),
        );
        setState({ type: "default" });
        e.stopImmediatePropagation();
      }
      setShift(e.shiftKey);
    },
    [sectors, setSectors, state],
  );

  const keyup = useCallback((e: KeyboardEvent) => {
    setShift(e.shiftKey);
  }, []);

  useEffect(() => {
    window.addEventListener("mousemove", mousemove);
    window.addEventListener("mouseup", mouseup);
    window.addEventListener("mousedown", mousedown);
    window.addEventListener("keydown", keydown, { capture: true });
    window.addEventListener("keyup", keyup);
    return () => {
      window.removeEventListener("mousemove", mousemove);
      window.removeEventListener("mouseup", mouseup);
      window.removeEventListener("mousedown", mousedown);
      window.removeEventListener("keydown", keydown, { capture: true });
      window.removeEventListener("keyup", keyup);
    };
  }, [mouseup, mousemove, mousedown, keydown, keyup]);

  const [{ width }, setSize] = useState({ width: 1 });
  useEffect(() => {
    if (!svgRef.current) return;
    const observer = new ResizeObserver(() => {
      if (!svgRef.current) return;
      const { width } = svgRef.current.getBoundingClientRect();
      setSize({ width });
    });
    observer.observe(svgRef.current);
    return () => observer.disconnect();
  }, []);
  const textboxDist = (width / 100) * (CIRCLE_RADIUS + 5);

  return (
    <>
      <Wrapper ref={divRef} $place={false} $drag={false}>
        <svg ref={svgRef} viewBox="0 0 100 100">
          <SvgBackgroundCircle onClick={() => setState({ type: "default" })} />
          {sectors.map((s, i) => (
            <SvgSector
              angle={s.middle}
              span={s.span}
              key={i}
              data-selected={state.type === "select" && state.index === i}
              onMouseDown={(e) => {
                const angle = getMouseAngle(e);
                moveCounter.current = 0;
                setState({
                  type: "move",
                  index: i,
                  offset: angle - s.middle,
                });
              }}
              onClick={() => {
                const isClick = moveCounter.current < MOVE_TICK_THRESHOLD;
                if (isClick) {
                  setState({
                    type: "select",
                    index: i,
                  });
                } else {
                  setState({ type: "default" });
                }
              }}
              ghost={!!allGhost}
            />
          ))}

          {ghostAngles && <SvgMooring angles={ghostAngles} />}

          {previewSector && (
            <SvgSector
              angle={previewSector.middle}
              span={previewSector.span}
              preview
            />
          )}

          {shift && <SvgHoverGuide angle={mouseAngle} />}

          <circle cx="50" cy="50" r="3.5" className="turbine" />
        </svg>

        {state.type === "select" && state.index < sectors.length && (
          <SpanInputField
            sector={sectors[state.index]}
            radius={textboxDist - 20}
            offset={width / 2}
            onChange={(s) => {
              setSectors(
                sectors
                  .slice(0, state.index)
                  .concat([s])
                  .concat(sectors.slice(state.index + 1)),
              );
            }}
          />
        )}
      </Wrapper>

      {state.type === "select" && state.index < sectors.length && (
        <Button
          icon={<BinIcon />}
          buttonType="secondary"
          onClick={() => {
            setSectors(
              sectors
                .slice(0, state.index)
                .concat(sectors.slice(state.index + 1)),
            );
            setState({ type: "default" });
          }}
          text="Delete"
        />
      )}
    </>
  );
};
