import { useRecoilState, useRecoilValue } from "recoil";
import { activeHintAtom } from "../../../state/hint";
import { userHintSettingsAtom } from "./state";
import { useCallback } from "react";
import { AllHints } from "../AllHints";
import { usePutUserHintSettings } from "./hooks";

const CloseableHints = () => {
  const [activeHint, setActiveHint] = useRecoilState(activeHintAtom);
  const userSettings = useRecoilValue(userHintSettingsAtom);
  const putUserHintSettings = usePutUserHintSettings();

  const close = useCallback(() => {
    setActiveHint(undefined);
  }, [setActiveHint]);

  const gotIt = useCallback(() => {
    if (!activeHint) return;
    close();
    return putUserHintSettings({
      dontShowTipIds: [...(userSettings.dontShowTipIds ?? []), activeHint],
    });
  }, [activeHint, close, putUserHintSettings, userSettings.dontShowTipIds]);

  if (
    !activeHint ||
    userSettings.hideAllTips ||
    userSettings.dontShowTipIds?.includes(activeHint)
  )
    return null;
  return (
    <AllHints hintId={activeHint} closeCallback={close} gotItCallback={gotIt} />
  );
};

export default CloseableHints;
