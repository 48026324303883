/// <reference types="vite-plugin-svgr/client" />
import React, {
  ReactNode,
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { getShareUrl } from "../../hooks/useOpenModal";
import { ScrollBody } from "../../hooks/useShowScrollShadow";
import { useToast } from "../../hooks/useToast";
import AddIcon from "@icons/24/Add.svg?react";
import DeleteIcon from "@icons/24/Bin.svg?react";
import CostIcon from "@icons/24/Cost.svg?react";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";
import FlashIcon from "@icons/24/Flash.svg?react";
import GraphIcon from "@icons/24/Graph.svg?react";
import HyperlinkIcon from "@icons/24/Hyperlink.svg?react";
import SettingsIcon from "@icons/24/Settings.svg?react";
import TurbineIcon from "@icons/24/Turbine.svg?react";
import FoundationIcon from "@icons/24/Foundation.svg?react";
import MooringIcon from "@icons/24/Anchor.svg?react";
import { Configuration } from "../../services/configurationService";
import { WindSourceConfiguration } from "../../services/windSourceConfigurationService";
import {
  branchSelectedConfigurationAtomFamily,
  libraryAndProjectConfigurationsSelectorFamily,
} from "../../state/configuration";
import { DashboardModalType, modalTypeOpenAtom } from "../../state/modal";
import { getParkFeatureSelectorFamily } from "../../state/park";
import {
  branchIdSelector,
  parkIdSelector,
  projectIdSelector,
  useTypedPath,
} from "../../state/pathParams";
import { getBranchSelectorFamily } from "../../state/timeline";
import { editorAccessProjectSelector } from "../../state/user";
import {
  selectedWindConfigurationAtomFamily,
  windSourceConfigurationsAtomFamily,
} from "../../state/windSourceConfiguration";
import { SkeletonBlock } from "../Loading/Skeleton";
import { spaceHuge, spaceLarge, spaceTiny, spacing8 } from "../../styles/space";
import { BranchMeta } from "../../types/api";
import { ParkFeature } from "../../types/feature";
import Button from "../General/Button";
import DnDGrid from "../General/DnDGrid/DnDGrid";
import { EditableTextInternalState } from "../General/EditableText";
import { Column, Row } from "../General/Layout";
import Tooltip from "../General/Tooltip";
import { EditableTextH3Wrapper } from "../Projects/styles";
import SettingsMenuItem from "../SettingsV2/Shared/SettingsMenuItem";
import {
  SettingsSubMenuCustomMenuList,
  SettingsSubMenuCustomMenuListContext,
} from "../SettingsV2/Shared/SettingsSubMenu";
import { selectedMenuItemState } from "../SettingsV2/Shared/state";
import { ContentContainer } from "../SettingsV2/Shared/styles";
import { ConfigurationSelector } from "./ConfigurationSelector";
import {
  CenterGrayMessage,
  DropdownList,
  VDivider,
  TextIcon,
  CollapseWithButton,
  HeaderWrapper,
  WidgetNewPlaceholder,
  DashboardColumn,
} from "./Dashboard.style";
import { ParkSelector } from "./ParkSelector";
import { WidgetSelector } from "./WidgetSelector";
import { WindSourceConfigurationSelector } from "./WindSourceConfigurationSelector";
import { useDashboard } from "./hooks";
import { VIND_DASHBOARDS, dashboardsAtomFamily } from "./state";
import { Dashboard, WidgetId, widgetIdRequireFeatureFlag } from "./types";
import { z } from "zod";
import {
  branchSelectedCostConfigurationAtom,
  libraryAndProjectCostConfigurationsSelectorFamily,
} from "../../state/costConfigurations";
import { CostConfiguration } from "../../services/costService";
import { CostConfigurationSelector } from "./CostConfigurationSelector";
import { useAllFeatureFlags } from "components/General/FeatureFlag";
import {
  TriggerDashboardFinanceAndProduction,
  currentParkTriggerId,
} from "components/Finance/Triggers";
import {
  ElectricalStatError,
  FoundationStatError,
  ParkHasAnyValidationError,
  ProductionError,
} from "components/ValidationWarnings/FeatureSpecificErrors";
import { ProdId } from "components/ProductionV2/state";
import { FinanceId } from "components/Finance/state";
import TopBarModal, {
  TopBarModalHeader,
} from "components/FullScreenModal/TopBarModal";
import { useRecoilValueDef } from "utils/recoil";
import { typography } from "styles/typography";
import { colors } from "styles/colors";
import { Mixpanel } from "mixpanel";

/** The preset dashboards have designated icons */
const _PresetDashboardIcon: Record<
  (typeof VIND_DASHBOARDS)[number]["id"],
  ReactNode
> = {
  "vind-preset-dashboard-overview": <GraphIcon />,
  "vind-preset-dashboard-yield": <TurbineIcon />,
  "vind-preset-dashboard-electrical": <FlashIcon />,
  "vind-preset-dashboard-cost": <CostIcon />,
  "vind-preset-dashboard-mooring": <MooringIcon />,
  "vind-preset-dashboard-foundation": <FoundationIcon />,
};

const DEFAULT_SELECTION_SP = "dashboardIds";
const _DefaultSelection = z.object({
  /** Branch */
  b: z.string().optional(),
  /** Park */
  p: z.string().optional(),
  /** Configuration */
  c: z.string().optional(),
  /** Wind configuration */
  w: z.string().optional(),
  /** Cost configuration */
  f: z.string().optional(),
});

type DefaultSelection = {
  branchId?: string;
  parkId?: string;
  configurationId?: string;
  windConfigurationId?: string;
  costConfigurationId?: string;
};

export type DashboardContextType = {
  projectId: string;
  organisationId: string;
  branch: BranchMeta;
  park: ParkFeature;
  configuration: Configuration;
  windConfiguration: WindSourceConfiguration;
  costConfiguration?: CostConfiguration;
  dashboard: Dashboard;
  canEdit?: boolean;

  addWidget: (id: WidgetId) => void;
  removeWidget: (id: WidgetId) => void;

  /** Hide dot menus and help texts. */
  noMenus?: boolean;

  /**
   * ID for finance and production triggers. This ensures that all widgets show
   * data from the same source.
   */
  triggerId: FinanceId & ProdId;
};

export const DashboardContext = React.createContext<
  DashboardContextType | undefined
>(undefined);

const H4Collapsible = ({
  text,
  collapsed,
}: {
  text: string;
  collapsed?: boolean;
}) => {
  if (collapsed) return <h4 style={{ marginLeft: spaceLarge }}>&mdash;</h4>;
  return (
    <h4 style={{ marginLeft: spaceLarge, whiteSpace: "nowrap" }}>{text}</h4>
  );
};

const DashboardComponentFallback = () => (
  <Column
    style={{
      flex: 1,
      alignItems: "center",
      padding: spaceHuge,
    }}
  >
    <SkeletonBlock style={{ height: "1rem", width: "100%" }} />
    <SkeletonBlock style={{ height: "1rem", width: "100%" }} />
    <SkeletonBlock style={{ height: "1rem", width: "100%" }} />
  </Column>
);

const DashboardStatError = ({
  parkId,
  branchId,
  dashboardId,
}: {
  parkId: string | undefined;
  branchId: string | undefined;
  dashboardId: string;
}) => {
  if (!parkId || !dashboardId || !branchId) return null;
  switch (dashboardId) {
    case "vind-preset-dashboard-electrical":
      return <ElectricalStatError parkId={parkId} branchId={branchId} />;
    case "vind-preset-dashboard-overview" || "vind-preset-dashboard-yield":
      return <ProductionError parkId={parkId} branchId={branchId} />;
    case "vind-preset-dashboard-cost":
      return <ParkHasAnyValidationError parkId={parkId} branchId={branchId} />;
    case "vind-preset-dashboard-foundation" || "vind-preset-dashboard-mooring":
      return <FoundationStatError parkId={parkId} branchId={branchId} />;
    default:
      return null;
  }
};

export const useDashboardContext = (): DashboardContextType => {
  const ctx = useContext(DashboardContext);
  if (ctx === undefined) throw new Promise(() => {});
  return ctx;
};

export const DashboardComponent = ({
  dashboard,
  canEdit,
  defaultSelection,
}: {
  dashboard: Dashboard;
  canEdit?: boolean;
  defaultSelection?: DefaultSelection;
}) => {
  const { projectId, organisationId } = useTypedPath(
    "projectId",
    "organisationId",
  );

  const mapBranchId = useRecoilValue(branchIdSelector);
  const mapParkId = useRecoilValue(parkIdSelector);

  const mapPark = useRecoilValue(
    getParkFeatureSelectorFamily({
      parkId: defaultSelection?.parkId ?? dashboard.parkId ?? mapParkId ?? "",
    }),
  );

  const branchId =
    defaultSelection?.branchId ?? dashboard.branchId ?? mapBranchId;
  const [park, setPark] = useState<undefined | ParkFeature>(mapPark);

  const branch = useRecoilValue(
    getBranchSelectorFamily({ projectId, branchId: branchId ?? "" }),
  );

  const activeWidgets = useMemo(
    () =>
      dashboard.rows.flatMap((r) => r.elements).map((e) => e.id as WidgetId),
    [dashboard],
  );

  const enabledFeatureFlags = useAllFeatureFlags();

  const dashboardRows = useMemo(
    () =>
      dashboard.rows
        .map((row) => ({
          ...row,
          elements: row.elements.filter((element) => {
            const widgetId = element.id as WidgetId;
            const requireFeatureFlag = widgetIdRequireFeatureFlag[widgetId];
            return (
              !requireFeatureFlag ||
              enabledFeatureFlags.includes(requireFeatureFlag)
            );
          }),
        }))
        .filter((row) => 0 < row.elements.length),
    [dashboard.rows, enabledFeatureFlags],
  );

  const {
    setRows,
    removeWidget,
    addWidget,
    update: updateDashboard,
  } = useDashboard(dashboard.id);
  const configurations = useRecoilValue(
    libraryAndProjectConfigurationsSelectorFamily({ nodeId: projectId }),
  );

  const branchConfiguration = useRecoilValue(
    branchSelectedConfigurationAtomFamily({
      projectId,
      branchId: branch?.id ?? "",
    }),
  );

  const [configuration, setConfiguration] = useState<undefined | Configuration>(
    () => {
      const target =
        defaultSelection?.configurationId ?? dashboard.configurationId;
      const ret =
        configurations.find((c) => c.id === target) ?? branchConfiguration;
      return ret;
    },
  );

  const windConfigurations = useRecoilValue(
    windSourceConfigurationsAtomFamily({
      projectId,
    }),
  );
  const branchWindConfiguration = useRecoilValue(
    selectedWindConfigurationAtomFamily({
      projectId,
      branchId,
    }),
  );
  const branchCostConfig = useRecoilValue(
    branchSelectedCostConfigurationAtom({ projectId, branchId }),
  );

  const [windConfiguration, setWindConfiguration] = useState<
    undefined | WindSourceConfiguration
  >(() => {
    const target =
      defaultSelection?.windConfigurationId ?? dashboard.windConfigurationId;
    return (
      windConfigurations.find((c) => c.id === target) ?? branchWindConfiguration
    );
  });

  const costConfigurations = useRecoilValue(
    libraryAndProjectCostConfigurationsSelectorFamily({
      nodeId: projectId,
    }),
  );

  const [costConfiguration, setCostConfiguration] = useState<
    undefined | CostConfiguration
  >(() => {
    const target =
      defaultSelection?.costConfigurationId ?? dashboard.costConfigurationId;
    return costConfigurations.find((c) => c.id === target) ?? branchCostConfig;
  });

  const { info } = useToast();
  const [dropdownHidden, setDropdownHidden] = useState(false);

  const selectedCustomDashboardPark = useRecoilValue(
    getParkFeatureSelectorFamily({
      parkId: dashboard.parkId ?? "",
    }),
  );

  // Use the park from the dashboard if it is a custom one and have a park already selected
  const selectedDashboardPark = !dashboard.preset
    ? selectedCustomDashboardPark || park
    : park;
  const selectedDashboardConfiguration = !dashboard.preset
    ? configurations.find((c) => c.id === dashboard.configurationId) ||
      configuration
    : configuration;
  const selectedDashboardWindConfiguration = !dashboard.preset
    ? windConfigurations.find((c) => c.id === dashboard.windConfigurationId) ||
      windConfiguration
    : windConfiguration;
  const selectedDashboardCostConfiguration = !dashboard.preset
    ? costConfigurations.find((c) => c.id === dashboard.costConfigurationId) ||
      costConfiguration
    : costConfiguration;

  return (
    <Row style={{ flex: 1, alignItems: "stretch", overflowY: "auto" }}>
      <DashboardColumn>
        <HeaderWrapper>
          {canEdit ? (
            <EditableTextH3Wrapper>
              <EditableTextInternalState
                value={dashboard.name}
                onEnter={(newName) => {
                  updateDashboard({ id: dashboard.id, name: newName });
                }}
              />
            </EditableTextH3Wrapper>
          ) : (
            <h3>
              {dashboard.name}
              <DashboardStatError
                parkId={park?.id}
                branchId={branch?.id}
                dashboardId={dashboard.id}
              />
            </h3>
          )}
        </HeaderWrapper>
        <DropdownList>
          <CollapseWithButton hidden={dropdownHidden}>
            <Button
              icon={<SettingsIcon />}
              text={dropdownHidden ? "Show settings" : "Hide settings"}
              buttonType={dropdownHidden ? "secondary" : "text"}
              onClick={() => setDropdownHidden((c) => !c)}
              style={{ margin: spaceTiny }}
            />
            <ParkSelector
              branch={branchId}
              park={selectedDashboardPark}
              setPark={(selectedPark) => {
                setPark(selectedPark);
                if (!dashboard.preset) {
                  updateDashboard({
                    id: dashboard.id,
                    parkId: selectedPark.id,
                  });
                }
              }}
            />
            <ConfigurationSelector
              configuration={selectedDashboardConfiguration}
              setConfiguration={(selectedConfiguration) => {
                setConfiguration(selectedConfiguration);
                if (!dashboard.preset) {
                  updateDashboard({
                    id: dashboard.id,
                    configurationId: selectedConfiguration.id,
                  });
                }
              }}
            />
            <WindSourceConfigurationSelector
              windConfiguration={selectedDashboardWindConfiguration}
              setWindConfiguration={(selectedWindConfig) => {
                setWindConfiguration(selectedWindConfig);
                if (!dashboard.preset) {
                  updateDashboard({
                    id: dashboard.id,
                    windConfigurationId: selectedWindConfig.id,
                  });
                }
              }}
            />
            <CostConfigurationSelector
              configuration={selectedDashboardCostConfiguration}
              setConfiguration={(selectedCostConfig) => {
                setCostConfiguration(selectedCostConfig);
                if (!dashboard.preset) {
                  updateDashboard({
                    id: dashboard.id,
                    costConfigurationId: selectedCostConfig.id,
                  });
                }
              }}
            />
          </CollapseWithButton>
          {!dashboard.preset && (
            <>
              <VDivider />
              <WidgetSelector
                widgets={activeWidgets}
                onWidgetClick={(id) => {
                  if (activeWidgets.includes(id)) removeWidget(id);
                  else addWidget(id);
                }}
                canEdit={!!canEdit}
              />
            </>
          )}
          <VDivider />
          <Tooltip text="Copy link to dashboard">
            <Button
              icon={<HyperlinkIcon />}
              buttonType="secondary"
              onClick={() => {
                const ids = _DefaultSelection.parse({
                  b: branch?.id,
                  p: selectedDashboardPark?.id,
                  c: selectedDashboardConfiguration?.id,
                  w: selectedDashboardWindConfiguration?.id,
                  f: selectedDashboardCostConfiguration?.id,
                });

                navigator.clipboard.writeText(
                  getShareUrl(
                    {
                      modalType: DashboardModalType,
                    },
                    {
                      id: dashboard.id,
                      [DEFAULT_SELECTION_SP]: JSON.stringify(ids),
                    },
                  ),
                );
                info("Copied");
              }}
            />
          </Tooltip>
        </DropdownList>
        <ScrollBody>
          {!branch ? (
            <CenterGrayMessage>
              <p>Choose a branch from the dropdown.</p>
            </CenterGrayMessage>
          ) : !selectedDashboardPark ? (
            <CenterGrayMessage>
              <p>Choose a park from the dropdown.</p>
            </CenterGrayMessage>
          ) : !selectedDashboardConfiguration ? (
            <CenterGrayMessage>
              <p>Choose a configuration from the dropdown.</p>
            </CenterGrayMessage>
          ) : !selectedDashboardWindConfiguration ? (
            <CenterGrayMessage>
              <p>Choose a wind configuration from the dropdown.</p>
            </CenterGrayMessage>
          ) : !selectedDashboardCostConfiguration ? (
            <CenterGrayMessage>
              <p>Choose a cost configuration from the dropdown.</p>
            </CenterGrayMessage>
          ) : null}

          {branch &&
            selectedDashboardPark &&
            selectedDashboardConfiguration &&
            selectedDashboardWindConfiguration &&
            selectedDashboardCostConfiguration && (
              <DashboardContext.Provider
                value={{
                  projectId,
                  organisationId,
                  branch,
                  park: selectedDashboardPark,
                  addWidget,
                  removeWidget,
                  configuration: selectedDashboardConfiguration,
                  windConfiguration: selectedDashboardWindConfiguration,
                  costConfiguration: selectedDashboardCostConfiguration,
                  dashboard,
                  canEdit,
                  triggerId: currentParkTriggerId,
                }}
              >
                <TriggerDashboardFinanceAndProduction
                  configuration={selectedDashboardCostConfiguration}
                  analysisConfiguration={selectedDashboardConfiguration}
                  parkId={selectedDashboardPark.id}
                  branchId={branch.id}
                  projectId={projectId}
                  windConfiguration={selectedDashboardWindConfiguration}
                />
                {0 < dashboardRows.length ? (
                  <DnDGrid
                    rows={dashboardRows}
                    setRows={setRows}
                    disabled={!canEdit}
                  />
                ) : (
                  <WidgetNewPlaceholder
                    onClick={(e) => {
                      e.stopPropagation();
                      document
                        .getElementById("widget-selector-button")
                        ?.click();
                    }}
                  >
                    <AddIcon />
                    <p>Add widget</p>
                  </WidgetNewPlaceholder>
                )}
              </DashboardContext.Provider>
            )}
        </ScrollBody>
      </DashboardColumn>
    </Row>
  );
};

const SettingMenuItemSkeleton = () => (
  <SkeletonBlock
    style={{ height: "2rem", width: "initial", margin: "0.8rem 0.5rem" }}
  />
);

const DashboardList = ({
  dashboards,
  canEdit,
}: {
  dashboards: Dashboard[];
  canEdit?: boolean;
}) => {
  const { selectedItemId, setSelectedItemId, expanded } = useContext(
    SettingsSubMenuCustomMenuListContext,
  );
  const { create, remove, duplicate } = useDashboard("");

  useEffect(() => {
    const selected =
      dashboards.find((d) => d.id === selectedItemId) ??
      VIND_DASHBOARDS.find((d) => d.id === selectedItemId);
    if (selected === undefined) {
      setSelectedItemId(dashboards[0]?.id ?? VIND_DASHBOARDS[0]?.id);
    }
  }, [dashboards, selectedItemId, setSelectedItemId]);

  const [loadingNewDashboard, setLoadingNewDashboard] = useState(false);

  return (
    <>
      <H4Collapsible collapsed={!expanded} text="Vind dashboards" />
      {VIND_DASHBOARDS.map((d, index) => (
        <Tooltip
          text={d.name}
          key={d.id}
          innerDivStyle={{ width: "100%" }}
          disabled={d.id === selectedItemId}
        >
          <SettingsMenuItem
            item={{
              id: d.id,
              label: d.name,
              icon: _PresetDashboardIcon[d.id],
              dotMenuOptions: canEdit
                ? [
                    {
                      title: "Duplicate",
                      onSelect: () => {
                        setLoadingNewDashboard(true);
                        duplicate(d.id)
                          .then((db) => {
                            setSelectedItemId(db.id);
                          })
                          .finally(() => {
                            setLoadingNewDashboard(false);
                          });
                      },
                      icon: <DuplicateIcon />,
                    },
                  ]
                : undefined,
            }}
            isFirstLevel
            onClick={() => {
              Mixpanel.track("Open default dashboard", {
                id: d.id,
              });
              setSelectedItemId(d.id);
            }}
            expanded={expanded}
            selected={selectedItemId === d.id}
            index={index + 1}
          />
        </Tooltip>
      ))}
      <div style={{ height: "1rem" }} />
      <H4Collapsible text="Your boards" collapsed={!expanded} />
      {dashboards.map((d, index) => (
        <Tooltip
          text={d.name}
          key={d.id}
          innerDivStyle={{ width: "100%" }}
          disabled={d.id === selectedItemId}
        >
          <SettingsMenuItem
            item={{
              id: d.id,
              label: d.name,
              icon: expanded ? undefined : (
                <TextIcon selected={selectedItemId === d.id}>
                  {d.name[0]}
                </TextIcon>
              ),
              dotMenuOptions: canEdit
                ? [
                    {
                      title: "Duplicate",
                      onSelect: () => duplicate(d.id),
                      icon: <DuplicateIcon />,
                    },
                    {
                      title: "Delete",
                      onSelect: () => {
                        if (window.confirm(`Delete ${d.name}?`)) remove(d.id);
                      },
                      icon: <DeleteIcon />,
                    },
                  ]
                : undefined,
            }}
            isFirstLevel
            onClick={() => {
              Mixpanel.track("Open custom dashboard", {
                widgetIds: d.rows.flatMap((r) => r.elements).map((e) => e.id),
              });
              setSelectedItemId(d.id);
            }}
            expanded={expanded}
            selected={selectedItemId === d.id}
            index={index + 1}
          />
        </Tooltip>
      ))}
      {loadingNewDashboard && <SettingMenuItemSkeleton />}
      {canEdit && (
        <Button
          text={expanded ? "New dashboard" : ""}
          onClick={async (e) => {
            e.stopPropagation();
            setLoadingNewDashboard(true);
            await create({ name: "New dashboard" })
              .then((dashboard) => {
                setSelectedItemId(dashboard.id);
              })
              .finally(() => {
                setLoadingNewDashboard(false);
              });
          }}
          buttonType="text"
          size="medium"
          icon={<AddIcon />}
          style={{
            overflowX: "hidden",
            marginTop: "0.8rem",
            marginLeft: "0.4rem",
          }}
        />
      )}
    </>
  );
};

const DashboardInnerFallback = () => {
  return (
    <Column style={{ flex: 1, padding: "5rem", gap: "3rem" }}>
      <SkeletonBlock style={{ height: "2rem", width: "100%" }} />
      <SkeletonBlock style={{ height: "2rem", width: "100%" }} />
      <SkeletonBlock style={{ height: "2rem", width: "100%" }} />
    </Column>
  );
};

const DashboardInner = ({
  defaultSelection,
}: {
  defaultSelection?: DefaultSelection;
}) => {
  const { projectId } = useTypedPath("projectId");
  const dashboards = useRecoilValue(
    dashboardsAtomFamily({
      nodeId: projectId,
    }),
  );

  const canEdit = useRecoilValue(editorAccessProjectSelector);

  const items = useMemo(
    () =>
      dashboards.concat(VIND_DASHBOARDS).map((d) => ({
        id: d.id,
        label: d.name,
        dotMenuOptions: [
          {
            title: "Delete",
            onSelect: () => {
              alert(`delete ${d.name}`);
            },
            icon: <DeleteIcon />,
          },
        ],
        content: (
          <Suspense fallback={<DashboardComponentFallback />}>
            <DashboardComponent
              dashboard={d}
              canEdit={canEdit && !d.preset}
              defaultSelection={defaultSelection}
            />
          </Suspense>
        ),
      })),
    [canEdit, dashboards, defaultSelection],
  );

  return (
    <SettingsSubMenuCustomMenuList
      parentId={DashboardModalType}
      items={items}
      depth={0}
      wide
    >
      <DashboardList dashboards={dashboards} canEdit={canEdit} />
    </SettingsSubMenuCustomMenuList>
  );
};

const DashboardHeader = ({ projectId }: { projectId: string }) => {
  const branchId = useRecoilValueDef(branchIdSelector) ?? "";
  const branch = useRecoilValue(
    getBranchSelectorFamily({
      projectId,
      branchId: branchId,
    }),
  );
  return (
    <Row style={{ alignItems: "center", gap: spacing8 }}>
      <h3>Dashboard</h3>
      <div
        style={{
          width: "1px",
          height: "2rem",
          backgroundColor: colors.blue200,
        }}
      />
      <p style={{ ...typography.caption, color: colors.textNegative }}>
        {branch?.title}
      </p>
    </Row>
  );
};

export const DashboardModal = () => {
  const [modalTypeOpen, setModalTypeOpen] = useRecoilState(modalTypeOpenAtom);
  const projectId = useRecoilValue(projectIdSelector) ?? "";
  const setSelectedDashboard = useSetRecoilState(
    selectedMenuItemState({ menuId: DashboardModalType, projectId }),
  );

  useEffect(() => {
    if (modalTypeOpen?.modalType === DashboardModalType) {
      const id = modalTypeOpen.metadata?.id;
      if (id) setSelectedDashboard(id);
    }
  }, [modalTypeOpen, setSelectedDashboard]);

  const [defaultSelection, setDefaultSelection] = useState<
    Partial<DefaultSelection> | undefined
  >(undefined);

  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const parkIdsInSearchParams = searchParams.get(DEFAULT_SELECTION_SP);
    if (parkIdsInSearchParams) {
      try {
        const { b, p, c, w, f } = _DefaultSelection.parse(
          JSON.parse(parkIdsInSearchParams),
        );
        setDefaultSelection({
          branchId: b,
          parkId: p,
          configurationId: c,
          windConfigurationId: w,
          costConfigurationId: f,
        });
        searchParams.delete(DEFAULT_SELECTION_SP);
        setSearchParams(searchParams);
        return;
      } catch (err) {}
    }
    // We only want to run this effect on first render, and not when the search params change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (modalTypeOpen?.modalType !== DashboardModalType) {
    return null;
  }

  return (
    <TopBarModal>
      <TopBarModalHeader
        title={<DashboardHeader projectId={projectId} />}
        onClose={() => {
          setModalTypeOpen(undefined);
        }}
      />
      <ContentContainer style={{ padding: 0 }}>
        <Suspense fallback={<DashboardInnerFallback />}>
          <DashboardInner defaultSelection={defaultSelection} />
        </Suspense>
      </ContentContainer>
    </TopBarModal>
  );
};
