import { useSetRecoilState, useRecoilCallback } from "recoil";
import {
  ABLY_THREAD_REACTIONS_ADD_FILTER,
  ABLY_THREAD_REACTIONS_REMOVE_FILTER,
} from "../../../state/ably";
import { useTypedPath } from "../../../state/pathParams";
import { postThreadReaction, deleteThreadReaction } from "../service";
import {
  userReactionSaveInProgressState,
  threadsAtomFamily,
  threadReactionsCounterAtomFamily,
  userReactionsAtomFamily,
} from "../state";
import { Reaction } from "../types";
import { initializeAndSet } from "./useReplyReactionCrud";
import { useAblyPublish } from "../../../hooks/useAblyPublish";

export const useThreadReactionCrud = () => {
  const { projectId: projectNodeId, branchId } = useTypedPath(
    "projectId",
    "branchId",
  );
  const ablyPublish = useAblyPublish();
  const setReactionSaveInProgress = useSetRecoilState(
    userReactionSaveInProgressState,
  );

  const post = useRecoilCallback(
    ({ set }) =>
      async (threadId: string) => {
        if (!projectNodeId) return;
        setReactionSaveInProgress(threadId);
        const res = await postThreadReaction(
          projectNodeId,
          branchId,
          threadId,
          {
            reaction: "like",
          },
        ).finally(() => setReactionSaveInProgress(undefined));
        set(threadsAtomFamily({ nodeId: projectNodeId, branchId }), (cur) => {
          return cur.map((c) =>
            c.threadId === threadId ? { ...c, reactions: c.reactions + 1 } : c,
          );
        });
        set(
          threadReactionsCounterAtomFamily({
            nodeId: projectNodeId,
            branchId,
            threadId,
          }),
          (cur) => cur + 1,
        );
        set(
          userReactionsAtomFamily({ nodeId: projectNodeId, branchId }),
          (cur) => {
            return [...cur, res];
          },
        );
        ablyPublish(
          `${projectNodeId}:all`,
          ABLY_THREAD_REACTIONS_ADD_FILTER,
          res,
        );
        return res;
      },
    [ablyPublish, projectNodeId, branchId, setReactionSaveInProgress],
  );

  const remove = useRecoilCallback(
    ({ set }) =>
      async (threadId: string, reactionId: string) => {
        if (!projectNodeId) return;
        set(threadsAtomFamily({ nodeId: projectNodeId, branchId }), (cur) => {
          return cur.map((c) =>
            c.threadId === threadId ? { ...c, reactions: c.reactions - 1 } : c,
          );
        });
        set(
          threadReactionsCounterAtomFamily({
            nodeId: projectNodeId,
            branchId,
            threadId,
          }),
          (cur) => cur + 1,
        );
        set(
          userReactionsAtomFamily({ nodeId: projectNodeId, branchId }),
          (cur) => {
            return cur.filter((c) => c.reactionId !== reactionId);
          },
        );
        const res = await deleteThreadReaction(
          projectNodeId,
          branchId,
          threadId,
          reactionId,
        );
        ablyPublish(
          `${projectNodeId}:all`,
          ABLY_THREAD_REACTIONS_REMOVE_FILTER,
          {
            threadId,
          },
        );
        return res;
      },
    [ablyPublish, branchId, projectNodeId],
  );

  const localPost = useRecoilCallback(
    ({ set, snapshot }) =>
      async (reaction: Reaction) => {
        if (!projectNodeId) return;
        initializeAndSet(
          snapshot,
          set,
          threadsAtomFamily({
            nodeId: reaction.nodeId,
            branchId: reaction.branchId,
          }),
          (cur) => {
            return cur.map((c) =>
              c.threadId === reaction.threadId
                ? { ...c, reactions: c.reactions + 1 }
                : c,
            );
          },
        );

        initializeAndSet(
          snapshot,
          set,
          threadReactionsCounterAtomFamily({
            nodeId: reaction.nodeId,
            branchId: reaction.branchId,
            threadId: reaction.threadId,
          }),
          (cur) => cur + 1,
        );
      },
    [projectNodeId],
  );

  const localRemove = useRecoilCallback(
    ({ set, snapshot }) =>
      async (threadId: string) => {
        if (!projectNodeId) return;
        initializeAndSet(
          snapshot,
          set,
          threadsAtomFamily({ nodeId: projectNodeId, branchId }),
          (cur) => {
            return cur.map((c) =>
              c.threadId === threadId
                ? { ...c, reactions: c.reactions - 1 }
                : c,
            );
          },
        );

        initializeAndSet(
          snapshot,
          set,
          threadReactionsCounterAtomFamily({
            nodeId: projectNodeId,
            branchId,
            threadId,
          }),
          (cur) => cur + 1,
        );
      },
    [branchId, projectNodeId],
  );

  return { post, remove, localPost, localRemove };
};
