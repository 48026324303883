import { distance } from "@turf/turf";
import { Position } from "geojson";
import * as turf from "@turf/turf";
import { projectPointToSegment } from "../../../../utils/geometry";

export const SNAP_PIXELS = 10;
export const OFFSET_PIXELS = 0.1;

export function snapToClosest(
  points: Position[],
  lines: [Position, Position][],
  point: Position,
  threshold: number,
) {
  try {
    const snap = points.reduce<{
      distance: number;
      point: Position | undefined;
    }>(
      (pre, cur) => {
        const currentDistance = distance(point, cur);
        return currentDistance < threshold && currentDistance < pre?.distance
          ? { distance: currentDistance, point: cur }
          : pre;
      },
      { distance: Infinity, point: undefined },
    );

    if (snap.point) {
      return snap.point;
    }

    const lineSnap = lines.map((segment) => {
      const p = projectPointToSegment(point, segment);
      return { pos: p, distance: turf.distance(point, p) };
    });

    if (lineSnap.length > 0) {
      const closest = lineSnap.reduce((pre, cur) => {
        return cur.distance < pre.distance ? cur : pre;
      });
      if (closest.distance < threshold) {
        return closest.pos;
      }
    }

    return point;
  } catch (e) {
    console.error(e);
    return point;
  }
}

// minimal distance before marker snaps (in pixels)
export function kmPerPixel(latitude: number, zoomLevel: number) {
  const earthCircumference = 40075017;
  const latitudeRadians = latitude * (Math.PI / 180);
  return (
    (earthCircumference * Math.cos(latitudeRadians)) /
    Math.pow(2, zoomLevel + 8) /
    1000
  );
}

export type SnapType =
  | "Point"
  | "LineString"
  | "Polygon"
  | "MultiPoint"
  | "MultiLineString"
  | "MultiPolygon";

type SnapObject = {
  featureId: string;
  point: Position;
  featureType?: string;
  parentIds?: string[];
};

export type SnapPoint = SnapObject & { type: "Point" };
export type SnapLineString = SnapObject & { type: "LineString" };
export type SnapPolygon = SnapObject & { type: "Polygon" };

export type SnapFeature = SnapPoint | SnapLineString | SnapPolygon;

export function snapToClosestFeature(
  features: SnapFeature[],
  point: Position,
  generalThreshold: number,
  turbineThreshold?: number,
) {
  const snap = features.reduce<{
    distance: number;
    feature: SnapFeature | undefined;
  }>(
    (pre, cur) => {
      const currentDistance = distance(point, cur.point);
      const threshold =
        cur.featureType === "park-layout-turbine" && turbineThreshold
          ? turbineThreshold
          : generalThreshold;
      return currentDistance < threshold && currentDistance < pre.distance
        ? { distance: currentDistance, feature: cur }
        : pre;
    },
    { distance: Infinity, feature: undefined },
  );

  return snap.feature;
}

// Snap points of line to closest external point
// if the distance is less than threshold and bigger than 0 (the point might already be a snapped point)
export function snapLineToClosestPoint(
  line: Position[],
  points: Position[],
  threshold: number, // in kilometeres
): { line: Position[]; snapped: boolean } {
  try {
    let snapped = false;
    const snappedLine = line.map((point) => {
      const maybeSnappedPoint = snapToClosest(points, [], point, threshold);
      snapped = snapped || maybeSnappedPoint !== point;
      return maybeSnappedPoint;
    });

    return { line: snappedLine, snapped };
  } catch (e) {
    console.error(e);
    return { line, snapped: false };
  }
}
