import { useEffect, useMemo } from "react";
import { getFileTypeFromFileName } from "../../utils";
import { UploadFileType } from "./types";
import { acceptedLayerFileEndingsWithShapeFiles } from "./UploadDataLayer/UploadDataLayerGeneral";
import { acceptedProjectFileEndingsWithShapeFiles } from "./UploadProjectFeatures/UploadProjectFeatures";
import { acceptedWindDataFileEndings } from "./UploadWindData/UploadWindData";

const DroppedFilesMapper = ({
  droppedFiles,
  resetDroppedFiles,
  setShowSelectUploadFileTypeForDroppedFiles,
  setErrorText,
  setSelectedUploadFileType,
}: {
  droppedFiles: File[];
  resetDroppedFiles(): void;
  setShowSelectUploadFileTypeForDroppedFiles(newVal: boolean): void;
  setErrorText(errorText: string): void;
  setSelectedUploadFileType(newVal: UploadFileType): void;
}) => {
  const uniqueFileTypes = useMemo(
    () => [
      ...new Set(
        droppedFiles.map((file) => getFileTypeFromFileName(file.name)),
      ),
    ],
    [droppedFiles],
  );

  useEffect(() => {
    const isWindDataFiles = uniqueFileTypes.some((fileType) =>
      acceptedWindDataFileEndings.includes(`.${fileType}`),
    );
    const isProjectFiles = uniqueFileTypes.some((fileType) =>
      acceptedProjectFileEndingsWithShapeFiles.includes(`.${fileType}`),
    );
    const isDataLayerFiles = uniqueFileTypes.some((fileType) =>
      acceptedLayerFileEndingsWithShapeFiles.includes(`.${fileType}`),
    );

    const shouldShowMultipleFileTypesError =
      isWindDataFiles && (isProjectFiles || isDataLayerFiles);

    if (shouldShowMultipleFileTypesError) {
      setErrorText(
        "Multiple file types detected. It is not possible to upload wind data " +
          "(.csv) and other types of data at the same time. Please try again and " +
          "select only .csv or other types of files.",
      );
      resetDroppedFiles();
    } else if (isWindDataFiles) {
      setSelectedUploadFileType(UploadFileType.WIND_DATA);
    } else if (isProjectFiles && !isDataLayerFiles) {
      setSelectedUploadFileType(UploadFileType.PROJECT_FEATURE);
    } else if (isDataLayerFiles && !isProjectFiles) {
      setSelectedUploadFileType(UploadFileType.DATA_LAYER);
    } else if (isProjectFiles && isDataLayerFiles) {
      setShowSelectUploadFileTypeForDroppedFiles(true);
    } else {
      setErrorText(
        "Unknown filetype. Please select files with a format that is supported.",
      );
      resetDroppedFiles();
    }
  }, [
    resetDroppedFiles,
    setSelectedUploadFileType,
    setErrorText,
    setShowSelectUploadFileTypeForDroppedFiles,
    uniqueFileTypes,
  ]);

  return null;
};

export default DroppedFilesMapper;
