import { Suspense } from "react";
import TextWithMentions from "../MapModal/TextWithMentions";
import UserInfo, { UserInfoLoading } from "../MapModal/UserInfo";
import ReplyReactions from "../Reactions/ReplyReactions";
import { Reply } from "../types";
import { Column, Row } from "../../General/Layout";
import { useTypedPath } from "../../../state/pathParams";

export default function ReplyWrapper({ reply }: { reply: Reply }) {
  const { projectId } = useTypedPath("projectId");

  return (
    <Column>
      <Row style={{ justifyContent: "space-between" }}>
        <Suspense fallback={<UserInfoLoading />}>
          <UserInfo userId={reply.userId} />
        </Suspense>
      </Row>
      <Row>
        <TextWithMentions message={reply.message} />
        {projectId && <ReplyReactions reply={reply} nodeId={projectId} />}
      </Row>
    </Column>
  );
}
