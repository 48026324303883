/// <reference types="vite-plugin-svgr/client" />
import styled from "styled-components";
import { colors } from "../../styles/colors";
import {
  spaceMedium,
  borderRadiusMedium,
  spaceSmall,
  spaceTiny,
} from "../../styles/space";
import { typography, RegularRaw } from "../../styles/typography";
import { Row } from "../General/Layout";
import FolderIcon from "@icons/24/Folder.svg?react";
import { projectImageHeight } from "../StaticMapboxImage/StaticMapboxProjectImage";

export const ProjectGridWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3rem 2.2rem;
  flex-wrap: wrap;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 2.4rem;
`;

export const DotMenuWrapper = styled.div<{
  isFolderHovered?: boolean;
  isActive?: boolean;
}>`
  position: absolute;
  align-self: end;
  cursor: pointer;
  border-radius: 0.4rem;
  margin: 0.4rem;
  background: ${({ isActive }) => (isActive ? colors.surfaceSelectedLight : colors.grey200)};}
  &:hover {
    background: ${({ isFolderHovered }) =>
      isFolderHovered ? colors.grey300 : colors.grey200};
  }
  visibility: ${({ isFolderHovered }) =>
    isFolderHovered ? "visible" : "hidden"};
`;

export const ProjectIconWrapper = styled.div<{
  isProjectHovered?: boolean;
  isActive?: boolean;
}>`
  display: flex;
  cursor: pointer;
  align-items: center;
  border-radius: ${borderRadiusMedium};

  svg {
    width: 1.4rem;
    height: 1.4rem;
    padding: 0 0.8rem;
    path {
      stroke: ${colors.iconDefault};
    }
  }
  background: ${({ isActive }) => (isActive ? colors.surfaceSelectedLight : colors.grey200)};}
  &:hover {
    background: ${({ isProjectHovered }) =>
      isProjectHovered ? colors.grey300 : colors.grey200};
  }
  visibility: ${({ isProjectHovered }) =>
    isProjectHovered ? "visible" : "hidden"};
`;

export const StarIconWrapper = styled.div<{ active: boolean }>`
  &:hover {
    background: ${colors.surfaceSubtle};
    svg { 
      path { 
        ${(p) => p.active && `stroke: none;`}
      }
    }
  }
  display: flex;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  margin: ${spaceMedium};
  padding: ${spaceTiny};
  border-radius: ${borderRadiusMedium};
  background: ${(p) => (p.active ? "transparent" : colors.surfaceSubtle)};
  opacity: ${(p) => (p.active ? "1 !important" : "inherit")};
  svg {
    width: 1.4rem;
    height: 1.4rem;
    path {
      stroke: ${(p) => (p.active ? colors.surfaceBrand : colors.surfaceBrand)};
      fill: ${(p) => (p.active ? colors.surfaceBrand : "transparent")};
    }
  }
}
`;

export const ProjectPictureWrapper = styled.div<{ hoverStyle?: boolean }>`
  position: relative;
  height: ${projectImageHeight};
  > img {
    border-radius: ${borderRadiusMedium};
  }

  > ${StarIconWrapper} {
    opacity: 0;
  }
  &:hover {
    ${StarIconWrapper} {
      opacity: 1;
    }

    ${(p) => p.hoverStyle && `background: ${colors.surfaceHover};`}
  }

  cursor: ${(p) => p.hoverStyle && "pointer"};
`;

export const DashText = styled.div`
  ${typography.caption}
  color: ${colors.textSecondary};
`;

export const FolderNavBar = styled(Row)`
  border-radius: ${borderRadiusMedium};
  align-items: center;
  gap: 0.8rem;
  button.text {
    ${typography.caption};
    color: ${colors.textSecondary};
  }
`;

export const EmptyGridMessageDiv = styled.div`
  margin: 0 auto;
  color: ${colors.primaryDisabled};
  ${typography.contentAndButtons};
`;

export const ProjectStatusWrapper = styled.div<{ active: boolean }>`
  ${RegularRaw};
  display: flex;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: ${spaceMedium} ${spaceSmall};
  padding: 0.2rem ${spaceSmall};

  border-radius: ${borderRadiusMedium};
  color: ${(p) => (p.active ? colors.primaryText : `${colors.primaryText}80`)};
  background: ${colors.hover};
`;

export const FolderCardGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 2rem ${spaceMedium};
  border-radius: ${borderRadiusMedium};
  width: 30rem;
  align-items: center;
  justify-items: left;
`;

export const FolderCardGridEmpty = styled.div`
  background: white;
  border: 1px dotted ${colors.inputOutline};
  box-sizing: border-box;
  opacity: 0.3;
`;

const FolderCardGridFolderWrapper = styled(FolderCardGridEmpty)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: end;
  gap: ${spaceMedium};
  opacity: 0.3;

  svg {
    align-self: center;
    stroke: ${colors.primaryText};
  }
  div {
    ${typography.contentAndButtons};
    background: ${colors.hover};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 0 0 ${borderRadiusMedium} ${borderRadiusMedium};
    padding: 0.1rem ${spaceMedium};
  }
`;

export const FolderCardGridFolder = ({ text }: { text?: string }) => {
  return (
    <FolderCardGridFolderWrapper>
      <FolderIcon />
      <div
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          color: colors.primaryText,
        }}
      >
        {text}
      </div>
    </FolderCardGridFolderWrapper>
  );
};

export const ProjectNameWrapper = styled.div`
  ${typography.sub1};
  color: ${colors.textPrimary};
  padding: 0;
  margin: 0;
  grid-area: projectname;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FolderNameWrapper = styled.div`
  ${typography.sub1};
  color: ${colors.textPrimary};
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 16rem;
`;

export const ProjectInfoLine = styled.div`
  ${typography.contentAndButtons};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: ${spaceTiny};
  color: ${colors.secondaryText};
  padding-left: ${spaceSmall};
  width: 100%;
`;

export const AddIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0.4rem;
  border-radius: 50%;
  background: ${colors.surfaceSecondary};
  cursor: pointer;
`;

export const NewProjectPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 22rem;
  width: 30rem;
  border-radius: ${borderRadiusMedium};
  border: 1px dashed ${colors.borderDefault};
  cursor: pointer;
  &:hover {
    border-color: ${colors.indigo400};

  &:hover ${AddIconWrapper} {
    background: ${colors.indigo100};
   
  }
`;

export const ProjectWrapper = styled.div<{
  disabled?: boolean;
  nohover?: boolean;
  isHovered?: boolean;
}>`
  opacity: ${(p) => (p.disabled ? "0.5" : "1.0")};
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 30rem;
  background: ${colors.surfaceSecondary};

  cursor: ${(p) => !p.nohover && "pointer"};

  border-radius: ${borderRadiusMedium};

  &:hover {
    ${(p) => !p.nohover && `background: ${colors.surfaceHover};`}
  }

  > img {
    border-radius: ${borderRadiusMedium};
  }
`;

export const FolderWrapper = styled.div<{
  disabled?: boolean;
  isHovered?: boolean;
}>`
  opacity: ${(p) => (p.disabled ? "0.5" : "1.0")};
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 30rem;
  background: ${colors.surfaceSecondary};

  cursor: "pointer";

  border-radius: ${borderRadiusMedium};
  &:hover {
    background: ${colors.surfaceHover};
  }
  &:hover ${DotMenuWrapper} {
    visibility: visible;
  }

  ${(p) => p.isHovered && `background: ${colors.surfaceHover};`}
`;

export const SecondaryText = styled.p`
  ${typography.caption}
  margin: 0;
  padding: 0 0.2rem;
  color: ${colors.textSecondary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const BlueCircleIcon = styled.div`
  background-color: ${colors.surfaceBrand};
  border-radius: 5rem;
  padding: 0.6rem 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
`;
