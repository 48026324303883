/// <reference types="vite-plugin-svgr/client" />
import * as turf from "@turf/turf";
import DownloadIcon from "@icons/24/Download.svg?react";
import Dropdown from "components/Dropdown/Dropdown";
import { Label } from "components/General/Form";
import {
  getAnalysisProgress,
  getExportSystemLosses,
  getStoppedReason,
  getStoppedReasonFromAnalysis,
} from "components/ProductionV2/state";
import {
  AnalysisStoppedTypes,
  analysisStoppedText,
} from "components/ProductionV2/types";
import { stringify } from "csv-stringify/sync";
import Plotly from "plotly.js-dist-min";
import { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import {
  getCablesSelectorFamily,
  getExportCablesInBranchSelectorFamily,
  getExportCablesSelectorFamily,
} from "state/cable";
import { spaceMedium } from "styles/space";
import { isDefined } from "utils/predicates";
import { useToast } from "../../../hooks/useToast";
import { downloadText, fastMax, fastMin } from "../../../utils/utils";
import { MenuItem } from "../../General/Menu";
import { useDashboardContext } from "../Dashboard";
import { CenterContainer, LoadingState, SafeCard } from "./Base";
import { exportCableSplitsOk } from "functions/elevation";
import { colors } from "styles/colors";
import { SkeletonText } from "components/Loading/Skeleton";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";

const ExportSystemVoltage = ({
  usedExportCableId,
  setUsedExportCableId,
}: {
  usedExportCableId: string | undefined;
  setUsedExportCableId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}) => {
  const { park, branch, configuration, triggerId } = useDashboardContext();
  const exportCables = useRecoilValue(
    getExportCablesInBranchSelectorFamily({
      parkId: park.id,
      branchId: branch.id,
    }),
  );
  const cables = useRecoilValue(getCablesSelectorFamily({ parkId: park.id }));
  const exportCableSegments = useRecoilValue(
    exportCableSplitsOk({ parkId: park.id, branchId: branch.id }),
  );

  const exportSystemLoss = useRecoilValue(getExportSystemLosses(triggerId));
  const exportSystemVoltages = exportSystemLoss?.results?.exportSystemVoltages;
  const exportCableSegLengths =
    exportSystemLoss?.results?.exportCableSegLengths;
  const stoppedReason = useRecoilValue(getStoppedReason(triggerId));
  const analysisStoppedReason = useRecoilValueLoadable(
    getStoppedReasonFromAnalysis(triggerId),
  ).valueMaybe();
  const analysisProgress = useRecoilValue(getAnalysisProgress(triggerId));

  if (cables.length === 0) {
    return (
      <CenterContainer>
        <SimpleAlert text={"No inter-array cables in park."} type={"error"} />
      </CenterContainer>
    );
  }

  if (stoppedReason) {
    return (
      <CenterContainer>
        <SimpleAlert
          title="Analysis stopped"
          text={analysisStoppedText[stoppedReason]}
          type={"error"}
        />
      </CenterContainer>
    );
  }
  if (analysisStoppedReason) {
    return (
      <SimpleAlert
        title="Analysis stopped"
        text={
          analysisStoppedText[analysisStoppedReason as AnalysisStoppedTypes]
        }
        type={"error"}
      />
    );
  }

  if (!configuration.electrical.exportSystemLoss) {
    return (
      <CenterContainer>
        <SimpleAlert
          text={
            "Export system losses are not enabled for the selected analysis configuration."
          }
          type={"error"}
        />
      </CenterContainer>
    );
  }

  if (!usedExportCableId) {
    return (
      <CenterContainer>
        <SimpleAlert text={"No export cables in park."} type={"error"} />
      </CenterContainer>
    );
  }

  if (!exportSystemVoltages || !exportCableSegLengths) {
    if (analysisProgress)
      return (
        <SkeletonText
          style={{ margin: "2rem", height: "2rem" }}
          text={`${Math.round(analysisProgress * 100)}%`}
        />
      );
    return <LoadingState />;
  }

  const selectedExportSystemVoltages = exportSystemVoltages[usedExportCableId];
  const selectedExportCableSegLengths =
    exportCableSegLengths[usedExportCableId];

  const completeCable = exportCables.find((c) => c.id === usedExportCableId);
  const offshoreCable = exportCableSegments.find(
    (c) => c.exportCable.id === usedExportCableId,
  )?.offshore;
  const cableHasLandfall = offshoreCable ? true : false;

  if (
    !selectedExportSystemVoltages ||
    !selectedExportCableSegLengths ||
    !completeCable
  ) {
    return (
      <CenterContainer>
        <SimpleAlert
          text={"Cannot find results for the selected export cable."}
          type={"error"}
        />
      </CenterContainer>
    );
  }

  const offshoreLength = turf.length(offshoreCable ?? completeCable, {
    units: "kilometers",
  });

  return (
    <>
      <Label style={{ width: "30%", padding: spaceMedium }}>
        <p>Export cable</p>
        <Dropdown
          style={{ flex: 1 }}
          onChange={(e) => setUsedExportCableId(e.target.value)}
          value={usedExportCableId}
        >
          {exportCables.map((c) => {
            return (
              <option key={c.id} value={c.id}>
                {`${c.properties.name}`}
              </option>
            );
          })}
        </Dropdown>
      </Label>
      <ExportSystemVoltageInner
        selectedExportSystemVoltages={selectedExportSystemVoltages}
        selectedExportCableSegLengths={selectedExportCableSegLengths}
        offshoreLength={offshoreLength}
        cableHasLandfall={cableHasLandfall}
      />
    </>
  );
};

const ExportSystemVoltageInner = ({
  selectedExportSystemVoltages,
  selectedExportCableSegLengths,
  offshoreLength,
  cableHasLandfall,
}: {
  selectedExportSystemVoltages: number[];
  selectedExportCableSegLengths: number[];
  offshoreLength: number;
  cableHasLandfall: boolean;
}) => {
  const graphRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!graphRef.current) return;
    setIsLoaded(false);

    const xValues = [...selectedExportCableSegLengths];
    xValues.splice(0, 0, xValues[0]);
    xValues.splice(xValues.length, 0, xValues[xValues.length - 1]);

    const landfallX = [offshoreLength, offshoreLength];
    const landfallY = [0, 5];

    const data = [
      {
        name: "Voltage at full load",
        y: selectedExportSystemVoltages,
        x: xValues,
        type: "scatter" as const,
        hovertemplate: "Position: %{x:,.0f} km<br />Voltage: %{y:,.2f} pu",
        mode: "lines+markers",
      } as const,
      cableHasLandfall
        ? ({
            name: "Landfall",
            y: landfallY,
            x: landfallX,
            type: "scatter" as const,
            line: { color: colors.primaryDisabled, width: 1 } as const,
            mode: "lines",
          } as const)
        : undefined,
    ].filter(isDefined);

    const layout = {
      hovermode: "closest" as const,
      legend: { x: 0.5, y: 1.1, xanchor: "center", orientation: "h" } as const,
      font: { size: 10, family: "Open Sans" },
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
      autosize: true,
      height: 220,
      xaxis: {
        title: "Distance from offshore substation [km]",
        range: [0, 1.01 * xValues[xValues.length - 1]],
        autotick: true,
      },
      yaxis: {
        title: "Export system voltage [pu]",
        range: [
          fastMin(selectedExportSystemVoltages) - 0.02,
          fastMax(selectedExportSystemVoltages) + 0.02,
        ],
        autotick: true,
      },
      margin: {
        l: 60,
        r: 30,
        b: 30,
        t: 30,
      },
    };

    Plotly.newPlot(graphRef.current, data, layout, {
      displayModeBar: false,
      responsive: true,
      showTips: false,
      staticPlot: true,
    }).then(() => setIsLoaded(true));
  }, [
    graphRef,
    selectedExportSystemVoltages,
    selectedExportCableSegLengths,
    offshoreLength,
    cableHasLandfall,
  ]);

  return (
    <div style={{ flex: 1 }} ref={graphRef}>
      {isLoaded ?? <LoadingState />}
    </div>
  );
};

const ExportSystemVoltageMenu = ({
  usedExportCableId,
}: {
  usedExportCableId: string | undefined;
}) => {
  const { triggerId } = useDashboardContext();
  const exportSystemLoss = useRecoilValue(getExportSystemLosses(triggerId));
  const exportSystemVoltages = exportSystemLoss?.results?.exportSystemVoltages;
  const exportCableSegLengths =
    exportSystemLoss?.results?.exportCableSegLengths;

  const { warning } = useToast();

  const download = useCallback(() => {
    if (!exportSystemVoltages || !exportCableSegLengths) {
      warning(
        "Data is still loading, please wait a few seconds and try again.",
      );
      return;
    }

    if (!usedExportCableId) {
      warning("No export cables in park.");
      return;
    }

    const selectedExportSystemVoltages =
      exportSystemVoltages[usedExportCableId];
    const selectedExportCableSegLengths =
      exportCableSegLengths[usedExportCableId];

    if (!selectedExportSystemVoltages || !selectedExportCableSegLengths) {
      warning("Cannot find results for the selected export cable.");
      return;
    }

    const header = [
      "Distance from offshore substation [km]",
      "Export system voltage at full load [pu]",
    ];

    const distances = [...selectedExportCableSegLengths];
    distances.splice(0, 0, distances[0]);
    distances.splice(distances.length, 0, distances[distances.length - 1]);

    const rows = selectedExportSystemVoltages
      .map((_, i) => {
        const length = distances[i].toFixed(3);
        const voltage = selectedExportSystemVoltages[i].toFixed(3);

        return [length, voltage];
      })
      .filter(isDefined);

    const csvString = stringify([header, ...rows]);
    downloadText(csvString, "export_system_voltage.csv");
  }, [exportCableSegLengths, exportSystemVoltages, usedExportCableId, warning]);

  return (
    <>
      <MenuItem
        name={"Download as .csv"}
        icon={<DownloadIcon />}
        onClick={() => download()}
      />
    </>
  );
};

export const ExportSystemVoltageWidget = () => {
  const { park } = useDashboardContext();
  const exportCables = useRecoilValue(
    getExportCablesSelectorFamily({ parkId: park.id }),
  );

  const [usedExportCableId, setUsedExportCableId] = useState<
    string | undefined
  >(exportCables[0]?.id);

  useEffect(() => {
    if (exportCables.length > 0) {
      setUsedExportCableId(exportCables[0]?.id);
    }
  }, [exportCables]);

  return (
    <SafeCard
      title="Export system voltage"
      id="Export system voltage"
      menuItems={
        <ExportSystemVoltageMenu usedExportCableId={usedExportCableId} />
      }
    >
      <ExportSystemVoltage
        usedExportCableId={usedExportCableId}
        setUsedExportCableId={setUsedExportCableId}
      />
    </SafeCard>
  );
};
