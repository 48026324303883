import { z } from "zod";

const _BranchDataSource = z.object({
  type: z.literal("branch"),
  id: z.string(),
  projectId: z.string(),
  version: z.number().optional(),
});

export const _DataSource = _BranchDataSource;

export type DataSource = z.infer<typeof _DataSource>;
