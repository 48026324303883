/// <reference types="vite-plugin-svgr/client" />
import { Label, TableWrapper } from "./styles";
import { RadioGroup as _RadioContainer } from "../../General/Radio";
import styled from "styled-components";

import { Row as _Row, Column as _Column } from "../../General/Layout";
import { TurbineCosts } from "./Capex/TurbineCosts";
import { CableCosts } from "./Capex/CableCosts";
import { FoundationCosts } from "./Capex/FoundationsCosts";
import { MooringCosts } from "./Capex/MooringCosts";
import { SubstationCosts } from "./Capex/SubstationCosts";
import { ExportCableCosts } from "./Capex/ExportCableCosts";
import { OtherCosts } from "./Capex/OtherCosts";
import { InputNumber } from "components/General/Input";
import {
  localCostConfigurationAtom,
  useLocalCostConfigurationCrud,
} from "./state";
import { useRecoilState, useRecoilValue } from "recoil";
import { between } from "utils/validations";
import Toggle, { ToggleSize } from "components/General/Toggle";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";

export const Column = styled(_Column)`
  margin-top: 2rem;
  gap: 0;
`;

export const FirstRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FirstRowItem = styled.div`
  display: flex;
  height: 3rem;
  align-items: center;
`;

export const Row = styled(_Row)<{ justify?: string; marginTop?: string }>`
  justify-content: ${(p) => p.justify || "space-between"};
  margin-top: ${(p) => p.marginTop || 0};
  height: 3.2rem;
  align-items: center;
  margin-right: 0.2rem;
`;

export const Capex = ({ isReadOnly }: { isReadOnly?: boolean }) => {
  const { inflation } = useRecoilValue(localCostConfigurationAtom);
  const { updateInflation } = useLocalCostConfigurationCrud();

  const [localConfig, setLocalConfig] = useRecoilState(
    localCostConfigurationAtom,
  );
  const useConfidenceLevel = localConfig.useConfidenceLevel;

  return (
    <TableWrapper>
      <Row>
        <Label
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <InputNumber
            compact
            style={{ width: "6rem", alignSelf: "center" }}
            value={inflation.referenceYearCapex}
            validate={between(2010, 2050)}
            validationMessage={"Expected to be in the range 2010 to 2050"}
            disabled={isReadOnly}
            onChange={(year) => {
              const referenceYearCapex = Math.round(year);
              updateInflation({
                referenceYearCapex,
              });
            }}
          />
          <Row style={{ whiteSpace: "nowrap" }}>
            Reference year
            <HelpTooltip
              size={10}
              text="Numbers in this pane will be inflated / deflated with respect to this year."
            />
          </Row>
        </Label>
        <Label
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Toggle
            disabled={isReadOnly}
            checked={useConfidenceLevel ?? false}
            onChange={() => {
              setLocalConfig({
                ...localConfig,
                useConfidenceLevel: !useConfidenceLevel,
              });
            }}
            size={ToggleSize.SMALL}
          />
          <Row>
            Use confidence level
            <HelpTooltip
              size={10}
              text="The confidence levels for cost inputs are intended only for visualization and communication; they do not affect the actual numbers in the analysis"
            />
          </Row>
        </Label>
      </Row>
      <TurbineCosts isReadOnly={isReadOnly} />
      <CableCosts isReadOnly={isReadOnly} />
      <FoundationCosts isReadOnly={isReadOnly} />
      <MooringCosts isReadOnly={isReadOnly} />
      <SubstationCosts isReadOnly={isReadOnly} />
      <ExportCableCosts isReadOnly={isReadOnly} />
      <OtherCosts isReadOnly={isReadOnly} />
    </TableWrapper>
  );
};
