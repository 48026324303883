/// <reference types="vite-plugin-svgr/client" />
import styled from "styled-components";
import {
  borderRadiusMedium,
  spaceLarge,
  spaceMedium,
  spaceSmall,
} from "../../styles/space";
import { colors } from "../../styles/colors";
import { Comp } from "../../types/utils";
import React, { CSSProperties, ReactElement, forwardRef } from "react";
import Close from "@icons/24/Close.svg?react";
import WindowsIcon from "@icons/24/Windows.svg?react";
import { IconBtn } from "./Icons";
import { DotMenuWrapper } from "../Dashboard/Widgets/Base.style";
import { DotMenu } from "./MenuButton";
import Tooltip from "components/General/Tooltip";

const FrameDiv = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  min-height: 0;
  z-index: 3;
  background: ${colors.background};
  border-radius: ${borderRadiusMedium};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  color: ${colors.primaryText};
  overflow: hidden;
  position: relative;

  ${({ width }) => width !== undefined && `width: ${width};`}

  padding-top: ${spaceLarge};
  > :first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    margin-top: 0;
  }
  > :last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }
  padding-bottom: ${spaceLarge};

  > * {
    padding-left: ${spaceLarge};
    padding-right: ${spaceLarge};
  }

  h2,
  h3,
  h4,
  h5 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${spaceMedium};
  }
`;

export const Frame = forwardRef<
  HTMLDivElement,
  Comp<"div", { width?: string }>
>(
  (
    { children, width = "30rem", ...props }: Comp<"div", { width?: string }>,
    ref,
  ) => {
    return (
      <FrameDiv width={width} {...props} ref={ref}>
        {children}
      </FrameDiv>
    );
  },
);

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaceMedium};
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const Row = styled.div<{
  alignCenter?: boolean;
  justifyCenter?: boolean;
}>`
  display: flex;
  flex-direction: row;
  gap: ${spaceMedium};
  ${({ alignCenter }) => alignCenter && `align-items: center;`}
  ${({ justifyCenter }) => justifyCenter && `justify-content: center;`}
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spaceSmall};
  align-items: center;
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`;

type ModalFrameProps = {
  title?: string;
  icon?: JSX.Element;
  onExit?: () => void;
  onOpenInNewWindow?: () => void;
  frameRef?: React.Ref<HTMLDivElement>;
  headerId?: string;
  h2style?: CSSProperties;
  menuItems?: ReactElement;
  fullscreen?: boolean;
  closeOnWrapperClick?: boolean;
};
export const ModalFrame = ({
  title,
  icon,
  onExit,
  onOpenInNewWindow,
  style,
  frameRef,
  children,
  headerId,
  h2style,
  menuItems,
  fullscreen,
  closeOnWrapperClick = true,
  onClick,
}: Comp<"div", ModalFrameProps>) => {
  return (
    <>
      <Frame style={style} ref={frameRef} onClick={onClick}>
        <h2
          id={headerId}
          style={{
            display: "flex",
            justifyContent: "baseline",
            marginBottom: "0.5lh",
            ...h2style,
          }}
        >
          <TitleRow>
            {icon}
            {title}
          </TitleRow>
          {(onExit || menuItems) && <div style={{ flex: 1 }} />}
          {menuItems && (
            <DotMenuWrapper>
              <DotMenu>{menuItems}</DotMenu>
            </DotMenuWrapper>
          )}
          {onOpenInNewWindow && (
            <Tooltip text="Open in new window">
              <IconBtn size="1.5rem" onClick={onOpenInNewWindow}>
                <WindowsIcon />
              </IconBtn>
            </Tooltip>
          )}
          {onExit && (
            <IconBtn size="1.4rem" onClick={() => onExit()}>
              <Close />
            </IconBtn>
          )}
        </h2>
        {children}
      </Frame>
      {fullscreen && (
        <Wrapper onClick={closeOnWrapperClick ? onExit : undefined} />
      )}
    </>
  );
};
