import { useEffect, useMemo, useRef } from "react";
import Plotly from "plotly.js-dist-min";
import { AnchorFeature } from "types/feature";
import { Raster } from "types/raster";
import { sendWarning } from "utils/sentry";
import { isDefined } from "utils/predicates";
import { fastMax, fastMin } from "utils/utils";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";

const AnchorDistribution = ({
  anchors,
  depthRaster,
}: {
  anchors: AnchorFeature[];
  depthRaster: Raster;
}) => {
  const graphRef = useRef<HTMLDivElement>(null);

  const anchorDepths = useMemo(() => {
    return anchors.map((anchor) => {
      const [lon, lat] = anchor.geometry.coordinates;

      if (!depthRaster.contains(lon, lat)) {
        const { minLon, maxLat, stepLon, stepLat, width, height } = depthRaster;
        sendWarning("Anchor is outside depth raster", {
          lon,
          lat,
          minLon,
          maxLat,
          stepLon,
          stepLat,
          width,
          height,
        });
        return undefined;
      }
      return depthRaster.latLngToValue(lon, lat);
    });
  }, [anchors, depthRaster]);

  const validAnchorDepths = anchorDepths.filter(isDefined);

  useEffect(() => {
    if (!graphRef.current || !validAnchorDepths) return;

    var layout = {
      font: { size: 8, family: "Open Sans" },
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
      autosize: true,
      height: 200,
      yaxis: {
        title: "Number of anchors",
      },
      xaxis: {
        title: "Depth",
      },
      margin: {
        l: 30,
        r: 0,
        b: 30,
        t: 0,
      },
    };

    const data = [
      {
        x: validAnchorDepths,
        type: "histogram" as const,
        xbins: {
          size: 1,
          start: fastMin(validAnchorDepths ?? [], 0),
          end: fastMax(validAnchorDepths ?? [], 0),
        },
      },
    ];

    Plotly.newPlot(graphRef.current, data, layout, {
      displayModeBar: false,
      responsive: true,
      staticPlot: false,
    });
  }, [anchors, validAnchorDepths]);

  return (
    <>
      {anchorDepths.length > validAnchorDepths.length && (
        <div>
          <SimpleAlert
            text={
              " Some anchors are too far away from the park to be shown in the depthplot."
            }
            type={"error"}
          />{" "}
        </div>
      )}
      <div ref={graphRef} />
    </>
  );
};

export default AnchorDistribution;
