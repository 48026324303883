export const EMPTY_LIST = [];

/**
 * Return `EMPTY_LIST` if the given list is empty, otherwise return the list.
 * This is useful for Recoil reasons, so that we get referential equality
 * of empty lists.
 */
export const replaceEmpty = <T>(list: T[]): T[] =>
  list.length === 0 ? EMPTY_LIST : list;

/**
 * {@link Array.filter}, but return the original list if no elements are
 * filtered out. Also wrap the result in {@link replaceEmpty}.
 */
export const recoilFilter = <T>(
  list: T[],
  predicate: (t: T) => boolean,
): T[] => {
  const filtered = list.filter(predicate);
  return replaceEmpty(filtered.length === list.length ? list : filtered);
};
