import {
  bboxToCoords,
  geotiffGeojsonEntry,
  GeotiffType,
} from "../utils/geojson/utils";
import { fromBlob } from "geotiff";
import { fetchEnhancerWithToken } from "./utils";
import { SetterOrUpdater } from "recoil";
import { getProj4StringForEPSG } from "./epsgAPIService";
import { projectedToWGS84 } from "../utils/proj4";
import { SignedUrlResponse } from "../types/api";
import {
  THIRTY_MEGABYTES,
  PROJECT_DATA_API_PATH_V3,
  PROJECT_DATA_API_VERSION,
} from "./projectDataAPIService";
import { GeotiffFeature } from "types/feature";

export const uploadGeoTiff = async (
  blob: Blob,
  nodeId: string,
  setToastMessage: SetterOrUpdater<any[]>,
  geotiffType: GeotiffType,
  maxFileSize = THIRTY_MEGABYTES,
  name?: string,
) => {
  if (blob.size > maxFileSize) {
    setToastMessage((tm) => [
      ...tm,
      {
        text: `The size of the georeferenced image is ${Math.ceil(
          blob.size / (1024 * 1024),
        )}MB which is larger than the maximum allowed ${Math.floor(
          maxFileSize / (1024 * 1024),
        )}MB`,
        timeout: 10000,
        type: "error",
      },
    ]);
    return;
  }
  const signedUrlResponse = await fetchEnhancerWithToken(
    `${PROJECT_DATA_API_PATH_V3}/${geotiffType}/${nodeId}/new`,
    {
      method: "get",
      headers: {
        "x-project-data-client-version": PROJECT_DATA_API_VERSION,
      },
    },
  );

  if (signedUrlResponse.status !== 200) {
    setToastMessage((tm) => [
      ...tm,
      {
        text: "Something went wrong when trying to save the image...",
        timeout: 2000,
        type: "error",
      },
    ]);
    return;
  }

  const signedUrl = (await signedUrlResponse.json()) as SignedUrlResponse;

  const formData = new FormData();
  Object.entries(signedUrl.fields).forEach(([k, v]) => {
    formData.append(k, v);
  });
  formData.append("file", blob);

  setToastMessage((tm) => [
    ...tm,
    {
      text: "Uploading image...",
      timeout: 2000,
    },
  ]);

  const uploadFileResponse = await fetch(signedUrl.url, {
    method: "post",
    body: formData,
  });

  if (uploadFileResponse.status !== 204) {
    setToastMessage((tm) => [
      ...tm,
      {
        text: "Something went wrong when trying to save the image...",
        timeout: 2000,
        type: "error",
      },
    ]);
    return;
  }

  const filename = signedUrl.fields.key.split("/").at(-1) ?? "";
  const geotiff = await fromBlob(blob);
  const geotiffImage = await geotiff.getImage();
  const bbox = geotiffImage.getBoundingBox();

  const epsg = geotiffImage.geoKeys.ProjectedCSTypeGeoKey;

  if (!epsg || epsg === 4326)
    return geotiffGeojsonEntry(filename, bboxToCoords(bbox), geotiffType, name);

  const proj4String = await getProj4StringForEPSG(epsg);
  const coords = [
    projectedToWGS84([bbox[0], bbox[1]], proj4String),
    projectedToWGS84([bbox[0], bbox[3]], proj4String),
    projectedToWGS84([bbox[2], bbox[3]], proj4String),
    projectedToWGS84([bbox[2], bbox[1]], proj4String),
    projectedToWGS84([bbox[0], bbox[1]], proj4String),
  ];
  return geotiffGeojsonEntry(filename, coords, geotiffType, name);
};

export async function* uploadGeoTiffGenerator(
  blob: Blob,
  nodeId: string,
  // setToastMessage: SetterOrUpdater<any[]>,
  geotiffType: GeotiffType,
  maxFileSize = THIRTY_MEGABYTES,
  name?: string,
): AsyncGenerator<string, GeotiffFeature, string> {
  if (blob.size > maxFileSize) {
    throw new Error(
      `The size of the georeferenced image is ${Math.ceil(
        blob.size / (1024 * 1024),
      )}MB which is larger than the maximum allowed ${Math.floor(
        maxFileSize / (1024 * 1024),
      )}MB`,
    );
  }
  const signedUrlResponse = await fetchEnhancerWithToken(
    `${PROJECT_DATA_API_PATH_V3}/${geotiffType}/${nodeId}/new`,
    {
      method: "get",
      headers: {
        "x-project-data-client-version": PROJECT_DATA_API_VERSION,
      },
    },
  );

  if (signedUrlResponse.status !== 200) {
    throw new Error("Something went wrong when trying to save the image...");
  }

  const signedUrl = (await signedUrlResponse.json()) as SignedUrlResponse;

  const formData = new FormData();
  Object.entries(signedUrl.fields).forEach(([k, v]) => {
    formData.append(k, v);
  });
  formData.append("file", blob);

  yield "Uploading image";

  const uploadFileResponse = await fetch(signedUrl.url, {
    method: "post",
    body: formData,
  });

  if (uploadFileResponse.status !== 204) {
    throw new Error("Something went wrong when trying to save the image...");
  }

  const filename = signedUrl.fields.key.split("/").at(-1) ?? "";
  const geotiff = await fromBlob(blob);
  const geotiffImage = await geotiff.getImage();
  const bbox = geotiffImage.getBoundingBox();

  const epsg = geotiffImage.geoKeys.ProjectedCSTypeGeoKey;

  if (!epsg || epsg === 4326)
    return geotiffGeojsonEntry(filename, bboxToCoords(bbox), geotiffType, name);

  const proj4String = await getProj4StringForEPSG(epsg);
  const coords = [
    projectedToWGS84([bbox[0], bbox[1]], proj4String),
    projectedToWGS84([bbox[0], bbox[3]], proj4String),
    projectedToWGS84([bbox[2], bbox[3]], proj4String),
    projectedToWGS84([bbox[2], bbox[1]], proj4String),
    projectedToWGS84([bbox[0], bbox[1]], proj4String),
  ];
  return geotiffGeojsonEntry(filename, coords, geotiffType, name);
}
