import React, { useCallback, useEffect, useRef } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Column, Row } from "components/General/Layout";
import AddIcon from "@icons/24/Add.svg";
import ChatIcon from "@icons/24/Chat.svg";
import CoordinatesAltIcon from "@icons/24/CoordinatesAlt.svg";
import InfoIcon from "@icons/24/Information.svg";
import ListViewIcon from "@icons/16/ListView.svg";
import RemoveIcon from "@icons/24/Remove.svg";
import UploadIcon from "@icons/24/Upload.svg";
import { spacing1, spacing6 } from "styles/space";
import Button from "components/General/Button";
import { IconBtn } from "components/General/Icons";
import { MenuItem } from "components/General/Menu";
import SplitButton from "components/General/SplitButton";
import { modalTypeOpenAtom } from "state/modal";
import { Anchor } from "components/General/Anchor";
import useBooleanState from "hooks/useBooleanState";
import { VerticalDivider } from "components/General/VerticalDivider";
import { colors } from "styles/colors";
import { OrganisationResourceChangelogButton } from "components/Changelog/Changelog";
import { Mixpanel } from "mixpanel";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { organisationIdSelector } from "state/pathParams";
import { useRecoilValueDef } from "utils/recoil";
import { typography } from "styles/typography";
import {
  organisationLibraryPackageSelector,
  selectedOrganisationPackageLayersAtom,
} from "../state";
import { AddLayersFromAllDataListModalType } from "../modals/AddLayersFromAllDataListModal";
import { UploadOrganisationDataLayerModalType } from "../modals/UploadOrganisationLayerModal";
import { UploadOrganisationLayerFromCoordinatesModalType } from "../modals/UploadOrganisationLayerFromCoordinatesModal";
import { DataLibraryNewEntry } from "../DataLibraryTab.style";
import EditDescriptionModalFrame from "../EditDescriptionModalFrame";
import { useDataLibraryLayersCrud } from "../useDataLibraryLayersCrud";
import { SelectedLayersWrapper } from "../shared";
import PackageTable from "./PackageTable";

const AddLayerToLibrarySplitButton = ({ packageId }: { packageId: string }) => {
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);

  return (
    <SplitButton
      buttonType="primary"
      text="Add"
      icon={<AddIcon />}
      onClick={() => {
        Mixpanel.track("Add library layer to package click DEFAULT", {});
        setModalTypeOpen({
          modalType: AddLayersFromAllDataListModalType,
          metadata: {
            packageId,
          },
        });
      }}
    >
      <MenuItem
        name="From All data"
        defaultButton={true}
        onClick={() => {
          Mixpanel.track(
            "Add library layer to package click From all data",
            {},
          );
          setModalTypeOpen({
            modalType: AddLayersFromAllDataListModalType,
            metadata: {
              packageId,
            },
          });
        }}
        icon={<ListViewIcon />}
      />
      <MenuItem
        name="From file"
        defaultButton={false}
        onClick={() => {
          Mixpanel.track("Add library layer to package click From file", {});
          setModalTypeOpen({
            modalType: UploadOrganisationDataLayerModalType,
            metadata: {
              addToPackageId: packageId,
            },
          });
        }}
        icon={<UploadIcon />}
      />
      {/* Todo: Uncomment when external layers are addable */}
      {/*<MenuItem
        name="Connect external server"
        onClick={() => {}}
        icon={<DatabaseIcon />}
      />*/}
      <MenuItem
        name="From coordinates"
        onClick={() => {
          Mixpanel.track(
            "Add library layer to package click From coordinates",
            {},
          );
          setModalTypeOpen({
            modalType: UploadOrganisationLayerFromCoordinatesModalType,
            metadata: {
              addToPackageId: packageId,
            },
          });
        }}
        icon={<CoordinatesAltIcon />}
      />
      {/* Todo: Uncomment when external layers are addable */}
      {/*<MenuItem
        name="From connected server"
        onClick={() => {}}
        icon={<GlobeIcon />}
      />*/}
    </SplitButton>
  );
};

export const PackageSection = ({
  packageId,
  setActivePackageId,
}: {
  packageId: string;
  setActivePackageId(packageId: string | undefined): void;
}) => {
  const organisationId = useRecoilValueDef(organisationIdSelector);
  const editDescriptionBtnRef = useRef<HTMLButtonElement>(null);
  const { updatePackage, removeLayersFromPackage } = useDataLibraryLayersCrud();
  const [isOpen, toggleIsOpen] = useBooleanState(false);
  const pkg = useRecoilValue(organisationLibraryPackageSelector({ packageId }));
  const [selectedLayers, setSelectedLayers] = useRecoilState(
    selectedOrganisationPackageLayersAtom,
  );
  const setContent = useSetRecoilState(
    organisationRightSideModal(organisationId),
  );

  // Reset active package id if package is not found
  useEffect(() => {
    if (!pkg) {
      setActivePackageId(undefined);
    } else {
      setContent((curr) => {
        if (curr?.type === "resource") {
          return { type: "resource", id: pkg.id };
        }
        return curr;
      });
    }
  }, [pkg, setActivePackageId, setContent]);

  const onRemoveSelectedClick = useCallback(async () => {
    Mixpanel.track("Delete multiple library layers from package", {
      nrLayers: selectedLayers.length,
    });
    if (
      !confirm(
        "Are you sure you want to remove the selected layers from the package?",
      )
    ) {
      return;
    }

    setSelectedLayers([]);
    await removeLayersFromPackage(organisationId, packageId, selectedLayers);
  }, [
    organisationId,
    packageId,
    removeLayersFromPackage,
    selectedLayers,
    setSelectedLayers,
  ]);

  if (!pkg) {
    return null;
  }

  return (
    <Column
      style={{
        gap: "0.8rem",
        overflowY: "hidden",
        flexGrow: 1,
        flexShrink: 0,
        height: "100%",
      }}
    >
      <Row
        alignCenter
        style={{
          gap: spacing6,
        }}
      >
        <div
          style={{
            height: "6rem",
            display: "flex",
            alignItems: "center",
            paddingLeft: spacing1,
          }}
        >
          {selectedLayers.length > 0 ? (
            <SelectedLayersWrapper>
              <p style={{ ...typography.regular, color: colors.blue700 }}>
                {selectedLayers.length} selected
              </p>
              <VerticalDivider />
              <Button
                buttonType="text"
                icon={<RemoveIcon />}
                onClick={onRemoveSelectedClick}
                title="Remove layers from package"
                text="Remove"
              />
            </SelectedLayersWrapper>
          ) : (
            <AddLayerToLibrarySplitButton packageId={packageId} />
          )}
        </div>
        <IconBtn
          buttonType="secondary"
          ref={editDescriptionBtnRef}
          size="1.4rem"
          onClick={toggleIsOpen}
          stroke={pkg.description ? colors.iconInfo : undefined}
          $fill={pkg.description ? colors.iconInfo : undefined}
        >
          <ChatIcon />
        </IconBtn>
        {isOpen && (
          <Anchor baseRef={editDescriptionBtnRef} floatPlace={"topRight"}>
            <EditDescriptionModalFrame
              updateDescription={(description: string) => {
                Mixpanel.track("Update library package description", {
                  descriptionLength: description.length,
                });
                return updatePackage(organisationId, pkg.id, {
                  description,
                });
              }}
              defaultValue={pkg.description}
              close={toggleIsOpen}
              subtitle={
                <div>
                  <p>Add a short description to the package.</p>
                  <p>
                    The description will be visible for members and projects
                    with access to this package.
                  </p>
                </div>
              }
            />
          </Anchor>
        )}
        <IconBtn
          buttonType="secondary"
          size="1.4rem"
          onClick={() => {
            setContent({ type: "resource", id: packageId });
          }}
        >
          <InfoIcon />
        </IconBtn>
      </Row>
      {pkg.layerIds.length > 0 ? (
        <>
          <PackageTable packageId={pkg.id} layerIds={pkg.layerIds} />
          <OrganisationResourceChangelogButton
            organisationId={organisationId}
            resourceId={pkg.id}
          />
        </>
      ) : (
        <div style={{ width: "25rem" }}>
          <DataLibraryNewEntry
            icon={<UploadIcon />}
            title="Add files"
            text="Add data directly to a package"
            actionButton={
              <AddLayerToLibrarySplitButton packageId={packageId} />
            }
          />
        </div>
      )}
    </Column>
  );
};

export default PackageSection;
