import { selector } from "recoil";
import { ProjectFeature } from "../../types/feature";
import {
  cableChainsFeaturesSelectorFamily,
  cablePartitionFeaturesSelectorFamily,
} from "../../state/cableEdit";
import { isPark } from "../../utils/predicates";
import { projectFeaturesSelector } from "./state";
import { parkIdSelector } from "../../state/pathParams";

export const selectableDerivedFeatures = selector<ProjectFeature[]>({
  key: "selectableDerivedFeatures",
  get: ({ get }) => {
    const parkId = get(parkIdSelector);
    const features = get(projectFeaturesSelector);
    if (parkId) {
      const chains = get(cableChainsFeaturesSelectorFamily({ parkId }));
      const partitions = get(cablePartitionFeaturesSelectorFamily({ parkId }));
      return [...features, ...chains, ...partitions];
    } else {
      const parks = features.filter(isPark);
      const chains = parks
        .map((p) => get(cableChainsFeaturesSelectorFamily({ parkId: p.id })))
        .flat();
      const partitions = parks
        .map((p) => get(cablePartitionFeaturesSelectorFamily({ parkId: p.id })))
        .flat();

      return [...features, ...chains, ...partitions];
    }
  },
});

export const selectableFeatureMap = selector<Map<string, ProjectFeature>>({
  key: "selectableFeatureMap",
  get: ({ get }) => {
    const features = get(selectableDerivedFeatures);
    return new Map(features.map((f) => [f.id, f]));
  },
});
