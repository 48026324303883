import { LineString } from "@turf/turf";
import {
  allCableTypesSelector,
  currentExportCableTypesSelector,
} from "components/Cabling/Generate/state";
import { Point } from "geojson";
import {
  UnwrapRecoilValue,
  atom,
  atomFamily,
  selector,
  selectorFamily,
} from "recoil";
import { v4 as uuid4 } from "uuid";
import { z } from "zod";
import {
  PROJECT_SERVICE_API_PATH,
  PROJECT_SERVICE_API_VERSION,
} from "../components/ProjectElements/service";
import {
  projectFeaturesInBranchSelectorFamily,
  projectFeaturesSelector,
} from "../components/ProjectElements/state";
import {
  CABLE_PROPERTY_TYPE,
  SUBSTATION_PROPERTY_TYPE,
} from "../constants/cabling";
import {
  fetchEnhancerWithToken,
  fetchSchemaWithToken,
} from "../services/utils";
import { colors } from "../styles/colors";
import {
  CableChainFeature,
  CableCorridorFeature,
  CableFeature,
  ExportCableFeature,
  SubstationFeature,
} from "../types/feature";
import {
  isCable,
  isCableCorridor,
  isDefined,
  isExportCable,
  isSubstation,
} from "../utils/predicates";
import { MooringCoords } from "./mooring";
import { getParkFeaturesSelector, inPark } from "./park";
import { currentSubstationTypesState } from "./substationType";
import { EMPTY_LIST, replaceEmpty } from "./recoil";
import { pointInPolygon } from "utils/geometry";
import { parkIdSelector } from "./pathParams";
import { getSelectedTurbines } from "./turbines";
import {
  currentSelectedProjectFeatures,
  currentSelectionArrayAtom,
} from "./selection";
import { labelledCableChainsFeaturesSelectorFamily } from "./cableEdit";
import { exportCableSplits } from "functions/elevation";
import { _IAVoltageType } from "services/cableTypeService";
import { count, dedup, maxBy } from "utils/utils";
import { getSelectedAreaStrict } from "./division";

export const HANG_OFF_ANGLE_LAZY_WAVE = (80 * Math.PI) / 180;
export const Z_SAG_DEPTH_FRACTION = 2 / 5;
export const Z_HOG_DEPTH_FRACTION = 3 / 5;
export const CABLE_SUBMERGED_WEIGHT = 40 * 9.81;
export const CABLE_BUOY_SUBMERGED_WEIGHT = CABLE_SUBMERGED_WEIGHT;
export const MIN_CABLE_LENGTH_KM = 0.001;
export const NUM_ELECTRICAL_POWER_BINS = 41;

export const substationFeature = (
  position: Point,
  id: string,
  parkId?: string,
  substationTypeId?: string,
): SubstationFeature => {
  return {
    type: "Feature",
    id,
    geometry: position,
    properties: {
      type: SUBSTATION_PROPERTY_TYPE,
      color: colors.substation,
      id,
      parentIds: parkId ? [parkId] : [],
      name: "Substation",
      substationTypeId,
    },
  };
};

export const cableFeature = (
  cable: {
    fromId: string;
    toId: string;
  },
  geometry: LineString,
  parkId?: string,
): CableFeature => {
  const id = uuid4();
  const properties: CableFeature["properties"] = {
    type: CABLE_PROPERTY_TYPE,
    id,
    parentIds: parkId ? [parkId] : [],
    name: "Cable",
    ...cable,
  };
  return {
    type: "Feature",
    id,
    geometry,
    properties,
  };
};

export const getAllCablesSelector = selector<CableFeature[]>({
  key: "getAllCablesSelector",
  get: ({ get }) => {
    const projectData = get(projectFeaturesSelector);
    return projectData.filter(isCable);
  },
});

export const getCablesSelectorFamily = selectorFamily<
  CableFeature[],
  { parkId: string }
>({
  key: "getCablesSelectorFamily",
  get:
    ({ parkId }) =>
    ({ get }) => {
      const projectData = get(projectFeaturesSelector);
      const allCables = projectData.filter(isCable);
      const cableFeatures = allCables.filter((f) =>
        f.properties.parentIds?.includes(parkId),
      );
      return cableFeatures;
    },
});

export const getCablesInBranchSelectorFamily = selectorFamily<
  CableFeature[],
  { parkId: string; branchId: string }
>({
  key: "getCablesInBranchSelectorFamily",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const projectData = get(
        projectFeaturesInBranchSelectorFamily({ branchId }),
      );
      const allCables = projectData.filter(isCable);
      const cableFeatures = allCables.filter((f) =>
        f.properties.parentIds?.includes(parkId),
      );
      return cableFeatures;
    },
});

export const getExportCablesSelectorFamily = selectorFamily<
  ExportCableFeature[],
  { parkId: string }
>({
  key: "getExportCablesSelectorFamily",
  get:
    ({ parkId }) =>
    ({ get }) => {
      const projectData = get(projectFeaturesSelector);
      const exportCableFeatures = projectData
        .filter(isExportCable)
        .filter((f) => f.properties?.parentIds?.includes(parkId));
      // Må vi legge det på her og? Sjekk hvor denne brukes
      return exportCableFeatures;
    },
});

export const getExportCablesInBranchSelectorFamily = selectorFamily<
  ExportCableFeature[],
  { parkId: string; branchId: string; exportCableTypeOverrideId?: string }
>({
  key: "getExportCablesInBranchSelectorFamily",
  get:
    ({ parkId, branchId, exportCableTypeOverrideId }) =>
    ({ get }) => {
      const projectData = get(
        projectFeaturesInBranchSelectorFamily({ branchId }),
      );
      const exportCableFeatures = projectData
        .filter(isExportCable)
        .filter((f) => f.properties?.parentIds?.includes(parkId));

      const updatedExportCableFeatures = exportCableTypeOverrideId
        ? exportCableFeatures.map((t) => ({
            ...t,
            properties: {
              ...t.properties,
              cableTypeId: exportCableTypeOverrideId ?? "",
            },
          }))
        : exportCableFeatures;

      return updatedExportCableFeatures;
    },
});

export const getCableCorridorsSelectorFamily = selectorFamily<
  CableCorridorFeature[],
  { parkId: string; branchId: string }
>({
  key: "getCableCorridorsSelectorFamily",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const projectData = get(
        projectFeaturesInBranchSelectorFamily({ branchId }),
      );
      const cableCorridorFeatures = projectData
        .filter(isCableCorridor)
        .filter(inPark(parkId));
      return cableCorridorFeatures;
    },
});

export const liveSubstationAtom = atom<SubstationFeature | undefined>({
  key: "liveSubstationAtom",
  default: undefined,
});

export const keepExportModalOpenAtom = atom<boolean>({
  key: "keepExportModalOpenAtom",
  default: false,
});

export const currentSubstationIdAtom = atom<string | undefined>({
  key: "currentSubstationIdAtom",
  default: selector({
    key: "currentSubstationIdSelector",
    get: ({ get }) => {
      const substationTypes = get(currentSubstationTypesState);
      if (substationTypes.length === 0) return undefined;
      return substationTypes[0].id;
    },
  }),
});

export const currentExportCableIdAtom = atom<
  { offshore: string; onshore: string } | undefined
>({
  key: "currentExportCableIdAtom",
  default: selector({
    key: "currentExportCableIdSelector",
    get: ({ get }) => {
      const exportCableTypes = get(currentExportCableTypesSelector);
      if (exportCableTypes.length === 0) return undefined;
      return {
        offshore: exportCableTypes[0].id,
        onshore: exportCableTypes[0].id,
      };
    },
  }),
});

export const getSubstationsSelectorFamily = selectorFamily<
  SubstationFeature[],
  { parkId: string }
>({
  key: "getSubstationsSelectorFamily",
  get:
    ({ parkId }) =>
    ({ get }) => {
      const projectData = get(projectFeaturesSelector);
      const substationFeatures = projectData
        .filter(isSubstation)
        .filter((f) => f.properties?.parentIds?.includes(parkId));
      return substationFeatures;
    },
});

export const getOffshoreSubstationsInBranchSelectorFamily = selectorFamily<
  SubstationFeature[],
  { parkId: string; branchId: string }
>({
  key: "getOffshoreSubstationsInBranchSelectorFamily",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const substationTypes = get(currentSubstationTypesState);
      const substationFeatures = get(
        getSubstationsInBranchSelectorFamily({ parkId, branchId }),
      ).filter((s) =>
        substationTypes.find(
          (st) =>
            st.id === s.properties.substationTypeId && st.type === "offshore",
        ),
      );
      return substationFeatures;
    },
});

export const getOnshoreSubstationsInBranchSelectorFamily = selectorFamily<
  SubstationFeature[],
  { parkId: string; branchId: string }
>({
  key: "getOnshoreSubstationsInBranchSelectorFamily",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const substationTypes = get(currentSubstationTypesState);
      const substationFeatures = get(
        getSubstationsInBranchSelectorFamily({ parkId, branchId }),
      ).filter((s) =>
        substationTypes.find(
          (st) =>
            st.id === s.properties.substationTypeId && st.type === "onshore",
        ),
      );
      return substationFeatures;
    },
});

export const getSubstationsInBranchSelectorFamily = selectorFamily<
  SubstationFeature[],
  { parkId: string; branchId: string }
>({
  key: "getSubstationsInBranchSelectorFamily",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const projectData = get(
        projectFeaturesInBranchSelectorFamily({ branchId }),
      );
      const substationFeatures = projectData
        .filter(isSubstation)
        .filter((f) => f.properties?.parentIds?.includes(parkId));
      return substationFeatures;
    },
});

export const getOffShoreSubstationsInBranchSelectorFamily = selectorFamily<
  SubstationFeature[],
  { parkId: string; branchId: string }
>({
  key: "getOffShoreSubstationsInBranchSelectorFamily",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const substationTypes = get(currentSubstationTypesState);
      const substationFeatures = get(
        getSubstationsInBranchSelectorFamily({ parkId, branchId }),
      ).filter((s) => {
        const substationType = substationTypes.find(
          (st) => st.id === s.properties.substationTypeId,
        );
        return substationType?.type === "offshore";
      });
      return substationFeatures;
    },
});

export const getSubstationsOutsideParkCableCorridorSelector = selectorFamily<
  SubstationFeature[],
  { parkId: string | undefined; branchId: string }
>({
  key: "getSubstationsOutsideParkCableCorridorSelector",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      if (!parkId) return EMPTY_LIST;
      const substationTypes = get(currentSubstationTypesState);
      const substations = get(
        getSubstationsInBranchSelectorFamily({ parkId, branchId }),
      );
      const offshoreSubstations = substations.filter((s) =>
        substationTypes.find(
          (st) =>
            st.id === s.properties.substationTypeId && st.type === "offshore",
        ),
      );
      const park = get(getParkFeaturesSelector).find((p) => p.id === parkId);
      if (!park) return EMPTY_LIST;
      const projectData = get(
        projectFeaturesInBranchSelectorFamily({ branchId }),
      );
      const parkCableCorridors = projectData
        .filter(isCableCorridor)
        .filter((cc: CableCorridorFeature) =>
          cc.properties.parentIds?.includes(parkId),
        );
      return replaceEmpty(
        offshoreSubstations.filter(
          (t) =>
            !pointInPolygon(t.geometry, park.geometry) &&
            !parkCableCorridors.some((cc) =>
              pointInPolygon(t.geometry, cc.geometry),
            ),
        ),
      );
    },
});

export const _CableTypeUsage = z.object({
  cableTypeId: z.string(),
  projectId: z.string(),
  branchId: z.string(),
  featureId: z.string(),
});
export type CableTypeUsageType = z.infer<typeof _CableTypeUsage>;

export async function getCableTypeUsage(nodeId: string, cableTypeId: string) {
  const headers = {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "x-project-data-client-version": PROJECT_SERVICE_API_VERSION,
    },
  };
  const res = await fetchEnhancerWithToken(
    `${PROJECT_SERVICE_API_PATH}/stats/node/${nodeId}/cableTypeId/${cableTypeId}`,
    headers,
  );
  const j = await res.json();
  return _CableTypeUsage.array().parse(j);
}

export const cableTypeUsageAtomFamily = atomFamily<
  CableTypeUsageType[],
  { nodeId: string; cableTypeId: string }
>({
  key: "cableTypeUsageAtomFamily",
  default: selectorFamily<
    CableTypeUsageType[],
    { nodeId: string; cableTypeId: string }
  >({
    key: "cableTypeUsageSelectorFamily",
    get:
      ({ nodeId, cableTypeId }) =>
      async () => {
        if (!nodeId) return [];
        const usage = await getCableTypeUsage(nodeId, cableTypeId);
        return usage;
      },
  }),
});

export const _CablesKeyVersions = z.object({
  author: z.string().optional(),
  start: z.number(),
  end: z.number(),
  meta: z.object({ count: z.number() }),
});
const _CableKeyVersionArray = z.array(_CablesKeyVersions);

export type CableKeyVersions = z.infer<typeof _CablesKeyVersions>;

export const getCableKeyVersions = async (
  nodeId: string,
): Promise<CableKeyVersions[]> =>
  fetchSchemaWithToken(
    _CableKeyVersionArray,
    `/api/cable/node/${nodeId}/key_versions`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-project-data-client-version": PROJECT_SERVICE_API_VERSION,
      },
    },
  );

export const keyCableVersionsState = selectorFamily<
  CableKeyVersions[],
  {
    projectId: string;
  }
>({
  key: "keyCableVersionsState",
  get: (o) => () => {
    return getCableKeyVersions(o.projectId);
  },
});

const lazyWave = ({
  waterDepth,
  hangOffAngle,
  zHog,
  zSag,
  w,
  wB,
}: {
  waterDepth: number;
  hangOffAngle: number;
  zHog: number;
  zSag: number;
  w: number;
  wB: number;
}): {
  x: number[];
  z: number[];
} => {
  const x: number[] = [];

  const z: number[] = [];

  const z5 = waterDepth - zSag;
  const H =
    ((w * z5) / Math.tan(hangOffAngle) ** 2) * (1 + 1 / Math.cos(hangOffAngle));
  const x5 = (H / w) * Math.acosh((w * z5) / H + 1);
  const s5 = (H / w) * Math.sinh((w * x5) / H);

  const z4 = (wB / (w + wB)) * (zHog - zSag);
  const x4 = (H / w) * Math.acosh((w * z4) / H + 1);
  const s4 = (H / w) * Math.sinh((w * x4) / H);

  const z3 = (w / (w + wB)) * (zHog - zSag);
  const x3 = (H / wB) * Math.acosh((wB * z3) / H + 1);
  const s3 = (H / wB) * Math.sinh((wB * x3) / H);

  const z2 = (w / (w + wB)) * zHog;
  const x2 = (H / wB) * Math.acosh((wB * z2) / H + 1);
  const s2 = (H / wB) * Math.sinh((wB * x2) / H);

  const z1 = (wB / (w + wB)) * zHog;
  const x1 = (H / w) * Math.acosh((w * z1) / H + 1);
  const s1 = (H / w) * Math.sinh((w * x1) / H);

  const x50 = x5;
  const z50 = -z5;
  for (let _s = s5; _s >= 0; _s += -5) {
    const xUnrot = (-H / w) * Math.asinh((w * _s) / H) + x50;
    x.push(xUnrot);
    z.push((H / w) * (Math.cosh((w * (xUnrot - x50)) / H) - 1) + z50);
  }

  const x40 = x50;
  const z40 = z50;
  for (let _s = 0; _s <= s4; _s += 5) {
    const xUnrot = (H / w) * Math.asinh((w * _s) / H) + x40;
    x.push(xUnrot);
    z.push((H / w) * (Math.cosh((w * (xUnrot - x40)) / H) - 1) + z40);
  }

  const x30 = (H / w) * Math.asinh((w * s4) / H) + x40 + x3;
  const z30 = (H / w) * (Math.cosh((w * x4) / H) - 1) + z40 + z3;
  for (let _s = s3; _s >= 0; _s += -5) {
    const xUnrot = -(H / wB) * Math.asinh((wB * _s) / H) + x30;
    x.push(xUnrot);
    z.push(-(H / wB) * (Math.cosh((wB * (xUnrot - x30)) / H) - 1) + z30);
  }

  const x20 = x30;
  const z20 = z30;
  for (let _s = 0; _s <= s2; _s += 5) {
    const xUnrot = (H / wB) * Math.asinh((wB * _s) / H) + x20;
    x.push(xUnrot);
    z.push((-H / wB) * (Math.cosh((wB * (xUnrot - x20)) / H) - 1) + z20);
  }

  const x10 =
    (H / wB) * Math.asinh((wB * s2) / H) +
    x20 +
    (H / w) * Math.asinh((w * s1) / H);
  const z10 =
    (-H / wB) * (Math.cosh((wB * x2) / H) - 1) +
    z20 -
    (H / w) * (Math.cosh((w * x1) / H) - 1);
  for (let _s = s1; _s >= 0; _s += -5) {
    const xUnrot = (-H / w) * Math.asinh((w * _s) / H) + x10;
    x.push(xUnrot);
    z.push((H / w) * (Math.cosh((w * (xUnrot - x10)) / H) - 1) + z10);
  }

  return { x, z };
};

const bottomFixed = ({
  waterDepth,
}: {
  waterDepth: number;
}): {
  x: number[];
  z: number[];
} => {
  const x = [0, 0];
  const z = [0, -waterDepth];

  return { x, z };
};

export const cableVisualization = ({
  bearing,
  waterDepth,
  distance,
  floaterFrom,
  floaterTo,
}: {
  bearing: number[];
  waterDepth: number[];
  distance: number[];
  floaterFrom: boolean;
  floaterTo: boolean;
}): MooringCoords => {
  const zSagFrom = waterDepth[0] * Z_SAG_DEPTH_FRACTION;
  const zHogFrom = waterDepth[0] * Z_HOG_DEPTH_FRACTION;
  const zSagTo = waterDepth[waterDepth.length - 1] * Z_SAG_DEPTH_FRACTION;
  const zHogTo = waterDepth[waterDepth.length - 1] * Z_HOG_DEPTH_FRACTION;

  const w = 40 * 9.81;
  const wB = w;

  const accDistance: number[] = [];
  let count = 0;
  for (let i = 0; i < distance.length; i++) {
    count += distance[i];
    accDistance.push(count);
  }

  let CoordsFrom: { x: number[]; z: number[] },
    CoordsTo: { x: number[]; z: number[] };

  if (floaterFrom) {
    CoordsFrom = lazyWave({
      waterDepth: waterDepth[0],
      hangOffAngle: HANG_OFF_ANGLE_LAZY_WAVE,
      zHog: zHogFrom,
      zSag: zSagFrom,
      w,
      wB,
    });
  } else {
    CoordsFrom = bottomFixed({
      waterDepth: waterDepth[0],
    });
  }
  if (floaterTo) {
    CoordsTo = lazyWave({
      waterDepth: waterDepth[waterDepth.length - 1],
      hangOffAngle: HANG_OFF_ANGLE_LAZY_WAVE,
      zHog: zHogTo,
      zSag: zSagTo,
      w,
      wB,
    });
  } else {
    CoordsTo = bottomFixed({
      waterDepth: waterDepth[waterDepth.length - 1],
    });
  }

  const xCable: number[] = [];
  const yCable: number[] = [];
  const zCable: number[] = [];

  const totalDistance = accDistance[accDistance.length - 1];

  let seg = 0;
  let x0 = 0;
  let y0 = 0;
  for (let i = 0; i < CoordsFrom.x.length; i++) {
    if (CoordsFrom.x[i] > accDistance[seg]) {
      x0 += distance[seg] * Math.sin((bearing[seg] * Math.PI) / 180);
      y0 += distance[seg] * Math.cos((bearing[seg] * Math.PI) / 180);
      seg += 1;
      continue;
    }
    xCable.push(
      x0 + CoordsFrom.x[i] * Math.sin((bearing[seg] * Math.PI) / 180),
    );
    yCable.push(
      y0 + CoordsFrom.x[i] * Math.cos((bearing[seg] * Math.PI) / 180),
    );
    zCable.push(CoordsFrom.z[i]);
  }

  for (let i = seg; i < accDistance.length; i++) {
    if (
      accDistance[i] > CoordsFrom.x[CoordsFrom.x.length - 1] &&
      accDistance[i] < totalDistance - CoordsTo.x[CoordsFrom.x.length - 1]
    ) {
      x0 += distance[i] * Math.sin((bearing[i] * Math.PI) / 180);
      y0 += distance[i] * Math.cos((bearing[i] * Math.PI) / 180);
      xCable.push(x0);
      yCable.push(y0);
      zCable.push(-waterDepth[i + 1]);
    }
  }

  seg = 0;
  x0 = 0;
  y0 = 0;
  for (let i = CoordsTo.x.length - 1; i >= 0; i--) {
    if (totalDistance - CoordsTo.x[i] > accDistance[seg]) {
      x0 += distance[seg] * Math.sin((bearing[seg] * Math.PI) / 180);
      y0 += distance[seg] * Math.cos((bearing[seg] * Math.PI) / 180);
      seg += 1;
      continue;
    }
    xCable.push(
      x0 +
        (distance[seg] - CoordsTo.x[i]) *
          Math.sin((bearing[seg] * Math.PI) / 180),
    );
    yCable.push(
      y0 +
        (distance[seg] - CoordsTo.x[i]) *
          Math.cos((bearing[seg] * Math.PI) / 180),
    );
    zCable.push(CoordsTo.z[i]);
  }

  return { x: xCable, y: yCable, z: zCable };
};

export const lazyWaveLengthCorrection = ({
  waterDepth,
}: {
  waterDepth: number;
}): number => {
  const hangOffAngle = HANG_OFF_ANGLE_LAZY_WAVE;
  const zSag = waterDepth * Z_SAG_DEPTH_FRACTION;
  const zHog = waterDepth * Z_HOG_DEPTH_FRACTION;
  const w = CABLE_SUBMERGED_WEIGHT;
  const wB = CABLE_BUOY_SUBMERGED_WEIGHT;

  const z5 = waterDepth - zSag;
  const H =
    ((w * z5) / Math.tan(hangOffAngle) ** 2) * (1 + 1 / Math.cos(hangOffAngle));
  const x5 = (H / w) * Math.acosh((w * z5) / H + 1);
  const s5 = (H / w) * Math.sinh((w * x5) / H);

  const z4 = (wB / (w + wB)) * (zHog - zSag);
  const x4 = (H / w) * Math.acosh((w * z4) / H + 1);
  const s4 = (H / w) * Math.sinh((w * x4) / H);

  const z3 = (w / (w + wB)) * (zHog - zSag);
  const x3 = (H / wB) * Math.acosh((wB * z3) / H + 1);
  const s3 = (H / wB) * Math.sinh((wB * x3) / H);

  const z2 = (w / (w + wB)) * zHog;
  const x2 = (H / wB) * Math.acosh((wB * z2) / H + 1);
  const s2 = (H / wB) * Math.sinh((wB * x2) / H);

  const z1 = (wB / (w + wB)) * zHog;
  const x1 = (H / w) * Math.acosh((w * z1) / H + 1);
  const s1 = (H / w) * Math.sinh((w * x1) / H);

  const lazyWaveLength = s1 + s2 + s3 + s4 + s5;
  const horLength = x1 + x2 + x3 + x4 + x5;
  const lengthCorr = lazyWaveLength - (horLength + waterDepth);

  return lengthCorr;
};

export const getInvalidCableTypeCablesInParkandBranch = selectorFamily<
  CableFeature[],
  { parkId: string; branchId: string }
>({
  key: "getInvalidCableTypeCablesInParkandBranch",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const cables = get(getCablesInBranchSelectorFamily({ parkId, branchId }));
      const allCableTypes = get(allCableTypesSelector);
      const invalidCableTypeCables = cables.filter((c) =>
        allCableTypes.every((ct) => ct.id !== c.properties.cableTypeId),
      );

      return invalidCableTypeCables;
    },
});

export const getSelectedCables = selector<CableFeature[]>({
  key: "getSelectedCables",
  get: ({ get }) => {
    const parkId = get(parkIdSelector);
    if (!parkId) return [];
    const cablesInPark = get(getCablesSelectorFamily({ parkId }));
    const turbines = get(getSelectedTurbines);
    const ids = new Set(turbines.map((t) => t.id));
    const selectionIds = new Set(get(currentSelectionArrayAtom));
    return cablesInPark.filter(
      (c) =>
        ids.has(c.properties.fromId) ||
        ids.has(c.properties.toId) ||
        selectionIds.has(c.id),
    );
  },
});

/**
 * A substation is in the selection if it's connected to a cable in the
 * selection, or if it's directly selected.
 */
export const getSelectedSubstations = selector<SubstationFeature[]>({
  key: "getSelectedSubstations",
  get: ({ get }) => {
    const parkId = get(parkIdSelector);
    if (!parkId) return [];
    const cables = get(getSelectedCables);
    const selectionIds = get(currentSelectionArrayAtom);
    const ids = new Set(
      cables
        .flatMap((c) => [c.properties.fromId, c.properties.toId])
        .concat(selectionIds),
    );
    const subsInPark = get(getSubstationsSelectorFamily({ parkId }));
    return subsInPark.filter((s) => ids.has(s.id));
  },
});

/**
 * Strict version. Substation needs to be in the selection, or in an area in
 * the selection.
 */
export const getSelectedSubstationsStrict = selector<SubstationFeature[]>({
  key: "getSelectedSubstationsStrict",
  get: ({ get }) => {
    const parkId = get(parkIdSelector);
    if (!parkId) return [];

    const substationsInPark = get(getSubstationsSelectorFamily({ parkId }));
    const area = get(getSelectedAreaStrict);
    if (!area) {
      const selected = get(currentSelectedProjectFeatures);
      if (selected.length === 0) return substationsInPark;
      return selected.filter(isSubstation).filter(inPark(parkId));
    }

    if (Array.isArray(area)) {
      const subareaSubstations = substationsInPark.filter((t) =>
        area.some((a) => pointInPolygon(t.geometry, a.geometry)),
      );
      const selected = get(currentSelectedProjectFeatures)
        .filter(isSubstation)
        .filter(inPark(parkId));
      return dedup(subareaSubstations.concat(selected), (f) => f.id);
    }

    return substationsInPark;
  },
});

/**
 * Gets all chains to the selected substations.
 */
export const getSelectedCableChains = selector<CableChainFeature[]>({
  key: "getSelectedCableChains",
  get: ({ get }) => {
    const parkId = get(parkIdSelector);
    if (!parkId) return [];
    const subs = get(getSelectedSubstations);
    const ids = new Set(subs.map((s) => s.id));
    const chains = get(labelledCableChainsFeaturesSelectorFamily({ parkId }));
    return chains.filter((c) => ids.has(c.properties.substation));
  },
});

export const getSelectedExportCables = selector<ExportCableFeature[]>({
  key: "getSelectedExportCables",
  get: ({ get }) => {
    const parkId = get(parkIdSelector);
    if (!parkId) return [];
    const subs = get(getSelectedSubstations);
    const selectionIds = get(currentSelectionArrayAtom);
    const subids = new Set(subs.map((s) => s.id));
    const exids = new Set(selectionIds);
    const exportCables = get(getExportCablesSelectorFamily({ parkId }));
    return exportCables.filter(
      (s) =>
        exids.has(s.id) ||
        subids.has(s.properties.fromSubstationId ?? "") ||
        subids.has(s.properties.toSubstationId ?? ""),
    );
  },
});

export const getSelectedExportCableSplits = selector<
  UnwrapRecoilValue<ReturnType<typeof exportCableSplits>>
>({
  key: "getSelectedExportCableSplits",
  get: ({ get }) => {
    const parkId = get(parkIdSelector);
    if (!parkId) return [];

    const exportCables = get(getSelectedExportCables);
    const ids = new Set(exportCables.map((f) => f.id));
    const splits = get(exportCableSplits({ parkId }));
    return splits.filter((sp) => ids.has(sp.exportCable.id));
  },
});

export const getMostCommonCableTypeId = (
  cables: (CableFeature | ExportCableFeature)[],
): string | undefined => {
  if (cables.length === 0) return;
  const typeIds = cables.map((c) => c.properties.cableTypeId).filter(isDefined);
  const counts = count(typeIds);
  const [maxId] = maxBy([...counts.entries()], ([_, count]) => count) ?? [];
  return maxId;
};
