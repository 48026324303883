import { atom, atomFamily, selectorFamily } from "recoil";
import {
  CostConfiguration,
  CostConfigurationUsageType,
  fetchCostConfigurationUsage,
  listConfigurations,
} from "../services/costService";
import { scream } from "../utils/sentry";
import { getBranchSelectorFamily } from "./timeline";
import { dedup } from "utils/utils";
import { getFinancialResourcesOnNode } from "components/Organisation/Library/service";

export const savingCostConfigurationInProgressAtom = atom<boolean>({
  key: "savingCostConfigurationInProgressAtom",
  default: false,
});

export const costConfigurationUsageRefresh = atomFamily<
  number,
  { nodeId: string; costConfigurationId: string }
>({
  key: "costConfigurationUsageRefresh",
  default: () => {
    return 0;
  },
});

// This is used to store the config name while still editing the config
// to avoid rendering the component and loose the values that is stored in LocalConfig
export const costConfigurationTempName = atomFamily<
  { [key: string]: string | undefined },
  { nodeId: string }
>({
  key: "costConfigurationTempName",
  default: () => {
    return {};
  },
});

export const costConfigurationsAtomFamily = atomFamily<
  CostConfiguration[],
  { projectId: string | undefined }
>({
  key: "costConfigurationsAtomFamily",
  default: selectorFamily<
    CostConfiguration[],
    { projectId: string | undefined }
  >({
    key: "costConfigurationsAtomFamily.default",
    get:
      ({ projectId }) =>
      async () => {
        if (!projectId) return [];

        try {
          const { items } = await listConfigurations(projectId);

          return items;
        } catch (error) {
          scream("Failed to fetch cost configurations", { error });
          return [];
        }
      },
  }),
});

export const libraryAndProjectCostConfigurationsSelectorFamily = selectorFamily<
  CostConfiguration[],
  { nodeId: string | undefined }
>({
  key: "libraryAndProjectCostConfigurationsSelectorFamily",
  get:
    ({ nodeId }) =>
    ({ get }) => [
      ...get(costConfigurationsAtomFamily({ projectId: nodeId })),
      ...get(libraryFinancialConfigOnNodeTypesState({ nodeId })),
    ],
});

export const costConfigurationSelectorFamily = selectorFamily<
  CostConfiguration,
  {
    projectId: string;
    configurationId: string;
  }
>({
  key: "costConfigurationSelectorFamily",
  get:
    ({ projectId, configurationId }) =>
    ({ get }) => {
      const configs = get(
        libraryAndProjectCostConfigurationsSelectorFamily({
          nodeId: projectId,
        }),
      );

      const config = configs.find((c) => c.id === configurationId);

      if (!config) {
        throw new Error(
          `Cost configuration not found: projectId=${projectId}, configurationId=${configurationId}`,
        );
      }

      return config;
    },
});

export const branchSelectedCostConfigurationAtom = atomFamily<
  CostConfiguration | undefined,
  {
    projectId: string | undefined;
    branchId: string | undefined;
  }
>({
  key: "branchSelectedCostConfigurationAtom",
  default: selectorFamily<
    CostConfiguration | undefined,
    {
      projectId: string | undefined;
      branchId: string | undefined;
    }
  >({
    key: "branchSelectedCostSelectorFamily",
    get:
      ({ projectId, branchId }) =>
      ({ get }) => {
        if (!projectId || !branchId) return undefined;

        const configs = get(
          libraryAndProjectCostConfigurationsSelectorFamily({
            nodeId: projectId,
          }),
        );
        const branchMeta = get(
          getBranchSelectorFamily({
            projectId,
            branchId,
          }),
        );

        const selectedConfigId = branchMeta?.costConfigurationId;
        if (!selectedConfigId) return undefined;

        return configs.find((c) => c.id === selectedConfigId);
      },
  }),
});

export const costConfigurationUsageAtomFamily = atomFamily<
  CostConfigurationUsageType[],
  { nodeId: string; costConfigurationId: string }
>({
  key: "costConfigurationUsageAtomFamily",
  default: selectorFamily<
    CostConfigurationUsageType[],
    { nodeId: string; costConfigurationId: string }
  >({
    key: "costConfigurationUsageSelectorFamily",
    get:
      ({ nodeId, costConfigurationId }) =>
      async () => {
        if (!nodeId) return [];
        const usage = await fetchCostConfigurationUsage(
          nodeId,
          costConfigurationId,
        );
        return usage;
      },
  }),
});

// -------- Organisation cost configurations ------------------------

export const libraryFinancialConfigRefreshAtom = atom({
  key: "libraryFinancialConfigRefreshAtom",
  default: 1,
});

export const libraryFinancialConfigOnNodeTypesState = selectorFamily<
  CostConfiguration[],
  { nodeId: string | undefined }
>({
  key: "libraryFinancialConfigOnNodeTypesState",
  get:
    ({ nodeId }) =>
    async ({ get }) => {
      get(libraryFinancialConfigRefreshAtom);
      if (!nodeId) return [];
      try {
        const allFinancialResourcesOnNode =
          await getFinancialResourcesOnNode(nodeId);
        const uniqueFinancialResourcesOnNode = dedup(
          allFinancialResourcesOnNode,
          (cr) => cr.config.id,
        );
        return uniqueFinancialResourcesOnNode.map((cr) => cr.config);
      } catch {
        return [];
      }
    },
});
