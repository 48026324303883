import React, {
  PropsWithChildren,
  ReactNode,
  Suspense,
  useEffect,
  useMemo,
} from "react";
import {
  useRecoilCallback,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import {
  FinanceId,
  FinanceOverrideInput,
  financeOverrideInputState,
} from "./state";

import {
  AnalysisOverrideInput,
  ProdId,
  analysisOverrideInputAtomFamily,
} from "components/ProductionV2/state";
import { CostConfiguration } from "services/costService";
import { useBathymetry } from "hooks/bathymetry";
import { WindSourceConfiguration } from "services/windSourceConfigurationService";
import { Configuration } from "services/configurationService";
import {
  branchIdSelector,
  parkIdSelector,
  projectIdSelector,
} from "state/pathParams";
import { EnrichedSelectedParkCompare } from "components/CompareParksModal/state";
import { loggedInUserIsCheckly } from "state/user";
import { windSourceConfigurationsAtomFamily } from "state/windSourceConfiguration";
import { costConfigurationsAtomFamily } from "state/costConfigurations";
import { libraryAndProjectConfigurationsSelectorFamily } from "state/configuration";
import { useDeep } from "hooks/useDeep";
import { objectEquals } from "utils/utils";

export const currentParkTriggerId: FinanceId & ProdId =
  "current-park-finance" as FinanceId & ProdId;

export const TriggerCurrentFinance = ({
  children,
  fallback = null,
}: PropsWithChildren<{ fallback: ReactNode | null }>) => {
  const setAnalysisArgs = useSetRecoilState(
    analysisOverrideInputAtomFamily(currentParkTriggerId),
  );

  const setFinanceArgs = useSetRecoilState(
    financeOverrideInputState(currentParkTriggerId),
  );
  const resetFinanceArgs = useResetRecoilState(
    financeOverrideInputState(currentParkTriggerId),
  );

  const projectId = useRecoilValue(projectIdSelector) ?? "";
  const branchId = useRecoilValue(branchIdSelector) ?? "";
  const parkId = useRecoilValue(parkIdSelector) ?? "";

  const bathymetry = useBathymetry({
    projectId: projectId,
    branchId: branchId,
    featureId: parkId,
  }).valueMaybe();

  useEffect(() => {
    if (!bathymetry) {
      // If we don't have bathymetry yet we cannot set the finance input.
      // However, former inputs might still be set, for instance if you switch
      // parks. By resetting the atom we avoid having other components showing
      // data for the old arguments.
      resetFinanceArgs();
      return;
    }

    // Ensure no zone is selected
    setAnalysisArgs({
      selectedSubAreas: undefined,
      projectId,
      branchId,
      parkId,
    });

    setFinanceArgs({ rasterId: bathymetry.id });
  }, [
    bathymetry,
    branchId,
    parkId,
    projectId,
    resetFinanceArgs,
    setAnalysisArgs,
    setFinanceArgs,
  ]);

  return <Suspense fallback={fallback}>{children}</Suspense>;
};

export const TriggerDashboardFinanceAndProduction = ({
  configuration,
  analysisConfiguration,
  windConfiguration,
  parkId,
  projectId,
  branchId,
}: {
  configuration: CostConfiguration;
  analysisConfiguration: Configuration;
  windConfiguration: WindSourceConfiguration;
  parkId: string;
  projectId: string;
  branchId: string;
}) => {
  const bathymetry = useBathymetry({
    projectId: projectId,
    branchId: branchId,
    featureId: parkId,
  }).valueMaybe();

  const setAnalysisArgs = useSetRecoilState(
    analysisOverrideInputAtomFamily(currentParkTriggerId),
  );
  const setInputArgs = useSetRecoilState(
    financeOverrideInputState(currentParkTriggerId),
  );
  const resetFinanceArgs = useResetRecoilState(
    financeOverrideInputState(currentParkTriggerId),
  );

  const analysisArgs = useDeep({
    parkId,
    branchId,
    configuration: analysisConfiguration,
    windConfiguration,
  });

  useEffect(() => {
    if (!bathymetry) {
      resetFinanceArgs(); // See comment in TriggerCurrentFinance
      return;
    }

    setAnalysisArgs(analysisArgs);

    setInputArgs({
      configuration,
      rasterId: bathymetry?.id,
    });
  }, [
    analysisArgs,
    bathymetry,
    configuration,
    resetFinanceArgs,
    setAnalysisArgs,
    setInputArgs,
  ]);

  return null;
};

export const TriggerCompareFinanceAndProduction = ({
  triggerId,
  selectedPark,
  projectId,
  children,
  fallback = null,
}: PropsWithChildren<{
  fallback: ReactNode | null;
  triggerId: ProdId & FinanceId;
  selectedPark: EnrichedSelectedParkCompare;
  projectId: string;
}>) => {
  const setAnalysisInput = useSetRecoilState(
    analysisOverrideInputAtomFamily(triggerId),
  );
  const setFinanceInput = useSetRecoilState(
    financeOverrideInputState(triggerId),
  );
  const resetFinanceArgs = useResetRecoilState(
    financeOverrideInputState(currentParkTriggerId),
  );

  const isCheckly = useRecoilValue(loggedInUserIsCheckly);
  const analysisConfigurations = useRecoilValue(
    libraryAndProjectConfigurationsSelectorFamily({ nodeId: projectId }),
  );
  const windConfigurations = useRecoilValue(
    windSourceConfigurationsAtomFamily({ projectId }),
  );
  const costConfigurations = useRecoilValue(
    costConfigurationsAtomFamily({ projectId }),
  );

  const productionInputs: AnalysisOverrideInput = useMemo(() => {
    return {
      branchId: selectedPark.branchId,
      parkId: selectedPark.parkId,
      configuration: analysisConfigurations.find(
        (c) => c.id === selectedPark.selectedAnalysisConfigurationId,
      ),
      windConfiguration: windConfigurations.find(
        (c) => c.id === selectedPark.selectedWindConfigurationId,
      ),
      hubHeightOverride: selectedPark.selectedHubHeight,
      turbineTypeOverride: selectedPark.selectedTurbineType,
      exportCableTypeOverrideId: selectedPark.selectedExportCableType?.id,
      restart: isCheckly,
    } satisfies AnalysisOverrideInput;
  }, [
    analysisConfigurations,
    selectedPark.branchId,
    selectedPark.parkId,
    selectedPark.selectedAnalysisConfigurationId,
    selectedPark.selectedHubHeight,
    selectedPark.selectedTurbineType,
    selectedPark.selectedExportCableType,
    selectedPark.selectedWindConfigurationId,
    windConfigurations,
    isCheckly,
  ]);

  const getCurrentAnalysisInput = useRecoilCallback(
    ({ snapshot }) =>
      (triggerId: ProdId) => {
        return snapshot
          .getLoadable(analysisOverrideInputAtomFamily(triggerId))
          .valueMaybe();
      },
    [],
  );

  useEffect(() => {
    const currentAnalysisInput = getCurrentAnalysisInput(triggerId);
    if (!objectEquals(currentAnalysisInput, productionInputs)) {
      setAnalysisInput(productionInputs);
    }
  }, [getCurrentAnalysisInput, productionInputs, setAnalysisInput, triggerId]);

  const bathymetry = useBathymetry({
    projectId: projectId,
    branchId: selectedPark.branchId,
    featureId: selectedPark.parkId,
  }).valueMaybe();

  const financeInputs: FinanceOverrideInput | undefined = useMemo(() => {
    if (!bathymetry) return;

    return {
      configuration: costConfigurations.find(
        (c) => c.id === selectedPark.selectedCostConfigurationId,
      ),
      rasterId: bathymetry.id,
    };
  }, [
    bathymetry,
    costConfigurations,
    selectedPark.selectedCostConfigurationId,
  ]);

  useEffect(() => {
    if (!financeInputs) {
      resetFinanceArgs();
      return;
    }
    setFinanceInput(financeInputs);
  }, [financeInputs, resetFinanceArgs, setFinanceInput]);

  return <Suspense fallback={fallback}>{children}</Suspense>;
};
