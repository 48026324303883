/// <reference types="vite-plugin-svgr/client" />
import styled from "styled-components";
import { TurbineType } from "../../../types/turbines";
import { Input, InputDimensioned, TextArea } from "../../General/Input";
import { adminAccessProjectSelector } from "../../../state/user";
import { Detail } from "../../../styles/typography";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";

import { Column, Row } from "../../General/Layout";
import { Label as _Label } from "../../General/Form";

import { formatCurveToText } from "../../SettingsV2/FeatureSettings/Data/utils";
import {
  ErrorBoundaryWarningTriangle,
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "../../ErrorBoundaries/ErrorBoundaryLocal";
import {
  ContentWrapper,
  SettingsFooter,
  SettingsHeaderStandard,
} from "../../SettingsV2/Shared/styles";
import { TurbineTypeUsageNode } from "../SettingsUsage/TurbineTypeUsage";
import Button from "../../General/Button";
import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { spaceLarge } from "../../../styles/space";
import Checkbox from "../../General/Checkbox";
import { IAVoltageType } from "../../../services/cableTypeService";
import { projectIdSelector } from "state/pathParams";

// Lazy load to prevent plotly being in main.js
const TurbineGraph = React.lazy(() => import("../TurbineGraph"));

export const ColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  height: 100%;
  width: 100%;
`;

const Label = styled(_Label)`
  flex: 1;
`;

export const DefaultTurbineSettingsView = ErrorBoundaryWrapper(
  ({
    turbine,
    duplicate,
  }: {
    turbine: TurbineType;
    duplicate?: (turbineId: string) => void;
  }) => {
    const adminAccessProject = useRecoilValue(adminAccessProjectSelector);
    const projectId = useRecoilValue(projectIdSelector);

    const [values, setValues] = useState<string>("");

    useEffect(() => {
      if (turbine) {
        setValues(
          formatCurveToText(
            turbine.windSpeed,
            turbine.power,
            turbine.thrustCoefficient,
          ),
        );
      }
    }, [setValues, turbine]);

    if (!projectId) return null;
    return (
      <>
        <ContentWrapper
          style={{
            maxHeight: "calc(100% - 7.3rem)",
            overflowY: "auto",
            boxSizing: "border-box",
          }}
        >
          <ColumnContent>
            <SettingsHeaderStandard>
              <h4 style={{ margin: 0 }}>Turbine settings</h4>
              {adminAccessProject && duplicate && (
                <Row style={{ marginLeft: "auto" }}>
                  <Button
                    text="Duplicate to edit"
                    icon={<DuplicateIcon />}
                    onClick={() => duplicate(turbine.id)}
                  />
                </Row>
              )}
            </SettingsHeaderStandard>
            <Column>
              <Row>
                <Label>
                  <p>Name</p>
                  <Input disabled={true} value={`${turbine.name} (standard)`} />
                </Label>
                <Label>
                  <p>Cost</p>
                  <InputDimensioned
                    disabled={true}
                    value={turbine.cost}
                    unit={turbine.costUnit}
                  />
                </Label>
              </Row>
              <Row>
                <Label>
                  <p>Diameter</p>
                  <InputDimensioned
                    type="number"
                    disabled={true}
                    value={turbine.diameter}
                    unit={"m"}
                  />
                </Label>

                <Label>
                  <p>Hub height</p>
                  <InputDimensioned
                    type="number"
                    disabled={true}
                    value={turbine?.hubHeight}
                    unit={"m"}
                  />
                </Label>

                <Label>
                  <p>RNA Mass</p>
                  <InputDimensioned
                    type="number"
                    disabled={true}
                    value={Math.round(turbine.rnaMass / 1000)}
                    unit={"t"}
                  />
                </Label>

                <Label>
                  <p>Reference air density</p>
                  <InputDimensioned
                    type="number"
                    disabled={true}
                    value={turbine.referenceAirDensity}
                    unit={"kg/m3"}
                    step={0.001}
                  />
                </Label>
                <Label>
                  <p>Voltage</p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: spaceLarge,
                    }}
                  >
                    <Checkbox
                      value={IAVoltageType.kV66}
                      label={String(IAVoltageType.kV66).concat("kV")}
                      labelPlacement="after"
                      checked={turbine.voltage.includes(IAVoltageType.kV66)}
                      disabled={true}
                    />
                    <Checkbox
                      value={IAVoltageType.kV132}
                      label={String(IAVoltageType.kV132).concat("kV")}
                      labelPlacement="after"
                      checked={turbine.voltage.includes(IAVoltageType.kV132)}
                      disabled={true}
                    />
                  </div>
                </Label>
              </Row>
              <Column>
                <p>Turbine-specific losses</p>
                <Row>
                  <Input
                    disabled={true}
                    type="text"
                    value={"Unnamed loss"}
                    onChange={() => {}}
                    style={{ width: "16rem" }}
                  />
                  <InputDimensioned
                    disabled={true}
                    value={0}
                    unit="%"
                    type="number"
                    onChange={() => {}}
                    style={{ width: "7rem" }}
                  />
                </Row>
              </Column>
              <>
                <Label>
                  <p>Curves</p>
                  <Row style={{ gap: "1.8rem", paddingRight: "3rem" }}>
                    <Column>
                      <Row
                        style={{
                          flex: 0,
                          justifyContent: "space-between",
                          marginRight: "1rem",
                        }}
                      >
                        <Detail>Speed [m/s]</Detail>
                        <Detail>Power [kW]</Detail>
                        <Detail>Thrust [-]</Detail>
                      </Row>
                      <TextArea
                        cols={25}
                        rows={15}
                        style={{ resize: "vertical" }}
                        disabled={true}
                        placeholder={"Speed Power Thrust\n[m/s] [kW] [-]"}
                        value={values}
                      />
                    </Column>
                    {turbine && (
                      <React.Suspense fallback={null}>
                        <TurbineGraph turbine={turbine} />
                      </React.Suspense>
                    )}
                  </Row>
                </Label>
                {turbine.note && (
                  <Row>
                    <Label>
                      <p>Description</p>
                      <TextArea
                        rows={5}
                        style={{ resize: "vertical" }}
                        disabled={true}
                        value={turbine.note}
                      />
                    </Label>
                  </Row>
                )}
              </>
            </Column>
          </ColumnContent>
        </ContentWrapper>
        <SettingsFooter>
          <TurbineTypeUsageNode turbineTypeId={turbine.id} nodeId={projectId} />
        </SettingsFooter>
      </>
    );
  },
  () => {
    return (
      <>
        <FatalErrorBoundaryWrapper />
        <ErrorBoundaryWarningTriangle />
      </>
    );
  },
  ScreamOnError,
);

export default DefaultTurbineSettingsView;
