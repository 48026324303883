import { z } from "zod";
import { isNotGeometryCollection } from "../predicates";
import { NestedArray, fastMax, fastMin, partition } from "../utils";
import { SentryExtras, sendInfo } from "../sentry";
import { ProjectFeature } from "../../types/feature";
import { FeatureCollection, Position } from "geojson";

export class NotFeatureCollectionError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "NotFeatureCollectionError";
  }
}

export class FeatureNotArrayError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "FeatureNotArrayError";
  }
}

export class NotWGS84 extends Error {
  constructor(message: string) {
    super(message);
    this.name = "NotWGS84";
  }
}

export class UnknownCoordinateSystemError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "UnknownCoordinateSystem";
  }
}

const isLevel0 = (coords: NestedArray<number>): coords is number[] =>
  Array.isArray(coords) && typeof coords[0] === "number";

const coordsAreLatLong = (
  coords: number[] | number[][] | number[][][] | number[][][][],
): boolean => {
  if (isLevel0(coords))
    return (
      coords[0] >= -180 &&
      coords[0] <= 180 &&
      coords[1] >= -90 &&
      coords[1] <= 90
    );
  return coords
    .map(coordsAreLatLong)
    .flat()
    .every((c) => c === true);
};

export const getFeatureCollectionEPSG = (
  featureCollection: FeatureCollection,
) => {
  const isLatLng = featureCollection.features
    .filter((f) => f.geometry)
    .every(
      (f) =>
        isNotGeometryCollection(f) && coordsAreLatLong(f.geometry.coordinates),
    );

  if (isLatLng) return 4326;

  const geojsonCRS = (featureCollection as any)?.crs?.properties?.name;

  if (!geojsonCRS) {
    if (typeof window === "undefined" || !("prompt" in window)) {
      throw new Error("No coordinate system provided in the file");
    }

    const retVal = window.prompt(
      "No coordinate system provided in the file, enter the EPSG coordinate system : ",
      "3857",
    );
    if (!retVal) throw new Error();
    const number = parseInt(retVal);
    if (isNaN(number)) throw new Error("Only numbers are allowed");
    return number;
  }

  const epsgString = geojsonCRS.replace(/^\D+/g, "");
  return parseInt(epsgString);
};

export const validateFeatureCollection = (
  maybeFeatureCollection: Record<string, unknown>,
) => {
  if (maybeFeatureCollection.type !== "FeatureCollection") {
    throw new NotFeatureCollectionError("Missing entry 'FeatureCollection'");
  }

  if (!Array.isArray(maybeFeatureCollection.features)) {
    throw new FeatureNotArrayError("No array for entry 'features'");
  }
};

/**
 * Get the "innermost" coordinate list of a GeoJSON geometry.
 * For a point, this is a list of one element, namely the point.
 * For a line string, this is the list of points
 * For a polygon, this is the list of points for the first ring in the list of rings.
 */
export const getCoordinates = (
  coordinates: number[] | number[][] | number[][][] | number[][][][],
): Position[] | null => {
  if (typeof coordinates[0] === "undefined") {
    return null;
  }
  if (typeof coordinates[0] === "number") {
    return [coordinates] as Position[];
  }
  if (typeof coordinates[0][0] === "undefined") return null;
  if (typeof coordinates[0][0] === "number") return coordinates as Position[];
  return getCoordinates(coordinates[0]);
};

/**
 * Order: `[lonMin, latMin, lonMax, latMax]`
 */
export type BBOX = [number, number, number, number];

export const getBBOXArrayFromFeatures = (
  features: Pick<ProjectFeature, "geometry">[],
): BBOX => {
  const featuresWithNoCoordinates = features.filter(
    (feature) =>
      typeof feature.geometry.coordinates?.[0] === "undefined" ||
      feature.geometry.coordinates?.length === 0,
  );
  if (featuresWithNoCoordinates.length > 0) {
    sendInfo("Some features has no coordinates", {
      featuresWithNoCoordinates,
    });
  }
  const coords = features.flatMap((f) =>
    getCoordinates(f.geometry.coordinates),
  );
  const xCoords = coords
    .filter((c): c is Position => c != null)
    .map((c) => c[0]);
  const yCoords = coords
    .filter((c): c is Position => c != null)
    .map((c) => c[1]);

  return [
    fastMin(xCoords),
    fastMin(yCoords),
    fastMax(xCoords),
    fastMax(yCoords),
  ];
};

/**
 * Check that all elements in the given array can be parsed by the given parser.
 * `scream`s if some elements don't parse.  Returns an array of all parsed
 * elements.
 */
export const checkAllDoParse = <T>(
  array: unknown[],
  parser: z.ZodType<T, z.ZodTypeDef, unknown>,
  extras?: SentryExtras,
): T[] => {
  const [ok, bad] = partition(
    array,
    (e): e is T => parser.safeParse(e).success,
  );

  if (bad.length) {
    sendInfo(`${bad.length} elements did not parse`, { bad, ...extras });
  }

  return ok;
};
