import { useEffect } from "react";

const useEnterToSave = (saveCallback: () => void, enabled: boolean) => {
  useEffect(() => {
    const handleEnter = (event: KeyboardEvent) => {
      if (
        enabled &&
        event.key === "Enter" &&
        document.activeElement instanceof HTMLInputElement
      ) {
        event.preventDefault();
        saveCallback();
      }
    };
    document.addEventListener("keypress", handleEnter);
    return () => {
      document.removeEventListener("keypress", handleEnter);
    };
  }, [enabled, saveCallback]);
};

export default useEnterToSave;
