import React, { useMemo } from "react";
import {
  ContentTableColumn,
  GaplessRow,
  GaplessRowPadding,
  TabDescription,
  Text,
  TableHeader,
} from "../../../style";
import { Column } from "components/General/Layout";
import { OrgResource, OrganisationResources } from "../../../types";
import { useRecoilValue } from "recoil";
import { useTypedPath } from "state/pathParams";
import {
  ErrorBoundaryPrintOnly,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";
import useGroupOrganisationResourcesCrud, {
  groupOrgResourceManagersAtomFamily,
} from "../../hooks/useGroupOrganisationResourcesCrud";
import { resourcesToNameMap } from "../../UserContent/tabs/OrganisationUserResourceTable";
import { organisationGroupsState } from "components/Organisation/Groups/state";
import { StyledLink } from "components/Organisation/Library/style";
import Toggle, { ToggleSize } from "components/General/Toggle";
import { libraryAllSelectorFamily } from "state/featureAccess";

function OrgResourceRow({
  groupId,
  resources,
  resourceName,
}: {
  groupId: string;
  resources: OrgResource[];
  resourceName: OrganisationResources;
}) {
  const { add, remove } = useGroupOrganisationResourcesCrud();
  const resourcesRaw = useMemo(
    () => resources.map((r) => r.resource_name),
    [resources],
  );

  const editSelected = useMemo(
    () => resourcesRaw.includes(resourceName),
    [resourcesRaw, resourceName],
  );
  return (
    <GaplessRowPadding>
      <Text>{resourcesToNameMap[resourceName]} access</Text>
      <Toggle
        checked={editSelected}
        onChange={() =>
          editSelected
            ? remove(groupId, resourceName)
            : add(groupId, resourceName)
        }
        size={ToggleSize.SMALL}
      />
    </GaplessRowPadding>
  );
}

export const OrganisationResourceGroupTable = ErrorBoundaryWrapper(
  ({ groupId }: { groupId: string }) => {
    const { organisationId } = useTypedPath("organisationId");
    const groups = useRecoilValue(organisationGroupsState({ organisationId }));
    const group = groups.find((g) => g.id === groupId);

    const resources = useRecoilValue(
      groupOrgResourceManagersAtomFamily({ organisationId, groupId }),
    );

    const allLibraryAccess = useRecoilValue(
      libraryAllSelectorFamily({ organisationId }),
    );

    const organisationalResources = allLibraryAccess
      ? ([
          "org_turbine_manage",
          "org_foundation_manage",
          "org_cable_manage",
          "org_analysis_manage",
          "org_financial_manage",
        ] as OrganisationResources[])
      : ([
          "org_turbine_manage",
          "org_foundation_manage",
        ] as OrganisationResources[]);

    return (
      <Column style={{ gap: "1.6rem" }}>
        <Column style={{ gap: "1.2rem" }}>
          <TabDescription>
            {`
            ${
              group?.name ?? "group"
            }’s access to the organisation Library. Access gives
            full manage and edit rights to those library resources.
            `}
            <StyledLink
              target="_blank"
              rel="noreferrer"
              href={"https://help.vindtech.com/en/articles/8842345-library"}
            >
              Read more.
            </StyledLink>
          </TabDescription>
        </Column>
        <GaplessRow>
          <TableHeader>Access</TableHeader>
          <div />
        </GaplessRow>
        <ContentTableColumn>
          {organisationalResources.map((resourceName) => {
            return (
              <OrgResourceRow
                key={resourceName}
                groupId={groupId}
                resourceName={resourceName}
                resources={resources}
              />
            );
          })}
        </ContentTableColumn>
      </Column>
    );
  },
  ErrorBoundaryPrintOnly,
  ScreamOnError,
);
