import { useEffect, useMemo } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { layerSettingSelectorFamily } from "../../components/LayerList/LayerSettings/state";
import LineString from "../../components/MapFeatures/LineString";
import { ProjectFeature } from "../../types/feature";
import {
  useDynamicStreamer,
  visibleDynamicLayersAtom,
} from "../../state/layer";
import { mapRefAtom } from "../../state/map";
import { addIdOnFeaturesIfNotUUID4 } from "../../utils/geojson/utils";
import useSelectionInMap from "../../hooks/useSelectionInMap";
import { useTypedPath } from "../../state/pathParams";
import { useLayoutFilter } from "../../hooks/map";
import {
  StrokeStyleToDashArray,
  filterFeatureCollectionAccordingToType,
  getLayerSymbols,
  layerToSourceId,
} from "./utils";
import { externalWFSAnchorId } from "../../components/Mapbox/utils";
import { Layer, LayerType } from "../../types/layers";
import { getVisibleLayers } from "../../components/LayerList/LayerSettings/utils";
import {
  ErrorBoundarySilent,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "../../components/ErrorBoundaries/ErrorBoundaryLocal";
import stc from "string-to-color";
import { LayerStrokeStyle } from "../../components/LayerList/LayerSettings/types";
import { sendInfo } from "../../utils/sentry";
import { isTileJSONLayer } from "../../state/tileJSON";
import { isCustomLayer } from "../../components/LayerList/utils";
import {
  arcgisLayerDrawingInfoSelector,
  DrawingInfoUniqueValue,
} from "../../state/arcgisRestAPI";
import { rgbToHex } from "../../utils/image";

const LINE_TYPES = ["MultiLineString", "LineString"];
export const LINE_LAYERS_SUFFIX = "-line";

const getLinePaint = (
  color: mapboxgl.LinePaint["line-color"],
  strokeStyle: LayerStrokeStyle,
): mapboxgl.LinePaint => ({
  "line-color": color,
  "line-width": 5,
  "line-dasharray": StrokeStyleToDashArray[strokeStyle],
});

const DynamicLineLayers = ErrorBoundaryWrapper(
  () => {
    const { projectId } = useTypedPath("projectId");
    const visibleLayers = useRecoilValue(getVisibleLayers({ projectId }));

    const selectedDynamicLineLayers = visibleLayers.filter(
      (layer) =>
        !isTileJSONLayer(layer) &&
        (layer.type === LayerType.Line ||
          isCustomLayer(layer) ||
          layer.type === LayerType.FeatureCollection),
    );

    return (
      <>
        {selectedDynamicLineLayers.map((layer) => (
          <DynamicLineLayer key={layer.id} layer={layer} />
        ))}
      </>
    );
  },
  ErrorBoundarySilent,
  ScreamOnError,
);

function generateUniqueValueExpression(
  classBreaks: DrawingInfoUniqueValue,
  defaultColor: string,
): mapboxgl.LinePaint["line-color"] {
  const expression: mapboxgl.LinePaint["line-color"] = [
    "match",
    ["get", classBreaks.field1],
  ];
  classBreaks.uniqueValueInfos.forEach((breakInfo) => {
    expression.push(breakInfo.value);
    const [r, g, b] = breakInfo.symbol.color;
    expression.push(rgbToHex(r, g, b));
  });
  expression.push(defaultColor); // Default color if no class matches
  return expression;
}

const DynamicLineLayer = ({ layer }: { layer: Layer }) => {
  const { projectId } = useTypedPath("projectId");
  const { onExternalLayerClick, getIdsOfSelectedExternalLayers } =
    useSelectionInMap();
  const setVisibleDynamicLayers = useSetRecoilState(visibleDynamicLayersAtom);
  const { dynamicVectorLayerFeatures } = useDynamicStreamer(layer);
  const filteredGeojson = useMemo(
    () =>
      isCustomLayer(layer)
        ? filterFeatureCollectionAccordingToType(
            dynamicVectorLayerFeatures,
            "LineString",
          )
        : dynamicVectorLayerFeatures,
    [layer, dynamicVectorLayerFeatures],
  );
  const drawingInfo = useRecoilValue(arcgisLayerDrawingInfoSelector({ layer }));
  const features = useMemo(() => {
    const featuresFiltered = filteredGeojson.features as any[];
    if (layer.type === "line")
      return addIdOnFeaturesIfNotUUID4(featuresFiltered);

    return addIdOnFeaturesIfNotUUID4(
      featuresFiltered.filter((f) => {
        if (f.geometry == null) {
          sendInfo("feature is missing geometry", { f, layer });
          return false;
        }
        return LINE_TYPES.includes(f.geometry.type);
      }),
    );
  }, [filteredGeojson, layer]) as ProjectFeature[];
  const map = useRecoilValue(mapRefAtom);

  const layerId = layer.id + LINE_LAYERS_SUFFIX;
  const sourceId = useMemo(
    () => layerToSourceId(layer, LINE_LAYERS_SUFFIX),
    [layer],
  );
  const selectedIds = useMemo(
    () => getIdsOfSelectedExternalLayers(features),
    [getIdsOfSelectedExternalLayers, features],
  );

  useEffect(() => {
    setVisibleDynamicLayers((vdl) => [...vdl, layerId]);
    return () =>
      setVisibleDynamicLayers((vdl) => vdl.filter((l) => l !== layerId));
  }, [setVisibleDynamicLayers, layerId]);

  const filter = useLayoutFilter(sourceId);

  const layerSettings = useRecoilValue(
    layerSettingSelectorFamily({
      projectId,
      layerId: layer.id,
    }),
  );

  const color = useMemo(() => {
    if (!layerSettings.overrideLayerStyle && drawingInfo) {
      if (drawingInfo.type === "simple" && drawingInfo.symbol.color) {
        const [r, g, b] = drawingInfo.symbol.color;
        return rgbToHex(r, g, b);
      } else if (drawingInfo.type === "uniqueValue") {
        return generateUniqueValueExpression(drawingInfo, stc(layer.id));
      }
    }
    if (layerSettings.layerStyle?.color) {
      return layerSettings.layerStyle.color;
    }
    return stc(layer.id);
  }, [layer, layerSettings, drawingInfo]);

  const opacity = useMemo(() => {
    if (
      !layerSettings.overrideLayerStyle &&
      drawingInfo?.type === "simple" &&
      drawingInfo.symbol.color
    ) {
      return (
        (1 / 255) *
        drawingInfo.symbol.color[drawingInfo.symbol.color.length - 1]
      );
    }
    return layerSettings.layerStyle?.opacity ?? 0.6;
  }, [layerSettings, drawingInfo]);

  const pinnedProperties = useMemo(() => {
    return layerSettings.layerStyle?.pinnedProperties;
  }, [layerSettings.layerStyle]);

  const pinnedSymbols = useMemo(
    () => getLayerSymbols(pinnedProperties),
    [pinnedProperties],
  );

  const strokeStyle = useMemo(() => {
    return layerSettings.layerStyle?.strokeStyle ?? LayerStrokeStyle.Solid;
  }, [layerSettings.layerStyle]);

  const paint = useMemo(() => {
    return getLinePaint(color, strokeStyle);
  }, [color, strokeStyle]);

  if (!map) return null;

  return (
    <LineString
      paint={paint}
      symbols={(layer as any)["symbols"] ?? pinnedSymbols}
      features={features}
      map={map}
      sourceId={sourceId}
      layerId={layerId}
      onClickCallback={onExternalLayerClick}
      filter={filter}
      opacity={opacity}
      selectedIds={selectedIds}
      beforeLayer={externalWFSAnchorId}
      zoomLevels={layerSettings.layerStyle?.zoomLevels}
    />
  );
};

export default DynamicLineLayers;
