import { Geometry, LineString, MultiPolygon, Polygon } from "geojson";
import { z, ZodObject } from "zod";
import {
  CABLE_CHAIN_POLYGON_PROPERTY_TYPE,
  CABLE_CORRIDOR_PROPERTY_TYPE,
  CABLE_PARTITION_POLYGON_PROPERTY_TYPE,
  CABLE_PROPERTY_TYPE,
  EXPORT_CABLE_PROPERTY_TYPE,
  SUBSTATION_PROPERTY_TYPE,
} from "../constants/cabling";
import {
  displayLabelPropertyName,
  lockedPropertyName,
  opacityPropertyName,
  zoomPropertyName,
} from "../constants/canvas";
import {
  DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
  SUB_AREA_PROPERTY_TYPE,
} from "../constants/division";
import { PARK_PROPERTY_TYPE } from "../constants/park";
import {
  ANCHOR_PROPERTY_TYPE,
  EXISTING_TURBINE_PROPERTY_TYPE,
  GRID_CONNECTION_POINT_TYPE,
  MOORING_LINE_PROPERTY_TYPE,
  PORT_POINT_TYPE,
  SLACK_REGION_PROPERTY_TYPE,
  TOUCHDOWN_PROPERTY_TYPE,
  TURBINE_PROPERTY_TYPE,
} from "../constants/projectMapView";
import { VIEW_POINT_TYPE } from "../constants/viewPoint";
import {
  BathymetryUserUploadedType,
  GeoTiffUserUploadedImageType,
} from "../services/types";
import {
  _Bbox,
  _Feature,
  _FeatureCollection,
  _GeometryNoCollection,
  _LineString,
  _Point,
  _Polygon,
  _PolygonOrMultiPolygon,
  _Position,
  GeometryNoCollection,
} from "../utils/geojson/geojson";
import { sendInfo } from "../utils/sentry";
import { _TurbineNoiseSettings } from "./turbines";
import { pointsAreEqual } from "../utils/geometry";
import { omitFields } from "utils/utils";
import { _CableFreeSectors } from "components/CableFreeSector/types";

/**
 * Properties that are common to ALL features. Try to avoid adding new features
 * here, since it will add complexity to all feature types.
 */
const _CommonProps = z.object({
  /**
   * Unique id for the feature. Is typically a UUIDv4
   */
  id: z.string(),
  /**
   * List of ids for the parent features. Is typically the park id in which the
   * feature resides.
   */
  parentIds: z.string().array().optional(),
  /**
   * Generic name of the feature
   */
  name: z.string().optional(),
  /**
   * Whether the feature is locked. This is used to prevent the user from
   * editing the feature.
   */
  [lockedPropertyName]: z.boolean().optional(),
  [opacityPropertyName]: z.number().optional(),
  [zoomPropertyName]: z.boolean().optional(),
  [displayLabelPropertyName]: z.boolean().optional(),
});

export type CommonProps = z.infer<typeof _CommonProps>;

/**
 * Create a parser for a `Feature` object given the `Geometry` and `Properties` type.
 * The `Properties` should already contain `CommonProps`.
 */
const mkFeature = <G extends z.ZodTypeAny, P extends z.ZodObject<any>>(
  geometry: G,
  properties: P,
) => {
  return z.object({
    geometry,
    properties: _CommonProps.merge(properties).passthrough(),
    type: z.literal("Feature"),
    id: z.string(),
    bbox: _Bbox.optional(),
  });
};

// ----------------------------------------------------------------------------

const _ParkProps = z.object({
  type: z.literal(PARK_PROPERTY_TYPE),
  noiseSettings: _TurbineNoiseSettings.optional().catch(() => undefined),
});
export const _ParkFeature = mkFeature(_Polygon, _ParkProps);
export type ParkFeature = z.infer<typeof _ParkFeature>;

const _ExistingTurbineProps = z.object({
  type: z.literal(EXISTING_TURBINE_PROPERTY_TYPE),
  power: z.number().nullish().optional(),
  name: z.string().optional(),
});

const _TurbineProps = z.object({
  type: z.literal(TURBINE_PROPERTY_TYPE),
  turbineTypeId: z.string().default("iea_15MW"),
  foundationId: z.string().optional(),
  cableFreeSectors: _CableFreeSectors.optional(),
});

export const _TurbineFeature = mkFeature(_Point, _TurbineProps);
export const _ExistingTurbineFeature = mkFeature(_Point, _ExistingTurbineProps);
export type TurbineFeature = z.infer<typeof _TurbineFeature>;
export type ExistingTurbineFeature = z.infer<typeof _ExistingTurbineFeature>;

const _CableProps = z.object({
  type: z.literal(CABLE_PROPERTY_TYPE),
  fromId: z.string(),
  toId: z.string(),
  cableTypeId: z.string().optional(),
  /** The partition the cable is in from the sweeping cable algorithm. */
  partition: z.number().optional(),
  powerLoad: z.number().optional(),
  /** If the cable is a redundancy cable.  These cables are not included in
   * electrical computations, but **are** included in length computations. */
  redundancy: z.boolean().optional(),
});

export const _CableFeature = mkFeature(_LineString, _CableProps);
export type CableFeature = z.infer<typeof _CableFeature>;

const _SubstationProps = z.object({
  type: z.literal(SUBSTATION_PROPERTY_TYPE),
  color: z.string(),
  substationTypeId: z.string().optional(),
  cableFreeSectors: _CableFreeSectors.optional(),
});

export const _SubstationFeature = mkFeature(_Point, _SubstationProps);
export type SubstationFeature = z.infer<typeof _SubstationFeature>;

const _CableCorridorProps = z.object({
  type: z.literal(CABLE_CORRIDOR_PROPERTY_TYPE),
});

export const _CableCorridorFeature = mkFeature(_Polygon, _CableCorridorProps);
export type CableCorridorFeature = z.infer<typeof _CableCorridorFeature>;

const _ExportCableProps = z.object({
  type: z.literal(EXPORT_CABLE_PROPERTY_TYPE),
  powerLoad: z.number().optional(),
  fromSubstationId: z.string().optional(),
  toSubstationId: z.string().optional(),
  cableTypeId: z.string().optional(),
  onshoreCableTypeId: z.string().optional(),
});

export const _ExportCableFeature = mkFeature(_LineString, _ExportCableProps);
export type ExportCableFeature = z.infer<typeof _ExportCableFeature>;

const _AnchorProps = z.object({
  type: z.literal(ANCHOR_PROPERTY_TYPE),
});

export const _AnchorFeature = mkFeature(_Point, _AnchorProps);
export type AnchorFeature = z.infer<typeof _AnchorFeature>;

const _CommonMooringLineProps = z.object({
  type: z.literal(MOORING_LINE_PROPERTY_TYPE),
  anchor: z.string(),
  target: z.string(),
  slack: z.number(),
});

const _MooringLineSingleProps = _CommonMooringLineProps.merge(
  z.object({
    lineType: z.string().optional(),
    lineLength: z.number().optional(),
  }),
);

const _MooringLineMultipleProps = _CommonMooringLineProps.merge(
  z.object({
    lineTypes: z.string().array(),
    lineLengths: z.number().array(),
    attachments: z.number().array(),
  }),
);

export const _MooringLineSingleFeature = mkFeature(
  _LineString,
  _MooringLineSingleProps,
);
export type MooringLineSingleFeature = z.infer<
  typeof _MooringLineSingleFeature
>;
export const _MooringLineMultipleFeature = mkFeature(
  _LineString,
  _MooringLineMultipleProps,
);
export type MooringLineMultipleFeature = z.infer<
  typeof _MooringLineMultipleFeature
>;
export const _MooringLineFeature = _MooringLineSingleFeature.or(
  _MooringLineMultipleFeature,
);
export type MooringLineFeature = z.infer<typeof _MooringLineFeature>;

/**
 * This is the old format. We should remove this once we are sure that all
 * projects have been migrated to the new format (if ever?)
 */
const _ExclusionDomain_v1 = z
  .union([z.literal("turbines"), z.literal("everything")])
  .transform((val) =>
    val === "turbines"
      ? exclusionDomainPack({
          turbine: true,
          cable: false,
          substation: false,
          anchor: true,
        })
      : exclusionDomainPack({
          turbine: true,
          cable: true,
          substation: true,
          anchor: true,
        }),
  );

/**
 * Bit field for exclusion domains. This is used to determine which features
 * Order is
 *  0. turbine
 *  1. cable
 *  2. substation
 *  3. anchors
 *
 * This is pretty awkward, but mapbox doesn't support nested objects used for rendering.
 */
const _ExclusionDomain_v3 = z.number().min(0).max(15);
export const ExclusionDomainDefault = 15;

const _ExclusionDomain = z.union([_ExclusionDomain_v3, _ExclusionDomain_v1]);
type ExclusionDomain = z.infer<typeof _ExclusionDomain>;

export type ExclusionDomainUnpacked = {
  turbine: boolean;
  cable: boolean;
  substation: boolean;
  anchor: boolean;
};

/** Bit-pack the domain. */
export const exclusionDomainPack = ({
  turbine,
  cable,
  substation,
  anchor: anchors,
}: ExclusionDomainUnpacked): number => {
  return (
    Number(turbine) |
    (Number(cable) << 1) |
    (Number(substation) << 2) |
    (Number(anchors) << 3)
  );
};

export const exclusionDomainUnpack = (
  ex: ExclusionDomain,
): ExclusionDomainUnpacked => {
  return {
    turbine: (ex & 1) !== 0,
    cable: (ex & 2) !== 0,
    substation: (ex & 4) !== 0,
    anchor: (ex & 8) !== 0,
  };
};

const _ExclusionZoneProps = z.object({
  type: z.literal(DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE),
  color: z.string(),
  domain: _ExclusionDomain.default(
    exclusionDomainPack({
      turbine: true,
      cable: true,
      substation: true,
      anchor: true,
    }),
  ),
});

export const _ExclusionZoneFeature = mkFeature(
  _PolygonOrMultiPolygon,
  _ExclusionZoneProps,
);
export type ExclusionZoneFeature = z.infer<typeof _ExclusionZoneFeature>;

const _SubAreaProps = z.object({
  type: z.literal(SUB_AREA_PROPERTY_TYPE),
  color: z.string(),
});

export const _SubAreaFeature = mkFeature(_Polygon, _SubAreaProps);
export type SubAreaFeature = z.infer<typeof _SubAreaFeature>;

export const _DivisionFeature = z.union([
  _ExclusionZoneFeature,
  _SubAreaFeature,
]);
export type DivisionFeature = z.infer<typeof _DivisionFeature>;

const _SlackRegionProps = z.object({
  type: z.literal(SLACK_REGION_PROPERTY_TYPE),
  turbine: z.string(),
});

export const _SlackRegionFeature = mkFeature(_Polygon, _SlackRegionProps);
export type SlackRegionFeature = z.infer<typeof _SlackRegionFeature>;

const _TouchdownPointProps = z.object({
  type: z.literal(TOUCHDOWN_PROPERTY_TYPE),
  line: z.string(),
});

export const _TouchdownPointFeature = mkFeature(_Point, _TouchdownPointProps);
export type TouchdownPointFeature = z.infer<typeof _TouchdownPointFeature>;

const _ViewpointProps = z.object({
  type: z.literal(VIEW_POINT_TYPE),
});

export const _ViewpointFeature = mkFeature(_Point, _ViewpointProps);
export type ViewpointFeature = z.infer<typeof _ViewpointFeature>;

const _PortPointProps = z.object({
  type: z.literal(PORT_POINT_TYPE),
});

export const _PortPointFeature = mkFeature(_Point, _PortPointProps);
export type PortPointFeature = z.infer<typeof _PortPointFeature>;

const _GridConnectionPointProps = z.object({
  type: z.literal(GRID_CONNECTION_POINT_TYPE),
});

export const _GridConnectionPointFeature = mkFeature(
  _Point,
  _GridConnectionPointProps,
);
export type GridConnectionPointFeature = z.infer<
  typeof _GridConnectionPointFeature
>;

const _CablePartitionProps = z.object({
  type: z.literal(CABLE_PARTITION_POLYGON_PROPERTY_TYPE),
  substation: z.string(),
  turbines: z.string().array(),
  color: z.string(),
  parentIds: z.string().array(),
});

export const _CablePartitionFeature = mkFeature(_Polygon, _CablePartitionProps);
export type CablePartitionFeature = z.infer<typeof _CablePartitionFeature>;

const _CableChainProps = z.object({
  type: z.literal(CABLE_CHAIN_POLYGON_PROPERTY_TYPE),
  substation: z.string(),
  turbines: z.string().array(),
  color: z.string(),
  label: z.string().optional(),
  parentIds: z.string().array(),
});

export const _CableChainFeature = mkFeature(_Polygon, _CableChainProps);
export type CableChainFeature = z.infer<typeof _CableChainFeature>;

export const _GeotiffTypes = z.union([
  z.literal(BathymetryUserUploadedType),
  z.literal(GeoTiffUserUploadedImageType),
]);
export type GeotiffTypes = z.infer<typeof _GeotiffTypes>;

const _GeotiffProps = z.object({
  type: _GeotiffTypes,
  filename: z.string(),
});

export const _GeotiffFeature = mkFeature(_Polygon, _GeotiffProps);
export type GeotiffFeature = z.infer<typeof _GeotiffFeature>;

const _BathymetryProps = z.object({
  filename: z.string(),
  type: z.literal(BathymetryUserUploadedType),
});

export const _BathymetryFeature = mkFeature(_Polygon, _BathymetryProps);
export type BathymetryFeature = z.infer<typeof _BathymetryFeature>;

export const _OtherFeature = z.object({
  geometry: _GeometryNoCollection,
  // Add in CommonProps here to try to get the typing right for those fields.
  properties: z
    .object({ type: z.string().optional() })
    .merge(_CommonProps.partial())
    .passthrough(),
  type: z.literal("Feature"),
  id: z.string(),
  bbox: _Bbox.optional(),
});

export type OtherFeature<
  G extends GeometryNoCollection = GeometryNoCollection,
> = z.infer<typeof _OtherFeature> & { geometry: G };

export const _GeoJSONFeatureToOtherFeature: z.ZodType<
  OtherFeature,
  z.ZodTypeDef,
  unknown
> = _Feature
  .refine((f) => f.geometry.type !== "GeometryCollection", {
    message: "GeometryCollection features are not supported",
  })
  .transform((f) => {
    const { geometry, properties, type, id, bbox } = f;
    if (geometry.type === "GeometryCollection") throw new Error("unreachable");
    const newId = typeof id === "string" ? id : String(id);
    const other: OtherFeature = {
      geometry,
      properties: {
        ...properties,
        id: newId,
      },
      type,
      id: newId,
      bbox,
    };
    return other;
  });

export const _GeoJSONFeatureCollectionToOtherFeatureCollection: z.ZodType<
  OtherFeatureCollection,
  z.ZodTypeDef,
  unknown
> = _FeatureCollection.transform((f) => {
  const features: OtherFeature[] = f.features.map((f) =>
    _GeoJSONFeatureToOtherFeature.parse(f),
  );
  const ofc: OtherFeatureCollection = {
    ...f,
    features,
  };
  return ofc;
});

export const _GeoJSONToOtherFeature = _GeoJSONFeatureToOtherFeature.or(
  _GeoJSONFeatureCollectionToOtherFeatureCollection,
);

const _SemanticFeature = z.union([
  _ParkFeature,
  _TurbineFeature,
  _ExistingTurbineFeature,
  _CableFeature,
  _SubstationFeature,
  _CableCorridorFeature,
  _ExportCableFeature,
  _AnchorFeature,
  _MooringLineFeature,
  _ExclusionZoneFeature,
  _SubAreaFeature,
  _SlackRegionFeature,
  _TouchdownPointFeature,
  _ViewpointFeature,
  _PortPointFeature,
  _GridConnectionPointFeature,
  _CablePartitionFeature,
  _CableChainFeature,
  _GeotiffFeature,
  _BathymetryFeature,
]);
type SemanticFeature = z.infer<typeof _SemanticFeature>;

export type ProjectFeature<G extends Geometry = GeometryNoCollection> = (
  | SemanticFeature
  | OtherFeature
) & {
  geometry: G;
};
export const _ProjectFeature = _SemanticFeature.or(_OtherFeature);

export type FeatureType = SemanticFeature["properties"]["type"];
export const _FeatureType: z.ZodType<FeatureType, z.ZodTypeDef, string> = z
  .string()
  .refine((s): s is FeatureType => s in FeatureTypeToParser);

export const _OtherFeatureCollection = z.object({
  type: z.literal("FeatureCollection"),
  features: _ProjectFeature.array(),
  bbox: _Bbox.optional(),
});
export type OtherFeatureCollection = z.infer<typeof _OtherFeatureCollection>;

export type AnyLineStringFeature = ProjectFeature<LineString>;

/**
 * Map from `properties.type` to the parser for that feature type.
 */
const FeatureTypeToParser: Record<FeatureType, z.ZodTypeAny> = {
  [PARK_PROPERTY_TYPE]: _ParkFeature,
  [EXISTING_TURBINE_PROPERTY_TYPE]: _ExistingTurbineFeature,
  [TURBINE_PROPERTY_TYPE]: _TurbineFeature,
  [CABLE_PROPERTY_TYPE]: _CableFeature,
  [SUBSTATION_PROPERTY_TYPE]: _SubstationFeature,
  [CABLE_CORRIDOR_PROPERTY_TYPE]: _CableCorridorFeature,
  [EXPORT_CABLE_PROPERTY_TYPE]: _ExportCableFeature,
  [ANCHOR_PROPERTY_TYPE]: _AnchorFeature,
  [MOORING_LINE_PROPERTY_TYPE]: _MooringLineFeature,
  [DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE]: _ExclusionZoneFeature,
  [SUB_AREA_PROPERTY_TYPE]: _SubAreaFeature,
  [SLACK_REGION_PROPERTY_TYPE]: _SlackRegionFeature,
  [TOUCHDOWN_PROPERTY_TYPE]: _TouchdownPointFeature,
  [VIEW_POINT_TYPE]: _ViewpointFeature,
  [PORT_POINT_TYPE]: _PortPointFeature,
  [GRID_CONNECTION_POINT_TYPE]: _GridConnectionPointFeature,
  [CABLE_PARTITION_POLYGON_PROPERTY_TYPE]: _CablePartitionFeature,
  [CABLE_CHAIN_POLYGON_PROPERTY_TYPE]: _CableChainFeature,
  [BathymetryUserUploadedType]: _BathymetryFeature,
  [GeoTiffUserUploadedImageType]: _GeotiffFeature,
};

export const _PointFeatureType = z.enum([
  TURBINE_PROPERTY_TYPE,
  SUBSTATION_PROPERTY_TYPE,
  ANCHOR_PROPERTY_TYPE,
  TOUCHDOWN_PROPERTY_TYPE,
  VIEW_POINT_TYPE,
  EXISTING_TURBINE_PROPERTY_TYPE,
  PORT_POINT_TYPE,
  GRID_CONNECTION_POINT_TYPE,
]);
export type PointFeatureType = z.infer<typeof _PointFeatureType>;

export const _LineStringFeatureType = z.enum([
  CABLE_PROPERTY_TYPE,
  EXPORT_CABLE_PROPERTY_TYPE,
  MOORING_LINE_PROPERTY_TYPE,
]);
export type LineStringFeatureType = z.infer<typeof _LineStringFeatureType>;

export const _PolygonFeatureType = z.enum([
  PARK_PROPERTY_TYPE,
  CABLE_CORRIDOR_PROPERTY_TYPE,
  DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
  SUB_AREA_PROPERTY_TYPE,
  SLACK_REGION_PROPERTY_TYPE,
  CABLE_PARTITION_POLYGON_PROPERTY_TYPE,
  CABLE_CHAIN_POLYGON_PROPERTY_TYPE,
  BathymetryUserUploadedType,
  GeoTiffUserUploadedImageType,
]);
export type PolygonFeatureType = z.infer<typeof _PolygonFeatureType>;

/**
 * Some polygons have messed up geometry, where they have 3 coordinates in the
 * list, but the two endpoints are the same, i.e. they are a line. This is
 * probably from a bad mapboxgl-draw thing, where we accidentally would make a
 * polygon with illegal geometry. These polygons are never shown, so it's safe
 * to ignore them alltogether.
 */
const commonError_PolygonWithTwoUniquePoints = (f: unknown): boolean => {
  const parser = z.object({
    geometry: z.object({
      type: z.literal("Polygon"),
      coordinates: _Position.array().array(),
    }),
  });
  const res = parser.safeParse(f);
  if (!res.success) return false;
  const coords = res.data.geometry.coordinates[0];
  if (coords.length === 3 && pointsAreEqual(coords[0], coords[2])) return true;
  return false;
};

/**
 * We've forgotten to check that `turf.buffer` doesn't return `null` on a buffer
 * operation. This would happen for instance if the buffer radius is larger than
 * the polygon. The result is that we have `Polygon`s floating around with
 * `property.name === 'Buffer result'`, but without a `geometry` field.
 */
const commonError_BufferResultWithoutGeometry = (f: unknown): boolean => {
  const parser = z.object({
    properties: z.object({
      name: z.literal("Buffer result"),
    }),
    geometry: z.undefined(),
  });
  const res = parser.safeParse(f);
  if (res.success) return true;
  return false;
};

const isNotACommonError = (f: unknown): boolean => {
  return ![
    commonError_PolygonWithTwoUniquePoints,
    commonError_BufferResultWithoutGeometry,
  ].some((fn) => fn(f));
};

export const stripFeatureTypeSpecificFields = <T extends ProjectFeature>(
  feature: T,
): T["properties"] => {
  const propsToRemove = [
    ...new Set(_TypeSpecificProps.flatMap((f) => Object.keys(f.shape))),
  ];
  return omitFields(feature.properties, ...propsToRemove, "parentIds");
};

export const stripFeatureTypeSpecificFieldsAndConvertToOther = (
  feature: ProjectFeature,
): ProjectFeature => {
  const newProps = stripFeatureTypeSpecificFields(feature);
  return _ProjectFeature.parse({
    ...feature,
    properties: newProps,
  });
};

/**
 * Try to parse a feature to anythink we know of. Dispatch on `properties.type`
 * to get an error message that's not completely bogus.
 *
 * Will also accept `OtherFeature` and `OtherFeatureCollection`.
 */
export const _FeatureParser: z.ZodType<ProjectFeature, z.ZodTypeDef, unknown> =
  z
    .object({
      properties: z
        .object({
          type: z.string().optional(),
        })
        .passthrough()
        .optional(),
    })
    .passthrough()
    .transform((val, ctx) => {
      const maybeType = val.properties?.type;
      const typeOrEmpty = maybeType ?? "";
      const parser: undefined | z.ZodTypeAny =
        typeOrEmpty in FeatureTypeToParser
          ? FeatureTypeToParser[typeOrEmpty as keyof typeof FeatureTypeToParser] // safety: typeOrEmpty is in FeatureTypeToParser
          : undefined;

      if (parser) {
        const res = parser.safeParse(val);
        if (res.success) {
          return res.data;
        } else {
          if (isNotACommonError(val)) {
            sendInfo("FeatureParser failed to parse", {
              val,
              valStr: JSON.stringify(val),
              res,
            });
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: `FeatureParser failed to parse ${maybeType}`,
            });
            return z.NEVER;
          }
        }
      } else if (maybeType !== undefined) {
        sendInfo("FeatureParser doesn't know about this type", {
          type: maybeType,
          feature: val,
        });
      }

      const p = _OtherFeature;
      const res = p.safeParse(val);
      if (!res.success) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `OtherFeature failed to parse`,
        });
        if (isNotACommonError(val))
          sendInfo("OtherFeature failed to parse", {
            feature: val,
            error: res.error,
          });
        return z.NEVER;
      }

      return res.data;
    });

export type MultiPolygonFeature = ProjectFeature<MultiPolygon>;
export type PolygonFeature = ProjectFeature<Polygon>;
export type LineStringFeature = ProjectFeature<LineString>;
export type FeatureWithAnyProperties = OtherFeature;
export const _FeatureWithAnyProperties = _OtherFeature;

const _TypeSpecificProps: ZodObject<any>[] = [
  _ParkProps,
  _TurbineProps,
  _CableProps,
  _SubstationProps,
  _CableCorridorProps,
  _ExportCableProps,
  _AnchorProps,
  _CommonMooringLineProps,
  _ExclusionZoneProps,
  _SubAreaProps,
  _SlackRegionProps,
  _TouchdownPointProps,
  _ViewpointProps,
  _PortPointProps,
  _GridConnectionPointProps,
  _CablePartitionProps,
  _CableChainProps,
  _GeotiffProps,
  _BathymetryProps,
];
