import React, { Suspense } from "react";
import { Column } from "components/General/Layout";
import { DownloadAnalysisWindDataButton } from "components/WindWaveMeasurementInfoModal/DownloadWindDataButton";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { ProdId, getAnalysis, getWindRose } from "./state";
import { projectIdSelector } from "state/pathParams";
import { SubtitleWithLine } from "components/General/GeneralSideModals.style";
import { spaceLarge } from "styles/space";

const WindRose = React.lazy(
  () => import("components/WindWaveMeasurementInfoModal/WindRose"),
);

const DownloadButton = ({ id }: { id: ProdId }) => {
  const analysis = useRecoilValueLoadable(getAnalysis(id)).valueMaybe();
  const projectId = useRecoilValue(projectIdSelector);
  if (!analysis?.id || !projectId || analysis.status !== "complete")
    return null;
  return (
    <DownloadAnalysisWindDataButton
      analysisId={analysis.id}
      projectId={projectId}
      analysisVersion={analysis.version}
    />
  );
};

export const WindRoseWidget = ({ id }: { id: ProdId }) => {
  const windRose = useRecoilValueLoadable(getWindRose(id)).valueMaybe();

  return (
    <>
      <SubtitleWithLine
        style={{ paddingTop: spaceLarge }}
        text={"Wind rose"}
        iconButton={
          <Suspense fallback={null}>
            <DownloadButton id={id} />
          </Suspense>
        }
      />

      {windRose ? (
        <Suspense fallback={null}>
          <WindRose windRose={windRose} />
        </Suspense>
      ) : (
        <Column>
          <SkeletonBlock style={{ height: "1rem" }} />
          <SkeletonBlock style={{ height: "1rem" }} />
          <SkeletonBlock style={{ height: "1rem" }} />
        </Column>
      )}
    </>
  );
};
