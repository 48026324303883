import { CostType } from "../../../services/costService";
import { CostUnit } from "../../../types/financial";

export const DefaultCosts = {
  [CostType.Turbine]: {
    turbine: {
      [CostUnit.millionEuroPerMW]: 1,
    },
    installation: {
      [CostUnit.thousandEuroPerMW]: 60,
    },
  },
  [CostType.Cable]: {
    cable: {
      [CostUnit.millionEuroPerKM]: 0.3,
      [CostUnit.thousandEuroPerMW]: 80,
    },
    installation: {
      [CostUnit.millionEuroPerKM]: 0.13,
      [CostUnit.thousandEuroPerMW]: 90,
    },
  },
  [CostType.Foundation]: {
    foundation: {
      [CostUnit.millionEuroPerMW]: 0.8,
      [CostUnit.millionEuroPerUnit]: 12,
    },
    primarySteel: {
      [CostUnit.euroPerT]: 2700,
    },
    secondarySteel: {
      [CostUnit.euroPerT]: 6300,
    },
    concrete: {
      [CostUnit.euroPerM3]: 400,
    },
    reinforcement: {
      [CostUnit.euroPerT]: 1350,
    },
    postTensionCables: {
      [CostUnit.euroPerT]: 4000,
    },
    solidBallast: {
      [CostUnit.euroPerT]: 70,
    },
    installation: {
      [CostUnit.thousandEuroPerMW]: 115,
    },
  },
  [CostType.Mooring]: {
    line: {
      [CostUnit.millionEuroPerKM]: 0.6,
      [CostUnit.thousandEuroPerMW]: 130,
    },
    anchor: {
      [CostUnit.millionEuroPerUnit]: 0.18,
    },
    clumpWeight: {
      [CostUnit.euroPerT]: 900,
    },
    buoy: {
      [CostUnit.euroPerT]: 900,
    },
    installation: {
      [CostUnit.millionEuroPerLine]: 0.1,
      [CostUnit.thousandEuroPerMW]: 70,
      [CostUnit.millionEuroPerUnit]: 0.1,
    },
  },
  [CostType.Substation]: {
    substation: {
      [CostUnit.millionEuroPerMW]: 0.14,
      [CostUnit.millionEuroPerUnit]: 200,
    },
    installation: {
      [CostUnit.thousandEuroPerMW]: 40,
    },
  },
  [CostType.ExportCable]: {
    exportCable: {
      [CostUnit.millionEuroPerKM]: 1.1,
      [CostUnit.millionEuroPerMW]: 0.23,
    },
    installation: {
      [CostUnit.millionEuroPerKM]: 0.5,
      [CostUnit.thousandEuroPerMW]: 70,
    },
  },
  decom: {
    [CostUnit.thousandEuroPerMW]: 1117.4971304968,
    [CostUnit.percent]: 25,
  },
  devex: {
    [CostUnit.thousandEuroPerMW]: 140,
    [CostUnit.percent]: 5,
  },
};
