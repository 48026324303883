import { _SimpleTurbineType } from "./../../../types/turbines";
import { _FoundationType } from "types/foundations";
import { z } from "zod";
import * as librarySpec from "../../../api/library";
import { OrganisationResources } from "../OrganisationRightSide/types";
import { _Configuration } from "services/configurationService";
import { _CableType } from "services/cableTypeService";
import { _CostConfiguration } from "services/costService";
import { _DataLibraryPackage } from "./dataLibrary/types";

export const {
  NodeLibraryResource: _NodeLibraryResource,
  LibraryResource: _LibraryResource,
  LibraryResourceType: _LibraryResourceType,
  UploadedVectorDataLayer: _UploadedVectorDataLayer,
} = librarySpec.components.schemas;

export type NodeLibraryResource = z.infer<typeof _NodeLibraryResource>;

export type LibraryResource = z.infer<typeof _LibraryResource>;
export type LibraryResourceType = z.infer<typeof _LibraryResourceType>;

export function libraryResourceTypeToOrgResourceName(
  type: LibraryResourceType,
): OrganisationResources {
  switch (type) {
    case "FOUNDATION": {
      return "org_foundation_manage";
    }
    case "TURBINE": {
      return "org_turbine_manage";
    }
    default: {
      throw Error("Non existing type");
    }
  }
}

export const _NodeFoundationAccess = z.object({
  nodeId: z.string(),
  foundation: _FoundationType,
});

export type NodeFoundationAccess = z.infer<typeof _NodeFoundationAccess>;

export const _NodeTurbineAccess = z.object({
  nodeId: z.string(),
  turbine: _SimpleTurbineType,
});

export type NodeTurbineAccess = z.infer<typeof _NodeTurbineAccess>;

export const _NodeAnalysisAccess = z.object({
  nodeId: z.string(),
  config: _Configuration,
});

export type NodeAnalysisAccess = z.infer<typeof _NodeAnalysisAccess>;
export const _NodeCableAccess = z.object({
  nodeId: z.string(),
  cable: _CableType,
});

export type NodeCableAccess = z.infer<typeof _NodeCableAccess>;

export const _NodeFinancialAccess = z.object({
  nodeId: z.string(),
  config: _CostConfiguration,
});

export type NodeFinancialAccess = z.infer<typeof _NodeFinancialAccess>; //TODO: skal denne fra en yaml?

export const _NodeDataPackageAccess = z.object({
  nodeId: z.string(),
  config: _DataLibraryPackage,
});

export type NodeDataPackageAccess = z.infer<typeof _NodeDataPackageAccess>;

export type UploadedVectorDataLayer = z.infer<typeof _UploadedVectorDataLayer>;
