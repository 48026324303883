import React, { useCallback, useRef } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { Mixpanel } from "../../../mixpanel";
import { useClickOutside } from "../../../hooks/useClickOutside";
import useBooleanState from "../../../hooks/useBooleanState";
import { Frame } from "../../General/Layout";
import Checkbox from "../../General/Checkbox";
import { selectedComparisonAttributesAtom } from "../state";
import { useColumnTemplates } from "../columnTemplates";
import {
  borderRadiusMedium,
  spaceDecent,
  spaceLarge,
  spaceMedium,
  spaceSmall,
  spacing4,
} from "../../../styles/space";
import Button from "../../General/Button";
import { ComparisonAttributeKey } from "../types";
import { colors } from "styles/colors";
import { IconREMSize, typography } from "styles/typography";
import ColumnIcon from "@icons/24/Column.svg";
import { useTrackEvent } from "components/OnboardingTours/state";

const SelectAttributesWrapper = styled.div`
  position: relative;
  z-index: 4;
`;

const SelectAttributesFrame = styled(Frame)`
  padding: ${spaceLarge} 0;
  display: grid;
  grid-template-columns: repeat(6, auto);
  gap: ${spaceLarge};
  flex-direction: row;
  position: absolute;
  margin-top: ${spaceSmall};
  width: unset;
  max-height: 60vh;
  overflow-y: auto;

  > * {
    padding-left: initial;
    padding-right: initial;
  }
  padding-left: ${spaceLarge};
  padding-right: ${spaceLarge};
`;

const AttributeColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 18rem;
`;

const AttributeCheckboxes = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaceMedium};
  white-space: nowrap;
  margin-top: ${spaceMedium};
`;

const ClearAllWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-column: span 6;
`;

const SelectAttributes = () => {
  const [isOpen, toggleIsOpen] = useBooleanState(false);
  const [selectedAttributes, setSelectedAttributes] = useRecoilState(
    selectedComparisonAttributesAtom,
  );
  const wrapperRef = useRef<HTMLDivElement>(null);
  const frameRef = useRef<HTMLDivElement>(null);
  const columnTemplates = useColumnTemplates();

  useClickOutside(
    frameRef,
    toggleIsOpen,
    (target) => target === wrapperRef?.current,
  );
  const trackEvent = useTrackEvent();
  const onClearAll = useCallback(() => {
    setSelectedAttributes((curr) => {
      return (Object.keys(curr) as (keyof typeof curr)[]).reduce<
        typeof selectedAttributes
      >(
        (acc, key) => {
          acc[key] = [];
          return acc;
        },
        {} as typeof selectedAttributes,
      );
    });
  }, [setSelectedAttributes]);

  const nrSelectedAttribues = Object.values(selectedAttributes).reduce(
    (acc, curr) => acc + curr.length,
    0,
  );

  return (
    <SelectAttributesWrapper ref={wrapperRef}>
      <div
        style={{
          display: "flex",
          gap: spaceDecent,
          borderRadius: borderRadiusMedium,
          padding: `${spaceSmall} ${spaceDecent}`,
          alignItems: "center",
          cursor: "pointer",
          position: "relative",
          border: isOpen
            ? `1px solid ${colors.borderSelected}`
            : `1px solid ${colors.borderDefault}`,
          boxSizing: "border-box",
        }}
        onClick={() => {
          toggleIsOpen();
          Mixpanel.track("Toggle Select comparison parks open", {});
        }}
      >
        <IconREMSize height={1.6} width={1.6}>
          <ColumnIcon />
        </IconREMSize>
        <p style={{ ...typography.regular, margin: 0 }}>Select rows</p>
        <span
          style={{
            ...typography.regular,
            margin: 0,
            backgroundColor: colors.blue100,
            color: colors.blue600,
            padding: `2px ${spacing4}`,
            borderRadius: typography.regular.fontSize,
          }}
        >
          {nrSelectedAttribues}
        </span>
      </div>

      {isOpen && (
        <SelectAttributesFrame ref={frameRef}>
          {Object.entries(columnTemplates).map(([key, column]) => {
            const allIsSelected = column.attributes.every((attribute) =>
              (selectedAttributes as any)[key]?.includes(attribute.key),
            );
            const anyoneIsSelected = column.attributes.some((attribute) =>
              (selectedAttributes as any)[key]?.includes(attribute.key),
            );

            return (
              <AttributeColumn key={key}>
                <h4>{column.name}</h4>

                <AttributeCheckboxes>
                  <Checkbox
                    label="Select all"
                    labelPlacement="after"
                    checked={
                      allIsSelected
                        ? true
                        : anyoneIsSelected
                          ? "indeterminate"
                          : false
                    }
                    labelStyle={{ fontWeight: "bold" }}
                    onChange={(event) => {
                      Mixpanel.track("Select all attributes in Compare park", {
                        isSelected: event.target.checked,
                        key: key,
                      });
                      trackEvent("exitedRowSelectionInCompare");
                      setSelectedAttributes((curr) => {
                        if (event.target.checked) {
                          return {
                            ...curr,
                            [key]: column.attributes.map(
                              (attribute) => attribute.key,
                            ),
                          };
                        }

                        return {
                          ...curr,
                          [key]: [],
                        };
                      });
                    }}
                  />
                  {column.attributes.map((value) => (
                    <Checkbox
                      key={value.key}
                      label={value.name}
                      labelPlacement="after"
                      value={value.key}
                      checked={(selectedAttributes as any)[key]?.includes(
                        value.key,
                      )}
                      labelStyle={{
                        overflow: "hidden",
                        textWrap: "wrap",
                        wordWrap: "break-word",
                      }}
                      onChange={(event) => {
                        Mixpanel.track("Select attribute in Compare park", {
                          key: value.key,
                          label: value.name,
                          category: key,
                          isSelected: event.target.checked,
                        });
                        trackEvent("exitedRowSelectionInCompare");
                        setSelectedAttributes((curr) => {
                          if (event.target.checked) {
                            return {
                              ...curr,
                              [key]: [
                                ...curr[key as ComparisonAttributeKey],
                                value.key,
                              ],
                            };
                          }

                          return {
                            ...curr,
                            [key]: curr[key as ComparisonAttributeKey].filter(
                              (f: string) => f !== value.key,
                            ),
                          };
                        });
                      }}
                    />
                  ))}
                </AttributeCheckboxes>
              </AttributeColumn>
            );
          })}
          <ClearAllWrapper>
            <Button buttonType="text" text="Clear all" onClick={onClearAll} />
          </ClearAllWrapper>
        </SelectAttributesFrame>
      )}
    </SelectAttributesWrapper>
  );
};

export default SelectAttributes;
