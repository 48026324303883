import { selectorFamily } from "recoil";
import { fetchEnhancer } from "../services/utils";
import {
  TileJSONMetadata,
  _TileJSONMetadata,
  Layer,
  SourceTypesLayer,
  ExternalDataSourceLinkLayerWithSourceTileJSON,
} from "../types/layers";

export const isTileJSONLayer = (
  layer: Layer,
): layer is ExternalDataSourceLinkLayerWithSourceTileJSON => {
  return layer.sourceType === SourceTypesLayer.tilejson;
};

export const fetchTileJSONServerSelectorFamily = selectorFamily<
  Blob | undefined,
  string
>({
  key: "fetchTileJSONServerSelectorFamily",
  get: (url) => async () => {
    try {
      const response = await fetchEnhancer(url, {
        method: "get",
      });
      return response.blob();
    } catch (err) {
      console.warn(`Could not read from WFS server: ${url}, ${err}`);
    }
  },
});

export const tileJSONServerFullMetadataSelector = selectorFamily<
  TileJSONMetadata | undefined,
  string
>({
  key: "tileJSONServerFullMetadataSelector",
  get: (url) => async () => {
    try {
      const response = await fetchEnhancer(`${url}?f=json`, {
        method: "get",
      });
      return _TileJSONMetadata.parse(await response.json());
    } catch (err) {
      console.warn(`Could not read from WFS server: ${url}, ${err}`);
      return undefined;
    }
  },
});
