/// <reference types="vite-plugin-svgr/client" />
import React, { useMemo } from "react";
import styled from "styled-components";
import {
  spaceLarge,
  spaceMedium,
  spaceSmall,
  spaceTiny,
} from "../../../../styles/space";
import { colors } from "../../../../styles/colors";
import { IconREMSize, typography } from "../../../../styles/typography";
import FeatureToCanvas from "../../../FeatureToCanvas/FeatureToCanvas";
import BathymetryIcon from "@icons/24/SideBarIcons/Bathymetry.svg?react";
import ImageIcon from "@icons/24/FileImage.svg?react";
import { TextNoMargin } from "../shared";
import feature from "./polygonFeature.json";
import UploadDataLayerPreview from "../../../../assets/upload-data-layer-preview.png";
import UploadWindDataPreview from "../../../../assets/upload-wind-data-preview.png";
import { ProjectFeature } from "../../../../types/feature";
import { TypeLineDot, TypeDot } from "components/General/Icons";

const FileTypePreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaceSmall};
  width: 100%;
`;

const SupportedFormats = styled.div`
  display: flex;
  gap: ${spaceSmall};
  padding: ${spaceMedium};
  background-color: ${colors.focusBackground};
  color: ${colors.secondaryText};
`;

const FeatureTypeRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${spaceTiny};
`;

const ProjectFeatureGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${spaceMedium};
  flex-grow: 1;
  padding: ${spaceMedium} ${spaceLarge};
  background-color: ${colors.focusBackground};
`;

export const ProjectFeaturePreview = () => {
  const subAreaFeature = useMemo(() => {
    const _feature = JSON.parse(JSON.stringify(feature)) as any;
    _feature.properties.color = colors.subArea;
    return _feature;
  }, []);
  const exclusionZoneFeature = useMemo(() => {
    const _feature = JSON.parse(JSON.stringify(feature)) as any;
    _feature.properties.color = colors.exclusionZone;
    return _feature;
  }, []);

  return (
    <FileTypePreviewWrapper>
      <ProjectFeatureGrid>
        <FeatureTypeRow>
          <FeatureToCanvas
            style={{
              width: "1.3rem",
              height: "1.3rem",
            }}
            features={[feature as ProjectFeature]}
            fillPolygons={true}
            fillParks={true}
          />
          <TextNoMargin>Polygon</TextNoMargin>
        </FeatureTypeRow>
        <FeatureTypeRow>
          <TypeDot dotColor={colors.turbine} />
          <TextNoMargin>Turbines</TextNoMargin>
        </FeatureTypeRow>

        <FeatureTypeRow>
          <TypeDot dotColor={colors.anchor} />
          <TextNoMargin>Mooring</TextNoMargin>
        </FeatureTypeRow>
        <FeatureTypeRow>
          <TypeDot dotColor={colors.substation} />

          <TextNoMargin>Substation</TextNoMargin>
        </FeatureTypeRow>
        <FeatureTypeRow>
          <TypeLineDot dotColor={colors.cable} />
          <TextNoMargin>Inter array cabling</TextNoMargin>
        </FeatureTypeRow>
        <FeatureTypeRow>
          <TypeLineDot dotColor={colors.exportCableMissingType} />
          <TextNoMargin>Export cable</TextNoMargin>
        </FeatureTypeRow>
        <FeatureTypeRow>
          <FeatureToCanvas
            style={{
              width: "1.3rem",
              height: "1.3rem",
            }}
            features={[exclusionZoneFeature]}
            fillPolygons={true}
            fillParks={true}
          />
          <TextNoMargin>Exclusion zone</TextNoMargin>
        </FeatureTypeRow>
        <FeatureTypeRow>
          <FeatureToCanvas
            style={{
              width: "1.3rem",
              height: "1.3rem",
            }}
            features={[subAreaFeature]}
            fillPolygons={true}
            fillParks={true}
          />
          <TextNoMargin>Sub area</TextNoMargin>
        </FeatureTypeRow>
        <FeatureTypeRow>
          <IconREMSize height={1.3} width={1.3}>
            <ImageIcon />
          </IconREMSize>
          <TextNoMargin>Image</TextNoMargin>
        </FeatureTypeRow>
        <FeatureTypeRow>
          <IconREMSize height={1.3} width={1.3}>
            <BathymetryIcon />
          </IconREMSize>
          <TextNoMargin>Bathymetry</TextNoMargin>
        </FeatureTypeRow>
      </ProjectFeatureGrid>
      <SupportedFormats>
        <div style={{ ...typography.label, flexShrink: 0 }}>
          Supported formats:
        </div>
        <div style={{ ...typography.label }}>
          .shp, .geojson, .json, .kml, .kmz, .tiff, .zip, .jpg, .png, pasted
          coordinates
        </div>
      </SupportedFormats>
    </FileTypePreviewWrapper>
  );
};

export const DataLayerPreview = () => {
  return (
    <FileTypePreviewWrapper>
      <div
        style={{
          flexGrow: 1,
          backgroundImage: `url(${UploadDataLayerPreview})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <SupportedFormats>
        <div style={{ ...typography.label, flexShrink: 0 }}>
          Supported formats:
        </div>
        <div style={{ ...typography.label }}>
          .shp, .geojson, .kml, .kmz, .json, .zip, ARCGIS REST API, WMS, WMTS,
          WFS
        </div>
      </SupportedFormats>
    </FileTypePreviewWrapper>
  );
};

export const WindDataPreview = () => {
  return (
    <FileTypePreviewWrapper>
      <div
        style={{
          flexGrow: 1,
          backgroundImage: `url(${UploadWindDataPreview})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <SupportedFormats>
        <div style={{ ...typography.label, flexShrink: 0 }}>
          Supported formats:
        </div>
        <div style={{ ...typography.label }}>.csv, .txt, .asc, .wrg</div>
      </SupportedFormats>
    </FileTypePreviewWrapper>
  );
};
