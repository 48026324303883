/// <reference types="vite-plugin-svgr/client" />

import Spinner from "@icons/spinner/Spinner";
import React, { Suspense, useRef } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import {
  branchIdSelector,
  organisationIdSelector,
  projectIdSelector,
} from "../../../state/pathParams";
import {
  getBranchSelectorFamily,
  isLoadingAnySnapshotDataSelectorFamily,
} from "../../../state/timeline";
import { MenuFrame } from "../../MenuPopup/CloseableMenuPopup";
import VersionHistoryInner from "./VersionHistoryInner";
import { versionHistoryOpenStateAtom } from "./state";
import { spaceMedium } from "styles/space";

const VersionHistoryContainer = styled.div`
  display: flex;
  position: fixed;
  right: ${spaceMedium};
  top: calc(
    calc(var(--top-bar-menu-height) + var(--branch-tab-bar-height)) +
      ${spaceMedium}
  );
  height: calc(
    100vh - calc(
        var(--top-bar-menu-height) + var(--branch-tab-bar-height) +
          var(--side-bars-width)
      ) - 2.4rem
  );
  @media screen and (max-width: 1000px) {
    /* This is to not cover the mapbox logo */
    height: calc(
      100vh - calc(
          var(--top-bar-menu-height) + var(--branch-tab-bar-height) +
            var(--side-bars-width)
        ) - 6.8rem
    );
  }
`;

const VersonHistoryOuterInner = () => {
  const frameRef = useRef<HTMLDivElement>(null);
  const organisationId = useRecoilValue(organisationIdSelector) ?? "";
  const projectId = useRecoilValue(projectIdSelector) ?? "";
  const branchId = useRecoilValue(branchIdSelector) ?? "";
  const branch = useRecoilValue(
    getBranchSelectorFamily({
      projectId,
      branchId: branchId,
    }),
  );
  const branchName = branch?.title ?? "Banch";

  const setVersionHistoryOpenState = useSetRecoilState(
    versionHistoryOpenStateAtom,
  );

  const isLoadingSnapshotData = useRecoilValue(
    isLoadingAnySnapshotDataSelectorFamily({
      organisationId,
      projectId,
      branchId,
    }),
  );

  return (
    <VersionHistoryContainer>
      <MenuFrame
        ref={frameRef}
        title="Version history"
        subtitle={branchName}
        onExit={() => setVersionHistoryOpenState(false)}
        style={{
          flex: 1,
          overflow: "auto",
          width: "30rem",
        }}
        icon={isLoadingSnapshotData ? <Spinner size="1rem" /> : undefined}
      >
        <Suspense fallback={null}>
          <VersionHistoryInner />
        </Suspense>
      </MenuFrame>
    </VersionHistoryContainer>
  );
};

export default function VersionHistory() {
  const versionHistoryOpenState = useRecoilValue(versionHistoryOpenStateAtom);
  if (!versionHistoryOpenState) {
    return null;
  }

  return <VersonHistoryOuterInner />;
}
