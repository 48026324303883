/// <reference types="vite-plugin-svgr/client" />
import { useMemo } from "react";
import { IconREMSize } from "styles/typography";
import { colors } from "styles/colors";
import { ConfidenceLevel } from "types/financial";
import { ConfidenceLevelDot } from "./styles";
import { DropDownItem } from "components/General/Dropdown/DropdownItems";
import DropdownButton, {
  ButtonText,
  ButtonTextWrapper,
} from "components/General/Dropdown/DropdownButton";

export const ConfidenceLevelSelector = ({
  disabled,
  confidenceLevel,
  onConfidenceLevelChange,
}: {
  disabled?: boolean;
  confidenceLevel: ConfidenceLevel | undefined;
  onConfidenceLevelChange: (confidenceLevel: ConfidenceLevel) => void;
}) => {
  const confidenceLevels = Object.values(ConfidenceLevel);

  const items: DropDownItem[] = useMemo(() => {
    return [
      ...confidenceLevels.map((confidenceLevel) => {
        return {
          value: confidenceLevel,
          name: confidenceLevel,
          icon: (
            <IconREMSize fill={colors.textPrimary} width={1.4} height={1.4}>
              <ConfidenceLevelDot confidenceLevel={confidenceLevel} />
            </IconREMSize>
          ),
        };
      }),
    ];
  }, [confidenceLevels]);

  const selected = items.find((i) => i.value === confidenceLevel);

  return (
    <DropdownButton
      disabled={disabled}
      size={"fixed"}
      items={items}
      onSelectItem={(e) => {
        onConfidenceLevelChange(e as ConfidenceLevel);
      }}
      buttonText={selected?.value ?? "..."}
      selectedItemValue={selected?.value}
      renderText={(e) => (
        <ButtonTextWrapper>
          <ConfidenceLevelDot confidenceLevel={confidenceLevel} />
          <ButtonText>{e}</ButtonText>
        </ButtonTextWrapper>
      )}
    />
  );
};
