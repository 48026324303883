import styled from "styled-components";
import { StandardBox } from "styles/boxes/Boxes";
import { colors } from "styles/colors";
import { spaceMedium } from "styles/space";

export const Modal = styled(StandardBox)`
  color: ${colors.textPrimary};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1.8rem;
  width: 50vw;
  max-width: 65rem;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  overflow: visible;
  box-sizing: border-box;
  max-height: 90vh;
  overflow-y: auto;
`;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: ${spaceMedium};

  h2 {
    margin: 0;
  }
`;
