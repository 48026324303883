import { useEffect } from "react";
import { selectorFamily, useRecoilValue } from "recoil";
import { getAllTurbinesInBranchSelector } from "state/layout";
import { useValidationWarnings } from "./ValidationWarnings";
import { turbineSourceId } from "components/Mapbox/constants";
import { ValidationWarningTypes } from "./utils";
import { allFoundationTypesSelector } from "state/foundations";
import { branchIdSelector } from "state/pathParams";

export const foundationTypeInvalidWarningSelector = selectorFamily<
  | { type: ValidationWarningTypes.FoundationTypeInvalid; featureIds: string[] }
  | undefined,
  {
    branchId: string;
  }
>({
  key: "foundationTypeInvalidWarningSelector",
  get:
    ({ branchId }) =>
    ({ get }) => {
      const foundationTypes = get(allFoundationTypesSelector);
      const allProjectTurbines = get(
        getAllTurbinesInBranchSelector({ branchId }),
      );
      const invalidFoundationTypeReferences = allProjectTurbines.filter(
        (f) =>
          f.properties.foundationId &&
          foundationTypes.every((tt) => tt.id !== f.properties.foundationId),
      );
      return invalidFoundationTypeReferences.length === 0
        ? undefined
        : {
            type: ValidationWarningTypes.FoundationTypeInvalid,
            featureIds: invalidFoundationTypeReferences.map((t) => t.id),
          };
    },
});

export const FoundationTypeInvalid = ({
  map,
  source = turbineSourceId,
}: {
  map: mapboxgl.Map;
  source?: string;
}) => {
  const branchId = useRecoilValue(branchIdSelector);
  const invalidFoundationTypeReferences = useRecoilValue(
    foundationTypeInvalidWarningSelector({ branchId: branchId ?? "" }),
  );

  const { warn, remove } = useValidationWarnings();

  useEffect(() => {
    if (!invalidFoundationTypeReferences) return;
    warn(invalidFoundationTypeReferences);
    if (map.getSource(source))
      for (const id of invalidFoundationTypeReferences.featureIds)
        map.setFeatureState({ source, id }, { overlap: true });

    return () => {
      remove(ValidationWarningTypes.FoundationTypeInvalid);
      if (map.getSource(source))
        for (const id of invalidFoundationTypeReferences.featureIds)
          map.removeFeatureState({ source, id }, "overlap");
    };
  }, [invalidFoundationTypeReferences, map, remove, source, warn]);

  return null;
};
