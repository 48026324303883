import SettingsModalHeader from "../Shared/SettingsModalHeader";
import FullScreenModal from "../../FullScreenModal/FullScreenModal";
import { ContentContainer, Modal } from "../Shared/styles";
import SettingsSubMenu from "../Shared/SettingsSubMenu";
import { modalTypeOpenAtom } from "../../../state/modal";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { PublicModeModalTypeV2 } from "../../../state/configuration";
import usePublicModeConfiguration from "./Data/usePublicModeConfiguration";
import { useEffect } from "react";
import { selectedMenuItemState } from "../Shared/state";
import { projectIdSelector } from "state/pathParams";

const PublicModeModalInner = () => {
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const publicMdoeConfiguration = usePublicModeConfiguration();

  const { title, submenus } = publicMdoeConfiguration;

  return (
    <FullScreenModal>
      <Modal>
        <SettingsModalHeader
          title={title}
          onClose={() => setModalTypeOpen(undefined)}
        />
        <ContentContainer style={{ padding: 0 }}>
          <SettingsSubMenu
            parentId={PublicModeModalTypeV2}
            submenus={submenus}
            depth={0}
          />
        </ContentContainer>
      </Modal>
    </FullScreenModal>
  );
};
const PublicModeModal = () => {
  const modalTypeOpen = useRecoilValue(modalTypeOpenAtom);
  const projectId = useRecoilValue(projectIdSelector);

  const setSelectedItemId = useSetRecoilState(
    selectedMenuItemState({
      menuId: PublicModeModalTypeV2,
      projectId: projectId ?? "",
    }),
  );

  useEffect(() => {
    if (modalTypeOpen?.modalType === PublicModeModalTypeV2) {
      const defaultSelection = modalTypeOpen.metadata?.selectedMenuId;
      if (defaultSelection) {
        setSelectedItemId(defaultSelection);
      }
    }
  }, [modalTypeOpen, setSelectedItemId]);

  if (projectId == null || modalTypeOpen?.modalType !== PublicModeModalTypeV2)
    return null;

  return <PublicModeModalInner />;
};

export default PublicModeModal;
