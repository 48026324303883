import { atom } from "recoil";
import { useRecoilCallback } from "recoil";
import { v4 as uuid } from "uuid";
import {
  CapexEntry,
  CashFlows,
  Contingency,
  CostConfiguration,
  CostType,
  Inflation,
  LCOE,
  LifeCycle,
  OpexEntry,
} from "../../../services/costService";
import { initializeAndSet } from "../../Comments/hooks/useReplyReactionCrud";
import {
  ConfidenceLevel,
  CostUnit,
  CostWithUnit,
  OccuranceType,
} from "../../../types/financial";
import { DefaultCosts } from "./constants";

export const getDefaultCostConfig = ({
  id,
}: {
  id: boolean;
}): Omit<CostConfiguration, "id"> & { id?: string } => ({
  id: id ? uuid() : undefined,
  name: "Untitled",
  devex: {
    cost: DefaultCosts.devex[CostUnit.thousandEuroPerMW],
    unit: CostUnit.thousandEuroPerMW,
    confidenceLevel: ConfidenceLevel.notSpecified,
  },
  capex: {
    fixed: {
      turbines: "feature_cost",
      foundations: {
        primarySteel: {
          unit: CostUnit.euroPerT,
          cost: DefaultCosts[CostType.Foundation].primarySteel[
            CostUnit.euroPerT
          ],
          confidenceLevel: ConfidenceLevel.notSpecified,
        },
        concrete: {
          unit: CostUnit.euroPerM3,
          cost: DefaultCosts[CostType.Foundation].concrete[CostUnit.euroPerM3],
          confidenceLevel: ConfidenceLevel.notSpecified,
        },
        reinforcement: {
          unit: CostUnit.euroPerT,
          cost: DefaultCosts[CostType.Foundation].reinforcement[
            CostUnit.euroPerT
          ],
          confidenceLevel: ConfidenceLevel.notSpecified,
        },
        postTensionCables: {
          unit: CostUnit.euroPerT,
          cost: DefaultCosts[CostType.Foundation].postTensionCables[
            CostUnit.euroPerT
          ],
          confidenceLevel: ConfidenceLevel.notSpecified,
        },
        solidBallast: {
          unit: CostUnit.euroPerT,
          cost: DefaultCosts[CostType.Foundation].solidBallast[
            CostUnit.euroPerT
          ],
          confidenceLevel: ConfidenceLevel.notSpecified,
        },
        floating: {
          primarySteel: {
            unit: CostUnit.euroPerT,
            cost: DefaultCosts[CostType.Foundation].primarySteel[
              CostUnit.euroPerT
            ],
            confidenceLevel: ConfidenceLevel.notSpecified,
          },
          concrete: {
            unit: CostUnit.euroPerM3,
            cost: DefaultCosts[CostType.Foundation].concrete[
              CostUnit.euroPerM3
            ],
            confidenceLevel: ConfidenceLevel.notSpecified,
          },
          reinforcement: {
            unit: CostUnit.euroPerT,
            cost: DefaultCosts[CostType.Foundation].reinforcement[
              CostUnit.euroPerT
            ],
            confidenceLevel: ConfidenceLevel.notSpecified,
          },
          postTensionCables: {
            unit: CostUnit.euroPerT,
            cost: DefaultCosts[CostType.Foundation].postTensionCables[
              CostUnit.euroPerT
            ],
            confidenceLevel: ConfidenceLevel.notSpecified,
          },
          solidBallast: {
            unit: CostUnit.euroPerT,
            cost: DefaultCosts[CostType.Foundation].solidBallast[
              CostUnit.euroPerT
            ],
            confidenceLevel: ConfidenceLevel.notSpecified,
          },
        },
        monopile: {
          primarySteel: {
            unit: CostUnit.euroPerT,
            cost: DefaultCosts[CostType.Foundation].primarySteel[
              CostUnit.euroPerT
            ],
            confidenceLevel: ConfidenceLevel.notSpecified,
          },
        },
        jacket: {
          primarySteel: {
            unit: CostUnit.euroPerT,
            cost: DefaultCosts[CostType.Foundation].primarySteel[
              CostUnit.euroPerT
            ],
            confidenceLevel: ConfidenceLevel.notSpecified,
          },
        },
      },
      mooring: {
        anchors: {
          unit: CostUnit.millionEuroPerUnit,
          cost: DefaultCosts[CostType.Mooring].anchor[
            CostUnit.millionEuroPerUnit
          ],
          confidenceLevel: ConfidenceLevel.notSpecified,
        },
        clumpWeights: {
          unit: CostUnit.euroPerT,
          cost: DefaultCosts[CostType.Mooring].clumpWeight[CostUnit.euroPerT],
          confidenceLevel: ConfidenceLevel.notSpecified,
        },
        buoys: {
          unit: CostUnit.euroPerT,
          cost: DefaultCosts[CostType.Mooring].buoy[CostUnit.euroPerT],
          confidenceLevel: ConfidenceLevel.notSpecified,
        },
      },
      substation: {
        cost: DefaultCosts[CostType.Substation].substation[
          CostUnit.millionEuroPerMW
        ],
        unit: CostUnit.millionEuroPerMW,
        confidenceLevel: ConfidenceLevel.notSpecified,
      },
      exportCable: {
        cost: DefaultCosts[CostType.ExportCable].exportCable[
          CostUnit.millionEuroPerKM
        ],
        unit: CostUnit.millionEuroPerKM,
        confidenceLevel: ConfidenceLevel.notSpecified,
      },
    },
    custom: [
      {
        id: uuid(),
        category: CostType.Turbine,
        name: "Installation",
        cost: DefaultCosts[CostType.Turbine].installation[
          CostUnit.thousandEuroPerMW
        ],
        unit: CostUnit.thousandEuroPerMW,
        confidenceLevel: ConfidenceLevel.notSpecified,
      },
      {
        id: uuid(),
        category: CostType.Cable,
        name: "Installation",
        cost: DefaultCosts[CostType.Cable].installation[
          CostUnit.millionEuroPerKM
        ],
        unit: CostUnit.millionEuroPerKM,
        confidenceLevel: ConfidenceLevel.notSpecified,
      },
      {
        id: uuid(),
        category: CostType.Foundation,
        name: "Installation",
        cost: DefaultCosts[CostType.Foundation].installation[
          CostUnit.thousandEuroPerMW
        ],
        unit: CostUnit.thousandEuroPerMW,
        confidenceLevel: ConfidenceLevel.notSpecified,
      },
      {
        id: uuid(),
        category: CostType.Mooring,
        name: "Installation",
        cost: DefaultCosts[CostType.Mooring].installation[
          CostUnit.millionEuroPerLine
        ],
        unit: CostUnit.millionEuroPerLine,
        confidenceLevel: ConfidenceLevel.notSpecified,
      },
      {
        id: uuid(),
        category: CostType.Substation,
        name: "Installation",
        cost: DefaultCosts[CostType.Substation].installation[
          CostUnit.thousandEuroPerMW
        ],
        unit: CostUnit.thousandEuroPerMW,
        confidenceLevel: ConfidenceLevel.notSpecified,
      },
      {
        id: uuid(),
        category: CostType.ExportCable,
        name: "Installation",
        cost: DefaultCosts[CostType.ExportCable].installation[
          CostUnit.millionEuroPerKM
        ],
        unit: CostUnit.millionEuroPerKM,
        confidenceLevel: ConfidenceLevel.notSpecified,
      },
    ],
  },
  opex: {
    custom: [
      {
        id: uuid(),
        name: "Service (per MW)",
        cost: 45,
        unit: CostUnit.thousandEuroPerMWPerYear,
        occurance: OccuranceType.Yearly,
      },
      {
        id: uuid(),
        name: "Service (per MWh)",
        cost: 5,
        unit: CostUnit.euroPerMWh,
        occurance: OccuranceType.Yearly,
      },
      {
        id: uuid(),
        name: "Other (per MW)",
        cost: 5,
        unit: CostUnit.thousandEuroPerMWPerYear,
        occurance: OccuranceType.Yearly,
      },
    ],
  },
  decom: {
    cost: DefaultCosts.decom[CostUnit.thousandEuroPerMW],
    unit: CostUnit.thousandEuroPerMW,
    confidenceLevel: ConfidenceLevel.notSpecified,
  },
  lcoe: {
    discountRate: 0.05,
  },
  lifeCycle: {
    projectStart: 2024,
    operationStart: 2024,
    decomissioning: 2049,
    phasing: {
      devex: [1.0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      capex: [1.0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  },
  inflation: {
    inflationRate: 0,
    referenceYearCapex: 2024,
    referenceYearOtherExpenditures: 2024,
    referenceYearRevenue: 2024,
  },
  cashFlows: {
    guaranteedPrice: {
      cost: 115,
      unit: CostUnit.euroPerMWh,
      confidenceLevel: ConfidenceLevel.notSpecified,
    },
    marketPrice: {
      cost: 70,
      unit: CostUnit.euroPerMWh,
      confidenceLevel: ConfidenceLevel.notSpecified,
    },
    guaranteedYears: 15,
    loanCapexFraction: 0.0,
    loanInterestRate: 0.0,
    loanRepaymentYears: 0,
  },
  contingency: { capex: { type: "fraction", value: 0 } },
});

export const localCostConfigurationAtom = atom<CostConfiguration>({
  key: "localCostConfigurationAtom",
  default: getDefaultCostConfig({ id: true }) as CostConfiguration,
});

export const useLocalCostConfigurationCrud = () => {
  const updateFixedCapex = useRecoilCallback(
    ({ set, snapshot }) =>
      async (update: Record<string, unknown>, key?: string) => {
        initializeAndSet(
          snapshot,
          set,
          localCostConfigurationAtom,
          (cur: CostConfiguration) => {
            if (key) {
              return {
                ...cur,
                capex: {
                  ...cur.capex,
                  fixed: {
                    ...cur.capex.fixed,
                    [key]: {
                      ...(cur.capex.fixed as any)[key],
                      ...update,
                    },
                  },
                },
              };
            }

            return {
              ...cur,
              capex: {
                ...cur.capex,
                fixed: {
                  ...cur.capex.fixed,
                  ...update,
                },
              },
            };
          },
        );
      },
    [],
  );

  const updateCustomCapex = useRecoilCallback(
    ({ set, snapshot }) =>
      async (update: CapexEntry) => {
        initializeAndSet(
          snapshot,
          set,
          localCostConfigurationAtom,
          (cur: CostConfiguration) => {
            const match = cur.capex.custom.some((c) => c.id === update.id);

            if (match) {
              return {
                ...cur,
                capex: {
                  ...cur.capex,
                  custom: cur.capex.custom.map((c) =>
                    c.id === update.id ? update : c,
                  ),
                },
              };
            }

            return {
              ...cur,
              capex: {
                ...cur.capex,
                custom: [...cur.capex.custom, update],
              },
            };
          },
        );
      },
    [],
  );

  const updateLCOE = useRecoilCallback(
    ({ set, snapshot }) =>
      async (update: Partial<LCOE>) => {
        initializeAndSet(
          snapshot,
          set,
          localCostConfigurationAtom,
          (cur: CostConfiguration) => {
            return {
              ...cur,
              lcoe: {
                ...cur.lcoe,
                ...update,
              },
            };
          },
        );
      },
    [],
  );

  const updateCashFlows = useRecoilCallback(
    ({ set, snapshot }) =>
      async (update: Partial<CashFlows>) => {
        initializeAndSet(
          snapshot,
          set,
          localCostConfigurationAtom,
          (cur: CostConfiguration) => {
            return {
              ...cur,
              cashFlows: {
                ...cur.cashFlows,
                ...update,
              },
            };
          },
        );
      },
    [],
  );

  const updateOPEX = useRecoilCallback(
    ({ set, snapshot }) =>
      async (update: OpexEntry) => {
        initializeAndSet(
          snapshot,
          set,
          localCostConfigurationAtom,
          (cur: CostConfiguration) => {
            const match = cur.opex.custom.some((c) => c.id === update.id);

            if (match) {
              return {
                ...cur,
                opex: {
                  ...cur.opex,
                  custom: cur.opex.custom.map((e) =>
                    e.id === update.id ? update : e,
                  ),
                },
              };
            }

            return {
              ...cur,
              opex: {
                ...cur.opex,
                custom: [...cur.opex.custom, update],
              },
            };
          },
        );
      },
    [],
  );

  const updateDEVEX = useRecoilCallback(
    ({ set, snapshot }) =>
      async (update: Partial<CostWithUnit>) => {
        initializeAndSet(
          snapshot,
          set,
          localCostConfigurationAtom,
          (cur: CostConfiguration) => {
            return {
              ...cur,
              devex: {
                ...cur.devex,
                ...update,
              },
            };
          },
        );
      },
    [],
  );

  const updateDECOM = useRecoilCallback(
    ({ set, snapshot }) =>
      async (update: Partial<CostWithUnit>) => {
        initializeAndSet(
          snapshot,
          set,
          localCostConfigurationAtom,
          (cur: CostConfiguration) => {
            return {
              ...cur,
              decom: {
                ...cur.decom,
                ...update,
              },
            };
          },
        );
      },
    [],
  );

  const updateLifeCycle = useRecoilCallback(
    ({ set, snapshot }) =>
      async (update: Partial<LifeCycle>) => {
        initializeAndSet(
          snapshot,
          set,
          localCostConfigurationAtom,
          (cur: CostConfiguration) => {
            return {
              ...cur,
              lifeCycle: {
                ...cur.lifeCycle,
                ...update,
              },
            };
          },
        );
      },
    [],
  );

  const updateInflation = useRecoilCallback(
    ({ set, snapshot }) =>
      async (update: Partial<Inflation>) => {
        initializeAndSet(
          snapshot,
          set,
          localCostConfigurationAtom,
          (cur: CostConfiguration) => {
            return {
              ...cur,
              inflation: {
                ...cur.inflation,
                ...update,
              },
            };
          },
        );
      },
    [],
  );

  const updateContingency = useRecoilCallback(
    ({ set, snapshot }) =>
      async (update: Partial<Contingency>) => {
        initializeAndSet(
          snapshot,
          set,
          localCostConfigurationAtom,
          (cur: CostConfiguration) => {
            return {
              ...cur,
              contingency: {
                ...cur.contingency,
                ...update,
              },
            };
          },
        );
      },
    [],
  );

  const deleteOPEX = useRecoilCallback(
    ({ set, snapshot }) =>
      async (id: string) => {
        initializeAndSet(
          snapshot,
          set,
          localCostConfigurationAtom,
          (cur: CostConfiguration) => {
            const match = cur.opex.custom.some((c) => c.id === id);

            if (match) {
              return {
                ...cur,
                opex: {
                  ...cur.opex,
                  custom: cur.opex.custom.filter((e) => e.id !== id),
                },
              };
            }

            return cur;
          },
        );
      },
    [],
  );

  return {
    updateFixedCapex,
    updateCustomCapex,
    updateLCOE,
    updateDEVEX,
    updateOPEX,
    updateDECOM,
    updateCashFlows,
    deleteOPEX,
    updateLifeCycle,
    updateInflation,
    updateContingency,
  };
};
