import mapboxgl, { Map } from "mapbox-gl";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { turbinesEditableCircleLayerId } from "../../../constants/draw";
import {
  visibleDynamicLayersAtom,
  visibleTileJSONLayersAtom,
} from "../../../state/layer";
import { canvasLayerFeatureHiddenAtomFamily } from "../../../state/projectLayers";
import { currentSelectionArrayAtom } from "../../../state/selection";
import { currentExternalLayerSelection } from "../../../state/externalLayerSelection";

import { dedup } from "../../../utils/utils";
import { featureIsLocked, isDefined } from "../../../utils/predicates";
import {
  dynamicTileCircleToLayerId,
  dynamicTileLineToLayerId,
  dynamicTilePolygonToLayerId,
} from "../../../layers/ExternalLayers/dynamicTileJSONLayers";

import {
  clearHover,
  setHoverMultiple,
  siteLocatorLayerId,
} from "components/Mapbox/utils";
import Tooltip from "../../General/Tooltip";
import { useShiftDragMultiSelect } from "../../../hooks/shiftDragMultiSelect";
import { useClickOutside } from "../../../hooks/useClickOutside";
import {
  AbsoluteContainer,
  FooterBox,
  HeaderBox,
  ItemContainer,
} from "./style";
import {
  selectionNameToTypes,
  selectionNames,
  selectionNameToIcon,
  basicProjectFeatureLayers,
} from "./const";
import { getDragDirection, completelyInsideBboxFilter } from "./utils";
import { addIdOnFeaturesIfNotUUID4 } from "utils/geojson/utils";
import { branchIdSelector } from "state/pathParams";
import Checkbox from "components/General/Checkbox";
import { Label } from "components/General/Form";
import { useLocalStorage } from "hooks/useBrowserStorage";

let resetTimeout: NodeJS.Timeout | string | number | undefined;
let dragStatus: "drag" | "complete" = "complete";
let _bbox: [mapboxgl.Point, mapboxgl.Point] | undefined;
let selectionTypeIndex: number = 0;

const MultiSelectFeatures = ({ map }: { map: Map }) => {
  const setCurrentSelectionArray = useSetRecoilState(currentSelectionArrayAtom);
  const setCurrentExternalSelectionArray = useSetRecoilState(
    currentExternalLayerSelection,
  );
  const [includeLockedFeatures, setIncludeLockedFeatures] = useLocalStorage(
    "vind:shift-drag-include-locked-features",
    true,
  );
  const visibleDynamicLayers = useRecoilValue(visibleDynamicLayersAtom);
  const visibleTileJSONLayers = useRecoilValue(visibleTileJSONLayersAtom);
  const branchId = useRecoilValue(branchIdSelector);
  const canvasLayerFeatureHidden = useRecoilValue(
    canvasLayerFeatureHiddenAtomFamily({ branchId }),
  );

  const [inProgress, setInProgress] = useState(false);

  const [selectionTypeIndexState, setSelectionTypeIndexState] = useState(
    selectionTypeIndex ?? 0,
  );
  const [currentlySelectedTypes, setCurrentlySelectedTypes] = useState<
    (string | undefined)[]
  >([]);

  const resetComponent = useCallback(() => {
    _bbox = undefined;
    selectionTypeIndex = 0;
    setInProgress(false);
    setSelectionTypeIndexState(0);
    setCurrentlySelectedTypes([]);
  }, []);

  const _getExternalFeatures = useCallback(
    (
      selectionTypeIndex: number,
      bbox: [mapboxgl.Point, mapboxgl.Point],
      dragDirection: "left" | "right",
    ) => {
      const tileJSONCircleLayers = visibleTileJSONLayers.circles.map((l) =>
        dynamicTileCircleToLayerId(l),
      );
      const tileJSONPolygonLayers = visibleTileJSONLayers.polygons.map((l) =>
        dynamicTilePolygonToLayerId(l),
      );
      const tileJSONLineLayers = visibleTileJSONLayers.lines.map((l) =>
        dynamicTileLineToLayerId(l),
      );
      let renderedExternalLayerFeatures = map.queryRenderedFeatures(bbox, {
        layers: [
          ...visibleDynamicLayers,
          ...tileJSONCircleLayers,
          ...tileJSONPolygonLayers,
          ...tileJSONLineLayers,
        ].filter((l) => map.getLayer(l)),
      });

      if (dragDirection !== "left") {
        renderedExternalLayerFeatures = completelyInsideBboxFilter(
          map,
          bbox,
          renderedExternalLayerFeatures,
        );
      }

      if (selectionTypeIndex !== 0) {
        const filterTypes =
          selectionNameToTypes[selectionNames[selectionTypeIndex]];

        renderedExternalLayerFeatures = renderedExternalLayerFeatures.filter(
          (feature) => {
            return filterTypes.includes(feature.properties?.type);
          },
        );
      }
      return renderedExternalLayerFeatures;
    },
    [map, visibleDynamicLayers, visibleTileJSONLayers],
  );

  const _getProjectFeatures = useCallback(
    (
      selectionTypeIndex: number,
      bbox: [mapboxgl.Point, mapboxgl.Point],
      dragDirection: "left" | "right",
    ) => {
      const startsWithAnyPrefix = (layerName: string) => {
        return [
          ...basicProjectFeatureLayers,
          turbinesEditableCircleLayerId,
          "vind:",
        ].some((prefix) => layerName.startsWith(prefix));
      };

      const allLayers = map.getStyle().layers;

      const filteredLayerNames = allLayers
        .filter((layer) => startsWithAnyPrefix(layer.id))
        .filter((layer) => layer.id !== siteLocatorLayerId)
        .map((layer) => layer.id);

      let renderedProjectFeatures = map
        .queryRenderedFeatures(bbox, {
          layers: filteredLayerNames.filter((l) => map.getLayer(l)),
        })
        .filter((f) => includeLockedFeatures || !featureIsLocked(f))
        .filter((f) => f.id);

      if (dragDirection !== "left") {
        renderedProjectFeatures = renderedProjectFeatures =
          completelyInsideBboxFilter(map, bbox, renderedProjectFeatures);
      }
      if (selectionTypeIndex !== 0) {
        const filterTypes =
          selectionNameToTypes[selectionNames[selectionTypeIndex]];
        renderedProjectFeatures = renderedProjectFeatures.filter((feature) => {
          return filterTypes.includes(feature.properties?.type);
        });
      }

      if (renderedProjectFeatures.length !== 0) {
        const visibleFeatures = renderedProjectFeatures.filter(
          (f) => !canvasLayerFeatureHidden.includes(String(f.id)),
        );
        const unique = dedup(visibleFeatures, (f) => f.id);

        return unique;
      }

      return [];
    },
    [canvasLayerFeatureHidden, map, includeLockedFeatures],
  );

  const selectCallback = useCallback(
    (
      status: "drag" | "complete",
      bbox: [mapboxgl.Point, mapboxgl.Point],
      resetSelection?: boolean,
    ) => {
      const dragDirection = getDragDirection(bbox);
      dragStatus = status;
      setInProgress(true);

      const selectedFeatures = _getProjectFeatures(
        selectionTypeIndex,
        bbox,
        dragDirection,
      );

      if (status === "drag") {
        _bbox = bbox;

        setCurrentlySelectedTypes(
          Array.from(new Set(selectedFeatures.map((f) => f.properties?.type))),
        );
        setHoverMultiple(
          map,
          selectedFeatures.map((f) => ({
            source: f.source,
            id: f?.id ? f.id : f.properties?.id,
            sourceLayer: f?.sourceLayer,
          })),
        );
      } else if (status === "complete") {
        _bbox = bbox;
        clearHover(map);
        resetTimeout && clearTimeout(resetTimeout);
        resetTimeout = setTimeout(resetComponent, 10000);

        const selectedFeatureIds = selectedFeatures
          .map((f) => f.id)
          .filter(isDefined)
          .map(String);

        let renderedExternalLayerFeatures = _getExternalFeatures(
          selectionTypeIndex,
          bbox,
          dragDirection,
        );

        setCurrentSelectionArray((curr) =>
          dedup([...selectedFeatureIds, ...(resetSelection ? [] : curr)]),
        );

        if (renderedExternalLayerFeatures.length !== 0) {
          const uniqueFeatures = dedup(
            renderedExternalLayerFeatures,
            (feature) => feature.id,
          );

          const externalFeaturesWithCompleteGeometry = uniqueFeatures
            .map((feature) => {
              const source = map.getSource(String(feature.layer.source)) as
                | mapboxgl.GeoJSONSource
                | undefined;
              if (!source) {
                return undefined;
              }

              const geometry = (
                (source as any)["_data"] ?? { features: [] }
              ).features.find(
                (sourceFeat: any) => sourceFeat.id === feature.id,
              )?.geometry;
              if (!geometry) {
                return undefined;
              }

              return {
                ...feature,
                geometry,
              };
            })
            .filter(isDefined);

          const externalFeaturesWithoutCompleteGeometry =
            addIdOnFeaturesIfNotUUID4(
              uniqueFeatures
                .filter(
                  (f) =>
                    !externalFeaturesWithCompleteGeometry
                      .map((fc) => fc.id)
                      .includes(f.id),
                )
                .map((feature) => ({
                  ...feature,
                  geometry: feature.geometry,
                }))
                .filter(isDefined),
            );

          setCurrentExternalSelectionArray([
            ...externalFeaturesWithCompleteGeometry,
            ...externalFeaturesWithoutCompleteGeometry,
          ]);
        }
        return () => clearTimeout(resetTimeout);
      }
    },
    [
      _getExternalFeatures,
      _getProjectFeatures,
      map,
      resetComponent,
      setCurrentExternalSelectionArray,
      setCurrentSelectionArray,
    ],
  );

  const onChangeSelectionIndex = useCallback(
    (nextIndex: number) => {
      if (!_bbox) return;
      selectionTypeIndex = nextIndex;
      setSelectionTypeIndexState(nextIndex);

      selectCallback(dragStatus, _bbox, true);

      resetTimeout && clearTimeout(resetTimeout);
      resetTimeout = setTimeout(resetComponent, 4000);
    },
    [resetComponent, selectCallback],
  );

  useEffect(() => {
    const onClickEnterFinishEditing = (e: KeyboardEvent) => {
      if (e.key === "Tab") {
        e.preventDefault();
        let nextIndex =
          selectionTypeIndex + 1 > selectionNames.length - 1
            ? 0
            : selectionTypeIndex + 1;

        // check if the type to be selected is not disabled,
        // if it is go to the next on until you find one that is not disabled
        let loopCounter = 0;
        if (nextIndex > 0) {
          while (
            nextIndex > 0 &&
            selectionNameToTypes[selectionNames[nextIndex]].every(
              (type) => currentlySelectedTypes.indexOf(type) === -1,
            )
          ) {
            nextIndex =
              nextIndex + 1 > selectionNames.length - 1 ? 0 : nextIndex + 1;

            loopCounter++;

            if (loopCounter >= selectionNames.length) {
              break;
            }
          }
        }
        onChangeSelectionIndex(nextIndex);
      }
    };
    document.addEventListener("keydown", onClickEnterFinishEditing);
    return () =>
      document.removeEventListener("keydown", onClickEnterFinishEditing);
  }, [currentlySelectedTypes, onChangeSelectionIndex]);

  useEffect(() => {
    return () => {
      resetTimeout && clearTimeout(resetTimeout);
    };
  }, []);

  useShiftDragMultiSelect(map, selectCallback);

  const popupRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(
    popupRef,
    () => {
      clearTimeout(resetTimeout);
      resetComponent();
    },
    undefined,
    { ignoreDragClicks: true },
  );

  return (
    <>
      {inProgress && (
        <AbsoluteContainer
          ref={popupRef}
          onMouseOver={() => {
            resetTimeout && clearTimeout(resetTimeout);
            resetTimeout = setTimeout(resetComponent, 4000);
          }}
        >
          <HeaderBox>Drag & select filter</HeaderBox>
          {selectionNames.map((name, index) => {
            const icon = (selectionNameToIcon as Record<string, ReactNode>)[
              name
            ];
            const types = selectionNameToTypes[name];
            const isDisabled =
              types &&
              !types.some((type) => currentlySelectedTypes.indexOf(type) > -1);
            return (
              <Tooltip text={name} key={name}>
                <ItemContainer
                  disabled={isDisabled}
                  selected={selectionTypeIndexState === index}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (isDisabled) {
                      return;
                    }
                    onChangeSelectionIndex(index);
                  }}
                >
                  {icon}
                </ItemContainer>
              </Tooltip>
            );
          })}
          <FooterBox>
            <Label left={true}>
              <Checkbox
                checked={includeLockedFeatures}
                onChange={(e) => setIncludeLockedFeatures(e.target.checked)}
              />
              <span>Include locked features</span>
            </Label>
          </FooterBox>
        </AbsoluteContainer>
      )}
    </>
  );
};

export default MultiSelectFeatures;
