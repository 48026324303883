/// <reference types="vite-plugin-svgr/client" />
import React from "react";
import styled from "styled-components";
import { spacing6 } from "styles/space";
import ArrowLeftIcon from "@icons/24/ArrowLeft.svg?react";
import ArrowRightIcon from "@icons/24/ArrowRight.svg?react";
import { IconREMSize } from "../../../styles/typography";
import { colors } from "../../../styles/colors";
import Button from "../../General/Button";

const createRange = (start: number, end: number): number[] =>
  Array.from({ length: end - start + 1 }, (_, i) => start + i);

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;

  button.text {
    color: ${colors.primaryText};

    svg {
      path {
        stroke: ${colors.primaryText};
      }
    }
    &:hover {
      color: unset;

      svg {
        path {
          stroke: ${colors.primaryText};
        }
      }
    }
  }
`;

// ChatGPT was here
const Pagination = ({
  currentPage,
  canPreviousPage,
  canNextPage,
  totalPages,
  onPageChange,
  nextPage,
  previousPage,
}: {
  currentPage: number;
  canPreviousPage: boolean;
  canNextPage: boolean;
  totalPages: number;
  onPageChange(pageNr: number): void;
  previousPage(): void;
  nextPage(): void;
}) => {
  const currentPageVisible = currentPage + 1;
  const extraPages = 2;
  let nrPrevPages = Math.min(currentPage, extraPages);
  const nrNextPages = Math.min(
    totalPages - currentPage - 1,
    extraPages * 2 - nrPrevPages,
  );

  if (nrNextPages < nrPrevPages) {
    nrPrevPages += extraPages - nrNextPages;
  }

  const prevPageNumbers = createRange(
    Math.max(currentPage - nrPrevPages, 0),
    currentPage - 1,
  );
  const nextPageNumbers = createRange(
    currentPage + 1,
    Math.min(currentPage + nrNextPages, totalPages - 1),
  );

  return (
    <PaginationWrapper>
      <Button
        buttonType="text"
        onClick={previousPage}
        disabled={!canPreviousPage}
        icon={
          <IconREMSize height={1} width={1}>
            <ArrowLeftIcon />
          </IconREMSize>
        }
        text="Prev"
        style={{
          paddingLeft: spacing6,
          paddingRight: spacing6,
        }}
      />
      {prevPageNumbers.length > 0 && !prevPageNumbers.includes(0) && (
        <Button
          buttonType="text"
          onClick={() => onPageChange(0)}
          text="1"
          style={{
            paddingLeft: spacing6,
            paddingRight: spacing6,
          }}
        />
      )}
      {prevPageNumbers.length > 0 &&
        !prevPageNumbers.includes(0) &&
        !prevPageNumbers.includes(1) && (
          <Button
            text="..."
            buttonType="text"
            disabled={true}
            style={{
              paddingLeft: spacing6,
              paddingRight: spacing6,
            }}
          />
        )}
      {prevPageNumbers.map((page) => (
        <Button
          key={page}
          size="medium"
          buttonType="text"
          onClick={() => onPageChange(page)}
          text={page + 1}
          style={{
            paddingLeft: spacing6,
            paddingRight: spacing6,
          }}
        />
      ))}
      <Button
        buttonType="text"
        disabled={true}
        text={currentPageVisible}
        style={{
          color: colors.secondary,
          backgroundColor: "#02698F",
          paddingLeft: spacing6,
          paddingRight: spacing6,
        }}
      />
      {nextPageNumbers.map((page) => (
        <Button
          key={page}
          buttonType="text"
          onClick={() => onPageChange(page)}
          text={page + 1}
          style={{
            paddingLeft: spacing6,
            paddingRight: spacing6,
          }}
        />
      ))}
      {totalPages - currentPage + 1 > extraPages &&
        !nextPageNumbers.includes(totalPages - 1) &&
        !nextPageNumbers.includes(totalPages - 2) && (
          <Button text="..." buttonType="text" disabled={true} />
        )}

      {nextPageNumbers.length > 0 &&
        !nextPageNumbers.includes(totalPages - 1) && (
          <Button
            buttonType="text"
            onClick={() => onPageChange(totalPages - 1)}
            disabled={!canNextPage}
            text={totalPages}
            style={{
              paddingLeft: spacing6,
              paddingRight: spacing6,
            }}
          />
        )}

      <Button
        buttonType="text"
        onClick={nextPage}
        disabled={!canNextPage}
        text="Next"
        icon={
          <IconREMSize height={1} width={1}>
            <ArrowRightIcon />
          </IconREMSize>
        }
        iconPosition="right"
        style={{
          paddingLeft: spacing6,
          paddingRight: spacing6,
        }}
      />
    </PaginationWrapper>
  );
};

export default Pagination;
