import styled from "styled-components";
import { colors } from "styles/colors";

const defaultSvgColor = "#ffffff";
const defaultSize = 9.1;

export const ContentImageWrapper = styled.div<{
  color?: string;
  svgColor?: string;
  size?: number;
}>`
  width: ${(p) => p.size ?? defaultSize}rem;
  min-width: ${(p) => p.size ?? defaultSize}rem;
  height: ${(p) => p.size ?? defaultSize}rem;
  min-height: ${(p) => p.size ?? defaultSize}rem;
  border-radius: ${(p) => p.size ?? defaultSize}rem;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(p) => p.color ?? colors.focusBackground};
  > svg {
    width: ${(p) => (p.size ?? defaultSize) / 2}rem;
    height: ${(p) => (p.size ?? defaultSize) / 2}rem;
    > path {
      stroke: ${(p) => p.svgColor ?? defaultSvgColor};
    }
    > circle {
      stroke: ${(p) => p.svgColor ?? defaultSvgColor};
    }
    > ellipse {
      fill: ${(p) => p.svgColor ?? defaultSvgColor};
    }
    > line {
      stroke: ${(p) => p.svgColor ?? defaultSvgColor};
    }
    > rect {
      stroke: ${(p) => p.svgColor ?? defaultSvgColor};
      fill: ${(p) => p.svgColor ?? defaultSvgColor};
    }
  }
`;
