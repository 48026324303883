import { SafeCard } from "./Base";
import * as THREE from "three";
import { useRef, useState, useEffect } from "react";
import useResizeThree from "../../ViewToPark/ThreeContext/useResizeThree";
import { ThreeCore } from "../../ViewToPark/ThreeContext/useCreateThreeCore";
import { With } from "../../../utils/utils";
import { allSimpleTurbineTypesSelector } from "../../../state/turbines";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import {
  allFoundationTypesSelector,
  getMostCommonFoundationInPark,
} from "../../../state/foundations";
import { createFoundationModel } from "../../MapFeatures/3Dfoundations";
import { Column, Row } from "../../General/Layout";
import Dropdown from "../../Dropdown/Dropdown";
import { spaceMedium } from "../../../styles/space";
import { Label } from "../../General/Form";
import { loadTurbineModel } from "../../MapFeatures/3Dturbines";
import { MenuItemDiv } from "../../General/Menu";
import styled from "styled-components";
import { colors } from "../../../styles/colors";
import { Slider } from "../../General/Slider";
import { parkIdSelector } from "state/pathParams";

const ThreeDFoundation = () => {
  const ref = useRef<HTMLDivElement | null>(null);

  type State = With<ThreeCore, "camera" | "renderer" | "scene"> & {
    models: (THREE.Mesh | THREE.Group)[];
  };
  const [state, setState] = useState<State | undefined>(undefined);

  const turbineTypes = useRecoilValue(allSimpleTurbineTypesSelector);
  const turbine = turbineTypes[0];
  const allFoundationTypes = useRecoilValue(allFoundationTypesSelector);
  const parkId = useRecoilValue(parkIdSelector) ?? "";
  const mostCommonFoundation = useRecoilValue(
    getMostCommonFoundationInPark({ parkId }),
  );

  const [foundation, setFoundation] = useState(
    mostCommonFoundation ?? allFoundationTypes[0],
  );

  useEffect(() => {
    const nodeRef = ref.current;
    if (!nodeRef) return;

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    const { width, height } = nodeRef.getBoundingClientRect();

    const fov = 45;
    const nearPlane = 0.1;
    const farPlane = 1000;
    const camera = new THREE.PerspectiveCamera(
      fov,
      width / height,
      nearPlane,
      farPlane,
    );
    camera.position.z = 20;

    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xffffff);
    scene.fog = new THREE.Fog(0xffffff, 500, 800);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
    directionalLight.position.set(0, 0, 100);
    scene.add(directionalLight);
    scene.add(new THREE.AmbientLight(0x808080));

    const models: State["models"] = createFoundationModel(turbine, foundation);

    const bladeMaterial = new THREE.MeshLambertMaterial({
      color: 0xffffff,
      side: THREE.DoubleSide,
    });
    const nacelleMaterial = new THREE.MeshLambertMaterial({ color: 0x888888 });
    loadTurbineModel({
      bladeMaterial,
      nacelleMaterial,
      towerMaterial: nacelleMaterial,
      turbineDiameter: turbine.diameter,
    }).then((turbineModels) => {
      for (const m of turbineModels) models.push(m);
      for (const m of models) scene.add(m);

      setState({
        renderer,
        camera,
        scene,
        models,
      });
    });

    // Water
    models.push(
      new THREE.Mesh(
        new THREE.CircleGeometry(900, 32),
        new THREE.MeshBasicMaterial({
          color: 0x023859,
          transparent: true,
          opacity: 0.5,
        }),
      ),
    );

    renderer.setSize(width, height);
    renderer.setDrawingBufferSize(width, height, window.devicePixelRatio);
    nodeRef.replaceChildren(renderer.domElement);

    return () => {
      nodeRef.removeChild(renderer.domElement);
      renderer.dispose();
      models.forEach((o) => {
        if (o instanceof THREE.Mesh) {
          o.geometry.dispose();
          if (o.material instanceof Array) {
            o.material.forEach((m) => m.dispose());
          } else {
            o.material.dispose();
          }
          o.removeFromParent();
          scene.remove(o);
        }
      });
    };
  }, [foundation, turbine]);

  const timer = useRef<number>((Math.PI * 7) / 2);
  const animState = useRecoilValue(animationStateAtom);

  useEffect(() => {
    let stop = false;
    const animateFn = () => {
      if (stop) return;
      window.requestAnimationFrame(animateFn);
      if (!state) return;

      const height = 60;
      const dist = animState.distance;
      const lookatHeight = (50 / 250) * animState.distance;

      if (animState.speed > 0) {
        timer.current += animState.speed;
        const f = timer.current;
        state.camera.position.set(
          Math.sin(f / 3) * dist,
          Math.cos(f / 3) * dist,
          height,
        );
        state.camera.up = new THREE.Vector3(0, 0, 1);
      }
      state.camera.lookAt(new THREE.Vector3(0, 0, lookatHeight));
      state.renderer.render(state.scene, state.camera);
    };
    animateFn();
    return () => {
      stop = true;
    };
  }, [state, animState]);

  useResizeThree(state, ref);

  return (
    <Column style={{ height: "100%" }}>
      <Row style={{ padding: spaceMedium }}>
        <Label left>
          <p>Foundation type</p>
          <Dropdown
            value={foundation.id}
            onChange={(e) => {
              setFoundation(
                allFoundationTypes.find((f) => f.id === e.target.value)!,
              );
            }}
          >
            {allFoundationTypes.map((f) => (
              <option key={f.id} value={f.id}>
                {f.name}
              </option>
            ))}
          </Dropdown>
        </Label>
      </Row>
      <div
        style={{ height: "100%", width: "100%", overflow: "hidden" }}
        ref={ref}
      />
    </Column>
  );
};

type AnimationState = {
  speed: number;
  distance: number;
};

const animationStateAtom = atom<AnimationState>({
  key: "animationStateAtom",
  default: {
    speed: 0.016,
    distance: 250.0,
  },
});

const ThreeDFoundationMenuItems = () => {
  const [state, setState] = useRecoilState(animationStateAtom);
  return (
    <>
      <MenuItemDiv nohover>
        <RestMenu>
          <Label left>
            <p>Rotation speed</p>
            <Slider
              min={0}
              max={0.1}
              step={0.001}
              onChange={(e) => {
                setState((c) => ({
                  ...c,
                  speed: e,
                }));
              }}
              value={state.speed}
            />
          </Label>
          <Label left>
            <p>Camera distance</p>
            <Slider
              min={100}
              max={400}
              step={10}
              onChange={(e) => {
                setState((c) => ({
                  ...c,
                  distance: e,
                }));
              }}
              value={state.distance}
            />
          </Label>
        </RestMenu>
      </MenuItemDiv>
    </>
  );
};

const RestMenu = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${spaceMedium};
  gap: ${spaceMedium};

  width: 20rem;

  .divider {
    border-bottom: 1px solid ${colors.inputOutline};
    height: 1px;
    flex: 1;
  }
`;

export const ThreeDFoundationWidget = () => (
  <SafeCard
    title="3D Foundation"
    id="3D-foundation"
    menuItems={<ThreeDFoundationMenuItems />}
  >
    <ThreeDFoundation />
  </SafeCard>
);
