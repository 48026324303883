import { useCallback, useMemo } from "react";
import { Types } from "ably";
import { _CostConfiguration } from "../services/costService";
import { ABLY_COST_CONFIG_UPDATE } from "../state/ably";
import useCostConfigurationCrud from "./useCostConfigurationCrud";
import { useAblyGeneric } from "./useAblyGeneric";

const useAblyCostConfiguration = (projectId: string) => {
  const { updateLocal, deleteLocal } = useCostConfigurationCrud();

  const channelName = useMemo(
    () => projectId && `${projectId}:all`,
    [projectId],
  );

  const onMessageReceived = useCallback(
    (message: Types.Message) => {
      const { isArchived } = message.data;

      if (isArchived) {
        deleteLocal(message.data.id);
        return;
      }

      const configuration = _CostConfiguration.parse(
        message.data.configuration,
      );
      updateLocal(configuration);
    },
    [deleteLocal, updateLocal],
  );

  const events = useMemo(
    () => [{ eventName: ABLY_COST_CONFIG_UPDATE, onMessageReceived }],
    [onMessageReceived],
  );

  useAblyGeneric(channelName, events);

  return null;
};

export default useAblyCostConfiguration;
