import styled, { CSSObject } from "styled-components";
import { dedup, fastMax, listCompare } from "../../utils/utils";
import { ReactElement, ReactNode } from "react";
import { colors } from "../../styles/colors";
import { spaceLarge, spaceSmall } from "../../styles/space";
import { RegularRaw, typography } from "../../styles/typography";
import groupBy from "../../utils/groupBy";
import React from "react";
import { sendInfo } from "../../utils/sentry";

type K = string | number;
/**
 * The "shape" of data to a {@link Table}.  The three generic parameters are:
 * 1. The type of the element to render in the table.
 * 2. The type of the column key (default `string`).
 * 3. The type of the row key (default `number`).
 *
 * Usually you would want to create a new type for a union of these types, and
 * use an array of that type as the `items` prop to the {@link Table}, like
 * this:
 * ```ts
 * type Shape =
 *  | ItemShape<string, "name">
 *  | ItemShape<number, "age">
 *  | ItemShape<string, "country">;
 * ```
 * Now we get correct type inference in the `render` prop of {@link Table}.
 */
export type ItemShape<E = any, C extends K = string, R extends K = number> = {
  row: R;
  col: C;
  ele: E;
  grp?: C[];
};

/**
 * Construct a list of {@link ItemShape}s from a list of objects.
 * Each object will correspond to a row of items, and each field in the objects
 * will be a column.
 */
export const fromObjects = <T extends Record<string, ReactNode>>(
  obj: T[],
): ItemShape<ReactNode>[] => {
  return obj.flatMap((item, i) =>
    Object.entries(item).map(([key, value]) => ({
      ele: value,
      row: i,
      col: key,
    })),
  );
};

export type TableCSS = {
  td?: CSSObject;
  tr?: CSSObject;
  th?: CSSObject;
  table?: CSSObject;
  thead?: CSSObject;
  tbody?: CSSObject;
  tfoot?: CSSObject;
  noalternate?: boolean;
  nohover?: boolean;
};

export const TableDiv = styled.table<TableCSS>`
  border-collapse: collapse;
  border: none;
  margin: 0;
  overflow-y: auto;
  ${typography.contentAndButtons}
  ${RegularRaw}

  tbody tr:nth-child(odd) {
    ${(p) => !p.noalternate && ` background: ${colors.surfaceHover}80;`}
  }

  tbody > tr:hover {
    ${(p) => !p.nohover && ` background: ${colors.selected};`}
  }

  td {
    text-align: center;
    padding: ${spaceSmall} ${spaceLarge};
  }

  th {
    padding: ${spaceSmall} ${spaceLarge};
  }

  thead > tr:nth-child(1) {
    font-weight: 600;
  }
  thead > tr:nth-child(even) {
  }

  thead {
    border-bottom: 1px solid ${colors.primaryDisabled};
  }

  tfoot {
    border-top: 1px solid ${colors.primaryDisabled};
  }

  thead > tr:nth-last-child(1) {
    font-size: 1.3rem;
  }
  thead > tr:nth-last-child(2) {
    font-size: 1.5rem;
  }
  thead > tr:nth-last-child(3) {
    font-size: 1.7rem;
  }

  tbody {
    ${(props) => ({ ...props.tbody })}
  }
  thead {
    ${(props) => ({ ...props.thead })}
  }
  tfoot {
    ${(props) => ({ ...props.tfoot })}
  }
  th {
    ${(props) => ({ ...props.th })}
  }
  td {
    ${(props) => ({ ...props.td })}
  }
  tr {
    transition: line-height 0.3s;
    ${(props) => ({ ...props.tr })}
  }
  ${(props) => ({ ...props.table })}
`;

function keyCompare<KK extends K>(a: KK, b: KK): number {
  if (typeof a === "string" && typeof b === "string") return a.localeCompare(b);
  if (typeof a === "number" && typeof b === "number") return a - b;
  if (typeof a === "string") return -1;
  if (typeof b === "string") return 1;
  return 0;
}

type Renderable = string | number | boolean | ReactElement;
/** Check if React can render this type.  Kinda dumb. */
function canRender(t: unknown): t is Renderable {
  return (
    typeof t === "string" ||
    typeof t === "number" ||
    typeof t === "boolean" ||
    React.isValidElement(t)
  );
}

export const Table = <E, I extends ItemShape<E>>({
  rows,
  columns,
  items,
  render,
  renderColumn,
  renderRow,
  hideRows,
  style,
  footers,
  rowLabel,
  rowClassName,
}: {
  items: I[];
  rows?: I["row"][];
  columns?: (I["col"] | I["col"][])[];
  rowLabel?: string;
  render?: (item?: I) => ReactNode;
  /**
   * Render function for the column cells of the table.
   * @param key The key for this cell
   * @param fullKey The full key for this cell, including all parent keys.
   */
  renderColumn?: (key: I["col"] | undefined, fullKey: I["col"][]) => ReactNode;
  renderRow?: (item: I["row"]) => ReactNode;
  hideRows?: boolean;
  style?: TableCSS;
  footers?: {
    name: string;
    render: (items: I[]) => ReactNode;
  }[];
  /** Inject class names for `tr`s. This function is given the row key and should return the string passed to `className`.  */
  rowClassName?: (row: I["row"]) => string | undefined;
}) => {
  const toArray = <T,>(t: T | T[]): T[] => (Array.isArray(t) ? t : [t]);

  const columnKey = (c: I["col"], g?: I["col"][]) =>
    `${c}-${g?.join(".") ?? ""}`;

  const _rows: I["row"][] =
    rows ?? dedup(items.map(({ row }) => row)).sort(keyCompare);
  const _columnGroups: I["col"][][] = columns
    ? columns.map(toArray)
    : dedup(
        items.map(({ col, grp }) => [...(grp ?? []), col]),
        (c) => columnKey(c.at(-1)!, c.slice(0, -1)),
      ).sort(listCompare(keyCompare));

  const maxHeadDepth = fastMax(
    _columnGroups.map((col) => (Array.isArray(col) ? col.length : 1)),
  );

  // Group up the headers, for multi-level columns. I.e. if we have columns
  // [a, x] and [a, y] we want to show this as a being a column that's 2 wide,
  // and below it we want two 1-wide columns x and y.  Here, a `Group` is a name
  // ('a', or 'x' or whatever), as well as its `span`, its size.  fullName is the
  // list of keys above it, and only makes sense for groups that have a name.
  type Group = {
    name?: I["col"];
    span: number;
    fullName: I["col"][];
  };

  const headerRows: Group[][] = [];
  for (let level = 0; level < maxHeadDepth; level++) {
    let group: Group = {
      name: _columnGroups.at(0)?.[level],
      span: 0,
      fullName: _columnGroups.at(0)?.slice(0, level + 1) ?? [],
    };

    const levelGroups: Group[] = [];
    for (let colI = 0; colI < _columnGroups.length; colI++) {
      const col = _columnGroups[colI];
      const key = col[level];
      if (!key && group.name) {
        // Gap in the keys.  Push the last group, make a new empty group.
        levelGroups.push(group);
        group = {
          name: undefined,
          span: 1,
          fullName: col.slice(0, level + 1),
        };
      } else if (key === group.name) {
        // Same group as before.
        group.span++;
      } else {
        // new group
        levelGroups.push(group);
        group = {
          name: key,
          span: 1,
          fullName: col.slice(0, level + 1),
        };
      }
    }

    levelGroups.push(group);
    headerRows.push(levelGroups);
  }

  const map: Record<string, I> = Object.fromEntries(
    items.map((o) => [`${o.row}-${columnKey(o.col, o.grp)}`, o]),
  );
  const getItem = (row: I["row"], column: I["col"], grp?: I["col"][]): I =>
    map[`${row}-${columnKey(column, grp)}`];

  return (
    <TableDiv {...style}>
      <thead>
        {headerRows.map((hr, i) => {
          const showRows = !hideRows;
          const lastRow = i === headerRows.length - 1;

          const children = hr.map(({ name, span, fullName }, i) => (
            <th id="table-header" scope="col" key={i} colSpan={span}>
              {renderColumn?.(name, fullName) ?? name}
            </th>
          ));

          // Row label should be at the last row.
          if (showRows && lastRow) {
            return (
              <tr key={i}>
                <th scope="col">{rowLabel}</th>
                {children}
              </tr>
            );
          }
          if (showRows) {
            return (
              <tr key={i}>
                <th />
                {children}
              </tr>
            );
          }
          return <tr key={i}>{children}</tr>;
        })}
      </thead>
      <tbody>
        {_rows.map((r, ri) => {
          return (
            <tr key={r} className={rowClassName?.(r)}>
              {!hideRows && <th scope="row">{renderRow ? renderRow(r) : r}</th>}

              {_columnGroups.map((c, ci) => {
                const key = `${ri}-${ci}`;
                const item = getItem(r, c.at(-1)!, c.slice(0, -1));
                const e = render ? render(item) : item?.ele;
                if (e == null) return <td key={key} />;
                if (!canRender(e)) {
                  // We don't know enough TS-fu to have this typechecked.
                  // Ideally `T` would be unconstrained as long as `render` is
                  // passed in, and if render is not passed in, then `T` would
                  // be constrained to `ReactNode`.  This would ensure that in
                  // either case we could produce a `ReactNode` here.

                  // `throw` an error here, since this is a programming error,
                  // for instance by having an object as the item, without
                  // specifying how the object should be rendered.
                  sendInfo("Tried to render an invalid element", { e });
                  return null;
                }
                return <td key={key}>{e}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        {footers?.map((f) => {
          const groups = groupBy(items, ({ col, grp }) => columnKey(col, grp));

          return (
            <tr key={f.name}>
              <th scope="row">{f.name}</th>
              {_columnGroups.map((c) => {
                const key = columnKey(c.at(-1)!, c.slice(0, -1));
                const items = groups[key];
                return <td key={key}>{f.render(items)}</td>;
              })}
            </tr>
          );
        })}
      </tfoot>
    </TableDiv>
  );
};
