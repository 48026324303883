import { useCallback, useMemo } from "react";
import { useSetRecoilState } from "recoil";
import { ABLY_PROJECT_ELEMENTS } from "../state/ably";
import { useProjectElementsCrud } from "../components/ProjectElements/useProjectElementsCrud";
import { Types } from "ably";
import { _DataSource } from "../state/dataSource";
import { toastMessagesAtom } from "../state/toast";
import { undefMap } from "../utils/utils";
import { scream } from "../utils/sentry";
import { useAblyGeneric } from "./useAblyGeneric";
import {
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "../components/ErrorBoundaries/ErrorBoundaryLocal";
import { checkAllDoParse } from "../utils/geojson/validate";
import { z } from "zod";
import { _FeatureParser } from "../types/feature";
import * as Sentry from "@sentry/react";

const AblyProjectElements = ErrorBoundaryWrapper(
  ({ projectId }: { projectId: string }) => {
    const { localUpdate } = useProjectElementsCrud();
    const setToastMessagesAtom = useSetRecoilState(toastMessagesAtom);

    const channelName = useMemo(() => `${projectId}:all`, [projectId]);

    const onMessageReceived = useCallback(
      (message: Types.Message) => {
        if (message.data.type === "error") {
          const errorMessage =
            message.data?.error?.message ??
            "Failing to sync project features, refresh the page before continuing";

          setToastMessagesAtom((cur) => [
            ...cur,
            {
              type: "error",
              text: errorMessage,
              timeout: 10000,
            },
          ]);
          scream(
            `Failing to sync project features on channel: ${channelName}. with message: ${errorMessage}`,
          );
          return;
        }
        const ds = _DataSource.parse(message.data.dataSource);

        const { add, remove, update } = message.data;

        const toAdd = undefMap(add, (arr) =>
          checkAllDoParse(arr, _FeatureParser),
        );
        const toRemove = undefMap(remove, (arr) =>
          checkAllDoParse(arr, z.string()),
        );
        const toUpdate = undefMap(update, (arr) =>
          checkAllDoParse(arr, _FeatureParser),
        );

        Sentry.addBreadcrumb({
          category: "ably",
          message: "Received project features update message",
          data: {
            nrToAdd: toAdd?.length,
            nrToRemove: toRemove?.length,
            nrToUpdate: toUpdate?.length,
          },
        });

        localUpdate(ds, { add: toAdd, remove: toRemove, update: toUpdate });
      },
      [channelName, localUpdate, setToastMessagesAtom],
    );

    const events = useMemo(
      () => [{ eventName: ABLY_PROJECT_ELEMENTS, onMessageReceived }],
      [onMessageReceived],
    );

    useAblyGeneric(channelName, events);

    return null;
  },
  FatalErrorBoundaryWrapper,
  ScreamOnError,
);

export default AblyProjectElements;
