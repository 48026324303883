import { libraryCableRefreshAtom } from "components/Cabling/Generate/state";
import {
  addOrUpdateCableResourceOnNode,
  deleteCableResourceOnNode,
} from "components/Organisation/Library/service";
import { nodesWithAccessToCable } from "components/Organisation/Library/state";
import { useRecoilCallback } from "recoil";
import { getCableResourcesOnNode } from "services/libraryService";
import { cableTypeUsageAtomFamily } from "state/cable";
import { cableResourceWithAccessOnNodeState } from "state/cableType";
import { useTypedPath } from "state/pathParams";

const useNodeCableResourcesCrud = () => {
  const { organisationId } = useTypedPath("organisationId");

  const addOrUpdate = useRecoilCallback(
    ({ snapshot, set, refresh }) =>
      async (nodeId: string, cableId: string) => {
        const fallback = await snapshot.getPromise(
          cableResourceWithAccessOnNodeState({ nodeId }),
        );

        return addOrUpdateCableResourceOnNode(nodeId, cableId)
          .then(async () => {
            const updatedCables = await getCableResourcesOnNode(nodeId);
            set(cableResourceWithAccessOnNodeState({ nodeId }), updatedCables);
            refresh(
              nodesWithAccessToCable({
                organisationId,
                resourceId: cableId,
              }),
            );
            set(libraryCableRefreshAtom, (cur) => cur + 1);
          })
          .catch((e) => {
            set(cableResourceWithAccessOnNodeState({ nodeId }), fallback);
            throw e;
          });
      },
    [organisationId],
  );

  const remove = useRecoilCallback(
    ({ snapshot, set, refresh }) =>
      async (nodeId: string, resourceId: string) => {
        const fallback = await snapshot.getPromise(
          cableResourceWithAccessOnNodeState({ nodeId }),
        );
        const usage = await snapshot.getPromise(
          cableTypeUsageAtomFamily({
            nodeId,
            cableTypeId: resourceId,
          }),
        );
        if (
          usage.length === 0 ||
          window.confirm(
            `Cable type is currently being used in ${usage.length} cables, are you sure you want to remove access to it?`,
          )
        ) {
          return deleteCableResourceOnNode(nodeId, resourceId)
            .then(() => {
              set(cableResourceWithAccessOnNodeState({ nodeId }), (cur) =>
                cur.filter((c) => c.cable.id !== resourceId),
              );
              refresh(nodesWithAccessToCable({ organisationId, resourceId }));
              set(libraryCableRefreshAtom, (cur) => cur + 1);
            })
            .catch((e) => {
              set(cableResourceWithAccessOnNodeState({ nodeId }), fallback);
              throw e;
            });
        }
      },
    [organisationId],
  );

  return { addOrUpdate, remove };
};

export default useNodeCableResourcesCrud;
