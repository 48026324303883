import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { projectIdSelector } from "state/pathParams";
import TopbarNavigation from "components/Design/TopbarNavigation/TopbarNavigation";
import BranchTabBar from "components/Design/BranchTabBar/BranchTabBar";
import TimelineModals from "./TimelineModals";
import ValidationWarnings from "components/ValidationWarnings/ValidationWarnings";

export default function DesignNavigation() {
  const projectId = useRecoilValue(projectIdSelector);
  if (!projectId) return null;
  return (
    <>
      <Suspense fallback={null}>
        <TimelineModals nodeId={projectId} />
      </Suspense>
      <TopbarNavigation />
      <BranchTabBar>
        <Suspense fallback={null}>
          <ValidationWarnings />
        </Suspense>
      </BranchTabBar>

      {/*<NavigationContainer>
        <Suspense fallback={null}>
          <AppNavigation />

          <ReadOnly />
        </Suspense>

      </NavigationContainer>*/}
    </>
  );
}
