/// <reference types="vite-plugin-svgr/client" />
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";
import FoundationIcon from "@icons/24/Foundation.svg?react";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";
import Earth from "@icons/14/Earth.svg?react";
import Library from "@icons/24/Library.svg?react";
import {
  createNewProjectFoundation,
  deleteProjectFoundation,
  getCustomProjectFoundation,
  updateProjectFoundation,
} from "../../../../services/turbineAPIService";
import DeleteIcon from "@icons/24/Bin.svg?react";
import AddIcon from "@icons/24/Add.svg?react";

import {
  defaultFoundations,
  fetchFoundationTypeUsage,
  foundationTypeUsageAtomFamily,
  showNewFoundationWizardAtom,
  projectFoundationTypesSelectorFamily,
  newFoundationNodeId,
} from "../../../../state/foundations";
import { useTypedPath } from "../../../../state/pathParams";
import {
  DEFAULT_MONOPILES,
  FoundationType,
} from "../../../../types/foundations";
import { scream } from "../../../../utils/sentry";
import { initializeAndSet } from "../../../Comments/hooks/useReplyReactionCrud";
import FoundationSettings from "../../../ConfigurationModal/FoundationSettings";
import { selectedMenuItemState } from "../../Shared/state";
import { toastMessagesAtom } from "../../../../state/toast";
import {
  editorAccessCustomerSelector,
  orgFoundationManageAccessSelector,
} from "state/user";
import {
  useRefreshLibraryFoundation,
  useRefreshProjectFoundation,
} from "components/ConfigurationModal/useRefreshCustomFoundations";
import {
  foundationResourceWithAccessOnNodeState,
  libraryTabState,
} from "components/Organisation/Library/state";
import { SettingsSubMenuProp } from "components/SettingsV2/Shared/types";
import useNodeFoundationResourcesCrud from "components/Organisation/OrganisationRightSide/content/NodeContent/useNodeFoundationResourcesCrud";
import Button from "components/General/Button";
import FoundationModal from "components/Organisation/OrganisationRightSide/content/ResourceContent/FoundationModal";
import { Anchor } from "components/General/Anchor";
import { useToast } from "hooks/useToast";
import useOrgFoundationCrud from "components/Organisation/Library/foundation/useOrgFoundationCrud";
import { IconREMSize } from "styles/typography";
import { selectedOrgTabState } from "components/Organisation/state";
import { useNavigate, useLocation } from "react-router-dom";
import { useDuplicateFoundationToProject } from "./useDuplicateToProject";

export const FOUNDATION_MENU_ID = "foundations";

export default function useFoundationSettings() {
  const { organisationId, projectId } = useTypedPath(
    "organisationId",
    "projectId",
  );

  const navigate = useNavigate();
  const location = useLocation();
  const setOrgtab = useSetRecoilState(selectedOrgTabState);
  const setSelectedTab = useSetRecoilState(libraryTabState({ organisationId }));

  const orgFoundationCrud = useOrgFoundationCrud();
  const editorAccessProject = useRecoilValue(editorAccessCustomerSelector);
  const orgFoundationManageAccess = useRecoilValue(
    orgFoundationManageAccessSelector,
  );
  const { error } = useToast();
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [saveInProgressLibrary, setSaveInProgressLibrary] =
    useState<boolean>(false);

  const setToastMessages = useSetRecoilState(toastMessagesAtom);

  const projectFoundations = useRecoilValueLoadable(
    projectFoundationTypesSelectorFamily({ nodeId: projectId }),
  );

  const libraryFoundations = useRecoilValueLoadable(
    foundationResourceWithAccessOnNodeState({
      nodeId: projectId,
    }),
  );

  const [menuSelection, setMenuSelection] = useRecoilState(
    selectedMenuItemState({ menuId: FOUNDATION_MENU_ID, projectId }),
  );

  const setShowNewFoundationWizard = useSetRecoilState(
    showNewFoundationWizardAtom,
  );

  const setNewFoundationNodeId = useSetRecoilState(newFoundationNodeId);

  const availableProjectFoundations = useMemo(() => {
    if (projectFoundations.state !== "hasValue" || !projectFoundations.contents)
      return defaultFoundations;
    return [...defaultFoundations, ...projectFoundations.contents].filter(
      (t) => !t.archived,
    );
  }, [projectFoundations]);

  const availableLibraryFoundations = useMemo(() => {
    if (libraryFoundations.state !== "hasValue" || !libraryFoundations.contents)
      return [];
    return libraryFoundations.contents.filter((t) => !t.foundation.archived);
  }, [libraryFoundations]);

  useEffect(() => {
    if (!menuSelection) {
      const firstItem = availableProjectFoundations[0];
      if (firstItem) {
        setMenuSelection(firstItem.id);
      }
    }
  }, [availableProjectFoundations, menuSelection, setMenuSelection]);

  const refreshProjectFoundations = useRefreshProjectFoundation();
  const refreshLibraryFoundations = useRefreshLibraryFoundation();

  const { duplicateToProject } = useDuplicateFoundationToProject();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const _onSave = useRecoilCallback(
    ({ set, snapshot }) =>
      async (foundation: FoundationType, nodeId: string | undefined) => {
        if (!nodeId) return;
        setIsLoading(true);
        const usage = await fetchFoundationTypeUsage(nodeId, foundation.id);
        initializeAndSet(
          snapshot,
          set,
          foundationTypeUsageAtomFamily({
            nodeId,
            foundationId: foundation.id,
          }),
          usage,
        );
        if (
          usage.length === 0 ||
          window.confirm(
            `Foundation type is currently being used in ${usage.length} foundations, are you sure you want to update it?`,
          )
        ) {
          updateProjectFoundation(nodeId, foundation).then(() => {
            refreshProjectFoundations();
            refreshLibraryFoundations();
            setIsLoading(false);
            setToastMessages((tm) => [
              ...tm,
              {
                text: "Saved",
                timeout: 3000,
                type: "success",
              },
            ]);
          });
        } else {
          setIsLoading(false);
        }
      },
    [refreshLibraryFoundations, refreshProjectFoundations, setToastMessages],
  );

  const _onDelete = useRecoilCallback(
    ({ set, snapshot }) =>
      async (foundationId: string, nodeId: string | undefined) => {
        if (!nodeId) return;
        setIsLoading(true);
        const usage = await fetchFoundationTypeUsage(nodeId, foundationId);
        initializeAndSet(
          snapshot,
          set,
          foundationTypeUsageAtomFamily({
            nodeId,
            foundationId,
          }),
          usage,
        );
        if (
          usage.length === 0 ||
          window.confirm(
            `Foundation type is currently being used in ${usage.length} foundations, are you sure you want to delete it?`,
          )
        ) {
          deleteProjectFoundation(nodeId, foundationId).then(() => {
            refreshProjectFoundations();
            refreshLibraryFoundations();
            setIsLoading(false);
            setMenuSelection(undefined);
          });
        } else {
          setIsLoading(false);
        }
      },
    [refreshLibraryFoundations, refreshProjectFoundations, setMenuSelection],
  );

  const _onDuplicateProjectLevel = useCallback(
    async (foundationId: string, nodeId: string | undefined) => {
      if (!nodeId) return;
      setSaveInProgress(true);
      setIsLoading(true);
      const foundation =
        availableProjectFoundations.find(
          (foundation) => foundation.id === foundationId,
        ) ?? (await getCustomProjectFoundation(nodeId, foundationId));
      if (!foundation) return;

      const newFoundation = {
        ...foundation,
        name: `${foundation.name} copy`,
      };
      createNewProjectFoundation(nodeId, newFoundation)
        .then((res) => {
          refreshProjectFoundations();
          setSaveInProgress(false);
          setIsLoading(false);
          setMenuSelection(res?.id);
        })
        .catch((e) => {
          scream("Failed to create new foundation", e);
          setIsLoading(false);
          throw Error("Could not create a foundation with the selected input.");
        });
    },
    [availableProjectFoundations, refreshProjectFoundations, setMenuSelection],
  );

  const _onDuplicateToLibrary = useRecoilCallback(
    ({ set, snapshot }) =>
      async (nodeId: string, foundation: FoundationType) => {
        setSaveInProgressLibrary(true);
        setIsLoading(true);
        try {
          const newFoundation = await orgFoundationCrud.duplicate({
            foundation,
            name: `${foundation.name} (duplicate)`,
            projectAccess: [projectId],
          });
          initializeAndSet(
            snapshot,
            set,
            foundationResourceWithAccessOnNodeState({
              nodeId: nodeId,
            }),
            (curr) => [
              ...curr,
              { nodeId, foundation: { ...foundation, id: newFoundation.id } },
            ],
          );
        } catch (e) {
          scream("Error when duplicating project foundation to library", { e });
          error(
            "Something went wrong while trying to duplicate project foundation to library, we have been notified",
          );
        } finally {
          setSaveInProgressLibrary(false);
          setIsLoading(false);
        }
      },
    [setIsLoading, error, orgFoundationCrud, projectId],
  );

  const foundationSubMenus = useMemo(() => {
    const libraryFoundationList = availableLibraryFoundations.map(
      (foundationAccess) => {
        const isDefaultFoundation = defaultFoundations.some(
          (defaultFoundation) =>
            defaultFoundation.id === foundationAccess.foundation.id,
        );

        let dotMenuOptions:
          | {
              title: string;
              onSelect: (id: string) => void;
              icon: ReactElement;
            }[]
          | undefined = [];

        if (orgFoundationManageAccess) {
          dotMenuOptions.push({
            title: "Edit in library",
            onSelect: () => {
              setOrgtab("Library");
              setSelectedTab("foundation");
              navigate(`/organisation/${organisationId}${location.search}`);
            },
            icon: <Library />,
          });
          if (editorAccessProject) {
            dotMenuOptions.push({
              title: "Duplicate to project",
              onSelect: () => {
                setSaveInProgress(true);
                duplicateToProject(foundationAccess.foundation).then(() => {
                  setSaveInProgress(false);
                });
              },
              icon: <DuplicateIcon />,
            });
          }
        }

        if (dotMenuOptions.length === 0) dotMenuOptions = undefined;

        return {
          id: foundationAccess.foundation.id,
          label: foundationAccess.foundation.name,
          loading: isLoading,
          content: (
            <div style={{ height: "100%", position: "relative" }}>
              <FoundationSettings
                foundation={foundationAccess.foundation}
                isDefault={isDefaultFoundation}
                onSave={() => {}}
                duplicate={() => {}}
                nodeId={organisationId}
                hasEditAccess={orgFoundationManageAccess}
                isLibraryFoundation={true}
              />
            </div>
          ),
        };
      },
    );

    const projectFoundationList = availableProjectFoundations.map(
      (foundation) => {
        const isDefaultFoundation = defaultFoundations.some(
          (defaultFoundation) => defaultFoundation.id === foundation.id,
        );
        const isSiteSpecificMonopile = DEFAULT_MONOPILES.some(
          (defaultMonopile) => defaultMonopile.id === foundation.id,
        );

        let dotMenuOptions:
          | {
              title: string;
              onSelect: (id: string) => void;
              icon: ReactElement;
            }[]
          | undefined = [];

        if (editorAccessProject && !isDefaultFoundation) {
          dotMenuOptions.push({
            title: "Delete",
            onSelect: (foundationId: string) =>
              _onDelete(foundationId, projectId),
            icon: <DeleteIcon />,
          });
        }

        if (editorAccessProject) {
          dotMenuOptions.push({
            title: "Duplicate",
            onSelect: () => _onDuplicateProjectLevel(foundation.id, projectId),
            icon: <DuplicateIcon />,
          });
          if (orgFoundationManageAccess) {
            dotMenuOptions.push({
              title: "Duplicate to library",
              onSelect: () => _onDuplicateToLibrary(projectId, foundation),
              icon: <DuplicateIcon />,
            });
          }
        }

        if (dotMenuOptions.length === 0) dotMenuOptions = undefined;

        return {
          id: foundation.id,
          label: `${foundation.name} ${
            isDefaultFoundation ? "(standard)" : ""
          }`,
          loading: isLoading,
          onDuplicate:
            editorAccessProject && !isSiteSpecificMonopile
              ? (foundationId: string) =>
                  _onDuplicateProjectLevel(foundationId, projectId)
              : undefined,
          onChangeName:
            editorAccessProject && !isDefaultFoundation
              ? (newName: string) => {
                  return _onSave({ ...foundation, name: newName }, projectId);
                }
              : undefined,
          content: (
            <div style={{ height: "100%", position: "relative" }}>
              <FoundationSettings
                foundation={foundation}
                isDefault={isDefaultFoundation}
                onSave={(foundation: FoundationType) =>
                  _onSave(foundation, projectId)
                }
                duplicate={(foundationId: string) =>
                  _onDuplicateProjectLevel(foundationId, projectId)
                }
                nodeId={projectId}
                hasEditAccess={editorAccessProject}
              />
            </div>
          ),
          dotMenuOptions,
        };
      },
    );

    const libraryFoundationMenu: SettingsSubMenuProp = {
      title: "Library foundations",
      items: libraryFoundationList,
      create: {
        type: "element",
        saveInProgress: saveInProgressLibrary,
        element: <AddFoundationResourceToNode nodeId={projectId} />,
      },
      icon: (
        <IconREMSize height={1.4} width={1.4}>
          <Library />
        </IconREMSize>
      ),
    };

    const projectFoundationMenu: SettingsSubMenuProp = {
      title: "Project foundations",
      items: projectFoundationList,
      icon: (
        <IconREMSize height={1.4} width={1.4}>
          <Earth />
        </IconREMSize>
      ),
      create: {
        type: "action",
        title: "New project foundation",
        disabled: !editorAccessProject,
        saveInProgress: saveInProgress,
        onCreate: () => {
          setNewFoundationNodeId(projectId);
          setShowNewFoundationWizard(true);
        },
      },
    };
    return [libraryFoundationMenu, projectFoundationMenu];
  }, [
    setSelectedTab,
    availableLibraryFoundations,
    availableProjectFoundations,
    orgFoundationManageAccess,
    projectId,
    editorAccessProject,
    isLoading,
    _onDelete,
    _onDuplicateProjectLevel,
    _onSave,
    organisationId,
    setOrgtab,
    navigate,
    location.search,
    duplicateToProject,
    _onDuplicateToLibrary,
    setNewFoundationNodeId,
    setShowNewFoundationWizard,
    saveInProgress,
    saveInProgressLibrary,
  ]);

  const foundationSettings = useMemo(() => {
    return {
      id: FOUNDATION_MENU_ID,
      label: "Foundations",
      icon: <FoundationIcon />,
      submenus: foundationSubMenus,
    };
  }, [foundationSubMenus]);

  return foundationSettings;
}

function AddFoundationResourceToNode({ nodeId }: { nodeId: string }) {
  const foundationResources = useRecoilValue(
    foundationResourceWithAccessOnNodeState({ nodeId }),
  );
  const ref = useRef<HTMLDivElement>(null);
  const { addOrUpdate: addOrUpdateFoundation } =
    useNodeFoundationResourcesCrud();
  const [showAddFoundation, setShowAddFoundation] = useState(false);
  const orgFoundationManageAccess = useRecoilValue(
    orgFoundationManageAccessSelector,
  );
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);

  return (
    <div ref={ref} style={{ position: "relative", marginLeft: "auto" }}>
      <Button
        buttonType="secondary"
        onClick={() => setShowAddFoundation(true)}
        icon={<AddIcon />}
        disabled={!orgFoundationManageAccess}
        size="small"
      />
      {showAddFoundation && (
        <Anchor baseRef={ref} basePlace={"topRight"} floatPlace={"topLeft"}>
          <FoundationModal
            onSave={async (foundations) => {
              setSaveInProgress(true);
              await Promise.all(
                foundations.map((f) => addOrUpdateFoundation(nodeId, f)),
              );
              setSaveInProgress(false);
              setShowAddFoundation(false);
            }}
            existingFoundations={foundationResources.map(
              (t) => t.foundation.id,
            )}
            isSaving={saveInProgress}
          />
        </Anchor>
      )}
    </div>
  );
}
