import { z } from "zod";
import { fetchEnhancerWithToken } from "./utils";

export const _WeibullParameters = z.object({
  A: z.number(),
  k: z.number(),
});
const _WindStatsResult = z.object({
  mostProbableDirection: z.number(),
});
export type WeibullParameters = z.infer<typeof _WeibullParameters>;
export type WindStatsResult = z.infer<typeof _WindStatsResult>;

export const getWeibullTile = async (
  x: number,
  y: number,
  z: number,
  height: number,
) =>
  fetchEnhancerWithToken(`/tiles/gwa/speed/${height}/${z}/${x}/${y}.png`, {
    method: "get",
    headers: {},
  });
