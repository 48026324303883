/// <reference types="vite-plugin-svgr/client" />
import * as turf from "@turf/turf";
import { useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { projectIdSelector } from "../../../state/pathParams";
import { ParkFeature } from "../../../types/feature";
import { ColoredGrid } from "../../General/Form";
import { isMooringLineMultiple } from "../../../utils/predicates";
import { getSelectedArea } from "../../../state/division";
import { previewMooringAndFoundationState } from "../../GenerateFoundationsAndAnchors/state";
import { Row } from "./InfoModal.style";
import { Tag } from "../../GenerateWindTurbines/Tags";
import { DashboardModalType, modalTypeOpenAtom } from "../../../state/modal";
import Dashboard from "@icons/24/Dashboard.svg?react";
import { ButtonWrapper } from "./style";
import Button from "../../General/Button";
import { sum } from "../../../utils/utils";
import { MAX_BUFFER_DISTANCE_RASTER_ANCHOR } from "services/mooringLineTypeService";
import { Column } from "components/General/Layout";
import { SkeletonText, ifLoader } from "components/Loading/Skeleton";
import { useBathymetryRaster } from "hooks/bathymetry";
import AnchorDistribution from "./AnchorDistribution";
import { getSelectedAnchors, getSelectedMooringLines } from "state/mooring";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import {
  SubtitleWithLine,
  ResultValue,
} from "components/General/GeneralSideModals.style";
import { SubAreaTag } from "components/SiteLocator/Tags";

const Inner = ({
  projectId,
  park,
}: {
  projectId: string;
  park: ParkFeature;
}) => {
  const setModalType = useSetRecoilState(modalTypeOpenAtom);

  const previewMooringAndFoundations = useRecoilValue(
    previewMooringAndFoundationState,
  );

  const areaFeatures = useRecoilValue(getSelectedArea) ?? park;
  const selectedArea = useMemo(() => {
    const area = Array.isArray(areaFeatures)
      ? sum(areaFeatures, (f) => turf.area(f))
      : turf.area(areaFeatures);
    return Math.round(area / 1e6);
  }, [areaFeatures]);

  const selectedLines = useRecoilValue(getSelectedMooringLines);
  const selectedAnchors = useRecoilValue(getSelectedAnchors);

  const anchors = previewMooringAndFoundations
    ? previewMooringAndFoundations.preview.anchors
    : selectedAnchors;

  const mooringLines = previewMooringAndFoundations
    ? previewMooringAndFoundations.preview.mooringLines
    : selectedLines;

  const totalLength = sum(mooringLines, (line) =>
    isMooringLineMultiple(line)
      ? sum(line.properties.lineLengths)
      : line.properties.lineLength ?? 0,
  );

  const bathRaster = useBathymetryRaster({
    projectId,
    featureId: park.id,
    bufferKm: MAX_BUFFER_DISTANCE_RASTER_ANCHOR,
  });

  if (bathRaster.state === "hasError")
    return (
      <div>
        <SimpleAlert text="Error downloading bathymetry." />
      </div>
    );

  if (anchors.length === 0)
    return (
      <div>
        <SimpleAlert text="No anchors in the park." />
      </div>
    );

  return (
    <>
      <Row style={{ justifyContent: "left", flexWrap: "wrap" }}>
        {Array.isArray(areaFeatures) ? (
          <SubAreaTag tooltip="These analysis numbers are only for the selected zone." />
        ) : (
          <Tag>Park</Tag>
        )}
        <Tag>{selectedArea} km²</Tag>
      </Row>

      <ColoredGrid style={{ gridTemplateColumns: "auto auto" }}>
        <p>Number of anchors</p>
        <ResultValue>
          <strong>{anchors.length}</strong>
        </ResultValue>

        <p>Mooring line length</p>
        <ResultValue>
          <strong>{totalLength.toFixed(3)}</strong> km
        </ResultValue>
      </ColoredGrid>
      <SubtitleWithLine text={"Anchor depth histogram"} />
      {ifLoader(
        bathRaster,
        () => (
          <Column>
            <SkeletonText
              style={{ height: "2rem" }}
              text="Loading bathymetry"
            />
          </Column>
        ),
        (raster) => (
          <AnchorDistribution anchors={anchors} depthRaster={raster} />
        ),
      )}
      <ButtonWrapper>
        <Button
          text="View in dashboard"
          buttonType="secondary"
          icon={<Dashboard />}
          onClick={() => {
            setModalType({
              modalType: DashboardModalType,
              metadata: { id: "vind-preset-dashboard-mooring" },
            });
          }}
        />
      </ButtonWrapper>
    </>
  );
};

const MooringTab = ({ park }: { park: ParkFeature }) => {
  const projectId = useRecoilValue(projectIdSelector);
  if (!projectId)
    return (
      <div>
        <SimpleAlert text="Missing park ID." />
      </div>
    );
  return <Inner park={park} projectId={projectId} />;
};

export default MooringTab;
