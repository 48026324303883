/// <reference types="vite-plugin-svgr/client" />
import { ReactNode, useCallback, useEffect, useState } from "react";
import { Loadable, useRecoilCallback, useRecoilValueLoadable } from "recoil";
import styled from "styled-components";
import { getTurbinesSortedByLabelSelectorFamily } from "../../../state/layout";
import { TurbineFeature } from "../../../types/feature";
import { allSimpleTurbineTypesSelector } from "../../../state/turbines";
import { CenterContainer, LoadingState, SafeCard } from "./Base";
import { colors } from "../../../styles/colors";
import {
  SkeletonBlock,
  SkeletonText,
  orTextLoader,
} from "../../Loading/Skeleton";
import { isDefined } from "../../../utils/predicates";
import { useDashboardContext } from "../Dashboard";
import { downloadText, roundToDecimal, undefMap } from "../../../utils/utils";
import { MenuItem } from "../../General/Menu";
import DownloadIcon from "@icons/24/Download.svg?react";
import { useToast } from "../../../hooks/useToast";
import {
  AnalysisStoppedTypes,
  analysisStoppedText,
} from "components/ProductionV2/types";
import { Raster } from "../../../types/raster";
import {
  getAEPPerTurbine,
  getAnalysisProgress,
  getAnalysisWindStats,
  getAverageSpeedPerTurbine,
  getStoppedReason,
  getStoppedReasonFromAnalysis,
  getTotalWakeLossPerTurbine,
} from "components/ProductionV2/state";
import { SimpleTurbineType } from "types/turbines";
import { useBathymetry } from "hooks/bathymetry";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import InfoIcon from "@icons/24/Information.svg?react";
import Tooltip from "components/General/Tooltip";
import { BATHYMETRY_SOURCE_DESCRIPTION } from "business/bathymetry/utils";
import Table from "components/Dashboard/Table";

const InfoIconWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 0.5rem;
  svg {
    width: 12px;
    height: 12px;
    path {
      stroke: ${colors.iconInfo};
    }
  }
`;

const getDepth = (turbine: TurbineFeature, raster: Raster): number => {
  const [lon, lat] = turbine.geometry.coordinates;

  return raster.latLngToValue(lon, lat);
};

type Data = {
  name: string | number;
  turbine: TurbineFeature;
  number: number;
  turbineType: SimpleTurbineType | undefined;
  wakeLoss: Loadable<number | undefined>;
  depth: number | undefined;
  speed: Loadable<number | undefined>;
  aep: Loadable<number | undefined>;
  longitude: number;
  latitude: number;
};

type FormattedData = {
  name: string | number;
  turbine: TurbineFeature;
  number: number;
  turbineType: SimpleTurbineType | undefined;
  wakeLoss: Loadable<string | undefined>;
  depth: string | undefined;
  speed: Loadable<string | undefined>;
  aep: Loadable<string | undefined>;
  longitude: string;
  latitude: string;
};

/**
 * Source for the data we're showing. The same hook is used to show the data in
 * the table, as well as for downloading the .csv.
 */
const useTurbineData = () => {
  const { projectId, park, branch, triggerId } = useDashboardContext();

  const { raster, hasCustomBathymetry } = useBathymetry({
    projectId,
    branchId: branch.id,
    featureId: park.id,
  })
    .map((bath) => {
      if (bath?.status === "finished")
        return {
          raster: bath.raster,
          hasCustomBathymetry: 0 < bath.usedCustomBathymetry.length,
        };
      return {
        raster: undefined,
        hasCustomBathymetry: false,
      };
    })
    .valueMaybe() ?? { raster: undefined, hasCustomBathymetry: false };

  const wakeLossPerTurbine = useRecoilValueLoadable(
    getTotalWakeLossPerTurbine(triggerId),
  );
  const aepPerTurbine = useRecoilValueLoadable(getAEPPerTurbine(triggerId));
  const averageSpeedPerTurbine = useRecoilValueLoadable(
    getAverageSpeedPerTurbine(triggerId),
  );
  const stoppedReason = useRecoilValueLoadable(
    getStoppedReason(triggerId),
  ).valueMaybe();
  const analysisProgress = useRecoilValueLoadable(
    getAnalysisProgress(triggerId),
  ).valueMaybe();
  const windStats = useRecoilValueLoadable(getAnalysisWindStats(triggerId));

  const getWakeLossForTurbine = useCallback(
    (turbineId: string) =>
      wakeLossPerTurbine.map(
        (l) => l?.find((t) => t.turbine.id === turbineId)?.value,
      ),
    [wakeLossPerTurbine],
  );

  const getSpeedForTurbine = useCallback(
    (turbineId: string) =>
      averageSpeedPerTurbine.map(
        (l) => l?.find((t) => t.turbine.id === turbineId)?.value,
      ),
    [averageSpeedPerTurbine],
  );

  const getAepForTurbine = useCallback(
    (turbineId: string) =>
      aepPerTurbine.map(
        (l) => l?.find((t) => t.turbine.id === turbineId)?.value,
      ),
    [aepPerTurbine],
  );

  const getData = useRecoilCallback(
    ({ snapshot }) =>
      async (): Promise<Data[] | undefined> => {
        if (!raster) return;
        const turbines = await snapshot.getPromise(
          getTurbinesSortedByLabelSelectorFamily({ parkId: park.id }),
        );
        const turbineTypes = await snapshot.getPromise(
          allSimpleTurbineTypesSelector,
        );
        const depthPerTurbine = turbines.map((turbine) =>
          getDepth(turbine, raster),
        );
        const data = turbines.map((turbine, i) => {
          return {
            name: turbine.properties.name ?? i + 1,
            turbine,
            speed: getSpeedForTurbine(turbine.id),
            wakeLoss: getWakeLossForTurbine(turbine.id),
            number: i + 1,
            depth: depthPerTurbine?.[i],
            aep: getAepForTurbine(turbine.id),
            longitude: turbine.geometry.coordinates[0],
            latitude: turbine.geometry.coordinates[1],
            turbineType: turbineTypes.find(
              (tt) => tt.id === turbine.properties.turbineTypeId,
            ),
          };
        });
        return data;
      },
    [
      getAepForTurbine,
      raster,
      park.id,
      getWakeLossForTurbine,
      getSpeedForTurbine,
    ],
  );

  return {
    getData,
    hasCustomBathymetry,
    stoppedReason,
    analysisProgress,
    windStats,
  };
};

/** Replaces the argument with a `Skeleton` if it is `undefined`.
 *
 * When we're requeting for production numbers, we immediately get back some
 * numbers, but not all. The ones that aren't ready yet are given as
 * `undefined`.  This is so that we can show as much data as possible eary on,
 * without having to wait for the analysis.
 *
 * This function just renders these values "correctly", so that the loading ones
 * are shown as a loader, and the ready ones are shown as-is.
 */
const undefToSkeleton = <T,>(t: T | undefined): T | ReactNode =>
  isDefined(t) ? (
    t
  ) : (
    <SkeletonBlock style={{ height: "1rem", width: "100%" }} />
  );

const TurbineDetails = () => {
  const { projectId, park, branch, triggerId } = useDashboardContext();

  const bathymetry = useBathymetry({
    projectId,
    branchId: branch.id,
    featureId: park.id,
  });

  const stoppedReason = useRecoilValueLoadable(
    getStoppedReason(triggerId),
  ).valueMaybe();
  const analysisStoppedReason = useRecoilValueLoadable(
    getStoppedReasonFromAnalysis(triggerId),
  ).valueMaybe();

  if (bathymetry.state === "hasError")
    return <div>Error when downloading bathymetry</div>;

  if (stoppedReason) {
    return (
      <CenterContainer>
        <SimpleAlert
          title="Analysis stopped"
          text={analysisStoppedText[stoppedReason]}
          type={"error"}
        />
      </CenterContainer>
    );
  }
  if (analysisStoppedReason) {
    return (
      <SimpleAlert
        title="Analysis stopped"
        text={
          analysisStoppedText[analysisStoppedReason as AnalysisStoppedTypes]
        }
        type={"error"}
      />
    );
  }

  return <Inner />;
};

const Inner = () => {
  const { analysisProgress, getData, windStats } = useTurbineData();

  const [items, setItems] = useState<undefined | FormattedData[]>(undefined);

  useEffect(() => {
    getData().then((data) => {
      setItems(
        data?.map((d) => {
          const wakeLoss = d.wakeLoss.map((wl) =>
            undefMap(wl, (wl) => `${(wl * 100.0).toFixed(1)}%`),
          );
          const depth = undefMap(d.depth, (d) => `${(-d).toFixed(0)} m`);
          const speed = d.speed.map((speed) =>
            undefMap(speed, (s) => `${s.toFixed(2)} m/s`),
          );
          const aep = d.aep.map((aep) =>
            undefMap(aep, (aep) => `${aep?.toFixed(0)} GWh`),
          );
          return {
            ...d,
            wakeLoss,
            depth,
            speed,
            aep,
            longitude: d.longitude.toFixed(4),
            latitude: d.latitude.toFixed(4),
          };
        }),
      );
    });
  }, [getData]);

  if (!items) {
    if (analysisProgress)
      return (
        <SkeletonText
          style={{ margin: "2rem", height: "2rem" }}
          text={`${Math.round(analysisProgress * 100)}%`}
        />
      );
    return <LoadingState />;
  }

  if (items.length === 0)
    return (
      <CenterContainer>
        <SimpleAlert text={"No turbines in the park."} type={"error"} />
      </CenterContainer>
    );

  return (
    <Table>
      <thead>
        <tr>
          <th>#</th>
          <th>Type</th>
          <th>AEP</th>
          <th>
            <InfoIconWrapper>
              <>Avg. speed</>
              <Tooltip
                text={`Unwaked mean speed, including any spatial calibration, valid at hub height: ${windStats.contents?.height || "- "}m`}
              >
                <InfoIcon />
              </Tooltip>
            </InfoIconWrapper>
          </th>
          <th>
            <InfoIconWrapper>
              <>Depth</>
              <Tooltip text={BATHYMETRY_SOURCE_DESCRIPTION}>
                <InfoIcon />
              </Tooltip>
            </InfoIconWrapper>
          </th>
          <th>Wake loss</th>
          <th>Longitude</th>
          <th>Latitude</th>
        </tr>
      </thead>
      <tbody>
        {items.map(
          ({
            name,
            turbineType,
            aep,
            speed,
            depth,
            wakeLoss,
            longitude,
            latitude,
            turbine,
          }) => (
            <tr key={turbine.id}>
              <td>{undefToSkeleton(name)}</td>
              <td>{undefToSkeleton(turbineType?.name)}</td>
              <td>
                {orTextLoader(
                  aep,
                  undefMap(
                    analysisProgress,
                    (p) => `${Math.round(p * 100)}%`,
                  ) ?? "",
                  (n) => (
                    <>{n}</>
                  ),
                )}
              </td>
              <td>
                {orTextLoader(
                  speed,
                  undefMap(
                    analysisProgress,
                    (p) => `${Math.round(p * 100)}%`,
                  ) ?? "",
                  (n) => (
                    <>{n}</>
                  ),
                )}
              </td>
              <td>{undefToSkeleton(depth)}</td>
              <td>
                {orTextLoader(
                  wakeLoss,
                  undefMap(
                    analysisProgress,
                    (p) => `${Math.round(p * 100)}%`,
                  ) ?? "",
                  (n) => (
                    <>{n}</>
                  ),
                )}
              </td>
              <td>{undefToSkeleton(longitude)}</td>
              <td>{undefToSkeleton(latitude)}</td>
            </tr>
          ),
        )}
      </tbody>
    </Table>
  );
};

const delimiter = ",";
const removeDelimiter = (s: string) => s.replace(delimiter, "");
const TurbineMenu = () => {
  const { error, warning } = useToast();
  const { hasCustomBathymetry, getData } = useTurbineData();
  const { park } = useDashboardContext();

  const downloadCSV = useCallback(async () => {
    const data = await getData();
    if (!data) {
      error("Cannot download turbine data: missing bathymetry data.");
      return;
    }

    const depthDecimals = hasCustomBathymetry ? 1 : 0;

    const isLoading = data.find(
      (d) =>
        d.wakeLoss.state !== "hasValue" ||
        d.speed.state !== "hasValue" ||
        d.aep.state !== "hasValue",
    );

    if (isLoading) {
      warning(
        "Data is still loading, please wait a few seconds and try again.",
      );
      return;
    }

    const fields = [
      "name",
      "turbineType",
      "aep",
      "speed",
      "depth",
      "wakeLoss",
      "longitude",
      "latitude",
    ];
    type Field = (typeof fields)[number];

    const formatted: Record<
      Field,
      string | undefined | number | TurbineFeature
    >[] = data.map((d) => ({
      name: `${d.name}`,
      turbine: d.turbine,
      wakeLoss: undefMap(d.wakeLoss.valueMaybe(), (s) => roundToDecimal(s, 4)),
      number: d.number,
      depth: undefMap(d.depth, (d) => roundToDecimal(-d, depthDecimals)),
      aep: undefMap(d.aep.valueMaybe(), (d) => d.toFixed(1)),
      speed: undefMap(d.speed.valueMaybe(), (s) => s.toFixed(2)),
      longitude: d.longitude.toFixed(6),
      latitude: d.latitude.toFixed(6),
      turbineType: d.turbineType?.name ?? "-",
    }));

    const headerNames: Record<Field, string> = {
      name: "Name",
      turbineType: "Type",
      aep: "AEP (GWh)",
      depth: "Depth",
      wakeLoss: "Wake loss",
      speed: "Mean speed",
      longitude: "Longitude",
      latitude: "Latitude",
    };

    let csv = "#";
    for (const f of fields.slice(1)) {
      csv += delimiter + headerNames[f];
    }

    for (const d of formatted) {
      csv += "\n" + removeDelimiter(String(d.name));
      for (const f of fields.slice(1)) {
        csv += delimiter + removeDelimiter(String(d[f]));
      }
    }

    downloadText(
      csv,
      `turbines-${park.properties.name}-${new Date().toISOString()}.csv`,
    );
  }, [error, getData, hasCustomBathymetry, park.properties.name, warning]);

  return (
    <MenuItem
      name={"Download as .csv"}
      icon={<DownloadIcon />}
      onClick={() => downloadCSV()}
    />
  );
};

export const TurbineListWidget = () => (
  <SafeCard title="Turbines" id="Turbines" menuItems={<TurbineMenu />}>
    <TurbineDetails />
  </SafeCard>
);
