import { useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { useMouseSampler } from "../../hooks/mouseSampler";
import { getWeibullTile } from "../../services/gwaService";
import { windLayerHeightAtom } from "../../state/layer";
import { isDefined } from "../../utils/predicates";

const zoomLevel = 6;
const tileSize = 512;

export const useWindMouseSampler = () => {
  const windLayerHeight = useRecoilValue(windLayerHeightAtom);
  const getWeibullTileWithHeight = useCallback(
    (x: number, y: number, z: number) =>
      getWeibullTile(x, y, z, windLayerHeight),
    [windLayerHeight],
  );
  const sample = useMouseSampler(getWeibullTileWithHeight, zoomLevel, tileSize);
  const meanSpeed = useMemo(() => {
    if (!sample) return;
    return (sample[0] / 255) * 10 + 3;
  }, [sample]);

  if (!meanSpeed) return;

  if (!isDefined(sample) || sample[2] === 0) return;

  return meanSpeed;
};

const WindMouseSampler = () => {
  const meanSpeed = useWindMouseSampler();
  if (!meanSpeed) return <div>No data</div>;
  return <div>Wind speed: {meanSpeed.toFixed(1)} m/s</div>;
};

export default WindMouseSampler;
