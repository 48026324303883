import styled from "styled-components";
import { typography } from "styles/typography";
import { colors } from "styles/colors";
import { spaceSmall, spaceTiny } from "styles/space";

export const Table = styled.table`
  padding: 0;
  margin: 0;
  border-collapse: separate;
  border-spacing: 0;
  align-self: stretch;

  ${typography.contentAndButtons};

  &:first-child {
    th {
      &:nth-child(n) {
        border-top: none;
      }
    }
  }

  thead {
    background: ${colors.focusBackground};
  }

  overflow: clip;

  position: relative;
  th {
    background: ${colors.focusBackground};
    position: sticky;
    top: 0;
    z-index: 2;
  }

  td {
    &:nth-child(n) {
      border-top: none;
    }
  }

  td,
  th {
    margin: 0;
    padding: ${spaceTiny} ${spaceSmall};
    white-space: nowrap;
    border: 1px solid ${colors.inputOutline};

    text-align: start;

    &:nth-child(n + 1) {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }
  }
`;

export default Table;
