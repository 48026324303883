import * as spec from "../../../api/customer";
import { z } from "zod";

export const {
  OrganisationResources: _OrganisationResources,
  OrgResource: _OrgResource,

  PersonalOrgResource: _PersonalOrgResource,
  GroupOrgResource: _GroupOrgResource,
} = spec.components.schemas;

export type OrganisationResources = z.infer<typeof _OrganisationResources>;
export type OrgResource = z.infer<typeof _OrgResource>;
export type PersonalOrgResource = z.infer<typeof _PersonalOrgResource>;
export type GroupOrgResource = z.infer<typeof _GroupOrgResource>;

export type GroupedOrgResourceEntry = {
  name: string;
  resources: OrganisationResources[];
};
export type GroupedOrgResource = Record<string, GroupedOrgResourceEntry>;
