import styled from "styled-components";
import { spaceMedium } from "../../styles/space";

export const LeftSideButtonsContainer = styled.div`
  position: fixed;
  width: var(--side-bars-width);
  top: calc(
    calc(var(--top-bar-menu-height) + var(--branch-tab-bar-height)) +
      ${spaceMedium}
  );
  left: var(--left-menu-margin);
  z-index: 1;
  display: flex;
  flex-direction: column;
  transition: left 0.3s ease-in-out;
`;
