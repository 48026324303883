import {
  ProdId,
  analysisOverrideInputAtomFamily,
  getBranchId,
  getCapacity,
  getParkId,
  getTurbineTypes,
  getTurbines,
} from "components/ProductionV2/state";
import { DefaultMap } from "lib/DefaultMap";
import { selectorFamily } from "recoil";
import { SubstationType } from "services/substationService";
import { cableChainsInBranchSelectorFamily } from "state/cableEdit";
import { currentSubstationTypesState } from "state/substationType";
import { SubstationFeature } from "types/feature";
import groupBy from "utils/groupBy";
import { isDefined } from "utils/predicates";
import { CostType } from "../../../../services/costService";
import {
  getExportCablesInBranchSelectorFamily,
  getSubstationsInBranchSelectorFamily,
} from "../../../../state/cable";
import { AmountUnit } from "../../../../types/financial";
import { mapToRecord, sum } from "../../../../utils/utils";
import { Amount, amountAddId, amountId } from "../generalAmounts";

export const allSubstationTypesInParkSelectorFamily = selectorFamily<
  SubstationType[],
  { branchId: string; parkId: string }
>({
  key: "allSubstationTypesInParkSelectorFamily",
  get:
    ({ branchId, parkId }) =>
    ({ get }) => {
      const substations = get(
        getSubstationsInBranchSelectorFamily({
          parkId,
          branchId,
        }),
      );
      const substationTypeIds = [
        ...new Set(
          substations
            .map((s) => s.properties.substationTypeId)
            .filter(isDefined),
        ),
      ];

      const allSubstationTypes = get(currentSubstationTypesState);

      const allSubstationTypesInPark = allSubstationTypes.filter((st) =>
        substationTypeIds.some((id) => id === st.id),
      );
      return allSubstationTypesInPark;
    },
});

export const getSubstationTypeToSubstationsSelectorFamily = selectorFamily<
  Record<string, SubstationFeature[]>,
  { parkId: string; branchId: string }
>({
  key: "getSubstationTypeToSubstationsSelectorFamily",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const substations = get(
        getSubstationsInBranchSelectorFamily({ parkId, branchId }),
      );
      const filteredSubstations = substations.filter(
        (s) => s.properties.substationTypeId,
      );
      const map = new DefaultMap<string, SubstationFeature[]>(() => []);
      for (const s of filteredSubstations)
        map.get(s.properties.substationTypeId!).push(s);
      return mapToRecord(map.inner);
    },
});

const substationCapacitySelectorFamily = selectorFamily<
  { [id: string]: number },
  ProdId
>({
  key: "substationCapacitySelectorFamily",
  get:
    (productionId) =>
    ({ get }) => {
      const branchId = get(getBranchId(productionId));
      const parkId = get(getParkId(productionId));

      const substations = get(
        getSubstationsInBranchSelectorFamily({ parkId, branchId }),
      );

      const allSubstationTypesInPark = get(
        allSubstationTypesInParkSelectorFamily({ branchId, parkId }),
      );

      const chains = get(
        cableChainsInBranchSelectorFamily({ parkId, branchId }),
      );

      const input = get(analysisOverrideInputAtomFamily(productionId));

      const exportCables = get(
        getExportCablesInBranchSelectorFamily({
          parkId,
          branchId,
          exportCableTypeOverrideId: input.exportCableTypeOverrideId,
        }),
      );

      const turbines = get(getTurbines(productionId));
      const turbineTypes = get(getTurbineTypes(productionId));

      const groups: { [key: string]: SubstationType[] } = groupBy(
        allSubstationTypesInPark,
        (s) => s.type,
      );

      const onshoreTypes: SubstationType[] = groups["onshore"] ?? [];
      const offshoreTypes: SubstationType[] = groups["offshore"] ?? [];

      const result: Record<string, number> = {};

      offshoreTypes.forEach((type) => {
        substations
          .filter(
            ({ properties: { substationTypeId } }) =>
              substationTypeId === type.id,
          )
          .forEach((sub) => {
            const subCableChains = chains.filter(
              ({ substation }) => substation === sub.id,
            );

            const subCableChainPowers = subCableChains.map((chain) => {
              const chainTurbinePowers = chain.turbines.map((turbine_id) => {
                const turbine = turbines.find(
                  (turbine) => turbine.id === turbine_id,
                );

                const turbineType = turbineTypes.find(
                  (type) => type.id === turbine?.properties.turbineTypeId,
                );

                return (turbineType?.ratedPower ?? 0) / 1000;
              });

              return sum(chainTurbinePowers);
            });

            const subCapacity = sum(subCableChainPowers);

            result[sub.id] = subCapacity;
          });
      });

      onshoreTypes.forEach((type) => {
        const onshoreSubs = substations.filter(
          ({ properties: { substationTypeId } }) =>
            substationTypeId === type.id,
        );

        onshoreSubs.forEach((onshoreSub) => {
          const fromSubstations = exportCables
            .filter(
              (cable) => cable.properties.toSubstationId === onshoreSub.id,
            )
            .map((cable) => cable.properties.fromSubstationId)
            .filter(isDefined);

          const fromSubCapacities = fromSubstations.map((fromSubId) => {
            const fromSubCapacity = result[fromSubId] ?? 0;
            const exportCablesFromSub = exportCables.filter(
              (cable) => cable.properties.fromSubstationId === fromSubId,
            ).length;

            // We assume the capacity is evenly distributed among the onshore substations they are connected to
            return fromSubCapacity / exportCablesFromSub;
          });

          const subCapacity = sum(fromSubCapacities);

          result[onshoreSub.id] = subCapacity;
        });
      });

      return result;
    },
});

export const substationAmountsSelectorFamily = selectorFamily<Amount[], ProdId>(
  {
    key: "substationAmountsSelectorFamily",
    get:
      (productionId) =>
      ({ get }) => {
        const branchId = get(getBranchId(productionId));
        const parkId = get(getParkId(productionId));

        const substations = get(
          getSubstationsInBranchSelectorFamily({ parkId, branchId }),
        );

        const allSubstationTypesInPark = get(
          allSubstationTypesInParkSelectorFamily({ branchId, parkId }),
        );

        const onshoreSubstations = substations.filter(
          (s) =>
            allSubstationTypesInPark.find(
              (st) => st.id === s.properties.substationTypeId,
            )?.type === "onshore",
        );
        const offshoreSubstations = substations.filter(
          (s) =>
            allSubstationTypesInPark.find(
              (st) => st.id === s.properties.substationTypeId,
            )?.type === "offshore",
        );

        const substationTypeToSubstationsMap = get(
          getSubstationTypeToSubstationsSelectorFamily({ parkId, branchId }),
        );

        const substationToCapacity = get(
          substationCapacitySelectorFamily(productionId),
        );

        const capacity = get(getCapacity(productionId));

        const mwPerSubstation = substations.map((sub) => {
          const capacity = substationToCapacity[sub.id] ?? 0;

          return {
            unit: AmountUnit.MW,
            amount: capacity,
            category: CostType.Substation,
            id: amountId({
              unit: AmountUnit.MW,
              category: CostType.Substation,
              featureTypeId: sub.id,
            }),
          };
        });

        const unitTotal = amountAddId({
          unit: AmountUnit.unit,
          amount: substations.length,
          category: CostType.Substation,
        });

        const OSSTotal = amountAddId({
          unit: AmountUnit.OSS,
          amount: offshoreSubstations.length,
          category: CostType.Substation,
        });

        const ONSTotal = amountAddId({
          unit: AmountUnit.ONS,
          amount: onshoreSubstations.length,
          category: CostType.Substation,
        });

        const unitAmounts = allSubstationTypesInPark.map((st) => {
          const amount = substationTypeToSubstationsMap[st.id]?.length ?? 0;
          const unit = AmountUnit.unit;
          const category = CostType.Substation;

          return {
            id: amountId({ unit, category, featureTypeId: st.id }),
            unit,
            amount,
            category,
          };
        });

        const perMwAmount = amountAddId({
          unit: AmountUnit.MW,
          amount: substations.length > 0 ? capacity ?? 0 : 0,
          category: CostType.Substation,
        });

        const fixedAmount = {
          id: amountId({
            unit: AmountUnit.fixed,
            category: CostType.Substation,
          }),
          unit: AmountUnit.fixed,
          amount: 1,
          category: CostType.Substation,
        };

        return [
          ...mwPerSubstation,
          ...unitAmounts,
          unitTotal,
          OSSTotal,
          ONSTotal,
          perMwAmount,
          fixedAmount,
        ];
      },
  },
);
