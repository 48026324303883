import { useState, useEffect, useCallback } from "react";
import FullScreenModal from "../FullScreenModal/FullScreenModal";
import { ModalFrame, Row } from "../General/Layout";
import { getGPUTier, TierResult } from "detect-gpu";
import Button from "../General/Button";
import { useLocalStorage } from "../../hooks/useBrowserStorage";
import { isInChecklyMode } from "../../utils/utils";
import { spaceMedium } from "../../styles/space";
import { sendInfo } from "../../utils/sentry";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { topLevelNodeFromOrgIdAndNodeIdSelectorFamily } from "components/Projects/useOrganisationFolderCrud";
import { organisationIdSelector, projectIdSelector } from "state/pathParams";

const GPUCheckInner = () => {
  const [hide, setHide] = useState(false);
  const [tier, setTier] = useState<TierResult | undefined>(undefined);
  useEffect(() => {
    setTimeout(() => {
      getGPUTier().then((res) => setTier(res));
    }, 10_000);
  }, []);

  const [didComplain, setDidComplain] = useLocalStorage<boolean>(
    "didComplainAboutGPU",
  );
  const [didIgnore, setIgnore] = useLocalStorage<boolean>("ignoreGPUComplaint");

  const report = useCallback(() => {
    setDidComplain(true);
    if (!didComplain) {
      sendInfo("User saw GPU complaint", tier);
    }
  }, [didComplain, setDidComplain, tier]);

  const ignore = useCallback(() => {
    setIgnore(true);
  }, [setIgnore]);

  const help = useCallback(() => {
    setHide(true);
    window.Intercom("showNewMessage");
  }, []);

  if (!tier || didIgnore || hide) return null;

  const isBad =
    (tier.fps ?? Infinity) < 30 || (tier.type === "FALLBACK" && tier.tier < 2);

  if (isBad) {
    report();
    return (
      <FullScreenModal>
        <ModalFrame title="Lacking GPU Support" style={{ width: "40rem" }}>
          <p>
            It seems your system lacks GPU support, which can lead to a poor
            experience using Vind. This could either be because your GPU is
            outdated, or because your browser is not configured to use it.
          </p>

          <p>
            Note that the process used to detect this is not exact, and this
            might be a false positive. If you think this is a{" "}
            <strong>mistake</strong>, please contact us through the help center.
          </p>

          <Row
            style={{ justifyContent: "space-between", marginTop: spaceMedium }}
          >
            <Button
              text="Ignore"
              buttonType="secondary"
              onClick={() => {
                ignore();
              }}
            />
            <Button
              text="Open help center"
              buttonType="primary"
              onClick={() => {
                help();
              }}
            />
          </Row>
        </ModalFrame>
      </FullScreenModal>
    );
  }
  return null;
};

const Inner = () => {
  const projectId = useRecoilValue(projectIdSelector);
  const organisationId = useRecoilValue(organisationIdSelector);
  const topLevelNode = useRecoilValueLoadable(
    topLevelNodeFromOrgIdAndNodeIdSelectorFamily({
      organisationId,
      nodeId: projectId,
    }),
  );

  const checklyTopLevelNodeIds = [
    "1ab0dbf8-6fca-4917-9d3b-af55ea97bb88", // checkly A nodeId
    "cdrI49YAOr9cCoCT90B1t", // checkly A legacy
    "uaBAlgySHGvd2O9B3ZSHj", // checkly B legacy
  ];

  const looksAtChecklyTeams =
    topLevelNode
      .map((tln) =>
        checklyTopLevelNodeIds.find(
          (i) => i === tln?.id || i === tln?.legacy_id,
        ),
      )
      .valueMaybe() ?? true;

  if (!organisationId || looksAtChecklyTeams || isInChecklyMode()) return null;
  return <GPUCheckInner />;
};

const GPUCheck = () => {
  if (isInChecklyMode()) return null;
  return <Inner />;
};

export default GPUCheck;
