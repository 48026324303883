import { atom, selectorFamily } from "recoil";
import { ChangelogEntry } from "./type";
import {
  getOrganisationLatestUpdate,
  getOrganisationResourceChangelog,
} from "./service";

export const organisationResourceLastUpdateRefresherAtom = atom<number>({
  key: "organisationResourceLastUpdateRefresherAtom",
  default: 0,
});

export const organisationResourceLastUpdateSelectorFamily = selectorFamily<
  Record<string, ChangelogEntry>,
  { organisationId: string; resourceIds: string[] }
>({
  key: "organisationResourceLastUpdateSelectorFamily",
  get:
    ({ organisationId, resourceIds }) =>
    async ({ get }) => {
      get(organisationResourceLastUpdateRefresherAtom);
      const items = await getOrganisationLatestUpdate(
        organisationId,
        resourceIds,
      );
      return items.reduce(
        (acc, item) => ({
          ...acc,
          [item.id]: item,
        }),
        {},
      );
    },
});

export const organisationResourceChangelogSelectorFamily = selectorFamily<
  ChangelogEntry[],
  { organisationId: string; resourceId: string }
>({
  key: "organisationResourceChangelogSelectorFamily",
  get:
    ({ organisationId, resourceId }) =>
    async () =>
      getOrganisationResourceChangelog(organisationId, resourceId),
});
