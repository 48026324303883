/// <reference types="vite-plugin-svgr/client" />
import * as turf from "@turf/turf";
import { Feature, LineString, Point } from "geojson";
import DownloadIcon from "@icons/24/Download.svg?react";
import { currentExportCableTypesSelector } from "components/Cabling/Generate/state";
import Dropdown from "components/Dropdown/Dropdown";
import { Label } from "components/General/Form";
import {
  getAnalysisProgress,
  getExportSystemLosses,
  getStoppedReason,
  getStoppedReasonFromAnalysis,
} from "components/ProductionV2/state";
import {
  AnalysisStoppedTypes,
  analysisStoppedText,
} from "components/ProductionV2/types";
import { stringify } from "csv-stringify/sync";
import Plotly from "plotly.js-dist-min";
import { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import {
  getCablesSelectorFamily,
  getExportCablesInBranchSelectorFamily,
  getExportCablesSelectorFamily,
} from "state/cable";
import { colors } from "styles/colors";
import { spaceMedium } from "styles/space";
import { isDefined } from "utils/predicates";
import { useToast } from "../../../hooks/useToast";
import { downloadText, fastMax, fastMin } from "../../../utils/utils";
import { MenuItem } from "../../General/Menu";
import { useDashboardContext } from "../Dashboard";
import { CenterContainer, LoadingState, SafeCard } from "./Base";
import { exportCableSplitsOk } from "functions/elevation";
import { binarySearchClosest } from "components/ProductionV2/utils";
import { ExportCableFeature } from "types/feature";
import { CableType } from "services/cableTypeService";
import { SkeletonText } from "components/Loading/Skeleton";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";

const computeCurrentNumbers = (
  exportCables: ExportCableFeature[],
  exportCableSegments: {
    exportCable: ExportCableFeature;
    offshore: Feature<LineString>;
    landfallPoint: Feature<Point>;
    onshore: Feature<LineString>;
  }[],
  exportCableTypes: CableType[],
  exportCableCurrents: Record<string, number[]>,
  exportCableSegLengths: Record<string, number[]>,
  usedExportCableId: string,
) => {
  const selectedExportCableCurrents = exportCableCurrents[usedExportCableId];
  const selectedExportCableSegLengths =
    exportCableSegLengths[usedExportCableId];

  const completeCable = exportCables.find((c) => c.id === usedExportCableId);
  const offshoreCable = exportCableSegments.find(
    (c) => c.exportCable.id === usedExportCableId,
  )?.offshore;
  const cableHasLandfall = offshoreCable ? true : false;

  const offshoreCableTypeId = exportCables.find(
    (c) => c.id === usedExportCableId,
  )?.properties.cableTypeId;
  const onshoreCableTypeId = exportCables.find(
    (c) => c.id === usedExportCableId,
  )?.properties.onshoreCableTypeId;
  const offshoreRatedCurrent = exportCableTypes.find(
    (ct) => ct.id === offshoreCableTypeId,
  )?.ampacity;
  const onshoreRatedCurrent = exportCableTypes.find(
    (ct) => ct.id === onshoreCableTypeId,
  )?.ampacity;

  if (
    !selectedExportCableCurrents ||
    !selectedExportCableSegLengths ||
    !completeCable ||
    !offshoreRatedCurrent ||
    !onshoreRatedCurrent
  )
    return undefined;

  const offshoreLength = turf.length(offshoreCable ?? completeCable, {
    units: "kilometers",
  });

  const landfallIdx = binarySearchClosest(
    selectedExportCableSegLengths,
    offshoreLength,
  );
  const offshoreArrayLength = selectedExportCableSegLengths.slice(
    0,
    landfallIdx + 1,
  ).length;
  const onshoreArrayLength =
    selectedExportCableSegLengths.length - offshoreArrayLength;

  const offshoreRatedCurrentValues = Array(offshoreArrayLength).fill(
    offshoreRatedCurrent * 1000,
  );
  const onshoreRatedCurrentValues = Array(onshoreArrayLength).fill(
    onshoreRatedCurrent * 1000,
  );
  const ratedCurrentValues = [
    ...offshoreRatedCurrentValues,
    ...onshoreRatedCurrentValues,
  ];

  return {
    selectedExportCableCurrents,
    selectedExportCableSegLengths,
    ratedCurrentValues,
    offshoreLength,
    cableHasLandfall,
  };
};

const ExportCableLoading = ({
  usedExportCableId,
  setUsedExportCableId,
}: {
  usedExportCableId: string | undefined;
  setUsedExportCableId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}) => {
  const { park, branch, configuration, triggerId } = useDashboardContext();

  const exportCables = useRecoilValue(
    getExportCablesInBranchSelectorFamily({
      parkId: park.id,
      branchId: branch.id,
    }),
  );
  const cables = useRecoilValue(getCablesSelectorFamily({ parkId: park.id }));
  const exportCableSegments = useRecoilValue(
    exportCableSplitsOk({ parkId: park.id, branchId: branch.id }),
  );
  const exportCableTypes = useRecoilValue(currentExportCableTypesSelector);
  const exportSystemLoss = useRecoilValue(getExportSystemLosses(triggerId));
  const exportCableCurrents = exportSystemLoss?.results?.exportCableCurrents;
  const exportCableSegLengths =
    exportSystemLoss?.results?.exportCableSegLengths;
  const stoppedReason = useRecoilValueLoadable(
    getStoppedReason(triggerId),
  ).valueMaybe();
  const analysisStoppedReason = useRecoilValueLoadable(
    getStoppedReasonFromAnalysis(triggerId),
  ).valueMaybe();
  const analysisProgress = useRecoilValue(getAnalysisProgress(triggerId));

  if (cables.length === 0) {
    return (
      <CenterContainer>
        <SimpleAlert text={"No inter-array cables in park."} type={"error"} />
      </CenterContainer>
    );
  }

  if (stoppedReason) {
    return (
      <CenterContainer>
        <SimpleAlert
          title="Analysis stopped"
          text={analysisStoppedText[stoppedReason]}
          type={"error"}
        />
      </CenterContainer>
    );
  }
  if (analysisStoppedReason) {
    return (
      <SimpleAlert
        title="Analysis stopped"
        text={
          analysisStoppedText[analysisStoppedReason as AnalysisStoppedTypes]
        }
        type={"error"}
      />
    );
  }

  if (!configuration.electrical.exportSystemLoss) {
    return (
      <CenterContainer>
        <SimpleAlert
          text={
            "Export system losses are not enabled for the selected analysis configuration."
          }
          type={"error"}
        />
      </CenterContainer>
    );
  }

  if (!usedExportCableId) {
    return (
      <CenterContainer>
        <SimpleAlert text={"No export cables in park."} type={"error"} />
      </CenterContainer>
    );
  }

  if (!exportCableCurrents || !exportCableSegLengths) {
    if (analysisProgress)
      return (
        <SkeletonText
          style={{ margin: "2rem", height: "2rem" }}
          text={`${Math.round(analysisProgress * 100)}%`}
        />
      );
    return <LoadingState />;
  }

  const res = computeCurrentNumbers(
    exportCables,
    exportCableSegments,
    exportCableTypes,
    exportCableCurrents,
    exportCableSegLengths,
    usedExportCableId,
  );

  if (!res) {
    return (
      <CenterContainer>
        <SimpleAlert
          text={"Cannot find results for the selected export cable."}
          type={"error"}
        />
      </CenterContainer>
    );
  }

  const {
    selectedExportCableCurrents,
    selectedExportCableSegLengths,
    ratedCurrentValues,
    offshoreLength,
    cableHasLandfall,
  } = res;

  return (
    <>
      <Label style={{ width: "30%", padding: spaceMedium }}>
        <p>Export cable</p>
        <Dropdown
          style={{ flex: 1 }}
          onChange={(e) => setUsedExportCableId(e.target.value)}
          value={usedExportCableId}
        >
          {exportCables.map((c) => {
            return (
              <option key={c.id} value={c.id}>
                {`${c.properties.name}`}
              </option>
            );
          })}
        </Dropdown>
      </Label>
      <ExportCableLoadingInner
        selectedExportCableCurrents={selectedExportCableCurrents}
        selectedExportCableSegLengths={selectedExportCableSegLengths}
        offshoreLength={offshoreLength}
        ratedCurrentValues={ratedCurrentValues}
        cableHasLandfall={cableHasLandfall}
      />
    </>
  );
};

const ExportCableLoadingInner = ({
  selectedExportCableCurrents,
  selectedExportCableSegLengths,
  offshoreLength,
  ratedCurrentValues,
  cableHasLandfall,
}: {
  selectedExportCableCurrents: number[];
  selectedExportCableSegLengths: number[];
  offshoreLength: number;
  ratedCurrentValues: number[];
  cableHasLandfall: boolean;
}) => {
  const graphRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!graphRef.current) return;
    setIsLoaded(false);

    const landfallX = [offshoreLength, offshoreLength];
    const landfallY = [0, 10000];

    const data = [
      {
        name: "Current at full load",
        y: selectedExportCableCurrents,
        x: selectedExportCableSegLengths,
        type: "scatter" as const,
        line: { color: colors.primary } as const,
        hovertemplate: "Position: %{x:,.0f} km<br />Current: %{y:,.0f} A",
        mode: "lines+markers",
      } as const,
      {
        name: "Rated current",
        y: ratedCurrentValues,
        x: selectedExportCableSegLengths,
        type: "scatter" as const,
        line: { shape: "vh", dash: "dash", color: "black" } as const,
        mode: "lines",
      } as const,
      cableHasLandfall
        ? ({
            name: "Landfall",
            y: landfallY,
            x: landfallX,
            type: "scatter" as const,
            line: { color: colors.primaryDisabled, width: 1 } as const,
            mode: "lines",
          } as const)
        : undefined,
    ].filter(isDefined);

    const layout = {
      hovermode: "closest" as const,
      legend: { x: 0.5, y: 1.1, xanchor: "center", orientation: "h" } as const,
      font: { size: 10, family: "Open Sans" },
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
      autosize: true,
      height: 220,
      xaxis: {
        title: "Distance from offshore substation [km]",
        range: [
          0,
          1.01 *
            selectedExportCableSegLengths[
              selectedExportCableSegLengths.length - 1
            ],
        ],
        autotick: true,
      },
      yaxis: {
        title: "Export cable current [A]",
        range: [
          fastMin([...selectedExportCableCurrents, ...ratedCurrentValues]) -
            100,
          fastMax([...selectedExportCableCurrents, ...ratedCurrentValues]) +
            100,
        ],
        autotick: true,
      },
      margin: {
        l: 60,
        r: 30,
        b: 30,
        t: 30,
      },
    };

    Plotly.newPlot(graphRef.current, data, layout, {
      displayModeBar: false,
      responsive: true,
      showTips: false,
      staticPlot: true,
    }).then(() => setIsLoaded(true));
  }, [
    cableHasLandfall,
    graphRef,
    offshoreLength,
    ratedCurrentValues,
    selectedExportCableCurrents,
    selectedExportCableSegLengths,
  ]);

  return (
    <div style={{ flex: 1 }} ref={graphRef}>
      {isLoaded ?? <LoadingState />}
    </div>
  );
};

const ExportCableLoadingMenu = ({
  usedExportCableId,
}: {
  usedExportCableId: string | undefined;
}) => {
  const { park, branch, triggerId } = useDashboardContext();

  const exportCables = useRecoilValue(
    getExportCablesInBranchSelectorFamily({
      parkId: park.id,
      branchId: branch.id,
    }),
  );
  const exportCableSegments = useRecoilValue(
    exportCableSplitsOk({ parkId: park.id, branchId: branch.id }),
  );
  const exportCableTypes = useRecoilValue(currentExportCableTypesSelector);
  const exportSystemLoss = useRecoilValue(getExportSystemLosses(triggerId));
  const exportCableCurrents = exportSystemLoss?.results?.exportCableCurrents;
  const exportCableSegLengths =
    exportSystemLoss?.results?.exportCableSegLengths;

  const { warning } = useToast();

  const download = useCallback(() => {
    if (!exportCableCurrents || !exportCableSegLengths) {
      warning(
        "Data is still loading, please wait a few seconds and try again.",
      );
      return;
    }

    if (!usedExportCableId) {
      warning("No export cables in park.");
      return;
    }

    const res = computeCurrentNumbers(
      exportCables,
      exportCableSegments,
      exportCableTypes,
      exportCableCurrents,
      exportCableSegLengths,
      usedExportCableId,
    );

    if (!res) {
      warning("Cannot find results for the selected export cable.");
      return;
    }

    const {
      selectedExportCableCurrents,
      selectedExportCableSegLengths,
      ratedCurrentValues,
    } = res;

    const header = [
      "Distance from offshore substation [km]",
      "Export cable current at full load [A]",
      "Rated current [A]",
    ];

    const rows = selectedExportCableSegLengths
      .map((_, i) => {
        const length = selectedExportCableSegLengths[i].toFixed(2);
        const current = selectedExportCableCurrents[i].toFixed(2);
        const ratedCurrent = ratedCurrentValues[i].toFixed(2);

        return [length, current, ratedCurrent];
      })
      .filter(isDefined);

    const csvString = stringify([header, ...rows]);
    downloadText(csvString, "export_cable_current.csv");
  }, [
    exportCableCurrents,
    exportCableSegLengths,
    exportCableSegments,
    exportCableTypes,
    exportCables,
    usedExportCableId,
    warning,
  ]);

  return (
    <>
      <MenuItem
        name={"Download as .csv"}
        icon={<DownloadIcon />}
        onClick={() => download()}
      />
    </>
  );
};

export const ExportCableLoadingWidget = () => {
  const { park } = useDashboardContext();
  const exportCables = useRecoilValue(
    getExportCablesSelectorFamily({ parkId: park.id }),
  );

  const [usedExportCableId, setUsedExportCableId] = useState<
    string | undefined
  >(exportCables[0]?.id);

  useEffect(() => {
    if (exportCables.length > 0) {
      setUsedExportCableId(exportCables[0]?.id);
    }
  }, [exportCables]);

  return (
    <SafeCard
      title="Export cable loading"
      id="Export cable loading"
      menuItems={
        <ExportCableLoadingMenu usedExportCableId={usedExportCableId} />
      }
    >
      <ExportCableLoading
        usedExportCableId={usedExportCableId}
        setUsedExportCableId={setUsedExportCableId}
      />
    </SafeCard>
  );
};
