import { typography } from "../../../styles/typography";
import styled from "styled-components";
import { StandardBox } from "../../../styles/boxes/Boxes";
import { colors } from "../../../styles/colors";
import { Column } from "../../General/Layout";
import { IconContainer } from "./SettingsMenuItem";

export const Modal = styled(StandardBox)`
  position: fixed;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 90vw;
  max-width: 140rem;
  height: 85vh;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const HeaderContainer = styled.div`
  height: 5.2rem;
  border-bottom: 1px solid lightgrey;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem 0 1.6rem;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

export const ItemContainer = styled.div<{
  selected: boolean;
  expanded: boolean;
}>`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 1.1rem;
  min-width: 3.6rem;
  max-height: 3.6rem;
  box-sizing: border-box;

  background-color: ${(p) => (p.selected ? colors.selected : "inherit")};

  > svg {
    width: 1.4rem;
    min-width: 1.4rem;
    height: 1.4rem;
    min-height: 1.4rem;
  }

  ${({ selected }) =>
    selected &&
    `
     ${IconContainer} {
        visibility: visible !important;
        transition: all 0.1s linear;
      }
   `}

  &:hover {
    background-color: ${(p) => (p.selected ? colors.selected : colors.hover)};

    ${IconContainer} {
      visibility: visible;
      transition: all 0.1s linear;
    }
  }

  transition: all 0.1s linear;
`;

export const FirstLevelItemContainer = styled(ItemContainer)`
  width: 100%;
  margin: 0 0.5rem;
  background-color: ${(p) => (p.selected ? colors.primary : "inherit")};
  border-radius: 0.4rem;

  > p {
    color: ${(p) => (p.selected ? "#fff" : colors.primaryText)};
  }

  svg {
    path {
      stroke: ${(p) => (p.selected ? "#fff" : colors.primaryText)};
    }
  }

  &:hover {
    background-color: ${(p) => (p.selected ? colors.primary : colors.hover)};
    div {
      opacity: 1 !important;
      transition: all 0.1s linear;
    }
  }
`;

export const ItemText = styled.p<{ expanded: boolean; selected?: boolean }>`
  ${typography.regular}
  color: ${colors.primaryText};
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  opacity: ${(p) => (p.expanded ? 1 : 0)};
  transition: all 0.1s linear;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  box-sizing: border-box;
  overflow: hidden;
`;

export const MainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
  padding: 0;
`;

export const SubMenuTitle = styled(ItemText)<{
  expanded: boolean;
}>`
  ${typography.h4}

  opacity: ${(p) => (p.expanded ? 1 : 0)};
  transition: width 0.1s linear;
`;

export const NoItemsWrapper = styled.div`
  ${typography.regular}
  color: ${colors.secondaryText};
  padding: 0 1.6rem;
`;

export const SubMenuColumn = styled(Column)<{
  opagueness: number;
  expanded: boolean;
  isFirstLevel?: boolean;
  wide?: boolean;
  widthVariable?: string;
}>`
  width: ${(p) =>
    p.expanded
      ? p.isFirstLevel && !p.wide
        ? "16rem"
        : `var(${p.widthVariable}, 23rem)`
      : "4.7rem"};
  min-width: ${(p) =>
    p.expanded ? (p.isFirstLevel && !p.wide ? "16rem" : "23rem") : "4.7rem"};
  max-width: 50rem;
  height: 100%;
  position: relative;
  background-color: ${(p) =>
    p.isFirstLevel ? colors.focusBackground : "#fbfbfb"};
  box-sizing: border-box;
  ${(p) => p.isFirstLevel && `border-right: 1px solid ${colors.inputOutline};`}
  ${(p) => !p.isFirstLevel && `box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);`}
  padding-top: 1.6rem;
  transition: width 0.1s linear;
  gap: ${(p) => (p.isFirstLevel ? "0.8rem" : "1.2rem")};
`;

export const ContentWrapper = styled.div`
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SettingsFooter = styled(ContentWrapper)`
  position: absolute;
  bottom: 0;

  width: 100%;
  box-sizing: border-box;

  margin-top: auto;
  border-top: 1px solid ${colors.inputOutline};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.6rem;

  background: white;
`;

export const SettingsFooterContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const SettingsHeader = styled(ContentWrapper)`
  position: absolute;
  right: 0;
  width: auto;
  background: white;
  box-sizing: border-box;
  padding: 1.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.6rem;
  z-index: 1;
`;

export const SettingsHeaderStandard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Text = styled.p`
  ${typography.contentAndButtons}
  margin: 0;
`;

export const OpenOrCollapseContainer = styled.div<{ collapsed?: boolean }>`
  position: absolute;
  bottom: 0.6rem;
  right: 0.6rem;
  button {
    ${(p) =>
      p.collapsed
        ? `
    background-color: white;
    border: 1px solid ${colors.inputOutline};
    `
        : "background-color: transparent;"}
  }
  div {
    box-shadow: none;
  }
`;

export const TeamWillBeRemovedWrapper = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
`;

export const PopupWarning = styled(StandardBox)`
  padding: 1rem;
  position: fixed;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  z-index: 1;
  max-width: 25rem;
  cursor: default;
  color: ${colors.primaryText};
`;

export const PopupWarningTitle = styled.div`
  ${typography.h4}
`;

export const PopupWarningHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
`;

export const PopupWarningSplitHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
`;

export const PopWarningButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;
