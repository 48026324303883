import React, { useCallback, useEffect, useRef, useState } from "react";
import { ExternalDataSourceLink, Layer } from "../../../types/layers";
import layerPreviewNotAvailableImage from "../../../assets/layer-preview-not-available.png";
import { SkeletonBlock } from "../../Loading/Skeleton";
import useBooleanState from "../../../hooks/useBooleanState";
import { fetchWithToken } from "../../../services/utils";
import { isCustomLayer } from "../../LayerList/utils";
import {
  getImagePreviewUrl,
  getImageS3Url,
} from "../../../services/layerPreviewAPIService";
import styled from "styled-components";
import RefreshImageDataCleaning from "./RefreshImageDataCleaning";
import AddManualPreviewImageDataCleaning from "./AddManualPreviewImageDataCleaning";

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const sharedImageStyle: React.CSSProperties = {
  width: "18rem",
  height: "18rem",
  borderRadius: "4px",
  flexShrink: 0,
  flexGrow: 0,
};

// 1. Try to load image from s3
// 2. Make request to lambda that will put image in s3
// 3. Try to load image from s3 again
// 4. If fail, setIsError(true)
const BucketOrLambdaImage = ({
  layer,
  sourceLink,
  style,
  setIsLoading,
  setIsError,
  clickToEnlarge,
  queryParam,
}: {
  layer: Layer;
  sourceLink: ExternalDataSourceLink;
  style?: React.CSSProperties;
  clickToEnlarge: boolean;
  queryParam: string;
  setIsLoading(isLoading: boolean): void;
  setIsError(isError: boolean): void;
}) => {
  const [imgUrl, setImgUrl] = useState(getImageS3Url(layer, sourceLink));
  const [hasS3Issue, setHasS3Issue] = useState(false);
  const [showLargeImage, toggleShowLargeImage] = useBooleanState(false);
  const lambdaUrl = getImagePreviewUrl(layer, sourceLink);
  const abortController = useRef<AbortController>(new AbortController());

  const onS3ImageError = useCallback(async () => {
    if (!lambdaUrl) {
      setIsError(true);
      return;
    }

    setHasS3Issue(true);
    setImgUrl("");

    try {
      const response = await fetchWithToken(lambdaUrl, {
        method: "GET",
        signal: abortController.current.signal,
      });
      if (!response.ok) {
        setIsError(true);
        return;
      }
      const json = (await response.json()) as any;
      setImgUrl(json?.path ?? undefined);
    } catch (err) {
      //
    }
  }, [lambdaUrl, setIsError]);

  useEffect(() => {
    const currAbortController = abortController.current;
    return () => {
      currAbortController?.abort();
    };
  }, []);

  // If the layerType is not supported, or the lambda returned something weird
  useEffect(() => {
    if (typeof imgUrl === "undefined" || !lambdaUrl) {
      setIsError(true);
    }
  }, [imgUrl, lambdaUrl, setIsError]);

  useEffect(() => {
    setImgUrl(getImageS3Url(layer, sourceLink));
    setHasS3Issue(false);
  }, [lambdaUrl, layer, setIsError, sourceLink]);

  return (
    <img
      src={`${imgUrl}${queryParam}`}
      alt="Layer preview"
      style={{
        ...style,
        ...(showLargeImage && {
          width: "50rem",
          height: "50rem",
        }),
      }}
      onLoad={() => {
        setIsLoading(false);
      }}
      onError={!hasS3Issue ? onS3ImageError : undefined}
      onClick={clickToEnlarge ? toggleShowLargeImage : undefined}
      referrerPolicy="origin"
    />
  );
};

const LayerPreviewImageV2 = ({
  layer,
  style,
  clickToEnlarge,
}: {
  layer: Layer;
  style?: React.CSSProperties;
  clickToEnlarge: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const sourceLink = !isCustomLayer(layer) ? layer.sourceLink : undefined;

  const [queryParam, setQueryParam] = useState("");

  const afterRefresh = useCallback(() => {
    setQueryParam(`?${new Date().getTime()}`);
    setIsError(false);
  }, [setQueryParam, setIsError]);

  useEffect(() => {
    setIsError(false);
    setIsLoading(true);
  }, [layer]);

  if (isError || !sourceLink) {
    return (
      <ImageWrapper>
        <img
          src={layerPreviewNotAvailableImage}
          style={{ ...sharedImageStyle, width: "unset", ...style }}
          alt="Layer preview not available"
        />
        <RefreshImageDataCleaning layer={layer} afterRefresh={afterRefresh} />
        <AddManualPreviewImageDataCleaning
          layer={layer}
          afterRefresh={afterRefresh}
        />
      </ImageWrapper>
    );
  }

  return (
    <ImageWrapper>
      {isLoading && <SkeletonBlock style={{ ...sharedImageStyle, ...style }} />}
      <BucketOrLambdaImage
        style={{
          ...sharedImageStyle,
          cursor: "pointer",
          ...(isLoading && {
            display: "none",
          }),
          ...style,
        }}
        layer={layer}
        sourceLink={sourceLink}
        setIsLoading={setIsLoading}
        setIsError={setIsError}
        clickToEnlarge={clickToEnlarge}
        queryParam={queryParam}
      />
      <RefreshImageDataCleaning layer={layer} afterRefresh={afterRefresh} />
      <AddManualPreviewImageDataCleaning
        layer={layer}
        afterRefresh={afterRefresh}
      />
    </ImageWrapper>
  );
};

export default LayerPreviewImageV2;
