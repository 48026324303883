import { useEffect, useMemo } from "react";
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";
import {
  OSM_BUILDING_TILE_ZOOM,
  osmBuildingTileSelector,
  VIEW_MODE,
  viewFromShoreOSMBuildingTilesRequestedAtom,
  viewOrigoSelector,
  viewPositionAtom,
  viewProj4StringAtom,
  viewViewModeAtom,
} from "../../../state/viewToPark";
import { wgs84ToProjected } from "utils/proj4";
import { fastMax, funcOnCoords } from "utils/utils";
import { DIVISION_FACTOR } from "../constants";
import {
  Mesh,
  MeshStandardMaterial,
  Shape,
  ExtrudeGeometry,
  MathUtils,
  Shader,
  DoubleSide,
  Vector3,
} from "three";
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils.js";

import { ThreeCore } from "./useCreateThreeCore";
import { disposeObject } from "../utils";
import {
  injectCurvatureIntoVertexShader,
  sampleTerrainMeshHeight,
} from "./utils";
import { getXTileNumber, getYTileNumber } from "hooks/mouseSampler";
import { multiFeatureToFeatures } from "utils/geojson/utils";
import { ProjectFeature } from "types/feature";
import { Feature, MultiPolygon, Polygon } from "geojson";
import { terrainPatchesAddedToSceneRefresherAtom } from "./useDynamicTerrain";

const nrOfBufferTiles = 4;

export const CameraOSMBuildings = ({
  threeCore,
  terrainPatches,
}: {
  threeCore: ThreeCore | undefined;
  terrainPatches: Mesh[];
}) => {
  const [osmBuildingTilesRequested, setOsmBuildingTilesRequested] =
    useRecoilState(viewFromShoreOSMBuildingTilesRequestedAtom);
  const viewPosition = useRecoilValue(viewPositionAtom);

  useEffect(() => {
    return () => {
      setOsmBuildingTilesRequested([]);
    };
  }, [setOsmBuildingTilesRequested]);

  useEffect(() => {
    if (!viewPosition) return;

    const osmBuildingTilesRequestedCopy = [...osmBuildingTilesRequested];
    const xCenter = getXTileNumber(viewPosition.lng, OSM_BUILDING_TILE_ZOOM);
    const yCenter = getYTileNumber(viewPosition.lat, OSM_BUILDING_TILE_ZOOM);

    for (
      let x = xCenter - nrOfBufferTiles;
      x <= xCenter + nrOfBufferTiles;
      x++
    ) {
      for (
        let y = yCenter - nrOfBufferTiles;
        y <= yCenter + nrOfBufferTiles;
        y++
      ) {
        if (
          osmBuildingTilesRequestedCopy.find(
            ([xTile, yTile]) => xTile === x && yTile === y,
          )
        )
          continue;
        osmBuildingTilesRequestedCopy.push([x, y]);
      }
    }

    if (
      osmBuildingTilesRequestedCopy.length !== osmBuildingTilesRequested.length
    )
      setOsmBuildingTilesRequested(osmBuildingTilesRequestedCopy);
  }, [viewPosition, osmBuildingTilesRequested, setOsmBuildingTilesRequested]);

  return (
    <>
      {osmBuildingTilesRequested.map(([x, y]) => (
        <CameraOSMBuildingTile
          key={`${x}-${y}`}
          threeCore={threeCore}
          terrainPatches={terrainPatches}
          tile={{ x, y }}
        />
      ))}
    </>
  );
};

export const CameraOSMBuildingTile = ({
  threeCore,
  tile,
  terrainPatches,
}: {
  threeCore: ThreeCore | undefined;
  tile: { x: number; y: number };
  terrainPatches: Mesh[];
}) => {
  const osmBuildingsMaybe = useRecoilValueLoadable(
    osmBuildingTileSelector(tile),
  );
  const proj4String = useRecoilValue(viewProj4StringAtom);
  const viewMode = useRecoilValue(viewViewModeAtom);
  const origo = useRecoilValue(viewOrigoSelector);
  const terrainPatchesAddedToSceneRefresher = useRecoilValue(
    terrainPatchesAddedToSceneRefresherAtom,
  );

  const osmBuildingsProjectedScaled = useMemo(() => {
    if (!proj4String || osmBuildingsMaybe.state !== "hasValue" || !origo)
      return;

    const osmBuildings = osmBuildingsMaybe.getValue();

    return osmBuildings.map((feature) => ({
      ...feature,
      geometry: {
        ...feature.geometry,
        coordinates: funcOnCoords(
          feature.geometry.coordinates,
          (coordinate) => {
            const projectedCoords = wgs84ToProjected(coordinate, proj4String);
            return [
              (projectedCoords[0] - origo[0]) / DIVISION_FACTOR,
              (projectedCoords[1] - origo[1]) / DIVISION_FACTOR,
            ];
          },
        ),
      },
    })) as Feature<MultiPolygon>[];
  }, [osmBuildingsMaybe, proj4String, origo]);

  const osmBuildingsMesh = useMemo(() => {
    if (
      !osmBuildingsProjectedScaled ||
      osmBuildingsProjectedScaled.length === 0 ||
      terrainPatchesAddedToSceneRefresher == null
    )
      return;

    const extrudeFeatures = osmBuildingsProjectedScaled
      .filter((feature) => feature.properties)
      .filter((feature) => feature?.properties?.extrude === "true");

    const extrudeSingleFeatures = extrudeFeatures.flatMap((f) =>
      multiFeatureToFeatures(f as ProjectFeature),
    ) as Feature<Polygon>[];

    const geometries = extrudeSingleFeatures.flatMap((feature) =>
      feature.geometry.coordinates.map((shapeCoords) => {
        const groundLevel =
          shapeCoords
            .map((coord) => {
              return fastMax(
                terrainPatches
                  .filter((patch) =>
                    patch.geometry.boundingBox?.containsPoint(
                      new Vector3(
                        coord[0],
                        coord[1],
                        (patch.geometry.boundingBox.max.z +
                          patch.geometry.boundingBox.min.z) /
                          2,
                      ),
                    ),
                  )
                  .map(
                    (patchToSampleFrom) =>
                      sampleTerrainMeshHeight(coord, patchToSampleFrom) ?? 0,
                  ),
                0,
              );
            })
            .reduce((acc, curr) => acc + curr, 0) / shapeCoords.length;
        const shape = new Shape();
        shape.moveTo(shapeCoords[0][0], shapeCoords[0][1]);

        shapeCoords
          .slice(1)
          .forEach((coord) => shape.lineTo(coord[0], coord[1]));

        const geometry = new ExtrudeGeometry(shape, {
          depth:
            ((feature?.properties?.height ?? 3) + groundLevel) /
            DIVISION_FACTOR,
          bevelEnabled: false,
        });

        return geometry;
      }),
    );

    const mergedGeometry =
      BufferGeometryUtils.mergeBufferGeometries(geometries);
    const materialCurvature = new MeshStandardMaterial({
      transparent: false,
      color: 0xffffff,
      side: DoubleSide,
    });

    materialCurvature.onBeforeCompile = (shader: Shader) => {
      shader.vertexShader = injectCurvatureIntoVertexShader(
        shader.vertexShader,
      );
    };

    const mesh = new Mesh(mergedGeometry, materialCurvature);
    mesh.rotateX(MathUtils.degToRad(-90));
    mesh.rotateZ(MathUtils.degToRad(-180));

    return mesh;
  }, [
    osmBuildingsProjectedScaled,
    terrainPatches,
    terrainPatchesAddedToSceneRefresher,
  ]);

  useEffect(() => {
    if (!threeCore || !osmBuildingsMesh) return;
    const { scene } = threeCore;
    scene.add(osmBuildingsMesh);
    return () => {
      scene.remove(osmBuildingsMesh);
      disposeObject(osmBuildingsMesh);
    };
  }, [threeCore, osmBuildingsMesh]);

  useEffect(() => {
    if (!osmBuildingsMesh) return;

    if (viewMode === VIEW_MODE.NATURAL_MODE) {
      osmBuildingsMesh.material.wireframe = false;
      osmBuildingsMesh.material.transparent = true;
    } else if (viewMode === VIEW_MODE.WIRE_FRAME_MODE) {
      osmBuildingsMesh.material.wireframe = true;
      osmBuildingsMesh.material.transparent = false;
    }
  }, [osmBuildingsMesh, viewMode]);

  return null;
};
