import { AddNewDataModalTypes } from "../components/LayerList/AddNewSourceModal/AddNewSourceToProjectModal";
import { CUSTOM_LAYER_TYPE } from "../constants/customLayers";
import { z } from "zod";

export enum SourceTypes {
  wms = "WMS",
  wmts = "WMTS",
  wfs = "WFS",
  arcgis_rest_api = "Arcgis rest api",
}

export enum SourceTypesLayer {
  wms = "wms",
  wmts = "wmts",
  wfs = "wfs",
  arcgis = "arcgis",
  tilejson = "tileJSON",
  hosted = "hosted",
  xyz = "xyz",
}

export enum LayerType {
  wms = "wms",
  wmts = "wmts",
  Line = "line",
  Point = "point",
  Polygon = "polygon",
  Circle = "circle",
  FeatureCollection = "featureCollection",
  XYZ = "xyz",
  Raster = "raster",
}

export const _LayerSettingsGlobal = z.object({
  tags: z.array(z.string()).optional(),
});
export type LayerSettingsGlobal = z.infer<typeof _LayerSettingsGlobal>;

export const _CustomLayer = z.object({
  id: z.string(),
  author: z.string().nullish(),
  name: z.string(),
  url: z.string(),
  sourceType: z.nativeEnum(CUSTOM_LAYER_TYPE),
  source: z.undefined(),
  sourceLayerId: z.string(),
  tags: z.string().array(),
  tokenRequired: z.literal(true),
  bbox: z.array(z.number()).optional(),
  dateAdded: z.number().nullish(),
  dateDeleted: z.null().default(null),
  description: z.string().nullable().optional(),
  type: z
    .literal(LayerType.FeatureCollection)
    .default(LayerType.FeatureCollection),
  // path: z.string(),
  // created_at: z.number().nullish(),
});

export type CustomLayer = z.infer<typeof _CustomLayer>;

export const _CustomLibraryLayer = _CustomLayer.extend({
  sourceType: z.literal(CUSTOM_LAYER_TYPE.CUSTOM_LIBRARY_LAYER_SOURCE_TYPE),
});

export type CustomLibraryLayer = z.infer<typeof _CustomLibraryLayer>;

const _ExternalDataSourceLinkLayer = z.object({
  id: z.string(),
  name: z.string(),
  nameEnglish: z.string().optional(),
  bbox: z.number().array(),
  dateAdded: z.string().nullish(),
  dateDeleted: z.string().nullish(),
  sourceId: z.string(),
  sourceType: z.nativeEnum(SourceTypesLayer),
  abstract: z.string().nullish(),
  abstractEnglish: z.string().optional(),
  type: z.nativeEnum(LayerType),
  sourceLayerId: z.string().or(z.number()),
  isHidden: z.boolean().optional(),
  tags: z.string().array().optional(),
  deprecated: z.string().optional(),
});

const _ExternalDataSourceLinkLayerArcgis = _ExternalDataSourceLinkLayer.extend({
  sourceType: z.literal(SourceTypesLayer.arcgis),
});

const _ExternalDataSourceLinkLayerTileJSON =
  _ExternalDataSourceLinkLayer.extend({
    sourceType: z.literal(SourceTypesLayer.tilejson),
  });

const _ExternalDataSourceLinkLayerHosted = _ExternalDataSourceLinkLayer.extend({
  sourceType: z.literal(SourceTypesLayer.hosted),
});

const _ExternalDataSourceLinkLayerXYZ = _ExternalDataSourceLinkLayer.extend({
  sourceType: z.literal(SourceTypesLayer.xyz),
});

const _ExternalDataSourceLinkLayerWFS = _ExternalDataSourceLinkLayer.extend({
  outputValue: z.string(),
  sourceType: z.literal(SourceTypesLayer.wfs),
});

export const _ExternalDataSourceLinkLayerWMS =
  _ExternalDataSourceLinkLayer.extend({
    sourceType: z.literal(SourceTypesLayer.wms),
  });

export const _ExternalDataSourceLinkLayerWMTS =
  _ExternalDataSourceLinkLayer.extend({
    sourceType: z.literal(SourceTypesLayer.wmts),
    isResourceOriented: z.boolean(),
  });

const _ExternalDataSourceLink = z.object({
  id: z.string(),
  url: z.string(),
  urlWhereFound: z.string().nullish(),
  skipProxy: z.boolean().nullish(),
  licenseType: z.string().nullish(),
  licenseUrl: z.string().nullish(),
  attribution: z.string().nullish(),
  layerSettingsGlobal: _LayerSettingsGlobal.nullish(),
  layers: _ExternalDataSourceLinkLayer.array().optional(),
  abstract: z.string().nullish(),
  abstractEnglish: z.string().optional(),
});

export type ExternalDataSourceLink = z.infer<typeof _ExternalDataSourceLink>;
export type ExternalDataSourceLinkLayer = z.infer<
  typeof _ExternalDataSourceLinkLayer
>;

const _ExternalDataSourceLinkWMS = _ExternalDataSourceLink.extend({
  layers: _ExternalDataSourceLinkLayerWMS.array().optional(),
});
const _ExternalDataSourceLinkWMTS = _ExternalDataSourceLink.extend({
  layers: _ExternalDataSourceLinkLayerWMTS.array().optional(),
});
const _ExternalDataSourceLinkWFS = _ExternalDataSourceLink.extend({
  layers: _ExternalDataSourceLinkLayerWFS.array().optional(),
});
const _ExternalDataSourceLinkARCGIS = _ExternalDataSourceLink.extend({
  layers: _ExternalDataSourceLinkLayerArcgis.array().optional(),
});
const _ExternalDataSourceLinkTileJSON = _ExternalDataSourceLink.extend({
  layers: _ExternalDataSourceLinkLayerTileJSON.array().optional(),
});
const _ExternalDataSourceLinkTileHosted = _ExternalDataSourceLink.extend({
  bbox: z.number().array(),
  layers: _ExternalDataSourceLinkLayerHosted.array().optional(),
});
const _ExternalDataSourceLinkTileXYZ = _ExternalDataSourceLink.extend({
  bbox: z.number().array(),
  layers: _ExternalDataSourceLinkLayerXYZ.array().optional(),
});

export const _ExternalDataSource = z.object({
  id: z.string(),
  alternativeNames: z.string().array().nullish(),
  countries: z.string().array().nullish(),
  name: z.string(),
  originalName: z.string().nullish(),
  filteredLayers: z.string().array().nullish(),
  private: z.boolean().optional(),
  wms: _ExternalDataSourceLinkWMS.array(),
  wmts: _ExternalDataSourceLinkWMTS.array(),
  wfs: _ExternalDataSourceLinkWFS.array(),
  arcgis: _ExternalDataSourceLinkARCGIS.array(),
  tilejson: _ExternalDataSourceLinkTileJSON.array(),
  hosted: _ExternalDataSourceLinkTileHosted.array(),
  xyz: _ExternalDataSourceLinkTileXYZ.array(),
});
export type ExternalDataSource = z.infer<typeof _ExternalDataSource>;

export const _PrivateDataSource = z.object({
  id: z.string(),
  private: z.boolean().default(true),
  name: z.string(),
  wms: _ExternalDataSourceLinkWMS.array(),
  wmts: _ExternalDataSourceLinkWMTS.array(),
  wfs: _ExternalDataSourceLinkWFS.array(),
  arcgis: _ExternalDataSourceLinkARCGIS.array(),
  tilejson: _ExternalDataSourceLinkTileJSON.array(),
  hosted: _ExternalDataSourceLinkTileHosted.array(),
  xyz: _ExternalDataSourceLinkTileXYZ.array(),
});
export type PrivateDataSource = z.infer<typeof _PrivateDataSource>;

export const _ExternalDataSourceLinkLayerWithSource =
  _ExternalDataSourceLinkLayer.extend({
    source: _ExternalDataSource,
    sourceLink: _ExternalDataSourceLink,
  });

export const _ExternalDataSourceLinkLayerWithSourceWMS =
  _ExternalDataSourceLinkLayerWithSource.extend({
    sourceType: z.literal(SourceTypesLayer.wms),
    sourceLayerId: z.string(),
  });

export const _ExternalDataSourceLinkLayerWithSourceWMTS =
  _ExternalDataSourceLinkLayerWithSource.extend({
    sourceType: z.literal(SourceTypesLayer.wmts),
    sourceLayerId: z.string(),
    isResourceOriented: z.boolean(),
  });

export const _ExternalDataSourceLinkLayerWithSourceWFS =
  _ExternalDataSourceLinkLayerWithSource.extend({
    sourceType: z.literal(SourceTypesLayer.wfs),
    sourceLayerId: z.string(),
    outputValue: z.string(),
  });

export const _ExternalDataSourceLinkLayerWithSourceArcgis =
  _ExternalDataSourceLinkLayerWithSource.extend({
    sourceType: z.literal(SourceTypesLayer.arcgis),
    sourceLayerId: z.number(),
  });

export const _ExternalDataSourceLinkLayerWithSourceHosted =
  _ExternalDataSourceLinkLayerWithSource.extend({
    sourceType: z.literal(SourceTypesLayer.hosted),
    sourceLayerId: z.string(),
  });

export const _ExternalDataSourceLinkLayerWithSourceXYZ =
  _ExternalDataSourceLinkLayerWithSource.extend({
    sourceType: z.literal(SourceTypesLayer.xyz),
  });

export const _ExternalDataSourceLinkLayerWithSourceTileJSON =
  _ExternalDataSourceLinkLayerWithSource.extend({
    sourceType: z.literal(SourceTypesLayer.tilejson),
    sourceLayerId: z.string(),
  });

export type ExternalDataSourceLinkLayerWithSourceWMS = z.infer<
  typeof _ExternalDataSourceLinkLayerWithSourceWMS
>;
export type ExternalDataSourceLinkLayerWithSourceWMTS = z.infer<
  typeof _ExternalDataSourceLinkLayerWithSourceWMTS
>;
export type ExternalDataSourceLinkLayerWithSourceXYZ = z.infer<
  typeof _ExternalDataSourceLinkLayerWithSourceXYZ
>;
export type ExternalDataSourceLinkLayerWithSourceWFS = z.infer<
  typeof _ExternalDataSourceLinkLayerWithSourceWFS
>;
export type ExternalDataSourceLinkLayerWithSourceArcgis = z.infer<
  typeof _ExternalDataSourceLinkLayerWithSourceArcgis
>;
export type ExternalDataSourceLinkLayerWithSourceHosted = z.infer<
  typeof _ExternalDataSourceLinkLayerWithSourceHosted
>;
export type ExternalDataSourceLinkLayerWithSourceTileJSON = z.infer<
  typeof _ExternalDataSourceLinkLayerWithSourceTileJSON
>;

export type ExternalLayer =
  | ExternalDataSourceLinkLayerWithSourceWMTS
  | ExternalDataSourceLinkLayerWithSourceWMS
  | ExternalDataSourceLinkLayerWithSourceWFS
  | ExternalDataSourceLinkLayerWithSourceArcgis
  | ExternalDataSourceLinkLayerWithSourceHosted
  | ExternalDataSourceLinkLayerWithSourceXYZ
  | ExternalDataSourceLinkLayerWithSourceTileJSON;
const _ExternalLayer = z.union([
  _ExternalDataSourceLinkLayerWithSourceWMTS,
  _ExternalDataSourceLinkLayerWithSourceWMS,
  _ExternalDataSourceLinkLayerWithSourceWFS,
  _ExternalDataSourceLinkLayerWithSourceArcgis,
  _ExternalDataSourceLinkLayerWithSourceHosted,
  _ExternalDataSourceLinkLayerWithSourceXYZ,
  _ExternalDataSourceLinkLayerWithSourceTileJSON,
]);

export const _Layer = z.union([_CustomLayer, _ExternalLayer]);
export type Layer = CustomLayer | ExternalLayer;
export type LayerWithSearchRelevance = Layer & {
  searchRelevance?: number;
};

export const _MetadataType = z.object({
  id: z.string(),
  bbox: z.number().array().optional(),
  source: z.string(),
  name: z.string(),
  type: z.string(),
  alias: z.string().optional(),
  paint: z.any().nullish(),
  tokenRequired: z.boolean().optional(),
  sourceType: z.nativeEnum(SourceTypesLayer),
  tags: z.string().array(),
});

const _SourceLegalInformation = z.object({
  urlWhereFound: z.string().optional(),
  licenseType: z.string().optional(),
  licenseUrl: z.string().optional(),
  attribution: z.string().optional(),
});

export const _WmtsMetadata = _MetadataType.extend({
  bbox: z.number().array().optional(),
  layer: z.string(),
  url: z.string(),
  abstract: z.string().optional(),
  keywords: z.string().array(),
  metaDataUrl: z.string().optional(),
  sourceType: z.literal(SourceTypesLayer.wmts),
  path: z.string(),
  originalUrl: z.string(),
  isResourceOriented: z.boolean(),
});
export type WmtsMetadata = z.infer<typeof _WmtsMetadata>;

export const _WmsMetadata = _MetadataType.extend({
  bbox: z.number().array().optional(),
  layer: z.string(),
  url: z.string(),
  abstract: z.string().optional(),
  keywords: z.string().array(),
  metaDataUrl: z.string().optional(),
  sourceType: z.literal(SourceTypesLayer.wms),
  path: z.string(),
  originalUrl: z.string(),
});
export type WmsMetadata = z.infer<typeof _WmsMetadata>;

export const _WfsMetadata = _MetadataType.extend({
  bbox: z.number().array(),
  typeName: z.string(),
  sourceType: z.literal(SourceTypesLayer.wfs),
  path: z.string(),
  theme: z.string().optional(),
  originalUrl: z.string(),
  abstract: z.string().optional(),
  keywords: z.string().array(),
  sourceLayerId: z.string(),
  outputValue: z.string(),
});
export type WfsMetadata = z.infer<typeof _WfsMetadata>;

export const _ArcgisFullLayersInfoType = _MetadataType.extend({
  bbox: z.number().array(),
  sourceType: z.literal(SourceTypesLayer.arcgis),
  path: z.string(),
  internalId: z.number(),
  originalUrl: z.string(),
  editingInfo: z
    .object({
      dataLastEditDate: z.number(),
      lastEditDate: z.number(),
      schemaLastEditDate: z.number(),
    })
    .optional(),
});
export type ArcgisFullLayersInfoType = z.infer<
  typeof _ArcgisFullLayersInfoType
>;

export const _WmtsSubLayer = z.object({
  featureTypeLayerName: z.string().optional(),
  featureTypeName: z.string().optional(),
  responseType: z.string().optional(),
  theme: z.string().optional(),
  type: z.string().optional(),
  icon: z.string().optional(),
  alias: z.string().optional(),
});

export const _WmtsLayer = z
  .object({
    source: z.string(),
    sourceType: z.literal(SourceTypesLayer.wmts),
    originalName: z.string().optional(),
    alternativeNames: z.string().array().nullish(),
    wmts_url: z.string(),
    layers: _WmtsSubLayer.array().optional(),
    countries: z.array(z.string()).optional(),
    layerSettingsGlobal: z.record(z.string(), _LayerSettingsGlobal).optional(),
    hide: z.boolean().optional(),
    filteredLayers: z.string().array().optional(),
    skipProxy: z.boolean().optional(),
    private: z.boolean().optional(),
  })
  .and(_SourceLegalInformation);
export type WmtsLayer = z.infer<typeof _WmtsLayer>;

export const _WmsSubLayer = z.object({
  featureTypeLayerName: z.string().optional(),
  featureTypeName: z.string().optional(),
  responseType: z.string().optional(),
  theme: z.string().optional(),
  type: z.string().optional(),
  icon: z.string().optional(),
  alias: z.string().optional(),
});

export const _WmsLayer = z
  .object({
    source: z.string(),
    sourceType: z.literal(SourceTypesLayer.wms),
    originalName: z.string().optional(),
    alternativeNames: z.string().array().nullish(),
    wms_url: z.string(),
    layers: _WmsSubLayer.array().optional(),
    countries: z.array(z.string()).optional(),
    layerSettingsGlobal: z.record(z.string(), _LayerSettingsGlobal).optional(),
    hide: z.boolean().optional(),
    filteredLayers: z.string().array().optional(),
    skipProxy: z.boolean().optional(),
    private: z.boolean().optional(),
  })
  .and(_SourceLegalInformation);
export type WmsLayer = z.infer<typeof _WmsLayer>;

export const _PrivateGISLayer = z.object({
  source: z.string(),
  url: z.string(),
  type: z.nativeEnum(SourceTypes),
});
export type PrivateGISLayer = z.infer<typeof _PrivateGISLayer>;

export const _WmsSourceEntries = z.object({
  source: z.string(),
  layersInfo: _WmsMetadata.array(),
  fetchSucceeded: z.boolean(),
  alternativeNames: z.set(z.string()),
  originalName: z.string().optional(),
  abstract: z.string().optional(),
  keywords: z.string().array(),
  sourceType: z.literal(SourceTypes.wms),
  url: z.string(),
});
export type WmsSourceEntries = z.infer<typeof _WmsSourceEntries>;

export const _WmtsSourceEntries = z.object({
  source: z.string(),
  layersInfo: _WmtsMetadata.array(),
  fetchSucceeded: z.boolean(),
  alternativeNames: z.set(z.string()),
  originalName: z.string().optional(),
  abstract: z.string().optional(),
  keywords: z.string().array(),
  sourceType: z.literal(SourceTypes.wmts),
  url: z.string(),
});
export type WmtsSourceEntries = z.infer<typeof _WmtsSourceEntries>;

export const _WfsSourceEntries = z.object({
  source: z.string(),
  layersInfo: _WfsMetadata.array(),
  fetchSucceeded: z.boolean(),
  alternativeNames: z.set(z.string()),
  keywords: z.string().array(),
  abstract: z.string().optional(),
  sourceType: z.literal(SourceTypes.wfs),
  url: z.string(),
});
export type WfsSourceEntries = z.infer<typeof _WfsSourceEntries>;

export const _ArcgisSourceEntries = z.object({
  source: z.string(),
  layersInfo: _ArcgisFullLayersInfoType.array(),
  fetchSucceeded: z.boolean(),
  alternativeNames: z.set(z.string()),
  abstract: z.string().optional(),
  sourceType: z.literal(SourceTypes.arcgis_rest_api),
  url: z.string(),
});
export type ArcgisSourceEntries = z.infer<typeof _ArcgisSourceEntries>;

export const _ArcgisSubLayer = z.object({
  id: z.string().optional(),
  paint: z
    .object({
      "fill-color": z.string(),
    })
    .optional(),
  theme: z.string().optional(),
  type: z.string().optional(),
  icon: z.string().optional(),
  alias: z.string().optional(),
});

export const _ArcgisLayer = z
  .object({
    arcgis_rest_url: z.string(),
    source: z.string(),
    sourceType: z.literal(SourceTypesLayer.arcgis),
    originalName: z.string().optional(),
    alternativeNames: z.string().array().nullish(),
    filteredLayers: z.number().array().optional(),
    countries: z.array(z.string()).optional(),
    layers: _ArcgisSubLayer.array().optional(),
    layerSettingsGlobal: z.record(z.string(), _LayerSettingsGlobal).optional(),
    skipProxy: z.boolean().optional(),
    private: z.boolean().optional(),
  })
  .and(_SourceLegalInformation);
export type ArqisLayer = z.infer<typeof _ArcgisLayer>;

export const _ExtentType = z.object({
  xmin: z
    .number()
    .or(z.string())
    .transform((n) => (typeof n === "number" ? n : parseFloat(n))),
  xmax: z
    .number()
    .or(z.string())
    .transform((n) => (typeof n === "number" ? n : parseFloat(n))),
  ymin: z
    .number()
    .or(z.string())
    .transform((n) => (typeof n === "number" ? n : parseFloat(n))),
  ymax: z
    .number()
    .or(z.string())
    .transform((n) => (typeof n === "number" ? n : parseFloat(n))),
  spatialReference: z.object({
    wkid: z.number(),
    latestWkid: z.number(),
  }),
});

export const _ArcgisMetadataType = z.object({
  type: z.string(),
  extent: _ExtentType,
  fullExtent: _ExtentType.optional(),
  geometryType: z.string().nullable(),
  name: z.string(),
  id: z.number(),
  abstract: z.string().optional(),
  parentLayer: z.any().nullable(),
  editingInfo: z
    .object({
      dataLastEditDate: z.number(),
      lastEditDate: z.number(),
      schemaLastEditDate: z.number(),
    })
    .optional(),
});
export type ArcgisMetadataType = z.infer<typeof _ArcgisMetadataType>;

export const _WfsSubLayer = z.object({
  featureTypeLayerName: z.string().optional(),
  featureTypeName: z.string().optional(),
  responseType: z.string().optional(),
  theme: z.string().optional(),
  type: z.string().optional(),
  icon: z.string().optional(),
  alias: z.string().optional(),
});

export const _WfsLayer = z
  .object({
    source: z.string(),
    sourceType: z.literal(SourceTypesLayer.wfs),
    originalName: z.string().optional(),
    alternativeNames: z.string().array().nullish(),
    wfs_url: z.string(),
    countries: z.array(z.string()).optional(),
    layers: _WfsSubLayer.array().optional(),
    layerSettingsGlobal: z.record(z.string(), _LayerSettingsGlobal).optional(),
    hide: z.boolean().optional(),
    filteredLayers: z.string().array().optional(),
    path: z.string().optional(),
    skipProxy: z.boolean().optional(),
    private: z.boolean().optional(),
  })
  .and(_SourceLegalInformation);
export type WfsLayer = z.infer<typeof _WfsLayer>;

export const _TileJSONLayer = z.object({
  url: z.string(),
  source: z.string(),
  layer_id_to_type: z.record(z.string()),
});
export type TileJSONLayer = z.infer<typeof _TileJSONLayer>;

export const _TileJSONMetadata = z.object({
  bounds: z.tuple([z.number(), z.number(), z.number(), z.number()]),
  vector_layers: z
    .object({
      id: z.string(),
      description: z.string(),
    })
    .array(),
  tiles: z.string().array().optional(),
});
export type TileJSONMetadata = z.infer<typeof _TileJSONMetadata>;

export type CustomDataEntry = {
  modalType?: AddNewDataModalTypes;
  urlWhereFound?: string;
  licenseType?: string;
  attribution?: string;
  licenseUrl?: string;
  alternativeNames?: string[];
  type: SourceTypes;
  name: string;
  url: string;
};
