import styled from "styled-components";
import { DEFAULT_TURBINES, TurbineType } from "../../types/turbines";

import { useRecoilValue } from "recoil";
import { allSimpleTurbineTypesSelector } from "../../state/turbines";
import {
  ErrorBoundaryWarningTriangle,
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "../ErrorBoundaries/ErrorBoundaryLocal";
import DefaultTurbineSettingsView from "./TurbineSettings/DefaultTurbineSettingsView";
import SimpleTurbineSettingsView from "./TurbineSettings/SimpleTurbineSettingsView";
import {
  TurbineSettingAdvancedLibraryManage,
  TurbineSettingAdvancedProjectAdmin,
} from "./TurbineSettings/TurbineSettingCustomAdmin";

export const ColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  height: 100%;
  width: 100%;
`;

const TurbineSettingsWrapper = ErrorBoundaryWrapper(
  ({
    turbineId,
    onSave,
    duplicate,
    nodeId,
    isLibraryTurbine,
    accessToAdvancedTurbine,
  }: {
    turbineId: string;
    onSave?: (turbine: TurbineType) => Promise<any>;
    duplicate?: (turbineId: string) => void;
    nodeId: string;
    isLibraryTurbine: boolean;
    accessToAdvancedTurbine: boolean;
  }) => {
    const simpleTurbineTypes = useRecoilValue(allSimpleTurbineTypesSelector);

    const defaultTurbine = DEFAULT_TURBINES.find((t) => t.id === turbineId);
    if (defaultTurbine)
      return (
        <DefaultTurbineSettingsView
          turbine={defaultTurbine}
          duplicate={duplicate}
        />
      );
    else if (isLibraryTurbine && accessToAdvancedTurbine)
      return (
        <TurbineSettingAdvancedLibraryManage
          turbineId={turbineId}
          nodeId={nodeId}
        />
      );
    else if (accessToAdvancedTurbine)
      return (
        <TurbineSettingAdvancedProjectAdmin
          turbineId={turbineId}
          onSave={onSave}
          nodeId={nodeId}
        />
      );
    else {
      const turbine = simpleTurbineTypes.find((t) => t.id === turbineId);
      if (!turbine) return null;
      return <SimpleTurbineSettingsView turbine={turbine} />;
    }
  },
  () => {
    return (
      <>
        <FatalErrorBoundaryWrapper />
        <ErrorBoundaryWarningTriangle />
      </>
    );
  },
  ScreamOnError,
);

export default TurbineSettingsWrapper;
