/// <reference types="vite-plugin-svgr/client" />
import DownloadIcon from "@icons/24/Download.svg?react";
import {
  ProdId,
  getCapacity,
  getConfiguration,
  getElectricalPowerBins,
  getElectricalPowerBinsProbabilities,
  getInterArrayLosses,
  getStoppedReason,
  getStoppedReasonFromAnalysis,
} from "components/ProductionV2/state";
import {
  AnalysisStoppedTypes,
  analysisStoppedText,
} from "components/ProductionV2/types";
import { stringify } from "csv-stringify/sync";
import Plotly from "plotly.js-dist-min";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { colors } from "styles/colors";
import { isDefined } from "utils/predicates";
import { useToast } from "../../../hooks/useToast";
import { downloadText, fastMax, undefMap } from "../../../utils/utils";
import { MenuItem } from "../../General/Menu";
import { useDashboardContext } from "../Dashboard";
import { CenterContainer, LoadingState, SafeCard } from "./Base";
import {
  InvalidTypes,
  InvalidTypesDisplayText,
  InvalidTypesStateType,
} from "types/invalidTypes";
import { getInvalidCableTypeCablesInParkandBranch } from "state/cable";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { FinanceId } from "components/Finance/state";

const useNumbers = (triggerId: FinanceId & ProdId) => {
  const interArrayLoss = useRecoilValue(getInterArrayLosses(triggerId));
  const config = useRecoilValue(getConfiguration(triggerId)).electrical;
  const powerBins = useRecoilValue(getElectricalPowerBins(triggerId));
  const probabilities = useRecoilValue(
    getElectricalPowerBinsProbabilities(triggerId),
  );
  const capacityMW = useRecoilValue(getCapacity(triggerId));
  const capacity = undefMap(capacityMW, (c) => c / 1000);

  const cableLossPerCase = useMemo(() => {
    if (!interArrayLoss?.results?.IACableLossPerCable) return undefined;
    if (!config.interArrayCableLoss) return null;
    return interArrayLoss.results.IACableLossPerCase;
  }, [config, interArrayLoss]);

  const turbineTrafoLossPerCase = useMemo(() => {
    if (!interArrayLoss?.results?.turbineTrafoLossPerCase) return undefined;
    if (!config.turbineTrafoLoss) return null;
    return interArrayLoss.results.turbineTrafoLossPerCase;
  }, [config, interArrayLoss]);

  const totalLossPerCase = useMemo(() => {
    if (cableLossPerCase === undefined || turbineTrafoLossPerCase === undefined)
      return undefined;
    if (cableLossPerCase === null && turbineTrafoLossPerCase === null)
      return null;

    if (cableLossPerCase && turbineTrafoLossPerCase) {
      return cableLossPerCase.map((v, i) => v + turbineTrafoLossPerCase[i]);
    }
    if (cableLossPerCase === null) {
      return turbineTrafoLossPerCase;
    }
    if (turbineTrafoLossPerCase === null) {
      return cableLossPerCase;
    }
  }, [cableLossPerCase, turbineTrafoLossPerCase]);

  const powerBinsMidPoints = useMemo(() => {
    if (!powerBins || !capacity) return undefined;

    return powerBins
      .slice(0, -1)
      .map((b, i) => (b + powerBins[i + 1]) / 2 / capacity);
  }, [powerBins, capacity]);

  const stoppedReason = useRecoilValue(getStoppedReason(triggerId));
  const analysisStoppedReason = useRecoilValue(
    getStoppedReasonFromAnalysis(triggerId),
  );

  return {
    cableLossPerCase,
    turbineTrafoLossPerCase,
    probabilities,
    totalLossPerCase,
    powerBinsMidPoints,
    stoppedReason,
    analysisStoppedReason,
    capacity: capacityMW,
  };
};

const InterArrayLossGraph = () => {
  const { configuration, park, branch, triggerId } = useDashboardContext();

  const {
    totalLossPerCase,
    turbineTrafoLossPerCase,
    cableLossPerCase,
    probabilities,
    powerBinsMidPoints,
    stoppedReason,
    analysisStoppedReason,
    capacity,
  } = useNumbers(triggerId);

  const invalidCableTypeCables = useRecoilValue(
    getInvalidCableTypeCablesInParkandBranch({
      parkId: park.id,
      branchId: branch.id,
    }),
  );
  const invalidTypes: InvalidTypesStateType[] = [
    {
      type: InvalidTypes.CableTypeInvalid,
      featureIds: invalidCableTypeCables.map((c) => c.id),
    },
  ];

  if (invalidCableTypeCables.length > 0)
    return (
      <CenterContainer style={{ margin: "3rem" }}>
        <InvalidTypesDisplayText invalidTypes={invalidTypes} />
      </CenterContainer>
    );

  if (stoppedReason) {
    return (
      <CenterContainer>
        <SimpleAlert
          title="Analysis stopped"
          text={analysisStoppedText[stoppedReason]}
          type={"error"}
        />
      </CenterContainer>
    );
  }
  if (analysisStoppedReason) {
    return (
      <SimpleAlert
        title="Analysis stopped"
        text={
          analysisStoppedText[analysisStoppedReason as AnalysisStoppedTypes]
        }
        type={"error"}
      />
    );
  }

  if (
    (!configuration.electrical.interArrayCableLoss &&
      !configuration.electrical.turbineTrafoLoss) ||
    totalLossPerCase === null
  ) {
    return (
      <CenterContainer>
        <SimpleAlert
          text={
            "Inter-array cable loss and turbine trafo loss are disabled in the current analysis configuration."
          }
          type={"error"}
        />
      </CenterContainer>
    );
  }

  if (
    totalLossPerCase === undefined ||
    !powerBinsMidPoints ||
    !capacity ||
    turbineTrafoLossPerCase === undefined ||
    cableLossPerCase === undefined ||
    !probabilities
  )
    return <LoadingState />;

  return (
    <>
      <InterArrayLossGraphInner
        totalLossPerCase={totalLossPerCase}
        turbineTrafoLossPerCase={turbineTrafoLossPerCase}
        cableLossPerCase={cableLossPerCase}
        probabilities={probabilities}
        powerBinsMidPoints={powerBinsMidPoints}
        capacity={capacity}
      />
    </>
  );
};

const InterArrayLossGraphInner = ({
  totalLossPerCase,
  turbineTrafoLossPerCase,
  cableLossPerCase,
  probabilities,
  powerBinsMidPoints,
  capacity,
}: {
  totalLossPerCase: number[];
  cableLossPerCase: number[] | null;
  turbineTrafoLossPerCase: number[] | null;
  probabilities: number[];
  powerBinsMidPoints: number[];
  capacity: number;
}) => {
  const graphRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!graphRef.current) return;
    setIsLoaded(false);
    const x = powerBinsMidPoints.map((v) => v * capacity);
    let data: any[] = [
      {
        name: "Total",
        y: totalLossPerCase,
        x,
        line: { shape: "vh", color: colors.blue700 } as const,
        customdata: totalLossPerCase.map((v, i) => [
          (100 * v) / (powerBinsMidPoints[i] * capacity),
          powerBinsMidPoints[i] * 100,
        ]),
        hovertemplate:
          "At a park production rate of %{x:,.0f}MW (%{customdata[1]:,.0f}%),<br>the total inter array loss is %{y:,.2f}MW (%{customdata[0]:,.2f}%)",
        mode: "lines",
        type: "scatter" as const,
      } as const,
    ];

    if (turbineTrafoLossPerCase) {
      data.push({
        name: "Turbine trafo loss",
        y: turbineTrafoLossPerCase,
        x,
        line: { shape: "vh", color: colors.blue500 } as const,
        customdata: turbineTrafoLossPerCase.map((v, i) => [
          (100 * v) / (powerBinsMidPoints[i] * capacity),
          powerBinsMidPoints[i] * 100,
        ]),
        hovertemplate:
          "At a park production rate of %{x:,.0f}MW (%{customdata[1]:,.0f}%),<br>the trafo-related loss is %{y:,.2f}MW (%{customdata[0]:,.2f}%)",
        mode: "dashed",
        type: "scatter" as const,
      } as const);
    }
    if (cableLossPerCase) {
      data.push({
        name: "Inter array cable loss",
        y: cableLossPerCase,
        x,
        line: { shape: "vh", color: colors.blue300 } as const,
        customdata: cableLossPerCase.map((v, i) => [
          (100 * v) / (powerBinsMidPoints[i] * capacity),
          powerBinsMidPoints[i] * 100,
        ]),
        hovertemplate:
          "At a park production rate of %{x:,.0f}MW (%{customdata[1]:,.0f}%),<br>the cable-related loss is %{y:,.2f}MW (%{customdata[0]:,.2f}%)",
        mode: "dashed",
        type: "scatter" as const,
      } as const);
    }

    data.push({
      name: "Probabilities",
      y: probabilities.map(
        (v) => (v / fastMax(probabilities)) * fastMax(totalLossPerCase),
      ),
      x,
      customdata: probabilities.map((v) => [v * 100]),
      hovertemplate: "%{customdata[0]:,.1f}%",
      type: "bar",
      marker: {
        color: colors.grey200,
        opacity: 0.9,
      },
    } as const);

    const layout = {
      hovermode: "closest" as const,
      legend: {
        x: 0.02,
        y: 1.1,
        orientation: "h",
      } as const,
      font: { size: 10, family: "Open Sans" },
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
      autosize: true,
      height: 250,
      bargap: 0.05,
      xaxis: {
        title: "Park production [MW]",
      },
      yaxis: {
        title: "Electrical loss [MW]",
        range: [0, 1.1 * fastMax(totalLossPerCase)],
      },
      margin: {
        l: 60,
        r: 30,
        b: 30,
        t: 30,
      },
    };

    Plotly.newPlot(graphRef.current, data, layout, {
      displayModeBar: false,
      responsive: true,
    }).then(() => setIsLoaded(true));
  }, [
    graphRef,
    powerBinsMidPoints,
    totalLossPerCase,
    capacity,
    cableLossPerCase,
    turbineTrafoLossPerCase,
    probabilities,
  ]);

  return (
    <div style={{ flex: 1 }} ref={graphRef}>
      {isLoaded ?? <LoadingState />}
    </div>
  );
};

const InterArrayLossGraphMenu = () => {
  const { triggerId } = useDashboardContext();
  const { totalLossPerCase, powerBinsMidPoints } = useNumbers(triggerId);

  const { warning } = useToast();

  const download = useCallback(() => {
    if (!totalLossPerCase || !powerBinsMidPoints) {
      warning(
        "Data is still loading, please wait a few seconds and try again.",
      );
      return;
    }

    const header = ["Production fraction [%]", "Total inter array loss [MW]"];

    const rows = totalLossPerCase
      .map((_, i) => {
        const bin = powerBinsMidPoints[i].toFixed(2);
        const loss = totalLossPerCase[i].toFixed(2);

        return [bin, loss];
      })
      .filter(isDefined);

    const csvString = stringify([header, ...rows]);
    downloadText(csvString, "inter_array_loss.csv");
  }, [powerBinsMidPoints, totalLossPerCase, warning]);

  return (
    <>
      <MenuItem
        name={"Download as .csv"}
        icon={<DownloadIcon />}
        onClick={() => download()}
      />
    </>
  );
};

export const InterArrayLossGraphWidget = () => (
  <SafeCard
    title="Inter array loss graph"
    id="Inter array loss graph"
    menuItems={<InterArrayLossGraphMenu />}
  >
    <InterArrayLossGraph />
  </SafeCard>
);
