import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  SearchObject,
  searchBarOpen,
  searchObjectInFocusAtom,
  searchObjectsAtomFamily,
} from "./state";
import { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Fuse from "fuse.js";
import { Column, Row } from "components/General/Layout";
import { SearchInput } from "components/General/Input";
import { colors } from "styles/colors";
import CloseIcon from "@icons/24/Close.svg?react";
import Flag from "@icons/24/WindMeasure.svg?react";
import ExpandIcon from "@icons/24/IntercomHelp.svg?react";
import { IntercomArticle, searchIntercomArticles } from "./service";
import { typography } from "styles/typography";
import { SVGWrapper } from "@icons/svgUtils";
import { SkeletonText } from "components/Loading/Skeleton";
import { Mixpanel } from "mixpanel";
import { useClickOutside } from "hooks/useClickOutside";
import { IconBtn } from "components/General/Icons";
import { modalTypeOpenAtom } from "state/modal";

export const SearchIconWrapper = styled.div<{
  disabled?: boolean;
  onClick?: () => void;
}>`
  display: flex;
  align-items: center;
  align-filtered: center;
  padding: 0.5rem;
  svg,
  img {
    width: 1.6rem;
    height: 1.6rem;
    ${({ disabled }) =>
      disabled ? `path { stroke : ${colors.primaryDisabled} }` : ""};
  }
  ${({ onClick }) => onClick && ":hover { cursor: pointer; }"}
`;

const Container = styled.div`
  position: fixed;
  top: 20rem;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 1;
  padding: 2.4rem 1.6rem;
  height: fit-content;
  width: 60rem;
  border-radius: 4px;
  background: white;
  box-shadow:
    0 4px 6px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.08);
`;

const ListContainer = styled.div`
  padding: 1.2rem 0;
  display: flex;
  flex-direction: column;

  max-height: 40vh;
  box-sizing: border-box;
  overflow-y: scroll;
`;

const ElementRow = styled(Row)<{ selected: boolean; disabled?: boolean }>`
  align-items: center;
  ${(p) => p.selected && `background-color: ${colors.selected};`}
  padding: 0.8rem 0.4rem;
  border-radius: 0.4rem;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  &:hover {
    background-color: ${colors.hover};
  }
  p {
    ${(p) => p.disabled && `color: ${colors.primaryDisabled};`}
  }
  svg {
    ${(p) => p.disabled && `opacity: 50%;`}
  }
`;

const ElementTitle = styled.p<{ disabled?: boolean }>`
  ${typography.contentAndButtons}
  margin: 0;
`;

const inputStyle: CSSProperties = {
  background: "white",
  paddingRight: "4rem",
  width: "100%",
  borderColor: colors.dropdownBorder,
};

const INITIAL_SEARCH_OBJECTS = [
  "search-screenshot",
  "search-upload",
  "search-invite",
  "search-compare-parks",
  "search-dashboard",
];

const DividerHeader = styled.p`
  ${typography.graphics}
  color: ${colors.secondaryText}
`;

const Divider = styled.div`
  flex: 1;
  width: 100%;
  height: 1px;
  border-top: 1px solid ${colors.inputOutline};
`;

const SearchBar = () => {
  const searchObjects = useRecoilValue(searchObjectsAtomFamily);
  const ref = useRef<HTMLDivElement>(null);
  const listContainerRef = useRef<HTMLDivElement>(null);
  const setSearchObjectInFocus = useSetRecoilState(searchObjectInFocusAtom);

  const [open, setOpen] = useRecoilState(searchBarOpen);
  const [search, setSearch] = useState("");
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const [searchIntercomInProgress, setSearchIntercomInProgress] =
    useState(false);

  const [articles, setArticles] = useState<IntercomArticle[]>([]);
  const [filtered, setFiltered] = useState<SearchObject[]>(
    searchObjects.filter((s) => INITIAL_SEARCH_OBJECTS.includes(s.id)),
  );
  useClickOutside(ref, () => setOpen(false));

  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  const closeAndTrack = useCallback(() => {
    setOpen(false);
    if (search.length > 0) {
      Mixpanel.track("Search for help", {
        searchWord: search,
        close: true,
      });
    }
  }, [setOpen, search]);

  const closeAnyOpenModal = useCallback(() => {
    setModalTypeOpen(undefined);
  }, [setModalTypeOpen]);

  const handleKeyboardEvents = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "k" && (event.metaKey || event.ctrlKey)) {
        if (event.metaKey || event.ctrlKey) {
          closeAnyOpenModal();
          setOpen((cur) => !cur);
        }
      }

      if (!open) return; // Only handle below key events if search bar is open

      const keys = ["ArrowDown", "ArrowUp", "Enter", "Escape"];
      if (keys.includes(event.key)) {
        event.preventDefault(); // Prevent default behavior for handled keys
        const allItems = [...filtered, ...articles];

        switch (event.key) {
          case "ArrowDown":
          case "ArrowUp":
            const direction = event.key === "ArrowDown" ? 1 : -1;
            let nextIndex =
              (selectedIndex + direction + allItems.length) % allItems.length;
            setSelectedIndex(nextIndex);
            break;
          case "Enter":
            const selectedItem = allItems[selectedIndex];
            if (selectedItem) {
              selectedItem.onSelect
                ? (selectedItem as SearchObject).onSelect()
                : window.Intercom("showArticle", parseInt(selectedItem.id));
              closeAndTrack();
            }
            break;
          case "Escape":
            closeAndTrack();
            break;
        }
      }
    },
    [
      closeAnyOpenModal,
      open,
      setOpen,
      selectedIndex,
      setSelectedIndex,
      filtered,
      articles,
      closeAndTrack,
    ],
  );

  useEffect(() => {
    if (selectedIndex >= 0 && listContainerRef.current) {
      const selectedItem = listContainerRef.current.children[selectedIndex];
      if (selectedItem) {
        selectedItem.scrollIntoView({
          behavior: "auto",
          block: "center",
        });
      }
    }
  }, [selectedIndex]);

  useEffect(() => {
    if (!open) return;
    const selectedItem = filtered[selectedIndex];
    setSearchObjectInFocus(selectedItem?.id);
    return () => setSearchObjectInFocus(undefined);
  }, [filtered, open, selectedIndex, setSearchObjectInFocus]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyboardEvents);
    return () => window.removeEventListener("keydown", handleKeyboardEvents);
  }, [handleKeyboardEvents]);

  const searchIntercom = useCallback(async (phrase: string) => {
    if (phrase.length === 0) return [];
    setSearchIntercomInProgress(true);
    const data = await searchIntercomArticles(phrase).finally(() =>
      setSearchIntercomInProgress(false),
    );
    setArticles(data.articles);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => searchIntercom(search), 400);
    return () => clearTimeout(timeout);
  }, [search, searchIntercom]);

  useEffect(() => {
    if (search.length === 0) return;
    const timeout = setTimeout(
      () =>
        Mixpanel.track("Search for help", {
          searchWord: search,
        }),
      1000,
    );
    return () => clearTimeout(timeout);
  }, [search]);

  useEffect(() => {
    const fuse = new Fuse(searchObjects, {
      keys: ["title", "tags"],
      includeScore: true,
      threshold: 0.3,
    });

    const results = fuse.search(search).map((result) => result.item);
    setFiltered(search.length > 0 ? results : searchObjects);
  }, [search, searchObjects]);

  if (!open) return <></>;

  return (
    <Container ref={ref}>
      <div style={{ position: "absolute", top: 0, right: 0 }}>
        <IconBtn size="1.2rem" onClick={closeAndTrack}>
          <CloseIcon />
        </IconBtn>
      </div>
      <SearchInput
        autoFocus
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
          setSelectedIndex(-1);
        }}
        onCancel={() => {
          closeAndTrack();
        }}
        onKeyDown={() => {}}
        onClear={() => setSearch("")}
        placeholder={`Search`}
        style={inputStyle}
      />
      <ListContainer role="list" ref={listContainerRef}>
        <Row style={{ alignItems: "center", gap: "0.8rem" }}>
          <DividerHeader style={{ margin: 0 }}>Actions</DividerHeader>
          <Divider />
        </Row>
        {filtered.map((f, index) => {
          return (
            <ElementRow
              key={f.id}
              disabled={f.disabled}
              role="listitem"
              selected={selectedIndex === index}
              onMouseEnter={() => setSearchObjectInFocus(f.id)}
              onMouseLeave={() =>
                setSearchObjectInFocus((cur) =>
                  cur === f.id ? undefined : cur,
                )
              }
              onClick={() => {
                if (f.disabled) return;
                f.onSelect();
                closeAndTrack();
              }}
            >
              {f.icon ? (
                <SVGWrapper size={1.6}>{f.icon}</SVGWrapper>
              ) : (
                <SVGWrapper size={1.6}>
                  <Flag />
                </SVGWrapper>
              )}
              <ElementTitle>{f.title}</ElementTitle>
            </ElementRow>
          );
        })}
        <Row style={{ alignItems: "center", gap: "0.8rem" }}>
          <DividerHeader style={{ margin: 0 }}>Read more</DividerHeader>
          <Divider />
        </Row>
        {searchIntercomInProgress
          ? Array.from({ length: 5 }, (_, index) => (
              <ElementRow key={index} role="listitem" selected={false}>
                <SVGWrapper size={1.6}>
                  <ExpandIcon />
                </SVGWrapper>
                <SkeletonText text="searching intercom for articles" />
              </ElementRow>
            ))
          : articles.map((a, index) => (
              <ElementRow
                key={a.id}
                role="listitem"
                selected={selectedIndex === filtered.length + index}
                onClick={() => {
                  window.Intercom("showArticle", parseInt(a.id));
                  closeAndTrack();
                }}
              >
                <SVGWrapper size={1.6}>
                  <ExpandIcon />
                </SVGWrapper>
                <Column style={{ gap: "0.2rem", overflow: "hidden" }}>
                  <ElementTitle>{a.title}</ElementTitle>
                  <ElementTitle
                    style={{
                      color: colors.secondaryText,
                      flex: 1,
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {a.description}
                  </ElementTitle>
                </Column>
              </ElementRow>
            ))}
        {articles.length === 0 && !searchIntercomInProgress ? (
          <ElementRow role="listitem" selected={false}>
            <ElementTitle style={{ color: colors.secondaryText }}>
              Try another search term
            </ElementTitle>
          </ElementRow>
        ) : (
          <></>
        )}
      </ListContainer>
    </Container>
  );
};

export default SearchBar;
