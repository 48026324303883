import { orLoader } from "components/Loading/Skeleton";
import { currentSelectionProduction } from "components/ProductionV2/Triggers";
import {
  ProdId,
  getAEPPerTurbine,
  getAverageSpeedPerTurbine,
  getStoppedReason,
  getStoppedReasonFromAnalysis,
  getTotalWakeLossPerTurbine,
} from "components/ProductionV2/state";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import {
  allSimpleTurbineTypesSelector,
  getSelectedTurbines,
} from "state/turbines";
import { TurbineFeature } from "../../../../types/feature";
import { ColoredGrid } from "../../../General/Form";
import { MenuFrame } from "../../../MenuPopup/CloseableMenuPopup";
import {
  AnalysisStoppedTypes,
  analysisStoppedText,
} from "components/ProductionV2/types";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";

const Inner = ({ id, feature }: { id: ProdId; feature: TurbineFeature }) => {
  const aep = useRecoilValueLoadable(getAEPPerTurbine(id)).map(
    (list) => list?.find((t) => t.turbine.id === feature.id)?.value,
  );

  const wakeLoss = useRecoilValueLoadable(getTotalWakeLossPerTurbine(id)).map(
    (list) => list?.find((t) => t.turbine.id === feature.id)?.value,
  );

  const averageSpeed = useRecoilValueLoadable(
    getAverageSpeedPerTurbine(id),
  ).map((list) => list?.find((t) => t.turbine.id === feature.id)?.value);

  const stoppedReason = useRecoilValueLoadable(
    getStoppedReason(id),
  ).valueMaybe();
  const analysisStoppedReason = useRecoilValueLoadable(
    getStoppedReasonFromAnalysis(id),
  ).valueMaybe();

  const selectedTurbines = useRecoilValue(getSelectedTurbines);
  console.log({ selectedTurbines });

  const turbineTypes = useRecoilValue(allSimpleTurbineTypesSelector);
  const turbineType = turbineTypes.find(
    (t) => t.id === feature.properties.turbineTypeId,
  );

  if (stoppedReason) {
    return (
      <div>
        <SimpleAlert
          text={analysisStoppedText[stoppedReason]}
          type={"error"}
          title="Analysis stopped"
        ></SimpleAlert>
      </div>
    );
  }
  if (analysisStoppedReason) {
    return (
      <div>
        <SimpleAlert
          title="Analysis stopped"
          text={
            analysisStoppedText[analysisStoppedReason as AnalysisStoppedTypes]
          }
          type={"error"}
        ></SimpleAlert>
      </div>
    );
  }

  return (
    <>
      {1 < selectedTurbines.length && (
        <div style={{ paddingBottom: "1rem" }}>
          <SimpleAlert
            type="info"
            text={`${selectedTurbines.length} turbines are selected, but we only show information for one:`}
          />
        </div>
      )}
      <ColoredGrid style={{ gridTemplateColumns: "auto auto" }}>
        <p>Turbine name:</p>
        <p>{feature.properties.name}</p>
        <p>Turbine type:</p>
        <p>{turbineType?.name}</p>

        <p>AEP:</p>
        {orLoader(aep, (aep) => (
          <p>{aep.toFixed(0)} GWh</p>
        ))}

        <p>Wake loss:</p>
        {orLoader(wakeLoss, (wakeLoss) => (
          <p>{(wakeLoss * 100).toFixed(2)} %</p>
        ))}

        <p>Mean speed:</p>
        {orLoader(averageSpeed, (speed) => (
          <p>{speed.toFixed(2)} m/s</p>
        ))}
      </ColoredGrid>
    </>
  );
};

const TurbineModal = ({
  canvasFeature,
  onClose,
}: {
  canvasFeature: TurbineFeature;
  onClose(): void;
}) => {
  return (
    <MenuFrame title={"Turbine statistics"} onExit={onClose}>
      <Inner id={currentSelectionProduction} feature={canvasFeature} />
    </MenuFrame>
  );
};

export default TurbineModal;
