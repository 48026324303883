import { fetchWithRetries, fetchWithToken } from "../services/utils";
import { selectorFamily, selector, waitForAll, atom } from "recoil";
import { appendQueryParamsSign, capitalize } from "../utils/utils";
import { addCorsAndCacheProxyURL } from "./gisSourceCorsProxy";
import { newCustomDataSourceAtom } from "./newLayer";
import {
  SourceTypes,
  WmsMetadata,
  Layer,
  ExternalDataSourceLinkLayerWithSourceWMS,
  SourceTypesLayer,
} from "../types/layers";
import { WmsSourceEntries, WmsLayer } from "../types/layers";
import { getExternalLayerId } from "../utils/externalLayers";
import { privateGISSourceDataWMSAPISelector } from "./privateGISSource";
import { isDefined } from "../utils/predicates";
import { scream } from "../utils/sentry";

export const isWMSLayer = (
  layer: Layer,
): layer is ExternalDataSourceLinkLayerWithSourceWMS => {
  return layer.sourceType === SourceTypesLayer.wms;
};

export const wmsGetCapabilitiesSuffix = "SERVICE=WMS&REQUEST=GetCapabilities";

const wmsServerFullMetadataSelector = selectorFamily<
  string | undefined,
  { url: string; privateSource: boolean | undefined }
>({
  key: "wmsServerFullMetadataSelector",
  get:
    ({ url, privateSource }) =>
    async () => {
      try {
        const response = privateSource
          ? await fetchWithToken(
              `${url}${appendQueryParamsSign(url)}${wmsGetCapabilitiesSuffix}`,
              {
                method: "get",
              },
            )
          : await fetchWithRetries(
              `${url}${appendQueryParamsSign(url)}${wmsGetCapabilitiesSuffix}`,
              {
                method: "get",
              },
              2,
            );

        if (!response.ok) {
          return;
        }

        const xml = await response.text();
        return xml;
      } catch (err) {
        console.warn(`Could not read from WMS server: ${url}, ${err}`);
        return;
      }
    },
});

export const resetWmsDataStateAtom = atom<number>({
  key: "resetWmsDataStateAtom",
  default: 1,
});

const getCombinedLayerName = (
  layer: Element | null,
  combinedName = "",
): string => {
  if (!layer || layer.nodeName !== "Layer") {
    return combinedName;
  }

  const name = layer.querySelector("Name")?.textContent;
  if (!name) {
    return combinedName;
  }

  const newCombinedName = combinedName.concat(name);
  return getCombinedLayerName(layer.parentElement, newCombinedName);
};

const getBBOXFromWMSLayer = (layerElem: Element) => {
  if (layerElem.querySelector("westBoundLongitude")) {
    return [
      layerElem.querySelector("westBoundLongitude")?.textContent,
      layerElem.querySelector("southBoundLatitude")?.textContent,
      layerElem.querySelector("eastBoundLongitude")?.textContent,
      layerElem.querySelector("northBoundLatitude")?.textContent,
    ].map((s) => parseFloat(s ?? ""));
  }

  if (layerElem.querySelector("LatLonBoundingBox")) {
    return [
      layerElem.querySelector("LatLonBoundingBox")?.getAttribute("minx"),
      layerElem.querySelector("LatLonBoundingBox")?.getAttribute("miny"),
      layerElem.querySelector("LatLonBoundingBox")?.getAttribute("maxx"),
      layerElem.querySelector("LatLonBoundingBox")?.getAttribute("maxy"),
    ].map((s) => parseFloat(s ?? ""));
  }
};

export const getWmsPath = (layer: ExternalDataSourceLinkLayerWithSourceWMS) => {
  const isPrivate = "private" in layer.source ? layer.source["private"] : false;

  return `${addCorsAndCacheProxyURL(
    layer.sourceLink.url,
    isPrivate,
  )}${appendQueryParamsSign(
    layer.sourceLink.url,
  )}format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&styles=&transparent=true&width=256&height=256&layers=${
    layer.sourceLayerId
  }`;
};

const wmsMetadataLayersSelector = selectorFamily<WmsSourceEntries, WmsLayer>({
  key: "wmsMetadataLayersSelector",
  get:
    (wmsLayer) =>
    ({ get }) => {
      if (!wmsLayer.wms_url)
        scream("URL is undefined for layer", { source: wmsLayer.source });
      const url = wmsLayer?.skipProxy
        ? wmsLayer.wms_url
        : addCorsAndCacheProxyURL(wmsLayer.wms_url, wmsLayer.private);

      const metadataXMLString = get(
        wmsServerFullMetadataSelector({ url, privateSource: wmsLayer.private }),
      );

      if (!metadataXMLString)
        return {
          url: wmsLayer.wms_url,
          sourceType: SourceTypes.wms,
          source: capitalize(wmsLayer.source),
          layersInfo: [],
          fetchSucceeded: false,
          alternativeNames: new Set(wmsLayer?.alternativeNames ?? []),
          originalName: wmsLayer.originalName,
          keywords: [],
        };

      const parser = new DOMParser();
      const filteredLayers = wmsLayer.filteredLayers || [];
      const xmlDoc = parser.parseFromString(metadataXMLString, "text/xml");

      const layers =
        xmlDoc.querySelectorAll("Layer Layer").length !== 0
          ? xmlDoc.querySelectorAll("Layer Layer")
          : xmlDoc.querySelectorAll("Layer");
      const sourceAbstract = xmlDoc.querySelector("Service Abstract");
      const sourceKeywordList = xmlDoc.querySelectorAll(
        "Service KeywordList Keyword",
      );
      /**
       * <ContactInformation xmlns="http://www.opengis.net/wms"><ContactPersonPrimary><ContactPerson/><ContactOrganization>Landesamt für Bergbau, Energie und Geologie (LBEG)</ContactOrganization></ContactPersonPrimary><ContactAddress><AddressType>postal</AddressType><Address>Stilleweg 2</Address><City>Hannover</City><StateOrProvince>Niedersachsen</StateOrProvince><PostCode>30655</PostCode><Country>Deutschland</Country></ContactAddress><ContactVoiceTelephone>+49 (0)511-643-0</ContactVoiceTelephone><ContactElectronicMailAddress>metadaten@lbeg.niedersachsen.de</ContactElectronicMailAddress></ContactInformation>
       */
      // const _contactInformation = xmlDoc.querySelector(
      //   "Service ContactInformation"
      // );

      const keywords = Array.from(sourceKeywordList ?? [])
        .map((keyword) => keyword.textContent ?? undefined)
        .filter(Boolean)
        .filter(isDefined);

      const layersInfo = [...layers]
        .map<WmsMetadata>((layerElem, index) => {
          const title = layerElem.querySelector("Title")?.textContent;
          const layerName = getCombinedLayerName(layerElem);
          const abstract =
            layerElem.querySelector("Abstract")?.textContent ?? undefined;
          const layerKeywordList = Array.from(
            layerElem.querySelectorAll("KeywordList Keyword") ?? [],
          )
            .map((keyword) => keyword?.textContent ?? undefined)
            .filter(Boolean)
            .filter(isDefined);

          const metaDataUrls = layerElem.querySelectorAll("MetadataURL");

          const metaDataUrl =
            Array.from(metaDataUrls)
              .map((f) => f.querySelector("Format"))

              .find((f) => f?.textContent === "application/xml")
              ?.nextElementSibling?.getAttribute("xlink:href") ?? undefined;

          let link: string | undefined = undefined;
          const htmlLink = [...layerElem.querySelectorAll("DataURL")].filter(
            (l) => l.querySelector("Format")?.textContent === "text/html",
          );
          if (htmlLink.length !== 0) {
            link =
              htmlLink[0]
                ?.querySelector("OnlineResource")
                ?.getAttribute("xlink:href") ?? "undefined";
          }

          const nameTitle = title ?? "undefined";

          const layerId = getExternalLayerId(
            wmsLayer.wms_url,
            layerName,
            SourceTypesLayer.wms,
            {
              index,
            },
          );
          return {
            id: layerId,
            type: "wms",
            name: nameTitle,
            abstract,
            keywords: layerKeywordList,
            metaDataUrl,
            link,
            bbox: getBBOXFromWMSLayer(layerElem),
            path: `${url}${appendQueryParamsSign(
              url,
            )}format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&styles=&transparent=true&width=256&height=256&layers=${
              layerElem.querySelector("Name")?.textContent
            }`,
            layer: layerElem.querySelector("Name")?.textContent ?? "undefined",
            url: url,
            originalUrl: wmsLayer.wms_url,
            source: wmsLayer.source,
            alias:
              wmsLayer.layers?.find(
                (l) =>
                  l.featureTypeName ===
                  layerElem.querySelector("Name")?.textContent,
              )?.alias || undefined,
            theme:
              wmsLayer.layers?.find(
                (l) =>
                  l.featureTypeName ===
                  layerElem.querySelector("Name")?.textContent,
              )?.theme || undefined,
            sourceType: SourceTypesLayer.wms,
            tags: wmsLayer.layerSettingsGlobal?.[layerId]?.tags ?? [],
          };
        })
        .filter((l) => !filteredLayers.includes(l.layer ?? ""));

      return {
        url: wmsLayer.wms_url,
        sourceType: SourceTypes.wms,
        source: capitalize(wmsLayer.source),
        alternativeNames: new Set(wmsLayer?.alternativeNames ?? []),
        layersInfo,
        fetchSucceeded: true,
        originalName: wmsLayer.originalName,
        abstract: sourceAbstract?.textContent ?? undefined,
        keywords,
      };
    },
});

const wmsDataLayersFullMetadataSelectorFamily = selectorFamily<
  WmsSourceEntries[],
  WmsLayer[]
>({
  key: "wmsDataLayersFullMetadataSelectorFamily",
  get:
    (wmsLayers) =>
    ({ get }) => {
      const wmsMetadataLayers = get(
        waitForAll(
          wmsLayers.map((wmsLayer) => wmsMetadataLayersSelector(wmsLayer)),
        ),
      );

      return wmsMetadataLayers.filter((l) => l != null);
    },
});

export const wmsPrivateDataLayersFullMetadataSucceededSelector = selectorFamily<
  WmsSourceEntries[],
  { projectId: string }
>({
  key: "wmsDataLayersFullMetadataSucceededSelector",
  get:
    ({ projectId }) =>
    ({ get }) => {
      const wmsLayers = get(privateGISSourceDataWMSAPISelector({ projectId }));

      return get(wmsDataLayersFullMetadataSelectorFamily(wmsLayers)).filter(
        (l) => l.fetchSucceeded,
      );
    },
});

export const customWmsDataLayersFullMetadataSelector = selector<
  WmsSourceEntries[]
>({
  key: "customWmsDataLayersFullMetadataSelector",
  get: ({ get }) => {
    const customWMSDataSource = get(newCustomDataSourceAtom);
    if (!customWMSDataSource || customWMSDataSource.type !== SourceTypes.wms)
      return [];

    const customWmsLayers: WmsLayer[] = [
      {
        source: customWMSDataSource.name,
        alternativeNames: customWMSDataSource?.alternativeNames ?? [],
        wms_url: customWMSDataSource.url,
        layers: [],
        private: true,
        sourceType: SourceTypesLayer.wms,
      },
    ];

    return get(wmsDataLayersFullMetadataSelectorFamily(customWmsLayers)).filter(
      (l) => l.fetchSucceeded,
    );
  },
});
