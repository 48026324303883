import { useEffect } from "react";
import { selectorFamily, useRecoilValue } from "recoil";
import { ValidationWarningTypes } from "./utils";
import { useValidationWarnings } from "./ValidationWarnings";
import { substationSourceId } from "components/Mapbox/constants";
import { getSubstationsOutsideParkCableCorridorSelector } from "state/cable";
import { branchIdSelector } from "state/pathParams";

export const offshoreSubstationOutsideParkCableCorridorWarningSelectorFamily =
  selectorFamily<
    | {
        type: ValidationWarningTypes.OffshoreSubstationOutsideParkCableCorridor;
        featureIds: string[];
        parkId: string;
      }
    | undefined,
    {
      parkId: string;
      branchId: string;
    }
  >({
    key: "offshoreSubstationOutsideParkCableCorridorWarningSelectorFamily",
    get:
      ({ parkId, branchId }) =>
      ({ get }) => {
        const outsideOffshoreSubstations = get(
          getSubstationsOutsideParkCableCorridorSelector({ parkId, branchId }),
        );

        return outsideOffshoreSubstations.length === 0
          ? undefined
          : {
              type: ValidationWarningTypes.OffshoreSubstationOutsideParkCableCorridor,
              featureIds: outsideOffshoreSubstations.map((t) => t.id),
              parkId: parkId,
            };
      },
  });

export const OffshoreSubstationOutsideParkCableCorridor = ({
  map,
  parkId,
  source = substationSourceId,
}: {
  map: mapboxgl.Map;
  parkId: string;
  source?: string;
}) => {
  const branchId = useRecoilValue(branchIdSelector);
  const outsideOffshoreSubstationsWarning = useRecoilValue(
    offshoreSubstationOutsideParkCableCorridorWarningSelectorFamily({
      parkId: parkId,
      branchId: branchId ?? "",
    }),
  );
  const { warn, remove } = useValidationWarnings();
  useEffect(() => {
    if (!outsideOffshoreSubstationsWarning) return;
    warn(outsideOffshoreSubstationsWarning);
    if (map.getSource(source))
      for (const id of outsideOffshoreSubstationsWarning.featureIds)
        map.setFeatureState({ source, id }, { outside: true });
    return () => {
      remove(ValidationWarningTypes.OffshoreSubstationOutsideParkCableCorridor);
      if (
        map.getSource(source) &&
        outsideOffshoreSubstationsWarning?.featureIds
      )
        for (const id of outsideOffshoreSubstationsWarning.featureIds)
          map.removeFeatureState({ source, id }, "outside");
    };
  }, [map, outsideOffshoreSubstationsWarning, remove, source, warn]);

  useEffect(
    () => () => {
      remove(ValidationWarningTypes.OffshoreSubstationOutsideParkCableCorridor);
    },
    [remove],
  );
  return null;
};
