import { orLoader } from "components/Loading/Skeleton";
import {
  getAnalysisWindStats,
  getStoppedReason,
  getStoppedReasonFromAnalysis,
} from "components/ProductionV2/state";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useRecoilValueLoadable } from "recoil";
import styled from "styled-components";
import { spaceMedium } from "../../../styles/space";
import { FlexGrid2 } from "../../General/Form";
import { Row } from "../../General/Layout";
import { SimpleWindRose } from "state/windStatistics";
import { CenterContainer, SafeCard } from "./Base";
import {
  AnalysisStoppedTypes,
  analysisStoppedText,
} from "components/ProductionV2/types";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useDashboardContext } from "../Dashboard";
import { SpatialCalibrationType } from "services/metoceanService";

// Lazy load to prevent plotly being in main.js
const WindRoseDiv = React.lazy(
  () => import("../../WindWaveMeasurementInfoModal/WindRose"),
);

const Figure = styled.div`
  display: flex;
  flex: 1;
`;

const Grid = styled(FlexGrid2)`
  flex: 1;
  padding: ${spaceMedium};
  align-self: start;
  p {
    white-space: nowrap;
  }
`;

const WindDetails = () => {
  const { triggerId, windConfiguration } = useDashboardContext();
  const stats = useRecoilValueLoadable(getAnalysisWindStats(triggerId));
  const stoppedReason = useRecoilValueLoadable(
    getStoppedReason(triggerId),
  ).valueMaybe();

  const analysisStoppedReason = useRecoilValueLoadable(
    getStoppedReasonFromAnalysis(triggerId),
  ).valueMaybe();

  const windRose = useMemo(() => {
    const s = stats.getValue();
    if (!s) return;
    return {
      directions: s.directionBins.map((d, i) => ({
        direction: d,
        probability: s.directionProbabilities[i],
        meanSpeed: s.meanSpeedPerDirection[i],
      })),
    } as SimpleWindRose;
  }, [stats]);

  const figref = useRef<HTMLDivElement>(null);
  const rowref = useRef<HTMLDivElement>(null);
  const gridref = useRef<HTMLDivElement>(null);

  const [figspace, setFigspace] = useState<number | undefined>(undefined);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (!rowref.current) return;
      const { height: rowHeight, width: rowWidth } =
        rowref.current.getBoundingClientRect();
      const gridwidth = gridref.current?.getBoundingClientRect().width ?? 0;
      const widthLeft = Math.max(1, rowWidth - gridwidth - 8); // NOTE: 8 is the gap in Row
      setFigspace(Math.min(widthLeft, rowHeight));
    });
    if (rowref.current) observer.observe(rowref.current);
    return () => observer.disconnect();
  }, []);

  if (stoppedReason) {
    return (
      <CenterContainer>
        <SimpleAlert
          title="Analysis stopped"
          text={analysisStoppedText[stoppedReason]}
          type={"error"}
        />
      </CenterContainer>
    );
  }
  if (analysisStoppedReason) {
    return (
      <SimpleAlert
        title="Analysis stopped"
        text={
          analysisStoppedText[analysisStoppedReason as AnalysisStoppedTypes]
        }
        type={"error"}
      />
    );
  }

  return (
    <Row style={{ flex: 1 }} ref={rowref}>
      <Grid ref={gridref}>
        <Row>
          <p>Latitude</p>
          {orLoader(stats, (s) => (
            <p>{s.latitude.toFixed(3)}</p>
          ))}
        </Row>
        <Row>
          <p>Longitude</p>
          {orLoader(stats, (s) => (
            <p>{s.longitude.toFixed(3)}</p>
          ))}
        </Row>
        <Row>
          <p>Height</p>
          {orLoader(stats, (s) => (
            <p>{s.height} m</p>
          ))}
        </Row>
        <Row>
          <p>Wind source</p>
          {orLoader(stats, (s) => (
            <p>{s.source.toUpperCase()}</p>
          ))}
        </Row>
        <Row>
          <p>Wind source timerange</p>
          {orLoader(stats, (s) => (
            <p>
              {s.fromYear}-{s.toYear}
            </p>
          ))}
        </Row>
        {windConfiguration.calibration &&
          windConfiguration.calibration.type == "percent" && (
            <Row>
              <p>Mean speed calibration</p>
              <p>{windConfiguration.calibration.value} %</p>
            </Row>
          )}
        {windConfiguration.spatial_calibration && (
          <Row>
            <p>Spatial calibration</p>
            <p>
              {windConfiguration.spatial_calibration.type ===
              SpatialCalibrationType.GWA
                ? "Global Wind Atlas"
                : "Uploaded"}
              ({windConfiguration.spatial_calibration.value})
            </p>
          </Row>
        )}
        <Row>
          <p>Avg. wind speed</p>
          {orLoader(stats, (s) => (
            <p>{s.meanSpeed.toFixed(2)} m/s</p>
          ))}
        </Row>
        <Row>
          <p>Avg. power-law coeff.</p>
          {orLoader(stats, (s) => (
            <p>{s.meanAlpha.toFixed(3)}</p>
          ))}
        </Row>
      </Grid>
      {0 <= (figspace ?? 0) && windRose && (
        <Figure ref={figref}>
          <React.Suspense fallback={null}>
            <WindRoseDiv
              windRose={windRose}
              width={figspace ?? 224}
              height={figspace ?? 224}
            />
          </React.Suspense>
        </Figure>
      )}
    </Row>
  );
};

export const WindRoseWidget = () => (
  <SafeCard title="Wind Rose" id="Wind rose">
    <WindDetails />
  </SafeCard>
);
