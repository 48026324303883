import { Map } from "mapbox-gl";
import { DefaultValue, atom, selector } from "recoil";
import { leftMenuModeActiveAtom } from "./filter";
import { imageToGeorefAtom } from "./georef";
import { modalTypeOpenAtom } from "./modal";
import { inReadOnlyModeSelector } from "./project";
import {
  GenerateFoundationsMenuType,
  GenerateWindTurbinesAndFoundationsMenuType,
  SiteLocatorMenuType,
} from "../constants/projectMapView";
import { ProjectElementMenuType, UploadMenuParkType } from "../constants/park";
import { WindSourceMenuType } from "../components/WindWaveSource/utils";
import { SelectionType } from "../services/types";
import { AddFeatureOptions } from "../types/map";
import { GenerateCablesMenuType } from "../constants/cabling";
import { WindDataSource } from "./windStatistics";
import { WaveDataSource } from "./waveStatistics";
import {
  BathymetryStyleId,
  availableMapStyles,
  getBathymetryStyle,
  getMapStyle,
  getOtherMap,
  OtherMapId,
} from "../constants/availableMapStyles";
import { cableEditModeAtom } from "../components/Cabling/Generate/state";
import { syncLocalStorage } from "./effects";
import { z } from "zod";
import { Feature } from "geojson";
import { editorAccessProjectSelector } from "./user";

export const defaultDepthRasterMinMax: [number, number] = [0, 700];

const depthRasterMinMaxAtom = atom<[number, number]>({
  key: "depthRasterMinMaxAtom",
  default: defaultDepthRasterMinMax,
});

export const depthRasterMinMaxSelector = selector<[number, number]>({
  key: "depthRasterMinMaxSelector",
  get: ({ get }) => {
    const bathymetryStyle = get(activeBathymetryStyleIdAtom);
    return bathymetryStyle === "raster"
      ? get(depthRasterMinMaxAtom)
      : defaultDepthRasterMinMax;
  },
  set: ({ set: SetRecoilState }, newValue: [number, number] | DefaultValue) => {
    SetRecoilState(depthRasterMinMaxAtom, newValue);
  },
});

export const defaultWindSpeedRasterMinMax: [number, number] = [5, 13];

export const windSpeedRasterMinMaxAtom = atom<[number, number]>({
  key: "windSpeedRasterMinMaxAtom",
  default: defaultWindSpeedRasterMinMax,
});

export const mapRefAtom = atom<undefined | Map>({
  key: "mapRefAtom",
  default: undefined,
  dangerouslyAllowMutability: true,
});

export const mapControlsAtom = atom<undefined | MapboxDraw>({
  key: "mapControlsAtom",
  default: undefined,
  dangerouslyAllowMutability: true,
});

type WindPoint = {
  source: WindDataSource;
  position: number[];
};

type WavePoint = {
  source: WaveDataSource;
  position: number[];
};

export const measureWindPointAtom = atom<undefined | WindPoint>({
  key: "measureWindPointAtom",
  default: undefined,
});

export const windPointsSource = atom<WindDataSource>({
  key: "windPointsSource",
  default: WindDataSource.ERA5,
});

export const wavePointsSource = atom<WaveDataSource>({
  key: "wavePointsSource",
  default: WaveDataSource.ERA5,
});

export const measureWavePointAtom = atom<undefined | WavePoint>({
  key: "measureWavePointAtom",
  default: undefined,
});

export const windPointHeightAtom = atom({
  key: "windPointHeightAtom",
  default: 150,
  effects: [
    syncLocalStorage(
      "vind:wind-measurement:height",
      z.number().min(0).max(1000),
    ),
  ],
});

export const editFeaturesAtom = atom<SelectionType[]>({
  key: "editFeaturesAtom",
  default: [],
});

export const addFeatureAtom = atom<
  | undefined
  | {
      mode: MapboxDraw.DrawMode | "draw_circle" | "draw_rectangle";
      options?: AddFeatureOptions;
      continuePlacing?: boolean;
      getPropertiesFunc?: (
        features: Feature,
      ) => Record<string, unknown> | Error;
    }
>({
  key: "addFeatureAtom",
  default: undefined,
});

const leftMenuAllowedInteraction = [
  ProjectElementMenuType,
  UploadMenuParkType,
  GenerateWindTurbinesAndFoundationsMenuType,
  GenerateCablesMenuType,
  GenerateFoundationsMenuType,
  SiteLocatorMenuType,
];

export const mapInteractionSelector = selector({
  key: "mapInteractionSelector",
  get: ({ get }) => {
    const leftMenuActive = get(leftMenuModeActiveAtom);
    const editInProgress = get(editFeaturesAtom).length > 0;
    return {
      select:
        !get(imageToGeorefAtom) &&
        (leftMenuActive == null ||
          leftMenuAllowedInteraction.includes(leftMenuActive)) &&
        !editInProgress,
      hover:
        !get(imageToGeorefAtom) &&
        (leftMenuActive == null ||
          leftMenuAllowedInteraction.includes(leftMenuActive)) &&
        !editInProgress,
      unselectOnClickOutside:
        !get(imageToGeorefAtom) &&
        leftMenuActive == null &&
        !editInProgress &&
        !get(cableEditModeAtom),
      createGeneratePolygon:
        !get(imageToGeorefAtom) &&
        leftMenuActive !== WindSourceMenuType &&
        !editInProgress &&
        !get(addFeatureAtom),
      canvasLayerPopupOptions:
        !get(imageToGeorefAtom) && !editInProgress && !get(addFeatureAtom),
      deleteFeatureKeyboardShortcut:
        !get(modalTypeOpenAtom) && !get(imageToGeorefAtom),
      leftSideMenuActive: !get(imageToGeorefAtom),
      projectControl: get(mapInteractionSelectorProjectControl),
      undoredo: !get(imageToGeorefAtom),
      paste:
        get(editorAccessProjectSelector) &&
        !get(modalTypeOpenAtom) &&
        !get(imageToGeorefAtom) &&
        !get(inReadOnlyModeSelector),
      copy: !get(modalTypeOpenAtom) && !get(imageToGeorefAtom),
      selectTurbineInLayout:
        get(leftMenuModeActiveAtom) !==
        GenerateWindTurbinesAndFoundationsMenuType,
      rightClickInMap: !editInProgress,
    };
  },
});

export const mapInteractionSelectorProjectControl = selector({
  key: "mapInteractionSelectorProjectControl",
  get: ({ get }) => {
    return !get(imageToGeorefAtom);
  },
});

export const selectedChildIndexForParentAtom = atom({
  key: "selectedChildIndexForParentAtom",
  default: {},
});

export const getActiveMapStyleSelector = selector<
  (typeof availableMapStyles)[0] | undefined
>({
  key: "getMapStyleSelector",
  get: ({ get }) => {
    const mapStyleId = get(activeMapStyleIdAtom);
    return getMapStyle(mapStyleId);
  },
});

export const activeMapStyleIdAtom = atom<string>({
  key: "mapStyleIdAtom",
  default: availableMapStyles[0].id,
  effects: [syncLocalStorage("vind:selected-map-style", z.string())],
});

export const activeBathymetryStyleIdAtom = atom<BathymetryStyleId | undefined>({
  key: "activeBathymetryStyle",
  default: "raster",
  effects: [
    syncLocalStorage("vind:selected-bathymetry-style", z.string().optional()),
  ],
});

export const activeBathymetryStyleSelector = selector({
  key: "activeBathymetryStyleSelector",
  get: ({ get }) => {
    const id = get(activeBathymetryStyleIdAtom);
    return getBathymetryStyle(id);
  },
});

export const mapStyleOpenAtom = atom({
  key: "mapStyleOpenAtom",
  default: false,
});

export const contourStepSizeAtom = atom({
  key: "contourStepSizeAtom",
  default: 25,
  effects: [syncLocalStorage("vind:map:contour-step-size", z.number())],
});

export const getActiveOtherMapSelector = selector({
  key: "getOtherMapSelector",
  get: ({ get }) => {
    const otherMapId = get(activeOtherMapIdAtom);
    return getOtherMap(otherMapId);
  },
});

export const activeOtherMapIdAtom = atom<OtherMapId | undefined>({
  key: "otherMapIdAtom",
  default: undefined,
});
