import { OrganisationAccessRole } from "./../types/user";
import { allOrganisationInvitationsAtomFamily } from "./../state/customer";
import { _OrganisationInvite, _OrganisationUser } from "./../types/customer";
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import {
  NotificationSettingsAtom,
  loggedInUserMetaInfoAtom,
  loggedInUserSelector,
} from "../state/user";
import { useCallback } from "react";
import {
  UserNotificationSettings,
  putUserMetaInfo,
  updateUserNotificationSettings,
  leaveNode,
} from "../services/userService";
import {
  fetchEnhancerWithToken,
  fetchSchemaWithToken,
} from "../services/utils";
import { UserAccessRole, UserMetaInfoUpdate } from "../types/user";
import { CustomerPersona } from "../types/customer";
import { _CustomerPersona } from "../types/customer";
import { nodeIdSelector, projectIdSelector } from "../state/pathParams";
import { initializeAndSet } from "../components/Comments/hooks/useReplyReactionCrud";
import { allCollaboratorsInNodeSelectorFamily } from "../state/customer";
import { useCheckAccessToken } from "./useRefreshTokenBeforeExpiration";
import { z } from "zod";
import { toastMessagesAtom } from "../state/toast";
import { useToast } from "hooks/useToast";

export const useUpdateUserNotifications = () => {
  const callback = useRecoilCallback(
    ({ set, snapshot }) =>
      async (settings: Partial<UserNotificationSettings>) => {
        const fallback = await snapshot.getPromise(NotificationSettingsAtom);
        const promise = updateUserNotificationSettings(settings)
          .then((res) =>
            initializeAndSet(
              snapshot,
              set,
              NotificationSettingsAtom,
              res.notification_settings,
            ),
          )
          .catch((e) => {
            set(NotificationSettingsAtom, fallback);
            throw e;
          });
        return promise;
      },
    [],
  );
  return callback;
};

export const useUpdateUserMeta = () => {
  const refreshToken = useCheckAccessToken();
  const callback = useRecoilCallback(
    ({ set, snapshot }) =>
      async (settings: UserMetaInfoUpdate) => {
        const fallback = await snapshot.getPromise(loggedInUserMetaInfoAtom);
        initializeAndSet(snapshot, set, loggedInUserMetaInfoAtom, (curr) => ({
          ...curr,
          ...settings,
        }));
        const promise = putUserMetaInfo(settings)
          .then((res) =>
            initializeAndSet(
              snapshot,
              set,
              loggedInUserMetaInfoAtom,
              (curr) => ({
                ...curr,
                ...res,
              }),
            ),
          )
          .then(() => refreshToken(true))
          .catch((e) => {
            set(loggedInUserMetaInfoAtom, fallback);
            throw e;
          });
        return promise;
      },
    [refreshToken],
  );
  return callback;
};

const _CreatedOrganisationInvitation = z.object({
  user_email: z.string(),
  status: z.literal("INVITATION_CREATED"),
  invitationId: z.string(),
});

export type CreatedOrganisationInvitation = z.infer<
  typeof _CreatedOrganisationInvitation
>;

const _UserAlreadyExistedInvitation = z.object({
  user_email: z.string(),
  status: z.literal("USER_ALREADY_EXISTED"),
  user_id: z.string(),
});

export type UserAlreadyExistedInvitation = z.infer<
  typeof _UserAlreadyExistedInvitation
>;

export const useAddMultipleToOrganisation = (organisationId: string) => {
  return useCallback(
    async (emails: string[], orgRole: OrganisationAccessRole) => {
      const response = await fetchSchemaWithToken(
        z.array(
          z.discriminatedUnion("status", [
            _UserAlreadyExistedInvitation,
            _CreatedOrganisationInvitation,
          ]),
        ),
        `/api/user/organisation/${organisationId}/inviteMultiple`,
        {
          method: "post",
          headers: {},
          body: JSON.stringify({
            org_role: orgRole,
            user_emails: emails,
          }),
        },
      );
      return response;
    },
    [organisationId],
  );
};

export const useUpdateInvitation = (organisationId: string) => {
  const { error: showError } = useToast();

  return useCallback(
    async (
      invitationId: string,
      nodes: Array<{ nodeId: string; role: UserAccessRole }>,
      groupIds: string[],
    ) => {
      try {
        const response = await fetchEnhancerWithToken(
          `/api/user/organisation/${organisationId}/${invitationId}`,
          {
            method: "post",
            headers: {},
            body: JSON.stringify({
              nodes,
              groups: groupIds,
            }),
          },
        );
        return response;
      } catch (error) {
        showError("Something went wrong when updating the invitation", {
          timeout: 5000,
        });
        throw error;
      }
    },
    [organisationId, showError],
  );
};

export const useDeleteInvitation = (organisationId: string) => {
  const setToastMessages = useSetRecoilState(toastMessagesAtom);

  return useRecoilCallback(
    ({ set }) =>
      async (invitationId: string) => {
        const promise = fetchEnhancerWithToken(
          `/api/user/${organisationId}/invite/${invitationId}`,
          {
            method: "delete",
            headers: {},
          },
        )
          .then(() => {
            set(allOrganisationInvitationsAtomFamily(organisationId), (curr) =>
              [...curr].filter(
                (invite) => invite.invitationId !== invitationId,
              ),
            );
            setToastMessages((tm) => [
              ...tm,
              {
                text: "Invite deleted",
                timeout: 3000,
                type: "success",
              },
            ]);
          })
          .catch(() =>
            setToastMessages((tm) => [
              ...tm,
              {
                text: "Failed to delete invite",
                timeout: 3000,
                type: "error",
              },
            ]),
          );
        return promise;
      },
    [organisationId, setToastMessages],
  );
};

export const useLeaveNode = (nodeId: string) => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        if (!nodeId) return;
        const currentUserId = (await snapshot.getPromise(loggedInUserSelector))
          ?.user_id;
        if (!currentUserId) return;
        const fallback = await snapshot.getPromise(
          allCollaboratorsInNodeSelectorFamily(nodeId),
        );
        set(allCollaboratorsInNodeSelectorFamily(nodeId), (curr) =>
          curr.filter((u) => u.user_id !== currentUserId),
        );
        const promise = leaveNode(nodeId).catch((e) => {
          set(allCollaboratorsInNodeSelectorFamily(nodeId), fallback);
          throw e;
        });
        return promise;
      },
    [nodeId],
  );
};

export const useGetUserFromIdOrgPage = (): ((
  id?: string,
) => CustomerPersona | undefined) => {
  const nodeId = useRecoilValue(nodeIdSelector);

  const usersInCustomer = useRecoilValue(
    allCollaboratorsInNodeSelectorFamily(nodeId),
  );

  return useCallback(
    (id?: string) =>
      id ? usersInCustomer?.find((u) => u.user_id === id) : undefined,
    [usersInCustomer],
  );
};

export const useGetUserFromIdProjectPage = (): ((
  id?: string,
) => CustomerPersona | undefined) => {
  const projectId = useRecoilValue(projectIdSelector);

  const usersInCustomer = useRecoilValue(
    allCollaboratorsInNodeSelectorFamily(projectId),
  );

  return useCallback(
    (id?: string) =>
      id ? usersInCustomer?.find((u) => u.user_id === id) : undefined,
    [usersInCustomer],
  );
};
