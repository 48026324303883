import Dropdown from "components/Dropdown/Dropdown";
import useGroupNodeAccessCrud from "components/Organisation/Groups/useGroupNodeAccessCrud";
import { useToast } from "hooks/useToast";
import { useState, useCallback } from "react";
import { useRecoilValue } from "recoil";
import { organisationIdSelector } from "state/pathParams";
import {
  adminInOrganisationSelectorFamily,
  userNodeAccessSelectorFamily,
} from "state/user";
import { UserAccessRole, _UserAccessRole } from "types/user";
import { scream } from "utils/sentry";
import { Text } from "../../style";

export const GroupNodeAccessDropdown = ({
  role,
  groupId,
  nodeId,
}: {
  role: UserAccessRole;
  groupId: string;
  nodeId: string;
}) => {
  const organisationId = useRecoilValue(organisationIdSelector);
  const isAdminInOrg = useRecoilValue(
    adminInOrganisationSelectorFamily({ organisationId }),
  );
  const nodeAccess = useRecoilValue(userNodeAccessSelectorFamily({ nodeId }));
  const isNodeAdmin = nodeAccess >= 2;
  const { addOrUpdate } = useGroupNodeAccessCrud();
  const [loading, setLoading] = useState(false);
  const { error } = useToast();
  const _updateRole = useCallback(
    async (groupId: string, newRole: UserAccessRole) => {
      try {
        setLoading(true);
        await addOrUpdate(groupId, nodeId, newRole);
      } catch (e) {
        scream("Failed to update group access", { e });
        error(
          "Something went wrong when updating access, the Vind team has been notified",
        );
      } finally {
        setLoading(false);
      }
    },
    [addOrUpdate, nodeId, error],
  );

  if (!isNodeAdmin && !isAdminInOrg)
    return <Text style={{ margin: 0 }}>{role}</Text>;

  return (
    <Dropdown
      kind="hidden"
      small
      disabled={loading}
      value={role}
      onChange={async (e) => {
        const newRole = _UserAccessRole.parse(e.target.value);
        _updateRole(groupId, newRole);
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <option value={"admin"}>Admin</option>
      <option value={"editor"}>Editor</option>
      <option value={"viewer"}>Viewer</option>
    </Dropdown>
  );
};
