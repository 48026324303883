import { sendWarning } from "../utils/sentry";

/**
 * The Union-Find data structure supports efficient component queries. One can
 * connect elments into components and query whether two elements are in the same
 * component or not.
 */
export class UnionFind<T> {
  map: Map<T, T> = new Map();

  add(a: T): boolean {
    if (this.map.has(a)) return false;
    this.map.set(a, a);
    return true;
  }

  /**
   * Connects the two elements `a` and `b`.
   */
  union(a: T, b: T): void {
    const aroot = this.find(a);
    const broot = this.find(b);
    if (aroot === broot) return;
    this.map.set(broot, aroot);
  }

  /** Ensure that the element is the cannonical element for its group. */
  makeRoot(a: T): void {
    let root = this.find(a);
    if (a === root) return;
    this.map.delete(a);
    this.map.set(root, a);
  }

  /**
   * Finds the cannonical element in the component to which `_a` belongs.
   * If `_a` is not in any component, returns `_a`.
   * @param _a
   * @returns a representative element from `_a`s component.
   */
  find(_a: T): T {
    let a = _a;
    if (!this.map.has(a)) return a;
    const guardMax = 1000;
    for (let guard = 0; guard < guardMax; guard++) {
      if (guard === guardMax - 1)
        throw sendWarning("UnionFind guardMax is too low (or we are looping)", {
          a,
          res: this.map.get(a),
        });
      if (!this.map.has(a)) return a;
      let aa = this.map.get(a)!;
      if (aa === a) break;
      a = aa;
    }
    // Set the component of the original element to the result to speed up queries in the future.
    this.map.set(_a, a);
    return a;
  }

  /**
   * Return all elements that are ni the same component of the given element.
   */
  group(t: T): T[] {
    const c = this.find(t);
    const group = [t, c];
    for (const k of this.map.keys()) {
      const cc = this.find(k);
      if (c === cc) group.push(k);
    }
    return [...new Set(group)];
  }

  /** Return a list of `[cannonicalElement, component[]]` so that it's possible to look at components. */
  groups(): [T, T[]][] {
    const groups = new Map<T, T[]>();
    for (const e of this.map.keys()) {
      const c = this.find(e);
      if (!groups.has(c)) groups.set(c, []);
      groups.get(c)!.push(e);
    }
    const g = [...groups.entries()];
    for (const [c, es] of g) if (!es.includes(c)) es.push(c);
    return g;
  }

  groupsSorted(fn: (a: T, b: T) => number): [T, T[]][] {
    const groups = this.groups().sort((a, b) => fn(a[0], b[0]));
    for (const t of groups) t[1].sort(fn);
    return groups;
  }
}
