import { UserAccessRole } from "../../../types/user";
import { useTypedPath } from "state/pathParams";
import { useRecoilCallback } from "recoil";
import {
  nodeGroupUserAccessSelector,
  userNodeAccessState,
} from "../Groups/state";
import {
  addOrUpdateUserNodeAccess,
  deleteUserNodeAccess,
} from "services/userService";

const useUserNodeAccessCrud = () => {
  const { organisationId } = useTypedPath("organisationId");

  const addOrUpdate = useRecoilCallback(
    ({ refresh }) =>
      async (userId: string, nodeId: string, role: UserAccessRole) => {
        return addOrUpdateUserNodeAccess(organisationId, userId, nodeId, role)
          .then(() => {
            refresh(userNodeAccessState({ organisationId, userId }));
            refresh(nodeGroupUserAccessSelector({ organisationId, nodeId }));
          })
          .catch(() => {});
      },
    [organisationId],
  );

  const addOrUpdateMultiple = useRecoilCallback(
    ({ refresh }) =>
      async (userIds: string[], nodeId: string, role: UserAccessRole) => {
        await Promise.all(
          userIds.map((userId) => {
            refresh(userNodeAccessState({ organisationId, userId }));
            return addOrUpdateUserNodeAccess(
              organisationId,
              userId,
              nodeId,
              role,
            );
          }),
        );
        refresh(nodeGroupUserAccessSelector({ organisationId, nodeId }));
      },
    [organisationId],
  );

  const remove = useRecoilCallback(
    ({ refresh }) =>
      async (userId: string, nodeId: string) => {
        return deleteUserNodeAccess(organisationId, userId, nodeId)
          .then(() => {
            refresh(userNodeAccessState({ organisationId, userId }));
            refresh(nodeGroupUserAccessSelector({ organisationId, nodeId }));
          })
          .catch(() => {});
      },
    [organisationId],
  );

  return { addOrUpdate, addOrUpdateMultiple, remove };
};

export default useUserNodeAccessCrud;
