import { lib, constants, modes } from "@vind-ai/mapbox-gl-draw";
const { doubleClickZoom } = lib;
import { circle, destination, distance, helpers, midpoint } from "@turf/turf";
import { LngLat } from "mapbox-gl";
import { colors } from "styles/colors";

// inspired by => https://github.com/iamanvesh/mapbox-gl-draw-circle

const ClickCircleMode = { ...modes.draw_polygon };

ClickCircleMode.onSetup = function () {
  const polygon = this.newFeature({
    type: constants.geojsonTypes.FEATURE,
    properties: {
      isCircle: true,
      center: [],
      radiusInKm: 0, // Initialize radius
    },
    geometry: {
      type: constants.geojsonTypes.POLYGON,
      coordinates: [],
    },
  });

  const state = {
    polygon,
    drawing: false, // Indicates if we are currently setting the radius
  };

  this.addFeature(polygon);
  this.clearSelectedFeatures();
  doubleClickZoom.disable(this);
  this.updateUIClasses({ mouse: constants.cursors.ADD });
  this.activateUIButton(constants.types.POLYGON);
  this.setActionableState({
    trash: true,
  });

  // Set up the keyboard listener
  this.setupKeyboardListener(state);

  return state;
};

ClickCircleMode.setupKeyboardListener = function (state) {
  const onKeyDown = (e) => {
    if (e.key === "Tab") {
      e.preventDefault(); // Prevent tabbing out of the application
      this.promptForRadiusInput(state);
    }
  };

  document.addEventListener("keydown", onKeyDown);

  this._keyDownListener = onKeyDown;
};

ClickCircleMode.tempAdjustCircleRadius = function (center, radiusInKm, state) {
  const tempCircleFeature = circle(center, radiusInKm);
  state.polygon.incomingCoords(tempCircleFeature.geometry.coordinates);
  this._ctx.store.render(); // Ensure rendering updates are applied
};

ClickCircleMode.promptForRadiusInput = function (state) {
  let input = document.getElementById("circle-radius-input");

  if (!input) {
    input = document.createElement("input");
    input.id = "circle-radius-input";
    input.type = "number";
    input.style.position = "absolute";
    input.style.zIndex = "1000";
    input.style.borderRadius = "4px";
    input.style.outline = "none";
    input.style.backgroundColor = "white";
    input.style.border = "none";
    input.style.width = "6rem";
    input.style.padding = "0.6rem 0.8rem";
    input.style.color = colors.primaryText;
    input.style.boxShadow = "0 2px 4px rgb(0 0 0 / 30%)";
    input.style.transform = "translate(-50%, -150%)";

    const style = document.createElement("style");
    style.textContent = `
      #circle-radius-input::-webkit-inner-spin-button,
      #circle-radius-input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      #circle-radius-input {
        -moz-appearance: textfield; /* Firefox */
        appearance: none; /* Reset the general appearance */
      }
    `;
    document.head.appendChild(style);

    // Initialize input with the current radius value in meters, if available
    const initialRadiusInMeters = state.polygon.properties.radiusInKm
      ? state.polygon.properties.radiusInKm * 1000
      : 0;

    input.value = initialRadiusInMeters.toFixed(0); // Format as needed

    // Position input field at the center of the circle
    if (state.polygon.properties.center.length > 0) {
      const center = state.polygon.properties.center;
      const map = this.map; // Assuming 'this.map' is your Mapbox GL JS map instance
      const centerPoint = map.project(new LngLat(center[0], center[1])); // Converts to pixel coordinates

      input.style.left = `${centerPoint.x}px`;
      input.style.top = `${centerPoint.y}px`;
    } else {
      // Fallback position if no center is defined
      input.style.left = "50%";
      input.style.top = "50%";
    }

    document.body.appendChild(input);

    // Dynamically update the circle as the user types or deletes characters.
    input.addEventListener("input", () => {
      const radiusInMeters = parseFloat(input.value);
      if (!isNaN(radiusInMeters) && radiusInMeters >= 0) {
        // Temporarily adjust the circle's radius for visual feedback
        const radiusInKm = radiusInMeters / 1000;
        this.tempAdjustCircleRadius(
          state.polygon.properties.center,
          radiusInKm,
          state,
        );
      }
    });

    // Finalize and save the circle on "Enter"
    input.addEventListener("keydown", (e) => {
      if (e.key === "Enter") {
        const radiusInMeters = parseFloat(input.value);
        if (!isNaN(radiusInMeters) && radiusInMeters >= 0) {
          const radiusInKm = radiusInMeters / 1000;
          this.adjustCircleRadius(
            state.polygon.properties.center,
            radiusInKm,
            state,
          );
        }
        this.changeMode(constants.modes.SIMPLE_SELECT, {
          featureIds: [state.polygon.id],
        });
        state.drawing = false; // Stop drawing
        e.preventDefault(); // Prevent default to avoid any form submission behavior
      } else if (e.key === "Escape") {
        // Cancel without saving the changes
        e.preventDefault();
      }
    });

    input.focus();
  }
};

// Adjust 'handleInputChange' to use the proper method name
ClickCircleMode.handleInputChange = function (input, state) {
  const radiusInMeters = parseFloat(input.value);

  if (!isNaN(radiusInMeters) && radiusInMeters > 0) {
    // Convert meters to kilometers for the circle function
    const radiusInKm = radiusInMeters / 1000;
    this.adjustCircleRadius(state.polygon.properties.center, radiusInKm, state);
  }
};

ClickCircleMode.adjustCircleRadius = function (center, radiusInKm, state) {
  const circleFeature = circle(center, radiusInKm);

  state.polygon.incomingCoords(circleFeature.geometry.coordinates);
  state.polygon.properties.radiusInKm = radiusInKm;

  // Use Turf.js to calculate the exact endpoint
  const endPoint = destination(center, radiusInKm, 90, { units: "kilometers" })
    .geometry.coordinates;

  this.drawRadiusLine(center, endPoint);

  const midpointCoord = midpoint(center, endPoint).geometry.coordinates;

  this.updateRadiusLabel(midpointCoord, radiusInKm);
  this._ctx.store.render();
};

ClickCircleMode.onClick = function (state, e) {
  if (!state.drawing) {
    // First click sets the center
    state.polygon.properties.center = [e.lngLat.lng, e.lngLat.lat];
    state.drawing = true; // Start drawing (setting the radius)
    this.promptForRadiusInput(state);
  } else {
    // Second click finalizes the circle
    this.changeMode(constants.modes.SIMPLE_SELECT, {
      featureIds: [state.polygon.id],
    });
    state.drawing = false; // Stop drawing
  }
};

ClickCircleMode.updateRadiusLabel = function (midpoint, distanceInKm) {
  const radiusText = `${(distanceInKm * 1000).toFixed(0)} m`;
  const labelFeature = {
    type: "Feature",
    properties: { description: radiusText },
    geometry: {
      type: "Point",
      coordinates: midpoint,
    },
  };
  // Check if the label layer exists, update it; otherwise, add a new layer
  if (this.map.getSource("radius-label-source")) {
    this.map.getSource("radius-label-source").setData(labelFeature);
  } else {
    this.map.addSource("radius-label-source", {
      type: "geojson",
      data: labelFeature,
    });
    this.map.addLayer({
      id: "radius-label",
      type: "symbol",
      source: "radius-label-source",
      layout: {
        "text-field": "{description}",
        "text-size": 14,
      },
      paint: {
        "text-color": "#333",
      },
    });
  }
};

ClickCircleMode.drawRadiusLine = function (center, endPoint) {
  const lineFeature = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: [center, endPoint],
    },
  };
  // Check if the layer exists, update it; otherwise, add a new layer
  if (this.map.getSource("radius-line-source")) {
    this.map.getSource("radius-line-source").setData(lineFeature);
  } else {
    this.map.addSource("radius-line-source", {
      type: "geojson",
      data: lineFeature,
    });
    this.map.addLayer({
      id: "radius-line",
      type: "line",
      source: "radius-line-source",
      layout: {},
      paint: {
        "line-color": "#fcba03", // Bright yellow color, similar to Mapbox's drawing color
        "line-width": 2,
        "line-opacity": 0.75, // Adjust opacity as needed
        "line-dasharray": [1, 1], // Creates a dotted line pattern, adjust as desired
      },
    });
  }
};

ClickCircleMode.onMouseMove = function (state, e) {
  if (state.drawing) {
    const center = state.polygon.properties.center;
    if (center.length > 0) {
      const endPoint = [e.lngLat.lng, e.lngLat.lat];
      const distanceInKm = distance(
        helpers.point(center),
        helpers.point(endPoint),
        { units: "kilometers" },
      );
      this.adjustCircleRadius(center, distanceInKm, state);
    }
    let input = document.getElementById("circle-radius-input");
    if (input) {
      const initialRadiusInMeters = state.polygon.properties.radiusInKm
        ? state.polygon.properties.radiusInKm * 1000
        : 0;
      input.value = initialRadiusInMeters.toFixed(0); // Format as needed
    }
  }
};

ClickCircleMode.toDisplayFeatures = function (state, geojson, display) {
  const isActivePolygon = geojson.properties.id === state.polygon.id;
  geojson.properties.active = isActivePolygon
    ? constants.activeStates.ACTIVE
    : constants.activeStates.INACTIVE;
  display(geojson);
};
ClickCircleMode.onStop = function (state) {
  if (this.map.getSource("radius-line-source")) {
    this.map.removeLayer("radius-line");
    this.map.removeSource("radius-line-source");
  }

  // Remove the radius label if it exists
  if (this.map.getSource("radius-label-source")) {
    this.map.removeLayer("radius-label");
    this.map.removeSource("radius-label-source");
  }

  // Remove the keyboard listener to prevent memory leaks
  if (this._keyDownListener) {
    document.removeEventListener("keydown", this._keyDownListener);
    this._keyDownListener = null;
  }

  // Remove the radius input if it exists
  const input = document.getElementById("circle-radius-input");
  if (input) {
    document.body.removeChild(input);
  }

  this.updateUIClasses({ mouse: constants.cursors.NONE });
  doubleClickZoom.enable(this);
  this.activateUIButton();

  // check to see if we've deleted this feature
  if (this.getFeature(state.polygon.id) === undefined) return;

  //remove last added coordinate
  state.polygon.removeCoordinate(`0.${state.currentVertexPosition}`);
  if (state.polygon.isValid()) {
    this.map.fire(constants.events.CREATE, {
      features: [state.polygon.toGeoJSON()],
    });
  } else {
    this.deleteFeature([state.polygon.id], { silent: true });
    this.changeMode(constants.modes.SIMPLE_SELECT, {}, { silent: true });
  }
};

ClickCircleMode.onTrash = function () {
  const tooltip = document.getElementById("circle-radius-tooltip");
  if (tooltip) {
    tooltip.remove();
  }
};

export default ClickCircleMode;
