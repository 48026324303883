import { Raster } from "types/raster";
import { z } from "zod";
import * as spec from "api/bathymetry";

export const _TileResponseStarted = spec.components.schemas.TileResponseStarted;
export type TileResponseStarted = z.infer<typeof _TileResponseStarted>;
export const _TileResponseFinished =
  spec.components.schemas.TileResponseFinished;
export type TileResponseFinished = z.infer<typeof _TileResponseFinished>;
export const _TileResponseError = spec.components.schemas.TileResponseError;
export type TileResponseError = z.infer<typeof _TileResponseError>;
export type TileResponseWithRaster = TileResponseFinished & {
  raster: Raster;
};

export const _SlopeResponseStarted =
  spec.components.schemas.SlopeResponseStarted;
export type SlopeResponseStarted = z.infer<typeof _SlopeResponseStarted>;
export const _SlopeResponseFinished =
  spec.components.schemas.SlopeResponseFinished;
export type SlopeResponseFinished = z.infer<typeof _SlopeResponseFinished>;
export const _SlopeResponseError = spec.components.schemas.SlopeResponseError;
export type SlopeResponseError = z.infer<typeof _SlopeResponseError>;
export type SlopeResponseWithRaster = SlopeResponseFinished & {
  raster: Raster;
};
