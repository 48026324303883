import { Load } from "../types/Load";
import { With } from "../utils/utils";
import { fetchEnhancerWithToken, fetchSchemaWithToken } from "./utils";
import { z } from "zod";
import * as spec from "../api/customer";
import { Body } from "../types/utils";

export const {
  Type: _Type,
  SharedNodeMutable: _SharedNodeMutable,
  SharedNodeInformation_: _SharedNodeInformation_,
  SharedNodeInformation: _SharedNodeInformation,
  PersonalFolderNodeInformation: _PersonalFolderNodeInformation,
  PersonalFolderNodeInformation: _PersonalFolderNodeInformation_,
  FolderNodeInformation: _FolderNodeInformation,
  FolderNodeInformation_: _FolderNodeInformation_,
  Project: _Project,
  ProjectNodeInformation: _ProjectNodeInformation,
  ProjectNodeInformation_: _ProjectNodeInformation_,
  OrganisationUserInformation: _OrganisationUserInformation,
  NodeInformation: _Node,
  NodeInformation_: _Node_,
  ProjectNodeMutable: _ProjectNodeMutable,
  PersonalFolderNodeMutable: _PersonalFolderNodeMutable,
  FolderNodeMutable: _FolderNodeMutable,
  NodeMutable: _NodeMutable,
  CreateNodeFolderBody: _CreateNodeFolderBody,
  CreateNodeProjectBody: _CreateNodeProjectBody,
} = spec.components.schemas;

export type LoadingFolder = {
  loading: true;
  id: string;
  createdAt: number;
  parent?: string;
};

export const _MoveNode = z.object({
  nodeId: z.string(),
  toNodeId: z.string(),
  topLevelNodeId: z.string(),
});
export type MoveNode = z.infer<typeof _MoveNode>;
export const _RemoveNode = z.object({
  nodeId: z.string(),
});

export type FolderNodeInformation = z.infer<typeof _FolderNodeInformation>;
export type ProjectNodeInformation = z.infer<typeof _ProjectNodeInformation>;
type OrganisationUserInformation = z.infer<typeof _OrganisationUserInformation>;

export const isProjectNodeInformation = (
  n: Node,
): n is ProjectNodeInformation => n.type === "project";

export type Node = z.infer<typeof _Node>;

export type NodeMutable = z.infer<typeof _NodeMutable>;

type LoadingNode = {
  loading: true;
  id: string;
  createdAt: number;
  parent?: string;
};

export type LoadNode = Load<Node, LoadingNode>;

export const listOrganisations = async (): Promise<
  OrganisationUserInformation[]
> => {
  return fetchSchemaWithToken(
    _OrganisationUserInformation.array(),
    `/api/customer/organisation/user`,
    {
      method: "get",
    },
  );
};

export const deleteNode = async (nodeId: string) =>
  await fetchEnhancerWithToken(`/api/customer/node/${nodeId}`, {
    method: "delete",
  });

export const getNode = async (nodeId: string) => {
  const res = await fetchEnhancerWithToken(`/api/customer/node/${nodeId}`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const j = await res.json();
  return _Node.parse(j);
};

export type PostNode = Body<typeof spec, "/api/customer/node/{nodeId}", "post">;
export const postNode = async (args: PostNode) => {
  const res = await fetchEnhancerWithToken(
    `/api/customer/node/${args.parent_id}`,
    {
      method: "post",
      body: JSON.stringify(args),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  const j = await res.json();
  return _Node.parse(j);
};

export const postTopLevelFolderNode = async (
  organisationId: string,
  name: string,
) => {
  const res = await fetchSchemaWithToken(
    z.record(z.string()),
    `/api/user/organisation/${organisationId}/team`,
    {
      method: "post",
      body: JSON.stringify({ team_name: name }),
      headers: {},
    },
  );
  return res;
};

type CreateProjectNode = Body<
  typeof spec,
  "/api/customer/v2/node/{nodeId}",
  "post"
>;
export const createProjectNode = async (args: CreateProjectNode) => {
  const res = await fetchEnhancerWithToken(
    `/api/customer/v2/node/${args.parent_id}`,
    {
      method: "post",
      body: JSON.stringify(args),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  const j = await res.json();
  return _Node.parse(j);
};

type PutNode = Body<typeof spec, "/api/customer/node/{nodeId}", "put">;
export const putNode = async (args: With<PutNode, "id">) => {
  const res = await fetchEnhancerWithToken(`/api/customer/node/${args.id}`, {
    method: "put",
    body: JSON.stringify(args),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const j = await res.json();
  return _Node.parse(j);
};

export const moveNode = async (nodeId: string, toNodeId: string) => {
  const res = await fetchEnhancerWithToken(
    `/api/customer/node/${nodeId}/move/${toNodeId}`,
    {
      method: "post",
      body: JSON.stringify({}),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  const j = await res.json();
  return _Node.parse(j);
};

export const getNodesInOrganisation = async (
  organisationId: string,
): Promise<Node[]> => {
  if (!organisationId) return [];
  const res = await fetchEnhancerWithToken(
    `/api/customer/node/organisation/${organisationId}`,
    {
      method: "get",
    },
  );
  const j = await res.json();
  return _Node.array().parse(j);
};
export const admin_getNodesInOrganisation = async (
  organisationId: string,
): Promise<Node[]> => {
  if (!organisationId) return [];
  const res = await fetchEnhancerWithToken(
    `/api/customer/node/organisation/${organisationId}/admin`,
    {
      method: "get",
    },
  );
  const j = await res.json();
  return _Node.array().parse(j);
};

export const getTopLevelNodeForNodeId = async (
  organisationId: string,
  nodeId: string,
): Promise<Node | undefined> => {
  if (!organisationId) return undefined;
  const res = await fetchEnhancerWithToken(
    `/api/customer/organisation/${organisationId}/team/node/${nodeId}`,
    {
      method: "get",
    },
  );
  try {
    const j = await res.json();
    return _Node.parse(j);
  } catch (e) {
    return;
  }
};
