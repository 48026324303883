import {
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { clamp } from "../../utils/utils";
import styled, { CSSProperties } from "styled-components";

/**
 * This class name is attached to the topmost <div> in the portal. This can be
 * useful if you are trying to detect clicks in the portal from the mounting
 * component, for instance in `useClickOutside`.
 */
export const AnchorClassName = "vind_anchor";

export type Place =
  | "topLeft"
  | "top"
  | "topRight"
  | "left"
  | "center"
  | "right"
  | "bottomLeft"
  | "bottom"
  | "bottomRight";

export const placeToTranslatePercent = (p: Place): [number, number] => {
  switch (p) {
    case "topLeft":
      return [0, 0];
    case "top":
      return [-50, 0];
    case "topRight":
      return [-100, 0];
    case "left":
      return [0, -50];
    case "center":
      return [-50, -50];
    case "right":
      return [-100, -50];
    case "bottomLeft":
      return [0, -100];
    case "bottom":
      return [-50, -100];
    case "bottomRight":
      return [-100, -100];
  }
};

export const oppositePlace = (p: Place): Place => {
  switch (p) {
    case "topLeft":
      return "bottomRight";
    case "top":
      return "bottom";
    case "topRight":
      return "bottomLeft";

    case "left":
      return "right";
    case "center":
      return "center";
    case "right":
      return "left";

    case "bottomLeft":
      return "topRight";
    case "bottom":
      return "top";
    case "bottomRight":
      return "topLeft";
  }
};

const dimToString = (dim: string | number) =>
  typeof dim === "number" ? `${dim}px` : dim;

const AnchorDiv = styled.div<{
  pos: { x: number; y: number };
  translateStyle: string;
  offset: [string | number, string | number];
}>`
  position: fixed;
  left: calc(${(p) => p.pos.x}px + ${(p) => dimToString(p.offset[0])});
  top: calc(${(p) => p.pos.y}px + ${(p) => dimToString(p.offset[1])});
  z-index: 10;
  transform: ${(p) => p.translateStyle};
  width: max-content;
`;

/**
 * Place a component floating above another component with {@link createPortal}.
 *
 * ### Positioning
 * You specify which points on the two elements should coincide.
 * For instance, if both places are `center`, then the centers of the two elements will be the same point.
 * If given `{ basePlace: 'top', floatPlace: 'bottomLeft'}` it means that the **center of the top** side of
 * `baseRef` will be at the same position as the bottom left corner of the child element.
 */
export const Anchor = ({
  basePlace = "center",
  floatPlace = "center",
  children,
  update,
  baseRef,
  allowOutside = false,
  offset,
  anchorStyle,
}: {
  basePlace?: Place;
  floatPlace?: Place;
  children?: ReactNode;
  /** Allow the anchored element to not be fully contained in the viewport.*/
  allowOutside?: boolean;
  /** This value will trigger a recomputation of the {@link baseRef} position when changed.*/
  update?: any;
  baseRef: RefObject<HTMLElement>;
  offset?: [string | number, string | number];
  anchorStyle?: CSSProperties;
}) => {
  const [pos, setPos] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const [floatingRef, setFloatingRef] = useState<HTMLDivElement | null>(null);

  const [updateState, setUpdateState] = useState(0);
  useEffect(() => {
    if (floatingRef === null) return;
    const ro = new ResizeObserver(() => {
      setUpdateState((c) => c + 1);
    });
    ro.observe(floatingRef);
    return () => {
      ro.disconnect();
    };
  }, [floatingRef]);

  const coordinatesFromBoundingRect = useMemo(() => {
    return (rect: DOMRect): { x: number; y: number } => {
      switch (basePlace) {
        case "topLeft":
          return { x: rect.left, y: rect.top };
        case "top":
          return { x: rect.left + rect.width / 2, y: rect.top };
        case "topRight":
          return { x: rect.right, y: rect.top };
        case "left":
          return { x: rect.left, y: rect.top + rect.height / 2 };
        case "center":
          return {
            x: rect.left + rect.width / 2,
            y: rect.top + rect.height / 2,
          };
        case "right":
          return { x: rect.right, y: rect.top + rect.height / 2 };
        case "bottomLeft":
          return { x: rect.left, y: rect.bottom };
        case "bottom":
          return { x: rect.left + rect.width / 2, y: rect.bottom };
        case "bottomRight":
          return { x: rect.right, y: rect.bottom };
      }
    };
  }, [basePlace]);

  // Adjust `translate(x, y)` so that we can have the floating element inside the viewport.
  const translateStyle = useMemo(() => {
    const [tx, ty] = placeToTranslatePercent(floatPlace);
    if (allowOutside) return `translate(${tx}%, ${ty}%)`;
    if (floatingRef === null) return "translate(0,0)";

    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    const { width, height } = floatingRef.getBoundingClientRect();
    const { x, y } = pos;

    const distLeft = x;
    const distRight = viewportWidth - (x + width);
    const distUp = y;
    const distDown = viewportHeight - (y + height);

    const minXTranslate = -distLeft / width;
    const maxXTranslate = distRight / width;
    const minYTranslate = -distUp / height;
    const maxYTranslate = distDown / height;

    const rx = clamp(100 * minXTranslate, tx, 100 * maxXTranslate);
    const ry = clamp(100 * minYTranslate, ty, 100 * maxYTranslate);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _ = updateState;

    return `translate(${rx}%, ${ry}%)`;
  }, [allowOutside, floatPlace, floatingRef, pos, updateState]);

  useEffect(() => {
    if (baseRef.current !== null) {
      const bcr = baseRef.current.getBoundingClientRect();
      const { x, y } = coordinatesFromBoundingRect(bcr);
      if (x !== pos.x || y !== pos.y) {
        setPos({ x, y });
      }
    }
  }, [baseRef, coordinatesFromBoundingRect, pos.x, pos.y, update, updateState]);

  const onRef = useCallback((node: HTMLDivElement) => {
    setFloatingRef(node);
  }, []);

  return createPortal(
    <AnchorDiv
      translateStyle={translateStyle}
      pos={pos}
      offset={offset ?? [0, 0]}
      ref={onRef}
      style={anchorStyle}
      className={AnchorClassName}
    >
      {children}
    </AnchorDiv>,
    baseRef.current?.ownerDocument.body ?? document.body,
  );
};
