import styled from "styled-components";

export const SVGWrapper = styled.div<{ size: number }>`
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    height: ${(p) => p.size}rem;
    width: ${(p) => p.size}rem;
  }
`;

export const SVGStrokeWrapper = styled.div<{
  size: number;
  color?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    height: ${(p) => p.size}rem;
    width: ${(p) => p.size}rem;
    path {
      stroke: white !important;
    }
  }
`;
