import React from "react";
import { atom } from "recoil";

export type ToastMessage = {
  text: React.ReactNode;
  timeout: number;
  id?: string;
  type?: "info" | "success" | "warning" | "error";
  link?: string;
  showCountdown?: boolean;
};

export const toastMessagesAtom = atom<ToastMessage[]>({
  key: "toastMessagesAtom",
  default: [],
});
