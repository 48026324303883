import * as turf from "@turf/turf";
import { Map } from "mapbox-gl";

export function getDragDirection(bbox: [mapboxgl.Point, mapboxgl.Point]) {
  const directionRight = bbox[0].x < bbox[1].x;
  return directionRight ? "right" : "left";
}

export function isFeatureInsideBbox(
  feature: mapboxgl.MapboxGeoJSONFeature,
  bboxPolygon: turf.helpers.Feature<
    turf.helpers.Polygon,
    turf.helpers.Properties
  >,
) {
  switch (feature.geometry.type) {
    case "Point":
      return turf.booleanPointInPolygon(
        feature.geometry.coordinates,
        bboxPolygon,
      );
    case "LineString":
    case "Polygon":
      return turf.booleanContains(bboxPolygon, feature);
    default:
      console.warn("Unhandled geometry type:", feature.geometry.type);
      return false;
  }
}

export function completelyInsideBboxFilter(
  map: Map,
  bbox: [mapboxgl.Point, mapboxgl.Point],
  features: mapboxgl.MapboxGeoJSONFeature[],
) {
  let startLatLng = map.unproject(bbox[0]);
  let endLatLng = map.unproject(bbox[1]);

  const bboxPolygon = turf.bboxPolygon([
    Math.min(startLatLng.lng, endLatLng.lng),
    Math.min(startLatLng.lat, endLatLng.lat),
    Math.max(startLatLng.lng, endLatLng.lng),
    Math.max(startLatLng.lat, endLatLng.lat),
  ]);

  return features.filter((feature) => {
    return isFeatureInsideBbox(feature, bboxPolygon);
  });
}
