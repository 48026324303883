import { useCallback, useMemo } from "react";
import { ABLY_WAKE_ANALYSIS_RESULT } from "../state/ably";
import { Types } from "ably";
import { useAblyGeneric } from "./useAblyGeneric";
import { useRecoilCallback } from "recoil";
import { AnalysisStatus, _AnalysisStatus } from "../functions/production";
import { initializeAndSet } from "../components/Comments/hooks/useReplyReactionCrud";
import { sendWarning } from "../utils/sentry";
import { analysisStatusAtomFamily } from "components/ProductionV2/state";

export function useAblyAnalysisStatus(projectId: string) {
  const setAnalysisStatus = useRecoilCallback(
    ({ set, snapshot }) =>
      async (status: AnalysisStatus) => {
        initializeAndSet(
          snapshot,
          set,
          analysisStatusAtomFamily({
            analysisStatusId: status.id,
          }),
          () => status,
        );
      },
    [],
  );

  const onAnalysisUpdate = useCallback(
    (message: Types.Message) => {
      const data = message.data;
      const ablyStatus = _AnalysisStatus.parse(data);
      setAnalysisStatus(ablyStatus);
      if (ablyStatus.status === "failed") {
        sendWarning("Wake analysis failed", { ablyStatus });
      }
    },
    [setAnalysisStatus],
  );

  const channelName = useMemo(() => `${projectId}:all`, [projectId]);

  const events = useMemo(
    () => [
      {
        eventName: ABLY_WAKE_ANALYSIS_RESULT,
        onMessageReceived: onAnalysisUpdate,
      },
    ],
    [onAnalysisUpdate],
  );

  useAblyGeneric(channelName, events);
}
