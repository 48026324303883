import styled from "styled-components";
import { spaceTiny } from "styles/space";
import { colors } from "styles/colors";
import { RoundIconWrapper } from "components/Design/TopbarNavigation/components/Middle/RoundIcon";
import { typography } from "styles/typography";

export const MiddleItemWrapper = styled.div<{
  disabled?: boolean;
  active: boolean;
}>`
  ${typography.graphics}
  display: flex;
  flex-direction: column;
  gap: ${spaceTiny};
  align-items: center;
  cursor: pointer;
  color: ${colors.textNegative};

  ${({ active }) =>
    active &&
    `
      &&& ${RoundIconWrapper} {
        background-color: ${colors.blue500};
        svg {
            path {
                fill: ${colors.iconNegative};
            }
        }
      }
    `}

  ${({ disabled }) =>
    disabled &&
    `
      color: ${colors.textDisabled}; 
      &&& ${RoundIconWrapper} {
        background-color: ${colors.blue800};
        svg {
            path {
                fill: ${colors.iconDisabled};
            }
        }
      }
    `}

  &:hover {
    ${RoundIconWrapper} {
      background-color: ${colors.blue300};
    }
  }
`;
