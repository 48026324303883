import {
  SplitAtLandfallPointErr,
  exportCableSplits,
} from "functions/elevation";
import { selectorFamily, useRecoilValue } from "recoil";
import { WarningProps } from "./ValidationWarnings";
import { useEffect } from "react";
import { ValidationWarningTypes } from "./utils";
import { ExportCableFeature } from "types/feature";
import { branchIdSelector } from "state/pathParams";

export const exportCableLandFallFailedWarningSelectorFamily = selectorFamily<
  | {
      type: ValidationWarningTypes.ExportCableLandfallFailed;
      data: {
        exportCableId: string;
        error: SplitAtLandfallPointErr;
      }[];
      parkId: string;
    }
  | undefined,
  {
    parkId: string;
    branchId: string;
  }
>({
  key: "exportCableLandFallFailedWarningSelectorFamily",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const splits = get(exportCableSplits({ parkId, branchId }));
      const failed = splits.filter(
        (
          s,
        ): s is {
          exportCable: ExportCableFeature;
          error: SplitAtLandfallPointErr;
        } => "error" in s,
      );

      return failed.length === 0
        ? undefined
        : {
            type: ValidationWarningTypes.ExportCableLandfallFailed,
            data: failed.map((s) => ({
              exportCableId: s.exportCable.id,
              error: s.error,
            })),
            parkId: parkId,
          };
    },
});

export const ExportCableLandfallFailed = ({
  parkId,
  warn,
  remove,
}: {
  parkId: string;
} & WarningProps) => {
  const branchId = useRecoilValue(branchIdSelector);
  const exportCableLandFallFailedWarning = useRecoilValue(
    exportCableLandFallFailedWarningSelectorFamily({
      parkId,
      branchId: branchId ?? "",
    }),
  );
  useEffect(() => {
    if (!exportCableLandFallFailedWarning) return;
    warn(exportCableLandFallFailedWarning);
    return () => {
      remove(ValidationWarningTypes.ExportCableLandfallFailed);
    };
  }, [exportCableLandFallFailedWarning, remove, warn]);

  return null;
};
