/// <reference types="vite-plugin-svgr/client" />
import React, { useCallback, useEffect, useRef } from "react";
import {
  useRecoilCallback,
  useRecoilRefresher_UNSTABLE,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import styled from "styled-components";
import {
  HeaderGroup,
  Row,
  useExpanded,
  usePagination,
  useSortBy,
  useTable,
  UseTableInstanceProps,
} from "react-table";
import { useTypedPath } from "../../../state/pathParams";
import SortAscendingIcon from "@icons/SortAscending.svg?react";
import SortDescendingIcon from "@icons/SortDescending.svg?react";
import SortNeutralIcon from "@icons/SortNeutral.svg?react";
import {
  activeFiltersAtom,
  filteredLayerListWithOverlapSelector,
} from "../layer-filter-state";
import { useLocalStorage } from "../../../hooks/useBrowserStorage";
import { typography } from "../../../styles/typography";
import {
  ExternalDataSource,
  ExternalLayer,
  Layer,
  LayerWithSearchRelevance,
  PrivateDataSource,
} from "../../../types/layers";
import { SkeletonText } from "../../Loading/Skeleton";
import { colors } from "../../../styles/colors";
import { useLayerSettingsCrud } from "../../LayerList/LayerSettings/useLayerSettingsCrud";
import {
  allLayerSettingsAtomFamily,
  layersSettingSelectorFamily,
  layerVisibleAtomFamily,
  selectedSourceNameAtom,
  SourceName,
} from "../../LayerList/LayerSettings/state";
import Dropdown from "../../Dropdown/Dropdown";
import { columns } from "../columns";
import Pagination from "./Pagination";
import LayerPreviewImageV2 from "./LayerPreviewImageV2";
import LayerDataCleaningView from "../../LayerList/LayerDataCleaning/LayerDataCleaning";
import { isCustomLayer, isPrivateSource } from "../../LayerList/utils";
import { deletePrivateDataSource } from "../../../services/gisDataAPIService";
import { privateGISSourceDataAPISelector } from "../../../state/privateGISSource";
import { Deprecated } from "../../Deprecated/Deprecated";
import { TranslatedLayerOrSourceLinkAbstract } from "../../LayerList/LayerInformation/LayerInformation";
import { scrollToLayerIdInListAtom } from "components/LayerList/state";
import useMapBBOX from "hooks/useMapBBOX";

const TableWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 0 2rem;

  * {
    box-sizing: border-box;
  }
`;

export const PaginationPositioner = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${colors.blue50};
  padding: 2rem;
  margin-top: 2rem;
`;

const makeLinksClickable = (text: string): React.ReactNode => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = text.split(urlRegex);

  return parts.map((part, index) => {
    if (part.match(urlRegex)) {
      return (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      );
    }

    return <span key={index}>{part}</span>;
  });
};

export const removeHtmlAndMakeClickableLinks = (
  text: string | undefined,
): React.ReactNode => {
  if (!text) {
    return null;
  }

  const elem = document.createElement("span");
  elem.innerHTML = text;
  return makeLinksClickable(elem.innerText);
};

const StickyThead = styled.thead`
  position: sticky;
  top: -1px;
  background-color: white;
  z-index: 2;
`;

const Header = ({ headerGroups }: { headerGroups: HeaderGroup<Layer>[] }) => {
  return (
    <StickyThead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <th
              {...column.getHeaderProps(
                column.getSortByToggleProps({
                  onClick: () => {
                    if (column.canSort) {
                      column.toggleSortBy(!column.isSortedDesc);
                    }
                  },
                  style: {
                    textAlign: "unset",
                    cursor: column.canSort ? "pointer" : "unset",
                    width: column.width,
                    padding: "1rem 0",
                    // backgroundColor: "red",
                  },
                }),
              )}
            >
              <span
                style={{
                  ...typography.label,
                }}
              >
                {column.render("Header")}
              </span>
              {column.canSort && (
                <span style={{ marginLeft: "0.3rem" }}>
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <SortDescendingIcon />
                    ) : (
                      <SortAscendingIcon />
                    )
                  ) : (
                    <SortNeutralIcon />
                  )}
                </span>
              )}
            </th>
          ))}
        </tr>
      ))}
    </StickyThead>
  );
};

const Pill = styled.div`
  display: flex;
  align-items: center;
  padding: 0.2rem 1.5rem;
  gap: 1rem;
  border-radius: 20px;
`;

export const TagPill = styled(Pill)`
  background-color: ${colors.purple};
  color: white;
`;

export const CountryPill = styled(Pill)`
  background-color: ${colors.port};
  color: white;
  text-transform: capitalize;
`;

export const SourcePill = styled(Pill)`
  background-color: ${colors.subArea};
  color: white;
`;

const RowLoadableData = ({
  row,
  cellProps,
}: {
  row: Row<Layer>;
  cellProps: any;
}) => {
  const source = row.original.source;
  const sourceLink = isCustomLayer(row.original)
    ? undefined
    : row.original.sourceLink;
  const sourceName = cellProps.getSourceName(source);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        flexGrow: 1,
        gap: "1rem",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {(row.original as any)["deprecated"] != null && (
          <div>
            <Deprecated>
              <h5 style={{ margin: 0, color: colors.redAlert }}>Deprecated</h5>
            </Deprecated>
            <span style={{ color: colors.redAlert }}>
              {removeHtmlAndMakeClickableLinks(
                (row.original as any)["deprecated"],
              )}
            </span>
          </div>
        )}
        {(row.original as any)["abstract"] && (
          <div>
            <h5 style={{ marginTop: 0 }}>Description</h5>
            <span>
              <TranslatedLayerOrSourceLinkAbstract
                layerOrSourceLink={row.original as ExternalLayer}
              />
            </span>
          </div>
        )}
        {sourceLink?.abstract && (
          <div>
            <h5 style={{ marginTop: 0 }}>Source description</h5>
            <span>
              <TranslatedLayerOrSourceLinkAbstract
                layerOrSourceLink={sourceLink}
              />
            </span>
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          gap: "1rem",
          flexWrap: "wrap",
        }}
      >
        {source?.countries?.map((country) => (
          <CountryPill key={country}>{country}</CountryPill>
        ))}
        <SourcePill>{sourceName}</SourcePill>
        {row.original.tags?.map((tag) => <TagPill key={tag}>{tag}</TagPill>)}
        {source && isPrivateSource(source) && sourceLink && (
          <SourcePill>{sourceLink.url}</SourcePill>
        )}
      </div>
    </div>
  );
};

const TR = styled.tr<{ isExpanded: boolean }>`
  &:hover {
    ${({ isExpanded }) =>
      !isExpanded &&
      `
      background-color: #f0f0f0;
      outline: 1px solid #E6E6E6;
      `}

    .show-on-hover {
      visibility: visible;
    }
  }

  .show-on-hover {
    visibility: hidden;
  }
`;

const Body = ({
  getTableBodyProps,
  page,
  prepareRow,
  cellProps,
}: {
  getTableBodyProps: UseTableInstanceProps<Layer>["getTableBodyProps"];
  page: Array<Row<Layer>>;
  prepareRow(row: Row<Layer>): void;
  cellProps: Record<string, any>;
}) => {
  return (
    <tbody {...getTableBodyProps()}>
      {page.map((row) => {
        prepareRow(row);

        return (
          <React.Fragment key={row.original.id}>
            <TR
              isExpanded={row.isExpanded}
              {...row.getRowProps({
                style: {
                  boxShadow: !row.isExpanded
                    ? "0 3px 12px rgba(51, 51, 51, 0.08)"
                    : "0 -10px 12px rgba(51, 51, 51, 0.08)",
                  cursor: "pointer",
                },
              })}
              onClick={() => row.toggleRowExpanded()}
            >
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps({
                      style: {
                        wordWrap: "break-word",
                        whiteSpace: "pre-wrap",
                      },
                    })}
                  >
                    <React.Suspense fallback={<SkeletonText />}>
                      {cell.render("Cell", cellProps)}
                    </React.Suspense>
                  </td>
                );
              })}
            </TR>
            {row.isExpanded && (
              <tr
                style={{
                  boxShadow: "0 10px 12px rgba(51, 51, 51, 0.08)",
                }}
              >
                <td
                  colSpan={6}
                  style={{ ...typography.label, padding: "0 1rem 1.5rem" }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                    }}
                  >
                    <LayerPreviewImageV2
                      layer={row.original}
                      clickToEnlarge={true}
                    />
                    <React.Suspense fallback={null}>
                      <RowLoadableData row={row} cellProps={cellProps} />
                    </React.Suspense>
                  </div>
                  {!isCustomLayer(row.original) && (
                    <LayerDataCleaningView inputLayer={row.original} />
                  )}
                </td>
              </tr>
            )}
          </React.Fragment>
        );
      })}
    </tbody>
  );
};

const DataLayersTable = ({
  onPageChange,
}: {
  onPageChange: (pageIndex: number) => void;
}) => {
  const { projectId } = useTypedPath("projectId");
  const activeFilters = useRecoilValue(activeFiltersAtom);
  const [mapBBOX] = useMapBBOX();
  const { layers: filteredLayerList, loadingState } = useRecoilValue(
    filteredLayerListWithOverlapSelector({
      projectId,
      mapBBOX,
    }),
  );
  const setScrollToLayerId = useSetRecoilState(scrollToLayerIdInListAtom);
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const [storedPageSize, setStoredPageSize] = useLocalStorage<number>(
    "vind:datalayers-page-size",
  );
  const { language: selectedSourceName } = useRecoilValue(
    selectedSourceNameAtom,
  );
  const resetPrivateGISSourceDataAPISelector = useRecoilRefresher_UNSTABLE(
    privateGISSourceDataAPISelector({ projectId }),
  );

  const getSourceName = useCallback(
    (source?: ExternalDataSource) => {
      const title =
        source &&
        selectedSourceName === SourceName.Original &&
        source.originalName
          ? source.originalName
          : source?.name ?? "";

      return title;
    },
    [selectedSourceName],
  );

  const allLayerSettings = useRecoilValue(
    allLayerSettingsAtomFamily({
      projectId,
    }),
  );

  const { put: putLayerSettings } = useLayerSettingsCrud();

  const setLayerVisible = useRecoilCallback(
    ({ set }) =>
      (id: string, visible: boolean) => {
        set(layerVisibleAtomFamily({ projectId, layerId: id }), visible);
      },
    [projectId],
  );

  const addLayer = useRecoilCallback(
    ({ snapshot }) =>
      async (layerId: string) => {
        const settings = snapshot.getLoadable(
          layersSettingSelectorFamily({
            projectId,
            layerIds: [layerId],
          }),
        );
        setLayerVisible(layerId, true);
        setScrollToLayerId(layerId);
        await putLayerSettings(settings.getValue());
      },
    [projectId, putLayerSettings, setLayerVisible, setScrollToLayerId],
  );

  const removeLayer = useRecoilCallback(
    ({ snapshot }) =>
      async (layerId: string) => {
        const settings = snapshot.getLoadable(
          layersSettingSelectorFamily({
            projectId,
            layerIds: [layerId],
          }),
        );
        setLayerVisible(layerId, false);
        await putLayerSettings(
          settings.getValue().map((s) => ({ ...s, selected: false })),
        );
      },
    [projectId, putLayerSettings, setLayerVisible],
  );

  const removePrivateSource = useCallback(
    async (source: PrivateDataSource) => {
      const url = [...source.wms, ...source.wfs, ...source.arcgis][0]?.url;
      if (!url || !projectId) {
        return;
      }

      await deletePrivateDataSource(projectId, url);
      resetPrivateGISSourceDataAPISelector();
    },
    [projectId, resetPrivateGISSourceDataAPISelector],
  );

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setSortBy,
    state: { pageIndex, pageSize, sortBy },
  } = useTable<LayerWithSearchRelevance>(
    {
      columns,
      data: filteredLayerList,
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetSortBy: false,
      initialState: {
        pageSize: Number(storedPageSize ?? 15),
        pageIndex: 0,
        sortBy: [{ id: "sourceName" }],
        hiddenColumns: ["searchRelevance"],
      },
    },
    useSortBy,
    useExpanded,
    usePagination,
  );

  useEffect(() => {
    onPageChange(pageIndex);
  }, [pageIndex, onPageChange]);

  // Go to page 0 when active filters/sortBy change
  useEffect(() => {
    gotoPage(0);
  }, [activeFilters, sortBy, gotoPage]);

  useEffect(() => {
    setSortBy([
      {
        id: "searchRelevance",
        desc: true,
      },
    ]);
  }, [activeFilters, setSortBy]);

  useEffect(() => {
    tableWrapperRef.current?.scroll({ top: 0 });
  }, [pageIndex]);

  return (
    <>
      {loadingState?.isLoading && (
        <span style={{ padding: "0 1rem" }}>
          Loading overlap {loadingState.nrLoading}/{loadingState.maxLayers}
        </span>
      )}
      <TableWrapper ref={tableWrapperRef}>
        <table
          {...getTableProps({
            style: {
              borderSpacing: "0 2rem",
              width: "100%",
            },
          })}
        >
          <Header headerGroups={headerGroups} />
          <Body
            page={page}
            getTableBodyProps={getTableBodyProps}
            cellProps={{
              addLayer,
              removeLayer,
              getIsAdded: (layerId: string) => {
                return allLayerSettings.some(
                  (setting) => setting.id === layerId,
                );
              },
              getSourceName,
              selectedSourceName,
              removePrivateSource,
            }}
            prepareRow={prepareRow}
          />
        </table>
      </TableWrapper>
      <PaginationPositioner>
        <Pagination
          currentPage={pageIndex}
          onPageChange={gotoPage}
          totalPages={pageCount}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          nextPage={nextPage}
          previousPage={previousPage}
        />
        <Dropdown
          value={pageSize}
          onChange={(e) => {
            const newVal = Number(e.target.value);
            setPageSize(newVal);
            setStoredPageSize(newVal);
          }}
        >
          {[15, 30, 60].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Page size: {pageSize}
            </option>
          ))}
        </Dropdown>
      </PaginationPositioner>
    </>
  );
};

export default DataLayersTable;
