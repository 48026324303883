import { atom } from "recoil";

export enum LeftModalMenuTypes {
  Comment = "Comment",
  Elements = "Elements",
  Layers = "Layers",
}

export const leftModalMenuOpenStateAtom = atom<LeftModalMenuTypes | undefined>({
  key: "leftModalMenuOpenStateAtom",
  default: undefined,
});
