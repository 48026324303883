import { TurbineFeature } from "../../types/feature";
import { v4 as uuidv4 } from "uuid";
import { TURBINE_PROPERTY_TYPE } from "../../constants/projectMapView";
import { ProjectFeature } from "../../types/feature";
import { CableFeature } from "../../types/feature";
import { DivisionFeature } from "../../types/feature";
import { inPark } from "../../state/park";
import { pointInPolygon } from "../../utils/geometry";
import { isTurbine, isPark, isCable } from "../../utils/predicates";
import { ParkFeature } from "../../types/feature";

export function point2TurbineFeature(
  coordinates: number[],
  parkId: string,
  turbineTypeId: string,
): TurbineFeature {
  const turbineId = uuidv4();
  return {
    type: "Feature",
    id: turbineId,
    geometry: { type: "Point", coordinates },
    properties: {
      id: turbineId,
      parentIds: [parkId],
      type: TURBINE_PROPERTY_TYPE,
      turbineTypeId,
    },
  };
}

/**
 * Find all ids of `CableFeature`s that are in the given polygon.
 */
export function getCableIdsInPolygon(
  projectData: ProjectFeature[],
  polygon: ParkFeature | DivisionFeature,
): string[] {
  const turbineMap = new Map<string, TurbineFeature>(
    projectData.filter(isTurbine).map((f) => [f.id, f]),
  );
  // If we filter out cables in a park,  we can just check the cable itself.
  // If we only filter out cables in a zone we need to check both ends of the cable
  // to see if either is in the zone.
  const inPolygon = isPark(polygon)
    ? inPark(polygon.id)
    : (f: CableFeature) => {
        const from = turbineMap.get(f.properties.fromId);
        const to = turbineMap.get(f.properties.toId);
        if (!from && !to) return false;

        const isFromZone =
          from && pointInPolygon(from.geometry, polygon.geometry);
        const isToZone = to && pointInPolygon(to.geometry, polygon.geometry);

        return isFromZone || isToZone;
      };

  return projectData
    .filter((f) => {
      if (!isCable(f)) return false;
      return inPolygon(f);
    })
    .map((f) => f.id);
}
