/// <reference types="vite-plugin-svgr/client" />
import React, { useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import { externalLayerFilterPropertyAtom } from "../../../state/layer";
import { TextIcon, TextRaw } from "../../../styles/typography";
import FilterIcon from "@icons/24/Filter.svg?react";
import PinnedIcon from "@icons/24/Pinned.svg?react";
import { modalTypeOpenAtom } from "../../../state/modal";
import { FilterExternalDataLayersModalType } from "../../FilterExternalDataLayers/FilterExternalDataLayers";
import { useLayerSettingsCrud } from "../../LayerList/LayerSettings/useLayerSettingsCrud";
import {
  getSelectedLayers,
  layerSettingSelectorFamily,
} from "../../LayerList/LayerSettings/state";
import { useTypedPath } from "../../../state/pathParams";
import { POLYGON_LAYERS_SUFFIX } from "../../../layers/ExternalLayers/dynamicPolygonLayers";
import { LINE_LAYERS_SUFFIX } from "../../../layers/ExternalLayers/dynamicLineLayers";
import { CIRCLE_LAYERS_SUFFIX } from "../../../layers/ExternalLayers/dynamicCircleLayers";
import { MenuFrame } from "../../MenuPopup/CloseableMenuPopup";
import { spaceMedium, spacing4 } from "../../../styles/space";
import { DYNAMIC_LAYER_TYPE } from "../../../constants/dynamic";
import {
  dynamicTileLineToLayerId,
  dynamicTileCircleToLayerId,
  dynamicTilePolygonToLayerId,
} from "../../../layers/ExternalLayers/dynamicTileJSONLayers";
import { currentExternalLayerSelection } from "../../../state/externalLayerSelection";
import { DotMenu } from "../../General/MenuButton";
import { MenuItem } from "../../General/Menu";
import { colors } from "../../../styles/colors";
import { resetListIfNotAlreadyEmpty } from "../../../utils/resetList";
import { Layer } from "../../../types/layers";
import { isCustomLayer } from "../../LayerList/utils";

const Scrollable = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: ${spaceMedium};
`;

const HiddenIconWrapper = styled(TextIcon)<{ visible?: boolean }>`
  opacity: ${(p) => (p.visible ? "1" : "0")};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${spaceMedium};

  &:hover ${HiddenIconWrapper} {
    opacity: 1;
  }
`;

const ValueWithMenu = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;

  p {
    margin: 0;
  }
`;

const BrokenWord = styled.p`
  word-break: break-all;
`;

const EllipsisWord = styled.p`
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  display: block;
`;

const KeyWrapper = styled.div`
  ${TextRaw};
  display: flex;
  align-items: center;
  text-transform: capitalize;
  min-width: 20%;
  margin: 0;
  word-break: break-all;

  svg {
    path {
      stroke: ${colors.secondaryText};
    }
  }
`;

const PropertyValueIsPinned = ({
  layerId,
  propertyKey,
  projectId,
}: {
  layerId: string;
  propertyKey: string;
  projectId: string;
}) => {
  const settings = useRecoilValue(
    layerSettingSelectorFamily({
      projectId,
      layerId: layerId,
    }),
  );
  const visible = (settings.layerStyle?.pinnedProperties ?? []).includes(
    propertyKey,
  );

  return visible ? (
    <PinnedIcon
      style={{
        width: "1.3rem",
        height: "1.3rem",
        marginLeft: "1rem",
      }}
    />
  ) : null;
};

const maybeGetLayerId = (feature: MapboxGeoJSONFeature) => {
  const endsWith = [
    POLYGON_LAYERS_SUFFIX,
    LINE_LAYERS_SUFFIX,
    CIRCLE_LAYERS_SUFFIX,
    DYNAMIC_LAYER_TYPE,
  ].find((suffix) => feature.layer.id.endsWith(suffix));

  if (endsWith) {
    return feature.layer.id.split(endsWith)[0];
  }

  const startsWith = [
    dynamicTileCircleToLayerId(""),
    dynamicTilePolygonToLayerId(""),
    dynamicTileLineToLayerId(""),
  ].find((prefix) => feature.layer.id.startsWith(prefix));

  if (startsWith) {
    return feature.layer.id.split(startsWith)[1];
  }
};

const getSourceName = (layer: Layer) => {
  if (isCustomLayer(layer)) {
    return layer.source;
  }
  return layer.source.name;
};

const ExternalInfoModal = ({
  feature,
  layerName,
}: {
  feature: MapboxGeoJSONFeature;
  layerName: string;
}) => {
  const { projectId } = useTypedPath("projectId");
  const setSelectedExternal = useSetRecoilState(currentExternalLayerSelection);
  const externalLayerFilterProperty = useRecoilValue(
    externalLayerFilterPropertyAtom,
  );
  const selectedLayers = useRecoilValue(getSelectedLayers({ projectId }));
  const { put: putLayerSettings } = useLayerSettingsCrud();
  const layerFilterProperty = externalLayerFilterProperty[layerName] || {};
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);

  const layerId = useMemo(() => maybeGetLayerId(feature), [feature]);
  const layer = useMemo(
    () => selectedLayers.find((l) => layerId && l.id === layerId),
    [selectedLayers, layerId],
  );

  const currentSettings = useRecoilValue(
    layerSettingSelectorFamily({
      projectId,
      layerId: layerId ?? "",
    }),
  );

  if (!feature?.properties) {
    return null;
  }

  return (
    <MenuFrame
      title="Information"
      style={{
        maxHeight: "80vh",
        position: "fixed",
        top: `calc(var(--top-bar-menu-height) + var(--branch-tab-bar-height) + ${spacing4})`,
        right: spacing4,
      }}
      onExit={() => setSelectedExternal(resetListIfNotAlreadyEmpty)}
    >
      <Scrollable>
        {layer && (
          <>
            <Row>
              <KeyWrapper>Source:</KeyWrapper>
              <EllipsisWord title={getSourceName(layer)}>
                {getSourceName(layer)}
              </EllipsisWord>
            </Row>
            <Row>
              <KeyWrapper>Layer:</KeyWrapper>
              <EllipsisWord title={layer.name}>{layer.name}</EllipsisWord>
            </Row>
          </>
        )}
        {Object.entries(feature.properties).map(([key, value]) => {
          const isFilteredOnProperty = Boolean(
            (layerFilterProperty as any)[key],
          );
          const isPinned = (
            currentSettings.layerStyle?.pinnedProperties ?? []
          ).includes(key);

          return (
            <Row key={key}>
              <KeyWrapper title={key}>
                {key}:
                {isFilteredOnProperty && (
                  <FilterIcon
                    style={{
                      width: "1.3rem",
                      height: "1.3rem",
                      marginLeft: "1rem",
                    }}
                    title="Is filtered on property"
                  />
                )}
                {layerId && (
                  <PropertyValueIsPinned
                    propertyKey={key}
                    layerId={layerId}
                    projectId={projectId}
                  />
                )}
              </KeyWrapper>
              <ValueWithMenu>
                <BrokenWord>{value?.toString() ?? "--"}</BrokenWord>
                <DotMenu
                  buttonStyle={{
                    margin: "0",
                    minWidth: "1rem",
                    maxWidth: "1rem",
                    height: "2.4rem",
                  }}
                >
                  <MenuItem
                    name={
                      isFilteredOnProperty ? "Edit filter" : "Create filter"
                    }
                    icon={<FilterIcon />}
                    onClick={() => {
                      setModalTypeOpen({
                        modalType: FilterExternalDataLayersModalType,
                        metadata: { source: layerName, property: key },
                      });
                    }}
                  />
                  <MenuItem
                    name={
                      isPinned
                        ? "Unpin property from map"
                        : "Pin property to map"
                    }
                    icon={<PinnedIcon />}
                    onClick={() => {
                      const newStyle = {
                        ...(currentSettings.layerStyle || {}),
                      };
                      const newPinnedProperties = (
                        newStyle?.pinnedProperties ?? []
                      ).includes(key)
                        ? newStyle.pinnedProperties!.filter((p) => p !== key)
                        : [...(newStyle.pinnedProperties ?? []), key];

                      putLayerSettings([
                        {
                          ...currentSettings,
                          id: currentSettings.id,
                          layerStyle: {
                            ...newStyle,
                            pinnedProperties: newPinnedProperties,
                          },
                        },
                      ]);
                    }}
                  />
                </DotMenu>
              </ValueWithMenu>
            </Row>
          );
        })}
      </Scrollable>
    </MenuFrame>
  );
};

export default ExternalInfoModal;
