export const suggestedLayers = [
  {
    // Open sea map
    layerId: "8513787cb83237a976df8c7247602079",
  },
  {
    // Petroleum pipelines
    layerId: "406af120e407535eade05f8950659b9f",
  },
  {
    // Substations
    layerId: "c952448f85d51954fcd26034445cc12c",
  },
  {
    // Power lines and cables
    layerId: "ee6b81f765f40b1e2ad70639e92b64f1",
  },
  {
    // Density all vessel types
    layerId: "558ca0ea61ec834f1e7714a9f92a63d5",
  },
];
