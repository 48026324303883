import styled from "styled-components";
import Spinner from "@icons/spinner/Spinner";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const HelperVideoType = styled.video`
  width: 100%;
  z-index: 2;
`;

const SpinnerWrapper = styled.div`
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const HelperVideo = ({ source }: { source: string }) => (
  <Wrapper>
    <SpinnerWrapper>
      <Spinner />
    </SpinnerWrapper>
    <HelperVideoType autoPlay loop>
      <source src={source} type="video/mp4" />
    </HelperVideoType>
  </Wrapper>
);
