/// <reference types="vite-plugin-svgr/client" />
import type { CSSProperties, HTMLAttributes } from "react";
import styled from "styled-components";
import SearchIcon from "@icons/24/Search.svg?react";
import { Input } from "../../../General/Input";
import { LineIconWrapper } from "../../../LayerList/LineIconWrapper";
import { colors } from "../../../../styles/colors";

const SearchBoxWrapper = styled.div`
  position: relative;
  flex-grow: 1;
`;

const inputStyle: CSSProperties = {
  background: "white",
  paddingRight: "4rem",
  width: "100%",
  borderColor: colors.dropdownBorder,
};

const iconWrapperStyle: CSSProperties = {
  display: "inline-flex",
  position: "absolute",
  height: "calc(100% - 8px)",
  right: 0,
  paddingRight: "0.8rem",
};

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, "onChange"> {
  value: string;
  onChange(search: string): void;
  autoFocus?: boolean;
}

const SearchBox = ({ onChange, style, value, autoFocus }: Props) => {
  return (
    <SearchBoxWrapper style={style}>
      <Input
        placeholder="Search layer, source or tag"
        autoFocus={autoFocus}
        style={inputStyle}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        type="search"
      />
      <LineIconWrapper style={iconWrapperStyle}>
        <SearchIcon stroke="red" style={{ stroke: "red" }} />
      </LineIconWrapper>
    </SearchBoxWrapper>
  );
};

export default SearchBox;
