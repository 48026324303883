import { z } from "zod";
import { fetchEnhancerWithToken } from "./utils";
import * as spec from "api/configurations";

export const _SubstationType = spec.components.schemas.SubstationType;
export type SubstationType = z.infer<typeof _SubstationType>;

export async function getSubstationTypes(nodeId: string, version?: number) {
  return fetchEnhancerWithToken(
    `/api/substation/node/${nodeId}${version ? `?version=${version}` : ""}`,
    {
      method: "get",
    },
  );
}

export async function createSubstationType(
  nodeId: string,
  substationType: z.infer<
    (typeof spec)["paths"]["/api/substation/node/{nodeId}"]["post"]["requestBody"]["application/json"]
  >,
): Promise<SubstationType> {
  return fetchEnhancerWithToken(`/api/substation/node/${nodeId}`, {
    method: "post",
    body: JSON.stringify(substationType),
    headers: {
      "Content-Type": "application/json",
    },
  }).then(async (res) => {
    if (!res.ok) throw new Error("Failed to create");
    const j = await res.json();
    return _SubstationType.parse(j);
  });
}
export async function updateSubstationType(
  nodeId: string,
  substationType: z.infer<
    (typeof spec)["paths"]["/api/substation/node/{nodeId}/type/{substationId}"]["post"]["requestBody"]["application/json"]
  >,
): Promise<void> {
  return fetchEnhancerWithToken(
    `/api/substation/node/${nodeId}/type/${substationType.id}`,
    {
      method: "post",
      body: JSON.stringify(substationType),
      headers: {
        "Content-Type": "application/json",
      },
    },
  ).then((res) => {
    if (!res.ok) throw new Error("Failed to update");
  });
}

export async function deleteSubstationType(
  nodeId: string,
  substationTypeId: string,
): Promise<void> {
  return fetchEnhancerWithToken(
    `/api/substation/node/${nodeId}/type/${substationTypeId}`,
    {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
    },
  ).then((res) => {
    if (!res.ok) throw new Error("Failed to delete");
  });
}
