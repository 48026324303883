import { useRecoilValue } from "recoil";
import { currentExternalLayerSelection } from "../../../state/externalLayerSelection";
import {
  currentSelectedProjectFeatures,
  currentSelectionWMSAtom,
} from "../../../state/selection";
import ProjectFeatureInfoModalV2 from "./ProjectFeatureInfoModal/ProjectFeatureInfoModalV2";
//import InfoModalDefault from "./Default/InfoModalDefault";
import ExternalInfoModal from "./ExternalInfoModal";
import WMSInfoModal from "./WMSInfoModal/WMSInfoModal";
import { ParentWrapper } from "./ProjectFeatureInfoModal/InfoModal.style";
import {
  ErrorBoundarySilent,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "../../ErrorBoundaries/ErrorBoundaryLocal";
import { parkIdSelector } from "state/pathParams";

const RightSideInner = () => {
  const selectedProjectFeatures = useRecoilValue(
    currentSelectedProjectFeatures,
  );
  const firstSelectedFeature = selectedProjectFeatures[0];
  const selectedExternalLayers = useRecoilValue(currentExternalLayerSelection);
  const firstSelectedExternal =
    selectedExternalLayers.length === 1 ? selectedExternalLayers[0] : undefined;

  const currentSelectionWMS = useRecoilValue(currentSelectionWMSAtom);
  const parkId = useRecoilValue(parkIdSelector);

  if (firstSelectedExternal) {
    return (
      <ExternalInfoModal
        feature={firstSelectedExternal}
        layerName={firstSelectedExternal.source}
      />
    );
  }

  if (currentSelectionWMS.length !== 0) {
    return <WMSInfoModal selections={currentSelectionWMS} />;
  }

  if (firstSelectedFeature) {
    return <ProjectFeatureInfoModalV2 featureId={firstSelectedFeature.id} />;
  }
  if (!firstSelectedFeature) {
    if (parkId) {
      return <ProjectFeatureInfoModalV2 featureId={parkId} />;
    }
    {
      /*return <InfoModalDefault />*/
    }
  }

  return null;
};

const RightSideV2 = ErrorBoundaryWrapper(
  () => {
    return (
      <ParentWrapper>
        <RightSideInner />
      </ParentWrapper>
    );
  },
  ErrorBoundarySilent,
  ScreamOnError,
);

export default RightSideV2;
