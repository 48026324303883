import { useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import {
  defaultDepthRasterMinMax,
  depthRasterMinMaxSelector,
  getActiveMapStyleSelector,
  mapRefAtom,
} from "../state/map";
import mapboxgl from "mapbox-gl";
import { Map } from "mapbox-gl";
import { scream } from "../utils/sentry";
import { LAYER_DEBUG_PRINT } from "../state/debug";

const MAP_SOURCE_DEPTH = "bathymetry-source";
export const DepthLayerId = "depth-layer";

class DepthLayer implements mapboxgl.CustomLayerInterface {
  id: string;
  type: "custom";
  renderingMode?: "3d";
  depthSource: string;
  map: Map | undefined;
  depthSourceCache: any;
  minMax: [number, number] = defaultDepthRasterMinMax;

  onRemove?(_map: mapboxgl.Map, _gl: WebGLRenderingContext): void {}
  prerender?(_gl: WebGLRenderingContext, _matrix: number[]): void {}

  constructor(depthSource: string, minMax: [number, number]) {
    this.id = DepthLayerId;
    this.type = "custom";
    this.depthSource = depthSource;
    this.minMax = minMax;
  }

  setMinMax(minMax: [number, number]) {
    this.minMax = minMax;
  }

  onAdd(map: Map, gl: WebGLRenderingContext) {
    LAYER_DEBUG_PRINT && console.log("DepthLayer.onAdd");
    this.map = map;
    this.depthSourceCache = (map as any).style._otherSourceCaches[
      this.depthSource
    ];
    this.depthSourceCache.pause();
    this.prepareShaders(gl);
    this.prepareBuffers(gl);
  }

  update() {
    const transform = (this.map as any).transform.clone();
    const pitchOffset =
      transform.cameraToCenterDistance * Math.sin(transform._pitch);
    transform.height = transform.height + pitchOffset;

    this.depthSourceCache._paused = false;

    this.depthSourceCache.used = true;

    this.depthSourceCache.update(transform);

    this.depthSourceCache.pause();
  }

  vertexArray: Int16Array | undefined;
  indexArray: Uint16Array | undefined;
  vertexBuffer: WebGLBuffer | undefined;
  indexBuffer: WebGLBuffer | undefined;
  program: WebGLProgram | undefined;
  aPos: number | undefined;
  prepareBuffers(gl: WebGLRenderingContext) {
    const n = 64;

    this.vertexArray = new Int16Array(n * n * 2);
    for (let i = 0; i < n; i++) {
      for (let j = 0; j < n; j++) {
        const vertex = [j * (8192 / (n - 1)), i * (8192 / (n - 1))];
        const offset = (i * n + j) * 2;
        this.vertexArray.set(vertex, offset);
      }
    }

    this.vertexBuffer = gl.createBuffer()!;
    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, this.vertexArray.buffer, gl.STATIC_DRAW);

    this.indexArray = new Uint16Array((n - 1) * (n - 1) * 6);
    let offset = 0;
    for (let i = 0; i < n - 1; i++) {
      for (let j = 0; j < n - 1; j++) {
        const index = i * n + j;
        const quad = [
          index,
          index + 1,
          index + n,
          index + n,
          index + 1,
          index + n + 1,
        ];
        this.indexArray.set(quad, offset);
        offset += 6;
      }
    }

    this.indexBuffer = gl.createBuffer()!;
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.indexBuffer);
    gl.bufferData(
      gl.ELEMENT_ARRAY_BUFFER,
      this.indexArray.buffer,
      gl.STATIC_DRAW,
    );
  }

  prepareShaders(gl: WebGLRenderingContext) {
    var vertexSource = `
  uniform mat4 u_matrix;
  uniform sampler2D u_depth_raster;
  uniform vec2 u_minMax;
  attribute vec2 a_pos;
  varying vec2 v_pos;
  void main() {
      v_pos = vec2(a_pos / 8192.0);
      gl_Position = u_matrix * vec4(a_pos, .0, 1.0);
  }`;

    var fragmentSource = `
  precision highp float;
  uniform sampler2D u_depth_raster;
  uniform vec2 u_minMax;
  varying vec2 v_pos;

  vec3 depthToColor(float depth) {
    float c = clamp((depth-u_minMax.x) / (u_minMax.y-u_minMax.x), 0., 1.);
    return mix(
      vec3(0.64, 0.78, 0.92),
      vec3(0.38, 0.47, 0.55),
      c
    );
  }


float terrariumDepth(sampler2D depth_raster, vec2 texCoord) {
  vec4 color = texture2D(depth_raster, texCoord);
  float R = color.r * 255.0;
  float G = color.g * 255.0;
  float B = color.b * 255.0;
  return ((R * 256.0 + G + B / 256.0) - 32768.0) * -1.;
}

  void main() {
    vec4 sample = texture2D(u_depth_raster, v_pos);
    float depth = terrariumDepth(u_depth_raster, v_pos);
    float alpha = clamp(sample.a, 0.0, 1.0);
    vec3 color = depthToColor(depth);
    gl_FragColor = vec4(color, alpha * step(10., depth));
  }`;

    var vertexShader = gl.createShader(gl.VERTEX_SHADER);
    if (!vertexShader) throw scream("Failed to create vertex shader", {});
    gl.shaderSource(vertexShader, vertexSource);
    gl.compileShader(vertexShader);
    var fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
    if (!fragmentShader) throw scream("Failed to create vertex shader", {});
    gl.shaderSource(fragmentShader, fragmentSource);
    gl.compileShader(fragmentShader);

    const program = gl.createProgram();
    if (!program) throw scream("Failed to create vertex shader", {});
    this.program = program;
    gl.attachShader(this.program, vertexShader);
    gl.attachShader(this.program, fragmentShader);
    gl.linkProgram(this.program);

    if (!gl.getProgramParameter(this.program, gl.LINK_STATUS)) {
      console.error(`Link failed: ${gl.getProgramInfoLog(this.program)}`);
      console.error(`vs info-log: ${gl.getShaderInfoLog(vertexShader)}`);
      console.error(`fs info-log: ${gl.getShaderInfoLog(fragmentShader)}`);
    }

    this.aPos = gl.getAttribLocation(this.program, "a_pos");
  }

  render(gl: WebGLRenderingContext, _matrix: number[]) {
    if (!this.indexArray) return;
    LAYER_DEBUG_PRINT && console.time("DepthLayer.render");
    if (
      !this.program ||
      !this.vertexBuffer ||
      this.aPos === undefined ||
      !this.indexBuffer
    )
      return;
    gl.useProgram(this.program);

    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

    // Bind vertex buffer
    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
    gl.enableVertexAttribArray(this.aPos);
    gl.vertexAttribPointer(this.aPos, 2, gl.SHORT, false, 0, 0);

    // Bind index buffer
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.indexBuffer);
    this.update();

    gl.uniform2fv(gl.getUniformLocation(this.program, "u_minMax"), this.minMax);

    let coords = this.depthSourceCache.getVisibleCoordinates().reverse();

    for (const coord of coords) {
      const depthTile = this.depthSourceCache.getTile(coord);

      // Bind depth raster texture to unit 0
      gl.activeTexture(gl.TEXTURE0);
      gl.bindTexture(gl.TEXTURE_2D, depthTile.texture.texture);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
      gl.uniform1i(gl.getUniformLocation(this.program, "u_depth_raster"), 0);

      // Bind matrix
      gl.uniformMatrix4fv(
        gl.getUniformLocation(this.program, "u_matrix"),
        false,
        coord.projMatrix,
      );

      // Draw
      const vertexCount = this.indexArray.length;
      const type = gl.UNSIGNED_SHORT;
      const offset = 0;
      gl.drawElements(gl.TRIANGLES, vertexCount, type, offset);
    }
    LAYER_DEBUG_PRINT && console.timeEnd("DepthLayer.render");
  }
}

const Bathymetry = () => {
  const mapStyle = useRecoilValue(getActiveMapStyleSelector);
  return <> {mapStyle?.useBathymetry && <BathymetryActive />} </>;
};

const BathymetryActive = () => {
  const depthRasterMinMax = useRecoilValue(depthRasterMinMaxSelector);
  const map = useRecoilValue(mapRefAtom);

  const bathymetrySource = useMemo(
    () => ({
      id: MAP_SOURCE_DEPTH,
      type: "raster" as const,
      tiles: [`/tiles/gebco-terrarium-2023/{z}/{x}/{y}.png`],
    }),
    [],
  );

  const depthLayer = useMemo(
    () => new DepthLayer(bathymetrySource.id, depthRasterMinMax),
    [bathymetrySource.id, depthRasterMinMax],
  );

  useEffect(() => {
    depthLayer.setMinMax(depthRasterMinMax);
    if (!map) return;
    map.triggerRepaint();
  }, [map, depthRasterMinMax, depthLayer]);

  useEffect(() => {
    if (!map) return;

    map.addSource(bathymetrySource.id, {
      type: bathymetrySource.type,
      tiles: bathymetrySource.tiles,
      tileSize: 512,
      maxzoom: 7,
    });
    try {
      map.addLayer(depthLayer, "aeroway-line");
    } catch (e) {
      return () => {
        map.removeSource(bathymetrySource.id);
      };
    }
    map.triggerRepaint();

    return () => {
      map.removeLayer(depthLayer.id);
      map.removeSource(bathymetrySource.id);
    };
  }, [map, depthLayer, bathymetrySource]);

  return null;
};

export default Bathymetry;
