import styled from "styled-components";
import { StandardBox } from "styles/boxes/Boxes";
import { colors } from "styles/colors";
import { spaceTiny } from "styles/space";
import { typography } from "styles/typography";

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  background-color: #00070f66;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Modal = styled(StandardBox)`
  color: ${colors.textPrimary};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1.8rem;
  width: 40rem;
  gap: 0.8rem;
  display: flex;
  flex-direction: column;
  overflow: visible;
  box-sizing: border-box;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  ${typography.h4}
`;

export const Subtitle = styled.div`
  color: ${colors.textSecondary};
  ${typography.sub4}
`;

export const GridValue = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: ${spaceTiny};
`;

export const GridKey = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${colors.borderSubtle};
  margin: 0 -2rem;
`;

export const OpenPreviewButton = styled.div`
  display: flex;
  padding: 0 0.9rem;
  &:hover {
    cursor: pointer;
}
  svg {
    width: 1rem;
    height: 1rem;
    stroke: ${colors.iconSubtle};
    &:hover {
        stroke: ${colors.iconHover};
  }
`;
