import { Layer } from "../../../types/layers";
import { isDefined } from "../../../utils/predicates";
import { LayerCollection } from "../LayerSettings/types";
import { BackendLayerCollection } from "./service";

export const expandLayerCollection2 = (
  collection: BackendLayerCollection,
  layersMap: Map<string, Layer>,
): LayerCollection => {
  const layerObjs = collection.layerIds
    .map((id) => layersMap.get(id))
    .filter(isDefined);
  return {
    name: collection.alias,
    layers: layerObjs,
    isDefault: collection.standard,
    id: collection.id,
  };
};

export function createDefaultName(collections: BackendLayerCollection[]) {
  const numberOfExistingCollections = collections.length;
  let defaultName = `My Folder ${numberOfExistingCollections + 1}`;
  const numberWithEqualDefaultName = collections.filter(
    (c) => c.alias === defaultName,
  ).length;
  if (numberWithEqualDefaultName > 0) {
    defaultName = defaultName + ` (${numberWithEqualDefaultName})`;
  }
  return defaultName;
}
