import { useCallback, useMemo, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { modalTypeOpenAtom } from "../../state/modal";
import { useTypedPath } from "../../state/pathParams";
import Spinner from "@icons/spinner/Spinner";
import { toastMessagesAtom } from "../../state/toast";
import { Mixpanel } from "../../mixpanel";
import useBooleanState from "../../hooks/useBooleanState";
import Checkbox from "../General/Checkbox";
import FullScreenModal from "../FullScreenModal/FullScreenModal";
import Button from "../General/Button";
import { useCollectionCrud } from "../LayerList/Collections/useCollectionCrud";
import { getLayerCollection } from "../LayerList/Collections/state";
import { isCustomLayer } from "../LayerList/utils";
import { sendWarning } from "../../utils/sentry";
import ProjectPicker from "components/Organisation/ProjectPicker";
import {
  getNodesWithMissingParents,
  nodesInOrganisationSelectorFamily,
} from "components/Projects/useOrganisationFolderCrud";
import { MenuFrame } from "components/MenuPopup/CloseableMenuPopup";
import { Column } from "components/General/Layout";

export const CopyCollectionModalType = "CopyCollectionModal" as const;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 0.8rem;
`;

const ProjectPickerWrapper = styled.div`
  display: flex;
  overflow-y: auto;
`;

const CopyCollectionModalInner = ({
  collectionId,
}: {
  collectionId: string;
}) => {
  const { organisationId, projectId } = useTypedPath(
    "organisationId",
    "projectId",
  );
  const setToastMessagesAtom = useSetRecoilState(toastMessagesAtom);
  const { copyCollection } = useCollectionCrud();
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [excludeCustomLayers, toggleExcludeCustomLayers] =
    useBooleanState(false);
  const collection = useRecoilValue(
    getLayerCollection({ collectionId, projectId }),
  );
  const [selectedProjectId, setSelectedProjectId] = useState<
    undefined | string
  >("");
  const customLayerIds = useMemo<string[]>(() => {
    if (!collection) {
      return [];
    }

    return collection.layers.filter(isCustomLayer).map((layer) => layer.id);
  }, [collection]);

  const onClose = useCallback(() => {
    setModalTypeOpen(undefined);
  }, [setModalTypeOpen]);

  const onSave = useCallback(async () => {
    Mixpanel.track("Copy collection", {});
    if (!selectedProjectId) {
      return;
    }

    setIsLoading(true);
    try {
      await copyCollection(
        collectionId,
        selectedProjectId,
        excludeCustomLayers ? [] : customLayerIds,
        excludeCustomLayers ? customLayerIds : [],
      );

      setToastMessagesAtom((curr) => [
        ...curr,
        {
          text: "The collection was copied to your other project",
          type: "success",
          timeout: 5000,
        },
      ]);
      onClose();
    } catch (error) {
      sendWarning("Something went wrong when trying to copy collection", {
        error,
        collection,
      });
      setToastMessagesAtom((curr) => [
        ...curr,
        {
          text: "Something went wrong when copying the collection, please try again.",
          type: "error",
          timeout: 10000,
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  }, [
    selectedProjectId,
    copyCollection,
    collectionId,
    customLayerIds,
    excludeCustomLayers,
    setToastMessagesAtom,
    onClose,
    collection,
  ]);

  const topNodes = useRecoilValue(
    getNodesWithMissingParents({ organisationId }),
  );
  const nodes = useRecoilValue(
    nodesInOrganisationSelectorFamily({ organisationId }),
  );

  return (
    <FullScreenModal>
      <MenuFrame
        title="Copy collection to project"
        onExit={onClose}
        style={{ width: "50rem", maxHeight: "70%" }}
      >
        <Column style={{ overflowY: "auto" }}>
          <ProjectPickerWrapper>
            <ProjectPicker
              selectedProjectId={selectedProjectId}
              setSelectedProjectId={setSelectedProjectId}
              topNodes={topNodes}
              allNodes={nodes}
            />
          </ProjectPickerWrapper>
          {customLayerIds.length > 0 && (
            <Checkbox
              checked={excludeCustomLayers}
              onClick={toggleExcludeCustomLayers}
              label="Exclude custom layers"
              labelPlacement="after"
            />
          )}
          <ButtonRow>
            <Button text="Cancel" buttonType="secondary" onClick={onClose} />
            <Button
              text="Save"
              buttonType="primary"
              disabled={!selectedProjectId || isLoading}
              onClick={onSave}
              icon={isLoading ? <Spinner size="1rem" /> : undefined}
            />
          </ButtonRow>
        </Column>
      </MenuFrame>
    </FullScreenModal>
  );
};

const CopyCollectionModal = () => {
  const modalTypeOpen = useRecoilValue(modalTypeOpenAtom);

  if (modalTypeOpen?.modalType !== CopyCollectionModalType) {
    return null;
  }

  return (
    <CopyCollectionModalInner
      collectionId={modalTypeOpen.metadata.collectionId}
    />
  );
};

export default CopyCollectionModal;
