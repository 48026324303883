import { z } from "zod";
import { LineStringFeature } from "../types/feature";
import { ANCHOR_PROPERTY_TYPE } from "../constants/projectMapView";
import { Position } from "geojson";
import { TurbineDistance, Angle } from "../components/Units/units";
import { keysMatch } from "./utils";
import { EventActions } from "./timeline";
import { CostUnit } from "./financial";
import { IAVoltageType } from "../services/cableTypeService";
import { AnchorFeature } from "./feature";
import { _WakeModel } from "services/configurationService";

export const _GenerationMethod = z.union([
  z.literal("regular"),
  z.literal("edge"),
  z.literal("regular_auto"),
]);
export type GenerationMethod = z.infer<typeof _GenerationMethod>;

// -------- Regular parameters --------
export const _RegularParameters = z.object({
  setNumberOfTurbines: z.boolean(),
  numberOfTurbines: z.number(),
  minorAxisSpacing: z.number(),
  majorAxisSpacing: z.number(),
  obliquity: z.number(),
  shiftX: z.number(),
  shiftY: z.number(),
  rotate: z.number(),
});

export type RegularParameters = z.infer<typeof _RegularParameters>;

export const _RegularParametersWithUnit = z.object({
  setNumberOfTurbines: z.boolean(),
  numberOfTurbines: z.number(),
  minorAxisSpacing: TurbineDistance.zod,
  majorAxisSpacing: TurbineDistance.zod,
  obliquity: Angle.zod,
  rotate: Angle.zod,
  shiftX: TurbineDistance.zod,
  shiftY: TurbineDistance.zod,
});

export type RegularParametersWithUnit = z.infer<
  typeof _RegularParametersWithUnit
>;
keysMatch<keyof RegularParameters, keyof RegularParametersWithUnit>();

// -------- Edge parameters --------
export const _AdditionalEdgeParameters = z.object({
  edgeSpacing: z.number(),
});
export const _AdditionalEdgeParametersWithUnit = z.object({
  edgeSpacing: TurbineDistance.zod,
});

export type AdditionalEdgeParameters = z.infer<
  typeof _AdditionalEdgeParameters
>;
export type AdditionalEdgeParametersWithUnit = z.infer<
  typeof _AdditionalEdgeParametersWithUnit
>;

export const _EdgeParameters = _RegularParameters.merge(
  _AdditionalEdgeParameters,
);
export const _EdgeParametersWithUnit = _RegularParametersWithUnit.merge(
  _AdditionalEdgeParametersWithUnit,
);
export type EdgeParameters = z.infer<typeof _EdgeParameters>;
export type EdgeParametersWithUnit = z.infer<typeof _EdgeParametersWithUnit>;
keysMatch<keyof EdgeParameters, keyof EdgeParametersWithUnit>();

// -------- Optimization parameters --------
export const _OptimizeParameters = z.object({
  numberOfTurbines: z.number(),
  minSpacing: z.number().default(3),
  includeEdge: z.boolean(),
  seed: z.number().optional(),
  includeNeighbours: z.boolean().default(true),
  overrideDataSource: z.boolean().default(false),
  wakeModel: _WakeModel.default("jensen"),
});
export const _OptimizeParametersWithUnit = z.object({
  numberOfTurbines: z.number(),
  minSpacing: z.number(),
  includeEdge: z.boolean(),
  seed: z.number().optional(),
  includeNeighbours: z.boolean().default(true),
  overrideDataSource: z.boolean().default(false),
  wakeModel: _WakeModel.default("jensen"),
});

export type OptimizeParameters = z.infer<typeof _OptimizeParameters>;
export type OptimizeParametersWithUnit = z.infer<
  typeof _OptimizeParametersWithUnit
>;

// -------- Common --------
export type GenerationParameters =
  | RegularParameters
  | EdgeParameters
  | OptimizeParameters;

export type GenerationMethodAndParameters =
  | { method: "regular"; params: RegularParameters }
  | { method: "edge"; params: EdgeParameters }
  | { method: "regular_auto"; params: OptimizeParameters };

export const _GenerationMethodAndParametersWithUnit = z.union([
  z.object({
    method: z.literal("regular"),
    params: _RegularParametersWithUnit,
  }),
  z.object({ method: z.literal("edge"), params: _EdgeParametersWithUnit }),
  z.object({
    method: z.literal("regular_auto"),
    params: _OptimizeParametersWithUnit,
  }),
]);

export type GenerationMethodAndParametersWithUnit =
  | { method: "regular"; params: RegularParametersWithUnit }
  | { method: "edge"; params: EdgeParametersWithUnit }
  | { method: "regular_auto"; params: OptimizeParameters };

export const _SimpleTurbineType = z.object({
  nodeId: z.string().optional(),
  name: z.string(),
  id: z.string(),
  note: z.string().nullish(),
  version: z.number().default(0),
  rnaMass: z.number(),
  hubHeight: z.number(),
  diameter: z.number(),
  ratedPower: z.number(),
  peakThrustSpeed: z.number().optional(),
  peakThrustCt: z.number().nullish(),
  archived: z.boolean().optional(),
  cost: z.number().default(0),
  costUnit: z
    .literal(CostUnit.millionEuroPerUnit)
    .default(CostUnit.millionEuroPerUnit),
  voltage: z
    .nativeEnum(IAVoltageType)
    .array()
    .default([IAVoltageType.kV66, IAVoltageType.kV132]),
});

export type SimpleTurbineType = z.infer<typeof _SimpleTurbineType>;

export const turbineLevel = ["project", "team", "library"] as const;

export const _TurbineLevel = z.enum(turbineLevel);

export type TurbineLevel = z.infer<typeof _TurbineLevel>;
export type SimpleTurbineTypeWithLevel = {
  level: TurbineLevel;
  turbine: SimpleTurbineType;
};

const _TurbineLoss = z.object({ name: z.string(), value: z.number() });
export type TurbineLoss = z.infer<typeof _TurbineLoss>;

export const _TurbineType = _SimpleTurbineType.merge(
  z.object({
    windSpeed: z.number().array(),
    power: z.number().array(),
    powerUnit: z.string(),
    thrustCoefficient: z.number().array(),
    ratedPower: z.number().optional(),
    referenceAirDensity: z.number().default(1.225),
    losses: _TurbineLoss.array().optional(),
    author: z.string().optional(),
    createdAt: z.number().optional(),
  }),
);
export type TurbineType = z.infer<typeof _TurbineType>;

export const _TurbineTypeKeyEvents = z.array(
  z.object({
    action: z.nativeEnum(EventActions),
    author: z.string().nullish(),
    start: z.number(),
    end: z.number(),
    meta: z.object({
      count: z.number(),
    }),
  }),
);
export type TurbineTypeKeyEvents = z.infer<typeof _TurbineTypeKeyEvents>;

export const makeAnchorFeature = (
  id: string,
  coordinates: Position,
  parkId: string,
): AnchorFeature => ({
  type: "Feature",
  id,
  geometry: {
    type: "Point",
    coordinates,
  },
  properties: {
    type: ANCHOR_PROPERTY_TYPE,
    id,
    parentIds: [parkId],
  },
});

export const replaceEndpointsLineString = <F extends LineStringFeature>(
  f: F,
  from: Position,
  to: Position,
): F => {
  return {
    ...f,
    geometry: {
      ...f.geometry,
      coordinates: [from, ...f.geometry.coordinates.slice(1, -1), to],
    },
  };
};

export const DEFAULT_TURBINES: TurbineType[] = [
  // https://github.com/IEAWindTask37/IEA-22-280-RWT/blob/main/Documentation/IEA-22-280-RWT_tabular.xlsx
  {
    id: "iea_22MW",
    version: 0,
    name: "IEA 22MW",
    hubHeight: 170,
    referenceAirDensity: 1.225,
    diameter: 283,
    rnaMass: 1_208_000,
    ratedPower: 22000,
    peakThrustSpeed: 11.78,
    peakThrustCt: 0.4966,
    windSpeed: [
      3, 3.55, 4.07, 4.55, 5.01, 5.42, 5.81, 6.15, 6.46, 6.73, 6.97, 7.16, 7.31,
      7.43, 7.5, 7.53, 7.54, 7.59, 7.68, 7.8, 7.97, 8.18, 8.42, 8.71, 9.03,
      9.39, 9.78, 10.21, 10.67, 11.17, 11.7, 11.78, 12.26, 12.85, 13.47, 14.11,
      14.78, 15.47, 16.19, 16.92, 17.67, 18.44, 19.23, 20.03, 20.84, 21.66,
      22.49, 23.32, 24.16, 25,
    ],
    power: [
      422, 730, 1098, 1541, 2047, 2604, 3194, 3800, 4402, 4980, 5513, 5986,
      6380, 6683, 6885, 6978, 6997, 7129, 7378, 7751, 8260, 8919, 9746, 10765,
      12002, 13907, 15575, 17170, 18708, 20200, 21753, 22000, 22000, 22000,
      22000, 22000, 22000, 22000, 22000, 22000, 22000, 22000, 22000, 22000,
      22000, 22000, 22000, 22000, 22000, 22000,
    ],
    powerUnit: "kW",
    thrustCoefficient: [
      0.9097, 0.8553, 0.8553, 0.8553, 0.8553, 0.8553, 0.8553, 0.8553, 0.8553,
      0.8553, 0.8553, 0.8553, 0.8553, 0.8553, 0.8553, 0.8553, 0.8553, 0.8553,
      0.8553, 0.8553, 0.8553, 0.8553, 0.8553, 0.8553, 0.8553, 0.7741, 0.7205,
      0.6611, 0.6049, 0.5523, 0.5035, 0.4966, 0.4252, 0.3587, 0.3051, 0.2611,
      0.2245, 0.194, 0.1683, 0.1466, 0.1282, 0.1126, 0.0993, 0.0879, 0.0782,
      0.0698, 0.0625, 0.0563, 0.0508, 0.0461,
    ],
    cost: 19.8, // Based on same equation as generic turbines
    costUnit: CostUnit.millionEuroPerUnit,
    voltage: [IAVoltageType.kV66, IAVoltageType.kV132],
    note: "As defined in https://github.com/IEAWindTask37/IEA-22-280-RWT/blob/main/Documentation/IEA-22-280-RWT_tabular.xlsx",
  },
  // https://www.nrel.gov/docs/fy20osti/75698.pdf
  {
    id: "iea_15MW",
    version: 0,
    name: "IEA 15MW",
    hubHeight: 150,
    referenceAirDensity: 1.225,
    diameter: 240,
    rnaMass: 1_017_000,
    ratedPower: 15000,
    peakThrustSpeed: 10.5,
    peakThrustCt: 0.801777,
    windSpeed: [
      0, 2.9, 3, 3.5, 4, 4.5, 4.75, 5, 5.25, 6, 6.2, 6.4, 6.5, 6.55, 6.6, 6.7,
      6.8, 6.9, 6.92, 6.93, 6.94, 6.95, 6.96, 6.97, 6.98, 6.99, 7, 7.5, 8, 8.5,
      9, 9.5, 10, 10.25, 10.5, 10.6, 10.7, 10.72, 10.74, 10.76, 10.78, 10.784,
      10.786, 10.787, 10.788, 10.789, 10.79, 10.8, 10.9, 11, 11.25, 11.5, 11.75,
      12, 13, 14, 15, 17.5, 20, 22.5, 25.01, 25.02, 50,
    ],
    power: [
      0, 0, 70, 301, 593, 961, 1180, 1423, 1688, 2645, 2945, 3262, 3428, 3513,
      3600, 3775, 3955, 4138, 4176, 4194, 4213, 4231, 4250, 4268, 4287, 4305,
      4324, 5319, 6455, 7743, 9192, 10811, 12609, 13581, 14599, 15000, 15000,
      15000, 15000, 15000, 15000, 15000, 15000, 15000, 15000, 15000, 15000,
      15000, 15000, 15000, 15000, 15000, 15000, 15000, 15000, 15000, 15000,
      15000, 15000, 15000, 15000, 0, 0,
    ],
    powerUnit: "kW",
    thrustCoefficient: [
      0, 0, 0.819749, 0.801112, 0.808268, 0.821911, 0.822876, 0.823266,
      0.830989, 0.834932, 0.833619, 0.831805, 0.829011, 0.826909, 0.824741,
      0.82043, 0.816176, 0.8112, 0.809741, 0.808781, 0.808102, 0.807567,
      0.807252, 0.806624, 0.806496, 0.806806, 0.806651, 0.80547, 0.804572,
      0.803949, 0.803905, 0.803709, 0.803452, 0.801706, 0.801777, 0.768658,
      0.707315, 0.698508, 0.690212, 0.682336, 0.674836, 0.673371, 0.672646,
      0.672283, 0.671922, 0.671564, 0.671387, 0.66764, 0.635292, 0.607278,
      0.548966, 0.501379, 0.460983, 0.425966, 0.321166, 0.251102, 0.201415,
      0.125654, 0.085067, 0.061026, 0.045815, 0, 0,
    ],
    cost: 13.7,
    costUnit: CostUnit.millionEuroPerUnit,
    voltage: [IAVoltageType.kV66, IAVoltageType.kV132],
    note: "As defined in https://www.nrel.gov/docs/fy20osti/75698.pdf",
  },
  // https://www.nrel.gov/docs/fy19osti/73492.pdf
  {
    id: "iea_10MW",
    version: 0,
    name: "IEA 10MW",
    hubHeight: 119,
    diameter: 198,
    rnaMass: 721_787,
    ratedPower: 10000,
    referenceAirDensity: 1.225,
    peakThrustSpeed: 10.7577,
    peakThrustCt: 0.7587,
    windSpeed: [
      0, 2.9, 3, 4, 4.5147, 5.0008, 5.4574, 5.8833, 6.2777, 6.6397, 6.9684,
      7.2632, 7.5234, 7.7484, 7.9377, 8.0909, 8.2077, 8.2877, 8.3308, 8.337,
      8.3678, 8.4356, 8.5401, 8.6812, 8.8585, 9.0717, 9.3202, 9.6035, 9.921,
      10.272, 10.6557, 10.7577, 11.5177, 11.9941, 12.4994, 13.0324, 13.592,
      14.1769, 14.7859, 15.4175, 16.0704, 16.7432, 17.4342, 18.1421, 18.8652,
      19.6019, 20.3506, 21.1096, 21.8773, 22.6519, 23.4317, 24.215, 25.01,
      25.02, 50,
    ],
    power: [
      0, 0, 38, 392, 653, 950, 1274, 1625, 1994, 2370, 2743, 3106, 3452, 3771,
      4054, 4293, 4482, 4614, 4687, 4697, 4749, 4866, 5049, 5303, 5635, 6051,
      6562, 7179, 7915, 8800, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
      10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
      10000, 10000, 10000, 10000, 10000, 10000, 0, 0,
    ],
    powerUnit: "kW",
    thrustCoefficient: [
      0, 0, 0.7701, 0.7701, 0.7763, 0.7824, 0.782, 0.7802, 0.7772, 0.7719,
      0.7768, 0.7768, 0.7768, 0.7768, 0.7768, 0.7768, 0.7768, 0.7768, 0.7768,
      0.7768, 0.7768, 0.7768, 0.7768, 0.7768, 0.7768, 0.7768, 0.7768, 0.7768,
      0.7768, 0.7675, 0.7651, 0.7587, 0.5056, 0.431, 0.3708, 0.3209, 0.2788,
      0.2432, 0.2128, 0.1868, 0.1645, 0.1454, 0.1289, 0.1147, 0.1024, 0.0918,
      0.0825, 0.0745, 0.0675, 0.0613, 0.0559, 0.0512, 0.047, 0, 0,
    ],
    cost: 8.7,
    costUnit: CostUnit.millionEuroPerUnit,
    voltage: [IAVoltageType.kV66, IAVoltageType.kV132],
    note: "As defined in https://www.nrel.gov/docs/fy19osti/73492.pdf",
  },
  // https://www.nrel.gov/docs/fy09osti/38060.pdf
  {
    archived: true,
    id: "nrel_5MW",
    version: 0,
    name: "NREL 5MW",
    hubHeight: 90,
    diameter: 126,
    rnaMass: 110_000 + 240_000,
    ratedPower: 5000,
    referenceAirDensity: 1.225,
    peakThrustSpeed: 11.5,
    peakThrustCt: 0.70701647,
    windSpeed: [
      0, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10,
      10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17,
      17.5, 18, 18.5, 19, 19.5, 20, 20.5, 21, 21.5, 22, 22.5, 23, 23.5, 24,
      24.5, 25, 25.01, 25.02, 50,
    ],
    power: [
      0, 0, 0, 37, 95, 171, 268, 388, 534, 707, 910, 1143, 1407, 1707, 2047,
      2431, 2858, 3329, 3843, 4404, 5000, 5000, 5000, 5000, 5000, 5000, 5000,
      5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000,
      5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 4717, 0, 0,
    ],
    powerUnit: "kW",
    thrustCoefficient: [
      0, 0, 0, 0.99, 0.99, 0.97373036, 0.92826162, 0.89210543, 0.86100905,
      0.835423, 0.81237673, 0.79225789, 0.77584769, 0.7629228, 0.76156073,
      0.76261984, 0.76169723, 0.75232027, 0.74026851, 0.72987175, 0.70701647,
      0.54054532, 0.45509459, 0.39343381, 0.34250785, 0.30487242, 0.27164979,
      0.24361964, 0.21973831, 0.19918151, 0.18131868, 0.16537679, 0.15103727,
      0.13998636, 0.1289037, 0.11970413, 0.11087113, 0.10339901, 0.09617888,
      0.09009926, 0.08395078, 0.0791188, 0.07448356, 0.07050731, 0.06684119,
      0.06345518, 0.06032267, 0.05741999, 0.05472609, 0, 0,
    ],
    cost: 3.7,
    costUnit: CostUnit.millionEuroPerUnit,
    voltage: [IAVoltageType.kV66, IAVoltageType.kV132],
  },
  // note: archived / not in use
  {
    id: "vestas_2MW",
    version: 0,
    name: "Vestas 2.0MW",
    archived: true,
    referenceAirDensity: 1.225,
    ratedPower: 2000,
    hubHeight: 70,
    diameter: 80,
    rnaMass: 0,
    peakThrustSpeed: 12,
    peakThrustCt: 0.71,
    windSpeed: [
      0, 3.5, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 25.01,
    ],
    power: [
      0, 0, 66, 154, 282, 460, 696, 996, 1341, 1661, 1866, 1958, 1988, 1997,
      1999, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 0,
    ],
    powerUnit: "kW",
    thrustCoefficient: [
      0, 0, 0.82, 0.81, 0.8, 0.81, 0.81, 0.81, 0.79, 0.74, 0.71, 0.41, 0.31,
      0.25, 0.2, 0.17, 0.14, 0.12, 0.1, 0.09, 0.08, 0.07, 0.06, 0.05, 0.0,
    ],
    cost: 0,
    costUnit: CostUnit.millionEuroPerUnit,
    voltage: [IAVoltageType.kV66, IAVoltageType.kV132],
  },
];

const _NoiseTemperatures = z.union([
  z.literal(10),
  z.literal(20),
  z.literal(30),
]);

export type NoiseTemperatures = z.infer<typeof _NoiseTemperatures>;

export const _TurbineNoiseSettings = z.object({
  source: z.number().or(z.string().transform((s) => parseFloat(s))),
  red: z.number().or(z.string().transform((s) => parseFloat(s))),
  yellow: z.number().or(z.string().transform((s) => parseFloat(s))),
  opacity: z.number().or(z.string().transform((s) => parseFloat(s))),
  temperature: _NoiseTemperatures,
});
export type TurbineNoiseSettings = z.infer<typeof _TurbineNoiseSettings>;

export const _TurbineTypeUsage = z.object({
  turbineTypeId: z.string(),
  projectId: z.string(),
  branchId: z.string(),
  featureId: z.string(),
});
export type TurbineTypeUsageType = z.infer<typeof _TurbineTypeUsage>;
