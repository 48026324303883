/// <reference types="vite-plugin-svgr/client" />
import React, { useMemo, useRef } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { Mixpanel } from "mixpanel";
import EarthIcon from "@icons/14/Earth.svg?react";
import {
  filteredPortfolioParkItemsAtom,
  mappedPortfolioItemsWithFilteredAtom,
} from "state/portfolio";
import { useClickOutside } from "hooks/useClickOutside";
import {
  borderRadiusMedium,
  borderRadiusSmall,
  spaceDecent,
  spaceHuge,
  spaceLarge,
  spaceSmall,
  spaceTiny,
} from "styles/space";
import Checkbox from "components/General/Checkbox";
import { IconREMSize, typography } from "styles/typography";
import { colors } from "styles/colors";
import useBooleanState from "hooks/useBooleanState";
import { Frame } from "components/General/Layout";
import { EarthIconWrapper } from "../style";

const ParkFilterFrameWrapper = styled.div`
  position: relative;
  z-index: 2;
`;
const ParkFilterFrame = styled(Frame)`
  position: absolute;
  padding: ${spaceHuge};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: ${spaceLarge};
  margin-top: ${spaceSmall};
  width: fit-content;
  min-width: 100%;
  max-height: 60vh;
  overflow-y: auto;
  max-width: 50vw;

  > * {
    padding-left: initial;
    padding-right: initial;
  }

  padding-left: ${spaceLarge};
  padding-right: ${spaceLarge};
`;

const FilterPopupFrame = ({
  toggleIsOpen,
  buttonRef,
}: {
  buttonRef: React.RefObject<HTMLDivElement>;
  toggleIsOpen(): void;
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const frameRef = useRef<HTMLDivElement>(null);
  const portfolioItems = useRecoilValue(mappedPortfolioItemsWithFilteredAtom);
  const [filteredPortfolioParkItems, setFilteredPortfolioParkItems] =
    useRecoilState(filteredPortfolioParkItemsAtom);

  useClickOutside(
    frameRef,
    toggleIsOpen,
    (target) => target === wrapperRef?.current || target === buttonRef?.current,
  );

  const selectAllIsEnabled = useMemo(() => {
    return filteredPortfolioParkItems.length === 0;
  }, [filteredPortfolioParkItems]);

  return (
    <ParkFilterFrameWrapper>
      <ParkFilterFrame ref={frameRef}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            rowGap: spaceLarge,
            columnGap: spaceLarge,
          }}
        >
          <Checkbox
            labelStyle={{
              ...typography.regular,
              whiteSpace: "nowrap",
            }}
            label="Select all"
            labelPlacement="after"
            checked={selectAllIsEnabled}
            onChange={(event) => {
              const checked = event.target.checked;
              setFilteredPortfolioParkItems(() => {
                if (checked) {
                  return [];
                }

                return portfolioItems.map((item) => ({
                  parkId: item.park.id,
                  projectId: item.project.id,
                  branchId: item.branchId,
                }));
              });
            }}
          />
          <div />

          {portfolioItems.map((item) => (
            <React.Fragment
              key={`${item.project.id}-${item.branchId}-${item.park.id}`}
            >
              <Checkbox
                labelStyle={{
                  ...typography.regular,
                  whiteSpace: "nowrap",
                }}
                label={item.park.properties.name}
                labelPlacement="after"
                checked={
                  !filteredPortfolioParkItems.some(
                    (filteredItem) =>
                      filteredItem.parkId === item.park.id &&
                      filteredItem.projectId === item.project.id &&
                      filteredItem.branchId === item.branchId,
                  )
                }
                onChange={(event) => {
                  Mixpanel.track("Toggle portfolio park filtered", {
                    totalNrItems: portfolioItems.length,
                    isFiltered: event.target.checked,
                  });

                  const checked = event.target.checked;
                  setFilteredPortfolioParkItems((curr) => {
                    if (checked) {
                      return curr.filter(
                        (filteredItem) =>
                          filteredItem.parkId !== item.park.id ||
                          filteredItem.projectId !== item.project.id ||
                          filteredItem.branchId !== item.branchId,
                      );
                    }

                    return [
                      ...curr,
                      {
                        parkId: item.park.id,
                        projectId: item.project.id,
                        branchId: item.branchId,
                      },
                    ];
                  });
                }}
                style={{
                  flexShrink: 0,
                }}
              />
              <p
                style={{
                  ...typography.contentAndButtons,
                  color: colors.secondaryText,
                  whiteSpace: "nowrap",
                }}
              >
                {item.project.name}
              </p>
            </React.Fragment>
          ))}
        </div>
      </ParkFilterFrame>
    </ParkFilterFrameWrapper>
  );
};

const SelectParksFilterButton = () => {
  const [isOpen, toggleIsOpen, setIsOpen] = useBooleanState(false);
  const portfolioItems = useRecoilValue(mappedPortfolioItemsWithFilteredAtom);
  const filteredPortfolioParkItems = useRecoilValue(
    filteredPortfolioParkItemsAtom,
  );
  const buttonRef = useRef<HTMLDivElement>(null);

  const nrFilteredItemsThatUserHasAccessTo = useMemo(() => {
    return filteredPortfolioParkItems.filter((f) =>
      portfolioItems.some(
        (portfolioItem) =>
          portfolioItem.project.id === f.projectId &&
          portfolioItem.branchId === f.branchId &&
          portfolioItem.park.id === f.parkId,
      ),
    ).length;
  }, [filteredPortfolioParkItems, portfolioItems]);

  if (portfolioItems.length === 0 && isOpen) {
    setIsOpen(false);
  }

  return (
    <div style={{ position: "relative", overflow: "visible" }}>
      <div
        ref={buttonRef}
        style={{
          display: "flex",
          gap: spaceDecent,
          borderRadius: borderRadiusMedium,
          background: isOpen
            ? "rgb(233, 241, 255)"
            : "rgba(233, 241, 255, 0.50)",
          padding: `${spaceSmall} ${spaceDecent}`,
          alignItems: "center",
          cursor: "pointer",
          position: "relative",
        }}
        onClick={() => {
          toggleIsOpen();
          Mixpanel.track("Toggle portfolio filters open", {});
        }}
      >
        <EarthIconWrapper>
          <IconREMSize height={1.6} width={1.6}>
            <EarthIcon />
          </IconREMSize>
        </EarthIconWrapper>
        <p style={{ ...typography.regular, margin: 0 }}>Number of parks</p>
        <span
          style={{
            ...typography.regular,
            margin: 0,
            backgroundColor: colors.brand,
            color: colors.lightText,
            padding: `2px ${spaceTiny}`,
            borderRadius: borderRadiusSmall,
          }}
        >
          {Math.max(
            portfolioItems.length - nrFilteredItemsThatUserHasAccessTo,
            0,
          )}
        </span>
      </div>
      {isOpen && (
        <FilterPopupFrame toggleIsOpen={toggleIsOpen} buttonRef={buttonRef} />
      )}
    </div>
  );
};

export default SelectParksFilterButton;
