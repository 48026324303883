import { MAX_TOTAL_TURBINES } from "@constants/park";
import { MIN_CABLE_LENGTH_KM } from "state/cable";
import { TurbineFeature } from "types/feature";
import { MAX_WIND_ROSE_DISTANCE_KM } from "./constants";
import { exportCableErrorKilometer } from "@constants/production";

export type TurbineStat = {
  turbine: TurbineFeature;
  value: number;
};
export enum AnalysisStoppedTypes {
  NoTurbines = "no_turbines",
  TooManyTurbines = "too_many_turbines",
  TurbinesNotFound = "turbines_not_found",
  NeighbourTurbinesNotFound = "neighbour_turbines_not_found",
  TurbinesOverlap = "turbines_overlap",
  ExistingTurbinesOverlap = "existing_turbines_overlap",
  TurbinesOutsidePark = "turbines_outside_park",
  GWAnotAvailable = "invalid_mean_grid",
  MissingTimestamps = "missing_timestamps",
  WindRoseFarAway = "windrose_far_away",
  TooLargeProblem = "too_large_problem",
  InterArrayFailed = "inter_array_failed",
  ExportSystemFailed = "export_system_failed",
  TooShortCables = "too_short_cables",
  CableMissingType = "cable_missing_type",
  TurbineNotConnected = "turbine_not_connected",
  SubstationNotConnected = "substation_not_connected",
  SubstationHasNoTurbines = "substation_has_no_turbines",
  ExportCableNotConnected = "export_cable_not_connected",
  ExportCableMissingType = "export_cable_missing_type",
  ExportCableTooLongType = "export_cable_too_long_type",
  SubstationHasTooManyExportCables = "substation_has_too_many_export_cables",
  TurbinesOutsideSpeedGrid = "turbines_outside_speed_grid",
}

export const analysisStoppedText: Record<AnalysisStoppedTypes, string> = {
  [AnalysisStoppedTypes.NoTurbines]: "No turbines to analyze.",
  [AnalysisStoppedTypes.TooManyTurbines]: `Too many turbines (>${MAX_TOTAL_TURBINES}) to analyze.`,
  [AnalysisStoppedTypes.TooLargeProblem]:
    "The total analysis problem size exceeds our capacity per analysis. Try reducing the number of turbines and/or the analysis precision",
  [AnalysisStoppedTypes.TurbinesNotFound]:
    "Unknown turbines are detected in the park/zone.",
  [AnalysisStoppedTypes.NeighbourTurbinesNotFound]:
    "Unknown turbines are detected in neighbouring park/zone.",
  [AnalysisStoppedTypes.TurbinesOverlap]: "Turbines are overlapping.",
  [AnalysisStoppedTypes.ExistingTurbinesOverlap]:
    "Some existing turbines are overlapping.",
  [AnalysisStoppedTypes.TurbinesOutsidePark]:
    "Turbines are outside the park boundary.",
  [AnalysisStoppedTypes.GWAnotAvailable]:
    "The GWA speed correction is not available at the park location.",
  [AnalysisStoppedTypes.WindRoseFarAway]: `The selected wind dataset is more than ${MAX_WIND_ROSE_DISTANCE_KM}km from the park center.`,
  [AnalysisStoppedTypes.InterArrayFailed]: `The inter array loss analysis has failed, please check your park cable types.`,
  [AnalysisStoppedTypes.ExportSystemFailed]: `The export system loss analysis has failed, please check your park export cable types and substation settings.`,
  [AnalysisStoppedTypes.TooShortCables]: `Some cables are too short ( < ${
    1000 * MIN_CABLE_LENGTH_KM
  } m) for the inter array loss analysis.`,
  [AnalysisStoppedTypes.CableMissingType]: `Inter array losses are activated, but some cables do not have a valid cable type.`,
  [AnalysisStoppedTypes.TurbineNotConnected]: `Inter array losses are activated, but some turbines are not connected to a substation.`,
  [AnalysisStoppedTypes.SubstationNotConnected]: `Export system losses are activated, but some substations are not connected to an export cable.`,
  [AnalysisStoppedTypes.ExportCableNotConnected]: `Export system losses are activated, but some export cables are not connected to both an offshore and an onshore substation.`,
  [AnalysisStoppedTypes.ExportCableMissingType]: `Export system losses are activated, but some export cables do not have both an offshore and an onshore cable type defined.`,
  [AnalysisStoppedTypes.ExportCableTooLongType]: `Export system losses are activated, but some export cables are > ${exportCableErrorKilometer}km which is too long to calculate losses for.`,
  [AnalysisStoppedTypes.SubstationHasTooManyExportCables]: `Export system losses are activated, but some offshore substations have more export cables than inter-array cable chains connected to it.`,
  [AnalysisStoppedTypes.TurbinesOutsideSpeedGrid]:
    "Turbines are outside the spatial speed grid used in the wind configuration.",
  [AnalysisStoppedTypes.MissingTimestamps]:
    "Missing timestamps in the uploaded wind data.",
  [AnalysisStoppedTypes.SubstationHasNoTurbines]:
    "A substation is not connected to any turbines.",
};
