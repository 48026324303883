import { selectorFamily } from "recoil";
import {
  CostType,
  isFeatureCost,
  isOverrideCost,
} from "../../../../services/costService";
import { libraryAndProjectCostConfigurationsSelectorFamily } from "../../../../state/costConfigurations";
import {
  unitToAmountUnit,
  _CostUnit,
  CostUnit,
} from "../../../../types/financial";
import { Cost, costId } from "../capexNumbers";
import { amountId } from "../generalAmounts";
import { allSubstationTypesInParkSelectorFamily } from "./substationAmounts";
import { getSubstationsInBranchSelectorFamily } from "state/cable";

export const substationCostsSelectorFamily = selectorFamily<
  Cost[],
  {
    projectId: string;
    branchId: string;
    parkId: string;
    configurationId: string;
  }
>({
  key: "substationCostsSelectorFamily",
  get:
    ({ projectId, branchId, parkId, configurationId }) =>
    ({ get }) => {
      const configurations = get(
        libraryAndProjectCostConfigurationsSelectorFamily({
          nodeId: projectId,
        }),
      );
      const allSubstationTypes = get(
        allSubstationTypesInParkSelectorFamily({ branchId, parkId }),
      );
      const substations = get(
        getSubstationsInBranchSelectorFamily({ parkId, branchId }),
      );

      const configuration = configurations.find(
        (c) => c.id === configurationId,
      );

      if (!configuration) return [];

      const {
        capex: { custom, fixed },
      } = configuration;

      const flatCosts: Cost[] = custom
        .filter((c) => c.category === CostType.Substation)
        .filter((c) => c.unit === CostUnit.millionEuro)
        .map((c) => ({
          ...c,
          id: costId({
            category: CostType.Substation,
            costId: c.id,
          }),
          amountId: amountId({
            unit: unitToAmountUnit[c.unit],
            category: c.category,
          }),
        }));

      const customCosts: Cost[] =
        custom
          .filter((c) => c.category === CostType.Substation)
          .filter((c) => c.unit !== CostUnit.millionEuro)
          .flatMap((custom) => ({
            ...custom,
            name: `${custom.name}`,
            id: costId({
              category: CostType.Substation,
              costId: custom.id,
              featureTypeId: CostType.Substation,
            }),
            amountId: amountId({
              unit: unitToAmountUnit[custom.unit],
              category: custom.category,
            }),
          })) ?? [];

      let fixedCosts: Cost[] = [];

      if (isFeatureCost(fixed.substation)) {
        const typeById = Object.fromEntries(
          allSubstationTypes.map((s) => [s.id, s]),
        );

        allSubstationTypes
          .filter((s) => s.costUnit === CostUnit.millionEuroPerUnit)
          .forEach((type) => {
            const { costUnit, cost, name: typeName } = type;
            const unit = _CostUnit.parse(costUnit);

            fixedCosts.push({
              id: costId({
                category: CostType.Substation,
                costId: type.id,
                featureTypeId: type.id,
              }),
              amountId: amountId({
                unit: unitToAmountUnit[costUnit],
                category: CostType.Substation,
                featureTypeId: type.id,
              }),
              category: CostType.Substation,
              name: `${typeName}`,
              cost,
              unit,
              confidenceLevel: undefined,
            });
          });

        substations
          .filter(
            (s) =>
              s.properties.substationTypeId &&
              typeById[s.properties.substationTypeId].costUnit ===
                CostUnit.thousandEuroPerMW,
          )
          .forEach((sub) => {
            const subStationType = allSubstationTypes.find(
              ({ id }) => id === sub.properties.substationTypeId,
            );

            if (!subStationType) throw new Error("Substation type not found");
            const { name } = sub.properties;
            const { costUnit, cost, name: typeName } = subStationType;

            const unit = _CostUnit.parse(costUnit);

            fixedCosts.push({
              id: costId({
                category: CostType.Substation,
                costId: sub.id,
                featureTypeId: sub.id,
              }),
              amountId: amountId({
                unit: unitToAmountUnit[costUnit],
                category: CostType.Substation,
                featureTypeId: sub.id,
              }),
              category: CostType.Substation,
              name: `${typeName} (${name})`,
              cost,
              unit,
              confidenceLevel: undefined,
            });
          });
      } else if (isOverrideCost(fixed.substation)) {
        const { cost, unit, confidenceLevel } = fixed.substation;

        fixedCosts.push({
          id: "substation",
          amountId: amountId({
            unit: unitToAmountUnit[unit],
            category: CostType.Substation,
          }),
          category: CostType.Substation,
          name: "Substations",
          cost: cost,
          unit: unit,
          confidenceLevel: confidenceLevel,
        });
      }

      return [...fixedCosts, ...customCosts, ...flatCosts];
    },
});
