/// <reference types="vite-plugin-svgr/client" />
import { formatGWhConditional } from "../../ProductionV2/format";
import { CenterContainer, SafeCard } from "./Base";
import { spaceMedium, spaceTiny } from "../../../styles/space";
import { colors } from "../../../styles/colors";
import { CostUnit, unitToScalingMap } from "../../../types/financial";
import { useDashboardContext } from "../Dashboard";

import { getInvalidTypesInParkAndBranch } from "state/layout";
import { InvalidTypesDisplayText } from "types/invalidTypes";
import { useRecoilValue } from "recoil";
import { Stats, StatHeader, Stat2 } from "./Base.style";
import { currentParkTriggerId } from "components/Finance/Triggers";
import { useLcoe } from "components/Finance/lcoe";
import { DownloadLcoeDataButton } from "components/FinancialAnalysis/Lcoe/DownloadLcoeData";
import { getStoppedReason } from "components/ProductionV2/state";
import { ValidationAlert } from "components/ValidationWarnings/ValidationAlert";
import { analysisStoppedText } from "components/ProductionV2/types";

export const LCoEWidget = () => {
  const { park, branch } = useDashboardContext();

  const invalidTypes = useRecoilValue(
    getInvalidTypesInParkAndBranch({ parkId: park.id, branchId: branch.id }),
  );

  if (invalidTypes.length > 0)
    return (
      <CenterContainer style={{ margin: "3rem" }}>
        <InvalidTypesDisplayText invalidTypes={invalidTypes} />
      </CenterContainer>
    );

  return (
    <SafeCard
      title="LCoE"
      id="Lcoe table"
      menuItems={
        <DownloadLcoeDataButton id={currentParkTriggerId} buttonType="menu" />
      }
    >
      <LCOETableInnerV2 />
    </SafeCard>
  );
};

const LCOETableInnerV2 = () => {
  const {
    inputs,
    results: { devex, capex, opex, decom, lcoe },
  } = useLcoe(currentParkTriggerId);

  const analysisStoppedReason = useRecoilValue(
    getStoppedReason(currentParkTriggerId),
  );
  if (analysisStoppedReason) {
    return (
      <ValidationAlert
        type={"error"}
        title="Analysis stopped"
        description={analysisStoppedText[analysisStoppedReason]}
      />
    );
  }

  return (
    <Stats>
      <StatHeader title={"Expenditures (NPV)"} value=""></StatHeader>
      <Stat2
        title={"DEVEX"}
        value={devex.npv}
        unit={CostUnit.millionEuro}
      ></Stat2>
      <Stat2
        title={"CAPEX"}
        value={capex.npv}
        unit={CostUnit.millionEuro}
      ></Stat2>
      <Stat2
        title={"OPEX"}
        value={opex.npv}
        unit={CostUnit.millionEuro}
      ></Stat2>
      <Stat2
        title={"DECOM"}
        value={decom.npv}
        unit={CostUnit.millionEuro}
      ></Stat2>
      <StatHeader title={"Other parameters"} value=""></StatHeader>
      <Stat2
        title={"AEP"}
        value={inputs.netAEP}
        format={formatGWhConditional}
      ></Stat2>
      <Stat2
        title={"Discount rate"}
        value={inputs.discountRate}
        format={(v: number) =>
          `${(v / unitToScalingMap[CostUnit.percent]).toFixed(1)} ${CostUnit.percent}`
        }
      ></Stat2>
      <Stat2
        title={"Inflation rate"}
        value={inputs.inflationRate}
        format={(v: number) =>
          `${(v / unitToScalingMap[CostUnit.percent]).toFixed(1)} ${CostUnit.percent}`
        }
      ></Stat2>
      <Stat2
        title={"CAPEX contingency"}
        value={inputs.capexContingency}
        format={(v: number) =>
          `${(v / unitToScalingMap[CostUnit.percent]).toFixed(1)} ${CostUnit.percent}`
        }
      ></Stat2>
      <Stat2 title={"Life time"} value={inputs.lifeTime} unit="years"></Stat2>
      <div
        style={{
          borderTop: `1px solid ${colors.inputOutline}`,
          margin: `${spaceTiny} ${spaceMedium}`,
        }}
      />
      <Stat2
        bold={true}
        title={"LCoE"}
        value={lcoe}
        format={(v: number) => `${v.toFixed(1)} ${CostUnit.euroPerMWh}`}
      ></Stat2>
    </Stats>
  );
};
