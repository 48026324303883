import React, { useCallback, useMemo, useRef, useState } from "react";
import { useRecoilCallback, useRecoilValue } from "recoil";
import styled from "styled-components";
import AddIcon from "@icons/24/Add.svg";
import CheckCircleIcon from "@icons/14/CheckCircle.svg";
import ChevronDownIcon from "@icons/14/ChevronDown.svg";
import DatabaseIcon from "@icons/24/Database.svg";
import SortDescendingIcon from "@icons/SortDescending.svg";
import SortAscendingIcon from "@icons/SortAscending.svg";
import SortNeutralIcon from "@icons/SortNeutral.svg";
import { StandardBox } from "styles/boxes/Boxes";
import { SkeletonBlock, SkeletonText } from "../../../Loading/Skeleton";
import {
  spacing1,
  spacing2,
  spacing3,
  spacing4,
  spacing6,
  spacing8,
} from "styles/space";
import { IconREMSize, typography } from "styles/typography";
import { IconBtn } from "components/General/Icons";
import Button from "../../../General/Button";
import Checkbox from "../../../General/Checkbox";
import { layerVisibleAtomFamily } from "../../../LayerList/LayerSettings/state";
import { colors } from "styles/colors";
import {
  dataLayerAccessOnNodeState,
  dataPackageResourceWithAccessOnNodeState,
} from "components/Organisation/Library/state";
import { projectIdSelector } from "state/pathParams";
import {
  Column as ReactTableColumn,
  useExpanded,
  usePagination,
  useSortBy,
  useTable,
  useGlobalFilter,
  UseGlobalFiltersOptions,
} from "react-table";
import { DataLibraryPackage } from "components/Organisation/Library/dataLibrary/types";
import { Frame, Row } from "components/General/Layout";
import { dateToYearDateTime } from "utils/utils";
import UserInfo from "components/Comments/MapModal/UserInfo";
import { ScrollBody } from "hooks/useShowScrollShadow";
import { useLayerSettingsCrud } from "components/LayerList/LayerSettings/useLayerSettingsCrud";
import { defaultLayerSettings } from "components/LayerList/LayerSettings/const";
import { libraryLayersAddedToProject } from "components/Organisation/Library/dataLibrary/state";
import { DataLibraryNewEntry } from "components/Organisation/Library/dataLibrary/DataLibraryTab.style";
import { VerticalDivider } from "components/General/VerticalDivider";
import ChatIcon from "@icons/24/Chat.svg";
import { Anchor } from "components/General/Anchor";
import useBooleanState from "hooks/useBooleanState";
import { useClickOutside } from "hooks/useClickOutside";
import { SearchInput } from "components/General/Input";
import { isDefined } from "utils/predicates";
import { Mixpanel } from "mixpanel";

export const Modal = styled(StandardBox)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  //padding: 1rem 1rem 0;
  width: 80vw;
  max-width: 140rem;
  height: 80vh;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  overflow: visible;
`;

const HeaderColumn = styled.p`
  ${typography.sub2};
`;

const HiddenIfNotChecked = styled.div`
  display: flex;
  align-items: center;
`;

const Table = styled.table`
  ${HiddenIfNotChecked}:not([data-checked=true]) {
    visibility: hidden;
  }

  tbody {
    tr:nth-child(odd) {
      background-color: ${colors.grey50};
    }
    tr:nth-child(even) {
      background-color: ${colors.white};
    }
    tr:hover {
      ${HiddenIfNotChecked} {
        visibility: visible;
      }
    }
  }
`;

const StickyThead = styled.thead`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
`;

const ExpandArrowWrapper = styled.div<{ open: boolean }>`
  margin-right: 1rem;
  cursor: pointer;
  margin-left: ${spacing1};
  transform: rotate(${({ open }) => (!open ? "-90deg" : "0deg")});
  transition: 0.1s;

  ${({ open }) =>
    !open &&
    `
    svg {
      path {
        fill: ${colors.grey500};
      }
    }`};
`;

const DescriptionIconButton = ({
  description,
}: {
  description?: string | null;
}) => {
  const [isOpen, toggleIsOpen, setIsOpen] = useBooleanState(false);
  const editDescriptionBtnRef = useRef<HTMLButtonElement>(null);
  const frameRef = useRef<HTMLDivElement>(null);
  useClickOutside(
    frameRef,
    () => setIsOpen(false),
    (target) => target === editDescriptionBtnRef.current,
  );

  if (!description) {
    return null;
  }

  return (
    <>
      <IconBtn
        ref={editDescriptionBtnRef}
        size="1.4rem"
        onClick={(e) => {
          e.stopPropagation();
          toggleIsOpen();
        }}
        stroke={colors.iconInfo}
        $fill={colors.iconInfo}
        backgroundColor={"transparent"}
      >
        <ChatIcon />
      </IconBtn>
      {isOpen && (
        <Anchor baseRef={editDescriptionBtnRef} floatPlace={"topRight"}>
          <Frame ref={frameRef} onClick={(e) => e.stopPropagation()}>
            <p
              style={{
                whiteSpace: "pre-wrap",
              }}
            >
              {description}
            </p>
          </Frame>
        </Anchor>
      )}
    </>
  );
};

const columns: Array<ReactTableColumn<SearchableDataLibraryPackage>> = [
  {
    id: "name",
    width: "40%",
    sortType: (a, b) => a.original.name.localeCompare(b.original.name),
    // @ts-ignore
    canSort: true,
    Header: ({ allIsSelected, someIsSelected, toggleAllSelected }) => {
      return (
        <Row
          style={{
            paddingLeft: spacing8,
            position: "relative",
          }}
        >
          <Checkbox
            checked={
              allIsSelected ? true : someIsSelected ? "indeterminate" : false
            }
            onChange={toggleAllSelected}
          />
          <HeaderColumn>Name</HeaderColumn>
        </Row>
      );
    },
    Cell: ({ row, getPackageSelectionStatus, onTogglePackageSelected }) => {
      const isSelected = getPackageSelectionStatus(row.original.id);
      return (
        <Row alignCenter style={{ display: "flex", paddingLeft: spacing8 }}>
          <HiddenIfNotChecked data-checked={Boolean(isSelected)}>
            <Checkbox
              checked={isSelected}
              onChange={() => {
                onTogglePackageSelected(row.original.id);
              }}
            />
          </HiddenIfNotChecked>
          <ExpandArrowWrapper open={row.isExpanded}>
            <ChevronDownIcon />
          </ExpandArrowWrapper>
          <DatabaseIcon />
          <p>{row.original.name}</p>
        </Row>
      );
    },
  },
  {
    id: "created_at",
    // @ts-ignore
    canSort: true,
    disableFilters: true,
    sortType: (a, b) => {
      return a.original.created_at - b.original.created_at;
    },
    Header: <HeaderColumn style={typography.sub2}>Last updated</HeaderColumn>,
    Cell: ({ row }) => (
      <p>{dateToYearDateTime(new Date(row.original.created_at))}</p>
    ),
    width: 100,
  },
  {
    id: "author",
    Header: <HeaderColumn>Created by</HeaderColumn>,
    Cell: ({ row }) => {
      return (
        <Row alignCenter>
          <UserInfo userId={row.original.author} />
        </Row>
      );
    },
  },
  {
    id: "description",
    // @ts-ignore
    disableFilters: false,
    Header: <HeaderColumn>Description</HeaderColumn>,
    Cell: ({ row }) => {
      return (
        <Row alignCenter>
          <DescriptionIconButton description={row.original.description} />
        </Row>
      );
    },
  },
  {
    id: "actions",
    Header: <HeaderColumn>Actions</HeaderColumn>,
    width: 100,
    Cell: ({ row, onAddLibraryPackageToProject, getAllIsAdded }) => {
      const allIsAdded = getAllIsAdded(row.original.layerIds);

      return (
        <Row
          alignCenter
          style={{
            gap: spacing6,
          }}
        >
          {row.original.layerIds.length > 0 && allIsAdded ? (
            <>
              <IconREMSize height={1.4} width={1.4}>
                <CheckCircleIcon />
              </IconREMSize>
              <p>Added to map</p>
            </>
          ) : (
            <Button
              text={"Add to map"}
              disabled={row.original.layerIds.length === 0}
              buttonType="secondary"
              size="small"
              icon={<AddIcon />}
              onClick={(e) => {
                e.stopPropagation();
                onAddLibraryPackageToProject(row.original.id);
              }}
            />
          )}
        </Row>
      );
    },
  },
];

const SelectedPackagesWrapper = styled.div<{ visible: boolean }>`
  position: absolute;
  left: ${spacing8};
  top: 1rem;
  z-index: 10;
  background-color: ${colors.white};
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: ${spacing4} ${spacing6};
  gap: ${spacing4};
  width: fit-content;
  margin-left: ${spacing2};
  margin-bottom: ${spacing1};
`;

type SearchableDataLibraryPackage = DataLibraryPackage & {
  layerNames: string[];
};

const LibraryDataLayersModalInner = () => {
  const projectId = useRecoilValue(projectIdSelector);
  const dataPackagesAvailableRaw = useRecoilValue(
    dataPackageResourceWithAccessOnNodeState({ nodeId: projectId }),
  );
  const dataLayersAvailable = useRecoilValue(
    dataLayerAccessOnNodeState({ nodeId: projectId }),
  );
  const dataPackagesAvailable = useMemo<SearchableDataLibraryPackage[]>(
    () =>
      dataPackagesAvailableRaw
        .map((p) => p.config)
        .map((f) => ({
          ...f,
          layerNames: f.layerIds
            .map((layerId) => dataLayersAvailable.find((d) => d.id === layerId))
            .filter(isDefined)
            .map((layer) => layer.name),
        })),
    [dataLayersAvailable, dataPackagesAvailableRaw],
  );

  const { put: putLayerSettings } = useLayerSettingsCrud();
  const addedDataLayers = useRecoilValue(
    libraryLayersAddedToProject({ projectId }),
  );
  const [selectedLayers, setSelectedLayers] = useState<
    Array<{
      packageId: string;
      layerId: string;
    }>
  >([]);

  const setLayerVisible = useRecoilCallback(
    ({ set }) =>
      (id: string, visible: boolean) => {
        set(
          layerVisibleAtomFamily({ projectId: projectId!, layerId: id }),
          visible,
        );
      },
    [projectId],
  );

  const addLibraryLayersToProject = useCallback(
    async (layerIds: string[]) => {
      await putLayerSettings(layerIds.map(defaultLayerSettings));
    },
    [putLayerSettings],
  );

  const onAddLibraryLayerToProject = useCallback(
    async (layerId: string) => {
      await addLibraryLayersToProject([layerId]);
      setLayerVisible(layerId, true);
    },
    [addLibraryLayersToProject, setLayerVisible],
  );

  const onAddLibraryPackageToProject = useCallback(
    (packageId: string) => {
      const pkg = dataPackagesAvailable.find((p) => p.id === packageId);
      if (pkg) {
        return addLibraryLayersToProject(pkg.layerIds);
      }
    },
    [addLibraryLayersToProject, dataPackagesAvailable],
  );

  const getAllIsAdded = useCallback(
    (layerIds: string[]) => {
      return layerIds
        .filter((r) => dataLayersAvailable.find((dl) => dl.id === r))
        .every((layerId) => addedDataLayers.some((l) => l.id === layerId));
    },
    [addedDataLayers, dataLayersAvailable],
  );

  const allIsSelected = selectedLayers.length >= dataLayersAvailable.length;
  const someIsSelected = selectedLayers.length > 0;

  const toggleAllSelected = useCallback(() => {
    setSelectedLayers(() => {
      if (allIsSelected) {
        return [];
      }

      return dataPackagesAvailable.flatMap((pkg) =>
        pkg.layerIds.map((layerId) => ({
          packageId: pkg.id,
          layerId,
        })),
      );
    });
  }, [allIsSelected, dataPackagesAvailable]);

  const getPackageSelectionStatus = useCallback(
    (packageId: string) => {
      const pkg = dataPackagesAvailable.find((p) => p.id === packageId);
      if (!pkg || pkg.layerIds.length === 0) {
        return false;
      }

      const allIsSelected = pkg.layerIds.every((layerId) =>
        selectedLayers.find(
          (row) => row.layerId === layerId && row.packageId === packageId,
        ),
      );
      const someIsSelected = pkg.layerIds.some((layerId) =>
        selectedLayers.find(
          (row) => row.layerId === layerId && row.packageId === packageId,
        ),
      );

      return allIsSelected ? true : someIsSelected ? "indeterminate" : false;
    },
    [dataPackagesAvailable, selectedLayers],
  );

  const onAddSelectedLibraryPackagesToMap = useCallback(async () => {
    await addLibraryLayersToProject(selectedLayers.map((row) => row.layerId));

    setSelectedLayers([]);
  }, [addLibraryLayersToProject, selectedLayers]);

  const getLayerIsAddedToProject = useCallback(
    (layerId: string) => addedDataLayers.some((l) => l.id === layerId),
    [addedDataLayers],
  );

  const onTogglePackageSelected = useCallback(
    (packageId: string) => {
      const pkg = dataPackagesAvailable.find((p) => p.id === packageId);
      if (!pkg) {
        return;
      }

      const layerIds = pkg.layerIds;
      setSelectedLayers((curr) => {
        if (
          layerIds.every((layerId) =>
            curr.find(
              (row) => row.layerId === layerId && row.packageId === packageId,
            ),
          )
        ) {
          return curr.filter((p) => p.packageId !== packageId);
        } else {
          return [
            ...curr,
            ...layerIds.map((layerId) => ({
              packageId,
              layerId,
            })),
          ];
        }
      });
    },
    [dataPackagesAvailable],
  );

  const onToggleLayerSelected = useCallback(
    (packageId: string, layerId: string) => {
      setSelectedLayers((curr) => {
        if (
          curr.find(
            (row) => row.layerId === layerId && row.packageId === packageId,
          )
        ) {
          return curr.filter(
            (p) => p.packageId !== packageId || p.layerId !== layerId,
          );
        } else {
          return [...curr, { packageId, layerId }];
        }
      });
    },
    [setSelectedLayers],
  );

  const globalFilterFunc = useMemo<
    UseGlobalFiltersOptions<SearchableDataLibraryPackage>["globalFilter"]
  >(
    () => (rows, _indexes, searchValue) => {
      return rows.filter((row) => {
        return (
          row.original.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          row.original.layerNames.some((name) =>
            name.toLowerCase().includes(searchValue.toLowerCase()),
          )
        );
      });
    },
    [],
  );

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    page,
    prepareRow,
    setGlobalFilter,
    state,
  } = useTable<SearchableDataLibraryPackage>(
    {
      columns,
      globalFilter: globalFilterFunc,
      data: dataPackagesAvailable,
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetSortBy: false,
      initialState: {
        pageSize: 1000,
        pageIndex: 0,
        sortBy: [{ id: "created_at", desc: true }],
      },
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
  );

  const globalFilterValue = (
    "globalFilter" in state ? state.globalFilter : ""
  ) as string;

  return (
    <ScrollBody
      style={{
        gap: 0,
      }}
    >
      <SelectedPackagesWrapper
        visible={selectedLayers.length > 0}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <p style={{ ...typography.regular, color: colors.blue500 }}>
          {selectedLayers.length} selected
        </p>
        <VerticalDivider />
        <Button
          text="Add to map"
          buttonType="primary"
          size="small"
          icon={<AddIcon />}
          onClick={onAddSelectedLibraryPackagesToMap}
        />
      </SelectedPackagesWrapper>
      {dataPackagesAvailable.length === 0 ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <DataLibraryNewEntry
              icon={<DatabaseIcon />}
              text="Your project doesn't have any Library packages"
              title="No library packages"
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            padding: `0 ${spacing8}`,
          }}
        >
          <SearchInput
            wrapperDivStyle={{
              marginBottom: "2rem",
              width: "30rem",
            }}
            style={{
              width: "100%",
            }}
            value={globalFilterValue ?? ""}
            placeholder="Search for layer"
            onChange={(e) => {
              setGlobalFilter(e.target.value);
            }}
            onClear={() => setGlobalFilter("")}
          />
          <Table
            {...getTableProps({
              style: {
                width: "100%",
                borderSpacing: 0,
              },
            })}
          >
            <StickyThead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(
                        column.getSortByToggleProps({
                          onClick: (e) => {
                            if (
                              !column.canSort ||
                              e.nativeEvent
                                .composedPath()
                                .find(
                                  (e) =>
                                    e instanceof HTMLElement &&
                                    e.dataset.type === "checkbox",
                                )
                            ) {
                              return;
                            }

                            Mixpanel.track(
                              "Sort library layers in Layers modal",
                              {
                                id: column.id,
                                desc: !column.isSortedDesc,
                              },
                            );
                            column.toggleSortBy(!column.isSortedDesc);
                          },
                          style: {
                            textAlign: "unset",
                            width: column.width,
                            padding: "0.5rem 0",
                            borderBottom: `1px solid ${colors.blue400}`,
                          },
                        }),
                      )}
                    >
                      <Row alignCenter>
                        {column.render("Header", {
                          allIsSelected,
                          toggleAllSelected,
                          someIsSelected,
                          getLayerIsAddedToProject,
                        })}
                        {column.canSort && (
                          <span style={{ marginLeft: "0.3rem" }}>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <SortDescendingIcon />
                              ) : (
                                <SortAscendingIcon />
                              )
                            ) : (
                              <SortNeutralIcon />
                            )}
                          </span>
                        )}
                      </Row>
                    </th>
                  ))}
                </tr>
              ))}
            </StickyThead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                const selectionStatus = getPackageSelectionStatus(
                  row.original.id,
                );

                return (
                  <React.Fragment key={row.original.id}>
                    <tr
                      {...row.getRowProps({
                        style: {
                          cursor: "pointer",
                          borderSpacing: 0,
                          backgroundColor:
                            selectionStatus === true
                              ? colors.blue100
                              : undefined,
                        },
                      })}
                      onClick={() => row.toggleRowExpanded()}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps({
                              style: {
                                wordWrap: "break-word",
                                whiteSpace: "pre-wrap",
                                padding: `${spacing3} 0`,
                                verticalAlign: "top",
                              },
                            })}
                          >
                            <React.Suspense fallback={<SkeletonText />}>
                              {cell.render("Cell", {
                                onAddLibraryPackageToProject,
                                getAllIsAdded,
                                getPackageSelectionStatus,
                                onTogglePackageSelected,
                              })}
                            </React.Suspense>
                          </td>
                        );
                      })}
                    </tr>
                    {row.isExpanded &&
                      row.original.layerIds.map((layerId) => {
                        const layer = dataLayersAvailable.find(
                          (l) => l.id === layerId,
                        );
                        const layerAddedToProject =
                          getLayerIsAddedToProject(layerId);
                        if (!layer) {
                          return null;
                        }

                        if (
                          globalFilterValue &&
                          !layer.name
                            .toLowerCase()
                            .includes(globalFilterValue.toLowerCase())
                        ) {
                          return null;
                        }

                        const isSelected = selectedLayers.some(
                          (selected) =>
                            selected.layerId === layerId &&
                            selected.packageId === row.original.id,
                        );

                        return (
                          <tr
                            key={layerId}
                            role="row"
                            onClick={() => {
                              onToggleLayerSelected(row.original.id, layerId);
                            }}
                            style={{
                              cursor: "pointer",
                              backgroundColor: isSelected
                                ? colors.blue200
                                : undefined,
                            }}
                          >
                            <td
                              style={{
                                wordWrap: "break-word",
                                whiteSpace: "pre-wrap",
                                padding: `${spacing3} 0 ${spacing3} 4rem`,
                              }}
                            >
                              <Row alignCenter>
                                <HiddenIfNotChecked data-checked={isSelected}>
                                  <Checkbox
                                    checked={isSelected}
                                    onChange={() => {
                                      onToggleLayerSelected(
                                        row.original.id,
                                        layerId,
                                      );
                                    }}
                                  />
                                </HiddenIfNotChecked>
                                <p>{layer.name}</p>
                              </Row>
                            </td>
                            <td
                              style={{
                                padding: `${spacing3} 0`,
                              }}
                            >
                              <p>
                                {dateToYearDateTime(new Date(layer.created_at))}
                              </p>
                            </td>
                            <td
                              style={{
                                padding: `${spacing3} 0`,
                              }}
                            >
                              <Row alignCenter>
                                <UserInfo userId={layer.author} />
                              </Row>
                            </td>
                            <td
                              style={{
                                padding: `${spacing3} 0`,
                              }}
                            >
                              <DescriptionIconButton
                                description={layer.description}
                              />
                            </td>
                            <td
                              style={{
                                padding: `${spacing3} 0`,
                              }}
                            >
                              {layerAddedToProject ? (
                                <Row alignCenter>
                                  <IconREMSize height={1.4} width={1.4}>
                                    <CheckCircleIcon />
                                  </IconREMSize>
                                  <p>Added to map</p>
                                </Row>
                              ) : (
                                <Button
                                  text="Add to map"
                                  buttonType="secondary"
                                  size="small"
                                  icon={<AddIcon />}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    return onAddLibraryLayerToProject(layerId);
                                  }}
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
    </ScrollBody>
  );
};

const LibraryDataLayersModal = () => {
  return (
    <React.Suspense
      fallback={
        <>
          <SkeletonBlock style={{ height: "5rem" }} />
        </>
      }
    >
      <LibraryDataLayersModalInner />
    </React.Suspense>
  );
};

export default LibraryDataLayersModal;
