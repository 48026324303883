/// <reference types="vite-plugin-svgr/client" />
import { useMemo } from "react";
import ShareModeSettings from "../../../ConfigurationModal/ShareModeSettings";
import Hyperlink from "@icons/24/Hyperlink.svg?react";

export default function usePublicModeConfiguration() {
  const configItems = useMemo(() => {
    return [
      {
        id: "public_mode",
        label: "Public mode",
        icon: <Hyperlink />,
        content: (
          <div style={{ height: "100%", position: "relative" }}>
            <ShareModeSettings />
          </div>
        ),
      },
    ];
  }, []);

  const publicModeConfiguration = useMemo(() => {
    return {
      title: "Share settings",
      submenus: [
        {
          items: configItems,
        },
      ],
    };
  }, [configItems]);

  return publicModeConfiguration;
}
