import { fetchSchemaWithToken, fetchWithToken } from "../../services/utils";
import { Dashboard, DashboardSerialize, _DashboardSerialize } from "./types";
import { With, omitFields } from "../../utils/utils";
import { deserializeDashboard } from "./utils";
import { z } from "zod";
import debounce from "debounce-promise";

export const fetchListDashboards = async (
  nodeId: string,
): Promise<Dashboard[]> => {
  const ds = await fetchSchemaWithToken(
    z.object({ dashboards: _DashboardSerialize.array() }),
    `/api/dashboard/node/${nodeId}/`,
    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  const dss = ds.dashboards.map((ds) => deserializeDashboard(ds));
  dss.sort((a, b) => a.id.localeCompare(b.id));
  return dss;
};

export const fetchCreateDashboard = async (
  nodeId: string,
  db: Omit<DashboardSerialize, "author" | "id">,
): Promise<Dashboard> => {
  const ds = await fetchSchemaWithToken(
    _DashboardSerialize,
    `/api/dashboard/node/${nodeId}/`,
    {
      method: "post",
      body: JSON.stringify(db),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  return deserializeDashboard(ds);
};

export const fetchUpdateDashboard = async (
  nodeId: string,
  update: With<DashboardSerialize, "id">,
): Promise<Dashboard> => {
  const ds = await fetchSchemaWithToken(
    _DashboardSerialize,
    `/api/dashboard/node/${nodeId}/${update.id}`,
    {
      method: "put",
      body: JSON.stringify(omitFields(update, "id")),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  return deserializeDashboard(ds);
};

export const fetchUpdateDashboardDebounce = debounce(
  fetchUpdateDashboard,
  1_000,
);

export const fetchDeleteDashboard = async (
  nodeId: string,
  dashboardId: string,
): Promise<Response> => {
  return fetchWithToken(`/api/dashboard/node/${nodeId}/${dashboardId}`, {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
