import { CirclePaint, Expression } from "mapbox-gl";
import { colors } from "../../styles/colors";
import { radiusAtZoom10, radiusAtZoom5 } from "./utils";
import { typePropertyName } from "../../constants/canvas";

export const defaultPointCircleRadius: Expression = [
  "interpolate",
  ["exponential", 2],
  ["zoom"],
  5,
  ["case", ["has", typePropertyName], radiusAtZoom5, 5],
  10,
  ["case", ["has", typePropertyName], radiusAtZoom10, 5],
  15,
  ["case", ["has", typePropertyName], 7, 5],
];

export const smallMapCircleRadius: Expression = [
  "interpolate",
  ["exponential", 2],
  ["zoom"],
  5,
  2,
  10,
  4,
];

export const smallMapCirclePaint: CirclePaint = {
  "circle-radius": smallMapCircleRadius,
  "circle-color": [
    "case",
    ["boolean", ["feature-state", "overlap"], false],
    colors.redAlert,
    colors.primaryText,
  ],
  "circle-opacity": [
    "case",
    [
      "boolean",
      ["feature-state", "hover"],
      ["feature-state", "selected"],
      false,
    ],
    1.0,
    0.8,
  ],
  "circle-stroke-color": [
    "case",
    ["!=", ["feature-state", "borderColor"], null],
    ["feature-state", "borderColor"],
    "#fff",
  ],
  "circle-stroke-width": [
    "case",
    ["!=", ["feature-state", "borderColor"], null],
    2.0,
    ["boolean", ["feature-state", "hover"], false],
    ["case", ["boolean", ["feature-state", "selected"], false], 2.0, 1.0],
    ["boolean", ["feature-state", "selected"], false],
    2.0,
    0.0,
  ],
};
