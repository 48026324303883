import {
  allCableTypesSelector,
  currentExportCableTypesSelector,
} from "components/Cabling/Generate/state";
import { projectFeaturesSelector } from "components/ProjectElements/state";
import { useRecoilValue } from "recoil";
import { allFoundationTypesSelector } from "state/foundations";
import { currentMooringLineTypesState } from "state/mooringLineType";
import { currentSubstationTypesState } from "state/substationType";
import { allSimpleTurbineTypesSelector } from "state/turbines";

export default function useComponentsInUse() {
  const allBranchFeatures = useRecoilValue(projectFeaturesSelector);
  const allCableTypesInUse = useRecoilValue(allCableTypesSelector).filter((c) =>
    allBranchFeatures.some((f) => f.properties.cableTypeId === c.id),
  );
  const allTurbineTypesInUse = useRecoilValue(
    allSimpleTurbineTypesSelector,
  ).filter((t) =>
    allBranchFeatures.some((f) => f.properties.turbineTypeId === t.id),
  );
  const allFoundationTypesInUse = useRecoilValue(
    allFoundationTypesSelector,
  ).filter((f) =>
    allBranchFeatures.some((feat) => feat.properties.foundationTypeId === f.id),
  );

  const allMooringLinesInUse = useRecoilValue(
    currentMooringLineTypesState,
  ).filter((m) =>
    allBranchFeatures.some((f) => f.properties.lineType === m.id),
  );

  const allSubStationsInUse = useRecoilValue(
    currentSubstationTypesState,
  ).filter((s) =>
    allBranchFeatures.some((f) => f.properties.substationTypeId === s.id),
  );

  const allExportCablesInUse = useRecoilValue(
    currentExportCableTypesSelector,
  ).filter((c) =>
    allBranchFeatures.some(
      (f) =>
        f.properties.cableTypeId === c.id ||
        f.properties.onshoreCableTypeId === c.id,
    ),
  );

  return {
    allCableTypesInUse,
    allTurbineTypesInUse,
    allFoundationTypesInUse,
    allMooringLinesInUse,
    allSubStationsInUse,
    allExportCablesInUse,
  };
}
