import { atomFamily, selector, selectorFamily } from "recoil";
import { currentVersionSelector } from "./project";
import { projectIdSelector } from "./pathParams";
import {
  SubstationType,
  _SubstationType,
  getSubstationTypes,
} from "services/substationService";
import { parseFilter } from "utils/zod";
import { z } from "zod";
import { uuidRegex } from "@constants/regex";

export const substationTypeAtomFamily = atomFamily<
  SubstationType[],
  {
    projectId: string | undefined;
    version?: number;
  }
>({
  key: "substationTypeAtomFamily",
  default: selectorFamily<
    SubstationType[],
    {
      projectId: string | undefined;
      version?: number;
    }
  >({
    key: "substationTypeAtomFamily.default",
    get:
      ({ projectId, version }) =>
      async () => {
        if (!projectId) return [];
        // If user goes to link with legacy type projectId/branchId and we try to
        // fetch substation types before the user has been redirected
        if (!uuidRegex.test(projectId)) return [];

        const response = await getSubstationTypes(projectId, version);
        if (!response.ok) return [];
        const j = await response.json();
        return parseFilter(_SubstationType, z.unknown().array().parse(j));
      },
  }),
});

export const currentSubstationTypesState = selector<SubstationType[]>({
  key: "currentSubstationTypesState",
  get: ({ get }) => {
    const projectId = get(projectIdSelector);
    if (!projectId) return [];
    // If user goes to link with legacy type projectId/branchId and we try to
    // fetch substation types before the user has been redirected
    if (!uuidRegex.test(projectId)) return [];

    const version = get(currentVersionSelector);
    const substations = get(substationTypeAtomFamily({ projectId, version }));
    return substations;
  },
});
