/// <reference types="vite-plugin-svgr/client" />
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { useGoToFeatures } from "../../hooks/map";
import ThreeDIcon from "@icons/24/SideBarIcons/3D.svg?react";
import Reset from "@icons/24/SideBarIcons/Reset.svg?react";
import SingleTurbine from "@icons/24/SideBarIcons/SingleTurbine.svg?react";
import HelpIcon from "@icons/24/Help.svg?react";
import Bulb from "@icons/24/Bulb.svg?react";
import Filter from "@icons/24/SideBarIcons/Filter.svg?react";
import Wind from "@icons/24/SideBarIcons/Wind.svg?react";
import Wave from "@icons/24/SideBarIcons/Wave.svg?react";
import { lowerRightMenuActiveModeAtom } from "../../state/layer";
import { mapRefAtom, getActiveMapStyleSelector } from "../../state/map";
import { getParkFeatureSelectorFamily } from "../../state/park";
import { replaceOrUndefined } from "../ControlPanels/utils";
import Tooltip from "../General/Tooltip";
import { MIN_ZOOM_TURBINES_VISIBLE } from "../MapFeatures/3Dturbines";
import FilterInput, { FilterMenuType } from "./FilterInput";
import { modalTypeOpenAtom } from "../../state/modal";
import LegendsWrapper from "./LegendsWrapper";
import Hints, { HintsMenuType } from "./Hints";
import PositionHint from "../ActiveTips/PositionHints/PositionHint";
import { resetOrientationHelpHelpHintType } from "../ActiveTips/PositionHints/ResetOrientationHelp";
import {
  BlueHelpPill,
  HelpText,
  HelpToolsWrapper,
  LowerRightColumnWrapperV2,
  RowWrapperV2,
} from "./LowerRight.style";
import { parkIdSelector } from "state/pathParams";
import { Divider, Icon, IconMenu } from "components/General/Icons";
import { MapStyleFrame } from "./MapStyle";
import SearchWrapper from "components/Search/SearchWrapper";
import ExistingTurbinesMenu from "./ExistingTurbines";
import { WindSources } from "components/WindWaveSource/WindSource";
import { WaveSources } from "components/WindWaveSource/WaveSource";
import { BottomMenuPositionWrapper } from "components/MenuPopup/CloseableMenuPopup";
import {
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";
import CostInput from "./CostLayer";
import SideBarIconBtn from "components/General/SideBarIconButton";
import { typography } from "styles/typography";
import { spacing1 } from "styles/space";
import { versionHistoryOpenStateAtom } from "components/Design/ProjectHistory/state";
import { TourStep } from "components/OnboardingTours/TourStep";
import { HighlightStep } from "components/OnboardingTours/HighlightWrapper";
import { useTrackEvent } from "components/OnboardingTours/state";
import { FloatingFocusManager, useFloating, offset } from "@floating-ui/react";

const LowerRightTools = forwardRef<HTMLDivElement>((_prop, ref) => {
  const map = useRecoilValue(mapRefAtom);
  const [lowerRightActiveMode, setLowerRightActiveMode] = useRecoilState(
    lowerRightMenuActiveModeAtom,
  );
  const setVersionHistoryOpenState = useSetRecoilState(
    versionHistoryOpenStateAtom,
  );
  const parkId = useRecoilValue(parkIdSelector);
  const goToFeatures = useGoToFeatures(map);
  const [isIn3d, setIsIn3d] = useState(false);

  const resetToNorth = useCallback(() => {
    if (!map) return;
    map.easeTo({ pitch: 0, bearing: 0, duration: 500 });
  }, [map]);

  const getParkFeature = useRecoilCallback(
    ({ snapshot }) =>
      ({ parkId }) => {
        return snapshot
          .getLoadable(getParkFeatureSelectorFamily({ parkId: parkId ?? "" }))
          .getValue();
      },
    [],
  );

  useEffect(() => {
    if (isIn3d) {
      // When the automatic pitching to 3d is done, set once-cb to reset isIn3d
      map?.once("pitchend", () => {
        map.once("pitch", () => {
          setIsIn3d(false);
        });
      });
    }
  }, [isIn3d, map]);

  const activeMapStyle = useRecoilValue(getActiveMapStyleSelector);

  const modalOpen = useRecoilValue(modalTypeOpenAtom);
  useEffect(() => {
    if (modalOpen) setLowerRightActiveMode(undefined);
  }, [modalOpen, setLowerRightActiveMode]);

  return (
    <IconMenu
      sideBar
      iconSize="2.4rem"
      ref={ref}
      style={{
        paddingBottom: 0,
        paddingTop: spacing1,
      }}
    >
      {parkId && (
        <SearchWrapper
          title="3D view"
          tags={["camera", "3d", "three"]}
          id="search-three-d"
          icon={<ThreeDIcon />}
          onSelect={() => {
            if (isIn3d) {
              map?.easeTo({
                pitch: 0,
                duration: 500,
              });
              return;
            }
            setIsIn3d(true);
            const park = getParkFeature({ parkId });
            if (!park) {
              return;
            }
          }}
        >
          <SideBarIconBtn
            active={isIn3d}
            onClick={(e) => {
              if (isIn3d) {
                map?.easeTo({
                  pitch: 0,
                  duration: 500,
                });
                return;
              }
              setIsIn3d(true);
              const park = getParkFeature({ parkId });
              if (!park) {
                return;
              }
              goToFeatures([park], e, {
                zoom: Math.max(MIN_ZOOM_TURBINES_VISIBLE, map?.getZoom() ?? 0),
                pitch: 75,
                bearing: map?.getBearing(),
                duration: 500,
              });
            }}
            icon={<ThreeDIcon />}
          >
            <p style={typography.graphics}>3D</p>
          </SideBarIconBtn>
        </SearchWrapper>
      )}
      <SearchWrapper
        title="Map layers"
        tags={["map", "layers", "style", "bathymetry", "satellite"]}
        id="search-map-layers"
        icon={
          <img
            style={{
              borderRadius: "2px",
              width: "2.6rem",
              height: "2.6rem",
            }}
            src={activeMapStyle?.image}
            alt="Active bathymetry style"
          />
        }
        onSelect={() => {
          setLowerRightActiveMode(replaceOrUndefined("mapLayers"));
          setVersionHistoryOpenState(false);
        }}
      >
        <SideBarIconBtn
          onClick={() => {
            setLowerRightActiveMode(replaceOrUndefined("mapLayers"));
            setVersionHistoryOpenState(false);
          }}
          icon={
            <img
              style={{
                borderRadius: "2px",
                width: "2.6rem",
                height: "2.6rem",
              }}
              src={activeMapStyle?.image}
              alt="Active bathymetry style"
            />
          }
          active={
            lowerRightActiveMode === "mapLayers" ||
            lowerRightActiveMode === "cost"
          }
        >
          <p style={typography.graphics}>Map</p>
        </SideBarIconBtn>
      </SearchWrapper>

      <SearchWrapper
        title="Reset orientation"
        tags={["camera", "map", "view", "north"]}
        id="search-reset-orientation"
        icon={<Reset />}
        onSelect={() => {
          resetToNorth();
        }}
      >
        <PositionHint
          allowedHints={[resetOrientationHelpHelpHintType]}
          position={"top"}
        >
          <SideBarIconBtn onClick={resetToNorth} icon={<Reset />}>
            <p style={typography.graphics}>Reset</p>
          </SideBarIconBtn>
        </PositionHint>
      </SearchWrapper>
      <Divider />
      <SearchWrapper
        title="Open wind measurement"
        tags={["waves", "nora3", "era5", "flag"]}
        id="search-wind-measurement"
        icon={<Wind />}
        onSelect={() => {
          setLowerRightActiveMode(replaceOrUndefined("windMeasurement"));
          setVersionHistoryOpenState(false);
        }}
      >
        <SideBarIconBtn
          active={lowerRightActiveMode === "windMeasurement"}
          onClick={() => {
            setLowerRightActiveMode(replaceOrUndefined("windMeasurement"));
            setVersionHistoryOpenState(false);
          }}
          icon={<Wind />}
        >
          <p style={typography.graphics}>Wind</p>
        </SideBarIconBtn>
      </SearchWrapper>

      <SearchWrapper
        title="Open wave sampler"
        tags={["waves", "nora3", "era5", "flag"]}
        id="search-wave-sampler"
        icon={<Wave />}
        onSelect={() => {
          setLowerRightActiveMode(replaceOrUndefined("waveSampler"));
          setVersionHistoryOpenState(false);
        }}
      >
        <SideBarIconBtn
          active={lowerRightActiveMode === "waveSampler"}
          onClick={() => {
            setLowerRightActiveMode(replaceOrUndefined("waveSampler"));
            setVersionHistoryOpenState(false);
          }}
          icon={<Wave />}
        >
          <p style={typography.graphics}>Wave</p>
        </SideBarIconBtn>
      </SearchWrapper>
      <SearchWrapper
        title="Existing turbines"
        tags={["existing", "turbines"]}
        id="existing-turbines"
        icon={<SingleTurbine />}
        onSelect={() => {
          setLowerRightActiveMode(replaceOrUndefined("existingTurbines"));
          setVersionHistoryOpenState(false);
        }}
      >
        <SideBarIconBtn
          active={lowerRightActiveMode === "existingTurbines"}
          onClick={() => {
            setLowerRightActiveMode(replaceOrUndefined("existingTurbines"));
            setVersionHistoryOpenState(false);
          }}
          icon={<SingleTurbine />}
        >
          <p style={typography.graphics}>Turbines</p>
        </SideBarIconBtn>
      </SearchWrapper>
      <Divider />
      <SearchWrapper
        title="Open map filter"
        tags={[
          "shoreline",
          "depth filter",
          "windspeed filter",
          "map layer",
          "constraint",
          "distance",
          "wind",
          "speed",
        ]}
        id="search-map-filter"
        icon={<Filter />}
        onSelect={() => {
          setLowerRightActiveMode(replaceOrUndefined(FilterMenuType));
          setVersionHistoryOpenState(false);
        }}
      >
        <SideBarIconBtn
          active={lowerRightActiveMode === FilterMenuType}
          onClick={() => {
            setLowerRightActiveMode(replaceOrUndefined(FilterMenuType));
            setVersionHistoryOpenState(false);
          }}
          icon={<Filter />}
        >
          <p style={typography.graphics}>Filter</p>
        </SideBarIconBtn>
      </SearchWrapper>
      <Divider />
    </IconMenu>
  );
});

const LowerRightToolsMenuV2 = ErrorBoundaryWrapper(
  () => {
    const parkId = useRecoilValue(parkIdSelector);
    const [lowerRightActiveMode, setLowerRightActiveMode] = useRecoilState(
      lowerRightMenuActiveModeAtom,
    );

    const trackEvent = useTrackEvent();

    const lowerRightToolsRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      const mapboxScaleElement = document.querySelector<HTMLDivElement>(
        ".mapboxgl-ctrl.mapboxgl-ctrl-scale",
      );

      if (!mapboxScaleElement) {
        return;
      }

      const toolsMenuResizeObserver = new ResizeObserver(([entry]) => {
        const remValue = parseFloat(
          getComputedStyle(document.documentElement).fontSize,
        );

        mapboxScaleElement!.style.right =
          (entry.borderBoxSize[0].inlineSize + 16 + 8) / remValue + "rem";
      });

      toolsMenuResizeObserver.observe(lowerRightToolsRef.current!);

      return () => {
        toolsMenuResizeObserver.disconnect();
      };
    }, []);

    const { refs, floatingStyles, context } = useFloating({
      placement: "top",
      middleware: [offset({ mainAxis: 20 })],
    });

    return (
      <LowerRightColumnWrapperV2>
        <RowWrapperV2>
          <LegendsWrapper />
          <FloatingFocusManager context={context} modal={false}>
            <TourStep
              tourId="general-intro-tour"
              stepId="beforeYouGo"
              innerRef={refs.setFloating}
              style={floatingStyles}
            />
          </FloatingFocusManager>
          <HelpToolsWrapper>
            <HighlightStep
              tourId="general-intro-tour"
              stepId="beforeYouGo"
              padding="4px"
            >
              <Tooltip text="Open help center" position="top">
                <BlueHelpPill
                  id="help_icon_link"
                  ref={refs.setReference}
                  onClick={() => trackEvent("clickedHelp")}
                >
                  <Icon size="1.8rem" stroke="white">
                    <HelpIcon />
                  </Icon>
                  <HelpText>Help</HelpText>
                </BlueHelpPill>
              </Tooltip>
            </HighlightStep>

            <Tooltip position="top" text="Hints">
              <SearchWrapper
                title="Open Hints"
                tags={[
                  "learn",
                  "discover",
                  "draw tips",
                  "tips and tricks",
                  "new",
                  "tutorial",
                  "beginner",
                  "onboarding",
                ]}
                id="search-hints"
                icon={<Bulb />}
                onSelect={() => {
                  setLowerRightActiveMode(replaceOrUndefined(HintsMenuType));
                }}
              >
                <BlueHelpPill
                  onClick={() =>
                    setLowerRightActiveMode(replaceOrUndefined(HintsMenuType))
                  }
                >
                  <Icon size="1.8rem" fill="white">
                    <Bulb />
                  </Icon>
                </BlueHelpPill>
              </SearchWrapper>
            </Tooltip>
          </HelpToolsWrapper>
          <LowerRightTools ref={lowerRightToolsRef} />
        </RowWrapperV2>
        <BottomMenuPositionWrapper parkSelected={!!parkId}>
          {lowerRightActiveMode === FilterMenuType && <FilterInput />}
          {lowerRightActiveMode === "windMeasurement" && (
            <WindSources onClose={() => setLowerRightActiveMode(undefined)} />
          )}
          {lowerRightActiveMode === "existingTurbines" && (
            <ExistingTurbinesMenu />
          )}
          {lowerRightActiveMode === "waveSampler" && (
            <WaveSources onClose={() => setLowerRightActiveMode(undefined)} />
          )}
          {lowerRightActiveMode === "cost" && <CostInput />}

          {lowerRightActiveMode === HintsMenuType && <Hints />}
          {lowerRightActiveMode === "mapLayers" && <MapStyleFrame />}
        </BottomMenuPositionWrapper>
      </LowerRightColumnWrapperV2>
    );
  },
  FatalErrorBoundaryWrapper,
  ScreamOnError,
);

export default LowerRightToolsMenuV2;
