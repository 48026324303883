import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import CloseIcon from "@icons/24/Close.svg?react";
import { colors } from "styles/colors";
import { typography } from "styles/typography";
import {
  borderRadiusMedium,
  borderRadiusSmall,
  spaceSmall,
  spaceTiny,
} from "styles/space";
import { Comp } from "types/utils";
import { IconBtn } from "components/General/Icons";
import { useClickOutside } from "hooks/useClickOutside";

const MultiInputWrapper = styled.div<{ isFocused: boolean }>`
  display: flex;
  flex-direction: row;
  position: relative;
  gap: ${spaceSmall};
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid ${colors.inputOutline};
  border-radius: ${borderRadiusMedium};
  box-sizing: border-box;
  background-color: white;
  padding: ${spaceTiny} ${spaceSmall};

  ${(p) => p.isFocused && `border-color: ${colors.primary};`}
  &:disabled {
    background-color: ${colors.focusBackground};
    color: ${colors.primaryDisabled};
  }
`;

const EnteredValueWrapper = styled.div<{ valid: boolean }>`
  background-color: ${colors.secondary};
  padding: 0 ${spaceSmall};
  border-radius: ${borderRadiusSmall};
  display: flex;
  gap: ${spaceTiny};
  align-items: center;
  ${(p) =>
    !p.valid &&
    `
    background-color: ${colors.errorText}33;
    border: 0.5px solid ${colors.errorText};
    `};
`;

const MultiInputTextInput = styled.input`
  ${typography.contentAndButtons}
  outline: none;
  border: none;
  flex-grow: 1;
  min-width: 50% !important;

  &:focus,
  &:focus-visible,
  &:focus-within {
    outline: none;
  }
`;

export type MultiInputRef = {
  /**
   * Check value validity and enter if valid, return any unentered value
   */
  checkValidityAndEnterIfValid(): string | false;
};

export type MultiInputValue = { value: string; valid: boolean };

const SuggestionsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 3;
  width: 100%;
  background-color: ${colors.background};
  border: 1px solid ${colors.inputOutline};
  box-sizing: border-box;
`;

// I tried to make it generic :(
interface MultiInputPropsWithSuggesionsProps<T = unknown>
  extends Comp<
    "input",
    {
      onEnter?: (newVal: string) => void;
      onEnterSuggestion?: (suggestion: T) => void;
      onCancel?: () => void;
      value: string;
      enteredValues: MultiInputValue[];
      inputStyle?: React.CSSProperties;
      onRemoveValue: (value: string, isFromBackspace: boolean) => void;
      suggestions?: T[]; // Use the generic type parameter T for suggestions
      renderSuggestionRow?: (
        suggestion: T,
        isActive?: boolean,
      ) => React.ReactNode;
      suggestionsWrapperStyle?: React.CSSProperties;
    }
  > {}

const MultiInputWithSuggestions = forwardRef<
  MultiInputRef,
  // I tried to make it generic :(
  MultiInputPropsWithSuggesionsProps<any>
>(
  (
    {
      onEnter,
      onCancel,
      onRemoveValue,
      enteredValues,
      suggestions,
      renderSuggestionRow,
      onEnterSuggestion,
      value,
      style,
      inputStyle,
      suggestionsWrapperStyle,
      ...otherProps
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const suggestionWrapperRef = useRef<HTMLDivElement>(null);
    const [showWrapperIsFocused, setShowShowWrapperIsFocused] = useState(false);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState<
      undefined | number
    >(undefined);

    useClickOutside(suggestionWrapperRef, () => {
      setShowSuggestions(false);
    });

    const scrollToSuggestion = useCallback((index: number) => {
      const suggestionElement = suggestionWrapperRef.current?.children[
        index
      ] as HTMLElement;
      if (!suggestionElement) {
        return;
      }
      const parent = suggestionElement.parentElement;
      if (!parent) {
        return;
      }
      const parentRect = parent.getBoundingClientRect();
      const suggestionRect = suggestionElement.getBoundingClientRect();
      if (suggestionRect.bottom > parentRect.bottom) {
        parent.scrollTop = suggestionElement.offsetTop - parent.offsetTop;
      } else if (suggestionRect.top < parentRect.top) {
        parent.scrollTop =
          suggestionElement.offsetTop - parent.offsetTop - parentRect.height;
      }
    }, []);

    const handleSuggestionKeydown = useCallback(
      (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (!suggestions || suggestions.length === 0) {
          return false;
        }

        if (e.key === "ArrowDown" || e.key === "ArrowUp") {
          const definedActiveSuggestionIndex = activeSuggestionIndex ?? -1;
          let nextIndex =
            e.key === "ArrowDown"
              ? (definedActiveSuggestionIndex + 1) % suggestions.length
              : definedActiveSuggestionIndex - 1;
          if (nextIndex < 0) {
            nextIndex = suggestions.length - 1;
          }

          setActiveSuggestionIndex(nextIndex);
          scrollToSuggestion(nextIndex);
          return true;
        } else if (
          e.key === "Enter" &&
          typeof activeSuggestionIndex !== "undefined"
        ) {
          const nextItem = suggestions[activeSuggestionIndex];
          if (!nextItem || nextItem.disabled) {
            return false;
          }
          onEnterSuggestion?.(suggestions[activeSuggestionIndex]);
          setActiveSuggestionIndex(undefined);
          return true;
        }
        return false;
      },
      [
        activeSuggestionIndex,
        onEnterSuggestion,
        scrollToSuggestion,
        suggestions,
      ],
    );

    const _onKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        e.stopPropagation();
        otherProps.onKeyDown?.(e);
        if (handleSuggestionKeydown(e)) {
          return;
        }

        // Enter, Tab, comma (for email)
        if (
          e.key === "Enter" ||
          e.key === "Tab" ||
          (otherProps.type === "email" && e.key === ",")
        ) {
          e.preventDefault();
          if (value !== "" && inputRef.current?.reportValidity()) {
            onEnter?.(value);
          }
        }

        // Remove latest value when clicking backspace when input is empty
        if (e.key === "Backspace") {
          if (value === "" && enteredValues.length > 0) {
            e.preventDefault();
            const previousValue = enteredValues[enteredValues.length - 1];
            onRemoveValue(previousValue.value, true);
          }
        }
        if (e.key === "Escape") {
          onCancel?.();
        }
      },
      [
        otherProps,
        handleSuggestionKeydown,
        value,
        onEnter,
        enteredValues,
        onRemoveValue,
        onCancel,
      ],
    );

    useEffect(() => {
      setActiveSuggestionIndex(undefined);
    }, [value]);

    useImperativeHandle(ref, () => ({
      checkValidityAndEnterIfValid: () => {
        if (inputRef.current?.reportValidity()) {
          if (value !== "") {
            onEnter?.(value);
          }
          return value as string;
        }

        return false;
      },
    }));

    // Add focus style to wrapper when input is focused
    useEffect(() => {
      if (!inputRef.current) {
        return;
      }

      const onInputFocus = () => {
        setShowShowWrapperIsFocused(true);
      };

      const onInputBlur = () => {
        setShowShowWrapperIsFocused(false);
      };

      inputRef.current.addEventListener("focus", onInputFocus);
      inputRef.current.addEventListener("blur", onInputBlur);

      const currInputRef = inputRef.current;
      return () => {
        currInputRef?.removeEventListener("focus", onInputFocus);
        currInputRef?.removeEventListener("blur", onInputBlur);
      };
    }, []);

    // Put focus to input when clicking on the wrapper
    const onWrapperClick = useCallback((e: React.MouseEvent) => {
      e.stopPropagation();
      inputRef.current?.focus();
    }, []);

    useEffect(() => {
      if (suggestions && suggestions.length > 0) {
        setShowSuggestions(true);
      }
    }, [suggestions]);

    return (
      <>
        <MultiInputWrapper
          style={style}
          onClick={onWrapperClick}
          isFocused={showWrapperIsFocused}
        >
          {enteredValues.map((value) => (
            <EnteredValueWrapper key={value.value} valid={value.valid}>
              <p
                style={{
                  ...typography.label,
                  margin: 0,
                  color: colors.primaryText,
                }}
              >
                {value.value}
              </p>
              <IconBtn
                size="0.7rem"
                style={{
                  padding: spaceTiny,
                  backgroundColor: "unset",
                }}
                pathStrokeWidth={3}
                onClick={(e) => {
                  e.preventDefault();
                  onRemoveValue(value.value, false);
                }}
              >
                <CloseIcon />
              </IconBtn>
            </EnteredValueWrapper>
          ))}
          <MultiInputTextInput
            ref={inputRef}
            {...otherProps}
            style={inputStyle}
            onKeyDown={_onKeyDown}
            value={value}
          />
        </MultiInputWrapper>
        {showSuggestions &&
          suggestions &&
          suggestions.length > 0 &&
          typeof renderSuggestionRow !== "undefined" && (
            <SuggestionsListWrapper
              ref={suggestionWrapperRef}
              style={suggestionsWrapperStyle}
            >
              {suggestions!.map((suggestion, index) =>
                renderSuggestionRow(
                  suggestion,
                  activeSuggestionIndex === index,
                ),
              )}
            </SuggestionsListWrapper>
          )}
      </>
    );
  },
);

// const MultiInputWithSuggestions = React.forwardRef(
//   MultiInputWithSuggestionsInner
// ) as <T>(
//   props: MultiInputPropsWithSuggesionsProps<T> & {
//     ref?: React.ForwardedRef<HTMLUListElement>;
//   }
// ) => ReturnType<typeof MultiInputWithSuggestionsInner>;

export default MultiInputWithSuggestions;
