import { selectorFamily, atom, atomFamily } from "recoil";
import { fetchEnhancer, fetchSchemaWithToken } from "../services/utils";
import { z } from "zod";
import { nDirectionsWindRoseVisualisation } from "../components/ProductionV2/constants";
import {
  fetchWindData,
  fetchWindRose,
  fetchCustomWindRose,
  fetchAvailableWindDatasets,
  _WindSource,
  WindDataset,
  WindDataSource,
  _CustomWindSource,
  fetchWindGumbelParameters,
  fetchSimpleWindRose,
  CustomWindFileType,
} from "../services/metoceanService";
import {
  isBestWindSourceConfiguration,
  isBuiltInWindSourceConfiguration,
  WindSourceConfiguration,
  isCustomWindSource,
  isMultipleSourceWindConfiguration,
} from "../services/windSourceConfigurationService";
import { gumbelPercentileValue } from "../functions/met";
import { Position } from "@turf/turf";

function haversineDistance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
) {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
}

function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}

export const DEFAULT_ALPHA = 0.08;

export {
  WindDataSource,
  WindSpeedCalibrationType,
} from "../services/metoceanService";

export const showWakeAtom = atom({
  key: "show-wake",
  default: false,
});

export const showMeanSpeedGridAtom = atom<boolean>({
  key: "show-mean-speed-grid",
  default: false,
});

export const meanSpeedGridLimitsAtom = atom<[number, number] | undefined>({
  key: "mean-speed-grid-limits",
  default: undefined,
});

export const showWakeLabelsAtom = atom({
  key: "show-wake-labels",
  default: false,
});

export const showCableLossesAtom = atom({
  key: "show-cable-losses",
  default: false,
});

export const wakeDirectionAtom = atom({
  key: "wake-direction",
  default: 270,
});

const _WRGSector = z.object({
  probability: z.number(),
  A: z.number(), // Weibull A parameter
  k: z.number(), // Weibull k parameter
});
export type WRGSector = z.infer<typeof _WRGSector>;

const _WRGPoint = z.object({
  easting: z.number(),
  northing: z.number(),
  lon: z.number(),
  lat: z.number(),
  sectors: _WRGSector.array(),
  A: z.number(),
  k: z.number(),
  power: z.number(),
  elevation: z.number(),
  height: z.number(),
});
export type WRGPoint = z.infer<typeof _WRGPoint>;

export const _WRG = z.object({
  points: _WRGPoint.array(),
  easting: z.number(),
  northing: z.number(),
  lon: z.number(),
  lat: z.number(),
  height: z.number(),
});

export type WRG = z.infer<typeof _WRG>;

export const _MeanSpeedGrid = z.object({
  /** number of columns in the grid */
  ncols: z.number(),
  /** number of rows in the grid */
  nrows: z.number(),
  /** distance between rows/columns */
  dx: z.number(),
  dy: z.number(),
  /** x-coordinate of lower left corner */
  xllcorner: z.number(),
  /** y-coordinate of lower left corner */
  yllcorner: z.number(),
  /** grid values */
  grid: z.number().array().array(),
  height: z.number(),
});

export const _SpatialCalibrationData = _MeanSpeedGrid.or(_WRG);

export type MeanSpeedGrid = z.infer<typeof _MeanSpeedGrid>;

const _MeanSpeedGridCustom = _MeanSpeedGrid.extend({
  lon: z.number(),
  lat: z.number(),
});

export type MeanSpeedGridCustom = z.infer<typeof _MeanSpeedGridCustom>;

export const _WindTimeseries = z.object({
  speed: z.number().array(),
  direction: z.number().array(),
  alpha: z.number().array().or(z.number()),
  timestamps: z.string().array().optional(),
  longitude: z.number(),
  latitude: z.number(),
  height: z.number(),
  source: _WindSource,
});
export type WindTimeseries = z.infer<typeof _WindTimeseries>;

export const _WindData = z.object({
  timestamps: z.string().array(),
  speed: z.number().array(),
  direction: z.number().array(),
  alpha: z.number().array().or(z.number()),
  lon: z.number().optional(),
  lat: z.number().optional(),
  height: z.number(),
  uploaded: z.number().optional(),
});

export const _WindDataMultipleHeights = z.object({
  timestamps: z.string().array(),
  speed: z.record(z.array(z.number())),
  direction: z.record(z.array(z.number())),
  lon: z.number().optional(),
  lat: z.number().optional(),
  heights: z.number().array(),
  uploaded: z.number().optional(),
});

export const _WindDataVortex = _WindData.extend({
  density: z.number().array().optional(),
});
export type WindData = z.infer<typeof _WindData>;
export type WindDataVortex = z.infer<typeof _WindDataVortex>;
export type WindDataMultipleHeights = z.infer<typeof _WindDataMultipleHeights>;

export const isWindDataMultipleHeights = (
  data: WindData | WindDataVortex | WindDataMultipleHeights,
): data is WindDataMultipleHeights => {
  return "heights" in data;
};

const _RawWindDirection = z.object({
  direction: z.number(),
  probability: z.number(),
  meanSpeed: z.number(),
  speedProbabilities: z.number().array(),
});
export type RawWindDirection = z.infer<typeof _RawWindDirection>;

const _WindSpeed = z.object({
  from: z.number(),
  to: z.number(),
});

export const _RawWindRose = z.object({
  directions: _RawWindDirection.array(),
  speeds: _WindSpeed.array(),
  windSpeeds: z.number().array(),
  speedProbabilities: z.number().array(),
  meanWindSpeed: z.number(),
  height: z.number(),
  longitude: z.number().optional(),
  latitude: z.number().optional(),
  source: z.union([_WindSource, _CustomWindSource]).optional(),
  alpha: z.number(),
});
export type RawWindRose = z.infer<typeof _RawWindRose>;

export const _SimpleWindRose = z.object({
  directions: z
    .object({
      direction: z.number(),
      probability: z.number(),
      meanSpeed: z.number(),
    })
    .array(),
  longitude: z.number(),
  latitude: z.number(),
  height: z.number(),
  alpha: z.number(),
  speeds: z.number().array(),
  speedProbabilities: z.number().array(),
});

export type SimpleWindRose = z.infer<typeof _SimpleWindRose>;

export const _YearlyMonthlyWindRoses = z.object({
  yearly: _RawWindRose,
});

export type YearlyMonthlyWindRoses = z.infer<typeof _YearlyMonthlyWindRoses>;

const _UploadedWindData = z
  .object({
    id: z.string(),
    name: z.string(),
    url: z.string().optional(),
    type: z.string().optional(),
    data: _WindData.optional(),
  })
  .array();
export type UploadedWindData = z.infer<typeof _UploadedWindData>;

const _WindStats = z.object({
  /** 1-year wind speed (1-hour value) */
  windSpeed1Yr: z.number(),
  /** dataset used to extract wind stats (NORA3, ERA5, ..) */
  windDataSource: z.string(),
});

export type WindStats = z.infer<typeof _WindStats>;

export const customWindDataWithNodeIdSelector = atomFamily<
  UploadedWindData,
  { nodeId?: string }
>({
  key: "customWindDataWithNodeIdSelector",
  default: selectorFamily({
    key: "customWindDataWithNodeIdSelectorDefault",
    get:
      ({ nodeId }) =>
      async () => {
        if (!nodeId) return [];
        const uploadedData = await fetchSchemaWithToken(
          _UploadedWindData,
          `/api/configuration/wind/node/${nodeId}`,
          {
            method: "get",
          },
        );
        return uploadedData;
      },
  }),
});

export const customWindTimeseriesSelector = selectorFamily<
  UploadedWindData,
  { nodeId?: string }
>({
  key: "customWindTimeseriesSelector",
  get:
    ({ nodeId }) =>
    async ({ get }) => {
      if (!nodeId) return [];
      const uploadedWindData: UploadedWindData = get(
        customWindDataWithNodeIdSelector({ nodeId }),
      );
      const windDataSets = uploadedWindData.filter(
        (d) => !d.type || d.type === CustomWindFileType.TIMESERIES,
      );
      const enrichedUploadedData = await Promise.all(
        windDataSets.map(async (windData) => {
          if (windData.url) {
            return fetchEnhancer(windData.url, {
              method: "get",
            }).then(async (response) => {
              if (!response.ok)
                throw new Error("Failed to fetch custom wind data source");

              const body = await response.json();
              const fetchedData = _WindData.parse(body);

              return {
                ...windData,
                data: fetchedData,
              };
            });
          } else {
            return windData;
          }
        }),
      );
      return enrichedUploadedData;
    },
});

export const customWRGFileSelector = selectorFamily<
  { id: string; wrg: WRG }[],
  { nodeId?: string }
>({
  key: "customWRGFileSelector",
  get:
    ({ nodeId }) =>
    async ({ get }) => {
      if (!nodeId) return [];
      const uploadedWindData: UploadedWindData = get(
        customWindDataWithNodeIdSelector({ nodeId }),
      );
      const wrgFiles = uploadedWindData.filter(
        (d) => d.type === CustomWindFileType.WRG,
      );
      return await Promise.all(
        wrgFiles.map(async (wrgFile) => {
          if (wrgFile.url) {
            return fetchEnhancer(wrgFile.url, {
              method: "get",
            }).then(async (response) => {
              if (!response.ok)
                throw new Error(
                  "Failed to fetch WRG file " +
                    wrgFile.id +
                    " for node " +
                    nodeId,
                );

              const body = await response.json();
              const wrg = _WRG.parse(body);

              return { id: wrgFile.id, wrg };
            });
          } else {
            throw new Error(
              "Failed to fetch WRG file " + wrgFile.id + " for node " + nodeId,
            );
          }
        }),
      );
    },
});

export const customMeanSpeedGridFileSelector = selectorFamily<
  { id: string; data: MeanSpeedGridCustom | undefined }[],
  { nodeId?: string }
>({
  key: "customMeanSpeedGridFileSelector",
  get:
    ({ nodeId }) =>
    async ({ get }) => {
      if (!nodeId) return [];
      const uploadedWindData: UploadedWindData = get(
        customWindDataWithNodeIdSelector({ nodeId }),
      );
      const meanSpeedGridFiles = uploadedWindData.filter(
        (d) => d.type === CustomWindFileType.MEAN_SPEED_GRID,
      );
      return await Promise.all(
        meanSpeedGridFiles.map(async (file) => {
          if (file.url) {
            return fetchEnhancer(file.url, {
              method: "get",
            }).then(async (response) => {
              if (!response.ok)
                throw new Error(
                  "Failed to fetch Mean speed grid file " +
                    file.id +
                    " for node " +
                    nodeId,
                );

              const body = await response.json();
              const meanSpeedgrid = _MeanSpeedGridCustom.parse(body);

              return { id: file.id, data: meanSpeedgrid };
            });
          } else {
            throw new Error(
              "Failed to fetch Mean speed grid file " +
                file.id +
                " for node " +
                nodeId,
            );
          }
        }),
      );
    },
});
export const mostProbableWindDirectionSelector = selectorFamily<
  number,
  {
    lon: number;
    lat: number;
    height: number;
    fromYear?: number;
    toYear?: number;
  }
>({
  key: "mostProbableWindDirectionSelector",
  get:
    ({ lon, lat, height, fromYear = 1990, toYear = 2022 }) =>
    async ({ get }) => {
      const { source } = get(bestWindDatasetSelectorFamily({ lon, lat }));

      const data = get(
        windRoseSelector({
          lon,
          lat,
          height,
          source: source.id,
          fromYear,
          toYear,
        }),
      );
      return data.directions.reduce(
        (md, d) => (md.probability > d.probability ? md : d),
        { probability: 0, direction: 0 },
      ).direction;
    },
});

export const bestWindRoseSelector = selectorFamily<
  RawWindRose,
  {
    lon: number;
    lat: number;
    height: number;
    numberOfDirections?: number;
    fromYear?: number;
    toYear?: number;
  }
>({
  key: "bestWindRoseSelector",
  get:
    ({ lon, lat, height, fromYear, toYear }) =>
    async ({ get }) => {
      const { source } = get(bestWindDatasetSelectorFamily({ lon, lat }));

      return get(
        windRoseSelector({
          lon,
          lat,
          height,
          source: source.id,
          fromYear,
          toYear,
        }),
      );
    },
});

export const windRoseFromConfigSelector = selectorFamily<
  SimpleWindRose | undefined,
  {
    lon: number;
    lat: number;
    height: number;
    numberOfDirections?: number;
    config: WindSourceConfiguration | undefined;
    projectId: string;
  }
>({
  key: "windRoseFromConfigSelector",
  get:
    ({
      lon,
      lat,
      height,
      numberOfDirections = nDirectionsWindRoseVisualisation,
      config,
      projectId,
    }) =>
    async () => {
      if (!config) return;
      if (
        isBestWindSourceConfiguration(config) ||
        isBuiltInWindSourceConfiguration(config)
      ) {
        return await fetchSimpleWindRose({
          lon,
          lat,
          height,
          numberOfDirections,
          sourceId: config.source.id,
        });
      } else if (isMultipleSourceWindConfiguration(config)) {
        const closestSource = config.source.data.reduce((prev, curr) => {
          const prevDistance = haversineDistance(
            prev.latitude ?? 0,
            prev.longitude ?? 0,
            lat,
            lon,
          );
          const currDistance = haversineDistance(
            curr.latitude ?? 0,
            curr.longitude ?? 0,
            lat,
            lon,
          );
          return currDistance < prevDistance ? curr : prev;
        });

        return await fetchCustomWindRose({
          height,
          numberOfDirections,
          sourceId: closestSource.id,
          nodeId: projectId,
        });
      } else if (isCustomWindSource(config.source) && config.source.id) {
        return await fetchCustomWindRose({
          height,
          numberOfDirections,
          sourceId: config.source.id,
          nodeId: projectId,
        });
      }
    },
});

export const windRoseSelector = selectorFamily<
  RawWindRose,
  {
    lon: number;
    lat: number;
    height: number;
    numberOfDirections?: number;
    source: WindDataSource;
    fromYear?: number;
    toYear?: number;
  }
>({
  key: "windRoseSelector",
  get:
    ({
      lon,
      lat,
      height,
      numberOfDirections = nDirectionsWindRoseVisualisation,
      source,
      fromYear = 1990,
      toYear = 2022,
    }) =>
    async () => {
      return await fetchWindRose({
        lon,
        lat,
        height,
        numberOfDirections,
        source,
        fromYear,
        toYear,
      });
    },
});

export const simpleWindRoseSelector = selectorFamily<
  SimpleWindRose,
  {
    lon: number;
    lat: number;
    height: number;
    numberOfDirections?: number;
    source: WindDataSource;
  }
>({
  key: "simpleWindRoseSelector",
  get:
    ({
      lon,
      lat,
      height,
      numberOfDirections = nDirectionsWindRoseVisualisation,
      source,
    }) =>
    async () => {
      return await fetchSimpleWindRose({
        lon,
        lat,
        height,
        numberOfDirections,
        sourceId: source,
      });
    },
});

export const bestWindTimeseriesSelector = selectorFamily<
  WindTimeseries,
  {
    lon: number;
    lat: number;
    height: number;
  }
>({
  key: "bestWindTimeseriesSelector",
  get:
    ({ lon, lat, height }) =>
    async ({ get }) => {
      const { source } = get(bestWindDatasetSelectorFamily({ lon, lat }));

      const res: WindTimeseries = await fetchWindData({
        source: source.id,
        lon,
        lat,
        height,
      });

      return res;
    },
});

export const windTimeseriesSelector = selectorFamily<
  WindTimeseries,
  {
    lon: number;
    lat: number;
    height: number;
    source: WindDataSource;
    fromYear?: number;
    toYear?: number;
  }
>({
  key: "windTimeseriesSelector",
  get:
    ({ lon, lat, height, source, fromYear = 1990, toYear = 2022 }) =>
    async () => {
      return fetchWindData({
        source,
        lon,
        lat,
        height,
        fromYear,
        toYear,
      });
    },
});

const bestWindDatasetSelectorFamily = selectorFamily<
  WindDataset,
  { lon: number; lat: number }
>({
  key: "bestWindDatasetSelectorFamily",
  get:
    ({ lon, lat }) =>
    async ({ get }) => {
      return get(windDatasetsSelectorFamily({ lon, lat }))[0];
    },
});

export const windDatasetsSelectorFamily = selectorFamily<
  WindDataset[],
  { lon?: number; lat?: number }
>({
  key: "windDatasetsSelectorFamily",
  get:
    ({ lon, lat }) =>
    async () => {
      if (!(lon && lat)) {
        return [];
      }

      return await fetchAvailableWindDatasets({ lon, lat });
    },
});

export const getWindStatsSelectorFamily = selectorFamily<
  WindStats | undefined,
  {
    parkCenter: Position | undefined;
    hubHeight: number;
  }
>({
  key: "getWindStatsSelectorFamily",
  get:
    ({ parkCenter, hubHeight }) =>
    async ({ get }) => {
      if (!parkCenter) return;

      const [lon, lat] = parkCenter;

      const { source } = get(bestWindDatasetSelectorFamily({ lon, lat }));

      const windParams = await fetchWindGumbelParameters({
        source: source.id,
        lon,
        lat,
        height: hubHeight,
      });

      if (!windParams.loc || !windParams.scale) {
        throw new Error(
          "Fitting of statistical extreme wind speed distribution did not converge.",
        );
      }

      const windSpeed1Yr = gumbelPercentileValue(
        windParams.loc,
        windParams.scale,
        Math.exp(1),
      );

      return {
        windSpeed1Yr,
        windDataSource: source.id,
      };
    },
});
