import { atom, atomFamily, selectorFamily } from "recoil";
import {
  fetchWindConfigurationUsage,
  listWindSourceConfigurations,
  WindConfigurationUsageType,
  WindSourceConfiguration,
  _WindSourceConfiguration,
} from "../services/windSourceConfigurationService";
import { getBranchSelectorFamily } from "./timeline";
import { projectIdSelector } from "./pathParams";

export const windSourceConfigurationsAtomFamily = atomFamily<
  WindSourceConfiguration[],
  { projectId: string | undefined }
>({
  key: "windSourceConfigurationsAtomFamily",
  default: selectorFamily<
    WindSourceConfiguration[],
    { projectId: string | undefined }
  >({
    key: "windSourceConfigurationsSelectorFamily",
    get:
      ({ projectId }) =>
      async () => {
        if (!projectId) return [];
        const response = await listWindSourceConfigurations(projectId);
        if (!response.ok) {
          throw new Error("Fetching configurations failed");
        }
        const parsed = (await response.json()) as {
          configurations: WindSourceConfiguration[];
        };
        const j = parsed.configurations;
        const configs = _WindSourceConfiguration.array().parse(j);

        return configs.sort((a, b) => a.created_ts - b.created_ts);
      },
  }),
});

export const selectedWindConfigurationAtomFamily = atomFamily<
  WindSourceConfiguration | undefined,
  {
    projectId: string | undefined;
    branchId: string | undefined;
  }
>({
  key: "selectedWindConfigurationAtom",
  default: selectorFamily<
    WindSourceConfiguration | undefined,
    {
      projectId: string | undefined;
      branchId: string | undefined;
    }
  >({
    key: "selectedWindConfigurationSelector",
    get:
      ({ projectId, branchId }) =>
      async ({ get }) => {
        if (!projectId || !branchId) return undefined;

        const configs = get(windSourceConfigurationsAtomFamily({ projectId }));
        const branchMeta = get(
          getBranchSelectorFamily({
            projectId,
            branchId,
          }),
        );

        const selectedConfigId = branchMeta?.windConfigurationId;
        if (!selectedConfigId) return undefined;

        return configs.find((c) => c.id === selectedConfigId);
      },
  }),
});

export const windConfigurationUsageRefresh = atomFamily<
  number,
  { nodeId: string; windConfigurationId: string }
>({
  key: "windConfigurationUsageRefresh",
  default: () => {
    return 0;
  },
});

// This is used to store the config name while still editing the config
// to avoid rendering the component and loose the values that is stored in LocalConfig
export const windConfigurationTempName = atomFamily<
  { [key: string]: string | undefined },
  { nodeId: string }
>({
  key: "windConfigurationTempName",
  default: () => {
    return {};
  },
});

export const windConfigurationUsageAtomFamily = atomFamily<
  WindConfigurationUsageType[],
  { projectId: string; windConfigurationId: string }
>({
  key: "windConfigurationUsageAtomFamily",
  default: selectorFamily<
    WindConfigurationUsageType[],
    { projectId: string; windConfigurationId: string }
  >({
    key: "windConfigurationUsageSelectorFamily",
    get:
      ({ projectId, windConfigurationId }) =>
      async ({ get }) => {
        if (!projectId) return [];
        get(
          windConfigurationUsageRefresh({
            nodeId: projectId,
            windConfigurationId,
          }),
        );
        const usage = await fetchWindConfigurationUsage(
          projectId,
          windConfigurationId,
        );
        return usage;
      },
  }),
});

export const uploadedWindDataSourceUsageAtomFamily = selectorFamily<
  number,
  { uploadedWindDataId: string }
>({
  key: "uploadedWindDataSourceUsageAtomFamily",
  get:
    ({ uploadedWindDataId }) =>
    async ({ get }) => {
      const projectId = get(projectIdSelector);
      const configurations = get(
        windSourceConfigurationsAtomFamily({
          projectId,
        }),
      );

      return configurations.filter((config) => {
        return (
          (config.source.type === "custom" &&
            config.source.id === uploadedWindDataId) ||
          (config.spatial_calibration?.type === "custom" &&
            config.spatial_calibration?.id === uploadedWindDataId)
        );
      }).length;
    },
});

export const savingWindConfigurationInProgressAtom = atom<boolean>({
  key: "savingWindConfigurationInProgressAtom",
  default: false,
});
