import { useRecoilCallback, useRecoilValue } from "recoil";
import {
  showRefreshTimelineButtonBranchAtomFamily,
  showRefreshTimelineButtonProjectAtomFamily,
} from "../state/timeline";
import {
  ABLY_BRANCH_LCOE_COST_UPDATE,
  ABLY_COLLECTION_DELETE,
  ABLY_COLLECTION_UPDATE,
  ABLY_LAYER_UPDATE,
  ABLY_PROJECT_ELEMENTS,
  ABLY_TIMELINE_UPDATE,
} from "../state/ably";
import { AblyEventHandler, useAblyGeneric } from "./useAblyGeneric";
import { Types } from "ably";
import { useCallback, useMemo } from "react";
import {
  branchIdSelector,
  projectIdSelector,
  useTypedPath,
} from "../state/pathParams";

const timelineAwareProjectEventIds = [
  ABLY_BRANCH_LCOE_COST_UPDATE,
  ABLY_TIMELINE_UPDATE,
  ABLY_LAYER_UPDATE,
  ABLY_COLLECTION_UPDATE,
  ABLY_COLLECTION_DELETE,
];

const timelineAwareBranchEventIds = [
  ABLY_PROJECT_ELEMENTS,
  ABLY_TIMELINE_UPDATE,
];

const useAblyTimelineRefreshCallback = () => {
  const projectId = useRecoilValue(projectIdSelector);
  const branchId = useRecoilValue(branchIdSelector);

  return useRecoilCallback(
    ({ set }) =>
      async (event: AblyEventHandler, msg: Types.Message) => {
        const branchIdMaybe = msg.data.branchId;

        if (
          projectId &&
          branchId &&
          branchId === branchIdMaybe &&
          timelineAwareBranchEventIds.includes(event.eventName)
        ) {
          set(
            showRefreshTimelineButtonBranchAtomFamily({
              projectId,
              branchId,
            }),
            true,
          );
        }
        if (
          projectId &&
          timelineAwareProjectEventIds.includes(event.eventName)
        ) {
          set(
            showRefreshTimelineButtonProjectAtomFamily({
              projectId,
            }),
            true,
          );
        }
      },
    [projectId, branchId],
  );
};

function useAblyTimelineUpdate() {
  const { projectId } = useTypedPath("projectId");

  const channelName = useMemo(
    () => projectId && `${projectId}:all`,
    [projectId],
  );

  const onMessageReceived = useCallback(() => {}, []);

  const events = useMemo(
    () => [{ eventName: ABLY_TIMELINE_UPDATE, onMessageReceived }],
    [onMessageReceived],
  );

  useAblyGeneric(channelName, events);
}

export const TimelineUpdateComponent = () => {
  useAblyTimelineUpdate();

  return null;
};

export default useAblyTimelineRefreshCallback;
