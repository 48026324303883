import { useRecoilValue } from "recoil";

import { TurbineFeature } from "../../../../../types/feature";

import groupBy from "../../../../../utils/groupBy";
import { MonopileSingleStats } from "./MonopileSingleStats";
import { allFixedTypesSelector } from "../../../../../state/foundations";
import { getDetailedMonopileMassesSelectorFamily } from "./state";
import { dedup } from "utils/utils";

export const DetailedMonopileGenerationInner = ({
  turbines,
  turbineNames,
  rasterId,
}: {
  turbines: TurbineFeature[];
  turbineNames: { [key: string]: string };
  rasterId: string;
}) => {
  const { foundationDetails } = useRecoilValue(
    getDetailedMonopileMassesSelectorFamily({
      turbinesWithDetailedMonopile: turbines,
      rasterId,
    }),
  );

  const allFixed = useRecoilValue(allFixedTypesSelector);
  const foundationIds = dedup(
    turbines.map((turbine) => turbine.properties.foundationId),
  );

  const monopileDesignsByTurbineTypeId = groupBy(
    foundationDetails,
    ({ turbineTypeId }) => turbineTypeId,
  );

  const foundationNames = new Map(
    allFixed
      .filter((f) => foundationIds.includes(f.id))
      .map((f) => [f.id, f.name]),
  );

  const turbineMonopileCombinations = Array.from(
    new Set(
      turbines.map(
        (t) => `${t.properties.turbineTypeId}-${t.properties.foundationId}`,
      ),
    ),
  );

  return (
    <>
      {turbineMonopileCombinations.map((combination) => {
        const [t_id, f_id] = combination.split("-");
        const t_name = turbineNames[t_id];
        const f_name = String(foundationNames.get(f_id));
        return (
          <MonopileSingleStats
            key={t_id + f_id}
            name={t_name + " - " + f_name}
            designs={monopileDesignsByTurbineTypeId[t_id]}
          />
        );
      })}
    </>
  );
};
