import { ReactNode } from "react";
import styled from "styled-components";
import AlertCircleIcon from "@icons/24/AlertCircle.svg";
import { dynamicVectorLayerFeaturesAtomFamily } from "state/layer";
import { Layer } from "types/layers";
import { useRecoilValue } from "recoil";
import { IconREMSize } from "styles/typography";
import Tooltip from "components/General/Tooltip";

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  overflow: auto;
`;

export const VectorError = ({
  layer,
  children,
}: {
  layer: Layer;
  children?: ReactNode;
}) => {
  const dynamicVectorLayerFeatures = useRecoilValue(
    dynamicVectorLayerFeaturesAtomFamily(layer.id),
  );

  if (!dynamicVectorLayerFeatures.hasError) return null;

  return (
    <ErrorWrapper>
      <Tooltip
        text={`Layer experienced error when downloading, ${dynamicVectorLayerFeatures.features.length === 0 ? `no features downloaded` : `${dynamicVectorLayerFeatures.features.length} features successfully downloaded`}`}
      >
        <IconREMSize width={1.4} height={1.4}>
          <AlertCircleIcon />
        </IconREMSize>
      </Tooltip>
      {children}
    </ErrorWrapper>
  );
};
