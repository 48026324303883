const TWOPI = Math.PI * 2;

const makeRadianBiggerThan = (a: number, b: number): number =>
  a + TWOPI * Math.ceil((b - a) / TWOPI);
const radianNormalize = (r: number) => r - TWOPI * Math.floor(r / TWOPI);
const makeRadianSmallerThan = (a: number, b: number): number =>
  a + TWOPI * Math.floor((b - a) / TWOPI);

export class Sector {
  /* The angle in radians of the middle of the sector. */
  middle: number;
  /** The angle in radians the sector spans. */
  span: number;

  constructor(middle: number, span: number) {
    this.middle = middle;
    this.span = span;
  }

  /** Construct a new Sector from the two endpoints. */
  static fromFromTo(from: number, to: number): Sector {
    const f = makeRadianSmallerThan(from, to);
    const middle = (to - f) / 2;
    const span = to - f;
    return new Sector(middle, span);
  }

  /** The smallest angle in the sector */
  from(): number {
    return radianNormalize(this.middle - this.span / 2);
  }

  /** The largest angle in the sector */
  to(): number {
    return radianNormalize(this.middle + this.span / 2);
  }

  /**
   * The distance in radians between the two sectors, or between the sector an
   * an angle. If the sectors overlap, the distance is negative.
   */
  distance(other: number | Sector): number {
    if (other instanceof Sector) {
      let diff = Math.abs(other.middle - this.middle);
      if (Math.PI < diff) diff = Math.abs(TWOPI - diff);
      return diff - other.span / 2 - this.span / 2;
    } else {
      let diff = Math.abs(other - this.middle);
      if (Math.PI < diff) diff = Math.abs(TWOPI - diff);
      return diff - this.span / 2;
    }
  }

  /**
   * Check if an angle is contained in the sector.
   */
  contains(r: number): boolean {
    const diff = Math.abs(this.middle - r);
    return diff < this.span / 2;
  }

  /** Construct a new Sector by replacing the lower endpoint. */
  withFromAs(angle: number): Sector {
    const from = makeRadianSmallerThan(angle, this.middle);
    return Sector.fromFromTo(from, this.to());
  }

  /** Construct a new Sector by replacing the upper endpoint. */
  withToAs(angle: number): Sector {
    const to = makeRadianBiggerThan(angle, this.middle);
    return Sector.fromFromTo(this.from(), to);
  }
}
