import { TriggerCurrentFinance } from "components/Finance/Triggers";
import { projectFeaturesSelector } from "components/ProjectElements/state";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import {
  branchIdSelector_,
  organisationIdSelector,
  parkIdSelector_,
  projectIdSelector_,
} from "state/pathParams";
import { partition } from "utils/utils";
import useComponentsInUse from "./useComponentsInUse";
import useCurrentConfigs from "./useCurrentConfigs";
import { useBathymetry } from "hooks/bathymetry";
import useWindDataUrl from "./useWindDataUrl";
import { getCurrentParkSelector } from "state/park";
import { ParkFeature } from "types/feature";
import useProductionNumbers from "./useProductionNumbers";
import { ARCHIVE_DATA_VERSION } from "./utils";

export default function ArchiveProjectVersion() {
  const organisationId = useRecoilValue(organisationIdSelector);
  const projectId = useRecoilValue(projectIdSelector_);
  const branchId = useRecoilValue(branchIdSelector_);
  const parkId = useRecoilValue(parkIdSelector_);
  const park = useRecoilValue(getCurrentParkSelector);

  if (!organisationId || !projectId || !branchId || !parkId || !park) {
    return <div>Invalid path</div>;
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <TriggerCurrentFinance fallback={<div>Loading...</div>}>
        <ArchiveProjectVersionInner
          organisationId={organisationId}
          projectId={projectId}
          branchId={branchId}
          parkId={parkId}
          park={park}
        />
      </TriggerCurrentFinance>
    </Suspense>
  );
}

function ArchiveProjectVersionInner({
  projectId,
  branchId,
  parkId,
  park,
}: {
  organisationId: string;
  projectId: string;
  branchId: string;
  parkId: string;
  park: ParkFeature;
}) {
  const allBranchFeatures = useRecoilValue(projectFeaturesSelector);
  const [selectedParkFeatures, remainingFeatures] = partition(
    allBranchFeatures,
    (f) => !!f.properties.parentIds?.includes(parkId),
  );

  const productionAndWindDataFromCompare = useProductionNumbers(
    projectId,
    branchId,
    parkId,
    park,
  );

  const {
    allCableTypesInUse,
    allTurbineTypesInUse,
    allFoundationTypesInUse,
    allMooringLinesInUse,
    allSubStationsInUse,
    allExportCablesInUse,
  } = useComponentsInUse();

  const { selectedAnalysisConfig, selectedWindConfig, selectedCostConfig } =
    useCurrentConfigs(projectId, branchId);

  // Bathymetry
  const bathymetry = useBathymetry({
    projectId,
    branchId,
    featureId: parkId,
  });

  // Wind data url
  const windDataUrl = useWindDataUrl(projectId);

  // Calcualte progress
  const windDataUrlComplete = windDataUrl !== null;
  const bathymetryComplete = bathymetry.state === "hasValue";
  const compareDataComplete =
    productionAndWindDataFromCompare.status === "complete";

  // count number of steps complete
  const stepsComplete = [
    windDataUrlComplete,
    bathymetryComplete,
    compareDataComplete,
  ].filter((x) => x).length;

  console.log(`Progress: ${stepsComplete} / 3`);
  // Print the key of the steps that is not complete
  [
    windDataUrlComplete ? null : "windDataUrl",
    bathymetryComplete ? null : "bathymetry",
    compareDataComplete ? null : "compareData",
  ]
    .filter((x) => x)
    .forEach((x) => console.log(`Missing: ${x}`));

  if (
    windDataUrl === null ||
    bathymetry.state === "loading" ||
    productionAndWindDataFromCompare.status === "loading"
  ) {
    return <div>Loading...</div>;
  }

  const dataObject = {
    version: ARCHIVE_DATA_VERSION, // when the data structure changes, increment this number. If we ever create parsers for the data the version will be useful to have
    selectedParkFeatures,
    remainingFeatures,
    allCableTypesInUse,
    allTurbineTypesInUse,
    allFoundationTypesInUse,
    allMooringLinesInUse,
    allSubStationsInUse,
    allExportCablesInUse,
    selectedAnalysisConfig,
    selectedWindConfig,
    selectedCostConfig,
    bathymetryUrl: bathymetry.contents?.url ?? null,
    windDataUrl,
    ...productionAndWindDataFromCompare.data,
  };

  return (
    <div>
      <p id="to-read">{JSON.stringify(dataObject)}</p>
    </div>
  );
}
