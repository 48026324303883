/// <reference types="vite-plugin-svgr/client" />
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useLocalStorage } from "../../hooks/useBrowserStorage";
import VindLogo from "@icons/vindlogo/vindLogo.svg?react";
import { StandardBox } from "../../styles/boxes/Boxes";
import { colors } from "../../styles/colors";
import { useRecoilValue } from "recoil";
import { topLevelFolderIdFromOrgIdAndProjectIdSelectorFamily } from "../Projects/useOrganisationFolderCrud";
import { organisationIdSelector, projectIdSelector } from "state/pathParams";

export const LogoWrapper = styled(StandardBox)`
  display: flex;
  align-items: center;
  padding: 1rem 1.7rem;
  position: relative;
  cursor: pointer;
  height: 3rem;
  border-radius: 0.6rem;
  background-color: ${colors.background};
  svg {
    // Note: the .svg is 24px high and 132px wide.
    height: calc(2.4rem / 1.2);
    width: calc(13.2rem / 1.2);
  }
  &:hover {
    path {
      fill: ${colors.primaryHover};
    }
  }
`;

const Logo = () => {
  const organisationId = useRecoilValue(organisationIdSelector);
  const projectId = useRecoilValue(projectIdSelector);

  const toplevelNodeId = useRecoilValue(
    topLevelFolderIdFromOrgIdAndProjectIdSelectorFamily({
      organisationId,
      projectId,
    }),
  );

  const [lastVisitedOrg] = useLocalStorage<string>(
    "vind:last-visited-organisation",
  );
  const { search } = useLocation();
  const link =
    (lastVisitedOrg && toplevelNodeId
      ? `/organisation/${lastVisitedOrg}${
          toplevelNodeId ? `/${toplevelNodeId}` : ""
        }`
      : "/organisation") + search;

  return (
    <Link to={link} style={{ textDecoration: "none" }}>
      <LogoWrapper>
        <VindLogo />
      </LogoWrapper>
    </Link>
  );
};

export default Logo;
