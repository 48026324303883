import { LibraryResourceType } from "components/Organisation/Library/types";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { organisationIdSelector } from "state/pathParams";
import {
  getNodesWithMissingParents,
  nodesInOrganisationSelectorFamily,
} from "./useOrganisationFolderCrud";
import {
  turbineResourceWithAccessOnNodeState,
  foundationResourceWithAccessOnNodeState,
  analysisResourceWithAccessOnNodeState,
  financialResourceWithAccessOnNodeState,
} from "components/Organisation/Library/state";
import { cableResourceWithAccessOnNodeState } from "state/cableType";
import {
  isUserNodeAccessWithMeta,
  isGroupNodeAccessWithMeta,
} from "components/Organisation/Groups/types";
import { isDefined } from "utils/predicates";
import { ACCESS_ROLE_TO_NUMBER } from "state/user";
import { UserAccessRole } from "types/user";
import { getGroupsAndUsersWithAccessToNode } from "components/Organisation/Groups/service";

interface LibraryResource {
  resource_id: string;
  resource_name: string;
  type: LibraryResourceType;
}

export type MoveNodeUserAccess = {
  from: UserAccessRole | "No access";
  to: UserAccessRole | "No access";
  user_id: string;
};

export type MoveNodeGroupAccess = {
  from: UserAccessRole | "No access";
  to: UserAccessRole | "No access";
  group_id: string;
  group_name: string;
};

export type LibraryResourceConsequences = {
  added: LibraryResource[];
  removed: LibraryResource[];
};

export interface MoveNodeConsequences {
  library_resources: LibraryResourceConsequences;
  group_accesses: MoveNodeGroupAccess[];
  user_accesses: MoveNodeUserAccess[];
}

export default function useExplainNodeMove() {
  const organisationId = useRecoilValue(organisationIdSelector);
  const storedNodes = useRecoilValue(
    nodesInOrganisationSelectorFamily({ organisationId }),
  );
  const allTopLevelFolders = useRecoilValue(
    getNodesWithMissingParents({ organisationId: organisationId ?? "" }),
  );

  const explainResourceConsequences = useRecoilCallback(
    ({ snapshot, refresh }) =>
      async (nodeId: string, parentId: string) => {
        if (!organisationId) throw new Error("organisationId is not set");

        const existingNode = storedNodes.find((f) => f.id === nodeId);
        if (!existingNode) throw new Error("id does not exist in stored nodes");

        const existingParentNode =
          storedNodes.find((f) => f.id === parentId) ??
          allTopLevelFolders.find((f) => f.id === parentId);
        if (!existingParentNode)
          throw new Error("id does not exist in stored nodes");

        // make sure we have the latest data
        refresh(turbineResourceWithAccessOnNodeState({ nodeId }));
        refresh(foundationResourceWithAccessOnNodeState({ nodeId }));
        refresh(cableResourceWithAccessOnNodeState({ nodeId }));
        refresh(analysisResourceWithAccessOnNodeState({ nodeId }));
        refresh(financialResourceWithAccessOnNodeState({ nodeId }));

        refresh(turbineResourceWithAccessOnNodeState({ nodeId: parentId }));
        refresh(foundationResourceWithAccessOnNodeState({ nodeId: parentId }));
        refresh(cableResourceWithAccessOnNodeState({ nodeId: parentId }));
        refresh(analysisResourceWithAccessOnNodeState({ nodeId: parentId }));
        refresh(financialResourceWithAccessOnNodeState({ nodeId: parentId }));

        const [
          turbineResources,
          foundationResources,
          cableResources,
          analysisResources,
          financialResources,
        ] = await Promise.all([
          snapshot.getPromise(turbineResourceWithAccessOnNodeState({ nodeId })),
          snapshot.getPromise(
            foundationResourceWithAccessOnNodeState({ nodeId }),
          ),
          snapshot.getPromise(cableResourceWithAccessOnNodeState({ nodeId })),
          snapshot.getPromise(
            analysisResourceWithAccessOnNodeState({ nodeId }),
          ),
          snapshot.getPromise(
            financialResourceWithAccessOnNodeState({ nodeId }),
          ),
        ]);
        const [
          parentTurbineResources,
          parentFoundationResources,
          parentCableResources,
          parentAnalysisResources,
          parentFinancialResources,
        ] = await Promise.all([
          snapshot.getPromise(
            turbineResourceWithAccessOnNodeState({ nodeId: parentId }),
          ),
          snapshot.getPromise(
            foundationResourceWithAccessOnNodeState({ nodeId: parentId }),
          ),
          snapshot.getPromise(
            cableResourceWithAccessOnNodeState({ nodeId: parentId }),
          ),
          snapshot.getPromise(
            analysisResourceWithAccessOnNodeState({ nodeId: parentId }),
          ),
          snapshot.getPromise(
            financialResourceWithAccessOnNodeState({ nodeId: parentId }),
          ),
        ]);

        const removedTurbineResources = turbineResources.filter(
          (r) =>
            r.nodeId !== nodeId &&
            !parentTurbineResources.some(
              (pr) => pr.turbine.id === r.turbine.id,
            ),
        );
        const addedTurbineResources = parentTurbineResources.filter(
          (r) => !turbineResources.some((tr) => tr.turbine.id === r.turbine.id),
        );

        const removedFoundationResources = foundationResources.filter(
          (r) =>
            r.nodeId !== nodeId &&
            !parentFoundationResources.some(
              (pr) => pr.foundation.id === r.foundation.id,
            ),
        );
        const addedFoundationResources = parentFoundationResources.filter(
          (r) =>
            !foundationResources.some(
              (fr) => fr.foundation.id === r.foundation.id,
            ),
        );

        const removedCableResources = cableResources.filter(
          (r) =>
            r.nodeId !== nodeId &&
            !parentCableResources.some((pr) => pr.cable.id === r.cable.id),
        );
        const addedCableResources = parentCableResources.filter(
          (r) => !cableResources.some((cr) => cr.cable.id === r.cable.id),
        );

        const removedAnalysisResources = analysisResources.filter(
          (r) =>
            r.nodeId !== nodeId &&
            !parentAnalysisResources.some((pr) => pr.config.id === r.config.id),
        );
        const addedAnalysisResources = parentAnalysisResources.filter(
          (r) => !analysisResources.some((ar) => ar.config.id === r.config.id),
        );

        const removedFinancialResources = financialResources.filter(
          (r) =>
            r.nodeId !== nodeId &&
            !parentFinancialResources.some(
              (pr) => pr.config.id === r.config.id,
            ),
        );

        const addedFinancialResources = parentFinancialResources.filter(
          (r) => !financialResources.some((fr) => fr.config.id === r.config.id),
        );

        const added: LibraryResource[] = [
          ...addedAnalysisResources.map((r) => ({
            resource_id: r.config.id,
            resource_name: r.config.name ?? "",
            type: "ANALYSIS" as LibraryResourceType,
          })),
          ...addedCableResources.map((r) => ({
            resource_id: r.cable.id,
            resource_name: r.cable.name ?? "",
            type: "CABLE" as LibraryResourceType,
          })),
          ...addedFinancialResources.map((r) => ({
            resource_id: r.config.id,
            resource_name: r.config.name ?? "",
            type: "FINANCIAL" as LibraryResourceType,
          })),
          ...addedFoundationResources.map((r) => ({
            resource_id: r.foundation.id,
            resource_name: r.foundation.name ?? "",
            type: "FOUNDATION" as LibraryResourceType,
          })),
          ...addedTurbineResources.map((r) => ({
            resource_id: r.turbine.id,
            resource_name: r.turbine.name ?? "",
            type: "TURBINE" as LibraryResourceType,
          })),
        ];

        const removed: LibraryResource[] = [
          ...removedAnalysisResources.map((r) => ({
            resource_id: r.config.id,
            resource_name: r.config.name ?? "",
            type: "ANALYSIS" as LibraryResourceType,
          })),
          ...removedCableResources.map((r) => ({
            resource_id: r.cable.id,
            resource_name: r.cable.name ?? "",
            type: "CABLE" as LibraryResourceType,
          })),
          ...removedFinancialResources.map((r) => ({
            resource_id: r.config.id,
            resource_name: r.config.name ?? "",
            type: "FINANCIAL" as LibraryResourceType,
          })),
          ...removedFoundationResources.map((r) => ({
            resource_id: r.foundation.id,
            resource_name: r.foundation.name ?? "",
            type: "FOUNDATION" as LibraryResourceType,
          })),
          ...removedTurbineResources.map((r) => ({
            resource_id: r.turbine.id,
            resource_name: r.turbine.name ?? "",
            type: "TURBINE" as LibraryResourceType,
          })),
        ];

        return {
          added,
          removed,
        };
      },
  );

  const explainUserAccessConsequences = useRecoilCallback(
    () => async (nodeId: string, parentId: string) => {
      if (!organisationId) throw new Error("organisationId is not set");

      const [groupsAndUsersWithAccess, parentGroupsAndUsersWithAccess] =
        await Promise.all([
          getGroupsAndUsersWithAccessToNode(organisationId, nodeId),
          getGroupsAndUsersWithAccessToNode(organisationId, parentId),
        ]);

      const usersWithAccess = groupsAndUsersWithAccess.filter(
        isUserNodeAccessWithMeta,
      );
      const parentUsersWithAccess = parentGroupsAndUsersWithAccess.filter(
        isUserNodeAccessWithMeta,
      );

      const allUniqueUsers = [
        ...new Set(
          [...usersWithAccess, ...parentUsersWithAccess].map((a) => a.user_id),
        ),
      ];

      const accessUpdates: MoveNodeUserAccess[] = allUniqueUsers
        .map((user_id) => {
          const currentAccess = usersWithAccess.find(
            (a) => a.user_id === user_id,
          );
          const currentAccessLevel =
            ACCESS_ROLE_TO_NUMBER[
              currentAccess ? currentAccess.resource_name : "undefined"
            ];
          const currentAccessIsToNodeBeingMoved =
            currentAccess?.node_id === nodeId;

          const parentAccess = parentUsersWithAccess.find(
            (a) => a.user_id === user_id,
          );
          const parentAccessLevel =
            ACCESS_ROLE_TO_NUMBER[
              parentAccess ? parentAccess.resource_name : "undefined"
            ];

          if (currentAccessIsToNodeBeingMoved) {
            // current access will remain
            if (
              !parentAccess ||
              parentAccessLevel < 0 ||
              currentAccessLevel >= parentAccessLevel
            )
              return undefined;

            // new access will override current access
            return {
              from: currentAccess.resource_name,
              to: parentAccess.resource_name,
              user_id,
            };
          } else if (currentAccess) {
            // current access will be removed
            if (!parentAccess || parentAccessLevel < 0)
              return {
                from: currentAccess.resource_name,
                to: "No access" as "No access" | UserAccessRole,
                user_id,
              };

            // access level remains the same
            if (currentAccessLevel == parentAccessLevel) return undefined;

            // Access will be updated
            return {
              from: currentAccess.resource_name,
              to: parentAccess.resource_name,
              user_id,
            };
          } else {
            if (!parentAccess) return undefined;
            return {
              from: "No access" as "No access" | UserAccessRole,
              to: parentAccess.resource_name,
              user_id,
            };
          }
        })
        .filter(isDefined);

      return accessUpdates;
    },
  );
  const explainGroupAccessConsequences = useRecoilCallback(
    () => async (nodeId: string, parentId: string) => {
      if (!organisationId) throw new Error("organisationId is not set");

      const groupsAndUsersWithAccess = await getGroupsAndUsersWithAccessToNode(
        organisationId,
        nodeId,
      );
      const groupsWithAccess = groupsAndUsersWithAccess.filter(
        isGroupNodeAccessWithMeta,
      );

      const parentGroupsAndUsersWithAccess =
        await getGroupsAndUsersWithAccessToNode(organisationId, parentId);
      const parentGroupsWithAccess = parentGroupsAndUsersWithAccess.filter(
        isGroupNodeAccessWithMeta,
      );

      const allUniqueGroups = [
        ...new Set(
          [...groupsWithAccess, ...parentGroupsWithAccess].map(
            (a) => a.group_id,
          ),
        ),
      ];

      const accessUpdates: MoveNodeGroupAccess[] = allUniqueGroups
        .map((group_id) => {
          const currentAccess = groupsWithAccess.find(
            (a) => a.group_id === group_id,
          );
          const currentAccessLevel =
            ACCESS_ROLE_TO_NUMBER[
              currentAccess ? currentAccess.resource_name : "undefined"
            ];
          const currentAccessIsToNodeBeingMoved =
            currentAccess?.node_id === nodeId;

          const parentAccess = parentGroupsWithAccess.find(
            (a) => a.group_id === group_id,
          );
          const parentAccessLevel =
            ACCESS_ROLE_TO_NUMBER[
              parentAccess ? parentAccess.resource_name : "undefined"
            ];

          if (currentAccessIsToNodeBeingMoved) {
            // current access will remain
            if (
              !parentAccess ||
              parentAccessLevel < 0 ||
              currentAccessLevel >= parentAccessLevel
            )
              return undefined;

            // new access will override current access
            return {
              from: currentAccess.resource_name,
              to: parentAccess.resource_name,
              group_id,
              group_name: currentAccess.group_name,
            };
          } else if (currentAccess) {
            // current access will be removed
            if (!parentAccess || parentAccessLevel < 0)
              return {
                from: currentAccess.resource_name,
                to: "No access" as "No access" | UserAccessRole,
                group_id,
                group_name: currentAccess.group_name,
              };

            // access level remains the same
            if (currentAccessLevel == parentAccessLevel) return undefined;

            // Access will be updated
            return {
              from: currentAccess.resource_name,
              to: parentAccess.resource_name,
              group_id,
              group_name: currentAccess.group_name,
            };
          } else {
            if (!parentAccess) return undefined;
            return {
              from: "No access" as "No access" | UserAccessRole,
              to: parentAccess.resource_name,
              group_id,
              group_name: parentAccess.group_name,
            };
          }
        })
        .filter(isDefined);

      return accessUpdates;
    },
  );

  const explain = useRecoilCallback(
    () => async (nodeId: string, parentId: string) => {
      const [libraryResourceConsequences, groupAccess, userAccess] =
        await Promise.all([
          explainResourceConsequences(nodeId, parentId),
          explainGroupAccessConsequences(nodeId, parentId),
          explainUserAccessConsequences(nodeId, parentId),
        ]);

      return {
        library_resources: libraryResourceConsequences,
        group_accesses: groupAccess,
        user_accesses: userAccess,
      };
    },
  );

  return { explain };
}
