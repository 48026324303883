/// <reference types="vite-plugin-svgr/client" />
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  turbineEllipsisMajorAxisPropertyName,
  turbineEllipsisMinorAxisPropertyName,
  turbineEllipsisOrientationPropertyName,
  turbineEllipsesShowPropertyName,
} from "../../constants/canvas";
import { EllipsesSelectorWrapper, EllipsisContainer, InputBox } from "./style";
import { useClickOutside } from "../../hooks/useClickOutside";
import Ellipses from "@icons/24/Ellipses.svg";
import { ProjectFeature } from "../../types/feature";
import Toggle, { ToggleSize } from "../General/Toggle";
import { IconBtn } from "components/General/Icons";
import { InputTitle } from "components/General/GeneralSideModals.style";
import { RangeWithDimInput } from "components/General/RangeWithDimInput";
import { Angle, TurbineDistance } from "components/Units/units";
import { HeaderTitle } from "components/RightSide/InfoModal/style";
import { Column, Row } from "components/General/Layout";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  getMostSelectedOrDefaultTurbineType,
  getSelectedTurbineTypes,
} from "state/turbines";
import { ELLIPSIS_KEY, EllipsesFeature, ellipsesPerTurbineAtom } from "./state";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";

export const TurbineEllipsesBox = ({
  selectedProjectFeatures,
  setOpen,
  direction = "bottom",
}: {
  selectedProjectFeatures: ProjectFeature[];
  setOpen?: Dispatch<SetStateAction<boolean>>;
  direction?: "bottom" | "right";
}) => {
  const ellipsesContainerRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(ellipsesContainerRef, () => setOpen && setOpen(false));

  const selectedProjectFeaturesIds = useMemo(
    () =>
      selectedProjectFeatures.map(
        (selectedProjectFeature) => selectedProjectFeature.id,
      ),
    [selectedProjectFeatures],
  );

  const ellipsesFromLocalStorage: any = useMemo(() => {
    return JSON.parse(localStorage.getItem(ELLIPSIS_KEY)) ?? {};
  }, []);

  const selectedEllipsesFromLocalStorage = useMemo(() => {
    return selectedProjectFeaturesIds.length > 0
      ? selectedProjectFeaturesIds
          .filter((id) => id in ellipsesFromLocalStorage)
          .map((id) => ellipsesFromLocalStorage[id])
      : [];
  }, [selectedProjectFeaturesIds, ellipsesFromLocalStorage]);

  const areAllEllipsesSame =
    selectedEllipsesFromLocalStorage.length > 0 &&
    selectedEllipsesFromLocalStorage.every(
      (ellipses) =>
        JSON.stringify(ellipses) ===
        JSON.stringify(selectedEllipsesFromLocalStorage[0]),
    );

  const defaultEllipses: EllipsesFeature = useMemo(() => {
    return areAllEllipsesSame
      ? selectedEllipsesFromLocalStorage[0]
      : {
          majorAxis: { value: 1000, unit: "m" },
          minorAxis: { value: 1000, unit: "m" },
          orientation: { value: 0, unit: "deg" },
          show: true,
        };
  }, [areAllEllipsesSame, selectedEllipsesFromLocalStorage]);

  const [parameters, setParameters] = useState(defaultEllipses);
  const [, setEllipsesPerTurbine] = useRecoilState(ellipsesPerTurbineAtom);

  useEffect(() => {
    //Setting the ellipses for new selected turbines
    const ellipsesMapping = selectedProjectFeatures.reduce<{
      [key: string]: EllipsesFeature;
    }>((map, feature) => {
      if (
        !ellipsesFromLocalStorage ||
        !areAllEllipsesSame ||
        !ellipsesFromLocalStorage[feature.id]
      ) {
        map[feature.id] = defaultEllipses;
      }
      return map;
    }, ellipsesFromLocalStorage);

    setEllipsesPerTurbine(ellipsesMapping);
  }, [
    selectedProjectFeatures,
    areAllEllipsesSame,
    defaultEllipses,
    ellipsesFromLocalStorage,
    setEllipsesPerTurbine,
  ]);

  const updateEllipses = (
    selectedProjectFeaturesIds: string[],
    propertyName: string,
    value: any,
  ) => {
    const ellipsesFromLocalStorage: Record<string, EllipsesFeature> =
      JSON.parse(localStorage.getItem(ELLIPSIS_KEY)) as Record<
        string,
        EllipsesFeature
      >;

    selectedProjectFeaturesIds.forEach((id) => {
      ellipsesFromLocalStorage[id] = {
        ...(ellipsesFromLocalStorage[id] || {}),
        [propertyName]: value,
      };
    });
    setEllipsesPerTurbine(ellipsesFromLocalStorage);
  };

  const selectedTurbineTypes = useRecoilValue(getSelectedTurbineTypes);
  const showInfoAlert =
    selectedTurbineTypes.length > 1 &&
    (parameters.majorAxis.unit === "D" || parameters.minorAxis.unit === "D");

  const mostUsedTurbineType = useRecoilValue(
    getMostSelectedOrDefaultTurbineType,
  );

  const turbineDistanceConvert = useMemo(
    () => TurbineDistance.makeConvert(mostUsedTurbineType.diameter),
    [mostUsedTurbineType],
  );

  return (
    <EllipsisContainer
      ref={ellipsesContainerRef}
      position={direction === "bottom" ? "left" : direction}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Column>
        <HeaderTitle>Turbine ellipses</HeaderTitle>
        {showInfoAlert && (
          <SimpleAlert
            text={`You are visualizing ellipsis for several turbine types. In this case, we use the rotor diameter (${mostUsedTurbineType.diameter} m) for the most common turbine type.`}
            type="info"
          />
        )}
        <Row style={{ padding: "1.2rem 0" }}>
          <Toggle
            size={ToggleSize.SMALL}
            checked={parameters.show}
            onChange={() => {
              setParameters((prevParameters) => ({
                ...prevParameters,
                show: !prevParameters.show,
              }));
              updateEllipses(
                selectedProjectFeaturesIds,
                turbineEllipsesShowPropertyName,
                !parameters.show,
              );
            }}
          />
          <InputTitle>Show ellipses</InputTitle>
        </Row>

        <InputBox>
          <InputTitle>Major axis</InputTitle>
          <RangeWithDimInput
            min={parameters.majorAxis.unit === "m" ? 200 : 1}
            max={parameters.majorAxis.unit === "m" ? 10000 : 50}
            rangeStep={parameters.majorAxis.unit === "m" ? 50 : 0.01}
            value={parameters.majorAxis.value}
            unit={parameters.majorAxis.unit as TurbineDistance.Unit}
            defaultValue={100}
            units={TurbineDistance.units}
            onChange={(n) => {
              setParameters((prevParameters) => ({
                ...prevParameters,
                majorAxis: {
                  ...prevParameters.majorAxis,
                  value: n,
                },
              }));

              updateEllipses(
                selectedProjectFeaturesIds,
                turbineEllipsisMajorAxisPropertyName,
                {
                  value: n,
                  unit: parameters.majorAxis.unit,
                },
              );
            }}
            onUnitChange={(u) => {
              setParameters((prevParameters) => ({
                ...prevParameters,
                majorAxis: {
                  value: turbineDistanceConvert(
                    turbineDistanceConvert(parameters.majorAxis.value, {
                      from: parameters.majorAxis.unit as TurbineDistance.Unit,
                    }),
                    { to: u },
                  ),
                  unit: u,
                },
              }));
              updateEllipses(
                selectedProjectFeaturesIds,
                turbineEllipsisMajorAxisPropertyName,
                {
                  value: turbineDistanceConvert(
                    turbineDistanceConvert(parameters.majorAxis.value, {
                      from: parameters.majorAxis.unit,
                    }),
                    { to: u },
                  ),
                  unit: u,
                },
              );
            }}
            format={TurbineDistance.format}
          />
        </InputBox>
        <InputBox>
          <InputTitle>Minor axis</InputTitle>
          <RangeWithDimInput
            min={parameters.minorAxis.unit === "m" ? 200 : 1}
            max={parameters.minorAxis.unit === "m" ? 10000 : 50}
            rangeStep={parameters.minorAxis.unit === "m" ? 50 : 0.01}
            value={parameters.minorAxis.value}
            unit={parameters.minorAxis.unit as TurbineDistance.Unit}
            defaultValue={100}
            units={TurbineDistance.units}
            onChange={(n) => {
              setParameters((prevParameters) => ({
                ...prevParameters,
                minorAxis: {
                  ...prevParameters.minorAxis,
                  value: n,
                },
              }));
              updateEllipses(
                selectedProjectFeaturesIds,
                turbineEllipsisMinorAxisPropertyName,
                {
                  value: n,
                  unit: parameters.minorAxis.unit,
                },
              );
            }}
            onUnitChange={(u) => {
              setParameters((prevParameters) => ({
                ...prevParameters,
                minorAxis: {
                  value: turbineDistanceConvert(
                    turbineDistanceConvert(parameters.minorAxis.value, {
                      from: parameters.minorAxis.unit as TurbineDistance.Unit,
                    }),
                    { to: u },
                  ),
                  unit: u,
                },
              }));
              updateEllipses(
                selectedProjectFeaturesIds,
                turbineEllipsisMinorAxisPropertyName,
                {
                  value: turbineDistanceConvert(
                    turbineDistanceConvert(parameters.minorAxis.value, {
                      from: parameters.minorAxis.unit,
                    }),
                    { to: u },
                  ),
                  unit: u,
                },
              );
            }}
            format={TurbineDistance.format}
          />
        </InputBox>
        <InputBox>
          <InputTitle>Orientation</InputTitle>
          <RangeWithDimInput
            min={
              Angle.range(parameters.orientation.unit)[0] +
              Angle.convert(180, {
                to: parameters.orientation.unit,
              })
            }
            max={
              Angle.range(parameters.orientation.unit)[1] +
              Angle.convert(180, {
                to: parameters.orientation.unit,
              })
            }
            value={parameters.orientation.value}
            unit={parameters.orientation.unit as Angle.Unit}
            defaultValue={0}
            units={Angle.units}
            onChange={(n) => {
              setParameters((prevParameters) => ({
                ...prevParameters,
                orientation: {
                  ...prevParameters.orientation,
                  value: n,
                },
              }));
              updateEllipses(
                selectedProjectFeaturesIds,
                turbineEllipsisOrientationPropertyName,
                {
                  value: n,
                  unit: parameters.orientation.unit,
                },
              );
            }}
            onUnitChange={(u) => {
              setParameters((prevParameters) => ({
                ...prevParameters,
                orientation: {
                  value: Angle.convert(
                    Angle.convert(parameters.orientation.value, {
                      from: parameters.orientation.unit as Angle.Unit,
                    }),
                    { to: u },
                  ),
                  unit: u,
                },
              }));

              updateEllipses(
                selectedProjectFeaturesIds,
                turbineEllipsisOrientationPropertyName,
                {
                  value: Angle.convert(
                    Angle.convert(parameters.orientation.value, {
                      from: parameters.orientation.unit,
                    }),
                    { to: u },
                  ),
                  unit: u,
                },
              );
            }}
            format={Angle.format}
          />
        </InputBox>
      </Column>
    </EllipsisContainer>
  );
};

const TurbineEllipsesSelector = ({
  selectedProjectFeatures,
}: {
  selectedProjectFeatures: ProjectFeature[];
}) => {
  const [open, setOpen] = useState(false);

  return (
    <EllipsesSelectorWrapper>
      <IconBtn
        active={open}
        $fill={true}
        onClick={(e) => {
          setOpen((c) => !c);
          e.stopPropagation();
        }}
      >
        <Ellipses />
      </IconBtn>

      {open && (
        <TurbineEllipsesBox
          direction={"bottom"}
          setOpen={setOpen}
          selectedProjectFeatures={selectedProjectFeatures}
        />
      )}
    </EllipsesSelectorWrapper>
  );
};

export default TurbineEllipsesSelector;
