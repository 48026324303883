import { useRecoilValueLoadable2 } from "utils/recoil";
import { CAPEX_STATE, FinanceId } from "./state";
import { CostType } from "services/costService";
import { roundToDecimal, sum } from "utils/utils";
import { MILLION } from "@constants/financialAnalysis";
import { selectorFamily } from "recoil";
import { GeneralCost } from "components/FinancialAnalysis/Capex/capexNumbers";
import { ConfidenceLevel } from "types/financial";
import { colors } from "styles/colors";

export const useCapexMillionPV = (id: FinanceId) => {
  const total = useRecoilValueLoadable2(getTotalInMillion(id));
  const costs = useRecoilValueLoadable2(getCostsInMillion(id));

  const useCosts = (type: CostType) => {
    const costs = useRecoilValueLoadable2(
      getCostsOfTypeInMillion({ id, type }),
    );
    const sum = useRecoilValueLoadable2(getSumInMillion({ id, type }));

    return {
      costs,
      sum,
    };
  };

  return {
    total,
    costs,
    useCosts,
  };
};

const getCostsInMillion = selectorFamily<GeneralCost[], FinanceId>({
  key: "capex-all-costs-pv",
  get:
    (id: FinanceId) =>
    ({ get }) => {
      const costs = get(CAPEX_STATE.getAllCosts(id));

      return costs
        .filter(({ amount }) => amount > 0)
        .map((c) => ({
          ...c,
          amount: roundToDecimal(c.amount, 2),
          costPerAmount: roundToDecimal(c.costPerAmount, 2),
          cost: Math.round(c.cost / MILLION),
        }));
    },
});

const getCostsOfTypeInMillion = selectorFamily<
  GeneralCost[],
  { id: FinanceId; type: CostType }
>({
  key: "capex-categiry-costs-pv",
  get:
    ({ id, type }: { id: FinanceId; type: CostType }) =>
    ({ get }) => {
      const costs = get(CAPEX_STATE.getCostsOfType({ id, type }));

      return costs
        .filter(({ amount }) => amount > 0)
        .map((c) => ({
          ...c,
          amount: roundToDecimal(c.amount, 2),
          costPerAmount: roundToDecimal(c.costPerAmount, 2),
          cost: Math.round(c.cost / MILLION),
        }));
    },
});

const getSumInMillion = selectorFamily<
  number,
  { id: FinanceId; type: CostType }
>({
  key: "capex-costs-sum-pv",
  get:
    ({ id, type }: { id: FinanceId; type: CostType }) =>
    ({ get }) => {
      const costs = get(CAPEX_STATE.getCostsOfType({ id, type }));

      const total = sum(costs.map(({ cost }) => cost));

      return Math.round(total / MILLION);
    },
});

const getTotalInMillion = selectorFamily<number, FinanceId>({
  key: "capex-total-cost-pv",
  get:
    (id: FinanceId) =>
    ({ get }) => {
      const totalPV = get(CAPEX_STATE.getTotalCostPV(id));

      return roundToDecimal(totalPV / MILLION, 0);
    },
});

export const getColorFromConfidenceLevel = (
  confidenceLevel: ConfidenceLevel | undefined,
) => {
  switch (confidenceLevel) {
    case ConfidenceLevel.verified:
      return colors.seagreen600;
    case ConfidenceLevel.uncertain:
      return colors.orange400;
    case ConfidenceLevel.notSpecified:
      return colors.grey500;
    default: //from components
      return colors.indigo400;
  }
};
