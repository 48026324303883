import { MoveNodeUserAccess } from "components/Projects/useExplainNodeMove";
import AlertCircle from "@icons/24/AlertCircle.svg?react";
import KeyboardArrowRight from "@icons/24/KeyboardArrowRight.svg?react";
import { Row } from "components/General/Layout";
import { Suspense } from "react";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { SVGWrapper } from "@icons/svgUtils";
import { ValidationAlert } from "components/ValidationWarnings/ValidationAlert";
import { VerticalDivider } from "components/General/VerticalDivider";
import UserInfo from "components/Comments/MapModal/UserInfo";
import { ValidationDescription, AccessText } from "../style";

export function UsersWarning({
  userAccess,
}: {
  userAccess: MoveNodeUserAccess[];
}) {
  return (
    <ValidationAlert
      title={`${userAccess.length} user${userAccess.length > 1 ? "s" : ""}`}
      renderDecription={() => (
        <ValidationDescription>
          {userAccess.map((ua) => {
            return (
              <Row
                key={ua.user_id}
                style={{ gap: "0.8rem", alignItems: "center" }}
              >
                <Suspense
                  fallback={
                    <SkeletonBlock
                      style={{ height: "1.6rem", width: "4rem" }}
                    />
                  }
                >
                  <UserInfo userId={ua.user_id} size={2.2} />
                </Suspense>

                <VerticalDivider height={1.4} />
                <Row style={{ gap: "0.4rem", alignItems: "center" }}>
                  <AccessText>{ua.from}</AccessText>
                  <SVGWrapper size={1.2}>
                    <KeyboardArrowRight />
                  </SVGWrapper>
                  <AccessText>{ua.to}</AccessText>
                  {ua.to === "No access" && <AlertCircle />}
                </Row>
              </Row>
            );
          })}
        </ValidationDescription>
      )}
      type="info"
    />
  );
}
