import { useEffect } from "react";
import { selectorFamily, useRecoilValue } from "recoil";
import { getParkFeatureSelectorFamily } from "../../state/park";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { WarningProps } from "./ValidationWarnings";
import { ParkFeature } from "../../types/feature";
import { branchIdSelector } from "../../state/pathParams";
import { getDisconnectedExportCablesInParkAndBranch } from "components/ProductionV2/state";

export const exportCableNotConnectedWarningSelectorFamily = selectorFamily<
  | {
      type: ValidationWarningTypes.ExportCableNotConnected;
      featureIds: string[];
      parkId: string;
    }
  | undefined,
  { parkId: string; branchId: string }
>({
  key: "exportCableNotConnectedWarningSelectorFamily",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const invalidExportCables = get(
        getDisconnectedExportCablesInParkAndBranch({
          parkId: parkId,
          branchId: branchId,
        }),
      );

      return invalidExportCables.length === 0
        ? undefined
        : {
            type: ValidationWarningTypes.ExportCableNotConnected,
            featureIds: invalidExportCables.map((c) => c.id),
            parkId: parkId,
          };
    },
});

const ExportCableNotConnectedInner = ({
  park,
  branchId,
  warn,
  remove,
}: {
  park: ParkFeature;
  branchId: string;
} & WarningProps) => {
  const parkId = park.id;

  const invalidExportCablesWarning = useRecoilValue(
    exportCableNotConnectedWarningSelectorFamily({ parkId, branchId }),
  );

  // Update validation warning
  useEffect(() => {
    if (!invalidExportCablesWarning) return;
    warn(invalidExportCablesWarning);
    return () => {
      remove(ValidationWarningTypes.ExportCableNotConnected);
    };
  }, [park, remove, invalidExportCablesWarning, warn]);

  return null;
};

const ExportCableNotConnected = ({
  parkId,
  ...props
}: { parkId: string } & WarningProps) => {
  const park = useRecoilValue(getParkFeatureSelectorFamily({ parkId }));
  const branchId = useRecoilValue(branchIdSelector);

  if (!park || !branchId) return null;
  return (
    <ExportCableNotConnectedInner park={park} branchId={branchId} {...props} />
  );
};

export default ExportCableNotConnected;
