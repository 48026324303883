import { getPark } from "components/ProductionV2/state";
import { useRecoilValue } from "recoil";
import { FinanceId } from "components/Finance/state";
import { DownloadCSV } from "components/CsvDownload/CsvDownloadButton";
import { useIRR } from "components/Finance/irr";

export const DownloadIRRData = ({
  id,
  buttonType,
}: {
  id: FinanceId;
  buttonType: "text" | "menu";
}) => {
  const {
    results: { exportData },
  } = useIRR(id);
  const park = useRecoilValue(getPark(id));

  const columns = [
    { header: "Type", field: "type" },
    { header: "Value", field: "value" },
    { header: "Unit", field: "unit" },
  ];

  const getData = () => exportData.toPromise();

  // TODO: this should be on-demand too
  const filename = `irr-${park.properties.name}-${new Date().toISOString()}.csv`;

  return (
    <DownloadCSV
      data={getData}
      columns={columns}
      filename={filename}
      buttonType={buttonType}
    />
  );
};
