import { useEffect, useMemo, useRef, useState } from "react";
import {
  WindData,
  WindDataMultipleHeights,
  isWindDataMultipleHeights,
} from "state/windStatistics";
import Plotly from "plotly.js-dist-min";
import styled from "styled-components";
import { fastMax, fastMin } from "utils/utils";
import Dropdown from "components/Dropdown/Dropdown";
import useTextInput from "hooks/useTextInput";
import { Row } from "components/General/Layout";
import { Label } from "components/General/Form";
import { InputNumber } from "components/General/Input";
import { colors } from "styles/colors";
import { borderRadiusMedium, spaceDecent, spaceMedium } from "styles/space";
import { UploadWindDataFileSizeTooLargeError } from "state/uploadWindData";
import { useToast } from "hooks/useToast";

const Wrapper = styled.div`
  background-color: ${colors.surfaceSecondary};
  padding: ${spaceDecent};
  gap: ${spaceMedium};
  border-radius: ${borderRadiusMedium};
  display: flex;
  flex-direction: column;
`;

const TimeSeriesPlot = styled.div`
  width: 100%;
  height: 100%;
`;

export const PreviewTimeSeries = ({
  data,
}: {
  data: Promise<WindData | WindDataMultipleHeights>;
}) => {
  const [timeseries, setTimeseries] = useState<
    WindData | WindDataMultipleHeights
  >();

  const { error: errorMessage } = useToast();

  useEffect(() => {
    data
      .then((res) => res && setTimeseries(res))
      .catch((error) => {
        if (error instanceof UploadWindDataFileSizeTooLargeError) {
          errorMessage(error.message);
          return;
        }
        throw error;
      });
  }, [data, errorMessage]);

  if (!timeseries) return null;

  return (
    <Wrapper>
      <PreviewTimeSeriesInner windData={timeseries} />
    </Wrapper>
  );
};

export const PreviewTimeSeriesInner = ({
  windData,
}: {
  windData: WindData | WindDataMultipleHeights;
}) => {
  const [variable, setVariable] = useTextInput<"speed" | "direction">("speed");
  const [year, setYear] = useState<number>(
    new Date(windData.timestamps[0]).getFullYear(),
  );

  const { minYear, maxYear } = useMemo(() => {
    const years = Array.from(
      new Set(
        windData.timestamps.map((timestamp) =>
          new Date(timestamp).getFullYear(),
        ),
      ),
    );
    const minYear = fastMin(years);
    const maxYear = fastMax(years);

    return { minYear, maxYear };
  }, [windData.timestamps]);

  const plotRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const current = plotRef.current;
    if (!current) return;
    const observer = new ResizeObserver(() => {
      if (!current.clientWidth) return;
      Plotly.Plots.resize(current);
    });
    if (current) observer.observe(current);
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (!plotRef.current) return;

    const x = windData.timestamps;

    let data: Plotly.Data[];

    if (isWindDataMultipleHeights(windData)) {
      data = windData.heights.map((height) => {
        return {
          x,
          y: windData[variable][height],
          type: "scatter",
          mode: "lines",
          line: { width: 0.5 },
          name: `${height} m`,
        };
      });
    } else {
      data = [
        {
          x,
          y: windData[variable],
          type: "scatter",
          mode: "lines",
          name: `${windData.height} m`,
          line: { width: 0.5 },
        },
      ];
    }

    const xRange = [`${year}`, `${year + 1}`];

    Plotly.newPlot(
      plotRef.current,
      data,
      {
        height: 300,
        autosize: true,
        xaxis: {
          title: "Time",
          range: xRange,
        },
        yaxis: {
          title:
            variable === "speed" ? "Wind speed (m/s)" : "Wind direction (°)",
          autorange: true,
        },
        showlegend: false,
        margin: {
          l: 50,
          r: 50,
          b: 50,
          t: 0,
          pad: 0,
        },
      },
      { displayModeBar: false, responsive: true },
    );
  }, [windData, variable, year]);

  return (
    <>
      <Row>
        <Label>
          <p>
            Year ({minYear} - {maxYear})
          </p>
          <InputNumber
            value={year}
            onChange={setYear}
            validate={(value) => value >= minYear && value <= maxYear}
            validationMessage={`The year must be between ${minYear} and ${maxYear}.`}
          />
        </Label>
        <Label>
          <p>Variable</p>
          <Dropdown value={variable} onChange={setVariable}>
            <option value="speed">Wind speed</option>
            <option value="direction">Wind direction</option>
          </Dropdown>
        </Label>
      </Row>
      <TimeSeriesPlot ref={plotRef} />
    </>
  );
};
