import { atom, atomFamily, selectorFamily, waitForAll } from "recoil";
import {
  OptProblem,
  ListOptProblems,
  ListResultsForProblem,
  getOptProblem,
  listOptProblems,
  listResultsForProblem,
} from "../../functions/optimize";
import { min } from "../../utils/utils";
import { scream } from "../../utils/sentry";
import { isDefined } from "../../utils/predicates";
import { GenerationMethodAndParametersWithUnit } from "../../types/turbines";
import { defaultRegularParamsWithUnits } from "../../state/turbines";
import { getParkFeatureSelectorFamily } from "../../state/park";
import { getSubAreaSelectorFamily } from "../../state/division";
import { getTurbinesSelectorFamily } from "../../state/layout";
import inferTurbineParameters, { inferResultToObj } from "./infer";
import { pointInPolygon } from "../../utils/geometry";
import { sendWarning } from "../../utils/sentry";
import { MooringParameters } from "components/GenerateFoundationsAndAnchors/types";
import { Raster } from "types/raster";

export const turbinesAndFoundationsGenerationIsLiveAtom = atom<boolean>({
  key: "turbinesAndFoundationsGenerationIsLiveAtom",
  default: false,
});

export const methodAndParametersForRegionAtomFamily = atomFamily<
  GenerationMethodAndParametersWithUnit | undefined,
  string | undefined
>({
  key: "methodAndParametersForRegionAtomFamily",
  default: selectorFamily({
    key: "methodAndParametersForRegionAtomFamily.default",
    get:
      (regionId?) =>
      ({ get }) => {
        if (!regionId) {
          return;
        }
        let park = get(getParkFeatureSelectorFamily({ parkId: regionId }));
        if (park) {
          const turbines = get(getTurbinesSelectorFamily({ parkId: park.id }));
          const params = inferTurbineParameters(turbines, park);

          if (params.ok) return inferResultToObj(params.value);
          return {
            method: "regular",
            params: defaultRegularParamsWithUnits(),
          };
        } else {
          const subArea = get(
            getSubAreaSelectorFamily({ divisionId: regionId }),
          );
          if (!subArea)
            throw sendWarning(
              "Illegal state: no park or sub area for regionId",
              { regionId },
            );
          const parkId = subArea.properties.parentIds?.[0];
          if (!isDefined(parkId))
            throw scream("Illegal state: sub area lacks parkId", {
              subArea,
              parkId,
            });
          park = get(getParkFeatureSelectorFamily({ parkId }));
          if (!park)
            throw scream("Illegal state: no park for parkId", { parkId });
          const turbines = get(
            getTurbinesSelectorFamily({ parkId: park.id }),
          ).filter((t) => pointInPolygon(t.geometry, subArea.geometry));
          const params = inferTurbineParameters(turbines, subArea);

          if (params.ok) return inferResultToObj(params.value);
          return {
            method: "regular",
            params: defaultRegularParamsWithUnits(),
          };
        }
      },
  }),
});

/** IDs used to query for optimization problems. */
export type Ids = {
  nodeId: string;
  branchId: string;
  zoneId: string;
};

/** IDs that identify a single optimization problem. */
export type Pids = Ids & { id: string };

export const listOptProblemsAtomFamily = atomFamily<ListOptProblems, Ids>({
  key: "listOptProblemsAtomFamily",
  default: selectorFamily({
    key: "listOptProblemsAtomFamily.default",
    get: (ids) => () => listOptProblems(ids),
  }),
});

export const recentlyDeletedProblemAtom = atom<string[]>({
  key: "recentlyDeletedProblemAtom",
  default: [],
});

export const optProblemSelectorFamily = selectorFamily<
  undefined | OptProblem,
  Pids
>({
  key: "optProblemSelectorFamily",
  get: (pids) => () =>
    getOptProblem(pids).catch((e) => {
      sendWarning("getOptProblem endpoint failed", { pids, e });
      return undefined;
    }),
});

export const optProblemsSelectorFamily = selectorFamily<OptProblem[], Ids>({
  key: "optProblemsSelectorFamily",
  get:
    (ids) =>
    ({ get }) => {
      const problemIds = get(listOptProblemsAtomFamily(ids)).map((e) => e.id);
      const allProblems = get(
        waitForAll(
          problemIds.map((id) => optProblemSelectorFamily({ ...ids, id })),
        ),
      );
      return allProblems.filter(isDefined);
    },
});

export const problemResultsAtomFamily = atomFamily<
  undefined | ListResultsForProblem,
  Pids
>({
  key: "problemResultsAtomFamily",
  default: selectorFamily({
    key: "problemResultsAtomFamily.default",
    get: (pids) => () =>
      listResultsForProblem(pids).catch((e) => {
        sendWarning("listResultsForProblem endpoint failed", { pids, e });
        return undefined;
      }),
  }),
});

export const lastOptProblemSelectorFamily = selectorFamily<
  OptProblem | undefined,
  {
    ids: Ids;
    settings: {
      numberOfTurbines: number;
      includeEdge: boolean;
    };
  }
>({
  key: "lastOptProblemSelectorFamily",
  get:
    ({ settings: { numberOfTurbines, includeEdge }, ids }) =>
    ({ get }) => {
      const problems = get(optProblemsSelectorFamily(ids));
      const matches = problems.filter(
        (p) => p.numberOfTurbines === numberOfTurbines,
      );
      const withEdge = matches.filter(
        (p) => (p.includeEdge ?? false) === includeEdge,
      );
      return (
        min(withEdge, (p) => -p.createdAt) ?? min(matches, (p) => -p.createdAt)
      );
    },
});

// Palms are sweaty
// Knees weak, arms are heavy
export const combinedGenMooringParameters = atom<
  (MooringParameters & { raster: Raster }) | undefined
>({
  key: "combinedGenMooringParameters",
  default: undefined,
});
