import mapboxgl, { Point } from "mapbox-gl";
import { useEffect } from "react";

const MIN_BBOX_DIFF = 3;

//If the bbox is tiny it was probably not a shift drag
const bboxTooSmall = (bbox: { x: number; y: number }[]) =>
  Math.abs(bbox[1].x - bbox[0].x) < MIN_BBOX_DIFF &&
  Math.abs(bbox[1].y - bbox[0].y) < MIN_BBOX_DIFF;

const removeMapboxDrawBoxSelect = () => {
  const glDrawBox = document.querySelector(".mapbox-gl-draw_boxselect");
  if (glDrawBox) {
    glDrawBox.parentNode?.removeChild(glDrawBox);
  }
};

export const useShiftDragMultiSelect = (
  map: mapboxgl.Map | undefined,
  callback: (
    status: "drag" | "complete",
    bbox: [mapboxgl.Point, mapboxgl.Point],
  ) => void,
) => {
  useEffect(() => {
    if (!map) return;

    map.boxZoom.disable();
    const canvas = map.getCanvasContainer();

    let start: Point;
    let current: Point;

    // Set `true` to dispatch the event before other functions
    // call it. This is necessary for disabling the default map
    // dragging behaviour.
    canvas.addEventListener("mousedown", mouseDown, true);

    // Return the xy coordinates of the mouse position
    function mousePos(e: MouseEvent) {
      const rect = canvas.getBoundingClientRect();
      return new mapboxgl.Point(
        e.clientX - rect.left - canvas.clientLeft,
        e.clientY - rect.top - canvas.clientTop,
      );
    }

    function mouseDown(e: MouseEvent) {
      // Continue the rest of the function if the shiftkey is pressed.
      if (!(e.shiftKey && e.button === 0)) return;

      // Disable default drag zooming when the shift key is held down.
      map!.dragPan.disable();

      // Call functions for the following events
      document.addEventListener("mouseup", onMouseUp);
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("keydown", onKeyDown);

      // Capture the first xy coordinates
      start = mousePos(e);
    }

    function onMouseMove(e: MouseEvent) {
      // Capture the ongoing xy coordinates
      current = mousePos(e);
      const bbox = [start, current] as [mapboxgl.Point, mapboxgl.Point];

      if (bbox && !bboxTooSmall(bbox)) {
        callback("drag", bbox);
      }
    }

    function onMouseUp(e: MouseEvent) {
      // Capture xy coordinates
      finish([start, mousePos(e)]);
    }

    function onKeyDown(e: KeyboardEvent) {
      // If the ESC key is pressed
      if (e.keyCode === 27) finish();
    }

    function finish(bbox?: [mapboxgl.Point, mapboxgl.Point]) {
      removeMapboxDrawBoxSelect();
      // Remove these events now that finish has been called.
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("mouseup", onMouseUp);

      // If bbox exists. use this value as the argument for `queryRenderedFeatures`
      if (bbox && !bboxTooSmall(bbox)) {
        callback("complete", bbox);
      }

      map!.dragPan.enable();
    }

    return () => {
      canvas.removeEventListener("mousedown", mouseDown, true);
      document.removeEventListener("mouseup", onMouseUp);
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [map, callback]);
};
