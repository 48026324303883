import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import { toastMessagesAtom } from "../../state/toast";
import { LOCAL_STORAGE_TOKEN_KEY } from "../../state/user";
import Plugin from "./plugin.png";
import InstallFromZip from "./installfromzip.png";
import ManagePlugins from "./manageplugins.png";
import StartPlugin from "./startplugin.png";

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  padding: 0 3rem;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow-y: auto;
  align-items: center;
  padding-bottom: 6rem;
  position: absolute;
  z-index: 1;
  background-color: white;
`;

const Text = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  margin: 1rem 0;
  gap: 0.5rem;
`;

const Fat = styled.div`
  font-weight: 800;
`;

const Menu = styled.div`
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Divider = styled.div`
  margin: 3rem 0;
  border-bottom: 1px solid #7795a7;
  width: 100%;
`;

const QgisPluginReadme = () => {
  const setToastMessagesAtom = useSetRecoilState(toastMessagesAtom);

  return (
    <Wrapper>
      <h1>VIND QGIS PLUGIN</h1>
      <Text>
        The following page explains how to install the plugin and how to use it
      </Text>
      <Menu>
        <a href="#usage">How to use</a>
        <a href="#installation">Installation</a>
      </Menu>
      <Divider />
      <h2 id={"usage"}>How to use</h2>
      <Text>
        The plugin can be used to synchronize all the <Fat>visible</Fat> vector
        GIS data in your QGIS project into a Vind Project.
      </Text>
      <Text>
        The synchronizing completely replaces the existing data in the Vind
        project.
      </Text>
      <Text>The plugin looks like this</Text>
      <img width={"30%"} src={Plugin} alt="QGIS plugin" />
      <Text>Get the token</Text>
      <button
        onClick={() => {
          navigator.clipboard.writeText(
            localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)!,
          );
          setToastMessagesAtom((tm) => [
            ...tm,
            { text: "Token copied to clip board", timeout: 2000 },
          ]);
        }}
      >
        Copy token to clip board
      </button>
      <Text>
        Paste this into the <Fat>token</Fat> field
      </Text>
      <Text>
        In order for the plugin to know which project to synchronize with, you
        need to provide a <Fat>URL to project</Fat>
      </Text>
      <Text>
        Just go into the{" "}
        <a href="/" target="_blank">
          project
        </a>{" "}
        you want and copy the <Fat>URL</Fat> from the browser and paste it into
        the field
      </Text>
      <Divider />
      <h2 id={"installation"}>Installation</h2>
      <Text>
        Download the plugin as a .zip by clicking{" "}
        <a
          download="vind-qgis-plugin.zip"
          href="https://github.com/vind-ai/qgis_vind_plugin/archive/refs/heads/master.zip"
        >
          {" "}
          here
        </a>
      </Text>
      <Text>
        Open up the plugin manager within QGIS by clicking on <Fat>Plugins</Fat>{" "}
        {"->"} <Fat>Manage and Install plugins</Fat>
      </Text>
      <img width={"30%"} src={ManagePlugins} alt="Manage plugins" />
      <Text>
        Install the plugin by opening the <Fat>install from ZIP</Fat> tab and
        selecting the downloaded zip
      </Text>
      <img width={"30%"} src={InstallFromZip} alt="Install from zip" />
      <Text>
        Start the plugin by clicking on <Fat>Plugins</Fat> {"->"}{" "}
        <Fat>Vind Technologies</Fat> {"->"} <Fat>Vind synch</Fat>
      </Text>
      <img width={"30%"} src={StartPlugin} alt="Start plugin" />
    </Wrapper>
  );
};

export default QgisPluginReadme;
