import { CostType } from "../../../services/costService";
import { isDefined } from "../../../utils/predicates";
import { AmountUnit } from "../../../types/financial";

export type Amount = {
  id: string;
  amount: number;
  amountName?: string;
  category: CostType;
  unit: AmountUnit;
};

export const amountId = ({
  unit,
  category,
  featureTypeId = undefined,
}: {
  unit: AmountUnit;
  category: CostType;
  featureTypeId?: string;
}) =>
  [category, featureTypeId, unit]
    .filter(isDefined)
    .join("#")
    .replace(" ", "_")
    .toLowerCase();

export const amountAddId = (
  amountWithoutId: Omit<Amount, "id">,
  propertyTypeId: string | undefined = undefined,
): Amount => ({
  ...amountWithoutId,
  id: amountId({
    unit: amountWithoutId.unit,
    category: amountWithoutId.category,
    featureTypeId: propertyTypeId,
  }),
});
