import { useCallback } from "react";
import { useRecoilState, useRecoilCallback, useRecoilValue } from "recoil";
import { canvasLayerFeatureHiddenAtomFamily } from "../state/projectLayers";
import { parkChildrenSelector } from "../components/ProjectElements/state";
import { branchIdSelector } from "state/pathParams";

const useToggleFeaturesHidden = () => {
  const branchId = useRecoilValue(branchIdSelector);
  const [hiddenCanvasLayerFeatures, setCanvasLayerFeatureHidden] =
    useRecoilState(canvasLayerFeatureHiddenAtomFamily({ branchId }));

  const getParkChildrenIds = useRecoilCallback(
    ({ snapshot }) =>
      async ({ parkId }) => {
        const parkChildren = await snapshot.getPromise(
          parkChildrenSelector({ parkId }),
        );
        return parkChildren.map((child) => child.id);
      },
    [],
  );

  const getAreAllFeaturesVisible = useCallback(
    (featureIds: string[]) =>
      featureIds.every(
        (featureId) => !hiddenCanvasLayerFeatures.includes(featureId),
      ),
    [hiddenCanvasLayerFeatures],
  );

  const toggleFeaturesHidden = useCallback(
    async (idsToToggle: string[], includeChildren: boolean) => {
      let allIds: string[];
      if (includeChildren) {
        allIds = (
          await Promise.all(
            idsToToggle.flatMap(async (id) => {
              const childIds = await getParkChildrenIds({ parkId: id });
              return [id, ...childIds];
            }),
          )
        ).flat();
      } else {
        allIds = idsToToggle;
      }

      const allFeaturesAreVisible = getAreAllFeaturesVisible(allIds);
      setCanvasLayerFeatureHidden((hiddenIds) =>
        !allFeaturesAreVisible
          ? hiddenIds.filter((hiddenId) => !allIds.includes(hiddenId))
          : [...hiddenIds, ...allIds],
      );
    },
    [getAreAllFeaturesVisible, getParkChildrenIds, setCanvasLayerFeatureHidden],
  );

  return {
    getAreAllFeaturesVisible,
    toggleFeaturesHidden,
    hiddenCanvasLayerFeatures,
  };
};

export default useToggleFeaturesHidden;
