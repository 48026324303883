import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { accessToken, setAccessTokenGlobal } from "../state/global";
import { getTokenAtom } from "../state/user";
import { parseJWT } from "utils/jwt";

export const useCheckAccessToken = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const setToken = useSetRecoilState(getTokenAtom);

  const fetchNewToken = useCallback(
    async (force?: boolean) => {
      const newToken = await getAccessTokenSilently({
        ignoreCache: Boolean(force),
      });
      setAccessTokenGlobal(newToken);
      setToken(newToken);
    },
    [getAccessTokenSilently, setToken],
  );

  const func = useCallback(
    async (force?: boolean) => {
      if (!accessToken) return;
      const decodedMiddleBit = parseJWT(accessToken);
      const expirationDate = new Date(decodedMiddleBit["exp"] * 1000).getTime();
      const inThirtySeconds = new Date().getTime() + 30 * 1000;
      if (inThirtySeconds > expirationDate || force) {
        fetchNewToken(force);
      }
    },
    [fetchNewToken],
  );

  // refresh token on load in case access has changed
  useEffect(() => {
    if (isAuthenticated) {
      fetchNewToken(true);
    }
  }, [fetchNewToken, isAuthenticated]);

  return func;
};

export function useRefreshTokenBeforeExpiration() {
  const checkAccessToken = useCheckAccessToken();

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        checkAccessToken(true);
      }
    };

    // recheck token every 10s ...
    const ref = setInterval(checkAccessToken, 10_000);
    // ... and when the user returns to the browser tab
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      clearInterval(ref);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [checkAccessToken]);
}
