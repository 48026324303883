/// <reference types="vite-plugin-svgr/client" />
import Button from "components/General/Button";
import { Column, Row } from "components/General/Layout";
import {
  analysisResourceWithAccessOnNodeState,
  dataPackageResourceWithAccessOnNodeState,
  financialResourceWithAccessOnNodeState,
  foundationResourceWithAccessOnNodeState,
  turbineResourceWithAccessOnNodeState,
} from "components/Organisation/Library/state";
import {
  ContentTableColumn,
  TabDescription,
  TabHeader,
} from "components/Organisation/OrganisationRightSide/style";
import AddIcon from "@icons/24/Add.svg?react";
import { useRecoilValue } from "recoil";
import {
  orgAnalysisManageAccessSelector,
  orgCableManageAccessSelector,
  orgDataPackagesManageAccessSelector,
  orgFinanicalManageAccessSelector,
  orgFoundationManageAccessSelector,
  orgTurbineManageAccessSelector,
} from "state/user";
import useNodeTurbineResourcesCrud from "../../useNodeTurbineResourcesCrud";
import useNodeFoundationResourcesCrud from "../../useNodeFoundationResourcesCrud";
import { Node } from "services/customerAPI";
import { useRef, useState } from "react";
import TurbineModal from "../../../ResourceContent/TurbineModal";
import FoundationModal from "../../../ResourceContent/FoundationModal";
import Tooltip from "components/General/Tooltip";
import { StyledLink } from "components/Organisation/Library/style";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { Anchor } from "components/General/Anchor";
import useNodeCableResourcesCrud from "../../useNodeCableResourcesCrud";
import CableModal from "../../../ResourceContent/CableModal";
import { cableResourceWithAccessOnNodeState } from "state/cableType";
import { libraryAllSelectorFamily } from "state/featureAccess";
import { organisationIdSelector } from "state/pathParams";
import useNodeAnalysisResourcesCrud from "../../useNodeAnalysisResourcesCrud";
import AnalysisModal from "../../../ResourceContent/AnalysisModal";
import useNodeFinancialResourcesCrud from "../../useNodeFinancialResourcesCrud";
import { SingleTurbineResource } from "./SingleTurbineResource";
import { SingleFoundationResource } from "./SingleFoundationResource";
import { SingleCableResource } from "./SingleCableResource";
import { SingleAnalysisResource } from "./SingleAnalysisResource";
import { SingleFinancialResource } from "./SingleFinancialResource";
import FinancialModal from "../../../ResourceContent/FinancialModal";
import useNodeDataPackageResourcesCrud from "../../useNodeDataPackageResourcesCrud";
import DataPackageModal from "../../../ResourceContent/DataPackageModal";
import { SingleDataPackageResource } from "./SingleDataPackageResource";

export function LibraryResources({ node }: { node: Node }) {
  const nodeId = node.id;
  const organisationId = useRecoilValue(organisationIdSelector);

  const allLibraryAccess = useRecoilValue(
    libraryAllSelectorFamily({ organisationId }),
  );

  const foundationRef = useRef<HTMLDivElement>(null);
  const turbineRef = useRef<HTMLDivElement>(null);
  const cableRef = useRef<HTMLDivElement>(null);
  const analysisRef = useRef<HTMLDivElement>(null);

  const { addOrUpdate: addOrUpdateTurbine } = useNodeTurbineResourcesCrud();
  const { addOrUpdate: addOrUpdateFoundation } =
    useNodeFoundationResourcesCrud();
  const { addOrUpdate: addOrUpdateCable } = useNodeCableResourcesCrud();
  const { addOrUpdate: addOrUpdateAnalysis } = useNodeAnalysisResourcesCrud();
  const { addOrUpdate: addOrUpdateFinancial } = useNodeFinancialResourcesCrud();
  const { addOrUpdate: addOrUpdateDataPackage } =
    useNodeDataPackageResourcesCrud();

  const turbineResources = useRecoilValue(
    turbineResourceWithAccessOnNodeState({ nodeId }),
  );
  const foundationResources = useRecoilValue(
    foundationResourceWithAccessOnNodeState({ nodeId }),
  );
  const cableResources = useRecoilValue(
    cableResourceWithAccessOnNodeState({ nodeId }),
  );
  const analysisResources = useRecoilValue(
    analysisResourceWithAccessOnNodeState({ nodeId }),
  );
  const financialResources = useRecoilValue(
    financialResourceWithAccessOnNodeState({ nodeId }),
  );
  const dataPackageResources = useRecoilValue(
    dataPackageResourceWithAccessOnNodeState({ nodeId }),
  );

  const orgTurbineManageAccess = useRecoilValue(orgTurbineManageAccessSelector);
  const orgFoundationManageAccess = useRecoilValue(
    orgFoundationManageAccessSelector,
  );
  const orgCableManageAccess = useRecoilValue(orgCableManageAccessSelector);
  const orgAnalysisManageAccess = useRecoilValue(
    orgAnalysisManageAccessSelector,
  );
  const orgFinancialManageAccess = useRecoilValue(
    orgFinanicalManageAccessSelector,
  );
  const orgDataPackageManageAccess = useRecoilValue(
    orgDataPackagesManageAccessSelector,
  );

  const [showAddTurbine, setShowAddTurbine] = useState(false);
  const [showAddFoundation, setShowAddFoundation] = useState(false);
  const [showAddCable, setShowAddCable] = useState(false);
  const [showAddAnalysis, setShowAddAnalysis] = useState(false);
  const [showAddFinancial, setShowAddFinancial] = useState(false);
  const [showAddDataPackage, setShowAddDataPackage] = useState(false);

  const [saveTurbinesInProgress, setSaveTurbinesInProgress] =
    useState<number>(0);
  const [saveFoundationsInProgress, setSaveFoundationsInProgress] =
    useState<number>(0);
  const [saveCablesInProgress, setSaveCablesInProgress] = useState<number>(0);
  const [saveAnalysisInProgress, setSaveAnalysisInProgress] =
    useState<number>(0);
  const [saveFinancialInProgress, setSaveFinancialInProgress] =
    useState<number>(0);
  const [saveDataPackageInProgress, setSaveDataPackageInProgress] =
    useState<number>(0);

  return (
    <Column style={{ gap: "2.4rem", height: "100%" }}>
      <Row style={{ alignItems: "center" }}>
        <TabDescription>
          {node.name} can use these organisation library resources.{" "}
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href={"https://help.vindtech.com/en/articles/8842345-library"}
          >
            Read more.
          </StyledLink>
        </TabDescription>
      </Row>
      <Column style={{ gap: "1.6rem" }}>
        <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
          <TabHeader>Turbines</TabHeader>
          <div style={{ position: "relative" }} ref={turbineRef}>
            <Tooltip
              text="Users with library turbine access can give the project access to turbines"
              disabled={orgTurbineManageAccess}
            >
              <Button
                text="Add turbine"
                buttonType="secondary"
                onClick={() => setShowAddTurbine(true)}
                icon={<AddIcon />}
                disabled={!orgTurbineManageAccess || saveTurbinesInProgress > 0}
              />
            </Tooltip>
            {showAddTurbine && (
              <Anchor
                baseRef={turbineRef}
                floatPlace="topRight"
                basePlace="bottom"
              >
                <TurbineModal
                  onSave={async (turbines) => {
                    setShowAddTurbine(false);
                    setSaveTurbinesInProgress(turbines.length);
                    await Promise.all(
                      turbines.map((t) =>
                        addOrUpdateTurbine(nodeId, t.id).then(() =>
                          setSaveTurbinesInProgress((cur) => cur - 1),
                        ),
                      ),
                    ).finally(() => setSaveTurbinesInProgress(0));
                  }}
                  existingTurbines={turbineResources.map((t) => t.turbine.id)}
                />
              </Anchor>
            )}
          </div>
        </Row>
        <ContentTableColumn>
          {turbineResources.map((sr) => {
            return (
              <SingleTurbineResource
                key={sr.turbine.id + sr.nodeId}
                access={sr}
                nodeId={nodeId}
              />
            );
          })}
          {Array.from({ length: saveTurbinesInProgress }, (_, index) => (
            <SkeletonBlock
              key={index}
              style={{ height: "4rem", marginTop: "0.8rem" }}
            />
          ))}
        </ContentTableColumn>
      </Column>
      <Column style={{ gap: "1.6rem" }}>
        <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
          <TabHeader>Foundations</TabHeader>
          <div style={{ position: "relative" }} ref={foundationRef}>
            <Tooltip
              text="Users with library foundation access can give the project access to foundations"
              disabled={orgFoundationManageAccess}
            >
              <Button
                text="Add foundation"
                buttonType="secondary"
                onClick={() => setShowAddFoundation(true)}
                icon={<AddIcon />}
                disabled={
                  !orgFoundationManageAccess || saveFoundationsInProgress > 0
                }
              />
            </Tooltip>
            {showAddFoundation && (
              <Anchor
                baseRef={foundationRef}
                floatPlace="right"
                basePlace="center"
              >
                <FoundationModal
                  onSave={async (foundations) => {
                    setShowAddFoundation(false);
                    setSaveFoundationsInProgress(foundations.length);
                    await Promise.all(
                      foundations.map((f) =>
                        addOrUpdateFoundation(nodeId, f).then(() =>
                          setSaveFoundationsInProgress((cur) => cur - 1),
                        ),
                      ),
                    ).finally(() => setSaveFoundationsInProgress(0));
                  }}
                  existingFoundations={foundationResources.map(
                    (t) => t.foundation.id,
                  )}
                />
              </Anchor>
            )}
          </div>
        </Row>
        <ContentTableColumn>
          {foundationResources.map((sr) => {
            return (
              <SingleFoundationResource
                key={sr.foundation.id}
                access={sr}
                nodeId={nodeId}
              />
            );
          })}
          {Array.from({ length: saveFoundationsInProgress }, (_, index) => (
            <SkeletonBlock
              key={index}
              style={{ height: "4rem", marginTop: "0.8rem" }}
            />
          ))}
        </ContentTableColumn>
      </Column>
      {allLibraryAccess && (
        <>
          <Column style={{ gap: "1.6rem" }}>
            <Row
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <TabHeader>Cables</TabHeader>
              <div style={{ position: "relative" }} ref={cableRef}>
                <Tooltip
                  text="Users with library cable access can give the project access to cables"
                  disabled={orgCableManageAccess}
                >
                  <Button
                    text="Add cable"
                    buttonType="secondary"
                    onClick={() => setShowAddCable(true)}
                    icon={<AddIcon />}
                    disabled={!orgCableManageAccess || saveCablesInProgress > 0}
                  />
                </Tooltip>
                {showAddCable && (
                  <Anchor
                    baseRef={cableRef}
                    floatPlace="topRight"
                    basePlace="bottom"
                  >
                    <CableModal
                      onSave={async (cables) => {
                        setShowAddCable(false);
                        setSaveCablesInProgress(cables.length);
                        await Promise.all(
                          cables.map((t) =>
                            addOrUpdateCable(nodeId, t.id).then(() =>
                              setSaveCablesInProgress((cur) => cur - 1),
                            ),
                          ),
                        ).finally(() => setSaveCablesInProgress(0));
                      }}
                      existingCables={cableResources.map((c) => c.cable.id)}
                    />
                  </Anchor>
                )}
              </div>
            </Row>
            <ContentTableColumn>
              {cableResources.map((sr) => {
                return (
                  <SingleCableResource
                    key={sr.cable.id + sr.nodeId}
                    access={sr}
                    nodeId={nodeId}
                  />
                );
              })}
              {Array.from({ length: saveCablesInProgress }, (_, index) => (
                <SkeletonBlock
                  key={index}
                  style={{ height: "4rem", marginTop: "0.8rem" }}
                />
              ))}
            </ContentTableColumn>
          </Column>
          <Column style={{ gap: "1.6rem" }}>
            <Row
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <TabHeader>Analysis configurations</TabHeader>
              <div style={{ position: "relative" }} ref={analysisRef}>
                <Tooltip
                  text="Users with library analysis access can give the project access to analysis"
                  disabled={orgAnalysisManageAccess}
                >
                  <Button
                    text="Add analysis"
                    buttonType="secondary"
                    onClick={() => setShowAddAnalysis(true)}
                    icon={<AddIcon />}
                    disabled={
                      !orgAnalysisManageAccess || saveAnalysisInProgress > 0
                    }
                  />
                </Tooltip>
                {showAddAnalysis && (
                  <Anchor
                    baseRef={analysisRef}
                    floatPlace="topRight"
                    basePlace="bottom"
                  >
                    <AnalysisModal
                      onSave={async (analysis) => {
                        setShowAddAnalysis(false);
                        setSaveAnalysisInProgress(analysis.length);
                        await Promise.all(
                          analysis.map((a) =>
                            addOrUpdateAnalysis(nodeId, a).then(() =>
                              setSaveAnalysisInProgress((cur) => cur - 1),
                            ),
                          ),
                        ).finally(() => setSaveAnalysisInProgress(0));
                      }}
                      existingAnalysis={analysisResources.map(
                        (c) => c.config.id,
                      )}
                    />
                  </Anchor>
                )}
              </div>
            </Row>
            <ContentTableColumn>
              {analysisResources.map((sr) => {
                return (
                  <SingleAnalysisResource
                    key={sr.config.id + sr.nodeId}
                    access={sr}
                    nodeId={nodeId}
                  />
                );
              })}
              {Array.from({ length: saveAnalysisInProgress }, (_, index) => (
                <SkeletonBlock
                  key={index}
                  style={{ height: "4rem", marginTop: "0.8rem" }}
                />
              ))}
            </ContentTableColumn>
          </Column>
          <Column style={{ gap: "1.6rem" }}>
            <Row
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <TabHeader>Financial configurations</TabHeader>
              <div style={{ position: "relative" }} ref={analysisRef}>
                <Tooltip
                  text="Users with library financial configuration access can give the project access to financial configurations"
                  disabled={orgFinancialManageAccess}
                >
                  <Button
                    text="Add financial"
                    buttonType="secondary"
                    onClick={() => setShowAddFinancial(true)}
                    icon={<AddIcon />}
                    disabled={
                      !orgFinancialManageAccess || saveAnalysisInProgress > 0
                    }
                  />
                </Tooltip>
                {showAddFinancial && (
                  <Anchor
                    baseRef={analysisRef}
                    floatPlace="topRight"
                    basePlace="bottom"
                  >
                    <FinancialModal
                      onSave={async (analysis) => {
                        setShowAddFinancial(false);
                        setSaveFinancialInProgress(analysis.length);
                        await Promise.all(
                          analysis.map((a) =>
                            addOrUpdateFinancial(nodeId, a).then(() =>
                              setSaveFinancialInProgress((cur) => cur - 1),
                            ),
                          ),
                        ).finally(() => setSaveFinancialInProgress(0));
                      }}
                      existingFinancialConfig={financialResources.map(
                        (c) => c.config.id,
                      )}
                    />
                  </Anchor>
                )}
              </div>
            </Row>
            <ContentTableColumn>
              {financialResources.map((sr) => {
                return (
                  <SingleFinancialResource
                    key={sr.config.id + sr.nodeId}
                    access={sr}
                    nodeId={nodeId}
                  />
                );
              })}
              {Array.from({ length: saveFinancialInProgress }, (_, index) => (
                <SkeletonBlock
                  key={index}
                  style={{ height: "4rem", marginTop: "0.8rem" }}
                />
              ))}
            </ContentTableColumn>
          </Column>
          <Column style={{ gap: "1.6rem" }}>
            <Row
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <TabHeader>Data packages</TabHeader>
              <div style={{ position: "relative" }} ref={analysisRef}>
                <Tooltip
                  text="Users with library data package access can give the project access to data packages"
                  disabled={orgDataPackageManageAccess}
                >
                  <Button
                    text="Add data package"
                    buttonType="secondary"
                    onClick={() => setShowAddDataPackage(true)}
                    icon={<AddIcon />}
                    disabled={
                      !orgDataPackageManageAccess || saveAnalysisInProgress > 0
                    }
                  />
                </Tooltip>
                {showAddDataPackage && (
                  <Anchor
                    baseRef={analysisRef}
                    floatPlace="topRight"
                    basePlace="bottom"
                  >
                    <DataPackageModal
                      onSave={async (analysis) => {
                        setShowAddDataPackage(false);
                        setSaveDataPackageInProgress(analysis.length);
                        await Promise.all(
                          analysis.map((a) =>
                            addOrUpdateDataPackage(nodeId, a).then(() =>
                              setSaveDataPackageInProgress((cur) => cur - 1),
                            ),
                          ),
                        ).finally(() => setSaveDataPackageInProgress(0));
                      }}
                      existingDataPackageConfig={dataPackageResources.map(
                        (c) => c.config.id,
                      )}
                    />
                  </Anchor>
                )}
              </div>
            </Row>
            <ContentTableColumn>
              {dataPackageResources.map((sr) => {
                return (
                  <SingleDataPackageResource
                    key={sr.config.id + sr.nodeId}
                    access={sr}
                    nodeId={nodeId}
                  />
                );
              })}
              {Array.from({ length: saveDataPackageInProgress }, (_, index) => (
                <SkeletonBlock
                  key={index}
                  style={{ height: "4rem", marginTop: "0.8rem" }}
                />
              ))}
            </ContentTableColumn>
          </Column>
        </>
      )}
    </Column>
  );
}
