import { LngLatLike } from "mapbox-gl";
import { atomFamily } from "recoil";

type LivePositions = Map<string, LngLatLike>;

export const liveCursorState = atomFamily<
  LivePositions,
  { projectId: string; branchId: string; version?: number }
>({
  key: "liveCursorState",
  default: new Map(),
});
