import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { imageToGeorefAtom } from "../../state/georef";
import { mapRefAtom } from "../../state/map";
import { StandardBox } from "../../styles/boxes/Boxes";
import { THIRTY_MEGABYTES } from "../../services/projectDataAPIService";
import { uploadGeoTiff } from "services/uploadGeoTiff";
import { toastMessagesAtom } from "../../state/toast";
import { ProjectFeature } from "../../types/feature";
import { GeotiffType } from "../../utils/geojson/utils";
import eventEmitter from "../../utils/eventEmitter";
import Point from "../MapFeatures/Point";
import { Feature } from "geojson";
import Button from "../General/Button";
import { LngLat } from "../../state/viewToPark";
import { projectFeaturesSelector } from "../ProjectElements/state";
import { useProjectElementsCrud } from "../ProjectElements/useProjectElementsCrud";
import { MapMouseEvent } from "mapbox-gl";
import {
  mouseMoveHandlerAtom,
  clickHandlerAtom,
  doubleClickHandlerAtom,
} from "components/Mapbox/state";
import { projectIdSelector } from "state/pathParams";

const sourceLayerId = "georefPoints";

const Wrapper = styled(StandardBox)`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: calc(
    calc(var(--top-bar-menu-height) + var(--branch-tab-bar-height)) + 0.8rem
  );
  right: calc(var(--side-bars-width) + 1.6rem);
  font-size: 1.3rem;
  line-height: 2;
  outline: none;
  justify-content: center;
  padding: 20px 10px;
`;

const Header = styled.h3``;

const Points = styled.div``;

const ImageWrapper = styled.div`
  position: relative;
  line-height: 0;
`;

const ImageToGeoref = styled.img<{ disabled?: boolean }>`
  opacity: ${(p) => (p.disabled ? "0.3" : "1.0")};
  cursor: ${(p) => (p.disabled ? "not-allowed" : "crosshair")};
  max-width: 40vw;
  max-height: 40vh;
`;

const ButtonGeoref = styled(Button)`
  margin: 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const GeorefPoint = styled.div<{ pos?: number[] }>`
  background-color: red;
  border-radius: 4px;
  height: 4px;
  width: 4px;
  position: absolute;
  left: calc(${(p) => p.pos?.[0]}% - 2px);
  top: calc(${(p) => p.pos?.[1]}% - 2px);
`;

export type GeoRefPoint = {
  imgCoords: {
    x: number;
    y: number;
    percentage: [number, number];
  };
  mapCoords?: LngLat;
};

export const GEOREF_DONE_EVENT = "geoRefDoneEvent";
const useHandleGeorefedImage = (
  coords: GeoRefPoint[],
  imageToGeoref?: File,
) => {
  const projectId = useRecoilValue(projectIdSelector);
  const setToastMessages = useSetRecoilState(toastMessagesAtom);
  const projectData = useRecoilValue(projectFeaturesSelector);
  const { update: updateFeatures } = useProjectElementsCrud();

  const georeferencer = useMemo(() => {
    return new Worker("/web_workers/gdal/georef.js");
  }, []);

  useEffect(() => {
    georeferencer.onmessage = async function (evt) {
      const blob = new Blob([evt.data.bytes]);

      const geotiffLayerEntry: ProjectFeature | undefined = await uploadGeoTiff(
        blob,
        projectId!,
        setToastMessages,
        GeotiffType.georefimage,
        THIRTY_MEGABYTES,
        imageToGeoref?.name,
      );

      if (geotiffLayerEntry) updateFeatures({ add: [geotiffLayerEntry] });
      georeferencer.terminate();
    };
  }, [
    coords,
    projectId,
    setToastMessages,
    projectData,
    updateFeatures,
    imageToGeoref,
    georeferencer,
  ]);

  return useCallback(() => {
    setToastMessages((tm) => [
      ...tm,
      {
        text: "Processing image...",
        timeout: 2000,
      },
    ]);
    georeferencer.onerror = function (e) {
      if (e.message.includes("try again"))
        setTimeout(
          () => georeferencer.postMessage({ file: imageToGeoref, coords }),
          100,
        );
    };
    georeferencer.postMessage({ file: imageToGeoref, coords });
  }, [setToastMessages, georeferencer, imageToGeoref, coords]);
};

const GeorefImageModalInner = () => {
  const [imageToGeoref, setImageToGeoref] = useRecoilState(imageToGeorefAtom);
  const [coords, setCoords] = useState<GeoRefPoint[]>([]);
  const objectURL = useMemo(() => {
    if (!imageToGeoref) return;
    return URL.createObjectURL(imageToGeoref);
  }, [imageToGeoref]);
  const map = useRecoilValue(mapRefAtom);
  const georefImage = useHandleGeorefedImage(coords, imageToGeoref);

  const setMouseMoveHandler = useSetRecoilState(mouseMoveHandlerAtom);
  const setClickHandler = useSetRecoilState(clickHandlerAtom);
  const setDoubleClickHandler = useSetRecoilState(doubleClickHandlerAtom);

  const waitingForLatLng = useMemo(() => {
    const lastElement = coords.slice(-1)[0];
    return lastElement && !lastElement.mapCoords;
  }, [coords]);

  const clickOnImage = useCallback(
    (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
      const target = e.target as HTMLImageElement;
      var rect = target.getBoundingClientRect();
      const percentageX = (e.clientX - rect.left) / target.width;
      const percentageY = (e.clientY - rect.top) / target.height;
      var x = percentageX * target.naturalWidth;
      var y = percentageY * target.naturalHeight;

      setCoords((coords) => [
        ...coords,
        {
          imgCoords: {
            x: Math.max(x, 0),
            y: Math.max(y, 0),
            percentage: [percentageX * 100, percentageY * 100],
          },
        },
      ]);
    },
    [setCoords],
  );

  const mouseClick = useCallback(
    (e: MapMouseEvent) => {
      e.preventDefault();
      setCoords((coords) => {
        const last = coords.slice(-1)[0];
        return [
          ...coords.slice(0, -1),
          {
            ...last,
            mapCoords: {
              lng: parseFloat(e.lngLat.lng.toFixed(4)),
              lat: parseFloat(e.lngLat.lat.toFixed(4)),
            },
          },
        ];
      });
    },
    [setCoords],
  );

  useEffect(() => {
    if (!map) return;
    if (waitingForLatLng) {
      map.getCanvas().style.cursor = "crosshair";
      setClickHandler(() => mouseClick);
      setMouseMoveHandler(() => () => {});
      setDoubleClickHandler(() => () => {});
      return () => {
        setMouseMoveHandler(undefined);
        setDoubleClickHandler(undefined);
        setClickHandler(undefined);
      };
    }
    map.getCanvas().style.cursor = "unset";
  }, [
    map,
    waitingForLatLng,
    mouseClick,
    setClickHandler,
    setMouseMoveHandler,
    setDoubleClickHandler,
  ]);

  const mapPoints: Feature[] = useMemo(() => {
    if (!map) return [];
    return coords
      .filter((c) => c.mapCoords != null)
      .map((c, i) => {
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [c.mapCoords!.lng, c.mapCoords!.lat],
          },
          properties: {
            id: i,
          },
        };
      });
  }, [map, coords]);

  useEffect(() => {
    const undo = (event: KeyboardEvent) => {
      if (
        event.isComposing ||
        event.code !== "KeyZ" ||
        (!event.metaKey && !event.ctrlKey) ||
        event.shiftKey
      ) {
        return;
      }
      setCoords(coords.slice(0, -1));
    };

    window.addEventListener("keydown", undo);
    return () => {
      window.removeEventListener("keydown", undo);
    };
  }, [setCoords, coords]);

  if (!map) return null;

  return (
    <Wrapper>
      <Header>Georeference Image</Header>
      <Points>{`Number of points: ${coords.length} (min 3 required)`}</Points>
      <ImageWrapper>
        <ImageToGeoref
          disabled={waitingForLatLng}
          onClick={(e) => {
            if (waitingForLatLng) return;
            clickOnImage(e);
          }}
          src={objectURL}
        />
        {coords.map((c, i) => (
          <GeorefPoint key={i} pos={c.imgCoords.percentage} />
        ))}
      </ImageWrapper>
      <ButtonWrapper>
        <ButtonGeoref
          text="Save image"
          disabled={coords.length < 3 || waitingForLatLng}
          onClick={() => {
            georefImage();
            setImageToGeoref(undefined);
            setCoords([]);
            eventEmitter.emit(GEOREF_DONE_EVENT, true);
          }}
        />
        <ButtonGeoref
          text="Cancel"
          buttonType="secondary"
          onClick={() => {
            setImageToGeoref(undefined);
            setCoords([]);
            eventEmitter.emit(GEOREF_DONE_EVENT, false);
          }}
        />
      </ButtonWrapper>
      <Point
        map={map}
        features={mapPoints}
        layerId={sourceLayerId}
        sourceId={sourceLayerId}
        paint={{
          "circle-radius": 3,
          "circle-color": "#f00",
        }}
      />
    </Wrapper>
  );
};

const GeorefImageModal = () => {
  const imageToGeoref = useRecoilValue(imageToGeorefAtom);

  if (!imageToGeoref) return null;

  return <GeorefImageModalInner />;
};

export default GeorefImageModal;
