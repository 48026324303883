import React, { useCallback, useState, useMemo } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import useTextInput from "../../../hooks/useTextInput";
import { Input } from "../../General/Input";
import Button from "../../General/Button";
import { getExternalDataSourcesSelector } from "../../../state/layer";
import Dropdown from "../../Dropdown/Dropdown";
import { SourceTypes } from "../../../types/layers";
import {
  checkIsValidWMS,
  checkArcgisRestApiIsValid,
  checkWFSIsValid,
  formatSourceUrl,
  checkIsValidWMTS,
} from "../utils";
import { spaceLarge } from "../../../styles/space";
import useBooleanState from "../../../hooks/useBooleanState";
import { useToast } from "../../../hooks/useToast";
import { addNewEndpointToSource } from "../../../services/gisDataAPIService";
import Spinner from "@icons/spinner/Spinner";

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
`;

const ErrorText = styled.h5`
  color: #ff0000;
`;

const AddSourceEndpointTab = () => {
  const existingSources = useRecoilValue(getExternalDataSourcesSelector);
  const [selectedSourceId, onSelectedSourceIdChange] = useTextInput("");
  const [endpointUrl, onEndpointUrlChange] = useTextInput("");
  const [sourceType, onSourceTypeChange] = useTextInput<SourceTypes>(
    SourceTypes.wms,
  );
  const showSpacesBeingReplacedInfo =
    endpointUrl.includes(" ") || endpointUrl.endsWith("/");

  const [licenseType, onLicenseTypeChange] = useTextInput("");
  const [licenseUrl, onLicenseUrlChange] = useTextInput("");
  const [urlWhereFound, onUrlWhereFoundChange] = useTextInput("");
  const [attribution, onAttributionChange] = useTextInput("");
  const [error, setError] = useState("");

  const sortedExisitingSource = useMemo(
    () => existingSources.slice().sort((a, b) => a.name.localeCompare(b.name)),
    [existingSources],
  );
  const [isLoading, toggleIsLoading] = useBooleanState(false);
  const { success, error: showError } = useToast();

  const onSubmit = useCallback(async () => {
    const formattedUrl = formatSourceUrl(endpointUrl);

    if (sourceType === SourceTypes.wms) {
      const valid = await checkIsValidWMS(formattedUrl);
      if (!valid) {
        setError("Url does not point to a valid WMS source...");
        return;
      }
    } else if (sourceType === SourceTypes.wmts) {
      const valid = await checkIsValidWMTS(formattedUrl);
      if (!valid) {
        setError("Url does not point to a valid WMTS source...");
        return;
      }
    } else if (sourceType === SourceTypes.arcgis_rest_api) {
      const valid = await checkArcgisRestApiIsValid(formattedUrl);
      if (!valid) {
        setError("Url does not point to a valid Arcgis rest api source...");
        return;
      }
    } else if (sourceType === SourceTypes.wfs) {
      const valid = await checkWFSIsValid(formattedUrl);
      if (!valid) {
        setError(
          "Url does not point to a valid wfs source or a wfs source that outputs geojson...",
        );
        return;
      }
    }

    toggleIsLoading();
    try {
      const response = await addNewEndpointToSource(
        selectedSourceId,
        sourceType,
        formattedUrl,
        licenseType,
        urlWhereFound,
        attribution,
        licenseUrl,
      );
      console.log(response);
      success(
        "Source endpoint was added. The layers beloning to this endpoint should be visible after a refresh in less than a minute.",
        { timeout: 5000 },
      );
    } catch (err) {
      if (err instanceof Error) showError(err.message, { timeout: 5000 });
    } finally {
      toggleIsLoading();
    }
  }, [
    endpointUrl,
    sourceType,
    toggleIsLoading,
    selectedSourceId,
    licenseType,
    urlWhereFound,
    attribution,
    licenseUrl,
    success,
    showError,
  ]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: spaceLarge }}>
      <Dropdown
        value={selectedSourceId}
        onChange={onSelectedSourceIdChange}
        style={{ padding: "1rem" }}
        defaultValue=""
      >
        <option value="" disabled>
          Choose source
        </option>
        {sortedExisitingSource.map((source) => (
          <option key={source.id} value={source.id}>
            {source.name}
          </option>
        ))}
      </Dropdown>
      <Dropdown onChange={onSourceTypeChange} style={{ padding: "1rem" }}>
        {Object.values(SourceTypes).map((type) => (
          <option key={type} value={type}>
            {type}
          </option>
        ))}
      </Dropdown>
      <Input
        value={endpointUrl}
        onChange={onEndpointUrlChange}
        type="text"
        placeholder={`URL to ${sourceType} source *`}
      />
      {showSpacesBeingReplacedInfo && (
        <>
          <div>
            Spaces in URL will be replaced by plus characters (+), and trailing
            slashes will be removed
          </div>
          <div>{formatSourceUrl(endpointUrl)}</div>
        </>
      )}
      <Input
        value={urlWhereFound}
        onChange={onUrlWhereFoundChange}
        type="text"
        placeholder="URL to where the source was found"
      />
      <Input
        value={licenseType}
        onChange={onLicenseTypeChange}
        type="text"
        placeholder="License type"
      />
      <Input
        value={licenseUrl}
        onChange={onLicenseUrlChange}
        type="text"
        placeholder="License url"
      />
      <Input
        value={attribution}
        onChange={onAttributionChange}
        type="text"
        placeholder="Attribution"
      />
      {error && <ErrorText>{error}</ErrorText>}

      <ButtonWrapper>
        <Button
          text="Submit"
          buttonType="primary"
          onClick={onSubmit}
          disabled={isLoading || selectedSourceId === ""}
          icon={isLoading ? <Spinner size="1rem" /> : undefined}
        />
      </ButtonWrapper>
    </div>
  );
};

export default AddSourceEndpointTab;
