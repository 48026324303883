import { Comp } from "../../types/utils";
import { colors } from "../../styles/colors";
import styled, { CSSProperties, keyframes } from "styled-components";
import { spaceSmall } from "../../styles/space";
import { Loadable } from "recoil";

const skeletonAnimation = keyframes`
    100% {transform: translateX(100%);}
`;

const Skeleton = styled.div.attrs(({ className }: { className?: string }) => ({
  className: className ?? "skeleton",
}))`
  --base-color: ${colors.hover};
  --highlight-color: ${colors.secondaryText}28;

  background-color: var(--base-color);

  width: 100%;
  height: 1.6rem;
  border-radius: 0.6rem;
  display: inline-flex;
  line-height: 1;

  position: relative;
  overflow: hidden;
  z-index: 1;

  &::after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(
      90deg,
      var(--base-color),
      var(--highlight-color),
      var(--base-color)
    );
    transform: translateX(-100%);

    animation-name: ${skeletonAnimation};
    animation-direction: normal;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
`;

const defaultSize = 32;
export const SkeletonRound = styled(Skeleton)<{ size?: number }>`
  width: ${(p) => (p.size ?? defaultSize) / 10}rem;
  height: ${(p) => (p.size ?? defaultSize) / 10}rem;
  min-width: ${(p) => (p.size ?? defaultSize) / 10}rem;
  min-height: ${(p) => (p.size ?? defaultSize) / 10}rem;
  border-radius: 50%;
`;

export const SkeletonText = ({
  text,
  style,
  ...props
}: Comp<"div", { text?: string }>) => {
  return (
    <Skeleton
      style={{
        display: "flex",
        height: text ? "initial" : "1lh",
        width: "initial",
        ...style,
      }}
      {...props}
    >
      {text && (
        <p
          style={{
            opacity: 0.5,
            marginBlock: 0,
            marginInline: spaceSmall,
            zIndex: 1,
          }}
        >
          {text}
        </p>
      )}
    </Skeleton>
  );
};

export const SkeletonBlock = styled(Skeleton)`
  height: 100%;
  width: 100%;
`;

export const SkeletonImage = ({ size }: { size: number }) => (
  <SkeletonBlock
    style={{
      width: `${size}rem`,
      height: `${size}rem`,
      borderRadius: `${size / 2}rem`,
    }}
  />
);

/**
 * Given a {@link Loadable} run the first function if it is loading and the
 * second function if the value is defined.
 *
 * If you just want a loader, use {@link orLoader} instead.
 */
export const ifLoader = <T,>(
  loadable: Loadable<T | undefined>,
  loadFn: () => JSX.Element | null,
  valueFn: (t: T) => JSX.Element | null,
) => {
  const value = loadable.valueMaybe();
  if (value === undefined) return loadFn();
  return valueFn(value);
};

/**
 * Runs the given function on the value of the loadable, or returns a skeleton
 * loader if the loadable is loading, or if its value is undefined.
 */
export const orLoader = <T,>(
  loadable: Loadable<T | undefined>,
  fn: (t: T) => JSX.Element | null,
  loaderStyle?: CSSProperties,
) =>
  ifLoader(
    loadable,
    () => (
      <SkeletonBlock
        style={{
          display: "flex",
          alignSelf: "center",
          height: "1rem",
          width: "8rem",
          ...loaderStyle,
        }}
      />
    ),
    fn,
  );
export const orTextLoader = <T,>(
  loadable: Loadable<T | undefined>,
  text: string,
  fn: (t: T) => JSX.Element | null,
) => {
  const value = loadable.valueMaybe();
  if (value === undefined)
    return <SkeletonText text={text} style={{ width: "8rem" }} />;
  return fn(value);
};
