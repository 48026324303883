import { CABLE_PROPERTY_TYPE } from "@constants/cabling";
import * as turf from "@turf/turf";
import { addCableLoads } from "components/Cabling/CableWalk";
import {
  CableConnectionError,
  getReadableErrorMessage,
} from "components/CanvasSelectOption/TypeSelector/LineStringTypeSelector/utils";
import { snapLineToClosestPoint } from "components/MapControls/CustomModes/lib/snapping";
import { useToast } from "hooks/useToast";
import { UnionFind } from "lib/UnionFind";
import { useRecoilCallback } from "recoil";
import {
  getCablesSelectorFamily,
  getSubstationsSelectorFamily,
} from "state/cable";
import { cableChainsSelectorFamily } from "state/cableEdit";
import { getTurbinesSelectorFamily } from "state/layout";
import { parkIdSelector } from "state/pathParams";
import { allSimpleTurbineTypesSelector } from "state/turbines";
import {
  _CableFeature,
  CableFeature,
  LineStringFeature,
  SubstationFeature,
  TurbineFeature,
} from "types/feature";
import { isDefined } from "utils/predicates";
import { partition } from "utils/utils";
import { v4 as uuid } from "uuid";

const checkIfCableStartsAndEndsOnTurbineOrSubstation = (
  cable: CableFeature | LineStringFeature,
  points: (TurbineFeature | SubstationFeature)[],
) => {
  const startCoords = cable.geometry.coordinates[0];
  const endCoords =
    cable.geometry.coordinates[cable.geometry.coordinates.length - 1];

  const featuresCloseToStart = points
    .map((turbine) => {
      return {
        distance: turf.distance(turbine, turf.point(startCoords), {
          units: "meters",
        }),
        turbine,
      };
    })
    .filter(({ distance }) => distance < 1)
    .sort((a, b) => a.distance - b.distance);

  const featuresCloseToEnd = points
    .map((turbine) => {
      return {
        distance: turf.distance(turbine, turf.point(endCoords), {
          units: "meters",
        }),
        turbine,
      };
    })
    .filter(({ distance }) => distance < 1)
    .sort((a, b) => a.distance - b.distance);

  return featuresCloseToStart.length > 0 && featuresCloseToEnd.length > 0;
};

// Split line in every possible way between points along the line.
// Example input:
// o--o-o----o
// Outputs:
// o--o
// o----o
// o---------o
//    o-o
//    o------o
//      o----o
const splitLineInEveryWayBetweenPoints = (
  cable: CableFeature | LineStringFeature,
  pointsOnLine: (TurbineFeature | SubstationFeature)[],
): LineStringFeature[] => {
  // Make every possible split of the line between the turbines
  let allPossibleSplitsBetweenTurbines: LineStringFeature[] = [];
  for (let i = 0; i < pointsOnLine.length; i++) {
    for (let j = i; j < pointsOnLine.length; j++) {
      if (i === j) {
        continue;
      }

      const splitLine = turf.lineSlice(pointsOnLine[i], pointsOnLine[j], cable);
      const fixedSplitLine =
        splitLine.geometry.coordinates.length > 2
          ? turf.cleanCoords(splitLine)
          : splitLine;

      const newId = uuid();

      allPossibleSplitsBetweenTurbines.push({
        ...fixedSplitLine,
        id: newId,
        properties: {
          ...fixedSplitLine.properties,
          id: newId,
        },
      });
    }
  }

  return allPossibleSplitsBetweenTurbines;
};

const splitAndConnectCableToPoints = (
  cable: CableFeature | LineStringFeature,
  turbines: TurbineFeature[],
  substations: SubstationFeature[],
  parkId?: string,
  cableNeedsToStartAndEndOnPoints = true,
): { cables: CableFeature[]; snapped: boolean } | undefined => {
  const possiblySnappedGeometry = snapLineToClosestPoint(
    cable.geometry.coordinates,
    turbines
      .map((t) => t.geometry.coordinates)
      .concat(substations.map((s) => s.geometry.coordinates)),
    0.01,
  );

  const possiblySnappedCable = {
    ...cable,
    geometry: {
      ...cable.geometry,
      coordinates: possiblySnappedGeometry.line,
    },
  };

  // Find turbines and substations that are on the line
  const pointsOnLine = [...turbines, ...substations].filter(
    (turbineOrSubstation) => {
      return turf.booleanPointOnLine(turbineOrSubstation, possiblySnappedCable);
    },
  );

  if (
    cableNeedsToStartAndEndOnPoints &&
    !checkIfCableStartsAndEndsOnTurbineOrSubstation(
      possiblySnappedCable,
      pointsOnLine,
    )
  ) {
    throw new CableConnectionError(
      "The first and last point of the line must connect to a turbine or substation.",
    );
  }

  if (pointsOnLine.length === 0) {
    return undefined;
  }

  // Make every possible split of the line between the turbines
  const allPossibleSplitsBetweenTurbines = splitLineInEveryWayBetweenPoints(
    possiblySnappedCable,
    pointsOnLine,
  );

  const allPossibleLinesWithTheirTurbines =
    allPossibleSplitsBetweenTurbines.map((line) => {
      return {
        line,
        turbines: pointsOnLine.filter((point) =>
          turf.booleanPointOnLine(point, line),
        ),
      };
    });

  // Find linesplits that have exactly 2 turbines/substations in them
  // Convert to cable and add from/to ids and parentIds
  const cableSplitsWith2Connections = allPossibleLinesWithTheirTurbines
    .filter((row) => row.turbines.length === 2)
    .map((row) => {
      // the order of the turbines is random, so we need to find the one closest to
      // cable coordinate 1 and use that as fromId and the one closest to the last coordinate as toId
      // so that the coordinate direction matches the from/to direction
      const firstCoordinate = row.line.geometry.coordinates[0];

      const useFirstTurbineAsFrom =
        turf.distance(row.turbines[0], turf.point(firstCoordinate), {
          units: "meters",
        }) <
        turf.distance(row.turbines[1], turf.point(firstCoordinate), {
          units: "meters",
        });

      const fromId = useFirstTurbineAsFrom
        ? row.turbines[0].id
        : row.turbines[1].id;
      const toId = useFirstTurbineAsFrom
        ? row.turbines[1].id
        : row.turbines[0].id;

      return _CableFeature.parse({
        ...row.line,
        properties: {
          ...row.line.properties,
          type: CABLE_PROPERTY_TYPE,
          fromId: fromId,
          toId: toId,
          parentIds: parkId ? [parkId] : row.line.properties.parentIds,
        },
      });
    });

  return {
    cables: cableSplitsWith2Connections,
    snapped: possiblySnappedGeometry.snapped,
  };
};

const useCreateCableSlicesBetweenTurbinesAndSubstations = () => {
  const { error: showError, warning: showWarning } = useToast();

  const splitSingle = useRecoilCallback(
    ({ snapshot }) =>
      (
        cable: CableFeature | LineStringFeature,
        parentId?: string,
        cableNeedsToStartAndEndOnPoints: boolean = true,
      ): undefined | { cables: CableFeature[]; snapped: boolean } => {
        const parkId =
          parentId ?? snapshot.getLoadable(parkIdSelector).getValue();
        const turbines = snapshot
          .getLoadable(getTurbinesSelectorFamily({ parkId: parkId ?? "" }))
          .getValue();
        const substations = snapshot
          .getLoadable(getSubstationsSelectorFamily({ parkId: parkId ?? "" }))
          .getValue();

        const result = splitAndConnectCableToPoints(
          cable,
          turbines,
          substations,
          parkId,
          cableNeedsToStartAndEndOnPoints,
        );

        return result;
      },
    [],
  );

  return useRecoilCallback(
    ({ snapshot }) =>
      (
        cables: (CableFeature | LineStringFeature)[],
        parentId?: string,
        cableNeedsToStartAndEndOnPoints: boolean = true,
      ): [CableFeature[], CableFeature[]] => {
        let snapped = false;

        const slices = cables.map((cable) => {
          try {
            const res = splitSingle(
              cable,
              parentId,
              cableNeedsToStartAndEndOnPoints,
            );
            if (!res) return;
            const { cables, snapped: snappedToCreateLines } = res;
            snapped = snapped || snappedToCreateLines;
            return cables;
          } catch (e) {
            if (e instanceof Error) {
              showError(getReadableErrorMessage(e));
            }
            throw e;
          }
        });

        if (snapped) {
          showWarning(
            "Cable was snapped (<10m) to a turbine or substation. Please review the cable path.",
          );
        }

        const containsFailedCables = slices.some(
          (slice) => slice === undefined,
        );

        if (containsFailedCables) {
          showError("Not all lines could be transformed to cables");
        }

        const newConnectedCables = slices.filter(isDefined).flat();

        const parkId =
          parentId ?? snapshot.getLoadable(parkIdSelector).getValue();
        const turbines = snapshot
          .getLoadable(getTurbinesSelectorFamily({ parkId: parkId ?? "" }))
          .getValue();
        const substations = snapshot
          .getLoadable(getSubstationsSelectorFamily({ parkId: parkId ?? "" }))
          .getValue();
        const existingCables = snapshot
          .getLoadable(
            getCablesSelectorFamily({
              parkId: parkId ?? "",
            }),
          )
          .getValue();

        const turbineTypes = snapshot
          .getLoadable(allSimpleTurbineTypesSelector)
          .getValue();

        const existingCableChains = snapshot
          .getLoadable(
            cableChainsSelectorFamily({
              parkId: parkId ?? "",
              allowEmptySubstation: true,
            }),
          )
          .getValue();

        const touchedCableChains = existingCableChains.filter((chain) => {
          return chain.turbines.some((turbineId) => {
            return newConnectedCables.some((cable) => {
              return (
                cable.properties.fromId === turbineId ||
                cable.properties.toId === turbineId
              );
            });
          });
        });
        const turbineIdsInTouchedCableChains = touchedCableChains.flatMap(
          (chain) => chain.turbines,
        );
        const touchedCables = existingCables.filter((cable) => {
          return (
            turbineIdsInTouchedCableChains.includes(cable.properties.fromId) ||
            turbineIdsInTouchedCableChains.includes(cable.properties.toId)
          );
        });

        // If the new cable connects turbines that are already connected to
        // substations, we mark the new cable as a redundancy cable.  Since
        // `addCableLoads` requires a tree input, we need to filter out
        // existing redundancy cables, as well as manually checking which
        // cables we can convert without creating a loop in the tree.
        const [touchedRedundant, touchedRegulars] = partition(
          touchedCables,
          (c) => !!c.properties.redundancy,
        );

        const uf = new UnionFind();
        for (const c of touchedRegulars)
          uf.union(c.properties.fromId, c.properties.toId);

        const loops: CableFeature[] = [];
        const regulars: CableFeature[] = [];
        for (const c of newConnectedCables) {
          const { fromId, toId } = c.properties;
          if (uf.find(fromId) == uf.find(toId)) loops.push(c);
          else regulars.push(c);
          uf.union(fromId, toId);
        }

        try {
          const cablesWithNewLoads = addCableLoads(
            [...regulars, ...touchedRegulars],
            substations,
            turbines,
            turbineTypes,
          ).concat(
            loops.concat(touchedRedundant).map((c) => ({
              ...c,
              properties: {
                ...c.properties,
                redundancy: true,
              },
            })),
          );

          const newCables = cablesWithNewLoads.filter((cable) => {
            return newConnectedCables.some((newCable) => {
              return cable.id === newCable.id;
            });
          });

          const updatedCables = cablesWithNewLoads.filter((cable) => {
            return !newConnectedCables.some((newCable) => {
              return cable.id === newCable.id;
            });
          });

          return [newCables, updatedCables];
        } catch (error) {
          if (error instanceof Error) {
            showError(getReadableErrorMessage(error));
          }

          throw error;
        }
      },
    [showError, showWarning, splitSingle],
  );
};

export default useCreateCableSlicesBetweenTurbinesAndSubstations;
