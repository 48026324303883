import { selectorFamily } from "recoil";
import { CostType } from "../../../../services/costService";
import { libraryAndProjectCostConfigurationsSelectorFamily } from "../../../../state/costConfigurations";
import { currentMooringLineTypesState } from "../../../../state/mooringLineType";
import {
  _CostUnit,
  CostUnit,
  unitToAmountUnit,
} from "../../../../types/financial";
import { Cost, costId } from "../capexNumbers";
import { amountId } from "../generalAmounts";

export const mooringCostsSelectorFamily = selectorFamily<
  Cost[],
  {
    projectId: string;
    configurationId: string;
  }
>({
  key: "mooringCostsSelectorFamily",
  get:
    ({ projectId, configurationId }) =>
    ({ get }) => {
      const configurations = get(
        libraryAndProjectCostConfigurationsSelectorFamily({
          nodeId: projectId,
        }),
      );

      const mooringLineTypes = get(currentMooringLineTypesState);

      const configuration = configurations.find(
        (c) => c.id === configurationId,
      );

      if (!configuration) return [];

      const {
        capex: { custom, fixed },
      } = configuration;

      const flatCosts: Cost[] = custom
        .filter((c) => c.category === CostType.Mooring)
        .filter((c) => c.unit === CostUnit.millionEuro)
        .map((c) => ({
          ...c,
          id: costId({
            category: CostType.Foundation,
            costId: c.id,
          }),
          amountId: amountId({
            unit: unitToAmountUnit[c.unit],
            category: c.category,
          }),
        }));

      const customCosts: Cost[] = custom
        .filter((c) => c.category === CostType.Mooring)
        .filter((c) => c.unit !== CostUnit.millionEuro)
        .flatMap((custom) => ({
          ...custom,
          name: `${custom.name}`,
          id: costId({
            category: CostType.Mooring,
            costId: custom.id,
            featureTypeId: CostType.Mooring,
          }),
          amountId: amountId({
            unit: unitToAmountUnit[custom.unit],
            category: custom.category,
          }),
        }));

      let fixedCosts: Cost[] = [];

      if (fixed.mooring?.lines !== undefined) {
        const {
          lines: { cost, unit, confidenceLevel },
        } = fixed.mooring;

        fixedCosts.push({
          id: `mooring_lines_${unit}`,
          amountId: amountId({
            unit: unitToAmountUnit[unit],
            category: CostType.Mooring,
          }),
          category: CostType.Mooring,
          name: "Mooring lines",
          cost: cost,
          unit: unit,
          confidenceLevel: confidenceLevel,
        });
      } else {
        fixedCosts = mooringLineTypes.map(
          ({ id, name, cost, costUnit: _costUnit }) => {
            const costUnit = _CostUnit.parse(_costUnit);
            return {
              id: `mooring_lines_${id}`,
              amountId: amountId({
                unit: unitToAmountUnit[costUnit],
                category: CostType.Mooring,
                featureTypeId: id,
              }),
              category: CostType.Mooring,
              name,
              cost,
              unit: costUnit,
              confidenceLevel: undefined,
            };
          },
        );
      }

      if (fixed.mooring?.anchors !== undefined) {
        const {
          anchors: { cost, unit, confidenceLevel },
        } = fixed.mooring;

        fixedCosts.push({
          id: `mooring_anchors_${unit}`,
          amountId: amountId({
            unit: unitToAmountUnit[unit],
            category: CostType.Mooring,
            featureTypeId: "anchors",
          }),
          category: CostType.Mooring,
          name: "Anchors",
          cost: cost,
          unit: unit,
          confidenceLevel: confidenceLevel,
        });
      }

      if (fixed.mooring?.clumpWeights !== undefined) {
        const {
          clumpWeights: { cost, unit, confidenceLevel },
        } = fixed.mooring;

        fixedCosts.push({
          id: `mooring_clump_weights_${unit}`,
          amountId: amountId({
            unit: unitToAmountUnit[unit],
            category: CostType.Mooring,
            featureTypeId: "clump_weights",
          }),
          category: CostType.Mooring,
          name: "Clump weights",
          cost: cost,
          unit: unit,
          confidenceLevel: confidenceLevel,
        });
      }

      if (fixed.mooring?.buoys !== undefined) {
        const {
          buoys: { cost, unit, confidenceLevel },
        } = fixed.mooring;

        fixedCosts.push({
          id: `mooring_buoys_${unit}`,
          amountId: amountId({
            unit: unitToAmountUnit[unit],
            category: CostType.Mooring,
            featureTypeId: "buoys",
          }),
          category: CostType.Mooring,
          name: "Buoys",
          cost: cost,
          unit: unit,
          confidenceLevel: confidenceLevel,
        });
      }

      return [...fixedCosts, ...customCosts, ...flatCosts];
    },
});
