/// <reference types="vite-plugin-svgr/client" />
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { fromBlob } from "geotiff";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { v4 as uuid } from "uuid";
import { useGoToFeatures } from "hooks/map";
import { useToast } from "hooks/useToast";
import ArrowLeftIcon from "@icons/24/ArrowLeft.svg?react";
import { THIRTY_MEGABYTES } from "services/projectDataAPIService";
import { uploadGeoTiffGenerator } from "services/uploadGeoTiff";
import { ProjectFeature, stripFeatureTypeSpecificFields } from "types/feature";
import { imageToGeorefAtom } from "state/georef";
import { mapRefAtom } from "state/map";
import { getParkFeaturesSelector } from "state/park";
import { parkIdSelector, projectIdSelector } from "state/pathParams";
import { spaceMedium } from "styles/space";
import {
  GeotiffType,
  SIMPLIFY_TOLERANCE,
  expandMultiFeaturesExceptMultiPolygonWithSeveralPolygons,
  simplifyToSize,
} from "utils/geojson/utils";
import { FeatureWithAnyProperties } from "types/feature";
import { lossyCompressGeotiffGenerator } from "utils/geotiff";
import eventEmitter from "utils/eventEmitter";
import { useRecoilValueDef } from "utils/recoil";
import { promiseWorker, typedWorker } from "utils/utils";
import {
  LeftModalNames,
  leftModalOpenStateAtom,
} from "components/Design/ProjectHistory/state";
import Button from "../../../../General/Button";
import { GEOREF_DONE_EVENT } from "components/GeorefImageModal/GeorefImageModal";
import { useProjectElementsCrud } from "components/ProjectElements/useProjectElementsCrud";
import { neededShpFileSuffixes } from "components/UploadFile/fileUtils";
import { hideUploadModalAtom } from "../../../state";
import {
  KMLToGeojsonFile,
  acceptedShapeFileEndings,
  getAllFilesOfTypeFromZip,
  getEPSGForGISFile,
  getFileTypeFromFileName,
  getZippedShapeFiles,
  parseFileAndCleanGeoJson,
  supportedUploadCRS,
} from "../../../utils";
import { ButtonWrapper, DropFileToUpload, UploadWrapper } from "../../shared";
import SelectedFile from "../SelectedFile";
import { FixUpload, LoadProgress, SelectedFileType } from "../types";
import AssignParkDropdown from "./AssignParkDropdown";
import ChangeTypeDropdown from "./ChangeTypeDropdown";
import NoFileSelectedWrapper from "./NoFileSelectedWrapper";
import { Mixpanel } from "mixpanel";
import { crsWhiteList } from "../CRSWhitelist";
import { modalTypeOpenAtom } from "state/modal";
import { UploadModalType } from "components/UploadModal/UploadModal";
import { PARK_PROPERTY_TYPE } from "@constants/park";
import { ABLY_MAX_SIZE } from "hooks/useCloneFeaturesWithDefaultCanvasSource";

const kmlFileEnding = ".kml";
const kmzFileEnding = ".kmz";
const acceptedProjectFeatureFileEndings = [
  ".geojson",
  ".json",
  ".zip",
  kmlFileEnding,
  kmzFileEnding,
];
const acceptedBathymetryFileEndings = [".tif", ".tiff"];
const acceptedImageFileEndings = [".jpg", ".jpeg", ".png"];

const acceptedProjectFileEndings = [
  ...acceptedProjectFeatureFileEndings,
  ...acceptedBathymetryFileEndings,
  ...acceptedImageFileEndings,
];

export const acceptedProjectFileEndingsWithShapeFiles = [
  ...acceptedProjectFileEndings,
  ...acceptedShapeFileEndings,
];

export const acceptedProjectFileEndingsWithShapeFilesWithoutZip =
  acceptedProjectFileEndingsWithShapeFiles.filter(
    (fileEnding) => fileEnding !== ".zip",
  );

const BITS_IN_BYTE = 8;
const ONE_MEGABYTE = 1024 * 1024;
const ONE_HOUNDRED_MEGABYTES = ONE_MEGABYTE * 100;
const SIXTY_MEGABYTES = 1024 * 1024 * 60;
const MAXIMUM_TOTAL_SIZE = 10485760;

async function* handleTifFileGenerator(file: File, nodeId: string) {
  // try {
  const geotiffMaybe = await fromBlob(file);
  const image = await geotiffMaybe.getImage();

  if (
    !image.geoKeys.ProjectedCSTypeGeoKey &&
    !image.geoKeys.GeographicTypeGeoKey
  ) {
    throw new Error("Something went wrong");
  }

  const geotiffIsGeotiffImage =
    [3, 4].includes(image.fileDirectory.BitsPerSample.length) &&
    Array.from(image.fileDirectory.BitsPerSample).every(
      (n) => n === BITS_IN_BYTE,
    );

  if (geotiffIsGeotiffImage) {
    return yield* uploadGeoTiffGenerator(
      file,
      nodeId,
      GeotiffType.georefimage,
      THIRTY_MEGABYTES,
      file.name,
    );
  }

  const maybeCompressedTiff = yield* lossyCompressGeotiffGenerator(
    file,
    SIXTY_MEGABYTES,
  );

  return yield* uploadGeoTiffGenerator(
    maybeCompressedTiff,
    nodeId,
    GeotiffType.bathymetry,
    ONE_HOUNDRED_MEGABYTES,
    file.name,
  );
}

const IMAGE_PROGRESS_BEFORE_GEOREF = 10;
const UploadProjectFeatures = ({
  initialFiles,
  resetInitialFiles,
  onUploadFromCoordinatesClick,
  onBackClick,
  onDoneClick,
}: {
  initialFiles: File[];
  resetInitialFiles(): void;
  onUploadFromCoordinatesClick(): void;
  onBackClick(): void;
  onDoneClick(): void;
}) => {
  const map = useRecoilValue(mapRefAtom);
  const goToFeatures = useGoToFeatures(map);
  const parkId = useRecoilValue(parkIdSelector);
  const projectId = useRecoilValueDef(projectIdSelector);
  const modalTypeOpen = useRecoilValue(modalTypeOpenAtom);
  const [selectedParkId, setSelectedParkId] = useState(parkId ?? "");
  const parks = useRecoilValue(getParkFeaturesSelector);
  const setLeftModalOpen = useSetRecoilState(leftModalOpenStateAtom);
  const { error, info } = useToast();
  const [selectedFiles, setSelectedFiles] = useState<SelectedFileType[]>([]);
  const [validatedFeatures, setValidatedFeatures] = useState<
    Array<{
      file: SelectedFileType;
      features: FeatureWithAnyProperties[];
      featureType: ProjectFeature["geometry"]["type"] | undefined;
    }>
  >([]);
  const [uploadState, setUploadState] = useState<LoadProgress[]>([]);
  const { update: updateFeatures } = useProjectElementsCrud();
  const setHideUploadModal = useSetRecoilState(hideUploadModalAtom);
  const setImageToGeoref = useSetRecoilState(imageToGeorefAtom);
  const [updateStatements, setUpdateStatements] = useState<
    | {
        [key: string]: {
          add?: ProjectFeature[];
          remove?: string[];
          update?: ProjectFeature[];
        };
      }
    | undefined
  >(undefined);

  // all features being added that have the type: "park-polygon" as well as current parks
  const potentialParks = useMemo(() => {
    const newParks = Object.keys(updateStatements ?? {}).reduce(
      (acc, fileId) => {
        const file = updateStatements?.[fileId];
        if (file?.add) {
          return [
            ...acc,
            ...file.add
              .filter(
                (feature) => feature.properties.type === PARK_PROPERTY_TYPE,
              )
              .map((f, i) => ({
                id: f.id,
                name: f.properties.name ?? `New park ${i + 1}`,
              })),
          ];
        }
        return acc;
      },
      [] as { id: string; name: string }[],
    );
    return [
      ...newParks,
      ...parks.map((p) => ({ id: p.id, name: p.properties.name ?? "park" })),
    ];
  }, [parks, updateStatements]);

  const preSelectedFeatureType =
    modalTypeOpen?.modalType === UploadModalType
      ? modalTypeOpen.metadata?.preSelectedFeatureType
      : undefined;

  const setUploadStateUsingId = useCallback(
    (
      fileId: string,
      progress: number | undefined,
      message?: string,
      error?: string,
      other?: Partial<LoadProgress>,
      intercomHelpId?: number,
      fixUpload?: FixUpload,
    ) => {
      setUploadState((curr) => {
        if (!curr.find((row) => row.id === fileId)) {
          return [
            ...curr,
            {
              id: fileId,
              error,
              message,
              progress,
              intercomHelpId,
              fixUpload,
              ...other,
            },
          ];
        }

        return curr.map((currRow) => {
          if (currRow.id === fileId) {
            return {
              ...currRow,
              error,
              message,
              progress,
              intercomHelpId,
              fixUpload,
              ...other,
            };
          }
          return currRow;
        });
      });
    },
    [],
  );

  const parsesAndValidateFile = useCallback(
    async (row: SelectedFileType) => {
      let result: Awaited<ReturnType<typeof parseFileAndCleanGeoJson>>;

      try {
        const crsCodesPerLayer = await getEPSGForGISFile(row.file);

        const unsupportedCrsCodes = crsCodesPerLayer.filter(
          (code) =>
            crsWhiteList.find((c) => parseInt(c.code) === code)?.match !== true,
        );

        if (unsupportedCrsCodes.length > 0) {
          setUploadStateUsingId(
            row.id,
            undefined,
            undefined,
            `File is using unsupported coordinate system(s) EPSG code ${unsupportedCrsCodes.join(", ")}`,
            undefined,
            supportedUploadCRS,
          );
          return;
        }
      } catch (error) {
        setUploadStateUsingId(
          row.id,
          undefined,
          undefined,
          `Error trying to read the files coordinate system.${row.file.name.endsWith(".zip") ? " Does the zip only contain one set of shape files?" : ""}`,
        );
        return;
      }

      const parseFileWorker = typedWorker<
        { file: File; accessToken?: string },
        Awaited<ReturnType<typeof parseFileAndCleanGeoJson>>
      >(
        new Worker(new URL("../../../parseFileWorker.ts", import.meta.url), {
          type: "module",
        }),
      );

      try {
        result = await promiseWorker(parseFileWorker, {
          file: row.file,
        });
      } catch (err) {
        if (err instanceof Error)
          setUploadStateUsingId(row.id, undefined, undefined, err.message);
        return;
      } finally {
        parseFileWorker.terminate();
      }

      const { cleanedWithAnyProperties, shapefileMissingEncoding } = result;

      const uploadCanvas = cleanedWithAnyProperties
        .flatMap(expandMultiFeaturesExceptMultiPolygonWithSeveralPolygons)
        .map<FeatureWithAnyProperties>((feature) => {
          const newId = uuid();
          const newProps = stripFeatureTypeSpecificFields(feature);
          return {
            ...feature,
            id: newId,
            properties: {
              ...newProps,
              id: newId,
              name: String(
                feature.properties?.name ??
                  feature.properties["Name"] ??
                  feature.properties["NAME"] ??
                  row.file.name ??
                  `Uploaded feature`,
              ),
              // color: DEFAULT_CANVAS_LAYER_COLOR,
              type: undefined,
            },
          };
        });

      if (
        new TextEncoder().encode(JSON.stringify(uploadCanvas)).length >
        MAXIMUM_TOTAL_SIZE
      ) {
        setUploadStateUsingId(
          row.id,
          undefined,
          undefined,
          "File size is bigger than 10MB",
        );
        return;
      }

      if (
        uploadCanvas.find(
          (f) =>
            new TextEncoder().encode(JSON.stringify(f)).length > ABLY_MAX_SIZE,
        )
      ) {
        setUploadStateUsingId(
          row.id,
          undefined,
          undefined,
          `Size of single feature can not exceed ${(ABLY_MAX_SIZE / 1024).toFixed(0)}kb`,
          undefined,
          undefined,
          {
            message: "Simplify features",
            callback: () => {
              const simplifiedUploadedCanvas = uploadCanvas.map(
                (f) => simplifyToSize(f, ABLY_MAX_SIZE, SIMPLIFY_TOLERANCE)[0],
              );
              return {
                ...row,
                file: new File(
                  [
                    new Blob(
                      [
                        JSON.stringify({
                          type: "FeatureCollection",
                          features: simplifiedUploadedCanvas,
                        }),
                      ],
                      {
                        type: "application/json",
                      },
                    ),
                  ],
                  row.file.name,
                ),
              };
            },
          },
        );
        return;
      }

      if (shapefileMissingEncoding) {
        error(
          "Shapefile is missing encoding information (no .cpg file). Some characters have been removed.",
          { timeout: 10000 },
        );
      }

      return uploadCanvas;
    },
    [error, setUploadStateUsingId],
  );

  const onParkIdChanged = useCallback(
    (parkId: string) => {
      if (parkId === "") {
        setUpdateStatements(
          validatedFeatures.reduce((acc, curr) => {
            return {
              ...acc,
              [curr.file.id]: { add: curr.features },
            };
          }, {}),
        );
      } else {
        setUpdateStatements(
          (cur) =>
            cur &&
            Object.keys(cur).reduce((acc, fileId) => {
              const statement = cur[fileId];
              return {
                ...acc,
                [fileId]: {
                  add: statement.add?.map((feature) =>
                    feature.properties.type === PARK_PROPERTY_TYPE
                      ? feature
                      : {
                          ...feature,
                          properties: {
                            ...feature.properties,
                            parentIds: [parkId],
                          },
                        },
                  ),
                  update: statement.update?.map((feature) =>
                    feature.properties.type === PARK_PROPERTY_TYPE
                      ? feature
                      : {
                          ...feature,
                          properties: {
                            ...feature.properties,
                            parentIds: [parkId],
                          },
                        },
                  ),
                },
              };
            }, {}),
        );
      }
      setSelectedParkId(parkId);
    },
    [validatedFeatures, setUpdateStatements],
  );

  const uploadTifFile = useCallback(
    async (file: SelectedFileType) => {
      try {
        if (!projectId) return;
        setUploadStateUsingId(file.id, 0, "Uploading");
        const generator = handleTifFileGenerator(file.file, projectId);
        let feature = await generator.next();
        while (!feature.done) {
          setUploadStateUsingId(file.id, 10, feature.value);
          feature = await generator.next();
        }
        const next = feature.value;
        setUpdateStatements((cur) => ({
          ...cur,
          [file.id]: { add: [next] },
        }));
        setUploadStateUsingId(file.id, 100, "Done", undefined);
        return feature.value;
      } catch (error) {
        if (error instanceof Error)
          setUploadStateUsingId(file.id, undefined, undefined, error.message);
      }
    },
    [projectId, setUploadStateUsingId],
  );

  const uploadShpGeojson = useCallback(
    async (file: SelectedFileType) => {
      setUploadStateUsingId(file.id, 0, "Validating");
      const parsedFeatures = await parsesAndValidateFile(file);
      if (parsedFeatures) {
        const uniqueFeatureTypes = [
          ...new Set(parsedFeatures.map((feature) => feature.geometry.type)),
        ];
        setValidatedFeatures((curr) => [
          ...curr,
          {
            file: file,
            features: parsedFeatures,
            featureType:
              uniqueFeatureTypes.length === 1
                ? uniqueFeatureTypes[0]
                : undefined,
          },
        ]);
        setUpdateStatements((cur) => ({
          ...cur,
          [file.id]: { add: parsedFeatures },
        }));
        setUploadStateUsingId(file.id, 100, "Done");
        return parsedFeatures;
      }
    },
    [setUploadStateUsingId, parsesAndValidateFile],
  );

  const uploadFiles = useCallback(
    async (files: SelectedFileType[]) => {
      const addedFeatures: ProjectFeature[] = [];
      for (const file of files) {
        const suffix = getFileTypeFromFileName(file.file.name);

        if (acceptedBathymetryFileEndings.includes(`.${suffix}`)) {
          const feature = await uploadTifFile(file);
          if (feature) {
            addedFeatures.push(feature);
          }
        } else if (acceptedImageFileEndings.includes(`.${suffix}`)) {
          // Image
          setUploadStateUsingId(
            file.id,
            IMAGE_PROGRESS_BEFORE_GEOREF,
            undefined,
            undefined,
            {
              waitingMessage: "Please georef image:",
            },
          );
        } else {
          const features = await uploadShpGeojson(file);
          if (features) {
            addedFeatures.push(...features);
          }
        }
      }
      return addedFeatures;
    },
    [setUploadStateUsingId, uploadShpGeojson, uploadTifFile],
  );

  const handleNewFiles = useCallback(
    async (files: File[]) => {
      const zipFiles = await Promise.all(
        files
          .filter((file) => file.name.endsWith(".zip"))
          .map((file) =>
            getAllFilesOfTypeFromZip(
              file,
              acceptedProjectFileEndingsWithShapeFilesWithoutZip,
            ),
          )
          .flat(),
      );
      const zippedKMLFiles = (
        await Promise.all(
          files
            .filter((file) => file.name.endsWith(kmzFileEnding))
            .map((file) => getAllFilesOfTypeFromZip(file, [kmlFileEnding])),
        )
      ).flat();
      const kmlFilesAsGeojson = await Promise.all(
        [...files, ...zippedKMLFiles]
          .filter((file) => file.name.endsWith(kmlFileEnding))
          .map((file) => KMLToGeojsonFile(file))
          .flat(),
      );
      const nonZipKmlFiles = files.filter(
        (file) =>
          !file.name.endsWith(".zip") &&
          !file.name.endsWith(kmlFileEnding) &&
          !file.name.endsWith(kmzFileEnding),
      );
      const allFiles = nonZipKmlFiles
        .concat(...zipFiles)
        .concat(...kmlFilesAsGeojson);

      const filesTooLarge = allFiles.filter(
        (file) =>
          acceptedProjectFeatureFileEndings.includes(
            `.${getFileTypeFromFileName(file.name)}`,
          ) && file.size > MAXIMUM_TOTAL_SIZE,
      );
      const filesWithinFileSize = allFiles.filter(
        (f) => !filesTooLarge.includes(f),
      );

      if (filesTooLarge.length !== 0) {
        error(
          `Some files are too large and have been removed, maximum 10 megabytes allowed\n\n${filesTooLarge.map((f) => `${f.name}: ${Math.round(f.size / ONE_MEGABYTE)}mb\n`)}`,
          {
            timeout: 8000,
          },
        );
      }

      const acceptableFilesExceptShapeFiles = filesWithinFileSize.filter(
        (file) => {
          const fileSuffix = getFileTypeFromFileName(file.name);
          return acceptedProjectFileEndings.includes(
            `.${fileSuffix.toLowerCase()}`,
          );
        },
      );

      const [zippedShapeFiles, invalidShpNames] =
        await getZippedShapeFiles(allFiles);

      const allAcceptableFiles = acceptableFilesExceptShapeFiles.concat(
        ...zippedShapeFiles,
      );

      if (invalidShpNames.length !== 0) {
        info(
          `file(s) "${invalidShpNames.join(
            ",",
          )}" needs corresponding shp,${neededShpFileSuffixes.join(
            ",",
          )} files to be able to be imported`,
          { timeout: 10000 },
        );
      }

      const newSelectedFiles = allAcceptableFiles.map((file) => ({
        file,
        id: uuid(),
      }));
      setSelectedFiles((currFiles) => [...currFiles, ...newSelectedFiles]);

      uploadFiles(newSelectedFiles).then((addedFeatures) => {
        goToFeatures(addedFeatures);
        setLeftModalOpen(LeftModalNames.ProjectElements);
      });
    },
    [goToFeatures, info, setLeftModalOpen, uploadFiles, error],
  );

  useEffect(() => {
    if (initialFiles.length > 0) {
      handleNewFiles(initialFiles);
      resetInitialFiles();
    }
  }, [handleNewFiles, initialFiles, resetInitialFiles]);

  return (
    <UploadWrapper>
      <DropFileToUpload
        acceptedFileTypes={acceptedProjectFileEndingsWithShapeFiles}
        handleNewFiles={handleNewFiles}
      >
        <Button
          buttonType="secondary"
          text="Use coordinates"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            Mixpanel.track("Clicked Use coordinates in upload modal", {});
            onUploadFromCoordinatesClick();
          }}
        />
      </DropFileToUpload>

      {selectedFiles.length === 0 && validatedFeatures.length === 0 ? (
        <NoFileSelectedWrapper />
      ) : (
        <div
          style={{ display: "flex", flexDirection: "column", gap: spaceMedium }}
        >
          {potentialParks.length > 0 && validatedFeatures.length > 0 && (
            <AssignParkDropdown
              selectedParkId={selectedParkId}
              setSelectedParkId={onParkIdChanged}
              parks={potentialParks}
            />
          )}
          {selectedFiles.map((row) => {
            const fileSuffix = getFileTypeFromFileName(row.file.name);
            const loadProgress = uploadState.find((load) => load.id === row.id);
            const validatedFeature = validatedFeatures.find(
              (validated) => validated.file.id === row.id,
            );
            const isImage = acceptedImageFileEndings.includes(`.${fileSuffix}`);
            return (
              <SelectedFile
                key={row.id}
                fileName={row.file.name}
                fileSize={row.file.size}
                loadProgress={loadProgress}
                uploadShpGeojson={uploadShpGeojson}
              >
                {isImage &&
                  loadProgress?.progress === IMAGE_PROGRESS_BEFORE_GEOREF && (
                    <Button
                      buttonType="primary"
                      text="Georef image"
                      onClick={() => {
                        setImageToGeoref(row.file);
                        setHideUploadModal(true);
                        eventEmitter.once(
                          GEOREF_DONE_EVENT,
                          (done: boolean) => {
                            setHideUploadModal(false);
                            if (done) {
                              setUploadStateUsingId(
                                row.id,
                                100,
                                undefined,
                                undefined,
                                {
                                  waitingMessage: undefined,
                                },
                              );
                            }
                          },
                        );
                      }}
                    />
                  )}
                {validatedFeature && (
                  <>
                    <ChangeTypeDropdown
                      row={validatedFeature}
                      selectedParkId={selectedParkId}
                      setUpdateStatement={(statement) => {
                        setUpdateStatements((cur) => ({
                          ...cur,
                          [row.id]: statement,
                        }));
                      }}
                      preSelectedFeatureType={preSelectedFeatureType}
                    />
                  </>
                )}
              </SelectedFile>
            );
          })}
        </div>
      )}
      <ButtonWrapper>
        <Button
          buttonType="text"
          text="Back"
          icon={<ArrowLeftIcon />}
          onClick={onBackClick}
          style={{
            paddingLeft: 0,
          }}
        />
        <ButtonWrapper>
          <Button text="Close" buttonType="text" onClick={onDoneClick} />
          <Button
            buttonType="primary"
            text="Done"
            onClick={async () => {
              if (updateStatements) {
                // combine all update statements into one
                const combined = Object.values(updateStatements).reduce(
                  (acc, cur) => {
                    const add = cur.add ?? [];
                    const remove = cur.remove ?? [];
                    const update = cur.update ?? [];
                    return {
                      add: [...(acc.add ?? []), ...add],
                      update: [...(acc.update ?? []), ...update],
                      remove: [...(acc.remove ?? []), ...remove],
                    };
                  },
                  { add: [], remove: [], update: [] },
                );

                await updateFeatures(combined);
              }
              onDoneClick();
            }}
            disabled={uploadState.some(
              (state) => !state.error && state.progress !== 100,
            )}
          />
        </ButtonWrapper>
      </ButtonWrapper>
    </UploadWrapper>
  );
};

export default UploadProjectFeatures;
