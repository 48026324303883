import { ProductionHistograms } from "functions/production";
import { ElectricalConfig, EnergyLoss } from "services/configurationService";
import { ExportLossType, IALossType } from "state/electrical";
import { TurbineFeature } from "types/feature";
import { sum } from "utils/utils";
import { HOURS_PER_YEAR } from "@constants/production";
import { SimpleTurbineType } from "types/turbines";
import { TurbineStat } from "./types";
import { linearInterpolation } from "./utils";

export type TotalInterArrayLoss = {
  totalInterArrayLoss: number | null | undefined;
  totalInterArrayCableLoss: number | null | undefined;
  totalTurbineTrafoLoss: number | null | undefined;
};
/**
 * Compute the total inter array loss in % of the net mean power.
 *
 * @param productionHistograms
 * @param interArrayLosses
 * @param configuration
 * @returns `undefined` if any of the parameters is `undefined`, otherwise the
 * total inter array loss, total inter array cable loss, and total turbine trafo
 * loss in % of the net mean power.
 */
export function computeTotalInterArrayLoss(
  productionHistograms?: ProductionHistograms,
  interArrayLosses?: IALossType,
  configuration?: ElectricalConfig,
  electricalPowerBins?: number[],
): {
  totalInterArrayLoss: number | null | undefined;
  totalInterArrayCableLoss: number | null | undefined;
  totalTurbineTrafoLoss: number | null | undefined;
} {
  if (
    !productionHistograms ||
    !interArrayLosses ||
    !configuration ||
    !electricalPowerBins
  )
    return {
      totalInterArrayLoss: undefined,
      totalInterArrayCableLoss: undefined,
      totalTurbineTrafoLoss: undefined,
    };

  if (
    !interArrayLosses.results ||
    (!configuration.interArrayCableLoss && !configuration.turbineTrafoLoss)
  )
    return {
      totalInterArrayLoss: null,
      totalInterArrayCableLoss: null,
      totalTurbineTrafoLoss: null,
    };

  const netProbabilities = productionHistograms.netProduction;
  const powerBins = productionHistograms.bins;
  const powerBinsMidPoints = powerBins
    .slice(0, -1)
    .map((b, i) => (b + powerBins[i + 1]) / 2);
  const netMeanPowerMW = netProbabilities.reduce((acc, p, i) => {
    return (acc += 1000 * powerBinsMidPoints[i] * p);
  }, 0);
  const electricalBinsMidPoints = electricalPowerBins
    .slice(0, -1)
    .map((b, i) => (b + electricalPowerBins[i + 1]) / 2);
  const cableLossPerCase = interArrayLosses.results.IACableLossPerCase.map(
    (loss, i) => [electricalBinsMidPoints[i], loss],
  ) as [number, number][];
  const turbineTrafoLossPerCase =
    interArrayLosses.results.turbineTrafoLossPerCase.map((loss, i) => [
      electricalBinsMidPoints[i],
      loss,
    ]) as [number, number][];
  const cableLossPerCaseInterp = linearInterpolation(
    cableLossPerCase,
    powerBinsMidPoints,
  );
  const turbineTrafoLossPerCaseInterp = linearInterpolation(
    turbineTrafoLossPerCase,
    powerBinsMidPoints,
  );
  const totalIACableLossMW = sum(
    cableLossPerCaseInterp,
    (loss, i) => loss * netProbabilities[i],
  );
  const totalTurbineTrafoLossMW = sum(
    turbineTrafoLossPerCaseInterp,
    (loss, i) => loss * netProbabilities[i],
  );

  const summedTotalIACableLoss = configuration.interArrayCableLoss
    ? totalIACableLossMW / netMeanPowerMW
    : 0;
  const summedTotalTurbineTrafoLoss = configuration.turbineTrafoLoss
    ? totalTurbineTrafoLossMW / netMeanPowerMW
    : 0;
  const totalInterArrayCableLoss = configuration.interArrayCableLoss
    ? summedTotalIACableLoss
    : null;
  const totalTurbineTrafoLoss = configuration.turbineTrafoLoss
    ? summedTotalTurbineTrafoLoss
    : null;
  const totalInterArrayLoss =
    summedTotalIACableLoss + summedTotalTurbineTrafoLoss;

  return {
    totalInterArrayLoss,
    totalInterArrayCableLoss,
    totalTurbineTrafoLoss,
  };
}

export type TotalExportSystemLoss = {
  totalExportSystemLoss: number | null | undefined;
  totalExportCableLoss: number | null | undefined;
  totalOffshoreTrafoLoss: number | null | undefined;
  totalOnshoreTrafoLoss: number | null | undefined;
};
/**
 * Compute the mean hourly inter array loss per cable in MW.
 *
 * @param productionHistograms
 * @param interArrayLosses
 * @param configuration
 * @returns `undefined` if any of the parameters is `undefined`, otherwise the
 * mean hourly inter array loss per cable in MW.
 */
export function computeWeightedInterArrayLossPerCable(
  productionHistograms?: ProductionHistograms,
  interArrayLosses?: IALossType,
  configuration?: ElectricalConfig,
): Record<string, number> | undefined {
  if (
    !productionHistograms ||
    !configuration ||
    !interArrayLosses?.results?.IACableLossPerCable
  )
    return;

  const netProbabilities = productionHistograms.netProduction;

  const lossPerCable = interArrayLosses.results.IACableLossPerCable.reduce(
    (acc, loss) => {
      const { cableId, cableLossMW, bin } = loss;

      const p = netProbabilities[bin];

      const weightedLoss = cableLossMW * p;

      acc[cableId] = acc[cableId] ? acc[cableId] + weightedLoss : weightedLoss;

      return acc;
    },
    {} as Record<string, number>,
  );

  return lossPerCable;
}

/**
 * Compute the total export system loss in % of the net mean power.
 *
 * @param productionHistograms
 * @param exportSystemLoss
 * @param configuration
 * @returns `undefined` if any of the parameters is `undefined`, otherwise the
 * total export system loss, total export cable loss, total offshore trafo loss,
 * and total onshore trafo loss in % of the net mean power.
 */
export function calcTotalExportSystemLoss(
  productionHistograms?: ProductionHistograms,
  exportSystemLosses?: ExportLossType,
  configuration?: ElectricalConfig,
  electricalPowerBins?: number[],
): TotalExportSystemLoss | undefined {
  if (
    !productionHistograms ||
    !exportSystemLosses ||
    !configuration ||
    !electricalPowerBins
  )
    return undefined;

  if (!exportSystemLosses.results || !configuration.exportSystemLoss)
    return undefined;

  const netProbabilities = productionHistograms.netProduction;
  const powerBins = productionHistograms.bins;
  const powerBinsMidPoints = powerBins
    .slice(0, -1)
    .map((b, i) => (b + powerBins[i + 1]) / 2);
  const netMeanPowerMW = netProbabilities.reduce((acc, p, i) => {
    return (acc += 1000 * powerBinsMidPoints[i] * p);
  }, 0);
  const electricalBinsMidPoints = electricalPowerBins
    .slice(0, -1)
    .map((b, i) => (b + electricalPowerBins[i + 1]) / 2);
  const cableLossPerCase =
    exportSystemLosses.results.exportCableLossPerCase.map((loss, i) => [
      electricalBinsMidPoints[i],
      loss,
    ]) as [number, number][];
  const offshoreTrafoLossPerCase =
    exportSystemLosses.results.offshoreTrafoLossPerCase.map((loss, i) => [
      electricalBinsMidPoints[i],
      loss,
    ]) as [number, number][];
  const onshoreTrafoLossPerCase =
    exportSystemLosses.results.onshoreTrafoLossPerCase.map((loss, i) => [
      electricalBinsMidPoints[i],
      loss,
    ]) as [number, number][];
  const cableLossPerCaseInterp = linearInterpolation(
    cableLossPerCase,
    powerBinsMidPoints,
  );
  const offshoreTrafoLossPerCaseInterp = linearInterpolation(
    offshoreTrafoLossPerCase,
    powerBinsMidPoints,
  );
  const onshoreTrafoLossPerCaseInterp = linearInterpolation(
    onshoreTrafoLossPerCase,
    powerBinsMidPoints,
  );
  const totalExportCableLossMW = sum(
    cableLossPerCaseInterp,
    (loss, i) => loss * netProbabilities[i],
  );
  const totalOffshoreTrafoLossMW = sum(
    offshoreTrafoLossPerCaseInterp,
    (loss, i) => loss * netProbabilities[i],
  );
  const totalOnshoreTrafoLossMW = sum(
    onshoreTrafoLossPerCaseInterp,
    (loss, i) => loss * netProbabilities[i],
  );

  const totalExportCableLoss = totalExportCableLossMW / netMeanPowerMW;
  const totalOffshoreTrafoLoss = totalOffshoreTrafoLossMW / netMeanPowerMW;
  const totalOnshoreTrafoLoss = totalOnshoreTrafoLossMW / netMeanPowerMW;
  const totalExportSystemLoss =
    totalExportCableLoss + totalOffshoreTrafoLoss + totalOnshoreTrafoLoss;

  return {
    totalExportSystemLoss,
    totalExportCableLoss,
    totalOffshoreTrafoLoss,
    totalOnshoreTrafoLoss,
  };
}

export const computeInternalWakeLoss = (
  grossPerTurbine: number[],
  internalWakeLossPerTurbine: number[],
  gross: number,
): number => {
  const netWithoutNeighbours = sum(
    grossPerTurbine,
    (g, i) => g * (1 - internalWakeLossPerTurbine[i]),
  );
  const internalWakeLoss = 1 - netWithoutNeighbours / gross;
  return internalWakeLoss;
};

/**
 * Computes the total loss for a series of `EnergyLoss`es.
 */
export const computeOtherLosses = (energyLosses: EnergyLoss[]): number => {
  const efficiency = energyLosses.reduce((t, l) => (t *= 1 - l.value), 1);
  return 1 - efficiency;
};

export const computeGrossEnergyProductionPerTurbine = (
  grossPerTurbine: number[],
  turbineLosses: number[],
  totalWakeLossPerTurbine: number[],
  turbines: TurbineFeature[],
): TurbineStat[] => {
  return grossPerTurbine.map((g, i) => {
    const value = g * (1 - turbineLosses[i]) * (1 - totalWakeLossPerTurbine[i]);
    return { turbine: turbines[i], value };
  });
};

export const computePostWakeEnergyProductionPerTurbine = (
  grossPerTurbine: number[],
  totalWakeLossPerTurbine: number[],
  turbines: TurbineFeature[],
): TurbineStat[] => {
  return grossPerTurbine.map((g, i) => {
    const value = g * (1 - totalWakeLossPerTurbine[i]);
    return { turbine: turbines[i], value };
  });
};

export const computeEfficiency = (
  otherLoss: number,
  totalInterArrayLoss: number | null,
  totalExportSystemLoss: number | null,
): number =>
  (1 - otherLoss) *
  (1 - (totalInterArrayLoss ?? 0)) *
  (1 - (totalExportSystemLoss ?? 0));

/**
 *
 * @param aepPerTurbine
 * @param otherLoss This should be the result of {@link computeOtherLosses}.
 * @param totalInterArrayLoss
 * @param totalExportSystemLoss
 * @returns
 */
export const computeAEP = (
  aepPerTurbine: TurbineStat[],
  otherLoss: number,
  totalInterArrayLoss: number | null,
  totalExportSystemLoss: number | null,
): number => {
  const efficiency = computeEfficiency(
    otherLoss,
    totalInterArrayLoss,
    totalExportSystemLoss,
  );
  return sum(aepPerTurbine, (a) => a.value * efficiency);
};

export const computeLoss = (
  grossPerTurbine: number[],
  totalLossPerTurbine: number[],
  gross: number,
) => {
  const net = sum(grossPerTurbine, (g, i) => g * (1 - totalLossPerTurbine[i]));
  return 1 - net / gross;
};

export const computeCapacityFactor = (aep: number, capacity: number): number =>
  (1e3 * aep) / (capacity * HOURS_PER_YEAR);

export const computeCapacity = (
  turbineTypes: SimpleTurbineType[],
  turbines: TurbineFeature[],
): number => {
  const ratedPowersMW = Object.fromEntries(
    turbineTypes.map((t) => [t.id, t.ratedPower / 1e3]),
  );
  return sum(turbines, (t) => ratedPowersMW[t.properties.turbineTypeId]);
};
