/// <reference types="vite-plugin-svgr/client" />
import { useRecoilValue } from "recoil";
import { useTypedPath } from "../../state/pathParams";
import { projectFeaturesSelector } from "../ProjectElements/state";
import { useProjectElementsCrud } from "../ProjectElements/useProjectElementsCrud";
import { useCallback } from "react";
import { trackCanvasOption } from "./MenuTracking";
import Split from "@icons/24/Split.svg?react";
import { multiFeatureToFeatures } from "../../utils/geojson/utils";
import { isMultiFeature } from "../../utils/predicates";
import { IconBtn } from "components/General/Icons";

const SplitMultiPart = ({ featureIds }: { featureIds: string[] }) => {
  const { projectId, branchId } = useTypedPath("projectId", "branchId");
  const projectData = useRecoilValue(projectFeaturesSelector);
  const { update: updateFeatures } = useProjectElementsCrud();
  const splitMultiFeaturesIntoParts = useCallback(() => {
    trackCanvasOption("split-feature", {
      projectId,
      branchId,
    });
    const multiFeatures = projectData
      .filter((p) => featureIds.includes(p.id))
      .filter(isMultiFeature);
    if (multiFeatures.length === 0) return;

    const splitFeatures = multiFeatures.flatMap((multiFeature) =>
      multiFeatureToFeatures(multiFeature),
    );

    updateFeatures({
      add: splitFeatures,
      remove: multiFeatures.map((f) => f.id),
    });
  }, [projectId, branchId, projectData, updateFeatures, featureIds]);
  return (
    <>
      <IconBtn onClick={splitMultiFeaturesIntoParts}>
        <Split />
      </IconBtn>
    </>
  );
};

export default SplitMultiPart;
