import { RotorTowerData } from "./types";

export function rotorTowerSizing(rotorDiameter: number): RotorTowerData {
  //Estimates RNA and tower masses, tower dimensions, and rated rotor speed for the turbine given its diameter. Based on curve fitting results from the research article "Review of scaling laws applied to floating offshore wind turbines"
  const maxRotorSpeed = 1561 / rotorDiameter / 60; // Hz

  const towerBaseDiameter = 0.032 * rotorDiameter + 2.23;
  const towerTopDiameter = towerBaseDiameter - 0.012 * rotorDiameter - 0.84;
  const towerBaseThickness =
    (towerBaseDiameter + 0.188 * rotorDiameter - 2.23) / 1000;
  const towerTopThickness =
    (towerBaseDiameter + 0.088 * rotorDiameter - 2.23) / 1000;

  const rnaMass = 18.76 * Math.pow(rotorDiameter, 2);
  const towerMass = 18.63 * Math.pow(rotorDiameter, 2);

  return {
    maxRotorSpeed,
    towerBaseDiameter,
    towerTopDiameter,
    towerBaseThickness,
    towerTopThickness,
    rnaMass,
    towerMass,
  };
}
