/// <reference types="vite-plugin-svgr/client" />
import { useRecoilValue, useSetRecoilState } from "recoil";
import Close from "@icons/24/Close.svg?react";
import Add from "@icons/24/Add.svg?react";
import { NewSnapshotButtonWrapper, CloseContainer } from "./styles";
import { SkeletonText } from "../../Loading/Skeleton";
import Button from "../../General/Button";
import { modalTypeOpenAtom } from "../../../state/modal";
import {
  BranchSnapshotModalHooks,
  BranchSnapshotModalType,
} from "../DesignNavigation/TimelineModals";
import { editorAccessProjectSelector } from "../../../state/user";

export default function NewSnapshotButton({
  hooks,
}: {
  hooks?: BranchSnapshotModalHooks;
}) {
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const editorAccessProject = useRecoilValue(editorAccessProjectSelector);
  return (
    <NewSnapshotButtonWrapper>
      {editorAccessProject && (
        <Button
          onClick={() =>
            setModalTypeOpen({
              modalType: BranchSnapshotModalType,
              metadata: { hooks },
            })
          }
          buttonType={"secondary"}
          icon={<Add />}
          text={"Add new event"}
        />
      )}
    </NewSnapshotButtonWrapper>
  );
}

export const NewSnapshotButtonPlaceholder = () => {
  return (
    <NewSnapshotButtonWrapper>
      <SkeletonText />
      <CloseContainer onClick={() => {}}>
        <Close />
      </CloseContainer>
    </NewSnapshotButtonWrapper>
  );
};
