import { useRecoilCallback } from "recoil";
import {
  ABLY_THREADS_ADD_FILTER,
  ABLY_THREADS_REMOVE_FILTER,
} from "../../../state/ably";
import { useTypedPath } from "../../../state/pathParams";
import {
  postThread,
  deleteThread,
  postConditionalThreadFollow,
} from "../service";
import { followThreadsAtomFamily, threadsAtomFamily } from "../state";
import { Thread } from "../types";
import { useReadComments } from "../UnreadComments/useReadComments";
import { initializeAndSet } from "./useReplyReactionCrud";
import { useAblyPublish } from "../../../hooks/useAblyPublish";

export const useThreadCrud = () => {
  const { projectId: projectNodeId, branchId } = useTypedPath(
    "projectId",
    "branchId",
  );

  const ablyPublish = useAblyPublish();
  const { removeRead } = useReadComments();

  const post = useRecoilCallback(
    ({ set }) =>
      async (data: {
        message: string;
        featureId?: string;
        mapPosition?: [number, number];
      }) => {
        if (!projectNodeId) return;
        const res = await postThread(projectNodeId, branchId, data);
        set(threadsAtomFamily({ nodeId: projectNodeId, branchId }), (cur) => {
          return [...cur, res];
        });
        ablyPublish(`${projectNodeId}:all`, ABLY_THREADS_ADD_FILTER, res);
        try {
          const followResponse = await postConditionalThreadFollow(
            projectNodeId,
            branchId,
            res.threadId,
          );
          set(
            followThreadsAtomFamily({ nodeId: projectNodeId, branchId }),
            (cur) => {
              return [...cur, followResponse];
            },
          );
        } catch (e) {
          console.log(e);
        }
        return res;
      },
    [ablyPublish, branchId, projectNodeId],
  );

  const remove = useRecoilCallback(
    ({ set }) =>
      async (threadId: string) => {
        if (!projectNodeId) return;
        set(threadsAtomFamily({ nodeId: projectNodeId, branchId }), (cur) => {
          return cur.filter((c) => c.threadId !== threadId);
        });
        const res = await deleteThread(projectNodeId, branchId, threadId);
        ablyPublish(`${projectNodeId}:all`, ABLY_THREADS_REMOVE_FILTER, {
          threadId,
        });

        removeRead(threadId);
        return res;
      },
    [ablyPublish, projectNodeId, branchId, removeRead],
  );

  const localPost = useRecoilCallback(
    ({ set, snapshot }) =>
      async (thread: Thread) => {
        if (!projectNodeId) return;
        initializeAndSet(
          snapshot,
          set,
          threadsAtomFamily({ nodeId: projectNodeId, branchId }),
          (cur) => {
            return [...cur, thread];
          },
        );
      },
    [branchId, projectNodeId],
  );

  const localRemove = useRecoilCallback(
    ({ set, snapshot }) =>
      async (threadId: string) => {
        if (!projectNodeId) return;
        initializeAndSet(
          snapshot,
          set,
          threadsAtomFamily({ nodeId: projectNodeId, branchId }),
          (cur) => {
            return cur.filter((c) => c.threadId !== threadId);
          },
        );
        removeRead(threadId);
      },
    [branchId, projectNodeId, removeRead],
  );

  return { post, remove, localPost, localRemove };
};
