import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import * as turf from "@turf/turf";
import { useDrawMode } from "components/MapControls/useActivateDrawMode";
import { TopRightMenuOptions } from "../../constants/canvas";
import { MAX_AREA, MIN_AREA } from "../../constants/park";
import {
  GenerateWindTurbinesAndFoundationsMenuType,
  TURBINE_PROPERTY_TYPE,
} from "../../constants/projectMapView";
import { useClickOutside } from "../../hooks/useClickOutside";
import { getDivisionFeaturesSelectorFamily } from "../../state/division";
import { getParkFeatureSelectorFamily, inPark } from "../../state/park";
import {
  currentSelectedProjectFeatures,
  currentSelectionArrayAtom,
} from "../../state/selection";
import { SkeletonText } from "../Loading/Skeleton";
import {
  _AnchorFeature,
  _MooringLineFeature,
  SubAreaFeature,
  ParkFeature,
  ProjectFeature,
} from "../../types/feature";
import { differenceAll } from "../../utils/turf";
import { count, dedup, undefMap } from "../../utils/utils";
import Dropdown from "../Dropdown/Dropdown";
import { Label } from "../General/Form";
import { Column, Row } from "../General/Layout";
import { ARTICLE_LAYOUT_GEN, HelpLink } from "../HelpTooltip/HelpTooltip";
import { MenuFrame } from "../MenuPopup/CloseableMenuPopup";
import { GenerationSettings } from "./GenerationSettings";
import {
  branchIdSelector,
  parkIdSelector,
  projectIdSelector,
} from "state/pathParams";
import Toggle, { ToggleSize } from "components/General/Toggle";
import useBooleanState from "hooks/useBooleanState";
import { Mode } from "components/GenerateFoundationsAndAnchors/GenerateFoundationAnchorMenu";
import Button from "components/General/Button";
import { spaceLarge, spaceMedium, spaceSmall } from "styles/space";
import {
  turbinesAndFoundationsGenerationIsLiveAtom,
  methodAndParametersForRegionAtomFamily,
  combinedGenMooringParameters,
} from "components/GenerateWindTurbines/state";
import { colors } from "styles/colors";
import {
  allFoundationTypesSelector,
  defaultFoundations,
  getMostCommonFoundationIdInZone,
  isFixedFoundationSelector,
  isFloatingFoundationSelector,
} from "state/foundations";
import {
  getMooringParametersAtomFamily,
  previewMooringAndFoundationState,
} from "components/GenerateFoundationsAndAnchors/state";
import { projectFeaturesSelector } from "components/ProjectElements/state";
import { isAnchor, isMooringLine } from "utils/predicates";
import { useProjectElementsCrud } from "components/ProjectElements/useProjectElementsCrud";
import { useTurbineGeneration } from "components/GenerateWindTurbines/useGenerateAndSave";
import { previewTurbinesState } from "state/turbines";
import { DEFAULT_TURBINES, SimpleTurbineType } from "types/turbines";
import { FoundationType } from "types/foundations";
import { MooringInner } from "components/GenerateFoundationsAndAnchors/MooringInner";
import { FixedFoundationInner } from "components/GenerateFoundationsAndAnchors/FixedFoundationInner";
import {
  getFixedTurbines,
  getFloatingTurbines,
} from "components/GenerateFoundationsAndAnchors/utils";
import { FoundationWarnings } from "components/GenerateFoundationsAndAnchors/shared";
import { useBathymetryRaster } from "hooks/bathymetry";
import { Raster } from "types/raster";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useToast } from "hooks/useToast";
import {
  SubtitleWithLine,
  Divider,
  InputTitle,
} from "components/General/GeneralSideModals.style";
import { FoundationSettings } from "components/GenerateFoundationsAndAnchors/FoundationSettings";
import { TourStep } from "components/OnboardingTours/TourStep";
import { useTrackEvent } from "components/OnboardingTours/state";
import { HighlightStep } from "components/OnboardingTours/HighlightWrapper";
import { useShowScrollShadow } from "hooks/useShowScrollShadow";
import {
  FloatingFocusManager,
  useFloating,
  offset,
  ReferenceType,
} from "@floating-ui/react";
import { IconBtn } from "components/General/Icons";
import OpenRight from "@icons/24/OpenWindowRight.svg";

export const CombinedFoundationInner = ({
  park,
  live,
  raster,
  foundation,
  setCurrentFoundationId,
}: {
  park: ParkFeature;
  live: boolean;
  raster: Raster;
  foundation: FoundationType;
  setCurrentFoundationId: (id: string) => void;
}) => {
  const turbinesPreviewState = useRecoilValue(previewTurbinesState);
  const previewTurbines = useMemo(
    () => turbinesPreviewState?.preview ?? [],
    [turbinesPreviewState?.preview],
  );
  const { scrollBodyRef } = useShowScrollShadow(true);

  const mode: Mode = useMemo(
    () => ({
      mode: "turbines",
      turbines: previewTurbines,
    }),
    [previewTurbines],
  );

  const selectedTypeCounts = count(
    previewTurbines.map((f) => f.properties.type),
  );
  const selectedTypes = [...selectedTypeCounts.keys()];
  selectedTypes.sort();

  if (selectedTypes.length === 0) {
    selectedTypes.push(TURBINE_PROPERTY_TYPE);
  }

  const getIsFloatingFoundation = useRecoilCallback(
    ({ snapshot }) =>
      (foundationId: string) => {
        return snapshot
          .getLoadable(
            isFloatingFoundationSelector({ foundationTypeId: foundationId }),
          )
          .getValue();
      },
    [],
  );

  const getIsFixedFoundation = useRecoilCallback(
    ({ snapshot }) =>
      (foundationId: string) => {
        return snapshot
          .getLoadable(
            isFixedFoundationSelector({ foundationTypeId: foundationId }),
          )
          .getValue();
      },
    [],
  );

  const floatingTurbines = getFloatingTurbines({
    turbineFeatures: previewTurbines,
    raster,
    currentFoundation: foundation,
  });
  const fixedTurbines = getFixedTurbines({
    turbineFeatures: previewTurbines,
    raster,
    currentFoundation: foundation,
  });

  const unavailableFloatingTurbines = previewTurbines.filter(
    (t) => !floatingTurbines.includes(t),
  );
  const unavailableFixedTurbines = previewTurbines.filter(
    (t) => !fixedTurbines.includes(t),
  );

  const isFloatingFoundation = getIsFloatingFoundation(foundation.id);
  const isFixedFoundation = getIsFixedFoundation(foundation.id);

  return (
    <Column
      ref={scrollBodyRef}
      style={{
        overflowY: "auto",
        maxHeight: "calc(100vh - 26rem)",
      }}
    >
      <FoundationSettings
        currentFoundationId={foundation.id}
        setCurrentFoundationId={setCurrentFoundationId}
      />
      {(floatingTurbines.length > 0 || fixedTurbines.length > 0) && (
        <FoundationWarnings
          isFloatingFoundation={isFloatingFoundation}
          isFixedFoundation={isFixedFoundation}
          floatingTurbines={floatingTurbines}
          fixedTurbines={fixedTurbines}
          selectedTurbines={previewTurbines}
          foundation={foundation}
        />
      )}

      {isFloatingFoundation && (
        <MooringInner
          live={live}
          park={park}
          mode={mode}
          raster={raster}
          currentFoundationId={foundation.id}
          floatingTurbines={floatingTurbines}
          unavailableTurbines={unavailableFloatingTurbines}
        />
      )}
      {isFixedFoundation && (
        <FixedFoundationInner
          park={park}
          mode={mode}
          fixedTurbines={fixedTurbines}
          unavailableTurbines={unavailableFixedTurbines}
          selectedTurbines={previewTurbines}
          currentFoundationId={foundation.id}
        />
      )}
    </Column>
  );
};

const GenerateFoundationsOuter = ({
  park,
  live,
}: {
  park: ParkFeature;
  live: boolean;
}) => {
  const projectId = useRecoilValue(projectIdSelector) ?? "";
  const branchId = useRecoilValue(branchIdSelector) ?? "";

  const bathymetryRaster = useBathymetryRaster({
    projectId,
    branchId,
    featureId: park.id,
  });
  const raster = bathymetryRaster.getValue();

  if (!raster) {
    return (
      <div>
        <SimpleAlert
          text={"Failed to load bathymetry, can not generate foundations"}
          type={"error"}
        />
      </div>
    );
  }

  return <GenerateFoundations park={park} live={live} raster={raster} />;
};

const GenerateFoundations = ({
  park,
  live,
  raster,
}: {
  park: ParkFeature;
  live: boolean;
  raster: Raster;
}) => {
  const [isGenerateFoundationsActive, toggleIsGenerateFoundationsActive] =
    useBooleanState(false);
  const [isPanelOpen, toggleIsPanelOpen, setIsPanelOpen] =
    useBooleanState(false);
  const parkFoundationId = useRecoilValue(
    getMostCommonFoundationIdInZone({ parkId: park.id }),
  );
  const [currentFoundationId, setCurrentFoundationId] =
    useState<string>(parkFoundationId);

  const allFoundationTypes = useRecoilValue(allFoundationTypesSelector).filter(
    (t) => !t.archived,
  );

  const mooringParams = useRecoilValue(
    getMooringParametersAtomFamily({
      foundationId: parkFoundationId,
    }),
  );

  const syncWithRecoil = useSetRecoilState(combinedGenMooringParameters);
  const foundationIsFloating = useRecoilValue(
    isFloatingFoundationSelector({ foundationTypeId: currentFoundationId }),
  );

  useEffect(() => {
    if (isGenerateFoundationsActive && foundationIsFloating) {
      syncWithRecoil({
        ...mooringParams,
        raster,
      });
    }
    return () => {
      syncWithRecoil(undefined);
    };
  }, [
    foundationIsFloating,
    isGenerateFoundationsActive,
    mooringParams,
    raster,
    syncWithRecoil,
  ]);

  const currentFoundation = allFoundationTypes.find(
    (f) => f.id === currentFoundationId,
  );

  if (!currentFoundation) {
    setCurrentFoundationId(defaultFoundations[0].id);
  }

  return (
    <React.Suspense
      fallback={
        <Column>
          <SkeletonText />
        </Column>
      }
    >
      <Row
        style={{
          paddingTop: spaceSmall,
          paddingBottom: "2rem",
          justifyContent: "space-between",
        }}
      >
        <Label
          left
          style={{
            cursor: "pointer",
          }}
        >
          <Toggle
            checked={isGenerateFoundationsActive}
            onChange={() => {
              toggleIsGenerateFoundationsActive();
              if (!isGenerateFoundationsActive && !isPanelOpen) {
                setIsPanelOpen(true);
              } else if (isGenerateFoundationsActive && isPanelOpen) {
                setIsPanelOpen(false);
              }
            }}
            title="Generate foundations"
            size={ToggleSize.SMALL}
          />
          <InputTitle>Generate foundations</InputTitle>
        </Label>
        <IconBtn
          active={isPanelOpen}
          backgroundColor={colors.surfaceButtonSecondary}
          size={"1.4rem"}
          onClick={() => {
            setIsPanelOpen(!isPanelOpen);
          }}
        >
          <OpenRight />
        </IconBtn>
      </Row>

      {isPanelOpen && (
        <>
          {currentFoundation && (
            <MenuFrame
              title={"Generate foundations"}
              onExit={toggleIsPanelOpen}
              style={{
                display: isPanelOpen ? "block" : "none",
                padding: "1.6rem 0rem 2rem 0rem",
                position: "absolute",
                left: "calc(100% + 1rem)",
                bottom: 0,
                backgroundColor: colors.background,
              }}
            >
              <CombinedFoundationInner
                park={park}
                live={live}
                foundation={currentFoundation}
                setCurrentFoundationId={setCurrentFoundationId}
                raster={raster}
              />
            </MenuFrame>
          )}
        </>
      )}
    </React.Suspense>
  );
};

const GenerateTurbines = ({
  park,
  live,
  selectedRegion,
  selectedZoneSize,
  innerRef,
}: {
  selectedRegion: ParkFeature | SubAreaFeature;
  park: ParkFeature;
  live: boolean;
  selectedZoneSize: number | undefined;
  innerRef?: ((node: ReferenceType | null) => void) &
    ((node: ReferenceType | null) => void);
}) => {
  return (
    <React.Suspense
      fallback={
        <Column>
          <SkeletonText />
        </Column>
      }
    >
      <GenerationSettings
        park={park}
        region={selectedRegion}
        selectedZoneSize={selectedZoneSize}
        live={live}
        selectedRegion={selectedRegion}
        innerRef={innerRef}
      />
    </React.Suspense>
  );
};

const Inner = ({ park }: { park: ParkFeature }) => {
  const selectedFeatures = useRecoilValue(currentSelectedProjectFeatures);
  const setSelectedFeatures = useSetRecoilState(currentSelectionArrayAtom);
  const { update: updateFeatures } = useProjectElementsCrud();
  const { subAreas, exclusionZones } = useRecoilValue(
    getDivisionFeaturesSelectorFamily({ parkId: park.id }),
  );
  const { scrollBodyRef } = useShowScrollShadow(true);

  const selectedSubAreas = useMemo(
    () => subAreas.filter((f) => selectedFeatures.some((sf) => sf.id === f.id)),
    [selectedFeatures, subAreas],
  );

  const selectedRegion = useMemo(() => {
    if (subAreas.length === 0) return park;
    if (subAreas.length === 1) return subAreas[0];
    // If there are sub areas, we require that the user clicks on exactly one.
    if (selectedSubAreas.length === 0) return undefined;
    if (selectedSubAreas.length === 1) return selectedSubAreas[0];
    return undefined;
  }, [subAreas, park, selectedSubAreas]);

  const [live, setLive] = useRecoilState(
    turbinesAndFoundationsGenerationIsLiveAtom,
  );

  const methodAndParameters = useRecoilValue(
    methodAndParametersForRegionAtomFamily(selectedRegion?.id),
  );

  const selectedZoneSize = useMemo(
    () =>
      undefMap(
        exclusionZones.length !== 0 && selectedRegion
          ? differenceAll([selectedRegion, ...exclusionZones])
          : selectedRegion,
        (r) => (r == null ? undefined : turf.area(r) / 1e6),
      ),
    [selectedRegion, exclusionZones],
  );

  const tooLarge = (selectedZoneSize ?? -Infinity) > MAX_AREA;
  const tooSmall = (selectedZoneSize ?? Infinity) < MIN_AREA;

  const { getFeaturesToSave } = useTurbineGeneration(
    park,
    selectedRegion ?? park,
    //We dont really need a turbine type since we are not using any generation methods
    DEFAULT_TURBINES[0] as SimpleTurbineType,
  );

  const { warning } = useToast();

  const saveOnExitAndApplyCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const live = snapshot
          .getLoadable(turbinesAndFoundationsGenerationIsLiveAtom)
          .getValue();
        if (!live) {
          return;
        }
        const turbinePreviews = await snapshot.getPromise(previewTurbinesState);
        const mooringPreviews = await snapshot.getPromise(
          previewMooringAndFoundationState,
        );
        const turbinesWithNewFoundation =
          turbinePreviews?.preview.map((turbine) => {
            const foundationId = mooringPreviews?.preview.foundations.find(
              (f) => f.turbineId === turbine.id,
            )?.foundationId;
            return {
              ...turbine,
              properties: {
                ...turbine.properties,
                foundationId,
              },
            };
          }) ?? [];

        let featuresToSave: {
          add: ProjectFeature[];
          remove: string[];
        } = getFeaturesToSave(turbinesWithNewFoundation);

        if (mooringPreviews) {
          // // Remove all mooring lines and anchors that are in this park. Everything we want to keep is in `p`.
          // // The same with turbines, because we might have changed the foundation type.
          const allFeatures = await snapshot.getPromise(
            projectFeaturesSelector,
          );
          const featuresInPark = allFeatures.filter(inPark(park.id));
          const mooringLines = featuresInPark.filter(isMooringLine);
          const anchors = featuresInPark.filter(isAnchor);

          const linesToRemove = mooringLines.filter(
            (ml) =>
              !mooringPreviews.existing.mooringLines.find(
                (m) => ml.id === m.id,
              ),
          );
          const anchorsToRemove = anchors.filter(
            (an) =>
              !mooringPreviews?.existing.anchors.find((a) => an.id === a.id),
          );

          if (mooringPreviews.preview.partialTurbines?.length) {
            const n = mooringPreviews.preview.partialTurbines.length;
            warning(`${n} turbines did not get all anchors`);
          }

          featuresToSave = {
            ...featuresToSave,
            add: [
              ...featuresToSave.add,
              ..._AnchorFeature.array().parse(mooringPreviews.preview.anchors),
              ..._MooringLineFeature
                .array()
                .parse(mooringPreviews.preview.mooringLines),
            ],
            remove: dedup([
              ...featuresToSave.remove,
              ...linesToRemove.map((f) => f.id),
              ...anchorsToRemove.map((f) => f.id),
            ]),
          };
        }

        await updateFeatures(featuresToSave);
        set(previewMooringAndFoundationState, undefined);
        set(previewTurbinesState, undefined);
      },
    [getFeaturesToSave, park.id, warning, updateFeatures],
  );

  const trackEvent = useTrackEvent();

  useEffect(() => {
    return () => {
      saveOnExitAndApplyCallback();
      setLive(false);
    };
  }, [saveOnExitAndApplyCallback, setLive]);

  const {
    refs: refGenerate,
    floatingStyles: floatingStyleGenerate,
    context: contextGenerate,
  } = useFloating({
    placement: "right",
    middleware: [offset({ mainAxis: 10 })],
  });

  const {
    refs: refApply,
    floatingStyles: floatingStyleApply,
    context: contextApply,
  } = useFloating({
    placement: "right-start",
    middleware: [offset({ mainAxis: 140 })],
  });

  return (
    <>
      {tooLarge && (
        <div>
          <SimpleAlert
            text={`Too large area. Current maximum is ${MAX_AREA} km²`}
            type={"error"}
          />
        </div>
      )}
      {tooSmall && (
        <div>
          <SimpleAlert
            text={`Too small area. Current minimum is ${MIN_AREA} km²`}
            type={"error"}
          />
        </div>
      )}
      {1 < selectedSubAreas.length && (
        <div>
          <SimpleAlert text={"Select exactly one area"} type={"error"} />
        </div>
      )}
      {subAreas.length > 1 && (
        <Label left>
          <p>Sub area</p>
          <Dropdown
            small
            onChange={(e) => {
              setSelectedFeatures([e.target.value]);
            }}
            value={selectedRegion?.id ?? ""}
          >
            <option value={""} disabled hidden>
              {"Select sub area"}
            </option>
            {subAreas.map((iz) => (
              <option key={iz.id} value={iz.id}>
                {iz.properties.name ?? "Unnamed sub area"}
              </option>
            ))}
          </Dropdown>
        </Label>
      )}
      {selectedRegion && methodAndParameters && !tooLarge && !tooSmall && (
        <>
          <FloatingFocusManager context={contextApply} modal={false}>
            <TourStep
              tourId="general-intro-tour"
              stepId="editParameters"
              innerRef={refApply.setFloating}
              style={floatingStyleApply}
            />
          </FloatingFocusManager>
          <div
            ref={scrollBodyRef}
            style={{
              overflow: "hidden auto",
            }}
          >
            <GenerateTurbines
              park={park}
              live={live}
              selectedRegion={selectedRegion}
              selectedZoneSize={selectedZoneSize}
              innerRef={refApply.setReference}
            />
            {methodAndParameters.method !== "regular_auto" && (
              <>
                <SubtitleWithLine
                  style={{ paddingTop: "2rem" }}
                  text={"Foundations"}
                />
                <React.Suspense
                  fallback={
                    <Column>
                      <SkeletonText
                        style={{ height: "2rem" }}
                        text="Loading bathymetry"
                      />
                    </Column>
                  }
                >
                  <GenerateFoundationsOuter park={park} live={live} />
                </React.Suspense>
              </>
            )}
          </div>

          {["edge", "regular"].includes(methodAndParameters.method) && (
            <>
              <Divider />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: `${spaceLarge} 0 0 ${spaceLarge}`,
                  gap: spaceMedium,
                }}
              >
                {live && (
                  <>
                    <Button
                      id="reset-turbines-button"
                      text={"Reset"}
                      buttonType={"secondary"}
                      onClick={() => {
                        setLive((curr) => !curr);
                      }}
                    />
                    <HighlightStep
                      tourId="general-intro-tour"
                      stepId="editParameters"
                    >
                      <Button
                        id="apply-turbines-button"
                        text={"Apply"}
                        buttonType={"primary"}
                        onClick={() => {
                          saveOnExitAndApplyCallback();
                          setLive((curr) => !curr);
                          trackEvent("clickedApply");
                        }}
                      />
                    </HighlightStep>
                  </>
                )}
                {!live && (
                  <>
                    <FloatingFocusManager
                      context={contextGenerate}
                      modal={false}
                    >
                      <TourStep
                        tourId="general-intro-tour"
                        stepId="createLayout"
                        innerRef={refGenerate.setFloating}
                        style={floatingStyleGenerate}
                      />
                    </FloatingFocusManager>
                    <HighlightStep
                      tourId="general-intro-tour"
                      stepId="createLayout"
                    >
                      <Button
                        id="generate-turbines-button"
                        text={"Generate"}
                        innerRef={refGenerate.setReference}
                        buttonType={"primary"}
                        onClick={() => {
                          setLive((curr) => !curr);
                          trackEvent("clickedGenerate");
                        }}
                      />
                    </HighlightStep>
                  </>
                )}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

const GenerateWindTurbinesAndFoundationsMenu = () => {
  const parkId = useRecoilValue(parkIdSelector);
  const park = useRecoilValue(
    getParkFeatureSelectorFamily({ parkId: parkId ?? "" }),
  );
  const [layoutControlActive, setLayoutControlActive] = useDrawMode();
  const popupRef = useRef<HTMLDivElement>(null);

  useClickOutside(
    popupRef,
    () => {
      setLayoutControlActive(undefined);
    },
    (target) => {
      if (!(target instanceof HTMLElement)) {
        return false;
      }
      if (target.dataset?.["isModal"]) {
        return true;
      }
      if (
        target.id === `button-${GenerateWindTurbinesAndFoundationsMenuType}`
      ) {
        return true;
      }

      if (target.id === "mooring-submenu-double-anchors") {
        return true;
      }

      // If we're in turbine gen menu and want to open exactly the production statistics pane,
      // let us do so :)
      return target.id === `button-${TopRightMenuOptions.productionStatistics}`;
    },
    { ignoreDragClicks: true },
  );

  if (
    !park ||
    layoutControlActive !== GenerateWindTurbinesAndFoundationsMenuType
  )
    return null;

  return (
    <>
      <MenuFrame
        id={GenerateWindTurbinesAndFoundationsMenuType}
        title="Generate layout"
        icon={<HelpLink article={ARTICLE_LAYOUT_GEN} />}
        ref={popupRef}
        onExit={() => {
          setLayoutControlActive(undefined);
        }}
        style={{
          maxHeight: "calc(100vh - 18rem)",
          boxSizing: "border-box",
          position: "relative",
          overflow: "visible",
        }}
      >
        <React.Suspense
          fallback={
            <Column>
              <SkeletonText
                style={{ height: "2rem" }}
                text="Loading bathymetry"
              />
            </Column>
          }
        >
          <Inner park={park} />
        </React.Suspense>
      </MenuFrame>
    </>
  );
};

export default GenerateWindTurbinesAndFoundationsMenu;
