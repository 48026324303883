import {
  BathymetryUserUploadedType,
  GeoTiffUserUploadedImageType,
} from "../../services/types";
import { ProjectFeature } from "../../types/feature";
import {
  isAnchor,
  isCable,
  isCableCorridor,
  isExportCable,
  isSubArea,
  isMooringLine,
  isSubstation,
  isTurbine,
} from "../../utils/predicates";
import { SubAreaFeature } from "../../types/feature";
import { TurbineFeature } from "../../types/feature";
import { ExportCableFeature } from "../../types/feature";
import { CableCorridorFeature } from "../../types/feature";
import { CableFeature } from "../../types/feature";
import { SubstationFeature } from "../../types/feature";
import { MooringLineFeature } from "../../types/feature";
import { AnchorFeature } from "../../types/feature";
import { ElementTreeFeature, ElementTreeNode, ElementTreeRoot } from "./types";
/**
 * Item outside a folder
 * TODO: rename to _ITEM
 */
export const PROJECT_ELEMENT_ORPHAN_ITEM = "PROJECT_ELEMENT_ORPHAN_ITEM";
/**
 * Item that's in a folder
 * @deprecated
 * */
export const PROJECT_ELEMENT_FOLDER_ITEM = "PROJECT_ELEMENT_FOLDER_ITEM";
export const PROJECT_ELEMENT_FOLDER = "PROJECT_ELEMENT_FOLDER";
export const PROJECT_ELEMENT_PARK = "PROJECT_ELEMENT_PARK";

export const generateSafeElementId = (featureId: string) => `el-${featureId}`;
export const generateSafeFolderId = (folderId: string) => `folder-${folderId}`;

export const getParkChildrenTypes = (
  children: ProjectFeature[],
): {
  subAreas: SubAreaFeature[];
  exportCables: ExportCableFeature[];
  internalCabling: CableFeature[];
  substations: SubstationFeature[];
  cableCorridors: CableCorridorFeature[];
  turbines: TurbineFeature[];
  anchoring: (AnchorFeature | MooringLineFeature)[];
} => {
  const subAreas = children.filter(isSubArea);
  const turbines = children.filter(isTurbine);

  const exportCables = children.filter(isExportCable);
  const internalCabling = children.filter(isCable);
  const substations = children.filter(isSubstation);
  const cableCorridors = children.filter(isCableCorridor);

  const anchors = children.filter(isAnchor);
  const mooringLine = children.filter(isMooringLine);
  const anchoring = [...anchors, ...mooringLine];

  return {
    subAreas,
    exportCables,
    internalCabling,
    substations,
    cableCorridors,
    turbines,
    anchoring,
  };
};

export const geotiffTypes = [
  BathymetryUserUploadedType,
  GeoTiffUserUploadedImageType,
];

export const featuresToDownloadText = (
  features: ProjectFeature[],
  vectorType: ".geojson" | ".shp" | ".kml",
) => {
  const texts = [] as string[];
  if (features.some((f) => !geotiffTypes.includes(f.properties.type ?? ""))) {
    texts.push(vectorType);
  }
  if (features.some((f) => geotiffTypes.includes(f.properties.type ?? ""))) {
    texts.push(".geotiff");
  }

  return "as " + texts.join(", ");
};

/**
 * Navigate up the tree to the root.
 */
export const getTreeRoot = (
  el: ElementTreeNode | ElementTreeRoot,
): ElementTreeRoot => {
  while (el.type !== "root") el = el.parent;
  return el;
};

/**
 * Looks through the entire tree to find a node that satisfies `pred`.
 *
 * Will not check the root, so that the predicate doesn't have to check if a
 * node is the root note every time.
 */
export const elementTreeFind = (
  el: ElementTreeNode | ElementTreeRoot,
  pred: (el: ElementTreeNode) => boolean,
): ElementTreeNode | undefined => {
  if (el.type !== "root" && pred(el)) return el;
  if (el.type === "feature") return undefined;
  for (const c of el.children) {
    const rec = elementTreeFind(c, pred);
    if (rec) return rec;
  }
  return undefined;
};

/**
 * Finds all nodes that satisfies the predicate.
 */
export const elementTreeFindAll = (
  el: ElementTreeNode | ElementTreeRoot,
  pred: (el: ElementTreeNode) => boolean,
): ElementTreeNode[] => {
  let ret: ElementTreeNode[] = [];
  if (el.type !== "root" && pred(el)) ret.push(el);
  if (el.type === "feature") return ret;
  for (const c of el.children) {
    ret = ret.concat(elementTreeFindAll(c, pred));
  }
  return ret;
};

/**
 * Get a flattened list of all features in the tree from the given node.
 */
export const elementTreeFlatten = (
  el: ElementTreeNode | ElementTreeRoot,
): ElementTreeFeature[] => {
  if (el.type === "feature") return [el];
  return el.children.flatMap((c) => elementTreeFlatten(c));
};

export const getDownloadMetadata = (
  currentSelectionArray: string[],
  featureIds: string[],
  writtenName: string,
  parent?: ProjectFeature,
) => {
  const hasSelectedFeatures =
    currentSelectionArray && currentSelectionArray.length > 1;

  const ids = hasSelectedFeatures ? currentSelectionArray : featureIds;
  const parentName = parent?.properties.name;
  const downloadedFeaturesFilename = hasSelectedFeatures
    ? "multiple-elements"
    : `${parentName ? parentName + "_" : ""}${writtenName}`;

  return { ids, downloadedFeaturesFilename };
};
