/// <reference types="vite-plugin-svgr/client" />
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { useDrag, useDrop } from "react-dnd";
import { useSetPropertyOnProjectFeatures } from "../../hooks/useSetPropertyOnProjectFeatures";
import { ProjectFeature } from "../../types/feature";
import { v4 as uuid } from "uuid";
import BinIcon from "@icons/24/Bin.svg";
import ChevronDownIcon from "@icons/14/ChevronDown.svg";
import ColorBucketIcon from "@icons/24/ColorBucket.svg?react";
import CompareIcon from "@icons/24/Compare.svg";
import DnDIconSmall from "@icons/12/DnDsmall.svg";
import DownloadIcon from "@icons/24/Download.svg";
import FolderOutlineIcon from "@icons/24/Folder-outline.svg";
import LockIcon from "@icons/24/Lock.svg";
import RemoveIcon from "@icons/24/Remove.svg";
import SectionIcon from "@icons/24/Section.svg";
import SearchIcon from "@icons/24/Search.svg";
import ParkIcon from "@icons/24/SideBarIcons/Park.svg";
import UnlockIcon from "@icons/24/Unlock.svg";
import ViewIcon from "@icons/24/View.svg";
import ViewOffIcon from "@icons/24/ViewOff.svg";
import Spinner from "@icons/spinner/Spinner";
import { IconREMSize } from "../../styles/typography";
import { featureIsLocked } from "../../utils/predicates";
import { HideIfNotHoverOrVisible } from "../LayerList/LayerList.style";
import useSystemSpecificUnicode from "../../hooks/useSystemSpecificUnicode";
import { modalTypeOpenAtom } from "../../state/modal";
import useTextInput from "../../hooks/useTextInput";
import { dedup, platformCtrlOrCommand } from "../../utils/utils";
import { EditableText } from "../General/EditableText";
import { DotMenu, MenuButtonRef } from "../General/MenuButton";
import { MenuItem } from "../General/Menu";
import { selectedParksAtom } from "../CompareParksModal/state";
import { CompareParksModalType } from "../CompareParksModal/CompareParksModalType";
import AddToPortfolioMenuItem from "components/Organisation/Portfolio/AddToPortfolioMenuItem";
import {
  featuresToDownloadText,
  generateSafeElementId,
  getParkChildrenTypes,
  PROJECT_ELEMENT_PARK,
  PROJECT_ELEMENT_ORPHAN_ITEM,
  PROJECT_ELEMENT_FOLDER_ITEM,
  PROJECT_ELEMENT_FOLDER,
  geotiffTypes,
  getTreeRoot,
  getDownloadMetadata,
  elementTreeFindAll,
} from "./utils";
import {
  ExpandArrowWrapper,
  ProjectElementFolderTopRow,
  ProjectElementFolderWrapper,
  TypeLine,
  Ui13RegularOverflow,
  DnDIconWrapper,
  ProjectElementItemWrapper,
  FlexCenterAligned,
} from "./ProjectElementsV2.style";
import { useProjectElementsContext } from "./ProjectElementsContext";
import { projectIdSelector } from "../../state/pathParams";
import { colors } from "../../styles/colors";
import { spaceMedium, spacing2 } from "../../styles/space";
import { lockedPropertyName } from "../../constants/canvas";
import { SubAreaFeature } from "../../types/feature";
import { parkChildrenSelector } from "../ProjectElements/state";
import { _FeatureParser } from "../../types/feature";
import { ProjectElementFolderType } from "./service";
import {
  DropCollectedProps,
  DropOnFeatureResults,
  ElementTreeNode,
} from "./types";
import { getDropCollect, dragCollect } from "./shared-dnd-callbacks";
import { TypeDot } from "components/General/Icons";
import { LineIconWrapper } from "components/LayerList/LineIconWrapper";
import { ElementsToIcon } from "components/ElementsToIcon/ElementsToIcon";
import { StylingSelectorBox } from "components/StylingSelector/StylingSelector";
import { useDnDLeafHooks } from "./hooks";
import { DownloadCustomCRSModalType } from "components/DownloadCustomCRSModal/DownloadCustomCRSModal";
import { FolderTreeMenuItem } from "./FolderTreeMenuItem";

const elementsToIconStyle: React.CSSProperties = {
  width: "1.4rem",
  height: "1.4rem",
};

const lineIconStyle: React.CSSProperties = {
  padding: "0 0.5rem 0 0",
  width: "16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const openItemsWrapperStyling: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  transition: "all 0.2s",
  gap: spacing2,
};

const SubAreaElements = ({
  park,
  folder,
  zones,
  onDeleteFeatures,
  depth,
}: {
  park: ProjectFeature;
  folder?: ProjectElementFolderType;
  zones: SubAreaFeature[];
  onDeleteFeatures?(featureIds: string[]): void;
  depth: number;
}) => {
  const { currentSelectionArray } = useProjectElementsContext();
  const [open, setOpen] = useState(false);
  const selected = useMemo(
    () =>
      zones.map((d) => d.id).every((id) => currentSelectionArray.includes(id)),
    [currentSelectionArray, zones],
  );

  if (zones.length === 0) return null;

  return (
    <>
      <ParkFeatureElement
        name="Sub areas"
        features={zones}
        parent={park}
        folder={folder}
        isSelected={selected}
        onDeleteFeatures={onDeleteFeatures}
        depth={depth}
        expanded={open}
        onCollapseChange={() => {
          setOpen(!open);
        }}
      />
      {open &&
        zones.map((z) => (
          <ParkFeatureElement
            key={z.id}
            name={z.properties.name ?? "Sub area"}
            features={[z]}
            parent={park}
            folder={folder}
            isSelected={!!currentSelectionArray.find((id) => id === z.id)}
            depth={depth + 1}
          />
        ))}
    </>
  );
};

/**
 * Element items that are under a park.
 */
const ParkFeatureElement = ({
  features,
  parent,
  name,
  folder,
  isSelected,
  onDeleteFeatures,
  icon,
  expanded,
  onCollapseChange,
  depth = 0,
}: {
  features: ProjectFeature[];
  parent: ProjectFeature;
  name: string;
  isSelected: boolean;
  isMultiSelect?: boolean;
  folder?: ProjectElementFolderType;
  icon?: React.ReactNode;
  onAddToFolder?(folder: ProjectElementFolderType, featureIds: string[]): void;
  onCreateFolder?(featureIds: string[]): void;
  onDeleteFeatures?(featureIds: string[]): void;
  depth?: number;
  sortIndex?: number;
  expanded?: boolean;
  onCollapseChange?(): void;
}) => {
  const projectId = useRecoilValue(projectIdSelector)!;

  const {
    selectedParks,
    branchId,
    navigateToPark,
    editorAccessProject,
    isReadOnly,
    goToFeatures,
    updateFeatures,
    isDownloading,
    downloadMultipleFeaturesShapeUsingId,
    downloadMultipleFeaturesGeojsonUsingId,
    downloadMultipleFeaturesKMLUsingId,
    updateProjectElementsFolder,
    getAreAllFeaturesVisible,
    toggleFeaturesHidden,
    setCurrentSelectionArray,
    currentSelectionArray,
    toggleFeaturesSelected,
    deselectAllFeatures,
    shiftSelectFeatures,
  } = useProjectElementsContext();
  const updateFeatureProperties = useSetPropertyOnProjectFeatures();
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const setSelectedCompareParks = useSetRecoilState(
    selectedParksAtom({ projectId }),
  );
  const [disableDrag, setDisableDrag] = useState(false);
  const dotMenuRef = useRef<MenuButtonRef>(null);

  const memoizedFeatures = useMemo<ProjectFeature[]>(
    () => features,
    [features],
  );

  const stringToUnicode = useSystemSpecificUnicode();
  const [writtenName, onWrittenNameChange, setWrittenName] = useTextInput(name);

  useEffect(() => {
    setWrittenName(name);
  }, [name, setWrittenName]);

  const featureIds = useMemo(
    () => memoizedFeatures.map((feature) => feature.id),
    [memoizedFeatures],
  );

  const allFeaturesAreVisible = useMemo(
    () => getAreAllFeaturesVisible(featureIds),
    [getAreAllFeaturesVisible, featureIds],
  );

  const onChangeNameSubmit = useCallback(() => {
    return updateFeatures({
      update: [
        _FeatureParser.parse({
          ...memoizedFeatures[0],
          properties: {
            ...memoizedFeatures[0].properties,
            name: writtenName,
          },
        }),
      ],
    });
  }, [memoizedFeatures, updateFeatures, writtenName]);

  const onMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (
        !currentSelectionArray ||
        currentSelectionArray.length === 0 ||
        !deselectAllFeatures ||
        platformCtrlOrCommand(e) ||
        e.shiftKey
      ) {
        return;
      }

      // Deselect elements if user has clicked on some other feature than the ones selected
      const isInSelected = featureIds.some((featureId) =>
        currentSelectionArray.includes(featureId),
      );
      if (!isInSelected) {
        deselectAllFeatures();
      }
    },
    [deselectAllFeatures, featureIds, currentSelectionArray],
  );

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (e.shiftKey) {
        if (parent) {
          toggleFeaturesSelected(featureIds);
        } else {
          shiftSelectFeatures(memoizedFeatures[0].id);
        }
      } else if (platformCtrlOrCommand(e)) {
        toggleFeaturesSelected(featureIds);
      } else {
        if (parent) {
          navigateToPark(parent.id);
        }

        goToFeatures(memoizedFeatures);
        setCurrentSelectionArray(featureIds);
      }
    },
    [
      featureIds,
      goToFeatures,
      memoizedFeatures,
      navigateToPark,
      parent,
      setCurrentSelectionArray,
      shiftSelectFeatures,
      toggleFeaturesSelected,
    ],
  );

  const allIsLocked = useMemo(
    () => memoizedFeatures.every(featureIsLocked),
    [memoizedFeatures],
  );

  const onLockFeaturesClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      updateFeatureProperties(
        memoizedFeatures.map((s) => s.id),
        {
          [lockedPropertyName]: !allIsLocked,
        },
      );
    },
    [memoizedFeatures, updateFeatureProperties, allIsLocked],
  );

  const _depth = useMemo(
    () => depth ?? Number(Boolean(folder)) + Number(Boolean(parent)),
    [depth, folder, parent],
  );

  return (
    <ProjectElementItemWrapper
      isDragging={false}
      isSelected={isSelected}
      onMouseDown={onMouseDown}
      onClick={onClick}
      onContextMenu={(e) => {
        e.preventDefault();
        dotMenuRef.current?.setIsOpen(true);
      }}
      depth={_depth}
      enableDrag={editorAccessProject && !isReadOnly && !disableDrag}
      isHoveredTop={false}
      isHoveredBottom={false}
    >
      <FlexCenterAligned>
        {onCollapseChange ? (
          <ExpandArrowWrapper
            open={!!expanded}
            onClick={(event) => {
              event.stopPropagation();
              onCollapseChange();
            }}
          >
            <ChevronDownIcon />
          </ExpandArrowWrapper>
        ) : (
          <></>
        )}
        <LineIconWrapper style={lineIconStyle}>
          {icon ? (
            <IconREMSize height={1.4} width={1.4}>
              {icon}
            </IconREMSize>
          ) : (
            <ElementsToIcon
              elements={memoizedFeatures}
              style={elementsToIconStyle}
              fillPolygons={true}
              fillParks={true}
            />
          )}
        </LineIconWrapper>
        <EditableText
          type="text"
          onEditChange={setDisableDrag}
          disabled={
            !editorAccessProject || isReadOnly || Boolean(parent) || allIsLocked
          }
          onEnter={onChangeNameSubmit}
          onCancel={onChangeNameSubmit}
          onChange={onWrittenNameChange}
          value={writtenName}
        >
          <Ui13RegularOverflow
            title={writtenName}
            style={{
              fontWeight: isSelected ? "600" : undefined,
            }}
          >
            {writtenName}
          </Ui13RegularOverflow>
        </EditableText>
      </FlexCenterAligned>
      <FlexCenterAligned>
        {editorAccessProject && (
          <HideIfNotHoverOrVisible
            className={allIsLocked ? "visible" : undefined}
            style={{ marginRight: spaceMedium }}
          >
            <IconREMSize height={1.6} width={1.6} onClick={onLockFeaturesClick}>
              {allIsLocked ? <LockIcon /> : <UnlockIcon />}
            </IconREMSize>
          </HideIfNotHoverOrVisible>
        )}

        <HideIfNotHoverOrVisible
          className={!allFeaturesAreVisible ? "visible" : undefined}
        >
          <IconREMSize
            title="Toggle feature visibility"
            height={1.5}
            width={1.5}
            onClick={(e) => {
              e.stopPropagation();
              toggleFeaturesHidden(featureIds, false);
            }}
          >
            {!allFeaturesAreVisible ? <ViewOffIcon /> : <ViewIcon />}
          </IconREMSize>
        </HideIfNotHoverOrVisible>

        {isDownloading[memoizedFeatures[0].id] ? (
          <Spinner
            style={{ width: "1rem", height: "1rem", margin: "0 1rem" }}
          />
        ) : (
          <HideIfNotHoverOrVisible>
            <DotMenu ref={dotMenuRef}>
              <MenuItem name="Download" icon={<DownloadIcon />}>
                <MenuItem
                  name={featuresToDownloadText(features, ".shp")}
                  icon={<DownloadIcon />}
                  onClick={() => {
                    const { ids, downloadedFeaturesFilename } =
                      getDownloadMetadata(
                        currentSelectionArray,
                        featureIds,
                        writtenName,
                        parent,
                      );
                    return downloadMultipleFeaturesShapeUsingId(
                      ids,
                      downloadedFeaturesFilename,
                      parent.id,
                    );
                  }}
                />
                {features.some(
                  (f) => !geotiffTypes.includes(f.properties.type ?? ""),
                ) && (
                  <>
                    <MenuItem
                      name={featuresToDownloadText(features, ".geojson")}
                      icon={<DownloadIcon />}
                      onClick={() => {
                        const { ids, downloadedFeaturesFilename } =
                          getDownloadMetadata(
                            currentSelectionArray,
                            featureIds,
                            writtenName,
                            parent,
                          );
                        return downloadMultipleFeaturesGeojsonUsingId(
                          ids,
                          downloadedFeaturesFilename,
                          parent.id,
                        );
                      }}
                    />
                    <MenuItem
                      name={featuresToDownloadText(features, ".kml")}
                      icon={<DownloadIcon />}
                      onClick={() => {
                        const { ids, downloadedFeaturesFilename } =
                          getDownloadMetadata(
                            currentSelectionArray,
                            featureIds,
                            writtenName,
                            parent,
                          );
                        return downloadMultipleFeaturesKMLUsingId(
                          ids,
                          downloadedFeaturesFilename,
                          parent.id,
                        );
                      }}
                    />
                    <MenuItem
                      name="Using custom coordinate system"
                      icon={<DownloadIcon />}
                      onClick={() => {
                        const { ids, downloadedFeaturesFilename } =
                          getDownloadMetadata(
                            currentSelectionArray,
                            featureIds,
                            writtenName,
                            parent,
                          );
                        setModalTypeOpen({
                          modalType: DownloadCustomCRSModalType,
                          metadata: {
                            featureIds: ids,
                            name: downloadedFeaturesFilename,
                            loadingId: parent.id,
                          },
                        });
                      }}
                    />
                  </>
                )}
              </MenuItem>
              <MenuItem
                name={isSelected ? "Deselect" : "Multiselect"}
                icon={<SectionIcon />}
                shortcut={`${stringToUnicode("command")} + Click`}
                onClick={() => {
                  toggleFeaturesSelected(featureIds);
                }}
              />
              {selectedParks.length > 0 && (
                <MenuItem
                  name={"Compare selected parks"}
                  icon={<CompareIcon />}
                  onClick={() => {
                    if (!branchId) {
                      return;
                    }

                    setSelectedCompareParks(
                      selectedParks.map((selectedPark) => ({
                        parkId: selectedPark.id,
                        branchId,
                        comparisonId: uuid(),
                      })),
                    );
                    setModalTypeOpen({ modalType: CompareParksModalType });
                  }}
                />
              )}
              <MenuItem
                name="Zoom to feature"
                icon={<SearchIcon />}
                onClick={() => goToFeatures(memoizedFeatures)}
              />
              {!isReadOnly && (
                <MenuItem
                  name="Set styling"
                  icon={<ColorBucketIcon />}
                  direction="right"
                  disabled={allIsLocked}
                >
                  <StylingSelectorBox
                    selectedProjectFeatures={memoizedFeatures}
                    direction="right"
                    enableColorStyling={false}
                    enableOpacityStyling={false}
                  />
                </MenuItem>
              )}
              {editorAccessProject && !isReadOnly && (
                <MenuItem
                  name={allIsLocked ? "Unlock features" : "Lock features"}
                  icon={allIsLocked ? <UnlockIcon /> : <LockIcon />}
                  onClick={onLockFeaturesClick}
                />
              )}
              {editorAccessProject && !isReadOnly && (
                <>
                  {folder && (
                    <MenuItem
                      name="Remove from folder"
                      icon={<RemoveIcon />}
                      onClick={() => {
                        const featureIdsToRemove =
                          currentSelectionArray &&
                          currentSelectionArray.length > 0
                            ? currentSelectionArray
                            : featureIds;

                        updateProjectElementsFolder?.({
                          ...folder,
                          featureIds: folder.featureIds.filter(
                            ({ id }) => !featureIdsToRemove.includes(id),
                          ),
                        });
                      }}
                    />
                  )}
                  <MenuItem
                    name="Delete"
                    disabled={allIsLocked}
                    icon={<BinIcon />}
                    onClick={() => {
                      onDeleteFeatures?.(featureIds);
                    }}
                  />
                </>
              )}
            </DotMenu>
          </HideIfNotHoverOrVisible>
        )}
      </FlexCenterAligned>
    </ProjectElementItemWrapper>
  );
};

const ParkElement = ({
  node,
  park,
  folder,
  onAddToFolder,
  onCreateFolder,
  onDeleteFeatures,
  depth,
}: {
  node: ElementTreeNode;
  park: ProjectFeature;
  folder?: ProjectElementFolderType;
  onAddToFolder?(folder: ProjectElementFolderType, featureIds: string[]): void;
  onCreateFolder?(featureIds: string[]): void;
  onDeleteFeatures?(featureIds: string[]): void;
  depth: number;
}) => {
  const projectId = useRecoilValue(projectIdSelector)!;
  const [hoverState, setHoverState] = useState<undefined | "bottom" | "top">(
    undefined,
  );
  const elementRef = useRef<HTMLDivElement>(null);
  const {
    branchId,
    selectedParks,
    editorAccessProject,
    isReadOnly,
    getAreAllFeaturesVisible,
    toggleFeaturesHidden,
    currentSelectionArray,
    currentSelectionMap,
    goToFeatures,
    updateFeatures,
    setCurrentSelectionArray,
    navigateToPark,
    updateProjectElementsFolder,
    deselectAllFeatures,
    isDownloading,
    downloadMultipleFeaturesShape,
    downloadMultipleFeaturesGeojson,
    downloadMultipleFeaturesKML,
    toggleFeaturesSelected,
    shiftSelectFeatures,
    onOpenedChange,
    getIsOpened,
    reorderTopLevel,
    tree,
  } = useProjectElementsContext();
  const [isOpen, setIsOpen] = useState(getIsOpened(park.id));
  const parkChildren = useRecoilValue(
    parkChildrenSelector({ parkId: park.id }),
  );
  const [disableDrag, setDisableDrag] = useState(false);
  const stringToUnicode = useSystemSpecificUnicode();
  const [writtenName, onWrittenNameChange, setWrittenName] = useTextInput(
    park.properties.name,
  );
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const setSelectedCompareParks = useSetRecoilState(
    selectedParksAtom({ projectId }),
  );
  const updateFeatureProperties = useSetPropertyOnProjectFeatures();
  const dotMenuRef = useRef<MenuButtonRef>(null);

  useEffect(() => {
    if (park.properties.name) {
      setWrittenName(park.properties.name);
    }
  }, [park.properties.name, setWrittenName]);

  const children = useMemo(
    () => getParkChildrenTypes(parkChildren),
    [parkChildren],
  );

  const changeIsOpen = useCallback(
    (newIsOpen: boolean) => {
      setIsOpen(newIsOpen);
      onOpenedChange(park.id, newIsOpen);
    },
    [onOpenedChange, park.id],
  );

  const childArray = useMemo(() => Object.values(children).flat(), [children]);
  const {
    subAreas,
    turbines,
    internalCabling,
    exportCables,
    cableCorridors,
    substations,
    anchoring,
  } = children;

  const featureIds = useMemo(
    () => childArray.map((feature) => feature.id).concat(park.id),
    [childArray, park.id],
  );

  const featureIdsAndParkIdMap = useMemo(
    () => new Map([park.id, ...featureIds].map((id) => [id, true])),
    [featureIds, park.id],
  );

  const isMarkedBecauseNotOpenedFolder = useMemo(() => {
    return (
      !isOpen &&
      currentSelectionArray.some((selection) =>
        featureIdsAndParkIdMap.has(selection),
      )
    );
  }, [currentSelectionArray, isOpen, featureIdsAndParkIdMap]);

  const {
    turbinesSelected,
    internalCablingSelected,
    exportCablesSelected,
    cableCorridorsSelected,
    substationsSelected,
    anchoringSelected,
  } = useMemo(() => {
    return {
      turbinesSelected:
        isOpen && turbines.some((f) => currentSelectionMap.has(f.id)),
      internalCablingSelected:
        isOpen && internalCabling.some((f) => currentSelectionMap.has(f.id)),
      exportCablesSelected:
        isOpen && exportCables.some((f) => currentSelectionMap.has(f.id)),
      cableCorridorsSelected:
        isOpen && cableCorridors.some((f) => currentSelectionMap.has(f.id)),
      substationsSelected:
        isOpen && substations.some((f) => currentSelectionMap.has(f.id)),
      anchoringSelected:
        isOpen && anchoring.some((f) => currentSelectionMap.has(f.id)),
    };
  }, [
    isOpen,
    turbines,
    internalCabling,
    exportCables,
    cableCorridors,
    substations,
    anchoring,
    currentSelectionMap,
  ]);

  const handleOnClick = useCallback(() => {
    navigateToPark(park.id);
    goToFeatures([park]);
    setCurrentSelectionArray([park.id]);
  }, [navigateToPark, park, goToFeatures, setCurrentSelectionArray]);

  const dnd = useDnDLeafHooks({
    node,
    divRef: elementRef,
    setHoverState,
    reorderTopLevel,
    updateFolder: updateProjectElementsFolder,
  });

  const [dragCollection, dragRef] = useDrag<
    ElementTreeNode[],
    void,
    {
      isDragging: boolean;
    }
  >(
    () => ({
      type: PROJECT_ELEMENT_PARK,
      item: () => {
        const root = getTreeRoot(node);
        const ids = new Set(currentSelectionArray);
        const nodes = elementTreeFindAll(root, (n) => ids.has(n.id));
        nodes.push(node);
        return dedup(nodes, (n) => n.id);
      },
      canDrag: () => {
        return (
          editorAccessProject &&
          !isReadOnly &&
          !disableDrag &&
          !featureIsLocked(park)
        );
      },
      collect: dragCollect,
      end: deselectAllFeatures,
    }),
    [
      editorAccessProject,
      isReadOnly,
      disableDrag,
      park,
      node,
      deselectAllFeatures,
      currentSelectionArray,
    ],
  );

  const [dropCollection, dropRef] = useDrop<
    ElementTreeNode[],
    DropOnFeatureResults,
    DropCollectedProps
  >(
    () => ({
      accept: [
        PROJECT_ELEMENT_ORPHAN_ITEM,
        PROJECT_ELEMENT_FOLDER_ITEM,
        PROJECT_ELEMENT_FOLDER,
        PROJECT_ELEMENT_PARK,
      ],
      collect: getDropCollect(),
      drop: dnd.drop,
      hover: dnd.hover,
    }),
    [dnd.drop, dnd.hover],
  );

  const allFeaturesAreVisible = useMemo(
    () => getAreAllFeaturesVisible(featureIds),
    [getAreAllFeaturesVisible, featureIds],
  );

  const allFeaturesAreSelected = useMemo(() => {
    const featureIdsAndParkId = [...featureIds, park.id];
    return featureIdsAndParkId.every((featureId) =>
      currentSelectionMap.has(featureId),
    );
  }, [featureIds, currentSelectionMap, park.id]);

  const onChangeNameSubmit = useCallback(() => {
    return updateFeatures({
      update: [
        _FeatureParser.parse({
          ...park,
          properties: {
            ...park.properties,
            name: writtenName,
          },
        }),
      ],
    });
  }, [park, updateFeatures, writtenName]);

  const allIsLocked = useMemo(
    () => [...childArray, park].every(featureIsLocked),
    [childArray, park],
  );

  const onLockFeaturesClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      const featureIdsAndParkId = [...featureIds, park.id];
      updateFeatureProperties(featureIdsAndParkId, {
        [lockedPropertyName]: !allIsLocked,
      });
    },
    [featureIds, park.id, updateFeatureProperties, allIsLocked],
  );

  dropRef(dragRef(elementRef));
  return (
    <ProjectElementFolderWrapper
      ref={elementRef}
      isDragging={dragCollection.isDragging}
      enableDrag={editorAccessProject && !isReadOnly && !disableDrag}
      isHoveredTop={dropCollection.isHovered && hoverState === "top"}
      isHoveredBottom={dropCollection.isHovered && hoverState === "bottom"}
      isInFolder={Boolean(folder)}
      id={generateSafeElementId(park.id)}
      isSelected={isMarkedBecauseNotOpenedFolder}
      depth={depth}
      onClick={(e) => {
        if (e.shiftKey) {
          shiftSelectFeatures(park.id);
        }
      }}
      onMouseDown={(e) => {
        if (
          !currentSelectionArray ||
          currentSelectionArray.length === 0 ||
          !deselectAllFeatures ||
          platformCtrlOrCommand(e) ||
          e.shiftKey
        ) {
          return;
        }

        // Deselect elements if user has clicked on some other feature than the ones selected
        const isInSelected = featureIds.some((featureId) =>
          currentSelectionArray.includes(featureId),
        );
        if (!isInSelected) {
          deselectAllFeatures();
        }
      }}
    >
      <ProjectElementFolderTopRow
        allFeaturesVisible={allFeaturesAreVisible}
        allFeaturesSelected={allFeaturesAreSelected}
        clickable={true}
        depth={depth}
        isInFolder={Boolean(folder)}
        onClick={(e) => {
          if (e.shiftKey) {
            shiftSelectFeatures(park.id);
          } else if (platformCtrlOrCommand(e)) {
            const featureIdsAndParkId = [...featureIds, park.id];
            toggleFeaturesSelected(featureIdsAndParkId);
          } else {
            changeIsOpen(true);
            handleOnClick();
          }
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          dotMenuRef.current?.setIsOpen(true);
        }}
        hoverable={true}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <DnDIconWrapper>
            <HideIfNotHoverOrVisible>
              <DnDIconSmall />
            </HideIfNotHoverOrVisible>
          </DnDIconWrapper>
          <ExpandArrowWrapper
            open={isOpen}
            onClick={(event) => {
              event.stopPropagation();
              changeIsOpen(!isOpen);
            }}
          >
            <ChevronDownIcon />
          </ExpandArrowWrapper>
          <IconREMSize height={1.2} width={1.2}>
            <ParkIcon />
          </IconREMSize>
          <EditableText
            type="text"
            disabled={
              !editorAccessProject || isReadOnly || featureIsLocked(park)
            }
            onEnter={onChangeNameSubmit}
            onCancel={onChangeNameSubmit}
            onChange={onWrittenNameChange}
            onEditChange={setDisableDrag}
            value={writtenName}
          >
            <Ui13RegularOverflow title={park.properties.name}>
              {park.properties.name}
            </Ui13RegularOverflow>
          </EditableText>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {editorAccessProject && (
            <HideIfNotHoverOrVisible
              className={allIsLocked ? "visible" : undefined}
              style={{ marginRight: spaceMedium }}
            >
              <IconREMSize
                height={1.6}
                width={1.6}
                onClick={onLockFeaturesClick}
              >
                {allIsLocked ? <LockIcon /> : <UnlockIcon />}
              </IconREMSize>
            </HideIfNotHoverOrVisible>
          )}

          <HideIfNotHoverOrVisible
            className={!allFeaturesAreVisible ? "visible" : undefined}
          >
            <IconREMSize
              title="Toggle park visibility"
              height={1.5}
              width={1.5}
              onClick={(e) => {
                e.stopPropagation();
                toggleFeaturesHidden(featureIds, false);
              }}
            >
              {!allFeaturesAreVisible ? <ViewOffIcon /> : <ViewIcon />}
            </IconREMSize>
          </HideIfNotHoverOrVisible>

          {isDownloading[park.id] ? (
            <Spinner
              style={{ width: "1rem", height: "1rem", margin: "0 1rem" }}
            />
          ) : (
            <HideIfNotHoverOrVisible>
              <DotMenu ref={dotMenuRef}>
                <AddToPortfolioMenuItem feature={park} />
                <MenuItem name="Download" icon={<DownloadIcon />}>
                  <MenuItem
                    name={featuresToDownloadText([...childArray, park], ".shp")}
                    icon={<DownloadIcon />}
                    onClick={() =>
                      downloadMultipleFeaturesShape(
                        [...childArray, park],
                        park.properties.name!,
                        park.id,
                      )
                    }
                  />
                  <MenuItem
                    name={featuresToDownloadText(
                      [...childArray, park],
                      ".geojson",
                    )}
                    icon={<DownloadIcon />}
                    onClick={() =>
                      downloadMultipleFeaturesGeojson(
                        [...childArray, park],
                        park.properties.name!,
                        park.id,
                      )
                    }
                  />
                  <MenuItem
                    name={featuresToDownloadText([...childArray, park], ".kml")}
                    icon={<DownloadIcon />}
                    onClick={() =>
                      downloadMultipleFeaturesKML(
                        [...childArray, park],
                        park.properties.name!,
                        park.id,
                      )
                    }
                  />
                  <MenuItem
                    name="Using custom coordinate system"
                    icon={<DownloadIcon />}
                    onClick={() => {
                      setModalTypeOpen({
                        modalType: DownloadCustomCRSModalType,
                        metadata: {
                          featureIds: [...childArray, park].map((d) => d.id),
                          name: park.properties.name ?? "unnamed park",
                          loadingId: park.id,
                        },
                      });
                    }}
                  />
                </MenuItem>
                <MenuItem
                  name={allFeaturesAreSelected ? "Deselect" : "Multiselect"}
                  icon={<SectionIcon />}
                  shortcut={`${stringToUnicode("command")} + Click`}
                  onClick={() => {
                    const featureIdsAndParkId = [...featureIds, park.id];
                    toggleFeaturesSelected(featureIdsAndParkId);
                  }}
                />
                {selectedParks.length > 0 && (
                  <MenuItem
                    name={"Compare selected parks"}
                    icon={<CompareIcon />}
                    onClick={() => {
                      if (!branchId) {
                        return;
                      }

                      setSelectedCompareParks(
                        selectedParks.map((selectedPark) => ({
                          parkId: selectedPark.id,
                          branchId,
                          comparisonId: uuid(),
                        })),
                      );
                      setModalTypeOpen({ modalType: CompareParksModalType });
                    }}
                  />
                )}
                <MenuItem
                  name="Zoom to feature"
                  icon={<SearchIcon />}
                  onClick={() => goToFeatures([park])}
                />
                {editorAccessProject && !isReadOnly && (
                  <MenuItem name="Add to folder" icon={<FolderOutlineIcon />}>
                    <FolderTreeMenuItem
                      node={tree}
                      onFolderClick={(f) =>
                        onAddToFolder?.(f.folder, [park.id])
                      }
                      onNewClick={() => onCreateFolder?.([park.id])}
                    />
                  </MenuItem>
                )}
                {editorAccessProject && !isReadOnly && (
                  <>
                    {folder && (
                      <MenuItem
                        name="Remove from folder"
                        icon={<RemoveIcon />}
                        onClick={() => {
                          const featureIdsToRemove =
                            currentSelectionArray &&
                            currentSelectionArray.length > 0
                              ? currentSelectionArray
                              : featureIds;

                          updateProjectElementsFolder?.({
                            ...folder,
                            featureIds: folder.featureIds.filter(
                              ({ id }) => !featureIdsToRemove.includes(id),
                            ),
                          });
                          deselectAllFeatures?.();
                        }}
                      />
                    )}
                    <MenuItem
                      name="Delete"
                      icon={<BinIcon />}
                      onClick={() => {
                        onDeleteFeatures?.([park.id]);
                      }}
                    />
                  </>
                )}
              </DotMenu>
            </HideIfNotHoverOrVisible>
          )}
        </div>
      </ProjectElementFolderTopRow>

      {isOpen && (
        <div style={openItemsWrapperStyling}>
          <ParkFeatureElement
            name="Area"
            features={[park]}
            parent={park}
            folder={folder}
            isSelected={currentSelectionArray.includes(park.id)}
            onDeleteFeatures={onDeleteFeatures}
            depth={depth + 1}
          />

          <SubAreaElements
            park={park}
            folder={folder}
            zones={subAreas}
            onDeleteFeatures={onDeleteFeatures}
            depth={depth + 1}
          />

          {internalCabling.length > 0 && (
            <ParkFeatureElement
              name="Internal cabling"
              features={internalCabling}
              parent={park}
              folder={folder}
              icon={<TypeLine lineColor="hsl(120, 100%, 50%)" />}
              isSelected={internalCablingSelected}
              onDeleteFeatures={onDeleteFeatures}
              depth={depth + 1}
            />
          )}

          {exportCables.length > 0 && (
            <ParkFeatureElement
              name="Export cables"
              features={exportCables}
              parent={park}
              folder={folder}
              icon={<TypeLine lineColor={colors.exportCableMissingType} />}
              isSelected={exportCablesSelected}
              onDeleteFeatures={onDeleteFeatures}
              depth={depth + 1}
            />
          )}

          {cableCorridors.length > 0 && (
            <ParkFeatureElement
              name="Cable corridors"
              features={cableCorridors}
              parent={park}
              folder={folder}
              icon={
                <TypeLine lineColor={colors.cableCorridor} lineWidth="6px" />
              }
              isSelected={cableCorridorsSelected}
              onDeleteFeatures={onDeleteFeatures}
              depth={depth + 1}
            />
          )}

          {substations.length > 0 && (
            <ParkFeatureElement
              name="Substations"
              features={substations}
              parent={park}
              folder={folder}
              icon={<TypeDot dotColor={colors.substation} />}
              isSelected={substationsSelected}
              onDeleteFeatures={onDeleteFeatures}
              depth={depth + 1}
            />
          )}

          {turbines.length > 0 && (
            <ParkFeatureElement
              name="Turbines"
              features={turbines}
              parent={park}
              folder={folder}
              icon={<TypeDot dotColor={colors.turbine} />}
              isSelected={turbinesSelected}
              onDeleteFeatures={onDeleteFeatures}
              depth={depth + 1}
            />
          )}

          {anchoring.length > 0 && (
            <ParkFeatureElement
              name="Mooring"
              features={anchoring}
              parent={park}
              folder={folder}
              icon={<TypeDot dotColor={colors.anchor} />}
              isSelected={anchoringSelected}
              onDeleteFeatures={onDeleteFeatures}
              depth={depth + 1}
            />
          )}
        </div>
      )}
    </ProjectElementFolderWrapper>
  );
};

export default ParkElement;
