import { Input, InputDimensioned } from "../../../../General/Input";

import { ConfidenceLevel, CostUnit } from "../../../../../types/financial";
import { isDefined } from "../../../../../utils/predicates";
import { GridWithFourColumns } from "../../styles";
import { Row } from "components/General/Layout";
import { localCostConfigurationAtom } from "../../state";
import { useRecoilValue } from "recoil";
import { CostConfiguration } from "services/costService";
import LogoIcon from "@icons/24/VindLogo.svg?react";
import { IconREMSize } from "styles/typography";
import { colors } from "styles/colors";
import Tooltip from "components/General/Tooltip";
import { IconBtn } from "components/General/Icons";
import UndoIcon from "@icons/24/Undo.svg";
import { ConfidenceLevelSelector } from "../../ConfidenceLevelSelector";

export const FixedCost = ({
  title,
  value = 0,
  unit,
  units = undefined,
  confidenceLevel,
  onValueChange,
  onUnitChange,
  onConfidenceLevelChange,
  isReadOnly,
  defaultCost,
}: {
  title: string;
  value?: number;
  unit: CostUnit;
  units?: CostUnit[];
  confidenceLevel: ConfidenceLevel | undefined;
  defaultCost: number;
  onValueChange: (value: number, unit?: CostUnit) => void;
  onUnitChange?: (unit: CostUnit) => void;
  onConfidenceLevelChange: (confidenceLevel: ConfidenceLevel) => void;
  isReadOnly?: boolean;
}) => {
  const localConfig: CostConfiguration = useRecoilValue(
    localCostConfigurationAtom,
  );
  const useConfidenceLevel = localConfig.useConfidenceLevel;
  const isUsingDefaultCost = value === defaultCost;
  return (
    <Row>
      <GridWithFourColumns>
        <Input
          value={title}
          disabled={true}
          locked={true}
          style={{ width: "20rem" }}
        />
        <Row style={{ width: "20rem" }}>
          <InputDimensioned
            style={{ width: "100%" }}
            unit={unit}
            units={units}
            value={value}
            onChange={(value, _, unit) => onValueChange(value, unit)}
            onUnitChange={onUnitChange}
            disabled={isReadOnly}
            validate={(value) => isDefined(value) && value >= 0}
            validationMessage={"Must be greater than or equal to 0"}
          />
          {isUsingDefaultCost ? (
            <Tooltip text={"Vind default value"}>
              <IconREMSize height={2.6} width={2.6} fill={colors.blue700}>
                <LogoIcon />
              </IconREMSize>
            </Tooltip>
          ) : (
            <Tooltip text="Reset to default">
              <IconBtn
                size={"1.6rem"}
                onClick={() => {
                  onValueChange(defaultCost);
                }}
              >
                <UndoIcon />
              </IconBtn>
            </Tooltip>
          )}
        </Row>
        {useConfidenceLevel && (
          <ConfidenceLevelSelector
            disabled={isReadOnly}
            onConfidenceLevelChange={onConfidenceLevelChange}
            confidenceLevel={confidenceLevel}
          />
        )}
      </GridWithFourColumns>
    </Row>
  );
};
