import styled from "styled-components";
import { StandardBox } from "styles/boxes/Boxes";
import { colors } from "styles/colors";
import { typography } from "styles/typography";

export const ModalWrapper = styled(StandardBox)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: fixed;
  top: 9rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1001;
  font-size: 1.6rem;
  font-weight: 500;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 1.6rem;
  width: 40rem;
  max-height: 50vh;
  overflow: hidden;
`;

export const SearchContainer = styled.div`
  ${typography.semiBold}
  display: flex;
  width: fit-content;
  background: ${colors.brand};
  color: white;
  text-align: center;
  padding: 0.6rem 1.6rem;
  z-index: 10;
  border-radius: 0.4rem;
  transition: opacity 0.25s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  gap: 1rem;
  white-space: pre;
`;

export const Header = styled.h3`
  ${typography.h3}
  margin: 0;
`;

export const Subtitle = styled.p`
  ${typography.sub3}
  margin: 0;
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  ${typography.caption}
  margin: 0;
  color: ${colors.textPrimary};
`;

export const TextCutOff = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AccessText = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  ${typography.caption}
  margin: 0;
  text-transform: capitalize;
`;

export const ValidationDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding: 0.8rem;
`;
