/// <reference types="vite-plugin-svgr/client" />
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import CloseIcon from "@icons/24/Close.svg?react";
import { IconBtn } from "components/General/Icons";
import { allSimpleTurbineTypesWithLevelSelector } from "state/turbines";
import { SubtitleWithLine } from "components/General/GeneralSideModals.style";
import { ColoredGrid } from "components/General/Form";
import Button from "components/General/Button";
import { PreviewItem, showComponentsPreviewAtom } from "./state";
import ProjectIcon from "@icons/24/ProjectGlobe.svg?react";
import LibraryIcon from "@icons/24/Library.svg?react";
import {
  Divider,
  GridKey,
  GridValue,
  HeaderRow,
  Modal,
  Subtitle,
  Title,
  Wrapper,
} from "./style";
import { TURBINE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useTurbineSettings";
import { FeatureSettingsShortcut } from "components/ConfigurationModal/Cost/Capex/FeatureSettingsShortcut";
import { useClickOutside } from "hooks/useClickOutside";

const TurbineComponentsPreview = () => {
  const [, setShowComponentsPreview] = useRecoilState(
    showComponentsPreviewAtom,
  );

  const onClose = useCallback(
    () => setShowComponentsPreview({ show: false }),
    [setShowComponentsPreview],
  );

  const containerRef = useRef<HTMLDivElement>(null);
  useClickOutside(containerRef, onClose, () => false, {
    ignoreDragClicks: true,
  });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  const allTurbineTypes = useRecoilValue(
    allSimpleTurbineTypesWithLevelSelector,
  );

  const turbineItems: PreviewItem[] = useMemo(
    () =>
      allTurbineTypes
        .filter((t) => !t.turbine.archived)
        .map((t) => ({
          id: t.turbine.id,
          name: t.turbine.name,
          cost: t.turbine.cost,
          costUnit: t.turbine.costUnit,
          level: ["project"].includes(t.level) ? "project" : "library",
        })),
    [allTurbineTypes],
  );

  return (
    <Wrapper>
      <Modal ref={containerRef}>
        <HeaderRow>
          <div>
            <Title>Turbine costs</Title>
            <Subtitle>Specified in Components</Subtitle>
          </div>
          <IconBtn size="1.5rem" onClick={onClose}>
            <CloseIcon />
          </IconBtn>
        </HeaderRow>

        <SubtitleWithLine
          textIcon={<LibraryIcon />}
          text={"Library turbines"}
        ></SubtitleWithLine>
        <ColoredGrid>
          {turbineItems
            .filter((t) => t.level === "library")
            .map((t) => (
              <React.Fragment key={t.id}>
                <GridKey>{t.name}</GridKey>
                <GridValue>
                  <strong>{t.cost}</strong>
                  {t.costUnit}
                  <FeatureSettingsShortcut
                    menuId={TURBINE_MENU_ID}
                    itemId={t.id}
                  />
                </GridValue>
              </React.Fragment>
            ))}
        </ColoredGrid>

        <SubtitleWithLine
          textIcon={<ProjectIcon />}
          text={"Project turbines"}
        ></SubtitleWithLine>
        <ColoredGrid>
          {turbineItems
            .filter((t) => t.level === "project")
            .map((t) => (
              <React.Fragment key={t.id}>
                <div>{t.name}</div>
                <GridValue>
                  <strong>{t.cost}</strong>
                  {t.costUnit}
                  <FeatureSettingsShortcut
                    menuId={TURBINE_MENU_ID}
                    itemId={t.id}
                  />
                </GridValue>
              </React.Fragment>
            ))}
        </ColoredGrid>
        <Divider />
        <div
          style={{ display: "flex", justifyContent: "end", paddingTop: "1rem" }}
        >
          <Button buttonType="secondary" text="Close" onClick={onClose} />
        </div>
      </Modal>
    </Wrapper>
  );
};

export default TurbineComponentsPreview;
