import { z } from "zod";

enum EventActions {
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  CREATE = "CREATE",
}

export const _ChangelogEntry = z.object({
  id: z.string(),
  branchId: z.string().optional(),
  nodeId: z.string(),
  author: z.string(),
  action: z.nativeEnum(EventActions),
  meta: z.record(z.string().or(z.boolean()).or(z.number())).optional(),
  version: z.number(),
  sqsMessageId: z.string(),
});

export type ChangelogEntry = z.infer<typeof _ChangelogEntry>;
