import {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { TextRaw } from "../../styles/typography";
import { Anchor, Place, oppositePlace } from "./Anchor";
import { spaceMedium, spaceSmall } from "../../styles/space";
import { useRecoilValue } from "recoil";
import { inReadOnlyModeSelector } from "../../state/project";

const animationTime = 0.2;

const TooltipContainer = styled.div<{
  show: boolean;
  maxWidth?: string;
  theme: "dark" | "light";
}>`
  z-index: 10;
  max-width: ${(p) => p.maxWidth ?? "25rem"};
  width: fit-content;
  padding: ${spaceSmall} ${spaceMedium};
  border-radius: 0.4rem;
  gap: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => {
    if (theme === "dark") {
      return `
        background-color: ${colors.blue900};
      `;
    }

    if (theme === "light") {
      return `
        background-color: ${colors.blue100};
      `;
    }
  }}

  white-space: pre-line;

  opacity: ${(p) => (p.show ? 1 : 0)};
  -webkit-transition: opacity ${animationTime}s ease-in-out;
  -moz-transition: opacity ${animationTime}s ease-in-out;
  transition: opacity ${animationTime}s ease-in-out;
`;

const TooltipText = styled.p<{ secondary: boolean; theme: "dark" | "light" }>`
  ${TextRaw};
  margin: 0;
  ${(p) => p.secondary && "font-size: 1.2rem;"}

  ${({ theme }) => {
    if (theme === "dark") {
      return `
        color: ${colors.lightText};
      `;
    }

    if (theme === "light") {
      return `
        color: ${colors.grey900};
      `;
    }
  }}
`;

// NOTE: This is a stupid hack to make the transition opacity work without
// needing to mount the tooltips when they're not visible.
const TooltipContainer2 = ({
  show,
  maxWidth,
  children,
  theme,
}: React.PropsWithChildren<{
  show: boolean;
  maxWidth?: string;
  theme: "dark" | "light";
}>) => {
  const [delayedShow, setDelayedShow] = useState(false);
  useEffect(() => {
    setDelayedShow(show);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);
  return (
    <TooltipContainer show={delayedShow} maxWidth={maxWidth} theme={theme}>
      {children}
    </TooltipContainer>
  );
};

const ShortcutText = styled.p`
  ${TextRaw};
  margin: 0;
  color: white;
  width: max-content;
  padding: 0.2rem 0.4rem;
  background-color: ${colors.secondaryText};
  border-radius: 0.4rem;
`;

const TooltipInner = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
`;

export default function Tooltip({
  id,
  children,
  text,
  content,
  shortcut,
  theme = "dark",
  position = "top",
  secondary = false,
  delay = 500,
  disabled = false,
  floatPlace,
  maxWidth,
  divRef,
  revealWithQKey,
  outerDivStyle,
  innerDivStyle,
  readonlyAware,
}: {
  id?: string;
  children: ReactNode;
  text: string | string[];
  content?: React.ReactNode;
  shortcut?: string;
  theme?: "dark" | "light";
  position?: Place;
  secondary?: boolean;
  floatPlace?: Place;
  maxWidth?: string;
  divRef?: React.RefObject<HTMLDivElement>;
  revealWithQKey?: boolean;
  outerDivStyle?: CSSProperties;
  innerDivStyle?: CSSProperties;
  readonlyAware?: boolean;
  delay?: number;
  disabled?: boolean;
}) {
  const timeout = useRef<NodeJS.Timeout>();
  const [show, setShow] = useState(false);
  const [showingWithKey, setShowingWithKey] = useState(false);
  const isReadOnly = useRecoilValue(inReadOnlyModeSelector);

  const _onEnter = useCallback(() => {
    timeout.current = setTimeout(() => {
      setShow(true);
    }, delay);
  }, [delay]);

  const _onLeave = useCallback(() => {
    clearTimeout(timeout.current);
    setShow(false);
  }, []);

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current);
    };
  });

  useEffect(() => {
    if (!revealWithQKey) return;
    const show = (event: KeyboardEvent) => {
      if (
        event.repeat ||
        event.isComposing ||
        !event.key ||
        event.key.toLowerCase() !== "q"
      ) {
        return;
      }
      setShowingWithKey(true);
    };
    const hide = (event: KeyboardEvent) => {
      if (
        event.repeat ||
        event.isComposing ||
        !event.key ||
        event.key.toLowerCase() !== "q"
      ) {
        return;
      }
      setShowingWithKey(false);
    };

    window.addEventListener("keydown", show);
    window.addEventListener("keyup", hide);

    return () => {
      window.removeEventListener("keydown", show);
      window.removeEventListener("keyup", hide);
    };
  }, [revealWithQKey, setShowingWithKey]);

  const ref = useRef<HTMLDivElement>(null);

  const shouldShow = showingWithKey || show;

  return (
    <div
      id={id}
      className="TooltipOuter"
      ref={ref}
      style={{ display: "flex", ...(outerDivStyle ?? {}) }}
    >
      <TooltipInner
        ref={divRef}
        onMouseEnter={_onEnter}
        onMouseLeave={_onLeave}
        onPointerLeave={_onLeave}
        style={innerDivStyle}
      >
        {children}
      </TooltipInner>
      {shouldShow && !disabled && (
        <Anchor
          basePlace={position}
          floatPlace={floatPlace ?? oppositePlace(position)}
          update={shouldShow}
          baseRef={ref}
        >
          <TooltipContainer2
            show={shouldShow}
            maxWidth={maxWidth}
            theme={theme}
          >
            {content ? (
              <div>{content}</div>
            ) : (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {readonlyAware && isReadOnly ? (
                  <TooltipText secondary={secondary} theme={theme}>
                    Read only mode
                  </TooltipText>
                ) : Array.isArray(text) ? (
                  text.map((t) => (
                    <TooltipText secondary={secondary} key={t} theme={theme}>
                      {t}
                    </TooltipText>
                  ))
                ) : (
                  <TooltipText secondary={secondary} theme={theme}>
                    {text}
                  </TooltipText>
                )}
              </div>
            )}
            {shortcut && <ShortcutText>{shortcut}</ShortcutText>}
          </TooltipContainer2>
        </Anchor>
      )}
    </div>
  );
}
