import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { borderRadiusMedium } from "styles/space";
import { colors } from "styles/colors";
import { typography } from "styles/typography";
import { MenuFrame } from "components/MenuPopup/CloseableMenuPopup";
import { showSelectFeatureForOverlapHintAtom } from "../layer-filter-state";

const ShowSelectFeatureForOverlapHintWrapper = styled.div`
  position: fixed;
  bottom: 10vh;
  left: 50%;
  transform: translateX(-50%);
`;

export const TextWrapper = styled.div`
  ${typography.contentAndButtons};
`;

export const KeyTag = styled.pre`
  ${typography.regular};
  font-family: monospace;
  display: inline-flex;
  background: ${colors.primary}20;
  border-radius: ${borderRadiusMedium};
  padding-inline: 0.2rem;
  margin: 0;
`;

const ShowSelectFeatureForOverlapHint = () => {
  const showSelectFeatureForOverlapHint = useRecoilValue(
    showSelectFeatureForOverlapHintAtom,
  );

  if (!showSelectFeatureForOverlapHint) {
    return null;
  }

  return (
    <ShowSelectFeatureForOverlapHintWrapper>
      <MenuFrame
        title="Select feature for overlap"
        style={{
          width: "unset",
          minWidth: "8rem",
          maxWidth: "30rem",
          marginBottom: "5rem",
        }}
      >
        <TextWrapper>
          Exit with <KeyTag>Escape</KeyTag>
        </TextWrapper>
      </MenuFrame>
    </ShowSelectFeatureForOverlapHintWrapper>
  );
};

export default ShowSelectFeatureForOverlapHint;
