import { MapLayerMouseEvent } from "mapbox-gl";
import { atom } from "recoil";

export type MouseHandler = (
  /**
   * The original event
   */
  e: MapLayerMouseEvent,
) => void;

export const mouseMoveHandlerAtom = atom<MouseHandler | undefined>({
  key: "mouseMoveHandlerAtom",
  default: undefined,
});

export const clickHandlerAtom = atom<MouseHandler | undefined>({
  key: "clickHandlerAtom",
  default: undefined,
});

export const doubleClickHandlerAtom = atom<MouseHandler | undefined>({
  key: "doubleClickHandlerAtom",
  default: undefined,
});
