import { _MonopileParameters } from "components/GenerateFoundationsAndAnchors/types";
import { _PileData } from "functions/types";
import { WaveStats } from "state/waveStatistics";
import { dateToDateTime } from "utils/utils";
import { z } from "zod";
import { ParkFeature } from "./feature";
import { SimpleTurbineType } from "./turbines";

export enum FoundationTypeIds {
  SemiCentralType = "semi_central",
  SemiPeripheralType = "semi_peripheral",
  SparType = "spar",
  MonopileType = "monopile",
  DetailedMonopileType = "detailed_monopile",
  JacketType = "jacket",
}

export const FoundationTypeNames = {
  [FoundationTypeIds.SemiCentralType]: "central semi",
  [FoundationTypeIds.SemiPeripheralType]: "offset semi",
  [FoundationTypeIds.SparType]: "spar",
  [FoundationTypeIds.MonopileType]: "simple monopile",
  [FoundationTypeIds.DetailedMonopileType]: "site-specific monopile",
  [FoundationTypeIds.JacketType]: "jacket",
};

export const _FoundationTypeIds = z.nativeEnum(FoundationTypeIds);

export const _SimpleFoundationType = z.object({
  name: z.string(),
  id: z.string(),
  type: _FoundationTypeIds,
  material: z.string(),
  info: z.string(),
  archived: z.boolean().optional(),
});
export type SimpleFoundationType = z.infer<typeof _SimpleFoundationType>;

const _ScalingLawType = z.enum(["Mass scaling"]);

export type ScalingLawType = z.infer<typeof _ScalingLawType>;

const _SemiCentralType = _SimpleFoundationType.merge(
  z.object({
    rotorDiameter: z.number(),
    ratedPower: z.number(),
    hubHeight: z.number(),
    rnaMass: z.number(),
    towerMass: z.number(),
    draft: z.number(),
    cornerColDiameter: z.number(),
    centralColDiameterTop: z.number(),
    centralColDiameterBase: z.number(),
    cornerColStart: z.number(),
    centralColStart: z.number(),
    cornerColHeight: z.number(),
    centralColHeight: z.number(),
    ponWidth: z.number(),
    ponHeight: z.number(),
    ponStart: z.number(),
    ccDistance: z.number(),
    primaryMass: z.number(),
    primaryDensity: z.number(),
    solidBallastMass: z.number(),
    liquidBallastMass: z.number(),
    displacement: z.number(),
    reinforceDensity: z.number().optional(),
    postTensDensity: z.number().optional(),
    scalingLaw: _ScalingLawType,
    fairZ: z.number().optional(),
    fairRadius: z.number().optional(),
  }),
);

export type SemiCentralType = z.infer<typeof _SemiCentralType>;

const _SemiPeripheralType = _SimpleFoundationType.merge(
  z.object({
    rotorDiameter: z.number(),
    ratedPower: z.number(),
    hubHeight: z.number(),
    rnaMass: z.number(),
    towerMass: z.number(),
    draft: z.number(),
    cornerColDiameter: z.number(),
    cornerColHeight: z.number(),
    cornerColStart: z.number(),
    ponWidth: z.number(),
    ponHeight: z.number(),
    ponStart: z.number(),
    deckWidth: z.number(),
    deckHeight: z.number(),
    deckStart: z.number(),
    ccDistance: z.number(),
    primaryMass: z.number(),
    primaryDensity: z.number(),
    solidBallastMass: z.number(),
    liquidBallastMass: z.number(),
    displacement: z.number(),
    reinforceDensity: z.number().optional(),
    postTensDensity: z.number().optional(),
    scalingLaw: _ScalingLawType,
    fairZ: z.number().optional(),
    fairRadius: z.number().optional(),
  }),
);
export type SemiPeripheralType = z.infer<typeof _SemiPeripheralType>;

const _SparType = _SimpleFoundationType.merge(
  z.object({
    rotorDiameter: z.number(),
    ratedPower: z.number(),
    hubHeight: z.number(),
    rnaMass: z.number(),
    towerMass: z.number(),
    draft: z.number(),
    colHeight: z.number(),
    topDiameter: z.number(),
    baseDiameter: z.number(),
    upperTaperLevel: z.number(),
    lowerTaperLevel: z.number(),
    primaryMass: z.number(),
    primaryDensity: z.number(),
    solidBallastMass: z.number(),
    liquidBallastMass: z.number(),
    displacement: z.number(),
    reinforceDensity: z.number().optional(),
    postTensDensity: z.number().optional(),
    scalingLaw: _ScalingLawType,
    fairZ: z.number().optional(),
    fairRadius: z.number().optional(),
  }),
);
export type SparType = z.infer<typeof _SparType>;

const _DetailedMonopileType = _SimpleFoundationType
  .and(_MonopileParameters)
  .and(_PileData);

export type DetailedMonopileType = z.infer<typeof _DetailedMonopileType>;

export const defaultDetailedMonopileParameters = (
  park: ParkFeature,
  turbineType: SimpleTurbineType,
  waterDepth: number,
  waveStats: WaveStats | undefined,
): DetailedMonopileType => {
  const parkName = park.properties.name ?? "unnamed park";
  const createdAt = new Date(Date.now());
  const {
    ratedPower,
    diameter: rotorDiameter,
    hubHeight,
    rnaMass,
  } = turbineType;

  return {
    name: `Monopile for ${parkName}`,
    id: "",
    type: FoundationTypeIds.DetailedMonopileType,
    material: "Steel",
    info: `Monopile generated on ${dateToDateTime(createdAt)}.`,
    soilCoeffSubReact: SoilStiffnessLevels.medium,
    ratedPower,
    rotorDiameter,
    hubHeight,
    rnaMass,
    waterDepth: Math.min(waterDepth, FoundationMaxDepths.detailed_monopile),
    hs50Yr: waveStats?.hs50Yr ?? 0,
    pileDiameter: 0,
    avgPileThickness: 0,
    embedLength: 0,
    totalPileLength: 0,
  };
};

export type ScalingVariable = "p" | "d" | "w";
export const SCALING_VARIABLES: ScalingVariable[] = ["p", "d", "w"];

const _MonopileType = _SimpleFoundationType.merge(
  z.object({
    scalingEquation: z.string(),
  }),
);

export type MonopileType = z.infer<typeof _MonopileType>;

const _JacketType = _SimpleFoundationType.merge(
  z.object({
    scalingEquation: z.string(),
  }),
);

export type JacketType = z.infer<typeof _JacketType>;

const _FloaterType = z.union([
  _SemiCentralType,
  _SemiPeripheralType,
  _SparType,
]);

export type FloaterType = z.infer<typeof _FloaterType>;

const _FixedType = z.union([_JacketType, _MonopileType, _DetailedMonopileType]);

export type FixedType = z.infer<typeof _FixedType>;

export const _FoundationType = z.union([_FloaterType, _FixedType]).and(
  z.object({
    author: z.string().optional(),
    createdAt: z.number().optional(),
  }),
);

export type FoundationType = z.infer<typeof _FoundationType>;

export const foundationLevel = ["project", "team", "library"] as const;

export const _FoundationLevel = z.enum(foundationLevel);

export type FoundationLevel = z.infer<typeof _FoundationLevel>;
export type FoundationTypeWithLevel = {
  level: FoundationLevel;
  foundation: FoundationType;
};

export const FoundationMinDepths = {
  [FoundationTypeIds.SemiCentralType]: 50,
  [FoundationTypeIds.SemiPeripheralType]: 50,
  [FoundationTypeIds.SparType]: 100,
  [FoundationTypeIds.MonopileType]: 0,
  [FoundationTypeIds.DetailedMonopileType]: 0,
  [FoundationTypeIds.JacketType]: 0,
};

export const FoundationMaxDepths = {
  [FoundationTypeIds.SemiCentralType]: Infinity,
  [FoundationTypeIds.SemiPeripheralType]: Infinity,
  [FoundationTypeIds.SparType]: Infinity,
  [FoundationTypeIds.MonopileType]: 75,
  [FoundationTypeIds.DetailedMonopileType]: 75,
  [FoundationTypeIds.JacketType]: 90,
};

const _SoilStiffnessType = z.union([
  z.literal("soft"),
  z.literal("medium"),
  z.literal("stiff"),
]);
export type SoilStiffnessType = z.infer<typeof _SoilStiffnessType>;

export const SoilStiffnessLevels = {
  soft: 2000e3,
  medium: 4000e3,
  stiff: 8000e3,
};

export const DEFAULT_SEMI_CENTRAL_FLOATERS: SemiCentralType[] = [
  {
    id: "umaine_15MW",
    name: "VolturnUS-S semi",
    type: FoundationTypeIds.SemiCentralType,
    material: "Steel",
    rotorDiameter: 240,
    ratedPower: 15000,
    hubHeight: 150,
    rnaMass: 991e3,
    towerMass: 1263e3,
    draft: 20,
    cornerColDiameter: 12.5,
    centralColDiameterTop: 10,
    centralColDiameterBase: 10,
    cornerColHeight: 35,
    centralColHeight: 35,
    cornerColStart: -20,
    centralColStart: -20,
    ponWidth: 12.5,
    ponHeight: 7,
    ponStart: -20,
    ccDistance: 51.75,
    primaryMass: 4014e3,
    primaryDensity: 7850,
    solidBallastMass: 2540e3,
    liquidBallastMass: 11300e3,
    displacement: 20206,
    info: "Based on the public definition of the UMaine VolturnUS-S steel semi, originally designed for the IEA 15MW turbine.",
    scalingLaw: "Mass scaling",
    fairRadius: 58,
    fairZ: -14,
  },
  {
    id: "activefloat_15MW",
    name: "ActiveFloat semi",
    type: FoundationTypeIds.SemiCentralType,
    material: "Concrete",
    rotorDiameter: 240,
    ratedPower: 15000,
    hubHeight: 135,
    rnaMass: 1016.5e3,
    towerMass: 1088.5e3,
    draft: 26.5,
    cornerColDiameter: 17,
    centralColDiameterTop: 11,
    centralColDiameterBase: 19.6,
    cornerColHeight: 35.5,
    centralColHeight: 24,
    cornerColStart: -26.5,
    centralColStart: -15,
    ponWidth: 17,
    ponHeight: 11.5,
    ponStart: -26.5,
    ccDistance: 34,
    primaryMass: 21027e3,
    primaryDensity: 2700,
    solidBallastMass: 0,
    liquidBallastMass: 13360e3,
    displacement: 36431,
    reinforceDensity: 350,
    postTensDensity: 40,
    info: "Based on the public definition of the ActiveFloat concrete semi, originally designed for the IEA 15MW turbine with modified hub height. The concrete weight is estimated from available info.",
    scalingLaw: "Mass scaling",
    fairRadius: 42.5,
    fairZ: -15,
  },
];
export const DEFAULT_SEMI_PERIPHERAL_FLOATERS: SemiPeripheralType[] = [
  {
    id: "windmoor_12MW",
    name: "WINDMOOR semi",
    type: FoundationTypeIds.SemiPeripheralType,
    material: "Steel",
    rotorDiameter: 216.9,
    ratedPower: 12000,
    hubHeight: 131.7,
    rnaMass: 849.1e3,
    towerMass: 1161.6e3,
    draft: 15.5,
    cornerColDiameter: 15,
    cornerColHeight: 31,
    cornerColStart: -15.5,
    ponWidth: 10,
    ponHeight: 4,
    ponStart: -15.5,
    deckWidth: 3.5,
    deckHeight: 3.5,
    deckStart: 12,
    ccDistance: 61 / Math.sqrt(3),
    primaryMass: 3950e3,
    primaryDensity: 7850,
    solidBallastMass: 0,
    liquidBallastMass: 8024e3,
    displacement: 14176,
    info: "Based on the public definition of the floater from the WINDMOOR project, originally designed for a 12MW turbine. The steel weight is estimated from available info.",
    scalingLaw: "Mass scaling",
    fairRadius: 42.7,
    fairZ: 0,
  },
];
export const DEFAULT_SPAR_FLOATERS: SparType[] = [
  {
    id: "spar_15MW",
    name: "Generic spar",
    type: FoundationTypeIds.SparType,
    material: "Concrete",
    rotorDiameter: 240,
    ratedPower: 15000,
    hubHeight: 143,
    rnaMass: 1017e3,
    towerMass: 1500e3,
    draft: 100,
    colHeight: 115,
    topDiameter: 10,
    baseDiameter: 23.5,
    upperTaperLevel: -5,
    lowerTaperLevel: -25,
    primaryMass: 15580e3,
    primaryDensity: 2700,
    solidBallastMass: 17000e3,
    liquidBallastMass: 5000e3,
    displacement: 39118,
    reinforceDensity: 250,
    postTensDensity: 30,
    info: "A generic concrete spar, originally sized for a 15MW turbine.",
    scalingLaw: "Mass scaling",
    fairRadius: 12,
    fairZ: -30,
  },
];

export const DEFAULT_MONOPILE_EQUATION =
  "1432 - 77.9 * w - 85.3 * p  + 3.5 * pow(p,2) + 1.4 * pow(w,2) + 4.5 * p * w";
export const DEFAULT_JACKET_EQUATION =
  "507 + 45.7 * p - 29.0 * w + 2.7 * pow(p,2) + 0.9 * pow(w,2) - 0.8 * p * w";
export const DEFAULT_MONOPILES: MonopileType[] = [
  {
    id: "site_monopile",
    name: "Generic monopile",
    type: FoundationTypeIds.MonopileType,
    material: "Steel",
    info: "A generic monopile, sized based on a provided weight equation. The default equation gives realistic weights for TP-less monopiles in water depths between 20-50 m.",
    scalingEquation: DEFAULT_MONOPILE_EQUATION,
  },
];

export const DEFAULT_JACKETS: JacketType[] = [
  {
    id: "generic_jacket",
    name: "Generic jacket",
    type: FoundationTypeIds.JacketType,
    material: "Steel",
    info: "A generic jacket, sized based on a provided weight equation. The default equation gives realistic weights for jackets (including piles) in water depths between 20-60 m.",
    scalingEquation: DEFAULT_JACKET_EQUATION,
  },
];
