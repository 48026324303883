import { z } from "zod";
import {
  NotAuthorizedError,
  NotFoundError,
} from "../components/ErrorBoundaries/types";
import { accessToken } from "../state/global";

// ----------------- With token --------------------------
export function addTokenToOptions(options: RequestInit): RequestInit {
  if (!accessToken) {
    alert("Access token not set, reloading page");
    window.location.reload();
  }
  const withAuth = {
    ...options,
    headers: { ...options.headers, Authorization: `Bearer ${accessToken}` },
  };
  return withAuth;
}

export const fetchWithToken = async (
  url: string,
  options: RequestInit,
): Promise<Response> => {
  const withAuth = addTokenToOptions(options);
  const res = await fetch(url, withAuth);
  return res;
};

export const fetchEnhancerWithToken = async (
  url: string,
  options: RequestInit,
  retries?: number,
): Promise<Response> => {
  const withAuth = addTokenToOptions(options);
  /* reloadAppIfAuthTokenIsExpired(options); */
  const res = await fetchWithRetries(url, withAuth, retries ?? 0);
  if (res.status === 426) {
    window.location.reload();
  } else if (res.status === 409) {
    // Stale data will need to be handled at the call site; throw here so that
    // the future will fail and the caller can decide how to deal with it.
    console.log("409 result", res);
    throw res;
  } else if (res.status === 403) {
    throw new NotAuthorizedError("Not authorized");
  } else if (res.status === 404) {
    throw new NotFoundError("Not found");
  } else if (!res.ok) {
    const clone = res.clone();
    console.log("not ok result", {
      url: res.url,
      status: res.status,
      text: await clone.text(),
    });
    throw res;
  }
  return res;
};

export const fetchSchemaWithToken = async <T, In = unknown>(
  schema: z.ZodType<T, any, In>,
  url: string,
  options?: RequestInit,
  retries?: number,
): Promise<T> => {
  const res = await fetchEnhancerWithToken(url, options ?? {}, retries);
  const json = await res.json();
  const value = schema.parse(json);
  return value;
};

// ----------------------- Without token ------------------------------
export const fetchEnhancer = async (
  url: string,
  options?: RequestInit,
): Promise<Response> => {
  const res = await fetch(url, options);
  if (res.status === 426) {
    window.location.reload();
  } else if (res.status === 403) {
    throw new NotAuthorizedError("Not authorized");
  }
  return res;
};

export const fetchWithRetries = async (
  url: string,
  options: RequestInit,
  retries: number,
): Promise<Response> => {
  const res = await fetchEnhancer(url, options);
  if (res.status < 500) {
    return res;
  }
  if (retries > 0) {
    return fetchWithRetries(url, options, retries - 1);
  }
  return res;
};

const _PresignedURLResponse = z.object({
  presignedUrl: z.string(),
  fields: z.record(z.string()),
  fileName: z.string(),
});

export type PresignedURLResponse = z.infer<typeof _PresignedURLResponse>;

export const presignedUpload = async (
  url: string,
  file: string | Blob,
  body: any = {},
): Promise<{ fileName: string }> => {
  const { presignedUrl, fields, fileName } = await fetchSchemaWithToken(
    _PresignedURLResponse,
    url,
    {
      method: "POST",
      body: JSON.stringify(body),
    },
  );

  const formData = new FormData();
  Object.entries(fields).forEach(([key, val]) => {
    formData.append(key, val);
  });
  formData.append("file", file);

  await fetchEnhancer(presignedUrl, {
    method: "POST",
    redirect: "follow",
    body: formData,
  });

  return { fileName };
};
