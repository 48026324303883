import { CableColor } from "components/Cabling/CablingMapController/Render";
import { preGenerateColorFromPrepickedColorLibrary } from "components/Cabling/CablingMapController/utils";
import { allCableTypesSelector } from "components/Cabling/Generate/state";
import {
  DEFAULT_IN_FOCUS_OPACITY,
  DEFAULT_NOT_IN_FOCUS_OPACITY,
  DEFAULT_SELECTED_OPACITY,
  displayLabelPropertyName,
  lockedPropertyName,
} from "@constants/canvas";
import mapboxgl, { GeoJSONSource, LinePaint, SymbolLayer } from "mapbox-gl";
import { useCallback, useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { CableType } from "services/cableTypeService";
import { colors } from "styles/colors";
import { CableFeature } from "types/feature";
import { safeRemoveLayer } from "utils/map";
import {
  cableLayerId,
  cableSourceId,
  cableSymbolLayerId,
  MIN_CABLE_VISIBLE_ZOOM,
} from "./constants";
import {
  getHiddenLargeLineClickLayer,
  addLayer,
  removeCodepointsFromFeatures,
} from "./utils";
import { debounce } from "debounce";

export const exportCablePaint: mapboxgl.LinePaint = {
  "line-color": [
    "case",
    ["!=", ["feature-state", "borderColor"], null],
    ["feature-state", "borderColor"],
    colors.exportCable,
  ],
  "line-opacity": [
    "case",
    [
      "all",
      [
        "boolean",
        ["feature-state", "hover"],
        ["feature-state", "selected"],
        false,
      ],
      ["!=", ["get", lockedPropertyName], true],
    ],
    DEFAULT_SELECTED_OPACITY,
    ["boolean", ["feature-state", "inFocus"], false],
    DEFAULT_IN_FOCUS_OPACITY,
    DEFAULT_NOT_IN_FOCUS_OPACITY,
  ],
  "line-width": [
    "case",
    ["==", ["get", lockedPropertyName], true],
    2,
    ["!=", ["feature-state", "borderColor"], null],
    4,
    [
      "boolean",
      ["feature-state", "hover"],
      ["feature-state", "selected"],
      false,
    ],
    4,
    2,
  ],
};

const cableSymbolLayer: SymbolLayer = {
  id: cableSymbolLayerId,
  source: cableSourceId,
  type: "symbol",
  layout: {
    "text-field": [
      "case",
      ["boolean", ["get", "redundancy"], false],
      "Rendundancy cable",
      ["concat", ["get", "powerLoad"], "MW"],
    ],
    "text-offset": [0, 0.75],
    "text-size": 8,
    "symbol-placement": "line",
  },
  filter: ["boolean", ["get", displayLabelPropertyName], true],
};

const getCableColors = ({
  cableTypes,
}: {
  cableTypes: CableType[];
}): CableColor[] => {
  const fallbackColor = {
    cableTypeId: "other",
    color: colors.secondaryText,
  };

  const preGeneratedColors = preGenerateColorFromPrepickedColorLibrary(
    cableTypes.length,
  );

  return [
    ...cableTypes.map(({ id }, i) => ({
      cableTypeId: id,
      color: preGeneratedColors[i],
    })),
    fallbackColor,
  ];
};

const hiddenClickLayer = getHiddenLargeLineClickLayer(
  cableLayerId,
  cableSourceId,
);

export const RenderCables = ({
  cables: features,
  map,
  oneColor,
}: {
  cables: CableFeature[];
  map: mapboxgl.Map;
  oneColor?: boolean;
}) => {
  const cableTypes = useRecoilValue(allCableTypesSelector);
  const paint: LinePaint = useMemo(() => {
    const cableColors = getCableColors({ cableTypes });
    return {
      "line-width": [
        "case",
        ["boolean", ["get", "error"], false],
        6,
        ["boolean", ["feature-state", "error"], false],
        [
          "case",
          [
            "boolean",
            ["feature-state", "hover"],
            ["feature-state", "selected"],
            false,
          ],
          4,
          2,
        ],
        ["==", ["get", lockedPropertyName], true],
        2,
        ["!=", ["feature-state", "borderColor"], null],
        4,
        [
          "boolean",
          ["feature-state", "hover"],
          ["feature-state", "selected"],
          false,
        ],
        4,
        2,
      ],
      "line-opacity": [
        "case",
        ["boolean", ["get", "error"], false],
        1.0,
        ["boolean", ["feature-state", "error"], false],
        1.0,
        [
          "all",
          [
            "boolean",
            ["feature-state", "hover"],
            ["feature-state", "selected"],
            false,
          ],
          ["!=", ["get", lockedPropertyName], true],
        ],
        DEFAULT_SELECTED_OPACITY,
        ["boolean", ["feature-state", "inFocus"], false],
        DEFAULT_IN_FOCUS_OPACITY,
        DEFAULT_NOT_IN_FOCUS_OPACITY,
      ],
      "line-dasharray": [
        "case",
        ["boolean", ["get", "redundancy"], false],
        ["literal", [1.5, 1.5]],
        ["literal", [1, 0]],
      ],
      "line-color": [
        "case",
        ["boolean", ["get", "error"], false],
        colors.redAlert,
        ["boolean", ["feature-state", "error"], false],
        colors.redAlert,
        ["boolean", ["get", "redundancy"], false],
        colors.grey200,
        ["!=", ["feature-state", "borderColor"], null],
        ["feature-state", "borderColor"],

        oneColor
          ? colors.cable
          : cableColors.length === 1
            ? colors.secondaryText
            : [
                "match",
                ["get", "cableTypeId"],
                ...cableColors
                  .slice(0, -1)
                  .flatMap(({ color, cableTypeId }) => [cableTypeId, color]),
                cableColors.at(-1)!.color,
              ],
      ],
    };
  }, [oneColor, cableTypes]);

  useEffect(() => {
    map.addSource(cableSourceId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    return () => {
      safeRemoveLayer(map, cableSymbolLayerId);
      safeRemoveLayer(map, cableLayerId);
      safeRemoveLayer(map, hiddenClickLayer.id);
      map.removeSource(cableSourceId);
    };
  }, [map]);

  useEffect(() => {
    addLayer(map, {
      id: cableLayerId,
      type: "line",
      source: cableSourceId,
      minzoom: MIN_CABLE_VISIBLE_ZOOM,
      paint,
    });
    addLayer(map, cableSymbolLayer);
    addLayer(map, hiddenClickLayer);
    return () => {
      safeRemoveLayer(map, cableSymbolLayerId);
      safeRemoveLayer(map, cableLayerId);
      safeRemoveLayer(map, hiddenClickLayer.id);
    };
  }, [map, paint]);

  const setSourceData = useCallback(
    (
      map: mapboxgl.Map,
      sourceId: string,
      data: Parameters<GeoJSONSource["setData"]>[0],
    ) =>
      debounce(() => {
        const source = map.getSource(sourceId);
        if (source?.type !== "geojson") return;
        source.setData(data);
      }, 50),
    [],
  );

  useEffect(() => {
    setSourceData(map, cableSourceId, {
      type: "FeatureCollection",
      features: removeCodepointsFromFeatures(features),
    })();
  }, [map, features, setSourceData]);

  return null;
};
