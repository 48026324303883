import styled from "styled-components";
import { StandardBox } from "../../styles/boxes/Boxes";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
`;

export const WrapContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem 4rem;
  flex-wrap: wrap;
  height: 17rem;
  width: max-content;
`;

export const ModalContainer = styled(StandardBox)`
  width: 40rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 2.4rem;
  gap: 2.4rem;
`;
