/// <reference types="vite-plugin-svgr/client" />
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Configuration } from "../../services/configurationService";
import { savingConfigurationInProgressAtom } from "../../state/configuration";
import Button from "../General/Button";
import { inReadOnlyModeSelector } from "../../state/project";
import {
  ContentWrapper,
  SettingsFooter,
  SettingsHeader,
} from "../SettingsV2/Shared/styles";
import AnalysisConfigurationUsageCurrentProject, {
  AnalysisConfigurationUsageLibrary,
} from "./SettingsUsage/AnalysisConfigurationUsage";
import { unsavedSettingsState } from "../SettingsV2/Shared/state";
import { toastMessagesAtom } from "../../state/toast";
import useEnterToSave from "./useEnterToSave";
import Tabs from "components/General/Tabs";
import WakeSettingsTab from "./AnalysisSettings/WakeSettingsTab";
import ElectricalSettingsTab from "./AnalysisSettings/ElectricalSettingsTab";
import CustomLossesTab from "./AnalysisSettings/CustomLossesTab";
import { ContentContainer, Row, SubHeader, HeaderContainer } from "./shared";
import { EditableText } from "components/General/EditableText";

export const AnalysisSettingsInner = ({
  configuration,
  configurationName,
  save,
  saveName,
  onUnmount,
  readOnly,
  isLibraryResource,
}: {
  configuration: Configuration;
  configurationName: string;
  save?: (config: Configuration) => Promise<any>;
  saveName?: (id: string, name: string) => Promise<any>;
  onUnmount?: () => void;
  readOnly?: boolean;
  isLibraryResource?: boolean;
}) => {
  const isSaving = useRecoilValue(savingConfigurationInProgressAtom);
  const isReadOnly = useRecoilValue(inReadOnlyModeSelector) || readOnly;

  const [localConfig, setLocalConfig] = useState(configuration);
  const isLoading = localConfig.id !== configuration.id;
  const allChangesSaved = isReadOnly || localConfig === configuration;

  const setUnsavedSettings = useSetRecoilState(unsavedSettingsState);
  const setToastMessages = useSetRecoilState(toastMessagesAtom);
  const [title, setTitle] = useState(configurationName);

  useEffect(() => {
    setTitle(configurationName);
  }, [configurationName]);

  useEffect(() => {
    setUnsavedSettings(!isLoading && !allChangesSaved);
  }, [allChangesSaved, isLoading, setUnsavedSettings]);

  const onEnterSaveCallback = useCallback(() => {
    if (!save) return;
    save(localConfig);
  }, [localConfig, save]);
  useEnterToSave(onEnterSaveCallback, !allChangesSaved && !isSaving);

  const renameConfig = useCallback(() => {
    if (!saveName) return;
    const renameConfigAsync = async () => {
      await saveName(configuration.id, title);
    };

    renameConfigAsync();
  }, [saveName, configuration.id, title]);

  useEffect(() => {
    setLocalConfig(configuration);
  }, [configuration]);

  const tabs = useMemo(() => {
    return [
      {
        name: "Wake settings",
        data: (
          <WakeSettingsTab
            isReadOnly={isReadOnly}
            setLocalConfig={setLocalConfig}
            localConfig={localConfig}
          />
        ),
      },
      {
        name: "Electrical settings",
        data: (
          <ElectricalSettingsTab
            isReadOnly={isReadOnly}
            setLocalConfig={setLocalConfig}
            localConfig={localConfig}
          />
        ),
      },
      {
        name: "Custom losses",
        data: (
          <CustomLossesTab
            isReadOnly={isReadOnly}
            setLocalConfig={setLocalConfig}
            localConfig={localConfig}
          />
        ),
      },
    ];
  }, [isReadOnly, localConfig]);

  useEffect(() => {
    return () => {
      if (onUnmount) onUnmount();
    };
  }, [onUnmount]);

  return (
    <>
      {!allChangesSaved && !readOnly && save && (
        <SettingsHeader>
          <Button
            disabled={isLoading || isSaving || allChangesSaved}
            text="Cancel"
            buttonType="secondary"
            onClick={() => {
              setLocalConfig(configuration);
            }}
            style={{ marginLeft: "auto" }}
          />
          <Button
            disabled={isLoading || isSaving || allChangesSaved}
            text="Save changes"
            onClick={() => {
              setToastMessages((tm) => [
                ...tm,
                { text: "Saving...", timeout: 1000 },
              ]);
              save({ ...localConfig, name: title });
            }}
          />
        </SettingsHeader>
      )}
      <ContentWrapper
        style={{
          maxHeight: "calc(100% - 7.3rem)",
          overflowY: "auto",
          boxSizing: "border-box",
        }}
      >
        <ContentContainer>
          <HeaderContainer>
            <Row>
              <SubHeader style={{ margin: 0 }}>
                Analysis configuration
              </SubHeader>
            </Row>
            <EditableText
              type="text"
              smallInput={true}
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              onEnter={renameConfig}
              onCancel={() => {
                setTitle(configurationName);
              }}
              textContainerStyle={{
                maxWidth: "20vw",
                padding: 0,
              }}
              renderText={(title) => (
                <h3
                  style={{
                    margin: 0,
                    whiteSpace: "nowrap",
                    overflowX: "hidden",
                    textOverflow: "ellipsis",
                    display: "block",
                  }}
                  title={title}
                >
                  {title}
                </h3>
              )}
              disabled={isReadOnly}
            />
          </HeaderContainer>

          <Tabs
            tabs={tabs}
            initialTab={0}
            menuStyle={{ justifyContent: "start", gap: "5rem" }}
          />
        </ContentContainer>
      </ContentWrapper>
      <SettingsFooter>
        {isLibraryResource ? (
          <AnalysisConfigurationUsageLibrary
            analysisConfigurationId={localConfig.id}
          />
        ) : (
          <AnalysisConfigurationUsageCurrentProject
            analysisConfigurationId={localConfig.id}
          />
        )}
      </SettingsFooter>
    </>
  );
};
