import React from "react";
import styled from "styled-components";
import { StandardBox } from "../../styles/boxes/Boxes";
import { spaceMedium, spacing4, spacing5, spacing6 } from "../../styles/space";
import { colors } from "../../styles/colors";

export const LAYERS_WRAPPER_MIN_WIDTH = 35; // NOTE: rem
export const SIDEBAR_PADDING = "1.2rem";

export const sidebarAnimation = "transition: all 0.3s";

export const Sidebar = styled(StandardBox)<{ open?: boolean }>`
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: stretch;
  padding: 1rem 0;
  ${({ open }) =>
    open
      ? `
      margin: 1rem 1rem 0 0;
      right: 0rem;
    `
      : `
        right: calc(0rem - var(--layers-menu-width));
  `}
  width: var(--left-menu-width);
  min-width: ${LAYERS_WRAPPER_MIN_WIDTH}rem;
  max-width: 40vw;
  border-radius: 6px;
  height: calc(100vh - 4rem);
  z-index: 4;
  background-color: ${colors.background};
  color: ${colors.primaryText};
  transition: right 0.3s ease-in-out;
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
`;

export const BottomButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid ${colors.inputOutline};
  padding: 1rem 1rem 0;
  justify-content: flex-end;
  gap: ${spacing4};
  margin-top: ${spaceMedium};
  flex-shrink: 0;
  margin-left: -${spacing6};
`;

export const HideIfNotHoverOrVisible = styled.div`
  display: flex;
  align-items: center;
`;

export const LayerItemTopRow = styled.div`
  display: flex;
  align-items: center;
`;

export const LayerItemBottomRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing4};
  //padding: 0 0.8rem;
`;

export const sourceLogoStyle: React.CSSProperties = {
  width: "16px",
  height: "16px",
  marginRight: "0.5rem",
};

export const CollectionItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: normal;
  transition: 0.4s;
  min-height: 3.6rem;
`;

export const CollectionLayersList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: normal;
  transition: all 0.2s;
  gap: ${spacing4};
  padding-top: ${spacing4};
  margin-bottom: ${spacing5};
`;
