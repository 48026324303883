/// <reference types="vite-plugin-svgr/client" />
import { SVGWrapper } from "@icons/svgUtils";
import {
  groupsProjectsState,
  organisationGroupsState,
  userGroupsMembershipState,
  userNodeAccessState,
} from "components/Organisation/Groups/state";
import {
  GroupNodeAccess,
  UserNodeAccess,
} from "components/Organisation/Groups/types";
import {
  ContentTableColumn,
  ContentTableRow,
  ContentTableRowNonHover,
  GroupLinkText,
  HoverableColumn,
  SecondaryText,
  SourceAccessRow,
  TabDescription,
  TableHeader,
  TextEllipsis,
} from "components/Organisation/OrganisationRightSide/style";
import { nodesInOrganisationSelectorFamily } from "components/Projects/useOrganisationFolderCrud";
import { useCallback, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { useTypedPath } from "state/pathParams";
import {
  adminInOrganisationSelectorFamily,
  userAllNodesAccessAtom,
  userHaveAdminNodeAccess,
} from "state/user";
import { TextIcon } from "styles/typography";
import Trashcan from "@icons/24/Bin.svg?react";
import SinglePerson from "@icons/24/SinglePerson.svg?react";
import Folder from "@icons/14/Folder.svg?react";
import Earth from "@icons/14/Earth.svg?react";
import AddIcon from "@icons/24/Add.svg?react";
import TeamMeeting from "@icons/24/TeamMeeting.svg?react";
import useUserNodeAccessCrud from "components/Organisation/Members/useUserNodeAccessCrud";
import { UserNodeAccessDropdown } from "../../shared/UserNodeAccessDropdown";
import Button from "components/General/Button";
import { Column, Row } from "components/General/Layout";
import { _UserAccessRole } from "types/user";
import {
  AdminNodeAccessModalWrapper,
  NodeAccess,
} from "../../shared/NodeAccessModal";
import { OrganisationUser } from "types/customer";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { IconBtn } from "components/General/Icons";

type GroupedAccess = {
  groupAccesses?: GroupNodeAccess[];
  personalAccess?: UserNodeAccess;
  nodeId: string;
};

export function UserProjects({ user }: { user: OrganisationUser }) {
  const { organisationId } = useTypedPath("organisationId");
  const userId = user.user_id;
  const nodeAccess = useRecoilValue(
    userNodeAccessState({ organisationId, userId }),
  );
  const memberships = useRecoilValue(
    userGroupsMembershipState({ organisationId, userId }),
  );
  const { addOrUpdate } = useUserNodeAccessCrud();

  const [showAddProject, setShowAddProject] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState<number>(0);

  const onAddProjects = useCallback(
    async (nodes: NodeAccess[]) => {
      setSaveInProgress(nodes.length);
      setShowAddProject(false);
      try {
        await Promise.all(
          nodes.map((node) => {
            const role = _UserAccessRole.parse(node.resource_name);
            return addOrUpdate(userId, node.node_id, role).finally(() =>
              setSaveInProgress((cur) => cur - 1),
            );
          }),
        );
      } catch {
      } finally {
        setSaveInProgress(0);
      }
    },
    [addOrUpdate, userId],
  );

  const groupNodeAccess = useRecoilValue(
    groupsProjectsState({
      organisationId,
      groupIds: memberships.map((m) => m.group_id),
    }),
  );

  const groupedAccessShips = useMemo(() => {
    const mappedGroupNodeAccess = groupNodeAccess.reduce(
      (acc: Record<string, GroupNodeAccess[]>, n) => {
        if (!acc[n.node_id]) acc[n.node_id] = [];
        acc[n.node_id].push(n);
        return acc;
      },
      {} as Record<string, GroupNodeAccess[]>,
    );
    const mappedUserNodeAccess = nodeAccess.reduce(
      (acc, n) => {
        return { ...acc, [n.node_id]: n };
      },
      {} as Record<string, UserNodeAccess>,
    );

    const allNodeIds = [
      ...new Set([
        ...Object.keys(mappedGroupNodeAccess),
        ...Object.keys(mappedUserNodeAccess),
      ]),
    ];

    return allNodeIds.map((nodeId) => ({
      ...(mappedGroupNodeAccess[nodeId]
        ? { groupAccesses: mappedGroupNodeAccess[nodeId] }
        : {}),
      ...(mappedUserNodeAccess[nodeId]
        ? { personalAccess: mappedUserNodeAccess[nodeId] }
        : {}),
      nodeId,
    })) as GroupedAccess[];
  }, [groupNodeAccess, nodeAccess]);

  return (
    <Column style={{ gap: "2.4rem", height: "100%" }}>
      <Row style={{ alignItems: "center" }}>
        <TabDescription>
          {user.nickname} has access to these projects.
        </TabDescription>
        <div style={{ marginLeft: "auto", position: "relative" }}>
          <Button
            text="Add projects"
            buttonType="secondary"
            icon={<AddIcon />}
            onClick={() => setShowAddProject((cur) => !cur)}
            disabled={saveInProgress > 0}
          />
          {showAddProject && (
            <div style={{ position: "absolute", right: "0.8rem" }}>
              <AdminNodeAccessModalWrapper
                onSave={onAddProjects}
                existingAccess={nodeAccess.map((n) => n.node_id)}
              />
            </div>
          )}
        </div>
      </Row>
      <Column style={{ gap: "1.2rem" }}>
        <Row style={{ gap: 0, padding: "0rem 1.5rem" }}>
          <TableHeader style={{ flex: "0 0 30%" }}>Project</TableHeader>
          <TableHeader style={{ flex: "0 0 30%", justifyContent: "center" }}>
            Access
          </TableHeader>
          <TableHeader style={{ flex: "0 0 30%", justifyContent: "center" }}>
            Source of access
          </TableHeader>
        </Row>
        <ContentTableColumn>
          {groupedAccessShips.map((ga) => (
            <GroupedRowHandler
              groupedNodeAccess={ga}
              userId={userId}
              key={ga.nodeId}
            />
          ))}
          {Array.from({ length: saveInProgress }, (_, index) => (
            <SkeletonBlock
              key={index}
              style={{ minHeight: "3.2rem", marginTop: "0.8rem" }}
            />
          ))}
        </ContentTableColumn>
      </Column>
    </Column>
  );
}

const GroupedRowHandler = ({
  groupedNodeAccess,
  userId,
}: {
  groupedNodeAccess: GroupedAccess;
  userId: string;
}) => {
  if (groupedNodeAccess.personalAccess && !groupedNodeAccess.groupAccesses) {
    return (
      <ProjectRow
        userId={userId}
        nodeAccess={groupedNodeAccess.personalAccess}
      />
    );
  } else if (
    !groupedNodeAccess.personalAccess &&
    groupedNodeAccess.groupAccesses
  ) {
    return (
      <>
        {groupedNodeAccess.groupAccesses.map((groupAccess) => (
          <GroupAccessRow
            key={groupAccess.group_id}
            groupNodeAccess={groupAccess}
          />
        ))}
      </>
    );
  }

  return (
    <GroupedRow
      groupAccesses={groupedNodeAccess.groupAccesses!}
      nodeAccess={groupedNodeAccess.personalAccess!}
      nodeId={groupedNodeAccess.nodeId}
      userId={userId}
    />
  );
};

const GroupedRow = ({
  groupAccesses,
  nodeAccess,
  nodeId,
  userId,
}: {
  groupAccesses: GroupNodeAccess[];
  nodeAccess: UserNodeAccess;
  nodeId: string;
  userId: string;
}) => {
  const { organisationId } = useTypedPath("organisationId");
  const nodes = useRecoilValue(
    nodesInOrganisationSelectorFamily({ organisationId }),
  );
  const node = nodes.find((n) => n.id === nodeId);
  const { remove } = useUserNodeAccessCrud();
  const isAdminInOrg = useRecoilValue(
    adminInOrganisationSelectorFamily({ organisationId }),
  );
  const userAllNodesAccess = useRecoilValue(userAllNodesAccessAtom);
  const isNodeAdmin = useMemo(
    () => userHaveAdminNodeAccess(userAllNodesAccess, nodeId),
    [userAllNodesAccess, nodeId],
  );

  const [deleteInProgress, setDeleteInProgress] = useState(false);

  if (!node) return <></>;

  const RowIcon = node.type === "project" ? Earth : Folder;
  return (
    <HoverableColumn>
      <ContentTableRowNonHover style={{ gap: 0 }} disabled={deleteInProgress}>
        <Row
          style={{
            alignItems: "center",
            flex: "0 0 30%",
            overflow: "hidden",
          }}
        >
          <SVGWrapper size={1.4}>
            <RowIcon />
          </SVGWrapper>
          <TextEllipsis style={{ margin: 0 }}>{node.name}</TextEllipsis>
        </Row>
        {isAdminInOrg || isNodeAdmin ? (
          <>
            <Row style={{ flex: "0 0 30%", justifyContent: "center" }}>
              <UserNodeAccessDropdown
                role={_UserAccessRole.parse(nodeAccess.resource_name)}
                nodeId={node.id}
                userId={userId}
              />
            </Row>
            <SourceAccessRow>
              <TextIcon style={{ padding: 0 }} scale={1.2}>
                <SinglePerson />
              </TextIcon>
              <TextEllipsis
                style={{
                  margin: 0,
                  textAlign: "center",
                }}
              >
                Personal
              </TextEllipsis>
            </SourceAccessRow>

            <Row>
              <IconBtn
                disabled={deleteInProgress}
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteInProgress(true);
                  remove(userId, nodeAccess.node_id).finally(() =>
                    setDeleteInProgress(false),
                  );
                }}
                size={"1.4rem"}
                style={{
                  marginLeft: "auto",
                }}
              >
                <Trashcan />
              </IconBtn>
            </Row>
          </>
        ) : (
          <>
            <Row style={{ flex: "0 0 30%", justifyContent: "center" }}>
              <SecondaryText style={{ margin: 0 }}>
                {nodeAccess.resource_name}
              </SecondaryText>
            </Row>
            <SourceAccessRow>
              <TextIcon
                scale={1.4}
                style={{
                  padding: 0,
                }}
              >
                <SinglePerson />
              </TextIcon>
              <TextEllipsis
                style={{
                  margin: 0,
                  textAlign: "center",
                }}
              >
                Personal
              </TextEllipsis>
            </SourceAccessRow>
          </>
        )}
      </ContentTableRowNonHover>
      {groupAccesses.map((groupNodeAccess) => (
        <GroupAccessSubRow
          groupNodeAccess={groupNodeAccess}
          key={groupNodeAccess.group_id}
        />
      ))}
    </HoverableColumn>
  );
};

const GroupAccessSubRow = ({
  groupNodeAccess,
}: {
  groupNodeAccess: GroupNodeAccess;
}) => {
  const { organisationId } = useTypedPath("organisationId");
  const group = useRecoilValue(
    organisationGroupsState({ organisationId }),
  ).find((g) => g.id === groupNodeAccess.group_id);

  if (!group) return <></>;

  return (
    <ContentTableRowNonHover key={groupNodeAccess.group_id} style={{ gap: 0 }}>
      <Row
        style={{
          alignItems: "center",
          flex: "0 0 30%",
          overflow: "hidden",
        }}
      />
      <TextEllipsis style={{ margin: 0, flex: "0 0 30%", textAlign: "center" }}>
        {groupNodeAccess.resource_name}
      </TextEllipsis>
      <SourceAccessRow>
        <SVGWrapper size={1.4}>
          <TeamMeeting />
        </SVGWrapper>
        <GroupLinkText
          style={{
            margin: 0,
            textAlign: "center",
          }}
        >
          {group.name}
        </GroupLinkText>
      </SourceAccessRow>
    </ContentTableRowNonHover>
  );
};

const ProjectRow = ({
  nodeAccess,
  userId,
}: {
  nodeAccess: UserNodeAccess;
  userId: string;
}) => {
  const { organisationId } = useTypedPath("organisationId");
  const nodes = useRecoilValue(
    nodesInOrganisationSelectorFamily({ organisationId }),
  );
  const node = nodes.find((n) => n.id === nodeAccess.node_id);
  const { remove } = useUserNodeAccessCrud();
  const isAdminInOrg = useRecoilValue(
    adminInOrganisationSelectorFamily({ organisationId }),
  );
  const userAllNodesAccess = useRecoilValue(userAllNodesAccessAtom);
  const isNodeAdmin = useMemo(
    () => userHaveAdminNodeAccess(userAllNodesAccess, nodeAccess.node_id),
    [userAllNodesAccess, nodeAccess.node_id],
  );

  const [deleteInProgress, setDeleteInProgress] = useState(false);

  if (!node) return <></>;

  const RowIcon = node.type === "project" ? Earth : Folder;
  return (
    <ContentTableRow
      key={node.id}
      style={{ gap: 0 }}
      disabled={deleteInProgress}
    >
      <Row
        style={{
          alignItems: "center",
          flex: "0 0 30%",
          overflow: "hidden",
        }}
      >
        <SVGWrapper size={1.4}>
          <RowIcon />
        </SVGWrapper>
        <TextEllipsis style={{ margin: 0 }}>{node.name}</TextEllipsis>
      </Row>
      {isAdminInOrg || isNodeAdmin ? (
        <>
          <Row style={{ flex: "0 0 30%", justifyContent: "center" }}>
            <UserNodeAccessDropdown
              role={_UserAccessRole.parse(nodeAccess.resource_name)}
              nodeId={node.id}
              userId={userId}
            />
          </Row>
          <SourceAccessRow>
            <TextIcon
              scale={1.4}
              style={{
                padding: 0,
              }}
            >
              <SinglePerson />
            </TextIcon>
            <TextEllipsis
              style={{
                margin: 0,
                textAlign: "center",
              }}
            >
              Personal
            </TextEllipsis>
          </SourceAccessRow>

          <Row>
            <IconBtn
              onClick={(e) => {
                e.stopPropagation();
                setDeleteInProgress(true);
                remove(userId, nodeAccess.node_id).finally(() =>
                  setDeleteInProgress(false),
                );
              }}
              size={"1.4rem"}
              disabled={deleteInProgress}
              style={{
                marginLeft: "auto",
              }}
            >
              <Trashcan />
            </IconBtn>
          </Row>
        </>
      ) : (
        <SecondaryText style={{ margin: 0 }}>
          {nodeAccess.resource_name}
        </SecondaryText>
      )}
    </ContentTableRow>
  );
};

function GroupAccessRow({
  groupNodeAccess,
}: {
  groupNodeAccess: GroupNodeAccess;
}) {
  const { organisationId } = useTypedPath("organisationId");
  const group = useRecoilValue(
    organisationGroupsState({ organisationId }),
  ).find((g) => g.id === groupNodeAccess.group_id);

  const node = useRecoilValue(
    nodesInOrganisationSelectorFamily({ organisationId }),
  ).find((n) => n.id === groupNodeAccess.node_id);

  if (!node || !group) return <></>;

  const RowIcon = node.type === "project" ? Earth : Folder;

  return (
    <ContentTableRow style={{ gap: 0 }}>
      <Row
        style={{
          alignItems: "center",
          flex: "0 0 30%",
          overflow: "hidden",
        }}
      >
        <SVGWrapper size={1.4}>
          <RowIcon />
        </SVGWrapper>

        <TextEllipsis style={{ margin: 0, flex: 1 }}>{node.name}</TextEllipsis>
      </Row>
      <TextEllipsis style={{ margin: 0, flex: "0 0 30%", textAlign: "center" }}>
        {groupNodeAccess.resource_name}
      </TextEllipsis>
      <SourceAccessRow>
        <SVGWrapper size={1.4}>
          <TeamMeeting />
        </SVGWrapper>
        <GroupLinkText
          style={{
            margin: 0,
            textAlign: "center",
          }}
        >
          {group.name}
        </GroupLinkText>
      </SourceAccessRow>
    </ContentTableRow>
  );
}
