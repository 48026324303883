import Spinner from "@icons/spinner/Spinner";
import * as wasm from "cables-wasm";
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import {
  CABLE_CHAIN_POLYGON_PROPERTY_TYPE,
  CABLE_PARTITION_POLYGON_PROPERTY_TYPE,
  CABLE_PROPERTY_TYPE,
  CablesMenuType,
  GenerateCablesMenuType,
} from "../../../constants/cabling";
import {
  turbineBufferZoneLayerId,
  turbineBufferZoneSourceId,
} from "../../../constants/projectMapView";
import {
  cableFeature,
  getCableCorridorsSelectorFamily,
  getCablesSelectorFamily,
  getSubstationsSelectorFamily,
  substationFeature,
} from "../../../state/cable";
import { FeatureSettingsModalTypeV2 } from "../../../state/configuration";
import {
  getDivisionFeaturesSelectorFamily,
  makeExclusionDivisonToExclusionDivisionPolygon,
  makeExclusionZone,
} from "../../../state/division";
import { getIAVoltageInPark } from "../../../state/electrical";
import {
  getAnchorsSelectorFamily,
  getMooringLinesSelector,
  getTurbinesSelectorFamily,
} from "../../../state/layout";
import { modalTypeOpenAtom } from "../../../state/modal";
import {
  getCurrentParkSelector,
  getParkFeatureSelectorFamily,
} from "../../../state/park";
import {
  branchIdSelector,
  parkIdSelector,
  useTypedPath,
} from "../../../state/pathParams";
import {
  findFeatureChildrenIds,
  projectFeatureMap,
} from "../../../state/projectLayers";
import { currentSelectedProjectFeatures } from "../../../state/selection";
import { allSimpleTurbineTypesSelector } from "../../../state/turbines";
import {
  spaceLarge,
  spaceMedium,
  spaceTiny,
  spacing4,
} from "../../../styles/space";
import {
  CableFeature,
  ProjectFeature,
  SubAreaFeature,
  SubstationFeature,
  TurbineFeature,
} from "../../../types/feature";
import {
  getLineIntersection,
  lineIntersectionIsClearlyOnSegments,
  pointInPolygon,
  segmentsAreEqual2,
} from "../../../utils/geometry";
import {
  featureIsLocked,
  isCable,
  isDefined,
  isSubArea,
  isSubstation,
  isTurbine,
  notUndefinedOrNull,
} from "../../../utils/predicates";
import {
  allPairs,
  isNever,
  maxBy,
  movingWindow,
  partition,
  uniquePairs,
} from "../../../utils/utils";
import Button from "../../General/Button";
import Checkbox from "../../General/Checkbox";
import { Label } from "../../General/Form";
import { Column } from "../../General/Layout";
import Radio, { RadioGroup } from "../../General/Radio";
import { RangeWithDimInput } from "../../General/RangeWithDimInput";
import Tooltip from "../../General/Tooltip";
import HelpTooltip, {
  ARTICLE_CABLE_GEN,
  ARTICLE_SUBSTATION_GEN,
  HelpLink,
} from "../../HelpTooltip/HelpTooltip";
import { SkeletonText } from "../../Loading/Skeleton";
import { interactionFeatureTypesWhitelistAtom } from "../../MapFeatures/GenericFeature";
import Polygon from "../../MapFeatures/Polygon";
import { MenuFrame } from "../../MenuPopup/CloseableMenuPopup";
import { projectFeaturesSelector } from "../../ProjectElements/state";
import { useProjectElementsCrud } from "../../ProjectElements/useProjectElementsCrud";
import { Row } from "../../RightSide/InfoModal/ProjectFeatureInfoModal/InfoModal.style";
import { useValidationWarnings } from "../../ValidationWarnings/ValidationWarnings";
import { addCableLoads, addCableTypes } from "../CableWalk";
import {
  CableSettings,
  DEFAULT_SUBSTATION_BUFFER,
  DEFAULT_TURBINE_BUFFER,
  allCableTypesSelector,
  filteredCableTypesState,
  generateCablesLoadingTimeAtom,
  generateCablesSettingState,
  getSelTurbinesSelectorFamily,
  openSubmenuAtom,
  selectedCableIdsState,
  showAnchorBufferZoneAtom,
  showMooringBufferZoneAtom,
  showTouchdownBufferZoneAtom,
  showTurbineBufferZoneAtom,
} from "./state";
import { calculateMaxChainLength } from "./utils";
import OpenRight from "@icons/24/OpenWindowRight.svg";
import * as Sentry from "@sentry/react";
import * as turf from "@turf/turf";
import init, { SubstationGenOptions } from "cables-wasm";
import { useBathymetryRaster } from "hooks/bathymetry";
import {
  InputTitle,
  InputTitleWrapper,
  OverlineText,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import { useDrawMode } from "components/MapControls/useActivateDrawMode";
import { SubstationInsideNoCableExclusionZonesValidationError } from "components/ValidationWarnings/FeatureSpecificErrors";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { Position } from "geojson";
import { useShowScrollShadow } from "hooks/useShowScrollShadow";
import { FillPaint } from "mapbox-gl";
import { currentSubstationTypesState } from "state/substationType";
import { TopRightMenuOptions } from "../../../constants/canvas";
import { useClickOutside } from "../../../hooks/useClickOutside";
import { useToast } from "../../../hooks/useToast";
import { Mixpanel } from "../../../mixpanel";
import { IAVoltageType } from "../../../services/cableTypeService";
import { mapRefAtom } from "../../../state/map";
import { colors } from "../../../styles/colors";
import { Text } from "../../../styles/typography";
import { ParkFeature } from "../../../types/feature";
import { useRecoilValueDef } from "../../../utils/recoil";
import { scream } from "../../../utils/sentry";
import { replaceOrUndefined } from "../../ControlPanels/utils";
import Dropdown from "../../Dropdown/Dropdown";
import {
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "../../ErrorBoundaries/ErrorBoundaryLocal";
import { Anchor } from "../../General/Anchor";
import { IconBtn } from "../../General/Icons";
import Toggle, { ToggleSize } from "../../General/Toggle";
import {
  AnchorBufferZone,
  MooringBufferZone,
  TouchdownBufferZone,
} from "../../GenerateFoundationsAndAnchors/Mooring";
import { getTouchdownPointsInPark } from "../../GenerateFoundationsAndAnchors/state";
import { cw } from "../Worker/cableWorker";
import { computeImportFeaturesJsonString } from "../Worker/utils";
import { ControlRow } from "./GenerateCables.style";
import { CableFreeSectorFromSelection } from "components/CableFreeSector/CableFreeSectors";
import { makeSector } from "components/CableFreeSector/types";

const turbineBufferPaint: FillPaint = {
  "fill-color": `${colors.redAlert}`,
  "fill-opacity": 0.25,
};

const TurbineBufferZoneInner = ({
  map,
  park,
  buffer,
}: {
  map: mapboxgl.Map;
  park: ParkFeature;
  buffer: number;
}) => {
  const turbines = useRecoilValue(
    getTurbinesSelectorFamily({ parkId: park.id }),
  );

  const zones = useMemo(() => {
    return turbines
      .map((a) =>
        turf.buffer(a, buffer, {
          units: "meters",
          steps: 3,
        }),
      )
      .filter(notUndefinedOrNull);
  }, [turbines, buffer]);

  return (
    <Polygon
      features={zones}
      map={map}
      sourceId={turbineBufferZoneSourceId}
      layerId={turbineBufferZoneLayerId}
      paint={turbineBufferPaint}
    />
  );
};

const TurbineBufferZone = ErrorBoundaryWrapper(
  () => {
    const map = useRecoilValue(mapRefAtom);
    const show = useRecoilValue(showTurbineBufferZoneAtom);
    const park = useRecoilValue(getCurrentParkSelector);
    const wiringSettings = useRecoilValue(generateCablesSettingState);
    if (!map || !show || !park || !wiringSettings.routeAroundTurbines)
      return null;
    return (
      <TurbineBufferZoneInner
        park={park}
        map={map}
        buffer={wiringSettings.turbineBuffer ?? DEFAULT_TURBINE_BUFFER}
      />
    );
  },
  () => null,
  ScreamOnError,
);

const SecondTimer = ({ from }: { from: number }) => {
  const [time, setTime] = useState(Date.now());
  useEffect(() => {
    let run = true;
    const interval = setInterval(() => {
      if (run) setTime(Date.now());
    }, 1000);
    return () => {
      run = false;
      clearInterval(interval);
    };
  }, [from]);

  const diff = time - from;
  const hours = Math.floor(diff / 1000 / 60 / 60);
  const minutes = Math.floor(diff / 1000 / 60) - hours * 60;
  const seconds = Math.floor(diff / 1000) - minutes * 60 - hours * 60 * 60;

  return (
    <p>
      {hours > 0 && `${hours}:`}
      {minutes < 10 ? `0${minutes}` : minutes}:
      {seconds < 10 ? `0${seconds}` : seconds}
    </p>
  );
};

const RouteAroundMooring = () => {
  const parkId = useRecoilValueDef(parkIdSelector);
  const map = useRecoilValue(mapRefAtom);
  const park = useRecoilValue(getParkFeatureSelectorFamily({ parkId }));
  const ref = useRef<HTMLDivElement>(null);
  const setMenu = useSetRecoilState(openSubmenuAtom);
  const [settings, setSettings] = useRecoilState(generateCablesSettingState);

  const setShowMooringBufferZoneAtom = useSetRecoilState(
    showMooringBufferZoneAtom,
  );
  const setShowTurbineBufferZoneAtom = useSetRecoilState(
    showTurbineBufferZoneAtom,
  );
  const setShowTouchdownBufferZoneAtom = useSetRecoilState(
    showTouchdownBufferZoneAtom,
  );
  const setShowAnchorBufferZoneAtom = useSetRecoilState(
    showAnchorBufferZoneAtom,
  );
  useClickOutside(
    ref,
    () => {
      setMenu(undefined);
    },
    (target) => {
      if (!(target instanceof HTMLElement)) {
        return false;
      }
      return target.id === "cable-submenu-open-mooring";
    },
  );

  return (
    <MenuFrame
      title="Mooring routing"
      onExit={() => setMenu(undefined)}
      id="cable-submenu-route-around-mooring"
      ref={ref}
      icon={<HelpLink article={ARTICLE_CABLE_GEN} />}
      style={{ gap: spacing4 }}
    >
      <Label>
        <InputTitle>Anchor buffer distance</InputTitle>
        <RangeWithDimInput
          min={0}
          max={200}
          rangeStep={1}
          value={settings.anchorBuffer ?? 100}
          unit={"m"}
          units={["m"]}
          onChange={(n) => {
            setSettings({
              ...settings,
              anchorBuffer: n,
            });
          }}
          onMouseUp={() => {
            setShowMooringBufferZoneAtom(false);
            setShowAnchorBufferZoneAtom(false);
            setShowTouchdownBufferZoneAtom(false);
            setShowTurbineBufferZoneAtom(false);
          }}
          onMouseDown={() => {
            setShowMooringBufferZoneAtom(true);
            setShowAnchorBufferZoneAtom(true);
            setShowTouchdownBufferZoneAtom(true);
            setShowTurbineBufferZoneAtom(true);
          }}
        />
      </Label>
      <Label>
        <InputTitle>Mooring line buffer distance</InputTitle>
        <RangeWithDimInput
          min={0}
          max={200}
          rangeStep={1}
          value={settings.mooringLineBuffer ?? 100}
          unit={"m"}
          units={["m"]}
          onChange={(n) => {
            setSettings({
              ...settings,
              mooringLineBuffer: n,
            });
          }}
          onMouseUp={() => {
            setShowMooringBufferZoneAtom(false);
            setShowAnchorBufferZoneAtom(false);
            setShowTouchdownBufferZoneAtom(false);
            setShowTurbineBufferZoneAtom(false);
          }}
          onMouseDown={() => {
            setShowMooringBufferZoneAtom(true);
            setShowAnchorBufferZoneAtom(true);
            setShowTouchdownBufferZoneAtom(true);
            setShowTurbineBufferZoneAtom(true);
          }}
        />
      </Label>
      <Label>
        <InputTitle>Touchdown buffer distance</InputTitle>
        <RangeWithDimInput
          min={0}
          max={100}
          rangeStep={1}
          value={settings.touchdownBuffer ?? 50}
          unit={"m"}
          units={["m"]}
          onChange={(n) => {
            setSettings({
              ...settings,
              touchdownBuffer: n,
            });
          }}
          onMouseUp={() => {
            setShowMooringBufferZoneAtom(false);
            setShowAnchorBufferZoneAtom(false);
            setShowTouchdownBufferZoneAtom(false);
            setShowTurbineBufferZoneAtom(false);
          }}
          onMouseDown={() => {
            setShowMooringBufferZoneAtom(true);
            setShowAnchorBufferZoneAtom(true);
            setShowTouchdownBufferZoneAtom(true);
            setShowTurbineBufferZoneAtom(true);
          }}
        />
      </Label>

      {map && park && (
        <>
          <AnchorBufferZone map={map} park={park} />
          <MooringBufferZone map={map} park={park} />
          <TouchdownBufferZone map={map} park={park} />
        </>
      )}
    </MenuFrame>
  );
};

const RouteAroundTurbines = () => {
  const ref = useRef<HTMLDivElement>(null);
  const setMenu = useSetRecoilState(openSubmenuAtom);
  const [settings, setSettings] = useRecoilState(generateCablesSettingState);

  const setShowMooringBufferZoneAtom = useSetRecoilState(
    showMooringBufferZoneAtom,
  );
  const setShowTurbineBufferZoneAtom = useSetRecoilState(
    showTurbineBufferZoneAtom,
  );
  const setShowTouchdownBufferZoneAtom = useSetRecoilState(
    showTouchdownBufferZoneAtom,
  );
  const setShowAnchorBufferZoneAtom = useSetRecoilState(
    showAnchorBufferZoneAtom,
  );
  useClickOutside(
    ref,
    () => {
      setMenu(undefined);
    },
    (target) => {
      if (!(target instanceof HTMLElement)) {
        return false;
      }
      return target.id === "cable-submenu-open-turbines";
    },
  );

  return (
    <MenuFrame
      title="Turbine routing"
      onExit={() => setMenu(undefined)}
      id="cable-submenu-route-around-turbines"
      ref={ref}
      icon={<HelpLink article={ARTICLE_CABLE_GEN} />}
      style={{ gap: spacing4 }}
    >
      <Label>
        <InputTitle>Turbine buffer distance</InputTitle>
        <RangeWithDimInput
          min={1}
          max={1000}
          rangeStep={1}
          value={settings.turbineBuffer ?? DEFAULT_TURBINE_BUFFER}
          unit={"m"}
          units={["m"]}
          onChange={(n) => {
            setSettings({
              ...settings,
              turbineBuffer: n,
            });
          }}
          onMouseUp={() => {
            setShowMooringBufferZoneAtom(false);
            setShowAnchorBufferZoneAtom(false);
            setShowTouchdownBufferZoneAtom(false);
            setShowTurbineBufferZoneAtom(false);
          }}
          onMouseDown={() => {
            setShowMooringBufferZoneAtom(true);
            setShowAnchorBufferZoneAtom(true);
            setShowTouchdownBufferZoneAtom(true);
            setShowTurbineBufferZoneAtom(true);
          }}
        />
      </Label>
    </MenuFrame>
  );
};

const SubstationSettings = ({
  turbines,
  numberOfSubstations,
}: {
  turbines: TurbineFeature[];
  numberOfSubstations: number;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const setMenu = useSetRecoilState(openSubmenuAtom);
  const [settings, setSettings] = useRecoilState(generateCablesSettingState);

  useClickOutside(
    ref,
    () => {
      setMenu(undefined);
    },
    (target) => {
      if (!(target instanceof HTMLElement)) {
        return false;
      }
      return target.id === "cable-submenu-open-substation";
    },
  );

  return (
    <MenuFrame
      title="Substation settings"
      onExit={() => setMenu(undefined)}
      id="cable-submenu-substation"
      ref={ref}
      icon={<HelpLink article={ARTICLE_CABLE_GEN} />}
      style={{ gap: spacing4 }}
    >
      <Label>
        <Tooltip text="Set a limit on the number of of turbines it is possible to connect to a one substation.">
          <InputTitle>Limit turbines per substation</InputTitle>
        </Tooltip>
        <RangeWithDimInput
          min={1}
          max={turbines.length}
          rangeStep={1}
          value={settings.maxTurbinesToSubstation ?? turbines.length}
          unit={"turbines"}
          onChange={(n) => {
            setSettings({
              ...settings,
              maxTurbinesToSubstation: n,
            });
          }}
        />
      </Label>
      {(settings.maxTurbinesToSubstation ?? turbines.length) *
        numberOfSubstations <
        turbines.length && (
        <SimpleAlert
          text={`Limit too low to connect all turbines:
          ${(settings.maxTurbinesToSubstation ?? turbines.length) * numberOfSubstations} < ${turbines.length}`}
          type={"error"}
        ></SimpleAlert>
      )}
    </MenuFrame>
  );
};

const ChainSettings = ({ turbines }: { turbines: TurbineFeature[] }) => {
  const ref = useRef<HTMLDivElement>(null);
  const setMenu = useSetRecoilState(openSubmenuAtom);
  const [settings, setSettings] = useRecoilState(generateCablesSettingState);

  useClickOutside(
    ref,
    () => {
      setMenu(undefined);
    },
    (target) => {
      if (!(target instanceof HTMLElement)) {
        return false;
      }
      return target.id === "cable-submenu-open-chains";
    },
  );

  const fixed = (settings.fixedChainsLen ?? 1) * (settings.fixedChainsNum ?? 1);
  const tooMany = turbines.length < fixed;
  const tooFew = fixed < turbines.length;

  return (
    <MenuFrame
      title="Chain settings"
      onExit={() => setMenu(undefined)}
      id="cable-submenu-chains"
      ref={ref}
      icon={<HelpLink article={ARTICLE_CABLE_GEN} />}
    >
      <p>
        Specify a number of chains that should be exactly of a given length, per
        substation.
      </p>
      <p style={{ paddingTop: "1rem" }}>
        If you have more than one substation, this requirement has to hold for
        every substation.
      </p>
      <Label style={{ paddingTop: "2.4rem" }}>
        <InputTitle>Number of chains</InputTitle>
        <RangeWithDimInput
          min={1}
          max={turbines.length}
          rangeStep={1}
          value={settings.fixedChainsNum ?? 1}
          onChange={(n) => {
            setSettings({
              ...settings,
              fixedChainsNum: n,
            });
          }}
        />
      </Label>
      <Label style={{ paddingTop: "0.8rem" }}>
        <InputTitle>Chain length</InputTitle>
        <RangeWithDimInput
          min={1}
          max={Math.min(turbines.length, 12)}
          rangeStep={1}
          value={settings.fixedChainsLen ?? 1}
          unit={"turbines"}
          onChange={(n) => {
            setSettings({
              ...settings,
              fixedChainsLen: n,
            });
          }}
        />
      </Label>
      <div style={{ height: "6rem", paddingTop: "1.6rem" }}>
        {tooMany ? (
          <SimpleAlert
            text={`Limit too high to for the number of turbines:
        ${turbines.length} < ${settings.fixedChainsLen ?? 1} * ${settings.fixedChainsNum ?? 1} = ${(settings.fixedChainsLen ?? 1) * (settings.fixedChainsNum ?? 1)}`}
            type={"error"}
          />
        ) : tooFew ? (
          <SimpleAlert
            text={`Remaining turbines (${turbines.length - fixed}) will be connected using the default rules.`}
            type={"info"}
          />
        ) : (
          <SimpleAlert
            text={`All turbines are accounted for.`}
            type={"success"}
          />
        )}
      </div>
    </MenuFrame>
  );
};

const SubstationBuffer = ({
  minimumTurbineDistance,
}: {
  minimumTurbineDistance: number | undefined;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const setMenu = useSetRecoilState(openSubmenuAtom);
  const [settings, setSettings] = useRecoilState(generateCablesSettingState);

  useClickOutside(
    ref,
    () => {
      setMenu(undefined);
    },
    (target) => {
      if (!(target instanceof HTMLElement)) {
        return false;
      }
      return target.id === "cable-submenu-open-substation-buffer";
    },
  );

  return (
    <MenuFrame
      title="Placement buffer"
      onExit={() => setMenu(undefined)}
      id="cable-submenu-substation-buffer"
      ref={ref}
      icon={<HelpLink article={ARTICLE_SUBSTATION_GEN} />}
      style={{ gap: spacing4 }}
    >
      <Label>
        <p>Turbine buffer distance to substation</p>
        <RangeWithDimInput
          min={100}
          max={1500}
          value={settings.substationBuffer ?? DEFAULT_SUBSTATION_BUFFER}
          unit={"m"}
          onChange={(n) => {
            setSettings({
              ...settings,
              substationBuffer: n,
            });
          }}
        />
      </Label>
      {minimumTurbineDistance &&
        (settings.substationBuffer ?? DEFAULT_SUBSTATION_BUFFER) >
          Math.round(minimumTurbineDistance / 2) && (
          <div style={{ padding: "0 1.6rem" }}>
            <SimpleAlert
              text={`Buffers larger than half the shortest distance between turbines in the park (${Math.round(minimumTurbineDistance / 2)}m) may produce sub-optimal or no substation placement(s).`}
              type={"warning"}
            />
          </div>
        )}
    </MenuFrame>
  );
};

const SettingsComponent = ({
  abortGeneration,
}: {
  abortGeneration(): void;
}) => {
  const { projectId, parkId, branchId } = useTypedPath(
    "projectId",
    "parkId",
    "branchId",
  );
  const [loadingTime, setLoadingTime] = useRecoilState(
    generateCablesLoadingTimeAtom,
  );
  const { scrollBodyRef } = useShowScrollShadow(true);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [settings, setSettings] = useRecoilState(generateCablesSettingState);
  const [generatedSettings, setGeneratedSettings] = useState<
    undefined | CableSettings
  >(undefined);

  const substationTypes = useRecoilValue(currentSubstationTypesState);
  const offshoreSubstationTypes = substationTypes.filter(
    (s) => s.type === "offshore",
  );
  const [currentSubstationTypeId, setCurrentSubstationTypeId] = useState<
    string | undefined
  >(offshoreSubstationTypes[0]?.id);

  const { error } = useToast();

  const featureMap = useRecoilValue(projectFeatureMap);
  const projectFeatures = useRecoilValue(projectFeaturesSelector);
  const { update: updateFeatures } = useProjectElementsCrud();

  const currentCables = useRecoilValue(getCablesSelectorFamily({ parkId }));
  const hasLockedCurrentCables = useMemo(
    () => currentCables.some((cable) => featureIsLocked(cable)),
    [currentCables],
  );

  const cableTypes = useRecoilValue(allCableTypesSelector);

  const [selectedCableIds, setSelectedCableIds] = useRecoilState(
    selectedCableIdsState,
  );
  const filteredCableTypes = useRecoilValue(filteredCableTypesState);

  const turbines = useRecoilValue(getTurbinesSelectorFamily({ parkId }));
  const { subAreas, exclusionZones: exclusionZonesMultiPolygon } =
    useRecoilValue(getDivisionFeaturesSelectorFamily({ parkId }));

  const exclusionZones = useMemo(
    () =>
      makeExclusionDivisonToExclusionDivisionPolygon(
        exclusionZonesMultiPolygon,
      ),
    [exclusionZonesMultiPolygon],
  );

  const selectedFeatures = useRecoilValue(currentSelectedProjectFeatures);
  const selectedSubAreaIds = useMemo(
    () => selectedFeatures.filter(isSubArea).map((f) => f.id),
    [selectedFeatures],
  );

  const substations = useRecoilValue(getSubstationsSelectorFamily({ parkId }));
  const selectedSubstations = useMemo(
    () => selectedFeatures.filter(isSubstation).map((f) => f.id),
    [selectedFeatures],
  );
  const turbineTypes = useRecoilValue(allSimpleTurbineTypesSelector);

  const park = useRecoilValue(getParkFeatureSelectorFamily({ parkId }));

  const cableCorridors = useRecoilValue(
    getCableCorridorsSelectorFamily({ parkId, branchId }),
  );

  const anchors = useRecoilValue(getAnchorsSelectorFamily(parkId));

  const mooringLines = useRecoilValue(getMooringLinesSelector(parkId));

  const raster = useBathymetryRaster({
    projectId,
    featureId: parkId,
  }).valueMaybe();

  const waterDepths: undefined | Record<string, number> = useMemo(() => {
    if (!raster) return undefined;
    let anyUndef = false;
    const entries = mooringLines.map((l) => {
      const turbine = turbines.find((a) => a.id === l.properties.target);
      if (!turbine) {
        anyUndef = true;
        return [l.id, 0.0];
      }
      const depth = -raster.latLngToValue(
        turbine.geometry.coordinates[0],
        turbine.geometry.coordinates[1],
      );
      return [l.id, depth];
    });

    if (anyUndef) return undefined;
    return Object.fromEntries(entries);
  }, [turbines, mooringLines, raster]);

  const touchdownPoints = useRecoilValue(
    getTouchdownPointsInPark({
      parkId,
      waterDepths: waterDepths ?? {},
    }),
  );

  const [shortestDistance, setShortestDistance] = useState<number | undefined>(
    undefined,
  );

  const featureIsRelevant = useCallback(
    (
      f: TurbineFeature | SubAreaFeature | SubstationFeature | undefined,
    ): boolean => {
      if (f === undefined || !park) return false;
      if ((subAreas.length ?? []) === 0) return true;
      const selectedOrAllSubAreas =
        (selectedSubAreaIds ?? []).length === 0
          ? subAreas
          : subAreas.filter((f) => selectedSubAreaIds.includes(f.id));

      if (isTurbine(f)) {
        if (selectedSubAreaIds.length > 0) {
          return selectedOrAllSubAreas.some((zone) =>
            pointInPolygon(f.geometry, zone.geometry),
          );
        }
        return pointInPolygon(f.geometry, park.geometry);
      } else if (isSubstation(f)) {
        if (selectedSubstations.length > 0) {
          return selectedSubstations.some((ss) => ss === f.id);
        } else if (selectedSubAreaIds.length > 0) {
          return selectedOrAllSubAreas.some((zone) =>
            pointInPolygon(f.geometry, zone.geometry),
          );
        }
        return (
          pointInPolygon(f.geometry, park.geometry) ||
          cableCorridors.some((zone) =>
            pointInPolygon(f.geometry, zone.geometry),
          )
        );
      } else if (isSubArea(f))
        return selectedOrAllSubAreas.some((zone) => zone.id === f.id);
      isNever(f);
      return false;
    },
    [cableCorridors, subAreas, park, selectedSubAreaIds, selectedSubstations],
  );

  const { warn } = useValidationWarnings();

  const selTurbines = useMemo(
    () => turbines?.filter(featureIsRelevant) ?? [],
    [featureIsRelevant, turbines],
  );

  const exclusionZonesWithSectors = useMemo(() => {
    const fs = (selTurbines as (TurbineFeature | SubstationFeature)[]).concat(
      substations,
    );
    const sectors = fs
      .flatMap((f) =>
        (f.properties.cableFreeSectors ?? []).map(
          ({ middle, span, distanceM }) =>
            makeSector({ feature: f, middle, span, distanceM }),
        ),
      )
      .filter(notUndefinedOrNull)
      .map((f) => makeExclusionZone(f.geometry));

    return exclusionZones.concat(sectors);
  }, [exclusionZones, selTurbines, substations]);

  const selTurbineTypes = useMemo(
    () => [
      ...new Set(
        selTurbines
          .map((turbine) =>
            turbineTypes.find(
              (type) => type.id === turbine.properties.turbineTypeId,
            ),
          )
          .filter(isDefined),
      ),
    ],
    [selTurbines, turbineTypes],
  );

  const selTurbineVoltages = useMemo(
    () => [
      ...new Set(selTurbineTypes.flatMap((turbineType) => turbineType.voltage)),
    ],
    [selTurbineTypes],
  );

  const assignCableTypes = useCallback(() => {
    const isCableToUpdate = (f: ProjectFeature): f is CableFeature => {
      if (!isCable(f)) return false;
      if (f.properties.redundancy) return false;

      const { parentIds, fromId, toId } = f.properties;
      if (parentIds![0] !== parkId) return false;

      const inZone =
        featureIsRelevant(
          featureMap.get(fromId) as
            | TurbineFeature
            | SubstationFeature
            | undefined,
        ) &&
        featureIsRelevant(
          featureMap.get(toId) as
            | TurbineFeature
            | SubstationFeature
            | undefined,
        );

      return inZone;
    };

    const cablesToUpdate = projectFeatures.filter(isCableToUpdate);
    const cablesWithUpdatedType = addCableTypes(
      addCableLoads(cablesToUpdate, substations, turbines, turbineTypes),
      filteredCableTypes,
    );
    const updatedCables = cablesWithUpdatedType.filter((cable) => {
      return (
        cablesToUpdate.find((c) => c.id === cable.id)?.properties
          .cableTypeId !== cable.properties.cableTypeId
      );
    });

    updateFeatures({ update: updatedCables });
  }, [
    featureIsRelevant,
    featureMap,
    filteredCableTypes,
    parkId,
    projectFeatures,
    updateFeatures,
    substations,
    turbineTypes,
    turbines,
  ]);

  const maxTurbineRating =
    Math.max(...selTurbineTypes.map((type) => type?.ratedPower ?? 0)) * 1e3;

  const maxCableRating = Math.max(
    ...filteredCableTypes.map((type) => type.powerRating),
  );

  const maxChainLength = calculateMaxChainLength({
    maxCableRating,
    maxTurbineRating,
  });

  const setInteractionWhitelist = useSetRecoilState(
    interactionFeatureTypesWhitelistAtom,
  );
  useEffect(() => {
    setInteractionWhitelist([
      CABLE_CHAIN_POLYGON_PROPERTY_TYPE,
      CABLE_PARTITION_POLYGON_PROPERTY_TYPE,
      CABLE_PROPERTY_TYPE,
    ]);
    return () => {
      setInteractionWhitelist(undefined);
    };
  }, [setInteractionWhitelist]);

  useEffect(() => {
    if (!park || loadingTime !== undefined) return;
    const str = computeImportFeaturesJsonString({
      turbines,
      substations,
      exclusionZones: exclusionZonesWithSectors,
      subAreas,
      mooringLines,
      anchors,
      touchdownPoints,
      park,
      cableCorridors,
      settings: {
        ...settings,
        maxChainLength,
      },
    });
    cw.newContext(str);
    // We only want this to fire when park changes, since it is an expensive operation
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [park, loadingTime]);

  const generateSubstationAndSave = useCallback(async (): Promise<{
    newSubstations: SubstationFeature[];
    lockedSubstations: SubstationFeature[];
    removedFeatureIDs: string[];
  }> => {
    if (!park || turbines.length === 0)
      return {
        newSubstations: [],
        lockedSubstations: [],
        removedFeatureIDs: [],
      };

    const [keptSubstations, removedSubstations] = partition(
      substations,
      (substation) => {
        const typeOfSubstation = substationTypes.find(
          (st) => st.id === substation.properties.substationTypeId,
        )?.type;
        return featureIsLocked(substation) || typeOfSubstation === "onshore";
      },
    );

    const selectedSubstations: SubstationFeature[] = keptSubstations;

    const o = await Sentry.startSpan(
      { name: "Generate substation" },
      async () => {
        const str = computeImportFeaturesJsonString({
          turbines,
          substations: selectedSubstations,
          exclusionZones: exclusionZonesWithSectors,
          subAreas,
          mooringLines,
          anchors,
          touchdownPoints,
          park,
          cableCorridors,
          settings: {
            ...settings,
            maxChainLength,
            routeAroundMooring: false,
          },
        });

        await cw.newContext(str);

        let options: SubstationGenOptions = {
          num_substations: 1,
          num_neighbours: 100,
          max_chain_length: maxChainLength,
          replace_turbines: settings.replaceTurbines,
          distance_threshold: settings.substationBuffer,
        };
        if (settings.numSubstations === "two") {
          options = {
            ...options,
            num_substations: 2,
            num_neighbours: 20,
            max_iter: 10,
            max_product: 3000,
          };
        }

        const ret = await cw.generate_substation(
          selTurbines.map((f) => f.id),
          selectedSubstations.map((f) => f.id),
          options,
          (s: string) => setLoadingMessage(s),
        );
        return ret;
      },
    );

    if ("error" in o) {
      error("Failed to optimize substations:\n" + o.error);
      scream("Failed to optimize substations", { error: o.error });
      return {
        newSubstations: [],
        lockedSubstations: [],
        removedFeatureIDs: [],
      };
    }

    let newSubstations = o.map((s) =>
      substationFeature(
        {
          type: "Point",
          coordinates: Object.values(s.coordinates),
        },
        s.id,
        parkId,
        currentSubstationTypeId,
      ),
    );

    const removedSubstationIDs = removedSubstations.map((f) => f.id);

    let removedFeatureIDs: string[] = removedSubstationIDs;

    // also remove cables directly connected to any removed substations
    const oldCableIds = currentCables
      .filter((f) => {
        const { fromId, toId } = f.properties;
        const remove =
          removedFeatureIDs.includes(fromId) ||
          removedFeatureIDs.includes(toId);
        return remove;
      })
      .map((f) => f.id);
    removedFeatureIDs = removedFeatureIDs.concat(oldCableIds);

    if (settings.replaceTurbines) {
      // remove turbines replaced by substations + associated features
      const replacedTurbineIDs = selTurbines
        .filter((turbine) =>
          o.some((sub) => sub.replaced_turbine_id === turbine.id),
        )
        .map((turbine) => turbine.id);
      const removedChildrenIDs = replacedTurbineIDs
        .map((id) => findFeatureChildrenIds(projectFeatures, id))
        .flat();
      const removedAnchorIDs = mooringLines
        .filter((m) =>
          replacedTurbineIDs.some((id) => m.properties.target === id),
        )
        .map((m) => m.properties.anchor);
      removedFeatureIDs = removedFeatureIDs
        .concat(replacedTurbineIDs)
        .concat(removedAnchorIDs)
        .concat(removedChildrenIDs);
    }

    return {
      newSubstations,
      lockedSubstations: selectedSubstations,
      removedFeatureIDs,
    };
  }, [
    park,
    turbines,
    substations,
    settings,
    substationTypes,
    currentCables,
    exclusionZonesWithSectors,
    subAreas,
    mooringLines,
    anchors,
    touchdownPoints,
    cableCorridors,
    maxChainLength,
    selTurbines,
    error,
    parkId,
    currentSubstationTypeId,
    projectFeatures,
  ]);

  const generateAndSave = useCallback(
    async (
      newSubstations: SubstationFeature[],
      lockedSubstations: SubstationFeature[],
      removedFeatureIDs: string[],
    ) => {
      if (!park) return;

      const selectedSubstations: SubstationFeature[] =
        newSubstations.length > 0
          ? [...newSubstations, ...lockedSubstations]
          : substations;

      const o = await Sentry.startSpan(
        { name: "Generate cables" },
        async () => {
          const str = computeImportFeaturesJsonString({
            turbines: turbines.filter((t) => !removedFeatureIDs.includes(t.id)),
            substations: selectedSubstations,
            exclusionZones: exclusionZonesWithSectors,
            subAreas,
            mooringLines,
            anchors,
            touchdownPoints,
            park,
            cableCorridors,
            settings: {
              ...settings,
              maxChainLength,
              fixedChainsLen: settings.fixedChains
                ? settings.fixedChainsLen
                : undefined,
              fixedChainsNum: settings.fixedChains
                ? settings.fixedChainsNum
                : undefined,
            },
          });

          await cw.newContext(str);

          const ret = await cw.generate(
            selectedSubstations.map((f) => f.id),
            selTurbines
              .map((f) => f.id)
              .filter((id) => !removedFeatureIDs.includes(id)),
            {
              max_chain_length: maxChainLength,
              turbine_buffer_radius: settings.routeAroundTurbines
                ? settings.turbineBuffer ?? DEFAULT_TURBINE_BUFFER
                : undefined,
              max_turbines_to_substation: settings.maxTurbinesToSubstation,
              fixed_chains_len: settings.fixedChains
                ? settings.fixedChainsLen ?? 1
                : undefined,
              fixed_chains_num: settings.fixedChains
                ? settings.fixedChainsNum ?? 1
                : undefined,
              cycle: settings.cycle,
            },
            (s: string) => setLoadingMessage(s),
          );
          return ret;
        },
      );

      if (!o) return;

      if ("error" in o) {
        error(`Failed to generate cables:\n${o.error}`);
        return;
      }
      const { cables, arrays } = o;

      if (!arrays) return;
      if (!cables || cables.length === 0) return;

      const touchedIds = arrays.flatMap((f) => [
        f.substation_id,
        ...f.turbine_ids,
      ]);

      const cableFeatures = cables.map((c) =>
        cableFeature(
          {
            fromId: c.from_id,
            toId: c.to_id,
          },
          {
            type: "LineString",
            coordinates: c.coordinates.map(({ x, y }) => [x, y]),
          },
          parkId,
        ),
      );

      if (settings.cycle) {
        for (const a of arrays) {
          const endpoints = a.turbine_ids
            .map((tid) =>
              cableFeatures.find((c) => {
                const { fromId, toId } = c.properties;
                return (
                  (fromId === a.substation_id && toId === tid) ||
                  (fromId === tid && toId === a.substation_id)
                );
              }),
            )
            .filter(isDefined);
          const longest = maxBy(endpoints, (f) => turf.length(f));
          if (longest) longest.properties.redundancy = true;
          else
            throw scream("could not find cable", {
              a,
              endpoints,
              cableFeatures,
            });
        }
      }

      const requestedTurbines = new Set(selTurbines.map((f) => f.id));
      for (const c of cables) {
        requestedTurbines.delete(c.from_id);
        requestedTurbines.delete(c.to_id);
      }
      const someTurbinesAreMissing = 0 < requestedTurbines.size;
      if (someTurbinesAreMissing)
        warn({
          type: ValidationWarningTypes.TurbinesNotReachable,
          featureIds: Array.from(requestedTurbines),
          parkId: parkId,
        });

      const crossingCableIds = new Set<string>();
      for (const [c1, c2] of uniquePairs(cableFeatures)) {
        for (const [pts1, pts2] of allPairs(
          movingWindow(c1.geometry.coordinates),
          movingWindow(c2.geometry.coordinates),
        )) {
          const seg1: [Position, Position] = [pts1[0], pts1[1]];
          const seg2: [Position, Position] = [pts2[0], pts2[1]];
          if (segmentsAreEqual2(seg1, seg2)) continue;
          const int = getLineIntersection(seg1, seg2);
          if (lineIntersectionIsClearlyOnSegments(int)) {
            warn({
              type: ValidationWarningTypes.CablesCross,
              featureIds: [c1.id, c2.id],
              parkId: parkId,
            });
            crossingCableIds.add(c1.id);
            crossingCableIds.add(c2.id);
          }
        }
      }

      const [redundancy, regulars] = partition(cableFeatures, (f) =>
        Boolean(f.properties.redundancy),
      );

      const withLoads = addCableLoads(
        regulars,
        selectedSubstations,
        turbines,
        turbineTypes,
      );
      const cablesWithTypes = addCableTypes(withLoads, filteredCableTypes);
      const newCables = cablesWithTypes.concat(redundancy);

      // TODO: somehow add this back in once we figure out how to check for cable collisions and stuff.
      // const newCables = cablesWithTypes.map((c) => ({
      //   ...c,
      //   properties: crossingCableIds.has(c.id)
      //     ? {
      //         ...c.properties,
      //         error: true,
      //       }
      //     : c.properties,
      // }));

      const oldCableIds = currentCables
        .filter((f) => {
          const { fromId, toId } = f.properties;
          const remove =
            touchedIds.includes(fromId) || touchedIds.includes(toId);
          return remove;
        })
        .map((f) => f.id);

      updateFeatures({
        add: [...newCables, ...newSubstations],
        remove: [...oldCableIds, ...removedFeatureIDs],
      });
    },
    [
      park,
      substations,
      selTurbines,
      warn,
      turbines,
      turbineTypes,
      filteredCableTypes,
      currentCables,
      updateFeatures,
      exclusionZonesWithSectors,
      subAreas,
      mooringLines,
      anchors,
      touchdownPoints,
      cableCorridors,
      settings,
      maxChainLength,
      error,
      parkId,
    ],
  );

  const clickGenerate = async () => {
    if (!park) return;
    if (!substations) return;
    if (settings === generatedSettings) return;

    if (settings.optimizeSubstation)
      if (
        substations.length !== 0 &&
        !window.confirm(
          "Existing non-locked substations will be removed when optimizing new substation positions.",
        )
      )
        return;

    if (
      hasLockedCurrentCables &&
      !window.confirm("Existing locked cables will be removed.")
    ) {
      return;
    }

    if (
      13 <= maxChainLength &&
      !window.confirm(
        `The current maximal chain length is ${maxChainLength}. This might take a long time to generate. Are you sure you want to continue?`,
      )
    ) {
      return;
    }
    setLoadingTime(Date.now());

    (async () => {
      if (settings.optimizeSubstation) {
        const { newSubstations, lockedSubstations, removedFeatureIDs } =
          await generateSubstationAndSave();
        await generateAndSave(
          newSubstations,
          lockedSubstations,
          removedFeatureIDs,
        );
      } else await generateAndSave([], [], []);
      setGeneratedSettings({ ...settings, maxChainLength });
    })().finally(() => setLoadingTime(undefined));
  };

  const numberOfSubstations = settings.optimizeSubstation
    ? { one: 1, two: 2 }[settings.numSubstations]
    : substations.length;

  const [openSubmenu, setOpenSubmenu] = useRecoilState(openSubmenuAtom);
  const mooringMenuRef = useRef<HTMLButtonElement>(null);
  const turbinesMenuRef = useRef<HTMLButtonElement>(null);
  const limitMenuRef = useRef<HTMLButtonElement>(null);
  const chainMenuRef = useRef<HTMLButtonElement>(null);
  const substationBufferMenuRef = useRef<HTMLButtonElement>(null);

  const existingVoltage = useRecoilValue(getIAVoltageInPark({ parkId }));
  const availableVoltages = useMemo(
    () =>
      selTurbineVoltages.filter((voltage) =>
        cableTypes.some((cableType) => cableType.voltage === voltage),
      ),
    [cableTypes, selTurbineVoltages],
  );

  const defaultSelectedVoltage = useMemo(
    () =>
      availableVoltages.includes(existingVoltage)
        ? existingVoltage
        : availableVoltages[0],
    [availableVoltages, existingVoltage],
  );

  const [parkVoltage, setParkVoltage] = useState<IAVoltageType>(
    defaultSelectedVoltage,
  );
  const cablesWithSelectedVoltage = useMemo(
    () => cableTypes.filter((c) => c.voltage === parkVoltage),
    [cableTypes, parkVoltage],
  );

  useEffect(() => {
    const allWithCurrentVoltage = cableTypes
      .filter((c) => c.voltage === parkVoltage)
      .map((type) => type.id);
    setSelectedCableIds((cur) => {
      const onlyCurrentVoltage = cur.filter((id) =>
        allWithCurrentVoltage.includes(id),
      );
      if (onlyCurrentVoltage.length === 0) return allWithCurrentVoltage;
      return onlyCurrentVoltage;
    });
  }, [cableTypes, parkVoltage, setSelectedCableIds]);

  useEffect(() => {
    const loadWasm = async () => {
      await init();
      const dist = wasm.shortest_inter_turbine_distance(
        turbines.map((t) => ({
          id: t.id,
          position: {
            x: t.geometry.coordinates[0],
            y: t.geometry.coordinates[1],
          },
        })),
      );
      setShortestDistance(dist);
    };
    loadWasm();
  }, [turbines]);

  return (
    <Column
      style={{
        overflowY: "auto",
        gap: 0,
        margin: `0 -${spaceLarge}`,
      }}
    >
      <Column
        ref={scrollBodyRef}
        style={{
          overflowY: "auto",
          padding: `0 ${spaceLarge}`,
        }}
      >
        <Column>
          <SubtitleWithLine text="Cables" />
          <Label>
            <InputTitle>Inter array voltage</InputTitle>
            <Dropdown
              style={{ gap: "0.8rem" }}
              small
              value={parkVoltage}
              onChange={(e) => {
                const newVoltage = parseInt(e.target.value) as IAVoltageType;
                setParkVoltage(newVoltage);
                const allWithNewVoltage = cableTypes
                  .filter((c) => c.voltage === newVoltage)
                  .map((type) => type.id);
                setSelectedCableIds(allWithNewVoltage);
              }}
            >
              <option
                value={IAVoltageType.kV66}
                disabled={!availableVoltages.includes(IAVoltageType.kV66)}
              >{`${IAVoltageType.kV66}kV`}</option>
              <option
                value={IAVoltageType.kV132}
                disabled={!availableVoltages.includes(IAVoltageType.kV132)}
              >
                {`${IAVoltageType.kV132}kV`}
              </option>
            </Dropdown>
          </Label>

          <OverlineText style={{ paddingTop: "1.6rem" }}>
            Select cable types
          </OverlineText>
          <Column>
            <Row style={{ justifyContent: "flex-start", gap: "1.2rem'" }}>
              <Checkbox
                label={"Select all"}
                labelPlacement="after"
                checked={
                  cablesWithSelectedVoltage.every((c) =>
                    selectedCableIds.includes(c.id),
                  )
                    ? true
                    : cablesWithSelectedVoltage.every(
                          (c) => !selectedCableIds.includes(c.id),
                        )
                      ? false
                      : "indeterminate"
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedCableIds(
                      cablesWithSelectedVoltage.map((type) => type.id),
                    );
                  } else {
                    setSelectedCableIds([]);
                  }
                }}
                style={{ marginTop: 0, marginBottom: 0 }}
              />
            </Row>
            <Column>
              {cablesWithSelectedVoltage.map((type) => {
                const checked = selectedCableIds.includes(type.id);
                return (
                  <Row
                    key={type.id + Math.random()}
                    style={{ justifyContent: "flex-start" }}
                  >
                    <Checkbox
                      label={`${type.name} (${type.powerRating / 1e6}MW)`}
                      checked={checked}
                      // Disable the checkbox if it's the only one checked
                      disabled={checked && filteredCableTypes.length === 1}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedCableIds((ids) => [
                            ...ids.filter((id) => id !== type.id),
                            type.id,
                          ]);
                        } else {
                          setSelectedCableIds((ids) =>
                            ids.filter((id) => id !== type.id),
                          );
                        }
                      }}
                      style={{ marginTop: 0, marginBottom: 0 }}
                      labelPlacement="after"
                    />
                  </Row>
                );
              })}
            </Column>
          </Column>
          <Row>
            <Text style={{ color: colors.secondaryText }}>
              Max turbine chain length: {maxChainLength}
            </Text>
          </Row>
        </Column>

        <Column>
          <OverlineText style={{ paddingTop: "1.6rem" }}>Options</OverlineText>
          <Column style={{ gap: spaceMedium }}>
            <ControlRow enabled={Boolean(settings.routeAroundMooring)}>
              <InputTitleWrapper>
                <Toggle
                  size={ToggleSize.TINY}
                  checked={settings.routeAroundMooring ?? false}
                  onChange={(e) => {
                    setOpenSubmenu(e.target.checked ? "mooring" : undefined);
                    setSettings({
                      ...settings,
                      routeAroundMooring: e.target.checked,
                    });
                  }}
                />
                <Tooltip text="Route cables around anchors and mooring lines.  For large parks this may slow the generation down.">
                  <InputTitle>Route around mooring</InputTitle>
                </Tooltip>
              </InputTitleWrapper>
              <IconBtn
                active={openSubmenu === "mooring"}
                backgroundColor={colors.surfaceButtonSecondary}
                size="1.4rem"
                id="cable-submenu-open-mooring"
                ref={mooringMenuRef}
                onClick={() => {
                  setOpenSubmenu(replaceOrUndefined("mooring"));
                }}
              >
                <OpenRight />
              </IconBtn>
              {openSubmenu === "mooring" && (
                <Anchor
                  baseRef={mooringMenuRef}
                  floatPlace="bottomLeft"
                  basePlace="topRight"
                >
                  <RouteAroundMooring />
                </Anchor>
              )}
            </ControlRow>

            <ControlRow enabled={Boolean(settings.routeAroundTurbines)}>
              <InputTitleWrapper>
                <Toggle
                  size={ToggleSize.TINY}
                  checked={settings.routeAroundTurbines ?? false}
                  onChange={(e) => {
                    setOpenSubmenu(e.target.checked ? "turbines" : undefined);
                    setSettings({
                      ...settings,
                      routeAroundTurbines: e.target.checked,
                    });
                  }}
                />
                <InputTitle>Route around turbines</InputTitle>
              </InputTitleWrapper>
              <IconBtn
                active={openSubmenu === "turbines"}
                backgroundColor={colors.surfaceButtonSecondary}
                size="1.4rem"
                id="cable-submenu-open-turbines"
                ref={turbinesMenuRef}
                onClick={() => {
                  setOpenSubmenu(replaceOrUndefined("turbines"));
                }}
              >
                <OpenRight />
              </IconBtn>
              {openSubmenu === "turbines" && (
                <Anchor
                  baseRef={turbinesMenuRef}
                  floatPlace="bottomLeft"
                  basePlace="topRight"
                >
                  <RouteAroundTurbines />
                </Anchor>
              )}
            </ControlRow>

            <ControlRow enabled={Boolean(settings.maxTurbinesToSubstation)}>
              <InputTitleWrapper>
                <Toggle
                  size={ToggleSize.TINY}
                  checked={isDefined(settings.maxTurbinesToSubstation)}
                  onChange={(e) => {
                    setOpenSubmenu(e.target.checked ? "substation" : undefined);
                    setSettings({
                      ...settings,
                      maxTurbinesToSubstation: e.target.checked
                        ? turbines.length
                        : undefined,
                    });
                  }}
                />
                <InputTitle>Substation controls</InputTitle>
              </InputTitleWrapper>
              <IconBtn
                active={openSubmenu === "substation"}
                backgroundColor={colors.surfaceButtonSecondary}
                size="1.4rem"
                id="cable-submenu-open-substation"
                ref={limitMenuRef}
                onClick={() => {
                  setOpenSubmenu(replaceOrUndefined("substation"));
                }}
              >
                <OpenRight />
              </IconBtn>
              {openSubmenu === "substation" && (
                <Anchor
                  baseRef={limitMenuRef}
                  floatPlace="bottomLeft"
                  basePlace="topRight"
                >
                  <SubstationSettings
                    turbines={turbines}
                    numberOfSubstations={numberOfSubstations}
                  />
                </Anchor>
              )}
            </ControlRow>

            <ControlRow enabled={Boolean(settings.fixedChains)}>
              <InputTitleWrapper>
                <Toggle
                  size={ToggleSize.TINY}
                  checked={Boolean(settings.fixedChains)}
                  onChange={(e) => {
                    setOpenSubmenu(e.target.checked ? "chains" : undefined);
                    setSettings({
                      ...settings,
                      fixedChains: e.target.checked,
                    });
                  }}
                />
                <InputTitle>Chain controls</InputTitle>
              </InputTitleWrapper>
              <IconBtn
                active={openSubmenu === "chains"}
                backgroundColor={colors.surfaceButtonSecondary}
                size="1.4rem"
                id="cable-submenu-open-chains"
                ref={chainMenuRef}
                onClick={() => {
                  setOpenSubmenu(replaceOrUndefined("chains"));
                }}
              >
                <OpenRight />
              </IconBtn>
              {openSubmenu === "chains" && (
                <Anchor
                  baseRef={chainMenuRef}
                  floatPlace="bottomLeft"
                  basePlace="topRight"
                >
                  <ChainSettings turbines={turbines} />
                </Anchor>
              )}
            </ControlRow>

            <ControlRow enabled={Boolean(settings.cycle)}>
              <InputTitleWrapper>
                <Toggle
                  size={ToggleSize.TINY}
                  checked={Boolean(settings.cycle)}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      cycle: e.target.checked,
                    });
                  }}
                />
                <InputTitle>Connect arrays in loops</InputTitle>
                <HelpTooltip text="Connect each turbine array in a loop, and make the longest cable touching the substation a redundancy cable." />
              </InputTitleWrapper>
            </ControlRow>
          </Column>
          <Column style={{ paddingTop: "1rem", paddingBottom: "1.6rem" }}>
            <SubtitleWithLine text="Substation" />
            <ControlRow enabled={Boolean(settings.fixedChains)}>
              <InputTitleWrapper>
                <Tooltip text="Search for substation positions that minimise the total cable length of the park.">
                  <Toggle
                    size={ToggleSize.TINY}
                    checked={Boolean(settings.optimizeSubstation ?? false)}
                    onChange={(e) => {
                      setSettings({
                        ...settings,
                        optimizeSubstation: e.target.checked,
                      });
                    }}
                  />
                </Tooltip>
                <InputTitle>Optimize substation placement</InputTitle>
                <HelpLink article={ARTICLE_SUBSTATION_GEN} />
              </InputTitleWrapper>
            </ControlRow>

            {settings.optimizeSubstation && (
              <div style={{ paddingLeft: "3.5rem" }}>
                <Column>
                  <Label>
                    <InputTitle>Offshore substation type</InputTitle>
                    {(!currentSubstationTypeId ||
                      offshoreSubstationTypes.length === 0) && (
                      <SimpleAlert
                        text={
                          "There are no offshore substation types in this project. Openfeature settings to add substation types."
                        }
                        type={"error"}
                      />
                    )}
                    <Dropdown
                      small
                      id="substation"
                      disabled={offshoreSubstationTypes.length === 0}
                      value={currentSubstationTypeId}
                      onChange={(e) => {
                        setCurrentSubstationTypeId(e.target.value);
                      }}
                    >
                      {offshoreSubstationTypes.map((s) => (
                        <option key={s.id} value={s.id}>
                          {`${s.name}`}
                        </option>
                      ))}
                    </Dropdown>
                  </Label>
                </Column>
                <Column style={{ paddingTop: "1rem" }}>
                  <OverlineText>Options</OverlineText>
                  <Row>
                    <Tooltip text="The number of substations to optimize.">
                      <RadioGroup style={{ paddingBottom: "1rem" }}>
                        <Radio
                          label="1 substation"
                          checked={settings.numSubstations === "one"}
                          onChange={() => {
                            setSettings({
                              ...settings,
                              numSubstations: "one",
                            });
                          }}
                        />
                        <Radio
                          label="2 substations"
                          checked={settings.numSubstations === "two"}
                          onChange={() => {
                            setSettings({
                              ...settings,
                              numSubstations: "two",
                            });
                          }}
                        />
                      </RadioGroup>
                    </Tooltip>
                  </Row>
                  <ControlRow enabled={Boolean(settings.routeAroundMooring)}>
                    <InputTitleWrapper>
                      <Toggle
                        size={ToggleSize.TINY}
                        checked={isDefined(settings.substationBuffer)}
                        disabled={settings.replaceTurbines ?? false}
                        onChange={(e) => {
                          setOpenSubmenu(
                            e.target.checked ? "substation-buffer" : undefined,
                          );
                          setSettings({
                            ...settings,
                            substationBuffer: e.target.checked
                              ? DEFAULT_SUBSTATION_BUFFER
                              : undefined,
                          });
                        }}
                      />
                      <InputTitle>Buffer substation</InputTitle>
                      <HelpTooltip
                        style={{ display: "inline-flex" }}
                        text="Adjust the minimum distance between substations and turbines."
                        size={10}
                      />
                    </InputTitleWrapper>

                    <IconBtn
                      backgroundColor={colors.surfaceButtonSecondary}
                      size="1.4rem"
                      id="cable-submenu-open-substation-buffer"
                      ref={substationBufferMenuRef}
                      disabled={settings.replaceTurbines ?? false}
                      onClick={() => {
                        setOpenSubmenu(replaceOrUndefined("substation-buffer"));
                      }}
                    >
                      <OpenRight />
                    </IconBtn>
                    {openSubmenu === "substation-buffer" && (
                      <Anchor
                        baseRef={substationBufferMenuRef}
                        floatPlace="bottomLeft"
                        basePlace="topRight"
                      >
                        <SubstationBuffer
                          minimumTurbineDistance={shortestDistance}
                        />
                      </Anchor>
                    )}
                  </ControlRow>
                  <ControlRow enabled={true}>
                    <InputTitleWrapper>
                      <Toggle
                        size={ToggleSize.TINY}
                        checked={settings.replaceTurbines ?? false}
                        onChange={(e) => {
                          setSettings({
                            ...settings,
                            replaceTurbines: e.target.checked,
                          });
                        }}
                      />
                      <InputTitle>Place substation in grid</InputTitle>
                      <HelpTooltip
                        style={{ display: "inline-flex" }}
                        text="Place generated substations in the turbine grid by replacing existing turbines. If not checked, then substations will be placed away from turbines."
                        size={10}
                      />
                    </InputTitleWrapper>
                  </ControlRow>
                </Column>
              </div>
            )}
          </Column>
        </Column>
      </Column>

      {loadingTime !== undefined ? (
        <SkeletonText
          text={loadingMessage + "..."}
          style={{ flexGrow: 1, flexShrink: 0, margin: "0 1rem" }}
        />
      ) : null}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          gap: spaceMedium,
          borderTop: `1px solid ${colors.borderSubtle}`,
          padding: `${spaceLarge} ${spaceLarge} 0 ${spaceLarge}`,
        }}
      >
        <Button
          text="Assign cable types"
          buttonType="secondary"
          tooltip={"Recalculate cable types without changing the layout."}
          disabled={loadingTime !== undefined}
          onClick={() => {
            assignCableTypes();
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignItems: "baseline",
            gap: spaceMedium,
          }}
        >
          {loadingTime !== undefined ? (
            <SecondTimer from={loadingTime} />
          ) : null}
          <Button
            style={{ justifySelf: "end" }}
            text={loadingTime === undefined ? "Generate" : `Abort`}
            buttonType={loadingTime === undefined ? "primary" : "secondary"}
            disabled={
              selectedCableIds.length === 0 ||
              (substations.length === 0 &&
                !settings.optimizeSubstation &&
                loadingTime === undefined)
                ? true
                : false
            }
            onClick={() => {
              if (loadingTime === undefined) {
                Mixpanel.track("Triggered cable optimization", {
                  ...settings,
                });
                clickGenerate();
              } else {
                Mixpanel.track("Cancelled cable optimization", {
                  ...settings,
                });
                abortGeneration();
              }
            }}
            title={
              substations.length === 0 && !settings.optimizeSubstation
                ? "Place a substation first"
                : "Generate"
            }
          />
        </div>
      </div>
    </Column>
  );
};

const NoTurbines = () => {
  return (
    <div>
      <SimpleAlert
        text={"There are no turbines in the selected park or sub area."}
        type={"error"}
      />
    </div>
  );
};

const MissingCables = () => {
  const setModalType = useSetRecoilState(modalTypeOpenAtom);

  return (
    <>
      <p>There are no cables in this project!</p>
      <p>
        Open
        <Button
          style={{ display: "inline-flex", padding: spaceTiny }}
          buttonType="text"
          text="cable settings"
          onClick={() => {
            setModalType({
              modalType: FeatureSettingsModalTypeV2,
              metadata: { selectedMenuId: "cables" },
            });
          }}
        />
        to add cables to the project.
      </p>
    </>
  );
};

const NoAvailableCablesForTurbineTypesWarning = () => {
  const setModalType = useSetRecoilState(modalTypeOpenAtom);

  return (
    <>
      <p>
        You have no cable types that can be connected to the turbines of this
        park.
      </p>
      <p>
        Open
        <Button
          style={{ display: "inline-flex", padding: spaceTiny }}
          buttonType="text"
          text="Cable settings"
          onClick={() => {
            setModalType({
              modalType: FeatureSettingsModalTypeV2,
              metadata: { selectedMenuId: "cables" },
            });
          }}
        />
        to add cables to the project, or
        <Button
          style={{ display: "inline-flex", padding: spaceTiny }}
          buttonType="text"
          text="Turbine settings"
          onClick={() => {
            setModalType({
              modalType: FeatureSettingsModalTypeV2,
              metadata: { selectedMenuId: "turbines" },
            });
          }}
        />
        to add or change turbine types.
      </p>
    </>
  );
};

const wrapperStyle: CSSProperties = {
  maxHeight: "calc(100vh - 20rem)",
  boxSizing: "border-box",
};

const innerStyle: CSSProperties = {
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
};

const Inner = ({
  parkId,
  abortGeneration,
}: {
  parkId: string;
  abortGeneration(): void;
}) => {
  const allCables = useRecoilValue(allCableTypesSelector);
  const allCableTypes = useRecoilValue(allCableTypesSelector);
  const parkTurbines = useRecoilValue(getTurbinesSelectorFamily({ parkId }));
  const selTurbines = useRecoilValue(getSelTurbinesSelectorFamily({ parkId }));
  const allTurbineTypes = useRecoilValue(allSimpleTurbineTypesSelector);
  const parkTurbineTypes = useMemo(
    () =>
      allTurbineTypes.filter((turbineType) =>
        parkTurbines.some(
          (turbine) => turbine.properties.turbineTypeId === turbineType.id,
        ),
      ),
    [allTurbineTypes, parkTurbines],
  );

  const availableVoltages = useMemo(
    () =>
      parkTurbineTypes
        .flatMap((tt) => tt.voltage)
        .filter((voltage) =>
          allCableTypes.some((cableType) => cableType.voltage === voltage),
        ),
    [allCableTypes, parkTurbineTypes],
  );

  if (selTurbines.length === 0) return <NoTurbines />;
  if (allCables.length === 0) return <MissingCables />;
  if (availableVoltages.length === 0)
    return <NoAvailableCablesForTurbineTypesWarning />;
  return (
    <>
      <SettingsComponent abortGeneration={abortGeneration} />
      <TurbineBufferZone />
      <CableFreeSectorFromSelection />
    </>
  );
};

const GenerateCablesFrame = () => {
  const parkId = useRecoilValueDef(parkIdSelector);
  const branchId = useRecoilValueDef(branchIdSelector);
  const popupRef = useRef<HTMLDivElement>(null);
  const [_, setLeftMenuActiveMode] = useDrawMode();
  const [, setLayoutControlActive] = useDrawMode();
  const setGenerateCablesLoadingTime = useSetRecoilState(
    generateCablesLoadingTimeAtom,
  );

  const getIsRunningGeneration = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        return (
          snapshot.getLoadable(generateCablesLoadingTimeAtom).getValue() !==
          undefined
        );
      },
    [],
  );

  const abortGeneration = useCallback(() => {
    cw.reset();
    setGenerateCablesLoadingTime(undefined);
  }, [setGenerateCablesLoadingTime]);

  const safeToCloseGeneration = useCallback((): boolean => {
    const isRunning = getIsRunningGeneration();
    return (
      !isRunning ||
      confirm(
        "Cable generation is in progress. Are you sure you want to leave?",
      )
    );
  }, [getIsRunningGeneration]);

  useClickOutside(
    popupRef,
    (event: MouseEvent) => {
      const abort = safeToCloseGeneration();
      if (abort) {
        setLayoutControlActive(undefined);
        return;
      }
      event.preventDefault();
      event.stopPropagation();
    },
    (target: EventTarget) => {
      if (!(target instanceof HTMLElement)) {
        return false;
      }
      if (target.dataset?.["isModal"]) {
        return true;
      }
      // Ignore clicks for any of the submenus
      if (
        target.id.startsWith("cable-submenu-") ||
        (!getIsRunningGeneration() &&
          target.id === `button-${GenerateCablesMenuType}`)
      ) {
        return true;
      }
      if (target.id.startsWith("button-")) {
        return false;
      }
      //ignoring click on the cable statistics menu
      return target.id === `button-${TopRightMenuOptions.cableStatistics}`;
    },
    { ignoreDragClicks: true, runCheckOnClick: true },
  );

  useEffect(() => {
    // Abort any running generation when closing the frame
    return () => {
      if (getIsRunningGeneration()) {
        abortGeneration();
      }
    };
  }, [abortGeneration, getIsRunningGeneration]);

  return (
    <MenuFrame
      id={CablesMenuType}
      title="Generate inter array"
      icon={<HelpLink article={ARTICLE_CABLE_GEN} />}
      ref={popupRef}
      onExit={() => {
        const abort = safeToCloseGeneration();
        if (abort) {
          setLeftMenuActiveMode(undefined);
          return;
        }
      }}
      style={wrapperStyle}
      validationError={
        <SubstationInsideNoCableExclusionZonesValidationError
          parkId={parkId}
          branchId={branchId}
        />
      }
    >
      <Column style={innerStyle}>
        <React.Suspense fallback={<Spinner />}>
          <Inner parkId={parkId} abortGeneration={abortGeneration} />
        </React.Suspense>
      </Column>
    </MenuFrame>
  );
};

const GenerateCables = () => {
  const parkId = useRecoilValueDef(parkIdSelector);
  const [leftMenuActiveMode] = useDrawMode();
  if (leftMenuActiveMode !== GenerateCablesMenuType || !parkId) return null;

  return <GenerateCablesFrame />;
};

export default GenerateCables;
