import { useMemo } from "react";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import {
  organisationIdSelector,
  projectIdSelector,
} from "../../../state/pathParams";
import {
  adminInOrganisationSelectorFamily,
  editorAccessProjectSelector,
  orgAnalysisManageAccessSelector,
} from "../../../state/user";
import { ProjectBranchInfo, UsageContainerInner } from "./common";
import { Header } from "./style";
import { TurbineTypeUsageType } from "../../../types/turbines";
import { analysisConfigurationUsageAtomFamily } from "../../../state/configuration";

export default function AnalysisConfigurationUsageCurrentProject({
  analysisConfigurationId,
}: {
  analysisConfigurationId: string;
}) {
  const editorAccessProject = useRecoilValue(editorAccessProjectSelector);
  const projectId = useRecoilValue(projectIdSelector);

  if (!editorAccessProject || !projectId) return null;

  return (
    <AnalysisConfigurationUsageInner
      analysisConfigurationId={analysisConfigurationId}
      nodeId={projectId}
    />
  );
}

export function AnalysisConfigurationUsageLibrary({
  analysisConfigurationId,
}: {
  analysisConfigurationId: string;
}) {
  const organisationId = useRecoilValue(organisationIdSelector);
  const adminInOrg = useRecoilValue(
    adminInOrganisationSelectorFamily({ organisationId }),
  );
  const hasOrgAnalysisAccess = useRecoilValue(orgAnalysisManageAccessSelector);

  if (!organisationId) return null;
  if (!adminInOrg && !hasOrgAnalysisAccess) return null;

  return (
    <AnalysisConfigurationUsageInner
      analysisConfigurationId={analysisConfigurationId}
      nodeId={organisationId}
    />
  );
}

function AnalysisConfigurationUsageInner({
  analysisConfigurationId,
  nodeId,
}: {
  analysisConfigurationId: string;
  nodeId: string;
}) {
  const usageLoadable = useRecoilValueLoadable(
    analysisConfigurationUsageAtomFamily({
      nodeId,
      analysisConfigurationId,
    }),
  );

  return (
    <UsageContainerInner
      loadable={usageLoadable}
      text="This configuration is used in: "
      typeName="branches"
    >
      <ExtraInfo usage={usageLoadable.contents} />
    </UsageContainerInner>
  );
}

function ExtraInfo({ usage }: { usage: TurbineTypeUsageType[] }) {
  const uniqueBranches = useMemo(
    () =>
      usage.reduce<TurbineTypeUsageType[]>(
        (pre, cur) =>
          pre.some((p) => p.branchId === cur.branchId) ? pre : pre.concat(cur),
        [],
      ),
    [usage],
  );

  return (
    <>
      <Header>Analysis configuration usage</Header>
      {usage.length > 0 && (
        <div
          style={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
        >
          {uniqueBranches.map((u) => (
            <ProjectBranchInfo key={u.branchId} usage={u} />
          ))}
        </div>
      )}
    </>
  );
}
