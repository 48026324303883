import { Place } from "../../General/Anchor";
import CloseableHint from "../CloseableHints/CloseableHint";
import addExternalDataToProjectHelpVideo from "./addExternalDataToProjectHelpVideo.mp4";

export const addExternalDataToProjectHelpHintType =
  "AddExternalDataToProjectHelpHintType";

const AddExternalDataToProjectHelp = ({
  closeCallback,
  gotItCallback,
  position,
}: {
  closeCallback: () => void;
  gotItCallback?: () => void;
  position?: Place;
}) => {
  return (
    <CloseableHint
      title={"Add external data to project"}
      closeCallback={closeCallback}
      gotItCallback={gotItCallback}
      timeToDissapearMS={10000}
      videosrc={addExternalDataToProjectHelpVideo}
      position={position}
    >
      {`Add external element to project by selecting the feature and clicking 'Add to element'`}
    </CloseableHint>
  );
};

export default AddExternalDataToProjectHelp;
