/**
 * This file is generated from spec.yaml.  Do not edit it directly.
 * To regenerate it, run `npm run gen`.
 */
import * as z from "zod";

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Components {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  export namespace Schemas {
    export const Type = z.union([
      z.literal("folder"),
      z.literal("personal_folder"),
      z.literal("project"),
      z.literal("organisation"),
    ]);
    export const OrganisationResources = z.union([
      z.literal("org_turbine_manage"),
      z.literal("org_foundation_manage"),
      z.literal("org_cable_manage"),
      z.literal("org_analysis_manage"),
      z.literal("org_financial_manage"),
      z.literal("org_data_package_manage"),
    ]);
    export const SharedNodeMutable = z
      .object({
        name: z.string().optional(),
        parent_id: z.string().nullable().optional(),
        type: Type.optional(),
      })
      .passthrough();
    export const SharedNodeInformation_ = z
      .object({
        name: z.string().optional(),
        parent_id: z.string().nullable().optional(),
        type: z
          .union([
            z.literal("folder"),
            z.literal("personal_folder"),
            z.literal("project"),
            z.literal("organisation"),
          ])
          .optional(),
      })
      .passthrough()
      .and(
        z
          .object({
            id: z.string().optional(),
            organisation_id: z.string().nullable().optional(),
            legacy_id: z.string().optional(),
            created_at: z.number().optional(),
            archived_at: z.number().nullable().optional(),
            author: z.string().optional(),
          })
          .passthrough(),
      );
    export const SharedNodeInformation = z
      .object({
        name: z.string(),
        parent_id: z.string().nullable().optional(),
        type: z.union([
          z.literal("folder"),
          z.literal("personal_folder"),
          z.literal("project"),
          z.literal("organisation"),
        ]),
      })
      .passthrough()
      .and(
        z
          .object({
            id: z.string(),
            organisation_id: z.string().nullable().optional(),
            legacy_id: z.string(),
            created_at: z.number(),
            archived_at: z.number().nullable().optional(),
            author: z.string(),
          })
          .passthrough(),
      )
      .and(z.object({}).passthrough());
    export const PersonalFolderNodeInformation = z
      .object({
        name: z.string(),
        parent_id: z.string().nullable().optional(),
        type: z.union([
          z.literal("folder"),
          z.literal("personal_folder"),
          z.literal("project"),
          z.literal("organisation"),
        ]),
      })
      .passthrough()
      .and(
        z
          .object({
            id: z.string(),
            organisation_id: z.string().nullable().optional(),
            legacy_id: z.string(),
            created_at: z.number(),
            archived_at: z.number().nullable().optional(),
            author: z.string(),
          })
          .passthrough(),
      )
      .and(z.object({}).passthrough())
      .and(
        z
          .object({
            type: z.literal("personal_folder"),
          })
          .passthrough(),
      );
    export const PersonalFolderNodeInformation_ = z
      .object({
        name: z.string().optional(),
        parent_id: z.string().nullable().optional(),
        type: z
          .union([
            z.literal("folder"),
            z.literal("personal_folder"),
            z.literal("project"),
            z.literal("organisation"),
          ])
          .optional(),
      })
      .passthrough()
      .and(
        z
          .object({
            id: z.string().optional(),
            organisation_id: z.string().nullable().optional(),
            legacy_id: z.string().optional(),
            created_at: z.number().optional(),
            archived_at: z.number().nullable().optional(),
            author: z.string().optional(),
          })
          .passthrough(),
      )
      .and(
        z
          .object({
            type: z.literal("personal_folder").optional(),
          })
          .passthrough(),
      );
    export const FolderNodeInformation = z
      .object({
        name: z.string(),
        parent_id: z.string().nullable().optional(),
        type: z.union([
          z.literal("folder"),
          z.literal("personal_folder"),
          z.literal("project"),
          z.literal("organisation"),
        ]),
      })
      .passthrough()
      .and(
        z
          .object({
            id: z.string(),
            organisation_id: z.string().nullable().optional(),
            legacy_id: z.string(),
            created_at: z.number(),
            archived_at: z.number().nullable().optional(),
            author: z.string(),
          })
          .passthrough(),
      )
      .and(z.object({}).passthrough())
      .and(
        z
          .object({
            type: z.literal("folder"),
          })
          .passthrough(),
      );
    export const FolderNodeInformation_ = z
      .object({
        name: z.string().optional(),
        parent_id: z.string().nullable().optional(),
        type: z
          .union([
            z.literal("folder"),
            z.literal("personal_folder"),
            z.literal("project"),
            z.literal("organisation"),
          ])
          .optional(),
      })
      .passthrough()
      .and(
        z
          .object({
            id: z.string().optional(),
            organisation_id: z.string().nullable().optional(),
            legacy_id: z.string().optional(),
            created_at: z.number().optional(),
            archived_at: z.number().nullable().optional(),
            author: z.string().optional(),
          })
          .passthrough(),
      )
      .and(
        z
          .object({
            type: z.literal("folder").optional(),
          })
          .passthrough(),
      );
    export const OrganisationNodeInformation = z
      .object({
        name: z.string(),
        parent_id: z.string().nullable().optional(),
        type: z.union([
          z.literal("folder"),
          z.literal("personal_folder"),
          z.literal("project"),
          z.literal("organisation"),
        ]),
      })
      .passthrough()
      .and(
        z
          .object({
            id: z.string(),
            organisation_id: z.string().nullable().optional(),
            legacy_id: z.string(),
            created_at: z.number(),
            archived_at: z.number().nullable().optional(),
            author: z.string(),
          })
          .passthrough(),
      )
      .and(z.object({}).passthrough())
      .and(
        z
          .object({
            type: z.literal("organisation"),
          })
          .passthrough(),
      );
    export const OrganisationNodeInformation_ = z
      .object({
        name: z.string().optional(),
        parent_id: z.string().nullable().optional(),
        type: z
          .union([
            z.literal("folder"),
            z.literal("personal_folder"),
            z.literal("project"),
            z.literal("organisation"),
          ])
          .optional(),
      })
      .passthrough()
      .and(
        z
          .object({
            id: z.string().optional(),
            organisation_id: z.string().nullable().optional(),
            legacy_id: z.string().optional(),
            created_at: z.number().optional(),
            archived_at: z.number().nullable().optional(),
            author: z.string().optional(),
          })
          .passthrough(),
      )
      .and(
        z
          .object({
            type: z.literal("organisation").optional(),
          })
          .passthrough(),
      );
    export const Project = z
      .object({
        location: z.string().nullable().optional(),
        status: z.string().nullable().optional(),
        tutorial_id: z.string().nullable().optional(),
        main_branch_id: z.string().nullable().optional(),
      })
      .passthrough();
    export const ProjectNodeInformation = z
      .object({
        name: z.string(),
        parent_id: z.string().nullable().optional(),
        type: z.union([
          z.literal("folder"),
          z.literal("personal_folder"),
          z.literal("project"),
          z.literal("organisation"),
        ]),
      })
      .passthrough()
      .and(
        z
          .object({
            id: z.string(),
            organisation_id: z.string().nullable().optional(),
            legacy_id: z.string(),
            created_at: z.number(),
            archived_at: z.number().nullable().optional(),
            author: z.string(),
          })
          .passthrough(),
      )
      .and(z.object({}).passthrough())
      .and(
        z
          .object({
            location: z.string().nullable().optional(),
            status: z.string().nullable().optional(),
            tutorial_id: z.string().nullable().optional(),
            main_branch_id: z.string().nullable().optional(),
          })
          .passthrough(),
      )
      .and(
        z
          .object({
            type: z.literal("project"),
          })
          .passthrough(),
      );
    export const ProjectNodeInformation_ = z
      .object({
        name: z.string().optional(),
        parent_id: z.string().nullable().optional(),
        type: z
          .union([
            z.literal("folder"),
            z.literal("personal_folder"),
            z.literal("project"),
            z.literal("organisation"),
          ])
          .optional(),
      })
      .passthrough()
      .and(
        z
          .object({
            id: z.string().optional(),
            organisation_id: z.string().nullable().optional(),
            legacy_id: z.string().optional(),
            created_at: z.number().optional(),
            archived_at: z.number().nullable().optional(),
            author: z.string().optional(),
          })
          .passthrough(),
      )
      .and(
        z
          .object({
            location: z.string().nullable().optional(),
            status: z.string().nullable().optional(),
            tutorial_id: z.string().nullable().optional(),
            main_branch_id: z.string().nullable().optional(),
          })
          .passthrough(),
      )
      .and(
        z
          .object({
            type: z.literal("project").optional(),
          })
          .passthrough(),
      );
    export const OrganisationUserInformation = z
      .object({
        id: z.string(),
        name: z.string(),
        teams: z.array(z.string()),
      })
      .passthrough();
    export const OrganisationInformation = z
      .object({
        id: z.string(),
        name: z.string(),
      })
      .passthrough();
    export const NodeInformation = z.union([
      ProjectNodeInformation,
      PersonalFolderNodeInformation,
      FolderNodeInformation,
      OrganisationNodeInformation,
    ]);
    export const NodeInformation_ = z.union([
      ProjectNodeInformation_,
      PersonalFolderNodeInformation_,
      FolderNodeInformation_,
      OrganisationNodeInformation_,
    ]);
    export const ProjectNodeMutable = z
      .object({
        name: z.string().optional(),
        parent_id: z.string().nullable().optional(),
        type: z
          .union([
            z.literal("folder"),
            z.literal("personal_folder"),
            z.literal("project"),
            z.literal("organisation"),
          ])
          .optional(),
      })
      .passthrough()
      .and(
        z
          .object({
            type: z
              .union([
                z.literal("folder"),
                z.literal("personal_folder"),
                z.literal("project"),
                z.literal("organisation"),
              ])
              .optional(),
            location: z.string().optional(),
            status: z.string().optional(),
            tutorial_id: z.string().optional(),
            main_branch_id: z.string().optional(),
          })
          .passthrough(),
      );
    export const PersonalFolderNodeMutable = z
      .object({
        name: z.string().optional(),
        parent_id: z.string().nullable().optional(),
        type: z
          .union([
            z.literal("folder"),
            z.literal("personal_folder"),
            z.literal("project"),
            z.literal("organisation"),
          ])
          .optional(),
      })
      .passthrough()
      .and(
        z
          .object({
            type: z
              .union([
                z.literal("folder"),
                z.literal("personal_folder"),
                z.literal("project"),
                z.literal("organisation"),
              ])
              .optional(),
            isArchived: z.boolean().optional(),
          })
          .passthrough(),
      );
    export const FolderNodeMutable = z
      .object({
        name: z.string(),
        parent_id: z.string().nullable().optional(),
        type: z.union([
          z.literal("folder"),
          z.literal("personal_folder"),
          z.literal("project"),
          z.literal("organisation"),
        ]),
      })
      .passthrough()
      .and(
        z
          .object({
            type: z.literal("folder"),
            isArchived: z.boolean().optional(),
          })
          .passthrough(),
      );
    export const NodeMutable = z.union([
      ProjectNodeMutable,
      FolderNodeMutable,
      PersonalFolderNodeMutable,
    ]);
    export const CreateNodeFolderBody = z
      .object({
        name: z.string(),
        parent_id: z.string(),
        children: z.array(z.string()).optional(),
        type: z.literal("folder"),
      })
      .passthrough();
    export const CreateNodeProjectBody = z
      .object({
        name: z.string(),
        parent_id: z.string(),
        type: z.literal("project"),
        legacy_id: z.string(),
      })
      .passthrough();
    export const CreateGroupBody = z
      .object({
        name: z.string(),
      })
      .passthrough();
    export const CreateGroupMembershipBody = z
      .object({
        userId: z.string(),
      })
      .passthrough();
    export const CreateGroupNodeAccessBody = z
      .object({
        resourceName: z.string(),
      })
      .passthrough();
    export const Group = z
      .object({
        id: z.string(),
        name: z.string(),
        author: z.string(),
        organisation_id: z.string(),
        created_at: z.number(),
        is_user: z.boolean(),
      })
      .passthrough();
    export const UserGroup = z
      .object({
        user_id: z.string(),
        group_id: z.string(),
        created_at: z.number(),
        author: z.string(),
      })
      .passthrough();
    export const GroupMembership = z
      .object({
        user_id: z.string(),
        group_id: z.string(),
        created_at: z.number(),
        author: z.string(),
        group_name: z.string(),
      })
      .passthrough();
    export const GroupNodeAccess = z
      .object({
        group_id: z.string(),
        node_id: z.string(),
        resource_name: z.string(),
      })
      .passthrough();
    export const GroupNodeAccessWithMeta = z
      .object({
        group_id: z.string(),
        node_id: z.string(),
        resource_name: z.string(),
        group_name: z.string(),
      })
      .passthrough();
    export const UserNodeAccess = z
      .object({
        user_id: z.string(),
        node_id: z.string(),
        resource_name: z.string(),
      })
      .passthrough();
    export const UserNodeAccessWithMeta = z
      .object({
        user_id: z.string(),
        node_id: z.string(),
        resource_name: z.string(),
        nickname: z.string(),
        email: z.string(),
        picture: z.string(),
      })
      .passthrough();
    export const PersonalOrgResource = z
      .object({
        resource_name: OrganisationResources,
        user_id: z.string(),
        type: z.literal("personal"),
      })
      .passthrough();
    export const GroupOrgResource = z
      .object({
        resource_name: OrganisationResources,
        group_name: z.string(),
        group_id: z.string(),
        type: z.literal("group"),
      })
      .passthrough();
    export const OrgResource = z.union([GroupOrgResource, PersonalOrgResource]);
    export const OrgFeatureAccess = z.union([
      z.literal("library_all"),
      z.literal("granular_access"),
      z.literal("integration"),
    ]);
    export const LibraryResourceType = z.union([
      z.literal("FOUNDATION"),
      z.literal("TURBINE"),
      z.literal("CABLE"),
      z.literal("ANALYSIS"),
      z.literal("FINANCIAL"),
    ]);
    export const NodeLibraryResource = z
      .object({
        resource_id: z.string(),
        node_information_id: z.string(),
        author: z.string(),
        type: LibraryResourceType,
      })
      .passthrough();
    export const IntercomArticle = z
      .object({
        type: z.string(),
        id: z.string(),
        workspace_id: z.string(),
        title: z.string(),
        description: z.string().optional(),
        body: z.string().optional(),
        author_id: z.number().optional(),
        state: z.string().optional(),
        created_at: z.number().optional(),
        url: z.string().optional(),
      })
      .passthrough();
    export const IntercomSearchResult = z
      .object({
        articles: z.array(IntercomArticle),
      })
      .passthrough();
  }
}
export const components = {
  schemas: {
    Type: Components.Schemas.Type,
    OrganisationResources: Components.Schemas.OrganisationResources,
    SharedNodeMutable: Components.Schemas.SharedNodeMutable,
    SharedNodeInformation_: Components.Schemas.SharedNodeInformation_,
    SharedNodeInformation: Components.Schemas.SharedNodeInformation,
    PersonalFolderNodeInformation:
      Components.Schemas.PersonalFolderNodeInformation,
    PersonalFolderNodeInformation_:
      Components.Schemas.PersonalFolderNodeInformation_,
    FolderNodeInformation: Components.Schemas.FolderNodeInformation,
    FolderNodeInformation_: Components.Schemas.FolderNodeInformation_,
    OrganisationNodeInformation: Components.Schemas.OrganisationNodeInformation,
    OrganisationNodeInformation_:
      Components.Schemas.OrganisationNodeInformation_,
    Project: Components.Schemas.Project,
    ProjectNodeInformation: Components.Schemas.ProjectNodeInformation,
    ProjectNodeInformation_: Components.Schemas.ProjectNodeInformation_,
    OrganisationUserInformation: Components.Schemas.OrganisationUserInformation,
    OrganisationInformation: Components.Schemas.OrganisationInformation,
    NodeInformation: Components.Schemas.NodeInformation,
    NodeInformation_: Components.Schemas.NodeInformation_,
    ProjectNodeMutable: Components.Schemas.ProjectNodeMutable,
    PersonalFolderNodeMutable: Components.Schemas.PersonalFolderNodeMutable,
    FolderNodeMutable: Components.Schemas.FolderNodeMutable,
    NodeMutable: Components.Schemas.NodeMutable,
    CreateNodeFolderBody: Components.Schemas.CreateNodeFolderBody,
    CreateNodeProjectBody: Components.Schemas.CreateNodeProjectBody,
    CreateGroupBody: Components.Schemas.CreateGroupBody,
    CreateGroupMembershipBody: Components.Schemas.CreateGroupMembershipBody,
    CreateGroupNodeAccessBody: Components.Schemas.CreateGroupNodeAccessBody,
    Group: Components.Schemas.Group,
    UserGroup: Components.Schemas.UserGroup,
    GroupMembership: Components.Schemas.GroupMembership,
    GroupNodeAccess: Components.Schemas.GroupNodeAccess,
    GroupNodeAccessWithMeta: Components.Schemas.GroupNodeAccessWithMeta,
    UserNodeAccess: Components.Schemas.UserNodeAccess,
    UserNodeAccessWithMeta: Components.Schemas.UserNodeAccessWithMeta,
    PersonalOrgResource: Components.Schemas.PersonalOrgResource,
    GroupOrgResource: Components.Schemas.GroupOrgResource,
    OrgResource: Components.Schemas.OrgResource,
    OrgFeatureAccess: Components.Schemas.OrgFeatureAccess,
    LibraryResourceType: Components.Schemas.LibraryResourceType,
    NodeLibraryResource: Components.Schemas.NodeLibraryResource,
    IntercomArticle: Components.Schemas.IntercomArticle,
    IntercomSearchResult: Components.Schemas.IntercomSearchResult,
  },
};

export const paths = {
  "/api/customer/node/organisation/{organisationId}": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      responses: {
        200: {
          /** List of nodes */
          "application/json": z.array(Components.Schemas.NodeInformation),
        },
        404: {
          /** No folders found */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/customer/node/organisation/{organisationId}/admin": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      responses: {
        200: {
          /** List of nodes */
          "application/json": z.array(Components.Schemas.NodeInformation),
        },
        404: {
          /** No folders found */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/customer/organisation/user": {
    get: {
      responses: {
        200: {
          /** List of organisation with top folder nodes */
          "application/json": z.array(
            Components.Schemas.OrganisationUserInformation,
          ),
        },
      },
    },
  },
  "/api/customer/node/organisation/teams/{organisationId}": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      responses: {
        200: {
          /** List of nodes */
          "application/json": z.array(Components.Schemas.NodeInformation),
        },
      },
    },
  },
  "/api/customer/organisation/{organisationId}/team/node/{nodeId}": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
        nodeId: z.string(),
      }),
      responses: {
        200: {
          /** Node */
          "application/json": Components.Schemas.NodeInformation,
        },
      },
    },
  },
  "/api/customer/node/organisation/teams/{organisationId}/{topFolderId}": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
        topFolderId: z.string(),
      }),
      responses: {
        200: {
          /** List of nodes */
          "application/json": z.array(Components.Schemas.NodeInformation),
        },
      },
    },
  },
  "/api/customer/node/{nodeId}": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      responses: {
        200: {
          /** Node */
          "application/json": Components.Schemas.NodeInformation,
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
    post: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      /** Node to create */
      requestBody: {
        "application/json": z.union([
          Components.Schemas.CreateNodeFolderBody,
          Components.Schemas.CreateNodeProjectBody,
        ]),
      },
      responses: {
        201: {
          /** Node */
          "application/json": Components.Schemas.NodeInformation,
        },
        400: {
          /** Invalid request body */
          "application/json": z.string(),
        },
      },
    },
    delete: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      responses: {
        204: z.never(),
        500: {
          /** Something went wrong */
          "application/json": z.string(),
        },
      },
    },
    put: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      /** Node to update */
      requestBody: {
        "application/json": Components.Schemas.NodeInformation_,
      },
      responses: {
        201: {
          /** Node */
          "application/json": Components.Schemas.NodeInformation,
        },
        400: {
          /** Invalid request body */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/customer/v2/node/{nodeId}": {
    post: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      /** Node to create */
      requestBody: {
        "application/json": z
          .object({
            name: z.string(),
            parent_id: z.string(),
            legacy_id: z.string().optional(),
            type: Components.Schemas.Type,
          })
          .passthrough(),
      },
      responses: {
        201: {
          /** Project */
          "application/json": Components.Schemas.ProjectNodeInformation,
        },
        400: {
          /** Invalid request body */
          "application/json": z.string(),
        },
        404: {
          /** Invalid request body */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/customer/topnode/{nodeId}": {
    put: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      /** Node to update */
      requestBody: {
        "application/json": z
          .object({
            name: z.string(),
          })
          .passthrough(),
      },
      responses: {
        200: {
          /** Node */
          "application/json": Components.Schemas.NodeInformation,
        },
        400: {
          /** Invalid request body */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/customer/organisation/{organisationId}": {
    put: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      /** Organisation to update */
      requestBody: {
        "application/json": z
          .object({
            name: z.string(),
          })
          .passthrough(),
      },
      responses: {
        200: {
          /** Node */
          "application/json": Components.Schemas.OrganisationInformation,
        },
        400: {
          /** Invalid request body */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/customer/node/{nodeId}/move/{toNodeId}": {
    post: {
      pathParams: z.object({
        nodeId: z.string(),
        toNodeId: z.string(),
      }),
      responses: {
        201: {
          /** Node */
          "application/json": Components.Schemas.NodeInformation,
        },
        400: {
          /** Invalid request body */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/customer/public/project/{customerId}/{projectId}": {
    get: {
      pathParams: z.object({
        customerId: z.string(),
        projectId: z.string(),
      }),
      responses: {
        200: {
          /** Project */
          "application/json": Components.Schemas.ProjectNodeInformation,
        },
        403: {
          /** Access denied */
          "application/json": z.string(),
        },
        404: {
          /** Project not found */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/customer/organisation/{organisationId}/groups": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      responses: {
        200: {
          /** Groups */
          "application/json": z.array(Components.Schemas.Group),
        },
        403: {
          /** Access denied */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
  },
  "/api/customer/organisation/{organisationId}/group": {
    post: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      /** group info */
      requestBody: {
        "application/json": Components.Schemas.CreateGroupBody,
      },
      responses: {
        200: {
          /** Group */
          "application/json": Components.Schemas.Group,
        },
        403: {
          /** Access denied */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
  },
  "/api/customer/organisation/{organisationId}/group/{groupId}": {
    delete: {
      pathParams: z.object({
        organisationId: z.string(),
        groupId: z.string(),
      }),
      responses: {
        204: z.never(),
        403: {
          /** Access denied */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
    put: {
      pathParams: z.object({
        organisationId: z.string(),
        groupId: z.string(),
      }),
      /** group info */
      requestBody: {
        "application/json": Components.Schemas.CreateGroupBody,
      },
      responses: {
        200: {
          /** Group */
          "application/json": Components.Schemas.Group,
        },
        403: {
          /** Access denied */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
  },
  "/api/customer/organisation/{organisationId}/group/{groupId}/members": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
        groupId: z.string(),
      }),
      responses: {
        200: {
          /** Group members */
          "application/json": z.array(Components.Schemas.GroupMembership),
        },
        403: {
          /** Access denied */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
  },
  "/api/customer/organisation/{organisationId}/group/{groupId}/member": {
    post: {
      pathParams: z.object({
        organisationId: z.string(),
        groupId: z.string(),
      }),
      /** group member */
      requestBody: {
        "application/json": Components.Schemas.CreateGroupMembershipBody,
      },
      responses: {
        200: {
          /** New group member */
          "application/json": Components.Schemas.GroupMembership,
        },
        403: {
          /** Access denied */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
  },
  "/api/customer/organisation/{organisationId}/group/{groupId}/member/{memberId}":
    {
      delete: {
        pathParams: z.object({
          organisationId: z.string(),
          groupId: z.string(),
          memberId: z.string(),
        }),
        responses: {
          204: z.never(),
          403: {
            /** Access denied */
            "application/json": z.string(),
          },
          500: {
            /** Something went wrong */
            "application/json": z
              .object({
                error: z.string().optional(),
              })
              .passthrough(),
          },
        },
      },
    },
  "/api/customer/organisation/{organisationId}/group/{groupId}/nodes": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
        groupId: z.string(),
      }),
      responses: {
        200: {
          /** List of group node accesses */
          "application/json": z.array(Components.Schemas.GroupNodeAccess),
        },
        403: {
          /** Access denied */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
  },
  "/api/customer/organisation/{organisationId}/group/{groupId}/node/{nodeId}": {
    post: {
      pathParams: z.object({
        organisationId: z.string(),
        groupId: z.string(),
        nodeId: z.string(),
      }),
      /** resource name */
      requestBody: {
        "application/json": Components.Schemas.CreateGroupNodeAccessBody,
      },
      responses: {
        200: {
          /** Update group node access */
          "application/json": Components.Schemas.GroupNodeAccess,
        },
        403: {
          /** Access denied */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
    delete: {
      pathParams: z.object({
        organisationId: z.string(),
        groupId: z.string(),
        nodeId: z.string(),
      }),
      responses: {
        204: z.never(),
        403: {
          /** Access denied */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
  },
  "/api/customer/organisation/{organisationId}/user/{userId}/groups": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
        userId: z.string(),
      }),
      responses: {
        200: {
          /** Group memberships */
          "application/json": z.array(Components.Schemas.GroupMembership),
        },
        403: {
          /** Access denied */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
  },
  "/api/customer/organisation/{organisationId}/user/{userId}/nodes": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
        userId: z.string(),
      }),
      responses: {
        200: {
          /** List of user node access */
          "application/json": z.array(Components.Schemas.UserNodeAccess),
        },
        403: {
          /** Access denied */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
  },
  "/api/customer/organisation/{organisationId}/user/{userId}/resources": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
        userId: z.string(),
      }),
      responses: {
        200: {
          /** List of user org resource access */
          "application/json": z.array(Components.Schemas.OrgResource),
        },
        403: {
          /** Access denied */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
  },
  "/api/customer/organisation/{organisationId}/user/resources": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      responses: {
        200: {
          /** List of user org resource access */
          "application/json": z.array(Components.Schemas.OrgResource),
        },
        403: {
          /** Access denied */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
  },
  "/api/customer/organisation/{organisationId}/group/{groupId}/resources": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
        groupId: z.string(),
      }),
      responses: {
        200: {
          /** List of group org resource access */
          "application/json": z.array(Components.Schemas.OrgResource),
        },
        403: {
          /** Access denied */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
  },
  "/api/customer/organisation/{organisationId}/node/{nodeId}/access": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
        nodeId: z.string(),
      }),
      responses: {
        200: {
          /** Groups and users with access to node */
          "application/json": z.array(
            z.union([
              Components.Schemas.GroupNodeAccessWithMeta,
              Components.Schemas.UserNodeAccessWithMeta,
            ]),
          ),
        },
        403: {
          /** Access denied */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
  },
  "/api/customer/organisation/{organisationId}/libraryaccess/foundation/{resourceId}":
    {
      get: {
        pathParams: z.object({
          organisationId: z.string(),
          resourceId: z.string(),
        }),
        responses: {
          200: {
            /** node-resource access objects */
            "application/json": z.array(Components.Schemas.NodeLibraryResource),
          },
          403: {
            /** Access denied */
            "application/json": z.string(),
          },
          500: {
            /** Something went wrong */
            "application/json": z
              .object({
                error: z.string().optional(),
              })
              .passthrough(),
          },
        },
      },
    },
  "/api/customer/organisation/{organisationId}/libraryaccess/turbine/{resourceId}":
    {
      get: {
        pathParams: z.object({
          organisationId: z.string(),
          resourceId: z.string(),
        }),
        responses: {
          200: {
            /** node-resource access objects */
            "application/json": z.array(Components.Schemas.NodeLibraryResource),
          },
          403: {
            /** Access denied */
            "application/json": z.string(),
          },
          500: {
            /** Something went wrong */
            "application/json": z
              .object({
                error: z.string().optional(),
              })
              .passthrough(),
          },
        },
      },
    },
  "/api/customer/organisation/{organisationId}/libraryaccess/analysis/{resourceId}":
    {
      get: {
        pathParams: z.object({
          organisationId: z.string(),
          resourceId: z.string(),
        }),
        responses: {
          200: {
            /** node-resource access objects */
            "application/json": z.array(Components.Schemas.NodeLibraryResource),
          },
          403: {
            /** Access denied */
            "application/json": z.string(),
          },
          500: {
            /** Something went wrong */
            "application/json": z
              .object({
                error: z.string().optional(),
              })
              .passthrough(),
          },
        },
      },
    },
  "/api/customer/organisation/{organisationId}/libraryaccess/cable/{resourceId}":
    {
      get: {
        pathParams: z.object({
          organisationId: z.string(),
          resourceId: z.string(),
        }),
        responses: {
          200: {
            /** node-resource access objects */
            "application/json": z.array(Components.Schemas.NodeLibraryResource),
          },
          403: {
            /** Access denied */
            "application/json": z.string(),
          },
          500: {
            /** Something went wrong */
            "application/json": z
              .object({
                error: z.string().optional(),
              })
              .passthrough(),
          },
        },
      },
    },
  "/api/customer/featureaccess/organisation/{organisationId}": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      responses: {
        200: {
          /** org feature access */
          "application/json": z.array(Components.Schemas.OrgFeatureAccess),
        },
        403: {
          /** Access denied */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
  },
  "/api/customer/intercom/search": {
    get: {
      searchParams: z.object({
        phrase: z.string(),
      }),
      responses: {
        200: {
          /** Node */
          "application/json": Components.Schemas.IntercomSearchResult,
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
  },
};

// Generated using openapi-to-zod v0.1.47
