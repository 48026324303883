import {
  MemberListEntry,
  OrganisationInvite,
  _OrganisationInvite,
} from "./../types/customer";
import { fetchSchemaWithToken } from "../services/utils";
import { atom, atomFamily, selectorFamily, useSetRecoilState } from "recoil";
import {
  CustomerPersona,
  collaboratorRole,
  personaIsMember,
  _CustomerPersona,
} from "../types/customer";
import { nodeGroupUserAccessSelector } from "components/Organisation/Groups/state";
import {
  isGroupNodeAccessWithMeta,
  isUserNodeAccessWithMeta,
} from "components/Organisation/Groups/types";
import { UserAccessRole } from "types/user";
import { adminInOrganisationSelectorFamily } from "state/user";
import { useCallback } from "react";

async function fetchAllOrganisationInvites(organisationId: string) {
  const response = await fetchSchemaWithToken(
    _OrganisationInvite.array(),
    `/api/user/${organisationId}/invite`,
    {
      method: "get",
      headers: {},
    },
  );
  return response;
}

export const allOrganisationInvitationsAtomFamily = atomFamily<
  OrganisationInvite[],
  string
>({
  key: "allOrganisationInvitationsAtomFamily",
  default: selectorFamily<OrganisationInvite[], string>({
    key: "allOrganisationInvitationsSelectorFamily.default",
    get:
      (organisationId) =>
      async ({ get }) => {
        get(refreshInvitationsAtom);
        const isAdminInOrg = get(
          adminInOrganisationSelectorFamily({ organisationId }),
        );
        if (!isAdminInOrg) {
          return [];
        }
        return fetchAllOrganisationInvites(organisationId);
      },
  }),
});

export const refreshInvitationsAtom = atom<number>({
  key: "refreshInvitationsAtom",
  default: 0,
});

export const useRefreshInvitations = () => {
  const set = useSetRecoilState(refreshInvitationsAtom);
  return useCallback(() => set((v) => v + 1), [set]);
};

export const allCollaboratorsInNodeSelectorFamily = atomFamily<
  CustomerPersona[],
  string | undefined
>({
  key: "allCollaboratorsInNodeSelectorFamily",
  default: selectorFamily<CustomerPersona[], string | undefined>({
    key: "allCollaboratorsInNodeSelectorFamily.default",
    get: (nodeId) => async () => {
      if (!nodeId || nodeId.length === 0) return [];
      const response = await fetchSchemaWithToken(
        _CustomerPersona.array(),
        `/api/user/node/${nodeId}`,
        {
          method: "get",
          headers: {},
        },
      );
      return response;
    },
  }),
});

export const allNodeMembersSortedSelectorFamily = selectorFamily<
  MemberListEntry[],
  { organisationId: string; nodeId: string }
>({
  key: "allNodeMembersSortedSelectorFamily",
  get:
    ({ organisationId, nodeId }) =>
    ({ get }) => {
      const nodeAccess = get(
        nodeGroupUserAccessSelector({ organisationId, nodeId }),
      );

      const usersWithAccess = nodeAccess
        .filter(isUserNodeAccessWithMeta)
        .map((user) => ({
          name: user.nickname,
          role: user.resource_name as UserAccessRole,
          id: user.user_id,
          type: "user",
          email: user.email,
          picture: user.picture,
          nodeId: user.node_id,
        }));

      const groupsWithAccess = nodeAccess
        .filter(isGroupNodeAccessWithMeta)
        .map((group) => ({
          name: group.group_name,
          role: group.resource_name as UserAccessRole,
          id: group.group_id,
          type: "group",
          nodeId: group.node_id,
        }));

      const m = [...usersWithAccess, ...groupsWithAccess] as MemberListEntry[];
      m.sort((a, b) => {
        // Groups after users
        if (a.type === "group" && b.type === "user") return 1;
        if (a.type === "user" && b.type === "group") return -1;
        // access on this nodeId before access on parentNodeId
        if (a.nodeId === nodeId && b.nodeId !== nodeId) return -1;
        if (a.nodeId !== nodeId && b.nodeId === nodeId) return 1;
        // NOTE: µ is compared to be after a-z so that collaborators are placed at the end.
        const r = (a.role ?? "µ").localeCompare(b.role ?? "µ");
        return r === 0 ? a.name.localeCompare(b.name) : r;
      });
      return m;
    },
});

const allCollaboratorsInNodeSortedSelectorFamily = selectorFamily<
  CustomerPersona[],
  string | undefined
>({
  key: "allCollaboratorsInNodeSortedSelectorFamily",
  get:
    (nodeId) =>
    ({ get }) => {
      if (!nodeId) return [];
      const m = [...get(allCollaboratorsInNodeSelectorFamily(nodeId))];
      m.sort((a, b) => {
        // NOTE: µ is compared to be after a-z so that collaborators are placed at the end.
        const r = (a.role ?? "µ").localeCompare(b.role ?? "µ");
        return r === 0 ? a.user_id.localeCompare(b.user_id) : r;
      });
      return m;
    },
});

export const projectPersonasSelectorFamily = selectorFamily<
  CustomerPersona[],
  { projectNodeId: string }
>({
  key: "projectPersonasSelectorFamily",
  get:
    ({ projectNodeId }) =>
    async ({ get }) => {
      const members = get(
        allCollaboratorsInNodeSortedSelectorFamily(projectNodeId),
      );
      return members.filter((u) => {
        if (personaIsMember(u)) return true;
        return !!collaboratorRole(u, projectNodeId);
      });
    },
});
