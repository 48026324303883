import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useSetRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
} from "recoil";
import { selectedMenuItemState } from "../Shared/state";
import {
  defaultFoundations,
  showNewFoundationWizardAtom,
  newFoundationNodeId,
} from "state/foundations";
import { MenuFrame } from "components/MenuPopup/CloseableMenuPopup";
import styled from "styled-components";
import { StandardBox } from "styles/boxes/Boxes";
import Dropdown from "components/Dropdown/Dropdown";
import { Grid2, Label } from "components/General/Form";
import { useClickOutside } from "hooks/useClickOutside";
import {
  defaultDetailedMonopileParameters,
  DetailedMonopileType,
  FoundationMaxDepths,
  FoundationMinDepths,
  FoundationType,
  FoundationTypeIds,
  FoundationTypeNames,
  SoilStiffnessLevels,
} from "types/foundations";
import Button from "components/General/Button";
import { spaceLarge, spaceMedium } from "styles/space";
import { FOUNDATION_MENU_ID } from "./Data/useFoundationSettings";
import { useTypedPath } from "state/pathParams";
import { createNewProjectFoundation } from "services/turbineAPIService";
import { scream } from "utils/sentry";
import { SkeletonBlock, SkeletonText } from "components/Loading/Skeleton";
import { Input, InputDimensioned } from "components/General/Input";
import { Column, Row } from "components/General/Layout";
import Radio, { RadioGroup } from "components/General/Radio";
import Toggle, { ToggleSize } from "components/General/Toggle";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import { MAX_PILE_DIAMETER } from "@constants/foundations";
import { monopileSizing } from "functions/monopileSizing";
import { getMostCommonTurbineTypeIdInZone } from "state/layout";
import { allSimpleTurbineTypesSelector } from "state/turbines";
import { ParkFeature } from "types/feature";
import { DEFAULT_TURBINES, SimpleTurbineType } from "types/turbines";
import { getParkFeaturesInBranchSelector } from "state/park";
import { DropDownItem } from "components/General/Dropdown/DropdownItems";
import { Raster } from "types/raster";
import { fastMin } from "utils/utils";
import {
  useRefreshLibraryFoundation,
  useRefreshProjectFoundation,
} from "components/ConfigurationModal/useRefreshCustomFoundations";
import useOrgFoundationCrud from "components/Organisation/Library/foundation/useOrgFoundationCrud";
import {
  getAvailableWaveSourcesSelectorFamily,
  getWaveStatsSelectorFamily,
} from "state/waveStatistics";
import { getParkCenter } from "utils/parkUtils";
import { useBathymetryRaster } from "hooks/bathymetry";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import {
  InputTitle,
  InputTitleWrapper,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";

const FoundationSubMenuWrapperOrg = styled(StandardBox)`
  margin: auto;
  display: flex;
  overflow: hidden;
  position: absolute;
  top: 3rem;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 1;
`;

const FoundationSubMenuWrapperProject = styled(StandardBox)`
  margin: auto;
  display: flex;
  overflow: hidden;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const CreateFoundationMenuOrganisation = ({
  onCreate,
  setSaveInProgess,
}: {
  onCreate: (foundation: FoundationType) => void;
  setSaveInProgess?: (saveInProgress: boolean) => void;
}) => {
  const { create } = useOrgFoundationCrud();

  const refresh = useRefreshLibraryFoundation();

  const _onSave = useCallback(
    async (newFoundationType: FoundationTypeIds) => {
      const baseFoundation = defaultFoundations.find(
        (f) => f.type === newFoundationType,
      );
      if (!baseFoundation) return;
      const newFoundation = {
        ...baseFoundation,
        name: `New ${FoundationTypeNames[baseFoundation.type]}`,
      };
      if (setSaveInProgess) setSaveInProgess(true);
      return create({ foundation: newFoundation })
        .then((res) => {
          if (res) {
            refresh();
            if (setSaveInProgess) setSaveInProgess(false);
            onCreate(res);
          }
        })
        .catch((e) => {
          scream("Failed to create new foundation", e);
          throw Error("Could not create a foundation with the selected input.");
        });
    },
    [create, onCreate, refresh, setSaveInProgess],
  );

  return (
    <FoundationSubMenuWrapperOrg>
      <CreateFoundationMenu onSave={_onSave} inProject={false} />
    </FoundationSubMenuWrapperOrg>
  );
};

export const CreateFoundationMenuProject = () => {
  const { projectId } = useTypedPath("projectId");

  const foundationTypeNodeId = useRecoilValue(newFoundationNodeId);

  const setMenuSelection = useSetRecoilState(
    selectedMenuItemState({ menuId: FOUNDATION_MENU_ID, projectId }),
  );

  const refreshFoundations = useRefreshProjectFoundation();

  const _onSave = useCallback(
    async (newFoundationType: FoundationTypeIds) => {
      const baseFoundation = defaultFoundations.find(
        (f) => f.type === newFoundationType,
      );
      if (!foundationTypeNodeId || !baseFoundation) return;
      const newFoundation = {
        ...baseFoundation,
        name: `New ${FoundationTypeNames[baseFoundation.type]}`,
      };
      createNewProjectFoundation(foundationTypeNodeId, newFoundation)
        .then((res) => {
          refreshFoundations();
          if (res?.id) setMenuSelection(res.id);
        })
        .catch((e) => {
          scream("Failed to create new foundation", e);
          throw Error("Could not create a foundation with the selected input.");
        });
    },
    [foundationTypeNodeId, refreshFoundations, setMenuSelection],
  );

  return (
    <FoundationSubMenuWrapperProject>
      <CreateFoundationMenu onSave={_onSave} inProject={true} />
    </FoundationSubMenuWrapperProject>
  );
};

const CreateFoundationMenu = ({
  onSave,
  inProject,
}: {
  onSave: (newFoundationType: FoundationTypeIds) => Promise<any>;
  inProject: boolean;
}) => {
  const setShowNewFoundationWizard = useSetRecoilState(
    showNewFoundationWizardAtom,
  );

  const [foundationType, setFoundationType] = useState<FoundationTypeIds>(
    FoundationTypeIds.SemiCentralType,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);

  const _onSave = useCallback(
    async (newFoundationType: FoundationTypeIds) => {
      setIsLoading(true);
      await onSave(newFoundationType);
      setIsLoading(false);
      setShowNewFoundationWizard(false);
    },
    [onSave, setShowNewFoundationWizard],
  );

  useClickOutside(ref, () => setShowNewFoundationWizard(false));

  return (
    <MenuFrame
      title="Create new foundation"
      onExit={() => setShowNewFoundationWizard(false)}
      id="create-foundation-submenu"
      ref={ref}
      style={{ width: "38rem" }}
    >
      <Label>
        <InputTitle>Foundation type</InputTitle>
        <Dropdown
          small
          id="foundation-type"
          value={foundationType}
          onChange={(e) => {
            setFoundationType(e.target.value as FoundationTypeIds);
          }}
        >
          <option value={FoundationTypeIds.SemiCentralType}>
            {"Semi, central turbine"}
          </option>
          <option value={FoundationTypeIds.SemiPeripheralType}>
            {"Semi, offset turbine"}
          </option>
          <option value={FoundationTypeIds.SparType}>{"Spar"}</option>
          <option value={FoundationTypeIds.JacketType}>{"Jacket"}</option>
          <option value={FoundationTypeIds.MonopileType}>
            {"Simple monopile"}
          </option>
          {inProject && (
            <option value={FoundationTypeIds.DetailedMonopileType}>
              {"Site-specific monopile wizard..."}
            </option>
          )}
        </Dropdown>
      </Label>
      {foundationType === FoundationTypeIds.DetailedMonopileType && (
        <MonopileWizard />
      )}
      {foundationType !== FoundationTypeIds.DetailedMonopileType && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignItems: "baseline",
            gap: spaceMedium,
            paddingTop: spaceLarge,
          }}
        >
          <Button
            disabled={isLoading}
            style={{ alignSelf: "end" }}
            text="Create"
            onClick={() => {
              _onSave(foundationType);
            }}
          />
        </div>
      )}
    </MenuFrame>
  );
};

const MonopileWizard = () => {
  const { branchId, projectId } = useTypedPath("branchId", "projectId");

  const parks = useRecoilValue(getParkFeaturesInBranchSelector({ branchId }));

  const [park, setPark] = useState<undefined | ParkFeature>();

  const items: DropDownItem[] = useMemo(() => {
    return parks.map((park) => {
      return {
        value: park.id,
        name: park.properties.name ?? "unnamed park",
      };
    });
  }, [parks]);

  const raster = useBathymetryRaster({
    projectId,
    featureId: park?.id ?? "",
  }).valueMaybe();

  return (
    <>
      <Label style={{ paddingTop: "1.6rem" }}>
        <InputTitle>Park</InputTitle>
        <Dropdown
          small
          value={park?.id}
          onChange={(e) => {
            const park = parks.find((p) => p.id === e.target.value);
            if (!park) throw scream("Selected impossible park", { parks, e });
            setPark?.(park);
          }}
          defaultValue=""
        >
          <option value="" disabled>
            Select park
          </option>
          {items.map((item) => (
            <option key={item.value} value={item.value}>
              {item.name}
            </option>
          ))}
        </Dropdown>
      </Label>
      {park && !raster && (
        <Column style={{ marginTop: "0.6rem", marginBottom: "0.6rem" }}>
          <SkeletonText text="Loading bathymetry" />
        </Column>
      )}
      {park && raster && <MonopileWizardInner park={park} raster={raster} />}
    </>
  );
};

const MonopileWizardInner = ({
  park,
  raster,
}: {
  park: ParkFeature;
  raster: Raster;
}) => {
  const currentTurbineTypeId = useRecoilValue(
    getMostCommonTurbineTypeIdInZone({ parkId: park.id }),
  );

  const allTurbineTypes = useRecoilValue(allSimpleTurbineTypesSelector).filter(
    (t) => !t.archived,
  );
  const turbineType =
    allTurbineTypes.find((t) => t.id === currentTurbineTypeId) ??
    allTurbineTypes[0];

  const parkCenter = getParkCenter(park, []);
  const waveSources = useRecoilValueLoadable(
    getAvailableWaveSourcesSelectorFamily({ parkCenter }),
  ).valueMaybe();
  const waveStats = useRecoilValueLoadable(
    getWaveStatsSelectorFamily({ parkCenter }),
  ).valueMaybe();

  const parkMaxDepth = -fastMin(raster.values);

  const waterDepth = Math.min(
    parkMaxDepth,
    FoundationMaxDepths.detailed_monopile,
  );

  const defaultMonopile = defaultDetailedMonopileParameters(
    park,
    turbineType,
    waterDepth,
    waveStats,
  );

  if (waveSources && waveSources.length === 0) {
    return (
      <SimpleAlert
        text={"Wave data not available at the selected location."}
        type={"error"}
      ></SimpleAlert>
    );
  }

  if (!waveStats) {
    return (
      <Column style={{ marginTop: "0.6rem", marginBottom: "0.6rem" }}>
        <SkeletonText text="Loading wave data" />
      </Column>
    );
  }

  return (
    <MonopileWizardInnerCreate
      defaultMonopile={defaultMonopile}
      turbineType={turbineType}
      parkMaxDepth={parkMaxDepth}
    />
  );
};

const MonopileWizardInnerCreate = ({
  defaultMonopile,
  turbineType,
  parkMaxDepth,
}: {
  defaultMonopile: DetailedMonopileType;
  turbineType: SimpleTurbineType;
  parkMaxDepth: number;
}) => {
  const { projectId } = useTypedPath("projectId");

  const setShowNewFoundationWizard = useSetRecoilState(
    showNewFoundationWizardAtom,
  );
  const foundationTypeNodeId = useRecoilValue(newFoundationNodeId);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const setMenuSelection = useSetRecoilState(
    selectedMenuItemState({ menuId: FOUNDATION_MENU_ID, projectId }),
  );

  const [metoceanOverride, setMetoceanOverride] = useState<boolean>(false);
  const [turbineTypeId, setTurbineTypeId] = useState<string>(turbineType.id);
  const [soilStiffnessOverride, setSoilStiffnessOverride] =
    useState<boolean>(false);
  const [hasRun, setHasRun] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const [tempFoundation, setTempFoundation] =
    useState<DetailedMonopileType>(defaultMonopile);

  useEffect(() => {
    setTempFoundation(defaultMonopile);
    setHasRun(false);
  }, [defaultMonopile]);

  const allTurbineTypes = useRecoilValue(allSimpleTurbineTypesSelector).filter(
    (t) => !t.archived,
  );

  const generateMonopile = useCallback(
    (tempFoundation: DetailedMonopileType, turbineTypeId: string) => {
      const turbineType = allTurbineTypes.find((t) => t.id === turbineTypeId)!;
      const { soilCoeffSubReact, waterDepth, hs50Yr } = tempFoundation;
      setIsLoading(true);
      const pileData = monopileSizing({
        soilCoeffSubReact,
        turbineType,
        waterDepth,
        hs50Yr,
      });

      if (pileData) {
        const { pileDiameter, avgPileThickness, embedLength, totalPileLength } =
          pileData;
        setTempFoundation({
          ...tempFoundation,
          pileDiameter,
          avgPileThickness,
          embedLength,
          totalPileLength,
        });
      } else setHasError(true);
      setIsLoading(false);
      setHasRun(true);
    },
    [allTurbineTypes],
  );

  const refreshFoundations = useRefreshProjectFoundation();

  const onSave = useCallback(
    async (newFoundation: FoundationType) => {
      if (!foundationTypeNodeId) return;
      setIsLoading(true);
      createNewProjectFoundation(foundationTypeNodeId, newFoundation)
        .then((res) => {
          refreshFoundations();
          setIsLoading(false);
          if (res?.id) setMenuSelection(res.id);
          setShowNewFoundationWizard(false);
        })
        .catch((e) => {
          scream("Failed to create new foundation", e);
          throw Error("Could not create a foundation with the selected input.");
        });
    },
    [
      foundationTypeNodeId,
      refreshFoundations,
      setMenuSelection,
      setShowNewFoundationWizard,
    ],
  );

  return (
    <>
      {!hasRun && (
        <>
          <Label style={{ paddingTop: "1.6rem" }}>
            <InputTitle>Turbine type</InputTitle>
            <Dropdown
              small
              id="turbine"
              value={turbineTypeId}
              onChange={(e) => {
                const newId = e.target.value;
                setTurbineTypeId(newId);
                const newType = allTurbineTypes.find((t) => t.id === newId);
                if (newType)
                  setTempFoundation({
                    ...tempFoundation,
                    ratedPower: newType.ratedPower,
                    rotorDiameter: newType.diameter,
                    hubHeight: newType.hubHeight,
                    rnaMass: newType.rnaMass,
                  });
              }}
            >
              {allTurbineTypes.map((t) => {
                const isDefaultTurbine = DEFAULT_TURBINES.some(
                  (defaultTurbine) => defaultTurbine.id === t.id,
                );

                return (
                  <option key={t.id} value={t.id} disabled={t.archived}>
                    {t.name} {isDefaultTurbine ? "(standard)" : ""}
                  </option>
                );
              })}
            </Dropdown>
          </Label>
          <Label style={{ paddingTop: "1.6rem" }}>
            <InputTitle>Name</InputTitle>
            <Input
              compact
              type="text"
              value={tempFoundation.name}
              onChange={(e) => {
                setTempFoundation({
                  ...tempFoundation,
                  name: e.target.value,
                });
              }}
            />
          </Label>

          <SubtitleWithLine
            text={"Metocean parameters"}
            style={{ paddingTop: "0.8rem", paddingBottom: "0.8rem" }}
          />
          <div style={{ paddingBottom: "1.6rem" }}>
            <InputTitleWrapper>
              <InputTitle>Override park values</InputTitle>
              <Toggle
                size={ToggleSize.SMALL}
                checked={metoceanOverride}
                onChange={() => {
                  setMetoceanOverride(!metoceanOverride);
                }}
              />
            </InputTitleWrapper>
          </div>

          <Label>
            <InputTitle>Water depth</InputTitle>
            <InputDimensioned
              compact
              disabled={!metoceanOverride}
              validate={between(
                FoundationMinDepths.detailed_monopile,
                FoundationMaxDepths.detailed_monopile,
              )}
              validationMessage={`Needs to be within ${FoundationMinDepths.detailed_monopile} - ${FoundationMaxDepths.detailed_monopile} m`}
              step="1"
              unit="m"
              type="number"
              value={
                Math.round(
                  10 *
                    Math.min(
                      tempFoundation.waterDepth,
                      FoundationMaxDepths.detailed_monopile,
                    ),
                ) / 10
              }
              onChange={(val) => {
                setTempFoundation({
                  ...tempFoundation,
                  waterDepth: val,
                });
              }}
              style={{ width: "100%" }}
            />
            {parkMaxDepth > FoundationMaxDepths.detailed_monopile &&
              !metoceanOverride && (
                <SimpleAlert
                  text={`Park depth is too large for monopile (> ${FoundationMaxDepths.detailed_monopile} m).`}
                  type={"error"}
                ></SimpleAlert>
              )}

            <InputTitle>50-year Hs</InputTitle>
            <InputDimensioned
              compact
              disabled={!metoceanOverride}
              validate={between(1, 20)}
              validationMessage={`Needs to be within 1 - 20 m`}
              step="0.1"
              unit="m"
              type="number"
              value={Math.round(10 * tempFoundation.hs50Yr) / 10}
              onChange={(val) => {
                setTempFoundation({ ...tempFoundation, hs50Yr: val });
              }}
              style={{ width: "100%" }}
            />
          </Label>
          <Row>
            <SubtitleWithLine
              text={"Soil stiffness"}
              iconButton={
                <HelpTooltip
                  size={10}
                  text="Coefficient of subgrade reaction for the soil."
                />
              }
            />
          </Row>
          <Label>
            <RadioGroup
              style={{ padding: `${spaceMedium} 0 ${spaceMedium} 0` }}
            >
              <Radio
                label="Use general levels"
                checked={!soilStiffnessOverride}
                onChange={() => {
                  setSoilStiffnessOverride(false);
                  setTempFoundation({
                    ...tempFoundation,
                    soilCoeffSubReact: SoilStiffnessLevels.medium,
                  });
                }}
              />
              <Radio
                label="Override"
                style={{ marginLeft: 10 }}
                checked={soilStiffnessOverride}
                onChange={() => {
                  setSoilStiffnessOverride(true);
                }}
              />
            </RadioGroup>

            <InputTitle>Soil stiffness</InputTitle>
            <Dropdown
              small
              disabled={soilStiffnessOverride}
              id="foundation-soil-stiffness"
              value={
                soilStiffnessOverride
                  ? SoilStiffnessLevels.medium
                  : tempFoundation.soilCoeffSubReact
              }
              onChange={(e) => {
                setTempFoundation({
                  ...tempFoundation,
                  soilCoeffSubReact: parseFloat(e.target.value),
                });
              }}
              style={{ width: "100%" }}
            >
              <option value={SoilStiffnessLevels.soft}>{"Soft"}</option>
              <option value={SoilStiffnessLevels.medium}>{"Medium"}</option>
              <option value={SoilStiffnessLevels.stiff}>{"Stiff"}</option>
            </Dropdown>
            <InputTitle>Sub. reaction coeff.</InputTitle>
            <InputDimensioned
              compact
              disabled={!soilStiffnessOverride}
              validate={between(1000, 20_000)}
              validationMessage={`Needs to be within 1000 - 20 000 kN/m³`}
              step="100"
              unit="kN/m³"
              type="number"
              value={
                ((1 / 1000) *
                  Math.round(0.01 * tempFoundation.soilCoeffSubReact)) /
                0.01
              }
              onChange={(soilStiffnesskN) => {
                setTempFoundation({
                  ...tempFoundation,
                  soilCoeffSubReact: soilStiffnesskN * 1000,
                });
              }}
              style={{ width: "100%" }}
            />
          </Label>
        </>
      )}
      {!hasRun && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignItems: "baseline",
            gap: spaceMedium,
            paddingTop: spaceLarge,
          }}
        >
          <Button
            disabled={isLoading}
            style={{ alignSelf: "end" }}
            title="Calculate monopile design."
            text="Calculate"
            onClick={() => {
              generateMonopile(tempFoundation, turbineTypeId);
            }}
          />
        </div>
      )}
      {hasRun && !hasError && (
        <Label>
          <h4 style={{ margin: `${spaceMedium} 0` }}>
            Calculated monopile design
          </h4>
          <Grid2 style={{ padding: `${spaceMedium} 0 ${spaceMedium} 0` }}>
            <p>Pile diameter</p>
            <InputDimensioned
              validate={between(1, MAX_PILE_DIAMETER)}
              validationMessage={`Needs to be within 1 - ${MAX_PILE_DIAMETER} m`}
              step="1"
              unit="m"
              type="number"
              value={Math.round(10 * tempFoundation.pileDiameter) / 10}
              onChange={(val) => {
                setTempFoundation({ ...tempFoundation, pileDiameter: val });
              }}
              style={{ width: "100%" }}
            />
            <p>Avg. pile thickness</p>
            <InputDimensioned
              validate={between(0.01, 0.5)}
              validationMessage={`Needs to be within 0.01 - 0.5 m`}
              step="0.01"
              unit="m"
              type="number"
              value={Math.round(1000 * tempFoundation.avgPileThickness) / 1000}
              onChange={(val) => {
                setTempFoundation({
                  ...tempFoundation,
                  avgPileThickness: val,
                });
              }}
              style={{ width: "100%" }}
            />
            <p>Embedded length</p>
            <InputDimensioned
              validate={between(10, 100)}
              validationMessage={`Needs to be within 10 - 100 m`}
              step="1"
              unit="m"
              type="number"
              value={Math.round(10 * tempFoundation.embedLength) / 10}
              onChange={(val) => {
                setTempFoundation({ ...tempFoundation, embedLength: val });
              }}
              style={{ width: "100%" }}
            />
            <p>Total pile length</p>
            <InputDimensioned
              validate={between(20, 150)}
              validationMessage={`Needs to be within 20 - 150 m`}
              step="1"
              unit="m"
              type="number"
              value={Math.round(10 * tempFoundation.totalPileLength) / 10}
              onChange={(val) => {
                setTempFoundation({
                  ...tempFoundation,
                  totalPileLength: val,
                });
              }}
              style={{ width: "100%" }}
            />
          </Grid2>
        </Label>
      )}
      {hasRun && hasError && (
        <SimpleAlert
          text={`Could not find a feasible monopile design with diameter < ${MAX_PILE_DIAMETER} m for the selected input.`}
          type={"error"}
        ></SimpleAlert>
      )}
      {hasRun && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignItems: "baseline",
            gap: spaceMedium,
            paddingTop: spaceLarge,
          }}
        >
          <Button
            disabled={isLoading}
            style={{ alignSelf: "end" }}
            buttonType={"secondary"}
            text="Back"
            onClick={() => {
              setHasRun(false);
              setHasError(false);
            }}
          />
          <Button
            disabled={isLoading || hasError}
            style={{ alignSelf: "end" }}
            title="Create foundation type."
            text="Create"
            onClick={() => {
              onSave(tempFoundation);
            }}
          />
        </div>
      )}
      {isLoading && <SkeletonBlock style={{ flex: 1 }} />}
    </>
  );
};

const between = (min: number, max: number) => (n: number) =>
  min <= n && n <= max;
