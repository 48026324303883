import React, { useCallback, useEffect, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import type { MapboxGeoJSONFeature, MapMouseEvent } from "mapbox-gl";
import { currentExternalLayerSelection } from "../../state/externalLayerSelection";
import {
  currentSelectedProjectFeatures,
  currentSelectionArrayAtom,
} from "../../state/selection";
import { editorAccessProjectSelector } from "../../state/user";
import { inReadOnlyModeSelector } from "../../state/project";
import { useCheckWMSInfo } from "../../hooks/useCheckWMSInfo";
import { dedup } from "../../utils/utils";
import { mapRefAtom } from "../../state/map";
import { projectIdSelector } from "../../state/pathParams";
import { projectFeaturesSelector } from "../ProjectElements/state";
import { visibleWmsLayersSelector } from "../LayerList/LayerSettings/utils";
import NoSelectionMenu from "./SelectionMenu/NoSelectionMenu";
import OneOrMoreCanvasSelectionMenu from "./SelectionMenu/OneOrMoreCanvasSelectionMenu";
import OneOrMoreCanvasAndExternalSelectionMenu from "./SelectionMenu/OneOrMoreCanvasAndExternalSelectionMenu";
import SingleFeatureMenu from "./SelectionMenu/SingleFeatureMenu";
import { resetListIfNotAlreadyEmpty } from "../../utils/resetList";
import useSelectionInMap from "hooks/useSelectionInMap";
import { turbineDiameterSourceId } from "components/Mapbox/constants";

const SelectionMenu = ({
  closeMenu,
  mapMouseEvent,
}: {
  closeMenu: () => void;
  mapMouseEvent: MapMouseEvent | undefined;
}) => {
  const projectId = useRecoilValue(projectIdSelector) ?? "";
  const map = useRecoilValue(mapRefAtom);
  const setCurrentSelectionArray = useSetRecoilState(currentSelectionArrayAtom);
  const projectFeatures = useRecoilValue(projectFeaturesSelector);
  const { onProjectFeatureClick: onFeatureClick, onExternalLayerClick } =
    useSelectionInMap();
  const selectedProjectFeatures = useRecoilValue(
    currentSelectedProjectFeatures,
  );
  const visibleWmsLayers = useRecoilValue(
    visibleWmsLayersSelector({ projectId }),
  );
  const checkWMSInfo = useCheckWMSInfo(visibleWmsLayers);
  const dynamicLayerSelection = useRecoilValue(currentExternalLayerSelection);
  const isCustomerEditor = useRecoilValue(editorAccessProjectSelector);
  const isReadOnly = useRecoilValue(inReadOnlyModeSelector);
  const canEdit = isCustomerEditor && !isReadOnly;

  const sampleWmsCallback = useCallback(() => {
    if (!mapMouseEvent) {
      return;
    }

    checkWMSInfo(mapMouseEvent);
  }, [checkWMSInfo, mapMouseEvent]);

  const featuresOnPoint = useMemo<MapboxGeoJSONFeature[]>(() => {
    if (!mapMouseEvent || !map) {
      return [];
    }

    const features = map
      .queryRenderedFeatures(mapMouseEvent.point)
      .filter((feature) => !feature.source.startsWith(turbineDiameterSourceId))
      .filter((f) => f.properties?.id);

    return dedup(features, (feature) => feature.properties?.id);
  }, [mapMouseEvent, map]);

  const rightClickedFeature = useMemo(() => {
    const mapFeature = featuresOnPoint.find(
      (feature) => feature.id && map?.getFeatureState(feature).hover,
    );
    const externalFeature = dynamicLayerSelection.find(
      (feature) => feature.id && map?.getFeatureState(feature).hover,
    );
    const projectFeature = projectFeatures.find(
      (feature) => feature.id && feature.id === mapFeature?.id,
    );
    return projectFeature ?? externalFeature ?? mapFeature;
  }, [featuresOnPoint, projectFeatures, dynamicLayerSelection, map]);

  const clickedFeatureIsProjectFeature = useMemo(() => {
    return (
      rightClickedFeature &&
      projectFeatures.some(
        (feature) =>
          feature.properties.id === rightClickedFeature.properties!.id,
      )
    );
  }, [rightClickedFeature, projectFeatures]);

  const clickedFeatureBelongsToCurrentSelection = useMemo(() => {
    return (
      rightClickedFeature &&
      selectedProjectFeatures.some(
        (projectFeature) =>
          projectFeature.properties.id === rightClickedFeature.properties!.id,
      )
    );
  }, [rightClickedFeature, selectedProjectFeatures]);

  const hasVisibleWmsLayers = useMemo(
    () => visibleWmsLayers.length > 0,
    [visibleWmsLayers],
  );

  const onSelectElement = useCallback(
    (feature: MapboxGeoJSONFeature) => {
      const isExternalLayer =
        projectFeatures.find((f) => f.id === feature.id) === undefined;
      if (isExternalLayer) {
        onExternalLayerClick([feature], false, false);
      } else {
        onFeatureClick([feature], false, false);
      }
    },
    [onExternalLayerClick, onFeatureClick, projectFeatures],
  );

  const onMouseEnterFeature = useCallback(
    (feature: MapboxGeoJSONFeature) => {
      if (feature.id) {
        map!.setFeatureState(feature, { hover: true });
      }
    },
    [map],
  );
  const onMouseLeaveFeature = useCallback(
    (feature: MapboxGeoJSONFeature) => {
      if (feature.id) {
        map!.removeFeatureState(feature, "hover");
      }
    },
    [map],
  );

  useEffect(() => {
    if (rightClickedFeature && !clickedFeatureBelongsToCurrentSelection) {
      setCurrentSelectionArray(resetListIfNotAlreadyEmpty);
    }
  }, [
    clickedFeatureBelongsToCurrentSelection,
    rightClickedFeature,
    setCurrentSelectionArray,
  ]);

  const clickedAFeatureThatIsNotSelected =
    rightClickedFeature &&
    clickedFeatureIsProjectFeature &&
    (!clickedFeatureBelongsToCurrentSelection ||
      selectedProjectFeatures.length === 1);

  if (
    canEdit &&
    clickedFeatureIsProjectFeature &&
    clickedAFeatureThatIsNotSelected
  ) {
    return (
      <SingleFeatureMenu
        feature={rightClickedFeature ?? selectedProjectFeatures[0]}
        featuresOnPoint={featuresOnPoint}
        enableShowLayerInfo={hasVisibleWmsLayers}
        closeMenu={closeMenu}
        sampleWmsCallback={sampleWmsCallback}
        onSelectFeature={onSelectElement}
        onMouseEnterFeature={onMouseEnterFeature}
        onMouseLeaveFeature={onMouseLeaveFeature}
      />
    );
  }

  if (dynamicLayerSelection.length !== 0 && canEdit)
    return (
      <OneOrMoreCanvasAndExternalSelectionMenu
        closeMenu={closeMenu}
        selectedProjectFeatures={selectedProjectFeatures}
        dynamicLayerSelection={dynamicLayerSelection}
        sampleWmsCallback={sampleWmsCallback}
        featuresOnPoint={featuresOnPoint}
        enableShowLayerInfo={hasVisibleWmsLayers}
        onSelectFeature={onSelectElement}
        onMouseEnterFeature={onMouseEnterFeature}
        onMouseLeaveFeature={onMouseLeaveFeature}
      />
    );

  if (selectedProjectFeatures.length !== 0 && canEdit)
    return (
      <OneOrMoreCanvasSelectionMenu
        closeMenu={closeMenu}
        selectedProjectFeatures={selectedProjectFeatures}
        sampleWmsCallback={sampleWmsCallback}
        featuresOnPoint={featuresOnPoint}
        enableShowLayerInfo={hasVisibleWmsLayers}
        onSelectFeature={onSelectElement}
        onMouseEnterFeature={onMouseEnterFeature}
        onMouseLeaveFeature={onMouseLeaveFeature}
      />
    );

  return (
    <NoSelectionMenu
      sampleWmsCallback={sampleWmsCallback}
      closeMenu={closeMenu}
      featuresOnPoint={featuresOnPoint}
      onSelectFeature={onSelectElement}
      enableShowLayerInfo={hasVisibleWmsLayers}
      onMouseEnterFeature={onMouseEnterFeature}
      onMouseLeaveFeature={onMouseLeaveFeature}
    />
  );
};

export default SelectionMenu;
