import { z } from "zod";
import { PROJECT_DATA_API_VERSION } from "../../../services/projectDataAPIService";
import {
  addTokenToOptions,
  fetchEnhancerWithToken,
  fetchSchemaWithToken,
} from "../../../services/utils";
import { LayerCollection } from "../LayerSettings/types";

// NOTE: this is the data we're storing in the backend; the biggest reason
// for having different types is that the backend stores layer ids as a `string[]`,
// but in the frontend we replace the ids with the actual layer objects.
export const _BackendLayerCollection = z.object({
  id: z.string(),
  alias: z.string(),
  layerIds: z.string().array(),
  standard: z.boolean(),
});
export type BackendLayerCollection = z.infer<typeof _BackendLayerCollection>;

export const postLayerCollection = (
  nodeId: string,
  collection: Partial<BackendLayerCollection> = {},
): Promise<BackendLayerCollection> => {
  return fetchSchemaWithToken(
    _BackendLayerCollection,
    `/api/layer-collection/${nodeId}`,
    addTokenToOptions({
      method: "post",
      body: JSON.stringify({
        alias: collection.alias,
        layerIds: collection.layerIds,
        standard: collection.standard,
      }),
      headers: {
        "x-project-data-client-version": PROJECT_DATA_API_VERSION,
      },
    }),
  );
};

export const putLayerCollection = async (
  nodeId: string,
  update: LayerCollection,
) => {
  return fetchSchemaWithToken(
    _BackendLayerCollection,
    `/api/layer-collection/${nodeId}/${update.id}`,
    addTokenToOptions({
      method: "put",
      body: JSON.stringify({
        id: update.id,
        alias: update.name,
        layerIds: update.layers!.map((l) => l.id),
        standard: update.isDefault,
      }),
      headers: {
        "x-project-data-client-version": PROJECT_DATA_API_VERSION,
      },
    }),
  );
};

export const deleteLayerCollection = (
  nodeId: string,
  collection: LayerCollection,
) => {
  return fetchEnhancerWithToken(
    `/api/layer-collection/${nodeId}/${collection.id}`,
    addTokenToOptions({
      method: "delete",
      headers: {
        "x-project-data-client-version": PROJECT_DATA_API_VERSION,
      },
    }),
  );
};

export const copyCollectionToProject = (
  collectionId: string,
  nodeId: string,
  toNodeId: string,
  customLayerIds: string[],
  excludeLayerIds: string[],
) => {
  return fetchEnhancerWithToken(
    `/api/layer-collection/clone/${nodeId}/${toNodeId}/${collectionId}`,
    addTokenToOptions({
      method: "post",
      headers: {
        "x-project-data-client-version": PROJECT_DATA_API_VERSION,
        "content-type": "application/json",
      },
      body: JSON.stringify({
        customLayerIds,
        excludeLayerIds,
      }),
    }),
  );
};

export const listLayerCollections = (nodeId: string, version?: number) => {
  return fetchSchemaWithToken(
    _BackendLayerCollection.array(),
    `/api/layer-collection/${nodeId}${version ? `?version=${version}` : ""}`,
    addTokenToOptions({
      method: "get",
      headers: {
        "x-project-data-client-version": PROJECT_DATA_API_VERSION,
      },
    }),
  );
};
