import React from "react";
import styled from "styled-components";
import { TurbineFeature } from "types/feature";
import {
  FoundationMaxDepths,
  FoundationMinDepths,
  FoundationType,
} from "types/foundations";
import WarningTriangle from "@icons/24/WarningTriangle.svg?react";
import { isDefined } from "utils/predicates";
import { MAX_DEPTH_FIXED, MIN_DEPTH_FLOATING } from "@constants/park";
import { IconREMSize } from "styles/typography";
import { colors } from "styles/colors";
import Button from "components/General/Button";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";

const WarningWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const WarningTextColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const warningTextStyle = { alignSelf: "flex-start" };

const ScaledWarningTriangle = () => {
  return (
    <IconREMSize
      height={2}
      width={2}
      style={{ alignSelf: "flex-start", marginTop: "0.5rem" }}
      stroke={colors.orange}
    >
      <WarningTriangle />
    </IconREMSize>
  );
};

export const FoundationWarnings = ({
  isFloatingFoundation,
  isFixedFoundation,
  floatingTurbines,
  fixedTurbines,
  selectedTurbines,
  foundation,
  onSelectTurbinesClick,
}: {
  isFloatingFoundation: boolean;
  isFixedFoundation: boolean;
  floatingTurbines: TurbineFeature[];
  fixedTurbines: TurbineFeature[];
  selectedTurbines: TurbineFeature[];
  foundation: FoundationType | undefined;
  onSelectTurbinesClick?(): void;
}) => {
  return (
    <>
      {isFloatingFoundation &&
        floatingTurbines.length < selectedTurbines.length &&
        floatingTurbines.length > 0 && (
          <WarningWrapper>
            <ScaledWarningTriangle />
            <WarningTextColumn>
              <p style={warningTextStyle}>
                Some selected turbines are too shallow for the selected
                foundation (&lt;{" "}
                {isDefined(foundation)
                  ? FoundationMinDepths[foundation.type]
                  : MIN_DEPTH_FLOATING}{" "}
                m). Foundations and mooring will be generated for{" "}
                {floatingTurbines.length} out of {selectedTurbines.length}{" "}
                turbines.
              </p>
              {onSelectTurbinesClick && (
                <Button
                  onClick={onSelectTurbinesClick}
                  text={"Select turbines"}
                />
              )}
            </WarningTextColumn>
          </WarningWrapper>
        )}
      {isFloatingFoundation && floatingTurbines.length === 0 && (
        <SimpleAlert
          text={`All selected turbines are too shallow for the current foundation (< ${
            isDefined(foundation)
              ? FoundationMinDepths[foundation.type]
              : MIN_DEPTH_FLOATING
          } m). Please select another alternative.`}
          type={"error"}
        ></SimpleAlert>
      )}
      {isFixedFoundation &&
        fixedTurbines.length < selectedTurbines.length &&
        fixedTurbines.length > 0 && (
          <WarningWrapper>
            <ScaledWarningTriangle />
            <WarningTextColumn>
              <p style={warningTextStyle}>
                Some selected turbines are too deep for the selected foundation
                (&gt;{" "}
                {isDefined(foundation)
                  ? FoundationMaxDepths[foundation.type]
                  : MAX_DEPTH_FIXED}{" "}
                m). Foundations will be generated for {fixedTurbines.length} out
                of {selectedTurbines.length} turbines.
              </p>
              {onSelectTurbinesClick && (
                <Button
                  onClick={onSelectTurbinesClick}
                  text={"Select turbines"}
                />
              )}
            </WarningTextColumn>
          </WarningWrapper>
        )}
      {isFixedFoundation && fixedTurbines.length === 0 && (
        <SimpleAlert
          text={`All selected turbines are too deep for the current foundation (> ${
            isDefined(foundation)
              ? FoundationMaxDepths[foundation.type]
              : MAX_DEPTH_FIXED
          } m). Please select another alternative.`}
          type={"error"}
        ></SimpleAlert>
      )}
    </>
  );
};
