import GeorefImageModal from "../GeorefImageModal/GeorefImageModal";

const RightSideModals = () => {
  return (
    <>
      <GeorefImageModal />
    </>
  );
};

export default RightSideModals;
