import React, {
  useRef,
  useMemo,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import styled from "styled-components";
import useBooleanState from "hooks/useBooleanState";
import { useLocalStorage } from "hooks/useBrowserStorage";
import { isDefined } from "utils/predicates";

const getCssVariableName = (
  variable: string | undefined,
): string | undefined => {
  if (!variable) {
    return undefined;
  }
  return variable.startsWith("--") ? variable : `--${variable}`;
};

// Use native resize event to update the save width in localStorage
export const useHorizontalResize = (
  elementRef: React.RefObject<HTMLElement>,
  cssVariableToUpdate: string,
  defaultIsEnabled: boolean = true,
) => {
  const [isEnabled, setIsEnabled] = useState(defaultIsEnabled);
  const cssVariableNameWithPrefix = useMemo(() => {
    if (!cssVariableToUpdate) {
      return undefined;
    }

    return cssVariableToUpdate.startsWith("--")
      ? cssVariableToUpdate
      : `--${cssVariableToUpdate}`;
  }, [cssVariableToUpdate]);

  const [storedWidth, setStoredWidth] = useLocalStorage<string>(
    `vind:saved-style-${cssVariableNameWithPrefix}`,
  );

  useLayoutEffect(() => {
    if (!storedWidth || !cssVariableNameWithPrefix || !isEnabled) {
      return;
    }

    document.documentElement.style.setProperty(
      cssVariableNameWithPrefix,
      storedWidth,
    );
  }, [cssVariableNameWithPrefix, isEnabled, storedWidth]);

  useEffect(() => {
    if (!elementRef.current || !isEnabled) {
      return;
    }

    const resizeObserver = new ResizeObserver(([entry]) => {
      const currWidth = entry.contentRect.width;
      if (currWidth === 0) {
        return;
      }

      const remValue = parseFloat(
        getComputedStyle(document.documentElement).fontSize,
      );
      const widthInRem = currWidth / remValue;
      if (cssVariableNameWithPrefix) {
        setStoredWidth(`${widthInRem}rem`);
      }
    });

    resizeObserver.observe(elementRef.current, {
      box: "content-box",
    });
    return () => {
      resizeObserver.disconnect();
    };
    //  We want to run effect when elementRef.current is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cssVariableNameWithPrefix, elementRef.current, setStoredWidth]);

  return [setIsEnabled];
};

const ResizeBar = styled.div`
  width: 8px;
  position: relative;
  transform: translateX(-50%);
  flex-shrink: 0;
  height: 100%;
  cursor: col-resize;
`;

const ResizeBarVertical = ({
  resizeElemRef,
  barSide,
  cssVariableToUpdate,
  style,
  onAfterResize,
  onBeforeResize,
}: {
  resizeElemRef: React.RefObject<HTMLElement>;
  barSide: "LEFT" | "RIGHT";
  cssVariableToUpdate?: string;
  style?: React.CSSProperties;
  onBeforeResize?(e: React.MouseEvent): void;
  onAfterResize?(): void;
}) => {
  const [isResizing, toggleIsResizing] = useBooleanState(false);
  const mouseStartPos = useRef<number | undefined>();
  const startWidth = useRef<number | undefined>();
  const blockingDivRef = useRef<HTMLDivElement>(null);

  const cssVariableNameWithPrefix = useMemo(
    () => getCssVariableName(cssVariableToUpdate),
    [cssVariableToUpdate],
  );
  const [storedWidth, setStoredWidth] = useLocalStorage<string>(
    `vind:saved-style-${cssVariableNameWithPrefix}`,
  );

  useEffect(() => {
    if (!isResizing || !resizeElemRef.current) {
      return;
    }

    const mouseMoveListener = (e: MouseEvent) => {
      if (!isDefined(mouseStartPos.current) || !isDefined(startWidth.current)) {
        return;
      }

      // Move blocking div to under cursor
      if (blockingDivRef.current) {
        blockingDivRef.current.style.left = `${e.clientX}px`;
        blockingDivRef.current.style.top = `${e.clientY}px`;
      }

      const currMovementX =
        barSide === "RIGHT"
          ? e.clientX - mouseStartPos.current
          : mouseStartPos.current - e.clientX;

      const newWidth = startWidth.current! + currMovementX;

      resizeElemRef.current!.style.width = `${newWidth}px`;
      const remValue = parseFloat(
        getComputedStyle(document.documentElement).fontSize,
      );

      const actualWidth = resizeElemRef.current!.getBoundingClientRect().width;
      const widthInRem = actualWidth / remValue;
      if (cssVariableNameWithPrefix) {
        setStoredWidth(`${widthInRem}rem`);
      }
    };

    const mouseUpListener = () => {
      toggleIsResizing();
      mouseStartPos.current = undefined;
      startWidth.current = undefined;
      if (resizeElemRef.current) {
        resizeElemRef.current.style.transition = "";
      }
      onAfterResize?.();
    };

    document.addEventListener("mousemove", mouseMoveListener);
    document.addEventListener("mouseup", mouseUpListener);
    return () => {
      document.removeEventListener("mousemove", mouseMoveListener);
      document.removeEventListener("mouseup", mouseUpListener);
    };
  }, [
    barSide,
    resizeElemRef,
    isResizing,
    toggleIsResizing,
    cssVariableNameWithPrefix,
    onAfterResize,
    setStoredWidth,
  ]);

  useEffect(() => {
    if (!storedWidth || !cssVariableNameWithPrefix) {
      return;
    }

    document.documentElement.style.setProperty(
      cssVariableNameWithPrefix,
      storedWidth,
    );
  }, [cssVariableNameWithPrefix, storedWidth]);

  return (
    <>
      {isResizing && (
        /* This is an element that is always under the cursor when the user is resizing.
        It will make sure the mouseup event is running event if the user mouseup:s on a disabled input
        (Previously the event didnt get called)
        */
        <div
          ref={blockingDivRef}
          style={{
            width: "30px",
            height: "30px",
            zIndex: "9000",
            transform: "translate(-50%, -50%)",
            position: "fixed",
            cursor: "col-resize",
          }}
        />
      )}
      <ResizeBar
        onMouseDown={(e) => {
          if (e.button !== 0) {
            return;
          }
          e.preventDefault();
          onBeforeResize?.(e);
          if (resizeElemRef.current) {
            resizeElemRef.current.style.transition = "unset";
            mouseStartPos.current = e.clientX;
            startWidth.current =
              resizeElemRef.current.getBoundingClientRect().width;
          }
          toggleIsResizing();
        }}
        style={style}
      />
    </>
  );
};

export default ResizeBarVertical;
