/// <reference types="vite-plugin-svgr/client" />
import { Row } from "components/General/Layout";
import GoBackIcon from "@icons/24/GoBack.svg?react";

export default function GoBack({ onClick }: { onClick: () => void }) {
  return (
    <Row
      style={{ alignItems: "center", cursor: "pointer", width: "fit-content" }}
      onClick={onClick}
    >
      <GoBackIcon />
      <h4 style={{ margin: 0 }}>Go back</h4>
    </Row>
  );
}
