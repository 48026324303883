import ResetIcon from "@icons/14/Reset.svg?react";
import Button from "components/General/Button";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { colors } from "styles/colors";
import {
  compareIsLoading,
  selectedComparisonAttributesAtom,
  selectedParksAtom,
  shownCompareDataAtom,
  visibleComparisonListRowsAtom,
} from "../state";
import Tooltip from "components/General/Tooltip";
import { Suspense, useCallback } from "react";
import { libraryAndProjectConfigurationsSelectorFamily } from "state/configuration";
import { libraryAndProjectCostConfigurationsSelectorFamily } from "state/costConfigurations";
import { getParkFeatureInBranchSelector } from "state/park";
import { projectIdSelector } from "state/pathParams";
import { getBranchSelectorFamily } from "state/timeline";
import { windSourceConfigurationsAtomFamily } from "state/windSourceConfiguration";
import { useColumnTemplates } from "../columnTemplates";
import { useToast } from "hooks/useToast";
import { range } from "utils/utils";
import { makeCSVString } from "components/CompareParksModal/CompareDownloadButton";

const Inner = () => {
  const projectId = useRecoilValue(projectIdSelector) ?? "";
  const selectedParkIds = useRecoilValue(selectedParksAtom({ projectId }));
  const shownData = useRecoilValue(shownCompareDataAtom);
  const selectedAttributes = useRecoilValue(selectedComparisonAttributesAtom);
  const columnTemplates = useColumnTemplates();
  const configurations = useRecoilValue(
    libraryAndProjectConfigurationsSelectorFamily({ nodeId: projectId }),
  );
  const visibleComparisonListRows = useRecoilValue(
    visibleComparisonListRowsAtom,
  );
  const costConfigurations = useRecoilValue(
    libraryAndProjectCostConfigurationsSelectorFamily({ nodeId: projectId }),
  );
  const windConfigurations = useRecoilValue(
    windSourceConfigurationsAtomFamily({ projectId }),
  );

  const getPark = useRecoilCallback(
    ({ snapshot }) =>
      ({ parkId, branchId }) => {
        const park = snapshot
          .getLoadable(getParkFeatureInBranchSelector({ parkId, branchId }))
          .getValue();
        return park;
      },
    [],
  );

  const getBranch = useRecoilCallback(
    ({ snapshot }) =>
      (branchId: string) => {
        const branch = snapshot
          .getLoadable(getBranchSelectorFamily({ branchId, projectId }))
          .getValue();
        return branch;
      },
    [projectId],
  );

  const getSortOrder = useCallback(
    (comparisonId: string) => {
      return selectedParkIds.findIndex(
        (park) => park.comparisonId === comparisonId,
      );
    },
    [selectedParkIds],
  );

  const { success } = useToast();

  return (
    <Tooltip text="Download data for the compare production number playwright test">
      <Button
        style={{ background: `${colors.orange}80` }}
        icon={<ResetIcon />}
        buttonType="secondary"
        onClick={() => {
          const csv = makeCSVString(
            shownData,
            selectedAttributes,
            columnTemplates,
            getPark,
            getSortOrder,
            getBranch,
            configurations,
            costConfigurations,
            windConfigurations,
            visibleComparisonListRows,
          );

          const lines = csv.split("\n");
          const rows = lines.map((line) => line.split(","));
          const numParks = rows[0].length - 1;
          const data = range(0, numParks).map((i) =>
            Object.fromEntries(rows.map((row) => [row[0], row[i + 1]])),
          );
          console.log(data);
          success("Logged to console");
        }}
      />
    </Tooltip>
  );
};

const Loader = () => (
  <SkeletonBlock style={{ height: "3.2rem", width: "3.2rem" }} />
);

export const UpdateCompareTestData = () => {
  const isLoadingValues = useRecoilValue(compareIsLoading);
  const isLoading = Object.values(isLoadingValues).some((v) => v);
  if (isLoading) return <Loader />;
  return (
    <Suspense fallback={<Loader />}>
      <Inner />
    </Suspense>
  );
};
