import * as turf from "@turf/turf";
import { orTextLoader } from "components/Loading/Skeleton";
import {
  getAEP,
  getCapacity,
  getCapacityFactor,
  getGrossEnergy,
  getTotalLoss,
  getStoppedReason,
  getStoppedReasonFromAnalysis,
  getAnalysisProgress,
} from "components/ProductionV2/state";
import {
  AnalysisStoppedTypes,
  analysisStoppedText,
} from "components/ProductionV2/types";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { getTurbinesSelectorFamily } from "../../../state/layout";
import { spaceMedium } from "../../../styles/space";
import { TurbineFeature } from "../../../types/feature";
import { FlexGrid2 } from "../../General/Form";
import { Row } from "../../General/Layout";
import {
  formatGWConditional,
  formatGWhConditional,
  formatLossToPercent,
  formatPercent,
} from "../../ProductionV2/format";
import { useDashboardContext } from "../Dashboard";
import { CenterContainer, SafeCard } from "./Base";
import { undefMap } from "utils/utils";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";

const EnergyDetailsInner = () => {
  const { park, triggerId } = useDashboardContext();
  const parkId = park.id;
  const turbines: TurbineFeature[] = useRecoilValue(
    getTurbinesSelectorFamily({ parkId }),
  );
  const area = Math.round(turf.area(park) / (1000 * 1000));

  const aep = useRecoilValueLoadable(getAEP(triggerId));
  const gross = useRecoilValueLoadable(getGrossEnergy(triggerId));
  const totalLoss = useRecoilValueLoadable(getTotalLoss(triggerId));

  const capacity = useRecoilValueLoadable(getCapacity(triggerId));
  const capacityFactor = useRecoilValueLoadable(getCapacityFactor(triggerId));

  const stoppedReason = useRecoilValueLoadable(
    getStoppedReason(triggerId),
  ).valueMaybe();

  const analysisStoppedReason = useRecoilValueLoadable(
    getStoppedReasonFromAnalysis(triggerId),
  ).valueMaybe();
  const analysisProgress = useRecoilValueLoadable(
    getAnalysisProgress(triggerId),
  ).valueMaybe();

  const density = capacity.map((capacity) =>
    capacity ? (capacity / area).toPrecision(3) : undefined,
  );

  if (turbines.length === 0) {
    return (
      <CenterContainer>
        <SimpleAlert text={"No turbines in the park."} type={"error"} />
      </CenterContainer>
    );
  }

  if (stoppedReason) {
    return (
      <CenterContainer>
        <SimpleAlert
          title="Analysis stopped"
          text={analysisStoppedText[stoppedReason]}
          type={"error"}
        />
      </CenterContainer>
    );
  }
  if (analysisStoppedReason) {
    return (
      <SimpleAlert
        title="Analysis stopped"
        text={
          analysisStoppedText[analysisStoppedReason as AnalysisStoppedTypes]
        }
        type={"error"}
      />
    );
  }

  return (
    <FlexGrid2 style={{ padding: spaceMedium }}>
      <Row>
        <p>Capacity</p>
        {orTextLoader(
          capacity,
          undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ?? "",
          (cap) => (
            <p>{formatGWConditional(cap / 1e3)}</p>
          ),
        )}
      </Row>

      <Row>
        <p>Gross energy</p>
        {orTextLoader(
          gross,
          undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ?? "",
          (g) => (
            <p>{formatGWhConditional(g)}</p>
          ),
        )}
      </Row>

      <Row>
        <p>Net energy</p>
        {orTextLoader(
          aep,
          undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ?? "",
          (c) => (
            <p>{formatGWhConditional(c)}</p>
          ),
        )}
      </Row>

      <Row>
        <p>Total loss</p>
        {orTextLoader(
          totalLoss,
          undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ?? "",
          (l) => (
            <p>{formatLossToPercent(l)}%</p>
          ),
        )}
      </Row>

      <Row>
        <p>Capacity density</p>
        {orTextLoader(
          density,
          undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ?? "",
          (d) => (
            <p>{d} MW / km²</p>
          ),
        )}
      </Row>

      <Row>
        <p>Capacity factor</p>
        {orTextLoader(
          capacityFactor,
          undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ?? "",
          (cf) => (
            <p>{formatPercent(cf)}</p>
          ),
        )}
      </Row>
    </FlexGrid2>
  );
};

export const EnergyWidget = () => (
  <SafeCard title="Energy" id="Energy">
    <EnergyDetailsInner />
  </SafeCard>
);
