import * as turf from "@turf/turf";
import { useDrawMode } from "components/MapControls/useActivateDrawMode";
import { Feature, FeatureCollection } from "geojson";
import mapboxgl, { MapMouseEvent } from "mapbox-gl";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { mapRefAtom } from "../../state/map";
import { projectFeatureMap } from "../../state/projectLayers";
import { StandardBox } from "../../styles/boxes/Boxes";
import {
  SNAP_PIXELS,
  kmPerPixel,
  snapToClosest,
} from "../MapControls/CustomModes/lib/snapping";
import {
  currentSnapLines,
  currentSnapPoints,
} from "../MapControls/useUpdateSnapPoints";
import { addLayer } from "components/Mapbox/utils";
import {
  measurePointLayerId,
  measurePointSourceId,
  measureLineLayerId,
} from "components/Mapbox/constants";
import { spacing4 } from "styles/space";
import { getHumanReadableDistance } from "utils/geometry";

const pointLayer: mapboxgl.CircleLayer = {
  id: measurePointLayerId,
  source: measurePointSourceId,
  type: "circle",
  paint: {
    "circle-radius": 5,
    "circle-color": "#000",
  },
  filter: ["in", "$type", "Point"],
};

const lineLayer: mapboxgl.LineLayer = {
  id: measureLineLayerId,
  type: "line",
  source: measurePointSourceId,
  layout: {
    "line-cap": "round",
    "line-join": "round",
  },
  paint: {
    "line-color": "#000",
    "line-width": 2.5,
  },
  filter: ["in", "$type", "LineString"],
};

const DistanceMeterWrapper = styled(StandardBox)`
  position: fixed;
  padding: 1rem;
  top: calc(
    var(--top-bar-menu-height) + var(--branch-tab-bar-height) + ${spacing4}
  );
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Row = ({ points }: { points: number[][] }) => {
  const distM = coordsToDistance(points);
  const distNM = coordsToDistanceNauticalMile(points);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "1rem",
        justifyContent: "space-evenly",
      }}
    >
      <div>{getHumanReadableDistance(distM)}</div>/
      <div>{(Math.round(distNM * 100) / 100).toFixed(2)}NM</div>
    </div>
  );
};

export const MeasureDistanceMode = "measure-distance-mode-type";

const coordsToDistance = (distanceCoords: number[][]): number => {
  if (distanceCoords.length === 0) return 0.0;
  const linestring: Feature = {
    type: "Feature",
    geometry: {
      type: "LineString",
      coordinates: distanceCoords,
    },
    properties: {},
  };
  return turf.length(linestring, { units: "meters" });
};

const coordsToDistanceNauticalMile = (distanceCoords: number[][]): number => {
  return coordsToDistance(distanceCoords) / 1852;
};

const Distance = () => {
  const [leftMenuModeActive] = useDrawMode();

  if (leftMenuModeActive !== MeasureDistanceMode) return null;

  return <DistanceInner />;
};

const DistanceInner = () => {
  const [hoverPoint, setHoverPoint] = useState([0, 0]);
  const map = useRecoilValue(mapRefAtom);
  const [, setLeftMenuModeActive] = useDrawMode();
  const [distanceCoords, setDistanceCoords] = useState<number[][]>([]);
  const featureMap = useRecoilValue(projectFeatureMap);

  useEffect(() => {
    if (!map) return;
    map.addSource(measurePointSourceId, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });
    addLayer(map, pointLayer);
    addLayer(map, lineLayer);
    return () => {
      map.removeLayer(lineLayer.id);
      map.removeLayer(pointLayer.id);
      map.removeSource(measurePointSourceId);
    };
  }, [map]);

  useEffect(() => {
    if (!map) return;

    const linestring: Feature = {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: [...distanceCoords, hoverPoint],
      },
      properties: {},
    };

    const geojson: FeatureCollection = {
      type: "FeatureCollection" as const,
      features: distanceCoords
        .map<Feature>((c, i) => ({
          type: "Feature" as const,
          geometry: {
            type: "Point",
            coordinates: [c[0], c[1]],
          },
          properties: {
            id: i,
          },
        }))
        .concat([linestring]),
    };

    const source = map.getSource(measurePointSourceId);
    if (source?.type === "geojson") source.setData(geojson);
  }, [distanceCoords, hoverPoint, map]);

  useEffect(() => {
    if (!map) return;

    const click = (e: MapMouseEvent) => {
      e.preventDefault();
      const features = map.queryRenderedFeatures(e.point, {
        layers: [measurePointLayerId],
      });

      if (features.length) {
        const id = features[0]?.properties?.["id"];
        if (!id) return;
        const filteredDistanceCoords = distanceCoords.filter(
          (_, i) => i !== id,
        );
        setDistanceCoords(filteredDistanceCoords);
      } else {
        const { lng, lat } = e.lngLat;
        let clickedPoint = [lng, lat];

        const dynamicSnapDistance =
          SNAP_PIXELS * kmPerPixel(e.lngLat.lat, map.getZoom());
        const snappingDisabled = e.originalEvent.shiftKey;
        const pointToUse = snappingDisabled
          ? clickedPoint
          : snapToClosest(
              currentSnapPoints,
              currentSnapLines,
              clickedPoint,
              dynamicSnapDistance,
            );

        setDistanceCoords((coords) => [...coords, pointToUse]);
      }
    };

    const mousemove = (e: MapMouseEvent) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: [measurePointLayerId],
      });

      const { lng, lat } = e.lngLat;
      let clickedPoint = [lng, lat];

      const dynamicSnapDistance =
        SNAP_PIXELS * kmPerPixel(e.lngLat.lat, map.getZoom());
      const snappingDisabled = e.originalEvent.shiftKey;
      const pointToUse = snappingDisabled
        ? clickedPoint
        : snapToClosest(
            currentSnapPoints,
            currentSnapLines,
            clickedPoint,
            dynamicSnapDistance,
          );

      setHoverPoint(pointToUse);

      map.getCanvas().style.cursor =
        features.length !== 0 && features[0].layer.type === "circle"
          ? "no-drop"
          : "crosshair";
    };

    map.on("click", click);
    map.on("mousemove", mousemove);

    const abort = (e: KeyboardEvent) => {
      if (e.code === "Escape") {
        setLeftMenuModeActive(undefined);
      }
    };

    document.addEventListener("keydown", abort);

    return () => {
      map.off("click", click);
      map.off("mousemove", mousemove);
      document.removeEventListener("keydown", abort);
    };
  }, [distanceCoords, featureMap, map, setLeftMenuModeActive]);

  return (
    <DistanceMeterWrapper>
      <Row points={distanceCoords} />
      <Row points={[...distanceCoords, hoverPoint]} />
    </DistanceMeterWrapper>
  );
};

export default Distance;
