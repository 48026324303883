import { useEffect } from "react";
import { selectorFamily, useRecoilValue } from "recoil";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { WarningProps } from "./ValidationWarnings";
import { getParkFeatureInBranchSelector } from "state/park";
import { branchIdSelector } from "state/pathParams";

export const holeInParkWarningSelector = selectorFamily<
  { type: ValidationWarningTypes.HoleInPark; featureIds: string[] } | undefined,
  { parkId: string; branchId: string }
>({
  key: "holeInParkWarningSelector",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const park = get(getParkFeatureInBranchSelector({ parkId, branchId }));

      if (!park) return;

      const parkHasHoles =
        park.geometry.type === "Polygon" &&
        park.geometry.coordinates.length > 1;

      return parkHasHoles
        ? {
            type: ValidationWarningTypes.HoleInPark,
            featureIds: [park.id],
          }
        : undefined;
    },
});

const HoleInPark = ({
  parkId,
  warn,
  remove,
}: WarningProps & { parkId: string }) => {
  const branchId = useRecoilValue(branchIdSelector);
  const holeInParkWarning = useRecoilValue(
    holeInParkWarningSelector({ parkId, branchId: branchId ?? "" }),
  );

  useEffect(() => {
    if (!holeInParkWarning) return;
    console.log("holeInParkWarning", holeInParkWarning);
    warn(holeInParkWarning);
    return () => {
      remove(ValidationWarningTypes.HoleInPark);
    };
  }, [remove, warn, holeInParkWarning]);

  useEffect(
    () => () => {
      remove(ValidationWarningTypes.HoleInPark);
    },
    [remove],
  );

  return null;
};

export default HoleInPark;
