/// <reference types="vite-plugin-svgr/client" />
import UserInfo from "components/Comments/MapModal/UserInfo";
import Button from "components/General/Button";
import { Column, Row } from "components/General/Layout";
import {
  analysisManagersSelector,
  cableManagersSelector,
  dataPackageManagersSelector,
  financialManagersSelector,
  foundationManagersSelector,
  turbineManagersSelector,
} from "components/Organisation/Library/state";
import {
  ContentTableColumn,
  ContentTableRow,
  TabDescription,
  TextEllipsis,
} from "components/Organisation/OrganisationRightSide/style";
import { usersInOrganisationState } from "components/Organisation/state";
import { useCallback, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { useTypedPath } from "state/pathParams";
import { adminInOrganisationSelectorFamily } from "state/user";
import { isGroupOrgResource, isPersonalOrgResource } from "utils/predicates";
import Trashcan from "@icons/24/Bin.svg?react";
import AddIcon from "@icons/24/Add.svg?react";
import TeamMeeting from "@icons/24/TeamMeeting.svg?react";
import { organisationGroupsState } from "components/Organisation/Groups/state";
import { SVGWrapper } from "@icons/svgUtils";
import {
  OrganisationResources,
  OrgResource,
} from "components/Organisation/OrganisationRightSide/types";
import UserGroupAccessModal, {
  GroupAccess,
  UserAccess,
} from "../../UserGroupAccessModal";
import useUserOrganisationResourcesCrud from "../../hooks/useUserOrganisationResourcesCrud";
import useGroupOrganisationResourcesCrud from "../../hooks/useGroupOrganisationResourcesCrud";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { IconBtn } from "components/General/Icons";

const ORGANISATION_RESOURCE_NAME: Record<OrganisationResources, string> = {
  ["org_turbine_manage"]: "turbines",
  ["org_foundation_manage"]: "foundations",
  ["org_cable_manage"]: "cables",
  ["org_analysis_manage"]: "analysis",
  ["org_financial_manage"]: "financial",
  ["org_data_package_manage"]: "data packages",
};

export function FoundationManagersOuter() {
  const { organisationId } = useTypedPath("organisationId");
  const foundationManagers = useRecoilValue(
    foundationManagersSelector({ organisationId }),
  );

  return (
    <ResourceManagers
      type={"org_foundation_manage"}
      resourceManagers={foundationManagers}
    />
  );
}

export function AnalysisManagersOuter() {
  const { organisationId } = useTypedPath("organisationId");
  const analysisManagers = useRecoilValue(
    analysisManagersSelector({ organisationId }),
  );

  return (
    <ResourceManagers
      type={"org_analysis_manage"}
      resourceManagers={analysisManagers}
    />
  );
}

export function TurbineManagersOuter() {
  const { organisationId } = useTypedPath("organisationId");
  const turbineManagers = useRecoilValue(
    turbineManagersSelector({ organisationId }),
  );

  return (
    <ResourceManagers
      type={"org_turbine_manage"}
      resourceManagers={turbineManagers}
    />
  );
}

export function CableManagersOuter() {
  const { organisationId } = useTypedPath("organisationId");
  const cableMangers = useRecoilValue(
    cableManagersSelector({ organisationId }),
  );

  return (
    <ResourceManagers
      type={"org_cable_manage"}
      resourceManagers={cableMangers}
    />
  );
}

export function FinancialManagersOuter() {
  const { organisationId } = useTypedPath("organisationId");
  const financialManagers = useRecoilValue(
    financialManagersSelector({ organisationId }),
  );

  return (
    <ResourceManagers
      type={"org_financial_manage"}
      resourceManagers={financialManagers}
    />
  );
}

export function DataLayerManagersOuter() {
  const { organisationId } = useTypedPath("organisationId");
  const dataPackageManagers = useRecoilValue(
    dataPackageManagersSelector({ organisationId }),
  );

  return (
    <ResourceManagers
      type={"org_data_package_manage"}
      resourceManagers={dataPackageManagers}
    />
  );
}

function ResourceManagers({
  type,
  resourceManagers,
}: {
  type: OrganisationResources;
  resourceManagers: OrgResource[];
}) {
  const { organisationId } = useTypedPath("organisationId");
  const { add: addUserManager, remove: removeUser } =
    useUserOrganisationResourcesCrud();
  const { add: addGroupManager, remove: removeGroup } =
    useGroupOrganisationResourcesCrud();
  const users = useRecoilValue(usersInOrganisationState(organisationId));
  const groups = useRecoilValue(organisationGroupsState({ organisationId }));
  const isAdminInOrg = useRecoilValue(
    adminInOrganisationSelectorFamily({ organisationId }),
  );

  const userManagers = useMemo(
    () => resourceManagers.filter(isPersonalOrgResource),
    [resourceManagers],
  );
  const groupManagers = useMemo(
    () => resourceManagers.filter(isGroupOrgResource),
    [resourceManagers],
  );

  const [showAddManagers, setShowAddManagers] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState<number>(0);
  const [removeInProgress, setRemoveInProgress] = useState<
    string | undefined
  >();

  const onAddAccess = useCallback(
    async (access: { users: UserAccess[]; groups: GroupAccess[] }) => {
      setSaveInProgress(access.groups.length + access.users.length);
      setShowAddManagers(false);
      try {
        await Promise.all([
          ...access.users.map((userId) =>
            addUserManager(organisationId, userId, type).then(() =>
              setSaveInProgress((cur) => cur - 1),
            ),
          ),
          ...access.groups.map((groupId) =>
            addGroupManager(groupId, type).then(() =>
              setSaveInProgress((cur) => cur - 1),
            ),
          ),
        ]);
      } catch {
      } finally {
        setSaveInProgress(0);
      }
    },
    [addUserManager, organisationId, type, addGroupManager],
  );

  return (
    <Column style={{ gap: "2.4rem", height: "100%" }}>
      <Row style={{ alignItems: "center" }}>
        <TabDescription>
          All library {ORGANISATION_RESOURCE_NAME[type]} can be managed by these
          people.
        </TabDescription>
        {isAdminInOrg && (
          <div style={{ marginLeft: "auto", position: "relative" }}>
            <Button
              text="Add managers"
              buttonType="secondary"
              onClick={() => setShowAddManagers((cur) => !cur)}
              disabled={saveInProgress > 0}
              icon={<AddIcon />}
            />
            {showAddManagers && (
              <div style={{ position: "absolute", right: "0.8rem" }}>
                <UserGroupAccessModal
                  existingGroups={groupManagers.map((gm) => ({
                    group_id: gm.group_id,
                  }))}
                  existingUsers={userManagers.map((um) => ({
                    user_id: um.user_id,
                  }))}
                  onSave={onAddAccess}
                  onCancel={() => setShowAddManagers(false)}
                  disableResourceName={true}
                />
              </div>
            )}
          </div>
        )}
      </Row>
      <ContentTableColumn>
        {userManagers.map((tm) => {
          const user = users.find((u) => u.user_id === tm.user_id);
          if (!user) return <></>;
          return (
            <ContentTableRow key={tm.user_id}>
              <UserInfo userId={tm.user_id} />
              {isAdminInOrg && (
                <Row style={{ marginLeft: "auto" }}>
                  <IconBtn
                    onClick={(e) => {
                      e.stopPropagation();
                      setRemoveInProgress(tm.user_id);
                      removeUser(
                        organisationId,
                        tm.user_id,
                        tm.resource_name,
                      ).then(() => setRemoveInProgress(undefined));
                    }}
                    disabled={removeInProgress === tm.user_id}
                    size={"1.4rem"}
                    style={{
                      marginLeft: "auto",
                    }}
                  >
                    <Trashcan />
                  </IconBtn>
                </Row>
              )}
            </ContentTableRow>
          );
        })}
        {groupManagers.map((gm) => {
          const g = groups.find((g) => g.id === gm.group_id);
          if (!g) return <></>;
          return (
            <ContentTableRow
              key={g.id}
              style={{ justifyContent: "space-between" }}
            >
              <Row style={{ gap: "0.8rem", alignItems: "center" }}>
                <SVGWrapper size={2.2}>
                  <TeamMeeting />
                </SVGWrapper>
                <TextEllipsis style={{ margin: 0 }}>{g.name}</TextEllipsis>
              </Row>

              {isAdminInOrg && (
                <Row style={{ marginLeft: "auto" }}>
                  <IconBtn
                    onClick={(e) => {
                      e.stopPropagation();
                      setRemoveInProgress(gm.group_id);
                      removeGroup(gm.group_id, gm.resource_name).then(() =>
                        setRemoveInProgress(undefined),
                      );
                    }}
                    size={"1.4rem"}
                    disabled={removeInProgress === gm.group_id}
                  >
                    <Trashcan />
                  </IconBtn>
                </Row>
              )}
            </ContentTableRow>
          );
        })}
        {Array.from({ length: saveInProgress }, (_, index) => (
          <SkeletonBlock
            key={index}
            style={{ height: "4rem", marginTop: "0.8rem" }}
          />
        ))}
      </ContentTableColumn>
    </Column>
  );
}
