import styled from "styled-components";
import { colors } from "../../styles/colors";
import { Row } from "components/General/Layout";
import { typography } from "styles/typography";

export const LogoPlaceholder = styled.div`
  border-radius: 1.6rem;
  height: 2.2rem;
  width: 2.2rem;
  background-color: ${colors.inputOutline};
  cursor: pointer;
`;

export const RowWithIcon = styled(Row)`
  align-items: center;
  svg {
    align-self: center;
    stroke: ${colors.primaryText};
  }
`;

export const Scrollable = styled.div`
  flex-grow: 0;
  overflow-y: auto;
`;

export const ListButtonWrapper = styled.div`
  flex-grow: 1;
`;

export const ProjectFolderListWrapper = styled.div`
  margin-top: 0.9rem;
  display: flex;
  flex-direction: column;
`;

export const Margin = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProjectFolderRow = styled(Row)`
  align-items: center;
  justify-content: flex-start;
`;

export const ProjectTitle = styled.div`
  ${typography.body}
  color: ${colors.textPrimary};
  &:hover {
    color: ${colors.blue600};
  }
`;

export const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0.2rem 1rem;
  cursor: pointer;
  &:hover ${ProjectTitle} {
    color: ${colors.blue600};
  }
`;

export const TabHeader = styled.div`
  ${typography.h2}
  color: ${colors.textPrimary};
`;

export const NameChevronWrapper = styled.div<{
  selectable?: boolean;
  level: number;
  selected?: boolean;
  isDragHovering?: boolean;
}>`
  ${(p) => (p.selectable ? typography.body : typography.sub1)}
  display: flex;
  flex-direction: row;
  gap: 0.6rem;
  align-items: center;
  padding: 0.6rem 1.6rem 0.6rem ${(p) => p.level * 2.6 - 1}rem;
  border-radius: 0.4rem;
  color: ${(p) => (p.selected ? colors.textSelected : colors.textPrimary)};
  font-weight: ${(p) => (p.selected ? "600" : "400")};
  cursor: ${(p) => (p.selectable ? "pointer" : "inherit")};
  background-color: ${(p) => (p.selected ? colors.surfaceSelectedLight : "inherit")};
  &:hover {
    background: ${(p) => (p.selected ? colors.blue200 : colors.surfaceHover)};
    
  }
    ${({ isDragHovering }) =>
      isDragHovering &&
      `
      background-color: ${colors.surfaceHover};
    `}}

  svg {
    fill: ${(p) => (p.selected ? colors.iconSelected : colors.iconDefault)};
  }
`;

export const SVGWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SVGWrapperLevelised = styled.div<{ level: number }>`
  display: flex;
  align-items: center;
`;

export const TabIconWrapper = styled.div`
  display: flex;
  flex-direction: column;

  svg {
    height: 1.4rem;
    width: 1.4rem;
  }
`;

export const OrganisationPageBodyWrapper = styled.div`
  display: grid;
  width: 100%;
  min-height: 0;
  flex: 1;
  position: relative;
  transition: 0.5s;
  overflow-x: hidden;
  overflow-y: hidden;
`;
