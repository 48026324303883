/// <reference types="vite-plugin-svgr/client" />
import styled from "styled-components";
import { Suspense, useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { SubstationFeature } from "../../types/feature";
import ArrowDownIcon from "@icons/24/ArrowDown.svg?react";
import ArrowRightIcon from "@icons/24/ArrowRight.svg?react";
import Tooltip from "../General/Tooltip";
import { isDefined, isSubstation } from "../../utils/predicates";
import { useProjectElementsCrud } from "../ProjectElements/useProjectElementsCrud";
import { trackCanvasOption } from "./MenuTracking";
import { projectIdSelector } from "../../state/pathParams";
import { Divider } from "components/General/Icons";
import { ProjectFeature } from "../../types/feature";
import { MenuItem } from "../General/Menu";
import { MenuButton } from "../General/MenuButton";
import {
  NamedTooltipContainer,
  NamedTooltipWrapper,
} from "./CanvasSelectOption.style";
import { dedup } from "utils/utils";
import { substationTypeAtomFamily } from "state/substationType";
import { SkeletonBlock } from "components/Loading/Skeleton";

const TypeSelectorWrapper = styled.div`
  position: relative;
  font-size: 1.6rem;
  font-weight: 500;
`;

const Inner = ({
  substations,
  editable,
}: {
  substations: SubstationFeature[];
  editable: boolean;
}) => {
  const projectId = useRecoilValue(projectIdSelector);
  const { update: updateFeatures } = useProjectElementsCrud();

  const substationTypes = useRecoilValue(
    substationTypeAtomFamily({ projectId }),
  );

  const currentSubstationTypeIds = dedup(
    substations.map((s) => s.properties.substationTypeId).filter(isDefined),
  );

  const onSelectItem = useCallback(
    (val: string) => {
      trackCanvasOption("change-substation-type", {
        projectId,
      });
      const updatedFeatures = substations.map((t) => ({
        ...t,
        properties: { ...t.properties, substationTypeId: val },
      }));

      updateFeatures({ update: updatedFeatures });
    },
    [projectId, substations, updateFeatures],
  );

  const items = useMemo(
    () =>
      substationTypes.map((t) => ({ value: t.id, name: t.name, type: t.type })),
    [substationTypes],
  );

  return (
    <TypeSelectorWrapper>
      <MenuButton
        disabled={!editable}
        icon={<ArrowRightIcon />}
        iconOpen={<ArrowDownIcon />}
        buttonStyle={{
          border: "none",
          flexDirection: "row-reverse",
          minWidth: "11rem",
          height: "fit-content",
          justifyContent: "space-between",
          padding: 0,
        }}
        buttonType="dropdown"
        buttonText={
          currentSubstationTypeIds.length === 1
            ? substationTypes.find((t) => t.id === currentSubstationTypeIds[0])
                ?.name ?? "..."
            : "..."
        }
      >
        {items.map((item) => (
          <MenuItem
            key={item.value}
            name={`${item.name} (${item.type})`}
            onClick={() => onSelectItem(item.value)}
          />
        ))}
      </MenuButton>
    </TypeSelectorWrapper>
  );
};

export function SubstationTypeSelector({
  editable,
  selectedProjectFeatures,
}: {
  editable: boolean;
  selectedProjectFeatures: ProjectFeature[];
}) {
  const selectedSubstationFeatures = useMemo(
    () => selectedProjectFeatures.filter(isSubstation),
    [selectedProjectFeatures],
  );

  const onlySubstationsSelected =
    selectedSubstationFeatures.length === selectedProjectFeatures.length;

  if (selectedSubstationFeatures.length === 0 || !onlySubstationsSelected)
    return null;

  return (
    <>
      <Tooltip
        position="top"
        text="Change type"
        readonlyAware
        key={
          selectedSubstationFeatures.at(0)?.properties?.substationTypeId ?? ""
        }
      >
        <NamedTooltipContainer>
          <NamedTooltipWrapper>Substation type</NamedTooltipWrapper>
          <Suspense
            fallback={
              <SkeletonBlock style={{ height: "1rem", width: "10rem" }} />
            }
          >
            <Inner
              substations={selectedSubstationFeatures}
              editable={editable}
            />
          </Suspense>
        </NamedTooltipContainer>
      </Tooltip>
      <Divider />
    </>
  );
}
