import { useEffect, useState } from "react";
import { Raster } from "../../types/raster";
import { scream } from "../../utils/sentry";
import { Polygon } from "geojson";
import { ProjectFeature } from "../../types/feature";
import { promiseWorker, typedWorker } from "utils/utils";

const runningWorkers = new Map();
const workerResult = new Map();

const getCacheId = (feature: ProjectFeature<Polygon>, raster: Raster) =>
  `${raster.id}-${JSON.stringify(feature.geometry.coordinates)}`;

export const getRasterStats = async (
  feature: ProjectFeature<Polygon>,
  raster: Raster,
  signal?: AbortSignal,
): Promise<{
  minValue: number;
  maxValue: number;
  averageValue: number;
}> => {
  const cacheId = getCacheId(feature, raster);
  if (workerResult.has(cacheId)) {
    return workerResult.get(cacheId);
  }

  if (runningWorkers.has(cacheId)) {
    return runningWorkers.get(cacheId);
  }

  type Params = [
    h: number,
    w: number,
    values: number[],
    maxLatitude: number,
    minLatitude: number,
    maxLongitude: number,
    minLongitude: number,
    ProjectFeature<Polygon>,
  ];

  const promise = new Promise<{
    minValue: number;
    maxValue: number;
    averageValue: number;
  }>((resolve, reject) => {
    const worker = typedWorker<
      Params,
      [minValue: number, maxValue: number, averageValue: number]
    >(
      new Worker(new URL("./depthAnalysDataWorker.ts", import.meta.url), {
        type: "module",
      }),
    );
    if (signal?.aborted) {
      worker.terminate();
      runningWorkers.delete(cacheId);
      return;
    }

    const args: Params = [
      raster.height,
      raster.width,
      raster.values,
      raster.maxLat,
      raster.maxLat - raster.stepLat * raster.height,
      raster.minLon + raster.stepLon * raster.width,
      raster.minLon,
      feature,
    ];

    signal?.addEventListener("abort", () => {
      worker.terminate();
      runningWorkers.delete(cacheId);
    });

    return promiseWorker(worker, args)
      .then((ret) => {
        worker.terminate();
        const result = {
          minValue: ret[0],
          maxValue: ret[1],
          averageValue: ret[2],
        };

        workerResult.set(cacheId, result);
        resolve(result);
      })
      .catch(reject)
      .finally(() => {
        runningWorkers.delete(cacheId);
      });
  });

  runningWorkers.set(cacheId, promise);
  return promise;
};

export const useGetRasterStats = (
  canvasFeature: ProjectFeature<Polygon>,
  raster: Raster | undefined,
  minLon: number,
  minLat: number,
  maxLon: number,
  maxLat: number,
) => {
  const [minValue, setMinValue] = useState<number | undefined>();
  const [maxValue, setMaxValue] = useState<number | undefined>();
  const [averageValue, setAverageValue] = useState<number | undefined>();

  useEffect(() => {
    if (!raster) return;
    let isSubmitted = false;
    const depthAnalysisWorker = new Worker(
      new URL("./depthAnalysDataWorker.ts", import.meta.url),
      { type: "module" },
    );

    const asyncMethod = async () => {
      const h = raster.height;
      const w = raster.width;

      const [min, max, average] = await new Promise<[number, number, number]>(
        (res, rej) => {
          depthAnalysisWorker.postMessage([
            h,
            w,
            raster.values,
            maxLat,
            minLat,
            maxLon,
            minLon,
            canvasFeature,
          ]);
          depthAnalysisWorker.onmessage = function (e) {
            res(e.data);
          };
          depthAnalysisWorker.onerror = function (e) {
            scream("depthAnalysisWorker.onerror", { e });
            rej(e);
          };
        },
      );
      depthAnalysisWorker.terminate();

      if (isSubmitted) return;

      setMinValue(min);
      setMaxValue(max);
      setAverageValue(average);
    };

    asyncMethod();
    return () => {
      isSubmitted = true;
      setMinValue(undefined);
      setMaxValue(undefined);
      setAverageValue(undefined);
      depthAnalysisWorker.terminate();
    };
  }, [
    canvasFeature,
    setMaxValue,
    setMinValue,
    setAverageValue,
    raster,
    maxLat,
    maxLon,
    minLat,
    minLon,
  ]);

  return { minValue, maxValue, averageValue };
};

export default useGetRasterStats;
