/// <reference types="vite-plugin-svgr/client" />
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { CABLE_CHAIN_POLYGON_PROPERTY_TYPE } from "../../../constants/cabling";
import { useToast } from "../../../hooks/useToast";
import Add from "@icons/24/Add.svg?react";
import {
  cableFeature,
  getCableCorridorsSelectorFamily,
  getCablesSelectorFamily,
  getSubstationsSelectorFamily,
} from "../../../state/cable";
import {
  CableChain,
  cableChainsFeaturesSelectorFamily,
  cableChainsSelectorFamily,
  cablePartitionFeaturesSelectorFamily,
  cablePartitionsSelectorFamily,
} from "../../../state/cableEdit";
import { getDivisionFeaturesSelectorFamily } from "../../../state/division";
import {
  getAnchorsSelectorFamily,
  getMooringLinesSelector,
  getTurbinesSelectorFamily,
} from "../../../state/layout";
import { mapRefAtom } from "../../../state/map";
import { getParkFeatureSelectorFamily } from "../../../state/park";
import { useTypedPath } from "../../../state/pathParams";
import {
  currentSelectedProjectFeatures,
  currentSelectionArrayAtom,
} from "../../../state/selection";
import { allSimpleTurbineTypesSelector } from "../../../state/turbines";
import { SkeletonBlock } from "../../Loading/Skeleton";
import { spaceTiny } from "../../../styles/space";
import {
  CableChainFeature,
  CablePartitionFeature,
  ParkFeature,
  SubstationFeature,
  TurbineFeature,
} from "../../../types/feature";
import {
  featureIsLocked,
  isCableChain,
  isCablePartition,
  isDefined,
} from "../../../utils/predicates";
import { sendWarning } from "../../../utils/sentry";
import { PopupDiv } from "../../DynamicSelectOption/Dynamic.style";
import { getTouchdownPointsInPark } from "../../GenerateFoundationsAndAnchors/state";
import { MenuFrame } from "../../MenuPopup/CloseableMenuPopup";
import { useProjectElementsCrud } from "../../ProjectElements/useProjectElementsCrud";
import { addCableLoads, addCableTypes } from "../CableWalk";
import { cableChainColor } from "../CablingMapController/Render";
import { cw } from "../Worker/cableWorker";
import { computeImportFeaturesJsonString } from "../Worker/utils";
import { KeyTag, MenuPosition, TextWrapper } from "./CableEdit.style";
import {
  cableEditModeAtom,
  filteredCableTypesState,
  generateCablesSettingState,
} from "./state";
import { maxChainLengthFromTypes } from "./utils";
import { RenderCablePartitions } from "components/Mapbox/CablePartition";
import { RenderCableChains } from "components/Mapbox/CableChains";
import { Popup } from "components/Mapbox/Popup";
import { useBathymetryRaster } from "hooks/bathymetry";

const IconWrapper = styled.div<{ showCursor?: boolean }>`
  display: flex;
  svg {
    width: 1.5rem;
    height: 1.5rem;
    ${({ showCursor }) => showCursor && "cursor: pointer"}
  }
`;
const MenuPopup = ({
  feature,
  onClick,
  icon,
}: {
  feature: TurbineFeature | SubstationFeature;
  onClick: () => Promise<void>;
  icon?: JSX.Element;
}) => {
  const map = useRecoilValue(mapRefAtom);
  const [loading, setLoading] = useState<boolean>(false);

  const popupPlacement = {
    lng: feature.geometry.coordinates[0],
    lat: feature.geometry.coordinates[1],
  };

  if (!map) return null;

  return (
    <Popup map={map} pos={popupPlacement}>
      <PopupDiv style={{ padding: spaceTiny }}>
        <IconWrapper
          showCursor={!loading}
          onClick={() => {
            if (loading) return;
            setLoading(true);
            onClick().finally(() => {
              setLoading(false);
            });
          }}
        >
          {loading ? (
            <SkeletonBlock style={{ width: "1.5rem", height: "1.5rem" }} />
          ) : (
            icon
          )}
        </IconWrapper>
      </PopupDiv>
    </Popup>
  );
};

export const useConnectChains = ({ parkId }: { parkId: string }) => {
  const turbineTypes = useRecoilValue(allSimpleTurbineTypesSelector);
  const substations = useRecoilValue(getSubstationsSelectorFamily({ parkId }));
  const turbines = useRecoilValue(getTurbinesSelectorFamily({ parkId }));
  const cableTypes = useRecoilValue(filteredCableTypesState);
  const cables = useRecoilValue(getCablesSelectorFamily({ parkId }));
  const { update: updateFeatures } = useProjectElementsCrud();
  const { error, warning } = useToast();

  return useCallback(
    async (chains: CableChain[]) => {
      const arrays = chains
        .map((c) => {
          if (!substations.find((s) => s.id === c.substation)) return undefined;
          const turbineIds = c.turbines.filter((t) =>
            turbines.some((turbine) => turbine.id === t),
          );
          return {
            turbine_ids: turbineIds,
            substation_id: c.substation,
          };
        })
        .filter(isDefined);
      const newCableData = await cw.connect_arrays(arrays, {});

      if (!newCableData) return;
      if ("error" in newCableData) {
        error(newCableData.error);
        return;
      }

      const touchedTurbines = new Set();
      for (const c of chains)
        for (const t of c.turbines) touchedTurbines.add(t);

      const cableFeatures = newCableData.map((c) =>
        cableFeature(
          {
            fromId: c.from_id,
            toId: c.to_id,
          },
          {
            type: "LineString",
            coordinates: c.coordinates.map(({ x, y }) => [x, y]),
          },
          parkId,
        ),
      );

      const withLoads = addCableLoads(
        cableFeatures,
        substations,
        turbines,
        turbineTypes,
      );
      const newCables = addCableTypes(withLoads, cableTypes);

      if (newCables.some((c) => c.properties.cableTypeId === undefined))
        warning(
          "Selected cables don't have the capacity to support this chain length.",
        );

      const oldCableIds = cables
        .filter((f) => {
          const { fromId, toId } = f.properties;
          const remove =
            touchedTurbines.has(fromId) || touchedTurbines.has(toId);
          return remove;
        })
        .map((f) => f.id);

      updateFeatures({ add: newCables, remove: oldCableIds });
    },
    [
      cableTypes,
      cables,
      error,
      parkId,
      substations,
      turbineTypes,
      turbines,
      updateFeatures,
      warning,
    ],
  );
};

export const useSelectCableEdit = ({ parkId }: { parkId: string }) => {
  const setRawSelection = useSetRecoilState(currentSelectionArrayAtom);
  const selectChainWithTurbine = useRecoilCallback(
    ({ snapshot }) =>
      async (turbineId: string) => {
        const chains = await snapshot.getPromise(
          cableChainsSelectorFamily({ parkId }),
        );
        const chain = chains.find((c) => c.turbines.includes(turbineId));
        if (chain) {
          setRawSelection((curr) => curr.concat([chain.id]));
        }
      },
    [parkId, setRawSelection],
  );

  const selectPartitionWithTurbine = useRecoilCallback(
    ({ snapshot }) =>
      async (turbineId: string) => {
        const partitions = await snapshot.getPromise(
          cablePartitionsSelectorFamily({ parkId }),
        );
        const partition = partitions.find((c) =>
          c.turbines.includes(turbineId),
        );
        if (partition) {
          setRawSelection((curr) => curr.concat([partition.id]));
        }
      },
    [parkId, setRawSelection],
  );

  return { selectChainWithTurbine, selectPartitionWithTurbine };
};

const useCableControls = ({ parkId }: { parkId: string }) => {
  const currentChains = useRecoilValue(cableChainsSelectorFamily({ parkId }));
  const cables = useRecoilValue(getCablesSelectorFamily({ parkId }));
  const substations = useRecoilValue(getSubstationsSelectorFamily({ parkId }));
  const { update: updateFeatures } = useProjectElementsCrud();
  const connect = useConnectChains({ parkId });
  const { selectChainWithTurbine } = useSelectCableEdit({ parkId });
  const { error } = useToast();

  const moveTurbineToPartition = useCallback(
    async (turbine: TurbineFeature, partition: CablePartitionFeature) => {
      const removeCableIds = cables
        .filter(
          (c) =>
            c.properties.fromId === turbine.id ||
            c.properties.toId === turbine.id,
        )
        .map((c) => c.id);
      const substation = substations.find(
        (f) => f.id === partition.properties.substation,
      );
      if (!substation) return;

      let newChains: CableChain[] = [
        {
          type: CABLE_CHAIN_POLYGON_PROPERTY_TYPE,
          parentIds: [parkId],
          id: uuidv4(),
          turbines: [turbine.id],
          substation: substation.id,
          color: cableChainColor,
        },
      ];

      const oldChain = currentChains.find((c) =>
        c.turbines.includes(turbine.id),
      );

      if (oldChain)
        newChains.push({
          ...oldChain,
          turbines: oldChain.turbines.filter((t) => t !== turbine.id),
        });

      await connect(newChains).catch(() => {
        error("Failed to connect turbine to new substation");
      });
      updateFeatures({
        remove: removeCableIds,
      });
      setTimeout(() => selectChainWithTurbine(turbine.id), 50);
    },
    [
      cables,
      connect,
      currentChains,
      error,
      parkId,
      selectChainWithTurbine,
      substations,
      updateFeatures,
    ],
  );

  const moveTurbineToChain = useCallback(
    async (turbine: TurbineFeature, chain: CableChain) => {
      let newChains: CableChain[] = [
        {
          ...chain,
          turbines: chain.turbines.concat([turbine.id]),
        },
      ];

      const oldChain = currentChains.find((c) =>
        c.turbines.includes(turbine.id),
      );
      if (oldChain) {
        const remainingTurbines = oldChain.turbines.filter(
          (t) => t !== turbine.id,
        );
        if (remainingTurbines.length !== 0) {
          newChains.push({
            ...oldChain,
            turbines: remainingTurbines,
          });
        }
      }

      await connect(newChains);
      setTimeout(() => selectChainWithTurbine(turbine.id), 50);
    },
    [connect, currentChains, selectChainWithTurbine],
  );

  const moveChainToSubstation = useCallback(
    async (chain: CableChain, substation: SubstationFeature) => {
      await connect([{ ...chain, substation: substation.id }]);
      setTimeout(() => selectChainWithTurbine(chain.turbines[0]), 50);
    },
    [connect, selectChainWithTurbine],
  );

  return { moveTurbineToChain, moveTurbineToPartition, moveChainToSubstation };
};

export const CablePartitionControls = ({
  partition,
  park,
}: {
  partition: CablePartitionFeature;
  park: ParkFeature;
}) => {
  const { moveTurbineToPartition } = useCableControls({ parkId: park.id });
  const allTurbines = useRecoilValue(
    getTurbinesSelectorFamily({ parkId: park.id }),
  );

  const outsideTurbines = useMemo(
    () =>
      allTurbines.filter((t) => !partition.properties.turbines.includes(t.id)),
    [allTurbines, partition.properties.turbines],
  );

  return (
    <>
      {outsideTurbines.map((t) => (
        <MenuPopup
          key={t.id}
          feature={t}
          onClick={() => moveTurbineToPartition(t, partition)}
          icon={<Add />}
        />
      ))}
    </>
  );
};

export const CableChainsControls = ({
  chain,
  park,
}: {
  chain: CableChainFeature;
  park: ParkFeature;
}) => {
  const { moveTurbineToChain, moveChainToSubstation } = useCableControls({
    parkId: park.id,
  });
  const allTurbines = useRecoilValue(
    getTurbinesSelectorFamily({ parkId: park.id }),
  );
  const substations = useRecoilValue(
    getSubstationsSelectorFamily({ parkId: park.id }),
  );

  const outsideTurbines = useMemo(
    () => allTurbines.filter((t) => !chain.properties.turbines.includes(t.id)),
    [allTurbines, chain.properties.turbines],
  );

  const _moveTurbineToChain = useCallback(
    (t: TurbineFeature) => () => moveTurbineToChain(t, chain.properties),
    [chain, moveTurbineToChain],
  );

  return (
    <>
      {outsideTurbines.map((t) => (
        <MenuPopup
          key={t.id}
          feature={t}
          onClick={_moveTurbineToChain(t)}
          icon={<Add />}
        />
      ))}
      {substations
        .filter((s) => s.id !== chain.properties.substation)
        .map((s) => (
          <MenuPopup
            key={s.id}
            feature={s}
            onClick={() => moveChainToSubstation(chain.properties, s)}
            icon={<Add />}
          />
        ))}
    </>
  );
};

export const useRecomputeChains = ({ parkId }: { parkId: string }) => {
  const substations = useRecoilValue(getSubstationsSelectorFamily({ parkId }));
  const connect = useConnectChains({ parkId });
  const turbines = useRecoilValue(getTurbinesSelectorFamily({ parkId }));
  const allCableTypes = useRecoilValue(filteredCableTypesState);
  const allTurbineTypes = useRecoilValue(allSimpleTurbineTypesSelector);
  const { error } = useToast();

  const recompute = useCallback(
    async (partition: CablePartitionFeature) => {
      const subInd = substations.findIndex(
        (f) => f.id === partition.properties.substation,
      );
      if (subInd === undefined)
        return sendWarning("No substation index found", {
          partition,
          substations,
        });

      const usedTurbineTypes = turbines
        .map((t) =>
          allTurbineTypes.find((tt) => tt.id === t.properties.turbineTypeId),
        )
        .filter(isDefined);

      const maxChainLength = maxChainLengthFromTypes(
        usedTurbineTypes,
        allCableTypes,
      );

      const arrays = await cw.split_into_arrays(
        [
          {
            substation_id: partition.properties.substation,
            turbine_ids: partition.properties.turbines,
          },
        ],
        { max_chain_length: maxChainLength },
      );

      if ("error" in arrays) {
        error(arrays.error);
        return;
      }

      let chains: CableChain[] = [];
      for (const [subId, turbineIds] of arrays) {
        const id = uuidv4();
        chains.push({
          id,
          parentIds: [parkId],
          turbines: turbineIds,
          substation: subId,
          type: CABLE_CHAIN_POLYGON_PROPERTY_TYPE,
          color: cableChainColor,
        });
      }
      connect(chains);
    },
    [
      allCableTypes,
      allTurbineTypes,
      connect,
      error,
      parkId,
      substations,
      turbines,
    ],
  );

  return recompute;
};

export const CableEdit = ({ parkId }: { parkId: string }) => {
  const map = useRecoilValue(mapRefAtom);
  const { projectId, branchId } = useTypedPath("projectId", "branchId");
  const park = useRecoilValue(getParkFeatureSelectorFamily({ parkId }));
  const setCurrentSelectionArray = useSetRecoilState(currentSelectionArrayAtom);
  const selectedProjectFeatures = useRecoilValue(
    currentSelectedProjectFeatures,
  );

  const cablePartitionFeatures = useRecoilValue(
    cablePartitionFeaturesSelectorFamily({ parkId }),
  );
  const cableChainFeatures = useRecoilValue(
    cableChainsFeaturesSelectorFamily({ parkId }),
  );

  const singlePartition =
    selectedProjectFeatures.length === 1 &&
    isCablePartition(selectedProjectFeatures[0])
      ? selectedProjectFeatures[0]
      : undefined;
  const singleChain =
    selectedProjectFeatures.length === 1 &&
    isCableChain(selectedProjectFeatures[0])
      ? selectedProjectFeatures[0]
      : undefined;

  const setCableEditMode = useSetRecoilState(cableEditModeAtom);

  const turbines = useRecoilValue(getTurbinesSelectorFamily({ parkId }));
  const { subAreas, exclusionZones } = useRecoilValue(
    getDivisionFeaturesSelectorFamily({ parkId }),
  );
  const substations = useRecoilValue(getSubstationsSelectorFamily({ parkId }));
  const cableCorridors = useRecoilValue(
    getCableCorridorsSelectorFamily({ parkId, branchId }),
  );
  const allCables = useRecoilValue(getCablesSelectorFamily({ parkId }));
  const anchors = useRecoilValue(getAnchorsSelectorFamily(parkId));
  const mooringLines = useRecoilValue(getMooringLinesSelector(parkId));
  const raster = useBathymetryRaster({
    projectId,
    featureId: parkId,
  }).valueMaybe();

  const waterDepths: undefined | Record<string, number> = useMemo(() => {
    if (!raster) return undefined;
    let anyUndef = false;
    const entries = mooringLines.map((l) => {
      const turbine = turbines.find((a) => a.id === l.properties.target);
      if (!turbine) {
        anyUndef = true;
        return [l.id, 0.0];
      }
      const depth = -raster.latLngToValue(
        turbine.geometry.coordinates[0],
        turbine.geometry.coordinates[1],
      );
      return [l.id, depth];
    });

    if (anyUndef) return undefined;
    return Object.fromEntries(entries);
  }, [turbines, mooringLines, raster]);

  const touchdownPoints = useRecoilValue(
    getTouchdownPointsInPark({
      parkId,
      waterDepths: waterDepths ?? {},
    }),
  );

  const settings = useRecoilValue(generateCablesSettingState);
  const hasLockedCables = useMemo(
    () => allCables.some(featureIsLocked),
    [allCables],
  );

  useEffect(() => {
    if (!park) return;
    const str = computeImportFeaturesJsonString({
      turbines,
      substations,
      exclusionZones,
      subAreas,
      mooringLines,
      anchors,
      touchdownPoints,
      park,
      cableCorridors,
      settings,
    });
    cw.newContext(str);
  }, [
    anchors,
    cableCorridors,
    exclusionZones,
    subAreas,
    mooringLines,
    park,
    settings,
    substations,
    touchdownPoints,
    turbines,
  ]);

  const exit = useCallback(() => {
    setCableEditMode(false);
    setCurrentSelectionArray([]);
  }, [setCableEditMode, setCurrentSelectionArray]);

  useEffect(() => {
    if (
      hasLockedCables &&
      !window.confirm(
        "The park has locked cables. The cable editor might unlock and change these cables.",
      )
    ) {
      exit();
    }
  }, [hasLockedCables, exit]);

  useEffect(() => {
    const keyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape" || e.key === "Enter") {
        e.stopPropagation();
        e.preventDefault();
        exit();
      }
    };
    document.addEventListener("keydown", keyDown);
    return () => {
      document.removeEventListener("keydown", keyDown);
    };
  }, [exit]);

  if (!map) return null;

  return (
    <>
      {singlePartition && park && (
        <CablePartitionControls partition={singlePartition} park={park} />
      )}
      {singleChain && park && (
        <CableChainsControls chain={singleChain} park={park} />
      )}
      <RenderCablePartitions map={map} partitions={cablePartitionFeatures} />
      <RenderCableChains map={map} chains={cableChainFeatures} />

      <MenuPosition>
        <MenuFrame
          style={{ minWidth: "10rem", maxWidth: "30rem", marginBottom: "5rem" }}
          title="Cable edit mode"
          onExit={() => exit()}
        >
          <TextWrapper>
            Exit with <KeyTag>Escape</KeyTag> or <KeyTag>Enter</KeyTag>.
          </TextWrapper>
        </MenuFrame>
      </MenuPosition>
    </>
  );
};
