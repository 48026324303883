/// <reference types="vite-plugin-svgr/client" />
import styled from "styled-components";
import { Suspense, useCallback, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { ExportCableFeature } from "../../types/feature";
import ArrowDownIcon from "@icons/24/ArrowDown.svg?react";
import ArrowRightIcon from "@icons/24/ArrowRight.svg?react";
import Tooltip from "../General/Tooltip";
import { isDefined } from "../../utils/predicates";
import { useProjectElementsCrud } from "../ProjectElements/useProjectElementsCrud";
import { trackCanvasOption } from "./MenuTracking";
import { projectIdSelector } from "../../state/pathParams";
import { Divider } from "components/General/Icons";
import { MenuItem } from "../General/Menu";
import { MenuButton } from "../General/MenuButton";
import {
  NamedTooltipContainer,
  NamedTooltipWrapper,
} from "./CanvasSelectOption.style";
import { dedup } from "utils/utils";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { currentExportCableTypesSelector } from "components/Cabling/Generate/state";

const TypeSelectorWrapper = styled.div`
  position: relative;
  font-size: 1.6rem;
  font-weight: 500;
`;

const Inner = ({
  exportCables,
  editable,
  onSelect,
  field,
}: {
  exportCables: ExportCableFeature[];
  editable: boolean;
  onSelect: (val: string) => void;
  field: "cableTypeId" | "onshoreCableTypeId";
}) => {
  const allTypes = useRecoilValue(currentExportCableTypesSelector);
  const typeIds = dedup(
    exportCables.map((s) => s.properties[field]).filter(isDefined),
  );
  const items = useMemo(
    () =>
      allTypes.map((t) => ({ value: t.id, name: t.name, voltage: t.voltage })),
    [allTypes],
  );

  const currentChoice =
    typeIds.length === 1
      ? allTypes.find((t) => t.id === typeIds[0])?.name ?? "..."
      : "...";

  return (
    <TypeSelectorWrapper>
      <MenuButton
        disabled={!editable}
        icon={<ArrowRightIcon />}
        iconOpen={<ArrowDownIcon />}
        buttonStyle={{
          border: "none",
          flexDirection: "row-reverse",
          minWidth: "13rem",
          height: "fit-content",
          justifyContent: "space-between",
          padding: 0,
        }}
        buttonType="dropdown"
        buttonText={currentChoice}
      >
        {items.map((item) => (
          <MenuItem
            key={item.value}
            name={`${item.name} (${item.voltage}kV)`}
            onClick={() => {
              onSelect(item.value);
            }}
          />
        ))}
      </MenuButton>
    </TypeSelectorWrapper>
  );
};

export function ExportCableTypeSelector({
  editable,
  cables,
}: {
  editable: boolean;
  cables: ExportCableFeature[];
}) {
  const [_refresh, refresh] = useState(0); // Selecting a dropdown needs to refresh the tooltip, otherwise it doesn't disappear.

  const projectId = useRecoilValue(projectIdSelector);
  const { update: updateFeatures } = useProjectElementsCrud();
  const onSelect = useCallback(
    (key: string) => (val: string) => {
      refresh((c) => c + 1);
      trackCanvasOption("change-export-cable-type", {
        projectId,
      });
      const update = cables.map((c) => ({
        ...c,
        properties: { ...c.properties, [key]: val },
      }));
      updateFeatures({ update });
    },
    [cables, projectId, updateFeatures],
  );

  if (cables.length === 0) return null;

  return (
    <>
      <Tooltip position="top" text="Change type" readonlyAware key={_refresh}>
        <NamedTooltipContainer>
          <NamedTooltipWrapper>Offshore cable type</NamedTooltipWrapper>
          <Suspense
            fallback={
              <SkeletonBlock style={{ height: "1rem", width: "7rem" }} />
            }
          >
            <Inner
              exportCables={cables}
              editable={editable}
              onSelect={onSelect("cableTypeId")}
              field="cableTypeId"
            />
          </Suspense>
        </NamedTooltipContainer>
      </Tooltip>
      <Divider />
      <Tooltip
        position="top"
        text="Change type"
        readonlyAware
        key={_refresh + 1}
      >
        <NamedTooltipContainer>
          <NamedTooltipWrapper>Onshore cable type</NamedTooltipWrapper>
          <Suspense
            fallback={
              <SkeletonBlock style={{ height: "1rem", width: "7rem" }} />
            }
          >
            <Inner
              exportCables={cables}
              editable={editable}
              onSelect={onSelect("onshoreCableTypeId")}
              field="onshoreCableTypeId"
            />
          </Suspense>
        </NamedTooltipContainer>
      </Tooltip>
      <Divider />
    </>
  );
}
