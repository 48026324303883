import { useProjectTurbineCrud } from "components/ConfigurationModal/TurbineSettings/useNodeTurbineCrud";
import { useRefreshProjectTurbines } from "components/ConfigurationModal/TurbineSettings/useRefreshCustomTurbines";
import { useRefreshProjectFoundation } from "components/ConfigurationModal/useRefreshCustomFoundations";
import { selectedMenuItemState } from "components/SettingsV2/Shared/state";
import { useToast } from "hooks/useToast";
import { v4 as uuid } from "uuid";
import { useState } from "react";
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import {
  createNewProjectFoundation,
  getAdvancedOrgTurbine,
} from "services/turbineAPIService";
import { projectIdSelector, useTypedPath } from "state/pathParams";
import { FoundationType } from "types/foundations";
import { scream } from "utils/sentry";
import { FOUNDATION_MENU_ID } from "./useFoundationSettings";
import {
  Configuration,
  createConfigurationWithValues,
} from "services/configurationService";
import { configurationsAtomFamily } from "state/configuration";
import { CableType, createProjectCableType } from "services/cableTypeService";
import { CABLE_MENU_ID } from "./useCableSettings";
import { savingConfigurationInProgressAtom } from "state/configuration";
import { cableTypeAtomFamily } from "state/cableType";
import { ANALYSIS_MENU_ID } from "components/SettingsV2/ProjectConfiguration/Data/useAnalysisConfiguration";
import {
  CostConfiguration,
  _CostConfigurationInput,
  createConfiguration,
} from "services/costService";
import { COST_MENU_ID } from "components/SettingsV2/ProjectConfiguration/Data/useCostConfiguration";
import { costConfigurationsAtomFamily } from "state/costConfigurations";

export function useDuplicateTurbineToProject() {
  const { organisationId } = useTypedPath("organisationId");
  const { error } = useToast();
  const { create } = useProjectTurbineCrud();
  const refreshProjectTurbines = useRefreshProjectTurbines();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const duplicateToProject = useRecoilCallback(
    () => async (turbineId: string) => {
      setIsLoading(true);
      try {
        const turbine = await getAdvancedOrgTurbine(
          organisationId,
          turbineId,
          undefined,
        );
        await create({
          turbine: turbine,
          name: `${turbine.name} (duplicate)`,
        });
        refreshProjectTurbines();
      } catch (e) {
        scream("Error when duplicating library turbine to project", { e });
        error(
          "Something went wrong while trying to duplicate library turbine to project, we have been notified",
        );
      } finally {
        setIsLoading(false);
      }
    },
    [organisationId, create, refreshProjectTurbines, error],
  );

  return { duplicateToProject, isLoading };
}

export function useDuplicateFoundationToProject() {
  const projectId = useRecoilValue(projectIdSelector);
  const { error } = useToast();
  const refreshProjectFoundations = useRefreshProjectFoundation();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const duplicateToProject = useRecoilCallback(
    ({ set }) =>
      async (foundation: FoundationType) => {
        if (!projectId) return;
        setIsLoading(true);
        try {
          const newFoundation = {
            ...foundation,
            name: `${foundation.name} (duplicate)`,
          };
          const res = await createNewProjectFoundation(
            projectId,
            newFoundation,
          );
          refreshProjectFoundations();
          if (res) {
            set(
              selectedMenuItemState({ menuId: FOUNDATION_MENU_ID, projectId }),
              res.id,
            );
          }
          return res;
        } catch (e) {
          scream("Error when duplicating library foundation to project", { e });
          error(
            "Something went wrong while trying to duplicate library foundation to project, we have been notified",
          );
        } finally {
          setIsLoading(false);
        }
      },
    [refreshProjectFoundations, error, projectId],
  );

  return { duplicateToProject, isLoading };
}

export function useDuplicateCableToProject() {
  const projectId = useRecoilValue(projectIdSelector);
  const { error } = useToast();

  const setIsAutoSaving = useSetRecoilState(savingConfigurationInProgressAtom);
  const setCurrentCableTypes = useSetRecoilState(
    cableTypeAtomFamily({ projectId }),
  );

  const duplicateToProject = useRecoilCallback(
    ({ set }) =>
      async (cable: CableType) => {
        if (!projectId) return;
        setIsAutoSaving(true);
        try {
          const newCable = {
            ...cable,
            name: `${cable.name} (duplicate)`,
          };
          const res = await createProjectCableType(projectId, newCable);
          if (res) {
            setCurrentCableTypes((cur) => [...cur, res]);
            set(
              selectedMenuItemState({ menuId: CABLE_MENU_ID, projectId }),
              res.id,
            );
          }
          return res;
        } catch (e) {
          scream("Error when duplicating library cable to project", { e });
          error(
            "Something went wrong while trying to duplicate library cable to project, we have been notified",
          );
        } finally {
          setIsAutoSaving(false);
        }
      },
    [error, projectId, setIsAutoSaving, setCurrentCableTypes],
  );

  return { duplicateToProject };
}

export function useDuplicateAnalysisToProject() {
  const projectId = useRecoilValue(projectIdSelector);
  const { error } = useToast();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const duplicateToProject = useRecoilCallback(
    ({ set }) =>
      async (analysis: Configuration) => {
        if (!projectId) return;
        setIsLoading(true);
        try {
          const newAnalysis = {
            ...analysis,
            name: `${analysis.name} (duplicate)`,
          };

          const res = await createConfigurationWithValues(
            projectId,
            newAnalysis,
          );
          set(configurationsAtomFamily({ nodeId: projectId }), (curr) => [
            ...curr,
            res,
          ]);
          if (res) {
            set(
              selectedMenuItemState({ menuId: ANALYSIS_MENU_ID, projectId }),
              res.id,
            );
          }
          return res;
        } catch (e) {
          scream("Error when duplicating library analysis to project", { e });
          error(
            "Something went wrong while trying to duplicate library analysis to project, we have been notified",
          );
        } finally {
          setIsLoading(false);
        }
      },
    [error, projectId],
  );

  return { duplicateToProject, isLoading };
}

export function useDuplicateFinancialConfigToProject() {
  const projectId = useRecoilValue(projectIdSelector);
  const { error } = useToast();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const duplicateToProject = useRecoilCallback(
    ({ set }) =>
      async (config: CostConfiguration) => {
        if (!projectId) return;
        setIsLoading(true);
        try {
          const clonedConfig = _CostConfigurationInput.parse({
            ...config,
            name: `${config.name} copy`,
            capex: {
              ...config.capex,
              custom: config.capex.custom.map((entry) => ({
                ...entry,
                id: uuid(),
              })),
            },
            opex: {
              ...config.opex,
              custom: config.opex.custom.map((entry) => ({
                ...entry,
                id: uuid(),
              })),
            },
          });

          const res = await createConfiguration(projectId, clonedConfig);
          set(costConfigurationsAtomFamily({ projectId }), (curr) => [
            ...curr,
            res,
          ]);
          if (res) {
            set(
              selectedMenuItemState({ menuId: COST_MENU_ID, projectId }),
              res.id,
            );
          }
          return res;
        } catch (e) {
          scream("Error when duplicating library cost config to project", {
            e,
          });
          error(
            "Something went wrong while trying to duplicate library cost config to project, we have been notified",
          );
        } finally {
          setIsLoading(false);
        }
      },
    [error, projectId],
  );

  return { duplicateToProject, isLoading };
}
