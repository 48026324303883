import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { measureWavePointAtom, wavePointsSource } from "../../state/map";
import { MenuFrame } from "../MenuPopup/CloseableMenuPopup";
import { Column } from "../General/Layout";
import WavePoints from "../../layers/waveMeasurements";
import { Label } from "components/General/Form";
import Dropdown from "components/Dropdown/Dropdown";
import { WaveDataSource } from "state/waveStatistics";
import { InputTitle } from "components/General/GeneralSideModals.style";

// Lazy load to prevent plotly being in main.js
const WaveStatistics = React.lazy(
  () => import("../WindWaveMeasurementInfoModal/WaveStatistics"),
);

export const WaveSources = ({ onClose }: { onClose(): void }) => {
  const measureWavePoint = useRecoilValue(measureWavePointAtom);
  const [selectedSource, setSelectedSource] = useRecoilState(wavePointsSource);
  return (
    <MenuFrame title={"Wave sampler"} onExit={onClose}>
      <Column style={{ maxHeight: "calc(100vh - 30rem)", overflow: "auto" }}>
        <Label>
          <InputTitle>Source</InputTitle>
          <Dropdown
            small
            id="wave-data-source"
            value={selectedSource}
            onChange={(e: any) => {
              setSelectedSource(e.target.value);
            }}
          >
            <option key={"era5"} value={WaveDataSource.ERA5}>
              ERA5
            </option>
            <option key={"nora3"} value={WaveDataSource.NORA3}>
              NORA3
            </option>
          </Dropdown>
        </Label>
        {measureWavePoint && (
          <React.Suspense fallback={null}>
            <WaveStatistics
              source={selectedSource}
              lon={measureWavePoint.position[0]}
              lat={measureWavePoint.position[1]}
            />
          </React.Suspense>
        )}
        <WavePoints />
        <p>Zoom in to see more data locations</p>
      </Column>
    </MenuFrame>
  );
};
