import { wmsGetCapabilitiesSuffix } from "../../state/wms";
import {
  getAllowedValues,
  supportedOutputFormats,
  wfsGetCapabilitiesSuffix,
} from "../../state/wfs";
import { appendQueryParamsSign } from "../../utils/utils";
import { fetchWithToken } from "../../services/utils";
import { addCorsAndCacheProxyURL } from "../../state/gisSourceCorsProxy";

const trimUrl = (url: string): string => {
  const split = url.split("?");
  const base = split[0];
  if (split.length === 1) {
    return base;
  }
  const params = split[1].split("&");
  const paramsToKeep = params.filter(
    (p) =>
      !["service", "request", "version", ""].includes(
        p.split("=")[0].toLowerCase(),
      ),
  );

  return paramsToKeep.length > 0 ? base + "?" + paramsToKeep.join("&") : base;
};

export const formatSourceUrl = (sourceUrl: string): string => {
  return trimUrl(sourceUrl)
    .replace(/\s/g, "+") // Replace spaces with +
    .replace(/\/+$/, ""); // Remove trailing slash
};

export const checkArcgisRestApiIsValid = async (
  url: string,
): Promise<boolean> => {
  const res = await fetchWithToken(addCorsAndCacheProxyURL(url, true), {
    method: "get",
  });

  if (!res.ok) return false;

  const htmlText = await res.text();

  const lowerCaseHtml = htmlText.toLowerCase();

  return (
    lowerCaseHtml.includes("<html") &&
    htmlText.includes("ArcGIS REST Services Directory")
  );
};

export const checkWFSIsValid = async (url: string): Promise<boolean> => {
  const separator = appendQueryParamsSign(url);
  const getCapabilitiesUrl =
    addCorsAndCacheProxyURL(url, true) + separator + wfsGetCapabilitiesSuffix;
  const res = await fetchWithToken(getCapabilitiesUrl, { method: "get" });

  if (!res.ok) return false;

  const xmlText = await res.text();

  if (!xmlText.toLowerCase().includes("wfs_capabilities")) return false;

  try {
    const parser = new DOMParser();
    const parsedDom = parser.parseFromString(xmlText, "text/xml");

    const allowedValues = getAllowedValues(parsedDom);
    const hasAllowedValues = allowedValues.some((v) =>
      supportedOutputFormats.includes(v),
    );
    return hasAllowedValues;
  } catch (e) {
    return false;
  }
};

export const checkIsValidWMS = async (url: string): Promise<boolean> => {
  const separator = appendQueryParamsSign(url);
  const getCapabilitiesUrl =
    addCorsAndCacheProxyURL(url, true) + separator + wmsGetCapabilitiesSuffix;

  const res = await fetchWithToken(getCapabilitiesUrl, { method: "get" });

  if (!res.ok) return false;

  const xmlText = await res.text();

  if (!xmlText.toLowerCase().includes("<wms_capabilities")) return false;

  try {
    const parser = new DOMParser();
    parser.parseFromString(xmlText, "text/xml");
  } catch (e) {
    return false;
  }

  return true;
};

export const wmtsIsResourceOriented = (url: string) =>
  new URL(url).pathname.includes("WMTSCapabilities.xml");

export const checkIsValidWMTS = async (url: string): Promise<boolean> => {
  const parsedUrl = new URL(url);
  const cleanUrl = `${parsedUrl.origin}${parsedUrl.pathname}`;
  const resourceOriented = wmtsIsResourceOriented(url);
  const getCapabilitiesUrl = addCorsAndCacheProxyURL(
    resourceOriented
      ? cleanUrl
      : `${cleanUrl}?service=WMTS&request=GetCapabilities`,
    true,
  );
  const res = await fetchWithToken(getCapabilitiesUrl, { method: "get" });

  if (!res.ok) return false;

  const xmlText = await res.text();

  if (!xmlText.toLowerCase().includes("ogc wmts")) return false;

  try {
    const parser = new DOMParser();
    parser.parseFromString(xmlText, "text/xml");
  } catch (e) {
    return false;
  }

  return true;
};

export const sourceEndpointUrlAlreadyExists = (
  sourceEndpointUrls: string[],
  url: string,
): boolean => sourceEndpointUrls.some((existingUrl) => existingUrl === url);
