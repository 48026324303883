import { selectorFamily } from "recoil";
import { getTurbinesWithinDivisionAndBranchSelectorFamily } from "../../../../../state/division";
import { currentSelectedProjectFeatures } from "../../../../../state/selection";
import { allSimpleTurbineTypesSelector } from "../../../../../state/turbines";
import groupBy from "../../../../../utils/groupBy";
import { isSubArea } from "../../../../../utils/predicates";
import {
  allFixedTypesSelector,
  isDetailedMonopileSelector,
  isJacketSelector,
  isMonopileSelector,
} from "../../../../../state/foundations";
import { TurbineFeature } from "../../../../../types/feature";
import { isDetailedMonopile, isJacket, isMonopile } from "../utils";
import Mexp from "math-expression-evaluator";
import {
  PILE_DENSITY,
  scalingEquationTokens,
} from "../../../../../constants/foundations";
import { FixedTotalMaterial } from "./FixedFoundationGeneration";
import { bathymetryAtomFamily } from "../../../../../state/bathymetry";
import { monopileNaturalFrequency } from "functions/monopileSizing";
import * as Sentry from "@sentry/browser";

export type MonopileDesign = {
  turbineId: string;
  turbineTypeId: string;
  pileMass: number;
  pileDiameter: number;
  avgPileThickness: number;
  totalPileLength: number;
  embedLength: number;
  natFreq: number;
};

export const getAverageTurbineHeightSelectorFamily = selectorFamily<
  number,
  { parkId: string; branchId: string }
>({
  key: "getAverageTurbineHeightSelectorFamily",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const selectedSubAreaIds = get(currentSelectedProjectFeatures)
        .filter(isSubArea)
        .map((f) => f.id);

      const turbines = get(
        getTurbinesWithinDivisionAndBranchSelectorFamily({
          parkId,
          branchId,
          selectedSubAreaIds,
        }),
      );

      const allTurbineTypes = get(allSimpleTurbineTypesSelector);
      const turbineTypesById = groupBy(allTurbineTypes, (t) => t.id);

      const averageTurbineHeight =
        turbines && turbines.length > 0
          ? turbines
              ?.map(
                ({ properties: { turbineTypeId } }) =>
                  turbineTypesById[turbineTypeId][0].hubHeight,
              )
              ?.reduce((acc, hubHeight) => acc + hubHeight, 0) /
            turbines?.length
          : 100;

      return averageTurbineHeight;
    },
});

export const getSimpleFixedMassesSelectorFamily = selectorFamily<
  {
    foundationMasses: {
      turbineId: string;
      turbineTypeId: string;
      foundationId: string;
      mass: number;
    }[];
    totalFoundationMass: number;
  },
  //@ts-ignore
  {
    turbinesWithSimpleFixed: TurbineFeature[];
    rasterId: string;
  }
>({
  key: "getSimpleFixedMassesSelectorFamily",
  get:
    ({ turbinesWithSimpleFixed, rasterId }) =>
    ({ get }) => {
      const rasterResult = get(bathymetryAtomFamily(rasterId));
      if (rasterResult.status === "failed")
        throw new Error(
          "Cannot compute bottom fixed masses without bathymetry",
        );

      const allTurbineTypes = get(allSimpleTurbineTypesSelector);
      const allFixed = get(allFixedTypesSelector);

      const foundationMasses = turbinesWithSimpleFixed.map((t) => {
        const turbineType = allTurbineTypes.find(
          (type) => type.id === t.properties.turbineTypeId,
        );
        const ratedPower = turbineType?.ratedPower ?? 0;
        const rotorDiameter = turbineType?.diameter ?? 0;

        Sentry.addBreadcrumb({
          category: "debug",
          message: `raster`,
          data: {
            coords: [t.geometry.coordinates[0], t.geometry.coordinates[1]],
            rasterId,
            rasterStatus: rasterResult.status,
            rasterUrl: JSON.stringify(rasterResult.url),
          },
          level: "info",
        });

        const depth = rasterResult
          ? -rasterResult.raster.latLngToValue(
              t.geometry.coordinates[0],
              t.geometry.coordinates[1],
            )
          : 0;

        const foundation = allFixed.find(
          (f) => f.id === t.properties.foundationId,
        );

        if (!isJacket(foundation) && !isMonopile(foundation))
          return {
            turbineId: t.id,
            turbineTypeId: t.properties.turbineTypeId,
            foundationId: t.properties.foundationId ?? "",
            mass: 0,
          };

        const tokenValues = {
          p: ratedPower / 1000,
          w: depth,
          d: rotorDiameter,
        };
        var mexp = new Mexp();
        const massKg =
          1000 *
          mexp.eval(
            foundation.scalingEquation,
            scalingEquationTokens,
            tokenValues,
          );

        return {
          turbineId: t.id,
          turbineTypeId: t.properties.turbineTypeId,
          foundationId: foundation.id,
          mass: massKg,
        };
      });

      const totalFoundationMass = foundationMasses.reduce(
        (acc, { mass }) => acc + mass,
        0,
      );

      return {
        totalFoundationMass,
        foundationMasses,
      };
    },
});

export const getDetailedMonopileMassesSelectorFamily = selectorFamily<
  {
    foundationDetails: MonopileDesign[];
    totalFoundationMass: number;
  },
  //@ts-ignore
  {
    turbinesWithDetailedMonopile: TurbineFeature[];
    rasterId: string;
  }
>({
  key: "getDetailedMonopileMassesSelectorFamily",
  get:
    ({ turbinesWithDetailedMonopile, rasterId }) =>
    ({ get }) => {
      const rasterResult = get(bathymetryAtomFamily(rasterId));
      if (rasterResult.status === "failed")
        throw new Error(
          "Cannot compute bottom fixed masses without bathymetry",
        );

      const allTurbineTypes = get(allSimpleTurbineTypesSelector);
      const allFixed = get(allFixedTypesSelector);

      const foundationDetails = turbinesWithDetailedMonopile.map((t) => {
        const turbineDepth = rasterResult
          ? -rasterResult.raster.latLngToValue(
              t.geometry.coordinates[0],
              t.geometry.coordinates[1],
            )
          : 0;

        const turbineType = allTurbineTypes.find(
          (type) => type.id === t.properties.turbineTypeId,
        );

        const foundation = allFixed.find(
          (f) => f.id === t.properties.foundationId,
        );

        if (!isDetailedMonopile(foundation) || !turbineType)
          return {
            turbineId: t.id,
            turbineTypeId: t.properties.turbineTypeId,
            foundationId: t.properties.foundationId ?? "",
            pileMass: 0,
            pileDiameter: 0,
            avgPileThickness: 0,
            embedLength: 0,
            totalPileLength: 0,
            natFreq: 0,
          };

        const {
          embedLength,
          totalPileLength,
          pileDiameter,
          avgPileThickness,
          waterDepth: designWaterDepth,
          soilCoeffSubReact,
        } = foundation;

        const maxPileMass =
          (Math.PI / 4) *
          (Math.pow(pileDiameter, 2) -
            Math.pow(pileDiameter - 2 * avgPileThickness, 2)) *
          totalPileLength *
          PILE_DENSITY;

        const depthDifference = designWaterDepth - turbineDepth;

        const scaleRatio =
          (totalPileLength - depthDifference) / totalPileLength;

        const scaledMass = maxPileMass * scaleRatio;
        const scaledLength = totalPileLength - depthDifference;

        const natFreq = monopileNaturalFrequency({
          pileDiameter,
          avgPileThickness,
          embedLength,
          totalPileLength: scaledLength,
          waterDepth: turbineDepth,
          soilCoeffSubReact,
          turbineType,
        });

        return {
          turbineId: t.id,
          turbineTypeId: t.properties.turbineTypeId,
          foundationId: foundation.id,
          pileMass: scaledMass,
          pileDiameter,
          avgPileThickness,
          embedLength,
          totalPileLength: scaledLength,
          natFreq,
        };
      });

      const totalFoundationMass = foundationDetails.reduce(
        (acc, { pileMass }) => acc + pileMass,
        0,
      );

      return {
        totalFoundationMass,
        foundationDetails,
      };
    },
});

export const getJacketTotalsSelectorFamily = selectorFamily<
  FixedTotalMaterial & {
    foundationMasses: {
      turbineId: string;
      turbineTypeId: string;
      foundationId: string;
      mass: number;
    }[];
    foundationDetails: MonopileDesign[];
  },
  //@ts-ignore
  {
    parkId: string;
    tempLayoutFoundations: TurbineFeature[];
    rasterId: string;
  }
>({
  key: "getJacketTotalsSelectorFamily",
  get:
    ({ tempLayoutFoundations, rasterId }) =>
    ({ get }) => {
      const isJacket = get(isJacketSelector);

      const turbinesWithJacket = tempLayoutFoundations.filter((t) =>
        isJacket(t.properties.foundationId ?? ""),
      );

      const { totalFoundationMass: totalSimpleFixedMass, foundationMasses } =
        get(
          getSimpleFixedMassesSelectorFamily({
            turbinesWithSimpleFixed: turbinesWithJacket,
            rasterId,
          }),
        );

      return {
        totalPrimarySteelMass: totalSimpleFixedMass,
        foundationMasses,
      };
    },
});

export const getMonopileTotalsSelectorFamily = selectorFamily<
  FixedTotalMaterial & {
    foundationMasses: {
      turbineId: string;
      turbineTypeId: string;
      foundationId: string;
      mass: number;
    }[];
    foundationDetails: MonopileDesign[];
  },
  //@ts-ignore
  {
    parkId: string;
    tempLayoutFoundations: TurbineFeature[];
    rasterId: string;
  }
>({
  key: "getMonopileTotalsSelectorFamily",
  get:
    ({ tempLayoutFoundations, rasterId }) =>
    ({ get }) => {
      const isMonopile = get(isMonopileSelector);
      const isDetailedMonopile = get(isDetailedMonopileSelector);
      const turbinesWithDetailedMonopile = tempLayoutFoundations.filter((t) =>
        isDetailedMonopile(t.properties.foundationId ?? ""),
      );
      const turbinesWithSimpleMonopile = tempLayoutFoundations.filter((t) =>
        isMonopile(t.properties.foundationId ?? ""),
      );

      const { totalFoundationMass: totalSimpleFixedMass, foundationMasses } =
        get(
          getSimpleFixedMassesSelectorFamily({
            turbinesWithSimpleFixed: turbinesWithSimpleMonopile,
            rasterId,
          }),
        );

      const {
        totalFoundationMass: totalDetailedMonopileMass,
        foundationDetails,
      } = get(
        getDetailedMonopileMassesSelectorFamily({
          turbinesWithDetailedMonopile,
          rasterId,
        }),
      );

      return {
        totalPrimarySteelMass: totalDetailedMonopileMass + totalSimpleFixedMass,
        foundationMasses,
        foundationDetails,
      };
    },
});

export const getFixedFoundationTotalsSelectorFamily = selectorFamily<
  // TODO: should we replace this? Or simplify it? It is used to state total weight on foundations
  FixedTotalMaterial & {
    foundationMasses: {
      turbineId: string;
      turbineTypeId: string;
      foundationId: string;
      mass: number;
    }[];
    foundationDetails: MonopileDesign[];
  },
  //@ts-ignore
  {
    parkId: string;
    tempLayoutFoundations: TurbineFeature[];
    rasterId: string;
  }
>({
  key: "getFixedFoundationTotalsSelectorFamily",
  get:
    ({ tempLayoutFoundations, rasterId }) =>
    ({ get }) => {
      const isMonopile = get(isMonopileSelector);
      const isJacket = get(isJacketSelector);
      const isDetailedMonopile = get(isDetailedMonopileSelector);
      const turbinesWithDetailedMonopile = tempLayoutFoundations.filter((t) =>
        isDetailedMonopile(t.properties.foundationId ?? ""),
      );
      const turbinesWithSimpleFixed = tempLayoutFoundations.filter(
        (t) =>
          isMonopile(t.properties.foundationId ?? "") ||
          isJacket(t.properties.foundationId ?? ""),
      );

      const { totalFoundationMass: totalSimpleFixedMass, foundationMasses } =
        get(
          getSimpleFixedMassesSelectorFamily({
            turbinesWithSimpleFixed,
            rasterId,
          }),
        );

      const {
        totalFoundationMass: totalDetailedMonopileMass,
        foundationDetails,
      } = get(
        getDetailedMonopileMassesSelectorFamily({
          turbinesWithDetailedMonopile,
          rasterId,
        }),
      );

      return {
        totalPrimarySteelMass: totalDetailedMonopileMass + totalSimpleFixedMass,
        foundationMasses,
        foundationDetails,
      };
    },
});
