import * as turf from "@turf/turf";
import { LinePaint } from "mapbox-gl";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { SubAreaFeature } from "../../types/feature";
import { mapRefAtom } from "../../state/map";
import { ParkFeature } from "../../types/feature";
import { calculateEllipseY, deg2rad, rad2deg } from "../../utils/geometry";
import LineString from "../MapFeatures/LineString";
import { axisLinesLayerId, axisLinesSourceId } from "./constants";
import { scream } from "utils/sentry";

const axisLinePaint: LinePaint = {
  "line-width": 1,
  "line-opacity": 0.25,
  "line-color": "black",
};

export const AxisLines = ({
  region,
  minorAxisSpacing,
  majorAxisSpacing,
  rotation,
  obliquity,
  shiftX,
  shiftY,
}: {
  region: ParkFeature | SubAreaFeature;
  minorAxisSpacing: number;
  majorAxisSpacing: number;
  rotation: number;
  obliquity: number;
  shiftX: number;
  shiftY: number;
}) => {
  const map = useRecoilValue(mapRefAtom);

  const features = useMemo(() => {
    try {
      const shift = Math.tan((obliquity * Math.PI) / 180) * minorAxisSpacing;
      const distanceMinorAxis = calculateEllipseY(
        shift,
        majorAxisSpacing,
        minorAxisSpacing,
      );
      const angle = rad2deg(Math.atan(shift / distanceMinorAxis));
      const distToRow = distanceMinorAxis / Math.cos(deg2rad(angle));

      // Here's the grid we want to draw:
      // a--b--c
      // |  |  |
      // d--e--f
      // |  |  |
      // g--h--i

      const e = turf.centerOfMass(region);
      const f = turf.destination(e, majorAxisSpacing, rotation, {
        units: "meters",
      });
      const d = turf.destination(e, majorAxisSpacing, rotation + 180, {
        units: "meters",
      });

      const b = turf.destination(e, distToRow, rotation + angle + 90, {
        units: "meters",
      });
      const h = turf.destination(e, distToRow, rotation + angle - 90, {
        units: "meters",
      });

      const a = turf.destination(b, majorAxisSpacing, rotation + 180, {
        units: "meters",
      });
      const c = turf.destination(b, majorAxisSpacing, rotation, {
        units: "meters",
      });
      const g = turf.destination(h, majorAxisSpacing, rotation + 180, {
        units: "meters",
      });
      const i = turf.destination(h, majorAxisSpacing, rotation, {
        units: "meters",
      });

      let lines = [
        [a, b, c],
        [d, e, f],
        [g, h, i],
        [a, d, g],
        [b, e, h],
        [c, f, i],
      ];
      const lineStrings = lines.map((fs) =>
        turf.lineString(fs.map((f) => f.geometry.coordinates)),
      );
      const offsetted = lineStrings.map((f) =>
        turf.transformTranslate(
          turf.transformTranslate(f, shiftX, 90, { units: "meters" }),
          shiftY,
          0,
          { units: "meters" },
        ),
      );

      return offsetted;
    } catch (e) {
      scream("Failed to generate axis lines", {
        error: e,
        region,
        minorAxisSpacing,
        majorAxisSpacing,
        rotation,
        obliquity,
        shiftX,
        shiftY,
      });
      return [];
    }
  }, [
    obliquity,
    region,
    rotation,
    shiftX,
    shiftY,
    minorAxisSpacing,
    majorAxisSpacing,
  ]);

  if (!map) return null;
  return (
    <LineString
      features={features}
      sourceId={axisLinesSourceId}
      layerId={axisLinesLayerId}
      map={map}
      paint={axisLinePaint}
    />
  );
};
