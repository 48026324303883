import { useEffect } from "react";
import { selectorFamily, useRecoilValue } from "recoil";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { WarningProps } from "./ValidationWarnings";
import { branchIdSelector } from "../../state/pathParams";
import { getSubstationsInBranchSelectorFamily } from "../../state/cable";

export const substationMissingTypeWarningSelectorFamily = selectorFamily<
  | {
      type: ValidationWarningTypes.SubstationMissingType;
      featureIds: string[];
      parkId: string;
    }
  | undefined,
  {
    parkId: string;
    branchId: string;
  }
>({
  key: "substationMissingTypeSelectorFamily",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const substations = get(
        getSubstationsInBranchSelectorFamily({ parkId, branchId }),
      );

      const substationsMissingType = substations.filter(
        (s) => !s.properties.substationTypeId,
      );

      return substationsMissingType.length === 0
        ? undefined
        : {
            type: ValidationWarningTypes.SubstationMissingType,
            featureIds: substationsMissingType.map((s) => s.id),
            parkId: parkId,
          };
    },
});

const SubstationMissingTypeInner = ({
  parkId,
  branchId,
  warn,
  remove,
}: {
  parkId: string;
  branchId: string;
} & WarningProps) => {
  const substationMissingTypeWarning = useRecoilValue(
    substationMissingTypeWarningSelectorFamily({ parkId, branchId }),
  );

  // Update validation warning
  useEffect(() => {
    if (!substationMissingTypeWarning) return;
    warn(substationMissingTypeWarning);
    return () => {
      remove(ValidationWarningTypes.SubstationMissingType);
    };
  }, [remove, substationMissingTypeWarning, warn]);
  return null;
};

const SubstationMissingType = ({
  parkId,
  ...props
}: { parkId: string } & WarningProps) => {
  const branchId = useRecoilValue(branchIdSelector);

  if (!branchId) return null;
  return (
    <SubstationMissingTypeInner
      parkId={parkId}
      branchId={branchId}
      {...props}
    />
  );
};

export default SubstationMissingType;
