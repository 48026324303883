import { AnySourceData, Expression, SymbolLayer } from "mapbox-gl";
import { colors } from "../../../styles/colors";
import { range } from "../../../utils/utils";
import { CableFeature } from "../../../types/feature";
import { defaultPointCircleRadius } from "../../MapFeatures/expressionUtils";
import { CableType } from "../../../services/cableTypeService";
import {
  DEFAULT_IN_FOCUS_OPACITY,
  DEFAULT_NOT_IN_FOCUS_OPACITY,
  DEFAULT_SELECTED_OPACITY,
  lockedPropertyName,
  displayLabelPropertyName,
} from "../../../constants/canvas";
import {
  generateColorFromPrepickedColorLibrary,
  generateHSLGradientColors,
  preGenerateColorFromPrepickedColorLibrary,
} from "./utils";

export const geojson = (data: any): AnySourceData => ({
  type: "geojson",
  data,
  promoteId: "id",
});

export interface CableColor {
  cableTypeId: string;
  color: string;
}

export interface CableLossColor {
  cableId: string;
  color: string;
}

export const getSomeCableColor = () =>
  generateColorFromPrepickedColorLibrary(0);

export const getCableColors = ({
  cableTypes,
}: {
  cableTypes: CableType[];
}): CableColor[] => {
  const fallbackColor = {
    cableTypeId: "other",
    color: colors.secondaryText,
  };

  const preGeneratedColors = preGenerateColorFromPrepickedColorLibrary(
    cableTypes.length,
  );

  return [
    ...cableTypes.map(({ id }, i) => ({
      cableTypeId: id,
      color: preGeneratedColors[i],
    })),
    fallbackColor,
  ];
};

export const getCableLossColors = ({
  cables,
  interArrayLossPerCable,
}: {
  cables: CableFeature[];
  interArrayLossPerCable: Record<string, number>;
}): CableLossColor[] => {
  const numColors = 20;
  const gradient = generateHSLGradientColors({
    n: numColors,
    startHue: 120,
    endHue: 30,
    saturation: 100,
    lightness: 50,
  });

  const maxLoss = Math.max(...Object.values(interArrayLossPerCable));

  const cableLossColors = cables.map(({ id }) => {
    const loss = interArrayLossPerCable[id];

    if (!loss)
      return {
        cableId: id,
        color: colors.secondaryText,
      };

    const gradientIdx = Math.ceil((loss / maxLoss) * numColors) - 1;

    return {
      cableId: id,
      color: gradient[gradientIdx],
    };
  });

  return cableLossColors;
};

// prettier-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const debugColorGradient = (n: number): Expression  => {
  n = Math.max(0, n);
  const ret: Expression = [
    "match",
    ["get", "partition"],
    ...range(0, n + 1).flatMap(i => [i, `hsl(${Math.round(i / n * 360)}, 100%, 50%)`]),
    '#888888'
  ];
  return ret;
}

// prettier-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const debugColorCablesByPartition: Expression = [
  "match",
  ["get", "partition"],
  0, '#ff0000',
  1, '#00ff00',
  2, '#0000ff',
  3, '#ffff00',
  4, '#ff00ff',
  5, '#00ffff',
  6,  '#880000',
  7,  '#008800',
  8,  '#000088',
  9,  '#888800',
  10, '#880088',
  11, '#008888',
  12, '#ff8800',
  13, '#ff0088',
  14, '#00ff88',
  15, '#88ff00',
  16, '#8800ff',
  17, '#0088ff',
  18, '#ff8888',
  19, '#88ff88',
  20, '#8888ff',
  21, '#ffff88',
  22, '#ff88ff',
  23, '#88ffff',
  24, '#888888',
  '#000000'
];

export const getCablePaint = ({
  cableTypes,
}: {
  cables?: CableFeature[];
  cableTypes: CableType[];
}): mapboxgl.LinePaint => {
  const cableColors = getCableColors({ cableTypes });

  // let numPartitions = 1;
  // if (0 < (cables?.length ?? 0)) {
  //   const partitions = cables?.map((c) => c.properties.partition ?? 1);
  //   numPartitions = fastMax(partitions ?? [1]);
  // }

  return {
    "line-width": [
      "case",
      ["boolean", ["get", "error"], false],
      6,
      ["==", ["get", lockedPropertyName], true],
      2,
      ["!=", ["feature-state", "borderColor"], null],
      4,
      [
        "boolean",
        ["feature-state", "hover"],
        ["feature-state", "selected"],
        false,
      ],
      4,
      2,
    ],
    "line-opacity": [
      "case",
      ["boolean", ["get", "error"], false],
      1.0,
      [
        "all",
        [
          "boolean",
          ["feature-state", "hover"],
          ["feature-state", "selected"],
          false,
        ],
        ["!=", ["get", lockedPropertyName], true],
      ],
      DEFAULT_SELECTED_OPACITY,
      ["boolean", ["feature-state", "inFocus"], false],
      DEFAULT_IN_FOCUS_OPACITY,
      DEFAULT_NOT_IN_FOCUS_OPACITY,
    ],
    "line-color": [
      "case",
      ["boolean", ["get", "error"], false],
      colors.redAlert,
      ["!=", ["feature-state", "borderColor"], null],
      ["feature-state", "borderColor"],
      [
        "match",
        ["get", "cableTypeId"],
        ...cableColors
          .slice(0, -1)
          .flatMap(({ color, cableTypeId }) => [cableTypeId, color]),
        cableColors[cableColors.length - 1].color,
      ],
    ],
  };
};

export const getCableLossPaint = ({
  cables,
  interArrayLossPerCable,
}: {
  cables: CableFeature[];
  interArrayLossPerCable: Record<string, number>;
}): mapboxgl.LinePaint => {
  const cableColors = getCableLossColors({ cables, interArrayLossPerCable });

  return {
    "line-width": 3,
    "line-color": [
      "match",
      ["get", "id"],
      ...cableColors.flatMap(({ color, cableId }) => [cableId, color]),
      colors.secondaryText,
    ],
  };
};

export const getCableRender = ({
  id,
  source,
  cableTypes,
  cables,
}: {
  id: string;
  source: string;
  cableTypes: CableType[];
  cables?: CableFeature[];
}) => {
  return {
    id,
    type: "line",
    source,
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    paint: getCablePaint({ cableTypes, cables }),
  };
};

export const cableClickRender = (id: string, source: string) => ({
  id,
  type: "line",
  source,
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-width": 14,
    "line-opacity": 0.0,
  },
});

export const cableTextRender = (id: string, source: string) => ({
  id,
  type: "symbol",
  source,
  layout: {
    "text-field": "{powerLoad} MW",
    "text-offset": [0, 0.75],
    "text-size": 8,
    "symbol-placement": "line",
  },
  filter: [
    "case",
    ["boolean", ["get", displayLabelPropertyName], true],
    true,
    false,
  ],
});

const substationStroke = 2.0;
export const substationPaint: mapboxgl.CirclePaint = {
  "circle-color": colors.substation,
  "circle-opacity": [
    "case",
    [
      "all",
      [
        "boolean",
        ["feature-state", "hover"],
        ["feature-state", "selected"],
        false,
      ],
      ["==", ["get", lockedPropertyName], false],
    ],
    DEFAULT_SELECTED_OPACITY,
    ["boolean", ["feature-state", "inFocus"], false],
    DEFAULT_IN_FOCUS_OPACITY,
    DEFAULT_NOT_IN_FOCUS_OPACITY,
  ],
  "circle-radius": defaultPointCircleRadius,
  "circle-stroke-color": [
    "case",
    ["==", ["get", lockedPropertyName], true],
    colors.lockedFeatureOutline,
    ["!=", ["feature-state", "borderColor"], null],
    ["feature-state", "borderColor"],
    ["boolean", ["feature-state", "selected"], false],
    "#ffffff",
    colors.substation,
  ],
  "circle-stroke-width": [
    "case",
    ["==", ["get", lockedPropertyName], true],
    substationStroke,
    ["!=", ["feature-state", "borderColor"], null],
    substationStroke,
    ["boolean", ["feature-state", "selected"], false],
    substationStroke,
    ["boolean", ["feature-state", "hover"], false],
    substationStroke,
    0.0,
  ],
};
export const substationRender = (
  id: string,
  source: string,
): mapboxgl.CircleLayer => ({
  id,
  type: "circle",
  source,
  // prettier-ignore
  paint: substationPaint,
});

export const substationClickRender = (id: string, source: string) => ({
  id,
  type: "circle",
  source,
  paint: {
    "circle-radius": 10,
    "circle-opacity": 0.0,
  },
});

export const exportCableLinePaint: mapboxgl.LinePaint = {
  "line-color": [
    "case",
    ["!=", ["feature-state", "borderColor"], null],
    ["feature-state", "borderColor"],
    colors.exportCable,
  ],
  "line-opacity": [
    "case",
    [
      "all",
      [
        "boolean",
        ["feature-state", "hover"],
        ["feature-state", "selected"],
        false,
      ],
      ["!=", ["get", lockedPropertyName], true],
    ],
    DEFAULT_SELECTED_OPACITY,
    ["boolean", ["feature-state", "inFocus"], false],
    DEFAULT_IN_FOCUS_OPACITY,
    DEFAULT_NOT_IN_FOCUS_OPACITY,
  ],
  "line-width": [
    "case",
    ["==", ["get", lockedPropertyName], true],
    2,
    ["!=", ["feature-state", "borderColor"], null],
    4,
    [
      "boolean",
      ["feature-state", "hover"],
      ["feature-state", "selected"],
      false,
    ],
    4,
    2,
  ],
};

export const exportCableLineRender = (id: string, source: string) => ({
  id,
  type: "line",
  source,
  layout: {
    "line-cap": "round",
    "line-join": "round",
  },
  paint: exportCableLinePaint,
});

export const exportCableLineClickRender = (id: string, source: string) =>
  cableClickRender(id, source);

export const cablePartitionColor = "orange";
export const cablePartitionPaint: mapboxgl.FillPaint = {
  "fill-opacity": [
    "case",
    [
      "boolean",
      ["feature-state", "hover"],
      ["feature-state", "selected"],
      false,
    ],
    0.6,
    0.2,
  ],
  "fill-color": cablePartitionColor,
};

export const cableChainColor = "yellow";
export const cableChainPaint: mapboxgl.FillPaint = {
  "fill-opacity": [
    "case",
    [
      "boolean",
      ["feature-state", "hover"],
      ["feature-state", "selected"],
      false,
    ],
    0.6,
    0.2,
  ],
  "fill-color": cableChainColor,
};

export const cableChainSymbolLayer: Omit<SymbolLayer, "id" | "source"> = {
  type: "symbol",
  minzoom: 2,
  layout: {
    "symbol-placement": "point",
    "text-field": "{label}",
    "text-size": 14,
    "text-allow-overlap": true,
    "symbol-z-order": "viewport-y",
    "symbol-spacing": 300,
    "text-keep-upright": true,
  },
  paint: {
    "text-opacity": 1,
    "text-halo-color": "rgba(255,255,255,0.9)",
    "text-halo-width": 2,
  },
  filter: ["boolean", ["get", displayLabelPropertyName], true],
};
