import { useRecoilValueLoadable2 } from "utils/recoil";
import {
  CAPEX_STATE,
  DECOM,
  DEVEX,
  FinanceId,
  IRRState,
  LCoE,
  OPEX,
} from "./state";

export const useCashFlow = (id: FinanceId) => {
  const devexCashFlow = useRecoilValueLoadable2(DEVEX.getCashFlow(id));
  const capexCashFlow = useRecoilValueLoadable2(CAPEX_STATE.getCashFlow(id));
  const opexCashFlow = useRecoilValueLoadable2(OPEX.getCashFlow(id));
  const decomCashFlow = useRecoilValueLoadable2(DECOM.getCashFlow(id));
  const guaranteedCashFlow = useRecoilValueLoadable2(
    IRRState.getGuaranteedCashFlow(id),
  );
  const marketCashFlow = useRecoilValueLoadable2(
    IRRState.getMarketCashFlow(id),
  );
  const discountRate = useRecoilValueLoadable2(LCoE.Inputs.getDiscountRate(id));
  const inflationRate = useRecoilValueLoadable2(
    LCoE.Inputs.getInflationRate(id),
  );

  return {
    inputs: {
      discountRate: discountRate.valueMaybe(),
      inflationRate: inflationRate.valueMaybe(),
    },
    results: {
      devex: devexCashFlow.valueMaybe(),
      capex: capexCashFlow.valueMaybe(),
      opex: opexCashFlow.valueMaybe(),
      decom: decomCashFlow.valueMaybe(),
      guaranteed: guaranteedCashFlow.valueMaybe(),
      market: marketCashFlow.valueMaybe(),
    },
  };
};
