import { Suspense, useMemo } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { useTypedPath } from "../../../state/pathParams";
import Like from "../../General/Like";
import UserInfo, { UserInfoLoading } from "../MapModal/UserInfo";
import { useReplyReactionCrud } from "../hooks/useReplyReactionCrud";
import {
  replyReactionsAtomFamily,
  userReactionsAtomFamily,
  userReactionSaveInProgressState,
} from "../state";
import { Reply } from "../types";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

export default function ReplyReactions({
  reply,
  nodeId,
}: {
  reply: Reply;
  nodeId: string;
}) {
  const { branchId } = useTypedPath("branchId");
  const currentUserReactions = useRecoilValue(
    userReactionsAtomFamily({ nodeId, branchId }),
  );
  const replyReactionSaveInProgress = useRecoilValue(
    userReactionSaveInProgressState,
  );
  const { post, remove } = useReplyReactionCrud();

  const currentUserReplyReaction = useMemo(
    () => currentUserReactions.find((r) => r.replyId === reply.replyId),
    [currentUserReactions, reply.replyId],
  );

  return (
    <Container>
      <Like
        like={
          !!currentUserReplyReaction ||
          replyReactionSaveInProgress === reply.replyId
        }
        disabled={replyReactionSaveInProgress === reply.replyId}
        totalLikes={reply.reactions}
        onClick={() =>
          currentUserReplyReaction
            ? remove(
                reply.threadId,
                reply.replyId,
                currentUserReplyReaction.reactionId,
              )
            : post(reply.threadId, reply.replyId)
        }
        reactionList={
          <Suspense
            fallback={
              <Column>
                {Array.from(Array(reply.reactions).keys()).map((_, i) => (
                  <UserInfoLoading key={i} />
                ))}
              </Column>
            }
          >
            <ReactionsList reply={reply} />
          </Suspense>
        }
      />
    </Container>
  );
}

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;
function ReactionsList({ reply }: { reply: Reply }) {
  const reactions = useRecoilValue(
    replyReactionsAtomFamily({
      nodeId: reply.nodeId,
      branchId: reply.branchId,
      threadId: reply.threadId,
      replyId: reply.replyId,
    }),
  );
  return (
    <Column style={{ pointerEvents: "none" }}>
      {reactions.map((r) => (
        <UserInfo key={r.userId} userId={r.userId} />
      ))}
    </Column>
  );
}
