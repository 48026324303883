import { organisationIdSelector, projectIdSelector } from "state/pathParams";
import {
  getNode,
  Node,
  _Node,
  _ProjectNodeInformation,
} from "./../services/customerAPI";
import { useCallback } from "react";
import {
  atomFamily,
  Loadable,
  noWait,
  selectorFamily,
  UnwrapLoadable,
  useRecoilCallback,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { Mixpanel } from "../mixpanel";
import * as api from "../types/api";
import {
  dedup,
  versionToMonthAndMaybeYearLongFormat,
  With,
} from "../utils/utils";
import {
  listKeySnapshotsInBranch,
  projectFeaturesSelector,
} from "../components/ProjectElements/state";
import { initializeAndSet } from "../components/Comments/hooks/useReplyReactionCrud";
import { useProjectElementsFoldersCrud } from "../components/ProjectElementsV2/useProjectElementsFoldersCrud";
import {
  listBranches,
  createSnapshot,
  deleteBranch,
  deleteSnapshot,
  listSnapshots,
  updateBranchMeta,
  updateSnapshotMeta,
  duplicateBranch,
  restoreVersionAsBranch,
  createBranch,
  sortBranches,
} from "../services/projectDataAPIService";
import {
  GroupedEvents,
  VersionEvent,
  DateDividerEvent,
  GeneralEvent,
} from "../types/timeline";
import { createdAtToVersion } from "../utils/project";
import { collectionLayerVersionsSelectorFamily } from "../components/LayerList/Collections/state";
import { DateTime } from "luxon";
import { getTurbineKeyVersionsSelectorFamily } from "./turbines";
import { keyCableVersionsState } from "./cable";
import { keyMooringLineVersionsState } from "./mooring";
import {
  getNodeTimelineAuditEvents,
  TimelineAuditProtoEvent,
} from "../services/timelineAPIservice";
import {
  getProjectElementFoldersKeyVersions,
  projectFoldersRefreshAtom,
} from "../components/ProjectElementsV2/state";
import {
  createProjectElementsFolder,
  restoreProjectElementsFromBranchVersionFolder,
} from "../components/ProjectElementsV2/service";
import { scream } from "../utils/sentry";
import { useToast } from "../hooks/useToast";
import { useProjectElementsSortOrder } from "components/ProjectElementsV2/useProjectElementsSortOrder";
import { getNodeSelectorFamily } from "components/Projects/useOrganisationFolderCrud";

const TEN_MINUTE_IN_SECONDS = 60 * 10;

// ======== Atoms ========

export const singleNodeAtomFamily = atomFamily<
  Node | undefined,
  { nodeId: string }
>({
  key: "singleNodeAtomFamily",
  default: selectorFamily<Node | undefined, { nodeId: string }>({
    key: "singleNodeAtomFamily.default",
    get:
      ({ nodeId }) =>
      async () => {
        try {
          const node = await getNode(nodeId);
          const parsedNode = _Node.parse(node);
          return parsedNode;
        } catch {
          return undefined;
        }
      },
  }),
});
export const customerProjectAtomFamily = atomFamily<
  api.ProjectMeta | undefined,
  { nodeId: string }
>({
  key: "customerProjectAtomFamily",
  default: selectorFamily<api.ProjectMeta | undefined, { nodeId: string }>({
    key: "customerProjectAtomFamily.default",
    get:
      ({ nodeId }) =>
      async ({ get }) => {
        const organisationId = get(organisationIdSelector);
        if (!organisationId) return;
        try {
          const node = get(getNodeSelectorFamily({ organisationId, nodeId }));
          const projectNode = _ProjectNodeInformation.parse(node);
          return projectNode;
        } catch {
          return undefined;
        }
      },
  }),
});
export const customerProjectLegacySelectorFamily = selectorFamily<
  api.ProjectMeta | undefined,
  { projectId: string | undefined }
>({
  key: "customerProjectLegacySelectorFamily",

  get:
    ({ projectId }) =>
    () => {
      if (!projectId) return undefined;
      return customerProjectAtomFamily({ nodeId: projectId });
    },
});

export const projectBranchesAtomFamily = atomFamily<
  api.BranchMeta[],
  { nodeId: string }
>({
  key: "projectBranchesAtomFamily",
  default: selectorFamily<api.BranchMeta[], { nodeId: string }>({
    key: "projectBranchesAtomFamily.default",
    get:
      ({ nodeId }) =>
      async () => {
        try {
          const res = await listBranches(nodeId);
          return (res.branches ?? []).sort((a, b) => {
            return (
              (a.sortOrder ?? Number.MAX_SAFE_INTEGER) -
              (b.sortOrder ?? Number.MAX_SAFE_INTEGER)
            );
          });
        } catch {
          return [];
        }
      },
  }),
});

export const branchSnapshotsAtomFamily = atomFamily<
  api.SnapshotMeta[],
  { projectId: string; branchId: string }
>({
  key: "branchSnapshotsAtomFamily",
  default: selectorFamily<
    api.SnapshotMeta[],
    { projectId: string; branchId: string }
  >({
    key: "branchSnapshotsAtomFamily.default",
    get: (o) => async () => {
      const res = await listSnapshots(o.projectId, o.branchId);
      return res?.snapshots;
    },
  }),
});

// ======== Branch ========

export const useCreateBranch = () => {
  const create = useRecoilCallback(
    ({ set, snapshot }) =>
      async (
        projectId: string,
        branch: With<api.BranchMeta, "title">,
        withFeatures?: boolean,
      ) => {
        const features = withFeatures
          ? await snapshot.getPromise(projectFeaturesSelector)
          : [];

        if (!projectId) throw new Error("failed to find nodeId");
        return await createBranch(projectId, branch.title, features).then(
          ({ meta }) => {
            set(projectBranchesAtomFamily({ nodeId: projectId }), (curr) => [
              ...curr,
              meta,
            ]);
            Mixpanel.track(`Project branch created`, {
              projectId,
              branchId: meta.id,
            });
            return { meta };
          },
        );
      },
    [],
  );
  const updateLocal = useRecoilCallback(
    ({ set }) =>
      async (meta: api.BranchMeta, projectId: string) => {
        if (!projectId) throw new Error("failed to find nodeId");
        set(projectBranchesAtomFamily({ nodeId: projectId }), (curr) => {
          const matchingBranch = curr.find((b) => b.id === meta.id) ?? {};
          const filtered = curr.filter((b) => b.id !== meta.id);
          return [...filtered, { ...matchingBranch, ...meta }];
        });
      },
    [],
  );
  return { create, updateLocal };
};
export const useDuplicateBranch = () => {
  const { items: projectElementsFolders } = useProjectElementsFoldersCrud();
  const { sortProjectElements, sortOrder } = useProjectElementsSortOrder();

  const { error: showError } = useToast();
  const setProjectFoldersRefresh = useSetRecoilState(projectFoldersRefreshAtom);
  const callback = useRecoilCallback(
    ({ set }) =>
      async (projectId: string, branchId: string, snapshotId?: string) => {
        if (!projectId) return;
        const dupedBranch = await duplicateBranch(
          projectId,
          branchId,
          snapshotId,
        ).then(({ meta }) => {
          set(projectBranchesAtomFamily({ nodeId: projectId }), (curr) => [
            ...curr,
            meta,
          ]);
          Mixpanel.track(`Project branch duplicated`, {
            projectId,
            branchId: meta.id,
          });
          return { meta };
        });

        const newFolderIdsMapping = new Map();
        for (const folder of projectElementsFolders) {
          try {
            const newFolder = await createProjectElementsFolder(
              projectId,
              dupedBranch.meta.id,
              {
                featureIds: folder.featureIds,
                folderName: folder.folderName,
              },
            );

            newFolderIdsMapping.set(folder.folderId, newFolder.folderId);
          } catch (error) {
            scream(
              "Could not clone project element folder when duplicating branch",
              {
                error,
                projectId,
                newBranchId: dupedBranch.meta.id,
                nrFeatures: folder.featureIds.length,
                folderName: folder.folderName,
              },
            );

            showError(
              `The feature folder "${folder.folderName}" could not be cloned to the new branch.
            The Vind team has been notified about the issue. Please try to clone the branch again, or contact support for more information.`,
              {
                timeout: 10_000,
              },
            );
          }
        }
        const sortOrderWithNewFolderIds = sortOrder.map((item) => ({
          ...item,
          id: newFolderIdsMapping.has(item.id)
            ? newFolderIdsMapping.get(item.id)
            : item.id,
        }));

        await sortProjectElements(
          sortOrderWithNewFolderIds,
          dupedBranch.meta.id,
        );

        setProjectFoldersRefresh((r) => r + 1);
        return dupedBranch;
      },
    [
      projectElementsFolders,
      setProjectFoldersRefresh,
      showError,
      sortOrder,
      sortProjectElements,
    ],
  );
  return useCallback(callback, [callback]);
};

export const useRestoreVersionToBranch = () => {
  const callback = useRecoilCallback(
    ({ set }) =>
      async (projectId: string, branchId: string, version: number) => {
        if (!projectId) return;
        const restoredVersionBranch = await restoreVersionAsBranch(
          projectId,
          branchId,
          version,
        ).then(({ meta }) => {
          set(projectBranchesAtomFamily({ nodeId: projectId }), (curr) => [
            ...curr,
            meta,
          ]);
          Mixpanel.track(`Version restored as branch`, {
            projectId,
            branchId: meta.id,
          });
          return { meta };
        });

        await restoreProjectElementsFromBranchVersionFolder(
          projectId,
          branchId,
          version,
          restoredVersionBranch.meta.id,
        );

        return restoredVersionBranch;
      },
    [],
  );
  return useCallback(callback, [callback]);
};

export const getBranchSelectorFamily = selectorFamily<
  undefined | api.BranchMeta,
  {
    projectId: string;
    branchId: string;
  }
>({
  key: "getBranchSelectorFamily",
  get:
    (o) =>
    ({ get }) => {
      const branches = get(projectBranchesAtomFamily({ nodeId: o.projectId }));

      return branches.find((b) => b.id === o.branchId);
    },
});

export const useUpdateBranch = () => {
  const projectId = useRecoilValue(projectIdSelector);
  const callback = useRecoilCallback(
    ({ set, snapshot }) =>
      async (
        update: Pick<
          api.BranchMeta,
          | "id"
          | "title"
          | "analysisConfigurationId"
          | "windConfigurationId"
          | "costConfigurationId"
        >,
      ) => {
        if (!projectId) return;

        const {
          title,
          analysisConfigurationId,
          windConfigurationId,
          costConfigurationId,
        } = update;

        const stateBeforeUpdate = await snapshot.getPromise(
          projectBranchesAtomFamily({ nodeId: projectId }),
        );

        set(projectBranchesAtomFamily({ nodeId: projectId }), (curr) =>
          [...curr].map((b) => (b.id === update.id ? { ...b, ...update } : b)),
        );
        await updateBranchMeta(projectId, update.id, {
          title,
          analysisConfigurationId,
          windConfigurationId,
          costConfigurationId,
        })
          .then(({ meta }) => {
            Mixpanel.track(`Update branch meta`, {
              projectId,
              branchId: meta.id,
            });
          })
          .catch(() => {
            set(
              projectBranchesAtomFamily({ nodeId: projectId }),
              () => stateBeforeUpdate,
            );
          });
      },
    [projectId],
  );
  return useCallback(callback, [callback]);
};

export const useSortBranches = () => {
  const projectId = useRecoilValue(projectIdSelector);

  const updateLocal = useRecoilCallback(
    ({ set }) =>
      async (sortOrder: string[]) => {
        if (!projectId) return;

        set(projectBranchesAtomFamily({ nodeId: projectId }), (curr) => {
          return [...curr].sort((a, b) => {
            const itemA = sortOrder.findIndex((id) => id === a.id);
            const itemB = sortOrder.findIndex((id) => id === b.id);

            return itemA - itemB;
          });
        });
      },
    [projectId],
  );

  const _sortBranches = useRecoilCallback(
    ({ set, snapshot }) =>
      async (sortOrder: string[]) => {
        if (!projectId) return;

        const stateBeforeUpdate = await snapshot.getPromise(
          projectBranchesAtomFamily({ nodeId: projectId }),
        );

        updateLocal(sortOrder);

        await sortBranches(projectId, sortOrder)
          .then(() => {
            Mixpanel.track(`Update branches sort order`, {
              projectId,
              nrBranches: sortOrder.length,
            });
          })
          .catch(() => {
            set(
              projectBranchesAtomFamily({ nodeId: projectId }),
              () => stateBeforeUpdate,
            );
          });
      },
    [projectId, updateLocal],
  );

  return {
    updateLocal,
    sortBranches: _sortBranches,
  };
};

export const useDeleteBranch = () => {
  const callback = useRecoilCallback(
    ({ set }) =>
      async (branch: api.BranchMeta, projectId: string) => {
        if (!projectId) throw new Error("No nodeId found");
        await deleteBranch(projectId, branch.id).then(() => {
          set(
            projectBranchesAtomFamily({
              nodeId: projectId,
            }),
            (curr) => curr.filter((b) => b.id !== branch.id),
          );
          Mixpanel.track(`Delete branch`, {
            projectId,
            branchId: branch.id,
          });
        });
      },
    [],
  );
  return useCallback(callback, [callback]);
};

// ======== Snapshots ========

export const useCreateSnapshot = () => {
  const callback = useRecoilCallback(
    ({ set, snapshot }) =>
      async (
        branch: api.BranchMeta,
        update: With<api.SnapshotMeta, "title">,
        projectId: string,
        version?: number,
      ) => {
        const { id: branchId } = branch;
        await createSnapshot(projectId, branchId, update, version).then(
          async ({ meta }) => {
            try {
              initializeAndSet(
                snapshot,
                set,
                branchSnapshotsAtomFamily({ projectId, branchId }),
                (curr) => dedup([...curr, meta], (snapshot) => snapshot.id),
              );
              Mixpanel.track(`Create snapshot`, {
                projectId,
                branchId,
                snapshotId: meta.id,
              });
            } catch (e) {
              console.log(e);
            }
          },
        );
      },
    [],
  );
  return useCallback(callback, [callback]);
};

export const useUpdateSnapshot = () => {
  const putLocal = useRecoilCallback(
    ({ set, snapshot }) =>
      async (update: api.SnapshotMeta, projectId: string) => {
        const { branchId, id: snapshotId } = update;

        initializeAndSet(
          snapshot,
          set,
          branchSnapshotsAtomFamily({ projectId, branchId }),
          (curr) => {
            const match = curr.find((c) => c.id === update.id) ?? {};
            return [
              ...curr.filter((c) => c.id !== snapshotId),
              { ...match, ...update },
            ];
          },
        );
      },
    [],
  );

  const update = useRecoilCallback(
    () =>
      async (
        snapshot: Pick<api.SnapshotMeta, "branchId" | "id">,
        update: Partial<api.SnapshotMetaUpdate>,
        projectId: string,
      ) => {
        const { branchId, id: snapshotId } = snapshot;
        await updateSnapshotMeta(projectId, branchId, snapshotId, update).then(
          (partialSnapshotMeta) => {
            putLocal(partialSnapshotMeta.meta, projectId);
            Mixpanel.track(`Update snapshot`, {
              projectId,
              branchId,
              snapshotId: snapshot.id,
            });
          },
        );
      },
    [putLocal],
  );

  return { update, putLocal };
};

export const showRefreshTimelineButtonBranchAtomFamily = atomFamily<
  boolean,
  {
    projectId: string;
    branchId: string;
  }
>({
  key: "showRefreshTimelineButtonBranchAtomFamily",
  default: false,
});
export const showRefreshTimelineButtonProjectAtomFamily = atomFamily<
  boolean,
  {
    projectId: string;
  }
>({
  key: "showRefreshTimelineButtonProjectAtomFamily",
  default: false,
});

export const showRefreshTimelineButtonSelectorFamily = selectorFamily<
  boolean,
  {
    projectId: string;
    branchId: string;
  }
>({
  key: "showRefreshTimelineButtonSelectorFamily",
  get:
    ({ projectId, branchId }) =>
    ({ get }) =>
      get(showRefreshTimelineButtonProjectAtomFamily({ projectId })) ||
      get(
        showRefreshTimelineButtonBranchAtomFamily({
          projectId,
          branchId,
        }),
      ),
});

const getValueIfLoaded = <T extends Loadable<any>>(
  loadable: T,
): UnwrapLoadable<T> => {
  return loadable.state === "hasValue"
    ? loadable.getValue()
    : ([] as UnwrapLoadable<T>);
};

export const isLoadingAnySnapshotDataSelectorFamily = selectorFamily<
  boolean,
  {
    organisationId: string;
    projectId: string;
    branchId: string;
  }
>({
  key: "isLoadingAnySnapshotDataSelectorFamily",
  get:
    (o) =>
    ({ get }) => {
      const keySnapshots = get(noWait(listKeySnapshotsInBranch(o)));
      const turbineKeyEvents = get(
        noWait(getTurbineKeyVersionsSelectorFamily(o)),
      );
      const layerCollectionSnaphots = get(
        noWait(collectionLayerVersionsSelectorFamily(o)),
      );
      const projectElementsFoldersKeyVersions = get(
        noWait(getProjectElementFoldersKeyVersions(o)),
      );

      return [
        keySnapshots.state,
        turbineKeyEvents.state,
        layerCollectionSnaphots.state,
        projectElementsFoldersKeyVersions.state,
      ].includes("loading");
    },
});

const sortGroupMergeAndAddDateDividerForSnapshots = (
  events: VersionEvent[],
  earliestBranchSnapshot: number,
) => {
  const orderedEvents: VersionEvent[] = events
    .sort((a, b) => {
      const diff = b.version - a.version;
      if (diff !== 0) return diff;
      return 0;
    })
    .filter((s) => s.version >= earliestBranchSnapshot);

  const groupedEvents = orderedEvents
    .reduce<(VersionEvent | DateDividerEvent)[]>((acc, event, i) => {
      const thisEventDate = DateTime.fromSeconds(event.version);

      if (i === 0)
        return [
          ...acc,
          {
            type: "date",
            date: versionToMonthAndMaybeYearLongFormat(event.version, "en-US"),
          },
          event,
        ];

      const previousEventDate = DateTime.fromSeconds(
        orderedEvents[i - 1].version,
      );

      if (thisEventDate.month !== previousEventDate.month)
        return [
          ...acc,
          {
            type: "date",
            date: versionToMonthAndMaybeYearLongFormat(event.version, "en-US"),
          },
          event,
        ];

      return [...acc, event];
    }, [])
    .reduce<GroupedEvents[]>((acc, event) => {
      if (event.type === "branch") return [...acc, event];
      if (event.type === "date") return [...acc, event];

      const lastEvent = acc[acc.length - 1];
      if (lastEvent.type === "general") {
        lastEvent.events.push(event);
      } else {
        acc.push({
          type: "general",
          events: [event],
          date: versionToMonthAndMaybeYearLongFormat(event.version, "en-US"),
        });
      }
      return acc;
    }, []);

  const groupedAndMergedEvents = groupedEvents.reduce((acc, eventGroup) => {
    if (eventGroup.type !== "general") return [...acc, eventGroup];
    const events = eventGroup.events.reduce((eventAcc, event) => {
      if (eventAcc.length === 0) return [...eventAcc, event];

      const lastElement = eventAcc[eventAcc.length - 1];
      if (
        event.type !== lastElement.type ||
        event.author !== lastElement.author ||
        event.action !== lastElement.action ||
        lastElement.version - event.version > TEN_MINUTE_IN_SECONDS
      )
        return [...eventAcc, event];

      return [
        ...eventAcc.slice(0, eventAcc.length - 1),
        {
          ...lastElement,
          meta: { ...lastElement.meta, count: lastElement.meta.count + 1 },
        },
      ];
    }, [] as GeneralEvent[]);

    return [...acc, { ...eventGroup, events }];
  }, [] as GroupedEvents[]);

  return groupedAndMergedEvents;
};

const getTimelineAuditEventsSelectorFamily = selectorFamily<
  TimelineAuditProtoEvent[],
  {
    projectId: string;
    branchId: string;
  }
>({
  key: "getTimelineAuditEventsSelectorFamily",
  get:
    ({ projectId, branchId }) =>
    async () => {
      const timelineNodeEvents = projectId
        ? await getNodeTimelineAuditEvents(projectId, branchId)
        : [];
      return timelineNodeEvents;
    },
});

export const listAllSnaphotsInBranchesSelectorFamily = selectorFamily<
  GroupedEvents[],
  {
    organisationId: string;
    projectId: string;
    branchId: string;
  }
>({
  key: "listAllSnaphotsInBranchesSelectorFamily",
  get:
    (o) =>
    ({ get }) => {
      const snapshots = get(
        branchSnapshotsAtomFamily({
          projectId: o.projectId,
          branchId: o.branchId,
        }),
      );
      const keySnapshots = get(noWait(listKeySnapshotsInBranch(o)));
      const keyCableVersions = get(
        noWait(
          keyCableVersionsState({
            projectId: o.projectId,
          }),
        ),
      );
      const keyMooringLineVersions = get(
        noWait(
          keyMooringLineVersionsState({
            projectId: o.projectId,
          }),
        ),
      );
      const turbineKeyEvents = get(
        noWait(getTurbineKeyVersionsSelectorFamily(o)),
      );
      const layerCollectionSnaphots = get(
        noWait(collectionLayerVersionsSelectorFamily(o)),
      );
      const projectElementsFoldersKeyVersions = get(
        noWait(getProjectElementFoldersKeyVersions(o)),
      );
      const timelineAuditEvents = get(
        getTimelineAuditEventsSelectorFamily({
          projectId: o.projectId,
          branchId: o.branchId,
        }),
      );

      const earliestBranchSnapshot = createdAtToVersion(
        Math.min(...snapshots.map((s) => s.createdAt)),
      );

      const events = [
        ...timelineAuditEvents.map((e) => ({
          ...e,
          meta: {
            count: 1,
          },
        })),
        ...getValueIfLoaded(keySnapshots).map((s) => ({
          ...s,
          version: createdAtToVersion(s.end),
          type: "keySnapshot",
        })),
        ...snapshots.map((s) => ({
          ...s,
          version: createdAtToVersion(s.createdAt),
          type: "branch",
        })),
        ...getValueIfLoaded(keyCableVersions).map((c) => ({
          ...c,
          type: "cable",
          version: createdAtToVersion(c.end),
        })),
        ...getValueIfLoaded(keyMooringLineVersions).map((l) => ({
          ...l,
          type: "mooringLine",
          version: createdAtToVersion(l.end),
        })),
        ...getValueIfLoaded(turbineKeyEvents).map((e) => ({
          ...e,
          type: "turbine",
          version: createdAtToVersion(e.end),
        })),
        ...getValueIfLoaded(layerCollectionSnaphots).map((s) => ({
          ...s,
          version: createdAtToVersion(s.end),
          type: s.configType,
        })),
        ...getValueIfLoaded(projectElementsFoldersKeyVersions).map((s) => ({
          ...s,
          type: "projectElementFolder",
          version: createdAtToVersion(s.end),
        })),
      ] as VersionEvent[];

      return sortGroupMergeAndAddDateDividerForSnapshots(
        events,
        earliestBranchSnapshot,
      );
    },
});

export const useDeleteSnapshot = () => {
  const callback = useRecoilCallback(
    ({ set }) =>
      async (
        data: With<api.SnapshotMeta, "branchId" | "id">,
        projectId: string,
      ) => {
        const { branchId, id } = data;
        if (!projectId) throw new Error("NodeId not found");
        await deleteSnapshot(projectId, branchId, id).then(() => {
          set(
            branchSnapshotsAtomFamily({
              projectId,
              branchId,
            }),
            (curr) => curr.filter((e) => e.id !== id),
          );
          Mixpanel.track(`Delete snapshot`, {
            projectId,
            branchId,
            snapshotId: id,
          });
        });
      },
    [],
  );
  return useCallback(callback, [callback]);
};

export const viewAllUnderMonthAtomFamily = atomFamily<
  boolean,
  { projectId: string; branchId: string; date: string }
>({
  key: "viewAllUnderMonthAtomFamily",
  default: false,
});

export const forTest = { sortGroupMergeAndAddDateDividerForSnapshots };
