export const spaceTiny = "0.4rem";
export const spaceSmall = "0.6rem";
export const spaceMedium = "0.8rem";
export const spaceDecent = "1.2rem";
export const spaceLarge = "1.6rem";
export const spaceLarge_2 = "3.2rem";
export const spaceHuge = "2.0rem";

export const borderRadiusSmall = "2px";
export const borderRadiusMedium = "4px";
export const borderRadiusLarge = "6px";
export const borderRadiusHuge = "2rem";

export const borderRadius = {
  sharp: "0px",
  small: "4px",
  /**
   * Not sure if this should be used, as opposed to 50% or something like that.
   */
  full: "999px",
  pill: "16px",
} as const;

export const spacing0 = "0.0rem";
export const spacing1 = "0.2rem";
export const spacing2 = "0.4rem";
export const spacing3 = "0.6rem";
export const spacing4 = "0.8rem";
export const spacing5 = "1.0rem";
export const spacing6 = "1.2rem";
export const spacing7 = "1.6rem";
export const spacing8 = "2.0rem";
export const spacing9 = "2.4rem";
export const spacing10 = "2.8rem";
export const spacing11 = "3.2rem";
export const spacing12 = "4.0rem";
export const spacing13 = "4.8rem";
export const spacing14 = "5.6rem";
export const spacing15 = "6.4rem";
export const spacing16 = "7.2rem";
export const spacing17 = "8.0rem";
