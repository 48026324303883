import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { mapRefAtom } from "../state/map";

const useMapBBOX = (): [
  number[] | undefined,
  React.Dispatch<React.SetStateAction<number[] | undefined>>,
] => {
  const map = useRecoilValue(mapRefAtom);
  const [mapBBOX, setMapBBOX] = useState<number[] | undefined>(undefined);

  useEffect(() => {
    if (!map) return;
    const moveend = () => {
      const bounds = map.getBounds();
      const mapBBOX: number[] = [
        ...Object.values(bounds.getSouthWest()),
        ...Object.values(bounds.getNorthEast()),
      ];
      setMapBBOX(mapBBOX);
    };
    map.on("moveend", moveend);
    moveend();
    return () => {
      map.off("moveend", moveend);
    };
  }, [map]);

  return [mapBBOX, setMapBBOX];
};

export default useMapBBOX;
