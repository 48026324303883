/// <reference types="vite-plugin-svgr/client" />
import { ReactNode, CSSProperties, useEffect } from "react";
import styled from "styled-components";
import ChevronDownIcon from "@icons/14/ChevronDown.svg";
import { typography } from "../../styles/typography";
import useBooleanState from "../../hooks/useBooleanState";
import { colors } from "../../styles/colors";
import { spacing1 } from "styles/space";

const Content = styled.div`
  padding: 1.2rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0.1rem 0.4rem rgba(0, 0, 0, 0.16);
`;

const TitleWithImage = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0.4rem;
  color: ${colors.textPrimary};
  ${typography.sub1}
`;

const ExpandArrowWrapper = styled.div<{ open: boolean }>`
  margin-right: 1rem;
  cursor: pointer;
  margin-left: ${spacing1};
  transform: rotate(${({ open }) => (!open ? "-90deg" : "0deg")});
  transition: 0.1s;

  ${({ open }) =>
    !open &&
    `
    svg {
      path {
        fill: ${colors.grey500};
      }
    }`};
`;

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  overflow-y: hidden;
  * > p {
    overflow: hidden;
  }
`;

const ShadeToggleableList = ({
  title,
  icon,
  children,
  contentStyle,
  defaultOpen = false,
  onOpenChange,
}: {
  title: string;
  icon?: JSX.Element;
  errorString?: string;
  contentStyle?: CSSProperties;
  children?: ReactNode;
  defaultOpen?: boolean;
  onOpenChange?(isOpen: boolean): void;
}) => {
  const [open, toggleOpen] = useBooleanState(defaultOpen);

  useEffect(() => {
    onOpenChange?.(open);
  }, [open, onOpenChange]);

  return (
    <Content>
      <Wrapper onClick={toggleOpen}>
        <ExpandArrowWrapper open={open}>
          <ChevronDownIcon />
        </ExpandArrowWrapper>
        <TitleWithImage>
          {!!icon && icon}
          {title}
        </TitleWithImage>
      </Wrapper>
      {children && <div style={contentStyle}>{open && children}</div>}
    </Content>
  );
};

export default ShadeToggleableList;
