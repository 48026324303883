import { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalTypeOpenAtom } from "../../state/modal";
import FullScreenModal from "../FullScreenModal/FullScreenModal";
import Button from "../General/Button";

import { SkeletonText } from "../Loading/Skeleton";
import { Column, ModalFrame, Row } from "../General/Layout";
import { useToast } from "../../hooks/useToast";
import { currentOrganisationSelector } from "./state";
import { Label } from "../General/Form";
import OrgImageUpload from "./Image/OrgImageUpload";
import { Input } from "../General/Input";
import { useEditOrganisation } from "./hooks/useEditOrganisation";
import { adminInOrganisationSelectorFamily } from "state/user";

export const OrgSettingsModalType = "OrgSettingsModal";

const OrgSettingsModal = () => {
  const modalTypeOpen = useRecoilValue(modalTypeOpenAtom);
  if (modalTypeOpen?.modalType !== OrgSettingsModalType) return null;

  return <OrgSettingsModalInner />;
};

const OrgSettingsModalInner = () => {
  const currentOrganisation = useRecoilValue(currentOrganisationSelector);
  const [loading, setLoading] = useState(false);
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const editName = useEditOrganisation();

  const isAdminInOrg = useRecoilValue(
    adminInOrganisationSelectorFamily({
      organisationId: currentOrganisation?.id,
    }),
  );

  const [name, setName] = useState(currentOrganisation?.name ?? "");
  useEffect(() => {
    const _name = currentOrganisation?.name;
    if (_name) {
      setName(_name);
    }
  }, [currentOrganisation]);

  const { success } = useToast();

  const onSave = useCallback(async () => {
    if (!isAdminInOrg) return;
    if (
      !currentOrganisation ||
      !currentOrganisation?.id ||
      name === currentOrganisation?.name
    )
      return;
    setLoading(true);
    await editName(currentOrganisation.id, name);
    setLoading(false);
    success("Update complete");
  }, [currentOrganisation, editName, isAdminInOrg, name, success]);

  if (loading)
    return (
      <FullScreenModal>
        <ModalFrame title="Organisation settings">
          <div>
            <SkeletonText />
          </div>
        </ModalFrame>
      </FullScreenModal>
    );

  return (
    <FullScreenModal>
      <ModalFrame title="Organisation settings">
        <Column style={{ gap: "2.4rem", alignItems: "center" }}>
          <OrgImageUpload size={9.2} />

          <Label style={{ width: "100%" }}>
            <p>Organisation name:</p>
            <Input
              value={name}
              disabled={!isAdminInOrg}
              onChange={(e) => {
                setName(e.target.value);
              }}
              style={{ width: "100%" }}
            />
          </Label>

          <Row style={{ marginLeft: "auto" }}>
            <Button
              buttonType="secondary"
              text="Cancel"
              onClick={() => setModalTypeOpen(undefined)}
            />
            <Button
              text="Save"
              disabled={name === currentOrganisation?.name}
              onClick={onSave}
            />
          </Row>
        </Column>
      </ModalFrame>
    </FullScreenModal>
  );
};

export default OrgSettingsModal;
