/// <reference types="vite-plugin-svgr/client" />
import { ReactNode, useCallback, useMemo } from "react";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { useToast } from "../../../hooks/useToast";
import DownloadIcon from "@icons/24/Download.svg?react";
import {
  FloatingFoundationScaledStatistics,
  TurbineFeatureWithFoundation,
  allFoundationTypesSelector,
  getAllScaledStatisticsForFloatingFoundationsInPark,
  isTurbineFeatureWithFoundation,
  hasDetailedMonopileSelector,
  isFloatingFoundationSelector,
} from "../../../state/foundations";
import {
  getInvalidTypesInParkAndBranch,
  getTurbinesSortedByLabelSelectorFamily,
} from "../../../state/layout";
import { TurbineFeature } from "../../../types/feature";
import { allSimpleTurbineTypesSelector } from "../../../state/turbines";
import { SkeletonBlock } from "../../Loading/Skeleton";
import { FoundationType } from "../../../types/foundations";
import { Raster } from "../../../types/raster";
import { SimpleTurbineType } from "../../../types/turbines";
import { isDefined } from "../../../utils/predicates";
import { downloadText, roundToDecimal, undefMap } from "../../../utils/utils";
import { MenuItem } from "../../General/Menu";
import { useDashboardContext } from "../Dashboard";
import { CenterContainer, SafeCard } from "./Base";
import {
  getSimpleFixedMassesSelectorFamily,
  MonopileDesign,
  getDetailedMonopileMassesSelectorFamily,
} from "../../RightSide/InfoModal/FoundationModal/fixed/state";
import { valueRounding } from "../../RightSide/InfoModal/FoundationModal/utils";
import { pointInPolygon } from "utils/geometry";
import { InvalidTypes, InvalidTypesDisplayText } from "types/invalidTypes";
import { useBathymetry } from "hooks/bathymetry";
import { TileResponseWithRaster } from "types/bathymetryTypes";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import Table from "components/Dashboard/Table";

const getDepth = (turbine: TurbineFeature, raster: Raster): number => {
  const [lon, lat] = turbine.geometry.coordinates;

  return raster.latLngToValue(lon, lat);
};

/**
 * Source for the data we're showing. The same hook is used to show the data in
 * the table, as well as for downloading the .csv.
 */
const useFoundationData = () => {
  const { projectId, park, branch } = useDashboardContext();

  const bathymetry = useBathymetry({
    projectId,
    branchId: branch.id,
    featureId: park.id,
  });

  const hasCustomBathymetry =
    (bathymetry
      .map((bath) => {
        if (!bath || bath.status === "failed") return 0;
        return bath.usedCustomBathymetry.length;
      })
      .valueMaybe() ?? 0) > 0;

  const getIsFloatingFoundation = useRecoilCallback(
    ({ snapshot }) =>
      (foundationId: string) => {
        return snapshot
          .getLoadable(
            isFloatingFoundationSelector({ foundationTypeId: foundationId }),
          )
          .getValue();
      },
    [],
  );

  const hasDetailedMonopile = useRecoilValue(hasDetailedMonopileSelector);
  const getData = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const bath = bathymetry.valueMaybe();
        if (!bath || bath.status !== "finished") return;
        const { raster } = bath;
        const rasterId = bath.id;

        const turbines: TurbineFeature[] = await snapshot.getPromise(
          getTurbinesSortedByLabelSelectorFamily({ parkId: park.id }),
        );
        const validTurbines = turbines.filter((t) =>
          pointInPolygon(t.geometry, park.geometry),
        );

        const turbinesWithFoundation =
          validTurbines?.filter(isTurbineFeatureWithFoundation) ?? [];

        const floatingFoundationScaledStatistics = await snapshot.getPromise(
          getAllScaledStatisticsForFloatingFoundationsInPark(park.id),
        );

        const turbinesWithDetailedMonopile =
          turbinesWithFoundation.filter(hasDetailedMonopile);
        const turbinesWithSimpleFixed = turbinesWithFoundation.filter(
          (t) => !turbinesWithDetailedMonopile.includes(t),
        );

        const monopileStats = await snapshot.getPromise(
          getDetailedMonopileMassesSelectorFamily({
            turbinesWithDetailedMonopile,
            rasterId,
          }),
        );

        const { foundationMasses } = await snapshot.getPromise(
          getSimpleFixedMassesSelectorFamily({
            turbinesWithSimpleFixed,
            rasterId,
          }),
        );

        const turbineTypes = await snapshot.getPromise(
          allSimpleTurbineTypesSelector,
        );
        const foundationTypes = await snapshot.getPromise(
          allFoundationTypesSelector,
        );
        const depthPerTurbine = turbines.map((turbine) =>
          getDepth(turbine, raster),
        );
        const data = turbinesWithFoundation.map((turbine, i) => {
          const foundationId = turbine.properties.foundationId;
          const turbineTypeId = turbine.properties.turbineTypeId;
          const foundationWeight = getIsFloatingFoundation(foundationId)
            ? getFloatingFoundationWeight(
                turbineTypeId,
                foundationId,
                floatingFoundationScaledStatistics,
              )
            : hasDetailedMonopile(turbine)
              ? getDetailedMonopileWeight(
                  turbine.id,
                  monopileStats.foundationDetails,
                )
              : getFixedSimpleWeight(turbine.id, foundationMasses);
          return {
            turbineName: turbine.properties.name ?? i + 1,
            turbineType: turbineTypes.find((tt) => tt.id === turbineTypeId),
            foundation: foundationId
              ? foundationTypes.find((ft) => ft.id === foundationId)
              : undefined,
            foundationWeight: foundationWeight,
            depth: depthPerTurbine?.[i],
            longitude: turbine.geometry.coordinates[0],
            latitude: turbine.geometry.coordinates[1],
          };
        });
        return data;
      },
    [
      bathymetry,
      park.id,
      park.geometry,
      hasDetailedMonopile,
      getIsFloatingFoundation,
    ],
  );

  return { getData, hasCustomBathymetry };
};

/** Replaces the argument with a `Skeleton` if it is `undefined`.
 *
 * When we're requeting for production numbers, we immediately get back some
 * numbers, but not all. The ones that aren't ready yet are given as
 * `undefined`.  This is so that we can show as much data as possible eary on,
 * without having to wait for the analysis.
 *
 * This function just renders these values "correctly", so that the loading ones
 * are shown as a loader, and the ready ones are shown as-is.
 */
const undefToSkeleton = <T,>(t: T | undefined): T | ReactNode =>
  isDefined(t) ? (
    t
  ) : (
    <SkeletonBlock style={{ height: "1rem", width: "100%" }} />
  );

const FoundationDetails = () => {
  const { projectId, park, branch } = useDashboardContext();

  const bathymetry = useBathymetry({
    projectId,
    branchId: branch.id,
    featureId: park.id,
  }).getValue();
  const parkId = park.id;

  const turbines: TurbineFeature[] = useRecoilValue(
    getTurbinesSortedByLabelSelectorFamily({ parkId }),
  );
  const validTurbines = turbines.filter((t) =>
    pointInPolygon(t.geometry, park.geometry),
  );

  const turbinesWithFoundation =
    validTurbines?.filter(isTurbineFeatureWithFoundation) ?? [];

  const allTurbineTypes = useRecoilValue(allSimpleTurbineTypesSelector);
  const allFoundationTypes = useRecoilValue(allFoundationTypesSelector);

  if (!bathymetry || bathymetry.status === "failed")
    return <div>Error when downloading bathymetry</div>;

  return (
    <FoundationListDetailsInner
      parkId={parkId}
      turbinesWithFoundation={turbinesWithFoundation}
      turbineTypes={allTurbineTypes}
      foundationTypes={allFoundationTypes}
      bathymetry={bathymetry}
    />
  );
};

const getFloatingFoundationWeight = (
  turbineTypeId: string,
  foundationId: string,
  scaledStatistics: FloatingFoundationScaledStatistics[],
): number | undefined => {
  const currentStatistics = scaledStatistics.find(
    (s) => s.foundationId === foundationId && s.turbineTypeId === turbineTypeId,
  );

  return currentStatistics?.scaledConcreteWeight &&
    currentStatistics?.scaledConcreteWeight > 0
    ? currentStatistics.scaledConcreteWeight
    : currentStatistics?.scaledPrimarySteelWeight;
};

const getDetailedMonopileWeight = (
  turbineId: string,
  monopileDesigns: MonopileDesign[],
): number => {
  const currentStatistics = monopileDesigns.find(
    (s) => s.turbineId === turbineId,
  );

  return (currentStatistics?.pileMass ?? 0) / 1000;
};

const getFixedSimpleWeight = (
  turbineId: string,
  foundationMasses:
    | {
        turbineId: string;
        turbineTypeId: string;
        foundationId: string;
        mass: number;
      }[]
    | undefined,
): number => {
  if (!foundationMasses) return 0;
  const currentStatistics = foundationMasses.find(
    (s) => s.turbineId === turbineId,
  );

  return (currentStatistics?.mass ?? 0) / 1000;
};

const FoundationListDetailsInner = ({
  parkId,
  turbinesWithFoundation,
  turbineTypes,
  foundationTypes,
  bathymetry,
}: {
  parkId: string;
  turbinesWithFoundation: TurbineFeatureWithFoundation[];
  turbineTypes: SimpleTurbineType[];
  foundationTypes: FoundationType[];
  bathymetry: TileResponseWithRaster;
}) => {
  const { branch } = useDashboardContext();
  const depthsPerTurbine = useMemo(() => {
    return turbinesWithFoundation.map((turbine) =>
      getDepth(turbine, bathymetry.raster),
    );
  }, [bathymetry, turbinesWithFoundation]);

  const hasDetailedMonopile = useRecoilValue(hasDetailedMonopileSelector);

  const getIsFloatingFoundation = useRecoilCallback(
    ({ snapshot }) =>
      (foundationId: string) => {
        return snapshot
          .getLoadable(
            isFloatingFoundationSelector({ foundationTypeId: foundationId }),
          )
          .getValue();
      },
    [],
  );

  const floatingFoundationScaledStatistics = useRecoilValue(
    getAllScaledStatisticsForFloatingFoundationsInPark(parkId),
  );

  const rasterId = bathymetry.id;
  const turbinesWithDetailedMonopile =
    turbinesWithFoundation.filter(hasDetailedMonopile);
  const turbinesWithSimpleFixed = turbinesWithFoundation.filter(
    (t) => !turbinesWithDetailedMonopile.includes(t),
  );

  const monopileStats = useRecoilValue(
    getDetailedMonopileMassesSelectorFamily({
      turbinesWithDetailedMonopile,
      rasterId,
    }),
  );

  const { foundationMasses: fixedSimpleMasses } = useRecoilValue(
    getSimpleFixedMassesSelectorFamily({
      turbinesWithSimpleFixed,
      rasterId,
    }),
  );
  const invalidTypes = useRecoilValue(
    getInvalidTypesInParkAndBranch({ parkId, branchId: branch.id }),
  );

  const items = useMemo(
    () =>
      turbinesWithFoundation.map((turbine, i) => {
        const foundationId: string = turbine.properties.foundationId;
        const turbineTypeId = turbine.properties.turbineTypeId;
        const foundationWeight = getIsFloatingFoundation(foundationId)
          ? getFloatingFoundationWeight(
              turbineTypeId,
              foundationId,
              floatingFoundationScaledStatistics,
            )
          : hasDetailedMonopile(turbine)
            ? getDetailedMonopileWeight(
                turbine.id,
                monopileStats.foundationDetails,
              )
            : getFixedSimpleWeight(turbine.id, fixedSimpleMasses);
        return {
          turbine,
          turbineType: turbineTypes.find((tt) => tt.id === turbineTypeId)?.name,
          foundation: foundationId
            ? foundationTypes.find((ft) => ft.id === foundationId)?.name
            : "-",
          weight: undefMap(
            foundationWeight,
            (w) => `${valueRounding(w, 10).toLocaleString()} t`,
          ),
          depth: undefMap(
            depthsPerTurbine?.at(i),
            (d) => `${(-d).toFixed(0)} m`,
          ),
          longitude: turbine.geometry.coordinates[0].toFixed(4),
          latitude: turbine.geometry.coordinates[1].toFixed(4),
        };
      }),
    [
      turbinesWithFoundation,
      getIsFloatingFoundation,
      floatingFoundationScaledStatistics,
      hasDetailedMonopile,
      monopileStats.foundationDetails,
      fixedSimpleMasses,
      turbineTypes,
      foundationTypes,
      depthsPerTurbine,
    ],
  );
  if (items.length === 0) {
    return (
      <CenterContainer>
        <SimpleAlert text={"No foundations in the park."} type={"error"} />
      </CenterContainer>
    );
  }
  const relevantInvalidTypes = invalidTypes.filter(
    (t) =>
      t.type === InvalidTypes.FoundationTypeInvalid ||
      t.type === InvalidTypes.TurbineTypeInvalid,
  );

  if (relevantInvalidTypes.length > 0)
    return (
      <CenterContainer style={{ margin: "3rem" }}>
        <InvalidTypesDisplayText invalidTypes={relevantInvalidTypes} />
      </CenterContainer>
    );

  return (
    <Table>
      <thead>
        <tr>
          <th>Foundation</th>
          <th>Turbine type</th>
          <th>Foundation weight</th>
          <th>Depth</th>
          <th>Longitude</th>
          <th>Latitude</th>
        </tr>
      </thead>
      <tbody>
        {items.map(
          ({
            turbine,
            turbineType,
            foundation,
            weight,
            depth,
            longitude,
            latitude,
          }) => (
            <tr key={turbine.id}>
              <td>{undefToSkeleton(foundation)}</td>
              <td>{undefToSkeleton(turbineType)}</td>
              <td>{undefToSkeleton(weight)}</td>
              <td>{undefToSkeleton(depth)}</td>
              <td>{undefToSkeleton(longitude)}</td>
              <td>{undefToSkeleton(latitude)}</td>
            </tr>
          ),
        )}
      </tbody>
    </Table>
  );
};

const delimiter = ",";
const FoundationMenu = () => {
  const { error, warning } = useToast();
  const { hasCustomBathymetry, getData } = useFoundationData();
  const { park } = useDashboardContext();

  const downloadCSV = useCallback(async () => {
    const data = await getData();
    if (!data) {
      error("Cannot download turbine data: missing bathymetry data.");
      return;
    }

    const depthDecimals = hasCustomBathymetry ? 1 : 0;

    const isLoading =
      data.find((d) => d.depth === undefined) ||
      data.find((d) => d.foundationWeight === undefined);

    if (isLoading) {
      warning(
        "Data is still loading, please wait a few seconds and try again.",
      );
      return;
    }

    const fields = [
      "turbineName",
      "turbineType",
      "foundation",
      "foundationWeight",
      "depth",
      "longitude",
      "latitude",
    ];

    const headerNames: Record<(typeof fields)[number], string> = {
      turbineName: "Turbine name",
      turbineType: "Turbine type",
      foundation: "Foundation",
      foundationWeight: "Foundation weight (t)",
      depth: "Depth (m)",
      longitude: "Longitude",
      latitude: "Latitude",
    };

    const formatted: Record<
      (typeof fields)[number],
      string | number | undefined
    >[] = data.map((d) => ({
      turbineName: `#${d.turbineName}`,
      turbineType: d.turbineType?.name ?? "-",
      foundation: d.foundation?.name,
      foundationWeight: undefMap(d.foundationWeight, (w) =>
        valueRounding(w, 10),
      ),
      depth: undefMap(d.depth, (d) => roundToDecimal(-d, depthDecimals)),
      longitude: d.longitude.toFixed(4),
      latitude: d.latitude.toFixed(4),
    }));

    let csv = "#";
    for (const f of fields.slice(1)) {
      csv += `${delimiter}${headerNames[f]}`;
    }

    for (const d of formatted) {
      csv += "\n" + String(d.turbineName).replace(delimiter, "");
      for (const f of fields.slice(1)) {
        csv += `${delimiter}${d[f]}`;
      }
    }

    downloadText(
      csv,
      `foundations-${park.properties.name}-${new Date().toISOString()}.csv`,
    );
  }, [error, getData, hasCustomBathymetry, park.properties.name, warning]);

  return (
    <>
      <MenuItem
        name={"Download as .csv"}
        icon={<DownloadIcon />}
        onClick={() => downloadCSV()}
      />
    </>
  );
};

export const FoundationListWidget = () => (
  <SafeCard title="Foundations" id="Foundations" menuItems={<FoundationMenu />}>
    <FoundationDetails />
  </SafeCard>
);
