import {
  SnapFeature,
  SnapPoint,
} from "../components/MapControls/CustomModes/lib/snapping";
import {
  Feature,
  Geometry,
  GeometryCollection,
  LineString,
  MultiLineString,
  MultiPoint,
  MultiPolygon,
  Point,
  Polygon,
} from "geojson";
import {
  DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
  SUB_AREA_PROPERTY_TYPE,
} from "../constants/division";
import { PARK_PROPERTY_TYPE } from "../constants/park";
import {
  BathymetryUserUploadedType,
  GeoTiffUserUploadedImageType,
} from "../services/types";
import {
  _OtherFeature,
  ExistingTurbineFeature,
  MooringLineMultipleFeature,
  ProjectFeature,
} from "../types/feature";
import { ParkFeature } from "../types/feature";
import {
  CABLE_CHAIN_POLYGON_PROPERTY_TYPE,
  CABLE_CORRIDOR_PROPERTY_TYPE,
  CABLE_PARTITION_POLYGON_PROPERTY_TYPE,
  CABLE_PROPERTY_TYPE,
  EXPORT_CABLE_PROPERTY_TYPE,
  SUBSTATION_PROPERTY_TYPE,
} from "../constants/cabling";
import {
  ANCHOR_PROPERTY_TYPE,
  MOORING_LINE_PROPERTY_TYPE,
  SLACK_REGION_PROPERTY_TYPE,
  TURBINE_PROPERTY_TYPE,
  PORT_POINT_TYPE,
  GRID_CONNECTION_POINT_TYPE,
  EXISTING_TURBINE_PROPERTY_TYPE,
} from "../constants/projectMapView";
import { VIEW_POINT_TYPE } from "../constants/viewPoint";
import { lockedPropertyName } from "../constants/canvas";
import {
  AnchorFeature,
  BathymetryFeature,
  CableChainFeature,
  CableCorridorFeature,
  CableFeature,
  CablePartitionFeature,
  DivisionFeature,
  ExclusionZoneFeature,
  ExportCableFeature,
  GeotiffFeature,
  SubAreaFeature,
  MooringLineFeature,
  SlackRegionFeature,
  SubstationFeature,
  TurbineFeature,
  ViewpointFeature,
} from "../types/feature";
import {
  GroupOrgResource,
  OrgResource,
  PersonalOrgResource,
} from "components/Organisation/OrganisationRightSide/types";
import { z } from "zod";

/** Utility for type narrowing to filter out undefined objects. */
export function isDefined<T>(t: T | undefined): t is T {
  return t !== undefined;
}

/**
 * Similar to {@link isDefined} but also checks for `null`.
 */
export const notUndefinedOrNull = <T>(t: T | undefined | null): t is T =>
  t != null;

/**
 * Returns `false` for `NaN`.
 */
export const isNumber = (t: unknown | undefined): t is number =>
  isDefined(t) && typeof t === "number" && !isNaN(t);

export const isPointGeometry = <G extends Geometry, PG extends Point & G>(
  f: G,
): f is PG => {
  return f.type === "Point";
};

export const isLineStringGeometry = <
  G extends Geometry,
  PG extends LineString & G,
>(
  f: G,
): f is PG => {
  return f.type === "LineString";
};

export function isPointFeature<
  F extends Feature<Geometry>,
  PF extends Feature<Point> & F,
>(f: F | undefined): f is PF;
export function isPointFeature<
  F extends ProjectFeature<Geometry>,
  PF extends ProjectFeature<Point> & F,
>(f: F | undefined): f is PF {
  return f?.geometry?.type === "Point";
}

export function isLineStringFeature<
  F extends Feature<Geometry>,
  PF extends Feature<LineString> & F,
>(f: F | undefined): f is PF;
export function isLineStringFeature<
  F extends ProjectFeature<Geometry>,
  PF extends ProjectFeature<LineString> & F,
>(f: F | undefined): f is PF {
  return f?.geometry?.type === "LineString";
}

export function isPolygonFeature<
  F extends Feature<Geometry>,
  PF extends Feature<Polygon> & F,
>(f: F | undefined): f is PF;
export function isPolygonFeature<
  F extends ProjectFeature<Geometry>,
  PF extends ProjectFeature<Polygon> & F,
>(f: F | undefined): f is PF {
  return f?.geometry?.type === "Polygon";
}

export function isMultiPolygonFeature<
  F extends Feature<Geometry>,
  MPF extends Feature<MultiPolygon> & F,
>(f: F): f is MPF;
export function isMultiPolygonFeature<
  F extends ProjectFeature<Geometry>,
  MPF extends ProjectFeature<MultiPolygon> & F,
>(f: F): f is MPF {
  return f.geometry.type === "MultiPolygon";
}

export const isMultiFeature = <
  F extends Feature<Geometry>,
  MF extends Feature<MultiPoint | MultiLineString | MultiPolygon> & F,
>(
  f: F,
): f is MF => {
  return f.geometry.type.includes("Multi");
};

export const isNotGeometryCollection = <
  F extends Feature<Geometry>,
  MF extends Feature<Exclude<Geometry, GeometryCollection>> & F,
>(
  f: F,
): f is MF => f?.geometry?.type !== "GeometryCollection";

export const isFeature = (f: any): f is ProjectFeature => {
  return _OtherFeature
    .merge(
      z.object({
        properties: z.any(),
      }),
    )
    .safeParse(f).success;
};

export const isPark = (f: ProjectFeature | undefined): f is ParkFeature =>
  f?.properties?.type === PARK_PROPERTY_TYPE;

export const isDivision = (
  f: ProjectFeature | undefined,
): f is DivisionFeature =>
  f?.properties?.type === DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE ||
  f?.properties?.type === SUB_AREA_PROPERTY_TYPE;

export const isSubArea = (f: ProjectFeature | undefined): f is SubAreaFeature =>
  f?.properties?.type === SUB_AREA_PROPERTY_TYPE;

export const isExclusionDivision = (
  f: ProjectFeature | undefined,
): f is ExclusionZoneFeature =>
  f?.properties?.type === DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE;

export const isCable = (f: ProjectFeature | undefined): f is CableFeature =>
  f?.properties?.type === CABLE_PROPERTY_TYPE;

export const isExportCable = (
  f: ProjectFeature | undefined,
): f is ExportCableFeature =>
  f?.properties?.type === EXPORT_CABLE_PROPERTY_TYPE;

export const isCableCorridor = (
  f: ProjectFeature | undefined,
): f is CableCorridorFeature =>
  f?.properties?.type === CABLE_CORRIDOR_PROPERTY_TYPE;

export const isTurbine = (f: ProjectFeature | undefined): f is TurbineFeature =>
  f?.properties?.type === TURBINE_PROPERTY_TYPE;

export const isSubstation = (
  f: ProjectFeature | undefined,
): f is SubstationFeature => f?.properties?.type === SUBSTATION_PROPERTY_TYPE;

export const isExistingTurbine = (
  f: ProjectFeature | undefined,
): f is ExistingTurbineFeature =>
  f?.properties?.type === EXISTING_TURBINE_PROPERTY_TYPE;

export const isViewPoint = (
  f: ProjectFeature | undefined,
): f is ViewpointFeature => f?.properties?.type === VIEW_POINT_TYPE;

export const isAnchor = (f: ProjectFeature | undefined): f is AnchorFeature =>
  f?.properties?.type === ANCHOR_PROPERTY_TYPE;

export const isMooringLine = (
  f: ProjectFeature | undefined,
): f is MooringLineFeature =>
  f?.properties?.type === MOORING_LINE_PROPERTY_TYPE;

export const isMooringLineMultiple = (
  f: ProjectFeature | undefined,
): f is MooringLineMultipleFeature =>
  isMooringLine(f) && "lineLengths" in f.properties;

export const isSlackRegion = (
  f: ProjectFeature | undefined,
): f is SlackRegionFeature =>
  f?.properties?.type === SLACK_REGION_PROPERTY_TYPE;

export const isCableSnapPoint = (f: SnapFeature | undefined): f is SnapPoint =>
  f?.type === "Point" &&
  [TURBINE_PROPERTY_TYPE, SUBSTATION_PROPERTY_TYPE].includes(
    f.featureType ?? "",
  );
export const isMooringLineSnapPoint = (
  f: SnapFeature | undefined,
): f is SnapPoint =>
  f?.type === "Point" &&
  [TURBINE_PROPERTY_TYPE, ANCHOR_PROPERTY_TYPE].includes(f.featureType ?? "");

export const isCablePartition = (
  f: ProjectFeature | undefined,
): f is CablePartitionFeature =>
  f?.properties?.type === CABLE_PARTITION_POLYGON_PROPERTY_TYPE;

export const isCableChain = (
  f: ProjectFeature | undefined,
): f is CableChainFeature =>
  f?.properties?.type === CABLE_CHAIN_POLYGON_PROPERTY_TYPE;

export const isGeoereferencedImage = (
  f: ProjectFeature | undefined,
): f is GeotiffFeature => f?.properties?.type === GeoTiffUserUploadedImageType;

export const isBathymetry = (
  f: ProjectFeature | undefined,
): f is BathymetryFeature => f?.properties?.type === BathymetryUserUploadedType;

export const isGeotiff = (f: ProjectFeature | undefined): f is GeotiffFeature =>
  f?.properties?.type === GeoTiffUserUploadedImageType;

export const isPort = (f: ProjectFeature | undefined): f is ProjectFeature =>
  f?.properties?.type === PORT_POINT_TYPE;

export const isGridConnection = (
  f: ProjectFeature | undefined,
): f is ProjectFeature => f?.properties?.type === GRID_CONNECTION_POINT_TYPE;

/**
 * "Derived" features are not stored in the backend but computed in selectors.
 * This means that while they are features, their `id` will be recomputed at a whim,
 * and so some features, like comments, doesn't work.
 */
export const featureIsDerived = (f: ProjectFeature): boolean =>
  isCablePartition(f) || isCableChain(f);

export const featureIsLocked = (
  f: ProjectFeature | Feature | undefined,
): boolean => Boolean(f?.properties?.[lockedPropertyName]);

export const isPersonalOrgResource = (
  f: OrgResource | undefined,
): f is PersonalOrgResource => f?.type === "personal";

export const isGroupOrgResource = (
  f: OrgResource | undefined,
): f is GroupOrgResource => f?.type === "group";
