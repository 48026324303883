/// <reference types="vite-plugin-svgr/client" />
import { colors } from "../../styles/colors";
import { MenuItem, MenuDivider } from "../General/Menu";
import { DotMenu } from "../General/MenuButton";

import BinIcon from "@icons/24/Bin.svg?react";
import MoveBackIcon from "@icons/24/MoveBack.svg?react";
import FolderIcon from "@icons/24/Folder.svg?react";
import PeopleIcon from "@icons/24/TeamMeeting.svg?react";
import StarIcon from "@icons/24/Star.svg?react";
import InfoIcon from "@icons/24/Information.svg?react";
import PencilIcon from "@icons/24/Pencil.svg?react";
import { useCallback, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { followProjectsAtom } from "../../state/project";
import { useFollowProjectCrud } from "./useFollowProjectCrud";
import {
  editorAccessCustomerSelector,
  memberInOrganisationSelectorFamily,
  userNodeAccessSelectorFamily,
} from "../../state/user";
import { useTypedPath } from "../../state/pathParams";
import { Node } from "../../services/customerAPI";
import {
  OrganisationNodeCrud,
  topLevelNodeFromOrgIdAndNodeIdSelectorFamily,
} from "./useOrganisationFolderCrud";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";

export const MenuItemNestedNodes = ({
  currentFolderId,
  folderTree,
  base = "",
  clickOnFolder,
}: {
  currentFolderId?: string;
  folderTree: Map<string, Node[]>;
  base?: string;
  clickOnFolder: (folder: Node) => Promise<void>;
}) => {
  const sub = folderTree.get(base);
  if (!sub) return null;

  return (
    <>
      {sub.map((f) => (
        <MenuItem
          icon={<FolderIcon />}
          key={f.id}
          name={f.name}
          onClick={async () => {
            await clickOnFolder(f);
          }}
        >
          {folderTree.has(f.id) && (
            <>
              <MenuItemNestedNodes
                currentFolderId={currentFolderId}
                folderTree={folderTree}
                base={f.id}
                clickOnFolder={clickOnFolder}
              />
            </>
          )}
        </MenuItem>
      ))}
    </>
  );
};

export const FolderDotMenu = ({
  folderId,
  currentFolder,
  nodes,
  crudNode,
  setIsEditingTitle,
  nestedDirection = "right",
}: {
  folderId: string;
  currentFolder: Node | undefined;
  nodes: Node[];
  crudNode: OrganisationNodeCrud;
  setIsEditingTitle: (value: boolean) => void;
  nestedDirection?: Parameters<typeof MenuItem>[0]["direction"];
}) => {
  const { organisationId } = useTypedPath("organisationId");

  const setContent = useSetRecoilState(
    organisationRightSideModal(organisationId),
  );

  const toplevelNode = useRecoilValue(
    topLevelNodeFromOrgIdAndNodeIdSelectorFamily({
      organisationId,
      nodeId: folderId,
    }),
  );

  const nodeAccess = useRecoilValue(
    userNodeAccessSelectorFamily({ nodeId: folderId }),
  );
  const isNodeAdmin = nodeAccess >= 2;

  const isCustomerEditor = useRecoilValue(editorAccessCustomerSelector);
  const isMemberInOrg = useRecoilValue(
    memberInOrganisationSelectorFamily({ organisationId }),
  );

  const { put: putFollow } = useFollowProjectCrud();

  const follows = useRecoilValue(followProjectsAtom);
  const isFollowed = useMemo(
    () => follows.some((f) => f.nodeId === folderId && f.follow),
    [follows, folderId],
  );

  const deleteProjectCallback = useCallback(() => {
    if (!window.confirm("Are you sure you want to delete this project?"))
      return;
    crudNode.remove(folderId);
  }, [crudNode, folderId]);

  const folderTree = useMemo(() => {
    const tree = new Map<string, Node[]>();
    const setMapForParentId = (parentId: string, nodes: Node[]) => {
      const children = nodes.filter(
        (n) => n.parent_id === parentId && n.type === "folder",
      );
      if (children.length === 0) return;
      tree.set(parentId, children);
      children.forEach((n) => setMapForParentId(n.id, nodes));
    };
    setMapForParentId(toplevelNode?.id ?? "", nodes);
    return tree;
  }, [toplevelNode, nodes]);

  const moveProjectToFolder = useCallback(
    async (folder?: Node) => {
      if (currentFolder?.id === folder?.id) return;
      const promises: ReturnType<typeof crudNode.move>[] = [];

      if (folder) promises.push(crudNode.move(folderId, folder.id));

      await Promise.all(promises);
    },
    [crudNode, currentFolder?.id, folderId],
  );

  const chosenProjectHasPersonalTopFolder =
    toplevelNode?.type === "personal_folder";

  return (
    <DotMenu size="medium">
      {isMemberInOrg && (
        <MenuItem
          name="Folder information"
          icon={<InfoIcon />}
          onClick={() => {
            setContent({ type: "project", id: folderId });
          }}
        />
      )}

      {isNodeAdmin && !chosenProjectHasPersonalTopFolder && (
        <>
          <MenuItem
            name="Share project"
            icon={<PeopleIcon />}
            onClick={() => {
              setContent({
                type: "project",
                id: folderId,
                meta: {
                  openAddCollaborators: true,
                },
              });
            }}
          />
        </>
      )}
      {isCustomerEditor && (
        <>
          <MenuItem
            name="Move"
            icon={<FolderIcon />}
            direction={nestedDirection}
          >
            <MenuItemNestedNodes
              currentFolderId={toplevelNode?.id ?? ""}
              folderTree={folderTree}
              base={toplevelNode?.id ?? ""}
              clickOnFolder={moveProjectToFolder}
            />
            <MenuDivider />
            {currentFolder !== undefined && toplevelNode && (
              <MenuItem
                icon={<MoveBackIcon />}
                name={`Move to ${toplevelNode.name}`}
                onClick={async () => crudNode.move(folderId, toplevelNode.id)}
              />
            )}
          </MenuItem>
          <MenuItem
            name="Rename"
            icon={<PencilIcon />}
            onClick={() => {
              setIsEditingTitle(true);
            }}
          />
          <MenuItem
            name={isFollowed ? "Unstar" : "Star"}
            icon={
              <StarIcon
                stroke={isFollowed ? colors.surfaceBrand : colors.surfaceBrand}
                fill={isFollowed ? colors.surfaceBrand : "transparent"}
              />
            }
            onClick={() => {
              putFollow(folderId, !isFollowed);
            }}
          />
          <MenuItem
            name="Delete project"
            icon={<BinIcon />}
            onClick={() => {
              deleteProjectCallback();
            }}
          />
        </>
      )}
    </DotMenu>
  );
};
