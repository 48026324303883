import { isDefined } from "../../utils/predicates";
import {
  sqsUnicodeStringIsOkay,
  sqsUnicodeStringFilter,
} from "../../utils/utils";
import { findUuid4sInString } from "../../utils/geojson/utils";
import { _FeatureWithAnyProperties } from "../../types/feature";
import { FeatureWithAnyProperties } from "../../types/feature";
import { ProjectFeature } from "../../types/feature";
import { _FeatureParser } from "../../types/feature";

export const neededShpFileSuffixes = ["shx", "dbf", "prj"];
export const extraShpFileSuffixes = ["cpg", "idx", "sbn", "qmd", "sbx"];

export const shpSupportFiles = [
  ...neededShpFileSuffixes,
  ...extraShpFileSuffixes,
].map((suffix) => `.${suffix}`);

export function cleanGeojsonFeatures(features: unknown[]): {
  cleaned: ProjectFeature[];
  cleanedWithAnyProperties: FeatureWithAnyProperties[];
  shapefileMissingEncoding: boolean;
} {
  let shapefileMissingEncoding = false;
  const cleanedButNotParsed = features
    .filter((u): u is NonNullable<object> => typeof u === "object" && u != null)
    .map((f) => ({
      ...f,
      properties: Object.fromEntries(
        Object.entries((f as any)["properties"] ?? {})
          .filter(([, v]) => {
            return !(typeof v === "number" && isNaN(v));
          })
          .filter(isDefined)
          .map(([key, v]) => {
            if (key === "parentIds") {
              // had a case were parentIds was on the format => (1:116b727d-cba8-408f-b290-aebc284d8926).
              // features does not work so this is just a hack to make it work
              if (typeof v === "string") {
                const parentIds = findUuid4sInString(v);
                if (parentIds) {
                  return [key, parentIds];
                }
                return undefined;
              }
              return [key, Array.isArray(v) ? v : [v]];
            }
            if (typeof v === "string") {
              // If we have seen the replacement character it (probably) means that decoding utf-8 failed.
              const replacementChar = "\ufffd";
              let safeVal = v;
              if (v.includes(replacementChar) || !sqsUnicodeStringIsOkay(v)) {
                shapefileMissingEncoding = true;
                const sqsSafe = sqsUnicodeStringFilter(v);
                safeVal = sqsSafe.replace(/[^\x20-\x7E]+/g, "");
              }

              return [key, safeVal];
            }
            return [key, v];
          })
          .filter(isDefined),
      ),
    }));

  const cleaned = cleanedButNotParsed.flatMap((f) => {
    const res = _FeatureParser.safeParse(f);
    return res.success ? [res.data] : [];
  });

  const cleanedWithAnyProperties = cleanedButNotParsed.flatMap((f) => {
    const res = _FeatureWithAnyProperties.safeParse(f);
    return res.success ? [res.data] : [];
  });

  return {
    cleaned,
    cleanedWithAnyProperties,
    shapefileMissingEncoding,
  };
}

export function readFileAsTextOrArrayBuffer(
  file: File,
  type: "text",
): Promise<string>;
export function readFileAsTextOrArrayBuffer(
  file: File,
  type: "other",
): Promise<ArrayBuffer>;
export function readFileAsTextOrArrayBuffer(
  file: File,
  type: "text" | "other",
): Promise<string | ArrayBuffer> {
  const reader = new FileReader();
  const promise = new Promise<string | ArrayBuffer>((resolve, reject) => {
    reader.onload = async (event) => {
      if (!event.target?.result) {
        return reject("Target is undefined");
      }

      resolve(event.target.result);
    };
    reader.onerror = reject;
  });

  if (type === "text") {
    reader.readAsText(file);
  } else {
    reader.readAsArrayBuffer(file);
  }
  return promise;
}
