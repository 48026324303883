import { CenterContainer, SafeCard } from "./Base";
import { FlexGrid2 } from "../../General/Form";
import { Row } from "../../General/Layout";
import { spaceMedium } from "../../../styles/space";
import { useDashboardContext } from "../Dashboard";
import { useRecoilValueLoadable } from "recoil";
import {
  getStoppedReason,
  getStoppedReasonFromAnalysis,
} from "components/ProductionV2/state";
import {
  analysisStoppedText,
  AnalysisStoppedTypes,
} from "components/ProductionV2/types";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { formatPercent } from "components/ProductionV2/format";

const InputDetails = () => {
  const { costConfiguration, triggerId } = useDashboardContext();

  const stoppedReason = useRecoilValueLoadable(
    getStoppedReason(triggerId),
  ).valueMaybe();

  const analysisStoppedReason = useRecoilValueLoadable(
    getStoppedReasonFromAnalysis(triggerId),
  ).valueMaybe();

  if (stoppedReason !== undefined) {
    return (
      <CenterContainer>
        <SimpleAlert text={analysisStoppedText[stoppedReason]} type={"error"} />
      </CenterContainer>
    );
  }
  if (analysisStoppedReason) {
    return (
      <CenterContainer>
        <SimpleAlert
          text={
            analysisStoppedText[analysisStoppedReason as AnalysisStoppedTypes]
          }
          type={"error"}
        />
      </CenterContainer>
    );
  }

  return (
    <FlexGrid2 style={{ padding: spaceMedium }}>
      <h4>Project lifetime</h4>
      <Row>
        <p>Project start</p>
        <p>{costConfiguration?.lifeCycle.projectStart}</p>
      </Row>
      <Row>
        <p>Operation start</p>
        <p>{costConfiguration?.lifeCycle.operationStart}</p>
      </Row>
      <Row>
        <p>Decomissioning</p>
        <p>{costConfiguration?.lifeCycle.decomissioning}</p>
      </Row>
      <h4>Contingency</h4>
      <Row>
        <p>Capex</p>
        <p>
          {costConfiguration
            ? formatPercent(costConfiguration.contingency.capex.value)
            : "-"}
        </p>
      </Row>

      <h4>LCoE rates</h4>
      <Row>
        <p>Discount rate</p>
        <p>
          {costConfiguration
            ? formatPercent(costConfiguration.lcoe.discountRate)
            : "-"}
        </p>
      </Row>
      <Row>
        <p>Inflation rate</p>
        <p>
          {costConfiguration
            ? formatPercent(costConfiguration.inflation.inflationRate)
            : "-"}
        </p>
      </Row>

      <h4>Cash flow</h4>
      <Row>
        <p>Revenue reference year</p>
        <p>{costConfiguration?.inflation.referenceYearRevenue}</p>
      </Row>

      <Row>
        <p>Guaranteed price</p>
        <p>
          {costConfiguration?.cashFlows.guaranteedPrice.cost}{" "}
          {costConfiguration?.cashFlows.guaranteedPrice.unit}
        </p>
      </Row>

      {costConfiguration?.lifeCycle.operationStart &&
        costConfiguration?.cashFlows.guaranteedYears && (
          <Row>
            <p>Guaranteed price years</p>
            <p>
              {costConfiguration?.lifeCycle.operationStart} -{" "}
              {costConfiguration?.lifeCycle.operationStart +
                costConfiguration?.cashFlows.guaranteedYears}
            </p>
          </Row>
        )}

      <Row>
        <p>Market price</p>
        <p>
          {costConfiguration?.cashFlows.marketPrice.cost}{" "}
          {costConfiguration?.cashFlows.marketPrice.unit}
        </p>
      </Row>
    </FlexGrid2>
  );
};

export const CostInputWidget = () => (
  <SafeCard title="Cost input" id="Cost input">
    <InputDetails />
  </SafeCard>
);
