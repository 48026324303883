import { otherUsersSelectionArrayAtomFamily } from "./../state/selection";
import { ABLY_FEATURE_SELECTION } from "../state/ably";
import { useCallback, useEffect, useState } from "react";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { ablyLoadedState } from "../state/ably";
import { Realtime } from "ably";
import { loggedInUserSelector } from "../state/user";
import { getCurrentTokenUserId } from "../state/global";
import { initializeAndSet } from "../components/Comments/hooks/useReplyReactionCrud";
import { currentSelectionArrayAtom } from "../state/selection";
import { SelectionType } from "../services/types";
import usePrevious from "./usePrevious";
import { areArraysEqualSets } from "../utils/utils";
import { branchIdSelector } from "../state/pathParams";

export function useAblySelection(projectId: string) {
  const ablyLoaded = useRecoilValue(ablyLoadedState);
  const branchId = useRecoilValue(branchIdSelector);
  const currentUserId = useRecoilValue(loggedInUserSelector)?.user_id;
  const currentSelection = useRecoilValue(currentSelectionArrayAtom);
  const previousSelection = usePrevious(currentSelection);

  const [initialSync, setInitialSync] = useState(false);

  const onSelectionChange = useCallback(
    (selection: SelectionType[]) => {
      if (!ablyLoaded) return;
      setInitialSync(true);

      const channel = ablyLoaded.channels.get(`${projectId}:all`);
      channel.publish(ABLY_FEATURE_SELECTION, {
        userId: currentUserId,
        selection: selection,
        source: { projectId, branchId },
      });
    },
    [ablyLoaded, branchId, currentUserId, projectId],
  );

  useEffect(() => {
    if (!initialSync) {
      onSelectionChange(currentSelection);
    } else if (
      previousSelection &&
      !areArraysEqualSets(previousSelection, currentSelection)
    ) {
      onSelectionChange(currentSelection);
    }
  }, [currentSelection, initialSync, onSelectionChange, previousSelection]);

  const updateOtherUsersSelectionState = useRecoilCallback(
    ({ set, snapshot }) =>
      async (
        source: { projectId: string; branchId: string },
        userId: string,
        selection: SelectionType[],
      ) => {
        initializeAndSet(
          snapshot,
          set,
          otherUsersSelectionArrayAtomFamily(source),
          (cur) => {
            const withoutUser = cur.filter((s) => s.userId !== userId);
            return selection.length === 0
              ? withoutUser
              : [...withoutUser, { userId, selection }];
          },
        );
      },
    [],
  );

  const onMount = useCallback(
    async (channelName: string, ablyRealtime: Realtime) => {
      const channel = ablyRealtime.channels.get(channelName);
      channel.subscribe(ABLY_FEATURE_SELECTION, (message) => {
        const { userId, selection, source } = message.data;
        if (userId === getCurrentTokenUserId()) return;
        updateOtherUsersSelectionState(source, userId, selection);
      });
    },
    [updateOtherUsersSelectionState],
  );

  const onUnmount = useCallback(
    (channelName: string, ablyRealtime: Realtime) => {
      const channel = ablyRealtime.channels.get(channelName);
      onSelectionChange([]);
      channel.unsubscribe(ABLY_FEATURE_SELECTION);
    },
    [onSelectionChange],
  );

  useEffect(() => {
    if (!ablyLoaded) return;
    onMount(`${projectId}:all`, ablyLoaded);
    return () => {
      onUnmount(`${projectId}:all`, ablyLoaded);
    };
  }, [ablyLoaded, projectId, onMount, onUnmount]);
}
