import styled from "styled-components";
import { StandardBox } from "../../styles/boxes/Boxes";
import { spaceSmall } from "styles/space";

export const EllipsesSelectorWrapper = styled.div`
  position: relative;
  display: flex;
`;

export const HeaderTitle = styled.h3`
  margin: 0;
`;

export const EllipsisContainer = styled(StandardBox)<{
  position?: "left" | "right";
}>`
  position: absolute;
  ${(p) =>
    (p.position ?? "right") === "right" ? { left: "0" } : { right: "0" }}
  margin-top: ${(p) => (p.position === "left" ? "4.4rem" : "0rem")};
  padding: 2rem 1.6rem;
  gap: 0.8rem;
  width: 30rem;
`;

export const RowItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: ${spaceSmall};
`;
