import React, { useCallback, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import mapboxgl, { MapLayerMouseEvent } from "mapbox-gl";
import { modalTypeOpenAtom } from "state/modal";
import { clickInMapModeStateAtom, ClickInMapMode } from "state/clickInMapMode";
import { ProjectFeature } from "types/feature";
import { clickHandlerAtom } from "components/Mapbox/state";
import { projectFeatureMap } from "state/projectLayers";
import { getSmallestFeature } from "components/Mapbox/MapboxSyncEffects";
import { mapRefAtom } from "state/map";
import { SelectOverlapBufferModalType } from "../SelectOverlapBufferModal/SelectOverlapBufferModal";
import {
  activeFiltersAtom,
  showSelectFeatureForOverlapHintAtom,
} from "./layer-filter-state";
import { AddDataLayersModalType } from "./DataLayersModal";

const useSelectFeatureForOverlap = () => {
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const setActiveFilters = useSetRecoilState(activeFiltersAtom);
  const setMapMode = useSetRecoilState(clickInMapModeStateAtom);
  const setShowSelectFeatureForOverlapHint = useSetRecoilState(
    showSelectFeatureForOverlapHintAtom,
  );

  const setClickHandler = useSetRecoilState(clickHandlerAtom);
  const featureMap = useRecoilValue(projectFeatureMap);
  const map = useRecoilValue(mapRefAtom);

  const onEscape = useCallback(
    (e: KeyboardEvent) => {
      if (e.key !== "Escape") {
        return;
      }
      e.stopPropagation();

      setMapMode(ClickInMapMode.SELECT_FEATURE);
      setClickHandler(undefined);
      setModalTypeOpen({
        modalType: AddDataLayersModalType,
      });
      setShowSelectFeatureForOverlapHint(false);
      document.removeEventListener("keydown", onEscape);
    },
    [
      setClickHandler,
      setMapMode,
      setModalTypeOpen,
      setShowSelectFeatureForOverlapHint,
    ],
  );

  const onClickFeatureForFeatureOverlap = useCallback(
    ([feature]: (mapboxgl.MapboxGeoJSONFeature | ProjectFeature)[]) => {
      setModalTypeOpen({
        modalType: SelectOverlapBufferModalType,
        metadata: {
          featureId: String(feature.id),
        },
      });
    },
    [setModalTypeOpen],
  );

  const activateSelectFeatureForOverlap = useCallback(() => {
    setModalTypeOpen(undefined);
    setShowSelectFeatureForOverlapHint(true);
    setClickHandler(() => (e: MapLayerMouseEvent) => {
      if (!map) return;
      const features = getSmallestFeature(e, map, featureMap);
      if (features.length === 0) {
        return;
      }
      onClickFeatureForFeatureOverlap(features);
    });

    document.addEventListener("keydown", onEscape);
  }, [
    setModalTypeOpen,
    setShowSelectFeatureForOverlapHint,
    setClickHandler,
    onEscape,
    map,
    featureMap,
    onClickFeatureForFeatureOverlap,
  ]);

  const setFeatureForFeatureOverlap = useCallback(
    (feature: ProjectFeature) => {
      setActiveFilters((curr) => ({
        ...curr,
        overlappingGeometry: feature.geometry,
      }));

      setModalTypeOpen({
        modalType: AddDataLayersModalType,
      });
      setMapMode(ClickInMapMode.SELECT_FEATURE);
      setClickHandler(undefined);
      setShowSelectFeatureForOverlapHint(false);
      document.removeEventListener("keydown", onEscape);
    },
    [
      onEscape,
      setActiveFilters,
      setClickHandler,
      setMapMode,
      setModalTypeOpen,
      setShowSelectFeatureForOverlapHint,
    ],
  );

  const onUnmount = useCallback(() => {
    document.removeEventListener("keydown", onEscape);
    setMapMode((currVal) => {
      if (currVal === ClickInMapMode.SELECT_FEATURE_FOR_OVERLAP) {
        return ClickInMapMode.SELECT_FEATURE;
      }
      return currVal;
    });

    setClickHandler(undefined);
    setShowSelectFeatureForOverlapHint(false);
  }, [
    onEscape,
    setClickHandler,
    setMapMode,
    setShowSelectFeatureForOverlapHint,
  ]);

  return {
    activateSelectFeatureForOverlap,
    onClickFeatureForFeatureOverlap,
    setFeatureForFeatureOverlap,
    onUnmount,
  };
};

const SelectFeatureForOverlapContext = React.createContext<
  | {
      onClickFeatureForFeatureOverlap: (
        features: (mapboxgl.MapboxGeoJSONFeature | ProjectFeature)[],
      ) => void;
      setFeatureForFeatureOverlap: (feature: ProjectFeature) => void;
      activateSelectFeatureForOverlap: () => void;
    }
  | undefined
>(undefined);

// This context exists to be able to reset the the click handler when the
// component unmounts. This is necessary because the click handler is set
// in a useEffect
export const useSelectFeatureForOverlapContext = () => {
  const context = React.useContext(SelectFeatureForOverlapContext);
  if (!context) {
    throw new Error(
      "useSelectFeatureForOverlapContext must be used within a SelectFeatureForOverlapContextProvider",
    );
  }
  return context;
};

export const SelectFeatureForOverlapContextProvider: React.FC<
  React.PropsWithChildren
> = ({ children }) => {
  const { onUnmount, ...context } = useSelectFeatureForOverlap();

  useEffect(() => {
    return () => {
      onUnmount();
    };
  }, [onUnmount]);

  return (
    <SelectFeatureForOverlapContext.Provider value={context}>
      {children}
    </SelectFeatureForOverlapContext.Provider>
  );
};
