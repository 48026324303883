import { FoundationType } from "./../../../../../types/foundations";
import { useRecoilCallback } from "recoil";

import {
  addOrUpdateFoundationResourceOnNode,
  deleteFoundationResourceOnNode,
} from "components/Organisation/Library/service";
import {
  foundationResourceWithAccessOnNodeState,
  nodesWithAccessToFoundation,
} from "components/Organisation/Library/state";
import { useTypedPath } from "state/pathParams";
import { foundationTypeUsageAtomFamily } from "state/foundations";
import { userAllNodesAccessAtom } from "state/user";

const useNodeFoundationResourcesCrud = () => {
  const { organisationId } = useTypedPath("organisationId");
  const addOrUpdate = useRecoilCallback(
    ({ snapshot, set, refresh }) =>
      async (nodeId: string, foundation: FoundationType) => {
        const fallback = await snapshot.getPromise(
          foundationResourceWithAccessOnNodeState({ nodeId }),
        );
        const allNodeIds = await snapshot.getPromise(userAllNodesAccessAtom);

        return addOrUpdateFoundationResourceOnNode(nodeId, foundation.id)
          .then(() => {
            Object.keys(allNodeIds.node_access).map((nodeId) =>
              refresh(foundationResourceWithAccessOnNodeState({ nodeId })),
            );
            refresh(
              nodesWithAccessToFoundation({
                organisationId,
                resourceId: foundation.id,
              }),
            );
          })
          .catch((e) => {
            set(foundationResourceWithAccessOnNodeState({ nodeId }), fallback);
            throw e;
          });
      },
    [organisationId],
  );

  const remove = useRecoilCallback(
    ({ snapshot, set, refresh }) =>
      async (nodeId: string, resourceId: string) => {
        const fallback = await snapshot.getPromise(
          foundationResourceWithAccessOnNodeState({ nodeId }),
        );
        const usage = await snapshot.getPromise(
          foundationTypeUsageAtomFamily({
            nodeId,
            foundationId: resourceId,
          }),
        );
        const allNodeIds = await snapshot.getPromise(userAllNodesAccessAtom);

        if (
          usage.length === 0 ||
          window.confirm(
            `Foundation type is currently being used in ${usage.length} foundations, are you sure you want to remove access to it?`,
          )
        ) {
          return deleteFoundationResourceOnNode(nodeId, resourceId)
            .then(() => {
              Object.keys(allNodeIds.node_access).map((nodeId) =>
                refresh(foundationResourceWithAccessOnNodeState({ nodeId })),
              );
              refresh(
                nodesWithAccessToFoundation({ organisationId, resourceId }),
              );
            })
            .catch((e) => {
              set(
                foundationResourceWithAccessOnNodeState({ nodeId }),
                fallback,
              );
              throw e;
            });
        }
      },
    [organisationId],
  );

  return { addOrUpdate, remove };
};

export default useNodeFoundationResourcesCrud;
