/// <reference types="vite-plugin-svgr/client" />
import React, { useMemo, useRef } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import useBooleanState from "../../../../hooks/useBooleanState";
import { useClickOutside } from "../../../../hooks/useClickOutside";
import { ScrollBody } from "../../../../hooks/useShowScrollShadow";
import useTextInput from "../../../../hooks/useTextInput";
import ArrowDownIcon from "@icons/24/ArrowDown.svg?react";
import ArrowRightIcon from "@icons/24/ArrowRight.svg?react";
import DatabaseIcon from "@icons/24/Database.svg?react";
import { IconREMSize, typography } from "styles/typography";
import { partition } from "utils/utils";
import {
  findSourceWithName,
  getAllDataSourcesSelector,
} from "../../../../state/layer";
import { useTypedPath } from "../../../../state/pathParams";
import { colors } from "../../../../styles/colors";
import { spaceLarge, spaceMedium, spaceSmall } from "../../../../styles/space";
import Button from "../../../General/Button";
import Checkbox from "../../../General/Checkbox";
import FeatureFlag, { SHOW_DATA_CLEANING } from "../../../General/FeatureFlag";
import { Input } from "../../../General/Input";
import { Frame } from "../../../General/Layout";
import {
  selectedSourceNameAtom,
  SourceName,
} from "../../../LayerList/LayerSettings/state";
import {
  activeFiltersAtom,
  availableLayerListFiltersAtom,
} from "../../layer-filter-state";

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  width: 40%;

  button {
    justify-content: space-between;

    // We want to override the default styling, so create a specific selector
    &.secondary {
      color: ${colors.primaryText};

      svg {
        path {
          stroke: ${colors.primaryText};
        }
      }
    }
  }
`;

const SourceListWrapper = styled.div`
  position: relative;
  z-index: 1;
  overflow: visible;
  width: 0;
`;

const SourcesFrame = styled(Frame)`
  padding: ${spaceLarge} 0;
  max-height: 400px;
  overflow-y: auto;
  gap: ${spaceMedium};
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  width: 40rem;
`;

const NonScrollableSourcesFrame = styled(SourcesFrame)`
  overflow-y: hidden;
`;

const searchFn = (target: EventTarget, source: HTMLElement): boolean => {
  if (target instanceof HTMLElement) {
    return (
      Boolean(target.dataset["popupType"]) &&
      target.dataset["popupType"] === source.dataset["popupType"]
    );
  }
  return false;
};

const ClickOutsideToClose = ({
  children,
  close,
  ...rest
}: { close(): void } & React.PropsWithChildren) => {
  const popupRef = useRef<HTMLDivElement>(null);
  useClickOutside(popupRef, close, searchFn);

  return (
    <div ref={popupRef} {...rest}>
      {children}
    </div>
  );
};

const BrowseLayersFilters = () => {
  const { projectId } = useTypedPath("projectId");
  const [sourcesOpen, toggleSourcesOpen] = useBooleanState(false);
  const [countriesOpen, toggleCountriesOpen] = useBooleanState(false);
  const [tagsOpen, toggleTagsOpen] = useBooleanState(false);
  const [typesOpen, toggleTypesOpen] = useBooleanState(false);
  const [sourcesSearch, onSourcesSearchChange] = useTextInput("");
  const [activeFilters, setActiveFilters] = useRecoilState(activeFiltersAtom);
  const availableFilters = useRecoilValue(
    availableLayerListFiltersAtom({ projectId }),
  );
  const { language: selectedSourceName } = useRecoilValue(
    selectedSourceNameAtom,
  );
  const sources = useRecoilValue(getAllDataSourcesSelector({ projectId }));

  const [privateSources, defaultSources] = useMemo(() => {
    const allSources = availableFilters.source
      .map((sourceName) => {
        const source = findSourceWithName(sourceName, sources);
        const title =
          selectedSourceName === SourceName.Original
            ? source?.originalName ?? source?.name ?? sourceName
            : source?.name ?? sourceName;

        return {
          sourceName: sourceName,
          originalName: source?.originalName,
          title,
          isPrivate: source?.private ?? false,
          sourceId: source?.id,
        };
      })
      .filter(({ sourceName, originalName }) => {
        return (
          !sourcesSearch ||
          sourceName.toLowerCase().includes(sourcesSearch.toLowerCase()) ||
          originalName?.toLowerCase().includes(sourcesSearch.toLowerCase())
        );
      })
      .sort((a, b) => a.title.localeCompare(b.title));

    const [privateSources, defaultSources] = partition(
      allSources,
      (source) => source.isPrivate,
    );
    return [privateSources, defaultSources];
  }, [availableFilters.source, selectedSourceName, sources, sourcesSearch]);

  return (
    <>
      <ButtonWrapper>
        <div>
          <Button
            data-popup-type="countries"
            text="Country"
            onClick={toggleCountriesOpen}
            onTouchEnd={toggleCountriesOpen}
            buttonType="dropdown"
            size="small"
            icon={countriesOpen ? <ArrowDownIcon /> : <ArrowRightIcon />}
            iconPosition="right"
            style={{
              minWidth: "100%",
            }}
          />
          {countriesOpen && (
            <ClickOutsideToClose
              close={toggleCountriesOpen}
              data-popup-type="countries"
            >
              <SourceListWrapper>
                <SourcesFrame>
                  <FeatureFlag name={SHOW_DATA_CLEANING}>
                    <div>
                      <Checkbox
                        label={"Show layers without countries"}
                        checked={activeFilters.debug_missing_countries}
                        labelPlacement="after"
                        onChange={() => {
                          setActiveFilters((curr) => ({
                            ...curr,
                            debug_missing_countries:
                              !curr.debug_missing_countries,
                          }));
                        }}
                      />
                    </div>
                  </FeatureFlag>
                  {availableFilters.country.map((country) => {
                    const isActive = activeFilters.country.some(
                      (activeCountry) => activeCountry === country,
                    );

                    return (
                      <div key={country}>
                        <Checkbox
                          style={{
                            textTransform: "capitalize",
                          }}
                          label={country}
                          value={country}
                          checked={isActive}
                          labelPlacement="after"
                          onChange={() => {
                            setActiveFilters((curr) => ({
                              ...curr,
                              country: isActive
                                ? curr.country.filter(
                                    (existingCountry) =>
                                      existingCountry !== country,
                                  )
                                : [...curr.country, country],
                            }));
                          }}
                        />
                      </div>
                    );
                  })}
                </SourcesFrame>
              </SourceListWrapper>
            </ClickOutsideToClose>
          )}
        </div>
        <div>
          <Button
            data-popup-type="source"
            text="Source"
            onClick={toggleSourcesOpen}
            onTouchEnd={toggleSourcesOpen}
            buttonType="dropdown"
            size="small"
            icon={sourcesOpen ? <ArrowDownIcon /> : <ArrowRightIcon />}
            iconPosition="right"
            style={{
              minWidth: "100%",
            }}
          />
          {sourcesOpen && (
            <ClickOutsideToClose
              close={toggleSourcesOpen}
              data-popup-type="source"
            >
              <SourceListWrapper>
                <NonScrollableSourcesFrame>
                  <div>
                    <Input
                      type="search"
                      value={sourcesSearch}
                      onChange={onSourcesSearchChange}
                      placeholder="Search source name"
                      style={{ width: "100%" }}
                      autoFocus={true}
                    />
                  </div>
                  <ScrollBody gap="inherit">
                    {privateSources.length > 0 && (
                      <>
                        <h4
                          style={{
                            ...typography.h4,
                          }}
                        >
                          Added sources
                        </h4>
                        {privateSources.map(
                          ({ sourceName, title, sourceId }) => {
                            const isActive = activeFilters.source.some(
                              (activeSource) => activeSource === sourceName,
                            );

                            return (
                              <div
                                key={sourceId}
                                style={{
                                  display: "flex",
                                  gap: spaceSmall,
                                }}
                              >
                                <Checkbox
                                  label={title}
                                  value={sourceName}
                                  checked={isActive}
                                  labelPlacement="after"
                                  onChange={() => {
                                    setActiveFilters((curr) => ({
                                      ...curr,
                                      source: isActive
                                        ? curr.source.filter(
                                            (source) => source !== sourceName,
                                          )
                                        : [...curr.source, sourceName],
                                    }));
                                  }}
                                />
                                <IconREMSize height={1.2} width={1.2}>
                                  <DatabaseIcon />
                                </IconREMSize>
                              </div>
                            );
                          },
                        )}
                      </>
                    )}
                    <h4
                      style={{
                        ...typography.h4,
                      }}
                    >
                      Default sources
                    </h4>
                    {defaultSources
                      .filter(({ sourceName, originalName }) => {
                        return (
                          !sourcesSearch ||
                          sourceName
                            .toLowerCase()
                            .includes(sourcesSearch.toLowerCase()) ||
                          originalName
                            ?.toLowerCase()
                            .includes(sourcesSearch.toLowerCase())
                        );
                      })
                      .map(({ sourceName, title, sourceId }) => {
                        const isActive = activeFilters.source.some(
                          (activeSource) => activeSource === sourceName,
                        );

                        return (
                          <div key={sourceId}>
                            <Checkbox
                              label={title}
                              value={sourceName}
                              checked={isActive}
                              labelPlacement="after"
                              onChange={() => {
                                setActiveFilters((curr) => ({
                                  ...curr,
                                  source: isActive
                                    ? curr.source.filter(
                                        (source) => source !== sourceName,
                                      )
                                    : [...curr.source, sourceName],
                                }));
                              }}
                            />
                          </div>
                        );
                      })}
                  </ScrollBody>
                </NonScrollableSourcesFrame>
              </SourceListWrapper>
            </ClickOutsideToClose>
          )}
        </div>
        <div>
          <Button
            data-popup-type="tags"
            text="Tag"
            onClick={toggleTagsOpen}
            onTouchEnd={toggleTagsOpen}
            buttonType="dropdown"
            size="small"
            icon={tagsOpen ? <ArrowDownIcon /> : <ArrowRightIcon />}
            iconPosition="right"
            style={{
              minWidth: "100%",
            }}
          />
          {tagsOpen && (
            <ClickOutsideToClose close={toggleTagsOpen} data-popup-type="tags">
              <SourceListWrapper>
                <SourcesFrame>
                  <FeatureFlag name={SHOW_DATA_CLEANING}>
                    <div>
                      <Checkbox
                        label={"Show layers without tags"}
                        checked={activeFilters.debug_missing_tags}
                        labelPlacement="after"
                        onChange={() => {
                          setActiveFilters((curr) => ({
                            ...curr,
                            debug_missing_tags: !curr.debug_missing_tags,
                          }));
                        }}
                      />
                    </div>
                  </FeatureFlag>
                  {availableFilters.tags.map((f) => {
                    const isActive = activeFilters.tags.some(
                      (tag) => tag === f,
                    );

                    return (
                      <div key={f}>
                        <Checkbox
                          label={f}
                          value={f}
                          checked={isActive}
                          labelPlacement="after"
                          onChange={() => {
                            setActiveFilters((curr) => ({
                              ...curr,
                              tags: isActive
                                ? curr.tags.filter((source) => source !== f)
                                : [...curr.tags, f],
                            }));
                          }}
                        />
                      </div>
                    );
                  })}
                </SourcesFrame>
              </SourceListWrapper>
            </ClickOutsideToClose>
          )}
        </div>
        <div>
          <Button
            data-popup-type="types"
            text="Type"
            onClick={toggleTypesOpen}
            onTouchEnd={toggleTypesOpen}
            buttonType="dropdown"
            size="small"
            icon={typesOpen ? <ArrowDownIcon /> : <ArrowRightIcon />}
            iconPosition="right"
            style={{
              minWidth: "100%",
            }}
          />
          {typesOpen && (
            <ClickOutsideToClose
              close={toggleTypesOpen}
              data-popup-type="types"
            >
              <SourceListWrapper>
                <SourcesFrame>
                  {availableFilters.sourceTypes.map((f) => {
                    const isActive = activeFilters.sourceTypes.some(
                      (tag) => tag === f,
                    );

                    return (
                      <div key={f}>
                        <Checkbox
                          label={f}
                          value={f}
                          checked={isActive}
                          labelPlacement="after"
                          onChange={() => {
                            setActiveFilters((curr) => ({
                              ...curr,
                              sourceTypes: isActive
                                ? curr.sourceTypes.filter(
                                    (source) => source !== f,
                                  )
                                : [...curr.sourceTypes, f],
                            }));
                          }}
                        />
                      </div>
                    );
                  })}
                </SourcesFrame>
              </SourceListWrapper>
            </ClickOutsideToClose>
          )}
        </div>
      </ButtonWrapper>
    </>
  );
};

export default BrowseLayersFilters;
