import * as turf from "@turf/turf";
import { jointPreviewMooringState } from "components/GenerateFoundationsAndAnchors/state";
import {
  editmodePropertyName,
  ghostPropertyName,
} from "components/Mapbox/constants";
import { computeCapacity } from "components/ProductionV2/functions";
import { Point } from "geojson";
import RBush from "rbush";
import { selector, selectorFamily } from "recoil";
import { InvalidTypes, InvalidTypesStateType } from "types/invalidTypes";
import {
  DEFAULT_TURBINES,
  SimpleTurbineType,
  replaceEndpointsLineString,
} from "types/turbines";
import { pointInPolygon } from "utils/geometry";
import {
  getDistanceFromLatLonInM,
  metersToLat,
  metersToLng,
} from "utils/proj4";
import { DefaultMap } from "../lib/DefaultMap";
import {
  AnchorFeature,
  MooringLineFeature,
  ProjectFeature,
  SubstationFeature,
  TurbineFeature,
} from "../types/feature";
import {
  isAnchor,
  isDefined,
  isMooringLine,
  isMooringLineMultiple,
  isNumber,
  isPointFeature,
  isSubArea,
  isTurbine,
} from "../utils/predicates";
import { count, fastMax, mapToRecord, partition } from "../utils/utils";
import {
  projectFeaturesInBranchSelectorFamily,
  projectFeaturesSelector,
} from "./../components/ProjectElements/state";
import {
  getInvalidCableTypeCablesInParkandBranch,
  getSubstationsInBranchSelectorFamily,
} from "./cable";
import { getInvalidFoundationTypeTurbinesInParkandBranch } from "./foundations";
import {
  getParkFeatureInBranchSelector,
  getParkFeaturesInBranchSelector,
  inPark,
} from "./park";
import { parkIdSelector } from "./pathParams";
import {
  canvasLayerFeatureHiddenAtomFamily,
  projectFeatureMap,
} from "./projectLayers";
import { EMPTY_LIST, replaceEmpty } from "./recoil";
import { currentSelectedProjectFeatures } from "./selection";
import {
  allSimpleTurbineTypesSelector,
  getInvalidTurbineTypeTurbinesInParkandBranch,
  previewTurbinesState,
} from "./turbines";

export const EXISTING_TURBINE_OVERLAP_DISTANCE = 100;

export const getAllTurbinesSelector = selector<TurbineFeature[]>({
  key: "getAllTurbinesSelector",
  get: ({ get }) => {
    const projectData = get(projectFeaturesSelector);
    return projectData.filter(isTurbine);
  },
});

export const getAllTurbinesInBranchSelector = selectorFamily<
  TurbineFeature[],
  { branchId: string }
>({
  key: "getAllTurbinesInBranchSelector",
  get:
    ({ branchId }) =>
    ({ get }) => {
      const projectData = get(
        projectFeaturesInBranchSelectorFamily({ branchId }),
      );
      return projectData.filter(isTurbine);
    },
});

export const getAnchorsSelectorFamily = selectorFamily<AnchorFeature[], string>(
  {
    key: "getAnchorsSelectorFamily",
    get:
      (parkId) =>
      ({ get }) =>
        get(projectFeaturesSelector).filter(inPark(parkId)).filter(isAnchor),
  },
);

export const getAnchorsInBranchSelector = selectorFamily<
  AnchorFeature[],
  { parkId: string; branchId: string }
>({
  key: "getAnchorsInBranchSelector",
  get:
    ({ parkId, branchId }) =>
    ({ get }) =>
      get(projectFeaturesInBranchSelectorFamily({ branchId }))
        .filter(inPark(parkId))
        .filter(isAnchor),
});

export const getVisibleAnchorsSelector = selectorFamily<
  AnchorFeature[],
  { parkId: string; branchId: string | undefined }
>({
  key: "getVisibleAnchorsSelector",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const canvasLayerFeatureHidden = get(
        canvasLayerFeatureHiddenAtomFamily({ branchId }),
      );
      return replaceEmpty(
        get(getAnchorsSelectorFamily(parkId)).filter(
          (f) => !canvasLayerFeatureHidden.includes(f.id),
        ),
      );
    },
});

export const getRawMooringLinesInBranchSelector = selectorFamily<
  MooringLineFeature[],
  { parkId: string; branchId: string }
>({
  key: "getRawMooringLinesInBranchSelector",
  get:
    ({ parkId, branchId }) =>
    ({ get }) =>
      get(projectFeaturesInBranchSelectorFamily({ branchId }))
        .filter(inPark(parkId))
        .filter(isMooringLine),
});

export const getMooringLinesSelector = selectorFamily<
  MooringLineFeature[],
  string
>({
  key: "getMooringLinesSelector",
  get:
    (parkId) =>
    ({ get }) => {
      const mooringLines = get(getAllMooringLinesSelector);
      return replaceEmpty(mooringLines.filter(inPark(parkId)));
    },
});

export const getMooringLinesInBranchSelector = selectorFamily<
  MooringLineFeature[],
  { parkId: string; branchId: string }
>({
  key: "getMooringLinesInBranchSelector",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const mooringLines = get(
        getRawMooringLinesInBranchSelector({ parkId, branchId }),
      );
      const turbines = get(
        getTurbinesInBranchSelectorFamily({ parkId, branchId }),
      );
      const anchors = get(getAnchorsInBranchSelector({ parkId, branchId }));

      const turbineMap = new Map(turbines.map((t) => [t.id, t]));
      const anchorMap = new Map(anchors.map((t) => [t.id, t]));

      const ret = mooringLines
        .map((ml) => {
          const { target, anchor } = ml.properties;
          const p = turbineMap.get(target)?.geometry?.coordinates;
          const q = anchorMap.get(anchor)?.geometry?.coordinates;

          if (p === undefined || q === undefined) return undefined;

          const coords = [p, ...ml.geometry.coordinates.slice(1, -1), q];
          const f: MooringLineFeature = {
            ...ml,
            geometry: {
              ...ml.geometry,
              coordinates: coords,
            },
          };
          return f;
        })
        .filter(isDefined);

      return replaceEmpty(ret);
    },
});

type BuoysAndClumpWeights = {
  buoys: number[];
  clumpWeights: number[];
};

export const getBuoysAndClumpWeightInBranchSelector = selectorFamily<
  BuoysAndClumpWeights,
  { parkId: string; branchId: string }
>({
  key: "getBuoysAndClumpWeightInBranchSelector",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const mooringLines = get(
        getMooringLinesInBranchSelector({ parkId, branchId }),
      );
      const buoys: number[] = [];
      const clumpWeights: number[] = [];

      for (const line of mooringLines) {
        if (isMooringLineMultiple(line)) {
          for (let i = 0; i < line.properties["lineLengths"].length; i++) {
            if (line.properties["attachments"][i] > 0) {
              clumpWeights.push(line.properties["attachments"][i]);
            } else if (line.properties["attachments"][i] < 0) {
              buoys.push(-line.properties["attachments"][i]);
            }
          }
        } else {
          // No attachments?
        }
      }
      return { buoys, clumpWeights };
    },
});

export const getVisibleMooringLinesSelector = selectorFamily<
  MooringLineFeature[],
  { parkId: string; branchId: string | undefined }
>({
  key: "getVisibleMooringLinesSelector",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const canvasLayerFeatureHidden = get(
        canvasLayerFeatureHiddenAtomFamily({ branchId }),
      );
      return replaceEmpty(
        get(getMooringLinesSelector(parkId)).filter(
          (f) => !canvasLayerFeatureHidden.includes(f.id),
        ),
      );
    },
});

export const getTurbinesSelectorFamily = selectorFamily<
  TurbineFeature[],
  { parkId: string }
>({
  key: "getTurbinesSelectorFamily",
  get:
    ({ parkId }) =>
    ({ get }) => {
      return get(getAllTurbinesSelector).filter((f) =>
        f.properties?.parentIds?.includes(parkId),
      );
    },
});

export const getTurbinesSortedByLabelSelectorFamily = selectorFamily<
  TurbineFeature[],
  { parkId: string }
>({
  key: "getTurbinesSortedByLabelSelectorFamily",
  get:
    ({ parkId }) =>
    ({ get }) => {
      const turbines = get(getTurbinesSelectorFamily({ parkId }));
      const withLabel: [number, TurbineFeature][] = turbines.map((t) => [
        parseInt(t.properties.name?.slice(1) ?? "0"),
        t,
      ]);
      withLabel.sort((a, b) => a[0] - b[0]);
      return withLabel.map((t) => t[1]);
    },
});

export const getLargestTurbineLabelSelectorFamily = selectorFamily<
  number,
  { parkId: string }
>({
  key: "getLargestTurbineLabelSelectorFamily",
  get:
    ({ parkId }) =>
    ({ get }) => {
      const turbines = get(getTurbinesSelectorFamily({ parkId }));
      const ids = turbines
        .map((t) => t.properties.name?.slice(1))
        .filter(isDefined)
        .map((s) => parseInt(s))
        .filter(isNumber);
      return fastMax(ids, 0);
    },
});

export const getTurbinesInBranchSelectorFamily = selectorFamily<
  TurbineFeature[],
  { parkId: string; branchId: string; turbineTypeOverride?: SimpleTurbineType }
>({
  key: "getTurbinesInBranchSelectorFamily",
  get:
    ({ parkId, branchId, turbineTypeOverride }) =>
    ({ get }) => {
      const turbines = get(getAllTurbinesInBranchSelector({ branchId })).filter(
        (f) => f.properties?.parentIds?.includes(parkId),
      );
      const updatedTurbines = turbineTypeOverride
        ? turbines.map((t) => ({
            ...t,
            properties: {
              ...t.properties,
              turbineTypeId: turbineTypeOverride?.id ?? "",
            },
          }))
        : turbines;
      return updatedTurbines;
    },
});

/**
 * Returns a map from turbine type id to the turbines of that type.
 */
export const getTurbinesTypeToTurbinesSelectorFamily = selectorFamily<
  Record<string, TurbineFeature[]>,
  { parkId: string; branchId: string; turbineTypeOverride?: SimpleTurbineType }
>({
  key: "getTurbinesTypeToTurbinesSelectorFamily",
  get:
    ({ parkId, branchId, turbineTypeOverride }) =>
    ({ get }) => {
      const turbines = get(
        getTurbinesInBranchSelectorFamily({
          parkId,
          branchId,
          turbineTypeOverride,
        }),
      );
      const map = new DefaultMap<string, TurbineFeature[]>(() => []);
      for (const t of turbines) map.get(t.properties.turbineTypeId).push(t);
      return mapToRecord(map.inner);
    },
});

export const getPaddedPark = selectorFamily<
  turf.Position[][] | undefined,
  { parkId: string; branchId: string; maxDistance?: number }
>({
  key: "getPaddedPark",
  get:
    ({ parkId, branchId, maxDistance }) =>
    ({ get }) => {
      if (!maxDistance) return;

      const park = get(getParkFeatureInBranchSelector({ parkId, branchId }));
      const turbines = get(
        getTurbinesInBranchSelectorFamily({ parkId, branchId }),
      );
      if (!park || !turbines) return undefined;
      return turf.buffer(
        turf.convex(turf.featureCollection(turbines)) || park,
        maxDistance,
      )?.geometry?.coordinates;
    },
});

export const getSurroundingTurbineFeaturesInBranchSelector = selectorFamily<
  TurbineFeature[],
  { parkId?: string; branchId: string; maxDistance: number }
>({
  key: "getSurroundingTurbineFeaturesInBranchSelector",
  get:
    ({ parkId, branchId, maxDistance }) =>
    ({ get }) => {
      if (!parkId) return [];
      const paddedPark = get(getPaddedPark({ parkId, branchId, maxDistance }));
      if (!paddedPark) return [];

      const allParks = get(getParkFeaturesInBranchSelector({ branchId }));
      const otherParks = allParks.filter((p) => p.id !== parkId);

      const closeParks = otherParks.filter((p) =>
        turf.intersect(turf.polygon(paddedPark), p),
      );

      const closeLayouts = closeParks
        .map((p) =>
          get(
            getTurbinesInBranchSelectorFamily({
              parkId: p.id,
              branchId,
            }),
          ),
        )
        .filter((p) => p != null);

      const closeTurbines = closeLayouts
        .flatMap((turbines) => turbines)
        .filter((t) => turf.inside(t, turf.polygon(paddedPark)));

      return closeTurbines;
    },
});

/**
 * Return all turbines that should be rendered in the project. Also
 * returns preview turbines.
 */
export const turbinesToShowSelector = selector<TurbineFeature[]>({
  key: "turbinesToShowSelector",
  get: ({ get }) => {
    const turbines = get(getAllTurbinesSelector);
    const previewState = get(previewTurbinesState);
    if (!previewState) return turbines;
    const parkId = get(parkIdSelector);
    if (!parkId) return turbines;

    const [ghostTurbines, otherTurbines] = partition(turbines, inPark(parkId));
    return otherTurbines
      .concat(
        ghostTurbines.map((t) => ({
          ...t,
          properties: {
            ...t.properties,
            [ghostPropertyName]: true,
          },
        })),
      )
      .concat(previewState.existing)
      .concat(
        previewState.preview.map((t) => ({
          ...t,
          properties: {
            ...t.properties,
            [editmodePropertyName]: true,
          },
        })),
      );
  },
});

const getAllAnchorsSelector = selector<AnchorFeature[]>({
  key: "getAllAnchorsSelector",
  get: ({ get }) => get(projectFeaturesSelector).filter(isAnchor),
});

export const anchorsToShowSelector = selector<AnchorFeature[]>({
  key: "anchorsToShowSelector",
  get: ({ get }) => {
    const anchors = get(getAllAnchorsSelector);
    const preview = get(jointPreviewMooringState);
    if (!preview) return anchors;
    const parkId = get(parkIdSelector);
    if (!parkId) return anchors;

    const [ghostAnchors, otherAnchors] = partition(anchors, inPark(parkId));
    return otherAnchors
      .concat(
        ghostAnchors.map((f) => ({
          ...f,
          properties: {
            ...f.properties,
            [ghostPropertyName]: true,
          },
        })),
      )
      .concat(preview.existing.anchors)
      .concat(
        preview.preview.anchors.map((f) => ({
          ...f,
          properties: {
            ...f.properties,
            [editmodePropertyName]: true,
          },
        })),
      );
  },
});

const getAllMooringLinesSelector = selector<MooringLineFeature[]>({
  key: "getAllMooringLinesSelector",
  get: ({ get }) => {
    const featureMap = get(projectFeatureMap);
    return get(projectFeaturesSelector)
      .filter(isMooringLine)
      .map((f) => {
        const from = featureMap.get(f.properties.anchor);
        const to = featureMap.get(f.properties.target);
        if (isPointFeature(from) && isPointFeature(to))
          return replaceEndpointsLineString(
            f,
            from.geometry.coordinates,
            to.geometry.coordinates,
          );
        return f;
      });
  },
});

export const mooringLinesToShowSelector = selector<MooringLineFeature[]>({
  key: "mooringLinesToShowSelector",
  get: ({ get }) => {
    const mooringLines = get(getAllMooringLinesSelector);
    const preview = get(jointPreviewMooringState);
    if (!preview) return mooringLines;
    const parkId = get(parkIdSelector);
    if (!parkId) return mooringLines;

    const [ghostAnchors, otherAnchors] = partition(
      mooringLines,
      inPark(parkId),
    );
    return otherAnchors
      .concat(
        ghostAnchors.map((f) => ({
          ...f,
          properties: {
            ...f.properties,
            [ghostPropertyName]: true,
          },
        })),
      )
      .concat(preview.existing.mooringLines)
      .concat(
        preview.preview.mooringLines.map((f) => ({
          ...f,
          properties: {
            ...f.properties,
            [editmodePropertyName]: true,
          },
        })),
      );
  },
});

export const getTurbinesOutsideParkSelector = selectorFamily<
  TurbineFeature[],
  { parkId: string | undefined; branchId: string }
>({
  key: "getTurbineOutsideParkSelector",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      if (!parkId) return EMPTY_LIST;
      const turbines = get(
        getTurbinesInBranchSelectorFamily({ parkId, branchId }),
      );
      const park = get(getParkFeatureInBranchSelector({ parkId, branchId }));

      if (!park) return EMPTY_LIST;
      return replaceEmpty(
        turbines.filter((t) => !pointInPolygon(t.geometry, park.geometry)),
      );
    },
});

export const overlappingPointFeatures = <F extends ProjectFeature<Point>>(
  features: F[],
  maxDistance: number,
): F[] => {
  const tree = new RBush<{
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;
    feature: ProjectFeature<Point>;
    index: number;
  }>();

  features.forEach((feature, index) => {
    const [lng, lat] = feature.geometry.coordinates;
    tree.insert({
      minX: lng,
      minY: lat,
      maxX: lng,
      maxY: lat,
      feature,
      index,
    });
  });

  return features.filter((f, i) => {
    const [lng, lat] = f.geometry.coordinates;
    const latDiff = metersToLat(maxDistance);
    const lngDiff = metersToLng(maxDistance, lat);

    const nearby = tree.search({
      minX: lng - lngDiff,
      minY: lat - latDiff,
      maxX: lng + lngDiff,
      maxY: lat + latDiff,
    });

    return nearby.some(({ feature, index }) => {
      if (i === index) return false;

      const distance = getDistanceFromLatLonInM(
        f.geometry.coordinates,
        feature.geometry.coordinates,
      );
      return distance < maxDistance;
    });
  });
};

export const getOverlappingTurbinesEfficient = (
  turbines: TurbineFeature[],
  turbineTypes: Record<string, SimpleTurbineType>,
) => {
  const tree = new RBush<{
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;
    turbine: TurbineFeature;
  }>();

  turbines.forEach((turbine) => {
    const [lng, lat] = turbine.geometry.coordinates;
    tree.insert({
      minX: lng,
      minY: lat,
      maxX: lng,
      maxY: lat,
      turbine,
    });
  });

  return turbines.filter((turbine) => {
    const diameter =
      turbineTypes[turbine.properties.turbineTypeId]?.diameter ?? 0;
    const [lng, lat] = turbine.geometry.coordinates;

    const latDiff = metersToLat(diameter);
    const lngDiff = metersToLng(diameter, lat);

    const nearbyTurbines = tree.search({
      minX: lng - lngDiff,
      minY: lat - latDiff,
      maxX: lng + lngDiff,
      maxY: lat + latDiff,
    });

    return nearbyTurbines.some((item) => {
      if (item.turbine.id === turbine.id) return false;

      const distance = getDistanceFromLatLonInM(
        turbine.geometry.coordinates,
        item.turbine.geometry.coordinates,
      );
      return distance < diameter;
    });
  });
};

export const getOverlappingTurbinesSelector = selectorFamily<
  TurbineFeature[],
  { parkId: string; branchId: string }
>({
  key: "getOverlappingTurbinesSelector",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const projectData = get(
        projectFeaturesInBranchSelectorFamily({ branchId }),
      );
      const turbines = projectData.filter(isTurbine);

      //const turbines = get(getTurbinesSelectorFamily({ parkId }));
      const park = get(getParkFeatureInBranchSelector({ parkId, branchId }));
      if (!park) return EMPTY_LIST;
      const turbineTypes = Object.fromEntries(
        get(allSimpleTurbineTypesSelector).map((t) => [t.id, t]),
      );

      const turbineOverlaps = getOverlappingTurbinesEfficient(
        turbines,
        turbineTypes,
      ).filter((t) => t.properties.parentIds?.includes(parkId));

      return replaceEmpty(turbineOverlaps);
    },
});

export const getOverlappingSubstationsSelector = selectorFamily<
  SubstationFeature[],
  { parkId: string; branchId: string }
>({
  key: "getOverlappingSubstationsSelector",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const substations = get(
        getSubstationsInBranchSelectorFamily({ parkId, branchId }),
      );
      const park = get(getParkFeatureInBranchSelector({ parkId, branchId }));
      if (!park) return EMPTY_LIST;

      const overlaps = overlappingPointFeatures(substations, 50);
      return replaceEmpty(overlaps);
    },
});

export const getAnchorsOutsideParkAndBranchSelector = selectorFamily<
  AnchorFeature[],
  { parkId: string; branchId: string }
>({
  key: "getAnchorsOutsideParkAndBranchSelector",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const anchors = get(getAnchorsInBranchSelector({ parkId, branchId }));
      const park = get(getParkFeatureInBranchSelector({ parkId, branchId }));
      if (!park) return EMPTY_LIST;
      return replaceEmpty(
        anchors.filter((t) => !pointInPolygon(t.geometry, park.geometry)),
      );
    },
});

export const getMostCommonTurbineTypeIdInZone = selectorFamily<
  string,
  { parkId: string }
>({
  key: "getMostCommonTurbineTypeIdInZone",

  get:
    ({ parkId }) =>
    ({ get }) => {
      const selectedFeatures = get(currentSelectedProjectFeatures);
      const selectedSubAreas = selectedFeatures.filter(isSubArea);
      let turbines = get(getTurbinesSelectorFamily({ parkId }));
      if (selectedSubAreas.length !== 0) {
        const turbineIsInSelectedZone = (t: TurbineFeature) =>
          selectedSubAreas.some((z) => pointInPolygon(t.geometry, z.geometry));

        turbines = turbines.filter(turbineIsInSelectedZone);
      }
      const numberPerTurbineType = count(
        turbines.map((t) => t.properties.turbineTypeId).filter(isDefined),
      );

      let parkTurbineTypeId = DEFAULT_TURBINES[0].id;
      let maxLength = 0;
      numberPerTurbineType.forEach((value: number, key: string) => {
        if (value > maxLength) {
          maxLength = value;
          parkTurbineTypeId = key;
        }
      });
      return parkTurbineTypeId;
    },
});

export const getInvalidTypesInParkAndBranch = selectorFamily<
  InvalidTypesStateType[],
  { parkId: string; branchId: string }
>({
  key: "getInvalidTypesInParkAndBranch",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const invalidTypes: InvalidTypesStateType[] = [];

      const invalidTurbineTypeTurbines = get(
        getInvalidTurbineTypeTurbinesInParkandBranch({ parkId, branchId }),
      );
      if (invalidTurbineTypeTurbines.length > 0) {
        invalidTypes.push({
          type: InvalidTypes.TurbineTypeInvalid,
          featureIds: invalidTurbineTypeTurbines.map((t) => t.id),
        });
      }

      const invalidFoundationTypeTurbines = get(
        getInvalidFoundationTypeTurbinesInParkandBranch({ parkId, branchId }),
      );
      if (invalidFoundationTypeTurbines.length > 0) {
        invalidTypes.push({
          type: InvalidTypes.FoundationTypeInvalid,
          featureIds: invalidFoundationTypeTurbines.map((t) => t.id),
        });
      }

      const invalidCableTypeCables = get(
        getInvalidCableTypeCablesInParkandBranch({ parkId, branchId }),
      );
      if (invalidCableTypeCables.length > 0) {
        invalidTypes.push({
          type: InvalidTypes.CableTypeInvalid,
          featureIds: invalidCableTypeCables.map((c) => c.id),
        });
      }

      return invalidTypes;
    },
});

export const getParkCapacitySelector = selectorFamily<
  number,
  { branchId: string; parkId: string }
>({
  key: "getParkCapacitySelector",
  get:
    ({ branchId, parkId }) =>
    ({ get }) => {
      const turbineTypes = get(allSimpleTurbineTypesSelector);

      const turbines = get(
        getTurbinesInBranchSelectorFamily({ branchId, parkId }),
      );

      return computeCapacity(turbineTypes, turbines);
    },
});
