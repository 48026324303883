import {
  getStoppedReason,
  getStoppedReasonFromAnalysis,
} from "components/ProductionV2/state";
import {
  AnalysisStoppedTypes,
  analysisStoppedText,
} from "components/ProductionV2/types";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useRecoilValueLoadable } from "recoil";
import { spaceMedium } from "../../../styles/space";
import { FlexGrid2 } from "../../General/Form";
import { Row } from "../../General/Layout";
import { useDashboardContext } from "../Dashboard";
import { CenterContainer, SafeCard } from "./Base";
import { formatDecimal } from "components/ProductionV2/format";

const InputDetails = () => {
  const { costConfiguration, triggerId } = useDashboardContext();

  const stoppedReason = useRecoilValueLoadable(
    getStoppedReason(triggerId),
  ).valueMaybe();

  const analysisStoppedReason = useRecoilValueLoadable(
    getStoppedReasonFromAnalysis(triggerId),
  ).valueMaybe();

  if (stoppedReason !== undefined) {
    return (
      <CenterContainer>
        <SimpleAlert text={analysisStoppedText[stoppedReason]} type={"error"} />
      </CenterContainer>
    );
  }
  if (analysisStoppedReason) {
    return (
      <CenterContainer>
        <SimpleAlert
          text={
            analysisStoppedText[analysisStoppedReason as AnalysisStoppedTypes]
          }
          type={"error"}
        />
      </CenterContainer>
    );
  }

  return (
    <FlexGrid2 style={{ padding: spaceMedium }}>
      <Row>
        <p>Other expenditures reference year</p>
        <p>{costConfiguration?.inflation.referenceYearOtherExpenditures}</p>
      </Row>

      <h4>Lifecycle expenditures</h4>
      {costConfiguration?.devex && (
        <Row>
          <p>DEVEX</p>
          <p>
            {costConfiguration
              ? formatDecimal(costConfiguration.devex.cost, 1)
              : "-"}{" "}
            {costConfiguration?.devex.unit}
          </p>
        </Row>
      )}

      {costConfiguration?.devex && (
        <Row>
          <p>DECOM</p>
          <p>
            {costConfiguration
              ? formatDecimal(costConfiguration.decom.cost, 1)
              : "-"}{" "}
            {costConfiguration?.decom.unit}
          </p>
        </Row>
      )}

      <h4>OPEX</h4>
      {costConfiguration?.opex &&
        costConfiguration.opex.custom.map((opex) => (
          <Row>
            <p>{opex.name}</p>
            <p>
              {opex.cost} {opex.unit}
            </p>
          </Row>
        ))}
    </FlexGrid2>
  );
};

export const OtherExpendituresInputWidget = () => (
  <SafeCard title="Other expenditures input" id="Other expenditures input">
    <InputDetails />
  </SafeCard>
);
