import { createContext, ReactNode, useRef } from "react";
import useAnimation from "./useAnimation";

import useCreateThreeCore, { ThreeCore } from "./useCreateThreeCore";
import useEnvironment from "./useEnvironment";
import useResizeThree from "./useResizeThree";
import useTurbines from "./useTurbines";
import useUpdateCamera from "./useUpdateCamera";
import useUpdateSunPos from "./useUpdateSunPos";
import { CameraOSMBuildings } from "./CameraOSMBuildings";
import { viewFromShoreOSMBuildingsActiveAtom } from "state/viewToPark";
import { useRecoilValue } from "recoil";
import DynamicTerrain, { getTerrainTilesFromMapbox } from "./useDynamicTerrain";

export const ViewToParkContext = createContext<{
  threeCore: ThreeCore | undefined;
  threeSceneRef: React.RefObject<HTMLDivElement> | undefined;
}>({
  threeCore: undefined,
  threeSceneRef: undefined,
});

export default function ViewToParkContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const threeCore = useCreateThreeCore();
  const threeSceneRef = useRef<HTMLDivElement>(null);

  const terrainPatches = useRecoilValue(getTerrainTilesFromMapbox);

  const viewFromShoreOSMBuildingsActive = useRecoilValue(
    viewFromShoreOSMBuildingsActiveAtom,
  );
  const { windTurbineBlinkingLightGroup, turbineBladeGroup } = useTurbines(
    threeCore,
    terrainPatches,
  );
  useAnimation(threeCore, windTurbineBlinkingLightGroup, turbineBladeGroup);
  useUpdateSunPos(threeCore);
  useUpdateCamera(threeCore, terrainPatches);
  useEnvironment(threeCore);
  useResizeThree(threeCore, threeSceneRef);

  return (
    <ViewToParkContext.Provider value={{ threeCore, threeSceneRef }}>
      {viewFromShoreOSMBuildingsActive && (
        <CameraOSMBuildings
          threeCore={threeCore}
          terrainPatches={terrainPatches}
        />
      )}

      <DynamicTerrain threeCore={threeCore} />
      {children}
    </ViewToParkContext.Provider>
  );
}
