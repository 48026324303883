import { useEffect } from "react";
import { selectorFamily, useRecoilValue } from "recoil";
import { getParkFeatureSelectorFamily } from "../../state/park";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { WarningProps } from "./ValidationWarnings";
import { getIAVoltageTypesInParkAndBranch } from "../../state/electrical";
import { ParkFeature } from "types/feature";
import { branchIdSelector } from "state/pathParams";

export const differentIAVoltageTypesSelectorFamily = selectorFamily<
  | {
      type: ValidationWarningTypes.CablesDifferentIAVoltageTypes;
      parkId: string;
    }
  | undefined,
  { parkId: string; branchId: string }
>({
  key: "differentIAVoltageTypesSelectorFamily",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const park = get(getParkFeatureSelectorFamily({ parkId }));
      if (!park) return;

      const voltageTypes = get(
        getIAVoltageTypesInParkAndBranch({
          parkId: park.id,
          branchId: branchId,
        }),
      );
      return voltageTypes.length <= 1
        ? undefined
        : {
            type: ValidationWarningTypes.CablesDifferentIAVoltageTypes,
            parkId: parkId,
          };
    },
});

const DifferentIAVoltageTypesInner = ({
  park,
  warn,
  remove,
}: { park: ParkFeature } & WarningProps) => {
  const branchId = useRecoilValue(branchIdSelector);
  const voltageTypesWarning = useRecoilValue(
    differentIAVoltageTypesSelectorFamily({
      parkId: park.id,
      branchId: branchId ?? "",
    }),
  );
  useEffect(() => {
    if (!voltageTypesWarning) return;
    warn(voltageTypesWarning);
    return () => {
      remove(ValidationWarningTypes.CablesDifferentIAVoltageTypes);
    };
  }, [voltageTypesWarning, remove, warn]);

  useEffect(
    () => () => {
      remove(ValidationWarningTypes.CablesDifferentIAVoltageTypes);
    },
    [remove],
  );

  return null;
};

const DifferentIAVoltageTypes = ({
  parkId,
  ...props
}: { parkId: string } & WarningProps) => {
  const park = useRecoilValue(getParkFeatureSelectorFamily({ parkId }));

  if (!park) return null;
  return <DifferentIAVoltageTypesInner park={park} {...props} />;
};

export default DifferentIAVoltageTypes;
