import { colors } from "../../../styles/colors";
import styled from "styled-components";

const ResolvedLabelWrapper = styled.div`
  background-color: ${colors.success};
  color: white;
  font-weight: bold;
  padding: 0.3rem;
  border: 1px solid ${colors.success};
  border-radius: 4px;
`;

const ResolvedLabel = () => {
  return <ResolvedLabelWrapper>Resolved</ResolvedLabelWrapper>;
};

export default ResolvedLabel;
