import { ReactNode } from "react";
import styled from "styled-components";
import WarningTriangleIcon from "@icons/24/WarningTriangle.svg";
import { IconREMSize } from "styles/typography";
import { colors } from "styles/colors";

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  overflow: auto;
`;

export const Deprecated = ({
  isDeprecated = true,
  children,
}: {
  isDeprecated?: boolean;
  children: ReactNode;
}) => (
  <ErrorWrapper>
    {isDeprecated && (
      <IconREMSize height={1.4} width={1.4} stroke={colors.textWarning}>
        <WarningTriangleIcon title="This layer has been deprecated" />
      </IconREMSize>
    )}
    {children}
  </ErrorWrapper>
);
