import { amountUnitPrefixSuffix, CostUnit } from "../../../types/financial";
import { getPark } from "components/ProductionV2/state";
import { useRecoilValue } from "recoil";
import { useCapexMillionPV } from "components/Finance/capex";
import { FinanceId } from "components/Finance/state";
import { DownloadCSV } from "components/CsvDownload/CsvDownloadButton";

export const DownloadCapexData = ({
  id,
  buttonType,
}: {
  id: FinanceId;
  buttonType: "text" | "menu";
}) => {
  const { costs } = useCapexMillionPV(id);
  const park = useRecoilValue(getPark(id));

  const columns = [
    { header: "Type", field: "type" },
    { header: "Count", field: "count" },
    { header: "Count unit", field: "countUnit" },
    { header: "Category", field: "category" },
    { header: "Total cost", field: "cost" },
    { header: "Cost unit", field: "costUnit" },
  ];

  const getData = async () => {
    const data = await costs.toPromise();
    return data
      ?.filter(({ amount }) => amount > 0)
      .map((c) => ({
        type: c.name,
        count: `${c.amount}`,
        countUnit: `${amountUnitPrefixSuffix[c.amountUnit]?.suffix ?? ""}`,
        category: c.category,
        cost: `${c.cost}`,
        costUnit: CostUnit.millionEuro,
      }));
  };

  const filename = `capex-${park.properties.name}-${new Date().toISOString()}.csv`;

  return (
    <DownloadCSV
      data={getData}
      columns={columns}
      filename={filename}
      buttonType={buttonType}
    />
  );
};
