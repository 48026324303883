import { selectorFamily, useRecoilValue } from "recoil";
import { ValidationWarningStateType } from "state/validationWarnings";
import { ValidationWarningTypes } from "./utils";
import { getCablesInBranchSelectorFamily } from "state/cable";
import { branchIdSelector } from "state/pathParams";
import { WarningProps } from "./ValidationWarnings";
import { useEffect } from "react";
import { checkForCycles } from "components/Cabling/CableWalk";

export const cableLoopsSelectorFamily = selectorFamily<
  | (ValidationWarningStateType & {
      type: ValidationWarningTypes.CableLoops;
    })
  | undefined,
  { branchId: string; parkId: string }
>({
  key: "cableLoopsSelectorFamily",
  get:
    ({ branchId, parkId }) =>
    ({ get }) => {
      const cables = get(getCablesInBranchSelectorFamily({ branchId, parkId }));
      const withoutRed = cables.filter((c) => !c.properties.redundancy);

      const looping = checkForCycles(withoutRed);
      if (looping) {
        return {
          type: ValidationWarningTypes.CableLoops,
          featureIds: [looping.id],
        };
      }

      return undefined;
    },
});

export const CableLoops = ({
  parkId,
  warn,
  remove,
}: { parkId: string } & WarningProps) => {
  const branchId = useRecoilValue(branchIdSelector) ?? "";

  const errors = useRecoilValue(
    cableLoopsSelectorFamily({ parkId: parkId, branchId }),
  );

  // Update validation warning
  useEffect(() => {
    if (!errors) return;
    warn(errors);
    return () => {
      remove(ValidationWarningTypes.CableLoops);
    };
  }, [remove, errors, warn]);

  return null;
};
