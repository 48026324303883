import { MAX_TOTAL_TURBINES } from "@constants/park";
import * as turf from "@turf/turf";
import {
  allCableTypesSelector,
  currentExportCableTypesSelector,
} from "components/Cabling/Generate/state";
import {
  TotalExportSystemLoss,
  calcTotalExportSystemLoss,
  computeAEP,
  computeCapacity,
  computeCapacityFactor,
  computeEfficiency,
  computeGrossEnergyProductionPerTurbine,
  computeInternalWakeLoss,
  computeOtherLosses,
  computePostWakeEnergyProductionPerTurbine,
  computeTotalInterArrayLoss,
  computeLoss,
  computeWeightedInterArrayLossPerCable,
} from "components/ProductionV2/functions";
import {
  AnalysisPercentiles,
  AnalysisResult,
  AnalysisStatus,
  AnalysisStatusDone,
  AnalysisTimeseries,
  AnalysisWindStats,
  Percentile,
  fetchAnalysisPercentiles,
  getAnalysisResult as fetchAnalysisResult,
  fetchAnalysisTimeseries,
  fetchAnalysisWindStats,
  fetchLatestAnalysisVersion,
  triggerAnalysis,
} from "functions/production";
import { Position } from "geojson";
import { DefaultValue, atomFamily, noWait, selectorFamily } from "recoil";
import {
  AnalysisPrecision,
  Configuration,
  WakeAnalysisConfiguration,
} from "services/configurationService";
import { WindSourceConfiguration } from "services/windSourceConfigurationService";
import {
  MIN_CABLE_LENGTH_KM,
  NUM_ELECTRICAL_POWER_BINS,
  getCablesInBranchSelectorFamily,
  getExportCablesInBranchSelectorFamily,
  getSubstationsInBranchSelectorFamily,
} from "state/cable";
import { cableChainsInBranchSelectorFamily } from "state/cableEdit";
import { branchSelectedConfigurationAtomFamily } from "state/configuration";
import { getDivisionFeaturesInBranchSelectorFamily } from "state/division";
import {
  ExportLossType,
  IALossType,
  LossStatusType,
  getElectricalAnalysis,
  getElectricalAnalysisArgs,
} from "state/electrical";
import {
  EXISTING_TURBINE_OVERLAP_DISTANCE,
  getOverlappingTurbinesEfficient,
  getSurroundingTurbineFeaturesInBranchSelector,
  getTurbinesInBranchSelectorFamily,
  overlappingPointFeatures,
} from "state/layout";
import { getParkFeatureInBranchSelector } from "state/park";
import {
  branchIdSelector_,
  parkIdSelector_,
  projectIdSelector_,
} from "state/pathParams";
import { currentSubstationTypesState } from "state/substationType";
import { getBranchSelectorFamily } from "state/timeline";
import { allSimpleTurbineTypesSelector } from "state/turbines";
import { selectedWindConfigurationAtomFamily } from "state/windSourceConfiguration";
import {
  MeanSpeedGrid,
  SimpleWindRose,
  WRG,
  WindTimeseries,
  windRoseFromConfigSelector,
} from "state/windStatistics";
import { BranchMeta } from "types/api";
import {
  ExistingTurbineFeature,
  ExportCableFeature,
  ParkFeature,
  SubAreaFeature,
  SubstationFeature,
  TurbineFeature,
} from "types/feature";
import { SimpleTurbineType } from "types/turbines";
import { pointInPolygon } from "utils/geometry";
import { getParkCenter } from "utils/parkUtils";
import { scream } from "utils/sentry";
import { fastMax, fastMin, sum, zip } from "utils/utils";
import {
  AnalysisConfigNotFoundError,
  WindConfigNotFoundError,
} from "./ErrorUtils";
import { AnalysisStoppedTypes, TurbineStat } from "./types";
import { existingTurbinesFeaturesSelector } from "state/projectLayers";
import { SubstationType } from "services/substationService";
import {
  allFixedTypesSelector,
  allFloaterTypesSelector,
  getScalesForTurbineTypeIdsAndFoundationIds,
  isTurbineFeatureWithFoundation,
  TurbineFeatureWithFoundation,
  turbineTypeAndFloatingFoundationCombinations,
} from "state/foundations";
import { getFixedFoundationTotalsSelectorFamily } from "components/RightSide/InfoModal/FoundationModal/fixed/state";
import { getFloatingFoundationDetailsSelectorFamily } from "components/RightSide/InfoModal/FoundationModal/floating/state";
import { bathymetryAtomFamily } from "state/bathymetry";
import { Raster } from "types/raster";
import { isDefined } from "utils/predicates";
import { fetchCustomCalibrationFile, fetchGwaSpeedGrid } from "functions/met";
import { exportCableTooLongWarningErrorSelectorFamily } from "components/ValidationWarnings/ExportCableTooLongWarningError";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";

/**
 * Override input for the production analysis.
 * All fields are optional.
 * If a field is not set, it will be calculated from the project.
 */
export type AnalysisOverrideInput = {
  /** Defaults to current project. */
  projectId?: string;
  /** Defaults to current branch. */
  branchId?: string;
  /** Defaults to current park. */
  parkId?: string;
  /** Defaults to current organisation. */
  organisationId?: string;

  /** Only show analysis numbers for turbines in these zones. */
  selectedSubAreas?: SubAreaFeature[];

  turbines?: TurbineFeature[];
  otherTurbines?: TurbineFeature[];
  existingTurbines?: ExistingTurbineFeature[];

  customTurbineTypes?: SimpleTurbineType[];

  /** Override the hub height for the turbines with this vaule. If not set, use
   * the hub heights on the {@link SimpleTurbineType}. */
  hubHeightOverride?: number;
  turbineTypeOverride?: SimpleTurbineType;
  exportCableTypeOverrideId?: string;
  windRose?: SimpleWindRose;
  windTimeseries?: WindTimeseries;
  configuration?: Configuration;
  windConfiguration?: WindSourceConfiguration;
  parkCenter?: Position;
  precision?: AnalysisPrecision;

  /** Force a new analysis to run. */
  restart?: boolean;
};

export type ProdId = string & { readonly __tag: unique symbol };

export const analysisOverrideInputAtomFamily = atomFamily<
  AnalysisOverrideInput,
  ProdId
>({
  key: "analysisOverrideInputAtomFamily",
});

export const getBranchId = selectorFamily<string, ProdId>({
  key: "productionv2-branchId",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const input = get(analysisOverrideInputAtomFamily(id));
      if (input.branchId) return input.branchId;

      return get(branchIdSelector_);
    },
});

export const getBranch = selectorFamily<BranchMeta, ProdId>({
  key: "productionv2-branch",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const projectId = get(getProjectId(id));
      const branchId = get(getBranchId(id));
      const branch = get(
        getBranchSelectorFamily({
          projectId,
          branchId,
        }),
      );
      if (branch === undefined) throw new Error("branch is undefined");
      return branch;
    },
});

export const getProjectId = selectorFamily<string, ProdId>({
  key: "productionv2-projectId",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const input = get(analysisOverrideInputAtomFamily(id));
      if (input.projectId) return input.projectId;

      return get(projectIdSelector_);
    },
});

export const getParkId = selectorFamily<string, ProdId>({
  key: "productionv2-parkId",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const input = get(analysisOverrideInputAtomFamily(id));
      if (input.parkId) return input.parkId;
      return get(parkIdSelector_);
    },
});

/**
 * If `selectedSubAreas` is set,
 * this will return the turbines inside the zones.
 * And if `turbineTypeOverride` is set,
 * the turbines returned will be of this turbine type
 */
export const getTurbines = selectorFamily<TurbineFeature[], ProdId>({
  key: "productionv2-turbines",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const input = get(analysisOverrideInputAtomFamily(id));
      if (input.turbines) return input.turbines;

      const branchId = get(getBranchId(id));
      const parkId = get(getParkId(id));

      const turbines = get(
        getTurbinesInBranchSelectorFamily({
          parkId,
          branchId,
          turbineTypeOverride: input.turbineTypeOverride,
        }),
      );

      const zones = input.selectedSubAreas;
      if (zones)
        return turbines.filter((t) =>
          zones.some((z) => pointInPolygon(t.geometry, z.geometry)),
        );
      return turbines;
    },
});

export const getFoundationStats = selectorFamily<
  | {
      totalPrimarySteelMass: number;
      minimumDepth: number;
      maximumDepth: number;
      averageDepth: number;
      minFoundationWeight: number;
      maxFoundationWeight: number;
      averageFoundationWeight: number;
    }
  | undefined,
  { id: ProdId; rasterId: string | undefined }
>({
  key: "getFoundationStats",
  get:
    ({ id, rasterId }) =>
    ({ get }) => {
      if (!rasterId) {
        return undefined;
      }

      const park = get(getPark(id));
      const bathymetry = get(bathymetryAtomFamily(rasterId));
      const floatingFoundationTurbines = get(
        getTurbinesWithFloatingFoundations(id),
      );
      const fixedFoundationTurbines = get(getTurbinesWithFixedFoundations(id));
      const allFloaters = get(allFloaterTypesSelector);

      const turbineTypeIdAndFloatingFoundationIdCombinations =
        turbineTypeAndFloatingFoundationCombinations(
          floatingFoundationTurbines,
          allFloaters,
        );

      const scales = get(
        getScalesForTurbineTypeIdsAndFoundationIds(
          turbineTypeIdAndFloatingFoundationIdCombinations,
        ),
      );

      const fixedTotalMaterial = get(
        getFixedFoundationTotalsSelectorFamily({
          parkId: park.id,
          tempLayoutFoundations: fixedFoundationTurbines,
          rasterId: bathymetry?.id ?? "",
        }),
      );

      const floatingFoundationsTotalMaterials = get(
        getFloatingFoundationDetailsSelectorFamily({
          tempLayoutFoundations: floatingFoundationTurbines,
          scales,
          turbineTypeIdAndFloatingFoundationIdCombinations,
        }),
      );

      const depthsPerTurbine = [
        ...floatingFoundationTurbines,
        ...fixedFoundationTurbines,
      ]
        .map((turbine) => {
          const [lon, lat] = turbine.geometry.coordinates;
          return (bathymetry?.raster as Raster | undefined)?.latLngToValue(
            lon,
            lat,
          );
        })
        .filter(isDefined);

      const minimumDepth = fastMax(depthsPerTurbine, 0);
      const maximumDepth = fastMin(depthsPerTurbine, 0);
      const averageDepth =
        depthsPerTurbine.length > 0
          ? sum(depthsPerTurbine) / depthsPerTurbine.length
          : 0;
      const allMasses = [
        ...fixedTotalMaterial.foundationMasses.map((a) => a.mass),
      ];

      if (floatingFoundationsTotalMaterials) {
        allMasses.push(
          ...floatingFoundationsTotalMaterials.map((total) =>
            sum(Object.values(total)),
          ),
        );
      }

      const totalPrimarySteelMass = sum([
        ...(floatingFoundationsTotalMaterials?.map(
          (f) => f.totalPrimarySteelMass,
        ) ?? []),
        fixedTotalMaterial.totalPrimarySteelMass,
      ]);

      const minFoundationWeight = fastMin(allMasses, 0);
      const maxFoundationWeight = fastMax(allMasses, 0);
      const averageFoundationWeight =
        allMasses.length > 0 ? sum(allMasses) / allMasses.length : 0;

      return {
        totalPrimarySteelMass,
        minimumDepth,
        maximumDepth,
        averageDepth,
        minFoundationWeight,
        maxFoundationWeight,
        averageFoundationWeight,
      };
    },
});

export const getTurbinesWithFixedFoundations = selectorFamily<
  TurbineFeatureWithFoundation[],
  ProdId
>({
  key: "productionv2-turbinesWithFixedFoundations",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const turbines = get(getTurbines(id));
      const allFixed = get(allFixedTypesSelector);

      const turbinesWithFoundation = turbines.filter(
        isTurbineFeatureWithFoundation,
      );
      const fixedFoundationTypeIds = new Set(allFixed.map(({ id }) => id));

      return turbinesWithFoundation.filter((turbineWithFoundation) =>
        fixedFoundationTypeIds.has(
          turbineWithFoundation.properties.foundationId,
        ),
      );
    },
});

export const getTurbinesWithFloatingFoundations = selectorFamily<
  TurbineFeatureWithFoundation[],
  ProdId
>({
  key: "productionv2-turbinesWithFloatingFoundations",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const turbines = get(getTurbines(id));
      const allFloaters = get(allFloaterTypesSelector);

      const turbinesWithFoundation = turbines.filter(
        isTurbineFeatureWithFoundation,
      );
      const floatingFoundationTypeIds = new Set(
        allFloaters.map(({ id }) => id),
      );

      return turbinesWithFoundation.filter((turbineWithFoundation) =>
        floatingFoundationTypeIds.has(
          turbineWithFoundation.properties.foundationId,
        ),
      );
    },
});

const getTurbinesOutsideSelectedZones = selectorFamily<
  TurbineFeature[],
  ProdId
>({
  key: "productionv2-turbinesOutsideSelectedZones",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const input = get(analysisOverrideInputAtomFamily(id));
      if (input.turbines) return [];
      const branchId = get(getBranchId(id));
      const parkId = get(getParkId(id));

      const turbines = get(
        getTurbinesInBranchSelectorFamily({
          parkId,
          branchId,
        }),
      );

      const zones = input.selectedSubAreas;
      if (!zones) return [];
      return turbines.filter(
        (t) => !zones.some((z) => pointInPolygon(t.geometry, z.geometry)),
      );
    },
});

export const getTurbineTypes = selectorFamily<SimpleTurbineType[], ProdId>({
  key: "productionv2-turbineTypes",
  get:
    (_id: ProdId) =>
    ({ get }) =>
      get(allSimpleTurbineTypesSelector),
});

export const getPark = selectorFamily<ParkFeature, ProdId>({
  key: "productionv2-park",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const branchId = get(getBranchId(id));
      const parkId = get(getParkId(id));
      const park = get(
        getParkFeatureInBranchSelector({
          branchId,
          parkId,
        }),
      );
      if (!park) throw new Error("park is undefined");
      return park;
    },
});

export const getSubAreas = selectorFamily<SubAreaFeature[], ProdId>({
  key: "productionv2-subAreas",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const branchId = get(getBranchId(id));
      const parkId = get(getParkId(id));
      return get(
        getDivisionFeaturesInBranchSelectorFamily({
          branchId,
          parkId,
        }),
      ).subAreas;
    },
});

export const getParkOrSubAreaCenter = selectorFamily<Position, ProdId>({
  key: "productionv2-parkOrSubAreaCenter",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const park = get(getPark(id));
      const subAreas = get(getSubAreas(id));
      return getParkCenter(park, subAreas);
    },
});

export const getHubHeightOverride = selectorFamily<number | undefined, ProdId>({
  key: "productionv2-hubHeightOverride",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const input = get(analysisOverrideInputAtomFamily(id));
      return input.hubHeightOverride;
    },
});

export const getTurbineTypeOverride = selectorFamily<
  SimpleTurbineType | undefined,
  ProdId
>({
  key: "productionv2-turbineTypeOverride",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const input = get(analysisOverrideInputAtomFamily(id));
      return input.turbineTypeOverride;
    },
});

/** Weighted average of the hub heights for the used turbines. */
export const getAverageHubHeight = selectorFamily<number, ProdId>({
  key: "productionv2-AverageHubHeight",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const turbines = get(getTurbines(id));
      if (turbines.length === 0) return 150;
      const turbineTypes = get(getTurbineTypes(id));
      const idToheight = Object.fromEntries(
        turbineTypes.map((t) => [t.id, t.hubHeight]),
      );
      const hasInvalidTurbineTypes = turbines.some((t) =>
        turbineTypes.every((tt) => tt.id !== t.properties.turbineTypeId),
      );
      if (hasInvalidTurbineTypes) return 100;
      const average =
        sum(turbines, (t) => idToheight[t.properties.turbineTypeId] ?? 0) /
        turbines.length;
      return Math.round(average);
    },
});

export const getWindSourceConfiguration = selectorFamily<
  WindSourceConfiguration,
  ProdId
>({
  key: "productionv2-WindSourceConfiguration",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const input = get(analysisOverrideInputAtomFamily(id));
      if (input.windConfiguration) return input.windConfiguration;

      const projectId = get(getProjectId(id));
      const branchId = get(getBranchId(id));
      const cfg = get(
        selectedWindConfigurationAtomFamily({ projectId, branchId }),
      );
      if (cfg === undefined)
        throw new WindConfigNotFoundError("cfg is undefined");
      return cfg;
    },
});

export const getConfiguration = selectorFamily<Configuration, ProdId>({
  key: "productionv2-Configuration",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const input = get(analysisOverrideInputAtomFamily(id));
      if (input.configuration) return input.configuration;

      const projectId = get(getProjectId(id));
      const branchId = get(getBranchId(id));
      const cfg = get(
        branchSelectedConfigurationAtomFamily({ projectId, branchId }),
      );
      if (cfg === undefined)
        throw new AnalysisConfigNotFoundError("cfg is undefined");
      return cfg;
    },
});

export const getWindRose = selectorFamily<SimpleWindRose, ProdId>({
  key: "productionv2-WindRose",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const input = get(analysisOverrideInputAtomFamily(id));
      if (input.windRose) return input.windRose;

      const parkCenter = get(getParkOrSubAreaCenter(id));
      const height = get(getAverageHubHeight(id));
      const config = get(getWindSourceConfiguration(id));
      const projectId = get(getProjectId(id));

      const windRose = get(
        windRoseFromConfigSelector({
          lon: parkCenter ? parkCenter[0] : 0,
          lat: parkCenter ? parkCenter[1] : 0,
          height,
          config,
          projectId,
        }),
      );
      if (windRose === undefined) throw new Error("windRose is undefined");
      return windRose;
    },
});

export const getSurroundingTurbines = selectorFamily<TurbineFeature[], ProdId>({
  key: "productionv2-surroundingTurbines",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const input = get(analysisOverrideInputAtomFamily(id));
      if (input.otherTurbines) return input.otherTurbines;

      const parkId = get(getParkId(id));
      const branchId = get(getBranchId(id));
      const configuration = get(getConfiguration(id));

      const surroundingTurbines = configuration.wakeAnalysis.neighbourWake
        ? get(
            getSurroundingTurbineFeaturesInBranchSelector({
              parkId,
              branchId,
              maxDistance: configuration.wakeAnalysis.neighbourWakeMaxDistance,
            }),
          )
        : [];

      const zones = input.selectedSubAreas;
      if (zones) {
        const outsides = get(getTurbinesOutsideSelectedZones(id));
        return outsides.concat(surroundingTurbines);
      }

      return surroundingTurbines;
    },
});

export const getCloseExistingTurbines = selectorFamily<
  ExistingTurbineFeature[],
  ProdId
>({
  key: "productionv2-existingTurbines",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const park = get(getPark(id));
      const maxDistance = get(getConfiguration(id)).wakeAnalysis
        .neighbourWakeMaxDistance;
      const paddedPark = turf.buffer(park, maxDistance);
      if (!paddedPark) {
        scream("Failed to buffer park", { park, maxDistance });
        return [];
      }
      const closeValidExistingTurbines = get(existingTurbinesFeaturesSelector)
        .filter((t) => t.properties.power)
        .filter((t) => turf.inside(t, paddedPark));

      return closeValidExistingTurbines;
    },
});

export const getWakeSettings = selectorFamily<
  WakeAnalysisConfiguration,
  ProdId
>({
  key: "productionv2-wakeSettings",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const settings = get(getConfiguration(id))?.wakeAnalysis;
      const input = get(analysisOverrideInputAtomFamily(id));
      return {
        ...settings,
        precision: input.precision || settings.precision,
      };
    },
});

export const getTriggerAnalysisArgs = selectorFamily<
  Parameters<typeof triggerAnalysis>[0],
  ProdId
>({
  key: "productionv2-triggerAnalysis",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const nodeId = get(getProjectId(id));
      const turbines = get(getTurbines(id));
      const parkCenter = get(getParkOrSubAreaCenter(id));

      const configuration = get(getConfiguration(id));
      const park = get(getPark(id));
      const neighbourTurbines = configuration.wakeAnalysis.neighbourWake
        ? get(getSurroundingTurbines(id))
        : [];
      const existingTurbines = configuration.wakeAnalysis.neighbourWake
        ? get(getCloseExistingTurbines(id))
        : [];
      const windConfiguration = get(getWindSourceConfiguration(id));
      const wakeSettings = get(getWakeSettings(id));
      const hubHeightOverride = get(getHubHeightOverride(id));
      const turbineTypeOverride = get(getTurbineTypeOverride(id));
      const restart = get(analysisOverrideInputAtomFamily(id)).restart;

      return {
        nodeId,
        turbines,
        neighbourTurbines,
        existingTurbines,
        wakeSettings,
        hubHeightOverride,
        turbineTypeOverride,
        windConfiguration,
        park,
        parkCenter,
        restart,
      };
    },
});

/**
 * Check if we have any turbines that are not connected to a substation
 */
const getHasDisconnectedTurbines = selectorFamily<boolean, ProdId>({
  key: "productionv2-hasDisconnectedTurbines",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const parkId = get(getParkId(id));
      const branchId = get(getBranchId(id));
      const chains = get(
        cableChainsInBranchSelectorFamily({ parkId, branchId }),
      );
      if (chains.length === 0) return false;
      const turbines = get(getTurbines(id));

      const turbineIdsInChain = new Set(chains.flatMap((c) => c.turbines));
      return !!turbines.find((t) => !turbineIdsInChain.has(t.id));
    },
});

/**
 * Check if we have any substations that are not connected to an export cable
 */

function getDisconnectedSubstations(
  substations: SubstationFeature[],
  exportCables: ExportCableFeature[],
) {
  if (exportCables.length === 0 || substations.length === 0) return [];
  const disconnectedSubstations = substations.filter(
    (s) =>
      !exportCables.some(
        (c) =>
          c.properties.fromSubstationId === s.id ||
          c.properties.toSubstationId === s.id,
      ),
  );

  return disconnectedSubstations;
}

export const getDisconnectedExportSubstations = selectorFamily<
  SubstationFeature[],
  ProdId
>({
  key: "productionv2-disconnectedExportSubstations",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const parkId = get(getParkId(id));
      const branchId = get(getBranchId(id));
      const substations = get(
        getSubstationsInBranchSelectorFamily({ parkId, branchId }),
      );
      const exportCables = get(
        getExportCablesInBranchSelectorFamily({ parkId, branchId }),
      );
      const disconnectedSubstations = getDisconnectedSubstations(
        substations,
        exportCables,
      );
      return disconnectedSubstations;
    },
});

export const getDisconnectedExportSubstationsInParkAndBranch = selectorFamily<
  SubstationFeature[],
  { parkId: string; branchId: string }
>({
  key: "productionv2-disconnectedExportSubstationsInParkAndBranch",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const substations = get(
        getSubstationsInBranchSelectorFamily({ parkId, branchId }),
      );
      const exportCables = get(
        getExportCablesInBranchSelectorFamily({ parkId, branchId }),
      );
      const disconnectedSubstations = getDisconnectedSubstations(
        substations,
        exportCables,
      );
      return disconnectedSubstations;
    },
});

/**
 * Check if we have any export cables that are not connected to an offshore substation at one end, and an onshore substation at the other end
 */

function getDisconnectedExportCablesFromSubstation(
  substations: SubstationFeature[],
  exportCables: ExportCableFeature[],
  substationTypes: SubstationType[],
) {
  if (exportCables.length === 0 || substations.length === 0) return [];

  const disconnectedExportCables = exportCables.filter((c) => {
    const fromSubstation = substations.find(
      (s) => s.id === c.properties.fromSubstationId,
    );
    const toSubstation = substations.find(
      (s) => s.id === c.properties.toSubstationId,
    );

    const fromSubstationType = substationTypes.find(
      (st) => st.id === fromSubstation?.properties.substationTypeId,
    )?.type;
    const toSubstationType = substationTypes.find(
      (st) => st.id === toSubstation?.properties.substationTypeId,
    )?.type;

    return !(
      (fromSubstationType === "offshore" && toSubstationType === "onshore") ||
      (fromSubstationType === "onshore" && toSubstationType === "offshore")
    );
  });

  return disconnectedExportCables;
}
export const getDisconnectedExportCables = selectorFamily<
  ExportCableFeature[],
  ProdId
>({
  key: "productionv2-disconnectedExportCables",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const parkId = get(getParkId(id));
      const branchId = get(getBranchId(id));
      const substations = get(
        getSubstationsInBranchSelectorFamily({ parkId, branchId }),
      );
      const exportCables = get(
        getExportCablesInBranchSelectorFamily({ parkId, branchId }),
      );
      const substationTypes = get(currentSubstationTypesState);
      const disconnectedExportCables =
        getDisconnectedExportCablesFromSubstation(
          substations,
          exportCables,
          substationTypes,
        );
      return disconnectedExportCables;
    },
});

export const getDisconnectedExportCablesInParkAndBranch = selectorFamily<
  ExportCableFeature[],
  { parkId: string; branchId: string }
>({
  key: "productionv2-disconnectedExportCablesInParkAndBranch",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const substations = get(
        getSubstationsInBranchSelectorFamily({ parkId, branchId }),
      );
      const exportCables = get(
        getExportCablesInBranchSelectorFamily({ parkId, branchId }),
      );
      const substationTypes = get(currentSubstationTypesState);
      const disconnectedExportCables =
        getDisconnectedExportCablesFromSubstation(
          substations,
          exportCables,
          substationTypes,
        );
      return disconnectedExportCables;
    },
});

/**
 * Check if we have any export cables that do not have both an offshore and an onshore cable type defined
 */
export const getHasExportCablesMissingTypes = selectorFamily<boolean, ProdId>({
  key: "productionv2-hasExportCablesMissingTypes",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const parkId = get(getParkId(id));
      const branchId = get(getBranchId(id));

      const exportCables = get(
        getExportCablesInBranchSelectorFamily({ parkId, branchId }),
      );
      const exportCableTypes = get(currentExportCableTypesSelector);

      if (exportCables.length === 0) return false;

      const exportCablesMissingTypes = exportCables.filter((c) => {
        const offshoreCableType = exportCableTypes.find(
          (ct) => ct.id === c.properties.cableTypeId,
        );
        const onshoreCableType = exportCableTypes.find(
          (ct) => ct.id === c.properties.onshoreCableTypeId,
        );

        return !offshoreCableType || !onshoreCableType;
      });

      return exportCablesMissingTypes.length > 0;
    },
});

export const getHasExportCablesTooLongTypes = selectorFamily<boolean, ProdId>({
  key: "productionv2-hasExportCablesTooLongTypes",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const parkId = get(getParkId(id));
      const branchId = get(getBranchId(id));
      const configuration = get(getConfiguration(id));

      if (!configuration?.electrical.exportSystemLoss) return false;

      const res = get(
        exportCableTooLongWarningErrorSelectorFamily({ parkId, branchId }),
      );
      return res?.type === ValidationWarningTypes.ExportCableTooLongError;
    },
});

/**
 * Check if we have any inter-array cables without a valid cable type
 */
export const getHasCablesMissingTypes = selectorFamily<boolean, ProdId>({
  key: "productionv2-HasCablesMissingTypes",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const parkId = get(getParkId(id));
      const branchId = get(getBranchId(id));

      const cables = get(getCablesInBranchSelectorFamily({ parkId, branchId }));
      const cableTypes = get(allCableTypesSelector);

      if (cables.length === 0) return false;

      const cablesMissingTypes = cables.filter((c) =>
        cableTypes.every((ct) => ct.id !== c.properties.cableTypeId),
      );

      return cablesMissingTypes.length > 0;
    },
});

/**
 * Check if we have any substations where number of export cables are larger than number of cable strings
 */
export const getSubstationHasTooManyExportCables = selectorFamily<
  boolean,
  ProdId
>({
  key: "productionv2-substationHasTooManyExportCables",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const parkId = get(getParkId(id));
      const branchId = get(getBranchId(id));
      const substations = get(
        getSubstationsInBranchSelectorFamily({ parkId, branchId }),
      );
      const substationTypes = get(currentSubstationTypesState);
      const offshoreSubstations = substations.filter(
        (s) =>
          substationTypes.find((st) => st.id === s.properties.substationTypeId)
            ?.type === "offshore",
      );
      const exportCables = get(
        getExportCablesInBranchSelectorFamily({ parkId, branchId }),
      );

      const cables = get(
        getCablesInBranchSelectorFamily({
          parkId,
          branchId,
        }),
      );

      if (
        exportCables.length === 0 ||
        offshoreSubstations.length === 0 ||
        cables.length === 0
      )
        return false;

      const invalidSubstations = offshoreSubstations.filter((s) => {
        const subCableChains = cables.filter(
          (c) => c.properties.fromId === s.id || c.properties.toId === s.id,
        );
        const subExportCables = exportCables.filter(
          (c) =>
            c.properties.fromSubstationId === s.id ||
            c.properties.toSubstationId === s.id,
        );

        return subExportCables.length > subCableChains.length;
      });

      return invalidSubstations.length > 0;
    },
});

const getOverlappingTurbines = selectorFamily<boolean, ProdId>({
  key: "productionv2-overlappingTurbines",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const turbines = get(getTurbines(id));
      const otherTurbines = get(getSurroundingTurbines(id));
      const allTurbines = turbines.concat(otherTurbines);
      const turbineTypes = Object.fromEntries(
        get(allSimpleTurbineTypesSelector).map((t) => [t.id, t]),
      );
      const overlaps = getOverlappingTurbinesEfficient(
        allTurbines,
        turbineTypes,
      );
      return 0 < overlaps.length;
    },
});

const getOverlappingExistingTurbines = selectorFamily<boolean, ProdId>({
  key: "productionv2-overlappingExistingTurbines",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const turbines = get(getCloseExistingTurbines(id));
      const overlaps = overlappingPointFeatures(
        turbines,
        EXISTING_TURBINE_OVERLAP_DISTANCE,
      );
      return 0 < overlaps.length;
    },
});

const getNumberOfTurbinesIsZero = selectorFamily<boolean, ProdId>({
  key: "productionv2-numberOfTurbinesIsZero",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const turbines = get(getTurbines(id));
      return turbines.length === 0;
    },
});

const getNumberOfTurbinesIsTooHigh = selectorFamily<boolean, ProdId>({
  key: "productionv2-numberOfTurbinesIsTooHigh",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const turbines = get(getTurbines(id));
      const otherTurbines = get(getSurroundingTurbines(id));
      const existingTurbines = get(getCloseExistingTurbines(id));
      const totalNumberOfTurbines =
        turbines.length + otherTurbines.length + existingTurbines.length;
      return MAX_TOTAL_TURBINES < totalNumberOfTurbines;
    },
});

const getTooShortCables = selectorFamily<boolean, ProdId>({
  key: "productionv2-tooShortCables",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const branchId = get(getBranchId(id));
      const parkId = get(getParkId(id));
      const cables = get(getCablesInBranchSelectorFamily({ parkId, branchId }));
      return (
        cables.find(
          (c) => turf.length(c, { units: "kilometers" }) < MIN_CABLE_LENGTH_KM,
        ) !== undefined
      );
    },
});

const getTurbinesOutsidePark = selectorFamily<boolean, ProdId>({
  key: "productionv2-turbinesOutsidePark",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const park = get(getPark(id));
      const turbines = get(getTurbines(id));
      return (
        0 <
        turbines.filter((t) => !pointInPolygon(t.geometry, park.geometry))
          .length
      );
    },
});

const getParkTurbineTypeIsMissing = selectorFamily<boolean, ProdId>({
  key: "productionv2-parkTurbineTypeIsMissing",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const turbines = get(getTurbines(id));
      const types = get(getTurbineTypes(id));
      const typeIds = new Set(types.map((t) => t.id));
      return !turbines.every((t) => typeIds.has(t.properties.turbineTypeId));
    },
});

const getNeighbourTurbineTypeIsMissing = selectorFamily<boolean, ProdId>({
  key: "productionv2-neighbourTurbineTypeIsMissing",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const otherTurbines = get(getSurroundingTurbines(id));
      const types = get(getTurbineTypes(id));
      const typeIds = new Set(types.map((t) => t.id));
      return !otherTurbines.every((t) =>
        typeIds.has(t.properties.turbineTypeId),
      );
    },
});

const getArgsStoppedReason = selectorFamily<
  AnalysisStoppedTypes | undefined,
  ProdId
>({
  key: "productionv2-argsStoppedReason",
  get:
    (id: ProdId) =>
    ({ get }) => {
      if (get(getOverlappingTurbines(id)))
        return AnalysisStoppedTypes.TurbinesOverlap;
      if (get(getOverlappingExistingTurbines(id)))
        return AnalysisStoppedTypes.ExistingTurbinesOverlap;
      if (get(getNumberOfTurbinesIsZero(id)))
        return AnalysisStoppedTypes.NoTurbines;
      if (get(getNumberOfTurbinesIsTooHigh(id)))
        return AnalysisStoppedTypes.TooManyTurbines;
      if (get(getTooShortCables(id)))
        return AnalysisStoppedTypes.TooShortCables;
      if (get(getTurbinesOutsidePark(id)))
        return AnalysisStoppedTypes.TurbinesOutsidePark;
      if (get(getParkTurbineTypeIsMissing(id)))
        return AnalysisStoppedTypes.TurbinesNotFound;
      if (get(getNeighbourTurbineTypeIsMissing(id)))
        return AnalysisStoppedTypes.NeighbourTurbinesNotFound;

      const configuration = get(getConfiguration(id));
      if (
        configuration.electrical.turbineTrafoLoss ||
        configuration.electrical.interArrayCableLoss
      ) {
        if (get(getHasDisconnectedTurbines(id)))
          return AnalysisStoppedTypes.TurbineNotConnected;
        if (get(getHasCablesMissingTypes(id)))
          return AnalysisStoppedTypes.CableMissingType;
      }
      if (configuration.electrical.exportSystemLoss) {
        if (get(getDisconnectedExportSubstations(id)).length > 0)
          return AnalysisStoppedTypes.SubstationNotConnected;
        if (get(getDisconnectedExportCables(id)).length > 0)
          return AnalysisStoppedTypes.ExportCableNotConnected;
        if (get(getHasExportCablesMissingTypes(id)))
          return AnalysisStoppedTypes.ExportCableMissingType;
        if (get(getHasExportCablesTooLongTypes(id)))
          return AnalysisStoppedTypes.ExportCableTooLongType;
        if (get(getSubstationHasTooManyExportCables(id)))
          return AnalysisStoppedTypes.SubstationHasTooManyExportCables;
      }
    },
});

export const getTriggerAnalysisRefresh = atomFamily<number, ProdId>({
  key: "getTriggerAnalysisRefresh",
  default: 0,
});

const getTriggerAnalysis = selectorFamily<AnalysisStatus, ProdId>({
  key: "productionv2-TriggerAnalysis",
  get:
    (id: ProdId) =>
    async ({ get }) => {
      const args = get(getTriggerAnalysisArgs(id));
      const reason = get(getArgsStoppedReason(id));
      if (reason !== undefined)
        return {
          status: "failed",
          id: "getTriggerAnalysis-check-failed",
          version: "",
        };
      const _ = get(getTriggerAnalysisRefresh(id));
      const res = await triggerAnalysis(args);
      return res;
    },
});

/**
 * You need to set {@link analysisOverrideInputAtomFamily} with the {@link ProdId} before this will return.
 */
export const getAnalysis = selectorFamily<AnalysisStatus, ProdId>({
  key: "productionv2-Analysis",
  get:
    (id: ProdId) =>
    async ({ get }) => {
      // Either we use the response from the fetch call, or we look at the atom
      // that is set by the ably subscription.
      const triggerStatus = get(getTriggerAnalysis(id));
      const ablyStatus = get(
        analysisStatusAtomFamily({
          analysisStatusId: triggerStatus.id,
        }),
      );
      const status = ablyStatus ?? triggerStatus;

      if (status.status === "complete" && !status.stats) {
        const projectId = get(getProjectId(id));
        const stats = await fetchAnalysisResult({
          nodeId: projectId,
          id: status.id,
          version: status.version,
        });
        return {
          ...status,
          stats,
        };
      }
      return status;
    },
});

/**
 * Get the completed analysis result.
 * Block until the analysis is ready, i.e. when it is `running` or `started`.
 */
const getFinishedAnalysis = selectorFamily<AnalysisStatusDone, ProdId>({
  key: "getFinishedAnalysis",
  get:
    (id) =>
    ({ get }) => {
      const pendingAnalysis = get(getAnalysis(id));
      if (analysisIsComplete(pendingAnalysis)) return pendingAnalysis;
      return get(
        finishedAnalysisStatusAtomFamily({
          analysisStatusId: pendingAnalysis.id,
        }),
      );
    },
});

/**
 * Get the stats from a successful analysis result.
 * **Blocks** if the analysis is not ready yet.
 * **`throw`s** if the analysis failed.
 */
export const getAnalysisStats = selectorFamily<AnalysisResult, ProdId>({
  key: "getAnalysisStats",
  get:
    (id) =>
    ({ get }) => {
      const analysis = get(getFinishedAnalysis(id));
      if (!analysis.stats) throw new Error("Analysis.stats was undefined");
      return analysis.stats;
    },
});

export const getAnalysisVersion = selectorFamily<string, ProdId>({
  key: "productionv2-AnalysisVersion",
  get:
    (id) =>
    ({ get }) => {
      const analysis = get(getAnalysis(id));
      const version: string = analysis?.version;
      return version;
    },
});

export const getAnalysisTimeseries = selectorFamily<
  AnalysisTimeseries | undefined,
  ProdId
>({
  key: "productionv2-AnalysisTimeseries",
  get:
    (id: ProdId) =>
    async ({ get }) => {
      const analysis = get(getAnalysis(id));
      if (analysis?.status !== "complete") return undefined;
      const efficiency = get(getTotalSystemEfficiency(id));
      if (efficiency === undefined) return undefined;
      const projectId = get(getProjectId(id));
      const data = await fetchAnalysisTimeseries({
        nodeId: projectId,
        id: analysis.id,
        version: analysis.version,
      });
      return {
        time: data.time,
        date: data.date,
        powerPerTime: data.powerPerTime.map((p) => p * efficiency),
        powerPerTimePostWake: data.powerPerTimePostWake,
        energyPerDate: data.energyPerDate.map((e) => e * efficiency),
      };
    },
});

export const getAnalysisWindStats = selectorFamily<
  AnalysisWindStats | undefined,
  ProdId
>({
  key: "productionv2-AnalysisWindStats",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const analysis = get(getAnalysis(id));
      if (analysis?.status !== "complete") return undefined;
      const projectId = get(getProjectId(id));
      return fetchAnalysisWindStats({
        nodeId: projectId,
        id: analysis.id,
        version: analysis.version,
      });
    },
});

export const getAnalysisMeanSpeedGrid = selectorFamily<
  MeanSpeedGrid | WRG | undefined | null,
  ProdId
>({
  key: "productionv2-AnalysisMeanSpeedGrid",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const nodeId = get(getProjectId(id));
      const stats = get(getAnalysisWindStats(id));
      const spatialCalibration = stats?.spatialCalibration;

      if (!stats || !spatialCalibration) return null;

      if (spatialCalibration.type === "custom") {
        return fetchCustomCalibrationFile({
          nodeId,
          id: spatialCalibration.id,
        });
      } else if (spatialCalibration.type === "global_wind_atlas") {
        const { turbines, neighbourTurbines } = get(getTriggerAnalysisArgs(id));

        const positions = [...turbines, ...neighbourTurbines].map(
          (t) => t.geometry.coordinates,
        );

        const multiPoint = turf.multiPoint(positions);

        const [xmin, ymin, xmax, ymax] = turf.bbox(
          turf.buffer(multiPoint, 0.5, {
            units: "kilometers",
          }),
        );

        return fetchGwaSpeedGrid({
          bbox: { xmin, ymin, xmax, ymax },
          height: stats.height,
        });
      }

      return null;
    },
});

export const getAnalysisPercentiles = selectorFamily<
  AnalysisPercentiles | undefined,
  ProdId
>({
  key: "productionv2-AnalysisPercentiles",
  get:
    (id: ProdId) =>
    async ({ get }) => {
      const analysis = get(getAnalysis(id));
      if (analysis?.status !== "complete") return undefined;
      const efficiency = get(getTotalSystemEfficiency(id));
      if (efficiency === undefined) return undefined;
      const projectId = get(getProjectId(id));
      const data = await fetchAnalysisPercentiles({
        nodeId: projectId,
        id: analysis.id,
        version: analysis.version,
      });
      return {
        preArrayEnergyPerMonth: data.preArrayEnergyPerMonth.map(
          (e) => e * efficiency,
        ),
        preArrayEnergyPerYear: data.preArrayEnergyPerYear.map(
          (e) => e * efficiency,
        ),
        preArrayEnergyPerYearDistribution: {
          mean: data.preArrayEnergyPerYearDistribution.mean * efficiency,
          std: data.preArrayEnergyPerYearDistribution.std * efficiency,
        },
        preArrayEnergyPerYearPercentiles:
          data.preArrayEnergyPerYearPercentiles.map((e) => ({
            percentile: e.percentile,
            value: e.value * efficiency,
          })),
      };
    },
});

export const getAEPPerTurbine = selectorFamily<TurbineStat[], ProdId>({
  key: "productionv2-AEPPerTurbine",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const stats = get(getAnalysisStats(id));
      const turbines = get(getTurbines(id));
      return computeGrossEnergyProductionPerTurbine(
        stats.grossPerTurbine,
        stats.turbineLosses,
        stats.totalWakeLossPerTurbine,
        turbines,
      );
    },
});

export const getAverageSpeedPerTurbine = selectorFamily<TurbineStat[], ProdId>({
  key: "productionv2-averageSpeedPerTurbine",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const stats = get(getAnalysisStats(id));
      const turbines = get(getTurbines(id));
      return zip(turbines, stats.averageSpeedPerTurbine).map(
        ([turbine, speed]) => ({
          turbine,
          value: speed,
        }),
      );
    },
});

export const getAverageTurbineSpeed = selectorFamily<number, ProdId>({
  key: "productionv2-averageTurbineSpeed",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const stats = get(getAnalysisStats(id));
      return (
        sum(stats.averageSpeedPerTurbine) / stats.averageSpeedPerTurbine.length
      );
    },
});

export const getGrossEnergy = selectorFamily<number, ProdId>({
  key: "productionv2-GrossEnergy",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const stats = get(getAnalysisStats(id));
      return sum(stats.grossPerTurbine);
    },
});

export const getTotalLoss = selectorFamily<number | undefined, ProdId>({
  key: "productionv2-TotalLoss",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const aep = get(getAEP(id));
      if (aep === undefined) return undefined;

      const gross = get(getGrossEnergy(id));
      if (gross === undefined) return undefined;

      return (gross - aep) / gross;
    },
});

export const getTotalWakeLossPerTurbine = selectorFamily<TurbineStat[], ProdId>(
  {
    key: "getWakeLossPerTurbine",
    get:
      (id: ProdId) =>
      ({ get }) => {
        const { turbines } = get(getTriggerAnalysisArgs(id));
        const stats = get(getAnalysisStats(id));
        return zip(turbines, stats.totalWakeLossPerTurbine).map(
          ([turbine, wakeLoss]) => ({
            turbine: turbine,
            value: wakeLoss,
          }),
        );
      },
  },
);

export const getTurbineSpecificLoss = selectorFamily<number, ProdId>({
  key: "productionv2-TurbineSpecificLoss",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const stats = get(getAnalysisStats(id));
      const gross = get(getGrossEnergy(id));
      return computeLoss(stats.grossPerTurbine, stats.turbineLosses, gross);
    },
});

export const getTotalWakeLoss = selectorFamily<number, ProdId>({
  key: "productionv2-TotalWakeLoss",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const stats = get(getAnalysisStats(id));
      const gross = get(getGrossEnergy(id));
      return computeLoss(
        stats.grossPerTurbine,
        stats.totalWakeLossPerTurbine,
        gross,
      );
    },
});

export const getCapacity = selectorFamily<number | undefined, ProdId>({
  key: "productionv2-Capacity",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const turbines = get(getTurbines(id));
      const turbineTypes = get(getTurbineTypes(id));
      const hasInvalidTurbineTypes = turbines.some((t) =>
        turbineTypes.every((tt) => tt.id !== t.properties.turbineTypeId),
      );
      if (hasInvalidTurbineTypes) return undefined;
      const capacity = computeCapacity(turbineTypes, turbines);
      return capacity;
    },
});

export const getElectricalPowerBins = selectorFamily<
  number[] | undefined,
  ProdId
>({
  key: "productionv2-PowerBins",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const capacityMW = get(getCapacity(id));
      if (!capacityMW) return;

      const capacity = capacityMW / 1000;

      const powerBins = Array.from(
        { length: NUM_ELECTRICAL_POWER_BINS + 1 },
        (_, i) => (i / NUM_ELECTRICAL_POWER_BINS) * capacity,
      );

      return powerBins;
    },
});

export const getElectricalPowerBinsProbabilities = selectorFamily<
  number[] | undefined,
  ProdId
>({
  key: "productionv2-PowerBins-Probabilities",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const analysis = get(getAnalysis(id));
      if (!analysis?.stats?.productionHistograms) return;
      return analysis.stats.productionHistograms.netProduction;
    },
});

function removePatchVersion(version: string): string {
  return version.split(".").slice(0, 2).join(".");
}

export const isOutdated = selectorFamily<boolean, ProdId>({
  key: "productionv2-isOutdated",
  get:
    (id: ProdId) =>
    async ({ get }) => {
      const analysis = get(getAnalysis(id));
      if (analysis?.status !== "complete") return false;
      const currentVersion = await fetchLatestAnalysisVersion();
      const thisMinor = removePatchVersion(analysis.version);
      const latestMinor = removePatchVersion(currentVersion);
      return thisMinor !== latestMinor;
    },
});

export const getOtherLosses = selectorFamily<number, ProdId>({
  key: "productionv2-OtherLosses",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const configuration = get(getConfiguration(id));
      const otherLoss = computeOtherLosses(configuration.energyLosses);
      return otherLoss;
    },
});

export const getAEP = selectorFamily<number | undefined, ProdId>({
  key: "productionv2-AEP",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const aepPerTurbine = get(getAEPPerTurbine(id));

      const iaLoss = get(getTotalInterArrayLoss(id));
      if (iaLoss === undefined) return undefined;

      const totalInterArrayLoss = iaLoss.totalInterArrayLoss;
      if (totalInterArrayLoss === undefined) return undefined;

      const otherLoss = get(getOtherLosses(id));

      const gross = get(getGrossEnergy(id));
      if (gross === undefined) return undefined;

      const wakeLoss = get(getTotalWakeLoss(id));
      if (wakeLoss === undefined) return undefined;

      const totalExportSystemLoss = get(getTotalExportSystemLoss(id));

      return computeAEP(
        aepPerTurbine,
        otherLoss,
        totalInterArrayLoss,
        totalExportSystemLoss?.totalExportSystemLoss ?? null,
      );
    },
});

export const getPostWakeEnergy = selectorFamily<number, ProdId>({
  key: "productionv2-post-wake-energy",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const stats = get(getAnalysisStats(id));
      const turbines = get(getTurbines(id));
      const perTurbine = computePostWakeEnergyProductionPerTurbine(
        stats.grossPerTurbine,
        stats.totalWakeLossPerTurbine,
        turbines,
      );
      return perTurbine.reduce((acc, p) => (acc += p.value), 0);
    },
});

export const getTotalSystemEfficiency = selectorFamily<
  number | undefined,
  ProdId
>({
  key: "productionv2-total-system-efifciency",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const iaLoss = get(getTotalInterArrayLoss(id));
      if (iaLoss === undefined) return undefined;
      const totalInterArrayLoss = iaLoss.totalInterArrayLoss;
      if (totalInterArrayLoss === undefined) return undefined;

      const totalExportSystemLoss = get(
        getTotalExportSystemLoss(id),
      )?.totalExportSystemLoss;
      if (totalExportSystemLoss === undefined) return undefined;
      const otherLoss = get(getOtherLosses(id));
      return computeEfficiency(
        otherLoss,
        totalInterArrayLoss,
        totalExportSystemLoss,
      );
    },
});

export const getAEPDistribution = selectorFamily<
  { mean: number; std: number } | undefined,
  ProdId
>({
  key: "productionv2-aep-distribution",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const data = get(getAnalysisPercentiles(id));
      if (!data) return undefined;
      return data.preArrayEnergyPerYearDistribution;
    },
});

export const getNetEmpiricalPercentiles = selectorFamily<
  Percentile[] | undefined,
  ProdId
>({
  key: "productionv2-empirical-percentile",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const data = get(getAnalysisPercentiles(id));
      if (!data) return undefined;
      return data.preArrayEnergyPerYearPercentiles;
    },
});

export const getAverageMonthlyEnergy = selectorFamily<
  number[] | undefined,
  ProdId
>({
  key: "productionv2-monthlyEnergy",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const data = get(getAnalysisPercentiles(id));
      if (!data) return undefined;
      return data.preArrayEnergyPerMonth;
    },
});

export const getCapacityFactor = selectorFamily<number | undefined, ProdId>({
  key: "productionv2-CapacityFactor",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const aep = get(getAEP(id));
      if (aep === undefined) return undefined;
      const capacity = get(getCapacity(id));
      if (capacity === undefined) return undefined;
      return computeCapacityFactor(aep, capacity);
    },
});

export const getNeighbourWakeLoss = selectorFamily<number | undefined, ProdId>({
  key: "productionv2-NeighbourWakeLoss",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const totalWakeLoss = get(getTotalWakeLoss(id));
      if (totalWakeLoss === undefined) return undefined;

      const gross = get(getGrossEnergy(id));
      if (gross === undefined) return undefined;

      const stats = get(getAnalysis(id))?.stats;
      if (!stats) return undefined;
      const internalWakeLoss = computeInternalWakeLoss(
        stats.grossPerTurbine,
        stats.internalWakeLossPerTurbine,
        gross,
      );
      return totalWakeLoss - internalWakeLoss;
    },
});

export const getProductionHistograms = selectorFamily<
  | {
      gross: {
        bins: number[];
        cumulativeProduction: number[];
      };
      net: {
        bins: number[];
        cumulativeProduction: number[];
      };
    }
  | undefined,
  ProdId
>({
  key: "productionv2-ProductionHistograms",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const analysis = get(getAnalysis(id));
      const stats = analysis?.stats;
      if (!stats) return undefined;
      const { bins, cumulativeGrossProduction, cumulativeNetProduction } =
        stats.productionHistograms;

      const totalInterArrayLoss = get(
        getTotalInterArrayLoss(id),
      )?.totalInterArrayLoss;
      if (totalInterArrayLoss === undefined) return undefined;

      const totalExportSystemLoss = get(
        getTotalExportSystemLoss(id),
      )?.totalExportSystemLoss;
      if (totalExportSystemLoss === undefined) return undefined;

      const otherLoss = get(getOtherLosses(id));

      const efficiency = computeEfficiency(
        otherLoss,
        totalInterArrayLoss,
        totalExportSystemLoss,
      );
      const gross = {
        bins,
        cumulativeProduction: cumulativeGrossProduction,
      };
      const net = {
        bins: bins.map((b) => b * efficiency),
        cumulativeProduction: cumulativeNetProduction,
      };
      return {
        gross,
        net,
      };
    },
});

export const getTotalInterArrayLoss = selectorFamily<
  Awaited<ReturnType<typeof computeTotalInterArrayLoss>> | undefined,
  ProdId
>({
  key: "productionv2-TotalInterArrayLoss",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const analysis = get(getAnalysis(id));

      const productionHistograms = analysis?.stats?.productionHistograms;

      const configuration = get(getConfiguration(id));
      if (
        !configuration.electrical.interArrayCableLoss &&
        !configuration.electrical.turbineTrafoLoss
      )
        return {
          totalInterArrayLoss: null,
          totalInterArrayCableLoss: null,
          totalTurbineTrafoLoss: null,
        };

      const interArrayLosses = get(getInterArrayLosses(id));

      const electricalPowerBins = get(getElectricalPowerBins(id));

      if (interArrayLosses === undefined) return undefined;

      return computeTotalInterArrayLoss(
        productionHistograms,
        interArrayLosses,
        configuration.electrical,
        electricalPowerBins,
      );
    },
});

export const getInterArrayLosses = selectorFamily<
  IALossType | undefined,
  ProdId
>({
  key: "productionv2-InterArrayLosses",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const powerBins = get(getElectricalPowerBins(id));
      if (!powerBins) return undefined;

      const branchId = get(getBranchId(id));
      const parkId = get(getParkId(id));
      const projectId = get(getProjectId(id));
      const configuration = get(getConfiguration(id));
      const exportCableTooLongWarningError = get(
        exportCableTooLongWarningErrorSelectorFamily({ parkId, branchId }),
      );

      if (
        exportCableTooLongWarningError?.type ===
        ValidationWarningTypes.ExportCableTooLongError
      )
        return;

      const input = get(
        getElectricalAnalysisArgs({ branchId, parkId, configuration }),
      );

      if (input === null) {
        return { status: LossStatusType.Complete, results: null };
      }

      if (!input) return;

      const result = get(
        getElectricalAnalysis({
          projectId,
          input,
          powerBins,
        }),
      );
      if (!result) return;

      return { status: result?.status, results: result?.interArrayResult };
    },
});

export const getInterArrayLossesPerCable = selectorFamily<
  Awaited<ReturnType<typeof computeWeightedInterArrayLossPerCable>> | undefined,
  ProdId
>({
  key: "productionv2-InterArrayLossesPerCable",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const analysis = get(getAnalysis(id));

      const productionHistograms = analysis?.stats?.productionHistograms;

      const configuration = get(getConfiguration(id));

      const interArrayLosses = get(getInterArrayLosses(id));

      if (interArrayLosses === undefined) return undefined;

      return computeWeightedInterArrayLossPerCable(
        productionHistograms,
        interArrayLosses,
        configuration.electrical,
      );
    },
});

export const getInterArrayLossesFailed = selectorFamily<
  AnalysisStoppedTypes | undefined,
  ProdId
>({
  key: "productionv2-InterArrayLossesFailed",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const configuration = get(getConfiguration(id));
      if (
        !configuration.electrical.turbineTrafoLoss &&
        !configuration.electrical.interArrayCableLoss
      )
        return undefined;

      const interArrayLossesL = get(noWait(getInterArrayLosses(id)));
      if (interArrayLossesL.state === "hasError")
        return AnalysisStoppedTypes.InterArrayFailed;
      const interArrayLosses = interArrayLossesL.getValue();
      if (interArrayLosses?.status === LossStatusType.Failed)
        return AnalysisStoppedTypes.InterArrayFailed;
    },
});

const getExportSystemLossesStoppedReason = selectorFamily<
  AnalysisStoppedTypes | undefined,
  ProdId
>({
  key: "getExportSystemLossesStoppedReason",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const parkId = get(getParkId(id));
      const branchId = get(getBranchId(id));
      const cables = get(getCablesInBranchSelectorFamily({ parkId, branchId }));
      if (cables.length === 0) return undefined;
      const chains = get(
        cableChainsInBranchSelectorFamily({ parkId, branchId }),
      );
      const turbines = get(getTurbines(id));
      const turbineIdsInChain = new Set(chains.flatMap((c) => c.turbines));

      const anyDisconnectedTurbines = !!turbines.find(
        (t) => !turbineIdsInChain.has(t.id),
      );
      if (anyDisconnectedTurbines)
        return AnalysisStoppedTypes.TurbineNotConnected;

      const substations = get(
        getSubstationsInBranchSelectorFamily({ parkId, branchId }),
      );
      const substationTypes = get(currentSubstationTypesState);

      const existOffshoreSubWithoutCables = substations
        .filter((s) => {
          const typ = s.properties.substationTypeId;
          const subType = substationTypes.find((ss) => ss.id === typ);
          if (!subType) return false;
          return subType.type === "offshore";
        })
        .some((sub) => {
          const adjacentTurbines = chains
            .filter((c) => c.substation === sub.id)
            .flatMap((c) => c.turbines);
          return adjacentTurbines.length === 0;
        });
      if (existOffshoreSubWithoutCables)
        return AnalysisStoppedTypes.SubstationHasNoTurbines;

      return undefined;
    },
});

export const getExportSystemLosses = selectorFamily<
  ExportLossType | undefined,
  ProdId
>({
  key: "productionv2-ExportSystemLosses",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const powerBins = get(getElectricalPowerBins(id));
      if (!powerBins) return undefined;

      const branchId = get(getBranchId(id));
      const projectId = get(getProjectId(id));
      const parkId = get(getParkId(id));
      const configuration = get(getConfiguration(id));

      const exportCableTooLongWarningError = get(
        exportCableTooLongWarningErrorSelectorFamily({ parkId, branchId }),
      );

      if (
        exportCableTooLongWarningError?.type ===
        ValidationWarningTypes.ExportCableTooLongError
      )
        return;

      const stop = get(getExportSystemLossesStoppedReason(id));
      if (stop) {
        return undefined;
      }

      const analysisOverrideInput = get(analysisOverrideInputAtomFamily(id));

      const input = get(
        getElectricalAnalysisArgs({
          branchId,
          parkId,
          configuration,
          exportCableTypeOverrideId:
            analysisOverrideInput.exportCableTypeOverrideId,
        }),
      );

      if (input === null) {
        return { status: LossStatusType.Complete, results: null };
      }

      if (!input) return;

      const result = get(
        getElectricalAnalysis({
          projectId,
          input,
          powerBins,
        }),
      );

      if (!result) return;

      return { status: result.status, results: result.exportSystemResult };
    },
});

export const getTotalExportSystemLoss = selectorFamily<
  TotalExportSystemLoss | undefined,
  ProdId
>({
  key: "productionv2-ExportSystemLoss",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const analysis = get(getAnalysis(id));
      const stats = analysis?.stats;
      if (!stats) return undefined;
      const productionHistograms = stats.productionHistograms;

      const configuration = get(getConfiguration(id));
      const electricalConfig = configuration.electrical;
      if (!electricalConfig.exportSystemLoss) {
        return {
          totalExportCableLoss: null,
          totalExportSystemLoss: null,
          totalOffshoreTrafoLoss: null,
          totalOnshoreTrafoLoss: null,
        };
      }

      const electricalPowerBins = get(getElectricalPowerBins(id));

      const exportSystemLosses = get(getExportSystemLosses(id));
      if (!exportSystemLosses) return undefined;
      if (exportSystemLosses.results === null)
        return {
          totalExportCableLoss: null,
          totalExportSystemLoss: null,
          totalOffshoreTrafoLoss: null,
          totalOnshoreTrafoLoss: null,
        };
      return calcTotalExportSystemLoss(
        productionHistograms,
        exportSystemLosses,
        electricalConfig,
        electricalPowerBins,
      );
    },
});

export const getExportSystemLossesFailed = selectorFamily<
  AnalysisStoppedTypes | undefined,
  ProdId
>({
  key: "productionv2-ExportSystemLossesFailed",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const configuration = get(getConfiguration(id));

      const exportSystemLossesL = get(noWait(getExportSystemLosses(id)));
      if (exportSystemLossesL.state === "hasError")
        return AnalysisStoppedTypes.ExportSystemFailed;
      const exportSystemLosses = exportSystemLossesL.getValue();

      if (configuration.electrical.exportSystemLoss) {
        if (exportSystemLosses?.status === LossStatusType.Failed)
          return AnalysisStoppedTypes.ExportSystemFailed;
      }

      return undefined;
    },
});

export const getStoppedReasonFromAnalysis = selectorFamily<
  AnalysisStoppedTypes | undefined,
  ProdId
>({
  key: "productionv2-stoppedReasonFromAnalysis",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const analysis = get(getAnalysis(id));
      const analysisStoppedReason = analysis?.reason;
      if (analysisStoppedReason)
        return analysisStoppedReason as AnalysisStoppedTypes;
      return undefined;
    },
});

export const getStoppedReason = selectorFamily<
  AnalysisStoppedTypes | undefined,
  ProdId
>({
  key: "productionv2-stoppedReason",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const argsStopped = get(getArgsStoppedReason(id));
      if (argsStopped !== undefined) return argsStopped;
      // TODO: if we get a stopped reason from the analysis, add it in here.
      const exportStopped = get(getExportSystemLossesStoppedReason(id));
      if (exportStopped) return exportStopped;
      const interArrayFailed = get(getInterArrayLossesFailed(id));
      if (interArrayFailed !== undefined) return interArrayFailed;
      const exportSystemFailed = get(getExportSystemLossesFailed(id));
      if (exportSystemFailed !== undefined) return exportSystemFailed;
      return undefined;
    },
});

export const getAnalysisProgress = selectorFamily<number | undefined, ProdId>({
  key: "productionv2-progress",
  get:
    (id: ProdId) =>
    ({ get }) => {
      const analysis = get(getAnalysis(id));
      return analysis?.progress;
    },
});

/**
 * This is {@link analysisStatusAtomFamily} but only for the `AnalysisStatus`es
 * that's either failed or complete.
 */
const finishedAnalysisStatusAtomFamily = atomFamily<
  AnalysisStatusDone,
  { analysisStatusId: string }
>({ key: "finishedAnalysisStatusAtomFamily" });

const analysisIsComplete = (a: AnalysisStatus): a is AnalysisStatusDone =>
  a.status === "failed" || a.status === "complete" || a.status === "stopped";

/**
 * Problem: we want to have a selector that skips the production "loading"
 * state, and instead returns the error state or the success state.
 * `suspendThisSelector` doesn't work for two reasons:
 *
 *  1. It triggers a "hasValue" with `undefined` sometimes (??)
 *  2. A useRecoilLoadable(-) which transitively depends on the suspending
 *     selector does not reload by itself when some other dep change. (this is
 *     the known bug)
 *
 * I couldn't figure out how to make this work in general last time, so maybe
 * it's better to specialize to this use-case.
 *
 * # Solution
 *
 * Have two atoms, one for the state as-is today, and one for a duplicated state
 * that's only used with the error or success state is set.  Write things through
 * a selector so that it's easy to use.
 */
const _analysisStatusAtomFamily = atomFamily<
  AnalysisStatus | undefined,
  {
    analysisStatusId: string;
  }
>({
  key: "_analysisStatusAtomFamily",
  default: undefined,
});

/**
 * This state is set by Ably when the analysis status changes.
 */
export const analysisStatusAtomFamily = selectorFamily<
  AnalysisStatus | undefined,
  {
    analysisStatusId: string;
  }
>({
  key: "analysisStatusAtomFamily",
  get:
    ({ analysisStatusId }) =>
    ({ get }) =>
      get(_analysisStatusAtomFamily({ analysisStatusId })),
  set:
    ({ analysisStatusId }) =>
    ({ set }, value) => {
      set(_analysisStatusAtomFamily({ analysisStatusId }), value);
      if (value instanceof DefaultValue) return;
      if (!value) return;
      if (analysisIsComplete(value))
        set(finishedAnalysisStatusAtomFamily({ analysisStatusId }), value);
    },
});
