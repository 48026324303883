import { useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { maxDepth } from "../components/DepthSlider/DepthSlider";

import {
  depthFilterAtomFamily,
  enableFilterLayersAtom,
  renderDepthFilterAtom,
  renderShoreDistanceAtom,
  renderWindSpeedAtom,
  shoreDistanceFilterAtomFamily,
  windSpeedFilterAtomFamily,
  maxShorelineDistance,
} from "../state/filter";
import { windLayerHeightAtom } from "../state/layer";
import { mapRefAtom } from "../state/map";
import { CustomLayerInterface, RasterLayer } from "mapbox-gl";
import { scream } from "../utils/sentry";
import { getBeforeLayer, filterLayerId } from "../components/Mapbox/utils";
import {
  maxWindSpeed,
  maxWindSpeedScalingFactor,
  minWindSpeed,
} from "../types/filter";
import { LAYER_DEBUG_PRINT } from "../state/debug";
import { useTypedPath } from "../state/pathParams";

const depthId = "bathymetry-full";
const shoreDistanceId = "shoredistance";
const windSpeedId = "windSpeed";

const tileSize = 512;
const maxzoom = 7;

class TerrainLayer implements CustomLayerInterface {
  id: string;
  type: "custom";
  depthSource: any;
  shoreDistanceSource: any;
  windSpeedSource: any;
  depth: number[];
  shorelineDistance: number[];
  windSpeed: number[];
  map: any;
  depthSourceCache: any;
  shoreDistanceSourceCache: any;
  windSpeedSourceCache: any;
  vertexArray: Int16Array | undefined;
  vertexBuffer: any;
  indexArray: Uint16Array | undefined;
  indexBuffer: any;
  program: any;
  aPos: any;

  constructor(
    depthSource: string,
    shoreDistanceSource: string,
    windSpeedSource: string,
  ) {
    this.id = filterLayerId;
    this.type = "custom";
    this.depthSource = depthSource;
    this.shoreDistanceSource = shoreDistanceSource;
    this.windSpeedSource = windSpeedSource;
    this.depth = [0, maxDepth];
    this.shorelineDistance = [0, maxShorelineDistance];
    this.windSpeed = [0, maxWindSpeed];
  }

  setDepth(newDepth: number[]) {
    this.depth = newDepth;
  }

  setShorelineDistance(newShorelineDistance: number[]) {
    this.shorelineDistance = newShorelineDistance;
  }

  setWindSpeed(newWindSpeed: number[]) {
    this.windSpeed = newWindSpeed;
  }

  onAdd(map: any, gl: WebGLRenderingContext) {
    LAYER_DEBUG_PRINT && console.log("TerrainLayer.onAdd");
    this.map = map;
    this.depthSourceCache = map.style._otherSourceCaches[this.depthSource];
    this.shoreDistanceSourceCache =
      map.style._otherSourceCaches[this.shoreDistanceSource];
    this.windSpeedSourceCache =
      map.style._otherSourceCaches[this.windSpeedSource];

    this.depthSourceCache?.pause();
    this.shoreDistanceSourceCache?.pause();
    this.windSpeedSourceCache?.pause();

    this.prepareShaders(gl);
    this.prepareBuffers(gl);
  }

  update() {
    const transform = this.map.transform.clone();
    const pitchOffset =
      transform.cameraToCenterDistance * Math.sin(transform._pitch);
    transform.height = transform.height + pitchOffset;

    this.depthSourceCache._paused = false;
    this.shoreDistanceSourceCache._paused = false;
    this.windSpeedSourceCache._paused = false;

    this.depthSourceCache.used = true;
    this.shoreDistanceSourceCache.used = true;
    this.windSpeedSourceCache.used = true;

    this.depthSourceCache.update(transform);
    this.shoreDistanceSourceCache.update(transform);
    this.windSpeedSourceCache.update(transform);

    this.depthSourceCache.pause();
    this.shoreDistanceSourceCache.pause();
    this.windSpeedSourceCache.pause();
  }

  prepareBuffers(gl: WebGLRenderingContext) {
    const n = 64;

    this.vertexArray = new Int16Array(n * n * 2);
    for (let i = 0; i < n; i++) {
      for (let j = 0; j < n; j++) {
        const vertex = [j * (8192 / (n - 1)), i * (8192 / (n - 1))];
        const offset = (i * n + j) * 2;
        this.vertexArray.set(vertex, offset);
      }
    }

    this.vertexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, this.vertexArray.buffer, gl.STATIC_DRAW);

    this.indexArray = new Uint16Array((n - 1) * (n - 1) * 6);
    let offset = 0;
    for (let i = 0; i < n - 1; i++) {
      for (let j = 0; j < n - 1; j++) {
        const index = i * n + j;
        const quad = [
          index,
          index + 1,
          index + n,
          index + n,
          index + 1,
          index + n + 1,
        ];
        this.indexArray.set(quad, offset);
        offset += 6;
      }
    }

    this.indexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.indexBuffer);
    gl.bufferData(
      gl.ELEMENT_ARRAY_BUFFER,
      this.indexArray.buffer,
      gl.STATIC_DRAW,
    );
  }

  prepareShaders(gl: WebGLRenderingContext) {
    var vertexSource = `
  uniform mat4 u_matrix;
  uniform vec2 u_depth;
  uniform vec2 u_shorelineDistance;
  uniform vec2 u_windspeed;
  uniform sampler2D u_depth_raster;
  uniform sampler2D u_shoreline_distance_raster;
  uniform sampler2D u_windspeed_raster;
  attribute vec2 a_pos;
  varying vec2 v_pos;
  void main() {
      v_pos = vec2(a_pos / 8192.0);
      gl_Position = u_matrix * vec4(a_pos, .0, 1.0);
  }`;

    var fragmentSource = `
  precision highp float;
  uniform sampler2D u_depth_raster;
  uniform sampler2D u_shoreline_distance_raster;
  uniform sampler2D u_windspeed_raster;
  uniform sampler2D u_palette;
  uniform vec2 u_depth;
  uniform vec2 u_shorelineDistance;
  uniform vec2 u_windspeed;
  varying vec2 v_pos;

  float getDepth(vec2 coord) {
    vec4 color = texture2D(u_depth_raster, coord);
    float R = color.r * 255.0;
    float G = color.g * 255.0;
    float B = color.b * 255.0;
    return ((R * 256.0 + G + B / 256.0) - 32768.0) * -1.;
  }

  vec2 getWindSpeedWithAlpha(vec2 coord) {
    vec4 value = texture2D(u_windspeed_raster, v_pos);
    float speed = value.r * 10. + 3.;
    float alpha = value.a;
    return vec2(speed, alpha);
  }

  void main() {
    float depth = getDepth(v_pos);
    vec2 windSpeedWithAlpha = getWindSpeedWithAlpha(v_pos);
    float windSpeed = windSpeedWithAlpha.x;
    float windSpeedAlpha = windSpeedWithAlpha.y;
    vec4 texSample = texture2D(u_depth_raster, v_pos);
    vec4 shoreLineDistance = texture2D(u_shoreline_distance_raster, v_pos);
    float distance = shoreLineDistance.r * 256.;
    
    float alphaShorelineDistance = 0.0;
    if(distance > 0. && distance < u_shorelineDistance.x && distance < u_shorelineDistance.y) {
      alphaShorelineDistance = 1.;
    } else if(distance > 0. && distance > u_shorelineDistance.y) {
      alphaShorelineDistance = 1.;
    }

    float alphaWindSpeed = 0.;
    if(u_windspeed.x > ${minWindSpeed}.0 && windSpeed < (u_windspeed.x / ${maxWindSpeedScalingFactor}.0) || windSpeed > (u_windspeed.y / ${maxWindSpeedScalingFactor}.0)) {
      alphaWindSpeed = 1. * step(1., depth); // Only show wind offshore
    }
    
    float depthAlpha = 1.0;
    if(texSample.r == .0 && texSample.g == .0 && texSample.b == .0) {
      depthAlpha = .0;
    } else if(depth >= u_depth.x && depth <= u_depth.y) {
      depthAlpha = .0;
    }
    if(depth < 0.) {
      depthAlpha = 0.0;
    }

    gl_FragColor = vec4(0.5, 0.5, 0.5, max(max(alphaShorelineDistance, depthAlpha), alphaWindSpeed));
  }`;

    var vertexShader = gl.createShader(gl.VERTEX_SHADER);
    if (!vertexShader) {
      scream("Failed to create vertex shader", { error: gl.getError() });
      return;
    }
    gl.shaderSource(vertexShader, vertexSource);
    gl.compileShader(vertexShader);
    var fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
    if (!fragmentShader) {
      scream("Failed to create vertex shader", { error: gl.getError() });
      return;
    }
    gl.shaderSource(fragmentShader, fragmentSource);
    gl.compileShader(fragmentShader);

    this.program = gl.createProgram();
    gl.attachShader(this.program, vertexShader);
    gl.attachShader(this.program, fragmentShader);
    gl.linkProgram(this.program);

    if (!gl.getProgramParameter(this.program, gl.LINK_STATUS)) {
      const info = gl.getProgramInfoLog(this.program);
      scream("Failed to create program", {
        info,
        program: this.program,
        vertexShader,
        fragmentShader,
      });
      return;
    }

    this.aPos = gl.getAttribLocation(this.program, "a_pos");
  }

  render(gl: WebGLRenderingContext, _matrix: number[]) {
    if (!this.indexArray) return;
    LAYER_DEBUG_PRINT && console.time("TerrainLayer.render");
    gl.useProgram(this.program);

    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

    // Bind vertex buffer
    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
    gl.enableVertexAttribArray(this.aPos);
    gl.vertexAttribPointer(this.aPos, 2, gl.SHORT, false, 0, 0);

    // Bind index buffer
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.indexBuffer);

    this.update();

    gl.uniform2fv(gl.getUniformLocation(this.program, "u_depth"), this.depth);

    gl.uniform2fv(
      gl.getUniformLocation(this.program, "u_shorelineDistance"),
      this.shorelineDistance,
    );

    gl.uniform2fv(
      gl.getUniformLocation(this.program, "u_windspeed"),
      this.windSpeed,
    );

    gl.activeTexture(gl.TEXTURE3);
    gl.uniform1i(gl.getUniformLocation(this.program, "u_palette"), 3);
    var paletteTex = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, paletteTex);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);

    let coords = this.depthSourceCache.getVisibleCoordinates().reverse();

    for (const coord of coords) {
      const depthTile = this.depthSourceCache.getTile(coord);
      const shoreLineDistanceTile =
        this.shoreDistanceSourceCache.getTile(coord);
      const windSpeedTile = this.windSpeedSourceCache.getTile(coord);

      // Bind depth raster texture to unit 0
      gl.activeTexture(gl.TEXTURE0);
      gl.bindTexture(gl.TEXTURE_2D, depthTile.texture.texture);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
      gl.uniform1i(gl.getUniformLocation(this.program, "u_depth_raster"), 0);

      // Bind shore line distance raster texture to unit 1
      gl.activeTexture(gl.TEXTURE1);
      gl.bindTexture(
        gl.TEXTURE_2D,
        shoreLineDistanceTile && shoreLineDistanceTile.texture
          ? shoreLineDistanceTile.texture.texture
          : gl.createTexture(),
      );
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
      gl.uniform1i(
        gl.getUniformLocation(this.program, "u_shoreline_distance_raster"),
        1,
      );

      // Bind wind speed raster texture to unit 2
      gl.activeTexture(gl.TEXTURE2);
      gl.bindTexture(
        gl.TEXTURE_2D,
        windSpeedTile && windSpeedTile.texture
          ? windSpeedTile.texture.texture
          : gl.createTexture(),
      );
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
      gl.uniform1i(
        gl.getUniformLocation(this.program, "u_windspeed_raster"),
        2,
      );

      // Bind matrix
      gl.uniformMatrix4fv(
        gl.getUniformLocation(this.program, "u_matrix"),
        false,
        coord.projMatrix,
      );

      // Draw
      const vertexCount = this.indexArray.length;
      const type = gl.UNSIGNED_SHORT;
      const offset = 0;
      gl.drawElements(gl.TRIANGLES, vertexCount, type, offset);
    }
    LAYER_DEBUG_PRINT && console.timeEnd("TerrainLayer.render");
  }
}

const Filter = () => {
  const { projectId } = useTypedPath("projectId");

  const map = useRecoilValue(mapRefAtom);

  const enabled = useRecoilValue(enableFilterLayersAtom);
  const renderDepth = useRecoilValue(renderDepthFilterAtom);
  const depth = useRecoilValue(depthFilterAtomFamily({ projectId }));
  const renderShore = useRecoilValue(renderShoreDistanceAtom);
  const shoreDistance = useRecoilValue(
    shoreDistanceFilterAtomFamily({ projectId }),
  );
  const renderWindSpeed = useRecoilValue(renderWindSpeedAtom);
  const windSpeed = useRecoilValue(windSpeedFilterAtomFamily({ projectId }));
  const windLayerHeight = useRecoilValue(windLayerHeightAtom);

  const bathymetrySource: RasterLayer & { tiles: string[] } = useMemo(
    () => ({
      id: depthId,
      type: "raster",
      tiles: [`/tiles/gebco-terrarium-2023/{z}/{x}/{y}.png`],
    }),
    [],
  );

  const shoreDistanceSource: RasterLayer & { tiles: string[] } = useMemo(
    () => ({
      id: shoreDistanceId,
      type: "raster",
      tiles: [`/tiles/shore/{z}/{x}/{y}.png`],
    }),
    [],
  );

  const windSpeedSource: RasterLayer & { tiles: string[] } = useMemo(
    () => ({
      id: windSpeedId,
      type: "raster",
      tiles: [`/tiles/gwa/speed/${windLayerHeight}/{z}/{x}/{y}.png`],
    }),
    [windLayerHeight],
  );

  const depthLayer = useMemo(
    () =>
      enabled &&
      new TerrainLayer(
        bathymetrySource.id,
        shoreDistanceSource.id,
        windSpeedSource.id,
      ),
    [bathymetrySource.id, enabled, shoreDistanceSource.id, windSpeedSource.id],
  );

  useEffect(() => {
    if (!map || !enabled) return;
    if (depthLayer) {
      if (renderDepth) depthLayer.setDepth(depth);
      else depthLayer.setDepth([0, Number.MAX_VALUE]);

      if (renderShore) depthLayer.setShorelineDistance(shoreDistance);
      else depthLayer.setShorelineDistance([0, Number.MAX_VALUE]);

      if (renderWindSpeed) depthLayer.setWindSpeed(windSpeed);
      else depthLayer.setWindSpeed([minWindSpeed, maxWindSpeed]);
      map.triggerRepaint();
    }
  }, [
    map,
    depth,
    shoreDistance,
    depthLayer,
    windSpeed,
    renderWindSpeed,
    renderDepth,
    renderShore,
    enabled,
  ]);

  useEffect(() => {
    if (!map) return;
    if (!depthLayer) return;

    map.addSource(bathymetrySource.id, {
      type: bathymetrySource.type,
      tiles: bathymetrySource.tiles,
      tileSize,
      maxzoom,
    });
    map.addSource(shoreDistanceSource.id, {
      type: shoreDistanceSource.type,
      tiles: shoreDistanceSource.tiles,
      tileSize,
      maxzoom,
    });
    map.addSource(windSpeedSource.id, {
      type: windSpeedSource.type,
      tiles: windSpeedSource.tiles,
      tileSize,
      maxzoom,
    });
    map.addLayer(depthLayer, getBeforeLayer(map, depthLayer.id));

    return () => {
      map.removeLayer(depthLayer.id);
      map.removeSource(windSpeedSource.id);
      map.removeSource(shoreDistanceSource.id);
      map.removeSource(bathymetrySource.id);
    };
  }, [map, depthLayer, bathymetrySource, shoreDistanceSource, windSpeedSource]);

  return null;
};

export default Filter;
