import { selector, selectorFamily } from "recoil";
import { ProjectFeature } from "../types/feature";
import { isPark } from "../utils/predicates";
import { canvasLayerFeatureHiddenAtomFamily } from "./projectLayers";
import {
  projectFeaturesSelector,
  projectFeaturesInBranchSelectorFamily,
} from "../components/ProjectElements/state";
import { ParkFeature } from "../types/feature";
import { parkIdSelector } from "./pathParams";

export const inPark =
  (parkId: string): ((f: ProjectFeature) => boolean) =>
  (f) =>
    f.properties.parentIds?.includes(parkId) ?? false;

export const getFilteredParkFeaturesSelector = selectorFamily<
  ParkFeature[],
  { branchId: string | undefined }
>({
  key: "getFilteredParkFeaturesSelector",
  get:
    ({ branchId }) =>
    ({ get }) => {
      const canvasLayerFeatureHidden = get(
        canvasLayerFeatureHiddenAtomFamily({ branchId }),
      );
      return get(getParkFeaturesSelector).filter(
        (p) => !canvasLayerFeatureHidden.includes(p.id),
      );
    },
});

export const getParkFeaturesSelector = selector<ParkFeature[]>({
  key: "getParkFeaturesSelector",
  get: ({ get }) => {
    const projectData = get(projectFeaturesSelector);
    return projectData.filter(isPark);
  },
});

export const getParkFeaturesInBranchSelector = selectorFamily<
  ParkFeature[],
  { branchId: string }
>({
  key: "getParkFeaturesInBranchSelector",
  get:
    ({ branchId }) =>
    ({ get }) => {
      if (branchId === "") return [];
      const projectData = get(
        projectFeaturesInBranchSelectorFamily({ branchId }),
      );
      return projectData.filter(isPark);
    },
});

export const getParkFeatureInBranchSelector = selectorFamily<
  ParkFeature | undefined,
  { branchId: string; parkId: string }
>({
  key: "getParkFeatureInBranchSelector",
  get:
    ({ branchId, parkId }) =>
    ({ get }) => {
      const parks = get(getParkFeaturesInBranchSelector({ branchId }));
      return parks.find((park) => park.id === parkId);
    },
});

export const getParkFeatureSelectorFamily = selectorFamily<
  undefined | ParkFeature,
  { parkId: string }
>({
  key: "getParkFeatureSelectorFamily",
  get:
    ({ parkId }) =>
    ({ get }) => {
      const parks = get(getParkFeaturesSelector);
      const park = parks.find((p) => p.id === parkId);
      return park;
    },
});

export const getCurrentParkSelector = selector<undefined | ParkFeature>({
  key: "getCurrentParkSelector",
  get: ({ get }) => {
    const parkId = get(parkIdSelector);
    if (!parkId) return undefined;
    return get(getParkFeatureSelectorFamily({ parkId }));
  },
});
