import { colors } from "./../styles/colors";
function componentToHex(c: number) {
  const hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

export function rgbToHex(r: number, g: number, b: number) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

// https://gist.github.com/robertnsharp/49fd46a071a267d9e5dd
export function randomPastelColor(input_str: string) {
  const baseRed = 128;
  const baseGreen = 128;
  const baseBlue = 128;

  let seed = input_str.charCodeAt(0) ^ input_str.charCodeAt(1);
  const rand_1 = Math.abs(Math.sin(seed++) * 10000) % 256;
  const rand_2 = Math.abs(Math.sin(seed++) * 10000) % 256;
  const rand_3 = Math.abs(Math.sin(seed++) * 10000) % 256;

  const red = Math.round((rand_1 + baseRed) / 2);
  const green = Math.round((rand_2 + baseGreen) / 2);
  const blue = Math.round((rand_3 + baseBlue) / 2);

  const backgroundColor = rgbToHex(red, green, blue);
  const textColor = getContrastingTextColor(red, green, blue);

  return { backgroundColor, textColor };
}

function getContrastingTextColor(r: number, g: number, b: number) {
  // Calculate the relative luminance
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  // Standard threshold for deciding between black and white text
  return luminance > 128 ? colors.primaryText : colors.lightText;
}

const getInitials = (name: string) => {
  const nameSplit = name.split(" ");
  const nameLength = nameSplit.length;
  const initials =
    nameLength > 1
      ? nameSplit[0].substring(0, 1) + nameSplit[nameLength - 1].substring(0, 1)
      : nameSplit[0].substring(0, 1);

  return initials.toUpperCase();
};

const size = 128;
export const createImageFromInitials = (name: string, color?: string) => {
  name = getInitials(name);

  const _color = color ?? randomPastelColor(name).backgroundColor;

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  if (!context) return "";
  canvas.width = canvas.height = size;

  context.fillStyle = "#ffffff";
  context.fillRect(0, 0, size, size);

  context.fillStyle = `${_color}50`;
  context.fillRect(0, 0, size, size);

  context.fillStyle = _color;
  context.textBaseline = "middle";
  context.textAlign = "center";
  context.font = `500 8rem Open Sans`;
  context.fillText(name, size / 2, size / 1.85);

  return canvas.toDataURL();
};
