import styled from "styled-components";
import { colors } from "../../../../styles/colors";
import Tooltip from "../../../General/Tooltip";
import { Container } from "../../../../styles/misc/Misc";
import {
  DISABLED_IN_READ_ONLY_MODE,
  inReadOnlyModeSelector,
} from "state/project";
import { useRecoilValue } from "recoil";
import { IconBtn } from "components/General/Icons";
import { SearchObject } from "components/Search/state";
import SearchWrapper from "components/Search/SearchWrapper";

export const ParentWrapper = styled(Container)`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: start;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
  margin: 0.2rem 0 0 0;
`;

export const SubHeader2 = styled.h4`
  color: ${colors.brand};
  margin: 1.2rem 0 0.4rem;
`;

export const Content = styled.div`
  flex-grow: 2;
  padding: 0 0.5rem 0 0;
`;

export const MenuOption = ({
  Icon,
  iconType = "fill",
  chosenMenu,
  setChosenMenu,
  option,
  tooltipText,
  id = undefined,
  searchObject,
  disableInReadOnlyMode = true,
}: {
  Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  iconType: any;
  chosenMenu: any;
  setChosenMenu: any;
  option: any;
  tooltipText: any;
  id?: string;
  searchObject?: SearchObject;
  disableInReadOnlyMode?: boolean;
}) => {
  const readOnlyMode = useRecoilValue(inReadOnlyModeSelector);
  const selected = chosenMenu === option;
  const children =
    iconType === "fill" ? (
      <Tooltip
        position="left"
        text={
          readOnlyMode && disableInReadOnlyMode
            ? DISABLED_IN_READ_ONLY_MODE
            : tooltipText
        }
        revealWithQKey
      >
        <IconBtn
          $fill
          data-intercom-target={id}
          id={id}
          active={selected}
          disabled={readOnlyMode && disableInReadOnlyMode}
          onClick={() => setChosenMenu(selected ? undefined : option)}
        >
          <Icon />
        </IconBtn>
      </Tooltip>
    ) : (
      <Tooltip
        position="left"
        text={
          readOnlyMode && disableInReadOnlyMode
            ? DISABLED_IN_READ_ONLY_MODE
            : tooltipText
        }
        revealWithQKey
      >
        <IconBtn
          data-intercom-target={id}
          id={id}
          active={selected}
          disabled={readOnlyMode && disableInReadOnlyMode}
          onClick={() => setChosenMenu(selected ? undefined : option)}
        >
          <Icon />
        </IconBtn>
      </Tooltip>
    );

  return searchObject ? (
    <SearchWrapper {...searchObject}>{children}</SearchWrapper>
  ) : (
    children
  );
};
