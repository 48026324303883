/// <reference types="vite-plugin-svgr/client" />
import React from "react";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import InformationIcon from "@icons/24/Information.svg?react";
import { Menu, MenuItem } from "../../General/Menu";
import SelectElementMenuItem from "./shared/SelectElementMenuItem";
import PasteRowMenuItem from "./shared/PasteRowMenuItem";

const NoSelectionMenu = ({
  sampleWmsCallback,
  featuresOnPoint,
  enableShowLayerInfo,
  closeMenu,
  onSelectFeature,
  onMouseEnterFeature,
  onMouseLeaveFeature,
}: {
  sampleWmsCallback: () => void;
  featuresOnPoint: MapboxGeoJSONFeature[];
  enableShowLayerInfo: boolean;
  closeMenu(): void;
  onSelectFeature(feature: MapboxGeoJSONFeature): void;
  onMouseEnterFeature(feature: MapboxGeoJSONFeature): void;
  onMouseLeaveFeature(feature: MapboxGeoJSONFeature): void;
}) => {
  return (
    <Menu style={{ boxShadow: "initial" }}>
      <MenuItem
        name={"Show layer info"}
        icon={<InformationIcon />}
        title={
          !enableShowLayerInfo
            ? "No WMS layers are visible in the map"
            : undefined
        }
        disabled={!enableShowLayerInfo}
        onClick={() => {
          sampleWmsCallback();
          closeMenu();
        }}
      />
      <PasteRowMenuItem closeMenu={closeMenu} />
      <SelectElementMenuItem
        onSelectFeature={onSelectFeature}
        onMouseEnterFeature={onMouseEnterFeature}
        onMouseLeaveFeature={onMouseLeaveFeature}
        closeMenu={closeMenu}
        features={featuresOnPoint}
      />
    </Menu>
  );
};

export default NoSelectionMenu;
