import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalTypeOpenAtom } from "../../state/modal";
import FullScreenModal from "../FullScreenModal/FullScreenModal";
import styled from "styled-components";
import { useCallback, useMemo, useState } from "react";
import { StandardBox } from "../../styles/boxes/Boxes";
import { Input } from "../General/Input";
import Button from "../General/Button";
import { projectFeaturesSelector } from "../ProjectElements/state";
import { useProjectElementsCrud } from "../ProjectElements/useProjectElementsCrud";
import { _FeatureParser } from "../../types/feature";
import { projectIdSelector } from "../../state/pathParams";

const Wrapper = styled(StandardBox)`
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;
  gap: 1rem;
`;

const Header = styled.h3`
  color: #264e5d;
  font-size: 1rem;
`;

const RenameFeatureModal = ({
  featureId,
  defaultName,
  header,
}: {
  featureId: string;
  defaultName: string;
  header: string;
}) => {
  const projectId = useRecoilValue(projectIdSelector);

  if (!projectId || !featureId) return null;

  return (
    <RenameFeatureModalInner
      featureId={featureId}
      defaultName={defaultName}
      header={header}
    />
  );
};

const RenameFeatureModalInner = ({
  featureId,
  defaultName,
  header,
}: {
  featureId: string;
  defaultName: string;
  header: string;
}) => {
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const projectData = useRecoilValue(projectFeaturesSelector);
  const { update: updateFeatures } = useProjectElementsCrud();
  const feature = useMemo(
    () => projectData.find((p) => p.id === featureId),
    [projectData, featureId],
  );
  const [name, setName] = useState(feature?.properties?.name || defaultName);
  const renameFeature = useCallback(() => {
    if (name === "" || !feature) return;

    const updatedFeature = _FeatureParser.parse({
      ...feature,
      properties: { ...feature.properties, name },
    });
    updateFeatures({ update: [updatedFeature] });
    setModalTypeOpen(undefined);
  }, [name, feature, updateFeatures, setModalTypeOpen]);

  return (
    <FullScreenModal>
      <Wrapper>
        <Header>{header}</Header>
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          type="text"
          placeholder="name"
          onEnter={renameFeature}
          onCancel={() => setModalTypeOpen(undefined)}
          autoFocus
        />
        <Button text="Save" onClick={renameFeature} />
      </Wrapper>
    </FullScreenModal>
  );
};

export default RenameFeatureModal;
