/// <reference types="vite-plugin-svgr/client" />
import React, { useMemo } from "react";
import styled from "styled-components";
import AddIcon from "@icons/24/Add.svg?react";
import { IconREMSize } from "../../../../styles/typography";
import { Layer } from "../../../../types/layers";
import { spaceMedium, spaceSmall } from "../../../../styles/space";
import { TranslatedLayerName } from "../../../LayerList/LayerInformation/LayerInformation";
import { LayerIconMapper } from "../../../LayerList/utils";
import Button from "../../../General/Button";
import { SourceNameComponent } from "../../../LayerList/LayerListPane/ProjectLayerItem";
import { colors } from "../../../../styles/colors";
import { useRecoilCallback, useRecoilValue } from "recoil";
import {
  layersSettingSelectorFamily,
  layerVisibleAtomFamily,
  allLayerSettingsAtomFamily,
} from "../../../LayerList/LayerSettings/state";
import { useTypedPath } from "../../../../state/pathParams";
import { useLayerSettingsCrud } from "../../../LayerList/LayerSettings/useLayerSettingsCrud";
import LayerPreviewImageV2 from "../LayerPreviewImageV2";

const OpacityLayer = styled.div<{ isAdded: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.lightText};
  opacity: 0.4;
  display: none;
  z-index: 2;

  ${({ isAdded }) =>
    isAdded &&
    `
    opacity: 0.6;
    display: block;
  `}
`;

const SuggestedLayerWrapper = styled.div<{ isAdded: boolean }>`
  position: relative;
  height: 20rem;
  cursor: pointer;

  button {
    display: none;
  }

  ${({ isAdded }) =>
    isAdded
      ? `
  ${OpacityLayer} {
      display: block;
    }

    button {
      display: flex;
    }
  `
      : ""}

  &:hover {
    ${OpacityLayer} {
      display: block;
    }

    button {
      display: flex;
    }
  }
`;

const SuggestedLayerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;
`;

const SuggestedLayer = ({ layer }: { layer: Layer }) => {
  const { projectId } = useTypedPath("projectId");
  const { put: putLayerSettings } = useLayerSettingsCrud();

  const allLayerSettings = useRecoilValue(
    allLayerSettingsAtomFamily({
      projectId,
    }),
  );

  const isAdded = useMemo(
    () => allLayerSettings.some((setting) => setting.id === layer.id),
    [allLayerSettings, layer.id],
  );

  const setLayerVisible = useRecoilCallback(
    ({ set }) =>
      (id: string, visible: boolean) => {
        set(layerVisibleAtomFamily({ projectId, layerId: id }), visible);
      },
    [projectId],
  );

  const addLayer = useRecoilCallback(
    ({ snapshot }) =>
      async (layerId: string) => {
        const settings = snapshot.getLoadable(
          layersSettingSelectorFamily({
            projectId,
            layerIds: [layerId],
          }),
        );
        setLayerVisible(layerId, true);
        await putLayerSettings(settings.getValue());
      },
    [projectId, putLayerSettings, setLayerVisible],
  );

  return (
    <SuggestedLayerWrapper
      isAdded={isAdded}
      onClick={() => {
        if (!isAdded) {
          addLayer(layer.id);
        }
      }}
    >
      <LayerPreviewImageV2
        layer={layer}
        clickToEnlarge={false}
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          border: `1px solid ${colors.inputOutline}`,
          boxSizing: "border-box",
        }}
      />
      <OpacityLayer isAdded={isAdded} />
      <SuggestedLayerContent>
        <div
          style={{
            backgroundColor: "#ffffffcc",
            padding: spaceMedium,
            fontSize: "1.3rem",
            justifyContent: "space-between",
            display: "flex",
            margin: "1px 1px",
            borderRadius: "4px 4px 0 0",
          }}
        >
          <b>
            <TranslatedLayerName layer={layer} />
          </b>
          <div style={{ display: "flex", gap: spaceSmall }}>
            <IconREMSize height={1.2} width={1.2}>
              <LayerIconMapper layer={layer} />
            </IconREMSize>
            <span style={{ fontSize: "1.2rem" }}>{layer.sourceType}</span>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {!isAdded ? (
            <Button
              buttonType="primary"
              text="Add to map"
              icon={<AddIcon />}
              onClick={() => {
                addLayer(layer.id);
              }}
            />
          ) : (
            <Button buttonType="primary" text="Added to map" disabled={true} />
          )}
        </div>
        <div
          style={{
            backgroundColor: "#ffffffcc",
            padding: spaceMedium,
            fontSize: "1.3rem",
            justifyContent: "space-between",
            display: "flex",
            margin: "1px 1px",
            borderRadius: "0 0 4px 4px",
            zIndex: 1,
          }}
        >
          <SourceNameComponent layer={layer} />
        </div>
      </SuggestedLayerContent>
    </SuggestedLayerWrapper>
  );
};

export default SuggestedLayer;
