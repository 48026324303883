/// <reference types="vite-plugin-svgr/client" />
import { HTMLAttributes } from "react";
import HelpIcon from "@icons/24/Help.svg?react";
import InfoIcon from "@icons/24/Information.svg?react";
import { Place } from "../General/Anchor";
import Tooltip from "../General/Tooltip";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { IconBtn } from "../General/Icons";

const articleMap = {
  cableGen: 6877707,
  substationGen: 8494475,
  cableStats: 8033643,
  compare: 8205697,
  mooringGen: 7470616,
  foundationStats: 7752370,
  financialConfigurations: 8601519,
  financialAnalysis: 8890529,
  financialDefaultNumbers: 8609407,
  wakeAnalysisBenchmarking: 6819707,
  yieldAssessment: 8560585,
  noiseAnalysis: 8625695,
  wakeModels: 6883891,
  importWindData: 6987317,
  windDatasets: 8232281,
  windMeanSpeedCalibration: 8535587,
  layoutGen: 7887320,
  whatIsVind: 6642748,
  viewshed: 7328879,
  lcoeMap: 8444586,
  airDensity: 8473019,
  interArrayLoss: 8516433,
  siteLocator: 8676886,
  portfolio: 8895791,
  genericTurbine: 9018365,
} as const;

export type Article = keyof typeof articleMap;

export const ARTICLE_CABLE_GEN = articleMap.cableGen;
export const ARTICLE_SUBSTATION_GEN = articleMap.substationGen;
export const ARTICLE_CABLE_STATS = articleMap.cableStats;
export const ARTICLE_COMPARE = articleMap.compare;
export const ARTICLE_MOORING_GEN = articleMap.mooringGen;
export const ARTICLE_FOUNDATION_STATS = articleMap.foundationStats;
export const ARTICLE_FINANCIAL_CONFIGURATIONS =
  articleMap.financialConfigurations;
export const ARTICLE_FINANCIAL_ANALYSIS = articleMap.financialAnalysis;
// export const ARTICLE_FINANCIAL_DEFAULT_NUMBERS = articleMap.financialDefaultNumbers;
export const ARTICLE_WAKE_ANALYSIS_BENCHMARKING =
  articleMap.wakeAnalysisBenchmarking;
export const ARTICLE_YIELD_ASSESSMENT = articleMap.yieldAssessment;
export const ARTICLE_NOISE_ANALYSIS = articleMap.noiseAnalysis;
export const ARTICLE_WAKE_MODELS = articleMap.wakeModels;
export const ARTICLE_IMPORT_WIND_DATA = articleMap.importWindData;
export const ARTICLE_WIND_DATASETS = articleMap.windDatasets;
export const ARTICLE_WIND_MEAN_SPEED_CALIBRATION =
  articleMap.windMeanSpeedCalibration;
export const ARTICLE_LAYOUT_GEN = articleMap.layoutGen;
// export const ARTICLE_WHAT_IS_VIND = articleMap.whatIsVind;
export const ARTICLE_VIEWSHED = articleMap.viewshed;
export const ARTICLE_LCOE_MAP = articleMap.lcoeMap;
export const ARTICLE_AIR_DENSITY = articleMap.airDensity;
export const ARTICLE_GENERIC_TURBINE = articleMap.genericTurbine;
export const ARTICLE_INTER_ARRAY_LOSS = articleMap.interArrayLoss;
export const ARTICLE_SITE_LOCATOR = articleMap.siteLocator;
export const ARTICLE_PORTFOLIO = articleMap.portfolio;

export const showArticle = (article: Article | number) => {
  const num = typeof article === "number" ? article : articleMap[article];
  window.Intercom("showArticle", num);
};

const Wrapper = styled.div<{ size?: number; color?: string }>`
  display: flex;

  svg {
    width: ${(p) => p.size ?? 14}px;
    height: ${(p) => p.size ?? 14}px;
    path {
      stroke: ${({ color }) => color ?? colors.iconInfo};
    }
  }
`;

const HelpTooltip = ({
  text,
  place,
  size,
  color,
  ...props
}: {
  text: string;
  size?: number;
  place?: Place;
} & HTMLAttributes<HTMLDivElement>) => {
  return (
    <Tooltip position={place ?? "top"} text={text} {...props}>
      <Wrapper size={size} color={color}>
        <InfoIcon />
      </Wrapper>
    </Tooltip>
  );
};

export const HelpLink = ({
  article,
  place,
  size,
  iconStroke,
  hoverBackgroundColor,
  style,
}: {
  article: Article | number;
  place?: Place;
  size?: string;
  iconStroke?: string;
  hoverBackgroundColor?: string;
  style?: React.CSSProperties;
}) => {
  const useSize = size || "1.4rem";
  return (
    <Tooltip text="Open help center" position={place ?? "top"}>
      <IconBtn
        size={useSize}
        stroke={iconStroke ?? colors.primary}
        hoverBackgroundColor={hoverBackgroundColor}
        onClick={() => {
          showArticle(article);
        }}
        style={{ ...style }}
      >
        <HelpIcon style={{ ...style, width: useSize, height: useSize }} />
      </IconBtn>
    </Tooltip>
  );
};

export default HelpTooltip;
