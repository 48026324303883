import { useRecoilCallback } from "recoil";
import { useTypedPath } from "../../../state/pathParams";
import { putThreadFollow } from "../service";
import { followThreadsAtomFamily } from "../state";

export const useFollowThreadCrud = () => {
  const { projectId, branchId } = useTypedPath("projectId", "branchId");

  const put = useRecoilCallback(
    ({ set }) =>
      async (threadId: string, follow: boolean) => {
        if (!projectId) return [];
        set(followThreadsAtomFamily({ nodeId: projectId, branchId }), (cur) => {
          const filtered = cur.filter((c) => c.threadId !== threadId);
          return [...filtered, { projectId, branchId, threadId, follow }];
        });
        const res = await putThreadFollow(projectId, branchId, threadId, {
          follow,
        });

        return res;
      },
    [branchId, projectId],
  );

  return { put };
};
