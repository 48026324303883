import * as turf from "@turf/turf";
import type { Position } from "@turf/turf";
import type {
  ParkFeature,
  ProjectFeature,
  SubAreaFeature,
  TurbineFeature,
} from "../types/feature";
import { fastMin, movingWindow, movingWindowLoop, zip } from "./utils";
import { scream } from "./sentry";
import type { Feature, LineString } from "geojson";
import type { BBOX } from "./geojson/validate";
import { GeometryNoCollection } from "./geojson/geojson";

const EPS = 1e-7; // floating point accuracy
export const isApprox = (a: number, b: number, eps: number = EPS) =>
  Math.abs(a - b) < eps;
export const isApproxBetween = (
  a: number,
  x: number,
  b: number,
  eps: number = EPS,
) => a - eps < x && x < b + eps;
export const isClearlyBetween = (
  a: number,
  x: number,
  b: number,
  eps: number = EPS,
) => a + eps < x && x < b - eps;

type Vec = number[];
export const vecAdd = <V extends Vec>(a: V, b: V): V =>
  zip(a, b).map(([aa, bb]) => aa + bb) as V; // Safety: length is preserved
export const vecSub = <V extends Vec>(a: V, b: V): V =>
  zip(a, b).map(([aa, bb]) => aa - bb) as V; // Safety: length is preserved
export const vecScale = <V extends Vec>(a: V, s: number): V =>
  a.map((aa) => aa * s) as V; // Safety: length is preserved
export const dot = (a: Vec, b: Vec): number => a[0] * b[0] + a[1] * b[1];
export const length = (v: Vec): number => Math.sqrt(dot(v, v));
export const sampleLine = <V extends Vec>(s: [V, V], f: number): V => {
  const ab = vecSub(s[1], s[0]);
  return vecAdd(s[0], vecScale(ab, f));
};
export const vecNormalize = (v: Vec): Vec => vecScale(v, 1 / length(v));
/** Returns in [-Pi, Pi] */
export const vectorAngle = (vec: Vec): number => Math.atan2(vec[1], vec[0]);
export const segmentAngle = ([a, b]: Vec[]): number =>
  vectorAngle(vecSub(b, a));
export const negateAngle = (a: number): number =>
  a < 0 ? a + Math.PI : a - Math.PI;
export const rad2deg = (r: number): number => (r * 180) / Math.PI;
export const deg2rad = (d: number): number => (d * Math.PI) / 180;
export const makeAngleBiggerThan = (a: number, b: number): number =>
  a + 2 * Math.PI * Math.ceil((b - a) / (2 * Math.PI));
export const angleToVector = (a: number): Vec => [Math.cos(a), Math.sin(a)];
export const rot90 = (v: Vec): Vec => [v[1], -v[0]];
export const bearingAdd = (a: number, b: number): number => {
  const sum = a + b;
  if (180 < sum) return sum - 360;
  if (sum < -180) return sum + 360;
  return sum;
};
/** Make an angle in degrees be in [0, 360) */
export const degreeNormalize = (a: number) => a - 360 * Math.floor(a / 360);

export const calculateEllipseY = (x: number, a: number, b: number) => {
  const ySquared = (1 - x ** 2 / a ** 2) * b ** 2;
  return Math.sqrt(Math.abs(ySquared));
};

export const calculateEllipseMajorAxisSpacing = (
  x: number,
  a: number,
  y: number,
) => {
  const bSquared = y ** 2 / (1 - x ** 2 / a ** 2);
  return Math.sqrt(bSquared);
};

export const isAngleApproxBetween = (
  a: number,
  x: number,
  b: number,
  eps: number = EPS,
): boolean => {
  const xAboveA = makeAngleBiggerThan(x, a - eps);
  return isApproxBetween(a, xAboveA, b, eps);
};

export const pointsAreEqual = (a: Vec, b: Vec, eps: number = EPS): boolean =>
  isApprox(length(vecSub(a, b)), 0.0, eps);

export const segmentsAreEqual = (
  a: [Vec, Vec],
  b: [Vec, Vec],
  eps: number = EPS,
): boolean => {
  return (
    (pointsAreEqual(a[0], b[0], eps) && pointsAreEqual(a[1], b[1], eps)) ||
    (pointsAreEqual(a[0], b[1], eps) && pointsAreEqual(a[1], b[0], eps))
  );
};

export const segmentsAreEqual2 = (
  a: [Vec, Vec],
  b: [Vec, Vec],
  eps: number = EPS,
): boolean => {
  return (
    (Math.abs(a[0][0] - b[0][0]) < eps &&
      Math.abs(a[0][1] - b[0][1]) < eps &&
      Math.abs(a[1][0] - b[1][0]) < eps &&
      Math.abs(a[1][1] - b[1][1]) < eps) ||
    (Math.abs(a[0][0] - b[1][0]) < eps &&
      Math.abs(a[0][1] - b[1][1]) < eps &&
      Math.abs(a[1][0] - b[0][0]) < eps &&
      Math.abs(a[1][1] - b[0][1]) < eps)
  );
};

/**
 * Argument order doesn't matter.
 */
export const pointsAreInLine = (
  a: Vec,
  b: Vec,
  c: Vec,
  eps?: number,
): boolean => {
  // If a == b then the three points always form a line. Otherwise,
  // project and see if we moved the point.
  if (pointsAreEqual(a, b)) return true;
  const cc = projectPointToLine(c, [a, b]);
  return pointsAreEqual(c, cc.point, eps);
};

export function projectPointToLine(
  pt: Vec,
  [lineFrom, lineTo]: Vec[],
): { point: Vec; factor: number } {
  const line = vecSub(lineTo, lineFrom);
  const normPt = vecSub(pt, lineFrom);
  const factor = dot(line, normPt) / dot(line, line);
  const point = sampleLine([lineFrom, lineTo], factor);
  return {
    point,
    factor,
  };
}

export function projectPointToSegment(pt: number[], segment: number[][]): Vec {
  const { point, factor } = projectPointToLine(pt, segment);
  if (factor < 0) return segment[0];
  if (1 < factor) return segment[1];
  return point;
}

export function distancePointToSegment(
  pt: number[],
  segment: number[][],
): number {
  const projected = projectPointToSegment(pt, segment);
  const diff = vecSub(pt, projected);
  return length(diff);
}

export function distancePointToPolygonBoundary(
  pt: Vec,
  boundaryPoints: Vec[],
): number {
  return movingWindow(boundaryPoints).reduce((min, segment) => {
    const dist = distancePointToSegment(pt, segment);
    return dist < min ? dist : min;
  }, Infinity);
}

export const distanceToZone = (
  t: TurbineFeature,
  region: SubAreaFeature | ParkFeature,
) =>
  fastMin(
    region.geometry.coordinates.flatMap((ring) =>
      movingWindow(ring, 2).map((segment) => {
        const proj = projectPointToSegment(t.geometry.coordinates, segment);
        return turf.distance(t.geometry.coordinates, proj, {
          units: "meters",
        });
      }),
    ),
  );

export function lineIntersectionIsClearlyOutsideEitherSegment(
  li: LineIntersection,
  eps = EPS,
): boolean {
  return (
    !isApproxBetween(0.0, li.s, 1.0, eps) ||
    !isApproxBetween(0.0, li.t, 1.0, eps) ||
    isNaN(li.pt[0])
  );
}

export function lineIntersectionIsClearlyOnSegments(
  li: LineIntersection,
  eps = EPS,
): boolean {
  return (
    isClearlyBetween(0.0, li.s, 1.0, eps) &&
    isClearlyBetween(0.0, li.t, 1.0, eps)
  );
}

export type LineIntersection = { s: number; t: number; pt: number[] };

/**
 * Compute the intersection of two lines and returns the two scalars for the two lines,
 * as well as the intersection point. If the lines are parallel and not overlapping, return
 * NaN for the points and `[0, 0]` for the intersection point. If the lines are parallel and
 * overlapping, return any point that is on both lines.
 *
 * Any point on the line crossing two points `a` and `b` can be written as `a + s * (b - a)` for some number `s`.
 * For instance, if `s == 0` then the point is `a`, if `s == 0.5` the point is in the middle of `a` and b`, and
 * if `s == 2` the point is past `b` so that `b` is right in the middle of `a` and the point.
 *
 * @param line1 The first line
 * @param line2 The second line
 * @returns a `LineIntersection` object `{s, t, pt}` containing the two scalar factors for the two lines and the intersection point.
 */
export function getLineIntersection(
  line1: number[][],
  line2: number[][],
  debug: boolean = false,
): LineIntersection {
  const [a, b] = line1;
  const [c, d] = line2;

  const [abx, aby] = [b[0] - a[0], b[1] - a[1]];
  const [cdx, cdy] = [d[0] - c[0], d[1] - c[1]];

  // Want to sovle the system
  // |abx -cdx| |s| = |cx - ax|
  // |aby -cdy| |t| = |cy - ay|

  const det = abx * -cdy + aby * cdx;
  if (isApprox(det, 0)) {
    // The lines are parallel.  If the lines are distinct they don't intersect at all.
    if (!pointsAreInLine(a, b, c)) {
      if (debug) console.log("parallel lines are offset. No intersection");
      return { s: NaN, t: NaN, pt: [NaN, NaN] };
    }
    // The lines are equal.  In order to be useful, we try to find a point that is on both line *segments*,
    // and report this as the intersection point, even though all points on the line intersects the other line.
    // Write the points as `a + t * ab` for some `t`, to reduce the problem to 1D.
    const ct = projectPointToLine(c, [a, b]).factor;
    const dt = projectPointToLine(d, [a, b]).factor;

    const ab0 = 0;
    const ab1 = 1;
    const cd0 = Math.min(ct, dt);
    const cd1 = Math.max(ct, dt);
    // We have three cases that should result in an overlap:
    //               ab0 ----------- ab1
    // Case 1: cd0 ------- cd1
    // Case 2:                 cd0 ------- cd1
    // Case 3: cd0 -------------------------- cd1

    if (cd0 <= ab1 && ab0 <= cd1) {
      // Collision. Now we just have to find a point the collision.
      // Choose the leftmost point.
      if (ab0 < cd0) {
        // Case 2.
        if (ct < dt) {
          // c--d is in the same direction as a--b
          const s = projectPointToLine(c, [a, b]).factor;
          const t = 0;
          if (debug)
            console.log("intersection case 2, same dir", { s, t, pt: c });
          return { s, t, pt: c };
        } else {
          const s = projectPointToLine(d, [a, b]).factor;
          const t = 1;
          if (debug)
            console.log("intersection case 2, diff dir", { s, t, pt: d });
          return { s, t, pt: d };
        }
      } else {
        // Case 1 or 3, but it doesn't matter.
        const s = ab0;
        const t = projectPointToLine(a, [c, d]).factor;
        if (debug)
          console.log("intersection case 1/3", { s, t, pt: a, ct, dt });
        return { s, t, pt: a };
      }
    }

    // Lines are parallel, so `s` and `t` are not defined.  Leave them as NaN so callers can detect this case.
    return { s: NaN, t: NaN, pt: a };
  }

  const dx = c[0] - a[0];
  const dy = c[1] - a[1];

  // Inverse matrix is
  // |-cdy  cdx|  /
  // |-aby  abx| / det
  // and we wan the first row of A^-1 * d
  const s = (-cdy * dx + cdx * dy) / det;
  const t = (-aby * dx + abx * dy) / det;

  return { s, t, pt: [a[0] + s * abx, a[1] + s * aby] };
}

export function pointInPolygon(
  point: turf.Point,
  polygonOrMultiPolygon: turf.Polygon | turf.MultiPolygon,
  eps: number = EPS,
): boolean {
  if (turf.booleanPointInPolygon(point.coordinates, polygonOrMultiPolygon))
    return true;

  const polygonsCoordinates =
    polygonOrMultiPolygon.type === "Polygon"
      ? [polygonOrMultiPolygon.coordinates]
      : polygonOrMultiPolygon.coordinates;
  // If we are on the boundary, the above method sometimes fails.  Check that we're really close instead.
  const closests = polygonsCoordinates.map((rings) =>
    rings.reduce((min, ring) => {
      const dist = distancePointToPolygonBoundary(point.coordinates, ring);
      return dist < min ? dist : min;
    }, Infinity),
  );
  return closests.some((closest) => isApprox(closest, 0, eps));
}

export const rotate = (vec: Position, angle: number): Position => {
  // Rotation matrix is
  // [ cos t  -sin t]
  // [ sin t   cos t]
  const cost = Math.cos(angle);
  const sint = Math.sin(angle);
  return [vec[0] * cost + vec[1] * -sint, vec[0] * sint + vec[1] * cost];
};

export function lineInPolygon(
  line: turf.LineString,
  polygon: turf.Polygon,
): boolean {
  if (
    turf.booleanContains(polygon, line) ||
    !turf.booleanDisjoint(polygon, line)
  )
    return true;
  return false;
}

/**
 * Apply the function `fn` to the vector `vec` in the rotated coordinate system.
 * Rotates back the resulting vector to the initial coordinate system.
 */
export const rotateMap = (
  vec: Position,
  angle: number,
  fn: (p: Position) => Position,
): Position => rotate(fn(rotate(vec, angle)), -angle);

/**
 * 0 means that the two segments are parallel. Positive is CCW.
 */
export const vertexAngle = (
  inSegment: Position[],
  outSegment: Position[],
): number => {
  const inAngle = segmentAngle(inSegment);
  const outVector = vecSub(outSegment[1], outSegment[0]);
  const rotated = rotate(outVector, -inAngle);
  const angle = vectorAngle(rotated);
  return angle;
};

/** Computes the total curvature of the boundary ring of a polygon. */
export function totalPolygonBoundaryCurvature(polygon: turf.Polygon) {
  const segments = movingWindow(polygon.coordinates[0]);
  return movingWindowLoop(segments).reduce(
    (a, [lastSegment, nextSegment]) =>
      a + vertexAngle(lastSegment, nextSegment),
    0,
  );
}

/** Computes the total curvature of the boundary ring of a polygon. */
export function totalRingCurvature(ring: turf.Position[]) {
  const segments = movingWindow(ring);
  return movingWindowLoop(segments).reduce(
    (a, [lastSegment, nextSegment]) =>
      a + vertexAngle(lastSegment, nextSegment),
    0,
  );
}

export function getCenterOfFeatures(
  features: mapboxgl.MapboxGeoJSONFeature[],
): turf.helpers.Position;
export function getCenterOfFeatures(
  features: ProjectFeature[],
): turf.helpers.Position;
export function getCenterOfFeatures(
  features: mapboxgl.MapboxGeoJSONFeature[] | ProjectFeature[],
): turf.helpers.Position {
  return turf.center({
    features: features as Feature<GeometryNoCollection>[], // safety: not really. But we don't use geometrycollection at all.
    type: "FeatureCollection",
  }).geometry.coordinates;
}

export function lonlat2xy(
  lon: number,
  lat: number,
  refLon: number,
  refLat: number,
  decimals: number = 0,
): { x: number; y: number } {
  const options: { units: turf.Units } = { units: "meters" };
  const refPoint = turf.point([refLon, refLat]);
  const point = turf.point([lon, lat]);

  const bearing = turf.rhumbBearing(refPoint, point);
  const distance = turf.rhumbDistance(refPoint, point, options);
  const decimalHelper = 10 ** decimals;
  return {
    y:
      Math.round(
        decimalHelper * distance * Math.cos((bearing * Math.PI) / 180),
      ) / decimalHelper,
    x:
      Math.round(
        decimalHelper * distance * Math.sin((bearing * Math.PI) / 180),
      ) / decimalHelper,
  };
}

export function xy2lonlat(
  x: number,
  y: number,
  refLon: number,
  refLat: number,
): [number, number] {
  const options: { units: turf.Units } = { units: "meters" };
  const refPoint = turf.point([refLon, refLat]);
  const direction = 90 - (Math.atan2(y, x) * 180) / Math.PI;
  const distance = Math.sqrt(y ** 2 + x ** 2);
  const newPoint = turf.transformTranslate(
    refPoint,
    distance,
    direction,
    options,
  );
  return newPoint.geometry.coordinates as [number, number]; // Safety: `refPoint` is 2D, so this is also 2D.
}

export const getSizeOfBBOXInKM2 = (bbox: number[][]) =>
  (((bbox[1][0] - bbox[0][0]) * (bbox[1][1] - bbox[0][1])) / 1000) * 1000;

export const insideBounds = (
  coordinates: [number, number],
  bounds: [number, number, number, number],
) =>
  turf.booleanPointInPolygon(turf.point(coordinates), turf.bboxPolygon(bounds));

export const getBearingFromTwoPoints = (
  coord1: [number, number],
  coord2: [number, number],
) => turf.bearing(turf.point(coord1), turf.point(coord2));

export const bbox = (f: ProjectFeature | Feature): BBOX => {
  const bbox = turf.bbox(f);
  if (bbox.length !== 4) throw scream("Feature is not 2D", { f, bbox });
  return bbox;
};

/**
 * Sample a line string so that two points on the curve are at most `maxSpacing` meters away from each other.
 * Corners and endpoints are preserved exactly.
 */
export const sampleLineString = (
  ls: LineString,
  /** Maximal number of meters in between sample points. */
  maxSpacing: number,
): { points: Position[]; distances: number[] } => {
  if (ls.coordinates.length === 0) return { points: [], distances: [] };
  const points: Position[] = [];
  const distances: number[] = [];
  let totalDist = 0;
  for (const segment of movingWindow(ls.coordinates)) {
    const len = turf.length(turf.lineString(segment), { units: "meters" });
    const numPoints = Math.ceil(len / maxSpacing);
    const spacing = len / numPoints;
    for (let i = 0; i < numPoints; i++) {
      const pt = turf.along(turf.lineString(segment), i * spacing, {
        units: "meters",
      });
      points.push(pt.geometry.coordinates);
      distances.push(totalDist + i * spacing);
    }
    totalDist += len;
  }
  points.push(ls.coordinates.at(-1)!);
  distances.push(totalDist);
  return { points, distances };
};

const oneSquareKilometerInSquareMeters = 1000000;
export const sqMeterToSqKm = (squareMeter: number) =>
  squareMeter / oneSquareKilometerInSquareMeters;
export const getHumanReadableArea = (sqm: number): string =>
  sqm > 10 * oneSquareKilometerInSquareMeters
    ? `${sqMeterToSqKm(sqm).toFixed(1)} km\u00b2`
    : sqm > 0.1 * oneSquareKilometerInSquareMeters
      ? `${sqMeterToSqKm(sqm).toFixed(2)} km\u00b2`
      : `${sqm.toFixed(0)} m\u00b2`;

export const getHumanReadableDistance = (distanceMeter: number): string =>
  `${
    distanceMeter < 1000
      ? `${Math.round(distanceMeter)} m`
      : distanceMeter < 10000
        ? `${(distanceMeter / 1000).toFixed(2)} km`
        : `${(distanceMeter / 1000).toFixed(1)} km`
  }`;
