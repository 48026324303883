/// <reference types="vite-plugin-svgr/client" />
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import SliderIcon from "@icons/24/Slider.svg?react";
import { MooringLineSingleFeature, ProjectFeature } from "../../types/feature";
import Tooltip from "../General/Tooltip";
import { useProjectElementsCrud } from "../ProjectElements/useProjectElementsCrud";
import { MooringLineFeature } from "../../types/feature";
import { isMooringLine } from "../../utils/predicates";
import { RangeWithDimInput } from "../General/RangeWithDimInput";
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import {
  getAnchorsSelectorFamily,
  getMooringLinesSelector,
  getTurbinesSelectorFamily,
} from "../../state/layout";
import {
  ANCHOR_RADIUS_MAX_KM,
  MOORING_LINE_LENGTH_MIN_KM,
} from "../../services/mooringLineTypeService";
import { useClickOutside } from "../../hooks/useClickOutside";
import { previewMooringAndFoundationState } from "../GenerateFoundationsAndAnchors/state";
import { ColorSelectorWrapper } from "./FeatureCoordinateEditor.style";
import { MenuFrame } from "../MenuPopup/CloseableMenuPopup";
import * as turf from "@turf/turf";
import { IconBtn } from "components/General/Icons";

const LineLengthEditor = ({
  parkId,
  feature,
  setOpen,
}: {
  parkId: string;
  feature: MooringLineSingleFeature;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const popupRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(popupRef, () => setOpen(false));

  const turbines = useRecoilValue(
    getTurbinesSelectorFamily({ parkId: parkId ?? "" }),
  );
  const anchors = useRecoilValue(getAnchorsSelectorFamily(parkId ?? ""));
  const mooringLines = useRecoilValue(getMooringLinesSelector(parkId ?? ""));

  const setPreviewMooringAndFoundationState = useSetRecoilState(
    previewMooringAndFoundationState,
  );

  const { update: updateFeatures } = useProjectElementsCrud();
  const saveOnExitCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const p = await snapshot.getPromise(previewMooringAndFoundationState);
        if (!p) return;

        await updateFeatures({ update: [...p.preview.mooringLines] });
        set(previewMooringAndFoundationState, undefined);
      },
    [updateFeatures],
  );

  useEffect(() => {
    return () => {
      saveOnExitCallback();
    };
  }, [saveOnExitCallback]);

  const updateLineLength = useCallback(
    (line: MooringLineFeature, lineLength: number) => {
      const updatedline = {
        ...line,
        properties: {
          ...line.properties,
          lineLength: lineLength,
        },
      };

      const existingMooringLines = mooringLines.filter(
        (ml) => ml.id !== line.id,
      );

      setPreviewMooringAndFoundationState({
        preview: {
          foundations: [],
          mooringLines: [updatedline],
          anchors: [],
        },
        existing: {
          mooringLines: existingMooringLines,
          anchors: anchors,
        },
      });
    },
    [anchors, mooringLines, setPreviewMooringAndFoundationState],
  );

  const lineLength = feature.properties.lineLength;
  const [previewLineLength, setPreviewLineLength] = useState(lineLength);
  const anchorRadius = useMemo(() => {
    const anchor = anchors.find((a) => a.id === feature.properties.anchor);
    const turbine = turbines.find((t) => t.id === feature.properties.target);

    if (!anchor || !turbine) return ANCHOR_RADIUS_MAX_KM;

    return turf.distance(
      turbine.geometry.coordinates,
      anchor.geometry.coordinates,
      { units: "kilometers" },
    );
  }, [anchors, turbines, feature]);

  return (
    <div
      style={{
        position: "absolute",
        top: "0.6rem",
        transform: "translateX(-50%)",
      }}
    >
      <MenuFrame title="Line length" onExit={() => setOpen(false)}>
        <RangeWithDimInput
          unit={"km"}
          units={["km"]}
          min={MOORING_LINE_LENGTH_MIN_KM}
          max={anchorRadius + 0.5}
          rangeStep={0.01}
          value={previewLineLength}
          onChange={(d) => {
            setPreviewLineLength(d);
            updateLineLength(feature, d);
          }}
        />
      </MenuFrame>
    </div>
  );
};

const MooringLineLengthEditor = ({
  feature,
  parkId,
}: {
  feature: ProjectFeature;
  parkId: string;
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Tooltip position="top" text={"Edit line length"}>
        <ColorSelectorWrapper>
          <IconBtn
            disabled={"lineLengths" in feature.properties}
            active={open}
            onClick={() => {
              setOpen(true);
            }}
          >
            <SliderIcon />
          </IconBtn>
        </ColorSelectorWrapper>
      </Tooltip>
      <div style={{ position: "relative", alignSelf: "end" }}>
        {open && isMooringLine(feature) && (
          <LineLengthEditor
            parkId={parkId}
            feature={feature}
            setOpen={setOpen}
          />
        )}
      </div>
    </>
  );
};

export default MooringLineLengthEditor;
