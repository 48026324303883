import React, {
  ChangeEvent,
  forwardRef,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled, { CSSProperties } from "styled-components";
import CloseIcon from "@icons/24/Close.svg?react";
import SearchIcon from "@icons/24/Search.svg?react";
import {
  IconREMSize,
  RegularRaw,
  TextRaw,
  typography,
} from "../../styles/typography";
import { colors } from "../../styles/colors";
import {
  borderRadiusMedium,
  spaceSmall,
  spacing1,
  spacing3,
  spacing5,
  spacing6,
} from "../../styles/space";
import { Comp } from "../../types/utils";
import { ValidationContainer } from "./Validate";
import { Distance } from "../Units/units";

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  height: 100%;
`;

export const InputLabel = styled.p`
  ${TextRaw};
  margin: 0;
`;

export const InputLabelDiv = styled.div`
  ${TextRaw};
  margin: 0;
`;

export const TextInput = styled.input<{
  invalid?: boolean;
  compact?: boolean;
  locked?: boolean;
  smallInput?: boolean;
}>`
  ${RegularRaw}
  ${typography.body}

  ${(p) =>
    p.compact
      ? `padding: ${spacing1} ${spacing5}`
      : `padding: ${spacing3} ${spacing6}`};

  box-sizing: border-box;
  height: ${(p) => (p.compact ? "2.4rem" : "3.2rem")};

  border: 1px solid ${colors.borderDefault};
  border-radius: ${borderRadiusMedium};

  background-color: white;

  &::placeholder {
    color: ${colors.textDisabled};
  }

  &:focus {
    outline: none;
    border-color: ${colors.borderSelected};
  }

  &:hover {
    border-color: ${colors.grey400};
  }

  &:invalid:not(:focus) {
    border-color: ${(p) =>
      p.formNoValidate ? "none" : colors.borderError} !important;
  }

  ${({ invalid }) =>
    invalid && `border-color: ${colors.borderError} !important;`};

  &:disabled {
    border-color: ${colors.grey300};
    background-color: ${colors.surfaceDisabled};
    color: ${colors.grey500};
    cursor: not-allowed;
  }

  ${(p) =>
    p.locked &&
    `
    && {
      background-color: white;
      color: ${colors.primaryText};
      ${lockedCss(colors.primaryDisabled)}
    }
  `}

  ${(p) =>
    p.smallInput &&
    `
    && {
      padding: 0;
      border: none;
      border-radius: 0;
      box-shadow: none;
      background-color: transparent;
      margin: 0;
      height: 2rem;
      border-bottom: 1px solid ${colors.borderDefault};
    }
  `}
`;

export type InputProps = {
  locked?: boolean;
  invalid?: boolean;
  compact?: boolean;
  mini?: boolean;
  smallInput?: boolean;
  onEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onCancel?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

export const Input = forwardRef<HTMLInputElement, Comp<"input", InputProps>>(
  ({ onEnter, onCancel, ...props }, ref) => {
    const _onKeyDown = useCallback(
      (e: any) => {
        e.stopPropagation();
        props.onKeyDown?.(e);
        if (e.key === "Enter") onEnter?.(e);
        if (e.key === "Escape") onCancel?.(e);
      },
      [onCancel, onEnter, props],
    );

    return <TextInput onKeyDown={_onKeyDown} {...props} ref={ref} />;
  },
);

type SearchInputProps = Omit<Comp<"input", InputProps>, "type"> & {
  onClear?(): void;
  wrapperDivStyle?: React.CSSProperties;
};

export const SearchInput = ({
  wrapperDivStyle,
  onClear,
  ...props
}: SearchInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <div
      style={{
        position: "relative",
        ...wrapperDivStyle,
      }}
    >
      <IconREMSize
        height={1.6}
        width={1.6}
        style={{
          position: "absolute",
          left: "1rem",
          top: "50%",
          transform: "translateY(-50%)",
        }}
        onClick={() => inputRef.current?.focus()}
      >
        <SearchIcon />
      </IconREMSize>
      <TextInput
        ref={inputRef}
        type="text"
        {...props}
        style={{
          paddingLeft: "3.5rem",
          paddingRight: "2.5rem",
          ...props.style,
        }}
      />
      {props.value !== "" && onClear && (
        <IconREMSize
          height={1}
          width={1}
          style={{
            position: "absolute",
            right: "1rem",
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer",
          }}
          onClick={() => {
            onClear();
            inputRef.current?.focus();
          }}
          hoverStroke={colors.blue800}
        >
          <CloseIcon />
        </IconREMSize>
      )}
    </div>
  );
};

const StyledTextarea = styled.textarea`
  ${TextRaw};
  min-height: 3.8rem;

  border-radius: 0.4rem;

  box-sizing: border-box;

  border: 1px solid ${colors.inputOutline};
  background-color: white;
  &::placeholder {
    color: ${colors.primaryDisabled};
  }

  &:focus {
    outline: none;
    border-color: ${colors.primary};
    &::placeholder {
      color: transparent;
    }
  }

  &:invalid {
    border-color: ${colors.redAlert};
  }

  &:disabled {
    background-color: ${colors.focusBackground};
    color: ${colors.primaryDisabled};
  }
`;

interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  onEnter?: () => void;
  onCancel?: () => void;
}

export const TextArea = ({ onEnter, onCancel, ...props }: TextAreaProps) => {
  const _onKeyDown = useCallback(
    (e: any) => {
      e.stopPropagation();
      props.onKeyDown?.(e);
      if (e.key === "Enter") onEnter?.();
      if (e.key === "Escape") onCancel?.();
    },
    [onCancel, onEnter, props],
  );

  return <StyledTextarea onKeyDown={_onKeyDown} {...props} />;
};

const chevronSize = "10px";
const chevronCss = (color: string) => `
  background: url('data:image/svg+xml,<svg height="${chevronSize}" width="${chevronSize}" viewBox="0 0 24 24"  fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.25 7.31104L12.53 18.03C12.4604 18.0997 12.3778 18.1549 12.2869 18.1926C12.1959 18.2304 12.0984 18.2498 12 18.2498C11.9016 18.2498 11.8041 18.2304 11.7131 18.1926C11.6222 18.1549 11.5396 18.0997 11.47 18.03L0.75 7.31104" stroke="%23${color.slice(
  1,
)}" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
</svg>')
    no-repeat;
  background-position: calc(100% - 0.8rem) !important;
`;

const lockedCss = (color: string) => `
  background: url('data:image/svg+xml,<svg height="16" width="16" viewBox="0 0 16 16"  fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.5 6.5H3.5C2.94772 6.5 2.5 6.94772 2.5 7.5V14.5C2.5 15.0523 2.94772 15.5 3.5 15.5H12.5C13.0523 15.5 13.5 15.0523 13.5 14.5V7.5C13.5 6.94772 13.0523 6.5 12.5 6.5Z" stroke="%23${color.slice(
  1,
)}" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
<path d="M4.5 6.5V4C4.5 3.07174 4.86875 2.1815 5.52513 1.52513C6.1815 0.868749 7.07174 0.5 8 0.5C8.92826 0.5 9.8185 0.868749 10.4749 1.52513C11.1313 2.1815 11.5 3.07174 11.5 4V6.5" stroke="%23${color.slice(
  1,
)}" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
<path d="M8 10V12" stroke="%23${color.slice(
  1,
)}" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
</svg>')
    no-repeat;
    background-position: calc(100% - 0.6rem) !important;
`;

export const StyledDimensionedInput = styled.div<{
  compact?: boolean;
  mini?: boolean;
  single?: boolean;
  invalid?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  flex-direction: row;
  background: #fff;
  gap: ${spaceSmall};
  position: relative;
  box-sizing: border-box;

  border: 1px solid ${colors.borderDefault};
  border-radius: ${borderRadiusMedium};

  min-height: ${(p) => (p.compact ? "2.4rem" : p.mini ? "2rem" : "3.2rem")};
  max-height: ${(p) => (p.compact ? "2.4rem" : p.mini ? "2rem" : "3.2rem")};
  width: 13rem;

  input {
    width: 0;
    flex: 1 1 2rem;
    min-width: 1lh;
    border-radius: inherit;
    background: inherit;
    border: none;
    height: ${(p) => (p.compact ? "2rem" : p.mini ? "1.4rem" : "2.8rem")};

    ${(p) =>
      p.compact
        ? `padding: ${spacing1} 0 ${spacing1} ${spacing5}`
        : `padding: ${spacing3} 0 ${spacing3} ${spacing6}`};
  }

  &::placeholder {
    color: ${colors.textDisabled};
  }

  &:focus-within {
    outline: none;
    border-color: ${colors.borderSelected};
  }

  &:hover:not(:focus-within) {
    border-color: ${colors.grey400};
  }

  &:disabled {
    border-color: ${colors.grey300};
    background-color: ${colors.surfaceDisabled};
    color: ${colors.grey500};
    cursor: not-allowed;
  }

  ${(p) => p.invalid && `border-color: ${colors.textError} !important`};

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    appearance: textfield;
  }

  select {
    ${TextRaw};
    text-align: end;
    border-radius: inherit;
    border: none;
    ${(p) =>
      p.compact
        ? ` padding: 0      calc(1.4rem + ${chevronSize}) 0      0`
        : p.mini
          ? ` padding: 0 calc(1rem + ${chevronSize}) 0 0`
          : ` padding: 0.5rem calc(1.4rem + ${chevronSize}) 0.5rem 0`};
    background: inherit;

    &:focus-visible {
      outline: none;
      text-decoration: underline;
    }

    ${(p) =>
      !p.single &&
      chevronCss(p.disabled ? colors.textDisabled : colors.textPrimary)}
    ${(p) => p.single && `padding-right: ${spaceSmall};`}

    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
  }

  select:disabled {
    color: ${colors.textDisabled};
  }

  ${(p) =>
    p.disabled &&
    `color: ${colors.inputOutline};
     border-color: ${colors.inputOutline};
     background-color: ${colors.focusBackground};
     `}
`;

const ValidationWrapper = styled.div`
  position: absolute;
  transform: translateY(110%);
  bottom: 0;
  z-index: 1;
`;

export const InputDimensioned = <U extends string>({
  value,
  unit,
  units,
  onChange,
  onUnitChange,
  onDefault,
  validate,
  validationMessage,
  validationMessageStyle,
  compact,
  mini,
  style,
  format = (v) => v.toString(),
  min,
  max,
  ...props
}: {
  value?: number;
  min?: number;
  max?: number;
  unit?: U;
  units?: readonly U[];
  onChange?: (
    value: number,
    e: ChangeEvent<HTMLInputElement>,
    unit?: U,
  ) => void;
  onUnitChange?: (unit: U, e: ChangeEvent<HTMLSelectElement>) => void;
  onDefault?: (unit: U | undefined) => number;
  validate?: (value: number, unit?: U) => boolean;
  validationMessage?: string;
  validationMessageStyle?: React.CSSProperties;
  style?: CSSProperties;
  compact?: boolean;
  mini?: boolean;
  /** Formatter for driven values.  Note that user inputted numbers will not be ran through this. */
  format?: (value: number, unit?: U) => string;
} & Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "onChange" | "min" | "max"
>) => {
  const singleUnit = (units?.length ?? 0) <= 1;

  const [text, setText] = useState(
    value !== undefined ? format(value, unit) : "",
  );
  const [valid, setValid] = useState(true);
  const [hasFocus, setFocus] = useState(false);

  const valueDiffers =
    value != null ? 1e-5 < Math.abs(parseFloat(text) - value) : false;
  useEffect(() => {
    if (
      (valueDiffers || isNaN(parseFloat(text))) &&
      !hasFocus &&
      value !== undefined
    ) {
      const formatted = format(value, unit);
      setText(formatted);
      const valid = validate?.(value) ?? true;
      setValid(valid);
    }
  }, [format, hasFocus, text, unit, validate, value, valueDiffers]);

  // If value is changed from something to undefined, clear the text field.
  useEffect(() => {
    if (value === undefined) setText("");
  }, [value]);

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <StyledDimensionedInput
        style={style}
        compact={compact}
        mini={mini}
        single={singleUnit}
        invalid={!valid}
        disabled={props.disabled}
        ref={containerRef}
      >
        <Input
          type="number"
          compact={compact}
          mini={mini}
          value={text}
          min={min}
          max={max}
          onKeyDown={(e) => {
            e.stopPropagation();
            if (e.key === "Enter" && text.trim() === "" && onDefault) {
              const defaultValue = onDefault(unit);
              setText(defaultValue.toString());
              setValid(true);
            }
          }}
          {...props}
          onFocus={(e) => {
            setFocus(true);
            props.onFocus?.(e);
          }}
          onBlur={(e) => {
            setFocus(false);
            props.onBlur?.(e);
          }}
          onChange={(e) => {
            e.stopPropagation();
            e.preventDefault();

            setText(e.target.value);

            const num = parseFloat(e.target.value.replaceAll(",", "."));
            if (validate && !validate(num, unit)) {
              setValid(false);
              return;
            }
            setValid(true);
            onChange?.(num, e, unit);
          }}
        />

        <select
          value={unit}
          disabled={singleUnit || props.disabled}
          onChange={(e) => {
            const u = e.target.value as U;
            onUnitChange?.(u, e);
          }}
          tabIndex={-1}
          style={{
            color: colors.textSecondary,
            opacity: props.disabled ? 0.5 : undefined,
          }}
        >
          {(units ? units : unit ? [unit] : []).map((u) => (
            <option key={u} value={u}>
              {u}
            </option>
          ))}
        </select>
        {!valid && validationMessage && !props.disabled && (
          <ValidationPositioner style={validationMessageStyle}>
            <ValidationContainer>
              <p>{validationMessage}</p>
            </ValidationContainer>
          </ValidationPositioner>
        )}
      </StyledDimensionedInput>
    </>
  );
};

/** Uses the cannonical unit of {@link Distance} (meter). */
export const InputDimensionedDistance = (
  props: Parameters<typeof InputDimensioned>[0],
) => {
  const [unit, setUnit] = useState(Distance.cannonical);
  return (
    <InputDimensioned
      {...props}
      unit={unit}
      units={Distance.units}
      onChange={(value, event) => {
        const cann = Distance.convert(value, { from: unit });
        props.onChange?.(cann, event, unit);
      }}
      onUnitChange={setUnit}
    />
  );
};

// Move the validation "popup" if it is outside the screen
const ValidationPositioner = ({
  style,
  children,
}: { style?: React.CSSProperties } & React.PropsWithChildren) => {
  const thisRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!thisRef.current) {
      return;
    }

    const observer = new IntersectionObserver(([entry1]) => {
      if (!thisRef.current) {
        return;
      }

      const isRightSideOfScreen =
        entry1.boundingClientRect.right > entry1.intersectionRect.right;
      const toMove =
        entry1.boundingClientRect.width - entry1.intersectionRect.width;

      if (isRightSideOfScreen) {
        thisRef.current.style.left = `-${toMove}px`;
      } else {
        thisRef.current.style.left = `${toMove}px`;
      }
    }, {});

    observer.observe(thisRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <ValidationWrapper ref={thisRef} style={style}>
      {children}
    </ValidationWrapper>
  );
};

export const InputNumber = <U extends string>({
  value,
  onChange,
  validate,
  validationMessage,
  compact,
  style,
  format = (v) => v.toString(),
  blurOnEnter = true,
  ...props
}: {
  value?: number;
  onChange?: (value: number, e: ChangeEvent<HTMLInputElement>) => void;
  validate?: (value: number) => boolean;
  validationMessage?: string;
  style?: CSSProperties;
  compact?: boolean;
  blurOnEnter?: boolean;
  /** Formatter for driven values.  Note that user inputted numbers will not be ran through this. */
  format?: (value: number, unit?: U) => string;
} & Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "onChange" | "min" | "max"
>) => {
  const [text, setText] = useState(value != null ? format(value) : "");
  const [valid, setValid] = useState(true);
  const [hasFocus, setFocus] = useState(false);

  const valueDiffers =
    value != null ? 1e-5 < Math.abs(parseFloat(text) - value) : false;
  useEffect(() => {
    if (valueDiffers && !hasFocus && value !== undefined) {
      const formatted = format(value);
      setText(formatted);
      const valid = validate?.(value) ?? true;
      setValid(valid);
    }
  }, [format, hasFocus, text, validate, value, valueDiffers]);

  // If value is changed from something to undefined, clear the text field.
  useEffect(() => {
    if (value === undefined) setText("");
  }, [value]);

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <StyledDimensionedInput
        style={style}
        compact={compact}
        single={true}
        invalid={!valid}
        className="StyledDimensionedInput-2"
        disabled={props.disabled}
        ref={containerRef}
      >
        <Input
          type="number"
          value={text}
          onKeyDown={(e) => {
            if (e.key === "Escape") e.stopPropagation();
            if (e.key === "Enter" && blurOnEnter) {
              (e.target as HTMLElement)["blur"]?.();
            }
          }}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onChange={(e) => {
            e.stopPropagation();
            e.preventDefault();

            setText(e.target.value);

            const num = parseFloat(e.target.value.replaceAll(",", "."));
            if (validate && !validate(num)) {
              setValid(false);
              return;
            }
            setValid(true);
            onChange?.(num, e);
          }}
          compact={compact}
          {...props}
        />
        {!valid && validationMessage && !props.disabled && (
          <ValidationPositioner>
            <ValidationContainer>
              <p>{validationMessage}</p>
            </ValidationContainer>
          </ValidationPositioner>
        )}
      </StyledDimensionedInput>
    </>
  );
};
