import {
  multiplayerBackgroundColors,
  multiplayerFontColors,
} from "../../styles/colors";

export function hashCode(s: string): number {
  let hash = 0;
  for (let i = 0; i < s.length; i++) {
    const char = s.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32-bit integer
  }
  return hash;
}

export function getUserColor(userId: string): string {
  const colorsArray = Object.values(multiplayerBackgroundColors);
  const index = Math.abs(hashCode(userId) % colorsArray.length);
  return colorsArray[index];
}
export function getUserFontColor(userId: string): string {
  const colorsArray = Object.values(multiplayerFontColors);
  const index = Math.abs(hashCode(userId) % colorsArray.length);
  return colorsArray[index];
}
