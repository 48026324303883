import { organisationGroupsState } from "components/Organisation/Groups/state";
import { useTypedPath } from "state/pathParams";
import { addGroup, removeGroup, updateGroup } from "./service";
import { useRecoilCallback } from "recoil";
import { scream } from "utils/sentry";

const useGroupsCrud = () => {
  const { organisationId } = useTypedPath("organisationId");

  const add = useRecoilCallback(
    ({ set, snapshot }) =>
      async (name: string) => {
        const fallback = await snapshot.getPromise(
          organisationGroupsState({ organisationId }),
        );
        return addGroup(organisationId, name)
          .then((newGroup) => {
            set(organisationGroupsState({ organisationId }), (cur) => [
              ...cur,
              newGroup,
            ]);
            return newGroup;
          })
          .catch((e) => {
            scream("Failed add group", { e });
            set(organisationGroupsState({ organisationId }), fallback);
          });
      },
    [organisationId],
  );

  const update = useRecoilCallback(
    ({ set, snapshot }) =>
      async (groupId: string, name: string) => {
        const fallback = await snapshot.getPromise(
          organisationGroupsState({ organisationId }),
        );
        return await updateGroup(organisationId, groupId, name)
          .then((updatedGroup) => {
            set(organisationGroupsState({ organisationId }), (cur) =>
              [...cur].map((c) => (c.id === groupId ? updatedGroup : c)),
            );
          })
          .catch((e) => {
            scream("Failed to update group", { e });
            set(organisationGroupsState({ organisationId }), fallback);
          });
      },
    [organisationId],
  );

  const remove = useRecoilCallback(
    ({ set, snapshot }) =>
      async (groupId: string) => {
        const fallback = await snapshot.getPromise(
          organisationGroupsState({ organisationId }),
        );
        return await removeGroup(organisationId, groupId)
          .then(() => {
            set(organisationGroupsState({ organisationId }), (cur) =>
              [...cur].filter((g) => g.id !== groupId),
            );
          })
          .catch((e) => {
            scream("Failed to remove group", { e });
            set(organisationGroupsState({ organisationId }), fallback);
          });
      },
    [organisationId],
  );

  return { add, remove, update };
};

export default useGroupsCrud;
