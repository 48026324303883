/// <reference types="vite-plugin-svgr/client" />
import ConfigurationIcon from "@icons/14/Configuration.svg?react";
import Button from "components/General/Button";
import { Label } from "components/General/Form";
import { Row } from "components/General/Layout";
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import {
  ProjectConfigModalTypeV2,
  analysisConfigurationUsageRefresh,
  branchSelectedConfigurationAtomFamily,
} from "state/configuration";
import { modalTypeOpenAtom } from "state/modal";
import { branchIdSelector_, projectIdSelector_ } from "state/pathParams";
import { getBranchSelectorFamily, useUpdateBranch } from "state/timeline";
import SelectAnalysisDropDown from "./SelectAnalysisDropDown";
import { InputTitle } from "components/General/GeneralSideModals.style";

export const SelectAnalysisConfiguration = () => {
  const projectId = useRecoilValue(projectIdSelector_);
  const branchId = useRecoilValue(branchIdSelector_);
  const setModalType = useSetRecoilState(modalTypeOpenAtom);
  const selectedConfig = useRecoilValue(
    branchSelectedConfigurationAtomFamily({ projectId, branchId }),
  );

  const resetAnalysisConfUsage = useRecoilCallback(
    ({ set }) =>
      async (analysisConfigurationId: string) => {
        if (projectId) {
          set(
            analysisConfigurationUsageRefresh({
              nodeId: projectId,
              analysisConfigurationId,
            }),
            (curr) => curr + 1,
          );
        }
      },
    [projectId],
  );
  const updateBranch = useUpdateBranch();

  const liveBranchMeta = useRecoilValue(
    getBranchSelectorFamily({
      projectId,
      branchId,
    }),
  );

  return (
    <Label>
      <InputTitle>Analysis configuration</InputTitle>
      <Row style={{ margin: 0 }}>
        <SelectAnalysisDropDown
          selectedConfig={selectedConfig}
          projectId={projectId}
          style={{
            flex: 1,
          }}
          onSelectItem={(id) => {
            if (!liveBranchMeta) return;

            liveBranchMeta.analysisConfigurationId &&
              resetAnalysisConfUsage(liveBranchMeta.analysisConfigurationId);
            resetAnalysisConfUsage(id);
            updateBranch({
              ...liveBranchMeta,
              analysisConfigurationId: id,
            });
          }}
        />
        <Button
          buttonType="secondary"
          icon={<ConfigurationIcon />}
          size="small"
          onClick={() => {
            setModalType({
              modalType: ProjectConfigModalTypeV2,
              metadata: {
                selectedMenuId: "analysis",
                selectedConfigId: selectedConfig?.id,
              },
            });
          }}
        />
      </Row>
    </Label>
  );
};
