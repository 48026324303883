import { ProjectFeature } from "../../types/feature";

export const getFeatureOfSelectionUndefinedIfTheyAreDifferent = <T>(
  selections: ProjectFeature[],
  propertyName: string,
  defaultValue: T,
): T => {
  const featureOfFirst = (selections[0].properties as any)[propertyName] as T;
  if (selections.length === 1) return featureOfFirst ?? defaultValue;
  return selections.every(
    (s) => (s.properties as any)[propertyName] === featureOfFirst,
  )
    ? featureOfFirst
    : defaultValue;
};
