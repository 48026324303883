import CloseableHint from "./CloseableHint";
import drawPolygon from "./drawPolygon.mp4";

export const drawPolygonHelpHintType = "drawPolygonHelpHintType";

const DrawPolygonHelp = ({
  closeCallback,
  gotItCallback,
}: {
  closeCallback: () => void;
  gotItCallback?: () => void;
}) => {
  return (
    <CloseableHint
      title={"Draw polygon"}
      closeCallback={closeCallback}
      gotItCallback={gotItCallback}
      videosrc={drawPolygon}
      timeToDissapearMS={10000}
    >
      {`Draw by left clicking on the map, Finish by pressing "Enter" or cancel with "Escape"`}
    </CloseableHint>
  );
};

export default DrawPolygonHelp;
