import { useState, useRef, useEffect, Suspense, ReactNode } from "react";
import { Loadable, useRecoilValue } from "recoil";
import { useTypedPath } from "state/pathParams";
import {
  customerProjectAtomFamily,
  projectBranchesAtomFamily,
} from "state/timeline";
import { SkeletonBlock, SkeletonText } from "../../Loading/Skeleton";
import BranchIcon from "@icons/24/Branch.svg";
import {
  ExtraContainer,
  Number,
  Name,
  UsageContainer,
  NoAccess,
  UsageContainerPlacement,
  NumberWrapper,
} from "./style";

export function UsageContainerInner2({
  loadable,
  children,
  text,
  icon,
  typeName,
  placement = UsageContainerPlacement.TOP,
  wrapperStyle,
  numberStyle,
}: {
  text?: string;
  icon?: React.ReactNode;
  typeName?: string;
  loadable: Loadable<any>;
  children: ReactNode;
  placement?: UsageContainerPlacement;
  wrapperStyle?: React.CSSProperties;
  numberStyle?: React.CSSProperties;
}) {
  const [isMouseOverParent, setIsMouseOverParent] = useState(false);
  const [isMouseOverChild, setIsMouseOverChild] = useState(false);

  const hideTimeout = useRef<NodeJS.Timeout>();

  const [showChild, setShowChild] = useState(false);

  useEffect(() => {
    if (!isMouseOverParent && !isMouseOverChild) {
      hideTimeout.current = setTimeout(() => {
        setShowChild(false);
      }, 100);
    } else {
      clearTimeout(hideTimeout.current);
      setShowChild(true);
    }
  }, [isMouseOverChild, isMouseOverParent, showChild]);

  if (loadable.state !== "hasValue") {
    return (
      <UsageContainer>
        <SkeletonBlock style={{ width: "100%", height: "2rem" }} />
      </UsageContainer>
    );
  }

  return (
    <UsageContainer
      style={wrapperStyle}
      onMouseEnter={() => setIsMouseOverParent(true)}
      onMouseLeave={() => setIsMouseOverParent(false)}
    >
      {icon ? icon : <p style={{ margin: 0 }}>{text ?? "Used:"} </p>}
      <Number style={numberStyle}>
        {loadable.contents.length} {typeName ?? "times"}
        {loadable.contents.length > 0 && showChild && (
          <ExtraContainer
            onMouseEnter={() => setIsMouseOverChild(true)}
            onMouseLeave={() => setIsMouseOverChild(false)}
            placement={placement}
          >
            <Suspense
              fallback={
                <SkeletonText style={{ width: "4rem", height: "2rem" }} />
              }
            >
              {children}
            </Suspense>
          </ExtraContainer>
        )}
      </Number>
    </UsageContainer>
  );
}

export function UsageContainerInner({
  loadable,
  children,
  text,
  typeName,
}: {
  text?: string;
  typeName?: string;
  loadable: Loadable<any>;
  children: ReactNode;
}) {
  const [isMouseOverParent, setIsMouseOverParent] = useState(false);
  const [isMouseOverChild, setIsMouseOverChild] = useState(false);

  const hideTimeout = useRef<NodeJS.Timeout>();

  const [showChild, setShowChild] = useState(false);

  useEffect(() => {
    if (!isMouseOverParent && !isMouseOverChild) {
      hideTimeout.current = setTimeout(() => {
        setShowChild(false);
      }, 100);
    } else {
      clearTimeout(hideTimeout.current);
      setShowChild(true);
    }
  }, [isMouseOverChild, isMouseOverParent, showChild]);

  if (loadable.state !== "hasValue") {
    return (
      <UsageContainer>
        <SkeletonBlock style={{ width: "100%", height: "2rem" }} />
      </UsageContainer>
    );
  }

  return (
    <UsageContainer>
      <p style={{ margin: 0 }}>{text ?? "Used:"} </p>
      <NumberWrapper>
        <BranchIcon />
        <Number
          onMouseEnter={() => setIsMouseOverParent(true)}
          onMouseLeave={() => setIsMouseOverParent(false)}
        >
          {loadable.contents.length} {typeName ?? "times"}
          {loadable.contents.length > 0 && showChild && (
            <ExtraContainer
              onMouseEnter={() => setIsMouseOverChild(true)}
              onMouseLeave={() => setIsMouseOverChild(false)}
            >
              <Suspense
                fallback={
                  <SkeletonText style={{ width: "4rem", height: "2rem" }} />
                }
              >
                {children}
              </Suspense>
            </ExtraContainer>
          )}
        </Number>
      </NumberWrapper>
    </UsageContainer>
  );
}

export function ProjectBranchInfo({
  usage,
}: {
  usage: { projectId: string; branchId: string };
}) {
  const { organisationId } = useTypedPath("organisationId");
  const project = useRecoilValue(
    customerProjectAtomFamily({
      nodeId: usage.projectId,
    }),
  );

  const branchMetaObjects = useRecoilValue(
    projectBranchesAtomFamily({
      nodeId: usage.projectId,
    }),
  );

  if (!project && branchMetaObjects.length === 0) {
    return <NoAccess>No access</NoAccess>;
  }

  return (
    <Name
      key={usage.branchId}
      to={`/design/project/${organisationId}/${usage.projectId}/${usage.branchId}`}
      target={"_blank"}
    >
      {branchMetaObjects.find((b) => b.id === usage.branchId)?.title} (
      {project?.name})
    </Name>
  );
}
