import { atomFamily, selectorFamily } from "recoil";
import {
  CableType,
  getCableTypes,
  _CableType,
} from "../services/cableTypeService";
import { parseFilter } from "utils/zod";
import { z } from "zod";
import { uuidRegex } from "@constants/regex";
import { _CableLevel } from "types/cables";
import { getCableResourcesOnNode } from "services/libraryService";
import { NodeCableAccess } from "components/Organisation/Library/types";

export const cableTypeAtomFamily = atomFamily<
  CableType[],
  {
    projectId: string | undefined;
    version?: number;
  }
>({
  key: "cableTypeAtomFamily",
  default: selectorFamily<
    CableType[],
    {
      projectId: string | undefined;
      version?: number;
    }
  >({
    key: "cableTypeSelectorFamily",
    get:
      ({ projectId, version }) =>
      async () => {
        if (!projectId) return [];
        // If user goes to link with legacy type projectId/branchId and we try to
        // fetch cable types before the user has been redirected
        if (!uuidRegex.test(projectId)) return [];
        const response = await getCableTypes(projectId, version);
        if (!response.ok) {
          throw new Error("Fetching configuration failed");
        }
        const j = await response.json();
        return parseFilter(_CableType, z.unknown().array().parse(j));
      },
  }),
});

export const cableResourceWithAccessOnNodeState = atomFamily<
  NodeCableAccess[],
  { nodeId: string | undefined }
>({
  key: "cableResourceWithAccessOnNodeState",
  default: selectorFamily({
    key: "cableResourceWithAccessOnNodeState.default",
    get:
      ({ nodeId }) =>
      async () => {
        if (!nodeId) return [];
        return await getCableResourcesOnNode(nodeId).catch(() => []);
      },
  }),
});
