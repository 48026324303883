import React, { useMemo } from "react";
import { useRecoilValue } from "recoil";
import DropdownButton from "components/General/Dropdown/DropdownButton";
import { DropDownItem } from "components/General/Dropdown/DropdownItems";
import ProjectIcon from "@icons/24/ProjectGlobe.svg?react";
import WindIcon from "@icons/24/Wind.svg";
import styled, { CSSProperties } from "styled-components";
import { IconREMSize, typography } from "styles/typography";
import { windSourceConfigurationsAtomFamily } from "state/windSourceConfiguration";
import { WindSourceConfiguration } from "services/windSourceConfigurationService";
import { colors } from "styles/colors";

const DropDownTextWrapper = styled.div`
  ${typography.body}
  color: ${colors.textPrimary};
  margin: 0;
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: 17rem;
  text-align: left;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const DropDownText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SelectWindDropDownCustom = ({
  selectedConfig,
  onSelectItem,
  projectId,
  style,
  className,
}: {
  selectedConfig?: WindSourceConfiguration;
  projectId: string;
  onSelectItem: (val: string) => void;
  style?: CSSProperties;
  className?: string;
}) => {
  const windConfigs = useRecoilValue(
    windSourceConfigurationsAtomFamily({ projectId }),
  );

  const items = useMemo<DropDownItem[]>(() => {
    return [
      ...windConfigs.map((c) => ({
        value: c.id,
        name: c.name ?? "Untitled",
        icon: (
          <IconREMSize width={1.4} height={1.4}>
            <ProjectIcon />
          </IconREMSize>
        ),
      })),
    ];
  }, [windConfigs]);

  return (
    <DropdownButton
      className={className}
      items={items}
      renderText={(text) => (
        <DropDownTextWrapper>
          <IconREMSize width={1.4} height={1.4}>
            <WindIcon />
          </IconREMSize>
          <DropDownText>{text}</DropDownText>
        </DropDownTextWrapper>
      )}
      size={"small"}
      listContainerStyle={style}
      style={style}
      onSelectItem={onSelectItem}
      buttonText={
        windConfigs.find((c) => c.id === selectedConfig?.id)?.name ??
        "-- select an option --"
      }
      selectedItemValue={selectedConfig?.id}
    />
  );
};

export default SelectWindDropDownCustom;
