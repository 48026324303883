import { useRecoilValue } from "recoil";
import {
  canvasLayerBathymetryFeaturesSelector,
  canvasLayerImageFeaturesSelector,
} from "../state/projectLayers";
import { contourStepSizeAtom, mapRefAtom } from "../state/map";
import { useEffect, useMemo } from "react";
import { getGeorefImageSelectorFamily } from "../state/georef";
import { projectIdSelector, useTypedPath } from "../state/pathParams";
import {
  geotifBathymetryLayerId,
  geotifImageLayerId,
  getBeforeLayer,
} from "../components/Mapbox/utils";
import { GeotiffImage } from "../components/MapFeatures/Geotiff";
import { BathymetryFeature, GeotiffFeature } from "../types/feature";
import { fastMax, fastMin } from "utils/utils";
import { scream } from "utils/sentry";
import { CustomLayerInterface } from "mapbox-gl";
import { BATHYMETRY_COLORS } from "./depthContours";
import {
  opacityPropertyName,
  DEFAULT_CANVAS_GEOTIFF_OPACITY,
} from "@constants/canvas";

const CanvasLayer = () => {
  const canvasLayerImageFeatures = useRecoilValue(
    canvasLayerImageFeaturesSelector,
  );
  const canvasLayerBathymetryFeatures = useRecoilValue(
    canvasLayerBathymetryFeaturesSelector,
  );

  return (
    <>
      {canvasLayerImageFeatures.map((canvasLayerImageFeature) => (
        <CanvasImageLayer
          key={canvasLayerImageFeature.id}
          feature={canvasLayerImageFeature}
        />
      ))}
      {canvasLayerBathymetryFeatures.map((canvasLayerBathymetryFeature) => (
        <CanvasBathymetryLayer
          key={canvasLayerBathymetryFeature.id}
          feature={canvasLayerBathymetryFeature}
        />
      ))}
    </>
  );
};

class Layer implements CustomLayerInterface {
  id: string;
  type: "custom";
  map: any;
  renderingMode?: "3d";
  depthSourceId: any;
  depthSourceCache: any;
  vertexArray: Int16Array | undefined;
  vertexBuffer: any;
  colors: number[][];
  indexArray: Uint16Array | undefined;
  indexBuffer: any;
  program: any;
  aPos: any;
  contourDist: number;

  constructor(id: string, depthSourceId: string) {
    this.id = id;
    this.type = "custom";
    this.colors = BATHYMETRY_COLORS.default;
    this.depthSourceId = depthSourceId;
    this.contourDist = 25.0;
  }

  updateContourDist(newContourDist: number) {
    this.contourDist = newContourDist;
  }

  onAdd(map: mapboxgl.Map, gl: WebGLRenderingContext) {
    this.map = map;
    const style = (map as any).style;
    this.depthSourceCache = style._otherSourceCaches[this.depthSourceId];
    this.depthSourceCache.pause();
    this.prepareShaders(gl);
    this.prepareBuffers(gl);
  }

  update() {
    const transform = this.map.transform.clone();
    const pitchOffset =
      transform.cameraToCenterDistance * Math.sin(transform._pitch);
    transform.height = transform.height + pitchOffset;

    this.depthSourceCache._paused = false;
    this.depthSourceCache.used = true;
    this.depthSourceCache.update(transform);
    this.depthSourceCache.pause();
  }

  prepareBuffers(gl: WebGLRenderingContext) {
    const n = 64;

    this.vertexArray = new Int16Array(n * n * 2);
    for (let i = 0; i < n; i++) {
      for (let j = 0; j < n; j++) {
        const vertex = [j * (8192 / (n - 1)), i * (8192 / (n - 1))];
        const offset = (i * n + j) * 2;
        this.vertexArray.set(vertex, offset);
      }
    }

    this.vertexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, this.vertexArray.buffer, gl.STATIC_DRAW);

    this.indexArray = new Uint16Array((n - 1) * (n - 1) * 6);
    let offset = 0;
    for (let i = 0; i < n - 1; i++) {
      for (let j = 0; j < n - 1; j++) {
        const index = i * n + j;
        const quad = [
          index,
          index + 1,
          index + n,
          index + n,
          index + 1,
          index + n + 1,
        ];
        this.indexArray.set(quad, offset);
        offset += 6;
      }
    }

    this.indexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.indexBuffer);
    gl.bufferData(
      gl.ELEMENT_ARRAY_BUFFER,
      this.indexArray.buffer,
      gl.STATIC_DRAW,
    );
  }

  prepareShaders(gl: WebGLRenderingContext) {
    const vertexSource = `#version 300 es
          precision highp float;
          uniform mat4 u_matrix;

          in vec2 a_pos;

          out vec2 v_texCoord;

          void main() {
            v_texCoord = a_pos / 8192.0;
            gl_Position = u_matrix * vec4(a_pos, 0.0, 1.0);
          }
          `;

    const fragmentSource = `#version 300 es
        precision highp float;
        uniform sampler2D u_depth_raster;
        uniform float u_contourDist;

        in vec2 a_texCoord;
        in vec2 v_texCoord;

        layout(location=0) out vec4 outColor;

        vec3 depthToColor(float depth) {
          vec3 lightBlue = vec3(0.64, 0.78, 0.92);
          vec3 medlightBlue = vec3(0.45, 0.55, 0.70);
          vec3 mediumBlue = vec3(0.38, 0.47, 0.60);
          vec3 darkBlue = vec3(0.31, 0.36, 0.40);
          vec3 darkestBlue = vec3(0.12, 0.17, 0.21);
          float l1 = 50.0;
          float l2 = 300.0;
          float l3 = 500.0;
          float l4 = 700.0;
          if (depth < l1) {
            float f = depth  / l1;
            return mix(lightBlue, medlightBlue, f);
          } else if (depth < l2) {
            float f = clamp((depth - l1) / (l2 - l1), 0., 1.);
            return mix(medlightBlue, mediumBlue, f);
          } else if (depth < l3) {
            float f = clamp((depth - l2) / (l3 - l2), 0., 1.);
            return mix(mediumBlue, darkBlue, f);
          } else {
            float f = clamp((depth - l3) / (l4 - l3), 0., 1.);
            return mix(darkBlue, darkestBlue, f);
          }
        }

        float depthToContour(float depth, float Step) {
          float frac = fract(depth / Step);
          float grace = fwidth(depth / Step);
          return 1.0 - step(grace, frac);
        }

        void main() {
          float STEP = u_contourDist;
          vec4 texel = texture(u_depth_raster, v_texCoord) * 255.;
          float depth = -(-32768. + (texel.r * 256. + texel.g + texel.b / 256.));

          float contour = depthToContour(depth, STEP);

          float depthstep = floor(depth / STEP);
          vec3 areaColor = depthToColor(depthstep * STEP);

          float sign = float(depth < 200.0) * 2.0 - 1.0; // +/- 1.0, positive is shallow.
          vec3 withIsoLines = areaColor + sign * contour * areaColor * 0.2;
          
          float alpha = texel.a / 255. * 0.9;
          outColor = vec4(withIsoLines, alpha);
    }`;

    var vertexShader = gl.createShader(gl.VERTEX_SHADER);
    if (!vertexShader)
      throw scream("Failed to create vertex shader in costLayer");
    gl.shaderSource(vertexShader, vertexSource);
    gl.compileShader(vertexShader);
    var fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
    if (!fragmentShader)
      throw scream("Failed to create fragmentShader shader in costLayer");
    gl.shaderSource(fragmentShader, fragmentSource);
    gl.compileShader(fragmentShader);

    this.program = gl.createProgram();
    gl.attachShader(this.program, vertexShader);
    gl.attachShader(this.program, fragmentShader);
    gl.linkProgram(this.program);

    this.aPos = gl.getAttribLocation(this.program, "a_pos");
  }

  render(gl: WebGLRenderingContext, _matrix: number[]) {
    if (!this.indexArray) return;
    gl.useProgram(this.program);

    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

    // Bind vertex buffer
    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
    gl.enableVertexAttribArray(this.aPos);
    gl.vertexAttribPointer(this.aPos, 2, gl.SHORT, false, 0, 0);

    // Bind index buffer
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.indexBuffer);

    // Bind colors

    gl.uniform1f(
      gl.getUniformLocation(this.program, "u_number_of_colors"),
      this.colors.length,
    );

    gl.uniform1f(
      gl.getUniformLocation(this.program, "u_contourDist"),
      this.contourDist,
    );

    this.update();

    let coords = this.depthSourceCache.getVisibleCoordinates().reverse();
    for (const coord of coords) {
      const depthTile = this.depthSourceCache.getTile(coord);

      // Bind depth raster texture to unit 0
      gl.activeTexture(gl.TEXTURE0);
      gl.bindTexture(gl.TEXTURE_2D, depthTile.texture.texture);
      gl.uniform1i(gl.getUniformLocation(this.program, "u_depth_raster"), 0);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);

      // Bind matrix
      gl.uniformMatrix4fv(
        gl.getUniformLocation(this.program, "u_matrix"),
        false,
        coord.projMatrix,
      );

      // Draw
      const vertexCount = this.indexArray.length;
      const type = gl.UNSIGNED_SHORT;
      const offset = 0;
      gl.drawElements(gl.TRIANGLES, vertexCount, type, offset);
    }
  }
}

const CanvasBathymetryLayer = ({ feature }: { feature: BathymetryFeature }) => {
  const map = useRecoilValue(mapRefAtom);
  const projectId = useRecoilValue(projectIdSelector);
  const contourStepSize = useRecoilValue(contourStepSizeAtom);

  const canvasBathymetryLayerSource = useMemo(
    () => `canvas-layer-bathymetry-source-${feature.id}`,
    [feature.id],
  );
  const layerId = useMemo(
    () => `${geotifBathymetryLayerId}-${feature.id}`,
    [feature],
  );

  const bounds = useMemo(() => {
    const xs = feature.geometry.coordinates[0].map((c) => c[0]);
    const ys = feature.geometry.coordinates[0].map((c) => c[1]);
    return [fastMin(xs), fastMin(ys), fastMax(xs), fastMax(ys)];
  }, [feature]);

  const layer = useMemo(
    () => new Layer(layerId, canvasBathymetryLayerSource),
    [layerId, canvasBathymetryLayerSource],
  );

  useEffect(() => {
    if (!map) return;
    layer.updateContourDist(contourStepSize);
    map.triggerRepaint();
  }, [map, layer, contourStepSize]);

  const { filename } = feature.properties;
  useEffect(() => {
    if (!map || map.getSource(canvasBathymetryLayerSource) || !projectId)
      return;

    map.addSource(canvasBathymetryLayerSource, {
      type: "raster",
      tiles: [
        `/api/bathymetry/custom/${projectId}/${filename}/{x}/{y}/{z}?include_land=true`,
      ],
      bounds,
      maxzoom: 13,
    });

    map.addLayer(layer, getBeforeLayer(map, geotifBathymetryLayerId));

    return () => {
      map.removeLayer(layerId);
      map.removeSource(canvasBathymetryLayerSource);
    };
  }, [
    bounds,
    canvasBathymetryLayerSource,
    filename,
    layerId,
    map,
    projectId,
    layer,
  ]);

  return null;
};

const CanvasImageLayer = ({
  feature,
}: {
  feature: BathymetryFeature | GeotiffFeature;
}) => {
  const { projectId, branchId } = useTypedPath("projectId", "branchId");
  const geotiff = useRecoilValue(
    getGeorefImageSelectorFamily({
      projectId,
      branchId,
      filename: feature.properties.filename,
    }),
  );
  const map = useRecoilValue(mapRefAtom);

  const layerId = useMemo(
    () => `${geotifImageLayerId}-${feature.id}`,
    [feature],
  );

  if (!map || !geotiff) return null;
  const opacity =
    feature.properties[opacityPropertyName] ?? DEFAULT_CANVAS_GEOTIFF_OPACITY;
  return (
    <GeotiffImage
      layerId={layerId}
      map={map}
      geotiff={geotiff}
      opacity={opacity}
    />
  );
};

export default CanvasLayer;
