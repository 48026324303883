import { fetchSchemaWithToken } from "./utils";
import { z } from "zod";
import { EventActions } from "../types/timeline";

export const _TimelineAuditProtoEvent = z.object({
  id: z.string(),
  branchId: z.string().optional(),
  version: z.number(),
  action: z.nativeEnum(EventActions),
  service: z.string(),
  title: z.string().optional(),
  description: z.string().optional(),
  meta: z.record(z.string().or(z.number()).or(z.boolean())).optional(),
  author: z.string().optional(),
});
export type TimelineAuditProtoEvent = z.infer<typeof _TimelineAuditProtoEvent>;
const _TimelineNodeAuditEvent = _TimelineAuditProtoEvent.extend({
  nodeId: z.string(),
});
type TimelineNodeAuditEvent = z.infer<typeof _TimelineNodeAuditEvent>;

export const getNodeTimelineAuditEvents = (
  nodeId: string,
  branchId: string,
): Promise<TimelineNodeAuditEvent[]> =>
  fetchSchemaWithToken(
    _TimelineNodeAuditEvent.array(),
    `/api/timeline/audit/${nodeId}/${branchId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
