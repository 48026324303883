import {
  DEFAULT_IN_FOCUS_OPACITY,
  DEFAULT_NOT_IN_FOCUS_OPACITY,
  DEFAULT_SELECTED_OPACITY,
  displayLabelPropertyName,
  lockedPropertyName,
} from "@constants/canvas";
import { FillPaint, LinePaint, SymbolLayer } from "mapbox-gl";
import { useEffect } from "react";
import { colors } from "styles/colors";
import { ParkFeature } from "types/feature";
import { safeRemoveLayer } from "utils/map";
import { addLayer, removeCodepointsFromFeatures } from "./utils";
import {
  parkSourceId,
  parkLayerSymbolId,
  parkLayerOutlineId,
  parkLayerFillId,
} from "./constants";

const parkFillPaint: FillPaint = {
  "fill-color": colors.park2,
  "fill-opacity": [
    "case",
    [
      "all",
      [
        "boolean",
        ["feature-state", "hover"],
        ["feature-state", "selected"],
        false,
      ],
      ["!=", ["get", lockedPropertyName], true],
    ],
    0.2,
    0.1,
  ],
};

const parkLinePaint: LinePaint = {
  "line-color": [
    "case",
    ["==", ["get", lockedPropertyName], true],
    colors.lockedFeatureOutline,
    ["!=", ["feature-state", "borderColor"], null],
    ["feature-state", "borderColor"],
    ["boolean", ["feature-state", "selected"], false],
    "#ffffff",
    colors.park,
  ],
  "line-width": [
    "case",
    ["==", ["get", lockedPropertyName], true],
    3,
    ["!=", ["feature-state", "borderColor"], null],
    3,
    [
      "boolean",
      ["feature-state", "selected"],
      ["feature-state", "hover"],
      false,
    ],
    3,
    1,
  ],
  "line-opacity": [
    "case",
    [
      "boolean",
      ["feature-state", "selected"],
      ["feature-state", "hover"],
      false,
    ],
    DEFAULT_SELECTED_OPACITY,
    ["boolean", ["feature-state", "inFocus"], false],
    DEFAULT_IN_FOCUS_OPACITY,
    DEFAULT_NOT_IN_FOCUS_OPACITY,
  ],
};

export const parkNameSymbolLayer: Omit<SymbolLayer, "id" | "source"> = {
  type: "symbol",
  minzoom: 5,
  layout: {
    "symbol-placement": "point",
    "text-field": "{name}",
    "text-size": 12,
    "symbol-spacing": 300,
    "text-keep-upright": true,
  },
  paint: {
    "text-opacity": 0.8,
    "text-halo-blur": 1,
    "text-halo-color": "white",
    "text-halo-width": 2,
  },
  filter: ["boolean", ["get", displayLabelPropertyName], true],
};

export const RenderParks = ({
  parks,
  map,
}: {
  parks: ParkFeature[];
  map: mapboxgl.Map;
}) => {
  useEffect(() => {
    map.addSource(parkSourceId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    return () => {
      safeRemoveLayer(map, parkLayerSymbolId);
      safeRemoveLayer(map, parkLayerOutlineId);
      safeRemoveLayer(map, parkLayerFillId);
      map.removeSource(parkSourceId);
    };
  }, [map]);

  useEffect(() => {
    addLayer(map, {
      id: parkLayerFillId,
      type: "fill",
      source: parkSourceId,
      paint: parkFillPaint,
    });
    addLayer(map, {
      id: parkLayerOutlineId,
      type: "line",
      source: parkSourceId,
      paint: parkLinePaint,
    });
    addLayer(map, {
      ...parkNameSymbolLayer,
      id: parkLayerSymbolId,
      source: parkSourceId,
    });
  }, [map]);

  useEffect(() => {
    const source = map.getSource(parkSourceId);
    if (source?.type !== "geojson") return;
    source.setData({
      type: "FeatureCollection",
      features: removeCodepointsFromFeatures(parks),
    });
  }, [map, parks]);

  return null;
};
