/// <reference types="vite-plugin-svgr/client" />
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";
import CloseIcon from "@icons/24/Close.svg?react";
import { IconBtn } from "components/General/Icons";
import { SubtitleWithLine } from "components/General/GeneralSideModals.style";
import { ColoredGrid } from "components/General/Form";
import Button from "components/General/Button";
import ProjectIcon from "@icons/24/ProjectGlobe.svg?react";
import LibraryIcon from "@icons/24/Library.svg?react";
import { projectIdSelector } from "state/pathParams";
import { PreviewItem, showComponentsPreviewAtom } from "./state";
import {
  Divider,
  GridKey,
  GridValue,
  HeaderRow,
  Modal,
  Subtitle,
  Title,
  Wrapper,
} from "./style";
import {
  libraryCableOnNodeTypesState,
  projectCableTypesState,
} from "components/Cabling/Generate/state";
import { CABLE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useCableSettings";
import { FeatureSettingsShortcut } from "components/ConfigurationModal/Cost/Capex/FeatureSettingsShortcut";
import { useClickOutside } from "hooks/useClickOutside";

const CableComponentsPreview = () => {
  const projectId = useRecoilValue(projectIdSelector);
  const [, setShowComponentsPreview] = useRecoilState(
    showComponentsPreviewAtom,
  );

  const onClose = useCallback(
    () => setShowComponentsPreview({ show: false }),
    [setShowComponentsPreview],
  );

  const containerRef = useRef<HTMLDivElement>(null);
  useClickOutside(containerRef, onClose, () => false, {
    ignoreDragClicks: true,
  });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  const projectCables = useRecoilValue(projectCableTypesState);

  const projectCablesItems: PreviewItem[] = useMemo(
    () =>
      projectCables.map((c) => ({
        id: c.id,
        name: c.name,
        cost: c.cost,
        costUnit: c.costUnit,
        level: "project",
      })),
    [projectCables],
  );

  const libraryCables = useRecoilValueLoadable(
    libraryCableOnNodeTypesState({ nodeId: projectId }),
  );

  const availableLibraryCables = useMemo(() => {
    return libraryCables.valueMaybe() ?? [];
  }, [libraryCables]);

  const libraryCablesItems: PreviewItem[] = useMemo(
    () =>
      availableLibraryCables.map((c) => ({
        id: c.id,
        name: c.name,
        cost: c.cost,
        costUnit: c.costUnit,
        level: "library",
      })),
    [availableLibraryCables],
  );

  const allCableItems = projectCablesItems.concat(libraryCablesItems);

  return (
    <Wrapper>
      <Modal ref={containerRef}>
        <HeaderRow>
          <div>
            <Title>Array cable costs</Title>
            <Subtitle>Specified in Components</Subtitle>
          </div>
          <IconBtn size="1.5rem" onClick={onClose}>
            <CloseIcon />
          </IconBtn>
        </HeaderRow>

        <SubtitleWithLine
          textIcon={<LibraryIcon />}
          text={"Library cables"}
        ></SubtitleWithLine>
        <ColoredGrid>
          {allCableItems
            .filter((c) => c.level === "library")
            .map((c) => (
              <React.Fragment key={c.id}>
                <GridKey>{c.name}</GridKey>
                <GridValue>
                  <strong>{c.cost}</strong>
                  {c.costUnit}
                  <FeatureSettingsShortcut
                    menuId={CABLE_MENU_ID}
                    itemId={c.id}
                  />
                </GridValue>
              </React.Fragment>
            ))}
        </ColoredGrid>

        <SubtitleWithLine
          textIcon={<ProjectIcon />}
          text={"Project cables"}
        ></SubtitleWithLine>
        <ColoredGrid>
          {allCableItems
            .filter((c) => c.level === "project")
            .map((c) => (
              <React.Fragment key={c.id}>
                <div>{c.name}</div>
                <GridValue>
                  <strong>{c.cost}</strong>
                  {c.costUnit}
                  <FeatureSettingsShortcut
                    menuId={CABLE_MENU_ID}
                    itemId={c.id}
                  />
                </GridValue>
              </React.Fragment>
            ))}
        </ColoredGrid>
        <Divider />
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            paddingTop: "1rem",
          }}
        >
          <Button buttonType="secondary" text="Close" onClick={onClose} />
        </div>
      </Modal>
    </Wrapper>
  );
};

export default CableComponentsPreview;
