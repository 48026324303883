/// <reference types="vite-plugin-svgr/client" />
import React, { useState } from "react";
import styled from "styled-components";
import { IconREMSize, typography } from "../../../../styles/typography";
import LayersIcon from "@icons/24/Layers.svg?react";
import Elements from "@icons/24/SideBarIcons/Elements.svg?react";
import WindIcon from "@icons/24/Wind.svg?react";
import { spaceLarge, spaceMedium } from "../../../../styles/space";
import { colors } from "../../../../styles/colors";
import Button from "../../../General/Button";
import { TextNoMargin } from "../shared";
import {
  DataLayerPreview,
  ProjectFeaturePreview,
  WindDataPreview,
} from "./previews";
import { UploadFileType } from "./types";

const FileTypeSelectorStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FileTypeOptionsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: 28rem;
  flex-shrink: 0;
`;

export const FileTypeOption = styled.div<{ selected: boolean }>`
  padding: ${spaceMedium};
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.inputOutline};
  transition: border 0.1s linear;
  border-radius: 4px;
  cursor: pointer;
  gap: ${spaceMedium};

  ${({ selected }) =>
    selected &&
    `
    border-color: ${colors.primary};
    box-shadow: 0px 6px 12px 0px rgba(51, 51, 51, 0.08);
  `}
  :hover {
    border-color: ${colors.primary};
  }
`;

export const FileTypeHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${spaceMedium};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: ${spaceLarge};
`;

const FileTypeOptionsList = ({
  selectedFileType,
  setSelectedFileType,
}: {
  selectedFileType: UploadFileType;
  setSelectedFileType(newFileType: UploadFileType): void;
}) => {
  return (
    <FileTypeOptionsListWrapper>
      <FileTypeOption
        selected={selectedFileType === UploadFileType.PROJECT_FEATURE}
        onClick={() => setSelectedFileType(UploadFileType.PROJECT_FEATURE)}
      >
        <FileTypeHeader>
          <IconREMSize height={1.5} width={1.5}>
            <Elements />
          </IconREMSize>
          <h4 style={{ margin: 0 }}>Elements</h4>
        </FileTypeHeader>
        <TextNoMargin>Editable elements in your project</TextNoMargin>
      </FileTypeOption>
      <FileTypeOption
        selected={selectedFileType === UploadFileType.DATA_LAYER}
        onClick={() => setSelectedFileType(UploadFileType.DATA_LAYER)}
      >
        <FileTypeHeader>
          <IconREMSize height={1.5} width={1.5}>
            <LayersIcon />
          </IconREMSize>
          <h4 style={{ margin: 0 }}>Data layers</h4>
        </FileTypeHeader>
        <TextNoMargin>Read-only geodata (large data)</TextNoMargin>
      </FileTypeOption>
      <FileTypeOption
        selected={selectedFileType === UploadFileType.WIND_DATA}
        onClick={() => setSelectedFileType(UploadFileType.WIND_DATA)}
      >
        <FileTypeHeader>
          <IconREMSize height={1.5} width={1.5}>
            <WindIcon />
          </IconREMSize>
          <h4 style={{ margin: 0 }}>Wind data</h4>
        </FileTypeHeader>
        <TextNoMargin>For statistics and configuration</TextNoMargin>
      </FileTypeOption>
    </FileTypeOptionsListWrapper>
  );
};

const FileTypePreview = ({
  selectedFileType,
}: {
  selectedFileType: UploadFileType;
}) => {
  if (selectedFileType === UploadFileType.PROJECT_FEATURE) {
    return <ProjectFeaturePreview />;
  }

  if (selectedFileType === UploadFileType.DATA_LAYER) {
    return <DataLayerPreview />;
  }

  if (selectedFileType === UploadFileType.WIND_DATA) {
    return <WindDataPreview />;
  }

  return null;
};

const FileTypeSelectionStep = ({
  defaultSelected,
  onNextClick,
  onClose,
}: {
  defaultSelected?: UploadFileType;
  onNextClick(fileType: UploadFileType): void;
  onClose(): void;
}) => {
  const [selectedFileType, setSelectedFileType] = useState<UploadFileType>(
    defaultSelected ?? UploadFileType.PROJECT_FEATURE,
  );

  return (
    <FileTypeSelectorStepWrapper>
      <p style={{ ...typography.semiBold, margin: "2rem 0" }}>
        What do you want to upload?
      </p>

      <div
        style={{
          display: "grid",
          gap: spaceLarge,
          gridTemplateColumns: "auto 1fr",
        }}
      >
        <FileTypeOptionsList
          selectedFileType={selectedFileType}
          setSelectedFileType={setSelectedFileType}
        />
        <FileTypePreview selectedFileType={selectedFileType} />
      </div>
      <ButtonWrapper>
        <Button text="Close" buttonType="text" onClick={onClose} />
        <Button
          text="Next"
          buttonType="primary"
          onClick={() => onNextClick(selectedFileType)}
        />
      </ButtonWrapper>
    </FileTypeSelectorStepWrapper>
  );
};

export default FileTypeSelectionStep;
