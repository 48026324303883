/// <reference types="vite-plugin-svgr/client" />
import styled from "styled-components";
import { Suspense, useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { TurbineFeature } from "../../types/feature";
import ArrowDownIcon from "@icons/24/ArrowDown.svg?react";
import ArrowRightIcon from "@icons/24/ArrowRight.svg?react";
import ProjectIcon from "@icons/24/ProjectGlobe.svg?react";
import LibraryIcon from "@icons/24/Library.svg?react";
import { allSimpleTurbineTypesWithLevelSelector } from "../../state/turbines";
import Tooltip from "../General/Tooltip";
import { isTurbine } from "../../utils/predicates";
import { useProjectElementsCrud } from "../ProjectElements/useProjectElementsCrud";
import { trackCanvasOption } from "./MenuTracking";
import { useTypedPath } from "../../state/pathParams";
import { Divider } from "components/General/Icons";
import { ProjectFeature } from "../../types/feature";
import { MenuItem } from "../General/Menu";
import { MenuButton } from "../General/MenuButton";
import {
  NamedTooltipContainer,
  NamedTooltipWrapper,
} from "./CanvasSelectOption.style";

const TypeSelectorWrapper = styled.div`
  position: relative;
  font-size: 1.6rem;
  font-weight: 500;
`;

const TypeSelector = ({
  selectedTurbines,
  editable,
}: {
  selectedTurbines: TurbineFeature[];
  editable: boolean;
}) => {
  const { projectId, branchId } = useTypedPath("projectId", "branchId");
  const { update: updateFeatures } = useProjectElementsCrud();
  const allTurbineTypes = useRecoilValue(
    allSimpleTurbineTypesWithLevelSelector,
  ).filter((t) => !t.turbine.archived);

  const currentTurbineTypes = [
    ...new Set(
      selectedTurbines.map((t) => t.properties.turbineTypeId),
    ).values(),
  ];

  const onSelectItem = useCallback(
    (val: string) => {
      trackCanvasOption("change-turbine-type", {
        projectId,
        branchId,
      });
      const updatedFeatures = selectedTurbines.map((t) => ({
        ...t,
        properties: { ...t.properties, turbineTypeId: val },
      }));

      updateFeatures({ update: updatedFeatures });
    },
    [branchId, projectId, selectedTurbines, updateFeatures],
  );

  const items = useMemo(
    () =>
      allTurbineTypes.map((t) => ({
        value: t.turbine.id,
        name: t.turbine.name,
        icon: ["project"].includes(t.level) ? (
          <Tooltip text={"Project turbine"}>
            <ProjectIcon />
          </Tooltip>
        ) : (
          <Tooltip text={"Library turbine"}>
            <LibraryIcon />
          </Tooltip>
        ),
      })),
    [allTurbineTypes],
  );
  return (
    <TypeSelectorWrapper>
      <MenuButton
        disabled={!editable}
        icon={<ArrowRightIcon />}
        iconOpen={<ArrowDownIcon />}
        buttonStyle={{
          border: "none",
          flexDirection: "row-reverse",
          height: "fit-content",
          justifyContent: "space-between",
          padding: 0,
        }}
        buttonType="dropdown"
        buttonText={
          currentTurbineTypes.length === 1
            ? allTurbineTypes.find(
                (t) => t.turbine.id === currentTurbineTypes[0],
              )?.turbine.name ?? "..."
            : "..."
        }
      >
        {items.map((item) => (
          <MenuItem
            key={item.value}
            name={item.name}
            onClick={() => onSelectItem(item.value)}
            icon={item.icon}
          />
        ))}
      </MenuButton>
    </TypeSelectorWrapper>
  );
};

export default function TurbineTypeSelector({
  editable,
  selectedProjectFeatures,
}: {
  editable: boolean;
  selectedProjectFeatures: ProjectFeature[];
}) {
  const selectedTurbineFeatures = useMemo(
    () => selectedProjectFeatures.filter(isTurbine),
    [selectedProjectFeatures],
  );

  const onlyTurbinesSelected =
    selectedTurbineFeatures.length === selectedProjectFeatures.length;

  if (selectedTurbineFeatures.length === 0 || !onlyTurbinesSelected)
    return null;

  return (
    <Suspense fallback={null}>
      <Tooltip position="top" text="Change type" readonlyAware>
        <NamedTooltipContainer>
          <NamedTooltipWrapper>Turbine type</NamedTooltipWrapper>
          <TypeSelector
            selectedTurbines={selectedTurbineFeatures}
            editable={editable}
          />
        </NamedTooltipContainer>
      </Tooltip>
      <Divider />
    </Suspense>
  );
}
