import styled from "styled-components";
import { StandardBox } from "../../../styles/boxes/Boxes";
import { colors } from "../../../styles/colors";

export const AbsoluteContainer = styled(StandardBox)`
  position: absolute;
  bottom: 10rem;
  left: 0;
  right: 0;
  margin: auto;
  width: fit-content;
  border-radius: 0.4rem;
  background: ${colors.background};
  display: flex;
  z-index: 1;

  /* Target the grandchild divs of the first direct child div */
  > :nth-child(2) > div > div {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  /* Target the grandchild divs of the last direct child div */
  > :last-child > div > div {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

export const HeaderBox = styled.div`
  position: absolute;
  top: -1.7rem;
  left: 0;
  right: 0;
  margin: auto;
  background: ${colors.background};
  padding: 0.2rem 0.6rem;
  display: flex;
  align-items: center;
  border-radius: 0.4rem 0.4rem 0 0;
  justify-content: center;
  width: fit-content;
`;

export const FooterBox = styled.div`
  position: absolute;
  bottom: -2rem;
  left: 0;
  right: 0;
  margin: auto;
  background: ${colors.background};
  padding: 0.2rem 0.6rem;
  display: flex;
  align-items: center;
  border-radius: 0 0 0.4rem 0.4rem;
  justify-content: center;
  width: fit-content;
`;

export const ItemContainer = styled.div<{
  selected: boolean;
  disabled: boolean;
}>`
  width: 4.2rem;
  min-width: 4.2rem;
  height: 4.2rem;
  min-height: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  background-color: ${(p) =>
    p.disabled
      ? colors.focusBackground
      : p.selected
        ? colors.selected
        : colors.background};
  &:hover {
    background-color: ${(p) =>
      p.disabled
        ? colors.focusBackground
        : p.selected
          ? colors.selected
          : colors.hover};
  }

  svg path {
    stroke: ${(p) =>
      p.disabled ? colors.primaryDisabled : colors.primaryText};
  }
`;
