import { useEffect } from "react";
import { selectorFamily, useRecoilValue } from "recoil";
import { getAnchorsOutsideParkAndBranchSelector } from "../../state/layout";
import { mapRefAtom } from "../../state/map";
import { getParkFeatureSelectorFamily } from "../../state/park";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { WarningProps, useValidationWarnings } from "./ValidationWarnings";
import { anchorSourceId } from "components/Mapbox/constants";
import { branchIdSelector } from "state/pathParams";

export const getAnchorsOutsideParkSelectorFamily = selectorFamily<
  | {
      type: ValidationWarningTypes.AnchorsOutsidePark;
      featureIds: string[];
      parkId: string;
    }
  | undefined,
  {
    parkId: string;
    branchId: string;
  }
>({
  key: "getAnchorsOutsideParkSelectorFamily",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const park = get(getParkFeatureSelectorFamily({ parkId }));

      if (!park) return;

      const outsideAnchors = get(
        getAnchorsOutsideParkAndBranchSelector({ parkId: park.id, branchId }),
      );

      return outsideAnchors.length === 0
        ? undefined
        : {
            type: ValidationWarningTypes.AnchorsOutsidePark,
            featureIds: outsideAnchors.map((a) => a.id),
            parkId: parkId,
          };
    },
});

export const AnchorOutsidePark = ({
  parkId,
  source = anchorSourceId,
}: {
  parkId: string;
  source?: string;
} & WarningProps) => {
  const { warn, remove } = useValidationWarnings();
  const map = useRecoilValue(mapRefAtom);
  const branchId = useRecoilValue(branchIdSelector);

  const outsideAnchorsWarning = useRecoilValue(
    getAnchorsOutsideParkSelectorFamily({ parkId, branchId: branchId ?? "" }),
  );

  useEffect(() => {
    if (!outsideAnchorsWarning || !map) return;
    warn(outsideAnchorsWarning);
    if (map.getSource(source))
      for (const id of outsideAnchorsWarning.featureIds)
        map.setFeatureState({ source, id }, { outside: true });

    return () => {
      remove(ValidationWarningTypes.AnchorsOutsidePark);
      if (map.getSource(source) && outsideAnchorsWarning?.featureIds)
        for (const id of outsideAnchorsWarning.featureIds)
          map.removeFeatureState({ source, id }, "outside");
    };
  }, [outsideAnchorsWarning, remove, source, warn, map]);

  useEffect(
    () => () => {
      remove(ValidationWarningTypes.AnchorsOutsidePark);
    },
    [remove],
  );

  return null;
};
