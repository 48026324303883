import { InputDimensioned } from "../../../General/Input";
import styled from "styled-components";
import { UiErrorText } from "styles/typography";
import { Label as _Label } from "components/General/Form";
import {
  localCostConfigurationAtom,
  useLocalCostConfigurationCrud,
} from "../state";
import { useRecoilValue } from "recoil";
import { isApprox } from "utils/geometry";
import { colors } from "styles/colors";
import { between } from "utils/validations";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";

const Table = styled.table`
  border-collapse: separate;
  width: fit-content;
  border-spacing: 0 0.2rem;
`;

const Header = styled.th`
  padding: 0.2rem;
  text-align: center;
  width: 7rem;

  position: relative;

  // Dashed line
  &.operation-start::before {
    content: "";
    position: absolute;
    left: -0.3rem;
    top: -1.4rem;
    bottom: -9.3rem;
    width: 0.2rem;
    border-left: 0.2rem dashed ${colors.success};
    transform: translateX(-50%);
  }

  // Circle
  &.operation-start::after {
    content: "";
    position: absolute;
    left: -0.4rem;
    top: -1.4rem;
    width: 1.2rem;
    height: 1.2rem;
    background-color: ${colors.success};
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
  }

  // Label
  &.operation-start label {
    position: absolute;
    left: 0.2rem;
    top: -1.4rem;
    white-space: nowrap;
    padding-left: 0.7rem;
    transform: translateY(-50%);
  }
`;

const Cell = styled.td`
  padding: 0.7rem 0.8rem 0.7rem 0rem;
`;

const Label = styled(_Label)`
  font-size: 1.3rem;
  font-weight: 400;
  color: primaryText;
`;

const YearlyDataTable = ({
  projectStart,
  operationStart,
  nYears = 10,
  isReadOnly,
}: {
  projectStart: number;
  operationStart: number;
  nYears?: number;
  isReadOnly?: boolean;
}) => {
  const { updateLifeCycle } = useLocalCostConfigurationCrud();
  const {
    lifeCycle: { phasing },
  } = useRecoilValue(localCostConfigurationAtom);

  const years = Array.from({ length: nYears }, (_, i) => projectStart + i);

  const handleRateChange = (
    year: number,
    type: "devex" | "capex",
    rate: number | undefined,
  ) => {
    updateLifeCycle({
      phasing: {
        ...phasing,
        [type]: [
          ...phasing[type].slice(0, year),
          rate ? rate : 0,
          ...phasing[type].slice(year + 1),
        ].slice(0, years.length),
      },
    });
  };

  const getRate = (year: number, type: "devex" | "capex") =>
    phasing[type][year] ?? 0;

  const capexPercentTotal =
    Math.round(
      years.reduce((acc, _, y) => acc + (phasing.capex[y] ?? 0), 0) * 100 * 10,
    ) / 10;

  const devexPercentTotal =
    Math.round(
      years.reduce((acc, _, y) => acc + (phasing.devex[y] ?? 0), 0) * 100 * 10,
    ) / 10;

  const isCapexTotalOK = isApprox(capexPercentTotal, 100, 0.049);
  const isDevexTotalOK = isApprox(devexPercentTotal, 100, 0.049);

  return (
    <>
      <Table>
        <thead>
          <tr>
            <Header style={{ width: "8.8rem" }} />
            {years.map((year) => (
              <Header
                key={year}
                className={year === operationStart ? "operation-start" : ""}
              >
                {year}
                {year === operationStart && <Label>OPERATION START</Label>}
              </Header>
            ))}
            <Header>{/* Validation message */}</Header>
          </tr>
        </thead>
        <tbody>
          <tr>
            <Cell>
              <Label>DEVEX</Label>
            </Cell>
            {years.map((year, i) => (
              <Cell key={year}>
                <InputDimensioned
                  unit="%"
                  type="number"
                  validate={between(0, 100)}
                  validationMessage={"Must be between 0 and 100%"}
                  value={getRate(i, "devex") * 100}
                  onChange={(v) => handleRateChange(i, "devex", v / 100)}
                  step={0.1}
                  disabled={isReadOnly}
                />
              </Cell>
            ))}
            <Cell style={{ width: "7rem" }}>
              <Label>
                {isDevexTotalOK ? (
                  `${devexPercentTotal}%`
                ) : (
                  <UiErrorText>{devexPercentTotal}%</UiErrorText>
                )}
              </Label>
            </Cell>
          </tr>
          <tr>
            <Cell>
              <Label>CAPEX</Label>
            </Cell>
            {years.map((year, y) => (
              <Cell key={year}>
                <InputDimensioned
                  type="number"
                  unit="%"
                  value={getRate(y, "capex") * 100}
                  onChange={(v) => handleRateChange(y, "capex", v / 100)}
                  step={0.1}
                  validate={between(0, 100)}
                  validationMessage={"Must be between 0 and 100%"}
                  disabled={isReadOnly}
                />
              </Cell>
            ))}
            <Cell style={{ width: "7rem" }}>
              <Label>
                {isCapexTotalOK ? (
                  `${capexPercentTotal}%`
                ) : (
                  <UiErrorText>{capexPercentTotal}%</UiErrorText>
                )}
              </Label>
            </Cell>
          </tr>
        </tbody>
      </Table>
      {(!isCapexTotalOK || !isDevexTotalOK) && (
        <SimpleAlert
          text={"Each row in the phasing table must sum to 100%"}
          type={"error"}
        />
      )}
    </>
  );
};

export default YearlyDataTable;
