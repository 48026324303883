import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const UnreadCommentDot = styled.div`
  width: 1rem;
  min-width: 1rem;
  max-width: 1rem;
  height: 1rem;
  min-height: 1rem;
  max-height: 1rem;
  border-radius: 1rem;
  background-color: ${colors.orange};
`;
