import styled from "styled-components";
import { colors } from "../colors";

export const Container = styled.div`
  pointer-events: none !important;
  & > * {
    pointer-events: auto;
  }
`;

export const SmallDot = styled.div`
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 0.5rem;
  background-color: ${colors.primaryHover};
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${colors.inputOutline};
  margin: 2rem 0;
`;
