import { appendQueryParamsSign, capitalize } from "../utils/utils";
import { addCorsAndCacheProxyURL } from "./gisSourceCorsProxy";
import {
  Layer,
  ExternalDataSourceLinkLayerWithSourceWMTS,
  SourceTypesLayer,
  SourceTypes,
  WmtsSourceEntries,
  WmtsLayer,
  WmtsMetadata,
} from "../types/layers";
import { selector, selectorFamily, waitForAll } from "recoil";
import { newCustomDataSourceAtom } from "./newLayer";
import { fetchWithRetries, fetchWithToken } from "../services/utils";
import { isDefined } from "../utils/predicates";
import { getExternalLayerId } from "../utils/externalLayers";
import { wmtsIsResourceOriented } from "../components/AddLayerSourceInternalModal/utils";
import { privateGISSourceDataWMTSAPISelector } from "./privateGISSource";

export const wmtsGetCapabilitiesSuffix = "service=WMTS&request=GetCapabilities";

export const isWMTSLayer = (
  layer: Layer,
): layer is ExternalDataSourceLinkLayerWithSourceWMTS => {
  return layer.sourceType === SourceTypesLayer.wmts;
};

const removeResourceOrientation = (url: string) => url.split("/WMTS/")[0];

export const getWmtsPath = (
  layer: ExternalDataSourceLinkLayerWithSourceWMTS,
) => {
  const wmtsUrl = layer.isResourceOriented
    ? removeResourceOrientation(layer.sourceLink.url) + "/tile"
    : layer.sourceLink.url;
  const isPrivate = "private" in layer.source ? layer.source["private"] : false;

  return `${addCorsAndCacheProxyURL(wmtsUrl, isPrivate)}`;
};

const getBBOXFromWMSLayer = (layerElem: Element) => {
  if (layerElem.querySelector("westBoundLongitude")) {
    return [
      layerElem.querySelector("westBoundLongitude")?.textContent,
      layerElem.querySelector("southBoundLatitude")?.textContent,
      layerElem.querySelector("eastBoundLongitude")?.textContent,
      layerElem.querySelector("northBoundLatitude")?.textContent,
    ].map((s) => parseFloat(s ?? ""));
  }

  if (layerElem.querySelector("LatLonBoundingBox")) {
    return [
      layerElem.querySelector("LatLonBoundingBox")?.getAttribute("minx"),
      layerElem.querySelector("LatLonBoundingBox")?.getAttribute("miny"),
      layerElem.querySelector("LatLonBoundingBox")?.getAttribute("maxx"),
      layerElem.querySelector("LatLonBoundingBox")?.getAttribute("maxy"),
    ].map((s) => parseFloat(s ?? ""));
  }
};

const wmtsServerFullMetadataSelector = selectorFamily<
  string | undefined,
  {
    url: string;
    privateSource: boolean | undefined;
    isResourceOriented: boolean;
  }
>({
  key: "wmtsServerFullMetadataSelector",
  get:
    ({ url, privateSource, isResourceOriented }) =>
    async () => {
      try {
        const urlGetCapabilities = isResourceOriented
          ? url
          : `${url}?${wmtsGetCapabilitiesSuffix}`;
        const response = privateSource
          ? await fetchWithToken(`${urlGetCapabilities}`, {
              method: "get",
            })
          : await fetchWithRetries(
              `${urlGetCapabilities}`,
              {
                method: "get",
              },
              2,
            );
        if (!response.ok) {
          return;
        }

        const xml = await response.text();
        return xml;
      } catch (err) {
        console.warn(`Could not read from WMS server: ${url}, ${err}`);
        return;
      }
    },
});

const wmtsMetadataLayersSelector = selectorFamily<WmtsSourceEntries, WmtsLayer>(
  {
    key: "wmtsMetadataLayersSelector",
    get:
      (wmtsLayer) =>
      ({ get }) => {
        const url = wmtsLayer?.skipProxy
          ? wmtsLayer.wmts_url
          : addCorsAndCacheProxyURL(wmtsLayer.wmts_url, wmtsLayer.private);

        const isResourceOriented = wmtsIsResourceOriented(wmtsLayer.wmts_url);

        const metadataXMLString = get(
          wmtsServerFullMetadataSelector({
            url,
            privateSource: wmtsLayer.private,
            isResourceOriented,
          }),
        );

        if (!metadataXMLString)
          return {
            url: wmtsLayer.wmts_url,
            sourceType: SourceTypes.wmts,
            source: capitalize(wmtsLayer.source),
            layersInfo: [],
            fetchSucceeded: false,
            alternativeNames: new Set(wmtsLayer?.alternativeNames ?? []),
            originalName: wmtsLayer.originalName,
            keywords: [],
          };

        const parser = new DOMParser();
        const filteredLayers = wmtsLayer.filteredLayers || [];
        const xmlDoc = parser.parseFromString(metadataXMLString, "text/xml");

        const layers =
          xmlDoc.querySelectorAll("Layer Layer").length !== 0
            ? xmlDoc.querySelectorAll("Layer Layer")
            : xmlDoc.querySelectorAll("Layer");
        const sourceAbstract = xmlDoc.querySelector("Service Abstract");
        const sourceKeywordList = xmlDoc.querySelectorAll(
          "Service KeywordList Keyword",
        );
        /**
         * <ContactInformation xmlns="http://www.opengis.net/wms"><ContactPersonPrimary><ContactPerson/><ContactOrganization>Landesamt für Bergbau, Energie und Geologie (LBEG)</ContactOrganization></ContactPersonPrimary><ContactAddress><AddressType>postal</AddressType><Address>Stilleweg 2</Address><City>Hannover</City><StateOrProvince>Niedersachsen</StateOrProvince><PostCode>30655</PostCode><Country>Deutschland</Country></ContactAddress><ContactVoiceTelephone>+49 (0)511-643-0</ContactVoiceTelephone><ContactElectronicMailAddress>metadaten@lbeg.niedersachsen.de</ContactElectronicMailAddress></ContactInformation>
         */
        // const _contactInformation = xmlDoc.querySelector(
        //   "Service ContactInformation"
        // );

        const keywords = Array.from(sourceKeywordList ?? [])
          .map((keyword) => keyword.textContent ?? undefined)
          .filter(Boolean)
          .filter(isDefined);

        const layersInfo = [...layers]
          .map<WmtsMetadata>((layerElem, index) => {
            const title = layerElem.querySelector("Title")?.textContent;
            const layerName = layerElem.querySelector("Title")?.textContent;

            const abstract =
              layerElem.querySelector("Abstract")?.textContent ?? undefined;
            const layerKeywordList = Array.from(
              layerElem.querySelectorAll("KeywordList Keyword") ?? [],
            )
              .map((keyword) => keyword?.textContent ?? undefined)
              .filter(Boolean)
              .filter(isDefined);

            const metaDataUrls = layerElem.querySelectorAll("MetadataURL");

            const metaDataUrl =
              Array.from(metaDataUrls)
                .map((f) => f.querySelector("Format"))

                .find((f) => f?.textContent === "application/xml")
                ?.nextElementSibling?.getAttribute("xlink:href") ?? undefined;

            let link: string | undefined = undefined;
            const htmlLink = [...layerElem.querySelectorAll("DataURL")].filter(
              (l) => l.querySelector("Format")?.textContent === "text/html",
            );
            if (htmlLink.length !== 0) {
              link =
                htmlLink[0]
                  ?.querySelector("OnlineResource")
                  ?.getAttribute("xlink:href") ?? "undefined";
            }

            const nameTitle = title ?? "undefined";

            const layerId = getExternalLayerId(
              wmtsLayer.wmts_url,
              layerName,
              SourceTypesLayer.wmts,
              {
                index,
              },
            );
            return {
              id: layerId,
              type: "wmts",
              name: nameTitle,
              abstract,
              keywords: layerKeywordList,
              metaDataUrl,
              link,
              isResourceOriented,
              bbox: getBBOXFromWMSLayer(layerElem),
              path: isResourceOriented
                ? removeResourceOrientation(url)
                : `${url}${appendQueryParamsSign(
                    url,
                  )}format=image/png&service=WMTS&version=1.0.0&request=GetTile&styles=&transparent=true&layers=${
                    layerElem.querySelector("Name")?.textContent
                  }`,
              layer:
                layerElem.querySelector("Name")?.textContent ?? "undefined",
              url: url,
              originalUrl: wmtsLayer.wmts_url,
              source: wmtsLayer.source,
              alias:
                wmtsLayer.layers?.find(
                  (l) =>
                    l.featureTypeName ===
                    layerElem.querySelector("Name")?.textContent,
                )?.alias || undefined,
              theme:
                wmtsLayer.layers?.find(
                  (l) =>
                    l.featureTypeName ===
                    layerElem.querySelector("Name")?.textContent,
                )?.theme || undefined,
              sourceType: SourceTypesLayer.wmts,
              tags: wmtsLayer.layerSettingsGlobal?.[layerId]?.tags ?? [],
            };
          })
          .filter((l) => !filteredLayers.includes(l.layer ?? ""));

        return {
          url: wmtsLayer.wmts_url,
          sourceType: SourceTypes.wmts,
          source: capitalize(wmtsLayer.source),
          alternativeNames: new Set(wmtsLayer?.alternativeNames ?? []),
          layersInfo,
          fetchSucceeded: true,
          originalName: wmtsLayer.originalName,
          abstract: sourceAbstract?.textContent ?? undefined,
          keywords,
        };
      },
  },
);

const wmtsDataLayersFullMetadataSelectorFamily = selectorFamily<
  WmtsSourceEntries[],
  WmtsLayer[]
>({
  key: "wmtsDataLayersFullMetadataSelectorFamily",
  get:
    (wmsLayers) =>
    ({ get }) => {
      const wmsMetadataLayers = get(
        waitForAll(
          wmsLayers.map((wmsLayer) => wmtsMetadataLayersSelector(wmsLayer)),
        ),
      );

      return wmsMetadataLayers.filter((l) => l != null);
    },
});

export const wmtsPrivateDataLayersFullMetadataSucceededSelector =
  selectorFamily<WmtsSourceEntries[], { projectId: string }>({
    key: "wmtsPrivateDataLayersFullMetadataSucceededSelector",
    get:
      ({ projectId }) =>
      ({ get }) => {
        const wmsLayers = get(
          privateGISSourceDataWMTSAPISelector({ projectId }),
        );

        return get(wmtsDataLayersFullMetadataSelectorFamily(wmsLayers)).filter(
          (l) => l.fetchSucceeded,
        );
      },
  });

export const customWmtsDataLayersFullMetadataSelector = selector<
  WmtsSourceEntries[]
>({
  key: "customWmtsDataLayersFullMetadataSelector",
  get: ({ get }) => {
    const customWMSDataSource = get(newCustomDataSourceAtom);
    if (!customWMSDataSource || customWMSDataSource.type !== SourceTypes.wmts)
      return [];

    const customWmsLayers: WmtsLayer[] = [
      {
        source: customWMSDataSource.name,
        alternativeNames: customWMSDataSource?.alternativeNames ?? [],
        wmts_url: customWMSDataSource.url,
        layers: [],
        private: true,
        sourceType: SourceTypesLayer.wmts,
      },
    ];

    return get(
      wmtsDataLayersFullMetadataSelectorFamily(customWmsLayers),
    ).filter((l) => l.fetchSucceeded);
  },
});
