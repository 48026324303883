import { atomFamily, selectorFamily, useRecoilState } from "recoil";
import { Dispatch, SetStateAction } from "react";
import { syncEffect } from "recoil-sync";
import { nullable, string } from "@recoiljs/refine";
import { Storage } from "../components/BrowserStorage/BrowserStorage";

const storageAtomFamily = atomFamily<
  any,
  {
    key: string;
    storage: Storage;
  }
>({
  key: "storageAtomFamily",
  effects: ({ key, storage }) => [
    syncEffect({
      itemKey: key,
      storeKey: storage,
      refine: nullable(string()),
    }),
  ],
});

export const storageSelectorFamily = selectorFamily<
  any,
  { key: string; storage: Storage }
>({
  key: "storageSelectorFamily",
  get:
    ({ key, storage }) =>
    ({ get }) => {
      const str = get(storageAtomFamily({ key, storage }));
      try {
        return JSON.parse(str);
      } catch (e) {
        return undefined;
      }
    },
  set:
    ({ key, storage }) =>
    ({ set }, value: any) => {
      const str = JSON.stringify(value);
      set(storageAtomFamily({ key, storage }), str);
    },
});

export function useLocalStorage<T>(
  key: string,
  defaultValue: T | undefined = undefined,
): [T | undefined, Dispatch<SetStateAction<T | undefined>>] {
  const [val, set] = useRecoilState(
    storageSelectorFamily({
      key,
      storage: "local-storage",
    }),
  );
  return [val ?? defaultValue, set] as [
    T | undefined,
    Dispatch<SetStateAction<T | undefined>>,
  ];
}

export function useSessionStorage<T>(
  key: string,
): [T | undefined, Dispatch<SetStateAction<T | undefined>>] {
  const [val, set] = useRecoilState(
    storageSelectorFamily({
      key,
      storage: "session-storage",
    }),
  );
  return [val, set] as [T | undefined, Dispatch<SetStateAction<T | undefined>>];
}
