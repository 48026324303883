/// <reference types="vite-plugin-svgr/client" />
import { CostConfiguration, CostType } from "../../../../services/costService";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  localCostConfigurationAtom,
  useLocalCostConfigurationCrud,
} from "../state";
import { Input, InputDimensioned } from "../../../General/Input";
import {
  ConfidenceLevelDot,
  CostDivider,
  DividerWrapper,
  GridWithFourColumns,
  Tag,
} from "../styles";
import { FirstRowWrapper, FirstRowItem } from "../Capex";
import Mooring from "@icons/24/Anchor.svg?react";
import { GeneralCost } from "./General/GeneralCost";
import { DefaultCosts } from "../constants";
import { ConfidenceLevel, CostUnit } from "../../../../types/financial";
import { isDefined } from "../../../../utils/predicates";
import { projectIdSelector } from "state/pathParams";
import Button from "components/General/Button";
import PencilIcon from "@icons/24/Pencil.svg";
import ComponentsIcon from "@icons/24/ComponentsIcon.svg";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import { MOORING_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useMooringSettings";
import { Row } from "components/General/Layout";
import Tooltip from "components/General/Tooltip";
import LogoIcon from "@icons/24/VindLogo.svg?react";
import { IconREMSize } from "styles/typography";
import { colors } from "styles/colors";
import { IconBtn } from "components/General/Icons";
import PreviewIcon from "@icons/24/PreviewIcon.svg?react";
import { showComponentsPreviewAtom } from "components/ComponentsPreview/state";
import UndoIcon from "@icons/24/Undo.svg";
import { ConfidenceLevelSelector } from "../ConfidenceLevelSelector";
import { ButtonTextWrapper } from "components/General/Dropdown/DropdownButton";

const MooringSpecificCosts = ({ isReadOnly }: { isReadOnly?: boolean }) => {
  const localConfig: CostConfiguration = useRecoilValue(
    localCostConfigurationAtom,
  );
  const [, setShowComponentsPreview] = useRecoilState(
    showComponentsPreviewAtom,
  );

  const useConfidenceLevel = localConfig.useConfidenceLevel;
  const projectId = useRecoilValue(projectIdSelector)!;
  const inLibraryContext = !projectId;

  const { updateFixedCapex } = useLocalCostConfigurationCrud();

  const handleLineCostTypeChange = (type: "default" | "override") => () => {
    if (type === "default") {
      updateFixedCapex({ lines: undefined }, "mooring");
    } else {
      updateFixedCapex(
        {
          lines: {
            cost: DefaultCosts[CostType.Mooring].line[
              CostUnit.millionEuroPerKM
            ],
            unit: CostUnit.millionEuroPerKM,
            confidenceLevel: ConfidenceLevel.notSpecified,
          },
        },
        "mooring",
      );
    }
  };

  const { lines } = localConfig.capex.fixed.mooring;

  const isUsingDefaultMooringCost =
    localConfig?.capex.fixed.mooring?.lines &&
    localConfig?.capex.fixed.mooring.lines?.cost ===
      DefaultCosts[CostType.Mooring].line[CostUnit.millionEuroPerKM] &&
    localConfig?.capex.fixed.mooring.lines?.unit === CostUnit.millionEuroPerKM;

  return (
    <>
      <FirstRowWrapper>
        <GridWithFourColumns>
          <FirstRowItem>
            <Input
              value={"Mooring system"}
              disabled={true}
              locked={true}
              style={{ width: "20rem" }}
            />
          </FirstRowItem>

          {lines === undefined ? (
            <Row style={{ width: "20rem" }}>
              <Tag>
                <ComponentsIcon />
                From components
              </Tag>
              {inLibraryContext ? (
                <HelpTooltip text="Using the cost specified on the mooring line type" />
              ) : (
                <IconBtn
                  size={"1.5rem"}
                  onClick={() => {
                    setShowComponentsPreview({
                      show: true,
                      componentType: MOORING_MENU_ID,
                    });
                  }}
                >
                  <PreviewIcon />
                </IconBtn>
              )}
            </Row>
          ) : (
            <Row style={{ width: "20rem" }}>
              <InputDimensioned
                step="0.1"
                unit={
                  localConfig?.capex.fixed.mooring.lines?.unit ??
                  CostUnit.millionEuroPerKM
                }
                units={[CostUnit.millionEuroPerKM, CostUnit.thousandEuroPerMW]}
                type="number"
                value={
                  localConfig?.capex.fixed.mooring?.lines
                    ? localConfig?.capex.fixed.mooring.lines?.cost ??
                      DefaultCosts[CostType.Mooring].line[
                        CostUnit.millionEuroPerKM
                      ]
                    : undefined
                }
                onChange={(cost, _, unit) =>
                  updateFixedCapex(
                    {
                      lines: {
                        unit,
                        cost,
                      },
                    },
                    "mooring",
                  )
                }
                onUnitChange={(unit) => {
                  updateFixedCapex(
                    {
                      lines: {
                        unit,
                        cost: (DefaultCosts as any)[CostType.Mooring].line[
                          unit
                        ],
                      },
                    },
                    "mooring",
                  );
                }}
                validate={(value) => isDefined(value) && value >= 0}
                validationMessage={"Must be greater than or equal to 0"}
                style={{ width: "100%" }}
                disabled={
                  isReadOnly || !localConfig?.capex.fixed.mooring?.lines
                }
              />
              {isUsingDefaultMooringCost ? (
                <Tooltip text={"Vind default value"}>
                  <IconREMSize height={2.6} width={2.6} fill={colors.blue700}>
                    <LogoIcon />
                  </IconREMSize>
                </Tooltip>
              ) : (
                <Tooltip text="Reset to default">
                  <IconBtn
                    size={"1.6rem"}
                    onClick={handleLineCostTypeChange("override")}
                  >
                    <UndoIcon />
                  </IconBtn>
                </Tooltip>
              )}
            </Row>
          )}
          {useConfidenceLevel ? (
            !(lines === undefined) ? (
              <ConfidenceLevelSelector
                disabled={isReadOnly}
                onConfidenceLevelChange={(confidenceLevel) => {
                  updateFixedCapex(
                    {
                      lines: {
                        ...localConfig.capex.fixed.mooring?.lines,
                        confidenceLevel,
                      },
                    },
                    "mooring",
                  );
                }}
                confidenceLevel={
                  localConfig.capex.fixed.mooring?.lines?.confidenceLevel
                }
              />
            ) : (
              <Tag>
                <Tooltip
                  text={
                    "Not able to specify confidence on configuration level when the cost is from components"
                  }
                >
                  <ButtonTextWrapper>
                    <ConfidenceLevelDot confidenceLevel={undefined} />
                    {ConfidenceLevel.notSpecified}
                  </ButtonTextWrapper>
                </Tooltip>
              </Tag>
            )
          ) : (
            <div></div>
          )}

          <Button
            size="small"
            icon={lines === undefined ? <PencilIcon /> : <ComponentsIcon />}
            buttonType="secondary"
            text={lines === undefined ? "Override" : "Use components"}
            onClick={
              lines === undefined
                ? handleLineCostTypeChange("override")
                : handleLineCostTypeChange("default")
            }
            disabled={isReadOnly}
          />
        </GridWithFourColumns>
      </FirstRowWrapper>

      <Row>
        <GridWithFourColumns>
          <Input
            value={"Anchors"}
            disabled={true}
            locked={true}
            style={{ width: "20rem" }}
          />
          <InputDimensioned
            step="0.1"
            unit={CostUnit.millionEuroPerUnit}
            type="number"
            disabled={isReadOnly}
            value={localConfig.capex.fixed.mooring?.anchors?.cost ?? 0}
            onChange={(cost, _, unit) =>
              updateFixedCapex(
                {
                  anchors: {
                    unit,
                    cost,
                  },
                },
                "mooring",
              )
            }
            style={{ width: "20rem" }}
          />
          {useConfidenceLevel ? (
            <ConfidenceLevelSelector
              disabled={isReadOnly}
              onConfidenceLevelChange={(confidenceLevel) => {
                updateFixedCapex(
                  {
                    anchors: {
                      ...localConfig.capex.fixed.mooring?.anchors,
                      confidenceLevel: confidenceLevel,
                    },
                  },
                  "mooring",
                );
              }}
              confidenceLevel={
                localConfig.capex.fixed.mooring?.anchors?.confidenceLevel
              }
            />
          ) : (
            <div></div>
          )}
        </GridWithFourColumns>
      </Row>
      <Row>
        <GridWithFourColumns>
          <Input
            value={"Clump weights"}
            disabled={true}
            locked={true}
            style={{ width: "20rem" }}
          />
          <InputDimensioned
            step="0.1"
            unit={CostUnit.euroPerT}
            type="number"
            disabled={isReadOnly}
            value={localConfig.capex.fixed.mooring?.clumpWeights?.cost ?? 0}
            onChange={(cost, _, unit) =>
              updateFixedCapex(
                {
                  clumpWeights: {
                    unit,
                    cost,
                  },
                },
                "mooring",
              )
            }
            style={{ width: "20rem" }}
          />
          {useConfidenceLevel ? (
            <ConfidenceLevelSelector
              disabled={isReadOnly}
              onConfidenceLevelChange={(confidenceLevel) => {
                updateFixedCapex(
                  {
                    clumpWeights: {
                      ...localConfig.capex.fixed.mooring?.clumpWeights,
                      confidenceLevel,
                    },
                  },
                  "mooring",
                );
              }}
              confidenceLevel={
                localConfig.capex.fixed.mooring?.clumpWeights?.confidenceLevel
              }
            />
          ) : (
            <div></div>
          )}
        </GridWithFourColumns>
      </Row>
      <Row>
        <GridWithFourColumns>
          <Input
            value={"Buoys"}
            disabled={true}
            locked={true}
            style={{ width: "20rem" }}
          />
          <InputDimensioned
            step="0.1"
            unit={CostUnit.euroPerT}
            type="number"
            disabled={isReadOnly}
            value={localConfig.capex.fixed.mooring?.buoys?.cost ?? 0}
            onChange={(cost, _, unit) =>
              updateFixedCapex(
                {
                  buoys: {
                    unit,
                    cost,
                  },
                },
                "mooring",
              )
            }
            style={{ width: "20rem" }}
          />
          {useConfidenceLevel ? (
            <ConfidenceLevelSelector
              disabled={isReadOnly}
              onConfidenceLevelChange={(confidenceLevel) => {
                updateFixedCapex(
                  {
                    buoys: {
                      ...localConfig.capex.fixed.mooring?.buoys,
                      confidenceLevel,
                    },
                  },
                  "mooring",
                );
              }}
              confidenceLevel={
                localConfig.capex.fixed.mooring?.buoys?.confidenceLevel
              }
            />
          ) : (
            <div></div>
          )}
        </GridWithFourColumns>
      </Row>
    </>
  );
};

export const MooringCosts = ({ isReadOnly }: { isReadOnly?: boolean }) => {
  return (
    <GeneralCost
      title="Mooring system"
      category={CostType.Mooring}
      isReadOnly={isReadOnly}
      units={[
        CostUnit.millionEuroPerMW,
        CostUnit.millionEuroPerKM,
        CostUnit.millionEuroPerLine,
        CostUnit.millionEuro,
      ]}
      icon={<Mooring />}
    >
      <MooringSpecificCosts isReadOnly={isReadOnly} />
      <DividerWrapper>
        <CostDivider />
      </DividerWrapper>
    </GeneralCost>
  );
};
