import styled from "styled-components";
import { colors } from "../colors";
import { borderRadiusMedium } from "../space";

export const StandardBox = styled.div`
  background: ${colors.background};
  border-radius: ${borderRadiusMedium};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  color: #264e5d;
`;
