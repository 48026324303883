import { useRecoilValue } from "recoil";
import { branchSelectedConfigurationAtomFamily } from "state/configuration";
import { branchSelectedCostConfigurationAtom } from "state/costConfigurations";
import { selectedWindConfigurationAtomFamily } from "state/windSourceConfiguration";

export default function useCurrentConfigs(projectId: string, branchId: string) {
  const selectedAnalysisConfig = useRecoilValue(
    branchSelectedConfigurationAtomFamily({ projectId, branchId }),
  );
  const selectedWindConfig = useRecoilValue(
    selectedWindConfigurationAtomFamily({ projectId, branchId }),
  );
  const selectedCostConfig = useRecoilValue(
    branchSelectedCostConfigurationAtom({ projectId, branchId }),
  );

  return {
    selectedAnalysisConfig,
    selectedWindConfig,
    selectedCostConfig,
  };
}
