import { selectorFamily } from "recoil";
import {
  CostType,
  isTurbineFeatureCost,
  isTurbineOverrideCost,
} from "../../../../services/costService";
import { libraryAndProjectCostConfigurationsSelectorFamily } from "../../../../state/costConfigurations";
import { CostUnit, unitToAmountUnit } from "../../../../types/financial";
import { Cost, costId } from "../capexNumbers";
import { amountId } from "../generalAmounts";
import { allTurbineTypesInParkSelectorFamily } from "./turbineAmounts";
import { SimpleTurbineType } from "types/turbines";

export const turbineCapexEntriesSelectorFamily = selectorFamily<
  Cost[],
  {
    projectId: string;
    branchId: string;
    parkId: string;
    configurationId: string;
    turbineTypeOverride: SimpleTurbineType | undefined;
  }
>({
  key: "turbineCapexEntriesSelectorFamily",
  get:
    ({ projectId, branchId, parkId, configurationId, turbineTypeOverride }) =>
    ({ get }) => {
      const configurations = get(
        libraryAndProjectCostConfigurationsSelectorFamily({
          nodeId: projectId,
        }),
      );
      const allTurbineTypes = get(
        allTurbineTypesInParkSelectorFamily({
          branchId,
          parkId,
          turbineTypeOverride,
        }),
      );

      const configuration = configurations.find(
        (c) => c.id === configurationId,
      );

      if (!configuration) return [];

      const {
        capex: { custom, fixed },
      } = configuration;

      const flatCosts: Cost[] = custom
        .filter((c) => c.category === CostType.Turbine)
        .filter((c) => c.unit === CostUnit.millionEuro)
        .map((c) => ({
          ...c,
          id: costId({
            category: CostType.Turbine,
            costId: c.id,
          }),
          amountId: amountId({
            unit: unitToAmountUnit[c.unit],
            category: c.category,
          }),
        }));

      const customCosts: Cost[] = custom
        .filter((c) => c.category === CostType.Turbine)
        .filter((c) => c.unit !== CostUnit.millionEuro)
        .flatMap((custom) =>
          allTurbineTypes.map((turbineType) => ({
            ...custom,
            name: `${custom.name} ${turbineType.name}`,
            id: costId({
              category: CostType.Turbine,
              costId: custom.id,
              featureTypeId: turbineType.id,
            }),
            amountId: amountId({
              unit: unitToAmountUnit[custom.unit],
              category: custom.category,
              featureTypeId: turbineType.id,
            }),
          })),
        );

      let fixedCosts: Cost[] = [];

      if (isTurbineFeatureCost(fixed.turbines)) {
        fixedCosts = allTurbineTypes.map(({ cost, costUnit, id, name }) => ({
          id: `turbines_${id}`,
          amountId: amountId({
            unit: unitToAmountUnit[costUnit],
            category: CostType.Turbine,
            featureTypeId: id,
          }),
          category: CostType.Turbine,
          name,
          cost,
          unit: costUnit,
          confidenceLevel: undefined,
        }));
      } else if (isTurbineOverrideCost(fixed.turbines)) {
        const { cost, unit, confidenceLevel } = fixed.turbines;

        fixedCosts = allTurbineTypes.map(({ id, name }) => ({
          id: `turbines_${id}`,
          amountId: amountId({
            unit: unitToAmountUnit[unit],
            category: CostType.Turbine,
            featureTypeId: id,
          }),
          category: CostType.Turbine,
          name,
          cost: cost,
          unit: unit,
          confidenceLevel: confidenceLevel,
        }));
      }
      return [...fixedCosts, ...customCosts, ...flatCosts];
    },
});
