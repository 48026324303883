import React, { ReactNode, useEffect, useRef } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { modalTypeOpenAtom } from "../../state/modal";
import { unsavedSettingsState } from "../SettingsV2/Shared/state";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { spaceHuge, spaceLarge } from "styles/space";

export const FullScreenModalSkeleton = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: spaceLarge,
        flex: 1,
        margin: spaceHuge,
      }}
    >
      <SkeletonBlock style={{ height: "1rem" }} />
      <SkeletonBlock style={{ height: "1rem" }} />
      <SkeletonBlock style={{ height: "1rem" }} />
    </div>
  );
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  background: #00070f66;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FullScreenModal = ({
  closeOnWrapperClick = true,
  onClick,
  onEscape,
  children,
  disableClose,
  style,
}: {
  closeOnWrapperClick?: boolean;
  onClick?: () => void;
  children: ReactNode;
  onEscape?: () => void;
  disableClose?: boolean;
  style?: React.CSSProperties;
}) => {
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const unsavedSettings = useRecoilValue(unsavedSettingsState);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (disableClose) return;
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        if (onEscape) {
          onEscape();
        } else {
          setModalTypeOpen(undefined);
        }
      }
    };
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [setModalTypeOpen, disableClose, onEscape]);

  return (
    <Wrapper
      data-is-modal={1}
      style={style}
      ref={wrapperRef}
      onMouseDown={(e) => {
        if (disableClose) return;
        if (e.target !== wrapperRef.current) {
          return;
        }
        if (onClick) {
          onClick();
          return;
        }
        if (closeOnWrapperClick === false) {
          return;
        }
        if (unsavedSettings) {
          if (window.confirm("Are you sure you want to quit without saving?")) {
            e.stopPropagation();
            setModalTypeOpen(undefined);
          }
        } else {
          e.stopPropagation();
          setModalTypeOpen(undefined);
        }
      }}
    >
      {children}
    </Wrapper>
  );
};

export default FullScreenModal;
