import { Suspense, useCallback, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import {
  ClientCredentialScope,
  ClientCredentials,
  copyClientCredentialsModalState,
  orgClientCredentials,
  postClientCredentials,
  revokeClientCredentials,
} from "./state/clientCredentials";
import Trashcan from "@icons/24/Bin.svg?react";
import Key from "@icons/24/Key.svg?react";
import { organisationIdSelector } from "../../../state/pathParams";
import { toastMessagesAtom } from "../../../state/toast";
import { StandardBox } from "../../../styles/boxes/Boxes";
import { colors } from "../../../styles/colors";
import { dateToDateTime } from "../../../utils/utils";
import {
  ErrorBoundaryWarningTriangle,
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "../../ErrorBoundaries/ErrorBoundaryLocal";
import FullScreenModal from "../../FullScreenModal/FullScreenModal";
import Button from "../../General/Button";
import Checkbox from "../../General/Checkbox";
import { Label } from "../../General/Form";
import { Input } from "../../General/Input";
import { Column, Row } from "../../General/Layout";
import { ModalHeader } from "../../RightSide/InfoModal/InfoModal.style";
import CopyClientCredentialsModal from "./CopyClientCredentialModal";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { integrationAccessSelectorFamily } from "state/featureAccess";
import { typography } from "styles/typography";
import { ValidationAlert } from "components/ValidationWarnings/ValidationAlert";
import { EmptyState } from "components/ValidationWarnings/EmptyState";
import { TabHeader } from "../style";

// -------------------------------

const Modal = styled(StandardBox)`
  position: fixed;
  top: 20%;
  padding: 20px;
  width: 300px;
`;

const SubHeader = styled.p`
  ${typography.sub2}
  margin: 0;
`;

const READ_TURBINE_SCOPE: ClientCredentialScope =
  "api.vind.ai/read_library_turbines";
const WRITE_TURBINE_SCOPE: ClientCredentialScope =
  "api.vind.ai/write_library_turbines";
const READ_CABLE_SCOPE: ClientCredentialScope =
  "api.vind.ai/read_library_cables";
const WRITE_CABLE_SCOPE: ClientCredentialScope =
  "api.vind.ai/write_library_cables";

const scopeReadableName = {
  [READ_TURBINE_SCOPE]: "Read library turbines",
  [WRITE_TURBINE_SCOPE]: "Write library turbines",
  [READ_CABLE_SCOPE]: "Read library cables",
  [WRITE_CABLE_SCOPE]: "Write library cables",
};

const CreateClientCredentials = () => {
  const organisationId = useRecoilValue(organisationIdSelector) ?? "";

  const setToastMessages = useSetRecoilState(toastMessagesAtom);
  const setClientCredentials = useSetRecoilState(
    orgClientCredentials({ organisationId }),
  );
  const [open, setOpen] = useState(false);
  const [name, setName] = useState<undefined | string>(undefined);
  const [readTurbines, setReadTurbines] = useState<boolean>(true);
  const [writeTurbines, setWriteTurbines] = useState<boolean>(true);
  const [readCables, setReadCables] = useState<boolean>(true);
  const [writeCables, setWriteCables] = useState<boolean>(true);

  const setNewlyCreated = useSetRecoilState(copyClientCredentialsModalState);

  const _create = useCallback(async () => {
    // call api
    const scopes: ClientCredentialScope[] = [];
    if (readTurbines) scopes.push(READ_TURBINE_SCOPE);
    if (writeTurbines) scopes.push(WRITE_TURBINE_SCOPE);
    if (readCables) scopes.push(READ_CABLE_SCOPE);
    if (writeCables) scopes.push(WRITE_CABLE_SCOPE);

    if (!name) return;

    const body = {
      name,
      scopes,
    };

    try {
      const res = await postClientCredentials({ organisationId, body });
      setNewlyCreated(res);
      setClientCredentials((cur) => [...cur, res]);
    } catch (e: any) {
      setToastMessages((cur) => [
        ...cur,
        { text: e.message, timeout: 5000, type: "error" },
      ]);
    }
    setOpen(false);
  }, [
    name,
    organisationId,
    readCables,
    readTurbines,
    setClientCredentials,
    setNewlyCreated,
    setToastMessages,
    writeCables,
    writeTurbines,
  ]);

  return (
    <>
      <Button
        text="Create client credential"
        onClick={() => setOpen(true)}
        buttonType="primary"
        style={{ marginLeft: "auto" }}
      />
      {open && (
        <FullScreenModal>
          <Modal>
            <Column style={{ gap: "2.4rem" }}>
              <ModalHeader>Create client credential</ModalHeader>
              <Label>
                <p>Name</p>
                <Input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="string"
                  placeholder="Create a name"
                  autoFocus
                />
              </Label>
              <Column>
                <SubHeader>Choose capabilities</SubHeader>
                <Checkbox
                  checked={readTurbines}
                  onChange={() => setReadTurbines(!readTurbines)}
                  label={"Read library turbines access"}
                  labelPlacement="after"
                />
                <Checkbox
                  checked={writeTurbines}
                  onChange={() => setWriteTurbines(!writeTurbines)}
                  label={"Write library turbines access"}
                  labelPlacement="after"
                />
                <Checkbox
                  checked={readCables}
                  onChange={() => setReadCables(!readCables)}
                  label={"Read library cables access"}
                  labelPlacement="after"
                />
                <Checkbox
                  checked={writeCables}
                  onChange={() => setWriteCables(!writeCables)}
                  label={"Write library cables access"}
                  labelPlacement="after"
                />
              </Column>
              <Row style={{ marginLeft: "auto" }}>
                <Button
                  text="Cancel"
                  onClick={() => setOpen(false)}
                  buttonType="secondary"
                />
                <Button text="Create" onClick={_create} buttonType="primary" />
              </Row>
            </Column>
          </Modal>
        </FullScreenModal>
      )}
    </>
  );
};

const Divider = styled.hr`
  border: 0;
  border-left: 1px solid ${colors.secondaryText};
  margin: 0;
  padding: 0;
  opacity: 0.5;
  flex: 1;
  width: 1px;
  max-width: 1px;
`;

const ElementContainer = styled.div`
  display: flex;
  padding: 1.6rem 2rem;
  background: ${colors.surfacePrimary};
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
  width: 100%;
  min-width: 32rem;
  max-width: 57rem;
  flex: 1 1 auto;
`;

const ClientCredentialElement = ({ cc }: { cc: ClientCredentials }) => {
  const organisationId = useRecoilValue(organisationIdSelector) ?? "";
  const setToastMessages = useSetRecoilState(toastMessagesAtom);
  const setClientCredentials = useSetRecoilState(
    orgClientCredentials({ organisationId }),
  );
  const [loading, setLoading] = useState(false);

  const _revoke = useCallback(async () => {
    setLoading(true);
    try {
      await revokeClientCredentials({ organisationId, clientId: cc.client_id });
      setClientCredentials((cur) =>
        [...cur].filter((c) => c.client_id !== cc.client_id),
      );
    } catch (e: any) {
      setToastMessages((cur) => [
        ...cur,
        { text: e.message, timeout: 5000, type: "error" },
      ]);
    }
    setLoading(false);
  }, [cc.client_id, organisationId, setClientCredentials, setToastMessages]);

  return (
    <ElementContainer key={cc.client_id}>
      <Row style={{ gap: "2.4rem", width: "100%" }}>
        <Column style={{ gap: "2.4rem", flex: 3 }}>
          <Row style={{ gap: "1.2rem" }}>
            <Column>
              <Label style={{ gap: "0.4rem" }}>
                <p style={{ fontWeight: 600 }}>Created</p>
                <p>{dateToDateTime(new Date(cc.created_at))}</p>
              </Label>
            </Column>
            <Divider />
            <Label style={{ gap: "0.4rem" }}>
              <p style={{ fontWeight: 600 }}>Name</p>
              <p>{cc.name}</p>
            </Label>
          </Row>
          <Label style={{ gap: "0.4rem" }}>
            <p style={{ fontWeight: 600 }}>Client_id</p>
            <p>{cc.client_id}</p>
          </Label>
          <Label style={{ gap: "0.4rem" }}>
            <p style={{ fontWeight: 600 }}>Capabilities</p>
            <p>
              {cc.scopes.map((s, i, all) => (
                <span key={s}>
                  {scopeReadableName[s]}
                  {i !== all.length - 1 ? "," : ""}{" "}
                </span>
              ))}
            </p>
          </Label>
        </Column>
        <Column style={{ flex: 1 }}>
          <Button
            text="Revoke"
            icon={<Trashcan />}
            onClick={() => {
              if (
                window.confirm(
                  "Credentials will no longer work, are you sure you want to continue?",
                )
              ) {
                _revoke();
              }
            }}
            buttonType="secondary"
            disabled={loading}
            style={{ marginLeft: "auto" }}
          />
        </Column>
      </Row>
    </ElementContainer>
  );
};

const ClientCredentialsList = () => {
  const organisationId = useRecoilValue(organisationIdSelector);

  const clientCredentials = useRecoilValue(
    orgClientCredentials({ organisationId: organisationId ?? "" }),
  );

  return (
    <Row style={{ flexWrap: "wrap", padding: "0.2rem", gap: "2rem" }}>
      {clientCredentials.map((cc) => (
        <ClientCredentialElement cc={cc} key={cc.client_id} />
      ))}
    </Row>
  );
};

const ClientCredentialsSettings = ErrorBoundaryWrapper(
  () => {
    const organisationId = useRecoilValue(organisationIdSelector);
    const orgHasAccess = useRecoilValue(
      integrationAccessSelectorFamily({ organisationId }),
    );

    if (!orgHasAccess)
      return (
        <Row style={{ gap: "2.4rem", padding: "2.4rem 0 0 0" }}>
          <EmptyState
            title={"Client credentials for the Vind API"}
            description="Your organisation does not currently have access to this feature. Get in touch to learn more."
            icon={<Key />}
            actionButton={
              <Button
                onClick={() => window.Intercom("showMessages")}
                text="Open chat"
              />
            }
          />
        </Row>
      );

    return (
      <>
        <TabHeader>Integration</TabHeader>
        <Column style={{ gap: "2.4rem", padding: "2.4rem 0 0 0" }}>
          <Row
            style={{
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <Column>
              <div style={{ width: "48rem" }}>
                <ValidationAlert
                  title={"Client credentials"}
                  description="Client credentials can be used to create a token that lets you access the Vind APIs."
                  type={"info"}
                  _isExpanded={true}
                />
              </div>
            </Column>
            {orgHasAccess && <CreateClientCredentials />}
          </Row>

          <>
            <Suspense
              fallback={
                <div style={{ height: "6rem" }}>
                  <SkeletonBlock />
                </div>
              }
            >
              <ClientCredentialsList />
            </Suspense>

            <CopyClientCredentialsModal />
          </>
        </Column>
      </>
    );
  },
  () => {
    return (
      <>
        <FatalErrorBoundaryWrapper />
        <ErrorBoundaryWarningTriangle />
      </>
    );
  },
  ScreamOnError,
);

export default ClientCredentialsSettings;
