import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import ComponentsIcon from "@icons/24/Components.svg?react";
import { FeatureSettingsModalTypeV2 } from "state/configuration";
import Tooltip from "components/General/Tooltip";
import {
  DISABLED_IN_READ_ONLY_MODE,
  inReadOnlyModeSelector,
} from "state/project";
import SearchWrapper from "components/Search/SearchWrapper";
import { modalTypeOpenAtom } from "state/modal";
import RoundIcon from "components/Design/TopbarNavigation/components/Middle/RoundIcon";
import { MiddleItemWrapper } from "components/Design/TopbarNavigation/components/Middle/style";
import { Mixpanel } from "mixpanel";

const ComponentsModalButton = () => {
  // const editorAccessProject = useRecoilValueLoadable(
  //   editorAccessProjectSelector,
  // );
  const [modalTypeOpen, setModalType] = useRecoilState(modalTypeOpenAtom);
  const readOnlyMode = useRecoilValue(inReadOnlyModeSelector);

  // Todo: Handle the case where user is not editor
  // if (
  //   editorAccessProject.state !== "hasValue" ||
  //   !editorAccessProject.contents
  // ) {
  //   return (
  //     <Tooltip text="Settings" position="bottom">
  //       <IconBtn disabled={true} onClick={() => {}}>
  //         <Settings />
  //       </IconBtn>
  //     </Tooltip>
  //   );
  // }

  return (
    <SearchWrapper
      title="Components"
      tags={[
        "projects",
        "list",
        "project list",
        "feature list",
        "turbine",
        "foundation",
        "inter array",
        "cable",
        "mooring line",
        "substation",
        "export cable",
        "cost",
        "diameter",
        "hub height",
        "loss",
        "voltage",
        "thrust",
        "power",
        "material",
        "steel",
        "balast",
        "resistance",
        "reactor",
      ]}
      id="search-components"
      icon={<ComponentsIcon />}
      onSelect={() => {
        setModalType({
          modalType: FeatureSettingsModalTypeV2,
        }),
          Mixpanel.track("Open full screen modal", {
            type: FeatureSettingsModalTypeV2,
          });
      }}
    >
      <Tooltip
        theme="light"
        text={readOnlyMode ? DISABLED_IN_READ_ONLY_MODE : "Settings"}
        position="bottom"
        disabled={!readOnlyMode}
      >
        <MiddleItemWrapper
          active={modalTypeOpen?.modalType === FeatureSettingsModalTypeV2}
          disabled={readOnlyMode}
          onClick={() => {
            setModalType((curr) =>
              curr?.modalType !== FeatureSettingsModalTypeV2
                ? {
                    modalType: FeatureSettingsModalTypeV2,
                  }
                : undefined,
            );
            Mixpanel.track("Open full screen modal", {
              type: FeatureSettingsModalTypeV2,
            });
          }}
        >
          <RoundIcon fillOrStroke="fill">
            <ComponentsIcon />
          </RoundIcon>
          Components
        </MiddleItemWrapper>
      </Tooltip>
    </SearchWrapper>
  );
};

export default ComponentsModalButton;
