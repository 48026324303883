/// <reference types="vite-plugin-svgr/client" />
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { SUB_AREA_PROPERTY_TYPE } from "../../constants/division";
import { PARK_PROPERTY_TYPE } from "../../constants/park";
import {
  GenerateFoundationsMenuType,
  TURBINE_PROPERTY_TYPE,
} from "../../constants/projectMapView";
import { useClickOutside } from "../../hooks/useClickOutside";
import {
  SubAreaFeature,
  ParkFeature,
  TurbineFeature,
} from "../../types/feature";
import { useDrawMode } from "components/MapControls/useActivateDrawMode";
import { CSSProperties } from "styled-components";
import {
  allFoundationTypesSelector,
  defaultFoundations,
  getMostCommonFoundationIdInZone,
  isFixedFoundationSelector,
  isFloatingFoundationSelector,
} from "../../state/foundations";
import { getTurbinesSelectorFamily } from "../../state/layout";
import { getParkFeatureSelectorFamily, inPark } from "../../state/park";
import { currentSelectedProjectFeatures } from "../../state/selection";
import { SkeletonText } from "../Loading/Skeleton";
import { pointInPolygon } from "../../utils/geometry";
import {
  isAnchor,
  isDefined,
  isSubArea,
  isMooringLine,
  isTurbine,
} from "../../utils/predicates";
import { allEqual, count, dedup } from "../../utils/utils";
import { Column } from "../General/Layout";
import { MenuFrame } from "../MenuPopup/CloseableMenuPopup";
import { projectFeaturesSelector } from "../ProjectElements/state";
import { useProjectElementsCrud } from "../ProjectElements/useProjectElementsCrud";
import { previewMooringAndFoundationState } from "./state";
import { parkIdSelector, projectIdSelector } from "state/pathParams";
import { MooringInner } from "./MooringInner";
import { FixedFoundationInner } from "./FixedFoundationInner";
import { useBathymetryRaster } from "hooks/bathymetry";
import { mapRefAtom } from "state/map";
import {
  generateFoundationWarningPropertyName,
  turbineSourceId,
} from "components/Mapbox/constants";
import {
  featureTypeToReadableName,
  getFixedTurbines,
  getFloatingTurbines,
} from "components/GenerateFoundationsAndAnchors/utils";
import { FoundationWarnings } from "components/GenerateFoundationsAndAnchors/shared";
import { Raster } from "types/raster";
import useSelectionInMap from "hooks/useSelectionInMap";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useToast } from "hooks/useToast";
import { FoundationSettings } from "./FoundationSettings";
import {
  ARTICLE_FOUNDATION_STATS,
  HelpLink,
} from "components/HelpTooltip/HelpTooltip";
import { useShowScrollShadow } from "hooks/useShowScrollShadow";

export type Mode =
  | { mode: "zone"; subAreas: SubAreaFeature[] }
  | { mode: "turbines"; turbines: TurbineFeature[] }
  | { mode: "park" }
  | { mode: "illegal" };

const columnStyle: CSSProperties = {
  overflowY: "auto",
};

const Inner = ({ park, raster }: { park: ParkFeature; raster: Raster }) => {
  const selection = useRecoilValue(currentSelectedProjectFeatures);
  const [live, setLive] = useState(false);
  const map = useRecoilValue(mapRefAtom);
  const { setCurrentSelectionArray } = useSelectionInMap();
  const { scrollBodyRef, forceCheck } = useShowScrollShadow(true);

  const mode: Mode = useMemo(() => {
    if (selection.length === 0) return { mode: "park" };

    const selectedTypes = selection.map((f) => f.properties.type);
    const allEqualTypes = allEqual(selectedTypes);
    if (!allEqualTypes) return { mode: "illegal" };

    switch (selectedTypes[0]) {
      case SUB_AREA_PROPERTY_TYPE:
        return {
          mode: "zone",
          subAreas: selection.filter(isSubArea),
        };
      case TURBINE_PROPERTY_TYPE:
        return {
          mode: "turbines",
          turbines: selection.filter(isTurbine),
        };
      case PARK_PROPERTY_TYPE:
        return {
          mode: "park",
        };
      default:
        return { mode: "illegal" };
    }
  }, [selection]);

  const selectedTypeCounts = count(selection.map((f) => f.properties.type));
  const selectedTypes = [...selectedTypeCounts.keys()];
  selectedTypes.sort();
  if (selectedTypes.length === 0) selectedTypes.push(PARK_PROPERTY_TYPE);

  const selectedTypesReadable = selectedTypes.map((t) => {
    const count = selectedTypeCounts.get(t) ?? 0;
    const name = featureTypeToReadableName(t);
    if (1 < count) return `${count} ${name}s`;
    return `${name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()}`;
  });

  const selectedTypesString =
    1 < selectedTypesReadable.length
      ? selectedTypesReadable.slice(0, -1).join(", ") +
        " and " +
        selectedTypesReadable.slice(-1)
      : selectedTypesReadable.join(", ");

  const allFoundationTypes = useRecoilValue(allFoundationTypesSelector).filter(
    (t) => !t.archived,
  );

  const parkFoundationId = useRecoilValue(
    getMostCommonFoundationIdInZone({ parkId: park.id }),
  );
  const [currentFoundationId, setCurrentFoundationId] =
    useState<string>(parkFoundationId);

  const currentFoundation = allFoundationTypes.find(
    (f) => f.id === currentFoundationId,
  );

  useEffect(() => {
    forceCheck();
  }, [forceCheck, currentFoundationId]);

  if (!currentFoundation) {
    setCurrentFoundationId(defaultFoundations[0].id);
  }

  const allTurbines = useRecoilValue(
    getTurbinesSelectorFamily({ parkId: park.id }),
  );

  const getIsFixedFoundation = useRecoilCallback(
    ({ snapshot }) =>
      (foundationId: string) => {
        return snapshot
          .getLoadable(
            isFixedFoundationSelector({ foundationTypeId: foundationId }),
          )
          .getValue();
      },
    [],
  );

  const getIsFloatingFoundation = useRecoilCallback(
    ({ snapshot }) =>
      (foundationId: string) => {
        return snapshot
          .getLoadable(
            isFloatingFoundationSelector({ foundationTypeId: foundationId }),
          )
          .getValue();
      },
    [],
  );

  const { warning } = useToast();
  const { update: updateFeatures } = useProjectElementsCrud();
  const saveOnExitAndApplyCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const previewState = await snapshot.getPromise(
          previewMooringAndFoundationState,
        );
        if (!previewState) return;

        if (previewState.preview.partialTurbines?.length) {
          const n = previewState.preview.partialTurbines.length;
          warning(`${n} turbines did not get all anchors`);
        }

        // Remove all mooring lines and anchors that are in this park. Everything we want to keep is in `p`.
        // The same with turbines, because we might have changed the foundation type.

        const allFeatures = await snapshot.getPromise(projectFeaturesSelector);
        const featuresInPark = allFeatures.filter(inPark(park.id));
        const mooringLines = featuresInPark.filter(isMooringLine);
        const anchors = featuresInPark.filter(isAnchor);

        const linesToRemove = mooringLines.filter(
          (ml) =>
            !previewState.existing.mooringLines.find((m) => ml.id === m.id),
        );
        const anchorsToRemove = anchors.filter(
          (an) => !previewState.existing.anchors.find((a) => an.id === a.id),
        );
        const turbinesWithNewFoundation = previewState.preview.foundations
          .map(({ turbineId, foundationId }) => {
            const t = allFeatures
              .filter(isTurbine)
              .find((f) => f.id === turbineId);
            if (!t) return undefined;
            return {
              ...t,
              properties: {
                ...t.properties,
                foundationId,
              },
            };
          })
          .filter(isDefined);

        const args = {
          add: [
            ...previewState.preview.anchors,
            ...previewState.preview.mooringLines,
          ],
          update: turbinesWithNewFoundation,
          remove: [
            ...linesToRemove.map((f) => f.id),
            ...anchorsToRemove.map((f) => f.id),
          ],
        };
        await updateFeatures(args);
        set(previewMooringAndFoundationState, undefined);
      },
    [park.id, warning, updateFeatures],
  );

  useEffect(() => {
    return () => {
      saveOnExitAndApplyCallback();
      setLive?.(false);
    };
  }, [saveOnExitAndApplyCallback, setLive]);

  const selectedTurbines = useMemo(
    () =>
      mode.mode === "park"
        ? allTurbines
        : mode.mode === "zone"
          ? allTurbines.filter((t) =>
              mode.subAreas.some((z) => pointInPolygon(t.geometry, z.geometry)),
            )
          : mode.mode === "turbines"
            ? mode.turbines
            : [],
    [allTurbines, mode],
  );

  const floatingTurbines = getFloatingTurbines({
    turbineFeatures: selectedTurbines,
    raster,
    currentFoundation,
  });
  const fixedTurbines = getFixedTurbines({
    turbineFeatures: selectedTurbines,
    raster,
    currentFoundation,
  });

  const turbinesThatDidNotGetFundament = useMemo(() => {
    const turbinesToGenerateFor = getIsFloatingFoundation(currentFoundationId)
      ? floatingTurbines
      : fixedTurbines;
    return selectedTurbines.filter(
      (st) => !turbinesToGenerateFor.map((ft) => ft.id).includes(st.id),
    );
  }, [
    currentFoundationId,
    floatingTurbines,
    fixedTurbines,
    selectedTurbines,
    getIsFloatingFoundation,
  ]);

  useEffect(() => {
    if (!map) return undefined;
    turbinesThatDidNotGetFundament.forEach((t) => {
      map.setFeatureState(
        { source: turbineSourceId, id: t.id },
        { [generateFoundationWarningPropertyName]: true },
      );
    });
    return () => {
      turbinesThatDidNotGetFundament.forEach((t) => {
        map.removeFeatureState(
          { source: turbineSourceId, id: t.id },
          generateFoundationWarningPropertyName,
        );
      });
    };
  }, [map, turbinesThatDidNotGetFundament]);

  const onSelectErroneousTurbinesClick = useCallback(() => {
    setCurrentSelectionArray(
      turbinesThatDidNotGetFundament.map((turbine) => turbine.id),
    );
  }, [setCurrentSelectionArray, turbinesThatDidNotGetFundament]);

  const unavaiFloatingTurbines = selectedTurbines.filter(
    (t) => !floatingTurbines.includes(t),
  );
  const unavaiFixedTurbines = selectedTurbines.filter(
    (t) => !fixedTurbines.includes(t),
  );

  if (mode.mode === "illegal") {
    const selectedTypes = dedup(selection.map((f) => f.properties.type));
    selectedTypes.sort();
    return (
      <div>
        <SimpleAlert
          text={`To generate foundations and mooring, either select a park, a zone, or a set of turbines. You have selected ${selectedTypesString}.`}
          type={"error"}
        />
      </div>
    );
  }

  if (selectedTurbines.length === 0) {
    return (
      <div>
        <SimpleAlert text={"No turbines selected"} type={"error"} />
      </div>
    );
  }

  const isFloatingFoundation = getIsFloatingFoundation(currentFoundationId);
  const isFixedFoundation = getIsFixedFoundation(currentFoundationId);

  return (
    <Column ref={scrollBodyRef} style={columnStyle}>
      <FoundationSettings
        currentFoundationId={currentFoundationId}
        setCurrentFoundationId={setCurrentFoundationId}
      />
      <FoundationWarnings
        isFloatingFoundation={isFloatingFoundation}
        isFixedFoundation={isFixedFoundation}
        floatingTurbines={floatingTurbines}
        fixedTurbines={fixedTurbines}
        selectedTurbines={selectedTurbines}
        foundation={currentFoundation}
        onSelectTurbinesClick={onSelectErroneousTurbinesClick}
      />
      {isFloatingFoundation && floatingTurbines.length > 0 && (
        <MooringInner
          live={live}
          setLive={setLive}
          park={park}
          mode={mode}
          raster={raster}
          currentFoundationId={currentFoundationId}
          floatingTurbines={floatingTurbines}
          unavailableTurbines={unavaiFloatingTurbines}
          saveOnExitAndApplyCallback={saveOnExitAndApplyCallback}
        />
      )}
      {isFixedFoundation && fixedTurbines.length > 0 && (
        <FixedFoundationInner
          park={park}
          mode={mode}
          fixedTurbines={fixedTurbines}
          unavailableTurbines={unavaiFixedTurbines}
          selectedTurbines={selectedTurbines}
          currentFoundationId={currentFoundationId}
        />
      )}
    </Column>
  );
};

const Outer = ({ park }: { park: ParkFeature }) => {
  const projectId = useRecoilValue(projectIdSelector) ?? "";
  const bathymetryRaster = useBathymetryRaster({
    projectId,
    featureId: park.id,
  });
  const raster = bathymetryRaster.getValue();

  if (!raster) {
    return (
      <div>
        <SimpleAlert text={"Failed to load bathymetry"} type={"error"} />
      </div>
    );
  }

  return <Inner park={park} raster={raster} />;
};

export const GenerateFoundationsMenu = () => {
  const [, setLeftMenuActiveMode] = useDrawMode();
  const parkId = useRecoilValue(parkIdSelector);
  const park = useRecoilValue(
    getParkFeatureSelectorFamily({ parkId: parkId ?? "" }),
  );
  const [layoutControlActive] = useDrawMode();

  const popupRef = useRef<HTMLDivElement>(null);
  const [, setLayoutControlActive] = useDrawMode();

  useClickOutside(
    popupRef,
    () => {
      setLayoutControlActive(undefined);
    },
    (target) => {
      if (!(target instanceof HTMLElement)) {
        return false;
      }
      if (
        Boolean(
          target.id === "mooring-submenu-double-anchors" ||
            target.id === "mooring-submenu-mooring-angles" ||
            target.id === "foundation-submenu-monopile-wizard",
        )
      ) {
        return true;
      }
      if (target.id === `button-${GenerateFoundationsMenuType}`) return true;
      return Boolean(target.dataset?.["ignoreInPopup"]);
    },
    { ignoreDragClicks: true },
  );

  if (!park || layoutControlActive !== GenerateFoundationsMenuType) return null;

  return (
    <MenuFrame
      id={GenerateFoundationsMenuType}
      title="Generate foundations"
      ref={popupRef}
      icon={<HelpLink article={ARTICLE_FOUNDATION_STATS} />}
      onExit={() => setLeftMenuActiveMode(undefined)}
      style={{
        maxHeight: "calc(100vh - 20rem)",
        boxSizing: "border-box",
      }}
    >
      <React.Suspense
        fallback={
          <Column>
            <SkeletonText
              style={{ height: "2rem" }}
              text="Loading bathymetry"
            />
          </Column>
        }
      >
        <Outer park={park} />
      </React.Suspense>
    </MenuFrame>
  );
};
