// DnDGrid.tsx
import React, { useCallback, useState } from "react";
import DnDRow from "./DnDRow";
import { DnDElement, DnDRow as DnDRowType, IndicatorPosition } from "./types";
import { Grid } from "./DnDStyles";
import { isDefined } from "../../../utils/predicates";
import { findElementAndIndex, removeElement } from "./utils";
import { v4 as uuid } from "uuid";
import { RowDrop } from "./RowResizer";

type DnDGridProps = {
  rows: DnDRowType[];
  setRows: React.Dispatch<React.SetStateAction<DnDRowType[]>>;
  disabled?: boolean;
};

const DnDGrid: React.FC<DnDGridProps> = ({ rows, setRows, disabled }) => {
  const [indicatorPosition, setIndicatorPosition] =
    useState<IndicatorPosition>(null);

  const updateIndicatorPosition = (
    elementId: string | null,
    after: boolean,
  ) => {
    setIndicatorPosition(elementId ? { elementId, after } : null);
  };

  const moveElement = useCallback(
    (draggedId: string, targetElementId: string | null, after: boolean) => {
      if (disabled) return;
      setRows((rows) => {
        let draggedRowIndex: number | null = null;
        let draggedElement: DnDElement | null = null;
        let draggedElementIndex: number | null = null;
        let targetRowIndex: number | null = null;
        let targetElementIndex: number | null = null;

        const updatedRows = rows
          .map((row, rowIndex) => {
            const targetIndex = row.elements.findIndex(
              (element) => element.id === targetElementId,
            );
            if (targetIndex > -1) {
              targetRowIndex = rowIndex;
              targetElementIndex = after ? targetIndex + 1 : targetIndex;
            }

            const elementIndex = row.elements.findIndex(
              (element) => element.id === draggedId,
            );

            if (elementIndex > -1) {
              draggedElement = { ...row.elements[elementIndex] };
              draggedElementIndex = elementIndex;
              draggedRowIndex = rowIndex;

              const newElements = [
                ...row.elements.slice(0, elementIndex),
                ...row.elements.slice(elementIndex + 1),
              ];

              if (newElements.length === 0) {
                if (targetRowIndex && targetRowIndex > rowIndex) {
                  targetRowIndex--;
                }
                return undefined;
              }

              return { ...row, elements: newElements };
            }

            return row;
          })
          .filter(isDefined);

        if (
          draggedElement === null ||
          draggedRowIndex === null ||
          targetRowIndex === null ||
          draggedElementIndex === null ||
          targetElementIndex === null
        ) {
          return rows;
        }

        // If the dragged element was before the target position in the same row,
        // decrease the targetElementIndex by 1.
        if (
          draggedRowIndex === targetRowIndex &&
          draggedElementIndex < targetElementIndex
        ) {
          targetElementIndex--;
        }

        if (
          rows.length !== updatedRows.length &&
          draggedRowIndex < targetRowIndex
        ) {
          targetRowIndex--;
        }

        // Insert the dragged element at the new location.
        const targetRow = updatedRows[targetRowIndex];
        const newElements = [
          ...targetRow.elements.slice(0, targetElementIndex),
          draggedElement,
          ...targetRow.elements.slice(targetElementIndex),
        ];

        updatedRows[targetRowIndex] = { ...targetRow, elements: newElements };

        return updatedRows;
      });
    },
    [setRows, disabled],
  );

  const onCreateNewRow = useCallback(
    (draggedId: string, targetRowId?: string) => {
      if (disabled) return;
      setRows((rows) => {
        let targetRowIndex = targetRowId
          ? rows.findIndex((r) => r.id === targetRowId) + 1
          : 0;

        const copiedRows = [...rows];
        // Find the dragged element in the current rows.
        const {
          element: draggedElement,
          rowIndex,
          elementIndex,
        } = findElementAndIndex(draggedId, copiedRows);

        if (!draggedElement) return rows;

        // Remove the dragged element from its original position.
        const updatedRows = removeElement(copiedRows, rowIndex, elementIndex);
        if (
          updatedRows.length !== copiedRows.length &&
          rowIndex < targetRowIndex
        ) {
          targetRowIndex--;
        }

        // Create a new row with the dragged element.
        const newRow: DnDRowType = {
          id: uuid(),
          height: 200,
          elements: [draggedElement],
        };

        if (targetRowIndex === -1) return updatedRows;

        updatedRows.splice(targetRowIndex, 0, newRow);

        return updatedRows;
      });
    },
    [setRows, disabled],
  );

  const adjustRowHeight = useCallback(
    (rowId: string, newHeight: number) => {
      if (disabled) return;
      setRows((prevRows) => {
        const updatedRows = prevRows.map((row) => {
          if (row.id === rowId) {
            return { ...row, height: newHeight };
          }
          return row;
        });
        return updatedRows;
      });
    },
    [setRows, disabled],
  );

  return (
    <>
      <Grid>
        <RowDrop
          onDrop={(droppedElementId) => onCreateNewRow(droppedElementId)}
          disabled={disabled}
        />
        {rows.map((row) => (
          <DnDRow
            key={row.id}
            row={row}
            moveElement={moveElement}
            indicatorPosition={indicatorPosition}
            updateIndicatorPosition={updateIndicatorPosition}
            adjustRowHeight={adjustRowHeight}
            onCreateNewRow={(droppedElementId) =>
              onCreateNewRow(droppedElementId, row.id)
            }
            disabled={disabled}
          />
        ))}
      </Grid>
    </>
  );
};

export default DnDGrid;
