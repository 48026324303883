export const DrawLineStringMenuType = "draw-line-string-menu-type";
export const DrawParkMenuType = "draw-park-menu-type";
export const DrawPolygonMenuType = "draw-polygon-menu-type";
export const DrawCircleMenuType = "draw-circle-menu-type";
export const DrawRectangleMenuType = "draw-rectangle-menu-type";
export const DrawPointMenuType = "draw-point-menu-type";
export const DrawPortMenuType = "draw-port-menu-type";
export const DrawViewPointMenuType = "draw-view-point-menu-type";
export const DrawMooringLineMenuType = "draw-mooring-line-menu-type";
export const DrawAnchorMenuType = "draw-anchor-menu-type";
export const DrawExistingTurbineMenuType = "draw-existing-turbine-menu-type";

export const turbinesEditableSourceId = "turbines-editable-layout-source";
export const turbinesEditableCircleLayerId =
  "turbines-editable-layout-circle-id";
export const turbinesEditableBufferSourceId =
  "turbines-editable-buffer-layout-source";

export const turbineNameSymbolLayerId = "turbines-editable-name-layer";
export const turbineNameSymbolSourceId = "turbines-editable-name-source";
