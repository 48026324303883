import SphericalMercator from "@mapbox/sphericalmercator";

export const calculateSample = (
  lng: number,
  lat: number,
  zoom: number,
  x: number,
  y: number,
  tileSize: number,
  sample: ImageData,
  merc: SphericalMercator,
): [number, number, number, number] => {
  const pixelCoords = merc.px([lng, lat], zoom);

  const localPixelCoords = [
    Math.min(pixelCoords[0] - x * tileSize, tileSize - 1), // Unsure why some local coordinates are larger than tile size, should be investigated
    Math.min(pixelCoords[1] - y * tileSize, tileSize - 1),
  ];

  const startPoint = (localPixelCoords[0] + localPixelCoords[1] * tileSize) * 4;

  return [
    sample.data[startPoint],
    sample.data[startPoint + 1],
    sample.data[startPoint + 2],
    sample.data[startPoint + 3],
  ];
};
