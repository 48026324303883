import {
  getBeforeLayer,
  cableLossesSourceId,
  cableLossesLayerId,
} from "components/Mapbox/utils";
import { SymbolLayer } from "mapbox-gl";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { mapRefAtom } from "state/map";
import { isDefined } from "utils/predicates";
import {
  ProdId,
  getParkId,
  getBranchId,
  getInterArrayLossesPerCable,
  getConfiguration,
  analysisOverrideInputAtomFamily,
} from "./state";
import { showCableLossesAtom } from "state/windStatistics";
import {
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";
import { getCablesInBranchSelectorFamily } from "state/cable";
import { HOURS_PER_YEAR } from "@constants/production";
import { getCableLossPaint } from "components/Cabling/CablingMapController/Render";
import { colors } from "styles/colors";
import LineString from "components/MapFeatures/LineString";

const cableLossSymbolLayer: Omit<SymbolLayer, "id" | "source"> = {
  type: "symbol",
  minzoom: 9,
  layout: {
    "text-field": ["get", "value"],
    "text-offset": [0, 0.75],
    "symbol-placement": "line",
    "text-size": 8,
    "text-anchor": "center",
  },
};

const Inner = ({
  map,
  productionId,
}: {
  map: mapboxgl.Map;
  productionId: ProdId;
}) => {
  const branchId = useRecoilValue(getBranchId(productionId));
  const parkId = useRecoilValue(getParkId(productionId));
  const cables = useRecoilValue(
    getCablesInBranchSelectorFamily({ parkId, branchId }),
  );
  const interArrayLossPerCable = useRecoilValue(
    getInterArrayLossesPerCable(productionId),
  );

  const cablePaint = interArrayLossPerCable
    ? getCableLossPaint({ cables, interArrayLossPerCable })
    : {
        "line-color": colors.secondaryText,
        "line-width": 3,
      };

  const lossFeatures = useMemo(() => {
    if (!map || !interArrayLossPerCable) return [];
    return cables
      .map((c) => {
        const annualLossGWh = interArrayLossPerCable[c.id]
          ? (interArrayLossPerCable[c.id] * HOURS_PER_YEAR) / 1000
          : undefined;
        if (annualLossGWh === undefined) return undefined;
        return {
          ...c,
          properties: {
            value: `${annualLossGWh.toFixed(2)} GWh`,
          },
          cableId: c.id,
        };
      })
      .filter(isDefined);
  }, [cables, interArrayLossPerCable, map]);

  return (
    <LineString
      features={lossFeatures}
      symbols={cableLossSymbolLayer}
      sourceId={cableLossesSourceId}
      layerId={cableLossesLayerId}
      map={map}
      paint={cablePaint}
      beforeLayer={getBeforeLayer(map, cableLossesLayerId)}
    />
  );
};

export const AnnualCableLosses = ErrorBoundaryWrapper(
  ({ id }: { id: ProdId }) => {
    const map = useRecoilValue(mapRefAtom);
    const showCableLosses = useRecoilValue(showCableLossesAtom);
    const configuration = useRecoilValue(getConfiguration(id));
    const { selectedSubAreas } = useRecoilValue(
      analysisOverrideInputAtomFamily(id),
    );
    const hasSelectedZones = (selectedSubAreas?.length ?? 0) !== 0;

    if (
      !map ||
      !showCableLosses ||
      !configuration.electrical.interArrayCableLoss ||
      hasSelectedZones
    )
      return null;

    return <Inner map={map} productionId={id} />;
  },
  () => null,
  ScreamOnError,
);
