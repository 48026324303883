import ShorelineDistanceSlider from "../ShorelineDistanceSlider/ShorelineDistanceSlider";
import DepthSlider from "../DepthSlider/DepthSlider";
import { MenuFrame } from "../MenuPopup/CloseableMenuPopup";
import { useRecoilState } from "recoil";
import { lowerRightMenuActiveModeAtom } from "../../state/layer";
import Checkbox from "../General/Checkbox";
import {
  enableFilterLayersAtom,
  renderDepthFilterAtom,
  renderShoreDistanceAtom,
  renderWindSpeedAtom,
} from "../../state/filter";
import WindSpeedFilterSlider from "../WindSpeedFilterSlider/WindSpeedFilterSlider";
import { Row } from "../General/Layout";
import { useEffect } from "react";
import {
  InputTitle,
  InputTitleWrapper,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import Toggle, { ToggleSize } from "components/General/Toggle";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";

export const FilterMenuType = "FilterMenu";

const FilterInput = () => {
  const [lowerRightActiveMode, setLowerRightActiveMode] = useRecoilState(
    lowerRightMenuActiveModeAtom,
  );

  const [enableFilterLayers, setEnableFilterLayers] = useRecoilState(
    enableFilterLayersAtom,
  );
  const [showDepth, setShowDepth] = useRecoilState(renderDepthFilterAtom);
  const [showShoreline, setShowShoreline] = useRecoilState(
    renderShoreDistanceAtom,
  );
  const [showWindSpeed, setShowWindSpeed] = useRecoilState(renderWindSpeedAtom);

  const [enabled, setEnabled] = useRecoilState(enableFilterLayersAtom);
  useEffect(() => {
    if (enabled === undefined) setEnabled(true);
  }, [enabled, setEnabled]);

  if (lowerRightActiveMode !== FilterMenuType) return null;

  return (
    <MenuFrame
      title="Filters"
      onExit={() => setLowerRightActiveMode(undefined)}
      style={{ gap: "1.2rem" }}
    >
      <Row>
        <Toggle
          size={ToggleSize.SMALL}
          checked={enableFilterLayers ?? false}
          onChange={(e) => setEnableFilterLayers(e.target.checked)}
        />
        <InputTitle>Filter layers</InputTitle>
      </Row>
      <SubtitleWithLine text="Filter settings" />

      <Row style={{ paddingTop: "0.8rem" }}>
        <Checkbox
          label="Depth (m)"
          labelPlacement="after"
          disabled={!enabled}
          checked={showDepth}
          onChange={(e) => setShowDepth(e.target.checked)}
        />
        <HelpTooltip text={"Source: Gebco"} size={10} />
      </Row>
      <DepthSlider disabled={!showDepth || !enabled} />

      <Checkbox
        style={{ paddingTop: "0.8rem" }}
        label="Distance to shore (km)"
        labelPlacement="after"
        disabled={!enabled}
        checked={showShoreline}
        onChange={(e) => setShowShoreline(e.target.checked)}
      />

      <ShorelineDistanceSlider disabled={!showShoreline || !enabled} />
      <InputTitleWrapper>
        <Checkbox
          style={{ paddingTop: "0.8rem" }}
          label="Windspeed (m/s)"
          labelPlacement="after"
          disabled={!enabled}
          checked={showWindSpeed}
          onChange={(e) => setShowWindSpeed(e.target.checked)}
        />
      </InputTitleWrapper>

      <WindSpeedFilterSlider disabled={!showWindSpeed || !enabled} />
    </MenuFrame>
  );
};

export default FilterInput;
