import { useRecoilCallback } from "recoil";
import { ABLY_RESOLVED_TOGGLE_FILTER } from "../../../state/ably";
import { useTypedPath } from "../../../state/pathParams";
import { resolveThread } from "../service";
import { threadsAtomFamily } from "../state";
import { initializeAndSet } from "./useReplyReactionCrud";
import { useAblyPublish } from "../../../hooks/useAblyPublish";

export const useResolveThreadCrud = () => {
  const { projectId: projectNodeId, branchId } = useTypedPath(
    "projectId",
    "branchId",
  );

  const ablyPublish = useAblyPublish();

  const localPut = useRecoilCallback(
    ({ set, snapshot }) =>
      async (branchId: string, threadId: string, resolved: boolean) => {
        if (!projectNodeId) return;
        initializeAndSet(
          snapshot,
          set,
          threadsAtomFamily({ nodeId: projectNodeId, branchId: branchId }),
          (cur) => {
            return cur.map((c) =>
              c.threadId === threadId ? { ...c, resolved: resolved } : c,
            );
          },
        );
      },
    [projectNodeId],
  );

  const put = useRecoilCallback(
    ({ set }) =>
      async (threadId: string, resolved: boolean) => {
        if (!projectNodeId) return;
        set(threadsAtomFamily({ nodeId: projectNodeId, branchId }), (cur) => {
          return cur.map((c) =>
            c.threadId === threadId ? { ...c, resolved: resolved } : c,
          );
        });
        await localPut(branchId, threadId, resolved);

        const res = await resolveThread(
          projectNodeId,
          branchId,
          threadId,
          resolved,
        );
        ablyPublish(`${projectNodeId}:all`, ABLY_RESOLVED_TOGGLE_FILTER, {
          nodeId: projectNodeId,
          branchId,
          threadId,
          resolved,
        });
        return res;
      },
    [ablyPublish, projectNodeId, branchId, localPut],
  );

  return { put, localPut };
};
