import { useRecoilCallback, useRecoilState } from "recoil";
import {
  PostOptProblemArgs,
  deleteOptProblem,
  listOptProblems,
  listResultsForProblem,
  postOptProblem,
} from "../../functions/optimize";
import { sendWarning } from "../../utils/sentry";
import { initializeAndSet } from "../Comments/hooks/useReplyReactionCrud";
import {
  Ids,
  Pids,
  listOptProblemsAtomFamily,
  problemResultsAtomFamily,
  recentlyDeletedProblemAtom,
} from "./state";

export const useOptimizationCrud = () => {
  const [, setRecentlyDeletedProblem] = useRecoilState(
    recentlyDeletedProblemAtom,
  );

  const list = useRecoilCallback(
    ({ set }) =>
      async (ids: Ids) => {
        const problems = await listOptProblems(ids);
        set(listOptProblemsAtomFamily(ids), problems);
        return problems;
      },
    [],
  );

  const deleteProblem = useRecoilCallback(
    () => async (pids: Pids, recentlyDeletedProblem: string[]) => {
      const res = await deleteOptProblem(pids);
      if (res.success) {
        setRecentlyDeletedProblem([...recentlyDeletedProblem, pids.id]);
      }
      return res;
    },
    [setRecentlyDeletedProblem],
  );

  const create = useRecoilCallback(
    ({ set, snapshot }) =>
      async ({ ids, args }: { ids: Ids; args: PostOptProblemArgs }) => {
        const res = await postOptProblem({
          ids,
          args,
        }).catch((e) => {
          if (e instanceof TypeError && e.message === "Failed to fetch") return;
          sendWarning("postOptProblem endpoint failed", {
            e,
            ids,
            args: {
              numberOfTurbines: args.numberOfTurbines,
              includeEdge: args.includeEdge,
              internal: args.internal,
            },
          });
        });

        if (res) {
          initializeAndSet(
            snapshot,
            set,
            listOptProblemsAtomFamily(ids),
            (curr) => [
              ...curr,
              {
                ...res,
                numberOfTurbines: args.numberOfTurbines,
              },
            ],
          );
        }
        // If creating failed, it might have timed out.  List again to see if we
        // get back a new one.
        else await list(ids);

        return res;
      },
    [list],
  );

  const getResults = useRecoilCallback(({ set }) => async (pids: Pids) => {
    const results = await listResultsForProblem(pids).catch(() => undefined);
    set(problemResultsAtomFamily(pids), results);
    return results;
  });

  return {
    create,
    list,
    getResults,
    deleteProblem,
  };
};
