import styled from "styled-components";
import Spinner from "../../icons/spinner/Spinner";

export const MiniSpinner = styled(Spinner)`
  width: 10px;
  height: 10px;
`;

export function getFormatGWUnit(gw: number) {
  if (gw < 10) {
    return "MW";
  }
  return "GW";
}

export function formatGWConditional(gw: number, clean?: boolean) {
  let decimals = 0;
  if (gw < 1000) {
    decimals = 1;
  }
  if (gw < 100) {
    decimals = 2;
  }
  if (gw < 10) {
    const mw = gw * 1000;
    return `${Math.round(mw).toFixed(1)}${clean ? "" : " MW"}`;
  }
  const decimalHelper = Math.pow(10, decimals);
  return `${(Math.round(gw * decimalHelper) / decimalHelper).toFixed(
    decimals,
  )}${clean ? "" : " GW"}`;
}

export function formatGWhConditional(gwh: number, clean?: boolean) {
  const unit = clean ? "" : "h";
  return formatGWConditional(gwh, clean) + unit;
}

export function formatGW(gw: number, clean?: boolean) {
  let decimals = 0;
  if (gw < 1000) {
    decimals = 1;
  }
  if (gw < 100) {
    decimals = 2;
  }
  if (gw < 10) {
    decimals = 3;
  }
  const decimalHelper = Math.pow(10, decimals);
  return `${(Math.round(gw * decimalHelper) / decimalHelper).toFixed(
    decimals,
  )}${clean ? "" : " GW"}`;
}

export function formatGWh(gwh: number, clean?: boolean) {
  const unit = clean ? "" : "h";
  return formatGW(gwh, clean) + unit;
}

export function formatPercent(
  a: number,
  decimals: number = 1,
  clean?: boolean,
) {
  const decimalHelper = Math.pow(10, decimals);
  return `${(Math.round(a * 100 * decimalHelper) / decimalHelper).toFixed(
    decimals,
  )}${clean ? "" : "%"}`;
}

export function formatPercentDecimal(
  a: number,
  decimals: number = 3,
  clean?: boolean,
) {
  const decimalHelper = Math.pow(10, decimals);
  return `${(Math.round(a * decimalHelper) / decimalHelper).toFixed(decimals)}${
    clean ? "" : ""
  }`;
}

export function formatLossToPercent(loss: number) {
  const decimals = loss < 0.1 ? 2 : 1;
  const decimalHelper = Math.pow(10, decimals);
  return (Math.round(loss * 100 * decimalHelper) / decimalHelper).toFixed(
    decimals,
  );
}
export function formatLossToPercentDecimal(loss: number) {
  const decimals = loss < 0.1 ? 4 : 3;
  const decimalHelper = Math.pow(10, decimals);
  return (Math.round(loss * decimalHelper) / decimalHelper).toFixed(decimals);
}

export function formatElectricalLossToPercentDecimal(
  electricalLoss: number,
  clean?: boolean,
) {
  const decimals = electricalLoss < 0.1 ? 2 : 1;
  const decimalHelper = Math.pow(10, decimals);
  return (
    (Math.round(electricalLoss * 100 * decimalHelper) / decimalHelper).toFixed(
      decimals,
    ) + (clean ? "" : "%")
  );
}

export function formatDecimal(a: number, decimals: number = 1) {
  const decimalHelper = Math.pow(10, decimals);
  return `${(Math.round(a * decimalHelper) / decimalHelper).toFixed(decimals)}`;
}
