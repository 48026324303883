import { useEffect } from "react";
import {
  BufferGeometry,
  Mesh,
  MeshBasicMaterial,
  PerspectiveCamera,
  Scene,
  SphereGeometry,
  WebGLRenderer,
} from "three";
import {
  computeBoundsTree,
  disposeBoundsTree,
  acceleratedRaycast,
} from "three-mesh-bvh";
import { Sky } from "three/examples/jsm/objects/Sky";
import { Water } from "three/examples/jsm/objects/Water";
import { disposeObject } from "../utils";
import {
  createPerspectiveCamera,
  createRenderer,
  createScene,
  createSky,
  createSun,
  createWater,
  INITIAL_SUN_POS,
  updateSun,
} from "./utils";
import { useRecoilState } from "recoil";
import { threeCoreAtom } from "../../../state/viewToPark";

export type ThreeCore = {
  scene: Scene;
  renderer: WebGLRenderer;
  camera: PerspectiveCamera;
  water: Water;
  sun: Mesh<SphereGeometry, MeshBasicMaterial>;
  sky: Sky;
};

BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
Mesh.prototype.raycast = acceleratedRaycast;

let _renderer: WebGLRenderer | undefined = undefined;
const getRenderer = () => {
  if (!_renderer) _renderer = createRenderer();
  return _renderer;
};

export default function useCreateThreeCore() {
  const [threeCore, setThreeCore] = useRecoilState(threeCoreAtom);

  useEffect(() => {
    const scene = createScene();
    const camera = createPerspectiveCamera();
    const water = createWater(scene);
    const sun = createSun();
    const sky = createSky();

    const renderer = getRenderer();
    updateSun(INITIAL_SUN_POS, sky, water, renderer, scene);

    setThreeCore({
      renderer,
      scene,
      camera,
      water,
      sun,
      sky,
    });

    return () => {
      setThreeCore(undefined);
      disposeObject(water);
      disposeObject(sun);
      disposeObject(sky);
      disposeObject(scene);
    };
  }, [setThreeCore]);

  return threeCore;
}
