import { defaultPointCircleRadius } from "components/MapFeatures/expressionUtils";
import {
  DEFAULT_IN_FOCUS_OPACITY,
  DEFAULT_NOT_IN_FOCUS_OPACITY,
  DEFAULT_SELECTED_OPACITY,
  lockedPropertyName,
} from "@constants/canvas";
import { CirclePaint } from "mapbox-gl";
import { useEffect } from "react";
import { colors } from "styles/colors";
import { AnchorFeature } from "types/feature";
import { safeRemoveLayer } from "utils/map";
import {
  MIN_MOORING_VISIBLE_ZOOM,
  editmodePropertyName,
  ghostPropertyName,
} from "./constants";
import { addLayer, removeCodepointsFromFeatures } from "./utils";
import { anchorSourceId, anchorLayerId } from "./constants";

const anchorPaint: CirclePaint = {
  "circle-radius": defaultPointCircleRadius,
  "circle-color": [
    "case",
    ["==", ["get", editmodePropertyName], true],
    colors.anchorPreview,
    ["boolean", ["feature-state", "error"], false],
    colors.redAlert,
    ["boolean", ["feature-state", "warning"], false],
    colors.yellow,
    colors.anchor,
  ],
  "circle-opacity": [
    "case",
    ["==", ["get", editmodePropertyName], true],
    1.0,
    ["==", ["get", ghostPropertyName], true],
    0.1,
    [
      "all",
      [
        "boolean",
        ["feature-state", "hover"],
        ["feature-state", "selected"],
        false,
      ],
      ["==", ["get", lockedPropertyName], false],
    ],
    DEFAULT_SELECTED_OPACITY,
    ["boolean", ["feature-state", "inFocus"], false],
    DEFAULT_IN_FOCUS_OPACITY,
    DEFAULT_NOT_IN_FOCUS_OPACITY,
  ],
  "circle-stroke-color": [
    "case",
    ["==", ["get", editmodePropertyName], true],
    colors.lightText,
    ["==", ["get", lockedPropertyName], true],
    colors.lockedFeatureOutline,
    ["!=", ["feature-state", "borderColor"], null],
    ["feature-state", "borderColor"],
    "#fff",
  ],
  "circle-stroke-width": [
    "case",
    ["==", ["get", editmodePropertyName], true],
    3,
    ["==", ["get", lockedPropertyName], true],
    2,
    ["!=", ["feature-state", "borderColor"], null],
    2.0,
    ["boolean", ["feature-state", "hover"], false],
    ["case", ["boolean", ["feature-state", "selected"], false], 2.0, 1.0],
    ["boolean", ["feature-state", "selected"], false],
    2.0,
    0.0,
  ],
};

export const RenderAnchors = ({
  anchors,
  map,
}: {
  anchors: AnchorFeature[];
  map: mapboxgl.Map;
}) => {
  useEffect(() => {
    map.addSource(anchorSourceId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    return () => {
      safeRemoveLayer(map, anchorLayerId);
      map.removeSource(anchorSourceId);
    };
  }, [map]);

  useEffect(() => {
    addLayer(map, {
      id: anchorLayerId,
      type: "circle",
      source: anchorSourceId,
      paint: anchorPaint,
      minzoom: MIN_MOORING_VISIBLE_ZOOM,
    });
  }, [map]);

  useEffect(() => {
    const source = map.getSource(anchorSourceId);
    if (source?.type !== "geojson") return;
    source.setData({
      type: "FeatureCollection",
      features: removeCodepointsFromFeatures(anchors),
    });
  }, [map, anchors]);

  return null;
};
