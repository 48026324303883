import {
  admin_getNodesInOrganisation,
  postTopLevelFolderNode,
} from "./../../services/customerAPI";
import {
  MoveNode,
  moveNode,
  createProjectNode,
  NodeMutable,
  ProjectNodeInformation,
  isProjectNodeInformation,
  _Node,
  PostNode,
  getNodesInOrganisation,
  getTopLevelNodeForNodeId,
} from "../../services/customerAPI";
import {
  atomFamily,
  selectorFamily,
  useRecoilCallback,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { isLoaded, makeLoaded, makeLoading } from "../../types/Load";
import { scream } from "../../utils/sentry";
import {
  LoadNode,
  LoadingFolder,
  Node,
  deleteNode,
  postNode,
  putNode,
} from "../../services/customerAPI";
import { initializeAndSet } from "../Comments/hooks/useReplyReactionCrud";
import { organisationIdSelector } from "state/pathParams";
import useAblyRefreshToken from "hooks/useAblyRefreshToken";
import { userAllNodesAccessAtom } from "state/user";
import useExplainNodeMove from "./useExplainNodeMove";
import { MoveNodeModalAtom } from "components/Organisation/MoveNodeModal/state";
import { toastMessagesAtom } from "state/toast";
import { createTutorialProjectService } from "components/ProjectElements/service";

// ------------ Admin -----------------------
export const admin_nodesInOrgSelectorFamily = selectorFamily<
  Node[],
  { organisationId: string }
>({
  key: "admin_nodesInOrgSelectorFamily.default",
  get:
    ({ organisationId }) =>
    async () => {
      try {
        return await admin_getNodesInOrganisation(organisationId);
      } catch {
        return [];
      }
    },
});

export const admin_getNodesWithMissingParents = selectorFamily<
  Node[],
  { organisationId: string }
>({
  key: "admin_getNodesWithMissingParents",
  get:
    ({ organisationId }) =>
    ({ get }) => {
      const allNodes = get(admin_nodesInOrgSelectorFamily({ organisationId }));
      const topNodes = [] as Node[];
      const findTopNode = (nodeId: string, nodes: Node[]): void => {
        const node = nodes.find((n) => n.id === nodeId);
        if (!node || !node.parent_id) return;

        const parentNode = nodes.find((n) => n.id === node.parent_id);

        if (parentNode && parentNode.type === "organisation") {
          if (topNodes.some((n) => n.id === nodeId)) return;
          topNodes.push(node);
          return;
        }
        if (!parentNode) return;

        return findTopNode(parentNode.id, nodes);
      };

      allNodes.forEach((n) => findTopNode(n.id, allNodes));

      return topNodes;
    },
});

// -------------------------------------

export const topLevelFolderIdFromOrgIdAndProjectIdSelectorFamily =
  selectorFamily<
    string | undefined,
    { organisationId: string | undefined; projectId: string | undefined }
  >({
    key: "topLevelFolderIdFromOrgIdAndNodeIdSelectorFamily",
    get:
      ({ organisationId, projectId }) =>
      ({ get }) => {
        if (projectId === organisationId) return projectId;
        const folder = get(
          topLevelNodeFromOrgIdAndNodeIdSelectorFamily({
            organisationId,
            nodeId: projectId,
          }),
        );
        return folder?.id;
      },
  });

export const findTopLevelNode = (
  nodes: Node[],
  nodeId: string,
  organisationId: string,
): Node | undefined => {
  const node = nodes.find((n) => n.id === nodeId);
  if (!node || !node.parent_id) return;
  if (node.parent_id === organisationId) return node;
  return findTopLevelNode(nodes, node.parent_id, organisationId);
};

export const topLevelNodeFromOrgIdAndNodeIdSelectorFamily = selectorFamily<
  Node | undefined,
  { organisationId: string | undefined; nodeId: string | undefined }
>({
  key: "topLevelNodeFromOrgIdAndNodeIdSelectorFamily",
  get:
    ({ organisationId, nodeId }) =>
    ({ get }) => {
      if (!organisationId || !nodeId) return undefined;
      const nodes = get(
        nodesInOrganisationSelectorFamily({
          organisationId,
        }),
      );
      const topLevelFromMyNodes = findTopLevelNode(
        nodes,
        nodeId,
        organisationId,
      );
      if (topLevelFromMyNodes) return topLevelFromMyNodes;

      return getTopLevelNodeForNodeId(organisationId, nodeId);
    },
});
export const nodesInOrganisationSelectorFamily = selectorFamily<
  Node[],
  { organisationId: string | undefined }
>({
  key: "nodesInOrganisationSelectorFamily",
  get:
    ({ organisationId }) =>
    ({ get }) =>
      organisationId
        ? get(storedNodesForOrganisation({ organisationId }))
            .filter(isLoaded)
            .map((n) => n.value)
        : [],
});

export const storedNodesForOrganisation = atomFamily<
  LoadNode[],
  { organisationId: string }
>({
  key: "storedNodesForOrganisation",
  default: selectorFamily({
    key: "storedNodesForOrganisation.default",
    get:
      ({ organisationId }) =>
      async () => {
        const nodes = (await getNodesInOrganisation(organisationId).catch(
          (error) => {
            scream(error);
            return [];
          },
        )) as Node[];
        return nodes.map(makeLoaded);
      },
  }),
});

export const getNodeSelectorFamily = selectorFamily<
  Node | undefined,
  { organisationId: string; nodeId: string }
>({
  key: "getNodeSelectorFamily.default",
  get:
    ({ organisationId, nodeId }) =>
    ({ get }) =>
      get(nodesInOrganisationSelectorFamily({ organisationId })).find(
        (n) => n.id === nodeId,
      ) ??
      get(admin_nodesInOrgSelectorFamily({ organisationId })).find(
        (n) => n.id === nodeId,
      ),
});

export const getNodesWithMissingParents = selectorFamily<
  Node[],
  { organisationId: string }
>({
  key: "getNodesWithMissingParents",
  get:
    ({ organisationId }) =>
    ({ get }) => {
      const allNodes = get(
        nodesInOrganisationSelectorFamily({ organisationId }),
      );
      const topNodes = [] as Node[];
      const findTopNode = (nodeId: string, nodes: Node[]): void => {
        const node = nodes.find((n) => n.id === nodeId);
        if (!node || !node.parent_id) return;

        const parentNode = nodes.find((n) => n.id === node.parent_id);
        if (!parentNode) {
          if (topNodes.some((n) => n.id === nodeId)) return;
          topNodes.push(node);
          return;
        }

        return findTopNode(parentNode.id, nodes);
      };

      allNodes.forEach((n) => findTopNode(n.id, allNodes));

      return topNodes;
    },
});

export const projectNodesInOrganisation = selectorFamily<
  ProjectNodeInformation[],
  { organisationId: string }
>({
  key: "projectNodesInOrganisation",
  get:
    ({ organisationId }) =>
    ({ get }) => {
      if (!organisationId) return [];
      const childrenNodes = get(
        nodesInOrganisationSelectorFamily({ organisationId }),
      );
      return childrenNodes.filter(isProjectNodeInformation);
    },
});

export type OrganisationNodeCrud = ReturnType<typeof useOrganisationNodeCrud>;
export const useOrganisationNodeCrud = () => {
  const organisationId = useRecoilValue(organisationIdSelector);
  const refreshAblyToken = useAblyRefreshToken();
  const setToastMessages = useSetRecoilState(toastMessagesAtom);
  const { explain } = useExplainNodeMove();
  const storedNodes = useRecoilValue(
    nodesInOrganisationSelectorFamily({ organisationId }),
  );
  const allTopLevelFolders = useRecoilValue(
    getNodesWithMissingParents({ organisationId: organisationId ?? "" }),
  );

  const setMoveNodeModal = useSetRecoilState(MoveNodeModalAtom);

  const update = useRecoilCallback(
    ({ set, snapshot }) =>
      async (node_id: string, partialNode: Partial<NodeMutable>) => {
        if (!organisationId) return;

        const existingNode = storedNodes.find((f) => f.id === node_id);
        if (!existingNode) throw new Error("id does not exist in stored nodes");

        // NOTE: The different variants of Node has different fields, so if
        // `partialNode` changes `type` without these new fields, the type will
        // be wrong.  Play it safe here and check.
        const node = _Node.parse({
          ...existingNode,
          name: partialNode.name ?? existingNode.name,
          ...partialNode,
        });

        const prevState = await snapshot.getPromise(
          storedNodesForOrganisation({
            organisationId,
          }),
        );

        // Optimistic local update
        set(
          storedNodesForOrganisation({
            organisationId,
          }),
          (curr) => {
            const f = curr.find((c) => c.value.id === node.id);
            if (!f || f.state === "loading") return curr;
            return [
              ...curr.filter((c) => c.value.id !== node.id),
              makeLoaded({
                ...f.value,
                ...node,
              }),
            ];
          },
        );

        return putNode(node)
          .then((f) => {
            // NOTE: if the node has been updated twice since we started the
            // update, we don't want to overwrite the changes made by the second
            // update.
            set(
              storedNodesForOrganisation({
                organisationId,
              }),
              (curr) => {
                const current = curr.find((c) => c.value.id === node.id);
                if (!current || current.state === "loading") return curr;

                const isTheSame = Object.keys(node).every(
                  (k) => (f as any)[k] === (node as any)[k],
                );
                if (isTheSame) {
                  return [
                    ...curr.filter((c) => c.value.id !== node.id),
                    makeLoaded(f),
                  ];
                }
                return curr;
              },
            );
          })
          .catch((e) => {
            set(
              storedNodesForOrganisation({
                organisationId,
              }),
              prevState,
            );
            throw e;
          })
          .finally(() => {
            console.log(`done update ${node.name}`);
          });
      },
    [organisationId, storedNodes],
  );
  const move = useRecoilCallback(
    ({ set, snapshot }) =>
      async (node_id: string, parent_id: string) => {
        if (!organisationId) return;

        if (parent_id === organisationId) {
          alert(
            "Sorry, but at the moment you are not able to move items to the organisation level, this will be coming soon",
          );
          return;
        }

        const existingNode = storedNodes.find((f) => f.id === node_id);
        if (!existingNode) throw new Error("id does not exist in stored nodes");

        const existingParentNode =
          storedNodes.find((f) => f.id === parent_id) ??
          allTopLevelFolders.find((f) => f.id === parent_id);
        if (!existingParentNode)
          throw new Error("id does not exist in stored nodes");

        const node = {
          ...existingNode,
          parent_id: existingParentNode?.id ?? existingNode.parent_id,
        };

        const prevState = await snapshot.getPromise(
          storedNodesForOrganisation({
            organisationId,
          }),
        );
        // Optimistic local update
        set(
          storedNodesForOrganisation({
            organisationId,
          }),
          (curr) => {
            const f = curr.find((c) => c.value.id === node.id);
            if (!f || f.state === "loading") return curr;
            return [
              ...curr.filter((c) => c.value.id !== node.id),
              makeLoaded({
                ...f.value,
                ...node,
              }),
            ];
          },
        );

        const performSave = async () => {
          const prevState = await snapshot.getPromise(
            storedNodesForOrganisation({
              organisationId,
            }),
          );

          setToastMessages((tm) => [
            ...tm,
            {
              text: "Move complete",
              type: "success",
              timeout: 5000,
            },
          ]);
          setMoveNodeModal(undefined);

          return moveNode(existingNode.id, existingParentNode.id)
            .then((f) => {
              // NOTE: if the node has been updated twice since we started the
              // update, we don't want to overwrite the changes made by the second
              // update.
              set(
                storedNodesForOrganisation({
                  organisationId,
                }),
                (curr) => {
                  const current = curr.find((c) => c.value.id === node.id);
                  if (!current || current.state === "loading") return curr;

                  const isTheSame = Object.keys(node).every(
                    (k) => (f as any)[k] === (node as any)[k],
                  );
                  if (isTheSame) {
                    return [
                      ...curr.filter((c) => c.value.id !== node.id),
                      makeLoaded(f),
                    ];
                  }
                  return curr;
                },
              );
            })
            .catch((e) => {
              setToastMessages((tm) => [
                ...tm,
                {
                  text: "Move failed",
                  type: "error",
                  timeout: 5000,
                },
              ]);
              set(
                storedNodesForOrganisation({
                  organisationId,
                }),
                prevState,
              );
              throw e;
            })
            .finally(() => {
              console.log(`done update ${node.name}`);
            });
        };

        setMoveNodeModal({
          state: "searching",
          nodeId: node_id,
          parentId: parent_id,
          nodeType: existingNode.type,
        });
        const consequences = await explain(node_id, parent_id);
        if (
          consequences.group_accesses.length > 0 ||
          consequences.user_accesses.length > 0 ||
          consequences.group_accesses.length > 0
        ) {
          setMoveNodeModal({
            state: "action-needed",
            consequences,
            confirm: performSave,
            cancel: () => {
              set(
                storedNodesForOrganisation({
                  organisationId,
                }),
                prevState,
              );
              setToastMessages((tm) => [
                ...tm,
                {
                  text: "Move cancelled",
                  type: "info",
                  timeout: 5000,
                },
              ]);
              setMoveNodeModal(undefined);
            },
            nodeId: node_id,
            nodeType: existingNode.type,
            parentId: parent_id,
          });
        } else {
          performSave();
        }
      },
    [
      organisationId,
      storedNodes,
      allTopLevelFolders,
      setMoveNodeModal,
      setToastMessages,
      explain,
    ],
  );

  const createTopLevelFolderNode = useRecoilCallback(
    ({ set, refresh }) =>
      async (name: string) => {
        if (!organisationId) return;

        const loading: LoadingFolder = {
          loading: true,
          id: Math.random().toString(),
          createdAt: Date.now(),
          parent: organisationId,
        };

        set(
          storedNodesForOrganisation({
            organisationId,
          }),
          (curr) => [...curr, makeLoading(loading)],
        );

        try {
          const topFolderRes = await postTopLevelFolderNode(
            organisationId,
            name,
          );
          await refreshAblyToken();
          const refetchedNodes = (await getNodesInOrganisation(
            organisationId,
          ).catch((error) => {
            scream(error);
            return [];
          })) as Node[];
          set(
            storedNodesForOrganisation({
              organisationId,
            }),
            refetchedNodes.map(makeLoaded),
          );
          refresh(userAllNodesAccessAtom);
          return Object.keys(topFolderRes)[0];
        } catch (e) {
          set(
            storedNodesForOrganisation({
              organisationId,
            }),
            (curr) => curr.filter((c) => c.value.id !== loading.id),
          );
          throw e;
        }
      },
    [organisationId, refreshAblyToken],
  );
  const create = useRecoilCallback(
    ({ set, refresh }) =>
      async (args: PostNode) => {
        if (!organisationId) return;

        const loading: LoadingFolder = {
          loading: true,
          id: Math.random().toString(),
          createdAt: Date.now(),
          parent: args.parent_id,
        };

        set(
          storedNodesForOrganisation({
            organisationId,
          }),
          (curr) => [...curr, makeLoading(loading)],
        );

        try {
          const newNode = await postNode(args);
          await refreshAblyToken();
          set(
            storedNodesForOrganisation({
              organisationId,
            }),
            (curr) => [
              makeLoaded(newNode),
              ...curr.filter((c) => c.value.id !== loading.id),
            ],
          );
          refresh(userAllNodesAccessAtom);
          return newNode;
        } catch (e) {
          set(
            storedNodesForOrganisation({
              organisationId,
            }),
            (curr) => curr.filter((c) => c.value.id !== loading.id),
          );
          throw e;
        }
      },
    [organisationId, refreshAblyToken],
  );
  const createV2 = useRecoilCallback(
    ({ set, refresh }) =>
      async (args: { name: string; parent_id: string; type: "project" }) => {
        if (!organisationId) return;

        const loading: LoadingFolder = {
          loading: true,
          id: Math.random().toString(),
          createdAt: Date.now(),
          parent: args.parent_id,
        };

        set(
          storedNodesForOrganisation({
            organisationId,
          }),
          (curr) => [...curr, makeLoading(loading)],
        );

        try {
          const newProNode = await createProjectNode(args);
          await refreshAblyToken();
          set(
            storedNodesForOrganisation({
              organisationId,
            }),
            (curr) => [
              ...curr.filter(
                // NOTE: We might have fetched the new project in another request,
                // so we also need to filter that out, if it's here.
                (c) =>
                  c.value.id !== loading.id && c.value.id !== newProNode.id,
              ),
              makeLoaded(newProNode),
            ],
          );
          refresh(userAllNodesAccessAtom);
          return newProNode;
        } catch (e) {
          set(
            storedNodesForOrganisation({
              organisationId,
            }),
            (curr) => curr.filter((c) => c.value.id !== loading.id),
          );
          throw e;
        }
      },
    [organisationId, refreshAblyToken],
  );

  const createTutorialProject = useRecoilCallback(
    ({ set, refresh }) =>
      async (parent_id: string) => {
        if (!organisationId) return;

        const loading: LoadingFolder = {
          loading: true,
          id: Math.random().toString(),
          createdAt: Date.now(),
          parent: parent_id,
        };

        set(
          storedNodesForOrganisation({
            organisationId,
          }),
          (curr) => [...curr, makeLoading(loading)],
        );

        try {
          const newProNode = await createTutorialProjectService(parent_id);
          await refreshAblyToken();
          set(
            storedNodesForOrganisation({
              organisationId,
            }),
            (curr) => [
              ...curr.filter(
                // NOTE: We might have fetched the new project in another request,
                // so we also need to filter that out, if it's here.
                (c) =>
                  c.value.id !== loading.id && c.value.id !== newProNode.id,
              ),
              makeLoaded(newProNode),
            ],
          );
          refresh(userAllNodesAccessAtom);
          return newProNode;
        } catch (e) {
          set(
            storedNodesForOrganisation({
              organisationId,
            }),
            (curr) => curr.filter((c) => c.value.id !== loading.id),
          );
          throw e;
        }
      },
    [organisationId, refreshAblyToken],
  );

  const remove = useRecoilCallback(
    ({ set, snapshot }) =>
      async (nodeId: string) => {
        if (!organisationId) return;

        const prevState = await snapshot.getPromise(
          storedNodesForOrganisation({
            organisationId,
          }),
        );
        set(
          storedNodesForOrganisation({
            organisationId,
          }),
          (curr) => curr.filter((f) => f.value.id !== nodeId),
        );
        try {
          await deleteNode(nodeId);
        } catch (error) {
          set(
            storedNodesForOrganisation({
              organisationId,
            }),
            prevState,
          );
          throw error;
        }
      },
    [organisationId],
  );

  const removeLocal = useRecoilCallback(
    ({ set, snapshot }) =>
      async (nodeId: string) => {
        if (!organisationId) return;

        initializeAndSet(
          snapshot,
          set,
          storedNodesForOrganisation({
            organisationId,
          }),
          (cur) => {
            return cur.filter((node) => node.value.id !== nodeId);
          },
        );
      },
    [organisationId],
  );

  const createLocal = useRecoilCallback(
    ({ set, snapshot, refresh }) =>
      async (newNode: Node) => {
        if (!organisationId) return;

        // createLocal is called when ably receives message of a newly created node
        // so we refresh the ably token before adding it to state, so that we can subscribe to the new nodes channels
        await refreshAblyToken();
        refresh(userAllNodesAccessAtom);
        initializeAndSet(
          snapshot,
          set,
          storedNodesForOrganisation({
            organisationId,
          }),
          (cur) =>
            cur.some((node) => node.value.id === newNode.id)
              ? cur
              : [...cur, makeLoaded(newNode)],
        );
      },
    [organisationId, refreshAblyToken],
  );

  const updateLocal = useRecoilCallback(
    ({ set, snapshot }) =>
      async (node: Node) => {
        if (!organisationId) return;

        initializeAndSet(
          snapshot,
          set,
          storedNodesForOrganisation({
            organisationId,
          }),
          (curr) => {
            const f = curr.find((c) => c.value.id === node.id);
            if (!f || f.state === "loading") return curr;
            return [
              ...curr.filter((c) => c.value.id !== node.id),
              makeLoaded({
                ...f.value,
                ...node,
              }),
            ];
          },
        );
      },
    [organisationId],
  );

  const moveLocal = useRecoilCallback(
    ({ set, snapshot }) =>
      async (moveNode: MoveNode) => {
        if (!organisationId) return;

        // no need to update locally for topLevelFolders we don't have access to
        if (!allTopLevelFolders.some((f) => f.id === moveNode.topLevelNodeId))
          return;

        initializeAndSet(
          snapshot,
          set,
          storedNodesForOrganisation({
            organisationId,
          }),
          (curr) =>
            curr.map((node) => {
              if (node.value.id !== moveNode.nodeId) return node;
              const loaded = node.getLoaded();
              if (loaded) {
                return makeLoaded({
                  ...loaded,
                  parent_id: moveNode.toNodeId,
                });
              } else {
                return makeLoading({
                  // Safety: if it's not loaded, it's loading.
                  ...node.getLoading()!,
                  parent: moveNode.toNodeId,
                });
              }
            }),
        );
      },
    [allTopLevelFolders, organisationId],
  );

  return {
    createTopLevelFolderNode,
    create,
    createV2,
    createTutorialProject,
    remove,
    removeLocal,
    update,
    updateLocal,
    createLocal,
    move,
    moveLocal,
  };
};
