import {
  AnyLayer,
  AnySourceData,
  Map,
  MapboxGeoJSONFeature,
  MapLayerMouseEvent,
} from "mapbox-gl";
import {
  defaultMouseHandlerCallBackClickableFeature,
  otherUsersSelectionArrayAtomFamily,
} from "../../state/selection";
import { mapInteractionSelector } from "../../state/map";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { useEffect, useMemo, useState } from "react";
import { Feature, FeatureCollection } from "geojson";
import { getBeforeLayer } from "../Mapbox/utils";
import { safeRemoveLayer } from "../../utils/map";
import { addIdOnFeatureCollectionIfNotUUID4 } from "../../utils/geojson/utils";
import {
  branchIdSelector,
  parkIdSelector,
  projectIdSelector,
} from "../../state/pathParams";
import { getUserColor } from "../LiveCursor/utils";
import { projectPresenceAtomFamily } from "../../state/ably";
import React from "react";
import { currentVersionSelector } from "../../state/project";
import { setInFocus, removeInFocus } from "components/Mapbox/utils";
import { scream, sendWarning } from "../../utils/sentry";

const defaultFeatureFilter = (
  selectedFeatures: MapboxGeoJSONFeature[],
  featureCollection: FeatureCollection,
): MapboxGeoJSONFeature[] => {
  const mapboxFeaturesWithFeatureMapping = selectedFeatures.filter(
    (mapboxFeature) =>
      featureCollection.features.find((f) => f.id === mapboxFeature.id),
  );
  return mapboxFeaturesWithFeatureMapping.map((mapboxFeature) => ({
    ...mapboxFeature,
    geometry: featureCollection.features.find((f) => f.id === mapboxFeature.id)!
      .geometry,
  }));
};

export const interactionFeatureTypesWhitelistAtom = atom<undefined | string[]>({
  key: "interactionFeatureTypesWhitelist",
  default: undefined,
});

/**
 * This class is responsible for adding and removing layers and sources from the
 * map. This isn't trivial because what we want to do, and the order we want to
 * add things and remove things depends on the exact case, which doesn't
 * translate very well to a series of effects.
 *
 * The main problem is that we need to be able to update a layer without
 * removing the source, but when we remove a source we need to remove the layers
 * first. And, of course, we need to add the source before adding a layer that
 * uses it. Since cleanups from useEffects run in the same order as the effects,
 * we can't add things in the effect and remove them in the cleanup, because the
 * cleanup for the source will run before the layer cleanup.
 *
 * I'm very sorry for this 😭
 */
class DumbMapboxSyncer extends React.Component<{
  sourceId: string;
  source: AnySourceData;
  layerId: string;
  featuresWithUUID: FeatureCollection;
  map: Map;
  layers: AnyLayer[];
  textLayer?: AnyLayer;
  symbolsLayer?: AnyLayer;
  beforeLayer?: string;
  symbolsBeforeLayer?: string;
  cluster?: boolean;
}> {
  removeLayers(sourceId?: string) {
    const toRemove = this.props.map
      .getStyle()
      .layers.filter(
        (l: AnyLayer) =>
          this.props.layers.some((ll) => ll.id === l.id) ||
          this.props.symbolsLayer?.id === l.id ||
          ("source" in l && l["source"] === sourceId),
      );
    for (const layer of toRemove) {
      safeRemoveLayer(this.props.map, layer.id);
    }
  }

  removeSourceAndAnyLayers(sourceId: string) {
    this.removeLayers(sourceId);
    this.props.map.removeSource(sourceId);
  }

  componentDidMount(): void {
    const {
      map,
      sourceId,
      source,
      layers,
      layerId,
      beforeLayer,
      featuresWithUUID,
      textLayer,
      symbolsLayer,
      symbolsBeforeLayer,
      cluster,
    } = this.props;
    try {
      map.addSource(sourceId, {
        ...source,
        ...(cluster
          ? { cluster: true, clusterMaxZoom: 9, clusterRadius: 5 }
          : {}),
      });
    } catch (e) {
      if (
        e instanceof Error &&
        e.message === "There is already a source with this ID"
      ) {
        throw scream("Mapbox tried to add a source that already exists", {
          sourceId,
          e,
        });
      }
      throw e;
    }
    for (const layer of layers) {
      map.addLayer(layer, getBeforeLayer(map, beforeLayer ?? layerId));
    }
    if (textLayer) {
      map.addLayer(textLayer);
    }
    if (symbolsLayer) {
      map.addLayer(
        symbolsLayer,
        getBeforeLayer(map, symbolsBeforeLayer ?? beforeLayer ?? layerId),
      );
    }
    if (source.type === "geojson") {
      const src = map.getSource(sourceId);
      if (!src) sendWarning("Mapbox could not find source", { sourceId });
      else if (src.type !== "geojson") {
        sendWarning("Tried to set data on a non-geojson source", {
          sourceId,
          type: src.type,
          src,
        });
      } else {
        src.setData(featuresWithUUID);
      }
    }
  }

  componentDidUpdate(
    prevProps: Readonly<{
      sourceId: string;
      source: AnySourceData;
      layerId: string;
      featuresWithUUID: FeatureCollection;
      map: Map;
      layers: AnyLayer[];
      symbolsLayer?: AnyLayer;
      beforeLayer?: string;
    }>,
  ): void {
    const {
      layerId,
      layers,
      source,
      sourceId,
      featuresWithUUID,
      symbolsLayer,
      map,
      beforeLayer,
      symbolsBeforeLayer,
    } = this.props;

    const layersChanged = prevProps.layers !== layers;
    const sourceChanged = prevProps.source !== source;
    const sourceIdChanged = prevProps.sourceId !== sourceId;
    const featuresChanged = prevProps.featuresWithUUID !== featuresWithUUID;
    const symbolsLayerChanged = prevProps.symbolsLayer !== symbolsLayer;

    if (sourceChanged || sourceIdChanged) {
      if (prevProps.sourceId) {
        this.removeSourceAndAnyLayers(prevProps.sourceId);
      }

      map.addSource(sourceId, source);
    }

    if (layersChanged) {
      this.removeLayers();
      for (const layer of layers) {
        map.addLayer(layer, getBeforeLayer(map, beforeLayer ?? layerId));
      }
    }

    if (symbolsLayerChanged || layersChanged) {
      if (prevProps.symbolsLayer)
        safeRemoveLayer(map, prevProps.symbolsLayer.id);
      if (symbolsLayer) {
        map.addLayer(
          symbolsLayer,
          getBeforeLayer(map, symbolsBeforeLayer ?? beforeLayer ?? layerId),
        );
      }
    }

    if (featuresChanged && source) {
      if (source.type === "geojson") {
        const src = map.getSource(sourceId);
        if (!src) sendWarning("Mapbox could not find source", { sourceId });
        else if (src.type !== "geojson") {
          sendWarning("Tried to set data on a non-geojson source", {
            sourceId,
            type: src.type,
            src,
          });
        } else {
          src.setData(featuresWithUUID);
        }
      }
    }
  }

  componentWillUnmount(): void {
    this.removeSourceAndAnyLayers(this.props.sourceId);
  }

  render() {
    return null;
  }
}

const GenericFeature = ({
  features,
  sourceId,
  source,
  layerId,
  map,
  layers,
  textLayer,
  symbolsLayer,
  onClickCallback,
  onDbClickCallback,
  selectedIds,
  filter,
  beforeLayer,
  symbolsBeforeLayer,
  sourceLayer,
  featureFilter,
  cluster,
}: {
  features: Feature[];
  sourceId: string;
  source: AnySourceData;
  layerId: string;
  map: Map;
  layers: mapboxgl.AnyLayer[];
  textLayer?: mapboxgl.AnyLayer;
  symbolsLayer?: mapboxgl.AnyLayer;
  onClickCallback?: (
    features: MapboxGeoJSONFeature[],
    shiftClicked: boolean,
  ) => void;
  onDbClickCallback?: (features: any[]) => void;
  selectedIds?: (string | number)[];
  filter?: any[];
  beforeLayer?: string;
  symbolsBeforeLayer?: string;
  sourceLayer?: string;
  featureFilter?: (
    selectedFeatures: MapboxGeoJSONFeature[],
    features: FeatureCollection,
  ) => MapboxGeoJSONFeature[];
  cluster?: boolean;
}) => {
  const projectNodeId = useRecoilValue(projectIdSelector) ?? "";
  const branchId = useRecoilValue(branchIdSelector) ?? "";
  const parkId = useRecoilValue(parkIdSelector);

  const layerIds = useMemo(() => layers.map((l) => l.id), [layers]);
  const version = useRecoilValue(currentVersionSelector);
  const mapInteraction = useRecoilValue(mapInteractionSelector);
  const [hoveredId, setHoveredId] = useState<number | string>();
  const setMouseHandlerCallBack = useSetRecoilState(
    defaultMouseHandlerCallBackClickableFeature,
  );
  const otherUsersSelection = useRecoilValue(
    otherUsersSelectionArrayAtomFamily({
      projectId: projectNodeId,
      branchId,
      version,
    }),
  );
  const projectPresence = useRecoilValue(
    projectPresenceAtomFamily({ nodeId: projectNodeId ?? "" }),
  );
  const interactionWhitelist = useRecoilValue(
    interactionFeatureTypesWhitelistAtom,
  );

  const interactFilter = useMemo(() => {
    if (!interactionWhitelist) return () => true;
    return (f: MapboxGeoJSONFeature) => {
      const type = f.properties?.["type"];
      if (!type) return true;
      return interactionWhitelist.includes(type);
    };
  }, [interactionWhitelist]);

  const featuresWithUUID = useMemo(
    () =>
      addIdOnFeatureCollectionIfNotUUID4({
        type: "FeatureCollection",
        features,
      }),
    [features],
  );

  useEffect(() => {
    if (!map || (!onClickCallback && !onDbClickCallback)) return;
    const mouseMove = (e: MapLayerMouseEvent) => {
      if (!mapInteraction.hover) return;
      const id = e.features?.filter(interactFilter)?.[0]?.id;
      if (id) setHoveredId(id);
    };

    const mouseLeave = () => {
      if (!mapInteraction.hover) return;
      setHoveredId(undefined);
    };

    const onClick = (e: MapLayerMouseEvent) => {
      if (!onClickCallback) return;
      e.preventDefault();
      if (!mapInteraction.hover) return;
      if (e.features === undefined) return;
      let featuresWithProperGeometry: MapboxGeoJSONFeature[] = [];
      if (!featureFilter) {
        featuresWithProperGeometry = defaultFeatureFilter(
          e.features,
          featuresWithUUID,
        );
      } else {
        featuresWithProperGeometry = featureFilter(
          e.features,
          featuresWithUUID,
        );
      }
      featuresWithProperGeometry =
        featuresWithProperGeometry.filter(interactFilter);
      if (featuresWithProperGeometry.length === 0) return;
      onClickCallback(featuresWithProperGeometry, e.originalEvent.shiftKey);
    };

    const onDblClick = (e: MapLayerMouseEvent) => {
      if (!onDbClickCallback) return;
      e.preventDefault();
      if (!mapInteraction.hover) return;
      const features = e.features?.filter(interactFilter) ?? [];
      if (features !== undefined) onDbClickCallback(features);
    };

    setMouseHandlerCallBack((l) => ({
      ...l,
      [layerId]: {
        onDblClick: onDblClick,
        onClick: onClick,
        onMouseMove: mouseMove,
        onMouseLeave: mouseLeave,
      },
    }));

    return () => {
      setMouseHandlerCallBack((l) => {
        const cleanedL = { ...l };
        delete cleanedL[layerId];
        return cleanedL;
      });
    };
  }, [
    setMouseHandlerCallBack,
    map,
    mapInteraction,
    setHoveredId,
    onClickCallback,
    onDbClickCallback,
    layerId,
    featuresWithUUID,
    featureFilter,
    interactFilter,
  ]);

  useEffect(() => {
    if (!map || !map.getSource(sourceId) || hoveredId == null) return;
    map.setFeatureState(
      { source: sourceId, id: hoveredId, sourceLayer },
      { hover: true },
    );

    return () => {
      if (!map.getSource(sourceId)) return;
      map.removeFeatureState(
        { source: sourceId, id: hoveredId, sourceLayer },
        "hover",
      );
    };
  }, [hoveredId, map, sourceId, sourceLayer]);

  useEffect(() => {
    if (!map || !selectedIds) return;

    selectedIds.forEach((id) => {
      map.setFeatureState(
        { source: sourceId, id: id, sourceLayer },
        { selected: true },
      );
    });

    return () => {
      if (!map.getSource(sourceId)) return;
      selectedIds.forEach((id) => {
        map.removeFeatureState(
          { source: sourceId, id: id, sourceLayer },
          "selected",
        );
      });
    };
  }, [selectedIds, map, sourceId, sourceLayer]);

  useEffect(() => {
    if (parkId) {
      features.forEach((f) => {
        const id = f.id;
        if (!id) return;
        const inFocus =
          f.id === parkId || f.properties?.parentIds?.includes(parkId);
        if (inFocus) {
          setInFocus(map, sourceId, String(id));
        } else {
          removeInFocus(map, sourceId, String(id));
        }
      });
    }
    return () => {
      features.map((f) => f.id && removeInFocus(map, sourceId, String(f.id)));
    };
  }, [features, map, parkId, sourceId]);

  useEffect(() => {
    if (!map || !otherUsersSelection || !branchId) return;

    otherUsersSelection.forEach((s) => {
      const branchPresence = projectPresence.find(
        (p) => p.clientId === s.userId && p.data.branchId === branchId,
      );
      const userIsActive =
        branchPresence && branchPresence.data.status === "active";

      if (userIsActive) {
        s.selection.forEach((id) => {
          map.setFeatureState(
            { source: sourceId, id },
            { borderColor: getUserColor(s.userId) },
          );
        });
      }
    });

    return () => {
      if (!map.getSource(sourceId)) return;

      otherUsersSelection.forEach((s) => {
        s.selection.forEach((id) => {
          map.setFeatureState({ source: sourceId, id }, { borderColor: null });
        });
      });
    };
  }, [map, sourceId, otherUsersSelection, projectPresence, branchId]);

  useEffect(() => {
    if (!map) return;
    layerIds.forEach((layerId) => {
      if (!map.getLayer(layerId)) return;
      map.setFilter(layerId, filter);
    });
  }, [filter, map, layerIds]);

  if (!map) return null;
  return (
    <DumbMapboxSyncer
      sourceId={sourceId}
      source={source}
      layerId={layerId}
      featuresWithUUID={featuresWithUUID}
      map={map}
      layers={layers}
      textLayer={textLayer}
      symbolsLayer={symbolsLayer}
      beforeLayer={beforeLayer}
      symbolsBeforeLayer={symbolsBeforeLayer}
      cluster={cluster}
    />
  );
};

export default GenericFeature;
