import { CostType } from "../../../../services/costService";

import { GeneralCost } from "./General/GeneralCost";
import { CostUnit } from "../../../../types/financial";

export const OtherCosts = ({ isReadOnly }: { isReadOnly?: boolean }) => {
  return (
    <GeneralCost
      title="Other"
      category={CostType.Other}
      isReadOnly={isReadOnly}
      units={[CostUnit.millionEuroPerMW, CostUnit.millionEuro]}
    />
  );
};
