import styled from "styled-components";
import { colors } from "../../styles/colors";
import {
  spaceHuge,
  spaceMedium,
  spaceSmall,
  spacing6,
  spacing9,
} from "../../styles/space";
import { RegularRaw, typography } from "../../styles/typography";

export const TitleBarDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 2.4rem;
  justify-content: space-between;
  background: ${colors.brand};
  height: var(--top-bar-menu-height);
  max-height: 8rem;
  padding: ${spacing6} ${spacing9};
  box-sizing: border-box;
`;

export const VindLogoWrapper = styled.div`
  svg {
    path {
      fill: white;
    }
  }
`;

export const UserSettingsLink = styled.a`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${spaceMedium};
  color: white;
  ${typography.regular};
  &:hover {
    cursor: pointer;
  }
`;

export const TeamsListWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  padding: 2.4rem 0 2rem 0;
  min-width: 25rem;
  max-width: 50rem;

  border-right: 1px solid ${colors.inputOutline};
`;

export const TeamListItemPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spaceMedium};
  ${typography.contentAndButtons};
  padding: ${spaceMedium} ${spaceSmall} ${spaceMedium} ${spaceHuge};
`;

export const TeamListItem = styled.div<{ selected?: boolean }>`
  ${RegularRaw};
  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${spaceMedium};
    ${typography.contentAndButtons}
    ${(p) => p.selected && `font-weight: 700;`}
  }

  white-space: nowrap;

  &:hover {
    background: ${colors.hover};
    cursor: pointer;
  }
  ${(p) =>
    p.selected &&
    `
  background: ${colors.selected};
  `}
`;

export const TeamListName = styled.div<{ selected?: boolean }>`
  ${RegularRaw};
  ${(p) => p.selected && `font-weight: 700;`}
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
`;

export const TeamImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 2rem;
  width: 2rem;
  border-radius: 3rem;

  font-size: 1.2rem;
  font-weight: 600;

  background-color: ${colors.brand};
  color: ${colors.lightText};

  flex-shrink: 0;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
`;

export const VerticalDivider = styled.div`
  display: flex;
  border-right: 1px solid ${colors.inputOutline};
  height: 1.6rem;
`;

export const WrapperFullScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 5%;
  z-index: 10000;
  width: 100%;
  height: 100%;
  background-color: ${colors.focusBackground};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TableItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spaceMedium};
  text-align: start;
  align-items: center;
`;

export const TableItemClickable = styled(TableItem)`
  cursor: pointer;
`;

export const EditableTextH3Wrapper = styled.h3`
  * {
    ${typography.h3};
  }
`;
