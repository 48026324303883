import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { customerProjectAtomFamily } from "../../state/timeline";
import { useTypedPath } from "../../state/pathParams";
import { Mixpanel } from "../../mixpanel";

const TutorialOpenedMixpanelFlag = () => {
  const { projectId } = useTypedPath("projectId");
  const project = useRecoilValue(
    customerProjectAtomFamily({ nodeId: projectId }),
  );

  useEffect(() => {
    if (!project) return;
    const { tutorial_id } = project;
    if (!tutorial_id) return;
    console.log("Opened tutorial project");
    Mixpanel.track("Opened tutorial project", {
      projectId: tutorial_id,
    });
  }, [project]);
  return null;
};

export default TutorialOpenedMixpanelFlag;
