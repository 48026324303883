import React, { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import { modalTypeOpenAtom, ModalTypeType } from "state/modal";

const useOpenUploadModalOnDragFile = ({
  modalType,
}: {
  modalType: ModalTypeType;
}) => {
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);

  const openUploadModal = useCallback(
    (e: React.DragEvent) => {
      if (e.dataTransfer.types.includes("Files")) {
        e.preventDefault();
        setModalTypeOpen({
          modalType: modalType as any,
        });
      }
    },
    [setModalTypeOpen, modalType],
  );

  return { openUploadModal };
};

export default useOpenUploadModalOnDragFile;
