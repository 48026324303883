import {
  VIEW_MODE,
  viewCameraAspectAtom,
  viewFovAtom,
  viewFromShoreTerrainColorActiveAtom,
  viewFromShoreTerrainColorAtom,
  viewFromShoreTerrainVisibleAtom,
  viewOrigoSelector,
  viewPositionAtom,
  viewProj4StringAtom,
  viewTowardsSelector,
  viewTowardsWGS84Atom,
  viewViewModeAtom,
} from "state/viewToPark";
import { ThreeCore } from "./useCreateThreeCore";
import { useEffect } from "react";
import {
  atom,
  noWait,
  selector,
  selectorFamily,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { mapboxAccessToken } from "components/MapNative/constants";
import { Tile, lonLatToTile, lonLatToTileFloat } from "types/tile";
import {
  BufferAttribute,
  Color,
  DoubleSide,
  MathUtils,
  Mesh,
  MeshStandardMaterial,
  PlaneGeometry,
  Texture,
  TextureLoader,
} from "three";
import { disposeObject, verticalFovToHorizontalFov } from "../utils";
import {
  boundingBoxToPolygonTiles,
  getFOVLines,
  injectCurvatureIntoVertexShader,
} from "./utils";
import { MeshBVH, SerializedBVH } from "three-mesh-bvh";
import { hexToRgb } from "styles/colors";
import { promiseWorker, typedWorker } from "utils/utils";
import { Position } from "@turf/turf";
import * as turf from "@turf/turf";
import { useToast } from "hooks/useToast";
import { parkIdSelector } from "state/pathParams";
import { getParkFeatureSelectorFamily } from "state/park";
import { getDistanceFromLatLonInM } from "utils/proj4";

const TERRAIN_ZOOM_LEVEL = 11;
const EXTRA_TEXTURE_Z_RESOLUTION = 3;
const HIGH_RESOLUTION_INCLUSION_SLACK = 0.2;
const MAX_PARALLELL_NEW_TILES_TO_ADD = 5;
const MAX_TILES = 200;
const PARK_SURROUNDING_TILES = 1;
const MAX_DISTANCE_FROM_VIEW_POINT = 100;

const tileEquality = (a: Tile, b: Tile) =>
  a.x === b.x && a.y === b.y && a.z === b.z;

async function mergeImages(urls: string[], cols: number): Promise<Blob> {
  const images = await Promise.all(urls.map((url) => loadImage(url)));
  const rows = Math.ceil(images.length / cols);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("Canvas context could not be retrieved");
  }

  const imgWidth = images[0].width;
  const imgHeight = images[0].height;

  canvas.width = imgWidth * cols;
  canvas.height = imgHeight * rows;
  ctx.fillStyle = "red";
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  images.forEach((img, index) => {
    const x = (index % cols) * imgWidth;
    const y = Math.floor(index / cols) * imgHeight;
    ctx.drawImage(img, x, y, imgWidth, imgHeight);
  });

  return new Promise<Blob>((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        resolve(blob);
      } else {
        reject(new Error("Canvas toBlob failed"));
      }
    }, "image/png");
  });
}

function loadImage(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = url;
  });
}

function getRGBAArray(
  img: HTMLImageElement,
): [number[], number[], number[], number[]] {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
  canvas.width = img.width;
  canvas.height = img.height;
  ctx.drawImage(img, 0, 0);
  const imageData = ctx.getImageData(0, 0, img.width, img.height);
  const rArray: number[] = [];
  const gArray: number[] = [];
  const bArray: number[] = [];
  const aArray: number[] = [];
  for (let i = 0; i < imageData.data.length; i += 4) {
    const r = imageData.data[i];
    const g = imageData.data[i + 1];
    const b = imageData.data[i + 2];
    const a = imageData.data[i + 3];
    rArray.push(r);
    gArray.push(g);
    bArray.push(b);
    aArray.push(a);
  }
  return [rArray, gArray, bArray, aArray];
}

const getTerrainGeometryTileFromMapbox = selectorFamily<
  PlaneGeometry | undefined,
  Tile
>({
  key: "getTerrainGeometryTileFromMapbox",
  get:
    ({ x, y, z }) =>
    async ({ get }) => {
      const proj4String = get(viewProj4StringAtom);
      const origo = get(viewOrigoSelector);
      if (!proj4String || !origo) return;

      const image = await loadImage(
        `https://api.mapbox.com/v4/mapbox.terrain-rgb/${z}/${x}/${y}@2x.pngraw?access_token=${mapboxAccessToken}`,
      );
      const rgbaArray = getRGBAArray(image);

      const parseTerrainTileWorker = typedWorker<
        [
          [number[], number[], number[], number[]],
          number,
          number,
          number,
          string,
          Position,
        ],
        [number, number, ArrayLike<number>, ArrayLike<number>, SerializedBVH]
      >(
        new Worker(new URL("./parseTerrainTileWorker.ts", import.meta.url), {
          type: "module",
        }),
      );

      const [width, height, position, normal, bvhSerialised] =
        await promiseWorker(parseTerrainTileWorker, [
          rgbaArray,
          x,
          y,
          z,
          proj4String,
          origo,
        ]);
      parseTerrainTileWorker.terminate();

      const geometry = new PlaneGeometry(width, height, width - 1, height - 1);

      geometry.setAttribute("position", new BufferAttribute(position, 3));
      geometry.setAttribute("normal", new BufferAttribute(normal, 3));
      const deserializedBVH = MeshBVH.deserialize(bvhSerialised, geometry);
      geometry.boundsTree = deserializedBVH;

      geometry.computeBoundingBox();
      return geometry;
    },
  dangerouslyAllowMutability: true,
});

const getTerrainTextureTileFromMapbox = selectorFamily<
  Texture | undefined,
  { tile: Tile; extraResolution: number }
>({
  key: "getTerrainTextureTileFromMapbox",
  get:
    ({ tile, extraResolution }) =>
    async ({ get }) => {
      const { x, y, z } = tile;
      const proj4String = get(viewProj4StringAtom);
      const origo = get(viewOrigoSelector);
      if (!proj4String || !origo) return;

      const textureZoom = z + extraResolution;
      const seCorner2 = {
        x: x * Math.pow(2, extraResolution) + Math.pow(2, extraResolution) - 1,
        y: y * Math.pow(2, extraResolution) + Math.pow(2, extraResolution) - 1,
      };
      const nwCorner2 = {
        x: x * Math.pow(2, extraResolution),
        y: y * Math.pow(2, extraResolution),
      };

      const tilesToRequest = [];
      for (let y = nwCorner2.y; y <= seCorner2.y; y++) {
        for (let x = nwCorner2.x; x <= seCorner2.x; x++) {
          tilesToRequest.push(
            `https://api.mapbox.com/v4/mapbox.satellite/${textureZoom}/${x}/${y}@2x.png?access_token=${mapboxAccessToken}`,
          );
        }
      }

      const mergedTextureBlob = await mergeImages(
        tilesToRequest,
        seCorner2.x - nwCorner2.x + 1,
      );
      const textureSignedUrl = window.URL.createObjectURL(mergedTextureBlob);
      const loader = new TextureLoader();
      const texture = await new Promise<THREE.Texture>((res, fail) => {
        loader.load(
          textureSignedUrl,
          function (texture) {
            res(texture);
          },
          undefined,
          function (err) {
            fail(err);
          },
        );
      });

      return texture;
    },
  dangerouslyAllowMutability: true,
});

const getTerrainTileFromMapbox = selectorFamily<
  Mesh<PlaneGeometry, MeshStandardMaterial> | undefined,
  Tile
>({
  key: "getTerrainTileFromMapbox",
  get:
    ({ x, y, z }) =>
    async ({ get }) => {
      const geometry = get(getTerrainGeometryTileFromMapbox({ x, y, z }));
      const extraResolution = get(highResolutionPatchesRequestedAtom).some(
        (hrt) => hrt.x === x && hrt.y === y && hrt.z === z,
      )
        ? EXTRA_TEXTURE_Z_RESOLUTION
        : 0;
      const textureMaybe = get(
        noWait(
          getTerrainTextureTileFromMapbox({
            tile: { x, y, z },
            extraResolution,
          }),
        ),
      );

      if (!geometry) return;

      const materialCurvature = new MeshStandardMaterial({
        transparent: true,
        side: DoubleSide,
        ...(textureMaybe.state === "hasValue" && textureMaybe.contents != null
          ? { map: textureMaybe.contents }
          : { color: 0x77bb77 }),
      });

      materialCurvature.onBeforeCompile = (shader: THREE.Shader) => {
        shader.vertexShader = injectCurvatureIntoVertexShader(
          shader.vertexShader,
        );

        const fragmentShader =
          `
  varying vec3 v_pos;
  ` +
          shader.fragmentShader.split("}")[0] +
          `
      if(v_pos.z < 0.0) {
        gl_FragColor = vec4(1.0, 0.0, 0.0, 0.0);
      } else {
        gl_FragColor = gl_FragColor;
      }
    }
    `;

        shader.fragmentShader = fragmentShader;
      };

      const mesh = new Mesh(geometry, materialCurvature);

      mesh.rotateX(MathUtils.degToRad(-90));
      mesh.rotateZ(MathUtils.degToRad(-180));

      mesh.matrixAutoUpdate = false;
      mesh.updateMatrix();

      return mesh;
    },
  dangerouslyAllowMutability: true,
});

export const terrainPatchesAddedToSceneRefresherAtom = atom<number>({
  key: "terrainPatchesAddedToSceneRefresherAtom",
  default: 0,
});

export const terrainPatchesRequestedAtom = atom<Tile[]>({
  key: "terrainPatchesRequestedAtom",
  default: [],
});

const highResolutionPatchesRequestedAtom = atom<Tile[]>({
  key: "highResolutionPatchesRequestedAtom",
  default: [],
});

export const getTerrainTilesFromMapbox = selector<
  Mesh<PlaneGeometry, MeshStandardMaterial>[]
>({
  key: "getTerrainTilesFromMapbox",
  get: async ({ get }) => {
    return get(terrainPatchesRequestedAtom)
      .map((tile) => get(noWait(getTerrainTileFromMapbox(tile))))
      .reduce(
        (acc, meshMaybe) =>
          meshMaybe.state === "hasValue" && meshMaybe.contents != null
            ? [...acc, meshMaybe.contents]
            : acc,
        [] as (Mesh<PlaneGeometry, MeshStandardMaterial> | undefined)[],
      )
      .filter((mesh) => mesh != null) as Mesh<
      PlaneGeometry,
      MeshStandardMaterial
    >[];
  },
  dangerouslyAllowMutability: true,
});

export const getLoadingTerrainTilesFromMapbox = selector<Tile[]>({
  key: "getLoadingTerrainTilesFromMapbox",
  get: async ({ get }) => {
    return get(terrainPatchesRequestedAtom).filter(
      (tile) => get(noWait(getTerrainTileFromMapbox(tile))).state === "loading",
    );
  },
});

export default function DynamicTerrain({
  threeCore,
}: {
  threeCore: ThreeCore | undefined;
}) {
  const viewPosition = useRecoilValue(viewPositionAtom);
  const [terrainPatchesRequested, setTerrainPatchesRequested] = useRecoilState(
    terrainPatchesRequestedAtom,
  );
  const [highResolutionPatchesRequested, setHighResolutionPatchesRequested] =
    useRecoilState(highResolutionPatchesRequestedAtom);
  const terrainPatches = useRecoilValue(getTerrainTilesFromMapbox);
  const viewMode = useRecoilValue(viewViewModeAtom);
  const terrainColorActive = useRecoilValue(
    viewFromShoreTerrainColorActiveAtom,
  );
  const terrainColor = useRecoilValue(viewFromShoreTerrainColorAtom);
  const viewFromShoreTerrainVisible = useRecoilValue(
    viewFromShoreTerrainVisibleAtom,
  );
  const setTerrainPatchesAddedToSceneRefresher = useSetRecoilState(
    terrainPatchesAddedToSceneRefresherAtom,
  );
  const viewTowards = useRecoilValue(viewTowardsSelector);
  const cameraAspect = useRecoilValue(viewCameraAspectAtom);
  const fov = useRecoilValue(viewFovAtom);
  const proj4String = useRecoilValue(viewProj4StringAtom);
  const loadingTiles = useRecoilValue(getLoadingTerrainTilesFromMapbox);
  const { info } = useToast();
  const parkId = useRecoilValue(parkIdSelector);
  const park = useRecoilValue(
    getParkFeatureSelectorFamily({ parkId: parkId ?? "" }),
  );
  const viewTowardsWGS84 = useRecoilValue(viewTowardsWGS84Atom);

  useEffect(() => {
    return () => {
      setTerrainPatchesRequested([]);
      setHighResolutionPatchesRequested([]);
    };
  }, [setTerrainPatchesRequested, setHighResolutionPatchesRequested]);

  useEffect(() => {
    if (!park) return;
    const centerCoords = turf.center(park).geometry.coordinates;
    const highResolutionTile = lonLatToTile(
      centerCoords[0],
      centerCoords[1],
      TERRAIN_ZOOM_LEVEL,
    );
    const tilesToRequest = [];
    for (
      let x = highResolutionTile.x - PARK_SURROUNDING_TILES;
      x <= highResolutionTile.x + PARK_SURROUNDING_TILES;
      x++
    ) {
      for (
        let y = highResolutionTile.y - PARK_SURROUNDING_TILES;
        y <= highResolutionTile.y + PARK_SURROUNDING_TILES;
        y++
      ) {
        tilesToRequest.push({ x, y, z: TERRAIN_ZOOM_LEVEL });
      }
    }
    const tilesToAdd = tilesToRequest.filter(
      (tile) =>
        !terrainPatchesRequested.some(
          (tp) =>
            tp.x === tile.x && tp.y === tile.y && TERRAIN_ZOOM_LEVEL === tile.z,
        ),
    );

    if (tilesToAdd.length !== 0) {
      setTerrainPatchesRequested((tpr) => [...tpr, ...tilesToAdd]);
    }
  }, [park, terrainPatchesRequested, setTerrainPatchesRequested]);

  useEffect(() => {
    if (!viewPosition || !cameraAspect || !viewTowards || !viewTowardsWGS84)
      return;

    if (terrainPatchesRequested.length >= MAX_TILES) {
      info(
        `Maximum number of ${MAX_TILES} terrain tiles reached, please close analysis and open again to restart`,
      );
      return;
    }

    const distanceKm =
      getDistanceFromLatLonInM(viewTowardsWGS84.geometry.coordinates, [
        viewPosition.lng,
        viewPosition.lat,
      ]) / 1000;

    if (distanceKm > MAX_DISTANCE_FROM_VIEW_POINT) {
      info(
        `Camera too far away from view point to load terrain, please stay within ${MAX_DISTANCE_FROM_VIEW_POINT}km to trigger terrain fetching`,
      );
      return;
    }

    const verticalFOV = verticalFovToHorizontalFov(cameraAspect, fov);
    const fovLines = getFOVLines(
      viewTowards,
      verticalFOV,
      proj4String,
      viewPosition,
    );

    if (fovLines.length === 0) return;

    const highResolutionTile = lonLatToTileFloat(
      viewPosition.lng,
      viewPosition.lat,
      TERRAIN_ZOOM_LEVEL,
    );
    const highResolutionTilesToRequest = [];
    for (
      let x = Math.floor(
        highResolutionTile.x - HIGH_RESOLUTION_INCLUSION_SLACK,
      );
      x <= Math.floor(highResolutionTile.x + HIGH_RESOLUTION_INCLUSION_SLACK);
      x++
    ) {
      for (
        let y = Math.floor(
          highResolutionTile.y - HIGH_RESOLUTION_INCLUSION_SLACK,
        );
        y <= Math.floor(highResolutionTile.y + HIGH_RESOLUTION_INCLUSION_SLACK);
        y++
      ) {
        highResolutionTilesToRequest.push({ x, y, z: TERRAIN_ZOOM_LEVEL });
      }
    }

    const triangle = turf.polygon([
      [
        ...fovLines[0].geometry.coordinates,
        ...[...fovLines[1].geometry.coordinates].reverse(),
        fovLines[0].geometry.coordinates[0],
      ],
    ]);
    const bboxLines = fovLines
      .map((line) => turf.bbox(line))
      .reduce(
        (finalBBOX, bbox) => [
          Math.min(finalBBOX[0], bbox[0]),
          Math.min(finalBBOX[1], bbox[1]),
          Math.max(finalBBOX[2], bbox[2]),
          Math.max(finalBBOX[3], bbox[3]),
        ],
        [
          Number.MAX_VALUE,
          Number.MAX_VALUE,
          Number.MIN_VALUE,
          Number.MIN_VALUE,
        ],
      );

    const tilesToRequest = boundingBoxToPolygonTiles(
      bboxLines,
      TERRAIN_ZOOM_LEVEL,
    )
      .filter(
        (polygon) =>
          turf.booleanOverlap(triangle, polygon) ||
          turf.booleanContains(triangle, polygon),
      )
      .map((polygon) => polygon.properties!.tile as Tile);

    const loadingTilesNr = loadingTiles.length;

    const tilesToAdd = tilesToRequest
      .filter(
        (tile) => !terrainPatchesRequested.some((tp) => tileEquality(tp, tile)),
      )
      .slice(0, MAX_PARALLELL_NEW_TILES_TO_ADD - loadingTilesNr);

    if (tilesToAdd.length !== 0) {
      setTerrainPatchesRequested((tpr) => [...tpr, ...tilesToAdd]);
    }

    const highResolutionTilesToAdd = highResolutionTilesToRequest.filter(
      (tile) =>
        !highResolutionPatchesRequested.some((tp) => tileEquality(tp, tile)),
    );
    if (highResolutionTilesToAdd.length !== 0) {
      setHighResolutionPatchesRequested((hrp) => [
        ...hrp,
        ...highResolutionTilesToAdd,
      ]);
    }
  }, [
    viewPosition,
    viewTowards,
    viewTowardsWGS84,
    cameraAspect,
    fov,
    proj4String,
    setTerrainPatchesRequested,
    terrainPatchesRequested,
    highResolutionPatchesRequested,
    setHighResolutionPatchesRequested,
    loadingTiles,
    info,
  ]);

  useEffect(() => {
    terrainPatches.forEach((terrain) => {
      if (viewMode === VIEW_MODE.NATURAL_MODE) {
        terrain.material.wireframe = false;
        terrain.material.transparent = true;
      } else if (viewMode === VIEW_MODE.WIRE_FRAME_MODE) {
        terrain.material.wireframe = true;
        terrain.material.transparent = false;
      }
    });
  }, [terrainPatches, viewMode]);

  useEffect(() => {
    if (!terrainColorActive) return;
    const maps = terrainPatches.map((terrain) => terrain.material.map);
    const rgb = hexToRgb(terrainColor);
    const color = new Color(rgb);
    terrainPatches.forEach((terrain) => {
      terrain.material.color.set(color);
      terrain.material.map = null;
    });
    return () => {
      terrainPatches.forEach((terrain, i) => {
        terrain.material.color.set(new Color(0xffffff));
        terrain.material.map = maps[i];
      });
    };
  }, [terrainColorActive, terrainColor, terrainPatches]);

  useEffect(() => {
    if (!threeCore || !viewFromShoreTerrainVisible) return;
    const { scene } = threeCore;
    terrainPatches.map((terrain) => scene.add(terrain));
    threeCore.renderer.render(threeCore.scene, threeCore.camera);
    setTerrainPatchesAddedToSceneRefresher((r) => r + 1);
    return () => {
      terrainPatches.map((terrain) => {
        scene.remove(terrain);
        disposeObject(terrain);
      });
    };
  }, [
    threeCore,
    terrainPatches,
    viewFromShoreTerrainVisible,
    setTerrainPatchesAddedToSceneRefresher,
  ]);

  return null;
}
