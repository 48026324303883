import { libraryCableRefreshAtom } from "components/Cabling/Generate/state";
import { initializeAndSet } from "components/Comments/hooks/useReplyReactionCrud";
import useOrgAnalysisCrud from "components/Organisation/Library/analysis/useOrgAnalysisCrud";
import {
  analysisResourceWithAccessOnNodeState,
  financialResourceWithAccessOnNodeState,
  turbineResourceWithAccessOnNodeState,
} from "components/Organisation/Library/state";
import useOrgCableCrud from "components/Organisation/Library/cable/useOrgCableCrud";
import useOrgTurbineCrud from "components/Organisation/Library/turbine/useOrgTurbineCrud";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { useRecoilCallback } from "recoil";
import { Configuration } from "services/configurationService";
import { CableType } from "services/cableTypeService";
import { getAdvancedProjectTurbine } from "services/turbineAPIService";
import { cableResourceWithAccessOnNodeState } from "state/cableType";
import { SimpleTurbineType } from "types/turbines";
import { scream } from "utils/sentry";
import useOrgFinancialCrud from "components/Organisation/Library/financial/useOrgFinancialCrud";
import { CostConfiguration } from "services/costService";
import { libraryFinancialConfigRefreshAtom } from "state/costConfigurations";

export function useDuplicateTurbineToLibrary() {
  const orgTurbineCrud = useOrgTurbineCrud();
  const { error } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const duplicateToLibrary = useRecoilCallback(
    ({ set, snapshot }) =>
      async (nodeId: string, simpleTurbine: SimpleTurbineType) => {
        setIsLoading(true);
        try {
          const turbine = await getAdvancedProjectTurbine(
            nodeId,
            simpleTurbine.id,
            undefined,
          );
          const newTurbine = await orgTurbineCrud.duplicate({
            turbine: turbine,
            name: `${turbine.name} (duplicate)`,
            projectAccess: [nodeId],
          });
          initializeAndSet(
            snapshot,
            set,
            turbineResourceWithAccessOnNodeState({
              nodeId: nodeId,
            }),
            (curr) => [
              ...curr,
              { nodeId, turbine: { ...simpleTurbine, id: newTurbine.id } },
            ],
          );
        } catch (e) {
          scream("Error when duplicating project turbine to library", { e });
          error(
            "Something went wrong while trying to duplicate project turbine to library, we have been notified",
          );
        } finally {
          setIsLoading(false);
        }
      },
    [error, orgTurbineCrud],
  );

  return { duplicateToLibrary, isLoading };
}

export function useDuplicateCableToLibrary() {
  const orgCableCrud = useOrgCableCrud();
  const { error } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const duplicateToLibrary = useRecoilCallback(
    ({ set, snapshot }) =>
      async (nodeId: string, cable: CableType) => {
        setIsLoading(true);
        try {
          const newCable = await orgCableCrud.duplicate({
            cable: cable,
            name: `${cable.name} (duplicate)`,
            projectAccess: [nodeId],
          });
          initializeAndSet(
            snapshot,
            set,
            cableResourceWithAccessOnNodeState({
              nodeId: nodeId,
            }),
            (curr) => [
              ...curr,
              { nodeId, cable: { ...cable, id: newCable.id } },
            ],
          );
          set(libraryCableRefreshAtom, (cur) => cur + 1);
        } catch (e) {
          scream("Error when duplicating project cable to library", { e });
          error(
            "Something went wrong while trying to duplicate project cable to library, we have been notified",
          );
        } finally {
          setIsLoading(false);
        }
      },
    [error, orgCableCrud],
  );

  return { duplicateToLibrary, isLoading };
}

export const useDuplicateAnalysisToLibrary = () => {
  const { duplicate } = useOrgAnalysisCrud();
  const { error } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const duplicateToLibrary = useRecoilCallback(
    ({ refresh }) =>
      async (nodeId: string, configuration: Configuration) => {
        setIsLoading(true);
        try {
          await duplicate({
            analysis: configuration,
            name: `${configuration.name} (duplicate)`,
            projectAccess: [nodeId],
          });

          refresh(analysisResourceWithAccessOnNodeState({ nodeId }));
        } catch (e) {
          scream("Error when duplicating project analysis to library", { e });
          error(
            "Something went wrong while trying to duplicate project analysis to library, we have been notified",
          );
        } finally {
          setIsLoading(false);
        }
      },
    [setIsLoading, error, duplicate],
  );

  return { duplicateToLibrary, isLoading };
};

export const useDuplicateFinancialToLibrary = () => {
  const { duplicate } = useOrgFinancialCrud();
  const { error } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const duplicateToLibrary = useRecoilCallback(
    ({ set, snapshot }) =>
      async (nodeId: string, configuration: CostConfiguration) => {
        setIsLoading(true);
        try {
          const newConfig = await duplicate({
            config: configuration,
            name: `${configuration.name} (duplicate)`,
            projectAccess: [nodeId],
          });

          initializeAndSet(
            snapshot,
            set,
            financialResourceWithAccessOnNodeState({
              nodeId: nodeId,
            }),
            (curr) => [...curr, { nodeId, config: newConfig }],
          );
          set(libraryFinancialConfigRefreshAtom, (cur) => cur + 1);
        } catch (e) {
          scream("Error when duplicating project financial to library", { e });
          error(
            "Something went wrong while trying to duplicate project financial to library, we have been notified",
          );
        } finally {
          setIsLoading(false);
        }
      },
    [setIsLoading, error, duplicate],
  );

  return { duplicateToLibrary, isLoading };
};
