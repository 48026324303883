/// <reference types="vite-plugin-svgr/client" />
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";
import CableIcon from "@icons/24/Cabling-2.svg?react";
import DeleteIcon from "@icons/24/Bin.svg?react";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";
import {
  organisationIdSelector,
  projectIdSelector,
} from "../../../../state/pathParams";
import { initializeAndSet } from "../../../Comments/hooks/useReplyReactionCrud";

import useCableTypeCrud from "../../../../hooks/useCableTypeCrud";
import { CableType } from "../../../../services/cableTypeService";
import {
  getCableTypeUsage,
  cableTypeUsageAtomFamily,
} from "../../../../state/cable";
import { inReadOnlyModeSelector } from "../../../../state/project";
import {
  libraryCableOnNodeTypesState,
  projectCableTypesState,
} from "../../../Cabling/Generate/state";
import { SingleCable } from "../../../ConfigurationModal/CableSettings";
import { selectedMenuItemState } from "../../Shared/state";
import { useToast } from "hooks/useToast";
import { scream } from "utils/sentry";
import { SettingsSubMenuProp } from "components/SettingsV2/Shared/types";
import { DEFAULT_CABLE } from "types/cables";
import { IconREMSize } from "styles/typography";
import Earth from "@icons/14/Earth.svg?react";
import Library from "@icons/24/Library.svg?react";
import {
  editorAccessProjectSelector,
  orgCableManageAccessSelector,
} from "state/user";
import { useLocation, useNavigate } from "react-router-dom";
import { selectedOrgTabState } from "components/Organisation/state";
import { useDuplicateCableToProject } from "./useDuplicateToProject";
import { useDuplicateCableToLibrary } from "./useDuplicateToLibrary";
import Button from "components/General/Button";
import AddIcon from "@icons/24/Add.svg?react";
import { Anchor } from "components/General/Anchor";
import CableModal from "components/Organisation/OrganisationRightSide/content/ResourceContent/CableModal";
import { cableResourceWithAccessOnNodeState } from "state/cableType";
import useNodeCableResourcesCrud from "components/Organisation/OrganisationRightSide/content/NodeContent/useNodeCableResourcesCrud";
import { libraryAllSelectorFamily } from "state/featureAccess";

export const CABLE_MENU_ID = "cables";

export default function useCableSettings() {
  const projectId = useRecoilValue(projectIdSelector) ?? "";
  const organisationId = useRecoilValue(organisationIdSelector) ?? "";
  const orgCableManageAccess = useRecoilValue(orgCableManageAccessSelector);
  const editorAccessProject = useRecoilValue(editorAccessProjectSelector);
  const allLibraryAccess = useRecoilValue(
    libraryAllSelectorFamily({ organisationId }),
  );

  const navigate = useNavigate();
  const location = useLocation();
  const setOrgtab = useSetRecoilState(selectedOrgTabState);
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [saveInProgressLibrary, setSaveInProgressLibrary] =
    useState<boolean>(false);

  const isReadOnly = useRecoilValue(inReadOnlyModeSelector);
  const { update, create, deleteCable } = useCableTypeCrud();
  const projectCables = useRecoilValue(projectCableTypesState);
  const { success } = useToast();

  const { duplicateToLibrary } = useDuplicateCableToLibrary();
  const { duplicateToProject } = useDuplicateCableToProject();

  const [menuSelection, setMenuSelection] = useRecoilState(
    selectedMenuItemState({ menuId: CABLE_MENU_ID, projectId }),
  );

  useEffect(() => {
    if (!menuSelection && projectCables[0])
      setMenuSelection(projectCables[0].id);
  }, [projectCables, menuSelection, setMenuSelection]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const libraryCables = useRecoilValueLoadable(
    libraryCableOnNodeTypesState({ nodeId: projectId }),
  );

  const availableLibraryCables = useMemo(() => {
    return libraryCables.valueMaybe() ?? [];
  }, [libraryCables]);

  const addCable = useCallback(
    async (cable: Partial<CableType>) => {
      setSaveInProgress(true);
      setIsLoading(true);
      const newCable = {
        ...cable,
        name: `${cable.name} copy`,
      };
      return create(newCable)
        .then((res) => setMenuSelection(res.id))
        .finally(() => {
          setSaveInProgress(false);
          setIsLoading(false);
        });
    },
    [create, setMenuSelection, setSaveInProgress],
  );

  const _onDuplicate = useCallback(
    async (id: string) => {
      const cable = projectCables.find((cable) => cable.id === id);
      if (!cable) throw scream("Tried to duplicate a non existing cable");

      setSaveInProgress(true);
      setIsLoading(true);
      const clone: Partial<CableType> = {
        ...cable,
        name: `${cable.name} copy`,
      };
      delete clone["id"];

      return create(clone)
        .then((res) => setMenuSelection(res.id))
        .finally(() => {
          setSaveInProgress(false);
          setIsLoading(false);
        });
    },
    [projectCables, create, setMenuSelection, setSaveInProgress],
  );

  const _onDelete = useCallback(
    async (cableId: string) => {
      if (!projectId) return;
      setIsLoading(true);
      const usage = await getCableTypeUsage(projectId, cableId);
      if (
        usage.length === 0 ||
        window.confirm(
          `Cable type is currently being used in ${usage.length} cables, are you sure you want to delete it?`,
        )
      ) {
        try {
          await deleteCable(cableId);
          setMenuSelection(undefined);
        } catch {}
      }
      setIsLoading(false);
    },
    [deleteCable, projectId, setMenuSelection],
  );

  const _onUpdate = useRecoilCallback(
    ({ set, snapshot }) =>
      async (newCable: CableType) => {
        setIsLoading(true);

        const usage = await getCableTypeUsage(projectId, newCable.id);
        await initializeAndSet(
          snapshot,
          set,
          cableTypeUsageAtomFamily({
            nodeId: projectId,
            cableTypeId: newCable.id,
          }),
          usage,
        );

        if (
          usage.length === 0 ||
          window.confirm(
            `Cable type is currently being used in ${usage.length} cables, are you sure you want to update it?`,
          )
        ) {
          await update(newCable).catch(() => {});
          success("Saved");
        }
        setIsLoading(false);
      },
    [projectId, success, update],
  );

  const cableSubMenus: SettingsSubMenuProp[] = useMemo(() => {
    const projectCableList = projectCables.map((cable) => {
      let dotMenuOptions:
        | {
            title: string;
            onSelect: (id: string) => void;
            icon: ReactElement;
          }[]
        | undefined = [];

      dotMenuOptions.push({
        title: "Delete",
        onSelect: _onDelete,
        icon: <DeleteIcon />,
      });

      if (orgCableManageAccess) {
        dotMenuOptions.push({
          title: "Duplicate to library",
          onSelect: () => {
            setSaveInProgressLibrary(true);
            duplicateToLibrary(projectId, cable).then(() =>
              setSaveInProgressLibrary(false),
            );
          },
          icon: <DuplicateIcon />,
        });
      }

      return {
        id: cable.id,
        name: cable.name,
        label: `${cable.name} (${cable.voltage}kV)`,
        loading: isLoading,
        content: (
          <div style={{ height: "100%", position: "relative" }}>
            <SingleCable
              disabled={isReadOnly}
              key={cable.id}
              cable={cable}
              onSave={_onUpdate}
              isLoading={isLoading}
              isLibraryCable={false}
            />
          </div>
        ),
        onDuplicate: _onDuplicate,
        onChangeName: (newName: string) => {
          return _onUpdate({ ...cable, name: newName });
        },
        dotMenuOptions,
      };
    });

    const libraryCableList = availableLibraryCables.map((cable) => {
      let dotMenuOptions:
        | {
            title: string;
            onSelect: (id: string) => void;
            icon: ReactElement;
          }[]
        | undefined = [];

      if (orgCableManageAccess) {
        dotMenuOptions.push({
          title: "Edit in library",
          onSelect: () => {
            setOrgtab("Library");
            navigate(`/organisation/${organisationId}${location.search}`);
          },
          icon: <Library />,
        });
      }
      if (editorAccessProject) {
        dotMenuOptions.push({
          title: "Duplicate to project",
          onSelect: () => {
            setSaveInProgress(true);
            duplicateToProject(cable).then(() => setSaveInProgress(false));
          },
          icon: <DuplicateIcon />,
        });
      }

      if (dotMenuOptions.length === 0) dotMenuOptions = undefined;

      return {
        id: cable.id,
        name: cable.name,
        label: `${cable.name} (${cable.voltage}kV)`,
        loading: isLoading,
        content: (
          <div style={{ height: "100%", position: "relative" }}>
            <SingleCable
              disabled={true}
              key={cable.id}
              cable={cable}
              isLibraryCable={true}
              isLoading={isLoading}
            />
          </div>
        ),
        dotMenuOptions,
      };
    });

    const libraryCablesMenu: SettingsSubMenuProp = {
      title: "Library array cables",
      items: libraryCableList,
      icon: (
        <IconREMSize height={1.4} width={1.4}>
          <Library />
        </IconREMSize>
      ),
      create: {
        type: "element",
        saveInProgress: saveInProgressLibrary,
        element: <AddCableResourceToNode nodeId={projectId} />,
      },
    };

    const projectCablesMenu: SettingsSubMenuProp = {
      items: projectCableList,
      title: "Project array cables",
      icon: (
        <IconREMSize height={1.4} width={1.4}>
          <Earth />
        </IconREMSize>
      ),
      create: {
        type: "action",
        title: "New project cable type",
        onCreate: () => addCable(DEFAULT_CABLE),
        saveInProgress: saveInProgress,
        disabled: isLoading,
      },
    };

    if (allLibraryAccess) return [libraryCablesMenu, projectCablesMenu];
    return [projectCablesMenu];
  }, [
    projectCables,
    availableLibraryCables,
    orgCableManageAccess,
    projectId,
    isLoading,
    allLibraryAccess,
    _onDelete,
    isReadOnly,
    _onUpdate,
    _onDuplicate,
    duplicateToLibrary,
    setOrgtab,
    navigate,
    organisationId,
    location.search,
    duplicateToProject,
    addCable,
    editorAccessProject,
    saveInProgress,
    saveInProgressLibrary,
  ]);

  const cableSettings = useMemo(() => {
    return {
      id: CABLE_MENU_ID,
      label: "Array cables",
      icon: <CableIcon />,
      submenus: cableSubMenus,
    };
  }, [cableSubMenus]);

  return cableSettings;
}

function AddCableResourceToNode({ nodeId }: { nodeId: string }) {
  const cableResources = useRecoilValue(
    cableResourceWithAccessOnNodeState({ nodeId }),
  );
  const ref = useRef<HTMLDivElement>(null);
  const { addOrUpdate: addOrUpdateCable } = useNodeCableResourcesCrud();
  const [showAddCable, setShowAddCable] = useState(false);
  const orgCableManageAccess = useRecoilValue(orgCableManageAccessSelector);
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);

  return (
    <div ref={ref} style={{ position: "relative", marginLeft: "auto" }}>
      <Button
        buttonType="secondary"
        onClick={() => setShowAddCable(true)}
        icon={<AddIcon />}
        disabled={!orgCableManageAccess}
        size="small"
      />
      {showAddCable && (
        <Anchor baseRef={ref} basePlace={"topRight"} floatPlace={"topLeft"}>
          <CableModal
            onSave={async (cables) => {
              setSaveInProgress(true);
              await Promise.all(
                cables.map((c) => addOrUpdateCable(nodeId, c.id)),
              );
              setSaveInProgress(false);
              setShowAddCable(false);
            }}
            existingCables={cableResources.map((c) => c.cable.id)}
            isSaving={saveInProgress}
          />
        </Anchor>
      )}
    </div>
  );
}
