export const fillOpacityWithCallback = (
  opacity: number,
  selectedOpacity: number,
) => [
  "case",
  ["boolean", ["feature-state", "hover"], ["feature-state", "selected"], false],
  selectedOpacity,
  ["boolean", ["feature-state", "inFocus"], false],
  selectedOpacity,
  opacity,
];

export function calculatePixelRadius(
  radiusInMeters: number,
  latitude: number,
  zoomLevel: number,
) {
  const R = 6371000; // Earth's radius in meters
  const φ = (latitude * Math.PI) / 180; // latitude in radians
  const scale = Math.cos(φ) * 2 * Math.PI * R * (1 / 2 ** (zoomLevel + 8));
  return radiusInMeters / scale;
}

const radiusInMeters = 400; // Could be dynamic and reflect diameter of turbine
const latitude = 59.9139; // Oslo

// Calculate circle sizes
export const radiusAtZoom5 = calculatePixelRadius(radiusInMeters, latitude, 5);
export const radiusAtZoom10 = calculatePixelRadius(
  radiusInMeters,
  latitude,
  10,
);

const SELECTED_OUTLINE_LAYER_ID_SUFFIX = "selected-outline-layer-id";
export const outlineLayer = (s: string) =>
  `${s}-${SELECTED_OUTLINE_LAYER_ID_SUFFIX}`;
