import { NotAuthorizedError } from "../components/ErrorBoundaries/types";
import { PROJECT_SERVICE_API_PATH } from "../components/ProjectElements/service";
import { accessToken } from "../state/global";
import { parseJWT } from "../utils/jwt";
import { dateToDateTime } from "../utils/utils";
import { fetchWithToken } from "./utils";

function onAbly403() {
  const expires = parseJWT(accessToken ?? "").exp;
  const date = new Date(expires * 1000);
  console.error(
    `Not authorized to access Ably. Attempted to fetch ably with access token that expires on ${dateToDateTime(
      date,
    )}, current date is ${dateToDateTime(new Date())}`,
  );
}

export const getAblyToken = async (organisationId: string) => {
  const res = await fetchWithToken(
    `${PROJECT_SERVICE_API_PATH}/ably/organisation/${organisationId}`,
    {
      method: "get",
      headers: {},
    },
  );
  if (res.status === 403) {
    onAbly403();
    return null;
  }
  if (res.status !== 200) {
    throw new NotAuthorizedError("Failed to fetch ably token");
  }
  const token = (await res.json()) as string;
  return token;
};
