import { atom, DefaultValue } from "recoil";
import { BufferModalType } from "../components/BufferModal/BufferModal";
import {
  BranchSnapshotModalHooks,
  BranchSnapshotModalType,
  EditBranchSnapshotMetadata,
  EditBranchSnapshotModalType,
} from "../components/Design/DesignNavigation/TimelineModals";
import { FilterExternalDataLayersModalType } from "../components/FilterExternalDataLayers/FilterExternalDataLayers";
import { UserProfileModalType } from "../components/UserProfileModal/UserProfileModal";
import { CompareParksModalType } from "../components/CompareParksModal/CompareParksModalType";
import { RenameParkModalType } from "../components/RenameModal/RenameParkModal";
import { CopyCollectionModalType } from "../components/CopyCollectionModal/CopyCollectionModal";
import {
  TeamConfigurationModalType,
  ConfigurationModalType,
  ProjectOverviewModalType,
  MenuItems,
  FeatureSettingsModalTypeV2,
  ProjectConfigModalTypeV2,
  PublicModeModalTypeV2,
} from "./configuration";
import { LoggedInUser } from "../types/user";
import { CustomerPersona } from "../types/customer";
import { CustomDataEntry } from "./../types/layers";
import { FeatureType, ProjectFeature } from "../types/feature";
import { ValidationWarningModalType } from "../components/ValidationWarnings/constants";
import { reloadForNewVersionModalType } from "../components/ReloadForNewVersionModal/ReloadForNewVersionModal";
import { FeatureVersionsModalType } from "../components/FeatureVersionsPopup/FeatureVersionsPopup";
import { AddDataLayersModalType } from "../components/DataLayersModal/DataLayersModal";
import { SelectOverlapBufferModalType } from "../components/SelectOverlapBufferModal/SelectOverlapBufferModal";
import {
  UploadModalType,
  UploadFileType,
} from "../components/UploadModal/UploadModal";
import { OrgSettingsModalType } from "../components/Organisation/OrgSettingsModal";
import { AddLayerSourceInternalModalType } from "../components/AddLayerSourceInternalModal/AddLayerSourceInternalModal";
import { AddNewDataSourceModalToProjectType } from "../components/LayerList/AddNewSourceModal/AddNewSourceToProjectModal";
import { KeyboardShortcutsModalType } from "components/LearnVind/KeyboardShortcutsModal";
import { VideoModalType } from "components/LearnVind/VideoModal";
import { DownloadCustomCRSModalType } from "components/DownloadCustomCRSModal/DownloadCustomCRSModal";
import { AddCustomCRSModalType } from "components/CustomCRSDropdown/CustomCRSDropdown";
import { UploadOrganisationDataLayerModalType } from "components/Organisation/Library/dataLibrary/modals/UploadOrganisationLayerModal";
import { EditVectordataType } from "components/Organisation/Library/dataLibrary/Edit/EditVectordata";
import { UploadOrganisationLayerFromCoordinatesModalType } from "components/Organisation/Library/dataLibrary/modals/UploadOrganisationLayerFromCoordinatesModal";
import { AddLayersFromAllDataListModalType } from "components/Organisation/Library/dataLibrary/modals/AddLayersFromAllDataListModal";
import { NewArchivedVersionModalType } from "components/Design/ArchiveProjectVersion/Modal/NewModal";
import { ArchivedVersionSuccessModalType } from "components/Design/ArchiveProjectVersion/Modal/SuccessModal";

export const DashboardModalType = "DashboardModal";

export type ModalType = (
  | ArchivedVersionSuccessModalType
  | NewArchivedVersionModalType
  | { modalType: typeof OrgSettingsModalType }
  | {
      modalType: typeof EditVectordataType;
      metadata: {
        layerId: string;
      };
    }
  | { modalType: typeof AddCustomCRSModalType }
  | { modalType: typeof reloadForNewVersionModalType }
  | {
      modalType: typeof AddNewDataSourceModalToProjectType;
      metadata?: CustomDataEntry;
    }
  | {
      modalType: typeof BranchSnapshotModalType;
      metadata?: {
        hooks?: BranchSnapshotModalHooks;
        version?: number;
      };
    }
  | {
      modalType: typeof EditBranchSnapshotModalType;
      metadata: EditBranchSnapshotMetadata;
    }
  | {
      modalType: typeof BufferModalType;
      metadata?: {
        selection: ProjectFeature | ProjectFeature[];
      };
    }
  | { modalType: typeof ConfigurationModalType }
  | {
      modalType: typeof ProjectConfigModalTypeV2;
      metadata?: { selectedMenuId?: string; selectedConfigId?: string };
    }
  | {
      modalType: typeof FeatureSettingsModalTypeV2;
      metadata?: { selectedMenuId?: string };
    }
  | {
      modalType: typeof PublicModeModalTypeV2;
      metadata?: { selectedMenuId?: string };
    }
  | {
      modalType: typeof FilterExternalDataLayersModalType;
      metadata: {
        source: string;
        property: string;
      };
    }
  | { modalType: typeof ProjectOverviewModalType }
  | { modalType: typeof RenameParkModalType }
  | {
      modalType: typeof TeamConfigurationModalType;
      metadata?: {
        tab: MenuItems;
      };
    }
  | {
      modalType: typeof UserProfileModalType;
      metadata: {
        user: LoggedInUser | CustomerPersona;
        tab?: "settings" | "notifications";
      };
    }
  | { modalType: typeof ValidationWarningModalType }
  | { modalType: typeof CompareParksModalType }
  | {
      modalType: typeof CopyCollectionModalType;
      metadata: {
        collectionId: string;
      };
    }
  | {
      modalType: typeof FeatureVersionsModalType;
      metadata: {
        featureId: string;
      };
    }
  | { modalType: typeof AddDataLayersModalType }
  | {
      modalType: typeof SelectOverlapBufferModalType;
      metadata: { featureId: string };
    }
  | {
      modalType: typeof UploadModalType;
      metadata?: {
        preSelectedFileType?: UploadFileType;
        preSelectedFeatureType?: ProjectFeature["properties"]["type"];
        droppedFiles?: File[];
      };
    }
  | {
      modalType: typeof AddLayerSourceInternalModalType;
    }
  | {
      modalType: typeof DashboardModalType;
      metadata?: {
        id?: string;
        branchId?: string;
        parkId?: string;
        configurationId?: string;
        windConfigurationId?: string;
      };
    }
  | {
      modalType: typeof KeyboardShortcutsModalType;
    }
  | {
      modalType: typeof VideoModalType;
      metadata: {
        title: string;
        videoId: string;
      };
    }
  | {
      modalType: typeof DownloadCustomCRSModalType;
      metadata: {
        featureIds: string[];
        name: string;
        loadingId: string;
        types?: FeatureType[];
      };
    }
  | {
      modalType: typeof UploadOrganisationDataLayerModalType;
      metadata?: {
        fileIdToReplace?: string;
        addToPackageId?: string;
      };
    }
  | {
      modalType: typeof UploadOrganisationLayerFromCoordinatesModalType;
      metadata?: {
        addToPackageId?: string;
      };
    }
  | {
      modalType: typeof AddLayersFromAllDataListModalType;
      metadata: {
        packageId: string;
      };
    }
) & {
  backTo?: ModalType;
};

export type ModalTypeType = ModalType["modalType"];

/** Convenience type wrapper to name a {@link ModalType} */
export type Modal<T> = ModalType & { modalType: T };

export const modalTypeOpenAtom = atom<ModalType | undefined>({
  key: "modalTypeOpenAtom",
  default: undefined,
  effects: [
    ({ onSet, setSelf }) => {
      onSet((newValue, oldValue, isReset) => {
        if (isReset) {
          setSelf(undefined);
          return;
        }
        // When trying to close the modal and it has a backTo value, set it to the backTo value
        if (
          typeof newValue === "undefined" &&
          oldValue &&
          !(oldValue instanceof DefaultValue) &&
          oldValue.backTo
        ) {
          setSelf(oldValue.backTo);
        }
      });
    },
  ],
});
