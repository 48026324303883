import { useToast } from "hooks/useToast";
import { useCallback, useState } from "react";
import { getArchivedVersionDownloadUrl } from "../service";
import { dateToFilenameSuffix } from "utils/utils";
import { ArchivedProjectVersion } from "../types";

export function useDownloadFile() {
  const { info } = useToast();
  const [inProgress, setInProgress] = useState(false);

  const download = useCallback(
    async (version: ArchivedProjectVersion) => {
      info("1/2 - Fetching data...");
      setInProgress(true);
      const { organisationId, nodeId, branchId, id, name, createdAt } = version;
      const url = await getArchivedVersionDownloadUrl({
        organisationId,
        projectId: nodeId,
        branchId,
        versionId: id,
      });
      const response = await fetch(url);
      const blob = await response.blob();

      info("2/2 - Downloading your file");
      const anchor = document.createElement("a");
      const blobUrl = URL.createObjectURL(blob);
      anchor.href = blobUrl;
      anchor.download = `${name}-${dateToFilenameSuffix(new Date(createdAt))}.zip`;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      URL.revokeObjectURL(blobUrl);
      setInProgress(false);
    },
    [info],
  );

  return { download, inProgress };
}
