import Button from "components/General/Button";
import { InitialsImageRound } from "components/UserImage/UserImageRound";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useTypedPath } from "state/pathParams";
import { organisationAnalysisResourceState } from "../state";
import {
  ContentColumn,
  ContentHeaderButtonsContainer,
  ContentHeaderRow,
  MainRow,
  ResourceColumn,
  ResourceItem,
  ResourceName,
  TabContainer,
} from "../style";
import AddIcon from "@icons/24/Add.svg?react";
import Trashcan from "@icons/24/Bin.svg?react";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { Column } from "components/General/Layout";
import useOrgAnalysisCrud from "./useOrgAnalysisCrud";
import { DotMenu } from "components/General/MenuButton";
import { MenuItem } from "components/General/Menu";
import OpenMoreInfo from "components/General/MoreInfoButton";
import { Configuration } from "services/configurationService";
import AnalysisSettingCustomAdmin from "components/ConfigurationModal/AnalysisSettings/AnalysisSettingCustomAdmin";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { configurationTempName } from "state/configuration";

export default function AnalysisTab() {
  const { organisationId } = useTypedPath("organisationId");
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [deleteInProgress, setDeleteInProgress] = useState<
    string | undefined
  >();
  const analysis = useRecoilValue(
    organisationAnalysisResourceState({ organisationId }),
  );
  const [selectedItemId, setSelectedItemId] = useState<string | undefined>(
    undefined,
  );
  const setContent = useSetRecoilState(
    organisationRightSideModal(organisationId),
  );

  const isLoading = useMemo(
    () => saveInProgress || !!deleteInProgress,
    [saveInProgress, deleteInProgress],
  );

  useEffect(() => {
    if (!selectedItemId && analysis.length > 0 && !saveInProgress) {
      setContent({ type: "resource", id: analysis[0].id });
      setSelectedItemId(analysis[0].id);
    }
  }, [selectedItemId, setContent, analysis, saveInProgress]);

  const selectedItem = useMemo(
    () => analysis.find((t) => t.id === selectedItemId),
    [selectedItemId, analysis],
  );

  const newItemRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const newItem = newItemRef.current;
    if (saveInProgress && newItem) {
      newItem.scrollIntoView();
    }
  }, [saveInProgress]);

  const { create, remove, duplicate, saveTempNameToLocal } =
    useOrgAnalysisCrud();

  const tempConfigNames = useRecoilValue(
    configurationTempName({ nodeId: organisationId }),
  );

  const onUnmount = useCallback(() => {
    analysis.forEach((c) => {
      if (saveTempNameToLocal) {
        saveTempNameToLocal(c.id);
      }
    });
  }, [saveTempNameToLocal, analysis]);

  return (
    <TabContainer>
      <MainRow>
        <ResourceColumn>
          <Button
            buttonType="secondary"
            icon={<AddIcon />}
            text={"New analysis configuration"}
            disabled={isLoading}
            onClick={async () => {
              setSaveInProgress(true);
              setSelectedItemId(undefined);
              const res = await create({});
              if (res) {
                setSelectedItemId(res.id);
                setContent({ type: "resource", id: res.id });
                setSaveInProgress(false);
              }
            }}
          />
          <Column style={{ gap: "0.8rem", overflowY: "auto" }}>
            {analysis.map((t) => {
              return (
                <ResourceItem
                  key={t.id}
                  onClick={() => {
                    setSelectedItemId(t.id);
                    setContent({ type: "resource", id: t.id });
                  }}
                  selected={selectedItemId === t.id}
                  disabled={deleteInProgress === t.id}
                >
                  <InitialsImageRound name={t.name ?? "Analysis"} size={2.2} />
                  <ResourceName>{tempConfigNames[t.id] ?? t.name}</ResourceName>
                  {!(deleteInProgress === t.id) && (
                    <DotMenu
                      divStyle={{
                        marginLeft: "auto",
                        width: "2.5rem",
                      }}
                    >
                      <MenuItem
                        name={"Duplicate"}
                        onClick={async () => {
                          setSaveInProgress(true);
                          setSelectedItemId(undefined);
                          const res = await duplicate({ analysis: t });
                          if (res) {
                            setSelectedItemId(res.id);
                            setContent({ type: "resource", id: res.id });
                            setSaveInProgress(false);
                          }
                        }}
                        icon={<DuplicateIcon />}
                      />
                      <MenuItem
                        name={"Delete"}
                        onClick={async () => {
                          setDeleteInProgress(t.id);
                          const res = await remove(t.id);
                          if (res.status === "success") {
                            setDeleteInProgress(undefined);
                            setSelectedItemId(analysis[0]?.id);
                            setContent({
                              type: "resource",
                              id: analysis[0]?.id,
                            });
                          }
                        }}
                        icon={<Trashcan />}
                      />
                    </DotMenu>
                  )}
                </ResourceItem>
              );
            })}
            {saveInProgress && (
              <ResourceItem
                key={"save-in-progress"}
                selected={true}
                ref={newItemRef}
              >
                <SkeletonBlock
                  style={{ height: "4rem", marginTop: "0.8rem" }}
                />
              </ResourceItem>
            )}
          </Column>
        </ResourceColumn>
        {selectedItem && (
          <AnalysisContent analysis={selectedItem} onUnmount={onUnmount} />
        )}
      </MainRow>
    </TabContainer>
  );
}

function AnalysisContent({
  analysis,
  onUnmount,
}: {
  analysis: Configuration;
  onUnmount: () => void;
}) {
  const { organisationId } = useTypedPath("organisationId");
  const [, setContent] = useRecoilState(
    organisationRightSideModal(organisationId),
  );
  const tempConfigNames = useRecoilValue(
    configurationTempName({ nodeId: organisationId }),
  );
  const configName = tempConfigNames[analysis.id] ?? analysis.name;
  const { update, saveName } = useOrgAnalysisCrud();

  return (
    <ContentColumn>
      <ContentHeaderRow>
        <ContentHeaderButtonsContainer>
          <OpenMoreInfo
            onClick={() => {
              setContent((cur) => {
                if (cur && cur.id === analysis.id) return undefined;
                return {
                  type: "resource",
                  id: analysis.id,
                };
              });
            }}
          />
        </ContentHeaderButtonsContainer>
      </ContentHeaderRow>
      <div
        style={{
          overflow: "hidden",
          flex: 1,
          position: "relative",
          width: "100%",
        }}
      >
        <AnalysisSettingCustomAdmin
          configuration={analysis}
          configurationName={configName ?? ""}
          onSave={update}
          onSaveName={saveName}
          onUnmount={onUnmount}
          isLoading={false}
          nodeId={organisationId}
          readOnly={false}
        />
      </div>
    </ContentColumn>
  );
}
