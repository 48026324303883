import { AtomEffect, Loadable, RecoilValue } from "recoil";
import { z } from "zod";

/**
 * Return a recoil effect that syncs the atom with local storage.
 *
 * If the value in local storage is not valid JSON, or fails to parse with the
 * given parser, it will be ignored.
 */
export function syncLocalStorage<T>(
  localStorageKey: string,
  parser: z.ZodType<T, any, any>,
): AtomEffect<T> {
  return (recoilArgs) => {
    if (typeof window === "undefined" || !("localStorage" in window)) return;

    const { setSelf, onSet, trigger } = recoilArgs;
    onSet((newValue, _oldValue, isReset) => {
      if (isReset) {
        localStorage.removeItem(localStorageKey);
        return;
      }
      localStorage.setItem(localStorageKey, JSON.stringify(newValue));
    });

    if (trigger === "get") {
      const str = localStorage.getItem(localStorageKey);
      if (str === null) return;

      let json = null;
      try {
        json = JSON.parse(str);
      } catch (e) {
        console.warn(
          `local storage value for key ${localStorageKey} is not valid JSON.`,
        );
        setSelf((defaultValue) => {
          return defaultValue;
        });
        return;
      }

      const parsed = parser.safeParse(json);
      if (parsed.success) {
        setSelf(parsed.data);
      } else {
        console.warn(
          `local storage value for key ${localStorageKey} failed to parse.`,
          json,
        );
      }
    }
  };
}

type Printer = typeof console.log;
export function printOnSet<T>(
  label: string,
  opt?: {
    extra?: any;
    print?: Printer;
  },
): AtomEffect<T> {
  return ({ onSet }) => {
    const print = opt?.print ?? console.log;
    onSet((newValue, oldValue, isReset) => {
      print(`Atom ${label} changed:`, {
        from: oldValue,
        to: newValue,
        reset: isReset,
        extra: opt?.extra,
      });
    });
  };
}

/**
 * Initialize the atom on first read with the supplied `async` function.
 *
 * # Bugs
 *
 * Effects will not be re-ran when an atom is reset, even though the third
 * parameter to the `onSet` function is `isReset`. This is a Recoil bug; see
 * [this bug report](https://github.com/facebookexperimental/Recoil/issues/2183).
 * This means that if you want to set an atom value to the result of a new
 * `fetch` call you have to do so manually.
 */
export const asyncInit =
  <T>(
    fn: ({
      getLoadable,
    }: {
      getLoadable: <S>(recoilValue: RecoilValue<S>) => Loadable<S>;
    }) => Promise<T>,
  ): AtomEffect<T> =>
  ({ trigger, getLoadable, node, onSet, setSelf }) => {
    // If we are initialized by being set we don't need to fetch.
    if (trigger === "set") return;
    // Check if we are not initialized yet.
    const firstInit = getLoadable(node).state === "loading";
    if (!firstInit) return;
    // Track whether the atom is set somewhere else while the promise is
    // resolving.  If this happens, ignore the promise value and keep the set
    // value.
    let wasSet = false;
    onSet(() => {
      wasSet = true;
    });
    // When the promise has resolved, set the atom.
    fn({ getLoadable }).then((value) => {
      if (!wasSet) setSelf(value);
    });
  };
