import styled from "styled-components";
import { StandardBox } from "../../styles/boxes/Boxes";
import { colors } from "../../styles/colors";

export const InputWrapper = styled(StandardBox)<{
  close?: boolean;
  maxHeight?: string;
}>`
  z-index: 0;
  background-color: ${colors.focusBackground};
  padding: 1.2rem;
  box-sizing: border-box;
  overflow: hidden;
  padding-bottom: 1rem;
  font-size: 1.2rem;
  width: ${(p) => (p.close ? "10rem" : "30rem")};
  max-height: ${(p) =>
    p.close ? "4.6rem" : p.maxHeight ? p.maxHeight : "85vh"};
  overflow: ${(p) => (p.close ? "hidden" : "scroll")};
  transition: all 0.3s ease-in-out;
`;

export const Grid2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
`;
