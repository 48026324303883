import { useTypedPath } from "state/pathParams";
import { useRefreshProjectTurbines } from "./useRefreshCustomTurbines";
import { useRecoilCallback } from "recoil";
import { DEFAULT_TURBINES, TurbineType } from "types/turbines";
import {
  createNewProjectTurbine,
  updateProjectTurbine,
  getAdvancedProjectTurbine,
  deleteProjectTurbine,
} from "services/turbineAPIService";
import { Mixpanel } from "mixpanel";
import { selectedMenuItemState } from "components/SettingsV2/Shared/state";
import { TURBINE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useTurbineSettings";
import {
  fetchTurbineTypeUsage,
  turbineTypeUsageAtomFamily,
} from "state/turbines";
import { initializeAndSet } from "components/Comments/hooks/useReplyReactionCrud";
import { currentVersionSelector } from "state/project";

export const useProjectTurbineCrud = () => {
  const { projectId } = useTypedPath("organisationId", "projectId");

  const refreshProjectTurbines = useRefreshProjectTurbines();
  const create = useRecoilCallback(
    ({ set }) =>
      async ({ turbine, name }: { turbine: TurbineType; name?: string }) => {
        const newTurbine = {
          ...turbine,
          name: name ? name : `${turbine.name} copy`,
        };
        return createNewProjectTurbine(projectId, newTurbine).then((res) => {
          if (res) {
            refreshProjectTurbines();
            set(
              selectedMenuItemState({ menuId: TURBINE_MENU_ID, projectId }),
              res.id,
            );
            Mixpanel.track("Created new turbine", {
              turbineId: turbine.id,
            });
          }
        });
      },
    [projectId, refreshProjectTurbines],
  );

  const update = useRecoilCallback(
    ({ set, snapshot }) =>
      async (turbine: TurbineType) => {
        if (!projectId) return;
        const usage = await fetchTurbineTypeUsage(projectId, turbine.id);
        initializeAndSet(
          snapshot,
          set,
          turbineTypeUsageAtomFamily({
            nodeId: projectId,
            turbineTypeId: turbine.id,
          }),
          usage,
        );

        if (
          usage.length === 0 ||
          window.confirm(
            `Turbine type is currently being used in ${usage.length} turbines, are you sure you want to update it?`,
          )
        ) {
          updateProjectTurbine(projectId, turbine).then(() => {
            refreshProjectTurbines();
            //set(toastMessagesAtom, (tm) => [
            //    ...tm, {
            //      text: "Saved",
            //      timeout: 3000,
            //      type: "success",
            //    },
            //  ])
          });
        }
      },
    [projectId, refreshProjectTurbines],
  );

  const duplicate = useRecoilCallback(
    ({ set, snapshot }) =>
      async (turbineId: string, nodeId: string) => {
        const version = await snapshot.getPromise(currentVersionSelector);
        const turbine =
          DEFAULT_TURBINES.find((turbine) => turbine.id === turbineId) ??
          (await getAdvancedProjectTurbine(nodeId, turbineId, version));
        if (!turbine) {
          return;
        }
        const newTurbine = {
          ...turbine,
          name: `${turbine.name} copy`,
        };
        const res = await createNewProjectTurbine(nodeId, newTurbine);
        if (res) {
          set(
            selectedMenuItemState({
              menuId: TURBINE_MENU_ID,
              projectId: nodeId,
            }),
            res.id,
          );
        }
        refreshProjectTurbines();
        Mixpanel.track("Duplicated turbine", {
          turbineId: turbine.id,
        });
      },
    [refreshProjectTurbines],
  );

  const onChangeName = useRecoilCallback(
    ({ snapshot }) =>
      async (turbineId: string, newName: string) => {
        if (!projectId) {
          return;
        }
        const version = await snapshot.getPromise(currentVersionSelector);

        const turbine = await getAdvancedProjectTurbine(
          projectId,
          turbineId,
          version,
        );
        Mixpanel.track("Change name of turbine", {
          turbineId: turbine.id,
        });

        if (!turbine) {
          return;
        }
        return create({
          turbine: {
            ...turbine,
            name: newName,
          },
        });
      },
    [projectId, create],
  );

  const remove = useRecoilCallback(
    ({ set, snapshot }) =>
      async (turbineId: string) => {
        if (!projectId) return projectId;

        const usage = await fetchTurbineTypeUsage(projectId, turbineId);
        initializeAndSet(
          snapshot,
          set,
          turbineTypeUsageAtomFamily({
            nodeId: projectId,
            turbineTypeId: turbineId,
          }),
          usage,
        );

        if (
          usage.length === 0 ||
          window.confirm(
            `Turbine type is currently being used in ${usage.length} turbines, are you sure you want to delete it?`,
          )
        ) {
          deleteProjectTurbine(projectId, turbineId).then(() => {
            refreshProjectTurbines();
            set(
              selectedMenuItemState({ menuId: TURBINE_MENU_ID, projectId }),
              undefined,
            );
          });
        }
      },
    [refreshProjectTurbines, projectId],
  );

  return { create, update, duplicate, onChangeName, remove };
};
