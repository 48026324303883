import {
  atomFamily,
  selectorFamily,
  useRecoilCallback,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import {
  GroupOrgResource,
  OrgResource,
  OrganisationResources,
} from "../../types";
import {
  addLibraryResourceManager,
  getGroupOrganisationResources,
  removeGroupLibraryResourceManager,
} from "../../service";
import { userOrganisationResourcesAtomFamily } from "./useUserOrganisationResourcesCrud";
import { scream } from "utils/sentry";
import { toastMessagesAtom } from "state/toast";
import {
  groupMembersState,
  organisationGroupsState,
} from "components/Organisation/Groups/state";
import {
  cableManagersSelector,
  foundationManagersSelector,
  turbineManagersSelector,
} from "components/Organisation/Library/state";
import { isGroupOrgResource } from "utils/predicates";
import { useTypedPath } from "state/pathParams";
import { initializeAndSet } from "components/Comments/hooks/useReplyReactionCrud";
import {
  loggedInUserSelector,
  orgAnalysisManageAccessSelector,
  orgCableManageAccessSelector,
  orgFinanicalManageAccessSelector,
  orgFoundationManageAccessSelector,
  orgTurbineManageAccessSelector,
} from "state/user";

export const groupOrgResourceManagersAtomFamily = atomFamily<
  OrgResource[],
  { organisationId: string; groupId: string }
>({
  key: "groupOrganisationResourcesAtomFamily",
  default: selectorFamily({
    key: "groupOrganisationResourcesAtomFamily.default",
    get:
      ({ organisationId, groupId }) =>
      async () =>
        await getGroupOrganisationResources(organisationId, groupId),
  }),
});

const useGroupOrganisationResourcesCrud = () => {
  const { organisationId } = useTypedPath("organisationId");
  const setToastMessages = useSetRecoilState(toastMessagesAtom);
  const groups = useRecoilValue(organisationGroupsState({ organisationId }));
  const currentUserId = useRecoilValue(loggedInUserSelector)?.user_id;

  const add = useRecoilCallback(
    ({ set, snapshot, refresh }) =>
      async (groupId: string, resourcesName: OrganisationResources) => {
        const group = groups.find((g) => g.id === groupId);
        if (!group) return;
        const prevState = await snapshot.getPromise(
          groupOrgResourceManagersAtomFamily({
            organisationId,
            groupId,
          }),
        );
        set(
          groupOrgResourceManagersAtomFamily({
            organisationId,
            groupId,
          }),
          (curr) =>
            curr.some(
              (g) =>
                isGroupOrgResource(g) &&
                g.resource_name === resourcesName &&
                g.group_id === groupId,
            )
              ? curr
              : [
                  ...curr,
                  {
                    group_id: groupId,
                    group_name: group.name,
                    resource_name: resourcesName,
                    type: "group",
                  } as GroupOrgResource,
                ],
        );
        try {
          const res = await addLibraryResourceManager(
            organisationId,
            groupId,
            resourcesName,
            "group",
          );
          refresh(turbineManagersSelector({ organisationId }));
          refresh(foundationManagersSelector({ organisationId }));
          refresh(cableManagersSelector({ organisationId }));
          const members = await snapshot.getPromise(
            groupMembersState({ organisationId, groupId }),
          );
          members.forEach((m) => {
            initializeAndSet(
              snapshot,
              set,
              userOrganisationResourcesAtomFamily({
                organisationId,
                userId: m.user_id,
              }),
              (curr) =>
                curr.some(
                  (g) =>
                    isGroupOrgResource(g) &&
                    g.resource_name === resourcesName &&
                    g.group_id === groupId,
                )
                  ? curr
                  : [
                      ...curr,
                      {
                        group_id: groupId,
                        group_name: group.name,
                        resource_name: resourcesName,
                        type: "group",
                      } as GroupOrgResource,
                    ],
            );
            currentUserId === m.user_id &&
              refresh(orgTurbineManageAccessSelector);
            currentUserId === m.user_id &&
              refresh(orgFoundationManageAccessSelector);
            currentUserId === m.user_id &&
              refresh(orgCableManageAccessSelector);
          });

          return res;
        } catch (e) {
          scream("Failed to set manager", { e });
          set(
            groupOrgResourceManagersAtomFamily({
              organisationId,
              groupId,
            }),
            prevState,
          );
          setToastMessages((tm) => [
            ...tm,
            {
              text: "Error while trying to update",
              timeout: 5000,
              type: "error",
            },
          ]);
        }
      },
    [currentUserId, groups, organisationId, setToastMessages],
  );
  const remove = useRecoilCallback(
    ({ set, snapshot, refresh }) =>
      async (groupId: string, resourcesName: OrganisationResources) => {
        const prevState = await snapshot.getPromise(
          groupOrgResourceManagersAtomFamily({
            organisationId,
            groupId,
          }),
        );
        set(
          groupOrgResourceManagersAtomFamily({
            organisationId,
            groupId,
          }),
          (curr) =>
            curr.filter(
              (g) =>
                isGroupOrgResource(g) &&
                (g.resource_name !== resourcesName || g.group_id !== groupId),
            ),
        );
        try {
          const res = await removeGroupLibraryResourceManager(
            organisationId,
            groupId,
            resourcesName,
          );
          refresh(turbineManagersSelector({ organisationId }));
          refresh(foundationManagersSelector({ organisationId }));
          refresh(cableManagersSelector({ organisationId }));
          const members = await snapshot.getPromise(
            groupMembersState({ organisationId, groupId }),
          );
          members.forEach((m) => {
            initializeAndSet(
              snapshot,
              set,
              userOrganisationResourcesAtomFamily({
                organisationId,
                userId: m.user_id,
              }),
              (curr) =>
                curr.filter((g) => {
                  if (g.type === "personal") return true;
                  return !(
                    isGroupOrgResource(g) &&
                    g.resource_name === resourcesName &&
                    g.group_id === groupId
                  );
                }),
            );
            if (currentUserId === m.user_id) {
              refresh(orgTurbineManageAccessSelector);
              refresh(orgFoundationManageAccessSelector);
              refresh(orgCableManageAccessSelector);
              refresh(orgFinanicalManageAccessSelector);
              refresh(orgAnalysisManageAccessSelector);
            }
          });

          return res;
        } catch (e) {
          scream("Failed to remove manager", { e });
          set(
            groupOrgResourceManagersAtomFamily({
              organisationId,
              groupId,
            }),
            prevState,
          );
          setToastMessages((tm) => [
            ...tm,
            {
              text: "Error while trying to update",
              timeout: 5000,
              type: "error",
            },
          ]);
        }
      },
    [currentUserId, organisationId, setToastMessages],
  );

  return { add, remove };
};

export default useGroupOrganisationResourcesCrud;
