import FullScreenModal from "components/FullScreenModal/FullScreenModal";
import Button from "components/General/Button";
import { Label } from "components/General/Form";
import { Input } from "components/General/Input";
import { ModalFrame, Column, Row } from "components/General/Layout";
import { useState, useCallback, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalTypeOpenAtom } from "state/modal";
import { useCreateArchivedVersions } from "../useArchivedVersions";
import { useToast } from "hooks/useToast";
import { getParkFeaturesInBranchSelector } from "state/park";
import Dropdown from "components/Dropdown/Dropdown";
import Tooltip from "components/General/Tooltip";

export const NewArchivedVersionModalTypeName = "NewArchivedVersionModal";
export type NewArchivedVersionModalType = {
  modalType: typeof NewArchivedVersionModalTypeName;
  metadata: {
    organisationId: string;
    projectId: string;
    branchId: string;
    parkId: string;
  };
};

const NewArchivedProjectVersionModal = () => {
  const modalTypeOpen = useRecoilValue(modalTypeOpenAtom);
  if (modalTypeOpen?.modalType !== NewArchivedVersionModalTypeName) return null;

  return <NewArchivedProjectVersionModalInner {...modalTypeOpen.metadata} />;
};

const NewArchivedProjectVersionModalInner = ({
  organisationId,
  projectId,
  branchId,
  parkId,
}: {
  organisationId: string;
  projectId: string;
  branchId: string;
  parkId: string;
}) => {
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const parks = useRecoilValue(getParkFeaturesInBranchSelector({ branchId }));

  const [_parkId, setParkId] = useState<string>("undefined");
  useEffect(() => {
    if (parkId) setParkId(parkId);
  }, [parkId]);

  const { create } = useCreateArchivedVersions();
  const { info } = useToast();

  const onSave = useCallback(async () => {
    create(organisationId, projectId, branchId, _parkId, name, description);
    info("Creating new project data save...");
    setModalTypeOpen(undefined);
  }, [
    branchId,
    create,
    description,
    info,
    name,
    organisationId,
    _parkId,
    projectId,
    setModalTypeOpen,
  ]);

  const disableExport = _parkId === "undefined" || name === "";

  return (
    <FullScreenModal>
      <ModalFrame title="New project data export">
        <Column style={{ gap: "2.4rem", alignItems: "center" }}>
          <Label style={{ width: "100%" }}>
            <p>Park:</p>
            <Dropdown onChange={(event) => setParkId(event.target.value)}>
              <option value={"undefined"} selected={_parkId === "undefined"}>
                ---------
              </option>
              {parks.map((p) => (
                <option key={p.id} value={p.id} selected={p.id === _parkId}>
                  {p.properties.name}
                </option>
              ))}
            </Dropdown>
          </Label>
          <Label style={{ width: "100%" }}>
            <p>Name:</p>
            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              style={{ width: "100%" }}
            />
          </Label>
          <Label style={{ width: "100%" }}>
            <p>Description:</p>
            <Input
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              style={{ width: "100%" }}
            />
          </Label>

          <Row style={{ marginLeft: "auto" }}>
            <Button
              buttonType="secondary"
              text="Cancel"
              onClick={() => setModalTypeOpen(undefined)}
            />
            <Tooltip
              text={disableExport ? "Name and park is required" : ""}
              disabled={!disableExport}
            >
              <Button text="Save" onClick={onSave} disabled={disableExport} />
            </Tooltip>
          </Row>
        </Column>
      </ModalFrame>
    </FullScreenModal>
  );
};

export default NewArchivedProjectVersionModal;
