import { useCallback } from "react";
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import { savingConfigurationInProgressAtom } from "../state/configuration";
import { initializeAndSet } from "../components/Comments/hooks/useReplyReactionCrud";
import { mooringLineTypeAtomFamily } from "../state/mooringLineType";
import {
  MooringLineType,
  createMooringLineType,
  updateMooringLineType,
  deleteMooringLineType,
} from "../services/mooringLineTypeService";
import { projectIdSelector } from "../state/pathParams";

const useMooringLineTypeCrud = () => {
  const projectId = useRecoilValue(projectIdSelector);
  const setIsAutoSaving = useSetRecoilState(savingConfigurationInProgressAtom);
  const setCurrentMooringLineTypes = useSetRecoilState(
    mooringLineTypeAtomFamily({ projectId }),
  );

  const create = useCallback(
    async (mooringLineType: Partial<MooringLineType>) => {
      if (!projectId) return;

      setIsAutoSaving(true);

      return createMooringLineType(projectId, mooringLineType)
        .then((ml) => {
          setCurrentMooringLineTypes((cur) => [...cur, ml]);
          return ml;
        })
        .finally(() => {
          setIsAutoSaving(false);
        });
    },
    [projectId, setCurrentMooringLineTypes, setIsAutoSaving],
  );

  const update = useCallback(
    async (mooringLineType: MooringLineType) => {
      if (!projectId) return;

      setIsAutoSaving(true);
      setCurrentMooringLineTypes((cur) =>
        [...cur].map((l) =>
          l.id === mooringLineType.id ? { ...l, ...mooringLineType } : l,
        ),
      );
      return updateMooringLineType(projectId, mooringLineType).finally(() => {
        setIsAutoSaving(false);
      });
    },
    [projectId, setCurrentMooringLineTypes, setIsAutoSaving],
  );
  const deleteMooringLine = useCallback(
    async (mooringLineId: string) => {
      if (!projectId) return;

      setIsAutoSaving(true);
      setCurrentMooringLineTypes((cur) =>
        [...cur].filter((l) => l.id !== mooringLineId),
      );
      return deleteMooringLineType(projectId, mooringLineId).finally(() => {
        setIsAutoSaving(false);
      });
    },
    [projectId, setCurrentMooringLineTypes, setIsAutoSaving],
  );

  const updateLocal = useRecoilCallback(
    ({ set, snapshot }) =>
      async (projectId: string, mooringLineType: MooringLineType) => {
        initializeAndSet(
          snapshot,
          set,
          mooringLineTypeAtomFamily({ projectId }),
          (cur) => {
            const isUpdate = cur.some((l) => l.id === mooringLineType.id);
            return isUpdate
              ? [...cur].map((l) =>
                  l.id === mooringLineType.id
                    ? { ...l, ...mooringLineType }
                    : l,
                )
              : [...cur, mooringLineType];
          },
        );
      },
    [],
  );

  return { create, update, deleteMooringLine, updateLocal };
};

export default useMooringLineTypeCrud;
