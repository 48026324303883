import React, { useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { resetListIfNotAlreadyEmpty } from "../../../../utils/resetList";
import { selectedUploadFileTypeAtom } from "../../state";
import AddDataLayerSourceFromUrl from "./AddDataLayerSourceFromUrl/AddDataLayerSourceFromUrl";
import DropFilesMessage from "./DropFilesMessage";
import DroppedFilesMapper from "./DroppedFilesMapper";
import ErrorMessage from "./ErrorMessage";
import FileTypeSelectionStep from "./FileTypeSelectionStep";
import SelectUploadTypeForDroppedFiles from "./SelectUploadTypeForDroppedFiles";
import { UploadFileType } from "./types";
import UploadDataLayer from "./UploadDataLayer/UploadDataLayer";
import UploadFeaturesFromCoordinates from "./UploadFeaturesFromCoordinates/UploadFeaturesFromCoordinates";
import UploadProjectFeatures from "./UploadProjectFeatures/UploadProjectFeatures";
import UploadWindData from "./UploadWindData/UploadWindData";

const UploadFileDropHandler = ({
  children,
  droppedFiles,
  setDroppedFiles,
  selectedUploadFileType,
  setSelectedUploadFileType,
}: {
  droppedFiles: File[];
  setDroppedFiles: React.Dispatch<React.SetStateAction<File[]>>;
  selectedUploadFileType?: UploadFileType;
  setSelectedUploadFileType(type: UploadFileType | undefined): void;
} & React.PropsWithChildren) => {
  const [showDropFilesMessage, setShowDropFilesMessage] = useState(false);
  const [
    showSelectUploadFileTypeForDroppedFiles,
    setShowSelectUploadFileTypeForDroppedFiles,
  ] = useState(false);
  const [errorText, setErrorText] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (selectedUploadFileType) {
      return;
    }

    const onDragOver = (e: DragEvent) => {
      if (e.dataTransfer?.types.includes("Files")) {
        e.preventDefault();
        setShowDropFilesMessage(true);
      }
    };

    const onMouseLeave = () => {
      // Set state after a short delay to avoid dropping files on component that doesnt capture the event
      setTimeout(() => {
        setShowDropFilesMessage((curr) => (curr ? false : curr));
      }, 100);
    };

    const onDrop = (e: DragEvent) => {
      if (e.dataTransfer?.types.includes("Files")) {
        e.preventDefault();
        setShowDropFilesMessage(false);
        setDroppedFiles(Array.from(e.dataTransfer.files));
      }
    };

    document.addEventListener("dragover", onDragOver);
    document.addEventListener("mouseleave", onMouseLeave);
    document.addEventListener("drop", onDrop);
    return () => {
      document.removeEventListener("dragover", onDragOver);
      document.removeEventListener("mouseleave", onMouseLeave);
      document.removeEventListener("drop", onDrop);
    };
  }, [selectedUploadFileType, setDroppedFiles]);

  const resetDroppedFiles = useCallback(() => {
    setDroppedFiles(resetListIfNotAlreadyEmpty);
  }, [setDroppedFiles]);

  const resetErrorText = useCallback(() => {
    setErrorText(undefined);
  }, []);

  if (showDropFilesMessage) {
    return <DropFilesMessage />;
  }

  if (errorText) {
    return (
      <ErrorMessage errorText={errorText} resetErrorMessage={resetErrorText} />
    );
  }

  if (showSelectUploadFileTypeForDroppedFiles) {
    return (
      <SelectUploadTypeForDroppedFiles
        droppedFiles={droppedFiles}
        setSelectedUploadFileType={(fileType) => {
          setSelectedUploadFileType(fileType);
          setShowSelectUploadFileTypeForDroppedFiles(false);
        }}
        onBackClick={() => {
          resetDroppedFiles();
          setSelectedUploadFileType(undefined);
          setShowSelectUploadFileTypeForDroppedFiles(false);
        }}
      />
    );
  }

  if (droppedFiles.length > 0 && !selectedUploadFileType && !errorText) {
    return (
      <DroppedFilesMapper
        droppedFiles={droppedFiles}
        resetDroppedFiles={resetDroppedFiles}
        setShowSelectUploadFileTypeForDroppedFiles={
          setShowSelectUploadFileTypeForDroppedFiles
        }
        setSelectedUploadFileType={setSelectedUploadFileType}
        setErrorText={setErrorText}
      />
    );
  }

  return <>{children}</>;
};

const NewUploadTab = ({ onClose }: { onClose(): void }) => {
  const [selectedUploadFileType, setSelectedUploadFileType] = useRecoilState(
    selectedUploadFileTypeAtom,
  );
  const [droppedFiles, setDroppedFiles] = useState<File[]>([]);
  const [defaultHighlightedFileType, setDefaultHighlightedFileType] = useState<
    UploadFileType | undefined
  >(undefined);

  const resetDroppedFiles = useCallback(() => {
    setDroppedFiles(resetListIfNotAlreadyEmpty);
  }, []);

  const renderUploadComponent = () => {
    switch (selectedUploadFileType) {
      case undefined:
        return (
          <FileTypeSelectionStep
            onClose={onClose}
            onNextClick={setSelectedUploadFileType}
            defaultSelected={defaultHighlightedFileType}
          />
        );

      case UploadFileType.PROJECT_FEATURE:
        return (
          <UploadProjectFeatures
            initialFiles={droppedFiles}
            resetInitialFiles={resetDroppedFiles}
            onDoneClick={onClose}
            onBackClick={() => {
              setDefaultHighlightedFileType(selectedUploadFileType);
              setSelectedUploadFileType(undefined);
            }}
            onUploadFromCoordinatesClick={() => {
              setSelectedUploadFileType(
                UploadFileType.FEATURE_FROM_COORDINATES,
              );
            }}
          />
        );

      case UploadFileType.FEATURE_FROM_COORDINATES:
        return (
          <UploadFeaturesFromCoordinates
            onDoneClick={onClose}
            onBackClick={() => {
              setSelectedUploadFileType(UploadFileType.PROJECT_FEATURE);
            }}
          />
        );

      case UploadFileType.DATA_LAYER:
        return (
          <UploadDataLayer
            initialFiles={droppedFiles}
            resetInitialFiles={resetDroppedFiles}
            onDoneClick={onClose}
            onBackClick={() => {
              setDefaultHighlightedFileType(selectedUploadFileType);
              setSelectedUploadFileType(undefined);
            }}
            onAddSourceFromUrlClick={() => {
              setSelectedUploadFileType(UploadFileType.ADD_DATA_LAYER_SOURCE);
            }}
          />
        );

      case UploadFileType.ADD_DATA_LAYER_SOURCE:
        return (
          <AddDataLayerSourceFromUrl
            onDoneClick={onClose}
            onBackClick={() => {
              setSelectedUploadFileType(UploadFileType.DATA_LAYER);
            }}
          />
        );

      case UploadFileType.WIND_DATA:
        return (
          <UploadWindData
            initialFiles={droppedFiles}
            resetInitialFiles={resetDroppedFiles}
            onDoneClick={onClose}
            onBackClick={() => {
              setDefaultHighlightedFileType(selectedUploadFileType);
              setSelectedUploadFileType(undefined);
            }}
          />
        );

      default:
        return null;
    }
  };

  return (
    <UploadFileDropHandler
      droppedFiles={droppedFiles}
      setDroppedFiles={setDroppedFiles}
      selectedUploadFileType={selectedUploadFileType}
      setSelectedUploadFileType={setSelectedUploadFileType}
    >
      {renderUploadComponent()}
    </UploadFileDropHandler>
  );
};

export default NewUploadTab;
