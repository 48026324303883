import { InputDimensioned, Input, TextArea } from "../General/Input";

import { useEffect, useMemo, useState, useCallback } from "react";
import Button from "../General/Button";
import { isNumber } from "../../utils/predicates";
import { Column, Row } from "../General/Layout";
import { ColumnContent } from "./TurbineSettings";
import { ContentWrapper, SettingsHeader } from "../SettingsV2/Shared/styles";
import { Label } from "../General/Form";
import { useSetRecoilState } from "recoil";
import { unsavedSettingsState } from "../SettingsV2/Shared/state";
import { toastMessagesAtom } from "../../state/toast";
import useEnterToSave from "./useEnterToSave";
import { SubstationType } from "services/substationService";
import Radio, { RadioGroup } from "components/General/Radio";
import { spaceLarge, spaceTiny } from "styles/space";
import Dropdown from "components/Dropdown/Dropdown";
import styled from "styled-components";
import { z } from "zod";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import { undefMap } from "utils/utils";
import { CostUnit } from "types/financial";

export const SettingsColumn = styled(Column)`
  ${Row} {
    & > label {
      flex: 1;
    }
  }
  gap: ${spaceLarge};
`;

export const TYPE_DEFAULT = "offshore";
export const IMPEDANCE_DEFAULT = 0.13;
export const RESISTANCE_DEFAULT = 0.003;
export const IRON_LOSSES_DEFAULT = 0.00025;
export const NO_LOAD_CURRENT_DEFAULT = 0.0015;
export const REACTOR_DEFAULT = true;
export const COMPENSATION_SHARE_DEFAULT = 0.5;

export function SingleSubstationType({
  substation,
  onSave,
  isLoading,
  disabled,
}: {
  substation: SubstationType;
  onSave: (substation: SubstationType) => void;
  isLoading: boolean;
  disabled: boolean;
}) {
  const [_substation, setSubstation] = useState(substation);
  const setUnsavedSettings = useSetRecoilState(unsavedSettingsState);
  const setToastMessages = useSetRecoilState(toastMessagesAtom);

  const fractionToPercentage = (fraction: number | undefined) =>
    undefMap(fraction, (f) => f * 100);
  const percentageToFraction = (percentage: number) => percentage / 100;

  useEffect(() => {
    setSubstation(substation);
  }, [substation]);

  const hasChanged = useMemo(() => {
    return substation !== _substation;
  }, [_substation, substation]);

  const onEnterSaveCallback = useCallback(
    () => onSave(_substation),
    [onSave, _substation],
  );
  useEnterToSave(onEnterSaveCallback, hasChanged && !isLoading);

  useEffect(() => {
    setUnsavedSettings(hasChanged);
  }, [hasChanged, setUnsavedSettings]);

  return (
    <>
      {hasChanged && (
        <SettingsHeader>
          <Button
            disabled={isLoading || !hasChanged}
            text="Cancel"
            buttonType="secondary"
            onClick={() => {
              setSubstation(_substation);
            }}
            style={{ marginLeft: "auto" }}
          />
          <Button
            disabled={isLoading || !hasChanged}
            text="Save changes"
            onClick={() => {
              setToastMessages((tm) => [
                ...tm,
                { text: "Saving...", timeout: 1000 },
              ]);
              onSave(_substation);
            }}
          />
        </SettingsHeader>
      )}
      <ContentWrapper
        style={{
          maxHeight: "calc(100% - 7.3rem)",
          overflowY: "auto",
          boxSizing: "border-box",
        }}
      >
        <ColumnContent>
          <h4 style={{ margin: 0 }}>Substation settings</h4>
          <SettingsColumn>
            <Row>
              <Label>
                <p>Name</p>
                <Input
                  disabled={disabled}
                  width={16}
                  value={_substation.name}
                  onChange={(e) => {
                    setSubstation({
                      ..._substation,
                      name: e.target.value,
                    });
                  }}
                />
              </Label>

              <Label>
                <p>Type</p>
                <Dropdown
                  value={_substation.type ?? "offshore"}
                  onChange={(e) => {
                    const type = z
                      .enum(["offshore", "onshore"])
                      .parse(e.target.value);
                    setSubstation({
                      ..._substation,
                      type,
                    });
                  }}
                >
                  <option value="offshore">Offshore</option>
                  <option value="onshore">Onshore</option>
                </Dropdown>
              </Label>

              <Label>
                <Row>
                  <p>Cost</p>
                  <HelpTooltip
                    style={{ display: "inline-flex" }}
                    text="On component level, price per MW is referring to the MW of the substation"
                    size={10}
                  />
                </Row>
                <InputDimensioned
                  disabled={disabled}
                  value={_substation.cost}
                  unit={_substation.costUnit}
                  units={[
                    CostUnit.millionEuroPerUnit,
                    CostUnit.thousandEuroPerMW,
                  ]}
                  step={0.1}
                  validate={(e) => e >= 0.0}
                  validationMessage={`Must be greater than 0`}
                  onChange={(e) => {
                    if (!isNumber(e)) return;
                    setSubstation({
                      ..._substation,
                      cost: e,
                    });
                  }}
                  onUnitChange={(costUnit) => {
                    setSubstation({
                      ..._substation,
                      costUnit,
                    });
                  }}
                />
              </Label>
            </Row>
            <h5 style={{ margin: 0 }}>Transformer settings</h5>
            <Row>
              <Label>
                <p>Short-circuit impedance</p>
                <InputDimensioned
                  disabled={disabled}
                  value={fractionToPercentage(_substation.impedance)}
                  unit={"%"}
                  step={0.01}
                  validate={(e) => e >= 0.0}
                  validationMessage={`Must be greater than 0`}
                  onChange={(e) => {
                    setSubstation({
                      ..._substation,
                      impedance: percentageToFraction(e),
                    });
                  }}
                  placeholder="0.14"
                />
              </Label>

              <Label>
                <p>Resistance</p>
                <InputDimensioned
                  disabled={disabled}
                  value={fractionToPercentage(_substation.resistance)}
                  unit={"%"}
                  step={0.01}
                  validate={(e) => e >= 0.0}
                  validationMessage={`Must be greater than 0`}
                  onChange={(e) => {
                    setSubstation({
                      ..._substation,
                      resistance: percentageToFraction(e) as any,
                    });
                  }}
                  placeholder="0.14"
                />
              </Label>

              <Label>
                <p>Iron losses</p>
                <InputDimensioned
                  disabled={disabled}
                  value={fractionToPercentage(_substation.ironLosses)}
                  unit={"%"}
                  step={0.1}
                  validate={(e) => e >= 0.0}
                  validationMessage={`Must be greater than 0`}
                  onChange={(e) => {
                    setSubstation({
                      ..._substation,
                      ironLosses: percentageToFraction(e) as any,
                    });
                  }}
                  placeholder="0.14"
                />
              </Label>

              <Label>
                <p>No load current</p>
                <InputDimensioned
                  disabled={disabled}
                  value={fractionToPercentage(_substation.noLoadCurrent)}
                  unit={"%"}
                  step={0.01}
                  validate={(e) => e >= 0.0}
                  validationMessage={`Must be greater than 0`}
                  onChange={(e) => {
                    setSubstation({
                      ..._substation,
                      noLoadCurrent: percentageToFraction(e) as any,
                    });
                  }}
                  placeholder="0.14"
                />
              </Label>
            </Row>

            {_substation.type === "offshore" && (
              <Label>
                <Row>
                  <p>Reactor</p>
                  <HelpTooltip text="Whether reactive power compensation should be performed at the substation." />
                </Row>
                <RadioGroup
                  style={{ padding: `${spaceTiny} 0`, gap: spaceLarge }}
                >
                  <Radio
                    label="Yes"
                    checked={_substation.reactor}
                    onChange={() =>
                      setSubstation((c) => ({ ...c, reactor: true }))
                    }
                  />
                  <Radio
                    label="No"
                    checked={!_substation.reactor}
                    onChange={() =>
                      setSubstation((c) => ({ ...c, reactor: false }))
                    }
                  />
                </RadioGroup>
              </Label>
            )}
            {_substation.type === "offshore" && _substation.reactor && (
              <Label>
                <Row>
                  <p>Total compensation share</p>
                  <HelpTooltip text="Share of total compensation that is performed at the offshore substation." />
                </Row>
                <InputDimensioned
                  disabled={disabled}
                  value={undefMap(_substation.compensationShare, (cs) =>
                    fractionToPercentage(cs),
                  )}
                  style={{
                    maxWidth: "21.5rem",
                  }}
                  unit={"%"}
                  step={0.01}
                  validate={(e) => e >= 0.0 && e <= 100}
                  validationMessage={`Must be between 0 and 100`}
                  onChange={(e) => {
                    setSubstation({
                      ..._substation,
                      compensationShare: percentageToFraction(e),
                    });
                  }}
                  placeholder="50"
                />
              </Label>
            )}
            <Label>
              <p>Description</p>
              <TextArea
                rows={4}
                style={{ resize: "vertical" }}
                disabled={disabled}
                placeholder={"Add a description"}
                value={_substation.note ?? ""}
                onChange={(e) => {
                  setSubstation({ ..._substation, note: e.target.value });
                }}
              />
            </Label>
          </SettingsColumn>
        </ColumnContent>
      </ContentWrapper>
    </>
  );
}
