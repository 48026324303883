import { z } from "zod";

export const _PileData = z.object({
  pileDiameter: z.number(),
  avgPileThickness: z.number(),
  embedLength: z.number(),
  totalPileLength: z.number(),
});
export type PileData = z.infer<typeof _PileData>;

export type RotorTowerData = {
  maxRotorSpeed: number;
  towerBaseDiameter: number;
  towerTopDiameter: number;
  towerBaseThickness: number;
  towerTopThickness: number;
  rnaMass: number;
  towerMass: number;
};
