/// <reference types="vite-plugin-svgr/client" />
import {
  CostConfiguration,
  CostType,
  isFeatureCost,
  isOverrideCost,
} from "../../../../services/costService";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  localCostConfigurationAtom,
  useLocalCostConfigurationCrud,
} from "../state";
import Substation from "@icons/24/Substation.svg?react";
import { GeneralCost } from "./General/GeneralCost";
import { ConfidenceLevel, CostUnit } from "../../../../types/financial";
import { DefaultCosts } from "../constants";
import { FirstRowItem, FirstRowWrapper } from "../Capex";
import { Input, InputDimensioned } from "components/General/Input";
import { SUBSTATION_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useSubstationSettings";
import { isDefined } from "utils/predicates";
import { projectIdSelector } from "state/pathParams";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import Button from "components/General/Button";
import PencilIcon from "@icons/24/Pencil.svg";
import ComponentsIcon from "@icons/24/ComponentsIcon.svg";
import {
  ConfidenceLevelDot,
  CostDivider,
  DividerWrapper,
  GridWithFourColumns,
  Tag,
} from "../styles";
import { Row } from "components/General/Layout";
import Tooltip from "components/General/Tooltip";
import LogoIcon from "@icons/24/VindLogo.svg?react";
import { IconREMSize } from "styles/typography";
import { colors } from "styles/colors";
import { IconBtn } from "components/General/Icons";
import PreviewIcon from "@icons/24/PreviewIcon.svg?react";
import { showComponentsPreviewAtom } from "components/ComponentsPreview/state";
import UndoIcon from "@icons/24/Undo.svg";
import { ConfidenceLevelSelector } from "../ConfidenceLevelSelector";
import { ButtonTextWrapper } from "components/General/Dropdown/DropdownButton";

const SubstationSpecificCosts = ({ isReadOnly }: { isReadOnly?: boolean }) => {
  const localConfig: CostConfiguration = useRecoilValue(
    localCostConfigurationAtom,
  );
  const [, setShowComponentsPreview] = useRecoilState(
    showComponentsPreviewAtom,
  );
  const useConfidenceLevel = localConfig.useConfidenceLevel;
  const projectId = useRecoilValue(projectIdSelector)!;
  const inLibraryContext = !projectId;

  const { updateFixedCapex } = useLocalCostConfigurationCrud();

  const handleSubstationCostTypeChange =
    (type: "feature_cost" | "override") => () => {
      if (type === "feature_cost") {
        updateFixedCapex({ substation: type });
      } else {
        updateFixedCapex({
          substation: {
            cost: DefaultCosts[CostType.Substation].substation[
              CostUnit.millionEuroPerMW
            ],
            unit: CostUnit.millionEuroPerMW,
            confidenceLevel: ConfidenceLevel.notSpecified,
          },
        });
      }
    };

  const isUsingDefaultSubstationCost =
    isOverrideCost(localConfig?.capex.fixed.substation) &&
    localConfig.capex.fixed.substation.cost ===
      DefaultCosts[CostType.Substation].substation[CostUnit.millionEuroPerMW] &&
    localConfig.capex.fixed.substation.unit === CostUnit.millionEuroPerMW;

  return (
    <FirstRowWrapper>
      <GridWithFourColumns>
        <FirstRowItem>
          <Input
            value={"Substations"}
            disabled={true}
            locked={true}
            style={{ width: "20rem" }}
          />
        </FirstRowItem>
        {isFeatureCost(localConfig?.capex.fixed.substation) ? (
          <Row style={{ width: "20rem" }}>
            <Tag>
              <ComponentsIcon />
              From components
            </Tag>
            {inLibraryContext ? (
              <HelpTooltip text="Using the cost specified on the substation type" />
            ) : (
              <IconBtn
                size={"1.5rem"}
                onClick={() => {
                  setShowComponentsPreview({
                    show: true,
                    componentType: SUBSTATION_MENU_ID,
                  });
                }}
              >
                <PreviewIcon />
              </IconBtn>
            )}
          </Row>
        ) : (
          <Row
            style={{
              width: "20rem",
            }}
          >
            <InputDimensioned
              step="0.1"
              unit={
                (isOverrideCost(localConfig?.capex.fixed.substation) &&
                  localConfig?.capex.fixed.substation.unit) ||
                CostUnit.millionEuroPerMW
              }
              units={[CostUnit.millionEuroPerMW, CostUnit.millionEuroPerUnit]}
              type="number"
              value={
                isOverrideCost(localConfig?.capex.fixed.substation)
                  ? localConfig.capex.fixed.substation.cost ??
                    DefaultCosts[CostType.Substation].substation[
                      CostUnit.millionEuroPerMW
                    ]
                  : undefined
              }
              onUnitChange={(unit: CostUnit) =>
                updateFixedCapex({
                  substation: {
                    cost: (DefaultCosts[CostType.Substation].substation as any)[
                      unit
                    ],
                    unit,
                  },
                })
              }
              validate={(value) => isDefined(value) && value >= 0}
              validationMessage={"Must be greater than or equal to 0"}
              disabled={
                isReadOnly ||
                !(
                  localConfig?.capex.fixed.substation &&
                  isOverrideCost(localConfig?.capex.fixed.substation)
                )
              }
              onChange={(cost) => updateFixedCapex({ cost }, "substation")}
              style={{ width: "100%" }}
            />
            {isUsingDefaultSubstationCost ? (
              <Tooltip text={"Vind default value"}>
                <IconREMSize height={2.6} width={2.6} fill={colors.blue700}>
                  <LogoIcon />
                </IconREMSize>
              </Tooltip>
            ) : (
              <Tooltip text="Reset to default">
                <IconBtn
                  size={"1.6rem"}
                  onClick={handleSubstationCostTypeChange("override")}
                >
                  <UndoIcon />
                </IconBtn>
              </Tooltip>
            )}
          </Row>
        )}

        {useConfidenceLevel ? (
          !(localConfig?.capex.fixed.substation === "feature_cost") ? (
            <ConfidenceLevelSelector
              disabled={isReadOnly}
              onConfidenceLevelChange={(confidenceLevel) => {
                if (localConfig?.capex.fixed.substation === "feature_cost")
                  return;
                updateFixedCapex({
                  substation: {
                    ...localConfig.capex.fixed.substation,
                    confidenceLevel,
                  },
                });
              }}
              confidenceLevel={
                localConfig.capex.fixed.substation.confidenceLevel
              }
            />
          ) : (
            <Tag>
              <Tooltip
                text={
                  "Not able to specify confidence on configuration level when the cost is from components"
                }
              >
                <ButtonTextWrapper>
                  <ConfidenceLevelDot confidenceLevel={undefined} />
                  {ConfidenceLevel.notSpecified}
                </ButtonTextWrapper>
              </Tooltip>
            </Tag>
          )
        ) : (
          <div></div>
        )}

        <Button
          size="small"
          icon={
            isFeatureCost(localConfig?.capex.fixed.substation) ? (
              <PencilIcon />
            ) : (
              <ComponentsIcon />
            )
          }
          buttonType="secondary"
          text={
            isFeatureCost(localConfig?.capex.fixed.substation)
              ? "Override"
              : "Use components"
          }
          onClick={
            isFeatureCost(localConfig?.capex.fixed.substation)
              ? handleSubstationCostTypeChange("override")
              : handleSubstationCostTypeChange("feature_cost")
          }
          disabled={isReadOnly}
        />
      </GridWithFourColumns>
    </FirstRowWrapper>
  );
};

export const SubstationCosts = ({ isReadOnly }: { isReadOnly?: boolean }) => {
  return (
    <GeneralCost
      title="Substations"
      category={CostType.Substation}
      isReadOnly={isReadOnly}
      units={[
        CostUnit.thousandEuroPerMW,
        CostUnit.millionEuroPerMW,
        CostUnit.millionEuroPerUnit,
        CostUnit.millionEuro,
        CostUnit.millionEuroPerONS,
        CostUnit.millionEuroPerOSS,
      ]}
      icon={<Substation />}
    >
      <SubstationSpecificCosts isReadOnly={isReadOnly} />
      <DividerWrapper>
        <CostDivider />
      </DividerWrapper>
    </GeneralCost>
  );
};
