import { LayerSettings } from "./types";
import stc from "string-to-color";

export const defaultLayerSettings = (source: string): LayerSettings => ({
  id: source,
  standard: false,
  visible: undefined,
  layerStyle: {
    opacity: 0.6,
    color: stc(source),
  },
});
