import {
  DEFAULT_IN_FOCUS_OPACITY,
  DEFAULT_NOT_IN_FOCUS_OPACITY,
  DEFAULT_SELECTED_OPACITY,
  lockedPropertyName,
} from "@constants/canvas";
import { useEffect } from "react";
import { colors } from "styles/colors";
import { CableCorridorFeature } from "types/feature";
import { safeRemoveLayer } from "utils/map";
import { addLayer } from "./utils";
import {
  cableCorridorSourceId,
  cableCorridorOutlineLayerId,
  cableCorridorLayerId,
  MIN_DIVISION_VISIBLE_ZOOM,
} from "./constants";

const cableCorridorFillPaint: mapboxgl.FillPaint = {
  "fill-color": colors.cableCorridor,
  "fill-opacity": [
    "case",
    [
      "all",
      ["boolean", ["feature-state", "selected"], false],
      ["!=", ["get", lockedPropertyName], true],
    ],
    0.8,
    ["boolean", ["feature-state", "inFocus"], false],
    0.6,
    DEFAULT_NOT_IN_FOCUS_OPACITY,
  ],
};

const cableCorridorOutlinePaint: mapboxgl.LinePaint = {
  "line-color": [
    "case",
    ["==", ["get", lockedPropertyName], true],
    colors.lockedFeatureOutline,
    ["boolean", ["feature-state", "selected"], false],
    "#ffffff",
    colors.cableCorridor,
  ],
  "line-opacity": [
    "case",
    [
      "all",
      [
        "boolean",
        ["feature-state", "hover"],
        ["feature-state", "selected"],
        false,
      ],
      ["!=", ["get", lockedPropertyName], true],
    ],
    DEFAULT_SELECTED_OPACITY,
    ["boolean", ["feature-state", "inFocus"], false],
    DEFAULT_IN_FOCUS_OPACITY,
    DEFAULT_NOT_IN_FOCUS_OPACITY,
  ],
  "line-width": [
    "case",
    ["==", ["get", lockedPropertyName], true],
    4,
    [
      "boolean",
      ["feature-state", "hover"],
      ["feature-state", "selected"],
      false,
    ],
    4.0,
    1.0,
  ],
};

export const RenderCableCorridor = ({
  cableCorridors: features,
  map,
}: {
  cableCorridors: CableCorridorFeature[];
  map: mapboxgl.Map;
}) => {
  useEffect(() => {
    map.addSource(cableCorridorSourceId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    return () => {
      safeRemoveLayer(map, cableCorridorOutlineLayerId);
      safeRemoveLayer(map, cableCorridorLayerId);
      map.removeSource(cableCorridorSourceId);
    };
  }, [map]);

  useEffect(() => {
    addLayer(map, {
      id: cableCorridorLayerId,
      type: "fill",
      source: cableCorridorSourceId,
      paint: cableCorridorFillPaint,
      minzoom: MIN_DIVISION_VISIBLE_ZOOM,
    });
    addLayer(map, {
      id: cableCorridorOutlineLayerId,
      type: "line",
      source: cableCorridorSourceId,
      paint: cableCorridorOutlinePaint,
    });
  }, [map]);

  useEffect(() => {
    const source = map.getSource(cableCorridorSourceId);
    if (source?.type !== "geojson") return;
    source.setData({
      type: "FeatureCollection",
      features,
    });
  }, [map, features]);

  return null;
};
