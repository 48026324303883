import { useCallback, useEffect } from "react";
import { Group, MathUtils } from "three";
import { ThreeCore } from "./useCreateThreeCore";
import { useRecoilValue } from "recoil";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { viewFromShoreFreeCameraAtom } from "state/viewToPark";

const FPS = 60;

export default function useAnimation(
  threeCore: ThreeCore | undefined,
  windTurbineLightsGlobal: Group | undefined,
  turbineRotorsGlobal: Group | undefined,
) {
  const freeCamera = useRecoilValue(viewFromShoreFreeCameraAtom);

  useEffect(() => {
    if (!threeCore || !freeCamera) return;
    const { renderer, camera } = threeCore;
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.zoomSpeed = 1 / 10;
    controls.rotateSpeed = 1 / 2;
    return () => {
      controls.dispose();
    };
  }, [freeCamera, threeCore]);

  const render = useCallback(() => {
    if (!threeCore) return;
    const { water, renderer, scene, camera } = threeCore;
    water.material.uniforms["time"].value += 1.0 / 240.0;

    const timestamp = Math.round(Date.now() / 1000);
    if (windTurbineLightsGlobal) {
      const seconds = timestamp - Math.floor(timestamp / 10) * 10;
      windTurbineLightsGlobal.visible = seconds % 2 === 0;
    }

    if (turbineRotorsGlobal) {
      const timestampDeci = Math.round(Date.now() / 60);
      turbineRotorsGlobal.children.forEach((c) =>
        c.children.forEach(
          (c2) => (c2.rotation.x = MathUtils.degToRad(timestampDeci % 360)),
        ),
      );
    }

    renderer.render(scene, camera);
  }, [threeCore, turbineRotorsGlobal, windTurbineLightsGlobal]);

  useEffect(() => {
    let stopAnimation = false;
    function animate() {
      if (stopAnimation) return;
      setTimeout(() => {
        requestAnimationFrame(animate);
        render();
      }, 1000 / FPS);
    }
    animate();

    return () => {
      stopAnimation = true;
    };
  }, [render]);
}
