/// <reference types="vite-plugin-svgr/client" />
import React, { useCallback, useMemo } from "react";
import { useSetRecoilState } from "recoil";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import BinIcon from "@icons/24/Bin.svg?react";
import CopyIcon from "@icons/24/Copy.svg?react";
import InformationIcon from "@icons/24/Information.svg?react";
import { toastMessagesAtom } from "state/toast";
import useSystemSpecificUnicode from "hooks/useSystemSpecificUnicode";
import { useDeleteFeaturesCallback } from "hooks/deleteFeature";
import { ProjectFeature } from "types/feature";
import { colors } from "styles/colors";
import { featureIsLocked, isPark } from "utils/predicates";
import { Menu, MenuItem } from "components/General/Menu";
import { copy } from "components/MapControls/CopyPasteSelectedFeature";
import { ElementToIcon } from "components/ElementsToIcon/ElementsToIcon";
import { PARK_PROPERTY_TYPE } from "@constants/park";
import SelectElementMenuItem, {
  getFeatureName,
} from "./shared/SelectElementMenuItem";
import PasteRowMenuItem from "./shared/PasteRowMenuItem";
import ShowPreviousVersionsMenuItem from "./shared/ShowPreviousVersionsMenuItem";
import SelectAllParkElementOfTypeMenuItem from "./shared/SelectAllParkElementOfTypeMenuItem";
import { ElementIconWrapper } from "./SelectionMenu.style";
import AddToPortfolioMenuItem from "components/Organisation/Portfolio/AddToPortfolioMenuItem";

const SingleFeatureMenu = ({
  feature,
  featuresOnPoint,
  enableShowLayerInfo,
  closeMenu,
  sampleWmsCallback,
  onSelectFeature,
  onMouseEnterFeature,
  onMouseLeaveFeature,
}: {
  feature: MapboxGeoJSONFeature | ProjectFeature;
  featuresOnPoint: MapboxGeoJSONFeature[];
  enableShowLayerInfo: boolean;
  closeMenu(): void;
  sampleWmsCallback: () => void;
  onSelectFeature(feature: MapboxGeoJSONFeature): void;
  onMouseEnterFeature(feature: MapboxGeoJSONFeature): void;
  onMouseLeaveFeature(feature: MapboxGeoJSONFeature): void;
}) => {
  const setToastMessagesAtom = useSetRecoilState(toastMessagesAtom);
  const isLocked = useMemo(() => featureIsLocked(feature), [feature]);
  const stringToUnicode = useSystemSpecificUnicode();
  const deleteFeatureWithChildren = useDeleteFeaturesCallback();
  const deleteFeatures = useCallback(() => {
    deleteFeatureWithChildren([feature.properties!.id]);
  }, [feature, deleteFeatureWithChildren]);

  const copyCallback = useCallback(() => {
    copy([feature], [], [], setToastMessagesAtom);
  }, [feature, setToastMessagesAtom]);

  const selectedParkId =
    feature.properties &&
    feature.properties.type === PARK_PROPERTY_TYPE &&
    feature.id;

  return (
    <Menu style={{ boxShadow: "initial" }}>
      <MenuItem
        name={getFeatureName(feature)}
        disabled={true}
        style={{ borderBottom: `1px solid ${colors.inputOutline}` }}
        icon={
          <ElementIconWrapper>
            <ElementToIcon
              style={{
                width: "1.5rem",
                height: "1.5rem",
              }}
              element={feature as any}
              fillPolygons={true}
              fillParks={true}
            />
          </ElementIconWrapper>
        }
      />
      {isPark(feature as ProjectFeature) && (
        <AddToPortfolioMenuItem feature={feature} onAfterClick={closeMenu} />
      )}
      <MenuItem
        name={"Show layer info"}
        icon={<InformationIcon />}
        title={
          !enableShowLayerInfo
            ? "No WMS layers are visible in the map"
            : undefined
        }
        disabled={!enableShowLayerInfo}
        onClick={() => {
          sampleWmsCallback();
          closeMenu();
        }}
      />
      <MenuItem
        name={"Copy"}
        icon={<CopyIcon />}
        shortcut={`${stringToUnicode("command")}+C`}
        onClick={() => {
          copyCallback();
          closeMenu();
        }}
      />
      <PasteRowMenuItem closeMenu={closeMenu} />
      <SelectElementMenuItem
        onSelectFeature={onSelectFeature}
        onMouseEnterFeature={onMouseEnterFeature}
        onMouseLeaveFeature={onMouseLeaveFeature}
        closeMenu={closeMenu}
        features={featuresOnPoint}
      />
      {selectedParkId && (
        <SelectAllParkElementOfTypeMenuItem
          closeMenu={closeMenu}
          selectedParkId={selectedParkId.toString()}
        />
      )}
      <ShowPreviousVersionsMenuItem closeMenu={closeMenu} feature={feature} />
      <MenuItem
        name={"Delete"}
        style={{ borderTop: `1px solid ${colors.inputOutline}` }}
        shortcut={`${stringToUnicode("backspace")}`}
        icon={<BinIcon />}
        disabled={isLocked}
        onClick={() => {
          closeMenu();
          deleteFeatures();
        }}
      />
    </Menu>
  );
};

export default SingleFeatureMenu;
