import { fetchSchemaWithToken } from "services/utils";
import { FoundationType, _FoundationType } from "types/foundations";
import { TurbineType, _TurbineType } from "types/turbines";
import { OrgResource, _OrgResource } from "../OrganisationRightSide/types";
import {
  NodeAnalysisAccess,
  NodeDataPackageAccess,
  NodeFinancialAccess,
  NodeFoundationAccess,
  NodeLibraryResource,
  NodeTurbineAccess,
  UploadedVectorDataLayer,
  _NodeAnalysisAccess,
  _NodeCableAccess,
  _NodeDataPackageAccess,
  _NodeFinancialAccess,
  _NodeFoundationAccess,
  _NodeLibraryResource,
  _NodeTurbineAccess,
  _UploadedVectorDataLayer,
} from "./types";
import { CableType, _CableType } from "services/cableTypeService";
import { _CostConfiguration } from "services/costService";

// -------------------- Turbine resources -------------------------------
export const getOrganisationTurbineResources = async (
  organisationId: string,
): Promise<TurbineType[]> => {
  return fetchSchemaWithToken(
    _TurbineType.array(),
    `/api/turbines/organisation/${organisationId}`,
    {
      method: "get",
    },
  );
};

export const getTurbineResourceManagers = async (
  organisationId: string,
): Promise<OrgResource[]> => {
  return fetchSchemaWithToken(
    _OrgResource.array(),
    `/api/library/libraryaccess/organisation/${organisationId}/managers/turbine`,
    {
      method: "get",
    },
  );
};

export const getAnalysisResourceManagers = async (
  organisationId: string,
): Promise<OrgResource[]> => {
  return fetchSchemaWithToken(
    _OrgResource.array(),
    `/api/library/libraryaccess/organisation/${organisationId}/managers/analysis`,
    {
      method: "get",
    },
  );
};

export const getNodesWithAccessToTurbine = async (
  organisationId: string,
  resourceId: string,
): Promise<NodeLibraryResource[]> => {
  return fetchSchemaWithToken(
    _NodeLibraryResource.array(),
    `/api/library/libraryaccess/organisation/${organisationId}/turbine/${resourceId}`,
    {
      method: "get",
    },
  );
};

export const getTurbineResourcesOnNode = async (
  nodeId: string,
): Promise<NodeTurbineAccess[]> => {
  return fetchSchemaWithToken(
    _NodeTurbineAccess.array(),
    `/api/library/libraryaccess/node/${nodeId}/turbine/v2`,
    {
      method: "get",
    },
  );
};

export const addOrUpdateTurbineResourceOnNode = async (
  nodeId: string,
  resourceId: string,
): Promise<NodeLibraryResource[]> => {
  return fetchSchemaWithToken(
    _NodeLibraryResource.array(),
    `/api/library/libraryaccess/node/${nodeId}/turbine`,
    {
      method: "put",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ resource_id: resourceId }),
    },
  );
};

export const deleteTurbineResourceOnNode = async (
  nodeId: string,
  resourceId: string,
): Promise<NodeLibraryResource[]> => {
  return fetchSchemaWithToken(
    _NodeLibraryResource.array(),
    `/api/library/libraryaccess/node/${nodeId}/turbine/${resourceId}`,
    {
      method: "delete",
    },
  );
};

// -------------------- Foundation resources -------------------------------
export const getOrganisationFoundationResources = async (
  organisationId: string,
): Promise<FoundationType[]> => {
  return fetchSchemaWithToken(
    _FoundationType.array(),
    `/api/turbines/organisation/${organisationId}/foundations`,
    {
      method: "get",
    },
  );
};

export const getFoundationResourceManagers = async (
  organisationId: string,
): Promise<OrgResource[]> => {
  return fetchSchemaWithToken(
    _OrgResource.array(),
    `/api/library/libraryaccess/organisation/${organisationId}/managers/foundation`,
    {
      method: "get",
    },
  );
};

export const getNodesWithAccessToFoundation = async (
  organisationId: string,
  resourceId: string,
): Promise<NodeLibraryResource[]> => {
  return fetchSchemaWithToken(
    _NodeLibraryResource.array(),
    `/api/library/libraryaccess/organisation/${organisationId}/foundation/${resourceId}`,
    {
      method: "get",
    },
  );
};

export const getFoundationResourcesOnNode = async (
  nodeId: string,
): Promise<NodeFoundationAccess[]> => {
  return fetchSchemaWithToken(
    _NodeFoundationAccess.array(),
    `/api/library/libraryaccess/node/${nodeId}/foundation/v2`,
    {
      method: "get",
    },
  );
};

export const addOrUpdateFoundationResourceOnNode = async (
  nodeId: string,
  resourceId: string,
): Promise<NodeLibraryResource[]> => {
  return fetchSchemaWithToken(
    _NodeLibraryResource.array(),
    `/api/library/libraryaccess/node/${nodeId}/foundation`,
    {
      method: "put",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ resource_id: resourceId }),
    },
  );
};

export const deleteFoundationResourceOnNode = async (
  nodeId: string,
  resourceId: string,
): Promise<NodeLibraryResource[]> => {
  return fetchSchemaWithToken(
    _NodeLibraryResource.array(),
    `/api/library/libraryaccess/node/${nodeId}/foundation/${resourceId}`,
    {
      method: "delete",
    },
  );
};

// -------------------- Analysis resources -------------------------------

export const getNodesWithAccessToAnalysis = async (
  organisationId: string,
  resourceId: string,
): Promise<NodeLibraryResource[]> => {
  return fetchSchemaWithToken(
    _NodeLibraryResource.array(),
    `/api/library/libraryaccess/organisation/${organisationId}/analysis/${resourceId}`,
    {
      method: "get",
    },
  );
};

export const getAnalysisResourcesOnNode = async (
  nodeId: string,
): Promise<NodeAnalysisAccess[]> => {
  return fetchSchemaWithToken(
    _NodeAnalysisAccess.array(),
    `/api/library/libraryaccess/node/${nodeId}/analysis`,
    {
      method: "get",
    },
  );
};

export const addOrUpdateAnalysisResourceOnNode = async (
  nodeId: string,
  resourceId: string,
): Promise<NodeLibraryResource[]> => {
  return fetchSchemaWithToken(
    _NodeLibraryResource.array(),
    `/api/library/libraryaccess/node/${nodeId}/analysis`,
    {
      method: "put",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ resource_id: resourceId }),
    },
  );
};

export const deleteAnalysisResourceOnNode = async (
  nodeId: string,
  resourceId: string,
): Promise<NodeLibraryResource[]> => {
  return fetchSchemaWithToken(
    _NodeLibraryResource.array(),
    `/api/library/libraryaccess/node/${nodeId}/analysis/${resourceId}`,
    {
      method: "delete",
    },
  );
};

// -------------------- Cable resources -------------------------------
export const getOrganisationCableResources = async (
  organisationId: string,
): Promise<CableType[]> => {
  return fetchSchemaWithToken(
    _CableType.array(),
    `/api/cable/organisation/${organisationId}`,
    {
      method: "get",
    },
  );
};

export const getCableResourceManagers = async (
  organisationId: string,
): Promise<OrgResource[]> => {
  return fetchSchemaWithToken(
    _OrgResource.array(),
    `/api/library/libraryaccess/organisation/${organisationId}/managers/cable`,
    {
      method: "get",
    },
  );
};

export const getNodesWithAccessToCable = async (
  organisationId: string,
  resourceId: string,
): Promise<NodeLibraryResource[]> => {
  return fetchSchemaWithToken(
    _NodeLibraryResource.array(),
    `/api/library/libraryaccess/organisation/${organisationId}/cable/${resourceId}`,
    {
      method: "get",
    },
  );
};

export const addOrUpdateCableResourceOnNode = async (
  nodeId: string,
  resourceId: string,
): Promise<NodeLibraryResource[]> => {
  return fetchSchemaWithToken(
    _NodeLibraryResource.array(),
    `/api/library/libraryaccess/node/${nodeId}/cable`,
    {
      method: "put",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ resource_id: resourceId }),
    },
  );
};

export const deleteCableResourceOnNode = async (
  nodeId: string,
  resourceId: string,
): Promise<NodeLibraryResource[]> => {
  return fetchSchemaWithToken(
    _NodeLibraryResource.array(),
    `/api/library/libraryaccess/node/${nodeId}/cable/${resourceId}`,
    {
      method: "delete",
    },
  );
};

// -------------------- Financial resources -------------------------------
export const getFinancialResourcesOnNode = async (
  nodeId: string,
): Promise<NodeFinancialAccess[]> => {
  return fetchSchemaWithToken(
    _NodeFinancialAccess.array(),
    `/api/library/libraryaccess/node/${nodeId}/financial`,
    {
      method: "get",
    },
  );
};

export const getFinancialResourceManagers = async (
  organisationId: string,
): Promise<OrgResource[]> => {
  return fetchSchemaWithToken(
    _OrgResource.array(),
    `/api/library/libraryaccess/organisation/${organisationId}/managers/financial`,
    {
      method: "get",
    },
  );
};

export const getNodesWithAccessToFinancial = async (
  organisationId: string,
  resourceId: string,
): Promise<NodeLibraryResource[]> => {
  return fetchSchemaWithToken(
    _NodeLibraryResource.array(),
    `/api/library/libraryaccess/organisation/${organisationId}/financial/${resourceId}`,
    {
      method: "get",
    },
  );
};

export const addOrUpdateFinancialResourceOnNode = async (
  nodeId: string,
  resourceId: string,
): Promise<NodeLibraryResource[]> => {
  return fetchSchemaWithToken(
    _NodeLibraryResource.array(),
    `/api/library/libraryaccess/node/${nodeId}/financial`,
    {
      method: "put",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ resource_id: resourceId }),
    },
  );
};

export const deleteFinancialResourceOnNode = async (
  nodeId: string,
  resourceId: string,
): Promise<NodeLibraryResource[]> => {
  return fetchSchemaWithToken(
    _NodeLibraryResource.array(),
    `/api/library/libraryaccess/node/${nodeId}/financial/${resourceId}`,
    {
      method: "delete",
    },
  );
};

// -------------------- Data package resources -------------------------------
export const getDataPackageResourcesOnNode = async (
  nodeId: string,
): Promise<NodeDataPackageAccess[]> => {
  return fetchSchemaWithToken(
    _NodeDataPackageAccess.array(),
    `/api/library/libraryaccess/node/${nodeId}/datapackage`,
    {
      method: "get",
    },
  );
};

export const getDataLayerResourcesOnNode = async (
  nodeId: string,
): Promise<UploadedVectorDataLayer[]> => {
  return fetchSchemaWithToken(
    _UploadedVectorDataLayer.array(),
    `/api/library/libraryaccess/node/${nodeId}/datalayer`,
    {
      method: "get",
    },
  );
};

export const getDataPackageResourceManagers = async (
  organisationId: string,
): Promise<OrgResource[]> => {
  return fetchSchemaWithToken(
    _OrgResource.array(),
    `/api/library/libraryaccess/organisation/${organisationId}/managers/datapackage`,
    {
      method: "get",
    },
  );
};

export const getNodesWithAccessToDataPackage = async (
  organisationId: string,
  resourceId: string,
): Promise<NodeLibraryResource[]> => {
  return fetchSchemaWithToken(
    _NodeLibraryResource.array(),
    `/api/library/libraryaccess/organisation/${organisationId}/datapackage/${resourceId}`,
    {
      method: "get",
    },
  );
};

export const addOrUpdateDataPackageResourceOnNode = async (
  nodeId: string,
  resourceId: string,
): Promise<NodeLibraryResource[]> => {
  return fetchSchemaWithToken(
    _NodeLibraryResource.array(),
    `/api/library/libraryaccess/node/${nodeId}/datapackage`,
    {
      method: "put",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ resource_id: resourceId }),
    },
  );
};

export const deleteDataPackageResourceOnNode = async (
  nodeId: string,
  resourceId: string,
): Promise<NodeLibraryResource[]> => {
  return fetchSchemaWithToken(
    _NodeLibraryResource.array(),
    `/api/library/libraryaccess/node/${nodeId}/datapackage/${resourceId}`,
    {
      method: "delete",
    },
  );
};
