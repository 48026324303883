/// <reference types="vite-plugin-svgr/client" />
import { useCallback, useState } from "react";
import Reset from "@icons/14/Reset.svg?react";
import Button from "../../General/Button";
import FeatureFlag, { SHOW_DATA_CLEANING } from "../../General/FeatureFlag";
import { Layer } from "../../../types/layers";
import {
  generatePreviewImage,
  getImagePreviewUrl,
} from "../../../services/layerPreviewAPIService";
import Spinner from "@icons/spinner/Spinner";
import { isCustomLayer } from "../../LayerList/utils";

const RefreshImageDataCleaningInner = ({
  layer,
  afterRefresh,
}: {
  layer: Layer;
  afterRefresh: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const url = getImagePreviewUrl(layer, (layer as any)["sourceLink"]);
  const onClick = useCallback(async () => {
    if (!url) return;
    setIsLoading(true);
    await generatePreviewImage(url);
    setIsLoading(false);
    afterRefresh();
  }, [setIsLoading, url, afterRefresh]);

  return (
    <FeatureFlag name={SHOW_DATA_CLEANING}>
      {!isLoading && (
        <Button
          onClick={onClick}
          disabled={!url}
          size={"small"}
          buttonType="text"
          text={url ? "Refresh preview image" : "Unable to refresh image"}
          icon={<Reset />}
        />
      )}
      {isLoading && <Spinner />}
    </FeatureFlag>
  );
};

const RefreshImageDataCleaning = ({
  layer,
  afterRefresh,
}: {
  layer: Layer;
  afterRefresh: () => void;
}) => {
  if (isCustomLayer(layer)) {
    return null;
  }

  return (
    <FeatureFlag name={SHOW_DATA_CLEANING}>
      <RefreshImageDataCleaningInner
        layer={layer}
        afterRefresh={afterRefresh}
      />
    </FeatureFlag>
  );
};

export default RefreshImageDataCleaning;
