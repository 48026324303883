import { syncLocalStorage } from "state/effects";
import { atom } from "recoil";
import { z } from "zod";
import { Angle, TurbineDistance } from "components/Units/units";

export const ELLIPSIS_KEY = "vind:turbine-ellipses";
export type EllipsesFeature = z.infer<typeof _EllipsesProps>;

const _EllipsesProps = z.object({
  show: z.boolean().default(true),
  minorAxis: TurbineDistance.zod.default({
    value: 100,
    unit: "m",
  }),
  majorAxis: TurbineDistance.zod.default({
    value: 100,
    unit: "m",
  }),
  orientation: Angle.zod.default({ value: 0, unit: "deg" }),
});

export const ellipsesPerTurbineAtom = atom<Record<string, EllipsesFeature>>({
  key: "ellipsesPerTurbineAtom",
  default: undefined,
  effects: [
    syncLocalStorage(ELLIPSIS_KEY, z.record(z.string(), _EllipsesProps)),
  ],
});
