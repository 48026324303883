import { useState } from "react";
import { objectEquals } from "utils/utils";

/**
 * Deep-compare gates the input value to return the same object as long as the
 * deep compare succeeds. Useful to ensure referential equality, even if the
 * objects change.
 *
 * Can optionally supply your own comparison function.
 */
export const useDeep = <T>(t: T, cmp?: (a: T, b: T) => boolean): T => {
  const [val, set] = useState(t);
  if (!(cmp ?? objectEquals)(val, t)) set(t);
  return val;
};
