import {
  atomFamily,
  selectorFamily,
  useRecoilCallback,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import {
  GroupOrgResource,
  GroupedOrgResource,
  GroupedOrgResourceEntry,
  OrganisationResources,
  PersonalOrgResource,
  OrgResource,
} from "../../types";
import {
  addLibraryResourceManager,
  getCurrentUserOrganisationResources,
  getUserOrganisationResources,
  removeUserLibraryResourceManager,
} from "../../service";
import { scream } from "utils/sentry";
import { toastMessagesAtom } from "state/toast";
import { isPersonalOrgResource } from "utils/predicates";
import {
  analysisManagersSelector,
  cableManagersSelector,
  dataPackageManagersSelector,
  financialManagersSelector,
  foundationManagersSelector,
  turbineManagersSelector,
} from "components/Organisation/Library/state";
import {
  loggedInUserSelector,
  orgAnalysisManageAccessSelector,
  orgCableManageAccessSelector,
  orgDataPackagesManageAccessSelector,
  orgFinanicalManageAccessSelector,
  orgFoundationManageAccessSelector,
  orgTurbineManageAccessSelector,
} from "state/user";

export const PERSONAL_GROUP = "personal";

export const currentUserOrganisationResourcesSelectorFamily = selectorFamily<
  OrgResource[],
  { organisationId: string }
>({
  key: "currentUserOrganisationResourcesAtomFamily",
  get:
    ({ organisationId }) =>
    () =>
      getCurrentUserOrganisationResources(organisationId),
});

export const userOrganisationResourcesAtomFamily = atomFamily<
  OrgResource[],
  { organisationId: string; userId: string }
>({
  key: "userOrganisationResourcesAtomFamily",
  default: selectorFamily({
    key: "userOrganisationResourcesAtomFamily.default",
    get:
      ({ organisationId, userId }) =>
      async () =>
        await getUserOrganisationResources(organisationId, userId),
  }),
});

export const groupedUserOrgResourcesAccessSelectorFamily = selectorFamily<
  GroupedOrgResource,
  { organisationId: string; userId: string }
>({
  key: "groupedOrgResourcesAccessSelectorFamily",
  get:
    ({ organisationId, userId }) =>
    async ({ get }) => {
      const rawResults = get(
        userOrganisationResourcesAtomFamily({ organisationId, userId }),
      );
      return rawResults.reduce((acc, orgResource) => {
        let index;
        let name;
        if (orgResource.type === PERSONAL_GROUP) {
          const personalResource = orgResource as PersonalOrgResource;
          index = personalResource.type;
          name = PERSONAL_GROUP;
        } else {
          const groupResourceMaybe = orgResource as GroupOrgResource;
          index = groupResourceMaybe.group_id;
          name = groupResourceMaybe.group_name;
        }

        if (!acc[index])
          acc[index] = { name, resources: [] } as GroupedOrgResourceEntry;

        acc[index].resources.push(orgResource.resource_name);
        return acc;
      }, {} as GroupedOrgResource);
    },
});

const useUserOrganisationResourcesCrud = () => {
  const setToastMessages = useSetRecoilState(toastMessagesAtom);
  const currentUserId = useRecoilValue(loggedInUserSelector)?.user_id;

  const add = useRecoilCallback(
    ({ set, snapshot, refresh }) =>
      async (
        organisationId: string,
        userId: string,
        resourcesName: OrganisationResources,
      ) => {
        const prevState = await snapshot.getPromise(
          userOrganisationResourcesAtomFamily({
            organisationId,
            userId,
          }),
        );
        set(
          userOrganisationResourcesAtomFamily({
            organisationId,
            userId,
          }),
          (curr) =>
            curr.some(
              (c) =>
                isPersonalOrgResource(c) &&
                c.resource_name === resourcesName &&
                c.user_id,
            )
              ? curr
              : [
                  ...curr,
                  {
                    user_id: userId,
                    resource_name: resourcesName,
                    type: "personal",
                  } as PersonalOrgResource,
                ],
        );
        return addLibraryResourceManager(
          organisationId,
          userId,
          resourcesName,
          "user",
        )
          .then(() => {
            refresh(foundationManagersSelector({ organisationId }));
            refresh(turbineManagersSelector({ organisationId }));
            refresh(analysisManagersSelector({ organisationId }));
            refresh(cableManagersSelector({ organisationId }));
            refresh(financialManagersSelector({ organisationId }));
            refresh(dataPackageManagersSelector({ organisationId }));
            if (currentUserId === userId) {
              refresh(
                currentUserOrganisationResourcesSelectorFamily({
                  organisationId,
                }),
              );
              refresh(orgTurbineManageAccessSelector);
              refresh(orgFoundationManageAccessSelector);
              refresh(orgCableManageAccessSelector);
              refresh(orgAnalysisManageAccessSelector);
              refresh(orgFinanicalManageAccessSelector);
              refresh(orgDataPackagesManageAccessSelector);
            }
          })
          .catch((e) => {
            scream(e);
            set(
              userOrganisationResourcesAtomFamily({
                organisationId,
                userId,
              }),
              prevState,
            );
            setToastMessages((tm) => [
              ...tm,
              {
                text: "Error while trying to update",
                timeout: 5000,
                type: "error",
              },
            ]);
          });
      },
    [currentUserId, setToastMessages],
  );
  const remove = useRecoilCallback(
    ({ set, snapshot, refresh }) =>
      async (
        organisationId: string,
        userId: string,
        resourcesName: OrganisationResources,
      ) => {
        const prevState = await snapshot.getPromise(
          userOrganisationResourcesAtomFamily({
            organisationId,
            userId,
          }),
        );
        set(
          userOrganisationResourcesAtomFamily({
            organisationId,
            userId,
          }),
          (curr) =>
            [...curr].filter((c) => {
              if (c.type === "group") return true;
              return !(
                isPersonalOrgResource(c) &&
                c.resource_name === resourcesName &&
                c.user_id === userId
              );
            }),
        );
        return removeUserLibraryResourceManager(
          organisationId,
          userId,
          resourcesName,
        )
          .then(() => {
            refresh(foundationManagersSelector({ organisationId }));
            refresh(turbineManagersSelector({ organisationId }));
            refresh(analysisManagersSelector({ organisationId }));
            refresh(cableManagersSelector({ organisationId }));
            refresh(financialManagersSelector({ organisationId }));
            refresh(dataPackageManagersSelector({ organisationId }));
            if (currentUserId === userId) {
              refresh(
                currentUserOrganisationResourcesSelectorFamily({
                  organisationId,
                }),
              );
              refresh(orgDataPackagesManageAccessSelector);
              refresh(orgTurbineManageAccessSelector);
              refresh(orgFoundationManageAccessSelector);
              refresh(orgCableManageAccessSelector);
              refresh(orgAnalysisManageAccessSelector);
              refresh(orgFinanicalManageAccessSelector);
            }
          })
          .catch((e) => {
            scream(e);
            set(
              userOrganisationResourcesAtomFamily({
                organisationId,
                userId,
              }),
              prevState,
            );
            setToastMessages((tm) => [
              ...tm,
              {
                text: "Error while trying to update",
                timeout: 5000,
                type: "error",
              },
            ]);
          });
      },
    [currentUserId, setToastMessages],
  );

  return { add, remove };
};

export default useUserOrganisationResourcesCrud;
