import {
  BathymetryStyle,
  MapStyle,
  bathymetryStyles,
  availableMapStyles,
  otherMap,
  OtherMap,
} from "@constants/availableMapStyles";
import { replaceOrUndefined } from "components/ControlPanels/utils";
import { Row } from "components/General/Layout";
import Tooltip from "components/General/Tooltip";
import { MenuFrame } from "components/MenuPopup/CloseableMenuPopup";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { lowerRightMenuActiveModeAtom } from "state/layer";
import {
  activeBathymetryStyleIdAtom,
  activeBathymetryStyleSelector,
  activeMapStyleIdAtom,
  activeOtherMapIdAtom,
  getActiveMapStyleSelector,
  getActiveOtherMapSelector,
} from "state/map";
import styled from "styled-components";
import { colors } from "styles/colors";
import { borderRadiusMedium, spaceTiny } from "styles/space";
import { IconREMSize, typography } from "styles/typography";
import { Comp } from "types/utils";
import OpenWindowRight from "icons/24/OpenWindowRight.svg?react";

const DisabledWrapper = styled.button`
  ${typography.caption};
  display: flex;
  flex-direction: column;
  gap: ${spaceTiny};
  background: initial;

  opacity: 0.5;

  padding: 0.8rem;
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }

  p {
    margin: 0;
    max-width: 4.8rem;
  }

  img {
    border-radius: ${borderRadiusMedium};
    width: 4.8rem;
    height: 4.8rem;
    margin: 2px;
  }
`;

const ImgButtonWrapper = styled.button<{ active: boolean }>`
  ${typography.caption};
  color: ${colors.textPrimary};
  display: flex;
  flex-direction: column;
  gap: ${spaceTiny};
  background: initial;
  cursor: pointer;
  align-items: center;

  ${(p) => p.disabled && `opacity: 0.5;`}

  padding: 0.8rem;
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }

  &:hover {
    p {
      color: ${colors.textHover};
    }
    img {
      border: 2px solid ${colors.grey400};
      margin: 0;
    }
  }

  ${(p) => p.active && typography.sub2}
  ${(p) => p.active && `color: ${colors.textSelected} !important;`}
  

  img {
    border-radius: ${borderRadiusMedium};
    width: 4.8rem;
    height: 4.8rem;
    ${(p) =>
      p.active && `border: 2px solid ${colors.borderSelected} !important;`}
    margin: ${(p) => (p.active ? "0px" : "2px")};
  }
`;

const ImgButton = ({
  style,
  active,
  disabled,
  ...props
}: Comp<
  "button",
  {
    style: BathymetryStyle | MapStyle | OtherMap;
    active: boolean;
    disabled: boolean;
  }
>) => {
  const text =
    style.id === "lcoe-map" ? (
      <>
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          {"LCoE"}
          <IconREMSize height={1} width={1}>
            <OpenWindowRight
              style={{ marginLeft: "0.5rem", paddingBottom: "0.4rem" }}
            />
          </IconREMSize>
        </div>
      </>
    ) : "text" in style ? (
      style.text
    ) : (
      style.name
    );

  if (disabled)
    return (
      <DisabledWrapper {...props}>
        <img src={style.image} alt={`image ${text}`} />
        {text}
      </DisabledWrapper>
    );

  return (
    <ImgButtonWrapper active={active} {...props}>
      <img src={style.image} alt={`image ${text}`} />
      <Tooltip
        text={
          "tooltip" in style && style.tooltip !== undefined ? style.tooltip : ""
        }
        disabled={!("tooltip" in style)}
      >
        {text}
      </Tooltip>
    </ImgButtonWrapper>
  );
};

const SubHeader = styled.p`
  ${typography.sub1}
  margin: 0.8rem 0 0.4rem;
`;

export const MapStyleFrame = () => {
  const setMapStyle = useSetRecoilState(activeMapStyleIdAtom);
  const activeMapStyle = useRecoilValue(getActiveMapStyleSelector);

  const activeBathymetryStyle = useRecoilValue(activeBathymetryStyleSelector);
  const setBathymetryStyleId = useSetRecoilState(activeBathymetryStyleIdAtom);

  const setOtherMap = useSetRecoilState(activeOtherMapIdAtom);
  const activeOtherMap = useRecoilValue(getActiveOtherMapSelector);

  const [, setLowerRightActiveMode] = useRecoilState(
    lowerRightMenuActiveModeAtom,
  );

  return (
    <MenuFrame
      title="Map"
      onExit={() => setLowerRightActiveMode(undefined)}
      style={{ width: "fit-content" }}
    >
      <SubHeader>Map style</SubHeader>
      <Row style={{ gap: 0 }}>
        {availableMapStyles.map((style) => (
          <ImgButton
            disabled={false}
            style={style}
            key={style.id}
            active={activeMapStyle?.id === style.id}
            onClick={() => {
              setMapStyle(style.id);
              if (style.id !== activeMapStyle?.id) document.location.reload();
            }}
          />
        ))}
      </Row>
      <SubHeader>Bathymetry style</SubHeader>
      <Row style={{ gap: 0 }}>
        <Tooltip
          text={`Not available with Satellite`}
          disabled={activeMapStyle?.useBathymetry}
          innerDivStyle={{ alignItems: "start" }}
        >
          {bathymetryStyles.map((style) => (
            <ImgButton
              disabled={!activeMapStyle?.useBathymetry}
              style={style}
              key={style.id}
              active={activeBathymetryStyle?.id === style.id}
              onClick={() => {
                if (activeBathymetryStyle?.id === style.id) {
                  setBathymetryStyleId("none");
                } else {
                  setBathymetryStyleId(style.id);
                }
              }}
            />
          ))}
        </Tooltip>
      </Row>
      <SubHeader>Overlay maps</SubHeader>
      <Row style={{ gap: 0 }}>
        <Tooltip
          text={`Not available with Satellite`}
          disabled={activeMapStyle?.useBathymetry}
          innerDivStyle={{ alignItems: "start" }}
        >
          {otherMap.map((style) => (
            <ImgButton
              disabled={!activeMapStyle?.useBathymetry}
              style={style}
              key={style.id}
              active={activeOtherMap?.id === style.id}
              onClick={() => {
                if (activeOtherMap?.id === style.id) {
                  setOtherMap(undefined);
                } else {
                  setOtherMap(style.id);
                  if (style.id === "lcoe-map") {
                    setLowerRightActiveMode(replaceOrUndefined("cost"));
                  }
                }
              }}
            />
          ))}
        </Tooltip>
      </Row>
    </MenuFrame>
  );
};
