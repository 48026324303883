/// <reference types="vite-plugin-svgr/client" />
import React from "react";
import { useRecoilValue } from "recoil";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import { Mixpanel } from "mixpanel";
import AddIcon from "@icons/24/Add.svg?react";
import CheckIcon from "@icons/24/Check.svg?react";
import Spinner from "@icons/spinner/Spinner";
import { ProjectFeature } from "types/feature";
import usePortfolioService from "hooks/usePortfolioService";
import { MenuItem } from "components/General/Menu";
import { adminInOrganisationSelectorFamily } from "state/user";
import { organisationIdSelector } from "state/pathParams";
import { useRecoilValueDef } from "utils/recoil";

const AddToPortfolioMenuItemInner = ({
  feature,
  onAfterClick,
}: {
  feature: MapboxGeoJSONFeature | ProjectFeature;
  onAfterClick?(): void;
}) => {
  const {
    create: addToPortfolio,
    remove: removeFromPortfolio,
    isPartOfPortfolio,
  } = usePortfolioService();

  return (
    <MenuItem
      name={
        isPartOfPortfolio(feature.properties!.id)
          ? "Remove park from portfolio"
          : "Add park to portfolio"
      }
      icon={
        isPartOfPortfolio(feature.properties!.id) ? <CheckIcon /> : <AddIcon />
      }
      // title={"No parks are visible in the map"}
      onClick={() => {
        if (isPartOfPortfolio(feature.properties!.id)) {
          Mixpanel.track("Remove portfolio park from menu item", {});
          removeFromPortfolio(feature.properties!.id);
        } else {
          Mixpanel.track("Add portfolio park from menu item", {});
          addToPortfolio(feature.properties!.id);
        }
        onAfterClick?.();
      }}
    />
  );
};

const AddToPortfolioMenuItem = ({
  feature,
  onAfterClick,
}: {
  feature: MapboxGeoJSONFeature | ProjectFeature;
  onAfterClick?(): void;
}) => {
  const organisationId = useRecoilValueDef(organisationIdSelector);
  const isOrgAdmin = useRecoilValue(
    adminInOrganisationSelectorFamily({ organisationId }),
  );

  if (!isOrgAdmin) {
    return null;
  }

  return (
    <React.Suspense
      fallback={
        <MenuItem
          name={"Loading portfolio"}
          icon={<Spinner size="0.7rem" />}
          disabled={true}
        />
      }
    >
      <AddToPortfolioMenuItemInner
        feature={feature}
        onAfterClick={onAfterClick}
      />
    </React.Suspense>
  );
};

export default AddToPortfolioMenuItem;
