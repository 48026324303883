import { CenterContainer, SafeCard } from "./Base";
import { useDashboardContext } from "../Dashboard";
import { useRecoilValueLoadable } from "recoil";
import {
  getStoppedReason,
  getStoppedReasonFromAnalysis,
} from "components/ProductionV2/state";
import {
  analysisStoppedText,
  AnalysisStoppedTypes,
} from "components/ProductionV2/types";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { CostType } from "services/costService";
import { useRecoilValueLoadable2 } from "utils/recoil";
import { CAPEX_STATE } from "components/Finance/state";
import { GeneralCost } from "components/FinancialAnalysis/Capex/capexNumbers";
import { Stat, StatWrapper, Stats } from "./Base.style";

const InputDetails = () => {
  const { costConfiguration, triggerId } = useDashboardContext();

  const stoppedReason = useRecoilValueLoadable(
    getStoppedReason(triggerId),
  ).valueMaybe();

  const analysisStoppedReason = useRecoilValueLoadable(
    getStoppedReasonFromAnalysis(triggerId),
  ).valueMaybe();

  const turbineCosts: GeneralCost[] = useRecoilValueLoadable2(
    CAPEX_STATE.getCostsOfType({ id: triggerId, type: CostType.Turbine }),
  ).contents;

  const cableCosts: GeneralCost[] = useRecoilValueLoadable2(
    CAPEX_STATE.getCostsOfType({ id: triggerId, type: CostType.Cable }),
  ).contents;

  const foundationCosts: GeneralCost[] = useRecoilValueLoadable2(
    CAPEX_STATE.getCostsOfType({ id: triggerId, type: CostType.Foundation }),
  ).contents;

  const substationCosts: GeneralCost[] = useRecoilValueLoadable2(
    CAPEX_STATE.getCostsOfType({ id: triggerId, type: CostType.Substation }),
  ).contents;

  const exportCableCosts: GeneralCost[] = useRecoilValueLoadable2(
    CAPEX_STATE.getCostsOfType({ id: triggerId, type: CostType.ExportCable }),
  ).contents;

  const mooringCosts: GeneralCost[] = useRecoilValueLoadable2(
    CAPEX_STATE.getCostsOfType({ id: triggerId, type: CostType.Mooring }),
  ).contents;

  const otherCosts: GeneralCost[] = useRecoilValueLoadable2(
    CAPEX_STATE.getCostsOfType({ id: triggerId, type: CostType.Other }),
  ).contents;

  if (stoppedReason !== undefined) {
    return (
      <CenterContainer>
        <SimpleAlert text={analysisStoppedText[stoppedReason]} type={"error"} />
      </CenterContainer>
    );
  }
  if (analysisStoppedReason) {
    return (
      <CenterContainer>
        <SimpleAlert
          text={
            analysisStoppedText[analysisStoppedReason as AnalysisStoppedTypes]
          }
          type={"error"}
        />
      </CenterContainer>
    );
  }

  return (
    <Stats>
      <Stat
        title="CAPEX reference year"
        value={costConfiguration?.inflation.referenceYearCapex}
        bold={false}
      />

      <h4>Turbines</h4>
      {turbineCosts.length > 0 &&
        turbineCosts
          .filter(({ amount }) => amount > 0)
          .map((c) => {
            return (
              <StatWrapper>
                <p>{c.name}</p>
                <p>
                  {c.costPerAmount} {c.unit}
                </p>
              </StatWrapper>
            );
          })}

      <h4>Inter array cables</h4>
      {cableCosts.length > 0 &&
        cableCosts
          .filter(({ amount }) => amount > 0)
          .map((c) => {
            return (
              <StatWrapper>
                <p>{c.name}</p>
                <p>
                  {c.costPerAmount} {c.unit}
                </p>
              </StatWrapper>
            );
          })}

      <h4>Foundations</h4>
      {foundationCosts.length > 0 &&
        foundationCosts
          .filter(({ amount }) => amount > 0)
          .map((c) => {
            return (
              <StatWrapper>
                <p>{c.name}</p>
                <p>
                  {c.costPerAmount} {c.unit}
                </p>
              </StatWrapper>
            );
          })}

      <h4>Mooring system</h4>
      {mooringCosts.length > 0 &&
        mooringCosts
          .filter(({ amount }) => amount > 0)
          .map((c) => {
            return (
              <StatWrapper>
                <p>{c.name}</p>
                <p>
                  {c.costPerAmount} {c.unit}
                </p>
              </StatWrapper>
            );
          })}

      <h4>Substations</h4>
      {substationCosts.length > 0 &&
        substationCosts
          .filter(({ amount }) => amount > 0)
          .map((c) => {
            return (
              <StatWrapper>
                <p>{c.name}</p>
                <p>
                  {c.costPerAmount} {c.unit}
                </p>
              </StatWrapper>
            );
          })}

      <h4>Export cable</h4>
      {exportCableCosts.length > 0 &&
        exportCableCosts
          .filter(({ amount }) => amount > 0)
          .map((c) => {
            return (
              <StatWrapper>
                <p>{c.name}</p>
                <p>
                  {c.costPerAmount} {c.unit}
                </p>
              </StatWrapper>
            );
          })}

      <h4>Other</h4>
      {otherCosts.length > 0 &&
        otherCosts
          .filter(({ amount }) => amount > 0)
          .map((c) => {
            return (
              <StatWrapper>
                <p>{c.name}</p>
                <p>
                  {c.costPerAmount} {c.unit}
                </p>
              </StatWrapper>
            );
          })}
    </Stats>
  );
};

export const CapexInputWidget = () => (
  <SafeCard title="Capex input" id="Capex input">
    <InputDetails />
  </SafeCard>
);
