import { useEffect } from "react";
import { selectorFamily, useRecoilValue } from "recoil";
import { getOverlappingTurbinesSelector } from "state/layout";
import { ValidationWarningTypes } from "./utils";
import { useValidationWarnings } from "./ValidationWarnings";
import { turbineSourceId } from "components/Mapbox/constants";
import { getParkFeatureSelectorFamily } from "state/park";
import { branchIdSelector } from "state/pathParams";

export const turbineOverlapWarningSelectorFamily = selectorFamily<
  | {
      type: ValidationWarningTypes.TurbineOverlap;
      featureIds: string[];
      parkId: string;
    }
  | undefined,
  {
    parkId: string;
    branchId: string;
  }
>({
  key: "turbineOverlapWarningSelectorFamily",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const park = get(getParkFeatureSelectorFamily({ parkId }));
      if (!park) return;

      const overlappingTurbines = get(
        getOverlappingTurbinesSelector({ parkId: park.id, branchId: branchId }),
      );

      return overlappingTurbines.length === 0
        ? undefined
        : {
            type: ValidationWarningTypes.TurbineOverlap,
            featureIds: overlappingTurbines.map((t) => t.id),
            parkId: parkId,
          };
    },
});

export const TurbineOverlap = ({
  parkId,
  map,
  source = turbineSourceId,
}: {
  parkId: string;
  map: mapboxgl.Map;
  source?: string;
}) => {
  const branchId = useRecoilValue(branchIdSelector);

  const turbineOverlapWarning = useRecoilValue(
    turbineOverlapWarningSelectorFamily({
      parkId,
      branchId: branchId ?? "",
    }),
  );
  const { warn, remove } = useValidationWarnings();
  useEffect(() => {
    if (!turbineOverlapWarning) return;
    warn(turbineOverlapWarning);
    if (map.getSource(source))
      for (const id of turbineOverlapWarning.featureIds)
        map.setFeatureState({ source, id }, { overlap: true });

    return () => {
      remove(ValidationWarningTypes.TurbineOverlap);
      if (map.getSource(source) && turbineOverlapWarning?.featureIds)
        for (const id of turbineOverlapWarning.featureIds)
          map.removeFeatureState({ source, id }, "overlap");
    };
  }, [turbineOverlapWarning, map, remove, source, warn]);

  return null;
};
