import { z } from "zod";
import { validate } from "uuid";
import {
  fetchEnhancerWithToken,
  fetchSchemaWithToken,
} from "../../services/utils";
import { PROJECT_SERVICE_API_VERSION } from "../ProjectElements/service";
import { scream } from "utils/sentry";

const _KeyVersion = z.object({
  author: z.string().optional(),
  start: z.number(),
  end: z.number(),
  meta: z.object({ count: z.number() }),
});

export const _ProjectElementFolderKeyVersion = _KeyVersion.extend({
  action: z.enum(["UPDATE", "DELETE"]),
});

export const _ProjectElementFolder = z.object({
  folderId: z.string(),
  featureIds: z
    .union([
      z.object({
        type: z.enum(["folder", "feature"]),
        id: z.string(),
      }),
      z.string().transform<{ type: "feature"; id: string }>((id) => ({
        type: "feature",
        id,
      })),
    ])
    .array(),
  folderName: z.string(),
  sortOrder: z.number().optional(),
  isTemporary: z.boolean().optional(),
  parentId: z.string().optional(),
});

export const restoreProjectElementsFromBranchVersionFolder = async (
  nodeId: string,
  fromBranchId: string,
  fromVersion: number,
  toBranchId: string,
) =>
  fetchEnhancerWithToken(
    `/api/project-elements/node/${nodeId}/${toBranchId}/restore/${fromBranchId}/${fromVersion}`,
    {
      method: "POST",
    },
  )
    .then((res) => res.json())
    .then(_ProjectElementFolder.array().parse);

export const getProjectElementsFolders = async (
  nodeId: string,
  branchId: string,
  version?: number,
) =>
  fetchEnhancerWithToken(
    `/api/project-elements/node/${nodeId}/${branchId}${
      version ? `?version=${version}` : ""
    }`,
    {
      method: "GET",
    },
  )
    .then((res) => res.json())
    .then(_ProjectElementFolder.array().parse);

export type ProjectElementFolderKeyVersion = z.infer<
  typeof _ProjectElementFolderKeyVersion
>;
export const listProjectElementsFoldersVersions = async (
  nodeId: string,
  branchId: string,
) =>
  fetchSchemaWithToken(
    z.array(_ProjectElementFolderKeyVersion),
    `/api/project-elements/node/${nodeId}/${branchId}/key_versions`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-project-data-client-version": PROJECT_SERVICE_API_VERSION,
      },
    },
  );

export const updateProjectElementsFolder = async (
  nodeId: string,
  branchId: string,
  folder: ProjectElementFolderType,
) =>
  fetchEnhancerWithToken(
    `/api/project-elements/node/${nodeId}/${branchId}/${folder.folderId}`,
    {
      method: "PUT",
      body: JSON.stringify({
        featureIds: folder.featureIds,
        folderName: folder.folderName,
        parentId: folder.parentId,
      }),
    },
  )
    .then((res) => res.json())
    .then(_ProjectElementFolder.parse);

export const deleteProjectElementsFolder = async (
  nodeId: string,
  branchId: string,
  folderId: string,
) =>
  fetchEnhancerWithToken(
    `/api/project-elements/node/${nodeId}/${branchId}/${folderId}`,
    {
      method: "DELETE",
    },
  ).then((res) => res.json());

export type ProjectElementFolderType = z.infer<typeof _ProjectElementFolder>;
export type FeatureIds = ProjectElementFolderType["featureIds"];

export const createProjectElementsFolder = async (
  nodeId: string,
  branchId: string,
  folder: Omit<ProjectElementFolderType, "folderId">,
) =>
  fetchEnhancerWithToken(
    `/api/project-elements/node/${nodeId}/${branchId}`,
    {
      method: "POST",
      body: JSON.stringify({
        featureIds: folder.featureIds,
        folderName: folder.folderName,
        parentId: folder.parentId,
      }),
    },
    3,
  )
    .then((res) => res.json())
    .then(_ProjectElementFolder.parse);

export const _ProjectElementSortOrder = z.object({
  id: z.string(),
  type: z.enum(["folder", "feature"]),
  sortOrder: z.number(),
});
export type ProjectElementSortOrder = z.infer<typeof _ProjectElementSortOrder>;
export const sortProjectElements = async (
  nodeId: string,
  branchId: string,
  sortOrder: ProjectElementSortOrder[],
): Promise<any> =>
  fetchEnhancerWithToken(
    `/api/project-elements/node/${nodeId}/${branchId}/sort-order`,
    {
      method: "POST",
      body: JSON.stringify({
        sortOrder,
      }),
    },
  )
    .then((res) => res.json())
    .then(_ProjectElementSortOrder.array().parse);

export const getProjectElementsSortOrder = async (
  nodeId: string,
  branchId: string,
): Promise<ProjectElementSortOrder[]> => {
  if (!validate(nodeId)) {
    scream("Calling sort-order with invalid uuid", {
      url: window.location.href,
      nodeId,
      branchId,
    });
  }
  return fetchEnhancerWithToken(
    `/api/project-elements/node/${nodeId}/${branchId}/sort-order`,
    {
      method: "GET",
    },
  )
    .then((res) => res.json())
    .then(_ProjectElementSortOrder.array().parse);
};
