/// <reference types="vite-plugin-svgr/client" />
import Button from "components/General/Button";
import Fuse from "fuse.js";
import { Input } from "components/General/Input";
import { Row } from "components/General/Layout";
import Close from "@icons/24/Close.svg?react";
import TeamMeeting from "@icons/24/TeamMeeting.svg?react";
import useTextInput from "hooks/useTextInput";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useTypedPath } from "state/pathParams";
import { Group } from "components/Organisation/Groups/types";
import { IconBtn } from "components/General/Icons";
import {
  ContentTableColumn,
  ContentTableRow,
  Divider,
  ResultContainer,
  SearchAndSelectContainer,
  SecondaryText,
  Text,
} from "../style";
import { organisationGroupsState } from "components/Organisation/Groups/state";
import { usersInOrganisationState } from "components/Organisation/state";
import { OrganisationUser } from "types/customer";
import UserImageRound from "components/UserImage/UserImageRound";
import { SVGWrapper } from "@icons/svgUtils";
import Dropdown from "components/Dropdown/Dropdown";
import { UserAccessRole, _UserAccessRole } from "types/user";
import SelectedLabel from "components/General/SelectedLabel";
import Spinner from "@icons/spinner/Spinner";
import { typography } from "styles/typography";
import { colors } from "styles/colors";
import { Link } from "react-router-dom";

export type UserAccess = string;
export type GroupAccess = string;

export default function UserGroupAccessModal({
  resourceName,
  existingUsers,
  existingGroups,
  onSave,
  onCancel,
  disableResourceName,
  isSaving,
  style,
  showAddExistingMembersMessage = true,
  autoFocus = true,
  showAlreadyExistingItems = true,
}: {
  resourceName?: string;
  existingUsers: { user_id: string }[];
  existingGroups: { group_id: string }[];
  isSaving?: boolean;
  autoFocus?: boolean;
  showAlreadyExistingItems?: boolean;
  showAddExistingMembersMessage?: boolean;
  style?: React.CSSProperties;
  onSave: (
    newAccess: { users: UserAccess[]; groups: GroupAccess[] },
    accessRole: UserAccessRole,
  ) => void;
  onCancel?: () => void;
  disableResourceName?: boolean;
}) {
  const { organisationId } = useTypedPath("organisationId");
  const usersInOrg = useRecoilValue(usersInOrganisationState(organisationId));
  const groupsInOrg = useRecoilValue(
    organisationGroupsState({ organisationId }),
  );

  const [name, onNameChange] = useTextInput("");
  const [selectedGroups, setSelectedGroups] = useState<GroupAccess[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<UserAccess[]>([]);
  const [selectedRole, setSelectedRole] = useState<UserAccessRole>("viewer");
  const [filteredGroups, setFilteredGroups] = useState<Group[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<OrganisationUser[]>([]);

  useEffect(() => {
    const fuseGroups = new Fuse(groupsInOrg, {
      keys: ["name"],
      includeScore: true,
      threshold: 0.3,
    });
    const fuseUsers = new Fuse(usersInOrg, {
      keys: ["nickname", "email"],
      includeScore: true,
      threshold: 0.2,
    });

    const groupsResult = fuseGroups.search(name).map((result) => result.item);
    const userResult = fuseUsers.search(name).map((result) => result.item);
    setFilteredGroups(name.length ? groupsResult : groupsInOrg);
    setFilteredUsers(name.length ? userResult : usersInOrg);
  }, [name, groupsInOrg, usersInOrg]);

  const toggleGroupSelection = (groupId: GroupAccess) => {
    setSelectedGroups((prevSelectedGroups) => {
      if (prevSelectedGroups.find((g) => g === groupId)) {
        return prevSelectedGroups.filter((g) => g !== groupId);
      } else {
        return [...prevSelectedGroups, groupId];
      }
    });
  };
  const toggleUserSelection = (userId: UserAccess) => {
    setSelectedUsers((prevSelectedUsers) => {
      if (prevSelectedUsers.find((u) => u === userId)) {
        return prevSelectedUsers.filter((u) => u !== userId);
      } else {
        return [...prevSelectedUsers, userId];
      }
    });
  };

  return (
    <SearchAndSelectContainer style={style}>
      <Row
        style={{
          alignItems: "center",
          gap: "0.8rem",
          padding: "1.6rem 1.2rem 0",
        }}
      >
        <Input
          autoFocus={autoFocus}
          value={name}
          onChange={onNameChange}
          type="search"
          placeholder={`Search`}
          style={{ flexGrow: 1 }}
        />
        {onCancel && (
          <IconBtn
            size="1.4rem"
            onClick={onCancel}
            style={{ marginLeft: "auto" }}
          >
            <Close />
          </IconBtn>
        )}
      </Row>
      {showAddExistingMembersMessage && (
        <Row style={{ padding: "0 1.2rem" }}>
          <p
            style={{
              ...typography.contentAndButtons,
              color: colors.secondaryText,
              margin: 0,
            }}
          >
            Select existing users or groups to share{" "}
            {resourceName ?? "the resource"} with. Invite new users to the
            organisation from the{" "}
            <Link
              style={{ textDecoration: "none" }}
              to={`/organisation/${organisationId}${location.search}`}
            >
              Overview
            </Link>{" "}
            page.
          </p>
        </Row>
      )}
      <ResultContainer>
        <ContentTableColumn
          style={{
            overflowY: "auto",
            padding: "0 0 1.2rem",
          }}
        >
          {filteredGroups.length > 0 &&
            filteredGroups
              .filter((g) => {
                return (
                  showAlreadyExistingItems ||
                  !existingGroups.some((eg) => eg.group_id === g.id)
                );
              })
              .map((g) => {
                const selected = selectedGroups.some((sg) => sg === g.id);
                const alreadyGroupMember = existingGroups.some(
                  (eg) => eg.group_id === g.id,
                );
                return (
                  <ContentTableRow
                    key={g.id}
                    style={{ alignItems: "center" }}
                    disabled={selected || alreadyGroupMember}
                    onClick={() =>
                      !alreadyGroupMember &&
                      !selected &&
                      toggleGroupSelection(g.id)
                    }
                  >
                    <SVGWrapper size={2.2}>
                      <TeamMeeting />
                    </SVGWrapper>
                    <Text style={{ margin: 0, marginRight: "auto" }}>
                      {g.name}
                    </Text>

                    {alreadyGroupMember ? (
                      <SecondaryText>Already a member</SecondaryText>
                    ) : selected ? (
                      <SecondaryText>Selected</SecondaryText>
                    ) : (
                      <></>
                    )}
                    {!alreadyGroupMember && !selected && (
                      <Button
                        buttonType="primary"
                        text="Select"
                        onClick={() => {}}
                        size="small"
                        style={{ padding: "0.2rem 1.2rem" }}
                      />
                    )}
                  </ContentTableRow>
                );
              })}
          {filteredUsers.length > 0 &&
            filteredUsers
              .filter((u) => {
                return (
                  showAlreadyExistingItems ||
                  !existingUsers.some((eu) => eu.user_id === u.user_id)
                );
              })
              .map((u) => {
                const selected = selectedUsers.some((su) => su === u.user_id);
                const alreadyHasAcces = existingUsers.some(
                  (eu) => eu.user_id === u.user_id,
                );
                return (
                  <ContentTableRow
                    key={u.user_id}
                    style={{ alignItems: "center" }}
                    disabled={selected || alreadyHasAcces}
                    onClick={() =>
                      !alreadyHasAcces &&
                      !selected &&
                      toggleUserSelection(u.user_id)
                    }
                  >
                    <UserImageRound user={u} size={2.2} />
                    <Text
                      style={{
                        margin: 0,
                      }}
                    >
                      {u.nickname}
                    </Text>
                    {!alreadyHasAcces && (
                      <SecondaryText style={{ margin: 0, marginRight: "auto" }}>
                        {u.email}
                      </SecondaryText>
                    )}
                    {alreadyHasAcces ? (
                      <SecondaryText>Already a member</SecondaryText>
                    ) : selected ? (
                      <SecondaryText>Selected</SecondaryText>
                    ) : (
                      <></>
                    )}
                    {!alreadyHasAcces && !selected && (
                      <Button
                        buttonType="primary"
                        text="Select"
                        onClick={() => {}}
                        size="small"
                        style={{ padding: "0.2rem 1.2rem" }}
                      />
                    )}
                  </ContentTableRow>
                );
              })}
        </ContentTableColumn>
      </ResultContainer>

      {(selectedUsers.length > 0 || selectedGroups.length > 0) && (
        <>
          <Divider />
          <Row
            style={{
              padding: "1.6rem 1.2rem",
            }}
          >
            <Row
              style={{
                flexWrap: "wrap",
                flex: 1,
                gap: "0.6rem",
              }}
            >
              {selectedUsers.map((su) => {
                const user = usersInOrg.find((u) => u.user_id === su);
                if (!user) return <></>;
                return (
                  <SelectedLabel
                    key={user.user_id}
                    title={user.nickname}
                    onDeselect={() => toggleUserSelection(su)}
                  />
                );
              })}
              {selectedGroups.map((sg) => {
                const group = groupsInOrg.find((g) => g.id === sg);
                if (!group) return <></>;
                return (
                  <SelectedLabel
                    key={group.id}
                    title={group.name}
                    onDeselect={() => toggleGroupSelection(sg)}
                  />
                );
              })}
            </Row>
            {!disableResourceName && (
              <Dropdown
                value={selectedRole}
                onChange={async (e) => {
                  const newRole = _UserAccessRole.parse(e.target.value);
                  setSelectedRole(newRole);
                }}
              >
                <option value={"admin"}>Admin</option>
                <option value={"editor"}>Editor</option>
                <option value={"viewer"}>Viewer</option>
              </Dropdown>
            )}
            <Button
              text="Share"
              onClick={() =>
                onSave(
                  { groups: selectedGroups, users: selectedUsers },
                  selectedRole,
                )
              }
              buttonType="primary"
              disabled={isSaving}
              icon={isSaving ? <Spinner size="1.2rem" /> : undefined}
            />
          </Row>
        </>
      )}
    </SearchAndSelectContainer>
  );
}
