import { LibraryTab } from "../state";

import FoundationIcon from "@icons/24/Foundation.svg?react";
import TurbineIcon from "@icons/24/WindTurbine.svg?react";
import CablingIcon from "@icons/24/Cabling-2.svg?react";
import FinancialIcon from "@icons/24/Cost.svg?react";
import StatisticsIcon from "@icons/24/Statistics.svg?react";
import LayersIcon from "@icons/24/Layers.svg";

export function tabToName(libraryTab: LibraryTab) {
  switch (libraryTab) {
    case "financial":
      return "Financial configurations";
    case "turbine":
      return "Turbines";
    case "foundation":
      return "Foundations";
    case "cable":
      return "Cables";
    case "analysis":
      return "Analysis configurations";
    case "datalayers":
      return "Data packages";
    default:
      return "Library";
  }
}

export function tabToIcon(libraryTab: LibraryTab) {
  switch (libraryTab) {
    case "financial":
      return <FinancialIcon />;
    case "turbine":
      return <TurbineIcon />;
    case "foundation":
      return <FoundationIcon />;
    case "cable":
      return <CablingIcon />;
    case "analysis":
      return <StatisticsIcon />;
    case "datalayers":
      return <LayersIcon />;
    default:
      return <FoundationIcon />;
  }
}
