import { useCallback, useMemo } from "react";
import {
  ABLY_ORGANISATION_DATA_PACKAGE_CREATE,
  ABLY_ORGANISATION_DATA_PACKAGE_UPDATE,
  ABLY_ORGANISATION_DATA_PACKAGE_DELETE,
  ABLY_UPLOAD_CUSTOM_ORGANISATION_LAYER_CHANNEL_NAME,
} from "state/ably";
import { organisationIdSelector } from "state/pathParams";
import {
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";
import {
  _ProjectElementFolder,
  _ProjectElementSortOrder,
} from "components/ProjectElementsV2/service";
import { useDataLibraryLayersCrud } from "./useDataLibraryLayersCrud";
import { useRecoilValue } from "recoil";
import { useAblyGeneric } from "hooks/useAblyGeneric";
import { Types } from "ably";
import { _DataLibraryLayer, _DataLibraryPackage } from "./types";
import { orgDataPackagesManageAccessSelector } from "state/user";
import * as z from "zod";
import { _CustomLayerMetadata } from "services/customLayersAPIService";

const AblySyncDataLibraryInner = ErrorBoundaryWrapper(
  () => {
    const {
      createPackageLocal,
      updatePackageLocal,
      deletePackageLocal,
      refreshLayers,
      updateLayerLocal,
      deleteLayerLocal,
    } = useDataLibraryLayersCrud();
    const organisationId = useRecoilValue(organisationIdSelector);

    const channelName = useMemo(
      () => organisationId && `${organisationId}:all`,
      [organisationId],
    );

    const onDataLayerChangesMessageReceived = useCallback(
      (message: Types.Message) => {
        switch (message.data.meta.type) {
          case "CREATE":
            refreshLayers();
            break;
          case "UPDATE":
            const updatedDataLayer = _DataLibraryLayer
              .partial()
              .parse(message.data.meta.message);
            updateLayerLocal(updatedDataLayer);
            break;
          case "DELETE":
            const deletedLayerIds = z
              .object({ ids: z.string().array() })
              .parse(message.data.meta.message);
            deletedLayerIds.ids.forEach((deletedLayerId: string) => {
              deleteLayerLocal(deletedLayerId);
            });
            break;
          default:
            break;
        }
      },
      [deleteLayerLocal, refreshLayers, updateLayerLocal],
    );

    const onDataPackageCreatedMessageReceived = useCallback(
      (message: Types.Message) => {
        const newDataPackage = _DataLibraryPackage.parse(message.data.meta);
        createPackageLocal(newDataPackage);
      },
      [createPackageLocal],
    );

    const onDataPackageUpdatedMessageReceived = useCallback(
      (message: Types.Message) => {
        const updatedDataPackage = _DataLibraryPackage.parse(message.data.meta);
        updatePackageLocal(updatedDataPackage);
      },
      [updatePackageLocal],
    );

    const onDataPackageDeletedMessageReceived = useCallback(
      (message: Types.Message) => {
        const deletedDataPackage = z.string().parse(message.data.meta);
        deletePackageLocal(deletedDataPackage);
      },
      [deletePackageLocal],
    );

    const events = useMemo(
      () => [
        {
          eventName: ABLY_ORGANISATION_DATA_PACKAGE_CREATE,
          onMessageReceived: onDataPackageCreatedMessageReceived,
        },
        {
          eventName: ABLY_ORGANISATION_DATA_PACKAGE_UPDATE,
          onMessageReceived: onDataPackageUpdatedMessageReceived,
        },
        {
          eventName: ABLY_ORGANISATION_DATA_PACKAGE_DELETE,
          onMessageReceived: onDataPackageDeletedMessageReceived,
        },
        {
          eventName: ABLY_UPLOAD_CUSTOM_ORGANISATION_LAYER_CHANNEL_NAME,
          onMessageReceived: onDataLayerChangesMessageReceived,
        },
      ],
      [
        onDataPackageCreatedMessageReceived,
        onDataPackageUpdatedMessageReceived,
        onDataPackageDeletedMessageReceived,
        onDataLayerChangesMessageReceived,
      ],
    );

    useAblyGeneric(channelName, events);

    return null;
  },
  FatalErrorBoundaryWrapper,
  ScreamOnError,
);

const AblySyncDataLibrary = () => {
  const hasOrgDataLayersAccess = useRecoilValue(
    orgDataPackagesManageAccessSelector,
  );

  if (!hasOrgDataLayersAccess) {
    return null;
  }

  return <AblySyncDataLibraryInner />;
};

export default AblySyncDataLibrary;
