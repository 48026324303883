import React from "react";
import { v4 as uuidv4 } from "uuid";
import { Input, InputDimensioned } from "components/General/Input";
import Button from "components/General/Button";
import { Configuration, EnergyLoss } from "services/configurationService";
import { TextIcon } from "styles/typography";
import { spaceTiny } from "styles/space";
import AddIcon from "@icons/24/Add.svg";
import Trashcan from "@icons/24/Bin.svg";
import { ElementsContainer, Row, SubHeader } from "../shared";

const MAX_LOSS = 0.2;

const fractionToPercentage = (fraction: number) => fraction * 100;
const percentageToFraction = (percentage: number) => percentage / 100;

const CustomLossesTab = ({
  isReadOnly,
  localConfig,
  setLocalConfig,
}: {
  isReadOnly?: boolean;
  localConfig: Configuration;
  setLocalConfig: React.Dispatch<React.SetStateAction<Configuration>>;
}) => {
  function updateName(name: string, index: number) {
    setLocalConfig((curr) => {
      const newLosses = curr.energyLosses.map((loss, i) =>
        i === index ? { ...loss, name } : loss,
      );
      return { ...curr, energyLosses: newLosses };
    });
  }

  function updateValue(value: number, index: number) {
    setLocalConfig((curr) => {
      const newLosses = curr.energyLosses.map((loss, i) =>
        i === index ? { ...loss, value } : loss,
      );
      return { ...curr, energyLosses: newLosses };
    });
  }

  function removeLoss(index: number) {
    setLocalConfig((curr) => {
      const newLosses = [
        ...curr.energyLosses.slice(0, index),
        ...curr.energyLosses.slice(index + 1),
      ];

      return { ...curr, energyLosses: newLosses };
    });
  }

  return (
    <ElementsContainer>
      <SubHeader>Other losses</SubHeader>
      {localConfig.energyLosses.map((loss, i) => (
        <Row key={loss.id}>
          <Input
            disabled={isReadOnly}
            type="text"
            value={loss.name}
            onChange={(e) => updateName(e.target.value, i)}
            style={{ width: "16rem" }}
          />

          <InputDimensioned
            disabled={isReadOnly}
            value={fractionToPercentage(loss.value)}
            validate={(n) => 0 <= n && n <= 100 * MAX_LOSS}
            validationMessage={`Needs to be within 0 - ${100 * MAX_LOSS}%`}
            step={0.5}
            unit="%"
            type="number"
            onChange={(value) => {
              updateValue(percentageToFraction(value), i);
            }}
            style={{ width: "7rem" }}
          />
          {!isReadOnly && (
            <TextIcon
              onClick={() => removeLoss(i)}
              scale={1.2}
              style={{ padding: spaceTiny, alignSelf: "center" }}
            >
              <Trashcan />
            </TextIcon>
          )}
        </Row>
      ))}
      <Button
        disabled={isReadOnly}
        buttonType="text"
        text="Add loss"
        icon={<AddIcon />}
        style={{ marginLeft: "15rem" }}
        onClick={() => {
          const newLoss: EnergyLoss = {
            id: uuidv4(),
            name: "Unnamed loss",
            value: 0,
          };
          setLocalConfig((curr) => ({
            ...curr,
            energyLosses: [...curr.energyLosses, newLoss],
          }));
        }}
      />
    </ElementsContainer>
  );
};

export default CustomLossesTab;
