import * as turf from "@turf/turf";
import SphericalMercator from "@mapbox/sphericalmercator";
import { booleanPointInPolygon, Feature, Polygon } from "@turf/turf";
import { atom } from "recoil";
import { ParkFeature } from "../../types/feature";
import { v4 as uuid4 } from "uuid";
import { LCOEParams } from "./state";
import { ProjectFeature } from "../../types/feature";
import { colors } from "styles/colors";

export const SiteLocatorResultsMode = "site-locator-results-mode";

export const siteLocatorLoadingTimeAtom = atom<undefined | number>({
  key: "siteLocatorLoadingTimeAtom",
  default: undefined,
});

export type Tile = { x: number; y: number; imageData: ImageData };
export type Args = [
  ProjectFeature<Polygon>,
  Feature[],
  Tile[],
  Tile[],
  Tile[],
  Tile[],
  ImageData,
  number,
  number,
  number,
  number,
  LCOEParams,
  SphericalMercator,
];
export type Bbox = { xmin: number; xmax: number; ymin: number; ymax: number };

export type OptimizeArgs = [
  Feature<turf.Polygon | turf.MultiPolygon>[],
  number,
  number,
  number,
];

export const parkFeature = (geometry: Polygon): ParkFeature => {
  const newId = uuid4();
  return {
    type: "Feature",
    id: newId,
    geometry: geometry,
    properties: {
      color: colors.park2,
      id: newId,
      name: "Park candidate",
      type: "park-polygon",
    },
  };
};

// Other possibilities:
// 2) distances from centroid
// 3) size of smallest bounding box (should rotate)
export function calculateComplexityScore(polygons: any[]) {
  // the longer the perimeter the lower the score (and higher the complexity)
  const multiPolygon = turf.multiPolygon(
    polygons.map((p) => p.geometry.coordinates),
  );

  const perimeterEnvelope = turf.length(turf.envelope(multiPolygon), {
    units: "kilometers",
  });
  const area = turf.area(multiPolygon);

  const isoperimetricQuotient =
    (4 * Math.PI * area) / Math.pow(perimeterEnvelope, 2);

  return isoperimetricQuotient;
}

/// Count number of adjacent polygons
function countPolygonsSharingEdges(
  targetPolygon: turf.Polygon,
  polygonCollection: turf.Polygon[],
) {
  let count = 0;

  for (const otherPolygon of polygonCollection) {
    if (targetPolygon !== otherPolygon) {
      const overlap = turf.booleanOverlap(targetPolygon, otherPolygon);

      if (overlap) {
        count++;
      }
    }
  }

  return count;
}

/// Compute average adjacent features
export function avgAdjacentCells(features: turf.Polygon[]) {
  let sum = 0;
  for (const feat of features) {
    sum += countPolygonsSharingEdges(feat, features);
  }

  return sum / features.length;
}

export const getBbox = (geometry: Polygon): Bbox =>
  geometry.coordinates[0].reduce(
    ({ xmin, xmax, ymin, ymax }, [x, y]) => ({
      xmin: Math.min(xmin, x),
      xmax: Math.max(xmax, x),
      ymin: Math.min(ymin, y),
      ymax: Math.max(ymax, y),
    }),
    { xmin: Infinity, xmax: -Infinity, ymin: Infinity, ymax: -Infinity },
  );

export const computeScoresFromPolygon = (
  polygon: Polygon,
  offsetX: number,
  offsetY: number,
  zoom: number,
  merc: SphericalMercator,
  depthBitmap: number[][],
  shoreDistanceBitmap: number[][],
  meanSpeedBitmap: number[][],
  capacityBitmap: number[][],
  LCOEBitmap: number[][],
  LCOEBitmapSmoothed: number[][],
  naturaBitmap: number[][],
  parkCapacity: number,
) => {
  // Limit the loop below to the bounding box around the actual polygon for a nice speedup
  const { xmin, xmax, ymin, ymax } = getBbox(polygon);
  const [bboxGlobalMinX, bboxGlobalMinY] = merc.px([xmin, ymin], zoom);
  const [bboxGlobalMaxX, bboxGlobalMaxY] = merc.px([xmax, ymax], zoom);
  const bboxLocalMinX = bboxGlobalMinX - offsetX;
  const bboxLocalMaxX = bboxGlobalMaxX - offsetX;
  const bboxLocalMaxY = bboxGlobalMaxY - offsetY;
  const bboxLocalMinY = bboxGlobalMinY - offsetY;

  let candidatePixelsOn = 0;
  let sumDepthScore = 0;
  let sumShoreDistanceScore = 0;
  let sumMeanSpeedScore = 0;
  let sumCapacityScore = 0;
  let sumLCOEScore = 0;
  let sumLCOESmoothedScore = 0;
  let naturaScore = 0;

  let meanSpeedDefined = true; // if meanSpeed isn't defined in one pixel then disable it for all pixels in one polygon

  // Set points inside polygon to 1
  for (let y = bboxLocalMaxY; y <= bboxLocalMinY; y++) {
    for (let x = bboxLocalMinX; x <= bboxLocalMaxX; x++) {
      const global_px_x = x + offsetX;
      const global_px_y = y + offsetY;
      const point: [number, number] = merc.ll([global_px_x, global_px_y], zoom);
      if (booleanPointInPolygon(point, polygon)) {
        candidatePixelsOn++;
        const depthPixel = depthBitmap[y][x]; // meters
        const shoreDistancePixel = shoreDistanceBitmap[y][x]; // kilometers
        const meanSpeedPixel = (meanSpeedBitmap[y][x] / 255) * 10 + 3; // m/s
        const capacityPixel =
          ((capacityBitmap[y][x] / 255) * 0.8 * 8766 * parkCapacity) / 1000; // GWh

        if (meanSpeedPixel === 3) meanSpeedDefined = false;

        sumDepthScore += depthPixel;
        sumShoreDistanceScore += shoreDistancePixel;
        sumMeanSpeedScore += meanSpeedPixel;
        sumCapacityScore += capacityPixel;
        sumLCOEScore += LCOEBitmap[y][x];
        sumLCOESmoothedScore += LCOEBitmapSmoothed[y][x];
        naturaScore = Math.max(naturaScore, naturaBitmap[y][x] !== 255 ? 1 : 0);
      }
    }
  }

  if (!meanSpeedDefined) {
    sumMeanSpeedScore = 0;
    sumCapacityScore = 0;
    sumLCOEScore = Infinity;
    sumLCOESmoothedScore = 0;
  }

  if (sumShoreDistanceScore === 0) {
    sumLCOEScore = Infinity;
    sumLCOESmoothedScore = 0;
  }

  return {
    sumDepthScore,
    sumShoreDistanceScore,
    sumMeanSpeedScore,
    sumCapacityScore,
    sumLCOEScore,
    sumLCOESmoothedScore,
    naturaScore,
    candidatePixelsOn,
  };
};

export const hexagonSideLength = (area: number): number =>
  Math.sqrt(area / ((3 * Math.sqrt(3)) / 2));

// blurring kernel
export function applyKernel(bitmap: number[][], kernelSize: number) {
  const rows = bitmap.length;
  const cols = bitmap[0].length;

  const newBitmap = new Array(rows).fill(0).map(() => new Array(cols).fill(0));
  const radius = Math.floor(kernelSize / 2);

  for (let i = 0; i < rows; i++) {
    for (let j = 0; j < cols; j++) {
      let sum = 0;
      let count = 0;

      for (let ki = -radius; ki <= radius; ki++) {
        for (let kj = -radius; kj <= radius; kj++) {
          const ni = i + ki;
          const nj = j + kj;

          if (ni >= 0 && ni < rows && nj >= 0 && nj < cols) {
            sum += bitmap[ni][nj];
            count++;
          }
        }
      }

      newBitmap[i][j] = sum / count;
    }
  }

  return newBitmap;
}
