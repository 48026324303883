import placePoint from "./placePoint.mp4";
import CloseableHint from "./CloseableHint";

export const drawPointHelpHintType = "drawPointHelpHintType";

const DrawPointHelp = ({
  closeCallback,
  gotItCallback,
}: {
  closeCallback: () => void;
  gotItCallback?: () => void;
}) => {
  return (
    <CloseableHint
      title={"Draw polygon"}
      videosrc={placePoint}
      closeCallback={closeCallback}
      gotItCallback={gotItCallback}
      timeToDissapearMS={10000}
    >
      {`Place a point with the left mouse button or press "Escape" to cancel`}
    </CloseableHint>
  );
};

export default DrawPointHelp;
