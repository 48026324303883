import { OrganisationAccessRole } from "../../../types/user";
import { useRecoilCallback } from "recoil";
import { leaveOrg, removeUserFromOrg, updateOrgRole } from "../service";
import { usersInOrganisationState } from "../state";
import { initializeAndSet } from "components/Comments/hooks/useReplyReactionCrud";

export const useOrgMemberCrud = (organisationId: string | undefined) => {
  const update = useRecoilCallback(
    ({ set, snapshot }) =>
      async (userId: string, role: OrganisationAccessRole) => {
        if (!organisationId) return;
        const preUpdate = await snapshot.getPromise(
          usersInOrganisationState(organisationId),
        );
        set(usersInOrganisationState(organisationId), (cur) =>
          cur.map((user) =>
            user.user_id === userId
              ? { ...user, org_role: role, org_access: role }
              : user,
          ),
        );
        return updateOrgRole(organisationId, userId, role)
          .then()
          .catch((e) => {
            set(usersInOrganisationState(organisationId), preUpdate);
            throw e;
          })
          .finally(() => {
            console.log(`done update`);
          });
      },
    [organisationId],
  );
  const remove = useRecoilCallback(
    ({ set, snapshot }) =>
      async (userId: string) => {
        if (!organisationId) return;
        const preUpdate = await snapshot.getPromise(
          usersInOrganisationState(organisationId),
        );
        set(usersInOrganisationState(organisationId), (cur) =>
          cur.filter((user) => user.user_id !== userId),
        );
        return removeUserFromOrg(organisationId, userId)
          .then()
          .catch((e) => {
            set(usersInOrganisationState(organisationId), preUpdate);
            throw e;
          });
      },
    [organisationId],
  );
  const leaveOrganisation = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        if (!organisationId) return;
        initializeAndSet(
          snapshot,
          set,
          usersInOrganisationState(organisationId),
          (cur) => cur.filter((user) => user.user_id !== user.user_id),
        );
        await leaveOrg(organisationId);
        window.location.href = window.location.origin;
      },
    [organisationId],
  );
  return { update, remove, leaveOrganisation };
};
