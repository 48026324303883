/// <reference types="vite-plugin-svgr/client" />
import { Suspense, useEffect, useMemo, useState } from "react";
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";
import { Link, useLocation } from "react-router-dom";
import { useDrag, useDrop } from "react-dnd";
import useTextInput from "../../hooks/useTextInput";
import BinIcon from "@icons/24/Bin.svg?react";
import FolderIcon from "@icons/40/FolderBig.svg?react";
import PersonalFolderIcon from "@icons/40/FolderPersonalBig.svg";
import BranchIcon from "@icons/24/Branch.svg";
import InfoIcon from "@icons/24/Information.svg?react";
import MoveBackIcon from "@icons/24/MoveBack.svg?react";
import PointIcon from "@icons/24/Point.svg?react";
import PeopleIcon from "@icons/24/TeamMeeting.svg?react";
import PencilIcon from "@icons/24/Pencil.svg?react";
import SinglePerson from "@icons/24/SinglePerson.svg?react";
import Star from "@icons/24/Star.svg?react";
import AddIcon from "@icons/24/Add.svg";
import { nodeIdSelector, useTypedPath } from "../../state/pathParams";
import { projectBranchCountAtom } from "../../state/project";
import { followProjectsAtom } from "../../state/project";
import {
  memberInOrganisationSelectorFamily,
  userAllNodesAccessAtom,
  userHaveEditorNodeAccess,
  userNodeAccessSelectorFamily,
} from "../../state/user";
import { colors } from "../../styles/colors";
import {
  SkeletonBlock,
  SkeletonRound,
  SkeletonText,
} from "../Loading/Skeleton";
import { spaceSmall } from "../../styles/space";
import { capitalize } from "../../utils/utils";
import { EditableTextInternalState } from "../General/EditableText";
import { Column, Row } from "../General/Layout";
import { MenuDivider, MenuItem } from "../General/Menu";
import { DotMenu } from "../General/MenuButton";
import StaticMapboxProjectImage, {
  TutorialProjectImage,
} from "../StaticMapboxImage/StaticMapboxProjectImage";
import { MenuItemNestedNodes, ProjectDotMenu } from "./ProjectDotMenu";
import {
  AddIconWrapper,
  BlueCircleIcon,
  DotMenuWrapper,
  FolderCardGrid,
  FolderNameWrapper,
  FolderWrapper,
  NewProjectPlaceholder,
  ProjectGridWrapper,
  ProjectIconWrapper,
  ProjectNameWrapper,
  ProjectPictureWrapper,
  ProjectStatusWrapper,
  ProjectWrapper,
  SecondaryText,
  StarIconWrapper,
} from "./ProjectGrid.style";
import { VerticalDivider } from "./styles";
import { useFollowProjectCrud } from "./useFollowProjectCrud";
import {
  OrganisationNodeCrud,
  findTopLevelNode,
  getNodesWithMissingParents,
  nodesInOrganisationSelectorFamily,
  storedNodesForOrganisation,
  topLevelFolderIdFromOrgIdAndProjectIdSelectorFamily,
  useOrganisationNodeCrud,
} from "./useOrganisationFolderCrud";
import { Node, ProjectNodeInformation } from "../../services/customerAPI";
import useFolderId from "hooks/useFolderId";
import { allNodeUsersSelectorFamily, makeNodeCompare } from "./state";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { ProjectPresenceWrapper } from "components/AblyComponents";
import { Icon } from "components/General/Icons";
import DontRenderWhenCheckly from "components/DontRenderWhenCheckly/DontRenderWhenCheckly";
import { SANDBOX_TUTORIAL_ID } from "components/OnboardingTours/Tour";
import LearnIcon from "@icons/24/Learn.svg?react";

export const ProjectGrid = ({
  createAndEnterProject,
  canCreateProjectHere,
}: {
  createAndEnterProject: any;
  canCreateProjectHere: boolean;
}) => {
  const folderId = useFolderId();
  const { organisationId } = useTypedPath("organisationId");

  const nodes = useRecoilValue(
    nodesInOrganisationSelectorFamily({ organisationId }),
  );

  const missingParentsNodes = useRecoilValue(
    getNodesWithMissingParents({ organisationId }),
  );

  const childrenNodes = useMemo(
    () =>
      folderId === organisationId
        ? missingParentsNodes
        : nodes.filter((n) => n.parent_id === folderId),
    [folderId, organisationId, missingParentsNodes, nodes],
  );

  const followsLoadable = useRecoilValueLoadable(followProjectsAtom);

  const sortedChildren = useMemo(() => {
    const fis = [...childrenNodes];
    const followIds = new Set(
      (followsLoadable.valueMaybe() ?? [])
        .filter((f) => f.follow)
        .map((f) => f.nodeId)
        .map((nodeId) => nodes.find((n) => n.id === nodeId)?.id)
        .filter((projectId) => projectId) as string[],
    );
    const cmp = makeNodeCompare(followIds);
    fis.sort(cmp);
    return fis;
  }, [childrenNodes, followsLoadable, nodes]);

  const loadeNodes = useRecoilValue(
    storedNodesForOrganisation({
      organisationId,
    }),
  );

  const organisationCrud = useOrganisationNodeCrud();
  const isOrganisationLevel = organisationId === folderId;
  const somethingLoads = followsLoadable.state === "loading";

  if (somethingLoads) {
    return (
      <>
        <ProjectGridWrapper>
          <ProjectPlaceholder />
          <ProjectPlaceholder />
          <ProjectPlaceholder />
        </ProjectGridWrapper>
      </>
    );
  }

  return (
    <div>
      <ProjectGridWrapper>
        {sortedChildren.map((node) => {
          if (["personal_folder", "folder"].includes(node.type)) {
            return (
              <Suspense key={node.id} fallback={<ProjectPlaceholder />}>
                <FolderCard
                  node={node}
                  nodes={nodes}
                  organisationId={organisationId}
                />
              </Suspense>
            );
          }
          return null;
        })}
      </ProjectGridWrapper>
      <ProjectGridWrapper>
        {loadeNodes.some((n) => n.getLoading()) && <ProjectPlaceholder />}
        {sortedChildren.map((node) => {
          if (node.type === "project") {
            return (
              <Suspense key={node.id} fallback={<ProjectPlaceholder />}>
                <ProjectCard
                  project={node as ProjectNodeInformation}
                  organisationId={organisationId}
                  crudNode={organisationCrud}
                />
              </Suspense>
            );
          }
          return null;
        })}
        {!isOrganisationLevel && canCreateProjectHere && (
          <NewProjectCard createAndEnterProject={createAndEnterProject} />
        )}
      </ProjectGridWrapper>
    </div>
  );
};

const ProjectCard = ({
  project,
  organisationId,
  crudNode,
}: {
  project: ProjectNodeInformation;
  organisationId: string;
  crudNode: OrganisationNodeCrud;
}) => {
  const location = useLocation();

  const isMemberInOrg = useRecoilValue(
    memberInOrganisationSelectorFamily({ organisationId }),
  );
  const { put: putFollow } = useFollowProjectCrud();
  const follows = useRecoilValue(followProjectsAtom);

  const nodes = useRecoilValue(
    nodesInOrganisationSelectorFamily({ organisationId }),
  );
  const setContent = useSetRecoilState(
    organisationRightSideModal(organisationId),
  );

  const isFollowed = useMemo(
    () => follows.some((f) => f.nodeId === project?.id && f.follow),
    [follows, project],
  );

  const [writtenTitle, onWrittenTitleChange, setWrittenTitle] = useTextInput(
    project.name ?? "",
  );

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setWrittenTitle(project.name);
  }, [project.name, setWrittenTitle]);

  const { update: updateNode } = useOrganisationNodeCrud();

  const currentFolder = useMemo(
    () => nodes.find((f) => f.id === project.parent_id),
    [project.parent_id, nodes],
  );

  const [, ref] = useDrag({
    type: "PROJECT",
    item: { id: project.id, currentFolder },
  });
  const [isBigHovered, setIsBigHovered] = useState(false);

  return (
    <Link
      key={project.id}
      style={{ textDecoration: "none" }}
      to={`/design/project/${organisationId}/${project.id}${location.search}`}
      ref={ref}
    >
      <ProjectWrapper
        onMouseEnter={() => setIsBigHovered(true)}
        onMouseLeave={() => setIsBigHovered(false)}
        isHovered={isBigHovered}
      >
        <ProjectPictureWrapper>
          {project.tutorial_id === SANDBOX_TUTORIAL_ID ? (
            <>
              <TutorialProjectImage>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BlueCircleIcon>
                    <Icon size="3rem" fill="white">
                      <LearnIcon />
                    </Icon>
                  </BlueCircleIcon>
                </div>
              </TutorialProjectImage>
            </>
          ) : (
            <DontRenderWhenCheckly>
              <StaticMapboxProjectImage project={project} />
            </DontRenderWhenCheckly>
          )}

          {project.status && (
            <ProjectStatusWrapper active={project.status === "active"}>
              {capitalize(project.status)}
            </ProjectStatusWrapper>
          )}

          <div
            style={{ position: "absolute", bottom: "0.8rem", left: "0.8rem" }}
          >
            <ProjectPresenceWrapper
              organisationId={organisationId}
              nodeId={project.id}
              size={2.2}
            />
          </div>
          <StarIconWrapper
            active={isFollowed}
            onClick={(e) => {
              e.preventDefault();
              putFollow(project.id, !isFollowed);
            }}
          >
            <Star />
          </StarIconWrapper>
        </ProjectPictureWrapper>

        <Column style={{ gap: 0 }}>
          <Row
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <EditableTextInternalState
              type="text"
              value={writtenTitle}
              disabled={true} // Only editable from dot menu
              onChange={onWrittenTitleChange}
              onEnter={async (newTitle) => {
                await updateNode(project.id, { name: newTitle });
              }}
              onCancel={async (newTitle) => {
                await updateNode(project.id, { name: newTitle });
              }}
              isEditing={isEditing}
              onAfter={() => {
                setIsEditing(false);
              }}
              renderText={(title) => (
                <ProjectNameWrapper>{title}</ProjectNameWrapper>
              )}
            />
            <Row style={{ gap: "0.4rem", padding: "0.4rem" }}>
              {isMemberInOrg && (
                <ProjectIconWrapper
                  isProjectHovered={isBigHovered}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setContent({ type: "project", id: project.id });
                  }}
                >
                  <InfoIcon />
                </ProjectIconWrapper>
              )}
              <ProjectIconWrapper isProjectHovered={isBigHovered}>
                <ProjectDotMenu
                  setIsEditingTitle={setIsEditing}
                  project={project}
                  nodes={nodes}
                  currentFolder={currentFolder}
                  crudNode={crudNode}
                />
              </ProjectIconWrapper>
            </Row>
          </Row>
          <Row
            style={{
              minHeight: "3rem",
              alignItems: "center",
              padding: `0 ${spaceSmall}`,
              marginTop: "-1'rem",
              gap: "0.6rem",
            }}
          >
            {project.id && <NumberOfBranches nodeId={project.id} />}
            <VerticalDivider />
            <NumberOfCollaborators
              organisationId={organisationId}
              nodeId={project.id}
            />
            {project.location && (
              <>
                <VerticalDivider />
                <Row style={{ alignItems: "center", gap: "0.4rem" }}>
                  <Icon size={"1.4rem"} stroke={colors.secondaryText}>
                    <PointIcon />
                  </Icon>
                  <SecondaryText>{project.location}</SecondaryText>
                </Row>
              </>
            )}
          </Row>
        </Column>
      </ProjectWrapper>
    </Link>
  );
};

const NewProjectCard = ({
  createAndEnterProject,
}: {
  createAndEnterProject?: () => void;
}) => {
  return (
    <NewProjectPlaceholder onClick={createAndEnterProject}>
      <AddIconWrapper>
        <Icon size={"16px"} stroke={colors.iconSelected}>
          <AddIcon />
        </Icon>
      </AddIconWrapper>
    </NewProjectPlaceholder>
  );
};

const NumberOfBranches = ({ nodeId }: { nodeId: string }) => {
  const numberLoadable = useRecoilValueLoadable(
    projectBranchCountAtom({ nodeId }),
  );

  if (numberLoadable.state !== "hasValue")
    return (
      <Row style={{ alignItems: "center", gap: "0.4rem" }}>
        <Icon size={"1.4rem"} fill={colors.textSecondary}>
          <BranchIcon />
        </Icon>
        <SkeletonRound size={14} />
      </Row>
    );

  return (
    <Row style={{ alignItems: "center", gap: "0.4rem" }}>
      <Icon size={"1.4rem"} fill={colors.textSecondary}>
        <BranchIcon />
      </Icon>
      <SecondaryText style={{ justifyContent: "flex-end" }}>
        {numberLoadable.contents}
      </SecondaryText>
    </Row>
  );
};

const NumberOfCollaborators = ({
  nodeId,
  organisationId,
}: {
  nodeId: string;
  organisationId: string;
}) => {
  const projectMembersLoadable = useRecoilValueLoadable(
    allNodeUsersSelectorFamily({ organisationId, nodeId }),
  );

  const setContent = useSetRecoilState(
    organisationRightSideModal(organisationId),
  );
  const isMemberInOrg = useRecoilValue(
    memberInOrganisationSelectorFamily({ organisationId }),
  );

  if (projectMembersLoadable.state !== "hasValue")
    return (
      <Row style={{ alignItems: "center", gap: "0.4rem" }}>
        <Icon size={"1.4rem"} stroke={colors.iconSubtle}>
          <SinglePerson />
        </Icon>
        <SkeletonRound size={14} />
      </Row>
    );

  return (
    <Row
      onClick={
        isMemberInOrg
          ? (e) => {
              e.preventDefault();
              e.stopPropagation();
              setContent({ type: "project", id: nodeId });
            }
          : undefined
      }
      style={{ alignItems: "center", gap: "0.4rem" }}
    >
      <Icon size={"1.4rem"} stroke={colors.iconSubtle}>
        <SinglePerson />
      </Icon>
      <SecondaryText style={{ justifyContent: "flex-end" }}>
        {projectMembersLoadable.contents.length}
      </SecondaryText>
    </Row>
  );
};

const FolderCard = ({
  node,
  nodes,
  organisationId,
}: {
  node: Node;
  nodes: Node[];
  organisationId: string;
}) => {
  const nodeId = useRecoilValue(nodeIdSelector);
  const location = useLocation();
  const topFolderId = useRecoilValue(
    topLevelFolderIdFromOrgIdAndProjectIdSelectorFamily({
      organisationId,
      projectId: node.id,
    }),
  );

  const folderChildren = useMemo(
    () => nodes.filter((n) => n.parent_id === node.id),
    [node, nodes],
  );

  const {
    update: updateNode,
    move: moveNode,
    remove: removeNode,
  } = useOrganisationNodeCrud();

  const [{ isOver }, dropRef] = useDrop({
    accept: ["PROJECT", "FOLDER"],
    drop: (item: { id: string; currentNode?: Node }) => {
      if (item.currentNode?.id === node.id) return;
      if (item.id === node.id) return;
      moveNode(item.id, node.id);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const [, dragRef] = useDrag({
    type: "FOLDER",
    item: { id: node.id, currentNode: node },
  });

  // Combine the drag and drop refs
  const combinedRef = (node: HTMLElement | null) => {
    dragRef(node);
    dropRef(node);
  };

  const userAllNodesAccess = useRecoilValue(userAllNodesAccessAtom);
  const isCustomerEditor = useMemo(
    () => userHaveEditorNodeAccess(userAllNodesAccess, node.id),
    [userAllNodesAccess, node.id],
  );
  const nodeAccess = useRecoilValue(
    userNodeAccessSelectorFamily({ nodeId: node.id }),
  );
  const isNodeAdmin = nodeAccess >= 2;

  const [writtenTitle, onWrittenTitleChange, setWrittenTitle] = useTextInput(
    node.name,
  );

  const [isEditing, setIsEditing] = useState(false);

  const chosenFolderHasPersonalTopFolder = node
    ? findTopLevelNode(nodes, node.id, organisationId ?? "")?.type ===
      "personal_folder"
    : false;

  const isPersonalFolder = node.type === "personal_folder";

  useEffect(() => {
    setWrittenTitle(node.name);
  }, [node.name, setWrittenTitle]);

  const folderTree = useMemo(() => {
    const tree = new Map<string, Node[]>();
    const setMapForParentId = (parentId: string, nodes: Node[]) => {
      const children = nodes.filter(
        (n) => n.parent_id === parentId && n.type === "folder",
      );
      if (children.length === 0) return;
      tree.set(parentId, children);
      children.forEach((n) => setMapForParentId(n.id, nodes));
    };
    setMapForParentId(topFolderId ?? "", nodes);
    return tree;
  }, [topFolderId, nodes]);

  const setContent = useSetRecoilState(
    organisationRightSideModal(organisationId),
  );
  const isMemberInOrg = useRecoilValue(
    memberInOrganisationSelectorFamily({ organisationId }),
  );
  const [isFolderHovered, setIsFolderHovered] = useState(false);

  return (
    <Link
      onClick={() => setContent({ type: "project", id: node.id })}
      to={`/organisation/${organisationId}/${node.id}${location.search}`}
      style={{ textDecoration: "none" }}
      ref={combinedRef}
    >
      <FolderWrapper
        onMouseEnter={() => setIsFolderHovered(true)}
        onMouseLeave={() => setIsFolderHovered(false)}
        onClick={() => {}}
        isHovered={isOver}
      >
        {(isMemberInOrg || isCustomerEditor) && (
          <DotMenuWrapper isFolderHovered={isFolderHovered}>
            <DotMenu size="medium" side="left" direction="down">
              {isCustomerEditor && (
                <>
                  {isMemberInOrg && (
                    <MenuItem
                      name="Folder information"
                      icon={<InfoIcon />}
                      onClick={() => {
                        setContent({ type: "project", id: node.id });
                      }}
                    />
                  )}
                  {!!nodeId && (
                    <MenuItem name="Move" icon={<FolderIcon />}>
                      <MenuItemNestedNodes
                        base={topFolderId}
                        currentFolderId={topFolderId}
                        folderTree={folderTree}
                        clickOnFolder={async (f: Node) =>
                          moveNode(node.id, f.id)
                        }
                      />
                      <MenuDivider />
                      {topFolderId && (
                        <MenuItem
                          icon={<MoveBackIcon />}
                          name={"Move to Projects"}
                          onClick={async () => {
                            moveNode(node.id, topFolderId);
                          }}
                        />
                      )}
                    </MenuItem>
                  )}
                </>
              )}
              {isCustomerEditor && !isPersonalFolder && (
                <>
                  <MenuItem
                    name="Rename"
                    icon={<PencilIcon />}
                    onClick={() => {
                      setIsEditing(true);
                    }}
                  />
                </>
              )}
              {isNodeAdmin && !chosenFolderHasPersonalTopFolder && (
                <>
                  <MenuItem
                    name="Share folder"
                    icon={<PeopleIcon />}
                    onClick={() => {
                      setContent({
                        type: "project",
                        id: node.id,
                        meta: {
                          openAddCollaborators: true,
                        },
                      });
                    }}
                  />
                </>
              )}
              <MenuItem
                name="Delete"
                disabled={folderChildren.length > 0}
                title={
                  folderChildren.length > 0
                    ? "Folder must be empty to delete"
                    : undefined
                }
                icon={<BinIcon />}
                onClick={() => {
                  removeNode(node.id);
                }}
              />
            </DotMenu>
          </DotMenuWrapper>
        )}
        <FolderCardGrid>
          {isPersonalFolder ? <PersonalFolderIcon /> : <FolderIcon />}
          <Column
            style={{
              display: "flex",
              maxWidth: "100%",
              gap: 0,
              padding: "0 0 0 1.6rem",
            }}
          >
            <EditableTextInternalState
              style={{ flex: 1 }}
              smallInput
              type="text"
              value={writtenTitle}
              disabled={true} // Only editable from dot menu
              onChange={onWrittenTitleChange}
              onEnter={async (newTitle) => {
                await updateNode(node.id, { name: newTitle });
              }}
              onCancel={async (newTitle) => {
                await updateNode(node.id, { name: newTitle });
              }}
              onAfter={() => {
                setIsEditing(false);
              }}
              isEditing={isEditing}
              renderText={(title) => (
                <FolderNameWrapper>{title}</FolderNameWrapper>
              )}
            />

            <SecondaryText>
              {folderChildren.length}{" "}
              {folderChildren.length === 1 ? "item" : "items"}
            </SecondaryText>
          </Column>
        </FolderCardGrid>
      </FolderWrapper>
    </Link>
  );
};

function ProjectPlaceholder({ text }: { text?: string }) {
  // NOTE: the project card is 21.2 rem
  // 16 + 0.9 + 2.2 + 0.9 + 1.2  == 21.2
  return (
    <ProjectWrapper nohover style={{ gap: "0.9rem" }}>
      <SkeletonBlock style={{ width: "30rem", height: "16rem" }} />
      {text ? (
        <SkeletonText
          text={text}
          style={{ width: "30rem", height: "2.2rem" }}
        />
      ) : (
        <SkeletonBlock style={{ width: "30rem", height: "2.0rem" }} />
      )}
      <SkeletonBlock style={{ width: "30rem", height: "1.4rem" }} />
    </ProjectWrapper>
  );
}
