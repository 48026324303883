/// <reference types="vite-plugin-svgr/client" />
import React, { HTMLAttributes, ReactNode } from "react";
import styled from "styled-components";
import Settings from "@icons/24/Settings.svg?react";
import { colors } from "../../styles/colors";
import { typography } from "../../styles/typography";
import Tooltip from "./Tooltip";
import { SkeletonBlock } from "../Loading/Skeleton";
import {
  borderRadius,
  borderRadiusLarge,
  spaceDecent,
  spaceSmall,
  spacing1,
  spacing3,
  spacing4,
  spacing6,
  spacing7,
} from "../../styles/space";
import { IconBtn } from "./Icons";
import { ReferenceType } from "@floating-ui/react";
import { isDefined } from "utils/predicates";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: React.ReactNode;
  icon?: ReactNode;
  iconText?: ReactNode;
  buttonType?: "primary" | "primary-dark" | "secondary" | "text" | "dropdown";
  size?: "small" | "medium";
  iconPosition?: "left" | "right";
  disabled?: boolean;
  tooltip?: string;
  style?: React.CSSProperties;
  skeleton?: boolean;
  maxWidth?: number;
  innerRef?:
    | (((node: ReferenceType | null) => void) &
        ((node: ReferenceType | null) => void))
    | React.RefObject<HTMLButtonElement>;
}

export const StyledButton = styled.button<{
  iconPosition: ButtonProps["iconPosition"];
}>`
  transition:
    all 200ms ease,
    visibility 0s ease;
  display: flex;
  flex-direction: ${({ iconPosition }) =>
    iconPosition === "right" ? "row-reverse" : "row"};

  min-width: fit-content;
  max-width: fit-content;

  align-items: center;
  justify-content: space-around;

  border: none;
  border-radius: ${borderRadius.small};

  height: 3.2rem;
  padding: ${spacing3} ${spacing7};
  gap: 0.6rem;

  text-align: center;

  cursor: pointer;
  :disabled {
    cursor: not-allowed;
  }

  span {
    white-space: nowrap;
  }

  ${typography.body}

  svg {
    stroke: ${colors.iconDefault};
    min-width: 1.2rem;
    width: 1.2rem;
    min-height: 1.2rem;
    height: 1.2rem;
    overflow: visible;
    ${(p) =>
      p.iconPosition === "right"
        ? "margin-right: -0.7rem;"
        : "margin-left: -0.7rem;"}
    // If we only have an icon, make it have zero width;
    :only-child {
      margin: 0 -0.7rem;
    }
  }

  &.small {
    height: 2.4rem;
    padding: ${spacing1} ${spacing6};
  }

  &.primary,
  &.primary-dark {
    color: ${colors.textNegative};
    background: ${colors.surfaceButtonPrimary};
    svg {
      path {
        stroke: ${colors.iconNegative};
      }
    }
    &:hover {
      background: ${colors.blue600};
    }
    &:active {
      background: ${colors.blue700};
    }
  }

  &.secondary {
    color: ${colors.iconBrand};
    background: ${colors.surfaceButtonSecondary};
    svg path {
      stroke: ${colors.iconBrand};
    }
    &:hover {
      background: ${colors.indigo100};
    }
    &:active {
      background: ${colors.indigo200};
    }
  }

  &.text {
    color: ${colors.brand};
    background: unset;
    padding: ${spacing3} 0;
    svg {
      margin: 0;
      path {
        stroke: ${colors.brand};
      }
      // Text button with only an icon should have more widith so that it's not super tiny.
      &:only-child {
        padding: 0 ${spacing4};
      }
    }
    &:hover {
      color: ${colors.blue700};
      svg path {
        stroke: ${colors.blue700};
      }
    }
    &:active {
      color: ${colors.blue800};
      svg path {
        stroke: ${colors.blue800};
      }
    }
  }

  &:not(.primary-dark):disabled {
    color: ${colors.textDisabled} !important;
    :not(.text) {
      background: ${colors.surfaceDisabled} !important;
    }
    svg path {
      stroke: ${colors.textDisabled} !important;
    }
  }

  &.primary-dark:disabled {
    color: ${colors.textDisabled} !important;
    :not(.text) {
      background: ${colors.blue800} !important;
    }
    svg path {
      stroke: ${colors.textDisabled} !important;
    }
  }

  &.dropdown {
    border: 1px solid ${colors.dropdownBorder};
    box-sizing: border-box;
    background-color: ${colors.dropdownBackground};
    color: ${colors.primaryText};

    svg path {
      stroke: ${colors.primaryText};
    }

    :hover {
      border: 1px solid ${colors.dropdownBorderHover};
      svg path {
        stroke: ${colors.primaryText};
      }
    }
  }
`;

export const SettingsButton = (
  props: { size: string } & HTMLAttributes<HTMLButtonElement>,
) => {
  return (
    <IconBtn {...props}>
      <Settings />
    </IconBtn>
  );
};

export default function Button({
  text,
  icon,
  iconText,
  buttonType = "primary",
  tooltip,
  iconPosition,
  size = "medium",
  skeleton,
  maxWidth,
  innerRef,
  className,
  ...props
}: ButtonProps) {
  const btn = (
    <StyledButton
      ref={innerRef}
      className={[buttonType, size, className].filter(isDefined).join(" ")}
      {...props}
      style={{ position: "relative", ...props.style }}
      iconPosition={iconPosition}
    >
      {icon ? icon : undefined}
      {text && (
        <span
          style={
            maxWidth
              ? {
                  maxWidth: `${maxWidth}rem`,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  display: "flex",
                  alignItems: "center",
                }
              : {
                  display: "flex",
                  alignItems: "center",
                }
          }
        >
          {iconText}
          {text}
        </span>
      )}
      {skeleton && (
        <SkeletonBlock
          style={{
            width: "initial",
            height: "initial",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            position: "absolute",
            borderRadius: borderRadiusLarge,
            margin: `${spaceSmall} ${spaceDecent}`,
          }}
        />
      )}
    </StyledButton>
  );

  if (tooltip) return <Tooltip text={tooltip}>{btn}</Tooltip>;
  return btn;
}
