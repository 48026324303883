/// <reference types="vite-plugin-svgr/client" />
import UserInfo from "components/Comments/MapModal/UserInfo";
import Button from "components/General/Button";
import { Column, Row } from "components/General/Layout";
import { groupMembersState } from "components/Organisation/Groups/state";
import useMemberInGroupCrud from "components/Organisation/Groups/useMemberInGroupCrud";
import {
  ContentTableColumn,
  ContentTableRow,
  TabDescription,
} from "components/Organisation/OrganisationRightSide/style";
import { usersInOrganisationState } from "components/Organisation/state";
import { useState, useCallback } from "react";
import { useRecoilValue } from "recoil";
import { useTypedPath } from "state/pathParams";
import { adminInOrganisationSelectorFamily } from "state/user";
import { OrganisationUser } from "types/customer";
import GroupMemberModal from "../../GroupMemberModal";
import Trashcan from "@icons/24/Bin.svg?react";
import AddIcon from "@icons/24/Add.svg?react";
import { Group } from "components/Organisation/Groups/types";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { StyledLink } from "components/Organisation/Library/style";
import Tooltip from "components/General/Tooltip";
import { IconBtn } from "components/General/Icons";

export function GroupMembers({ group }: { group: Group }) {
  const { organisationId } = useTypedPath("organisationId");
  const { add, remove } = useMemberInGroupCrud();
  const groupId = group.id;

  const users = useRecoilValue(usersInOrganisationState(organisationId));
  const members = useRecoilValue(
    groupMembersState({ organisationId, groupId }),
  );
  const isAdminInOrg = useRecoilValue(
    adminInOrganisationSelectorFamily({ organisationId }),
  );

  const [addInProgress, setAddInProgress] = useState<number>(0);
  const [removeInProgress, setRemoveInProgress] = useState(false);
  const onSaveMultiple = useCallback(
    async (users: OrganisationUser[]) => {
      setShowUserSearch(false);
      setAddInProgress(users.length);
      try {
        await Promise.all(
          users.map((u) =>
            add(groupId, u.user_id).then(() =>
              setAddInProgress((cur) => cur - 1),
            ),
          ),
        );
      } catch {
      } finally {
        setAddInProgress(0);
      }
    },
    [add, groupId],
  );
  const onRemove = useCallback(
    async (user_id: string) => {
      setRemoveInProgress(true);
      try {
        await remove(groupId, user_id);
      } catch {
      } finally {
        setRemoveInProgress(false);
      }
    },
    [groupId, remove],
  );

  const [showUserSearch, setShowUserSearch] = useState(false);

  return (
    <Column style={{ gap: "2.4rem", height: "100%" }}>
      <Row style={{ alignItems: "center" }}>
        <TabDescription>
          Members of {group.name}.{" "}
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href={"https://help.vindtech.com/en/articles/8871123-user-groups"}
          >
            Read more.
          </StyledLink>
        </TabDescription>
        <div style={{ marginLeft: "auto", position: "relative" }}>
          <Tooltip
            text="Organisation admin can add members to the group"
            disabled={isAdminInOrg}
          >
            <Button
              text="Add members"
              buttonType="secondary"
              onClick={() => setShowUserSearch((cur) => !cur)}
              disabled={addInProgress > 0 || removeInProgress || !isAdminInOrg}
              icon={<AddIcon />}
            />
          </Tooltip>
          {showUserSearch && (
            <div style={{ position: "absolute", right: "0.8rem" }}>
              <GroupMemberModal members={members} onSave={onSaveMultiple} />
            </div>
          )}
        </div>
      </Row>
      <ContentTableColumn>
        {members.map((m) => {
          const user = users.find((u) => u.user_id === m.user_id);
          if (!user) return <></>;
          return (
            <ContentTableRow key={m.user_id}>
              <UserInfo userId={m.user_id} />
              {isAdminInOrg && (
                <IconBtn
                  disabled={removeInProgress}
                  onClick={(e) => {
                    e.stopPropagation();
                    onRemove(m.user_id);
                  }}
                  size={"1.4rem"}
                  style={{
                    marginLeft: "auto",
                  }}
                >
                  <Trashcan />
                </IconBtn>
              )}
            </ContentTableRow>
          );
        })}
        {Array.from({ length: addInProgress }, (_, index) => (
          <SkeletonBlock
            key={index}
            style={{ height: "4rem", marginTop: "0.8rem" }}
          />
        ))}
      </ContentTableColumn>
    </Column>
  );
}
