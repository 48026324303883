import React from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { colors } from "styles/colors";
import { spacing6 } from "styles/space";
import { HighlightStep } from "components/OnboardingTours/HighlightWrapper";
import { baselineComparisonValuesAtom } from "../state";
import SelectParksButton from "./SelectParks/SelectParksButton";
import SelectAttributes from "./SelectAttributes";
import ComparisonModeToggle from "./ComparisonModeToggle";

const ConfigurationBarWrapper = styled.div`
  display: flex;
  gap: 2rem;
  padding: ${spacing6};
  background-color: ${colors.surfaceSecondary};
  align-items: center;
`;

const ConfigurationBar = ({ innerRef }: { innerRef?: any }) => {
  const baselineComparisonValues = useRecoilValue(baselineComparisonValuesAtom);

  return (
    <ConfigurationBarWrapper ref={innerRef}>
      <HighlightStep
        tourId="general-intro-tour"
        stepId="openParkSelectorInCompare"
      >
        <SelectParksButton />
      </HighlightStep>
      <HighlightStep tourId="general-intro-tour" stepId="selectRowsInCompare">
        <SelectAttributes />
      </HighlightStep>
      {baselineComparisonValues && <ComparisonModeToggle />}
    </ConfigurationBarWrapper>
  );
};

export default ConfigurationBar;
