/// <reference types="vite-plugin-svgr/client" />
import { OpexEntry } from "../../../../services/costService";
import { Input, InputDimensioned, InputNumber } from "../../../General/Input";
import {
  localCostConfigurationAtom,
  useLocalCostConfigurationCrud,
} from "../state";
import { TextIcon } from "../../../../styles/typography";
import Trashcan from "@icons/24/Bin.svg?react";
import { spaceTiny } from "../../../../styles/space";
import { CostUnit, OccuranceType } from "../../../../types/financial";
import { isDefined } from "../../../../utils/predicates";
import { Row as _Row } from "../../../General/Layout";
import styled from "styled-components";
import Dropdown from "components/Dropdown/Dropdown";
import { GridWithFiveColumns, Label } from "../styles";
import { useRecoilValue } from "recoil";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";

const Row = styled(_Row)<{ justify?: string; marginTop?: string }>`
  margin-top: ${(p) => p.marginTop || 0};
  height: 3.2rem;
  align-items: center;
  margin-right: 0.2rem;
  width: fit-content;
`;

const OpexRow = ({
  entry,
  autoFocus,
  deletable,
  isReadOnly,
}: {
  entry: OpexEntry;
  autoFocus: boolean;
  deletable: boolean;
  isReadOnly?: boolean;
}) => {
  const { updateOPEX, deleteOPEX } = useLocalCostConfigurationCrud();
  const { lifeCycle } = useRecoilValue(localCostConfigurationAtom);
  const lifeTime = lifeCycle.decomissioning - lifeCycle.projectStart + 1;

  const units = [
    CostUnit.thousandEuroPerMWPerYear,
    CostUnit.euroPerMWh,
  ] as const;

  return (
    <GridWithFiveColumns>
      <Input
        value={entry.name}
        autoFocus={autoFocus}
        onChange={(e) => updateOPEX({ ...entry, name: e.target.value })}
        disabled={isReadOnly}
      />
      <InputDimensioned
        style={{ minWidth: "17rem" }}
        unit={entry.unit}
        units={units}
        value={entry.cost}
        validate={(value) => isDefined(value) && value >= 0}
        validationMessage={"Must be greater than or equal to 0"}
        onUnitChange={(unit) => updateOPEX({ ...entry, unit })}
        onChange={(cost) => updateOPEX({ ...entry, cost })}
        disabled={isReadOnly}
      />
      <Dropdown
        disabled={isReadOnly}
        value={entry.occurance}
        onChange={(occurance) =>
          updateOPEX({
            ...entry,
            occurance: occurance.target.value as OccuranceType,
          })
        }
      >
        <option value={OccuranceType.Yearly}>Yearly</option>
        <option value={OccuranceType.Recurring}>Recurring</option>
        <option value={OccuranceType.SingleEvent}>Single event</option>
      </Dropdown>

      {entry.occurance === OccuranceType.Recurring && (
        <Row>
          <Label style={{ textAlign: "end", paddingRight: "2rem" }}>
            every
          </Label>
          <InputNumber
            value={entry.occuranceYear}
            autoFocus={autoFocus}
            validate={(e) => e >= 1 && e < lifeTime}
            validationMessage={`Must be equal or greater than 1 and smaller than the project lifetime`}
            onChange={(input) => {
              const yearIndex = Math.round(input);
              updateOPEX({
                ...entry,
                occuranceYear: yearIndex,
              });
            }}
            disabled={isReadOnly}
            style={{ width: "5rem" }}
          />
          <Label style={{ paddingLeft: "1rem" }}>years</Label>
          <HelpTooltip
            style={{ display: "inline-flex" }}
            text={`Years after operation start. Year 1 is ${lifeCycle.operationStart}.`}
            size={8}
          />
        </Row>
      )}

      {entry.occurance === OccuranceType.SingleEvent && (
        <Row>
          <Label style={{ textAlign: "end", paddingRight: "1rem" }}>
            in year
          </Label>
          <InputNumber
            value={entry.occuranceYear}
            autoFocus={autoFocus}
            validate={(e) => e >= 1 && e < lifeTime}
            validationMessage={`Must be equal or greater than 1 and smaller than the project lifetime`}
            onChange={(input) => {
              const yearIndex = Math.round(input);
              updateOPEX({
                ...entry,
                occuranceYear: yearIndex,
              });
            }}
            disabled={isReadOnly}
            style={{ width: "5rem" }}
          />
          <HelpTooltip
            style={{ display: "inline-flex" }}
            text={`Years after operation start. Year 1 is ${lifeCycle.operationStart}.`}
            size={8}
          />
        </Row>
      )}

      {entry.occurance === OccuranceType.Yearly && <div></div>}

      {deletable && !isReadOnly && (
        <TextIcon
          onClick={() => deleteOPEX(entry.id)}
          scale={1.2}
          style={{
            padding: spaceTiny,
            alignSelf: "center",
          }}
        >
          <Trashcan />
        </TextIcon>
      )}
    </GridWithFiveColumns>
  );
};

export default OpexRow;
