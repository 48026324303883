/// <reference types="vite-plugin-svgr/client" />
import Add from "@icons/24/Add.svg?react";
import Close from "@icons/24/Close.svg?react";
import { SVGWrapper } from "@icons/svgUtils";
import Button from "components/General/Button";
import { IconBtn } from "components/General/Icons";
import { Input } from "components/General/Input";
import { Row } from "components/General/Layout";
import SelectedLabel from "components/General/SelectedLabel";
import { organisationLibraryDataPackageResourceState } from "components/Organisation/Library/state";
import Fuse from "fuse.js";
import useTextInput from "hooks/useTextInput";
import { useState } from "react";
import { useRecoilValueLoadable } from "recoil";
import { useTypedPath } from "state/pathParams";
import CostIcon from "../../../../../icons/24/Cost.svg?react";
import {
  ContentTableColumn,
  ContentTableRow,
  Divider,
  ResultContainer,
  SearchAndSelectContainer,
  SecondaryText,
  TextEllipsis,
} from "../../style";
import { TableRowsPlaceholder } from "../shared/TablePlaceholder";
import { DataLibraryPackage } from "components/Organisation/Library/dataLibrary/types";

export default function DataPackageModal({
  existingDataPackageConfig: existingAnalysis,
  onSave,
}: {
  existingDataPackageConfig: string[];
  onSave: (config: DataLibraryPackage[]) => void;
}) {
  const { organisationId } = useTypedPath("organisationId");
  const dataPackageInOrgLoadable = useRecoilValueLoadable(
    organisationLibraryDataPackageResourceState({ organisationId }),
  );

  const [name, onNameChange] = useTextInput("");
  const [selectedDataPackage, setSelectedDataPackage] = useState<
    DataLibraryPackage[]
  >([]);

  const filteredDataPackage = dataPackageInOrgLoadable.map(
    (dataPackageInOrg) => {
      const fuse = new Fuse(dataPackageInOrg, {
        keys: ["name"],
        includeScore: true,
        threshold: 0.3,
      });

      const results = fuse.search(name).map((result) => result.item);
      return name.length > 0 ? results : dataPackageInOrg;
    },
  );

  const toggleDataPackageConfigSelection = (
    dataPackage: DataLibraryPackage,
  ) => {
    setSelectedDataPackage((prevSelectedDataPackageConfig) => {
      if (prevSelectedDataPackageConfig.find((t) => t.id === dataPackage.id)) {
        return prevSelectedDataPackageConfig.filter(
          (g) => g.id !== dataPackage.id,
        );
      } else {
        return [...prevSelectedDataPackageConfig, dataPackage];
      }
    });
  };

  return (
    <SearchAndSelectContainer>
      <Row
        style={{
          alignItems: "center",
          gap: "0.8rem",
          padding: "1.6rem 1.2rem 0",
        }}
      >
        <Input
          autoFocus
          value={name}
          onChange={onNameChange}
          type="search"
          placeholder={`Search`}
          style={{ width: "22rem" }}
        />
        <IconBtn
          size="1.4rem"
          onClick={() => onSave([])}
          style={{ marginLeft: "auto" }}
        >
          <Close />
        </IconBtn>
      </Row>
      <ResultContainer>
        <ContentTableColumn
          style={{
            overflowY: "auto",
            padding: "1.2rem 0",
          }}
        >
          {dataPackageInOrgLoadable.state === "loading" && (
            <TableRowsPlaceholder />
          )}
          {filteredDataPackage.state === "loading" ? (
            <TableRowsPlaceholder />
          ) : (
            filteredDataPackage.contents.map((g: DataLibraryPackage) => {
              const selected = selectedDataPackage.some((st) => st.id === g.id);
              const alreadyExist = existingAnalysis.some((id) => id === g.id);
              return (
                <ContentTableRow
                  key={g.id}
                  style={{ cursor: "pointer", alignItems: "center" }}
                  onClick={() =>
                    !alreadyExist && toggleDataPackageConfigSelection(g)
                  }
                  disabled={selected || alreadyExist}
                >
                  <SVGWrapper size={1.4}>
                    <CostIcon />
                  </SVGWrapper>
                  <TextEllipsis style={{ margin: 0, marginRight: "auto" }}>
                    {g.name}
                  </TextEllipsis>
                  {alreadyExist ? (
                    <SecondaryText style={{ marginLeft: "auto" }}>
                      Already connected
                    </SecondaryText>
                  ) : selected ? (
                    <SecondaryText style={{ marginLeft: "auto" }}>
                      Selected
                    </SecondaryText>
                  ) : (
                    <></>
                  )}
                  {!alreadyExist && !selected && (
                    <Button
                      buttonType="text"
                      text="Select"
                      icon={<Add />}
                      onClick={() => {}}
                      size="small"
                      style={{ padding: "0.2rem 1.2rem" }}
                    />
                  )}
                </ContentTableRow>
              );
            })
          )}
        </ContentTableColumn>
      </ResultContainer>
      {selectedDataPackage.length > 0 && (
        <>
          <Divider />
          <Row
            style={{
              padding: "0 1.2rem 1.2rem",
              alignItems: "center",
            }}
          >
            <Row style={{ flexWrap: "wrap", flex: 1, gap: "0.6rem" }}>
              {selectedDataPackage.map((g) => {
                return (
                  <SelectedLabel
                    key={g.id}
                    title={g.name ?? "Unnamed analysis"}
                    onDeselect={() => toggleDataPackageConfigSelection(g)}
                  />
                );
              })}
            </Row>
            <Button
              text="Add"
              onClick={() => onSave(selectedDataPackage)}
              buttonType="primary"
            />
          </Row>
        </>
      )}
    </SearchAndSelectContainer>
  );
}
