import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import { Types } from "ably";
import { ABLY_CHANGELOG_UPDATE } from "state/ably";
import { AblyEventHandler, useAblyGeneric } from "hooks/useAblyGeneric";
import { organisationIdSelector } from "state/pathParams";
import { useCallback, useMemo } from "react";
import {
  organisationResourceChangelogSelectorFamily,
  organisationResourceLastUpdateRefresherAtom,
} from "./state";
import { _ChangelogEntry } from "./type";

const useAblyChangelogRefreshCallback = () => {
  const setOrganisationResourceLastUpdateRefresher = useSetRecoilState(
    organisationResourceLastUpdateRefresherAtom,
  );
  return useRecoilCallback(
    ({ refresh }) =>
      async (event: AblyEventHandler, msg: Types.Message) => {
        if (event.eventName === ABLY_CHANGELOG_UPDATE) {
          const changelogEntry = _ChangelogEntry.parse(msg.data);
          refresh(
            organisationResourceChangelogSelectorFamily({
              organisationId: changelogEntry.nodeId,
              resourceId: changelogEntry.id,
            }),
          );
          setOrganisationResourceLastUpdateRefresher((s) => s + 1);
        }
      },
    [setOrganisationResourceLastUpdateRefresher],
  );
};

function useAblyChangelogUpdate() {
  const organisationId = useRecoilValue(organisationIdSelector);

  const channelName = useMemo(
    () => organisationId && `${organisationId}:all`,
    [organisationId],
  );

  const onMessageReceived = useCallback(() => {}, []);

  const events = useMemo(
    () => [{ eventName: ABLY_CHANGELOG_UPDATE, onMessageReceived }],
    [onMessageReceived],
  );

  useAblyGeneric(channelName, events);
}

export const AblyChangelogUpdateComponent = () => {
  useAblyChangelogUpdate();

  return null;
};

export default useAblyChangelogRefreshCallback;
