import { selectorFamily } from "recoil";
import {
  ArqisLayer as ArcgisLayer,
  PrivateGISLayer,
  SourceTypes,
  WfsLayer,
  WmsLayer,
  _PrivateGISLayer,
  SourceTypesLayer,
  WmtsLayer,
} from "../types/layers";
import { getPrivateDataSources } from "../services/gisDataAPIService";

export const privateGISSourceDataAPISelector = selectorFamily<
  PrivateGISLayer[],
  { projectId: string }
>({
  key: "privateGISSourceDataAPISelector",
  get:
    ({ projectId }) =>
    async () => {
      if (!projectId) return [];
      const response = await getPrivateDataSources(projectId);

      let j = await response.json();
      return _PrivateGISLayer.array().parse(j);
    },
});

export const privateGISSourceDataWMSAPISelector = selectorFamily<
  WmsLayer[],
  { projectId: string }
>({
  key: "privateGISSourceDataWMSAPISelector",
  get:
    ({ projectId }) =>
    ({ get }) => {
      const privateGISLayers = get(
        privateGISSourceDataAPISelector({ projectId }),
      );
      return privateGISLayers
        .filter((l) => l.type === SourceTypes.wms)
        .map(
          (l) =>
            ({
              source: l.source,
              wms_url: l.url,
              private: true,
              sourceType: SourceTypesLayer.wms,
            }) as WmsLayer,
        );
    },
});

export const privateGISSourceDataWMTSAPISelector = selectorFamily<
  WmtsLayer[],
  { projectId: string }
>({
  key: "privateGISSourceDataWMTSAPISelector",
  get:
    ({ projectId }) =>
    ({ get }) => {
      const privateGISLayers = get(
        privateGISSourceDataAPISelector({ projectId }),
      );
      return privateGISLayers
        .filter((l) => l.type === SourceTypes.wmts)
        .map(
          (l) =>
            ({
              source: l.source,
              wmts_url: l.url,
              private: true,
              sourceType: SourceTypesLayer.wmts,
            }) as WmtsLayer,
        );
    },
});

export const privateGISSourceDataArcgisRestAPISelector = selectorFamily<
  ArcgisLayer[],
  { projectId: string }
>({
  key: "privateGISSourceDataArcgisRestAPISelector",
  get:
    ({ projectId }) =>
    ({ get }) => {
      const privateGISLayers = get(
        privateGISSourceDataAPISelector({ projectId }),
      );
      return privateGISLayers
        .filter((l) => l.type === SourceTypes.arcgis_rest_api)
        .map(
          (l) =>
            ({
              source: l.source,
              arcgis_rest_url: l.url,
              private: true,
              sourceType: SourceTypesLayer.arcgis,
            }) as ArcgisLayer,
        );
    },
});

export const privateGISSourceDataWFSAPISelector = selectorFamily<
  WfsLayer[],
  { projectId: string }
>({
  key: "privateGISSourceDataWFSAPISelector",
  get:
    ({ projectId }) =>
    ({ get }) => {
      const privateGISLayers = get(
        privateGISSourceDataAPISelector({ projectId }),
      );
      return privateGISLayers
        .filter((l) => l.type === SourceTypes.wfs)
        .map(
          (l) =>
            ({
              source: l.source,
              wfs_url: l.url,
              private: true,
              sourceType: SourceTypesLayer.wfs,
            }) as WfsLayer,
        );
    },
});
