/// <reference types="vite-plugin-svgr/client" />
import { useRecoilValue } from "recoil";
import Foundation from "@icons/24/SideBarIcons/Foundation.svg?react";
import InterArray from "@icons/24/SideBarIcons/InterArray.svg?react";
import ExportCable from "@icons/24/SideBarIcons/ExportCable.svg?react";
import SingleTurbine from "@icons/24/SideBarIcons/SingleTurbine.svg?react";
import { mapInteractionSelector } from "../../state/map";
import { parkIdSelector } from "../../state/pathParams";
import { inReadOnlyModeSelector } from "../../state/project";
import { editorAccessProjectSelector } from "../../state/user";
import GenerateCables from "../Cabling/Generate/GenerateCables";
import PlaceWindTurbines from "../GenerateWindTurbines/PlaceWindTurbines";
import { LeftMenuPositionWrapper } from "../MenuPopup/CloseableMenuPopup";
import { LeftSideButtonsContainer } from "./ControlPanel.layout";
import { replaceOrUndefined } from "./utils";
import {
  DrawExportCableMenuType,
  GenerateCablesMenuType,
} from "../../constants/cabling";
import {
  GenerateFoundationsMenuType,
  GenerateWindTurbinesAndFoundationsMenuType,
} from "../../constants/projectMapView";
import {
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "../ErrorBoundaries/ErrorBoundaryLocal";
import { GenerateFoundationsMenu } from "../GenerateFoundationsAndAnchors/GenerateFoundationAnchorMenu";
import { useDrawMode } from "components/MapControls/useActivateDrawMode";
import { Divider, IconMenu } from "components/General/Icons";
import { TriggerCurrentProduction } from "components/ProductionV2/Triggers";
import AddSubstation from "components/Cabling/Generate/AddSubstation";
import AddExportCable from "components/Cabling/Generate/AddExportCable";
import GenerateWindTurbinesAndFoundationsMenu from "components/GenerateWindTurbines/GenerateWindTurbinesAndFoundationsMenu";
import SearchWrapper from "components/Search/SearchWrapper";
import { DrawTools } from "./DrawToolsMenuV2";
import SiteLocator from "components/SiteLocator/SiteLocator";
import DrawMooringLineHelp from "./DrawMooringLineHelp";
import DrawCableHelp from "./DrawCableHelp";
import SideBarIconBtn from "components/General/SideBarIconButton";
import { typography } from "styles/typography";
import { TourStep } from "components/OnboardingTours/TourStep";
import { useTrackEvent } from "components/OnboardingTours/state";
import { HighlightStep } from "components/OnboardingTours/HighlightWrapper";
import {
  FloatingFocusManager,
  autoUpdate,
  useFloating,
  offset,
} from "@floating-ui/react";
import { CableFreeSectorsPanel } from "components/CableFreeSector/Panel";

const GenerationTools = () => {
  const [leftMenuActiveMode, setLeftMenuActiveMode] = useDrawMode();
  const editorAccessProject = useRecoilValue(editorAccessProjectSelector);

  const readOnlyMode = useRecoilValue(inReadOnlyModeSelector);
  const mapInteractions = useRecoilValue(mapInteractionSelector);
  const trackEvent = useTrackEvent();

  const { refs, floatingStyles, context } = useFloating({
    whileElementsMounted: autoUpdate,
    placement: "right",
    middleware: [offset({ mainAxis: 10, crossAxis: 20 })],
  });

  if (!editorAccessProject || readOnlyMode) return null;
  const disable = !mapInteractions.leftSideMenuActive;

  return (
    <>
      <SearchWrapper
        title="Turbine layout"
        tags={[
          "gis",
          "draw",
          "feature",
          "point",
          "turbine",
          "generate",
          "layout",
          "optimize",
        ]}
        id="search-turbine-layout"
        icon={<SingleTurbine />}
        onSelect={() => {
          setLeftMenuActiveMode(
            replaceOrUndefined(GenerateWindTurbinesAndFoundationsMenuType),
          );
        }}
      >
        <FloatingFocusManager context={context} modal={false}>
          <div ref={refs.setFloating} style={floatingStyles}>
            <TourStep tourId="general-intro-tour" stepId="tools"></TourStep>
          </div>
        </FloatingFocusManager>
        <HighlightStep stepId="tools" tourId="general-intro-tour">
          <SideBarIconBtn
            innerRef={refs.setReference}
            id={`button-${GenerateWindTurbinesAndFoundationsMenuType}`}
            disabled={disable}
            active={
              leftMenuActiveMode === GenerateWindTurbinesAndFoundationsMenuType
            }
            onClick={() => {
              trackEvent("clickedLayout");
              setLeftMenuActiveMode(
                replaceOrUndefined(GenerateWindTurbinesAndFoundationsMenuType),
              );
            }}
            icon={<SingleTurbine />}
            title={"Layout"}
          />
        </HighlightStep>
      </SearchWrapper>

      <SearchWrapper
        title="Create foundations and mooring"
        tags={["gis", "draw", "feature", "foundation", "mooring"]}
        id="search-create-foundation-and-mooring"
        icon={<Foundation />}
        onSelect={() => {
          setLeftMenuActiveMode(
            replaceOrUndefined(GenerateFoundationsMenuType),
          );
        }}
      >
        <SideBarIconBtn
          id={`button-${GenerateFoundationsMenuType}`}
          disabled={disable}
          active={leftMenuActiveMode === GenerateFoundationsMenuType}
          onClick={() => {
            setLeftMenuActiveMode(
              replaceOrUndefined(GenerateFoundationsMenuType),
            );
          }}
          title={"Foundation"}
          icon={<Foundation />}
        />
      </SearchWrapper>

      <SearchWrapper
        title="Inter array cables"
        tags={["gis", "draw", "feature", "cables", "export", "electrical"]}
        id="search-create-cables"
        icon={<InterArray />}
        onSelect={() =>
          setLeftMenuActiveMode(replaceOrUndefined(GenerateCablesMenuType))
        }
      >
        <SideBarIconBtn
          id={`button-${GenerateCablesMenuType}`}
          disabled={disable}
          active={leftMenuActiveMode === GenerateCablesMenuType}
          onClick={() => {
            setLeftMenuActiveMode(replaceOrUndefined(GenerateCablesMenuType));
          }}
          title={"Inter array"}
          icon={<InterArray />}
        />
      </SearchWrapper>

      <SearchWrapper
        title="Export cables"
        tags={["export", "cables", "electrical", "volt"]}
        id="search-export-cables"
        icon={<ExportCable />}
        onSelect={() =>
          setLeftMenuActiveMode(replaceOrUndefined(DrawExportCableMenuType))
        }
      >
        <SideBarIconBtn
          id={`button-${DrawExportCableMenuType}`}
          disabled={disable}
          active={leftMenuActiveMode === DrawExportCableMenuType}
          onClick={() => {
            setLeftMenuActiveMode(replaceOrUndefined(DrawExportCableMenuType));
          }}
          title="Export cable"
          icon={<ExportCable />}
        />
      </SearchWrapper>
      <Divider />
    </>
  );
};

const GenerationToolsMenuV2 = ErrorBoundaryWrapper(
  () => {
    const parkId = useRecoilValue(parkIdSelector);
    return (
      <>
        <LeftSideButtonsContainer>
          <div id="tools-container">
            <IconMenu vertical sideBar iconSize="2.4rem">
              <p style={typography.sub2}>Tools</p>
              {parkId && <GenerationTools />}
              {parkId && <TriggerCurrentProduction />}
              <DrawTools />
            </IconMenu>
          </div>

          <LeftMenuPositionWrapper style={{ alignItems: "start" }}>
            <GenerateWindTurbinesAndFoundationsMenu />
            <GenerateFoundationsMenu />
            <GenerateCables />
            <AddExportCable />
            <AddSubstation />
            <DrawMooringLineHelp />
            <DrawCableHelp />
            <PlaceWindTurbines />
            <SiteLocator />
            <CableFreeSectorsPanel />
          </LeftMenuPositionWrapper>
        </LeftSideButtonsContainer>
      </>
    );
  },
  FatalErrorBoundaryWrapper,
  ScreamOnError,
);

export default GenerationToolsMenuV2;
