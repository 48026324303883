import { Place } from "../../General/Anchor";
import CloseableHint from "../CloseableHints/CloseableHint";
import splitMultiFeatureHelpVideo from "./splitMultiFeatureHelpVideo.mp4";

export const splitMultiFeatureHelpHintType = "splitMultiFeatureHelpHintType";

const SplitMultiFeatureHelp = ({
  closeCallback,
  gotItCallback,
  position,
}: {
  closeCallback: () => void;
  gotItCallback?: () => void;
  position?: Place;
}) => {
  return (
    <CloseableHint
      title={"Split multi part feature"}
      closeCallback={closeCallback}
      gotItCallback={gotItCallback}
      timeToDissapearMS={10000}
      videosrc={splitMultiFeatureHelpVideo}
      position={position}
    >
      {`Multi part features needs to be split into individual features in order to be used further`}
    </CloseableHint>
  );
};

export default SplitMultiFeatureHelp;
