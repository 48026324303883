import { useEffect } from "react";
import { selectorFamily, useRecoilValue } from "recoil";
import { getDivisionFeaturesInBranchSelectorFamily } from "../../state/division";
import * as turf from "@turf/turf";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { WarningProps } from "./ValidationWarnings";
import { getParkFeatureSelectorFamily } from "state/park";
import { branchIdSelector } from "state/pathParams";

export const subAreaOutsideParkWarningSelectorFamily = selectorFamily<
  | {
      type: ValidationWarningTypes.SubAreasOutsidePark;
      featureIds: string[];
      parkId: string;
    }
  | undefined,
  {
    parkId: string;
    branchId: string;
  }
>({
  key: "subAreaOutsideParkWarningSelectorFamily",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const park = get(getParkFeatureSelectorFamily({ parkId }));
      if (!park) return;

      const { subAreas } = get(
        getDivisionFeaturesInBranchSelectorFamily({ parkId, branchId }),
      );

      const subAreaOutsideParkIds = subAreas
        .filter((iz) => turf.intersect(park, iz) == null)
        .map((tz) => tz.id);

      return subAreaOutsideParkIds.length === 0
        ? undefined
        : {
            type: ValidationWarningTypes.SubAreasOutsidePark,
            featureIds: subAreaOutsideParkIds,
            parkId: parkId,
          };
    },
});

const SubAreasOutsidePark = ({
  parkId,
  warn,
  remove,
}: { parkId: string } & WarningProps) => {
  const branchId = useRecoilValue(branchIdSelector);
  const zoneOutsideParkWarning = useRecoilValue(
    subAreaOutsideParkWarningSelectorFamily({
      parkId: parkId,
      branchId: branchId ?? "",
    }),
  );
  useEffect(() => {
    if (!zoneOutsideParkWarning) return;
    warn(zoneOutsideParkWarning);
    return () => {
      remove(ValidationWarningTypes.SubAreasOutsidePark);
    };
  }, [zoneOutsideParkWarning, remove, warn]);

  return null;
};

export default SubAreasOutsidePark;
