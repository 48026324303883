import React from "react";
import { atom, selector } from "recoil";
import { organisationIdSelector } from "state/pathParams";
import { projectNodesInOrganisation } from "components/Projects/useOrganisationFolderCrud";
import { isDefined } from "utils/predicates";
import { syncLocalStorage } from "state/effects";
import { ProjectNodeInformation } from "services/customerAPI";
import {
  _MappedPortfolioItem,
  _PortfolioItemBase,
  getPortfolio,
  getPortfolioSimpleItems,
  MappedPortfolioItem,
  PortfolioItemBase,
  PortfolioItemResponse,
} from "services/portfolioAPIService";

export const portfolioItemsSimpleAtom = atom<PortfolioItemBase[]>({
  key: "portfolioItemsSimpleAtom",
  default: selector<PortfolioItemBase[]>({
    key: "portfolioItemsSimpleAtom.default",
    get: ({ get }) => {
      get(portfolioRefreshAtom);
      const organisationId = get(organisationIdSelector);
      if (!organisationId) {
        return [];
      }
      return getPortfolioSimpleItems(organisationId);
    },
  }),
});

export const portfolioItemsStoreAtom = atom<PortfolioItemResponse[]>({
  key: "portfolioItemsStoreAtom",
  default: selector<PortfolioItemResponse[]>({
    key: "portfolioItemsStoreAtom.default",
    get: async ({ get }) => {
      get(portfolioRefreshAtom);
      const organisationId = get(organisationIdSelector);
      if (!organisationId) {
        return [];
      }

      return await getPortfolio(organisationId);
    },
  }),
});

export const numberNonAccessablePortfolioParksSelector = selector<number>({
  key: "numberNonAccessablePortfolioParksSelector",
  get: ({ get }) => {
    const organisationId = get(organisationIdSelector);
    if (!organisationId) {
      return 0;
    }

    const portfolioItems = get(portfolioItemsStoreAtom);
    const projectNodes = get(projectNodesInOrganisation({ organisationId }));

    return portfolioItems.reduce((acc, curr) => {
      const project = projectNodes.find((n) => n.id === curr.projectId) as
        | ProjectNodeInformation
        | undefined;

      if (!project) {
        return acc + 1;
      }
      return acc;
    }, 0);
  },
});

export const mappedPortfolioItemsStoreAtom = atom<MappedPortfolioItem[]>({
  key: "mappedPortfolioItemsStoreAtom",
  default: selector<MappedPortfolioItem[]>({
    key: "mappedPortfolioItemsStoreAtom.default",
    get: async ({ get }) => {
      const organisationId = get(organisationIdSelector);
      if (!organisationId) {
        return [];
      }

      const response = get(portfolioItemsStoreAtom);
      const projectNodes = get(projectNodesInOrganisation({ organisationId }));

      const result = response
        .map<MappedPortfolioItem | undefined>((item) => {
          const project = projectNodes.find((n) => n.id === item.projectId) as
            | ProjectNodeInformation
            | undefined;

          if (!project) {
            return undefined;
          }

          return {
            project,
            nrTurbines: item.items.turbines.count,
            totalPower: item.items.turbines.totalPower,
            branchId: item.branchId,
            park: item.items.park,
          };
        })
        .filter(isDefined);
      return result;
    },
  }),
});

export const mappedPortfolioItemsWithFilteredAtom = atom<MappedPortfolioItem[]>(
  {
    key: "mappedPortfolioItemsWithFilteredAtom",
    default: selector<MappedPortfolioItem[]>({
      key: "mappedPortfolioItemsWithFilteredAtom.default",
      get: async ({ get }) => {
        const items = get(mappedPortfolioItemsStoreAtom);
        const filteredParkItems = get(filteredPortfolioParkItemsAtom);

        const result = items.map((item) => {
          const isFiltered = filteredParkItems.some(
            (p) =>
              p.parkId === item.park.id &&
              p.branchId === item.branchId &&
              p.projectId === item.project.id,
          );

          return {
            isFiltered,
            ...item,
          };
        });

        return _MappedPortfolioItem.array().parse(result);
      },
    }),
  },
);

export const filteredPortfolioParkItemsAtom = atom<PortfolioItemBase[]>({
  key: "filteredPortfolioParkItemsAtom",
  default: [],
  effects: [
    syncLocalStorage(
      "vind:filtered-portfolio-items",
      _PortfolioItemBase.array(),
    ),
  ],
});

export const portfolioFilteredTotalCapacitySelector = selector<number>({
  key: "portfolioFilteredTotalCapacitySelector",
  get: ({ get }) => {
    const portfolioItems = get(mappedPortfolioItemsWithFilteredAtom);
    return portfolioItems
      .filter((item) => !item.isFiltered)
      .reduce((acc, item) => acc + item.totalPower, 0);
  },
});

export const portfolioRefreshAtom = atom({
  key: "portfolioRefreshAtom",
  default: 0,
});

export const portfolioFullScreenAtom = atom<boolean>({
  key: "portfolioFullScreenAtom",
  default: false,
});

export const portfolioMapPopupBoundaryRefAtom = atom<
  React.RefObject<HTMLDivElement> | undefined
>({
  key: "portfolioMapPopupBoundaryRefAtom",
  default: undefined,
  dangerouslyAllowMutability: true,
});
