import { useEffect } from "react";
import { selectorFamily, useRecoilValue } from "recoil";
import { ValidationWarningTypes } from "./utils";
import { useValidationWarnings } from "./ValidationWarnings";
import { branchIdSelector } from "state/pathParams";
import {
  getCableCorridorsSelectorFamily,
  getCablesInBranchSelectorFamily,
  getSubstationsInBranchSelectorFamily,
} from "state/cable";
import { getDivisionFeaturesInBranchSelectorFamily } from "state/division";
import { ExclusionDomainUnpacked, exclusionDomainUnpack } from "types/feature";
import { pointInPolygon } from "utils/geometry";
import * as turf from "@turf/turf";
import { getTurbinesInBranchSelectorFamily } from "state/layout";
import { scream, sendWarning } from "utils/sentry";
import { multiFeatureToFeatures } from "utils/geojson/utils";
import { isPolygonFeature } from "utils/predicates";
import { getParkFeatureInBranchSelector } from "state/park";
import { Feature, Polygon } from "geojson";
import { unionAll } from "utils/turf";

export const substationInsideNoSubstationExclusionZoneWarningSelectorFamily =
  selectorFamily<
    | {
        type: ValidationWarningTypes.SubstationInsideNoSubstationExclusionZone;
        featureIds: string[];
        parkId: string;
      }
    | undefined,
    {
      parkId: string;
      branchId: string;
    }
  >({
    key: "substationInsideNoSubstationExclusionZoneWarningSelectorFamily",
    get:
      ({ parkId, branchId }) =>
      ({ get }) => {
        const substations = get(
          getSubstationsInBranchSelectorFamily({ parkId, branchId }),
        );
        const { exclusionZones } = get(
          getDivisionFeaturesInBranchSelectorFamily({ parkId, branchId }),
        );

        const exlusionZonesWithoutCables = exclusionZones.filter(
          (e) => exclusionDomainUnpack(e.properties.domain).substation,
        );

        const overlappingSubstations = substations.filter((s) =>
          exlusionZonesWithoutCables.some((zone) =>
            pointInPolygon(s.geometry, zone.geometry),
          ),
        );

        return overlappingSubstations.length === 0
          ? undefined
          : {
              type: ValidationWarningTypes.SubstationInsideNoSubstationExclusionZone,
              featureIds: overlappingSubstations.map((s) => s.id),
              parkId: parkId,
            };
      },
  });

export const cablesInsideNoCablesExclusionZoneWarningSelectorFamily =
  selectorFamily<
    | {
        type: ValidationWarningTypes.CableInsideNoCableExclusionZone;
        featureIds: string[];
        parkId: string;
      }
    | undefined,
    {
      parkId: string;
      branchId: string;
    }
  >({
    key: "cablesInsideNoCablesExclusionZoneWarning",
    get:
      ({ parkId, branchId }) =>
      ({ get }) => {
        const cables = get(
          getCablesInBranchSelectorFamily({ parkId, branchId }),
        );
        const exclusionZones = get(
          getExclusionZonesOverlappingWithParkOrCableCorridor({
            parkId,
            branchId,
          }),
        );

        const exlusionZonesWithoutCables = exclusionZones.filter(
          (e) => e.properties.domain.cable,
        );

        const zones = exlusionZonesWithoutCables
          .flatMap((zone) => {
            const buffer = turf.buffer(zone.geometry, -1, {
              units: "meters",
            });

            if (buffer == null) return [];

            return multiFeatureToFeatures(buffer);
          })
          .filter(isPolygonFeature);

        const cablesCrossingNoCablesExclusionZones = cables.filter((cable) =>
          zones.some((polygon1) => {
            const line1 = cable.geometry;

            try {
              return turf.lineIntersect(line1, polygon1).features.length > 0;
            } catch (e) {
              if (e instanceof Error) {
                scream(e, {
                  error:
                    "Error in cablesInsideNoCablesExclusionZoneWarningSelectorFamily",
                  line1: JSON.stringify(line1),
                  polygon1: JSON.stringify(polygon1),
                });
              } else {
                scream(
                  "Error in cablesInsideNoCablesExclusionZoneWarningSelectorFamily",
                  {
                    error: JSON.stringify(e),
                    line1: JSON.stringify(line1),
                    polygon1: JSON.stringify(polygon1),
                  },
                );
              }
              return false;
            }
          }),
        );

        return cablesCrossingNoCablesExclusionZones.length === 0
          ? undefined
          : {
              type: ValidationWarningTypes.CableInsideNoCableExclusionZone,
              featureIds: cablesCrossingNoCablesExclusionZones.map((s) => s.id),
              parkId: parkId,
            };
      },
  });

export const turbinesInsideNoTurbinesExclusionZoneWarningSelectorFamily =
  selectorFamily<
    | {
        type: ValidationWarningTypes.TurbineInsideNoTurbineExclusionZone;
        featureIds: string[];
        parkId: string;
      }
    | undefined,
    {
      parkId: string;
      branchId: string;
    }
  >({
    key: "turbinesInsideNoTurbinesExclusionZoneWarning",
    get:
      ({ parkId, branchId }) =>
      ({ get }) => {
        const turbines = get(
          getTurbinesInBranchSelectorFamily({ parkId, branchId }),
        );

        const exclusionZones = get(
          getOverlappingExclusionZones({ parkId, branchId }),
        );

        const exlusionZonesWithoutTurbines = exclusionZones.filter(
          (e) => e.properties.domain.turbine,
        );

        const overlappingTurbines = turbines.filter((s) =>
          exlusionZonesWithoutTurbines.some((zone) =>
            pointInPolygon(s.geometry, zone.geometry),
          ),
        );

        return overlappingTurbines.length === 0
          ? undefined
          : {
              type: ValidationWarningTypes.TurbineInsideNoTurbineExclusionZone,
              featureIds: overlappingTurbines.map((s) => s.id),
              parkId: parkId,
            };
      },
  });

export const ExclusionZoneContainingExcludedFeature = ({
  parkId,
}: {
  parkId: string;
}) => {
  const branchId = useRecoilValue(branchIdSelector);
  const substationInsideNoSubstationExclusionZoneWarning = useRecoilValue(
    substationInsideNoSubstationExclusionZoneWarningSelectorFamily({
      parkId: parkId,
      branchId: branchId ?? "",
    }),
  );

  const cableInsideNoCableExclusionZoneWarning = useRecoilValue(
    cablesInsideNoCablesExclusionZoneWarningSelectorFamily({
      parkId: parkId,
      branchId: branchId ?? "",
    }),
  );

  const turbinesInsideNoTurbinesExclusionZoneWarning = useRecoilValue(
    turbinesInsideNoTurbinesExclusionZoneWarningSelectorFamily({
      parkId: parkId,
      branchId: branchId ?? "",
    }),
  );

  const { warn, remove } = useValidationWarnings();
  useEffect(() => {
    if (!substationInsideNoSubstationExclusionZoneWarning) return;
    warn(substationInsideNoSubstationExclusionZoneWarning);
    return () => {
      remove(ValidationWarningTypes.SubstationInsideNoSubstationExclusionZone);
    };
  }, [substationInsideNoSubstationExclusionZoneWarning, remove, warn]);

  useEffect(() => {
    if (!cableInsideNoCableExclusionZoneWarning) return;
    warn(cableInsideNoCableExclusionZoneWarning);
    return () => {
      remove(ValidationWarningTypes.CableInsideNoCableExclusionZone);
    };
  }, [cableInsideNoCableExclusionZoneWarning, remove, warn]);

  useEffect(() => {
    if (!turbinesInsideNoTurbinesExclusionZoneWarning) return;
    warn(turbinesInsideNoTurbinesExclusionZoneWarning);
    return () => {
      remove(ValidationWarningTypes.TurbineInsideNoTurbineExclusionZone);
    };
  }, [turbinesInsideNoTurbinesExclusionZoneWarning, remove, warn]);

  return null;
};

const getOverlappingExclusionZones = selectorFamily<
  Feature<Polygon, { domain: ExclusionDomainUnpacked }>[],
  { parkId: string; branchId: string }
>({
  key: "getOverlappingExclusionZonesSelectorFamily",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const park = get(getParkFeatureInBranchSelector({ parkId, branchId }));
      if (!park) return [] as any;

      const { exclusionZones } = get(
        getDivisionFeaturesInBranchSelectorFamily({ parkId, branchId }),
      );

      return exclusionZones.flatMap((zone) => {
        const intersectionFeature = turf.intersect(
          park.geometry,
          zone.geometry,
        );
        if (!intersectionFeature) return [];

        return multiFeatureToFeatures(intersectionFeature).map((f) => {
          f.properties = {
            domain: exclusionDomainUnpack(zone.properties.domain),
          };

          return f;
        });
      });
    },
});

const getExclusionZonesOverlappingWithParkOrCableCorridor = selectorFamily<
  Feature<Polygon, { domain: ExclusionDomainUnpacked }>[],
  { parkId: string; branchId: string }
>({
  key: "getExclusionZonesOverlappingWithParkOrCableCorridorSelectorFamily",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const park = get(getParkFeatureInBranchSelector({ parkId, branchId }));
      if (!park) return [] as any;

      const cableCorridors = get(
        getCableCorridorsSelectorFamily({ parkId, branchId }),
      );

      const overlappingCableCorridors = cableCorridors.filter(
        (corridor) => turf.intersect(park.geometry, corridor.geometry) !== null,
      );

      const feature = unionAll([park, ...overlappingCableCorridors]);

      if (feature === null || !isPolygonFeature(feature)) {
        sendWarning("Feature is not a polygon", { feature });
        return [];
      }

      const { exclusionZones } = get(
        getDivisionFeaturesInBranchSelectorFamily({ parkId, branchId }),
      );

      return exclusionZones.flatMap((zone) => {
        const intersectionFeature = turf.intersect(
          feature.geometry,
          zone.geometry,
        );
        if (!intersectionFeature) return [];

        return multiFeatureToFeatures(intersectionFeature).map((f) => {
          f.properties = {
            domain: exclusionDomainUnpack(zone.properties.domain),
          };

          return f;
        });
      });
    },
});
