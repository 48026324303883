import {
  followThreadsAtomFamily,
  threadsAtomFamily,
  userReactionsAtomFamily,
} from "components/Comments/state";
import { dashboardsAtomFamily } from "components/Dashboard/state";
import {
  allBackendLayerCollectionsAtomFamily,
  collectionLayerVersionsSelectorFamily,
  getAllLayerCollections,
} from "components/LayerList/Collections/state";
import { allLayerSettingsAtomFamily } from "components/LayerList/LayerSettings/state";
import {
  analysisResourceWithAccessOnNodeState,
  dataPackageResourceWithAccessOnNodeState,
  foundationResourceWithAccessOnNodeState,
  turbineResourceWithAccessOnNodeState,
} from "components/Organisation/Library/state";
import { projectFeaturesState } from "components/ProjectElements/state";
import {
  getProjectElementsSortOrderAtomFamily,
  projectFoldersAtomFamily,
} from "components/ProjectElementsV2/state";
import { useEffect } from "react";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { configurationsAtomFamily } from "state/configuration";
import { costConfigurationsAtomFamily } from "state/costConfigurations";
import { projectIdSelector, branchIdSelector } from "state/pathParams";
import {
  branchSnapshotsAtomFamily,
  projectBranchesAtomFamily,
} from "state/timeline";

export default function useRefreshProjectState() {
  const projectId = useRecoilValue(projectIdSelector);
  const branchId = useRecoilValue(branchIdSelector);

  const refreshBranchState = useRecoilCallback(
    ({ refresh }) =>
      (projectId: string, branchId: string) => {
        refresh(
          projectFeaturesState({
            projectId,
            branchId,
          }),
        );
        refresh(branchSnapshotsAtomFamily({ projectId, branchId }));
        refresh(
          projectFoldersAtomFamily({
            projectId,
            branchId,
          }),
        );
        refresh(
          getProjectElementsSortOrderAtomFamily({
            nodeId: projectId,
            branchId,
          }),
        );
        refresh(
          threadsAtomFamily({
            nodeId: projectId,
            branchId,
          }),
        );
        refresh(
          userReactionsAtomFamily({
            nodeId: projectId,
            branchId,
          }),
        );
        refresh(
          followThreadsAtomFamily({
            nodeId: projectId,
            branchId,
          }),
        );
      },
    [],
  );
  useEffect(() => {
    if (projectId && branchId) {
      refreshBranchState(projectId, branchId);
    }
  }, [branchId, projectId, refreshBranchState]);

  const refreshProjectState = useRecoilCallback(
    ({ refresh }) =>
      (projectId: string) => {
        refresh(allLayerSettingsAtomFamily({ projectId }));
        refresh(allBackendLayerCollectionsAtomFamily({ projectId }));
        refresh(costConfigurationsAtomFamily({ projectId }));
        refresh(configurationsAtomFamily({ nodeId: projectId }));
        refresh(projectBranchesAtomFamily({ nodeId: projectId }));
        refresh(turbineResourceWithAccessOnNodeState({ nodeId: projectId }));
        refresh(foundationResourceWithAccessOnNodeState({ nodeId: projectId }));
        refresh(analysisResourceWithAccessOnNodeState({ nodeId: projectId }));
        refresh(
          dataPackageResourceWithAccessOnNodeState({ nodeId: projectId }),
        );
        refresh(dashboardsAtomFamily({ nodeId: projectId }));
        refresh(getAllLayerCollections({ projectId }));
        refresh(collectionLayerVersionsSelectorFamily({ projectId }));
      },
    [],
  );
  useEffect(() => {
    if (projectId) {
      refreshProjectState(projectId);
    }
  }, [projectId, refreshProjectState]);

  return refreshBranchState;
}
