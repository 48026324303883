import React, { Suspense, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { selectorFamily, useRecoilValue } from "recoil";
import { mapRefAtom } from "../../state/map";
import Wake from "../../layers/wake";
import Noise from "../../layers/noise";
import CanvasLayer from "../../layers/canvasLayer";
import Filter from "../../layers/filter";
import useNavigateToPark from "../../hooks/useNavigateToPark";
import GeneralMapLayers from "../../layers/GeneralMapLayers";
import CanvasSingleSelectOption from "../CanvasSelectOption/CanvasSingleSelectOption";
import { FeatureLayers } from "../Mapbox/ParkLayers";
import {
  currentSelectedProjectFeatures,
  firstCurrentSelectionSelector,
} from "../../state/selection";
import CanvasMultiSelectOption from "../CanvasSelectOption/CanvasMultiSelectOption";
import { currentExternalLayerSelection } from "../../state/externalLayerSelection";
import DynamicSelectOption from "../DynamicSelectOption/DynamicSelectOption";
import GenerationToolsMenuV2 from "../ControlPanels/GenerationToolsMenuV2";
import { getParkFeaturesSelector } from "../../state/park";
import { PARK_PROPERTY_TYPE } from "../../constants/park";
import { projectVersionAtom } from "../../state/project";
import { projectFeaturesSelector } from "../ProjectElements/state";
import {
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "../ErrorBoundaries/ErrorBoundaryLocal";
import FeatureVersionsPopup from "../FeatureVersionsPopup/FeatureVersionsPopup";
import { openedFeatureIdForVersionHistoryAtom } from "../FeatureVersionsPopup/state";
import { cableEditModeAtom } from "../Cabling/Generate/state";
import { CableEdit } from "../Cabling/Generate/CableEdit";
import ScoresOnMouse from "../SiteLocator/SiteLocatorTooltip";
import CoordinateOnMouse from "../CoordinateOnMouse/CoordinateOnMouse";
import { MapboxSyncEffects } from "components/Mapbox/MapboxSyncEffects";
import { hasParkSelector, parkIdSelector } from "../../state/pathParams";
import { storedNodesForOrganisation } from "components/Projects/useOrganisationFolderCrud";
import { customerIdToOrganisationIdSelectorFamily } from "components/Organisation/state";
import { isLoaded } from "types/Load";
import ExistingTurbines, { AddExistingTurbines } from "layers/existingTurbines";
import { useSnapExportCableToChangedSubstations } from "hooks/useSnapExportCableToChangedSubstations";
import DrawToolsMenuV2 from "components/ControlPanels/DrawToolsMenuV2";
import RightSideMapV2 from "components/RightSide/RightSideMapV2";
import { SelectOptionsContext } from "./SelectOptions";
import { useProjectElementsCrud } from "components/ProjectElements/useProjectElementsCrud";

const projectIdToNodeIdSelectorFamilyV2 = selectorFamily<
  string | undefined,
  { customerId: string | undefined; projectId: string | undefined }
>({
  key: "projectIdToNodeIdSelectorFamilyV2",
  get:
    ({ customerId, projectId }) =>
    ({ get }) => {
      if (!customerId || !projectId) return undefined;

      const organisationId = get(
        customerIdToOrganisationIdSelectorFamily(customerId),
      );
      if (!organisationId) return undefined;
      const nodes = get(
        storedNodesForOrganisation({
          organisationId,
        }),
      )
        .filter(isLoaded)
        .map((l) => l.value);
      return nodes.find((n) => n.legacy_id === projectId)?.id;
    },
});

const ParkDesignInner = () => {
  const parkIsSelected = useRecoilValue(hasParkSelector);

  return (
    <>
      <Suspense fallback={null}>
        <ParkDesignMap />
      </Suspense>
      <Suspense fallback={null}>
        <RightSideMapV2 />
      </Suspense>
      <ScoresOnMouse />

      {parkIsSelected ? (
        <>
          <GenerationToolsMenuV2 />
        </>
      ) : (
        <>
          <DrawToolsMenuV2 />
          <CoordinateOnMouse />
        </>
      )}

      <Filter />
      <RedirectToSelectedPark />
    </>
  );
};

const RedirectToSelectedPark = ErrorBoundaryWrapper(
  () => {
    const firstCurrentSelection = useRecoilValue(firstCurrentSelectionSelector);
    const { navigateToPark } = useNavigateToPark();
    const parkId = useRecoilValue(parkIdSelector);

    const projectLayerFeaturesWithHistory = useRecoilValue(
      projectFeaturesSelector,
    );

    const selectedItem = projectLayerFeaturesWithHistory.find(
      (f) => f.id === firstCurrentSelection?.id,
    );

    useEffect(() => {
      if (
        selectedItem?.properties?.type === PARK_PROPERTY_TYPE &&
        firstCurrentSelection &&
        parkId !== firstCurrentSelection?.id
      ) {
        navigateToPark(firstCurrentSelection?.id);
      }
    }, [
      firstCurrentSelection,
      navigateToPark,
      parkId,
      selectedItem?.properties?.type,
    ]);

    return null;
  },
  FatalErrorBoundaryWrapper,
  ScreamOnError,
);

const ParkDesignLegacy = () => {
  const { customerId, projectId, branchId } = useParams();

  // legacy, still needed
  const projectNodeId = useRecoilValue(
    projectIdToNodeIdSelectorFamilyV2({ customerId, projectId }),
  );
  const orgId = useRecoilValue(
    customerIdToOrganisationIdSelectorFamily(customerId),
  );

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (projectNodeId && orgId) {
      navigate(
        {
          pathname: `/design/project/${orgId}/${projectNodeId}${
            branchId ? "/" + branchId : ""
          }`,
          search: searchParams.toString(),
        },
        {
          replace: true,
        },
      );
    } else {
      navigate(
        {
          pathname: `/organisation/${orgId}`,
          search: searchParams.toString(),
        },
        {
          replace: true,
        },
      );
    }
  }, [branchId, navigate, orgId, projectNodeId, searchParams]);

  return <></>;
};

export const ParkDesignV2 = () => {
  return (
    <React.Suspense fallback={null}>
      <ParkDesignInner />
    </React.Suspense>
  );
};

function ClearParkFromUrl() {
  const { organisationId, projectId, branchId, parkId } = useParams();
  const parks = useRecoilValue(getParkFeaturesSelector);
  const version = useRecoilValue(
    projectVersionAtom({
      projectId,
      branchId,
    }),
  );
  const openedFeatureIdForVersionHistory = useRecoilValue(
    openedFeatureIdForVersionHistoryAtom,
  );
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const skipCheck = Boolean(openedFeatureIdForVersionHistory) && version;

  useEffect(() => {
    // selected park does not exist (Dont clear if we are viewing a specific version with Feature version history)
    if (!skipCheck && parkId && !parks.some((p) => p.id === parkId)) {
      navigate(
        {
          pathname: `/design/project/${organisationId}/${projectId}/${branchId}`,
          search: searchParams.toString(),
        },
        {
          replace: true,
        },
      );
    }
  }, [
    branchId,
    organisationId,
    navigate,
    parkId,
    parks,
    projectId,
    searchParams,
    skipCheck,
  ]);
  return null;
}

const SelectOptions = () => {
  const selectedProjectFeatures = useRecoilValue(
    currentSelectedProjectFeatures,
  );
  const { update: updateFeatures } = useProjectElementsCrud();

  return (
    <SelectOptionsContext.Provider
      value={{
        updateFeatures,
      }}
    >
      {selectedProjectFeatures.length === 1 && (
        <React.Suspense fallback={null}>
          <CanvasSingleSelectOption
            selectedProjectFeature={selectedProjectFeatures[0]}
          />
        </React.Suspense>
      )}
      {selectedProjectFeatures.length > 1 && (
        <React.Suspense fallback={null}>
          <CanvasMultiSelectOption
            selectedProjectFeatures={selectedProjectFeatures}
          />
        </React.Suspense>
      )}
    </SelectOptionsContext.Provider>
  );
};

const ParkDesignMap = ErrorBoundaryWrapper(
  () => {
    const parkId = useRecoilValue(parkIdSelector);
    const map = useRecoilValue(mapRefAtom);

    const dynamicLayerSelection = useRecoilValue(currentExternalLayerSelection);

    const cableEditMode = useRecoilValue(cableEditModeAtom);

    useSnapExportCableToChangedSubstations();

    if (!map) return null;

    return (
      <>
        <ClearParkFromUrl />
        <Wake />
        <Noise />
        <CanvasLayer />
        <ExistingTurbines />
        <AddExistingTurbines map={map} />
        <MapboxSyncEffects map={map} />

        <FeatureLayers map={map} />
        <GeneralMapLayers />
        {dynamicLayerSelection.length > 0 && (
          <DynamicSelectOption selections={dynamicLayerSelection} />
        )}
        <SelectOptions />
        <FeatureVersionsPopup />
        {cableEditMode && parkId && <CableEdit parkId={parkId} />}
      </>
    );
  },
  FatalErrorBoundaryWrapper,
  ScreamOnError,
);

export default ParkDesignLegacy;
