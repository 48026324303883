import { z } from "zod";
import { isDefined } from "./predicates";

/**
 * Try to parse the data. If parsing fails, return the default value.
 */
export const parseOr = <In, Out>(
  parser: z.ZodType<Out, z.ZodTypeDef, In>,
  value: In,
  defaultValue: Out,
) => {
  const res = parser.safeParse(value);
  if (res.success) return res.data;
  return defaultValue;
};

/**
 * Parse each element in a list and return a new list containing all elements
 * that parsed successfully.
 *
 * Related: {@link safeArray}
 */
export const parseFilter = <In, Out>(
  parser: z.ZodType<Out, z.ZodTypeDef, In>,
  values: In[],
): Out[] =>
  values.flatMap((v) => {
    const res = parser.safeParse(v);
    if (res.success) return [res.data];
    return [];
  });

/**
 * Construct a parser that parses an array of the given type, and filters
 * out any elements that failed to parse.
 *
 * Related: {@link parseFilter}
 */
export const safeArray = <T>(
  p: z.ZodType<T>,
): z.ZodType<T[], z.ZodTypeDef, unknown> =>
  p
    .optional()
    .catch(undefined)
    .array()
    .transform((a) => a.filter(isDefined));
