import { useRecoilCallback } from "recoil";
import { useRecoilValueDef } from "utils/recoil";
import { organisationIdSelector } from "state/pathParams";
import { useToast } from "hooks/useToast";
import {
  deleteCustomOrganisationLayers,
  editCustomOrganisationLayerMetadata,
} from "services/customLayersAPIService";
import {
  getDataLayersUsage,
  getDataPackageUsage,
  organisationLibraryLayersAtomFamily,
  refreshOrganisationLibraryLayersAtom,
} from "./state";
import {
  deleteOrganisationCustomLayerResource,
  deleteOrganisationLibraryPackage,
  newOrganisationLibraryPackage,
  updateOrganisationLibraryPackage,
  updateOrganisationLibraryPackageLayerIds,
} from "./service";
import { dedup } from "utils/utils";
import { useState } from "react";
import { scream } from "utils/sentry";
import {
  DataLibraryLayer,
  DataLibraryPackage,
  NewLayersLibraryPackage,
} from "./types";
import { organisationLibraryDataPackageResourceState } from "../state";
import { initializeAndSet } from "components/Comments/hooks/useReplyReactionCrud";

export const useDataLibraryLayersCrud = () => {
  const organisationId = useRecoilValueDef(organisationIdSelector);
  const { success: showSuccess, error: showError } = useToast();
  const [loading, setLoading] = useState(false);
  const [isDeletingPackage, setIsDeletingPackage] = useState(false);
  const [creatingNewPackage, setCreatingNewPackage] = useState(false);
  const [addingLayersToPackage, setAddingLayersToPackage] = useState(false);

  const refreshLayers = useRecoilCallback(
    ({ set }) =>
      () => {
        set(refreshOrganisationLibraryLayersAtom, (curr) => curr + 1);
      },
    [],
  );

  const deleteLayer = useRecoilCallback(
    ({ set, snapshot }) =>
      async (layerId: string) => {
        const fallback = await snapshot.getPromise(
          organisationLibraryLayersAtomFamily({ organisationId }),
        );

        const usage = await snapshot.getPromise(
          getDataLayersUsage({
            nodeId: organisationId,
            layerIds: [layerId],
          }),
        );

        const totalUsage = Object.values(usage).flat();
        if (
          totalUsage.length !== 0 &&
          !window.confirm(
            `Layer is currently being used in ${totalUsage.length} projects, are you sure you want to remove access to it?`,
          )
        ) {
          return;
        } else if (
          totalUsage.length === 0 &&
          !window.confirm("Are you sure you want to delete this package?")
        ) {
          return;
        }

        set(organisationLibraryLayersAtomFamily({ organisationId }), (curr) => {
          return curr.filter((layer) => layer.id !== layerId);
        });
        try {
          await deleteOrganisationCustomLayerResource(organisationId, layerId);
          showSuccess("The layer was successfully deleted");
        } catch (err) {
          set(
            organisationLibraryLayersAtomFamily({ organisationId }),
            fallback,
          );
          showError(
            "Something went wrong when deleting the layer, please try again.",
          );
        }
      },
    [organisationId, showError, showSuccess],
  );

  const addLayerLocal = useRecoilCallback(
    ({ set, snapshot }) =>
      async (addedLayer: DataLibraryLayer) => {
        return initializeAndSet(
          snapshot,
          set,
          organisationLibraryLayersAtomFamily({ organisationId }),
          (curr) => [...curr, addedLayer],
        );
      },
    [organisationId],
  );

  const updateLayerLocal = useRecoilCallback(
    ({ set, snapshot }) =>
      async (updatedLayer: Partial<DataLibraryLayer>) => {
        return initializeAndSet(
          snapshot,
          set,
          organisationLibraryLayersAtomFamily({ organisationId }),
          (curr) => {
            return curr.map((layer) =>
              layer.id !== updatedLayer.id
                ? layer
                : { ...layer, ...updatedLayer },
            );
          },
        );
      },
    [organisationId],
  );

  const deleteLayerLocal = useRecoilCallback(
    ({ set, snapshot }) =>
      async (deletedLayerId: string) => {
        return initializeAndSet(
          snapshot,
          set,
          organisationLibraryLayersAtomFamily({ organisationId }),
          (curr) => {
            return curr.filter((layer) => layer.id !== deletedLayerId);
          },
        );
      },
    [organisationId],
  );

  const deleteLayers = useRecoilCallback(
    ({ set, snapshot }) =>
      async (layerIds: string[]) => {
        const fallback = await snapshot.getPromise(
          organisationLibraryLayersAtomFamily({ organisationId }),
        );

        const usage = await snapshot.getPromise(
          getDataLayersUsage({
            nodeId: organisationId,
            layerIds,
          }),
        );

        const totalUsage = Object.values(usage).flat();
        if (
          totalUsage.length !== 0 &&
          !window.confirm(
            `Layers are currently being used in ${totalUsage.length} projects, are you sure you want to remove access to it?`,
          )
        ) {
          return;
        } else if (
          totalUsage.length === 0 &&
          !window.confirm("Are you sure you want to delete these layers?")
        ) {
          return;
        }

        set(organisationLibraryLayersAtomFamily({ organisationId }), (curr) => {
          return curr.filter((layer) => !layerIds.includes(layer.id));
        });

        try {
          await deleteCustomOrganisationLayers(organisationId, layerIds);
          showSuccess(
            `${layerIds.length} ${layerIds.length !== 1 ? "layers were" : "layer was"} successfully deleted`,
          );
        } catch (err) {
          set(
            organisationLibraryLayersAtomFamily({ organisationId }),
            fallback,
          );
          showError(
            "Something went wrong when trying to delete the layers, please try again.",
          );
        }
      },
    [organisationId, showError, showSuccess],
  );

  const editLayerMetadata = useRecoilCallback(
    ({ set, snapshot }) =>
      async (
        layerId: string,
        newData: { name?: string; description?: string },
      ) => {
        const fallback = await snapshot.getPromise(
          organisationLibraryLayersAtomFamily({ organisationId }),
        );

        try {
          set(
            organisationLibraryLayersAtomFamily({ organisationId }),
            (curr) => {
              return curr.map((layer) => {
                if (layer.id === layerId) {
                  return {
                    ...layer,
                    ...newData,
                  };
                }
                return layer;
              });
            },
          );
          await editCustomOrganisationLayerMetadata(
            organisationId,
            layerId,
            newData,
          );

          showSuccess("The layer was successfully updated");
        } catch (err) {
          set(
            organisationLibraryLayersAtomFamily({ organisationId }),
            fallback,
          );
          showError(
            "Something went wrong when updating the layer, please try again.",
          );
        }
      },
    [organisationId, showError, showSuccess],
  );

  const createPackage = useRecoilCallback(
    ({ set, snapshot }) =>
      async (organisationId: string, newData: { name?: string }) => {
        setLoading(true);
        setCreatingNewPackage(true);
        const fallback = await snapshot.getPromise(
          organisationLibraryDataPackageResourceState({ organisationId }),
        );

        try {
          const newPackage = await newOrganisationLibraryPackage(
            organisationId,
            {
              name: newData.name || "Untitled",
              type: "package",
            },
          );
          set(
            organisationLibraryDataPackageResourceState({ organisationId }),
            (curr) => {
              return [...curr, newPackage];
            },
          );
          return newPackage;
        } catch (err) {
          set(
            organisationLibraryDataPackageResourceState({ organisationId }),
            fallback,
          );
          showError(
            "Something went wrong when adding new package, please try again.",
          );
          scream("Error posting new package", { error: err });
        } finally {
          setLoading(false);
          setCreatingNewPackage(false);
        }
      },
    [setLoading, showError],
  );

  const deletePackage = useRecoilCallback(
    ({ set, snapshot }) =>
      async (organisationId: string, packageId: string) => {
        setIsDeletingPackage(true);
        const fallback = await snapshot.getPromise(
          organisationLibraryDataPackageResourceState({ organisationId }),
        );

        const usage = await snapshot.getPromise(
          getDataPackageUsage({
            organisationId,
            packageId,
          }),
        );

        const totalUsage = Object.values(usage).flat();
        if (
          totalUsage.length !== 0 &&
          !window.confirm(
            `Data package configuration is currently being used in ${totalUsage.length} projects, are you sure you want to delete it?`,
          )
        ) {
          setIsDeletingPackage(false);
          return;
        } else if (
          totalUsage.length === 0 &&
          !window.confirm("Are you sure you want to delete this package?")
        ) {
          setIsDeletingPackage(false);
          return;
        }

        set(
          organisationLibraryDataPackageResourceState({ organisationId }),
          (curr) => curr.filter((p) => p.id !== packageId),
        );

        try {
          await deleteOrganisationLibraryPackage(organisationId, packageId);
        } catch (err) {
          set(
            organisationLibraryDataPackageResourceState({ organisationId }),
            fallback,
          );
          showError(
            "Something went wrong when deleting the package, please try again.",
          );
          scream("Error posting new package", { error: err });
        } finally {
          setIsDeletingPackage(false);
        }
      },
    [setIsDeletingPackage, showError],
  );

  const addLayersToPackage = useRecoilCallback(
    ({ set, snapshot }) =>
      async (organisationId: string, packageId: string, layerIds: string[]) => {
        setAddingLayersToPackage(true);
        const fallback = await snapshot.getPromise(
          organisationLibraryDataPackageResourceState({ organisationId }),
        );
        const currentLayerIds =
          fallback.find((p) => p.id === packageId)?.layerIds || [];
        const newLayers = dedup([...currentLayerIds, ...layerIds]);

        set(
          organisationLibraryDataPackageResourceState({ organisationId }),
          (curr) =>
            curr.map((p) => {
              if (p.id === packageId) {
                return {
                  ...p,
                  layerIds: newLayers,
                };
              }
              return p;
            }),
        );

        try {
          if (newLayers.length !== currentLayerIds.length) {
            await updateOrganisationLibraryPackageLayerIds(
              organisationId,
              packageId,
              newLayers,
            );
          }
        } catch (err) {
          set(
            organisationLibraryDataPackageResourceState({ organisationId }),
            fallback,
          );
          showError(
            `Something went wrong when adding the layer${layerIds.length !== 0 ? "s" : ""} to the package`,
          );
          if (err instanceof Error) {
            scream(err, {
              message: "Error when adding layers to package",
              layerIds,
              packageId,
            });
          } else {
            scream(new Error("Error when adding layers to package"), {
              layerIds,
              packageId,
              error: err,
            });
          }
        } finally {
          setAddingLayersToPackage(false);
        }
      },
    [showError],
  );

  const removeLayersFromPackage = useRecoilCallback(
    ({ set, snapshot }) =>
      async (organisationId: string, packageId: string, layerIds: string[]) => {
        // setLoading(true);
        const fallback = await snapshot.getPromise(
          organisationLibraryDataPackageResourceState({ organisationId }),
        );

        const usage = await snapshot.getPromise(
          getDataLayersUsage({
            nodeId: organisationId,
            layerIds: layerIds,
          }),
        );

        const totalUsage = Object.values(usage).flat();
        if (
          totalUsage.length !== 0 &&
          !window.confirm(
            `Layers are currently being used in ${totalUsage.length} projects, are you sure you want to remove access to it?`,
          )
        ) {
          return;
        }

        const currentLayerIds =
          fallback.find((p) => p.id === packageId)?.layerIds || [];
        const newLayers = currentLayerIds.filter(
          (id) => !layerIds.includes(id),
        );

        set(
          organisationLibraryDataPackageResourceState({ organisationId }),
          (curr) =>
            curr.map((p) => {
              if (p.id === packageId) {
                return {
                  ...p,
                  layerIds: newLayers,
                };
              }
              return p;
            }),
        );

        try {
          await updateOrganisationLibraryPackageLayerIds(
            organisationId,
            packageId,
            newLayers as any,
          );
        } catch (err) {
          set(
            organisationLibraryDataPackageResourceState({ organisationId }),
            fallback,
          );
          showError(
            `Something went wrong when removing the layer${layerIds.length !== 0 ? "s" : ""} from the package`,
          );
          if (err instanceof Error) {
            scream(err, {
              message: "Error when removing layers to package",
              layerIds,
              packageId,
            });
          } else {
            scream(new Error("Error when removing layers to package"), {
              layerIds,
              packageId,
              error: err,
            });
          }
        } finally {
          // setLoading(false);
        }
      },
    [showError],
  );

  const updatePackage = useRecoilCallback(
    ({ set, snapshot }) =>
      async (
        organisationId: string,
        packageId: string,
        newData: NewLayersLibraryPackage,
      ) => {
        setLoading(true);
        const fallback = await snapshot.getPromise(
          organisationLibraryDataPackageResourceState({ organisationId }),
        );

        try {
          set(
            organisationLibraryDataPackageResourceState({ organisationId }),
            (curr) => {
              return curr.map((p) =>
                p.id === packageId ? { ...p, ...newData } : p,
              );
            },
          );
          await updateOrganisationLibraryPackage(
            organisationId,
            packageId,
            newData,
          );
        } catch (err) {
          set(
            organisationLibraryDataPackageResourceState({ organisationId }),
            fallback,
          );
          showError(
            "Something went wrong when adding new package, we have been notified.",
          );
          scream("Error posting new package", { error: err });
        } finally {
          setLoading(false);
        }
      },
    [setLoading, showError],
  );

  const createPackageLocal = useRecoilCallback(
    ({ set }) =>
      async (newPackage: DataLibraryPackage) => {
        set(
          organisationLibraryDataPackageResourceState({ organisationId }),
          (curr) => [...curr, newPackage],
        );
      },
    [organisationId],
  );

  const updatePackageLocal = useRecoilCallback(
    ({ set }) =>
      async (updatedPackage: DataLibraryPackage) => {
        set(
          organisationLibraryDataPackageResourceState({ organisationId }),
          (curr) =>
            curr.map((p) => (p.id === updatedPackage.id ? updatedPackage : p)),
        );
      },
    [organisationId],
  );

  const deletePackageLocal = useRecoilCallback(
    ({ set }) =>
      async (deletedPackageId: string) => {
        set(
          organisationLibraryDataPackageResourceState({ organisationId }),
          (curr) => curr.filter((p) => p.id !== deletedPackageId),
        );
      },
    [organisationId],
  );

  return {
    refreshLayers,
    deletePackage,
    deletePackageLocal,
    createPackage,
    createPackageLocal,
    creatingNewPackage,
    updatePackage,
    updatePackageLocal,
    editLayerMetadata,
    deleteLayer,
    deleteLayerLocal,
    addLayerLocal,
    updateLayerLocal,
    deleteLayers,
    addLayersToPackage,
    addingLayersToPackage,
    removeLayersFromPackage,
    loading,
    isDeletingPackage,
  };
};
