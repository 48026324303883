import Dropdown from "components/Dropdown/Dropdown";
import Button from "components/General/Button";
import { Label } from "components/General/Form";
import { useState } from "react";
import styled from "styled-components";
import { StandardBox } from "styles/boxes/Boxes";
import { spaceLarge, spaceMedium } from "styles/space";
import { DEFAULT_TURBINES, TurbineType, _TurbineType } from "types/turbines";
import Close from "@icons/24/Close.svg?react";
import { Row } from "components/General/Layout";
import { IconBtn } from "components/General/Icons";
import { InputDimensioned } from "components/General/Input";
import { HeaderTitle } from "components/RightSide/InfoModal/style";
import { fetchEnhancerWithToken } from "services/utils";
import { between } from "utils/validations";
import {
  ARTICLE_GENERIC_TURBINE,
  HelpLink,
} from "components/HelpTooltip/HelpTooltip";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { scream } from "utils/sentry";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";

export const WizardContainer = styled(StandardBox)`
  display: flex;
  flex-direction: column;
  width: 30rem;
  padding: 2rem;
  height: fit-content;
  gap: 1.2rem;
  background: white;
`;

export const TurbineWizard = ({
  close,
  create,
  setLoading,
}: {
  close: () => void;
  setLoading: (v: boolean) => void;
  create: ({
    turbine,
    name,
  }: {
    turbine: TurbineType;
    name?: string;
  }) => Promise<any>;
}) => {
  const [status, setStatus] = useState<"failed" | "loading" | null>(null);
  const [selected, setSelected] = useState("iea_15MW");
  const [wantedMW, setWantedMW] = useState(18);
  return (
    <WizardContainer>
      <Row style={{ alignItems: "center" }}>
        <HeaderTitle>Create turbine</HeaderTitle>
        <HelpLink article={ARTICLE_GENERIC_TURBINE} />
        <IconBtn
          size="1.4rem"
          onClick={close}
          style={{ marginLeft: "auto" }}
          disabled={status === "loading"}
        >
          <Close />
        </IconBtn>
      </Row>
      <Label>
        <p>Create from</p>
        <Dropdown
          id="turbine-generation-options"
          value={selected}
          onChange={(e) => {
            const id = e.target.value;
            if (id === "generic") {
              setWantedMW(18);
            } else {
              const turbine = DEFAULT_TURBINES.find((t) => t.id === id)!;
              setWantedMW(turbine.ratedPower! / 1000);
            }
            setSelected(id);
          }}
        >
          {DEFAULT_TURBINES.filter((t) => !t.archived).map((t) => {
            return (
              <option key={t.id} value={t.id}>
                {t.name}
              </option>
            );
          })}
          <option key={"generic"} value={"generic"}>
            Create generic...
          </option>
        </Dropdown>
      </Label>
      {selected === "generic" && (
        <Label>
          <p>Wanted turbine size</p>
          <InputDimensioned
            value={wantedMW}
            units={["MW"]}
            onChange={(value) => setWantedMW(value)}
            validate={between(2, 30)}
            validationMessage="Must be between 2 and 30MW"
          ></InputDimensioned>
        </Label>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          alignItems: "baseline",
          gap: spaceMedium,
          paddingTop: spaceLarge,
        }}
      >
        <Button
          disabled={status === "loading"}
          style={{ alignSelf: "end" }}
          text="Create"
          onClick={() => {
            setLoading(true);
            setStatus("loading");
            if (selected === "generic") {
              getGenericTurbine(wantedMW * 1000).then((res) => {
                if (res === "failed") {
                  setStatus("failed");
                  setLoading(false);
                  return;
                }
                create({ turbine: res, name: res.name }).then(() => {
                  setStatus(null);
                  setLoading(false);
                });
              });
            } else {
              const turbine = DEFAULT_TURBINES.find((t) => t.id === selected)!;
              create({ turbine }).then(() => {
                setStatus(null);
                setLoading(false);
              });
            }
          }}
        />
      </div>
      {status === "loading" && <SkeletonBlock style={{ height: "2rem" }} />}
      {status === "failed" && (
        <SimpleAlert
          text={"Failed generating turbine"}
          type={"error"}
        ></SimpleAlert>
      )}
    </WizardContainer>
  );
};

async function getGenericTurbine(
  power: number,
): Promise<TurbineType | "failed"> {
  return fetchEnhancerWithToken(`/api/octopus/turbine/${power}`, {})
    .then((res) => res.json())
    .then(_TurbineType.parse)
    .catch(() => {
      scream("Failed getting generic turbine");
      return "failed";
    });
}
