import { selectorFamily } from "recoil";
import { CostType } from "../../../../services/costService";
import {
  allFloaterTypesSelector,
  getScalesForTurbineTypeIdsAndFoundationIds,
  hasFloatingFoundationSelector,
  hasJacketSelector,
  hasMonopileSelector,
  isTurbineFeatureWithFoundation,
  turbineTypeAndFloatingFoundationCombinations,
} from "../../../../state/foundations";
import { getTurbinesInBranchSelectorFamily } from "../../../../state/layout";
import { TurbineFeature } from "../../../../types/feature";
import {
  getJacketTotalsSelectorFamily,
  getMonopileTotalsSelectorFamily,
} from "../../../RightSide/InfoModal/FoundationModal/fixed/state";
import { getFloatingFoundationTotalsSelectorFamily } from "../../../RightSide/InfoModal/FoundationModal/floating/state";
import { valueRounding } from "../../../RightSide/InfoModal/FoundationModal/utils";

import { AmountUnit } from "../../../../types/financial";
import { Amount, amountAddId, amountId } from "../generalAmounts";
import { pointInPolygon } from "utils/geometry";
import {
  analysisOverrideInputAtomFamily,
  getBranchId,
  getCapacity,
  getPark,
  getParkId,
} from "components/ProductionV2/state";
import { FinanceId, financeOverrideInputState } from "components/Finance/state";

const jacketAmountSelectorFamily = selectorFamily<
  Amount[],
  //@ts-ignore
  {
    turbines: TurbineFeature[];
    triggerId: FinanceId;
  }
>({
  key: "jacketAmountSelectorFamily",
  get:
    ({ turbines, triggerId }) =>
    ({ get }) => {
      const parkId = get(getParkId(triggerId));
      const { rasterId } = get(financeOverrideInputState(triggerId));

      const layoutFoundations =
        turbines?.filter((t) => t.properties.foundationId) ?? [];

      const { totalPrimarySteelMass } = get(
        getJacketTotalsSelectorFamily({
          parkId,
          tempLayoutFoundations: layoutFoundations,
          rasterId: rasterId,
        }),
      );

      const totalsWithTonnes = {
        totalPrimarySteelMass: totalPrimarySteelMass / 1000,
      };

      const steel = amountAddId(
        {
          unit: AmountUnit.t,
          amount: valueRounding(totalsWithTonnes.totalPrimarySteelMass, 100),
          category: CostType.Foundation,
        },
        "Primary steel jacket",
      );

      return [steel];
    },
});

const monopileAmountSelectorFamily = selectorFamily<
  Amount[],
  //@ts-ignore
  {
    turbines: TurbineFeature[];
    triggerId: FinanceId;
  }
>({
  key: "monopileAmountSelectorFamily",
  get:
    ({ turbines, triggerId }) =>
    ({ get }) => {
      const parkId = get(getParkId(triggerId));
      const { rasterId } = get(financeOverrideInputState(triggerId));

      const layoutFoundations =
        turbines?.filter((t) => t.properties.foundationId) ?? [];

      const { totalPrimarySteelMass } = get(
        getMonopileTotalsSelectorFamily({
          parkId,
          tempLayoutFoundations: layoutFoundations,
          rasterId: rasterId,
        }),
      );

      const totalsWithTonnes = {
        totalPrimarySteelMass: totalPrimarySteelMass / 1000,
      };

      const steel = amountAddId(
        {
          unit: AmountUnit.t,
          amount: valueRounding(totalsWithTonnes.totalPrimarySteelMass, 100),
          category: CostType.Foundation,
        },
        "Primary steel monopile",
      );

      return [steel];
    },
});

const floatingFoundationAmountSelectorFamily = selectorFamily<
  Amount[],
  //@ts-ignore
  TurbineFeature[]
>({
  key: "floatingFoundationAmountSelectorFamily",
  get:
    (turbines) =>
    ({ get }) => {
      const layoutFoundations =
        turbines?.filter((t) => t.properties.foundationId) ?? [];

      const allFloaters = get(allFloaterTypesSelector);

      const turbineTypeIdAndFloatingFoundationIdCombinations =
        turbineTypeAndFloatingFoundationCombinations(
          layoutFoundations,
          allFloaters,
        );

      const scales = get(
        getScalesForTurbineTypeIdsAndFoundationIds(
          turbineTypeIdAndFloatingFoundationIdCombinations,
        ),
      );

      const {
        totalPrimarySteelMass,
        totalPrimaryConcreteVolume,
        totalRebarMass,
        totalPostTensMass,
        totalSolidBallastMass,
      } = get(
        getFloatingFoundationTotalsSelectorFamily({
          tempLayoutFoundations: layoutFoundations,
          scales,
          turbineTypeIdAndFloatingFoundationIdCombinations,
        }),
      );

      const totalsWithTonnes = {
        totalPrimarySteelMass: totalPrimarySteelMass / 1000,
        totalPrimaryConcreteVolume,
        totalRebarMass: totalRebarMass / 1000,
        totalPostTensMass: totalPostTensMass / 1000,
        totalSolidBallastMass: totalSolidBallastMass / 1000,
      };

      const steel = amountAddId(
        {
          unit: AmountUnit.t,
          amount: valueRounding(totalsWithTonnes.totalPrimarySteelMass, 100),
          category: CostType.Foundation,
        },
        "Primary steel floating",
      );

      const concrete = amountAddId(
        {
          unit: AmountUnit.m3,
          amount: valueRounding(
            totalsWithTonnes.totalPrimaryConcreteVolume,
            100,
          ),
          category: CostType.Foundation,
        },
        "Concrete",
      );

      const reinforcement = amountAddId(
        {
          unit: AmountUnit.t,
          amount: valueRounding(totalsWithTonnes.totalRebarMass, 100),
          category: CostType.Foundation,
        },
        "Reinforcement",
      );

      const cables = amountAddId(
        {
          unit: AmountUnit.t,
          amount: valueRounding(totalsWithTonnes.totalPostTensMass, 100),
          category: CostType.Foundation,
        },
        "Post tension cables",
      );

      const ballast = amountAddId(
        {
          unit: AmountUnit.t,
          amount: valueRounding(totalsWithTonnes.totalSolidBallastMass, 100),
          category: CostType.Foundation,
        },
        "Solid ballast",
      );

      return [steel, concrete, reinforcement, cables, ballast];
    },
});

export const foundationAmountSelectorFamily = selectorFamily<
  Amount[],
  FinanceId
>({
  key: "foundationAmountSelectorFamily",
  get:
    (id) =>
    ({ get }) => {
      const branchId = get(getBranchId(id));
      const parkId = get(getParkId(id));

      const park = get(getPark(id));

      const input = get(analysisOverrideInputAtomFamily(id));

      const turbines = get(
        getTurbinesInBranchSelectorFamily({
          parkId,
          branchId,
          turbineTypeOverride: input.turbineTypeOverride,
        }),
      );

      const validTurbines = turbines.filter((t) =>
        pointInPolygon(t.geometry, park.geometry),
      );
      const turbinesWithFoundation =
        validTurbines?.filter(isTurbineFeatureWithFoundation) ?? [];

      const hasFloatingFoundation = get(hasFloatingFoundationSelector);
      const hasJacketFoundation = get(hasJacketSelector);
      const hasMonopileFoundation = get(hasMonopileSelector);

      const turbinesWithFloatingFoundations = turbinesWithFoundation.filter(
        hasFloatingFoundation,
      );

      const turbinesWithJacketFoundations =
        turbinesWithFoundation.filter(hasJacketFoundation);
      const turbinesWithMonopileFoundations = turbinesWithFoundation.filter(
        hasMonopileFoundation,
      );

      const floatingAmounts = get(
        floatingFoundationAmountSelectorFamily(turbinesWithFloatingFoundations),
      );

      const jacketAmounts = get(
        jacketAmountSelectorFamily({
          turbines: turbinesWithJacketFoundations,
          triggerId: id,
        }),
      );

      const monopileAmounts = get(
        monopileAmountSelectorFamily({
          turbines: turbinesWithMonopileFoundations,
          triggerId: id,
        }),
      );

      const capacity = get(getCapacity(id));

      const perMwAmount = amountAddId({
        unit: AmountUnit.MW,
        amount: turbinesWithFoundation.length > 0 ? capacity ?? 0 : 0,
        category: CostType.Foundation,
      });

      const foundationCount = amountAddId({
        unit: AmountUnit.unit,
        amount: turbinesWithFoundation.length,
        category: CostType.Foundation,
      });

      const fixedAmount = {
        id: amountId({
          unit: AmountUnit.fixed,
          category: CostType.Foundation,
        }),
        unit: AmountUnit.fixed,
        amount: 1,
        category: CostType.Foundation,
      };

      return [
        ...floatingAmounts,
        ...jacketAmounts,
        ...monopileAmounts,
        perMwAmount,
        foundationCount,
        fixedAmount,
      ];
    },
});
