import { useEffect, useState } from "react";
import { modalTypeOpenAtom } from "../../state/modal";
import { reloadForNewVersionModalType } from "../ReloadForNewVersionModal/ReloadForNewVersionModal";
import { useSetRecoilState } from "recoil";
import { scream } from "../../utils/sentry";

const minute = 60_000;

const parseVersion = (version: string) =>
  version.split(".").map((v) => parseInt(v));

const FrontEndVersionPoller = () => {
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const [isSeen, setIsSeen] = useState(false);

  useEffect(() => {
    if (
      !["app.vind.ai", "app.vindtech.com"].includes(window.location.host) ||
      isSeen
    )
      return;

    const checkVersion = async () => {
      const clientVersion = import.meta.env.VITE_VERSION;
      if (!clientVersion) return;
      const [ours] = parseVersion(clientVersion);
      const [latest] = await fetch("/version.txt")
        .then((r) => r.text())
        .then((t) => t.trim())
        .catch(() => clientVersion) // We don't mind failing
        .then(parseVersion);

      if (latest <= ours) return;

      setModalTypeOpen({ modalType: reloadForNewVersionModalType });
      setIsSeen(true);
    };

    const id = window.setInterval(() => {
      try {
        checkVersion();
      } catch (e) {
        scream("Failed to check for new version", { e });
      }
    }, minute);

    return () => {
      window.clearInterval(id);
    };
  }, [setModalTypeOpen, isSeen, setIsSeen]);

  return null;
};

export default FrontEndVersionPoller;
