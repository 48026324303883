/// <reference types="vite-plugin-svgr/client" />
import { useRecoilValue } from "recoil";
import { getParkFeaturesInBranchSelector } from "../../state/park";
import DropdownButton, {
  ButtonText,
  ButtonTextWrapper,
} from "../General/Dropdown/DropdownButton";
import { useMemo } from "react";
import { DropDownItem } from "../General/Dropdown/DropdownItems";
import { ParkFeature } from "../../types/feature";
import { scream } from "../../utils/sentry";
import PolygonIcon from "@icons/24/Polygon.svg?react";

export const ParkSelector = ({
  branch,
  park,
  setPark,
}: {
  branch?: string;
  park?: ParkFeature;
  setPark?: (park: ParkFeature) => void;
}) => {
  const parks = useRecoilValue(
    getParkFeaturesInBranchSelector({ branchId: branch ?? "" }),
  );

  const items: DropDownItem[] = useMemo(() => {
    return parks.map((park) => {
      return {
        value: park.id,
        name: park.properties.name ?? "unnamed park",
      };
    });
  }, [parks]);

  const selected = items.find((i) => i.value === park?.id);

  return (
    <DropdownButton
      items={items}
      onSelectItem={(e) => {
        const park = parks.find((p) => p.id === e);
        if (!park) throw scream("Selected impossible park", { parks, e });
        setPark?.(park);
      }}
      disabled={branch === undefined}
      buttonText={selected?.name ?? "Select park"}
      className="dashboard-dropdown"
      selectedItemValue={selected?.value}
      renderText={(e) => (
        <ButtonTextWrapper>
          <PolygonIcon />
          <ButtonText>{e}</ButtonText>
        </ButtonTextWrapper>
      )}
    />
  );
};
