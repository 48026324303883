import styled from "styled-components";
import { colors } from "../../styles/colors";
import { Frame } from "../General/Layout";
import { spacing5 } from "../../styles/space";

const DRAWER_WIDTH = "28rem";
const TRANSITION_TIME = "0.1s";

export const Wrapper = styled(Frame)`
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  > * {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.6rem;
  box-sizing: border-box;
`;

export const HeaderLeftSide = styled.div`
  display: flex;
  gap: 0.8rem;

  h3 {
    margin: 0;
  }
`;

export const HeaderRightSide = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing5};
  margin-right: ${spacing5};
  height: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

export const ParkPickerDrawerWrapper = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
  background-color: ${colors.focusBackground};
  box-sizing: border-box;
  transition: width ${TRANSITION_TIME} linear;
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 0.5rem 0rem;

  border-right: 1px solid ${colors.inputOutline};

  ${({ open }) => (open ? `width: ${DRAWER_WIDTH};` : `width: 7rem;`)}
`;

export const PaddingWrapper = styled.div`
  padding: 0 2.4rem;
`;

export const ParkPickerContentWrapper = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  width: calc(${DRAWER_WIDTH} - 4.8rem);
  transition: opacity ${TRANSITION_TIME} linear;
  overflow: hidden;
  ${({ open }) => (open ? `opacity: 1;` : `opacity: 0;`)};
  width: 100%;
`;

export const ChooseBranchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

export const SectionHeader = styled.h4`
  margin: 1rem 0 1rem 0;
`;

export const ParkList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  gap: 1.6rem;
  overflow-y: auto;
  padding: 0 2.4rem 6rem 2.4rem;
`;

export const ToggleParkPickerOpenWrapper = styled.div`
  position: absolute;
  bottom: 1.6rem;
  right: 1.6rem;
`;
