import styled from "styled-components";
import { colors } from "./colors";
import Tooltip from "components/General/Tooltip";

const Circle = styled.div<{ color: string }>`
  background: ${(p) => p.color};
  height: 1.4rem;
  width: 1.4rem;
  border-radius: 1rem;
  color: white;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-content: center;
`;

const Exclamation = styled.div`
  width: fit-content;
  height: fit-content;
  line-height: 1.3rem;
`;

export const WarningCircle = ({ title }: { title: string }) => (
  <Tooltip text={title}>
    <Circle color={colors.alert}>
      <Exclamation>!</Exclamation>
    </Circle>
  </Tooltip>
);
export const ErrorCircle = ({ title }: { title: string }) => (
  <Tooltip text={title}>
    <Circle color={colors.errorText}>
      <Exclamation>!</Exclamation>
    </Circle>
  </Tooltip>
);
