import { isDefined } from "../../../utils/predicates";
import { amountId } from "./generalAmounts";
import { CapexEntry, CostType } from "../../../services/costService";
import {
  AmountUnit,
  ConfidenceLevel,
  CostUnit,
  unitToAmountUnit,
} from "../../../types/financial";

export type Cost = CapexEntry & { amountId: string };

export type GeneralCost = {
  id: string;
  name: string;
  category: CostType;
  amount: number;
  amountUnit: AmountUnit;
  costPerAmount: number;
  cost: number;
  unit: CostUnit;
  confidenceLevel: ConfidenceLevel | undefined;
};

export const costId = ({
  category,
  costId,
  featureTypeId = undefined,
}: {
  category: CostType;
  costId: string;
  featureTypeId?: string;
}) =>
  [category, featureTypeId, costId]
    .filter(isDefined)
    .join("#")
    .replace("_", "")
    .toLocaleLowerCase();

export const costAddIds = (
  costWithoutId: Omit<Cost, "id" | "amountId">,
): Cost => ({
  ...costWithoutId,
  id: costId({
    costId: costWithoutId.name.toLowerCase(),
    category: costWithoutId.category,
  }),
  amountId: amountId({
    unit: unitToAmountUnit[costWithoutId.unit],
    category: costWithoutId.category,
    featureTypeId: costWithoutId.name,
  }),
});
