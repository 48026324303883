import { Feature, MultiPolygon, Polygon } from "geojson";
import * as turf from "@turf/turf";
import { ProjectFeature } from "types/feature";

export function intersectAll(
  features: Feature<Polygon | MultiPolygon>[],
): Feature | null {
  let result: Feature<Polygon | MultiPolygon> | null = features[0];
  for (const f of features.slice(1)) {
    result = turf.intersect(result, f);
    if (!result) return null;
  }
  return result;
}

export function differenceAll(
  features: Feature<Polygon | MultiPolygon>[],
): Feature | null {
  let result: Feature<Polygon | MultiPolygon> | null = features[0];
  for (const f of features.slice(1)) {
    result = turf.difference(result, f);
    if (!result) return null;
  }
  return result;
}

export function unionAll(
  features: Feature<Polygon | MultiPolygon>[],
): Feature | null {
  let result: Feature<Polygon | MultiPolygon> | null = features[0];
  for (const f of features.slice(1)) {
    result = turf.union(result, f);
    if (!result) return null;
  }
  return result;
}

/** Get the top center of the given feature */
export const getTopCenter = (selectedProjectFeature: ProjectFeature) => {
  const bbox = turf.bbox(selectedProjectFeature);
  return { lng: (bbox[0] + bbox[2]) / 2, lat: bbox[3] };
};

/** Get the center of the given feature */
export const getCenter = (selectedProjectFeature: ProjectFeature) => {
  const bbox = turf.bbox(selectedProjectFeature);
  return { lng: (bbox[0] + bbox[2]) / 2, lat: (bbox[1] + bbox[3]) / 2 };
};
