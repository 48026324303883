/// <reference types="vite-plugin-svgr/client" />
import React, { useCallback, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import { ProjectFeature } from "../../../types/feature";
import BinIcon from "@icons/24/Bin.svg?react";
import CopyIcon from "@icons/24/Copy.svg?react";
import InformationIcon from "@icons/24/Information.svg?react";
import useSystemSpecificUnicode from "../../../hooks/useSystemSpecificUnicode";
import { useDeleteFeaturesCallback } from "../../../hooks/deleteFeature";
import { projectFeaturesSelector } from "../../ProjectElements/state";
import { toastMessagesAtom } from "../../../state/toast";
import {
  copy,
  featureSupportsCopy,
} from "../../MapControls/CopyPasteSelectedFeature";
import { Menu, MenuItem } from "../../General/Menu";
import PasteRowMenuItem from "./shared/PasteRowMenuItem";
import SelectElementMenuItem from "./shared/SelectElementMenuItem";
import { colors } from "../../../styles/colors";
import { featureIsLocked } from "../../../utils/predicates";

const getMenuTitle = (nrSelectedFeatures: number) => {
  const menuTitle = `${nrSelectedFeatures} selected feature`;
  if (nrSelectedFeatures > 1) {
    return menuTitle.concat("s");
  }
  return menuTitle;
};

const OneOrMoreCanvasSelectionMenu = ({
  sampleWmsCallback,
  selectedProjectFeatures,
  closeMenu,
  featuresOnPoint,
  enableShowLayerInfo,
  onSelectFeature,
  onMouseEnterFeature,
  onMouseLeaveFeature,
}: {
  sampleWmsCallback: () => void;
  selectedProjectFeatures: ProjectFeature[];
  featuresOnPoint: MapboxGeoJSONFeature[];
  enableShowLayerInfo: boolean;
  closeMenu(): void;
  onSelectFeature(feature: MapboxGeoJSONFeature): void;
  onMouseEnterFeature(feature: MapboxGeoJSONFeature): void;
  onMouseLeaveFeature(feature: MapboxGeoJSONFeature): void;
}) => {
  const stringToUnicode = useSystemSpecificUnicode();
  const deleteFeatureWithChildren = useDeleteFeaturesCallback();
  const nonLockedFeatures = useMemo(
    () =>
      selectedProjectFeatures.filter((feature) => !featureIsLocked(feature)),
    [selectedProjectFeatures],
  );

  const deleteFeatures = useCallback(() => {
    deleteFeatureWithChildren(nonLockedFeatures.map((f) => f.id));
  }, [nonLockedFeatures, deleteFeatureWithChildren]);
  const projectFeatures = useRecoilValue(projectFeaturesSelector);
  const setToastMessagesAtom = useSetRecoilState(toastMessagesAtom);
  const copyCallback = useCallback(() => {
    copy(selectedProjectFeatures, [], projectFeatures, setToastMessagesAtom);
  }, [selectedProjectFeatures, projectFeatures, setToastMessagesAtom]);

  const isCopyEnabled = selectedProjectFeatures.some(featureSupportsCopy);

  const menuTitle = useMemo(
    () => getMenuTitle(selectedProjectFeatures.length),
    [selectedProjectFeatures],
  );

  return (
    <Menu style={{ boxShadow: "initial" }}>
      <MenuItem
        name={menuTitle}
        disabled={true}
        style={{
          borderBottom: `1px solid ${colors.inputOutline}`,
        }}
      />
      <MenuItem
        name={"Show layer info"}
        icon={<InformationIcon />}
        disabled={!enableShowLayerInfo}
        title={
          !enableShowLayerInfo
            ? "No WMS layers are visible in the map"
            : undefined
        }
        onClick={() => {
          sampleWmsCallback();
          closeMenu();
        }}
      />
      <MenuItem
        name={"Copy"}
        icon={<CopyIcon />}
        disabled={!isCopyEnabled}
        shortcut={`${stringToUnicode("command")}+C`}
        onClick={() => {
          copyCallback();
          closeMenu();
        }}
      />
      <PasteRowMenuItem closeMenu={closeMenu} />
      <SelectElementMenuItem
        onSelectFeature={onSelectFeature}
        onMouseEnterFeature={onMouseEnterFeature}
        onMouseLeaveFeature={onMouseLeaveFeature}
        closeMenu={closeMenu}
        features={featuresOnPoint}
      />
      <MenuItem
        name={"Delete"}
        disabled={nonLockedFeatures.length === 0}
        style={{ borderTop: `1px solid ${colors.inputOutline}` }}
        icon={<BinIcon />}
        shortcut={`${stringToUnicode("backspace")}`}
        onClick={() => {
          closeMenu();
          deleteFeatures();
        }}
      />
    </Menu>
  );
};

export default OneOrMoreCanvasSelectionMenu;
