/// <reference types="vite-plugin-svgr/client" />
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import { MenuFrame } from "../MenuPopup/CloseableMenuPopup";
import { ErrorInfo, Suspense } from "react";
import Tabs from "../General/Tabs";
import { ParkFeature } from "../../types/feature";
import {
  ARTICLE_FINANCIAL_ANALYSIS,
  HelpLink,
} from "../HelpTooltip/HelpTooltip";
import { Column, Row } from "../General/Layout";
import { SkeletonText } from "../Loading/Skeleton";
import Button from "../General/Button";
import { organisationIdSelector, useTypedPath } from "../../state/pathParams";
import ConfigurationIcon from "@icons/14/Configuration.svg?react";
import { editorAccessProjectSelector } from "../../state/user";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorBoundaryPrintOnly } from "../ErrorBoundaries/ErrorBoundaryLocal";
import { logError } from "../ErrorBoundaries/utils";
import {
  branchSelectedConfigurationAtomFamily,
  ProjectConfigModalTypeV2,
} from "../../state/configuration";
import { selectedWindConfigurationAtomFamily } from "../../state/windSourceConfiguration";
import { getBranchSelectorFamily, useUpdateBranch } from "../../state/timeline";
import { modalTypeOpenAtom } from "../../state/modal";
import {
  branchSelectedCostConfigurationAtom,
  costConfigurationUsageAtomFamily,
} from "../../state/costConfigurations";
import { Label } from "../General/Form";
import { COST_MENU_ID } from "../SettingsV2/ProjectConfiguration/Data/useCostConfiguration";
import { Lcoe } from "./Lcoe/Lcoe";
import { getInvalidTypesInParkAndBranch } from "state/layout";
import { InvalidTypesDisplayText } from "types/invalidTypes";
import {
  currentParkTriggerId,
  TriggerCurrentFinance,
} from "components/Finance/Triggers";
import { CAPEX } from "./Capex/Capex";
import { IRR } from "./IRR/IRR";
import { TopRightModeActiveAtom } from "components/RightSide/InfoModal/ProjectFeatureInfoModal/state";
import { TopRightMenuOptions } from "@constants/canvas";
import SelectFinancialConfigDropDown from "./SelectFinancialDropDown";
import { ParkHasAnyValidationError } from "components/ValidationWarnings/FeatureSpecificErrors";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { getStoppedReason } from "components/ProductionV2/state";
import { ValidationAlert } from "components/ValidationWarnings/ValidationAlert";
import { analysisStoppedText } from "components/ProductionV2/types";
import { spacing4 } from "styles/space";
import { InputTitle } from "components/General/GeneralSideModals.style";
import { Tag } from "components/GenerateWindTurbines/Tags";

const _onError = (error: Error, info: ErrorInfo) => {
  logError("Financial analysis", error, info);
};

const FinancialAnalysisInner = ({
  projectId,
  branchId,
}: {
  projectId: string;
  branchId: string;
}) => {
  const selectedAnalysisConfig = useRecoilValue(
    branchSelectedConfigurationAtomFamily({ projectId, branchId }),
  );

  const selectedWindConfig = useRecoilValue(
    selectedWindConfigurationAtomFamily({ projectId, branchId }),
  );

  const selectedCostConfig = useRecoilValue(
    branchSelectedCostConfigurationAtom({ projectId, branchId }),
  );

  const setTopRightModeActive = useSetRecoilState(TopRightModeActiveAtom);

  const id = currentParkTriggerId;
  const analysisStoppedReason = useRecoilValue(getStoppedReason(id));

  if (!selectedAnalysisConfig) {
    return (
      <div
        style={{
          padding: "1.6rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <SimpleAlert text={"No analysis config selected"} type={"error"} />

        <Button
          text={"Select in Production analysis"}
          onClick={() =>
            setTopRightModeActive(TopRightMenuOptions.productionStatistics)
          }
        />
      </div>
    );
  } else if (!selectedWindConfig) {
    return (
      <div
        style={{
          padding: "1.6rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <SimpleAlert text={"No wind config selected"} type={"error"} />
        <Button
          style={{ marginLeft: "1.6rem" }}
          text={"Select in Production analysis"}
          onClick={() =>
            setTopRightModeActive(TopRightMenuOptions.productionStatistics)
          }
        />
      </div>
    );
  } else if (!selectedCostConfig) {
    return <SimpleAlert text={"No cost config selected"} type={"error"} />;
  }

  if (analysisStoppedReason)
    return (
      <div>
        <ValidationAlert
          type={"error"}
          title="Analysis stopped"
          description={analysisStoppedText[analysisStoppedReason]}
        />
      </div>
    );
  return (
    <div style={{ display: "flex", overflowY: "hidden" }}>
      <Tabs
        tabs={[
          {
            name: "CAPEX",
            data: <CAPEX id={id} />,
          },
          {
            name: "LCoE",
            data: <Lcoe id={id} />,
          },
          {
            name: "IRR",
            data: <IRR id={id} />,
          },
        ]}
        menuStyle={{ justifyContent: "flex-start" }}
        wrapperStyle={{ minHeight: 0 }}
        contentWrapperStyle={{ padding: 0 }}
      />
    </div>
  );
};

function SelectCostConfiguration() {
  const organisationId = useRecoilValue(organisationIdSelector);
  const { projectId, branchId } = useTypedPath("projectId", "branchId");
  const editorAccessProject = useRecoilValue(editorAccessProjectSelector);

  const refreshCostUsage = useRecoilCallback(
    ({ refresh }) =>
      (costConfigId: string) => {
        refresh(
          costConfigurationUsageAtomFamily({
            nodeId: projectId,
            costConfigurationId: costConfigId,
          }),
        );
        organisationId &&
          refresh(
            costConfigurationUsageAtomFamily({
              nodeId: organisationId,
              costConfigurationId: costConfigId,
            }),
          );
      },
  );

  const liveBranchMeta = useRecoilValue(
    getBranchSelectorFamily({
      projectId,
      branchId,
    }),
  );
  const setModalType = useSetRecoilState(modalTypeOpenAtom);

  const selectedCostConfig = useRecoilValue(
    branchSelectedCostConfigurationAtom({ projectId, branchId }),
  );

  const selectedAnalysisConfig = useRecoilValue(
    branchSelectedConfigurationAtomFamily({ projectId, branchId }),
  );

  const selectedWindConfig = useRecoilValue(
    selectedWindConfigurationAtomFamily({ projectId, branchId }),
  );
  const updateBranch = useUpdateBranch();

  return (
    <Label>
      <Row
        style={{
          justifyContent: "left",
          flexWrap: "wrap",
          paddingBottom: "0.8rem",
        }}
      >
        <Tag>Park</Tag>
        {selectedAnalysisConfig && (
          <Tag>Analysis: {selectedAnalysisConfig?.name}</Tag>
        )}
        {selectedWindConfig && <Tag>Wind: {selectedWindConfig?.name}</Tag>}
      </Row>

      <InputTitle>Financial configuration</InputTitle>
      <Row style={{ margin: 0 }}>
        <SelectFinancialConfigDropDown
          selectedConfig={selectedCostConfig}
          projectId={projectId}
          style={{
            flex: 1,
          }}
          onSelectItem={(id) => {
            if (!liveBranchMeta) return;
            updateBranch({
              ...liveBranchMeta,
              costConfigurationId: id,
            }).then(() => {
              selectedCostConfig && refreshCostUsage(selectedCostConfig.id);
              refreshCostUsage(id);
            });
          }}
          disabled={!editorAccessProject}
        />
        <Button
          buttonType="secondary"
          icon={<ConfigurationIcon />}
          size="small"
          onClick={() => {
            setModalType({
              modalType: ProjectConfigModalTypeV2,
              metadata: {
                selectedMenuId: COST_MENU_ID,
                selectedConfigId: selectedCostConfig?.id,
              },
            });
          }}
        />
      </Row>
    </Label>
  );
}

export const FinancialAnalysisV2 = ({
  park,
  onClose,
}: {
  park: ParkFeature;
  onClose(): void;
}) => {
  const { projectId, branchId } = useTypedPath("projectId", "branchId");

  const invalidTypes = useRecoilValue(
    getInvalidTypesInParkAndBranch({ parkId: park.id, branchId }),
  );

  return (
    <MenuFrame
      style={{
        width: "36rem",
        maxHeight: "calc(100vh - 22rem)",
        gap: spacing4,
      }}
      icon={
        <>
          <HelpLink article={ARTICLE_FINANCIAL_ANALYSIS} />
        </>
      }
      title="Financial"
      onExit={onClose}
      validationError={
        <ParkHasAnyValidationError parkId={park.id} branchId={branchId} />
      }
    >
      {invalidTypes.length > 0 && (
        <InvalidTypesDisplayText invalidTypes={invalidTypes} />
      )}
      {invalidTypes.length === 0 && (
        <>
          <ErrorBoundary
            FallbackComponent={ErrorBoundaryPrintOnly}
            onError={_onError}
          >
            <Suspense
              fallback={
                <Column>
                  <SkeletonText />
                </Column>
              }
            >
              <SelectCostConfiguration />

              <TriggerCurrentFinance
                fallback={
                  <Column>
                    <SkeletonText />
                  </Column>
                }
              >
                <FinancialAnalysisInner
                  projectId={projectId}
                  branchId={branchId}
                />
              </TriggerCurrentFinance>
            </Suspense>
          </ErrorBoundary>
        </>
      )}
    </MenuFrame>
  );
};
