import React, { memo } from "react";
import styled, { keyframes, css } from "styled-components";
import usePrevious from "../../../hooks/usePrevious";
import { colors } from "../../../styles/colors";

const fillFromLeft = keyframes`
  from {
    width: 0;
    left: 0;
  }
  to {
    width: 100%;
    left: 0;
  }
`;

const fillFromRight = keyframes`
  from {
    width: 0;
    right: 0;
  }
  to {
    width: 100%;
    right: 0;
  }
`;

const emptyFromLeft = keyframes`
  from {
    width: 100%;
    left: 0;
  }
  to {
    width: 0;
    left: 0;
  }
`;

const emptyFromRight = keyframes`
  from {
    width: 100%;
    right: 0;
  }
  to {
    width: 0;
    right: 0;
  }
`;

const ProgressContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

interface BarProps {
  isActive: boolean;
  fillDirection:
    | "fill-left"
    | "fill-right"
    | "empty-left"
    | "empty-right"
    | null;
}

const Bar = styled.div<BarProps>`
  position: relative;
  flex: 1;
  height: 0.7rem;
  border-radius: 0.2rem;
  background-color: ${colors.hover};
  margin-right: 4px;

  &:last-child {
    margin-right: 0;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    background-color: ${colors.primaryHover};
    border-radius: 0.2rem;
    display: ${({ isActive }) => (isActive ? "block" : "block")};
    ${({ fillDirection }) =>
      fillDirection === "fill-left" &&
      css`
        animation: ${fillFromLeft} 0.5s forwards;
      `}

    ${({ fillDirection }) =>
      fillDirection === "fill-right" &&
      css`
        animation: ${fillFromRight} 0.5s forwards;
      `}

    ${({ fillDirection }) =>
      fillDirection === "empty-left" &&
      css`
        animation: ${emptyFromLeft} 0.5s forwards;
      `}

    ${({ fillDirection }) =>
      fillDirection === "empty-right" &&
      css`
        animation: ${emptyFromRight} 0.5s forwards;
      `}
  }
`;

function ProgressIndicator({ currentStep }: { currentStep: number }) {
  const prevStep = usePrevious(currentStep);

  return (
    <ProgressContainer>
      {[0, 1].map((step) => {
        const fillDirection =
          prevStep == null
            ? step === currentStep
              ? "fill-left"
              : null
            : step === currentStep && step > prevStep
              ? "fill-left"
              : step === currentStep && step < prevStep
                ? "fill-right"
                : step === currentStep - 1 && prevStep === step
                  ? "empty-right"
                  : step === currentStep + 1 && prevStep === step
                    ? "empty-left"
                    : null;

        return (
          <Bar
            key={step}
            isActive={step === currentStep}
            fillDirection={fillDirection}
          />
        );
      })}
    </ProgressContainer>
  );
}

export default memo(ProgressIndicator);
