import { organisationIdSelector, projectIdSelector } from "../state/pathParams";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { Types } from "ably";
import styled from "styled-components";
import Tooltip from "./General/Tooltip";
import { useMemo } from "react";
import {
  dateToDayMonthShortFormat,
  dedup,
  extractHHMMFromDate,
} from "../utils/utils";
import { projectPresenceAtomFamily } from "../state/ably";
import UserImageRound, {
  MoreUsersImageRound,
} from "./UserImage/UserImageRound";
import { SkeletonRound } from "./Loading/Skeleton";
import { BranchMeta } from "../types/api";
import { isDefined } from "../utils/predicates";
import { useUpdatePresence } from "hooks/useAbly";
import { nodeGroupUserAccessSelector } from "./Organisation/Groups/state";
import {
  UserNodeAccessWithMeta,
  isUserNodeAccessWithMeta,
} from "./Organisation/Groups/types";
import { Place } from "components/General/Anchor";
import { projectBranchesAtomFamily } from "state/timeline";
import { loggedInUserSelector } from "state/user";

const ProfileImageContainer = styled.div<{ itemGap: string }>`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  > *:not(:last-child) {
    margin-left: ${(p) => p.itemGap};
  }
  cursor: pointer;
`;

export function BranchPresenceWrapper({
  branch,
  itemGap = "-1.2rem",
  tooltipTheme = "dark",
}: {
  branch: BranchMeta;
  itemGap?: string;
  tooltipTheme?: "dark" | "light";
}) {
  const organisationId = useRecoilValue(organisationIdSelector);
  const projectId = useRecoilValue(projectIdSelector);

  const presenceData = useRecoilValue(
    projectPresenceAtomFamily({ nodeId: projectId ?? "" }),
  );

  const deduped = useMemo(
    () =>
      dedup(
        [...presenceData].filter((msg) => msg.data.branchId === branch.id),
        (item) => item.clientId,
      ),
    [branch.id, presenceData],
  );

  const peers = useMemo(() => {
    if (!organisationId || !projectId) return [];

    return deduped
      .sort((a, b) => a.timestamp - b.timestamp)
      .slice(0, 3)
      .map((msg) => (
        <NodePresenceImage
          tooltipTheme={tooltipTheme}
          organisationId={organisationId}
          nodeId={projectId}
          msg={msg}
          size={2.2}
          key={msg.id + msg.timestamp.toString()}
        />
      ));
  }, [organisationId, projectId, deduped, tooltipTheme]);

  const numberOfNotShown = Math.max(0, deduped.length - peers.length);

  return (
    <ProfileImageContainer itemGap={itemGap}>
      {numberOfNotShown > 0 && (
        <NotShownUsers number={numberOfNotShown} size={2.2} />
      )}
      {peers}
    </ProfileImageContainer>
  );
}

export function ProjectPresenceWrapper({
  nodeId,
  organisationId,
  size,
  nrToShow = 3,
  itemGap = "-1.2rem",
  showSelf = true,
  tooltipTheme = "dark",
  tooltipPosition,
}: {
  nodeId: string;
  organisationId: string;
  size?: number;
  nrToShow?: number;
  itemGap?: string;
  showSelf?: boolean;
  tooltipTheme?: "dark" | "light";
  tooltipPosition?: Place;
}) {
  useUpdatePresence(nodeId);
  const loggedInUser = useRecoilValue(loggedInUserSelector);
  const presenceData = useRecoilValue(
    projectPresenceAtomFamily({ nodeId: nodeId ?? "" }),
  );

  const deduped = useMemo(
    () => dedup([...presenceData], (item) => item.clientId),
    [presenceData],
  );

  const dedupedMaybeWithoutCurrentUser = useMemo(() => {
    if (showSelf) {
      return deduped;
    }
    return deduped.filter(
      (msg) => showSelf || msg.clientId !== loggedInUser?.user_id,
    );
  }, [deduped, loggedInUser?.user_id, showSelf]);

  const peers = useMemo(() => {
    if (!organisationId) return [];
    return dedupedMaybeWithoutCurrentUser
      .sort((a, b) => a.timestamp - b.timestamp)
      .slice(0, nrToShow)
      .map((msg) => (
        <NodePresenceImage
          tooltipTheme={tooltipTheme}
          tooltipPosition={tooltipPosition}
          organisationId={organisationId}
          nodeId={nodeId}
          msg={msg}
          key={msg.id + msg.timestamp.toString()}
          size={size}
        />
      ));
  }, [
    organisationId,
    dedupedMaybeWithoutCurrentUser,
    nrToShow,
    tooltipTheme,
    tooltipPosition,
    nodeId,
    size,
  ]);

  const numberOfNotShown = Math.max(
    0,
    dedupedMaybeWithoutCurrentUser.length - peers.length,
  );

  return (
    <ProfileImageContainer itemGap={itemGap}>
      {numberOfNotShown > 0 && (
        <NotShownUsers number={numberOfNotShown} size={size} />
      )}
      {peers}
    </ProfileImageContainer>
  );
}

const ActiveWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0.6rem; /* Match the size of div #3 */
  width: 0.6rem;
  position: absolute;
  bottom: 0.1rem;
  right: 0.1rem;
  border-radius: 0.6rem;
  background-color: #00ff00;
`;

export function NodePresenceImage({
  organisationId,
  nodeId,
  msg,
  tooltipTheme,
  tooltipPosition = "right",
  size = 2.8,
}: {
  organisationId: string;
  nodeId: string;
  msg: Types.PresenceMessage;
  tooltipTheme: "dark" | "light";
  tooltipPosition?: Place;
  size?: number;
}) {
  const users = useRecoilValueLoadable(
    nodeGroupUserAccessSelector({ organisationId, nodeId }),
  ).valueMaybe();
  const branchesInfo = useRecoilValue(projectBranchesAtomFamily({ nodeId }));

  const userInfo = users
    ?.filter(isUserNodeAccessWithMeta)
    .find((u: UserNodeAccessWithMeta) => u.user_id === msg.clientId);

  const lastActive = useMemo(() => {
    const date = new Date(msg.timestamp);
    return dateToDayMonthShortFormat(date) + ", " + extractHHMMFromDate(date);
  }, [msg.timestamp]);

  if (!isDefined(userInfo)) {
    return <SkeletonRound size={size * 10} />;
  }

  const branchName = branchesInfo.find(
    (b) => b.id === msg.data.branchId,
  )?.title;
  return (
    <Tooltip
      secondary
      theme={tooltipTheme}
      text={[
        userInfo.nickname,
        `${
          msg.data.status === "active"
            ? "Active now."
            : `Last active: ${lastActive}.`
        }`,
        branchName ? "In branch " + branchName : "",
      ]}
      position={tooltipPosition}
    >
      <div style={{ position: "relative", display: "flex" }}>
        <UserImageRound user={userInfo} size={size} title="" />
        {msg.data.status === "active" && <ActiveWrapper />}
      </div>
    </Tooltip>
  );
}

function NotShownUsers({
  number,
  size = 2.8,
}: {
  number: number;
  size?: number;
}) {
  return (
    <Tooltip
      secondary
      text={`${number} more users active in the project`}
      position="right"
    >
      <MoreUsersImageRound
        size={size}
        title={`+ ${number}`}
        style={{ marginLeft: "0.8rem" }}
      />
    </Tooltip>
  );
}
