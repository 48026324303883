/// <reference types="vite-plugin-svgr/client" />

import { useRecoilValue } from "recoil";
import { typography } from "../../../../../styles/typography";
import { GridWithFourColumns } from "../../styles";
import styled from "styled-components";
import { colors } from "styles/colors";
import { localCostConfigurationAtom } from "../../state";
import { CostConfiguration } from "services/costService";

const Title = styled.div`
  ${typography.sub3}
`;

const BlueDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.blue500}};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: fit-content;
`;

export const CostTopRow = () => {
  const localConfig: CostConfiguration = useRecoilValue(
    localCostConfigurationAtom,
  );
  const useConfidenceLevel = localConfig.useConfidenceLevel;
  return (
    <Wrapper>
      <GridWithFourColumns style={{ padding: 0 }}>
        <Title>Type</Title>
        <Title>Cost</Title>
        {useConfidenceLevel ? <Title>Confidence level</Title> : <div></div>}
      </GridWithFourColumns>
      <BlueDivider />
    </Wrapper>
  );
};
