import React, { useRef, useCallback } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useDrop } from "react-dnd";
import { Mixpanel } from "mixpanel";
import AddIcon from "@icons/24/Add.svg";
import BinIcon from "@icons/24/Bin.svg";
import DatabaseIcon from "@icons/24/Database.svg";
import GlobeIcon from "@icons/24/Globe.svg";
import OpenLeft from "@icons/24/OpenLeft.svg";
import { IconREMSize, typography } from "styles/typography";
import { colors } from "styles/colors";
import { spacing10, spacing8, spacing4 } from "styles/space";
import { Column } from "components/General/Layout";
import useOpenUploadModalOnDragFile from "components/UploadModal/useOpenUploadModalOnDragFile";
import Button from "components/General/Button";
import { useHorizontalResize } from "components/ResizeBar/ResizeBarVertical";
import { MenuItem } from "components/General/Menu";
import { DotMenu } from "components/General/MenuButton";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { organisationIdSelector } from "state/pathParams";
import { useRecoilValueDef } from "utils/recoil";
import Spinner from "@icons/spinner/Spinner";
import { EditableTextInternalState } from "components/General/EditableText";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import AddLayersFromAllDataListModal from "./modals/AddLayersFromAllDataListModal";
import {
  TabContainer,
  ResourceColumn,
  MainRow,
  ResourceItem,
  ResourceName,
  ResourceSectionHeader,
  ResourceSectionDivider,
} from "../style";
import { organisationLibraryDataPackageResourceState } from "../state";
import PackageSection from "./PackageSection/PackageSection";
import AllDataSection from "./AllDataSection/AllDataSection";
import ConnectExternalModal from "./ConnectExternal/Modal";
import { DataLibraryNewEntry } from "./DataLibraryTab.style";
import UploadOrganisationLayerModal, {
  UploadOrganisationDataLayerModalType,
} from "./modals/UploadOrganisationLayerModal";
import UploadOrganisationLayerFromCoordinatesModal from "./modals/UploadOrganisationLayerFromCoordinatesModal";
import EditVectordata, { EditVectordataType } from "./Edit/EditVectordata";
import { DraggedLayersProps } from "./AllDataSection/DataTable";
import { useDataLibraryLayersCrud } from "./useDataLibraryLayersCrud";
import { activePackageIdAtom } from "./state";
import { modalTypeOpenAtom } from "state/modal";

const PackageResourceItem = ({
  packageId,
  name,
  selected,
  onClick,
}: {
  packageId: string;
  name: string;
  selected: boolean;
  onClick(): void;
}) => {
  const {
    loading,
    deletePackage,
    updatePackage,
    addLayersToPackage,
    isDeletingPackage,
  } = useDataLibraryLayersCrud();
  const organisationId = useRecoilValueDef(organisationIdSelector);
  const ref = useRef<HTMLDivElement>(null);
  const [dropCollection, dropRef] = useDrop<
    DraggedLayersProps,
    void,
    {
      isHovered: boolean;
    }
  >(
    () => ({
      accept: "ORGANISATION_LAYER",
      collect: (monitor) => {
        const isHovered = monitor.isOver() && monitor.canDrop();
        return {
          isHovered,
        };
      },
      drop: (draggedItem) => {
        const { layerIds } = draggedItem;
        addLayersToPackage(organisationId, packageId, layerIds);
      },
    }),
    [addLayersToPackage, organisationId, packageId],
  );

  const renamePackage = useCallback(
    (newName: string) =>
      updatePackage(organisationId, packageId, { name: newName }),
    [updatePackage, organisationId, packageId],
  );
  const setContent = useSetRecoilState(
    organisationRightSideModal(organisationId),
  );

  dropRef(ref);

  return (
    <ResourceItem
      ref={ref}
      selected={selected}
      onClick={onClick}
      isDragHovered={dropCollection.isHovered}
    >
      <IconREMSize
        height={1.6}
        width={1.6}
        stroke={selected ? colors.blue600 : undefined}
      >
        <DatabaseIcon />
      </IconREMSize>
      <EditableTextInternalState
        type={"text"}
        value={name}
        onEnter={renamePackage}
        textContainerStyle={{
          ...typography.contentAndButtons,
          margin: 0,
        }}
        style={{
          width: "12rem",
        }}
      >
        <ResourceName>{name}</ResourceName>
      </EditableTextInternalState>
      {isDeletingPackage ? (
        <Spinner
          size={"1rem"}
          style={{
            marginLeft: "auto",
          }}
        />
      ) : (
        <DotMenu
          divStyle={{
            marginLeft: "auto",
            width: "2.5rem",
          }}
        >
          <MenuItem
            name={"Open info"}
            onClick={() => setContent({ type: "resource", id: packageId })}
            icon={<OpenLeft />}
          />
          <MenuItem
            name={"Delete"}
            onClick={() => {
              deletePackage(organisationId, packageId);
            }}
            disabled={loading}
            icon={<BinIcon />}
          />
        </DotMenu>
      )}
    </ResourceItem>
  );
};

const PackagesResourceItems = ({
  activePackageId,
  setActivePackageId,
}: {
  activePackageId: string | undefined;
  setActivePackageId(packageId: string): void;
}) => {
  const organisationId = useRecoilValueDef(organisationIdSelector);
  const packages = useRecoilValue(
    organisationLibraryDataPackageResourceState({ organisationId }),
  );
  const { loading, createPackage } = useDataLibraryLayersCrud();

  if (packages.length === 0) {
    return (
      <DataLibraryNewEntry
        icon={<DatabaseIcon />}
        title="Create packages"
        text="Share the packages across projects"
        actionButton={
          <Button
            disabled={loading}
            text="New package"
            icon={loading ? <Spinner size={"1.2rem"} /> : <AddIcon />}
            onClick={async () => {
              const result = await createPackage(organisationId, {
                name: "Untitled",
              });
              if (result) {
                setActivePackageId(result.id);
              }
            }}
          />
        }
      />
    );
  }
  return (
    <>
      {packages.map((pkg) => (
        <PackageResourceItem
          key={pkg.id}
          packageId={pkg.id}
          name={pkg.name}
          selected={activePackageId === pkg.id}
          onClick={() => setActivePackageId(pkg.id)}
        />
      ))}
    </>
  );
};

export default function DataLibraryTab() {
  const [activePackageId, setActivePackageId] =
    useRecoilState(activePackageIdAtom);
  const addPackageButtonWrapperRef = useRef<HTMLDivElement>(null);

  const organisationId = useRecoilValueDef(organisationIdSelector);

  const { openUploadModal } = useOpenUploadModalOnDragFile({
    modalType: UploadOrganisationDataLayerModalType,
  });
  const modalTypeOpen = useRecoilValue(modalTypeOpenAtom);

  const elem = useRef<HTMLDivElement>(null);
  useHorizontalResize(elem, "--data-library-resource-list-width");

  const { loading, createPackage, creatingNewPackage, addLayersToPackage } =
    useDataLibraryLayersCrud();

  const [dropCollection, dropRef] = useDrop<
    DraggedLayersProps,
    void,
    {
      isHovered: boolean;
    }
  >(
    () => ({
      accept: "ORGANISATION_LAYER",
      collect: (monitor) => {
        const isHovered = monitor.isOver() && monitor.canDrop();
        return {
          isHovered,
        };
      },
      drop: (draggedItem) => {
        const { layerIds } = draggedItem;

        Mixpanel.track("Drag N drop layerIds to Create new package button", {
          nrLayers: layerIds.length,
        });
        createPackage(organisationId, { name: "Untitled" }).then((pkg) => {
          if (pkg) {
            addLayersToPackage(organisationId, pkg.id, layerIds).then(() => {
              setActivePackageId(pkg.id);
            });
          }
        });
      },
    }),
    [addLayersToPackage, createPackage, organisationId, setActivePackageId],
  );

  dropRef(addPackageButtonWrapperRef);

  return (
    <>
      <UploadOrganisationLayerModal />
      <UploadOrganisationLayerFromCoordinatesModal />
      <AddLayersFromAllDataListModal />
      <TabContainer
        onDragOver={(e) => {
          if (modalTypeOpen?.modalType === EditVectordataType) return;
          openUploadModal(e);
        }}
      >
        <MainRow>
          <ResourceColumn
            ref={elem}
            style={{
              gap: spacing4,
              overflowY: "auto",
              width: "35rem",
              flexShrink: 0,
            }}
          >
            <ResourceItem
              selected={activePackageId === undefined}
              onClick={() => {
                setActivePackageId(undefined);
              }}
            >
              <IconREMSize
                height={1.6}
                width={1.6}
                stroke={
                  activePackageId === undefined ? colors.blue600 : undefined
                }
              >
                <GlobeIcon />
              </IconREMSize>
              <ResourceName>All data</ResourceName>
            </ResourceItem>
            <ResourceSectionDivider />
            <ResourceSectionHeader>Packages</ResourceSectionHeader>
            <div
              ref={addPackageButtonWrapperRef}
              style={{
                width: "100%",
                backgroundColor: dropCollection.isHovered
                  ? colors.selected
                  : undefined,
              }}
            >
              <Button
                buttonType="secondary"
                text="New package"
                disabled={loading || creatingNewPackage}
                icon={
                  loading || creatingNewPackage ? (
                    <Spinner size={"1.2rem"} />
                  ) : (
                    <AddIcon />
                  )
                }
                onClick={async () => {
                  const result = await createPackage(organisationId, {
                    name: "Untitled",
                  });
                  if (result) {
                    setActivePackageId(result.id);
                  }
                }}
              />
            </div>
            <React.Suspense
              fallback={
                <>
                  <SkeletonBlock
                    style={{
                      height: "4rem",
                    }}
                  />
                  <SkeletonBlock
                    style={{
                      height: "4rem",
                    }}
                  />
                  <SkeletonBlock
                    style={{
                      height: "4rem",
                    }}
                  />
                  <SkeletonBlock
                    style={{
                      height: "4rem",
                    }}
                  />
                </>
              }
            >
              <PackagesResourceItems
                setActivePackageId={setActivePackageId}
                activePackageId={activePackageId}
              />
            </React.Suspense>
          </ResourceColumn>

          <Column
            style={{
              gap: spacing10,
              flexGrow: 1,
              padding: `${spacing8} ${spacing8}`,
            }}
          >
            <React.Suspense
              fallback={
                <SkeletonBlock
                  style={{
                    width: "100%",
                    height: "30rem",
                  }}
                />
              }
            >
              {typeof activePackageId !== "undefined" ? (
                <PackageSection
                  packageId={activePackageId}
                  setActivePackageId={setActivePackageId}
                />
              ) : (
                <AllDataSection />
              )}
            </React.Suspense>
          </Column>
          <ConnectExternalModal />
          <EditVectordata />
        </MainRow>
      </TabContainer>
    </>
  );
}
