import { atom, selectorFamily, atomFamily } from "recoil";
import {
  ProjectElementFolderType,
  listProjectElementsFoldersVersions,
  ProjectElementFolderKeyVersion,
  getProjectElementsFolders,
  getProjectElementsSortOrder,
  ProjectElementSortOrder,
} from "./service";
import { isDefined } from "utils/predicates";
import { ProjectFeature } from "types/feature";
import {
  bathymetryLayerFeaturesSelector,
  canvasLayerFeaturesSelector,
  existingTurbinesFeaturesSelector,
  georefImagesLayerFeaturesSelector,
  gridConnectionFeaturesSelector,
  portFeaturesSelector,
  viewPointsFeaturesSelector,
} from "state/projectLayers";
import { getParkFeaturesSelector } from "state/park";
import { exclusionZoneSelector } from "state/division";
import { ElementTreeNode, ElementTreeRoot } from "./types";

export const projectFoldersAtomFamily = atomFamily<
  ProjectElementFolderType[],
  {
    projectId: string;
    branchId: string;
    version?: number;
  }
>({
  key: "projectFoldersAtomFamily",
  default: selectorFamily({
    key: "projectFoldersAtomFamilySelector",
    get:
      ({ projectId, branchId, version }) =>
      async ({ get }) => {
        get(projectFoldersRefreshAtom);
        const folders = await getProjectElementsFolders(
          projectId,
          branchId,
          version,
        );

        return folders.sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0));
      },
  }),
});

export const projectFoldersRefreshAtom = atom({
  key: "projectFoldersRefreshAtom",
  default: 1,
});

export const getProjectElementFoldersKeyVersions = selectorFamily<
  ProjectElementFolderKeyVersion[],
  {
    projectId: string;
    branchId: string;
  }
>({
  key: "getProjectElementFoldersKeyVersions",
  get: (o) => () => {
    return listProjectElementsFoldersVersions(o.projectId, o.branchId);
  },
});

/**
 * Sort order of top level elements in a branch.
 */
export const getProjectElementsSortOrderAtomFamily = atomFamily<
  ProjectElementSortOrder[],
  {
    nodeId: string;
    branchId: string;
  }
>({
  key: "getProjectElementsSortOrderAtomFamily",
  default: ({ branchId, nodeId }) => {
    return getProjectElementsSortOrder(nodeId, branchId);
  },
});

const elementFeatures = selectorFamily<ProjectFeature[], {}>({
  key: "elementFeatures",
  get:
    () =>
    ({ get }) => {
      // TODO: branchId and version(?) here
      let features: ProjectFeature[] = [];
      features = features
        .concat(get(getParkFeaturesSelector))
        .concat(get(canvasLayerFeaturesSelector))
        .concat(get(bathymetryLayerFeaturesSelector))
        .concat(get(georefImagesLayerFeaturesSelector))
        .concat(get(viewPointsFeaturesSelector))
        .concat(get(portFeaturesSelector))
        .concat(get(existingTurbinesFeaturesSelector))
        .concat(get(gridConnectionFeaturesSelector))
        .concat(get(exclusionZoneSelector));
      return features;
    },
});

/**
 * Gets a tree representation of the Elements list.
 */
export const elementTreeSelectorFamily = selectorFamily<
  ElementTreeRoot,
  { nodeId: string; branchId: string; version?: number }
>({
  key: "elementTreeSelectorFamily",
  get:
    ({ nodeId, branchId, version }) =>
    ({ get }) => {
      const folders = get(
        projectFoldersAtomFamily({ projectId: nodeId, branchId, version }),
      );
      const features = get(elementFeatures({ branchId }));

      const root: ElementTreeRoot = { type: "root", children: [] };

      // Construct a node for every folder and feature (that should be a node)
      const id2node = new Map<string, ElementTreeNode>(
        folders
          .map<[string, ElementTreeNode]>((f) => [
            f.folderId,
            {
              type: "folder" as const,
              id: f.folderId,
              folder: f,
              children: [],
              parent: root,
            },
          ])
          .concat(
            features.map((feature) => [
              feature.id,
              {
                type: "feature" as const,
                id: feature.id,
                feature,
                parent: root,
              },
            ]),
          ),
      );

      // Candidates for nodes on the top level.
      const topLevelIds = new Set(
        folders.map((f) => f.folderId).concat(features.map((f) => f.id)),
      );
      const placed = new Set<string>(); // Safeguard, in case an ID appears in multiple folders

      for (const folder of folders) {
        const f = id2node.get(folder.folderId);
        if (!f || f.type !== "folder") continue; // NOTE: f is always a folder, but TS doesn't know it
        // NOTE: This is where we decide the ordering of the folder contents.
        // It's just the `featureIds` array.
        f.children = folder.featureIds
          .map((o) => {
            if (o.id === f.id) return;
            if (placed.has(o.id))
              // Check that we only insert a node once in the tree
              return;
            placed.add(o.id);
            topLevelIds.delete(o.id); // register this id as in a folder
            const node = id2node.get(o.id);
            if (node) node.parent = f;
            return node;
          })
          .filter(isDefined);
      }

      // Sort the top level, since this is handled by a separate endpoint
      const sortOrder = get(
        getProjectElementsSortOrderAtomFamily({ nodeId, branchId }),
      );
      const id2order = new Map(sortOrder.map((so) => [so.id, so.sortOrder]));

      const topNodes = [...topLevelIds.values()]
        .map((id) => id2node.get(id))
        .filter(isDefined);

      topNodes.sort((a, b) => {
        const ka = id2order.get(a.id) ?? -1;
        const kb = id2order.get(b.id) ?? -1;
        return ka - kb;
      });

      root.children = topNodes;

      return root;
    },
});
