import styled, { keyframes } from "styled-components";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div<{ size?: string; color?: string }>`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);

  border-top: 2px solid ${({ color = "grey" }) => color};
  border-right: 2px solid ${({ color = "grey" }) => color};
  border-bottom: 2px solid ${({ color = "grey" }) => color};
  border-left: 2px solid black;
  padding: 2px 2px 2px 2px;
  background: transparent;
  width: ${({ size = "2.4rem" }) => size};
  height: ${({ size = "2.4rem" }) => size};
  border-radius: 50%;
  flex-shrink: 0;
`;

export default Spinner;
