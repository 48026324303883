import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { ReactNode, useCallback } from "react";
import { useNavigate } from "react-router-dom";

export default function Auth0ProviderWithCallback({
  children,
}: {
  children: ReactNode;
}) {
  const navigate = useNavigate();

  const onRedirectCallback = useCallback(
    (appState?: AppState) => {
      navigate(appState?.redirectUrl || window.location.pathname);
    },
    [navigate],
  );

  return (
    <Auth0Provider
      domain="vindtech.eu.auth0.com"
      clientId="3E7lyrhVyGdDmRdjVdefoSCwNUOO8Wmt"
      redirectUri={window.location.origin}
      audience="app.vind.ai/api/spa"
      scope="openid profile email read:current_user"
      useRefreshTokens={true}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
}
