import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import StackedUserImages from "components/UserImage/StackedUserImages";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { memberInOrganisationSelectorFamily } from "state/user";
import { nodeGroupUserAccessSelector } from "components/Organisation/Groups/state";
import { useMemo } from "react";
import { isUserNodeAccessWithMeta } from "components/Organisation/Groups/types";

export function NodeAccessList({
  organisationId,
  nodeId,
  maxNumberOfUsers,
}: {
  organisationId: string;
  nodeId: string;
  maxNumberOfUsers?: number;
}) {
  const setContent = useSetRecoilState(
    organisationRightSideModal(organisationId),
  );
  const isMemberInOrg = useRecoilValue(
    memberInOrganisationSelectorFamily({ organisationId }),
  );

  const groupsAndUsersWithAccess = useRecoilValue(
    nodeGroupUserAccessSelector({ organisationId, nodeId }),
  );
  const usersWithAccess = useMemo(
    () => groupsAndUsersWithAccess.filter(isUserNodeAccessWithMeta) ?? [],
    [groupsAndUsersWithAccess],
  );

  return (
    <StackedUserImages
      maxNumberOfUsers={maxNumberOfUsers}
      onClick={
        isMemberInOrg
          ? () => setContent({ type: "project", id: nodeId })
          : undefined
      }
      users={usersWithAccess}
      size={2.5}
    />
  );
}
