import { ChangeEvent, useCallback, useEffect, useRef } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const DropFile = ({
  acceptedFileTypes,
  handleNewFiles,
  children,
}: {
  acceptedFileTypes: string[];
  handleNewFiles(files: File[]): void;
} & React.PropsWithChildren) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDrop = useCallback(async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.currentTarget.style.backgroundColor = "unset";
  }, []);

  const onFileChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      return handleNewFiles(Array.from(e.target.files ?? []));
    },
    [handleNewFiles],
  );

  useEffect(() => {
    const preventDefault = (e: DragEvent) => {
      e.preventDefault();
    };

    const onDrop = (e: DragEvent) => {
      if (!e.dataTransfer) {
        return;
      }
      e.preventDefault();

      if (!e.dataTransfer.types.includes("Files") || !fileInputRef.current) {
        return;
      }
      return handleNewFiles(Array.from(e.dataTransfer.files));
    };

    document.addEventListener("dragover", preventDefault);
    document.addEventListener("dragleave", preventDefault);
    document.addEventListener("drop", onDrop);
    return () => {
      document.removeEventListener("dragover", preventDefault);
      document.removeEventListener("dragleave", preventDefault);
      document.removeEventListener("drop", onDrop);
    };
  }, [handleNewFiles]);

  return (
    <>
      <input
        multiple
        ref={fileInputRef}
        onChange={onFileChange}
        type="file"
        accept={acceptedFileTypes.join(",")}
        style={{ display: "none" }}
      />
      <Wrapper
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDragLeave={(e) => {
          e.preventDefault();
        }}
        onDrop={handleDrop}
      >
        {children}
      </Wrapper>
    </>
  );
};
