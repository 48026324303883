import {
  fetchEnhancer,
  fetchEnhancerWithToken,
  fetchSchemaWithToken,
} from "./utils";
import { GeoTiffUserUploadedImageType } from "./types";
import { ProjectFeature } from "../types/feature";
import { z } from "zod";
import {
  PROJECT_SERVICE_API_PATH,
  PROJECT_SERVICE_API_VERSION,
} from "../components/ProjectElements/service";
import {
  _BranchMeta,
  _SnapshotMeta,
  BranchMetaUpdate,
  SnapshotMeta,
  SnapshotMetaUpdate,
  _ProjectFeatures,
  _PublicProjectBranchMeta,
  _PublicProjectMeta,
  ProjectFollow,
  _ProjectFollow,
  _BranchCount,
} from "../types/api";
import { scream } from "../utils/sentry";
import { GeotiffTypes } from "../types/feature";
import { checkAllDoParse } from "utils/geojson/validate";

export const THIRTY_MEGABYTES = 1024 * 1024 * 30;

export const PROJECT_DATA_API_VERSION = "3.0.0";

// Does no longer contain actual project data (features), see PROJECT_DATA_API_PATH_V4
export const PROJECT_DATA_API_PATH_V3 = "/api/project-data-v3";

// -------- Projects --------

export const getProjectImage = async (
  nodeId: string,
  branchId: string,
  parkId?: string,
): Promise<string | undefined> =>
  fetchEnhancerWithToken(
    `${PROJECT_SERVICE_API_PATH}/node/${nodeId}/image/${branchId}`.concat(
      parkId ? `/${parkId}` : "",
    ),
    {
      method: "get",
      headers: {
        "x-project-data-client-version": PROJECT_SERVICE_API_VERSION,
      },
    },
  ).then((res) =>
    res.status === 204 ? undefined : (res.json() as Promise<string>),
  );

// -------- Branches --------

const _createBranch = z.object({
  features: _ProjectFeatures,
  meta: _BranchMeta,
  snapshots: z.array(z.object({ meta: _SnapshotMeta })),
});

export const createBranch = async (
  nodeId: string,
  title: string,
  features: ProjectFeature[],
): Promise<z.infer<typeof _createBranch>> => {
  const res = await fetchEnhancerWithToken(
    `${PROJECT_SERVICE_API_PATH}/node/${nodeId}/branch`,
    {
      method: "post",
      body: JSON.stringify({ title, features }),
      headers: {
        "Content-Type": "application/json",
        "x-project-data-client-version": PROJECT_SERVICE_API_VERSION,
      },
    },
  );
  const branch = _createBranch.parse(await res.json());

  return branch;
};

const _duplicateBranch = z.object({
  features: _ProjectFeatures,
  meta: _BranchMeta,
  snapshots: _SnapshotMeta.array(),
});
export const duplicateBranch = async (
  nodeId: string,
  branchId: string,
  snapshotId?: string,
): Promise<z.infer<typeof _duplicateBranch>> => {
  const res = await fetchEnhancerWithToken(
    `${PROJECT_SERVICE_API_PATH}/node/${nodeId}/branch/${branchId}/duplicate${
      snapshotId ? `?snapshotId=${snapshotId}` : ""
    }`,
    {
      method: "post",
      headers: {
        "x-project-data-client-version": PROJECT_SERVICE_API_VERSION,
      },
    },
  );
  return _duplicateBranch.parse(await res.json());
};
export const restoreVersionAsBranch = async (
  nodeId: string,
  branchId: string,
  version: number,
): Promise<z.infer<typeof _duplicateBranch>> => {
  const res = await fetchEnhancerWithToken(
    `${PROJECT_SERVICE_API_PATH}/node/${nodeId}/branch/${branchId}/duplicate-from-version?version=${version}`,
    {
      method: "post",
      headers: {
        "x-project-data-client-version": PROJECT_SERVICE_API_VERSION,
      },
    },
  );
  return _duplicateBranch.parse(await res.json());
};

const _listBranches = z.object({ branches: _BranchMeta.array() });
export const listBranches = async (nodeId: string) =>
  fetchEnhancerWithToken(`${PROJECT_SERVICE_API_PATH}/node/${nodeId}/branch`, {
    method: "get",
    headers: {
      "x-project-data-client-version": PROJECT_SERVICE_API_VERSION,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      const { branches } = z
        .object({ branches: z.unknown().array() })
        .parse(res);
      const parsed = checkAllDoParse(branches, _BranchMeta, {
        source: "listBranches",
      });
      return { branches: parsed };
    });

export const deleteBranch = async (nodeId: string, branchId: string) =>
  fetchEnhancerWithToken(
    `${PROJECT_SERVICE_API_PATH}/node/${nodeId}/branch/${branchId}`,
    {
      method: "delete",
      headers: {
        "x-project-data-client-version": PROJECT_SERVICE_API_VERSION,
      },
    },
  );

const _updateBranch = z.object({ meta: _BranchMeta });
export const updateBranchMeta = async (
  nodeId: string,
  branchId: string,
  update: BranchMetaUpdate,
) =>
  fetchEnhancerWithToken(
    `${PROJECT_SERVICE_API_PATH}/node/${nodeId}/branch/${branchId}`,
    {
      method: "put",
      body: JSON.stringify(update),
      headers: {
        "Content-Type": "application/json",
        "x-project-data-client-version": PROJECT_SERVICE_API_VERSION,
      },
    },
  )
    .then((res) => res.json())
    .then(_updateBranch.parse);

const _sortBranchesResponse = z.array(z.string());
export const sortBranches = async (nodeId: string, sortOrder: string[]) =>
  fetchEnhancerWithToken(
    `${PROJECT_SERVICE_API_PATH}/node/${nodeId}/branch/sort`,
    {
      method: "post",
      body: JSON.stringify({ sortOrder }),
      headers: {
        "Content-Type": "application/json",
        "x-project-data-client-version": PROJECT_SERVICE_API_VERSION,
      },
    },
  )
    .then((res) => res.json())
    .then(_sortBranchesResponse.parse);

// -------- Snapshots --------

const _createSnapshot = z.object({
  meta: _SnapshotMeta,
});
export const createSnapshot = async (
  nodeId: string,
  branchId: string,
  snapshotMeta: Partial<SnapshotMeta>,
  version?: number,
): Promise<z.infer<typeof _createSnapshot>> => {
  const searchParams: Record<string, string> = {};
  if (version) {
    searchParams["version"] = version.toString();
  }
  const res = await fetchEnhancerWithToken(
    `${PROJECT_SERVICE_API_PATH}/node/${nodeId}/branch/${branchId}/snapshot?${new URLSearchParams(
      searchParams,
    )}`,
    {
      method: "post",
      body: JSON.stringify(snapshotMeta),
      headers: {
        "Content-Type": "application/json",
        "x-project-data-client-version": PROJECT_SERVICE_API_VERSION,
      },
    },
  )
    .then((res) => res.json())
    .then(_createSnapshot.parse);

  return res;
};

const _listSnapshots = z.object({ snapshots: _SnapshotMeta.array() });
export const listSnapshots = async (nodeId: string, branchId: string) =>
  fetchEnhancerWithToken(
    `${PROJECT_SERVICE_API_PATH}/node/${nodeId}/branch/${branchId}/snapshot`,
    {
      method: "get",
      headers: {
        "x-project-data-client-version": PROJECT_SERVICE_API_VERSION,
      },
    },
  )
    .then((res) => res.json())
    .then(_listSnapshots.parse);

const _updateSnapshots = z.object({ meta: _SnapshotMeta });

export const updateSnapshotMeta = async (
  nodeId: string,
  branchId: string,
  snapshotId: string,
  update: Partial<SnapshotMetaUpdate>,
): Promise<{ meta: SnapshotMeta }> =>
  fetchEnhancerWithToken(
    `${PROJECT_SERVICE_API_PATH}/node/${nodeId}/branch/${branchId}/snapshot/${snapshotId}`,
    {
      method: "put",
      body: JSON.stringify(update),
      headers: {
        "Content-Type": "application/json",
        "x-project-data-client-version": PROJECT_SERVICE_API_VERSION,
      },
    },
  )
    .then((res) => res.json())
    .then(_updateSnapshots.parse);

export const deleteSnapshot = async (
  nodeId: string,
  branchId: string,
  snapshotId: string,
) =>
  fetchEnhancerWithToken(
    `${PROJECT_SERVICE_API_PATH}/node/${nodeId}/branch/${branchId}/snapshot/${snapshotId}`,
    {
      method: "delete",
      headers: {
        "x-project-data-client-version": PROJECT_SERVICE_API_VERSION,
      },
    },
  );

// -------- Features --------

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const getGeoTiffPresignedUrl = async (
  nodeId: string,
  fileName: string,
  type: GeotiffTypes,
) =>
  fetchEnhancerWithToken(
    `${PROJECT_DATA_API_PATH_V3}/${
      type === GeoTiffUserUploadedImageType ? "georefimage" : "bathymetry"
    }/${nodeId}/${fileName}`,
    {
      method: "get",
      headers: {
        "x-project-data-client-version": PROJECT_DATA_API_VERSION,
      },
    },
  )
    .then((res) => res.json())
    .then(z.string().parse);

export const isProjectBranchPublic = async (nodeId: string, branchId: string) =>
  await fetchEnhancerWithToken(
    `${PROJECT_DATA_API_PATH_V3}/public/metadata/node/${nodeId}/${branchId}`,
    {
      method: "get",
      headers: {
        "x-project-data-client-version": PROJECT_DATA_API_VERSION,
      },
    },
  )
    .then((res) => res.json())
    .then(_PublicProjectBranchMeta.parse);

export const getProjectPublicSettings = async (nodeId: string) =>
  await fetchSchemaWithToken(
    _PublicProjectMeta,
    `${PROJECT_DATA_API_PATH_V3}/public/settings/withtoken/${nodeId}`,
    {
      method: "get",
      headers: {
        "x-project-data-client-version": PROJECT_DATA_API_VERSION,
      },
    },
  );

export const setPublicProjectAccess = async (
  nodeId: string,
  branchId: string,
  access: boolean,
) => {
  await fetchEnhancerWithToken(
    `${PROJECT_DATA_API_PATH_V3}/public/metadata/${nodeId}/${branchId}`,
    {
      method: "post",
      headers: {
        "x-project-data-client-version": PROJECT_DATA_API_VERSION,
      },
      body: JSON.stringify({
        is_public: access,
      }),
    },
  );
};

export const setPublicProjectGreeting = async (
  nodeId: string,
  greetingTitle: string,
  greetingText: string,
) =>
  await fetchEnhancerWithToken(
    `${PROJECT_DATA_API_PATH_V3}/public/settings/${nodeId}`,
    {
      method: "post",
      headers: {
        "x-project-data-client-version": PROJECT_DATA_API_VERSION,
      },
      body: JSON.stringify({
        greeting_title: greetingTitle,
        greeting_text: greetingText,
      }),
    },
  );

export const setPublicProjectSetting = async (
  nodeId: string,
  settings: Record<string, any>,
) =>
  await fetchEnhancerWithToken(
    `${PROJECT_DATA_API_PATH_V3}/public/settings/${nodeId}`,
    {
      method: "post",
      headers: {
        "x-project-data-client-version": PROJECT_DATA_API_VERSION,
      },
      body: JSON.stringify(settings),
    },
  );

export const getGeotiffImage = async (
  nodeId: string,
  filename: string,
  type: GeotiffTypes,
) => {
  const presignedUrl = await getGeoTiffPresignedUrl(nodeId, filename, type);

  try {
    const res = await fetchEnhancer(presignedUrl, { method: "get" });
    if (!res.ok) {
      throw new Error(
        `Could not load geotiff image, reason: ${res.statusText} (${res.status}) (${nodeId}/${filename})`,
      );
    }
    const blob = await res.blob();
    return blob;
  } catch (e) {
    scream("getGeotiffImage failed", { e });
  }
};

export const getUserProjectFollows = async (): Promise<ProjectFollow[]> => {
  try {
    const follows = fetchSchemaWithToken(
      _ProjectFollow.array(),
      `${PROJECT_DATA_API_PATH_V3}/project/follow`,
      {
        method: "get",
      },
    );
    return follows;
  } catch {
    return [];
  }
};
export const putProjectFollow = async (
  nodeId: string,
  body: { follow: boolean },
): Promise<ProjectFollow> => {
  return fetchSchemaWithToken(
    _ProjectFollow,
    `${PROJECT_DATA_API_PATH_V3}/project/${nodeId}/follow`,
    {
      method: "put",
      body: JSON.stringify(body),
    },
  );
};

export const getProjectBranchCount = async (
  nodeId: string,
): Promise<number> => {
  try {
    const response = await fetchSchemaWithToken(
      _BranchCount,
      `${PROJECT_SERVICE_API_PATH}/node/${nodeId}/branch/count`,
      {
        method: "get",
        headers: {
          "x-project-data-client-version": PROJECT_DATA_API_VERSION,
        },
      },
    );
    return response.count;
  } catch {
    return 0;
  }
};
