import { defaultPointCircleRadius } from "components/MapFeatures/expressionUtils";
import {
  DEFAULT_IN_FOCUS_OPACITY,
  DEFAULT_NOT_IN_FOCUS_OPACITY,
  DEFAULT_SELECTED_OPACITY,
  displayLabelPropertyName,
  lockedPropertyName,
} from "@constants/canvas";
import { SymbolLayer } from "mapbox-gl";
import { useEffect } from "react";
import { colors } from "styles/colors";
import { SubstationFeature } from "types/feature";
import { safeRemoveLayer } from "utils/map";
import { addLayer, removeCodepointsFromFeatures } from "./utils";
import {
  substationSymbolLayerId,
  substationSourceId,
  substationLayerId,
} from "./constants";

export const substationPaint: mapboxgl.CirclePaint = {
  "circle-color": [
    "case",
    ["boolean", ["feature-state", "outside"], false],
    colors.redAlert,
    colors.substation,
  ],
  "circle-opacity": [
    "case",
    [
      "all",
      [
        "boolean",
        ["feature-state", "hover"],
        ["feature-state", "selected"],
        false,
      ],
      ["==", ["get", lockedPropertyName], false],
    ],
    DEFAULT_SELECTED_OPACITY,
    ["boolean", ["feature-state", "inFocus"], false],
    DEFAULT_IN_FOCUS_OPACITY,
    DEFAULT_NOT_IN_FOCUS_OPACITY,
  ],
  "circle-radius": defaultPointCircleRadius,
  "circle-stroke-color": [
    "case",
    ["==", ["get", lockedPropertyName], true],
    colors.lockedFeatureOutline,
    ["!=", ["feature-state", "borderColor"], null],
    ["feature-state", "borderColor"],
    ["boolean", ["feature-state", "selected"], false],
    "#ffffff",
    colors.substation,
  ],
  "circle-stroke-width": [
    "case",
    ["==", ["get", lockedPropertyName], true],
    2.0,
    ["!=", ["feature-state", "borderColor"], null],
    2.0,
    ["boolean", ["feature-state", "selected"], false],
    2.0,
    ["boolean", ["feature-state", "hover"], false],
    2.0,
    0.0,
  ],
};

const substationSymbolLayer: SymbolLayer = {
  id: substationSymbolLayerId,
  source: substationSourceId,
  type: "symbol",
  minzoom: 8,
  layout: {
    "symbol-placement": "point",
    "text-field": "{name}",
    "text-offset": [0, -2],
    "text-size": 8,
    "text-anchor": "center",
  },
  filter: ["boolean", ["get", displayLabelPropertyName], true],
};

export const RenderSubstations = ({
  substations: features,
  map,
  symbols,
}: {
  substations: SubstationFeature[];
  map: mapboxgl.Map;
  symbols?: boolean;
}) => {
  useEffect(() => {
    map.addSource(substationSourceId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    addLayer(map, {
      id: substationLayerId,
      type: "circle",
      source: substationSourceId,
      paint: substationPaint,
    });
    if (symbols) {
      addLayer(map, substationSymbolLayer);
    }

    return () => {
      safeRemoveLayer(map, substationLayerId);
      if (symbols) {
        safeRemoveLayer(map, substationSymbolLayer.id);
      }
      map.removeSource(substationSourceId);
    };
  }, [map, symbols]);

  useEffect(() => {
    const source = map.getSource(substationSourceId);
    if (source?.type !== "geojson") return;
    source.setData({
      type: "FeatureCollection",
      features: removeCodepointsFromFeatures(features),
    });
  }, [map, features]);

  return null;
};
