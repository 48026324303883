/// <reference types="vite-plugin-svgr/client" />
import { useCallback, useEffect, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useSetPropertyOnProjectFeatures } from "../../hooks/useSetPropertyOnProjectFeatures";
import {
  editFeaturesAtom,
  mapInteractionSelector,
  mapRefAtom,
} from "../../state/map";
import { currentSelectionArrayAtom } from "../../state/selection";
import AddCircle from "@icons/24/AddCircle.svg?react";
import Pen from "@icons/24/Pencil.svg?react";
import Splitter from "@icons/splitter/splitter.svg?react";
import Combine from "@icons/24/Union.svg?react";
import Cabling from "@icons/24/Cabling.svg?react";
import Intersect from "@icons/24/Intersect.svg?react";
import Difference from "@icons/24/Difference.svg?react";
import DnDIconSmall from "@icons/12/DnDsmall.svg?react";
import LockIcon from "@icons/24/Lock.svg?react";
import UnlockIcon from "@icons/24/Unlock.svg?react";
import { editorAccessProjectSelector } from "../../state/user";
import { inReadOnlyModeSelector } from "../../state/project";
import * as turf from "@turf/turf";
import { useDeleteFeaturesCallback } from "../../hooks/deleteFeature";
import { v4 as uuidv4 } from "uuid";
import { toastMessagesAtom } from "../../state/toast";
import { keyEventTargetIsInput } from "../../utils/keyboard";
import Tooltip from "../General/Tooltip";
import { vecAdd } from "../../utils/geometry";
import {
  BathymetryUserUploadedType,
  GeoTiffUserUploadedImageType,
} from "../../services/types";
import { ProjectFeature } from "../../types/feature";
import {
  DEFAULT_CANVAS_LAYER_COLOR,
  lockedPropertyName,
} from "../../constants/canvas";
import TurbineTypeSelector from "./TurbineTypeSelector";
import { getParkFeatureSelectorFamily, inPark } from "../../state/park";
import { TypeSelector } from "./TypeSelector/TypeSelector";
import ConnectAnchorOption from "./ConnectAnchorOption/ConnectAnchorOption";
import { BufferSelector } from "./CanvasSingleSelectOption";
import {
  featureIsLocked,
  isAnchor,
  isCable,
  isCableChain,
  isCablePartition,
  isDefined,
  isExclusionDivision,
  isExportCable,
  isLineStringFeature,
  isMooringLine,
  isMultiPolygonFeature,
  isNotGeometryCollection,
  isPolygonFeature,
  isSubArea,
  isSubstation,
  isTurbine,
} from "../../utils/predicates";
import { makeAnchorFeature } from "../../types/turbines";
import { AnchorFeature } from "../../types/feature";
import CableTypeSelector from "./CableTypeSelector";
import { useProjectElementsCrud } from "../ProjectElements/useProjectElementsCrud";
import { projectFeaturesSelector } from "../ProjectElements/state";
import { Feature, MultiPolygon } from "@turf/turf";
import { trackCanvasOption } from "./MenuTracking";
import {
  branchIdSelector,
  parkIdSelector,
  projectIdSelector,
  useTypedPath,
} from "../../state/pathParams";
import FoundationTypeSelector from "./FoundationTypeSelector";
import { PolygonFeature } from "../../types/feature";
import { MultiPolygonFeature } from "../../types/feature";
import { Polygon, Position } from "geojson";
import { ParkFeature } from "../../types/feature";
import { resetListIfNotAlreadyEmpty } from "../../utils/resetList";
import StylingSelector from "../StylingSelector/StylingSelector";
import MooringLineTypeSelector from "./MooringLineTypeSelector";
import PositionHint from "../ActiveTips/PositionHints/PositionHint";
import { splitMultiFeatureHelpHintType } from "../ActiveTips/PositionHints/SplitMultiFeatureHelp";
import SplitMultiPart from "./SplitMultiPart";
import { cableEditModeAtom } from "../Cabling/Generate/state";
import { multiFeatureToFeatures } from "../../utils/geojson/utils";
import { parseOr } from "../../utils/zod";
import { _FeatureType } from "../../types/feature";
import { SubstationTypeSelector } from "./SubstationTypeSelector";
import { ExportCableTypeSelector } from "./ExportCableTypeSelector";
import { Popup } from "components/Mapbox/Popup";
import { Divider, IconBtn, IconMenu } from "components/General/Icons";
import { dedup, roundToDecimal } from "utils/utils";
import {
  NamedTooltipWrapper,
  ToolsWrapper,
} from "components/CanvasSelectOption/CanvasSelectOption.style";
import { IconREMSize } from "styles/typography";
import useMoveablePopup from "./useMoveablePopup";
import { useToast } from "hooks/useToast";
import ExistingTurbineTypeSelector from "./ExistingTurbineTypeSelector";
import { unionAll } from "utils/turf";
import { sendWarning } from "utils/sentry";
import { makeExclusionZone, makeSubArea } from "state/division";
import ExclusionTypeSelector from "./TypeSelector/ExclusionTypeSelector";
import TurbineEllipsesSettings from "components/TurbineEllipsesSettings/TurbineEllipsesSettings";

const LINE_BUFFER_BEFORE_SPLIT = 0.001;

const SplitFeatures = ({ selections }: { selections: ProjectFeature[] }) => {
  const { update: updateFeatures } = useProjectElementsCrud();

  const setCurrentSelectionArray = useSetRecoilState(currentSelectionArrayAtom);

  const splitFeatures = useCallback(() => {
    const lines = selections
      .filter((f) => f.geometry.type === "LineString")
      .map((f) => f.geometry);
    const polygons = selections
      .filter((f) => f.geometry.type === "Polygon")
      .map((f) => f.geometry);

    const bufferedLines = lines.map((f) =>
      turf.buffer(f, LINE_BUFFER_BEFORE_SPLIT, { units: "kilometers" }),
    );

    const newFeatures = polygons
      .map((p) =>
        bufferedLines.map(
          (l) =>
            // Todo: Fix types?
            turf.difference(
              p as MultiPolygon,
              l as Feature<MultiPolygon>,
            ) as ProjectFeature,
        ),
      )
      .flat()
      .flatMap((features) => multiFeatureToFeatures(features))
      .map((f) => {
        const newId = uuidv4();
        return {
          ...f,
          id: newId,
          properties: {
            color: DEFAULT_CANVAS_LAYER_COLOR,
            name: "Split result",
            id: newId,
          },
        };
      });

    updateFeatures({ add: newFeatures, remove: selections.map((s) => s.id) });
    setCurrentSelectionArray(resetListIfNotAlreadyEmpty);
  }, [selections, updateFeatures, setCurrentSelectionArray]);

  if (
    selections.filter((s) => s.geometry.type === "Polygon").length === 0 ||
    selections.filter((s) => s.geometry.type === "LineString").length !== 1
  )
    return null;

  return (
    <>
      <Tooltip position="top" text="Split">
        <IconBtn onClick={splitFeatures}>
          <Splitter />
        </IconBtn>
      </Tooltip>
    </>
  );
};

const UnionFeatures = ({ selections }: { selections: ProjectFeature[] }) => {
  const { projectId, branchId } = useTypedPath("projectId", "branchId");
  const { update: updateFeatures } = useProjectElementsCrud();
  const setCurrentSelectionArray = useSetRecoilState(currentSelectionArrayAtom);
  const { error } = useToast();

  const combineFeatures = useCallback(() => {
    const union = unionAll(selections.filter(isPolygonFeature));
    if (!union) {
      error("Failed to compute the union.");
      sendWarning("Failed to compute union", {
        selections,
        types: selections.map((s) => s.geometry.type),
      });
      return;
    }

    if (selections.every(isExclusionDivision)) {
      if (!isPolygonFeature(union) && !isMultiPolygonFeature(union)) {
        error(
          `Result geometry was not a Polygon\nTypes: ${union.geometry.type}.`,
        );
        return;
      }
      const newFeature = makeExclusionZone(union.geometry);
      updateFeatures({
        add: [newFeature],
        remove: selections.map((s) => s.id),
      });
    } else if (selections.every(isSubArea)) {
      const unions = multiFeatureToFeatures(union);
      if (!unions.every(isPolygonFeature)) {
        const types = dedup(unions.map((u) => u.geometry)).join(", ");
        error(`Some result geometry was not a Polygon\nTypes: ${types}.`);
        return;
      }
      const parkIds = dedup(
        selections.map((f) => f?.properties?.parentIds?.[0]).filter(isDefined),
      );
      if (parkIds.length !== 1) {
        error("Cannot join sub areas in different parks.");
        return;
      }
      const newFeatures = unions.map((u) =>
        makeSubArea(u.geometry, parkIds[0]),
      );
      updateFeatures({
        add: newFeatures,
        remove: selections.map((s) => s.id),
      });
    } else {
      if (!isNotGeometryCollection(union)) return;
      const newId = uuidv4();
      const newFeature: ProjectFeature = {
        ...union,
        id: newId,
        properties: {
          color: DEFAULT_CANVAS_LAYER_COLOR,
          id: newId,
          name: "Union result",
        },
      };
      updateFeatures({
        add: [newFeature],
        remove: selections.map((s) => s.id),
      });
    }
    setCurrentSelectionArray(resetListIfNotAlreadyEmpty);
    trackCanvasOption("union", {
      projectId,
      branchId,
      numberOfFeatures: selections.length,
    });
  }, [
    error,
    selections,
    updateFeatures,
    setCurrentSelectionArray,
    projectId,
    branchId,
  ]);

  if (
    selections.filter((s) => s.geometry.type === "Polygon").length !==
    selections.length
  )
    return null;

  return (
    <>
      <Tooltip position="top" text="Union">
        <IconBtn onClick={combineFeatures}>
          <Combine />
        </IconBtn>
      </Tooltip>
    </>
  );
};

const coordinatesOverlap = (coord1: Position, coord2: Position) =>
  roundToDecimal(coord1[0], 11) === roundToDecimal(coord2[0], 11) &&
  roundToDecimal(coord1[1], 11) === roundToDecimal(coord2[1], 11);

const overlapsWithStartPosition = (
  line1: ProjectFeature,
  line2: ProjectFeature,
) => {
  const endPointLine1 = line1.geometry.coordinates[
    line1.geometry.coordinates.length - 1
  ] as [number, number];

  const startPointLine2 = line2.geometry.coordinates[0] as [number, number];

  return coordinatesOverlap(endPointLine1, startPointLine2);
};

const findLineStringNeighbour = (
  linestring: ProjectFeature,
  selections: ProjectFeature[],
) => {
  const potentialNeighbourLineString = selections.filter(
    (s) => s.id !== linestring.id,
  );
  const startPoint = linestring.geometry.coordinates[0] as [number, number];
  const endPoint = linestring.geometry.coordinates[
    linestring.geometry.coordinates.length - 1
  ] as [number, number];

  return [
    potentialNeighbourLineString.filter(
      (s) =>
        coordinatesOverlap(startPoint, s.geometry.coordinates[0] as Position) ||
        coordinatesOverlap(
          startPoint,
          s.geometry.coordinates[s.geometry.coordinates.length - 1] as Position,
        ),
    ),
    potentialNeighbourLineString.filter(
      (s) =>
        coordinatesOverlap(endPoint, s.geometry.coordinates[0] as Position) ||
        coordinatesOverlap(
          endPoint,
          s.geometry.coordinates[s.geometry.coordinates.length - 1] as Position,
        ),
    ),
  ];
};

const UnionLineStrings = ({ selections }: { selections: ProjectFeature[] }) => {
  const { projectId, branchId } = useTypedPath("projectId", "branchId");
  const { update: updateFeatures } = useProjectElementsCrud();
  const setCurrentSelectionArray = useSetRecoilState(currentSelectionArrayAtom);
  const { error } = useToast();

  const combineLineStringFeatures = useCallback(() => {
    let selectionsToMerge = [...selections];
    let startLines = selectionsToMerge.filter((s) => {
      const [startNeighbour, _] = findLineStringNeighbour(s, selections);
      return startNeighbour.length === 0;
    });

    // Did not find a starting line so need to reverse it
    if (startLines.length === 0) {
      selectionsToMerge = [...selections].map((s) => ({
        ...s,
        geometry: {
          ...s.geometry,
          coordinates: [...s.geometry.coordinates].reverse(),
        },
      })) as ProjectFeature[];
      startLines = selectionsToMerge.filter((s) => {
        const [startNeighbour, _] = findLineStringNeighbour(s, selections);
        return startNeighbour.length === 0;
      });
    }

    if (startLines.length === 0) {
      error(
        "Could not find a clear starting linestring, do you have a circular dependency?",
      );
      throw new Error("Did not find a starting line");
    }

    let mergedLineStringFeature = startLines[0];
    selectionsToMerge = selectionsToMerge.filter(
      (s) => s.id !== mergedLineStringFeature.id,
    );

    while (selectionsToMerge.length !== 0) {
      const [_, endMatch] = findLineStringNeighbour(
        mergedLineStringFeature,
        selectionsToMerge,
      );

      if (endMatch.length > 1) {
        error(
          "Found multiple overlapping linestrings at one point, do you have a circular dependency?",
        );
        throw new Error("Did not find exact one neighbour");
      }

      const coordsToAdd = endMatch[0].geometry.coordinates as Position[];
      const coordsRightOrder = overlapsWithStartPosition(
        mergedLineStringFeature,
        endMatch[0],
      )
        ? coordsToAdd
        : [...coordsToAdd].reverse();
      mergedLineStringFeature = {
        ...mergedLineStringFeature,
        id: endMatch[0].id,
        geometry: {
          ...mergedLineStringFeature.geometry,
          type: "LineString",
          coordinates: [
            ...(mergedLineStringFeature.geometry.coordinates as Position[]),
            ...(coordsRightOrder.slice(1) as Position[]),
          ],
        },
      };
      selectionsToMerge = selectionsToMerge.filter(
        (s) => s.id !== mergedLineStringFeature.id,
      );
    }

    const newId = uuidv4();
    const newFeature = {
      ...mergedLineStringFeature,
      id: newId,
      properties: {
        color: DEFAULT_CANVAS_LAYER_COLOR,
        id: newId,
        name: "Union result",
      },
    } as ProjectFeature;
    updateFeatures({ add: [newFeature], remove: selections.map((s) => s.id) });
    setCurrentSelectionArray(resetListIfNotAlreadyEmpty);
    trackCanvasOption("linestringunion", {
      projectId,
      branchId,
    });
  }, [
    selections,
    updateFeatures,
    setCurrentSelectionArray,
    projectId,
    branchId,
    error,
  ]);

  const tooLargeGap = useMemo(
    () =>
      selections.some((s) => {
        const [startNeighbour, endNeighbour] = findLineStringNeighbour(
          s,
          selections,
        );
        return startNeighbour.length === 0 && endNeighbour.length === 0;
      }),
    [selections],
  );

  if (selections.filter(isLineStringFeature).length !== selections.length)
    return null;

  return (
    <>
      <Tooltip
        position="top"
        text={tooLargeGap ? "Too large gap between lines" : "Union"}
      >
        <IconBtn onClick={combineLineStringFeatures} disabled={tooLargeGap}>
          <Combine />
        </IconBtn>
      </Tooltip>
    </>
  );
};

const DifferenceFeatures = ({
  selections,
}: {
  selections: ProjectFeature[];
}) => {
  const { projectId, branchId } = useTypedPath("projectId", "branchId");
  const { update: updateFeatures } = useProjectElementsCrud();
  const setCurrentSelectionArray = useSetRecoilState(currentSelectionArrayAtom);

  const differenceFeatures = useCallback(() => {
    const difference = selections.slice(1).reduce(
      (acc, f) =>
        // Wrong types?
        turf.difference(
          acc as Feature<MultiPolygon>,
          f as Feature<MultiPolygon>,
        ) as ProjectFeature,
      selections[0],
    );

    if (difference) {
      const newId = uuidv4();
      const newFeature = {
        ...difference,
        id: newId,
        properties: {
          color: DEFAULT_CANVAS_LAYER_COLOR,
          id: newId,
          name: "Difference result",
        },
      };
      updateFeatures({
        add: [newFeature],
        remove: selections.map((s) => s.id),
      });
    }

    setCurrentSelectionArray(resetListIfNotAlreadyEmpty);
    trackCanvasOption("difference", {
      projectId,
      branchId,
    });
  }, [
    selections,
    updateFeatures,
    setCurrentSelectionArray,
    projectId,
    branchId,
  ]);

  if (
    selections.filter((s) => s.geometry.type === "Polygon").length !==
    selections.length
  )
    return null;

  return (
    <>
      <Tooltip position="top" text="Difference">
        <IconBtn onClick={differenceFeatures}>
          <Difference />
        </IconBtn>
      </Tooltip>
    </>
  );
};

const IntersectFeatures = ({
  selections,
}: {
  selections: ProjectFeature[];
}) => {
  const { projectId, branchId } = useTypedPath("projectId", "branchId");
  const { update: updateFeatures } = useProjectElementsCrud();
  const setCurrentSelectionArray = useSetRecoilState(currentSelectionArrayAtom);
  const setToastMessages = useSetRecoilState(toastMessagesAtom);

  const intersectFeatures = useCallback(() => {
    // NOTE: this is mostly type gymnastics, because we'll only call this function if all selections are polygons.
    const polygonSelections = selections.filter(
      (f): f is PolygonFeature | MultiPolygonFeature =>
        isPolygonFeature(f) || isMultiPolygonFeature(f),
    );

    const intersect = polygonSelections
      .map<Polygon | MultiPolygon | undefined>((f) => f.geometry)
      .reduce((acc, f) => (acc && f ? turf.intersect(acc, f)?.geometry : acc));

    if (!intersect) {
      setToastMessages((tm) => [
        ...tm,
        {
          text: "Intersection is empty.",
          timeout: 4000,
        },
      ]);
      return;
    }

    const newId = uuidv4();
    const newFeature = {
      ...turf.feature(intersect),
      id: newId,
      properties: {
        color: DEFAULT_CANVAS_LAYER_COLOR,
        id: newId,
        name: "Intersect result",
      },
    };
    updateFeatures({ add: [newFeature], remove: selections.map((s) => s.id) });
    setCurrentSelectionArray(resetListIfNotAlreadyEmpty);
    trackCanvasOption("intersect", {
      projectId,
      branchId,
    });
  }, [
    selections,
    updateFeatures,
    setCurrentSelectionArray,
    projectId,
    setToastMessages,
    branchId,
  ]);

  if (
    selections.filter((s) => s.geometry.type === "Polygon").length !==
    selections.length
  )
    return null;

  return (
    <>
      <Tooltip position="top" text="Intersect">
        <IconBtn onClick={intersectFeatures}>
          <Intersect />
        </IconBtn>
      </Tooltip>
    </>
  );
};

export const useMergeAnchors = (park: ParkFeature) => {
  const { projectId, branchId } = useTypedPath("projectId", "branchId");
  const projectFeatures = useRecoilValue(projectFeaturesSelector);
  const { update: updateFeatures } = useProjectElementsCrud();

  const mergeAnchors = useCallback(
    (anchors: AnchorFeature[]) => {
      const ids = anchors.map((f) => f.id);
      const coords = anchors.map((f) => f.geometry.coordinates);
      const newPosition = coords
        .reduce(vecAdd, [0, 0])
        .map((n) => n / coords.length);

      const newAnchor = makeAnchorFeature(uuidv4(), newPosition, park.id);

      const updateLines = projectFeatures
        .filter(isMooringLine)
        .filter(inPark(park.id))
        .filter((f) => ids.includes(f.properties.anchor))
        .map((f) => ({
          ...f,
          properties: {
            ...f.properties,
            anchor: newAnchor.id,
          },
        }));

      updateFeatures({ update: updateLines, remove: ids, add: [newAnchor] });
      trackCanvasOption("merge-anchors", {
        projectId,
        branchId,
      });
    },
    [branchId, park.id, projectFeatures, projectId, updateFeatures],
  );

  return mergeAnchors;
};

const MergeAnchors = ({
  selection,
  park,
}: {
  selection: ProjectFeature[];
  park: ParkFeature;
}) => {
  const mergeAnchors = useMergeAnchors(park);
  return (
    <>
      <Tooltip position="top" text="Merge anchors">
        <IconBtn
          onClick={() => {
            mergeAnchors(selection.filter(isAnchor));
          }}
        >
          <AddCircle />
        </IconBtn>
      </Tooltip>
    </>
  );
};

export const CanvasMultiSelectOption = ({
  selectedProjectFeatures,
}: {
  selectedProjectFeatures: ProjectFeature[];
}) => {
  const parkId = useRecoilValue(parkIdSelector);
  const projectId = useRecoilValue(projectIdSelector)!;
  const branchId = useRecoilValue(branchIdSelector)!;
  const park = useRecoilValue(
    getParkFeatureSelectorFamily({ parkId: parkId ?? "" }),
  );
  const map = useRecoilValue(mapRefAtom);
  const mapInteraction = useRecoilValue(mapInteractionSelector);
  const isCustomerEditor = useRecoilValue(editorAccessProjectSelector);
  const isReadOnly = useRecoilValue(inReadOnlyModeSelector);
  const canEdit = isCustomerEditor && !isReadOnly;
  const nonLockedFeatures = useMemo(
    () =>
      selectedProjectFeatures.filter((feature) => !featureIsLocked(feature)),
    [selectedProjectFeatures],
  );
  const setCurrentSelectionArray = useSetRecoilState(currentSelectionArrayAtom);
  const setEditFeature = useSetRecoilState(editFeaturesAtom);
  const updateFeatureProperties = useSetPropertyOnProjectFeatures();

  const { popupPlacement, isMoving, setMouseDownPosition } = useMoveablePopup(
    selectedProjectFeatures,
    map,
  );

  const allFeaturesOfSameType = useMemo(
    () =>
      selectedProjectFeatures.reduce(
        (previous, f) => {
          const isSame =
            f.properties?.type === previous.type &&
            f.geometry?.type === previous.geometry;

          if (isSame) return previous;
          return {
            type: f.properties?.type,
            same: false,
            geometry: f.geometry?.type,
          };
        },
        {
          type: selectedProjectFeatures[0].properties.type,
          same: true,
          geometry: selectedProjectFeatures[0].geometry.type,
        },
      ),
    [selectedProjectFeatures],
  );

  const deleteFeaturesWithChildren = useDeleteFeaturesCallback();

  const deleteFeatures = useCallback(() => {
    if (!canEdit) return;
    deleteFeaturesWithChildren(nonLockedFeatures.map((s) => s.id));
  }, [canEdit, nonLockedFeatures, deleteFeaturesWithChildren]);

  const onClickEditFeatures = useCallback(() => {
    if (nonLockedFeatures.length < 1) return;
    setCurrentSelectionArray(resetListIfNotAlreadyEmpty);
    setEditFeature(nonLockedFeatures.map((s) => s.id));
    trackCanvasOption("edit-feature", {
      projectId,
      branchId,
    });
  }, [
    branchId,
    projectId,
    nonLockedFeatures,
    setCurrentSelectionArray,
    setEditFeature,
  ]);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (!["Backspace", "Delete"].includes(e.key)) {
        return;
      }

      if (
        !mapInteraction.deleteFeatureKeyboardShortcut ||
        keyEventTargetIsInput(e)
      ) {
        return;
      }

      deleteFeatures();
    };
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [deleteFeatures, mapInteraction.deleteFeatureKeyboardShortcut]);

  const selectionsAreOfTypeOther = selectedProjectFeatures.every(
    (s) =>
      s.properties.type == null &&
      ![BathymetryUserUploadedType, GeoTiffUserUploadedImageType].includes(
        s.properties.type ?? "",
      ),
  );

  const selectionsAreOfTypeGeoTiff = selectedProjectFeatures.every(
    (s) => s.properties.type === GeoTiffUserUploadedImageType,
  );

  const allowEdit = useMemo(() => {
    return selectedProjectFeatures.every(
      (s) =>
        ![BathymetryUserUploadedType, GeoTiffUserUploadedImageType].includes(
          s.properties.type ?? "",
        ),
    );
  }, [selectedProjectFeatures]);

  const allLocked = useMemo(
    () => selectedProjectFeatures.every(featureIsLocked),
    [selectedProjectFeatures],
  );

  const onClickLockFeatures = useCallback(() => {
    trackCanvasOption("lock-features", {
      projectId,
      branchId,
    });
    updateFeatureProperties(
      selectedProjectFeatures.map((s) => s.id),
      {
        [lockedPropertyName]: !allLocked,
      },
    );
  }, [
    allLocked,
    branchId,
    projectId,
    selectedProjectFeatures,
    updateFeatureProperties,
  ]);

  const setCableEditMode = useSetRecoilState(cableEditModeAtom);

  if (!canEdit) return null;

  const allTurbines =
    allFeaturesOfSameType.same && isTurbine(selectedProjectFeatures[0]);

  const allAnchors =
    allFeaturesOfSameType.same && isAnchor(selectedProjectFeatures[0]);

  const allCables =
    allFeaturesOfSameType.same && isCable(selectedProjectFeatures[0]);

  const allMooringLines =
    allFeaturesOfSameType.same && isMooringLine(selectedProjectFeatures[0]);

  const allExclusionZones =
    allFeaturesOfSameType.same &&
    isExclusionDivision(selectedProjectFeatures[0]);

  const cablePartitionControls =
    selectedProjectFeatures.filter(isCablePartition).length === 1;
  const cableChainControls =
    selectedProjectFeatures.every(
      (f) => isTurbine(f) || isSubstation(f) || isCableChain(f),
    ) && selectedProjectFeatures.some(isCableChain);

  if (!map) return null;
  return (
    <Popup
      map={map}
      pos={popupPlacement}
      offsetPx={[0, -20]}
      place="bottom"
      style={{
        pointerEvents: isMoving ? "none" : undefined,
      }}
    >
      <div
        style={{
          position: "relative",
        }}
      >
        <IconMenu iconSize="2.2rem">
          <div
            style={{
              cursor: "move",
            }}
            onMouseDown={(e) => {
              setMouseDownPosition({
                x: e.clientX,
                y: e.clientY,
              });
            }}
          >
            <NamedTooltipWrapper>
              <IconREMSize height={1.2} width={1.2}>
                <DnDIconSmall />
              </IconREMSize>
            </NamedTooltipWrapper>
          </div>
          <>
            {allFeaturesOfSameType.same && // NOTE: selecing multiple "other" polygons needs to be handled.
              allFeaturesOfSameType.geometry !== undefined &&
              ![
                BathymetryUserUploadedType,
                GeoTiffUserUploadedImageType,
              ].includes(allFeaturesOfSameType.type || "") && (
                <>
                  <TypeSelector
                    selections={selectedProjectFeatures}
                    setCurrentSelectionArray={setCurrentSelectionArray}
                    disabled={!canEdit || allLocked}
                    selectedType={parseOr(
                      _FeatureType,
                      allFeaturesOfSameType.type,
                      undefined,
                    )}
                  />
                </>
              )}
            <TurbineTypeSelector
              selectedProjectFeatures={nonLockedFeatures}
              editable={canEdit && !allLocked}
            />

            <ExistingTurbineTypeSelector
              selectedProjectFeatures={nonLockedFeatures}
              editable={canEdit && !allLocked}
            />

            <SubstationTypeSelector
              selectedProjectFeatures={nonLockedFeatures}
              editable={canEdit && !allLocked}
            />
            {nonLockedFeatures.every(isExportCable) && (
              <ExportCableTypeSelector
                cables={nonLockedFeatures}
                editable={canEdit && !allLocked}
              />
            )}
            <FoundationTypeSelector
              selectedProjectFeatures={nonLockedFeatures}
              editable={canEdit && !allLocked}
            />

            {allCables && (
              <>
                <CableTypeSelector
                  selectedProjectFeatures={nonLockedFeatures}
                  editable={canEdit && !allLocked}
                />
                <Divider />
              </>
            )}
            {allMooringLines && (
              <>
                <MooringLineTypeSelector
                  selectedProjectFeatures={nonLockedFeatures}
                  editable={canEdit && !allLocked}
                />
                <Divider />
              </>
            )}
            {allExclusionZones && (
              <>
                <ExclusionTypeSelector
                  divisions={nonLockedFeatures.filter(isExclusionDivision)}
                  setCurrentSelectionArray={setCurrentSelectionArray}
                />
                <Divider />
              </>
            )}

            <ToolsWrapper>
              {allowEdit && !(cablePartitionControls || cableChainControls) && (
                <Tooltip position="top" text={`Edit features`}>
                  <IconBtn onClick={onClickEditFeatures} disabled={allLocked}>
                    <Pen />
                  </IconBtn>
                </Tooltip>
              )}
              {canEdit && (
                <Tooltip
                  position="top"
                  text={allLocked ? "Unlock features" : "Lock features"}
                >
                  <IconBtn onClick={onClickLockFeatures}>
                    {allLocked ? <UnlockIcon /> : <LockIcon />}
                  </IconBtn>
                </Tooltip>
              )}

              {allowEdit && allCables && !allLocked && (
                <Tooltip position="top" text={`Change cabling`}>
                  <IconBtn
                    onClick={() => {
                      setCableEditMode(true);
                      setCurrentSelectionArray([]);
                    }}
                  >
                    <Cabling />
                  </IconBtn>
                </Tooltip>
              )}

              {canEdit &&
                !allLocked &&
                selectedProjectFeatures.find((f) =>
                  f.geometry.type.includes("Multi"),
                ) && (
                  <PositionHint
                    allowedHints={[splitMultiFeatureHelpHintType]}
                    position={"top"}
                  >
                    <Tooltip position="top" text="Split into parts">
                      <SplitMultiPart
                        featureIds={selectedProjectFeatures.map((f) => f.id)}
                      />
                    </Tooltip>
                  </PositionHint>
                )}

              <UnionFeatures selections={selectedProjectFeatures} />
              <IntersectFeatures selections={selectedProjectFeatures} />
              <DifferenceFeatures selections={selectedProjectFeatures} />
              <SplitFeatures selections={selectedProjectFeatures} />
              {!allCables && (
                <UnionLineStrings selections={selectedProjectFeatures} />
              )}
              {canEdit &&
                !selectedProjectFeatures.some((f) =>
                  [
                    BathymetryUserUploadedType,
                    GeoTiffUserUploadedImageType,
                  ].includes(f.properties.type ?? ""),
                ) &&
                !(cablePartitionControls || cableChainControls) && (
                  <>
                    <Tooltip position="top" text="Buffer">
                      <BufferSelector />
                    </Tooltip>
                  </>
                )}
              {canEdit && (
                <>
                  <Tooltip position="top" text="Change style">
                    <StylingSelector
                      selectedProjectFeatures={selectedProjectFeatures}
                      enableColorStyling={selectionsAreOfTypeOther}
                      enableOpacityStyling={selectionsAreOfTypeGeoTiff}
                    />
                  </Tooltip>
                </>
              )}
              {canEdit && allTurbines && (
                <>
                  <Tooltip position="top" text="Display turbine ellipses">
                    <TurbineEllipsesSettings
                      selectedProjectFeatures={selectedProjectFeatures}
                    />
                  </Tooltip>
                </>
              )}

              {park &&
                canEdit &&
                allAnchors &&
                !allLocked &&
                nonLockedFeatures.length > 1 && (
                  <MergeAnchors selection={nonLockedFeatures} park={park} />
                )}

              {parkId && park && (
                <>
                  <ConnectAnchorOption
                    selection={selectedProjectFeatures}
                    park={park}
                  />
                </>
              )}
            </ToolsWrapper>
          </>
        </IconMenu>
      </div>
    </Popup>
  );
};

export default CanvasMultiSelectOption;
