import React, { useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { modalTypeOpenAtom } from "../../../../../state/modal";
import { newCustomDataSourceAtom } from "../../../../../state/newLayer";
import { AddDataLayersModalType } from "../../../../DataLayersModal/DataLayersModal";
import AddNewDataSourceToProjectModalInner, {
  AddNewDataSourceModalToProjectType,
} from "../../../../LayerList/AddNewSourceModal/AddNewSourceToProjectModal";
import { TestNewPrivateSourceModalInner } from "../../../../LayerList/TestNewSourceModal";
import { UploadWrapper } from "../../shared";

const AddDataLayerSourceFromUrl = ({
  onBackClick,
  onDoneClick,
}: {
  onBackClick(): void;
  onDoneClick(): void;
}) => {
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const [newCustomDataSource, setNewCustomDataSource] = useRecoilState(
    newCustomDataSourceAtom,
  );

  const onCancel = () => {
    setNewCustomDataSource(undefined);
  };

  const onDone = () => {
    setModalTypeOpen({
      modalType: AddDataLayersModalType,
    });
  };

  useEffect(() => {
    return () => {
      setNewCustomDataSource(undefined);
    };
  }, [setNewCustomDataSource]);

  return (
    <UploadWrapper>
      {newCustomDataSource ? (
        <TestNewPrivateSourceModalInner
          newCustomDataSource={newCustomDataSource}
          showCloseControls={false}
          onDone={onDone}
          cancel={onCancel}
          close={onDoneClick}
        />
      ) : (
        <AddNewDataSourceToProjectModalInner
          modalType={AddNewDataSourceModalToProjectType}
          showCloseControls={false}
          close={onDoneClick}
          cancel={onBackClick}
        />
      )}
    </UploadWrapper>
  );
};

export default AddDataLayerSourceFromUrl;
