import { useRecoilValue } from "recoil";
import {
  ErrorBoundarySilent,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "../../components/ErrorBoundaries/ErrorBoundaryLocal";
import Point from "../../components/MapFeatures/Point";
import {
  mapboxBridgeRail,
  externalWFSAnchorId,
  externalWMSAnchorId,
} from "../../components/Mapbox/utils";
import { mapRefAtom } from "../../state/map";

const ExternalLayerOrderAnchorLayers = ErrorBoundaryWrapper(
  () => {
    const map = useRecoilValue(mapRefAtom);

    if (!map) return null;

    return (
      <>
        <Point
          features={[]}
          sourceId={externalWFSAnchorId}
          layerId={externalWFSAnchorId}
          map={map}
          beforeLayer={mapboxBridgeRail}
        />
        <Point
          features={[]}
          sourceId={externalWMSAnchorId}
          layerId={externalWMSAnchorId}
          map={map}
          beforeLayer={mapboxBridgeRail}
        />
      </>
    );
  },
  ErrorBoundarySilent,
  ScreamOnError,
);

export default ExternalLayerOrderAnchorLayers;
