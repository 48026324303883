import mapboxgl, { CustomLayerInterface, Map } from "mapbox-gl";
import * as THREE from "three";
import { Camera, Scene, WebGLRenderer } from "three";
import { foundations3dLayerId } from "../../constants/projectMapView";
import { foundationScale } from "../../state/foundations";
import { FoundationType } from "../../types/foundations";
import { LAYER_DEBUG_PRINT } from "../../state/debug";
import { SimpleTurbineType } from "../../types/turbines";
import {
  isFloater,
  isSemiCentral,
  isSemiPeripheral,
  isSpar,
  isMonopile,
  isJacket,
  isDetailedMonopile,
} from "../../components/RightSide/InfoModal/FoundationModal/utils";
import { dispose } from "../../utils/three";

export const createFoundationModel = (
  turbine: SimpleTurbineType,
  foundation: FoundationType,
) => {
  const mat = new THREE.MeshLambertMaterial({ color: 0xffffff });

  const meshes: THREE.Mesh[] = [];

  if (isSemiCentral(foundation)) {
    const cornerColGeometry1 = new THREE.CylinderGeometry(
      foundation.cornerColDiameter / 2,
      foundation.cornerColDiameter / 2,
      foundation.cornerColHeight,
      32,
    );
    const centralColGeometry = new THREE.CylinderGeometry(
      foundation.centralColDiameterTop / 2,
      foundation.centralColDiameterBase / 2,
      foundation.centralColHeight,
      32,
    );
    const pontoonGeometry1 = new THREE.BoxGeometry(
      foundation.ponWidth,
      foundation.ponHeight,
      foundation.ccDistance,
    );
    cornerColGeometry1.rotateX((90 * Math.PI) / 180);
    const cornerColGeometry2 = cornerColGeometry1.clone();
    const cornerColGeometry3 = cornerColGeometry1.clone();
    cornerColGeometry1.translate(
      foundation.ccDistance,
      0,
      foundation.cornerColHeight / 2 + foundation.cornerColStart,
    );
    cornerColGeometry2.translate(
      foundation.ccDistance * Math.cos((120 * Math.PI) / 180),
      foundation.ccDistance * Math.sin((120 * Math.PI) / 180),
      foundation.cornerColHeight / 2 + foundation.cornerColStart,
    );
    cornerColGeometry3.translate(
      foundation.ccDistance * Math.cos((240 * Math.PI) / 180),
      foundation.ccDistance * Math.sin((240 * Math.PI) / 180),
      foundation.cornerColHeight / 2 + foundation.cornerColStart,
    );
    const cornerColMesh1 = new THREE.Mesh(cornerColGeometry1, mat);
    const cornerColMesh2 = new THREE.Mesh(cornerColGeometry2, mat);
    const cornerColMesh3 = new THREE.Mesh(cornerColGeometry3, mat);
    meshes.push(cornerColMesh1, cornerColMesh2, cornerColMesh3);

    centralColGeometry.rotateX((90 * Math.PI) / 180);
    centralColGeometry.translate(
      0,
      0,
      foundation.centralColHeight / 2 + foundation.centralColStart,
    );
    const centralColMesh = new THREE.Mesh(centralColGeometry, mat);
    meshes.push(centralColMesh);

    pontoonGeometry1.rotateX((90 * Math.PI) / 180);
    pontoonGeometry1.rotateZ((90 * Math.PI) / 180);
    const pontoonGeometry2 = pontoonGeometry1.clone();
    const pontoonGeometry3 = pontoonGeometry1.clone();
    pontoonGeometry2.rotateZ((120 * Math.PI) / 180);
    pontoonGeometry3.rotateZ((240 * Math.PI) / 180);
    pontoonGeometry1.translate(
      foundation.ccDistance / 2,
      0,
      foundation.ponHeight / 2 + foundation.ponStart,
    );
    pontoonGeometry2.translate(
      (foundation.ccDistance / 2) * Math.cos((120 * Math.PI) / 180),
      (foundation.ccDistance / 2) * Math.sin((120 * Math.PI) / 180),
      foundation.ponHeight / 2 + foundation.ponStart,
    );
    pontoonGeometry3.translate(
      (foundation.ccDistance / 2) * Math.cos((240 * Math.PI) / 180),
      (foundation.ccDistance / 2) * Math.sin((240 * Math.PI) / 180),
      foundation.ponHeight / 2 + foundation.ponStart,
    );
    const pontoonMesh1 = new THREE.Mesh(pontoonGeometry1, mat);
    const pontoonMesh2 = new THREE.Mesh(pontoonGeometry2, mat);
    const pontoonMesh3 = new THREE.Mesh(pontoonGeometry3, mat);
    meshes.push(pontoonMesh1, pontoonMesh2, pontoonMesh3);
  } else if (isSemiPeripheral(foundation)) {
    const cornerColGeometry1 = new THREE.CylinderGeometry(
      foundation.cornerColDiameter / 2,
      foundation.cornerColDiameter / 2,
      foundation.cornerColHeight,
      32,
    );
    const pontoonGeometry1 = new THREE.BoxGeometry(
      foundation.ponWidth,
      foundation.ponHeight,
      foundation.ccDistance * Math.sqrt(3),
    );
    const deckGeometry1 = new THREE.BoxGeometry(
      foundation.deckWidth,
      foundation.deckHeight,
      foundation.ccDistance * Math.sqrt(3),
    );
    cornerColGeometry1.rotateX((90 * Math.PI) / 180);
    const cornerColGeometry2 = cornerColGeometry1.clone();
    const cornerColGeometry3 = cornerColGeometry1.clone();
    cornerColGeometry1.translate(
      foundation.ccDistance - foundation.ccDistance,
      0,
      foundation.cornerColHeight / 2 + foundation.cornerColStart,
    );
    cornerColGeometry2.translate(
      foundation.ccDistance * Math.cos((120 * Math.PI) / 180) -
        foundation.ccDistance,
      foundation.ccDistance * Math.sin((120 * Math.PI) / 180),
      foundation.cornerColHeight / 2 + foundation.cornerColStart,
    );
    cornerColGeometry3.translate(
      foundation.ccDistance * Math.cos((240 * Math.PI) / 180) -
        foundation.ccDistance,
      foundation.ccDistance * Math.sin((240 * Math.PI) / 180),
      foundation.cornerColHeight / 2 + foundation.cornerColStart,
    );
    const cornerColMesh1 = new THREE.Mesh(cornerColGeometry1, mat);
    const cornerColMesh2 = new THREE.Mesh(cornerColGeometry2, mat);
    const cornerColMesh3 = new THREE.Mesh(cornerColGeometry3, mat);
    meshes.push(cornerColMesh1, cornerColMesh2, cornerColMesh3);

    pontoonGeometry1.rotateX((90 * Math.PI) / 180);
    const pontoonGeometry2 = pontoonGeometry1.clone();
    const pontoonGeometry3 = pontoonGeometry1.clone();
    pontoonGeometry2.rotateZ((120 * Math.PI) / 180);
    pontoonGeometry3.rotateZ((240 * Math.PI) / 180);
    pontoonGeometry1.translate(
      (foundation.ccDistance / 2) * Math.cos((180 * Math.PI) / 180) -
        foundation.ccDistance,
      (foundation.ccDistance / 2) * Math.sin((180 * Math.PI) / 180),
      foundation.ponHeight / 2 + foundation.ponStart,
    );
    pontoonGeometry2.translate(
      (foundation.ccDistance / 2) * Math.cos((300 * Math.PI) / 180) -
        foundation.ccDistance,
      (foundation.ccDistance / 2) * Math.sin((300 * Math.PI) / 180),
      foundation.ponHeight / 2 + foundation.ponStart,
    );
    pontoonGeometry3.translate(
      (foundation.ccDistance / 2) * Math.cos((60 * Math.PI) / 180) -
        foundation.ccDistance,
      (foundation.ccDistance / 2) * Math.sin((60 * Math.PI) / 180),
      foundation.ponHeight / 2 + foundation.ponStart,
    );
    const pontoonMesh1 = new THREE.Mesh(pontoonGeometry1, mat);
    const pontoonMesh2 = new THREE.Mesh(pontoonGeometry2, mat);
    const pontoonMesh3 = new THREE.Mesh(pontoonGeometry3, mat);
    meshes.push(pontoonMesh1, pontoonMesh2, pontoonMesh3);

    deckGeometry1.rotateX((90 * Math.PI) / 180);
    const deckGeometry2 = deckGeometry1.clone();
    const deckGeometry3 = deckGeometry1.clone();
    deckGeometry2.rotateZ((120 * Math.PI) / 180);
    deckGeometry3.rotateZ((240 * Math.PI) / 180);
    deckGeometry1.translate(
      (foundation.ccDistance / 2) * Math.cos((180 * Math.PI) / 180) -
        foundation.ccDistance,
      (foundation.ccDistance / 2) * Math.sin((180 * Math.PI) / 180),
      foundation.deckHeight / 2 + foundation.deckStart,
    );
    deckGeometry2.translate(
      (foundation.ccDistance / 2) * Math.cos((300 * Math.PI) / 180) -
        foundation.ccDistance,
      (foundation.ccDistance / 2) * Math.sin((300 * Math.PI) / 180),
      foundation.deckHeight / 2 + foundation.deckStart,
    );
    deckGeometry3.translate(
      (foundation.ccDistance / 2) * Math.cos((60 * Math.PI) / 180) -
        foundation.ccDistance,
      (foundation.ccDistance / 2) * Math.sin((60 * Math.PI) / 180),
      foundation.deckHeight / 2 + foundation.deckStart,
    );
    const deckMesh1 = new THREE.Mesh(deckGeometry1, mat);
    const deckMesh2 = new THREE.Mesh(deckGeometry2, mat);
    const deckMesh3 = new THREE.Mesh(deckGeometry3, mat);
    meshes.push(deckMesh1, deckMesh2, deckMesh3);
  } else if (isSpar(foundation)) {
    const topGeometry = new THREE.CylinderGeometry(
      foundation.topDiameter / 2,
      foundation.topDiameter / 2,
      foundation.colHeight - foundation.draft - foundation.upperTaperLevel,
      32,
    );
    const taperGeometry = new THREE.CylinderGeometry(
      foundation.topDiameter / 2,
      foundation.baseDiameter / 2,
      foundation.upperTaperLevel - foundation.lowerTaperLevel,
      32,
    );
    const baseGeometry = new THREE.CylinderGeometry(
      foundation.baseDiameter / 2,
      foundation.baseDiameter / 2,
      foundation.draft + foundation.lowerTaperLevel,
      32,
    );
    topGeometry.rotateX((90 * Math.PI) / 180);
    taperGeometry.rotateX((90 * Math.PI) / 180);
    baseGeometry.rotateX((90 * Math.PI) / 180);
    topGeometry.translate(
      0,
      0,
      foundation.colHeight -
        foundation.draft -
        (foundation.colHeight - foundation.draft - foundation.upperTaperLevel) /
          2,
    );
    taperGeometry.translate(
      0,
      0,
      foundation.lowerTaperLevel +
        (foundation.upperTaperLevel - foundation.lowerTaperLevel) / 2,
    );
    baseGeometry.translate(
      0,
      0,
      -foundation.draft + (foundation.draft + foundation.lowerTaperLevel) / 2,
    );
    const topMesh = new THREE.Mesh(topGeometry, mat);
    const taperMesh = new THREE.Mesh(taperGeometry, mat);
    const baseMesh = new THREE.Mesh(baseGeometry, mat);
    meshes.push(topMesh, taperMesh, baseMesh);
  } else if (isMonopile(foundation) || isDetailedMonopile(foundation)) {
    const pileDiameter = 12;
    const pileLength = 50;
    const pileGeometry = new THREE.CylinderGeometry(
      pileDiameter / 2,
      pileDiameter / 2,
      pileLength,
      32,
    );
    pileGeometry.rotateX((90 * Math.PI) / 180);
    pileGeometry.translate(0, 0, -pileLength / 2 + 20);
    const pileMesh = new THREE.Mesh(pileGeometry, mat);
    meshes.push(pileMesh);
  } else if (isJacket(foundation)) {
    const waterDepth = 60;
    const lengthAboveWL = 10;
    const legDiameter = 2.6;
    const braceDiameter = 1.3;
    const deckHeight = 3.0;
    const h0 = 6.4,
      h1 = 16.6,
      h2 = 14.0,
      h3 = 11.64,
      h4 = 13.44,
      h5 = 7.92;
    const h = [h0, h1, h2, h3, h4, h5];
    const d0 = 25,
      d5 = 14;
    const d1 = d0 + ((d5 - d0) * h1) / (h1 + h2 + h3 + h4 + h5);
    const d2 = d0 + ((d5 - d0) * (h1 + h2)) / (h1 + h2 + h3 + h4 + h5);
    const d3 = d0 + ((d5 - d0) * (h1 + h2 + h3)) / (h1 + h2 + h3 + h4 + h5);
    const d4 =
      d0 + ((d5 - d0) * (h1 + h2 + h3 + h4)) / (h1 + h2 + h3 + h4 + h5);
    const d = [d0, d1, d2, d3, d4, d5];
    const l: number[] = [];
    for (let i = 0; i < 5; i++) {
      l.push(
        Math.sqrt(Math.pow(d[i] / 2 + d[i + 1] / 2, 2) + Math.pow(h[i + 1], 2)),
      );
    }
    const angle: number[] = [];
    for (let i = 0; i < 5; i++) {
      angle.push(Math.atan(h[i + 1] / (d[i] / 2 + d[i + 1] / 2)));
    }
    const legLength = Math.sqrt(
      Math.pow(d0 / 2 - d5 / 2, 2) +
        Math.pow(lengthAboveWL + waterDepth - h0, 2),
    );
    const legAngle = Math.atan(
      (d0 / 2 - d5 / 2) / (lengthAboveWL + waterDepth - h0),
    );

    for (let i = 0; i < 4; i++) {
      const legGeometry = new THREE.CylinderGeometry(
        legDiameter / 2,
        legDiameter / 2,
        legLength,
        32,
      );
      legGeometry.rotateX((90 * Math.PI) / 180 - legAngle);
      legGeometry.rotateY(legAngle);
      legGeometry.translate(
        -d0 / 2 + (legLength / 2) * Math.sin(legAngle),
        -d0 / 2 + (legLength / 2) * Math.sin(legAngle),
        -waterDepth + legLength / 2 + h0,
      );
      legGeometry.rotateZ((90 * i * Math.PI) / 180);
      meshes.push(new THREE.Mesh(legGeometry, mat));
    }

    const bGeometry: any[][] = [[], [], [], []];
    let braceHeight = 0;
    for (let j = 0; j < 5; j++) {
      braceHeight += h[j];
      const braceGeometry = new THREE.CylinderGeometry(
        braceDiameter / 2,
        braceDiameter / 2,
        l[j],
        32,
      );
      braceGeometry.rotateX(angle[j]);
      braceGeometry.rotateY(legAngle);
      braceGeometry.translate(
        -d[j] / 2 + (l[j] / 2) * Math.sin(legAngle),
        -d[j] / 2 + (l[j] / 2) * Math.cos(angle[j]),
        -waterDepth + braceHeight + (Math.tan(angle[j]) * d[j]) / 2,
      );
      bGeometry[0].push(braceGeometry);
    }

    for (let j = 0; j < 5; j++) {
      const braceGeometry = bGeometry[0][j].clone();
      braceGeometry.applyMatrix4(new THREE.Matrix4().makeScale(-1, 1, 1));
      bGeometry[0].push(braceGeometry);
    }

    for (let i = 1; i < 4; i++) {
      for (let j = 0; j < 10; j++) {
        const braceGeometry = bGeometry[0][j].clone();
        braceGeometry.rotateZ((90 * i * Math.PI) / 180);
        bGeometry[i].push(braceGeometry);
      }
    }

    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 10; j++) {
        meshes.push(new THREE.Mesh(bGeometry[i][j], mat));
      }
    }

    const deckGeometry = new THREE.BoxGeometry(
      d5 + legDiameter,
      d5 + legDiameter,
      deckHeight,
    );
    deckGeometry.translate(0, 0, lengthAboveWL);

    const mesh = new THREE.Mesh(deckGeometry, mat);
    meshes.push(mesh);
  }

  const scale = isFloater(foundation)
    ? foundationScale({
        foundation: foundation,
        turbine: turbine,
      }) ?? 1
    : turbine.diameter / 240;

  for (const m of meshes) {
    m.scale.set(scale, scale, scale);
  }

  return meshes;
};

export class ThreeDFoundations implements CustomLayerInterface {
  id: string;
  type: "custom";
  renderingMode: "2d" | "3d" | undefined;
  turbineLonlats: [number, number][];
  turbines: SimpleTurbineType[];
  waterDepth: number;
  foundation: FoundationType | undefined;
  parkId: string;
  direction: number = 0;
  modelTransforms:
    | {
        translateX: number;
        translateY: number;
        translateZ: number;
        rotateX: number;
        rotateY: number;
        rotateZ: number;
        scale: number;
      }[]
    | undefined;
  camera: Camera | undefined;
  scene: Scene | undefined;
  renderer: WebGLRenderer | undefined;
  map: Map | undefined;
  constructor(
    turbineLonlats: [number, number][],
    turbines: SimpleTurbineType[],
    waterDepth: number,
    foundation: FoundationType | undefined,
    parkId: string,
  ) {
    this.parkId = parkId;
    this.id = foundations3dLayerId + parkId + (foundation?.id ?? "");
    this.type = "custom";
    this.renderingMode = "3d";
    this.turbineLonlats = turbineLonlats;
    this.turbines = turbines;
    this.waterDepth = waterDepth;
    this.foundation = foundation;
    this.direction = 0;
  }
  onAdd(map: Map, gl: WebGLRenderingContext) {
    LAYER_DEBUG_PRINT && console.log("ThreeDFoundations.onAdd");
    // parameters to ensure the model is georeferenced correctly on the map
    if (this.turbineLonlats.length === 0) return;
    const modelOrigins = this.turbineLonlats;
    const modelAltitude = 0;
    map.setLayerZoomRange(this.id, 13, 24);

    const modelAsMercatorCoordinates = modelOrigins.map((modelOrigin) =>
      mapboxgl.MercatorCoordinate.fromLngLat(modelOrigin, modelAltitude),
    );

    // transformation parameters to position, rotate and scale the 3D model onto the map
    this.modelTransforms = modelAsMercatorCoordinates.map(
      (modelAsMercatorCoordinate, i) => {
        const conversion =
          modelAsMercatorCoordinate.meterInMercatorCoordinateUnits();
        const scale = isFloater(this.foundation)
          ? foundationScale({
              foundation: this.foundation,
              turbine: this.turbines[i],
            }) ?? 1
          : this.turbines[i].diameter / 240;
        return {
          translateX: modelAsMercatorCoordinate.x ?? 0,
          translateY: modelAsMercatorCoordinate.y ?? 0,
          translateZ: modelAsMercatorCoordinate.z ?? 0,
          rotateX: 0,
          rotateY: 0,
          rotateZ: 0,
          /* Since the 3D model is in real world meters, a scale transform needs to be
           * applied since the CustomLayerInterface expects units in MercatorCoordinates.
           */
          scale: scale * conversion,
        };
      },
    );
    this.camera = new THREE.Camera();
    this.scene = new THREE.Scene();

    // create two three.js lights to illuminate the model
    const directionalLight = new THREE.DirectionalLight(0xffffff);
    directionalLight.position.set(0, -70, 100).normalize();
    this.scene.add(directionalLight);

    const directionalLight2 = new THREE.DirectionalLight(0xffffff);
    directionalLight2.position.set(0, 70, 100).normalize();
    this.scene.add(directionalLight2);

    const mat = new THREE.MeshLambertMaterial({ color: 0xffffff });

    if (isSemiCentral(this.foundation)) {
      const cornerColGeometry1 = new THREE.CylinderGeometry(
        this.foundation.cornerColDiameter / 2,
        this.foundation.cornerColDiameter / 2,
        this.foundation.cornerColHeight,
        32,
      );
      const centralColGeometry = new THREE.CylinderGeometry(
        this.foundation.centralColDiameterTop / 2,
        this.foundation.centralColDiameterBase / 2,
        this.foundation.centralColHeight,
        32,
      );
      const pontoonGeometry1 = new THREE.BoxGeometry(
        this.foundation.ponWidth,
        this.foundation.ponHeight,
        this.foundation.ccDistance,
      );
      cornerColGeometry1.rotateX((90 * Math.PI) / 180);
      const cornerColGeometry2 = cornerColGeometry1.clone();
      const cornerColGeometry3 = cornerColGeometry1.clone();
      cornerColGeometry1.translate(
        this.foundation.ccDistance,
        0,
        this.foundation.cornerColHeight / 2 + this.foundation.cornerColStart,
      );
      cornerColGeometry2.translate(
        this.foundation.ccDistance * Math.cos((120 * Math.PI) / 180),
        this.foundation.ccDistance * Math.sin((120 * Math.PI) / 180),
        this.foundation.cornerColHeight / 2 + this.foundation.cornerColStart,
      );
      cornerColGeometry3.translate(
        this.foundation.ccDistance * Math.cos((240 * Math.PI) / 180),
        this.foundation.ccDistance * Math.sin((240 * Math.PI) / 180),
        this.foundation.cornerColHeight / 2 + this.foundation.cornerColStart,
      );
      const cornerColMesh1 = new THREE.Mesh(cornerColGeometry1, mat);
      const cornerColMesh2 = new THREE.Mesh(cornerColGeometry2, mat);
      const cornerColMesh3 = new THREE.Mesh(cornerColGeometry3, mat);
      this.scene.add(cornerColMesh1, cornerColMesh2, cornerColMesh3);

      centralColGeometry.rotateX((90 * Math.PI) / 180);
      centralColGeometry.translate(
        0,
        0,
        this.foundation.centralColHeight / 2 + this.foundation.centralColStart,
      );
      const centralColMesh = new THREE.Mesh(centralColGeometry, mat);
      this.scene.add(centralColMesh);

      pontoonGeometry1.rotateX((90 * Math.PI) / 180);
      pontoonGeometry1.rotateZ((90 * Math.PI) / 180);
      const pontoonGeometry2 = pontoonGeometry1.clone();
      const pontoonGeometry3 = pontoonGeometry1.clone();
      pontoonGeometry2.rotateZ((120 * Math.PI) / 180);
      pontoonGeometry3.rotateZ((240 * Math.PI) / 180);
      pontoonGeometry1.translate(
        this.foundation.ccDistance / 2,
        0,
        this.foundation.ponHeight / 2 + this.foundation.ponStart,
      );
      pontoonGeometry2.translate(
        (this.foundation.ccDistance / 2) * Math.cos((120 * Math.PI) / 180),
        (this.foundation.ccDistance / 2) * Math.sin((120 * Math.PI) / 180),
        this.foundation.ponHeight / 2 + this.foundation.ponStart,
      );
      pontoonGeometry3.translate(
        (this.foundation.ccDistance / 2) * Math.cos((240 * Math.PI) / 180),
        (this.foundation.ccDistance / 2) * Math.sin((240 * Math.PI) / 180),
        this.foundation.ponHeight / 2 + this.foundation.ponStart,
      );
      const pontoonMesh1 = new THREE.Mesh(pontoonGeometry1, mat);
      const pontoonMesh2 = new THREE.Mesh(pontoonGeometry2, mat);
      const pontoonMesh3 = new THREE.Mesh(pontoonGeometry3, mat);
      this.scene.add(pontoonMesh1, pontoonMesh2, pontoonMesh3);
    } else if (isSemiPeripheral(this.foundation)) {
      const cornerColGeometry1 = new THREE.CylinderGeometry(
        this.foundation.cornerColDiameter / 2,
        this.foundation.cornerColDiameter / 2,
        this.foundation.cornerColHeight,
        32,
      );
      const pontoonGeometry1 = new THREE.BoxGeometry(
        this.foundation.ponWidth,
        this.foundation.ponHeight,
        this.foundation.ccDistance * Math.sqrt(3),
      );
      const deckGeometry1 = new THREE.BoxGeometry(
        this.foundation.deckWidth,
        this.foundation.deckHeight,
        this.foundation.ccDistance * Math.sqrt(3),
      );
      cornerColGeometry1.rotateX((90 * Math.PI) / 180);
      const cornerColGeometry2 = cornerColGeometry1.clone();
      const cornerColGeometry3 = cornerColGeometry1.clone();
      cornerColGeometry1.translate(
        this.foundation.ccDistance - this.foundation.ccDistance,
        0,
        this.foundation.cornerColHeight / 2 + this.foundation.cornerColStart,
      );
      cornerColGeometry2.translate(
        this.foundation.ccDistance * Math.cos((120 * Math.PI) / 180) -
          this.foundation.ccDistance,
        this.foundation.ccDistance * Math.sin((120 * Math.PI) / 180),
        this.foundation.cornerColHeight / 2 + this.foundation.cornerColStart,
      );
      cornerColGeometry3.translate(
        this.foundation.ccDistance * Math.cos((240 * Math.PI) / 180) -
          this.foundation.ccDistance,
        this.foundation.ccDistance * Math.sin((240 * Math.PI) / 180),
        this.foundation.cornerColHeight / 2 + this.foundation.cornerColStart,
      );
      const cornerColMesh1 = new THREE.Mesh(cornerColGeometry1, mat);
      const cornerColMesh2 = new THREE.Mesh(cornerColGeometry2, mat);
      const cornerColMesh3 = new THREE.Mesh(cornerColGeometry3, mat);
      this.scene.add(cornerColMesh1, cornerColMesh2, cornerColMesh3);

      pontoonGeometry1.rotateX((90 * Math.PI) / 180);
      const pontoonGeometry2 = pontoonGeometry1.clone();
      const pontoonGeometry3 = pontoonGeometry1.clone();
      pontoonGeometry2.rotateZ((120 * Math.PI) / 180);
      pontoonGeometry3.rotateZ((240 * Math.PI) / 180);
      pontoonGeometry1.translate(
        (this.foundation.ccDistance / 2) * Math.cos((180 * Math.PI) / 180) -
          this.foundation.ccDistance,
        (this.foundation.ccDistance / 2) * Math.sin((180 * Math.PI) / 180),
        this.foundation.ponHeight / 2 + this.foundation.ponStart,
      );
      pontoonGeometry2.translate(
        (this.foundation.ccDistance / 2) * Math.cos((300 * Math.PI) / 180) -
          this.foundation.ccDistance,
        (this.foundation.ccDistance / 2) * Math.sin((300 * Math.PI) / 180),
        this.foundation.ponHeight / 2 + this.foundation.ponStart,
      );
      pontoonGeometry3.translate(
        (this.foundation.ccDistance / 2) * Math.cos((60 * Math.PI) / 180) -
          this.foundation.ccDistance,
        (this.foundation.ccDistance / 2) * Math.sin((60 * Math.PI) / 180),
        this.foundation.ponHeight / 2 + this.foundation.ponStart,
      );
      const pontoonMesh1 = new THREE.Mesh(pontoonGeometry1, mat);
      const pontoonMesh2 = new THREE.Mesh(pontoonGeometry2, mat);
      const pontoonMesh3 = new THREE.Mesh(pontoonGeometry3, mat);
      this.scene.add(pontoonMesh1, pontoonMesh2, pontoonMesh3);

      deckGeometry1.rotateX((90 * Math.PI) / 180);
      const deckGeometry2 = deckGeometry1.clone();
      const deckGeometry3 = deckGeometry1.clone();
      deckGeometry2.rotateZ((120 * Math.PI) / 180);
      deckGeometry3.rotateZ((240 * Math.PI) / 180);
      deckGeometry1.translate(
        (this.foundation.ccDistance / 2) * Math.cos((180 * Math.PI) / 180) -
          this.foundation.ccDistance,
        (this.foundation.ccDistance / 2) * Math.sin((180 * Math.PI) / 180),
        this.foundation.deckHeight / 2 + this.foundation.deckStart,
      );
      deckGeometry2.translate(
        (this.foundation.ccDistance / 2) * Math.cos((300 * Math.PI) / 180) -
          this.foundation.ccDistance,
        (this.foundation.ccDistance / 2) * Math.sin((300 * Math.PI) / 180),
        this.foundation.deckHeight / 2 + this.foundation.deckStart,
      );
      deckGeometry3.translate(
        (this.foundation.ccDistance / 2) * Math.cos((60 * Math.PI) / 180) -
          this.foundation.ccDistance,
        (this.foundation.ccDistance / 2) * Math.sin((60 * Math.PI) / 180),
        this.foundation.deckHeight / 2 + this.foundation.deckStart,
      );
      const deckMesh1 = new THREE.Mesh(deckGeometry1, mat);
      const deckMesh2 = new THREE.Mesh(deckGeometry2, mat);
      const deckMesh3 = new THREE.Mesh(deckGeometry3, mat);
      this.scene.add(deckMesh1, deckMesh2, deckMesh3);
    } else if (isSpar(this.foundation)) {
      const topGeometry = new THREE.CylinderGeometry(
        this.foundation.topDiameter / 2,
        this.foundation.topDiameter / 2,
        this.foundation.colHeight -
          this.foundation.draft -
          this.foundation.upperTaperLevel,
        32,
      );
      const taperGeometry = new THREE.CylinderGeometry(
        this.foundation.topDiameter / 2,
        this.foundation.baseDiameter / 2,
        this.foundation.upperTaperLevel - this.foundation.lowerTaperLevel,
        32,
      );
      const baseGeometry = new THREE.CylinderGeometry(
        this.foundation.baseDiameter / 2,
        this.foundation.baseDiameter / 2,
        this.foundation.draft + this.foundation.lowerTaperLevel,
        32,
      );
      topGeometry.rotateX((90 * Math.PI) / 180);
      taperGeometry.rotateX((90 * Math.PI) / 180);
      baseGeometry.rotateX((90 * Math.PI) / 180);
      topGeometry.translate(
        0,
        0,
        this.foundation.colHeight -
          this.foundation.draft -
          (this.foundation.colHeight -
            this.foundation.draft -
            this.foundation.upperTaperLevel) /
            2,
      );
      taperGeometry.translate(
        0,
        0,
        this.foundation.lowerTaperLevel +
          (this.foundation.upperTaperLevel - this.foundation.lowerTaperLevel) /
            2,
      );
      baseGeometry.translate(
        0,
        0,
        -this.foundation.draft +
          (this.foundation.draft + this.foundation.lowerTaperLevel) / 2,
      );
      const topMesh = new THREE.Mesh(topGeometry, mat);
      const taperMesh = new THREE.Mesh(taperGeometry, mat);
      const baseMesh = new THREE.Mesh(baseGeometry, mat);
      this.scene.add(topMesh, taperMesh, baseMesh);
    } else if (
      isMonopile(this.foundation) ||
      isDetailedMonopile(this.foundation)
    ) {
      const waterDepth = this.waterDepth;
      const pileDiameter = 12;
      const pileLength = waterDepth + 20;
      const pileGeometry = new THREE.CylinderGeometry(
        pileDiameter / 2,
        pileDiameter / 2,
        pileLength,
        32,
      );
      pileGeometry.rotateX((90 * Math.PI) / 180);
      pileGeometry.translate(0, 0, -pileLength / 2 + 20);
      const pileMesh = new THREE.Mesh(pileGeometry, mat);
      this.scene.add(pileMesh);
    } else if (isJacket(this.foundation)) {
      const waterDepth = this.waterDepth;
      const waterDepthRef = 60;
      const lengthAboveWL = 10;
      const legDiameter = 2.6;
      const braceDiameter = 1.3;
      const deckHeight = 3.0;
      const h0 = (6.4 * waterDepth) / waterDepthRef,
        h1 = (16.6 * waterDepth) / waterDepthRef,
        h2 = (14.0 * waterDepth) / waterDepthRef,
        h3 = (11.64 * waterDepth) / waterDepthRef,
        h4 = (13.44 * waterDepth) / waterDepthRef,
        h5 = (7.92 * waterDepth) / waterDepthRef;
      const h = [h0, h1, h2, h3, h4, h5];
      const d0 = 25,
        d5 = 14;
      const d1 = d0 + ((d5 - d0) * h1) / (h1 + h2 + h3 + h4 + h5);
      const d2 = d0 + ((d5 - d0) * (h1 + h2)) / (h1 + h2 + h3 + h4 + h5);
      const d3 = d0 + ((d5 - d0) * (h1 + h2 + h3)) / (h1 + h2 + h3 + h4 + h5);
      const d4 =
        d0 + ((d5 - d0) * (h1 + h2 + h3 + h4)) / (h1 + h2 + h3 + h4 + h5);
      const d = [d0, d1, d2, d3, d4, d5];
      const l: number[] = [];
      for (let i = 0; i < 5; i++) {
        l.push(
          Math.sqrt(
            Math.pow(d[i] / 2 + d[i + 1] / 2, 2) + Math.pow(h[i + 1], 2),
          ),
        );
      }
      const angle: number[] = [];
      for (let i = 0; i < 5; i++) {
        angle.push(Math.atan(h[i + 1] / (d[i] / 2 + d[i + 1] / 2)));
      }
      const legLength = Math.sqrt(
        Math.pow(d0 / 2 - d5 / 2, 2) +
          Math.pow(lengthAboveWL + waterDepth - h0, 2),
      );
      const legAngle = Math.atan(
        (d0 / 2 - d5 / 2) / (lengthAboveWL + waterDepth - h0),
      );

      for (let i = 0; i < 4; i++) {
        const legGeometry = new THREE.CylinderGeometry(
          legDiameter / 2,
          legDiameter / 2,
          legLength,
          32,
        );
        legGeometry.rotateX((90 * Math.PI) / 180 - legAngle);
        legGeometry.rotateY(legAngle);
        legGeometry.translate(
          -d0 / 2 + (legLength / 2) * Math.sin(legAngle),
          -d0 / 2 + (legLength / 2) * Math.sin(legAngle),
          -waterDepth + legLength / 2 + h0,
        );
        legGeometry.rotateZ((90 * i * Math.PI) / 180);
        this.scene.add(new THREE.Mesh(legGeometry, mat));
      }

      const bGeometry: any[][] = [[], [], [], []];
      let braceHeight = 0;
      for (let j = 0; j < 5; j++) {
        braceHeight += h[j];
        const braceGeometry = new THREE.CylinderGeometry(
          braceDiameter / 2,
          braceDiameter / 2,
          l[j],
          32,
        );
        braceGeometry.rotateX(angle[j]);
        braceGeometry.rotateY(legAngle);
        braceGeometry.translate(
          -d[j] / 2 + (l[j] / 2) * Math.sin(legAngle),
          -d[j] / 2 + (l[j] / 2) * Math.cos(angle[j]),
          -waterDepth + braceHeight + (Math.tan(angle[j]) * d[j]) / 2,
        );
        bGeometry[0].push(braceGeometry);
      }

      for (let j = 0; j < 5; j++) {
        const braceGeometry = bGeometry[0][j].clone();
        braceGeometry.applyMatrix4(new THREE.Matrix4().makeScale(-1, 1, 1));
        bGeometry[0].push(braceGeometry);
      }

      for (let i = 1; i < 4; i++) {
        for (let j = 0; j < 10; j++) {
          const braceGeometry = bGeometry[0][j].clone();
          braceGeometry.rotateZ((90 * i * Math.PI) / 180);
          bGeometry[i].push(braceGeometry);
        }
      }

      for (let i = 0; i < 4; i++) {
        for (let j = 0; j < 10; j++) {
          this.scene.add(new THREE.Mesh(bGeometry[i][j], mat));
        }
      }

      const deckGeometry = new THREE.BoxGeometry(
        d5 + legDiameter,
        d5 + legDiameter,
        deckHeight,
      );
      deckGeometry.translate(0, 0, lengthAboveWL);
      this.scene.add(new THREE.Mesh(deckGeometry, mat));
    }

    this.map = map;

    // use the Mapbox GL JS map canvas for three.js
    this.renderer = new THREE.WebGLRenderer({
      canvas: map.getCanvas(),
      context: gl,
      antialias: true,
    });

    this.renderer.autoClear = false;
  }

  onRemove(_map: mapboxgl.Map, _gl: WebGLRenderingContext): void {
    this.scene?.traverse((o) => dispose(o));
    this.renderer?.dispose();
  }

  renderSingle(gl: WebGLRenderingContext, matrix: number[], index: number) {
    if (
      !this.camera ||
      !this.modelTransforms ||
      !this.renderer ||
      !this.map ||
      !this.scene
    )
      return;
    const transform = this.modelTransforms[index];
    const rotationX = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(1, 0, 0),
      transform.rotateX,
    );
    const rotationY = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(0, 1, 0),
      transform.rotateY,
    );
    const rotationZ = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(0, 0, 1),
      ((-90 - this.direction) * Math.PI) / 180,
    );
    const m = new THREE.Matrix4().fromArray(matrix);
    const l = new THREE.Matrix4()
      .makeTranslation(
        transform.translateX,
        transform.translateY,
        transform.translateZ,
      )
      .scale(
        new THREE.Vector3(transform.scale, -transform.scale, transform.scale),
      )
      .multiply(rotationX)
      .multiply(rotationY)
      .multiply(rotationZ);

    this.camera.projectionMatrix = m.multiply(l);
    this.renderer.resetState();
    this.renderer.render(this.scene, this.camera);
    this.map.triggerRepaint();
  }

  render(gl: WebGLRenderingContext, matrix: number[]) {
    LAYER_DEBUG_PRINT && console.time("ThreeDFoundations.render");
    if (this.turbineLonlats.length > 0) {
      for (let i = 0; i < (this.modelTransforms ?? []).length; i++) {
        this.renderSingle(gl, matrix, i);
      }
    }
    LAYER_DEBUG_PRINT && console.timeEnd("ThreeDFoundations.render");
  }
}
