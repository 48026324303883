export function disposeObject(threeObject: any) {
  if (!threeObject) {
    return;
  }
  if (threeObject.parent) {
    threeObject.parent.remove(threeObject);
  }

  // If the object has children, recursively dispose of them
  if (threeObject.children.length > 0) {
    for (var x = threeObject.children.length - 1; x >= 0; x--) {
      disposeObject(threeObject.children[x]);
    }
  }

  // Dispose geometry
  if (threeObject.geometry) {
    threeObject.geometry.dispose();
  }

  // Dispose material
  if (threeObject.material) {
    if (Array.isArray(threeObject.material)) {
      threeObject.material.forEach((material: any) => {
        if (material.map) material.map.dispose();
        if (material.lightMap) material.lightMap.dispose();
        if (material.bumpMap) material.bumpMap.dispose();
        if (material.normalMap) material.normalMap.dispose();
        if (material.specularMap) material.specularMap.dispose();
        if (material.envMap) material.envMap.dispose();
        material.dispose(); // disposes any programs associated with the material
      });
    } else {
      if (threeObject.material.map) threeObject.material.map.dispose();
      if (threeObject.material.lightMap)
        threeObject.material.lightMap.dispose();
      if (threeObject.material.bumpMap) threeObject.material.bumpMap.dispose();
      if (threeObject.material.normalMap)
        threeObject.material.normalMap.dispose();
      if (threeObject.material.specularMap)
        threeObject.material.specularMap.dispose();
      if (threeObject.material.envMap) threeObject.material.envMap.dispose();
      threeObject.material.dispose(); // disposes any programs associated with the material
    }
  }

  // Dispose textures if object is a texture
  if (threeObject.isTexture) {
    threeObject.dispose();
  }
}

export const verticalFovToHorizontalFov = (
  cameraAspect: number | undefined,
  fov: number,
) =>
  cameraAspect != null
    ? Math.round(
        (2 *
          Math.atan(Math.tan((fov * Math.PI) / 180 / 2) * cameraAspect) *
          180) /
          Math.PI,
      )
    : undefined;
