/// <reference types="vite-plugin-svgr/client" />
import { useRecoilValue } from "recoil";

import { inReadOnlyModeSelector } from "../../../../state/project";

import {
  localCostConfigurationAtom,
  useLocalCostConfigurationCrud,
} from "../state";
import { InputDimensioned, InputNumber } from "../../../General/Input";

import InformationIcon from "@icons/24/Information.svg?react";
import PhasingTable from "./PhasingTable";
import styled from "styled-components";
import { typography } from "styles/typography";
import { ColumnWrapper, Header, Label, RowItem, RowWrapper } from "../styles";
import { roundToDecimal } from "utils/utils";
import Tooltip from "components/General/Tooltip";
import { SpecificLcoeFormula } from "components/FinancialAnalysis/Lcoe/LcoeFormula";
import { currentYear } from "utils/inflation";
import {
  ARTICLE_FINANCIAL_ANALYSIS,
  HelpLink,
} from "components/HelpTooltip/HelpTooltip";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 0 1.6rem;
`;

export const Lcoe = ({ isReadOnly }: { isReadOnly?: boolean }) => {
  const inReadOnlyMode = useRecoilValue(inReadOnlyModeSelector) || isReadOnly;

  const {
    lcoe: { discountRate },
    lifeCycle: { projectStart, operationStart, decomissioning },
    inflation: { inflationRate },
    contingency: {
      capex: { value: capexContigency },
    },
  } = useRecoilValue(localCostConfigurationAtom);
  const { updateLCOE, updateInflation, updateLifeCycle, updateContingency } =
    useLocalCostConfigurationCrud();

  const lifetime = decomissioning - operationStart;

  return (
    <Form>
      <ColumnWrapper>
        <Header>Project lifetime</Header>
        <RowWrapper>
          <RowItem>
            <Label>
              Project start
              <InputNumber
                disabled={inReadOnlyMode}
                value={projectStart}
                onChange={(projectStart) =>
                  updateLifeCycle({
                    projectStart,
                  })
                }
                validate={(projectStart) =>
                  projectStart >= 1950 && projectStart <= 2050
                }
                validationMessage="Please enter a project start year between 1950 and 2050"
              />
            </Label>
          </RowItem>
          <RowItem>
            <Label>
              Operation start
              <InputNumber
                disabled={inReadOnlyMode}
                value={operationStart}
                onChange={(operationStart) =>
                  updateLifeCycle({
                    operationStart,
                  })
                }
                validate={(operationStart) =>
                  operationStart >= projectStart &&
                  operationStart < projectStart + 10
                }
                validationMessage={`Please enter an operation start year that is no earlier than the project start year of ${projectStart} and no later than ${
                  projectStart + 9
                }.`}
              />
            </Label>
          </RowItem>
          <RowItem>
            <Label>
              Decomissioning
              <InputNumber
                disabled={inReadOnlyMode}
                value={decomissioning}
                onChange={(decomissioning) =>
                  updateLifeCycle({
                    decomissioning,
                  })
                }
                validate={(decomissioning) =>
                  decomissioning - operationStart >= 10 &&
                  decomissioning - operationStart <= 50
                }
                validationMessage={`Please enter a decomissioning year between ${
                  operationStart + 10
                } and ${
                  operationStart + 50
                }, for a life time in the expected range of 10 to 50 years.`}
              />
            </Label>
          </RowItem>
        </RowWrapper>
        <Label left={true}>
          Life time: <p style={typography.semiBold}>{lifetime} years</p>
        </Label>
      </ColumnWrapper>

      <Header>Phasing</Header>
      <PhasingTable
        projectStart={projectStart}
        operationStart={operationStart}
        isReadOnly={isReadOnly}
      />

      <ColumnWrapper style={{ width: "17rem" }}>
        <Header>Contingency</Header>
        <Label>
          <Tooltip text="Added to the CAPEX when performing LCoE and IRR analysis.">
            CAPEX
            <InformationIcon style={{ height: "1.3rem" }} />
          </Tooltip>
          <RowWrapper style={{ width: "30rem", alignItems: "center" }}>
            <RowItem style={{ width: "12rem" }}>
              <InputDimensioned
                disabled={inReadOnlyMode}
                type={"number"}
                style={{ width: "15rem" }}
                unit="%"
                value={roundToDecimal(capexContigency * 100, 1)}
                validate={(value) => value >= 0 && value <= 50}
                validationMessage={
                  "CAPEX contingency must be between 0% and 50%"
                }
                onChange={(v) =>
                  updateContingency({
                    capex: { type: "fraction", value: v / 100 },
                  })
                }
                step={0.1}
              />
            </RowItem>
          </RowWrapper>
        </Label>
      </ColumnWrapper>

      <ColumnWrapper>
        <Header>Rates</Header>
        <RowWrapper>
          <RowItem>
            <Label>
              <Tooltip text="Also referred to as the nominal WACC.">
                Discount rate (nominal)
                <InformationIcon style={{ height: "1.3rem" }} />
              </Tooltip>
              <InputDimensioned
                disabled={inReadOnlyMode}
                type={"number"}
                style={{ width: "15rem" }}
                unit="%"
                value={roundToDecimal(discountRate * 100, 1)}
                validate={(value) => value >= 0 && value <= 20}
                validationMessage={"Discount rate must be between 0% and 20%"}
                onChange={(v) => updateLCOE({ discountRate: v / 100 })}
                step={0.1}
              />
            </Label>
          </RowItem>
          <RowItem>
            <Label>
              Inflation rate
              <InputDimensioned
                disabled={inReadOnlyMode}
                type={"number"}
                style={{ width: "15rem" }}
                unit="%"
                value={roundToDecimal(inflationRate * 100, 1)}
                validate={(value) => value >= 0 && value <= 20}
                validationMessage={"Inflation rate must be between 0% and 20%"}
                onChange={(v) => updateInflation({ inflationRate: v / 100 })}
                step={0.1}
              />
            </Label>
          </RowItem>
        </RowWrapper>
        <Label left={true}>
          Real discount rate:{" "}
          <p style={typography.semiBold}>{`${(
            ((1 + discountRate) / (1 + inflationRate) - 1) *
            100
          ).toFixed(2)}%`}</p>
        </Label>
      </ColumnWrapper>
      <ColumnWrapper>
        <Header style={{ display: "flex", alignItems: "center" }}>
          LCoE formula
          <HelpLink article={ARTICLE_FINANCIAL_ANALYSIS}></HelpLink>
        </Header>
        <RowWrapper>
          <RowItem>
            <SpecificLcoeFormula
              discountRate={discountRate}
              inflationRate={inflationRate}
              projectStart={projectStart}
              operationStart={operationStart}
              decomissioning={decomissioning}
              currentYear={currentYear()}
            />
          </RowItem>
        </RowWrapper>
      </ColumnWrapper>
    </Form>
  );
};
