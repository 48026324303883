import { Place } from "../../General/Anchor";
import CloseableHint from "../CloseableHints/CloseableHint";
import changeFeatureTypeHelp from "./changeFeatureTypeHelp.mp4";

export const changeFeatureHelpHintType = "changeFeatureHelpHintType";

const ChangeFeatureTypeHelp = ({
  closeCallback,
  gotItCallback,
  position,
}: {
  closeCallback: () => void;
  gotItCallback?: () => void;
  position?: Place;
}) => {
  return (
    <CloseableHint
      title={"Change feature type"}
      closeCallback={closeCallback}
      gotItCallback={gotItCallback}
      timeToDissapearMS={10000}
      videosrc={changeFeatureTypeHelp}
      position={position}
    >
      {`You can easily change the type of the feature selected using the drop down menu`}
    </CloseableHint>
  );
};

export default ChangeFeatureTypeHelp;
