/// <reference types="vite-plugin-svgr/client" />
import React, { useCallback, useState } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import {
  spaceLarge,
  spaceLarge_2,
  spaceMedium,
  spaceSmall,
  spaceTiny,
} from "styles/space";
import { colors } from "styles/colors";
import AddIcon from "@icons/24/Add.svg?react";
import CheckIcon from "@icons/24/Check.svg?react";
import EarthIcon from "@icons/24/Earth.svg?react";
import FolderIcon from "@icons/14/Folder.svg?react";
import RemoveIcon from "@icons/24/Remove.svg?react";
import TeamMeetingIcon from "@icons/24/TeamMeeting.svg?react";
import {
  CreatedOrganisationInvitation,
  useAddMultipleToOrganisation,
  UserAlreadyExistedInvitation,
  useUpdateInvitation,
} from "hooks/useUser";
import { Organisation } from "components/Organisation/service";
import useUserNodeAccessCrud from "components/Organisation/Members/useUserNodeAccessCrud";
import useMemberInGroupCrud from "components/Organisation/Groups/useMemberInGroupCrud";
import { useToast } from "hooks/useToast";
import useBooleanState from "hooks/useBooleanState";
import { CheckIconWrapper, InvitationNodeAccess } from "./shared";
import { Group } from "components/Organisation/Groups/types";
import { scream } from "utils/sentry";
import { Row } from "components/General/Layout";
import { IconREMSize, typography } from "styles/typography";
import GroupPickerFrame from "./GroupPickerFrame";
import ProjectPickerFrame from "./ProjectPickerFrame";
import Button from "components/General/Button";
import Tooltip from "components/General/Tooltip";
import Spinner from "@icons/spinner/Spinner";
import { _UserAccessRole, UserAccessRole, UserAccessRoles } from "types/user";
import { getNodeSelectorFamily } from "components/Projects/useOrganisationFolderCrud";
import Dropdown from "components/Dropdown/Dropdown";
import { capitalize } from "utils/utils";
import { useRefreshInvitations } from "state/customer";

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaceMedium};
  width: 100%;

  > :nth-child(odd) {
    background-color: ${colors.focusBackground};
  }
`;

const NodeRow = ({
  organisationId,
  nodeAccess,
  onChangeRole,
  onRemove,
}: {
  organisationId: string;
  nodeAccess: InvitationNodeAccess;
  onChangeRole(role: UserAccessRole): void;
  onRemove(): void;
}) => {
  const node = useRecoilValue(
    getNodeSelectorFamily({ organisationId, nodeId: nodeAccess.nodeId }),
  );

  if (!node) {
    return <span>Error: Node not found</span>;
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: spaceSmall,
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: spaceSmall,
        }}
      >
        <IconREMSize height={1.6} width={1.6}>
          {node.type === "project" ? <EarthIcon /> : <FolderIcon />}
        </IconREMSize>
        <p
          style={{
            ...typography.contentAndButtons,
          }}
        >
          {node.name}
        </p>
      </div>

      <div style={{ display: "flex", gap: spaceSmall }}>
        <Dropdown
          kind="hidden"
          value={nodeAccess.role ?? ""}
          onChange={async (e) => {
            const newRole = _UserAccessRole.parse(e.target.value);
            onChangeRole(newRole);
          }}
        >
          <option value="" disabled hidden>
            Select access
          </option>
          {UserAccessRoles.map((role) => (
            <option key={role} value={role}>
              {capitalize(role)}
            </option>
          ))}
        </Dropdown>
        <IconREMSize
          height={1.4}
          width={1.4}
          style={{
            cursor: "pointer",
          }}
          onClick={onRemove}
        >
          <RemoveIcon />
        </IconREMSize>
      </div>
    </div>
  );
};

const GroupRow = ({ group, onRemove }: { group: Group; onRemove(): void }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: spaceSmall,
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: spaceSmall,
        }}
      >
        <IconREMSize height={1.6} width={1.6}>
          <TeamMeetingIcon />
        </IconREMSize>
        <p
          style={{
            ...typography.contentAndButtons,
          }}
        >
          {group.name}
        </p>
      </div>

      <div style={{ display: "flex", gap: spaceSmall }}>
        <IconREMSize
          height={1.4}
          width={1.4}
          style={{
            cursor: "pointer",
          }}
          onClick={onRemove}
        >
          <RemoveIcon />
        </IconREMSize>
      </div>
    </div>
  );
};

const AfterInviteResult = ({
  isOrgAdmin,
  inviteResult,
  organisation,
  onClose,
}: {
  isOrgAdmin: boolean;
  inviteResult: Awaited<
    ReturnType<ReturnType<typeof useAddMultipleToOrganisation>>
  >;
  organisation: Organisation;
  onClose(): void;
}) => {
  const updateInvitation = useUpdateInvitation(organisation.id);
  const refreshInvitations = useRefreshInvitations();
  const { addOrUpdate } = useUserNodeAccessCrud();
  const { add: addToGroup } = useMemberInGroupCrud();
  const { success: showSuccess } = useToast();
  const [projectPickerOpen, toggleProjectPickerOpen] = useBooleanState(false);
  const [groupPickerOpen, toggleGroupPickerOpen] = useBooleanState(false);
  const [updatingInvites, setUpdatingInvites] = useState(false);
  const invitedEmails = inviteResult.map((invite) => invite.user_email);
  const [nodesAccess, setNodesAccess] = useState<InvitationNodeAccess[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);
  const confirmButtonDisabled =
    nodesAccess.some((n) => !n.role) ||
    (selectedGroups.length === 0 && nodesAccess.length === 0);

  const resetInvitationsAndClose = useCallback(() => {
    refreshInvitations();
    onClose();
  }, [onClose, refreshInvitations]);

  const onConfirmClick = useCallback(async () => {
    const createdInvites = inviteResult.filter(
      (result) => result.status === "INVITATION_CREATED",
    ) as CreatedOrganisationInvitation[];

    const usersExisted = inviteResult.filter(
      (result) => result.status === "USER_ALREADY_EXISTED",
    ) as UserAlreadyExistedInvitation[];

    const groupIds = selectedGroups.map((g) => g.id);

    setUpdatingInvites(true);
    try {
      const invitationPromises = createdInvites.map((invitation) => {
        return updateInvitation(
          invitation.invitationId,
          nodesAccess as any,
          groupIds,
        );
      });

      const existingUserNodeAccessPromises = usersExisted.flatMap((user) => {
        return nodesAccess.map(async (node) => {
          return addOrUpdate(user.user_id, node.nodeId, node.role!);
        });
      });

      const existingUserGroupAccessPromises = usersExisted.flatMap((user) => {
        return groupIds.map(async (groupId) => {
          return addToGroup(groupId, user.user_id);
        });
      });

      await Promise.all([
        ...invitationPromises,
        ...existingUserNodeAccessPromises,
        ...existingUserGroupAccessPromises,
      ]);

      const nrInvitedUsers = createdInvites.length + usersExisted.length;

      if (nrInvitedUsers > 0) {
        showSuccess(
          `${createdInvites.length} invitation${
            createdInvites.length !== 1 ? "s" : ""
          } updated`,
          {
            timeout: 5000,
          },
        );
      }
      resetInvitationsAndClose();
    } catch (error) {
      const extra = {
        inviteResult,
        nodesAccess,
        selectedGroups,
      };
      if (error instanceof Error) {
        scream(error, extra);
      } else {
        scream("Something went wrong when inviting users", extra);
      }
    } finally {
      setUpdatingInvites(false);
    }
  }, [
    addOrUpdate,
    addToGroup,
    inviteResult,
    nodesAccess,
    resetInvitationsAndClose,
    selectedGroups,
    showSuccess,
    updateInvitation,
  ]);

  return (
    <>
      <Row style={{ alignItems: "center" }}>
        <CheckIconWrapper>
          <IconREMSize height={1.6} width={1.6}>
            <CheckIcon />
          </IconREMSize>
        </CheckIconWrapper>
        <div style={{ display: "flex", gap: spaceTiny, flexWrap: "wrap" }}>
          {invitedEmails.map((email) => (
            <span
              style={{
                ...typography.contentAndButtons,
              }}
              key={email}
            >
              {email}
            </span>
          ))}
          <span
            style={{
              ...typography.contentAndButtons,
            }}
          >
            is invited to {organisation?.name ?? "the organisation"}
          </span>
        </div>
      </Row>
      {isOrgAdmin && (
        <Row>
          {groupPickerOpen && (
            <div style={{ position: "relative", overflow: "visible" }}>
              <GroupPickerFrame
                selectedGroups={selectedGroups}
                onClose={toggleGroupPickerOpen}
                organisationId={organisation.id}
                setSelectedGroups={setSelectedGroups}
              />
            </div>
          )}
        </Row>
      )}
      <Row>
        {projectPickerOpen && (
          <div style={{ position: "relative", overflow: "visible" }}>
            <ProjectPickerFrame
              organisationId={organisation.id}
              isOrgAdmin={isOrgAdmin}
              setNodesAccess={setNodesAccess}
              onClose={toggleProjectPickerOpen}
              selectedNodeIds={nodesAccess.map((n) => n.nodeId)}
            />
          </div>
        )}
      </Row>
      <Row>
        <div
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: colors.inputOutline,
            margin: `${spaceLarge_2} 0`,
          }}
        />
      </Row>
      {nodesAccess.length === 0 && selectedGroups.length === 0 && (
        <Row style={{ marginBottom: spaceLarge }}>
          <h5 style={{ margin: 0 }}>
            Select projects{isOrgAdmin ? " and/or groups" : ""}
          </h5>
        </Row>
      )}

      {nodesAccess.length > 0 && (
        <Row style={{ flexDirection: "column", marginBottom: spaceLarge }}>
          <p style={{ ...typography.contentAndButtons }}>Selected projects</p>
          <List>
            {nodesAccess.length > 0 ? (
              nodesAccess.map((node) => (
                <NodeRow
                  key={node.nodeId}
                  nodeAccess={node}
                  organisationId={organisation.id}
                  onChangeRole={(role) => {
                    setNodesAccess((curr) =>
                      curr.map((n) =>
                        n.nodeId === node.nodeId ? { ...n, role: role } : n,
                      ),
                    );
                  }}
                  onRemove={() => {
                    setNodesAccess((curr) =>
                      curr.filter((n) => n.nodeId !== node.nodeId),
                    );
                  }}
                />
              ))
            ) : (
              <p>No projects or groups selected</p>
            )}
          </List>
        </Row>
      )}
      {selectedGroups.length > 0 && (
        <Row style={{ flexDirection: "column", marginBottom: spaceLarge }}>
          <p style={{ ...typography.contentAndButtons }}>Selected groups</p>
          <List>
            {selectedGroups.map((group) => (
              <GroupRow
                key={group.id}
                group={group}
                onRemove={() => {
                  setSelectedGroups((curr) =>
                    curr.filter((g) => g.id !== group.id),
                  );
                }}
              />
            ))}
          </List>
        </Row>
      )}
      <Row style={{ marginBottom: spaceLarge }}>
        <Button
          buttonType="secondary"
          text="Add to project"
          disabled={updatingInvites}
          icon={<AddIcon />}
          style={{
            flexGrow: 1,
          }}
          onClick={toggleProjectPickerOpen}
        />
        {isOrgAdmin && (
          <Button
            buttonType="secondary"
            disabled={updatingInvites}
            text="Add to group"
            icon={<AddIcon />}
            style={{
              flexGrow: 1,
            }}
            onClick={toggleGroupPickerOpen}
          />
        )}
      </Row>
      <Row style={{ justifyContent: "flex-end" }}>
        <Button
          buttonType="text"
          text="Skip for now"
          onClick={resetInvitationsAndClose}
          disabled={updatingInvites}
        />
        <Tooltip
          text="Select access level above to confirm"
          disabled={!confirmButtonDisabled}
        >
          <Button
            buttonType="primary"
            text="Confirm"
            disabled={confirmButtonDisabled || updatingInvites}
            icon={updatingInvites ? <Spinner size="0.5rem" /> : undefined}
            style={{
              flexGrow: 1,
            }}
            onClick={onConfirmClick}
          />
        </Tooltip>
      </Row>
    </>
  );
};

export default AfterInviteResult;
