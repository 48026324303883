import { selectorFamily } from "recoil";
import { CableType } from "../../../../services/cableTypeService";
import { CostType } from "../../../../services/costService";
import { getCablesInBranchSelectorFamily } from "../../../../state/cable";
import { CableFeature } from "../../../../types/feature";
import { allCableTypesSelector } from "../../../Cabling/Generate/state";
import { get3DCableLengthsInParkWithContingency } from "../../../Cabling/Generate/utils";
import { AmountUnit } from "../../../../types/financial";
import { Amount, amountId } from "../generalAmounts";
import { sumKeys } from "../../../../utils/utils";
import {
  ProdId,
  getBranchId,
  getCapacity,
  getParkId,
  getConfiguration,
} from "components/ProductionV2/state";

export const allCableTypesInParkSelectorFamily = selectorFamily<
  { cableTypes: CableType[]; lengthPerCableType: Record<string, number> },
  { branchId: string; parkId: string; analysisConfigurationId?: string }
>({
  key: "allCableTypesInParkSelectorFamily",
  get:
    ({ branchId, parkId, analysisConfigurationId }) =>
    ({ get }) => {
      const cables = get(getCablesInBranchSelectorFamily({ parkId, branchId }));
      const cableTypes = get(allCableTypesSelector);

      const cableLengths = get(
        get3DCableLengthsInParkWithContingency({
          parkId,
          branchId,
          analysisConfigurationId,
        }),
      );

      if (!cableLengths) return { cableTypes, lengthPerCableType: {} };

      const cableTypeIds = new Set(cableTypes.map((t) => t.id));
      const lengthPerCableType = sumKeys(
        cables.map((c: CableFeature) => {
          const len = cableLengths[c.id] ?? 0;
          if (
            !c.properties.cableTypeId ||
            !cableTypeIds.has(c.properties.cableTypeId)
          )
            return ["other", len];
          return [c.properties.cableTypeId, len];
        }),
      );

      return { cableTypes, lengthPerCableType };
    },
});

export const cableAmountsSelectorFamily = selectorFamily<Amount[], ProdId>({
  key: "cableAmountsSelectorFamily",
  get:
    (productionId) =>
    ({ get }) => {
      const branchId = get(getBranchId(productionId));
      const parkId = get(getParkId(productionId));
      const config = get(getConfiguration(productionId));

      const cables = get(getCablesInBranchSelectorFamily({ branchId, parkId }));

      const { cableTypes, lengthPerCableType } = get(
        allCableTypesInParkSelectorFamily({
          branchId,
          parkId,
          analysisConfigurationId: config.id,
        }),
      );

      const capacity = get(getCapacity(productionId));

      const kmPerType = cableTypes.map((cable) => {
        const amount = lengthPerCableType[cable.id] ?? 0;
        const unit = AmountUnit.km;
        const category = CostType.Cable;

        return {
          id: amountId({ unit, category, featureTypeId: cable.id }),
          unit,
          amount,
          category,
        };
      });

      const kmTotal = {
        id: amountId({
          unit: AmountUnit.km,
          category: CostType.Cable,
        }),
        amount: kmPerType.reduce((acc, { amount }) => acc + amount, 0),
        unit: AmountUnit.km,
        category: CostType.Cable,
      };

      const cableCount = {
        id: amountId({
          unit: AmountUnit.cable,
          category: CostType.Cable,
        }),
        unit: AmountUnit.cable,
        amount: cables.length,
        category: CostType.Cable,
      };

      const mwAmount = {
        id: amountId({
          unit: AmountUnit.MW,
          category: CostType.Cable,
        }),
        unit: AmountUnit.MW,
        amount: cables.length > 0 ? capacity ?? 0 : 0,
        category: CostType.Cable,
      };

      const fixedAmount = {
        id: amountId({
          unit: AmountUnit.fixed,
          category: CostType.Cable,
        }),
        unit: AmountUnit.fixed,
        amount: 1,
        category: CostType.Cable,
      };

      return [...kmPerType, kmTotal, mwAmount, cableCount, fixedAmount];
    },
});
