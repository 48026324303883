import { selectorFamily } from "recoil";
import { getProj4StringForEPSG } from "../services/epsgAPIService";

export const getProj4StringForEPSGSelectorFamily = selectorFamily<
  string | undefined,
  number | string | undefined
>({
  key: "getProj4StringForEPSGSelectorFamily",
  get: (epsg: number | string | undefined) => async () => {
    if (!epsg) return;
    const epsgNumber = typeof epsg === "string" ? parseInt(epsg) : epsg;
    if (isNaN(epsgNumber)) return;
    try {
      return await getProj4StringForEPSG(epsgNumber);
    } catch (e) {
      return;
    }
  },
});
