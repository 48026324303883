import { selectorFamily } from "recoil";
import { CostType } from "../../../../services/costService";

import { AmountUnit } from "../../../../types/financial";
import { Amount, amountId } from "../generalAmounts";
import { ProdId, getCapacity } from "components/ProductionV2/state";

export const otherAmountsSelectorFamily = selectorFamily<Amount[], ProdId>({
  key: "otherAmountsSelectorFamily",
  get:
    (productionId) =>
    ({ get }) => {
      const capacity = get(getCapacity(productionId));

      const category = CostType.Other;

      return [
        {
          id: amountId({
            unit: AmountUnit.MW,
            category,
          }),
          unit: AmountUnit.MW,
          amount: capacity ?? 0,
          category,
        },
        {
          id: amountId({
            unit: AmountUnit.fixed,
            category,
          }),
          unit: AmountUnit.fixed,
          amount: 1,
          category,
        },
      ];
    },
});
