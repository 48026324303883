import { useRecoilState, useRecoilValue } from "recoil";
import { CircleLayer, CirclePaint, Map } from "mapbox-gl";
import { mapRefAtom } from "../state/map";
import { SymbolLayer, VectorSource } from "mapbox-gl";
import {
  existingTurbinesExternalLayerId,
  getBeforeLayer,
} from "../components/Mapbox/utils";
import GenericFeature from "../components/MapFeatures/GenericFeature";
import {
  existingTurbinesInputAtom,
  lowerRightMenuActiveModeAtom,
} from "state/layer";
import { currentSelectionArrayAtom } from "state/selection";
import Add from "@icons/24/Add.svg?react";
import { ProjectFeature } from "types/feature";
import { Popup } from "components/Mapbox/Popup";
import {
  PopupDiv,
  SVGWrapper,
} from "components/DynamicSelectOption/Dynamic.style";
import PositionHint from "components/ActiveTips/PositionHints/PositionHint";
import { useCallback, useMemo } from "react";
import { getCenterOfFeatures } from "utils/geometry";
import useCloneFeaturesWithDefaultCanvasSource from "hooks/useCloneFeaturesWithDefaultCanvasSource";
import { useProjectElementsFoldersCrud } from "components/ProjectElementsV2/useProjectElementsFoldersCrud";
import { dedup } from "utils/utils";
import { smallMapCircleRadius } from "components/MapFeatures/expressionUtils";
import { editmodePropertyName } from "components/Mapbox/constants";
import {
  DEFAULT_NOT_IN_FOCUS_OPACITY,
  DEFAULT_SELECTED_OPACITY,
} from "@constants/canvas";

const ExistingTurbines = () => {
  const lowerRightActiveMode = useRecoilValue(lowerRightMenuActiveModeAtom);

  if (lowerRightActiveMode === "existingTurbines")
    return <ExistingTurbinesActive />;
  return null;
};

const turbinePaint: CirclePaint = {
  "circle-radius": smallMapCircleRadius,
  "circle-color": [
    "case",
    ["==", ["get", "power"], null],
    "#ff7a00",
    "#515867",
  ],
  "circle-opacity": [
    "case",
    [
      "boolean",
      ["feature-state", "hover"],
      ["feature-state", "selected"],
      false,
    ],
    DEFAULT_SELECTED_OPACITY,
    DEFAULT_NOT_IN_FOCUS_OPACITY + 0.2,
  ],
  // prettier-ignore
  "circle-stroke-width": [
    "case",
    ["==", ["get", editmodePropertyName], true], 3,
    ["!=", ["feature-state", "borderColor"], null], 2.0,
    ["boolean", ["feature-state", "selected"], false], 2.0,
    ["boolean", ["feature-state", "hover"], false], 1.0,
    0.0,
  ],
};

export const AddExistingTurbines = ({ map }: { map: Map }) => {
  const lowerRightActiveMode = useRecoilValue(lowerRightMenuActiveModeAtom);
  const selection = useRecoilValue(currentSelectionArrayAtom);
  const cloneFeatures = useCloneFeaturesWithDefaultCanvasSource();
  const {
    create: createFolder,
    update: updateFolder,
    items: folderItems,
  } = useProjectElementsFoldersCrud();

  const turbines = useMemo(() => {
    const allLayers = map.getLayer(existingTurbinesExternalLayerId);
    if (!allLayers || lowerRightActiveMode !== "existingTurbines") return [];
    const allTurbines = dedup(
      map.queryRenderedFeatures(undefined, {
        layers: [allLayers.id],
      }),
    );
    const rawTurbines = allTurbines.filter((t) =>
      selection.includes(`${t.id}`),
    );
    return rawTurbines.map(
      (raw) =>
        ({
          type: "Feature",
          geometry: raw.geometry,
          id: raw?.id,
          properties: {
            type: "existing-turbine",
            ...raw?.properties,
            name:
              "Existing turbine" +
              (raw?.properties?.name ? ` - ${raw.properties.name}` : ""),
          },
        }) as ProjectFeature,
    );
  }, [lowerRightActiveMode, map, selection]);

  const onClickDuplicate = useCallback(() => {
    const createdFeatures = cloneFeatures(
      // @ts-ignore: the reason this doesn't typecheck is that the
      // `MapboxGeoJSONFeature` is not a proper feature, since its geometry can
      // be clipped by mapbox internals. This causes the added feature to have
      // wrong geometry.  Should get the actual feature from somewhere else.
      turbines,
      "Added to Features",
      true,
    );
    const createdFeatureIds = createdFeatures.map((feature) => feature.id);
    const addToFolderName = "Existing turbines";
    const existingFolder = folderItems.find(
      (folder) => folder.folderName === addToFolderName,
    );
    if (existingFolder) {
      updateFolder({
        ...existingFolder,
        featureIds: existingFolder.featureIds.concat(
          createdFeatureIds.map((id) => ({ type: "feature", id })),
        ),
      });
    } else {
      createFolder({
        featureIds: createdFeatures.map((feature) => ({
          type: "feature",
          id: feature.id,
        })),
        folderName: addToFolderName,
      });
    }
  }, [turbines, updateFolder, createFolder, folderItems, cloneFeatures]);

  const pos = useMemo(() => {
    if (turbines.length === 0) return { lat: 0, lon: 0 };
    const p = getCenterOfFeatures(turbines);
    return {
      lng: p[0],
      lat: p[1],
    };
  }, [turbines]);

  if (lowerRightActiveMode !== "existingTurbines" || turbines.length === 0)
    return null;

  return (
    <Popup map={map} pos={pos} offsetPx={[0, -100]}>
      <PopupDiv>
        <PositionHint allowedHints={[]} position={"top"}>
          <SVGWrapper onClick={onClickDuplicate}>
            <Add
              style={{
                width: "2.4rem",
                height: "2.4rem",
              }}
            />
            <p>{`Add ${turbines.length} existing turbine${turbines.length > 1 ? "(s)" : ""}`}</p>
          </SVGWrapper>
        </PositionHint>
      </PopupDiv>
    </Popup>
  );
};

const emptyList: ProjectFeature[] = [];
const ExistingTurbinesActive = () => {
  const map = useRecoilValue(mapRefAtom);
  const [selection, setSelection] = useRecoilState(currentSelectionArrayAtom);
  const input = useRecoilValue(existingTurbinesInputAtom);

  const source = {
    type: "vector",
    url: "mapbox://vindai.offshore-turbines-v2",
  } as VectorSource;

  const layer = {
    id: existingTurbinesExternalLayerId,
    type: "circle",
    minzoom: 0,
    maxzoom: 14,
    source: "offshore-turbines-v2",
    "source-layer": "offshore-turbines-v2",
    paint: turbinePaint,
  };

  const symbolLayer = {
    id: existingTurbinesExternalLayerId + "symbol",
    type: "symbol",
    minzoom: 9,
    maxzoom: 14,
    source: "offshore-turbines-v2",
    "source-layer": "offshore-turbines-v2",
    layout: {
      "text-field": [
        "case",
        ["==", ["typeof", ["get", "power"]], "number"],
        ["concat", ["get", "power"], "MW"],
        "Unknown MW",
      ],
      "text-offset": [0, 1],
      "text-size": 7,
      "text-anchor": "top",
    },
  };

  if (!map || !layer || !source) return null;
  return (
    <GenericFeature
      features={emptyList}
      sourceId={layer["source-layer"] as string}
      source={source}
      layerId={existingTurbinesExternalLayerId}
      map={map}
      layers={[layer as CircleLayer, symbolLayer as SymbolLayer]}
      onClickCallback={(features, shiftClicked) => {
        const newSelection = features.map((f) => `${f.id}` as string);
        if (shiftClicked) {
          setSelection([...selection, ...newSelection]);
        } else {
          setSelection(newSelection);
        }
      }}
      beforeLayer={getBeforeLayer(map, existingTurbinesExternalLayerId)}
      sourceLayer={layer["source-layer"]}
      selectedIds={selection}
      featureFilter={(selectedFeatures) =>
        selectedFeatures.filter(
          (f) =>
            f.layer.id === existingTurbinesExternalLayerId &&
            f?.properties?.power !== null,
        )
      }
      filter={
        input.showTurbinesWithoutPower
          ? undefined
          : ["!=", ["get", "power"], null]
      }
    />
  );
};

export default ExistingTurbines;
