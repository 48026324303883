import { ABLY_RESOLVED_TOGGLE_FILTER } from "./../state/ably";
import {
  ABLY_REPLY_REACTIONS_ADD_FILTER,
  ABLY_REPLY_REACTIONS_REMOVE_FILTER,
  ABLY_THREADS_ADD_FILTER,
  ABLY_THREADS_REMOVE_FILTER,
  ABLY_THREAD_REACTIONS_ADD_FILTER,
  ABLY_THREAD_REACTIONS_REMOVE_FILTER,
} from "../state/ably";
import { useReplyCrud } from "../components/Comments/hooks/useReplyCrud";
import { useMemo } from "react";
import {
  ABLY_ADD_REPLIES_FILTER,
  ABLY_REMOVE_REPLIES_FILTER,
} from "../state/ably";
import { useReplyReactionCrud } from "../components/Comments/hooks/useReplyReactionCrud";
import { useResolveThreadCrud } from "../components/Comments/hooks/useResolveThreadCrud";
import { useThreadCrud } from "../components/Comments/hooks/useThreadCrud";
import { useThreadReactionCrud } from "../components/Comments/hooks/useThreadReactionCrud";
import { AblyEventHandler, useAblyGeneric } from "./useAblyGeneric";

export function useAblyComments(nodeId: string | undefined) {
  const { localPost: localPostReply, localRemove: localRemoveReply } =
    useReplyCrud();
  const { localPost: localPostThread, localRemove: localRemoveThread } =
    useThreadCrud();
  const {
    localPost: localPostThreadReaction,
    localRemove: localRemoveThreadReaction,
  } = useThreadReactionCrud();
  const {
    localPost: localPostReplyReaction,
    localRemove: localRemoveReplyReaction,
  } = useReplyReactionCrud();
  const { localPut: localPutResolveThread } = useResolveThreadCrud();

  const events: AblyEventHandler[] = useMemo(() => {
    return [
      {
        eventName: ABLY_THREADS_ADD_FILTER,
        onMessageReceived: (message) => {
          if (message.data.threadId) {
            localPostThread(message.data);
          }
        },
      },
      {
        eventName: ABLY_THREADS_REMOVE_FILTER,
        onMessageReceived: (message) => {
          if (message.data.threadId) {
            localRemoveThread(message.data.threadId);
          }
        },
      },
      {
        eventName: ABLY_ADD_REPLIES_FILTER,
        onMessageReceived: (message) => {
          if (message.data.replyId) {
            localPostReply(message.data);
          }
        },
      },
      {
        eventName: ABLY_REMOVE_REPLIES_FILTER,
        onMessageReceived: (message) => {
          if (message.data.replyId && message.data.threadId) {
            localRemoveReply(message.data.threadId, message.data.replyId);
          }
        },
      },
      {
        eventName: ABLY_THREAD_REACTIONS_ADD_FILTER,
        onMessageReceived: (message) => {
          if (message.data.threadId && message.data.reactionId) {
            localPostThreadReaction(message.data);
          }
        },
      },
      {
        eventName: ABLY_THREAD_REACTIONS_REMOVE_FILTER,
        onMessageReceived: (message) => {
          if (message.data.threadId) {
            localRemoveThreadReaction(message.data.threadId);
          }
        },
      },
      {
        eventName: ABLY_REPLY_REACTIONS_ADD_FILTER,
        onMessageReceived: (message) => {
          if (
            message.data.reactionId &&
            message.data.replyId &&
            message.data.threadId
          ) {
            localPostReplyReaction(message.data);
          }
        },
      },
      {
        eventName: ABLY_REPLY_REACTIONS_REMOVE_FILTER,
        onMessageReceived: (message) => {
          if (message.data.threadId && message.data.replyId) {
            localRemoveReplyReaction(
              message.data.threadId,
              message.data.replyId,
            );
          }
        },
      },
      {
        eventName: ABLY_RESOLVED_TOGGLE_FILTER,
        onMessageReceived: (message) => {
          if (
            message.data.branchId &&
            message.data.threadId &&
            message.data.resolved != null
          ) {
            localPutResolveThread(
              message.data.branchId,
              message.data.threadId,
              message.data.resolved,
            );
          }
        },
      },
    ];
  }, [
    localPostReply,
    localPostReplyReaction,
    localPostThread,
    localPostThreadReaction,
    localPutResolveThread,
    localRemoveReply,
    localRemoveReplyReaction,
    localRemoveThread,
    localRemoveThreadReaction,
  ]);

  const channelName = useMemo(() => `${nodeId}:all`, [nodeId]);

  useAblyGeneric(channelName, events);
}
