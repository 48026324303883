import { Suspense, useMemo } from "react";
import Spinner from "@icons/spinner/Spinner";
import PolygonTypeSelector from "./PolygonTypeSelector";
import PointTypeSelector from "./PointTypeSelector";
import LineStringTypeSelector from "./LineStringTypeSelector/LineStringTypeSelector";
import {
  ProjectFeature,
  _LineStringFeatureType,
  _PointFeatureType,
  _PolygonFeatureType,
} from "../../../types/feature";
import { FeatureType } from "../../../types/feature";
import { isLineStringFeature } from "../../../utils/predicates";
import PositionHint from "../../ActiveTips/PositionHints/PositionHint";
import { changeFeatureHelpHintType } from "../../ActiveTips/PositionHints/ChangeFeatureTypeHelp";
import { Divider } from "components/General/Icons";
import {
  NamedTooltipContainer,
  NamedTooltipWrapper,
} from "../CanvasSelectOption.style";
import { parseOr } from "../../../utils/zod";
import MultiPolygonTypeSelector from "./MultiPolygonTypeSelector";

export const TypeSelector = ({
  selections,
  setCurrentSelectionArray,
  disabled,
  selectedType,
}: {
  selections: ProjectFeature[];
  disabled: boolean;
  setCurrentSelectionArray(ids: string[]): void;
  selectedType?: FeatureType;
}) => {
  const selectedGeometryType =
    selections.length === 0 ? undefined : selections[0].geometry.type;

  const geometryTypeSelector = useMemo(() => {
    switch (selectedGeometryType) {
      case "MultiPolygon":
        return (
          <MultiPolygonTypeSelector
            selections={selections}
            disabled={disabled}
            selectedType={parseOr(_PolygonFeatureType, selectedType, "other")}
          />
        );
      case "Polygon":
        return (
          <PolygonTypeSelector
            selections={selections}
            setCurrentSelectionArray={setCurrentSelectionArray}
            disabled={disabled}
            selectedType={parseOr(_PolygonFeatureType, selectedType, "other")}
          />
        );
      case "Point":
        return (
          <PointTypeSelector
            selections={selections}
            disabled={disabled}
            selectedType={parseOr(_PointFeatureType, selectedType, "other")}
          />
        );
      case "LineString":
        return (
          <LineStringTypeSelector
            selections={selections.filter(isLineStringFeature)}
            disabled={disabled}
            selectedType={parseOr(
              _LineStringFeatureType,
              selectedType,
              "other",
            )}
          />
        );
      default:
        return null;
    }
  }, [
    disabled,
    selectedGeometryType,
    selections,
    selectedType,
    setCurrentSelectionArray,
  ]);

  if (!geometryTypeSelector) return null;

  return (
    <>
      <Suspense fallback={<Spinner />}>
        <PositionHint
          allowedHints={[changeFeatureHelpHintType]}
          position={"top"}
        >
          <NamedTooltipContainer>
            <NamedTooltipWrapper>Type</NamedTooltipWrapper>
            {geometryTypeSelector}
          </NamedTooltipContainer>
        </PositionHint>
      </Suspense>
      <Divider />
    </>
  );
};
