import { atomFamily, selector, selectorFamily } from "recoil";
import { z } from "zod";
import {
  projectFeaturesInBranchSelectorFamily,
  projectFeaturesSelector,
} from "../components/ProjectElements/state";
import {
  _FeatureType,
  BathymetryFeature,
  ExistingTurbineFeature,
  GeotiffFeature,
  LineStringFeature,
  OtherFeature,
  ProjectFeature,
} from "../types/feature";
import {
  isAnchor,
  isBathymetry,
  isCable,
  isCableChain,
  isCableCorridor,
  isCablePartition,
  isDefined,
  isDivision,
  isExistingTurbine,
  isExportCable,
  isGeotiff,
  isGridConnection,
  isLineStringFeature,
  isMooringLine,
  isMultiPolygonFeature,
  isPark,
  isPolygonFeature,
  isPort,
  isSubstation,
  isTurbine,
  isViewPoint,
} from "../utils/predicates";
import { partition } from "../utils/utils";
import {
  BathymetryUserUploadedType,
  GeoTiffUserUploadedImageType,
} from "./../services/types";
import { sendInfo } from "../utils/sentry";
import { loggedInUserSelector } from "./user";
import { EMPTY_LIST, replaceEmpty } from "./recoil";
import { MultiPoint, MultiPolygon, Point, Polygon } from "geojson";
import { branchIdSelector, projectIdSelector } from "./pathParams";
import { DefaultMap } from "lib/DefaultMap";

export function isDependentOfFeature(
  parentId: string,
  child: ProjectFeature,
  checkIfIsParent: boolean,
): boolean {
  if (isCable(child)) {
    return (
      child.properties.fromId === parentId ||
      child.properties.toId === parentId ||
      (checkIfIsParent &&
        (child.properties.parentIds?.includes(parentId) ?? false))
    );
  } else if (isMooringLine(child)) {
    return (
      child.properties.anchor === parentId ||
      child.properties.target === parentId ||
      (checkIfIsParent &&
        (child.properties.parentIds?.includes(parentId) ?? false))
    );
  } else if (isExportCable(child)) {
    return (
      child.properties.fromSubstationId === parentId ||
      child.properties.toSubstationId === parentId ||
      (checkIfIsParent &&
        (child.properties.parentIds?.includes(parentId) ?? false))
    );
  }

  return (
    checkIfIsParent &&
    (child.properties?.parentIds?.includes(parentId) ?? false)
  );
}

const findParentLessAnchors = (
  children: string[],
  projectData: ProjectFeature[],
) => {
  const potentialParentLessAnchorIds = projectData
    .filter((pd) => children.includes(pd.id))
    .filter(isMooringLine)
    .map((m) => m.properties.anchor);
  const allOtherMorringLines = projectData
    .filter((pd) => !children.includes(pd.id))
    .filter(isMooringLine);
  return potentialParentLessAnchorIds.filter((anchorId) =>
    allOtherMorringLines.every((line) => line.properties.anchor !== anchorId),
  );
};

/**
 * @return An object where the key is a feature id and the value is an array of feature ids that are dependent on the key feature.
 *  Dependent meaning for instance a turbine has cables connected to it, so the cables are dependent on the turbine.
 *  Example:
 *  {
 *    [turbineId]: [cableId1, cableId2],
 *  }
 */
export const createDependencyMap = (
  projectData: ProjectFeature[],
): Map<string, string[]> => {
  const map = new DefaultMap<string, string[]>(() => []);
  for (const curr of projectData) {
    if (isCable(curr)) {
      const fromId = curr.properties.fromId;
      const toId = curr.properties.toId;
      if (fromId) {
        //From-turbine has the cable as dependency
        map.get(fromId).push(curr.id);
      }
      if (toId) {
        // To-turbine has the cable as dependency
        map.get(toId).push(curr.id);
      }
    } else if (isMooringLine(curr)) {
      const anchor = curr.properties.anchor;
      const target = curr.properties.target;
      if (anchor) {
        // Anchor has a the mooring line as dependency
        map.get(anchor).push(curr.id);
        // The mooring line has the anchor as dependency
        // (This is necessary because we need to find anchors that is connected to a mooring line that is being deleted)
        map.get(curr.id).push(anchor);
      }
      if (target) {
        // Turbine has the mooring line as dependency
        map.get(target).push(curr.id);
      }
    } else if (isExportCable(curr)) {
      const fromSubstationId = curr.properties.fromSubstationId;
      const toSubstationId = curr.properties.toSubstationId;
      if (fromSubstationId) {
        // From-substation has the export cable as dependency
        map.get(fromSubstationId).push(curr.id);
      }
      if (toSubstationId) {
        // To-substation has the export cable as dependency
        map.get(toSubstationId).push(curr.id);
      }
    }
    if (
      curr.properties?.parentIds &&
      Array.isArray(curr.properties.parentIds)
    ) {
      curr.properties.parentIds.forEach((parentId) => {
        // The parent (park) has the child as dependency
        map.get(parentId).push(curr.id);
      });
    }
  }
  return map.inner;
};

export const findFeatureChildrenIdsUsingDependencyMap = (
  dependencyMap: ReturnType<typeof createDependencyMap>,
  featureIds: string[],
): string[] => {
  // Do this in a loop: find features that are dependent on the feature with the given featureId,
  // then find dependencies of the found dependencies, and so on.
  let children = featureIds;
  let i = 0;
  while (i < children.length) {
    const id = children[i++];
    if (dependencyMap.has(id)) {
      children = [...new Set(children.concat(dependencyMap.get(id)!))];
    }
  }

  return children;
};

export const findFeatureChildrenIds = (
  projectData: ProjectFeature[],
  featureId: string,
): string[] => {
  // Do this in a loop: for each iteration we get all features with a given parentId,
  // and remove these features from the list of all features.  Next iteration we only
  // look through the features that weren't removed in any previous iteration.  This
  // way we ensure that we don't loop.  The only edge case is if we don't find any
  // features with a given parentId, but this is not a problem because we will
  // eventually run out of feature ids to look for.
  let features = projectData;
  let children = [featureId];
  let i = 0;
  while (i < children.length) {
    const id = children[i];
    i++;
    const [newChildren, rest] = partition(features, (f) =>
      isDependentOfFeature(id, f, true),
    );
    features = rest;
    children = children.concat(newChildren.map((f) => f.id)).filter(isDefined);
  }

  const parentLessAnchorIds = findParentLessAnchors(children, projectData);

  // Don't include the provided featureId
  return Array.from(new Set([...children.slice(1), ...parentLessAnchorIds]));
};

export const findParkChildren = (
  projectData: ProjectFeature[],
  parkId: string,
): ProjectFeature[] => {
  return projectData.filter(
    (f) =>
      Array.isArray(f.properties.parentIds) &&
      f.properties.parentIds.includes(parkId),
  );
};

export const findFeatureConnectionIds = (
  projectData: ProjectFeature[],
  feature: ProjectFeature,
): string[] => {
  return projectData
    .filter((possibleConnectionFeature) =>
      isDependentOfFeature(possibleConnectionFeature.id, feature, false),
    )
    .map((connectionFeature) => connectionFeature.id);
};

/**
 * Find all features that are children of the feature with id `featureId`. This works recursively,
 * so children of children is also included.
 *
 * A "child" means that the child feature is somehow dependent on the parent feature. For instance,
 * a `MooringLineFeature` depends on the features it is connected to (the anchor and the turbine),
 * and a `CableFeature` depends on the endpoints it connects.
 */
export const findFeatureChildren = (
  projectData: ProjectFeature[],
  featureId: string,
): ProjectFeature[] => {
  const featureIds = findFeatureChildrenIds(projectData, featureId);
  return projectData.filter((p) => featureIds.includes(p.id));
};

/**
 * Find all features that are connections of the feature with id `featureId`.
 * A "connection" means that the passed feature points to the connecting feature by an id in properties, like fromId, toId etc
 */
export const findFeatureConnections = (
  projectData: ProjectFeature[],
  feature: ProjectFeature,
): ProjectFeature[] => {
  const featureIds = findFeatureConnectionIds(projectData, feature);
  return projectData.filter((p) => featureIds.includes(p.id));
};

/**
 * Find children of feature, not finding children of children as {@link findFeatureChildren} does
 */
export const findFeatureChildrenSimple = (
  projectData: ProjectFeature[],
  featureId: string,
): ProjectFeature[] => {
  return projectData.filter((p) => isDependentOfFeature(featureId, p, true));
};

const HIDDEN_FEATURES_LS_KEY = "vind:hidden-feature-ids";
const getHiddenFeaturesLocalStorageKey = (
  nodeId: string,
  branchId: string,
  userId: string,
) => HIDDEN_FEATURES_LS_KEY.concat("-", nodeId, "-", branchId, "-", userId);
export const canvasLayerFeatureHiddenAtomFamily = atomFamily<
  string[],
  { branchId: string | undefined }
>({
  key: "canvasLayerFeatureHiddenAtomFamily",
  default: selectorFamily({
    key: "canvasLayerFeatureHiddenAtom.default",
    get:
      ({ branchId }) =>
      ({ get }) => {
        const projectId = get(projectIdSelector);
        const userId = get(loggedInUserSelector)?.user_id;

        if (
          typeof window === "undefined" ||
          !("localStorage" in window) ||
          !projectId ||
          !branchId ||
          !userId
        ) {
          return EMPTY_LIST;
        }

        const key = getHiddenFeaturesLocalStorageKey(
          projectId,
          branchId,
          userId,
        );

        try {
          const storageItem = localStorage.getItem(key);
          if (storageItem) {
            const result = JSON.parse(storageItem);
            return replaceEmpty(z.array(z.string()).parse(result));
          }
        } catch (err) {
          sendInfo("Could not read local storage", {
            key: key,
            err,
          });
        }

        return EMPTY_LIST;
      },
  }),
  effects: [
    ({ onSet, getPromise }) => {
      onSet(async (newValue) => {
        const projectId = await getPromise(projectIdSelector);
        const branchId = await getPromise(branchIdSelector);
        const userId = (await getPromise(loggedInUserSelector))?.user_id;

        if (
          typeof window === "undefined" ||
          !("localStorage" in window) ||
          !projectId ||
          !branchId ||
          !userId
        ) {
          return;
        }

        const key = getHiddenFeaturesLocalStorageKey(
          projectId,
          branchId,
          userId,
        );
        try {
          if (newValue.length === 0) {
            localStorage.removeItem(key);
            return;
          }

          localStorage.setItem(key, JSON.stringify(newValue));
        } catch (err) {
          sendInfo("Could not set local storage", {
            key: key,
            newValue,
          });
        }
      });
    },
  ],
});

export const canvasLayerFeaturesSelector = selector({
  key: "canvasLayerFeaturesSelector",
  get: ({ get }) =>
    get(projectFeaturesSelector).filter(
      (c) => !_FeatureType.safeParse(c.properties.type).success,
    ),
  dangerouslyAllowMutability: true,
});

export const bathymetryLayerFeaturesSelector = selector({
  key: "bathymetryLayerFeaturesSelector",
  get: ({ get }) =>
    get(projectFeaturesSelector).filter(
      (c) => c.properties.type === BathymetryUserUploadedType,
    ),
  dangerouslyAllowMutability: true,
});

export const georefImagesLayerFeaturesSelector = selector({
  key: "georefImagesLayerFeaturesSelector",
  get: ({ get }) =>
    get(projectFeaturesSelector).filter(
      (c) => c.properties.type === GeoTiffUserUploadedImageType,
    ),
  dangerouslyAllowMutability: true,
});

export const projectFeatureMap = selector<Map<string, ProjectFeature>>({
  key: "projectFeatureMap",
  get: ({ get }) => {
    const features = get(projectFeaturesSelector);
    return new Map(features.map((f) => [f.id, f]));
  },
});

export const projectFeatureMapInBranch = selectorFamily<
  Map<string, ProjectFeature>,
  { branchId: string }
>({
  key: "projectFeatureMapInBranch",
  get:
    ({ branchId }) =>
    ({ get }) => {
      const features = get(projectFeaturesInBranchSelectorFamily({ branchId }));
      return new Map(features.map((f) => [f.id, f]));
    },
});

export const projectFeatureById = selectorFamily<
  ProjectFeature | undefined,
  string
>({
  key: "projectFeatureById",
  get:
    (id) =>
    ({ get }) => {
      const map = get(projectFeatureMap);
      return map.get(id);
    },
});

export const projectFeatureByIdInBranch = selectorFamily<
  ProjectFeature | undefined,
  { id: string; branchId: string }
>({
  key: "projectFeatureByIdInBranch",
  get:
    ({ id, branchId }) =>
    ({ get }) => {
      const map = get(projectFeatureMapInBranch({ branchId }));
      return map.get(id);
    },
});

export const canvasLayerPolygonFeaturesSelector = selector<
  OtherFeature<Polygon | MultiPolygon>[]
>({
  key: "canvasLayerPolygonFeaturesSelector",
  get: ({ get }) => {
    const branchId = get(branchIdSelector);
    const canvasLayerFeatureHidden = get(
      canvasLayerFeatureHiddenAtomFamily({ branchId }),
    );
    return get(projectFeaturesSelector)
      .filter((f) => isPolygonFeature(f) || isMultiPolygonFeature(f))
      .filter(
        // TODO: fix typing here. Should have another function in predicates.ts
        (c): c is OtherFeature<Polygon | MultiPolygon> =>
          !canvasLayerFeatureHidden.includes(c.id) &&
          !isPark(c) &&
          !isDivision(c) &&
          !isCableCorridor(c) &&
          !isCablePartition(c) &&
          !isCableChain(c),
      );
  },
});

export const canvasLayerBathymetryFeaturesSelector = selector<
  BathymetryFeature[]
>({
  key: "canvasLayerBathymetryFeaturesSelector",
  get: ({ get }) => {
    const branchId = get(branchIdSelector);
    const canvasLayerFeatureHidden = get(
      canvasLayerFeatureHiddenAtomFamily({ branchId }),
    );
    return get(projectFeaturesSelector)
      .filter(isBathymetry)
      .filter(
        (c) =>
          c?.geometry?.type === "Polygon" &&
          !canvasLayerFeatureHidden.includes(c.id),
      );
  },
});

export const canvasLayerBathymetryFilenamesSelector = selector<string[]>({
  key: "canvasLayerBathymetryFilenamesSelector",
  get: ({ get }) =>
    get(projectFeaturesSelector)
      .filter(isBathymetry)
      .map((f) => f.properties.filename),
});

export const canvasLayerImageFeaturesSelector = selector<GeotiffFeature[]>({
  key: "canvasLayerImageFeaturesSelector",
  get: ({ get }) => {
    const branchId = get(branchIdSelector);
    const canvasLayerFeatureHidden = get(
      canvasLayerFeatureHiddenAtomFamily({ branchId }),
    );
    return get(projectFeaturesSelector)
      .filter(isGeotiff)
      .filter((c) => !canvasLayerFeatureHidden.includes(c.id));
  },
});

export const canvasLayerLineFeaturesSelector = selector<LineStringFeature[]>({
  key: "canvasLayerLineFeaturesSelector",
  get: ({ get }) => {
    const branchId = get(branchIdSelector);
    const canvasLayerFeatureHidden = get(
      canvasLayerFeatureHiddenAtomFamily({ branchId }),
    );
    return get(projectFeaturesSelector)
      .filter(isLineStringFeature)
      .filter(
        (c) =>
          !isCable(c) &&
          !isExportCable(c) &&
          !isMooringLine(c) &&
          !canvasLayerFeatureHidden.includes(c.id),
      );
  },
});

export const canvasLayerPointFeaturesSelector = selector<
  OtherFeature<Point | MultiPoint>[]
>({
  key: "canvasLayerPointFeaturesSelector",
  get: ({ get }) => {
    const branchId = get(branchIdSelector);
    const canvasLayerFeatureHidden = get(
      canvasLayerFeatureHiddenAtomFamily({ branchId }),
    );
    return get(projectFeaturesSelector).filter(
      // TODO: safety here. Should have another function in predicates.ts
      (c): c is OtherFeature<Point | MultiPoint> =>
        c?.geometry?.type === "Point" &&
        !canvasLayerFeatureHidden.includes(c.id) &&
        !isTurbine(c) &&
        !isSubstation(c) &&
        !isAnchor(c) &&
        !isExistingTurbine(c),
    );
  },
});

export const viewPointsFeaturesSelector = selector({
  key: "viewPointsFeaturesSelector",
  get: async ({ get }) => {
    return get(projectFeaturesSelector).filter(isViewPoint);
  },
});

export const existingTurbinesFeaturesSelector = selector<
  ExistingTurbineFeature[]
>({
  key: "existingTurbinesFeaturesSelector",
  get: async ({ get }) => {
    return get(projectFeaturesSelector).filter(isExistingTurbine);
  },
});

export const portFeaturesSelector = selector({
  key: "portFeaturesSelector",
  get: async ({ get }) => {
    return get(projectFeaturesSelector).filter(isPort);
  },
});

export const gridConnectionFeaturesSelector = selector({
  key: "gridConnectionFeaturesSelector",
  get: async ({ get }) => {
    return get(projectFeaturesSelector).filter(isGridConnection);
  },
});
