const CORS_AND_CACHE_PROXY_URL_PREFIX = "/api/gis-source-proxy";
export const CORS_AND_CACHE_PROXY_WITH_TOKEN_URL_PREFIX =
  "/api/gis-source-proxy-with-token";

export const addCorsAndCacheProxyURL = (url: string, privateSource = false) => {
  const urlWithEncodedSearchParams = keepParams(url);
  return `${
    privateSource
      ? CORS_AND_CACHE_PROXY_WITH_TOKEN_URL_PREFIX
      : CORS_AND_CACHE_PROXY_URL_PREFIX
  }/${urlWithEncodedSearchParams}`;
};

// Put params from URL in a "keepParams" query param
const keepParams = (url: string): string => {
  const urlObj = new URL(url);
  const searchParamString = urlObj.searchParams.toString();

  if (searchParamString) {
    for (const key of urlObj.searchParams.keys()) {
      urlObj.searchParams.delete(key);
    }

    urlObj.searchParams.append("keepParams", searchParamString);
  }

  return urlObj.toString();
};
