import Dropdown from "components/Dropdown/Dropdown";
import { useRecoilValue } from "recoil";
import { useTypedPath } from "state/pathParams";
import {
  loggedInUserSelector,
  ORGANISATION_ACCESS_ROLE_TO_NUMBER,
  userOrganisationAccessSelectorFamily,
} from "state/user";
import { OrganisationUser } from "types/customer";
import { _OrganisationAccessRole } from "types/user";
import { TableText } from "./style";
import { useOrgMemberCrud } from "./useOrgMemberCrud";

export default function OrgRoleDropdown({ user }: { user: OrganisationUser }) {
  const { organisationId } = useTypedPath("organisationId");
  const organisationAccess = useRecoilValue(
    userOrganisationAccessSelectorFamily({ organisationId }),
  );

  const currentUser = useRecoilValue(loggedInUserSelector);

  const userIsCurrentUser = currentUser?.user_id === user.user_id;

  const accessLevel =
    ORGANISATION_ACCESS_ROLE_TO_NUMBER[user.org_role ?? "null"];

  const { update } = useOrgMemberCrud(organisationId);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {organisationAccess >= ORGANISATION_ACCESS_ROLE_TO_NUMBER["admin"] &&
      !userIsCurrentUser &&
      accessLevel !== ORGANISATION_ACCESS_ROLE_TO_NUMBER["owner"] ? (
        <Dropdown
          kind="hidden"
          style={{ width: "14rem" }}
          value={user.org_role ?? undefined}
          onChange={async (e) => {
            const newRole = _OrganisationAccessRole.parse(e.target.value);
            await update(user.user_id, newRole);
          }}
        >
          {organisationAccess >= 1 && <option value={"guest"}>Guest</option>}
          {organisationAccess >= 1 && <option value={"member"}>Member</option>}
          {organisationAccess >= 2 && <option value={"admin"}>Admin</option>}
        </Dropdown>
      ) : (
        <TableText style={{ textTransform: "capitalize" }}>
          {user.org_role}
        </TableText>
      )}
    </div>
  );
}
