import {
  ResultValue,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import minMax from "../../../../../utils/minMax";
import { ColoredGrid } from "../../../../General/Form";
import { valueRounding } from "../utils";
import { MonopileDesign } from "./state";

export const MonopileSingleStats = ({
  name,
  designs,
}: {
  name: string;
  designs: MonopileDesign[];
}) => {
  const numberOfTurbines = designs.length;

  const pileMassStats = minMax(designs, (d) => d.pileMass);
  const primarySteelMin =
    pileMassStats && valueRounding(pileMassStats.min / 1000, 10);
  const primarySteelMax =
    pileMassStats && valueRounding(pileMassStats.max / 1000, 10);

  const pileDiameter = valueRounding(designs[0].pileDiameter, 0.1);
  const pileThickess = valueRounding(designs[0].avgPileThickness, 0.001);
  const embedLength = valueRounding(designs[0].embedLength, 1);

  const natFreqStats = minMax(designs, (d) => d.natFreq);
  const natFreqMin = natFreqStats && valueRounding(natFreqStats.min, 0.01);
  const natFreqMax = natFreqStats && valueRounding(natFreqStats.max, 0.01);

  const totalLengthStats = minMax(designs, (d) => d.totalPileLength);
  const totalLengthMin =
    totalLengthStats && valueRounding(totalLengthStats.min, 1);
  const totalLengthMax =
    totalLengthStats && valueRounding(totalLengthStats.max, 1);

  return (
    <>
      <SubtitleWithLine text={name} />
      <ColoredGrid>
        <ResultValue>Natural frequency</ResultValue>
        {numberOfTurbines !== 1 && (
          <ResultValue>
            <strong>
              {natFreqMin}
              {" - "}
              {natFreqMax}
            </strong>{" "}
            Hz
          </ResultValue>
        )}
        {numberOfTurbines === 1 && (
          <ResultValue>
            {" "}
            <strong>{natFreqMax}</strong> m
          </ResultValue>
        )}
        <ResultValue>Primary steel weight(s)</ResultValue>
        {numberOfTurbines !== 1 && pileMassStats && (
          <ResultValue>
            <strong>
              {primarySteelMin?.toLocaleString()} -{" "}
              {primarySteelMax?.toLocaleString()}
            </strong>{" "}
            t
          </ResultValue>
        )}
        {numberOfTurbines === 1 && pileMassStats && <p>{primarySteelMax} t</p>}
        <ResultValue>Pile diameter</ResultValue>
        <ResultValue>
          <strong>{pileDiameter.toLocaleString()}</strong> m
        </ResultValue>
        <ResultValue>Average pile thickness</ResultValue>
        <ResultValue>
          <strong>{pileThickess.toLocaleString()}</strong> m
        </ResultValue>
        <ResultValue>Embedded length</ResultValue>
        <ResultValue>
          <strong>{embedLength.toLocaleString()}</strong> m
        </ResultValue>
        <ResultValue>Total pile length(s)</ResultValue>
        {numberOfTurbines !== 1 && (
          <ResultValue>
            <strong>
              {totalLengthMin}
              {" - "}
              {totalLengthMax}
            </strong>{" "}
            m
          </ResultValue>
        )}
        {numberOfTurbines === 1 && (
          <ResultValue>
            {" "}
            <strong>{totalLengthMax} </strong> m
          </ResultValue>
        )}
      </ColoredGrid>
    </>
  );
};
