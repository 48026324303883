import FullScreenModal from "components/FullScreenModal/FullScreenModal";
import { Column, ModalFrame, Row } from "components/General/Layout";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  connectExternalModalOpen,
  externalSourcesAtom,
  useSourceCrud,
} from "./state";
import { typography } from "styles/typography";
import Dropdown from "components/Dropdown/Dropdown";
import { Input, SearchInput } from "components/General/Input";
import Button from "components/General/Button";
import {
  borderRadiusMedium,
  spacing2,
  spacing3,
  spacing4,
  spacing6,
  spacing7,
  spacing9,
} from "styles/space";
import { colors } from "styles/colors";
import React, { useMemo, useRef, useState } from "react";
import Checkbox from "components/General/Checkbox";
import styled from "styled-components";
import { Icon, IconBtn } from "components/General/Icons";
import BinIcon from "@icons/24/Bin.svg";
import GlobeIcon from "@icons/24/Globe.svg";
import useTextInput from "hooks/useTextInput";
import Fuse from "fuse.js";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { ScrollBody } from "hooks/useShowScrollShadow";
import { organisationIdSelector } from "state/pathParams";
import { useToast } from "hooks/useToast";
import { _SourceType, _PostSource, Source } from "./state";

const NewDataSource = () => {
  const organisationId = useRecoilValue(organisationIdSelector)!;
  const selectRef = useRef<HTMLSelectElement>(null);
  const urlRef = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const { error } = useToast();
  const { list, post } = useSourceCrud();
  return (
    <Column>
      <h3>Explore new data source</h3>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto min-content",
          rowGap: spacing6,
          columnGap: spacing7,
        }}
      >
        <Dropdown ref={selectRef} style={{ gridArea: "1/1/2/2" }}>
          <option value="wms">WMS</option>
          <option value="wfs">WFS</option>
          <option value="arcgis">ArcGIS</option>
        </Dropdown>

        <Input
          ref={urlRef}
          placeholder="URL to WMS source*"
          style={{ gridArea: "2/1/3/2" }}
        />
        <Input
          ref={nameRef}
          placeholder="Source name*"
          style={{ gridArea: "3/1/4/2" }}
        />
        <Button
          text="Connect"
          style={{ gridArea: "3/2/4/3" }}
          onClick={() => {
            const type = _SourceType.parse(selectRef.current?.value);
            const name = nameRef.current?.value ?? "";
            const url = urlRef.current?.value ?? "";
            return post({ type, name, url }, { organisationId }).catch(
              async (e) => {
                if (e instanceof Response) {
                  error("Failed to connect to server");
                } else throw e;
              },
            );
          }}
        />
        <Button
          text="DEBUG"
          style={{ gridArea: "2/2/3/3" }}
          onClick={() => {
            list({ organisationId });
          }}
        />
      </div>
    </Column>
  );
};

const SourcesGrid = styled.div`
  display: grid;
  grid-template-columns: 1.6rem auto min-content;
  align-items: center;
  row-gap: ${spacing2};
  column-gap: ${spacing4};
  padding: ${spacing4} ${spacing6};

  > span:nth-child(2) {
    ${typography.sub1}
  }
  > span:nth-child(3) {
    grid-area: 2/2/3/3;
    ${typography.caption};
  }

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
  }

  border-radius: ${borderRadiusMedium};
  &[aria-selected="true"] {
    background: ${colors.surfaceSelectedLight};
  }
  :hover {
    cursor: pointer;
  }
`;
const ExistingSources = ({
  source,
  setSource,
}: {
  source: Source | undefined;
  setSource(s: Source | undefined): void;
}) => {
  const organisationId = useRecoilValue(organisationIdSelector)!;
  const sources = useRecoilValue(
    externalSourcesAtom({ organisationId: organisationId }),
  );
  const { remove } = useSourceCrud();
  return (
    <Column>
      <h3>Existing sources</h3>
      <ScrollBody gap="0">
        {sources.map((src) => (
          <SourcesGrid
            key={src.url}
            id={src.url}
            aria-selected={src === source}
            onClick={() => setSource(src === source ? undefined : src)}
          >
            <Icon size="1.6rem" style={{ gridArea: "1 / 1 / 2 / 2" }}>
              <GlobeIcon />
            </Icon>

            <span title={src.name}>{src.name}</span>
            <span title={src.url}>{src.url}</span>

            <IconBtn
              size="1.2rem"
              style={{ gridArea: "1 / 3 / 3 / 4" }}
              onClick={(e) => {
                e.stopPropagation(); // don't select the item
                remove({ organisationId, sourceId: src.id });
              }}
            >
              <BinIcon />
            </IconBtn>
          </SourcesGrid>
        ))}
        {sources.length === 0 && (
          <SimpleAlert
            type="info"
            text="Add a source above to populate the list."
          />
        )}
      </ScrollBody>
    </Column>
  );
};

const AvailableLayersGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;

  > * {
    padding: ${spacing3} ${spacing7};
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
    ${typography.caption};
  }

  // First row
  > :nth-child(-n + 2) {
    ${typography.sub2};
    position: sticky;
    top: 0;
    background: white;
    border-bottom: 1px solid ${colors.blue500};
  }
`;

const AvailableLayers = ({ layers }: { layers: any[] | undefined }) => {
  const [selected, setSelected] = useState<Record<string, any>>({});

  const [searchValue, onSearchValueChange, setSearchValue] = useTextInput("");
  const fuse = useMemo(
    () =>
      new Fuse(layers ?? [], {
        keys: ["title", "description"],
        includeScore: true,
        threshold: 0.3,
      }),
    [layers],
  );

  const searchResults = useMemo(() => {
    if (searchValue.length === 0) return undefined;
    return fuse.search(searchValue).map((result) => result.item);
  }, [fuse, searchValue]);

  if (layers === undefined)
    return (
      <Column>
        <h3>Available layers</h3>
        <SimpleAlert
          type="info"
          text="Select a layer on the left to view the layers it contains."
        />
      </Column>
    );

  return (
    <Column style={{ maxHeight: "inherit" }}>
      <h3>Available layers</h3>
      <SearchInput
        style={{ flex: 1 }}
        wrapperDivStyle={{ display: "flex" }}
        placeholder="Search ..."
        value={searchValue}
        onChange={onSearchValueChange}
        onClear={() => setSearchValue("")}
      />

      <ScrollBody gap="0">
        <AvailableLayersGrid>
          <span>Title</span> <span>Description</span>
          {(searchResults ? searchResults : layers).map((l, i) => {
            const name = l.name;
            if (!name) return null;
            return (
              <React.Fragment key={i}>
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked)
                      setSelected((c) => ({ ...c, [name]: true }));
                    else setSelected(({ [name]: _, ...c }) => c);
                  }}
                  checked={selected[name]}
                  label={l.title ?? l.name}
                />
                <span title={l.abstract ?? undefined}>{l.abstract}</span>
              </React.Fragment>
            );
          })}
        </AvailableLayersGrid>
        {layers.length === 0 && (
          <SimpleAlert
            type="info"
            text="The source contains no layers"
            style={{ margin: "1rem" }}
          />
        )}
        {searchResults?.length === 0 && (
          <SimpleAlert
            type="info"
            text="No results"
            style={{ margin: "1rem" }}
          />
        )}
      </ScrollBody>
    </Column>
  );
};

const ConnectExternalModal = () => {
  const [open, setOpen] = useRecoilState(connectExternalModalOpen);

  const [selectedSource, setSelectedSource] = useState<Source | undefined>(
    undefined,
  );

  if (!open) return null;
  return (
    <FullScreenModal>
      <ModalFrame
        title={"Connect external data servers"}
        onExit={() => {
          setOpen(false);
        }}
        fullscreen
        closeOnWrapperClick
        style={{ width: "90rem", top: "10rem", marginBottom: "auto" }}
      >
        <p
          style={{
            ...typography.caption,
            marginTop: spacing4,
            marginBottom: spacing6,
          }}
        >
          Uploaded data will only be visible for projects with access to this
          source
        </p>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1px 1fr",
            columnGap: spacing9,
            maxHeight: "60rem",
          }}
        >
          <Column style={{ maxHeight: "inherit" }}>
            <NewDataSource />
            <ExistingSources
              source={selectedSource}
              setSource={setSelectedSource}
            />
          </Column>
          <div
            style={{
              background: colors.borderSubtle,
            }}
          />
          <AvailableLayers
            key={selectedSource?.url}
            layers={selectedSource?.layers as any[]}
          />
        </div>
        <Row
          style={{
            borderTop: `1px solid ${colors.borderSubtle}`,
            marginTop: spacing7,
            paddingTop: spacing7,
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <span style={{ ...typography.caption, color: colors.textDisabled }}>
            1 selected
          </span>
          <Button
            buttonType="secondary"
            text="Cancel"
            onClick={() => window.alert("cancel")}
          />
          <Button text="Add" onClick={() => window.alert("add")} />
        </Row>
      </ModalFrame>
    </FullScreenModal>
  );
};

export default ConnectExternalModal;
