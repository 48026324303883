import { atom, atomFamily, selector } from "recoil";
import { LayerMouseHandlingCallbacks } from "../hooks/mouseHandling";
import { SelectionType } from "../services/types";
import { ProjectFeature } from "../types/feature";
import { isDefined } from "../utils/predicates";
import { selectableFeatureMap } from "../components/ProjectElements/selectableState";

export const currentSelectionArrayAtom = atom<SelectionType[]>({
  key: "currentSelectionArrayAtom",
  default: [],
});

export const currentSelectedProjectFeatures = selector<ProjectFeature[]>({
  key: "currentSelectedProjectFeatures",
  get: ({ get }) => {
    const selection = get(currentSelectionArrayAtom);
    const projectFeatures = get(selectableFeatureMap);

    const selectedFeatures = selection
      .map((s) => projectFeatures.get(s))
      .filter(isDefined);

    return selectedFeatures;
  },
});

export const firstCurrentSelectionSelector = selector({
  key: "firstCurrentSelectionSelector",
  get: ({ get }) => {
    const csa = get(currentSelectedProjectFeatures);
    if (csa.length === 1) return csa[0];
    return;
  },
  dangerouslyAllowMutability: true,
});

export type WMSSelection = {
  type: string;
  url: string;
  legendGraphicUrl: string;
  html?: string;
};

export const currentSelectionWMSAtom = atom<WMSSelection[]>({
  key: "currentSelectionWMSAtom",
  default: [],
});

export const defaultMouseHandlerCallBackClickableFeature =
  atom<LayerMouseHandlingCallbacks>({
    key: "defaultMouseHandlerCallBackClickableFeature",
    default: {},
  });

export type OtherUserSelection = {
  userId: string;
  selection: SelectionType[];
};

export const otherUsersSelectionArrayAtomFamily = atomFamily<
  OtherUserSelection[],
  | {
      projectId: string;
      branchId: string;
      version?: number;
    }
  | undefined
>({
  key: "otherUsersSelectionArrayAtomFamily",
  default: [],
});
