import React, { useEffect, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { colors } from "styles/colors";
import { ToastMessage, toastMessagesAtom } from "state/toast";

const ToastWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: fixed;
  top: 11rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  font-size: 1.6rem;
  font-weight: 500;
  align-items: center;
`;

const ToastElementWrapper = styled.div<{ toastType: ToastMessage["type"] }>`
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  background-color: black;
  color: white;
  text-align: center;
  padding: 0.6rem 1.6rem;
  z-index: 10;
  border-radius: 0.4rem;
  transition: opacity 0.25s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  gap: 1rem;
  white-space: pre;

  ${({ toastType }) => {
    switch (toastType) {
      case "success":
        return `
            background-color: ${colors.success};
          `;
      case "info":
        return `
            background-color: ${colors.brand};
          `;
      case "warning":
        return `
            background-color: ${colors.yellow};
          `;
      case "error":
        return `
            background-color: ${colors.errorText};
          `;
    }
  }}
`;

const ReadMoreLink = styled.a`
  &:link {
    font-size: 1rem;
    color: white;
    text-decoration: none;
  }

  &:visited {
    font-size: 1rem;
    color: white;
    text-decoration: none;
  }
`;

const Countdown = styled.div<{ time: number }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.4rem;
  border-radius: 0 0.4rem 0.4rem 0.4rem;
  animation: countdown ${({ time }) => time}ms linear;
  animation-fill-mode: forwards;
  background-color: ${colors.background};

  @keyframes countdown {
    0% {
      width: 100%;
    }
    100% {
      width: 0;
    }
  }
`;

const ToastElement = ({ toast }: { toast: ToastMessage }) => {
  return (
    <ToastElementWrapper toastType={toast.type ?? "info"}>
      {toast.text}
      {toast.link && (
        <ReadMoreLink href={toast.link} target="_blank">
          Read more
        </ReadMoreLink>
      )}
      {toast.showCountdown && <Countdown time={toast.timeout} />}
    </ToastElementWrapper>
  );
};

const createToastElement = (toast: ToastMessage) => {
  const newElement = document.createElement("div");
  newElement.innerHTML = ReactDOMServer.renderToString(
    <ToastElement key={toast.id} toast={toast} />,
  );
  return newElement;
};

const ToastHandler = ({
  toastRef,
}: {
  toastRef: React.RefObject<HTMLElement>;
}) => {
  const [toastMessages, setToastMessage] = useRecoilState(toastMessagesAtom);

  useEffect(() => {
    if (toastMessages.length === 0) return;
    toastMessages.forEach((tm) => {
      const newElement = createToastElement(tm);
      if (toastRef.current) toastRef.current.appendChild(newElement);
      if (tm.id) newElement.id = tm.id;
      setTimeout(() => {
        newElement.remove();
      }, tm.timeout);
    });
    setToastMessage((cur) => (cur.length > 0 ? [] : cur));
  }, [toastMessages, toastRef, setToastMessage]);

  return null;
};

const Toast = () => {
  const toastRef = useRef<HTMLDivElement>(null);
  return (
    <ToastWrapper ref={toastRef}>
      <ToastHandler toastRef={toastRef} />
    </ToastWrapper>
  );
};

export default Toast;
