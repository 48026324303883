/// <reference types="vite-plugin-svgr/client" />
import React, { useEffect, useState } from "react";
import Dropdown from "../Dropdown/Dropdown";
import {
  DetailedMonopileType,
  FloaterType,
  FoundationMaxDepths,
  FoundationMinDepths,
  FoundationType,
  FoundationTypeIds,
  ScalingLawType,
} from "../../types/foundations";
import { Input, InputDimensioned, TextArea } from "../General/Input";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";
import {
  isSpar,
  isFloater,
  isMonopile,
  isJacket,
  isDetailedMonopile,
} from "../RightSide/InfoModal/FoundationModal/utils";
import { Label } from "../General/Form";
import { Column, Row } from "../General/Layout";
import {
  ErrorBoundaryWarningTriangle,
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "../ErrorBoundaries/ErrorBoundaryLocal";
import { inReadOnlyModeSelector } from "../../state/project";
import { useRecoilValue, useSetRecoilState } from "recoil";

import {
  ContentWrapper,
  SettingsFooter,
  SettingsFooterContainerWrapper,
  SettingsHeader,
  SettingsHeaderStandard,
} from "../SettingsV2/Shared/styles";
import FoundationTypeUsage from "./SettingsUsage/FoundationTypeUsage";
import Button from "../General/Button";
import { ColumnContent } from "./TurbineSettings";
import styled from "styled-components";
import {
  MAX_PILE_DIAMETER,
  scalingEquationTokens,
} from "../../constants/foundations";
import Mexp from "math-expression-evaluator";
import HelpTooltip, {
  ARTICLE_FOUNDATION_STATS,
  HelpLink,
} from "../HelpTooltip/HelpTooltip";
import { unsavedSettingsState } from "../SettingsV2/Shared/state";
import { toastMessagesAtom } from "../../state/toast";

import { validateRnaMass } from "./TurbineSettings/TurbineSettingCustomAdmin";
import { orgFoundationManageAccessSelector } from "state/user";
import { useLocation, useNavigate } from "react-router-dom";
import { selectedOrgTabState } from "components/Organisation/state";
import { useTypedPath } from "state/pathParams";
import { useDuplicateFoundationToProject } from "components/SettingsV2/FeatureSettings/Data/useDuplicateToProject";
import { libraryTabState } from "components/Organisation/Library/state";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import ComponentLastChanged from "./SettingsUsage/ComponentLastChanged";

const FoundationGraph = React.lazy(() => import("./FoundationGraph"));

const WrapColumn = styled(Column)`
  flex: 1;
  flex-wrap: wrap;
  overflow: auto;
  > label {
  }
`;

const between = (min: number, max: number) => (n: number) =>
  min <= n && n <= max;

function validEquation(equation: string) {
  var mexp = new Mexp();
  const tokenValues = { p: 15, w: 30, d: 220 }; //check equation for 15MW and 30 m water depth, assume it's then ok for all other p, w and d
  try {
    mexp.eval(equation, scalingEquationTokens, tokenValues);
    return true;
  } catch (error) {
    return false;
  }
}

const FloatingFoundationSettings = ({
  tempFoundation,
  setTempFoundation,
  disabled,
  setHasChanged,
}: {
  tempFoundation: FloaterType;
  setTempFoundation: React.Dispatch<React.SetStateAction<FoundationType>>;
  setHasChanged: React.Dispatch<React.SetStateAction<boolean>>;
  disabled: boolean;
}) => {
  return (
    <>
      <Label>
        <p>Scaling law</p>
        <Dropdown
          id="scaling-law"
          disabled={true}
          value={tempFoundation.scalingLaw}
          onChange={(e) => {
            setTempFoundation({
              ...tempFoundation,
              scalingLaw: e.target.value as ScalingLawType,
            });
            setHasChanged(true);
          }}
        >
          <option value={"Mass scaling"}>{"Mass scaling"}</option>
        </Dropdown>
      </Label>
      <Label>
        <p>Reference rated power</p>
        <InputDimensioned
          unit="MW"
          disabled={disabled}
          value={Math.round(tempFoundation.ratedPower / 1000)}
          validate={between(1, 30)}
          validationMessage="Must be between 1 and 30 MW"
          onChange={(ratedPowerMW) => {
            setTempFoundation({
              ...tempFoundation,
              ratedPower: ratedPowerMW * 1000,
            });
            setHasChanged(true);
          }}
        />
      </Label>
      <Label>
        <p>Reference diameter</p>
        <InputDimensioned
          unit="m"
          disabled={disabled}
          value={tempFoundation.rotorDiameter}
          validate={between(50, 350)}
          onChange={(d) => {
            setTempFoundation({
              ...tempFoundation,
              rotorDiameter: d,
            });
            setHasChanged(true);
          }}
        />
      </Label>
      <Label>
        <p>Reference hub height</p>
        <InputDimensioned
          unit="m"
          disabled={disabled}
          value={tempFoundation.hubHeight}
          validate={between(50, 300)}
          validationMessage="Must be between 50 and 300 m"
          onChange={(h) => {
            setTempFoundation({
              ...tempFoundation,
              hubHeight: h,
            });
            setHasChanged(true);
          }}
        />
      </Label>
      <Label>
        <p>Reference RNA weight</p>
        <InputDimensioned
          unit="t"
          disabled={disabled}
          value={Math.round(tempFoundation.rnaMass / 1000)}
          validate={between(10, 10_000)}
          validationMessage="Must be between 10 and 10 000 t"
          onChange={(rnaMassTons) => {
            setTempFoundation({
              ...tempFoundation,
              rnaMass: rnaMassTons * 1000,
            });
            setHasChanged(true);
          }}
        />
      </Label>
      <Label>
        <p>{tempFoundation.material} weight</p>
        <InputDimensioned
          unit="t"
          disabled={disabled}
          value={Math.round(tempFoundation.primaryMass / 1000)}
          validate={between(10, 100_000)}
          validationMessage="Must be between 10 and 100 000 t"
          onChange={(primaryMassTons) => {
            setTempFoundation({
              ...tempFoundation,
              primaryMass: primaryMassTons * 1000,
            });
            setHasChanged(true);
          }}
        />
      </Label>
      <Label>
        <p>Solid ballast weight</p>
        <InputDimensioned
          unit="t"
          disabled={disabled}
          value={Math.round(tempFoundation.solidBallastMass / 1000)}
          validate={between(0, 100_000)}
          validationMessage="Must be between 0 and 100 000 t"
          onChange={(solidBallastMassTons) => {
            setTempFoundation({
              ...tempFoundation,
              solidBallastMass: solidBallastMassTons * 1000,
            });
            setHasChanged(true);
          }}
        />
      </Label>
      <Label>
        <p>Liquid ballast weight</p>
        <InputDimensioned
          unit="t"
          disabled={disabled}
          value={Math.round(tempFoundation.liquidBallastMass / 1000)}
          validate={between(0, 100_000)}
          validationMessage="Must be between 0 and 100 000 t"
          onChange={(liquidBallastMassTons) => {
            setTempFoundation({
              ...tempFoundation,
              liquidBallastMass: liquidBallastMassTons * 1000,
            });
            setHasChanged(true);
          }}
        />
      </Label>
      <Label>
        <p>Displacement</p>
        <InputDimensioned
          unit="m³"
          disabled={disabled}
          value={tempFoundation.displacement}
          validate={between(1e3, 1e8)}
          validationMessage="Must be between 1e3 and 1e8 m³"
          onChange={(d) => {
            setTempFoundation({
              ...tempFoundation,
              displacement: d,
            });
            setHasChanged(true);
          }}
        />
      </Label>
      <Label>
        <p>Draft</p>
        <InputDimensioned
          unit="m"
          disabled={disabled}
          value={tempFoundation.draft}
          validate={between(0, 1e2)}
          validationMessage="Must be between 0 and 100 m"
          onChange={(d) => {
            setTempFoundation({
              ...tempFoundation,
              draft: d,
            });
            setHasChanged(true);
          }}
        />
      </Label>
      {isSpar(tempFoundation) && (
        <Label>
          <p>Main diameter</p>
          <InputDimensioned
            unit="m"
            disabled={disabled}
            value={tempFoundation.baseDiameter}
            validate={between(0, 1e2)}
            validationMessage="Must be between 0 and 100 m"
            onChange={(d) => {
              setTempFoundation({
                ...tempFoundation,
                baseDiameter: d,
              });
              setHasChanged(true);
            }}
          />
        </Label>
      )}
      <Label>
        <p>Fairlead radius</p>
        <InputDimensioned
          unit="m"
          disabled={disabled}
          value={tempFoundation.fairRadius}
          validate={between(0, 1e2)}
          validationMessage="Must be between 0 and 100 m"
          onChange={(r) => {
            setTempFoundation({
              ...tempFoundation,
              fairRadius: r,
            });
            setHasChanged(true);
          }}
        />
      </Label>
      <Label>
        <p>Fairlead height</p>
        <InputDimensioned
          unit="m"
          disabled={disabled}
          value={tempFoundation.fairZ}
          validate={between(-1e2, 1e2)}
          validationMessage="Must be between -100 and 100 m"
          onChange={(h) => {
            setTempFoundation({
              ...tempFoundation,
              fairZ: h,
            });
            setHasChanged(true);
          }}
        />
      </Label>
    </>
  );
};

const DetailedMonopileSettings = ({
  tempFoundation,
  setTempFoundation,
  disabled,
  setHasChanged,
}: {
  tempFoundation: DetailedMonopileType;
  setTempFoundation: React.Dispatch<React.SetStateAction<FoundationType>>;
  setHasChanged: React.Dispatch<React.SetStateAction<boolean>>;
  disabled: boolean;
}) => {
  return (
    <>
      <Label>
        <p>Pile diameter</p>
        <InputDimensioned
          disabled={disabled}
          validate={between(1, MAX_PILE_DIAMETER)}
          validationMessage={`Needs to be within 1 - ${MAX_PILE_DIAMETER} m`}
          step="1"
          unit="m"
          type="number"
          value={Math.round(10 * tempFoundation.pileDiameter) / 10}
          onChange={(d) => {
            setTempFoundation({
              ...tempFoundation,
              pileDiameter: d,
            });
            setHasChanged(true);
          }}
        />
      </Label>
      <Label>
        <p>Average pile thickness</p>
        <InputDimensioned
          disabled={disabled}
          validate={between(0.01, 0.5)}
          validationMessage={`Needs to be within 0.01 - 0.5 m`}
          step="0.01"
          unit="m"
          type="number"
          value={Math.round(1000 * tempFoundation.avgPileThickness) / 1000}
          onChange={(d) => {
            setTempFoundation({
              ...tempFoundation,
              avgPileThickness: d,
            });
            setHasChanged(true);
          }}
        />
      </Label>
      <Label>
        <p>Embedded length</p>
        <InputDimensioned
          disabled={disabled}
          validate={between(10, 100)}
          validationMessage={`Needs to be within 10 - 100 m`}
          step="1"
          unit="m"
          type="number"
          value={Math.round(10 * tempFoundation.embedLength) / 10}
          onChange={(d) => {
            setTempFoundation({
              ...tempFoundation,
              embedLength: d,
            });
            setHasChanged(true);
          }}
        />
      </Label>
      <Label>
        <p>Total pile length</p>
        <InputDimensioned
          disabled={disabled}
          validate={between(20, 150)}
          validationMessage={`Needs to be within 20 - 150 m`}
          step="1"
          unit="m"
          type="number"
          value={Math.round(10 * tempFoundation.totalPileLength) / 10}
          onChange={(d) => {
            setTempFoundation({
              ...tempFoundation,
              totalPileLength: d,
            });
            setHasChanged(true);
          }}
        />
      </Label>
      <Label>
        <p>Reference rated power</p>
        <InputDimensioned
          disabled={disabled}
          validate={between(1, 30)}
          validationMessage={`Needs to be within 1 - 30 MW`}
          step="1"
          unit="MW"
          type="number"
          value={tempFoundation.ratedPower / 1000}
          onChange={(ratedPowerMW) => {
            setTempFoundation({
              ...tempFoundation,
              ratedPower: ratedPowerMW * 1000,
            });
            setHasChanged(true);
          }}
        />
      </Label>
      <Label>
        <p>Reference rotor diameter</p>
        <InputDimensioned
          disabled={disabled}
          validate={between(50, 350)}
          validationMessage={`Needs to be within 50 - 350 m`}
          step="1"
          unit="m"
          type="number"
          value={tempFoundation.rotorDiameter}
          onChange={(d) => {
            setTempFoundation({
              ...tempFoundation,
              rotorDiameter: d,
            });
            setHasChanged(true);
          }}
        />
      </Label>
      <Label>
        <p>Reference hub height</p>
        <InputDimensioned
          disabled={disabled}
          validate={between(50, 300)}
          validationMessage={`Needs to be within 50 - 300 m`}
          step="1"
          unit="m"
          type="number"
          value={tempFoundation.hubHeight}
          onChange={(d) => {
            setTempFoundation({
              ...tempFoundation,
              hubHeight: d,
            });
            setHasChanged(true);
          }}
        />
      </Label>
      <Label>
        <p>Reference RNA weight</p>
        <InputDimensioned
          disabled={disabled}
          validate={(rnaMassTons) =>
            validateRnaMass({
              rnaMass: rnaMassTons * 1000,
              diameter: tempFoundation.rotorDiameter,
            })
          }
          validationMessage={`The value differs from our estimation by more than 50%`}
          step="1"
          unit="t"
          type="number"
          value={Math.round(tempFoundation.rnaMass / 1000)}
          onChange={(rnaMassTons) => {
            setTempFoundation({
              ...tempFoundation,
              rnaMass: rnaMassTons * 1000,
            });
            setHasChanged(true);
          }}
        />
      </Label>
      <Label>
        <p>Reference water depth</p>
        <InputDimensioned
          disabled={disabled}
          validate={between(
            FoundationMinDepths.detailed_monopile,
            FoundationMaxDepths.detailed_monopile,
          )}
          validationMessage={`Needs to be within ${FoundationMinDepths.detailed_monopile} - ${FoundationMaxDepths.detailed_monopile} m`}
          step="1"
          unit="m"
          type="number"
          value={tempFoundation.waterDepth}
          onChange={(d) => {
            setTempFoundation({
              ...tempFoundation,
              waterDepth: d,
            });
            setHasChanged(true);
          }}
        />
      </Label>
      <Label>
        <p>Reference 50-year Hs</p>
        <InputDimensioned
          disabled={disabled}
          validate={between(1, 20)}
          validationMessage={`Needs to be within 1 - 20 m`}
          step="0.1"
          unit="m"
          type="number"
          value={Math.round(10 * tempFoundation.hs50Yr) / 10}
          onChange={(d) => {
            setTempFoundation({
              ...tempFoundation,
              hs50Yr: d,
            });
            setHasChanged(true);
          }}
        />
      </Label>
      <Label>
        <p>Reference sub. reaction coeff.</p>
        <InputDimensioned
          disabled={disabled}
          validate={between(1000, 20_000)}
          validationMessage={`Needs to be within 1000 - 20 000 kN/m³`}
          step="100"
          unit="kN/m³"
          type="number"
          value={
            ((1 / 1000) * Math.round(0.01 * tempFoundation.soilCoeffSubReact)) /
            0.01
          }
          onChange={(soilStiffnesskN) => {
            setTempFoundation({
              ...tempFoundation,
              soilCoeffSubReact: soilStiffnesskN * 1000,
            });
            setHasChanged(true);
          }}
        />
      </Label>
    </>
  );
};

const FoundationSettings = ErrorBoundaryWrapper(
  ({
    foundation,
    isDefault,
    onSave,
    duplicate,
    nodeId,
    hasEditAccess,
    isLibraryFoundation,
  }: {
    foundation: FoundationType;
    isDefault: boolean;
    onSave: (foundation: FoundationType) => void;
    duplicate: (foundationId: string) => void;
    nodeId: string;
    hasEditAccess: boolean;
    isLibraryFoundation?: boolean;
  }) => {
    const { organisationId } = useTypedPath("organisationId");
    const setSelectedTab = useSetRecoilState(
      libraryTabState({ organisationId }),
    );
    const navigate = useNavigate();
    const location = useLocation();
    const setOrgtab = useSetRecoilState(selectedOrgTabState);
    const isReadOnly = useRecoilValue(inReadOnlyModeSelector);
    const setUnsavedSettings = useSetRecoilState(unsavedSettingsState);
    const setToastMessages = useSetRecoilState(toastMessagesAtom);
    const hasOrgFoundationAccess = useRecoilValue(
      orgFoundationManageAccessSelector,
    );
    const isDisabled =
      isReadOnly || isDefault || !hasEditAccess || !!isLibraryFoundation;

    const [tempFoundation, setTempFoundation] =
      useState<FoundationType>(foundation);
    const [hasChanged, setHasChanged] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(true);
    const { duplicateToProject, isLoading: isLoadingDuplicate } =
      useDuplicateFoundationToProject();

    useEffect(() => setTempFoundation(foundation), [foundation]);

    useEffect(() => {
      setUnsavedSettings(hasChanged);
    }, [hasChanged, setUnsavedSettings]);

    return (
      <>
        {!isDefault && !(!hasEditAccess || !hasChanged) && (
          <SettingsHeader>
            <Button
              disabled={!hasEditAccess || !hasChanged}
              text="Cancel"
              buttonType="secondary"
              onClick={() => {
                setTempFoundation(foundation);
                setHasChanged(false);
              }}
              style={{ marginLeft: "auto" }}
            />
            <Button
              disabled={!hasEditAccess || !hasChanged}
              text="Save changes"
              onClick={() => {
                setToastMessages((tm) => [
                  ...tm,
                  { text: "Saving...", timeout: 1000 },
                ]);
                onSave(tempFoundation);
                setHasChanged(false);
              }}
            />
          </SettingsHeader>
        )}
        <ContentWrapper
          style={{
            maxHeight: "calc(100% - 7.3rem)",
            overflowY: "auto",
            boxSizing: "border-box",
          }}
        >
          <ColumnContent style={{ overflow: "auto" }}>
            <SettingsHeaderStandard>
              <h4 style={{ margin: 0 }}>Foundation settings</h4>
              <HelpLink article={ARTICLE_FOUNDATION_STATS} />
              {isLibraryFoundation && hasOrgFoundationAccess && (
                <Row style={{ marginLeft: "auto" }}>
                  <Button
                    buttonType="text"
                    text="Edit foundation in Library"
                    onClick={() => {
                      setOrgtab("Library");
                      setSelectedTab("foundation");
                      navigate(
                        `/organisation/${organisationId}${location.search}`,
                      );
                    }}
                  />
                  <Button
                    text="Duplicate as project foundation"
                    onClick={() => duplicateToProject(foundation)}
                    disabled={isLoadingDuplicate}
                  />
                </Row>
              )}
              {isDefault && hasEditAccess && (
                <Row style={{ marginLeft: "auto" }}>
                  <Button
                    text="Duplicate to edit"
                    icon={<DuplicateIcon />}
                    onClick={() => duplicate(foundation.id)}
                  />
                </Row>
              )}
            </SettingsHeaderStandard>
            <WrapColumn>
              <Label>
                <p>Name</p>
                <Input
                  type="string"
                  disabled={isDisabled}
                  value={
                    isDefault
                      ? `${foundation.name} (standard)`
                      : tempFoundation.name
                  }
                  onChange={(e) => {
                    setTempFoundation((curr) => ({
                      ...curr,
                      name: e.target.value,
                    }));
                    setHasChanged(true);
                  }}
                />
              </Label>
              <Label>
                <p>Type</p>
                <Dropdown
                  id="foundation-type"
                  disabled={true}
                  value={tempFoundation.type}
                  onChange={(e) => {
                    setTempFoundation((curr) => ({
                      ...curr,
                      type: e.target.value as FoundationTypeIds,
                    }));
                    setHasChanged(true);
                  }}
                >
                  <option value={FoundationTypeIds.SemiCentralType}>
                    {"Semi, central turbine"}
                  </option>
                  <option value={FoundationTypeIds.SemiPeripheralType}>
                    {"Semi, offset turbine"}
                  </option>
                  <option value={FoundationTypeIds.SparType}>{"Spar"}</option>
                  <option value={FoundationTypeIds.MonopileType}>
                    {"Simple monopile"}
                  </option>
                  <option value={FoundationTypeIds.JacketType}>
                    {"Jacket"}
                  </option>
                  <option value={FoundationTypeIds.DetailedMonopileType}>
                    {"Site-specific monopile"}
                  </option>
                </Dropdown>
              </Label>
              <Label>
                <p>Primary material</p>
                <Dropdown
                  id="primary-material"
                  disabled={
                    isDisabled ||
                    isJacket(tempFoundation) ||
                    isMonopile(tempFoundation) ||
                    isDetailedMonopile(tempFoundation)
                  }
                  value={tempFoundation.material}
                  onChange={(e) => {
                    setTempFoundation((curr) => ({
                      ...curr,
                      material: e.target.value,
                    }));
                    setHasChanged(true);
                  }}
                >
                  <option value={"Steel"}>{"Steel"}</option>
                  <option value={"Concrete"}>{"Concrete"}</option>
                </Dropdown>
              </Label>
              {(isJacket(tempFoundation) || isMonopile(tempFoundation)) && (
                <>
                  <Label>
                    <Row>
                      <p>Weight [tonnes]</p>
                      <HelpTooltip text="A constant number, or an equation describing how the weight of the foundation varies with rated power (p) in MW and/or water depth (w) in m. Other possible variables are: rotor diameter (d) in m." />
                    </Row>
                    <TextArea
                      cols={20}
                      rows={3}
                      disabled={isDisabled}
                      value={tempFoundation.scalingEquation}
                      onChange={(e) => {
                        setTempFoundation((curr) => ({
                          ...curr,
                          scalingEquation: e.target.value,
                        }));
                        setHasChanged(true);
                        const isValidEquation = validEquation(e.target.value);
                        setIsValid(isValidEquation);
                      }}
                    />
                  </Label>
                </>
              )}
              {isFloater(tempFoundation) && (
                <FloatingFoundationSettings
                  tempFoundation={tempFoundation}
                  setTempFoundation={setTempFoundation}
                  setHasChanged={setHasChanged}
                  disabled={isDisabled}
                />
              )}
              {isDetailedMonopile(tempFoundation) && (
                <DetailedMonopileSettings
                  tempFoundation={tempFoundation}
                  setTempFoundation={setTempFoundation}
                  setHasChanged={setHasChanged}
                  disabled={isDisabled}
                />
              )}
              <Label>
                <p>Description</p>
                <TextArea
                  rows={6}
                  disabled={isDisabled}
                  value={tempFoundation.info}
                  style={{ resize: "vertical" }}
                  placeholder="Add a description"
                  onChange={(e) => {
                    setTempFoundation((curr) => ({
                      ...curr,
                      info: e.target.value,
                    }));
                    setHasChanged(true);
                  }}
                />
              </Label>
              {(isJacket(tempFoundation) || isMonopile(tempFoundation)) &&
                isValid && (
                  <React.Suspense fallback={null}>
                    <FoundationGraph
                      scalingEquation={tempFoundation.scalingEquation}
                    />
                  </React.Suspense>
                )}
              {!isValid && (
                <SimpleAlert text={"Invalid weight equation"} type={"error"} />
              )}
            </WrapColumn>
          </ColumnContent>
        </ContentWrapper>
        <SettingsFooter>
          <SettingsFooterContainerWrapper>
            <FoundationTypeUsage foundationId={foundation.id} nodeId={nodeId} />
            <ComponentLastChanged component={foundation} />
          </SettingsFooterContainerWrapper>
        </SettingsFooter>
      </>
    );
  },
  () => {
    return (
      <>
        <FatalErrorBoundaryWrapper />
        <ErrorBoundaryWarningTriangle />
      </>
    );
  },
  ScreamOnError,
);

export default FoundationSettings;
