import styled from "styled-components";
import { Row } from "../../General/Layout";
import { colors } from "../../../styles/colors";
import { typography } from "../../../styles/typography";
import { spaceLarge, spaceMedium } from "../../../styles/space";

export const CableColorBar = styled.div<{ color: string }>`
  background: ${(props) => props.color};
  align-self: center;
  height: 2rem;
  width: 0.3rem;
`;

export const HoverRow = styled(Row)<{ selected?: boolean }>`
  &:hover {
    background: ${({ selected }) =>
      selected ? colors.hoverSelected : colors.hover} !important;
  }
`;

export const TableHeader = styled.h4`
  margin: ${spaceMedium} ${spaceLarge} 0 ${spaceLarge};
`;

const selFirstRow = ({ columns }: { columns: number }): string =>
  `> div:nth-child(-n + ${columns})`;
const selFirstCol = ({ columns }: { columns: number }): string =>
  `> div:nth-child(${columns}n + 1)`;
const selFirstColNoHeader = ({ columns }: { columns: number }): string =>
  `> div:nth-child(${columns}n + ${columns + 1})`;
const selLastCol = ({ columns }: { columns: number }): string =>
  `> div:nth-child(${columns}n + ${columns})`;

export const CableTable = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
  color: ${colors.primaryText};

  > div {
    box-sizing: border-box;
    border-bottom: 1px solid ${colors.inputOutline};
    border-right: 1px solid ${colors.inputOutline};
    padding: 0.4rem;
    ${typography.contentAndButtons}
    white-space: nowrap;
  }

  ${selFirstRow} {
    ${typography.h4};
    text-align: center;
  }

  ${selLastCol} {
    font-weight: 600;
    border-right: none;
  }

  ${selFirstColNoHeader} {
    justify-content: center;
  }

  ${selFirstRow}, ${selFirstCol}, ${selLastCol} {
    background: ${colors.focusBackground};
    font-weight: 600;
  }

  ${selFirstRow} {
    border-top: 1px solid ${colors.inputOutline};
  }

  ${selFirstCol} {
    border-left: none;
  }
`;
