import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { projectIdSelector } from "state/pathParams";
import * as timeline from "state/timeline";
import { editorAccessProjectSelector } from "state/user";
import { SkeletonText } from "components/Loading/Skeleton";
import { EditableText } from "components/General/EditableText";
import { ProjectMeta } from "types/api";
import { useOrganisationNodeCrud } from "components/Projects/useOrganisationFolderCrud";
import { typography } from "styles/typography";
import { colors } from "styles/colors";

function EditableProjectNameInner({
  projectMeta,
}: {
  projectMeta: ProjectMeta;
}) {
  const { update: updateNode } = useOrganisationNodeCrud();
  const editorAccessProject = useRecoilValue(editorAccessProjectSelector);

  const [title, setTitle] = useState(projectMeta.name);

  useEffect(() => {
    setTitle(projectMeta.name);
  }, [projectMeta.name]);

  const renameProject = useCallback(() => {
    if (title === "") return;
    const renameProjectAsync = async () => {
      await updateNode(projectMeta.id, { name: title });
    };

    renameProjectAsync();
  }, [title, updateNode, projectMeta.id]);

  return (
    <React.Suspense fallback={<SkeletonText />}>
      <EditableText
        type="text"
        smallInput={true}
        style={{
          ...typography.sub1,
          color: "white",
        }}
        value={title}
        onChange={(e) => {
          setTitle(e.target.value);
        }}
        onEnter={renameProject}
        onCancel={renameProject}
        renderText={(title) => (
          <p
            style={{
              ...typography.sub1,
              color: "white",
            }}
            title={title}
          >
            {title}
          </p>
        )}
        disabled={!editorAccessProject}
        editIconStrokeColor={colors.white}
        editIconStrokeHoverColor={colors.blue300}
        tooltipTheme="light"
        tooltipPosition="bottom"
      />
    </React.Suspense>
  );
}

const EditableProjectName = () => {
  const projectId = useRecoilValue(projectIdSelector);
  const projectMeta = useRecoilValue(
    timeline.customerProjectAtomFamily({ nodeId: projectId ?? "" }),
  );

  if (!projectMeta) {
    return null;
  }

  return <EditableProjectNameInner projectMeta={projectMeta} />;
};

export default EditableProjectName;
