import React from "react";
import { FallbackProps } from "react-error-boundary";
import Button from "../General/Button";
import { HandleError } from "./ErrorHandling";
import { ErrorTextWrapper, Wrapper } from "./ErrorBoundaryGlobal.styles";

function ErrorBoundaryGlobalFallback({
  error,
  resetErrorBoundary,
}: FallbackProps) {
  return (
    <HandleError error={error}>
      <Wrapper>
        <ErrorTextWrapper>
          An error has occured, please refresh the page in order to continue. We
          apologize for the inconvenience
        </ErrorTextWrapper>
        <Button
          onClick={resetErrorBoundary}
          text="Refresh"
          buttonType="primary"
        />
      </Wrapper>
    </HandleError>
  );
}

export default ErrorBoundaryGlobalFallback;
