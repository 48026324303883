import { LibraryResourceConsequences } from "components/Projects/useExplainNodeMove";
import AlertCircle from "@icons/24/AlertCircle.svg?react";
import { Column, Row } from "components/General/Layout";
import { useMemo } from "react";
import { ValidationAlert } from "components/ValidationWarnings/ValidationAlert";
import { VerticalDivider } from "components/General/VerticalDivider";
import { LibraryResourceType } from "../../Library/types";
import { ValidationDescription, Subtitle, TextCutOff, Text } from "../style";

export function ResourceWarning({
  resourceAccess,
}: {
  resourceAccess: LibraryResourceConsequences;
}) {
  const numberOfChanges =
    resourceAccess.added.length + resourceAccess.removed.length;

  // sorted by type so that all turbines are together, all substations are together etc
  const processedResources: {
    type: LibraryResourceType;
    resources: {
      name: string;
      change: "added" | "removed";
    }[];
  }[] = useMemo(() => {
    const resources = resourceAccess.added
      .map((r) => ({ ...r, change: "added" }))
      .concat(
        resourceAccess.removed.map((r) => ({
          ...r,
          change: "removed",
        })),
      );

    const sortedResources = resources.sort((a, b) =>
      a.type.localeCompare(b.type),
    );

    const processed: {
      type: LibraryResourceType;
      resources: {
        name: string;
        change: "added" | "removed";
      }[];
    }[] = [];

    sortedResources.forEach((r) => {
      const type = r.type;
      const existing = processed.find((p) => p.type === type);
      if (existing) {
        existing.resources.push({
          name: r.resource_name,
          change: r.change as "added" | "removed",
        });
      } else {
        processed.push({
          type,
          resources: [
            { name: r.resource_name, change: r.change as "added" | "removed" },
          ],
        });
      }
    });

    return processed;
  }, [resourceAccess]);

  return (
    <ValidationAlert
      title={`${numberOfChanges} resource${numberOfChanges > 1 ? "s" : ""}`}
      renderDecription={() => (
        <ValidationDescription style={{ gap: "1.6rem" }}>
          {processedResources.map((resourceType) => {
            return (
              <Column key={resourceType.type}>
                <Subtitle>{resourceType.type}</Subtitle>
                <Column style={{ gap: "0.6rem" }}>
                  {resourceType.resources.map((resource) => {
                    return (
                      <Row
                        key={resource.name}
                        style={{ gap: "0.8rem", alignItems: "center" }}
                      >
                        <TextCutOff>{resource.name}</TextCutOff>
                        <VerticalDivider height={1.4} />
                        <Row style={{ alignItems: "center", gap: "0.4rem" }}>
                          <Text style={{ textTransform: "capitalize" }}>
                            {resource.change}
                          </Text>
                          {resource.change === "removed" && <AlertCircle />}
                        </Row>
                      </Row>
                    );
                  })}
                </Column>
              </Column>
            );
          })}
        </ValidationDescription>
      )}
      type="info"
    />
  );
}
