import type {
  ExternalDataSource,
  PrivateDataSource,
  SourceTypesLayer,
} from "../../types/layers";
import { isDefined } from "../../utils/predicates";
import { Layer } from "../../types/layers";
import { bboxInsideMapBBOX } from "../LayerList/utils";
import type { LayerFilterState } from "./layer-filter-state";

export interface FilterResultInactive {
  active: false;
}

export interface FilterResultActive {
  active: true;
  match: boolean;
  weight: number;
}

export type FilterResult = FilterResultInactive | FilterResultActive;

export const getUniqueSourceNames = (
  sources: (ExternalDataSource | PrivateDataSource)[],
): string[] => {
  const allNames = sources.map((source) => source.name);
  return [...new Set(allNames)].sort((a, b) => a.localeCompare(b));
};

export const getUniqueSortedTags = (layers: Layer[]): string[] => {
  const tags = layers.flatMap((layer) => layer.tags).filter(isDefined);
  return [...new Set(tags)].sort((a, b) => a.localeCompare(b));
};

export const getUniqueCountries = (sources: ExternalDataSource[]): string[] => {
  const countries = (
    sources.flatMap((source) => source.countries).filter(Boolean) as string[]
  ).map((c) => c.toLowerCase());

  return [...new Set(countries)].sort((a, b) => a.localeCompare(b));
};

export const filterBySourceWeighted = (
  layer: Layer,
  filters: LayerFilterState,
): FilterResult => {
  if (filters.source.length === 0) {
    return {
      active: false,
    };
  }

  const findings = filters.source.filter(
    (sourceName) =>
      sourceName.toLowerCase() === layer.source?.name.toLowerCase(),
  );

  return {
    active: true,
    weight: 1 * findings.length,
    match: findings.length > 0,
  };
};

export const filterByTagsWeighted = (
  layerTags: string[] | undefined,
  filters: LayerFilterState,
) => {
  if (filters.tags.length === 0) {
    return {
      active: false,
    };
  }

  const findings = filters.tags.filter((tag) => layerTags?.includes(tag));

  return {
    active: true,
    weight: 1 * findings.length,
    match: findings.length > 0,
  };
};

export const filterBySourceTypeWeighted = (
  layerTag: SourceTypesLayer,
  filters: LayerFilterState,
) => {
  if (filters.sourceTypes.length === 0) {
    return {
      active: false,
    };
  }

  const findings = filters.sourceTypes.filter((f) => f === layerTag);

  return {
    active: true,
    weight: 1 * findings.length,
    match: findings.length > 0,
  };
};

export const filterBySearchWeighted = (
  layer: Layer,
  filters: LayerFilterState,
): FilterResult => {
  if (!filters.searchString) {
    return {
      active: false,
    };
  }

  const searchWords = filters.searchString
    .toLowerCase()
    .split(/ +/)
    .filter((s) => s.length !== 0);

  const nameSearch = filterByNameSearchWeighted(layer, searchWords);
  const abstractSearch = filterByAbstractSearchWeighted(layer, searchWords);

  const weight = [nameSearch, abstractSearch].reduce((acc, curr) => {
    if (curr.match) {
      return acc + curr.weight;
    }
    return acc;
  }, 0);

  return {
    active: true,
    match: nameSearch.match || abstractSearch.match,
    weight,
  };
};

export const filterByNameSearchWeighted = (
  layer: Layer,
  searchWords: string[],
): FilterResultActive => {
  const result = makeSearchFilterName(searchWords)(layer);
  return {
    active: true,
    match: result,
    weight: 5,
  };
};

export const filterByAbstractSearchWeighted = (
  layer: Layer,
  searchWords: string[],
): FilterResultActive => {
  const result = makeSearchFilterAbstract(searchWords)(layer);
  return {
    active: true,
    match: result,
    weight: 1,
  };
};

export const filterByBBOXWeighted = (
  layer: Layer,
  filters: LayerFilterState,
  mapBBOX?: number[],
): FilterResult => {
  if (
    !filters.filterOnBBOX ||
    !mapBBOX ||
    mapBBOX.length === 0 ||
    !layer.bbox
  ) {
    return {
      active: false,
    };
  }

  return {
    active: true,
    match: bboxInsideMapBBOX(mapBBOX, layer.bbox),
    weight: 1,
  };
};

export const filterByCountryWeighted = (
  source: ExternalDataSource | undefined,
  filters: LayerFilterState,
): FilterResult => {
  if (filters.country.length === 0) {
    return {
      active: false,
    };
  }

  const results = filters.country.filter((country) =>
    source?.countries?.map((c) => c.toLowerCase()).includes(country),
  );

  return {
    active: true,
    match: results.length > 0,
    weight: 1 * results.length,
  };
};

export const filterByDebugMissingTagsWeighted = (
  layerTags: string[] | undefined,
  filters: LayerFilterState,
): FilterResult => {
  if (!filters.debug_missing_tags) {
    return {
      active: false,
    };
  }

  return {
    active: true,
    match: !layerTags || layerTags.length === 0,
    weight: 1,
  };
};

export const filterByDebugMissingCountriesWeighted = (
  source: ExternalDataSource | undefined,
  filters: LayerFilterState,
): FilterResult => {
  if (!filters.debug_missing_countries) {
    return {
      active: false,
    };
  }

  return {
    active: true,
    match: source?.countries == null || source?.countries.length === 0,
    weight: 1,
  };
};

const makeSearchFilterName = (
  searchWords: string[],
): ((layer: Layer) => boolean) => {
  return (layer) => {
    if (searchWords.length === 0) {
      return true;
    }
    const source = layer.source;
    const { name } = layer;
    const searchableStrings = [
      name,
      source?.name,
      source?.originalName,
      ...(source?.alternativeNames ?? []),
    ]
      .filter(isDefined)
      .filter(Boolean)
      .map((s) => s!.toLowerCase());

    return searchWords.every((word) =>
      searchableStrings.some((searchable) => searchable.includes(word)),
    );
  };
};

const makeSearchFilterAbstract = (
  searchWords: string[],
): ((layer: Layer) => boolean) => {
  return (layer) => {
    if (searchWords.length === 0) {
      return true;
    }
    const sourceLink = (layer as any)["sourceLink"];
    const { sourceType, id } = layer;
    const searchableStrings = [
      id,
      sourceType,
      (layer as any)["abstract"],
      (layer as any)["abstractEnglish"],
      sourceLink?.abstract,
      sourceLink?.abstractEnglish,
      sourceLink?.url,
    ]
      .filter(isDefined)
      .filter(Boolean)
      .map((s) => s!.toLowerCase());

    return searchWords.every((word) =>
      searchableStrings.some((searchable) => searchable.includes(word)),
    );
  };
};
