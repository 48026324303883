import { useMemo } from "react";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import {
  foundationTypeUsageAtomFamily,
  FoundationTypeUsageType,
} from "../../../state/foundations";
import { useTypedPath } from "../../../state/pathParams";

import {
  adminInOrganisationSelectorFamily,
  orgFoundationManageAccessSelector,
  userNodeAccessSelectorFamily,
} from "../../../state/user";
import { ProjectBranchInfo, UsageContainerInner } from "./common";
import { Header, SubHeader, UsageText } from "./style";

export default function FoundationTypeUsage({
  foundationId,
  nodeId,
}: {
  foundationId: string;
  nodeId: string;
}) {
  const { organisationId } = useTypedPath("organisationId");
  const adminInOrg = useRecoilValue(
    adminInOrganisationSelectorFamily({ organisationId }),
  );
  const hasOrgFoundationAccess = useRecoilValue(
    orgFoundationManageAccessSelector,
  );
  const nodeAccess = useRecoilValue(userNodeAccessSelectorFamily({ nodeId }));
  const isAdminForNode = nodeAccess >= 2;

  if (!adminInOrg && !hasOrgFoundationAccess && !isAdminForNode) return null;

  return (
    <FoundationTypeUsageInner foundationId={foundationId} nodeId={nodeId} />
  );
}

function FoundationTypeUsageInner({
  nodeId,
  foundationId,
}: {
  nodeId: string;
  foundationId: string;
}) {
  const usageLoadable = useRecoilValueLoadable(
    foundationTypeUsageAtomFamily({
      nodeId,
      foundationId,
    }),
  );

  return (
    <UsageContainerInner
      loadable={usageLoadable}
      text="This type is used in: "
      typeName="foundations"
    >
      <ExtraInfo usage={usageLoadable.contents} />
    </UsageContainerInner>
  );
}

function ExtraInfo({ usage }: { usage: FoundationTypeUsageType[] }) {
  const uniqueBranches = useMemo(
    () =>
      usage.reduce<FoundationTypeUsageType[]>(
        (pre, cur) =>
          pre.some((p) => p.branchId === cur.branchId) ? pre : pre.concat(cur),
        [],
      ),
    [usage],
  );

  return (
    <>
      <Header>Foundation type usage</Header>
      <div style={{ display: "flex", flexDirection: "column", gap: "0.8rem" }}>
        <SubHeader>Used in</SubHeader>
        <UsageText>{usage.length} foundations</UsageText>
      </div>
      {usage.length > 0 && (
        <div
          style={{ display: "flex", flexDirection: "column", gap: "0.8rem" }}
        >
          <SubHeader>Used in branches</SubHeader>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
          >
            {uniqueBranches.map((u) => (
              <ProjectBranchInfo key={u.branchId} usage={u} />
            ))}
          </div>
        </div>
      )}
    </>
  );
}
