import { useState, useCallback, ChangeEvent } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { useUpdateUserMeta } from "../../hooks/useUser";
import { UserInterests } from "../../types/user";
import Button from "../General/Button";
import Checkbox from "../General/Checkbox";
import { Input } from "../General/Input";
import { Column, Row } from "../General/Layout";
import { acceptedTermsOfUseState, wizardStepState } from "./TermsOfUseWizard";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
`;

const WrapContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem 4rem;
  flex-wrap: wrap;
  height: 17rem;
  width: max-content;
`;

const OPTIONS: { key: string; label: string }[] = [
  { key: "electrical", label: "Electrical" },
  { key: "yield", label: "Yield" },
  { key: "gis", label: "GIS" },
  { key: "foundation", label: "Foundation" },
  { key: "environmental", label: "Environmental" },
  { key: "financial", label: "Financial" },
  { key: "business_development", label: "Business development" },
  { key: "project_management", label: "Project management" },
  { key: "leadership", label: "Leadership" },
];

export default function Interests({
  currentInterests,
  currentReceiveNews,
}: {
  currentInterests: UserInterests;
  currentReceiveNews: boolean;
}) {
  const updateUserMeta = useUpdateUserMeta();
  const setStep = useSetRecoilState(wizardStepState);
  const hasRead = useRecoilValue(acceptedTermsOfUseState);

  const [saving, setSaving] = useState(false);

  const [state, setState] = useState<UserInterests>(currentInterests);
  const [otherSelected, setOtherSelected] = useState(!!currentInterests.other);

  const [receiveNews, setReceiveNews] = useState(currentReceiveNews);

  const handleOtherChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setState((curr) => {
      const copy = { ...curr };
      if (value.length === 0) {
        delete copy.other;
      } else {
        copy["other"] = value;
      }
      return copy;
    });
  }, []);

  const onSave = useCallback(async () => {
    setSaving(true);
    const obj = {
      interests: state ?? {},
      allow_interest_specific_news: receiveNews,
      accept_terms_of_use: hasRead,
    };
    if (receiveNews) (obj as any)["allow_news"] = true;
    await updateUserMeta(obj);
    setSaving(false);
  }, [hasRead, receiveNews, state, updateUserMeta]);

  return (
    <Container>
      <Column>
        <h2 style={{ margin: 0 }}>{"Tailor your Vind AI experience"}</h2>
        <p style={{ margin: 0 }}>
          Share your interests to get the most out of our product.
        </p>
      </Column>
      <Column>
        <h4 style={{ margin: 0 }}>Interests</h4>
        <WrapContainer>
          {OPTIONS.map((o) => (
            <Checkbox
              disabled={saving}
              key={o.key}
              label={o.label}
              value={o.key}
              checked={(state as any)[o.key]}
              labelPlacement="after"
              onChange={(e) => {
                setState((curr) => {
                  const interestsCopy = { ...curr };
                  if (!e.target.checked) {
                    delete (interestsCopy as any)[o.key];
                  } else {
                    (interestsCopy as any)[o.key] = true;
                  }
                  return interestsCopy;
                });
              }}
            />
          ))}

          <Checkbox
            disabled={saving}
            key={"other"}
            label={"Other"}
            value={"other"}
            checked={otherSelected}
            labelPlacement="after"
            onChange={(e) => {
              if (e.target.checked) {
                setOtherSelected(true);
                setState((curr) => {
                  return { ...curr, other: "" };
                });
              } else {
                setOtherSelected(false);
                setState((curr) => {
                  const copy = { ...curr };
                  delete copy.other;
                  return copy;
                });
              }
            }}
          />
          {otherSelected && (
            <Input
              value={state.other ?? ""}
              onChange={handleOtherChange}
              style={{ marginLeft: "2.4rem" }}
              autoFocus
              maxLength={50}
            />
          )}
        </WrapContainer>
      </Column>
      <Column>
        <h4 style={{ margin: 0 }}>Stay up to date</h4>
        <Checkbox
          disabled={saving}
          label={"Receive product updates based on my selections"}
          value={"other"}
          checked={receiveNews}
          labelPlacement="after"
          onChange={(e) => {
            setReceiveNews(e.target.checked);
          }}
        />
      </Column>
      <Row style={{ marginTop: "6.8rem" }}>
        <Button
          text="Back"
          disabled={saving}
          buttonType="secondary"
          style={{ marginLeft: "auto" }}
          onClick={() => setStep((cur) => Math.max(0, cur - 1))}
        />
        <Button
          disabled={saving}
          text="Save"
          onClick={() => {
            onSave();
          }}
        />
      </Row>
    </Container>
  );
}
