import { useRecoilCallback } from "recoil";

import {
  addOrUpdateAnalysisResourceOnNode,
  deleteAnalysisResourceOnNode,
} from "components/Organisation/Library/service";
import {
  analysisResourceWithAccessOnNodeState,
  nodesWithAccessToAnalysis,
} from "components/Organisation/Library/state";
import { useTypedPath } from "state/pathParams";
import { Configuration } from "services/configurationService";
import { analysisConfigurationUsageAtomFamily } from "state/configuration";
import { userAllNodesAccessAtom } from "state/user";

const useNodeAnalysisResourcesCrud = () => {
  const { organisationId } = useTypedPath("organisationId");
  const addOrUpdate = useRecoilCallback(
    ({ snapshot, set, refresh }) =>
      async (nodeId: string, config: Configuration) => {
        const fallback = await snapshot.getPromise(
          analysisResourceWithAccessOnNodeState({ nodeId }),
        );
        const allNodeIds = await snapshot.getPromise(userAllNodesAccessAtom);
        return addOrUpdateAnalysisResourceOnNode(nodeId, config.id)
          .then(() => {
            Object.keys(allNodeIds.node_access).map((nodeId) =>
              refresh(analysisResourceWithAccessOnNodeState({ nodeId })),
            );
            refresh(
              nodesWithAccessToAnalysis({
                organisationId,
                resourceId: config.id,
              }),
            );
          })
          .catch((e) => {
            set(analysisResourceWithAccessOnNodeState({ nodeId }), fallback);
            throw e;
          });
      },
    [organisationId],
  );

  const remove = useRecoilCallback(
    ({ snapshot, set, refresh }) =>
      async (nodeId: string, resourceId: string) => {
        const fallback = await snapshot.getPromise(
          analysisResourceWithAccessOnNodeState({ nodeId }),
        );
        const usage = await snapshot.getPromise(
          analysisConfigurationUsageAtomFamily({
            nodeId,
            analysisConfigurationId: resourceId,
          }),
        );
        const allNodeIds = await snapshot.getPromise(userAllNodesAccessAtom);

        if (
          usage.length === 0 ||
          window.confirm(
            `Analysis is currently being used in ${usage.length} projects, are you sure you want to remove access to it?`,
          )
        ) {
          return deleteAnalysisResourceOnNode(nodeId, resourceId)
            .then(() => {
              refresh(
                nodesWithAccessToAnalysis({ organisationId, resourceId }),
              );
              Object.keys(allNodeIds.node_access).map((nodeId) =>
                refresh(analysisResourceWithAccessOnNodeState({ nodeId })),
              );
            })
            .catch((e) => {
              set(analysisResourceWithAccessOnNodeState({ nodeId }), fallback);
              throw e;
            });
        }
      },
    [organisationId],
  );

  return { addOrUpdate, remove };
};

export default useNodeAnalysisResourcesCrud;
