import { ABLY_MOVE_NODE } from "../state/ably";
import { useCallback, useMemo } from "react";
import { Types } from "ably";
import { useAblyGeneric } from "./useAblyGeneric";
import { useOrganisationNodeCrud } from "../components/Projects/useOrganisationFolderCrud";
import { _MoveNode } from "../services/customerAPI";

export function useAblyNodes(organisationId: string) {
  const { moveLocal: moveLocalNode } = useOrganisationNodeCrud();

  const channelName = useMemo(() => `${organisationId}:all`, [organisationId]);

  const onMessageReceivedMove = useCallback(
    (message: Types.Message) => {
      const moveNode = _MoveNode.parse(message.data.meta);
      moveLocalNode(moveNode);
    },
    [moveLocalNode],
  );

  const events = useMemo(
    () =>
      organisationId
        ? [
            {
              eventName: ABLY_MOVE_NODE,
              onMessageReceived: onMessageReceivedMove,
            },
          ]
        : [],
    [onMessageReceivedMove, organisationId],
  );

  useAblyGeneric(channelName, events);
}
