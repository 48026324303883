import { CenterContainer, SafeCard } from "./Base";
import { orLoader } from "../../Loading/Skeleton";
import { CostType } from "../../../services/costService";
import { amountUnitPrefixSuffix, CostUnit } from "../../../types/financial";
import { useDashboardContext } from "../Dashboard";
import { getInvalidTypesInParkAndBranch } from "state/layout";
import { useRecoilValue } from "recoil";
import { InvalidTypesDisplayText } from "types/invalidTypes";
import { Stat, Stats, StatHeaderLoadable } from "./Base.style";
import { currentParkTriggerId } from "components/Finance/Triggers";
import { useCapexMillionPV } from "components/Finance/capex";
import { FinanceId } from "components/Finance/state";
import { DownloadCapexData } from "components/FinancialAnalysis/Capex/DownloadCapexData";
import { getStoppedReason } from "components/ProductionV2/state";
import { ValidationAlert } from "components/ValidationWarnings/ValidationAlert";
import { analysisStoppedText } from "components/ProductionV2/types";

export const CapexTableWidget = () => {
  const { park, branch } = useDashboardContext();

  const invalidTypes = useRecoilValue(
    getInvalidTypesInParkAndBranch({ parkId: park.id, branchId: branch.id }),
  );

  if (invalidTypes.length > 0)
    return (
      <CenterContainer style={{ margin: "3rem" }}>
        <InvalidTypesDisplayText invalidTypes={invalidTypes} />
      </CenterContainer>
    );

  return (
    <SafeCard
      title="CAPEX"
      id="Capex table"
      menuItems={
        <DownloadCapexData id={currentParkTriggerId} buttonType={"menu"} />
      }
    >
      <CAPEXTableInnerV2 />
    </SafeCard>
  );
};

const CAPEXCategoryPrinterV2 = ({
  title,
  type,
  id,
}: {
  title: string;
  type: CostType;
  id: FinanceId;
}) => {
  const { useCosts } = useCapexMillionPV(id);
  const { costs, sum } = useCosts(type);

  return (
    <>
      <StatHeaderLoadable title={title} value={sum} unit={"m€"} />
      {orLoader(costs, (costs) => (
        <>
          {costs
            .filter((c) => c.amount !== 0)
            .map((c) => (
              <Stat
                bold={false}
                key={c.id}
                title={`${
                  amountUnitPrefixSuffix[c.amountUnit]?.prefix ?? ""
                }${Math.round(c.amount)} ${
                  amountUnitPrefixSuffix[c.amountUnit]?.suffix ?? ""
                } ${c.name}`}
                value={`${c.cost} ${CostUnit.millionEuro}`}
              />
            ))}
        </>
      ))}
    </>
  );
};

const CAPEXTableInnerV2 = () => {
  const analysisStoppedReason = useRecoilValue(
    getStoppedReason(currentParkTriggerId),
  );
  if (analysisStoppedReason) {
    return (
      <ValidationAlert
        type={"error"}
        title="Analysis stopped"
        description={analysisStoppedText[analysisStoppedReason]}
      />
    );
  }

  return (
    <Stats>
      <CAPEXCategoryPrinterV2
        title={"Turbines"}
        type={CostType.Turbine}
        id={currentParkTriggerId}
      />
      <CAPEXCategoryPrinterV2
        title={"Inter array cabling"}
        type={CostType.Cable}
        id={currentParkTriggerId}
      />
      <CAPEXCategoryPrinterV2
        title={"Foundations"}
        type={CostType.Foundation}
        id={currentParkTriggerId}
      />
      <CAPEXCategoryPrinterV2
        title={"Mooring"}
        type={CostType.Mooring}
        id={currentParkTriggerId}
      />
      <CAPEXCategoryPrinterV2
        title={"Substation"}
        type={CostType.Substation}
        id={currentParkTriggerId}
      />
      <CAPEXCategoryPrinterV2
        title={"Export cable"}
        type={CostType.ExportCable}
        id={currentParkTriggerId}
      />
      <CAPEXCategoryPrinterV2
        title={"Other"}
        type={CostType.Other}
        id={currentParkTriggerId}
      />
    </Stats>
  );
};
