import { useEffect } from "react";
import { selectorFamily, useRecoilValue } from "recoil";
import * as turf from "@turf/turf";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { WarningProps } from "./ValidationWarnings";
import { projectFeaturesInBranchSelectorFamily } from "components/ProjectElements/state";
import { isCableCorridor } from "utils/predicates";
import { getParkFeatureInBranchSelector } from "state/park";
import { branchIdSelector } from "state/pathParams";

export const cableCorridorNotConnectedToParkWarningSelectorFamily =
  selectorFamily<
    | {
        type: ValidationWarningTypes.CableCorridorNotConnectedToPark;
        featureIds: string[];
      }
    | undefined,
    { parkId: string; branchId: string }
  >({
    key: "cableCorridorNotConnectedToParkWarningSelectorFamily",
    get:
      ({ parkId, branchId }) =>
      ({ get }) => {
        const park = get(getParkFeatureInBranchSelector({ parkId, branchId }));
        if (!park) return;
        const branchData = get(
          projectFeaturesInBranchSelectorFamily({ branchId }),
        );
        const cableCorridorFeatures = branchData
          .filter(isCableCorridor)
          .filter((p) => p.properties?.parentIds?.includes(parkId));

        const corridorsNotConnectedToParkIds = cableCorridorFeatures
          .filter((cc) => {
            const union = turf.union(cc, park);
            return union == null || union.geometry.type.includes("Multi");
          })
          .map((cc) => cc.id);

        return corridorsNotConnectedToParkIds.length === 0
          ? undefined
          : {
              type: ValidationWarningTypes.CableCorridorNotConnectedToPark,
              featureIds: corridorsNotConnectedToParkIds,
            };
      },
  });

const CableCorridorNotConnectedToPark = ({
  parkId,
  warn,
  remove,
}: { parkId: string } & WarningProps) => {
  const branchId = useRecoilValue(branchIdSelector);
  const cableCorridorFeaturesWarning = useRecoilValue(
    cableCorridorNotConnectedToParkWarningSelectorFamily({
      parkId: parkId,
      branchId: branchId ?? "",
    }),
  );

  useEffect(() => {
    if (!cableCorridorFeaturesWarning) return;
    warn(cableCorridorFeaturesWarning);
    return () => {
      remove(ValidationWarningTypes.CableCorridorNotConnectedToPark);
    };
  }, [cableCorridorFeaturesWarning, parkId, remove, warn]);

  return null;
};

export default CableCorridorNotConnectedToPark;
