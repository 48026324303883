import { Suspense, useMemo } from "react";
import { useRecoilValue } from "recoil";

import { getParkFeatureSelectorFamily } from "../../../../state/park";
import {
  branchIdSelector_,
  parkIdSelector,
} from "../../../../state/pathParams";
import { SkeletonText } from "../../../Loading/Skeleton";
import {
  ARTICLE_FOUNDATION_STATS,
  HelpLink,
} from "../../../HelpTooltip/HelpTooltip";
import { MenuFrame } from "../../../MenuPopup/CloseableMenuPopup";
import { FoundationStatError } from "components/ValidationWarnings/FeatureSpecificErrors";
import Tabs, { TabData } from "components/General/Tabs";
import FoundationTab from "./FoundationTab";
import MooringTab from "../MooringTab";
import styled from "styled-components";

const TabContentWrapper = styled.div`
  padding-top: 1.6rem;
`;

const FoundationModal = ({ onClose }: { onClose(): void }) => {
  const parkId = useRecoilValue(parkIdSelector);
  const branchId = useRecoilValue(branchIdSelector_);

  const park = useRecoilValue(
    getParkFeatureSelectorFamily({ parkId: parkId ?? "" }),
  );

  const tabs: TabData[] = useMemo(() => {
    if (!park) {
      return [];
    }

    return [
      {
        name: "Foundations",
        data: <FoundationTab park={park} />,
      },
      {
        name: "Mooring",
        data: <MooringTab park={park} />,
      },
    ];
  }, [park]);

  if (!park) return null;

  return (
    <MenuFrame
      icon={<HelpLink article={ARTICLE_FOUNDATION_STATS} />}
      title={"Foundation"}
      style={{ maxHeight: "calc(100vh - 22rem)" }}
      onExit={onClose}
      validationError={
        <FoundationStatError parkId={park.id} branchId={branchId} />
      }
    >
      <Tabs
        tabs={tabs}
        wrapperStyle={{ padding: "0rem 1.6rem" }}
        contentWrapperStyle={{ height: "100%" }}
        menuStyle={{ justifyContent: "flex-start" }}
      >
        {(tab) => (
          <Suspense fallback={<SkeletonText />}>
            <TabContentWrapper>{tab}</TabContentWrapper>
          </Suspense>
        )}
      </Tabs>
    </MenuFrame>
  );
};

export default FoundationModal;
