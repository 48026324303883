const g = 7;
const C = [
  0.99999999999980993, 676.5203681218851, -1259.1392167224028,
  771.32342877765313, -176.61502916214059, 12.507343278686905,
  -0.13857109526572012, 9.9843695780195716e-6, 1.5056327351493116e-7,
];

function gamma(z: number): number {
  if (z < 0.5) return Math.PI / (Math.sin(Math.PI * z) * gamma(1 - z));
  else {
    z -= 1;

    var x = C[0];
    for (var i = 1; i < g + 2; i++) x += C[i] / (z + i);

    var t = z + g + 0.5;
    return Math.sqrt(2 * Math.PI) * Math.pow(t, z + 0.5) * Math.exp(-t) * x;
  }
}

export function weibullMedian(a: number, k: number) {
  return Math.round(a * Math.log(2) ** (1 / k) * 100) / 100;
}

export function weibullMean(a: number, k: number) {
  return Math.round(a * gamma(1 + 1 / k) * 100) / 100;
}
