import { useCallback } from "react";
import { useRecoilCallback, useRecoilState } from "recoil";
import debounce from "debounce-promise";
import { useTypedPath } from "state/pathParams";
import { sortProjectElements, ProjectElementSortOrder } from "./service";
import { getProjectElementsSortOrderAtomFamily } from "./state";

const debouncedSortProjectElements = debounce(sortProjectElements, 1000);
export const useProjectElementsSortOrder = () => {
  const { projectId, branchId } = useTypedPath("projectId", "branchId");

  const [sortOrder, setSortOrder] = useRecoilState(
    getProjectElementsSortOrderAtomFamily({
      nodeId: projectId,
      branchId,
    }),
  );

  const localSortProjectElements = useCallback(
    (newSortOrder: ProjectElementSortOrder[]) => {
      setSortOrder(newSortOrder);
    },
    [setSortOrder],
  );

  const _sortProjectElements = useRecoilCallback(
    () =>
      async (
        newSortOrder: ProjectElementSortOrder[],
        _branchId: string = branchId,
      ) => {
        localSortProjectElements(newSortOrder);

        await debouncedSortProjectElements(projectId, _branchId, newSortOrder);
      },
    [branchId, localSortProjectElements, projectId],
  );

  return {
    sortOrder,
    sortProjectElements: _sortProjectElements,
    localSortProjectElements,
  };
};
