import { z } from "zod";
import { _WakeAnalysisConfiguration } from "../services/configurationService";
import { _OptimizeParameters, _TurbineType } from "../types/turbines";
import {
  fetchSchemaWithToken,
  fetchEnhancerWithToken,
} from "../services/utils";
import { Ids, Pids } from "../components/GenerateWindTurbines/state";
import { _RawWindRose } from "../state/windStatistics";
import { _WindSourceConfiguration } from "services/windSourceConfigurationService";

const _Turbine = z.object({
  lon: z.number(),
  lat: z.number(),
  type: z.string(),
});

const _RefLonLats = z.object({
  lon: z.number(),
  lat: z.number(),
});

const _PostOptProblemRet = z.object({
  id: z.string(),
  createdAt: z.number(),
});
type PostOptProblemRet = z.infer<typeof _PostOptProblemRet>;

const _PostOptProblemArgs = z
  .object({
    parkPolygon: z.tuple([z.number(), z.number()]).array(),
    exclusionPolygons: z.tuple([z.number(), z.number()]).array().array(),
    windConfiguration: _WindSourceConfiguration.optional(),
    turbineId: z.string(),
    turbineTypes: z.record(z.string(), _TurbineType),
    wakeSettings: _WakeAnalysisConfiguration.optional(),
    neighbourTurbines: _Turbine.array().optional(),
    internal: z.boolean().optional(),
    refLonLat: _RefLonLats,
    lonlats: z.boolean(),
  })
  .merge(_OptimizeParameters);

export type PostOptProblemArgs = z.infer<typeof _PostOptProblemArgs>;

export async function postOptProblem({
  ids: { nodeId, branchId, zoneId },
  args,
}: {
  ids: Ids;
  args: PostOptProblemArgs;
}): Promise<PostOptProblemRet> {
  return fetchSchemaWithToken(
    _PostOptProblemRet,
    `/api/octopus/layout/optimize/v2/node/${nodeId}/${branchId}/${zoneId}`,
    {
      method: "post",
      body: JSON.stringify(args),
      headers: {},
    },
  );
}

const _OptProblem = _OptimizeParameters
  .extend({
    id: z.string(),
    version: z.string(),
    createdAt: z.number(),
    parkPolygon: z.tuple([z.number(), z.number()]).array(),
    exclusionPolygons: z.tuple([z.number(), z.number()]).array().array(),
    turbineId: z.string(),
    turbineNodeId: z.string().optional(),
    internal: z.boolean().optional(),
  })
  .passthrough();
export type OptProblem = z.infer<typeof _OptProblem>;

export async function getOptProblem({
  nodeId,
  branchId,
  zoneId,
  id,
}: Pids): Promise<OptProblem> {
  return fetchSchemaWithToken(
    _OptProblem,
    `/api/octopus/layout/optimize/v2/node/${nodeId}/${branchId}/${zoneId}/${id}`,
    {
      method: "get",
      headers: {},
    },
  );
}

export async function deleteOptProblem({
  nodeId,
  branchId,
  zoneId,
  id,
}: Pids): Promise<{ success: boolean; error?: string }> {
  try {
    await fetchEnhancerWithToken(
      `/api/octopus/layout/optimize/v2/node/${nodeId}/${branchId}/${zoneId}/${id}`,
      {
        method: "delete",
        headers: {},
      },
    );
    return { success: true };
  } catch (error) {
    return { success: false, error: "Failed to delete optimisation problem" };
  }
}

const _ListOptProblems = z
  .object({
    id: z.string(),
    createdAt: z.number(),
    numberOfTurbines: z.number(),
  })
  .array();
export type ListOptProblems = z.infer<typeof _ListOptProblems>;

export async function listOptProblems({
  nodeId,
  branchId,
  zoneId,
}: Ids): Promise<ListOptProblems> {
  return fetchSchemaWithToken(
    _ListOptProblems,
    `/api/octopus/layout/optimize/v2/node/${nodeId}/${branchId}/${zoneId}`,
    {
      method: "get",
      headers: {},
    },
  );
}

const _RunningOptResult = z.object({
  task_status: z.literal("running"),
  version: z.string(),
  createdAt: z.number(),
});
export type RunningOptResult = z.infer<typeof _RunningOptResult>;

const _FailedOptResult = z.object({
  task_status: z.literal("failed"),
  version: z.string(),
  createdAt: z.number(),
  updatedAt: z.number(),
  seed: z.number().optional(),
});
export type FailedOptResult = z.infer<typeof _FailedOptResult>;

const _CompletedOptResult = z.object({
  version: z.string(),
  createdAt: z.number(),
  updatedAt: z.number(),
  task_status: z.literal("completed"),
  seed: z.number(),
  aep: z.number(),
  positions: z.tuple([z.number(), z.number()]).array(),
  optParameters: z.object({
    centerX: z.number(),
    centerY: z.number(),
    spacingX: z.number(),
    spacingY: z.number(),
    obliquity: z.number(),
    rotation: z.number(),
    edgeSpacing: z.number().optional(),
  }),
});
export type CompletedOptItem = z.infer<typeof _CompletedOptResult>;

const _OptResult = z.discriminatedUnion("task_status", [
  _RunningOptResult,
  _FailedOptResult,
  _CompletedOptResult,
]);
export type OptResult = z.infer<typeof _OptResult>;

const _ListResultsForProblem = z.object({
  id: z.string(),
  items: _OptResult.array(),
});
export type ListResultsForProblem = z.infer<typeof _ListResultsForProblem>;

export async function listResultsForProblem({
  nodeId,
  branchId,
  zoneId,
  id,
}: Pids): Promise<ListResultsForProblem> {
  return fetchSchemaWithToken(
    _ListResultsForProblem,
    `/api/octopus/layout/optimize/v2/node/${nodeId}/${branchId}/${zoneId}/${id}/results`,
    {
      method: "get",
      headers: {},
    },
  );
}
