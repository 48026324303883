/// <reference types="vite-plugin-svgr/client" />
import styled from "styled-components";
import { TurbineLoss, TurbineType } from "../../../types/turbines";
import { Input, InputDimensioned, TextArea } from "../../General/Input";
import { Detail, TextIcon } from "../../../styles/typography";
import Trashcan from "@icons/24/Bin.svg?react";
import AddIcon from "@icons/24/Add.svg?react";
import { Column, Row } from "../../General/Layout";
import { SkeletonBlock } from "../../Loading/Skeleton";
import { Label as _Label } from "../../General/Form";
import {
  validCurves,
  formatCurveToText,
  textToCurve,
  formatInput,
} from "../../SettingsV2/FeatureSettings/Data/utils";
import {
  ErrorBoundaryWarningTriangle,
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "../../ErrorBoundaries/ErrorBoundaryLocal";
import {
  ContentWrapper,
  SettingsFooter,
  SettingsFooterContainerWrapper,
  SettingsHeader,
} from "../../SettingsV2/Shared/styles";
import { TurbineTypeUsageNode } from "../SettingsUsage/TurbineTypeUsage";
import Button from "../../General/Button";
import {
  currentVersionSelector,
  inReadOnlyModeSelector,
} from "../../../state/project";
import React, { useState, useEffect, useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { isApproxBetween } from "../../../utils/geometry";
import { allSimpleTurbineTypesSelector } from "../../../state/turbines";
import { unsavedSettingsState } from "../../SettingsV2/Shared/state";
import { isDefined } from "../../../utils/predicates";
import { IAVoltageType } from "../../../services/cableTypeService";
import Checkbox from "../../General/Checkbox";
import { dedup } from "../../../utils/utils";
import { spaceLarge } from "../../../styles/space";
import { spaceTiny } from "../../../styles/space";
import { rotorTowerSizing } from "../../../functions/rotorTowerSizing";
import { toastMessagesAtom } from "../../../state/toast";
import useEnterToSave from "../useEnterToSave";
import {
  getAdvancedOrgTurbine,
  getAdvancedProjectTurbine,
} from "services/turbineAPIService";
import { useLocation, useNavigate } from "react-router-dom";
import { selectedOrgTabState } from "components/Organisation/state";
import { useTypedPath } from "state/pathParams";
import { useDuplicateTurbineToProject } from "components/SettingsV2/FeatureSettings/Data/useDuplicateToProject";
import { libraryTabState } from "components/Organisation/Library/state";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import ComponentLastChanged from "../SettingsUsage/ComponentLastChanged";

// Lazy load to prevent plotly being in main.js
const TurbineGraph = React.lazy(() => import("../TurbineGraph"));
const MAX_LOSS = 0.2;
const MAX_NUMBER_OF_LOSSES = 3;

export const ColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  height: 100%;
  width: 100%;
`;

const Label = styled(_Label)`
  flex: 1;
`;

export const validateRnaMass = ({
  rnaMass,
  diameter,
}: {
  rnaMass: number;
  diameter: number;
}) => {
  const { rnaMass: estimation } = rotorTowerSizing(diameter);
  const [from, to] = [estimation * 0.5, estimation * 1.5];

  return isApproxBetween(from, rnaMass, to);
};

export const TurbineSettingAdvancedProjectAdmin = ErrorBoundaryWrapper(
  ({
    turbineId,
    onSave,
    nodeId,
  }: {
    turbineId: string;
    onSave?: (turbine: TurbineType) => Promise<any>;
    nodeId: string;
  }) => {
    const version = useRecoilValue(currentVersionSelector);
    const simpleTurbineTypes = useRecoilValue(allSimpleTurbineTypesSelector);

    const [isLoadingTurbine, setIsLoadingTurbine] = useState<boolean>(false);

    const [turbine, setTurbine] = useState<TurbineType>();

    useEffect(() => {
      if (!nodeId) return;
      let isSubmitted = false;
      setIsLoadingTurbine(true);
      getAdvancedProjectTurbine(nodeId, turbineId, version)
        .then((res) => {
          if (isSubmitted) return;
          setTurbine(res);
        })
        .finally(() => {
          if (!isSubmitted) setIsLoadingTurbine(false);
        });
      return () => {
        isSubmitted = true;
      };
    }, [version, turbineId, nodeId, simpleTurbineTypes]);

    return (
      <TurbineSettingCustomAdmin
        turbine={turbine}
        isLoading={isLoadingTurbine}
        onSave={onSave}
        nodeId={nodeId}
        readOnly={false}
      />
    );
  },
  () => {
    return (
      <>
        <FatalErrorBoundaryWrapper />
        <ErrorBoundaryWarningTriangle />
      </>
    );
  },
  ScreamOnError,
);

export const TurbineSettingAdvancedLibraryManage = ErrorBoundaryWrapper(
  ({ turbineId, nodeId }: { turbineId: string; nodeId: string }) => {
    const { organisationId } = useTypedPath("organisationId");
    const version = useRecoilValue(currentVersionSelector);
    const navigate = useNavigate();
    const location = useLocation();
    const setOrgtab = useSetRecoilState(selectedOrgTabState);
    const simpleTurbineTypes = useRecoilValue(allSimpleTurbineTypesSelector);
    const setSelectedTab = useSetRecoilState(
      libraryTabState({ organisationId }),
    );

    const { duplicateToProject, isLoading: isLoadingDuplicate } =
      useDuplicateTurbineToProject();

    const [isLoadingTurbine, setIsLoadingTurbine] = useState<boolean>(false);

    const [turbine, setTurbine] = useState<TurbineType>();

    useEffect(() => {
      if (!nodeId) return;
      setIsLoadingTurbine(true);
      getAdvancedOrgTurbine(nodeId, turbineId, version)
        .then((res) => {
          setTurbine(res);
        })
        .finally(() => setIsLoadingTurbine(false));
    }, [version, turbineId, nodeId, simpleTurbineTypes]);

    if (!turbine) return <></>;

    return (
      <>
        <SettingsHeader>
          <Button
            buttonType="text"
            text="Edit turbine in Library"
            onClick={() => {
              setOrgtab("Library");
              setSelectedTab("turbine");
              navigate(`/organisation/${organisationId}${location.search}`);
            }}
            style={{ marginLeft: "auto" }}
          />
          <Button
            text="Duplicate as project turbine"
            onClick={() => duplicateToProject(turbine.id)}
            disabled={isLoadingDuplicate}
          />
        </SettingsHeader>
        <TurbineSettingCustomAdmin
          turbine={turbine}
          isLoading={isLoadingTurbine}
          nodeId={nodeId}
          readOnly={true}
        />
      </>
    );
  },
  () => {
    return (
      <>
        <FatalErrorBoundaryWrapper />
        <ErrorBoundaryWarningTriangle />
      </>
    );
  },
  ScreamOnError,
);

export const TurbineSettingCustomAdmin = ErrorBoundaryWrapper(
  ({
    turbine,
    onSave,
    isLoading,
    nodeId,
    readOnly,
  }: {
    turbine: TurbineType | undefined;
    onSave?: (turbine: TurbineType) => Promise<any>;
    isLoading: boolean;
    nodeId: string;
    readOnly: boolean;
  }) => {
    const isReadOnly = useRecoilValue(inReadOnlyModeSelector) || readOnly;

    const [isValid, setIsValid] = useState<boolean>(true);
    const [values, setValues] = useState<string>("");
    const [hasChanged, setHasChanged] = useState<boolean>(false);

    const [tempTurbine, setTempTurbine] = useState<TurbineType>();

    useEffect(() => turbine && setTempTurbine(turbine), [turbine]);

    const setUnsavedSettings = useSetRecoilState(unsavedSettingsState);
    const setToastMessages = useSetRecoilState(toastMessagesAtom);
    const allChangesSaved = isReadOnly || !hasChanged || !isValid;

    useEffect(() => {
      setUnsavedSettings(!isLoading && !allChangesSaved);
    }, [allChangesSaved, isLoading, setUnsavedSettings]);

    const fractionToPercentage = (fraction: number) => fraction * 100;
    const percentageToFraction = (percentage: number) => percentage / 100;

    function removeLoss(index: number) {
      if (!tempTurbine?.losses) return;
      const newLosses = [
        ...tempTurbine.losses.slice(0, index),
        ...tempTurbine.losses.slice(index + 1),
      ];
      setTempTurbine({ ...tempTurbine, losses: newLosses });
      setHasChanged(true);
    }
    function updateLoss(value: number, index: number) {
      if (!tempTurbine?.losses) return;
      const newLosses = tempTurbine.losses.map((loss, i) =>
        i === index ? { ...loss, value } : loss,
      );
      setTempTurbine({ ...tempTurbine, losses: newLosses });
      setHasChanged(true);
    }
    function updateName(name: string, index: number) {
      if (!tempTurbine?.losses) return;
      const newLosses = tempTurbine.losses.map((loss, i) =>
        i === index ? { ...loss, name } : loss,
      );
      setTempTurbine({ ...tempTurbine, losses: newLosses });
      setHasChanged(true);
    }

    useEffect(() => {
      const isValidCurves = tempTurbine ? validCurves(tempTurbine) : false;
      setIsValid(isValidCurves);
    }, [tempTurbine]);

    const _onSave = useCallback(async () => {
      if (onSave) {
        await onSave(tempTurbine!);
        setHasChanged(false);
      }
    }, [tempTurbine, onSave]);

    useEnterToSave(
      _onSave,
      !allChangesSaved && !isLoading && Boolean(tempTurbine),
    );

    useEffect(() => {
      if (turbine) {
        setValues(
          formatCurveToText(
            turbine.windSpeed,
            turbine.power,
            turbine.thrustCoefficient,
          ),
        );
      }
    }, [setValues, turbine]);

    const onVoltageChange = useCallback(
      (ev: React.ChangeEvent<HTMLInputElement>) => {
        setTempTurbine((turbine) => {
          if (!turbine) {
            return turbine;
          }
          const currentVoltage = turbine.voltage ?? [];
          const currValue = Number(ev.target.value) as IAVoltageType;

          if (currentVoltage.includes(currValue)) {
            return {
              ...turbine,
              voltage: currentVoltage.filter((v) => v !== currValue),
            };
          }
          return {
            ...turbine,
            voltage: dedup([...currentVoltage, currValue]),
          };
        });
        setHasChanged(true);
      },
      [],
    );

    if (!tempTurbine || !turbine)
      return (
        <ContentWrapper
          style={{
            maxHeight: "calc(100% - 7.3rem)",
            overflowY: "auto",
            boxSizing: "border-box",
          }}
        >
          <ColumnContent>
            <h4 style={{ margin: 0 }}>Loading data...</h4>
            <SkeletonBlock style={{ width: "100%", height: "40rem" }} />
          </ColumnContent>
        </ContentWrapper>
      );

    return (
      <>
        {!allChangesSaved && (
          <SettingsHeader>
            <Button
              disabled={allChangesSaved || isLoading}
              buttonType="secondary"
              text="Cancel"
              onClick={() => {
                setValues(
                  formatCurveToText(
                    turbine.windSpeed,
                    turbine.power,
                    turbine.thrustCoefficient,
                  ),
                );
                setTempTurbine(turbine);
                setHasChanged(false);
              }}
              style={{ marginLeft: "auto" }}
            />
            <Button
              disabled={allChangesSaved || isLoading}
              text="Save changes"
              onClick={() => {
                setToastMessages((tm) => [
                  ...tm,
                  { text: "Saving...", timeout: 2000 },
                ]);
                _onSave();
              }}
            />
          </SettingsHeader>
        )}
        <ContentWrapper
          style={{
            maxHeight: "calc(100% - 7.3rem)",
            overflowY: "auto",
            boxSizing: "border-box",
          }}
        >
          <ColumnContent>
            <Column>
              <Row>
                <Label>
                  <p>Name</p>
                  <Input
                    type="string"
                    disabled={isReadOnly}
                    value={tempTurbine.name}
                    onChange={(e) => {
                      setTempTurbine({
                        ...tempTurbine,
                        name: e.target.value,
                      });
                      setHasChanged(true);
                    }}
                  />
                </Label>
                <Label>
                  <p>Cost</p>
                  <InputDimensioned
                    disabled={isReadOnly}
                    value={tempTurbine.cost}
                    unit={tempTurbine.costUnit}
                    step={0.1}
                    validate={(value) => isDefined(value) && value >= 0}
                    onChange={(value) => {
                      setTempTurbine({
                        ...tempTurbine,
                        cost: value,
                      });
                      setHasChanged(true);
                    }}
                  />
                </Label>
              </Row>
              <Row>
                <Label>
                  <p>Diameter</p>
                  {tempTurbine && (
                    <InputDimensioned
                      type="number"
                      disabled={isReadOnly}
                      value={tempTurbine.diameter}
                      unit={"m"}
                      validate={(v) => isApproxBetween(50, v, 350)}
                      onChange={(diameter) => {
                        setTempTurbine({
                          ...tempTurbine,
                          diameter,
                        });
                        setHasChanged(true);
                      }}
                    />
                  )}
                </Label>

                <Label>
                  <p>Hub height</p>
                  <InputDimensioned
                    type="number"
                    disabled={isReadOnly}
                    value={tempTurbine?.hubHeight}
                    validate={(v) => isApproxBetween(50, v, 300)}
                    unit={"m"}
                    onChange={(hubHeight) => {
                      setTempTurbine({
                        ...tempTurbine,
                        hubHeight,
                      });
                      setHasChanged(true);
                    }}
                  />
                </Label>

                <Label>
                  <p>RNA weight</p>
                  <InputDimensioned
                    type="number"
                    disabled={isReadOnly}
                    value={Math.round(tempTurbine.rnaMass / 1000)}
                    validate={(rnaMassTons) =>
                      validateRnaMass({
                        rnaMass: rnaMassTons * 1000,
                        diameter: tempTurbine.diameter,
                      })
                    }
                    validationMessage={`The value differs from our estimation by more than 50%`}
                    step={1}
                    unit={"t"}
                    onChange={(rnaMassTons) => {
                      setTempTurbine({
                        ...tempTurbine,
                        rnaMass: rnaMassTons * 1000,
                      });
                      setHasChanged(true);
                    }}
                  />
                </Label>

                <Label>
                  <p>Reference air density</p>
                  <InputDimensioned
                    type="number"
                    disabled={isReadOnly}
                    value={tempTurbine.referenceAirDensity}
                    validate={(v) => isApproxBetween(0.7, v, 1.5)}
                    unit={"kg/m3"}
                    step={0.001}
                    onChange={(referenceAirDensity) => {
                      setTempTurbine({
                        ...tempTurbine,
                        referenceAirDensity,
                      });
                      setHasChanged(true);
                    }}
                  />
                </Label>
                <Label>
                  <p>Voltage</p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: spaceLarge,
                    }}
                  >
                    <Checkbox
                      value={IAVoltageType.kV66}
                      label={String(IAVoltageType.kV66).concat("kV")}
                      labelPlacement="after"
                      checked={tempTurbine.voltage.includes(IAVoltageType.kV66)}
                      disabled={
                        (tempTurbine.voltage.length === 1 &&
                          tempTurbine.voltage.includes(IAVoltageType.kV66)) ||
                        isReadOnly
                      }
                      onChange={onVoltageChange}
                    />
                    <Checkbox
                      value={IAVoltageType.kV132}
                      label={String(IAVoltageType.kV132).concat("kV")}
                      labelPlacement="after"
                      checked={tempTurbine.voltage.includes(
                        IAVoltageType.kV132,
                      )}
                      disabled={
                        (tempTurbine.voltage.length === 1 &&
                          tempTurbine.voltage.includes(IAVoltageType.kV132)) ||
                        isReadOnly
                      }
                      onChange={onVoltageChange}
                    />
                  </div>
                </Label>
              </Row>
              <Column>
                <p>Turbine-specific losses</p>
                {(!tempTurbine.losses || tempTurbine.losses.length === 0) && (
                  <Row>
                    <Input
                      disabled={true}
                      type="text"
                      value={"Unnamed loss"}
                      onChange={() => {}}
                      style={{ width: "16rem" }}
                    />
                    <InputDimensioned
                      disabled={true}
                      value={0}
                      unit="%"
                      type="number"
                      onChange={() => {}}
                      style={{ width: "7rem" }}
                    />
                  </Row>
                )}
                {tempTurbine?.losses &&
                  tempTurbine.losses.map((loss, i) => (
                    <Row key={i}>
                      <Input
                        disabled={isReadOnly}
                        type="text"
                        value={loss.name}
                        onChange={(e) => updateName(e.target.value, i)}
                        style={{ width: "16rem" }}
                      />

                      <InputDimensioned
                        disabled={isReadOnly}
                        value={fractionToPercentage(loss.value)}
                        validate={(n) => 0 <= n && n <= 100 * MAX_LOSS}
                        validationMessage={`Needs to be within 0 - ${
                          100 * MAX_LOSS
                        }%`}
                        step={0.5}
                        unit="%"
                        type="number"
                        onChange={(value) => {
                          updateLoss(percentageToFraction(value), i);
                        }}
                        style={{ width: "7rem" }}
                      />
                      {!isReadOnly && (
                        <TextIcon
                          onClick={() => removeLoss(i)}
                          scale={1.2}
                          style={{ padding: spaceTiny, alignSelf: "center" }}
                        >
                          <Trashcan />
                        </TextIcon>
                      )}
                    </Row>
                  ))}
                <Button
                  disabled={
                    isReadOnly ||
                    (tempTurbine?.losses?.length ?? 0) >= MAX_NUMBER_OF_LOSSES
                  }
                  buttonType="text"
                  text="Add loss"
                  icon={<AddIcon />}
                  style={{ marginLeft: "15rem" }}
                  onClick={() => {
                    const newLoss: TurbineLoss = {
                      name: "Unnamed loss",
                      value: 0,
                    };
                    setTempTurbine({
                      ...tempTurbine,
                      losses: [...(tempTurbine?.losses ?? []), newLoss],
                    });
                    setHasChanged(true);
                  }}
                />
              </Column>

              <Label>
                <p>Curves</p>
                <Row style={{ gap: "1.8rem", paddingRight: "3rem" }}>
                  <Column>
                    <Row
                      style={{
                        flex: 0,
                        justifyContent: "space-between",
                        marginRight: "1rem",
                      }}
                    >
                      <Detail>Speed [m/s]</Detail>
                      <Detail>Power [kW]</Detail>
                      <Detail>Thrust [-]</Detail>
                    </Row>
                    <TextArea
                      cols={25}
                      rows={15}
                      style={{ resize: "vertical" }}
                      disabled={isReadOnly}
                      placeholder={"Speed Power Thrust\n[m/s] [kW] [-]"}
                      value={values}
                      onChange={(e) => {
                        const { speed, power, thrust } = textToCurve(
                          e.target.value,
                        );
                        const turbine = {
                          ...tempTurbine,
                          power,
                          windSpeed: speed,
                          thrustCoefficient: thrust,
                        } as TurbineType;
                        setValues(formatInput(e.target.value));
                        setTempTurbine(turbine);
                        setHasChanged(true);
                      }}
                    />
                  </Column>
                  {tempTurbine && (
                    <React.Suspense fallback={null}>
                      <TurbineGraph turbine={tempTurbine} />
                    </React.Suspense>
                  )}
                </Row>
              </Label>
              {!isValid && (
                <SimpleAlert text={"Invalid input curves"} type={"error"} />
              )}
              <Row>
                <Label>
                  <p>Description</p>
                  <TextArea
                    rows={5}
                    style={{ resize: "vertical" }}
                    disabled={isReadOnly}
                    placeholder={"Add a description"}
                    value={tempTurbine.note ?? ""}
                    onChange={(e) => {
                      setTempTurbine({ ...tempTurbine, note: e.target.value });
                      setHasChanged(true);
                    }}
                  />
                </Label>
              </Row>
            </Column>
          </ColumnContent>
        </ContentWrapper>
        <SettingsFooter>
          <SettingsFooterContainerWrapper>
            <TurbineTypeUsageNode turbineTypeId={turbine.id} nodeId={nodeId} />
            <ComponentLastChanged component={turbine} />
          </SettingsFooterContainerWrapper>
        </SettingsFooter>
      </>
    );
  },
  () => {
    return (
      <>
        <FatalErrorBoundaryWrapper />
        <ErrorBoundaryWarningTriangle />
      </>
    );
  },
  ScreamOnError,
);

export default TurbineSettingCustomAdmin;
