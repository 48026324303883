import { useEffect } from "react";
import { SetterOrUpdater, useRecoilValue, useSetRecoilState } from "recoil";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import { z } from "zod";
import { projectFeaturesSelector } from "./../ProjectElements/state";
import { currentSelectedProjectFeatures } from "./../../state/selection";
import { mapControlsAtom, mapInteractionSelector } from "../../state/map";
import { ToastMessage, toastMessagesAtom } from "../../state/toast";
import useCloneFeaturesWithDefaultCanvasSource, {
  CloneFeaturesReturnType,
} from "../../hooks/useCloneFeaturesWithDefaultCanvasSource";
import {
  _OtherFeature,
  ProjectFeature,
  stripFeatureTypeSpecificFields,
  stripFeatureTypeSpecificFieldsAndConvertToOther,
} from "../../types/feature";
import {
  ExternalSelectionItem,
  currentExternalLayerSelection,
} from "../../state/externalLayerSelection";
import {
  findFeatureChildren,
  findFeatureConnections,
} from "../../state/projectLayers";
import { GeoTiffUserUploadedImageType } from "../../services/types";
import { dedup } from "../../utils/utils";
import { cleanGeojsonFeatures } from "../UploadFile/fileUtils";
import { sendWarning } from "../../utils/sentry";
import { _FeatureParser } from "../../types/feature";
import { isPark, isFeature, isDefined } from "utils/predicates";

export const featureSupportsCopy = (
  feature: ProjectFeature | MapboxGeoJSONFeature,
) => feature.properties?.type !== GeoTiffUserUploadedImageType;

const vindFeaturesParser = z
  .object({
    copiedFromVind: z.literal(true),
    features: z
      .unknown()
      .array()
      .transform((arr) =>
        arr.filter((f) => _FeatureParser.safeParse(f).success),
      ),
  })
  .passthrough();

export const getClipboardFeaturesIfContainingVindFeatures = async () => {
  try {
    const clipboardData = await navigator.clipboard.readText();
    const json = JSON.parse(clipboardData);

    const parse = vindFeaturesParser.safeParse(json);
    if (!parse.success) {
      return;
    }

    return parse.data;
  } catch (e) {
    return;
  }
};

const hasConnectionsThatWereNotSelected = (
  selected: ProjectFeature,
  allFeatures: ProjectFeature[],
  selectedFeatures: ProjectFeature[],
) => {
  const featureConnections = findFeatureConnections(allFeatures, selected);

  return featureConnections.some(
    (parent) => !selectedFeatures.find((feature) => feature.id === parent.id),
  );
};

export const copy = async (
  currentSelectedFeatures: (ProjectFeature | MapboxGeoJSONFeature)[],
  externalLayerSelection: ExternalSelectionItem[],
  allProjectFeatures: ProjectFeature[],
  setToastMessagesAtom: SetterOrUpdater<ToastMessage[]>,
) => {
  const toastMessages: ToastMessage[] = [];
  const features = currentSelectedFeatures
    .concat(
      externalLayerSelection
        .map((f) => {
          const parsed = _OtherFeature.safeParse({
            ...f,
            properties: {
              ...f.properties,
              type: undefined,
              parentIds: undefined,
            },
            id: String(f.id),
          });
          return parsed.success ? parsed.data : undefined;
        })
        .filter(isDefined)
        .map((parsed) => {
          return {
            ...parsed,
            properties: stripFeatureTypeSpecificFields(parsed),
          };
        }),
    )
    .filter(isFeature);

  const children = features.flatMap((selected) =>
    isPark(selected)
      ? findFeatureChildren(allProjectFeatures, String(selected.id))
      : [],
  );
  const allFeaturesWithTheirChildren = dedup(
    [...features, ...children],
    (f) => f.id,
  );

  let triedToCopyFeaturesWithoutTheirConnections = 0;
  const featuresToCopy = allFeaturesWithTheirChildren
    .filter(featureSupportsCopy)
    .map((feature) => {
      const hasConnections = hasConnectionsThatWereNotSelected(
        feature,
        allProjectFeatures,
        allFeaturesWithTheirChildren,
      );

      if (hasConnections) {
        triedToCopyFeaturesWithoutTheirConnections++;
        return stripFeatureTypeSpecificFieldsAndConvertToOther(feature);
      }
      return feature;
    });

  if (featuresToCopy.length < allFeaturesWithTheirChildren.length) {
    toastMessages.push({
      text: "Image copying is not supported",
      timeout: 2000,
    });
  }

  if (triedToCopyFeaturesWithoutTheirConnections > 0) {
    toastMessages.push({
      text: `${triedToCopyFeaturesWithoutTheirConnections} copied feature${triedToCopyFeaturesWithoutTheirConnections !== 1 ? "s were" : " was"} converted to type "Other". Cable and mooring must be copied with their connected features.`,
      timeout: 8_000,
      type: "info",
    });
  }

  if (featuresToCopy.length > 0) {
    await window.navigator.clipboard.writeText(
      JSON.stringify({ features: featuresToCopy, copiedFromVind: true }),
    );
    toastMessages.push({ text: "Copied", timeout: 2000, type: "success" });
  }
  setToastMessagesAtom((tm) => [...tm, ...toastMessages]);
};

export const paste = async (
  cloneFeatures: CloneFeaturesReturnType,
  setExternalLayerSelection: (
    features: mapboxgl.MapboxGeoJSONFeature[],
  ) => void,
) => {
  try {
    const json = await getClipboardFeaturesIfContainingVindFeatures();
    const parse = vindFeaturesParser.safeParse(json);
    if (!parse.success) {
      return;
    }
    const cleanedJson = {
      ...parse.data,
      features: cleanGeojsonFeatures(parse.data.features).cleaned,
    };

    cloneFeatures(cleanedJson.features, "Pasted");
    setExternalLayerSelection([]);
  } catch (err) {
    sendWarning("Something went wrong when trying to paste features", {
      err,
    });
  }
};

export const pasteFromText = (
  text: string,
  cloneFeatures: CloneFeaturesReturnType,
) => {
  try {
    const json = JSON.parse(text);
    const parse = vindFeaturesParser.safeParse(json);
    if (!parse.success) {
      return;
    }

    const cleanedJson = {
      ...parse.data,
      features: cleanGeojsonFeatures(parse.data.features).cleaned,
    };

    cloneFeatures(cleanedJson.features, "Pasted", true);
  } catch (err) {
    if (!(err instanceof SyntaxError)) {
      sendWarning("Something went wrong when trying to paste features", {
        text,
        err,
      });
    }
  }
};

const CopyPasteSelectedFeature = () => {
  const mapControls = useRecoilValue(mapControlsAtom);
  const mapInteraction = useRecoilValue(mapInteractionSelector);
  const canvasLayers = useRecoilValue(projectFeaturesSelector);
  const cloneFeatures = useCloneFeaturesWithDefaultCanvasSource();

  const setToastMessagesAtom = useSetRecoilState(toastMessagesAtom);
  const currentSelectedFeatures = useRecoilValue(
    currentSelectedProjectFeatures,
  );
  const externalLayerSelection = useRecoilValue(currentExternalLayerSelection);

  useEffect(() => {
    if (
      !mapInteraction.copy ||
      (currentSelectedFeatures.length === 0 &&
        externalLayerSelection.length === 0)
    ) {
      return;
    }

    const onCopy = () => {
      if (window.getSelection()?.toString() !== "") {
        return;
      }

      return copy(
        currentSelectedFeatures,
        externalLayerSelection,
        canvasLayers,
        setToastMessagesAtom,
      );
    };
    document.body.addEventListener("copy", onCopy);
    return () => document.body.removeEventListener("copy", onCopy);
  }, [
    mapControls,
    currentSelectedFeatures,
    externalLayerSelection,
    setToastMessagesAtom,
    mapInteraction,
    canvasLayers,
  ]);

  useEffect(() => {
    if (!mapInteraction.paste) {
      return;
    }

    const onPaste = (e: Event) => {
      if (!(e instanceof ClipboardEvent)) {
        return;
      }

      if (
        e.target instanceof HTMLTextAreaElement ||
        e.target instanceof HTMLInputElement
      ) {
        return true;
      }
      const text = e.clipboardData?.getData("text/plain");
      if (!text) {
        return;
      }

      return pasteFromText(text, cloneFeatures);
    };
    window.addEventListener("paste", onPaste);
    return () => window.removeEventListener("paste", onPaste);
  }, [mapInteraction, cloneFeatures]);

  return null;
};

export default CopyPasteSelectedFeature;
