/// <reference types="vite-plugin-svgr/client" />
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import {
  organisationIdSelector,
  projectIdSelector,
  useTypedPath,
} from "../../state/pathParams";
import {
  publicMetadataAtomFamily,
  publicProjectSettingsAtomFamily,
} from "../../state/projectAPI";
import { toastMessagesAtom } from "../../state/toast";
import Toggle, { ToggleSize } from "../General/Toggle";
import { Detail, IconREMSize, typography } from "../../styles/typography";
import Hyperlink from "@icons/24/Hyperlink.svg?react";
import Add from "@icons/24/Add.svg?react";
import Remove from "@icons/24/Remove.svg?react";
import ArrowDown from "@icons/24/ArrowDown.svg?react";
import ArrowUp from "@icons/24/ArrowUp.svg?react";
import ResetIcon from "@icons/14/Reset.svg?react";
import Button from "../General/Button";
import { mapRefAtom } from "../../state/map";
import { SkeletonBlock, SkeletonText } from "../Loading/Skeleton";
import { getAllLayerCollections } from "../LayerList/Collections/state";
import {
  LayerCollection,
  LayerCollectionLayer,
} from "../LayerList/LayerSettings/types";
import { isDefined } from "../../utils/predicates";
import { Column, Row } from "../General/Layout";
import { Input, TextArea } from "../General/Input";
import { Label } from "../General/Form";
import { BranchMeta, PublicProjectMeta } from "../../types/api";
import {
  ErrorBoundaryWarningTriangle,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "../ErrorBoundaries/ErrorBoundaryLocal";
import { ContentWrapper } from "../SettingsV2/Shared/styles";
import {
  customerProjectLegacySelectorFamily,
  projectBranchesAtomFamily,
} from "../../state/timeline";
import { topLevelNodeFromOrgIdAndNodeIdSelectorFamily } from "components/Projects/useOrganisationFolderCrud";
import { adminAccessProjectSelector } from "state/user";
import { inReadOnlyModeSelector } from "state/project";
import useBooleanState from "hooks/useBooleanState";
import { EditableText } from "components/General/EditableText";
import Tooltip from "components/General/Tooltip";
import usePublicModeSettingsCrud, {
  analysisVisibleKey,
  projectElementsVisibleKey,
  publicCollectionsKey,
  publicCollectionsRenamedLayers,
  startZoomAreaKey,
} from "./usePublicModeSettingsCrud";
import Spinner from "@icons/spinner/Spinner";

export const ShareProjectModalType = "ShareProjectModal";
export const PUBLIC_MODE_URL = "https://app.public.vind.ai";

const WrapperLarge = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;

const VisibleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.8rem;
  width: 30rem;
`;

const SubVisibleRow = styled(VisibleRow)``;

const RowWithSubRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const MainRowText = styled.div`
  ${typography.semiBold}
`;

const SubRowText = styled.div`
  margin-left: 1rem;
`;

const OpenCollectionLayerListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 30rem;
  cursor: pointer;
`;

const LayerListWrapper = styled.div`
  width: 30rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const EditablePublicLayerNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
`;

const TitleWithLoader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const LoaderWrapper = styled.div<{ loading: boolean }>`
  transition: opacity 0.25s;
  opacity: ${(p) => (p.loading ? "1" : "0")};
`;

const PublicModeLink = ({
  branchId,
  disabled,
}: {
  branchId: string;
  disabled?: boolean;
}) => {
  const setToastMessagesAtom = useSetRecoilState(toastMessagesAtom);
  const organisationId = useRecoilValue(organisationIdSelector);
  const projectId = useRecoilValue(projectIdSelector);
  const topLevelNode = useRecoilValue(
    topLevelNodeFromOrgIdAndNodeIdSelectorFamily({
      organisationId,
      nodeId: projectId,
    }),
  );
  const projectNode = useRecoilValue(
    customerProjectLegacySelectorFamily({ projectId }),
  );

  const shareUrl = useMemo(
    () =>
      `${PUBLIC_MODE_URL}/${topLevelNode?.legacy_id}/${projectNode?.legacy_id}/${branchId}`,
    [branchId, projectNode?.legacy_id, topLevelNode?.legacy_id],
  );

  if (!topLevelNode || !projectNode) return <></>;

  return (
    <Button
      icon={<Hyperlink />}
      size="small"
      disabled={disabled}
      buttonType="secondary"
      onClick={() => {
        navigator.clipboard.writeText(shareUrl);
        setToastMessagesAtom((tm) => [
          ...tm,
          { text: "Copied", timeout: 2000 },
        ]);
      }}
    />
  );
};

const PublicModePerBranch = ({
  branch,
  nodeId,
}: {
  branch: BranchMeta;
  nodeId: string;
}) => {
  const publicProjectMetadata = useRecoilValue(
    publicMetadataAtomFamily({ nodeId, branchId: branch.id }),
  );
  const { setProjectAsPublic } = usePublicModeSettingsCrud();
  const adminAccessProject = useRecoilValue(adminAccessProjectSelector);
  const inReadOnlyMode = useRecoilValue(inReadOnlyModeSelector);

  return (
    <Row
      style={{
        alignItems: "center",
        width: "30rem",
        justifyContent: "space-between",
      }}
    >
      <p style={{ width: "18rem", fontWeight: 600 }}>{branch.title}</p>
      <Row style={{ gap: "1.2rem" }}>
        <PublicModeLink
          branchId={branch.id}
          disabled={!publicProjectMetadata.is_public}
        />
        <Toggle
          checked={publicProjectMetadata.is_public}
          size={ToggleSize.SMALL}
          onChange={() =>
            setProjectAsPublic(
              nodeId,
              branch.id,
              !publicProjectMetadata.is_public,
            )
          }
          disabled={!adminAccessProject || inReadOnlyMode}
        />
      </Row>
    </Row>
  );
};

const EditablePublicLayerName = ({
  layer,
  publicProjectSettings,
  nodeId,
}: {
  layer: LayerCollectionLayer;
  publicProjectSettings: PublicProjectMeta;
  nodeId: string;
}) => {
  const name = useMemo(
    () =>
      (publicProjectSettings?.public_collections_renamed_layers ?? {})[
        layer.id
      ] ?? layer.name,
    [layer, publicProjectSettings.public_collections_renamed_layers],
  );
  const [layerName, setLayerName] = useState(name);
  useEffect(() => {
    setLayerName(name);
  }, [name, setLayerName]);
  const { setPublicExternalLayerName, resetPublicExternalLayerName } =
    usePublicModeSettingsCrud();

  return (
    <EditablePublicLayerNameWrapper>
      <EditableText
        value={layerName}
        onChange={(e) => {
          setLayerName(e.target.value);
        }}
        onEnter={async () => {
          await setPublicExternalLayerName(nodeId, layer.id, layerName);
        }}
        onCancel={() => {
          setLayerName(layer.name);
        }}
      />
      {(publicProjectSettings?.public_collections_renamed_layers ?? {})[
        layer.id
      ] && (
        <Tooltip text={"Reset name"}>
          <IconREMSize
            width={1.4}
            height={1.4}
            style={{ cursor: "pointer" }}
            onClick={async () => {
              const newRenameLayers = {
                ...(publicProjectSettings[publicCollectionsRenamedLayers] ??
                  {}),
              };
              delete newRenameLayers[layer.id];
              await resetPublicExternalLayerName(nodeId, layer.id);
            }}
          >
            <ResetIcon />
          </IconREMSize>
        </Tooltip>
      )}
    </EditablePublicLayerNameWrapper>
  );
};

const PublicCollectionLayer = ({
  collection,
  setCollectionAvailable,
  isLoading,
  publicProjectSettings,
  nodeId,
}: {
  collection: LayerCollection;
  isLoading: boolean;
  setCollectionAvailable: (collectionId: string, available: boolean) => void;
  publicProjectSettings: PublicProjectMeta;
  nodeId: string;
}) => {
  const { updatePublicDefaultToggledCollections } = usePublicModeSettingsCrud();
  const toggledByDefaultIds: string[] = useMemo(
    () => publicProjectSettings.public_collections_toggled_by_default ?? [],
    [publicProjectSettings.public_collections_toggled_by_default],
  );

  const setCollectionToggledByDefault = useCallback(
    (collectionId: string, toggled: boolean) => {
      const newListOfCollectionIds = toggled
        ? [...toggledByDefaultIds, collectionId]
        : toggledByDefaultIds.filter((colId) => colId !== collectionId);

      updatePublicDefaultToggledCollections(nodeId, newListOfCollectionIds);
    },
    [nodeId, updatePublicDefaultToggledCollections, toggledByDefaultIds],
  );

  const [showCollectionLayers, toggleShowCollectionLayers] =
    useBooleanState(false);

  return (
    <RowWithSubRow key={collection.id}>
      <VisibleRow onClick={() => {}}>
        <MainRowText>{collection.name}</MainRowText>
        <Button
          disabled={isLoading}
          onClick={() => setCollectionAvailable(collection.id, false)}
          icon={
            isLoading ? (
              <SkeletonBlock style={{ width: "2rem", height: "1rem" }} />
            ) : (
              <Remove />
            )
          }
          text={"Make private"}
          buttonType={"secondary"}
          size="small"
        />
      </VisibleRow>
      <SubVisibleRow key={collection.id} onClick={() => {}}>
        <SubRowText>Toggle layers on by default</SubRowText>
        <Toggle
          checked={toggledByDefaultIds.includes(collection.id)}
          size={ToggleSize.SMALL}
          onChange={() => {
            setCollectionToggledByDefault(
              collection.id,
              !toggledByDefaultIds.includes(collection.id),
            );
          }}
        />
      </SubVisibleRow>
      {showCollectionLayers && (
        <LayerListWrapper>
          {collection.layers.map((l) => (
            <EditablePublicLayerName
              key={l.id}
              nodeId={nodeId}
              layer={l}
              publicProjectSettings={publicProjectSettings}
            />
          ))}
        </LayerListWrapper>
      )}
      <OpenCollectionLayerListWrapper
        onClick={() => toggleShowCollectionLayers()}
      >
        <Button
          icon={
            <IconREMSize width={1.4} height={1.4}>
              {showCollectionLayers ? <ArrowUp /> : <ArrowDown />}
            </IconREMSize>
          }
          buttonType={"text"}
        />
      </OpenCollectionLayerListWrapper>
    </RowWithSubRow>
  );
};

const PublicModeSettings = ({
  publicProjectSettings,
  nodeId,
}: {
  publicProjectSettings: PublicProjectMeta;
  nodeId: string;
}) => {
  const map = useRecoilValue(mapRefAtom);
  const { projectId } = useTypedPath("projectId");
  const adminAccessProject = useRecoilValue(adminAccessProjectSelector);
  const inReadOnlyMode = useRecoilValue(inReadOnlyModeSelector);
  const collections = useRecoilValue(getAllLayerCollections({ projectId }));
  const {
    updatePublicCollectionSettings,
    updatePublicStartZoomSettings,
    updatePublicGreeting,
    togglePublicSetting,
    loading,
  } = usePublicModeSettingsCrud();
  const [greetingTitle, setGreetingTitle] = useState<string>(
    publicProjectSettings.greeting_title ?? "",
  );
  const [greetingText, setGreetingText] = useState<string>(
    publicProjectSettings.greeting_text ?? "",
  );

  const projectElementsVisible =
    publicProjectSettings[projectElementsVisibleKey] ?? true;
  const analysisVisible = publicProjectSettings[analysisVisibleKey] ?? true;
  const [startZoomArea, setStartZoomArea] = useState(
    publicProjectSettings[startZoomAreaKey] ?? [],
  );

  const branchMetaObjects = useRecoilValue(
    projectBranchesAtomFamily({ nodeId: nodeId }),
  );

  const publicallyAvailableCollectionsLayers: LayerCollection[] = useMemo(
    () =>
      publicProjectSettings[publicCollectionsKey]
        ? publicProjectSettings[publicCollectionsKey]
            .filter((collectionId) =>
              collections.find((c) => c.id === collectionId),
            )
            .map((collectionId) =>
              collections.find((c) => c.id === collectionId),
            )
            .filter(isDefined)
        : [],
    [publicProjectSettings, collections],
  );

  const toggleStartMapBounds = useCallback(() => {
    if (!map) return;
    if (startZoomArea.length !== 0) {
      setStartZoomArea([]);
      return;
    }

    setStartZoomArea(map.getBounds().toArray());
  }, [map, startZoomArea, setStartZoomArea]);

  const setCollectionAvailable = useCallback(
    (collectionId: string, available: boolean) => {
      const publicallyAvailableIds = publicallyAvailableCollectionsLayers.map(
        (coll) => coll.id,
      );

      const newListOfCollectionIds = available
        ? [...publicallyAvailableIds, collectionId]
        : publicallyAvailableIds.filter((colId) => colId !== collectionId);

      updatePublicCollectionSettings(nodeId, newListOfCollectionIds);
    },
    [
      publicallyAvailableCollectionsLayers,
      nodeId,
      updatePublicCollectionSettings,
    ],
  );

  useEffect(() => {
    const asyncFunc = async () => {
      await updatePublicStartZoomSettings(nodeId, startZoomArea);
    };
    asyncFunc();
  }, [startZoomArea, updatePublicStartZoomSettings, nodeId]);

  const startZoomAreaActive = startZoomArea && startZoomArea.length === 2;

  const privateCollections = collections.filter(
    (c) => !publicallyAvailableCollectionsLayers.find((pc) => pc.id === c.id),
  );

  return (
    <WrapperLarge>
      <Column>
        <TitleWithLoader>
          <h3>Public access settings</h3>
          <LoaderWrapper loading={loading}>
            <Spinner size={"1.4rem"} />
          </LoaderWrapper>
        </TitleWithLoader>
        <p>
          Public access gives anyone the possibility to view a slimmed version
          of this project branch together with the currently active data layers.
        </p>

        <h3>Public branches</h3>
        <p>Control which branches should be public.</p>
        {branchMetaObjects.map((branch) => (
          <Suspense fallback={<SkeletonBlock />} key={branch.id}>
            <PublicModePerBranch branch={branch} nodeId={nodeId} />
          </Suspense>
        ))}

        <h3>View settings</h3>
        <VisibleRow>
          <Column style={{ gap: "0.2rem" }}>
            <p style={{ margin: 0 }}>
              {startZoomAreaActive
                ? "Zoom to specified place"
                : "Zoom to all features"}
            </p>
            <Detail>
              Toggle between zooming to custom area or to all map features.
            </Detail>
          </Column>
          <Toggle
            checked={startZoomAreaActive}
            size={ToggleSize.SMALL}
            onChange={toggleStartMapBounds}
            disabled={!adminAccessProject || inReadOnlyMode}
          />
        </VisibleRow>
        {startZoomAreaActive && (
          <VisibleRow>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                if (!map) return;
                setStartZoomArea(map.getBounds().toArray());
              }}
              text={"Zoom to current map location"}
              buttonType={"secondary"}
            />
          </VisibleRow>
        )}
        <VisibleRow>
          <Column style={{ gap: "0.2rem" }}>
            <p>Access project elements</p>
            <Detail>Allow users to view and download geometry</Detail>
          </Column>
          <Toggle
            checked={projectElementsVisible}
            size={ToggleSize.SMALL}
            onChange={() => {
              togglePublicSetting(nodeId, projectElementsVisibleKey);
            }}
            disabled={!adminAccessProject || inReadOnlyMode}
          />
        </VisibleRow>
        <VisibleRow>
          <Column style={{ gap: "0.2rem" }}>
            <p>Show analysis</p>
            <Detail>
              Allow users to view feature properties, noise- and view from
              shore-analysis
            </Detail>
          </Column>
          <Toggle
            checked={analysisVisible}
            size={ToggleSize.SMALL}
            onChange={() => {
              togglePublicSetting(nodeId, analysisVisibleKey);
            }}
            disabled={!adminAccessProject || inReadOnlyMode}
          />
        </VisibleRow>

        <h3>Welcome text</h3>
        <Column style={{ width: "30rem" }}>
          <Label>
            <p>Title</p>
            <Input
              type="text"
              placeholder={"Title"}
              value={greetingTitle}
              onChange={(e) => setGreetingTitle(e.target.value)}
            />
          </Label>
          <Label>
            <p>Description</p>
            <TextArea
              value={greetingText}
              onChange={(e) => setGreetingText(e.target.value)}
              placeholder={"Text"}
              rows={4}
            />
          </Label>
          <Button
            disabled={loading || !adminAccessProject || inReadOnlyMode}
            onClick={() =>
              updatePublicGreeting(nodeId, greetingTitle, greetingText)
            }
            buttonType="primary"
            text={"Save"}
            style={{ marginLeft: "auto" }}
          />
        </Column>

        <h3>Data layers</h3>

        <h4>Public collections</h4>
        {publicallyAvailableCollectionsLayers.length > 0 ? (
          publicallyAvailableCollectionsLayers?.map((collection) => (
            <PublicCollectionLayer
              key={collection.id}
              publicProjectSettings={publicProjectSettings}
              collection={collection}
              setCollectionAvailable={setCollectionAvailable}
              isLoading={loading}
              nodeId={nodeId}
            />
          ))
        ) : (
          <p>No public collections</p>
        )}
        <h4>Project collections</h4>
        {0 < privateCollections.length ? (
          privateCollections.map((collection) => {
            return (
              <VisibleRow key={collection.id} onClick={() => {}}>
                <p>{collection.name}</p>
                <Button
                  disabled={loading || !adminAccessProject || inReadOnlyMode}
                  onClick={() => setCollectionAvailable(collection.id, true)}
                  text={"Make public"}
                  icon={
                    loading ? (
                      <SkeletonBlock
                        style={{ width: "2rem", height: "1rem" }}
                      />
                    ) : (
                      <Add />
                    )
                  }
                  buttonType={"secondary"}
                  size="small"
                />
              </VisibleRow>
            );
          })
        ) : (
          <p>No private collections</p>
        )}
        <span style={{ height: "10rem" }} />
      </Column>
    </WrapperLarge>
  );
};

const PublicModeSettingsOuter = () => {
  const { projectId } = useTypedPath("projectId");
  const publicProjectSettings = useRecoilValue(
    publicProjectSettingsAtomFamily({ projectId }),
  );

  if (!publicProjectSettings || !projectId) return null;

  return (
    <PublicModeSettings
      nodeId={projectId}
      publicProjectSettings={publicProjectSettings}
    />
  );
};

const ShareModeSettings = ErrorBoundaryWrapper(
  () => {
    return (
      <ContentWrapper
        style={{
          maxHeight: "100%",
          overflowY: "auto",
          boxSizing: "border-box",
        }}
      >
        <React.Suspense fallback={<SkeletonText />}>
          <PublicModeSettingsOuter />
        </React.Suspense>
      </ContentWrapper>
    );
  },
  ErrorBoundaryWarningTriangle,
  ScreamOnError,
);

export default ShareModeSettings;
