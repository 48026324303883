import { ReactNode, Suspense, useRef, useState } from "react";
import {
  ChangelogEntryRow,
  ChangelogWrapper,
  DateWrapper,
  ExtraContainer,
  TimelineContainer,
  TimelineContainerPlacement,
} from "./style";
import { SkeletonBlock, SkeletonText } from "components/Loading/Skeleton";
import { useRecoilValue } from "recoil";
import { organisationResourceChangelogSelectorFamily } from "./state";
import { UserInfo } from "components/Organisation/Library/dataLibrary/shared";
import { dateToYearDateTime } from "utils/utils";
import {
  ErrorBoundarySilent,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";
import { useClickOutside } from "hooks/useClickOutside";
import VersionHistoryIcon from "@icons/14/VersionHistoryAlt.svg?react";

const OrganisationResourceChangelogPopupInner = ({
  organisationId,
  resourceId,
  placement = TimelineContainerPlacement.BOTTOM,
}: {
  organisationId: string;
  resourceId: string;
  placement?: TimelineContainerPlacement;
}) => {
  const changelogEntry = useRecoilValue(
    organisationResourceChangelogSelectorFamily({ organisationId, resourceId }),
  );

  return (
    <>
      {changelogEntry && (
        <ExtraContainer placement={placement}>
          {changelogEntry.length === 0 && <div>No changelog entries</div>}
          {changelogEntry.slice(0, 5).map((entry) => (
            <ChangelogEntryRow key={entry.sqsMessageId}>
              <DateWrapper>
                {dateToYearDateTime(new Date(entry.version))}
              </DateWrapper>
              <div>{entry.action}</div>
              <UserInfo organisationId={organisationId} userId={entry.author} />
            </ChangelogEntryRow>
          ))}
          {changelogEntry.length > 5 && <div>....</div>}
        </ExtraContainer>
      )}
    </>
  );
};

const LastUpdatedInner = ({
  organisationId,
  resourceId,
}: {
  organisationId: string;
  resourceId: string;
}) => {
  const changelogEntry = useRecoilValue(
    organisationResourceChangelogSelectorFamily({ organisationId, resourceId }),
  );

  if (!changelogEntry || changelogEntry.length === 0) return null;

  return <>{dateToYearDateTime(new Date(changelogEntry[0].version))}</>;
};

export const LastUpdated = ErrorBoundaryWrapper(
  ({
    organisationId,
    resourceId,
  }: {
    organisationId: string;
    resourceId: string;
  }) => {
    return (
      <Suspense
        fallback={
          <TimelineContainer>
            <SkeletonText />
          </TimelineContainer>
        }
      >
        <LastUpdatedInner
          organisationId={organisationId}
          resourceId={resourceId}
        />
      </Suspense>
    );
  },
  ErrorBoundarySilent,
  ScreamOnError,
);

export const OrganisationResourceChangelogPopup = ErrorBoundaryWrapper(
  ({
    organisationId,
    resourceId,
    children,
    placement = TimelineContainerPlacement.BOTTOM,
  }: {
    organisationId: string;
    resourceId: string;
    children: ReactNode;
    placement?: TimelineContainerPlacement;
  }) => {
    const [changelogOpen, setChangelogOpen] = useState(false);
    const changeLogRef = useRef<HTMLDivElement>(null);
    useClickOutside(changeLogRef, () => setChangelogOpen(false));
    return (
      <TimelineContainer
        onClick={(e) => {
          e.stopPropagation();
          setChangelogOpen(true);
        }}
        ref={changeLogRef}
      >
        {changelogOpen && (
          <Suspense
            fallback={
              <ExtraContainer placement={placement}>
                <TimelineContainer>
                  <SkeletonBlock style={{ width: "10rem", height: "2rem" }} />
                </TimelineContainer>
              </ExtraContainer>
            }
          >
            <OrganisationResourceChangelogPopupInner
              placement={placement}
              organisationId={organisationId}
              resourceId={resourceId}
            />
          </Suspense>
        )}
        {children}
      </TimelineContainer>
    );
  },
  ErrorBoundarySilent,
  ScreamOnError,
);

export const OrganisationResourceChangelogButton = ({
  organisationId,
  resourceId,
}: {
  organisationId: string;
  resourceId: string;
}) => {
  return (
    <OrganisationResourceChangelogPopup
      organisationId={organisationId}
      resourceId={resourceId}
      placement={TimelineContainerPlacement.TOP}
    >
      <ChangelogWrapper>
        <VersionHistoryIcon />
        <div>Change log</div>
      </ChangelogWrapper>
    </OrganisationResourceChangelogPopup>
  );
};
