import { Mixpanel } from "mixpanel";
import { useRecoilCallback } from "recoil";
import { useTypedPath } from "state/pathParams";
import {
  analysisResourceWithAccessOnNodeState,
  organisationAnalysisResourceState,
} from "../state";
import {
  Configuration,
  createOrgConfigurationWithValues,
  deleteOrgConfiguration,
  updateOrgConfiguration,
} from "services/configurationService";
import {
  configurationTempName,
  fetchAnalysisConfigurationUsage,
} from "state/configuration";
import { userAllNodesAccessAtom } from "state/user";

const useOrgAnalysisCrud = () => {
  const { organisationId } = useTypedPath("organisationId");

  const create = useRecoilCallback(
    ({ set, snapshot }) =>
      async ({
        analysis,
        name,
        projectAccess,
      }: {
        analysis?: Configuration;
        name?: string;
        projectAccess?: string[];
      }) => {
        const fallback = await snapshot.getPromise(
          organisationAnalysisResourceState({ organisationId }),
        );

        try {
          const newName =
            name ?? analysis != null ? `${analysis!.name} copy` : "Untitled";
          const newAnalysis = {
            ...analysis,
            name: newName,
          } as Configuration;
          const res = await createOrgConfigurationWithValues(
            organisationId,
            newAnalysis,
            projectAccess,
          );

          set(organisationAnalysisResourceState({ organisationId }), (cur) => [
            ...cur,
            res,
          ]);

          Mixpanel.track("Created org analysis", {
            analysisId: newAnalysis.id,
            organisationId,
            projectAccess,
          });
          return res;
        } catch (e) {
          set(organisationAnalysisResourceState({ organisationId }), fallback);
          throw e;
        }
      },
    [organisationId],
  );

  const update = useRecoilCallback(
    ({ set, snapshot, refresh }) =>
      async (analysis: Configuration) => {
        const fallback = await snapshot.getPromise(
          organisationAnalysisResourceState({ organisationId }),
        );

        const usage = await fetchAnalysisConfigurationUsage(
          organisationId,
          analysis.id,
        );

        if (
          usage.length === 0 ||
          window.confirm(
            `Configuration is currently being used in ${usage.length} places, are you sure you want to update it?`,
          )
        ) {
          const allNodeIds = await snapshot.getPromise(userAllNodesAccessAtom);
          set(organisationAnalysisResourceState({ organisationId }), (cur) =>
            cur.map((c) => (c.id === analysis.id ? analysis : c)),
          );

          return updateOrgConfiguration(organisationId, analysis)
            .then(() => {
              Mixpanel.track("Update org analysis", {
                analysisId: analysis.id,
                organisationId,
              });
              Object.keys(allNodeIds.node_access).map((nodeId) =>
                refresh(analysisResourceWithAccessOnNodeState({ nodeId })),
              );
            })
            .catch((e) => {
              set(
                organisationAnalysisResourceState({ organisationId }),
                fallback,
              );
              throw e;
            });
        }
      },
    [organisationId],
  );

  const remove = useRecoilCallback(
    ({ set, snapshot, refresh }) =>
      async (analysisId: string) => {
        const fallback = await snapshot.getPromise(
          organisationAnalysisResourceState({ organisationId }),
        );
        const usage = await fetchAnalysisConfigurationUsage(
          organisationId,
          analysisId,
        );

        if (
          usage.length === 0 ||
          window.confirm(
            `Configuration is currently being used in ${usage.length} places, are you sure you want to delete it?`,
          )
        ) {
          const allNodeIds = await snapshot.getPromise(userAllNodesAccessAtom);
          return deleteOrgConfiguration(organisationId, analysisId)
            .then((res) => {
              if (res) {
                set(
                  organisationAnalysisResourceState({ organisationId }),
                  (cur) => [...cur].filter((c) => c.id !== analysisId),
                );
                Object.keys(allNodeIds.node_access).map((nodeId) =>
                  refresh(analysisResourceWithAccessOnNodeState({ nodeId })),
                );
                Mixpanel.track("Delete org analysis", {
                  analysisId: analysisId,
                  organisationId,
                });
              }
              return {
                status: "success",
              };
            })
            .catch((e) => {
              set(
                organisationAnalysisResourceState({ organisationId }),
                fallback,
              );
              throw e;
            });
        } else {
          return { status: "cancelled" };
        }
      },
    [organisationId],
  );

  const duplicate = useRecoilCallback(
    () =>
      async ({
        analysis,
        name,
        projectAccess,
      }: {
        analysis: Configuration;
        name?: string;
        projectAccess?: string[];
      }) => {
        return create({ analysis, name, projectAccess });
      },
    [create],
  );

  // This method saves the new name to the data base and the atom 'costConfigurationTempName'.
  // the new name is not updated in localConfig or costConfigurationsAtomFamily,
  // because it would result in loosing non-saved values in the config
  const saveName = useRecoilCallback(
    ({ set, snapshot }) =>
      async (id: string, name: string) => {
        set(configurationTempName({ nodeId: organisationId }), (cur) => ({
          ...cur,
          [id]: name,
        }));

        const currentConfigurations = await snapshot.getPromise(
          organisationAnalysisResourceState({ organisationId }),
        );
        const currentConfig = currentConfigurations.find((c) => c.id === id);
        if (!currentConfig)
          throw Error("Could not find org analysis config, config id: " + id);

        const updatedConfiguration: Configuration = {
          ...currentConfig,
          name: name,
        };

        return updateOrgConfiguration(organisationId, updatedConfiguration);
      },
    [organisationId],
  );

  // This is used to transfer the new names stored in costConfigurationTempName to costConfigurationsAtomFamily,
  // so that costConfigurationsAtomFamily is up to date with the database
  // This is called when the component is unmounted
  const saveTempNameToLocal = useRecoilCallback(
    ({ set, snapshot }) =>
      async (configurationId: string) => {
        const tempNames = await snapshot.getPromise(
          configurationTempName({ nodeId: organisationId }),
        );
        const tempName = tempNames[configurationId];
        if (!tempName) return;

        set(organisationAnalysisResourceState({ organisationId }), (cur) =>
          cur.map((c) =>
            c.id === configurationId ? { ...c, name: tempName } : c,
          ),
        );

        set(configurationTempName({ nodeId: organisationId }), (cur) => ({
          ...cur,
          [configurationId]: undefined,
        }));
      },
    [organisationId],
  );

  return { create, remove, update, duplicate, saveName, saveTempNameToLocal };
};

export default useOrgAnalysisCrud;
