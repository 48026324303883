import { useMemo } from "react";
import { selectorFamily } from "recoil";
import { useRecoilValueLoadable2 } from "utils/recoil";
import { CostUnit } from "types/financial";
import { roundToDecimal } from "utils/utils";
import { MILLION } from "@constants/financialAnalysis";
import { FinanceId, LCoE } from "./state";

export const useLcoe = (id: FinanceId) => {
  const netAEP = useRecoilValueLoadable2(LCoE.Production.getNetAEP(id));

  const discountRate = useRecoilValueLoadable2(LCoE.Inputs.getDiscountRate(id));
  const capexContingency = useRecoilValueLoadable2(
    LCoE.Inputs.getCapexContingency(id),
  );
  const inflationRate = useRecoilValueLoadable2(
    LCoE.Inputs.getInflationRate(id),
  );
  const lifeTime = useRecoilValueLoadable2(LCoE.Inputs.getParkLifeTime(id));

  const devex = useRecoilValueLoadable2(LCoE.Costs.getDevexNpvMillion(id));
  const devexCashFlow = useRecoilValueLoadable2(
    LCoE.Costs.getDevexCashFlow(id),
  );

  const capex = useRecoilValueLoadable2(LCoE.Costs.getCapexNpvMillion(id));
  const capexCashFlow = useRecoilValueLoadable2(
    LCoE.Costs.getCapexCashFlow(id),
  );

  const opex = useRecoilValueLoadable2(LCoE.Costs.getOpexNpvMillion(id));
  const opexCashFlow = useRecoilValueLoadable2(LCoE.Costs.getOpexCashFlow(id));
  const opexDistribution = useRecoilValueLoadable2(
    LCoE.Costs.getOpexDistribution(id),
  );

  const decom = useRecoilValueLoadable2(LCoE.Costs.getDecomNpvMillion(id));
  const decomCashFlow = useRecoilValueLoadable2(
    LCoE.Costs.getDecomCashFlow(id),
  );

  const cashFlow = useRecoilValueLoadable2(lcoeCashFlow(id));
  const lcoe = useRecoilValueLoadable2(LCoE.getLCoE(id));

  return useMemo(
    () => ({
      inputs: {
        netAEP,
        discountRate,
        inflationRate,
        capexContingency,
        lifeTime,
      },
      results: {
        devex: {
          npv: devex,
          cashFlow: devexCashFlow,
        },
        capex: {
          npv: capex,
          cashFlow: capexCashFlow,
        },
        opex: {
          npv: opex,
          cashFlow: opexCashFlow,
          distribution: opexDistribution,
        },
        decom: {
          npv: decom,
          cashFlow: decomCashFlow,
        },
        cashFlow,
        lcoe,
      },
    }),
    [
      capex,
      capexCashFlow,
      capexContingency,
      cashFlow,
      decom,
      decomCashFlow,
      devex,
      devexCashFlow,
      discountRate,
      inflationRate,
      lcoe,
      lifeTime,
      netAEP,
      opex,
      opexCashFlow,
      opexDistribution,
    ],
  );
};

const lcoeCashFlow = selectorFamily({
  key: "lcoe-cash-flow",
  get:
    (id: FinanceId) =>
    ({ get }) => {
      const devex = get(LCoE.Costs.getDevexCashFlow(id));
      const capex = get(LCoE.Costs.getCapexCashFlow(id));
      const opex = get(LCoE.Costs.getOpexCashFlow(id));
      const decom = get(LCoE.Costs.getDecomCashFlow(id));

      return [
        ...devex.map(({ year, value }) => ({
          year,
          value: roundToDecimal(value / MILLION, 2),
          type: "DEVEX",
          unit: CostUnit.millionEuro,
        })),
        ...capex.map(({ year, value }) => ({
          year,
          value: roundToDecimal(value / MILLION, 2),
          type: "CAPEX",
          unit: CostUnit.millionEuro,
        })),
        ...opex.map(({ year, value }) => ({
          year,
          value: roundToDecimal(value / MILLION, 2),
          type: "OPEX",
          unit: CostUnit.millionEuro,
        })),
        ...decom.map(({ year, value }) => ({
          year,
          value: roundToDecimal(value / MILLION, 2),
          type: "DECOM",
          unit: CostUnit.millionEuro,
        })),
      ];
    },
});
