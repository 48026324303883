import { z } from "zod";
import {
  WindData,
  WindDataVortex,
  MeanSpeedGridCustom,
  WindDataMultipleHeights,
  _WindDataMultipleHeights,
  WRG,
} from "../state/windStatistics";
import {
  fetchEnhancerWithToken,
  fetchSchemaWithToken,
  fetchWithToken,
} from "./utils";

import { scream } from "../utils/sentry";

export const _WakeModel = z.union([
  z.literal("jensen"),
  z.literal("turbopark"),
]);
export type WakeModel = z.infer<typeof _WakeModel>;

export const _AnalysisPrecision = z.union([
  z.literal("high"),
  z.literal("default"),
  z.literal("fast"),
]);
export type AnalysisPrecision = z.infer<typeof _AnalysisPrecision>;

export const WAKE_MODEL_NAME: Record<WakeModel, string> = {
  jensen: "Jensen (Park 2)",
  turbopark: "TurbOPark",
};

export const _WakeAnalysisConfiguration = z.object({
  wakeModel: _WakeModel,
  wakeExpansionFactor: z.number(),
  turbulenceIntensity: z.number(),
  turboparkWakeExpansionCalibration: z.number(),
  blockage: z.boolean(),
  neighbourWakeMaxDistance: z.number().default(20),
  neighbourWake: z.boolean().default(true),
  density: z.enum(["mean_at_location"]).or(z.number()),
  precision: _AnalysisPrecision.default("default"),
});
export type WakeAnalysisConfiguration = z.infer<
  typeof _WakeAnalysisConfiguration
>;

export const _EnergyLoss = z.object({
  id: z.string(),
  name: z.string(),
  value: z.number(),
});
export const _ElectricalConfig = z.object({
  interArrayCableLoss: z.boolean().default(false),
  turbineTrafoLoss: z.boolean().default(false),
  cableLengthContingencyEnabled: z.boolean().default(false),
  cableLengthContingency: z.number().default(0),
  exportSystemLoss: z.boolean().optional().default(false), //remove the optional when this is released
});
export type EnergyLoss = z.infer<typeof _EnergyLoss>;
export type ElectricalConfig = z.infer<typeof _ElectricalConfig>;

export const _Configuration = z.object({
  timestamp: z.number().optional(),
  wakeAnalysis: _WakeAnalysisConfiguration,
  electrical: _ElectricalConfig.default({
    interArrayCableLoss: false,
    turbineTrafoLoss: false,
    exportSystemLoss: false,
  }),
  energyLosses: _EnergyLoss.array(),
  id: z.string(),
  name: z.string().optional(),
  description: z.string().optional(),
});

export type Configuration = z.infer<typeof _Configuration>;

const _WindDataReference = z.object({
  file: z.object({
    id: z.string(),
    name: z.string(),
  }),
  data: z.object({
    lon: z.number().optional(),
    lat: z.number().optional(),
    type: z.string().optional(),
  }),
});

export type WindDataReference = z.infer<typeof _WindDataReference>;
export const _ConfigurationUsageType = z.object({
  analysisConfigurationId: z.string(),
  projectId: z.string(),
  branchId: z.string(),
});
export type ConfigurationUsageType = z.infer<typeof _ConfigurationUsageType>;

export async function listConfigurations(nodeId: string) {
  return fetchEnhancerWithToken(`/api/configuration/node/${nodeId}`, {
    method: "get",
  });
}

// --------- Organisation analysis configuration ------------------------

export async function listOrgConfigurations(organisationId: string) {
  return fetchSchemaWithToken(
    _Configuration.array(),
    `/api/configuration/organisation/${organisationId}`,
    {
      method: "get",
    },
  );
}

export async function updateOrgConfiguration(
  organisationId: string,
  configuration: Configuration,
) {
  return fetchEnhancerWithToken(
    `/api/configuration/organisation/${organisationId}/config/${configuration.id}`,
    {
      method: "post",
      body: JSON.stringify(configuration),
      headers: {
        "Content-Type": "application/json",
      },
    },
  ).then((res) => {
    if (!res.ok) throw new Error("Failed saving configuration");
  });
}

export async function createOrgConfigurationWithValues(
  organisationId: string,
  configuration: Configuration,
  projectAccess?: string[],
) {
  return await fetchSchemaWithToken(
    _Configuration,
    `/api/configuration/organisation/${organisationId}/config/new`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ configuration, projectAccess }),
    },
  );
}

export async function deleteOrgConfiguration(
  organisationId: string,
  configId: string,
) {
  return fetchWithToken(
    `/api/configuration/organisation/${organisationId}/config/${configId}`,
    {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
}
// --------- Project analysis configuration ------------------------

export async function updateConfiguration(
  nodeId: string,
  configuration: Configuration,
) {
  return fetchEnhancerWithToken(
    `/api/configuration/node/${nodeId}/config/${configuration.id}`,
    {
      method: "post",
      body: JSON.stringify(configuration),
      headers: {
        "Content-Type": "application/json",
      },
    },
  ).then((res) => {
    if (!res.ok) throw new Error("Failed saving configuration");
  });
}

const _WindDataGetUrlResponse = z.object({
  id: z.string(),
  uploadedAt: z.number(),
  url: z.string().url(),
  fields: z.record(z.string(), z.string()),
});

export type WindDataGetUrlResponse = z.infer<typeof _WindDataGetUrlResponse>;

export async function deleteConfiguration(nodeId: string, configId: string) {
  return fetchWithToken(
    `/api/configuration/node/${nodeId}/config/${configId}`,
    {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
}
export async function createConfigurationWithValues(
  nodeId: string,
  configuration: Configuration,
) {
  return await fetchSchemaWithToken(
    _Configuration,
    `/api/configuration/node/${nodeId}/config/new`,
    {
      method: "post",
      body: JSON.stringify(configuration),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
}

export async function createConfiguration(nodeId: string) {
  const config = await fetchSchemaWithToken(
    _Configuration,
    `/api/configuration/node/${nodeId}/config`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  return config;
}

export async function saveWindData(
  nodeId: string,
  data:
    | WindData
    | WindDataVortex
    | MeanSpeedGridCustom
    | WindDataMultipleHeights
    | WRG,
  fileName: string,
  fileType: string,
): Promise<WindDataReference> {
  const signedUrlResponse = await getWindDataPresignedUrl(nodeId);

  const formData = new FormData();
  Object.entries(signedUrlResponse.fields).forEach(([k, v]) => {
    formData.append(k, v);
  });

  const blob = new Blob([JSON.stringify(data)], {
    type: "application/json",
  });

  formData.append("file", blob);

  const uploadFileResponse = await fetch(signedUrlResponse.url, {
    method: "post",
    body: formData,
  });

  if (uploadFileResponse.status !== 204) {
    throw scream(
      "Something went wrong when trying to upload the wind data...",
      { res: uploadFileResponse },
    );
  }

  const windDataRef: WindDataReference = {
    file: {
      id: signedUrlResponse.id,
      name: fileName,
    },
    data: {
      lon: data.lon,
      lat: data.lat,
      type: fileType,
    },
  };

  return fetchEnhancerWithToken(`/api/configuration/wind/node/${nodeId}`, {
    method: "post",
    body: JSON.stringify(windDataRef),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      if (!res.ok) throw new Error("Failed saving wind data");
      return res.json();
    })
    .then(_renameWindResponse.parse)
    .then((val) => {
      return {
        ...windDataRef,
        file: {
          ...windDataRef.file,
          name: val.name,
        },
      };
    });
}

const getWindDataPresignedUrl = async (
  nodeId: string,
): Promise<WindDataGetUrlResponse> =>
  fetchEnhancerWithToken(
    `/api/configuration/wind/generate-url/node/${nodeId}`,
    {
      method: "get",
    },
  )
    .then((res) => {
      if (!res.ok) {
        throw new Error("Failed to get signed url");
      }
      return res.json();
    })
    .then(_WindDataGetUrlResponse.parse);

const _renameWindResponse = z.object({
  name: z.string(),
});

export async function renameWindData(
  nodeId: string,
  fileId: string,
  name: string,
): Promise<{ name: string }> {
  const response = await fetchWithToken(
    `/api/configuration/wind/node/${nodeId}/${fileId}`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
      }),
    },
  );

  return _renameWindResponse.parse(await response.json());
}

export async function deleteWindData(
  nodeId: string,
  fileId: string,
): Promise<void> {
  await fetchWithToken(`/api/configuration/wind/node/${nodeId}/${fileId}`, {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
    },
  });
}
