import { useMemo } from "react";
import useCableSettings from "./useCableSettings";
import useFoundationSettings from "./useFoundationSettings";
import useMooringSettings from "./useMooringSettings";
import useTurbineSettings from "./useTurbineSettings";
import useSubstationSettings from "./useSubstationSettings";
import useExportCableSettings from "./useExportCableSettings";
import { SettingsItem } from "components/SettingsV2/Shared/types";

export default function useFeatureSettings() {
  const turbineSettings = useTurbineSettings();
  const foundationSettings = useFoundationSettings();
  const cableSettings = useCableSettings();
  const exportCableSettings = useExportCableSettings();
  const mooringSettings = useMooringSettings();
  const substationSettings = useSubstationSettings();

  const projectConfiguration = useMemo(() => {
    const items: SettingsItem[] = [
      turbineSettings,
      foundationSettings,
      cableSettings,
      mooringSettings,
      substationSettings,
      exportCableSettings,
    ];
    return [{ items: items }];
  }, [
    turbineSettings,
    foundationSettings,
    cableSettings,
    mooringSettings,
    substationSettings,
    exportCableSettings,
  ]);

  return projectConfiguration;
}
