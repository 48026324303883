import { z } from "zod";

export const distanceUnits = ["km" as const, "depth" as const];
const _MooringDistance = z.union([z.literal("km"), z.literal("depth")]);
export type MooringDistance = z.infer<typeof _MooringDistance>;

const _MooringParameters = z.object({
  numberOfAnchors: z.number(),
  doubleAnchors: z.boolean(),
  doublingAngle: z.number(),
  mergeDistance: z.number().optional(),
  /** Up-direction for evenly spaced anchors.  Will not be used if `angles` is set. */
  bearing: z.number(),
  /**
   * The angle at which each anchor comes out.  Should be the same length as `numberOfAnchors`.
   * Note that this overrides `bearing`.
   */
  angles: z.number().array().optional(),
  distance: z.number(),
  lineLength: z.number(),
  lineTypeId: z.string(),
  multisegment: z.boolean(),
  lineLengths: z.number().array(),
  lineTypeIds: z.string().array(),
  attachments: z.number().array(),
  distanceMode: _MooringDistance,
  // NOTE: useSeed and slack are intentionally not here
  keepAnchorsInPark: z.boolean().default(false),
  requireAllAnchors: z.boolean().default(false),
});

export type MooringParameters = z.infer<typeof _MooringParameters>;

export const defaultMooringParameters = (
  lineTypeIds: string[],
): MooringParameters => ({
  numberOfAnchors: 3,
  doubleAnchors: false,
  doublingAngle: 5,
  bearing: 0,
  distance: 4,
  lineLength: 3.5,
  lineTypeId: lineTypeIds[0],
  multisegment: false,
  lineLengths: [0.3, 2.2, 0.5],
  lineTypeIds: lineTypeIds,
  attachments: [-2e3, 5e3, 0],
  distanceMode: "depth",
  keepAnchorsInPark: false,
  requireAllAnchors: false,
});

export const _MonopileParameters = z.object({
  soilCoeffSubReact: z.number(),
  ratedPower: z.number(),
  rotorDiameter: z.number(),
  hubHeight: z.number(),
  rnaMass: z.number(),
  waterDepth: z.number(),
  hs50Yr: z.number(),
});

export type MonopileParameters = z.infer<typeof _MonopileParameters>;
