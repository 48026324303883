/// <reference types="vite-plugin-svgr/client" />
import { ProjectFeature } from "../../../types/feature";
import { inPark } from "../../../state/park";
import { partition } from "../../../utils/utils";
import { isAnchor, isMooringLine, isTurbine } from "../../../utils/predicates";
import Tooltip from "../../General/Tooltip";
import ConnectIcon from "@icons/24/Cabling.svg?react";
import { useRecoilValue } from "recoil";
import { MooringLineFeature } from "../../../types/feature";
import { AnchorFeature } from "../../../types/feature";
import { v4 as uuidv4 } from "uuid";
import { MOORING_LINE_PROPERTY_TYPE } from "../../../constants/projectMapView";
import { projectFeaturesSelector } from "../../ProjectElements/state";
import { useProjectElementsCrud } from "../../ProjectElements/useProjectElementsCrud";
import { ParkFeature } from "../../../types/feature";
import { useCallback } from "react";
import { TurbineFeature } from "../../../types/feature";
import { Divider, IconBtn } from "../../General/Icons";

export default function ConnectAnchorOption({
  selection,
  park,
}: {
  selection: ProjectFeature[];
  park: ParkFeature;
}) {
  const projectFeatures = useRecoilValue(projectFeaturesSelector);
  const { update: updateFeatures } = useProjectElementsCrud();

  const onClick = useCallback(
    (anchors: AnchorFeature[], turbine: TurbineFeature) => {
      const id = uuidv4();
      const anchor = anchors[0].id;
      const target = turbine.id;
      const mooringLine: MooringLineFeature = {
        type: "Feature",
        id: id,
        geometry: {
          type: "LineString",
          coordinates: [
            anchors[0].geometry.coordinates,
            turbine.geometry.coordinates,
          ],
        },
        properties: {
          type: MOORING_LINE_PROPERTY_TYPE,
          id: id,
          anchor,
          target,
          parentIds: [park.id],
          slack: 0.3,
        },
      };

      const toBeRemoved = projectFeatures
        .filter((f) => {
          if (!inPark(park.id)(f)) return false;
          if (!isMooringLine(f)) return false;
          return (
            f.properties.anchor === anchor && f.properties.target === target
          );
        })
        .map((f) => f.id);

      updateFeatures({ add: [mooringLine], remove: toBeRemoved });
    },
    [park.id, projectFeatures, updateFeatures],
  );

  if (selection.length !== 2) return null;
  const [anchors, others] = partition(selection, isAnchor);
  const turbine = others[0];
  const okaySelection = anchors.length === 1 && isTurbine(turbine);

  if (!okaySelection) return null;

  return (
    <>
      <Tooltip position="top" text="Anchor turbines">
        <IconBtn onClick={() => onClick(anchors, turbine)}>
          <ConnectIcon />
        </IconBtn>
      </Tooltip>
      <Divider />
    </>
  );
}
