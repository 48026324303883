import React from "react";
import { DateTime } from "luxon";
import { scream, sendWarning } from "./sentry";

export const capitalize = (str: string): string => {
  if (str == null || str.length === 0) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function movingWindow<T>(array: T[]): [T, T][];
export function movingWindow<T>(array: T[], size: number): T[][];
export function movingWindow<T>(array: T[], size: number = 2): T[][] {
  const end = array.length - (size - 1);
  return movingWindowLoop(array, size).slice(0, end);
}

export function movingWindowLoop<T>(array: T[]): [T, T][];
export function movingWindowLoop<T>(array: T[], size: number): T[][];
export function movingWindowLoop<T>(array: T[], size: number = 2): T[][] {
  if (array.length < size) {
    const error = scream(
      `size must be smaller than array length: ${array.length} < ${size}`,
      { items: array },
    );
    throw error;
  }
  let ret: T[][] = [];
  for (let i = 0; i < array.length; i++) {
    const end = i + size;
    if (array.length <= end) {
      ret.push([...array.slice(i), ...array.slice(0, end - array.length)]);
    } else {
      ret.push(array.slice(i, end));
    }
  }
  return ret;
}

export const allEqual = <T>(array: T[]): boolean =>
  array.length < 2 ? true : movingWindow(array).every(([a, b]) => a === b);

export const symmetricDifference = <T>(a: Set<T>, b: Set<T>): Set<T> => {
  const union = new Set([...a, ...b]);
  for (const n of union) if (a.has(n) && b.has(n)) union.delete(n);
  return union;
};

export function min(array: number[]): undefined | number;
export function min<T>(array: T[], fn: (t: T) => number): undefined | T;
export function min<T>(array: T[], fn?: (t: T) => number): undefined | T {
  const f = fn ?? ((e) => e as number); // Safety: if fn is undefined, then e is a number.
  let best = array.at(0);
  if (best === undefined) return undefined;
  let score = f(best);
  for (const e of array.slice(1)) {
    const cand = f(e);
    if (cand < score) {
      score = cand;
      best = e;
    }
  }
  return best;
}

export function max(array: number[]): undefined | number;
export function max<T>(array: T[], fn: (t: T) => number): undefined | T;
export function max<T>(array: T[], fn?: (t: T) => number): undefined | T {
  const f = fn ?? ((e) => e as number); // Safety: if fn is undefined, then e is a number.
  let best = array.at(0);
  if (best === undefined) return undefined;
  let score = f(best);
  for (const e of array.slice(1)) {
    const cand = f(e);
    if (score < cand) {
      score = cand;
      best = e;
    }
  }
  return best;
}

/**
 * Return a new array where the element at the given index is removed.
 */
export const removeIndex = <T>(array: T[], index: number): T[] =>
  array.slice(0, index).concat(array.slice(index + 1));

export function dateToDayMonthShortFormat(date: Date, locale?: string): string {
  return date.toLocaleDateString(locale, {
    day: "numeric",
    month: "short",
  });
}
export function dateToDayMonthShortYearFormat(
  date: Date,
  locale?: string,
): string {
  return date.toLocaleDateString(locale, {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
}

export function versionToMonthAndMaybeYearLongFormat(
  version: number,
  locale?: string,
): string {
  const thisEventDate = DateTime.fromSeconds(version);
  const now = DateTime.now();
  const printYear = now.year !== thisEventDate.year;
  return thisEventDate.toJSDate().toLocaleString(locale, {
    month: "long",
    year: printYear ? "numeric" : undefined,
  });
}

export function numberToClockFormat(time: number) {
  const asString = time.toString();
  if (asString.length === 1) return `0${asString}`;
  else return asString;
}

export function extractHHMMFromDate(date: Date) {
  return `${numberToClockFormat(date.getHours())}:${numberToClockFormat(
    date.getMinutes(),
  )}`;
}

/** Gives you a date of the form `28 Nov, 16:49` */
export function dateToDateTime(date: Date) {
  return `${dateToDayMonthShortFormat(date)}, ${extractHHMMFromDate(date)}`;
}

/** Gives you a date of the form `28 Nov 2023, 16:49` */
export function dateToYearDateTime(date: Date) {
  return `${dateToDayMonthShortYearFormat(date)}, ${extractHHMMFromDate(date)}`;
}

/**
 * Formats the given date as `YYYYMMDD` which is suitable for filenames.
 * @param date The date to format.
 * @returns A formatted date string.
 */
export function dateToFilenameSuffix(date: Date): string {
  const pad = (num: number) => num.toString().padStart(2, "0");
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  return `${year}${month}${day}`;
}

/** Format a number with markers in between each thousand, for readability.
 * For instance, `formatThousands(123456789) === "123'456'789"`.
 */
export function formatThousands(num: number, c: string = "'"): string {
  const fraction = num % 1;
  if (0 < fraction) {
    // NOTE: The reason for not supporting fractions is that it is difficult to
    // get formatting right For instance, `num === 1.1` leads to `fraction ===
    // 0.10000000000000009`. This is "correct" (in the floating point sense),
    // but not helpful.  Further, it's not clear if we should add in separators
    // to the fraction or not (i.e. `0.12345` to be `"0.123'45"`).  Lastly,
    // we probably only want to use this for integers anyways.
    scream("formatThousands does not support fractions", { num, c });
  }
  let number = Math.round(num);

  const lst: string[] = [];
  if (number === 0) lst.push("0");
  while (0 < number) {
    let digits = String(number % 1_000);
    number = Math.floor(number / 1_000);
    lst.push(digits);
  }
  lst.reverse();
  for (let i = 1; i < lst.length; i++) {
    if (lst[i].length === 0) lst[i] = `000`;
    if (lst[i].length === 1) lst[i] = `00${lst[i]}`;
    if (lst[i].length === 2) lst[i] = `0${lst[i]}`;
  }
  return lst.join(c);
}

export const downloadAppFile = (filename: string, filenameOut: string) => {
  const a = document.createElement("a");
  a.href = filename;
  a.download = filenameOut;
  a.click();
};

export const downloadBlob = (blob: Blob, filename: string) => {
  const a = document.createElement("a");
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const tileImageBlobToArrayBuffer = async (
  blob: Blob,
  tileSize: number,
): Promise<Uint8ClampedArray> => {
  const canvas = document.createElement("canvas");
  canvas.height = tileSize;
  canvas.width = tileSize;
  var ctx = canvas.getContext("2d", { willReadFrequently: true });
  if (!ctx) throw new Error("Unable to get canvas context");
  var img = new Image();
  img.src = URL.createObjectURL(blob);
  await new Promise((res) => {
    img.onload = function () {
      res(img);
    };
  });
  ctx.drawImage(img, 0, 0);
  const imageData = ctx.getImageData(0, 0, tileSize, tileSize);
  return imageData.data;
};

export function downloadText(text: string, filename: string) {
  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text),
  );
  element.setAttribute("download", filename);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function objectEquals(a: any, b: any): boolean {
  if (a === b) return true;
  if (typeof a !== typeof b) return false;
  if (typeof a !== "object" || a === null) return a === b;
  if (Array.isArray(a) !== Array.isArray(b)) return false;
  if (Object.keys(a).length !== Object.keys(b).length) return false;
  for (const key of Object.keys(a)) {
    if (!(key in b)) return false;
    if (!objectEquals(a[key], b[key])) return false;
  }
  return true;
}

/**
 * Return a new object without the specificed fields.
 */
export const omitFields = <T, K extends keyof T>(
  obj: T,
  ...keys: K[]
): Omit<T, K> => {
  const ret = { ...obj };
  for (const key of keys) delete ret[key];
  return ret;
};

/** Ensures the argument is of type `never`.
 *
 * This is useful for making sure that all union types are covered in a code path. For instance, if you have
 * a variable `const a: string | number = ...` and branch on the type you can assert at compile time that
 * both the `string` and `number` paths are covered.  If you then add a third type to the union, it will be
 * a type error to not also update the branch.
 * ```
 * const a: string | number = ...
 * if (typeof a === 'string') {
 *     ...
 * } else if (typeof a === 'number') {
 *     ...
 * } else {
 *     isNever(a); // ok
 * }
 * ```
 * If another variant is added, this will not typecheck
 * ```
 * const a: string | number | Feature = ...
 * if (typeof a === 'string') {
 *     ...
 * } else if (typeof a === 'number') {
 *     ...
 * } else {
 *     isNever(a); // Argument of type 'Feature' is not assignable to parameter of type 'never'.
 * }
 * ```
 */
export const isNever = (_: never): undefined => {
  return new Error(`isNever got a value: ${JSON.stringify(_).slice(64)}`, {
    cause: _,
  }) as any as undefined;
};

export const isInChecklyMode = () => {
  const params = new URLSearchParams(window.location.search);
  return !!params.get("checkly");
};

/** Type for our Worker wrapper with input and output types. If we need more
 * functionality from `Worker` (similar to `terminate()`), add it here. */
export type TypedWorker<Args, Ret> = {
  addEventListener: Worker["addEventListener"];

  postMessage: (args: Args) => void;
  /** A callback for receiving messages in the worker.  Note that this is a slightly different
   * API than the standard Worker api, since we pass a callback instad of assinging one.*/
  readonly onmessage: (
    f: (ret: MessageEvent<Ret & { error: Error }>) => any,
  ) => void;
  /** A callback for worker errors.  Note that this is a slightly different
   * API than the standard Worker api, since we pass a callback instad of assinging one.*/
  readonly onerror: (f: (e: ErrorEvent) => any) => void;
  terminate: () => void;
};

/** Wrap a `Worker` with the specified type to get type safety for web workers. */
export const typedWorker = <Args, Ret>(
  worker: Worker,
): TypedWorker<Args, Ret> => ({
  addEventListener: worker.addEventListener.bind(worker),
  postMessage: (args: Args) => worker.postMessage(args),
  onmessage: (f: (ret: MessageEvent<Ret & { error: Error }>) => void) =>
    (worker.onmessage = f),
  onerror: (f: (e: ErrorEvent) => void) => {
    worker.onerror = f;
  },
  terminate: () => worker.terminate(),
});

/** Convenience function for `TypedWorker` for the case when the worker just wraps a function.
 *
 * Example usage where `foo` is the function the worker executes:
 * ```
 * const worker = TypedFnWorker<typeof foo>(new Worker(...))
 * ```
 */
export const typedFnWorker = <Fn extends (...args: any) => any>(
  worker: Worker,
) => typedWorker<Parameters<Fn>, ReturnType<Fn>>(worker);

/**
 * Convenience method for making a `Promise` with a `TypedWorker` inside.
 *
 * ## Warning
 * You cannot create multiple {@link Promise}s with the same {@link TypedWorker},
 * since we overwrite `.onmessage` in each call to {@link promiseWorker}.
 * */
export const promiseWorker = <Args, Ret>(
  worker: TypedWorker<Args, Ret>,
  args: Args,
): Promise<Ret> => {
  return new Promise<Ret>((res, rej) => {
    worker.onmessage((e) => {
      if (typeof e.data === "object" && "error" in e.data) {
        sendWarning("promiseWorker got error", {
          e: e.data.error.message,
          data: e.data,
          error: e.data.error,
        });
        rej(e.data.error);
      } else {
        res(e.data);
      }
    });
    worker.onerror((e) => {
      rej(e);
    });
    worker.postMessage(args);
  });
};

/** Get a partial type with the given union type required.  For example,
 * `With<api.ProjectMeta, 'id'>` is a `Partial<api.ProjectMeta>` but with the `id` field required.
 *
 * From https://stackoverflow.com/a/57390160
 */
export type With<T, K extends keyof T> = Partial<T> & Required<Pick<T, K>>;

/**
 * Extend a type with another type. If there are overlap in the fields, the
 * types of the second type paramter will be used.
 */
export type Extend<A, B> = Omit<A, keyof B> & B;

/**
 * Remove `undefiend` from the type.
 */
export type Defined<T> = T extends undefined ? never : T;

export const zip = <A, B>(a: A[], b: B[]): [A, B][] => {
  const n = Math.min(a.length, b.length);
  return a.slice(0, n).map((aa, i) => [aa, b[i]]);
};

/**
 * Split the array into two arrays, the first for elements for which the type guard returns `true` and one for the rest.
 * ```ts
 * const [turbines, rest] = partition(projectData, isTurbine)
 * ```
 */
export function partition<T, S extends T>(
  array: T[],
  fn: (t: T) => t is S,
): [S[], Exclude<T, S>[]];
/**
 * Split the array into two arrays, the first for elements for which the predicate returns `true` and one for the rest.
 * ```ts
 * const [even, odd] = partition([1,2,3,4,5,6], (x) => x % 2 === 0)
 * even // [2,4,6]
 * odd // [1,3,5]
 * ```
 */
export function partition<T>(array: T[], fn: (t: T) => boolean): [T[], T[]];
export function partition<T>(array: T[], fn: (t: T) => unknown): [T[], T[]] {
  const trues: T[] = [];
  const falses: T[] = [];
  for (const e of array) {
    if (fn(e)) trues.push(e);
    else falses.push(e);
  }
  return [trues, falses];
}

export const clamp = (a: number, x: number, b: number): number => {
  if (x < a) return a;
  if (b < x) return b;
  return x;
};

export const mapToRecord = <K extends string | number | symbol, V>(
  map: Map<K, V>,
): Record<K, V> => {
  let ret: Partial<Record<K, V>> = {};
  for (const [k, v] of map.entries()) ret[k] = v;
  return ret as Record<K, V>; // safety: All keys have been inserted, so it is no longer Partial.
};

/** Deduplicates the array.
 * Two elements are considered the same if {@link Map} consideres them the same key.
 * See [this](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map#key_equality) page.
 *
 * ## Ordering
 * The ordering of the **keys** is maintained, but the unique value for each
 * key is the **last** element in the array.  Confusingly, this means that if
 * the key is the element itself, **order is maintained**.
 *
 * ### Examples
 *
 * No `key` means order is maintained:
 *
 * ```ts
 * dedup([1, 2, 3, 4, 3, 2, 1]); // [1, 2, 3, 4]
 * ```
 *
 * Using a key can lead to a surprising ordering:
 *
 * ```ts
 * dedup(["a", "ab", "abc", "cd"], (s) => s.length); // ["a", "cd", abc"]
 * ```
 *
 * The keys of the input array are `[1, 2, 3, 2]`, and so the output ordering
 * of the unique keys is `[1, 2, 3]`.  However, the returned element of length
 * 2 is `"cd"` and **not** `"ab"`, since `"cd"` was the **last** element in the input
 * array.
 **/
export function dedup<T>(array: T[]): T[];
export function dedup<T, K>(array: T[], key: (t: T) => K): T[];
export function dedup<T>(array: T[], key = (t: T) => t): T[] {
  return [...new Map(array.map((t) => [key(t), t])).values()];
}

/**
 * Returna a `Map` where each element in the array is a key and the value is the
 * number of times it appears in the array.
 *
 * Two elements are considered the same if `Map` considers them the same; See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map#key_equality
 */
export function count<T>(array: T[]): Map<T, number> {
  const counts = new Map<T, number>();
  array.forEach((t) => {
    counts.set(t, (counts.get(t) ?? 0) + 1);
  });
  return counts;
}

/**
 * Takes a list of `[key, number]` values, and returns an object with the input
 * keys mapped to the sum of the elements with that key.
 */
export function sumKeys<T extends string | number | symbol>(
  array: [T, number][],
): Record<T, number> {
  let sum: Partial<Record<T, number>> = {};
  for (const [k, n] of array) {
    sum[k] = (sum[k] ?? 0) + n;
  }
  return sum as Record<T, number>; // safety: All keys have been inserted, so it is no longer Partial.
}

/**
 * Sums the number under the supplied function.
 * If `array` is empty, return `0`.
 */

export function sum(array: number[]): number;
export function sum<T>(array: T[], fn: (t: T, i: number) => number): number;
export function sum<T>(array: T[], fn?: (t: T, i: number) => number): number {
  const f = fn ?? ((e) => e as number); // Safety: if fn is undefined, then e is a number.
  let sum = 0;
  for (let i = 0; i < array.length; i++) sum += f(array[i], i);
  return sum;
}

/**Inclusive, exclusive range.
 * ```ts
 * range(4, 6) === [4, 5]
 * ```
 */
export const range = (from: number, to: number): number[] => {
  if (to <= from) return [];
  return new Array(to - from).fill(0).map((_, i) => from + i);
};

/**
 * Get a new array with all combinations of the two input arrays.
 */
export const allPairs = <A, B>(as: A[], bs: B[]): [A, B][] => {
  const ret: [A, B][] = [];
  for (const a of as) for (const b of bs) ret.push([a, b]);
  return ret;
};

/**
 * Get all unique pairs of elements in the array.
 */
export const uniquePairs = <T>(array: T[]): [T, T][] => {
  const ret: [T, T][] = [];
  for (let i = 0; i < array.length; i++)
    for (let j = i + 1; j < array.length; j++) ret.push([array[i], array[j]]);
  return ret;
};

export const fuzzyMatch = (word: string, searchString: string): boolean => {
  let i = 0,
    j = 0;
  outer: for (; i < word.length; i++) {
    for (; j < searchString.length; j++) {
      if (word[i] === searchString[j]) {
        j++;
        continue outer;
      }
    }
    return false;
  }
  return true;
};

export const platformCtrlOrCommand = <
  E extends React.KeyboardEvent | React.MouseEvent,
>(
  e: E,
): boolean => {
  const isMac = usingMac();
  if (isMac) return e.metaKey;
  return e.ctrlKey;
};

/** Return a promise that waits the given number of milliseconds. */
export const wait = (ms: number): Promise<void> =>
  new Promise((res) => setTimeout(() => res(undefined), ms));

export const appendQueryParamsSign = (url: string) =>
  url.includes("?") ? "&" : "?";

export const fastMax = (array: number[], fallback = NaN) => {
  if (array.length === 0) return fallback;
  let maxElement = array[0];
  for (let i = 1; i < array.length; i++) {
    if (array[i] > maxElement) {
      maxElement = array[i];
    }
  }
  return maxElement;
};

export const maxBy = <T>(array: T[], fn: (t: T) => number): T | undefined => {
  if (array.length === 0) return undefined;
  let maxElement = array[0];
  let maxVal = fn(maxElement);
  for (let i = 1; i < array.length; i++) {
    const val = fn(array[i]);
    if (val > maxVal) {
      maxElement = array[i];
      maxVal = val;
    }
  }
  return maxElement;
};

export const fastMin = (array: number[], fallback = NaN) => {
  if (array.length === 0) return fallback;
  let minElement = array[0];
  for (let i = 1; i < array.length; i++) {
    if (array[i] < minElement) {
      minElement = array[i];
    }
  }
  return minElement;
};

export const roundToDecimal = (val: number, decimal: number) =>
  Math.round(val * Math.pow(10, decimal)) / Math.pow(10, decimal);

export const sqsUnicodeCharIsOkay = (codePoint: number): boolean => {
  // NOTE: SQS has weird unicode limits. See https://docs.aws.amazon.com/AWSSimpleQueueService/latest/SQSDeveloperGuide/quotas-messages.html
  // Also see this: https://www.w3.org/TR/REC-xml/#charsets
  if (codePoint === 0x9) return true;
  if (codePoint === 0xa) return true;
  if (codePoint === 0xd) return true;
  if (codePoint >= 0x20 && codePoint <= 0xd7ff) return true;
  if (codePoint >= 0xe000 && codePoint <= 0xfffd) return true;
  if (codePoint >= 0x10000 && codePoint <= 0x10ffff) return true;
  return false;
};

export const sqsUnicodeStringIsOkay = (str: string): boolean => {
  for (let i = 0; i < str.length; i++) {
    const codePoint = str.codePointAt(i);
    if (codePoint === undefined) return false;
    if (!sqsUnicodeCharIsOkay(codePoint)) return false;
  }
  return true;
};

export const sqsUnicodeStringFilter = (str: string): string => {
  let ret = "";
  for (let i = 0; i < str.length; i++) {
    const codePoint = str.codePointAt(i);
    if (codePoint === undefined) continue;
    if (!sqsUnicodeCharIsOkay(codePoint)) continue;
    ret += str[i];
  }
  return ret;
};

/** assumes array elements are primitive types
 * check whether 2 arrays are equal sets.
 * @param  {} a1 is an array
 * @param  {} a2 is an array
 */
export function areArraysEqualSets(a1: string[], a2: string[]) {
  const superSet: Record<string, number> = {};
  for (const i of a1) {
    const e = i + typeof i;
    superSet[e] = 1;
  }

  for (const i of a2) {
    const e = i + typeof i;
    if (!superSet[e]) {
      return false;
    }
    superSet[e] = 2;
  }

  for (let e in superSet) {
    if (superSet[e] === 1) {
      return false;
    }
  }

  return true;
}

export type NestedArray<T> = Array<T> | NestedArray<T>[];
export const funcOnCoords = <T>(
  coords: number[] | NestedArray<number>,
  func: (n: number[]) => T,
): T | NestedArray<T> => {
  const isBottom = <T>(a: NestedArray<T>): a is Array<T> =>
    typeof a[0] === "number";

  if (isBottom(coords)) return func(coords);

  const mapped = coords.map((c) => funcOnCoords(c, func)) as
    | T[]
    | NestedArray<T>;
  return mapped;
};

/** Apply the function to the value if it is defined. If it is undefined, pass down `undefined`. */
export const undefMap = <T, U>(
  val: T | undefined,
  fn: (t: T) => U | undefined,
): U | undefined => (val === undefined ? undefined : fn(val));

export const nullMap = <T, U>(
  val: T | null | undefined,
  fn: (t: T) => U | null | undefined,
): U | null | undefined =>
  val == null || val === undefined ? undefined : fn(val);

/**
 * Parity-2 version of {@link undefMap}. Required both arguments to be not
 * `undefined` to call the function.
 */
export const undefMap2 = <A, B, T>(
  a: A | undefined,
  b: B | undefined,
  fn: (a: A, b: B) => T | undefined,
): T | undefined =>
  a === undefined ? undefined : b === undefined ? undefined : fn(a, b);

/**
 * Comparator function creator for lists of elements where the supplied function
 * is used to compare the elements.
 */
export function listCompare<T>(fn: (t1: T, t2: T) => number) {
  function inner(a: T[], b: T[]) {
    const n = Math.max(a.length, b.length);
    for (let i = 0; i < n; i++) {
      if (i < a.length && b.length <= i) return -1;
      if (a.length <= i && i < b.length) return 1;
      const c = fn(a[i], b[i]);
      if (c !== 0) return c;
    }
    return 0;
  }
  return inner;
}

export const getFieldNameWithCorrectCasing = (
  obj: Record<string, unknown> | undefined | null,
  fieldName: string,
): string | undefined => {
  if (!obj) {
    return;
  }

  return Object.keys(obj).find(
    (key) => key.toLowerCase() === fieldName.toLowerCase(),
  );
};

/**
 * Returns the integer and the decimal part of a number. The decimal part is
 * **always** positive, and the sum of the integer part and decimal part is
 * equal to the input number.
 *
 * ## Example
 * ```ts
 * const [i, f] = splitDecimal(3.14);
 * i === 3;
 * f === 0.14;
 *
 * const [i, f] = splitDecimal(-3.14);
 * i === -4;
 * f === 0.86;
 * ```
 */
export const splitDecimal = (val: number): [number, number] => {
  const int = Math.floor(val);
  const frac = val - Math.floor(val);
  return [int, frac];
};

export const usingMac = () => navigator.userAgent.indexOf("Mac OS X") != -1;

/**
 * Stringify all keys and values of an object.
 */
export const toRecordString = <K extends string | number | symbol, V>(
  r: Partial<Record<K, V>>,
): Record<string, string> =>
  Object.fromEntries(Object.entries(r).map(([k, v]) => [String(k), String(v)]));
