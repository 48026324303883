export const cursorIsInRightHalfOfElement = (
  element: HTMLElement | null,
  mousePosition: { x: number; y: number } | null,
): boolean => {
  if (!element || !mousePosition) {
    return false;
  }

  const targetRect = element.getBoundingClientRect();
  const targetCenterX = (targetRect.right - targetRect.left) / 2;
  const offsetX = mousePosition.x - targetRect.left;
  return offsetX > targetCenterX;
};

export const cursorIsInBottomHalfOfElement = (
  element: HTMLElement | null,
  mousePosition: { x: number; y: number } | null,
): boolean => {
  if (!element || !mousePosition) {
    return false;
  }

  const targetRect = element.getBoundingClientRect();
  const targetCenterY = (targetRect.bottom - targetRect.top) / 2;
  const offsetY = mousePosition.y - targetRect.top;
  return offsetY > targetCenterY;
};

export const findCursorPositionInElement = (
  element: HTMLElement | null,
  mousePosition: { x: number; y: number } | null,
  topBottomZoneSizePx?: number,
): "top" | "middle" | "bottom" | undefined => {
  if (!element || !mousePosition) {
    return;
  }

  const targetRect = element.getBoundingClientRect();
  const offsetY = mousePosition.y - targetRect.top;
  const divisionSize = topBottomZoneSizePx ?? targetRect.height / 3;

  return offsetY > targetRect.height
    ? undefined
    : offsetY > targetRect.height - divisionSize
      ? "bottom"
      : offsetY < divisionSize
        ? "top"
        : "middle";
};
