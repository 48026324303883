import styled from "styled-components";
import { colors } from "../../../../styles/colors";
import { Container } from "../../../../styles/misc/Misc";
import { inReadOnlyModeSelector } from "state/project";
import { useRecoilValue } from "recoil";
import { SearchObject } from "components/Search/state";
import SearchWrapper from "components/Search/SearchWrapper";
import SideBarIconBtn from "components/General/SideBarIconButton";
import { ReferenceType } from "@floating-ui/react";

export const ParentWrapper = styled(Container)`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: start;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
  margin: 0.2rem 0 0 0;
`;

export const SubHeader2 = styled.h4`
  color: ${colors.brand};
  margin: 1.2rem 0 0.4rem;
`;

export const Content = styled.div`
  flex-grow: 2;
  padding: 0 0.5rem 0 0;
`;

export const MenuOptionV2 = ({
  Icon,
  chosenMenu,
  setChosenMenu,
  option,
  title,
  id = undefined,
  searchObject,
  innerRef,
}: {
  Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;

  chosenMenu: any;
  setChosenMenu: any;
  option: any;
  title: string;
  id?: string;
  searchObject?: SearchObject;
  innerRef?: ((node: ReferenceType | null) => void) &
    ((node: ReferenceType | null) => void);
}) => {
  const readOnlyMode = useRecoilValue(inReadOnlyModeSelector);
  const selected = chosenMenu === option;
  const children = (
    <SideBarIconBtn
      id={id}
      data-intercom-target={id}
      active={selected}
      disabled={readOnlyMode}
      onClick={() => setChosenMenu(selected ? undefined : option)}
      icon={<Icon />}
      title={title}
      innerRef={innerRef}
    />
  );

  return searchObject ? (
    <SearchWrapper {...searchObject}>{children}</SearchWrapper>
  ) : (
    children
  );
};
