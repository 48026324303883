import { fromBlob } from "geotiff";

let geotiffCompresser: Worker | undefined = undefined;
const getGeotiffCompressor = () => {
  if (geotiffCompresser === undefined)
    geotiffCompresser = new Worker("/web_workers/gdal/compress.js");
  return geotiffCompresser;
};

export async function* lossyCompressGeotiffGenerator(
  file: File,
  maxSizeInBytes: number,
) {
  if (file.size < maxSizeInBytes) {
    return file;
  }

  yield "Bathymetry file is larger than 100 MB, will compress...";
  const geotiffMaybe = await fromBlob(file);
  const image = await geotiffMaybe.getImage();

  const sizeDiff = maxSizeInBytes / file.size;
  const shrinkPercentage = Math.sqrt(sizeDiff);

  const tr = image
    .getResolution()
    .map((r) => Math.abs(r) / shrinkPercentage)
    .slice(0, 2);

  const gc = getGeotiffCompressor();
  const compressedGeotiff = await new Promise<Blob>((res) => {
    gc.postMessage({ file, tr });
    gc.onerror = function (e) {
      if (e.message.includes("try again"))
        setTimeout(() => gc.postMessage({ file, tr }), 100);
    };
    gc.onmessage = async function (evt) {
      const blob = new Blob([evt.data.bytes]);
      res(blob);
    };
  });

  return compressedGeotiff;
}
