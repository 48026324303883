import styled from "styled-components";
import { spaceMedium } from "../../../styles/space";
import { useDashboardContext } from "../Dashboard";
import { SafeCard } from "./Base";
import {
  EditableTextAreaInternalState,
  EditableTextInternalState,
} from "../../General/EditableText";
import { useDashboard } from "../hooks";

const Body = styled.div`
  padding: ${spaceMedium};
  display: flex;
  flex-direction: row;

  & > * {
    white-space: pre-line;
  }
  .editable-text-area-column,
  .editable-text-area-column > * {
    flex: 1;
  }
`;

export const TextWidget = () => {
  const { dashboard } = useDashboardContext();
  const { updateWidget } = useDashboard(dashboard.id);
  const state = dashboard.widgetState["Text"];
  const title = state?.title ?? "New Card";
  const body = state?.body ?? "Lorem Ipsum";
  return (
    <SafeCard
      title={
        <EditableTextInternalState
          value={title}
          disabled={dashboard.preset}
          onEnter={(title) => {
            updateWidget("Text", { title });
          }}
        />
      }
      id="Text"
    >
      <Body>
        <EditableTextAreaInternalState
          value={body}
          onEnter={(body) => {
            updateWidget("Text", { body });
          }}
        />
      </Body>
    </SafeCard>
  );
};
