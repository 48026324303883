import { atom, atomFamily, selectorFamily } from "recoil";
import { listArchivedProjectVersions } from "./service";
import { ArchivedProjectVersion } from "./types";

export const archivedProjectVersionsAtomFamily = atomFamily<
  ArchivedProjectVersion[],
  { organisationId: string; projectId: string; branchId: string }
>({
  key: "archivedProjectVersionsAtomFamily",
  default: selectorFamily({
    key: "archivedProjectVersionsFamily.default",
    get:
      ({ organisationId, projectId, branchId }) =>
      async () => {
        const res = await listArchivedProjectVersions({
          organisationId,
          projectId,
          branchId,
        });
        return res.archivedVersions;
      },
  }),
});

export const archivedProjectVersionsOpenStateAtom = atom<boolean>({
  key: "archivedProjectVersionsOpenStateAtom",
  default: false,
});
