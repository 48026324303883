import React, { Suspense, useEffect, useMemo, useRef, useState } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import {
  SetterOrUpdater,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import {
  Routes,
  Route,
  Outlet,
  Navigate,
  useNavigate,
  useParams,
} from "react-router-dom";
import WebFont from "webfontloader";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getCompanyNameFromEmail,
  getTokenAtom,
  loggedInUserIsCheckly,
  loggedInUserIsInternalSelector,
  loggedInUserSelector,
  userNodeAccessNameSelectorFamily,
} from "./state/user";
import Toast from "./components/Toast/Toast";
import UserProfileSettingsModal, {
  UserProfileModalType,
} from "./components/UserProfileModal/UserProfileModal";
import RenameModals from "./components/RenameModal/RenameModals";
import QgisPluginReadme from "./components/QgisPluginReadme/QgisPluginReadme";
import ArcgisPluginReadme from "./components/ArcgisPluginReadme/ArcgisPluginReadme";
import { RedirectToPublicModeApp } from "./PublicMap/PublicMap";
import { Mixpanel } from "./mixpanel";
import TrackNavigation from "./hooks/TrackNavigation";
import BufferModal from "./components/BufferModal/BufferModal";
import Design from "./components/Design/DesignWrapper";
import FilterExternalDataLayers from "./components/FilterExternalDataLayers/FilterExternalDataLayers";
import * as SentryR from "@sentry/react";
import { isInChecklyMode } from "./utils/utils";
import { useRefreshTokenBeforeExpiration } from "./hooks/useRefreshTokenBeforeExpiration";
import { setAccessTokenGlobal } from "./state/global";
import AblyWrapper from "./components/AblyWrapper";
import ParkDesignLegacy, {
  ParkDesignV2,
} from "./components/ParkDesign/ParkDesignV2";
import SelectCorrectBranch from "./components/SelectCorrectBranch/SelectCorrectBranch";
import CompareParksModal from "./components/CompareParksModal/CompareParksModal";
import { modalTypeOpenAtom } from "./state/modal";
import ActiveTips from "./components/ActiveTips/ActiveTips";
import { viewFromShoreVisibleAtom } from "./state/viewToPark";
import ViewToParkThree from "./components/ViewToPark/ViewToParkThree";
import ViewToParkContextProvider from "./components/ViewToPark/ThreeContext/ViewToParkContextProvider";
import CopyCollectionModal from "./components/CopyCollectionModal/CopyCollectionModal";
import { LoggedInUser } from "./types/user";
import ReloadForNewVersionModal from "./components/ReloadForNewVersionModal/ReloadForNewVersionModal";
import FrontEndVersionPoller from "./components/FrontendVersionPoller/FrontEndVersionPoller";
import GPUCheck from "./components/GPUCheck/GPUCheck";
import ProjectConfigurationModal from "./components/SettingsV2/ProjectConfiguration/ProjectConfigurationModal";
import FeatureSettingsModal from "./components/SettingsV2/FeatureSettings/FeatureSettingsModal";
import PublicModeModal from "./components/SettingsV2/PublicModeSettings/PublicModeModal";
import TermsOfUseWizard from "./components/TermsOfUseWizard/TermsOfUseWizard";
import UploadModal from "./components/UploadModal/UploadModal";
import OrganisationPage, {
  CustomerBody,
  CustomerBodyPlaceholder,
  OrganisationBody,
} from "./components/Organisation/OrganisationPage";
import { DashboardModal } from "./components/Dashboard/Dashboard";
import AddLayerSourceInternalModal from "./components/AddLayerSourceInternalModal/AddLayerSourceInternalModal";
import * as Sentry from "@sentry/browser";
import { TopRightModeActiveAtom } from "./components/RightSide/InfoModal/ProjectFeatureInfoModal/state";
import usePrevious from "./hooks/usePrevious";
import { lowerRightMenuActiveModeAtom } from "./state/layer";
import { pathParamsAtom_DontUseThisDirectly } from "./state/pathParams";
import { useDrawMode } from "components/MapControls/useActivateDrawMode";
import useRefreshOnTabFocusAfterDormancy from "hooks/useRefreshOnTabFocusAfterDormancy";
import NoOrganisationPage from "components/Organisation/NoOrganisationPage";
import KeyboardShortcutsModal from "components/LearnVind/KeyboardShortcutsModal";
import VideoModal from "components/LearnVind/VideoModal";
import DownloadCustomCRSModal from "components/DownloadCustomCRSModal/DownloadCustomCRSModal";
import { AddCustomCRSModal } from "components/CustomCRSDropdown/CustomCRSDropdown";
import ComponentsPreview from "components/ComponentsPreview/ComponentsPreviewModal";
import ArchiveProjectAccess from "components/Design/ArchiveProjectVersion/ArchiveProjectAccess";
import ArchiveProjectVersion from "components/Design/ArchiveProjectVersion/lambdaRender/ArchiveProjectVersion";
import { AblyChangelogUpdateComponent } from "components/Changelog/useChangelogAbly";
import AblySyncDataLibrary from "components/Organisation/Library/dataLibrary/AblySyncDataLibrary";

export const useSetPathParams = () => {
  const params = useParams();
  const setPathParams = useSetRecoilState(pathParamsAtom_DontUseThisDirectly);
  useEffect(() => {
    setPathParams(params);
  }, [params, setPathParams]);
};

const BootIntercom = () => {
  const userData = useRecoilValue(loggedInUserSelector);
  const [intercomInitialized, setIntercomInitialized] = useState(false);

  useEffect(() => {
    if (
      userData &&
      userData.email !== "checkly@vind.ai" &&
      userData.hmac &&
      !intercomInitialized
    ) {
      window.Intercom("boot", {
        app_id: "vt2a9v5k",
        custom_launcher_selector: "#help_icon_link",
        user_id: userData.user_id,
        user_hash: userData.hmac,
        email: userData.email,
      });
      setIntercomInitialized(true);
    }
  }, [intercomInitialized, userData]);

  return null;
};

const SetMixpanelData = () => {
  const userData = useRecoilValue(loggedInUserSelector);
  const isInternal = useRecoilValue(loggedInUserIsInternalSelector);
  const isCheckly = useRecoilValue(loggedInUserIsCheckly);
  const [mixpanelInitialized, setMixpanelInitialized] = useState(false);

  useEffect(() => {
    if (!userData || mixpanelInitialized) {
      return;
    }

    if (isCheckly) {
      Mixpanel.stop_tracking();
      return;
    }

    const userCompany = getCompanyNameFromEmail(userData.email);

    Mixpanel.identify(userData.user_id);
    if (userCompany) {
      Mixpanel.people.set_once({ company: userCompany });
    }
    if (userData.email) {
      Mixpanel.people.set_once({ $email: userData.email });
    }
    const interests = userData.interests;
    Mixpanel.people.set({
      interests: interests ?? {},
      allow_interest_specific_news:
        userData.allow_interest_specific_news ?? false,
      allow_news: userData.allow_news ?? false,
    });
    Mixpanel.track("App loaded", { internal: isInternal });
    setMixpanelInitialized(true);
  }, [isCheckly, isInternal, mixpanelInitialized, userData]);

  return null;
};

const SetSentryData = () => {
  const userData = useRecoilValue(loggedInUserSelector);
  const isInternal = useRecoilValue(loggedInUserIsInternalSelector);
  const { organisationId, projectId, branchId } = useParams();
  const userProjectAccess = useRecoilValue(
    userNodeAccessNameSelectorFamily({ nodeId: projectId }),
  );
  const [sentryInitialized, setSentryInitialized] = useState(false);

  useEffect(() => {
    if (!userData || sentryInitialized) {
      return;
    }

    const userCompany = getCompanyNameFromEmail(userData.email);

    if (isInternal) {
      Sentry.setTag("internal", userData.email);
    }

    if (userCompany) {
      Sentry.setTag("vind.company", userCompany);
    }
    setSentryInitialized(true);
  }, [isInternal, sentryInitialized, userData]);

  useEffect(() => {
    Sentry.setTag("vind.organisationId", organisationId);
    Sentry.setTag("vind.projectId", projectId);
    Sentry.setTag("vind.branchId", branchId);
    Sentry.setTag("vind.projectAccess", userProjectAccess);
  }, [branchId, organisationId, projectId, userProjectAccess]);

  return null;
};

const TrackStateChanges = () => {
  const pathParams = useRecoilValue(pathParamsAtom_DontUseThisDirectly);

  // track mode changes in the left menu
  const [leftMenuModeActive] = useDrawMode();
  const previousLeft = usePrevious(leftMenuModeActive);
  useEffect(() => {
    if (leftMenuModeActive && leftMenuModeActive !== previousLeft) {
      Mixpanel.track("Left menu mode activated", {
        mode: leftMenuModeActive,
        ...pathParams,
      });
    }
  }, [leftMenuModeActive, pathParams, previousLeft]);

  // track mode changes in the top right menu
  const topRightMenuModeActive = useRecoilValue(TopRightModeActiveAtom);
  const previousTopRight = usePrevious(topRightMenuModeActive);
  useEffect(() => {
    if (topRightMenuModeActive && previousTopRight !== topRightMenuModeActive) {
      Mixpanel.track("Top right mode activated", {
        mode: topRightMenuModeActive,
        ...pathParams,
      });
    }
  }, [pathParams, previousTopRight, topRightMenuModeActive]);

  // track mode changes in the lower right menu
  const lowerRightMenuModeActive = useRecoilValue(lowerRightMenuActiveModeAtom);
  const previousLowerRight = usePrevious(lowerRightMenuModeActive);
  useEffect(() => {
    if (
      lowerRightMenuModeActive &&
      previousLowerRight !== lowerRightMenuModeActive
    ) {
      Mixpanel.track("Lower right mode activated", {
        mode: lowerRightMenuModeActive,
        ...pathParams,
      });
    }
  }, [lowerRightMenuModeActive, pathParams, previousLowerRight]);

  return null;
};

const DesignSystem = React.lazy(() => import("./components/DesignSystem"));
const PermissionsPage = React.lazy(
  () => import("./components/PermissionsPage"),
);
const OrgInternalPage = React.lazy(
  () => import("./components/OrgToolingInternal"),
);
const PageAPI = React.lazy(() => import("./components/PageAPI"));

const InternalRoute = () => {
  const userIsInternal = useRecoilValue(loggedInUserIsInternalSelector);
  const navigate = useNavigate();
  const ref = useRef<NodeJS.Timeout>();
  if (ref.current) clearTimeout(ref.current);
  if (!userIsInternal) {
    ref.current = setTimeout(() => navigate("/"), 100);
    return null;
  }
  return <Outlet />;
};

const ProtectedRoute = ({
  token,
  setToken,
}: {
  token: string | undefined;
  setToken: SetterOrUpdater<string | undefined>;
}) => {
  useRefreshTokenBeforeExpiration();
  useSetPathParams();
  const {
    user,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
    handleRedirectCallback,
  } = useAuth0();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const sharedToken = query.get("token");
    if (sharedToken) {
      setAccessTokenGlobal(sharedToken);
      setToken(sharedToken);
      return;
    }

    if (isLoading) return;
    if (!isAuthenticated) {
      loginWithRedirect({
        appState: {
          redirectUrl: window.location.pathname + window.location.search,
        },
      });
      return;
    }
    const getNewToken = async () => {
      const token = await getAccessTokenSilently();
      setAccessTokenGlobal(token);
      setToken(token);
    };
    getNewToken();
  }, [
    user,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
    setToken,
    handleRedirectCallback,
  ]);

  if (!token) {
    return null;
  }

  return <Outlet />;
};

export const CommonComponents = () => {
  const viewFromShoreVisible = useRecoilValue(viewFromShoreVisibleAtom);
  return (
    <>
      <Toast />
      {viewFromShoreVisible && (
        <ViewToParkContextProvider>
          <ViewToParkThree />
        </ViewToParkContextProvider>
      )}
    </>
  );
};

const OpenUSerProfileIfQueryParamSet = () => {
  const OpenUSerProfileIfQueryParamSetInner = ({
    userData,
  }: {
    userData: LoggedInUser;
  }) => {
    const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
    useEffect(() => {
      const query = new URLSearchParams(window.location.search);
      if (query.get("profile") == null) return;
      setModalTypeOpen({
        modalType: UserProfileModalType,
        metadata: { user: userData },
      });
    }, [setModalTypeOpen, userData]);
    return null;
  };
  const userData = useRecoilValue(loggedInUserSelector);

  if (!userData) return null;

  return <OpenUSerProfileIfQueryParamSetInner userData={userData} />;
};

export const CommonComponentsForLoggedInUsers = () => {
  return (
    <>
      <SetMixpanelData />
      <TrackStateChanges />
      <BootIntercom />
      <SetSentryData />
      <ActiveTips />
      <GPUCheck />
      <OpenUSerProfileIfQueryParamSet />
      <AblyWrapper />
      <UserProfileSettingsModal />
      <KeyboardShortcutsModal />
      <VideoModal />
      <ProjectConfigurationModal />
      <FeatureSettingsModal />
      <DashboardModal />
      <PublicModeModal />
      <TermsOfUseWizard />
      <React.Suspense fallback={null}>
        <AblyChangelogUpdateComponent />
        <AblySyncDataLibrary />
        <FrontEndVersionPoller />
        <RenameModals />
        <BufferModal />
        <DownloadCustomCRSModal />
        <AddCustomCRSModal />
        <ReloadForNewVersionModal />
        <CopyCollectionModal />
        <FilterExternalDataLayers />
        <CompareParksModal />
        <AddLayerSourceInternalModal />
        <UploadModal />
        <ComponentsPreview />
      </React.Suspense>
      <Outlet />
    </>
  );
};

function App() {
  const [token, setToken] = useRecoilState(getTokenAtom);

  useRefreshOnTabFocusAfterDormancy();

  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Open Sans:100,200,300,400,500,600,700,800,900"],
      },
    });
  }, []);
  const inChecklyMode = useMemo(() => isInChecklyMode(), []);

  useEffect(() => {
    if (inChecklyMode) {
      Mixpanel.stop_tracking();
    } else {
      Mixpanel.start_tracking();
    }
  }, [inChecklyMode]);

  const SentryRoutes = useMemo(
    () => SentryR.withSentryReactRouterV6Routing(Routes),
    [],
  );

  return (
    <>
      <SentryRoutes>
        <Route path="/public/*" element={<RedirectToPublicModeApp />} />
        <Route element={<ProtectedRoute token={token} setToken={setToken} />}>
          <Route element={<CommonComponentsForLoggedInUsers />}>
            <Route
              path="no-access"
              element={
                <Suspense fallback={<CustomerBodyPlaceholder />}>
                  <NoOrganisationPage />
                </Suspense>
              }
            />
            <Route path="organisation" element={<OrganisationPage />}>
              <Route
                path=":organisationId/:nodeId"
                element={
                  <Suspense fallback={<CustomerBodyPlaceholder />}>
                    <CustomerBody />
                  </Suspense>
                }
              />
              <Route
                path=":organisationId"
                element={
                  <Suspense fallback={<CustomerBodyPlaceholder />}>
                    <OrganisationBody />
                  </Suspense>
                }
              />
            </Route>

            <Route
              path="/projects"
              element={<Navigate replace to="/organisation" />}
            />

            <Route
              path="design/map/:customerId/:projectId/:branchId/:parkId"
              element={<ParkDesignLegacy />}
            />
            <Route
              path="design/map/:customerId/:projectId/:branchId"
              element={<ParkDesignLegacy />}
            />
            <Route
              path="design/map/:customerId/:projectId"
              element={<ParkDesignLegacy />}
            />
            <Route
              path="design/park/:customerId/:projectId/:branchId/:parkId"
              element={<ParkDesignLegacy />}
            />
            <Route
              path="design/park/:customerId/:projectId/:branchId"
              element={<ParkDesignLegacy />}
            />
            <Route
              path="design/park/:customerId/:projectId"
              element={<ParkDesignLegacy />}
            />

            <Route
              path="design/project/:organisationId/:projectId"
              element={<SelectCorrectBranch />}
            />
            <Route path="design" element={<Design />}>
              <Route
                path="project/:organisationId/:projectId/:branchId"
                element={<ParkDesignV2 />}
              />
              <Route
                path="project/:organisationId/:projectId/:branchId/:parkId"
                element={<ParkDesignV2 />}
              />
              <Route index element={<Navigate replace to="/organisation" />} />
            </Route>
            <Route path="/qgisplugin" element={<QgisPluginReadme />} />
            <Route path="/arcgis" element={<ArcgisPluginReadme />} />
            <Route path="*" element={<Navigate to="/organisation" />} />
          </Route>

          <Route element={<InternalRoute />}>
            <Route path="/permissions" element={<PermissionsPage />} />
            <Route path="/endpoints" element={<PageAPI />} />
            <Route path="/designsystem" element={<DesignSystem />} />
            <Route path="/tooling/org" element={<OrgInternalPage />} />
          </Route>
        </Route>

        <Route element={<ArchiveProjectAccess />}>
          <Route
            path="/archive/:organisationId/:projectId/:branchId/:parkId"
            element={<ArchiveProjectVersion />}
          />
        </Route>
      </SentryRoutes>
      <TrackNavigation />
      <CommonComponents />
    </>
  );
}

export default SentryR.withProfiler(App);
