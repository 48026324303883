/// <reference types="vite-plugin-svgr/client" />
import { useCallback } from "react";
import {
  ExclusionDomainUnpacked,
  ExclusionZoneFeature,
} from "../../../types/feature";
import ArrowDownIcon from "@icons/24/ArrowDown.svg?react";
import ArrowRightIcon from "@icons/24/ArrowRight.svg?react";
import Tooltip from "../../General/Tooltip";
import { SetterOrUpdater } from "recoil";
import { useTypedPath } from "../../../state/pathParams";
import { trackCanvasOption } from "../MenuTracking";
import { useSetPropertyOnProjectFeatures } from "../../../hooks/useSetPropertyOnProjectFeatures";
import { MenuItemDiv } from "../../General/Menu";
import { MenuButton } from "../../General/MenuButton";
import Checkbox from "../../General/Checkbox";
import SubstationIcon from "@icons/24/Substation.svg?react";
import TurbineIcon from "@icons/24/WindTurbine.svg?react";
import CablingIcon from "@icons/24/Cabling-2.svg?react";
import AnchorIcon from "@icons/24/Anchor.svg?react";
import {
  NamedTooltipWrapper,
  NamedTooltipContainer,
} from "../CanvasSelectOption.style";
import styled from "styled-components";
import { spaceMedium, spaceSmall } from "../../../styles/space";
import {
  exclusionDomainPack,
  exclusionDomainUnpack,
} from "../../../types/feature";

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spaceMedium};
  svg {
    height: 1.5rem;
    width: 1.5rem;
  }

  svg.disabled {
    opacity: 0.3;
  }
`;

const CheckboxLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spaceSmall};
  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
`;

export default function ExclusionTypeSelector({
  divisions,
}: {
  divisions: ExclusionZoneFeature[];
  setCurrentSelectionArray: SetterOrUpdater<string[]>;
}) {
  const { projectId, branchId } = useTypedPath("projectId", "branchId");
  const setProperties = useSetPropertyOnProjectFeatures(() => {});

  // OR together all domtains to get what we render
  const domain = divisions
    .map((f) => exclusionDomainUnpack(f.properties.domain))
    .reduce((a, e) => ({
      turbine: a.turbine || e.turbine,
      cable: a.cable || e.cable,
      substation: a.substation || e.substation,
      anchor: a.anchor || e.anchor,
    }));

  const onSelectItem = useCallback(
    (field: keyof ExclusionDomainUnpacked, checked: boolean) => {
      trackCanvasOption("change-zone-type", {
        projectId,
        branchId,
      });

      setProperties(
        divisions.map((d) => d.id),
        {
          domain: exclusionDomainPack({
            ...domain,
            [field]: checked,
          }),
        },
      );
    },
    [branchId, divisions, domain, projectId, setProperties],
  );

  return (
    <>
      <Tooltip text="Change what should be excluded">
        <NamedTooltipContainer>
          <NamedTooltipWrapper>Exclude</NamedTooltipWrapper>
          <MenuButton
            icon={<ArrowRightIcon />}
            iconOpen={<ArrowDownIcon />}
            buttonStyle={{ border: "none", flexDirection: "row-reverse" }}
            buttonType="dropdown"
            buttonText={
              <IconWrapper>
                <TurbineIcon className={domain.turbine ? "" : "disabled"} />
                <CablingIcon className={domain.cable ? "" : "disabled"} />
                <SubstationIcon
                  className={domain.substation ? "" : "disabled"}
                />
                <AnchorIcon className={domain.anchor ? "" : "disabled"} />
              </IconWrapper>
            }
            offset={["0.0rem", "0.6rem"]}
          >
            <MenuItemDiv nohover>
              <Checkbox
                checked={domain.turbine}
                label={
                  <CheckboxLabel>
                    <TurbineIcon />
                    Turbines
                  </CheckboxLabel>
                }
                labelPlacement="after"
                onChange={(e) => {
                  onSelectItem("turbine", e.target.checked);
                }}
              />
            </MenuItemDiv>

            <MenuItemDiv nohover>
              <Checkbox
                checked={domain.cable}
                label={
                  <CheckboxLabel>
                    <CablingIcon />
                    Cables
                  </CheckboxLabel>
                }
                labelPlacement="after"
                onChange={(e) => {
                  onSelectItem("cable", e.target.checked);
                }}
              />
            </MenuItemDiv>

            <MenuItemDiv nohover>
              <Checkbox
                checked={domain.substation}
                label={
                  <CheckboxLabel>
                    <SubstationIcon />
                    Substations
                  </CheckboxLabel>
                }
                labelPlacement="after"
                onChange={(e) => {
                  onSelectItem("substation", e.target.checked);
                }}
              />
            </MenuItemDiv>
            <MenuItemDiv nohover>
              <Checkbox
                checked={domain.anchor}
                label={
                  <CheckboxLabel>
                    <AnchorIcon />
                    Anchors
                  </CheckboxLabel>
                }
                labelPlacement="after"
                onChange={(e) => {
                  onSelectItem("anchor", e.target.checked);
                }}
              />
            </MenuItemDiv>
          </MenuButton>
        </NamedTooltipContainer>
      </Tooltip>
    </>
  );
}
