import styled from "styled-components";
import { Row } from "../../General/Layout";
import { IconBtn } from "../../General/Icons";
import { colors } from "../../../styles/colors";
import { spaceTiny } from "../../../styles/space";

export const ControlRow = styled(Row)<{ enabled: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${IconBtn} {
    padding: ${spaceTiny};
    svg {
      path {
        stroke: ${colors.primary};
      }
    }
  }
`;
