import { z } from "zod";

export const _Thread = z.object({
  message: z.string(),
  threadTimestamp: z.number(),
  nodeId: z.string(),
  branchId: z.string(),
  threadId: z.string(),
  replies: z.number(),
  userId: z.string(),
  reactions: z.number(),
  resolved: z.boolean(),
  featureId: z.string().nullish(),
  mapPosition: z.tuple([z.number(), z.number()]).nullable().optional(),
});
export type Thread = z.infer<typeof _Thread>;

export const _Reply = z.object({
  message: z.string(),
  replyTimestamp: z.number(),
  nodeId: z.string(),
  branchId: z.string(),
  threadId: z.string(),
  replyId: z.string(),
  userId: z.string(),
  reactions: z.number(),
});
export type Reply = z.infer<typeof _Reply>;

export const _ReactionType = z.literal("like");
export type ReactionType = z.infer<typeof _ReactionType>;

export const _Reaction = z.object({
  reactionId: z.string(),
  reaction: _ReactionType,
  nodeId: z.string(),
  branchId: z.string(),
  threadId: z.string(),
  reactionTimestamp: z.number().optional(),
  userId: z.string(),
  replyId: z.string().optional(),
});

export type Reaction = z.infer<typeof _Reaction>;

export type ReadComments = { threadId: string; replies: string[] }[];

export const _ThreadFollow = z.object({
  branchId: z.string(),
  threadId: z.string(),
  follow: z.boolean(),
});
export type ThreadFollow = z.infer<typeof _ThreadFollow>;
