import React, { RefObject, useCallback } from "react";
import { DropTargetHookSpec } from "react-dnd/src/hooks/types";
import { FeatureIds, ProjectElementFolderType } from "./service";
import {
  DropCollectedProps,
  ElementTreeNode,
  ElementTreeFolder,
  ElementTreeRoot,
} from "./types";
import { dedup } from "utils/utils";
import { useProjectElementsFoldersCrud } from "./useProjectElementsFoldersCrud";
import { ProjectElementsContextType } from "./ProjectElementsContext";
import {
  cursorIsInBottomHalfOfElement,
  findCursorPositionInElement,
} from "utils/dragNDropUtils";
import { getTreeRoot } from "./utils";

type DropLeaf = Required<
  DropTargetHookSpec<
    ElementTreeNode[], // Items that are dragged around
    { handled: boolean }, // Return type of the drop handler
    DropCollectedProps // not sure about collect
  >
>;

/**
 * The number of pixels on the top/bottom of a folder div where we register a
 * drop as "above" or "beneath" the folder, and not inside the folder.
 */
const PLACEMENT_BAR_ZONE_IN_PIXELS = 4;

/**
 * `useDrag` and `useDrop` functions for hover, drop, and so on, for leaf
 * nodes, i.e. feature or parks.
 */
export const useDnDLeafHooks = ({
  node,
  divRef,
  setHoverState,
  reorderTopLevel,
  updateFolder,
  onError,
}: {
  node: ElementTreeNode;
  divRef: RefObject<HTMLDivElement>;
  setHoverState: React.Dispatch<"bottom" | "top" | undefined>;
  reorderTopLevel: Reorder;
  updateFolder: Update;
  onError?: (e: Error) => void;
}) => {
  const hover = useCallback<DropLeaf["hover"]>(
    (_hoveredItem, monitor) => {
      if (!monitor.isOver({ shallow: true })) {
        return setHoverState(undefined);
      }
      const isBottom = cursorIsInBottomHalfOfElement(
        divRef.current,
        monitor.getClientOffset(),
      );
      setHoverState(isBottom ? "bottom" : "top");
    },
    [divRef, setHoverState],
  );

  const drop: DropLeaf["drop"] = useCallback(
    (nodes, monitor) => {
      if (monitor.getDropResult()?.handled) return;
      const bottom = cursorIsInBottomHalfOfElement(
        divRef.current,
        monitor.getClientOffset(),
      );
      const opt = bottom
        ? {
            after: node.id,
          }
        : { before: node.id };

      try {
        if (node.parent.type === "root") {
          moveToToplevel(nodes, updateFolder, reorderTopLevel, opt);
        } else {
          moveToFolder(nodes, node.parent, updateFolder, opt);
        }
      } catch (e) {
        if (e instanceof Error) onError?.(e);
        else throw e;
      }
      return { handled: true };
    },
    [divRef, node.id, node.parent, onError, reorderTopLevel, updateFolder],
  );

  return {
    hover,
    drop,
  };
};

export const useDnDNodeHooks = ({
  node,
  divRef,
  setHoverState,
  reorderTopLevel,
  updateFolder,
  onError,
}: {
  node: ElementTreeFolder;
  divRef: RefObject<HTMLDivElement>;
  setHoverState: React.Dispatch<"bottom" | "middle" | "top" | undefined>;
  reorderTopLevel: Reorder;
  updateFolder: Update;
  onError?: (s: Error) => void;
}) => {
  const hover = useCallback<DropLeaf["hover"]>(
    (_nodes, monitor) => {
      if (!monitor.isOver({ shallow: true })) {
        return setHoverState(undefined);
      }
      const zone = findCursorPositionInElement(
        divRef.current,
        monitor.getClientOffset(),
        PLACEMENT_BAR_ZONE_IN_PIXELS,
      );
      setHoverState(zone);
    },
    [divRef, setHoverState],
  );

  const drop: DropLeaf["drop"] = useCallback(
    (nodes, monitor) => {
      if (monitor.getDropResult()?.handled) return;
      const zone = findCursorPositionInElement(
        divRef.current,
        monitor.getClientOffset(),
        PLACEMENT_BAR_ZONE_IN_PIXELS,
      );

      let opt: Opts = { start: true };
      let target: ElementTreeFolder | ElementTreeRoot = node.parent;
      switch (zone) {
        case "top":
          opt = { before: node.id };
          break;
        case "middle":
          target = node; // put it in the current folder instead
          break;
        case "bottom":
          opt = { after: node.id };
          break;
      }

      try {
        if (target.type === "root") {
          moveToToplevel(nodes, updateFolder, reorderTopLevel, opt);
        } else {
          // Edge case: dropping a folder on itself should not do anything.
          const nodes_ = nodes.filter((n) => n.id !== node.id);
          if (nodes_.length) moveToFolder(nodes_, target, updateFolder, opt);
        }
      } catch (e) {
        if (e instanceof Error) onError?.(e);
        else throw e;
      }
      return { handled: true };
    },
    [divRef, node, reorderTopLevel, onError, updateFolder],
  );

  return {
    hover,
    drop,
  };
};

/**
 * Special case for the drop zone at the bottom of the elements list.
 */
export const useDnDRootHooks = ({
  setHoverState,
  reorderTopLevel,
  updateFolder,
}: {
  setHoverState: React.Dispatch<boolean>;
  reorderTopLevel: Reorder;
  updateFolder: Update;
}) => {
  const hover = useCallback<DropLeaf["hover"]>(
    (_nodes, monitor) => {
      setHoverState(monitor.isOver({ shallow: true }));
    },
    [setHoverState],
  );

  const drop: DropLeaf["drop"] = useCallback(
    (nodes, monitor) => {
      if (monitor.getDropResult()?.handled) return;
      moveToToplevel(nodes, updateFolder, reorderTopLevel, {
        end: true,
      });
      setHoverState(false);
      return { handled: true };
    },
    [reorderTopLevel, setHoverState, updateFolder],
  );

  return {
    hover,
    drop,
  };
};

/**
 * Options for {@link moveFolderToFolder} and similar functions.
 * Controls where a thing is supposed to be placed.
 */
type Opts =
  | { start: true }
  | { end: true }
  | { before: string }
  | { after: string };

type Update = ReturnType<typeof useProjectElementsFoldersCrud>["update"];
type Reorder = ProjectElementsContextType["reorderTopLevel"];

/**
 * Figure out where to insert the given id.
 */
function getInsertIndex(
  featureIds: ProjectElementFolderType["featureIds"],
  opts: Opts = { end: true },
): number {
  if ("start" in opts) return 0;
  if ("before" in opts) {
    const ind = featureIds.findIndex((o) => o.id === opts.before);
    if (ind === -1) return featureIds.length;
    return ind;
  }
  if ("after" in opts) {
    const ind = featureIds.findIndex((o) => o.id === opts.after);
    if (ind === -1) return featureIds.length;
    return ind + 1;
  }
  return featureIds.length;
}

export function moveToFolder(
  nodes: ElementTreeNode[],
  target: ElementTreeFolder,
  updateFolder: Update,
  opts?: Opts,
) {
  // Check that targets parent (or parent's parent, etc) is the folder.
  // This means that you try to move a folder into a child of itself; makes no sense!
  const illegalIds = new Set(
    nodes.filter((n) => n.type === "folder").map((n) => n.id),
  );

  {
    let p: ElementTreeFolder | ElementTreeRoot = target;
    while (p.type !== "root") {
      if (illegalIds.has(p.id))
        throw new Error("Cannot move a folder to within itself");
      p = p.parent;
    }
  }

  const nodeIds = nodes.map((n) => n.id);
  const nodeSet = new Set(nodeIds);

  const parents: ElementTreeFolder[] = dedup(
    nodes
      .map((n) => n.parent)
      .filter((n): n is ElementTreeFolder => n.type === "folder"),
    (folder) => folder.id,
  );

  for (const parent of parents) {
    if (parent.id === target.id) continue;
    const featureIds = parent.folder.featureIds.filter(
      ({ id }) => !nodeSet.has(id),
    );
    updateFolder({
      ...parent.folder,
      featureIds,
    });
  }

  const newIds = target.children
    .filter((n) => !nodeSet.has(n.id))
    .map<FeatureIds[number]>((o) => ({
      type: o.type,
      id: o.id,
    }));
  const index = getInsertIndex(newIds, opts);

  const featureIds = dedup(
    newIds
      .slice(0, index)
      .concat(
        nodes.map((n) => ({
          type: n.type,
          id: n.id,
        })),
      )
      .concat(newIds.slice(index)),
    ({ id }) => id,
  );
  updateFolder({
    ...target.folder,
    featureIds,
  });
}

/**
 * The top level is handled separately, because ordering is handled separately there.
 */
export function moveToToplevel(
  nodes: ElementTreeNode[],
  updateFolder: Update,
  reorderTopLevel: Reorder,
  opts?: Opts,
) {
  if (nodes.length === 0) throw new Error("empty nodes list");
  const nodeIds = new Set(nodes.map((n) => n.id));
  const parents = dedup(
    nodes
      .map((n) => n.parent)
      .filter((n): n is ElementTreeFolder => n.type === "folder"),
    (f) => f.id,
  );
  if (1 < parents.length) {
    // NOTE: we could support this, but we don't right now.
    throw new Error(
      "Tried to move features from many folders at once. Not supported.",
    );
  }

  if (parents.length) {
    const from = parents[0].folder; // NOTE: we know we have =1 at this point.
    updateFolder({
      ...from,
      featureIds: from.featureIds.filter(({ id }) => !nodeIds.has(id)),
    });
  }

  const root = getTreeRoot(nodes[0]);
  // NOTE: no index compensation, because this happens in `reorderTopLevel`
  const topIds = root.children.map<FeatureIds[number]>((o) => ({
    type: o.type,
    id: o.id,
  }));
  const index = getInsertIndex(topIds, opts);

  reorderTopLevel(
    nodes.map((n) => ({ type: n.type, id: n.id })),
    index,
  );
}
