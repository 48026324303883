import React, { useMemo } from "react";
import { useRecoilValue } from "recoil";
import DropdownButton from "components/General/Dropdown/DropdownButton";
import { DropDownItem } from "components/General/Dropdown/DropdownItems";
import ProjectIcon from "@icons/24/ProjectGlobe.svg";
import LibraryIcon from "@icons/24/Library.svg";
import { analysisResourceWithAccessOnNodeState } from "components/Organisation/Library/state";
import { configurationsAtomFamily } from "state/configuration";
import styled, { CSSProperties } from "styled-components";
import { IconREMSize, typography } from "styles/typography";
import { Configuration } from "services/configurationService";
import ProductionIcon from "@icons/24/SideBarIcons/Production.svg";
import { colors } from "styles/colors";

const DropDownTextWrapper = styled.div`
  ${typography.body}
  color: ${colors.textPrimary};
  margin: 0;
  overflow-x: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 17rem;
  text-align: left;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const DropDownText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SelectAnalysisDropDownCustom = ({
  selectedConfig,
  onSelectItem,
  projectId,
  style,
  className,
}: {
  selectedConfig?: Configuration;
  projectId: string;
  onSelectItem: (val: string) => void;
  style?: CSSProperties;
  className?: string;
}) => {
  const libraryAnalysis = useRecoilValue(
    analysisResourceWithAccessOnNodeState({ nodeId: projectId }),
  ).map((c) => c.config);
  const projectAnalysis = useRecoilValue(
    configurationsAtomFamily({ nodeId: projectId }),
  );

  const configItems = useMemo<DropDownItem[]>(() => {
    return [
      ...projectAnalysis.map((c) => ({
        value: c.id,
        name: c.name ?? "Untitled",
        icon: (
          <IconREMSize width={1.4} height={1.4}>
            <ProjectIcon />
          </IconREMSize>
        ),
      })),
      ...libraryAnalysis.map((c) => ({
        value: c.id,
        name: c.name ?? "Untitled",
        icon: (
          <IconREMSize width={1.4} height={1.4}>
            <LibraryIcon />
          </IconREMSize>
        ),
      })),
    ];
  }, [libraryAnalysis, projectAnalysis]);

  return (
    <DropdownButton
      className={className}
      items={configItems}
      renderText={(text) => (
        <DropDownTextWrapper>
          <IconREMSize width={1.4} height={1.4}>
            <ProductionIcon />
          </IconREMSize>
          <DropDownText>{text}</DropDownText>
        </DropDownTextWrapper>
      )}
      size={"small"}
      listContainerStyle={style}
      style={style}
      onSelectItem={onSelectItem}
      buttonText={
        [...projectAnalysis, ...libraryAnalysis].find(
          (c) => c.id === selectedConfig?.id,
        )?.name ?? "-- select an option --"
      }
      selectedItemValue={selectedConfig?.id}
    />
  );
};

export default SelectAnalysisDropDownCustom;
