import { nodesWithAccessToTurbine } from "./../../../Library/state";
import { useRecoilCallback } from "recoil";

import {
  addOrUpdateTurbineResourceOnNode,
  deleteTurbineResourceOnNode,
} from "components/Organisation/Library/service";
import { turbineResourceWithAccessOnNodeState } from "components/Organisation/Library/state";
import { useTypedPath } from "state/pathParams";
import {
  libraryTurbinesOnNodeState,
  libraryTurbinesRefreshAtom,
  turbineTypeUsageAtomFamily,
} from "state/turbines";
import { userAllNodesAccessAtom } from "state/user";

const useNodeTurbineResourcesCrud = () => {
  const { organisationId } = useTypedPath("organisationId");

  const addOrUpdate = useRecoilCallback(
    ({ snapshot, set, refresh }) =>
      async (nodeId: string, turbineId: string) => {
        const fallback = await snapshot.getPromise(
          turbineResourceWithAccessOnNodeState({ nodeId }),
        );
        const allNodeIds = await snapshot.getPromise(userAllNodesAccessAtom);

        return addOrUpdateTurbineResourceOnNode(nodeId, turbineId)
          .then(async () => {
            Object.keys(allNodeIds.node_access).map((nodeId) =>
              refresh(turbineResourceWithAccessOnNodeState({ nodeId })),
            );
            set(libraryTurbinesRefreshAtom, (curr) => curr + 1);
            refresh(
              nodesWithAccessToTurbine({
                organisationId,
                resourceId: turbineId,
              }),
            );
            refresh(
              libraryTurbinesOnNodeState({
                nodeId,
              }),
            );
          })
          .catch((e) => {
            set(turbineResourceWithAccessOnNodeState({ nodeId }), fallback);
            throw e;
          });
      },
    [organisationId],
  );

  const remove = useRecoilCallback(
    ({ snapshot, set, refresh }) =>
      async (nodeId: string, resourceId: string) => {
        const fallback = await snapshot.getPromise(
          turbineResourceWithAccessOnNodeState({ nodeId }),
        );
        const allNodeIds = await snapshot.getPromise(userAllNodesAccessAtom);
        const usage = await snapshot.getPromise(
          turbineTypeUsageAtomFamily({
            nodeId,
            turbineTypeId: resourceId,
          }),
        );
        if (
          usage.length === 0 ||
          window.confirm(
            `Turbine type is currently being used in ${usage.length} turbines, are you sure you want to remove access to it?`,
          )
        ) {
          return deleteTurbineResourceOnNode(nodeId, resourceId)
            .then(() => {
              Object.keys(allNodeIds.node_access).map((nodeId) =>
                refresh(turbineResourceWithAccessOnNodeState({ nodeId })),
              );
              set(libraryTurbinesRefreshAtom, (curr) => curr + 1);
              refresh(nodesWithAccessToTurbine({ organisationId, resourceId }));
              refresh(
                libraryTurbinesOnNodeState({
                  nodeId,
                }),
              );
            })
            .catch((e) => {
              set(turbineResourceWithAccessOnNodeState({ nodeId }), fallback);
              throw e;
            });
        }
      },
    [organisationId],
  );

  return { addOrUpdate, remove };
};

export default useNodeTurbineResourcesCrud;
