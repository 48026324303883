/// <reference types="vite-plugin-svgr/client" />
import { useCallback } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import styled from "styled-components";
import CloseIcon from "@icons/24/Close.svg?react";
import {
  activeFiltersAtom,
  FilterType,
  LayerFilterState,
  nrActiveFiltersSelector,
} from "../../layer-filter-state";
import { colors } from "../../../../styles/colors";
import {
  selectedSourceNameAtom,
  SourceName,
} from "../../../LayerList/LayerSettings/state";
import {
  findSourceWithName,
  getExternalDataSourcesSelector,
} from "../../../../state/layer";
import Button from "../../../General/Button";

const ActiveFilterPillsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 0 1rem;
`;

const ActiveFilterPill = styled.div<{ filterType: FilterType }>`
  display: flex;
  align-items: center;
  padding: 0.2rem 1.5rem;
  gap: 1rem;
  border-radius: 20px;
  cursor: pointer;

  ${({ filterType }) => {
    if (filterType === "source") {
      return `
        background-color: ${colors.subArea};
        color: white;
        svg {
          path {
            stroke: white;
          }
        }
      `;
    }

    if (filterType === "tags") {
      return `
        background-color: ${colors.purple};
        color: white;
        svg {
          path {
            stroke: white;
          }
        }
      `;
    }

    if (filterType === "country") {
      return `
        background-color: ${colors.port};
        color: white;
        text-transform: capitalize;
        svg {
          path {
            stroke: white;
          }
        }
      `;
    }

    if (filterType === "sourceTypes") {
      return `
        background-color: ${colors.slackRegion};
        color: white;
        svg {
          path {
            stroke: black;
          }
        }
      `;
    }

    if (filterType === "overlappingGeometry") {
      return `
        background-color: ${colors.yellow};
        color: black;
        svg {
          path {
            stroke: black;
          }
        }
      `;
    }

    if (filterType === "filterOnBBOX") {
      return `
        background-color: ${colors.orange};
        color: white;
        svg {
          path {
            stroke: black;
          }
        }
      `;
    }
  }}
`;

const ActiveFilterPills = () => {
  const [activeFilters, setActiveFilters] = useRecoilState(activeFiltersAtom);
  const resetActiveFilters = useResetRecoilState(activeFiltersAtom);
  const nrActiveFilters = useRecoilValue(nrActiveFiltersSelector);
  const sources = useRecoilValue(getExternalDataSourcesSelector);
  const { language: selectedSourceName } = useRecoilValue(
    selectedSourceNameAtom,
  );
  const onClick = useCallback(
    (filter: string | { id: string; name: string }, category: FilterType) => {
      setActiveFilters((curr) => {
        return {
          ...curr,
          [category]: curr[category].filter((f) => f !== filter),
        };
      });
    },
    [setActiveFilters],
  );

  const onClearFilterCategory = useCallback(
    (category: FilterType, value: any) => {
      setActiveFilters((curr) => {
        return {
          ...curr,
          [category]: value,
        };
      });
    },
    [setActiveFilters],
  );

  if (nrActiveFilters === 0) {
    return null;
  }

  return (
    <ActiveFilterPillsWrapper>
      {Object.entries<LayerFilterState[keyof LayerFilterState]>(
        activeFilters,
      ).map(([category, filters]) => {
        if (Array.isArray(filters)) {
          return filters.map((filter) => {
            const source =
              category === "source"
                ? findSourceWithName(filter, sources)
                : undefined;

            const title = source
              ? selectedSourceName === SourceName.Original &&
                source.originalName
                ? source.originalName
                : filter
              : filter;

            const key = source ? source.id : filter;
            return (
              <ActiveFilterPill
                key={key}
                filterType={category as FilterType}
                title="Remove filter"
                onClick={() => {
                  onClick(filter, category as FilterType);
                }}
              >
                <span>{title}</span>
                <CloseIcon style={{ width: "8px", height: "8px" }} />
              </ActiveFilterPill>
            );
          });
        } else if (category === "overlappingGeometry" && Boolean(filters)) {
          return (
            <ActiveFilterPill
              key={category}
              filterType={category as FilterType}
              title="Remove filter"
              onClick={() => {
                onClearFilterCategory(category as FilterType, undefined);
              }}
            >
              <span>Overlap</span>
              <CloseIcon style={{ width: "8px", height: "8px" }} />
            </ActiveFilterPill>
          );
        } else if (category === "filterOnBBOX" && filters === true) {
          return (
            <ActiveFilterPill
              key={category}
              filterType={category as FilterType}
              title="Remove filter"
              onClick={() => {
                onClearFilterCategory(category as FilterType, false);
              }}
            >
              <span>Filter layers using map view</span>
              <CloseIcon style={{ width: "8px", height: "8px" }} />
            </ActiveFilterPill>
          );
        } else {
          return null;
        }
      })}
      <Button
        size="small"
        buttonType="text"
        text="Clear all"
        onClick={resetActiveFilters}
      />
    </ActiveFilterPillsWrapper>
  );
};

export default ActiveFilterPills;
