import { useEffect } from "react";
import { selectorFamily, useRecoilValue } from "recoil";
import { getOverlappingSubstationsSelector } from "state/layout";
import { ValidationWarningTypes } from "./utils";
import { useValidationWarnings } from "./ValidationWarnings";
import { turbineSourceId } from "components/Mapbox/constants";
import { branchIdSelector } from "state/pathParams";

export const substationOverlapWarningSelectorFamily = selectorFamily<
  | {
      type: ValidationWarningTypes.SubstationOverlap;
      featureIds: string[];
      parkId: string;
    }
  | undefined,
  {
    parkId: string;
    branchId: string;
  }
>({
  key: "substationOverlapWarningSelectorFamily",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const overlappingSubstations = get(
        getOverlappingSubstationsSelector({ parkId, branchId }),
      );

      return overlappingSubstations.length === 0
        ? undefined
        : {
            type: ValidationWarningTypes.SubstationOverlap,
            featureIds: overlappingSubstations.map((s) => s.id),
            parkId: parkId,
          };
    },
});

export const SubstationOverlap = ({
  parkId,
  map,
  source = turbineSourceId,
}: {
  parkId: string;
  map: mapboxgl.Map;
  source?: string;
}) => {
  const branchId = useRecoilValue(branchIdSelector);
  const overlappingSubstationsWarning = useRecoilValue(
    substationOverlapWarningSelectorFamily({
      parkId: parkId,
      branchId: branchId ?? "",
    }),
  );

  const { warn, remove } = useValidationWarnings();
  useEffect(() => {
    if (!overlappingSubstationsWarning) return;
    warn(overlappingSubstationsWarning);
    if (map.getSource(source))
      for (const id of overlappingSubstationsWarning.featureIds)
        map.setFeatureState({ source, id: id }, { overlap: true });

    return () => {
      remove(ValidationWarningTypes.SubstationOverlap);
      if (map.getSource(source))
        for (const id of overlappingSubstationsWarning.featureIds)
          map.removeFeatureState({ source, id }, "overlap");
    };
  }, [map, overlappingSubstationsWarning, remove, source, warn]);

  return null;
};
