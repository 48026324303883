import { LayerCollectionVersionConfigTypes } from "../components/LayerList/LayerSettings/service";
import { SnapshotMeta } from "./api";

export enum EventActions {
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  CREATE = "CREATE",
}

type Snapshot = {
  version: number;
  action: EventActions;
  meta: {
    count: number;
  };
  author?: string;
  title?: string;
};

export type SnapshotEvent = Snapshot &
  SnapshotMeta & {
    type: "branch";
  };

export type CostEvent = Snapshot & {
  type: "cost";
};

export type CableTypeEvent = Snapshot & {
  type: "cable";
};
export type MooringLineTypeEvent = Snapshot & {
  type: "mooringLine";
};
export type TurbineEvent = Snapshot & {
  type: "turbine";
};

export type KeyEvent = Snapshot & {
  type: "keySnapshot";
};

export type ProjectElementFolderEvent = Snapshot & {
  type: "projectElementFolder";
};

export type LayerCollectionEvent = Snapshot & {
  type: LayerCollectionVersionConfigTypes;
};

export type DateDividerEvent = {
  type: "date";
  date: string;
};

export type GeneralEvent =
  | TurbineEvent
  | CableTypeEvent
  | MooringLineTypeEvent
  | CostEvent
  | KeyEvent
  | LayerCollectionEvent
  | ProjectElementFolderEvent;
export type VersionEvent = SnapshotEvent | GeneralEvent;

export type GeneralEventGroup = {
  type: "general";
  events: GeneralEvent[];
  date: string;
};
export type GroupedEvents =
  | GeneralEventGroup
  | SnapshotEvent
  | DateDividerEvent;
