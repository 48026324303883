import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import ConfigurationIcon from "@icons/14/Configuration.svg";
import Button from "components/General/Button";
import { Label } from "components/General/Form";
import { Row } from "components/General/Layout";
import { ProjectConfigModalTypeV2 } from "state/configuration";
import { modalTypeOpenAtom } from "state/modal";
import { getBranchSelectorFamily, useUpdateBranch } from "state/timeline";
import {
  selectedWindConfigurationAtomFamily,
  windConfigurationUsageRefresh,
} from "state/windSourceConfiguration";
import { projectIdSelector, branchIdSelector } from "state/pathParams";
import SelectWindDropDownCustom from "./SelectWindDropDown";
import { InputTitle } from "components/General/GeneralSideModals.style";

const Inner = ({
  projectId,
  branchId,
}: {
  projectId: string;
  branchId: string;
}) => {
  const liveBranchMeta = useRecoilValue(
    getBranchSelectorFamily({
      projectId,
      branchId,
    }),
  );
  const setModalType = useSetRecoilState(modalTypeOpenAtom);
  const selectedWindConfig = useRecoilValue(
    selectedWindConfigurationAtomFamily({ projectId, branchId }),
  );
  const updateBranch = useUpdateBranch();
  const resetWindConfUsage = useRecoilCallback(
    ({ set }) =>
      async (windConfigurationId: string) => {
        if (!projectId) return;
        set(
          windConfigurationUsageRefresh({
            nodeId: projectId,
            windConfigurationId,
          }),
          (curr) => curr + 1,
        );
      },
    [projectId],
  );

  return (
    <Label>
      <InputTitle>Wind data configuration</InputTitle>
      <Row style={{ margin: 0 }}>
        <Row style={{ flex: 1 }}>
          <SelectWindDropDownCustom
            projectId={projectId}
            style={{ flex: 1 }}
            onSelectItem={(item) => {
              if (!liveBranchMeta) return;
              liveBranchMeta.windConfigurationId &&
                resetWindConfUsage(liveBranchMeta.windConfigurationId);
              resetWindConfUsage(item);
              updateBranch({
                ...liveBranchMeta,
                windConfigurationId: item,
              });
            }}
            selectedConfig={selectedWindConfig}
          />
        </Row>

        <Button
          buttonType="secondary"
          icon={<ConfigurationIcon />}
          size="small"
          onClick={() => {
            setModalType({
              modalType: ProjectConfigModalTypeV2,
              metadata: {
                selectedMenuId: "wind",
                selectedConfigId: selectedWindConfig?.id,
              },
            });
          }}
        />
      </Row>
    </Label>
  );
};

export const SelectWindConfiguration = () => {
  const projectId = useRecoilValue(projectIdSelector);
  const branchId = useRecoilValue(branchIdSelector);
  if (!projectId || !branchId) return null;
  return <Inner projectId={projectId} branchId={branchId} />;
};
