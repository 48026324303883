import { RecoilState, Snapshot } from "recoil";
import { isDefined } from "../../utils/predicates";
import { omitFields } from "../../utils/utils";
import { DnDElement, DnDRow } from "../General/DnDGrid/types";
import { CableMatrixMapWidget } from "./Widgets/CableMatrixMapWidget";
import { CableMatrixWidget } from "./Widgets/CableMatrixWidget";
import { CableUsageWidget } from "./Widgets/CableUsageWidget";
import { CapexPieChartWidget } from "./Widgets/CapexPieChartWidget";
import { CapexTableWidget } from "./Widgets/CapexTableWidget";
import { CumulativeGraphWidget } from "./Widgets/CumulativeProductionWidget";
import { EnergyWidget } from "./Widgets/EnergyWidget";
import { InputWidget } from "./Widgets/InputWidget";
import { LCoEWidget } from "./Widgets/LCOEWidget";
import { LossWidget } from "./Widgets/LossWidget";
import { MapWidget } from "./Widgets/MapWidget";
import { MonthlyProductionWidget } from "./Widgets/MonthlyProductionWidget";
import { MooringLineAttachmentsWidget } from "./Widgets/MooringLineAttachmentsWidget";
import { MooringLinesWidget } from "./Widgets/MooringLinesWidget";
import { ParkWidget } from "./Widgets/ParkWidget";
import { TextWidget } from "./Widgets/TextWidget";
import { TurbineListWidget } from "./Widgets/TurbineListWidget";
import { WindRoseWidget } from "./Widgets/WindRoseWidget";
import { Dashboard, DashboardSerialize, WidgetId } from "./types";
import { MooringLineAnchorsWidget } from "./Widgets/MooringLineAnchorsWidget";
import { FoundationKeyStatisticsWidget } from "./Widgets/FoundationKeyStatisticsWidget";
import { FoundationTotalsWidget } from "./Widgets/FoundationTotalsWidget";
import { ThreeDFoundationWidget } from "./Widgets/ThreeDFoundationWidget";
import { FoundationListWidget } from "./Widgets/FoundationListWidget";
import { WindDistributionWidget } from "./Widgets/WindDistributionWidget";
import { ExportCableLoadingWidget } from "./Widgets/ExportCableLoadingWidget";
import { ExportSystemVoltageWidget } from "./Widgets/ExportCableVoltageWidget";
import { AEPDistributionWidget } from "./Widgets/AEPDistributionWidget";
import { ParkLifeCashFlowWidget } from "./Widgets/ParkLifeCashFlowWidget";
import { InterArrayLossGraphWidget } from "./Widgets/InterArrayLossGraphWidget";
import { IRRWidget } from "./Widgets/IRRWidget";
import { CostInputWidget } from "./Widgets/CostInputWidget";
import { OtherExpendituresInputWidget } from "./Widgets/OtherExpendituresInputWidget";
import { CapexInputWidget } from "./Widgets/CapexInputWidget";
import { ExportCablesWidget } from "components/Dashboard/Widgets/ExportCablesWidget";

export const WidgetToComponent: Record<
  WidgetId,
  undefined | React.ComponentType
> = {
  "Lcoe table": LCoEWidget,
  "IRR table": IRRWidget,
  Energy: EnergyWidget,
  Foundations: FoundationListWidget,
  "Foundation key statistics": FoundationKeyStatisticsWidget,
  "Foundation totals": FoundationTotalsWidget,
  Park: ParkWidget,
  "Wind rose": WindRoseWidget,
  "Capex input": CapexInputWidget,
  "Capex table": CapexTableWidget,
  "Capex pie chart": CapexPieChartWidget,
  "Cost input": CostInputWidget,
  "Park life cash flow": ParkLifeCashFlowWidget,
  "Monthly graph": MonthlyProductionWidget,
  "Wind speed distribution": WindDistributionWidget,
  "Annual variability": AEPDistributionWidget,
  Map: MapWidget,
  "Cumulative graph": CumulativeGraphWidget,
  Turbines: TurbineListWidget,
  Input: InputWidget,
  Losses: LossWidget,
  Cabling: CableUsageWidget,
  "Cable matrix": CableMatrixWidget,
  "Export cables": ExportCablesWidget,
  "Cable matrix map": CableMatrixMapWidget,
  Text: TextWidget,
  "Mooring lines": MooringLinesWidget,
  "Mooring line attachments": MooringLineAttachmentsWidget,
  "Mooring line anchors": MooringLineAnchorsWidget,
  "Other expenditures input": OtherExpendituresInputWidget,
  "3D-foundation": ThreeDFoundationWidget,
  "Export cable loading": ExportCableLoadingWidget,
  "Export system voltage": ExportSystemVoltageWidget,
  "Inter array loss graph": InterArrayLossGraphWidget,
};

/** Formats a number `12345.6789` to e.g. `12,345.68`, depending on locale. */
export const kmStyle = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const dndEntry = (id: WidgetId): undefined | DnDElement => {
  const Component = WidgetToComponent[id];
  if (Component === undefined) return undefined;
  return {
    id,
    component: <Component />,
  };
};

export const deserializeDashboard = (ds: DashboardSerialize): Dashboard => {
  return {
    ...ds,
    widgetState: ds.widgetState ?? {},
    rows: deserializeDashboardRows(ds.rows),
  };
};

export const serializeDashboardRows = (
  rows: Dashboard["rows"],
): DashboardSerialize["rows"] => {
  return (
    rows?.map((row) => ({
      ...row,
      elements: row.elements.map((el) => omitFields(el, "component")),
    })) ?? []
  );
};

export const deserializeDashboardRows = (
  rows: DashboardSerialize["rows"],
): Dashboard["rows"] => {
  return (
    rows?.map((row) => ({
      ...row,
      elements: row.elements
        .map((el) => dndEntry(el.id as WidgetId))
        .filter(isDefined),
    })) ?? []
  );
};

export const serializeDashboard = (
  dashboard: Dashboard,
): DashboardSerialize => {
  return {
    ...dashboard,
    rows: serializeDashboardRows(dashboard.rows),
  };
};

export const makeDefaultDashboard = (): DnDRow[] => {
  return [
    {
      id: "row-1",
      height: 100,
      elements: [
        dndEntry("Lcoe table"),
        dndEntry("Park"),
        dndEntry("Energy"),
      ].filter(isDefined),
    },
    {
      id: "row-2",
      height: 100,
      elements: [dndEntry("Map"), dndEntry("Capex pie chart")].filter(
        isDefined,
      ),
    },
  ];
};

type RecoilSet<T> = (
  recoilVal: RecoilState<T>,
  valOrUpdater: ((currVal: T) => T) | T,
) => void;

/**
 * This counter is used to keep track of the update requests.  If an update
 * request is made before another is properly finished, we will get flashing:
 * the second requests optimistic update will be overwritten by the write from
 * the first requests response.  By using this counter we can tell that another
 * request has set the local state, so that we should not set it in the `.then`
 * branch when we get the response.
 *
 * This is indented to only be used from `optimisticUpdate`.
 */
let _counters: Record<string, number> = {};

/**
 * Perform an optimistic update of a recoil state while waiting for a `Promise`
 * to resolve to the real updated state. Intended usage is for when you want to
 * update a state, but you don't want to wait for the response from the server.
 *
 * If the promise fails, the state will be reset to the previous value, if this
 * is the last promise that was ran.
 *
 * @param id A unique ID that identifies the promise.  Promises ran with the
 * same ID will update serially.
 * @returns
 */
export const optimisticUpdate = async <T,>(
  id: string,
  state: RecoilState<T>,
  snapshot: Snapshot,
  set: RecoilSet<T>,
  update: T,
  promise: () => Promise<T>,
): Promise<T> => {
  const fallback = await snapshot.getPromise(state);
  const counter = (_counters[id] = (_counters[id] ?? 0) + 1);
  set(state, update);
  return promise()
    .then((res) => {
      if (counter === _counters[id]) set(state, res);
      return res;
    })
    .catch((e) => {
      if (counter === _counters[id]) set(state, fallback);
      throw e;
    });
};
