import "nouislider/dist/nouislider.css";
import { useRecoilState } from "recoil";
import { depthFilterAtomFamily } from "../../state/filter";
import { RangeSlider } from "../General/Slider";
import { useTypedPath } from "../../state/pathParams";

export const maxDepth = 500;

const DepthSlider = ({ disabled }: { disabled?: boolean }) => {
  const { projectId } = useTypedPath("projectId");
  const [depth, setDepth] = useRecoilState(
    depthFilterAtomFamily({ projectId }),
  );
  return (
    <label>
      <RangeSlider
        labels
        min={0}
        step={5}
        max={maxDepth}
        values={depth as [number, number]}
        onChange={(range) =>
          setDepth(
            range.map((v) => (v >= maxDepth ? Number.MAX_SAFE_INTEGER : v)),
          )
        }
        inside
        disabled={disabled}
      />
    </label>
  );
};

export default DepthSlider;
