/// <reference types="vite-plugin-svgr/client" />
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { spaceTiny, borderRadiusMedium, spaceMedium } from "../../styles/space";
import Tooltip from "../General/Tooltip";

import IconWindTurbine from "@icons/24/WindTurbine.svg?react";
import IconLine from "@icons/24/Line.svg?react";
import IconExpand from "@icons/24/Expand.svg?react";
import IconSubstation from "@icons/24/Substation.svg?react";
import { typography } from "styles/typography";

export const Tag = styled.div.attrs(
  ({ selected, className }: { selected?: boolean; className?: string }) => ({
    selected: selected ?? false,
    className: className ?? "tag-opt",
  }),
)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 1.8rem;
  color: ${colors.textBrand};
  ${typography.caption}

  gap: ${spaceTiny};
  padding: ${spaceTiny} ${spaceMedium};
  background: ${colors.surfaceInfo};
  border-radius: ${borderRadiusMedium};

  svg {
    width: 1.2rem;
    height: 1.2rem;
    stroke: ${colors.iconBrand};
  }
`;

export const NumberOfTurbinesTag = ({ n }: { n: number | string }) => {
  const text =
    "Number of turbines" +
    (typeof n === "number" ? "" : " (neighbouring turbines)");
  return (
    <Tooltip text={text}>
      <Tag>
        <IconWindTurbine />
        {n}
      </Tag>
    </Tooltip>
  );
};

export const NumberOfSubstationsTag = ({ n }: { n: number }) => {
  return (
    <Tooltip text="Number of substations">
      <Tag>
        <IconSubstation />
        {n}
      </Tag>
    </Tooltip>
  );
};

export const EdgeTag = () => {
  return (
    <Tooltip text="Edge turbines">
      <Tag>
        <IconLine />
      </Tag>
    </Tooltip>
  );
};

export const MinSpacingTag = ({ n }: { n: number }) => {
  return (
    <Tooltip text="Minimum spacing between turbines">
      <Tag>
        <IconExpand />
        {n.toFixed(1)}
      </Tag>
    </Tooltip>
  );
};

export const VersionTag = ({ v }: { v: string }) => {
  return (
    <Tooltip text="Version of the optimizer">
      <Tag>v{v}</Tag>
    </Tooltip>
  );
};
