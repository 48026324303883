import {
  ResultValue,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import { getScaledStatisticsForFloatingFoundation } from "../../../../../state/foundations";
import { FloaterType } from "../../../../../types/foundations";
import { ColoredGrid } from "../../../../General/Form";
import { isDefined } from "utils/predicates";

export const FloatingFoundationGenerationInner = ({
  currentFoundation,
  turbineTypeId,
  turbineTypeName,
  scale,
}: {
  currentFoundation: FloaterType | undefined;
  turbineTypeId: string;
  turbineTypeName: string | undefined;
  scale: number;
}) => {
  if (!currentFoundation || !turbineTypeName) return null;

  const {
    scaledConcreteVolume,
    scaledConcreteWeight,
    scaledPrimarySteelWeight,
    scaledSolidBallastWeight,
    scaledLiquidBallastWeight,
    scaledReinforcementWeight,
    scaledPostTensionCableWeight,
    scaledDisplacementVolume,
    scaledDraft,
    scaledFootprintLength,
    scaledFootprintBreath,
    scaledMainDiameter,
  } = getScaledStatisticsForFloatingFoundation({
    currentFoundation,
    turbineTypeId,
    scale,
  });

  const name = turbineTypeName + " - " + currentFoundation.name;
  return (
    <>
      <SubtitleWithLine text={name} />
      <ColoredGrid
        style={{
          gridTemplateColumns: "1.5fr 1fr",
        }}
      >
        <ResultValue>Scaling law</ResultValue>
        <ResultValue>
          <strong>{currentFoundation.scalingLaw}</strong>
        </ResultValue>
        <ResultValue>Scaling factor on weight</ResultValue>
        <ResultValue>
          <strong>{scale.toLocaleString()}</strong>
        </ResultValue>
        {isDefined(scaledConcreteVolume) && (
          <>
            <ResultValue> Concrete volume</ResultValue>
            <ResultValue>
              <strong>{scaledConcreteVolume.toLocaleString()} </strong> m³
            </ResultValue>
          </>
        )}
        {isDefined(scaledConcreteWeight) && (
          <>
            <ResultValue>Concrete weight</ResultValue>
            <ResultValue>
              <strong>{scaledConcreteWeight.toLocaleString()} </strong> t
            </ResultValue>
          </>
        )}
        {isDefined(scaledPrimarySteelWeight) && (
          <>
            <ResultValue>Primary steel weight</ResultValue>
            <ResultValue>
              <strong>{scaledPrimarySteelWeight.toLocaleString()} </strong> t
            </ResultValue>
          </>
        )}
        {isDefined(scaledSolidBallastWeight) && (
          <>
            <ResultValue>Solid ballast weight</ResultValue>
            <ResultValue>
              <strong>{scaledSolidBallastWeight.toLocaleString()} </strong> t
            </ResultValue>
          </>
        )}
        {isDefined(scaledLiquidBallastWeight) && (
          <>
            <ResultValue>Liquid ballast weight</ResultValue>
            <ResultValue>
              <strong>{scaledLiquidBallastWeight.toLocaleString()} </strong> t
            </ResultValue>
          </>
        )}
        {isDefined(scaledReinforcementWeight) && (
          <>
            <ResultValue>Reinforcement weight</ResultValue>
            <ResultValue>
              <strong>{scaledReinforcementWeight.toLocaleString()} </strong> t
            </ResultValue>
          </>
        )}
        {isDefined(scaledPostTensionCableWeight) && (
          <>
            <ResultValue>Post tension cables weight</ResultValue>
            <ResultValue>
              <strong>{scaledPostTensionCableWeight.toLocaleString()} </strong>{" "}
              t
            </ResultValue>
          </>
        )}
        <ResultValue>Displacement</ResultValue>
        <ResultValue>
          <strong>{scaledDisplacementVolume.toLocaleString()}</strong> m³
        </ResultValue>
        <ResultValue>Draft</ResultValue>
        <ResultValue>
          <strong>{scaledDraft}</strong> m{" "}
        </ResultValue>
        {scaledFootprintLength && scaledFootprintBreath && (
          <>
            <ResultValue>Footprint (L x B):</ResultValue>
            <ResultValue>
              <strong>{scaledFootprintLength} </strong> m x{" "}
              <strong>{scaledFootprintBreath}</strong> m
            </ResultValue>
          </>
        )}
        {scaledMainDiameter && (
          <>
            <ResultValue>Main diameter</ResultValue>
            <ResultValue>
              <strong>{scaledMainDiameter} </strong> m{" "}
            </ResultValue>
          </>
        )}
      </ColoredGrid>
    </>
  );
};
