import { useRecoilState, useRecoilValue } from "recoil";
import { activePositionHintAtom } from "../../../state/hint";
import { userHintSettingsAtom } from "../CloseableHints/state";
import { useCallback } from "react";
import { AllHints } from "../AllHints";
import { Place } from "../../General/Anchor";
import { usePutUserHintSettings } from "../CloseableHints/hooks";

const PositionHints = ({ position }: { position?: Place }) => {
  const [activeHint, setActiveHint] = useRecoilState(activePositionHintAtom);
  const userSettings = useRecoilValue(userHintSettingsAtom);
  const putUserHintSettings = usePutUserHintSettings();

  const close = useCallback(() => {
    if (!activeHint) return;
    putUserHintSettings({
      ...userSettings,
      dontShowTipIds: [...(userSettings.dontShowTipIds ?? []), activeHint],
    });
    setActiveHint(undefined);
  }, [activeHint, putUserHintSettings, userSettings, setActiveHint]);

  if (
    !activeHint ||
    userSettings.hideAllTips ||
    userSettings.dontShowTipIds?.includes(activeHint)
  )
    return null;

  return (
    <AllHints hintId={activeHint} closeCallback={close} position={position} />
  );
};

export default PositionHints;
