export const axisLinesSourceId = "turbine-gen-axis-lines-source";
export const axisLinesLayerId = "turbine-gen-axis-lines-layer";

export const turbineEllipsesFillSourceId = "turbine-gen-ellipses-fill-source";
export const turbineEllipsesLineSourceId = "turbine-gen-ellipses-line-source";
export const turbineEllipsesFillLayerId = "turbine-gen-ellipses-fill-layer";
export const turbineEllipsesLineLayerId = "turbine-gen-ellipses-line-layer";

export const turbineEllipsesCustomFillSourceId =
  "turbine-cus-ellipses-fill-source";
export const turbineEllipsesCustomLineSourceId =
  "turbine-cus-ellipses-line-source";
export const turbineEllipsesCustomFillLayerId =
  "turbine-cus-ellipses-fill-layer";
export const turbineEllipsesCustomLineLayerId =
  "turbine-cus-ellipses-line-layer";
