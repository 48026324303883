/// <reference types="vite-plugin-svgr/client" />
import { CostConfiguration, CostType } from "../../../../services/costService";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  localCostConfigurationAtom,
  useLocalCostConfigurationCrud,
} from "../state";
import { Input, InputDimensioned } from "../../../General/Input";
import {
  ConfidenceLevelDot,
  CostDivider,
  DividerWrapper,
  GridWithFourColumns,
  Tag,
} from "../styles";
import { FirstRowWrapper, FirstRowItem, Row } from "../Capex";
import Cabling from "@icons/24/Cabling.svg?react";
import { GeneralCost } from "./General/GeneralCost";
import { DefaultCosts } from "../constants";
import { ConfidenceLevel, CostUnit } from "../../../../types/financial";
import { CABLE_MENU_ID } from "../../../SettingsV2/FeatureSettings/Data/useCableSettings";
import { isDefined } from "../../../../utils/predicates";
import { projectIdSelector } from "state/pathParams";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import Button from "components/General/Button";
import PencilIcon from "@icons/24/Pencil.svg";
import ComponentsIcon from "@icons/24/ComponentsIcon.svg";
import Tooltip from "components/General/Tooltip";
import LogoIcon from "@icons/24/VindLogo.svg?react";
import UndoIcon from "@icons/24/Undo.svg";
import { IconREMSize } from "styles/typography";
import { colors } from "styles/colors";
import { IconBtn } from "components/General/Icons";
import PreviewIcon from "@icons/24/PreviewIcon.svg?react";
import { showComponentsPreviewAtom } from "components/ComponentsPreview/state";
import { ConfidenceLevelSelector } from "../ConfidenceLevelSelector";
import { ButtonTextWrapper } from "components/General/Dropdown/DropdownButton";

const CableSpecificCosts = ({ isReadOnly }: { isReadOnly?: boolean }) => {
  const localConfig: CostConfiguration = useRecoilValue(
    localCostConfigurationAtom,
  );
  const [, setShowComponentsPreview] = useRecoilState(
    showComponentsPreviewAtom,
  );
  const useConfidenceLevel = localConfig.useConfidenceLevel;
  const projectId = useRecoilValue(projectIdSelector)!;
  const inLibraryContext = !projectId;

  const { updateFixedCapex } = useLocalCostConfigurationCrud();

  const handleCableCostTypeChange = (type: "default" | "override") => () => {
    if (type === "default") {
      updateFixedCapex({ cables: undefined });
    } else {
      updateFixedCapex({
        cables: {
          cost: DefaultCosts[CostType.Cable].cable[CostUnit.millionEuroPerKM],
          unit: CostUnit.millionEuroPerKM,
          confidenceLevel: ConfidenceLevel.notSpecified,
        },
      });
    }
  };

  const isUsingDefaultCableCost =
    localConfig?.capex.fixed.cables &&
    localConfig.capex.fixed.cables.cost ===
      DefaultCosts[CostType.Cable].cable[CostUnit.millionEuroPerKM] &&
    localConfig.capex.fixed.cables.unit === CostUnit.millionEuroPerKM;

  return (
    <>
      <FirstRowWrapper>
        <GridWithFourColumns>
          <FirstRowItem>
            <Input
              value={"Cables"}
              disabled={true}
              locked={true}
              style={{ width: "20rem" }}
            />
          </FirstRowItem>

          {localConfig?.capex.fixed.cables === undefined ? (
            <Row style={{ width: "20rem" }}>
              <Tag>
                <ComponentsIcon />
                From components
              </Tag>
              {inLibraryContext ? (
                <HelpTooltip text="Using the cost specified on the cable type" />
              ) : (
                <IconBtn
                  size={"1.5rem"}
                  onClick={() => {
                    setShowComponentsPreview({
                      show: true,
                      componentType: CABLE_MENU_ID,
                    });
                  }}
                >
                  <PreviewIcon />
                </IconBtn>
              )}
            </Row>
          ) : (
            <Row style={{ width: "20rem" }}>
              <InputDimensioned
                step="0.1"
                unit={
                  localConfig?.capex.fixed.cables?.unit
                    ? localConfig.capex.fixed.cables.unit
                    : CostUnit.millionEuroPerKM
                }
                units={[CostUnit.millionEuroPerKM, CostUnit.thousandEuroPerMW]}
                type="number"
                value={
                  localConfig?.capex.fixed.cables
                    ? localConfig?.capex.fixed.cables?.cost
                      ? localConfig.capex.fixed.cables.cost
                      : DefaultCosts[CostType.Cable].cable[
                          CostUnit.millionEuroPerKM
                        ]
                    : undefined
                }
                onUnitChange={(unit: CostUnit) =>
                  updateFixedCapex({
                    cables: {
                      cost: (DefaultCosts[CostType.Cable].cable as any)[unit],
                      unit,
                    },
                  })
                }
                validate={(value) => isDefined(value) && value >= 0}
                validationMessage={"Must be greater than or equal to 0"}
                disabled={isReadOnly || !localConfig?.capex.fixed.cables}
                onChange={(cost) => updateFixedCapex({ cost }, "cables")}
                style={{ width: "100%" }}
              />
              {isUsingDefaultCableCost ? (
                <Tooltip text={"Vind default value"}>
                  <IconREMSize height={2.6} width={2.6} fill={colors.blue700}>
                    <LogoIcon />
                  </IconREMSize>
                </Tooltip>
              ) : (
                <Tooltip text="Reset to default">
                  <IconBtn
                    size={"1.6rem"}
                    onClick={handleCableCostTypeChange("override")}
                  >
                    <UndoIcon />
                  </IconBtn>
                </Tooltip>
              )}
            </Row>
          )}

          {useConfidenceLevel ? (
            !(localConfig?.capex.fixed.cables === undefined) ? (
              <ConfidenceLevelSelector
                disabled={isReadOnly}
                onConfidenceLevelChange={(confidenceLevel) => {
                  updateFixedCapex({
                    cables: {
                      ...localConfig.capex.fixed.cables,
                      confidenceLevel: confidenceLevel,
                    },
                  });
                }}
                confidenceLevel={
                  localConfig.capex.fixed.cables?.confidenceLevel
                }
              />
            ) : (
              <Tag>
                <Tooltip
                  text={
                    "Not able to specify confidence on configuration level when the cost is from components"
                  }
                >
                  <ButtonTextWrapper>
                    <ConfidenceLevelDot confidenceLevel={undefined} />
                    {ConfidenceLevel.notSpecified}
                  </ButtonTextWrapper>
                </Tooltip>
              </Tag>
            )
          ) : (
            <div></div>
          )}

          <Button
            size="small"
            icon={
              !(localConfig?.capex.fixed.cables !== undefined) ? (
                <PencilIcon />
              ) : (
                <ComponentsIcon />
              )
            }
            buttonType="secondary"
            text={
              !(localConfig?.capex.fixed.cables !== undefined)
                ? "Override"
                : "Use components"
            }
            onClick={
              !(localConfig?.capex.fixed.cables !== undefined)
                ? handleCableCostTypeChange("override")
                : handleCableCostTypeChange("default")
            }
            disabled={isReadOnly}
          />
        </GridWithFourColumns>
      </FirstRowWrapper>
    </>
  );
};

export const CableCosts = ({ isReadOnly }: { isReadOnly?: boolean }) => {
  return (
    <GeneralCost
      title="Inter array cables"
      category={CostType.Cable}
      units={[
        CostUnit.millionEuroPerKM,
        CostUnit.millionEuroPerMW,
        CostUnit.millionEuroPerCable,
        CostUnit.millionEuro,
      ]}
      icon={<Cabling />}
      isReadOnly={isReadOnly}
    >
      <CableSpecificCosts isReadOnly={isReadOnly} />
      <DividerWrapper>
        <CostDivider />
      </DividerWrapper>
    </GeneralCost>
  );
};
