import { Position } from "@turf/turf";
import { fetchEnhancerWithToken } from "./utils";
import { mapboxAccessToken } from "components/MapNative/constants";
import { PBFToGeojsonFile } from "components/UploadModal/utils";
import { Feature, FeatureCollection, MultiPolygon } from "geojson";

export async function getViewShedAnalysis(
  coords: Position[],
  epsg: number,
  zoom: number,
  observerHeight: number,
) {
  const body = coords;

  const headers = {
    method: "post",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetchEnhancerWithToken(
    `/api/terrain-giver/viewshed/${zoom}/${epsg}?include_land=false&observerHeight=${observerHeight}`,
    headers,
  );
}

export const getTerrainFromBBOX = async (
  bbox: Position[],
  zoom: number,
  epsg: number,
) =>
  fetchEnhancerWithToken(
    `/api/terrain-giver/bbox/${bbox[0][0]}/${bbox[0][1]}/${bbox[1][0]}/${bbox[1][1]}/${zoom}/${epsg}`,
    {
      method: "get",
    },
  );

export const getOSMBuildingsFromTile = async (
  x: number,
  y: number,
  z: number,
): Promise<Feature<MultiPolygon>[]> => {
  const res = await fetch(
    `https://api.mapbox.com/v4/mapbox.mapbox-streets-v8/${z}/${x}/${y}.vector.pbf?access_token=${mapboxAccessToken}`,
    {
      method: "get",
    },
  );
  if (!res.ok)
    throw new Error("Error downloading building footprints from mapbox");
  const blob = await res.blob();
  const geojsonFile = await PBFToGeojsonFile(
    new File([blob], `${z}-${x}-${y}.pbf`),
  );

  if (!geojsonFile) return [];

  var reader = new FileReader();
  reader.readAsText(geojsonFile);
  const text = await new Promise<string>(
    (res) =>
      (reader.onload = function () {
        res(reader.result as string);
      }),
  );
  return (JSON.parse(text) as FeatureCollection<MultiPolygon>).features.filter(
    (f) => f.geometry.type.includes("Polygon"),
  );
};

export const getSatellitePNGFromBBOX = async (
  bbox: Position[],
  zoom: number,
  epsg: number,
) =>
  fetchEnhancerWithToken(
    `/api/terrain-giver/satellite/bbox/${bbox[0][0]}/${bbox[0][1]}/${bbox[1][0]}/${bbox[1][1]}/${zoom}/${epsg}`,
    {
      method: "get",
    },
  );
