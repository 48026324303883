import React from "react";
import { colors } from "../../../../styles/colors";
import { UploadWrapper } from "../shared";

const DropFilesMessage = () => {
  return (
    <UploadWrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: `3px dashed ${colors.inputOutline}`,
          padding: "4rem 0",
        }}
      >
        <h4>Drop files to upload</h4>
      </div>
    </UploadWrapper>
  );
};

export default DropFilesMessage;
