import styled from "styled-components";
import { StandardBox } from "../../styles/boxes/Boxes";

export const FeatureCoordinateEditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1.2rem;
`;

export const OpacityNumber = styled.div`
  flex-grow: 1;
  cursor: pointer;
  font-size: 1.4rem;
`;

export const ColorSelectorWrapper = styled.div`
  position: relative;
`;

export const FeatureOpacityPickerContainer = styled(StandardBox)<{
  position?: "left" | "right";
}>`
  position: fixed;
  ${(p) =>
    (p.position ?? "right") === "right" ? { right: "0" } : { left: "0" }}
  margin-top: 2rem;
`;

export const OpacitySliderWrapper = styled.div`
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`;
