import React, { useCallback, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { modalTypeOpenAtom } from "../../state/modal";
import FullScreenModal from "../FullScreenModal/FullScreenModal";
import { projectFeatureSelector } from "../ProjectElements/state";
import Button from "../General/Button";
import { InputDimensionedDistance } from "../General/Input";
import { bufferSingleFeature } from "../../utils/bufferSingleFeature";
import { useSelectFeatureForOverlapContext } from "components/DataLayersModal/selectFeatureForOverlapContext";
import { typography } from "../../styles/typography";
import { MenuFrame } from "components/MenuPopup/CloseableMenuPopup";
import { Column, Row } from "components/General/Layout";
import { Label } from "components/General/Form";

export const SelectOverlapBufferModalType =
  "SelectOverlapBufferModalType" as const;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  div {
  }
`;

const SelectOverlapBufferModalInner = ({
  featureId,
}: {
  featureId: string;
}) => {
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const feature = useRecoilValue(projectFeatureSelector({ featureId }));
  const [bufferSize, setBufferSize] = useState<undefined | number>();
  const { setFeatureForFeatureOverlap } = useSelectFeatureForOverlapContext();

  const onClose = useCallback(() => {
    setModalTypeOpen(undefined);
  }, [setModalTypeOpen]);

  const onContinueClick = useCallback(() => {
    if (!feature) {
      return;
    }

    if (!bufferSize) {
      setFeatureForFeatureOverlap(feature);
      return;
    }

    const bufferedFeature = bufferSingleFeature(feature, bufferSize, false, 1);

    if (bufferedFeature) setFeatureForFeatureOverlap(bufferedFeature);
  }, [bufferSize, feature, setFeatureForFeatureOverlap]);

  if (!feature) {
    return null;
  }

  return (
    <FullScreenModal>
      <MenuFrame title="Overlap" onExit={onClose}>
        <Column>
          <Row style={{ alignItems: "baseline" }}>
            <span style={{ ...typography.semiBold }}>Feature selected:</span>
            <p>{feature.properties.name}</p>
          </Row>

          <Label>
            <span style={{ ...typography.semiBold }}>
              Buffer feature (optional)
            </span>
            <InputDimensionedDistance
              placeholder="Buffer size"
              onChange={(n) => setBufferSize(n)}
            />
          </Label>

          <ButtonRow>
            <Button text="Cancel" buttonType="secondary" onClick={onClose} />
            <Button
              text="Continue"
              buttonType="primary"
              onClick={onContinueClick}
            />
          </ButtonRow>
        </Column>
      </MenuFrame>
    </FullScreenModal>
  );
};

const SelectOverlapBufferModal = () => {
  const [modalTypeOpen] = useRecoilState(modalTypeOpenAtom);

  if (modalTypeOpen?.modalType !== SelectOverlapBufferModalType) {
    return null;
  }

  return (
    <SelectOverlapBufferModalInner
      featureId={modalTypeOpen.metadata.featureId}
    />
  );
};

export default SelectOverlapBufferModal;
