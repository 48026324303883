/// <reference types="vite-plugin-svgr/client" />
import Redo from "@icons/24/Redo.svg?react";
import Undo from "@icons/24/Undo.svg?react";
import { RoundIconBtn } from "components/General/Icons";
import { useEffect } from "react";
import styled from "styled-components";
import { spaceMedium } from "styles/space";
import useSystemSpecificUnicode from "hooks/useSystemSpecificUnicode";
import Tooltip from "components/General/Tooltip";

const LowerLeft = styled.div`
  position: absolute;
  bottom: 0.8rem;
  left: 0.8rem;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
`;

const UndoRedoContainer = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: row;
  padding-left: ${spaceMedium};
  gap: ${spaceMedium};
`;

function UndoRedoEditMap({
  canUndo,
  canRedo,
  undo,
  redo,
  mapContainer,
}: {
  canUndo: boolean;
  canRedo: boolean;
  undo: () => void;
  redo: () => void;
  mapContainer: React.RefObject<HTMLDivElement>;
}) {
  const stringToUnicode = useSystemSpecificUnicode();

  useEffect(() => {
    const map = mapContainer.current;
    if (!map) return;
    const onUndo = (event: KeyboardEvent) => {
      if (
        event.isComposing ||
        event.code !== "KeyZ" ||
        (!event.metaKey && !event.ctrlKey) ||
        event.shiftKey
      ) {
        return;
      }
      undo();
    };
    const onRedo = (event: KeyboardEvent) => {
      if (
        event.isComposing ||
        event.code !== "KeyZ" ||
        (!event.metaKey && !event.ctrlKey) ||
        !event.shiftKey
      ) {
        return;
      }
      redo();
    };
    map.addEventListener("keydown", onUndo);
    map.addEventListener("keydown", onRedo);
    return () => {
      map.removeEventListener("keydown", onUndo);
      map.removeEventListener("keydown", onRedo);
    };
  }, [redo, undo, mapContainer]);

  return (
    <LowerLeft>
      <UndoRedoContainer>
        <Tooltip
          position="right"
          text="Undo"
          shortcut={`${stringToUnicode("command")}+Z`}
          revealWithQKey
          readonlyAware
        >
          <RoundIconBtn disabled={!canUndo} onClick={undo} id={"undo"}>
            <Undo />
          </RoundIconBtn>
        </Tooltip>
        <Tooltip
          position="right"
          text="Redo"
          shortcut={`${stringToUnicode("shift")}+${stringToUnicode("command")}+Z`}
          revealWithQKey
          readonlyAware
        >
          <RoundIconBtn disabled={!canRedo} onClick={redo} id={"redo"}>
            <Redo />
          </RoundIconBtn>
        </Tooltip>
      </UndoRedoContainer>
    </LowerLeft>
  );
}

export function UndoRedoButtonsPlaceholder() {
  const stringToUnicode = useSystemSpecificUnicode();
  return (
    <>
      <Tooltip
        position="right"
        text="Undo"
        shortcut={`${stringToUnicode("command")}+Z`}
        readonlyAware
      >
        <RoundIconBtn disabled={true} onClick={() => {}} id={"undo"}>
          <Undo />
        </RoundIconBtn>
      </Tooltip>
      <Tooltip
        position="right"
        text="Redo"
        shortcut={`${stringToUnicode("shift")}+${stringToUnicode("command")}+Z`}
        readonlyAware
      >
        <RoundIconBtn disabled={true} onClick={() => {}} id={"redo"}>
          <Redo />
        </RoundIconBtn>
      </Tooltip>
    </>
  );
}

export default UndoRedoEditMap;
