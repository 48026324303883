import { z } from "zod";

export const cloneExistingStylesAndPollForStyleChanges = (
  openedWindow: Window,
) => {
  const parentDocument = openedWindow.opener.document as Document;

  const currentStyles =
    parentDocument.head.querySelectorAll<HTMLStyleElement>("style");
  for (const style of currentStyles) {
    openedWindow.document.head.append(style.cloneNode(true));
  }

  for (const styleSheet of parentDocument.styleSheets) {
    try {
      const styleElem = openedWindow.document.createElement("style");
      openedWindow.document.head.append(styleElem);

      for (const ruleIndex in styleSheet.cssRules) {
        const cssRule = styleSheet.cssRules.item(Number(ruleIndex));
        if (!cssRule) {
          continue;
        }

        styleElem.sheet?.insertRule(cssRule.cssText, Number(ruleIndex));
      }
    } catch (e) {}
  }

  // The element that styled components will add style to
  // SC uses CSSOM to add styles, so we need to keep the stylesheets sheet.cssRules in sync
  const styledComponentsStyleElement =
    parentDocument.head.querySelector<HTMLStyleElement>(
      "style[data-styled='active']",
    );

  const openedWindowStyledComponentsStyleElement =
    styledComponentsStyleElement?.cloneNode(true) as HTMLStyleElement;

  openedWindow.document.head.appendChild(
    openedWindowStyledComponentsStyleElement,
  );

  const interval = setInterval(() => {
    if (
      !styledComponentsStyleElement?.sheet ||
      !openedWindowStyledComponentsStyleElement?.sheet
    ) {
      return;
    }

    if (
      openedWindowStyledComponentsStyleElement.sheet.cssRules.length ===
      styledComponentsStyleElement.sheet.cssRules.length
    ) {
      return;
    }

    // Keep stylesheets in sync
    for (const indexString in styledComponentsStyleElement.sheet.cssRules) {
      const index = Number(indexString);
      const rule = styledComponentsStyleElement.sheet.cssRules.item(index);
      if (!rule) {
        continue;
      }

      if (
        openedWindowStyledComponentsStyleElement.sheet.cssRules.item(index)
          ?.cssText !== rule.cssText
      ) {
        openedWindowStyledComponentsStyleElement.sheet.insertRule(
          rule.cssText,
          index,
        );
      }
    }
  }, 50);

  return interval as any as number;
};

export const cloneExistingLinks = (openedWindow: Window) => {
  const parentDocument = openedWindow.opener.document;
  const currentLinks = parentDocument.head.querySelectorAll("link");

  for (const currentLink of currentLinks) {
    openedWindow.document.head.appendChild(currentLink.cloneNode(true));
  }
};

export const cloneExistingScripts = (openedWindow: Window) => {
  const parentDocument = openedWindow.opener.document;
  const currentScripts = parentDocument.head.querySelectorAll("script");

  for (const script of currentScripts) {
    openedWindow.document.head.appendChild(script.cloneNode(true));
  }
};

export const saveSizeToLocalStorage = (
  componentIdentifier: string,
  width: number,
  height: number,
) => {
  if (!("localStorage" in window)) {
    return;
  }

  localStorage.setItem(
    `vind:window-size:${componentIdentifier}`,
    JSON.stringify({
      width,
      height,
    }),
  );
};

const _SavedWindowSize = z.object({
  width: z.number(),
  height: z.number(),
});

export const readSizeFromLocalStorage = (
  componentIdentifier: string,
): z.infer<typeof _SavedWindowSize> | undefined => {
  if (!("localStorage" in window)) {
    return;
  }

  const lsValue = localStorage.getItem(
    `vind:window-size:${componentIdentifier}`,
  );
  if (!lsValue) {
    return;
  }

  try {
    return _SavedWindowSize.parse(JSON.parse(lsValue));
  } catch (error) {}
};
