import { PROJECT_DATA_API_VERSION } from "services/projectDataAPIService";
import { fetchEnhancerWithToken } from "services/utils";
import { z } from "zod";
import { _ArchivedProjectVersion } from "./types";

export const ARCHIVE_PROJECT_API_PATH = "/api/archive-project";

export async function createArchivedProjectVersion({
  organisationId,
  projectId,
  branchId,
  parkId,
  name,
  description,
}: {
  organisationId: string;
  projectId: string;
  branchId: string;
  parkId: string;
  name: string;
  description?: string;
}) {
  const res = await fetchEnhancerWithToken(
    `${ARCHIVE_PROJECT_API_PATH}/${organisationId}/${projectId}/${branchId}/${parkId}`,
    {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ name, description }),
    },
  )
    .then((res) => res.json())
    .then((json) => z.object({ meta: _ArchivedProjectVersion }).parse(json));

  return res.meta;
}

export async function deleteArchivedProjectVersion({
  organisationId,
  projectId,
  branchId,
  versionId,
}: {
  organisationId: string;
  projectId: string;
  branchId: string;
  versionId: string;
}) {
  return await fetchEnhancerWithToken(
    `${ARCHIVE_PROJECT_API_PATH}/${organisationId}/${projectId}/${branchId}/delete/${versionId}`,
    {
      method: "delete",
      headers: {
        "content-type": "application/json",
      },
    },
  ).then((res) => {
    if (res.status === 204) {
      return;
    }
    throw new Error("Failed to delete archived version");
  });
}

export async function listArchivedProjectVersions({
  organisationId,
  projectId,
  branchId,
}: {
  organisationId: string;
  projectId: string;
  branchId: string;
}) {
  const res = await fetchEnhancerWithToken(
    `${ARCHIVE_PROJECT_API_PATH}/${organisationId}/${projectId}/${branchId}`,
    {
      method: "get",
      headers: {
        "x-project-data-client-version": PROJECT_DATA_API_VERSION,
      },
    },
  )
    .then((res) => res.json())
    .then((json) =>
      z
        .object({ archivedVersions: z.array(_ArchivedProjectVersion) })
        .parse(json),
    );

  return res;
}

export async function getArchivedVersionDownloadUrl({
  organisationId,
  projectId,
  branchId,
  versionId,
}: {
  organisationId: string;
  projectId: string;
  branchId: string;
  versionId: string;
}) {
  const res = await fetchEnhancerWithToken(
    `${ARCHIVE_PROJECT_API_PATH}/download/${organisationId}/${projectId}/${branchId}/${versionId}`,
    {
      method: "get",
      headers: {
        "x-project-data-client-version": PROJECT_DATA_API_VERSION,
      },
    },
  )
    .then((res) => res.json())
    .then((json) => z.object({ url: z.string() }).parse(json));

  return res.url;
}
