import { useLocalStorage } from "../../../../../hooks/useBrowserStorage";
import { CostType } from "../../../../../services/costService";
import { IconWrapper } from "../../styles";
import { Column } from "../../Capex";
import ShadeToggleableList from "../../../../ToggleableList/ShadeToggleableList";
import { ReactNode } from "react";
import { CustomCosts } from "./CustomCosts";
import { ConfidenceLevel, CostUnit } from "../../../../../types/financial";
import { CostTopRow } from "./CostTopRow";

export const GeneralCost = ({
  title,
  category,
  units,
  defaultOpen = false,
  icon = undefined,
  children = undefined,
  isReadOnly = false,
}: {
  title: string;
  category: CostType;
  units: CostUnit[];
  defaultOpen?: boolean;
  icon?: JSX.Element;
  children?: ReactNode;
  isReadOnly?: boolean;
}) => {
  const [isOpen, setIsOpen] = useLocalStorage<boolean | undefined>(
    `vind:config-financial-capex-${title.replace(" ", "-")}-open`,
  );

  return (
    <ShadeToggleableList
      contentStyle={{
        minWidth: "40rem",
      }}
      title={title}
      defaultOpen={isOpen ?? defaultOpen}
      icon={icon && <IconWrapper>{icon}</IconWrapper>}
      onOpenChange={setIsOpen}
    >
      <Column>
        <CostTopRow />
      </Column>
      <Column>{children ? children : null}</Column>
      <CustomCosts
        category={category}
        units={units}
        confidenceLevel={ConfidenceLevel.notSpecified}
        isReadOnly={isReadOnly}
      />
    </ShadeToggleableList>
  );
};
