import { colors } from "styles/colors";
import styled from "styled-components";
import { useCallback, useEffect, useRef, useState } from "react";
import React from "react";
import AngleIcon from "@icons/24/RotateAngle.svg?react";
import { typography } from "styles/typography";
import { borderRadius } from "styles/space";
import { EditableText, TextContainer } from "components/General/EditableText";
import { TextInput } from "components/General/Input";
import { range } from "utils/utils";
import { degreeNormalize } from "utils/geometry";

const AnchorEditorDiv = styled.div<{ $dragging: boolean }>`
  position: relative;
  aspect-ratio: 1 / 1;

  user-select: none; // disable text highlight when mouse dragging

  ${(p) => p.$dragging && `cursor: grabbing !important;`}

  .turbine {
    fill: ${colors.blue900};
  }

  .anchor:hover,
  .mooringline:hover {
    ${(p) => (p.$dragging ? "" : "cursor: grab")};
  }

  .anchor {
    fill: ${colors.anchor};
    stroke: ${colors.white};
    &:hover {
      fill: ${colors.purple500};
    }
    &.selected {
      fill: ${colors.orange400};
    }
  }

  .mooringline {
    stroke: ${colors.mooringLine};
    stroke-dasharray: 1;
    &.selected {
      stroke: ${colors.orange400};
    }
  }

  .textbox {
    position: absolute;
    transform: translate(-50%, -160%);
    display: flex;
    align-items: center;

    ${TextContainer} {
      gap: 0;
      padding-right: 0;
    }

    background: ${colors.surfaceSelectedLight};
    border-radius: ${borderRadius.small};

    gap: 0.4rem;
    padding: 0.3rem 0.6rem;

    ${typography.graphics};
    font-variant-numeric: tabular-nums;
    min-width: 4ch;
    text-align: end;
    color: ${colors.textBrand};
    white-space: nowrap;

    svg {
      overflow: visible;
      height: 1rem;
      width: 1rem;
      path {
        stroke: ${colors.iconSubtle};
      }
    }
  }

  ${TextInput} {
    border: none;
    width: 8ch;
    height: 1.6rem;
    padding: 0;
    ${typography.graphics};
  }
`;

export const AnchorEditor = ({
  numAngles,
  initial,
  onAngleChange,
}: {
  numAngles: number;
  initial?: number[];
  onAngleChange?: (angles: number[]) => void;
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);
  const [angles, setAngles] = useState(
    initial ?? range(0, numAngles).map((i) => (i * 360) / numAngles),
  ); // 0 is straight up. Positive is CW.
  const circleR = 40;

  if (numAngles !== angles.length)
    setAngles(range(0, numAngles).map((i) => (i * 360) / numAngles));

  const [drag, setDrag] = useState<undefined | number>(undefined);

  const mouseMove = useCallback(
    (e: any) => {
      if (!svgRef.current || !divRef.current || drag === undefined) return;
      const divRect = divRef.current?.getBoundingClientRect();
      // Convert mouse coordinates to SVG coordinates, and then compute the angle.
      const inX = e.clientX - divRect.x;
      const inY = e.clientY - divRect.y;
      const outY = (inY / divRect.height) * 100 - 50;
      const outX = (inX / divRect.width) * 100 - 50;
      let angle = (Math.atan2(outY, outX) * 180) / Math.PI + 90;
      if (angle < 0) angle += 360;

      let nextAngles = [...angles];
      nextAngles[drag] = angle;
      onAngleChange?.(nextAngles);
      setAngles(nextAngles);
    },
    [angles, drag, onAngleChange],
  );

  const [{ width, height }, setSize] = useState({ width: 1, height: 1 });
  useEffect(() => {
    if (!svgRef.current) return;
    const observer = new ResizeObserver(() => {
      if (!svgRef.current) return;
      const { height, width } = svgRef.current.getBoundingClientRect();
      setSize({ height, width });
    });
    observer.observe(svgRef.current);
    return () => observer.disconnect();
  }, []);
  const textboxDist = (width / 100) * circleR;

  useEffect(() => {
    if (drag === undefined) return;

    const mouseUp = () => {
      setDrag(undefined);
    };
    window.addEventListener("mousemove", mouseMove);
    window.addEventListener("mouseup", mouseUp);
    return () => {
      window.removeEventListener("mousemove", mouseMove);
      window.removeEventListener("mouseup", mouseUp);
    };
  }, [drag, mouseMove]);

  return (
    <AnchorEditorDiv ref={divRef} $dragging={drag != undefined}>
      <svg ref={svgRef} viewBox="0 0 100 100">
        <circle
          cx="50"
          cy="50"
          r={circleR}
          fill={colors.surfaceSecondary}
          strokeWidth="0.5"
          stroke={colors.borderDefault}
        />

        {angles.map((a, i) => {
          const t = (a / 180) * Math.PI;
          const x = Math.sin(t) * circleR + 50;
          const y = -Math.cos(t) * circleR + 50;
          return (
            <React.Fragment key={i}>
              <line
                x1={50}
                y1={50}
                x2={x}
                y2={y}
                strokeWidth="0.5"
                className={`mooringline ${drag === i ? "selected" : ""}`}
                onMouseDown={() => setDrag(i)}
              />
              <circle
                cx={x}
                cy={y}
                r="3"
                fill={colors.blue900}
                key={i}
                strokeWidth={0.25}
                className={`anchor ${drag === i ? "selected" : ""}`}
                onMouseDown={() => setDrag(i)}
              />
            </React.Fragment>
          );
        })}
        <circle cx="50" cy="50" r="3.5" className="turbine" />
      </svg>
      {angles.map((a, i) => {
        const t = -((a + 90) / 180) * Math.PI;
        const X = Math.sin(t) * textboxDist + width / 2;
        const Y = -Math.cos(t) * textboxDist + height / 2;
        return (
          <div className="textbox" key={i} style={{ top: X, left: Y }}>
            <AngleIcon />
            <EditableText
              value={a.toFixed(0)}
              renderText={(t) => `${t}°`}
              onChange={(a) => {
                const angle = parseFloat(a.target.value);
                if (isNaN(angle)) return;
                let nextAngles = [...angles];
                nextAngles[i] = degreeNormalize(angle);
                onAngleChange?.(nextAngles);
                setAngles(nextAngles);
              }}
            />
          </div>
        );
      })}
    </AnchorEditorDiv>
  );
};
