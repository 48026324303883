import { atom, atomFamily, selectorFamily } from "recoil";
import { organisationIdSelector } from "state/pathParams";
import { allLayerSettingsAtomFamily } from "components/LayerList/LayerSettings/state";
import { DataLibraryLayer, DataLibraryPackage, ProjectUsages } from "./types";
import {
  dataLayersUsage,
  dataPackageUsage,
  getOrganisationCustomLayerResources,
} from "./service";
import {
  dataLayerAccessOnNodeState,
  dataPackageResourceWithAccessOnNodeStateRefreshAtom,
  organisationLibraryDataPackageResourceState,
} from "../state";
import { UploadedVectorDataLayer } from "../types";

export const organisationLibraryPackageSelector = selectorFamily<
  DataLibraryPackage | undefined,
  {
    packageId: string;
  }
>({
  key: "organisationLibraryPackageSelector",
  get:
    ({ packageId }) =>
    ({ get }) => {
      const organisationId = get(organisationIdSelector);
      const packages = get(
        organisationLibraryDataPackageResourceState({
          organisationId: organisationId ?? "",
        }),
      );
      return packages.find((p) => p.id === packageId);
    },
});

export const organisationLibraryLayersAtomFamily = atomFamily<
  DataLibraryLayer[],
  { organisationId: string }
>({
  key: "organisationLibraryLayersAtomFamily",
  default: selectorFamily({
    key: "organisationLibraryLayersAtomFamily.default",
    get:
      ({ organisationId }) =>
      async ({ get }) => {
        get(refreshOrganisationLibraryLayersAtom);
        return await getOrganisationCustomLayerResources(organisationId);
      },
  }),
});

export const refreshOrganisationLibraryLayersAtom = atom<number>({
  key: "refreshOrganisationLibraryLayersAtom",
  default: 0,
});

export const selectedOrganisationPackageLayersAtom = atom<string[]>({
  key: "selectedOrganisationPackageLayersAtom",
  default: [],
});

export const selectedOrganisationLayersAtom = atom<string[]>({
  key: "selectedOrganisationLayersAtom",
  default: [],
});

// Selector for selected layers that still exist in the organisation
export const selectedOrganisationLayerIdsSelector = selectorFamily<
  string[],
  { organisationId: string }
>({
  key: "selectedOrganisationLayerIdsSelector",
  get:
    ({ organisationId }) =>
    ({ get }) => {
      const selectedLayers = get(selectedOrganisationLayersAtom);
      const existingLayers = get(
        organisationLibraryLayersAtomFamily({ organisationId }),
      );
      return selectedLayers.filter((l) =>
        existingLayers.find((e) => e.id === l),
      );
    },
  set: () => {
    return ({ set }, newValue) => {
      set(selectedOrganisationLayersAtom, newValue);
    };
  },
});

export const organisationLibraryLayerSelector = selectorFamily<
  DataLibraryLayer,
  { layerId: string }
>({
  key: "organisationLibraryLayerSelector",
  get:
    ({ layerId }) =>
    ({ get }) => {
      const organisationId = get(organisationIdSelector);
      const layers = get(
        organisationLibraryLayersAtomFamily({
          organisationId: organisationId ?? "",
        }),
      );
      const layer = layers.find((l) => l.id === layerId);
      if (!layer) {
        throw new Error("Layer not found");
      }
      return layer;
    },
});

export const organisationLibraryLayersSelector = selectorFamily<
  DataLibraryLayer[],
  { layerIds: string[] }
>({
  key: "organisationLibraryLayersSelector",
  get:
    ({ layerIds }) =>
    ({ get }) => {
      const organisationId = get(organisationIdSelector);
      const layers = get(
        organisationLibraryLayersAtomFamily({
          organisationId: organisationId ?? "",
        }),
      );
      return layers.filter((l) => layerIds.includes(l.id));
    },
});

export const libraryLayersAddedToProject = selectorFamily<
  UploadedVectorDataLayer[],
  { projectId: string | undefined }
>({
  key: "libraryLayersAddedToProject",
  get:
    ({ projectId }) =>
    ({ get }) => {
      if (!projectId) return [];
      const libraryLayers = get(
        dataLayerAccessOnNodeState({ nodeId: projectId }),
      );
      const allLayerSettingIds = get(
        allLayerSettingsAtomFamily({ projectId }),
      ).map((ll) => ll.id);
      return libraryLayers.filter((ll) => allLayerSettingIds.includes(ll.id));
    },
});

export const activePackageIdAtom = atom<string | undefined>({
  key: "activePackageIdAtom",
  default: undefined,
});

export const scrollToOrganisationLayerIdAtom = atom<string | undefined>({
  key: "scrollToOrganisationLayerIdAtom",
  default: undefined,
});

export const getDataLayersUsage = selectorFamily<
  ProjectUsages,
  { layerIds: string[]; nodeId: string }
>({
  key: "getDataLayersUsage",
  get:
    ({ layerIds, nodeId }) =>
    async ({ get }) => {
      get(dataPackageResourceWithAccessOnNodeStateRefreshAtom);
      return dataLayersUsage(nodeId, layerIds);
    },
});

export const getDataPackageUsage = selectorFamily<
  ProjectUsages,
  { packageId: string; organisationId: string }
>({
  key: "getDataPackageUsage",
  get:
    ({ packageId, organisationId }) =>
    async ({ get }) => {
      get(dataPackageResourceWithAccessOnNodeStateRefreshAtom);
      return dataPackageUsage(organisationId, packageId);
    },
});

export const allDataLayerSearchInputAtom = atom<string>({
  key: "allDataLayerSearchInputAtom",
  default: "",
});
