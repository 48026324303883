import { useEffect } from "react";
import { selectorFamily, useRecoilValue } from "recoil";
import { getParkFeatureSelectorFamily } from "../../state/park";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { WarningProps } from "./ValidationWarnings";
import { ParkFeature } from "../../types/feature";
import { branchIdSelector } from "../../state/pathParams";
import {
  getCablesInBranchSelectorFamily,
  MIN_CABLE_LENGTH_KM,
} from "../../state/cable";
import * as turf from "@turf/turf";

export const tooShortCablesWarningSelectorFamily = selectorFamily<
  | {
      type: ValidationWarningTypes.TooShortCables;
      featureIds: string[];
      parkId: string;
    }
  | undefined,
  {
    parkId: string;
    branchId: string;
  }
>({
  key: "tooShortCablesWarningSelectorFamily",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const cables = get(
        getCablesInBranchSelectorFamily({ parkId: parkId, branchId: branchId }),
      );

      const tooShortCables = cables.filter(
        (c) => turf.length(c, { units: "kilometers" }) < MIN_CABLE_LENGTH_KM,
      );

      return tooShortCables.length === 0
        ? undefined
        : {
            type: ValidationWarningTypes.TooShortCables,
            featureIds: tooShortCables.map((c) => c.id),
            parkId: parkId,
          };
    },
});

const TooShortCablesInner = ({
  park,
  warn,
  remove,
}: { park: ParkFeature } & WarningProps) => {
  const branchId = useRecoilValue(branchIdSelector);

  const tooShortCablesWarning = useRecoilValue(
    tooShortCablesWarningSelectorFamily({
      parkId: park.id,
      branchId: branchId ?? "",
    }),
  );

  // Update validation warning
  useEffect(() => {
    if (!tooShortCablesWarning) return;
    warn(tooShortCablesWarning);
    return () => {
      remove(ValidationWarningTypes.TooShortCables);
    };
  }, [park, remove, tooShortCablesWarning, warn]);

  useEffect(
    () => () => {
      remove(ValidationWarningTypes.TooShortCables);
    },
    [remove],
  );
  return null;
};

const TooShortCables = ({
  parkId,
  ...props
}: { parkId: string } & WarningProps) => {
  const park = useRecoilValue(getParkFeatureSelectorFamily({ parkId }));

  if (!park) return null;
  return <TooShortCablesInner park={park} {...props} />;
};

export default TooShortCables;
