import React from "react";
import { downloadText } from "utils/utils";
import DropdownButton from "components/General/Dropdown/DropdownButton";
import { TextNoMargin, DropdownText } from "../../shared";
import vindExampleFile from "../example-files/example-wind-timeseries.csv?raw";
import vortexExampleFile from "../example-files/example-vortex-timeseries.txt?raw";
import vortexGridExampleFile from "../example-files/example-vortex-grid.txt?raw";

const NoFileSelectedWrapper = () => {
  return (
    <>
      <TextNoMargin>Supported formats: .csv, .txt, .asc, .wrg</TextNoMargin>
      <div style={{ display: "flex" }}>
        <DropdownButton
          items={[
            {
              name: "Vind.ai format",
              value: "vindData",
            },
            {
              name: "Vortex format",
              value: "vortex",
            },
            {
              name: "Vortex grid format",
              value: "vortexGrid",
            },
          ]}
          onSelectItem={(val) => {
            switch (val) {
              case "vindData":
                downloadText(vindExampleFile, "example-wind-timeseries.csv");
                break;
              case "vortex":
                downloadText(
                  vortexExampleFile,
                  "example-vortex-timeseries.txt",
                );
                break;
              case "vortexGrid":
                downloadText(vortexGridExampleFile, "example-vortex-grid.asc");
                break;
            }
          }}
          buttonText="Download an example file"
          position="top"
          renderText={(text) => {
            return <DropdownText>{text}</DropdownText>;
          }}
          chevronStyle={{
            width: "0.8rem",
            height: "0.8rem",
          }}
        />
      </div>
    </>
  );
};

export default NoFileSelectedWrapper;
