import React from "react";
import { UiErrorText } from "styles/typography";

export enum InvalidTypes {
  TurbineTypeInvalid = "turbine_type_invalid",
  FoundationTypeInvalid = "foundation_type_invalid",
  CableTypeInvalid = "cable_type_invalid",
}

export type InvalidTypesStateType = {
  type: InvalidTypes;
  featureIds: string[];
};

export const invalidTypesTextMap = ({
  numFeatures,
}: {
  numFeatures: number;
}): Record<InvalidTypes, JSX.Element> => ({
  [InvalidTypes.TurbineTypeInvalid]: (
    <UiErrorText>
      {numFeatures} turbine{numFeatures === 1 ? "" : "s"} in the park ha
      {numFeatures === 1 ? "s" : "ve"} a turbine type that no longer exist.
      Please update all turbines with a valid turbine type.
    </UiErrorText>
  ),
  [InvalidTypes.FoundationTypeInvalid]: (
    <UiErrorText>
      {numFeatures} turbine{numFeatures === 1 ? "" : "s"} in the park ha
      {numFeatures === 1 ? "s" : "ve"} a foundation type that no longer exist.
      Please update all turbines with a valid foundation type.
    </UiErrorText>
  ),
  [InvalidTypes.CableTypeInvalid]: (
    <UiErrorText>
      {numFeatures} cable{numFeatures === 1 ? "" : "s"} in the park ha
      {numFeatures === 1 ? "s" : "ve"} a cable type that no longer exist. Please
      update all cables with a valid cable type.
    </UiErrorText>
  ),
});

export const InvalidTypesDisplayText = ({
  invalidTypes,
}: {
  invalidTypes: InvalidTypesStateType[];
}) => (
  <>
    <UiErrorText>Park contains invalid types:</UiErrorText>
    {invalidTypes.map((t) => {
      const errorText = invalidTypesTextMap({
        numFeatures: t.featureIds.length,
      })[t.type];
      return <React.Fragment key={t.type}>{errorText}</React.Fragment>;
    })}
  </>
);
