export const PARK_PROPERTY_TYPE = "park-polygon";
export const UploadMenuParkType = "UploadMenuPark";

export const ProjectElementMenuType = "ProjectElement";

export const MAX_AREA = 2000;
export const MAX_PARK_TURBINES = 400;
export const MAX_TOTAL_TURBINES = 1000;
export const MAX_TURBINES_OPTIMIZE = 300;
export const MIN_TURBINES_OPTIMIZE = 5;
export const MAX_MIN_SPACING = 8;
export const MIN_MIN_SPACING = 2;
export const DEFAULT_MIN_SPACING = 4;
export const MIN_AREA = 4;
export const MAX_MW_PER_AREA = 17;
export const IDEAL_MW_PER_AREA = 6;
export const MIN_MW_PER_AREA = 1.5;
export const MIN_DEPTH_FLOATING = 60;
export const MAX_DEPTH_FIXED = 60;
