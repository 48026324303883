/// <reference types="vite-plugin-svgr/client" />
import { useMemo } from "react";

import {
  allFloaterTypesSelector,
  getScalesForTurbineTypeIdsAndFoundationIds,
  turbineTypeAndFloatingFoundationCombinations,
} from "../../../../../state/foundations";
import { isDefined, isNumber } from "../../../../../utils/predicates";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { TurbineFeature } from "../../../../../types/feature";
import { ColoredGrid } from "../../../../General/Form";
import { Column } from "../../../../General/Layout";
import { valueRounding } from "../utils";
import { FloatingFoundationGenerationInner } from "./FloatingFoundationGenerationInner";
import { getFloatingFoundationTotalsSelectorFamily } from "./state";
import { allSimpleTurbineTypesSelector } from "../../../../../state/turbines";
import { ButtonWrapper } from "../../style";
import Button from "../../../../General/Button";
import {
  DashboardModalType,
  modalTypeOpenAtom,
} from "../../../../../state/modal";
import Dashboard from "@icons/24/Dashboard.svg?react";
import {
  Divider,
  ResultValue,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import { useShowScrollShadow } from "hooks/useShowScrollShadow";

export type FloatingTotalMaterial = {
  totalPrimarySteelMass: number;
  totalPrimaryConcreteMass: number;
  totalPrimaryConcreteVolume: number;
  totalSolidBallastMass: number;
  totalLiquidBallastMass: number;
  totalRebarMass: number;
  totalPostTensMass: number;
};

export const FloatingFoundationGeneration = ({
  tempLayoutFoundations,
}: {
  tempLayoutFoundations: TurbineFeature[];
}) => {
  const setModalType = useSetRecoilState(modalTypeOpenAtom);
  const { scrollBodyRef } = useShowScrollShadow(true);

  const allTurbineTypes = useRecoilValue(allSimpleTurbineTypesSelector);
  const allFloaters = useRecoilValue(allFloaterTypesSelector);

  const turbineTypeIdAndFloatingFoundationIdCombinations =
    turbineTypeAndFloatingFoundationCombinations(
      tempLayoutFoundations,
      allFloaters,
    );

  const scales = useRecoilValue(
    getScalesForTurbineTypeIdsAndFoundationIds(
      turbineTypeIdAndFloatingFoundationIdCombinations,
    ),
  );

  const foundationTypes = useMemo<string[] | undefined>(() => {
    const foundationIds = [
      ...new Set(
        tempLayoutFoundations.map((turbine) => turbine.properties.foundationId),
      ).values(),
    ];
    return foundationIds
      .map((id) => allFloaters.find((t) => t.id === id)?.name)
      .filter(isDefined);
  }, [tempLayoutFoundations, allFloaters]);

  const foundationMaterials = useMemo<string[] | undefined>(() => {
    const foundationIds = [
      ...new Set(
        tempLayoutFoundations.map((turbine) => turbine.properties.foundationId),
      ).values(),
    ];
    return foundationIds
      .map((id) => allFloaters.find((t) => t.id === id)?.material)
      .filter(isDefined)
      .filter((m, i, ar) => ar.indexOf(m) === i);
  }, [tempLayoutFoundations, allFloaters]);

  const {
    totalPrimarySteelMass,
    totalPrimaryConcreteMass,
    totalPrimaryConcreteVolume,
    totalSolidBallastMass,
    totalLiquidBallastMass,
    totalRebarMass,
    totalPostTensMass,
  } = useRecoilValue(
    getFloatingFoundationTotalsSelectorFamily({
      tempLayoutFoundations,
      scales,
      turbineTypeIdAndFloatingFoundationIdCombinations,
    }),
  );

  const concreteVolume =
    foundationMaterials?.includes("Concrete") &&
    isNumber(totalPrimaryConcreteVolume)
      ? valueRounding(totalPrimaryConcreteVolume, 100)
      : undefined;

  const concreteWeight =
    foundationMaterials?.includes("Concrete") &&
    isNumber(totalPrimaryConcreteMass)
      ? valueRounding(totalPrimaryConcreteMass / 1000, 100)
      : undefined;

  const primarySteelWeight =
    foundationMaterials?.includes("Steel") && isNumber(totalPrimarySteelMass)
      ? valueRounding(totalPrimarySteelMass / 1000, 100)
      : undefined;

  const solidBallastWeight =
    totalSolidBallastMass && isNumber(totalSolidBallastMass)
      ? valueRounding(totalSolidBallastMass / 1000, 100)
      : undefined;

  const liquidBallastWeight =
    totalLiquidBallastMass && isNumber(totalLiquidBallastMass)
      ? valueRounding(totalLiquidBallastMass / 1000, 100)
      : undefined;

  const reinforcementWeight =
    foundationMaterials?.includes("Concrete") && isNumber(totalRebarMass)
      ? valueRounding(totalRebarMass / 1000, 10)
      : undefined;

  const postTensionCableWeights =
    foundationMaterials?.includes("Concrete") && isNumber(totalPostTensMass)
      ? valueRounding(totalPostTensMass / 1000, 10)
      : undefined;

  return (
    <Column style={{ padding: 0, gap: 0 }}>
      <div
        ref={scrollBodyRef}
        style={{
          maxHeight: "calc(100vh - 40rem)",
          overflowY: "auto",
        }}
      >
        <ColoredGrid>
          <ResultValue>Foundations</ResultValue>
          <ResultValue>
            <strong>{tempLayoutFoundations.length}</strong>
          </ResultValue>
          <ResultValue>Foundation type(s)</ResultValue>
          <ResultValue>
            {" "}
            <strong>{foundationTypes?.join(", ") ?? ""} </strong>
          </ResultValue>
          <ResultValue>Primary material</ResultValue>
          <ResultValue>
            {" "}
            <strong>{foundationMaterials?.join(", ") ?? ""}</strong>{" "}
          </ResultValue>
        </ColoredGrid>

        {turbineTypeIdAndFloatingFoundationIdCombinations.map((combination) => {
          const t_id = combination.turbineTypeId;
          const f_id = combination.foundationId;
          const scale = scales[`${t_id},${f_id}`];

          const foundation = allFloaters.find((f) => f.id === f_id);
          const turbineType = allTurbineTypes.find((tt) => tt.id === t_id);

          return (
            <FloatingFoundationGenerationInner
              key={t_id + f_id}
              currentFoundation={foundation}
              turbineTypeId={combination.turbineTypeId}
              turbineTypeName={turbineType?.name}
              scale={scale}
            />
          );
        })}

        <SubtitleWithLine text={"Selected area totals"} />
        <ColoredGrid
          style={{
            gridTemplateColumns: "1.5fr 1fr",
          }}
        >
          {isDefined(concreteVolume) && (
            <>
              <ResultValue>Concrete volume</ResultValue>
              <ResultValue>
                <strong>{concreteVolume.toLocaleString()}</strong> m³
              </ResultValue>
            </>
          )}
          {isDefined(concreteWeight) && (
            <>
              <ResultValue>Concrete weight</ResultValue>
              <ResultValue>
                <strong>{concreteWeight.toLocaleString()}</strong> t
              </ResultValue>
            </>
          )}
          {isDefined(primarySteelWeight) && (
            <>
              <ResultValue>Primary steel weight</ResultValue>
              <ResultValue>
                <strong>{primarySteelWeight.toLocaleString()}</strong> t
              </ResultValue>
            </>
          )}
          {isDefined(solidBallastWeight) && (
            <>
              <ResultValue>Solid ballast weight</ResultValue>
              <ResultValue>
                <strong>{solidBallastWeight.toLocaleString()}</strong> t
              </ResultValue>
            </>
          )}
          {isDefined(liquidBallastWeight) && (
            <>
              <ResultValue>Liquid ballast weight</ResultValue>
              <ResultValue>
                <strong>{liquidBallastWeight.toLocaleString()}</strong> t
              </ResultValue>
            </>
          )}
          {isDefined(reinforcementWeight) && (
            <>
              <ResultValue>Reinforcement weight</ResultValue>
              <ResultValue>
                <strong>{reinforcementWeight.toLocaleString()}</strong> t
              </ResultValue>
            </>
          )}
          {isDefined(postTensionCableWeights) && (
            <>
              <ResultValue>Post tension cables weight</ResultValue>
              <ResultValue>
                <strong>{postTensionCableWeights.toLocaleString()}</strong> t
              </ResultValue>
            </>
          )}
        </ColoredGrid>
      </div>
      <Divider style={{ marginLeft: "-2rem", marginRight: "-2rem" }} />
      <ButtonWrapper>
        <Button
          text="View in dashboard"
          buttonType="secondary"
          icon={<Dashboard />}
          onClick={() => {
            setModalType({
              modalType: DashboardModalType,
              metadata: { id: "vind-preset-dashboard-foundation" },
            });
          }}
        />
      </ButtonWrapper>
    </Column>
  );
};
