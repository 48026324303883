import { currentSelectionArrayAtom } from "./../../state/selection";
import { SetterOrUpdater, useRecoilCallback, useRecoilValue } from "recoil";
import {
  canvasLineLayerSource,
  canvasPolygonLayerSource,
  canvasPointLayerSource,
} from "../../constants/canvas";
import {
  SUB_AREA_PROPERTY_TYPE,
  SUB_AREA_COLOR,
  DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
  EXCLUSION_ZONE_COLOR,
} from "../../constants/division";
import {
  DrawLineStringMenuType,
  DrawParkMenuType,
  DrawPolygonMenuType,
  DrawPointMenuType,
  turbinesEditableSourceId,
  DrawPortMenuType,
  DrawAnchorMenuType,
  DrawExistingTurbineMenuType,
  DrawMooringLineMenuType,
  DrawViewPointMenuType,
  DrawCircleMenuType,
  DrawRectangleMenuType,
} from "../../constants/draw";
import {
  ANCHOR_PROPERTY_TYPE,
  EXISTING_TURBINE_PROPERTY_TYPE,
  MOORING_LINE_PROPERTY_TYPE,
  PORT_POINT_TYPE,
  TURBINE_PROPERTY_TYPE,
} from "../../constants/projectMapView";
import { PARK_PROPERTY_TYPE } from "../../constants/park";
import {
  AddSubStationMenuType,
  CABLE_CORRIDOR_PROPERTY_TYPE,
  CABLE_PROPERTY_TYPE,
  DrawCableCorridorMenuType,
  DrawCableMenuType,
  DrawExportCableMenuType,
  EXPORT_CABLE_PROPERTY_TYPE,
  SUBSTATION_PROPERTY_TYPE,
} from "../../constants/cabling";
import { zoneLayerSource } from "../../constants/zones";
import { LeftMenuModeType, leftMenuModeActiveAtom } from "../../state/filter";
import {
  addFeatureAtom,
  editFeaturesAtom,
  mapControlsAtom,
  mapRefAtom,
} from "../../state/map";
import { currentTurbineIdAtom } from "../../state/turbines";
import {
  PlaceGoZoneType,
  PlaceNoGoZoneType,
} from "../DivisionDesign/DivisionConst";
import { PlaceWindTurbinesMenuType } from "../GenerateWindTurbines/PlaceWindTurbines";
import { AddFeatureOptions } from "../../types/map";
import { getParkFeaturesSelector } from "../../state/park";
import { colors } from "../../styles/colors";
import { sendInfo } from "utils/sentry";
import { resetListIfNotAlreadyEmpty } from "../../utils/resetList";
import { getLargestTurbineLabelSelectorFamily } from "../../state/layout";
import { ParkFeature, exclusionDomainPack } from "../../types/feature";
import { parkIdSelector, projectIdSelector } from "state/pathParams";
import {
  anchorSourceId,
  cableCorridorSourceId,
  cableSourceId,
  existingTurbineSourceId,
  exportCableSourceId,
  mooringLineSourceId,
  portSourceId,
  viewPointSourceId,
} from "components/Mapbox/constants";
import { useEffect } from "react";
import {
  currentExportCableIdAtom,
  currentSubstationIdAtom,
  getExportCablesSelectorFamily,
  getSubstationsSelectorFamily,
} from "state/cable";
import { currentSubstationTypesState } from "state/substationType";
import { pointInPolygon } from "utils/geometry";
import { Feature, Point } from "geojson";
import * as turf from "@turf/turf";
import { VIEW_POINT_TYPE } from "@constants/viewPoint";
import { isAnchor, isSubstation, isTurbine } from "utils/predicates";
import { parkChildrenSelector } from "components/ProjectElements/state";
import {
  AnchorLineError,
  CableConnectionError,
} from "components/CanvasSelectOption/TypeSelector/LineStringTypeSelector/utils";

export const newParkName = (numParks: number) => `Park ${numParks + 1}`;
export const newExportCableName = (numExportCables: number) =>
  `Export cable ${numExportCables + 1}`;

export const useDrawMode = (): [
  LeftMenuModeType,
  SetterOrUpdater<LeftMenuModeType>,
] => {
  const leftMenuModeActive = useRecoilValue(leftMenuModeActiveAtom);

  const setMapDrawMode = useRecoilCallback(
    ({ snapshot, set }) =>
      async (
        mode:
          | undefined
          | MapboxDraw.DrawMode
          | "draw_circle"
          | "draw_rectangle",
        options?: AddFeatureOptions,
        continuePlacing?: boolean,
        getPropertiesFunc?: (
          features: Feature,
        ) => Record<string, unknown> | Error,
        drawModeSettings?: Record<string, unknown>,
      ) => {
        const mapControls = await snapshot.getPromise(mapControlsAtom);
        const map = snapshot.getLoadable(mapRefAtom).valueMaybe();
        if (!mapControls) return;
        if (map) {
          if (map.hasControl(mapControls)) {
            mapControls.deleteAll();
            mapControls.changeMode(
              // @ts-ignore: The overloads for MapControls.changeMode cause this to
              // not typecheck, even though it's fine.  Even
              // `mapControls.changeMode(mode)` doesnt work, since the overload in the
              // type definition is for `Exclude<DrawMode, ...>`.
              mode === undefined ? mapControls.modes.SIMPLE_SELECT : mode,
              drawModeSettings,
            );
          } else {
            sendInfo(
              "MapControls not found in map when trying to change mode",
              {
                refIssue:
                  "https://vind-technologies.sentry.io/issues/5183561431",
              },
            );
          }
        }

        set(editFeaturesAtom, (s) => resetListIfNotAlreadyEmpty(s));
        if (mode !== undefined)
          set(currentSelectionArrayAtom, (s) => resetListIfNotAlreadyEmpty(s));

        set(
          addFeatureAtom,
          mode === undefined
            ? undefined
            : options
              ? {
                  mode,
                  options,
                  continuePlacing,
                  getPropertiesFunc,
                }
              : { mode },
        );
      },
    [],
  );

  const handleValueChange = useRecoilCallback(
    ({ snapshot }) =>
      async (
        leftMenuActiveMode: LeftMenuModeType,
        previousLeftMenuActiveMode: LeftMenuModeType,
      ) => {
        if (leftMenuActiveMode === DrawLineStringMenuType) {
          setMapDrawMode("draw_line_string", {
            source: canvasLineLayerSource,
          });
        } else if (leftMenuActiveMode === DrawParkMenuType) {
          const parks = await snapshot.getPromise(getParkFeaturesSelector);
          setMapDrawMode("draw_polygon", {
            type: PARK_PROPERTY_TYPE,
            color: colors.park2,
            name: newParkName(parks.length),
            source: "wat",
          });
        } else if (leftMenuActiveMode === PlaceGoZoneType) {
          const parkId = await snapshot.getPromise(parkIdSelector);
          const parks = await snapshot.getPromise(getParkFeaturesSelector);
          if (parkId)
            setMapDrawMode(
              "draw_polygon",
              {
                type: SUB_AREA_PROPERTY_TYPE,
                color: SUB_AREA_COLOR,
                source: zoneLayerSource,
                parentIds: [parkId],
                name: "Sub area",
              },
              false,
              (feature) => {
                const intersectingParks = parks.filter(
                  (p) =>
                    turf.intersect(p, feature as Feature<turf.Polygon>) != null,
                );

                const parentIds = [
                  intersectingParks.length === 1
                    ? intersectingParks[0]!.id
                    : parkId,
                ];

                return {
                  parentIds,
                };
              },
            );
        } else if (leftMenuActiveMode === PlaceNoGoZoneType) {
          setMapDrawMode(
            "draw_polygon",
            {
              type: DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
              color: EXCLUSION_ZONE_COLOR,
              source: zoneLayerSource,
              domain: exclusionDomainPack({
                turbine: true,
                cable: true,
                substation: true,
                anchor: true,
              }),
              name: "Exclusion zone",
            },
            undefined,
            undefined,
            {
              warnIfTooBig: false,
            },
          );
        } else if (leftMenuActiveMode === DrawCircleMenuType) {
          setMapDrawMode("draw_circle", {
            source: canvasPolygonLayerSource,
          });
        } else if (leftMenuActiveMode === DrawRectangleMenuType) {
          setMapDrawMode("draw_rectangle", {
            source: canvasPolygonLayerSource,
          });
        } else if (leftMenuActiveMode === DrawPolygonMenuType) {
          setMapDrawMode(
            "draw_polygon",
            {
              source: canvasPolygonLayerSource,
            },
            undefined,
            undefined,
            {
              warnIfTooBig: false,
            },
          );
        } else if (leftMenuActiveMode === DrawPointMenuType) {
          setMapDrawMode("draw_point", {
            source: canvasPointLayerSource,
          });
        } else if (leftMenuActiveMode === DrawExportCableMenuType) {
          const parkId = await snapshot.getPromise(parkIdSelector);
          if (parkId) {
            const exportCables = await snapshot.getPromise(
              getExportCablesSelectorFamily({ parkId }),
            );
            const currentExportCable = await snapshot.getPromise(
              currentExportCableIdAtom,
            );
            const substations = await snapshot.getPromise(
              getSubstationsSelectorFamily({ parkId }),
            );
            const substationTypes = await snapshot.getPromise(
              currentSubstationTypesState,
            );
            const offshoreSubstations = substations.filter(
              (s) =>
                substationTypes.find(
                  (st) => st.id === s.properties.substationTypeId,
                )?.type === "offshore",
            );
            const onshoreSubstations = substations.filter(
              (s) =>
                substationTypes.find(
                  (st) => st.id === s.properties.substationTypeId,
                )?.type === "onshore",
            );
            if (
              !currentExportCable ||
              onshoreSubstations.length === 0 ||
              offshoreSubstations.length === 0
            )
              return;
            setMapDrawMode("draw_line_string", {
              source: exportCableSourceId,
              parentIds: [parkId],
              name: newExportCableName(exportCables.length),
              cableTypeId: currentExportCable.offshore,
              onshoreCableTypeId: currentExportCable.onshore,
              type: EXPORT_CABLE_PROPERTY_TYPE,
            });
          }
        } else if (leftMenuActiveMode === DrawCableCorridorMenuType) {
          const parkId = await snapshot.getPromise(parkIdSelector);
          const parks = await snapshot.getPromise(getParkFeaturesSelector);
          const findParkOverlap = (
            parkFeature: ParkFeature,
            feature: Feature<turf.Polygon>,
          ) => {
            const union = turf.union(parkFeature, feature);
            return union != null && !union.geometry.type.includes("Multi");
          };
          if (parkId)
            setMapDrawMode(
              "draw_polygon",
              {
                source: cableCorridorSourceId,
                parentIds: [parkId],
                name: "Cable corridor",
                type: CABLE_CORRIDOR_PROPERTY_TYPE,
              },
              false,
              (feature: Feature) => {
                const overlappingParks = parks.filter((p) =>
                  findParkOverlap(p, feature as Feature<turf.Polygon>),
                );

                const parentIds = [
                  overlappingParks.length === 1
                    ? overlappingParks[0]!.id
                    : parkId,
                ];
                return {
                  parentIds,
                };
              },
              {
                warnIfTooBig: false,
              },
            );
        } else if (leftMenuActiveMode === DrawMooringLineMenuType) {
          const parkId = await snapshot.getPromise(parkIdSelector);
          const parkFeatures = await snapshot.getPromise(
            parkChildrenSelector({ parkId: parkId ?? "" }),
          );

          if (parkId)
            setMapDrawMode(
              "draw_line_string",
              {
                source: mooringLineSourceId,
                parentIds: [parkId],
                name: "Mooring line",
                type: MOORING_LINE_PROPERTY_TYPE,
                color: colors.mooringLine,
                slack: 0.3,
              },
              undefined,
              (feature) => {
                if (!("coordinates" in feature.geometry)) {
                  return {};
                }

                const start = feature.geometry.coordinates.at(0) as number[];
                const end = feature.geometry.coordinates.at(-1) as number[];
                if (!start || !end) {
                  return {};
                }

                const anchors = parkFeatures.filter(isAnchor);
                const turbines = parkFeatures.filter(isTurbine);

                const closestAnchors = anchors
                  .map((anchor) => {
                    const distanceToStart = turf.distance(
                      anchor,
                      turf.point(start),
                      {
                        units: "meters",
                      },
                    );
                    const distanceToEnd = turf.distance(
                      anchor,
                      turf.point(end),
                      {
                        units: "meters",
                      },
                    );
                    return {
                      distance: Math.min(distanceToStart, distanceToEnd),
                      anchor,
                    };
                  })
                  .filter(({ distance }) => distance < 1)
                  .sort((a, b) => a.distance - b.distance);

                const closestTurbines = turbines
                  .map((turbine) => {
                    const distanceToStart = turf.distance(
                      turbine,
                      turf.point(start),
                      {
                        units: "meters",
                      },
                    );
                    const distanceToEnd = turf.distance(
                      turbine,
                      turf.point(end),
                      {
                        units: "meters",
                      },
                    );
                    return {
                      distance: Math.min(distanceToStart, distanceToEnd),
                      turbine,
                    };
                  })
                  .filter(({ distance }) => distance < 1)
                  .sort((a, b) => a.distance - b.distance);

                if (
                  closestTurbines.length === 0 ||
                  closestAnchors.length === 0
                ) {
                  return new AnchorLineError(
                    "Mooring lines must connect to an anchor and a turbine",
                  );
                }

                return {
                  anchor: closestAnchors[0]?.anchor.id,
                  target: closestTurbines[0]?.turbine.id,
                };
              },
            );
        } else if (leftMenuActiveMode === DrawCableMenuType) {
          const parkId = await snapshot.getPromise(parkIdSelector);
          const projectFeatures = await snapshot.getPromise(
            parkChildrenSelector({ parkId: parkId ?? "" }),
          );
          if (parkId)
            setMapDrawMode(
              "draw_line_string",
              {
                source: cableSourceId,
                parentIds: [parkId],
                name: "Cable",
                type: CABLE_PROPERTY_TYPE,
                color: colors.cable,
              },
              undefined,
              (feature) => {
                if (!("coordinates" in feature.geometry)) {
                  return {};
                }

                const start = feature.geometry.coordinates.at(0) as number[];
                const end = feature.geometry.coordinates.at(-1) as number[];
                if (!start || !end) {
                  return {};
                }

                const turbines = projectFeatures.filter(isTurbine);
                const substations = projectFeatures.filter(isSubstation);
                const turbinesAndSubstations = [...turbines, ...substations];

                const featuresCloseToStart = turbinesAndSubstations
                  .map((turbine) => {
                    return {
                      distance: turf.distance(turbine, turf.point(start), {
                        units: "meters",
                      }),
                      turbine,
                    };
                  })
                  .filter(({ distance }) => distance < 1)
                  .sort((a, b) => a.distance - b.distance);

                const featuresCloseToEnd = turbinesAndSubstations
                  .map((turbine) => {
                    return {
                      distance: turf.distance(turbine, turf.point(end), {
                        units: "meters",
                      }),
                      turbine,
                    };
                  })
                  .filter(({ distance }) => distance < 1)
                  .sort((a, b) => a.distance - b.distance);

                if (
                  featuresCloseToEnd.length === 0 ||
                  featuresCloseToStart.length === 0
                ) {
                  return new CableConnectionError(
                    "Cables must connect turbines or a substation",
                  );
                }

                return {
                  fromId: featuresCloseToStart[0]?.turbine.id,
                  toId: featuresCloseToEnd[0]?.turbine?.id,
                };
              },
            );
        } else if (leftMenuActiveMode === PlaceWindTurbinesMenuType) {
          const parkId = await snapshot.getPromise(parkIdSelector);
          const parks = await snapshot.getPromise(getParkFeaturesSelector);
          const projectId = await snapshot.getPromise(projectIdSelector);
          const currentTurbine = await snapshot.getPromise(
            currentTurbineIdAtom({
              projectId,
            }),
          );
          if (!currentTurbine) return;
          if (!parkId) return;
          const maxTurbineId = await snapshot.getPromise(
            getLargestTurbineLabelSelectorFamily({ parkId }),
          );

          setMapDrawMode(
            "draw_point",
            {
              source: turbinesEditableSourceId,
              type: TURBINE_PROPERTY_TYPE,
              parentIds: [parkId],
              turbineTypeId: currentTurbine,
              name: `#${maxTurbineId + 1}`,
            },
            true,
            (feature: Feature) => {
              const parksOnPoint = parks.filter((p) =>
                pointInPolygon(feature.geometry as Point, p.geometry),
              );

              const parentIds = [
                parksOnPoint.length === 1 ? parksOnPoint[0]!.id : parkId,
              ];
              return {
                parentIds,
              };
            },
          );
        } else if (leftMenuActiveMode === AddSubStationMenuType) {
          const parkId = await snapshot.getPromise(parkIdSelector);
          const parks = await snapshot.getPromise(getParkFeaturesSelector);
          const currentSubstation = await snapshot.getPromise(
            currentSubstationIdAtom,
          );
          if (!currentSubstation) return;
          if (parkId)
            setMapDrawMode(
              "draw_point",
              {
                source: turbinesEditableSourceId,
                type: SUBSTATION_PROPERTY_TYPE,
                parentIds: [parkId],
                name: "Substation",
                substationTypeId: currentSubstation,
                color: colors.substation,
              },
              false,
              (feature: Feature) => {
                const parksOnPoint = parks.filter((p) =>
                  pointInPolygon(feature.geometry as Point, p.geometry),
                );
                const parentIds = [
                  parksOnPoint.length === 1 ? parksOnPoint[0]!.id : parkId,
                ];
                return {
                  parentIds,
                };
              },
            );
        } else if (leftMenuActiveMode === DrawViewPointMenuType) {
          setMapDrawMode("draw_point", {
            source: viewPointSourceId,
            type: VIEW_POINT_TYPE,
            name: "View point",
            color: colors.viewpoint,
          });
        } else if (leftMenuActiveMode === DrawPortMenuType) {
          setMapDrawMode("draw_point", {
            source: portSourceId,
            type: PORT_POINT_TYPE,
            name: "Port",
            color: colors.port,
          });
        } else if (leftMenuActiveMode === DrawAnchorMenuType) {
          const parkId = await snapshot.getPromise(parkIdSelector);
          if (parkId)
            setMapDrawMode("draw_point", {
              source: anchorSourceId,
              type: ANCHOR_PROPERTY_TYPE,
              parentIds: [parkId],
              name: "Anchor",
              color: colors.anchor,
            });
        } else if (leftMenuActiveMode === DrawExistingTurbineMenuType) {
          setMapDrawMode("draw_point", {
            source: existingTurbineSourceId,
            type: EXISTING_TURBINE_PROPERTY_TYPE,
            name: "Existing turbine",
            color: colors.existingTurbine,
          });
        } else if (previousLeftMenuActiveMode !== leftMenuActiveMode) {
          setMapDrawMode(undefined);
        }
      },
    [setMapDrawMode],
  );

  // When the current turbine/substation/export cable changes, we need up update the draw mode to
  // use the new turbine.  Call handleValueChange to do this.
  const projectId = useRecoilValue(projectIdSelector);
  const currentTurbineId = useRecoilValue(currentTurbineIdAtom({ projectId }));
  const currentSubstation = useRecoilValue(currentSubstationIdAtom);
  const currentExportCable = useRecoilValue(currentExportCableIdAtom);
  useEffect(() => {
    if (leftMenuModeActive === PlaceWindTurbinesMenuType)
      handleValueChange(PlaceWindTurbinesMenuType, PlaceWindTurbinesMenuType);
    else if (leftMenuModeActive === AddSubStationMenuType)
      handleValueChange(AddSubStationMenuType, AddSubStationMenuType);
    else if (leftMenuModeActive === DrawExportCableMenuType)
      handleValueChange(DrawExportCableMenuType, DrawExportCableMenuType);
    else return;
  }, [
    currentTurbineId,
    currentSubstation,
    currentExportCable,
    handleValueChange,
    leftMenuModeActive,
  ]);

  const setter: SetterOrUpdater<LeftMenuModeType> = useRecoilCallback(
    ({ snapshot, set }) =>
      async (valOrFn) => {
        const currVal = await snapshot.getPromise(leftMenuModeActiveAtom);
        const nextVal =
          typeof valOrFn === "function" ? valOrFn(currVal) : valOrFn;
        handleValueChange(nextVal, currVal);
        set(leftMenuModeActiveAtom, nextVal);
        return valOrFn;
      },
    [handleValueChange],
  );

  return [leftMenuModeActive, setter];
};
