import { typography } from "styles/typography";
import styled from "styled-components";
import { colors } from "styles/colors";
import { Column, Row } from "components/General/Layout";

const Wrapper = styled.div`
  width: 100%;
  max-width: 35rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.4rem;
  margin: 0;
  border-radius: var(--Radius, 0.4rem);
  gap: 1.2rem;

  background: ${colors.surfaceInfo};
`;

const Title = styled.p`
  ${typography.sub2}
  margin: 0;
  text-align: center;
`;

const Description = styled.p`
  ${typography.label}
  margin: 0;
  text-align: center;
  line-height: 120%;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  width: 4rem;
  height: 4rem;
  cursor: auto;
  border-radius: 50%;
  background-color: ${colors.surfaceBrand};
`;

const IconWrapper = styled.div`
  svg {
    width: 1.6rem;
    height: 1.6rem;
    overflow: initial; // Icons that are slightly larger than the viewPort due to weird scaling should not be clipped.

    path,
    line {
      fill: #ffffff;
    }
  }
`;

export const EmptyState = ({
  title,
  description = "",
  actionButton,
  actionButtonSecondary,
  icon,
  style,
}: {
  title: string;
  description?: string;
  actionButton?: JSX.Element;
  actionButtonSecondary?: JSX.Element;
  icon: JSX.Element;
  style?: React.CSSProperties;
}) => {
  return (
    <Wrapper style={style}>
      <IconContainer>
        <IconWrapper>{icon}</IconWrapper>
      </IconContainer>

      <Column style={{ gap: "0.4rem" }}>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Column>
      <Row>
        {actionButtonSecondary}
        {actionButton}
      </Row>
    </Wrapper>
  );
};
