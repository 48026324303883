/// <reference types="vite-plugin-svgr/client" />
import {
  CostConfiguration,
  CostType,
  FoundationMaterialCost,
  isFoundationMaterialCost,
} from "../../../../services/costService";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import {
  localCostConfigurationAtom,
  useLocalCostConfigurationCrud,
} from "../state";
import Foundations from "@icons/24/Foundation.svg?react";
import { GeneralCost } from "./General/GeneralCost";
import { FixedCost } from "./General/FixedCost";
import { ConfidenceLevel, CostUnit } from "../../../../types/financial";
import { FirstRowItem, FirstRowWrapper } from "../Capex";
import {
  CostDivider,
  DividerWrapper,
  GridWithFourColumns,
  Tag,
} from "../styles";
import { DefaultCosts } from "../constants";
import { Input, InputDimensioned } from "components/General/Input";
import { isDefined } from "utils/predicates";
import Button from "components/General/Button";
import PencilIcon from "@icons/24/Pencil.svg";
import { Row } from "components/General/Layout";
import LogoIcon from "@icons/24/VindLogo.svg?react";
import { IconREMSize, typography } from "styles/typography";
import { colors } from "styles/colors";
import Tooltip from "components/General/Tooltip";
import { IconBtn } from "components/General/Icons";
import UndoIcon from "@icons/24/Undo.svg";
import { ConfidenceLevelSelector } from "../ConfidenceLevelSelector";
import styled from "styled-components";
import { FoundationCategorySelector } from "../FoundationCategorySelector";

const FoundationCategory = styled.div`
  ${typography.caption}
  color: ${colors.textSecondary};
  padding: 0.8rem 0;
`;

export const foundationCategoriesToDisplayAtom = atom<string[]>({
  key: "foundationCategoriesToDisplayAtom",
  default: ["Jacket", "Monopile", "Floating"],
});

const FoundationSpecificCosts = ({ isReadOnly }: { isReadOnly?: boolean }) => {
  const localConfig: CostConfiguration = useRecoilValue(
    localCostConfigurationAtom,
  );
  const [, setFoundationCategoriesToDisplay] = useRecoilState(
    foundationCategoriesToDisplayAtom,
  );

  const useConfidenceLevel = localConfig.useConfidenceLevel;

  const { updateFixedCapex } = useLocalCostConfigurationCrud();

  const handleFoundationCostTypeChange =
    (type: "material" | "override") => () => {
      if (type === "material") {
        updateFixedCapex({
          foundations: {
            floating: {
              primarySteel: {
                unit: CostUnit.euroPerT,
                cost: DefaultCosts[CostType.Foundation].primarySteel[
                  CostUnit.euroPerT
                ],
                confidenceLevel: ConfidenceLevel.notSpecified,
              },
              concrete: {
                unit: CostUnit.euroPerM3,
                cost: DefaultCosts[CostType.Foundation].concrete[
                  CostUnit.euroPerM3
                ],
                confidenceLevel: ConfidenceLevel.notSpecified,
              },
              reinforcement: {
                unit: CostUnit.euroPerT,
                cost: DefaultCosts[CostType.Foundation].reinforcement[
                  CostUnit.euroPerT
                ],
                confidenceLevel: ConfidenceLevel.notSpecified,
              },
              postTensionCables: {
                unit: CostUnit.euroPerT,
                cost: DefaultCosts[CostType.Foundation].postTensionCables[
                  CostUnit.euroPerT
                ],
                confidenceLevel: ConfidenceLevel.notSpecified,
              },
              solidBallast: {
                unit: CostUnit.euroPerT,
                cost: DefaultCosts[CostType.Foundation].solidBallast[
                  CostUnit.euroPerT
                ],
                confidenceLevel: ConfidenceLevel.notSpecified,
              },
            },
            monopile: {
              primarySteel: {
                unit: CostUnit.euroPerT,
                cost: DefaultCosts[CostType.Foundation].primarySteel[
                  CostUnit.euroPerT
                ],
                confidenceLevel: ConfidenceLevel.notSpecified,
              },
            },
            jacket: {
              primarySteel: {
                unit: CostUnit.euroPerT,
                cost: DefaultCosts[CostType.Foundation].primarySteel[
                  CostUnit.euroPerT
                ],
                confidenceLevel: ConfidenceLevel.notSpecified,
              },
            },
          },
        });
      } else {
        updateFixedCapex({
          foundations: {
            cost: DefaultCosts[CostType.Foundation].foundation[
              CostUnit.millionEuroPerUnit
            ],
            unit: CostUnit.millionEuroPerUnit,
            confidenceLevel: ConfidenceLevel.notSpecified,
          },
        });
      }
    };

  const { foundations } = localConfig.capex.fixed;

  const isUsingDefaultFoundationCost =
    !isFoundationMaterialCost(foundations) &&
    foundations.cost ===
      DefaultCosts[CostType.Foundation].foundation[
        CostUnit.millionEuroPerUnit
      ] &&
    foundations.unit === CostUnit.millionEuroPerUnit;

  if (!foundations) return null;

  return (
    <>
      <FirstRowWrapper>
        <GridWithFourColumns>
          {!isFoundationMaterialCost(foundations) ? (
            <FirstRowItem>
              <Input
                value={"Foundations"}
                disabled={true}
                locked={true}
                style={{ width: "20rem" }}
              />
            </FirstRowItem>
          ) : (
            <FirstRowItem>
              <FoundationCategorySelector
                onSelectionChange={(foundationCategory) => {
                  setFoundationCategoriesToDisplay((prev) => {
                    if (prev.includes(foundationCategory)) {
                      return prev.filter((f) => f !== foundationCategory);
                    }
                    return [...prev, foundationCategory];
                  });
                }}
              />
            </FirstRowItem>
          )}
          {isFoundationMaterialCost(foundations) ? (
            <Row style={{ width: "20rem" }}>
              <Tag>From materials listed below</Tag>
            </Row>
          ) : (
            <Row style={{ width: "20rem" }}>
              <InputDimensioned
                step="0.1"
                unit={foundations.unit}
                units={[CostUnit.millionEuroPerMW, CostUnit.millionEuroPerUnit]}
                type="number"
                value={foundations.cost}
                disabled={isReadOnly}
                onUnitChange={(unit) => {
                  updateFixedCapex(
                    {
                      unit,
                      cost: (DefaultCosts.foundation.foundation as any)[unit],
                    },
                    "foundations",
                  );
                }}
                validate={(value) => isDefined(value) && value >= 0}
                validationMessage={"Must be greater than or equal to 0"}
                onChange={(cost, _, unit) => {
                  updateFixedCapex(
                    {
                      cost,
                      unit,
                    },
                    "foundations",
                  );
                }}
                style={{ width: "100%" }}
              />
              {isUsingDefaultFoundationCost ? (
                <Tooltip text={"Vind default value"}>
                  <IconREMSize height={2.6} width={2.6} fill={colors.blue700}>
                    <LogoIcon />
                  </IconREMSize>
                </Tooltip>
              ) : (
                <Tooltip text="Reset to default">
                  <IconBtn
                    size={"1.6rem"}
                    onClick={handleFoundationCostTypeChange("override")}
                  >
                    <UndoIcon />
                  </IconBtn>
                </Tooltip>
              )}
            </Row>
          )}

          {useConfidenceLevel ? (
            !isFoundationMaterialCost(foundations) ? (
              <ConfidenceLevelSelector
                disabled={isReadOnly}
                onConfidenceLevelChange={(confidenceLevel) => {
                  updateFixedCapex({
                    foundations: {
                      ...foundations,
                      confidenceLevel: confidenceLevel,
                    },
                  });
                }}
                confidenceLevel={foundations.confidenceLevel}
              />
            ) : (
              <div></div>
            )
          ) : (
            <div></div>
          )}

          <Button
            size="small"
            icon={isFoundationMaterialCost(foundations) && <PencilIcon />}
            buttonType="secondary"
            text={
              isFoundationMaterialCost(foundations)
                ? "Override"
                : "Use matierals"
            }
            onClick={
              isFoundationMaterialCost(foundations)
                ? handleFoundationCostTypeChange("override")
                : handleFoundationCostTypeChange("material")
            }
            disabled={isReadOnly}
          />
        </GridWithFourColumns>
      </FirstRowWrapper>
      {isFoundationMaterialCost(foundations) && (
        <FoundationMaterialCosts
          foundations={foundations}
          isReadOnly={isReadOnly}
        />
      )}
    </>
  );
};

const FoundationMaterialCosts = ({
  foundations,
  isReadOnly,
}: {
  foundations: FoundationMaterialCost;
  isReadOnly?: boolean;
}) => {
  const { updateFixedCapex } = useLocalCostConfigurationCrud();
  const foundationCategoriesToDisplay = useRecoilValue(
    foundationCategoriesToDisplayAtom,
  );

  const { floating, jacket, monopile } = foundations;
  if (!floating || !jacket || !monopile) return null;

  return (
    <>
      {foundationCategoriesToDisplay.includes("Jacket") && (
        <>
          <FoundationCategory>Jacket</FoundationCategory>
          <FixedCost
            title={"Primary steel"}
            unit={jacket.primarySteel.unit}
            value={jacket.primarySteel.cost}
            confidenceLevel={jacket.primarySteel.confidenceLevel}
            isReadOnly={isReadOnly}
            onValueChange={(cost) => {
              updateFixedCapex(
                {
                  jacket: {
                    ...jacket,
                    primarySteel: jacket.primarySteel && {
                      ...jacket.primarySteel,
                      cost,
                    },
                  },
                },

                "foundations",
              );
            }}
            onConfidenceLevelChange={(confidenceLevel) => {
              updateFixedCapex(
                {
                  jacket: {
                    ...jacket,
                    primarySteel: {
                      ...jacket.primarySteel,
                      confidenceLevel,
                    },
                  },
                },

                "foundations",
              );
            }}
            defaultCost={
              DefaultCosts[CostType.Foundation].primarySteel[CostUnit.euroPerT]
            }
          />
        </>
      )}
      {foundationCategoriesToDisplay.includes("Monopile") && (
        <>
          <FoundationCategory>Monopile</FoundationCategory>
          <FixedCost
            title={"Primary steel"}
            unit={monopile.primarySteel.unit}
            value={monopile.primarySteel.cost}
            confidenceLevel={monopile.primarySteel.confidenceLevel}
            isReadOnly={isReadOnly}
            onValueChange={(cost) => {
              updateFixedCapex(
                {
                  monopile: {
                    ...monopile,
                    primarySteel: monopile.primarySteel && {
                      ...monopile.primarySteel,
                      cost,
                    },
                  },
                },

                "foundations",
              );
            }}
            onConfidenceLevelChange={(confidenceLevel) => {
              updateFixedCapex(
                {
                  monopile: {
                    ...monopile,
                    primarySteel: {
                      ...monopile.primarySteel,
                      confidenceLevel,
                    },
                  },
                },

                "foundations",
              );
            }}
            defaultCost={
              DefaultCosts[CostType.Foundation].primarySteel[CostUnit.euroPerT]
            }
          />
        </>
      )}
      {foundationCategoriesToDisplay.includes("Floating") && (
        <>
          <FoundationCategory>Floating</FoundationCategory>
          <FixedCost
            title={"Primary steel"}
            unit={floating.primarySteel.unit}
            value={floating.primarySteel.cost}
            confidenceLevel={floating.primarySteel.confidenceLevel}
            isReadOnly={isReadOnly}
            onValueChange={(cost) => {
              updateFixedCapex(
                {
                  floating: {
                    ...floating,
                    primarySteel: floating.primarySteel && {
                      ...floating.primarySteel,
                      cost,
                    },
                  },
                },

                "foundations",
              );
            }}
            onConfidenceLevelChange={(confidenceLevel) => {
              updateFixedCapex(
                {
                  floating: {
                    ...floating,
                    primarySteel: {
                      ...floating.primarySteel,
                      confidenceLevel,
                    },
                  },
                },

                "foundations",
              );
            }}
            defaultCost={
              DefaultCosts[CostType.Foundation].primarySteel[CostUnit.euroPerT]
            }
          />
          <FixedCost
            title={"Concrete"}
            unit={floating.concrete.unit}
            value={floating.concrete.cost}
            confidenceLevel={floating.concrete.confidenceLevel}
            isReadOnly={isReadOnly}
            onValueChange={(cost) => {
              updateFixedCapex(
                {
                  floating: floating && {
                    ...floating,
                    concrete: {
                      ...floating.concrete,
                      cost,
                    },
                  },
                },
                "foundations",
              );
            }}
            onConfidenceLevelChange={(confidenceLevel) => {
              updateFixedCapex(
                {
                  floating: floating && {
                    ...floating,
                    concrete: {
                      ...floating.concrete,
                      confidenceLevel,
                    },
                  },
                },
                "foundations",
              );
            }}
            defaultCost={
              DefaultCosts[CostType.Foundation].concrete[CostUnit.euroPerM3]
            }
          />
          <FixedCost
            title={"Solid ballast"}
            unit={floating.solidBallast.unit}
            value={floating.solidBallast.cost}
            confidenceLevel={floating.solidBallast.confidenceLevel}
            isReadOnly={isReadOnly}
            onValueChange={(cost) => {
              updateFixedCapex(
                {
                  floating: floating && {
                    ...floating,
                    solidBallast: {
                      ...floating.solidBallast,
                      cost,
                    },
                  },
                },
                "foundations",
              );
            }}
            onConfidenceLevelChange={(confidenceLevel) => {
              updateFixedCapex(
                {
                  floating: floating && {
                    ...floating,
                    solidBallast: {
                      ...floating.solidBallast,
                      confidenceLevel,
                    },
                  },
                },
                "foundations",
              );
            }}
            defaultCost={
              DefaultCosts[CostType.Foundation].solidBallast[CostUnit.euroPerT]
            }
          />
          <FixedCost
            title={"Reinforcement"}
            unit={floating.reinforcement.unit}
            value={floating.reinforcement.cost}
            confidenceLevel={floating.reinforcement.confidenceLevel}
            isReadOnly={isReadOnly}
            onValueChange={(cost) => {
              updateFixedCapex(
                {
                  floating: floating && {
                    ...floating,
                    reinforcement: {
                      ...floating.reinforcement,
                      cost,
                    },
                  },
                },
                "foundations",
              );
            }}
            onConfidenceLevelChange={(confidenceLevel) => {
              updateFixedCapex(
                {
                  floating: floating && {
                    ...floating,
                    reinforcement: {
                      ...floating.reinforcement,
                      confidenceLevel,
                    },
                  },
                },
                "foundations",
              );
            }}
            defaultCost={
              DefaultCosts[CostType.Foundation].reinforcement[CostUnit.euroPerT]
            }
          />
          <FixedCost
            title={"Post tension cables"}
            unit={floating.postTensionCables.unit}
            value={floating.postTensionCables.cost}
            confidenceLevel={floating.postTensionCables.confidenceLevel}
            isReadOnly={isReadOnly}
            onValueChange={(cost) => {
              updateFixedCapex(
                {
                  floating: floating && {
                    ...floating,
                    postTensionCables: {
                      ...floating.postTensionCables,
                      cost,
                    },
                  },
                },
                "foundations",
              );
            }}
            onConfidenceLevelChange={(confidenceLevel) => {
              updateFixedCapex(
                {
                  floating: floating && {
                    ...floating,
                    postTensionCables: {
                      ...floating.postTensionCables,
                      confidenceLevel,
                    },
                  },
                },
                "foundations",
              );
            }}
            defaultCost={
              DefaultCosts[CostType.Foundation].postTensionCables[
                CostUnit.euroPerT
              ]
            }
          />
        </>
      )}
    </>
  );
};

export const FoundationCosts = ({ isReadOnly }: { isReadOnly?: boolean }) => {
  return (
    <GeneralCost
      title="Foundations"
      category={CostType.Foundation}
      units={[
        CostUnit.thousandEuroPerMW,
        CostUnit.millionEuroPerMW,
        CostUnit.millionEuroPerUnit,
        CostUnit.millionEuro,
      ]}
      icon={<Foundations />}
      isReadOnly={isReadOnly}
    >
      <FoundationSpecificCosts isReadOnly={isReadOnly} />
      <DividerWrapper>
        <CostDivider />
      </DividerWrapper>
    </GeneralCost>
  );
};
