import { useRecoilCallback, useRecoilValue } from "recoil";
import { Dashboard, DashboardSerialize, WidgetId, WidgetState } from "./types";
import {
  VIND_DASHBOARDS,
  dashboardSelectorFamily,
  dashboardsAtomFamily,
} from "./state";
import {
  deserializeDashboardRows,
  optimisticUpdate,
  serializeDashboard,
  serializeDashboardRows,
} from "./utils";
import { DnDRow } from "../General/DnDGrid/types";
import { isDefined } from "../../utils/predicates";
import { useCallback } from "react";
import { With, omitFields } from "../../utils/utils";
import {
  fetchCreateDashboard,
  fetchDeleteDashboard,
  fetchUpdateDashboardDebounce,
} from "./service";
import { useTypedPath } from "../../state/pathParams";

export const useDashboard = (dashboardId: string) => {
  const { projectId } = useTypedPath("projectId");
  const dashboard = useRecoilValue(
    dashboardSelectorFamily({ nodeId: projectId, dashboardId }),
  );

  const update = useRecoilCallback(
    ({ set, snapshot }) =>
      async (update: With<DashboardSerialize, "id">) => {
        if (!dashboard) return;
        if (dashboard.preset) return;

        const updateDashboard = {
          ...dashboard,
          ...update,
          rows:
            "rows" in update
              ? deserializeDashboardRows(update.rows)
              : dashboard.rows,
        };

        return optimisticUpdate(
          "dashboard-update",
          dashboardSelectorFamily({
            nodeId: projectId,
            dashboardId: dashboard.id,
          }),
          snapshot,
          set,
          updateDashboard,
          () => fetchUpdateDashboardDebounce(projectId, update),
        );
      },
    [projectId, dashboard],
  );

  const create = useRecoilCallback(
    ({ set, snapshot }) =>
      async (ds: With<Omit<DashboardSerialize, "id" | "author">, "name">) => {
        const db: Dashboard = await fetchCreateDashboard(projectId, ds);
        await snapshot.getPromise(dashboardsAtomFamily({ nodeId: projectId }));
        set(
          dashboardSelectorFamily({
            nodeId: projectId,
            dashboardId: db.id,
          }),
          db,
        );
        return db;
      },
    [projectId],
  );

  const duplicate = useRecoilCallback(
    ({ snapshot }) =>
      async (id: string) => {
        const dashboard =
          (await snapshot.getPromise(
            dashboardSelectorFamily({
              nodeId: projectId,
              dashboardId: id,
            }),
          )) ?? VIND_DASHBOARDS.find((d) => d.id === id);
        if (!dashboard) throw new Error("No dashboard to duplicate");

        const sd = serializeDashboard(dashboard);
        const newDashboard = await create({
          ...omitFields(sd, "id", "preset", "name"),
          name: `${sd.name} duplicate`,
        });
        return newDashboard;
      },
    [create, projectId],
  );

  const remove = useRecoilCallback(
    ({ set, snapshot }) =>
      (dashboardId: string) => {
        return optimisticUpdate(
          "dashboard-update",
          dashboardSelectorFamily({
            nodeId: projectId,
            dashboardId,
          }),
          snapshot,
          set,
          undefined,
          () =>
            fetchDeleteDashboard(projectId, dashboardId).then(() => undefined),
        );
      },
    [projectId],
  );

  const setRows: React.Dispatch<React.SetStateAction<DnDRow[]>> =
    useRecoilCallback(
      ({ snapshot }) =>
        async (dispatch) => {
          const dashboard = await snapshot.getPromise(
            dashboardSelectorFamily({
              nodeId: projectId,
              dashboardId,
            }),
          );
          if (!dashboard) throw new Error("No dashboard to update");

          const newRows = Array.isArray(dispatch)
            ? dispatch
            : dispatch(dashboard.rows);
          return update({
            id: dashboard.id,
            rows: serializeDashboardRows(newRows),
          });
        },
      [dashboardId, projectId, update],
    );

  const updateWidget = useRecoilCallback(
    ({ snapshot }) =>
      async <W extends WidgetId>(
        widgetId: W,
        updateWidget: Partial<WidgetState[W]>,
      ) => {
        const dashboard = await snapshot.getPromise(
          dashboardSelectorFamily({ nodeId: projectId, dashboardId }),
        );
        if (!dashboard) throw new Error("No dashboard to update");
        return update({
          id: dashboardId,
          widgetState: {
            ...dashboard?.widgetState,
            [widgetId]: {
              ...dashboard?.widgetState?.[widgetId],
              ...updateWidget,
            },
          },
        });
      },
    [projectId, dashboardId, update],
  );

  const addWidget = useCallback(
    async (id: WidgetId) => {
      if (!dashboard) return;
      const ds = serializeDashboard(dashboard);

      let newRows: DashboardSerialize["rows"] = (() => {
        let rows = ds.rows ?? [];
        if (3 <= (rows.at(-1)?.elements?.length ?? 999)) {
          const row = {
            id: Math.random().toString(),
            height: 100,
            elements: [{ id }],
          };
          return [...rows, row];
        } else {
          const last = rows.at(-1)!;
          return [
            ...rows.slice(0, -1),
            { ...last, elements: [...last.elements, { id }] },
          ];
        }
      })();

      return update({ id: dashboardId, rows: newRows });
    },
    [dashboard, dashboardId, update],
  );

  const removeWidget = useCallback(
    (id: WidgetId) => {
      if (!dashboard) return;
      const ds = serializeDashboard(dashboard);
      let newRows: DashboardSerialize["rows"] = ds.rows
        ?.map((r) => {
          let elements = r.elements.filter((e) => e.id !== id);
          if (elements.length === 0) return undefined;
          return {
            ...r,
            elements,
          };
        })
        .filter(isDefined);
      return update({ id: dashboardId, rows: newRows });
    },
    [dashboard, dashboardId, update],
  );

  return {
    addWidget,
    removeWidget,
    setRows,
    update,
    create,
    remove,
    updateWidget,
    duplicate,
  };
};
