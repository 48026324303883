import {
  E_PILE,
  E_TOWER,
  PILE_YIELD_STRESS,
  TURB_INT_REF,
  T_A,
  T_B,
  A_C,
  TURB_SCALE_PARAM,
  MATERIAL_FACTOR,
  LOAD_FACTOR,
  MAX_PILE_DEFL,
  MAX_PILE_ANGLE,
  C_D,
  C_M,
  MAX_PILE_DIAMETER,
  AVG_WIND_SPEED,
  WINDSPEED_1YR,
} from "../constants/foundations";
import { AIR_DENSITY, WATER_DENSITY } from "../constants/metocean";
import { AERO_DAMPING } from "../constants/foundations";
import { newtonRaphson } from "./newtonRapson";
import { rotorTowerSizing } from "./rotorTowerSizing";
import { PileData } from "./types";
import { SimpleTurbineType } from "types/turbines";

export const monopileSizing = ({
  soilCoeffSubReact,
  turbineType,
  waterDepth,
  hs50Yr,
}: {
  soilCoeffSubReact: number;
  turbineType: SimpleTurbineType;
  waterDepth: number;
  hs50Yr: number;
}): PileData | undefined => {
  //Sizes a monopile based on the research article "Design of monopiles for offshore wind turbines in 10 steps"
  const {
    hubHeight,
    diameter: rotorDiameter,
    peakThrustSpeed,
    peakThrustCt,
  } = turbineType;

  const { maxRotorSpeed } = rotorTowerSizing(rotorDiameter);

  const hs1Yr = 0.7 * hs50Yr;

  //Get from turbine
  const ratedWindSpeed = peakThrustSpeed ?? 11;
  const ctRated = peakThrustCt ?? 0.8;

  function roughness(z0: number) {
    return (
      z0 -
      (A_C / 9.81) *
        Math.pow((0.4 * ratedWindSpeed) / Math.log(hubHeight / z0), 2)
    );
  }

  const turbLenScale = 8 * TURB_SCALE_PARAM;
  const sigmaUEtm =
    1.4 *
    TURB_INT_REF *
    ((3 * ratedWindSpeed + 38) / 4 - (ratedWindSpeed - AVG_WIND_SPEED) / 18);
  const sigmaUEtmF1p =
    sigmaUEtm *
    Math.sqrt(
      1 /
        Math.pow(
          ((6 * turbLenScale) / ratedWindSpeed) * maxRotorSpeed + 1,
          2 / 3,
        ),
    );

  const surfaceRoughness = newtonRaphson({
    guess: 1e-3,
    increment: 1e-6,
    iterations: 50,
    eps: 1e-8,
    f: roughness,
  });
  const sigma1Rated =
    (8 * ratedWindSpeed) / Math.log(hubHeight / surfaceRoughness) +
    T_A * TURB_INT_REF -
    0.0025 * Math.pow(ratedWindSpeed, 2) -
    T_B * ratedWindSpeed;
  const uEog = Math.min(
    1.35 * (WINDSPEED_1YR - ratedWindSpeed),
    (3.3 * sigma1Rated) / (1 + (0.1 * rotorDiameter) / TURB_SCALE_PARAM),
  );
  const uEtm = 2 * sigmaUEtmF1p;

  // thrust force for ETM and EOG
  const fTEtm =
    0.5 *
    AIR_DENSITY *
    (Math.PI / 4) *
    Math.pow(rotorDiameter, 2) *
    ctRated *
    Math.pow(ratedWindSpeed + uEtm, 2);
  const fTEog =
    0.5 *
    AIR_DENSITY *
    (Math.PI / 4) *
    Math.pow(rotorDiameter, 2) *
    ctRated *
    Math.pow(ratedWindSpeed + uEog, 2);

  // mudline moment due to thrust force for ETM and EOG
  const mTEtm = fTEtm * (waterDepth + hubHeight);
  const mTEog = fTEog * (waterDepth + hubHeight);

  // max wave height and corresponding period. Period taken as minimum of recommended range (largest particle accelerations/velocities => largest wave load)
  const hm1Yr = 1.86 * hs1Yr;
  const hm50Yr = 1.86 * hs50Yr;
  const tm1Yr = 11.1 * Math.sqrt(hs1Yr / 9.81);
  const tm50Yr = 11.1 * Math.sqrt(hs50Yr / 9.81);

  const omegaM1Yr = (2 * Math.PI) / tm1Yr;
  const omegaM50Yr = (2 * Math.PI) / tm50Yr;

  // find wave number from implicit dispersion relation using a Newton-Raphson scheme
  function wavenum1yr(k: number) {
    return Math.pow(omegaM1Yr, 2) - k * 9.81 * Math.tanh(k * waterDepth);
  }

  function wavenum50yr(k: number) {
    return Math.pow(omegaM50Yr, 2) - k * 9.81 * Math.tanh(k * waterDepth);
  }

  // limit wave number to avoid the unphysical case where forces are going to infinity when the wave period goes to zero
  const waveNumberM1Yr = Math.min(
    0.1,
    newtonRaphson({
      guess: Math.pow(omegaM1Yr, 2) / 9.81,
      increment: 1e-3,
      iterations: 50,
      eps: 1e-6,
      f: wavenum1yr,
    }),
  );
  const waveNumberM50Yr = Math.min(
    0.1,
    newtonRaphson({
      guess: Math.pow(omegaM50Yr, 2) / 9.81,
      increment: 1e-3,
      iterations: 50,
      eps: 1e-6,
      f: wavenum50yr,
    }),
  );

  const pDrag1Yr =
    (Math.exp(2 * waveNumberM1Yr * (waterDepth + hm1Yr / 2)) -
      Math.exp(-2 * waveNumberM1Yr * (waterDepth + hm1Yr / 2))) /
      (8 * waveNumberM1Yr) +
    (waterDepth + hm1Yr / 2) / 2;
  const qDrag1Yr =
    ((waterDepth + hm1Yr / 2) / (8 * waveNumberM1Yr) -
      1 / (16 * Math.pow(waveNumberM1Yr, 2))) *
      Math.exp(2 * waveNumberM1Yr * (waterDepth + hm1Yr / 2)) -
    ((waterDepth + hm1Yr / 2) / (8 * waveNumberM1Yr) +
      1 / (16 * Math.pow(waveNumberM1Yr, 2))) *
      Math.exp(-2 * waveNumberM1Yr * (waterDepth + hm1Yr / 2)) +
    Math.pow((waterDepth + hm1Yr / 2) / 2, 2) +
    1 / (8 * Math.pow(waveNumberM1Yr, 2));
  const pDrag50Yr =
    (Math.exp(2 * waveNumberM50Yr * (waterDepth + hm50Yr / 2)) -
      Math.exp(-2 * waveNumberM50Yr * (waterDepth + hm50Yr / 2))) /
      (8 * waveNumberM50Yr) +
    (waterDepth + hm50Yr / 2) / 2;
  const qDrag50Yr =
    ((waterDepth + hm50Yr / 2) / (8 * waveNumberM50Yr) -
      1 / (16 * Math.pow(waveNumberM50Yr, 2))) *
      Math.exp(2 * waveNumberM50Yr * (waterDepth + hm50Yr / 2)) -
    ((waterDepth + hm50Yr / 2) / (8 * waveNumberM50Yr) +
      1 / (16 * Math.pow(waveNumberM50Yr, 2))) *
      Math.exp(-2 * waveNumberM50Yr * (waterDepth + hm50Yr / 2)) +
    Math.pow((waterDepth + hm50Yr / 2) / 2, 2) +
    1 / (8 * Math.pow(waveNumberM50Yr, 2));
  const pInertia1Yr = Math.sinh(waveNumberM1Yr * waterDepth) / waveNumberM1Yr;
  const qInertia1Yr =
    (waterDepth / (2 * waveNumberM1Yr) -
      1 / (2 * Math.pow(waveNumberM1Yr, 2))) *
      Math.exp(waveNumberM1Yr * waterDepth) -
    (waterDepth / (2 * waveNumberM1Yr) -
      1 / (2 * Math.pow(waveNumberM1Yr, 2))) *
      Math.exp(-waveNumberM1Yr * waterDepth) +
    1 / Math.pow(waveNumberM1Yr, 2);
  const pInertia50Yr =
    Math.sinh(waveNumberM50Yr * waterDepth) / waveNumberM50Yr;
  const qInertia50Yr =
    (waterDepth / (2 * waveNumberM50Yr) -
      1 / (2 * Math.pow(waveNumberM50Yr, 2))) *
      Math.exp(waveNumberM50Yr * waterDepth) -
    (waterDepth / (2 * waveNumberM50Yr) -
      1 / (2 * Math.pow(waveNumberM50Yr, 2))) *
      Math.exp(-waveNumberM50Yr * waterDepth) +
    1 / Math.pow(waveNumberM50Yr, 2);

  // length of the part of the pile above mudline (the part below mudline covered by embedLength)
  const maxPileLength = waterDepth + hm50Yr / 2 + Math.max(1, 0.2 * hs50Yr) + 2;

  var maxStressUtil = 10;
  var pileDeflUtil = 10;
  var pileAngleUtil = 10;
  var natFreqUtil = 10;

  var pileDiameter = 0;
  var avgPileThickness = 0;
  var embedLength = 0;
  var natFreq = 0;
  const step = 0.1;

  while (
    maxStressUtil > 1 ||
    pileDeflUtil > 1 ||
    pileAngleUtil > 1 ||
    natFreqUtil > 1
  ) {
    pileDiameter += step; // stepwise increase the diameter of the monopile until all design criteria are satisfied (utilization < 1.0)

    avgPileThickness = (6.35 + 10 * pileDiameter) / 1000;

    const I_pile =
      (Math.PI / 64) *
      (Math.pow(pileDiameter, 4) -
        Math.pow(pileDiameter - 2 * avgPileThickness, 4));

    // soil calcs assumes linear soil profile and rigid pile. This is the max length at which the pile can be considered rigid, according to Poulos and Davis (1980)
    embedLength = 2 * Math.pow((E_PILE * I_pile) / soilCoeffSubReact, 0.2);

    const K_l = (1 / 2) * Math.pow(embedLength, 2) * soilCoeffSubReact;
    const K_lr = (-1 / 3) * Math.pow(embedLength, 3) * soilCoeffSubReact;
    const K_r = (1 / 4) * Math.pow(embedLength, 4) * soilCoeffSubReact;

    // natural frequency taking monopile flexibility and soil stiffness into account
    natFreq = monopileNaturalFrequency({
      pileDiameter,
      avgPileThickness,
      embedLength,
      totalPileLength: maxPileLength + embedLength,
      waterDepth: waterDepth,
      soilCoeffSubReact,
      turbineType,
    });

    // dynamic amplification factors, assuming only aerodynamic damping
    const daf1yr = Math.min(
      1 /
        Math.sqrt(
          Math.pow(1 - 1 / Math.pow(tm1Yr * natFreq, 2), 2) +
            Math.pow((2 * AERO_DAMPING * 1) / (tm1Yr * natFreq), 2),
        ),
      1.3,
    );
    const daf50yr = Math.min(
      1 /
        Math.sqrt(
          Math.pow(1 - 1 / Math.pow(tm50Yr * natFreq, 2), 2) +
            Math.pow((2 * AERO_DAMPING * 1) / (tm50Yr * natFreq), 2),
        ),
      1.3,
    );

    const fDrag1Yr =
      ((0.5 *
        WATER_DENSITY *
        pileDiameter *
        C_D *
        Math.pow(Math.PI, 2) *
        Math.pow(hm1Yr, 2)) /
        (Math.pow(tm1Yr, 2) *
          Math.pow(Math.sinh(waveNumberM1Yr * waterDepth), 2))) *
      pDrag1Yr;
    const mDrag1Yr =
      ((0.5 *
        WATER_DENSITY *
        pileDiameter *
        C_D *
        Math.pow(Math.PI, 2) *
        Math.pow(hm1Yr, 2)) /
        (Math.pow(tm1Yr, 2) * Math.sinh(waveNumberM1Yr * waterDepth))) *
      qDrag1Yr;
    const fDrag50Yr =
      ((0.5 *
        WATER_DENSITY *
        pileDiameter *
        C_D *
        Math.pow(Math.PI, 2) *
        Math.pow(hm50Yr, 2)) /
        (Math.pow(tm50Yr, 2) *
          Math.pow(Math.sinh(waveNumberM50Yr * waterDepth), 2))) *
      pDrag50Yr;
    const mDrag50Yr =
      ((0.5 *
        WATER_DENSITY *
        pileDiameter *
        C_D *
        Math.pow(Math.PI, 2) *
        Math.pow(hm50Yr, 2)) /
        (Math.pow(tm50Yr, 2) * Math.sinh(waveNumberM50Yr * waterDepth))) *
      qDrag50Yr;

    const fInertia1Yr =
      ((0.5 *
        WATER_DENSITY *
        Math.pow(pileDiameter, 2) *
        C_M *
        Math.pow(Math.PI, 3) *
        hm1Yr) /
        (Math.pow(tm1Yr, 2) * Math.sinh(waveNumberM1Yr * waterDepth))) *
      pInertia1Yr;
    const mInertia1Yr =
      ((0.5 *
        WATER_DENSITY *
        Math.pow(pileDiameter, 2) *
        C_M *
        Math.pow(Math.PI, 3) *
        hm1Yr) /
        (Math.pow(tm1Yr, 2) * Math.sinh(waveNumberM1Yr * waterDepth))) *
      qInertia1Yr;
    const fInertia50Yr =
      ((0.5 *
        WATER_DENSITY *
        Math.pow(pileDiameter, 2) *
        C_M *
        Math.pow(Math.PI, 3) *
        hm50Yr) /
        (Math.pow(tm50Yr, 2) * Math.sinh(waveNumberM50Yr * waterDepth))) *
      pInertia50Yr;
    const mInertia50Yr =
      ((0.5 *
        WATER_DENSITY *
        Math.pow(pileDiameter, 2) *
        C_M *
        Math.pow(Math.PI, 3) *
        hm50Yr) /
        (Math.pow(tm50Yr, 2) * Math.sinh(waveNumberM50Yr * waterDepth))) *
      qInertia50Yr;

    // find which at which phase angle the combination of inertia and drag load gives the highest total wave force and mudline moment (1-yr and 50-yr), since they're out of phase
    const fWaveMaxAngle1Yr = Math.atan(fDrag1Yr / fInertia1Yr);
    const fWaveMaxAngle50Yr = Math.atan(fDrag50Yr / fInertia50Yr);
    const mWaveMaxAngle1Yr = Math.atan(mDrag1Yr / mInertia1Yr);
    const mWaveMaxAngle50Yr = Math.atan(mDrag50Yr / mInertia50Yr);

    const fWave1Yr =
      fDrag1Yr * Math.sin(fWaveMaxAngle1Yr) +
      fInertia1Yr * Math.cos(fWaveMaxAngle1Yr);
    const fWave50Yr =
      fDrag50Yr * Math.sin(fWaveMaxAngle50Yr) +
      fInertia50Yr * Math.cos(fWaveMaxAngle50Yr);
    const mWave1Yr =
      mDrag1Yr * Math.sin(mWaveMaxAngle1Yr) +
      mInertia1Yr * Math.cos(mWaveMaxAngle1Yr);
    const mWave50Yr =
      mDrag50Yr * Math.sin(mWaveMaxAngle50Yr) +
      mInertia50Yr * Math.cos(mWaveMaxAngle50Yr);

    // dynamic wave force and mudline moment
    const fDynWave1Yr = fWave1Yr * daf1yr;
    const fDynWave50Yr = fWave50Yr * daf50yr;
    const mDynWave1Yr = mWave1Yr * daf1yr;
    const mDynWave50Yr = mWave50Yr * daf50yr;

    // stress at mudline due to wave and wind moment
    const sDynEtm50Yr = (((mTEtm + mDynWave50Yr) / I_pile) * pileDiameter) / 2;
    const sDynEog1Yr = (((mTEog + mDynWave1Yr) / I_pile) * pileDiameter) / 2;

    const pileDeflEtm50Yr =
      (K_r / (K_l * K_r - Math.pow(K_lr, 2))) * (fTEtm + fDynWave50Yr) -
      (K_lr / (K_l * K_r - Math.pow(K_lr, 2))) * (mTEtm + mDynWave50Yr);
    const pileDeflEog1Yr =
      (K_r / (K_l * K_r - Math.pow(K_lr, 2))) * (fTEog + fDynWave1Yr) -
      (K_lr / (K_l * K_r - Math.pow(K_lr, 2))) * (mTEog + mDynWave1Yr);
    const pileAngleEtm50Yr =
      (-K_lr / (K_l * K_r - Math.pow(K_lr, 2))) * (fTEtm + fDynWave50Yr) +
      (K_l / (K_l * K_r - Math.pow(K_lr, 2))) * (mTEtm + mDynWave50Yr);
    const pileAngleEog1Yr =
      (-K_lr / (K_l * K_r - Math.pow(K_lr, 2))) * (fTEog + fDynWave1Yr) +
      (K_l / (K_l * K_r - Math.pow(K_lr, 2))) * (mTEog + mDynWave1Yr);

    maxStressUtil =
      (LOAD_FACTOR * MATERIAL_FACTOR * Math.max(sDynEog1Yr, sDynEtm50Yr)) /
      PILE_YIELD_STRESS;
    pileDeflUtil = Math.max(pileDeflEog1Yr, pileDeflEtm50Yr) / MAX_PILE_DEFL;
    pileAngleUtil =
      (Math.max(pileAngleEog1Yr, pileAngleEtm50Yr) * 180) /
      Math.PI /
      MAX_PILE_ANGLE;
    natFreqUtil = (1.1 * maxRotorSpeed) / natFreq;

    // to avoid infinite loop or unrealistic dimensions: if we don't find a feasible diameter within a certain range, we return undefined and post error message that no feasible design can be found for chosen turbine and location
    if (pileDiameter >= MAX_PILE_DIAMETER) return undefined;
  }

  const totalPileLength = maxPileLength + embedLength;

  return {
    pileDiameter,
    avgPileThickness,
    embedLength,
    totalPileLength,
  };
};

export const monopileNaturalFrequency = ({
  pileDiameter,
  avgPileThickness,
  embedLength,
  totalPileLength,
  waterDepth,
  soilCoeffSubReact,
  turbineType,
}: {
  pileDiameter: number;
  avgPileThickness: number;
  embedLength: number;
  totalPileLength: number;
  waterDepth: number;
  soilCoeffSubReact: number;
  turbineType: SimpleTurbineType;
}): number => {
  //Calculates the natural frequency for a monopile based on the research article "Design of monopiles for offshore wind turbines in 10 steps"
  const { hubHeight, diameter: rotorDiameter, rnaMass } = turbineType;

  const {
    towerBaseDiameter,
    towerTopDiameter,
    towerBaseThickness,
    towerTopThickness,
    towerMass,
  } = rotorTowerSizing(rotorDiameter);

  // estimated tower length, assuming 5 m vertical distance from tower top to hub height for 15 MW turbine (diameter 240 m), which is linearly scaled with the actual rotor diameter
  const towerLength =
    hubHeight +
    waterDepth +
    embedLength -
    totalPileLength -
    (rotorDiameter / 240) * 5.0;

  // estimated inertia for the tower, and natural frequency for a case where the tower base is fixed
  const I_tower =
    (((1 / 16) * (towerBaseThickness + towerBaseThickness)) / 2) *
    Math.PI *
    (Math.pow(towerBaseDiameter, 3) + Math.pow(towerTopDiameter, 3));
  const I_tower_top =
    (1 / 16) * towerTopThickness * Math.PI * Math.pow(towerTopDiameter, 3);
  const natFreqFixedBase =
    (1 / (2 * Math.PI)) *
    Math.sqrt(
      (3 * E_TOWER * I_tower) /
        (Math.pow(towerLength, 3) * (rnaMass + (33 / 140) * towerMass)),
    );

  const I_pile =
    (Math.PI / 64) *
    (Math.pow(pileDiameter, 4) -
      Math.pow(pileDiameter - 2 * avgPileThickness, 4));

  const K_l = (1 / 2) * Math.pow(embedLength, 2) * soilCoeffSubReact;
  const K_lr = (-1 / 3) * Math.pow(embedLength, 3) * soilCoeffSubReact;
  const K_r = (1 / 4) * Math.pow(embedLength, 4) * soilCoeffSubReact;

  const xi = (E_TOWER * I_tower) / (E_PILE * I_pile);
  const psi = (totalPileLength - embedLength) / towerLength;

  const C_s = Math.sqrt(1 / (1 + Math.pow(1 + psi, 3) * xi - xi));

  const q = towerBaseDiameter / towerTopDiameter;
  const f_q =
    ((1 / 3) * (2 * Math.pow(q, 2) * Math.pow(q - 1, 3))) /
    (2 * Math.pow(q, 2) * Math.log(q) - 3 * Math.pow(q, 2) + 4 * q - 1);
  const I_eta = I_tower_top * f_q;

  const eta_l = (K_l * Math.pow(towerLength, 3)) / (E_TOWER * I_eta);
  const eta_lr = (K_lr * Math.pow(towerLength, 2)) / (E_TOWER * I_eta);
  const eta_r = (K_r * towerLength) / (E_TOWER * I_eta);

  const C_l = 1 - 1 / (1 + 0.5 * (eta_l - Math.pow(eta_lr, 2) / eta_r));
  const C_r = 1 - 1 / (1 + 0.6 * (eta_r - Math.pow(eta_lr, 2) / eta_l));

  const natFreq = C_l * C_r * C_s * natFreqFixedBase;

  return natFreq;
};
