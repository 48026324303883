import { defaultIACables } from "../constants/cabling";
import {
  CABLE_POWER_RATING_WATT_MAX,
  CABLE_POWER_RATING_WATT_MIN,
  CableType,
  IAVoltageType,
} from "services/cableTypeService";
import { z } from "zod";
import { CostUnit } from "./financial";

export class CablesNotLinearError extends Error {
  constructor() {
    super("Cables does not form a single array from the substation.");
    this.name = "Cables are not in arrays";
  }
}

export const wattToMegawatt = (watt: number) => watt / 1e6;

export const megawattToWatt = (megawatt: number) => megawatt * 1e6;

export const POWER_RATING_MW_MIN = wattToMegawatt(CABLE_POWER_RATING_WATT_MIN);
export const POWER_RATING_MW_MAX = wattToMegawatt(CABLE_POWER_RATING_WATT_MAX);
export const POWER_RATING_MW_DEFAULT = 89;
export const VOLTAGE_DEFAULT = IAVoltageType.kV66;
export const RESISTANCE_DEFAULT =
  defaultIACables[IAVoltageType.kV66]["Cu 800mm2 66kV"].resistance;
export const REACTANCE_DEFAULT =
  defaultIACables[IAVoltageType.kV66]["Cu 800mm2 66kV"].reactance;
export const CAPACITANCE_DEFAULT =
  defaultIACables[IAVoltageType.kV66]["Cu 800mm2 66kV"].capacitance;
export const AMPACITY_DEFAULT =
  defaultIACables[IAVoltageType.kV66]["Cu 800mm2 66kV"].ampacity;

export const NAME_DEFAULT = "Cu 800mm2 66kV";

export const MAX_POWER_RATING_DEVIATION = 0.2;

export const DEFAULT_CABLE: Partial<CableType> = {
  name: NAME_DEFAULT,
  powerRating: megawattToWatt(POWER_RATING_MW_DEFAULT),
  voltage: VOLTAGE_DEFAULT,
  resistance: RESISTANCE_DEFAULT,
  reactance: REACTANCE_DEFAULT,
  capacitance: CAPACITANCE_DEFAULT,
  ampacity: AMPACITY_DEFAULT,
  cost: 0.3,
  costUnit: CostUnit.millionEuroPerKM,
};

export const cableLevel = ["project", "library"] as const;

export const _CableLevel = z.enum(cableLevel);

export type CableLevel = z.infer<typeof _CableLevel>;
export type CableTypeWithLevel = {
  level: CableLevel;
  cable: CableType;
};
