import { GeoJSONSourceRaw, Map, MapboxGeoJSONFeature } from "mapbox-gl";
import { Feature } from "geojson";
import GenericFeature from "./GenericFeature";

const geojsonSource = {
  type: "geojson",
  promoteId: "id",
  data: { type: "FeatureCollection", features: [] },
} as GeoJSONSourceRaw;

const GeoJsonFeature = ({
  features,
  sourceId,
  layerId,
  map,
  layers,
  textLayer,
  symbolsLayer,
  onClickCallback,
  onDbClickCallback,
  selectedIds,
  filter,
  beforeLayer,
  symbolsBeforeLayer,
  cluster,
}: {
  features: Feature[];
  sourceId: string;
  layerId: string;
  map: Map;
  layers: mapboxgl.AnyLayer[];
  textLayer?: mapboxgl.AnyLayer;
  symbolsLayer?: mapboxgl.AnyLayer;
  onClickCallback?: (
    features: MapboxGeoJSONFeature[],
    shiftClicked: boolean,
  ) => void;
  onDbClickCallback?: (features: any[]) => void;
  selectedIds?: (string | number)[];
  filter?: any[];
  beforeLayer?: string;
  symbolsBeforeLayer?: string;
  cluster?: boolean;
}) => {
  return (
    <GenericFeature
      features={features}
      sourceId={sourceId}
      source={geojsonSource}
      layerId={layerId}
      map={map}
      layers={layers}
      textLayer={textLayer}
      symbolsLayer={symbolsLayer}
      onClickCallback={onClickCallback}
      onDbClickCallback={onDbClickCallback}
      selectedIds={selectedIds}
      filter={filter}
      beforeLayer={beforeLayer}
      symbolsBeforeLayer={symbolsBeforeLayer}
      cluster={cluster}
    />
  );
};

export default GeoJsonFeature;
