import LibraryIcon from "@icons/24/Library.svg?react";
import PlayIcon from "@icons/24/Play.svg?react";
import ExpandIcon from "@icons/24/Open.svg";
import ArticleIcon from "@icons/learnVind/article.svg";
import LinkIcon from "@icons/learnVind/link.svg";
import VideoIcon from "@icons/learnVind/video.svg";
import Button from "components/General/Button";
import { Column, Row } from "components/General/Layout";
import {
  ProjectGridWrapper,
  ProjectNameWrapper,
  ProjectWrapper,
} from "components/Projects/ProjectGrid.style";
import { useSetRecoilState } from "recoil";
import { modalTypeOpenAtom } from "state/modal";
import styled from "styled-components";
import { colors } from "styles/colors";
import { borderRadius, spacing9 } from "styles/space";
import { KeyboardShortcutsModalType } from "./KeyboardShortcutsModal";
import { VideoModalType } from "./VideoModal";
import { Mixpanel } from "mixpanel";
import {
  showArticle,
  ARTICLE_WAKE_ANALYSIS_BENCHMARKING,
} from "components/HelpTooltip/HelpTooltip";
import { TabHeader } from "components/Organisation/style";

const LearnVindPictureWrapper = styled.div`
  background: ${colors.blue800};
  width: 27rem;
  height: 16rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${borderRadius.small};
`;

export enum LearningSessions {
  LearningVind = "learningVind",
}

export const LearnVindGrid = () => {
  // NOTE: We piggy-back on the hints-api with the attribute `hideLearningSessions`

  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);

  return (
    <>
      <TabHeader>Learn</TabHeader>
      <section style={{ marginBottom: spacing9, padding: "2.4rem 0" }}>
        <ProjectGridWrapper style={{ overflowY: "visible", paddingBottom: 0 }}>
          <LearnVindCard
            text="Vind AI demo"
            buttonText="4m watch"
            cardPicture="rocket"
            legend="video"
            onClick={() => {
              setModalTypeOpen({
                modalType: VideoModalType,
                metadata: {
                  title: "Vind AI demo",
                  videoId:
                    "https://player.vimeo.com/video/933674125?h=402ffdc7ac&texttrack=en-x-autogen",
                },
              });
            }}
          />
          <LearnVindCard
            text="Navigate in Vind AI"
            buttonText="4m watch"
            cardPicture="video"
            legend="video"
            onClick={() => {
              setModalTypeOpen({
                modalType: VideoModalType,
                metadata: {
                  title: "Navigate in Vind AI",
                  videoId:
                    "https://player.vimeo.com/video/912653414?texttrack=en-x-autogen",
                },
              });
            }}
          />
          <LearnVindCard
            text="Keyboard shortcuts"
            buttonText="1m read"
            cardPicture="article"
            legend="article"
            onClick={() => {
              setModalTypeOpen({
                modalType: KeyboardShortcutsModalType,
              });
            }}
          />
          <LearnVindCard
            text="Wake analysis benchmarking"
            buttonText="2m read"
            cardPicture="article"
            legend="link"
            onClick={() => {
              showArticle(ARTICLE_WAKE_ANALYSIS_BENCHMARKING);
            }}
          />
        </ProjectGridWrapper>
      </section>
    </>
  );
};

function LearnVindCard({
  text,
  buttonText,
  cardPicture,
  legend,
  onClick,
}: {
  text: string;
  buttonText: string;
  cardPicture: "rocket" | "video" | "article";
  legend: "video" | "article" | "link" | "none";
  onClick: () => void;
}) {
  const handleClick = () => {
    Mixpanel.track("LearnVindCard Clicked", {
      text,
      buttonText,
      cardPicture,
      legend,
    });
    onClick();
  };

  const cardIcon = () => {
    switch (cardPicture) {
      case "video":
        return <VideoIcon />;
      case "article":
        return <ArticleIcon />;
      case "rocket":
        return <LinkIcon />;
    }
  };

  const buttonIcon = () => {
    switch (legend) {
      case "video":
        return <PlayIcon style={{ strokeWidth: "0rem" }} />;
      case "article":
        return <LibraryIcon style={{ strokeWidth: "0rem" }} />;
      case "link":
        return <ExpandIcon />;
      case "none":
        return null;
    }
  };

  return (
    <ProjectWrapper
      onClick={handleClick}
      style={{ gap: "0.9rem" }}
      nohover={false}
    >
      <LearnVindPictureWrapper>{cardIcon()}</LearnVindPictureWrapper>
      <Column style={{ gap: 0 }}>
        <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
          <ProjectNameWrapper>{text}</ProjectNameWrapper>
        </Row>
        <Row>
          <Button text={buttonText} buttonType="text" icon={buttonIcon()} />
        </Row>
      </Column>
    </ProjectWrapper>
  );
}
