import { selectorFamily } from "recoil";
import * as turf from "@turf/turf";
import { AmountUnit } from "../../../../types/financial";
import { Amount, amountAddId, amountId } from "../generalAmounts";
import { getExportCablesInBranchSelectorFamily } from "../../../../state/cable";
import { ExportCableFeature } from "../../../../types/feature";
import { roundToDecimal, sumKeys } from "../../../../utils/utils";
import { CostType } from "../../../../services/costService";
import { CableType } from "services/cableTypeService";
import { currentExportCableTypesSelector } from "components/Cabling/Generate/state";
import { exportCableSplitsOk } from "functions/elevation";
import {
  ProdId,
  analysisOverrideInputAtomFamily,
  getBranchId,
  getCapacity,
  getParkId,
} from "components/ProductionV2/state";

export const allExportCableTypesInParkSelectorFamily = selectorFamily<
  { exportCableTypes: CableType[]; lengthPerCableType: Record<string, number> },
  { branchId: string; parkId: string; exportCableTypeOverrideId?: string }
>({
  key: "allExportCableTypesInParkSelectorFamily",
  get:
    ({ branchId, parkId, exportCableTypeOverrideId }) =>
    ({ get }) => {
      const exportCables = get(
        getExportCablesInBranchSelectorFamily({
          parkId,
          branchId,
          exportCableTypeOverrideId,
        }),
      );
      const exportCableSegments = get(
        exportCableSplitsOk({ parkId, branchId, exportCableTypeOverrideId }),
      );
      const exportCableTypes = get(currentExportCableTypesSelector);

      const exportCableLengths: Record<
        string,
        { offshore: number; onshore: number }
      > = {};
      exportCables.forEach((cable) => {
        if (!exportCableLengths[cable.id]) {
          exportCableLengths[cable.id] = {
            offshore: 0,
            onshore: 0,
          };
        }
        const segments = exportCableSegments.find(
          (seg) => seg.exportCable.id === cable.id,
        );
        const offshoreLength = turf.length(
          segments ? segments.offshore : cable,
          {
            units: "kilometers",
          },
        );
        const onshoreLength = segments
          ? turf.length(segments.onshore, {
              units: "kilometers",
            })
          : 0;
        exportCableLengths[cable.id].offshore += offshoreLength;
        exportCableLengths[cable.id].onshore += onshoreLength;
      });

      if (!exportCableLengths)
        return { exportCableTypes, lengthPerCableType: {} };

      const cableTypeIds = new Set(exportCableTypes.map((t) => t.id));
      const lengthPerCableType = sumKeys(
        exportCables.flatMap((cable) => {
          const offshoreLen = exportCableLengths[cable.id].offshore ?? 0;
          const onshoreLen = exportCableLengths[cable.id].onshore ?? 0;
          const cableTypeId = cable.properties.cableTypeId;
          const usedCableTypeId =
            cableTypeId && cableTypeIds.has(cableTypeId)
              ? cableTypeId
              : "other";
          const onshoreCableTypeId = cable.properties.onshoreCableTypeId;
          const usedOnshoreCableTypeId =
            onshoreCableTypeId && cableTypeIds.has(onshoreCableTypeId)
              ? onshoreCableTypeId
              : "other";

          return [
            [usedCableTypeId, offshoreLen],
            [usedOnshoreCableTypeId, onshoreLen],
          ];
        }),
      );

      return { exportCableTypes, lengthPerCableType };
    },
});

export const exportCableAmountsSelectorFamily = selectorFamily<
  Amount[],
  ProdId
>({
  key: "exportCableAmountsSelectorFamily",
  get:
    (productionId) =>
    ({ get }) => {
      const branchId = get(getBranchId(productionId));
      const parkId = get(getParkId(productionId));

      const input = get(analysisOverrideInputAtomFamily(productionId));

      const exportCables = get(
        getExportCablesInBranchSelectorFamily({
          parkId,
          branchId,
          exportCableTypeOverrideId: input.exportCableTypeOverrideId,
        }),
      );

      const { exportCableTypes, lengthPerCableType } = get(
        allExportCableTypesInParkSelectorFamily({
          branchId,
          parkId,
          exportCableTypeOverrideId: input.exportCableTypeOverrideId,
        }),
      );

      const capacity = get(getCapacity(productionId));

      const exportLength = exportCables.reduce(
        (a, e: ExportCableFeature) =>
          a + turf.length(e, { units: "kilometers" }),
        0,
      );

      const exportCableLengthAmount = amountAddId({
        unit: AmountUnit.km,
        amount: roundToDecimal(exportLength, 1),
        category: CostType.ExportCable,
      });

      const exportCableLengthAmountPerType = exportCableTypes.map((cable) => {
        const amount = lengthPerCableType[cable.id] ?? 0;
        const unit = AmountUnit.km;
        const category = CostType.ExportCable;

        return {
          id: amountId({ unit, category, featureTypeId: cable.id }),
          unit,
          amount,
          category,
        };
      });

      const perMwAmount = amountAddId({
        unit: AmountUnit.MW,
        amount: exportCables.length > 0 ? capacity ?? 0 : 0,
        category: CostType.ExportCable,
      });

      const fixedAmount = {
        id: amountId({
          unit: AmountUnit.fixed,
          category: CostType.ExportCable,
        }),
        unit: AmountUnit.fixed,
        amount: 1,
        category: CostType.ExportCable,
      };

      return [
        ...exportCableLengthAmountPerType,
        exportCableLengthAmount,
        perMwAmount,
        fixedAmount,
      ];
    },
});
