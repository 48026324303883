/// <reference types="vite-plugin-svgr/client" />
import { IconBtn } from "components/General/Icons";
import { useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { organisationIdSelector } from "state/pathParams";
import styled from "styled-components";
import Close from "@icons/24/Close.svg?react";
import { colors } from "styles/colors";
import GroupContent from "./content/GroupContent/GroupContent";
import NodeContent from "./content/NodeContent/NodeContent";
import UserContent from "./content/UserContent/UserContent";
import { organisationRightSideModal } from "./state";
import ResourceContent from "./content/ResourceContent/ResourceContent";
import EmptyContent from "./content/EmptyContent";

const Container = styled.div`
  display: flex;
  width: 45rem;
  height: 100%;
  background: white;
  padding: 5.4rem 2.4rem 2.4rem;
  box-sizing: border-box;
  transform: translateX(100%); /* Initially positioned off-screen */
  transition: transform 0.5s;
  border-left: 1px solid ${colors.dropdownBorder};
  position: relative;
  overflow-y: scroll;
`;

export default function OrganisationRightSide() {
  const organisationId = useRecoilValue(organisationIdSelector);

  const [content, setContent] = useRecoilState(
    organisationRightSideModal(organisationId ?? ""),
  );

  const contentComponent = useMemo(() => {
    switch (content?.type) {
      case "group": {
        if (content.id) {
          return <GroupContent groupId={content.id} key={content.id} />;
        }
        break;
      }
      case "project": {
        if (content.id) {
          return <NodeContent nodeId={content.id} key={content.id} />;
        }
        break;
      }
      case "user": {
        if (content.id) {
          return <UserContent userId={content.id} key={content.id} />;
        }
        break;
      }
      case "resource": {
        if (content.id) {
          return <ResourceContent resourceId={content.id} key={content.id} />;
        }
        break;
      }
      case "empty": {
        return <EmptyContent />;
      }
      default:
        return <></>;
    }
  }, [content]);

  return (
    <Container
      style={{ transform: content ? "translateX(0)" : "translateX(100%)" }}
    >
      {contentComponent}

      <IconBtn
        size="1.6rem"
        style={{ position: "absolute", top: "0.4rem", right: "1.2rem" }}
        onClick={() => setContent(undefined)}
      >
        <Close />
      </IconBtn>
    </Container>
  );
}
