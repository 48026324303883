/// <reference types="vite-plugin-svgr/client" />
import { Column, Row } from "components/General/Layout";
import styled from "styled-components";
import { StandardBox } from "styles/boxes/Boxes";
import CheckIcon from "@icons/24/Check.svg?react";
import CloseIcon from "@icons/24/Close.svg?react";
import QuestionIcon from "@icons/24/Question.svg?react";
import { typography } from "styles/typography";
import { SVGWrapper } from "@icons/svgUtils";
import { colors } from "styles/colors";

export const ModalContainer = styled(StandardBox)`
  display: flex;
  flex-direction: column;
  padding: 2.4rem 1.6rem;
  width: 45rem;
  height: fit-content;
  max-width: 45rem;
  gap: 2.4rem;
  background: white;
`;

const Label = styled.h4`
  ${typography.semiBold}
  font-weight: 600;
  font-size: 1.4rem;
  margin: 0;
`;
const Text = styled.p`
  ${typography.contentAndButtons}
  margin:0;
`;
const SecondaryText = styled.p`
  ${typography.contentAndButtons}
  color: ${colors.secondaryText};
  margin: 0;
`;

const DescriptionContainer = styled.div`
  flex: 3;
`;
const EntryContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

const StyledLink = styled.a`
  ${typography.contentAndButtons}
  margin: 0;
`;

export function QuestionIconComp({
  onClick,
}: {
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
}) {
  return (
    <SVGWrapper size={1.4} onClick={onClick} style={{ cursor: "pointer" }}>
      <QuestionIcon />
    </SVGWrapper>
  );
}

function StyledCheckIcon() {
  return (
    <SVGWrapper style={{ width: "fit-content" }} size={1.4}>
      <CheckIcon />
    </SVGWrapper>
  );
}

function Yes() {
  return (
    <EntryContainer>
      <StyledCheckIcon />
    </EntryContainer>
  );
}
function YesStar() {
  return (
    <EntryContainer style={{ gap: "0.2rem" }}>
      <StyledCheckIcon />
      <p>*</p>
    </EntryContainer>
  );
}

function No() {
  return <EntryContainer />;
}

export default function AccessOverviewModal({
  onClose,
}: {
  onClose: () => void;
}) {
  return (
    <ModalContainer>
      <Column style={{ gap: "0.8rem" }}>
        <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
          <h3 style={{ margin: 0 }}>Organisation role permission</h3>
          <SVGWrapper
            size={1.4}
            onClick={onClose}
            style={{ cursor: "pointer" }}
          >
            <CloseIcon />
          </SVGWrapper>
        </Row>
        <Text>
          These are the resources each role has access to.{" "}
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href={
              "https://help.vindtech.com/en/articles/7056300-access-management"
            }
          >
            Read more
          </StyledLink>
        </Text>
      </Column>
      <Column style={{ gap: "1.2rem" }}>
        <Row>
          <DescriptionContainer>
            <Label>Organisation</Label>
          </DescriptionContainer>
          <EntryContainer>
            <Label>Owner</Label>
          </EntryContainer>
          <EntryContainer>
            <Label>Admin</Label>
          </EntryContainer>
          <EntryContainer>
            <Label>Member</Label>
          </EntryContainer>
          <EntryContainer>
            <Label>Guest</Label>
          </EntryContainer>
        </Row>
        <Row>
          <DescriptionContainer>
            <Text>Edit organisation settings</Text>
          </DescriptionContainer>
          <Yes />
          <No />
          <No />
          <No />
        </Row>
        <Row>
          <DescriptionContainer>
            <Text>Manage portfolio</Text>
          </DescriptionContainer>
          <Yes />
          <Yes />
          <No />
          <No />
        </Row>
        <Row>
          <DescriptionContainer>
            <Text>Create and manage groups</Text>
          </DescriptionContainer>
          <Yes />
          <Yes />
          <No />
          <No />
        </Row>
        <Row>
          <DescriptionContainer>
            <Text>View groups</Text>
          </DescriptionContainer>
          <Yes />
          <Yes />
          <Yes />
          <No />
        </Row>
        <Row>
          <DescriptionContainer>
            <Text>Assign library access</Text>
          </DescriptionContainer>
          <Yes />
          <Yes />
          <No />
          <No />
        </Row>
        <Row>
          <DescriptionContainer>
            <Label>Projects</Label>
          </DescriptionContainer>
          <EntryContainer />
          <EntryContainer />
          <EntryContainer />
          <EntryContainer />
        </Row>
        <Row>
          <DescriptionContainer>
            <Text>Manage access to all projects</Text>
          </DescriptionContainer>
          <YesStar />
          <YesStar />
          <No />
          <No />
        </Row>
        <Row>
          <DescriptionContainer>
            <Text>Create projects</Text>
          </DescriptionContainer>
          <Yes />
          <Yes />
          <Yes />
          <No />
        </Row>

        <Row>
          <DescriptionContainer>
            <Label>Users</Label>
          </DescriptionContainer>
          <EntryContainer />
          <EntryContainer />
          <EntryContainer />
          <EntryContainer />
        </Row>
        <Row>
          <DescriptionContainer>
            <Text>Invite to organisation</Text>
          </DescriptionContainer>
          <Yes />
          <Yes />
          <Yes />
          <No />
        </Row>
        <Row>
          <DescriptionContainer>
            <Text>Remove from organisation</Text>
          </DescriptionContainer>
          <Yes />
          <Yes />
          <No />
          <No />
        </Row>
        <Row>
          <DescriptionContainer>
            <Text>View organisation members</Text>
          </DescriptionContainer>
          <Yes />
          <Yes />
          <Yes />
          <No />
        </Row>
        <Row>
          <DescriptionContainer>
            <Text>View project collaborators</Text>
          </DescriptionContainer>
          <Yes />
          <Yes />
          <Yes />
          <Yes />
        </Row>
        <Row />
        <Row>
          <SecondaryText>
            * Owners and Admins does not have automatic access to all projects,
            but they can give themselves access to any project or any group.
          </SecondaryText>
        </Row>
      </Column>
    </ModalContainer>
  );
}
