/// <reference types="vite-plugin-svgr/client" />
import Dashboard from "@icons/24/Dashboard.svg?react";
import * as turf from "@turf/turf";
import { ifLoader } from "components/Loading/Skeleton";
import { currentSelectionProduction } from "components/ProductionV2/Triggers";
import { formatElectricalLossToPercentDecimal } from "components/ProductionV2/format";
import {
  TotalExportSystemLoss,
  TotalInterArrayLoss,
} from "components/ProductionV2/functions";
import {
  getConfiguration,
  getStoppedReason,
  getStoppedReasonFromAnalysis,
  getTotalExportSystemLoss,
  getTotalInterArrayLoss,
} from "components/ProductionV2/state";
import { ChevronIcon } from "components/ToggleableList/ToggleableList";
import { Suspense, useMemo, useState } from "react";
import {
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";
import styled from "styled-components";
import { colors } from "styles/colors";
import { spaceSmall } from "styles/space";
import { getSelectedArea } from "../../../../state/division";
import { showCableTypeColorInMapAtom } from "../../../../state/electrical";
import { DashboardModalType, modalTypeOpenAtom } from "../../../../state/modal";
import { getParkFeatureSelectorFamily } from "../../../../state/park";
import { branchIdSelector } from "../../../../state/pathParams";
import { ParkFeature } from "../../../../types/feature";
import {
  fastMax,
  fastMin,
  nullMap,
  sum,
  undefMap,
} from "../../../../utils/utils";
import { get3DCableLengthsInParkWithContingency } from "../../../Cabling/Generate/utils";
import Button from "../../../General/Button";
import { ColoredGrid } from "../../../General/Form";
import Tooltip from "../../../General/Tooltip";
import {
  NumberOfSubstationsTag,
  NumberOfTurbinesTag,
  Tag,
} from "../../../GenerateWindTurbines/Tags";
import HelpTooltip, {
  ARTICLE_CABLE_STATS,
  ARTICLE_INTER_ARRAY_LOSS,
  HelpLink,
} from "../../../HelpTooltip/HelpTooltip";
import {
  SkeletonBlock,
  SkeletonText,
  orLoader,
} from "../../../Loading/Skeleton";
import { MenuFrame } from "../../../MenuPopup/CloseableMenuPopup";
import { Row } from "../InfoModal.style";
import { ButtonWrapper } from "../style";
import { Column, TitleRow } from "components/General/Layout";
import { analysisStoppedText } from "components/ProductionV2/types";
import { ElectricalStatError } from "components/ValidationWarnings/FeatureSpecificErrors";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import {
  Divider,
  InputTitle,
  OverlineText,
  ResultValue,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import { useShowScrollShadow } from "hooks/useShowScrollShadow";
import NewToggleableList from "components/ToggleableList/NewToggleableList";
import {
  getMostCommonCableTypeId,
  getSelectedCableChains,
  getSelectedCables,
  getSelectedExportCableSplits,
  getSelectedExportCables,
  getSelectedSubstations,
} from "state/cable";
import { getSelectedTurbines } from "state/turbines";
import {
  allCableTypesSelector,
  currentExportCableTypesSelector,
} from "components/Cabling/Generate/state";
import { parseOr } from "utils/zod";
import {
  _ExportCableVoltageType,
  _IAVoltageType,
} from "services/cableTypeService";
import { SubAreaTag } from "components/SiteLocator/Tags";
import Toggle, { ToggleSize } from "components/General/Toggle";
import { TypeLine } from "components/ProjectElementsV2/ProjectElementsV2.style";
import { getCableColors } from "components/Cabling/CablingMapController/Render";
import { wattToMegawatt } from "types/cables";

const DisabledRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spaceSmall};
  p {
    color: ${colors.primaryDisabled};
    margin: 0;
  }
  * > svg {
    height: 1.4rem;
    width: 1.4rem;
    padding: 0.1rem;
    path {
      stroke: ${colors.primaryDisabled};
    }
  }
`;

const Disabled = ({ text }: { text: string }) => (
  <DisabledRow>
    <ResultValue style={{ color: colors.textDisabled }}>Disabled</ResultValue>
    <HelpTooltip text={text} />
  </DisabledRow>
);

const Inner = ({ park }: { park: ParkFeature }) => {
  const branchId = useRecoilValue(branchIdSelector);
  const setModalType = useSetRecoilState(modalTypeOpenAtom);
  const { scrollBodyRef } = useShowScrollShadow(true);

  if (!branchId) return null;
  return (
    <>
      <div
        ref={scrollBodyRef}
        style={{
          maxHeight: "calc(100vh - 32rem)",
          overflowY: "auto",
        }}
      >
        <Stats park={park} branchId={branchId} />
      </div>
      <Divider />
      <ButtonWrapper>
        <Button
          text="View in dashboard"
          buttonType="secondary"
          icon={<Dashboard />}
          onClick={() => {
            setModalType({
              modalType: DashboardModalType,
              metadata: { id: "vind-preset-dashboard-electrical" },
            });
          }}
        />
      </ButtonWrapper>
    </>
  );
};

const ElectricalLosses = () => {
  const stoppedReason = useRecoilValueLoadable(
    getStoppedReason(currentSelectionProduction),
  ).valueMaybe();
  const analysisStoppedReason = useRecoilValueLoadable(
    getStoppedReasonFromAnalysis(currentSelectionProduction),
  ).valueMaybe();
  const interArrayLoss = useRecoilValueLoadable(
    getTotalInterArrayLoss(currentSelectionProduction),
  );
  const exportSystemLoss = useRecoilValueLoadable(
    getTotalExportSystemLoss(currentSelectionProduction),
  );
  const configuration = useRecoilValueLoadable(
    getConfiguration(currentSelectionProduction),
  ).valueMaybe();

  const exportSystemLossEnabled =
    configuration?.electrical.exportSystemLoss ?? false;

  const interArrayLossEnabled =
    configuration?.electrical.interArrayCableLoss ?? false;
  const [lossesOpen, setLossesOpen] = useState(true);
  return (
    <>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          setLossesOpen(!lossesOpen);
        }}
      >
        <SubtitleWithLine
          text={"Electrical losses"}
          article={ARTICLE_INTER_ARRAY_LOSS}
          expandButton={
            <ChevronIcon
              open={lossesOpen}
              chevronSize={"1.4rem"}
              style={{
                alignSelf: "center",
              }}
            />
          }
        />
      </div>
      {lossesOpen && (
        <Column style={{ paddingBottom: "0.8rem", gap: "0.4rem" }}>
          {stoppedReason ? (
            <SimpleAlert
              title="Analysis stopped"
              text={analysisStoppedText[stoppedReason]}
            />
          ) : analysisStoppedReason ? (
            <SimpleAlert
              title="Analysis stopped"
              text={analysisStoppedText[analysisStoppedReason]}
            />
          ) : (
            <>
              {interArrayLossEnabled ? (
                ifLoader(
                  interArrayLoss,
                  () => <ToggleableLoader text="Total inter array loss" />,
                  (loss) => <TotalIALoss loss={loss} />,
                )
              ) : (
                <Row>
                  <OverlineText>Total inter array loss</OverlineText>
                  <Disabled text="Inter array loss is disabled in the current analysis configuration." />
                </Row>
              )}

              {exportSystemLossEnabled ? (
                ifLoader(
                  exportSystemLoss,
                  () => <ToggleableLoader text="Total export system loss:" />,
                  (loss) => <ExportSystemLosses loss={loss} />,
                )
              ) : (
                <Row>
                  <OverlineText>Total export system loss</OverlineText>
                  <Disabled text="Export system loss is disabled in the current analysis configuration." />
                </Row>
              )}
            </>
          )}
        </Column>
      )}
    </>
  );
};

const ExportSystem = () => {
  const [open, setOpen] = useState(true);

  const exportCables = useRecoilValue(getSelectedExportCables);
  const exportCableSplits = useRecoilValue(getSelectedExportCableSplits);
  const exportLength = sum(exportCables, (e) =>
    turf.length(e, { units: "kilometers" }),
  );
  const cableExportId = getMostCommonCableTypeId(exportCables);

  const { onshoreLen, offshoreLen, anyLengthError } = useMemo(() => {
    let onshoreLen = 0;
    let offshoreLen = 0;
    let anyErrors = false;
    for (const sp of exportCableSplits) {
      if ("error" in sp) {
        anyErrors = true;
      } else {
        onshoreLen += turf.length(sp.onshore, { units: "kilometers" });
        offshoreLen += turf.length(sp.offshore, { units: "kilometers" });
      }
    }
    return { offshoreLen, onshoreLen, anyLengthError: anyErrors };
  }, [exportCableSplits]);

  const allCableTypes = useRecoilValue(currentExportCableTypesSelector);

  const exportvoltage = undefMap(
    allCableTypes.find((c) => c.id === cableExportId)?.voltage,
    (v) => parseOr(_ExportCableVoltageType, v, undefined),
  );

  return (
    <>
      <div style={{ cursor: "pointer" }} onClick={() => setOpen(!open)}>
        <SubtitleWithLine
          text={"Export system"}
          expandButton={
            <ChevronIcon
              open={open}
              chevronSize={"1.4rem"}
              style={{
                alignSelf: "center",
              }}
            />
          }
        />
      </div>
      {open && (
        <>
          {anyLengthError ? (
            <SimpleAlert text="Failed to compute some lengths" />
          ) : exportCables.length === 0 ? (
            <SimpleAlert text="No export cables to show" type="info" />
          ) : (
            <ColoredGrid style={{ gridTemplateColumns: "auto auto" }}>
              <ResultValue>Export cable voltage</ResultValue>
              <ResultValue>
                <strong>{exportvoltage} </strong> kV
              </ResultValue>
              <ResultValue>Export cable length offshore</ResultValue>
              <ResultValue>
                <strong>{offshoreLen.toFixed(1)} </strong> km
              </ResultValue>
              <ResultValue>Export cable length onshore</ResultValue>
              <ResultValue>
                <strong>{onshoreLen.toFixed(1)} </strong> km
              </ResultValue>
              <ResultValue>Total horizontal length</ResultValue>
              <ResultValue>
                <strong>{exportLength.toFixed(1)} </strong> km
              </ResultValue>
            </ColoredGrid>
          )}
        </>
      )}
    </>
  );
};

const Visualisations = () => {
  const [showCableTypeColorInMap, setShowCableTypeColorInMap] = useRecoilState(
    showCableTypeColorInMapAtom,
  );
  const cableTypes = useRecoilValue(allCableTypesSelector);
  const cableColors = useMemo(
    () => getCableColors({ cableTypes }),
    [cableTypes],
  );
  const [visualisationOpen, setVisualisationOpen] = useState(true);

  return (
    <>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => setVisualisationOpen(!visualisationOpen)}
      >
        <SubtitleWithLine
          text={"Visualisations"}
          expandButton={
            <ChevronIcon
              open={visualisationOpen}
              chevronSize={"1.4rem"}
              style={{
                alignSelf: "center",
              }}
            />
          }
        />
      </div>

      {visualisationOpen && (
        <Column style={{ paddingBottom: "0.8rem", gap: "0.4rem" }}>
          <Row>
            <Toggle
              checked={showCableTypeColorInMap}
              onChange={() =>
                setShowCableTypeColorInMap(!showCableTypeColorInMap)
              }
              size={ToggleSize.SMALL}
            />
            <InputTitle style={{ flex: 1 }}>Cable types</InputTitle>
          </Row>
          {showCableTypeColorInMap &&
            cableTypes.map((c) => {
              const color =
                cableColors.find((cc) => cc.cableTypeId === c.id)?.color ??
                "#111111";
              return (
                <Row>
                  <TypeLine lineColor={color} lineLength={"1.5rem"} />
                  <TitleRow
                    style={{ flex: 1 }}
                  >{`${c.name} (${wattToMegawatt(c.powerRating)} MW)`}</TitleRow>
                </Row>
              );
            })}
        </Column>
      )}
    </>
  );
};

const TotalIALoss = ({ loss }: { loss: TotalInterArrayLoss }) => {
  const configuration = useRecoilValueLoadable(
    getConfiguration(currentSelectionProduction),
  ).valueMaybe();
  const interArrayLossEnabled =
    configuration?.electrical.interArrayCableLoss ?? false;
  const turbineTrafoLossEnabled =
    configuration?.electrical.turbineTrafoLoss ?? false;

  return (
    <NewToggleableList
      title="Total inter array loss"
      chevronSize="1rem"
      defaultOpen={false}
      value={
        <p>
          {nullMap(
            loss.totalInterArrayLoss,
            formatElectricalLossToPercentDecimal,
          ) ?? "-"}{" "}
        </p>
      }
    >
      <ColoredGrid style={{ gridTemplateColumns: "4fr 2fr" }}>
        <p>{"Inter array cable loss:"}</p>
        {interArrayLossEnabled ? (
          <p>
            {nullMap(
              loss.totalInterArrayCableLoss,
              formatElectricalLossToPercentDecimal,
            ) ?? "-"}
          </p>
        ) : (
          <Disabled
            text={
              "Inter-array cable loss is disabled in the current analysis configuration."
            }
          />
        )}

        <p>{"Turbine transformer loss:"}</p>
        {turbineTrafoLossEnabled ? (
          <p>
            {nullMap(
              loss.totalTurbineTrafoLoss,
              formatElectricalLossToPercentDecimal,
            ) ?? "-"}
          </p>
        ) : (
          <Disabled
            text={
              "Turbine trafo loss is disabled in the current analysis configuration."
            }
          />
        )}
      </ColoredGrid>
    </NewToggleableList>
  );
};

const ExportSystemLosses = ({ loss }: { loss: TotalExportSystemLoss }) => {
  return (
    <NewToggleableList
      title="Total export system loss"
      chevronSize="1rem"
      defaultOpen={false}
      value={
        <p>
          {nullMap(
            loss.totalExportSystemLoss,
            formatElectricalLossToPercentDecimal,
          ) ?? "-"}
        </p>
      }
    >
      <ColoredGrid style={{ gridTemplateColumns: "4fr 2fr" }}>
        <ResultValue>Export cable loss</ResultValue>
        <p>
          {nullMap(
            loss.totalExportCableLoss,
            formatElectricalLossToPercentDecimal,
          ) ?? "-"}
        </p>

        <ResultValue>Offshore transformer loss</ResultValue>
        <p>
          {nullMap(
            loss.totalOffshoreTrafoLoss,
            formatElectricalLossToPercentDecimal,
          ) ?? "-"}
        </p>

        <ResultValue>Onshore transformer loss</ResultValue>
        <p>
          {nullMap(
            loss.totalOnshoreTrafoLoss,
            formatElectricalLossToPercentDecimal,
          ) ?? "-"}
        </p>
      </ColoredGrid>
    </NewToggleableList>
  );
};

const ToggleableLoader = ({ text }: { text: string }) => (
  <ColoredGrid>
    <OverlineText>{text}</OverlineText>
    <SkeletonText style={{ width: "50%" }} />
  </ColoredGrid>
);

const Stats = ({ branchId, park }: { branchId: string; park: ParkFeature }) => {
  const areaFeatures = useRecoilValue(getSelectedArea) ?? park;
  const selectedArea = useMemo(() => {
    const area = Array.isArray(areaFeatures)
      ? sum(areaFeatures, (f) => turf.area(f))
      : turf.area(areaFeatures);
    return Math.round(area / 1e6);
  }, [areaFeatures]);

  const cables = useRecoilValue(getSelectedCables);
  const turbines = useRecoilValue(getSelectedTurbines);
  const substations = useRecoilValue(getSelectedSubstations);
  const chains = useRecoilValue(getSelectedCableChains);

  const redundancyCables = cables?.filter((c) => c.properties.redundancy);

  const cableIdToLength = useRecoilValueLoadable(
    get3DCableLengthsInParkWithContingency({ parkId: park.id, branchId }),
  );

  const lengthPerCableInDivision = cableIdToLength.map(
    (c2l) => new Map((cables ?? []).map((c) => [c.id, c2l?.[c.id] ?? 0])),
  );

  const aggregates = lengthPerCableInDivision.map((map) => {
    const values = [...map.values()];
    return {
      shortest: fastMin(values, 0),
      longest: fastMax(values, 0),
      total: sum(values),
    };
  });

  const cableIAId = getMostCommonCableTypeId(cables);

  const allCableTypes = useRecoilValue(allCableTypesSelector);
  const voltage = undefMap(
    allCableTypes.find((c) => c.id === cableIAId)?.voltage,
    (v) => parseOr(_IAVoltageType, v, undefined),
  );

  const [interArraySystemOpen, setInterArraySystemOpen] = useState(true);

  return (
    <>
      <Row style={{ justifyContent: "left", flexWrap: "wrap" }}>
        {Array.isArray(areaFeatures) ? (
          <SubAreaTag tooltip="These analysis numbers are only for the selected zone." />
        ) : (
          <Tag>Park</Tag>
        )}
        <Tag>{selectedArea} km²</Tag>
        {turbines && <NumberOfTurbinesTag n={turbines.length} />}
        {substations && <NumberOfSubstationsTag n={substations.length} />}
      </Row>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          setInterArraySystemOpen(!interArraySystemOpen);
        }}
      >
        <SubtitleWithLine
          text={"Inter array cabling"}
          expandButton={
            <ChevronIcon
              open={interArraySystemOpen}
              chevronSize={"1.4rem"}
              style={{
                alignSelf: "center",
              }}
            />
          }
        />
      </div>
      {interArraySystemOpen && (
        <ColoredGrid style={{ gridTemplateColumns: "5fr 3fr" }}>
          <ResultValue>No. of cable segments</ResultValue>
          <ResultValue>
            <strong>{cables?.length ?? 0}</strong>
          </ResultValue>

          <ResultValue>Cable segment lengths</ResultValue>
          {orLoader(aggregates, ({ shortest, longest }) => (
            <ResultValue>
              <strong>
                {shortest === 0 && longest === 0
                  ? "0"
                  : `${shortest.toFixed(2)} - ${longest.toFixed(2)}`}
              </strong>{" "}
              km
            </ResultValue>
          ))}

          <ResultValue>Number of chains</ResultValue>
          <ResultValue>
            <strong>{chains?.length ?? 0}</strong>
          </ResultValue>

          {redundancyCables !== undefined && (
            <>
              <ResultValue>Redundancy cables</ResultValue>
              <ResultValue>
                <strong>{redundancyCables.length}</strong>
              </ResultValue>
            </>
          )}

          <ResultValue>Inter array voltage</ResultValue>
          <ResultValue>
            <strong>{voltage ? voltage : 0}</strong> kV
          </ResultValue>
          <Tooltip text="The horizontal cable length, plus the distance up to the water surface at the cable ends.">
            <ResultValue style={{ margin: 0 }}>
              Inter array cable length
            </ResultValue>
          </Tooltip>
          {orLoader(aggregates, ({ total }) => (
            <ResultValue>
              <strong>{total === 0 ? "0 km" : `${total.toFixed(2)}`}</strong> km
            </ResultValue>
          ))}
        </ColoredGrid>
      )}

      <ExportSystem />
      <ElectricalLosses />
      <Visualisations />
    </>
  );
};

export const ElectricalStatistics = ({
  onClose,
  parkId,
}: {
  onClose(): void;
  parkId: string;
}) => {
  const park = useRecoilValue(
    getParkFeatureSelectorFamily({ parkId: parkId ?? "" }),
  );
  const branchId = useRecoilValue(branchIdSelector);
  if (!park) return null;

  return (
    <MenuFrame
      title={"Electrical"}
      icon={<HelpLink article={ARTICLE_CABLE_STATS} />}
      onExit={onClose}
      validationError={
        <ElectricalStatError parkId={park.id} branchId={branchId ?? ""} />
      }
    >
      <Suspense
        fallback={
          <SkeletonBlock
            style={{ height: "1rem", margin: "1rem", width: "unset" }}
          />
        }
      >
        <Inner park={park} />
      </Suspense>
    </MenuFrame>
  );
};
