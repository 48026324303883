import CloseableHint from "./CloseableHint";
import avoidSnappingVideo from "./avoidSnappingVideo.mp4";
import styled from "styled-components";

export const avoidSnappingHintType = "avoidSnappingHintType";

const TextSvgWrapper = styled.div`
  > svg {
    margin: 0 0.3rem;
    width: 1.4rem;
    height: 1.4rem;
  }
`;

const AvoidSnapping = ({
  closeCallback,
  gotItCallback,
}: {
  closeCallback: () => void;
  gotItCallback?: () => void;
}) => {
  return (
    <CloseableHint
      title={"Avoid snapping"}
      closeCallback={closeCallback}
      gotItCallback={gotItCallback}
      timeToDissapearMS={10000}
      videosrc={avoidSnappingVideo}
    >
      <TextSvgWrapper>
        {`Press "Shift" while drawing to avoid snapping to other features`}
      </TextSvgWrapper>
    </CloseableHint>
  );
};

export default AvoidSnapping;
