import {
  isProjectBranchPublic,
  getProjectPublicSettings,
} from "./../services/projectDataAPIService";
import { atomFamily, selectorFamily } from "recoil";
import { PublicProjectBranchMeta, PublicProjectMeta } from "../types/api";

export const publicMetadataAtomFamily = atomFamily({
  key: "publicMetadataAtomFamily",
  default: selectorFamily<
    PublicProjectBranchMeta,
    {
      nodeId: string;
      branchId: string;
    }
  >({
    key: "publicMetadataSelectorFamily",
    get:
      ({ nodeId, branchId }) =>
      async () => {
        return isProjectBranchPublic(nodeId, branchId);
      },
  }),
});

const publicProjectSettingsSelectorFamily = selectorFamily<
  PublicProjectMeta | undefined,
  {
    projectId: string;
  }
>({
  key: "publicProjectSettingsSelectorFamily",
  get:
    ({ projectId }) =>
    async () => {
      if (!projectId) return;
      return getProjectPublicSettings(projectId);
    },
});

export const publicProjectSettingsAtomFamily = atomFamily<
  PublicProjectMeta | undefined,
  {
    projectId: string;
  }
>({
  key: "publicProjectSettingsAtomFamily",
  default: publicProjectSettingsSelectorFamily,
});
