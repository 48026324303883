/// <reference types="vite-plugin-svgr/client" />
import Button from "components/General/Button";
import Fuse from "fuse.js";
import { Input } from "components/General/Input";
import { Row } from "components/General/Layout";
import Close from "@icons/24/Close.svg?react";
import Add from "@icons/24/Add.svg?react";
import StatisticsIcon from "../../../../../icons/24/Statistics.svg?react";
import useTextInput from "hooks/useTextInput";
import { useState } from "react";
import { useRecoilValueLoadable } from "recoil";
import { useTypedPath } from "state/pathParams";
import { IconBtn } from "components/General/Icons";
import {
  ContentTableColumn,
  ContentTableRow,
  Divider,
  ResultContainer,
  SearchAndSelectContainer,
  SecondaryText,
  TextEllipsis,
} from "../../style";
import SelectedLabel from "components/General/SelectedLabel";
import { SVGWrapper } from "@icons/svgUtils";
import { organisationAnalysisResourceState } from "components/Organisation/Library/state";
import { TableRowsPlaceholder } from "../shared/TablePlaceholder";
import { Configuration } from "services/configurationService";

export default function AnalysisModal({
  existingAnalysis,
  onSave,
}: {
  existingAnalysis: string[];
  onSave: (config: Configuration[]) => void;
}) {
  const { organisationId } = useTypedPath("organisationId");
  const analysisInOrgLoadable = useRecoilValueLoadable(
    organisationAnalysisResourceState({ organisationId }),
  );

  const [name, onNameChange] = useTextInput("");
  const [selectedAnalysis, setSelectedAnalysis] = useState<Configuration[]>([]);

  const filteredAnalysis = analysisInOrgLoadable.map((analysisInOrg) => {
    const fuse = new Fuse(analysisInOrg, {
      keys: ["name"],
      includeScore: true,
      threshold: 0.3,
    });

    const results = fuse.search(name).map((result) => result.item);
    return name.length > 0 ? results : analysisInOrg;
  });

  const toggleAnalysisSelection = (analysis: Configuration) => {
    setSelectedAnalysis((prevSelectedAnalysis) => {
      if (prevSelectedAnalysis.find((t) => t.id === analysis.id)) {
        return prevSelectedAnalysis.filter((g) => g.id !== analysis.id);
      } else {
        return [...prevSelectedAnalysis, analysis];
      }
    });
  };

  return (
    <SearchAndSelectContainer>
      <Row
        style={{
          alignItems: "center",
          gap: "0.8rem",
          padding: "1.6rem 1.2rem 0",
        }}
      >
        <Input
          autoFocus
          value={name}
          onChange={onNameChange}
          type="search"
          placeholder={`Search`}
          style={{ width: "22rem" }}
        />
        <IconBtn
          size="1.4rem"
          onClick={() => onSave([])}
          style={{ marginLeft: "auto" }}
        >
          <Close />
        </IconBtn>
      </Row>
      <ResultContainer>
        <ContentTableColumn
          style={{
            overflowY: "auto",
            padding: "1.2rem 0",
          }}
        >
          {analysisInOrgLoadable.state === "loading" && (
            <TableRowsPlaceholder />
          )}
          {filteredAnalysis.state === "loading" ? (
            <TableRowsPlaceholder />
          ) : (
            filteredAnalysis.contents.map((g: Configuration) => {
              const selected = selectedAnalysis.some((st) => st.id === g.id);
              const alreadyExist = existingAnalysis.some((id) => id === g.id);
              return (
                <ContentTableRow
                  key={g.id}
                  style={{ cursor: "pointer", alignItems: "center" }}
                  onClick={() => !alreadyExist && toggleAnalysisSelection(g)}
                  disabled={selected || alreadyExist}
                >
                  <SVGWrapper size={1.4}>
                    <StatisticsIcon />
                  </SVGWrapper>
                  <TextEllipsis style={{ margin: 0, marginRight: "auto" }}>
                    {g.name}
                  </TextEllipsis>
                  {alreadyExist ? (
                    <SecondaryText style={{ marginLeft: "auto" }}>
                      Already connected
                    </SecondaryText>
                  ) : selected ? (
                    <SecondaryText style={{ marginLeft: "auto" }}>
                      Selected
                    </SecondaryText>
                  ) : (
                    <></>
                  )}
                  {!alreadyExist && !selected && (
                    <Button
                      buttonType="text"
                      text="Select"
                      icon={<Add />}
                      onClick={() => {}}
                      size="small"
                      style={{ padding: "0.2rem 1.2rem" }}
                    />
                  )}
                </ContentTableRow>
              );
            })
          )}
        </ContentTableColumn>
      </ResultContainer>
      {selectedAnalysis.length > 0 && (
        <>
          <Divider />
          <Row
            style={{
              padding: "0 1.2rem 1.2rem",
              alignItems: "center",
            }}
          >
            <Row style={{ flexWrap: "wrap", flex: 1, gap: "0.6rem" }}>
              {selectedAnalysis.map((g) => {
                return (
                  <SelectedLabel
                    key={g.id}
                    title={g.name ?? "Unnamed analysis"}
                    onDeselect={() => toggleAnalysisSelection(g)}
                  />
                );
              })}
            </Row>
            <Button
              text="Add"
              onClick={() => onSave(selectedAnalysis)}
              buttonType="primary"
            />
          </Row>
        </>
      )}
    </SearchAndSelectContainer>
  );
}
