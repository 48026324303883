/**
 * A similar map to `Map`, but one which automatically inserts a default element
 * when calling `get` with a key that is not present in the map. This can be useful
 * e.g. for associating a list to a set of keys with the empty list as initializing value
 * so that you don't have to check whether a key is in the map or not.
 *
 * ### Example
 * With the standard `Map`, we need to handle the case where the key is not in the map:
 * ```
 * const map = new Map();
 * for (const [a, b] in foo) {
 *     if (!map.has(a)) {
 *         map.set(a, []);
 *     }
 *     map.get(a).push(b);
 * }
 * ```
 * With `DefaultMap`, this looks like this:
 * ```
 * const map = new DefaultMap(() => []);
 * for (const [a, b] in foo)
 *     map.get(a).push(b);
 * ```
 *
 * ### Notes
 * If the initializing function returns `undefined`, it risk being called excessively. This
 * is checked.
 */
export class DefaultMap<K, V> {
  inner: Map<K, V>;

  constructor(private init: (k: K) => V) {
    this.inner = new Map();
  }

  clear(): void {
    this.inner.clear();
  }

  delete(k: K): boolean {
    return this.inner.delete(k);
  }

  entries(): IterableIterator<[K, V]> {
    return this.inner.entries();
  }

  forEach(callbackFn: (value: V, key: K, map: DefaultMap<K, V>) => void): void {
    return this.inner.forEach((value, key) => {
      callbackFn(value, key, this);
    });
  }

  /**
   * Returns a specified element from the Map object. If the value that is associated to the
   * provided key is an object, then you will get a reference to that object and any change made to
   * that object will effectively modify it inside the Map.
   * @returns — Returns the element associated with the specified key. If no element is associated
   * with the specified key, the initializer is evaluated and its return value inserted in the map,
   * and this value is returned.
   */
  get(key: K): V {
    if (!this.has(key)) this.inner.set(key, this.init(key));
    return this.inner.get(key)!;
  }

  has(key: K): boolean {
    return this.inner.has(key);
  }

  keys(): IterableIterator<K> {
    return this.inner.keys();
  }

  set(key: K, value: V): DefaultMap<K, V> {
    this.inner.set(key, value);
    return this;
  }

  update(key: K, fn: (v: V) => V): DefaultMap<K, V> {
    this.inner.set(key, fn(this.get(key)));
    return this;
  }

  values(): IterableIterator<V> {
    return this.inner.values();
  }
}
