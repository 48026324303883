import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { modalTypeOpenAtom } from "../../state/modal";
import FullScreenModal from "../FullScreenModal/FullScreenModal";
import styled from "styled-components";
import { useCallback, useState } from "react";
import { StandardBox } from "../../styles/boxes/Boxes";
import { Input } from "../General/Input";
import { externalLayerFilterPropertyAtom } from "../../state/layer";
import Button from "../General/Button";

export const FilterExternalDataLayersModalType =
  "FilterExternalDataLayersModal";

export enum FilterOperator {
  Equal = "==",
  LTOE = "<=",
  LT = "<",
  GTOE = ">=",
  GT = ">",
}

const Wrapper = styled(StandardBox)`
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;
  gap: 1rem;
  max-width: 40rem;
`;

const Header = styled.h2`
  margin: 0;
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
`;

const FilterExternalDataLayersInternal = ({
  metadata,
}: {
  metadata: { source: string; property: string };
}) => {
  const { source, property } = metadata;
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const [externalLayerFilterProperty, setExternalLayerFilterProperty] =
    useRecoilState(externalLayerFilterPropertyAtom);
  const [filterOperator, setFilterOperator] = useState<FilterOperator>(
    ((externalLayerFilterProperty[source] ?? {})[property] ?? {}).operator ??
      FilterOperator.Equal,
  );

  const clearFilter = useCallback(
    (source: string, property: string) => {
      setExternalLayerFilterProperty((elfp) => {
        let mutableElfp = { ...elfp };

        if (
          (mutableElfp as any)[source] &&
          (mutableElfp as any)[source][property]
        ) {
          (mutableElfp as any)[source] = Object.keys(
            (mutableElfp as any)[source],
          ).reduce((acc, k) => {
            if (k === property) return acc;
            return { ...acc, [k]: (mutableElfp as any)[source][k] };
          }, {});
          if (Object.keys((mutableElfp as any)[source]).length === 0) {
            mutableElfp = Object.keys(mutableElfp).reduce((acc, k) => {
              if (k === source) return acc;
              return { ...acc, [k]: (mutableElfp as any)[k] };
            }, {});
          }
        }
        return mutableElfp;
      });
      setModalTypeOpen(undefined);
    },
    [setExternalLayerFilterProperty, setModalTypeOpen],
  );

  const addFilter = useCallback(
    (
      source: string,
      property: string,
      operator: FilterOperator,
      value: string,
    ) => {
      if (value === "") {
        return clearFilter(source, property);
      }
      setExternalLayerFilterProperty((elfp) => ({
        ...elfp,
        [source]: { ...(elfp as any)[source], [property]: { operator, value } },
      }));
      setModalTypeOpen(undefined);
    },
    [clearFilter, setExternalLayerFilterProperty, setModalTypeOpen],
  );
  const [inputValue, setInputValue] = useState(
    ((externalLayerFilterProperty as any)[source] || {})[property] != null
      ? (externalLayerFilterProperty as any)[source][property].value
      : "",
  );

  return (
    <FullScreenModal>
      <Wrapper>
        <Header>Filter feature {source}</Header>
        <FilterWrapper>
          <p>{property}</p>
          <select
            value={filterOperator}
            onChange={(e) => {
              setFilterOperator(e.target.value as FilterOperator);
            }}
          >
            <option value={FilterOperator.Equal}>{"="}</option>
            <option value={FilterOperator.GT}>{">"}</option>
            <option value={FilterOperator.LT}>{"<"}</option>
            <option value={FilterOperator.GTOE}>{">="}</option>
            <option value={FilterOperator.LTOE}>{"<="}</option>
          </select>
          <Input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            type="string"
            placeholder="value"
            onCancel={() => setModalTypeOpen(undefined)}
            onEnter={() =>
              addFilter(source, property, filterOperator, inputValue)
            }
            autoFocus
          />
        </FilterWrapper>
        <ButtonWrapper>
          <Button
            text="Cancel"
            buttonType="secondary"
            onClick={() => setModalTypeOpen(undefined)}
          />
          <Button
            text="Clear filter"
            buttonType="secondary"
            onClick={() => clearFilter(source, property)}
          />
          <Button
            text="Filter"
            onClick={() =>
              addFilter(source, property, filterOperator, inputValue)
            }
          />
        </ButtonWrapper>
      </Wrapper>
    </FullScreenModal>
  );
};

const FilterExternalDataLayers = () => {
  const modalTypeOpen = useRecoilValue(modalTypeOpenAtom);
  if (modalTypeOpen?.modalType !== FilterExternalDataLayersModalType)
    return null;

  return (
    <FilterExternalDataLayersInternal
      metadata={modalTypeOpen.metadata ?? { source: "", property: "" }}
    />
  );
};

export default FilterExternalDataLayers;
