import styled from "styled-components";
import { typography } from "../../styles/typography";
import { colors } from "../../styles/colors";
import { ScrollBody } from "../../hooks/useShowScrollShadow";
import { StandardBox } from "styles/boxes/Boxes";

export const PopupDiv = styled(StandardBox)`
  min-width: 20rem;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 50vh;
`;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem 0;
`;

export const VersionScrollBody = styled(ScrollBody)`
  gap: 0;
`;

export const DateText = styled.p`
  ${typography.contentAndButtons};
  margin: 0.4rem 0;
  color: ${colors.primaryText};
`;

export const VersionRow = styled.div<{ isSelected: boolean }>`
  cursor: pointer;
  padding: 0.5rem 0 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ isSelected }) =>
    isSelected &&
    `
    background-color: ${colors.selected};
  `}
  &:hover {
    background-color: ${colors.hover};
  }
`;

export const VersionAuthorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;
