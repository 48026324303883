import { useRecoilValue } from "recoil";
import { activeBathymetryStyleIdAtom } from "state/map";
import CostLayer from "./costLayer";
import Bathymetry from "./depth";
import { BathymetryContourBackgroundLayer } from "./depthContours";
import SpeedLayer from "./windSpeed";

const SelectedBathymetryStyle = () => {
  const style = useRecoilValue(activeBathymetryStyleIdAtom);
  if (style === "contour") return <BathymetryContourBackgroundLayer />;
  if (style === "raster") return <Bathymetry />;
  return null;
};

export default function GeneralMapLayers() {
  return (
    <>
      <SelectedBathymetryStyle />
      <CostLayer />
      <SpeedLayer />
    </>
  );
}
