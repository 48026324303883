import { PropsWithChildren, Suspense, useEffect } from "react";
import { useSetRecoilState, useRecoilValue, useRecoilCallback } from "recoil";
import { getSelectedSubAreas } from "state/division";
import { parkIdSelector } from "state/pathParams";
import {
  ProdId,
  analysisOverrideInputAtomFamily,
  getTriggerAnalysisRefresh,
} from "./state";

/**
 * Default production that looks at the current selection. This is the current
 * park, as well as the selected sub areas, if any.
 */
export const currentSelectionProduction: ProdId =
  "current-production" as ProdId;

/**
 * Mount this component to ensure that production numbers for the current park
 * is computed and made available.
 *
 * ## Note
 * It looks like this can be written as a hook instead, but that is very error
 * prone, since the `useEffect` here needs to run before
 * `useRecoilValue(getAnalysis(id))` will load.  If not written carefully, e.g.
 * by having both in the same component, the `useRecoilValue` will suspend, and
 * the `useEffect` will never run, which leads to the component never rendering.
 */
export const TriggerCurrentProduction = ({
  children,
  fallback = null,
}: PropsWithChildren<{}> & { fallback?: React.ReactNode }) => {
  const setInputArgs = useSetRecoilState(
    analysisOverrideInputAtomFamily(currentSelectionProduction),
  );

  const parkId = useRecoilValue(parkIdSelector) ?? "";
  const selectedSubAreas = useRecoilValue(getSelectedSubAreas({ parkId }));

  useEffect(() => {
    setInputArgs({
      selectedSubAreas: selectedSubAreas.length ? selectedSubAreas : undefined,
    });
  }, [setInputArgs, selectedSubAreas]);

  return <Suspense fallback={fallback}>{children}</Suspense>;
};

export const useAnalysisForceRestart = (id: ProdId) => {
  return useRecoilCallback(
    ({ set }) =>
      () => {
        set(analysisOverrideInputAtomFamily(id), (c) => ({
          ...c,
          restart: true,
        }));
        set(getTriggerAnalysisRefresh(id), (c) => c + 1);
      },
    [id],
  );
};
