import { fetchWithToken } from "../services/utils";
import {
  atom,
  atomFamily,
  selector,
  selectorFamily,
  useRecoilCallback,
  useRecoilState,
  useRecoilValueLoadable,
  useResetRecoilState,
} from "recoil";
import {
  arcgisLayerDrawingInfoSelector,
  arcgisPrivateRestAPILayersFullMetadataSucceededSelector,
  customArcgisRestAPILayersFullMetadataSucceededSelector,
  getArcgisPath,
  isArcgisLayer,
} from "./arcgisRestAPI";
import {
  customWfsDataLayersFullMetadataSucceededSelector,
  getWfsPath,
  isWfsLayer,
  wfsPrivateDataLayersFullMetadataSucceededSelector,
} from "./wfs";
import {
  customWmsDataLayersFullMetadataSelector,
  isWMSLayer,
  wmsPrivateDataLayersFullMetadataSucceededSelector,
} from "./wms";
import { FilterMenuType } from "../components/LowerRight/FilterInput";
import { Feature, Polygon } from "geojson";
import { FeatureCollection } from "@turf/turf";
import {
  _PrivateDataSource,
  ArcgisSourceEntries,
  ExternalDataSource,
  ExternalDataSourceLinkLayerWithSourceHosted,
  Layer,
  LayerType,
  PrivateDataSource,
  SourceTypes,
  SourceTypesLayer,
  WfsSourceEntries,
  WmsSourceEntries,
  WmtsSourceEntries,
} from "../types/layers";
import { z } from "zod";
import { GeometryNoCollection, _Feature } from "../utils/geojson/geojson";
import { HintsMenuType } from "../components/LowerRight/Hints";
import { CORS_AND_CACHE_PROXY_WITH_TOKEN_URL_PREFIX } from "./gisSourceCorsProxy";
import { useEffect } from "react";
import { jsonrepair } from "jsonrepair";
import { getExternalDataSources } from "../services/gisDataAPIService";
import { isDefined, isNumber } from "../utils/predicates";
import { isCustomLayer } from "../components/LayerList/utils";
import { suggestedLayers } from "../constants/suggestedLayersList";
import { showHiddenLayersAtom } from "./internalDataCleaning";
import { getExternalLayerId } from "../utils/externalLayers";
import {
  customWmtsDataLayersFullMetadataSelector,
  wmtsPrivateDataLayersFullMetadataSucceededSelector,
} from "./wmts";
import { EMPTY_LIST } from "./recoil";
import { projectIdSelector } from "./pathParams";
import { syncLocalStorage } from "./effects";
import { SiteLocatorMenuType } from "@constants/projectMapView";

export const isHostedLayer = (
  layer: Layer,
): layer is ExternalDataSourceLinkLayerWithSourceHosted => {
  return layer.sourceType === SourceTypesLayer.hosted;
};

const WFS_FEATURE_COUNT = 20;

const _VectorDataSchema = z.object({
  features: _Feature.array().optional(),
  type: z.string(),
  exceededTransferLimit: z.boolean().optional(),
  isWFSLayerWithoutPaginationSupport: z.boolean().optional(),
  isArcgisLayerWithoutPaginationSupport: z.boolean().optional(),
  numberMatched: z.number().optional(),
  numberReturned: z.number().optional(),
  totalFeatures: z.number().optional(),
});

type VectorDataSchema = z.infer<typeof _VectorDataSchema>;

export const libraryLayersOpenAtom = atom({
  key: "libraryLayersOpenAtom",
  default: false,
});

export const visibleDynamicLayersAtom = atom<string[]>({
  key: "visibleDynamicLayersAtom",
  default: [],
});

export const visibleTileJSONLayersAtom = atom<{
  circles: string[];
  polygons: string[];
  lines: string[];
}>({
  key: "visibleTileJSONLayersAtom",
  default: { circles: [], lines: [], polygons: [] },
});

export type CostLayerVariables = {
  turbinesPerMw: number;
  fixedFoundationPerMw: number;
  fixedFoundationPerMwDepth: number;
  floatingFoundationPerMw: number;
  floatingFoundationPerMwDepth: number;
  opexPerMw: number;
  exportCablePerShoreDistance: number;
  fixedFoundationMaxDepth: number;
};

export const costLayerVariablesAtom = atom<CostLayerVariables>({
  key: "costLayerVariablesAtom",
  default: {
    turbinesPerMw: 1000,
    fixedFoundationPerMw: 150,
    fixedFoundationPerMwDepth: 10.5,
    floatingFoundationPerMw: 800,
    floatingFoundationPerMwDepth: 0,
    opexPerMw: 50,
    exportCablePerShoreDistance: 2.8,
    fixedFoundationMaxDepth: 60,
  },
});

export const windLayerHeightAtom = atom({
  key: "windLayerHeightAtom",
  default: 150,
  effects: [syncLocalStorage("vind:wind-layer:height", z.number())],
});

export const existingTurbinesInputAtom = atom({
  key: "existingTurbinesInputAtom",
  default: { showTurbinesWithoutPower: false },
});

export const costLayerRangeAtom = atom<[number, number]>({
  key: "costLayerRangeAtom",
  default: [0, 60],
  effects: [
    syncLocalStorage(
      "vind:cost-layer:range",
      z.tuple([z.number(), z.number()]),
    ),
  ],
});

export const costLayerFilterAtom = atom<[number, number]>({
  key: "costLayerFilterAtom",
  default: [0, 60],
  effects: [
    syncLocalStorage(
      "vind:cost-layer:filter",
      z.tuple([z.number(), z.number()]),
    ),
  ],
});

export type InfoLayer =
  | undefined
  | "cost"
  | typeof FilterMenuType
  | typeof HintsMenuType
  | typeof SiteLocatorMenuType
  | "windSpeed"
  | "existingTurbines"
  | "waveSampler"
  | "windMeasurement"
  | "mapLayers";

export const externalLayerFilterPropertyAtom = atom<
  Record<string, Record<string, any>>
>({
  key: "externalLayerFilterPropertyAtom",
  default: {},
});

export const lowerRightMenuActiveModeAtom = atom<InfoLayer | undefined>({
  key: "infoLayerActiveAtom",
  default: undefined,
});

export const getExternalDataSourcesSelector = selector<ExternalDataSource[]>({
  key: "getExternalDataSourcesSelector",
  get: ({ get }) => {
    get(allDataSourcesRefreshAtom);
    return getExternalDataSources();
  },
});

export const getAllDataSourcesSelector = selectorFamily<
  (ExternalDataSource | PrivateDataSource)[],
  { projectId: string }
>({
  key: "getAllDataSourcesSelector",
  get:
    ({ projectId }) =>
    ({ get }) => {
      const externalDataSources = get(getExternalDataSourcesSelector);
      const mappedPrivateDataSources = get(
        getAllPrivateSourcesSelector({ projectId }),
      );
      return [...externalDataSources, ...mappedPrivateDataSources];
    },
});

export const allDataSourcesRefreshAtom = atom({
  key: "allDataSourcesRefreshAtom",
  default: 0,
});

export const getAllPrivateSourcesSelector = selectorFamily<
  PrivateDataSource[],
  { projectId: string }
>({
  key: "getAllPrivateSourcesSelector",
  get:
    ({ projectId }) =>
    ({ get }) => {
      if (!projectId) {
        return [];
      }

      const wms = get(
        wmsPrivateDataLayersFullMetadataSucceededSelector({
          projectId,
        }),
      );

      const wmts = get(
        wmtsPrivateDataLayersFullMetadataSucceededSelector({
          projectId,
        }),
      );

      const wfs = get(
        wfsPrivateDataLayersFullMetadataSucceededSelector({
          projectId,
        }),
      );

      const arcgis = get(
        arcgisPrivateRestAPILayersFullMetadataSucceededSelector({
          projectId,
        }),
      );

      return [...wmts, ...wms, ...wfs, ...arcgis].map<PrivateDataSource>(
        (privateDataSource) => {
          return _PrivateDataSource.parse({
            name: privateDataSource.source,
            arcgis:
              privateDataSource.sourceType === SourceTypes.arcgis_rest_api
                ? [
                    {
                      id: "",
                      layers: privateDataSource.layersInfo.map((layer) => ({
                        id: layer.id,
                        name: layer.name,
                        bbox: layer.bbox,
                        dateAdded: null,
                        sourceId: "",
                        sourceType: SourceTypesLayer.arcgis,
                        type: layer.type as LayerType,
                        sourceLayerId: layer.internalId,
                        isHidden: false,
                        tags: [],
                      })),
                      abstract: privateDataSource.abstract,
                      url: privateDataSource.url,
                      layerSettingsGlobal: {},
                    },
                  ]
                : [],
            wms:
              privateDataSource.sourceType === SourceTypes.wms
                ? [
                    {
                      id: "",
                      layers: privateDataSource.layersInfo.map((layer) => ({
                        id: layer.id,
                        name: layer.name,
                        bbox: layer.bbox ?? [],
                        dateAdded: null,
                        sourceId: "",
                        sourceType: SourceTypesLayer.wms,
                        abstract: layer.abstract,
                        type: LayerType.wms,
                        sourceLayerId: layer.layer,
                        isHidden: false,
                        tags: [],
                      })),
                      abstract: privateDataSource.abstract,
                      url: privateDataSource.url,
                      layerSettingsGlobal: {},
                    },
                  ]
                : [],
            wmts:
              privateDataSource.sourceType === SourceTypes.wmts
                ? [
                    {
                      id: "",
                      layers: privateDataSource.layersInfo.map((layer) => ({
                        id: layer.id,
                        name: layer.name,
                        bbox: layer.bbox ?? [],
                        dateAdded: null,
                        sourceId: "",
                        isResourceOriented: layer.isResourceOriented,
                        sourceType: SourceTypesLayer.wmts,
                        abstract: layer.abstract,
                        type: LayerType.wmts,
                        sourceLayerId: layer.layer,
                        isHidden: false,
                        tags: [],
                      })),
                      abstract: privateDataSource.abstract,
                      url: privateDataSource.url,
                      layerSettingsGlobal: {},
                    },
                  ]
                : [],
            wfs:
              privateDataSource.sourceType === SourceTypes.wfs
                ? [
                    {
                      id: "",
                      layers: privateDataSource.layersInfo.map((layer) => ({
                        id: layer.id,
                        name: layer.name,
                        bbox: layer.bbox,
                        dateAdded: null,
                        sourceId: "",
                        sourceType: SourceTypesLayer.wfs,
                        abstract: layer.abstract,
                        type: layer.type as LayerType,
                        sourceLayerId: layer.sourceLayerId,
                        isHidden: false,
                        tags: [],
                        outputValue: layer.outputValue,
                      })),
                      abstract: privateDataSource.abstract,
                      url: privateDataSource.url,
                      layerSettingsGlobal: {},
                    },
                  ]
                : [],
            xyz: [],
            id: "",
            hosted: [],
            tilejson: [],
          });
        },
      );
    },
});

export const getAllSourceEndpointUrls = selectorFamily<
  string[],
  { projectId: string }
>({
  key: "getAllSourceEndpointUrls",
  get:
    ({ projectId }) =>
    ({ get }) => {
      const externalDataSources = get(getAllDataSourcesSelector({ projectId }));
      return externalDataSources
        .flatMap((dataSource) => {
          return [...dataSource.wms, ...dataSource.wfs, ...dataSource.arcgis];
        })
        .map((sourceEndpoint) => sourceEndpoint.url);
    },
});

/**
 * Gets all layers that are not hidden (Or only hidden, if {@link showHiddenLayersAtom} returns true
 */
export const getAllLayersSelector = selectorFamily<
  Layer[],
  { projectId: string }
>({
  key: "getAllLayersSelector",
  get:
    ({ projectId }) =>
    ({ get }) => {
      const allDataSource = get(getAllDataSourcesSelector({ projectId }));
      const showHiddenLayers = get(showHiddenLayersAtom);

      return allDataSource
        .flatMap((source) => {
          return [
            ...source.arcgis,
            ...source.wfs,
            ...source.wms,
            ...source.wmts,
            ...source.xyz.map((xyzEndpoint) => ({
              ...xyzEndpoint,
              abstract: undefined,
              layers: xyzEndpoint.layers?.map((layer) => ({
                ...layer,
                id: getExternalLayerId(
                  xyzEndpoint.url,
                  layer.name,
                  SourceTypesLayer.xyz,
                ),
              })),
            })),
            ...source.hosted.map((hostedEndpoint) => ({
              ...hostedEndpoint,
              layers: hostedEndpoint.layers?.map((layer) => ({
                ...layer,
                id: getExternalLayerId(
                  hostedEndpoint.url,
                  layer.name,
                  SourceTypesLayer.hosted,
                ),
              })),
            })),
            ...source.tilejson,
          ]
            .map((sourceLink) => ({
              layers: sourceLink.layers,
              sourceLink,
            }))
            .flatMap((sourceLink) => {
              return sourceLink.layers?.map((layer) => ({
                ...layer,
                source,
                sourceLink: sourceLink.sourceLink,
              }));
            });
        })
        .filter(isDefined)
        .filter(
          (layer) =>
            (!layer.isHidden && !showHiddenLayers) ||
            (layer.isHidden && showHiddenLayers),
        ) as Layer[]; // safety: none
    },
});

/**
 * Gets layers from {@link getAllLayersSelector} and filter out layers that are deleted from the source
 */
export const getAllNonDeletedLayersSelector = selectorFamily<
  Layer[],
  { projectId: string }
>({
  key: "getAllNonDeletedLayersSelector",
  get:
    ({ projectId }) =>
    ({ get }) => {
      const allLayers = get(getAllLayersSelector({ projectId }));
      return allLayers.filter((layer) => !layer.dateDeleted);
    },
});

export const getSuggestedLayersSelector = selector<Layer[]>({
  key: "getSuggestedLayersSelector",
  get: ({ get }) => {
    const projectId = get(projectIdSelector);
    if (!projectId) {
      return EMPTY_LIST;
    }

    const allLayers = get(getAllLayersSelector({ projectId }));
    return suggestedLayers
      .map((suggestedLayer) =>
        allLayers.find((layer) => layer.id === suggestedLayer.layerId),
      )
      .filter(isDefined);
  },
});

const gisDataMapper = (
  layers: (
    | WmsSourceEntries
    | WmtsSourceEntries
    | WfsSourceEntries
    | ArcgisSourceEntries
  )[],
) =>
  layers
    .filter((l) => l != null)
    .reduce<Record<string, any[]>>(
      (acc, l) => ({
        ...acc,
        [l.source]: [...(acc[l.source] || []), ...l.layersInfo],
      }),
      {},
    );

export const findSourceWithId = (
  sourceId: string,
  sources: ExternalDataSource[],
): ExternalDataSource | undefined => {
  return sources.find((source) => {
    return source.id === sourceId;
  });
};

// Todo: We should use {@link findSourceWithId} instead
export const findSourceWithName = (
  sourceName: string,
  sources: ExternalDataSource[],
): ExternalDataSource | undefined => {
  return sources.find((source) => {
    return source.name === sourceName;
  });
};

export const addNewSourceTemporaryGisDataLayersFullMetadataSelector = selector<
  Record<string, any[]>
>({
  key: "addNewSourceTemporaryGisDataLayersFullMetadataSelector",
  get: ({ get }) => {
    const customWMS = get(customWmsDataLayersFullMetadataSelector);
    const customWMTS = get(customWmtsDataLayersFullMetadataSelector);
    const customWFS = get(customWfsDataLayersFullMetadataSucceededSelector);
    const arcgis = get(customArcgisRestAPILayersFullMetadataSucceededSelector);

    return gisDataMapper([
      ...customWMTS,
      ...customWMS,
      ...customWFS,
      ...arcgis,
    ]);
  },
});

const pathWithOffset = (layer: Layer, offset: number, bbox?: number[]) => {
  if (isHostedLayer(layer)) return `${layer.sourceLink.url}`;
  if (isCustomLayer(layer)) return `${layer.url}`;

  if (isArcgisLayer(layer)) {
    const url = `${getArcgisPath(layer)}&resultOffset=${offset}`;
    if (!bbox) return url;
    return url
      .replaceAll("&geometry=&", "&geometry=" + bbox.join(",") + "&")
      .replaceAll("&inSR=&", "&inSR=4326&");
  }

  if (isWfsLayer(layer)) {
    const url = `${getWfsPath(
      layer,
    )}&count=${WFS_FEATURE_COUNT}&startIndex=${offset}`;
    if (!bbox) return url;
    return url + "&bbox=" + bbox.join(",") + ",EPSG:4326";
  }

  throw new Error(
    "Can not add offset to this layer sourceType: " + layer.sourceType,
  );
};

export const dynamicVectorLayerFeaturesAtomFamily = atomFamily<
  {
    features: Feature[];
    isLoading: boolean;
    cancelled?: boolean;
    offset?: number;
    isFinished?: boolean;
    hasError?: boolean;
  },
  string
>({
  key: "dynamicVectorLayerFeaturesAtomFamily",
  default: {
    features: [],
    isLoading: false,
    cancelled: false,
    offset: 0,
    isFinished: false,
    hasError: false,
  },
});

export const useDynamicStreamer = (layer: Layer) => {
  const [dynamicVectorLayerFeatures, setDynamicVectorLayerFeatures] =
    useRecoilState(dynamicVectorLayerFeaturesAtomFamily(layer.id));
  const resetDynamicVectorLayerFeatures = useResetRecoilState(
    dynamicVectorLayerFeaturesAtomFamily(layer.id),
  );

  // load arcgis layer styling
  useRecoilValueLoadable(arcgisLayerDrawingInfoSelector({ layer }));

  const getState = useRecoilCallback<void[], typeof dynamicVectorLayerFeatures>(
    ({ snapshot }) =>
      () =>
        snapshot.getLoadable(dynamicVectorLayerFeaturesAtomFamily(layer.id))
          .contents,
    [layer],
  );

  useEffect(() => {
    return () => {
      setDynamicVectorLayerFeatures((featuresWithLoading) => ({
        ...featuresWithLoading,
        isLoading: false,
        cancelled: true,
      }));
    };
  }, [setDynamicVectorLayerFeatures]);

  useEffect(() => {
    let offset = getState().offset ?? 0;
    const asyncFunc = async () => {
      if (getState().isLoading || getState().isFinished) {
        return;
      }

      setDynamicVectorLayerFeatures((featuresWithLoading) => ({
        ...featuresWithLoading,
        isLoading: true,
        cancelled: false,
      }));

      while (
        getState().isLoading &&
        !getState().cancelled &&
        !getState().isFinished
      ) {
        let result: VectorDataSchema;
        try {
          result = await getLayerWithOffsetUrl(layer, offset);
        } catch (error) {
          if (error instanceof Error) {
            console.error(error.message);
            // eslint-disable-next-line no-loop-func
            setDynamicVectorLayerFeatures((featuresWithLoading) => ({
              ...featuresWithLoading,
              isLoading: false,
              isFinished: true,
              hasError: true,
              offset,
            }));
          }
          break;
        }

        offset += result.numberReturned ?? 0;

        if (
          isHostedLayer(layer) ||
          isCustomLayer(layer) ||
          result.isWFSLayerWithoutPaginationSupport ||
          result.isArcgisLayerWithoutPaginationSupport ||
          ((isWMSLayer(layer) || isWfsLayer(layer)) &&
            !result.exceededTransferLimit) ||
          result.numberReturned === 0
        ) {
          // eslint-disable-next-line no-loop-func
          setDynamicVectorLayerFeatures((featuresWithLoading) => ({
            ...featuresWithLoading,
            isLoading: false,
            isFinished: true,
            offset,
            features: [
              ...featuresWithLoading.features,
              ...(result.features ?? []),
            ],
          }));
          break;
        }

        // eslint-disable-next-line no-loop-func
        setDynamicVectorLayerFeatures((featuresWithLoading) => ({
          ...featuresWithLoading,
          offset,
          features: [
            ...featuresWithLoading.features,
            ...(result.features ?? []),
          ],
        }));
      }
    };
    asyncFunc();
  }, [setDynamicVectorLayerFeatures, layer, getState]);

  return { dynamicVectorLayerFeatures, resetDynamicVectorLayerFeatures };
};

export const dynamicLayersSelectorFunction = async ({
  layer,
  bbox,
}: {
  layer: Layer;
  bbox?: number[];
}): Promise<
  | {
      [key: string]: any;
      features: Feature[];
    }
  | FeatureCollection<Polygon>
> => {
  let getNextPage = true;

  let result = { features: [] as Feature[] };
  while (getNextPage) {
    try {
      const newResult = await getLayerWithOffsetUrl(
        layer,
        result.features.length,
        bbox,
      );
      const newFeatures = newResult.features ?? [];
      result = {
        ...newResult,
        features: [...newFeatures, ...result.features],
      };
      if (!newResult.exceededTransferLimit) {
        getNextPage = false;
      }
    } catch (err) {
      getNextPage = false;
    }
  }
  return result;
};

export const dynamicLayersSelector = selectorFamily<
  | {
      [key: string]: any;
      features: Feature[];
    }
  | FeatureCollection<Polygon>,
  { layer: Layer; bbox?: number[] }
>({
  key: "dynamicLayersSelector",
  get:
    ({ layer, bbox }) =>
    () => {
      return dynamicLayersSelectorFunction({
        layer,
        bbox,
      });
    },
});

const specialHandlingForWFSLayersWithoutOffsetHandling = async (
  path: string,
): Promise<Response> => {
  const pathWithoutStartIndex = path.substring(0, path.indexOf("&startIndex"));
  const res = pathWithoutStartIndex.includes(
    CORS_AND_CACHE_PROXY_WITH_TOKEN_URL_PREFIX,
  )
    ? await fetchWithToken(pathWithoutStartIndex, {
        method: "get",
      })
    : await fetch(pathWithoutStartIndex, {
        method: "get",
      });

  return res;
};

const specialHandlingForArcgisLayersWithoutPagination = async (
  path: string,
): Promise<Response> => {
  const pathWithoutStartIndex = path
    .substring(0, path.indexOf("&resultOffset"))
    .replace(/&resultRecordCount=\d+/, "");
  console.log({ pathWithoutStartIndex });
  const res = pathWithoutStartIndex.includes(
    CORS_AND_CACHE_PROXY_WITH_TOKEN_URL_PREFIX,
  )
    ? await fetchWithToken(pathWithoutStartIndex, {
        method: "get",
      })
    : await fetch(pathWithoutStartIndex, {
        method: "get",
      });

  return res;
};

const isOnlyNumbers = (value: unknown): boolean => {
  if (Array.isArray(value)) {
    return value.every((subval) => isOnlyNumbers(subval));
  }
  return isNumber(value);
};

const getLayerWithOffsetUrl = async (
  layer: Layer,
  offset: number,
  bbox?: number[],
): Promise<VectorDataSchema> => {
  const path = pathWithOffset(layer, offset, bbox);
  let isWFSLayerWithoutPaginationSupport = false;
  let isArcgisLayerWithoutPaginationSupport = false;
  let res = path.includes(CORS_AND_CACHE_PROXY_WITH_TOKEN_URL_PREFIX)
    ? await fetchWithToken(path, {
        method: "get",
      })
    : await fetch(path, {
        method: "get",
      });

  if (!res.ok) {
    let text = await res.text();
    if (
      text.includes("IOExceptionCannot do natural order without a primary key")
    ) {
      res = await specialHandlingForWFSLayersWithoutOffsetHandling(path);
      isWFSLayerWithoutPaginationSupport = true;
    }
  } else {
    const clone = res.clone();
    const text = await clone.text();
    if (text.includes("Pagination is not supported")) {
      res = await specialHandlingForArcgisLayersWithoutPagination(path);
      isArcgisLayerWithoutPaginationSupport = true;
    }
  }

  if (!res.ok) {
    throw new Error("Error while trying to download external data layer");
  }

  const resJsonText = await res.text();
  const repaired = jsonrepair(resJsonText);
  const resJson = JSON.parse(repaired);
  const parsed = z.record(z.any()).parse(resJson); // NOTE: any here, because we're parsing it later anyways.

  if ("error" in parsed) {
    throw new Error("Error while trying to download external data layer");
  }

  const cleanResult = {
    ...parsed,
    features: (parsed.features ?? []).filter(
      (f: Feature<GeometryNoCollection>) =>
        Boolean(f.geometry?.coordinates) &&
        isOnlyNumbers(f.geometry.coordinates),
    ),
    isWFSLayerWithoutPaginationSupport,
    isArcgisLayerWithoutPaginationSupport,
    exceededTransferLimit:
      parsed.exceededTransferLimit ??
      parsed?.properties?.exceededTransferLimit ??
      (isWfsLayer(layer) && parsed?.features?.length === WFS_FEATURE_COUNT),
    numberReturned: parsed?.features?.length,
  }; // Some results return null as geometry and crash when parsing

  return _VectorDataSchema.parse(cleanResult);
};
