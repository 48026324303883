import { useMemo } from "react";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { CostConfigurationUsageType } from "../../../services/costService";
import { costConfigurationUsageAtomFamily } from "../../../state/costConfigurations";
import {
  organisationIdSelector,
  projectIdSelector,
} from "../../../state/pathParams";
import {
  adminInOrganisationSelectorFamily,
  editorAccessProjectSelector,
  orgFinanicalManageAccessSelector,
} from "../../../state/user";
import { ProjectBranchInfo, UsageContainerInner } from "./common";
import { Header } from "./style";

export default function CostConfigurationUsageCurrentProject({
  costConfigurationId,
}: {
  costConfigurationId: string;
}) {
  const editorAccessProject = useRecoilValue(editorAccessProjectSelector);
  const projectId = useRecoilValue(projectIdSelector);

  if (!editorAccessProject || !projectId) return null;

  return (
    <CostConfigurationUsageInner
      costConfigurationId={costConfigurationId}
      nodeId={projectId}
    />
  );
}

export function CostConfigurationUsageLibrary({
  costConfigurationId,
}: {
  costConfigurationId: string;
}) {
  const organisationId = useRecoilValue(organisationIdSelector);
  const adminInOrg = useRecoilValue(
    adminInOrganisationSelectorFamily({ organisationId }),
  );
  const hasOrgFinancialAccess = useRecoilValue(
    orgFinanicalManageAccessSelector,
  );

  if (!organisationId) return null;
  if (!adminInOrg && !hasOrgFinancialAccess) return null;

  return (
    <CostConfigurationUsageInner
      costConfigurationId={costConfigurationId}
      nodeId={organisationId}
    />
  );
}

function CostConfigurationUsageInner({
  costConfigurationId,
  nodeId,
}: {
  costConfigurationId: string;
  nodeId: string;
}) {
  const usageLoadable = useRecoilValueLoadable(
    costConfigurationUsageAtomFamily({
      nodeId,
      costConfigurationId,
    }),
  );

  return (
    <UsageContainerInner
      loadable={usageLoadable}
      text="This configuration is used in: "
      typeName="branches"
    >
      <ExtraInfo usage={usageLoadable.contents} />
    </UsageContainerInner>
  );
}

function ExtraInfo({ usage }: { usage: CostConfigurationUsageType[] }) {
  const uniqueBranches = useMemo(
    () =>
      usage.reduce<CostConfigurationUsageType[]>(
        (pre, cur) =>
          pre.some((p) => p.branchId === cur.branchId) ? pre : pre.concat(cur),
        [],
      ),
    [usage],
  );

  return (
    <>
      <Header>Cost configuration usage</Header>
      {usage.length > 0 && (
        <div
          style={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
        >
          {uniqueBranches.map((u) => (
            <ProjectBranchInfo key={u.branchId} usage={u} />
          ))}
        </div>
      )}
    </>
  );
}
