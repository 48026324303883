import React, { useMemo } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { ParkFeature } from "../../types/feature";
import { findFeatureChildrenSimple } from "../../state/projectLayers";
import { getBBOXArrayFromFeatures } from "../../utils/geojson/validate";
import { projectFeaturesInBranchSelectorFamily } from "../ProjectElements/state";
import { ElementsToIcon } from "../ElementsToIcon/ElementsToIcon";
import {
  mapboxAccessToken,
  mapboxAccount,
  mapboxStyleId,
} from "../MapNative/constants";
import { isExportCable } from "../../utils/predicates";
import { ProjectFeature } from "../../types/feature";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const ParkBackgroundImage = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const ElementWrapper = styled.div`
  z-index: 1;
  position: relative;
  opacity: 0.8;
`;

const ParkWithChildrenImage = ({
  park,
  branchId,
  mapboxImageSize,
  wrapperStyle,
  style,
  noExportCable,
  onImageLoad,
}: {
  park: ParkFeature;
  branchId: string;
  mapboxImageSize: string;
  style?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
  noExportCable?: boolean;
  onImageLoad?(): void;
}) => {
  const projectFeatures = useRecoilValue(
    projectFeaturesInBranchSelectorFamily({ branchId }),
  );

  const parkAndChildren = useMemo(() => {
    const parkChildren = findFeatureChildrenSimple(projectFeatures, park.id);
    if (noExportCable)
      return ([park] as ProjectFeature[]).concat(
        parkChildren.filter((f) => !isExportCable(f)),
      );
    return [park, ...parkChildren];
  }, [noExportCable, park, projectFeatures]);

  const mapboxUrl = useMemo(() => {
    const bbox = getBBOXArrayFromFeatures(parkAndChildren);
    return `https://api.mapbox.com/styles/v1/${mapboxAccount}/${mapboxStyleId}/static/[${bbox}]/${mapboxImageSize}?access_token=${mapboxAccessToken}`;
  }, [mapboxImageSize, parkAndChildren]);

  return (
    <Wrapper style={wrapperStyle}>
      <ElementWrapper>
        <ElementsToIcon
          elements={parkAndChildren}
          resolution="800"
          style={style}
          fillPolygons={true}
          fillParks={false}
        />
      </ElementWrapper>
      <ParkBackgroundImage
        src={mapboxUrl}
        referrerPolicy="origin"
        onLoad={onImageLoad}
      />
    </Wrapper>
  );
};

export default React.memo(ParkWithChildrenImage);
