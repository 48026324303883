import React, { useCallback, useMemo } from "react";
import { useRecoilCallback, useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { spacing4, spacing8 } from "styles/space";
import CollectionItem from "components/LayerList/LayerListPane/CollectionItem";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { selectedLayerIdsWithCollectionIdAtom } from "components/LayerList/state";
import { isDefined } from "utils/predicates";
import { deleteCustomLayers } from "../../../services/customLayersAPIService";
import { useForceReloadCustomLayers } from "../../../state/customLayers";
import { Layer } from "../../../types/layers";
import { useTypedPath } from "../../../state/pathParams";
import { LayerCollection } from "../LayerSettings/types";
import { useCollectionCrud } from "../Collections/useCollectionCrud";
import {
  layerVisibleAtomFamily,
  layersSettingSelectorFamily,
} from "../LayerSettings/state";
import { useLayerSettingsCrud } from "../LayerSettings/useLayerSettingsCrud";
import ProjectLayerItem from "./ProjectLayerItem";
import { inReadOnlyModeSelector } from "../../../state/project";
import { isCustomUploadedLayer } from "../utils";
import { BackendLayerCollection } from "../Collections/service";
import { useToast } from "hooks/useToast";
import Button from "components/General/Button";
import { dedup, platformCtrlOrCommand } from "utils/utils";

const ProjectLayersTabWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${spacing4};
`;

const CollectionsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing4};

  &:not(:empty) {
    margin-bottom: ${spacing8};
  }
`;

interface Props {
  layers: Layer[];
  collections: LayerCollection[];
  canEditProject: boolean;
  setOpenedCollections: React.Dispatch<React.SetStateAction<string[]>>;
}

const ProjectLayersTab = ({
  layers,
  collections,
  canEditProject,
  setOpenedCollections,
}: Props) => {
  const { put: putCollection, post: postCollection } = useCollectionCrud();
  const { del: removeLayers } = useLayerSettingsCrud();
  const { projectId } = useTypedPath("projectId");
  const forceReloadCustomLayers = useForceReloadCustomLayers();
  const { isCreatingNewCollection } = useCollectionCrud();
  const { success: showSuccess } = useToast();
  const [selectedLayerIdsWithCollection, setSelectedLayerIdsWithCollection] =
    useRecoilState(selectedLayerIdsWithCollectionIdAtom);

  const layersWithoutCollections = useMemo(() => {
    return layers.filter((layer) => {
      return !collections.some((collection) =>
        collection.layers.find(
          (collectionLayer) => collectionLayer.id === layer.id,
        ),
      );
    });
  }, [collections, layers]);

  const onToggleMultiSelect = useCallback(
    (newLayerId: string, newCollectionId: string | undefined) => {
      setSelectedLayerIdsWithCollection((state) => {
        const hasPreviouslySelectedInsideAFolder = state.some(
          ({ collectionId }) => isDefined(collectionId),
        );

        const hasPreviouslySelectedInsideSameFolder =
          hasPreviouslySelectedInsideAFolder &&
          state.some(({ collectionId }) => collectionId === newCollectionId);

        const isAlreadySelected = state.some(
          ({ layerId, collectionId }) =>
            layerId === newLayerId && collectionId === newCollectionId,
        );

        if (isAlreadySelected) {
          return state.filter(
            ({ layerId, collectionId }) =>
              layerId !== newLayerId || collectionId !== newCollectionId,
          );
        }

        if (hasPreviouslySelectedInsideSameFolder) {
          return [
            ...state,
            { layerId: newLayerId, collectionId: newCollectionId },
          ];
        }

        const newSelectionIsInsideFolder = Boolean(newCollectionId);
        if (hasPreviouslySelectedInsideAFolder && !newSelectionIsInsideFolder) {
          return [
            {
              layerId: newLayerId,
              collectionId: undefined,
            },
          ];
        }

        if (newCollectionId) {
          return [
            {
              layerId: newLayerId,
              collectionId: newCollectionId,
            },
          ];
        }

        return [
          ...state,
          {
            layerId: newLayerId,
            collectionId: newCollectionId,
          },
        ];
      });
    },
    [setSelectedLayerIdsWithCollection],
  );

  const onShiftSelect = useCallback(
    (newLayerId: string, newCollectionId: string | undefined) => {
      const lastSelectedLayer =
        selectedLayerIdsWithCollection[
          selectedLayerIdsWithCollection.length - 1
        ];

      if (!lastSelectedLayer) {
        setSelectedLayerIdsWithCollection([{ layerId: newLayerId }]);
        return;
      }

      // Only allow shift select in the same collection (or no collection)
      if (lastSelectedLayer.collectionId !== newCollectionId) {
        setSelectedLayerIdsWithCollection([
          { layerId: newLayerId, collectionId: newCollectionId },
        ]);
        return;
      }

      const lastSelectedIndex = layers.findIndex(
        (layer) => layer.id === lastSelectedLayer.layerId,
      );

      const newLayerIndex = layers.findIndex(
        (layer) => layer.id === newLayerId,
      );
      const start = Math.min(lastSelectedIndex, newLayerIndex);
      const end = Math.max(lastSelectedIndex, newLayerIndex);
      const layersToSelect = layers.slice(start, end + 1);
      const selectedLayerIds = layersToSelect.map(({ id }) => id);

      const newIds = selectedLayerIds.map((layerId) => ({
        layerId,
        collectionId: newCollectionId,
      }));
      setSelectedLayerIdsWithCollection((curr) => dedup([...curr, ...newIds]));
    },
    [layers, selectedLayerIdsWithCollection, setSelectedLayerIdsWithCollection],
  );

  const onAddAllToCollectionClick = useCallback(
    async (collection?: LayerCollection) => {
      const layerIdsThatAreNotAlreadyInCollection =
        selectedLayerIdsWithCollection
          .filter(
            ({ layerId }) =>
              typeof collection === "undefined" ||
              !collection.layers.some((l) => l.id === layerId),
          )
          .map(({ layerId }) => layerId);

      const layersToAdd = layers.filter((sortedLayer) =>
        layerIdsThatAreNotAlreadyInCollection.includes(sortedLayer.id),
      );

      let response: BackendLayerCollection | undefined;
      if (!collection) {
        response = await postCollection({
          layers: layersToAdd,
        });
      } else {
        response = await putCollection({
          ...collection,
          layers: [...collection.layers, ...layersToAdd],
        });
      }
      if (response) {
        showSuccess(
          `${layersToAdd.length} layer${layersToAdd.length !== 0 ? "s" : ""} added to folder`,
        );
        setSelectedLayerIdsWithCollection([]);
        setOpenedCollections([response.id]);
      }
    },
    [
      selectedLayerIdsWithCollection,
      layers,
      postCollection,
      putCollection,
      showSuccess,
      setSelectedLayerIdsWithCollection,
      setOpenedCollections,
    ],
  );

  const onRemoveMultipleLayers = useCallback(async () => {
    if (!projectId) return;

    // TOdo: Remove layers from collections
    const _collectionLayersToRemove = selectedLayerIdsWithCollection.filter(
      ({ collectionId }) => isDefined(collectionId),
    );

    const ids = selectedLayerIdsWithCollection
      .filter(({ collectionId }) => !isDefined(collectionId))
      .map(({ layerId }) => layerId);

    setSelectedLayerIdsWithCollection([]);
    await removeLayers(ids);
    const customLayerIds = ids.filter((id) => {
      const layer = layers.find((l) => l.id === id);
      if (!layer) {
        return false;
      }
      return isCustomUploadedLayer(layer);
    });
    if (customLayerIds.length > 0) {
      await deleteCustomLayers(projectId, customLayerIds);
      forceReloadCustomLayers();
    }
  }, [
    projectId,
    selectedLayerIdsWithCollection,
    setSelectedLayerIdsWithCollection,
    removeLayers,
    layers,
    forceReloadCustomLayers,
  ]);

  const isReadOnly = useRecoilValue(inReadOnlyModeSelector);

  const allLayerSettings = useRecoilValue(
    layersSettingSelectorFamily({
      projectId,
      layerIds: layers.map((layer) => layer.id),
    }),
  );

  const allLayersAreOff = useMemo(() => {
    return (
      layers.length === 0 ||
      layers.every((layer) => {
        return !allLayerSettings.find((setting) => setting.id === layer.id)
          ?.visible;
      })
    );
  }, [allLayerSettings, layers]);

  const setLayersNotVisible = useRecoilCallback(
    ({ set }) =>
      (layers: Layer[]) => {
        for (const layer of layers) {
          set(
            layerVisibleAtomFamily({
              projectId,
              layerId: layer.id,
            }),
            false,
          );
        }
      },
    [projectId],
  );
  const toggleAllProjectLayersOff = useCallback(() => {
    setLayersNotVisible(layers);
  }, [layers, setLayersNotVisible]);

  const resetSelectedLayerIds = useCallback(
    (e: React.MouseEvent) => {
      if (platformCtrlOrCommand(e)) {
        return;
      }
      setSelectedLayerIdsWithCollection([]);
    },
    [setSelectedLayerIdsWithCollection],
  );

  return (
    <ProjectLayersTabWrapper onClick={resetSelectedLayerIds}>
      {layers.length > 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            paddingRight: spacing4,
          }}
        >
          <Button
            buttonType="text"
            size="small"
            text="Turn off all"
            onClick={toggleAllProjectLayersOff}
            disabled={allLayersAreOff}
          />
        </div>
      )}
      <CollectionsListWrapper>
        {collections.map((collection) => {
          return (
            <CollectionItem
              key={collection.id}
              collection={collection}
              editable={!isReadOnly && canEditProject}
              defaultOpen={false}
              onToggleMultiSelect={onToggleMultiSelect}
              onAddAllToCollectionClick={onAddAllToCollectionClick}
              onShiftSelect={onShiftSelect}
              // defaultOpen={openedCollections.includes(collection.id)}
              onOpenChange={() => {}}
            />
          );
        })}
        {isCreatingNewCollection && (
          <SkeletonBlock
            style={{
              height: "3.6rem",
            }}
          />
        )}
      </CollectionsListWrapper>
      {collections.length === 0 && layers.length === 0 ? (
        <p>Add layers to your map and they will appear here.</p>
      ) : (
        layersWithoutCollections.map((layer) => (
          <ProjectLayerItem
            key={layer.id}
            layer={layer}
            depth={0}
            collections={collections}
            editable={!isReadOnly && canEditProject}
            nameEditable={isCustomUploadedLayer(layer)}
            onToggleMultiSelect={onToggleMultiSelect}
            onShiftSelect={onShiftSelect}
            onAddAllToCollectionClick={onAddAllToCollectionClick}
            onRemoveMultiple={onRemoveMultipleLayers}
          />
        ))
      )}
    </ProjectLayersTabWrapper>
  );
};

export default ProjectLayersTab;
