/// <reference types="vite-plugin-svgr/client" />
import React, { ReactElement, ReactNode, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import styled from "styled-components";
import CloseIcon from "@icons/24/Close.svg?react";
import DnDsmallIcon from "@icons/12/DnDsmall.svg?react";
import { SkeletonBlock } from "../../Loading/Skeleton";
import { spaceLarge } from "../../../styles/space";
import { Comp } from "../../../types/utils";
import { ErrorBoundaryPrintOnly } from "../../ErrorBoundaries/ErrorBoundaryLocal";
import { logError } from "../../ErrorBoundaries/utils";
import { IconBtn } from "../../General/Icons";
import { Column } from "../../General/Layout";
import { DotMenu } from "../../General/MenuButton";
import HelpTooltip from "../../HelpTooltip/HelpTooltip";
import { EditableTextH3Wrapper } from "../../Projects/styles";
import { useDashboardContext } from "../Dashboard";
import { WidgetId } from "../types";
import {
  CardContainer,
  CardDiv,
  DotMenuWrapper,
  Header,
  HoverCardCloseWrapper,
  Scroll,
} from "./Base.style";

type CardProps = {
  title: string | ReactNode;
  widgetId: WidgetId;
  menu?: ReactElement;
  helptext?: string;
};

export const Card = ({
  title,
  children,
  widgetId,
  menu,
  helptext,
  ...props
}: React.PropsWithChildren<Comp<"div", CardProps>>) => {
  const { removeWidget, dashboard, canEdit, noMenus } = useDashboardContext();
  const edit = !dashboard.preset && canEdit;
  return (
    <CardDiv {...props} preset={dashboard.preset}>
      <Header>
        <DnDsmallIcon className="dnd-hand" />
        <EditableTextH3Wrapper>{title}</EditableTextH3Wrapper>
        {helptext && !noMenus && <HelpTooltip text={helptext} place="left" />}
        <div style={{ flex: 1 }} />
        <div style={{ display: "flex", alignItems: "center" }}>
          {menu && !noMenus && (
            <DotMenuWrapper>
              <DotMenu>{menu}</DotMenu>
            </DotMenuWrapper>
          )}
          {edit && (
            <IconBtn size="1.4rem" onClick={() => removeWidget(widgetId)}>
              <CloseIcon />
            </IconBtn>
          )}
        </div>
      </Header>
      <Scroll>{children}</Scroll>
    </CardDiv>
  );
};

const CornerExitButton = ({ id }: { id: WidgetId }) => {
  const { removeWidget, dashboard, canEdit } = useDashboardContext();
  const edit = !dashboard.preset;
  if (!edit || !canEdit) return null;
  return (
    <HoverCardCloseWrapper
      onClick={() => {
        removeWidget(id);
      }}
    >
      <CloseIcon />
    </HoverCardCloseWrapper>
  );
};

export const LoadingState = () => (
  <Column style={{ padding: spaceLarge }}>
    <SkeletonBlock style={{ height: "1rem", width: "100%" }} />
    <SkeletonBlock style={{ height: "1rem", width: "100%" }} />
    <SkeletonBlock style={{ height: "1rem", width: "100%" }} />
  </Column>
);

/**
 * A <Card /> that's both a Suspense- and Error boundary.
 * All widgets should be wrapped in this, so that a widget can crash or load or whatever without
 * crashing the whole dashboard.  Further, this allows the Card and its title to be shown when
 * the contents of the card is loading.
 *
 * If the widget wants to handle its own loading by e.g. `useRecoilStateLoadable` or something,
 * it should return `<LoadingState />` for the loading case.
 */
export const SafeCard = ({
  title,
  id,
  menuItems,
  children,
  fullscreen,
  helptext,
}: React.PropsWithChildren<{
  title: string | ReactNode;
  id: WidgetId;
  menuItems?: React.ReactElement;
  /** A fullscreen card does not have a title bar. For instance, the map widgets. */
  fullscreen?: boolean;
  /** Help text that shows up on hover over an (i) icon to the right of the title. */
  helptext?: string;
}>) => {
  if (fullscreen) {
    return (
      <CardContainer>
        <ErrorBoundary
          FallbackComponent={ErrorBoundaryPrintOnly}
          onError={(error, info) => logError(id, error, info)}
        >
          <Suspense fallback={<LoadingState />}>
            <div style={{ display: "flex", position: "relative", flex: 1 }}>
              {children}
              <CornerExitButton id={id} />
            </div>
          </Suspense>
        </ErrorBoundary>
      </CardContainer>
    );
  }
  return (
    <Card title={title} widgetId={id} menu={menuItems} helptext={helptext}>
      <ErrorBoundary
        FallbackComponent={ErrorBoundaryPrintOnly}
        onError={(error, info) => logError(id, error, info)}
      >
        <Suspense fallback={<LoadingState />}>{children}</Suspense>
      </ErrorBoundary>
    </Card>
  );
};

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
