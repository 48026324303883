import { Suspense, useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import mapboxgl from "mapbox-gl";
import {
  addFeatureAtom,
  editFeaturesAtom,
  mapControlsAtom,
  mapInteractionSelector,
  mapRefAtom,
} from "../../state/map";
import mapboxGlDraw from "@vind-ai/mapbox-gl-draw";
import CopyPasteSelectedFeature from "./CopyPasteSelectedFeature";
import { editorAccessProjectSelector } from "../../state/user";
import DrawLineString from "./CustomModes/MapboxGlDrawLineWithLength";
import { drawThemes } from "./CustomModes/lib/draw-themes";
import DirectSelectWithArea from "./CustomModes/DirectSelectWithArea";
import MultiSelectFeatures from "./MultiSelect/MultiSelectFeatures";
import EditAnyFeature from "./Edit/EditAnyFeature";
import LiveCursor from "../LiveCursor/LiveCursor";
import useUpdateSnapPoints from "./useUpdateSnapPoints";
import SimpleSelect from "./CustomModes/SimpleSelect";
import {
  ErrorBoundarySilent,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "../ErrorBoundaries/ErrorBoundaryLocal";
import { useDrawMode } from "./useActivateDrawMode";
import {
  clickHandlerAtom,
  doubleClickHandlerAtom,
  mouseMoveHandlerAtom,
} from "components/Mapbox/state";
import DrawRectangle from "./CustomModes/DrawRectangle";
import ClickCircleMode from "./CustomModes/DrawCircle";
import DrawWithArea from "./CustomModes/MapboxGlDrawWithArea";

const controls = new mapboxGlDraw({
  controls: {
    point: false,
    line_string: false,
    polygon: false,
    trash: true,
    combine_features: false,
    uncombine_features: false,
  },
  modes: Object.assign(mapboxGlDraw.modes, {
    draw_line_string: DrawLineString,
    draw_polygon: DrawWithArea,
    draw_circle: ClickCircleMode,
    draw_rectangle: DrawRectangle,
    direct_select: DirectSelectWithArea,
    simple_select: SimpleSelect,
  }) as any,
  styles: drawThemes,
});

const controlsViewer = new mapboxGlDraw({
  boxSelect: true,
  controls: {
    point: false,
    polygon: false,
    trash: false,
    line_string: false,
    uncombine_features: false,
    combine_features: false,
  },
});

const MapControls = ErrorBoundaryWrapper(
  () => {
    const map = useRecoilValue(mapRefAtom);
    const editorAccessProject = useRecoilValue(editorAccessProjectSelector);
    if (!map) return null;

    if (editorAccessProject) {
      return <MapControlsEditorInner map={map} />;
    }

    return <MapControlsViewerInner map={map} />;
  },
  ErrorBoundarySilent,
  ScreamOnError,
);

const MapControlsEditorInner = ({ map }: { map: mapboxgl.Map }) => {
  useUpdateSnapPoints();
  const [mapControls, setMapControls] = useRecoilState(mapControlsAtom);
  const [addFeature, setAddFeature] = useRecoilState(addFeatureAtom);
  const [leftMenuActiveMode, setLeftMenuActiveMode] = useDrawMode();
  const mapInteraction = useRecoilValue(mapInteractionSelector);
  const [editFeature, setEditFeature] = useRecoilState(editFeaturesAtom);

  const setMouseMoveHandler = useSetRecoilState(mouseMoveHandlerAtom);
  const setClickHandler = useSetRecoilState(clickHandlerAtom);
  const setDoubleClickHandler = useSetRecoilState(doubleClickHandlerAtom);

  useEffect(() => {
    if (leftMenuActiveMode) {
      setMouseMoveHandler(() => () => {});
      setClickHandler(() => () => {});
      setDoubleClickHandler(() => () => {});
      return () => {
        setMouseMoveHandler(undefined);
        setClickHandler(undefined);
        setDoubleClickHandler(undefined);
      };
    }
  }, [
    leftMenuActiveMode,
    setClickHandler,
    setDoubleClickHandler,
    setMouseMoveHandler,
  ]);

  useEffect(() => {
    if (!mapControls) return;
    const onClickEnterFinishEditing = (e: KeyboardEvent) => {
      if (!editFeature) return;
      if (e.key !== "Enter") {
        return;
      }

      // Only when in simple/direct_select mode (editing, not drawing)
      if (mapControls.getMode().includes("draw")) return;

      mapControls.deleteAll();
      setEditFeature((cur) => (cur.length > 0 ? [] : cur));
      setLeftMenuActiveMode(undefined);
    };
    window.addEventListener("keydown", onClickEnterFinishEditing);
    return () =>
      window.removeEventListener("keydown", onClickEnterFinishEditing);
  }, [mapControls, editFeature, setEditFeature, setLeftMenuActiveMode]);

  useEffect(() => {
    if (!addFeature || !mapControls) return;
    const onClickEscapeExitDrawing = (e: KeyboardEvent) => {
      if (e.key !== "Escape") {
        return;
      }
      mapControls.deleteAll();
      mapControls.changeMode("simple_select");
      setAddFeature(undefined);
      setLeftMenuActiveMode(undefined);
    };
    window.addEventListener("keydown", onClickEscapeExitDrawing);
    return () =>
      window.removeEventListener("keydown", onClickEscapeExitDrawing);
  }, [mapControls, addFeature, setAddFeature, setLeftMenuActiveMode]);

  useEffect(() => {
    map.addControl(controls, "bottom-right");
    setMapControls(controls);
    return () => {
      if (map.hasControl(controls)) {
        controls.deleteAll();
        controls.changeMode("simple_select");
        setAddFeature(undefined);
        map.removeControl(controls);
        setMapControls(undefined);
      }
    };
  }, [map, mapControls, setAddFeature, setMapControls]);

  useEffect(() => {
    if (mapInteraction.createGeneratePolygon) return;

    const divs = document.getElementsByClassName(
      "mapboxgl-ctrl-group mapboxgl-ctrl",
    );

    if (divs.length === 0) return;

    const controlDiv = divs[0];
    const hide = document.createElement("div");
    hide.style.width = "100%";
    hide.style.height = "100%";
    hide.style.opacity = "0.7";
    hide.style.backgroundColor = "white";
    hide.style.position = "absolute";
    hide.style.top = "0";
    hide.style.cursor = "not-allowed";
    controlDiv.appendChild(hide);

    return () => {
      controlDiv.removeChild(hide);
    };
  }, [mapInteraction]);

  return (
    <>
      <Suspense fallback={null}>
        <CopyPasteSelectedFeature />
      </Suspense>
      <Suspense fallback={null}>
        <MultiSelectFeatures map={map} />
      </Suspense>
      <Suspense fallback={null}>
        <EditAnyFeature map={map} />
      </Suspense>
      <Suspense fallback={null}>
        <LiveCursor />
      </Suspense>
    </>
  );
};

const MapControlsViewerInner = ({ map }: { map: mapboxgl.Map }) => {
  useEffect(() => {
    map.addControl(controlsViewer, "bottom-right");

    return () => {
      if (map.hasControl(controlsViewer)) {
        controlsViewer.deleteAll();
        controlsViewer.changeMode("simple_select");
        map.removeControl(controlsViewer);
      }
    };
  }, [map]);

  return (
    <>
      <MultiSelectFeatures map={map} />
      <LiveCursor />
    </>
  );
};

export default MapControls;
