import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import { projectIdSelector } from "../state/pathParams";
import { _CostConfiguration } from "../services/costService";
import { savingCostConfigurationInProgressAtom } from "../state/costConfigurations";
import {
  windConfigurationTempName,
  windSourceConfigurationsAtomFamily,
} from "state/windSourceConfiguration";
import { updateWindSourceConfiguration } from "services/windSourceConfigurationService";

const useWindConfigurationCrud = () => {
  const projectId = useRecoilValue(projectIdSelector);
  const setIsSaving = useSetRecoilState(savingCostConfigurationInProgressAtom);

  // This method saves the new name to the data base and the atom 'windConfigurationTempName'.
  // The new name is not updated in localConfig or windSourceConfigurationsAtomFamily,
  // because it would result in loosing non-saved values in the config
  const saveName = useRecoilCallback(
    ({ snapshot, set }) =>
      async (configId: string, name: string) => {
        if (!projectId) return;
        setIsSaving(true);

        set(
          windConfigurationTempName({
            nodeId: projectId,
          }),
          (cur) => ({
            ...cur,
            [configId]: name,
          }),
        );

        const currentConfigurations = await snapshot.getPromise(
          windSourceConfigurationsAtomFamily({ projectId }),
        );

        const currentConfig = currentConfigurations.find(
          (c) => c.id === configId,
        );

        if (!currentConfig)
          throw Error("Could not find wind config, config id: " + configId);

        await updateWindSourceConfiguration({
          nodeId: projectId,
          configuration: { ...currentConfig, name: name },
        });
        setIsSaving(false);
      },
    [setIsSaving, projectId],
  );

  // This is used to transfer the new names stored in windConfigurationTempName to windSourceConfigurationsAtomFamily,
  // This is called when the component is unmounted
  const saveTempNameToLocal = useRecoilCallback(
    ({ set, snapshot }) =>
      async (configId: string) => {
        if (!projectId) return;

        const tempConfigNames = await snapshot.getPromise(
          windConfigurationTempName({ nodeId: projectId }),
        );

        const tempName = tempConfigNames[configId];
        if (!tempName) return;

        set(
          windSourceConfigurationsAtomFamily({
            projectId,
          }),
          (cur) => {
            const index = cur.findIndex((c) => c.id === configId);
            return [
              ...cur.slice(0, index),
              { ...cur[index], name: tempName },
              ...cur.slice(index + 1),
            ];
          },
        );

        set(
          windConfigurationTempName({
            nodeId: projectId,
          }),
          (cur) => ({
            ...cur,
            [configId]: undefined,
          }),
        );
      },
    [projectId],
  );

  return { saveName, saveTempNameToLocal };
};

export default useWindConfigurationCrud;
