import { currentExportCableTypesSelector } from "components/Cabling/Generate/state";
import { selectorFamily } from "recoil";
import {
  CostType,
  isFeatureCost,
  isOverrideCost,
} from "../../../../services/costService";
import { libraryAndProjectCostConfigurationsSelectorFamily } from "../../../../state/costConfigurations";
import {
  unitToAmountUnit,
  _CostUnit,
  CostUnit,
} from "../../../../types/financial";
import { Cost, costId } from "../capexNumbers";
import { amountId } from "../generalAmounts";

export const exportCableCostsSelectorFamily = selectorFamily<
  Cost[],
  {
    projectId: string;
    configurationId: string;
  }
>({
  key: "exportCableCostsSelectorFamily",
  get:
    ({ projectId, configurationId }) =>
    ({ get }) => {
      const configurations = get(
        libraryAndProjectCostConfigurationsSelectorFamily({
          nodeId: projectId,
        }),
      );
      const allExportCableTypes = get(currentExportCableTypesSelector);
      const configuration = configurations.find(
        (c) => c.id === configurationId,
      );

      if (!configuration) return [];

      const {
        capex: { custom, fixed },
      } = configuration;

      const flatCosts: Cost[] = custom
        .filter((c) => c.category === CostType.ExportCable)
        .filter((c) => c.unit === CostUnit.millionEuro)
        .map((c) => ({
          ...c,
          id: costId({
            category: CostType.ExportCable,
            costId: c.id,
          }),
          amountId: amountId({
            unit: unitToAmountUnit[c.unit],
            category: c.category,
          }),
        }));

      const customCosts: Cost[] =
        custom
          .filter((c) => c.category === CostType.ExportCable)
          .filter((c) => c.unit !== CostUnit.millionEuro)
          .flatMap((custom) => ({
            ...custom,
            name: `${custom.name}`,
            id: costId({
              category: CostType.ExportCable,
              costId: custom.id,
              featureTypeId: CostType.ExportCable,
            }),
            amountId: amountId({
              unit: unitToAmountUnit[custom.unit],
              category: custom.category,
            }),
          })) ?? [];

      let fixedCosts: Cost[] = [];

      if (isFeatureCost(fixed.exportCable)) {
        fixedCosts = allExportCableTypes.map(
          ({ cost, costUnit: _costUnit, id, name }) => {
            const costUnit = _CostUnit.parse(_costUnit);

            return {
              id: `export_cable_${id}`,
              amountId: amountId({
                unit: unitToAmountUnit[costUnit],
                category: CostType.ExportCable,
                featureTypeId: id,
              }),
              category: CostType.ExportCable,
              name: name,
              cost: cost ?? 0,
              unit: costUnit,
              confidenceLevel: undefined,
            };
          },
        );
      } else if (isOverrideCost(fixed.exportCable)) {
        const { cost, unit, confidenceLevel } = fixed.exportCable;
        fixedCosts.push({
          id: `export_cable`,
          amountId: amountId({
            unit: unitToAmountUnit[unit],
            category: CostType.ExportCable,
          }),
          category: CostType.ExportCable,
          name: "Export cable",
          cost: cost,
          unit: unit,
          confidenceLevel: confidenceLevel,
        });
      }

      return [...fixedCosts, ...customCosts, ...flatCosts];
    },
});
