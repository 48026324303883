import styled from "styled-components";
import { StyledButton } from "components/General/Button";

export const IconButtonStyler = styled.div<{
  backgroundColor?: string;
  hoverBackgroundColor?: string;
}>`
  ${StyledButton} {
    background-color: ${({ backgroundColor }) => backgroundColor ?? "initial"};
  }

  ${StyledButton}:hover {
    background-color: ${({ hoverBackgroundColor }) =>
      hoverBackgroundColor ?? "initial"};
  }
`;
