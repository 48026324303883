import { MapMouseEvent, EventData } from "mapbox-gl";
import { useState, useRef, useEffect } from "react";
import { atom, useRecoilValue } from "recoil";
import styled from "styled-components";
import { z } from "zod";
import { mapRefAtom } from "../../state/map";
import { StandardBox } from "../../styles/boxes/Boxes";
import { hoverPolygonAtom } from "./SiteLocatorHeatmap";

const _ComparisonCell = z
  .object({
    polygonId: z.number(),
    depth: z.number(),
    shoreDist: z.number(),
    meanSpeed: z.number(),
    aep: z.number(),
    lcoe: z.number(),
  })
  .optional();
type ComparisonCell = z.infer<typeof _ComparisonCell>;
export const comparisonCellState = atom<ComparisonCell>({
  key: "comparisonCell",
  default: undefined,
});

export const ScoresOnMouse = () => {
  const hoverPolygon = useRecoilValue(hoverPolygonAtom);

  if (!hoverPolygon.show) return null;

  return (
    <FollowMouse
      depth={hoverPolygon.depth}
      shoreDist={hoverPolygon.shoreDist}
      meanSpeed={hoverPolygon.meanSpeed}
      capacity={hoverPolygon.capacity}
      lcoe={hoverPolygon.lcoe}
    />
  );
};

const FollowMouse = ({
  depth,
  shoreDist,
  meanSpeed,
  capacity,
  lcoe,
}: {
  depth: number;
  shoreDist: number;
  meanSpeed: number;
  capacity: number;
  lcoe: number;
}) => {
  const [pos, setPos] = useState<number[]>([0, 0]);
  const [lngLat, setLngLat] = useState<number[] | undefined>(undefined);
  const map = useRecoilValue(mapRefAtom);
  const isMounted = useRef(true);
  const comparisonCell = useRecoilValue(comparisonCellState);

  useEffect(() => {
    isMounted.current = true;
    if (!map) return;

    const mouseMove = (e: MapMouseEvent & EventData) => {
      // avoid setting states during unmount map.off below
      if (isMounted.current) {
        setPos(Object.values(e.point));
        setLngLat(Object.values(e.lngLat));
      }
    };
    map.on("mousemove", mouseMove);
    return () => {
      isMounted.current = false;
      map.off("mousemove", mouseMove);
    };
  }, [map, setPos, setLngLat]);

  if (!lngLat) return null;

  return (
    <MouseWrapper pos={pos}>
      {comparisonCell !== undefined ? (
        <>
          Depth: {compareValues(depth, comparisonCell.depth)} m<br />
          Distance: {compareValues(shoreDist, comparisonCell.shoreDist)} km
          <br />
          Speed: {compareValues(meanSpeed, comparisonCell.meanSpeed)} ms
          <br />
          AEP: {compareValues(capacity, comparisonCell.aep)} GWh
          <br />
          LCOE: {compareValues(lcoe, comparisonCell.lcoe)} m€/MWh
        </>
      ) : (
        <>
          Depth: {depth.toFixed(2)} m<br />
          Distance: {shoreDist.toFixed(2)} km
          <br />
          Speed: {meanSpeed.toFixed(2)} ms
          <br />
          AEP: {capacity.toFixed(2)} GWh
          <br />
          LCOE: {lcoe?.toFixed(1) || "-"} m€/MWh
        </>
      )}
    </MouseWrapper>
  );
};

const OFFSET_Y_PX = 50;

interface WrapperProps {
  pos: number[];
}

const MouseWrapper = styled(StandardBox).attrs<WrapperProps>(({ pos }) => ({
  style: {
    left: `${pos[0]}px`,
    top: `${pos[1] - OFFSET_Y_PX}px`,
  },
}))<WrapperProps>`
  position: fixed;
  z-index: 1;
  transform: translateX(-50%);
  padding: 0.5rem;
`;

const compareValues = (newValue?: number, oldValue?: number) => {
  if (newValue === undefined) return "-";
  if (oldValue === undefined) {
    return newValue.toFixed(2);
  } else if (newValue > oldValue) {
    return `${newValue.toFixed(2)} (+${(newValue - oldValue).toFixed(2)})`;
  } else if (newValue < oldValue) {
    return `${newValue.toFixed(2)} (-${(oldValue - newValue).toFixed(2)})`;
  } else {
    return newValue.toFixed(2);
  }
};

export default ScoresOnMouse;
