/// <reference types="vite-plugin-svgr/client" />
import React, { ReactNode, useEffect, useRef } from "react";
import styled from "styled-components";
import Checkmark from "@icons/checkmark/checkmark.svg?react";
import Line from "@icons/checkmark/line.svg?react";
import { colors } from "../../styles/colors";
import { spacing6 } from "../../styles/space";
import { Comp } from "../../types/utils";
import { typography } from "styles/typography";
import Tooltip from "./Tooltip";

const CheckBoxLabel = styled.label<{
  disabled?: boolean;
  checked?: boolean | "indeterminate";
}>`
  display: flex;
  gap: ${spacing6};
  box-sizing: border-box;
  cursor: pointer;
  align-items: center;
  width: 100%;
  height: 5rem;
  justify-content: space-between;
  border-radius: 0.4rem;
  box-shadow: 0px 1px 4px 0px #00000029;

  padding: 0 1.6rem;
  ${(p) => p.checked && `background: ${colors.surfaceInfo};`}
  ${(p) => p.disabled && `background: ${colors.surfaceDisabled};`}
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
`;

const Title = styled.span`
  ${typography.body}
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${colors.textPrimary};
`;

const Subtitle = styled.span`
  ${typography.body}
  color: ${colors.textDisabled};
`;

const HiddenCheckbox = styled.input`
  visibility: hidden;
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  margin: 0;
`;

type StyledCheckboxProps = {
  checked: CheckboxProps["checked"];
  disabled: boolean;
};

const CheckboxContainer = styled.div`
  display: flex;
  height: 5rem;
  padding: 0.4rem;
`;

const StyledCheckbox = styled.div<StyledCheckboxProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 1.6rem;
  height: 1.6rem;

  background: white;
  ${HiddenCheckbox}:checked + &,
  ${HiddenCheckbox}:indeterminate + & {
    background: ${colors.surfaceButtonPrimary};
  }

  border: 1px solid ${colors.surfaceButtonPrimary};
  border-radius: 2px;

  transition: all 200ms ease;

  path {
    stroke: white;
  }

  ${HiddenCheckbox}:hover + & {
    border: 1px solid ${colors.blue600};
  }
  ${HiddenCheckbox}:checked:hover + &,
  ${HiddenCheckbox}:indeterminate:hover + & {
    background: ${colors.blue600};
  }

  ${HiddenCheckbox}:active + & {
    border: 1px solid ${colors.blue700};
  }
  ${HiddenCheckbox}:checked:active + &,
  ${HiddenCheckbox}:indeterminate:active + & {
    background: ${colors.blue700};
  }

  ${HiddenCheckbox}:disabled + & {
    cursor: not-allowed;
    border: 1px solid ${colors.textDisabled};
    path {
      stroke: ${colors.surfaceDisabled};
    }
  }

  ${HiddenCheckbox}:checked:disabled + & {
    background: ${colors.textDisabled};
  }
`;

type CheckboxProps = Comp<
  "input",
  {
    label?: ReactNode;
    subLabel?: ReactNode;
    checked?: boolean | "indeterminate";
  }
>;

export default function UploadCheckbox({
  label,
  subLabel,
  checked,
  disabled,
  ...p
}: CheckboxProps) {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (ref.current) ref.current.indeterminate = checked === "indeterminate";
  }, [checked]);
  return (
    <CheckboxContainer>
      <Tooltip
        innerDivStyle={{ width: "100%" }}
        outerDivStyle={{ width: "100%" }}
        disabled={!disabled}
        text="You can only select up to 5 timeseries with the same height"
      >
        <CheckBoxLabel checked={checked} disabled={disabled}>
          <Column>
            <Title>{label}</Title>
            <Subtitle>{subLabel}</Subtitle>
          </Column>

          <HiddenCheckbox
            ref={ref}
            type="checkbox"
            checked={checked === true}
            disabled={disabled}
            {...p}
          />
          <StyledCheckbox
            checked={checked ?? false}
            disabled={disabled ?? false}
          >
            {checked === true ? (
              <Checkmark />
            ) : (
              checked === "indeterminate" && <Line />
            )}
          </StyledCheckbox>
        </CheckBoxLabel>
      </Tooltip>
    </CheckboxContainer>
  );
}
