import { Loadable, selectorFamily, waitForAll, waitForNone } from "recoil";
import * as turf from "@turf/turf";
import { VectorTile } from "@mapbox/vector-tile";
import { Feature, Geometry } from "geojson";
import Protobuf from "pbf";
import {
  getAllLayersSelector,
  dynamicLayersSelectorFunction,
} from "state/layer";
import {
  fetchTileJSONServerSelectorFamily,
  isTileJSONLayer,
  tileJSONServerFullMetadataSelector,
} from "state/tileJSON";
import { getXTileNumber, getYTileNumber } from "hooks/mouseSampler";
import { fetchEnhancer, fetchWithToken } from "services/utils";
import { isDefined } from "utils/predicates";
import { appendQueryParamsSign } from "utils/utils";
import {
  ExternalDataSourceLinkLayerWithSourceTileJSON,
  ExternalDataSourceLinkLayerWithSourceWMS,
  Layer,
} from "types/layers";
import { customLayersMetadataSelectorAsync } from "state/customLayers";
import {
  CORS_AND_CACHE_PROXY_WITH_TOKEN_URL_PREFIX,
  addCorsAndCacheProxyURL,
} from "state/gisSourceCorsProxy";
import { scream } from "utils/sentry";
import { CreateSerializableParam } from "types/utils";
import { BBOX } from "utils/geojson/validate";
import Semaphore from "utils/semaphore";
import { filteredLayerListSelector } from "../layer-filter-state";
import { _GeometryType } from "utils/geojson/geojson";

const TILE_JSON_ZOOM = 8;
const WMS_IMAGE_SIZE = 256;
const requestConcurrencySemaphore = new Semaphore(30);
const serviceWorkerConcurrencySemaphore = new Semaphore(40);

const getIdToLayerMapSelectorFamily = selectorFamily<
  Record<string, Layer>,
  {
    projectId: string;
  }
>({
  key: "getIdToLayerMapSelectorFamily",
  get:
    ({ projectId }) =>
    ({ get }) =>
      [
        ...get(getAllLayersSelector({ projectId })),
        ...get(
          customLayersMetadataSelectorAsync({
            nodeId: projectId,
          }),
        ),
      ].reduce<Record<string, Layer>>((acc, layer) => {
        acc[layer.id] = layer;
        return acc;
      }, {}),
});

export const bboxOverlaps = (bboxA: number[], bboxB: number[]) =>
  bboxA[0] >= bboxB[0] &&
  bboxA[1] >= bboxB[1] &&
  bboxA[2] <= bboxB[2] &&
  bboxA[3] <= bboxB[3];

type TileJSONLayerWithMetadata =
  ExternalDataSourceLinkLayerWithSourceTileJSON & {
    queryTiles: string[];
    vectorLayers: Record<string, any>;
  };

export const getTileJSONMetadataLayersWithinBBOX = selectorFamily<
  TileJSONLayerWithMetadata[],
  { projectId: string; bbox: number[] }
>({
  key: "getTileJSONMetadataLayersWithinBBOX",
  get:
    ({ bbox, projectId }) =>
    async ({ get }) => {
      const tileJSON = get(filteredLayerListSelector({ projectId })).filter(
        isTileJSONLayer,
      );

      const tileJSONMetatdata = get(
        waitForAll(
          tileJSON.map((json) =>
            tileJSONServerFullMetadataSelector(json.sourceLink.url),
          ),
        ),
      );
      const vectorLayers = Object.fromEntries(
        tileJSONMetatdata
          .filter(isDefined)
          .flatMap((l) => l.vector_layers)
          .map((l) => [l.id, l]),
      );
      const tileJSONTiles: string[] = tileJSONMetatdata
        .filter(isDefined)
        .flatMap((l) => l.tiles ?? [])
        .filter((t) => t != null);

      const xTiles = [bbox[0], bbox[2]].map((lng) =>
        getXTileNumber(lng, TILE_JSON_ZOOM),
      );
      const yTiles = [bbox[3], bbox[1]].map((lat) =>
        getYTileNumber(lat, TILE_JSON_ZOOM),
      );

      const result: any[] = [];
      for (let i = 0; i < tileJSONTiles.length; i++) {
        let queryTiles = [] as string[];
        for (let x = xTiles[0]; x <= xTiles[1]; x++) {
          for (let y = yTiles[0]; y <= yTiles[1]; y++) {
            queryTiles.push(
              tileJSONTiles[i]
                .replace("{z}", `${TILE_JSON_ZOOM}`)
                .replace("{x}", `${x}`)
                .replace("{y}", `${y}`),
            );
          }
        }
        result.push({ ...tileJSON[i], queryTiles, vectorLayers });
      }

      return result;
    },
});

const checkWMSOverlapSelectorFamily = selectorFamily<
  { layer: Layer; result: boolean | Error },
  { layerId: string; bbox: number[]; projectId: string }
>({
  key: "checkWMSOverlapSelectorFamily",
  get:
    ({ layerId, bbox, projectId }) =>
    async ({ get }) => {
      const layer = get(getIdToLayerMapSelectorFamily({ projectId }))[
        layerId
      ] as ExternalDataSourceLinkLayerWithSourceWMS;
      try {
        const isPrivate = (
          "private" in layer.source ? layer.source["private"] : false
        ) as boolean;
        const queryUrlWMS = encodeURI(
          `${addCorsAndCacheProxyURL(
            layer.sourceLink.url,
            isPrivate,
          )}${appendQueryParamsSign(
            layer.sourceLink.url,
          )}request=GetMap&service=WMS&version=1.1.1&styles=&layers=${
            layer.sourceLayerId
          }&srs=EPSG:4326&bbox=${bbox.join(
            ",",
          )}&width=${WMS_IMAGE_SIZE}&height=${WMS_IMAGE_SIZE}&format=image/png`,
        );

        await requestConcurrencySemaphore.acquire();
        let response;
        try {
          response = queryUrlWMS.includes(
            CORS_AND_CACHE_PROXY_WITH_TOKEN_URL_PREFIX,
          )
            ? await fetchWithToken(queryUrlWMS, {
                method: "get",
              })
            : await fetchEnhancer(queryUrlWMS, {
                method: "get",
              });

          if (!response.ok) {
            throw new Error("Response not ok");
          }
        } finally {
          requestConcurrencySemaphore.release();
        }
        const blob = await response.blob();
        const canvas = document.createElement("canvas");
        canvas.width = WMS_IMAGE_SIZE;
        canvas.height = WMS_IMAGE_SIZE;
        const ctx = canvas.getContext("2d");

        if (!ctx) {
          throw new Error("Error when getting 2d context from canvas");
        }

        const img = new Image();
        const imgData: Uint8ClampedArray = await new Promise((res, rej) => {
          img.onload = function () {
            ctx.drawImage(img, 0, 0);
            res(ctx.getImageData(0, 0, img.width, img.height).data);
          };
          img.onerror = () => {
            rej(new Error("Error when loading WMS image"));
          };
          img.src = URL.createObjectURL(blob);
        });
        const overlapWMSWorker = new Worker(
          new URL("./overlapWMSWorkerSingle.js", import.meta.url),
          { type: "module" },
        );
        const result = await new Promise<boolean>((res, rej) => {
          overlapWMSWorker.postMessage(imgData);
          overlapWMSWorker.onmessage = function (e) {
            serviceWorkerConcurrencySemaphore.release();
            res(e.data);
          };
          overlapWMSWorker.onerror = function (e) {
            serviceWorkerConcurrencySemaphore.release();
            scream("overlapWMSWorker.onerror", { e });
            rej(e);
          };
        });

        img.remove();
        canvas.remove();
        overlapWMSWorker.terminate();

        return { layer, result };
      } catch (e) {
        return { layer, result: e instanceof Error ? e : new Error(String(e)) };
      }
    },
});

const checkFeatureOverlapSelectorFamily = selectorFamily<
  { layer: Layer; result: boolean | Error },
  {
    layerId: string;
    geometry: CreateSerializableParam<Geometry>;
    projectId: string;
    bbox: number[];
  }
>({
  key: "checkFeatureOverlapSelectorFamily",
  get:
    ({ layerId, geometry, projectId, bbox }) =>
    async ({ get }) => {
      const layer = get(getIdToLayerMapSelectorFamily({ projectId }))[layerId];

      try {
        await requestConcurrencySemaphore.acquire();
        let layerFeature: Awaited<
          ReturnType<typeof dynamicLayersSelectorFunction>
        >;
        try {
          layerFeature = await dynamicLayersSelectorFunction({
            layer,
            bbox,
          });
        } finally {
          requestConcurrencySemaphore.release();
        }

        const overlapWorker = new Worker(
          new URL("./overlapWorkerSingle.js", import.meta.url),
          { type: "module" },
        );
        await serviceWorkerConcurrencySemaphore.acquire();

        if (!_GeometryType.safeParse(geometry?.type)) {
          return {
            layer,
            result: scream("overlap: geometry has illegal type", {
              type: geometry?.type,
            }),
          };
        }

        for (const f of layerFeature.features) {
          if (!_GeometryType.safeParse(f?.geometry?.type)) {
            return {
              layer,
              result: scream("overlap: layerFeature contains illegal feature", {
                type: f?.geometry?.type,
                feature: f,
              }),
            };
          }
        }

        const result = await new Promise<boolean>((res, rej) => {
          overlapWorker.postMessage([geometry, layerFeature]);
          overlapWorker.onmessage = function (e) {
            serviceWorkerConcurrencySemaphore.release();
            res(e.data);
          };
          overlapWorker.onerror = function (e) {
            serviceWorkerConcurrencySemaphore.release();
            scream("overlapWMSWorker.onerror", { e });
            rej(e);
          };
        });
        overlapWorker.terminate();
        return { layer, result };
      } catch (e) {
        return { layer, result: e instanceof Error ? e : new Error(String(e)) };
      }
    },
});

const getTileJSONLayerOverlapSelectorFamily = selectorFamily<
  {
    layer: TileJSONLayerWithMetadata;
    result: boolean;
  },
  {
    tileJSONLayer: TileJSONLayerWithMetadata;
    geometry: CreateSerializableParam<Geometry>;
  }
>({
  key: "getTileJSONLayerOverlapSelectorFamily",
  get:
    ({ tileJSONLayer, geometry }) =>
    async ({ get }) => {
      const blobs: Blob[] = get(
        waitForAll(
          tileJSONLayer.queryTiles.map((url) =>
            fetchTileJSONServerSelectorFamily(url),
          ),
        ),
      ).filter(isDefined);
      const tiles: VectorTile[] = await Promise.all(
        blobs.map(
          async (b) => new VectorTile(new Protobuf(await b.arrayBuffer())),
        ),
      );
      const geojson: Record<string, Feature[]>[] = [];
      for (let i = 0; i < tileJSONLayer.queryTiles.length; i++) {
        const queryTile = tileJSONLayer.queryTiles[i];
        const tile = tiles[i];
        if (!tile) {
          scream("Tile is undefined when trying to run overlap", {
            tiles,
            tileJSONLayer,
            geometry,
          });
          continue;
        }
        const layers = Object.keys(tile.layers);
        const splitUrl = queryTile.split("/");
        const y = parseInt(splitUrl.slice(-1)[0].split(".")[0]);
        const x = parseInt(splitUrl.slice(-2)[0]);
        const z = parseInt(splitUrl.slice(-3)[0]);
        const geojsonFromTile = Object.fromEntries(
          layers.map((l) => {
            const layer = tile.layers[l];
            const lengthArray = Array.from(Array(layer.length).keys());
            const val = lengthArray.map((i) =>
              tile.layers[l].feature(i).toGeoJSON(x, y, z),
            );
            return [l, val];
          }),
        );
        geojson.push(geojsonFromTile);
      }

      const layerNames = Array.from(
        new Set(geojson.map((l) => Object.keys(l)).flat()),
      );
      const allGeojsons = Object.fromEntries(
        layerNames.map((layerName) => {
          const val = geojson
            .map((layerToFeatures) => layerToFeatures[layerName] ?? [])
            .flat();
          return [layerName, val];
        }),
      );

      const overlap = Object.keys(allGeojsons).filter(
        (l) =>
          allGeojsons[l].some((f) =>
            (turf as any).booleanIntersects(geometry, f),
          ), //booleanIntersects is not exported in typescript, but it should be
      );

      return {
        result: overlap.includes(tileJSONLayer.sourceLayerId),
        layer: tileJSONLayer,
      };
    },
});

export const getTileJSONLayersOverlapSelectorFamily = selectorFamily<
  Loadable<{
    layer: TileJSONLayerWithMetadata;
    result: boolean;
  }>[],
  { tileJSONLayers: TileJSONLayerWithMetadata[]; geometry: any }
>({
  key: "getTileJSONLayersOverlapSelectorFamily",
  get:
    ({ tileJSONLayers, geometry }) =>
    async ({ get }) =>
      get(
        waitForNone(
          tileJSONLayers.map((tileJSONLayer) =>
            getTileJSONLayerOverlapSelectorFamily({ tileJSONLayer, geometry }),
          ),
        ),
      ),
});

export const getFeatureLayersDataSelectorFamily = selectorFamily<
  Loadable<{ layer: Layer; result: boolean | Error }>[],
  {
    layerIds: string[];
    geometry: CreateSerializableParam<Geometry>;
    projectId: string;
    bbox: number[];
  }
>({
  key: "getFeatureLayersDataSelectorFamily",
  get:
    ({ layerIds, geometry, projectId, bbox }) =>
    async ({ get }) =>
      get(
        waitForNone(
          layerIds.map((layerId) =>
            checkFeatureOverlapSelectorFamily({
              layerId,
              geometry,
              projectId,
              bbox,
            }),
          ),
        ),
      ),
});

export const getWMSLayersDataSelectorFamily = selectorFamily<
  Loadable<{ layer: Layer; result: boolean | Error }>[],
  { layerIds: string[]; bbox: BBOX; projectId: string }
>({
  key: "getWMSLayersDataSelectorFamily",
  get:
    ({ bbox, layerIds, projectId }) =>
    async ({ get }) =>
      get(
        waitForNone(
          layerIds.map((layerId) =>
            checkWMSOverlapSelectorFamily({
              layerId,
              bbox,
              projectId,
            }),
          ),
        ),
      ),
});
