import { InputDimensioned, Input, TextArea } from "../General/Input";

import {
  MooringLineType,
  MooringMaterialType,
  MOORING_LINE_DIAMETER_MAX,
  MOORING_LINE_DIAMETER_MIN,
} from "../../services/mooringLineTypeService";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import Button from "../General/Button";
import { DefaultMooringProps } from "../../constants/mooring";
import { WATER_DENSITY } from "../../constants/metocean";
import Dropdown from "../Dropdown/Dropdown";
import { isNumber } from "../../utils/predicates";
import { valueRounding } from "../RightSide/InfoModal/FoundationModal/utils";
import { Column } from "../General/Layout";
import { ColumnContent } from "./TurbineSettings";
import { ContentWrapper, SettingsHeader } from "../SettingsV2/Shared/styles";
import { Label } from "../General/Form";
import { useSetRecoilState } from "recoil";
import { unsavedSettingsState } from "../SettingsV2/Shared/state";
import { toastMessagesAtom } from "../../state/toast";
import useEnterToSave from "./useEnterToSave";

const newtonToMeganewton = (newton: number) => newton / 1e6;

const meganewtonToNewton = (meganewton: number) => meganewton * 1e6;

export const diameterToEA = (
  diameter: number,
  material: MooringMaterialType,
): number => {
  const mooringProps = DefaultMooringProps[material];
  const d_nom = diameter / 1000;

  return mooringProps.EA_d2 * Math.pow(d_nom, 2);
};

export const diameterToWetWeight = (
  diameter: number,
  material: MooringMaterialType,
): number => {
  const mooringProps = DefaultMooringProps[material];
  const d_nom = diameter / 1000;
  const d_vol = mooringProps.dvol_dnom * d_nom;
  const massDens = mooringProps.mass_d2 * Math.pow(d_nom, 2);

  return (massDens - (Math.PI / 4) * d_vol ** 2 * WATER_DENSITY) * 9.81;
};

export const MATERIAL_DEFAULT: MooringMaterialType = "chain_r3";
export const DIAMETER_DEFAULT = 100;
export const EA_DEFAULT = diameterToEA(DIAMETER_DEFAULT, MATERIAL_DEFAULT);
export const WETWEIGHT_DEFAULT = diameterToWetWeight(
  DIAMETER_DEFAULT,
  MATERIAL_DEFAULT,
);

export function SingleLineType({
  line,
  onSave,
  isLoading,
  disabled,
}: {
  line: MooringLineType;
  onSave: (line: MooringLineType) => void;
  isLoading: boolean;
  disabled: boolean;
}) {
  const [_line, setLine] = useState(line);
  const setUnsavedSettings = useSetRecoilState(unsavedSettingsState);
  const setToastMessages = useSetRecoilState(toastMessagesAtom);

  useEffect(() => {
    setLine(line);
  }, [line]);

  const hasChanged = useMemo(() => {
    return line !== _line;
  }, [_line, line]);

  const onEnterSaveCallback = useCallback(() => onSave(_line), [onSave, _line]);
  useEnterToSave(onEnterSaveCallback, hasChanged && !isLoading);

  useEffect(() => {
    setUnsavedSettings(hasChanged);
  }, [hasChanged, setUnsavedSettings]);

  return (
    <>
      {hasChanged && (
        <SettingsHeader>
          <Button
            disabled={isLoading || !hasChanged}
            text="Cancel"
            buttonType="secondary"
            onClick={() => {
              setLine(_line);
            }}
            style={{ marginLeft: "auto" }}
          />
          <Button
            disabled={isLoading || !hasChanged}
            text="Save changes"
            onClick={() => {
              setToastMessages((tm) => [
                ...tm,
                { text: "Saving...", timeout: 1000 },
              ]);
              onSave(_line);
            }}
          />
        </SettingsHeader>
      )}
      <ContentWrapper
        style={{
          maxHeight: "calc(100% - 7.3rem)",
          overflowY: "auto",
          boxSizing: "border-box",
        }}
      >
        <ColumnContent>
          <h4 style={{ margin: 0 }}>Line settings</h4>
          <Column>
            <Label>
              <p>Name</p>
              <Input
                disabled={disabled}
                width={16}
                value={_line.name}
                onChange={(e) => {
                  setLine({
                    ..._line,
                    name: e.target.value,
                  });
                }}
              />
            </Label>
            <Label>
              <p>Material</p>
              <Dropdown
                disabled={disabled}
                value={_line.material as string}
                onChange={(e) => {
                  const material = e.target.value as MooringMaterialType;
                  setLine({
                    ..._line,
                    material: material,
                  });
                }}
              >
                <option value={"chain_r3"}>{"Chain R3"}</option>
                <option value={"chain_r4"}>{"Chain R4"}</option>
                <option value={"polyester"}>{"Polyester"}</option>
                <option value={"nylon"}>{"Nylon"}</option>
              </Dropdown>
            </Label>

            <Label>
              <p>Diameter</p>
              <InputDimensioned
                disabled={disabled}
                unit="mm"
                value={_line.diameter}
                validate={(e) =>
                  MOORING_LINE_DIAMETER_MIN <= e &&
                  e <= MOORING_LINE_DIAMETER_MAX
                }
                validationMessage={`Must be between ${MOORING_LINE_DIAMETER_MIN} and ${MOORING_LINE_DIAMETER_MAX} mm`}
                onChange={(e) => {
                  if (!isNumber(e)) return;

                  setLine({
                    ..._line,
                    diameter: e,
                  });
                }}
              />
            </Label>
            <Label>
              <p>EA</p>
              <InputDimensioned
                disabled={disabled}
                unit="MN"
                value={valueRounding(newtonToMeganewton(_line.EA), 0.01)}
                validate={(e) => e >= 0.0}
                validationMessage={`Must be greater than 0`}
                onChange={(e) => {
                  if (!isNumber(e)) return;
                  setLine({
                    ..._line,
                    EA: meganewtonToNewton(e),
                  });
                }}
              />
            </Label>
            <Label>
              <p>Wet weight</p>
              <InputDimensioned
                disabled={disabled}
                unit="N/m"
                value={valueRounding(_line.wetWeight, 0.1)}
                validate={(e) => e >= 0.0}
                validationMessage={`Must be greater than 0`}
                onChange={(e) => {
                  if (!isNumber(e)) return;
                  setLine({
                    ..._line,
                    wetWeight: e,
                  });
                }}
              />
            </Label>
            <Label>
              <p>Cost</p>
              <InputDimensioned
                disabled={disabled}
                unit={_line.costUnit}
                value={_line.cost}
                step={0.1}
                validate={(e) => e >= 0.0}
                validationMessage={`Must be greater than 0`}
                onChange={(e) => {
                  if (!isNumber(e)) return;
                  setLine({
                    ..._line,
                    cost: e,
                  });
                }}
              />
            </Label>
            <Label>
              <p>Description</p>
              <TextArea
                rows={6}
                style={{ resize: "vertical" }}
                disabled={disabled}
                placeholder={"Add a description"}
                value={_line.note ?? ""}
                onChange={(e) => {
                  setLine({ ..._line, note: e.target.value });
                }}
              />
            </Label>
          </Column>
        </ColumnContent>
      </ContentWrapper>
    </>
  );
}
