import { ReactNode } from "react";
import { atom } from "recoil";

export type SearchObject = {
  title: string;
  onSelect: () => void;
  setFocus?: (focus: boolean) => void;
  id: string;
  tags?: string[];
  icon?: ReactNode;
  disabled?: boolean;
};

export const searchBarOpen = atom<boolean>({
  key: "searchBarOpen",
  default: false,
});

export const searchObjectsAtomFamily = atom<SearchObject[]>({
  key: "searchObjectsAtomFamily",
  default: [],
});

export const searchObjectInFocusAtom = atom<string | undefined>({
  key: "searchObjectInFocusAtom",
  default: undefined,
});
