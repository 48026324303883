import { useEffect } from "react";
import { selectorFamily, useRecoilValue } from "recoil";
import { getParkFeatureSelectorFamily } from "../../state/park";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { WarningProps } from "./ValidationWarnings";
import { getExportCablesInBranchSelectorFamily } from "../../state/cable";
import { currentExportCableTypesSelector } from "components/Cabling/Generate/state";
import { branchIdSelector } from "state/pathParams";

export const differentExportVoltageTypesSelectorFamily = selectorFamily<
  | {
      type: ValidationWarningTypes.CableDifferentExportVoltageTypes;
      featureIds: string[];
      parkId: string;
    }
  | undefined,
  { parkId: string; branchId: string }
>({
  key: "differentExportVoltageTypesSelectorFamily",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const park = get(getParkFeatureSelectorFamily({ parkId }));
      if (!park) return;

      const exportCables = get(
        getExportCablesInBranchSelectorFamily({ parkId: park.id, branchId }),
      );
      const exportCableTypes = get(currentExportCableTypesSelector);

      const cablesDifferentVoltage = exportCables.filter((c) => {
        const offshoreCableType = exportCableTypes.find(
          (ct) => ct.id === c.properties.cableTypeId,
        );
        const onshoreCableType = exportCableTypes.find(
          (ct) => ct.id === c.properties.onshoreCableTypeId,
        );

        return (
          offshoreCableType &&
          onshoreCableType &&
          offshoreCableType.voltage !== onshoreCableType.voltage
        );
      });

      return cablesDifferentVoltage.length === 0
        ? undefined
        : {
            type: ValidationWarningTypes.CableDifferentExportVoltageTypes,
            featureIds: cablesDifferentVoltage.map((f) => f.id),
            parkId: parkId,
          };
    },
});

const DifferentExportVoltageTypes = ({
  parkId,
  warn,
  remove,
}: { parkId: string } & WarningProps) => {
  const branchId = useRecoilValue(branchIdSelector);
  const cablesDifferentVoltage = useRecoilValue(
    differentExportVoltageTypesSelectorFamily({
      parkId,
      branchId: branchId ?? "",
    }),
  );
  useEffect(() => {
    if (!cablesDifferentVoltage) return;
    warn(cablesDifferentVoltage);
    return () => {
      remove(ValidationWarningTypes.CableDifferentExportVoltageTypes);
    };
  }, [remove, warn, cablesDifferentVoltage]);

  return null;
};

export default DifferentExportVoltageTypes;
