import { useRecoilCallback, useSetRecoilState } from "recoil";
import {
  ABLY_ADD_REPLIES_FILTER,
  ABLY_REMOVE_REPLIES_FILTER,
} from "../../../state/ably";
import { useTypedPath } from "../../../state/pathParams";
import {
  deleteReply,
  postConditionalThreadFollow,
  postReply,
} from "../service";
import {
  followThreadsAtomFamily,
  replySaveInProgressState,
  threadRepliesAtomFamily,
  threadsAtomFamily,
} from "../state";
import { Reply } from "../types";
import { initializeAndSet } from "./useReplyReactionCrud";
import { useAblyPublish } from "../../../hooks/useAblyPublish";

export const useReplyCrud = () => {
  const { projectId: projectNodeId, branchId } = useTypedPath(
    "projectId",
    "branchId",
  );
  const ablyPublish = useAblyPublish();
  const setReplySaveInProgress = useSetRecoilState(replySaveInProgressState);

  const post = useRecoilCallback(
    ({ set }) =>
      async (threadId: string, data: { message: string }) => {
        if (!projectNodeId) return;
        setReplySaveInProgress({ threadId, message: data.message });
        const res = await postReply(
          projectNodeId,
          branchId,
          threadId,
          data,
        ).finally(() => setReplySaveInProgress(undefined));

        set(threadsAtomFamily({ nodeId: projectNodeId, branchId }), (cur) => {
          return cur.map((c) =>
            c.threadId === threadId ? { ...c, replies: c.replies + 1 } : c,
          );
        });
        set(
          threadRepliesAtomFamily({
            nodeId: projectNodeId,
            branchId,
            threadId,
          }),
          (cur) => {
            return [...cur, res];
          },
        );
        ablyPublish(`${projectNodeId}:all`, ABLY_ADD_REPLIES_FILTER, res);
        try {
          const followResponse = await postConditionalThreadFollow(
            projectNodeId,
            branchId,
            threadId,
          );
          set(
            followThreadsAtomFamily({ nodeId: projectNodeId, branchId }),
            (cur) => {
              return [...cur, followResponse];
            },
          );
        } catch (e) {
          console.log(e);
        }
        return res;
      },
    [ablyPublish, branchId, projectNodeId, setReplySaveInProgress],
  );

  const remove = useRecoilCallback(
    ({ set }) =>
      async (threadId: string, replyId: string) => {
        if (!projectNodeId) return;
        set(threadsAtomFamily({ nodeId: projectNodeId, branchId }), (cur) => {
          return cur.map((c) =>
            c.threadId === threadId ? { ...c, replies: c.replies - 1 } : c,
          );
        });
        set(
          threadRepliesAtomFamily({
            nodeId: projectNodeId,
            branchId,
            threadId,
          }),
          (cur) => {
            return cur.filter((c) => c.replyId !== replyId);
          },
        );
        const res = await deleteReply(
          projectNodeId,
          branchId,
          threadId,
          replyId,
        );
        ablyPublish(`${projectNodeId}:all`, ABLY_REMOVE_REPLIES_FILTER, {
          threadId,
          replyId,
        });

        return res;
      },
    [ablyPublish, projectNodeId, branchId],
  );

  const localPost = useRecoilCallback(
    ({ set, snapshot }) =>
      async (reply: Reply) => {
        initializeAndSet(
          snapshot,
          set,
          threadsAtomFamily({
            nodeId: reply.nodeId,
            branchId: reply.branchId,
          }),
          (cur) => {
            return cur.map((c) =>
              c.threadId === reply.threadId
                ? { ...c, replies: c.replies + 1 }
                : c,
            );
          },
        );

        initializeAndSet(
          snapshot,
          set,
          threadRepliesAtomFamily({
            nodeId: reply.nodeId,
            branchId: reply.branchId,
            threadId: reply.threadId,
          }),
          (cur) => {
            return [...cur, reply];
          },
        );
      },
    [],
  );

  const localRemove = useRecoilCallback(
    ({ set, snapshot }) =>
      async (threadId: string, replyId: string) => {
        if (!projectNodeId) return;
        initializeAndSet(
          snapshot,
          set,
          threadsAtomFamily({ nodeId: projectNodeId, branchId }),
          (cur) => {
            return cur.map((c) =>
              c.threadId === threadId ? { ...c, replies: c.replies - 1 } : c,
            );
          },
        );

        initializeAndSet(
          snapshot,
          set,
          threadRepliesAtomFamily({
            nodeId: projectNodeId,
            branchId,
            threadId: threadId,
          }),
          (cur) => {
            return cur.filter((c) => c.replyId !== replyId);
          },
        );
      },
    [branchId, projectNodeId],
  );

  return { post, remove, localPost, localRemove };
};
