import styled from "styled-components";
import { StandardBox } from "styles/boxes/Boxes";
import { colors } from "styles/colors";
import { IconREMSize, typography } from "styles/typography";
import React from "react";
import { RoundIconWrapper } from "components/CompareParksModal/shared";

export const DataLibraryNewEntryWrapper = styled(StandardBox)`
  background-color: ${colors.surfaceSelectedLight};
  box-shadow: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  width: 100%;
  box-sizing: border-box;
`;

export const DataLibraryNewEntryTitle = styled.div`
  ${typography.sub2};
  color: ${colors.textPrimary};
`;

export const DataLibraryNewEntryText = styled.div`
  ${typography.caption};
`;

export const DataLibraryNewEntry = ({
  title,
  text,
  icon,
  actionButton,
}: {
  title: string;
  text: string;
  icon?: React.ReactNode;
  actionButton?: React.ReactNode;
}) => {
  return (
    <DataLibraryNewEntryWrapper>
      {icon && (
        <IconREMSize height={1.6} width={1.6} stroke={colors.white}>
          <RoundIconWrapper>{icon}</RoundIconWrapper>
        </IconREMSize>
      )}
      <DataLibraryNewEntryTitle>{title}</DataLibraryNewEntryTitle>
      <DataLibraryNewEntryText>{text}</DataLibraryNewEntryText>
      {actionButton}
    </DataLibraryNewEntryWrapper>
  );
};
