import { ABLY_SLOPE_INCLUDING_CUSTOM } from "../../state/ably";
import { useCallback, useMemo } from "react";
import { Types } from "ably";
import { useAblyGeneric } from "../../hooks/useAblyGeneric";
import { scream, sendWarning } from "../../utils/sentry";
import {
  TileResponseError,
  TileResponseFinished,
  _SlopeResponseError,
  _SlopeResponseFinished,
} from "../../types/bathymetryTypes";
import { useRecoilCallback } from "recoil";
import { z } from "zod";
import { tileResponseAtom } from "../../state/bathymetry";

const _Response = z.union([_SlopeResponseFinished, _SlopeResponseError]);

export function useAblySlopeBathymetryListener(nodeId: string) {
  const channelName = useMemo(() => `${nodeId}:all`, [nodeId]);

  const setResponse = useRecoilCallback(
    ({ set }) =>
      (response: TileResponseFinished | TileResponseError) => {
        set(tileResponseAtom(response.id), response);
      },
    [],
  );

  const onMessage = useCallback(
    (message: Types.Message) => {
      const data = message.data;
      const parse = _Response.safeParse(data);
      if (parse.success) {
        const { data } = parse;
        setResponse(data);
        if (data.status === "failed") {
          sendWarning("Bathymetry slope response failed", {
            message: data.message,
          });
        }
      } else {
        scream("Failed to parse tile response", {
          data: data,
          error: parse.error,
        });
      }
    },
    [setResponse],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_SLOPE_INCLUDING_CUSTOM,
        onMessageReceived: onMessage,
      },
    ],
    [onMessage],
  );

  useAblyGeneric(channelName, events);
}
