import React, { useCallback, useMemo, useRef } from "react";
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import * as turf from "@turf/turf";
import { v4 as uuid } from "uuid";
import AddIcon from "@icons/24/Add.svg";
import ChevronDownIcon from "@icons/14/ChevronDown.svg";
import InformationIcon from "@icons/24/Information.svg";
import SplitIcon from "@icons/24/Split2.svg";
import ParkIcon from "@icons/24/SideBarIcons/Park.svg";
import WindTurbineIcon from "@icons/24/WindTurbine.svg";
import {
  spacing1,
  spacing10,
  spacing2,
  spacing3,
  spacing4,
  spacing6,
} from "styles/space";
import { IconREMSize, typography } from "styles/typography";
import { Row } from "components/General/Layout";
import { getBranchSelectorFamily } from "state/timeline";
import { BranchMeta } from "types/api";
import { useRecoilValueDef } from "utils/recoil";
import { branchIdSelector, projectIdSelector } from "state/pathParams";
import Tooltip from "components/General/Tooltip";
import useBooleanState from "hooks/useBooleanState";
import { getParkFeaturesInBranchSelector } from "state/park";
import ParkWithChildrenImage from "components/CompareParksModal/ParkWithChildrenImage";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { ParkFeature } from "types/feature";
import { getTurbinesInBranchSelectorFamily } from "state/layout";
import DontRenderWhenCheckly from "components/DontRenderWhenCheckly/DontRenderWhenCheckly";
import Button from "components/General/Button";
import {
  SelectedParkCompare,
  selectedParksAtom,
} from "components/CompareParksModal/state";
import { colors } from "styles/colors";
import { BranchTableItemWrapper, BranchesTableWrapper } from "./style";
import Spinner from "@icons/spinner/Spinner";
import { RoundIconWrapper } from "../../shared";
import { HighlightStep } from "components/OnboardingTours/HighlightWrapper";
import { useTrackEvent } from "components/OnboardingTours/state";
import { TUTORIAL_PROJECT_SEARCH_PARAM } from "components/OnboardingTours/Tour";
import { useSearchParams } from "react-router-dom";
import { getHumanReadableArea } from "utils/geometry";

const TUTORIAL_PARK_ID = "b405da7b-cc4a-46e1-a59d-24516d4d0061";

const ExpandArrowWrapper = styled.div<{ open: boolean }>`
  margin-right: 1rem;
  cursor: pointer;
  margin-left: ${spacing1};
  transform: rotate(${({ open }) => (!open ? "-90deg" : "0deg")});
  transition: 0.1s;

  ${({ open }) =>
    !open &&
    `
    svg {
      path {
        fill: ${colors.grey500};
      }
    }`};
`;

const HoverToShowAddButton = styled.div`
  position: relative;
  cursor: pointer;

  button {
    display: none;
  }

  .curtain {
    display: none;
  }

  &:hover {
    button {
      display: flex;
    }

    .curtain {
      display: block;
    }
  }
`;

const BranchParkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing6};
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  width: 15rem;
`;

const ProductionNumberItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  box-sizing: border-box;
  background-color: ${colors.surfaceInfo};
  padding: ${spacing2} ${spacing4};
  border-radius: 4px;
  color: ${colors.textBrand};
`;

const BranchPark = ({
  park,
  branchId,
}: {
  park: ParkFeature;
  branchId: string;
}) => {
  const nodeId = useRecoilValueDef(projectIdSelector);
  const setSelectedParks = useSetRecoilState(
    selectedParksAtom({ projectId: nodeId }),
  );
  const branch = useRecoilValue(
    getBranchSelectorFamily({
      projectId: nodeId,
      branchId: branchId,
    }),
  );

  const trackEvent = useTrackEvent();

  const [imageHasLoaded, toggleImageHasLoaded] = useBooleanState(false);
  const numberOfTurbines = useRecoilValue(
    getTurbinesInBranchSelectorFamily({
      parkId: park.id,
      branchId,
    }),
  ).length;

  const areaM2 = useMemo(() => {
    return turf.area(park);
  }, [park]);

  const handleOnClick = useCallback(() => {
    trackEvent("parkSelectedInCompare");
    setSelectedParks((curr) => [
      ...curr,
      {
        comparisonId: uuid(),
        parkId: park.id,
        branchId,
        selectedAnalysisConfigurationId: branch?.analysisConfigurationId,
        selectedCostConfigurationId: branch?.costConfigurationId,
        selectedWindConfigurationId: branch?.windConfigurationId,
      },
    ]);
  }, [branch, branchId, park.id, setSelectedParks, trackEvent]);

  return (
    <BranchParkWrapper key={park.id}>
      <p style={typography.body}>{park.properties.name}</p>
      <DontRenderWhenCheckly>
        <HoverToShowAddButton onClick={handleOnClick}>
          <ParkWithChildrenImage
            park={park}
            branchId={branchId}
            mapboxImageSize="150x120"
            style={{
              visibility: imageHasLoaded ? "visible" : "hidden",
              height: "12rem",
              width: "15rem",
            }}
            wrapperStyle={{
              borderRadius: "4px",
            }}
            onImageLoad={toggleImageHasLoaded}
          />
          <div
            className="curtain"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "#ffffffaa",
              zIndex: 2,
            }}
          />
          <Button
            text="Add"
            icon={<AddIcon />}
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: "auto",
              zIndex: 100,
            }}
          />
        </HoverToShowAddButton>
      </DontRenderWhenCheckly>
      <div style={{ display: "flex", gap: spacing6 }}>
        <ProductionNumberItem>
          <Tooltip text="Area">
            <IconREMSize
              height={1.4}
              width={1.4}
              style={{ marginRight: "0.4rem" }}
            >
              <ParkIcon />
            </IconREMSize>
            {getHumanReadableArea(areaM2)}
          </Tooltip>
        </ProductionNumberItem>
        <ProductionNumberItem>
          <Tooltip text="Number of turbines">
            <IconREMSize
              height={1.4}
              width={1.4}
              style={{ marginRight: "0.4rem" }}
            >
              <WindTurbineIcon />
            </IconREMSize>
            {numberOfTurbines}
          </Tooltip>
        </ProductionNumberItem>
      </div>
    </BranchParkWrapper>
  );
};

const BranchParkItems = ({ branchId }: { branchId: string }) => {
  const parks = useRecoilValue(getParkFeaturesInBranchSelector({ branchId }));

  if (parks.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          gap: spacing4,
          borderRadius: "4px",
          padding: `${spacing4} 0 ${spacing4} ${spacing10}`,
          marginBottom: spacing4,
          alignItems: "center",
        }}
      >
        <RoundIconWrapper
          style={{
            backgroundColor: colors.blue100,
            padding: spacing3,
          }}
        >
          <IconREMSize height={1.4} width={1.4} stroke={colors.blue600}>
            <InformationIcon />
          </IconREMSize>
        </RoundIconWrapper>
        <p style={typography.caption}>There are no parks in this branch</p>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        gap: spacing6,
        flexWrap: "wrap",
        padding: `${spacing4} 0 ${spacing4} ${spacing10}`,
        marginBottom: spacing4,
      }}
    >
      {parks.map((park) =>
        park.id === TUTORIAL_PARK_ID ? (
          <HighlightStep
            key={park.id}
            tourId="general-intro-tour"
            stepId="selectParkInCompare"
          >
            <BranchPark key={park.id} park={park} branchId={branchId} />
          </HighlightStep>
        ) : (
          <BranchPark key={park.id} park={park} branchId={branchId} />
        ),
      )}
    </div>
  );
};

const BranchTableItem = ({
  branch,
  active,
}: {
  branch: BranchMeta;
  active: boolean;
}) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const nodeId = useRecoilValueDef(projectIdSelector);
  const setSelectedParks = useSetRecoilState(
    selectedParksAtom({ projectId: nodeId }),
  );
  const [isOpen, toggleIsOpen] = useBooleanState(false);
  const [isLoadingParks, toggleIsLoadingParks] = useBooleanState(false);

  const [searchParams] = useSearchParams();
  const isTutorialProject = searchParams.get(TUTORIAL_PROJECT_SEARCH_PARAM);

  const onAddAllClick = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        toggleIsLoadingParks();
        const parks = await snapshot.getPromise(
          getParkFeaturesInBranchSelector({ branchId: branch.id }),
        );
        toggleIsLoadingParks();

        const newItems = parks.map<SelectedParkCompare>((park) => ({
          comparisonId: uuid(),
          parkId: park.id,
          branchId: branch.id,
          selectedAnalysisConfigurationId: branch?.analysisConfigurationId,
          selectedCostConfigurationId: branch?.costConfigurationId,
          selectedWindConfigurationId: branch?.windConfigurationId,
        }));

        setSelectedParks((curr) => [...curr].concat(newItems));
      },
    [branch, setSelectedParks, toggleIsLoadingParks],
  );

  return (
    <>
      <BranchTableItemWrapper
        key={branch.id}
        ref={elementRef}
        onClick={toggleIsOpen}
        active={active}
      >
        <Row style={{ alignItems: "center", gap: spacing3 }}>
          <ExpandArrowWrapper open={isOpen}>
            <IconREMSize height={0.8} width={0.8}>
              <ChevronDownIcon />
            </IconREMSize>
          </ExpandArrowWrapper>
          <IconREMSize height={1.4} width={1.4}>
            <SplitIcon />
          </IconREMSize>
          <p style={typography.contentAndButtons}>{branch.title}</p>
        </Row>
        <Row>
          <Button
            className={isLoadingParks ? "is-loading-parks" : undefined}
            buttonType="primary"
            text="Add all parks"
            size="small"
            disabled={isLoadingParks}
            icon={isLoadingParks ? <Spinner size="1rem" /> : <AddIcon />}
            onClick={(e) => {
              e.stopPropagation();
              return onAddAllClick();
            }}
          />
        </Row>
      </BranchTableItemWrapper>
      <React.Suspense
        fallback={
          <div
            style={{
              padding: `${spacing4} ${spacing4} ${spacing4} ${spacing10}`,
            }}
          >
            <SkeletonBlock
              style={{
                height: "8rem",
                width: "100%",
                boxSizing: "border-box",
                flexShrink: 0,
              }}
            />
          </div>
        }
      >
        {isTutorialProject && branch.title === "Main" ? (
          <BranchParkItems branchId={branch.id} />
        ) : (
          isOpen && <BranchParkItems branchId={branch.id} />
        )}
      </React.Suspense>
    </>
  );
};

const BranchesTable = ({ branches }: { branches: BranchMeta[] }) => {
  const tableElementRef = useRef<HTMLDivElement>(null);
  const branchId = useRecoilValueDef(branchIdSelector);

  return (
    <BranchesTableWrapper ref={tableElementRef}>
      {branches.map((branch) => (
        <BranchTableItem
          key={branch.id}
          branch={branch}
          active={branch.id === branchId}
        />
      ))}
    </BranchesTableWrapper>
  );
};

export default BranchesTable;
