import DrawCableCorridorHelp, {
  drawCableCorridorHelpHintType,
} from "./CloseableHints/DrawCableCorridorHelp";
import DrawPolygonHelp, {
  drawPolygonHelpHintType,
} from "./CloseableHints/DrawPolygonHelp";
import DrawLineStringHelp, {
  drawLineStringHelpHintType,
} from "./CloseableHints/DrawLineStringHelp";
import DrawPointHelp, {
  drawPointHelpHintType,
} from "./CloseableHints/DrawPointHelp";
import EditGeneralHelp, {
  editGeneralHelpHintType,
} from "./CloseableHints/EditGeneralHelp";
import ShiftDragSelectHelp, {
  shiftDragSelectHelpHintType,
} from "./CloseableHints/ShiftDragSelectHelp";
import ChangeFeatureTypeHelp, {
  changeFeatureHelpHintType,
} from "./PositionHints/ChangeFeatureTypeHelp";
import { Place } from "../General/Anchor";
import AddExternalDataToProjectHelp, {
  addExternalDataToProjectHelpHintType,
} from "./PositionHints/AddExternalDataToProjectHelp";
import SplitMultiFeatureHelp, {
  splitMultiFeatureHelpHintType,
} from "./PositionHints/SplitMultiFeatureHelp";
import ResetOrientationHelp, {
  resetOrientationHelpHelpHintType,
} from "./PositionHints/ResetOrientationHelp";
import AvoidSnapping, {
  avoidSnappingHintType,
} from "./CloseableHints/AvoidSnapping";
import BenchmarkingArticles, {
  benchmarkingArticleHintType,
} from "./PositionHints/AnalysisBenchmarking";

export const AllHints = ({
  hintId,
  closeCallback,
  gotItCallback,
  position,
}: {
  hintId: string;
  closeCallback: () => void;
  gotItCallback?: () => void;
  position?: Place;
}) => {
  switch (hintId) {
    case drawPolygonHelpHintType:
      return (
        <DrawPolygonHelp
          closeCallback={closeCallback}
          gotItCallback={gotItCallback}
        />
      );
    case drawCableCorridorHelpHintType:
      return (
        <DrawCableCorridorHelp
          closeCallback={closeCallback}
          gotItCallback={gotItCallback}
        />
      );
    case drawLineStringHelpHintType:
      return (
        <DrawLineStringHelp
          closeCallback={closeCallback}
          gotItCallback={gotItCallback}
        />
      );
    case drawPointHelpHintType:
      return (
        <DrawPointHelp
          closeCallback={closeCallback}
          gotItCallback={gotItCallback}
        />
      );
    case editGeneralHelpHintType:
      return (
        <EditGeneralHelp
          closeCallback={closeCallback}
          gotItCallback={gotItCallback}
        />
      );
    case shiftDragSelectHelpHintType:
      return (
        <ShiftDragSelectHelp
          closeCallback={closeCallback}
          gotItCallback={gotItCallback}
        />
      );
    case changeFeatureHelpHintType:
      return (
        <ChangeFeatureTypeHelp
          closeCallback={closeCallback}
          gotItCallback={gotItCallback}
          position={position}
        />
      );
    case addExternalDataToProjectHelpHintType:
      return (
        <AddExternalDataToProjectHelp
          closeCallback={closeCallback}
          gotItCallback={gotItCallback}
          position={position}
        />
      );
    case splitMultiFeatureHelpHintType:
      return (
        <SplitMultiFeatureHelp
          closeCallback={closeCallback}
          gotItCallback={gotItCallback}
          position={position}
        />
      );
    case resetOrientationHelpHelpHintType:
      return (
        <ResetOrientationHelp
          closeCallback={closeCallback}
          gotItCallback={gotItCallback}
        />
      );
    case avoidSnappingHintType:
      return (
        <AvoidSnapping
          closeCallback={closeCallback}
          gotItCallback={gotItCallback}
        />
      );
    case benchmarkingArticleHintType:
      return (
        <BenchmarkingArticles
          closeCallback={closeCallback}
          gotItCallback={gotItCallback}
        />
      );
    default:
      return null;
  }
};
