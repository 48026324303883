/// <reference types="vite-plugin-svgr/client" />
import {
  Suspense,
  Fragment,
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useTypedPath } from "../../../state/pathParams";
import Bin from "@icons/24/Bin.svg?react";
import HourglassIcon from "@icons/14/Hourglass.svg?react";

import { SkeletonText } from "../../Loading/Skeleton";
import { spaceMedium } from "../../../styles/space";
import {
  ErrorBoundaryWrapper,
  ErrorBoundaryWarningTriangle,
  ScreamOnError,
} from "../../ErrorBoundaries/ErrorBoundaryLocal";
import { _MemberTabName, usersInOrganisationState } from "../state";
import OrgRoleDropdown from "./OrgRoleDropdown";
import {
  ArrowContainer,
  AscArrow,
  BinWrapper,
  DescArrow,
  MemberGrid,
  TableText,
  UserImage,
} from "./style";
import { SortState, Field, Order } from "./types";
import { TextIcon } from "styles/typography";
import {
  adminInOrganisationSelectorFamily,
  loggedInUserSelector,
  ownerInOrganisationSelectorFamily,
} from "state/user";
import { useOrgMemberCrud } from "./useOrgMemberCrud";
import { organisationRightSideModal } from "../OrganisationRightSide/state";
import { TableHeader } from "../OrganisationRightSide/style";
import { Anchor } from "components/General/Anchor";
import AccessOverviewModal, {
  QuestionIconComp,
} from "components/AccessOverview/AccessOverviewModal";
import Button from "components/General/Button";
import Tooltip from "components/General/Tooltip";
import { colors } from "styles/colors";
import { Column, Row } from "components/General/Layout";
import { allOrganisationInvitationsAtomFamily } from "state/customer";
import { dateToDateTime } from "utils/utils";
import { useDeleteInvitation } from "hooks/useUser";
import InviteToOrganisationButton from "../Invites/InviteToOrganisation/InviteToOrganisationButton";

const OrganisationMembers = ErrorBoundaryWrapper(
  ({ onlyPending }: { onlyPending?: boolean }) => {
    const { organisationId } = useTypedPath("organisationId");
    const orgRoleRef = useRef<HTMLDivElement>(null);
    const [sortState, setSortState] = useState<SortState>({
      field: "nickname",
      order: "ASC",
    });

    const [showAccessModal, setShowAccessModal] = useState(false);

    const setContent = useSetRecoilState(
      organisationRightSideModal(organisationId),
    );
    useEffect(() => {
      setContent((cur) => (cur?.type === "user" ? cur : undefined));
    }, [setContent]);

    const toggleSort = useCallback(
      (field: Field) => {
        let newOrder: Order = "ASC";
        if (sortState.field === field && sortState.order === "ASC") {
          newOrder = "DESC";
        } else if (sortState.field === field && sortState.order === "DESC") {
          newOrder = "NONE";
        }
        setSortState({ field, order: newOrder });
      },
      [sortState.field, sortState.order],
    );

    return (
      <Column
        style={{
          padding: "2.4rem 0 8rem",
          gap: "2.4rem",
        }}
      >
        <MemberGrid>
          <TableHeader
            onClick={() => toggleSort("nickname")}
            style={{ margin: "0.8rem 0 3rem 1.1rem", cursor: "pointer" }}
          >
            Name
            {sortState.field === "nickname" && sortState.order === "ASC" ? (
              <AscArrow />
            ) : sortState.field === "nickname" && sortState.order === "DESC" ? (
              <DescArrow />
            ) : (
              <ArrowContainer />
            )}
          </TableHeader>
          <TableHeader
            onClick={() => toggleSort("email")}
            style={{ margin: "0.8rem 0 3rem", cursor: "pointer" }}
          >
            Email
            {sortState.field === "email" && sortState.order === "ASC" ? (
              <AscArrow />
            ) : sortState.field === "email" && sortState.order === "DESC" ? (
              <DescArrow />
            ) : (
              <ArrowContainer />
            )}
          </TableHeader>
          <TableHeader
            ref={orgRoleRef}
            onClick={() => toggleSort("org_access")}
            style={{ margin: "0.8rem 0 3rem", cursor: "pointer" }}
          >
            Organisation role
            <QuestionIconComp
              onClick={(e) => {
                e.stopPropagation();
                setShowAccessModal((cur) => !cur);
              }}
            />
            {sortState.field === "org_access" && sortState.order === "ASC" ? (
              <AscArrow />
            ) : sortState.field === "org_access" &&
              sortState.order === "DESC" ? (
              <DescArrow />
            ) : (
              <ArrowContainer />
            )}
          </TableHeader>
          <TableHeader
            style={{ margin: "0.8rem 0 3rem", cursor: "pointer" }}
          ></TableHeader>
          {showAccessModal && (
            <Anchor
              baseRef={orgRoleRef}
              floatPlace="topLeft"
              basePlace="bottomLeft"
            >
              <AccessOverviewModal onClose={() => setShowAccessModal(false)} />
            </Anchor>
          )}

          <div style={{ marginLeft: "auto" }}>
            <InviteToOrganisationButton buttonType="primary" />
          </div>

          <Suspense
            fallback={
              <>
                <SkeletonText />
                <SkeletonText />
                <SkeletonText />
              </>
            }
          >
            <MemberRows sortState={sortState} onlyPending={onlyPending} />
          </Suspense>
        </MemberGrid>
      </Column>
    );
  },
  ErrorBoundaryWarningTriangle,
  ScreamOnError,
);

function MemberRows({
  sortState,
  onlyPending,
}: {
  sortState: SortState;
  onlyPending?: boolean;
}) {
  const { organisationId } = useTypedPath("organisationId");
  const allMembersRaw = useRecoilValue(
    usersInOrganisationState(organisationId),
  );
  const [content, setContent] = useRecoilState(
    organisationRightSideModal(organisationId),
  );

  const currentUser = useRecoilValue(loggedInUserSelector);
  const customerInvites = useRecoilValue(
    allOrganisationInvitationsAtomFamily(organisationId),
  );
  const deleteInvite = useDeleteInvitation(organisationId);
  const { remove, leaveOrganisation } = useOrgMemberCrud(organisationId);

  const isAdminInOrg = useRecoilValue(
    adminInOrganisationSelectorFamily({ organisationId }),
  );
  const isOwnerInOrg = useRecoilValue(
    ownerInOrganisationSelectorFamily({ organisationId }),
  );

  const sorted = useMemo(() => {
    let allMembers = [...allMembersRaw];
    const field = sortState.field;
    if (sortState.order === "NONE" || !field) {
      return [...allMembers];
    }
    return [...allMembers].sort((a, b) => {
      const fieldA = a[field]?.toLowerCase();
      const fieldB = b[field]?.toLowerCase();
      if (!fieldA) return -1;
      if (!fieldB) return 1;
      const comparison = fieldA.localeCompare(fieldB);
      return sortState.order === "ASC" ? comparison : -comparison;
    });
  }, [allMembersRaw, sortState.field, sortState.order]);

  return (
    <>
      {!onlyPending &&
        sorted.map((u) => {
          const isSelected = content?.id === u.user_id;
          return (
            <Fragment key={u.user_id}>
              <div
                onClick={() => {
                  setContent({ type: "user", id: u.user_id });
                }}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  gap: spaceMedium,
                  ...(isSelected ? { backgroundColor: colors.selected } : {}),
                }}
              >
                <UserImage src={u.picture} alt={"User image"} />
                <TableText
                  style={{
                    textTransform: "capitalize",
                  }}
                >
                  {u.nickname}
                </TableText>
              </div>
              <TableText
                style={{
                  ...(isSelected ? { backgroundColor: colors.selected } : {}),
                }}
              >
                {u.email}
              </TableText>
              <Row
                style={{
                  ...(isSelected ? { backgroundColor: colors.selected } : {}),
                }}
              >
                <OrgRoleDropdown user={u} />
              </Row>
              <TableText
                style={{
                  ...(isSelected ? { backgroundColor: colors.selected } : {}),
                }}
              />

              {isAdminInOrg && u.user_id !== currentUser?.user_id ? (
                <BinWrapper
                  style={{
                    ...(isSelected ? { backgroundColor: colors.selected } : {}),
                  }}
                  onClick={() => {
                    if (
                      !window.confirm(
                        `Are you sure you want to remove the user ${u.nickname} (${u.email}) from your organisation?`,
                      )
                    )
                      return;
                    remove(u.user_id);
                  }}
                >
                  <TextIcon
                    scale={1.2}
                    style={{
                      alignSelf: "center",
                      marginLeft: "auto",
                      padding: "0 1.6rem",
                      boxSizing: "border-box",
                    }}
                  >
                    <Bin />
                  </TextIcon>
                </BinWrapper>
              ) : u.user_id === currentUser?.user_id ? (
                <Row
                  style={{
                    width: "100%",
                    justifyContent: "flex-end",
                    padding: "0 1.6rem",
                    boxSizing: "border-box",
                    ...(isSelected ? { backgroundColor: colors.selected } : {}),
                  }}
                >
                  <Tooltip
                    disabled={!isOwnerInOrg}
                    text={"Owner can not leave the organisation"}
                  >
                    <Button
                      text="Leave organisation"
                      onClick={leaveOrganisation}
                      disabled={isOwnerInOrg}
                      style={{ marginLeft: "auto" }}
                    />
                  </Tooltip>
                </Row>
              ) : (
                <div
                  style={{
                    ...(isSelected ? { backgroundColor: colors.selected } : {}),
                  }}
                />
              )}
            </Fragment>
          );
        })}
      {customerInvites.map((invite) => (
        <Fragment key={invite.invitationId}>
          <div
            style={{
              minHeight: "4.4rem",
              display: "flex",
              alignItems: "center",
              gap: spaceMedium,
            }}
          >
            <HourglassIcon
              style={{
                margin: "1.4rem 0 1.4rem 1.6rem",
              }}
            />
            <TableText>Pending invitation</TableText>
          </div>
          <TableText>{invite.email}</TableText>
          <TableText
            style={{
              textTransform: "capitalize",
            }}
          >
            {invite.organisationRole}
          </TableText>
          <TableText>
            Expires {dateToDateTime(new Date(invite.expiration * 1000))}
          </TableText>
          <BinWrapper
            onClick={() => {
              if (
                !window.confirm(
                  `Are you sure you want to remove the invitation from your organisation?`,
                )
              )
                return;
              deleteInvite(invite.invitationId);
            }}
          >
            <TextIcon
              scale={1.2}
              style={{
                alignSelf: "center",
                marginLeft: "auto",
                padding: "0 1.6rem",
                boxSizing: "border-box",
              }}
            >
              <Bin />
            </TextIcon>
          </BinWrapper>
        </Fragment>
      ))}
    </>
  );
}

export default OrganisationMembers;
