import { ABLY_BATHYMETRY_INCLUDING_CUSTOM } from "../../state/ably";
import { useCallback, useMemo } from "react";
import { Types } from "ably";
import { useAblyGeneric } from "../../hooks/useAblyGeneric";
import { useRecoilCallback } from "recoil";
import {
  TileResponseError,
  TileResponseFinished,
  _TileResponseError,
  _TileResponseFinished,
} from "../../types/bathymetryTypes";
import { tileResponseAtom } from "../../state/bathymetry";
import { scream, sendWarning } from "../../utils/sentry";
import { z } from "zod";

const _Response = z.union([_TileResponseFinished, _TileResponseError]);

const noDataErrorCode = "nodata";

export const useAblyBathymetryListener = (projectNodeId?: string) => {
  const channelName = useMemo(
    () => projectNodeId && `${projectNodeId}:all`,
    [projectNodeId],
  );

  const setResponse = useRecoilCallback(
    ({ set }) =>
      (response: TileResponseFinished | TileResponseError) => {
        set(tileResponseAtom(response.id), response);
      },
    [],
  );

  const onUpdateBathymaretryIncludingCustom = useCallback(
    (message: Types.Message) => {
      const data = message.data;
      const parse = _Response.safeParse(data);
      if (parse.success) {
        const { data } = parse;
        setResponse(data);
        if (data.status === "failed" && data.message !== noDataErrorCode) {
          sendWarning("Bathymetry response failed", { message: data.message });
        }
      } else {
        scream("Failed to parse tile response", {
          data: data,
          error: parse.error,
        });
      }
    },
    [setResponse],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_BATHYMETRY_INCLUDING_CUSTOM,
        onMessageReceived: onUpdateBathymaretryIncludingCustom,
      },
    ],
    [onUpdateBathymaretryIncludingCustom],
  );

  useAblyGeneric(channelName, events);
};
