import * as turf from "@turf/turf";
import { makeExclusionZone } from "../../../state/division";
import { CableCorridorFeature, ParkFeature } from "../../../types/feature";
import { pencilBufferLineString } from "../../../utils/bufferSingleFeature";
import { isDefined, notUndefinedOrNull } from "../../../utils/predicates";
import { ImportFeaturesToWasm } from "./types";

const joinParkAndCableCorridors = (
  park: ParkFeature,
  cableCorridors: CableCorridorFeature[],
): ParkFeature => {
  // We union the cable corrdiors with the park, so that we can use the unioned geometry as the park geometry for cable routing.
  // However, we only want to union the cable corridors that are connected to the park, so we first find the union of all
  // corridors, and then we union in each corridor one by one, and if the union is disconnected we ignore that corridor.
  if (cableCorridors.length === 0) return park;

  const corridorUnion = cableCorridors
    .slice(1)
    .map((c) => c.geometry)
    .reduce(
      (a, e) => {
        const union = turf.union(a, e);
        if (union === null) return a;
        return union.geometry;
      },
      cableCorridors[0].geometry as turf.Polygon | turf.MultiPolygon,
    );

  if (corridorUnion.type === "Polygon") {
    const geometry = turf.union(park.geometry, corridorUnion)?.geometry;
    if (!geometry) return park;
    if (geometry.type === "Polygon") return { ...park, geometry };
    return park;
  }

  const parkCorridorUnion = corridorUnion.coordinates.reduce((a, e) => {
    const union = turf.union(a, { type: "Polygon", coordinates: e });
    if (union === null) return a;
    if (union.geometry.type === "Polygon") return union.geometry;
    return a;
  }, park.geometry as turf.Polygon);
  return { ...park, geometry: parkCorridorUnion };
};

export const computeImportFeaturesJsonString = ({
  exclusionZones,
  subAreas,
  park,
  cableCorridors,
  substations,
  turbines,
  settings,
  anchors,
  mooringLines,
  touchdownPoints,
}: ImportFeaturesToWasm): string => {
  let exZones = exclusionZones;
  if (settings.routeAroundMooring) {
    const anchorBuffers =
      0 < (settings.anchorBuffer ?? 0)
        ? anchors
            .map((anchor) =>
              turf.buffer(anchor, settings.anchorBuffer ?? 100, {
                units: "meters",
                steps: 3, // NOTE: this makes the circle buffers pretty rough, but it's already pretty slow with the number of vertices we get.
              }),
            )
            .filter(notUndefinedOrNull)
        : [];
    const anchorExclusionZones = anchorBuffers.map((buffer) =>
      makeExclusionZone(buffer.geometry),
    );

    const mooringBuffers =
      0 < (settings.mooringLineBuffer ?? 0)
        ? mooringLines
            .map((ml) =>
              pencilBufferLineString(
                ml,
                (settings.mooringLineBuffer ?? 100) / 1000.0,
                true,
              ),
            )
            .filter(isDefined)
        : [];
    const mooringExclusionZones = mooringBuffers.map((buffer) =>
      makeExclusionZone(buffer.geometry),
    );

    const touchdownBuffers =
      0 < (settings.touchdownBuffer ?? 0)
        ? touchdownPoints
            .map((point) =>
              turf.buffer(point, settings.touchdownBuffer, {
                units: "meters",
                steps: 3,
              }),
            )
            .filter(notUndefinedOrNull)
        : [];
    const touchdownExclusionZones = touchdownBuffers.map((buffer) =>
      makeExclusionZone(buffer.geometry),
    );
    exZones = [
      ...exZones,
      ...anchorExclusionZones,
      ...mooringExclusionZones,
      ...touchdownExclusionZones,
    ];
  }

  const newPark = joinParkAndCableCorridors(park, cableCorridors);
  const f: turf.Feature[] = [
    ...substations,
    ...turbines,
    newPark,
    ...exZones,
    ...subAreas,
  ];
  const fc = turf.featureCollection(f);
  const string = JSON.stringify(fc);
  return string;
};
