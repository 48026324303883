import mapboxgl, { CustomLayerInterface, Map } from "mapbox-gl";
import * as THREE from "three";
import { Camera, Scene, WebGLRenderer } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { LAYER_DEBUG_PRINT } from "../../state/debug";
import { dispose } from "../../utils/three";

export const MIN_ZOOM_TURBINES_VISIBLE = 13;

export const loadTurbineModel = async (options?: {
  bladeMaterial?: THREE.Material;
  towerMaterial?: THREE.Material;
  nacelleMaterial?: THREE.Material;
  turbineDiameter?: number;
}): Promise<(THREE.Mesh | THREE.Group)[]> => {
  const modelDiameter = 126;
  const scalingFactor =
    (options?.turbineDiameter ?? modelDiameter) / modelDiameter;
  const modelHeight = 90;
  const nacelleHeight = 5;
  const towerBaseHeight = 10;
  const towerHeight = modelHeight - nacelleHeight - towerBaseHeight;

  return new Promise((resolve) => {
    const meshes: (THREE.Mesh | THREE.Group)[] = [];
    const loader = new GLTFLoader();
    loader.load(
      "https://vind-public-files-eu-west-1.s3.eu-west-1.amazonaws.com/rotor_rotated_center.glb",
      (gltf) => {
        gltf.scene.traverse((object) => {
          if (object instanceof THREE.Mesh) {
            const material = object.material;
            if (options?.bladeMaterial) {
              object.material = options.bladeMaterial;
            } else {
              material.color.setHex(0xffffff);
              material.side = THREE.DoubleSide;
            }
            object.translateZ(towerHeight);
          }
        });
        const group = gltf.scene;
        group.scale.set(scalingFactor, scalingFactor, scalingFactor);

        meshes.push(group);
        resolve(meshes);
      },
    );

    const getDefaultMaterial = () =>
      new THREE.MeshLambertMaterial({ color: 0xffaaaa });

    const cylinderGeometry = new THREE.CylinderGeometry(
      2.2,
      3,
      towerHeight,
      32,
    );

    cylinderGeometry.rotateX((90 * Math.PI) / 180);
    cylinderGeometry.rotateY((0 * Math.PI) / 180);
    cylinderGeometry.translate(0, 0, 0.5 * towerHeight);
    const cylinderMesh = new THREE.Mesh(
      cylinderGeometry,
      options?.towerMaterial ?? getDefaultMaterial(),
    );
    cylinderMesh.scale.set(scalingFactor, scalingFactor, scalingFactor);
    meshes.push(cylinderMesh);

    const nacelleGeometry = new THREE.BoxGeometry(
      10,
      nacelleHeight,
      nacelleHeight,
    );
    nacelleGeometry.translate(1, 0, -1.5 + towerHeight);
    nacelleGeometry.rotateY((0 * Math.PI) / 180);
    const nacelleMesh = new THREE.Mesh(
      nacelleGeometry,
      options?.nacelleMaterial ?? getDefaultMaterial(),
    );
    nacelleMesh.scale.set(scalingFactor, scalingFactor, scalingFactor);
    meshes.push(nacelleMesh);
  });
};

export class ThreeDTurbines implements CustomLayerInterface {
  id: string;
  type: "custom";
  renderingMode: "2d" | "3d" | undefined;
  turbineLonlats: [number, number][];
  turbineHeights: number[];
  turbineDiameters: number[];
  direction: number = 0;
  modelTransforms:
    | {
        translateX: number;
        translateY: number;
        translateZ: number;
        rotateX: number;
        rotateY: number;
        rotateZ: number;
        scale: number;
      }[]
    | undefined;
  camera: Camera | undefined;
  scene: Scene | undefined;
  renderer: WebGLRenderer | undefined;
  map: Map | undefined;

  constructor(layerId: string) {
    this.id = layerId;
    this.type = "custom";
    this.renderingMode = "3d";
    this.turbineLonlats = [];
    this.turbineHeights = [];
    this.turbineDiameters = [];
    this.direction = 0;
  }

  setPositions(
    turbineLonlats: [number, number][],
    turbineHeights: number[],
    turbineDiameters: number[],
  ) {
    this.turbineLonlats = turbineLonlats;
    this.turbineHeights = turbineHeights;
    this.turbineDiameters = turbineDiameters;

    const modelAltitude = 0;
    const modelDiameter = 126;
    const modelOrigins = this.turbineLonlats;
    const modelAsMercatorCoordinates = modelOrigins.map((modelOrigin) =>
      mapboxgl.MercatorCoordinate.fromLngLat(modelOrigin, modelAltitude),
    );

    this.modelTransforms = modelAsMercatorCoordinates.map(
      (modelAsMercatorCoordinate, i) => {
        const conversion =
          modelAsMercatorCoordinate.meterInMercatorCoordinateUnits();
        return {
          translateX: modelAsMercatorCoordinate.x ?? 0,
          translateY: modelAsMercatorCoordinate.y ?? 0,
          translateZ:
            (modelAsMercatorCoordinate.z ?? 0) +
            this.turbineHeights[i] * conversion,
          rotateX: 0,
          rotateY: 0,
          rotateZ: 0,
          /* Since the 3D model is in real world meters, a scale transform needs to be
           * applied since the CustomLayerInterface expects units in MercatorCoordinates.
           */
          scale: (conversion * this.turbineDiameters[i]) / modelDiameter,
        };
      },
    );
  }

  onAdd(map: Map, gl: WebGLRenderingContext) {
    LAYER_DEBUG_PRINT && console.log("ThreeDTurbines.onAdd");
    // parameters to ensure the model is georeferenced correctly on the map
    if (this.turbineLonlats.length === 0) return;
    const modelOrigins = this.turbineLonlats;
    const modelAltitude = 0;
    const modelHeight = 90;
    const nacelleHeight = 5;
    const towerBaseHeight = 10;
    const towerHeight = modelHeight - nacelleHeight - towerBaseHeight;
    const modelDiameter = 126;
    map.setLayerZoomRange(this.id, MIN_ZOOM_TURBINES_VISIBLE, 24);

    const modelAsMercatorCoordinates = modelOrigins.map((modelOrigin) =>
      mapboxgl.MercatorCoordinate.fromLngLat(modelOrigin, modelAltitude),
    );

    // transformation parameters to position, rotate and scale the 3D model onto the map
    this.modelTransforms = modelAsMercatorCoordinates.map(
      (modelAsMercatorCoordinate, i) => {
        const conversion =
          modelAsMercatorCoordinate.meterInMercatorCoordinateUnits();
        return {
          translateX: modelAsMercatorCoordinate.x ?? 0,
          translateY: modelAsMercatorCoordinate.y ?? 0,
          translateZ:
            (modelAsMercatorCoordinate.z ?? 0) +
            this.turbineHeights[i] * conversion,
          rotateX: 0,
          rotateY: 0,
          rotateZ: 0,
          /* Since the 3D model is in real world meters, a scale transform needs to be
           * applied since the CustomLayerInterface expects units in MercatorCoordinates.
           */
          scale: (conversion * this.turbineDiameters[i]) / modelDiameter,
        };
      },
    );
    this.camera = new THREE.Camera();
    this.scene = new THREE.Scene();

    // create two three.js lights to illuminate the model
    const directionalLight = new THREE.DirectionalLight(0xffffff);
    directionalLight.position.set(0, -70, 100).normalize();
    this.scene.add(directionalLight);

    const directionalLight2 = new THREE.DirectionalLight(0xffffff);
    directionalLight2.position.set(0, 70, 100).normalize();
    this.scene.add(directionalLight2);

    // Create a new cylinder geometry with 32 segments

    // use the three.js GLTF loader to add the 3D model to the three.js scene
    const loader = new GLTFLoader();
    loader.load(
      "https://vind-public-files-eu-west-1.s3.eu-west-1.amazonaws.com/rotor_rotated_center.glb",
      (gltf) => {
        gltf.scene.traverse((object) => {
          if (object instanceof THREE.Mesh) {
            const material = object.material;
            material.color.setHex(0xffffff);
            material.side = THREE.DoubleSide;
            object.translateX(-7);
          }
        });
        this.scene?.add(gltf.scene);
      },
    );
    const cylinderGeometry = new THREE.CylinderGeometry(
      2.2,
      3,
      towerHeight,
      32,
    );
    cylinderGeometry.rotateX((90 * Math.PI) / 180);
    cylinderGeometry.rotateY((0 * Math.PI) / 180);
    cylinderGeometry.translate(0, 0, -0.5 * towerHeight);
    const mat = new THREE.MeshLambertMaterial({ color: 0xffffff });
    const cylinderMesh = new THREE.Mesh(cylinderGeometry, mat);
    this.scene.add(cylinderMesh);

    const nacelleGeometry = new THREE.BoxGeometry(
      10,
      nacelleHeight,
      nacelleHeight,
    );
    nacelleGeometry.translate(1, 0, -1.5);
    nacelleGeometry.rotateY((0 * Math.PI) / 180);
    const boxMesh = new THREE.Mesh(nacelleGeometry, mat);
    this.scene.add(boxMesh);

    this.map = map;

    // use the Mapbox GL JS map canvas for three.js
    this.renderer = new THREE.WebGLRenderer({
      canvas: map.getCanvas(),
      context: gl,
      antialias: true,
    });

    this.renderer.autoClear = false;
  }

  onRemove(_map: mapboxgl.Map, _gl: WebGLRenderingContext): void {
    this.scene?.traverse((o) => dispose(o));
    this.renderer?.dispose();
  }

  renderSingle(_gl: WebGLRenderingContext, matrix: number[], index: number) {
    // Idk what this is; seems we're getting called before `onAdd` sometimes??
    // https://vind-technologies.sentry.io/issues/4370670572
    // Check for this explicitly, and hope that it's okay.
    if (
      !this.camera ||
      !this.modelTransforms ||
      !this.renderer ||
      !this.map ||
      !this.scene
    )
      return;
    if (!this.modelTransforms) return;
    const transform = this.modelTransforms[index];
    const rotationX = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(1, 0, 0),
      transform.rotateX,
    );
    const rotationY = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(0, 1, 0),
      transform.rotateY,
    );
    const rotationZ = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(0, 0, 1),
      ((-90 - this.direction) * Math.PI) / 180,
    );
    const m = new THREE.Matrix4().fromArray(matrix);
    const l = new THREE.Matrix4()
      .makeTranslation(
        transform.translateX,
        transform.translateY,
        transform.translateZ,
      )
      .scale(
        new THREE.Vector3(transform.scale, -transform.scale, transform.scale),
      )
      .multiply(rotationX)
      .multiply(rotationY)
      .multiply(rotationZ);

    this.camera.projectionMatrix = m.multiply(l);
    this.renderer.resetState();
    this.renderer.render(this.scene, this.camera);
    this.map.triggerRepaint();
  }

  render(gl: WebGLRenderingContext, matrix: number[]) {
    LAYER_DEBUG_PRINT && console.time("ThreeDTurbines.render");
    if (this.turbineLonlats.length > 0) {
      for (let i = 0; i < (this.modelTransforms ?? []).length; i++) {
        this.renderSingle(gl, matrix, i);
      }
    }
    LAYER_DEBUG_PRINT && console.timeEnd("ThreeDTurbines.render");
  }
}
