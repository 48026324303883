import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import {
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";
import styled from "styled-components";
import CloseIcon from "@icons/24/Close.svg";
import CompareIcon from "@icons/24/Compare.svg";
import DnDIconSmall from "@icons/12/DnDsmall.svg";
import { IconBtn } from "components/General/Icons";
import {
  getAnalysisVersion,
  getAverageHubHeight,
  getBranch,
  getConfiguration,
  getProjectId,
  getWindSourceConfiguration,
  isOutdated,
  ProdId,
} from "components/ProductionV2/state";
import { Configuration } from "services/configurationService";
import { CostConfiguration } from "services/costService";
import { WindSourceConfiguration } from "services/windSourceConfigurationService";
import { libraryAndProjectCostConfigurationsSelectorFamily } from "state/costConfigurations";
import { organisationIdSelector, projectIdSelector_ } from "state/pathParams";
import { colors } from "styles/colors";
import { SkeletonBlock, SkeletonText } from "components/Loading/Skeleton";
import { spacing2, spacing4, spacing5, spacing8 } from "styles/space";
import { typography } from "styles/typography";
import { isDefined } from "utils/predicates";
import { dedup, objectEquals } from "utils/utils";
import { getDefaultCostConfig } from "components/ConfigurationModal/Cost/state";
import {
  ErrorBoundaryLocalFallback,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";
import Tooltip from "components/General/Tooltip";
import {
  analysisStoppedText,
  AnalysisStoppedTypes,
} from "components/ProductionV2/types";
import ParkWithChildrenImage from "components/CompareParksModal/ParkWithChildrenImage";
import { useColumnTemplates } from "components/CompareParksModal/columnTemplates";
import {
  baselineComparisonValuesAtom,
  compareIsLoading,
  ComparisonMode,
  comparisonModeAtom,
  EnrichedSelectedParkCompare,
  selectedComparisonAttributesAtom,
  selectedParksAtom,
  shownCompareDataAtom,
  visibleComparisonListRowsAtom,
} from "components/CompareParksModal/state";
import {
  CompareColumnItemAttribute,
  CompareColumnListItemAttribute,
  ComparisonAttributeKey,
} from "components/CompareParksModal/types";
import {
  AttributeHeader,
  AttributeValue,
  ITEM_WIDTH,
} from "components/CompareParksModal/ParkComparisonView/ParkComparisonView.style";
import useParkComparisonValues from "components/CompareParksModal/ParkComparisonView/useParkComparisonValues";
import { getInvalidTypesInParkAndBranch } from "state/layout";
import { InvalidTypesDisplayText } from "types/invalidTypes";
import Button from "components/General/Button";
import { editorAccessProjectSelector } from "state/user";
import { useAnalysisForceRestart } from "components/ProductionV2/Triggers";
import { WarningCircle } from "styles/errorCircle";
import { modalTypeOpenAtom } from "state/modal";
import useNavigateToPark from "hooks/useNavigateToPark";
import { mapRefAtom } from "state/map";
import { useGoToFeatures } from "hooks/map";
import useSelectionInMap from "hooks/useSelectionInMap";
import { AnalysisConfigNotFoundCompareErrorBoundaryFallbackRender } from "components/CompareParksModal/ErrorUtils";
import { ErrorBoundary } from "react-error-boundary";
import {
  ElectricalStatError,
  ParkHasAnyValidationError,
  ProductionError,
} from "components/ValidationWarnings/FeatureSpecificErrors";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import DontRenderWhenCheckly from "components/DontRenderWhenCheckly/DontRenderWhenCheckly";
import { TriggerCompareFinanceAndProduction } from "components/Finance/Triggers";
import { FinanceId } from "components/Finance/state";
import { cursorIsInRightHalfOfElement } from "utils/dragNDropUtils";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import {
  ColumnHeader,
  ColumnTemplateWithBorder,
  DnDWrapper,
  ErrorWrapper,
  ParkName,
  StickyHeader,
  StickyHeaderBorderContent,
} from "./ComparisonParkV2.style";
import {
  AnalysisConfigSelector,
  CostConfigSelector,
  ExportCableTypeSelector,
  HubHeightSelector,
  TurbineTypeSelector,
  WindConfigSelector,
} from "./config-selector-dropdowns";
import { BranchMeta } from "types/api";
import { branchSelectedConfigurationAtomFamily } from "state/configuration";
import { selectedWindConfigurationAtomFamily } from "state/windSourceConfiguration";
import { SimpleTurbineType } from "types/turbines";
import ComparisonValue from "./ComparisonValue";
import { CableType } from "services/cableTypeService";

function AttributeValueFlattener({
  attribute,
  averageTurbineHeight,
  visibleIds,
  selectedHubHeight,
  setSelectedHubHeight,
  selectedTurbineType,
  setSelectedTurbineType,
  selectedExportCableType,
  setSelectedExportCableType,
  analysisStoppedOrFailed,
  hasInvalidTypes,
  showProgress,
  analysisProgress,
  baseline,
  attributeKey,
  parkId,
  branchId,
  value,
  comparisonMode,
}: {
  attribute: CompareColumnItemAttribute | CompareColumnListItemAttribute;
  averageTurbineHeight: number;
  visibleIds: string[] | undefined;
  selectedHubHeight: number | undefined;
  setSelectedHubHeight: (selectedHubHeight: number | undefined) => void;
  selectedTurbineType: SimpleTurbineType | undefined;
  setSelectedTurbineType: (turbineType: SimpleTurbineType | undefined) => void;
  selectedExportCableType: CableType | undefined;
  setSelectedExportCableType: (exportCableType: CableType | undefined) => void;
  analysisStoppedOrFailed: boolean;
  hasInvalidTypes: boolean;
  showProgress: 0 | undefined | boolean;
  analysisProgress: number | undefined;
  baseline?: unknown;
  attributeKey: ComparisonAttributeKey;
  parkId: string;
  branchId: string;
  value: unknown;
  comparisonMode: ComparisonMode;
}) {
  if (attribute.type === "list") {
    return (
      <>
        {attribute.values.map((att) => {
          if (Array.isArray(visibleIds) && !visibleIds.includes(att.key)) {
            return null;
          }

          return (
            <AttributeValueFlattener
              key={att.key}
              attribute={att}
              visibleIds={visibleIds}
              value={(value as Record<string, unknown>)[att.key]}
              baseline={
                (baseline as Record<string, unknown> | undefined)?.[att.key]
              }
              averageTurbineHeight={averageTurbineHeight}
              selectedHubHeight={selectedHubHeight}
              setSelectedHubHeight={setSelectedHubHeight}
              selectedTurbineType={selectedTurbineType}
              setSelectedTurbineType={setSelectedTurbineType}
              selectedExportCableType={selectedExportCableType}
              setSelectedExportCableType={setSelectedExportCableType}
              analysisStoppedOrFailed={analysisStoppedOrFailed}
              hasInvalidTypes={hasInvalidTypes}
              showProgress={showProgress}
              analysisProgress={analysisProgress}
              attributeKey={attributeKey}
              parkId={parkId}
              branchId={branchId}
              comparisonMode={comparisonMode}
            />
          );
        })}
      </>
    );
  }

  const valueIsDefined = isDefined(value);

  if (attribute.key === "averageTurbineHeight") {
    const hasChangedHubHeight =
      Boolean(selectedHubHeight) && selectedHubHeight !== averageTurbineHeight;
    return (
      <AttributeValue
        textRight
        data-attribute-value={1}
        overflowVisible={true}
        warning={hasChangedHubHeight}
      >
        <HubHeightSelector
          formatFunction={attribute.format}
          originalAverageTurbineHeight={averageTurbineHeight}
          selectedHubHeight={selectedHubHeight}
          setSelectedHubHeight={setSelectedHubHeight}
          hasChangedHubHeight={hasChangedHubHeight}
        />
      </AttributeValue>
    );
  }

  if (attribute.key === "turbineTypesString") {
    const hasChangedTurbineType =
      Boolean(selectedTurbineType) &&
      selectedTurbineType !== attribute.format(value);
    return (
      <AttributeValue
        textRight
        data-attribute-value={1}
        overflowVisible={true}
        warning={hasChangedTurbineType}
      >
        <TurbineTypeSelector
          turbineTypesString={value as string}
          selectedTurbineType={selectedTurbineType}
          setSelectedTurbineType={setSelectedTurbineType}
          hasChangedTurbineType={hasChangedTurbineType}
          averageTurbineHeight={averageTurbineHeight}
        />
      </AttributeValue>
    );
  }

  if (attribute.key === "exportCableTypesString") {
    const hasChangedExportCableType =
      Boolean(selectedExportCableType) &&
      selectedExportCableType !== attribute.format(value);
    return (
      <AttributeValue
        textRight
        data-attribute-value={1}
        overflowVisible={true}
        warning={hasChangedExportCableType}
      >
        <ExportCableTypeSelector
          exportCableTypesString={value as string}
          selectedExportCableType={selectedExportCableType}
          setSelectedExportCableType={setSelectedExportCableType}
          hasChangedExportCableType={hasChangedExportCableType}
        />
      </AttributeValue>
    );
  }

  return (
    <AttributeValue textRight data-attribute-value={1}>
      {analysisStoppedOrFailed || hasInvalidTypes ? (
        <p>-</p>
      ) : !valueIsDefined ? (
        <SkeletonText
          style={{ width: "50%", minHeight: "2rem" }}
          text={`${showProgress ? `${Math.round(analysisProgress! * 100)}%` : ""}`}
        />
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: spacing8,
            flexDirection: "row-reverse",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: spacing2,
            }}
          >
            {attribute.format(value)}
          </div>
          {typeof baseline !== "undefined" && (
            <ComparisonValue
              baselineValue={baseline}
              attribute={attribute}
              value={value}
              comparisonMode={comparisonMode}
            />
          )}
        </div>
      )}
      {attributeKey === ComparisonAttributeKey.CABLING && (
        <ElectricalStatError parkId={parkId} branchId={branchId} />
      )}
      {attributeKey === ComparisonAttributeKey.WIND && (
        <ProductionError parkId={parkId} branchId={branchId} />
      )}
      {attributeKey === ComparisonAttributeKey.PRODUCTION && (
        <ProductionError parkId={parkId} branchId={branchId} />
      )}
      {attributeKey === ComparisonAttributeKey.FINANCIAL && (
        <ParkHasAnyValidationError parkId={parkId} branchId={branchId} />
      )}
    </AttributeValue>
  );
}

const SelectedConfigDiffersHelpTooltip = ({
  nameOfDefault,
}: {
  nameOfDefault?: string;
}) => {
  return (
    <div style={{ flexShrink: 0 }}>
      <HelpTooltip
        text={"Selected config differs from branch default".concat(
          nameOfDefault ? ` (${nameOfDefault})` : "",
        )}
        color={colors.black}
      />
    </div>
  );
};

const ParkAttributes = React.memo(
  ({
    parkId,
    baseline,
    branchId,
    branch,
    analysisVersion,
    selectedAttributes,
    parkComparisonValues,
    selectedConfig,
    setSelectedConfig,
    defaultAnalysisConfig,
    selectedWindConfig,
    setSelectedWindConfig,
    defaultWindConfig,
    selectedCostConfig,
    setSelectedCostConfig,
    defaultCostConfig,
    averageTurbineHeight,
    selectedHubHeight,
    setSelectedHubHeight,
    selectedTurbineType,
    setSelectedTurbineType,
    selectedExportCableType,
    setSelectedExportCableType,
    analysisStoppedOrFailed,
    hasInvalidTypes,
    analysisProgress,
  }: {
    parkId: string;
    branchId: string;
    branch: BranchMeta;
    analysisVersion?: string;
    selectedAttributes: Record<ComparisonAttributeKey, string[]>;
    parkComparisonValues: Record<
      ComparisonAttributeKey,
      Record<string, unknown>
    >;
    baseline?: Record<ComparisonAttributeKey, Record<string, unknown>>;
    selectedConfig: Configuration | undefined;
    defaultAnalysisConfig?: Configuration;
    setSelectedConfig: (config: Configuration) => void;
    selectedWindConfig: WindSourceConfiguration;
    setSelectedWindConfig: (config: WindSourceConfiguration) => void;
    defaultWindConfig?: WindSourceConfiguration;
    selectedCostConfig: CostConfiguration;
    setSelectedCostConfig: (config: CostConfiguration) => void;
    defaultCostConfig?:
      | CostConfiguration
      | ReturnType<typeof getDefaultCostConfig>;
    averageTurbineHeight: number;
    selectedHubHeight: number | undefined;
    setSelectedHubHeight: (selectedHubHeight: number | undefined) => void;
    selectedTurbineType: SimpleTurbineType | undefined;
    setSelectedTurbineType: (
      turbineType: SimpleTurbineType | undefined,
    ) => void;
    selectedExportCableType: CableType | undefined;
    setSelectedExportCableType: (
      exportCableType: CableType | undefined,
    ) => void;
    analysisStoppedOrFailed: boolean;
    hasInvalidTypes: boolean;
    analysisProgress: number | undefined;
  }) => {
    const columnTemplates = useColumnTemplates();
    const visibleComparisonListRows = useRecoilValue(
      visibleComparisonListRowsAtom,
    );
    const comparisonMode = useRecoilValue(comparisonModeAtom);

    return (
      <>
        <AttributeHeader />
        <AttributeValue
          textRight
          style={{
            gap: spacing4,
          }}
          warning={selectedCostConfig.id !== branch.costConfigurationId}
          data-attribute-value={1}
        >
          {selectedCostConfig.id !== branch.costConfigurationId && (
            <SelectedConfigDiffersHelpTooltip
              nameOfDefault={defaultCostConfig?.name}
            />
          )}
          <CostConfigSelector
            selectedConfig={selectedCostConfig}
            setSelectedConfig={setSelectedCostConfig}
          />
        </AttributeValue>
        <AttributeValue
          textRight
          warning={selectedConfig?.id !== branch.analysisConfigurationId}
          style={{
            gap: spacing4,
          }}
          data-attribute-value={1}
        >
          {selectedConfig?.id !== branch.analysisConfigurationId && (
            <SelectedConfigDiffersHelpTooltip
              nameOfDefault={defaultAnalysisConfig?.name}
            />
          )}
          <AnalysisConfigSelector
            selectedConfig={selectedConfig}
            setSelectedConfig={setSelectedConfig}
          />
        </AttributeValue>
        <AttributeValue
          textRight
          warning={selectedWindConfig?.id !== branch.windConfigurationId}
          style={{
            gap: spacing4,
          }}
          data-attribute-value={1}
        >
          {selectedWindConfig?.id !== branch.windConfigurationId && (
            <SelectedConfigDiffersHelpTooltip
              nameOfDefault={defaultWindConfig?.name}
            />
          )}
          <WindConfigSelector
            selectedConfig={selectedWindConfig}
            setSelectedConfig={setSelectedWindConfig}
          />
        </AttributeValue>
        <AttributeValue textRight data-attribute-value={1}>
          {analysisVersion}
        </AttributeValue>

        {Object.entries(columnTemplates).map(
          ([attributeKey, columnTemplate]) => {
            const typedAttributeKey = attributeKey as ComparisonAttributeKey;
            const visibleAttributes = columnTemplate.attributes.filter((f) =>
              (selectedAttributes as any)[typedAttributeKey]?.includes(f.key),
            );

            if (visibleAttributes.length === 0) {
              return null;
            }
            const showProgress =
              analysisProgress && columnTemplate.name === "Production";
            return (
              <div key={typedAttributeKey}>
                <AttributeHeader />
                {visibleAttributes.map((attribute) => {
                  return (
                    <AttributeValueFlattener
                      key={attribute.key}
                      attribute={attribute}
                      visibleIds={visibleComparisonListRows[attribute.key]}
                      averageTurbineHeight={averageTurbineHeight}
                      selectedHubHeight={selectedHubHeight}
                      setSelectedHubHeight={setSelectedHubHeight}
                      selectedTurbineType={selectedTurbineType}
                      setSelectedTurbineType={setSelectedTurbineType}
                      selectedExportCableType={selectedExportCableType}
                      setSelectedExportCableType={setSelectedExportCableType}
                      analysisStoppedOrFailed={analysisStoppedOrFailed}
                      hasInvalidTypes={hasInvalidTypes}
                      showProgress={showProgress}
                      analysisProgress={analysisProgress}
                      baseline={baseline?.[typedAttributeKey][attribute.key]}
                      attributeKey={typedAttributeKey}
                      parkId={parkId}
                      branchId={branchId}
                      value={
                        parkComparisonValues[typedAttributeKey][attribute.key]
                      }
                      comparisonMode={comparisonMode}
                    />
                  );
                })}
              </div>
            );
          },
        )}
      </>
    );
  },
);

const ClickableParkName = ({
  projectId,
  selectedPark,
  children,
}: {
  projectId: string;
  selectedPark: EnrichedSelectedParkCompare;
} & React.PropsWithChildren) => {
  const map = useRecoilValue(mapRefAtom);
  const goToFeatures = useGoToFeatures(map);
  const organisationId = useRecoilValue(organisationIdSelector);
  const { navigateToPark } = useNavigateToPark();
  const { setCurrentSelectionArray } = useSelectionInMap();

  const setModalOpen = useSetRecoilState(modalTypeOpenAtom);

  return (
    <Tooltip
      text="Go to park"
      outerDivStyle={{ overflowX: "hidden" }}
      innerDivStyle={{ overflowX: "hidden" }}
    >
      <Link
        style={{
          ...typography.h4,
          textDecoration: "none",
        }}
        to={{
          pathname: `/design/project/${organisationId}/${projectId}/${selectedPark.branchId}/${selectedPark.parkId}`,
        }}
        onClick={(e) => {
          e.preventDefault();
          setModalOpen(undefined);
          navigateToPark(selectedPark.parkId, selectedPark.branchId);
          goToFeatures([selectedPark.park]);
          setCurrentSelectionArray([selectedPark.parkId]);
        }}
      >
        {children}
      </Link>
    </Tooltip>
  );
};

const ParkImageWrapper = styled.div`
  position: relative;
  background-color: #a9c6e8;
  height: 18rem;
  display: flex;
  justify-content: center;
`;

const parkImageStyle: React.CSSProperties = {
  width: "100%",
  height: "18rem",
};

export const PARK_DRAG_TYPE = "PARK";
const defaultCostConfig = getDefaultCostConfig({ id: true });

const Inner = ({
  selectedPark,
  onRemove,
  index,
  maxIndex,
  movePark,
  triggerId,
}: {
  selectedPark: EnrichedSelectedParkCompare;
  onRemove(parkId: string, branchId: string, comparisonId: string): void;
  index: number;
  maxIndex: number;
  movePark: (fromIndex: number, toIndex: number) => void;
  triggerId: ProdId & FinanceId;
}) => {
  const projectId = useRecoilValue(getProjectId(triggerId));
  const [baselineComparison, setBaselineComparison] = useRecoilState(
    baselineComparisonValuesAtom,
  );
  const ref = useRef<HTMLInputElement>(null);

  const [hoverState, setHoverState] = useState<undefined | "start" | "end">(
    undefined,
  );
  const thisComparisonIsBaseline =
    baselineComparison?.comparisonId === selectedPark.comparisonId;

  const isProjectEditor = useRecoilValueLoadable(
    editorAccessProjectSelector,
  ).valueMaybe();

  const branch = useRecoilValue(getBranch(triggerId));

  const invalidTypes = useRecoilValue(
    getInvalidTypesInParkAndBranch({
      parkId: selectedPark.parkId,
      branchId: selectedPark.branchId,
    }),
  );
  const hasInvalidTypes = invalidTypes.length > 0;

  const outdated = useRecoilValueLoadable(isOutdated(triggerId)).valueMaybe();
  const [restarted, setRestarted] = useState<boolean>(false);
  const restartProduction = useAnalysisForceRestart(triggerId);
  const [analysisStopped, setAnalysisStopped] = useState<string>();
  const [analysisFailed, setAnalysisFailed] = useState<boolean>(false);
  const analysisStoppedOrFailed: boolean = !!analysisStopped || analysisFailed;
  const setVisibleComparisonListRows = useSetRecoilState(
    visibleComparisonListRowsAtom,
  );

  const parkComparisonValues = useParkComparisonValues(
    triggerId,
    selectedPark.park,
    selectedPark.branchId,
    selectedPark.selectedHubHeight,
    selectedPark.selectedTurbineType,
    selectedPark.selectedExportCableType,
  );

  const cableTypes = parkComparisonValues[ComparisonAttributeKey.CABLING]
    .cableTypes as Record<string, number> | Record<string, undefined>;
  useEffect(() => {
    if (cableTypes) {
      const ids = Object.entries(cableTypes)
        .filter(([_key, value]) => typeof value !== "undefined" && value > 0)
        .map(([key]) => key);
      setVisibleComparisonListRows((curr) => {
        if (!curr.cableTypes) {
          return {
            ...curr,
            cableTypes: ids,
          };
        } else {
          return {
            ...curr,
            cableTypes: dedup([...curr.cableTypes, ...ids]),
          };
        }
      });
    }
  }, [cableTypes, setVisibleComparisonListRows, maxIndex]);

  const handleRemove = useCallback(() => {
    onRemove(
      selectedPark.parkId,
      selectedPark.branchId,
      selectedPark.comparisonId,
    );

    if (baselineComparison?.comparisonId === selectedPark.comparisonId) {
      setBaselineComparison(undefined);
    }
  }, [
    baselineComparison?.comparisonId,
    onRemove,
    selectedPark.branchId,
    selectedPark.comparisonId,
    selectedPark.parkId,
    setBaselineComparison,
  ]);

  const [dropCollection, drop] = useDrop(
    () => ({
      accept: PARK_DRAG_TYPE,
      hover: (hoveredItem, monitor) => {
        if (!monitor.isOver() || hoveredItem.index === index) {
          return setHoverState(undefined);
        }
        const hoveringRight = cursorIsInRightHalfOfElement(
          ref.current,
          monitor.getClientOffset(),
        );
        setHoverState(hoveringRight ? "end" : "start");
      },
      collect: (monitor) => {
        const isHovered = monitor.isOver() && monitor.canDrop();
        return {
          isHovered,
        };
      },
      drop: (draggedItem: { index: number }, monitor: DropTargetMonitor) => {
        const rect = ref.current?.getBoundingClientRect();
        if (!rect) {
          return {
            droppedOnWrapper: false,
          };
        }
        if (draggedItem.index === index) {
          return {
            droppedOnWrapper: false,
          };
        }

        const isRight = cursorIsInRightHalfOfElement(
          ref.current,
          monitor.getClientOffset(),
        );

        const newIndex = draggedItem.index > index ? index + 1 : index;

        movePark(draggedItem.index, isRight ? newIndex : newIndex - 1);
        return {
          droppedOnWrapper: false,
        };
      },
    }),
    [index, movePark],
  );

  const [{ isDragging }, drag] = useDrag<
    any,
    { droppedOnWrapper: boolean },
    { isDragging: boolean }
  >(
    () => ({
      type: PARK_DRAG_TYPE,
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (draggedItem, monitor) => {
        if (monitor.getDropResult()?.droppedOnWrapper) {
          movePark(draggedItem.index, maxIndex);
        }
      },
    }),
    [index, maxIndex, movePark],
  );

  useEffect(() => {
    if (thisComparisonIsBaseline) {
      setBaselineComparison((curr) => {
        return {
          comparisonId: curr!.comparisonId,
          values: parkComparisonValues,
        };
      });
    }
  }, [
    parkComparisonValues,
    selectedPark.comparisonId,
    setBaselineComparison,
    thisComparisonIsBaseline,
  ]);

  const onSetBaselineClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (Boolean(analysisFailed) || Boolean(analysisStopped)) {
      return;
    }

    setBaselineComparison((curr) => {
      if (curr?.comparisonId === selectedPark.comparisonId) {
        return undefined;
      }
      return {
        comparisonId: selectedPark.comparisonId,
        values: parkComparisonValues,
      };
    });
  };

  drag(drop(ref));

  return (
    <>
      <ColumnTemplateWithBorder
        isBaseline={thisComparisonIsBaseline}
        isError={analysisStoppedOrFailed || invalidTypes.length > 0}
        isHoveredStart={dropCollection.isHovered && hoverState === "start"}
        isHoveredEnd={dropCollection.isHovered && hoverState === "end"}
        ref={ref}
        style={{
          opacity: isDragging ? 0.3 : 1,
        }}
        data-park={1}
      >
        <StickyHeader>
          <StickyHeaderBorderContent
            isBaseline={thisComparisonIsBaseline}
            isError={analysisStoppedOrFailed || invalidTypes.length > 0}
          >
            <div
              style={{
                display: "flex",
                gap: spacing5,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DnDWrapper>
                <DnDIconSmall />
              </DnDWrapper>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: spacing4,
                  cursor: "pointer",
                  padding: `${spacing4} 0`,
                }}
                onClick={onSetBaselineClick}
              >
                <p>Baseline</p>
                <Button
                  disabled={Boolean(analysisFailed) || Boolean(analysisStopped)}
                  buttonType="primary"
                  style={{
                    backgroundColor: thisComparisonIsBaseline
                      ? colors.blue700
                      : undefined,
                  }}
                  size="small"
                  icon={<CompareIcon />}
                  onClick={onSetBaselineClick}
                />
              </div>
              <IconBtn
                onClick={handleRemove}
                size="0.8rem"
                style={{
                  position: "absolute",
                  top: "1px",
                  right: "1px",
                }}
              >
                <CloseIcon />
              </IconBtn>
            </div>
            <ColumnHeader>
              <ParkName>
                <ClickableParkName
                  selectedPark={selectedPark}
                  projectId={projectId}
                >
                  {selectedPark.park.properties.name}
                </ClickableParkName>
              </ParkName>
              <p style={typography.body}>{branch.title}</p>
            </ColumnHeader>
            {analysisStoppedOrFailed && (
              <ErrorWrapper style={{ position: "relative" }}>
                <SimpleAlert
                  title={analysisStopped ? "Analysis stopped" : undefined}
                  text={
                    analysisStopped
                      ? analysisStoppedText[
                          analysisStopped as AnalysisStoppedTypes
                        ]
                      : "Production analysis failed. The Vind team has been notified."
                  }
                  type={"error"}
                />
              </ErrorWrapper>
            )}
            {outdated && !analysisStoppedOrFailed && (
              <ErrorWrapper
                style={{
                  position: "relative",
                  width: "96%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <WarningCircle title={"Analysis is outdated"} />
                <p>Analysis is outdated</p>
                <Button
                  text={"Rerun"}
                  style={{ height: "2rem", margin: 0 }}
                  onClick={() => {
                    setRestarted(true);
                    restartProduction();
                  }}
                  disabled={restarted || !isProjectEditor}
                />
              </ErrorWrapper>
            )}
            {hasInvalidTypes && !analysisStoppedOrFailed && (
              <ErrorWrapper style={{ position: "relative" }}>
                <InvalidTypesDisplayText invalidTypes={invalidTypes} />
              </ErrorWrapper>
            )}
          </StickyHeaderBorderContent>
        </StickyHeader>
        <ParkImageWrapper>
          <DontRenderWhenCheckly>
            <ParkWithChildrenImage
              park={selectedPark.park}
              branchId={selectedPark.branchId}
              style={parkImageStyle}
              mapboxImageSize="200x200"
              noExportCable
            />
          </DontRenderWhenCheckly>
        </ParkImageWrapper>
        <ErrorSafeParkComparisonValues
          parkComparisonValues={parkComparisonValues}
          selectedPark={selectedPark}
          triggerId={triggerId}
          setAnalysisStopped={setAnalysisStopped}
          setAnalysisFailed={setAnalysisFailed}
          analysisStoppedOrFailed={analysisStoppedOrFailed}
          hasInvalidTypes={hasInvalidTypes}
        />
      </ColumnTemplateWithBorder>
    </>
  );
};

const ErrorSafeParkComparisonValues = ({
  parkComparisonValues,
  selectedPark,
  triggerId,
  analysisStoppedOrFailed,
  hasInvalidTypes,
  setAnalysisStopped,
  setAnalysisFailed,
}: {
  parkComparisonValues: Record<ComparisonAttributeKey, Record<string, unknown>>;
  selectedPark: EnrichedSelectedParkCompare;
  triggerId: ProdId & FinanceId;
  analysisStoppedOrFailed: boolean;
  hasInvalidTypes: boolean;
  setAnalysisStopped: (analysisStopped: any) => void;
  setAnalysisFailed: (analysisFailed: boolean) => void;
}) => {
  const projectId = useRecoilValue(getProjectId(triggerId));
  const setSelectedParks = useSetRecoilState(selectedParksAtom({ projectId }));

  const onSelectAnalysisConfigOnError = useCallback(
    (config: Configuration) => {
      setSelectedParks((curr) =>
        [...curr].map((p) => {
          return p.comparisonId === selectedPark.comparisonId
            ? { ...p, selectedAnalysisConfigurationId: config.id }
            : p;
        }),
      );
    },
    [selectedPark.comparisonId, setSelectedParks],
  );

  const onSelectWindAnalysisConfigOnError = useCallback(
    (config: WindSourceConfiguration) => {
      setSelectedParks((curr) =>
        [...curr].map((p) => {
          return p.comparisonId === selectedPark.comparisonId
            ? { ...p, selectedWindConfigurationId: config.id }
            : p;
        }),
      );
    },
    [selectedPark.comparisonId, setSelectedParks],
  );

  const currentSelectionProductionLoadable = useRecoilValueLoadable(
    getConfiguration(triggerId),
  );
  const currentWindSourceConfigurationLoadable = useRecoilValueLoadable(
    getWindSourceConfiguration(triggerId),
  );

  const currentCostConfigurationLoadable = useRecoilValueLoadable(
    libraryAndProjectCostConfigurationsSelectorFamily({ nodeId: projectId }),
  );

  const emptyCostConfig = useMemo(
    () =>
      currentCostConfigurationLoadable.valueMaybe() &&
      currentCostConfigurationLoadable.valueMaybe()?.length === 0,
    [currentCostConfigurationLoadable],
  );

  const resetKeys = useMemo(
    () => [
      currentSelectionProductionLoadable.state,
      currentWindSourceConfigurationLoadable.state,
      currentCostConfigurationLoadable.state,
    ],
    [
      currentSelectionProductionLoadable,
      currentWindSourceConfigurationLoadable,
      currentCostConfigurationLoadable,
    ],
  );
  return (
    <>
      {currentCostConfigurationLoadable.valueMaybe()?.length === 0 && (
        <div>
          <SimpleAlert
            text={
              "No financial configurations exist for this project. Go to Project configurations to create one."
            }
            type={"error"}
          />
        </div>
      )}
      {!emptyCostConfig && (
        <ErrorBoundary
          fallbackRender={({ error }) => (
            <AnalysisConfigNotFoundCompareErrorBoundaryFallbackRender
              error={error}
              onSelectAnalysisConfig={onSelectAnalysisConfigOnError}
              onSelectWindConfig={onSelectWindAnalysisConfigOnError}
            />
          )}
          resetKeys={resetKeys}
        >
          <ParkComparisonValues
            parkComparisonValues={parkComparisonValues}
            selectedPark={selectedPark}
            triggerId={triggerId}
            setAnalysisStopped={setAnalysisStopped}
            setAnalysisFailed={setAnalysisFailed}
            analysisStoppedOrFailed={analysisStoppedOrFailed}
            hasInvalidTypes={hasInvalidTypes}
          />
        </ErrorBoundary>
      )}
    </>
  );
};

const ParkComparisonValues = ({
  parkComparisonValues,
  selectedPark,
  triggerId,
  analysisStoppedOrFailed,
  hasInvalidTypes,
  setAnalysisStopped,
  setAnalysisFailed,
}: {
  parkComparisonValues: Record<ComparisonAttributeKey, Record<string, unknown>>;
  selectedPark: EnrichedSelectedParkCompare;
  triggerId: ProdId & FinanceId;
  analysisStoppedOrFailed: boolean;
  hasInvalidTypes: boolean;
  setAnalysisStopped: (analysisStopped: any) => void;
  setAnalysisFailed: (analysisFailed: boolean) => void;
}) => {
  const projectId = useRecoilValue(getProjectId(triggerId));
  const setSelectedParks = useSetRecoilState(selectedParksAtom({ projectId }));

  const selectedAttributes = useRecoilValue(selectedComparisonAttributesAtom);
  const setShownData = useSetRecoilState(shownCompareDataAtom);

  const branch = useRecoilValue(getBranch(triggerId));

  const selectedAnalysisConfig = useRecoilValue(getConfiguration(triggerId));
  const selectedWindConfig = useRecoilValue(
    getWindSourceConfiguration(triggerId),
  );
  const analysisVersion = useRecoilValue(getAnalysisVersion(triggerId));
  const averageTurbineHeight = useRecoilValue(getAverageHubHeight(triggerId));

  const baselineComparisonValues = useRecoilValue(baselineComparisonValuesAtom);

  const costConfigurations = useRecoilValue(
    libraryAndProjectCostConfigurationsSelectorFamily({ nodeId: projectId }),
  );

  const defaultBranchAnalysisConfig = useRecoilValue(
    branchSelectedConfigurationAtomFamily({
      projectId,
      branchId: branch.id,
    }),
  );

  const defaultBranchCostConfig = useMemo(
    () =>
      costConfigurations.find((c) => c.id === branch.costConfigurationId) ??
      defaultCostConfig,
    [costConfigurations, branch.costConfigurationId],
  );

  const defaultBranchWindConfig = useRecoilValue(
    selectedWindConfigurationAtomFamily({ projectId, branchId: branch.id }),
  );

  const [selectedCostConfig, setSelectedCostConfig] =
    useState<CostConfiguration>(
      costConfigurations.find(
        (c) => c.id === selectedPark.selectedCostConfigurationId,
      ) ??
        costConfigurations.find((c) => c.id === branch?.costConfigurationId) ??
        costConfigurations[0] ??
        defaultCostConfig,
    );

  if (!isDefined(selectedCostConfig)) {
    throw new Error("Cost configuration not found");
  }

  const onSelectAnalysisConfig = useCallback(
    (config: Configuration) => {
      setSelectedParks((curr) =>
        [...curr].map((p) => {
          return p.comparisonId === selectedPark.comparisonId
            ? { ...p, selectedAnalysisConfigurationId: config.id }
            : p;
        }),
      );
    },
    [selectedPark.comparisonId, setSelectedParks],
  );

  const onSelectWindConfig = useCallback(
    (config: WindSourceConfiguration) => {
      setSelectedParks((curr) =>
        [...curr].map((p) => {
          return p.comparisonId === selectedPark.comparisonId
            ? { ...p, selectedWindConfigurationId: config.id }
            : p;
        }),
      );
    },
    [selectedPark.comparisonId, setSelectedParks],
  );

  const onSelectCostConfig = useCallback(
    (config: CostConfiguration) => {
      setSelectedCostConfig(config);
      setSelectedParks((curr) =>
        [...curr].map((p) => {
          return p.comparisonId === selectedPark.comparisonId
            ? { ...p, selectedCostConfigurationId: config.id }
            : p;
        }),
      );
    },
    [selectedPark.comparisonId, setSelectedParks],
  );

  const onSelectHubHeight = useCallback(
    (selectedHubHeight: number | undefined) => {
      setSelectedParks((curr) =>
        [...curr].map((p) => {
          return p.comparisonId === selectedPark.comparisonId
            ? { ...p, selectedHubHeight: selectedHubHeight }
            : p;
        }),
      );
    },
    [selectedPark.comparisonId, setSelectedParks],
  );

  const onSelectTurbineType = useCallback(
    (selectedTurbineType: SimpleTurbineType | undefined) => {
      setSelectedParks((curr) =>
        [...curr].map((p) => {
          return p.comparisonId === selectedPark.comparisonId
            ? { ...p, selectedTurbineType: selectedTurbineType }
            : p;
        }),
      );
    },
    [selectedPark.comparisonId, setSelectedParks],
  );

  const onSelectExportCableType = useCallback(
    (selectedExportCableType: CableType | undefined) => {
      setSelectedParks((curr) =>
        [...curr].map((p) => {
          return p.comparisonId === selectedPark.comparisonId
            ? { ...p, selectedExportCableType: selectedExportCableType }
            : p;
        }),
      );
    },
    [selectedPark.comparisonId, setSelectedParks],
  );

  const analysisProgress = parkComparisonValues.PRODUCTION.progress;

  useEffect(() => {
    setAnalysisStopped(parkComparisonValues.PRODUCTION.stoppedReason);
    setAnalysisFailed(parkComparisonValues.PRODUCTION.status === "failed");
  }, [parkComparisonValues, setAnalysisFailed, setAnalysisStopped]);

  useEffect(() => {
    setShownData((prev) => {
      const p = prev[selectedPark.comparisonId];
      if (!p) {
        return {
          ...prev,
          [selectedPark.comparisonId]: {
            branchId: selectedPark.branchId,
            parkId: selectedPark.parkId,
            analysisConfigurationId:
              selectedPark.selectedAnalysisConfigurationId,
            windConfigurationId: selectedPark.selectedWindConfigurationId,
            costConfigurationId: selectedPark.selectedCostConfigurationId,
            analysisVersion: analysisVersion,
            comparisonData: parkComparisonValues,
          },
        };
      }

      if (!objectEquals(p.comparisonData, parkComparisonValues)) {
        return {
          ...prev,
          [selectedPark.comparisonId]: {
            ...prev[selectedPark.comparisonId],
            comparisonData: parkComparisonValues,
          },
        };
      }
      return prev;
    });

    return () => {
      setShownData((prev) => {
        const clone = JSON.parse(JSON.stringify(prev)) as typeof prev;
        delete clone[selectedPark.comparisonId];
        return clone;
      });
    };
  }, [
    parkComparisonValues,
    analysisVersion,
    selectedPark.branchId,
    selectedPark.comparisonId,
    selectedPark.parkId,
    selectedPark.selectedAnalysisConfigurationId,
    selectedPark.selectedCostConfigurationId,
    selectedPark.selectedWindConfigurationId,
    setShownData,
  ]);

  return (
    <ParkAttributes
      key={selectedPark.park.id}
      baseline={
        baselineComparisonValues?.comparisonId !== selectedPark.comparisonId
          ? baselineComparisonValues?.values
          : undefined
      }
      branch={branch}
      parkId={selectedPark.parkId}
      branchId={selectedPark.branchId}
      analysisVersion={analysisVersion}
      selectedAttributes={selectedAttributes}
      parkComparisonValues={parkComparisonValues}
      selectedConfig={selectedAnalysisConfig}
      defaultCostConfig={defaultBranchCostConfig}
      defaultAnalysisConfig={defaultBranchAnalysisConfig}
      defaultWindConfig={defaultBranchWindConfig}
      setSelectedConfig={onSelectAnalysisConfig}
      selectedWindConfig={selectedWindConfig}
      setSelectedWindConfig={onSelectWindConfig}
      selectedCostConfig={selectedCostConfig}
      setSelectedCostConfig={onSelectCostConfig}
      averageTurbineHeight={averageTurbineHeight}
      selectedHubHeight={selectedPark.selectedHubHeight}
      setSelectedHubHeight={onSelectHubHeight}
      selectedTurbineType={selectedPark.selectedTurbineType}
      setSelectedTurbineType={onSelectTurbineType}
      selectedExportCableType={selectedPark.selectedExportCableType}
      setSelectedExportCableType={onSelectExportCableType}
      analysisStoppedOrFailed={analysisStoppedOrFailed}
      hasInvalidTypes={hasInvalidTypes}
      analysisProgress={analysisProgress as number}
    />
  );
};

const ComparisonPark = ErrorBoundaryWrapper(
  (props: {
    selectedPark: EnrichedSelectedParkCompare;
    onRemove(parkId: string, branchId: string, id: string): void;
    index: number;
    maxIndex: number;
    movePark: (fromIndex: number, toIndex: number) => void;
  }) => {
    const projectId = useRecoilValue(projectIdSelector_);

    const { selectedPark } = props;

    const triggerId = useMemo(
      () => `analysis-${selectedPark.comparisonId}` as ProdId & FinanceId,
      [selectedPark.comparisonId],
    );

    const setIsLoading = useSetRecoilState(compareIsLoading);
    useEffect(() => {
      setIsLoading((c) => ({ ...c, [triggerId]: true }));

      return () => {
        setIsLoading((c) => ({ ...c, [triggerId]: undefined }));
      };
    }, [triggerId, setIsLoading]);

    return (
      <TriggerCompareFinanceAndProduction
        triggerId={triggerId}
        projectId={projectId}
        selectedPark={selectedPark}
        fallback={
          <SkeletonBlock style={{ width: ITEM_WIDTH, flexShrink: 0 }} />
        }
      >
        <Inner {...props} triggerId={triggerId} />
      </TriggerCompareFinanceAndProduction>
    );
  },
  ErrorBoundaryLocalFallback,
  ScreamOnError,
);

export default React.memo(ComparisonPark);
