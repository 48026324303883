import { atomFamily, selectorFamily } from "recoil";
import {
  CostType,
  isFoundationMaterialCost,
} from "../../../../services/costService";
import { libraryAndProjectCostConfigurationsSelectorFamily } from "../../../../state/costConfigurations";
import { SoilStiffnessType } from "../../../../types/foundations";
import { CostUnit, unitToAmountUnit } from "../../../../types/financial";
import { Cost, costAddIds, costId } from "../capexNumbers";
import { amountId } from "../generalAmounts";

const floatingFoundationCostDefaultSelector = selectorFamily<
  Cost[],
  { projectId: string; configurationId: string }
>({
  key: "floatingFoundationCostDefaultSelector",
  get:
    ({ projectId, configurationId }) =>
    ({ get }) => {
      // TODO: make selector for this
      const configurations = get(
        libraryAndProjectCostConfigurationsSelectorFamily({
          nodeId: projectId,
        }),
      );
      const configuration = configurations.find(
        (c) => c.id === configurationId,
      );

      if (!configuration) return [];

      const {
        capex: {
          fixed: { foundations },
        },
      } = configuration;

      if (!isFoundationMaterialCost(foundations)) return [];

      const { floating } = foundations;

      if (!floating) return [];

      const steelDefaultCost = costAddIds({
        unit: floating.primarySteel.unit,
        name: "Primary steel floating",
        cost: floating.primarySteel.cost,
        category: CostType.Foundation,
        confidenceLevel: floating.primarySteel.confidenceLevel,
      });

      const concreteDefaultCost = costAddIds({
        unit: floating.concrete.unit,
        name: "Concrete",
        cost: floating.concrete.cost,
        category: CostType.Foundation,
        confidenceLevel: floating.concrete.confidenceLevel,
      });

      const reinforcementDefaultCost = costAddIds({
        name: "Reinforcement",
        unit: floating.reinforcement.unit,
        cost: floating.reinforcement.cost,
        category: CostType.Foundation,
        confidenceLevel: floating.reinforcement.confidenceLevel,
      });

      const postTensionCablesDefaultCost = costAddIds({
        name: "Post tension cables",
        unit: floating.postTensionCables.unit,
        cost: floating.postTensionCables.cost,
        category: CostType.Foundation,
        confidenceLevel: floating.postTensionCables.confidenceLevel,
      });

      const ballastDefaultCost = costAddIds({
        name: "Solid ballast",
        unit: floating.solidBallast.unit,
        cost: floating.solidBallast.cost,
        category: CostType.Foundation,
        confidenceLevel: floating.solidBallast.confidenceLevel,
      });

      return [
        steelDefaultCost,
        concreteDefaultCost,
        reinforcementDefaultCost,
        postTensionCablesDefaultCost,
        ballastDefaultCost,
      ];
    },
});

const jacketFoundationCostDefaultSelector = selectorFamily<
  Cost[],
  { projectId: string; configurationId: string }
>({
  key: "jacketFoundationCostDefaultSelector",
  get:
    ({ projectId, configurationId }) =>
    ({ get }) => {
      // TODO: make selector for this
      const configurations = get(
        libraryAndProjectCostConfigurationsSelectorFamily({
          nodeId: projectId,
        }),
      );
      const configuration = configurations.find(
        (c) => c.id === configurationId,
      );

      if (!configuration) return [];

      const {
        capex: {
          fixed: { foundations },
        },
      } = configuration;

      if (!isFoundationMaterialCost(foundations)) return [];

      const { jacket } = foundations;

      if (!jacket) return [];

      const steelDefaultCost = costAddIds({
        name: "Primary steel jacket",
        unit: CostUnit.euroPerT,
        category: CostType.Foundation,
        cost: jacket.primarySteel.cost,
      });

      return [steelDefaultCost];
    },
});

const monopileFoundationCostDefaultSelector = selectorFamily<
  Cost[],
  { projectId: string; configurationId: string }
>({
  key: "monopileFoundationCostDefaultSelector",
  get:
    ({ projectId, configurationId }) =>
    ({ get }) => {
      // TODO: make selector for this
      const configurations = get(
        libraryAndProjectCostConfigurationsSelectorFamily({
          nodeId: projectId,
        }),
      );
      const configuration = configurations.find(
        (c) => c.id === configurationId,
      );

      if (!configuration) return [];

      const {
        capex: {
          fixed: { foundations },
        },
      } = configuration;

      if (!isFoundationMaterialCost(foundations)) return [];

      const { monopile } = foundations;

      if (!monopile) return [];

      const steelDefaultCost = costAddIds({
        name: "Primary steel monopile",
        unit: CostUnit.euroPerT,
        category: CostType.Foundation,
        cost: monopile.primarySteel.cost,
      });

      return [steelDefaultCost];
    },
});

export const foundationCostsSelector = selectorFamily<
  Cost[],
  { projectId: string; configurationId: string }
>({
  key: "foundationCostDefaultSelector",
  get:
    ({ projectId, configurationId }) =>
    ({ get }) => {
      const floatingDefaultCost = get(
        floatingFoundationCostDefaultSelector({
          projectId,
          configurationId,
        }),
      );
      const monopileDefaultCost = get(
        monopileFoundationCostDefaultSelector({
          projectId,
          configurationId,
        }),
      );
      const jacketDefaultCost = get(
        jacketFoundationCostDefaultSelector({
          projectId,
          configurationId,
        }),
      );
      const configurations = get(
        libraryAndProjectCostConfigurationsSelectorFamily({
          nodeId: projectId,
        }),
      );

      const configuration = configurations.find(
        (c) => c.id === configurationId,
      );

      if (!configuration) return [];

      const {
        capex: {
          fixed: { foundations },
          custom,
        },
      } = configuration;

      const flatCosts: Cost[] = custom
        .filter((c) => c.category === CostType.Foundation)
        .filter((c) => c.unit === CostUnit.millionEuro)
        .map((c) => ({
          ...c,
          id: costId({
            category: CostType.Foundation,
            costId: c.id,
          }),
          amountId: amountId({
            unit: unitToAmountUnit[c.unit],
            category: c.category,
          }),
        }));

      const customCosts: Cost[] = custom
        .filter((c) => c.category === CostType.Foundation)
        .filter((c) => c.unit !== CostUnit.millionEuro)
        .flatMap((custom) => ({
          ...custom,
          id: costId({
            category: CostType.Foundation,
            costId: custom.id,
          }),
          amountId: amountId({
            unit: unitToAmountUnit[custom.unit],
            category: custom.category,
          }),
        }));

      let foundationCost: Cost[] = [];
      if (!isFoundationMaterialCost(foundations)) {
        const { cost, unit, confidenceLevel } = foundations;
        foundationCost.push({
          id: "foundation",
          amountId: amountId({
            unit: unitToAmountUnit[unit],
            category: CostType.Foundation,
          }),
          category: CostType.Foundation,
          name: "Foundations",
          cost: cost,
          unit: unit,
          confidenceLevel: confidenceLevel,
        });
      }

      return [
        ...floatingDefaultCost,
        ...monopileDefaultCost,
        ...jacketDefaultCost,
        ...foundationCost,
        ...customCosts,
        ...flatCosts,
      ];
    },
});

export const foundationsErrorAtomFamily = atomFamily<
  Error | undefined,
  {
    projectId: string;
    branchId: string;
    parkId: string;
    soilStiffness: SoilStiffnessType;
  }
>({
  key: "foundationsErrorAtomFamily",
  default: undefined,
});
