import styled from "styled-components";
import { colors } from "../../../styles/colors";
import { typography } from "../../../styles/typography";

export const UserImage = styled.img`
  border-radius: 1.6rem;
  height: 2.2rem;
  width: 2.2rem;
  margin: 1.4rem 0 1.4rem 1.6rem;
  object-fit: cover;
`;

export const MemberGrid = styled.div`
  padding: 0;
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1.5fr 1fr 1fr;

  /* Every 3 rows (each guest occupies 3 cells in your grid), starting from the 4th row (first guest) */
  & > :nth-child(10n+11),  /* Targets the Name cell of every second row */
  & > :nth-child(10n+12),  /* Targets the Email cell of every second row */
  & > :nth-child(10n+13),  /* Targets the Org access cell of every second row */
  & > :nth-child(10n+14),  /* Targets the Invitation expire date on every second row */
  & > :nth-child(10n+15)   /* Targets the delete button of every second row */ {
    background-color: ${colors.focusBackground};
  }
`;

export const TableText = styled.p`
  ${typography.contentAndButtons}
  display: flex;
  align-items: center;
`;

export const arrowStyles = {
  none: {
    up: { color: colors.inputOutline },
    down: { color: colors.inputOutline },
  },
  asc: {
    up: { color: colors.secondaryText },
    down: { color: colors.inputOutline },
  },
  desc: {
    up: { color: colors.inputOutline },
    down: { color: colors.secondaryText },
  },
};

export const ArrowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 12px; // Total height: 5px (arrow) + 2px (gap) + 5px (arrow)
  margin-left: 5px;

  &::before,
  &::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    display: block;
  }

  &::before {
    border-bottom: 5px solid ${colors.inputOutline};
  }

  &::after {
    border-top: 5px solid ${colors.inputOutline};
    margin-top: 2px;
  }
`;

export const AscArrow = styled(ArrowContainer)`
  &::before {
    border-bottom-color: ${colors.primaryText};
  }
`;

export const DescArrow = styled(ArrowContainer)`
  &::after {
    border-top-color: ${colors.primaryText};
  }
`;

export const BinWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
