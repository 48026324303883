import { extraShpFileSuffixes, neededShpFileSuffixes } from "./fileUtils";

export const validateShpInput = (files: File[]): [string[], string[]] => {
  const allNames = [] as string[];
  for (let file of files) {
    allNames.push(file.name.toLowerCase());
  }

  const shpNames = allNames
    .filter((n) => n.endsWith(".shp"))
    .map((n) => n.substring(0, n.length - 4));
  const shpSupportFilesWithoutShape = allNames.filter((n) => {
    const fileName = n.split(".")[0];
    const suffix = n.split(".")[1];
    const fileNeedShp = [
      ...neededShpFileSuffixes,
      ...extraShpFileSuffixes,
    ].some((s) => s === suffix);
    if (!fileNeedShp) return false;
    return !allNames.find((n2) => n2 === fileName + ".shp");
  });

  const shpWithNeededNeighbours = shpNames.filter((n) =>
    neededShpFileSuffixes.every((suffix) =>
      allNames.includes(`${n}.${suffix}`),
    ),
  );
  const shpWithoutNeededNeighbours = shpNames.filter(
    (n) =>
      !neededShpFileSuffixes.every((suffix) =>
        allNames.includes(`${n}.${suffix}`),
      ),
  );

  return [
    shpWithNeededNeighbours,
    [...shpWithoutNeededNeighbours, ...shpSupportFilesWithoutShape],
  ];
};
