import { LayerCollection } from "../LayerSettings/types";
import { BackendLayerCollection, listLayerCollections } from "./service";
import { atom, atomFamily, selectorFamily } from "recoil";
import {
  getLayersMap,
  layerSettingSelectorFamily,
} from "../LayerSettings/state";
import { expandLayerCollection2 } from "./utils";
import { isDefined } from "../../../utils/predicates";
import { Layer } from "../../../types/layers";
import { COLLECTION_URL_KEY } from "../const";
import { visibleInUrl } from "../utils";
import {
  BackendLayerCollectionVersion,
  listLayerCollectionVersions,
} from "../LayerSettings/service";
import { projectVersionAtom } from "../../../state/project";

export const allBackendLayerCollectionsAtomFamily = atomFamily<
  BackendLayerCollection[],
  { projectId: string }
>({
  key: "allBackendLayerCollectionsAtomFamily",
  default: selectorFamily({
    key: "allBackendLayerCollectionsSelectorFamily",
    get:
      ({ projectId }) =>
      ({ get }) => {
        const version = get(projectVersionAtom({ projectId }));
        if (!projectId) return [];
        return listLayerCollections(projectId, version);
      },
  }),
});

export const getAllLayerCollections = selectorFamily<
  LayerCollection[],
  { projectId: string }
>({
  key: "getAllLayerCollections",
  get:
    ({ projectId }) =>
    ({ get }) => {
      const allBackendCollections = get(
        allBackendLayerCollectionsAtomFamily({ projectId }),
      );
      const layerMap = get(getLayersMap({ projectId }));
      return allBackendCollections
        .map((b) => expandLayerCollection2(b, layerMap))
        .filter(isDefined);
    },
});

export const getLayerCollection = selectorFamily<
  LayerCollection | undefined,
  { projectId: string; collectionId: string }
>({
  key: "getLayerCollection",
  get:
    ({ collectionId, projectId }) =>
    ({ get }) => {
      const allCollections = get(getAllLayerCollections({ projectId }));
      return allCollections.find(
        (collection) => collection.id === collectionId,
      );
    },
});

export const layerCollectionVisibleAtom = atomFamily<
  boolean,
  { projectId: string; collectionId: string }
>({
  key: "layerCollectionVisibleAtom",
  default: selectorFamily({
    key: "layerCollectionVisibleSelectorFamily",
    get:
      ({ projectId, collectionId }) =>
      ({ get }) => {
        const visibleFromSharedUrl = visibleInUrl(
          collectionId,
          COLLECTION_URL_KEY,
        );
        if (visibleFromSharedUrl) return true;

        const collections = get(getAllLayerCollections({ projectId }));
        const collection = collections.find(
          (collection) => collection.id === collectionId,
        );
        return collection?.isDefault ?? false;
      },
  }),
});

export const visibleCollectionLayersSelectorFamily = selectorFamily<
  Layer[],
  { projectId: string }
>({
  key: "visibleCollectionLayersSelectorFamily",
  get:
    ({ projectId }) =>
    ({ get }) => {
      const collections = get(getAllLayerCollections({ projectId }));
      const layers = collections.flatMap((l) => l.layers);
      const visibleLayers = layers.filter((layer) => {
        const settings = get(
          layerSettingSelectorFamily({
            projectId,
            layerId: layer.id,
          }),
        );
        return settings.visible ?? false;
      });

      return visibleLayers;
    },
});

export const collectionLayerVersionsSelectorFamily = selectorFamily<
  BackendLayerCollectionVersion[],
  { projectId: string }
>({
  key: "collectionLayerVersionsSelectorFamily",
  get:
    ({ projectId }) =>
    () => {
      return listLayerCollectionVersions(projectId);
    },
});

export const isCreatingNewCollectionAtom = atom<boolean>({
  key: "isCreatingNewCollectionAtom",
  default: false,
});
