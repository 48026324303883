import {
  ErrorBoundaryWrapper,
  ErrorBoundaryWarningTriangle,
  ScreamOnError,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";
import { Column, Row } from "components/General/Layout";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useTypedPath } from "state/pathParams";
import {
  orgAnalysisManageAccessSelector,
  orgCableManageAccessSelector,
  orgDataPackagesManageAccessSelector,
  orgFinanicalManageAccessSelector,
  orgFoundationManageAccessSelector,
  orgTurbineManageAccessSelector,
} from "state/user";
import FoundationImage from "./foundation/FoundationImage";
import FoundationTab from "./foundation/FoundationTab";
import TurbineImage from "./turbine/TurbineImage";
import TurbineTab from "./turbine/TurbineTab";
import { libraryTabState } from "./state";
import AnalysisImage from "./analysis/AnalysisImage";
import AnalysisTab from "./analysis/AnalysisTab";
import CableImage from "./cable/CableImage";
import { libraryAllSelectorFamily } from "state/featureAccess";
import CableTab from "./cable/CableTab";
import { useEffect } from "react";
import { organisationRightSideModal } from "../OrganisationRightSide/state";
import FinancialImage from "./financial/FinancialImage";
import FinancialTab from "./financial/FinancialTab";
import DataLibraryImage from "./dataLibrary/DataLibraryImage";
import DataLibraryTab from "./dataLibrary/DataLibraryTab";
import { TabHeader } from "../style";

const Library = ErrorBoundaryWrapper(
  () => {
    const { organisationId } = useTypedPath("organisationId");
    const [selectedTab, setSelectedTab] = useRecoilState(
      libraryTabState({ organisationId }),
    );

    useEffect(() => {
      return () => {
        setSelectedTab(undefined);
      };
    }, [setSelectedTab]);

    const setContent = useSetRecoilState(
      organisationRightSideModal(organisationId),
    );
    useEffect(() => {
      if (!selectedTab) {
        setContent(undefined);
      }
    }, [selectedTab, setContent]);

    const hasOrgTurbineAccess = useRecoilValue(orgTurbineManageAccessSelector);
    const hasOrgFoundationAccess = useRecoilValue(
      orgFoundationManageAccessSelector,
    );
    const hasOrgAnalysisAccess = useRecoilValue(
      orgAnalysisManageAccessSelector,
    );
    const hasOrgCableAccess = useRecoilValue(orgCableManageAccessSelector);
    const hasOrgFinancialAccess = useRecoilValue(
      orgFinanicalManageAccessSelector,
    );
    const hasOrgDataLayersAccess = useRecoilValue(
      orgDataPackagesManageAccessSelector,
    );

    const allLibraryAccess = useRecoilValue(
      libraryAllSelectorFamily({ organisationId }),
    );

    if (selectedTab === "turbine" && hasOrgTurbineAccess) return <TurbineTab />;
    if (selectedTab === "foundation" && hasOrgFoundationAccess)
      return <FoundationTab />;
    if (selectedTab === "cable" && hasOrgCableAccess && allLibraryAccess)
      return <CableTab />;
    if (selectedTab === "analysis" && hasOrgAnalysisAccess && allLibraryAccess)
      return <AnalysisTab />;
    if (
      selectedTab === "financial" &&
      hasOrgFinancialAccess &&
      allLibraryAccess
    )
      return <FinancialTab />;
    if (
      selectedTab === "datalayers" &&
      hasOrgDataLayersAccess &&
      allLibraryAccess
    )
      return <DataLibraryTab />;

    return (
      <>
        <TabHeader>Library</TabHeader>
        <Column style={{ gap: "1.6rem", padding: "2.4rem 0" }}>
          <Row
            style={{ alignItems: "center", gap: "2.4rem", flexWrap: "wrap" }}
          >
            <TurbineImage
              onClick={() => setSelectedTab("turbine")}
              disabled={!hasOrgTurbineAccess}
            />
            <FoundationImage
              onClick={() => setSelectedTab("foundation")}
              disabled={!hasOrgFoundationAccess}
            />
            {allLibraryAccess && (
              <>
                <CableImage
                  onClick={() => setSelectedTab("cable")}
                  disabled={!hasOrgCableAccess || !allLibraryAccess}
                  disabledText={
                    !allLibraryAccess
                      ? "Upgrade your subscription"
                      : "Cable library access needed"
                  }
                />
                <AnalysisImage
                  onClick={() => setSelectedTab("analysis")}
                  disabled={!hasOrgAnalysisAccess || !allLibraryAccess}
                  disabledText={
                    !allLibraryAccess
                      ? "Upgrade your subscription"
                      : "Analysis library access needed"
                  }
                />
                <FinancialImage
                  onClick={() => setSelectedTab("financial")}
                  disabled={!hasOrgFinancialAccess || !allLibraryAccess}
                  disabledText={
                    !allLibraryAccess
                      ? "Upgrade your subscription"
                      : "Fincancial configuration library access needed"
                  }
                />
                <DataLibraryImage
                  onClick={() => setSelectedTab("datalayers")}
                  disabled={!hasOrgDataLayersAccess || !allLibraryAccess}
                  disabledText={
                    !allLibraryAccess
                      ? "Upgrade your subscription"
                      : "Data packages library access needed"
                  }
                />
              </>
            )}
          </Row>
        </Column>
      </>
    );
  },
  ErrorBoundaryWarningTriangle,
  ScreamOnError,
);

export default Library;
