import * as Sentry from "@sentry/browser";

export type SentryExtras = Parameters<typeof Sentry.captureEvent>[0]["extra"];

export function sendInfo(msgOrError: Error, extra?: SentryExtras): Error;
export function sendInfo(msgOrError: string, extra?: SentryExtras): Error;
export function sendInfo(
  msgOrError: Error | string,
  extra?: SentryExtras,
): Error {
  const error =
    msgOrError instanceof Error ? msgOrError : new Error(msgOrError);
  Sentry.captureEvent({
    message: error.message ?? error.name,
    level: "info",
    extra,
  });
  console.info(error, extra);
  return error;
}

export function sendWarning(msgOrError: Error, extra?: SentryExtras): Error;
export function sendWarning(msgOrError: string, extra?: SentryExtras): Error;
export function sendWarning(
  msgOrError: Error | string,
  extra?: SentryExtras,
): Error {
  const error =
    msgOrError instanceof Error ? msgOrError : new Error(msgOrError);
  Sentry.captureEvent({
    message: error.message ?? error.name,
    level: "warning",
    extra,
  });
  console.warn(error, extra);
  return error;
}

export function scream(msgOrError: Error, extra?: SentryExtras): Error;
export function scream(msgOrError: string, extra?: SentryExtras): Error;
export function scream(
  msgOrError: Error | string,
  extra: SentryExtras = {},
): Error {
  const errorToUse =
    msgOrError instanceof Error ? msgOrError : new Error(msgOrError);
  console.error(errorToUse, extra);
  Sentry.captureException(errorToUse, { extra });
  return errorToUse;
}
