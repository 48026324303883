import { atomFamily, selectorFamily } from "recoil";
import { replaceEmpty } from "./recoil";
import { turbineOverlapWarningSelectorFamily } from "components/ValidationWarnings/TurbineOverlap";
import { offshoreSubstationOutsideParkCableCorridorWarningSelectorFamily } from "components/ValidationWarnings/SubstationOutsideParkCableCorridor";
import { turbineOutsideParkWarningSelectorFamily } from "components/ValidationWarnings/TurbineOutsidePark";
import { zoneOverlapWarningSelectorFamily } from "components/ValidationWarnings/OverlappingZones";
import { differentExportVoltageTypesSelectorFamily } from "components/ValidationWarnings/DifferentExportVoltageTypes";
import { differentIAVoltageTypesSelectorFamily } from "components/ValidationWarnings/DifferentIAVoltageTypes";
import { turbineCableWrongVoltageTypeSelectorFamily } from "components/ValidationWarnings/TurbineCableWrongVoltageType";
import { turbinesNotConnectedSelectorFamily } from "components/ValidationWarnings/TurbineNotConnected";
import { foundationTypeInvalidWarningSelector } from "components/ValidationWarnings/FoundationTypeInvalid";
import { cableTypeInvalidWarningSelector } from "components/ValidationWarnings/CableTypeInvalid";
import { turbineTypeInvalidWarningSelector } from "components/ValidationWarnings/TurbineTypeInvalid";
import { overlappingParksWarningSelector } from "components/ValidationWarnings/OverlappingParks";
import { substationOverlapWarningSelectorFamily } from "components/ValidationWarnings/SubstationOverlap";
import { tooShortCablesWarningSelectorFamily } from "components/ValidationWarnings/TooShortCables";
import { substationNotConnectedWarningSelectorFamily } from "components/ValidationWarnings/SubstationNotConnected";
import { exportCableNotConnectedWarningSelectorFamily } from "components/ValidationWarnings/ExportCableNotConnected";
import { maxSizeExceededWarningSelector } from "components/ValidationWarnings/MaxSizeExceeded";
import { exportCableLandFallFailedWarningSelectorFamily } from "components/ValidationWarnings/ExportCableLandfallFailed";
import { cableCorridorNotConnectedToParkWarningSelectorFamily } from "components/ValidationWarnings/CableCorridorNotConnectedToPark";
import { subAreaOutsideParkWarningSelectorFamily } from "components/ValidationWarnings/SubAreasOutsidePark";
import { turbineAndMonopileSelectorFamily } from "components/ValidationWarnings/TurbineAndMonopileTypeError";
import { substationInsideNoCableExclusionZoneWarningSelectorFamily } from "components/ValidationWarnings/SubstationInsideNoCableExclusionZone";
import {
  cablesInsideNoCablesExclusionZoneWarningSelectorFamily,
  substationInsideNoSubstationExclusionZoneWarningSelectorFamily,
  turbinesInsideNoTurbinesExclusionZoneWarningSelectorFamily,
} from "components/ValidationWarnings/ExclusionZoneContainingExcludedFeature";
import { holeInParkWarningSelector } from "components/ValidationWarnings/HoleInPark";
import { SplitAtLandfallPointErr } from "functions/elevation";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { cableIntersectsSectorSelectorFamily } from "components/ValidationWarnings/CableFreeSector";
import { cableLoopsSelectorFamily } from "components/ValidationWarnings/CableLoops";
import { existingTurbineOverlapWarningSelector } from "components/ValidationWarnings/ExistingTurbineOverlap";

export type ValidationWarningOrganisationStateType = {
  type: ValidationWarningTypes.FatalAblyLost;
  retryTimestamp?: number;
};

export type ValidationWarningStateType =
  | { type: ValidationWarningTypes.ProjectSize }
  | {
      type: ValidationWarningTypes.TurbineOverlap;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.ExistingTurbineOverlap;
      featureIds: string[];
    }
  | { type: ValidationWarningTypes.CableTypeInvalid; featureIds: string[] }
  | { type: ValidationWarningTypes.TurbineTypeInvalid; featureIds: string[] }
  | { type: ValidationWarningTypes.FoundationTypeInvalid; featureIds: string[] }
  | {
      type: ValidationWarningTypes.TurbineOutsidePark;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.TurbinesNotReachable;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.SubstationInsideNoCableExclusionZone;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.CableInsideNoCableExclusionZone;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.TurbineInsideNoTurbineExclusionZone;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.SubstationOverlap;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.SubstationInsideNoSubstationExclusionZone;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.CableCorridorNotConnectedToPark;
      featureIds: string[];
    }
  | {
      type: ValidationWarningTypes.OverlappingSubAreas;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.SubAreasOutsidePark;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.OverlappingParks;
      featureIds: string[];
    }
  | {
      type: ValidationWarningTypes.HoleInPark;
      featureIds: string[];
    }
  | {
      type: ValidationWarningTypes.CablesDifferentIAVoltageTypes;
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.CableDifferentExportVoltageTypes;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.TurbinesAndCablesErroneousIAVoltageTypes;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.TurbineNotConnected;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.SubstationNotConnected;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.SubstationMissingType;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.OffshoreSubstationOutsideParkCableCorridor;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.ExportCableNotConnected;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.ExportCableTooLongWarning;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.ExportCableTooLongError;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.TooShortCables;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.TurbineAndMonopileTypeError;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.CablesCross;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.ExportCableLandfallFailed;
      data: {
        exportCableId: string;
        error: SplitAtLandfallPointErr;
      }[];
      parkId: string;
    }
  | { type: ValidationWarningTypes.FatalGeneral }
  | {
      type: ValidationWarningTypes.AnchorsOutsidePark;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.ExistingTurbinesWithoutPower;
      featureIds: string[];
    }
  | {
      type: ValidationWarningTypes.ExportSystemInsufficientCapacity;
      detailsText: string;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.CableIntersectsSector;
      featureIds: string[];
    }
  | {
      type: ValidationWarningTypes.CableLoops;
      featureIds: string[];
    };

export const validationWarningsOrganisationAtom = atomFamily<
  ValidationWarningOrganisationStateType[],
  { organisationId: string }
>({
  key: "validationWarningsOrganisationAtom",
  default: () => [],
});
export const validationWarningsAtom = atomFamily<
  ValidationWarningStateType[],
  { projectId: string }
>({
  key: "validationWarningsAtom",
  default: () => [],
});

export const validationWarningsFromParkSelectorFamily = selectorFamily<
  ValidationWarningStateType[],
  { parkId: string; branchId: string }
>({
  key: "validationWarningsFromParkSelectorFamily",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      //Not park related warnings and errors
      const maxSizeExceededWarning = get(maxSizeExceededWarningSelector);
      const existingTurbineOverlapping = get(
        existingTurbineOverlapWarningSelector,
      );
      const foundationTypeWarning = get(
        foundationTypeInvalidWarningSelector({ branchId }),
      );
      const overlapParksWarning = get(
        overlappingParksWarningSelector({ branchId }),
      );
      const turbineTypeInvalidWarning = get(
        turbineTypeInvalidWarningSelector({ branchId }),
      );
      const cableTypeInvalidWarning = get(
        cableTypeInvalidWarningSelector({ branchId }),
      );
      //Park errors
      const turbineOverlapWarning = get(
        turbineOverlapWarningSelectorFamily({ parkId, branchId }),
      );
      const outsideOffshoreSubstationsWarning = get(
        offshoreSubstationOutsideParkCableCorridorWarningSelectorFamily({
          parkId,
          branchId,
        }),
      );
      const outsideTurbinesWarning = get(
        turbineOutsideParkWarningSelectorFamily({ parkId, branchId }),
      );
      const overlapZoneWarning = get(
        zoneOverlapWarningSelectorFamily({ parkId, branchId }),
      );

      //Park warnings:
      const holeInParksWarning = get(
        holeInParkWarningSelector({ parkId, branchId }),
      );
      const tooShortCableWarning = get(
        tooShortCablesWarningSelectorFamily({ parkId, branchId }),
      );
      const differentExportVoltageTypesWarning = get(
        differentExportVoltageTypesSelectorFamily({ parkId, branchId }),
      );
      const differentIAVoltageTypesWarning = get(
        differentIAVoltageTypesSelectorFamily({ parkId, branchId }),
      );
      const turbineCableWrongVoltageTypeWarning = get(
        turbineCableWrongVoltageTypeSelectorFamily({ parkId, branchId }),
      );
      const turbinesNotConnectedWarning = get(
        turbinesNotConnectedSelectorFamily({ parkId, branchId }),
      );
      const substationOverlapWarning = get(
        substationOverlapWarningSelectorFamily({ parkId, branchId }),
      );
      const substationInsideNoCableExclusionZoneWarning = get(
        substationInsideNoCableExclusionZoneWarningSelectorFamily({
          parkId,
          branchId,
        }),
      );
      const substationInsideNoSubstationExclusionZoneWarning = get(
        substationInsideNoSubstationExclusionZoneWarningSelectorFamily({
          parkId,
          branchId,
        }),
      );
      const turbinesInsideNoTurbinesExclusionZoneWarning = get(
        turbinesInsideNoTurbinesExclusionZoneWarningSelectorFamily({
          parkId,
          branchId,
        }),
      );
      const cablesInsideNoCablesExclusionZoneWarning = get(
        cablesInsideNoCablesExclusionZoneWarningSelectorFamily({
          parkId,
          branchId,
        }),
      );
      const exportCableNotConnectedWarning = get(
        exportCableNotConnectedWarningSelectorFamily({ parkId, branchId }),
      );
      const substationNotConnectedWarning = get(
        substationNotConnectedWarningSelectorFamily({ parkId, branchId }),
      );
      const exportCableLandFallFailedWarning = get(
        exportCableLandFallFailedWarningSelectorFamily({ parkId, branchId }),
      );
      const cableCorridorNotConnectedToParkWarning = get(
        cableCorridorNotConnectedToParkWarningSelectorFamily({
          parkId,
          branchId,
        }),
      );
      const zoneOutsideParkWarning = get(
        subAreaOutsideParkWarningSelectorFamily({ parkId, branchId }),
      );
      const turbineAndMonopileWarning = get(
        turbineAndMonopileSelectorFamily({ parkId, branchId }),
      );

      const cableIntersectsSector = get(
        cableIntersectsSectorSelectorFamily({ parkId, branchId }),
      );

      const cableLoops = get(cableLoopsSelectorFamily({ parkId, branchId }));

      const allWarningsAndErrors = [
        maxSizeExceededWarning,
        foundationTypeWarning,
        overlapParksWarning,
        cableTypeInvalidWarning,
        turbineTypeInvalidWarning,
        zoneOutsideParkWarning,
        cableCorridorNotConnectedToParkWarning,
        exportCableNotConnectedWarning,
        exportCableLandFallFailedWarning,
        substationNotConnectedWarning,
        tooShortCableWarning,
        substationOverlapWarning,
        substationInsideNoCableExclusionZoneWarning,
        substationInsideNoSubstationExclusionZoneWarning,
        turbinesInsideNoTurbinesExclusionZoneWarning,
        cablesInsideNoCablesExclusionZoneWarning,
        holeInParksWarning,
        turbineOverlapWarning,
        existingTurbineOverlapping,
        overlapZoneWarning,
        outsideOffshoreSubstationsWarning,
        differentIAVoltageTypesWarning,
        outsideTurbinesWarning,
        differentExportVoltageTypesWarning,
        turbineCableWrongVoltageTypeWarning,
        turbinesNotConnectedWarning,
        turbineAndMonopileWarning,
        cableIntersectsSector,
        cableLoops,
      ].filter(Boolean) as ValidationWarningStateType[];
      return allWarningsAndErrors;
    },
});

export const validationWarningsSelectorFamily = selectorFamily<
  (ValidationWarningOrganisationStateType | ValidationWarningStateType)[],
  { organisationId: string; projectId: string }
>({
  key: "validationWarningsSelectorFamily",
  get:
    ({ organisationId, projectId }) =>
    ({ get }) => {
      const warnings = get(validationWarningsAtom({ projectId }));
      const warningsOrg = get(
        validationWarningsOrganisationAtom({ organisationId }),
      );
      const validationWarnings = replaceEmpty([...warningsOrg, ...warnings]);
      return validationWarnings;
    },
});
