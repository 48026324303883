import React, { useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import Fuse from "fuse.js";
import { colors } from "styles/colors";
import { useRecoilValueDef } from "utils/recoil";
import { projectIdSelector } from "state/pathParams";
import CloseIcon from "@icons/24/Close.svg";
import SearchIcon from "@icons/24/Search.svg";
import { projectBranchesAtomFamily } from "state/timeline";
import useTextInput from "hooks/useTextInput";
import { BranchMeta } from "types/api";
import { MenuFrame } from "components/MenuPopup/CloseableMenuPopup";
import { spacing4, spacing6, spacing7, spacing8 } from "styles/space";
import { SearchInput } from "components/General/Input";
import { useHorizontalResize } from "components/ResizeBar/ResizeBarVertical";
import { IconREMSize, typography } from "styles/typography";
import { selectedParksAtom } from "../../state";
import { RoundIconWrapper } from "../../shared";
import BranchesTable from "./BranchesTable";

const ParkCounterPill = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem;
  gap: 1rem;
  border-radius: 20px;
  cursor: pointer;
  background-color: ${colors.surfaceSelectedLight};
`;

const NoResultsFound = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.blue50,
        textAlign: "center",
        padding: spacing7,
      }}
    >
      <RoundIconWrapper style={{ marginBottom: spacing6 }}>
        <IconREMSize height={1.6} width={1.6} stroke={colors.white}>
          <SearchIcon />
        </IconREMSize>
      </RoundIconWrapper>
      <p style={{ ...typography.sub2 }}>No results</p>
      <p style={{ ...typography.caption }}>
        We couldn't find any branches matching your search.
      </p>
    </div>
  );
};

const NumberSelectedParksPill = ({ nodeId }: { nodeId: string }) => {
  const [selectedParks, setSelectedParks] = useRecoilState(
    selectedParksAtom({ projectId: nodeId }),
  );

  if (selectedParks.length === 0) {
    return null;
  }

  return (
    <div style={{ display: "flex" }}>
      <ParkCounterPill
        onClick={() => {
          setSelectedParks([]);
        }}
        title="Clear all"
      >
        <div>
          {selectedParks.length} selected park
          {selectedParks.length !== 1 ? "s" : ""}
        </div>
        <CloseIcon style={{ width: "8px", height: "8px" }} />
      </ParkCounterPill>
    </div>
  );
};

const BranchProjectPicker = React.forwardRef<
  HTMLDivElement,
  { onExit(): void }
>(({ onExit }, elementRef) => {
  const nodeId = useRecoilValueDef(projectIdSelector);
  const branchMetaObjects = useRecoilValue(
    projectBranchesAtomFamily({ nodeId }),
  );
  useHorizontalResize(
    elementRef as React.RefObject<HTMLElement>,
    "--branch-projects-frame-width",
  );
  const [searchValue, onSearchValueChange, setSearchValue] = useTextInput("");
  const fuse = useMemo(
    () =>
      new Fuse(branchMetaObjects, {
        keys: ["title"],
        includeScore: true,
        threshold: 0.3,
      }),
    [branchMetaObjects],
  );

  const branchesSearchResult = useMemo<BranchMeta[]>(() => {
    return searchValue.length > 0
      ? fuse.search(searchValue).map((result) => result.item)
      : branchMetaObjects;
  }, [fuse, searchValue, branchMetaObjects]);

  return (
    <MenuFrame
      ref={elementRef}
      title="All branches"
      onExit={onExit}
      style={{
        position: "absolute",
        left: spacing4,
        overflow: "hidden",
        maxHeight: "70vh",
        width: "var(--branch-projects-frame-width, 60rem)",
        zIndex: 5,
        resize: "horizontal",
        minWidth: "40rem",
        maxWidth: "50vw",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: `0 ${spacing8}`,
          gap: spacing7,
          overflow: "hidden",
          position: "relative",
        }}
      >
        <SearchInput
          style={{ width: "100%" }}
          wrapperDivStyle={{ marginBottom: "" }}
          placeholder="Search for branch"
          value={searchValue}
          onChange={onSearchValueChange}
          onClear={() => {
            setSearchValue("");
          }}
        />
        <NumberSelectedParksPill nodeId={nodeId} />
        {searchValue !== "" && branchesSearchResult.length === 0 ? (
          <NoResultsFound />
        ) : (
          <BranchesTable branches={branchesSearchResult} />
        )}
      </div>
    </MenuFrame>
  );
});

export default BranchProjectPicker;
