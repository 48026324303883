/// <reference types="vite-plugin-svgr/client" />
import React, {
  Fragment,
  RefObject,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import FolderAdd from "@icons/16/FolderAdd.svg?react";
import AnimatedLogo from "@icons/animatedlogo/AnimatedLogo";
import { modalTypeOpenAtom } from "../../state/modal";
import { savingNewProjectAtom } from "../../state/project";
import {
  userNodeAccessSelectorFamily,
  userAllNodesAccessAtom,
  memberInOrganisationSelectorFamily,
} from "../../state/user";
import {
  TeamListItemPlaceholder,
  TeamsListWrapper,
  WrapperFullScreen,
} from "../Projects/styles";
import styled from "styled-components";
import ListViewIcon from "@icons/16/ListView.svg?react";
import SectionIcon from "@icons/24/Section.svg?react";
import {
  nodeIdSelector,
  organisationIdSelector,
  useTypedPath,
} from "../../state/pathParams";
import { colors } from "../../styles/colors";
import { SkeletonBlock } from "../Loading/Skeleton";
import {
  borderRadiusMedium,
  spaceHuge,
  spaceSmall,
  spacing9,
} from "../../styles/space";
import Button from "../General/Button";
import { Node } from "../../services/customerAPI";
import { Column, Row } from "../General/Layout";
import { MenuItem } from "../General/Menu";
import Tooltip from "../General/Tooltip";
import { ProjectGrid } from "../Projects/ProjectGrid";
import { ProjectListView } from "../Projects/ProjectListView";
import { EditableTextInternalState } from "../General/EditableText";
import { range } from "../../utils/utils";
import { organisationsState, selectedOrgTabState, _OrgTabName } from "./state";
import ResizeBarVertical from "components/ResizeBar/ResizeBarVertical";
import eventEmitter from "utils/eventEmitter";
import { useLocalStorage } from "../../hooks/useBrowserStorage";
import OrgSettingsModal from "./OrgSettingsModal";
import {
  nodesInOrganisationSelectorFamily,
  topLevelNodeFromOrgIdAndNodeIdSelectorFamily,
  useOrganisationNodeCrud,
} from "../Projects/useOrganisationFolderCrud";
import LowerRightHelpOnly from "../LowerRight/LowerRightHelp";
import { RefreshNotifier } from "./RefreshNotifier";
import FolderProjectList from "./FolderProjectList";
import { OrganisationPageBodyWrapper, Scrollable, TabHeader } from "./style";
import { UserNodesAccess } from "types/user";
import { useDndScrolling } from "react-dnd-scrolling";
import Portfolio from "./Portfolio/Portfolio";
import { useToast } from "hooks/useToast";
import OpenMoreInfo from "components/General/MoreInfoButton";
import OrganisationRightSide from "./OrganisationRightSide/OrganisationRightSide";
import { organisationRightSideModal } from "./OrganisationRightSide/state";
import Library from "./Library/Library";
import { ProjectPageTitleBar } from "./ProjectPageTitleBar";
import { libraryTabState } from "./Library/state";
import GoBack from "./Library/shared/GoBack";
import { tabToIcon, tabToName } from "./Library/shared/utils";
import { SVGWrapper } from "@icons/svgUtils";
import MoveNodeModal from "./MoveNodeModal/MoveNodeModal";
import Integration from "./Integration/IntegrationSettings";
import { LearnVindGrid } from "components/LearnVind/LearnVindGrid";
import SplitButton from "components/General/SplitButton";
import MembersTabs from "./Members/MembersTabs";
import useFolderId from "hooks/useFolderId";
import { DashText, FolderNavBar } from "components/Projects/ProjectGrid.style";
import { useDrop } from "react-dnd";
import { sendWarning } from "utils/sentry";
import { VerticalDivider } from "components/General/VerticalDivider";
import PencilIcon from "@icons/24/Pencil.svg";
import { IconREMSize } from "styles/typography";
import { NodeAccessList } from "components/Projects/NodeAccessList";

const FolderNavigation = ({ nodes }: { nodes: Node[] }) => {
  const { organisationId } = useTypedPath("organisationId");
  const folderId = useFolderId();
  const setContent = useSetRecoilState(
    organisationRightSideModal(organisationId ?? ""),
  );
  const [, setSelectedOrgTab] = useRecoilState(selectedOrgTabState);

  const location = useLocation();

  const selectedFolder = useMemo(
    () => nodes.find((f) => f.id === folderId),
    [nodes, folderId],
  );

  const parentChain = useMemo(() => {
    if (!selectedFolder) return [];
    const chain = [];
    let sel: Node = selectedFolder;
    while (sel) {
      const { parent_id } = sel;
      const parent = nodes.find((f) => f.id === parent_id);
      if (parent === sel) throw sendWarning("Loop in folder contains");
      if (parent) chain.push(parent);
      else break;
      sel = parent;
    }
    return chain.reverse();
  }, [nodes, selectedFolder]);

  return (
    <FolderNavBar>
      <Link
        to={`/organisation/${organisationId}`}
        style={{ textDecoration: "none" }}
        onClick={() => {
          setContent(undefined);
          setSelectedOrgTab("Projects");
        }}
      >
        <Button
          size={"small"}
          style={{ padding: 0 }}
          buttonType="text"
          text={"All projects"}
          onClick={() => {}}
        />
      </Link>
      {parentChain.map((node) => (
        <Fragment key={node.id}>
          <DashText>{" / "}</DashText>
          <Link
            key={node.id}
            style={{ textDecoration: "none" }}
            onClick={() => setContent({ type: "project", id: node.id })}
            to={`/organisation/${organisationId}/${node.id}${location.search}`}
          >
            <NavigationButton node={node} />
          </Link>
        </Fragment>
      ))}
    </FolderNavBar>
  );
};

function NavigationButton({ node }: { node: Node }) {
  const { move: moveNode } = useOrganisationNodeCrud();
  const [{ isOver }, dropRef] = useDrop({
    accept: ["PROJECT", "FOLDER"],
    drop: (item: { id: string; currentFolder?: Node }) => {
      if (item.currentFolder?.id === node.id) return;
      if (item.id === node.id) return;
      if (item.currentFolder) {
        moveNode(item.id, item.currentFolder.id);
      }
      moveNode(item.id, node.id);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div ref={dropRef} style={isOver ? { backgroundColor: colors.hover } : {}}>
      <Button
        size={"small"}
        style={{ padding: 0 }}
        buttonType="text"
        text={node.name}
        onClick={() => {}}
      />
    </div>
  );
}

const FullScreenNewProjectLoader = () => {
  const savingNewProject = useRecoilValue(savingNewProjectAtom);

  if (!savingNewProject) return null;

  return (
    <WrapperFullScreen>
      <AnimatedLogo />
      <h2>Creating new project...</h2>
    </WrapperFullScreen>
  );
};

const ProjectFolderListPlaceholder = () => (
  <>
    {range(0, 5).map((i) => (
      <TeamListItemPlaceholder key={i}>
        <SkeletonBlock style={{ height: "2rem", width: "100%" }} />
      </TeamListItemPlaceholder>
    ))}
  </>
);

const TeamsList = ({ organisationId }: { organisationId: string }) => {
  const elementRef = useRef<HTMLDivElement>(null);
  useDndScrolling(elementRef, {});

  return (
    <>
      <Scrollable ref={elementRef}>
        <FolderProjectList organisationId={organisationId} />
      </Scrollable>
    </>
  );
};

const IconWrapper = styled.div<{ selected?: boolean }>`
  &:hover {
    background: ${colors.surfaceHover};
    cursor: pointer;
  }

  background: ${(p) => p.selected && colors.surfaceSelectedLight};
  border-radius: ${borderRadiusMedium};
  padding: ${spaceSmall};

  display: flex;
  flex-direction: column;

  svg {
    height: 1.6rem;
    width: 1.6rem;
  }
`;

const ProjectGridOrList = ({
  toplevelNodeId,
  organisationId,
}: {
  toplevelNodeId: string;
  organisationId: string;
}) => {
  const isOrganisationLevel = organisationId === toplevelNodeId;
  const isMemberInOrg = useRecoilValue(
    memberInOrganisationSelectorFamily({ organisationId }),
  );

  const nodeId = useRecoilValue(nodeIdSelector);
  const nodeAccess = useRecoilValue(userNodeAccessSelectorFamily({ nodeId }));
  const isAdminInNode = nodeAccess >= 2;

  const canCreateProjectHere =
    !isOrganisationLevel && isMemberInOrg && isAdminInNode;

  const canCreateFolderHere = isMemberInOrg && isOrganisationLevel;
  const navigate = useNavigate();
  const location = useLocation();

  const roleAsString = "admin";

  const {
    createV2,
    createTopLevelFolderNode,
    create: createNodeOrg,
  } = useOrganisationNodeCrud();

  const [allNodesAccess, setAllNodesAccess] = useRecoilState(
    userAllNodesAccessAtom,
  );
  const [folderLoading, setFolderLoading] = useState(false);
  const [, setDidCreate] = useState(false);

  const createAndEnterProject = useCallback(async () => {
    if (!nodeId) return;
    setDidCreate(true);
    const res = await createV2({
      name: "Untitled project",
      parent_id: nodeId,
      type: "project",
    });
    if (res) {
      const newAllNodesAccess = {
        organisation_access: allNodesAccess["organisation_access"],
        node_access: {
          ...allNodesAccess["node_access"],
          [res.id]: roleAsString,
        },
      } as UserNodesAccess;
      setAllNodesAccess(newAllNodesAccess);
      navigate(`/design/project/${organisationId}/${res.id}${location.search}`);
    }
  }, [
    nodeId,
    createV2,
    allNodesAccess,
    roleAsString,
    setAllNodesAccess,
    navigate,
    organisationId,
    location.search,
  ]);

  const createAndEnterFolder = useCallback(async () => {
    if (isOrganisationLevel) {
      const parentId = organisationId;
      if (!parentId) return;
      setFolderLoading(true);
      createTopLevelFolderNode("New folder")
        .then((nodeId) => {
          if (nodeId) {
            const newAllNodesAccess = {
              organisation_access: allNodesAccess["organisation_access"],
              node_access: {
                ...allNodesAccess["node_access"],
                [nodeId]: roleAsString,
              },
            } as UserNodesAccess;
            setAllNodesAccess(newAllNodesAccess);
          }
        })
        .finally(() => setFolderLoading(false));
    } else {
      const parentId = nodeId;
      if (!parentId) return;
      setFolderLoading(true);
      createNodeOrg({
        name: "New folder",
        parent_id: parentId,
        type: "folder",
      })
        .then((res) => {
          if (res) {
            const newAllNodesAccess = {
              organisation_access: allNodesAccess["organisation_access"],
              node_access: {
                ...allNodesAccess["node_access"],
                [res.id]: roleAsString,
              },
            } as UserNodesAccess;
            setAllNodesAccess(newAllNodesAccess);
          }
        })
        .finally(() => setFolderLoading(false));
    }
  }, [
    isOrganisationLevel,
    organisationId,
    setFolderLoading,
    createTopLevelFolderNode,
    createNodeOrg,
    allNodesAccess,
    setAllNodesAccess,
    nodeId,
  ]);

  const [tab, setTab] = useLocalStorage("vind:selected-project-tab", 0);
  const [content, setContent] = useRecoilState(
    organisationRightSideModal(organisationId),
  );

  const nodes = useRecoilValue(
    nodesInOrganisationSelectorFamily({ organisationId }),
  );

  const chosenNode = useMemo(
    () => nodes.find((n) => n.id === nodeId),
    [nodes, nodeId],
  );

  useEffect(() => {
    setContent((cur) => (cur?.type === "project" ? cur : undefined));
  }, [setContent]);

  return (
    <>
      {!chosenNode && (
        <>
          <Row style={{ justifyContent: "space-between" }}>
            <TabHeader>All projects</TabHeader>
            <Row>
              {canCreateFolderHere && (
                <Tooltip text="Create a new folder">
                  <Button
                    buttonType="secondary"
                    icon={<FolderAdd />}
                    disabled={folderLoading}
                    onClick={createAndEnterFolder}
                  />
                </Tooltip>
              )}
              <VerticalDivider height={3} />
              {isMemberInOrg && (
                <OpenMoreInfo
                  onClick={() => {
                    setContent({
                      type: "empty",
                    });
                  }}
                  isOpen={!!content}
                />
              )}
            </Row>
          </Row>
        </>
      )}
      <Column
        style={{
          position: "relative",
          gap: spacing9,
        }}
      >
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            paddingTop: "0.6rem",
          }}
        >
          <Row
            onClick={
              isMemberInOrg && nodeId
                ? () => setContent({ type: "project", id: nodeId })
                : undefined
            }
          >
            {nodeId && (
              <>
                <NodeAccessList
                  maxNumberOfUsers={6}
                  nodeId={nodeId}
                  organisationId={organisationId}
                />
                <Button
                  buttonType="secondary"
                  size={"small"}
                  icon={
                    <IconREMSize height={1.4} width={1.4}>
                      <PencilIcon />
                    </IconREMSize>
                  }
                />
              </>
            )}
          </Row>
          <Row>
            <Tooltip text="Grid view">
              <IconWrapper
                id="button-project-view-grid"
                selected={tab === 0}
                onClick={() => {
                  setTab(0);
                }}
              >
                <SectionIcon />
              </IconWrapper>
            </Tooltip>
            <Tooltip text="List view">
              <IconWrapper
                id="button-project-view-list"
                selected={tab === 1}
                onClick={() => {
                  setTab(1);
                }}
              >
                <ListViewIcon />
              </IconWrapper>
            </Tooltip>
          </Row>
        </Row>
        <Column
          style={{
            position: "relative",
            maxHeight: chosenNode ? "75vh" : "78vh",
            overflowY: "scroll",
          }}
        >
          {tab === 0 ? (
            <ProjectGrid
              createAndEnterProject={createAndEnterProject}
              canCreateProjectHere={canCreateProjectHere}
            />
          ) : (
            <ProjectListView />
          )}
        </Column>
        <RefreshNotifier toplevelNodeId={toplevelNodeId} />
      </Column>
    </>
  );
};

export const CustomerBodyPlaceholder = () => (
  <Column style={{ minHeight: 0 }}>
    <Row style={{ padding: spaceHuge }}>
      <SkeletonBlock style={{ height: "2rem", width: "20rem" }} />
    </Row>
  </Column>
);

export const OrganisationBody = () => {
  const organisationId = useRecoilValue(organisationIdSelector);
  const selectedTab = useRecoilValue(
    libraryTabState({ organisationId: organisationId ?? "" }),
  );
  const [selectedOrgTab] = useRecoilState(selectedOrgTabState);

  return (
    <Column
      style={{
        minHeight: 0,
        padding: spacing9,
        paddingBottom: 0,
        gap: spacing9,
        overflow: "hidden",
      }}
    >
      {!!selectedTab && <LibraryHeader />}
      <div style={{ overflow: "hidden", height: "100%" }}>
        {(selectedOrgTab === "Projects" || !selectedOrgTab) &&
          organisationId && (
            <ProjectGridOrList
              toplevelNodeId={organisationId!}
              organisationId={organisationId}
            />
          )}
        {selectedOrgTab === "Library" && <Library />}
        {selectedOrgTab === "Members" && <MembersTabs />}
        {selectedOrgTab === "Learn" && <LearnVindGrid />}
        {selectedOrgTab === "Portfolio" && (
          <Portfolio toplevelNodeId={organisationId!} />
        )}
        {selectedOrgTab === "Integration" && <Integration />}
      </div>
      <LowerRightHelpOnly />
    </Column>
  );
};

const LibraryHeader = () => {
  const organisationId = useRecoilValue(organisationIdSelector);

  const [selectedTab, setSelectedTab] = useRecoilState(
    libraryTabState({ organisationId: organisationId ?? "" }),
  );

  return (
    <Column style={{ gap: "2rem" }}>
      <GoBack onClick={() => setSelectedTab(undefined)} />
      <Row style={{ alignItems: "center" }}>
        <SVGWrapper size={2}>{tabToIcon(selectedTab)}</SVGWrapper>
        <h1 style={{ margin: 0 }}>{tabToName(selectedTab)}</h1>
      </Row>
    </Column>
  );
};

export const CustomerBody = () => {
  const organisationId = useRecoilValue(organisationIdSelector);
  const nodeId = useRecoilValue(nodeIdSelector);
  const isMemberInOrg = useRecoilValue(
    memberInOrganisationSelectorFamily({ organisationId: organisationId }),
  );
  const topLevelFolder = useRecoilValue(
    topLevelNodeFromOrgIdAndNodeIdSelectorFamily({
      organisationId,
      nodeId: nodeId,
    }),
  );
  const [content, setContent] = useRecoilState(
    organisationRightSideModal(organisationId ?? ""),
  );
  const nodes = useRecoilValue(
    nodesInOrganisationSelectorFamily({ organisationId }),
  );
  const chosenNode = useMemo(
    () => nodes.find((n) => n.id === nodeId),
    [nodes, nodeId],
  );

  const nodeAccess = useRecoilValue(userNodeAccessSelectorFamily({ nodeId }));
  const isNodeAdmin = nodeAccess >= 2;

  const navigate = useNavigate();
  const location = useLocation();

  const roleAsString = "admin";

  const {
    create: createNodeOrg,
    createV2,
    createTutorialProject,
    createTopLevelFolderNode,
  } = useOrganisationNodeCrud();

  const [folderLoading, setFolderLoading] = useState(false);

  const [allNodesAccess, setAllNodesAccess] = useRecoilState(
    userAllNodesAccessAtom,
  );

  const isOrganisationLevel = organisationId === topLevelFolder;

  const canCreateFolderHere =
    isMemberInOrg &&
    (isOrganisationLevel || (!isOrganisationLevel && isNodeAdmin));
  const canCreateProjectHere =
    !isOrganisationLevel && isMemberInOrg && isNodeAdmin;

  const [didCreate, setDidCreate] = useState(false);

  const createAndEnterProject = useCallback(async () => {
    if (!nodeId) return;
    setDidCreate(true);
    const res = await createV2({
      name: "Untitled project",
      parent_id: nodeId,
      type: "project",
    });
    if (res) {
      const newAllNodesAccess = {
        organisation_access: allNodesAccess["organisation_access"],
        node_access: {
          ...allNodesAccess["node_access"],
          [res.id]: roleAsString,
        },
      } as UserNodesAccess;
      setAllNodesAccess(newAllNodesAccess);
      navigate(`/design/project/${organisationId}/${res.id}${location.search}`);
    }
  }, [
    nodeId,
    createV2,
    allNodesAccess,
    roleAsString,
    setAllNodesAccess,
    navigate,
    organisationId,
    location.search,
  ]);

  const createAndEnterTutorialProject = useCallback(async () => {
    if (!nodeId) return;
    setDidCreate(true);
    const res = await createTutorialProject(nodeId);
    if (res) {
      const newAllNodesAccess = {
        organisation_access: allNodesAccess["organisation_access"],
        node_access: {
          ...allNodesAccess["node_access"],
          [res.id]: roleAsString,
        },
      } as UserNodesAccess;
      setAllNodesAccess(newAllNodesAccess);
      navigate(`/design/project/${organisationId}/${res.id}${location.search}`);
    }
  }, [
    nodeId,
    createTutorialProject,
    allNodesAccess,
    roleAsString,
    setAllNodesAccess,
    navigate,
    organisationId,
    location.search,
  ]);

  const createAndEnterFolder = useCallback(async () => {
    if (isOrganisationLevel) {
      const parentId = organisationId;
      if (!parentId) return;
      setFolderLoading(true);
      createTopLevelFolderNode("New folder")
        .then((nodeId) => {
          if (nodeId) {
            const newAllNodesAccess = {
              organisation_access: allNodesAccess["organisation_access"],
              node_access: {
                ...allNodesAccess["node_access"],
                [nodeId]: roleAsString,
              },
            } as UserNodesAccess;
            setAllNodesAccess(newAllNodesAccess);
          }
        })
        .finally(() => setFolderLoading(false));
    } else {
      const parentId = nodeId;
      if (!parentId) return;
      setFolderLoading(true);
      createNodeOrg({
        name: "New folder",
        parent_id: parentId,
        type: "folder",
      })
        .then((res) => {
          if (res) {
            const newAllNodesAccess = {
              organisation_access: allNodesAccess["organisation_access"],
              node_access: {
                ...allNodesAccess["node_access"],
                [res.id]: roleAsString,
              },
            } as UserNodesAccess;
            setAllNodesAccess(newAllNodesAccess);
          }
        })
        .finally(() => setFolderLoading(false));
    }
  }, [
    isOrganisationLevel,
    organisationId,
    setFolderLoading,
    createTopLevelFolderNode,
    createNodeOrg,
    allNodesAccess,
    setAllNodesAccess,
    nodeId,
  ]);

  const { error } = useToast();

  const { update } = useOrganisationNodeCrud();

  const isPersonalFolder = chosenNode?.type === "personal_folder";
  return (
    <Column
      style={{
        minHeight: 0,
        padding: spacing9,
        paddingBottom: 0,
        gap: "0.4rem",
      }}
    >
      <FolderNavigation nodes={nodes} />
      <Row>
        <div style={{ marginRight: "auto", alignSelf: "flex-end" }}>
          {isNodeAdmin ? (
            <EditableTextInternalState
              value={chosenNode?.name}
              renderText={(text) => <h2 style={{ margin: 0 }}>{text}</h2>}
              disabled={isPersonalFolder}
              onEnter={(newName) => {
                if (!chosenNode) return;
                update(chosenNode.id, {
                  ...chosenNode,
                  name: newName,
                }).catch(() => error("Failed to update team name"));
              }}
            />
          ) : (
            <h2 style={{ margin: 0 }}>{chosenNode?.name}</h2>
          )}
        </div>
        <Row style={{ alignItems: "center" }}>
          {canCreateFolderHere && (
            <Tooltip text="Create a new folder">
              <Button
                buttonType="secondary"
                icon={<FolderAdd />}
                disabled={folderLoading}
                onClick={createAndEnterFolder}
              />
            </Tooltip>
          )}
          {canCreateProjectHere && (
            <>
              {isPersonalFolder ? (
                <SplitButton
                  text={"New project"}
                  onClick={createAndEnterProject}
                  disabled={didCreate}
                  skeleton={didCreate}
                >
                  <MenuItem
                    name={"New project"}
                    onClick={createAndEnterProject}
                    disabled={didCreate}
                    description={"Create a regular project."}
                    defaultButton
                  />
                  <MenuItem
                    name={"New tutorial project"}
                    onClick={createAndEnterTutorialProject}
                    disabled={didCreate}
                    description={
                      "Create a tutorial project with a guided tour."
                    }
                  />
                </SplitButton>
              ) : (
                <Button
                  text={"New project"}
                  onClick={createAndEnterProject}
                  disabled={didCreate}
                  skeleton={didCreate}
                />
              )}
              <VerticalDivider height={3} />
            </>
          )}
        </Row>
        {isMemberInOrg && nodeId && (
          <OpenMoreInfo
            isOpen={!!content}
            onClick={() => {
              setContent((cur) => {
                if (cur && cur.id === nodeId) return undefined;
                return {
                  type: "project",
                  id: nodeId,
                };
              });
            }}
          />
        )}
      </Row>
      {topLevelFolder?.id && organisationId && (
        <ProjectGridOrList
          toplevelNodeId={topLevelFolder.id}
          organisationId={organisationId}
        />
      )}
      <LowerRightHelpOnly />
    </Column>
  );
};

const OrganisationPageBody = () => {
  const gridRef = useRef<HTMLDivElement>(null);
  const organisationId = useRecoilValue(organisationIdSelector);

  const content = useRecoilValue(
    organisationRightSideModal(organisationId ?? ""),
  );

  const selectedTab = useRecoilValue(
    libraryTabState({ organisationId: organisationId ?? "" }),
  );

  const isMemberInOrg = useRecoilValue(
    memberInOrganisationSelectorFamily({ organisationId: organisationId }),
  );

  return (
    <>
      <OrganisationPageBodyWrapper
        ref={gridRef}
        style={{
          gridTemplateColumns: `${selectedTab ? "" : "var(--organisation-page-left-menu-width, 25rem)"} 1fr ${
            isMemberInOrg && content ? "45rem" : "0rem"
          }`,
        }}
      >
        {!selectedTab && <TeamList gridRef={gridRef} />}
        <Outlet />
        {organisationId && isMemberInOrg && <OrganisationRightSide />}
      </OrganisationPageBodyWrapper>
      <MoveNodeModal />
      <OrgSettingsModal />
    </>
  );
};

const TeamList = ({ gridRef }: { gridRef: RefObject<HTMLDivElement> }) => {
  const organisations = useRecoilValue(organisationsState);
  const elementRef = useRef<HTMLDivElement>(null);
  const organisationId = useRecoilValue(organisationIdSelector);
  const location = useLocation();
  const navigate = useNavigate();
  const [lastVisitedOrg, setLastVisitedOrg] = useLocalStorage<string>(
    "vind:last-visited-organisation",
  );

  const currentOrg = organisations.find((org) => org.id === organisationId);

  useEffect(() => {
    if (organisationId) {
      setLastVisitedOrg(organisationId);
    }
  }, [organisationId, setLastVisitedOrg]);

  useEffect(() => {
    if (!organisationId) {
      const firstOrg = lastVisitedOrg ? lastVisitedOrg : organisations[0]?.id;
      if (firstOrg) {
        const newPath = `/organisation/${firstOrg}${location.search}`;
        navigate(newPath);
      }
    } else {
      const invalidOrgId = !organisations.some(
        (org) => org.id === organisationId,
      );
      if (invalidOrgId) {
        const firstOrgId = organisations[0]?.id;
        if (firstOrgId) {
          const newPath = `/organisation/${firstOrgId}${location.search}`;
          navigate(newPath);
        } else {
          // user has no organisation
          navigate("/no-access");
        }
      }
    }
  }, [
    lastVisitedOrg,
    location.search,
    navigate,
    organisationId,
    organisations,
  ]);

  const onBeforeResize = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.style.transition = "unset";
    }
  }, [gridRef]);

  const onAfterResize = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.style.transition = "";
    }
    eventEmitter.emit("organisation-left-menu-resize");
  }, [gridRef]);

  return (
    <TeamsListWrapper ref={elementRef}>
      {currentOrg && (
        <Suspense fallback={<ProjectFolderListPlaceholder />}>
          {organisationId && <TeamsList organisationId={currentOrg.id} />}
        </Suspense>
      )}
      <ResizeBarVertical
        resizeElemRef={elementRef}
        barSide="RIGHT"
        style={{ position: "absolute", right: "-8px", top: 0 }}
        cssVariableToUpdate="--organisation-page-left-menu-width"
        onBeforeResize={onBeforeResize}
        onAfterResize={onAfterResize}
      />
    </TeamsListWrapper>
  );
};

const OrganisationPage = () => {
  const setOpenModal = useSetRecoilState(modalTypeOpenAtom);
  useEffect(() => {
    setOpenModal(undefined);
  }, [setOpenModal]);

  return (
    <>
      <Column
        style={{
          height: "100vh",
          gap: 0,
        }}
      >
        <ProjectPageTitleBar />
        <OrganisationPageBody />
      </Column>
      <FullScreenNewProjectLoader />
    </>
  );
};

export default OrganisationPage;
