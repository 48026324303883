import { ProjectFeature } from "../../types/feature";
import { ProjectElementFolderType } from "./service";

export type ElementTreeFolder = {
  type: "folder";
  id: string;
  folder: ProjectElementFolderType;
  children: ElementTreeNode[];
  parent: ElementTreeFolder | ElementTreeRoot;
};

/**
 * Free feature in the elements tree, meaning a feature that's not in a park.
 */
export type ElementTreeFeature = {
  type: "feature";
  id: string;
  feature: ProjectFeature;
  parent: ElementTreeFolder | ElementTreeRoot;
};

/**
 * The root of the tree.  Used so that we can walk up the tree
 * and get the ordering of elements at the top level, using `.parent`.
 */
export type ElementTreeRoot = {
  type: "root";
  children: ElementTreeNode[];
};

export const isFolder = (
  el: ElementTreeNode | ElementTreeRoot,
): el is ElementTreeFolder => el.type === "folder";

/**
 * Tree structure for project elements.
 */
export type ElementTreeNode = ElementTreeFolder | ElementTreeFeature;

export type DropCollectedProps = {
  isHovered: boolean;
};

export type DropOnFeatureResults =
  | {
      handled: boolean;
      droppedOnIndex?: number;
      cursorPosition?: "top" | "bottom";
    }
  | undefined;
