import md5 from "md5";
import { sendWarning } from "./sentry";
import { SourceTypesLayer } from "../types/layers";

export const getExternalLayerId = (
  sourceUrl: string,
  layerName: string | undefined | null,
  sourceType: SourceTypesLayer,
  extraForLogging?: any,
  salt?: string,
): string => {
  if (!layerName || layerName === "") {
    sendWarning("LayerName is falsy", {
      sourceType,
      sourceUrl,
      ...extraForLogging,
    });
    layerName = "undefined";
  }
  const sourceForId = [sourceUrl, sourceType, layerName, salt]
    .filter(Boolean)
    .join("::");
  return md5(sourceForId.replaceAll(" ", "").trim());
};
