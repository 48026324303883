import { RenderAnchors } from "components/Mapbox/Anchors";
import { RenderCableCorridor } from "components/Mapbox/CableCorridors";
import { RenderCables } from "./Cables";
import { RenderDivisions } from "components/Mapbox/Divisions";
import { RenderExportCables } from "components/Mapbox/ExportCables";
import { RenderMooringLines } from "components/Mapbox/MooringLines";
import { RenderOtherLineStrings } from "components/Mapbox/OtherLineStrings";
import { RenderOtherPoints } from "components/Mapbox/OtherPoints";
import { RenderOtherPolygons } from "components/Mapbox/OtherPolygons";
import { RenderParks } from "components/Mapbox/Parks";
import { RenderSubstations } from "components/Mapbox/Substations";
import {
  RenderTurbineDiameterCircle,
  RenderTurbineEllipsis,
  RenderTurbines,
} from "components/Mapbox/Turbines";
import { projectFeaturesSelector } from "components/ProjectElements/state";
import { Map } from "mapbox-gl";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { allDivisionFeaturesSelectorFamily } from "state/division";
import {
  anchorsToShowSelector,
  mooringLinesToShowSelector,
  turbinesToShowSelector,
} from "state/layout";
import {
  canvasLayerFeatureHiddenAtomFamily,
  canvasLayerLineFeaturesSelector,
  canvasLayerPointFeaturesSelector,
  canvasLayerPolygonFeaturesSelector,
  existingTurbinesFeaturesSelector,
} from "state/projectLayers";
import { DivisionFeature, ProjectFeature } from "types/feature";
import {
  isCable,
  isCableCorridor,
  isExportCable,
  isPark,
  isSubstation,
} from "utils/predicates";
import { partition } from "utils/utils";
import { RenderExistingTurbines } from "./ExistingTurbines";
import { branchIdSelector } from "state/pathParams";
import { showCableTypeColorInMapAtom } from "state/electrical";
import {
  EllipsesFeature,
  ellipsesPerTurbineAtom,
} from "components/TurbineEllipsesSettings/state";
import { CableFreeSectorWhenEditing } from "components/CableFreeSector/CableFreeSectors";

export const FeatureLayers = ({ map }: { map: Map }) => {
  const branchId = useRecoilValue(branchIdSelector);
  const hidden = useRecoilValue(
    canvasLayerFeatureHiddenAtomFamily({ branchId }),
  );

  const isVisible = useMemo(
    () => (f: ProjectFeature) => !hidden.includes(f.id),
    [hidden],
  );

  const features = useRecoilValue(projectFeaturesSelector);
  const [parks, other0] = partition(features, isPark);
  const turbines = useRecoilValue(turbinesToShowSelector);
  const existingTurbines = useRecoilValue(existingTurbinesFeaturesSelector);
  const mooringLines = useRecoilValue(mooringLinesToShowSelector);
  const anchors = useRecoilValue(anchorsToShowSelector);
  const turbineEllipses = useRecoilValue(ellipsesPerTurbineAtom);
  const [substations, other5] = partition(other0, isSubstation);
  const [exportCables, other6] = partition(other5, isExportCable);
  const [cableCorridors, other7] = partition(other6, isCableCorridor);
  const [cables] = partition(other7, isCable);

  const { subAreas, exclusionZones } = useRecoilValue(
    allDivisionFeaturesSelectorFamily,
  );
  const divisions = useMemo(() => {
    const ret: DivisionFeature[] = subAreas;
    return ret.concat(exclusionZones);
  }, [exclusionZones, subAreas]);

  const visibleEllipsis: Record<string, EllipsesFeature> = useMemo(() => {
    if (!turbineEllipses) {
      return {};
    }
    return Object.fromEntries(
      Object.entries(turbineEllipses).filter(
        ([_, ellipsis]) => (ellipsis as EllipsesFeature).show,
      ),
    );
  }, [turbineEllipses]);

  const otherPointFeatures = useRecoilValue(canvasLayerPointFeaturesSelector);
  const otherLineStringFeatures = useRecoilValue(
    canvasLayerLineFeaturesSelector,
  );
  const otherPolygonFeatures = useRecoilValue(
    canvasLayerPolygonFeaturesSelector,
  );

  const visibleParks = useMemo(
    () => parks.filter(isVisible),
    [parks, isVisible],
  );
  const visibleTurbines = useMemo(
    () => turbines.filter(isVisible),
    [turbines, isVisible],
  );

  const visibleExistingTurbines = useMemo(
    () => existingTurbines.filter(isVisible),
    [existingTurbines, isVisible],
  );
  const visibleDivisinons = useMemo(
    () => divisions.filter(isVisible),
    [divisions, isVisible],
  );
  const visibleMooringLines = useMemo(
    () => mooringLines.filter(isVisible),
    [mooringLines, isVisible],
  );
  const visibleAnchors = useMemo(
    () => anchors.filter(isVisible),
    [anchors, isVisible],
  );
  const visibleSubstations = useMemo(
    () => substations.filter(isVisible),
    [substations, isVisible],
  );
  const visibleExportCables = useMemo(
    () => exportCables.filter(isVisible),
    [exportCables, isVisible],
  );
  const visibleCableCorridors = useMemo(
    () => cableCorridors.filter(isVisible),
    [cableCorridors, isVisible],
  );
  const visibleCables = useMemo(
    () => cables.filter(isVisible),
    [cables, isVisible],
  );
  const visibleOtherPointFeatures = useMemo(
    () => otherPointFeatures.filter(isVisible),
    [otherPointFeatures, isVisible],
  );
  const visibleOtherLineStringFeatures = useMemo(
    () => otherLineStringFeatures.filter(isVisible),
    [otherLineStringFeatures, isVisible],
  );
  const visibleOtherPolygonFeatures = useMemo(
    () => otherPolygonFeatures.filter(isVisible),
    [otherPolygonFeatures, isVisible],
  );
  const showCableTypeColorInMap = useRecoilValue(showCableTypeColorInMapAtom);

  return (
    <>
      <RenderParks map={map} parks={visibleParks} />
      <RenderTurbines map={map} turbines={visibleTurbines} />
      <RenderTurbineDiameterCircle map={map} turbines={visibleTurbines} />
      <RenderTurbineEllipsis
        map={map}
        turbines={visibleTurbines}
        visibleEllipses={visibleEllipsis as Record<string, EllipsesFeature>}
      />
      <RenderDivisions map={map} divisions={visibleDivisinons} />
      <RenderMooringLines map={map} mooringLines={visibleMooringLines} />
      <RenderAnchors map={map} anchors={visibleAnchors} />
      <RenderExistingTurbines map={map} turbines={visibleExistingTurbines} />
      <RenderSubstations map={map} substations={visibleSubstations} symbols />
      <RenderExportCables map={map} exportCables={visibleExportCables} />
      <RenderCableCorridor map={map} cableCorridors={visibleCableCorridors} />
      <RenderCables
        map={map}
        cables={visibleCables}
        oneColor={!showCableTypeColorInMap}
      />
      <RenderOtherPoints map={map} features={visibleOtherPointFeatures} />
      <RenderOtherLineStrings
        map={map}
        features={visibleOtherLineStringFeatures}
      />
      <RenderOtherPolygons map={map} features={visibleOtherPolygonFeatures} />

      <CableFreeSectorWhenEditing />
    </>
  );
};
