import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { Types } from "ably";
import { ablyLoadedState } from "../state/ably";

export const useAblyPublish = () => {
  const ablyLoaded = useRecoilValue(ablyLoadedState);

  const publishWithConnectionId = useCallback(
    async (
      channelName: string,
      eventName: string,
      message: Record<any, any>,
      errorCallback?: Types.errorCallback,
    ) => {
      if (!ablyLoaded) {
        return;
      }

      const channel = ablyLoaded.channels.get(channelName);
      const messageWithConnectionId = {
        ...message,
        _connectionId: ablyLoaded.connection.id,
      };

      await channel.publish(eventName, messageWithConnectionId, errorCallback);
    },
    [ablyLoaded],
  );

  return publishWithConnectionId;
};
