import { isDefined } from "./../../../utils/predicates";
import { DnDRow } from "./types";

export const findElementAndIndex = (elementId: string, rows: DnDRow[]) => {
  for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
    const elements = rows[rowIndex].elements;
    for (let elementIndex = 0; elementIndex < elements.length; elementIndex++) {
      if (elements[elementIndex].id === elementId) {
        return {
          element: { ...elements[elementIndex] },
          rowIndex,
          elementIndex,
        };
      }
    }
  }
  return { element: null, rowIndex: -1, elementIndex: -1 };
};

export const removeElement = (
  rows: DnDRow[],
  rowIndex: number,
  elementIndex: number,
) => {
  return rows
    .map((row, i) => {
      if (i !== rowIndex) return row;
      const filteredElements = [
        ...row.elements.slice(0, elementIndex),
        ...row.elements.slice(elementIndex + 1),
      ];
      if (filteredElements.length === 0) return undefined;
      return {
        ...row,
        elements: filteredElements,
      };
    })
    .filter(isDefined);
};
