/// <reference types="vite-plugin-svgr/client" />
import { getColorFromConfidenceLevel } from "components/Finance/capex";
import Tooltip from "components/General/Tooltip";
import { GeneralCost } from "./Capex/capexNumbers";
import {
  ConfidenceBar,
  ConfidenceSegment,
  ConfidenceSegmentWrapper,
} from "./styles";
import { ConfidenceLevel } from "types/financial";

const GeneralCostConfidenceBar = ({
  generalCosts,
}: {
  generalCosts: GeneralCost[] | undefined;
}) => {
  if (!generalCosts) return null;
  const confidenceLevels = generalCosts.reduce(
    (acc, generalCost) => {
      const key = generalCost.confidenceLevel ?? "";
      acc[key] = (acc[key] || 0) + generalCost.cost;
      return acc;
    },
    {} as Record<string, number>,
  );

  const totalCosts = generalCosts.reduce((sum, cost) => sum + cost.cost, 0);

  const getHelpText = (confidenceLevel: string, cost: number) => {
    const percentage = ((cost / totalCosts) * 100).toFixed(1);
    return confidenceLevel
      ? `Confidence level: ${percentage}% ${confidenceLevel} `
      : `From components. Confidence level: ${percentage}% Not specified`;
  };

  return (
    <ConfidenceBar>
      {Object.entries(confidenceLevels).map(
        ([confidenceLevel, cost], index, array) => (
          <ConfidenceSegmentWrapper
            key={confidenceLevel}
            flex={cost / totalCosts}
          >
            <Tooltip
              innerDivStyle={{ width: "100%" }}
              text={getHelpText(confidenceLevel, cost)}
            >
              <ConfidenceSegment
                key={confidenceLevel}
                first={index === 0}
                last={index === array.length - 1}
                color={getColorFromConfidenceLevel(
                  confidenceLevel as ConfidenceLevel,
                )}
              />
            </Tooltip>
          </ConfidenceSegmentWrapper>
        ),
      )}
    </ConfidenceBar>
  );
};

export default GeneralCostConfidenceBar;
