import React, { useCallback } from "react";
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import { LngLatBoundsLike } from "mapbox-gl";
import { Mixpanel } from "mixpanel";
import {
  CustomLayerAddResponse,
  uploadCustomLayer,
} from "services/customLayersAPIService";
import { useForceReloadCustomLayers } from "state/customLayers";
import { projectIdSelector } from "state/pathParams";
import { layerVisibleAtomFamily } from "components/LayerList/LayerSettings/state";
import { scrollToLayerIdInListAtom } from "components/LayerList/state";
import { mapRefAtom } from "state/map";
import { useRecoilValueDef } from "utils/recoil";
import {
  LeftModalMenuTypes,
  leftModalMenuOpenStateAtom,
} from "components/LowerLeftV2/state";
import UploadDataLayerGeneral from "./UploadDataLayerGeneral";

const UploadDataLayer = ({
  initialFiles,
  resetInitialFiles,
  onAddSourceFromUrlClick,
  onBackClick,
  onDoneClick,
}: {
  initialFiles: File[];
  resetInitialFiles(): void;
  onAddSourceFromUrlClick(): void;
  onBackClick(): void;
  onDoneClick(): void;
}) => {
  const projectId = useRecoilValue(projectIdSelector);
  const map = useRecoilValueDef(mapRefAtom);
  const setScrollToLayerId = useSetRecoilState(scrollToLayerIdInListAtom);
  const setLeftModalMenuOpen = useSetRecoilState(leftModalMenuOpenStateAtom);
  const forceReloadCustomLayers = useForceReloadCustomLayers();

  const setUploadedLayerVisible = useRecoilCallback(
    ({ set }) =>
      (layerId: string) => {
        if (projectId) {
          set(layerVisibleAtomFamily({ projectId, layerId }), true);
        }
      },
    [projectId],
  );

  const onAfterUpload = useCallback(
    (result: CustomLayerAddResponse, file: File) => {
      Mixpanel.track("Upload project layer", {
        layerName: file.name,
        size: file.size,
      });
      forceReloadCustomLayers();
      setUploadedLayerVisible(result.output_file_id);
      setLeftModalMenuOpen(LeftModalMenuTypes.Layers);
      setScrollToLayerId(result.output_file_id);

      map.fitBounds([
        result.bbox.slice(0, 2),
        result.bbox.slice(2, 4),
      ] as LngLatBoundsLike);
    },
    [
      forceReloadCustomLayers,
      map,
      setLeftModalMenuOpen,
      setScrollToLayerId,
      setUploadedLayerVisible,
    ],
  );

  return (
    <UploadDataLayerGeneral
      initialFiles={initialFiles}
      resetInitialFiles={resetInitialFiles}
      upload={(file) => uploadCustomLayer(projectId!, file)}
      onDoneClick={onDoneClick}
      onBackClick={onBackClick}
      onAfterUploadFile={onAfterUpload}
      onAddSourceFromUrlClick={onAddSourceFromUrlClick}
    />
  );
};

export default UploadDataLayer;
