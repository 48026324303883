import { Row } from "components/General/Layout";
import Tabs from "components/General/Tabs";
import { usersInOrganisationState } from "components/Organisation/state";
import UserImageRound from "components/UserImage/UserImageRound";
import { Suspense, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useTypedPath } from "state/pathParams";
import {
  ContentHeader,
  ContentHeaderColumn,
  ContentWrapper,
  Text,
} from "../../style";
import { TablePlaceholder } from "../shared/TablePlaceholder";
import OrganisationResourceUserTable from "./tabs/OrganisationUserResourceTable";
import { adminInOrganisationSelectorFamily } from "state/user";
import { UserGroups } from "./tabs/UserGroups";
import { UserProjects } from "./tabs/UserProjects";
import { toastMessagesAtom } from "state/toast";
import { organisationRightSideModal } from "../../state";

export default function UserContent({ userId }: { userId: string }) {
  const { organisationId } = useTypedPath("organisationId");
  const setToastMessages = useSetRecoilState(toastMessagesAtom);

  const user = useRecoilValue(usersInOrganisationState(organisationId)).find(
    (u) => u.user_id === userId,
  );

  const isAdminInOrg = useRecoilValue(
    adminInOrganisationSelectorFamily({ organisationId }),
  );

  const rightSideModalContent = useRecoilValue(
    organisationRightSideModal(organisationId),
  );

  const tabs = useMemo(() => {
    if (!user) return [];
    const ts = [];
    if (isAdminInOrg) {
      ts.push({
        name: "Projects",
        data: (
          <Suspense fallback={<TablePlaceholder />}>
            <UserProjects user={user} />
          </Suspense>
        ),
      });
    }
    ts.push({
      name: "Groups",
      data: (
        <Suspense fallback={<TablePlaceholder />}>
          <UserGroups user={user} />
        </Suspense>
      ),
    });
    if (isAdminInOrg) {
      ts.push({
        name: "Library access",
        data: (
          <Suspense fallback={<TablePlaceholder />}>
            <OrganisationResourceUserTable user={user} />
          </Suspense>
        ),
      });
    }
    return ts;
  }, [isAdminInOrg, user]);

  if (!user)
    return (
      <ContentWrapper>
        <h4>User not found</h4>
      </ContentWrapper>
    );

  return (
    <ContentWrapper>
      <Row style={{ gap: "2.4rem", alignItems: "center" }}>
        <UserImageRound user={user} size={9.1} />
        <ContentHeaderColumn>
          <ContentHeader>{user.nickname}</ContentHeader>
          <Text
            onClick={() => {
              navigator.clipboard.writeText(user.email);
              setToastMessages((tm) => [
                ...tm,
                { text: "Copied to clipboard", timeout: 2000 },
              ]);
            }}
          >
            {user.email}
          </Text>
        </ContentHeaderColumn>
      </Row>
      <Tabs
        initialTab={
          rightSideModalContent?.meta?.showLibraryAccess ? 2 : undefined
        }
        menuStyle={{ justifyContent: "start", gap: "5rem" }}
        tabs={tabs}
        wrapperStyle={{ overflowY: "hidden" }}
        contentWrapperStyle={{ height: "100%" }}
      />
    </ContentWrapper>
  );
}
