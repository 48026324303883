import { atomFamily, selectorFamily } from "recoil";
import {
  listGroupMembers,
  getGroupProjectAccess,
  getGroupsAndUsersWithAccessToNode,
  getOrganisationGroups,
  getUserGroupMemberships,
  getUserNodeAccess,
} from "./service";
import {
  GroupMembership,
  Group,
  GroupNodeAccess,
  UserNodeAccess,
  AccessUnionSchema,
} from "./types";

export const organisationGroupsState = atomFamily<
  Group[],
  { organisationId: string | undefined }
>({
  key: "organisationGroupsState",
  default: selectorFamily({
    key: "organisationGroupsState.default",
    get:
      ({ organisationId }) =>
      () => {
        if (!organisationId) return [];
        return getOrganisationGroups(organisationId);
      },
  }),
});

export const groupMembersState = atomFamily<
  GroupMembership[],
  { organisationId: string | undefined; groupId: string }
>({
  key: "groupMembersState",
  default: selectorFamily({
    key: "groupMembersState.default",
    get:
      ({ organisationId, groupId }) =>
      () => {
        if (!organisationId || !groupId) return [];
        return listGroupMembers(organisationId, groupId);
      },
  }),
});

export const groupProjectsState = atomFamily<
  GroupNodeAccess[],
  { organisationId: string | undefined; groupId: string }
>({
  key: "groupProjectsState",
  default: selectorFamily({
    key: "groupProjectsState.default",
    get:
      ({ organisationId, groupId }) =>
      () => {
        if (!organisationId || !groupId) return [];
        return getGroupProjectAccess(organisationId, groupId);
      },
  }),
});

export const groupsProjectsState = selectorFamily<
  GroupNodeAccess[],
  { organisationId: string | undefined; groupIds: string[] }
>({
  key: "groupsProjectsState",
  get:
    ({ organisationId, groupIds }) =>
    ({ get }) => {
      if (!organisationId) return [];
      return groupIds
        .map((groupId) => get(groupProjectsState({ organisationId, groupId })))
        .flat();
    },
});

export const userGroupsMembershipState = selectorFamily<
  GroupMembership[],
  { organisationId: string | undefined; userId: string }
>({
  key: "userGroupsMembershipState",
  get:
    ({ organisationId, userId }) =>
    () => {
      if (!organisationId) return [];
      return getUserGroupMemberships(organisationId, userId);
    },
});

export const userNodeAccessState = selectorFamily<
  UserNodeAccess[],
  { organisationId: string | undefined; userId: string }
>({
  key: "userNodeAccessState",
  get:
    ({ organisationId, userId }) =>
    () => {
      if (!organisationId) return [];
      return getUserNodeAccess(organisationId, userId);
    },
});

export const nodeGroupUserAccessSelector = selectorFamily<
  AccessUnionSchema[],
  { organisationId: string | undefined; nodeId: string }
>({
  key: "nodeGroupUserAccessSelector",
  get:
    ({ organisationId, nodeId }) =>
    () => {
      if (!organisationId) return [];
      return getGroupsAndUsersWithAccessToNode(organisationId, nodeId);
    },
});
