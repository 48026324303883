import { selectorFamily } from "recoil";
import { CostType } from "../../../../services/costService";
import { MooringLineType } from "../../../../services/mooringLineTypeService";
import {
  getAnchorsInBranchSelector,
  getMooringLinesInBranchSelector,
} from "../../../../state/layout";
import { currentMooringLineTypesState } from "../../../../state/mooringLineType";
import { AmountUnit } from "../../../../types/financial";
import { Amount, amountAddId, amountId } from "../generalAmounts";
import { isMooringLineMultiple } from "../../../../utils/predicates";
import {
  ProdId,
  getBranchId,
  getCapacity,
  getParkId,
} from "components/ProductionV2/state";

export const mooringAmountSelectorFamily = selectorFamily<Amount[], ProdId>({
  key: "mooringAmountSelectorFamily",
  get:
    (productionId) =>
    ({ get }) => {
      const branchId = get(getBranchId(productionId));
      const parkId = get(getParkId(productionId));

      const anchors = get(getAnchorsInBranchSelector({ parkId, branchId }));
      const mooringLines = get(
        getMooringLinesInBranchSelector({ parkId, branchId }),
      );
      const {
        mooringLineTypes,
        lengthPerMooringLineType,
        clumpWeightTonnes,
        buoyTonnes,
      } = get(mooringLineTypesInParkSelectorFamily({ branchId, parkId }));

      const mooringLengthAmount = mooringLineTypes.map((line) => {
        const amount = lengthPerMooringLineType[line.id] ?? 0;
        const unit = AmountUnit.km;
        const category = CostType.Mooring;
        const name = line.name;

        return amountAddId(
          {
            unit,
            amount,
            amountName: name,
            category,
          },
          line.id,
        );
      });

      const anchorAmount = amountAddId(
        {
          unit: AmountUnit.unit,
          amount: anchors.length,
          category: CostType.Mooring,
        },
        "anchors",
      );

      const mooringLinesAmount = amountAddId({
        unit: AmountUnit.line,
        amount: mooringLines.length,
        category: CostType.Mooring,
      });

      const mooringLinesKmAmount = amountAddId({
        unit: AmountUnit.km,
        amount: mooringLineTypes
          .map((line) => lengthPerMooringLineType[line.id] ?? 0)
          .reduce((acc, n) => acc + n, 0),
        category: CostType.Mooring,
      });

      const clumpWeightAmount = amountAddId(
        {
          unit: AmountUnit.t,
          amount: clumpWeightTonnes,
          category: CostType.Mooring,
        },
        "clump_weights",
      );

      const buoyAmount = amountAddId(
        {
          unit: AmountUnit.t,
          amount: buoyTonnes,
          category: CostType.Mooring,
        },
        "buoys",
      );

      const capacity = get(getCapacity(productionId));

      const perMwAmount = amountAddId({
        unit: AmountUnit.MW,
        amount: mooringLines.length > 0 ? capacity ?? 0 : 0,
        category: CostType.Mooring,
      });

      const fixedAmount = {
        id: amountId({
          unit: AmountUnit.fixed,
          category: CostType.Mooring,
        }),
        unit: AmountUnit.fixed,
        amount: 1,
        category: CostType.Mooring,
      };

      return [
        anchorAmount,
        ...mooringLengthAmount,
        mooringLinesAmount,
        mooringLinesKmAmount,
        perMwAmount,
        clumpWeightAmount,
        buoyAmount,
        fixedAmount,
      ];
    },
});

export const mooringLineTypesInParkSelectorFamily = selectorFamily<
  {
    mooringLineTypes: MooringLineType[];
    lengthPerMooringLineType: Record<string, number>;
    clumpWeightTonnes: number;
    buoyTonnes: number;
  },
  { branchId: string; parkId: string }
>({
  key: "mooringLineTypesInParkSelectorFamily",
  get:
    ({ branchId, parkId }) =>
    ({ get }) => {
      const mooringLines = get(
        getMooringLinesInBranchSelector({ parkId, branchId }),
      );
      const mooringLineTypes = get(currentMooringLineTypesState);

      const lineSegmentLengths: number[] = [];
      const lineSegmentTypes: string[] = [];
      let clumpWeightTonnes = 0;
      let buoyTonnes = 0;
      for (const line of mooringLines) {
        if (isMooringLineMultiple(line)) {
          for (let i = 0; i < line.properties.lineLengths.length; i++) {
            lineSegmentLengths.push(line.properties.lineLengths[i]);
            lineSegmentTypes.push(line.properties.lineTypes[i]);
            if (line.properties.attachments[i] > 0) {
              clumpWeightTonnes += line.properties.attachments[i] / 1000;
            } else if (line.properties.attachments[i] < 0) {
              buoyTonnes += -line.properties.attachments[i] / 1000;
            }
          }
        } else if (line.properties.lineLength) {
          lineSegmentLengths.push(line.properties.lineLength);
          lineSegmentTypes.push(line.properties.lineType ?? "");
        }
      }

      const lineTypeIds = new Set(mooringLineTypes.map((t) => t.id));
      let lengthPerMooringLineType: { [key: string]: number } = {};
      for (let i = 0; i < lineSegmentLengths.length; i++) {
        const len = lineSegmentLengths[i];
        const type = lineSegmentTypes[i];
        const typeName = lineTypeIds.has(type) ? type : "other";
        lengthPerMooringLineType[typeName] =
          (lengthPerMooringLineType[typeName] ?? 0) + len;
      }

      return {
        mooringLineTypes,
        lengthPerMooringLineType,
        clumpWeightTonnes,
        buoyTonnes,
      };
    },
});
