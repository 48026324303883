import Plotly from "plotly.js-dist-min";
import { useRef, useEffect } from "react";
import { CenterContainer, LoadingState, SafeCard } from "./Base";
import { useRecoilValue } from "recoil";
import { getInvalidTypesInParkAndBranch } from "state/layout";
import { InvalidTypesDisplayText } from "types/invalidTypes";
import { useDashboardContext } from "../Dashboard";
import { currentParkTriggerId } from "components/Finance/Triggers";
import { useCapexMillionPV } from "components/Finance/capex";
import { CostType } from "services/costService";
import { getStoppedReason } from "components/ProductionV2/state";
import { ValidationAlert } from "components/ValidationWarnings/ValidationAlert";
import { analysisStoppedText } from "components/ProductionV2/types";

export const CapexPieChartWidget = () => {
  const { park, branch } = useDashboardContext();

  const invalidTypes = useRecoilValue(
    getInvalidTypesInParkAndBranch({ parkId: park.id, branchId: branch.id }),
  );

  if (invalidTypes.length > 0)
    return (
      <CenterContainer style={{ margin: "3rem" }}>
        <InvalidTypesDisplayText invalidTypes={invalidTypes} />
      </CenterContainer>
    );

  return (
    <SafeCard title="Capex graph" id="Capex pie chart">
      <CapexPieChartInnerV2 />
    </SafeCard>
  );
};

const CapexPieChartInnerV2 = () => {
  const graphRef = useRef<HTMLDivElement>(null);
  const { useCosts } = useCapexMillionPV(currentParkTriggerId);
  const turbinesCapex = useCosts(CostType.Turbine).sum.valueMaybe();
  const interArrayCablingCapex = useCosts(CostType.Cable).sum.valueMaybe();
  const foundationCapex = useCosts(CostType.Foundation).sum.valueMaybe();
  const mooringCapex = useCosts(CostType.Mooring).sum.valueMaybe();
  const substationCapex = useCosts(CostType.Substation).sum.valueMaybe();
  const exportCableCapex = useCosts(CostType.ExportCable).sum.valueMaybe();
  const otherCapex = useCosts(CostType.Other).sum.valueMaybe();

  useEffect(() => {
    if (!graphRef.current) return;
    if (turbinesCapex === undefined) return;
    if (interArrayCablingCapex === undefined) return;
    if (foundationCapex === undefined) return;
    if (mooringCapex === undefined) return;
    if (substationCapex === undefined) return;
    if (exportCableCapex === undefined) return;
    if (otherCapex === undefined) return;

    const valueLabels = [
      [turbinesCapex, "Turbines", "#002334"],
      [interArrayCablingCapex, "Inter array cabling", "#03B6F7"],
      [foundationCapex, "Foundations", "#00445F"],
      [mooringCapex, "Mooring", "#028EC2"],
      [substationCapex, "Substation", "#01688F"],
      [exportCableCapex, "Export cable", "#A3D8FF"],
      [otherCapex, "Other", "#E5F2FF"],
    ].filter(([val]) => val !== 0);

    const values = valueLabels.map(([val, ,]) => val);
    const labels = valueLabels.map(([, label]) => label);
    const colors = valueLabels.map(([, , color]) => color);
    var data = [
      {
        values,
        labels,
        marker: {
          colors,
        },
        type: "pie" as const,
        automargin: true,
      },
    ];

    var layout = {
      autosize: true,
      height: 200,
      width: 350,
      margin: { t: 0, b: 0, l: 0, r: 0 },
      showlegend: true,
    };

    Plotly.newPlot(graphRef.current, data, layout);
  }, [
    exportCableCapex,
    foundationCapex,
    graphRef,
    interArrayCablingCapex,
    mooringCapex,
    otherCapex,
    substationCapex,
    turbinesCapex,
  ]);

  const analysisStoppedReason = useRecoilValue(
    getStoppedReason(currentParkTriggerId),
  );
  if (analysisStoppedReason) {
    return (
      <ValidationAlert
        type={"error"}
        title="Analysis stopped"
        description={analysisStoppedText[analysisStoppedReason]}
      />
    );
  }

  if (
    [
      turbinesCapex,
      interArrayCablingCapex,
      foundationCapex,
      mooringCapex,
      substationCapex,
      exportCableCapex,
      otherCapex,
    ].some((x) => x === undefined)
  )
    return <LoadingState />;

  return <div ref={graphRef} />;
};
