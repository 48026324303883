import { atom } from "recoil";

export enum ClickInMapMode {
  SELECT_FEATURE = "selectFeature",
  SELECT_FEATURE_FOR_OVERLAP = "selectFeatureForOverlap",
}

export const clickInMapModeStateAtom = atom<ClickInMapMode>({
  key: "clickInMapModeStateAtom",
  default: ClickInMapMode.SELECT_FEATURE,
});
