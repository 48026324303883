import React from "react";
import styled from "styled-components";
import { OtherUserMetaInfo, LoggedInUser } from "../../types/user";
import { createImageFromInitials } from "../../utils/image";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import {
  UserThumbnailSize,
  makeThumbnailUrl,
} from "../../services/userService";
import { isInChecklyMode, wait } from "../../utils/utils";
import { typography } from "styles/typography";
import { colors } from "styles/colors";

const userPictureCounter = atom<number>({
  key: "userPicture",
  default: 0,
});

export const useInvalidateUntilOkay = () => {
  const setCounter = useSetRecoilState(userPictureCounter);
  return async (user: LoggedInUser, size: UserThumbnailSize) => {
    const url = makeThumbnailUrl(user, size);
    if (!url) return;
    let res = await fetch(url);
    while (res.status === 403) {
      await wait(1000);
      res = await fetch(url);
    }
    setCounter((c) => c + 1);
  };
};

const StyledProfileImage = styled.img<{ onClick?: () => void; size: number }>`
  width: ${(p) => p.size}rem;
  height: ${(p) => p.size}rem;
  border-radius: ${(p) => p.size / 2}rem;
  object-fit: cover;
  cursor: ${(p) => (p.onClick ? "pointer" : "inherit")};
`;

const StyledProfileDiv = styled.div<{ size: number }>`
  width: ${(p) => p.size}rem;
  height: ${(p) => p.size}rem;
  border-radius: ${(p) => p.size / 2}rem;
  object-fit: cover;
  cursor: ${(p) => (p.onClick ? "pointer" : "inherit")};
  background-color: ${colors.secondaryHover};
  display: flex;
  align-items: center;
  justify-content: center;
  ${typography.graphics}
  color: ${colors.primaryText};
  letter-spacing: -0.1rem;
`;

const UserImageRound = ({
  user,
  onClick,
  size = 3.2,
  style,
  title,
  thumbnailSize,
}: {
  user: OtherUserMetaInfo;
  onClick?: () => void;
  size?: number;
  style?: React.CSSProperties;
  title?: HTMLImageElement["title"];
  thumbnailSize?: UserThumbnailSize;
}) => {
  const count = useRecoilValue(userPictureCounter);
  const _thumbnailSize =
    thumbnailSize ?? (size <= 2 ? 32 : size <= 6.4 ? 64 : undefined);
  const url = makeThumbnailUrl(user, _thumbnailSize);

  if (isInChecklyMode()) return null;

  return (
    <>
      <StyledProfileImage
        title={title ?? user.nickname}
        onClick={onClick}
        style={style}
        size={size}
        src={`${url}?k=${count}`}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = createImageFromInitials(user.nickname ?? "User");
        }}
        alt="profile"
      />
    </>
  );
};

export const InitialsImageRound = ({
  name,
  onClick,
  size = 3.2,
  style,
  title,
}: {
  name: string;
  onClick?: () => void;
  size?: number;
  style?: React.CSSProperties;
  title?: HTMLImageElement["title"];
}) => {
  if (isInChecklyMode()) return null;

  return (
    <>
      <StyledProfileImage
        title={title ?? name}
        onClick={onClick}
        style={style}
        size={size}
        src={createImageFromInitials(name)}
        onError={() => {}}
        alt="profile"
      />
    </>
  );
};

export const MoreUsersImageRound = ({
  onClick,
  size = 3.2,
  style,
  title,
}: {
  onClick?: () => void;
  size?: number;
  style?: React.CSSProperties;
  title?: HTMLImageElement["title"];
}) => {
  if (isInChecklyMode()) return null;

  return (
    <>
      <StyledProfileDiv
        onClick={onClick}
        style={style}
        size={size}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
        }}
      >
        {title}
      </StyledProfileDiv>
    </>
  );
};

export default UserImageRound;
