import { FeatureFlagName } from "components/General/FeatureFlag";
import { z } from "zod";
import { DnDRow } from "../General/DnDGrid/types";

/**
 * State associated with a widget.
 * For widget that doesn't have a state (this is most of them), the type is `never`.
 */
export type WidgetState = {
  "3D-foundation"?: never;
  Text?: {
    title: string;
    body: string;
  };
  "Cable matrix map"?: never;
  "Cable matrix"?: never;
  Cabling?: never;
  "Capex input"?: never;
  "Capex pie chart"?: never;
  "Capex table"?: never;
  "Cost input"?: never;
  "Cumulative graph"?: never;
  "Export cable loading"?: never;
  "Export cables"?: never;
  "Export system voltage"?: never;
  "Inter array loss graph"?: never;
  Energy?: never;
  Foundations?: never;
  "Foundation key statistics"?: never;
  "Foundation totals"?: never;
  Input?: never;
  "Lcoe table"?: never;
  "IRR table"?: never;
  "Park life cash flow"?: never;
  Map?: never;
  "Monthly graph"?: never;
  "Annual variability"?: never;
  "Wind speed distribution"?: never;
  "Mooring lines"?: never;
  "Mooring line anchors"?: never;
  "Mooring line attachments"?: never;
  "Other expenditures input"?: never;
  Losses?: never;
  Park?: never;
  Turbines?: never;
  "Wind rose"?: never;
};

const _DnDElement = z.object({
  id: z.string(),
  widthShare: z.number().optional(),
  component: z.never().optional(), // NOTE: trick to make it not typecheck when this is something else.
});

const _DnDRow = z.object({
  id: z.string(),
  height: z.number().optional(),
  elements: _DnDElement.array(),
});

export const _DashboardSerialize = z.object({
  id: z.string(),
  name: z.string(),
  preset: z.boolean().optional(),
  branchId: z.string().optional(),
  parkId: z.string().optional(),
  configurationId: z.string().optional(),
  windConfigurationId: z.string().optional(),
  costConfigurationId: z.string().optional(),
  rows: _DnDRow.array().optional(),
  widgetState: z.record(z.any()).optional(),
});
export type DashboardSerialize = z.infer<typeof _DashboardSerialize>;

export type Dashboard = {
  id: string;
  name: string;

  /** True if this dashboard is a "preset Vind dashboard". */
  preset?: boolean;

  branchId?: string;
  parkId?: string;
  configurationId?: string;
  windConfigurationId?: string;
  costConfigurationId?: string;

  rows: DnDRow[];
  widgetState: WidgetState;
};

export const widgetIds = [
  "3D-foundation",
  "Cable matrix map",
  "Cable matrix",
  "Cabling",
  "Capex input",
  "Capex pie chart",
  "Capex table",
  "Cost input",
  "Cumulative graph",
  "Energy",
  "Export cables",
  "Foundations",
  "Foundation key statistics",
  "Foundation totals",
  "Input",
  "Lcoe table",
  "IRR table",
  "Annual variability",
  "Park life cash flow",
  "Map",
  "Monthly graph",
  "Wind speed distribution",
  "Mooring lines",
  "Mooring line anchors",
  "Mooring line attachments",
  "Other expenditures input",
  "Losses",
  "Park",
  "Turbines",
  "Wind rose",
  "Text",
  "Export cable loading",
  "Export system voltage",
  "Inter array loss graph",
] as const;

const widgetIdsCost = [
  "Capex pie chart",
  "Capex table",
  "Capex input",
  "Cost input",
  "Lcoe table",
  "IRR table",
  "Park life cash flow",
  "Other expenditures input",
] as const;

const widgetIdsElectrical = [
  "Cable matrix map",
  "Cable matrix",
  "Cabling",
  "Export cables",
  "Inter array loss graph",
  "Export cable loading",
  "Export system voltage",
] as const;

const widgetIdsPark = ["Map", "Park"] as const;

const widgetIdsYield = [
  "Cumulative graph",
  "Energy",
  "Input",
  "Monthly graph",
  "Wind speed distribution",
  "Annual variability",
  "Losses",
  "Turbines",
  "Wind rose",
] as const;

const widgetIdsMooring = [
  "Mooring lines",
  "Mooring line anchors",
  "Mooring line attachments",
] as const;

const widgetIdsFoundation = [
  "Foundations",
  "Foundation key statistics",
  "Foundation totals",
  "3D-foundation",
] as const;

const allWidgetIdsInCategories: WidgetId[] = [
  ...widgetIdsCost,
  ...widgetIdsElectrical,
  ...widgetIdsPark,
  ...widgetIdsYield,
  ...widgetIdsMooring,
  ...widgetIdsFoundation,
];

// Set widgetIdsOther to be all widgetIds that do not exist in the other lists
const widgetIdsOther = widgetIds.filter(
  (id) => !allWidgetIdsInCategories.includes(id),
);

export const widgetIdsInCategories = {
  Cost: widgetIdsCost,
  Electrical: widgetIdsElectrical,
  Foundation: widgetIdsFoundation,
  Mooring: widgetIdsMooring,
  Park: widgetIdsPark,
  Yield: widgetIdsYield,
  Other: widgetIdsOther,
} as const;

export type WidgetId = (typeof widgetIds)[number];

export const widgetIdRequireFeatureFlag: Partial<
  Record<WidgetId, FeatureFlagName>
> = {};
