import { atomFamily, selector, selectorFamily } from "recoil";

import {
  getMooringLineTypes,
  MooringLineType,
  _MooringLineType,
} from "../services/mooringLineTypeService";
import { currentVersionSelector } from "./project";
import { projectIdSelector } from "./pathParams";

export const mooringLineTypeAtomFamily = atomFamily<
  MooringLineType[],
  {
    projectId: string | undefined;
    version?: number;
  }
>({
  key: "mooringLineTypeAtomFamily",
  default: selectorFamily<
    MooringLineType[],
    {
      projectId: string | undefined;
      version?: number;
    }
  >({
    key: "mooringLineTypeSelectorFamily",
    get:
      ({ projectId, version }) =>
      async () => {
        if (!projectId) return [];
        if (!projectId) return [];
        const response = await getMooringLineTypes(projectId, version);
        if (!response.ok) {
          throw new Error("Fetching configuration failed");
        }
        const j = await response.json();
        return _MooringLineType.array().parse(j);
      },
  }),
});

export const currentMooringLineTypesState = selector<MooringLineType[]>({
  key: "mooringLineTypesState",
  get: ({ get }) => {
    const projectId = get(projectIdSelector);
    if (!projectId) return [];
    const version = get(currentVersionSelector);
    const lineTypes = get(mooringLineTypeAtomFamily({ projectId, version }));

    return [...lineTypes].sort((a, b) => a.diameter - b.diameter);
  },
});
