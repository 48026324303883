import { atomFamily, selectorFamily } from "recoil";
import {
  getNodesWithMissingParents,
  nodesInOrganisationSelectorFamily,
} from "./useOrganisationFolderCrud";
import { Node } from "../../services/customerAPI";
import {
  UserNodeAccessWithMeta,
  isUserNodeAccessWithMeta,
} from "components/Organisation/Groups/types";
import { nodeGroupUserAccessSelector } from "components/Organisation/Groups/state";

export const nodesThatMightBeOutOfSync = atomFamily<
  string[],
  { toplevelNodeId: string }
>({
  key: "nodesThatMightBeOutOfSync",
  default: [],
});

export const allNodeUsersSelectorFamily = selectorFamily<
  UserNodeAccessWithMeta[],
  { organisationId: string; nodeId: string }
>({
  key: "allNodeUsersSelectorFamily",
  get:
    ({ organisationId, nodeId }) =>
    ({ get }) => {
      const nodeAccess = get(
        nodeGroupUserAccessSelector({ organisationId, nodeId }),
      );

      return nodeAccess.filter(isUserNodeAccessWithMeta);
    },
});

export type FolderTreeItem = Node & {
  type: "folder";
  items: TreeItem[];
};
export type TreeItem = FolderTreeItem | Node;

export const folderTreeSelectorFamily = selectorFamily<
  TreeItem[],
  { organisationId: string; folderId: string }
>({
  key: "folderTreeSelectorFamily",
  get:
    ({ organisationId, folderId }) =>
    ({ get }) => {
      const nodes = get(nodesInOrganisationSelectorFamily({ organisationId }));

      const missingParentsNodes = get(
        getNodesWithMissingParents({ organisationId }),
      );

      const childrenNodes =
        folderId === organisationId
          ? missingParentsNodes
          : nodes.filter((n) => n.parent_id === folderId);

      function make(node: TreeItem): TreeItem {
        if (["personal_folder", "folder"].includes(node.type)) {
          return {
            ...node,
            type: "folder",
            items: nodes.filter((n) => n.parent_id === node.id).map(make),
          } as FolderTreeItem;
        }
        return node;
      }
      return childrenNodes.map(make);
    },
});

/** `map`s over each `.items` array in the tree. */
export const treeMap = (
  e: TreeItem[],
  fn: (e: TreeItem) => TreeItem,
): TreeItem[] => {
  return e.map((e) => {
    if (e.type === "folder") {
      const folderItem = e as FolderTreeItem;
      const items = treeMap(folderItem.items, fn);
      return fn({
        ...e,
        items,
      });
    }
    return fn(e);
  });
};

/**
 * Make comparision function used for {@link Array.sort}.
 *  - Folders before projects.
 *  - Starred items first.
 *  - Alphabetical order.
 */
export const makeNodeCompare = (followIds: Set<string>) => {
  return (a: Node, b: Node): number => {
    if (a.type === "personal_folder") return -1;
    if (b.type === "personal_folder") return 1;
    if (a.type === "project" && b.type === "folder") return 1;
    if (a.type === "folder" && b.type === "project") return -1;
    if (a.type === "project" && b.type === "folder") return 1;

    const aId = a.id;
    const aTitle = a.name;

    const bId = b.id;
    const bTitle = b.name;

    const aFollow = followIds.has(aId);
    const bFollow = followIds.has(bId);
    if (aFollow && !bFollow) return -1;
    if (bFollow && !aFollow) return 1;
    return aTitle.localeCompare(bTitle);
  };
};
