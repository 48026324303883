/// <reference types="vite-plugin-svgr/client" />
import { CostUnit } from "types/financial";
import { InputDimensioned, InputNumber } from "components/General/Input";
import Tooltip from "components/General/Tooltip";
import styled from "styled-components";
import { typography } from "styles/typography";
import { Label, TableWrapper } from "./styles";
import InformationIcon from "@icons/24/Information.svg?react";
import { useRecoilValue } from "recoil";
import {
  localCostConfigurationAtom,
  useLocalCostConfigurationCrud,
} from "./state";
import { between } from "utils/validations";
import { KeyValueGridWrapper } from "components/FinancialAnalysis/styles";
import { Column } from "components/General/Layout";
import { FirstRowItem } from "./Capex";
import { colors } from "styles/colors";

const SubHeader = styled.p`
  ${typography.h4}
  margin: 0;
`;

export const CashFlows = ({ isReadOnly }: { isReadOnly?: boolean }) => {
  const { cashFlows, inflation, lifeCycle } = useRecoilValue(
    localCostConfigurationAtom,
  );
  const { updateCashFlows, updateInflation } = useLocalCostConfigurationCrud();
  return (
    <TableWrapper>
      <KeyValueGridWrapper>
        <Label>
          <Tooltip text="Numbers in this pane will be inflated / deflated with respect to this year.">
            Reference year
            <InformationIcon style={{ height: "1.3rem" }} />
          </Tooltip>
          <InputNumber
            value={inflation.referenceYearRevenue}
            validate={between(2010, 2050)}
            validationMessage={"Expected to be in the range 2010 to 2050"}
            onChange={(year) => {
              const referenceYearRevenue = Math.round(year);
              updateInflation({
                referenceYearRevenue,
              });
            }}
            disabled={isReadOnly}
          />
        </Label>
      </KeyValueGridWrapper>
      <Column style={{ gap: "1.2rem" }}>
        <Tooltip text="The revenue prior to market price.">
          <SubHeader>
            Guaranteed price
            <InformationIcon style={{ height: "1.3rem" }} />
          </SubHeader>
        </Tooltip>
        <Label>
          Years
          <FirstRowItem style={{ width: "40rem", gap: "0.8rem" }}>
            <InputDimensioned
              style={{ width: "20rem" }}
              value={cashFlows.guaranteedYears}
              validate={(value) => value >= 0}
              validationMessage={"Must be greater than or equal to 0"}
              onChange={(cost) =>
                updateCashFlows({
                  guaranteedYears: cost,
                })
              }
              unit={CostUnit.years}
              disabled={isReadOnly}
            />
            <p style={{ color: colors.secondaryText }}>
              {(cashFlows.guaranteedYears === 0 && "-") ||
                `${lifeCycle.operationStart} -
                  ${
                    lifeCycle.operationStart + cashFlows.guaranteedYears - 1
                  } inclusive`}
            </p>
          </FirstRowItem>
        </Label>
        <KeyValueGridWrapper>
          <Label>
            Price
            <InputDimensioned
              value={cashFlows.guaranteedPrice.cost}
              validate={(value) => value >= 0}
              validationMessage={"Must be greater than or equal to 0"}
              onChange={(cost) =>
                updateCashFlows({
                  guaranteedPrice: {
                    cost,
                    unit: cashFlows.guaranteedPrice.unit,
                  },
                })
              }
              unit={cashFlows.guaranteedPrice.unit}
              disabled={isReadOnly}
            />
          </Label>
        </KeyValueGridWrapper>

        <SubHeader>Market price</SubHeader>
        <KeyValueGridWrapper>
          <Label>
            Price
            <InputDimensioned
              value={cashFlows.marketPrice.cost}
              validate={(value) => value >= 0}
              validationMessage={"Must be greater than or equal to 0"}
              onChange={(cost) =>
                updateCashFlows({
                  marketPrice: { cost, unit: cashFlows.marketPrice.unit },
                })
              }
              unit={cashFlows.marketPrice.unit}
              disabled={isReadOnly}
            />
          </Label>
        </KeyValueGridWrapper>
      </Column>
    </TableWrapper>
  );
};
