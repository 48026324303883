/// <reference types="vite-plugin-svgr/client" />
import {
  WidgetId,
  widgetIdRequireFeatureFlag,
  widgetIdsInCategories,
} from "./types";
import { RoundTag } from "./RoundTag";
import { Column, Frame, Row } from "../General/Layout";
import ShapesIcon from "@icons/24/Shapes.svg?react";
import BulbIcon from "@icons/24/Bulb.svg?react";
import { useRef, useState } from "react";
import { useClickOutside } from "../../hooks/useClickOutside";
import { Anchor } from "../General/Anchor";
import Button from "../General/Button";
import { ButtonWrapper } from "../RightSide/InfoModal/style";
import { isDefined } from "utils/predicates";
import { useAllFeatureFlags } from "components/General/FeatureFlag";

export const WidgetSelector = ({
  canEdit,
  widgets,
  onWidgetClick,
}: {
  canEdit: boolean;
  widgets: WidgetId[];
  onWidgetClick?: (w: WidgetId) => void;
}) => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const enabledFeatureFlags = useAllFeatureFlags();

  useClickOutside(
    panelRef,
    () => {
      setOpen(false);
    },
    (target) => target === buttonRef.current,
  );

  return (
    <>
      <div ref={buttonRef}>
        <Button
          id="widget-selector-button"
          onClick={() => {
            setOpen(!open);
          }}
          text={"Widgets"}
          icon={<ShapesIcon />}
          buttonType={"primary"}
          disabled={!canEdit}
        />
      </div>
      {open && (
        <Anchor basePlace={"bottom"} floatPlace={"top"} baseRef={buttonRef}>
          <Frame style={{ width: "fit-content" }} ref={panelRef}>
            <Row style={{ flexWrap: "wrap" }}>
              {Object.entries(widgetIdsInCategories).map(
                ([title, widgetIds]) => (
                  <Column
                    key={title}
                    style={{ width: "fit-content", paddingRight: "1rem" }}
                  >
                    <h4>{title}</h4>
                    {widgetIds
                      .map((id) => {
                        if (
                          id in widgetIdRequireFeatureFlag &&
                          !enabledFeatureFlags.includes(
                            widgetIdRequireFeatureFlag[id]!,
                          )
                        ) {
                          return undefined;
                        } else {
                          return (
                            <RoundTag
                              key={id}
                              text={id}
                              selected={widgets.includes(id)}
                              style={{ alignSelf: "start" }}
                              onClick={() => {
                                if (!canEdit) return;
                                onWidgetClick?.(id);
                              }}
                              disabled={!canEdit}
                            />
                          );
                        }
                      })
                      .filter(isDefined)}
                  </Column>
                ),
              )}
            </Row>
            <ButtonWrapper>
              <Button
                text="Request new statistic"
                icon={<BulbIcon />}
                buttonType="secondary"
                onClick={() => {
                  window.Intercom("showNewMessage");
                }}
              />
            </ButtonWrapper>
          </Frame>
        </Anchor>
      )}
    </>
  );
};
