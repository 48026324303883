export default function minMax<T>(
  array: T[],
  key: (t: T) => number,
): { min: number; max: number } | undefined {
  if (array.length === 0) return;

  const init = key(array[0]);
  let [min, max] = [init, init];

  for (const element of array) {
    const val = key(element);
    if (val < min) {
      min = val;
    } else if (val > max) {
      max = val;
    }
  }

  return {
    min,
    max,
  };
}
