import { defaultPointCircleRadius } from "components/MapFeatures/expressionUtils";
import {
  DEFAULT_IN_FOCUS_OPACITY,
  DEFAULT_NOT_IN_FOCUS_OPACITY,
  DEFAULT_SELECTED_OPACITY,
  displayLabelPropertyName,
  lockedPropertyName,
} from "@constants/canvas";
import { CirclePaint } from "mapbox-gl";
import { useEffect } from "react";
import { colors } from "styles/colors";
import { ExistingTurbineFeature } from "types/feature";
import { safeRemoveLayer } from "utils/map";
import {
  MIN_EXISTING_TURBINE_VISIBLE_ZOOM,
  editmodePropertyName,
  existingTurbineLayerId,
  existingTurbineSourceId,
  existingTurbineSymbolLayerId,
  ghostPropertyName,
} from "./constants";
import { addLayer, removeCodepointsFromFeatures } from "./utils";

const existingTurbinePaint: CirclePaint = {
  "circle-radius": defaultPointCircleRadius,
  "circle-color": [
    "case",
    ["==", ["get", editmodePropertyName], true],
    colors.anchorPreview,
    ["boolean", ["feature-state", "error"], false],
    colors.redAlert,
    ["boolean", ["feature-state", "warning"], false],
    colors.yellow,
    colors.existingTurbine,
  ],
  "circle-opacity": [
    "case",
    ["boolean", ["feature-state", "error"], false],
    0.9,
    ["==", ["get", editmodePropertyName], true],
    1.0,
    ["==", ["get", ghostPropertyName], true],
    0.2,
    [
      "boolean",
      ["feature-state", "hover"],
      ["feature-state", "selected"],
      false,
    ],
    DEFAULT_SELECTED_OPACITY,
    ["boolean", ["feature-state", "inFocus"], false],
    DEFAULT_IN_FOCUS_OPACITY,
    DEFAULT_NOT_IN_FOCUS_OPACITY + 0.2,
  ],
  "circle-stroke-color": [
    "case",
    ["==", ["get", editmodePropertyName], true],
    colors.lightText,
    ["==", ["get", lockedPropertyName], true],
    colors.lockedFeatureOutline,
    ["!=", ["feature-state", "borderColor"], null],
    ["feature-state", "borderColor"],
    "#fff",
  ],
  "circle-stroke-width": [
    "case",
    ["==", ["get", editmodePropertyName], true],
    3,
    ["==", ["get", lockedPropertyName], true],
    2,
    ["!=", ["feature-state", "borderColor"], null],
    2.0,
    ["boolean", ["feature-state", "hover"], false],
    ["case", ["boolean", ["feature-state", "selected"], false], 2.0, 1.0],
    ["boolean", ["feature-state", "selected"], false],
    2.0,
    0.0,
  ],
};

export const RenderExistingTurbines = ({
  turbines,
  map,
}: {
  turbines: ExistingTurbineFeature[];
  map: mapboxgl.Map;
}) => {
  useEffect(() => {
    map.addSource(existingTurbineSourceId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    return () => {
      safeRemoveLayer(map, existingTurbineLayerId);
      safeRemoveLayer(map, existingTurbineSymbolLayerId);
      map.removeSource(existingTurbineSourceId);
    };
  }, [map]);

  useEffect(() => {
    addLayer(map, {
      id: existingTurbineLayerId,
      type: "circle",
      source: existingTurbineSourceId,
      paint: existingTurbinePaint,
      minzoom: MIN_EXISTING_TURBINE_VISIBLE_ZOOM,
    });
    addLayer(map, {
      id: existingTurbineSymbolLayerId,
      source: existingTurbineSourceId,
      type: "symbol",
      minzoom: 11.5,
      layout: {
        "text-field": [
          "case",
          ["==", ["typeof", ["get", "power"]], "number"],
          ["concat", ["get", "power"], "MW"],
          "Unknown",
        ],
        "text-offset": [0, 1],
        "text-size": 7,
        "text-anchor": "top",
      },
      filter: ["boolean", ["get", displayLabelPropertyName], true],
    });
  }, [map]);

  useEffect(() => {
    const source = map.getSource(existingTurbineSourceId);
    if (source?.type !== "geojson") return;
    source.setData({
      type: "FeatureCollection",
      features: removeCodepointsFromFeatures(turbines),
    });
  }, [map, turbines]);

  return null;
};
