import { Row } from "components/Design/ProjectHistory/styles";
import Button from "components/General/Button";
import { Column } from "components/General/Layout";
import { nodesThatMightBeOutOfSync } from "components/Projects/state";
import { nodesInOrganisationSelectorFamily } from "components/Projects/useOrganisationFolderCrud";
import { useMemo } from "react";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { useTypedPath } from "state/pathParams";
import styled from "styled-components";
import { colors } from "styles/colors";
import { typography } from "styles/typography";

export const RefreshNotifier = ({
  toplevelNodeId,
}: {
  toplevelNodeId: string;
}) => {
  const { organisationId } = useTypedPath("organisationId");

  const outOfSyncNodes = useRecoilValue(
    nodesThatMightBeOutOfSync({ toplevelNodeId }),
  );

  const nodesLoadable = useRecoilValueLoadable(
    nodesInOrganisationSelectorFamily({ organisationId }),
  );

  const folderItems =
    nodesLoadable.state !== "hasValue" ? undefined : nodesLoadable.contents;

  const showWarning = useMemo(() => {
    if (!folderItems) return false;
    return folderItems.some((item) =>
      outOfSyncNodes.some((nodeId) => item.id === nodeId),
    );
  }, [folderItems, outOfSyncNodes]);

  return showWarning ? (
    <WarningContainer>
      <Row style={{ alignItems: "center", gap: "1.2rem" }}>
        <Column style={{ gap: 0 }}>
          <Text>Updated by another user.</Text>
          <Text>Reload to see the latest changes</Text>
        </Column>
        <Button
          buttonType="secondary"
          text="Reload"
          onClick={() => window.location.reload()}
        />
      </Row>
    </WarningContainer>
  ) : (
    <></>
  );
};

const WarningContainer = styled.div`
  position: absolute;
  top: 0rem;
  left: 0;
  right: 0;
  margin: auto;

  background: ${colors.primary};
  padding: 0.6rem 1.6rem;
  width: fit-content;
  border-radius: 0.4rem;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
`;

const Text = styled.p`
  ${typography.semiBold};
  color: white;
  margin: 0;
`;
