import { useRef, useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { StandardBox } from "../../styles/boxes/Boxes";
import { colors } from "../../styles/colors";
import { spaceMedium, spaceSmall } from "../../styles/space";

const animationTime = 0.2;

export const ValidationContainer = styled(StandardBox)`
  text-align: center;
  // border: 1px solid ${colors.warning};
  padding: ${spaceSmall} ${spaceMedium};
  white-space: nowrap;
  p {
    color: ${colors.warning};
    margin: 0;
  }
`;

const ValidationMessageText = styled(ValidationContainer)<{
  offsetY?: number;
  offsetX?: number;
  show: boolean;
}>`
  opacity: ${(p) => (p.show ? 1 : 0)};
  -webkit-transition: opacity ${animationTime}s ease-in-out;
  -moz-transition: opacity ${animationTime}s ease-in-out;
  transition: opacity ${animationTime}s ease-in-out;
  position: absolute;
  z-index: 3;
  font-size: 1.2rem;
  transform: translateY(${(p) => p.offsetY ?? 0}px)
    translateX(${(p) => p.offsetX ?? 0}px);
`;

export const ValidationMessage = ({
  validationMessage,
}: {
  validationMessage: string;
}) => {
  const validationRef = useRef<HTMLInputElement | null>(null);

  //Have to re-render to know how much we should offset X
  const [rendered, setRendered] = useState(false);
  useEffect(() => {
    setRendered(true);
  }, []);
  const offsetX = useMemo(() => {
    if (!rendered || !validationRef.current) return;
    var rect = validationRef.current.getBoundingClientRect();

    const getParentOverflowing = (
      element: HTMLElement | null,
    ): HTMLElement | undefined => {
      if (!element) return;
      const style = getComputedStyle(element);
      if (style.overflow || style.overflowX || style.overflowY) return element;
      return getParentOverflowing(element.parentElement);
    };

    const parentOverFlowing = getParentOverflowing(
      validationRef.current.parentElement,
    );
    if (!parentOverFlowing) {
      if (rect.left < 0) return rect.left;
      if (rect.right > window.innerWidth) return window.innerWidth - rect.right;
      return 0;
    }

    var parentRect = parentOverFlowing.getBoundingClientRect();
    if (rect.left < parentRect.left) return parentRect.left - rect.left;
    if (rect.right > parentRect.right) return parentRect.right - rect.right;

    return 0;
  }, [rendered, validationRef]);

  return (
    <ValidationMessageText
      ref={validationRef}
      offsetX={offsetX}
      show={rendered}
    >
      {validationMessage}
    </ValidationMessageText>
  );
};

export const ValidationMessageMaybe = ({
  validationMessage,
  valid,
}: {
  validationMessage?: string;
  valid: boolean;
}) => {
  if (!validationMessage || valid) return null;

  return <ValidationMessage validationMessage={validationMessage} />;
};
