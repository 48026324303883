import { useRecoilValue } from "recoil";
import { addFeatureAtom, mapRefAtom } from "../../state/map";
import styled from "styled-components";
import { StandardBox } from "../../styles/boxes/Boxes";
import { useEffect, useState } from "react";
import { MapMouseEvent } from "mapbox-gl";

const OFFSET_Y_PX = 30;

interface WrapperProps {
  pos: number[];
}

const Wrapper = styled(StandardBox).attrs<WrapperProps>(({ pos }) => ({
  style: {
    left: `${pos[0]}px`,
    top: `${pos[1] + OFFSET_Y_PX}px`,
  },
}))<WrapperProps>`
  position: fixed;
  z-index: 1;
  transform: translateX(-50%);
  padding: 0.5rem;
`;

const FollowMouse = () => {
  const [pos, setPos] = useState<number[]>([0, 0]);
  const [lngLat, setLngLat] = useState<number[] | undefined>(undefined);
  const map = useRecoilValue(mapRefAtom);

  useEffect(() => {
    if (!map) return;

    const mouseMove = (e: MapMouseEvent) => {
      setPos(Object.values(e.point));
      setLngLat(Object.values(e.lngLat));
    };
    map.on("mousemove", mouseMove);
    return () => {
      map.off("mousemove", mouseMove);
    };
  }, [map, setPos, setLngLat]);

  if (!lngLat) return null;

  return (
    <Wrapper pos={pos}>
      lat: {lngLat[1].toFixed(4)} lng: {lngLat[0].toFixed(4)}
    </Wrapper>
  );
};

const CoordinateOnMouse = () => {
  const addFeature = useRecoilValue(addFeatureAtom);

  if (!addFeature || !addFeature.mode) return null;

  return <FollowMouse />;
};

export default CoordinateOnMouse;
