import { RefObject, useCallback, useEffect, useRef } from "react";

const DELTA = 6;
function hasMoved(p1: [number, number], p2: [number, number]) {
  const a = p1[0] - p2[0];
  const b = p1[1] - p2[1];
  var distance = Math.sqrt(a * a + b * b);
  return distance > DELTA;
}

/**
 *
 * @param htmlElement Target that is clicked outside of.
 * @param onClickOutside Callback
 * @param ignoreClickFn Callback ran on each node up the tree. If any call returns truthy, the click event is ignored, and {@link onClickOutside} is **not** called.
 * @param {Object} options Options for the hook
 * @param {boolean} options.ignoreDragClicks Disable onClickOutside if click is a drag (Default: false)
 * @param {boolean} options.runCheckOnMouseDown Run the check on mouseDown instead of mouseUp (Default: false)
 * @param {boolean} options.runCheckOnClick Run the check on click instead of mouseDown (Default: false)
 */
export function useClickOutside<E extends HTMLElement>(
  htmlElement: RefObject<E> | undefined,
  onClickOutside: (event: MouseEvent) => void,
  ignoreClickFn?: (
    target: EventTarget,
    source: E,
    event: MouseEvent,
  ) => boolean,
  options?: {
    ignoreDragClicks?: boolean;
    runCheckOnMouseDown?: boolean;
    runCheckOnClick?: boolean;
  },
) {
  const mouseDownPos = useRef<[number, number]>([0, 0]);

  const handleMouseUp = useCallback(
    (event: MouseEvent) => {
      if (!htmlElement) return;
      // This cast seems to be okay; see
      // https://stackoverflow.com/questions/61164018/typescript-ev-target-and-node-contains-eventtarget-is-not-assignable-to-node
      const targetNode = event.target as Node;
      if (
        options?.ignoreDragClicks &&
        hasMoved([event.clientX, event.clientY], mouseDownPos.current)
      ) {
        return;
      }
      const elem = htmlElement.current;

      if (
        elem !== null &&
        !elem.contains(targetNode) &&
        !event.composedPath().includes(elem) &&
        (!ignoreClickFn ||
          !event
            .composedPath()
            .some((target) => ignoreClickFn(target, elem, event)))
      ) {
        onClickOutside(event);
      }
    },
    [options?.ignoreDragClicks, htmlElement, ignoreClickFn, onClickOutside],
  );

  const onMouseDown = useCallback((event: MouseEvent) => {
    //Don't remove this, makes dropdowns in firefox break
    if ((event.target as any)?.["tagName"] === "OPTION") return;
    mouseDownPos.current = [event.clientX, event.clientY];
  }, []);

  useEffect(() => {
    if (!htmlElement) return;
    const documentToUse = htmlElement.current?.ownerDocument ?? document;

    if (options?.ignoreDragClicks) {
      documentToUse.addEventListener("mousedown", onMouseDown);
    }

    if (options?.runCheckOnClick) {
      documentToUse.addEventListener("click", handleMouseUp, true);
    } else if (options?.runCheckOnMouseDown) {
      documentToUse.addEventListener("mousedown", handleMouseUp);
    } else {
      documentToUse.addEventListener("mousedown", onMouseDown);
      documentToUse.addEventListener("mouseup", handleMouseUp, true);
    }

    return () => {
      documentToUse.removeEventListener("click", handleMouseUp, true);
      documentToUse.removeEventListener("mouseup", handleMouseUp, true);
      documentToUse.removeEventListener("mousedown", handleMouseUp);
      documentToUse.removeEventListener("mousedown", onMouseDown);
    };
  }, [
    handleMouseUp,
    onMouseDown,
    htmlElement,
    options?.runCheckOnMouseDown,
    options?.runCheckOnClick,
    options?.ignoreDragClicks,
  ]);
}
