import { projectFeaturesSelector } from "../ProjectElements/state";
import { atom, atomFamily, selector, selectorFamily } from "recoil";
import { replaceEndpointsLineString } from "../../types/turbines";
import { TouchdownPointFeature } from "../../types/feature";
import { SlackRegionFeature } from "../../types/feature";
import { MooringLineFeature } from "../../types/feature";
import { AnchorFeature } from "../../types/feature";
import {
  isAnchor,
  isDefined,
  isMooringLine,
  isMooringLineMultiple,
  isPolygonFeature,
  isTurbine,
} from "../../utils/predicates";
import {
  SLACK_REGION_PROPERTY_TYPE,
  TOUCHDOWN_PROPERTY_TYPE,
} from "../../constants/projectMapView";
import {
  getTurbinesSelectorFamily,
  getMooringLinesSelector,
  getAnchorsSelectorFamily,
} from "../../state/layout";
import { TurbineFeature } from "../../types/feature";
import { removeIndex } from "../../utils/utils";
import { scream } from "../../utils/sentry";
import { v4 as uuidv4 } from "uuid";
import * as turf from "@turf/turf";
import { defaultMooringParameters, MooringParameters } from "./types";
import { estimateLineLengthFromParams, touchdown } from "../../state/mooring";
import {
  allFloaterTypesSelector,
  allFoundationTypesSelector,
  foundationScale,
} from "../../state/foundations";
import {
  allSimpleTurbineTypesSelector,
  previewTurbinesState,
} from "../../state/turbines";
import { currentMooringLineTypesState } from "../../state/mooringLineType";
import { isFloater } from "../RightSide/InfoModal/FoundationModal/utils";
import { intersectAll } from "../../utils/turf";

const makeSlackRegion = (
  turbine: TurbineFeature,
  linesAndAnchors: [MooringLineFeature, AnchorFeature][],
): undefined | SlackRegionFeature => {
  const parkId = turbine.properties.parentIds?.[0];
  if (parkId === undefined)
    throw scream("turbine parkId was undefined", { turbine });

  const circles = linesAndAnchors
    .map(([line, anchor]) => {
      const p = anchor?.geometry.coordinates;
      const length = turf.length(line, {
        units: "meters",
      });
      const slackedLength = length * (1 + (line.properties.slack ?? 0.3));
      const circle = turf.circle(p, slackedLength, {
        steps: 64,
        units: "meters",
      });
      return circle;
    })
    .filter(isDefined);
  if (circles.length === 0) return undefined;

  const intersection = intersectAll(circles);

  if (!intersection || !isPolygonFeature(intersection)) return;

  const id = uuidv4();

  const feature: SlackRegionFeature = {
    ...intersection,
    id: id,
    properties: {
      name: "slack region",
      type: SLACK_REGION_PROPERTY_TYPE,
      id,
      turbine: turbine.id,
      parentIds: [parkId],
    },
  };

  return feature;
};

export const getSlackRegionForTurbine = selectorFamily<
  undefined | SlackRegionFeature,
  string
>({
  key: "getSlackRegionForTurbine",
  get:
    (turbineId: string) =>
    ({ get }) => {
      const features = get(projectFeaturesSelector);
      const turbine = features
        .filter(isTurbine)
        .find((f) => f.id === turbineId);
      if (!turbine) return undefined;

      const mooringLines = features
        .filter(isMooringLine)
        .filter((f) => f.properties.target === turbineId);

      const linesAndAnchors = features
        .filter(isAnchor)
        .map<[MooringLineFeature, AnchorFeature] | undefined>((anchor) => {
          const line = mooringLines.find(
            (ml) => ml.properties.anchor === anchor.id,
          );
          if (!line) return undefined;
          return [
            replaceEndpointsLineString(
              line,
              anchor.geometry.coordinates,
              turbine.geometry.coordinates,
            ),
            anchor,
          ];
        })
        .filter(isDefined);

      if (linesAndAnchors.length === 0) return undefined;

      return makeSlackRegion(turbine, linesAndAnchors);
    },
});

export const getTouchdownPointsForLine = selectorFamily<
  undefined | TouchdownPointFeature,
  { parkId: string; lineId: string; waterDepth: number }
>({
  key: "getTouchdownPointsForLine",
  get:
    ({ parkId, lineId, waterDepth }) =>
    ({ get }) => {
      const anchors = get(getAnchorsSelectorFamily(parkId));
      const allTurbineTypes = get(allSimpleTurbineTypesSelector);
      const lineTypes = get(currentMooringLineTypesState);
      const foundations = get(allFoundationTypesSelector);
      const mooringLines = get(getMooringLinesSelector(parkId));
      const line = mooringLines.find((l) => l.id === lineId);

      if (!line) return undefined;

      const turbines = get(getTurbinesSelectorFamily({ parkId }));
      const turbine = turbines.find((t) => t.id === line.properties.target);

      if (!turbine) return;

      const turbineType = allTurbineTypes.find(
        (t) => t.id === turbine.properties.turbineTypeId,
      );

      const anchor = anchors.find((a) => a.id === line.properties.anchor);

      const foundation = foundations.find(
        (f) => f.id === turbine.properties.foundationId,
      );

      if (!turbineType || !anchor || !foundation || !isFloater(foundation)) {
        return;
      }

      const scale = foundationScale({
        foundation: foundation,
        turbine: turbineType,
      });

      const fairRadius = (scale ?? 1) * (foundation.fairRadius ?? 0);
      const fairZ = (scale ?? 1) * (foundation.fairZ ?? 0);

      let lineLengths: number[] = [];
      let EAs: number[] = [];
      let wetWeights: number[] = [];
      let attachments: number[] = [];
      if (isMooringLineMultiple(line)) {
        for (let i = 0; i < line.properties["lineLengths"].length; i++) {
          lineLengths.push(1000 * line.properties["lineLengths"][i]);
          const lineType = lineTypes.find(
            (lt) => lt.id === line.properties["lineTypes"][i],
          );
          EAs.push(lineType?.EA ?? 1e2);
          wetWeights.push(lineType?.wetWeight ?? 1);
          attachments.push(line.properties["attachments"][i]);
        }
      } else if (line.properties.lineLength) {
        lineLengths.push(1000 * line.properties["lineLength"]);
        const lineType = lineTypes.find(
          (lt) => lt.id === line.properties["lineType"],
        );
        EAs.push(lineType?.EA ?? 1e2);
        wetWeights.push(lineType?.wetWeight ?? 1);
        attachments.push(0);
      }

      const bearing = turf.bearing(
        turbine.geometry.coordinates,
        anchor.geometry.coordinates,
      );

      const anchorRadius = turf.distance(
        turbine.geometry.coordinates,
        anchor.geometry.coordinates,
        { units: "meters" },
      );

      const touchdownRadius = touchdown({
        lineLengths,
        anchorRadius,
        waterDepth,
        fairRadius,
        fairZ,
        EAs,
        wetWeights,
        attachments,
      });

      if (touchdownRadius >= anchorRadius) return;

      const point = turf.destination(
        turbine.geometry.coordinates,
        touchdownRadius,
        bearing,
        { units: "meters" },
      );

      const id = uuidv4();

      const feature: TouchdownPointFeature = {
        type: "Feature",
        geometry: point.geometry,
        id: id,
        properties: {
          name: "Touchdown point",
          type: TOUCHDOWN_PROPERTY_TYPE,
          id,
          line: line.id,
          parentIds: [parkId],
        },
      };

      return feature;
    },
});

export const getTouchdownPointsInPark = selectorFamily<
  TouchdownPointFeature[],
  { parkId: string; waterDepths: { [key: string]: number } }
>({
  key: "getTouchdownPointsInPark",
  get:
    ({ parkId, waterDepths }) =>
    ({ get }) => {
      const mooringLines = get(getMooringLinesSelector(parkId));
      return mooringLines
        .map((line) =>
          get(
            getTouchdownPointsForLine({
              parkId,
              lineId: line.id,
              waterDepth: waterDepths[line.id],
            }),
          ),
        )
        .filter(isDefined);
    },
});

/**
 * These are the envelopes for when one anchor is broken.
 * If a turbine has three anchors this should return three slack regions,
 * one for each scenario of a mooring line breaking.
 */
export const getBreakingSlackRegionForTurbine = selectorFamily<
  undefined | SlackRegionFeature[],
  string
>({
  key: "getBreakingSlackRegionForTurbine",
  get:
    (turbineId: string) =>
    ({ get }) => {
      const features = get(projectFeaturesSelector);
      const turbine = features
        .filter(isTurbine)
        .find((f) => f.id === turbineId);
      if (!turbine) return undefined;

      const mooringLines = features
        .filter(isMooringLine)
        .filter((f) => f.properties.target === turbineId);

      const linesAndAnchors = features
        .filter(isAnchor)
        .map<[MooringLineFeature, AnchorFeature] | undefined>((anchor) => {
          const line = mooringLines.find(
            (ml) => ml.properties.anchor === anchor.id,
          );
          if (!line) return undefined;
          return [
            replaceEndpointsLineString(
              line,
              anchor.geometry.coordinates,
              turbine.geometry.coordinates,
            ),
            anchor,
          ];
        })
        .filter(isDefined);

      const n = linesAndAnchors.length;
      if (n === 0) return undefined;

      const subsets = Array.from({ length: n }, (_, i) => i).map((i) =>
        removeIndex(linesAndAnchors, i),
      );

      return subsets.map((s) => makeSlackRegion(turbine, s)).filter(isDefined);
    },
});

export const getSlackRegionsInPark = selectorFamily<
  SlackRegionFeature[],
  string
>({
  key: "getSlackRegionsInPark",
  get:
    (parkId) =>
    ({ get }) => {
      const turbines = get(getTurbinesSelectorFamily({ parkId }));
      return turbines
        .map((t) => get(getSlackRegionForTurbine(t.id)))
        .filter(isDefined);
    },
});

export const getBreakingSlackRegionsInPark = selectorFamily<
  SlackRegionFeature[],
  string
>({
  key: "getBreakingSlackRegionsInPark",
  get:
    (parkId) =>
    ({ get }) => {
      const turbines = get(getTurbinesSelectorFamily({ parkId }));
      return turbines
        .flatMap((t) => get(getBreakingSlackRegionForTurbine(t.id)))
        .filter(isDefined);
    },
});

export const getMooringParametersAtomFamily = atomFamily<
  MooringParameters,
  { foundationId: string }
>({
  key: "getMooringParametersAtomFamily",
  default: selector<MooringParameters>({
    key: "getMooringParametersAtomFamily.default",
    get: ({ get }) => {
      const lineTypes = get(currentMooringLineTypesState);
      const defaultParams = defaultMooringParameters(
        lineTypes.map((l) => l.id),
      );
      return defaultParams;
    },
  }),
});

export const getCableLengthSelectorFamily = selectorFamily<
  Pick<MooringParameters, "lineLength" | "lineLengths">,
  { foundationId: string; waterDepth: number }
>({
  key: "getCableLengthSelectorFamily",
  get:
    ({ foundationId, waterDepth }) =>
    ({ get }) => {
      const defaultParams = get(
        getMooringParametersAtomFamily({ foundationId }),
      );
      const lineTypes = get(currentMooringLineTypesState);
      const allFloaterTypes = get(allFloaterTypesSelector).filter(
        (t) => !t.archived,
      );

      const currentFoundation = allFloaterTypes.find(
        (f) => f.id === foundationId,
      );
      const lineLength = estimateLineLengthFromParams(
        defaultParams,
        lineTypes,
        waterDepth,
        currentFoundation,
      );

      if (!lineLength) {
        return {
          lineLength: defaultParams.lineLength,
          lineLengths: defaultParams.lineLengths,
        };
      }

      const unitLineLength =
        defaultParams.distanceMode === "km"
          ? lineLength / 1000
          : lineLength / waterDepth;
      const updatedLineLength = Math.round(100 * unitLineLength) / 100;

      const segTotalLineLength = defaultParams.lineLengths.reduce(
        (acc, l) => (acc += l),
        0,
      );
      const lineLengthFactor = unitLineLength / segTotalLineLength;
      const updatedLineLengths = defaultParams.lineLengths.map(
        (length) => Math.round(100 * length * lineLengthFactor) / 100,
      );

      return {
        lineLength: updatedLineLength,
        lineLengths: updatedLineLengths,
      };
    },
});

export type PreviewMooringAndFoundationState = {
  /** The temporary state. */
  preview: {
    foundations: { turbineId: string; foundationId: string }[];
    mooringLines: MooringLineFeature[];
    anchors: AnchorFeature[];
    /**
     * A turbine is "partial" if some of its anchors were not added due to
     * exclusion/park constraints.
     */
    partialTurbines?: string[];
  };
  /**
   * Also list out the existing mooring lines and anchors so that we can
   * visualize them in case we only generate new ones for a part of the park.
   */
  existing: {
    mooringLines: MooringLineFeature[];
    anchors: AnchorFeature[];
  };
};

export const previewMooringAndFoundationState = atom<
  PreviewMooringAndFoundationState | undefined
>({
  key: "previewMooringAndFoundationState",
  default: undefined,
});

/**
 * Mooring preview state that also takes the turbine preview state into
 * account. This is needed for joint generation.
 */
export const jointPreviewMooringState = selector<
  PreviewMooringAndFoundationState | undefined
>({
  key: "jointPreviewMooringState",
  get: ({ get }) => {
    const tp = get(previewTurbinesState);
    const p = get(previewMooringAndFoundationState);
    if (!tp || !p) return p;

    const visibleTurbineIds = new Set(
      tp.existing.map((t) => t.id).concat(tp.preview.map((t) => t.id)),
    );

    const mooringLines = p.existing.mooringLines.filter((ml) =>
      visibleTurbineIds.has(ml.properties.target),
    );
    const connectedAnchorIds = new Set(
      mooringLines.map((ml) => ml.properties.anchor),
    );
    const anchors = p.existing.anchors.filter((a) =>
      connectedAnchorIds.has(a.id),
    );

    return {
      preview: p.preview,
      existing: {
        mooringLines,
        anchors,
      },
    };
  },
});

export const showSlackRegionsAtom = atom({
  key: "showSlackRegionsAtom",
  default: true,
});

export const showBreakingSlackRegionsAtom = atom({
  key: "showBreakingZonesAtom",
  default: false,
});
