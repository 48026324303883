import { DefaultValue, atom, atomFamily, selectorFamily } from "recoil";
import { Dashboard } from "./types";
import { dndEntry } from "./utils";
import { isDefined } from "../../utils/predicates";
import { fetchListDashboards } from "./service";

export const VIND_DASHBOARDS = [
  {
    id: "vind-preset-dashboard-overview",
    name: "Overview",
    preset: true,
    rows: [
      {
        id: "row-1",
        height: 200,
        elements: [dndEntry("Park"), dndEntry("Map")].filter(isDefined),
      },
      {
        id: "row-2",
        height: 200,
        elements: [dndEntry("Energy"), dndEntry("Wind rose")].filter(isDefined),
      },
    ],
    widgetState: {},
  },
  {
    id: "vind-preset-dashboard-yield",
    name: "Yield",
    preset: true,
    rows: [
      {
        id: "row-1",
        height: 200,
        elements: [dndEntry("Turbines"), dndEntry("Wind rose")].filter(
          isDefined,
        ),
      },
      {
        id: "row-2",
        height: 200,
        elements: [
          dndEntry("Energy"),
          dndEntry("Losses"),
          dndEntry("Input"),
        ].filter(isDefined),
      },
      {
        id: "row-3",
        height: 200,
        elements: [
          dndEntry("Wind speed distribution"),
          dndEntry("Cumulative graph"),
        ].filter(isDefined),
      },
      {
        id: "row-4",
        height: 200,
        elements: [
          dndEntry("Monthly graph"),
          dndEntry("Annual variability"),
        ].filter(isDefined),
      },
    ],
    widgetState: {},
  },
  {
    id: "vind-preset-dashboard-electrical",
    name: "Electrical",
    preset: true,
    rows: [
      {
        id: "row-1",
        height: 200,
        elements: [dndEntry("Cabling"), dndEntry("Cable matrix map")].filter(
          isDefined,
        ),
      },
      {
        id: "row-2",
        height: 345,
        elements: [
          dndEntry("Cable matrix"),
          dndEntry("Inter array loss graph"),
        ].filter(isDefined),
      },
      {
        id: "row-3",
        height: 345,
        elements: [dndEntry("Export cables")].filter(isDefined),
      },
      {
        id: "row-4",
        height: 401,
        elements: [
          dndEntry("Export cable loading"),
          dndEntry("Export system voltage"),
        ].filter(isDefined),
      },
    ],
    widgetState: {},
  },
  {
    id: "vind-preset-dashboard-cost",
    name: "Cost",
    preset: true,
    rows: [
      {
        id: "row-1",
        height: 401,
        elements: [
          dndEntry("Lcoe table"),
          dndEntry("Capex table"),
          dndEntry("Park life cash flow"),
        ].filter(isDefined),
      },
      {
        id: "row-2",
        height: 345,
        elements: [
          dndEntry("IRR table"),
          dndEntry("Capex pie chart"),
          dndEntry("Map"),
        ].filter(isDefined),
      },
      {
        id: "row-3",
        height: 345,
        elements: [
          dndEntry("Cost input"),
          dndEntry("Capex input"),
          dndEntry("Other expenditures input"),
        ].filter(isDefined),
      },
    ],
    widgetState: {},
  },
  {
    id: "vind-preset-dashboard-mooring",
    name: "Mooring",
    preset: true,
    rows: [
      {
        id: "row-1",
        height: 50,
        elements: [dndEntry("Mooring lines")].filter(isDefined),
      },
      {
        id: "row-2",
        height: 200,
        elements: [
          dndEntry("Mooring line attachments"),
          dndEntry("Mooring line anchors"),
        ].filter(isDefined),
      },
    ],
    widgetState: {},
  },
  {
    id: "vind-preset-dashboard-foundation",
    name: "Foundation",
    preset: true,
    rows: [
      {
        id: "row-1",
        height: 50,
        elements: [
          dndEntry("Foundation totals"),
          dndEntry("3D-foundation"),
        ].filter(isDefined),
      },
      {
        id: "row-2",
        height: 100,
        elements: [dndEntry("Foundation key statistics")].filter(isDefined),
      },
      {
        id: "row-3",
        height: 100,
        elements: [dndEntry("Foundations")].filter(isDefined),
      },
    ],
    widgetState: {},
  },
];

export const dashboardsAtomFamily = atomFamily<
  Dashboard[],
  {
    nodeId: string;
  }
>({
  key: "dashboardsAtomFamily",
  default: selectorFamily({
    key: "dashboardsAtomFamily.default",
    get:
      ({ nodeId }) =>
      () =>
        fetchListDashboards(nodeId),
  }),
});

export const dashboardSelectorFamily = selectorFamily<
  Dashboard | undefined,
  { nodeId: string; dashboardId: string }
>({
  key: "dashboardSelectorFamily",
  get:
    ({ nodeId, dashboardId }) =>
    ({ get }) => {
      const dashboards = get(dashboardsAtomFamily({ nodeId }));
      return dashboards.find((d) => d.id === dashboardId);
    },
  set:
    ({ nodeId, dashboardId }) =>
    ({ set }, newValue: Dashboard | DefaultValue | undefined) => {
      if (newValue instanceof DefaultValue || newValue === undefined) {
        set(dashboardsAtomFamily({ nodeId }), (curr) =>
          curr.filter((d) => d.id !== dashboardId),
        );
      } else {
        set(dashboardsAtomFamily({ nodeId }), (curr) => {
          if (curr === undefined) return [newValue];
          if (curr.find((d) => d.id === newValue.id))
            return curr.map((d) => {
              if (d.id === newValue.id) return newValue;
              return d;
            });
          return curr.concat([newValue]);
        });
      }
    },
});
export const isPrintingAtom = atom({ key: "isPrintingAtom", default: false });
