import { useRefreshLibraryTurbines } from "components/ConfigurationModal/TurbineSettings/useRefreshCustomTurbines";
import { Mixpanel } from "mixpanel";
import { useRecoilCallback } from "recoil";
import {
  createNewOrgTurbine,
  deleteOrgTurbine,
  updateOrgTurbine,
} from "services/turbineAPIService";
import { useTypedPath } from "state/pathParams";
import { TurbineType } from "types/turbines";
import { organisationTurbineResourceState } from "../state";
import { fetchTurbineTypeUsage } from "state/turbines";

const useOrgTurbineCrud = () => {
  const { organisationId } = useTypedPath("organisationId");

  const refreshLibraryTurbines = useRefreshLibraryTurbines();

  const create = useRecoilCallback(
    ({ set, snapshot }) =>
      async ({
        turbine,
        name,
        projectAccess,
      }: {
        turbine: TurbineType;
        name?: string;
        projectAccess?: string[];
      }) => {
        const newTurbine = {
          ...turbine,
          name: name ? name : `${turbine.name} copy`,
        };

        const fallback = await snapshot.getPromise(
          organisationTurbineResourceState({ organisationId }),
        );

        try {
          const res = await createNewOrgTurbine(
            organisationId,
            newTurbine,
            projectAccess,
          );
          set(organisationTurbineResourceState({ organisationId }), (cur) => [
            ...cur,
            res,
          ]);
          refreshLibraryTurbines();
          Mixpanel.track("Created org turbine", {
            turbineId: turbine.id,
            organisationId,
            projectAccess,
          });
          return res;
        } catch (e) {
          set(organisationTurbineResourceState({ organisationId }), fallback);
          throw e;
        }
      },
    [organisationId, refreshLibraryTurbines],
  );

  const update = useRecoilCallback(
    ({ set, snapshot }) =>
      async (turbine: TurbineType) => {
        const fallback = await snapshot.getPromise(
          organisationTurbineResourceState({ organisationId }),
        );

        return updateOrgTurbine(organisationId, turbine)
          .then((res) => {
            if (res) {
              set(organisationTurbineResourceState({ organisationId }), (cur) =>
                cur.map((c) => (c.id === turbine.id ? res : c)),
              );
              refreshLibraryTurbines();
              Mixpanel.track("Update org turbine", {
                turbineId: turbine.id,
                organisationId,
              });
            }
          })
          .catch(() => {
            set(organisationTurbineResourceState({ organisationId }), fallback);
          });
      },
    [organisationId, refreshLibraryTurbines],
  );

  const remove = useRecoilCallback(
    ({ set, snapshot }) =>
      async (turbineId: string) => {
        const fallback = await snapshot.getPromise(
          organisationTurbineResourceState({ organisationId }),
        );

        const usage = await fetchTurbineTypeUsage(organisationId, turbineId);
        if (
          usage.length === 0 ||
          window.confirm(
            `Turbine type is currently being used in ${usage.length} turbines, are you sure you want to delete it?`,
          )
        ) {
          return deleteOrgTurbine(organisationId, turbineId)
            .then((res) => {
              if (res) {
                set(
                  organisationTurbineResourceState({ organisationId }),
                  (cur) => [...cur].filter((c) => c.id !== turbineId),
                );
                refreshLibraryTurbines();
                Mixpanel.track("Delete org turbine", {
                  turbineId,
                  organisationId,
                });
              }
            })
            .catch(() => {
              set(
                organisationTurbineResourceState({ organisationId }),
                fallback,
              );
            });
        }
      },
    [organisationId, refreshLibraryTurbines],
  );

  const duplicate = useRecoilCallback(
    () =>
      async ({
        turbine,
        name,
        projectAccess,
      }: {
        turbine: TurbineType;
        name?: string;
        projectAccess?: string[];
      }) => {
        return create({ turbine, name, projectAccess });
      },
    [create],
  );

  return { create, remove, update, duplicate };
};

export default useOrgTurbineCrud;
