import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalTypeOpenAtom } from "../../state/modal";
import FullScreenModal from "../FullScreenModal/FullScreenModal";
import Button from "../General/Button";
import { Column, ModalFrame } from "../General/Layout";
import styled from "styled-components";
import { spaceLarge } from "../../styles/space";

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  div {
  }
`;

export const reloadForNewVersionModalType = "ReloadForNewVersionModal";

const ReloadForNewVersionModalInner = () => {
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);

  return (
    <FullScreenModal>
      <ModalFrame title={"New version available"}>
        <Column style={{ gap: spaceLarge }}>
          <p>
            A new version of the app has been deployed which requires a refresh
            in order to load. You can continue using the current version but it
            might be unstable.
          </p>
          <ButtonRow>
            <Button
              text="Cancel"
              buttonType="secondary"
              onClick={() => setModalTypeOpen(undefined)}
            />
            <Button
              text="Reload"
              buttonType="primary"
              onClick={() => window.location.reload()}
            />
          </ButtonRow>
        </Column>
      </ModalFrame>
    </FullScreenModal>
  );
};

const ReloadForNewVersionModal = () => {
  const modalTypeOpen = useRecoilValue(modalTypeOpenAtom);
  if (
    !modalTypeOpen ||
    modalTypeOpen?.modalType !== reloadForNewVersionModalType
  )
    return null;

  return <ReloadForNewVersionModalInner />;
};

export default ReloadForNewVersionModal;
