import React, { Suspense, useEffect } from "react";
import { ContentContainer } from "../Shared/styles";
import SettingsSubMenu from "../Shared/SettingsSubMenu";
import { ProjectConfigModalTypeV2 } from "../../../state/configuration";
import {
  useSetRecoilState,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
} from "recoil";
import { modalTypeOpenAtom } from "../../../state/modal";
import useProjectConfiguration from "./Data/useProjectConfiguration";
import { selectedMenuItemState, unsavedSettingsState } from "../Shared/state";
import { editorAccessProjectSelector } from "state/user";
import { projectIdSelector } from "state/pathParams";
import TopBarModal, {
  TopBarModalHeader,
} from "components/FullScreenModal/TopBarModal";
import { showComponentsPreviewAtom } from "components/ComponentsPreview/state";

const ProjectConfigurationModalInner = () => {
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);

  const projectConfiguration = useProjectConfiguration();
  const { title, items } = projectConfiguration;
  const unsavedSettings = useRecoilValue(unsavedSettingsState);

  return (
    <>
      <TopBarModalHeader
        title={title}
        onClose={() => {
          if (unsavedSettings) {
            if (
              window.confirm("Are you sure you want to quit without saving?")
            ) {
              setModalTypeOpen(undefined);
            }
          } else {
            setModalTypeOpen(undefined);
          }
        }}
      />
      <ContentContainer style={{ padding: 0 }}>
        <SettingsSubMenu
          parentId={ProjectConfigModalTypeV2}
          submenus={items}
          depth={0}
        />
      </ContentContainer>
    </>
  );
};
const ProjectConfigurationModal = () => {
  const [modalTypeOpen, setModalTypeOpen] = useRecoilState(modalTypeOpenAtom);
  const showComponentsPreview = useRecoilValue(showComponentsPreviewAtom);
  const projectId = useRecoilValue(projectIdSelector);

  const editorAccessProject = useRecoilValueLoadable(
    editorAccessProjectSelector,
  );
  const noAccess =
    editorAccessProject.state !== "hasValue" || !editorAccessProject.contents;

  const [selectedItemId, setSelectedItemId] = useRecoilState(
    selectedMenuItemState({
      menuId: ProjectConfigModalTypeV2,
      projectId: projectId ?? "",
    }),
  );

  useEffect(() => {
    if (modalTypeOpen?.modalType === ProjectConfigModalTypeV2) {
      const defaultSelection = modalTypeOpen.metadata?.selectedMenuId;
      if (defaultSelection) {
        setModalTypeOpen((curr) => {
          if (curr?.modalType === ProjectConfigModalTypeV2) {
            return {
              ...curr,
              metadata: {
                ...curr.metadata,
                selectedMenuId: undefined,
              },
            };
          }
          return curr;
        });
        setSelectedItemId(defaultSelection);
      } else if (!selectedItemId) {
        setSelectedItemId("analysis");
      }
    }
  }, [modalTypeOpen, selectedItemId, setModalTypeOpen, setSelectedItemId]);

  if (
    noAccess ||
    projectId == null ||
    modalTypeOpen?.modalType !== ProjectConfigModalTypeV2
  )
    return null;

  return (
    <TopBarModal disableClose={showComponentsPreview.show}>
      <Suspense
        fallback={
          <TopBarModalHeader
            title="Loading content"
            onClose={() => setModalTypeOpen(undefined)}
          />
        }
      >
        <ProjectConfigurationModalInner />
      </Suspense>
    </TopBarModal>
  );
};

export default ProjectConfigurationModal;
