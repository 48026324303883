import { MenuFrame } from "../MenuPopup/CloseableMenuPopup";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { lowerRightMenuActiveModeAtom } from "../../state/layer";
import Checkbox from "../General/Checkbox";
import { userHintSettingsAtom } from "../ActiveTips/CloseableHints/state";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { drawCableCorridorHelpHintType } from "../ActiveTips/CloseableHints/DrawCableCorridorHelp";
import { drawLineStringHelpHintType } from "../ActiveTips/CloseableHints/DrawLineStringHelp";
import { drawPointHelpHintType } from "../ActiveTips/CloseableHints/DrawPointHelp";
import { drawPolygonHelpHintType } from "../ActiveTips/CloseableHints/DrawPolygonHelp";
import { colors } from "../../styles/colors";
import { typography } from "../../styles/typography";
import { SkeletonText } from "../Loading/Skeleton";
import { shiftDragSelectHelpHintType } from "../ActiveTips/CloseableHints/ShiftDragSelectHelp";
import { changeFeatureHelpHintType } from "../ActiveTips/PositionHints/ChangeFeatureTypeHelp";
import { AllHints } from "../ActiveTips/AllHints";
import { addExternalDataToProjectHelpHintType } from "../ActiveTips/PositionHints/AddExternalDataToProjectHelp";
import { splitMultiFeatureHelpHintType } from "../ActiveTips/PositionHints/SplitMultiFeatureHelp";
import { resetOrientationHelpHelpHintType } from "../ActiveTips/PositionHints/ResetOrientationHelp";
import { usePutUserHintSettings } from "../ActiveTips/CloseableHints/hooks";
import { avoidSnappingHintType } from "../ActiveTips/CloseableHints/AvoidSnapping";
import { benchmarkingArticleHintType } from "../ActiveTips/PositionHints/AnalysisBenchmarking";
import { activeTourState } from "components/OnboardingTours/state";
import {
  DEFAULT_ONBOARDING_TOUR,
  TUTORIAL_PROJECT_SEARCH_PARAM,
} from "components/OnboardingTours/Tour";
import { useSearchParams } from "react-router-dom";
import { spacing8 } from "styles/space";

export const HintsMenuType = "HintsMenu";

const hintIdToTextTitle = {
  [benchmarkingArticleHintType]: "🔍 Benchmarking",
  [drawCableCorridorHelpHintType]: "🔌 Draw cable corridor",
  [drawLineStringHelpHintType]: "✍️ Draw line",
  [drawPointHelpHintType]: "✍️ Place point",
  [drawPolygonHelpHintType]: "✍️ Draw polygon",
  [avoidSnappingHintType]: "✍️ Avoid snapping",
  [shiftDragSelectHelpHintType]: "✍️ Multiselect features",
  [changeFeatureHelpHintType]: "✏️ Change feature type",
  [addExternalDataToProjectHelpHintType]: "✏️ Add external data to project",
  [splitMultiFeatureHelpHintType]: "✏️ Split multi part feature",
  [resetOrientationHelpHelpHintType]: "🎥 Reset orientation",
};

type HintID = keyof typeof hintIdToTextTitle;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: flex-end;
`;

const ClosedHintsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 2rem 0 0 0;
`;

const OpenHintHidden = styled.div`
  opacity: 0;
  transition: opacity 0.25s;
  color: ${colors.primary};
  cursor: pointer;
`;

const Padding = styled.div`
  margin: 0 2rem 0 0;
`;

const ClosedHintWrapper = styled.div`
  ${typography.label}
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  padding: 0.5rem 1.7rem;

  &:hover {
    background-color: ${colors.hover};
    ${OpenHintHidden} {
      opacity: 1;
    }
  }
`;

const ShowHint = ({
  hintId,
  close,
}: {
  hintId: string | undefined;
  close: () => void;
}) => {
  if (!hintId) return null;
  return <AllHints hintId={hintId} closeCallback={close} />;
};

const ClosedHint = ({
  id,
  setHintId,
}: {
  id: HintID;
  setHintId: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  return (
    <ClosedHintWrapper>
      <div>{hintIdToTextTitle[id] ?? id}</div>
      <OpenHintHidden onClick={() => setHintId(id)}>Open hint</OpenHintHidden>
    </ClosedHintWrapper>
  );
};

const ShowHintsCheckbox = () => {
  const userHintSettings = useRecoilValue(userHintSettingsAtom);
  const putUserHintSettings = usePutUserHintSettings();
  const toggleHideHintsCallback = useCallback(() => {
    return putUserHintSettings({
      ...userHintSettings,
      hideAllTips: !userHintSettings.hideAllTips,
    });
  }, [putUserHintSettings, userHintSettings]);

  return (
    <Padding>
      <Checkbox
        label="Give me hints while working"
        labelPlacement="after"
        checked={!userHintSettings.hideAllTips}
        onChange={toggleHideHintsCallback}
      />
    </Padding>
  );
};

const RestartOnboardingTourButton = () => {
  const setActiveTour = useSetRecoilState(activeTourState);

  return (
    <ClosedHintWrapper style={{ marginBottom: spacing8 }}>
      <div>⏱ Onboarding tour</div>
      <OpenHintHidden onClick={() => setActiveTour(DEFAULT_ONBOARDING_TOUR)}>
        Restart
      </OpenHintHidden>
    </ClosedHintWrapper>
  );
};

const Hints = () => {
  const [hintId, setHintId] = useState<string | undefined>();
  const [lowerRightActiveMode, setLowerRightActiveMode] = useRecoilState(
    lowerRightMenuActiveModeAtom,
  );
  const [searchParams] = useSearchParams();
  const isTutorialProject = searchParams.get(TUTORIAL_PROJECT_SEARCH_PARAM);
  if (lowerRightActiveMode !== HintsMenuType) return null;

  return (
    <Wrapper>
      <ShowHint hintId={hintId} close={() => setHintId(undefined)} />
      <MenuFrame
        title="Hints 💡"
        onExit={() => setLowerRightActiveMode(undefined)}
        style={{ padding: "1rem 0", height: "fit-content" }}
      >
        {isTutorialProject && (
          <React.Suspense fallback={<SkeletonText />}>
            <RestartOnboardingTourButton />
          </React.Suspense>
        )}
        <React.Suspense fallback={<SkeletonText />}>
          <ShowHintsCheckbox />
        </React.Suspense>
        <ClosedHintsWrapper>
          {Object.keys(hintIdToTextTitle).map((id) => (
            <ClosedHint
              id={
                id as HintID // safety: it's the key to hintIdToTextTitle.
              }
              key={id}
              setHintId={setHintId}
            />
          ))}
        </ClosedHintsWrapper>
      </MenuFrame>
    </Wrapper>
  );
};

export default Hints;
