import React, { useRef } from "react";
import { spaceMedium } from "styles/space";
import { Group } from "components/Organisation/Groups/types";
import { useClickOutside } from "hooks/useClickOutside";
import { Frame } from "components/General/Layout";
import { UserGroupPicker } from "components/Organisation/OrganisationRightSide/content/UserGroupModal";
import { FrameLoader } from "./shared";

const GroupPickerFrame = ({
  organisationId,
  selectedGroups,
  setSelectedGroups,
  onClose,
}: {
  organisationId: string;
  selectedGroups: Group[];
  setSelectedGroups: React.Dispatch<React.SetStateAction<Group[]>>;
  onClose(): void;
}) => {
  const frameRef = useRef<HTMLDivElement>(null);
  useClickOutside(frameRef, onClose);

  return (
    <Frame
      ref={frameRef}
      style={{
        position: "absolute",
        right: spaceMedium,
        overflowY: "auto",
        minWidth: "40rem",
      }}
    >
      <React.Suspense fallback={<FrameLoader />}>
        <UserGroupPicker
          organisationId={organisationId}
          onClose={onClose}
          selectedGroups={selectedGroups}
          existingMemberships={[]}
          toggleGroupSelection={(group: Group) => {
            setSelectedGroups((prevSelectedGroups) => {
              if (prevSelectedGroups.find((g) => g.id === group.id)) {
                return prevSelectedGroups.filter((g) => g.id !== group.id);
              } else {
                return [...prevSelectedGroups, group];
              }
            });
          }}
        />
      </React.Suspense>
    </Frame>
  );
};

export default GroupPickerFrame;
