import React, { useState } from "react";
import { ColoredGrid } from "components/General/Form";
import { ChevronIcon } from "components/ToggleableList/ToggleableList";
import { useRecoilValueLoadable } from "recoil";
import {
  ProdId,
  getAnalysisWindStats,
  getWindSourceConfiguration,
} from "./state";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { Suspense, useMemo } from "react";
import { Column } from "../General/Layout";
import { colors } from "styles/colors";
import styled from "styled-components";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import {
  ResultValue,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";

const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 0.5rem;
  svg {
    width: 10px;
    height: 10px;
    path {
      stroke: ${colors.iconInfo};
    }
  }
`;

const Inner = ({ id }: { id: ProdId }) => {
  const windStats = useRecoilValueLoadable(
    getAnalysisWindStats(id),
  ).valueMaybe();

  const windConfiguration = useRecoilValueLoadable(
    getWindSourceConfiguration(id),
  ).valueMaybe();

  const sourceName = useMemo(() => {
    if (!windStats) return;
    const source = windStats.source;
    return source === "custom" ? "Custom" : source.toUpperCase();
  }, [windStats]);

  const spatialCalibration = useMemo(() => {
    if (!windStats) return "-";
    if (windStats.spatialCalibration?.type === "global_wind_atlas")
      return `GWA (${windStats.spatialCalibration.value})`;
    if (windStats.spatialCalibration?.type === "custom")
      return `Custom (${windStats.spatialCalibration.value})`;
    return "-";
  }, [windStats]);

  const percentCalibration = useMemo(() => {
    if (!windConfiguration?.calibration) return "-";
    if (windConfiguration.calibration.value)
      return `${windConfiguration.calibration.value} %`;
    return "-";
  }, [windConfiguration]);

  if (!windStats)
    return (
      <Column>
        <SkeletonBlock style={{ height: "1rem" }} />
        <SkeletonBlock style={{ height: "1rem" }} />
        <SkeletonBlock style={{ height: "1rem" }} />
      </Column>
    );

  return (
    <>
      <ColoredGrid
        style={{ gridTemplateColumns: "2fr 2fr", margin: "1rem 0 0 0" }}
      >
        <p>Source</p>
        <ResultValue>
          <strong>{sourceName}</strong>
        </ResultValue>
        <p>Position</p>
        <ResultValue>
          <strong>
            {windStats.longitude.toFixed(3)}, {windStats.latitude.toFixed(3)}
          </strong>
        </ResultValue>
        <p>Spatial calibration</p>
        <ResultValue>
          <strong>{spatialCalibration}</strong>
        </ResultValue>
        <p>Percent calibration</p>
        <ResultValue>
          <strong>{percentCalibration}</strong>
        </ResultValue>
        <p>Height</p>
        <ResultValue>
          <strong>{windStats.height.toFixed(0)}</strong> m
        </ResultValue>
        <Wrapper>
          <p>Wind speed</p>
          <HelpTooltip
            size={10}
            text={`Mean speed at wind rose position for current height (${150}m). Without any calibration.`}
          />
        </Wrapper>
        <ResultValue>
          <strong>{windStats.meanSpeed.toFixed(2)}</strong> m/s
        </ResultValue>
        <p
          style={{
            whiteSpace: "nowrap",
            textOverflow: "visible",
          }}
        >
          Avg. power-law coeff.
        </p>
        <ResultValue>
          <strong>{windStats.meanAlpha.toFixed(3)}</strong>
        </ResultValue>
        <Wrapper>
          <p>Air density</p>
          <HelpTooltip
            size={10}
            text={"Air density as specified in the analysis configuration"}
          />
        </Wrapper>
        <ResultValue>
          <strong>{windStats.meanDensity.toFixed(3)}</strong> kg/m3
        </ResultValue>
      </ColoredGrid>
    </>
  );
};

export const WindStatistics = ({ id }: { id: ProdId }) => {
  const [open, setOpen] = useState(true);
  return (
    <>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <SubtitleWithLine
          text={"Wind statistics"}
          expandButton={
            <ChevronIcon
              open={open}
              chevronSize={"1.4rem"}
              style={{
                alignSelf: "center",
              }}
            />
          }
        />
      </div>
      {open && (
        <Suspense
          fallback={<SkeletonBlock style={{ height: "1rem", width: "100%" }} />}
        >
          <Inner id={id} />
        </Suspense>
      )}
    </>
  );
};
