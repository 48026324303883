import { useEffect } from "react";
import { useRecoilSnapshot } from "recoil";

// https://recoiljs.org/docs/guides/dev-tools#observing-all-state-changes
const DebugObserver = () => {
  const snapshot = useRecoilSnapshot();

  useEffect(() => {
    for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
      const contents = snapshot.getLoadable(node).contents;
      console.debug(node.key, contents);
    }
  }, [snapshot]);

  return null;
};

export default DebugObserver;
