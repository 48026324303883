import GeoTIFF, { fromBlob } from "geotiff";
import { atom, selectorFamily } from "recoil";
import { getGeotiffImage } from "../services/projectDataAPIService";
import { GeoTiffUserUploadedImageType } from "../services/types";

export const imageToGeorefAtom = atom<undefined | File>({
  key: "imageToGeorefAtom",
  default: undefined,
  dangerouslyAllowMutability: true,
});

export const getGeorefImageSelectorFamily = selectorFamily<
  GeoTIFF | undefined,
  { projectId: string; branchId: string; filename: string }
>({
  key: "getGeorefImageSelectorFamily",
  get:
    ({ projectId, filename }) =>
    async () => {
      if (!projectId) return;

      const blob = await getGeotiffImage(
        projectId,
        filename,
        GeoTiffUserUploadedImageType,
      );
      return blob ? await fromBlob(blob) : undefined;
    },
  dangerouslyAllowMutability: true,
});
