import styled from "styled-components";
import { colors } from "styles/colors";
import { spacing6 } from "styles/space";

export const RoundIconWrapper = styled.div`
  background-color: ${colors.blue900};
  border-radius: 50%;
  padding: ${spacing6};
  box-sizing: border-box;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
`;
