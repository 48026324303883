import {
  useSetRecoilState,
  useRecoilCallback,
  Snapshot,
  RecoilState,
} from "recoil";
import {
  ABLY_REPLY_REACTIONS_ADD_FILTER,
  ABLY_REPLY_REACTIONS_REMOVE_FILTER,
} from "../../../state/ably";
import { useTypedPath } from "../../../state/pathParams";
import { postReplyReaction, deleteReplyReaction } from "../service";
import {
  userReactionSaveInProgressState,
  threadRepliesAtomFamily,
  replyReactionsCounterAtomFamily,
  userReactionsAtomFamily,
} from "../state";
import { Reaction } from "../types";
import { useAblyPublish } from "../../../hooks/useAblyPublish";

export const useReplyReactionCrud = () => {
  const { projectId: projectNodeId, branchId } = useTypedPath(
    "projectId",
    "branchId",
  );
  const ablyPublish = useAblyPublish();

  const setReactionSaveInProgress = useSetRecoilState(
    userReactionSaveInProgressState,
  );

  const post = useRecoilCallback(
    ({ set }) =>
      async (threadId: string, replyId: string) => {
        if (!projectNodeId) return;
        setReactionSaveInProgress(replyId);
        const res = await postReplyReaction(
          projectNodeId,
          branchId,
          threadId,
          replyId,
          {
            reaction: "like",
          },
        ).finally(() => setReactionSaveInProgress(undefined));

        set(
          threadRepliesAtomFamily({
            nodeId: projectNodeId,
            branchId,
            threadId,
          }),
          (cur) => {
            return cur.map((c) =>
              c.replyId === replyId ? { ...c, reactions: c.reactions + 1 } : c,
            );
          },
        );
        set(
          replyReactionsCounterAtomFamily({
            nodeId: projectNodeId,
            branchId,
            threadId,
            replyId,
          }),
          (cur) => {
            return cur + 1;
          },
        );
        set(
          userReactionsAtomFamily({ nodeId: projectNodeId, branchId }),
          (cur) => {
            return [...cur, res];
          },
        );
        ablyPublish(
          `${projectNodeId}:all`,
          ABLY_REPLY_REACTIONS_ADD_FILTER,
          res,
        );
        return res;
      },
    [ablyPublish, branchId, projectNodeId, setReactionSaveInProgress],
  );

  const remove = useRecoilCallback(
    ({ set }) =>
      async (threadId: string, replyId: string, reactionId: string) => {
        if (!projectNodeId) return;
        set(
          threadRepliesAtomFamily({
            nodeId: projectNodeId,
            branchId,
            threadId,
          }),
          (cur) => {
            return cur.map((c) =>
              c.replyId === replyId ? { ...c, reactions: c.reactions - 1 } : c,
            );
          },
        );
        set(
          userReactionsAtomFamily({ nodeId: projectNodeId, branchId }),
          (cur) => {
            return cur.filter((c) => c.reactionId !== reactionId);
          },
        );
        const res = await deleteReplyReaction(
          projectNodeId,
          branchId,
          threadId,
          replyId,
          reactionId,
        );
        set(
          replyReactionsCounterAtomFamily({
            nodeId: projectNodeId,
            branchId,
            threadId,
            replyId,
          }),
          (cur) => {
            return cur + 1;
          },
        );
        ablyPublish(
          `${projectNodeId}:all`,
          ABLY_REPLY_REACTIONS_REMOVE_FILTER,
          {
            threadId,
            replyId,
            reactionId,
          },
        );
        return res;
      },
    [ablyPublish, projectNodeId, branchId],
  );

  const localPost = useRecoilCallback(
    ({ set, snapshot }) =>
      async (reaction: Reaction) => {
        initializeAndSet(
          snapshot,
          set,
          threadRepliesAtomFamily({
            nodeId: reaction.nodeId,
            branchId: reaction.branchId,
            threadId: reaction.threadId,
          }),
          (cur) => {
            return cur.map((c) =>
              c.replyId === reaction.replyId
                ? { ...c, reactions: c.reactions + 1 }
                : c,
            );
          },
        );

        if (reaction.replyId) {
          initializeAndSet(
            snapshot,
            set,
            replyReactionsCounterAtomFamily({
              nodeId: reaction.nodeId,
              branchId: reaction.branchId,
              threadId: reaction.threadId,
              replyId: reaction.replyId,
            }),
            (cur) => {
              return cur + 1;
            },
          );
        }
      },
    [],
  );

  const localRemove = useRecoilCallback(
    ({ set, snapshot }) =>
      async (threadId: string, replyId: string) => {
        if (!projectNodeId) return;
        initializeAndSet(
          snapshot,
          set,
          threadRepliesAtomFamily({
            nodeId: projectNodeId,
            branchId,
            threadId,
          }),
          (cur) => {
            return cur.map((c) =>
              c.replyId === replyId ? { ...c, reactions: c.reactions - 1 } : c,
            );
          },
        );
        initializeAndSet(
          snapshot,
          set,
          replyReactionsCounterAtomFamily({
            nodeId: projectNodeId,
            branchId,
            threadId,
            replyId,
          }),
          (cur) => {
            return cur + 1;
          },
        );
      },
    [branchId, projectNodeId],
  );

  return { post, remove, localPost, localRemove };
};

export async function initializeAndSet<T>(
  snapshot: Snapshot,
  set: (
    recoilVal: RecoilState<T>,
    valOrUpdater: T | ((currVal: T) => T),
  ) => void,
  recoilVal: RecoilState<T>,
  valOrUpdater: T | ((currVal: T) => T),
) {
  const isInitialized =
    snapshot.getInfo_UNSTABLE(recoilVal).loadable?.state === "hasValue";

  if (!isInitialized) {
    return snapshot
      .getPromise(recoilVal)
      .then(() => set(recoilVal, valOrUpdater))
      .catch((e) => console.log(e));
  } else {
    set(recoilVal, valOrUpdater);
  }
}
