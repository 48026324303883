/// <reference types="vite-plugin-svgr/client" />
import React from "react";
import { useSetRecoilState } from "recoil";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import VersionHistoryIcon from "@icons/14/VersionHistoryAlt.svg?react";
import { ProjectFeature } from "../../../../types/feature";
import { openedFeatureIdForVersionHistoryAtom } from "../../../FeatureVersionsPopup/state";
import { MenuItem } from "../../../General/Menu";

const ShowPreviousVersionsMenuItem = ({
  closeMenu,
  feature,
}: {
  feature: MapboxGeoJSONFeature | ProjectFeature;
  closeMenu: () => void;
}) => {
  const setOpenedFeatureIdForVersionHistory = useSetRecoilState(
    openedFeatureIdForVersionHistoryAtom,
  );

  return (
    <MenuItem
      name="See previous versions"
      icon={<VersionHistoryIcon />}
      onClick={() => {
        setOpenedFeatureIdForVersionHistory(feature.properties!.id);
        closeMenu();
      }}
    />
  );
};

export default ShowPreviousVersionsMenuItem;
