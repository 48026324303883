import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { turbinesEditableSourceId } from "../../constants/draw";
import {
  getAnchorsSelectorFamily,
  getMooringLinesSelector,
} from "../../state/layout";
import { mapRefAtom } from "../../state/map";
import {
  AnchorFeature,
  MooringLineFeature,
  ParkFeature,
  TurbineFeature,
} from "../../types/feature";
import { pointInPolygon } from "../../utils/geometry";
import { sendInfo } from "../../utils/sentry";
import { isNever } from "../../utils/utils";
import { previewMooringAndFoundationState } from "./state";
import { Mode } from "./GenerateFoundationAnchorMenu";

export const FixedFoundationInner = ({
  park,
  mode,
  fixedTurbines,
  unavailableTurbines,
  selectedTurbines,
  currentFoundationId,
}: {
  park: ParkFeature;
  mode: Mode;
  fixedTurbines: TurbineFeature[];
  unavailableTurbines: TurbineFeature[];
  selectedTurbines: TurbineFeature[];
  currentFoundationId: string;
}) => {
  const map = useRecoilValue(mapRefAtom);

  const anchors = useRecoilValue(getAnchorsSelectorFamily(park.id));
  const mooringLines = useRecoilValue(getMooringLinesSelector(park.id));

  const setPreviewMooringAndFoundationState = useSetRecoilState(
    previewMooringAndFoundationState,
  );

  const previewFoundations = useMemo(
    () =>
      fixedTurbines.map((t) => ({
        turbineId: t.id,
        foundationId: currentFoundationId,
      })),
    [currentFoundationId, fixedTurbines],
  );

  useEffect(() => {
    setPreviewMooringAndFoundationState((curr) =>
      curr
        ? {
            preview: {
              ...curr.preview,
              foundations: previewFoundations,
            },
            existing: curr.existing,
          }
        : undefined,
    );
  }, [
    setPreviewMooringAndFoundationState,
    currentFoundationId,
    previewFoundations,
  ]);

  const resetMooring = useCallback(() => {
    if (mode.mode === "illegal") {
      sendInfo(
        "Tried to save with illegal state. Shouln't be possible, but no biggie",
        { mode },
      );
      return;
    }
    const existingMooringLines: MooringLineFeature[] = [];
    const existingAnchors: AnchorFeature[] = [];

    if (mode.mode === "park") {
      const keptMooringLines = mooringLines.filter(
        (ml) => !selectedTurbines.find((t) => t.id === ml.properties.target),
      );
      const keptAnchors = anchors.filter((a) =>
        keptMooringLines.find((ml) => ml.properties.anchor === a.id),
      );
      existingMooringLines.push(...keptMooringLines);
      existingAnchors.push(...keptAnchors);
    } else if (mode.mode === "zone") {
      const turbineIsInSelectedZone = (t: TurbineFeature) =>
        mode.subAreas.some((z) => pointInPolygon(t.geometry, z.geometry));

      const turbinesInZone = selectedTurbines.filter(turbineIsInSelectedZone);
      const keptMooringLines = mooringLines.filter(
        (ml) => !turbinesInZone.find((t) => t.id === ml.properties.target),
      );
      const keptAnchors = anchors.filter((a) =>
        keptMooringLines.find((ml) => ml.properties.anchor === a.id),
      );
      existingMooringLines.push(...keptMooringLines);
      existingAnchors.push(...keptAnchors);
    } else if (mode.mode === "turbines") {
      const keptMooringLines = mooringLines.filter(
        (ml) => !mode.turbines.find((t) => t.id === ml.properties.target),
      );
      const keptAnchors = anchors.filter((a) =>
        keptMooringLines.find((ml) => ml.properties.anchor === a.id),
      );
      existingMooringLines.push(...keptMooringLines);
      existingAnchors.push(...keptAnchors);
    } else {
      isNever(mode);
    }

    setPreviewMooringAndFoundationState({
      preview: {
        foundations: previewFoundations,
        mooringLines: [],
        anchors: [],
      },
      existing: {
        mooringLines: existingMooringLines,
        anchors: existingAnchors,
      },
    });
  }, [
    anchors,
    mode,
    mooringLines,
    selectedTurbines,
    setPreviewMooringAndFoundationState,
    previewFoundations,
  ]);

  const [c, C] = useState(0);
  useEffect(() => {
    if (c === 0) {
      C(c + 1);
      resetMooring();
    }
  }, [c, resetMooring]);

  useEffect(() => {
    if (!map) return;
    const turbinesEditableSource = map.getSource(turbinesEditableSourceId);

    if (0 < unavailableTurbines.length) {
      unavailableTurbines.forEach((t) => {
        turbinesEditableSource &&
          map.setFeatureState(
            { source: turbinesEditableSourceId, id: t.id },
            { overlap: true },
          );
      });
      return () => {
        unavailableTurbines.forEach((t) => {
          turbinesEditableSource &&
            map.removeFeatureState(
              { source: turbinesEditableSourceId, id: t.id },
              "overlap",
            );
        });
      };
    }
  }, [map, unavailableTurbines]);

  return <></>;
};
