import { useEffect } from "react";
import { selectorFamily, useRecoilValue } from "recoil";
import { getParkFeatureSelectorFamily } from "../../state/park";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { WarningProps } from "./ValidationWarnings";
import { ParkFeature } from "../../types/feature";
import { branchIdSelector, projectIdSelector } from "../../state/pathParams";
import {
  exportCableErrorKilometer,
  exportCableWarningKilometer,
} from "@constants/production";
import { getExportCablesInBranchSelectorFamily } from "state/cable";
import * as turf from "@turf/turf";
import { branchSelectedConfigurationAtomFamily } from "state/configuration";

export const exportCableTooLongWarningErrorSelectorFamily = selectorFamily<
  | {
      type:
        | ValidationWarningTypes.ExportCableTooLongWarning
        | ValidationWarningTypes.ExportCableTooLongError;
      featureIds: string[];
      parkId: string;
    }
  | undefined,
  { parkId: string; branchId: string }
>({
  key: "exportCableOver150kmLongWarningSelectorFamily",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const exportCables = get(
        getExportCablesInBranchSelectorFamily({ parkId, branchId }),
      );
      const errorLengthExportCables = exportCables.filter(
        (cable) =>
          turf.length(cable, { units: "kilometers" }) >
          exportCableErrorKilometer,
      );

      if (errorLengthExportCables.length > 0) {
        return {
          type: ValidationWarningTypes.ExportCableTooLongError,
          featureIds: errorLengthExportCables.map((c) => c.id),
          parkId: parkId,
        };
      }

      const warningLengthExportCables = exportCables.filter(
        (cable) =>
          turf.length(cable, { units: "kilometers" }) >
          exportCableWarningKilometer,
      );

      if (warningLengthExportCables.length > 0) {
        return {
          type: ValidationWarningTypes.ExportCableTooLongWarning,
          featureIds: warningLengthExportCables.map((c) => c.id),
          parkId: parkId,
        };
      }
    },
});

const ExportCableTooLongInner = ({
  park,
  projectId,
  branchId,
  warn,
  remove,
}: {
  park: ParkFeature;
  branchId: string;
  projectId: string;
} & WarningProps) => {
  const parkId = park.id;

  const invalidExportCablesWarningOrError = useRecoilValue(
    exportCableTooLongWarningErrorSelectorFamily({ parkId, branchId }),
  );

  const cfg = useRecoilValue(
    branchSelectedConfigurationAtomFamily({ projectId, branchId }),
  );

  // Update validation warning
  useEffect(() => {
    if (!invalidExportCablesWarningOrError || !cfg?.electrical.exportSystemLoss)
      return;
    warn(invalidExportCablesWarningOrError);
    return () => {
      remove(invalidExportCablesWarningOrError.type);
    };
  }, [
    park,
    remove,
    invalidExportCablesWarningOrError,
    warn,
    cfg?.electrical.exportSystemLoss,
  ]);

  return null;
};

const ExportCableTooLong = ({
  parkId,
  ...props
}: { parkId: string } & WarningProps) => {
  const park = useRecoilValue(getParkFeatureSelectorFamily({ parkId }));
  const projectId = useRecoilValue(projectIdSelector);
  const branchId = useRecoilValue(branchIdSelector);

  if (!projectId || !park || !branchId) return null;
  return (
    <ExportCableTooLongInner
      projectId={projectId}
      park={park}
      branchId={branchId}
      {...props}
    />
  );
};

export default ExportCableTooLong;
