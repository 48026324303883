/// <reference types="vite-plugin-svgr/client" />
import DeleteIcon from "@icons/24/Bin.svg?react";
import SubstationIcon from "@icons/24/Substation.svg?react";
import {
  COMPENSATION_SHARE_DEFAULT,
  IMPEDANCE_DEFAULT,
  IRON_LOSSES_DEFAULT,
  NO_LOAD_CURRENT_DEFAULT,
  REACTOR_DEFAULT,
  RESISTANCE_DEFAULT,
  SingleSubstationType,
  TYPE_DEFAULT,
} from "components/ConfigurationModal/SubstationSettings";
import { useToast } from "hooks/useToast";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { SubstationType } from "services/substationService";
import { currentSubstationTypesState } from "state/substationType";
import useSubstationTypeCrud from "../../../../hooks/useSubstationTypeCrud";
import { projectIdSelector } from "../../../../state/pathParams";
import { inReadOnlyModeSelector } from "../../../../state/project";
import { DefaultCosts } from "../../../ConfigurationModal/Cost/constants";
import { CostUnit } from "../../../../types/financial";
import { selectedMenuItemState } from "../../Shared/state";
import {
  SettingsItem,
  SettingsSubMenuProp,
} from "components/SettingsV2/Shared/types";

export const SUBSTATION_MENU_ID = "substation";

export default function useSubstationSettings() {
  const projectId = useRecoilValue(projectIdSelector) ?? "";
  const isReadOnly = useRecoilValue(inReadOnlyModeSelector);
  const { update, create, deleteSubstation } = useSubstationTypeCrud();
  const substationTypes = useRecoilValue(currentSubstationTypesState);
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const { success } = useToast();

  const [menuSelection, setMenuSelection] = useRecoilState(
    selectedMenuItemState({ menuId: SUBSTATION_MENU_ID, projectId }),
  );

  useEffect(() => {
    if (!menuSelection && substationTypes.length > 0)
      setMenuSelection(substationTypes[0].id);
  }, [menuSelection, setMenuSelection, substationTypes]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const addSubstation = useCallback(
    async (substation: Partial<SubstationType>) => {
      setIsLoading(true);
      setSaveInProgress(true);
      return create(substation)
        .then((result) => {
          if (result) setMenuSelection(result.id);
        })
        .finally(() => {
          setSaveInProgress(false);
          setIsLoading(false);
        });
    },
    [create, setMenuSelection, setSaveInProgress],
  );

  const _onDuplicate = useCallback(
    async (substationId: string) => {
      const substationType = substationTypes.find(
        (substationItem) => substationItem.id === substationId,
      );
      if (!substationType)
        throw new Error("Tried to duplicate non existing substation");

      setIsLoading(true);
      setSaveInProgress(true);
      const clone: Partial<SubstationType> = {
        ...substationType,
        name: `${substationType.name} copy`,
      };
      delete clone["id"];
      return create(clone).finally(() => {
        setSaveInProgress(false);
        setIsLoading(false);
      });
    },
    [create, substationTypes, setSaveInProgress],
  );

  const _onDelete = useCallback(
    async (lineId: string) => {
      setIsLoading(true);
      return deleteSubstation(lineId).finally(() => setIsLoading(false));
    },
    [deleteSubstation],
  );

  const _onUpdate = useCallback(
    async (newSubstation: SubstationType) => {
      setIsLoading(true);
      return update(newSubstation)
        .then(() => success("Saved"))
        .finally(() => setIsLoading(false));
    },
    [success, update],
  );

  const substationSubMenus: SettingsSubMenuProp[] = useMemo(() => {
    if (!substationTypes) return [];

    const substationList = substationTypes.map((substation) => {
      return {
        id: substation.id,
        label: substation.name,
        loading: isLoading,
        content: (
          <div style={{ height: "100%", position: "relative" }}>
            <SingleSubstationType
              disabled={isReadOnly}
              key={substation.id}
              substation={substation}
              onSave={_onUpdate}
              isLoading={isLoading}
            />
          </div>
        ),
        onDuplicate: _onDuplicate,
        dotMenuOptions: [
          {
            title: "Delete",
            onSelect: _onDelete,
            icon: <DeleteIcon />,
          },
        ],
      };
    });
    return [
      {
        items: substationList,
        title: "Project substations",
        create: {
          type: "action",
          title: "New substation type",
          saveInProgress: saveInProgress,
          onCreate: () =>
            addSubstation({
              name: `Substation ${substationTypes.length + 1}`,
              cost: DefaultCosts.substation.substation[
                CostUnit.millionEuroPerUnit
              ],
              costUnit: CostUnit.millionEuroPerUnit,
              type: TYPE_DEFAULT,
              impedance: IMPEDANCE_DEFAULT,
              resistance: RESISTANCE_DEFAULT,
              ironLosses: IRON_LOSSES_DEFAULT,
              noLoadCurrent: NO_LOAD_CURRENT_DEFAULT,
              reactor: REACTOR_DEFAULT,
              compensationShare: COMPENSATION_SHARE_DEFAULT,
            }),
          disabled: isLoading,
        },
      },
    ];
  }, [
    substationTypes,
    isLoading,
    isReadOnly,
    _onUpdate,
    _onDuplicate,
    _onDelete,
    addSubstation,
    saveInProgress,
  ]);

  const topLevelMenu: SettingsItem = useMemo(() => {
    return {
      id: SUBSTATION_MENU_ID,
      label: "Substations",
      icon: <SubstationIcon />,
      submenus: substationSubMenus,
    };
  }, [substationSubMenus]);

  return topLevelMenu;
}
