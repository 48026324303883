import { useEffect } from "react";
import { selectorFamily, useRecoilValue } from "recoil";
import { getTurbinesOutsideParkSelector } from "state/layout";
import { ValidationWarningTypes } from "./utils";
import { useValidationWarnings } from "./ValidationWarnings";
import { turbineSourceId } from "components/Mapbox/constants";
import { branchIdSelector } from "state/pathParams";

export const turbineOutsideParkWarningSelectorFamily = selectorFamily<
  | {
      type: ValidationWarningTypes.TurbineOutsidePark;
      featureIds: string[];
      parkId: string;
    }
  | undefined,
  {
    parkId: string;
    branchId: string;
  }
>({
  key: "turbineOutsideParkWarningSelectorFamily",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const outsideTurbines = get(
        getTurbinesOutsideParkSelector({ parkId, branchId }),
      );

      return outsideTurbines.length === 0
        ? undefined
        : {
            type: ValidationWarningTypes.TurbineOutsidePark,
            featureIds: outsideTurbines.map((t) => t.id),
            parkId: parkId,
          };
    },
});

export const TurbineOutsidePark = ({
  map,
  parkId,
  source = turbineSourceId,
}: {
  map: mapboxgl.Map;
  parkId: string;
  source?: string;
}) => {
  const branchId = useRecoilValue(branchIdSelector);
  const outsideTurbinesWarning = useRecoilValue(
    turbineOutsideParkWarningSelectorFamily({
      parkId: parkId,
      branchId: branchId ?? "",
    }),
  );
  const { warn, remove } = useValidationWarnings();
  useEffect(() => {
    if (!outsideTurbinesWarning) return;
    warn(outsideTurbinesWarning);
    if (map.getSource(source))
      for (const id of outsideTurbinesWarning.featureIds)
        map.setFeatureState({ source, id }, { outside: true });

    return () => {
      remove(ValidationWarningTypes.TurbineOutsidePark);
      if (map.getSource(source))
        for (const id of outsideTurbinesWarning.featureIds)
          map.removeFeatureState({ source, id }, "outside");
    };
  }, [map, outsideTurbinesWarning, remove, source, warn]);

  useEffect(
    () => () => {
      remove(ValidationWarningTypes.TurbineOutsidePark);
    },
    [remove],
  );
  return null;
};
