/// <reference types="vite-plugin-svgr/client" />
import Button from "components/General/Button";
import { InitialsImageRound } from "components/UserImage/UserImageRound";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useTypedPath } from "state/pathParams";
import { organisationTurbineResourceState } from "../state";
import {
  ContentColumn,
  ContentHeader,
  ContentHeaderButtonsContainer,
  ContentHeaderRow,
  MainRow,
  ResourceColumn,
  ResourceItem,
  ResourceName,
  TabContainer,
} from "../style";
import AddIcon from "@icons/24/Add.svg?react";
import Trashcan from "@icons/24/Bin.svg?react";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";
import { useEffect, useMemo, useRef, useState } from "react";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { DEFAULT_TURBINES, TurbineType } from "types/turbines";
import TurbineSettingCustomAdmin from "components/ConfigurationModal/TurbineSettings/TurbineSettingCustomAdmin";
import { Column } from "components/General/Layout";
import useOrgTurbineCrud from "./useOrgTurbineCrud";
import { DotMenu } from "components/General/MenuButton";
import { MenuItem } from "components/General/Menu";
import OpenMoreInfo from "components/General/MoreInfoButton";
import { SkeletonBlock } from "components/Loading/Skeleton";

export default function TurbineTab() {
  const { organisationId } = useTypedPath("organisationId");
  const turbines = useRecoilValue(
    organisationTurbineResourceState({ organisationId }),
  );

  const [selectedItemId, setSelectedItemId] = useState<string | undefined>(
    undefined,
  );
  const setContent = useSetRecoilState(
    organisationRightSideModal(organisationId),
  );

  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [deleteInProgress, setDeleteInProgress] = useState<
    string | undefined
  >();

  const isLoading = useMemo(
    () => saveInProgress || !!deleteInProgress,
    [saveInProgress, deleteInProgress],
  );

  useEffect(() => {
    if (!selectedItemId && turbines.length > 0 && !saveInProgress) {
      setContent({ type: "resource", id: turbines[0].id });
      setSelectedItemId(turbines[0].id);
    }
  }, [selectedItemId, setContent, turbines, saveInProgress]);

  const selectedItem = useMemo(
    () => turbines.find((t) => t.id === selectedItemId),
    [selectedItemId, turbines],
  );

  const newItemRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const newItem = newItemRef.current;
    if (saveInProgress && newItem) {
      newItem.scrollIntoView();
    }
  }, [saveInProgress]);

  const { create, remove, duplicate } = useOrgTurbineCrud();

  return (
    <TabContainer>
      <MainRow>
        <ResourceColumn>
          <Button
            buttonType="secondary"
            icon={<AddIcon />}
            text={"New turbine"}
            disabled={isLoading}
            onClick={async () => {
              setSaveInProgress(true);
              setSelectedItemId(undefined);
              const defaultTurbine =
                DEFAULT_TURBINES.find((t) => t.id === "iea_15MW") ??
                DEFAULT_TURBINES[0];
              const res = await create({ turbine: defaultTurbine });
              if (res) {
                setSelectedItemId(res.id);
                setSaveInProgress(false);
                setContent({ type: "resource", id: res.id });
              }
            }}
          />
          <Column
            style={{
              gap: "0.8rem",
              overflowY: "auto",
            }}
          >
            {turbines.map((t) => {
              return (
                <ResourceItem
                  key={t.id}
                  onClick={() => {
                    setSelectedItemId(t.id);
                    setContent({ type: "resource", id: t.id });
                  }}
                  selected={selectedItemId === t.id}
                  disabled={deleteInProgress === t.id}
                >
                  <InitialsImageRound name={t.name} size={2.2} />
                  <ResourceName>{t.name}</ResourceName>
                  {!(deleteInProgress === t.id) && (
                    <DotMenu
                      divStyle={{
                        marginLeft: "auto",
                        width: "2.5rem",
                      }}
                    >
                      <MenuItem
                        name={"Duplicate"}
                        disabled={isLoading}
                        onClick={async () => {
                          setSaveInProgress(true);
                          setSelectedItemId(undefined);
                          const res = await duplicate({ turbine: t });
                          setSaveInProgress(false);
                          if (res) {
                            setSelectedItemId(res.id);
                            setContent({ type: "resource", id: res.id });
                          }
                        }}
                        icon={<DuplicateIcon />}
                      />
                      <MenuItem
                        name={"Delete"}
                        disabled={isLoading}
                        onClick={async () => {
                          setDeleteInProgress(t.id);
                          await remove(t.id);
                          setDeleteInProgress(undefined);
                          setSelectedItemId(turbines[0]?.id);
                          setContent({ type: "resource", id: turbines[0]?.id });
                        }}
                        icon={<Trashcan />}
                      />
                    </DotMenu>
                  )}
                </ResourceItem>
              );
            })}
            {saveInProgress && (
              <ResourceItem
                key={"save-in-progress"}
                selected={true}
                ref={newItemRef}
              >
                <SkeletonBlock
                  style={{ height: "4rem", marginTop: "0.8rem" }}
                />
              </ResourceItem>
            )}
          </Column>
        </ResourceColumn>
        {selectedItem && <TurbineContent turbine={selectedItem} />}
      </MainRow>
    </TabContainer>
  );
}

function TurbineContent({ turbine }: { turbine: TurbineType }) {
  const { organisationId } = useTypedPath("organisationId");
  const [, setContent] = useRecoilState(
    organisationRightSideModal(organisationId),
  );
  const { update } = useOrgTurbineCrud();

  return (
    <ContentColumn>
      <ContentHeaderRow>
        <ContentHeaderButtonsContainer>
          <OpenMoreInfo
            onClick={() => {
              setContent((cur) => {
                if (cur && cur.id === turbine.id) return undefined;
                return {
                  type: "resource",
                  id: turbine.id,
                };
              });
            }}
          />
        </ContentHeaderButtonsContainer>
        <ContentHeader>{turbine.name}</ContentHeader>
      </ContentHeaderRow>
      <div
        style={{
          overflow: "hidden",
          flex: 1,
          position: "relative",
          width: "100%",
        }}
      >
        <TurbineSettingCustomAdmin
          turbine={turbine}
          onSave={update}
          isLoading={false}
          nodeId={organisationId}
          readOnly={false}
        />
      </div>
    </ContentColumn>
  );
}
