export const decodeTerrarium = ({
  r,
  g,
  b,
}: {
  r: number;
  g: number;
  b: number;
}) => r * 256.0 + g + b / 256.0 - 32768.0;

export const decodeTerrariumDepth = (rgb: {
  r: number;
  g: number;
  b: number;
}) => decodeTerrarium(rgb) * -1;
