/// <reference types="vite-plugin-svgr/client" />
import styled from "styled-components";
import { Suspense, useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { TurbineFeature } from "../../types/feature";
import ArrowDownIcon from "@icons/24/ArrowDown.svg?react";
import ArrowRightIcon from "@icons/24/ArrowRight.svg?react";
import ProjectIcon from "@icons/24/ProjectGlobe.svg?react";
import LibraryIcon from "@icons/24/Library.svg?react";
import Tooltip from "../General/Tooltip";
import { isDefined, isTurbine } from "../../utils/predicates";
import { useProjectElementsCrud } from "../ProjectElements/useProjectElementsCrud";
import { trackCanvasOption } from "./MenuTracking";
import {
  branchIdSelector,
  projectIdSelector,
  useTypedPath,
} from "../../state/pathParams";
import { allFoundationTypesWithLevelSelector } from "../../state/foundations";
import { MAX_DEPTH_FIXED, MIN_DEPTH_FLOATING } from "../../constants/park";
import { SkeletonText } from "../Loading/Skeleton";
import { Raster } from "../../types/raster";
import { Divider } from "components/General/Icons";
import { getParkFeatureSelectorFamily } from "../../state/park";
import { ParkFeature } from "../../types/feature";
import {
  isFloater,
  isFixed,
} from "..//RightSide/InfoModal/FoundationModal/utils";
import {
  FoundationMaxDepths,
  FoundationMinDepths,
} from "../../types/foundations";
import { ProjectFeature } from "../../types/feature";
import { MenuItem } from "../General/Menu";
import { MenuButton } from "../General/MenuButton";
import {
  NamedTooltipContainer,
  NamedTooltipWrapper,
} from "./CanvasSelectOption.style";
import { pointInPolygon } from "utils/geometry";
import { useBathymetryRaster } from "hooks/bathymetry";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { fastMax, fastMin } from "utils/utils";

const TypeSelectorWrapper = styled.div`
  position: relative;
  font-size: 1.6rem;
  font-weight: 500;
`;

const FallbackWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TypeSelector = ({
  selectedTurbines,
  editable,
  raster,
}: {
  selectedTurbines: TurbineFeature[];
  editable: boolean;
  raster: undefined | Raster;
}) => {
  const { projectId, branchId } = useTypedPath("projectId", "branchId");

  const { update: updateFeatures } = useProjectElementsCrud();
  const allFoundationTypes = useRecoilValue(
    allFoundationTypesWithLevelSelector,
  ).filter((ftl) => !ftl.foundation.archived);

  const currentFoundationTypeIds = [
    ...new Set(selectedTurbines.map((t) => t.properties.foundationId)).values(),
  ];

  const { minDepth, maxDepth } = useMemo(() => {
    if (!raster) return { minDepth: 0, maxDepth: 0 };
    const depths = selectedTurbines
      .map((t) => {
        const [lat, lng] = t.geometry.coordinates;
        return -raster.latLngToValue(lat, lng);
      })
      .filter((depth) => depth > 0);
    return {
      maxDepth: fastMax(depths),
      minDepth: fastMin(depths),
    };
  }, [raster, selectedTurbines]);

  const onSelectItem = useCallback(
    (val: string) => {
      trackCanvasOption("change-foundation-type", {
        projectId,
        branchId,
      });
      const updatedFeatures = selectedTurbines.map((t) => ({
        ...t,
        properties: { ...t.properties, foundationId: val },
      }));

      updateFeatures({ update: updatedFeatures });
    },
    [branchId, projectId, selectedTurbines, updateFeatures],
  );

  const items = useMemo(
    () =>
      allFoundationTypes.map((ft) => {
        let disabled = false;

        if (
          isFloater(ft.foundation) &&
          minDepth <
            (FoundationMinDepths[ft.foundation.type] ?? MIN_DEPTH_FLOATING)
        ) {
          disabled = true;
        } else if (
          isFixed(ft.foundation) &&
          maxDepth >
            (FoundationMaxDepths[ft.foundation.type] ?? MAX_DEPTH_FIXED)
        ) {
          disabled = true;
        }

        return {
          icon: ["project", "team"].includes(ft.level) ? (
            <Tooltip text={"Project foundation"}>
              <ProjectIcon />
            </Tooltip>
          ) : (
            <Tooltip text={"Library foundation"}>
              <LibraryIcon />
            </Tooltip>
          ),
          value: ft.foundation.id,
          name: ft.foundation.name,
          disabled,
        };
      }),
    [allFoundationTypes, maxDepth, minDepth],
  );

  const selectedFoundationTypeId =
    currentFoundationTypeIds.length === 1
      ? currentFoundationTypeIds[0]
      : undefined;

  return (
    <TypeSelectorWrapper>
      <MenuButton
        disabled={!editable}
        icon={<ArrowRightIcon />}
        iconOpen={<ArrowDownIcon />}
        buttonStyle={{
          border: "none",
          flexDirection: "row-reverse",
          height: "fit-content",
          justifyContent: "space-between",
          padding: 0,
        }}
        buttonType="dropdown"
        buttonText={
          selectedFoundationTypeId
            ? allFoundationTypes.find(
                (t) => t.foundation.id === selectedFoundationTypeId,
              )?.foundation.name ?? "..."
            : "Foundation type"
        }
      >
        {items.map((item) => (
          <MenuItem
            key={item.value}
            name={item.name}
            onClick={() => onSelectItem(item.value)}
            icon={item.icon}
            disabled={item.disabled}
          />
        ))}
      </MenuButton>
    </TypeSelectorWrapper>
  );
};

function FoundationTypeSelectorInner({
  editable,
  park,
  selectedTurbineFeatures,
}: {
  editable: boolean;
  park: ParkFeature;
  selectedTurbineFeatures: TurbineFeature[];
}) {
  const projectId = useRecoilValue(projectIdSelector) ?? "";
  const branchId = useRecoilValue(branchIdSelector) ?? "";

  const bathymetry = useBathymetryRaster({
    projectId,
    branchId,
    featureId: park.id,
  });

  const bath = bathymetry.valueMaybe();
  if (bathymetry.state === "hasError") {
    return (
      <FallbackWrapper>
        <SimpleAlert
          text={"Failed to load bathymetry"}
          type={"error"}
        ></SimpleAlert>
      </FallbackWrapper>
    );
  }

  return (
    <Suspense
      fallback={
        <FallbackWrapper>
          <SkeletonText style={{ width: "3rem" }} />
        </FallbackWrapper>
      }
    >
      <Tooltip position="top" text="Change foundation type" readonlyAware>
        <NamedTooltipContainer>
          <NamedTooltipWrapper>Foundation type</NamedTooltipWrapper>
          <TypeSelector
            raster={bath}
            selectedTurbines={selectedTurbineFeatures}
            editable={editable}
          />
        </NamedTooltipContainer>
      </Tooltip>
    </Suspense>
  );
}

export default function FoundationTypeSelector({
  editable,
  selectedProjectFeatures,
}: {
  editable: boolean;
  selectedProjectFeatures: ProjectFeature[];
}) {
  const selectedTurbineFeatures = useMemo(
    () => selectedProjectFeatures.filter(isTurbine),
    [selectedProjectFeatures],
  );

  const parkIds = [
    ...new Set(
      selectedTurbineFeatures
        .flatMap((t) => t.properties.parentIds)
        .filter(isDefined),
    ),
  ];

  const park = useRecoilValue(
    getParkFeatureSelectorFamily({ parkId: parkIds[0] ?? "" }),
  );

  if (!park || parkIds.length > 1) return null;

  const onlyTurbinesSelected =
    selectedTurbineFeatures.length === selectedProjectFeatures.length;

  if (selectedTurbineFeatures.length === 0 || !onlyTurbinesSelected)
    return null;

  const turbinesOutsidePark = selectedTurbineFeatures.filter(
    (t) => !pointInPolygon(t.geometry, park.geometry),
  );

  if (turbinesOutsidePark.length > 0) return null;

  return (
    <>
      <FoundationTypeSelectorInner
        selectedTurbineFeatures={selectedTurbineFeatures}
        park={park}
        editable={editable}
      />
      <Divider />
    </>
  );
}
