import { Column } from "components/General/Layout";
import { ContentTableColumn } from "../../style";
import { SkeletonBlock } from "components/Loading/Skeleton";

export const TablePlaceholder = () => {
  return (
    <Column style={{ gap: "2.4rem" }}>
      <SkeletonBlock
        style={{ width: "10rem", height: "3.2rem", marginLeft: "auto" }}
      />
      <ContentTableColumn style={{ gap: "0.4rem" }}>
        <TableRowsPlaceholder />
      </ContentTableColumn>
    </Column>
  );
};

export const TableRowsPlaceholder = () => {
  return (
    <>
      <SkeletonBlock style={{ height: "3.2rem" }} />
      <SkeletonBlock style={{ height: "3.2rem" }} />
      <SkeletonBlock style={{ height: "3.2rem" }} />
    </>
  );
};
