import React from "react";
import { colors } from "styles/colors";
import { IconREMSize } from "styles/typography";
import { isNumber } from "utils/predicates";
import KeyboardArrowRightIcon from "@icons/24/KeyboardArrowRight.svg";
import { CompareColumnItemAttribute, ComparisonType } from "../../types";
import { ComparisonMode } from "../../state";
import { isFalsy } from "../../columnTemplates";
import { ComparisonValueWrapper } from "./ComparisonParkV2.style";

const getPercentageValue = (baselineValue: unknown, value: number): number => {
  if (!isNumber(baselineValue)) {
    return 0;
  }

  if (baselineValue === value) {
    return 0;
  }

  if (baselineValue === 0 || value === 0) {
    if (Math.abs(baselineValue) > Math.abs(value)) {
      return Number.NEGATIVE_INFINITY;
    }
    return Number.POSITIVE_INFINITY;
  }

  return (value / baselineValue) * 100 - 100;
};

const ComparisonValueIndicatorMapper = ({
  comparisonType,
  result,
  formatFunction,
}: {
  comparisonType: ComparisonType;
  result: number;
  formatFunction?(result: any): React.ReactNode;
}) => {
  const absoluteResult = Math.abs(result);
  const formattedResult = formatFunction
    ? formatFunction(absoluteResult)
    : absoluteResult;

  if (absoluteResult === 0) {
    return (
      <ComparisonValueWrapper style={{ backgroundColor: colors.surfaceInfo }}>
        {result !== 0 && (
          <IconREMSize height={1} width={1}>
            <KeyboardArrowRightIcon
              style={{
                transform: result < 0 ? "rotate(90deg)" : "rotate(-90deg)",
              }}
            />
          </IconREMSize>
        )}
        <p
          style={{
            color: colors.textBrand,
          }}
        >
          {absoluteResult === 0
            ? "="
            : formatFunction
              ? formatFunction(absoluteResult)
              : absoluteResult}
        </p>
      </ComparisonValueWrapper>
    );
  }

  if (comparisonType === "NEUTRAL_CHECK") {
    return (
      <ComparisonValueWrapper style={{ backgroundColor: colors.purple100 }}>
        {result !== 0 && (
          <IconREMSize height={1} width={1} fill={colors.purple600}>
            <KeyboardArrowRightIcon
              style={{
                transform: result < 0 ? "rotate(90deg)" : "rotate(-90deg)",
              }}
            />
          </IconREMSize>
        )}
        <p
          style={{
            color: colors.purple600,
          }}
        >
          {formatFunction ? formatFunction(absoluteResult) : absoluteResult}
        </p>
      </ComparisonValueWrapper>
    );
  }

  const isGoodResult =
    (comparisonType === "POSITIVE_CHECK" && result >= 0) ||
    (comparisonType === "NEGATIVE_CHECK" && result <= 0);
  const icon = (
    <IconREMSize
      height={1}
      width={1}
      fill={isGoodResult ? colors.green500 : colors.red500}
    >
      <KeyboardArrowRightIcon
        style={{
          transform: result < 0 ? "rotate(90deg)" : "rotate(-90deg)",
        }}
      />
    </IconREMSize>
  );

  if (isGoodResult) {
    return (
      <ComparisonValueWrapper
        style={{ backgroundColor: colors.surfaceSuccess }}
      >
        {icon}
        <p style={{ color: colors.green500 }}>{formattedResult}</p>
      </ComparisonValueWrapper>
    );
  }
  return (
    <ComparisonValueWrapper style={{ backgroundColor: colors.surfaceError }}>
      {icon}
      <p style={{ color: colors.red500 }}>{formattedResult}</p>
    </ComparisonValueWrapper>
  );
};

const ComparisonValueList = ({
  result,
  formatFunction,
  comparisonMode,
}: {
  result: number[];
  comparisonMode: ComparisonMode;
  formatFunction?(result: any): React.ReactNode;
}) => {
  if (comparisonMode === "percentage") {
    return (
      <ComparisonValueWrapper style={{ backgroundColor: colors.surfaceInfo }}>
        <p style={{ color: colors.textBrand }}>-</p>
      </ComparisonValueWrapper>
    );
  }
  if (result.length === 0) {
    return (
      <ComparisonValueWrapper style={{ backgroundColor: colors.surfaceInfo }}>
        <p style={{ color: colors.textBrand }}>=</p>
      </ComparisonValueWrapper>
    );
  }

  if (!formatFunction) {
    return null;
  }

  return (
    <ComparisonValueWrapper style={{ backgroundColor: colors.purple100 }}>
      <p
        style={{
          color: colors.purple600,
        }}
      >
        {formatFunction(result)}
      </p>
    </ComparisonValueWrapper>
  );
};

const ComparisonValue = ({
  baselineValue,
  value,
  attribute,
  comparisonMode,
}: {
  baselineValue: unknown;
  value: any;
  attribute: CompareColumnItemAttribute;
  comparisonMode: ComparisonMode;
}) => {
  if (isFalsy(baselineValue) || isFalsy(value)) {
    return null;
  }

  const comparison = attribute.compareValues?.(baselineValue, value);

  if (typeof comparison === "undefined") {
    return null;
  }

  if (Array.isArray(comparison.result)) {
    if (comparison.type === "LIST_CHECK") {
      return (
        <ComparisonValueList
          formatFunction={attribute.formatComparisonResult}
          comparisonMode={comparisonMode}
          result={comparison.result}
        />
      );
    }
    return null;
  }

  if (comparisonMode === "percentage") {
    const percentageValue = getPercentageValue(baselineValue, value);

    return (
      <ComparisonValueIndicatorMapper
        comparisonType={comparison.type}
        result={percentageValue}
        formatFunction={(value) =>
          value === Number.POSITIVE_INFINITY ||
          value === Number.NEGATIVE_INFINITY
            ? "-"
            : `${value.toFixed(2)}%`
        }
      />
    );
  }

  return (
    <ComparisonValueIndicatorMapper
      comparisonType={comparison.type}
      result={comparison.result}
      formatFunction={attribute.formatComparisonResult}
    />
  );
};

export default ComparisonValue;
