import { useEffect } from "react";

// Updates the height of a <textarea> when the value changes.
const useAutosizeTextArea = (
  textAreaRef: HTMLTextAreaElement | null,
  value: string,
  minHeightInPx: number,
) => {
  useEffect(() => {
    if (textAreaRef) {
      textAreaRef.style.height = "0";
      const scrollHeight = textAreaRef.scrollHeight;
      const newHeight = Math.max(scrollHeight, minHeightInPx);
      textAreaRef.style.height = `${newHeight}px`;
    }
  }, [textAreaRef, value, minHeightInPx]);
};

export default useAutosizeTextArea;
