/// <reference types="vite-plugin-svgr/client" />
import { Row } from "components/General/Layout";
import Tabs from "components/General/Tabs";
import Earth from "@icons/14/Earth.svg?react";
import FolderIcon from "@icons/40/FolderBig.svg?react";
import PersonalFolderIcon from "@icons/40/FolderPersonalBig.svg";
import { Suspense, useCallback, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useTypedPath } from "state/pathParams";
import { singleNodeAtomFamily } from "state/timeline";
import {
  ContentHeader,
  ContentHeaderColumn,
  ContentWrapper,
  Text,
} from "../../style";
import { TablePlaceholder } from "../shared/TablePlaceholder";
import {
  adminInOrganisationSelectorFamily,
  userNodeAccessSelectorFamily,
} from "state/user";
import { LibraryResources } from "./tabs/LibraryResource/LibraryResource";
import { Collaborators } from "./tabs/Collaborators";
import UserInfo from "components/Comments/MapModal/UserInfo";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { ContentImageWrapper } from "components/Organisation/Image/ImageWrapper";
import { EditableTextInternalState } from "components/General/EditableText";
import { useToast } from "hooks/useToast";
import { useOrganisationNodeCrud } from "components/Projects/useOrganisationFolderCrud";
import { typography } from "styles/typography";
import { colors } from "styles/colors";

export default function NodeContent({ nodeId }: { nodeId: string }) {
  return (
    <ContentWrapper>
      <Suspense fallback={<TablePlaceholder />}>
        <NodeContentInner nodeId={nodeId} />
      </Suspense>
    </ContentWrapper>
  );
}

function NodeContentInner({ nodeId }: { nodeId: string }) {
  const node = useRecoilValue(singleNodeAtomFamily({ nodeId }));
  const { organisationId } = useTypedPath("organisationId");
  const [rightSideModalContent, setRightSideModalContent] = useRecoilState(
    organisationRightSideModal(organisationId),
  );

  const isAdminInOrg = useRecoilValue(
    adminInOrganisationSelectorFamily({ organisationId }),
  );
  const nodeAccess = useRecoilValue(userNodeAccessSelectorFamily({ nodeId }));
  const isViewerInNode = nodeAccess >= 0;

  const onAfterCollaboratorsRender = useCallback(() => {
    setRightSideModalContent((curr) => {
      if (!curr || !curr.meta) {
        return curr;
      }
      const { meta: _tempName, ...rest } = curr;
      return rest;
    });
  }, [setRightSideModalContent]);

  const tabs = useMemo(() => {
    if (!node) return [];
    const t = [
      {
        name: "Collaborators",
        data: (
          <Suspense fallback={<TablePlaceholder />}>
            <Collaborators
              node={node}
              onAfterRender={onAfterCollaboratorsRender}
              openAddCollaborators={Boolean(
                rightSideModalContent?.meta?.["openAddCollaborators"],
              )}
            />
          </Suspense>
        ),
      },
    ];
    if (isAdminInOrg || isViewerInNode) {
      t.push({
        name: "Library resources",
        data: (
          <Suspense fallback={<TablePlaceholder />}>
            <LibraryResources node={node} />
          </Suspense>
        ),
      });
    }
    return t;
  }, [
    isAdminInOrg,
    isViewerInNode,
    node,
    onAfterCollaboratorsRender,
    rightSideModalContent?.meta,
  ]);

  const { error } = useToast();

  const { update } = useOrganisationNodeCrud();

  if (!node) return <h4>Project not found</h4>;
  const NodeIcon =
    node.type === "project"
      ? Earth
      : node.type === "folder"
        ? FolderIcon
        : PersonalFolderIcon;

  const createdByUser = node.author.startsWith("auth0");

  const isNodeAdmin = nodeAccess >= 2;

  const isPersonalFolder = node?.type === "personal_folder";

  return (
    <>
      <Row style={{ gap: "2.4rem", alignItems: "center" }}>
        <ContentImageWrapper color={colors.surfaceInfo}>
          <NodeIcon />
        </ContentImageWrapper>
        <ContentHeaderColumn>
          {isNodeAdmin ? (
            <EditableTextInternalState
              inputStyle={{ width: "25rem", ...typography.h3 }}
              smallInput={true}
              value={node?.name}
              renderText={(text) => <ContentHeader>{text}</ContentHeader>}
              disabled={isPersonalFolder}
              onEnter={(newName) => {
                if (!node) return;
                update(node.id, {
                  ...node,
                  name: newName,
                }).catch(() => error("Failed to update folder name"));
              }}
            />
          ) : (
            <ContentHeader>{node?.name}</ContentHeader>
          )}

          {createdByUser && (
            <Row style={{ alignItems: "center", cursor: "pointer" }}>
              <Text>Created by:</Text>
              <UserInfo userId={node.author} />
            </Row>
          )}
        </ContentHeaderColumn>
      </Row>
      <Tabs
        menuStyle={{ justifyContent: "start", gap: "5rem" }}
        tabs={tabs}
        wrapperStyle={{ overflowY: "hidden" }}
        contentWrapperStyle={{ height: "100%" }}
      />
    </>
  );
}
