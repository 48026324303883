import React from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { getAnchorsSelectorFamily } from "../../../state/layout";
import { spaceLarge, spaceMedium } from "../../../styles/space";
import { useDashboardContext } from "../Dashboard";
import { LoadingState, SafeCard } from "./Base";
import { FlexGrid2 } from "../../General/Form";
import { MAX_BUFFER_DISTANCE_RASTER_ANCHOR } from "services/mooringLineTypeService";
import { StatHeader } from "./Base.style";
import { useBathymetryRaster } from "hooks/bathymetry";

// Lazy load to prevent plotly being in main.js
const AnchorDistribution = React.lazy(
  () => import("../../RightSide/InfoModal/AnchorDistribution"),
);

export const TableHeader = styled.h4`
  margin: ${spaceMedium} ${spaceLarge} 0 ${spaceLarge};
`;

const MooringLineAnchorsDetails = () => {
  const { projectId, park } = useDashboardContext();

  const anchors = useRecoilValue(getAnchorsSelectorFamily(park.id)); // Todo: why does getAnchorSelector not need branch id?

  const raster = useBathymetryRaster({
    projectId,
    featureId: park.id,
    bufferKm: MAX_BUFFER_DISTANCE_RASTER_ANCHOR,
  }).valueMaybe();

  if (!raster) return <LoadingState />;

  return (
    <>
      <FlexGrid2 style={{ padding: spaceLarge }}>
        <StatHeader title={"Number of anchors"} value={anchors.length} />
      </FlexGrid2>
      <React.Suspense fallback={null}>
        <AnchorDistribution anchors={anchors} depthRaster={raster} />
      </React.Suspense>
    </>
  );
};

export const MooringLineAnchorsWidget = () => (
  <SafeCard title="Mooring line anchors" id="Mooring line anchors">
    <MooringLineAnchorsDetails />
  </SafeCard>
);
