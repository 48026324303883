/// <reference types="vite-plugin-svgr/client" />
import { SVGWrapper } from "@icons/svgUtils";
import UserInfo from "components/Comments/MapModal/UserInfo";
import { Column, Row } from "components/General/Layout";
import {
  nodeGroupUserAccessSelector,
  organisationGroupsState,
} from "components/Organisation/Groups/state";
import {
  isUserNodeAccessWithMeta,
  isGroupNodeAccessWithMeta,
} from "components/Organisation/Groups/types";
import useGroupNodeAccessCrud from "components/Organisation/Groups/useGroupNodeAccessCrud";
import {
  ContentTableColumn,
  ContentTableRow,
  SecondaryText,
  TextEllipsis,
  TabDescription,
  Text,
} from "components/Organisation/OrganisationRightSide/style";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { useTypedPath } from "state/pathParams";
import { singleNodeAtomFamily } from "state/timeline";
import {
  adminInOrganisationSelectorFamily,
  loggedInUserSelector,
  userNodeAccessSelectorFamily,
} from "state/user";
import FolderIcon from "@icons/24/Folder.svg?react";
import House from "@icons/14/House.svg?react";
import InviteIcon from "@icons/24/Invite.svg?react";
import TeamMeeting from "@icons/24/TeamMeeting.svg?react";
import Trashcan from "@icons/24/Bin.svg?react";
import useUserNodeAccessCrud from "components/Organisation/Members/useUserNodeAccessCrud";
import { UserNodeAccessDropdown } from "../../shared/UserNodeAccessDropdown";
import { GroupNodeAccessDropdown } from "../../shared/GroupNodeAccessDropdown";
import Button from "components/General/Button";
import UserGroupAccessModal, {
  GroupAccess,
  UserAccess,
} from "../../UserGroupAccessModal";
import { Node } from "services/customerAPI";
import { UserAccessRole } from "types/user";
import Tooltip from "components/General/Tooltip";
import { IconBtn } from "components/General/Icons";
import { SkeletonBlock } from "components/Loading/Skeleton";
import {
  findTopLevelNode,
  nodesInOrganisationSelectorFamily,
  useOrganisationNodeCrud,
} from "components/Projects/useOrganisationFolderCrud";
import { useLeaveNode } from "hooks/useUser";
import { useToast } from "hooks/useToast";
import { sendWarning } from "utils/sentry";

export function Collaborators({
  node,
  openAddCollaborators,
  onAfterRender,
}: {
  node: Node;
  openAddCollaborators: boolean;
  onAfterRender(): void;
}) {
  const { organisationId } = useTypedPath("organisationId");
  const nodeId = node.id;

  const groups = useRecoilValue(organisationGroupsState({ organisationId }));

  const groupsAndUsersWithAccess = useRecoilValue(
    nodeGroupUserAccessSelector({ organisationId, nodeId }),
  );
  const usersWithAccess = useMemo(
    () => groupsAndUsersWithAccess.filter(isUserNodeAccessWithMeta),
    [groupsAndUsersWithAccess],
  );
  const groupsWithAccess = useMemo(
    () => groupsAndUsersWithAccess.filter(isGroupNodeAccessWithMeta),
    [groupsAndUsersWithAccess],
  );

  const isAdminInOrg = useRecoilValue(
    adminInOrganisationSelectorFamily({ organisationId }),
  );
  const nodeAccess = useRecoilValue(userNodeAccessSelectorFamily({ nodeId }));
  const isNodeAdmin = nodeAccess >= 2;

  const currentUserId = useRecoilValue(loggedInUserSelector)?.user_id;

  const nodes = useRecoilValue(
    nodesInOrganisationSelectorFamily({ organisationId }),
  );
  const chosenFolderHasPersonalTopFolder = node
    ? findTopLevelNode(nodes, node.id, organisationId ?? "")?.type ===
      "personal_folder"
    : false;

  const { remove: removeGroupNodeAccess, addOrUpdate: addOrUpdateGroup } =
    useGroupNodeAccessCrud();
  const { remove: removeUserNodeAccess, addOrUpdate: addOrUpdateUser } =
    useUserNodeAccessCrud();

  const { removeLocal } = useOrganisationNodeCrud();
  const { error } = useToast();

  const leaveNode = useLeaveNode(nodeId);
  const _leaveNode = useCallback(async () => {
    const adminsWithoutMe = groupsAndUsersWithAccess.filter(
      (a) => a.resource_name === "admin" && a.user_id !== currentUserId,
    );
    if (adminsWithoutMe.length === 0) {
      error("You are the only admin in this project. You cannot leave.");
      return;
    }

    const msg = `Are you sure you want to leave this project?`;
    if (!window.confirm(msg)) return;

    return leaveNode()
      .then(() => {
        removeLocal(nodeId);
        document.location.reload();
      })
      .catch((e) => {
        error("Failed to leave project");
        sendWarning("failed to leave project", e);
      });
  }, [
    currentUserId,
    error,
    groupsAndUsersWithAccess,
    leaveNode,
    nodeId,
    removeLocal,
  ]);

  const [showAddProject, setShowAddProject] = useState(openAddCollaborators);
  const [saveInProgress, setSaveInProgress] = useState<number>(0);
  const [deleteInProgress, setDeleteInProgress] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (openAddCollaborators) {
      setShowAddProject(true);
    }
  }, [openAddCollaborators]);

  useEffect(() => {
    onAfterRender();
  }, [onAfterRender]);

  const onAddAccess = useCallback(
    async (
      access: { users: UserAccess[]; groups: GroupAccess[] },
      accessRole: UserAccessRole,
    ) => {
      setSaveInProgress(access.groups.length + access.users.length);
      setShowAddProject(false);
      try {
        await Promise.all([
          ...access.groups.map((groupId) =>
            addOrUpdateGroup(groupId, nodeId, accessRole).finally(() =>
              setSaveInProgress((cur) => cur - 1),
            ),
          ),
          ...access.users.map((userId) =>
            addOrUpdateUser(userId, nodeId, accessRole).finally(() =>
              setSaveInProgress((cur) => cur - 1),
            ),
          ),
        ]);
      } catch {
      } finally {
        setSaveInProgress(0);
      }
    },
    [addOrUpdateGroup, addOrUpdateUser, nodeId],
  );

  const enableAddButton = isNodeAdmin || isAdminInOrg;

  return (
    <Column style={{ gap: "2.4rem", height: "100%" }}>
      <Row style={{ alignItems: "center" }}>
        <TabDescription>Collaborators in {node.name}.</TabDescription>
        <div style={{ marginLeft: "auto", position: "relative" }}>
          <Tooltip
            text={
              chosenFolderHasPersonalTopFolder
                ? "Personal folders and projects cannot be shared"
                : "Organisation admin or project admin can add collaborators."
            }
          >
            <Button
              text={node.type === "project" ? "Share project" : "Share folder"}
              buttonType="secondary"
              onClick={() => setShowAddProject((cur) => !cur)}
              disabled={
                saveInProgress > 0 ||
                !enableAddButton ||
                chosenFolderHasPersonalTopFolder
              }
              icon={<InviteIcon />}
            />
          </Tooltip>
          {showAddProject && (
            <div style={{ position: "absolute", right: "0.8rem" }}>
              <UserGroupAccessModal
                resourceName={node.name}
                autoFocus={!openAddCollaborators}
                existingGroups={groupsWithAccess}
                existingUsers={usersWithAccess}
                onSave={onAddAccess}
                onCancel={() => setShowAddProject(false)}
              />
            </div>
          )}
        </div>
      </Row>

      <ContentTableColumn>
        {usersWithAccess.map((ua) => {
          const accessFromThisNode = ua.node_id === nodeId;
          const isCurrentUser = ua.user_id === currentUserId;
          return (
            <ContentTableRow
              key={ua.user_id + ua.resource_name}
              style={{ justifyContent: "space-between" }}
              disabled={deleteInProgress === ua.user_id}
            >
              <Row style={{ flex: "0 0 30%" }}>
                <UserInfo userId={ua.user_id} size={2.2} />
              </Row>
              <Row style={{ flex: "0 0 30%" }}>
                {accessFromThisNode ? (
                  <UserNodeAccessDropdown
                    role={ua.resource_name}
                    nodeId={ua.node_id}
                    userId={ua.user_id}
                  />
                ) : (
                  <SecondaryText style={{ margin: 0 }}>
                    {ua.resource_name}
                  </SecondaryText>
                )}
              </Row>
              <Row style={{ width: "8rem", justifyContent: "flex-end" }}>
                {!accessFromThisNode ? (
                  <NodeLink nodeId={ua.node_id} />
                ) : (
                  <>
                    {isCurrentUser ? (
                      <Button
                        text="Leave"
                        buttonType="text"
                        size="small"
                        onClick={_leaveNode}
                        icon={<Trashcan />}
                      />
                    ) : (
                      (isNodeAdmin || isAdminInOrg) && (
                        <IconBtn
                          onClick={(e) => {
                            e.stopPropagation();
                            setDeleteInProgress(ua.user_id);
                            removeUserNodeAccess(
                              ua.user_id,
                              ua.node_id,
                            ).finally(() => setDeleteInProgress(undefined));
                          }}
                          size={"1.4rem"}
                          disabled={deleteInProgress === ua.user_id}
                        >
                          <Trashcan />
                        </IconBtn>
                      )
                    )}
                  </>
                )}
              </Row>
            </ContentTableRow>
          );
        })}
        {groupsWithAccess.map((ga) => {
          const g = groups.find((g) => g.id === ga.group_id);
          const accessFromThisNode = ga.node_id === nodeId;
          if (!g) return <></>;
          return (
            <ContentTableRow
              key={g.id}
              style={{ justifyContent: "space-between" }}
              disabled={deleteInProgress === ga.group_id}
            >
              <Row
                style={{ gap: "0.8rem", alignItems: "center", flex: "0 0 30%" }}
              >
                <SVGWrapper size={2.2}>
                  <TeamMeeting />
                </SVGWrapper>
                <TextEllipsis style={{ margin: 0 }}>{g.name}</TextEllipsis>
              </Row>
              <Row style={{ flex: "0 0 30%" }}>
                {accessFromThisNode ? (
                  <GroupNodeAccessDropdown
                    groupId={ga.group_id}
                    nodeId={ga.node_id}
                    role={ga.resource_name}
                  />
                ) : (
                  <SecondaryText style={{ margin: 0 }}>
                    {ga.resource_name}
                  </SecondaryText>
                )}
              </Row>
              <Row style={{ width: "8rem", justifyContent: "flex-end" }}>
                {!accessFromThisNode ? (
                  <NodeLink nodeId={ga.node_id} />
                ) : (
                  <>
                    {(isNodeAdmin || isAdminInOrg) && (
                      <IconBtn
                        disabled={deleteInProgress === ga.group_id}
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeleteInProgress(ga.group_id);
                          removeGroupNodeAccess(
                            ga.group_id,
                            ga.node_id,
                          ).finally(() => setDeleteInProgress(undefined));
                        }}
                        size={"1.4rem"}
                      >
                        <Trashcan />
                      </IconBtn>
                    )}
                  </>
                )}
              </Row>
            </ContentTableRow>
          );
        })}
        {Array.from({ length: saveInProgress }, (_, index) => (
          <SkeletonBlock
            key={index}
            style={{ height: "4rem", marginTop: "0.8rem" }}
          />
        ))}
      </ContentTableColumn>
    </Column>
  );
}

export function NodeLink({ nodeId }: { nodeId: string }) {
  const node = useRecoilValue(singleNodeAtomFamily({ nodeId }));

  if (!node) return <></>;
  const isOrg = node.type === "organisation";

  return (
    <Tooltip text="Access inherited from this level — go there to edit.">
      <Row style={{ alignItems: "center", gap: "0.4rem" }}>
        <SVGWrapper size={1.6}>{isOrg ? <House /> : <FolderIcon />}</SVGWrapper>
        <Text>{isOrg ? "Global" : node.name}</Text>
      </Row>
    </Tooltip>
  );
}
