/// <reference types="vite-plugin-svgr/client" />
import { useEffect, useMemo } from "react";
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";

import { replaceOrUndefined } from "../ControlPanels/utils";
import Elements from "@icons/24/SideBarIcons/Elements.svg?react";
import Layers from "@icons/24/SideBarIcons/Layers.svg?react";
import Comments from "@icons/24/SideBarIcons/Comments.svg?react";

import { Divider, IconMenu } from "components/General/Icons";

import SearchWrapper from "components/Search/SearchWrapper";
import {
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";

import SideBarIconBtn from "components/General/SideBarIconButton";
import CommentsLeftModal from "components/Comments/LeftModal/LeftModal";
import ProjectElementsV2 from "components/ProjectElementsV2/ProjectElementsV2";
import { leftModalMenuOpenStateAtom } from "./state";
import styled from "styled-components";
import { LeftModalMenuTypes } from "./state";
import { branchIdSelector, projectIdSelector } from "state/pathParams";
import { Mixpanel } from "mixpanel";
import { filteredThreadsSelectorFamily } from "components/Comments/state";
import { useReadComments } from "components/Comments/UnreadComments/useReadComments";
import { UnreadCommentDot } from "components/Comments/UnreadComments/style";
import LayerListV2 from "components/LayerList/LayerListV2";
import UndoRedoButtonsV2 from "components/ControlPanels/UndoRedoButtonsV2";
import { typography } from "styles/typography";
import { spacing1 } from "styles/space";

const LowerLeftColumnWrapperV2 = styled.div`
  position: absolute;
  bottom: 0.8rem;
  left: 0.8rem;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
`;

const LowerLeftTools = () => {
  const [leftModalMenuOpen, setLeftModalMenuOpen] = useRecoilState(
    leftModalMenuOpenStateAtom,
  );

  const projectId = useRecoilValue(projectIdSelector) ?? "";
  const branchId = useRecoilValue(branchIdSelector) ?? "";

  const allCommentsLoadable = useRecoilValueLoadable(
    filteredThreadsSelectorFamily({ nodeId: projectId, branchId }),
  );
  const { isRead } = useReadComments();

  const hasUnreadComments = useMemo(() => {
    if (allCommentsLoadable.state !== "hasValue") return false;

    const { threads } = allCommentsLoadable.contents;
    return threads.some((c) => !isRead(c));
  }, [allCommentsLoadable.contents, allCommentsLoadable.state, isRead]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--left-menu-margin",
      leftModalMenuOpen &&
        [
          LeftModalMenuTypes.Elements,
          LeftModalMenuTypes.Comment,
          LeftModalMenuTypes.Layers,
        ].includes(leftModalMenuOpen)
        ? `calc(var(--left-menu-width) + 1.6rem)`
        : "0.8rem",
    );
  }, [leftModalMenuOpen]);

  return (
    <IconMenu
      sideBar
      iconSize="2.4rem"
      style={{
        paddingBottom: 0,
        paddingTop: spacing1,
      }}
    >
      <SearchWrapper
        title="View elements list"
        tags={[
          "features",
          "elements",
          "project",
          "status",
          "upload",
          "lock",
          "unlock",
          "styling",
          "style",
          "hide",
          "show",
          "download",
          "export",
        ]}
        id="search-elements-list"
        icon={<Elements />}
        onSelect={() => {
          setLeftModalMenuOpen(replaceOrUndefined(LeftModalMenuTypes.Elements));
          Mixpanel.track(
            `Elements ${
              leftModalMenuOpen === LeftModalMenuTypes.Elements
                ? "closed"
                : "opened"
            }`,
            {
              projectId,
              branchId,
            },
          );
        }}
      >
        <SideBarIconBtn
          icon={<Elements />}
          active={leftModalMenuOpen === LeftModalMenuTypes.Elements}
          onClick={() => {
            setLeftModalMenuOpen(
              replaceOrUndefined(LeftModalMenuTypes.Elements),
            );
            Mixpanel.track(
              `Elements ${
                leftModalMenuOpen === LeftModalMenuTypes.Elements
                  ? "closed"
                  : "opened"
              }`,
              {
                projectId,
                branchId,
              },
            );
          }}
        >
          <p
            style={{
              ...typography.graphics,
            }}
          >
            Elements
          </p>
        </SideBarIconBtn>
      </SearchWrapper>
      <SearchWrapper
        title="View layers"
        tags={["layers", "data", "hide", "show", "download"]}
        id="search-layers"
        icon={<Layers />}
        onSelect={() => {
          setLeftModalMenuOpen(replaceOrUndefined(LeftModalMenuTypes.Layers));
          Mixpanel.track(
            `Layers ${
              leftModalMenuOpen === LeftModalMenuTypes.Layers
                ? "closed"
                : "opened"
            }`,
            {
              projectId,
              branchId,
            },
          );
        }}
      >
        <SideBarIconBtn
          icon={<Layers />}
          active={leftModalMenuOpen === LeftModalMenuTypes.Layers}
          onClick={() => {
            setLeftModalMenuOpen(replaceOrUndefined(LeftModalMenuTypes.Layers));
            Mixpanel.track(
              `Layers ${
                leftModalMenuOpen === LeftModalMenuTypes.Layers
                  ? "closed"
                  : "opened"
              }`,
              {
                projectId,
                branchId,
              },
            );
          }}
        >
          <p
            style={{
              ...typography.graphics,
            }}
          >
            Layers
          </p>
        </SideBarIconBtn>
      </SearchWrapper>
      {projectId && branchId && (
        <>
          <Divider />
          <SearchWrapper
            title="View all comments"
            tags={["people", "users", "communication", "message"]}
            id="search-comments-list"
            icon={<Comments />}
            onSelect={() => {
              setLeftModalMenuOpen(
                replaceOrUndefined(LeftModalMenuTypes.Comment),
              );
              Mixpanel.track(
                `Comments ${
                  leftModalMenuOpen === LeftModalMenuTypes.Comment
                    ? "closed"
                    : "opened"
                }`,
                {
                  projectId,
                  branchId,
                },
              );
            }}
          >
            <SideBarIconBtn
              style={{ position: "relative" }}
              icon={<Comments />}
              onClick={() => {
                setLeftModalMenuOpen(
                  replaceOrUndefined(LeftModalMenuTypes.Comment),
                );
                Mixpanel.track(
                  `Comments ${
                    leftModalMenuOpen === LeftModalMenuTypes.Comment
                      ? "closed"
                      : "opened"
                  }`,
                  {
                    projectId,
                    branchId,
                  },
                );
              }}
              active={leftModalMenuOpen === LeftModalMenuTypes.Comment}
            >
              <p
                style={{
                  ...typography.graphics,
                }}
              >
                Comments
              </p>
              {allCommentsLoadable.state === "hasValue" &&
                hasUnreadComments && (
                  <UnreadCommentDot
                    style={{
                      position: "absolute",
                      top: "0.1rem",
                      right: "0.1rem",
                    }}
                  />
                )}
            </SideBarIconBtn>
          </SearchWrapper>
        </>
      )}
    </IconMenu>
  );
};

const LowerLeftToolsMenuV2 = ErrorBoundaryWrapper(
  () => {
    return (
      <LowerLeftColumnWrapperV2 id="lower-left">
        <LowerLeftTools />
        <CommentsLeftModal />
        <ProjectElementsV2 />
        <LayerListV2 />
        <UndoRedoButtonsV2 />
      </LowerLeftColumnWrapperV2>
    );
  },
  FatalErrorBoundaryWrapper,
  ScreamOnError,
);

export default LowerLeftToolsMenuV2;
