import * as turf from "@turf/turf";
import { exportCableSplitsOk } from "functions/elevation";
import { atom, selectorFamily } from "recoil";
import { fastMax, sum, sumKeys } from "utils/utils";
import { z } from "zod";
import {
  allCableTypesSelector,
  currentExportCableTypesSelector,
} from "../components/Cabling/Generate/state";

import {
  ExportCableVoltageType,
  IAVoltageType,
} from "../services/cableTypeService";
import { Configuration } from "../services/configurationService";
import { fetchSchemaWithToken } from "../services/utils";

import { isDefined } from "../utils/predicates";
import {
  getCablesInBranchSelectorFamily,
  getCablesSelectorFamily,
  getExportCablesInBranchSelectorFamily,
  getSubstationsInBranchSelectorFamily,
} from "./cable";
import { getTurbinesInBranchSelectorFamily } from "./layout";
import { currentSubstationTypesState } from "./substationType";
import { allSimpleTurbineTypesSelector } from "./turbines";
import { cableChainsInBranchSelectorFamily } from "./cableEdit";
import {
  getCablesWithinDivisionSelectorFamily,
  getExportCablesWithinDivisionSelectorFamily,
} from "./division";
import { syncLocalStorage } from "./effects";
import {
  ProdId,
  analysisOverrideInputAtomFamily,
  getBranchId,
  getExportSystemLosses,
  getParkId,
} from "components/ProductionV2/state";
import { binarySearchClosest } from "components/ProductionV2/utils";

export enum LossStatusType {
  Complete = "complete",
  Failed = "failed",
}
const _IALossResultsType = z.object({
  IACableLossPerCase: z.number().array(),
  turbineTrafoLossPerCase: z.number().array(),
  IACableLossPerCable: z
    .object({
      bin: z.number(),
      cableId: z.string(),
      cableLossMW: z.number(),
    })
    .array(),
});

export const _IALossType = z.object({
  status: z.nativeEnum(LossStatusType),
  results: _IALossResultsType.nullish().optional(),
});
export type IALossType = z.infer<typeof _IALossType>;

const _ExportLossResultsType = z.object({
  exportCableLossPerCase: z.number().array(),
  offshoreTrafoLossPerCase: z.number().array(),
  onshoreTrafoLossPerCase: z.number().array(),
  exportCableCurrents: z.record(z.number().array()),
  exportCableSegLengths: z.record(z.number().array()),
  exportSystemVoltages: z.record(z.number().array()),
});

export const _ExportLossType = z.object({
  status: z.nativeEnum(LossStatusType),
  results: _ExportLossResultsType.nullish().optional(),
});
export type ExportLossType = z.infer<typeof _ExportLossType>;

export const _ElectricalAnalysisResult = z.object({
  status: z.nativeEnum(LossStatusType),
  reason: z.string().optional(),
  interArrayResult: _IALossResultsType.nullish(),
  exportSystemResult: _ExportLossResultsType.nullish(),
});
export type ElectricalAnalysisResult = z.infer<
  typeof _ElectricalAnalysisResult
>;

type ExportCable = {
  fromId: string;
  toId: string;
  offshoreLength: number;
  onshoreLength: number;
  offshoreResistance: number;
  offshoreReactance: number;
  offshoreCapacitance: number;
  offshoreAmpacity: number;
  offshoreVoltage: number;
  onshoreResistance: number;
  onshoreReactance: number;
  onshoreCapacitance: number;
  onshoreAmpacity: number;
  onshoreVoltage: number;
};

type OffshoreSubstation = {
  impedance: number;
  resistance: number;
  ironLosses: number;
  noLoadCurrent: number;
  reactor: boolean;
  compensationShare: number;
  exportCableIds: string[];
  MVWindings: number;
  powerCableStrings: number[];
};

type OnshoreSubstation = {
  impedance: number;
  resistance: number;
  ironLosses: number;
  noLoadCurrent: number;
  exportCableIds: string[];
};

type ElectricalTurbine = {
  ratedPowerMW: number;
};

type InterArrayCable = {
  fromId: string;
  toId: string;
  length: number;
  resistance: number;
  reactance: number;
  capacitance: number;
  ampacity: number;
};

export type ElectricalArgs = {
  interArrayCables: Record<string, InterArrayCable>;
  turbines: Record<string, ElectricalTurbine>;
  offshoreSubstations: Record<string, OffshoreSubstation>;
  interArrayVoltage: number;
  exportCables?: Record<string, ExportCable>;
  onshoreSubstations?: Record<string, OnshoreSubstation>;
};

export async function fetchElectricalAnalysis({
  nodeId,
  powerBins,
  input,
}: {
  nodeId: string;
  powerBins: number[];
  input: ElectricalArgs;
}): Promise<ElectricalAnalysisResult> {
  const options = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      ...input,
      powerBins,
    }),
  };
  return await fetchSchemaWithToken(
    _ElectricalAnalysisResult,
    `/api/octopus/electrical/${nodeId}/sync`,
    options,
  );
}

export const getOffshoreExportCableLength = selectorFamily<
  number,
  { parkId: string; branchId: string; selectedSubAreaIds: string[] }
>({
  key: "getOffshoreExportCableLength",
  get:
    ({ parkId, branchId, selectedSubAreaIds }) =>
    ({ get }) => {
      const exportCables = get(
        getExportCablesWithinDivisionSelectorFamily({
          parkId,
          selectedSubAreaIds,
        }),
      );
      if (!exportCables || exportCables.length === 0) return 0;
      const exportCableSegments = get(
        exportCableSplitsOk({ parkId, branchId }),
      );
      return sum(
        exportCables.map((c) => {
          const segments = exportCableSegments.find(
            (seg) => seg.exportCable.id === c.id,
          );
          return turf.length(segments ? segments.offshore : c, {
            units: "kilometers",
          });
        }),
      );
    },
});

export const getOnshoreExportCableLength = selectorFamily<
  number,
  { parkId: string; branchId: string; selectedSubAreaIds: string[] }
>({
  key: "getOnshoreExportCableLength",
  get:
    ({ parkId, branchId, selectedSubAreaIds }) =>
    ({ get }) => {
      const exportCables = get(
        getExportCablesWithinDivisionSelectorFamily({
          parkId,
          selectedSubAreaIds,
        }),
      );
      if (!exportCables || exportCables.length === 0) return 0;
      const exportCableSegments = get(
        exportCableSplitsOk({ parkId, branchId }),
      );
      return sum(
        exportCables.map((c) => {
          const segments = exportCableSegments.find(
            (seg) => seg.exportCable.id === c.id,
          );
          return segments
            ? turf.length(segments.onshore, { units: "kilometers" })
            : 0;
        }),
      );
    },
});

export const getExportVoltageInParkDivision = selectorFamily<
  ExportCableVoltageType | undefined,
  { parkId: string; selectedSubAreaIds: string[] }
>({
  key: "getExportVoltageInParkDivision",
  get:
    ({ parkId, selectedSubAreaIds }) =>
    ({ get }) => {
      const exportCables = get(
        getExportCablesWithinDivisionSelectorFamily({
          parkId,
          selectedSubAreaIds,
        }),
      );
      if (!exportCables || exportCables.length === 0) return;
      const allCableTypes = get(allCableTypesSelector);
      const voltageLengths = sumKeys(
        exportCables
          .map<[number, number] | undefined>((c) => {
            const cableType = allCableTypes.find(
              (ct) => ct.id === c.properties.cableTypeId,
            );
            if (!cableType) return undefined;
            const cableLength = turf.length(c, { units: "kilometers" });
            return [cableType.voltage, cableLength];
          })
          .filter(isDefined),
      );

      let exportVoltage: ExportCableVoltageType = ExportCableVoltageType.kV150;
      let maxLength = 0;
      for (const key in voltageLengths) {
        if (voltageLengths[key] > maxLength) {
          maxLength = voltageLengths[key];
          exportVoltage = Number.parseInt(key);
        }
      }
      return exportVoltage;
    },
});

export const getIAVoltageInParkDivision = selectorFamily<
  IAVoltageType | undefined,
  { parkId: string; selectedSubAreaIds: string[] }
>({
  key: "getIAVoltageInParkDivision",
  get:
    ({ parkId, selectedSubAreaIds }) =>
    ({ get }) => {
      const cables = get(
        getCablesWithinDivisionSelectorFamily({ parkId, selectedSubAreaIds }),
      );
      if (!cables || cables.length === 0) return;
      const allCableTypes = get(allCableTypesSelector);
      const voltageLengths = sumKeys(
        cables
          .map<[number, number] | undefined>((c) => {
            const cableType = allCableTypes.find(
              (ct) => ct.id === c.properties.cableTypeId,
            );
            if (!cableType) return undefined;
            const cableLength = turf.length(c, { units: "kilometers" });
            return [cableType.voltage, cableLength];
          })
          .filter(isDefined),
      );

      let IAVoltage: IAVoltageType = IAVoltageType.kV66;
      let maxLength = 0;
      for (const key in voltageLengths) {
        if (voltageLengths[key] > maxLength) {
          maxLength = voltageLengths[key];
          IAVoltage = Number.parseInt(key);
        }
      }
      return IAVoltage;
    },
});

export const getIAVoltageInPark = selectorFamily<
  IAVoltageType,
  { parkId: string }
>({
  key: "getIAVoltageInPark",
  get:
    ({ parkId }) =>
    ({ get }) => {
      const cables = get(getCablesSelectorFamily({ parkId }));
      const allCableTypes = get(allCableTypesSelector);
      const voltageLengths = sumKeys(
        cables
          .map<[number, number] | undefined>((c) => {
            const cableType = allCableTypes.find(
              (ct) => ct.id === c.properties.cableTypeId,
            );
            if (!cableType) return undefined;
            const cableLength = turf.length(c, { units: "kilometers" });
            return [cableType.voltage, cableLength];
          })
          .filter(isDefined),
      );

      let IAVoltage: IAVoltageType = IAVoltageType.kV66;
      let maxLength = 0;
      for (const key in voltageLengths) {
        if (voltageLengths[key] > maxLength) {
          maxLength = voltageLengths[key];
          IAVoltage = Number.parseInt(key);
        }
      }
      return IAVoltage;
    },
});

export const getIAVoltageInParkAndBranch = selectorFamily<
  IAVoltageType,
  { parkId: string; branchId: string }
>({
  key: "getIAVoltageInParkAndBranch",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const cables = get(getCablesInBranchSelectorFamily({ parkId, branchId }));
      const allCableTypes = get(allCableTypesSelector);
      const voltageLengths = sumKeys(
        cables
          .map((c) => {
            const cableType = allCableTypes.find(
              (ct) => ct.id === c.properties.cableTypeId,
            );
            if (!cableType) return undefined;
            const cableLength = turf.length(c, { units: "kilometers" });
            return [cableType.voltage, cableLength] as [number, number];
          })
          .filter(isDefined),
      );

      let IAVoltage: IAVoltageType = IAVoltageType.kV66;
      let maxLength = 0;
      for (const key in voltageLengths) {
        if (voltageLengths[key] > maxLength) {
          maxLength = voltageLengths[key];
          IAVoltage = Number.parseInt(key);
        }
      }
      return IAVoltage;
    },
});

export const getIAVoltageTypesInParkAndBranch = selectorFamily<
  IAVoltageType[],
  { parkId: string; branchId: string }
>({
  key: "getIAVoltageTypesInParkAndBranch",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const cables = get(getCablesInBranchSelectorFamily({ parkId, branchId }));
      const allCableTypes = get(allCableTypesSelector);
      const currentCableTypes = cables
        .map((c) =>
          allCableTypes.find((ct) => ct.id === c.properties.cableTypeId),
        )
        .filter(isDefined);

      return [...new Set(currentCableTypes.map((ct) => ct.voltage)).values()];
    },
});

export const getElectricalAnalysis = selectorFamily<
  ElectricalAnalysisResult | undefined | null,
  {
    projectId: string;
    input: ElectricalArgs;
    powerBins: number[];
  }
>({
  key: "getElectricalAnalysisForPark",
  get:
    ({ projectId, input, powerBins }) =>
    () => {
      if (!input) return;
      return fetchElectricalAnalysis({
        nodeId: projectId,
        input,
        powerBins,
      });
    },
});

export const getElectricalAnalysisArgs = selectorFamily<
  ElectricalArgs | undefined | null,
  {
    branchId: string;
    parkId: string;
    configuration?: Configuration;
    exportCableTypeOverrideId?: string;
  }
>({
  key: "getElectricalAnalysisArgs",
  get:
    ({ branchId, parkId, configuration, exportCableTypeOverrideId }) =>
    ({ get }) => {
      const electricalConfig = configuration?.electrical;
      const cables = get(
        getCablesInBranchSelectorFamily({ parkId, branchId }),
      ).filter((c) => !c.properties.redundancy);
      if (!electricalConfig || cables.length === 0) return null;

      // Turbines
      const rawTurbines = get(
        getTurbinesInBranchSelectorFamily({ parkId, branchId }),
      );
      const turbineTypes = get(allSimpleTurbineTypesSelector);
      const turbines = Object.fromEntries(
        rawTurbines.map((t) => {
          const ratedPower =
            turbineTypes.find((type) => type.id === t.properties.turbineTypeId)
              ?.ratedPower ?? 0;
          const ratedPowerMW = ratedPower / 1000;
          return [t.id, { ratedPowerMW }];
        }),
      );

      // Inter-array cables
      const interArrayVoltage = get(
        getIAVoltageInParkAndBranch({ parkId, branchId }),
      );

      const cableLengthContingencyFactor =
        electricalConfig.cableLengthContingencyEnabled
          ? 1 + electricalConfig.cableLengthContingency
          : 1;

      const cableTypes = get(allCableTypesSelector);
      const interArrayCables = Object.fromEntries(
        cables
          .map((c) => {
            const cableType = cableTypes.find(
              (ct) => ct.id === c.properties.cableTypeId,
            );
            if (!cableType) return undefined;
            const fromId = c.properties.fromId;
            const toId = c.properties.toId;
            const length =
              turf.length(c, { units: "kilometers" }) *
              cableLengthContingencyFactor;
            const { resistance, reactance, capacitance, ampacity } = cableType;
            return [
              c.id,
              {
                fromId,
                toId,
                length,
                resistance,
                reactance,
                capacitance,
                ampacity,
              },
            ];
          })
          .filter(isDefined),
      );

      // Export cables
      const exportCables = get(
        getExportCablesInBranchSelectorFamily({
          parkId,
          branchId,
          exportCableTypeOverrideId,
        }),
      );
      const exportCableSegments = get(
        exportCableSplitsOk({ parkId, branchId, exportCableTypeOverrideId }),
      );
      const exportCableTypes = get(currentExportCableTypesSelector);
      const exportCableData = Object.fromEntries(
        exportCables
          .map((c) => {
            const segments = exportCableSegments.find(
              (seg) => seg.exportCable.id === c.id,
            );
            const offshoreCableType = exportCableTypes.find(
              (ct) => ct.id === c.properties.cableTypeId,
            );
            const onshoreCableType = exportCableTypes.find(
              (ct) => ct.id === c.properties.onshoreCableTypeId,
            );
            if (!offshoreCableType || !onshoreCableType) return undefined;
            const fromId = c.properties.fromSubstationId;
            const toId = c.properties.toSubstationId;
            const offshoreLength = turf.length(
              segments ? segments.offshore : c,
              {
                units: "kilometers",
              },
            );
            const onshoreLength = segments
              ? turf.length(segments.onshore, {
                  units: "kilometers",
                })
              : 0;
            const {
              resistance: offshoreResistance,
              reactance: offshoreReactance,
              capacitance: offshoreCapacitance,
              ampacity: offshoreAmpacity,
              voltage: offshoreVoltage,
            } = offshoreCableType;
            const {
              resistance: onshoreResistance,
              reactance: onshoreReactance,
              capacitance: onshoreCapacitance,
              ampacity: onshoreAmpacity,
              voltage: onshoreVoltage,
            } = onshoreCableType;
            return [
              c.id,
              {
                fromId,
                toId,
                offshoreLength,
                onshoreLength,
                offshoreResistance,
                offshoreReactance,
                offshoreCapacitance,
                offshoreAmpacity,
                offshoreVoltage,
                onshoreResistance,
                onshoreReactance,
                onshoreCapacitance,
                onshoreAmpacity,
                onshoreVoltage,
              },
            ];
          })
          .filter(isDefined),
      );

      // Substations
      const substations = get(
        getSubstationsInBranchSelectorFamily({ parkId, branchId }),
      );
      const cableChains = get(
        cableChainsInBranchSelectorFamily({ parkId, branchId }),
      ).filter((c) => substations.find((s) => s.id === c.substation));
      const substationTypes = get(currentSubstationTypesState);
      const cableAdjacentIds = new Set(
        cables.flatMap((c) => [c.properties.fromId, c.properties.toId]),
      );

      const offshoreSubstations: Record<string, OffshoreSubstation> =
        Object.fromEntries(
          substations
            .map((sub) => {
              const substationType = substationTypes.find(
                (st) => st.id === sub.properties.substationTypeId,
              );
              if (!substationType) return undefined;
              // If no inter-array cables are connected, skip it. Otherwise we
              // pretend it's an offshore sub, no matter if it's really on-shore
              // or not.  We do this so that we can connect turbines straight to
              // an on-shore substation, getting the different cost for an
              // onshore sub.
              if (!cableAdjacentIds.has(sub.id)) return undefined;
              let {
                impedance,
                resistance,
                ironLosses,
                noLoadCurrent,
                reactor,
                compensationShare,
              } = substationType;
              reactor = reactor ?? false;

              const exportCableIds = exportCables
                .filter(
                  (c) =>
                    c.properties.fromSubstationId === sub.id ||
                    c.properties.toSubstationId === sub.id,
                )
                .map((c) => c.id);
              // Get all cables conneced to this sub
              const subCableChains = cableChains.filter(
                (c) => c.substation === sub.id,
              );
              const powerCableStrings = subCableChains.map((c) => {
                return sum(c.turbines, (chain_turbine) => {
                  const turbine = rawTurbines.find(
                    (t) => t.id === chain_turbine,
                  );
                  if (!turbine) return 0;
                  const turbineType = turbineTypes.find(
                    (type) => type.id === turbine.properties.turbineTypeId,
                  );
                  if (!turbineType) return 0;
                  return turbineType.ratedPower / 1000;
                });
              });

              const totalSubstationPower = powerCableStrings.reduce(
                (acc, power) => acc + power,
                0,
              );

              let MVWindings = 1;
              const busBarCurrent =
                (1000 * totalSubstationPower) /
                (exportCableIds.length || 1) /
                (interArrayVoltage * Math.sqrt(3)) /
                0.9;

              if (interArrayVoltage === IAVoltageType.kV66) {
                if (busBarCurrent > 2500) MVWindings = 2; //TODO: add warning for very high busBarCurrent, in which the system is infeasible
              } else {
                if (busBarCurrent > 3150) MVWindings = 2;
              }

              if (powerCableStrings.length === 1) MVWindings = 1;

              return [
                sub.id,
                {
                  impedance,
                  resistance,
                  ironLosses,
                  noLoadCurrent,
                  reactor,
                  compensationShare,
                  exportCableIds,
                  MVWindings,
                  powerCableStrings,
                },
              ];
            })
            .filter(isDefined),
        );

      const onshoreSubstations: {
        [substationId: string]: {
          impedance: number;
          resistance: number;
          ironLosses: number;
          noLoadCurrent: number;
          exportCableIds: string[];
        };
      } = Object.fromEntries(
        substations
          .map((sub) => {
            const substationType = substationTypes.find(
              (st) => st.id === sub.properties.substationTypeId,
            );
            if (!substationType || substationType.type !== "onshore")
              return undefined;
            const { impedance, resistance, ironLosses, noLoadCurrent } =
              substationType;

            const exportCableIds = exportCables
              .filter(
                (c) =>
                  c.properties.fromSubstationId === sub.id ||
                  c.properties.toSubstationId === sub.id,
              )
              .map((c) => c.id);

            return [
              sub.id,
              {
                impedance,
                resistance,
                ironLosses,
                noLoadCurrent,
                exportCableIds,
              },
            ];
          })
          .filter(isDefined),
      );

      return {
        interArrayVoltage,
        turbines,
        interArrayCables,
        offshoreSubstations,
        exportCables: exportCableData,
        onshoreSubstations,
      };
    },
});

export const showCableTypeColorInMapAtom = atom<boolean>({
  key: "showCableTypeColorInMapAtom",
  default: false,
  effects: [
    syncLocalStorage("vind:electrical:cableTypeVisualisation", z.boolean()),
  ],
});

export const getExportCablesUtilizationSelectorFamily = selectorFamily<
  | { onshoreUtilization: number | null; offshoreUtilization: number | null }
  | undefined,
  ProdId
>({
  key: "getExportCablesUtilizationSelectorFamily",
  get:
    (triggerId: ProdId) =>
    ({ get }) => {
      const branchId = get(getBranchId(triggerId));
      const parkId = get(getParkId(triggerId));

      const exportSystemLoss = get(getExportSystemLosses(triggerId));
      const exportCableCurrents =
        exportSystemLoss?.results?.exportCableCurrents;
      const exportCableSegLengths =
        exportSystemLoss?.results?.exportCableSegLengths;

      const input = get(analysisOverrideInputAtomFamily(triggerId));
      const exportCables = get(
        getExportCablesInBranchSelectorFamily({
          parkId,
          branchId,
          exportCableTypeOverrideId: input.exportCableTypeOverrideId,
        }),
      );
      const exportCableSegments = get(
        exportCableSplitsOk({
          parkId,
          branchId,
          exportCableTypeOverrideId: input.exportCableTypeOverrideId,
        }),
      );

      const exportCableTypes = get(currentExportCableTypesSelector);

      if (!exportCableCurrents) return undefined;
      if (!exportCableSegLengths) return undefined;

      let onshoreUtilizations: number[] = [];
      let offshoreUtilizations: number[] = [];

      exportCables.forEach((e) => {
        const offshoreCableTypeId = e.properties.cableTypeId;
        const onshoreCableTypeId = e.properties.onshoreCableTypeId;

        const offshoreRatedCurrent =
          exportCableTypes.find((ct) => ct.id === offshoreCableTypeId)!
            .ampacity * 1000;
        const onshoreRatedCurrent =
          exportCableTypes.find((ct) => ct.id === onshoreCableTypeId)!
            .ampacity * 1000;

        const offshoreCable = exportCableSegments.find(
          (c) => c.exportCable.id === e.id,
        )?.offshore;

        const selectedExportCableSegLengths = exportCableSegLengths[e.id];
        const offshoreLength = turf.length(offshoreCable ?? e, {
          units: "kilometers",
        });

        const landfallIdx = binarySearchClosest(
          selectedExportCableSegLengths,
          offshoreLength,
        );

        const selectedExportCableCurrents = exportCableCurrents[e.id];
        const offshoreCurrentValues = selectedExportCableCurrents.slice(
          0,
          landfallIdx + 1,
        );
        if (offshoreCurrentValues.length > 0) {
          const maxOffshore = fastMax(offshoreCurrentValues);
          offshoreUtilizations.push(maxOffshore / offshoreRatedCurrent);
        }

        const onshoreCurrentValues = selectedExportCableCurrents.slice(
          landfallIdx + 1,
          selectedExportCableCurrents.length + 1,
        );
        if (onshoreCurrentValues.length > 0) {
          const maxOnshore = fastMax(onshoreCurrentValues);
          onshoreUtilizations.push(maxOnshore / onshoreRatedCurrent);
        }
      });
      return {
        onshoreUtilization:
          onshoreUtilizations.length === 0
            ? null
            : fastMax(onshoreUtilizations),
        offshoreUtilization:
          offshoreUtilizations.length === 0
            ? null
            : fastMax(offshoreUtilizations),
      };
    },
});
