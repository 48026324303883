import { useEffect } from "react";

let lastDormantTime: number | null = null;

const useRefreshOnTabFocusAfterDormancy = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      const now = new Date().getTime();

      if (document.visibilityState === "hidden") {
        // Tab goes dormant - record the current time in the global variable
        lastDormantTime = now;
      } else if (
        document.visibilityState === "visible" &&
        lastDormantTime !== null
      ) {
        // Tab becomes active again - check how long it was dormant
        // Refresh the page if dormant for more than 4 hours (4 * 60 * 60 * 1000 milliseconds)
        if (now - lastDormantTime > 14400000) {
          window.location.reload();
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
};

export default useRefreshOnTabFocusAfterDormancy;
