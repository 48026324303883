/// <reference types="vite-plugin-svgr/client" />
import styled from "styled-components";
import Tooltip from "../../General/Tooltip";
import ViewIcon from "@icons/24/View.svg?react";
import { inReadOnlyModeSelector } from "../../../state/project";
import { useRecoilValue } from "recoil";
import { colors } from "../../../styles/colors";
import { IconREMSize, typography } from "../../../styles/typography";
import { useSetVersion } from "../../../hooks/useSetVersion";
import { useEffect } from "react";

const Wrapper = styled.div`
  display: flex;
  gap: 0.8rem;
  color: ${colors.textNegative};
  ${typography.body}
`;

const RoundIconWrapper = styled.div`
  padding: 0.3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ViewIconWrapper = styled(RoundIconWrapper)`
  background-color: ${colors.orange100};
  svg {
    path {
      stroke: ${colors.iconWarning};
    }
  }
`;

const ReadOnly = () => {
  const isReadOnly = useRecoilValue(inReadOnlyModeSelector);
  const setVersion = useSetVersion();

  useEffect(() => {
    if (!isReadOnly) return;

    const closeWithEsc = (e: KeyboardEvent) => {
      if (e.key !== "Escape") {
        return;
      }

      setVersion(undefined);
    };
    window.addEventListener("keyup", closeWithEsc);
    return () => {
      window.removeEventListener("keyup", closeWithEsc);
    };
  }, [isReadOnly, setVersion]);

  if (!isReadOnly) return null;

  const text = "Snapshots cannot be edited";
  return (
    <Tooltip text={text} position="top">
      <Wrapper>
        <ViewIconWrapper>
          <IconREMSize height={1.4} width={1.4}>
            <ViewIcon />
          </IconREMSize>
        </ViewIconWrapper>
        View only
      </Wrapper>
    </Tooltip>
  );
};

export default ReadOnly;
