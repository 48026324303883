import * as turf from "@turf/turf";
import { pointInPolygon } from "utils/geometry";
import { Raster } from "types/raster";
import { NO_DATA_VAL } from "components/MouseSampler/constants";

export const BATHYMETRY_SOURCE_DESCRIPTION =
  "Source: best available of uploaded bathymetry, EMODnet and GEBCO";

export function getDepthAtPoints(
  raster: Raster,
  lonlats: number[][],
  bilinear?: boolean,
  onNaN?: (index: number) => void,
): number[] {
  if (bilinear)
    return lonlats.map((lonlat, i) => {
      const d = raster.latLngToValue_bilinear(lonlat[0], lonlat[1]);
      if (isNaN(d)) onNaN?.(i);
      return d;
    });

  return lonlats.map((lonlat, i) => {
    const d = raster.latLngToValue(lonlat[0], lonlat[1]);
    if (isNaN(d)) onNaN?.(i);
    return d;
  });
}

export function calcMinMaxAvg(
  maxLatitude: number,
  minLatitude: number,
  maxLongitude: number,
  minLongitude: number,
  h: number,
  w: number,
  values: any,
  canvasFeature: any,
) {
  const sizeLatitude = maxLatitude - minLatitude;
  const sizeLongitude = maxLongitude - minLongitude;
  const stepLatitude = sizeLatitude / h;
  const stepLongitude = sizeLongitude / w;
  const imageIndexToLatLng = (i: number) => {
    const x = i % w;
    const y = Math.floor(i / w);
    return [x * stepLongitude + minLongitude, y * -stepLatitude + maxLatitude];
  };

  const valuesInsidePolygon = values.filter(
    (e: number, i: number) =>
      !isNaN(e) &&
      pointInPolygon(
        turf.point(imageIndexToLatLng(i)).geometry,
        canvasFeature.geometry,
      ),
  );

  const averageValue =
    valuesInsidePolygon.reduce((sum: number, e: number) => (sum += e), 0) /
    valuesInsidePolygon.length;

  const [minValue, maxValue] = valuesInsidePolygon.reduce(
    ([min, max]: [number, number], e: number) => [
      e === NO_DATA_VAL ? min : Math.min(min, Math.abs(e)),
      Math.max(max, Math.abs(e)),
    ],
    [Infinity, 0],
  );
  return [minValue, maxValue, averageValue];
}
