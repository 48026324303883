import React, { useCallback } from "react";
import { Mixpanel } from "mixpanel";
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import styled from "styled-components";
import { toPng } from "html-to-image";
import { ParkFeature } from "types/feature";
import CsvIcon from "@icons/24/FileCsv.svg?react";
import PngIcon from "@icons/24/FilePng.svg?react";
import { colors } from "styles/colors";
import { BranchMeta } from "types/api";
import { Configuration } from "services/configurationService";
import { CostConfiguration } from "services/costService";
import { WindSourceConfiguration } from "services/windSourceConfigurationService";
import { isDefined } from "utils/predicates";
import { scream } from "utils/sentry";
import { toastMessagesAtom } from "state/toast";
import { isPrintingAtom } from "components/Dashboard/state";
import { useColumnTemplates } from "components/CompareParksModal/columnTemplates";
import { libraryAndProjectConfigurationsSelectorFamily } from "state/configuration";
import { libraryAndProjectCostConfigurationsSelectorFamily } from "state/costConfigurations";
import { windSourceConfigurationsAtomFamily } from "state/windSourceConfiguration";
import { getParkFeatureInBranchSelector } from "state/park";
import { getBranchSelectorFamily } from "state/timeline";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { MenuButton } from "components/General/MenuButton";
import DownloadIcon from "@icons/24/Download.svg?react";
import { MenuItem } from "components/General/Menu";
import { downloadText } from "utils/utils";
import {
  Columns,
  CompareColumnItemAttribute,
  ComparisonAttributeKey,
  comparisonAttributeKeys,
} from "./types";
import {
  compareIsLoading,
  ComparisonData,
  getSelectedParksForComparisonSelector,
  selectedComparisonAttributesAtom,
  selectedParksAtom,
  shownCompareDataAtom,
  visibleComparisonListRowsAtom,
} from "./state";

const DownloadIconStyled = styled(DownloadIcon)`
  path {
    stroke: ${colors.white} !important;
  }
`;

const getSortedConfigurationNames = (
  data: ComparisonData,
  dataConfigurationId:
    | "costConfigurationId"
    | "windConfigurationId"
    | "analysisConfigurationId",
  getBranch: (branchId: string) => BranchMeta | undefined,
  sortedComparisonIds: string[],
  configurations: { id: string; name?: string }[],
) =>
  sortedComparisonIds
    .map((comparisonId) => data[comparisonId])
    .map(
      (dataForPark) =>
        configurations.find((c) => c.id === dataForPark[dataConfigurationId]) ??
        configurations.find(
          (c) =>
            c.id === getBranch(dataForPark.branchId)?.[dataConfigurationId],
        ) ??
        configurations[0],
    )
    .filter(isDefined)
    .map((f) => f.name)
    .filter(isDefined);

const delim = ",";
const removeDelimiter = (s: string) => s.replaceAll(delim, "");

const getAttributeRows = (
  attribute: CompareColumnItemAttribute,
  sortedComparisonIds: string[],
  data: ComparisonData,
  attributeKey: ComparisonAttributeKey,
  visibleComparisonListRows: string[] | undefined,
  subsection?: string,
): string[] => {
  const { csvFormat, unit } = attribute;

  const formatter = csvFormat;
  if (!formatter) {
    return [];
  }

  if (
    typeof visibleComparisonListRows !== "undefined" &&
    !visibleComparisonListRows.includes(attribute.key)
  ) {
    return [];
  }

  const values = sortedComparisonIds
    .map((comparisonId) => data[comparisonId])
    .flat()
    .map((parkData) => {
      const parkAttributeValue = subsection
        ? (parkData.comparisonData[attributeKey][subsection] as any)?.[
            attribute.key
          ]
        : parkData.comparisonData[attributeKey][attribute.key];
      // Some values use `undefined` to indicate that the value is loading.
      // Others can be `undefined`, and the `format` for these keys take
      // this into account.  Only warn if the former is the case, since this
      // means the user is trying to download the csv while data is loading.
      const keyWithMaybeUndefinedValue = [
        "selectedHubHeight",
        "turbineTypesString",
      ];
      if (
        parkAttributeValue === undefined &&
        !keyWithMaybeUndefinedValue.includes(attribute.key)
      ) {
        scream('makeCSVString: value was undefined (and replaced with "")', {
          section: attributeKey,
          attr: attribute,
        });
        return undefined;
      }
      return parkAttributeValue;
    })
    .map((value) => (value === undefined ? "" : formatter(value)))
    .map(removeDelimiter);
  if (unit) {
    return [`${attribute.name} (${removeDelimiter(unit)})`, ...values];
  } else {
    return [attribute.name, ...values];
  }
};

export const makeCSVString = (
  data: ComparisonData,
  selectedAttrs: Record<ComparisonAttributeKey, string[]>,
  columns: Columns,
  getPark: ({
    parkId,
    branchId,
  }: {
    parkId: string;
    branchId: string;
  }) => ParkFeature | undefined,
  getSortOrder: (comparisonId: string) => number,
  getBranch: (branchId: string) => BranchMeta | undefined,
  productionConfigurations: Configuration[],
  costConfigurations: CostConfiguration[],
  windConfigurations: WindSourceConfiguration[],
  visibleComparisonListRows: Record<string, string[]>,
): string => {
  const sortedComparisonIds = Object.keys(data).sort((a, b) => {
    const sortOrder1 = getSortOrder(a);
    const sortOrder2 = getSortOrder(b);
    return sortOrder1 - sortOrder2;
  });

  const analysisVersion = sortedComparisonIds
    .map((comparisonId) => data[comparisonId])
    .map(({ analysisVersion }) => analysisVersion);

  const branchNames = sortedComparisonIds
    .map((comparisonId) => data[comparisonId])
    .map(({ branchId }) => getBranch(branchId)?.title)
    .filter(isDefined);

  const parkNames = sortedComparisonIds
    .map((comparisonId) => data[comparisonId])
    .map(({ branchId, parkId }) => getPark({ branchId, parkId }))
    .filter(isDefined)
    .map((f) => f.properties.name)
    .filter(isDefined);

  const windConfigurationNames = getSortedConfigurationNames(
    data,
    "windConfigurationId",
    getBranch,
    sortedComparisonIds,
    windConfigurations,
  );
  const productionConfigurationNames = getSortedConfigurationNames(
    data,
    "analysisConfigurationId",
    getBranch,
    sortedComparisonIds,
    productionConfigurations,
  );
  const costConfigurationNames = getSortedConfigurationNames(
    data,
    "costConfigurationId",
    getBranch,
    sortedComparisonIds,
    costConfigurations,
  );

  const csvRows = [
    ["Park name", ...parkNames.map(removeDelimiter)],
    ["Branch", ...branchNames.map(removeDelimiter)],
    ["Cost configuration", ...costConfigurationNames.map(removeDelimiter)],
    [
      "Production configuration",
      ...productionConfigurationNames.map(removeDelimiter),
    ],
    ["Wind configuration", ...windConfigurationNames.map(removeDelimiter)],
    ["Analysis version", ...analysisVersion],
  ];

  for (const section of comparisonAttributeKeys) {
    for (const attr of columns[section].attributes) {
      if (!selectedAttrs[section].includes(attr.key)) {
        continue;
      }

      if (attr.type === "list") {
        for (const subAttr of attr.values) {
          const result = getAttributeRows(
            subAttr,
            sortedComparisonIds,
            data,
            section,
            visibleComparisonListRows[attr.key],
            attr.key,
          );
          if (result.length > 0) csvRows.push(result);
        }
      } else {
        const result = getAttributeRows(
          attr,
          sortedComparisonIds,
          data,
          section,
          visibleComparisonListRows[attr.key],
        );
        csvRows.push(result);
      }
    }
  }

  return csvRows.map((row) => row.join(delim)).join("\n");
};

function CompareDownloadButton({
  projectId,
  printAreaElementId = "compare-print-area",
}: {
  projectId: string;
  printAreaElementId?: string;
}) {
  const selectedParks = useRecoilValue(
    getSelectedParksForComparisonSelector({ nodeId: projectId }),
  );
  const setToastMessages = useSetRecoilState(toastMessagesAtom);
  const [isPrinting, setIsPrinting] = useRecoilState(isPrintingAtom);
  const selectedParkIds = useRecoilValue(selectedParksAtom({ projectId }));
  const shownData = useRecoilValue(shownCompareDataAtom);
  const isLoadingValues = useRecoilValue(compareIsLoading);
  const isLoading = Object.values(isLoadingValues).some((v) => v);
  const selectedAttributes = useRecoilValue(selectedComparisonAttributesAtom);
  const columnTemplates = useColumnTemplates();
  const configurations = useRecoilValue(
    libraryAndProjectConfigurationsSelectorFamily({ nodeId: projectId }),
  );
  const costConfigurations = useRecoilValue(
    libraryAndProjectCostConfigurationsSelectorFamily({ nodeId: projectId }),
  );
  const windConfigurations = useRecoilValue(
    windSourceConfigurationsAtomFamily({ projectId }),
  );
  const visibleComparisonListRows = useRecoilValue(
    visibleComparisonListRowsAtom,
  );

  const getPark = useRecoilCallback(
    ({ snapshot }) =>
      ({ parkId, branchId }) => {
        const park = snapshot
          .getLoadable(getParkFeatureInBranchSelector({ parkId, branchId }))
          .getValue();
        return park;
      },
    [],
  );

  const getBranch = useRecoilCallback(
    ({ snapshot }) =>
      (branchId: string) => {
        const branch = snapshot
          .getLoadable(getBranchSelectorFamily({ branchId, projectId }))
          .getValue();
        return branch;
      },
    [projectId],
  );

  const getSortOrder = useCallback(
    (comparisonId: string) => {
      return selectedParkIds.findIndex(
        (park) => park.comparisonId === comparisonId,
      );
    },
    [selectedParkIds],
  );

  if (isPrinting)
    return <SkeletonBlock style={{ width: "3.2rem", height: "3.2rem" }} />;

  return (
    <MenuButton
      icon={<DownloadIconStyled />}
      buttonType="text"
      side="right"
      id="dashboard-btn-download"
    >
      <MenuItem
        name="Download as .png"
        icon={<PngIcon />}
        onClick={() => {
          Mixpanel.track("Save Compare parks as img", {});
          const currentRef = document.querySelector(`#${printAreaElementId}`);

          if (!currentRef) return;
          const clone = currentRef.cloneNode(true) as HTMLDivElement;
          document.body.appendChild(clone);

          // For some reason, the park images are not cloned correctly. Go through all exising canvases and draw the same image in the clones
          const oldCanvases =
            currentRef.querySelectorAll<HTMLCanvasElement>("canvas");
          const clonedCanvases =
            clone.querySelectorAll<HTMLCanvasElement>("canvas");
          oldCanvases.forEach((oldCanvas, index) => {
            const clonedCanvas = clonedCanvases.item(index);
            const context = clonedCanvas!.getContext("2d");
            clonedCanvas!.width = oldCanvas!.width;
            clonedCanvas!.height = oldCanvas!.height;
            context!.drawImage(oldCanvas!, 0, 0);
          });
          setIsPrinting(true);
          const paddingPx = 10;
          clone.style.overflow = "visible";
          clone.style.height = "100%";
          clone.style.padding = `${paddingPx}px`;
          clone.style.boxSizing = "border-box";
          clone.style.width = "fit-content";

          setTimeout(() => {
            const dropdownConfigs =
              clone.getElementsByClassName("dropdown-config");
            for (let i = 0; i < dropdownConfigs.length; i++) {
              const dropdownConfig = dropdownConfigs[i] as HTMLSelectElement;
              dropdownConfig.classList.add("hide-for-export");
              if (dropdownConfig.parentElement) {
                dropdownConfig.parentElement.style.width = "100%";
              }
            }

            const columnToRemoveScrollFor = clone.querySelector<HTMLDivElement>(
              ".hide-scroll-in-export",
            );
            columnToRemoveScrollFor?.classList.add("no-scroll");

            const elementsToHide = clone.querySelectorAll(".hide-in-export");
            elementsToHide.forEach((element) => {
              element.parentElement?.removeChild(element);
            });

            toPng(clone, {
              cacheBust: true,
              height: clone.scrollHeight,
              width: clone.scrollWidth + paddingPx * 2,
            })
              .then((dataUrl) => {
                for (let i = 0; i < dropdownConfigs.length; i++) {
                  dropdownConfigs[i].classList.remove("hide-for-export");
                }
                const link = document.createElement("a");
                link.download = `comparison-${selectedParks
                  .map((p) => p.park.properties.name ?? "UntitledPark")
                  .join("-")}.png`.replaceAll(" ", "_");
                link.href = dataUrl;
                link.click();
                setIsPrinting(false);
              })
              .catch((err) => {
                setIsPrinting(false);
                setToastMessages((tm) => [
                  ...tm,
                  {
                    text: "Something went wrong when trying to save comparison as image",
                    timeout: 5000,
                    type: "error",
                  },
                ]);
                console.log(err);
              })
              .finally(() => {
                document.body.removeChild(clone);
              });
          }, 100);
        }}
        // disabled={printRef.current === undefined}
      />
      <MenuItem
        id="dashboard-btn-download-csv"
        name="Download as .csv"
        icon={<CsvIcon />}
        disabled={isLoading}
        onClick={() => {
          Mixpanel.track("Export Compare parks data as .csv", {
            nrParks: selectedParks.length,
          });
          const csv = makeCSVString(
            shownData,
            selectedAttributes,
            columnTemplates,
            getPark,
            getSortOrder,
            getBranch,
            configurations,
            costConfigurations,
            windConfigurations,
            visibleComparisonListRows,
          );
          downloadText(csv, "parks-comparison.csv");
        }}
      />
    </MenuButton>
  );
}

export default CompareDownloadButton;
