import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import pako from "pako";
import ArrowLeftIcon from "@icons/24/ArrowLeft.svg";
import { useToast } from "hooks/useToast";
import { CustomLayerAddResponse } from "services/customLayersAPIService";
import { spaceMedium } from "styles/space";
import { accessToken } from "state/global";
import { isDefined } from "utils/predicates";
import { sendWarning } from "utils/sentry";
import { dedup, promiseWorker, typedWorker } from "utils/utils";
import Button from "components/General/Button";
import { neededShpFileSuffixes } from "components/UploadFile/fileUtils";
import {
  KMLToGeojsonFile,
  acceptedShapeFileEndings,
  getAllFilesOfTypeFromZip,
  getEPSGForGISFile,
  getFileTypeFromFileName,
  getZippedShapeFiles,
  parseFileAndCleanGeoJson,
} from "../../../utils";
import { ButtonWrapper, DropFileToUpload, UploadWrapper } from "../../shared";
import { LoadProgress, SelectedFileType } from "../types";
import SelectedFile from "../SelectedFile";
import NoFileSelectedWrapper from "./NoFileSelectedWrapper";
import { crsWhiteList } from "../CRSWhitelist";
import { acceptedProjectFileEndingsWithShapeFilesWithoutZip } from "../UploadProjectFeatures/UploadProjectFeatures";

const kmlFileEnding = ".kml";
const kmzFileEnding = ".kmz";
const acceptedLayerFileEndings = [
  ".geojson",
  ".json",
  ".zip",
  kmlFileEnding,
  kmzFileEnding,
];
export const acceptedLayerFileEndingsWithShapeFiles = [
  ...acceptedLayerFileEndings,
  ...acceptedShapeFileEndings,
];

export const ONE_MEGABYTE_IN_BYTES = 1_000_000;
const HUNDRED_MEGABYTES_IN_BYTES = 100_000_000;
const THREE_HUNDRED_MEGABYTES_IN_BYTES = 3 * HUNDRED_MEGABYTES_IN_BYTES;

export const prepareGISFilesInGDALFriendlyFormat = async (files: File[]) => {
  const zipFiles = await Promise.all(
    files
      .filter((file) => file.name.endsWith(".zip"))
      .map((file) =>
        getAllFilesOfTypeFromZip(
          file,
          acceptedProjectFileEndingsWithShapeFilesWithoutZip,
        ),
      )
      .flat(),
  );
  const zippedKMLFiles = (
    await Promise.all(
      files
        .filter((file) => file.name.endsWith(kmzFileEnding))
        .map((file) => getAllFilesOfTypeFromZip(file, [kmlFileEnding])),
    )
  ).flat();

  const kmlFilesAsGeojson = await Promise.all(
    [...files, ...zippedKMLFiles]
      .filter((file) => file.name.endsWith(kmlFileEnding))
      .map(async (file) => await KMLToGeojsonFile(file))
      .flat(),
  );

  const nonZipKmlFiles = files.filter(
    (file) =>
      !file.name.endsWith(".zip") &&
      !file.name.endsWith(kmlFileEnding) &&
      !file.name.endsWith(kmzFileEnding),
  );
  const allFiles: File[] = nonZipKmlFiles
    .concat(...zipFiles)
    .concat(...kmlFilesAsGeojson);

  const filesTooLarge = allFiles.filter(
    (file) =>
      file.size >
      (getFileTypeFromFileName(file.name) === "zip"
        ? HUNDRED_MEGABYTES_IN_BYTES
        : THREE_HUNDRED_MEGABYTES_IN_BYTES),
  );
  const filesWithinFileSize = allFiles.filter(
    (f) => !filesTooLarge.includes(f),
  );

  const acceptableFilesExceptShapeFiles = filesWithinFileSize.filter((file) => {
    const fileSuffix = getFileTypeFromFileName(file.name);
    return acceptedLayerFileEndings.includes(`.${fileSuffix.toLowerCase()}`);
  });

  const [zippedShapeFiles, invalidShpNames] =
    await getZippedShapeFiles(allFiles);

  const allAcceptableFiles = acceptableFilesExceptShapeFiles.concat(
    ...zippedShapeFiles,
  );

  return { allAcceptableFiles, invalidShpNames, filesTooLarge };
};

class UnsupportedCrsError extends Error {
  name = "UnsupportedCrsError";
}

class NoFeaturesCouldBeParsedError extends Error {
  name = "NoFeaturesCouldBeParsedError";
}

class SizeOfFeaturesTooBigError extends Error {
  name = "SizeOfFeaturesTooBigError";
}

const parseFileAndCreateGzipFile = async (file: File) => {
  const crsCodesPerLayer = await getEPSGForGISFile(file);

  const unsupportedCrsCodes = crsCodesPerLayer.filter(
    (code) =>
      crsWhiteList.find((c) => parseInt(c.code) === code)?.match !== true,
  );

  if (unsupportedCrsCodes.length > 0) {
    throw new UnsupportedCrsError(
      `File '${file.name}' is using unsupported coordinate system(s) EPSG code ${unsupportedCrsCodes.join(", ")}`,
    );
  }

  let result: Awaited<ReturnType<typeof parseFileAndCleanGeoJson>>;
  const parseFileWorker = typedWorker<
    { file: File; accessToken?: string },
    Awaited<ReturnType<typeof parseFileAndCleanGeoJson>>
  >(
    new Worker(new URL("../../../parseFileWorker.ts", import.meta.url), {
      type: "module",
    }),
  );

  try {
    result = await promiseWorker(parseFileWorker, {
      file: file,
      accessToken,
    });
  } finally {
    parseFileWorker.terminate();
  }

  const { cleanedWithAnyProperties, shapefileMissingEncoding, collections } =
    result;

  if (cleanedWithAnyProperties.length === 0) {
    throw new NoFeaturesCouldBeParsedError(
      `No features could be parsed from file ${file.name} after filtering out Vind AI specific features`,
    );
  }

  const fixedObj = {
    ...collections[0],
    features: cleanedWithAnyProperties,
  };
  const fileName = file.name.replace(/\.zip$/g, ".geojson");
  const compressedUint8Array = pako.gzip(
    new TextEncoder().encode(JSON.stringify(fixedObj)),
  );
  const gzipFileToUpload = new File([compressedUint8Array], `${fileName}.gz`);

  if (gzipFileToUpload.size > HUNDRED_MEGABYTES_IN_BYTES) {
    throw new SizeOfFeaturesTooBigError(
      `Size of features too large, maximum 100 megabytes zipped allowed (Is ${
        gzipFileToUpload.size / ONE_MEGABYTE_IN_BYTES
      }mb)`,
    );
  }

  return { gzipFileToUpload, shapefileMissingEncoding };
};

const UploadDataLayer = ({
  initialFiles,
  resetInitialFiles,
  upload,
  onAfterUploadFile,
  onAfterUploadAll,
  onAddSourceFromUrlClick,
  onBackClick,
  onDoneClick,
  maxNumberOfLayers,
}: {
  initialFiles?: File[];
  resetInitialFiles?(): void;
  upload: (file: File) => Promise<CustomLayerAddResponse>;
  onAfterUploadFile?(response: CustomLayerAddResponse, file: File): void;
  onAfterUploadAll?(responses: Array<Awaited<ReturnType<typeof upload>>>): void;
  onAddSourceFromUrlClick?(): void;
  onBackClick?(): void;
  onDoneClick(): void;
  maxNumberOfLayers?: number;
}) => {
  const { error, info } = useToast();
  const [selectedFiles, setSelectedFiles] = useState<SelectedFileType[]>([]);
  const [uploadState, setUploadState] = useState<LoadProgress[]>([]);
  const [validationState, setValidationState] = useState<
    Record<string, boolean>
  >({});

  const setIsValidatingId = useCallback((id: string, isValidating: boolean) => {
    setValidationState((curr) => ({
      ...curr,
      [id]: isValidating,
    }));
  }, []);

  const setUploadStateUsingServerFilename = useCallback(
    (
      serverFileName: string,
      progress: number | undefined,
      message?: string,
      error?: string,
      other?: Partial<LoadProgress>,
      intercomHelpId?: number,
    ) => {
      setUploadState((curr) => {
        return curr.map((currRow) => {
          if (currRow.serverFileName === serverFileName) {
            return {
              ...currRow,
              ...other,
              message,
              progress,
              error,
              intercomHelpId,
            };
          }
          return currRow;
        });
      });
    },
    [],
  );

  const setUnrecoverableValidationError = useCallback(
    (row: SelectedFileType, error: string, intercomHelpId?: number) => {
      setUploadState((curr) => {
        return [
          ...curr,
          {
            id: row.id,
            serverFileName: row.fileName,
            error,
            intercomHelpId,
          },
        ];
      });
    },
    [],
  );

  const parseAndValidateFile = useCallback(
    async (row: SelectedFileType) => {
      setIsValidatingId(row.id, true);

      try {
        const { gzipFileToUpload, shapefileMissingEncoding } =
          await parseFileAndCreateGzipFile(row.file);

        if (shapefileMissingEncoding) {
          error(
            "Shapefile is missing encoding information (no .cpg file). Some characters have been removed.",
            { timeout: 10000 },
          );
        }
        return gzipFileToUpload;
      } catch (err) {
        if (err instanceof UnsupportedCrsError) {
          setUnrecoverableValidationError(row, err.message, 7193914);
        } else if (err instanceof Error) {
          setUnrecoverableValidationError(row, err.message);
        }
      } finally {
        setIsValidatingId(row.id, false);
      }
    },
    [error, setIsValidatingId, setUnrecoverableValidationError],
  );

  const uploadFiles = useCallback(
    async (files: SelectedFileType[]) => {
      const results: Array<Awaited<ReturnType<typeof upload>>> = [];
      for (const file of files) {
        if (!file.zippedFile) {
          continue;
        }

        const tempId = uuid();
        setUploadState((curr) => {
          return [
            ...curr,
            {
              id: file.id,
              serverFileName: tempId,
              progress: 5,
              message: "Uploading",
            },
          ];
        });
        try {
          const result = await upload(file.zippedFile);
          results.push(result);
          setUploadStateUsingServerFilename(tempId, 100, "Done", undefined, {
            serverFileName: result.fileId,
          });

          onAfterUploadFile?.(result, file.zippedFile);
        } catch (error) {
          sendWarning("Something went wrong when uploading custom layer", {
            error,
          });
          console.error(error);
          if (error instanceof Error)
            setUploadStateUsingServerFilename(
              tempId,
              undefined,
              undefined,
              error.message,
            );
        }
      }
      onAfterUploadAll?.(results);
    },
    [
      setUploadStateUsingServerFilename,
      upload,
      onAfterUploadFile,
      onAfterUploadAll,
    ],
  );

  const handleNewFiles = useCallback(
    async (files: File[]) => {
      const { allAcceptableFiles, invalidShpNames, filesTooLarge } =
        await prepareGISFilesInGDALFriendlyFormat(files);

      if (filesTooLarge.length !== 0) {
        error(
          `Some files are too large and have been removed, maximum 100 megabytes zipped / 300 megabytes unzipped allowed\n\n${filesTooLarge.map((f) => `${f.name}: ${Math.round(f.size / ONE_MEGABYTE_IN_BYTES)}mb\n`)}`,
          {
            timeout: 8000,
          },
        );
      }

      if (invalidShpNames.length !== 0) {
        info(
          `file(s) "${invalidShpNames.join(
            ",",
          )}" needs corresponding shp,${neededShpFileSuffixes.join(
            ",",
          )} files to be able to be imported`,
          { timeout: 10000 },
        );
      }

      const newSelectedFiles = allAcceptableFiles.map((file) => ({
        file,
        id: uuid(),
      }));

      if (
        isDefined(maxNumberOfLayers) &&
        newSelectedFiles.length > maxNumberOfLayers
      ) {
        error(
          `You can only upload ${maxNumberOfLayers} layer${maxNumberOfLayers !== 1 ? "s" : ""} at once`,
        );
        return;
      }

      setSelectedFiles(() => {
        if (isDefined(maxNumberOfLayers)) {
          return newSelectedFiles;
        }
        return [...selectedFiles, ...newSelectedFiles];
      });

      const newSelectedFilesWithZippedFiles = (
        await Promise.all(
          newSelectedFiles.map(async (row) => {
            return {
              ...row,
              zippedFile: await parseAndValidateFile(row),
            };
          }),
        )
      ).filter((row) => isDefined(row.zippedFile));
      setSelectedFiles((curr) =>
        dedup([...curr, ...newSelectedFilesWithZippedFiles], (row) => row.id),
      );
      uploadFiles(newSelectedFilesWithZippedFiles);
    },
    [
      maxNumberOfLayers,
      selectedFiles,
      uploadFiles,
      error,
      info,
      parseAndValidateFile,
    ],
  );

  useEffect(() => {
    if (initialFiles && initialFiles.length > 0) {
      handleNewFiles(initialFiles);
      resetInitialFiles?.();
    }
  }, [handleNewFiles, initialFiles, resetInitialFiles]);

  const handleAddSourceFromUrlClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onAddSourceFromUrlClick!();
    },
    [onAddSourceFromUrlClick],
  );

  return (
    <UploadWrapper>
      <DropFileToUpload
        acceptedFileTypes={acceptedLayerFileEndingsWithShapeFiles}
        handleNewFiles={handleNewFiles}
      >
        {onAddSourceFromUrlClick && (
          <Button
            buttonType="secondary"
            text="Add URL"
            size="small"
            onClick={handleAddSourceFromUrlClick}
          />
        )}
      </DropFileToUpload>

      {selectedFiles.length === 0 ? (
        <NoFileSelectedWrapper />
      ) : (
        <div
          style={{ display: "flex", flexDirection: "column", gap: spaceMedium }}
        >
          {selectedFiles.map((row) => {
            const loadProgress = uploadState.find((load) => load.id === row.id);
            const isValidating = validationState[row.id] ?? false;
            return (
              <SelectedFile
                key={row.id}
                fileName={row.file.name}
                fileSize={row?.zippedFile?.size}
                loadProgress={
                  isValidating
                    ? {
                        progress: 0,
                        message: "Validating",
                      }
                    : loadProgress
                }
              />
            );
          })}
        </div>
      )}
      <ButtonWrapper>
        {onBackClick ? (
          <Button
            buttonType="text"
            text="Back"
            icon={<ArrowLeftIcon />}
            onClick={onBackClick}
            style={{
              paddingLeft: 0,
            }}
          />
        ) : (
          <div />
        )}
        <ButtonWrapper>
          <Button text="Close" buttonType="text" onClick={onDoneClick} />
          <Button
            buttonType="primary"
            text="Done"
            onClick={onDoneClick}
            disabled={uploadState.some(
              (state) => !state.error && state.progress !== 100,
            )}
          />
        </ButtonWrapper>
      </ButtonWrapper>
    </UploadWrapper>
  );
};

export default UploadDataLayer;
