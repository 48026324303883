import {
  parkWindRoseLayerId,
  getBeforeLayer,
  turbineWakeSymbolSourceId,
  turbineWakeSymbolLayerId,
} from "components/Mapbox/utils";
import { Feature } from "geojson";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { showMeanSpeedGridAtom } from "state/windStatistics";
import { isDefined } from "utils/predicates";
import {
  ProdId,
  getAnalysis,
  getAnalysisWindStats,
  getTurbines,
  getWindSourceConfiguration,
} from "./state";
import Point from "components/MapFeatures/Point";
import { displayLabelPropertyName } from "@constants/canvas";
import { SymbolLayer, CirclePaint } from "mapbox-gl";
import { zip } from "utils/utils";
import { mapRefAtom } from "state/map";
import {
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";
import { isMultipleSourceWindConfiguration } from "services/windSourceConfigurationService";

const parkWindRoseSourceId = "park-wind-rose-symbol-source";

const turbineWakeSymbolLayer: Omit<SymbolLayer, "id" | "source"> = {
  type: "symbol",
  minzoom: 9,
  layout: {
    "text-field": ["get", "value"],
    "text-offset": [0, 2],
    "text-size": [
      "interpolate",
      ["linear"],
      ["zoom"],
      // zoom is 11 (or less) -> text size will be 9px
      11,
      8,
      // zoom is 14 (or greater) -> text size will be 14px
      14,
      14,
    ],
    "text-anchor": "left",
  },
  filter: ["boolean", ["get", displayLabelPropertyName], true],
};

const windRoseSymbolLayer: Omit<SymbolLayer, "id" | "source"> = {
  type: "symbol",
  minzoom: 9,
  layout: {
    "icon-image": "embassy",
    "icon-size": ["interpolate", ["exponential", 2], ["zoom"], 5, 1, 10, 1],
    "icon-allow-overlap": true,
  },
  paint: {
    "icon-opacity": 0.8,
    "icon-color": "#ff0000",
    "icon-halo-color": "#fff",
  },
  filter: ["boolean", ["get", displayLabelPropertyName], true],
};
const symbolPaint: CirclePaint = {
  "circle-radius": 6,
  "circle-opacity": 0,
};

const Inner = ({
  map,
  productionId,
}: {
  map: mapboxgl.Map;
  productionId: ProdId;
}) => {
  const turbines = useRecoilValue(getTurbines(productionId));
  const analysis = useRecoilValue(getAnalysis(productionId));
  const averageSpeedPerTurbine = analysis?.stats?.averageSpeedPerTurbine;
  const windStats = useRecoilValue(getAnalysisWindStats(productionId));
  const windConfig = useRecoilValue(getWindSourceConfiguration(productionId));

  const dataPointsToShow: Feature[] = useMemo(() => {
    if (isMultipleSourceWindConfiguration(windConfig)) {
      return windConfig.source.data.map((data, index) => {
        return {
          type: "Feature",
          id: `data-point-${index}`,
          properties: {},
          geometry: {
            coordinates: [data.longitude ?? 0, data.latitude ?? 0],
            type: "Point",
          },
        };
      });
    } else {
      return [
        {
          type: "Feature",
          id: "current-wind-rose",
          properties: {},
          geometry: {
            coordinates: [windStats?.longitude ?? 0, windStats?.latitude ?? 0],
            type: "Point",
          },
        },
      ];
    }
  }, [windConfig, windStats]);

  const speedFeatures = useMemo(() => {
    if (!averageSpeedPerTurbine) return [];
    return zip(turbines, averageSpeedPerTurbine)
      .map(([t, speed]) => {
        if (speed === undefined) return undefined;
        return {
          ...t,
          properties: {
            value: `${speed.toFixed(2)} m/s`,
          },
          id: `${t.id}-value-label`,
        };
      })
      .filter(isDefined);
  }, [turbines, averageSpeedPerTurbine]);

  return (
    <>
      <Point
        features={dataPointsToShow}
        symbols={windRoseSymbolLayer}
        sourceId={parkWindRoseSourceId}
        layerId={parkWindRoseLayerId}
        map={map}
        paint={symbolPaint}
        beforeLayer={getBeforeLayer(map, parkWindRoseLayerId)}
      />
      <Point
        features={speedFeatures}
        symbols={turbineWakeSymbolLayer}
        sourceId={turbineWakeSymbolSourceId}
        layerId={turbineWakeSymbolLayerId}
        map={map}
        paint={symbolPaint}
        beforeLayer={getBeforeLayer(map, turbineWakeSymbolLayerId)}
      />
    </>
  );
};

export const MeanSpeedGridLabels = ErrorBoundaryWrapper(
  ({ id }: { id: ProdId }) => {
    const map = useRecoilValue(mapRefAtom);
    const showMeanSpeedGrid = useRecoilValue(showMeanSpeedGridAtom);

    if (!map || !showMeanSpeedGrid) return null;

    return <Inner map={map} productionId={id} />;
  },
  () => null,
  ScreamOnError,
);
