/// <reference types="vite-plugin-svgr/client" />
import React, { useMemo } from "react";
import {
  BathymetryUserUploadedType,
  GeoTiffUserUploadedImageType,
} from "../../services/types";
import { ProjectFeature } from "../../types/feature";
import { scream } from "../../utils/sentry";
import { isPointFeature } from "../../utils/predicates";
import Image from "@icons/image/image.svg?react";
import Point from "@icons/24/Point.svg?react";
import Bathymetry from "@icons/bathymetryanalysis/bathymetryanalysis.svg?react";
import FeatureToCanvas from "../FeatureToCanvas/FeatureToCanvas";

export const ElementsToIcon = ({
  elements,
  fillPolygons,
  style,
  resolution,
  fillParks,
}: {
  elements: ProjectFeature[];
  resolution?: string;
  fillPolygons: boolean;
  fillParks: boolean;
  style?: React.CSSProperties;
}) => {
  if (
    elements.some((element) =>
      [GeoTiffUserUploadedImageType].includes(element?.properties?.type ?? ""),
    )
  )
    return <Image style={style} />;
  if (
    elements.some((element) =>
      [BathymetryUserUploadedType].includes(element?.properties?.type ?? ""),
    )
  )
    return <Bathymetry style={style} />;

  return elements.every(isPointFeature) ? (
    <Point style={{ width: "2rem", height: "2rem", ...style }} />
  ) : (
    <FeatureToCanvas
      features={elements}
      fillPolygons={fillPolygons}
      fillParks={fillParks}
      style={style}
      resolution={resolution ?? "100"}
    />
  );
};

export const ElementToIcon = ({
  element,
  fillPolygons,
  fillParks,
  style,
}: {
  element: ProjectFeature;
  fillPolygons: boolean;
  fillParks: boolean;
  style?: React.CSSProperties;
}) => {
  const elementArr = useMemo(() => [element], [element]);
  // NOTE: Try to figure out  https://vind-technologies.sentry.io/issues/3966592447
  if (!("geometry" in element))
    scream("ProjectFeature doesn't have geometry: ", { element });

  return (
    <ElementsToIcon
      elements={elementArr}
      fillPolygons={fillPolygons}
      style={style}
      fillParks={fillParks}
    />
  );
};
