import drawLineStringHelp from "./drawLineStringHelp.mp4";
import CloseableHint from "./CloseableHint";

export const drawLineStringHelpHintType = "drawLineStringHelpHintType";

const DrawLineStringHelp = ({
  closeCallback,
  gotItCallback,
}: {
  closeCallback: () => void;
  gotItCallback?: () => void;
}) => {
  return (
    <CloseableHint
      title={"Draw line"}
      videosrc={drawLineStringHelp}
      closeCallback={closeCallback}
      gotItCallback={gotItCallback}
      timeToDissapearMS={10000}
    >
      {`Draw a line by left clicking on the map. Finish by pressing "Enter" or cancel with "Escape"`}
    </CloseableHint>
  );
};

export default DrawLineStringHelp;
