import { useEffect } from "react";
import mapboxgl, { SymbolLayer } from "mapbox-gl";
import {
  portfolioDotClusterLayerId,
  portfolioDotClusterSymbolLayerId,
  portfolioDotLayerId,
  portfolioDotSourceId,
  portfolioDotSymbolLayerId,
} from "components/Mapbox/constants";
import { addLayer } from "components/Mapbox/utils";
import { dedup } from "utils/utils";
import { colors } from "styles/colors";
import { PortfolioFeature } from "components/Organisation/Portfolio/Portfolio";

const portfolioDotPaint: mapboxgl.CirclePaint = {
  "circle-color": "#008179",
  "circle-opacity": 1,
  "circle-radius": 10,
  "circle-stroke-color": "#ffffff",
  "circle-stroke-width": [
    "case",
    ["boolean", ["feature-state", "selected"], false],
    2.0,
    ["boolean", ["feature-state", "hover"], false],
    1.0,
    0.0,
  ],
};

const portfolioDotSymbolLayer: SymbolLayer = {
  id: portfolioDotSymbolLayerId,
  source: portfolioDotSourceId,
  type: "symbol",
  minzoom: 2,
  layout: {
    "symbol-placement": "point",
    "symbol-z-order": "viewport-y",
    "symbol-spacing": 300,
    "text-field": "{name}",
    "text-size": 14,
    "text-allow-overlap": false,
    "text-keep-upright": true,
    "text-variable-anchor": ["left", "top", "right"],
    "text-offset": [1, 0],
  },
  paint: {
    "text-opacity": 1,
    "text-halo-color": [
      "case",
      ["boolean", ["feature-state", "selected"], false],
      "rgb(255,255,255)",
      ["boolean", ["feature-state", "hover"], false],
      "rgb(255,255,255)",
      "rgba(255,255,255, 0.9)",
    ],
    "text-halo-width": [
      "case",
      ["boolean", ["feature-state", "selected"], false],
      2.0,
      1.0,
    ],
  },
};

const portfolioDotClusterPaint: mapboxgl.CirclePaint = {
  "circle-color": colors.blue50,
  "circle-opacity": 1,
  "circle-radius": 20,
};

const portfolioDotClusterSymbolLayer: SymbolLayer = {
  id: portfolioDotClusterSymbolLayerId,
  source: portfolioDotSourceId,
  type: "symbol",
  minzoom: 0,
  filter: ["has", "point_count"],
  layout: {
    "symbol-placement": "point",
    "symbol-z-order": "viewport-y",
    "text-field": "{point_count}",
    "text-size": 14,
    "text-anchor": "center",
  },
};

const RenderPortfolioParkDots = ({
  parks: features,
  map,
  onFeatureClick,
}: {
  parks: PortfolioFeature[];
  map: mapboxgl.Map;
  onFeatureClick: (feature: PortfolioFeature | undefined) => void;
}) => {
  useEffect(() => {
    map.addSource(portfolioDotSourceId, {
      type: "geojson",
      promoteId: "id",
      cluster: true,
      clusterMaxZoom: 8,
      clusterRadius: 30,
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });
  }, [map]);

  useEffect(() => {
    addLayer(map, {
      id: portfolioDotLayerId,
      type: "circle",
      source: portfolioDotSourceId,
      paint: portfolioDotPaint,
      filter: ["!has", "point_count"],
    });

    addLayer(map, {
      id: portfolioDotClusterLayerId,
      type: "circle",
      source: portfolioDotSourceId,
      paint: portfolioDotClusterPaint,
      filter: ["has", "point_count"],
    });

    map.addLayer(portfolioDotClusterSymbolLayer, "continent-label");
    map.addLayer(portfolioDotSymbolLayer, portfolioDotClusterSymbolLayer.id);
  }, [map]);

  useEffect(() => {
    const source = map.getSource(portfolioDotSourceId);
    if (source?.type !== "geojson") {
      return;
    }

    source.setData({
      type: "FeatureCollection",
      features,
    });

    map.on("click", (e) => {
      for (const park of features) {
        e.target.setFeatureState(
          {
            id: park.properties.id,
            source: portfolioDotSourceId,
          },
          {
            selected: false,
          },
        );
      }

      const clustersOnPoint = dedup(
        map.queryRenderedFeatures(e.point, {
          layers: [portfolioDotClusterLayerId],
        }),
        (cluster) => cluster.properties?.cluster_id,
      );

      if (clustersOnPoint.length > 0) {
        const source = map.getSource(portfolioDotSourceId);
        const clusterId = clustersOnPoint[0].properties?.cluster_id;
        const coordinates =
          "coordinates" in clustersOnPoint[0].geometry
            ? (clustersOnPoint[0].geometry.coordinates as mapboxgl.LngLatLike)
            : undefined;

        if (
          !source ||
          source.type !== "geojson" ||
          !clusterId ||
          !coordinates
        ) {
          return;
        }

        source.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          map.easeTo({
            center: coordinates,
            zoom: zoom,
          });
        });
        return;
      }

      const featuresOnPoint = dedup(
        map.queryRenderedFeatures(e.point, {
          layers: [portfolioDotLayerId, portfolioDotSymbolLayerId],
        }),
        (feature) => feature.id,
      );

      if (featuresOnPoint.length === 0) {
        onFeatureClick(undefined);
        return;
      }
      e.target.setFeatureState(featuresOnPoint[0], { selected: true });
      const projectFeat = features.find(
        (feature) =>
          feature.properties.id === featuresOnPoint[0].properties?.id,
      );

      if (projectFeat) {
        onFeatureClick(projectFeat);
      }
    });
  }, [map, features, onFeatureClick]);

  return null;
};

export default RenderPortfolioParkDots;
