import { MoveNodeConsequences } from "components/Projects/useExplainNodeMove";
import { atom } from "recoil";

export const MoveNodeModalAtom = atom<
  | undefined
  | {
      state: "searching";
      nodeId: string;
      nodeType: string;
      parentId: string;
    }
  | {
      state: "action-needed";
      consequences: MoveNodeConsequences;
      confirm: () => void;
      cancel: () => void;
      nodeId: string;
      nodeType: string;
      parentId: string;
    }
>({
  key: "MoveNodeModalAtom",
  default: undefined,
});
