/// <reference types="vite-plugin-svgr/client" />
import "nouislider/dist/nouislider.css";
import { useRecoilState } from "recoil";
import { costLayerFilterAtom, costLayerRangeAtom } from "../../state/layer";
import InformationIcon from "@icons/24/Information.svg?react";
import { RangeSlider } from "../General/Slider";
import Tooltip from "../General/Tooltip";
import { Label } from "../General/Form";

const CostSlider = () => {
  // filter is shown range
  const [filter, setFilter] = useRecoilState(costLayerFilterAtom);
  // range is colored range
  const [range, setRange] = useRecoilState(costLayerRangeAtom);

  return (
    <>
      <Label>
        <Tooltip text="The cost range that is colored. The lowest number is colored green and the highest is colored red.">
          <p>Color scale range (€/MWh) </p>
          <InformationIcon style={{ height: "1.3rem" }} />
        </Tooltip>
        <RangeSlider
          min={0}
          max={60}
          values={range}
          onChange={setRange}
          inside
          labels
        />
      </Label>

      <Label>
        <Tooltip text="The part of the range that is shown. This does not influence the colors used.">
          <p>Filter (€/MWh)</p>
          <InformationIcon style={{ height: "1.3rem" }} />
        </Tooltip>
        <RangeSlider
          min={range[0]}
          max={range[1]}
          values={filter}
          onChange={setFilter}
          inside
          labels
        />
      </Label>
    </>
  );
};

export default CostSlider;
