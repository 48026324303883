import { useRecoilCallback } from "recoil";
import { putProjectFollow } from "../../services/projectDataAPIService";
import { followProjectsAtom } from "../../state/project";

export const useFollowProjectCrud = () => {
  const put = useRecoilCallback(
    ({ set }) =>
      async (nodeId: string, follow: boolean) => {
        set(followProjectsAtom, (cur) => {
          const filtered = cur.filter((c) => c.nodeId !== nodeId);
          return [...filtered, { nodeId, follow }];
        });
        const res = await putProjectFollow(nodeId, {
          follow,
        });

        return res;
      },
    [],
  );

  return { put };
};
