import { Column } from "components/General/Layout";
import { ProjectPageTitleBar } from "components/Organisation/ProjectPageTitleBar";

export default function NoOrganisationPage() {
  return (
    <Column
      style={{
        height: "100vh",
        gap: 0,
      }}
    >
      <ProjectPageTitleBar />
      <Column style={{ padding: "1.6rem" }}>
        <h1>No access</h1>
        <p>
          You don't have access to any organisations. Please contact your
          organisation administrator and ask for an invite.
        </p>
      </Column>
    </Column>
  );
}
