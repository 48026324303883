/// <reference types="vite-plugin-svgr/client" />
import React from "react";
import { IconREMSize, typography } from "../../../../styles/typography";
import ArrowLeftIcon from "@icons/24/ArrowLeft.svg?react";
import LayersIcon from "@icons/24/Layers.svg?react";
import Elements from "@icons/24/SideBarIcons/Elements.svg?react";
import Button from "../../../General/Button";
import { ButtonWrapper, TextNoMargin, UploadWrapper } from "../shared";
import { FileTypeHeader, FileTypeOption } from "./FileTypeSelectionStep";
import { UploadFileType } from "./types";

const SelectUploadTypeForDroppedFiles = ({
  droppedFiles,
  setSelectedUploadFileType,
  onBackClick,
}: {
  droppedFiles: File[];
  setSelectedUploadFileType(fileType: UploadFileType): void;
  onBackClick(): void;
}) => {
  const msg =
    droppedFiles.length !== 1
      ? `Select upload type for ${droppedFiles.length} dropped files`
      : `Select upload type for ${droppedFiles.length} dropped file`;
  return (
    <UploadWrapper>
      <p style={{ ...typography.semiBold, margin: "0" }}>{msg}</p>
      <FileTypeOption
        selected={false}
        onClick={() =>
          setSelectedUploadFileType(UploadFileType.PROJECT_FEATURE)
        }
      >
        <FileTypeHeader>
          <IconREMSize height={1.5} width={1.5}>
            <Elements />
          </IconREMSize>
          <h4 style={{ margin: 0 }}>Elements</h4>
        </FileTypeHeader>
        <TextNoMargin>Editable elements in your project</TextNoMargin>
      </FileTypeOption>
      <FileTypeOption
        selected={false}
        onClick={() => setSelectedUploadFileType(UploadFileType.DATA_LAYER)}
      >
        <FileTypeHeader>
          <IconREMSize height={1.5} width={1.5}>
            <LayersIcon />
          </IconREMSize>
          <h4 style={{ margin: 0 }}>Data layers</h4>
        </FileTypeHeader>
        <TextNoMargin>Read-only geodata (large data)</TextNoMargin>
      </FileTypeOption>
      <ButtonWrapper>
        <Button
          buttonType="text"
          text="Back"
          icon={<ArrowLeftIcon />}
          onClick={onBackClick}
          style={{
            paddingLeft: 0,
          }}
        />
      </ButtonWrapper>
    </UploadWrapper>
  );
};

export default SelectUploadTypeForDroppedFiles;
