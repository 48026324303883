import mapboxgl from "mapbox-gl";
import { useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { getTurbinesSelectorFamily } from "../state/layout";
import { mapRefAtom } from "../state/map";
import { showNoiseAtom, noiseLevelSelectorFamily } from "../state/turbines";
import { parkIdSelector } from "../state/pathParams";
import { TurbineNoiseSettings } from "../types/turbines";
import { LAYER_DEBUG_PRINT } from "../state/debug";
import { getBeforeLayer } from "../components/Mapbox/utils";
import { noiseLayerId } from "components/Mapbox/constants";
import { scream } from "../utils/sentry";

const alphaPerCelcius = {
  10: [0.1, 0.4, 1, 1.9, 3.7, 9.7, 32.8, 117],
  20: [0.1, 0.3, 1.1, 2.8, 5, 9, 22.9, 76.6],
  30: [0.1, 0.3, 1, 3.1, 7.4, 12.7, 23.1, 59.3],
}; // https://puc.sd.gov/commission/dockets/electric/2019/el19-003/KMExhibit9.pdf, table 2
const A_weights = [-26.2, -16.1, -8.6, -3.2, 0, 1.2, 1.0, -1.1]; // https://dsp.stackexchange.com/questions/68767/a-weighted-digital-filter-for-1-3rd-octave-band-analysis

class NoiseLayer {
  id: string;
  type: string;
  source: string;
  points: number[];
  scale: number;
  opacity: number;
  db: number;
  red: number;
  alpha: number[];
  yellow: number;
  turbineLocations: number[];
  program: WebGLProgram | undefined;
  aPos: GLint | undefined;
  buffer: WebGLBuffer | undefined;
  scaleLocation: WebGLUniformLocation | undefined | null;
  opacityLocation: WebGLUniformLocation | undefined | null;
  dbLocation: WebGLUniformLocation | undefined | null;
  redLocation: WebGLUniformLocation | undefined | null;
  yellowLocation: WebGLUniformLocation | undefined | null;
  numberLocation: WebGLUniformLocation | undefined | null;
  turbinesLocation: WebGLUniformLocation | undefined | null;
  constructor(
    points: number[],
    scale: number,
    turbineLocations: number[],
    noiseLevel: TurbineNoiseSettings,
  ) {
    this.id = noiseLayerId;
    this.type = "custom";
    this.source = "turbines";
    this.points = points;
    this.scale = scale;
    this.opacity = noiseLevel.opacity;
    this.db = noiseLevel.source;
    this.alpha = alphaPerCelcius[noiseLevel.temperature];
    this.red = noiseLevel.red;
    this.yellow = noiseLevel.yellow;
    this.turbineLocations = turbineLocations;
  }
  onAdd(map: mapboxgl.Map, gl: WebGLRenderingContext) {
    LAYER_DEBUG_PRINT && console.log("NoiseLayer.onAdd");
    // create GLSL source for vertex shader
    const vertexSource = `
              precision highp float; 
              uniform mat4 u_matrix;
              attribute vec2 a_pos;
              varying vec2 cc;
              void main() {
                  cc = a_pos;
                  gl_Position = u_matrix * vec4(a_pos, 0.0, 1.0);
              }`;

    // create GLSL source for fragment shader
    const fragmentSource = `
              // Useful for implementation: https://github.com/michkowalczuk/IsoNoise/blob/master/src/calc.py

              precision highp float;
              const int max_turbines = 1000;
              const float As = -1.5;
              const float Ar = -1.5;

              varying vec2 cc;
              uniform int u_number_of_turbines;
              uniform vec2 u_turbines[max_turbines];
              uniform float u_scale;
              uniform float u_opacity;
              uniform float u_db;
              uniform float u_red;
              uniform float u_yellow;
              uniform float u_alpha[8];
              uniform float u_A[8];

              float a = 1. / log(10.);

              float log10(float x) {
                return a * log(x);
              }

              float Noise(vec2 turbine, vec2 point) {
                vec2 vector = (point - turbine) / u_scale;
                float distance = length(vector);
                float geometrical_divergence = 20. * log10(distance) + 11.;

                if (distance > 50000.) {
                  return 0.;
                }
                
                float octave_sum = 0.;
                float atmospheric_absorption = 0.;
                float b = u_db - geometrical_divergence - As - Ar;
                for(int i=0;i<8;i++) {
                  atmospheric_absorption = u_alpha[i] * distance / 1000.;
                  float total = b - atmospheric_absorption + u_A[i];
                  octave_sum += pow(10., 0.1 * total);
                }
                return octave_sum;
              }

              void main() {
                float noise = 0.;
                for(int i=0;i<max_turbines;i++) {
                  noise += Noise(u_turbines[i], cc);
                  if (i >= u_number_of_turbines) break;
                }
                float total = 10.0 * log10(noise);
                bool red_zone = u_red < total;
                bool yellow_zone = u_yellow < total;

                vec3 color = vec3(0., 0., 0.);
                float alpha = 0.;
                if ( red_zone ) {
                  color = vec3(1., 0., 0.);
                  alpha = u_opacity;
                } else if ( yellow_zone ) {
                  color = vec3(1.,1.,0.);
                  alpha = u_opacity;
                }
                gl_FragColor = vec4(color, alpha);
              }`;

    // create a vertex shader
    const vertexShader = gl.createShader(gl.VERTEX_SHADER);
    if (!vertexShader) throw scream("noise: failed to create vertexShader");
    gl.shaderSource(vertexShader, vertexSource);
    gl.compileShader(vertexShader);

    // create a fragment shader
    const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
    if (!fragmentShader) throw scream("noise: failed to create fragmentShader");
    gl.shaderSource(fragmentShader, fragmentSource);
    gl.compileShader(fragmentShader);

    // link the two shaders into a WebGL program
    const program = gl.createProgram();
    if (!program) throw scream("noise: failed to create program");
    this.program = program;
    gl.attachShader(this.program, vertexShader);
    gl.attachShader(this.program, fragmentShader);
    gl.linkProgram(this.program);

    this.aPos = gl.getAttribLocation(this.program, "a_pos");
    // create and initialize a WebGLBuffer to store vertex and color data
    const buffer = gl.createBuffer();
    if (!buffer) throw scream("noise: failed to create buffer");
    this.buffer = buffer;
    gl.bindBuffer(gl.ARRAY_BUFFER, this.buffer);
    gl.bufferData(
      gl.ARRAY_BUFFER,
      new Float32Array(this.points),
      gl.STATIC_DRAW,
    );

    this.scaleLocation = gl.getUniformLocation(this.program, "u_scale");
    this.opacityLocation = gl.getUniformLocation(this.program, "u_opacity");
    this.dbLocation = gl.getUniformLocation(this.program, "u_db");
    this.redLocation = gl.getUniformLocation(this.program, "u_red");
    this.yellowLocation = gl.getUniformLocation(this.program, "u_yellow");
    this.numberLocation = gl.getUniformLocation(
      this.program,
      "u_number_of_turbines",
    );
    this.turbinesLocation = gl.getUniformLocation(this.program, "u_turbines");
  }
  render(gl: WebGLRenderingContext, matrix: number[]) {
    LAYER_DEBUG_PRINT && console.time("NoiseLayer.render");
    if (
      !this.program ||
      !this.scaleLocation ||
      !this.opacityLocation ||
      !this.dbLocation ||
      !this.redLocation ||
      !this.yellowLocation ||
      !this.numberLocation ||
      !this.turbinesLocation ||
      !this.buffer ||
      this.aPos === undefined ||
      !this.red ||
      !this.db ||
      !this.yellow
    )
      return;

    gl.useProgram(this.program);

    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

    gl.uniformMatrix4fv(
      gl.getUniformLocation(this.program, "u_matrix"),
      false,
      matrix,
    );

    gl.uniform1fv(gl.getUniformLocation(this.program, "u_alpha"), this.alpha);
    gl.uniform1fv(gl.getUniformLocation(this.program, "u_A"), A_weights);
    gl.uniform1f(this.scaleLocation, this.scale);
    gl.uniform1f(this.opacityLocation, this.opacity);
    gl.uniform1f(this.dbLocation, this.db);
    gl.uniform1f(this.redLocation, this.red);
    gl.uniform1f(this.yellowLocation, this.yellow);
    gl.uniform1i(this.numberLocation, this.turbineLocations.length);
    gl.uniform2fv(this.turbinesLocation, this.turbineLocations);

    gl.bindBuffer(gl.ARRAY_BUFFER, this.buffer);
    gl.enableVertexAttribArray(this.aPos);
    gl.vertexAttribPointer(this.aPos, 2, gl.FLOAT, false, 0, 0);

    gl.drawArrays(gl.TRIANGLE_FAN, 0, 4);
    LAYER_DEBUG_PRINT && console.timeEnd("NoiseLayer.render");
  }
}

const Noise = () => {
  const showNoise = useRecoilValue(showNoiseAtom);
  const parkId = useRecoilValue(parkIdSelector);
  if (!showNoise || !parkId) return null;
  return <NoiseActive parkId={parkId} />;
};

const NoiseActive = ({ parkId }: { parkId: string }) => {
  const features = useRecoilValue(getTurbinesSelectorFamily({ parkId }));

  const noiseLevel = useRecoilValue(noiseLevelSelectorFamily({ parkId }));
  const map = useRecoilValue(mapRefAtom);

  const noiseLayer = useMemo(() => {
    const turbineLonlats = features.map((f) => f.geometry.coordinates);
    if (turbineLonlats.length === 0) return null;
    const turbinePoints = turbineLonlats.map((v) =>
      mapboxgl.MercatorCoordinate.fromLngLat({
        lng: v[0],
        lat: v[1],
      }),
    );
    const longitudes = turbineLonlats.map((c) => c[0]);
    const latitudes = turbineLonlats.map((c) => c[1]);

    const buffer = 0.5;
    const lonMin = Math.min(...longitudes);
    const latMin = Math.min(...latitudes);
    const lonMax = Math.max(...longitudes);
    const latMax = Math.max(...latitudes);
    const bbox = [
      lonMin - buffer,
      latMin - buffer,
      lonMax + buffer,
      latMax + buffer,
    ];
    const centerCoord = mapboxgl.MercatorCoordinate.fromLngLat({
      lng: (lonMin + lonMax) / 2,
      lat: (latMin + latMax) / 2,
    });
    const lowerLeft = mapboxgl.MercatorCoordinate.fromLngLat({
      lng: bbox[0],
      lat: bbox[1],
    });
    const lowerRight = mapboxgl.MercatorCoordinate.fromLngLat({
      lng: bbox[2],
      lat: bbox[1],
    });
    const upperRight = mapboxgl.MercatorCoordinate.fromLngLat({
      lng: bbox[2],
      lat: bbox[3],
    });
    const upperLeft = mapboxgl.MercatorCoordinate.fromLngLat({
      lng: bbox[0],
      lat: bbox[3],
    });

    const scale = centerCoord.meterInMercatorCoordinateUnits();

    const turbineLocations = turbinePoints.flatMap((v) => [v.x, v.y]);

    const points = [
      lowerLeft.x,
      lowerLeft.y,
      lowerRight.x,
      lowerRight.y,
      upperRight.x,
      upperRight.y,
      upperLeft.x,
      upperLeft.y,
    ];
    return new NoiseLayer(points, scale, turbineLocations, noiseLevel);
  }, [features, noiseLevel]);

  useEffect(() => {
    if (!map || !noiseLayer) return;
    noiseLayer.db = noiseLevel.source;
    noiseLayer.red = noiseLevel.red;
    noiseLayer.yellow = noiseLevel.yellow;
    noiseLayer.opacity = noiseLevel.opacity;
    noiseLayer.alpha = alphaPerCelcius[noiseLevel.temperature];
  }, [noiseLevel, map, noiseLayer]);

  useEffect(() => {
    if (!map || !noiseLayer) return;
    map.addLayer(noiseLayer as any, getBeforeLayer(map, noiseLayerId));

    return () => {
      map.removeLayer(noiseLayer.id);
    };
  }, [map, noiseLayer]);

  return null;
};

export default Noise;
