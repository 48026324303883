import { useRef, useState, useCallback, useEffect } from "react";
import styled from "styled-components";

const isScrolledToBottom = (target: HTMLElement) => {
  const totalScrolledHeight = Math.ceil(target.scrollTop + target.clientHeight);
  return totalScrolledHeight >= target.scrollHeight - 2;
};
const isScrolledToTop = (target: HTMLElement) => {
  return target.scrollTop === 0;
};

const isScrolledToLeft = (target: HTMLElement) => {
  return target.scrollLeft === 0;
};

export function useShowScrollShadow<T extends HTMLDivElement>(
  autoSetBottomShadow = false,
) {
  const scrollBodyRef = useRef<T>(null);
  const [showBottomShadow, setShowBottomShadow] = useState<boolean>(false);
  const [showTopShadow, setShowTopShadow] = useState<boolean>(false);
  const [showRightShadow, setShowRightShadow] = useState<boolean>(false);

  const [count, setCount] = useState(0);

  const forceCheck = useCallback(() => {
    setCount((cur) => cur + 1);
  }, []);

  useEffect(() => {
    if (!scrollBodyRef.current) {
      return;
    }

    const onScroll = (e: Event) => {
      const inBottom = isScrolledToBottom(e.target as HTMLDivElement);
      setShowBottomShadow(!inBottom);

      const inTop = isScrolledToTop(e.target as HTMLDivElement);
      setShowTopShadow(!inTop);

      const inLeft = isScrolledToLeft(e.target as HTMLDivElement);
      setShowRightShadow(!inLeft);
    };

    scrollBodyRef.current.addEventListener("scroll", onScroll);
    const scrollBodyRefVal = scrollBodyRef.current;
    return () => {
      scrollBodyRefVal.removeEventListener("scroll", onScroll);
    };
  }, [setShowBottomShadow]);

  useEffect(() => {
    if (!scrollBodyRef.current) {
      return;
    }
    const inBottom = isScrolledToBottom(scrollBodyRef.current);
    setShowBottomShadow(!inBottom);

    const inTop = isScrolledToTop(scrollBodyRef.current);
    setShowTopShadow(!inTop);

    const inLeft = isScrolledToLeft(scrollBodyRef.current);
    setShowRightShadow(!inLeft);

    // We want to run effect when scrollBodyRef changes, disable eslint check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollBodyRef.current, count]);

  useEffect(() => {
    if (!scrollBodyRef.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(forceCheck);
    resizeObserver.observe(scrollBodyRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [forceCheck]);

  useEffect(() => {
    if (!scrollBodyRef.current || !autoSetBottomShadow) {
      return;
    }

    if (showBottomShadow) {
      scrollBodyRef.current.style.boxShadow =
        "inset 0 -6px 12px -4px rgba(51, 51, 51, 0.08)";
    } else {
      scrollBodyRef.current.style.boxShadow = "unset";
    }
  }, [autoSetBottomShadow, showBottomShadow]);

  return {
    scrollBodyRef,
    showBottomShadow,
    showTopShadow,
    showRightShadow,
    forceCheck,
  };
}

export const ScrollBody = styled.div<{
  noScroll?: boolean;
  gap?: string;
  showBottomShadow?: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: ${({ noScroll }) => (noScroll ? "hidden" : "auto")};
  gap: ${({ gap }) => gap ?? "2rem"};
  ${({ showBottomShadow }) =>
    showBottomShadow &&
    `
    box-shadow: inset 0 -6px 12px -4px rgba(51, 51, 51, 0.08);
    `}
`;
