import { atom } from "recoil";
import { z } from "zod";
import {
  _SimpleTurbineType,
  DEFAULT_TURBINES,
  SimpleTurbineType,
} from "../../types/turbines";
import { _Feature, _FeatureCollection } from "../../utils/geojson/geojson";
import { _ParkFeature } from "../../types/feature";

const _SelectedCell = z.object({
  polygonId: z.number(),
  feature: _ParkFeature,
  depth: z.number(),
  meanSpeed: z.number(),
  shoreDist: z.number(),
  aep: z.number(),
  lcoe: z.number(),
});

export type SelectedCell = z.infer<typeof _SelectedCell>;

const _SelectedCandidate = z
  .object({
    hull: _Feature,
    collection: z.array(_SelectedCell),
    lcoe: z.number(),
    id: z.string(),
  })
  .optional();
export type SelectedCandidate = z.infer<typeof _SelectedCandidate>;
export const selectedCandidateState = atom<SelectedCandidate>({
  key: "selectedCandidateState",
  default: undefined,
});

const _CandidateId = z.string();
const _ParkId = z.string();
type CandidateID = z.infer<typeof _CandidateId>;
type ParkID = z.infer<typeof _ParkId>;
export const createdCandidateIDsState = atom<Record<CandidateID, ParkID>>({
  key: "createdCandidateIDs",
  default: {},
});

const _SiteLocatorProblems = z.array(
  z.object({
    candidates: z.array(_SelectedCandidate),
  }),
);
type SiteLocatorProblems = z.infer<typeof _SiteLocatorProblems>;
export type SiteLocatorProblem = SiteLocatorProblems[number];
export const siteLocatorProblemsAtom = atom<SiteLocatorProblems>({
  key: "siteLocatorProblemsAtom",
  default: [],
});

const _SiteLocatorProblemMeta = z.object({
  maxParkCapacity: z.number(),
  turbinePower: z.number(),
  turbineType: _SimpleTurbineType,
  density: z.number(),
  lcoeSlider: z.object({ min: z.number(), max: z.number() }),
  natura2000filter: z.boolean(),
});
export type SiteLocatorProblemMeta = z.infer<typeof _SiteLocatorProblemMeta>;

const _SiteLocatorProblemsMeta = z.array(_SiteLocatorProblemMeta);
type SiteLocatorProblemsMeta = z.infer<typeof _SiteLocatorProblemsMeta>;
export const siteLocatorProblemsMetaAtom = atom<SiteLocatorProblemsMeta>({
  key: "siteLocatorProblemsMetaAtom",
  default: [],
});

const _LCOEParams = z.object({
  turbines: z.number(),
  fixedFoundationsConstant: z.number(),
  fixedFoundationsMultiplier: z.number(),
  floatingFoundationsConstant: z.number(),
  floatingFoundationsMultiplier: z.number(),
  exportCableMultiplier: z.number(),
  opex: z.number(),
  devex: z.number(),
  decom: z.number(),
  discountRate: z.number(),
  lifetime: z.number(),
});

export type LCOEParams = z.infer<typeof _LCOEParams>;

const _SiteLocatorSettings = z.object({
  maxParkCapacity: z.number(),
  density: z.number(),
  natura2000filter: z.boolean(),
  hull: z.union([z.literal("convex"), z.literal("concave")]),
  layers: z.object({
    shoreDistance: z.object({ alpha: z.number() }),
    depth: z.object({ alpha: z.number() }),
    meanSpeed: z.object({ alpha: z.number() }),
    aep: z.object({ alpha: z.number() }),
    lcoe: z.object({ alpha: z.number() }),
  }),
  generation: z.object({
    maxIter: z.number(),
    maxComplexity: z.number(),
    maxSolutions: z.number(),
  }),
  lcoeParams: _LCOEParams,
  turbineType: _SimpleTurbineType,
});

export type SiteLocatorSettings = z.infer<typeof _SiteLocatorSettings>;

export const siteLocatorSettingState = atom<SiteLocatorSettings>({
  key: "siteLocatorSettingState",
  default: {
    maxParkCapacity: 300,
    density: 5,
    natura2000filter: false,
    hull: "convex",
    layers: {
      shoreDistance: { alpha: 0.5 },
      depth: { alpha: 0.5 },
      meanSpeed: { alpha: 0.5 },
      aep: { alpha: 0.5 },
      lcoe: { alpha: 0.5 },
    },
    generation: {
      maxIter: 30,
      maxComplexity: 400000,
      maxSolutions: 5,
    },
    lcoeParams: {
      turbines: 1000,
      fixedFoundationsConstant: 150,
      fixedFoundationsMultiplier: 10.5,
      floatingFoundationsConstant: 800,
      floatingFoundationsMultiplier: 0,
      exportCableMultiplier: 2.8,
      opex: 50,
      devex: 140,
      decom: 330,
      discountRate: 0.05,
      lifetime: 25,
    },
    turbineType: DEFAULT_TURBINES[0] as SimpleTurbineType,
  },
});

const _SelectedPolygonIdsAtom = z.array(z.number());
type SelectedPolygonIdsAtom = z.infer<typeof _SelectedPolygonIdsAtom>;
export const selectedPolygonIdsAtom = atom<SelectedPolygonIdsAtom>({
  key: "selectedPolygonIdsAtom",
  default: [],
});

const _CandidatesState = z.array(
  z.object({
    polygonId: z.number().optional(),
    feature: _ParkFeature,
    depth: z.number(),
    meanSpeed: z.number(),
    shoreDist: z.number(),
    aep: z.number(),
    lcoe: z.number(),
  }),
);

export type CandidatesState = z.infer<typeof _CandidatesState>;
export const candidatesState = atom<CandidatesState>({
  key: "candidatesState",
  default: [],
});

const _LCOESliderState = z.object({
  min: z.number(),
  max: z.number(),
});
type LCOESliderState = z.infer<typeof _LCOESliderState>;
export const lcoeSliderState = atom<LCOESliderState>({
  key: "lcoeSliderState",
  default: { min: 0, max: 0 },
});

const _GridStatistics = z
  .object({
    depthMin: z.number(),
    depthMax: z.number(),
    distMin: z.number(),
    distMax: z.number(),
    windMin: z.number(),
    windMax: z.number(),
    capacityMin: z.number(),
    capacityMax: z.number(),
    lcoeMin: z.number(),
    lcoeMax: z.number(),
  })
  .optional();
export type GridStatistics = z.infer<typeof _GridStatistics>;
export const gridStatisticsState = atom<GridStatistics>({
  key: "gridStatisticsState",
  default: undefined,
});

const _ComputingSitesState = z.boolean();
type ComputingSitesState = z.infer<typeof _ComputingSitesState>;
export const computingSitesState = atom<ComputingSitesState>({
  key: "computingSitesState",
  default: false,
});

export const showDepthLayerAtom = atom<boolean>({
  key: "showDepthLayerAtom",
  default: false,
});

export const showDistLayerAtom = atom<boolean>({
  key: "showDistLayerAtom",
  default: false,
});

export const showMeanSpeedAtom = atom<boolean>({
  key: "showMeanSpeedAtom",
  default: false,
});

export const showAEPLayerAtom = atom<boolean>({
  key: "showAEPLayerAtom",
  default: false,
});

export const showLCOELayerAtom = atom<boolean>({
  key: "showLCOELayerAtom",
  default: true,
});

const _CandidateGrid = _FeatureCollection.optional();
export type CandidateGrid = z.infer<typeof _CandidateGrid>;
export const candidateGridAtom = atom<CandidateGrid | undefined>({
  key: "candidateGridAtom",
  default: undefined,
  dangerouslyAllowMutability: true,
});

const _LastParkId = z.string().optional();
type LastParkId = z.infer<typeof _LastParkId>;
export const lastParkIdState = atom<LastParkId>({
  key: "lastParkId",
  default: undefined,
});

export const openSiteLocatorListProblem = atom<number | undefined>({
  key: "openSiteLocatorListProblem",
  default: undefined,
});

export const openSiteLocatorListEntry = atom<number | undefined>({
  key: "openSiteLocatorListEntry",
  default: undefined,
});

export const openLCOESubmenuAtom = atom<"lcoe" | undefined>({
  key: "openLCOESubmenuAtom",
  default: undefined,
});
