import {
  ABLY_CREATE_ARCHIVED_VERSION,
  ABLY_DELETE_ARCHIVED_VERSION,
} from "../state/ably";
import { _SnapshotDeleteMessage, _SnapshotMeta } from "../types/api";
import { useCallback, useMemo } from "react";
import { Types } from "ably";
import { useAblyGeneric } from "./useAblyGeneric";
import {
  useCreateArchivedVersions,
  useDeleteArchivedVersions,
} from "components/Design/ArchiveProjectVersion/useArchivedVersions";
import { _ArchivedProjectVersion } from "components/Design/ArchiveProjectVersion/types";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalTypeOpenAtom } from "state/modal";
import { ArchivedVersionSuccessModalTypeName } from "components/Design/ArchiveProjectVersion/Modal/SuccessModal";
import { z } from "zod";
import { useToast } from "./useToast";
import { loggedInUserSelector } from "state/user";

export function useAblyArchivedVersion(projectId: string) {
  const { updateLocal } = useCreateArchivedVersions();
  const { deleteLocal } = useDeleteArchivedVersions();
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const userId = useRecoilValue(loggedInUserSelector)?.user_id;

  const { error } = useToast();

  const channelName = useMemo(() => `${projectId}:all`, [projectId]);

  const onMessageReceived = useCallback(
    (message: Types.Message) => {
      const meta = _ArchivedProjectVersion.parse(message.data.meta);
      const { organisationId, nodeId, branchId, author } = meta;
      updateLocal(meta, { organisationId, projectId: nodeId, branchId });
      if (meta.status === "completed" && author === userId) {
        setModalTypeOpen({
          modalType: ArchivedVersionSuccessModalTypeName,
          metadata: meta,
        });
      }
      if (meta.status === "failed" && author === userId) {
        error("Failed to create archived version. Please try again.");
      }
    },
    [error, setModalTypeOpen, updateLocal, userId],
  );

  const onDeleteMessageReceived = useCallback(
    (message: Types.Message) => {
      const meta = z
        .object({
          organisationId: z.string(),
          nodeId: z.string(),
          branchId: z.string(),
          versionId: z.string(),
        })
        .parse(message.data.meta);
      deleteLocal(meta);
    },
    [deleteLocal],
  );

  const events = useMemo(
    () => [
      { eventName: ABLY_CREATE_ARCHIVED_VERSION, onMessageReceived },
      {
        eventName: ABLY_DELETE_ARCHIVED_VERSION,
        onMessageReceived: onDeleteMessageReceived,
      },
    ],
    [onMessageReceived, onDeleteMessageReceived],
  );

  useAblyGeneric(channelName, events);
}
