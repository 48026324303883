export const DefaultMooringProps = {
  chain_r3: {
    mass_d2: 19900,
    EA_d2: 85400000000,
    MBL_d2: 0.8624e9,
    MBL_d3: -1.568e9,
    dvol_dnom: 1.8,
  },
  chain_r4: {
    mass_d2: 19900,
    EA_d2: 85400000000,
    MBL_d2: 1.2056e9,
    MBL_d3: -2.192e9,
    dvol_dnom: 1.8,
  },
  polyester: {
    mass_d2: 797.8,
    EA_d2: 1090000000,
    MBL_d2: 170500000,
    dvol_dnom: 0.86,
  },
  nylon: {
    mass_d2: 647.6,
    EA_d2: 118000000,
    MBL_d2: 139400000,
    dvol_dnom: 0.85,
  },
};
