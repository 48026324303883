import { selectorFamily } from "recoil";
import {
  Layer,
  ExternalDataSourceLinkLayerWithSourceWMS,
} from "../../../types/layers";
import { dedup } from "../../../utils/utils";
import { visibleCollectionLayersSelectorFamily } from "../Collections/state";
import {
  visibleCustomLayersSelector,
  visibleSelectedLayersSelectorFamily,
} from "./state";
import { isWMSLayer } from "../../../state/wms";

/**
 * A layer is visible if it is selected and either
 * 1. Explicitly made visible
 * 2. Standard, and not explicitly made invisible
 * 3. Contained in a collection that is visible (same rules).
 */
export const getVisibleLayers = selectorFamily<Layer[], { projectId: string }>({
  key: "getVisibleLayers",
  get:
    ({ projectId }) =>
    ({ get }) => {
      const enabledLayers = get(
        visibleSelectedLayersSelectorFamily({ projectId }),
      );
      const enabledCollectionLayers = get(
        visibleCollectionLayersSelectorFamily({ projectId }),
      );

      const enabledCustomLayers = get(
        visibleCustomLayersSelector({ projectId }),
      );

      const visibleLayers = dedup(
        [...enabledLayers, ...enabledCollectionLayers, ...enabledCustomLayers],
        (l) => l.id,
      );

      return visibleLayers;
    },
});

export const visibleWmsLayersSelector = selectorFamily<
  ExternalDataSourceLinkLayerWithSourceWMS[],
  {
    projectId: string;
  }
>({
  key: "visibleWmsLayersSelector",
  get:
    ({ projectId }) =>
    async ({ get }) => {
      const allLayers = get(getVisibleLayers({ projectId }));
      return allLayers.filter(isWMSLayer);
    },
});
