import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import mapboxgl from "mapbox-gl";
import styled from "styled-components";
import {
  EXPORT_CABLE_PROPERTY_TYPE,
  SUBSTATION_PROPERTY_TYPE,
} from "@constants/cabling";
import {
  DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
  SUB_AREA_PROPERTY_TYPE,
} from "@constants/division";
import { PARK_PROPERTY_TYPE } from "@constants/park";
import { TURBINE_PROPERTY_TYPE } from "@constants/projectMapView";
import useTextInput from "hooks/useTextInput";
import { useToast } from "hooks/useToast";
import ArrowLeftIcon from "@icons/24/ArrowLeft.svg";
import { _ProjectFeature, ProjectFeature } from "types/feature";
import { getProj4StringForEPSGSelectorFamily } from "state/epsg";
import { activeMapStyleIdAtom } from "state/map";
import { spaceMedium, spaceSmall } from "styles/space";
import { getBBOXArrayFromFeatures } from "utils/geojson/validate";
import { toWGS84 } from "utils/proj4";
import Dropdown from "components/Dropdown/Dropdown";
import Button from "components/General/Button";
import { Label } from "components/General/Form";
import { TextArea } from "components/General/Input";
import { FeatureType } from "types/feature";
import CustomCRSDropdown from "components/CustomCRSDropdown/CustomCRSDropdown";
import { ButtonWrapper, UploadWrapper } from "../../shared";
import {
  coordinateToFeature,
  formatInput,
  getProperties,
  tempFeatureFromCoordinatesCircleLayerId,
  tempFeatureFromCoordinatesCircleSymbolId,
  tempFeatureFromCoordinatesLineLayerId,
  tempFeatureFromCoordinatesPolygonLayerId,
  tempFeatureFromCoordinatesSourceId,
} from "./utils";
import Spinner from "@icons/spinner/Spinner";

const ProjectFeatureTypeSelector = ({
  inContextOfPark,
  inContextOfProject,
  geometryType,
  projectFeatureType,
  setProjectFeatureType,
}: {
  inContextOfPark: boolean;
  inContextOfProject: boolean;
  geometryType: "Point" | "Polygon" | "LineString";
  projectFeatureType: string;
  setProjectFeatureType: (val: "other" | FeatureType) => void;
}) => {
  const options = useMemo(() => {
    switch (geometryType) {
      case "Polygon":
        return (
          <>
            <option value="other">Other</option>
            {inContextOfProject && (
              <>
                <option value={PARK_PROPERTY_TYPE}>Park</option>
                <option value={DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE}>
                  Exclusion zone
                </option>
              </>
            )}
            {inContextOfPark && (
              <option value={SUB_AREA_PROPERTY_TYPE}>Sub area</option>
            )}
          </>
        );
      case "LineString": {
        return (
          <>
            <option value="other">Other</option>
            {inContextOfPark && (
              <option value={EXPORT_CABLE_PROPERTY_TYPE}>Export cable</option>
            )}
          </>
        );
      }
      case "Point": {
        return (
          <>
            <option value="other">Other</option>
            {inContextOfPark && (
              <option value={TURBINE_PROPERTY_TYPE}>Turbine</option>
            )}
            {inContextOfPark && (
              <option value={SUBSTATION_PROPERTY_TYPE}>Substation</option>
            )}
          </>
        );
      }
      default:
        return null;
    }
  }, [geometryType, inContextOfPark, inContextOfProject]);

  return (
    <Label>
      {inContextOfProject ? <p>Project feature type:</p> : <p>Feature type:</p>}
      <Dropdown
        value={projectFeatureType}
        onChange={(e) => {
          setProjectFeatureType(e.target.value as "other" | FeatureType);
        }}
      >
        {options}
      </Dropdown>
    </Label>
  );
};

const CRSSelector = ({
  setCustomCRS,
}: {
  setCustomCRS: React.Dispatch<React.SetStateAction<number | undefined>>;
}) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: spaceSmall }}>
      <Label>
        <p>Coordinate system:</p>
        <CustomCRSDropdown onSelectCRS={(crs) => setCustomCRS(crs.code)} />
      </Label>
    </div>
  );
};

const CoordinateTextBox = ({
  coordinates,
  isPoint,
  setCoordinates,
}: {
  coordinates: string;
  isPoint: boolean;
  setCoordinates: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const placeholder =
    `Coordinates (lng lat/x y) separated by a space${isPoint ? ", and optional point name" : ""}. New line for next coordinate. Eg:\n` +
    `10.409491 58.751193${isPoint ? " Point name 1" : ""}\n` +
    `10.409491 58.669206${isPoint ? " Point name 2" : ""}\n` +
    `10.58692 58.66920${isPoint ? " Point name 3" : ""}\n` +
    "\n\n" +
    "We also support DMS coordinates such as:\n" +
    "04° 17’ 01,00’’ E, 59° 26’ 56,03’’ N\n" +
    "04° 33’ 18,83’’ E, 59° 28’ 19,39’’ N\n";

  const { warning } = useToast();

  return (
    <TextArea
      placeholder={placeholder}
      value={coordinates}
      onChange={(e) => {
        try {
          setCoordinates(formatInput(e.target.value));
        } catch (e) {
          if (e instanceof Error) {
            const msgObj = JSON.parse(e.message) as any;
            const msg = msgObj?.[0]?.message;
            warning("Unable to parse coordinates" + (msg ? `: ${msg}` : ""));
          }
        }
      }}
      onKeyDown={(e) => e.stopPropagation()}
    />
  );
};

const ControlsRow = styled.div`
  display: flex;
  gap: ${spaceMedium};
  justify-content: space-between;
  align-items: flex-start;

  > * {
    flex-grow: 1;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: ${spaceMedium};
  height: 30rem;

  > * {
    flex-basis: 50%;
    resize: none;
  }
`;

// Center of europe
const defaultCenter: mapboxgl.LngLatLike = {
  lat: 38,
  lng: 13.8,
};
const PREVIEW_MAP_BOUNDS_BUFFER = 0.2;

const UploadFeaturesFromCoordinatesGeneral = ({
  currentTurbineType,
  inContextOfProject,
  parkId,
  onDoneClick,
  onBackClick,
  onAddClick,
}: {
  currentTurbineType?: string;
  parkId?: string;
  inContextOfProject: boolean;
  onDoneClick(): void;
  onBackClick?(): void;
  onAddClick(features: ProjectFeature[]): Promise<void> | void;
}) => {
  const previewMap = useRef<mapboxgl.Map>();
  const [isSaving, setIsSaving] = useState(false);
  const previewMapContainer = useRef<HTMLDivElement>(null);
  const activeMapStyleId = useRecoilValue(activeMapStyleIdAtom);
  const [coordinates, setCoordinates] = useState<string>("");
  const [tempFeatures, setTempFeatures] = useState<ProjectFeature[]>([]);
  const [validCoordinates, setValidCoordinates] = useState(false);
  const [geometryType, setGeometryType] = useState<
    "Polygon" | "Point" | "LineString"
  >("Point");
  const [projectFeatureType, setProjectFeatureType] = useState<
    "other" | FeatureType
  >("other");
  const [customCRS, , setCustomCRS] = useTextInput<number | undefined>();
  const proj4StringLoadable = useRecoilValueLoadable(
    getProj4StringForEPSGSelectorFamily(customCRS),
  );

  useEffect(() => {
    if (!previewMapContainer.current) return;

    const newMap = new mapboxgl.Map({
      container: previewMapContainer.current,
      style: activeMapStyleId,
      zoom: 1,
      center: defaultCenter,
    });
    newMap.dragRotate.disable();
    newMap.touchZoomRotate.disableRotation();

    newMap.on("load", (ev) => {
      previewMap.current = ev.target;
      ev.target.resize();
    });
  }, [previewMapContainer, activeMapStyleId]);

  const properties = useMemo(
    () =>
      getProperties(
        projectFeatureType,
        geometryType,
        parkId,
        currentTurbineType,
      ),
    [currentTurbineType, geometryType, parkId, projectFeatureType],
  );

  useEffect(() => {
    if (proj4StringLoadable.state !== "hasValue" || !customCRS) return;
    const proj4String = proj4StringLoadable.contents;
    setTempFeatures([]);
    if (coordinates.trim() === "") {
      return;
    }
    const coordinateLines = coordinates.trim().replaceAll("−", "-").split("\n");
    const allLinesAreValid = coordinateLines
      .map((l) => l.trim())
      .every((l) => {
        const splitLine = l.split(" ");
        return splitLine.slice(0, 2).every((c) => !isNaN(parseFloat(c)));
      });

    if (!allLinesAreValid) {
      setValidCoordinates(false);
      return;
    }

    const coords = coordinateLines.map((cl) =>
      cl
        .trim()
        .split(" ")
        .slice(0, 2)
        .map((c) => parseFloat(c.trim())),
    );
    const pointNames = coordinateLines.map((cl) => {
      const name = cl.trim().split(" ").slice(2);
      return name.length > 0 ? name.join(" ") : undefined;
    });

    try {
      const coordsWGS84 =
        customCRS !== 4326
          ? coords.map((c) => toWGS84(c, proj4String ?? ""))
          : coords;
      const allCoordsWithinWGS84Range = coordsWGS84.every(
        (c) => -90 <= c[1] && c[1] <= 90 && -180 <= c[0] && c[0] <= 180,
      );
      if (!allCoordsWithinWGS84Range)
        throw new Error("Coordinates not within WGS84");

      const features = coordinateToFeature(
        geometryType,
        coordsWGS84,
        pointNames,
        properties,
      );

      const parsedFeatures = _ProjectFeature.array().safeParse(features);
      if (!parsedFeatures.success) {
        setTempFeatures([]);
        setValidCoordinates(false);
        return;
      }

      setTempFeatures(features);
      setValidCoordinates(true);
    } catch (e) {
      console.log("Unable to transform coords to wGS84: " + e);
      setValidCoordinates(false);
    }
  }, [
    setTempFeatures,
    coordinates,
    proj4StringLoadable,
    geometryType,
    customCRS,
    properties,
  ]);

  useEffect(() => {
    if (!validCoordinates || !previewMap.current || tempFeatures.length === 0) {
      return;
    }

    const bbox = getBBOXArrayFromFeatures(tempFeatures);
    previewMap.current.fitBounds(
      [
        [
          bbox[0] - PREVIEW_MAP_BOUNDS_BUFFER,
          bbox[1] - PREVIEW_MAP_BOUNDS_BUFFER,
        ],
        [
          bbox[2] + PREVIEW_MAP_BOUNDS_BUFFER,
          bbox[3] + PREVIEW_MAP_BOUNDS_BUFFER,
        ],
      ],
      {
        duration: 5000,
      },
    );

    previewMap.current.addSource(tempFeatureFromCoordinatesSourceId, {
      type: "geojson",
      data: { type: "FeatureCollection", features: tempFeatures },
    });

    if (geometryType === "Polygon")
      previewMap.current.addLayer({
        id: tempFeatureFromCoordinatesPolygonLayerId,
        type: "fill",
        source: tempFeatureFromCoordinatesSourceId,
        layout: {},
        paint: {
          "fill-color": "red",
          "fill-opacity": 0.5,
        },
      });

    if (geometryType === "LineString")
      previewMap.current.addLayer({
        id: tempFeatureFromCoordinatesLineLayerId,
        type: "line",
        source: tempFeatureFromCoordinatesSourceId,
        layout: {},
        paint: {
          "line-color": "red",
          "line-opacity": 0.5,
          "line-width": 3,
        },
      });

    if (geometryType === "Point") {
      previewMap.current.addLayer({
        id: tempFeatureFromCoordinatesCircleLayerId,
        type: "circle",
        source: tempFeatureFromCoordinatesSourceId,
        paint: {
          "circle-radius": 6,
          "circle-color": "red",
          "circle-opacity": 0.5,
        },
      });

      previewMap.current.addLayer({
        id: tempFeatureFromCoordinatesCircleSymbolId,
        type: "symbol",
        source: tempFeatureFromCoordinatesSourceId,
        layout: {
          "text-field": [
            "case",
            ["==", ["get", "name"], "Uploaded feature"],
            "",
            ["get", "name"],
          ],
          "text-size": 8,
          "text-offset": [0, 1],
          "symbol-placement": "point",
        },
      });
    }

    return () => {
      if (geometryType === "LineString") {
        previewMap.current?.removeLayer(tempFeatureFromCoordinatesLineLayerId);
      }
      if (geometryType === "Polygon") {
        previewMap.current?.removeLayer(
          tempFeatureFromCoordinatesPolygonLayerId,
        );
      }
      if (geometryType === "Point") {
        previewMap.current?.removeLayer(
          tempFeatureFromCoordinatesCircleLayerId,
        );
        previewMap.current?.removeLayer(
          tempFeatureFromCoordinatesCircleSymbolId,
        );
      }
      previewMap.current?.removeSource(tempFeatureFromCoordinatesSourceId);
    };
  }, [geometryType, tempFeatures, validCoordinates]);

  const handleDoneClick = useCallback(async () => {
    if (validCoordinates) {
      setIsSaving(true);
      try {
        await onAddClick(tempFeatures);
        setIsSaving(false);
        onDoneClick();
      } catch (e) {
        setIsSaving(false);
        throw e;
      }
    }
  }, [onAddClick, onDoneClick, tempFeatures, validCoordinates]);

  return (
    <>
      <UploadWrapper>
        <ControlsRow>
          <Label>
            <p>Geometry type:</p>
            <Dropdown
              value={geometryType}
              onChange={(e) => {
                const type = e.target.value as
                  | "Point"
                  | "LineString"
                  | "Polygon";
                setGeometryType(type);
                setProjectFeatureType("other");
              }}
            >
              <option value="Point">Point(s)</option>
              <option value="LineString">LineString</option>
              <option value="Polygon">Polygon</option>
            </Dropdown>
          </Label>

          <ProjectFeatureTypeSelector
            inContextOfProject={inContextOfProject}
            inContextOfPark={!!parkId}
            geometryType={geometryType}
            projectFeatureType={projectFeatureType}
            setProjectFeatureType={setProjectFeatureType}
          />
          <CRSSelector setCustomCRS={setCustomCRS} />
        </ControlsRow>
        <ContentWrapper>
          <CoordinateTextBox
            isPoint={geometryType === "Point"}
            coordinates={coordinates}
            setCoordinates={setCoordinates}
          />
          <div ref={previewMapContainer} />
        </ContentWrapper>
        <ButtonWrapper>
          {onBackClick ? (
            <Button
              buttonType="text"
              text="Back"
              icon={<ArrowLeftIcon />}
              onClick={onBackClick}
              style={{
                paddingLeft: 0,
              }}
            />
          ) : (
            <div />
          )}
          <ButtonWrapper>
            <Button text="Close" buttonType="text" onClick={onDoneClick} />
            <Button
              buttonType="primary"
              text="Add"
              disabled={!validCoordinates || isSaving}
              onClick={handleDoneClick}
              icon={isSaving ? <Spinner size="1rem" /> : undefined}
            />
          </ButtonWrapper>
        </ButtonWrapper>
      </UploadWrapper>
    </>
  );
};

export default UploadFeaturesFromCoordinatesGeneral;
