import styled from "styled-components";
import { typography } from "../../../styles/typography";
import { colors } from "../../../styles/colors";
import { borderRadiusMedium } from "../../../styles/space";

export const TextWrapper = styled.div`
  ${typography.contentAndButtons};
`;

export const KeyTag = styled.pre`
  ${typography.regular};
  font-family: monospace;
  display: inline-flex;
  background: ${colors.primary}20;
  border-radius: ${borderRadiusMedium};
  padding-inline: 0.2rem;
  margin: 0;
`;

export const MenuPosition = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`;
