import "nouislider/dist/nouislider.css";
import { useRecoilState } from "recoil";
import {
  shoreDistanceFilterAtomFamily,
  maxShorelineDistance,
} from "../../state/filter";
import { RangeSlider } from "../General/Slider";
import { useTypedPath } from "../../state/pathParams";

const ShorelineDistanceSlider = ({ disabled }: { disabled?: boolean }) => {
  const { projectId } = useTypedPath("projectId");
  const [shorelineDistance, setShorelineDistance] = useRecoilState(
    shoreDistanceFilterAtomFamily({ projectId }),
  );

  return (
    <label>
      <RangeSlider
        labels
        min={0}
        max={maxShorelineDistance}
        values={shorelineDistance as [number, number]}
        onChange={(range) =>
          setShorelineDistance(
            range.map((v) =>
              v >= maxShorelineDistance ? Number.MAX_SAFE_INTEGER : v,
            ),
          )
        }
        inside
        disabled={disabled}
      />
    </label>
  );
};

export default ShorelineDistanceSlider;
