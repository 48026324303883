import styled from "styled-components";
import { SimpleTurbineType } from "../../../types/turbines";
import { Input, InputDimensioned } from "../../General/Input";

import { Column, Row } from "../../General/Layout";
import { Label as _Label } from "../../General/Form";

import {
  ErrorBoundaryWarningTriangle,
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "../../ErrorBoundaries/ErrorBoundaryLocal";
import { ContentWrapper } from "../../SettingsV2/Shared/styles";
import { spaceLarge } from "styles/space";
import Checkbox from "components/General/Checkbox";
import { IAVoltageType } from "services/cableTypeService";

export const ColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  height: 100%;
  width: 100%;
`;

const Label = styled(_Label)`
  flex: 1;
`;

export const SimpleTurbineSettingsView = ErrorBoundaryWrapper(
  ({ turbine }: { turbine: SimpleTurbineType }) => {
    const isDisabled = true;

    return (
      <ContentWrapper
        style={{
          maxHeight: "calc(100% - 7.3rem)",
          overflowY: "auto",
          boxSizing: "border-box",
        }}
      >
        <ColumnContent>
          <h4 style={{ margin: 0 }}>Turbine settings</h4>
          <Column>
            <Row>
              <Label>
                <p>Name</p>
                <Input disabled={isDisabled} value={turbine.name} />
              </Label>
              <Label>
                <p>Cost</p>
                <InputDimensioned
                  disabled={true}
                  value={turbine.cost}
                  unit={turbine.costUnit}
                />
              </Label>
            </Row>
            <Row>
              <Label>
                <p>Diameter</p>
                {turbine && (
                  <InputDimensioned
                    type="number"
                    disabled={isDisabled}
                    value={turbine.diameter}
                    unit={"m"}
                  />
                )}
              </Label>

              <Label>
                <p>Hub height</p>
                <InputDimensioned
                  type="number"
                  disabled={isDisabled}
                  value={turbine?.hubHeight}
                  unit={"m"}
                />
              </Label>

              <Label>
                <p>RNA Mass</p>
                <InputDimensioned
                  type="number"
                  disabled={true}
                  value={Math.round(turbine.rnaMass / 1000)}
                  unit={"t"}
                />
              </Label>
              <Label>
                <p>Voltage</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: spaceLarge,
                  }}
                >
                  <Checkbox
                    value={IAVoltageType.kV66}
                    label={String(IAVoltageType.kV66).concat("kV")}
                    labelPlacement="after"
                    checked={turbine.voltage.includes(IAVoltageType.kV66)}
                    disabled={true}
                  />
                  <Checkbox
                    value={IAVoltageType.kV132}
                    label={String(IAVoltageType.kV132).concat("kV")}
                    labelPlacement="after"
                    checked={turbine.voltage.includes(IAVoltageType.kV132)}
                    disabled={true}
                  />
                </div>
              </Label>
            </Row>
          </Column>
        </ColumnContent>
      </ContentWrapper>
    );
  },
  () => {
    return (
      <>
        <FatalErrorBoundaryWrapper />
        <ErrorBoundaryWarningTriangle />
      </>
    );
  },
  ScreamOnError,
);

export default SimpleTurbineSettingsView;
