import { Map } from "mapbox-gl";
import { createContext, ReactNode, useState } from "react";

export const mapContext = createContext<
  | {
      map: Map | undefined;
      setMap: (map: Map) => void;
    }
  | undefined
>(undefined);

export default function MapContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [map, setMap] = useState<Map>();

  return (
    <mapContext.Provider value={{ map, setMap }}>
      {children}
    </mapContext.Provider>
  );
}
