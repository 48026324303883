import { useRefreshLibraryFoundation } from "components/ConfigurationModal/useRefreshCustomFoundations";
import { Mixpanel } from "mixpanel";
import { useRecoilCallback } from "recoil";
import {
  createNewOrgFoundation,
  deleteOrgFoundation,
  updateOrgFoundation,
} from "services/turbineAPIService";
import { useTypedPath } from "state/pathParams";
import { FoundationType } from "types/foundations";
import { organisationFoundationResourceState } from "../state";
import { fetchFoundationTypeUsage } from "state/foundations";

const useOrgFoundationCrud = () => {
  const { organisationId } = useTypedPath("organisationId");

  const refreshLibraryFoundations = useRefreshLibraryFoundation();

  const create = useRecoilCallback(
    ({ set, snapshot }) =>
      async ({
        foundation,
        name,
        projectAccess,
      }: {
        foundation: FoundationType;
        name?: string;
        projectAccess?: string[];
      }) => {
        const newFoundation = {
          ...foundation,
          name: name ? name : `${foundation.name} copy`,
        };

        const fallback = await snapshot.getPromise(
          organisationFoundationResourceState({ organisationId }),
        );

        try {
          const res = await createNewOrgFoundation(
            organisationId,
            newFoundation,
            projectAccess,
          );
          set(
            organisationFoundationResourceState({ organisationId }),
            (cur) => [...cur, res],
          );
          refreshLibraryFoundations();
          Mixpanel.track("Created org foundation", {
            foundationId: foundation.id,
            organisationId,
          });
          return res;
        } catch (e) {
          set(
            organisationFoundationResourceState({ organisationId }),
            fallback,
          );
          throw e;
        }
      },
    [organisationId, refreshLibraryFoundations],
  );

  const update = useRecoilCallback(
    ({ set, snapshot }) =>
      async (foundation: FoundationType) => {
        const fallback = await snapshot.getPromise(
          organisationFoundationResourceState({ organisationId }),
        );

        return updateOrgFoundation(organisationId, foundation)
          .then((res) => {
            if (res) {
              set(
                organisationFoundationResourceState({ organisationId }),
                (cur) => cur.map((c) => (c.id === foundation.id ? res : c)),
              );
              refreshLibraryFoundations();
              Mixpanel.track("Update org foundation", {
                foundationId: foundation.id,
                organisationId,
              });
            }
          })
          .catch(() => {
            set(
              organisationFoundationResourceState({ organisationId }),
              fallback,
            );
          });
      },
    [organisationId, refreshLibraryFoundations],
  );

  const remove = useRecoilCallback(
    ({ set, snapshot }) =>
      async (foundationId: string) => {
        const fallback = await snapshot.getPromise(
          organisationFoundationResourceState({ organisationId }),
        );

        const usage = await fetchFoundationTypeUsage(
          organisationId,
          foundationId,
        );
        if (
          usage.length === 0 ||
          window.confirm(
            `Foundation type is currently being used in ${usage.length} foundations, are you sure you want to delete it?`,
          )
        ) {
          return deleteOrgFoundation(organisationId, foundationId)
            .then((res) => {
              if (res) {
                set(
                  organisationFoundationResourceState({ organisationId }),
                  (cur) => [...cur].filter((c) => c.id !== foundationId),
                );
                refreshLibraryFoundations();
                Mixpanel.track("Delete org turbine", {
                  turbineId: foundationId,
                  organisationId,
                });
              }
            })
            .catch(() => {
              set(
                organisationFoundationResourceState({ organisationId }),
                fallback,
              );
            });
        }
      },
    [organisationId, refreshLibraryFoundations],
  );

  const duplicate = useRecoilCallback(
    () =>
      async (duplicate: {
        foundation: FoundationType;
        name?: string;
        projectAccess?: string[];
      }) => {
        return create(duplicate);
      },
    [create],
  );

  return { create, remove, update, duplicate };
};

export default useOrgFoundationCrud;
