/// <reference types="vite-plugin-svgr/client" />
import { SVGWrapper } from "@icons/svgUtils";
import Button from "components/General/Button";
import { Column, Row } from "components/General/Layout";
import { groupProjectsState } from "components/Organisation/Groups/state";
import useGroupNodeAccessCrud from "components/Organisation/Groups/useGroupNodeAccessCrud";
import {
  ContentTableColumn,
  ContentTableRow,
  TabDescription,
  TextEllipsis,
} from "components/Organisation/OrganisationRightSide/style";
import { useState, useCallback } from "react";
import { useRecoilValue } from "recoil";
import { useTypedPath } from "state/pathParams";
import { singleNodeAtomFamily } from "state/timeline";
import { UserAccessRole, _UserAccessRole } from "types/user";
import {
  AdminNodeAccessModalWrapper,
  NodeAccess,
} from "../../shared/NodeAccessModal";
import Trashcan from "@icons/24/Bin.svg?react";
import Folder from "@icons/14/Folder.svg?react";
import Earth from "@icons/14/Earth.svg?react";
import AddIcon from "@icons/24/Add.svg?react";
import { GroupNodeAccessDropdown } from "../../shared/GroupNodeAccessDropdown";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { IconBtn } from "components/General/Icons";

export function GroupProjects({ groupId }: { groupId: string }) {
  const { organisationId } = useTypedPath("organisationId");
  const projectsAccess = useRecoilValue(
    groupProjectsState({ organisationId, groupId }),
  );
  const { addOrUpdate } = useGroupNodeAccessCrud();
  const [showAddProject, setShowAddProject] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState<number>(0);

  const onAddProjects = useCallback(
    async (nodes: NodeAccess[]) => {
      setSaveInProgress(nodes.length);
      setShowAddProject(false);
      try {
        await Promise.all(
          nodes.map((node) => {
            const role = _UserAccessRole.parse(node.resource_name);
            return addOrUpdate(groupId, node.node_id, role).then(() =>
              setSaveInProgress((cur) => cur - 1),
            );
          }),
        );
      } catch {
      } finally {
        setSaveInProgress(0);
      }
    },
    [addOrUpdate, groupId],
  );

  return (
    <Column style={{ gap: "1.6rem", height: "100%" }}>
      <Column style={{ gap: "1.2rem" }}>
        <Row style={{ alignItems: "center" }}>
          <TabDescription>Projects this group has access to.</TabDescription>
          <div style={{ marginLeft: "auto", position: "relative" }}>
            <Button
              text="Add projects"
              buttonType="secondary"
              onClick={() => setShowAddProject((cur) => !cur)}
              disabled={saveInProgress > 0}
              icon={<AddIcon />}
            />
            {showAddProject && (
              <div style={{ position: "absolute", right: "0.8rem" }}>
                <AdminNodeAccessModalWrapper
                  onSave={onAddProjects}
                  existingAccess={projectsAccess.map((p) => p.node_id)}
                />
              </div>
            )}
          </div>
        </Row>
      </Column>
      <ContentTableColumn>
        {projectsAccess.map((m) => {
          return (
            <SingleNode
              key={m.node_id}
              groupId={groupId}
              nodeId={m.node_id}
              resourceName={_UserAccessRole.parse(m.resource_name)}
            />
          );
        })}
        {Array.from({ length: saveInProgress }, (_, index) => (
          <SkeletonBlock
            key={index}
            style={{ height: "4rem", marginTop: "0.8rem" }}
          />
        ))}
      </ContentTableColumn>
    </Column>
  );
}

function SingleNode({
  groupId,
  nodeId,
  resourceName,
}: {
  groupId: string;
  nodeId: string;
  resourceName: UserAccessRole;
}) {
  const { remove } = useGroupNodeAccessCrud();
  const node = useRecoilValue(
    singleNodeAtomFamily({
      nodeId,
    }),
  );

  const [saveInProgress, setSaveInProgress] = useState(false);

  if (!node) return <></>;

  const RowIcon = node.type === "project" ? Earth : Folder;

  return (
    <ContentTableRow disabled={saveInProgress}>
      <Row style={{ flex: "0 0 45%", overflow: "hidden" }}>
        <SVGWrapper size={1.4}>
          <RowIcon />
        </SVGWrapper>
        <TextEllipsis style={{ margin: 0 }}>{node.name}</TextEllipsis>
      </Row>
      <Row style={{ flex: "0 0 45%" }}>
        <GroupNodeAccessDropdown
          groupId={groupId}
          nodeId={nodeId}
          role={resourceName}
        />
      </Row>
      <Row style={{ flex: "0 0 5%" }}>
        <IconBtn
          onClick={(e) => {
            e.stopPropagation();
            setSaveInProgress(true);
            remove(groupId, nodeId).finally(() => setSaveInProgress(false));
          }}
          size={"1.4rem"}
          style={{
            marginLeft: "auto",
          }}
          disabled={saveInProgress}
        >
          <Trashcan />
        </IconBtn>
      </Row>
    </ContentTableRow>
  );
}
