import Button from "components/General/Button";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useTypedPath } from "state/pathParams";
import {
  adminInOrganisationSelectorFamily,
  loggedInUserSelector,
} from "state/user";

export default function ManageMyAccessButton() {
  const { organisationId } = useTypedPath("organisationId");
  const setContent = useSetRecoilState(
    organisationRightSideModal(organisationId),
  );
  const navigate = useNavigate();
  const userId = useRecoilValue(loggedInUserSelector)?.user_id;

  const isAdminInOrg = useRecoilValue(
    adminInOrganisationSelectorFamily({ organisationId }),
  );

  if (!userId) return <></>;
  if (!isAdminInOrg) return <></>;

  return (
    <Button
      text="Manage my access"
      onClick={() => {
        navigate(
          {
            search: "tab=members",
          },
          {
            replace: false,
          },
        );
        setContent({
          type: "user",
          id: userId,
          meta: { showLibraryAccess: true },
        });
      }}
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: "auto",
        zIndex: 100,
      }}
    />
  );
}
