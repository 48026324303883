import styled from "styled-components";
import { StandardBox } from "../../../styles/boxes/Boxes";
import Hyperlink from "../../../icons/24/Hyperlink.svg?react";
import { IconREMSize, typography } from "../../../styles/typography";
import FullScreenModal from "../../FullScreenModal/FullScreenModal";
import Button from "../../General/Button";
import ValidationSuccess from "@icons/24/ValidationSuccess.svg?react";
import { Column, Row } from "../../General/Layout";

import { useRecoilState, useSetRecoilState } from "recoil";
import { toastMessagesAtom } from "../../../state/toast";
import { useState } from "react";
import { colors } from "styles/colors";
import { copyClientCredentialsModalState } from "./state/clientCredentials";

const Modal = styled(StandardBox)`
  position: fixed;
  top: 20%;
  padding: 20px;
  width: fit-content;
  max-width: 80rem;
`;

const Text = styled.p`
  ${typography.regular}
`;

const Bold = styled(Text)`
  ${typography.sub2}
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  width: 2.4rem;
  height: 2.4rem;
  cursor: auto;
  border-radius: 50%;
  background-color: ${colors.green100};
`;

export default function CopyClientCredentialsModal() {
  const setToastMessages = useSetRecoilState(toastMessagesAtom);
  const [cc, setCCToCopy] = useRecoilState(copyClientCredentialsModalState);

  const [copiedSecret, setCopiedSecret] = useState(false);

  if (cc === null) return <></>;

  return (
    <FullScreenModal>
      <Modal>
        <Column style={{ gap: "2.4rem" }}>
          <Column style={{ gap: "1.6rem" }}>
            <Row style={{ alignItems: "center" }}>
              <IconContainer>
                <IconREMSize height={1.4} width={1.4}>
                  <ValidationSuccess />
                </IconREMSize>
              </IconContainer>
              <h3 style={{ margin: 0 }}>
                Client credentials successfully created
              </h3>
            </Row>
            <Text style={{ textWrap: "balance" }}>
              Make sure to copy and store your credentials in a safe place. The
              client secret <strong>will not be available</strong> after you
              close this window.
            </Text>
          </Column>
          <Column
            style={{
              padding: "1.6rem",
              backgroundColor: colors.surfaceInfo,
              gap: "2rem",
              borderRadius: "0.4rem",
            }}
          >
            <Column style={{ gap: "0.2rem" }}>
              <Bold>Name</Bold>
              <Text>{cc.name}</Text>
            </Column>
            <Column style={{ gap: "0.2rem" }}>
              <Bold>Client id</Bold>
              <Row style={{ width: "100%", justifyContent: "space-between" }}>
                <Text>{cc.client_id}</Text>
                <Button
                  size="small"
                  icon={<Hyperlink />}
                  buttonType="primary"
                  onClick={() => {
                    navigator.clipboard.writeText(cc.client_id);
                    setToastMessages((tm) => [
                      ...tm,
                      { text: "Client id copied to clipboard", timeout: 2000 },
                    ]);
                  }}
                />
              </Row>
            </Column>
            <Column style={{ gap: "0.2rem" }}>
              <Bold>Client secret</Bold>
              <Row style={{ width: "100%", justifyContent: "space-between" }}>
                <Text style={{ flex: 1 }}>{cc.client_secret}</Text>
                <Button
                  size="small"
                  icon={<Hyperlink />}
                  buttonType="primary"
                  onClick={() => {
                    navigator.clipboard.writeText(cc.client_secret);
                    setCopiedSecret(true);
                    setToastMessages((tm) => [
                      ...tm,
                      {
                        text: "Client secret copied to clipboard",
                        timeout: 2000,
                      },
                    ]);
                  }}
                />
              </Row>
            </Column>
          </Column>
          <Row style={{ marginTop: "1.2rem" }}>
            <Button
              style={{ marginLeft: "auto" }}
              text="Close"
              onClick={() => {
                if (
                  copiedSecret ||
                  window.confirm(
                    "Please confirm that you have copied your client secret",
                  )
                ) {
                  setCCToCopy(null);
                }
              }}
              buttonType="secondary"
            />
          </Row>
        </Column>
      </Modal>
    </FullScreenModal>
  );
}
