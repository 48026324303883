import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import mapboxgl from "mapbox-gl";
import styled from "styled-components";
import { Place, placeToTranslatePercent } from "components/General/Anchor";
import { Comp } from "types/utils";

const HoverDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
`;

/**
 * A {@link mapboxgl.Popup} like component, but better.
 */
export const Popup = ({
  map,
  pos,
  mapRef,
  place = "bottom",
  offsetPx = [0, 0],
  children,
  style,
  ...props
}: React.PropsWithChildren<
  Comp<
    "div",
    {
      map: mapboxgl.Map;
      pos: mapboxgl.LngLatLike;
      /**
       * Component to put the popup in. If not provided, the popup will have position fixed.
       * If provided, the popup will have position absolute
       */
      mapRef?: React.RefObject<HTMLDivElement>;
      /**
       * The place of the component that should be put on the {@link pos}.
       */
      place?: Place;
      /**
       * Offset of the popup, in pixels.
       */
      offsetPx?: [number, number];
    }
  >
>) => {
  const [, refresh] = useState<number>(0);

  const { x: mapX, y: mapY } = map.project(pos);
  const viewportX = mapX + offsetPx[0];
  const viewportY = mapY + offsetPx[1];

  useEffect(() => {
    const move = () => refresh((c) => c + 1);
    map.on("move", move);
    return () => {
      map.off("move", move);
    };
  }, [map]);

  const [tx, ty] = placeToTranslatePercent(place);

  return createPortal(
    <HoverDiv
      style={{
        transform: `translate(${viewportX}px, ${viewportY}px) translate(${tx}%, ${ty}%)`,
        position: "absolute",
        ...style,
      }}
      {...props}
    >
      {children}
    </HoverDiv>,
    mapRef?.current ?? map.getContainer(),
  );
};
