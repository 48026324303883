import styled from "styled-components";
import { spacing2, spacing4, spacing5, spacing6 } from "styles/space";
import { colors } from "styles/colors";
import { ColumnTemplate } from "components/CompareParksModal/ParkComparisonView/ParkComparisonView.style";

export const ColumnTemplateWithBorder = styled(ColumnTemplate)<{
  isHoveredEnd?: boolean;
  isHoveredStart?: boolean;
  isBaseline?: boolean;
  isError?: boolean;
}>`
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
  cursor: grab;
  z-index: 2;
  background-color: white;
  position: relative;

  ${({ isError, isBaseline }) =>
    isError
      ? `
      border-color: ${colors.borderError};  
  `
      : isBaseline &&
        `
      border-color: ${colors.surfaceSelectedDark};
  `}

  ${({ isHoveredEnd, isHoveredStart }) => {
    if (isHoveredStart) {
      return `
        &::before {
          content: "";
          position: absolute;
          height: 100%;
          width: 3px;
          background-color: ${colors.blue500};
          top: 0;
          left: calc(calc(${spacing6} / 2) * -1);
          z-index: 4;
        }
      `;
    }

    if (isHoveredEnd) {
      return `
        &::after {
          content: "";
          position: absolute;
           height: 100%;
          width: 3px;
          background-color: ${colors.blue500};
          top: 0;
          right: calc(calc(${spacing6} / 2) * -1);
          z-index: 4;
          
        }
      `;
    }
  }};
`;

export const ErrorWrapper = styled.div`
  background-color: white;
  z-index: 2;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  position: absolute;
`;

export const ParkName = styled.div`
  width: 100%;
  padding: 0.8rem 0.8rem 0.8rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  gap: 0.3rem;

  a {
    display: block;
    margin: 0;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    color: ${colors.primaryText};

    &:hover {
      color: ${colors.primary};
    }
  }
`;

export const ColumnHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  overflow: hidden;
  padding: ${spacing5};
  background-color: ${colors.surfaceInfo};
  border-radius: 4px 4px 0 0;
`;

export const StickyHeader = styled.div`
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 3;
  margin: -1px -1px 0 -1px;
  width: calc(25rem + 2px);
`;

export const StickyHeaderBorderContent = styled.div<{
  isBaseline?: boolean;
  isError?: boolean;
}>`
  display: flex;
  flex-direction: column;
  border-radius: 4px 4px 0 0;
  border: 1px solid transparent;
  border-bottom: none;

  ${({ isError, isBaseline }) =>
    isError
      ? `
      border-color: ${colors.borderError};
  `
      : isBaseline &&
        `
        border-color: ${colors.surfaceSelectedDark};
  `}
`;

export const DnDWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  position: absolute;
  visibility: hidden;
  left: 0;
  svg {
    width: 1.2rem;
    height: 1.2rem;
  }
  ${ColumnTemplateWithBorder}:hover & {
    visibility: visible;
  }
`;

export const ConfigContainer = styled.span`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: 100%;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 0.4rem;
  overflow: visible;
  justify-content: space-between;
  width: 100%;
`;

export const ComparisonValueWrapper = styled.div`
  padding: ${spacing2} ${spacing4};
  border-radius: 4px;
  display: flex;
  gap: ${spacing2};
`;
