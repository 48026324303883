import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { useTypedPath } from "../../state/pathParams";
import { colors } from "../../styles/colors";
import { liveCursorState } from "./state";
import usePrevious from "../../hooks/usePrevious";
import { LngLatLike, Map } from "mapbox-gl";
import { mapRefAtom } from "../../state/map";
import styled from "styled-components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { projectPresenceAtomFamily } from "../../state/ably";
import { isDefined } from "../../utils/predicates";
import { TextRaw } from "../../styles/typography";
import { getUserColor, getUserFontColor } from "./utils";
import { currentVersionSelector } from "../../state/project";
import { animated, useSpring } from "@react-spring/web";
import { nodeGroupUserAccessSelector } from "components/Organisation/Groups/state";
import {
  UserNodeAccessWithMeta,
  isUserNodeAccessWithMeta,
} from "components/Organisation/Groups/types";

function lngLatToContainerPosition(
  map: Map,
  lngLat: LngLatLike,
): { x: number; y: number } {
  const point = map.project(lngLat);
  return { x: point.x, y: point.y };
}

export default function LiveCursor() {
  const map = useRecoilValue(mapRefAtom);

  if (!map) return null;
  return <LiveCursorInner map={map} />;
}

function LiveCursorInner({ map }: { map: Map }) {
  const { projectId, branchId } = useTypedPath("projectId", "branchId");
  const version = useRecoilValue(currentVersionSelector);
  const livePositions = useRecoilValue(
    liveCursorState({ projectId, branchId, version }),
  );
  const projectPresence = useRecoilValue(
    projectPresenceAtomFamily({ nodeId: projectId ?? "" }),
  );

  const [transformedPositions, setTransformedPositions] = useState<
    {
      userId: string;
      position: { x: number; y: number };
      idle: boolean;
      immediate?: boolean;
    }[]
  >([]);

  const transformPositions = useCallback(
    (immediate?: boolean) => {
      if (!map) return [];
      const newPos = Array.from(livePositions.entries())
        .map(([userId, position]) => {
          const branchPresence = projectPresence.find(
            (p) => p.clientId === userId && p.data.branchId === branchId,
          );
          if (!branchPresence) return undefined;
          return {
            userId,
            position: lngLatToContainerPosition(map, position),
            idle: branchPresence.data.status === "idle",
            immediate,
          };
        })
        .filter(isDefined);
      setTransformedPositions(newPos);
    },
    [branchId, livePositions, map, projectPresence],
  );

  useEffect(() => {
    transformPositions();
  }, [transformPositions]);

  useEffect(() => {
    if (!map) return;
    const transformImmediate = () => transformPositions(true);
    map.on("move", transformImmediate);
    return () => {
      map.off("move", transformImmediate);
    };
  });

  if (!map) return null;
  return (
    <div>
      {transformedPositions.map(({ userId, position, immediate, idle }) => (
        <Cursor
          key={userId}
          userId={userId}
          position={position}
          immediate={immediate}
          idle={idle}
        />
      ))}
    </div>
  );
}

const Name = styled.div`
  ${TextRaw}
  padding: 0.2rem 0.4rem;
  border-radius: 0.4rem;
  background-color: ${colors.orange};
  margin-left: 1.2rem;
`;

function UserName({
  userId,
  color,
  fontColor,
  organisationId,
  projectId,
}: {
  userId: string;
  color: string;
  fontColor: string;
  organisationId: string;
  projectId: string;
}) {
  const users = useRecoilValueLoadable(
    nodeGroupUserAccessSelector({ organisationId, nodeId: projectId }),
  ).valueMaybe();

  const userInfo = users
    ?.filter(isUserNodeAccessWithMeta)
    .find((u: UserNodeAccessWithMeta) => u.user_id === userId);

  if (!userInfo) return null;

  return (
    <Name style={{ backgroundColor: color, color: fontColor }}>
      {userInfo.nickname}
    </Name>
  );
}

function Cursor({
  position,
  userId,
  immediate,
  idle,
}: {
  position: { x: number; y: number };
  userId: string;
  immediate?: boolean;
  idle: boolean;
}) {
  const { organisationId, projectId } = useTypedPath(
    "organisationId",
    "projectId",
  );
  const prev = usePrevious(position);
  const styles = useSpring({
    from: {
      position: "fixed" as const,
      top: "0",
      left: "0",
      zIndex: 1,
      opacity: idle ? 0.5 : 1,
      transform: `translate(${prev?.x ?? 0}px, ${prev?.y ?? 0}px)`,
    },
    to: [
      {
        position: "fixed" as const,
        top: "0",
        left: "0",
        zIndex: 1,
        opacity: idle ? 0.5 : 1,
        transform: `translate(${position.x}px, ${position.y}px)`,
        immediate: immediate ?? false,
      },
    ],
  });

  const backgroundColor = useMemo(() => {
    return getUserColor(userId);
  }, [userId]);
  const fontColor = useMemo(() => {
    return getUserFontColor(userId);
  }, [userId]);

  return (
    <animated.div style={{ ...styles, pointerEvents: "none" }}>
      <CursorSvg color={backgroundColor} />
      {organisationId && (
        <UserName
          organisationId={organisationId}
          userId={userId}
          color={backgroundColor}
          fontColor={fontColor}
          projectId={projectId}
        />
      )}
    </animated.div>
  );
}

function CursorSvg({ color }: { color: string }) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fill={color}
        d="M5.65376 12.3673H5.46026L5.31717 12.4976L0.500002 16.8829L0.500002 1.19841L11.7841 12.3673H5.65376Z"
      />
    </svg>
  );
}
