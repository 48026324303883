import Dropdown, { DropdownStyle } from "components/Dropdown/Dropdown";
import { DropDownItem } from "components/General/Dropdown/DropdownItems";
import { useEffect, useMemo, useState } from "react";
import {
  atom,
  selector,
  selectorFamily,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";
import { syncLocalStorage } from "state/effects";
import { z } from "zod";
import { modalTypeOpenAtom } from "state/modal";
import styled from "styled-components";
import { typography } from "styles/typography";
import { colors } from "styles/colors";
import FullScreenModal from "components/FullScreenModal/FullScreenModal";
import { Column, ModalFrame } from "components/General/Layout";
import { spaceLarge } from "styles/space";
import { InputNumber } from "components/General/Input";
import Button from "components/General/Button";
import { fetchEnhancer } from "services/utils";

export const AddCustomCRSModalType = "AddCustomCRSModal";

const _CRSDefinition = z.object({
  code: z.number(),
  name: z.string(),
  proj4string: z.string(),
});
export type CRSDefinition = z.infer<typeof _CRSDefinition>;

const getEPSGJsonSelectorFamily = selectorFamily<
  { name: string; proj4string: string } | undefined,
  number | undefined
>({
  key: "getEPSGJsonSelectorFamily",
  get: (code) => async () => {
    if (!code) return;
    const resName = await fetchEnhancer(`https://epsg.io/${code}.json`);
    const resJsonName = (await resName.json()) as { name?: string };
    const name = resJsonName.name;
    if (!resName.ok || !name) throw new Error("Unable to fetch name for EPSG");

    const resProj4String = await fetchEnhancer(`https://epsg.io/${code}.proj4`);
    if (!resProj4String.ok)
      throw new Error("Unable to fetch proj4string for EPSG");
    const proj4string = await resProj4String.text();

    return { name, proj4string };
  },
});

const availableCRSAtom = atom<CRSDefinition[]>({
  key: "availableCRSAtom",
  default: [
    {
      code: 4326,
      name: "WGS84",
      proj4string: "+proj=longlat +datum=WGS84 +no_defs +type=crs",
    },
    {
      code: 3006,
      name: "SWEREF99 TM",
      proj4string:
        "+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    },
    {
      code: 32633,
      name: "UTM zone 33N",
      proj4string:
        "+proj=utm +zone=33 +datum=WGS84 +units=m +no_defs +type=crs",
    },
    {
      code: 32632,
      name: "UTM zone 32N",
      proj4string:
        "+proj=utm +zone=32 +datum=WGS84 +units=m +no_defs +type=crs",
    },
    {
      code: 32631,
      name: "UTM zone 31N",
      proj4string:
        "+proj=utm +zone=31 +datum=WGS84 +units=m +no_defs +type=crs",
    },
    {
      code: 32630,
      name: "UTM zone 30N",
      proj4string:
        "+proj=utm +zone=30 +datum=WGS84 +units=m +no_defs +type=crs",
    },
    {
      code: 32629,
      name: "UTM zone 29N",
      proj4string:
        "+proj=utm +zone=29 +datum=WGS84 +units=m +no_defs +type=crs",
    },
    {
      code: 5111,
      name: "NTM zone 11",
      proj4string:
        "+proj=tmerc +lat_0=58 +lon_0=11.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    },
    {
      code: 5110,
      name: "NTM zone 10",
      proj4string:
        "+proj=tmerc +lat_0=58 +lon_0=10.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    },
    {
      code: 5109,
      name: "NTM zone 10",
      proj4string:
        "+proj=tmerc +lat_0=58 +lon_0=9.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    },
  ],
  effects: [syncLocalStorage(`vind:CRS:customv2`, _CRSDefinition.array())],
});

export const selectedCRSAtom = atom<CRSDefinition>({
  key: "selectedCRSAtom",
  default: selector({
    key: "selectedCRSAtomDefault",
    get: ({ get }) => get(availableCRSAtom)[0],
  }),
  effects: [syncLocalStorage(`vind:CRS:selected`, _CRSDefinition)],
});

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  div {
  }
`;

const EpsgInputColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

const ErrorText = styled.p`
  ${typography.sub4}
  color: ${colors.textError};
`;

const AddCustomCRSModalInner = () => {
  const [newEPSGCode, setNewEPSGCode] = useState<number | undefined>();
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const setAvailableCRS = useSetRecoilState(availableCRSAtom);
  const setSelectedCRS = useSetRecoilState(selectedCRSAtom);

  const epsgResult = useRecoilValueLoadable(
    getEPSGJsonSelectorFamily(newEPSGCode),
  );

  return (
    <FullScreenModal>
      <ModalFrame title={"Add new custom CRS"}>
        <Column style={{ gap: spaceLarge }}>
          <p>
            Specify the wanted custom CRS using its{" "}
            <a target={"_blank"} href="https://epsg.io" rel="noreferrer">
              EPSG code
            </a>
            .
          </p>

          <EpsgInputColumn>
            <InputNumber
              placeholder={"Insert EPSG code"}
              type="number"
              value={newEPSGCode}
              onChange={(code) => {
                setNewEPSGCode(code);
              }}
              style={{ width: "14rem" }}
              validate={(n) => Number.isInteger(n) && 2000 < n && n <= 32766}
              validationMessage={`Needs to be integer between 2000 and 32766`}
            />
            {epsgResult.state === "hasValue" && !!epsgResult.getValue() && (
              <p>{epsgResult.getValue()?.name}</p>
            )}
            {epsgResult.state === "loading" && <p>Loading...</p>}
            {epsgResult.state === "hasError" && (
              <ErrorText>Unable to fetch name for EPSG</ErrorText>
            )}
          </EpsgInputColumn>
          <ButtonRow>
            <Button
              text="Cancel"
              buttonType="secondary"
              onClick={() => {
                setModalTypeOpen(undefined);
              }}
            />
            <Button
              disabled={
                epsgResult.state !== "hasValue" ||
                !epsgResult.getValue() ||
                !newEPSGCode
              }
              text="Store"
              buttonType="primary"
              onClick={() => {
                if (epsgResult.state !== "hasValue" || !newEPSGCode) return;

                const newEpsg = epsgResult.getValue();
                if (!newEpsg) return;

                const newCrs = {
                  code: newEPSGCode,
                  name: newEpsg.name,
                  proj4string: newEpsg.proj4string,
                } as CRSDefinition;

                setAvailableCRS((oldCRS) => [
                  ...oldCRS.filter((crs) => crs.code !== newCrs.code),
                  newCrs,
                ]);
                setSelectedCRS(newCrs);
                setModalTypeOpen(undefined);
              }}
            />
          </ButtonRow>
        </Column>
      </ModalFrame>
    </FullScreenModal>
  );
};

export const AddCustomCRSModal = () => {
  const modalTypeOpen = useRecoilValue(modalTypeOpenAtom);
  if (!modalTypeOpen || modalTypeOpen?.modalType !== AddCustomCRSModalType)
    return null;

  return <AddCustomCRSModalInner />;
};

const CustomCRSDropdown = ({
  onSelectCRS,
  kind,
  small,
  _size,
  disabled,
}: {
  onSelectCRS?: (newCRS: CRSDefinition) => void;
  kind?: DropdownStyle;
  small?: boolean;
  _size?: "small" | "large";
  disabled?: boolean;
}) => {
  const [modalTypeOpen, setModalTypeOpen] = useRecoilState(modalTypeOpenAtom);
  const availableCRS = useRecoilValue(availableCRSAtom);
  const [selectedCRS, setSelectedCRS] = useRecoilState(selectedCRSAtom);
  const crsItems: DropDownItem<number>[] = useMemo(
    () => availableCRS.map((crs) => ({ value: crs.code, name: crs.name })),
    [availableCRS],
  );
  useEffect(() => {
    if (!onSelectCRS) return;
    onSelectCRS(selectedCRS);
  }, [selectedCRS, onSelectCRS]);

  return (
    <Dropdown
      kind={kind}
      small={small}
      _size={_size}
      disabled={disabled}
      value={selectedCRS.code}
      onChange={(e) => {
        const crs = parseInt(e.target.value);
        if (crs === 0) {
          setModalTypeOpen({
            modalType: AddCustomCRSModalType,
            backTo: modalTypeOpen,
          });
          return;
        }
        const selectedCRS = availableCRS.find(
          (availableCrs) => availableCrs.code === crs,
        );
        if (!selectedCRS) return;
        setSelectedCRS(selectedCRS);
      }}
    >
      {crsItems.map((crsItem) => (
        <option key={crsItem.value} value={crsItem.value}>
          {crsItem.name}
        </option>
      ))}
      <option value={0}>+ New CRS</option>
    </Dropdown>
  );
};

export default CustomCRSDropdown;
