import styled from "styled-components";
import { RegularRaw, typography } from "../../styles/typography";
import { colors } from "styles/colors";

export const NamedTooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.2rem 1.2rem 0.4rem 1.2rem;
  ${RegularRaw};
  > input {
    z-index: 1;
  }
`;

export const NamedTooltipWrapper = styled.div`
  ${typography.label};
  color: ${colors.textDisabled};
  white-space: nowrap;
`;

export const ToolsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
`;
