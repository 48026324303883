import { z } from "zod";
import {
  UserAccessRole,
  _CollaboratorAccess,
  _MemberAccss,
  _OrganisationAccessRole,
  _UserAccess,
  _UserAccessRole,
} from "./user";

export const _OrganisationUser = z
  .object({
    org_role: _OrganisationAccessRole.nullish(),
    org_access: _OrganisationAccessRole.nullish(),
    email: z.string(),
    email_verified: z.boolean(),
    nickname: z.string(),
    picture: z.string().optional(),
    user_id: z.string(),
  })
  .merge(_CollaboratorAccess);
export type OrganisationUser = z.infer<typeof _OrganisationUser>;

const _CustomerCollaborator = z
  .object({
    access: _UserAccessRole.nullish(),
    email: z.string(),
    email_verified: z.boolean(),
    nickname: z.string(),
    picture: z.string().optional(),
    user_id: z.string(),
  })
  .merge(_CollaboratorAccess);
/** A collaborator  is invited in to a project in the team, but is not a part of the team. */
type CustomerCollaborator = z.infer<typeof _CustomerCollaborator>;

const _CustomerMember = z
  .object({
    access: _UserAccessRole.nullish(),
    email: z.string(),
    email_verified: z.boolean(),
    nickname: z.string(),
    picture: z.string().optional(),
    user_id: z.string(),
  })
  .merge(_MemberAccss);
type CustomerMember = z.infer<typeof _CustomerMember>;

export const _OrganisationInvite = z.object({
  organisationId: z.string(),
  expiration: z.number(),
  invitationId: z.string(),
  email: z.string(),
  organisationRole: z.string().nullish(),
  teamAccess: z.record(z.string(), _UserAccess),
});

export type OrganisationInvite = z.infer<typeof _OrganisationInvite>;

export const _CustomerPersona = z.union([
  _CustomerCollaborator,
  _CustomerMember,
]);

/** A customer persona is either a {@link CustomerMember} or a {@link CustomerCollaborator}. */
export type CustomerPersona = z.infer<typeof _CustomerPersona>;

export const collaboratorRole = (
  cg: CustomerCollaborator,
  projectId: string,
): undefined | UserAccessRole => cg.projects?.[projectId]?.role ?? undefined;

export type MemberListEntry = {
  name: string;
  role: UserAccessRole;
  id: string;
  type: "group" | "user";
  nodeId: string;
};

export const personaIsMember = (cm: CustomerPersona): cm is CustomerMember =>
  cm.role != null;

type UserMemberListEntry = MemberListEntry & {
  type: "user";
  email: string;
  picture: string | undefined;
};

type GroupMemberListEntry = MemberListEntry & {
  type: "group";
};

export function isGroupMemberListEntry(
  entry: MemberListEntry,
): entry is GroupMemberListEntry {
  return "type" in entry && entry["type"] === "group";
}

export function isUserMemberListEntry(
  entry: MemberListEntry,
): entry is UserMemberListEntry {
  return "type" in entry && entry["type"] === "user";
}
