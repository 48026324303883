import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useRecoilValueLoadable } from "recoil";
import { useTypedPath } from "../../state/pathParams";
import { getVisibleLayers } from "./LayerSettings/utils";
import { getLayerIdHash } from "./utils";
import { LAYER_URL_KEY, COLLECTION_URL_KEY } from "./const";

export default function useSyncVisibleLayerWithUrl() {
  const { projectId } = useTypedPath("projectId");
  const [searchParams, setSearchParams] = useSearchParams();

  const visibleLayersL = useRecoilValueLoadable(
    getVisibleLayers({ projectId }),
  );

  const { visibleLayers } = useMemo(
    () => ({
      visibleLayers: visibleLayersL.valueMaybe() ?? null,
    }),
    [visibleLayersL],
  );

  useEffect(() => {
    const oldLayerValue = searchParams.get(LAYER_URL_KEY);
    const oldCollectionValue = searchParams.get(COLLECTION_URL_KEY);

    if (visibleLayers) {
      const visibleLayerIdsHash = visibleLayers
        .map((l) => getLayerIdHash(l.id))
        .join("");

      if (visibleLayerIdsHash.length > 0) {
        searchParams.set(LAYER_URL_KEY, visibleLayerIdsHash);
      } else {
        searchParams.delete(LAYER_URL_KEY);
      }
    }

    const newLayerValue = searchParams.get(LAYER_URL_KEY);
    const newCollectionValue = searchParams.get(COLLECTION_URL_KEY);

    if (
      newLayerValue !== oldLayerValue ||
      newCollectionValue !== oldCollectionValue
    ) {
      setSearchParams(searchParams, {
        replace: true,
      });
    }
  }, [setSearchParams, visibleLayers, searchParams]);
}

export function visibleInUrl(layerId: string, urlKey: string) {
  const layerIdHash = getLayerIdHash(layerId);
  const sp = new URLSearchParams(window.location.search);
  const visibleLayerHash = sp.get(urlKey);

  if (!visibleLayerHash) return false;
  return visibleLayerHash.includes(layerIdHash);
}
