import { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { projectVersionAtom } from "../../state/project";
import { versionQueryName } from "../../hooks/useSetVersion";
import { branchIdSelector, projectIdSelector } from "../../state/pathParams";

const VersionSync = () => {
  const projectId = useRecoilValue(projectIdSelector);
  const branchId = useRecoilValue(branchIdSelector);
  const version = new URLSearchParams(window.location.search).get(
    versionQueryName,
  );
  const setProjectVersionAtom = useSetRecoilState(
    projectVersionAtom({ projectId, branchId }),
  );
  useEffect(() => {
    setProjectVersionAtom(version ? parseInt(version) : undefined);
  }, [version, setProjectVersionAtom, branchId]);
  return null;
};

export default VersionSync;
