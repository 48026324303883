/// <reference types="vite-plugin-svgr/client" />
import styled from "styled-components";
import { HelperVideo } from "../../HelperVideo/HelperVideo";
import { ReactNode, useCallback, useEffect, useRef } from "react";
import { StandardBox } from "../../../styles/boxes/Boxes";
import { RegularRaw, typography } from "../../../styles/typography";
import Close from "@icons/24/Close.svg?react";
import Button from "../../General/Button";
import { colors } from "../../../styles/colors";
import { Place } from "../../General/Anchor";
import { IconBtn } from "components/General/Icons";

const HintWrapper = styled(StandardBox)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  width: 30rem;
  transition: opacity 0.25s;

  ${RegularRaw};
`;

const HintTitle = styled.h4`
  ${typography.semiBold}
`;

const HintText = styled.div`
  ${typography.label}
`;

const CloseWrapper = styled.div`
  position: absolute;
  top: 0.1rem;
  right: 0.1rem;
  z-index: 4;
`;

const HintTextWrapper = styled.div`
  padding: 0 1rem;
`;

const GotItWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1rem;
`;

const ArrowDown = styled.div`
  content: "";
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  border-top: 6px solid ${colors.background};
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
`;

const CloseableHint = ({
  children,
  videosrc,
  timeToDissapearMS = 20000,
  closeCallback,
  gotItCallback,
  position,
  title,
}: {
  children: ReactNode;
  closeCallback: () => void;
  videosrc?: string;
  timeToDissapearMS?: number;
  gotItCallback?: () => void;
  position?: Place;
  title?: string;
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const close = useCallback(() => {
    if (!wrapperRef.current) return;
    wrapperRef.current!.style.opacity = "0";
    if (gotItCallback) gotItCallback();
    setTimeout(() => {
      closeCallback();
    }, 250);
  }, [closeCallback, gotItCallback, wrapperRef]);

  useEffect(() => {
    if (!wrapperRef.current) return;
    const removeToastId = setTimeout(close, timeToDissapearMS);
    return () => {
      clearTimeout(removeToastId);
    };
  }, [close, timeToDissapearMS]);

  useEffect(() => {
    return () => {
      closeCallback();
      if (gotItCallback) gotItCallback();
    };
  }, [gotItCallback, closeCallback]);

  return (
    <HintWrapper ref={wrapperRef}>
      <CloseWrapper>
        <IconBtn size="1.4rem" onClick={close}>
          <Close />
        </IconBtn>
      </CloseWrapper>
      {videosrc && <HelperVideo source={videosrc} />}
      <HintTextWrapper>
        <HintTitle>{title ? title : "Hint"} 💡</HintTitle>
        <HintText>{children}</HintText>
        {gotItCallback && (
          <GotItWrapper>
            <Button
              onClick={gotItCallback}
              text={"Got it"}
              buttonType={"primary"}
            />
          </GotItWrapper>
        )}
      </HintTextWrapper>
      {position === "top" && <ArrowDown />}
    </HintWrapper>
  );
};

export default CloseableHint;
