import React, { useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Mixpanel } from "mixpanel";
import AddIcon from "@icons/24/Add.svg";
import DatabaseIcon from "@icons/24/Database.svg";
import FolderAddIcon from "@icons/16/FolderAdd.svg";
import Spinner from "@icons/spinner/Spinner";
import { useRecoilValueDef } from "utils/recoil";
import { spacing2 } from "styles/space";
import { organisationIdSelector } from "state/pathParams";
import { MenuButton } from "components/General/MenuButton";
import { MenuDivider, MenuItem } from "components/General/Menu";
import { useDataLibraryLayersCrud } from "../useDataLibraryLayersCrud";
import {
  activePackageIdAtom,
  selectedOrganisationLayerIdsSelector,
} from "../state";
import { organisationLibraryDataPackageResourceState } from "../../state";
import Tooltip from "components/General/Tooltip";

const AddToPackageButton = ({
  layerIds,
  buttonText,
}: {
  layerIds: string[];
  buttonText?: string;
}) => {
  const organisationId = useRecoilValueDef(organisationIdSelector);
  const allPackages = useRecoilValue(
    organisationLibraryDataPackageResourceState({ organisationId }),
  );
  const setSelectedLayers = useSetRecoilState(
    selectedOrganisationLayerIdsSelector({ organisationId }),
  );
  const setActivePackageId = useSetRecoilState(activePackageIdAtom);
  const { addLayersToPackage, createPackage, creatingNewPackage } =
    useDataLibraryLayersCrud();

  const onAddToPackage = useCallback(
    (packageId: string) => {
      Mixpanel.track("Add library layer to package from MenuButton", {});
      const pkg = allPackages.find((p) => p.id === packageId);
      if (
        layerIds.length < 2 ||
        confirm(
          `Are you sure you want to add ${layerIds.length} layers to the package ${pkg?.name}?`,
        )
      ) {
        addLayersToPackage(organisationId, packageId, layerIds).then(() => {
          setSelectedLayers([]);
        });
      }
    },
    [
      addLayersToPackage,
      allPackages,
      layerIds,
      organisationId,
      setSelectedLayers,
    ],
  );

  const onCreateNewPackage = useCallback(async () => {
    Mixpanel.track(
      "Create organisation package and add layers to created package from MenuButton",
      {
        nrLayers: layerIds.length,
      },
    );
    const pkg = await createPackage(organisationId, { name: "Untitled" });
    if (pkg) {
      addLayersToPackage(organisationId, pkg.id, layerIds).then(() => {
        setSelectedLayers([]);
      });

      setActivePackageId(pkg.id);
    }
  }, [
    addLayersToPackage,
    createPackage,
    layerIds,
    organisationId,
    setActivePackageId,
    setSelectedLayers,
  ]);

  return (
    <MenuButton
      buttonText={buttonText}
      buttonStyle={{
        height: "unset",
        padding: `${spacing2} 0`,
      }}
      icon={creatingNewPackage ? <Spinner size="1rem" /> : <FolderAddIcon />}
      disabled={creatingNewPackage}
      title="Add to package"
    >
      {allPackages.length > 0 ? (
        allPackages.map((pkg) => {
          const alreadyAddedToPackage =
            pkg.layerIds.length > 0 &&
            layerIds.every((layerId) => pkg.layerIds.includes(layerId));

          return (
            <Tooltip
              key={pkg.id}
              text="The layer is already added to this package"
              disabled={!alreadyAddedToPackage}
              innerDivStyle={{
                width: "100%",
              }}
            >
              <MenuItem
                style={{
                  width: "100%",
                }}
                name={pkg.name}
                onClick={() => onAddToPackage(pkg.id)}
                disabled={alreadyAddedToPackage}
                icon={<DatabaseIcon />}
              />
            </Tooltip>
          );
        })
      ) : (
        <MenuItem name="No packages to show" />
      )}
      <MenuDivider />
      <MenuItem
        name="Create new package"
        onClick={onCreateNewPackage}
        icon={<AddIcon />}
      />
    </MenuButton>
  );
};

export default AddToPackageButton;
