/// <reference types="vite-plugin-svgr/client" />
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilCallback, useRecoilState, useRecoilValue } from "recoil";
import CableIcon from "@icons/24/ExportCable.svg?react";
import DeleteIcon from "@icons/24/Bin.svg?react";

import { projectIdSelector } from "../../../../state/pathParams";
import { initializeAndSet } from "../../../Comments/hooks/useReplyReactionCrud";

import useCableTypeCrud from "../../../../hooks/useCableTypeCrud";
import { CableType } from "../../../../services/cableTypeService";
import {
  getCableTypeUsage,
  cableTypeUsageAtomFamily,
} from "../../../../state/cable";
import { inReadOnlyModeSelector } from "../../../../state/project";
import { currentExportCableTypesSelector } from "../../../Cabling/Generate/state";
import { selectedMenuItemState } from "../../Shared/state";
import { CostUnit } from "../../../../types/financial";
import {
  EXPORT_AMPACITY_DEFAULT,
  EXPORT_CAPACITANCE_DEFAULT,
  EXPORT_POWER_RATING_MW_DEFAULT,
  EXPORT_REACTANCE_DEFAULT,
  EXPORT_RESISTANCE_DEFAULT,
  EXPORT_TYPE_DEFAULT,
  EXPORT_VOLTAGE_DEFAULT,
  SingleExportCable,
  megawattToWatt,
} from "components/ConfigurationModal/ExportCableSettings";
import { scream } from "utils/sentry";
import { useToast } from "hooks/useToast";
import { SettingsSubMenuProp } from "components/SettingsV2/Shared/types";
import { DefaultCosts } from "components/ConfigurationModal/Cost/constants";

export const EXPORT_CABLE_MENU_ID = "export-cables";

export default function useExportCableSettings() {
  const projectId = useRecoilValue(projectIdSelector) ?? "";
  const isReadOnly = useRecoilValue(inReadOnlyModeSelector);
  const { update, create, deleteCable } = useCableTypeCrud();
  const cables = useRecoilValue(currentExportCableTypesSelector);
  const { success } = useToast();
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);

  const [menuSelection, setMenuSelection] = useRecoilState(
    selectedMenuItemState({ menuId: EXPORT_CABLE_MENU_ID, projectId }),
  );
  useEffect(() => {
    if (!menuSelection && cables[0]) setMenuSelection(cables[0].id);
  }, [cables, menuSelection, setMenuSelection]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const addCable = useCallback(
    async (cable: Partial<CableType>) => {
      setIsLoading(true);
      setSaveInProgress(true);
      return create(cable)
        .then((res) => setMenuSelection(res.id))
        .finally(() => {
          setSaveInProgress(false);
          setIsLoading(false);
        });
    },
    [create, setMenuSelection, setSaveInProgress],
  );

  const _onDuplicate = useCallback(
    async (id: string) => {
      const cable = cables.find((cable) => cable.id === id);
      if (!cable) throw scream("Tried to duplicate non existing cable");

      setIsLoading(true);
      setSaveInProgress(true);
      const clone: Partial<CableType> = {
        ...cable,
        name: `${cable.name} copy`,
      };
      delete clone.id;

      return create(clone)
        .then((res) => setMenuSelection(res.id))
        .finally(() => {
          setSaveInProgress(false);
          setIsLoading(false);
        });
    },
    [cables, create, setMenuSelection, setSaveInProgress],
  );

  const _onDelete = useCallback(
    async (cableId: string) => {
      setIsLoading(true);
      const usage = await getCableTypeUsage(projectId, cableId);
      if (
        usage.length === 0 ||
        window.confirm(
          `Cable type is currently being used in ${usage.length} cables, are you sure you want to delete it?`,
        )
      ) {
        await deleteCable(cableId)
          .catch(() => {})
          .finally(() => setMenuSelection(undefined));
      }
      setIsLoading(false);
    },
    [deleteCable, projectId, setMenuSelection],
  );

  const _onUpdate = useRecoilCallback(
    ({ set, snapshot }) =>
      async (newCable: CableType) => {
        setIsLoading(true);

        const usage = await getCableTypeUsage(projectId, newCable.id);
        await initializeAndSet(
          snapshot,
          set,
          cableTypeUsageAtomFamily({
            nodeId: projectId,
            cableTypeId: newCable.id,
          }),
          usage,
        );

        if (
          usage.length === 0 ||
          window.confirm(
            `Cable type is currently being used in ${usage.length} cables, are you sure you want to update it?`,
          )
        ) {
          await update(newCable).catch(() => {});
          success("Saved");
        }
        setIsLoading(false);
      },
    [projectId, success, update],
  );

  const exportCableSubMenus: SettingsSubMenuProp[] = useMemo(() => {
    const exportCableList = cables.map((cable) => {
      return {
        id: cable.id,
        label: `${cable.name} (${cable.voltage}kV)`,
        loading: isLoading !== false,
        content: (
          <div style={{ height: "100%", position: "relative" }}>
            <SingleExportCable
              disabled={isReadOnly}
              key={cable.id}
              cable={cable}
              onSave={_onUpdate}
              isLoading={isLoading !== false}
            />
          </div>
        ),
        onDuplicate: _onDuplicate,
        dotMenuOptions: [
          {
            title: "Delete",
            onSelect: _onDelete,
            icon: <DeleteIcon />,
          },
        ],
      };
    });
    return [
      {
        items: exportCableList,
        title: "Project export cables",
        create: {
          type: "action",
          title: "New cable type",
          saveInProgress: saveInProgress,
          onCreate: () =>
            addCable({
              name: `Export cable ${cables.length + 1}`,
              powerRating: megawattToWatt(EXPORT_POWER_RATING_MW_DEFAULT),
              voltage: EXPORT_VOLTAGE_DEFAULT,
              resistance: EXPORT_RESISTANCE_DEFAULT,
              reactance: EXPORT_REACTANCE_DEFAULT,
              capacitance: EXPORT_CAPACITANCE_DEFAULT,
              ampacity: EXPORT_AMPACITY_DEFAULT,
              cost: DefaultCosts.exportCable.exportCable[
                CostUnit.millionEuroPerKM
              ],
              costUnit: CostUnit.millionEuroPerKM,
              exportCableType: EXPORT_TYPE_DEFAULT,
            }),
          disabled: isLoading,
        },
      },
    ];
  }, [
    cables,
    isLoading,
    isReadOnly,
    _onUpdate,
    _onDuplicate,
    _onDelete,
    addCable,
    saveInProgress,
  ]);

  const exportCableSettings = useMemo(() => {
    return {
      id: EXPORT_CABLE_MENU_ID,
      label: "Export cables",
      icon: <CableIcon />,
      submenus: exportCableSubMenus,
    };
  }, [exportCableSubMenus]);

  return exportCableSettings;
}
