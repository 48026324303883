import mapboxgl, {
  CustomLayerInterface,
  Map,
  MercatorCoordinate,
} from "mapbox-gl";
import * as THREE from "three";
import { Camera, Scene, WebGLRenderer } from "three";
import { MooringCoords } from "../../state/mooring";
import { dispose } from "../../utils/three";

export class ThreeDCables implements CustomLayerInterface {
  id: string;
  type: "custom";
  renderingMode: "2d" | "3d" | undefined;
  data: [[number, number], MooringCoords][];
  camera: Camera | undefined;
  scene: Scene | undefined;
  renderer: WebGLRenderer | undefined;
  map: Map | undefined;

  constructor(data: [[number, number], MooringCoords][], layerId: string) {
    this.id = layerId;
    this.type = "custom";
    this.renderingMode = "3d";
    this.data = data;
  }

  onAdd(map: Map, gl: WebGLRenderingContext) {
    this.map = map;
    this.camera = new THREE.Camera();
    this.scene = new THREE.Scene();

    map.setLayerZoomRange(this.id, 13, 24);

    this.addMooringObjects();

    this.renderer = new THREE.WebGLRenderer({
      canvas: map.getCanvas(),
      context: gl,
      antialias: true,
    });
    this.renderer.autoClear = false;
  }

  onRemove(_map: mapboxgl.Map, _gl: WebGLRenderingContext): void {
    this.scene?.traverse((o) => dispose(o));
    this.renderer?.dispose();
  }

  addMooringObjects() {
    for (const [lonlat, cableCoords] of this.data) {
      const modelAsMercatorCoordinate =
        mapboxgl.MercatorCoordinate.fromLngLat(lonlat);
      const scale = modelAsMercatorCoordinate.meterInMercatorCoordinateUnits();
      this.addCable(modelAsMercatorCoordinate, cableCoords, scale);
    }
  }

  updateData(newData: [[number, number], MooringCoords][]) {
    this.data = newData;
    this.clearScene();
    this.addMooringObjects();
  }

  clearScene() {
    if (this.scene) {
      while (this.scene.children.length) {
        this.scene.remove(this.scene.children[0]);
      }
    }
  }

  addCable(
    modelAsMercatorCoordinate: MercatorCoordinate,
    cableCoords: MooringCoords,
    scale: number,
  ) {
    const points: THREE.Vector3[] = [];
    for (let i = 0; i < cableCoords.x.length; i++) {
      points.push(
        new THREE.Vector3(cableCoords.x[i], cableCoords.y[i], cableCoords.z[i]),
      );
    }
    const lineMat = new THREE.LineBasicMaterial({ color: 0x000000 });
    const geometry = new THREE.BufferGeometry().setFromPoints(points);
    const line = new THREE.Line(geometry, lineMat);
    line.position.set(
      modelAsMercatorCoordinate.x,
      modelAsMercatorCoordinate.y,
      modelAsMercatorCoordinate.z ?? 0,
    );
    line.scale.set(scale, -scale, scale);
    this.scene?.add(line);
  }

  render(gl: WebGLRenderingContext, matrix: number[]) {
    if (!this.camera || !this.renderer || !this.scene || !this.map) return;
    const m = new THREE.Matrix4().fromArray(matrix);
    this.camera.projectionMatrix = m;
    this.renderer.resetState();
    this.renderer.render(this.scene, this.camera);
    this.map.triggerRepaint();
  }
}
