/// <reference types="vite-plugin-svgr/client" />
import styled from "styled-components";
import { colors } from "styles/colors";
import { typography } from "styles/typography";
import { borderRadiusMedium, spacing2, spacing4 } from "styles/space";
import { ReactElement, ReactNode } from "react";
import { ReferenceType } from "@floating-ui/react";

const IconStyler = styled.div<{
  disabled?: boolean;
  active?: boolean;
  $stroke?: boolean;
}>`
  display: flex;
  cursor: pointer;
  border-radius: ${borderRadiusMedium};
  padding: ${spacing2} ${spacing4};

  svg {
    width: 2.4rem;
    height: 2.4rem;
    overflow: initial; // Icons that are slightly larger than the viewPort due to weird scaling should not be clipped.

    path,
    line {
      ${(p) => {
        if (p.$stroke) {
          if (p.disabled) return `stroke: ${colors.iconDisabled};`;

          if (p.active) return `stroke: ${colors.iconSelected};`;
          return `stroke: ${colors.iconBrand};`;
        } else {
          if (p.disabled) return `fill: ${colors.iconDisabled};`;

          if (p.active) return `fill: ${colors.iconSelected};`;
          return `fill: ${colors.iconBrand};`;
        }
      }}}
    }
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  background-color: ${(p) => {
    if (p.disabled) return colors.backgroundDefault;
    if (p.active) return colors.surfaceSelectedLight;
    return "initial";
  }};

  :hover {
    background-color: ${(p) => {
      if (p.disabled) return colors.backgroundDefault;
      if (p.active) return colors.surfaceSelectedHover;
      return colors.surfaceHover;
    }};
  }


`;

const SideBarButtonWrapper = styled.div<{
  active?: boolean;
  disabled?: boolean;
}>`
  max-width: var(--side-bars-width);
  min-width: 5.7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  background-color: ${colors.white};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  color: ${(p) => {
    if (p.disabled) return colors.textDisabled;
    if (p.active) return colors.textSelected;
    return "inherit";
  }};

  ${({ active }) => (active ? typography.sub4 : typography.graphics)};
`;

export default function SideBarIconBtn({
  title,
  icon,
  onClick,
  active,
  disabled,
  children,
  style,
  id,
  innerRef,
  stroke,
}: {
  title?: string;
  icon: ReactElement;
  onClick: (e?: any) => void;
  active?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  style?: React.CSSProperties;
  id?: string;
  innerRef?: ((node: ReferenceType | null) => void) &
    ((node: ReferenceType | null) => void);
  stroke?: boolean;
}) {
  return (
    <SideBarButtonWrapper
      id={id}
      style={style}
      active={active}
      onClick={onClick}
      disabled={disabled}
      ref={innerRef}
    >
      <IconStyler disabled={disabled} active={active} $stroke={stroke}>
        {icon}
      </IconStyler>
      {title && title}
      {children}
    </SideBarButtonWrapper>
  );
}
