import { Subtitle } from "components/General/GeneralSideModals.style";
import { viewshedBucketsAtom } from "components/RightSide/InfoModal/ProjectFeatureInfoModal/ViewshedAnalysis";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import Item, { ItemProps } from "./Item";

const ItemsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

const LegendInner = ({ items }: { items: ItemProps[] }) => {
  return (
    <>
      <Subtitle>Visible turbines</Subtitle>
      <ItemsList>
        {items.map((item) => (
          <Item key={item.color} color={item.color} label={item.label} />
        ))}
      </ItemsList>
    </>
  );
};

const Legend = () => {
  const buckets = useRecoilValue(viewshedBucketsAtom);
  const items = buckets.map((bucket) => ({
    label: `${bucket.from} - ${bucket.to}`,
    color: bucket.color,
  }));

  return <LegendInner items={items} />;
};

export default Legend;
