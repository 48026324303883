import { RenderTurbineCableZones } from "components/Mapbox/TurbineCableZone";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { getSelectedSubstations } from "state/cable";
import { editFeaturesAtom, mapRefAtom } from "state/map";
import { projectFeatureMap } from "state/projectLayers";
import { getSelectedTurbines } from "state/turbines";
import { SubstationFeature, TurbineFeature } from "types/feature";
import { isCable, isSubstation, isTurbine } from "utils/predicates";

/**
 * Render the cable free sectors for the selected turbines and substations.
 */
export const CableFreeSectorFromSelection = () => {
  const map = useRecoilValue(mapRefAtom);
  const turbines = useRecoilValue(getSelectedTurbines);
  const substations = useRecoilValue(getSelectedSubstations);

  const features = useMemo(
    () =>
      (turbines as (TurbineFeature | SubstationFeature)[]).concat(substations),
    [substations, turbines],
  );

  if (!map) return null;
  return <RenderTurbineCableZones map={map} features={features} />;
};

export const CableFreeSectorWhenEditing = () => {
  const map = useRecoilValue(mapRefAtom);
  const editFeatures = useRecoilValue(editFeaturesAtom);
  const featureMap = useRecoilValue(projectFeatureMap);

  const features = useMemo(() => {
    const adjacent: (TurbineFeature | SubstationFeature)[] = [];
    for (const id of editFeatures) {
      const f = featureMap.get(id);
      if (!f) continue;
      if (isCable(f)) {
        const from = featureMap.get(f.properties.fromId);
        if (isTurbine(from) || isSubstation(from)) adjacent.push(from);
        const to = featureMap.get(f.properties.toId);
        if (isTurbine(to) || isSubstation(to)) adjacent.push(to);
      }
    }
    return adjacent;
  }, [editFeatures, featureMap]);

  if (!map || !features.length) return null;
  return <RenderTurbineCableZones map={map} features={features} />;
};
