import { z } from "zod";
import * as spec from "api/dataLayerLibrary";

export const _NewLayersLibraryPackage = spec.components.schemas.DataPackagePost;
export type NewLayersLibraryPackage = z.infer<typeof _NewLayersLibraryPackage>;

export const _DataLibraryLayer = spec.components.schemas.Layer;
export type DataLibraryLayer = z.infer<typeof _DataLibraryLayer>;

export const _DataLibraryPackage = spec.components.schemas.DataPackage;
export type DataLibraryPackage = z.infer<typeof _DataLibraryPackage>;

export const _ProjectUsage = z.string().array();
export type ProjectUsage = z.infer<typeof _ProjectUsage>;

export const _ProjectUsages = z.record(z.string(), _ProjectUsage);
export type ProjectUsages = z.infer<typeof _ProjectUsages>;
