import { useEffect } from "react";
import { selectorFamily, useRecoilValue } from "recoil";
import { getDivisionFeaturesInBranchSelectorFamily } from "../../state/division";
import * as turf from "@turf/turf";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { WarningProps } from "./ValidationWarnings";
import { getParkFeatureSelectorFamily } from "state/park";
import { branchIdSelector } from "state/pathParams";

export const zoneOverlapWarningSelectorFamily = selectorFamily<
  | {
      type: ValidationWarningTypes.OverlappingSubAreas;
      featureIds: string[];
      parkId: string;
    }
  | undefined,
  {
    parkId: string;
    branchId: string;
  }
>({
  key: "zoneOverlapWarningSelectorFamily",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const park = get(getParkFeatureSelectorFamily({ parkId }));
      if (!park) return;

      const { subAreas } = get(
        getDivisionFeaturesInBranchSelectorFamily({ parkId, branchId }),
      );

      const overlapIds: string[] = [];
      for (let i = 0; i < subAreas.length; i++) {
        const a = subAreas[i];
        for (let j = i + 1; j < subAreas.length; j++) {
          const b = subAreas[j];
          const result = turf.intersect(a, b);
          if (result) {
            overlapIds.push(a.id);
            overlapIds.push(b.id);
          }
        }
      }

      return overlapIds.length === 0
        ? undefined
        : {
            type: ValidationWarningTypes.OverlappingSubAreas,
            featureIds: overlapIds,
            parkId: parkId,
          };
    },
});

export const OverlappingZones = ({
  parkId,
  warn,
  remove,
}: { parkId: string } & WarningProps) => {
  const branchId = useRecoilValue(branchIdSelector);
  const overlapWarning = useRecoilValue(
    zoneOverlapWarningSelectorFamily({ parkId, branchId: branchId ?? "" }),
  );

  useEffect(() => {
    if (!overlapWarning) return;
    warn(overlapWarning);
    return () => {
      remove(ValidationWarningTypes.OverlappingSubAreas);
    };
  }, [overlapWarning, remove, warn]);

  return null;
};
