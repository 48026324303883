import {
  SemiCentralType,
  SemiPeripheralType,
  SparType,
  MonopileType,
  DetailedMonopileType,
  FoundationType,
  FloaterType,
  FixedType,
  JacketType,
} from "../../../../types/foundations";

export const isSemiCentral = (
  t: FoundationType | undefined,
): t is SemiCentralType => t?.type === "semi_central";
export const isSemiPeripheral = (
  t: FoundationType | undefined,
): t is SemiPeripheralType => t?.type === "semi_peripheral";
export const isSpar = (t: FoundationType | undefined): t is SparType =>
  t?.type === "spar";
export const isFloater = (t: FoundationType | undefined): t is FloaterType =>
  t?.type === "semi_central" ||
  t?.type === "semi_peripheral" ||
  t?.type === "spar";
export const isFixed = (t: FoundationType | undefined): t is FixedType =>
  t?.type === "monopile" ||
  t?.type === "jacket" ||
  t?.type === "detailed_monopile";
export const isJacket = (t: FoundationType | undefined): t is JacketType =>
  t?.type === "jacket";
export const isMonopile = (t: FoundationType | undefined): t is MonopileType =>
  t?.type === "monopile";
export const isDetailedMonopile = (
  t: FoundationType | undefined,
): t is DetailedMonopileType => t?.type === "detailed_monopile";

export const isSteelFoundation = (t: FoundationType): boolean =>
  t.material === "Steel";
export const isConcreteFoundation = (t: FoundationType): boolean =>
  t.material === "Concrete";

export const valueRounding = (value: number, precision: number) => {
  if (precision >= 1) {
    const roundedValue = Math.round(value / precision) * precision;
    return roundedValue;
  } else {
    const decimalPoints = precision.toString().split(".")[1]?.length ?? 0;
    return parseFloat(value.toFixed(decimalPoints));
  }
};
