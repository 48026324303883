/// <reference types="vite-plugin-svgr/client" />
import {
  FormEvent,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { projectIdSelector, useTypedPath } from "../../../state/pathParams";
import SendIcon from "@icons/24/Send.svg?react";
import Bin from "@icons/24/Bin.svg?react";
import Alarm from "@icons/24/Alarm.svg?react";
import { colors } from "../../../styles/colors";
import { dateToDateTime } from "../../../utils/utils";
import Button from "../../General/Button";
import CommentReactions from "../Reactions/CommentReactions";
import ReplyReactions from "../Reactions/ReplyReactions";
import {
  filteredThreadsSelectorFamily,
  followThreadsAtomFamily,
  newThreadAtomFamily,
  replySaveInProgressState,
  selectedThreadAtomFamily,
  threadRepliesAtomFamily,
} from "../state";
import { Reply, Thread } from "../types";
import { useReplyCrud } from "../hooks/useReplyCrud";
import { NewCommentModalInner } from "./NewCommentModal";
import UserInfo, { UserInfoLoading } from "./UserInfo";
import TextareaWithMentions from "./TextareaWithMentions";
import Toggle, { ToggleSize } from "../../General/Toggle";
import TextWithMentions from "./TextWithMentions";
import { loggedInUserSelector } from "../../../state/user";
import { SkeletonRound, SkeletonText } from "../../Loading/Skeleton";
import { useReadComments } from "../UnreadComments/useReadComments";
import PreviewModal from "./PreviewModal";
import { getCurrentTokenUserId } from "../../../state/global";
import Like from "../../General/Like";
import { useResolveThreadCrud } from "../hooks/useResolveThreadCrud";
import { useThreadCrud } from "../hooks/useThreadCrud";
import {
  Divider,
  ButtonContainer,
  HeaderContainer,
  Container,
  MentionTextWrapper,
  ReplyWrapper,
  DeleteWrapper,
  Form,
  AlarmIconWrapper,
} from "./style";
import { useFollowThreadCrud } from "../hooks/useFollowThreadCrud";
import { DotMenu } from "../../General/MenuButton";
import { MenuItem } from "../../General/Menu";
import { Column, Frame, Row } from "../../General/Layout";

export default function MapModal({ nodeId }: { nodeId: string }) {
  const { projectId, branchId } = useTypedPath("projectId", "branchId");
  const { threads, resolvedThreads } = useRecoilValue(
    filteredThreadsSelectorFamily({ nodeId, branchId }),
  );

  const combined = useMemo(
    () => threads.concat(resolvedThreads),
    [resolvedThreads, threads],
  );

  const newThreadOpen = useRecoilValue(newThreadAtomFamily({ projectId }));

  if (!nodeId) return null;

  return (
    <>
      <div>
        {newThreadOpen && (
          <NewCommentModalInner nodeId={nodeId} newThreadMeta={newThreadOpen} />
        )}
      </div>
      {combined.map((t) => (
        <div key={t.threadId}>
          <CommentAndRepliesModalInner thread={t} nodeId={nodeId} />
        </div>
      ))}
    </>
  );
}

function CommentAndRepliesModalInner({
  thread,
  nodeId,
}: {
  thread: Thread;
  nodeId: string;
}) {
  const projectId = useRecoilValue(projectIdSelector);

  const [text, setText] = useState("");
  const ref = useRef<HTMLTextAreaElement>(null);

  const [selectedThreadId, setSelectedThreadId] = useRecoilState(
    selectedThreadAtomFamily({
      nodeId: thread.nodeId,
    }),
  );

  useEffect(() => {
    if (selectedThreadId === thread.threadId) {
      setTimeout(() => ref.current?.focus(), 10);
    }
  });

  const { post } = useReplyCrud();

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      post(thread.threadId, { message: text });
      setText("");
    },
    [post, text, thread.threadId],
  );

  const handleCancel = useCallback(() => {
    setText("");
    setSelectedThreadId(undefined);
  }, [setSelectedThreadId]);

  if (!projectId) return null;

  return (
    <PreviewModal currentProjectId={projectId} thread={thread} nodeId={nodeId}>
      <Frame style={{ boxShadow: "none", overflow: "visible" }}>
        <CommentHeader thread={thread} />
        <Divider />
        <Suspense
          fallback={Array.from(Array(thread.replies).keys()).map((e, i) => (
            <CommentRowLoading key={i} />
          ))}
        >
          <CommentAndRepliesList thread={thread} nodeId={nodeId} />
        </Suspense>
        <Divider />
        <Form onSubmit={handleSubmit}>
          <TextareaWithMentions
            text={text}
            onChange={setText}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            textAreaRef={ref}
            nodeId={nodeId}
          />
          <ButtonContainer>
            <Button
              type="submit"
              icon={<SendIcon />}
              buttonType="text"
              disabled={text.length === 0}
            />
          </ButtonContainer>
        </Form>
      </Frame>
    </PreviewModal>
  );
}

function CommentRowLoading() {
  return (
    <Column style={{ padding: "0.6rem 1.2rem", gap: "0.8rem" }}>
      <Row>
        <SkeletonRound size={24} />
        <SkeletonText />
      </Row>
      <SkeletonText
        style={{ marginLeft: "3.2rem", width: "calc(100% - 3.2rem)" }}
      />
    </Column>
  );
}

function CommentHeader({ thread }: { thread: Thread }) {
  const { remove } = useThreadCrud();

  const { put } = useResolveThreadCrud();
  const { put: putFollow } = useFollowThreadCrud();
  const follows = useRecoilValue(
    followThreadsAtomFamily({
      nodeId: thread.nodeId,
      branchId: thread.branchId,
    }),
  );
  const isFollowed = useMemo(
    () => follows.some((f) => f.threadId === thread.threadId && f.follow),
    [follows, thread.threadId],
  );

  const onChange = useCallback(async () => {
    const newValue = !thread.resolved;
    put(thread.threadId, newValue);
  }, [put, thread.resolved, thread.threadId]);

  return (
    <HeaderContainer>
      <Row>
        <Toggle
          checked={thread.resolved}
          onChange={onChange}
          size={ToggleSize.SMALL}
        />
        <p>{thread.resolved ? "Unresolve" : "Resolve"}</p>
      </Row>
      <Row>
        <AlarmIconWrapper
          active={isFollowed}
          onClick={() => putFollow(thread.threadId, !isFollowed)}
        >
          <Alarm />
        </AlarmIconWrapper>
        <DotMenu>
          <MenuItem
            name={isFollowed ? "Unfollow thread" : "Follow thread"}
            onClick={() => {
              putFollow(thread.threadId, !isFollowed);
            }}
            icon={<Alarm />}
          />
          <MenuItem
            name={"Delete thread"}
            onClick={() => {
              if (
                window.confirm(
                  "This will permanently delete the comment with all replies and reactions. Are you sure?",
                )
              ) {
                remove(thread.threadId);
              }
            }}
            icon={<Bin />}
          />
        </DotMenu>
      </Row>
    </HeaderContainer>
  );
}

function CommentAndRepliesList({
  thread,
  nodeId,
}: {
  thread: Thread;
  nodeId: string;
}) {
  const { branchId } = useTypedPath("branchId");
  const { setRead } = useReadComments();
  const replySaveInProgress = useRecoilValue(replySaveInProgressState);
  const selectedThreadId = useRecoilValue(selectedThreadAtomFamily({ nodeId }));

  const replies = useRecoilValue(
    threadRepliesAtomFamily({
      nodeId,
      branchId,
      threadId: thread.threadId,
    }),
  );
  useEffect(() => {
    if (selectedThreadId === thread.threadId) {
      setRead(
        thread,
        replies.map((r) => r.replyId),
      );
    }
  }, [replies, selectedThreadId, setRead, thread]);

  const timeString = useMemo(() => {
    return dateToDateTime(new Date(thread.threadTimestamp));
  }, [thread.threadTimestamp]);

  return (
    <Container>
      <Column>
        <Row style={{ justifyContent: "space-between" }}>
          <Suspense fallback={<UserInfoLoading />}>
            <UserInfo userId={thread.userId} />
          </Suspense>
          <p style={{ color: colors.secondaryText }}>{timeString}</p>
        </Row>
        <MentionTextWrapper style={{ justifyContent: "space-between" }}>
          <TextWithMentions message={thread.message} />
          <CommentReactions thread={thread} nodeId={nodeId} />
        </MentionTextWrapper>
      </Column>
      {replies.map((r) => (
        <ReplyElement key={r.replyId} reply={r} />
      ))}
      {replySaveInProgress?.threadId === thread.threadId && (
        <LoadingReplyElement
          message={replySaveInProgress.message}
          userId={getCurrentTokenUserId()}
        />
      )}
    </Container>
  );
}

function LoadingReplyElement({
  message,
  userId,
}: {
  message: string;
  userId: string | undefined;
}) {
  return (
    <ReplyWrapper style={{ opacity: 0.6, pointerEvents: "none" }}>
      <Row style={{ justifyContent: "space-between" }}>
        {userId ? <UserInfo userId={userId} /> : <UserInfoLoading />}
      </Row>
      <MentionTextWrapper style={{ justifyContent: "space-between" }}>
        <TextWithMentions message={message} />
        <Like
          like={false}
          onClick={() => {}}
          reactionList={null}
          totalLikes={0}
        />
      </MentionTextWrapper>
    </ReplyWrapper>
  );
}
function ReplyElement({ reply }: { reply: Reply }) {
  const timeString = useMemo(() => {
    return dateToDateTime(new Date(reply.replyTimestamp));
  }, [reply.replyTimestamp]);
  const { projectId } = useTypedPath("projectId");

  return (
    <ReplyWrapper>
      <Row style={{ justifyContent: "space-between" }}>
        <UserInfo userId={reply.userId} />
        <Row>
          <DeleteReply reply={reply} />
          <p style={{ margin: 0, color: colors.primaryDisabled }}>
            {timeString}
          </p>
        </Row>
      </Row>
      <MentionTextWrapper style={{ justifyContent: "space-between" }}>
        <TextWithMentions message={reply.message} />
        {projectId && <ReplyReactions reply={reply} nodeId={projectId} />}
      </MentionTextWrapper>
    </ReplyWrapper>
  );
}

function DeleteReply({ reply }: { reply: Reply }) {
  const currentUserId = useRecoilValue(loggedInUserSelector)?.user_id;
  const { remove } = useReplyCrud();

  const showDelete = useMemo(
    () => currentUserId && reply.userId === currentUserId,
    [currentUserId, reply.userId],
  );

  if (!showDelete) return null;

  return (
    <DeleteWrapper>
      <Bin onClick={() => remove(reply.threadId, reply.replyId)} />
    </DeleteWrapper>
  );
}
