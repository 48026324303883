import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useRecoilValue } from "recoil";
import { toPng } from "html-to-image";
import styled from "styled-components";
import Button from "../components/General/Button";
import Checkbox from "../components/General/Checkbox";
import Tooltip from "../components/General/Tooltip";
import { StyledModalBase } from "../components/RightSide/InfoModal/InfoModal.style";
import Camera from "@icons/24/Camera.svg?react";
import CloseIcon from "@icons/24/Close.svg?react";
import { Mixpanel } from "../mixpanel";
import {
  customerProjectAtomFamily,
  getBranchSelectorFamily,
} from "../state/timeline";
import { spacing4 } from "styles/space";
import ScreenCaptureMode from "./ScreenCaptureMode";
import { Column } from "../components/General/Layout";
import {
  branchIdSelector,
  parkIdSelector,
  projectIdSelector,
  useTypedPath,
} from "../state/pathParams";
import { IconBtn } from "components/General/Icons";
import { ThreeCore } from "components/ViewToPark/ThreeContext/useCreateThreeCore";
import { trackProjectControlMenu } from "components/ProjectControl/MenuTracking";
import SearchWrapper from "components/Search/SearchWrapper";
import { TopRightModeActiveAtom } from "components/RightSide/InfoModal/ProjectFeatureInfoModal/state";
import { TopRightMenuOptions } from "@constants/canvas";
import useBooleanState from "hooks/useBooleanState";

const Container = styled.div`
  position: relative;
`;

export const getDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const takePrintScreen = (
  resolution: [number, number],
  aspectRatio: number,
  threeCore: ThreeCore,
  name: string,
) => {
  const renderer = threeCore.renderer;
  const canvas = renderer.domElement;
  const oldSize = [canvas.clientWidth, canvas.clientHeight];
  const oldPixelRatio = renderer.getPixelRatio();
  const oldAspect = threeCore.camera.aspect;

  renderer.setPixelRatio(1);
  threeCore.camera.aspect = aspectRatio;
  threeCore.camera.updateProjectionMatrix();
  threeCore.renderer.setSize(resolution[0], resolution[1]);
  renderer.render(threeCore.scene, threeCore.camera);

  var link = document.createElement("a");
  link.download = `${name}.png`;
  link.href = canvas.toDataURL();
  link.click();

  renderer.setSize(oldSize[0], oldSize[1]);
  renderer.setPixelRatio(oldPixelRatio);
  threeCore.camera.aspect = oldAspect;
  threeCore.camera.updateProjectionMatrix();
  renderer.render(threeCore.scene, threeCore.camera);
};

export default function ScreenCaptureWrapper() {
  const projectId = useRecoilValue(projectIdSelector);
  const branchId = useRecoilValue(branchIdSelector);
  const parkId = useRecoilValue(parkIdSelector);
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleClick = useCallback(() => {
    if (!open)
      trackProjectControlMenu("Screenshot", {
        projectId,
        branchId,
        parkId,
      });
    setOpen(!open);
  }, [setOpen, branchId, open, parkId, projectId]);

  return (
    <Container ref={ref}>
      <SearchWrapper
        title="Screenshot"
        id="search-screenshot"
        tags={["picture", "camera", "download", "export", "snapshot"]}
        icon={<Camera />}
        onSelect={() => setOpen(true)}
      >
        <Tooltip position="bottom" text={"Screenshot"}>
          <IconBtn active={open} onClick={handleClick}>
            <Camera />
          </IconBtn>
        </Tooltip>
        {open && <ScreenCaptureModeAnalysis onClose={() => setOpen(false)} />}
      </SearchWrapper>
    </Container>
  );
}

const FloatingWrapper = styled(StyledModalBase)`
  position: fixed;
  top: calc(
    calc(var(--top-bar-menu-height) + var(--branch-tab-bar-height)) +
      ${spacing4}
  );
  right: ${spacing4};
  flex-direction: column;
  gap: 1.6rem;
  z-index: 4;
`;

const FloatingWrapperPortal = ({ children }: React.PropsWithChildren) => {
  const element = useMemo(() => document.createElement("div"), []);

  useEffect(() => {
    document.body.appendChild(element);
    return () => {
      document.body.removeChild(element);
    };
  }, [element]);

  return ReactDOM.createPortal(
    <FloatingWrapper>{children}</FloatingWrapper>,
    element,
  );
};

export const ScreenCaptureModeAnalysis = ({ onClose }: { onClose(): void }) => {
  const { projectId, branchId } = useTypedPath("projectId", "branchId");
  const parkId = useRecoilValue(parkIdSelector);
  const ref = useRef<HTMLDivElement>(null);

  const projectMeta = useRecoilValue(
    customerProjectAtomFamily({ nodeId: projectId }),
  );

  const currentBranchMeta = useRecoilValue(
    getBranchSelectorFamily({ projectId, branchId }),
  );

  const mode = useRecoilValue(TopRightModeActiveAtom);
  const inViewshedAnalysis = mode === TopRightMenuOptions.viewshed;

  const [show, setShow] = useState(false);
  const [showProjectName, toggleShowProjectName] = useBooleanState(false);
  const [showBranchName, toggleShowBranchName] = useBooleanState(false);
  const [showFeatureLegend, toggleShowFeatureLegend] = useBooleanState(false);
  const [showViewshedLegend, toggleViewshedLegend] = useBooleanState(false);

  const onButtonClick = useCallback(() => {
    const curr = ref.current;
    if (curr === null || !projectMeta || !currentBranchMeta) {
      return;
    }
    setShow(true);
    Mixpanel.track("Downloaded screenshot", {
      projectId,
      branchId,
      parkId,
    });

    // give component on render cycle to prepare image
    setTimeout(() => {
      toPng(curr, { cacheBust: true })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = `${projectMeta.name}-${currentBranchMeta.title}.png`;
          link.href = dataUrl;
          link.click();
          setShow(false);
        })
        .catch((err) => {
          console.log(err);
          setShow(false);
        });
    }, 1);
  }, [branchId, currentBranchMeta, parkId, projectId, projectMeta]);

  return (
    <>
      <FloatingWrapperPortal>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3 style={{ margin: 0 }}>Screenshot</h3>
          <IconBtn onClick={onClose} size="1.5rem">
            <CloseIcon />
          </IconBtn>
        </div>
        <Column>
          <Checkbox
            checked={true}
            onChange={() => {}}
            label={"Current map with features"}
            disabled={true}
            labelPlacement="after"
          />
          <Checkbox
            checked={showProjectName}
            onChange={toggleShowProjectName}
            label={"Project name"}
            labelPlacement="after"
          />
          <Checkbox
            checked={showBranchName}
            onChange={toggleShowBranchName}
            label={"Branch name"}
            labelPlacement="after"
          />
          {inViewshedAnalysis && (
            <Checkbox
              checked={showViewshedLegend}
              onChange={toggleViewshedLegend}
              label={"Viewshed legend"}
              labelPlacement="after"
            />
          )}
          <Checkbox
            checked={showFeatureLegend}
            onChange={toggleShowFeatureLegend}
            label={"Feature legend"}
            labelPlacement="after"
          />
          <Button
            style={{ marginTop: "1rem" }}
            onClick={onButtonClick}
            text="Download screenshot"
            buttonType="primary"
          />
        </Column>
      </FloatingWrapperPortal>
      <ScreenCaptureMode
        showProjectName={showProjectName}
        showBranchName={showBranchName}
        showFeatureLegend={showFeatureLegend}
        showViewshedLegend={showViewshedLegend}
        refProp={ref}
        show={show}
      />
    </>
  );
};
