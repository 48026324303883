import {
  turbineWakeSymbolSourceId,
  turbineWakeSymbolLayerId,
  getBeforeLayer,
} from "components/Mapbox/utils";
import { displayLabelPropertyName } from "@constants/canvas";
import { CirclePaint, SymbolLayer } from "mapbox-gl";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { mapRefAtom } from "state/map";
import { isDefined } from "utils/predicates";
import { ProdId, getAnalysis, getTurbines } from "./state";
import { showWakeLabelsAtom } from "state/windStatistics";
import { zip } from "utils/utils";
import Point from "components/MapFeatures/Point";
import {
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";

const turbineWakeSymbolLayer: Omit<SymbolLayer, "id" | "source"> = {
  type: "symbol",
  minzoom: 9,
  layout: {
    "text-field": ["get", "value"],
    "text-offset": [0, 2],
    "text-size": [
      "interpolate",
      ["linear"],
      ["zoom"],
      // zoom is 11 (or less) -> text size will be 9px
      11,
      8,
      // zoom is 14 (or greater) -> text size will be 14px
      14,
      14,
    ],
    "text-anchor": "left",
  },
  filter: ["boolean", ["get", displayLabelPropertyName], true],
};

const symbolPaint: CirclePaint = {
  "circle-radius": 6,
  "circle-opacity": 0,
};

export const WakeLabels = ErrorBoundaryWrapper(
  ({ id }: { id: ProdId }) => {
    const map = useRecoilValue(mapRefAtom);
    const showWakeLabels = useRecoilValue(showWakeLabelsAtom);

    const analysis = useRecoilValue(getAnalysis(id));
    const turbines = useRecoilValue(getTurbines(id));
    const wakeLossPerTurbine = analysis?.stats?.totalWakeLossPerTurbine;

    const labelFeatures = useMemo(() => {
      if (!map || !wakeLossPerTurbine) return [];
      return zip(turbines, wakeLossPerTurbine)
        .map(([turbine, loss]) => {
          if (loss === undefined) return undefined;
          return {
            ...turbine,
            properties: {
              value: `${(Math.round(loss * 1000) / 10).toFixed(1)} %`,
            },
            id: `${turbine.id}-value-label`,
          };
        })
        .filter(isDefined);
    }, [map, turbines, wakeLossPerTurbine]);

    if (!map || !showWakeLabels) return null;

    return (
      <Point
        features={labelFeatures}
        symbols={turbineWakeSymbolLayer}
        sourceId={turbineWakeSymbolSourceId}
        layerId={turbineWakeSymbolLayerId}
        map={map}
        paint={symbolPaint}
        beforeLayer={getBeforeLayer(map, turbineWakeSymbolLayerId)}
      />
    );
  },
  () => null,
  ScreamOnError,
);
