import Button from "components/General/Button";
import { InitialsImageRound } from "components/UserImage/UserImageRound";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useTypedPath } from "state/pathParams";
import { organisationCableResourceState } from "../state";
import {
  ContentColumn,
  ContentHeader,
  ContentHeaderButtonsContainer,
  ContentHeaderRow,
  MainRow,
  ResourceColumn,
  ResourceItem,
  ResourceName,
  TabContainer,
} from "../style";
import AddIcon from "@icons/24/Add.svg?react";
import Trashcan from "@icons/24/Bin.svg?react";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";
import { useEffect, useMemo, useRef, useState } from "react";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { Column } from "components/General/Layout";
import { DotMenu } from "components/General/MenuButton";
import { MenuItem } from "components/General/Menu";
import OpenMoreInfo from "components/General/MoreInfoButton";
import useOrgCableCrud from "./useOrgCableCrud";
import { CableType } from "services/cableTypeService";
import { SingleCable } from "components/ConfigurationModal/CableSettings";
import { DEFAULT_CABLE } from "types/cables";
import { SkeletonBlock } from "components/Loading/Skeleton";

export default function CableTab() {
  const { organisationId } = useTypedPath("organisationId");
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [deleteInProgress, setDeleteInProgress] = useState<
    string | undefined
  >();
  const cables = useRecoilValue(
    organisationCableResourceState({ organisationId }),
  );
  const [selectedItemId, setSelectedItemId] = useState<string | undefined>(
    undefined,
  );
  const setContent = useSetRecoilState(
    organisationRightSideModal(organisationId),
  );

  const isLoading = useMemo(
    () => saveInProgress || !!deleteInProgress,
    [saveInProgress, deleteInProgress],
  );

  useEffect(() => {
    if (!selectedItemId && cables.length > 0 && !saveInProgress) {
      setContent({ type: "resource", id: cables[0].id });
      setSelectedItemId(cables[0].id);
    }
  }, [selectedItemId, setContent, cables, saveInProgress]);

  const selectedItem = useMemo(
    () => cables.find((t) => t.id === selectedItemId),
    [selectedItemId, cables],
  );

  const newItemRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const newItem = newItemRef.current;
    if (saveInProgress && newItem) {
      newItem.scrollIntoView();
    }
  }, [saveInProgress]);

  const { create, remove, duplicate } = useOrgCableCrud();

  return (
    <TabContainer>
      <MainRow>
        <ResourceColumn>
          <Button
            buttonType="secondary"
            icon={<AddIcon />}
            text={"New cable"}
            disabled={isLoading}
            onClick={async () => {
              setSaveInProgress(true);
              setSelectedItemId(undefined);
              const res = await create({
                cable: DEFAULT_CABLE,
                name: `New ${DEFAULT_CABLE.name}`,
              });
              if (res) {
                setSelectedItemId(res.id);
                setContent({ type: "resource", id: res.id });
                setSaveInProgress(false);
              }
            }}
          />
          <Column style={{ gap: "0.8rem", overflowY: "auto" }}>
            {cables.map((c) => {
              return (
                <ResourceItem
                  key={c.id}
                  onClick={() => {
                    setSelectedItemId(c.id);
                    setContent({ type: "resource", id: c.id });
                  }}
                  selected={selectedItemId === c.id}
                  disabled={deleteInProgress === c.id}
                >
                  <InitialsImageRound name={c.name} size={2.2} />
                  <ResourceName>{c.name}</ResourceName>
                  {!(deleteInProgress === c.id) && (
                    <DotMenu
                      divStyle={{
                        marginLeft: "auto",
                        width: "2.5rem",
                      }}
                    >
                      <MenuItem
                        name={"Duplicate"}
                        onClick={async () => {
                          setSaveInProgress(true);
                          const res = await duplicate({ cable: c });
                          if (res) {
                            setSelectedItemId(res.id);
                            setContent({ type: "resource", id: res.id });
                            setSaveInProgress(false);
                          }
                        }}
                        icon={<DuplicateIcon />}
                      />
                      <MenuItem
                        name={"Delete"}
                        onClick={async () => {
                          setDeleteInProgress(c.id);
                          await remove(c.id);
                          setDeleteInProgress(undefined);
                          setSelectedItemId(cables[0]?.id);
                          setContent({ type: "resource", id: cables[0]?.id });
                        }}
                        icon={<Trashcan />}
                      />
                    </DotMenu>
                  )}
                </ResourceItem>
              );
            })}
            {saveInProgress && (
              <ResourceItem
                key={"save-in-progress"}
                selected={true}
                ref={newItemRef}
              >
                <SkeletonBlock
                  style={{ height: "4rem", marginTop: "0.8rem" }}
                />
              </ResourceItem>
            )}
          </Column>
        </ResourceColumn>
        {selectedItem && <CableContent cable={selectedItem} />}
      </MainRow>
    </TabContainer>
  );
}

function CableContent({ cable }: { cable: CableType }) {
  const { organisationId } = useTypedPath("organisationId");
  const [, setContent] = useRecoilState(
    organisationRightSideModal(organisationId),
  );
  const { update } = useOrgCableCrud();

  return (
    <ContentColumn>
      <ContentHeaderRow>
        <ContentHeaderButtonsContainer>
          <OpenMoreInfo
            onClick={() => {
              setContent((cur) => {
                if (cur && cur.id === cable.id) return undefined;
                return {
                  type: "resource",
                  id: cable.id,
                };
              });
            }}
          />
        </ContentHeaderButtonsContainer>
        <ContentHeader>{cable.name}</ContentHeader>
      </ContentHeaderRow>
      <div
        style={{
          overflow: "hidden",
          flex: 1,
          position: "relative",
          width: "100%",
        }}
      >
        <SingleCable
          disabled={false}
          key={cable.id}
          cable={cable}
          onSave={update}
          isLoading={false} // TODO
          isLibraryCable={true}
        />
      </div>
    </ContentColumn>
  );
}
