/// <reference types="vite-plugin-svgr/client" />
import React, { useEffect, useState, useMemo, useCallback } from "react";
import styled from "styled-components";
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";
import {
  ErrorBoundaryWarningTriangle,
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "../ErrorBoundaries/ErrorBoundaryLocal";
import {
  WindSourceConfiguration,
  _CustomWindSourceConfiguration,
  _MultipleSourceWindConfiguration,
  _SingleSourceSpatialCalibrationWindConfiguration,
  _SingleSourceWindConfiguration,
  _WindSourceConfiguration,
  isBestWindSource,
  isMultipleSourceWindConfiguration,
  isSingleSourceSpatialCalibrationWindConfiguration,
  isSingleSourceWindConfiguration,
} from "../../services/windSourceConfigurationService";
import {
  ContentWrapper,
  SettingsFooter,
  SettingsHeader,
} from "../SettingsV2/Shared/styles";
import { Label } from "../General/Form";
import { Row } from "../General/Layout";
import Button from "../General/Button";
import { TextArea } from "../General/Input";
import {
  CustomWindFileType,
  SpatialCalibrationType,
  WindDataSource,
} from "../../services/metoceanService";
import {
  UploadedWindData,
  customWindDataWithNodeIdSelector,
  customWindTimeseriesSelector,
  customWRGFileSelector,
  customMeanSpeedGridFileSelector,
} from "../../state/windStatistics";
import { useTypedPath } from "../../state/pathParams";
import { inReadOnlyModeSelector } from "../../state/project";
import {
  savingWindConfigurationInProgressAtom,
  uploadedWindDataSourceUsageAtomFamily,
} from "../../state/windSourceConfiguration";
import { unsavedSettingsState } from "../SettingsV2/Shared/state";
import DropdownButton from "../General/Dropdown/DropdownButton";
import { DropDownItem } from "../General/Dropdown/DropdownItems";
import { TextSemi, IconREMSize } from "../../styles/typography";
import {
  EditableText,
  EditableTextInternalState,
} from "../General/EditableText";
import {
  useRenameUploadedWindData,
  useDeleteUploadedWindData,
} from "../../state/uploadWindData";
import { useToast } from "../../hooks/useToast";
import Spinner from "@icons/spinner/Spinner";
import { spaceSmall } from "../../styles/space";
import { toastMessagesAtom } from "../../state/toast";
import useEnterToSave from "./useEnterToSave";
import { HeaderContainer, SubHeader, ContentContainer } from "./shared";
import { colors } from "styles/colors";
import MultipleTimeSeries from "./Wind/MultipleTimeSeries";
import SingleTimeSeries from "./Wind/SingleTimeSeries";
import SingleTimeSeriesWithCalibration from "./Wind/SingleTimeSeriesWithCalibration";
import WindConfigurationUsage from "./SettingsUsage/WindConfigurationUsage";
import BinIcon from "@icons/24/Bin.svg?react";

const HorizontalLine = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  border-bottom: 1px solid ${colors.borderDefault};
  justify-content: space-between;
`;

export enum ConfigType {
  SingleTimeseries = "single_timeseries",
  SpatialCalibration = "spatial_calibration",
  MultipleTimeseries = "multiple_timeseries",
}

const CONFIG_TYPE_NAMES = {
  [ConfigType.SingleTimeseries]: "Single time series",
  [ConfigType.SpatialCalibration]: "Single time series + Grid/WRG",
  [ConfigType.MultipleTimeseries]: "Multiple time series",
};

const WindDatasetDropdownItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${IconREMSize} {
    visibility: hidden;
  }

  &:hover {
    ${IconREMSize} {
      visibility: visible;
    }
  }
`;

export const WindDatasetDropdownItem = ({
  windData,
  onBeforeDeleteWindData,
}: {
  windData: UploadedWindData[0];
  onBeforeDeleteWindData(id: string): void;
}) => {
  const renameUploadedWindDataFile = useRenameUploadedWindData();
  const deleteUploadedWindDataFile = useDeleteUploadedWindData();
  const { error: showError, success: showSuccess } = useToast();
  const nrUsage = useRecoilValue(
    uploadedWindDataSourceUsageAtomFamily({
      uploadedWindDataId: windData.id,
    }),
  );

  const changeWindDataName = useCallback(
    (id: string, newName: string) => {
      if (newName === "") {
        return;
      }

      renameUploadedWindDataFile(id, newName).catch(() => {
        showError("Could not rename dataset, please try again.");
      });
    },
    [renameUploadedWindDataFile, showError],
  );

  const deleteWindData = useCallback(
    (id: string) => {
      if (!window.confirm("Are you sure you want to delete this dataset?")) {
        return;
      }

      onBeforeDeleteWindData(id);
      deleteUploadedWindDataFile(id)
        .then(() => {
          showSuccess("Dataset deleted");
        })
        .catch(() => {
          showError("Could not delete dataset, please try again.");
        });
    },
    [
      deleteUploadedWindDataFile,
      onBeforeDeleteWindData,
      showError,
      showSuccess,
    ],
  );

  return (
    <WindDatasetDropdownItemWrapper>
      <EditableTextInternalState
        type="text"
        onEnter={(newName) => {
          changeWindDataName(windData.id, newName);
        }}
        value={windData.name}
        textContainerStyle={{
          maxWidth: "20vw",
          padding: 0,
        }}
        renderText={(title) => <TextSemi title={title}>{title}</TextSemi>}
      />
      <IconREMSize
        height={1.3}
        width={1.3}
        style={{
          cursor: "pointer",
          marginLeft: spaceSmall,
        }}
        title="Delete wind dataset"
        onClick={(e) => {
          e.stopPropagation();
          if (nrUsage > 0) {
            alert(
              `This dataset can not be delete since it is being used in ${nrUsage} wind configuration${
                nrUsage !== 1 ? "s" : ""
              }. Remove it from all wind configurations and then try again.`,
            );
            return;
          }
          deleteWindData(windData.id);
        }}
      >
        <BinIcon />
      </IconREMSize>
    </WindDatasetDropdownItemWrapper>
  );
};

export const WindSettings = ErrorBoundaryWrapper(
  ({
    windConfiguration,
    windConfigurationName,
    onSave,
    onSaveName,
    onUnmount,
  }: {
    windConfiguration: WindSourceConfiguration;
    windConfigurationName: string;
    onSave: (newConfig: WindSourceConfiguration) => void;
    onSaveName?: (id: string, name: string) => Promise<any>;
    onUnmount?: () => void;
  }) => {
    const { projectId } = useTypedPath("projectId");
    const isSaving = useRecoilValue(savingWindConfigurationInProgressAtom);
    const setUnsavedSettings = useSetRecoilState(unsavedSettingsState);
    const setToastMessages = useSetRecoilState(toastMessagesAtom);
    const [title, setTitle] = useState(windConfigurationName);

    useEffect(() => {
      setTitle(windConfigurationName);
    }, [windConfigurationName]);

    const [localWindConfig, setLocalWindConfig] =
      useState<WindSourceConfiguration>(windConfiguration);
    const isReadOnly = useRecoilValue(inReadOnlyModeSelector);

    const uploadedWindData = useRecoilValueLoadable(
      customWindDataWithNodeIdSelector({ nodeId: projectId }),
    ).valueMaybe();

    const uploadedWindTimeSeries = useRecoilValueLoadable(
      customWindTimeseriesSelector({ nodeId: projectId }),
    ).valueMaybe();

    const uploadedWRGDataSet = useRecoilValueLoadable(
      customWRGFileSelector({ nodeId: projectId }),
    ).valueMaybe();

    const uploadedMeanSpeedGridDataSet = useRecoilValueLoadable(
      customMeanSpeedGridFileSelector({ nodeId: projectId }),
    ).valueMaybe();

    const disableActions = isReadOnly || isSaving;
    const isLoading = localWindConfig.id !== windConfiguration.id;
    const allChangesSaved =
      disableActions || localWindConfig === windConfiguration;
    const [useSpatialCalibration, setUseSpatialCalibration] = useState<boolean>(
      localWindConfig.spatial_calibration !== null,
    );
    const onEnterSaveCallback = useCallback(
      () => onSave(localWindConfig),
      [localWindConfig, onSave],
    );
    useEnterToSave(onEnterSaveCallback, !allChangesSaved && !isSaving);

    const renameConfig = useCallback(() => {
      if (!onSaveName) return;
      const renameConfigAsync = async () => {
        await onSaveName(localWindConfig.id, title);
      };

      renameConfigAsync();
    }, [onSaveName, localWindConfig, title]);

    useEffect(() => {
      setLocalWindConfig(windConfiguration);
    }, [windConfiguration]);

    useEffect(() => {
      setUnsavedSettings(!isLoading && !allChangesSaved);
    }, [allChangesSaved, isLoading, setUnsavedSettings]);

    useEffect(() => {
      setUseSpatialCalibration(localWindConfig.spatial_calibration !== null);
    }, [localWindConfig]);

    useEffect(() => {
      return () => {
        if (onUnmount) {
          onUnmount();
        }
      };
    }, [onUnmount]);

    const [configType, setConfigType] = useState<ConfigType>(
      getConfigType(windConfiguration),
    );

    useEffect(() => {
      setConfigType(getConfigType(windConfiguration));
    }, [windConfiguration]);

    function getConfigType(windConfiguration: WindSourceConfiguration) {
      if (isMultipleSourceWindConfiguration(windConfiguration)) {
        return ConfigType.MultipleTimeseries;
      } else if (
        isSingleSourceSpatialCalibrationWindConfiguration(windConfiguration)
      ) {
        return ConfigType.SpatialCalibration;
      } else {
        return ConfigType.SingleTimeseries;
      }
    }

    function parseConfig(
      configType: ConfigType,
      localWindConfig: WindSourceConfiguration,
    ) {
      switch (configType) {
        case ConfigType.SingleTimeseries:
          return _SingleSourceWindConfiguration.parse(localWindConfig);
        case ConfigType.SpatialCalibration:
          return _SingleSourceSpatialCalibrationWindConfiguration.parse(
            localWindConfig,
          );
        case ConfigType.MultipleTimeseries:
          return _MultipleSourceWindConfiguration.parse(localWindConfig);
        default:
          throw new Error(`Config not mathcing the type: ${configType}`);
      }
    }

    // Change selected dataset to a built in dataset if user is removing the active dataset
    const onBeforeDeleteWindData = useCallback(
      (id: string) => {
        if (localWindConfig.source.id === id) {
          setLocalWindConfig(
            _WindSourceConfiguration.parse({
              ...localWindConfig,
              calibration: null,
              source: { id: WindDataSource.BEST, type: "built_in" },
            }),
          );
        }
      },
      [localWindConfig],
    );

    // Change selected spatial dataset to a built in dataset if user is removing the active dataset
    const onBeforeDeleteSpatialWindData = useCallback(
      (id: string) => {
        if ((localWindConfig.spatial_calibration as any)?.id === id) {
          setLocalWindConfig(
            _WindSourceConfiguration.parse({
              ...localWindConfig,
              calibration: null,
              spatial_calibration: {
                type: SpatialCalibrationType.GWA,
                value: "absolute",
              },
            }),
          );
        }
      },
      [localWindConfig, setLocalWindConfig],
    );

    const dataSetDropdownItems = useMemo<DropDownItem[]>(() => {
      return [
        {
          value: WindDataSource.BEST,
          name: "Best available",
        },
        {
          value: WindDataSource.NORA3,
          name: "NORA3",
        },
        {
          value: WindDataSource.ERA5,
          name: "ERA5",
        },
        {
          value: WindDataSource.CERRA,
          name: "CERRA",
        },
        ...(uploadedWindData
          ? uploadedWindData
              .filter(
                (d) => !d.type || d.type === CustomWindFileType.TIMESERIES,
              )
              .map((d) => ({
                value: d.id,
                name: d.name,
                wrapperStyle: {
                  width: "20rem",
                  paddingRight: "1.2rem",
                },
                renderItem: () => {
                  return (
                    <WindDatasetDropdownItem
                      windData={d}
                      onBeforeDeleteWindData={onBeforeDeleteWindData}
                    />
                  );
                },
              }))
          : [
              {
                name: "Loading custom wind data...",
                icon: <Spinner size="1rem" />,
                value: "loading",
                disabled: true,
              },
            ]),
      ];
    }, [onBeforeDeleteWindData, uploadedWindData]);

    const dataSetDropdownItemsForCalibration = dataSetDropdownItems.filter(
      (item) => item.value !== WindDataSource.BEST,
    );

    const spatialCalibrationDropdownItems = useMemo<DropDownItem[]>(() => {
      return [
        {
          value: SpatialCalibrationType.GWA,
          name: "Global Wind Atlas",
        },
        ...(uploadedWindData
          ? uploadedWindData
              .filter(
                (d) =>
                  d.type === CustomWindFileType.MEAN_SPEED_GRID ||
                  d.type === CustomWindFileType.WRG,
              )
              .map((d) => ({
                value: d.id,
                name: d.name,
                wrapperStyle: {
                  width: "20rem",
                  paddingRight: "1.2rem",
                },
                renderItem: () => {
                  return (
                    <WindDatasetDropdownItem
                      windData={d}
                      onBeforeDeleteWindData={onBeforeDeleteSpatialWindData}
                    />
                  );
                },
              }))
          : [
              {
                name: "Loading custom wind data...",
                icon: <Spinner size="1rem" />,
                value: "loading",
                disabled: true,
              },
            ]),
      ];
    }, [onBeforeDeleteSpatialWindData, uploadedWindData]);

    const configTypeDropdownItems = useMemo<DropDownItem[]>(() => {
      return [
        {
          value: ConfigType.SingleTimeseries,
          name: CONFIG_TYPE_NAMES[ConfigType.SingleTimeseries],
        },
        {
          value: ConfigType.SpatialCalibration,
          name: CONFIG_TYPE_NAMES[ConfigType.SpatialCalibration],
        },
        {
          value: ConfigType.MultipleTimeseries,
          name: CONFIG_TYPE_NAMES[ConfigType.MultipleTimeseries],
          disabled: true,
          info: "Coming soon",
        },
      ];
    }, []);

    return (
      <>
        {!allChangesSaved && (
          <SettingsHeader>
            <Button
              disabled={isLoading || isSaving || allChangesSaved}
              text="Cancel"
              buttonType="secondary"
              onClick={() => {
                setLocalWindConfig(windConfiguration);
              }}
              style={{ marginLeft: "auto" }}
            />
            <Button
              disabled={isLoading || isSaving || allChangesSaved}
              text="Save changes"
              onClick={() => {
                if (
                  localWindConfig.source.type === "custom_multiple" &&
                  localWindConfig.source.data.length < 2
                ) {
                  setToastMessages((tm) => [
                    ...tm,
                    {
                      text: "You need to add at least two datasets to create a configuration with multiple time series",
                      timeout: 5000,
                    },
                  ]);
                  return;
                }
                setToastMessages((tm) => [
                  ...tm,
                  { text: "Saving...", timeout: 1000 },
                ]);
                try {
                  const parsedConfig = parseConfig(configType, localWindConfig);
                  onSave({ ...parsedConfig, name: title });
                } catch (error) {
                  setToastMessages((tm) => [
                    ...tm,
                    {
                      text: "Could not save. Invalid configuration type",
                      timeout: 5000,
                    },
                  ]);
                }
              }}
            />
          </SettingsHeader>
        )}

        <ContentWrapper
          style={{
            maxHeight: "calc(100% - 7.3rem)",
            overflowY: "auto",
            boxSizing: "border-box",
            height: "100%",
          }}
        >
          <ContentContainer>
            <HeaderContainer>
              <Row>
                <SubHeader style={{ margin: 0 }}>Wind configuration</SubHeader>
              </Row>
              <EditableText
                type="text"
                smallInput={true}
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                onEnter={renameConfig}
                onCancel={() => {
                  setTitle(windConfigurationName);
                }}
                textContainerStyle={{
                  maxWidth: "20vw",
                  padding: 0,
                }}
                renderText={(title) => (
                  <h3
                    style={{
                      margin: 0,
                      whiteSpace: "nowrap",
                      overflowX: "hidden",
                      textOverflow: "ellipsis",
                      display: "block",
                    }}
                    title={title}
                  >
                    {title}
                  </h3>
                )}
                disabled={isReadOnly}
              />
            </HeaderContainer>
            <Row style={{ alignItems: "flex-start", gap: "3.2rem" }}>
              <Label>
                <Row style={{ alignItems: "center" }}>
                  <p>Configuration type</p>
                </Row>
                <Row
                  style={{
                    width: "fit-content",
                  }}
                >
                  <DropdownButton
                    items={configTypeDropdownItems}
                    style={{
                      height: "fit-content",
                      width: "32rem",
                    }}
                    disabled={disableActions}
                    onSelectItem={(id) => {
                      const t = id as ConfigType;
                      setConfigType(t);
                      if (t === ConfigType.SingleTimeseries) {
                        setLocalWindConfig(
                          _SingleSourceWindConfiguration.parse({
                            ...localWindConfig,
                            spatial_calibration: null,
                            source: !isSingleSourceWindConfiguration(
                              localWindConfig,
                            )
                              ? { id: WindDataSource.BEST, type: "built_in" }
                              : localWindConfig.source,
                          }),
                        );
                      } else if (t === ConfigType.SpatialCalibration) {
                        setLocalWindConfig(
                          _SingleSourceSpatialCalibrationWindConfiguration.parse(
                            {
                              ...localWindConfig,
                              spatial_calibration: {
                                type: SpatialCalibrationType.GWA,
                                value: "absolute",
                              },
                              source:
                                isMultipleSourceWindConfiguration(
                                  localWindConfig,
                                ) ||
                                (isSingleSourceWindConfiguration(
                                  localWindConfig,
                                ) &&
                                  isBestWindSource(localWindConfig.source))
                                  ? {
                                      id: WindDataSource.ERA5,
                                      type: "built_in",
                                    }
                                  : localWindConfig.source,
                            },
                          ),
                        );
                      } else if (t === ConfigType.MultipleTimeseries) {
                        setLocalWindConfig(
                          _MultipleSourceWindConfiguration.parse({
                            ...localWindConfig,
                            spatial_calibration: null,
                            source: {
                              type: "custom_multiple",
                              data: [],
                            },
                          }),
                        );
                      }
                    }}
                    buttonText={CONFIG_TYPE_NAMES[configType]}
                    selectedItemValue={configType}
                  />
                </Row>
              </Label>
              <Label>
                <p>Description</p>
                <TextArea
                  rows={3}
                  disabled={disableActions}
                  value={localWindConfig.note ?? ""}
                  style={{
                    resize: "vertical",
                    width: "45rem",
                  }}
                  placeholder="Add a description"
                  onChange={(e) =>
                    setLocalWindConfig({
                      ...localWindConfig,
                      note: e.target.value,
                    })
                  }
                />
              </Label>
            </Row>

            <HorizontalLine />
            {configType === ConfigType.SingleTimeseries && (
              <SingleTimeSeries
                dataSetDropdownItems={dataSetDropdownItems}
                localWindConfig={_SingleSourceWindConfiguration.parse(
                  localWindConfig,
                )}
                disableActions={disableActions}
                setLocalWindConfig={setLocalWindConfig}
                uploadedWindData={uploadedWindData}
                uploadedTimeSeries={uploadedWindTimeSeries}
              />
            )}
            {configType === ConfigType.SpatialCalibration && (
              <SingleTimeSeriesWithCalibration
                dataSetDropdownItems={dataSetDropdownItemsForCalibration}
                spatialCalibrationDropdownItems={
                  spatialCalibrationDropdownItems
                }
                localWindConfig={_SingleSourceSpatialCalibrationWindConfiguration.parse(
                  localWindConfig,
                )}
                disableActions={disableActions}
                setLocalWindConfig={setLocalWindConfig}
                uploadedWindData={uploadedWindData}
                uploadedWRGGrids={uploadedWRGDataSet}
                uploadedMeanSpeedGrids={uploadedMeanSpeedGridDataSet}
                useSpatialCalibration={useSpatialCalibration}
              />
            )}
            {configType === ConfigType.MultipleTimeseries && (
              <MultipleTimeSeries
                disableActions={disableActions}
                windDataSets={uploadedWindTimeSeries}
                localWindConfig={_MultipleSourceWindConfiguration.parse(
                  localWindConfig,
                )}
                setLocalWindConfig={setLocalWindConfig}
              />
            )}
          </ContentContainer>
        </ContentWrapper>
        <SettingsFooter>
          <WindConfigurationUsage windConfigurationId={windConfiguration.id} />
        </SettingsFooter>
      </>
    );
  },
  () => {
    return (
      <>
        <FatalErrorBoundaryWrapper />
        <ErrorBoundaryWarningTriangle />
      </>
    );
  },
  ScreamOnError,
);

export default WindSettings;
