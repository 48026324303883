/// <reference types="vite-plugin-svgr/client" />
import { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import {
  ListResultsForProblem,
  OptProblem,
  OptResult,
} from "../../functions/optimize";
import { SubAreaFeature } from "../../types/feature";
import { colors } from "../../styles/colors";
import { SkeletonBlock, SkeletonText } from "../Loading/Skeleton";
import { spaceDecent, spaceLarge, spaceTiny } from "../../styles/space";
import { TextIcon, textFontSize } from "../../styles/typography";
import { ParkFeature } from "../../types/feature";
import { lonlat2xy, pointsAreEqual } from "../../utils/geometry";
import { dateToDateTime, partition, zip } from "../../utils/utils";
import { replaceOrUndefined } from "../ControlPanels/utils";
import { ChevronIcon } from "../ToggleableList/ToggleableList";
import {
  EdgeTag,
  MinSpacingTag,
  NumberOfTurbinesTag,
  VersionTag,
} from "./Tags";
import { isCompleted, isFailed } from "./predicates";
import {
  optProblemsSelectorFamily,
  problemResultsAtomFamily,
  recentlyDeletedProblemAtom,
} from "./state";
import { useOptimizationCrud } from "./useOptimizationCrud";
import Trashcan from "@icons/24/Bin.svg?react";
import { inReadOnlyModeSelector } from "state/project";
import Tooltip from "components/General/Tooltip";
import { editorAccessProjectSelector } from "state/user";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { OverlineText } from "components/General/GeneralSideModals.style";
import Button from "components/General/Button";
import SingleTurbine from "@icons/24/SideBarIcons/SingleTurbine.svg?react";
import { useTrackEvent } from "components/OnboardingTours/state";

// NOTE: The `> * { margin }` part is so that the hover buttons are colored all
// the way to the edges, but margins for other elements are reasonable.
const ProblemDiv = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  > * {
    margin: 0 ${spaceLarge};
  }
`;

type Ids = {
  nodeId: string;
  branchId: string;
  zoneId: string;
};

type Pids = Ids & { id: string };

const splitResultsByStatus = (items: ListResultsForProblem["items"]) => {
  const [completed, rest] = partition(items, isCompleted);
  const [failed, rest2] = partition(rest, isFailed);

  const [running, stuck] = partition(rest2, (e) => {
    const spendSeconds = Date.now() / 1000 - e.createdAt;
    return spendSeconds < 60;
  });

  return { completed, failed, running, stuck };
};

const ProblemEntry = styled.div<{ open?: boolean }>`
  display: flex;
  flex-direction: column;

  margin: 0 !important;
  &:hover {
    cursor: pointer;
  }

  p {
    margin: 0;
    padding: 0;
  }
`;

const ProblemEntryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${spaceTiny};
`;

const ResultEntry = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${textFontSize};
  border-radius: 0.4rem;
  margin: 0 !important;
  cursor: auto;
  min-height: 2.8rem;
  padding: ${spaceTiny} ${spaceDecent};
  .primary-dark {
    display: none;
  }
  &:hover {
    .primary-dark {
      display: flex;
    }
    background-color: ${colors.surfaceHover} !important;
  }
`;

const NoResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: ${spaceTiny};
  padding: 2.4rem;
  border-radius: 0.4rem;
  background-color: ${colors.blue50};
  color: ${colors.textPrimary};
`;

const RoundIconWrapper = styled.div`
  padding: 1.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TurbineIconWrapper = styled(RoundIconWrapper)`
  background-color: ${colors.surfaceBrand};
  svg {
    width: 1.6rem;
    height: 1.6rem;
    path {
      fill: ${colors.iconNegative};
    }
  }
`;

const PreviousProblemResultsPlaceholder = () => {
  const minHeight = "1.7rem"; // NOTE: fit this to the height of ResultEntry
  const marginTop = "0.6rem";
  const marginBottom = marginTop;
  return (
    <>
      <div>
        <SkeletonBlock
          style={{ flex: 1, minHeight, marginTop, marginBottom }}
        />
      </div>
      <div>
        <SkeletonBlock
          style={{ flex: 1, minHeight, marginTop, marginBottom }}
        />
      </div>
      <div>
        <SkeletonBlock
          style={{ flex: 1, minHeight, marginTop, marginBottom }}
        />
      </div>
    </>
  );
};

const ResultItem = ({
  index,
  onClick,
  aep,
  version,
  style,
}: {
  index: number;
  onClick: Parameters<typeof ResultEntry>[0]["onClick"];
  aep?: number;
  version: string;
  style?: React.CSSProperties;
}) => {
  return (
    <ResultEntry style={style}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "0.7rem",
        }}
      >
        <p>Option {index}</p>
        {aep && version >= "2.0.4" && (
          <p style={{ color: colors.textSecondary, fontSize: "1rem" }}>
            ～{Math.round(aep / 1e3)} GWh
          </p>
        )}
      </div>
      <Button
        buttonType={"primary-dark"}
        text={"Apply"}
        onClick={onClick}
        size={"small"}
        style={{ height: "2.4rem" }}
      />
    </ResultEntry>
  );
};

const PreviousProblemResults = ({
  ids,
  problem,
  selectedRegion,
  onOptionClick,
}: {
  ids: Ids;
  problem: OptProblem;
  selectedRegion: ParkFeature | SubAreaFeature;
  onOptionClick?(
    result: OptResult,
    problem: OptProblem,
    outdated: boolean,
    deselect: boolean,
  ): void;
}) => {
  const pids: Pids = useMemo(
    () => ({ ...ids, id: problem.id }),
    [ids, problem.id],
  );
  const results = useRecoilValue(problemResultsAtomFamily(pids));

  const { completed, running, stuck } = splitResultsByStatus(
    results?.items ?? [],
  );
  const numberOfItems = results?.items?.length ?? 0;

  const { getResults } = useOptimizationCrud();
  const shouldPoll =
    results === undefined ||
    results.items.length === 0 ||
    0 < running.length ||
    0 < stuck.length ||
    numberOfItems < 20; // NOTE: magic number here: we happen to know that we run exactly 20 sims.

  const handle = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (!shouldPoll) {
      clearInterval(handle.current);
      return;
    }
    if (!handle.current) {
      handle.current = setInterval(() => {
        getResults(pids);
      }, 3_000);
      getResults(pids);
    }
  }, [getResults, pids, shouldPoll]);

  useEffect(
    () => () => {
      if (handle.current) {
        clearInterval(handle.current);
      }
    },
    [],
  );
  const trackEvent = useTrackEvent();
  const isOutdated = useMemo(() => {
    const [reflon, reflat] = selectedRegion.geometry.coordinates[0][0];
    const parkPolygonIsLonLat = problem.parkPolygon.reduce(
      (isLonLat, v) => isLonLat && Math.abs(v[0]) < 180,
      true,
    );
    const parkXY = selectedRegion.geometry.coordinates[0].map((c) => {
      if (!parkPolygonIsLonLat) {
        const { x, y } = lonlat2xy(c[0], c[1], reflon, reflat);
        return [x, y];
      } else {
        return [c[0], c[1]];
      }
    });
    const parksAreDifferent =
      zip(parkXY, problem.parkPolygon).find(
        ([p, q]) => !pointsAreEqual(p, q),
      ) !== undefined;
    return parksAreDifferent;
  }, [problem.parkPolygon, selectedRegion.geometry.coordinates]);

  const [clickedOption, setClickedOption] = useState<undefined | number>(
    undefined,
  );

  if (!results || results.items.length === 0) {
    return <PreviousProblemResultsPlaceholder />;
  }

  if (0 < running.length) {
    const numberDone = numberOfItems - running.length;
    const percentDone = Math.round((numberDone / numberOfItems) * 100);
    return (
      <SkeletonText
        style={{
          height: "2rem",
          width: "initial",
        }}
        text={`${percentDone}%`}
      />
    );
  }

  if (completed.length === 0) {
    return (
      <ResultEntry>
        <SimpleAlert
          text={
            "No solutions were found." +
            (problem.minSpacing >= 6 ? "Try to reduce the min spacing." : "")
          }
          type={"info"}
        />
      </ResultEntry>
    );
  }
  completed.sort((a, b) => b.aep - a.aep);

  return (
    <>
      {completed.slice(0, 3).map((r, i) => {
        return (
          <ResultItem
            index={i + 1}
            aep={r.aep}
            version={r.version}
            key={`${problem.id}-${r.seed}`}
            style={{
              backgroundColor:
                i % 2 === 0 ? `${colors.surfaceSecondary}` : "auto",
            }}
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
              onOptionClick?.(r, problem, isOutdated, i === clickedOption);
              setClickedOption(replaceOrUndefined(i));
              trackEvent("optionSelected");
            }}
          />
        );
      })}
    </>
  );
};

export const openOptListItem = atom<string | undefined>({
  key: "openOptListItem",
  default: undefined,
});

type ResProps = Parameters<typeof PreviousProblemResults>[0];
export const PreviousOptimizationsList = ({
  ids,
  selectedRegion,
  onOptionClick,
}: {
  ids: Ids;
  selectedRegion: ResProps["selectedRegion"];
  onOptionClick: ResProps["onOptionClick"];
}) => {
  const isReadOnly = useRecoilValue(inReadOnlyModeSelector);
  const isCustomerEditor = useRecoilValue(editorAccessProjectSelector);
  const canEdit = isCustomerEditor && !isReadOnly;
  const recentlyDeletedProblem = useRecoilValue(recentlyDeletedProblemAtom);
  const { deleteProblem } = useOptimizationCrud();
  const problems = useRecoilValue(optProblemsSelectorFamily(ids));
  const problemsNotDeleted = problems.filter(
    (p) => !recentlyDeletedProblem.includes(p.id),
  );
  const [open, setOpen] = useRecoilState(openOptListItem);

  const sortedProblems = useMemo(() => {
    const l = [...problemsNotDeleted];
    l.sort((a, b) => b.createdAt - a.createdAt);
    return l;
  }, [problemsNotDeleted]);

  return (
    <ProblemDiv style={{ padding: 0 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {sortedProblems.length === 0 && (
          <NoResultsWrapper>
            <TurbineIconWrapper>
              <SingleTurbine />
            </TurbineIconWrapper>
            <p>No previous optimizations.</p>
            <p>Optimisation results are stored for 3 months</p>
          </NoResultsWrapper>
        )}
        {sortedProblems
          .filter((p) => !(Date.now() / 1000 - p.createdAt > 88 * 24 * 60 * 60)) //Filter out problems older than 88 days
          .map((p) => {
            const dateStr = dateToDateTime(new Date(p.createdAt * 1000));
            const isMoreThan10WeeksOld =
              Date.now() / 1000 - p.createdAt > 10 * 7 * 24 * 60 * 60;

            return (
              <ProblemEntry key={p.id} open={open === p.id}>
                <ProblemEntryHeader
                  onClick={() => {
                    setOpen(replaceOrUndefined(p.id));
                  }}
                >
                  <Tooltip
                    secondary
                    text={
                      "The result will soon be deleted. Layouts used in the project will not be affected."
                    }
                    position="right"
                    disabled={!isMoreThan10WeeksOld}
                  >
                    <OverlineText
                      style={{
                        color: isMoreThan10WeeksOld
                          ? colors.textWarning
                          : colors.textSecondary,
                        justifySelf: "end",
                      }}
                    >
                      {dateStr}
                    </OverlineText>
                  </Tooltip>
                  <div style={{ display: "flex", gap: "0.8rem" }}>
                    {canEdit && (
                      <Tooltip
                        text={
                          "Delete this optimization problem and its results. This cannot be undone"
                        }
                      >
                        <TextIcon
                          onClick={async (event) => {
                            event.stopPropagation();
                            deleteProblem(
                              {
                                ...ids,
                                id: p.id,
                              },
                              recentlyDeletedProblem,
                            );
                          }}
                          scale={1.2}
                          style={{ padding: spaceTiny, alignSelf: "center" }}
                        >
                          <Trashcan />
                        </TextIcon>
                      </Tooltip>
                    )}
                    <ChevronIcon
                      open={p.id === open}
                      chevronSize={"1rem"}
                      style={{
                        alignSelf: "center",
                      }}
                    />
                  </div>
                </ProblemEntryHeader>
                {p.id === open && (
                  <Suspense fallback={<PreviousProblemResultsPlaceholder />}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          paddingBottom: "0.8rem",
                          gap: "0.8rem",
                        }}
                      >
                        <NumberOfTurbinesTag n={p.numberOfTurbines} />
                        {p.includeEdge && <EdgeTag />}
                        <MinSpacingTag n={p.minSpacing} />
                        <VersionTag v={p.version} />
                      </div>

                      <PreviousProblemResults
                        ids={ids}
                        problem={p}
                        selectedRegion={selectedRegion}
                        onOptionClick={onOptionClick}
                      />
                    </div>
                  </Suspense>
                )}
              </ProblemEntry>
            );
          })}
      </div>
    </ProblemDiv>
  );
};
