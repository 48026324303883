import { useRecoilCallback } from "recoil";

import {
  addOrUpdateFinancialResourceOnNode,
  deleteFinancialResourceOnNode,
  getFinancialResourcesOnNode,
} from "components/Organisation/Library/service";
import {
  financialResourceWithAccessOnNodeState,
  nodesWithAccessToFinancial,
} from "components/Organisation/Library/state";
import { CostConfiguration } from "services/costService";
import {
  costConfigurationUsageAtomFamily,
  libraryFinancialConfigRefreshAtom,
} from "state/costConfigurations";
import { useTypedPath } from "state/pathParams";

const useNodeFinancialResourcesCrud = () => {
  const { organisationId } = useTypedPath("organisationId");
  const addOrUpdate = useRecoilCallback(
    ({ snapshot, set, refresh }) =>
      async (nodeId: string, config: CostConfiguration) => {
        const fallback = await snapshot.getPromise(
          financialResourceWithAccessOnNodeState({ nodeId }),
        );
        return addOrUpdateFinancialResourceOnNode(nodeId, config.id)
          .then(async () => {
            const updatedConfigs = await getFinancialResourcesOnNode(nodeId);
            set(
              financialResourceWithAccessOnNodeState({ nodeId }),
              updatedConfigs,
            );
            refresh(
              nodesWithAccessToFinancial({
                organisationId,
                resourceId: config.id,
              }),
            );
            set(libraryFinancialConfigRefreshAtom, (cur) => cur + 1);
          })
          .catch((e) => {
            set(financialResourceWithAccessOnNodeState({ nodeId }), fallback);
            throw e;
          });
      },
    [organisationId],
  );

  const remove = useRecoilCallback(
    ({ snapshot, set, refresh }) =>
      async (nodeId: string, resourceId: string) => {
        const fallback = await snapshot.getPromise(
          financialResourceWithAccessOnNodeState({ nodeId }),
        );
        const usage = await snapshot.getPromise(
          costConfigurationUsageAtomFamily({
            nodeId,
            costConfigurationId: resourceId,
          }),
        );

        if (
          usage.length === 0 ||
          window.confirm(
            `Financial configuration is currently being used in ${usage.length} projects, are you sure you want to remove access to it?`,
          )
        ) {
          return deleteFinancialResourceOnNode(nodeId, resourceId)
            .then(() => {
              set(financialResourceWithAccessOnNodeState({ nodeId }), (cur) =>
                cur.filter((c) => c.config.id !== resourceId),
              );
              refresh(
                nodesWithAccessToFinancial({ organisationId, resourceId }),
              );
              set(libraryFinancialConfigRefreshAtom, (cur) => cur + 1);
            })
            .catch((e) => {
              set(financialResourceWithAccessOnNodeState({ nodeId }), fallback);
              throw e;
            });
        }
      },
    [organisationId],
  );

  return { addOrUpdate, remove };
};

export default useNodeFinancialResourcesCrud;
