import FinancialIcon from "@icons/24/Financial.svg?react";
import { InnerContainer, Container, Title } from "../style";
import Tooltip from "components/General/Tooltip";
import { IconREMSize } from "styles/typography";
import { colors } from "styles/colors";
import ManageMyAccessButton from "../shared/ManageMyAccessButton";
import { tabToName } from "../shared/utils";

export default function FinancialImage({
  onClick,
  disabled,
  disabledText,
}: {
  onClick: () => void;
  disabled?: boolean;
  disabledText: string;
}) {
  return (
    <Container disabled={disabled}>
      {disabled && <ManageMyAccessButton />}
      <Tooltip disabled={!disabled} text={disabledText}>
        <InnerContainer
          className="InnerContainer"
          onClick={() => {
            if (disabled) return;
            onClick();
          }}
        >
          <IconREMSize height={11} width={10} stroke={colors.inputOutline}>
            <FinancialIcon />
          </IconREMSize>
        </InnerContainer>
      </Tooltip>
      <Title>{tabToName("financial")}</Title>
    </Container>
  );
}
