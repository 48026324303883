import React, { useEffect, useMemo, useRef, useState } from "react";
import { useRecoilCallback, useSetRecoilState } from "recoil";
import { MeanSpeedGridCustom } from "state/windStatistics";
import mapboxgl, { LngLatBoundsLike } from "mapbox-gl";
import { useSpeedGridActive } from "layers/speedGrid";
import { activeMapStyleIdAtom } from "state/map";
import { MapWrapper } from "components/Organisation/Portfolio/style";
import { toastMessagesAtom } from "state/toast";

export const ShowGridFile = ({
  data,
}: {
  data?: Promise<MeanSpeedGridCustom | undefined>;
}) => {
  const [grid, setGrid] = useState<MeanSpeedGridCustom | undefined>(undefined);
  const setToastMessage = useSetRecoilState(toastMessagesAtom);

  useEffect(() => {
    if (!data) return;
    data
      .then((res) => setGrid(res))
      .catch((err) => {
        setToastMessage((tm) => [
          ...tm,
          {
            text: err.message,
            timeout: 5000,
            type: "error",
          },
        ]);
      });
    return () => setGrid(undefined);
  }, [data, setToastMessage]);

  return <ShowGridFileInner grid={grid} />;
};

export const ShowGridFileInner = ({
  grid,
}: {
  grid: MeanSpeedGridCustom | undefined;
}) => {
  const mapContainer = useRef<HTMLDivElement>(null);

  const getActiveMapStyleId = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        return snapshot.getLoadable(activeMapStyleIdAtom).getValue();
      },
    [],
  );

  const [mapLoaded, setMapLoaded] = useState<mapboxgl.Map>();

  const bounds: LngLatBoundsLike | undefined = useMemo(() => {
    if (!grid) return;
    return [
      grid.xllcorner,
      grid.yllcorner,
      grid.xllcorner + grid.dx * grid.ncols,
      grid.yllcorner + grid.dy * grid.nrows,
    ];
  }, [grid]);

  useEffect(() => {
    if (!mapContainer.current) {
      return;
    }

    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: getActiveMapStyleId(),
      logoPosition: "top-right",
      preserveDrawingBuffer: true,
      dragRotate: false,
    });
    map.on("load", (e) => {
      setMapLoaded(e.target);
    });

    return () => {
      map.remove();
      setMapLoaded(undefined);
    };
  }, [getActiveMapStyleId]);

  useEffect(() => {
    if (!bounds) return;
    mapLoaded?.fitBounds(bounds, {
      padding: 30,
      animate: false,
    });
  }, [bounds, mapLoaded]);

  const minSpeed = useMemo(() => {
    if (!grid) return;
    return grid.grid
      .flatMap((v) => v)
      .reduce((acc, v) => (v > 0 ? Math.min(acc, v) : acc), 10);
  }, [grid]);

  const maxSpeed = useMemo(() => {
    if (!grid) return;
    return grid.grid
      .flatMap((v) => v)
      .reduce((acc, v) => (v > 0 ? Math.max(acc, v) : acc), 0);
  }, [grid]);

  const speedLayer = useSpeedGridActive({
    meanSpeedGrid: grid,
    minSpeed,
    maxSpeed,
  });

  useEffect(() => {
    if (!mapLoaded || !speedLayer) return;
    if (!mapLoaded.getLayer(speedLayer.id)) {
      mapLoaded.addLayer(speedLayer as any, "building");
    }
  }, [mapLoaded, speedLayer]);

  return (
    <>
      <div style={{ height: "40vh" }}>
        <MapWrapper id="grid-map" ref={mapContainer} />
      </div>
    </>
  );
};
