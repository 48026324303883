import {
  RecoilLoadable,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";
import React, { useCallback, useEffect, useMemo } from "react";
import {
  Column as ReactTableColumn,
  SortByFn,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import EarthIcon from "@icons/14/Earth.svg";
import ListViewIcon from "@icons/16/ListView.svg";
import RemoveIcon from "@icons/24/Remove.svg";
import SortAscendingIcon from "@icons/SortAscending.svg";
import SortDescendingIcon from "@icons/SortDescending.svg";
import SortNeutralIcon from "@icons/SortNeutral.svg";
import { Row } from "components/General/Layout";
import { IconBtn } from "components/General/Icons";
import { spacing4, spacing6, spacing8 } from "styles/space";
import { typography } from "styles/typography";
import { colors } from "styles/colors";
import { SkeletonText } from "components/Loading/Skeleton";
import { useRecoilValueDef } from "utils/recoil";
import { organisationIdSelector } from "state/pathParams";
import Checkbox from "components/General/Checkbox";
import { dateToYearDateTime } from "utils/utils";
import { ScrollBody } from "hooks/useShowScrollShadow";
import { DataLibraryLayer } from "../types";
import {
  HeaderColumn,
  Table,
  StickyThead,
  UserInfo,
  HiddenIfNotChecked,
  ProjectInfo,
} from "../shared";
import {
  activePackageIdAtom,
  getDataLayersUsage,
  organisationLibraryLayersSelector,
  scrollToOrganisationLayerIdAtom,
  selectedOrganisationPackageLayersAtom,
} from "../state";
import { useDataLibraryLayersCrud } from "../useDataLibraryLayersCrud";
import Tooltip from "components/General/Tooltip";
import { UsageContainerInner2 } from "components/ConfigurationModal/SettingsUsage/common";
import { UsageContainerPlacement } from "components/ConfigurationModal/SettingsUsage/style";
import { OrganisationResourceChangelogPopup } from "components/Changelog/Changelog";
import { organisationResourceLastUpdateSelectorFamily } from "components/Changelog/state";
import { Mixpanel } from "mixpanel";
import { usersInOrganisationState } from "components/Organisation/state";
import { getSortTypes } from "components/Organisation/Library/dataLibrary/sortTypes";

const columns: Array<ReactTableColumn<DataLibraryLayer>> = [
  {
    id: "name",
    width: "40%",
    // @ts-ignore
    canSort: true,
    sortType: (a, b) => a.original.name.localeCompare(b.original.name),
    Header: ({ toggleAllSelected, allIsSelected, someIsSelected }) => {
      return (
        <Row
          style={{
            paddingLeft: spacing8,
          }}
        >
          <Checkbox
            checked={
              allIsSelected ? true : someIsSelected ? "indeterminate" : false
            }
            onChange={toggleAllSelected}
          />
          <HeaderColumn>Name</HeaderColumn>
        </Row>
      );
    },
    Cell: ({ row, isSelected, onToggleLayerSelected }) => {
      return (
        <Row
          alignCenter
          style={{ display: "flex", gap: spacing6, paddingLeft: spacing8 }}
        >
          <HiddenIfNotChecked data-checked={isSelected}>
            <Checkbox
              checked={isSelected}
              onChange={() => {
                onToggleLayerSelected(row.original.id);
              }}
            />
          </HiddenIfNotChecked>
          <p>{row.original.name}</p>
        </Row>
      );
    },
  },
  {
    id: "created_at",
    // @ts-ignore
    canSort: true,
    sortType: "lastChanged",
    Header: <HeaderColumn style={typography.sub2}>Last updated</HeaderColumn>,
    Cell: ({ row, organisationId, lastChanges }) =>
      typeof lastChanges === "undefined" ? (
        <SkeletonText />
      ) : (
        <OrganisationResourceChangelogPopup
          organisationId={organisationId}
          resourceId={row.original.id}
        >
          <p>
            {lastChanges[row.original.id] &&
              dateToYearDateTime(
                new Date(lastChanges[row.original.id].version),
              )}
          </p>
        </OrganisationResourceChangelogPopup>
      ),
  },
  {
    id: "description",
    // @ts-ignore
    canSort: false,
    Header: <HeaderColumn style={typography.sub2}>Description</HeaderColumn>,
    Cell: ({ row }) => <p>{row.original.description}</p>,
  },
  {
    id: "author",
    // @ts-ignore
    canSort: true,
    sortType: "author",
    Header: <HeaderColumn>Created by</HeaderColumn>,
    Cell: ({ row, organisationId }) => {
      return (
        <Row alignCenter>
          <UserInfo
            organisationId={organisationId}
            userId={row.original.author}
          />
        </Row>
      );
    },
  },
  {
    id: "nrPackageUsages",
    width: 100,
    Header: <HeaderColumn>Usage</HeaderColumn>,
    Cell: ({ row, layerUsages }) => {
      const usage = (layerUsages[row.original.id] ?? []) as string[];
      return (
        <Row
          alignCenter
          style={{
            gap: "0",
          }}
        >
          <EarthIcon />
          <UsageContainerInner2
            wrapperStyle={{
              backgroundColor: "unset",
              alignItems: "center",
              gap: spacing4,
              padding: 0,
            }}
            numberStyle={{
              backgroundColor: colors.blue100,
              margin: 0,
              padding: `2px ${spacing4}`,
              borderRadius: typography.regular.fontSize,
            }}
            text={""}
            loadable={RecoilLoadable.of(usage)}
            typeName=""
            placement={UsageContainerPlacement.BOTTOM}
          >
            {usage &&
              usage.map((projectId) => (
                <ProjectInfo key={projectId} projectId={projectId} />
              ))}
          </UsageContainerInner2>
        </Row>
      );
    },
  },
  {
    id: "actions",
    Header: (
      <Row
        justifyCenter
        style={{
          width: "100%",
        }}
      >
        <HeaderColumn>Actions</HeaderColumn>
      </Row>
    ),
    width: 50,
    Cell: ({ row, onGoToLayerClick, onDeleteClick }) => (
      <Row
        alignCenter
        justifyCenter
        style={{
          gap: spacing6,
        }}
      >
        <Tooltip text="Show in All data list">
          <IconBtn
            onClick={(e) => {
              e.stopPropagation();
              onGoToLayerClick(row.original.id);
            }}
            size="1.4rem"
          >
            <ListViewIcon />
          </IconBtn>
        </Tooltip>
        <Tooltip text="Remove from package">
          <IconBtn
            onClick={(e) => {
              e.stopPropagation();
              onDeleteClick(row.original.id);
            }}
            size="1.4rem"
          >
            <RemoveIcon />
          </IconBtn>
        </Tooltip>
      </Row>
    ),
  },
];

const PackageTable = ({
  packageId,
  layerIds,
}: {
  packageId: string;
  layerIds: string[];
}) => {
  const organisationId = useRecoilValueDef(organisationIdSelector);
  const { removeLayersFromPackage } = useDataLibraryLayersCrud();
  const layers = useRecoilValue(
    organisationLibraryLayersSelector({ layerIds }),
  );
  const setActivePackageId = useSetRecoilState(activePackageIdAtom);
  const scrollToOrganisationLayerId = useSetRecoilState(
    scrollToOrganisationLayerIdAtom,
  );

  const allMembersRaw = useRecoilValue(
    usersInOrganisationState(organisationId),
  );

  const getUsername = useCallback(
    (userId: string) => {
      return allMembersRaw.find((m) => m.user_id === userId)?.nickname;
    },
    [allMembersRaw],
  );

  const [selectedLayers, setSelectedLayers] = useRecoilState(
    selectedOrganisationPackageLayersAtom,
  );
  const layerUsages = useRecoilValue(
    getDataLayersUsage({
      layerIds: layers.map((l) => l.id),
      nodeId: organisationId,
    }),
  );

  const lastChanges = useRecoilValueLoadable(
    organisationResourceLastUpdateSelectorFamily({
      organisationId,
      resourceIds: layers.map((l) => l.id),
    }),
  );

  const allIsSelected =
    layers.length > 0 && selectedLayers.length === layers.length;
  const someIsSelected = selectedLayers.length > 0;

  const toggleAllSelected = useCallback(() => {
    setSelectedLayers(() => {
      if (allIsSelected) {
        return [];
      }

      return layers.map((p) => p.id);
    });
  }, [allIsSelected, layers, setSelectedLayers]);

  const getLayerIsSelected = useCallback(
    (layerId: string) => {
      return selectedLayers.includes(layerId);
    },
    [selectedLayers],
  );

  const onToggleLayerSelected = useCallback(
    (layerId: string) => {
      Mixpanel.track("Toggle library package layer selected", {});
      setSelectedLayers((curr) => {
        if (curr.includes(layerId)) {
          return curr.filter((p) => p !== layerId);
        } else {
          return [...curr, layerId];
        }
      });
    },
    [setSelectedLayers],
  );

  const onGoToLayerClick = useCallback(
    (layerId: string) => {
      Mixpanel.track("Go to library layer from library package", {});
      scrollToOrganisationLayerId(layerId);
      setActivePackageId(undefined);
    },
    [setActivePackageId, scrollToOrganisationLayerId],
  );

  const onDeleteClick = useCallback(
    (layerId: string) => {
      const usage = layerUsages[layerId];
      Mixpanel.track("Remove library layer from library package", {});

      if (!usage) return;
      if (
        confirm(
          (usage.length !== 0
            ? `This layer is used in ${usage.length} projects.`
            : "") +
            "Are you sure you want to remove this layer from the package?",
        )
      ) {
        return removeLayersFromPackage(organisationId, packageId, [layerId]);
      }
    },
    [organisationId, packageId, removeLayersFromPackage, layerUsages],
  );

  const sortTypes = useMemo<Record<string, SortByFn<DataLibraryLayer>>>(
    () => getSortTypes(lastChanges, getUsername),
    [lastChanges, getUsername],
  );

  const { getTableProps, headerGroups, getTableBodyProps, page, prepareRow } =
    useTable<DataLibraryLayer>(
      {
        columns,
        data: layers,
        sortTypes,
        autoResetPage: false,
        autoResetExpanded: false,
        autoResetSortBy: false,
        initialState: {
          pageSize: 1000,
          pageIndex: 0,
          sortBy: [{ id: "created_at", desc: true }],
        },
      },
      useSortBy,
      usePagination,
    );

  // Deselect layers when packageId changes
  useEffect(() => {
    return () => {
      setSelectedLayers([]);
    };
  }, [setSelectedLayers, packageId]);

  return (
    <ScrollBody>
      <Table
        {...getTableProps({
          style: {
            width: "100%",
            borderSpacing: 0,
          },
        })}
      >
        <StickyThead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({
                      onClick: (e) => {
                        if (
                          !column.canSort ||
                          e.nativeEvent
                            .composedPath()
                            .find(
                              (e) =>
                                e instanceof HTMLElement &&
                                e.dataset.type === "checkbox",
                            )
                        ) {
                          return;
                        }

                        Mixpanel.track("Sort library packages", {
                          id: column.id,
                          desc: !column.isSortedDesc,
                        });
                        column.toggleSortBy(!column.isSortedDesc);
                      },
                      style: {
                        textAlign: "unset",
                        cursor: column.canSort ? "pointer" : "unset",
                        width: column.width,
                        padding: "0.5rem 0",
                        borderBottom: `1px solid ${colors.blue400}`,
                        // backgroundColor: "red",
                      },
                    }),
                  )}
                >
                  <Row alignCenter>
                    {column.render("Header", {
                      allIsSelected,
                      toggleAllSelected,
                      someIsSelected,
                    })}
                    {column.canSort && (
                      <span style={{ marginLeft: "0.3rem" }}>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <SortDescendingIcon />
                          ) : (
                            <SortAscendingIcon />
                          )
                        ) : (
                          <SortNeutralIcon />
                        )}
                      </span>
                    )}
                  </Row>
                </th>
              ))}
            </tr>
          ))}
        </StickyThead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            const isSelected = getLayerIsSelected(row.original.id);

            return (
              <React.Fragment key={row.original.id}>
                <tr
                  {...row.getRowProps({
                    style: {
                      cursor: "pointer",
                      borderSpacing: 0,
                    },
                  })}
                  onClick={() => {
                    onToggleLayerSelected(row.original.id);
                  }}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps({
                          style: {
                            wordWrap: "break-word",
                            whiteSpace: "pre-wrap",
                            padding: `${spacing4} 0`,
                            backgroundColor: isSelected
                              ? colors.blue200
                              : undefined,
                          },
                        })}
                      >
                        <React.Suspense fallback={<SkeletonText />}>
                          {cell.render("Cell", {
                            organisationId,
                            onGoToLayerClick,
                            onDeleteClick,
                            onToggleLayerSelected,
                            isSelected,
                            getLayerIsSelected,
                            layerUsages,
                            lastChanges: lastChanges.valueMaybe(),
                          })}
                        </React.Suspense>
                      </td>
                    );
                  })}
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    </ScrollBody>
  );
};

export default PackageTable;
