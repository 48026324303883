import { ProjectFeature } from "../../types/feature";
import { SnapFeature } from "./CustomModes/lib/snapping";
import { Position } from "geojson";
import { useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { projectFeaturesSelector } from "../ProjectElements/state";
import { projectFeatureMap } from "../../state/projectLayers";
import { movingWindow } from "../../utils/utils";

export let currentSnapPoints: Position[] = [];
export let currentSnapLines: [Position, Position][] = [];
export let currentFeatureMap: Map<string, ProjectFeature> = new Map();

export default function useUpdateSnapPoints() {
  const projectLayerFeaturesWithHistory = useRecoilValue(
    projectFeaturesSelector,
  );
  const featureMap = useRecoilValue(projectFeatureMap);

  useEffect(() => {
    currentFeatureMap = featureMap;
  }, [featureMap]);

  useEffect(() => {
    const snapPoints: Position[] = projectLayerFeaturesWithHistory.flatMap(
      (f) => {
        const type = f.geometry?.type;
        if (type === "Point") return [f.geometry.coordinates];
        else if (type === "LineString") return f.geometry.coordinates;
        else if (type === "Polygon") return f.geometry.coordinates.flat();
        return [];
      },
    );
    currentSnapPoints = snapPoints;

    const snapLines: [Position, Position][] =
      projectLayerFeaturesWithHistory.flatMap((f) => {
        const type = f.geometry?.type;
        if (type === "LineString") return movingWindow(f.geometry.coordinates);
        else if (type === "Polygon")
          return f.geometry.coordinates.map((c) => movingWindow(c)).flat();
        return [];
      });
    currentSnapLines = snapLines;
  }, [projectLayerFeaturesWithHistory]);
}

export function useSnapFeatures() {
  const projectLayerFeaturesWithHistory = useRecoilValue(
    projectFeaturesSelector,
  );
  const snapFeatures = useMemo(() => {
    const newSF: SnapFeature[] = projectLayerFeaturesWithHistory.flatMap(
      (f) => {
        if (f.geometry.type === "Point")
          return {
            featureId: f.id,
            point: f.geometry.coordinates,
            type: "Point",
            featureType: f.properties.type,
            parentIds: f.properties.parentIds,
          };
        return [];
      },
    );
    return newSF;
  }, [projectLayerFeaturesWithHistory]);

  return snapFeatures;
}
