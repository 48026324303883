/// <reference types="vite-plugin-svgr/client" />
import { PropsWithChildren, ReactNode, useCallback, useEffect } from "react";
import styled from "styled-components";
import Chevron from "@icons/24/ArrowRight.svg?react";
import useBooleanState from "../../hooks/useBooleanState";
import { colors } from "../../styles/colors";

const Title = styled.div<{ chevronSide: "left" | "right" }>`
  display: flex;
  ${(p) =>
    p.chevronSide === "right" &&
    `
    justify-content: space-between;
  `}

  ${(p) =>
    p.chevronSide === "left" &&
    `
    *:not(:last-child) {
      margin-right: 1rem;
    }
  `}
  font-size: 1.6rem;
  align-items: center;
`;

const ToggleableTitle = styled(Title)<{ stickyTitle?: boolean }>`
  cursor: pointer;
  ${({ stickyTitle }) =>
    stickyTitle &&
    `
    position: sticky;
    top: 0;
    background-color: ${colors.background};
    z-index: 1;
  `}
`;

const ChevronWrapper = styled.div<{ open: boolean }>`
  transform: rotate(${(p) => (p.open ? "90deg" : "0deg")});
  transition: 0.1s;
  svg {
    height: 2.2rem;
    width: 1.3rem;
  }
`;

const TitleAndIcon = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

interface Props extends PropsWithChildren {
  title: ReactNode;
  chevronSide?: "left" | "right";
  defaultOpen?: boolean;
  forceOpen?: boolean;
  stickyTitle?: boolean;
  onOpenChange?(isOpen: boolean): void;
  icon?: JSX.Element;
}

const ToggleableRow = ({
  title,
  children,
  defaultOpen,
  chevronSide = "right",
  forceOpen,
  onOpenChange,
  stickyTitle,
  icon,
}: Props) => {
  const [open, toggleOpen, setOpen] = useBooleanState(defaultOpen ?? false);

  const handleOnClick = useCallback(() => {
    toggleOpen();
    onOpenChange?.(!open);
  }, [onOpenChange, open, toggleOpen]);

  useEffect(() => {
    if (typeof forceOpen === "boolean") {
      setOpen(forceOpen);
    } else if (typeof defaultOpen === "boolean") {
      setOpen(defaultOpen);
    }
  }, [setOpen, forceOpen, defaultOpen]);

  return (
    <div>
      <ToggleableTitle
        onClick={handleOnClick}
        chevronSide={chevronSide}
        stickyTitle={stickyTitle}
      >
        {chevronSide === "left" && (
          <ChevronWrapper open={open}>
            <Chevron width={"1rem"} />
          </ChevronWrapper>
        )}
        <Title chevronSide={chevronSide}>
          <TitleAndIcon>
            <h4 style={{ margin: "0", fontWeight: "normal" }}>{title}</h4>
            {icon && icon}
          </TitleAndIcon>
        </Title>
        {chevronSide === "right" && (
          <ChevronWrapper open={open}>
            <Chevron width={"1rem"} />
          </ChevronWrapper>
        )}
      </ToggleableTitle>
      {open && children}
    </div>
  );
};

export default ToggleableRow;
