import { selectorFamily } from "recoil";
import { getViewShedAnalysis } from "../services/terrainAPIService";
import { fastMax, fastMin } from "../utils/utils";
import { scream } from "../utils/sentry";
import * as utm from "utm";
import { getDistanceFromLatLonInM, wgs84ToProjected } from "../utils/proj4";
import { Position } from "geojson";
import GeoTIFF, { fromBlob } from "geotiff";
import { z } from "zod";

const MAXIMUM_ALLOWED_DISTANCE = 100_000;

export const diagonalDistanceWithinAllowedRange = (coords: Position[]) => {
  const Xs = coords.map((c) => c[0]);
  const Ys = coords.map((c) => c[1]);

  const [minX, minY, maxX, maxY] = [
    fastMin(Xs),
    fastMin(Ys),
    fastMax(Xs),
    fastMax(Ys),
  ];

  return (
    getDistanceFromLatLonInM([minX, minY], [maxX, maxY]) <
    MAXIMUM_ALLOWED_DISTANCE
  );
};

export const getViewShedMultipleResultSelectorFamily = selectorFamily<
  {
    geotiff: GeoTIFF;
    originalTerrainUrl: string;
    viewshedResultUrl: string;
    pixelSize: [number, number];
  },
  { coords: Position[]; observerHeight: number }
>({
  key: "getViewShedMultipleResultSelectorFamily",
  get:
    ({ coords, observerHeight }) =>
    async () => {
      if (!diagonalDistanceWithinAllowedRange(coords))
        throw new Error("Distance between turbines are too great");

      const Xs = coords.map((c) => c[0]);
      const Ys = coords.map((c) => c[1]);

      const [minX, minY, maxX, maxY] = [
        fastMin(Xs),
        fastMin(Ys),
        fastMax(Xs),
        fastMax(Ys),
      ];

      const origoUTM = utm.fromLatLon((maxY - minY) / 2, (maxX - minX) / 2);

      const projectedCoordinates = coords.map((c) => [
        ...wgs84ToProjected(
          c,
          `+proj=utm +zone=${origoUTM.zoneNum} +datum=WGS84 +units=m +no_defs +type=crs`,
        ),
        c[2],
      ]);

      const epsg = parseInt(
        `32${origoUTM.zoneLetter === "N" ? 6 : 7}${origoUTM.zoneNum}`,
      );

      const zoom = coords.length > 80 ? 9 : 10;

      const res = await getViewShedAnalysis(
        projectedCoordinates,
        epsg,
        zoom,
        observerHeight,
      );

      if (!res.ok) {
        throw scream("Unable to read viewshed tiff", { res });
      }

      const parser = z.object({
        originalterrain: z.string(),
        viewshed: z.string(),
      });
      const j = await res.json();
      const jsonRes = parser.parse(j);

      const viewShedRes = await fetch(jsonRes.viewshed);

      if (!viewShedRes.ok) {
        throw scream("Unable to read viewshed tiff", { res });
      }

      const blob = await viewShedRes.blob();
      const geotiff = await fromBlob(blob);
      const image = await geotiff.getImage();
      const [xMin, yMin, xMax, yMax] = image.getBoundingBox();
      const pixelSize: [number, number] = [
        Math.round((xMax - xMin) / image.getWidth()),
        Math.round((yMax - yMin) / image.getHeight()),
      ];
      return {
        geotiff,
        pixelSize,
        originalTerrainUrl: jsonRes.originalterrain,
        viewshedResultUrl: jsonRes.viewshed,
      };
    },
  dangerouslyAllowMutability: true,
});
