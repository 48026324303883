import styled from "styled-components";

export const TransparentMapColorIndicator = styled.div`
  width: 0.3rem;
  height: 2rem;
  opacity: 0;
  flex-shrink: 0;
`;

export const MapColorIndicator = styled(TransparentMapColorIndicator)<{
  color?: string;
  opacity?: number;
}>`
  width: 0.3rem;
  height: 2rem;
  opacity: ${(p) => p.opacity ?? "0"};
  background-color: ${(p) => p.color ?? "#fff"};
`;
