import {
  Loadable,
  RecoilLoadable,
  RecoilValue,
  selector,
  useRecoilValue,
  useRecoilValueLoadable,
} from "recoil";
import { scream } from "./sentry";

/**
 * Gets a recoil value, asserting that it is not `undefined`.
 */
export const useRecoilValueDef = <T>(
  recoilValue: RecoilValue<T | undefined>,
) => {
  const val = useRecoilValue(recoilValue);
  if (!val)
    throw scream("useRecoilValueDef: value was undefined", { recoilValue });
  return val;
};

/**
 * `get` this selector if you want your selector to never resolve.
 */
export const suspendThisSelector = selector<never>({
  key: "suspendThisSelector",
  get: async () => {
    await new Promise(() => {});
    throw undefined;
  },
  set: () => {
    throw new Error("This selector should never be set.");
  },
});

export const useRecoilValueLoadable2 = <T>(
  recoilValue: RecoilValue<T>,
): Loadable<T | undefined> => {
  const l = useRecoilValueLoadable(recoilValue);
  try {
    const val = useRecoilValue(recoilValue);
    return RecoilLoadable.of(val);
  } catch (e) {
    if (l.state === "loading") {
      // WARN: When `useRecoilValue` on an `atom` without a default value, the
      // promise that's thrown resolves to `undefined` when the atom is set.
      // This kinda makes sense, since what other value should it resolve to?
      // We're using this promise to figure out when to update this hook here,
      // since that's the problem with `useRecoilValueLoadable` when we throw
      // promises that don't resolve. However, by returning this here we're
      // giving back a Loadable which value will be undefined /when/ it
      // resolves (because it will resolve when the `atom` is finally set).
      const p = e as Promise<T | undefined>;
      return RecoilLoadable.of(p);
    }
    return l;
  }
};
