/// <reference types="vite-plugin-svgr/client" />
import styled from "styled-components";
import { ContentWrapper } from "../style";
import { colors } from "styles/colors";
import ListIcon from "@icons/24/List.svg?react";
import { spaceTiny, spacing6 } from "styles/space";

const NoResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: ${spaceTiny};
  padding: 2.4rem;
  border-radius: 0.4rem;
  background-color: ${colors.blue50};
  color: ${colors.textPrimary};
`;

export const RoundIconWrapper = styled.div`
  background-color: ${colors.blue900};
  border-radius: 50%;
  padding: ${spacing6};
  box-sizing: border-box;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ListIconWrapper = styled(RoundIconWrapper)`
  background-color: ${colors.surfaceBrand};
  svg {
    width: 1.6rem;
    height: 1.6rem;
    path {
      fill: ${colors.iconNegative};
    }
  }
`;

export default function EmptyContent() {
  return (
    <ContentWrapper>
      {" "}
      <NoResultsWrapper>
        <ListIconWrapper>
          <ListIcon />
        </ListIconWrapper>
        <p>Select an item to se the details</p>
      </NoResultsWrapper>
    </ContentWrapper>
  );
}
