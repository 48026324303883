import {
  getAEPDistribution,
  getAnalysisProgress,
  getNetEmpiricalPercentiles,
  getStoppedReason,
  getStoppedReasonFromAnalysis,
} from "components/ProductionV2/state";
import {
  AnalysisStoppedTypes,
  analysisStoppedText,
} from "components/ProductionV2/types";
import Plotly from "plotly.js-dist-min";
import { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { colors } from "../../../styles/colors";
import { CenterContainer, LoadingState, SafeCard } from "./Base";
import { Percentile } from "functions/production";
import { fastMax, fastMin } from "utils/utils";
import { SkeletonText } from "components/Loading/Skeleton";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useDashboardContext } from "../Dashboard";

const AEPGraph = () => {
  const { triggerId } = useDashboardContext();
  const stoppedReason = useRecoilValue(getStoppedReason(triggerId));
  const analysisStoppedReason = useRecoilValue(
    getStoppedReasonFromAnalysis(triggerId),
  );
  const analysisProgress = useRecoilValue(getAnalysisProgress(triggerId));
  const percentiles = useRecoilValue(getNetEmpiricalPercentiles(triggerId));
  const distribution = useRecoilValue(getAEPDistribution(triggerId));

  if (stoppedReason) {
    return (
      <CenterContainer>
        <SimpleAlert
          title="Analysis stopped"
          text={analysisStoppedText[stoppedReason]}
          type={"error"}
        />
      </CenterContainer>
    );
  }
  if (analysisStoppedReason) {
    return (
      <SimpleAlert
        title="Analysis stopped"
        text={
          analysisStoppedText[analysisStoppedReason as AnalysisStoppedTypes]
        }
        type={"error"}
      />
    );
  }

  if (!percentiles || !distribution) {
    if (analysisProgress)
      return (
        <SkeletonText
          style={{ margin: "2rem", height: "2rem" }}
          text={`${Math.round(analysisProgress * 100)}%`}
        />
      );
    return <LoadingState />;
  }

  return (
    <AEPGraphInner percentiles={percentiles} distribution={distribution} />
  );
};

const AEPGraphInner = ({
  percentiles,
  distribution,
}: {
  percentiles: Percentile[];
  distribution: { mean: number; std: number };
}) => {
  const graphRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    const current = graphRef.current;
    if (!isLoaded || !current) return;
    const observer = new ResizeObserver(() => {
      if (!current.clientWidth) return;
      Plotly.Plots.resize(current);
    });
    if (current) observer.observe(current);
    return () => observer.disconnect();
  }, [isLoaded]);

  useEffect(() => {
    if (!graphRef.current) return;
    setIsLoaded(false);

    const x = percentiles.map((p) => p.value);
    const xmin = fastMin(x);
    const xmax = fastMax(x);
    const step = (xmax - xmin) / (x.length / 2);
    const xStart = xmin - 2 * step;
    const xStop = xmax + 2 * step;
    const n = Math.round((xStop - xStart) / step);
    const refine = 10;
    if (!n || n < 0) return;

    const p90 = percentiles.find((p) => p.percentile === 10)!.value;
    const p10 = percentiles.find((p) => p.percentile === 90)!.value;

    const xx = new Array(n * refine)
      .fill(0)
      .map((_, i) => (i / refine) * step + xStart);
    const yy = xx.map(
      (_x) =>
        (1 / distribution.std / Math.sqrt(2 * Math.PI)) *
        Math.exp(-0.5 * ((_x - distribution.mean) / distribution.std) ** 2),
    );
    const yMax = fastMax(yy);

    var data = [
      {
        x,
        name: "Net energy",
        type: "histogram" as const,
        textposition: "auto" as const,
        hoverinfo: "none" as const,
        autobinx: false,
        opacity: 0.8,
        marker: {
          color: colors.hoverSelected,
          line: {
            color: colors.brand,
            width: 1.5,
          },
        },
        xbins: {
          start: xmin - 2 * step,
          size: step,
          end: xmax + 2 * step,
        },
      },
      {
        x: [p90, p90],
        y: [0, yMax],
        mode: "lines",
        yaxis: "y2",
        showlegend: false,
        hoverinfo: "none" as const,
        text: "hi",
        textposition: [p90, 4],
        opacity: 0.8,
        marker: {
          color: "black",
        },
      },
      {
        x: [p10, p10],
        y: [0, yMax],
        mode: "lines",
        yaxis: "y2",
        showlegend: false,
        textposition: "auto" as const,
        hoverinfo: "none" as const,
        opacity: 0.8,
        marker: {
          color: "black",
        },
      },
      {
        x: xx,
        y: yy,
        name: "Normal fit",
        textposition: "auto" as const,
        hoverinfo: "none" as const,
        mode: "lines",
        opacity: 0.8,
        yaxis: "y2",
        marker: {
          color: colors.hoverSelected,
        },
      },
    ];
    var layout = {
      font: { size: 10, family: "Open Sans" },
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
      autosize: true,
      legend: {
        x: 1.1,
        xanchor: "right" as const,
        y: 10,
      },
      height: 200,
      bargap: 0.05,
      yaxis: {
        title: "Number of years",
        rangemode: "tozero" as const,
      },
      yaxis2: {
        rangemode: "tozero" as const,
        showgrid: false,
        showticklabels: false,
      } as const,
      xaxis: { title: "GWh" },
      margin: {
        l: 60,
        r: 20,
        b: 30,
        t: 30,
      },
      annotations: [
        {
          x: p90,
          y: yMax,
          showarrow: false,
          yref: "y2" as const,
          text: `P90: ${Math.round(p90)}`,
          xanchor: "right" as const,
          yanchor: "top" as const,
          textangle: "-90",
        },
        {
          x: p10,
          y: yMax,
          text: `P10: ${Math.round(p10)}`,
          showarrow: false,
          yref: "y2" as const,
          xanchor: "left" as const,
          yanchor: "top" as const,
          textangle: "90",
        },
      ],
    };

    Plotly.newPlot(graphRef.current, data, layout, {
      displayModeBar: false,
      responsive: true,
      staticPlot: true,
    }).then(() => setIsLoaded(true));
  }, [graphRef, percentiles, distribution]);

  return <div ref={graphRef} />;
};

export const AEPDistributionWidget = () => (
  <SafeCard title="Annual variability" id="Annual variability">
    <AEPGraph />
  </SafeCard>
);
