import styled from "styled-components";
import { colors } from "../../../styles/colors";
import { Container } from "styles/misc/Misc";

export const ANIMATION_DURATION = 0.3;
export const CONTAINER_MIN_WIDTH = "30rem";
export const LeftModalContainer = styled(Container)<{ open: boolean }>`
  position: absolute;
  bottom: calc(var(--side-bars-width));
  z-index: 1;
  ${({ open }) =>
    open
      ? `
    left: -1rem;
  `
      : `
    left: calc(0rem - calc(var(--left-menu-width) + 5rem)); 
  `}
  height: calc(100vh - calc(var(--top-bar-menu-height) + var(--branch-tab-bar-height) + var(--side-bars-width)) - 2.4rem);
  overflow: ${(p) => (p.open ? "auto" : "hidden")};
  display: flex;
  flex-direction: row;
  transition: all ${ANIMATION_DURATION}s ease-in-out;
  padding: 0.8rem 1rem;
`;

export const CloseContainer = styled.div`
  padding: 0.8rem 0;
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 2;

  cursor: pointer;

  > svg {
    width: 1.6rem;
    height: 1.6rem;
  }
`;

export const ShadedButton = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  cursor: pointer;
  gap: 0.5rem;
  transition: 0.075s ease-in-out;
  border-radius: 6px;

  ${(o) =>
    o.selected
      ? `
  background: ${colors.brand}; 
  p {
    color: ${colors.focusBackground};
  }

  path, circle {
    stroke: ${colors.focusBackground};
  }

  &:hover {
    background: ${colors.primaryHover};
  }
  `
      : `
  filter: opacity(50%);
  &:hover {
    filter: opacity(100%);
  }
  &:active {
    filter: opacity(75%);
  }
    `}
`;

export const HiddenIconWrapper = styled.div<{
  size?: number;
  show?: boolean;
  iconColor?: string;
  iconHoverColor?: string;
  selected?: boolean;
  overlap?: boolean;
}>`
  ${(p) => (p.overlap ? "position: absolute;  right: 0;" : "")}
  ${(p) => (p.overlap ? "padding: 0 0.2rem 0 5rem;" : "padding: 0 0.2rem;")}
  cursor: pointer;
  opacity: ${(p) => (p.show ? "1" : "0")};
  transition: 0.1s ease-in-out;
  display: flex;
  align-items: center;
  pointer-events: none;
  ${(p) =>
    p.overlap
      ? `background: linear-gradient(to left, ${p.selected ? colors.blue200 : colors.blue900}, transparent);`
      : ""}

  svg {
    width: ${(p) => (p.size ? p.size : 1.6)}rem;
    height: ${(p) => (p.size ? p.size : 1.6)}rem;
    pointer-events: auto;
    path {
      stroke: ${(p) => p.iconColor ?? colors.secondaryText};
      fill: transparent;
    }
  }
  &:hover {
    svg {
      path {
        stroke: ${(p) => p.iconHoverColor ?? colors.brand};
        fill: transparent;
      }
    }
  }
`;

export const NewSnapshotButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 1.6rem;
`;

export const BranchHeader = styled.h2`
  margin: 0;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
