import { ReactNode } from "react";
import { z } from "zod";

export type ComparisonType =
  | "POSITIVE_CHECK"
  | "NEGATIVE_CHECK"
  | "NEUTRAL_CHECK"
  | "LIST_CHECK";
export type CompareColumnItemAttribute = {
  key: string;
  type: "item";
  name: string;
  unit?: string;
  csvFormat(value: any): string;
  format(value: any): ReactNode;
  compareValues?(
    baseline: any,
    thisValue: any,
  ):
    | {
        type: ComparisonType;
        result: number | number[];
      }
    | undefined;
  formatComparisonResult?(result: number): ReactNode;
  infoMessage?: string;
};

export type CompareColumnListItemAttribute = {
  type: "list";
  name: string;
  key: string;
  values: CompareColumnItemAttribute[];
};

export type Columns = Record<
  ComparisonAttributeKey,
  {
    name: string;
    configSelector?: ReactNode;
    attributes: Array<
      CompareColumnItemAttribute | CompareColumnListItemAttribute
    >;
  }
>;

export const _ComparisonAttributesPark = z.enum([
  "area",
  "averageDepth",
  "depthRange",
  "parkLocation",
  "turbines",
  "turbineTypesString",
  "averageTurbineHeight",
  "nrTurbines",
]);

export const _ComparisonAttributesProduction = z.enum([
  "capacity",
  "capacityDensity",
  "capacityFactor",
  "netEnergy",
  "grossEnergy",
  "otherLoss",
  "totalWakeLoss",
  "neighbourWakeLoss",
  "internalWakeLoss",
  "progress",
  "analysisVersion",
  "turbineSpecificLoss",
  "wakeModel",
]);

export const _ComparisonAttributesWind = z.enum([
  "avgPowerLawCoefficient",
  "avgTurbineWindSpeed",
  "avgSourceWindSpeed",
  "airDensity",
  "height",
  "windSource",
  "location",
]);

export const _ComparisonAttributesCabling = z.enum([
  "exportCableLength",
  "totalInterArrayLoss",
  "totalExportSystemLoss",
  "numberExportCables",
  "cableTypes",
  "exportCableTypesString",
  "exportCableTypesOnshoreString",
  "cableLengthContingency",
  "interArrayCableLength",
  "numberOffShoreSubstations",
  "offshoreCableLength",
  "onshoreCableLength",
  "interArrayCableVoltages",
  "exportCableVoltages",
  "maxExportCableUtilizationOffshore",
  "maxExportCableUtilizationOnshore",
]);

export const _ComparisonAttributesFINANCIAL = z.enum([
  "lcoe",
  "aep",
  "capex",
  "devex",
  "decommissioning",
  "lifeTime",
  "opex",
  "irr",
  "turbineCost",
  "cableCost",
  "foundationCost",
  "mooringCost",
  "substationCost",
  "exportCableCost",
  "otherCapexCost",
]);

export const _ComparisonAttributesFoundations = z.enum([
  "primarySteelWeight",
  "minFoundationWeight",
  "avgFoundationWeight",
  "maxFoundationWeight",
  "minFoundationDepth",
  "avgFoundationDepth",
  "maxFoundationDepth",
]);

export const _ComparisonAttributesFinancialInputs = z.enum([
  "contingency",
  "discountRate",
  "inflationRate",
  "turbineCost",
  "lifeTime",
  "aep",
]);

export enum ComparisonAttributeKey {
  PARK = "PARK",
  PRODUCTION = "PRODUCTION",
  WIND = "WIND",
  CABLING = "CABLING",
  FINANCIAL = "FINANCIAL",
  FOUNDATIONS = "FOUNDATIONS",
  FINANCIAL_INPUTS = "FINANCIAL_INPUTS",
}

export const comparisonAttributeKeys: ComparisonAttributeKey[] = [
  ComparisonAttributeKey.PARK,
  ComparisonAttributeKey.PRODUCTION,
  ComparisonAttributeKey.WIND,
  ComparisonAttributeKey.CABLING,
  ComparisonAttributeKey.FINANCIAL,
  ComparisonAttributeKey.FOUNDATIONS,
  ComparisonAttributeKey.FINANCIAL_INPUTS,
];
