import React, { useState, useEffect, useCallback } from "react";
import { useRecoilValue, useRecoilCallback } from "recoil";
import * as turf from "@turf/turf";
import DownloadIcon from "@icons/24/Download.svg";
import { setHover, removeHover } from "components/Mapbox/utils";
import { exportCableSourceId } from "components/Mapbox/constants";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { exportCableSplitsOk } from "functions/elevation";
import { useDashboardContext } from "../Dashboard";
import { cableMatrixMap } from "./CableMatrixMapWidget";
import { CenterContainer, SafeCard } from "./Base";
import Table from "components/Dashboard/Table";
import { currentExportCableTypesSelector } from "components/Cabling/Generate/state";
import { CableType } from "services/cableTypeService";
import { wattToMegawatt } from "components/ConfigurationModal/ExportCableSettings";
import { MenuItem } from "components/General/Menu";
import { downloadText } from "utils/utils";
import { kmStyle } from "components/Dashboard/utils";

type ExportCableInfo = {
  featureId: string;
  name: string | undefined;
  offshoreLength: number;
  onshoreLength: number;
  totalLength: number;
  powerRating?: number;
  voltage?: number;
};

const useData = () => {
  const { park, branch } = useDashboardContext();
  return useRecoilCallback<any, Promise<ExportCableInfo[]>>(
    ({ snapshot }) =>
      async () => {
        const exportCableTypes = await snapshot.getPromise(
          currentExportCableTypesSelector,
        );
        const exportCableTypesMap = exportCableTypes.reduce<
          Record<string, CableType>
        >((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {});

        const exportCableSegments = await snapshot.getPromise(
          exportCableSplitsOk({ parkId: park.id, branchId: branch.id }),
        );

        return exportCableSegments
          .map((f) => {
            const cableType = f.exportCable.properties.cableTypeId
              ? exportCableTypesMap[f.exportCable.properties.cableTypeId]
              : undefined;

            return {
              featureId: f.exportCable.id,
              name: f.exportCable.properties.name,
              offshoreFeatureId: f.offshore.id,
              offshoreLength: turf.length(f.offshore, { units: "kilometers" }),
              onshoreFeatureId: f.onshore.id,
              onshoreLength: turf.length(f.onshore, { units: "kilometers" }),
              powerRating: cableType?.powerRating,
              voltage: cableType?.voltage,
            };
          })
          .map((f) => ({
            ...f,
            totalLength: f.offshoreLength + f.onshoreLength,
          }));
      },
    [park.id, branch.id],
  );
};

const ExportCables = () => {
  const map = useRecoilValue(cableMatrixMap);
  const getData = useData();

  const [dataPerExportCable, setData] = useState<
    undefined | ExportCableInfo[] | Error
  >(undefined);

  useEffect(() => {
    let isMounted = true;
    getData()
      .then((d) => {
        if (isMounted) {
          setData(d);
        }
      })
      .catch((e) => {
        if (isMounted) {
          setData(e);
        }
      });
    return () => {
      isMounted = false;
    };
  }, [getData]);

  if (dataPerExportCable instanceof Error) {
    throw dataPerExportCable;
  }

  if (dataPerExportCable?.length === 0)
    return (
      <CenterContainer>
        <SimpleAlert text={"No export cables in park."} />
      </CenterContainer>
    );

  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Power rating (MW)</th>
          <th>Voltage (kV)</th>
          <th>Offshore cable length (km)</th>
          <th>Onshore cable length (km)</th>
          <th>Total length (km)</th>
        </tr>
      </thead>
      <tbody>
        {dataPerExportCable?.map((cable) => (
          <tr
            key={cable.featureId}
            onMouseEnter={() => {
              if (cable && map) {
                setHover(map, exportCableSourceId, cable.featureId);
              }
            }}
            onMouseLeave={() => {
              if (cable && map) {
                removeHover(map, exportCableSourceId, cable.featureId);
              }
            }}
          >
            <td>{cable.name}</td>
            <td>
              {cable.powerRating ? wattToMegawatt(cable.powerRating) : "-"}
            </td>
            <td>{cable.voltage ? cable.voltage : "-"}</td>
            <td>{kmStyle.format(cable.offshoreLength)}</td>
            <td>{kmStyle.format(cable.onshoreLength)}</td>
            <td
              style={{
                fontWeight: 600,
              }}
            >
              {kmStyle.format(cable.totalLength)} km
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const ExportCablesMenu = () => {
  const getData = useData();
  const { park, branch } = useDashboardContext();

  const downloadCSV = useCallback(async () => {
    const data = await getData();
    const csvRows = [
      [
        "Name",
        "Power rating (MW)",
        "Voltage (kV)",
        "Offshore cable length (km)",
        "Onshore cable length (km)",
        "Total length (km)",
      ],
      ...data.map((cable) => [
        cable.name,
        cable.powerRating ? wattToMegawatt(cable.powerRating) : "",
        cable.voltage ? cable.voltage : "",
        kmStyle.format(cable.offshoreLength),
        kmStyle.format(cable.onshoreLength),
        kmStyle.format(cable.totalLength),
      ]),
    ];
    downloadText(
      csvRows.join("\n"),
      `${park.properties.name}_${branch.title}_export_cables.csv`,
    );
  }, [branch.title, getData, park.properties.name]);

  return (
    <MenuItem
      name={"Download as .csv"}
      icon={<DownloadIcon />}
      onClick={downloadCSV}
    />
  );
};

export const ExportCablesWidget = () => (
  <SafeCard
    title="Export cables"
    id="Export cables"
    menuItems={<ExportCablesMenu />}
  >
    <ExportCables />
  </SafeCard>
);
