import React from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import { spacing4 } from "styles/space";
import { IconREMSize } from "styles/typography";

export const RoundIconWrapper = styled.div`
  background-color: ${colors.blue200};
  border-radius: 50%;
  padding: ${spacing4};
  box-sizing: border-box;
  transition: background-color 0.2s;
`;

const RoundIcon = ({
  children,
  fillOrStroke = "stroke",
}: { fillOrStroke?: "stroke" | "fill" } & React.PropsWithChildren) => {
  return (
    <RoundIconWrapper>
      <IconREMSize
        height={1.2}
        width={1.2}
        {...{ [fillOrStroke]: colors.blue900 }}
      >
        {children}
      </IconREMSize>
    </RoundIconWrapper>
  );
};

export default RoundIcon;
