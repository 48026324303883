import { z } from "zod";
import { MILLION, THOUSAND } from "../constants/financialAnalysis";

export enum ConfidenceLevel {
  notSpecified = "Not specified",
  uncertain = "Uncertain",
  verified = "Verified",
}

export type CostWithUnit = {
  cost: number;
  unit: CostUnit;
  confidenceLevel: ConfidenceLevel;
};

export enum CostUnit {
  millionEuroPerUnit = "m€/unit",
  millionEuroPerOSS = "m€/OSS",
  millionEuroPerONS = "m€/ONS",
  millionEuroPerMW = "m€/MW",
  millionEuroPerKM = "m€/km",
  thousandEuroPerMW = "k€/MW",
  thousandEuroPerMWPerYear = "k€/MW/y",
  euroPerKM = "€/km",
  millionEuroPerCable = "m€/cable",
  euroPerM3 = "€/m³",
  euroPerT = "€/t",
  euroPerMWh = "€/MWh",
  millionEuroPerLine = "m€/line",
  millionEuro = "m€",
  gigaWattHours = "GWh",
  percent = "%",
  years = "years",
}
export const _CostUnit = z.nativeEnum(CostUnit);

export enum OccuranceType {
  Yearly = "yearly",
  Recurring = "recurring",
  SingleEvent = "singleEvent",
}
export const _Occurance = z.nativeEnum(OccuranceType);

export enum AmountUnit {
  MW = "MW",
  unit = "unit",
  OSS = "OSS",
  ONS = "ONS",
  km = "km",
  t = "t",
  line = "line",
  string = "string",
  cable = "cable",
  m3 = "m3",
  fixed = "fixed",
  amountUnit = "%",
}
export const _AmountUnit = z.nativeEnum(AmountUnit);

export const unitToAmountUnit = {
  [CostUnit.millionEuroPerUnit]: AmountUnit.unit,
  [CostUnit.millionEuroPerOSS]: AmountUnit.OSS,
  [CostUnit.millionEuroPerONS]: AmountUnit.ONS,
  [CostUnit.millionEuroPerMW]: AmountUnit.MW,
  [CostUnit.thousandEuroPerMW]: AmountUnit.MW,
  [CostUnit.millionEuroPerKM]: AmountUnit.km,
  [CostUnit.millionEuro]: AmountUnit.fixed,
  [CostUnit.euroPerKM]: AmountUnit.km,
  [CostUnit.millionEuroPerCable]: AmountUnit.cable,
  [CostUnit.euroPerM3]: AmountUnit.m3,
  [CostUnit.euroPerT]: AmountUnit.t,
  [CostUnit.millionEuroPerLine]: AmountUnit.line,
} as Record<CostUnit, AmountUnit>;

export const unitToScalingMap = {
  [CostUnit.millionEuroPerUnit]: MILLION,
  [CostUnit.millionEuroPerOSS]: MILLION,
  [CostUnit.millionEuroPerONS]: MILLION,
  [CostUnit.millionEuroPerMW]: MILLION,
  [CostUnit.thousandEuroPerMW]: THOUSAND,
  [CostUnit.thousandEuroPerMWPerYear]: THOUSAND,
  [CostUnit.euroPerMWh]: 1,
  [CostUnit.millionEuroPerKM]: MILLION,
  [CostUnit.millionEuro]: MILLION,
  [CostUnit.euroPerKM]: 1,
  [CostUnit.millionEuroPerCable]: MILLION,
  [CostUnit.euroPerM3]: 1,
  [CostUnit.euroPerT]: 1,
  [CostUnit.millionEuroPerLine]: MILLION,
  [CostUnit.percent]: 1 / 100,
} as Record<CostUnit, number>;

export const amountUnitPrefixSuffix = {
  [AmountUnit.ONS]: { prefix: "#" },
  [AmountUnit.OSS]: { prefix: "#" },
  [AmountUnit.unit]: { prefix: "#" },
  [AmountUnit.cable]: { prefix: "#" },
  [AmountUnit.string]: { prefix: "#" },
  [AmountUnit.line]: { prefix: "#" },
  [AmountUnit.fixed]: { prefix: "#" },
  [AmountUnit.km]: { suffix: "km" },
  [AmountUnit.MW]: { suffix: "MW" },
  [AmountUnit.m3]: { suffix: "m³" },
  [AmountUnit.t]: { suffix: "t" },
} as Record<AmountUnit, { prefix?: string; suffix?: string }>;
