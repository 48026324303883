import mapboxgl from "mapbox-gl";
import { useEffect, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { mapRefAtom } from "../state/map";
import { meanSpeedGridLimitsAtom, WRG } from "../state/windStatistics";
import { COLORS } from "./windSpeed";
import { scream } from "../utils/sentry";
import { weibullMedian } from "functions/windStatistics";

class PointSpeedLayer {
  id: string;
  type: string;
  source: string;
  lonlats: number[][];
  speeds: number[];
  minSpeed: number;
  maxSpeed: number;
  program: WebGLProgram | undefined;
  aPos: number | undefined;
  aColor: number | undefined;
  buffer: WebGLBuffer | undefined | null;
  texcoordBuffer: WebGLBuffer | undefined;
  texcoordLocation?: number;
  texture: any;
  palette: any;
  paletteTex: any;
  colors: number[][];
  constructor(lonlats: number[][], speeds: number[], limits: [number, number]) {
    this.id = "point-speed-grid";
    this.type = "custom";
    this.source = "point-speed-grid";
    this.lonlats = lonlats;
    this.speeds = speeds;
    this.minSpeed = limits[0];
    this.maxSpeed = limits[1];
    this.colors = [];
  }
  onAdd(map: mapboxgl.Map, gl: WebGLRenderingContext) {
    const vertexSource = `
    precision mediump float;
            uniform mat4 u_matrix;
            attribute vec2 a_pos;
            attribute vec3 a_color;
            varying vec3 v_color;
            void main() {
                v_color = a_color;
                gl_Position = u_matrix * vec4(a_pos, 0.0, 1.0);
                gl_PointSize = 20.0;
            }`;

    // Create GLSL source for fragment shader
    const fragmentSource = `
        precision mediump float;
            varying vec3 v_color;
            void main() {
                gl_FragColor = vec4(v_color, 0.9);
            }`;
    // create a vertex shader
    const vertexShader = gl.createShader(gl.VERTEX_SHADER);
    if (!vertexShader) throw scream("speedGrid failed to create vertexShader");
    gl.shaderSource(vertexShader, vertexSource);
    gl.compileShader(vertexShader);

    // create a fragment shader
    const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
    if (!fragmentShader)
      throw scream("speedGrid failed to create fragmentShader");
    gl.shaderSource(fragmentShader, fragmentSource);
    gl.compileShader(fragmentShader);

    if (!gl.getShaderParameter(vertexShader, gl.COMPILE_STATUS)) {
      const info = gl.getShaderInfoLog(vertexShader);
      throw new Error(`Could not compile vertex shader: ${info}`);
    }

    if (!gl.getShaderParameter(fragmentShader, gl.COMPILE_STATUS)) {
      const info = gl.getShaderInfoLog(fragmentShader);
      throw new Error(`Could not compile fragment shader: ${info}`);
    }

    // link the two shaders into a WebGL program
    const program = gl.createProgram();
    if (!program) throw scream("speedGrid failed to create program");
    this.program = program;

    gl.attachShader(this.program, vertexShader);
    gl.attachShader(this.program, fragmentShader);
    gl.linkProgram(this.program);

    this.aPos = gl.getAttribLocation(this.program, "a_pos");
    this.aColor = gl.getAttribLocation(this.program, "a_color");

    const mercatorPoints = this.lonlats.map((lonlat) =>
      mapboxgl.MercatorCoordinate.fromLngLat({
        lng: lonlat[0],
        lat: lonlat[1],
      }),
    );
    const nColors = COLORS.default.length;
    const indices = this.speeds.map((s) =>
      Math.max(
        Math.min(
          Math.floor(
            (nColors * (s - this.minSpeed)) / (this.maxSpeed - this.minSpeed),
          ) + 1,
          nColors - 1,
        ),
        0,
      ),
    );
    const colors = indices.flatMap((i) => COLORS.default[i].slice(0, 3));
    const points = mercatorPoints.flatMap((p) => [p.x, p.y]);

    // create and initialize a WebGLBuffer to store vertex and color data
    const combinedData = [];
    for (let i = 0; i < points.length / 2; i++) {
      combinedData.push(
        points[i * 2],
        points[i * 2 + 1],
        colors[i * 3] / 255,
        colors[i * 3 + 1] / 255,
        colors[i * 3 + 2] / 255,
      );
    }
    this.buffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, this.buffer);
    gl.bufferData(
      gl.ARRAY_BUFFER,
      new Float32Array(combinedData),
      gl.STATIC_DRAW,
    );
  }

  render(gl: WebGLRenderingContext, matrix: number[]) {
    if (
      !this.program ||
      this.aPos === undefined ||
      !this.buffer ||
      !this.aColor
    )
      return;
    gl.useProgram(this.program);
    gl.uniformMatrix4fv(
      gl.getUniformLocation(this.program, "u_matrix"),
      false,
      matrix,
    );
    gl.bindBuffer(gl.ARRAY_BUFFER, this.buffer);
    gl.enableVertexAttribArray(this.aPos);
    gl.vertexAttribPointer(this.aPos, 2, gl.FLOAT, false, 20, 0);
    gl.enableVertexAttribArray(this.aColor);
    gl.vertexAttribPointer(this.aColor, 3, gl.FLOAT, false, 20, 8);
    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
    gl.drawArrays(gl.POINTS, 0, this.lonlats.length);
  }
}

const WRGSpeedGrid = ({
  wrg,
  sectorIndex,
}: {
  wrg: WRG;
  sectorIndex: number;
}) => {
  const setMeanSpeedLimits = useSetRecoilState(meanSpeedGridLimitsAtom);
  const map = useRecoilValue(mapRefAtom);

  const speeds = useMemo(() => {
    const points = wrg.points.map((p) => {
      return p.sectors[sectorIndex];
    });
    return points.map((p) => weibullMedian(p.A, p.k));
  }, [wrg, sectorIndex]);

  const lonlats = useMemo(() => {
    return wrg.points.map((p) => [p.lon, p.lat]);
  }, [wrg.points]);

  const limits: [number, number] = useMemo(() => {
    const min = speeds
      .filter((s) => s > 1)
      .reduce((a, v) => Math.min(a, v), 12);
    const max = speeds.reduce((a, v) => Math.max(a, v), 5);
    return [min, max];
  }, [speeds]);

  useEffect(() => {
    setMeanSpeedLimits(limits);
    return () => setMeanSpeedLimits(undefined);
  }, [limits, setMeanSpeedLimits]);

  const speedLayer = usePointSpeedGridActive({
    speeds,
    lonlats,
    limits,
  });

  useEffect(() => {
    if (!map || !speedLayer) return;
    map.addLayer(speedLayer as any, "building");
    return () => {
      map.removeLayer(speedLayer.id);
    };
  }, [map, speedLayer]);

  return null;
};

export const usePointSpeedGridActive = ({
  speeds,
  lonlats,
  limits,
}: {
  speeds: number[];
  lonlats: number[][];
  limits: [number, number];
}) => {
  const speedLayer = useMemo(() => {
    return new PointSpeedLayer(lonlats, speeds, limits);
  }, [speeds, lonlats, limits]);

  return speedLayer;
};

export default WRGSpeedGrid;
