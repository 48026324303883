import { atom, atomFamily, selectorFamily } from "recoil";
import { projectFeatureMap } from "../../state/projectLayers";
import {
  getForumThreads,
  getReplyReactions,
  getThreadReactions,
  getThreadReplies,
  getUserReactions,
  getUserThreadFollows,
} from "./service";
import { Thread, Reply, Reaction, ThreadFollow } from "./types";
import { syncLocalStorage } from "state/effects";
import { z } from "zod";

export const newThreadAtomFamily = atomFamily<
  { featureId: string; mapPosition?: [number, number] } | undefined,
  { projectId: string }
>({
  key: "newThreadAtomFamily",
  default: undefined,
});
export const selectedThreadAtomFamily = atomFamily<
  string | undefined,
  { nodeId: string | undefined }
>({
  key: "selectedThreadAtomFamily",
  default: undefined,
});

export const threadsAtomFamily = atomFamily<
  Thread[],
  { nodeId: string | undefined; branchId: string }
>({
  key: "threadsAtomFamily",
  default: selectorFamily({
    key: "threadSelectorFamily",
    get:
      ({ nodeId, branchId }) =>
      () => {
        if (!nodeId) return [];
        return getForumThreads(nodeId, branchId).then((threads) =>
          threads.sort((a, b) => a.threadTimestamp - b.threadTimestamp),
        );
      },
  }),
});

export const filteredThreadsSelectorFamily = selectorFamily<
  {
    threads: Thread[];
    resolvedThreads: Thread[];
    resolvedAndNotResolvedThreads: Thread[];
    deletedFeatureThreads: Thread[];
  },
  { nodeId: string; branchId: string }
>({
  key: "filteredThreadsSelectorFamily",
  get:
    ({ nodeId, branchId }) =>
    ({ get }) => {
      const featureMap = get(projectFeatureMap);
      const threads = get(threadsAtomFamily({ nodeId, branchId }));

      const { resolved, resolvedAndNotResolvedThreads, notResolved, deleted } =
        threads.reduce<{
          resolved: Thread[];
          resolvedAndNotResolvedThreads: Thread[];
          notResolved: Thread[];
          deleted: Thread[];
        }>(
          (pre, thread) => {
            const isDeleted =
              thread.featureId && !featureMap.has(thread.featureId);
            if (!isDeleted) {
              pre.resolvedAndNotResolvedThreads.push(thread);
            }

            if (isDeleted) {
              pre.deleted.push(thread);
            } else if (thread.resolved) {
              pre.resolved.push(thread);
            } else {
              pre.notResolved.push(thread);
            }
            return pre;
          },
          {
            resolved: [],
            notResolved: [],
            deleted: [],
            resolvedAndNotResolvedThreads: [],
          },
        );
      return {
        threads: notResolved,
        resolvedThreads: resolved,
        resolvedAndNotResolvedThreads,
        deletedFeatureThreads: deleted,
      };
    },
});

export const showResolvedCommentsAtom = atom<boolean>({
  key: "showResolvedCommentsAtom",
  default: false,
});

export const replySaveInProgressState = atom<
  { threadId: string; message: string } | undefined
>({
  key: "replySaveInProgressState",
  default: undefined,
});

export const threadRepliesAtomFamily = atomFamily<
  Reply[],
  { nodeId: string; branchId: string; threadId: string }
>({
  key: "threadRepliesAtomFamily",
  default: selectorFamily({
    key: "threadRepliesSelectorFamily",
    get:
      ({ nodeId, branchId, threadId }) =>
      () => {
        return getThreadReplies(nodeId, branchId, threadId);
      },
  }),
});

export const threadReactionsCounterAtomFamily = atomFamily<
  number,
  { nodeId: string; branchId: string; threadId: string }
>({
  key: "threadReactionsCounterAtomFamily",
  default: 0,
});
export const threadReactionsAtomFamily = atomFamily<
  Reaction[],
  { nodeId: string; branchId: string; threadId: string }
>({
  key: "threadReactionsAtomFamily",
  default: selectorFamily({
    key: "threadReactionsSelectorFamily",
    get:
      ({ nodeId, branchId, threadId }) =>
      ({ get }) => {
        get(
          threadReactionsCounterAtomFamily({
            nodeId,
            branchId,
            threadId,
          }),
        );
        return getThreadReactions(nodeId, branchId, threadId);
      },
  }),
});

export const replyReactionsCounterAtomFamily = atomFamily<
  number,
  {
    nodeId: string;
    branchId: string;
    threadId: string;
    replyId: string;
  }
>({
  key: "replyReactionsCounterAtomFamily",
  default: 0,
});
export const replyReactionsAtomFamily = atomFamily<
  Reaction[],
  {
    nodeId: string;
    branchId: string;
    threadId: string;
    replyId: string;
  }
>({
  key: "replyReactionsAtomFamily",
  default: selectorFamily({
    key: "replyReactionsSelectorFamily",
    get:
      ({ nodeId, branchId, threadId, replyId }) =>
      ({ get }) => {
        get(
          replyReactionsCounterAtomFamily({
            nodeId,
            branchId,
            threadId,
            replyId,
          }),
        );
        return getReplyReactions(nodeId, branchId, threadId, replyId);
      },
  }),
});

// replyId or threadId
export const userReactionSaveInProgressState = atom<string | undefined>({
  key: "userReplyReactionSaveInProgressState",
  default: undefined,
});

export const userReactionsAtomFamily = atomFamily<
  Reaction[],
  { nodeId: string; branchId: string }
>({
  key: "userReactionsAtomFamily",
  default: selectorFamily({
    key: "userReactionsSelectorFamily",
    get:
      ({ nodeId, branchId }) =>
      () => {
        return getUserReactions(nodeId, branchId);
      },
  }),
});

export const followThreadsAtomFamily = atomFamily<
  ThreadFollow[],
  { nodeId: string; branchId: string }
>({
  key: "followThreadsAtomFamily",
  default: selectorFamily({
    key: "followThreadsSelectorFamily",
    get:
      ({ nodeId, branchId }) =>
      () => {
        return getUserThreadFollows(nodeId, branchId);
      },
  }),
});

export const showCommentIconsInMapState = atom<boolean>({
  key: "showCommentIconsInMapState",
  default: true,
  effects: [syncLocalStorage("vind:comments:show-in-map", z.boolean())],
});
