/// <reference types="vite-plugin-svgr/client" />
import {
  ReactNode,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";
import StarIcon from "@icons/24/Star.svg?react";
import FolderClosedIcon from "@icons/20/FolderMenuClosed.svg";
import PersonalFolderClosedIcon from "@icons/20/PersonalFolderClosed.svg";
import { useTypedPath } from "../../state/pathParams";
import { followProjectsAtom } from "../../state/project";
import { SkeletonBlock } from "../Loading/Skeleton";
import { spaceHuge } from "../../styles/space";
import { ProjectMeta } from "../../types/api";
import { capitalize, isNever } from "../../utils/utils";
import { Column, Row } from "../General/Layout";
import { ItemShape, Table } from "../General/Table";
import { useFollowProjectCrud } from "./useFollowProjectCrud";
import { EditableTextInternalState } from "../General/EditableText";
import useTextInput from "../../hooks/useTextInput";
import { ProjectDotMenu } from "./ProjectDotMenu";
import EarthIcon from "@icons/14/Earth.svg";
import {
  StarWrapper,
  EmptyListMessageDiv,
  ProjectListWrapper,
  ProjectNameWrapper,
  FolderNameWrapper,
  ProjectTitle,
  DotMenuWrapper,
  FolderTitle,
} from "./ProjectList.style";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  FolderTreeItem,
  TreeItem,
  folderTreeSelectorFamily,
  makeNodeCompare,
  treeMap,
} from "./state";
import { colors } from "../../styles/colors";
import {
  findTopLevelNode,
  nodesInOrganisationSelectorFamily,
  storedNodesForOrganisation,
  useOrganisationNodeCrud,
} from "./useOrganisationFolderCrud";
import { ProjectNodeInformation } from "../../services/customerAPI";
import useFolderId from "hooks/useFolderId";
import { NodeMembersList } from "./NodeMembersList";
import { IconREMSize } from "styles/typography";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { FolderDotMenu } from "./FolderDotMenu";

/**
 * Used for `FolderName` and `ProjectName`. We want to be able to drag
 * an item and drop it in a folder. If the target of a drop is a project,
 * we want to put the dragging element in the same folder as the project.
 * This so that we can move things to the top level.
 */

const FolderName = ({
  folder,
  organisationId,
  isEditingTitle,
  setIsEditingTitle,
}: {
  folder: FolderTreeItem;
  organisationId: string;
  isEditingTitle: boolean;
  setIsEditingTitle: (value: boolean) => void;
}) => {
  const nodes = useRecoilValue(
    nodesInOrganisationSelectorFamily({ organisationId }),
  );
  const { update: updateNode } = useOrganisationNodeCrud();

  const folderTitle = useMemo(
    () => nodes.find((n) => n.id === folder.id)?.name ?? "",
    [nodes, folder],
  );
  const [writtenTitle, onWrittenTitleChange, setWrittenTitle] =
    useTextInput(folderTitle);

  const chosenFolderHasPersonalTopFolder = folder
    ? findTopLevelNode(nodes, folder.id, organisationId ?? "")?.type ===
      "personal_folder"
    : false;

  const isPersonalFolder = chosenFolderHasPersonalTopFolder;

  const setContent = useSetRecoilState(
    organisationRightSideModal(organisationId),
  );
  const updateProjectCallback = useCallback(
    async (newTitle: string) => {
      await updateNode(folder.id, {
        name: newTitle,
      });
    },
    [folder.id, updateNode],
  );
  useEffect(() => {
    setWrittenTitle(folderTitle);
  }, [folderTitle, setWrittenTitle]);

  return (
    <Link
      onClick={() => setContent({ type: "project", id: folder.id })}
      to={`/organisation/${organisationId}/${folder.id}${location.search}`}
      style={{ textDecoration: "none" }}
    >
      <FolderNameWrapper>
        <IconREMSize height={2} width={2}>
          {isPersonalFolder ? (
            <PersonalFolderClosedIcon />
          ) : (
            <FolderClosedIcon />
          )}
        </IconREMSize>
        <EditableTextInternalState
          type="text"
          value={writtenTitle}
          disabled={true} // Only editable from dot menu
          onChange={onWrittenTitleChange}
          onEnter={async (newTitle) => {
            await Promise.all([
              updateNode(folder.id, { name: newTitle }),
              updateProjectCallback(newTitle),
            ]);
          }}
          onCancel={async (newTitle) => {
            await Promise.all([
              updateNode(folder.id, { name: newTitle }),
              updateProjectCallback(newTitle),
            ]);
          }}
          isEditing={isEditingTitle}
          onAfter={() => {
            setIsEditingTitle(false);
          }}
          renderText={(text) => <FolderTitle>{text}</FolderTitle>}
        />
      </FolderNameWrapper>
    </Link>
  );
};

const ProjectName = ({
  project,
  organisationId,
  isEditingTitle,
  setIsEditingTitle,
}: {
  project: ProjectMeta;
  organisationId: string;
  isEditingTitle: boolean;
  setIsEditingTitle: (value: boolean) => void;
}) => {
  const location = useLocation();

  const { update: updateNode } = useOrganisationNodeCrud();
  const [writtenTitle, onWrittenTitleChange, setWrittenTitle] = useTextInput(
    project?.name ?? "",
  );
  const updateProjectCallback = useCallback(
    async (newTitle: string) => {
      await updateNode(project.id, {
        name: newTitle,
      });
    },
    [project.id, updateNode],
  );

  useEffect(() => {
    setWrittenTitle(project?.name ?? "");
  }, [project, setWrittenTitle]);

  return (
    <Link
      key={project.id}
      style={{ textDecoration: "none", cursor: "pointer" }}
      to={`/design/project/${organisationId}/${project.id}${location.search}`}
    >
      <ProjectNameWrapper>
        <IconREMSize height={1.4} width={1.4}>
          <EarthIcon />
        </IconREMSize>
        <EditableTextInternalState
          type="text"
          value={writtenTitle}
          disabled={true} // Only editable from dot menu
          onChange={onWrittenTitleChange}
          onEnter={async (newTitle) => {
            await Promise.all([
              updateNode(project.id, { name: newTitle }),
              updateProjectCallback(newTitle),
            ]);
          }}
          onCancel={async (newTitle) => {
            await Promise.all([
              updateNode(project.id, { name: newTitle }),
              updateProjectCallback(newTitle),
            ]);
          }}
          isEditing={isEditingTitle}
          onAfter={() => {
            setIsEditingTitle(false);
          }}
          renderText={(text) => <ProjectTitle>{text}</ProjectTitle>}
        />
      </ProjectNameWrapper>
    </Link>
  );
};

const EmptyListMessage = () => (
  <Column style={{ margin: "0 auto" }}>
    <EmptyListMessageDiv>This team has no projects.</EmptyListMessageDiv>
  </Column>
);

export const ProjectListView = () => {
  const { nodeId } = useParams();

  if (!nodeId) return ProjectListInOverviewPage();

  return ProjectListInFolders();
};

const ProjectListInOverviewPage = () => {
  const { organisationId } = useTypedPath("organisationId");

  return ProjectListInner(organisationId);
};

const ProjectListInFolders = () => {
  const folderId = useFolderId() ?? "";

  return ProjectListInner(folderId);
};

const ProjectListInner = (folderId: string) => {
  const { organisationId } = useTypedPath("organisationId");

  const nodes = useRecoilValue(
    nodesInOrganisationSelectorFamily({ organisationId }),
  );

  const projectsLoading = useRecoilValue(
    storedNodesForOrganisation({
      organisationId,
    }),
  ).some((l) => l.state === "loading");
  const { put: followProject } = useFollowProjectCrud();
  const followsLoadable = useRecoilValueLoadable(followProjectsAtom);

  const folderTree = useRecoilValueLoadable(
    folderTreeSelectorFamily({ organisationId, folderId }),
  );

  const somethingLoads =
    folderTree.state === "loading" ||
    projectsLoading ||
    followsLoadable.state === "loading";

  const crudNode = useOrganisationNodeCrud();

  const follows = useMemo(
    () => followsLoadable.valueMaybe() ?? [],
    [followsLoadable],
  );

  const cmp = useMemo(() => {
    const followIds = new Set(
      follows
        .filter((f) => f.follow)
        .map((f) => f.nodeId)
        .map((nodeId) => nodes.find((n) => n.id === nodeId)?.id)
        .filter((projectId) => projectId) as string[],
    );
    return makeNodeCompare(followIds);
  }, [nodes, follows]);

  type Shape =
    | ItemShape<ReactNode, "name">
    | ItemShape<string, "members">
    | ItemShape<ReactNode, "dot">;

  const columns: Shape["col"][] = useMemo(() => ["name", "members", "dot"], []);

  const [editingTitles, setEditingTitles] = useState<Record<string, boolean>>(
    {},
  );
  const { items }: { items: Shape[] } = useMemo(() => {
    let row = 0;
    const items: Shape[] = [];

    function process(ti: TreeItem) {
      if (ti.type === "project") {
        const project = ti as ProjectNodeInformation;
        const followsProject =
          follows.find((f) => f.nodeId === project?.id)?.follow ?? false;
        const inFolder = nodes.find((n) => n.id === ti.parent_id);

        const ret: Shape[] = [
          {
            col: "name",
            row,
            ele: (
              <ProjectName
                isEditingTitle={editingTitles[ti.id] || false}
                setIsEditingTitle={(isEditing) => {
                  setEditingTitles((current) => ({
                    ...current,
                    [ti.id]: isEditing,
                  }));
                }}
                organisationId={organisationId}
                key={ti.id}
                project={project}
              />
            ),
          },
          { col: "members", row, ele: project.id },
          {
            col: "dot",
            row,
            ele: (
              <>
                {" "}
                <StarWrapper
                  starred={followsProject}
                  onClick={(e) => {
                    followProject(project.id, !followsProject);
                    e.preventDefault(); // Don't follow Link
                  }}
                >
                  <StarIcon />
                </StarWrapper>
                <DotMenuWrapper>
                  <ProjectDotMenu
                    setIsEditingTitle={(isEditing) => {
                      setEditingTitles((current) => ({
                        ...current,
                        [ti.id]: isEditing,
                      }));
                    }}
                    project={project}
                    currentFolder={inFolder}
                    nodes={nodes}
                    crudNode={crudNode}
                    nestedDirection="left"
                  />
                </DotMenuWrapper>
              </>
            ),
          },
        ];
        ret.forEach((i) => items.push(i));
        row += 1;
      } else if (ti.type === "folder") {
        const folder = ti as FolderTreeItem;
        const followsFolder =
          follows.find((f) => f.nodeId === folder?.id)?.follow ?? false;
        if (!folder) {
          row += 1;
          const i: Shape[] = [
            {
              col: "name",
              row,
              ele: (
                <SkeletonBlock
                  style={{
                    alignSelf: "start",
                    height: "1rem",
                    width: "20rem",
                  }}
                />
              ),
            },
          ];
          items.push(...i);
          row += 1;
        } else {
          const i: Shape[] = [
            {
              col: "name",
              row,
              ele: (
                <FolderName
                  isEditingTitle={editingTitles[ti.id] || false}
                  setIsEditingTitle={(isEditing) => {
                    setEditingTitles((current) => ({
                      ...current,
                      [ti.id]: isEditing,
                    }));
                  }}
                  key={folder.id}
                  folder={folder}
                  organisationId={organisationId}
                />
              ),
            },
            { col: "members", row, ele: folder.id },
            {
              col: "dot",
              row,
              ele: (
                <>
                  {" "}
                  <StarWrapper
                    starred={followsFolder}
                    onClick={(e) => {
                      followProject(folder.id, !followsFolder);
                      e.preventDefault(); // Don't follow Link
                    }}
                  >
                    <StarIcon />
                  </StarWrapper>
                  <DotMenuWrapper>
                    <FolderDotMenu
                      setIsEditingTitle={(isEditing) => {
                        setEditingTitles((current) => ({
                          ...current,
                          [ti.id]: isEditing,
                        }));
                      }}
                      currentFolder={folder}
                      folderId={folder.id}
                      nodes={nodes}
                      crudNode={crudNode}
                      nestedDirection="left"
                    />
                  </DotMenuWrapper>
                </>
              ),
            },
          ];
          items.push(...i);
          row += 1;
        }
      } else throw isNever(ti as never);
    }

    const tree = treeMap(folderTree.valueMaybe() ?? [], (e) => {
      if (e.type === "folder") {
        const folderItem = e as FolderTreeItem;
        const sorted = folderItem.items.sort(cmp);
        return {
          ...e,
          items: sorted,
        };
      }
      return e;
    });
    tree.sort(cmp);

    tree.forEach((e) => process(e));
    return { items };
  }, [
    cmp,
    crudNode,
    folderTree,
    nodes,
    follows,
    followProject,
    organisationId,
    editingTitles,
  ]);

  const render = useCallback(
    (item?: Shape) => {
      if (!item) return null;
      if (item.col === "name") return item.ele;
      if (item.col === "members") {
        return (
          <Suspense
            fallback={
              <SkeletonBlock style={{ width: "50%", height: "1rem" }} />
            }
          >
            <Row style={{ display: "flex", alignItems: "start" }}>
              <NodeMembersList
                organisationId={organisationId}
                nodeId={item.ele}
              />
            </Row>
          </Suspense>
        );
      }
      if (item.col === "dot") {
        return (
          <Row style={{ display: "flex", justifyContent: "end" }}>
            {item.ele}
          </Row>
        );
      }
      isNever(item);
    },
    [organisationId],
  );

  if (somethingLoads) {
    return (
      <>
        <SkeletonBlock
          style={{ margin: spaceHuge, width: "inherit", height: "5rem" }}
        />
      </>
    );
  }

  return (
    <>
      <ProjectListWrapper>
        {items.length === 0 ? (
          <EmptyListMessage />
        ) : (
          <>
            <Table
              items={items}
              columns={columns}
              render={render}
              renderColumn={(c) => {
                if (!c) return null;
                if (c === "name")
                  return (
                    <div
                      style={{
                        textAlign: "start",
                      }}
                    >
                      Name
                    </div>
                  );
                if (c === "dot") return " ";
                if (c === "members")
                  return (
                    <div
                      style={{
                        textAlign: "start",
                        minWidth: "10rem",
                      }}
                    >
                      Collaborators
                    </div>
                  );
                return capitalize(c);
              }}
              hideRows
              style={{
                nohover: true,
                table: {
                  "tr:nth-child(odd):not(#table-header)": {
                    borderRadius: "0.4rem",
                  },
                  "tr:not(#table-header)": {
                    ":hover": {
                      backgroundColor: `${colors.surfaceHover}`,
                    },
                  },
                  "#table-header": {
                    backgroundColor: `${colors.surfacePrimary}`,
                  },
                },
              }}
            />
          </>
        )}

        {/** Add some space here so that the list doesn't stop at the very bottom of the screen. */}
        <div style={{ minHeight: spaceHuge }} />
      </ProjectListWrapper>
    </>
  );
};
