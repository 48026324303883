import { useMemo, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import mapboxgl from "mapbox-gl";
import { layerSettingSelectorFamily } from "../../components/LayerList/LayerSettings/state";
import Polygon from "../../components/MapFeatures/Polygon";
import {
  ProjectFeature,
  _GeoJSONFeatureToOtherFeature,
} from "../../types/feature";
import {
  useDynamicStreamer,
  visibleDynamicLayersAtom,
} from "../../state/layer";

import { mapRefAtom } from "../../state/map";
import { useTypedPath } from "../../state/pathParams";
import { addIdOnFeaturesIfNotUUID4 } from "../../utils/geojson/utils";
import useSelectionInMap from "../../hooks/useSelectionInMap";
import { useLayoutFilter } from "../../hooks/map";
import {
  StrokeStyleToDashArray,
  filterFeatureCollectionAccordingToType,
  getLayerSymbols,
  layerToSourceId,
} from "./utils";
import { externalWFSAnchorId } from "../../components/Mapbox/utils";
import { Layer, LayerType } from "../../types/layers";
import {
  LayerSettings,
  LayerStrokeStyle,
} from "../../components/LayerList/LayerSettings/types";
import { opacity as colorOpacity } from "../../styles/colors";
import { getVisibleLayers } from "../../components/LayerList/LayerSettings/utils";
import {
  ErrorBoundarySilent,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "../../components/ErrorBoundaries/ErrorBoundaryLocal";
import stc from "string-to-color";
import { sendInfo } from "../../utils/sentry";
import { isTileJSONLayer } from "../../state/tileJSON";
import { isCustomLayer } from "../../components/LayerList/utils";
import {
  arcgisLayerDrawingInfoSelector,
  DrawingInfoClassBreakInfosRenderer,
} from "../../state/arcgisRestAPI";
import { rgbToHex } from "../../utils/image";
import { z } from "zod";

const POLYGON_TYPES = ["Polygon", "MultiPolygon"];
export const POLYGON_LAYERS_SUFFIX = "-polygon";

const getOutlinePaint = (
  color: string,
  strokeStyle: LayerStrokeStyle,
): mapboxgl.LinePaint => ({
  "line-color": [
    "case",
    ["boolean", ["feature-state", "selected"], false],
    "#ffffff",
    color,
  ],
  "line-width": [
    "case",
    [
      "boolean",
      ["feature-state", "selected"],
      ["feature-state", "hover"],

      false,
    ],
    strokeStyle === LayerStrokeStyle.Solid ? 4 : 2,
    1,
  ],
  "line-opacity": 1.0,
  "line-dasharray": StrokeStyleToDashArray[strokeStyle],
});

const getFillPaint = (
  color: mapboxgl.FillPaint["fill-color"],
  opacity: number,
): mapboxgl.FillPaint => ({
  "fill-color": color,
  "fill-opacity": opacity,
});

const DynamicPolygonLayers = ErrorBoundaryWrapper(
  () => {
    const { projectId } = useTypedPath("projectId");
    const visibleLayers = useRecoilValue(getVisibleLayers({ projectId }));

    const selectedDynamicPolygonLayers = visibleLayers.filter(
      (layer) =>
        !isTileJSONLayer(layer) &&
        (layer.type === LayerType.Polygon ||
          isCustomLayer(layer) ||
          layer.type === LayerType.FeatureCollection),
    );

    return (
      <>
        {selectedDynamicPolygonLayers.map((layer) => (
          <DynamicPolygonLayer key={layer.id} layer={layer} />
        ))}
      </>
    );
  },
  ErrorBoundarySilent,
  ScreamOnError,
);

const DynamicPolygonLayer = ({ layer }: { layer: Layer }) => {
  const { projectId } = useTypedPath("projectId");
  const { dynamicVectorLayerFeatures } = useDynamicStreamer(layer);

  const filteredGeojson = useMemo(
    () =>
      isCustomLayer(layer)
        ? filterFeatureCollectionAccordingToType(
            dynamicVectorLayerFeatures,
            "Polygon",
          )
        : dynamicVectorLayerFeatures,
    [dynamicVectorLayerFeatures, layer],
  );

  const map = useRecoilValue(mapRefAtom);
  const layerSettings = useRecoilValue(
    layerSettingSelectorFamily({
      projectId,
      layerId: layer.id,
    }),
  );

  const features = useMemo(() => {
    const featuresFiltered = z
      .object({ features: _GeoJSONFeatureToOtherFeature.array() })
      .parse(filteredGeojson).features;
    if (layer.type === "polygon")
      return addIdOnFeaturesIfNotUUID4(featuresFiltered);
    return addIdOnFeaturesIfNotUUID4(
      featuresFiltered.filter((f) => {
        if (f.geometry == null) {
          // NOTE: I don't think this should happen, but it does, so we'll add
          // some debugging here.
          sendInfo("feature is missing geometry", { f, layer });
          return false;
        }
        return POLYGON_TYPES.includes(f.geometry.type);
      }),
    );
  }, [filteredGeojson, layer]);
  return (
    <DynamicPolygonLayerRender
      features={features}
      layer={layer}
      map={map}
      layerSettings={layerSettings}
    />
  );
};

function generateInfoClassBreakExpression(
  classBreaks: DrawingInfoClassBreakInfosRenderer,
  defaultColor: string,
): mapboxgl.FillPaint["fill-color"] {
  const expression: mapboxgl.FillPaint["fill-color"] = [
    "step",
    ["get", classBreaks.field],
  ];
  classBreaks.classBreakInfos.forEach((breakInfo) => {
    const [r, g, b] = breakInfo.symbol.color;
    expression.push(rgbToHex(r, g, b));
    expression.push(breakInfo.classMaxValue);
  });
  expression.push(defaultColor); // Default color if no class matches
  return expression;
}

const DynamicPolygonLayerRender = ({
  features,
  layer,
  map,
  layerSettings,
}: {
  features: ProjectFeature[];
  layer: Layer;
  map?: mapboxgl.Map;
  layerSettings: LayerSettings;
}) => {
  const { onExternalLayerClick, getIdsOfSelectedExternalLayers } =
    useSelectionInMap();
  const drawingInfo = useRecoilValue(arcgisLayerDrawingInfoSelector({ layer }));
  const layerId = layer.id + POLYGON_LAYERS_SUFFIX;
  const sourceId = useMemo(
    () => layerToSourceId(layer, POLYGON_LAYERS_SUFFIX),
    [layer],
  );

  const selectedIds = useMemo(
    () => getIdsOfSelectedExternalLayers(features),
    [getIdsOfSelectedExternalLayers, features],
  );

  const color = useMemo(() => {
    if (!layerSettings.overrideLayerStyle && drawingInfo) {
      if (drawingInfo.type === "simple" && drawingInfo.symbol.color) {
        const [r, g, b] = drawingInfo.symbol.color;
        return rgbToHex(r, g, b);
      } else if (drawingInfo.type === "classBreaks") {
        return generateInfoClassBreakExpression(drawingInfo, stc(layer.id));
      }
    }
    if (layerSettings.layerStyle?.color) {
      return layerSettings.layerStyle.color;
    }
    return stc(layer.id);
  }, [drawingInfo, layer.id, layerSettings]);

  const strokeColor = useMemo(() => {
    if (
      !layerSettings.overrideLayerStyle &&
      drawingInfo?.type === "simple" &&
      drawingInfo.symbol?.outline?.color
    ) {
      const [r, g, b] = drawingInfo.symbol.outline.color;
      return rgbToHex(r, g, b);
    }
    if (layerSettings.layerStyle?.strokeColor) {
      return layerSettings.layerStyle.strokeColor;
    }
    return stc(layer.id);
  }, [layer, layerSettings, drawingInfo]);

  const strokeStyle = useMemo(() => {
    return layerSettings.layerStyle?.strokeStyle ?? LayerStrokeStyle.Solid;
  }, [layerSettings.layerStyle]);

  const opacity = useMemo(() => {
    return layerSettings.layerStyle?.opacity ?? colorOpacity.externalLayer;
  }, [layerSettings.layerStyle]);

  const pinnedProperties = useMemo(() => {
    return layerSettings.layerStyle?.pinnedProperties;
  }, [layerSettings.layerStyle]);

  const setVisibleDynamicLayers = useSetRecoilState(visibleDynamicLayersAtom);
  useEffect(() => {
    setVisibleDynamicLayers((vdl) => [...vdl, layerId]);
    return () => {
      setVisibleDynamicLayers((vdl) => vdl.filter((l) => l !== layerId));
    };
  }, [setVisibleDynamicLayers, layerId, layer]);

  const filter = useLayoutFilter(sourceId);

  const pinnedSymbols = useMemo(
    () =>
      getLayerSymbols(pinnedProperties, layerSettings.layerStyle?.zoomLevels),
    [pinnedProperties, layerSettings.layerStyle?.zoomLevels],
  );

  const linePaint = useMemo(
    () => getOutlinePaint(strokeColor, strokeStyle),
    [strokeColor, strokeStyle],
  );

  const fillPaint = useMemo(
    () => getFillPaint(color, opacity),
    [color, opacity],
  );

  if (!map) return null;

  return (
    <Polygon
      features={features}
      sourceId={sourceId}
      layerId={layerId}
      symbols={(layer as any)["symbols"] ?? pinnedSymbols}
      map={map}
      onClickCallback={onExternalLayerClick}
      selectedIds={selectedIds}
      filter={filter}
      beforeLayer={externalWFSAnchorId}
      linePaint={linePaint}
      paint={fillPaint}
      zoomLevels={layerSettings.layerStyle?.zoomLevels}
    />
  );
};

export default DynamicPolygonLayers;

export const forTesting = { DynamicPolygonLayerRender, getOutlinePaint };
