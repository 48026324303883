import { atom, atomFamily } from "recoil";
import { TurbineSettingsActiveType } from "../components/GenerateWindTurbines/TurbineSettings";
import { PlaceWindTurbinesMenuType } from "../components/GenerateWindTurbines/PlaceWindTurbines";
import { MeasureDistanceMode } from "../components/Distance/Distance";

import {
  PlaceGoZoneType,
  PlaceNoGoZoneType,
  PlaceZoneType,
} from "../components/DivisionDesign/DivisionConst";
import {
  AreaMenuType,
  ConstraintsMenuType,
  AddFeaturesMenuType,
  GenerateAnchorsMenuType,
  GenerateFoundationsMenuType,
  GenerateWindTurbinesAndFoundationsMenuType,
  TurbinesMenuType,
  SiteLocatorMenuType,
} from "../constants/projectMapView";
import {
  AddSubStationMenuType,
  GenerateCablesMenuType,
  DrawExportCableMenuType,
  DrawCableCorridorMenuType,
  CablesMenuType,
  DrawCableMenuType,
  CableFreeZoneMenuType,
} from "../constants/cabling";
import {
  DrawLineStringMenuType,
  DrawParkMenuType,
  DrawPolygonMenuType,
  DrawPointMenuType,
  DrawMooringLineMenuType,
  DrawViewPointMenuType,
  DrawPortMenuType,
  DrawAnchorMenuType,
  DrawCircleMenuType,
  DrawRectangleMenuType,
  DrawExistingTurbineMenuType,
} from "../constants/draw";
import { ProjectElementMenuType, UploadMenuParkType } from "../constants/park";
import {
  WaveSourceMenuType,
  WindSourceMenuType,
} from "../components/WindWaveSource/utils";
import { maxWindSpeed, minWindSpeed } from "../types/filter";
import { syncLocalStorage } from "./effects";
import { z } from "zod";

export type LeftMenuModeType =
  | undefined
  | typeof WindSourceMenuType
  | typeof WaveSourceMenuType
  | typeof ProjectElementMenuType
  | typeof TurbineSettingsActiveType
  | typeof TurbinesMenuType
  | typeof GenerateWindTurbinesAndFoundationsMenuType
  | typeof GenerateAnchorsMenuType
  | typeof GenerateFoundationsMenuType
  | typeof DrawLineStringMenuType
  | typeof DrawParkMenuType
  | typeof DrawPolygonMenuType
  | typeof DrawCircleMenuType
  | typeof DrawRectangleMenuType
  | typeof DrawPointMenuType
  | typeof PlaceZoneType
  | typeof PlaceGoZoneType
  | typeof PlaceNoGoZoneType
  | typeof PlaceWindTurbinesMenuType
  | typeof AddSubStationMenuType
  | typeof GenerateCablesMenuType
  | typeof DrawExportCableMenuType
  | typeof DrawCableCorridorMenuType
  | typeof CablesMenuType
  | typeof MeasureDistanceMode
  | typeof UploadMenuParkType
  | typeof AreaMenuType
  | typeof ConstraintsMenuType
  | typeof AddFeaturesMenuType
  | typeof DrawMooringLineMenuType
  | typeof DrawViewPointMenuType
  | typeof DrawPortMenuType
  | typeof DrawAnchorMenuType
  | typeof DrawExistingTurbineMenuType
  | typeof DrawCableMenuType
  | typeof CableFreeZoneMenuType
  | typeof SiteLocatorMenuType;

/**
 * NOTE: Unless you only want to read this atom, you probably want to use
 * {@link useDrawMode} instead, because this hook handles the logic for
 * transitioning between different states.
 */
export const leftMenuModeActiveAtom = atom<LeftMenuModeType>({
  key: "leftMenuModeActiveAtom",
  default: undefined,
});

export const enableFilterLayersAtom = atom<boolean | undefined>({
  key: "enableFilterLayersAtom",
  default: undefined,
});

export const renderDepthFilterAtom = atom<boolean>({
  key: "renderDepthFilterAtom",
  default: true,
});

export const depthFilterAtomFamily = atomFamily<
  number[],
  { projectId: string }
>({
  key: "depthFilterAtomFamily",
  default: [100, Number.MAX_VALUE],
  effects: ({ projectId }) => [
    syncLocalStorage(`vind:depth-filter:${projectId}`, z.number().array()),
  ],
});

export const renderShoreDistanceAtom = atom<boolean>({
  key: "renderShoreDistanceAtom",
  default: true,
});

export const maxShorelineDistance = 250;

export const shoreDistanceFilterAtomFamily = atomFamily<
  number[],
  { projectId: string }
>({
  key: "shoreDistanceFilterAtomFamily",
  default: [0, maxShorelineDistance],
  effects: ({ projectId }) => [
    syncLocalStorage(
      `vind:shore-distance-filter:${projectId}`,
      z.number().array(),
    ),
  ],
});

export const renderWindSpeedAtom = atom<boolean>({
  key: "renderWindSpeedAtom",
  default: true,
});

export const windSpeedFilterAtomFamily = atomFamily<
  number[],
  { projectId: string }
>({
  key: "windSpeedFilterAtomFamily",
  default: [minWindSpeed, maxWindSpeed],
  effects: ({ projectId }) => [
    syncLocalStorage(`vind:wind-speed-filter:${projectId}`, z.number().array()),
  ],
});
