import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { getDivisionFeaturesSelectorFamily } from "../../../state/division";
import { getTurbinesSelectorFamily } from "../../../state/layout";
import { TurbineFeature } from "../../../types/feature";
import { allSimpleTurbineTypesSelector } from "../../../state/turbines";
import { SimpleTurbineType } from "../../../types/turbines";
import { isDefined } from "../../../utils/predicates";
import { dedup, undefMap, undefMap2 } from "../../../utils/utils";
import useGetRasterStats from "../../Bathymetry/useGetRasterStats";
import * as turf from "@turf/turf";
import { SafeCard } from "./Base";
import { useDashboardContext } from "../Dashboard";
import React from "react";
import { SkeletonBlock } from "../../Loading/Skeleton";
import { spaceMedium } from "../../../styles/space";
import { FlexGrid2 } from "../../General/Form";
import { Row } from "../../General/Layout";
import { useBathymetryRaster } from "hooks/bathymetry";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import { BATHYMETRY_SOURCE_DESCRIPTION } from "business/bathymetry/utils";
import {
  getOffshoreSubstationsInBranchSelectorFamily,
  getOnshoreSubstationsInBranchSelectorFamily,
} from "state/cable";

const turbineTypeString = (
  turbines: TurbineFeature[],
  allTurbineTypes: SimpleTurbineType[],
): string | undefined => {
  const types = dedup(turbines.map((f) => f.properties.turbineTypeId))
    .map((id) => allTurbineTypes.find((t) => t.id === id)?.name)
    .filter(isDefined)
    .sort();
  if (types.length === 0) return "-";
  return types.join(", ");
};

const formatDepthRange = (min: number, max: number) =>
  `${Math.abs(Math.round(min))} - ${Math.abs(Math.round(max))} m`;

const formatDepth = (depth: number) => `${Math.abs(Math.round(depth))} m`;

const formatMinDistance = (minDistance: number) =>
  minDistance === Infinity ? "-" : `${Math.abs(Math.round(minDistance))} m`;

const ParkInner = () => {
  const { projectId, park, branch } = useDashboardContext();
  const { subAreas, exclusionZones } = useRecoilValue(
    getDivisionFeaturesSelectorFamily({ parkId: park.id }),
  );
  const turbines: TurbineFeature[] = useRecoilValue(
    getTurbinesSelectorFamily({ parkId: park.id }),
  );
  const onshoreSubstations = useRecoilValue(
    getOnshoreSubstationsInBranchSelectorFamily({
      parkId: park.id,
      branchId: branch.id,
    }),
  );

  const offshoreSubstations = useRecoilValue(
    getOffshoreSubstationsInBranchSelectorFamily({
      parkId: park.id,
      branchId: branch.id,
    }),
  );

  let minDistanceBetweenTurbines = Infinity;

  for (let i = 0; i < turbines.length; i++) {
    for (let j = i + 1; j < turbines.length; j++) {
      const turbine1 = turbines[i];
      const turbine2 = turbines[j];

      const from = turf.point([
        turbine1.geometry.coordinates[0],
        turbine1.geometry.coordinates[1],
      ]);
      const to = turf.point([
        turbine2.geometry.coordinates[0],
        turbine2.geometry.coordinates[1],
      ]);

      const distance = turf.distance(from, to, { units: "meters" });

      if (distance < minDistanceBetweenTurbines) {
        minDistanceBetweenTurbines = distance;
      }
    }
  }

  const allTurbineTypes = useRecoilValue(allSimpleTurbineTypesSelector);

  const turbineTypesString = useMemo<string | undefined>(
    () => turbineTypeString(turbines, allTurbineTypes),
    [turbines, allTurbineTypes],
  );

  const bathymetry = useBathymetryRaster({
    projectId,
    featureId: park.id,
    bufferKm: 1,
  })
    .map((raster) => {
      if (!raster) return undefined;
      const [minLon, minLat, maxLon, maxLat] = raster.bbox();
      return {
        raster: raster,
        minLon,
        minLat,
        maxLon,
        maxLat,
      };
    })
    .valueMaybe();

  const { minValue, maxValue, averageValue } = useGetRasterStats(
    park,
    bathymetry?.raster,
    bathymetry?.minLon ?? 0,
    bathymetry?.minLat ?? 0,
    bathymetry?.maxLon ?? 0,
    bathymetry?.maxLat ?? 0,
  );

  const area = Math.round(turf.area(park) / (1000 * 1000));

  const areaWithoutExclusionZones = useMemo(
    () =>
      Math.round(
        turf.area(
          exclusionZones.reduce<turf.Feature<turf.Polygon | turf.MultiPolygon>>(
            (acc, f) => {
              const diff = turf.difference(acc, f);
              if (diff == null) return acc;
              return diff;
            },
            park,
          ),
        ) /
          (1000 * 1000),
      ),
    [park, exclusionZones],
  );

  const keyValues: [string, number | string | undefined, string?][] =
    useMemo(() => {
      const area_extra: [string, number | string | undefined][] =
        areaWithoutExclusionZones && exclusionZones.length !== 0
          ? [
              [
                "Total area without exlusion zones",
                `${areaWithoutExclusionZones}  km²`,
              ],
            ]
          : [];

      return [
        ["Total area", `${area}  km²`],
        ...area_extra,
        ["Sub area(s)", subAreas.length],
        ["No. Onshore substations", onshoreSubstations.length],
        ["No. Offshore substations", offshoreSubstations.length],
        ["Number of turbines", turbines.length],
        ["Turbine types", turbineTypesString],
        [
          "Min. distance between turbines",
          formatMinDistance(minDistanceBetweenTurbines),
        ],
        [
          "Average depth",
          undefMap(averageValue, formatDepth),
          BATHYMETRY_SOURCE_DESCRIPTION,
        ],
        [
          "Depth range",
          undefMap2(minValue, maxValue, formatDepthRange),
          BATHYMETRY_SOURCE_DESCRIPTION,
        ],
      ];
    }, [
      areaWithoutExclusionZones,
      exclusionZones.length,
      area,
      subAreas.length,
      onshoreSubstations.length,
      offshoreSubstations.length,
      turbines.length,
      turbineTypesString,
      minDistanceBetweenTurbines,
      averageValue,
      minValue,
      maxValue,
    ]);

  return (
    <FlexGrid2 style={{ padding: spaceMedium }}>
      {keyValues.map(([key, value, tooltip]) => (
        <React.Fragment key={key}>
          <Row>
            <p style={{ display: "flex", gap: "0.8rem" }}>
              {key}
              {tooltip && <HelpTooltip text={tooltip} />}
            </p>
            {value === undefined ? (
              <SkeletonBlock style={{ width: "6rem", height: "1rem" }} />
            ) : (
              <p>{value}</p>
            )}
          </Row>
        </React.Fragment>
      ))}
    </FlexGrid2>
  );
};

export const ParkWidget = () => (
  <SafeCard title="Park" id="Park">
    <ParkInner />
  </SafeCard>
);
