import { useMemo } from "react";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { useTypedPath } from "../../../state/pathParams";
import { editorAccessProjectSelector } from "../../../state/user";
import { ProjectBranchInfo, UsageContainerInner } from "./common";
import { Header } from "./style";
import { TurbineTypeUsageType } from "../../../types/turbines";
import { windConfigurationUsageAtomFamily } from "../../../state/windSourceConfiguration";

export default function WindConfigurationUsage({
  windConfigurationId,
}: {
  windConfigurationId: string;
}) {
  const editorAccessProject = useRecoilValue(editorAccessProjectSelector);

  if (!editorAccessProject) return null;

  return (
    <WindConfigurationUsageInner windConfigurationId={windConfigurationId} />
  );
}

function WindConfigurationUsageInner({
  windConfigurationId,
}: {
  windConfigurationId: string;
}) {
  const { projectId } = useTypedPath("projectId");

  const usageLoadable = useRecoilValueLoadable(
    windConfigurationUsageAtomFamily({
      projectId,
      windConfigurationId,
    }),
  );

  return (
    <UsageContainerInner
      loadable={usageLoadable}
      text="This configuration is used in: "
      typeName="branches"
    >
      <ExtraInfo usage={usageLoadable.contents} />
    </UsageContainerInner>
  );
}

function ExtraInfo({ usage }: { usage: TurbineTypeUsageType[] }) {
  const uniqueBranches = useMemo(
    () =>
      usage.reduce<TurbineTypeUsageType[]>(
        (pre, cur) =>
          pre.some((p) => p.branchId === cur.branchId) ? pre : pre.concat(cur),
        [],
      ),
    [usage],
  );

  return (
    <>
      <Header>Wind configuration usage</Header>
      {usage.length > 0 && (
        <div
          style={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
        >
          {uniqueBranches.map((u) => (
            <ProjectBranchInfo key={u.branchId} usage={u} />
          ))}
        </div>
      )}
    </>
  );
}
