import { useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import SphericalMercator from "@mapbox/sphericalmercator";
import { mapRefAtom } from "../state/map";
import * as turf from "@turf/turf";
import { Position } from "@turf/turf";
import { calculateSample } from "../utils/tiles";
import { Feature, LineString } from "geojson";
import { promiseWorker, typedWorker } from "../utils/utils";
import { scream } from "../utils/sentry";
import { isDefined } from "../utils/predicates";
import { MapMouseEvent } from "mapbox-gl";

export const getXTileNumber = (lng: number, zoom: number) =>
  Math.floor(((lng + 180) / 360) * Math.pow(2, zoom));
export const getYTileNumber = (lat: number, zoom: number) =>
  Math.floor(
    ((1 -
      Math.log(
        Math.tan((lat * Math.PI) / 180) + 1 / Math.cos((lat * Math.PI) / 180),
      ) /
        Math.PI) /
      2) *
      Math.pow(2, zoom),
  );

const drawCanvas = async (
  tileFetchFunc: TileFetchFunc,
  x: number,
  y: number,
  z: number,
  tileSize: number,
) => {
  const tile = await tileFetchFunc(x, y, z, tileSize);
  const blob = await tile.blob();

  const canvas = document.createElement("canvas");
  canvas.id = `${x}-${y}-${z}`;
  canvas.height = tileSize;
  canvas.width = tileSize;
  var ctx = canvas.getContext("2d");
  var img = new Image();
  img.src = URL.createObjectURL(blob);
  await new Promise((res) => {
    img.onload = function () {
      res(img);
    };
  });
  if (!ctx) return canvas;
  ctx.drawImage(img, 0, 0);

  return canvas;
};

type TileFetchFunc = (
  x: number,
  y: number,
  z: number,
  tileSize: number,
) => Promise<Response>;

export const useMouseSampler = (
  tileFetchFunc: TileFetchFunc,
  maxZoom?: number,
  tileSize = 256,
  limits?: Position[][],
) => {
  const merc = useMemo(
    () =>
      new SphericalMercator({
        size: tileSize,
        antimeridian: true,
      }),
    [tileSize],
  );
  const map = useRecoilValue(mapRefAtom);
  const [mousePos, setMousePos] = useState<
    undefined | { zoom: number; lat: number; lng: number }
  >();
  const [canvas, setCanvas] = useState<undefined | HTMLCanvasElement>();
  const [sample, setSample] = useState<
    undefined | [number, number, number, number]
  >();

  useEffect(() => {
    if (!map) return;
    const onMouseMove = (e: MapMouseEvent) => {
      if (
        limits &&
        !turf.booleanPointInPolygon(
          turf.point(Object.values(e.lngLat)),
          turf.polygon(limits),
        )
      ) {
        setMousePos(undefined);
        setSample(undefined);
        return;
      }
      setMousePos({
        ...e.lngLat,
        zoom: !maxZoom
          ? Math.floor(map.getZoom())
          : Math.min(Math.floor(map.getZoom()), maxZoom),
      });
    };
    map.on("mousemove", onMouseMove);
    return () => {
      map.off("mousemove", onMouseMove);
    };
  }, [limits, map, setMousePos, maxZoom, setSample]);

  const [x, y, z] = useMemo(() => {
    if (!mousePos) return [0, 0, 0];
    const { lat, lng, zoom } = mousePos;
    const x = getXTileNumber(lng, zoom);
    const y = getYTileNumber(lat, zoom);
    return [x, y, zoom];
  }, [mousePos]);

  useEffect(() => {
    if (x === 0 && y === 0 && z === 0) return;

    let isSubmitted = false;
    const setTileAsync = async () => {
      const canvas = await drawCanvas(tileFetchFunc, x, y, z, tileSize);
      if (!isSubmitted) setCanvas(canvas);
    };
    setTileAsync();
    return () => {
      setCanvas(undefined);
      isSubmitted = true;
    };
  }, [x, y, z, setCanvas, tileSize, tileFetchFunc]);

  useEffect(() => {
    if (!canvas || !mousePos) return;

    const setSampleAsync = async () => {
      const { lat, lng, zoom } = mousePos;
      var ctx = canvas.getContext("2d", { willReadFrequently: true });
      if (!ctx) return;
      const imageData = ctx.getImageData(0, 0, tileSize, tileSize);
      setSample(
        calculateSample(lng, lat, zoom, x, y, tileSize, imageData, merc),
      );
    };
    setSampleAsync();
  }, [mousePos, canvas, setSample, x, y, tileSize, merc]);

  return sample;
};

export const usePointMetainfoFetcher = (
  pointMetainfoFetchFunc: (lng: number, lat: number) => Promise<string>,
  limits?: Position[][],
) => {
  const map = useRecoilValue(mapRefAtom);
  const [mousePos, setMousePos] = useState<
    undefined | { zoom: number; lat: number; lng: number }
  >();
  const [pointMetainfo, setPointMetainfo] = useState<string>("");

  useEffect(() => {
    if (!map) return;
    const onMouseMove = (e: MapMouseEvent) => {
      if (
        limits &&
        !turf.booleanPointInPolygon(
          turf.point(Object.values(e.lngLat)),
          turf.polygon(limits),
        )
      ) {
        setMousePos(undefined);
        return;
      }
      setMousePos((curr) => ({
        ...e.lngLat,
        zoom: curr?.zoom ?? 10,
      }));
    };
    map.on("mousemove", onMouseMove);
    return () => {
      map.off("mousemove", onMouseMove);
    };
  }, [limits, map, setMousePos]);

  useEffect(() => {
    const fetchPointMetadata = setTimeout(() => {
      if (!mousePos) return;
      let isSubmitted = false;
      const setPointMetainfoAsync = async () => {
        const pointMetainfo = await pointMetainfoFetchFunc(
          mousePos.lng,
          mousePos.lat,
        );
        if (isSubmitted) return;
        setPointMetainfo(pointMetainfo);
      };
      setPointMetainfoAsync();

      return () => {
        isSubmitted = true;
      };
    }, 100);

    return () => clearTimeout(fetchPointMetadata);
  }, [mousePos, setPointMetainfo, pointMetainfoFetchFunc]);

  return pointMetainfo;
};

const addTileNumberToPoint = (
  point: number[],
  zoom: number,
): PointWithLngLag => {
  const lng = point[0];
  const lat = point[1];
  return {
    x: getXTileNumber(lng, zoom),
    y: getYTileNumber(lat, zoom),
    lng,
    lat,
  };
};

const getPointsAlongTheLine = (
  line: Feature<LineString>,
  sampleDistance: number,
) => {
  const turfLine = turf.lineString(line.geometry.coordinates);
  const length = turf.length(turfLine, { units: "kilometers" });
  const pointsAlongLine: [number, number][] = [];
  const sampleDistanceInKm = sampleDistance / 1000;
  for (
    let kmAlongTheLine = 0;
    kmAlongTheLine < length;
    kmAlongTheLine = kmAlongTheLine + sampleDistanceInKm
  ) {
    var point = turf.along(turfLine, kmAlongTheLine, {});
    pointsAlongLine.push(point.geometry.coordinates as [number, number]);
  }
  return pointsAlongLine;
};

export const fetchTileAsync = async (
  x: number,
  y: number,
  z: number,
  tileSize: number,
  tileFetchFunc: (
    x: number,
    y: number,
    z: number,
    tileSize: number,
  ) => Promise<Response>,
) => {
  const canvas = await drawCanvas(tileFetchFunc, x, y, z, tileSize).catch(
    (e) => {
      if (e instanceof Response) {
        scream("failed to fetch depth tile", {
          url: e.url,
          status: e.status,
          text: e.statusText,
        });
      } else {
        scream("failed to fetch depth tile", {
          error: e,
        });
      }
      return;
    },
  );
  if (!canvas) return;
  const ctx = canvas.getContext("2d")!; // TODO: Safety here
  const imageData = ctx.getImageData(0, 0, tileSize, tileSize);
  return { imageData, x, y };
};

export type Tile = { x: number; y: number; imageData: ImageData };
export type PointWithLngLag = {
  x: number;
  y: number;
  lng: number;
  lat: number;
};
export type Args = [
  Tile[],
  PointWithLngLag[],
  number,
  number,
  SphericalMercator,
];

export const useCoordsTileSampler = (
  tileFetchFunc: (
    x: number,
    y: number,
    z: number,
    tileSize: number,
  ) => Promise<Response>,
  coords: Position,
  tileSize: number = 256,
  zoom: number = 10,
): [number, number, number, number] | undefined | null => {
  const merc = useMemo(
    () =>
      new SphericalMercator({
        size: tileSize,
        antimeridian: true,
      }),
    [tileSize],
  );

  const [sample, setSample] = useState<
    [number, number, number, number] | undefined | null
  >(undefined);

  useEffect(() => {
    let isSubmitted = false;
    const asyncFunc = async () => {
      const pointWithTileNumber = addTileNumberToPoint(coords, zoom);
      const imageData = await fetchTileAsync(
        pointWithTileNumber.x,
        pointWithTileNumber.y,
        zoom,
        tileSize,
        tileFetchFunc,
      );
      if (!isDefined(imageData)) {
        setSample(null);
        return;
      }

      const worker = typedWorker<Args, [number, number, number, number][]>(
        new Worker(new URL("./depthProfileWebWorker.ts", import.meta.url), {
          type: "module",
        }),
      );

      const samples = await promiseWorker(worker, [
        [imageData],
        [pointWithTileNumber],
        zoom,
        tileSize,
        merc,
      ]);
      worker.terminate();
      if (isSubmitted) return;
      setSample(samples[0]);
    };
    asyncFunc();
    return () => {
      isSubmitted = true;
    };
  }, [merc, tileFetchFunc, tileSize, zoom, coords]);

  return sample;
};

export const coordsTileSampler = async (
  tileFetchFunc: (
    x: number,
    y: number,
    z: number,
    tileSize: number,
  ) => Promise<Response>,
  coords: Position,
  tileSize: number = 256,
  zoom: number = 10,
): Promise<[number, number, number, number] | null> => {
  const merc = new SphericalMercator({
    size: tileSize,
    antimeridian: true,
  });

  const pointWithTileNumber = addTileNumberToPoint(coords, zoom);
  const imageData = await fetchTileAsync(
    pointWithTileNumber.x,
    pointWithTileNumber.y,
    zoom,
    tileSize,
    tileFetchFunc,
  );
  if (!isDefined(imageData)) {
    return null;
  }

  const worker = typedWorker<Args, [number, number, number, number][]>(
    new Worker(new URL("./depthProfileWebWorker.ts", import.meta.url), {
      type: "module",
    }),
  );

  const samples = await promiseWorker(worker, [
    [imageData],
    [pointWithTileNumber],
    zoom,
    tileSize,
    merc,
  ]);
  worker.terminate();
  const sample = samples[0];

  return sample;
};

export const useLineInTileSampler = (
  tileFetchFunc: (
    x: number,
    y: number,
    z: number,
    tileSize: number,
  ) => Promise<Response>,
  line: Feature<LineString>,
  sampleDistance: number,
  tileSize: number = 256,
  zoom: number = 10,
): [[number, number, number, number][], [number, number][], boolean] => {
  const merc = useMemo(
    () =>
      new SphericalMercator({
        size: tileSize,
        antimeridian: true,
      }),
    [tileSize],
  );

  const [isDone, setIsDone] = useState(false);
  const [samples, setSamples] = useState<[number, number, number, number][]>(
    [],
  );
  const pointsAlongTheLine = useMemo(
    () => getPointsAlongTheLine(line, sampleDistance),
    [line, sampleDistance],
  );

  useEffect(() => {
    const pointsWithTileNumber = pointsAlongTheLine.map((point) =>
      addTileNumberToPoint(point, zoom),
    );

    const uniqueTiles = pointsWithTileNumber.reduce<{ x: number; y: number }[]>(
      (acc, currentTile) => {
        const tileIsPresent =
          acc.findIndex((t) => t.x === currentTile.x && t.y === currentTile.y) >
          -1;
        if (!tileIsPresent) {
          acc.push({ x: currentTile.x, y: currentTile.y });
        }
        return acc;
      },
      [],
    );

    const imageDataPromises = uniqueTiles.map((tile) => {
      const imageData = fetchTileAsync(
        tile.x,
        tile.y,
        zoom,
        tileSize,
        tileFetchFunc,
      );
      return imageData;
    });

    let isSubmitted = false;
    const asyncFunc = async () => {
      const allImageData = (await Promise.all(imageDataPromises)).filter(
        isDefined,
      );
      if (allImageData.length === 0) {
        setIsDone(true);
        return;
      }

      const worker = typedWorker<Args, [number, number, number, number][]>(
        new Worker(new URL("./depthProfileWebWorker.ts", import.meta.url), {
          type: "module",
        }),
      );

      const samples = await promiseWorker(worker, [
        allImageData,
        pointsWithTileNumber,
        zoom,
        tileSize,
        merc,
      ]);
      worker.terminate();
      if (isSubmitted) return;
      setSamples(samples);
      setIsDone(true);
    };
    asyncFunc();
    return () => {
      isSubmitted = true;
    };
  }, [
    line,
    merc,
    tileFetchFunc,
    tileSize,
    zoom,
    sampleDistance,
    pointsAlongTheLine,
  ]);

  return [samples, pointsAlongTheLine, isDone];
};
