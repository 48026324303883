import styled from "styled-components";
import { colors } from "styles/colors";
import { typography } from "styles/typography";

const ItemWrapper = styled.div`
  ${typography.body}
  color: ${colors.textPrimary};
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

const Square = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  min-width: 2.4rem;
  max-width: 2.4rem;
  min-height: 2.4rem;
  max-height: 2.4rem;
  border: 0.1rem solid;
  background-color: ${({ color }) => color};
  box-sizing: border-box;
  border-radius: 0.4rem;
`;

export type ItemProps = {
  label: string;
  color: string;
};

const Item = ({ color, label }: ItemProps) => {
  return (
    <ItemWrapper>
      <Square color={color} />
      <span>{label}</span>
    </ItemWrapper>
  );
};

export default Item;
