import CloseableHint from "./CloseableHint";

export const editGeneralHelpHintType = "editGeneralHelpHintType";

const EditGeneralHelp = ({
  closeCallback,
  gotItCallback,
}: {
  closeCallback: () => void;
  gotItCallback?: () => void;
}) => {
  return (
    <CloseableHint
      closeCallback={closeCallback}
      gotItCallback={gotItCallback}
      timeToDissapearMS={10000}
    >{`Finish by pressing "Enter"`}</CloseableHint>
  );
};

export default EditGeneralHelp;
