import { useEffect } from "react";
import { selectorFamily, useRecoilValue } from "recoil";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { WarningProps } from "./ValidationWarnings";
import { branchIdSelector } from "../../state/pathParams";
import { getExportCablesInBranchSelectorFamily } from "state/cable";
import { getParkCapacitySelector } from "state/layout";
import { currentExportCableTypesSelector } from "components/Cabling/Generate/state";
import { sum } from "utils/utils";

const exportSystemCapacityWarning = selectorFamily<
  | {
      type: ValidationWarningTypes.ExportSystemInsufficientCapacity;
      featureIds: string[];
      parkId: string;
      detailsText: string;
    }
  | undefined,
  { branchId: string; parkId: string }
>({
  key: "exportSystemCapacityWarningSelectorFamily",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const parkCapacity = get(getParkCapacitySelector({ parkId, branchId }));

      const exportCableTypes = get(currentExportCableTypesSelector);

      const exportCables = get(
        getExportCablesInBranchSelectorFamily({ parkId, branchId }),
      );

      if (exportCables.length === 0) return undefined;

      const capacityById = new Map(
        exportCableTypes.map((t) => [t.id, t.powerRating / 1e6]),
      );

      const capacities = exportCables.map(
        ({ properties: { cableTypeId, onshoreCableTypeId } }) => {
          if (!cableTypeId || !onshoreCableTypeId) return 0;

          const offshoreCapacity = capacityById.get(cableTypeId) ?? 0;
          const onshoreCapacity = capacityById.get(onshoreCableTypeId) ?? 0;

          return Math.min(offshoreCapacity, onshoreCapacity);
        },
      );

      const exportSystemCapacity = sum(capacities);
      if (exportSystemCapacity < parkCapacity) {
        const diff = parkCapacity - exportSystemCapacity;
        return {
          type: ValidationWarningTypes.ExportSystemInsufficientCapacity,
          detailsText: `The total capacity of the export cables (${exportSystemCapacity.toFixed(0)} MW) is ${diff.toFixed(0)} MW lower than the total capacity of the park (${parkCapacity.toFixed(0)} MW).`,
          featureIds: exportCables.map((c) => c.id),
          parkId,
        };
      }

      return undefined;
    },
});

export const ExportSystemInsufficientCapacityWarning = ({
  warn,
  remove,
  parkId,
}: {
  parkId: string;
} & WarningProps) => {
  const branchId = useRecoilValue(branchIdSelector);

  const warning = useRecoilValue(
    exportSystemCapacityWarning({
      parkId,
      branchId: branchId ?? "",
    }),
  );
  useEffect(() => {
    if (!warning) return;
    warn(warning);
    return () => {
      remove(ValidationWarningTypes.ExportSystemInsufficientCapacity);
    };
  }, [remove, warn, warning]);

  return null;
};
