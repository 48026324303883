import { useCallback, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { v4 as uuidv4 } from "uuid";
import { toastMessagesAtom } from "../state/toast";
import { ProjectFeature } from "../types/feature";
import { currentSelectionArrayAtom } from "../state/selection";
import { isDefined, isTurbine } from "../utils/predicates";
import { useProjectElementsCrud } from "../components/ProjectElements/useProjectElementsCrud";
import { getFieldNameWithCorrectCasing, partition } from "../utils/utils";
import { allSimpleTurbineTypesSelector } from "../state/turbines";
import {
  DECIMAL_PRECISION,
  SIMPLIFY_TOLERANCE,
  expandMultiFeaturesExceptMultiPolygonWithSeveralPolygons,
  reduceCoordinatePrecisionFeature,
  simplifyToSize,
} from "../utils/geojson/utils";
import { fastMax } from "../utils/utils";
import { _FeatureParser } from "../types/feature";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import { parkIdSelector } from "state/pathParams";
import {
  LeftModalNames,
  leftModalOpenStateAtom,
} from "components/Design/ProjectHistory/state";
import { currentExternalLayerSelection } from "state/externalLayerSelection";

export const ABLY_MAX_SIZE = 60 * 1024;
const WARN_TOLERANCE_THREHSOLD = 0.01;

const WARN_TOLERANCE_THREHSOLD_TEXT =
  "Some features have been simplified in order to fit within our size restriction. Please inspect the features to make sure they look acceptable";

const createFeatureClones = (
  features: (ProjectFeature | MapboxGeoJSONFeature)[],
  parkId: undefined | string,
): ProjectFeature[] => {
  const oldIdToNewIdMap = new Map(
    features.map((feature) => [feature.id, uuidv4()]),
  );

  return (
    features
      .map((feature) => {
        const newId = oldIdToNewIdMap.get(feature.id)!; // Safety: all feature ids are in the map.

        const maybeMapboxGeojsonFeature = feature as MapboxGeoJSONFeature;
        const nameField = getFieldNameWithCorrectCasing(
          maybeMapboxGeojsonFeature.properties,
          "name",
        );
        let name;
        if (nameField) {
          name = maybeMapboxGeojsonFeature.properties?.[nameField];
        }

        if (!name) {
          name = maybeMapboxGeojsonFeature.sourceLayer
            ? `${maybeMapboxGeojsonFeature.sourceLayer} - Copy`
            : "Copied feature";
        }

        return _FeatureParser.parse({
          id: newId,
          type: feature.type,
          geometry: {
            ...feature.geometry,
          },
          properties: {
            ...feature.properties,
            id: newId,
            name,
          },
        });
      })
      // Add reference to new parentIds
      .map((clone) => {
        if (!clone.properties) return clone;

        // ParentIds: if we are pasting a park with features in it, the
        // parentIds of the features will be in `oldTdToNewIdMap`.  In that case
        // we should update the ids.   If not, we should update the id to the
        // passed id, since that's the current park.  This will make pasted
        // features belong to the current park.
        if (clone.properties.parentIds) {
          clone.properties.parentIds = clone.properties.parentIds
            .map((oldParentId) => {
              return oldIdToNewIdMap.get(oldParentId) ?? parkId;
            })
            .filter(isDefined);
        }

        if ((clone.properties as any)["fromId"])
          (clone.properties as any)["fromId"] = oldIdToNewIdMap.get(
            (clone.properties as any)["fromId"],
          );
        if ((clone.properties as any)["toId"])
          (clone.properties as any)["toId"] = oldIdToNewIdMap.get(
            (clone.properties as any)["toId"],
          );
        if (clone.properties["anchor"])
          clone.properties["anchor"] = oldIdToNewIdMap.get(
            String(clone.properties["anchor"]),
          );
        if (clone.properties["target"])
          clone.properties["target"] = oldIdToNewIdMap.get(
            String(clone.properties["target"]),
          );
        if (clone.properties["fromSubstationId"])
          clone.properties["fromSubstationId"] = oldIdToNewIdMap.get(
            String(clone.properties["fromSubstationId"]),
          );
        if (clone.properties["toSubstationId"])
          clone.properties["toSubstationId"] = oldIdToNewIdMap.get(
            String(clone.properties["toSubstationId"]),
          );
        return clone;
      })
  );
};

export type CloneFeaturesReturnType = (
  featuresToClone: ProjectFeature[],
  successMessage?: string,
  selectAfterCloning?: boolean,
) => ProjectFeature[];

const reducePrecisionIfNeeded = (
  feature: ProjectFeature,
  decimals: number,
  maxSize: number,
) =>
  new Blob([JSON.stringify(feature)]).size < maxSize
    ? feature
    : reduceCoordinatePrecisionFeature(feature, decimals);

const useCloneFeaturesWithDefaultCanvasSource = (): CloneFeaturesReturnType => {
  const { update: updateFeatures } = useProjectElementsCrud();
  const setToastMessagesAtom = useSetRecoilState(toastMessagesAtom);
  const setCurrentSelectionArray = useSetRecoilState(currentSelectionArrayAtom);
  const allTurbineTypes = useRecoilValue(allSimpleTurbineTypesSelector);
  const turbineIdToTurbineType = useMemo(
    () => Object.fromEntries(allTurbineTypes.map((t) => [t.id, t])),
    [allTurbineTypes],
  );
  const parkId = useRecoilValue(parkIdSelector);
  const setLeftModalOpen = useSetRecoilState(leftModalOpenStateAtom);
  const setCurrentExternalLayerSelection = useSetRecoilState(
    currentExternalLayerSelection,
  );

  const cloneFeatures = useCallback(
    (
      features: ProjectFeature[],
      successMessage?: string,
      selectAfterCloning = true,
    ): ProjectFeature[] => {
      const idSet = new Set(features.map((f) => f.id));
      const [featuresThatAreOk, featuresMissingParentOrTurbineType] = partition(
        features,
        (f) => {
          let valid = true;
          if (f.properties.parentIds) {
            valid = f.properties.parentIds.every((id) => idSet.has(id));
          }

          if (valid && isTurbine(f)) {
            valid = isDefined(
              turbineIdToTurbineType[f.properties.turbineTypeId],
            );
          }

          return valid;
        },
      );

      let featuresToClone = featuresThatAreOk;
      if (parkId) {
        // If we're in a park update the parkId of these features to the current park.
        featuresToClone = featuresToClone.concat(
          featuresMissingParentOrTurbineType.map((f) =>
            _FeatureParser.parse({
              ...f,
              properties: {
                ...f.properties,
                parentIds: [parkId],
              },
            }),
          ),
        );
      } else {
        if (featuresMissingParentOrTurbineType.length !== 0) {
          const text =
            featuresMissingParentOrTurbineType.length < 10
              ? `${featuresMissingParentOrTurbineType.length} pasted feature(s) belonged to a park which was not copied, they have been transformed to type 'Other'`
              : `${featuresMissingParentOrTurbineType.length} pasted feature(s) belonged to a park which was not copied, they have been transformed to type 'Other', did you know that you can copy the whole park?`;
          setToastMessagesAtom((toastMessages) => [
            ...toastMessages,
            {
              text,
              timeout: 8000,
            },
          ]);

          featuresToClone = featuresToClone.concat(
            featuresMissingParentOrTurbineType.map((f) => ({
              ...f,
              properties: {
                id: f.id,
              },
            })),
          );
        }
      }

      const clones: ProjectFeature[] = createFeatureClones(
        featuresToClone,
        parkId,
      );

      const clonesExpandedMulti = clones.flatMap(
        expandMultiFeaturesExceptMultiPolygonWithSeveralPolygons,
      );

      const clonesExpandedMultiSimplifiedIfNeeded = clonesExpandedMulti
        .map((f) =>
          reducePrecisionIfNeeded(f, DECIMAL_PRECISION, ABLY_MAX_SIZE),
        )
        .map((f) =>
          simplifyToSize<ProjectFeature>(f, ABLY_MAX_SIZE, SIMPLIFY_TOLERANCE),
        );

      const simplifiedFeatures = clonesExpandedMultiSimplifiedIfNeeded.map(
        ([feature]) => feature,
      );
      const tolerances = clonesExpandedMultiSimplifiedIfNeeded.map(
        ([, tolerance]) => tolerance,
      );

      if (fastMax(tolerances) >= WARN_TOLERANCE_THREHSOLD) {
        setToastMessagesAtom((toastMessages) => [
          ...toastMessages,
          { text: WARN_TOLERANCE_THREHSOLD_TEXT, timeout: 10000 },
        ]);
      }

      updateFeatures({ add: simplifiedFeatures });

      if (successMessage) {
        setToastMessagesAtom((toastMessages) => [
          ...toastMessages,
          { text: successMessage, timeout: 2000 },
        ]);
      }

      if (selectAfterCloning) {
        setCurrentSelectionArray(simplifiedFeatures.map((f) => f.id));
        setCurrentExternalLayerSelection([]);
      }

      setLeftModalOpen(LeftModalNames.ProjectElements);

      return simplifiedFeatures;
    },
    [
      parkId,
      updateFeatures,
      turbineIdToTurbineType,
      setToastMessagesAtom,
      setCurrentSelectionArray,
      setLeftModalOpen,
      setCurrentExternalLayerSelection,
    ],
  );

  return cloneFeatures;
};

export default useCloneFeaturesWithDefaultCanvasSource;
