import { useCallback } from "react";
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import { useTypedPath } from "../../state/pathParams";
import { currentVersionSelector } from "../../state/project";
import { projectFoldersRefreshAtom, projectFoldersAtomFamily } from "./state";
import {
  createProjectElementsFolder,
  ProjectElementFolderType,
  deleteProjectElementsFolder,
  updateProjectElementsFolder,
} from "./service";
import { dedup } from "utils/utils";

export const useRefreshProjectElementsFolders = () => {
  const setProjectFoldersRefresh = useSetRecoilState(projectFoldersRefreshAtom);

  return useCallback(() => {
    setProjectFoldersRefresh((currVal) => currVal + 1);
  }, [setProjectFoldersRefresh]);
};

export const useProjectElementsFoldersCrud = () => {
  const { projectId, branchId } = useTypedPath("projectId", "branchId");

  const version = useRecoilValue(currentVersionSelector);

  const projectFolders = useRecoilValue(
    projectFoldersAtomFamily({
      projectId,
      branchId,
      version,
    }),
  );

  const localCreate = useRecoilCallback(
    ({ set }) =>
      (folder: ProjectElementFolderType) => {
        set(
          projectFoldersAtomFamily({
            projectId,
            branchId,
          }),
          (currVal) => [...currVal, folder],
        );
      },
    [branchId, projectId],
  );

  const localUpdate = useRecoilCallback(
    ({ set }) =>
      (folder: Partial<ProjectElementFolderType>) => {
        set(
          projectFoldersAtomFamily({
            projectId,
            branchId,
          }),
          (currVal) => {
            return currVal.map((currFolder) => {
              return currFolder.folderId === folder.folderId
                ? { ...currFolder, ...folder }
                : currFolder;
            });
          },
        );
      },
    [branchId, projectId],
  );

  const localDelete = useRecoilCallback(
    ({ set }) =>
      (folderId: string) => {
        set(
          projectFoldersAtomFamily({
            projectId,
            branchId,
          }),
          (currVal) => currVal.filter((folder) => folder.folderId !== folderId),
        );
      },
    [branchId, projectId],
  );

  const create = useRecoilCallback(
    () =>
      async (
        folder: Omit<ProjectElementFolderType, "folderId">,
        onTemporaryCreated?: (tempFolderId: string) => void,
      ) => {
        if (!projectId) return;
        const tempFolderId = (Math.random() * 1000).toFixed(0).toString();
        localCreate({
          ...folder,
          folderId: tempFolderId,
          sortOrder: Number.MAX_VALUE,
          isTemporary: true,
        });
        onTemporaryCreated?.(tempFolderId);
        const response = await createProjectElementsFolder(
          projectId,
          branchId,
          folder,
        );

        localDelete(tempFolderId);
        localCreate(response);
        return response;
      },
    [localCreate, projectId, branchId, localDelete],
  );

  const remove = useRecoilCallback(
    () => async (folderId: string) => {
      if (!projectId) return;
      localDelete(folderId);

      await deleteProjectElementsFolder(projectId, branchId, folderId);
    },
    [branchId, projectId, localDelete],
  );

  const update = useRecoilCallback(
    () => async (folder: ProjectElementFolderType) => {
      if (!projectId) return;
      folder.featureIds = dedup(folder.featureIds);
      localUpdate(folder);
      const response = await updateProjectElementsFolder(
        projectId,
        branchId,
        folder,
      );
      return response;
    },
    [branchId, projectId, localUpdate],
  );

  return {
    items: projectFolders,
    create,
    remove,
    update,
    localCreate,
    localDelete,
    localUpdate,
  };
};
