import styled from "styled-components";
import { spaceLarge, spaceMedium } from "../../../styles/space";

export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.2rem;
`;

export const HeaderTitle = styled.h4`
  margin: 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${spaceLarge};
  justify-content: end;
`;

export const RightSideButtonsContainer = styled.div`
  position: fixed;
  right: ${spaceMedium};
  width: var(--side-bars-width);
  top: calc(
    calc(var(--top-bar-menu-height) + var(--branch-tab-bar-height)) +
      ${spaceMedium}
  );
  z-index: 3;
  display: flex;
  flex-direction: column;
`;
