import { useEffect } from "react";
import { selector, selectorFamily, useRecoilValue } from "recoil";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { getCablesInBranchSelectorFamily } from "../../state/cable";
import { getTurbinesInBranchSelectorFamily } from "../../state/layout";
import { allSimpleTurbineTypesSelector } from "../../state/turbines";
import { TurbineFeature } from "../../types/feature";
import { SimpleTurbineType } from "../../types/turbines";
import { allCableTypesSelector } from "../Cabling/Generate/state";
import { WarningProps } from "./ValidationWarnings";
import { branchIdSelector } from "state/pathParams";

const turbineMapSelector = selectorFamily<
  Record<string, TurbineFeature>,
  { parkId: string; branchId: string }
>({
  key: "turbineMap",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const turbines = get(
        getTurbinesInBranchSelectorFamily({
          parkId: parkId,
          branchId: branchId,
        }),
      );
      return turbines.reduce<Record<string, TurbineFeature>>((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});
    },
});

const turbineTypesMapSelector = selector({
  key: "turbineTypesMap",
  get: ({ get }) => {
    const allTurbineTypes = get(allSimpleTurbineTypesSelector);
    return allTurbineTypes.reduce<Record<string, SimpleTurbineType>>(
      (acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      },
      {},
    );
  },
});

export const turbineCableWrongVoltageTypeSelectorFamily = selectorFamily<
  | {
      type: ValidationWarningTypes.TurbinesAndCablesErroneousIAVoltageTypes;
      featureIds: string[];
      parkId: string;
    }
  | undefined,
  { parkId: string; branchId: string }
>({
  key: "turbineCableWrongVoltageTypeSelectorFamily",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const parkCables = get(
        getCablesInBranchSelectorFamily({ parkId, branchId }),
      );
      const cableTypes = get(allCableTypesSelector);

      const turbineMap = get(turbineMapSelector({ parkId, branchId }));
      const turbineTypesMap = get(turbineTypesMapSelector);
      const erroneousTurbineIds: string[] = [];

      for (const cable of parkCables) {
        const cableType = cableTypes.find(
          (t) => t.id === cable.properties.cableTypeId,
        );
        if (!cableType) {
          continue;
        }

        const fromId = cable.properties.fromId;
        const toId = cable.properties.toId;
        const fromTurbine = turbineMap[fromId];
        const toTurbine = turbineMap[toId];

        if (fromTurbine) {
          const fromTurbineType =
            turbineTypesMap[fromTurbine.properties.turbineTypeId];
          if (
            fromTurbineType &&
            !fromTurbineType.voltage.includes(cableType.voltage)
          ) {
            erroneousTurbineIds.push(fromTurbine.id);
          }
        }

        if (toTurbine) {
          const toTurbineType =
            turbineTypesMap[toTurbine.properties.turbineTypeId];
          if (
            toTurbineType &&
            !toTurbineType.voltage.includes(cableType.voltage)
          ) {
            erroneousTurbineIds.push(toTurbine.id);
          }
        }
      }
      return erroneousTurbineIds.length === 0
        ? undefined
        : {
            type: ValidationWarningTypes.TurbinesAndCablesErroneousIAVoltageTypes,
            featureIds: [...new Set(erroneousTurbineIds)],
            parkId: parkId,
          };
    },
});

const TurbineCableWrongVoltageTypeInner = ({
  parkId,
  warn,
  remove,
}: { parkId: string } & WarningProps) => {
  const branchId = useRecoilValue(branchIdSelector);
  const turbineCableWrongVoltageTypeWarning = useRecoilValue(
    turbineCableWrongVoltageTypeSelectorFamily({
      parkId,
      branchId: branchId ?? "",
    }),
  );
  useEffect(() => {
    if (!turbineCableWrongVoltageTypeWarning) return;
    warn(turbineCableWrongVoltageTypeWarning);
    return () => {
      remove(ValidationWarningTypes.TurbinesAndCablesErroneousIAVoltageTypes);
    };
  }, [remove, warn, turbineCableWrongVoltageTypeWarning]);

  useEffect(
    () => () => {
      remove(ValidationWarningTypes.TurbinesAndCablesErroneousIAVoltageTypes);
    },
    [remove],
  );

  return null;
};

const TurbineCableWrongVoltageType = ({
  parkId,
  ...props
}: { parkId: string } & WarningProps) => {
  if (!parkId) return null;
  return <TurbineCableWrongVoltageTypeInner parkId={parkId} {...props} />;
};

export default TurbineCableWrongVoltageType;
