import React, { useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Mixpanel } from "mixpanel";
import CloseIcon from "@icons/24/Close.svg";
import FileIcon from "@icons/24/File.svg";
import { IconREMSize, typography } from "styles/typography";
import { IconBtn } from "components/General/Icons";
import FullScreenModal from "components/FullScreenModal/FullScreenModal";
import UploadDataLayerGeneral from "components/UploadModal/components/NewUploadTab/UploadDataLayer/UploadDataLayerGeneral";
import {
  replaceCustomOrganisationLayer,
  uploadCustomOrganisationLayer,
} from "services/customLayersAPIService";
import { modalTypeOpenAtom } from "state/modal";
import { organisationIdSelector } from "state/pathParams";
import { useRecoilValueDef } from "utils/recoil";
import { refreshOrganisationLibraryLayersAtom } from "../state";
import { useDataLibraryLayersCrud } from "../useDataLibraryLayersCrud";
import { HeaderRow, Modal, Title } from "./shared";

export const UploadOrganisationDataLayerModalType =
  "uploadOrganisationDataLayerModal" as const;

const UploadOrganisationDataLayerModalInner = ({
  fileIdToReplace,
  addToPackageId,
}: {
  fileIdToReplace?: string;
  addToPackageId?: string;
}) => {
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const organisationId = useRecoilValueDef(organisationIdSelector);
  const refreshUploadedFiles = useSetRecoilState(
    refreshOrganisationLibraryLayersAtom,
  );
  const { addLayersToPackage } = useDataLibraryLayersCrud();

  const onClose = useCallback(() => {
    setModalTypeOpen(undefined);
  }, [setModalTypeOpen]);

  return (
    <FullScreenModal closeOnWrapperClick={false}>
      <Modal>
        <HeaderRow>
          <Title>
            {fileIdToReplace ? <h2>Replace layer</h2> : <h2>Upload</h2>}
          </Title>
          <IconBtn size="1.5rem" onClick={onClose}>
            <CloseIcon />
          </IconBtn>
        </HeaderRow>
        <div style={{ display: "flex", alignItems: "center", gap: "0.8rem" }}>
          <IconREMSize height={1.4} width={1.4}>
            <FileIcon />
          </IconREMSize>
          {fileIdToReplace ? (
            <p style={typography.body}>Replace existing layer with new data</p>
          ) : (
            <p style={typography.body}>
              Uploaded data will only be visible for projects with access to
              this layer
            </p>
          )}
        </div>

        {fileIdToReplace ? (
          <UploadDataLayerGeneral
            upload={(file) =>
              replaceCustomOrganisationLayer(
                organisationId,
                file,
                fileIdToReplace,
              )
            }
            onDoneClick={onClose}
            onAfterUploadFile={(_response, file) => {
              Mixpanel.track("Replace organisation layer", {
                layerName: file.name,
                size: file.size,
              });
            }}
            onAfterUploadAll={() => refreshUploadedFiles((curr) => curr + 1)}
            maxNumberOfLayers={1}
          />
        ) : (
          <UploadDataLayerGeneral
            upload={(file) =>
              uploadCustomOrganisationLayer(organisationId, file)
            }
            onDoneClick={onClose}
            onAfterUploadFile={(_response, file) => {
              Mixpanel.track("Upload organisation layer", {
                layerName: file.name,
                size: file.size,
              });
            }}
            onAfterUploadAll={(responses) => {
              refreshUploadedFiles((curr) => curr + 1);

              if (addToPackageId) {
                return addLayersToPackage(
                  organisationId,
                  addToPackageId,
                  responses.map((r) => r.output_file_id),
                );
              }
            }}
          />
        )}
      </Modal>
    </FullScreenModal>
  );
};

const UploadOrganisationLayerModal = () => {
  const modalTypeOpen = useRecoilValue(modalTypeOpenAtom);
  if (modalTypeOpen?.modalType !== UploadOrganisationDataLayerModalType) {
    return null;
  }

  return (
    <UploadOrganisationDataLayerModalInner
      fileIdToReplace={modalTypeOpen.metadata?.fileIdToReplace}
      addToPackageId={modalTypeOpen.metadata?.addToPackageId}
    />
  );
};

export default UploadOrganisationLayerModal;
