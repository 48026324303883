import { useEffect } from "react";
import { selectorFamily, useRecoilValue } from "recoil";
import { getParkFeatureSelectorFamily } from "../../state/park";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { WarningProps } from "./ValidationWarnings";
import { ParkFeature } from "../../types/feature";
import { branchIdSelector } from "../../state/pathParams";
import { getDisconnectedExportSubstationsInParkAndBranch } from "components/ProductionV2/state";

export const substationNotConnectedWarningSelectorFamily = selectorFamily<
  | {
      type: ValidationWarningTypes.SubstationNotConnected;
      featureIds: string[];
      parkId: string;
    }
  | undefined,
  {
    parkId: string;
    branchId: string;
  }
>({
  key: "substationNotConnectedWarningSelectorFamily",
  get:
    ({ parkId, branchId }) =>
    ({ get }) => {
      const invalidSubstations = get(
        getDisconnectedExportSubstationsInParkAndBranch({
          parkId: parkId,
          branchId: branchId,
        }),
      );
      return invalidSubstations.length === 0
        ? undefined
        : {
            type: ValidationWarningTypes.SubstationNotConnected,
            featureIds: invalidSubstations.map((s) => s.id),
            parkId: parkId,
          };
    },
});

const SubstationNotConnectedInner = ({
  park,
  branchId,
  warn,
  remove,
}: {
  park: ParkFeature;
  branchId: string;
} & WarningProps) => {
  const parkId = park.id;
  const invalidSubstationsWarning = useRecoilValue(
    substationNotConnectedWarningSelectorFamily({ parkId: parkId, branchId }),
  );

  // Update validation warning
  useEffect(() => {
    if (!invalidSubstationsWarning) return;
    warn(invalidSubstationsWarning);
    return () => {
      remove(ValidationWarningTypes.SubstationNotConnected);
    };
  }, [park, remove, invalidSubstationsWarning, warn]);

  return null;
};

const SubstationNotConnected = ({
  parkId,
  ...props
}: { parkId: string } & WarningProps) => {
  const park = useRecoilValue(getParkFeatureSelectorFamily({ parkId }));
  const branchId = useRecoilValue(branchIdSelector);

  if (!park || !branchId) return null;
  return (
    <SubstationNotConnectedInner park={park} branchId={branchId} {...props} />
  );
};

export default SubstationNotConnected;
