import { atom, atomFamily, noWait, selectorFamily } from "recoil";
import { z, ZodError } from "zod";
import { LAYER_URL_KEY } from "../const";
import { CustomLayer, Layer } from "../../../types/layers";
import { getAllLayersSelector } from "../../../state/layer";
import { customLayersMetadataSelectorAsync } from "../../../state/customLayers";
import { defaultLayerSettings } from "./const";
import { listLayerSettings } from "./service";
import { LayerSettings } from "./types";
import { visibleInUrl, hasAnyLayerInUrl } from "../utils";
import { scream, sendInfo } from "../../../utils/sentry";
import { loggedInUserSelector } from "../../../state/user";
import { syncLocalStorage } from "state/effects";

export enum SourceName {
  Original = "Original",
  English = "English",
}

const _SourceName = z.object({
  language: z.nativeEnum(SourceName),
});

const SELECTED_SOURCE_NAME_LS_KEY =
  "vind:selected-source-name-language" as const;

export const selectedSourceNameAtom = atom<{ language: SourceName }>({
  key: "sourceNameAtom",
  default: { language: SourceName.Original },
  effects: [syncLocalStorage(SELECTED_SOURCE_NAME_LS_KEY, _SourceName)],
});

export const allLayerSettingsAtomFamily = atomFamily<
  LayerSettings[],
  { projectId: string }
>({
  key: "allLayerSettingsAtomFamily",
  default: selectorFamily({
    key: "allLayerSettingsSelectorFamily",
    get:
      ({ projectId }) =>
      () => {
        if (!projectId) return [];
        return listLayerSettings(projectId).catch((e) => {
          scream(e);
          return [];
        });
      },
  }),
});

const getLayerSettingsLocalStorageKey = (nodeId: string, userId: string) =>
  `vind:layer-visibility-${nodeId}-${userId}`;
export const layerVisibleAtomFamily = atomFamily<
  boolean,
  { projectId: string; layerId: string }
>({
  key: "layerVisibleAtomFamily",
  default: selectorFamily({
    key: "layerVisibleAtomFamily.default",
    get:
      ({ projectId, layerId }) =>
      ({ get }) => {
        const userId = get(loggedInUserSelector)?.user_id;

        if (
          !hasAnyLayerInUrl(LAYER_URL_KEY) &&
          !(
            typeof window === "undefined" ||
            !("localStorage" in window) ||
            !projectId ||
            !userId
          )
        ) {
          const key = getLayerSettingsLocalStorageKey(projectId, userId);

          try {
            const storageItem = localStorage.getItem(key);
            if (storageItem) {
              const result = JSON.parse(storageItem) as string[];
              if (result.includes(layerId)) {
                return true;
              }
            }
          } catch (err) {
            sendInfo("Could not read local storage", {
              key: key,
              err,
            });
          }
        }

        const visibleFromSharedUrl = visibleInUrl(layerId, LAYER_URL_KEY);
        if (visibleFromSharedUrl) return true;

        const allLayersSettings = get(
          allLayerSettingsAtomFamily({ projectId }),
        );
        const settingsForLayer = allLayersSettings.find(
          (l) => l.id === layerId,
        );
        return settingsForLayer?.standard ?? false;
      },
  }),
  effects: ({ layerId, projectId }) => [
    ({ onSet, getPromise }) => {
      onSet(async (newValue) => {
        const userId = (await getPromise(loggedInUserSelector))?.user_id;

        if (
          typeof window === "undefined" ||
          !("localStorage" in window) ||
          !projectId ||
          !userId
        ) {
          return;
        }

        const key = getLayerSettingsLocalStorageKey(projectId, userId);
        try {
          const currentSavedItems = z
            .string()
            .array()
            .parse(JSON.parse(localStorage.getItem(key) ?? "[]"));
          if (newValue) {
            localStorage.setItem(
              key,
              JSON.stringify([...currentSavedItems, layerId]),
            );
          } else {
            localStorage.setItem(
              key,
              JSON.stringify(currentSavedItems.filter((i) => i !== layerId)),
            );
          }
        } catch (err) {
          if (err instanceof ZodError) {
            localStorage.removeItem(key);
          }
          sendInfo("Could not set local storage", {
            key: key,
            newValue,
            err,
          });
        }
      });
    },
  ],
});

export const layerSettingSelectorFamily = selectorFamily<
  LayerSettings,
  { projectId: string; layerId: string }
>({
  key: "layerSettingSelectorFamily",
  get:
    ({ projectId, layerId }) =>
    ({ get }) => {
      const visible = get(layerVisibleAtomFamily({ projectId, layerId }));
      const allLayerSettings = get(allLayerSettingsAtomFamily({ projectId }));
      const settingsForLayer = allLayerSettings.find((s) => s.id === layerId);
      return {
        ...(settingsForLayer ?? defaultLayerSettings(layerId)),
        visible,
      };
    },
});

export const layersSettingSelectorFamily = selectorFamily<
  LayerSettings[],
  { projectId: string; layerIds: string[] }
>({
  key: "layersSettingSelectorFamily",
  get:
    ({ projectId, layerIds }) =>
    ({ get }) =>
      layerIds.map((layerId) =>
        get(layerSettingSelectorFamily({ projectId, layerId })),
      ),
});

/** Get all layers as a list */
export const getLayers = selectorFamily<Layer[], { projectId: string }>({
  key: "getLayersS",
  get:
    ({ projectId }) =>
    ({ get }) => {
      const gisDataLayers = get(getAllLayersSelector({ projectId }));
      const customLayers = get(
        noWait(
          customLayersMetadataSelectorAsync({
            nodeId: projectId,
          }),
        ),
      );
      const successLayers = gisDataLayers;
      return Object.values(successLayers)
        .concat(customLayers.contents ?? [])
        .flat();
    },
});

/** Get all layers as a `Map` from layer ID to the layer. */
export const getLayersMap = selectorFamily<
  Map<string, Layer>,
  { projectId: string }
>({
  key: "getLayersMap",
  get:
    ({ projectId }) =>
    ({ get }) => {
      const layers = get(getLayers({ projectId }));
      return new Map(
        layers.map((layer) => {
          return [layer.id, layer];
        }),
      );
    },
});

export const getSelectedLayers = selectorFamily<Layer[], { projectId: string }>(
  {
    key: "getSelectedLayers",
    get:
      ({ projectId }) =>
      ({ get }) => {
        const successLayers = get(getAllLayersSelector({ projectId }));

        const allLayerSettings = get(allLayerSettingsAtomFamily({ projectId }));
        return Object.values(successLayers)
          .flat()
          .filter((layer) => {
            const settingsForLayer = allLayerSettings.find(
              (s) => s.id === layer.id,
            );
            return settingsForLayer;
          });
      },
  },
);

export const visibleSelectedLayersSelectorFamily = selectorFamily<
  Layer[],
  { projectId: string }
>({
  key: "visibleSelectedLayersSelectorFamily",
  get:
    ({ projectId }) =>
    ({ get }) => {
      const selectedLayers = get(getSelectedLayers({ projectId }));

      const visibleLayers = selectedLayers.filter((layer) => {
        const layerId = layer.id;
        const settings = get(
          layerSettingSelectorFamily({ projectId, layerId }),
        );
        // Don't enable layers that has been deleted by the source
        return !Boolean(layer.dateDeleted) && Boolean(settings.visible);
      });

      return visibleLayers;
    },
});

export const visibleCustomLayersSelector = selectorFamily<
  CustomLayer[],
  {
    projectId: string;
  }
>({
  key: "visibleCustomLayersSelector",
  get:
    ({ projectId }) =>
    async ({ get }) => {
      const customLayers = get(
        customLayersMetadataSelectorAsync({ nodeId: projectId }),
      );

      const visibleCustomLayers = customLayers.filter((layer) => {
        const layerId = layer.id;
        const settings = get(
          layerSettingSelectorFamily({ projectId, layerId }),
        );
        return settings.visible ?? false;
      });
      return visibleCustomLayers;
    },
});
