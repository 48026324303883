/// <reference types="vite-plugin-svgr/client" />
import { useMemo } from "react";
import { randomPastelColor } from "utils/image";
import styled from "styled-components";
import { colors } from "styles/colors";
import TeamMeeting from "@icons/24/TeamMeeting.svg?react";

export const ContentImageWrapper = styled.div<{
  color: string;
  svgColor: string;
  size: number;
}>`
  width: ${(p) => p.size}rem;
  height: ${(p) => p.size}rem;
  border-radius: ${(p) => p.size / 2}rem;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(p) => p.color ?? colors.focusBackground};
  > svg {
    width: 1.2rem;
    height: 1.2rem;
  }
  path {
    stroke: ${(p) => p.svgColor};
  }
  circle {
    stroke: ${(p) => p.svgColor};
  }
  ellipse {
    fill: ${(p) => p.svgColor};
  }
  line {
    stroke: ${(p) => p.svgColor};
  }
  rect {
    stroke: ${(p) => p.svgColor};
    fill: ${(p) => p.svgColor};
  }
`;

const GroupImageRound = ({
  groupId,
  size = 3.2,
}: {
  groupId: string;
  size?: number;
}) => {
  const colors = useMemo(() => randomPastelColor(groupId), [groupId]);
  return (
    <ContentImageWrapper
      size={size}
      color={colors.backgroundColor}
      svgColor={colors.textColor}
    >
      <TeamMeeting />
    </ContentImageWrapper>
  );
};

export default GroupImageRound;
