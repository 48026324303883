import { Link } from "react-router-dom";
import styled from "styled-components";
import { StandardBox } from "../../../styles/boxes/Boxes";
import { colors } from "../../../styles/colors";
import { typography } from "../../../styles/typography";

export enum UsageContainerPlacement {
  TOP = "top",
  BOTTOM = "bottom",
}

export const UsageContainer = styled.div`
  padding: 0.4rem;
  border-radius: 0.4rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.2rem;

  width: fit-content;
  white-space: nowrap;

  position: relative;
`;

export const NumberWrapper = styled.div`
  cursor: pointer;
  background: ${colors.surfaceInfo};
  gap: 0.4rem;
  padding: 0.3rem 0.8rem;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 1.2rem;
    width: 1.2rem;
  }
`;

export const Number = styled.div`
  ${typography.label}
  color: ${colors.textBrand};
  margin: 0;
  cursor: pointer;
  position: relative;
`;

export const ExtraContainer = styled(StandardBox)<{
  placement?: UsageContainerPlacement;
}>`
  position: absolute;
  ${(p) =>
    p.placement === UsageContainerPlacement.TOP ? "bottom: 3rem" : "top: 3rem"};
  padding: 1.2rem 1.6rem;
  background: white;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  cursor: auto;
  max-height: 50rem;
  overflow: auto;
  z-index: 1;
`;

export const UsageText = styled.p`
  ${typography.label}
  color: ${colors.primaryText};
  margin: 0;
`;

export const Header = styled.p`
  ${typography.h4}
  margin: 0;
  white-space: nowrap;
`;
export const SubHeader = styled.p`
  ${typography.semiBold}
  margin: 0;
  white-space: nowrap;
`;

export const NoAccess = styled.p`
  ${typography.label}
  margin: 0;
  white-space: nowrap;
`;

export const Name = styled(Link)`
  ${typography.label}
  margin: 0;
  white-space: nowrap;
  cursor: pointer;
`;
