import { useCallback, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { modalTypeOpenAtom } from "../../../state/modal";
import { branchIdSelector, projectIdSelector } from "../../../state/pathParams";
import FullScreenModal from "../../FullScreenModal/FullScreenModal";
import { Input, TextArea } from "../../General/Input";
import * as timeline from "../../../state/timeline";
import Button from "../../General/Button";
import { BranchMeta, SnapshotMeta } from "../../../types/api";
import { Column, ModalFrame } from "../../General/Layout";
import { Label } from "../../General/Form";
import {
  ErrorBoundaryPrintOnly,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "../../ErrorBoundaries/ErrorBoundaryLocal";
import { dateToDateTime } from "../../../utils/utils";

function TimelineModals({ nodeId }: { nodeId: string }) {
  const branchId = useRecoilValue(branchIdSelector);
  const branch = useRecoilValue(
    timeline.projectBranchesAtomFamily({ nodeId }),
  ).find((b) => b.id === branchId);

  if (!branch) return null;

  return (
    <>
      <NewBranchSnapshotModalWrapper branch={branch} />
      <EditBranchSnapshotModalWrapper />
    </>
  );
}

export function NewBranchSnapshotModalWrapper({
  branch,
}: {
  branch: BranchMeta;
}) {
  const modalTypeOpen = useRecoilValue(modalTypeOpenAtom);

  if (!modalTypeOpen || modalTypeOpen?.modalType !== BranchSnapshotModalType)
    return null;

  return (
    <BranchSnapshotModal
      branch={branch}
      hooks={modalTypeOpen.metadata?.hooks}
      version={modalTypeOpen.metadata?.version}
    />
  );
}

export function EditBranchSnapshotModalWrapper() {
  const modalTypeOpen = useRecoilValue(modalTypeOpenAtom);

  if (
    !modalTypeOpen ||
    modalTypeOpen?.modalType !== EditBranchSnapshotModalType
  )
    return null;

  return <EditBranchSnapshotModal metadata={modalTypeOpen.metadata} />;
}

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  justify-content: flex-end;
  margin-top: 1rem;
`;

export type BranchSnapshotModalHooks = {
  beforeHook?: () => void;
  afterHook?: () => void;
};

export const BranchSnapshotModalType = "BranchSnapshotModalType";
function BranchSnapshotModal({
  branch,
  hooks,
  version,
}: {
  branch: BranchMeta;
  hooks?: BranchSnapshotModalHooks;
  version?: number;
}) {
  const projectId = useRecoilValue(projectIdSelector) ?? "";
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");

  const createSnapshot = timeline.useCreateSnapshot();

  const { beforeHook, afterHook } = hooks ?? {};
  const createSnapshotCallback = useCallback(async () => {
    beforeHook?.();
    const promise = createSnapshot(
      branch,
      {
        title,
        body,
      },
      projectId,
      version,
    );
    setBody("");
    setTitle("");
    setModalTypeOpen(undefined);

    await promise;
    afterHook?.();
  }, [
    beforeHook,
    createSnapshot,
    branch,
    title,
    body,
    projectId,
    version,
    setModalTypeOpen,
    afterHook,
  ]);

  return (
    <>
      <FullScreenModal>
        <ModalFrame title="Add event to timeline">
          <Column>
            {version && (
              <Label>
                <p>Date</p>
                <p>{dateToDateTime(new Date(version * 1000))}</p>
              </Label>
            )}
            <Label>
              <p>Title</p>
              <Input
                autoFocus
                value={title ?? ""}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Event title"
                style={{ resize: "none" }}
              />
            </Label>
            <Label>
              <p>Comment</p>
              <TextArea
                value={body ?? ""}
                onChange={(e) => setBody(e.target.value)}
                placeholder="Describe what changed"
                rows={5}
              />
            </Label>
            <ButtonContainer>
              <Button
                buttonType="text"
                text="Cancel"
                onClick={() => {
                  setBody("");
                  setTitle("");
                  setModalTypeOpen(undefined);
                }}
              />
              <Button text="Save" onClick={createSnapshotCallback} />
            </ButtonContainer>
          </Column>
        </ModalFrame>
      </FullScreenModal>
    </>
  );
}

export type EditBranchSnapshotMetadata = {
  snapshot: SnapshotMeta;
};

export const EditBranchSnapshotModalType = "EditBranchSnapshotModalType";
function EditBranchSnapshotModal({
  metadata,
}: {
  metadata: EditBranchSnapshotMetadata;
}) {
  const projectId = useRecoilValue(projectIdSelector) ?? "";
  const { update: updateSnapshot } = timeline.useUpdateSnapshot();
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const [title, setTitle] = useState(metadata.snapshot.title);
  const [body, setBody] = useState(metadata.snapshot?.body ?? "");
  const [loading, setLoading] = useState(false);

  const updateSnapshotCallback = useCallback(async () => {
    setLoading(true);
    await updateSnapshot(metadata.snapshot, { title, body }, projectId);
    setLoading(false);
    setModalTypeOpen(undefined);
  }, [
    updateSnapshot,
    metadata.snapshot,
    title,
    body,
    projectId,
    setModalTypeOpen,
  ]);

  return (
    <>
      <FullScreenModal>
        <ModalFrame title="Edit project snapshot">
          <Column>
            <Label>
              <p>Title</p>
              <Input
                autoFocus
                value={title ?? ""}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Snapshot title"
                style={{ resize: "none" }}
              />
            </Label>
            <Label>
              <p>Comment</p>
              <TextArea
                value={body ?? ""}
                onChange={(e) => setBody(e.target.value)}
                placeholder="Snapshot comment"
                rows={5}
              />
            </Label>
            <ButtonContainer>
              <Button
                buttonType="text"
                text="Cancel"
                onClick={() => {
                  setBody("");
                  setTitle("");
                  setModalTypeOpen(undefined);
                }}
              />
              <Button
                disabled={loading}
                text={loading ? "Saving..." : "Save"}
                onClick={updateSnapshotCallback}
              />
            </ButtonContainer>
          </Column>
        </ModalFrame>
      </FullScreenModal>
    </>
  );
}

export default ErrorBoundaryWrapper(
  TimelineModals,
  ErrorBoundaryPrintOnly,
  ScreamOnError,
);
