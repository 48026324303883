/// <reference types="vite-plugin-svgr/client" />
import { useRecoilValue } from "recoil";
import { showComponentsPreviewAtom } from "./state";
import TurbineComponentsPreview from "./TurbineComponentsPreview";
import CableComponentsPreview from "./CableComponentsPreview";
import ExportCableComponentsPreview from "./ExportCableComponentsPreview";
import MooringComponentsPreview from "./MooringComponentsPreview";
import SubstationComponentsPreview from "./SubstationComponentsPreview";

const ComponentsPreview = () => {
  const showComponentsPreview = useRecoilValue(showComponentsPreviewAtom);
  if (!showComponentsPreview.show) {
    return null;
  }

  switch (showComponentsPreview.componentType) {
    case "turbines":
      return <TurbineComponentsPreview />;
    case "cables":
      return <CableComponentsPreview />;
    case "export-cables":
      return <ExportCableComponentsPreview />;
    case "mooring":
      return <MooringComponentsPreview />;
    case "substation":
      return <SubstationComponentsPreview />;
    default:
      return null;
  }
};

export default ComponentsPreview;
