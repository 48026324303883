import type { Loadable } from "recoil";
import type { SortByFn } from "react-table";
import type { DataLibraryLayer } from "components/Organisation/Library/dataLibrary/types";
import type { ChangelogEntry } from "components/Changelog/type";

export const getSortTypes = (
  lastChanges: Loadable<Record<string, ChangelogEntry>>,
  getUsername: (userId: string) => string | undefined,
): Record<string, SortByFn<DataLibraryLayer>> => {
  return {
    lastChanged: (rowA, rowB) => {
      const lastChangesVal = lastChanges.valueMaybe();
      if (!lastChangesVal) {
        return 0;
      }

      const lastChangeA = lastChangesVal[rowA.original.id];
      const lastChangeB = lastChangesVal[rowB.original.id];

      if (!lastChangeA && !lastChangeB) {
        return 0;
      }
      if (!lastChangeA) {
        return 1;
      }
      if (!lastChangeB) {
        return -1;
      }

      return lastChangeA.version - lastChangeB.version;
    },
    author: (rowA, rowB) => {
      const user1Name = getUsername(rowA.original.author);
      const user2Name = getUsername(rowB.original.author);

      if (!user1Name && !user2Name) {
        return 0;
      }
      if (!user1Name) {
        return 1;
      }
      if (!user2Name) {
        return -1;
      }
      return user1Name.localeCompare(user2Name);
    },
  };
};
