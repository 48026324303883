import { MenuItem } from "components/General/Menu";
import { ElementTreeFolder, ElementTreeRoot, isFolder } from "./types";
import FolderOutlineIcon from "@icons/24/Folder-outline.svg?react";
import AddIcon from "@icons/24/Add.svg?react";

const Inner = ({
  node,
  onFolderClick,
}: {
  node: ElementTreeFolder | ElementTreeRoot;
  onFolderClick: (node: ElementTreeFolder) => void;
}) => {
  if (node.children.length === 0) return null;
  return node.children.filter(isFolder).map((c) => (
    <MenuItem
      key={c.id}
      name={c.folder.folderName}
      icon={<FolderOutlineIcon />}
      onClick={() => onFolderClick(c)}
    >
      {c.children.find(isFolder) && (
        <Inner node={c} onFolderClick={onFolderClick} />
      )}
    </MenuItem>
  ));
};

export const FolderTreeMenuItem = ({
  node,
  onFolderClick,
  onNewClick,
}: {
  node: ElementTreeFolder | ElementTreeRoot;
  onFolderClick: (node: ElementTreeFolder) => void;
  onNewClick: () => void;
}) => {
  return (
    <>
      <Inner node={node} onFolderClick={onFolderClick} />
      <MenuItem
        name="Create new folder"
        icon={<AddIcon />}
        onClick={onNewClick}
      />
    </>
  );
};
