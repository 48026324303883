// Material constants
export const E_PILE = 2.1e11;
export const E_TOWER = 2.1e11;
export const PILE_YIELD_STRESS = 355e6;
export const PILE_DENSITY = 7850; // kg/m3
export const CONCRETE_DENSITY = 2700; // kg/m3

// Constants from standards
export const TURB_INT_REF = 0.1; // DNV OC class, DNV-ST-0437
export const T_A = 11; // DNV OC class, DNV-ST-0437
export const T_B = 0.556; // DNV OC class, DNV-ST-0437
export const A_C = 0.011; // DNV OC class, DNV-ST-0437
export const TURB_SCALE_PARAM = 42; // DNV-ST-0437
export const MATERIAL_FACTOR = 1.1; // DNV-ST-0126
export const LOAD_FACTOR = 1.35; // DNV-ST-0437
export const MAX_PILE_DEFL = 0.2; // from IEC 61400-3
export const MAX_PILE_ANGLE = 0.5; // deg, from IEC 61400-3
export const C_D = 0.85; // DNV-RP-C205
export const C_M = 2.0; // DNV-RP-C205

// Constants from literature/experience
export const AERO_DAMPING = 0.04;
export const AVG_WIND_SPEED = 10;
export const WINDSPEED_1YR = 30;
export const MAX_PILE_DIAMETER = 25;

//Constants for weight equation
export const scalingEquationTokens = [
  {
    type: 3,
    token: "p",
    show: "p",
    value: "p",
    precedence: 0,
  },
  {
    type: 3,
    token: "w",
    show: "w",
    value: "w",
    precedence: 0,
  },
  {
    type: 3,
    token: "d",
    show: "d",
    value: "d",
    precedence: 0,
  },
];
