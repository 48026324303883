import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { getBuoysAndClumpWeightInBranchSelector } from "../../../state/layout";
import { spaceLarge, spaceMedium } from "../../../styles/space";
import { FlexGrid2 } from "../../General/Form";
import { useDashboardContext } from "../Dashboard";
import { SafeCard } from "./Base";
import { Row } from "../../General/Layout";
import React from "react";
import { StatHeader } from "./Base.style";

export const TableHeader = styled.h4`
  margin: ${spaceMedium} ${spaceLarge} 0 ${spaceLarge};
`;

const MooringLineAttachmentsDetails = () => {
  const { park, branch } = useDashboardContext();
  const branchId = branch.id;
  const parkId = park.id;

  const buoysAndClumpWeights = useRecoilValue(
    getBuoysAndClumpWeightInBranchSelector({ parkId, branchId }),
  );

  function countOccurrences(arr: number[]) {
    const count: Record<number, number> = {};
    arr.forEach((item) => {
      if (!count[item]) {
        count[item] = 0;
      }
      ++count[item];
    });
    return count;
  }

  const uniqueBuoysWeights = countOccurrences(buoysAndClumpWeights.buoys);
  const uniqueClumpWightWeights = countOccurrences(
    buoysAndClumpWeights.clumpWeights,
  );

  return (
    <>
      <FlexGrid2 style={{ padding: spaceMedium }}>
        <StatHeader
          title={"Number of buoys"}
          value={buoysAndClumpWeights.buoys.length}
        />
        {Object.keys(uniqueBuoysWeights).map((buoyWeight) => (
          <React.Fragment key={"buoys" + buoyWeight}>
            <Row>
              <p>{buoyWeight} kg</p>
              <p>{uniqueBuoysWeights[Number(buoyWeight)]}</p>
            </Row>
          </React.Fragment>
        ))}
        <StatHeader
          title={"Number of clump weights"}
          value={buoysAndClumpWeights.clumpWeights.length}
        />
        {Object.keys(uniqueClumpWightWeights).map((clumpWeightWeight) => (
          <React.Fragment key={"clumpweight" + clumpWeightWeight}>
            <Row>
              <p>{clumpWeightWeight} kg</p>
              <p>{uniqueClumpWightWeights[Number(clumpWeightWeight)]}</p>
            </Row>
          </React.Fragment>
        ))}
      </FlexGrid2>
    </>
  );
};

export const MooringLineAttachmentsWidget = () => (
  <SafeCard title="Mooring line attachments" id="Mooring line attachments">
    <MooringLineAttachmentsDetails />
  </SafeCard>
);
