import { useRecoilCallback, useRecoilState } from "recoil";
import { useTypedPath } from "../../../state/pathParams";
import { LayerCollection } from "../LayerSettings/types";
import {
  postLayerCollection,
  putLayerCollection,
  deleteLayerCollection,
  BackendLayerCollection,
  copyCollectionToProject,
} from "./service";
import {
  allBackendLayerCollectionsAtomFamily,
  isCreatingNewCollectionAtom,
} from "./state";
import { createDefaultName } from "./utils";
import { Mixpanel } from "../../../mixpanel";
import { initializeAndSet } from "../../Comments/hooks/useReplyReactionCrud";
import { scream } from "utils/sentry";

export const useCollectionCrud = () => {
  const { projectId } = useTypedPath("projectId");
  const [isCreatingNewCollection, setIsCreatingNewCollection] = useRecoilState(
    isCreatingNewCollectionAtom,
  );

  const post = useRecoilCallback(
    ({ set, snapshot }) =>
      async (data: Partial<LayerCollection>) => {
        if (!projectId) return;
        try {
          setIsCreatingNewCollection(true);
          const existingCollections = await snapshot.getPromise(
            allBackendLayerCollectionsAtomFamily({ projectId }),
          );
          const blc: Partial<BackendLayerCollection> = {
            alias: data.name ?? createDefaultName(existingCollections),
            layerIds: data.layers?.map((l) => l.id) ?? [],
            standard: data.isDefault ?? false,
          };
          const res = await postLayerCollection(projectId, blc);
          Mixpanel.track("Create collection", { nodeId: projectId });

          set(allBackendLayerCollectionsAtomFamily({ projectId }), (cur) => {
            const clone = [...cur];
            const index = clone.findIndex((c) => c.id === res.id);
            if (index !== -1) {
              clone[index] = res;
            } else {
              clone.push(res);
            }
            return clone;
          });
          return res;
        } catch (error) {
          if (error instanceof Error) {
            scream(error, {
              message: "Failed to create new collection",
              error,
            });
          } else {
            scream("Failed to create new collection", {
              error,
            });
          }
        } finally {
          setIsCreatingNewCollection(false);
        }
      },
    [projectId, setIsCreatingNewCollection],
  );

  const put = useRecoilCallback(
    ({ set }) =>
      async (data: LayerCollection) => {
        if (!projectId) return;
        const blc: BackendLayerCollection = {
          id: data.id,
          alias: data.name,
          layerIds: data.layers.map((l) => l.id),
          standard: data.isDefault,
        };
        set(allBackendLayerCollectionsAtomFamily({ projectId }), (cur) => {
          const clone = [...cur];
          const index = clone.findIndex((c) => c.id === blc.id);
          if (index !== -1) {
            clone[index] = blc;
          }
          return clone;
        });
        const res = await putLayerCollection(projectId, data);
        Mixpanel.track("Update collection", {
          projectId,
          isDefault: data.isDefault,
          collectionId: data.id,
        });
        return res;
      },
    [projectId],
  );

  const remove = useRecoilCallback(
    ({ set }) =>
      async (collection: LayerCollection) => {
        if (!projectId) return;
        set(allBackendLayerCollectionsAtomFamily({ projectId }), (cur) => {
          const rest = cur.filter((c) => c.id !== collection.id);
          return rest;
        });
        return deleteLayerCollection(projectId, collection);
      },
    [projectId],
  );

  const copyCollection = useRecoilCallback(
    () =>
      async (
        collectionId: string,
        toProjectId: string,
        customLayerIds: string[],
        excludeLayerIds: string[],
      ) => {
        if (!toProjectId) return;
        return copyCollectionToProject(
          collectionId,
          projectId,
          toProjectId,
          customLayerIds,
          excludeLayerIds,
        );
      },
    [projectId],
  );

  const putLocal = useRecoilCallback(
    ({ set, snapshot }) =>
      async (projectId: string, data: BackendLayerCollection) => {
        initializeAndSet(
          snapshot,
          set,
          allBackendLayerCollectionsAtomFamily({ projectId }),
          (cur) => {
            const clone = [...cur];
            const index = clone.findIndex((c) => c.id === data.id);
            if (index !== -1) {
              clone[index] = data;
              return clone;
            }
            return clone.concat(data);
          },
        );
      },
    [],
  );

  const removeLocal = useRecoilCallback(
    ({ set, snapshot }) =>
      async (projectId: string, collectionId: string) => {
        initializeAndSet(
          snapshot,
          set,
          allBackendLayerCollectionsAtomFamily({ projectId }),
          (cur) => {
            const rest = cur.filter((c) => c.id !== collectionId);
            return rest;
          },
        );
      },
    [],
  );

  return {
    post,
    put,
    remove,
    copyCollection,
    putLocal,
    removeLocal,
    isCreatingNewCollection,
  };
};
