import React from "react";
import Spinner from "@icons/spinner/Spinner";
import UserImageRound from "./UserImageRound";
import styled from "styled-components";
import { colors } from "styles/colors";
import { typography } from "styles/typography";
import { OtherUserMetaInfo } from "types/user";

const StyledRow = styled.div<{
  size: number;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
}>`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  > * {
    margin-left: -${(p) => p.size / 3}rem;
    z-index: 1;
    &:last-child {
      margin-left: 0;
    }
  }

  cursor: ${(p) => (p.onClick ? "pointer" : "inherit")};
`;

const StyledProfileImage = styled.div<{ size: number }>`
  width: ${(p) => p.size}rem;
  height: ${(p) => p.size}rem;
  border-radius: ${(p) => p.size / 2}rem;
  background-color: ${colors.hover};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -${(p) => p.size / 8}rem;
`;

const NoUsersText = styled.p`
  ${typography.contentAndButtons}
  margin: 0;
  color: ${colors.secondaryText};
`;

const DEFAULT_MAX = 4;

const StackedUserImages = ({
  users,
  maxNumberOfUsers = DEFAULT_MAX,
  size = 2.4,
  onClick,
  noUsersText = "No members yet",
}: {
  users: OtherUserMetaInfo[];
  maxNumberOfUsers?: number;
  size?: number;
  onClick?: () => void;
  noUsersText?: string;
}) => {
  const usersToShow = users.slice(0, maxNumberOfUsers);
  const additionalUsersCount = users.length - maxNumberOfUsers;

  if (users.length === 0) return <NoUsersText>{noUsersText}</NoUsersText>;

  return (
    <React.Suspense fallback={<Spinner />}>
      <StyledRow
        size={size}
        onClick={
          onClick
            ? (e) => {
                e.stopPropagation();
                e.preventDefault();
                onClick();
              }
            : undefined
        }
      >
        {additionalUsersCount > 0 && (
          <StyledProfileImage size={size}>
            +{additionalUsersCount}
          </StyledProfileImage>
        )}
        {usersToShow.map((user) => {
          return <UserImageRound key={user.user_id} user={user} size={size} />;
        })}
      </StyledRow>
    </React.Suspense>
  );
};

export default StackedUserImages;
