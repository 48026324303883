import { useCallback, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { viewCameraAspectAtom } from "../../../state/viewToPark";
import { ThreeCore } from "./useCreateThreeCore";
import { With } from "../../../utils/utils";

export default function useResizeThree(
  threeCore: With<ThreeCore, "camera" | "renderer"> | undefined,
  threeSceneRef: React.RefObject<HTMLDivElement>,
) {
  const setCameraAspect = useSetRecoilState(viewCameraAspectAtom);
  useEffect(() => {
    const container = threeSceneRef.current;
    if (!container || !threeCore) return;

    threeCore.renderer.setSize(container.clientWidth, container.clientHeight);
    container.appendChild(threeCore.renderer.domElement);
  }, [threeCore, threeSceneRef]);

  const onWindowResize = useCallback(() => {
    const container = threeSceneRef.current;
    if (!threeCore || !container) return;
    const camera = threeCore.camera;
    camera.aspect = container.clientWidth / container.clientHeight;
    camera.updateProjectionMatrix();
    threeCore.renderer.setSize(container.clientWidth, container.clientHeight);
    setCameraAspect(camera.aspect);
  }, [setCameraAspect, threeCore, threeSceneRef]);

  useEffect(() => {
    const container = threeSceneRef.current;
    if (!container) return;

    const resizeObserver = new ResizeObserver(() => {
      onWindowResize();
    });
    resizeObserver.observe(container);
    window.addEventListener("resize", onWindowResize);

    return () => window.removeEventListener("resize", onWindowResize);
  }, [onWindowResize, threeSceneRef]);
}
