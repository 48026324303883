import styled, { CSSProperties } from "styled-components";
import { colors } from "../../../styles/colors";

const LegendWrapper = styled.div`
  position: absolute;
  overflow: hidden;
  left: 0.75rem;
  bottom: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 0.75rem;
  background-color: ${colors.focusBackground};
  border-radius: 0.25rem;
`;
const LegendItems = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0.75rem;
`;
const LegendItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.65rem;
`;

const Label = styled.label<{ fontSize: number }>`
  font-style: normal;
  font-weight: normal;
  font-size: ${(p) => p.fontSize}rem;
  color: ${colors.brand};
  margin: 0;
  padding: 0;
`;

const Circle = styled.span<{ fontSize: number }>`
  width: ${(p) => p.fontSize}rem;
  height: ${(p) => p.fontSize}rem;
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;
`;

export interface LegendItem {
  name: string;
  style:
    | {
        backgroundColor: string;
      }
    | {
        background: string;
      };
}

const MapLegend = ({
  items,
  style = {},
  fontSize = 1,
}: {
  items: LegendItem[];
  style?: CSSProperties;
  fontSize?: number;
}) => {
  return (
    <LegendWrapper style={style}>
      <LegendItems>
        {items.map(({ name, style }) => (
          <LegendItemWrapper key={name}>
            <Circle fontSize={fontSize} style={style} />
            <Label fontSize={fontSize}>{name}</Label>
          </LegendItemWrapper>
        ))}
      </LegendItems>
    </LegendWrapper>
  );
};

export default MapLegend;
