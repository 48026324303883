import {
  OrgFeatureAccess,
  _OrgFeatureAccess,
} from "../components/OrganisationFeatureAccess/types";
import { atomFamily, selectorFamily } from "recoil";
import { fetchFeatureAccess } from "../services/featureAccessService";
export const featureAccessAtomFamily = atomFamily<
  OrgFeatureAccess[] | undefined,
  {
    organisationId: string;
  }
>({
  key: "featureAccessAtomFamily",
  default: selectorFamily({
    key: "featureAccessAtomFamily.default",
    get:
      ({ organisationId }) =>
      () =>
        fetchFeatureAccess(organisationId),
  }),
});

export const libraryAllSelectorFamily = selectorFamily<
  boolean,
  { organisationId: string | undefined }
>({
  key: "libraryAllSelectorFamily",
  get:
    ({ organisationId }) =>
    ({ get }) => {
      if (!organisationId) return false;

      const featureAccessInOrg = get(
        featureAccessAtomFamily({ organisationId }),
      );

      if (!featureAccessInOrg) return false;

      return featureAccessInOrg.some((fa) => fa === "library_all");
    },
});

export const integrationAccessSelectorFamily = selectorFamily<
  boolean,
  { organisationId: string | undefined }
>({
  key: "integrationAccessSelectorFamily",
  get:
    ({ organisationId }) =>
    ({ get }) => {
      if (!organisationId) return false;

      const featureAccessInOrg = get(
        featureAccessAtomFamily({ organisationId }),
      );

      if (!featureAccessInOrg) return false;

      return featureAccessInOrg.some((fa) => fa === "integration");
    },
});
