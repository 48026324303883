import { useMemo } from "react";
import useAnalysisConfiguration from "./useAnalysisConfiguration";
import useCostConfiguration from "./useCostConfiguration";
import useWindConfiguration from "./useWindConfiguration";

export default function useProjectConfiguration() {
  const analysisConfiguration = useAnalysisConfiguration();
  const windConfigurations = useWindConfiguration();

  const costConfiguration = useCostConfiguration();

  const projectConfiguration = useMemo(() => {
    return {
      title: "Configuration",
      items: [
        {
          items: [analysisConfiguration, windConfigurations, costConfiguration],
        },
      ],
    };
  }, [analysisConfiguration, windConfigurations, costConfiguration]);

  return projectConfiguration;
}
