/// <reference types="vite-plugin-svgr/client" />
import { useCallback, useEffect, useMemo } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import WindIcon from "@icons/14/Wind.svg?react";
import DeleteIcon from "@icons/24/Bin.svg?react";
import {
  createWindSourceConfiguration,
  deleteWindSourceConfiguration,
  fetchWindConfigurationUsage,
  updateWindSourceConfiguration,
  WindSourceConfiguration,
} from "../../../../services/windSourceConfigurationService";
import { useTypedPath } from "../../../../state/pathParams";
import { toastMessagesAtom } from "../../../../state/toast";
import {
  savingWindConfigurationInProgressAtom,
  windConfigurationTempName,
  windSourceConfigurationsAtomFamily,
} from "../../../../state/windSourceConfiguration";
import { WindDataSource } from "../../../../state/windStatistics";
import WindSettings from "../../../ConfigurationModal/WindSettings";
import { selectedMenuItemState } from "../../Shared/state";
import { modalTypeOpenAtom } from "../../../../state/modal";
import { SettingsSubMenuProp } from "components/SettingsV2/Shared/types";
import { useToast } from "hooks/useToast";
import Earth from "@icons/14/Earth.svg?react";
import useWindConfigurationCrud from "hooks/useWindConfigurationCrud";
import { Mixpanel } from "mixpanel";

export const WIND_MENU_ID = "wind";

export default function useWindConfiguration() {
  const { projectId } = useTypedPath("projectId");
  const modalTypeOpen = useRecoilValue(modalTypeOpenAtom);
  const { error: showErrorToast } = useToast();
  const setToastMessages = useSetRecoilState(toastMessagesAtom);
  const [isSaving, setIsSaving] = useRecoilState(
    savingWindConfigurationInProgressAtom,
  );
  const [configurations, setConfigurations] = useRecoilState(
    windSourceConfigurationsAtomFamily({ projectId }),
  );
  const [menuSelection, setMenuSelection] = useRecoilState(
    selectedMenuItemState({ menuId: WIND_MENU_ID, projectId }),
  );

  const tempConfigNames = useRecoilValue(
    windConfigurationTempName({ nodeId: projectId }),
  );

  useEffect(() => {
    if (!menuSelection) {
      const firstItem = configurations[0];
      if (firstItem) {
        setMenuSelection(firstItem.id);
      }
    }
  }, [configurations, menuSelection, setMenuSelection]);

  useEffect(() => {
    if (
      (modalTypeOpen as any)?.["metadata"]?.["selectedMenuId"] === "wind" &&
      (modalTypeOpen as any)?.["metadata"]?.["selectedConfigId"]
    ) {
      setMenuSelection((modalTypeOpen as any)["metadata"]["selectedConfigId"]);
    }
  }, [modalTypeOpen, setMenuSelection]);

  const _delete = useCallback(
    async (configurationId: string) => {
      if (!projectId) return;
      setIsSaving(true);
      const usage = await fetchWindConfigurationUsage(
        projectId,
        configurationId,
      );
      if (usage.length > 0) {
        showErrorToast(
          `Configuration is used in ${usage.length} branch(es) and can't be deleted`,
          {
            timeout: 6000,
          },
        );
      } else {
        setConfigurations((curr) =>
          curr.filter((c) => c.id !== configurationId),
        );
        await deleteWindSourceConfiguration({
          nodeId: projectId,
          configurationId,
        });
      }
      setIsSaving(false);
    },
    [projectId, setConfigurations, setIsSaving, showErrorToast],
  );

  const _create = useCallback(async () => {
    if (!projectId) return;
    setIsSaving(true);
    const newConfig = await createWindSourceConfiguration({
      nodeId: projectId,
      name: "Untitled",
      note: undefined,
      source: {
        id: WindDataSource.BEST,
        type: "built_in",
      },
      calibration: undefined,
      spatialCalibration: undefined,
    });
    setConfigurations((cur) => [...cur, newConfig]);
    setMenuSelection(newConfig.id);
    setIsSaving(false);
  }, [projectId, setConfigurations, setIsSaving, setMenuSelection]);

  const _duplicate = useCallback(
    async (id: string) => {
      if (!projectId) return;
      const configToDuplicate = configurations.find(
        (config) => config.id === id,
      );
      if (!configToDuplicate) {
        return;
      }
      setIsSaving(true);

      const configClone = JSON.parse(
        JSON.stringify(configToDuplicate),
      ) as WindSourceConfiguration;

      try {
        const newConfig = await createWindSourceConfiguration({
          nodeId: projectId,
          name: "Untitled",
          note: undefined,
          source: {
            id: WindDataSource.BEST,
            type: "built_in",
          },
          calibration: undefined,
          spatialCalibration: undefined,
        });

        const updatedConfig = {
          ...newConfig,
          ...configClone,
          id: newConfig.id,
          name: `${configClone.name} copy`,
        };
        await updateWindSourceConfiguration({
          nodeId: projectId,
          configuration: updatedConfig,
        });
        setConfigurations((cur) => [...cur, updatedConfig]);
        setMenuSelection(updatedConfig.id);
      } catch {
      } finally {
        setIsSaving(false);
      }
    },
    [
      configurations,
      projectId,
      setConfigurations,
      setIsSaving,
      setMenuSelection,
    ],
  );

  const _update = useCallback(
    async (windConfiguration: WindSourceConfiguration) => {
      if (!projectId) return;
      setIsSaving(true);
      const usage = await fetchWindConfigurationUsage(
        projectId,
        windConfiguration.id,
      );

      const currentConfig = configurations.find(
        (c) => c.id === windConfiguration.id,
      );

      const { name: _, ...configurationWithoutName } = windConfiguration;
      const { name: __, ...currentConfigWithoutName } = currentConfig || {};

      const changedSomethingElseThanName =
        JSON.stringify(configurationWithoutName) !==
        JSON.stringify(currentConfigWithoutName);
      if (
        !changedSomethingElseThanName ||
        usage.length === 0 ||
        window.confirm(
          `This configuration is used in ${usage.length} branch${
            usage.length > 1 ? "es" : ""
          }, are you sure you want to update it?`,
        )
      ) {
        const newConfig = await updateWindSourceConfiguration({
          nodeId: projectId,
          configuration: windConfiguration,
        });

        setConfigurations((curr) => {
          const index = curr.findIndex((c) => c.id === newConfig.id);
          return [...curr.slice(0, index), newConfig, ...curr.slice(index + 1)];
        });
        Mixpanel.track("Config saved", { type: "wind" });
        setToastMessages((tm) => [
          ...tm,
          {
            text: "Saved",
            timeout: 3000,
            type: "success",
          },
        ]);
      }
      setIsSaving(false);
    },
    [
      projectId,
      setConfigurations,
      setIsSaving,
      setToastMessages,
      configurations,
    ],
  );

  const { saveName, saveTempNameToLocal } = useWindConfigurationCrud();

  const onUnmount = useCallback(() => {
    configurations.forEach((c) => {
      if (saveTempNameToLocal) saveTempNameToLocal(c.id);
    });
  }, [configurations, saveTempNameToLocal]);

  const configSubMenus: SettingsSubMenuProp[] = useMemo(() => {
    const configurationList = configurations.map((config) => ({
      id: config.id,
      label: tempConfigNames[config.id] ?? config.name ?? "",
      loading: isSaving,
      content: (
        <div style={{ height: "100%", position: "relative" }}>
          <WindSettings
            windConfiguration={config}
            windConfigurationName={
              tempConfigNames[config.id] ?? config.name ?? ""
            }
            onSave={_update}
            onSaveName={saveName}
            onUnmount={onUnmount}
          />
        </div>
      ),
      onDuplicate: _duplicate,
      onChangeName: async (newName: string) => {
        return _update({ ...config, name: newName });
      },
      dotMenuOptions:
        configurations.length > 1
          ? [
              {
                title: "Delete",
                onSelect: _delete,
                icon: <DeleteIcon />,
              },
            ]
          : undefined,
    }));
    return [
      {
        title: "Project wind",
        items: configurationList,
        create: {
          type: "action",
          title: "New configuration",
          onCreate: _create,
          disabled: isSaving,
        },
        icon: <Earth />,
      },
    ];
  }, [
    _delete,
    _duplicate,
    _update,
    _create,
    configurations,
    isSaving,
    onUnmount,
    tempConfigNames,
    saveName,
  ]);

  const windConfigurations = useMemo(() => {
    return {
      id: WIND_MENU_ID,
      label: "Wind",
      icon: <WindIcon />,
      submenus: configSubMenus,
    };
  }, [configSubMenus]);

  return windConfigurations;
}
