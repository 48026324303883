import React from "react";
import styled, { CSSProperties } from "styled-components";
import { spacing1 } from "styles/space";
import { IconREMSize, typography } from "styles/typography";
import {
  ValidationAlertType,
  validationBackground,
  validationIcon,
} from "./ValidationAlert";

const Container = styled.div<{ type: ValidationAlertType }>`
  display: flex;
  padding: 0.8rem;
  align-items: flex-start;
  gap: 0.8rem;
  border-radius: var(--Radius, 0.4rem);
  background: ${({ type }) => validationBackground[type]};
`;
const Header = styled.h4`
  ${typography.sub2};
  margin: 0;
  font-weight: 700;
`;

const Text = styled.p`
  ${typography.caption};
  margin: 0;
`;

const IconContainer = styled.div<{ type: ValidationAlertType }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: ${({ type }) => validationBackground[type]};
`;

const SimpleAlert = ({
  text,
  title,
  type = "error",
  children,
  style,
}: {
  text?: string;
  title?: string;
  type?: ValidationAlertType;
  children?: React.ReactNode;
  style?: CSSProperties;
}) => {
  return (
    <Container type={type} style={style}>
      <div style={{ flex: "0 0 auto" }}>
        <IconContainer type={type}>
          <IconREMSize height={1.4} width={1.4}>
            {validationIcon[type]}
          </IconREMSize>
        </IconContainer>
      </div>
      <div
        style={{
          flex: "1 1 auto",
          marginTop: "0.4rem",
          display: "flex",
          flexDirection: "column",
          gap: spacing1,
        }}
      >
        {title && <Header>{title}</Header>}
        {text && <Text>{text}</Text>}
        {children}
      </div>
    </Container>
  );
};

export default SimpleAlert;
