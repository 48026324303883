/// <reference types="vite-plugin-svgr/client" />
import { useMemo, useState, useCallback, Suspense, useEffect } from "react";
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import styled from "styled-components";
import { BranchMeta } from "../../../types/api";
import { useTypedPath } from "../../../state/pathParams";
import AddIcon from "@icons/24/Add.svg?react";
import BinIcon from "@icons/24/Bin.svg?react";
import Reset from "@icons/14/Reset.svg?react";
import JaggedLine from "@icons/24/JaggedLine.svg?react";
import Pencil from "@icons/24/Pencil.svg?react";
import { colors } from "../../../styles/colors";
import { dateToDateTime } from "../../../utils/utils";
import { Row } from "./styles";
import {
  getBranchSelectorFamily,
  listAllSnaphotsInBranchesSelectorFamily,
  viewAllUnderMonthAtomFamily,
  showRefreshTimelineButtonBranchAtomFamily,
  showRefreshTimelineButtonProjectAtomFamily,
  showRefreshTimelineButtonSelectorFamily,
  useDeleteSnapshot,
  useDuplicateBranch,
  useUpdateSnapshot,
  useRestoreVersionToBranch,
} from "../../../state/timeline";
import UserImageRoundFromId from "../../UserImage/UserImageRoundFromId";
import NewSnapshotButton, {
  NewSnapshotButtonPlaceholder,
} from "./NewSnapshotButton";
import { EditableTextInternalState } from "../../General/EditableText";
import { editorAccessProjectSelector } from "../../../state/user";
import { SkeletonText } from "../../Loading/Skeleton";
import { useNavigate, useSearchParams } from "react-router-dom";
import useResetMapControls from "../../../hooks/useResetMapControls";
import {
  ErrorBoundaryWarningTriangle,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "../../ErrorBoundaries/ErrorBoundaryLocal";
import {
  currentVersionSelector,
  projectVersionAtom,
} from "../../../state/project";
import { useSetVersion, versionQueryName } from "../../../hooks/useSetVersion";
import {
  DateDividerEvent,
  GeneralEvent,
  GeneralEventGroup,
  GroupedEvents,
  SnapshotEvent,
} from "../../../types/timeline";
import Button from "../../General/Button";
import { IconREMSize, typography } from "../../../styles/typography";
import { LayerCollectionVersionConfigTypes } from "../../LayerList/LayerSettings/service";
import { DotMenu } from "../../General/MenuButton";
import { MenuItem } from "../../General/Menu";
import {
  BranchSnapshotModalType,
  EditBranchSnapshotModalType,
} from "../DesignNavigation/TimelineModals";
import { modalTypeOpenAtom } from "../../../state/modal";
import Spinner from "@icons/spinner/Spinner";
import { toastMessagesAtom } from "../../../state/toast";
import { useGetUserFromIdProjectPage } from "hooks/useUser";

const SnapshotSkeleton = () => <MakeSekeleton height={62.39} />;

const MakeSekeleton = ({
  height,
  style = {},
}: {
  height: number;
  style?: React.CSSProperties;
}) => {
  return (
    <SkeletonText
      style={{
        height: `${height / 3}px`,
        margin: `${height / 3}px auto`,
        ...style,
      }}
    />
  );
};

const DatePositionWrapper = styled.div`
  position: absolute;
  left: -4.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DateWrapper = styled.div`
  ${typography.regular};
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 0.3rem 0.6rem;
  border: 1px solid ${colors.inputOutline};
  border-radius: 4px;
`;

const SnapshotListWrapper = styled.div`
  width: 100%;
  overflow: auto;
  padding: 0;
  position: relative;
`;

const SnapshotContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
`;

const DescriptionWrapper = styled.div<{ open: boolean }>`
  text-overflow: ellipsis;
  overflow: hidden;
  height: ${(p) => (p.open ? "fit-content" : "1.8rem")};
`;

const SnapshotWrapper = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 0rem 1.4rem 0rem 5.6rem;
  width: 100%;
  box-sizing: border-box;
  background-color: ${(p) => (p.selected ? colors.selected : "inherit")};

  &:hover {
    background-color: ${(p) =>
      p.selected !== undefined
        ? p.selected
          ? colors.selected
          : colors.hover
        : "inherit"};
  }
`;

const RefreshButtonWrapper = styled.div`
  margin: 0 0 1rem 0;
`;
const SnapshotTitleWrapper = styled.h5`
  margin: 0;
  &::first-letter {
    text-transform: capitalize;
  }
`;

const Snapshots = ErrorBoundaryWrapper(
  () => {
    const { organisationId, projectId, branchId } = useTypedPath(
      "organisationId",
      "projectId",
      "branchId",
    );

    const snapShots = useRecoilValue(
      listAllSnaphotsInBranchesSelectorFamily({
        organisationId,
        projectId,
        branchId,
      }),
    );

    return (
      <>
        {snapShots.map((s, i, all) => {
          return (
            <GroupedSnapshotElement
              key={i}
              s={s}
              lastElement={i === all.length - 1}
            />
          );
        })}
      </>
    );
  },
  ErrorBoundaryWarningTriangle,
  ScreamOnError,
);

const RefreshTimeline = ({
  organisationId,
  projectId,
  branchId,
}: {
  organisationId: string;
  projectId: string;
  branchId: string;
}) => {
  const resetListAllSnaphotsInBranches = useRecoilRefresher_UNSTABLE(
    listAllSnaphotsInBranchesSelectorFamily({
      organisationId,
      projectId,
      branchId,
    }),
  );
  const resetShowRefreshTimelineButtonProject = useResetRecoilState(
    showRefreshTimelineButtonProjectAtomFamily({ projectId }),
  );
  const resetShowRefreshTimelineButtonBranch = useResetRecoilState(
    showRefreshTimelineButtonBranchAtomFamily({
      projectId,
      branchId,
    }),
  );
  const showRefreshTimelineButton = useRecoilValue(
    showRefreshTimelineButtonSelectorFamily({ projectId, branchId }),
  );

  const onClick = useCallback(() => {
    resetListAllSnaphotsInBranches();
    resetShowRefreshTimelineButtonProject();
    resetShowRefreshTimelineButtonBranch();
  }, [
    resetShowRefreshTimelineButtonProject,
    resetShowRefreshTimelineButtonBranch,
    resetListAllSnaphotsInBranches,
  ]);

  if (!showRefreshTimelineButton) return null;
  return (
    <RefreshButtonWrapper>
      <Button
        onClick={onClick}
        size={"small"}
        buttonType="text"
        text={"Refresh timeline"}
        icon={<Reset />}
      />
    </RefreshButtonWrapper>
  );
};

function VersionHistoryInner() {
  const [loading, setLoading] = useState(false);
  const { organisationId, projectId, branchId } = useTypedPath(
    "organisationId",
    "projectId",
    "branchId",
  );
  const branch = useRecoilValue(
    getBranchSelectorFamily({
      projectId,
      branchId,
    }),
  );

  if (!branch) return null;

  return (
    <>
      <Suspense fallback={null}>
        <RefreshTimeline
          projectId={projectId}
          organisationId={organisationId}
          branchId={branchId}
        />
      </Suspense>
      <Suspense fallback={<SnapshotSkeleton />}>
        <SnapshotListWrapper>
          <NewSnapshot branch={branch} />
          <SnapshotContainer>
            {loading && <SnapshotSkeleton />}
            <Snapshots />
          </SnapshotContainer>
        </SnapshotListWrapper>
      </Suspense>
      <Suspense fallback={<NewSnapshotButtonPlaceholder />}>
        <NewSnapshotButton
          hooks={{
            beforeHook: () => setLoading(true),
            afterHook: () => setLoading(false),
          }}
        />
      </Suspense>
    </>
  );
}

const SNAPSHOT_SPACE = 1.6;

const NewSnapshotBox = styled.div`
  position: relative;

  width: 100%;
  box-sizing: border-box;

  padding: ${SNAPSHOT_SPACE / 2}rem 0;

  display: flex;
  flex-direction: column;
`;

function NewSnapshot({ branch }: { branch: BranchMeta }) {
  const { projectId } = useTypedPath("projectId");
  const resetMapControls = useResetMapControls();
  const setVersion = useSetVersion();

  const navigateTo = useCallback(() => {
    resetMapControls();
    setVersion(0);
  }, [resetMapControls, setVersion]);

  const isSelected =
    useRecoilValue(
      projectVersionAtom({
        projectId: projectId,
        branchId: branch.id,
      }),
    ) === undefined;

  return (
    <SnapshotWrapper selected={isSelected}>
      <NewSnapshotBox>
        <SnapshotBall
          onClick={(e) => {
            e.stopPropagation();
            navigateTo();
          }}
          size={"medium"}
          selected={isSelected}
        />
        <DivisionLineBottom />

        <SnapshotHeaderWrapper
          onClick={(e) => {
            e.stopPropagation();
            navigateTo();
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.2rem",
              marginRight: "2rem",
            }}
          >
            <h5>Live</h5>
          </div>
        </SnapshotHeaderWrapper>
      </NewSnapshotBox>
    </SnapshotWrapper>
  );
}

const SnapshotBox = styled.div`
  position: relative;

  width: 100%;
  box-sizing: border-box;
  min-height: 2.2rem;

  padding: ${SNAPSHOT_SPACE / 2}rem 0;

  display: flex;
  flex-direction: column;
`;

const SnapshotHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    div {
      opacity: 1;
    }
  }
`;

export const ChevronWrapper = styled.div<{ open: boolean }>`
  transform: rotate(${(p) => (p.open ? "90deg" : "0deg")});
  transition: 0.1s ease-in-out;
  opacity: ${(p) => (p.open ? "1" : "0")};
  svg {
    width: 1.6rem;
    height: 1.6rem;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  transition: max-height 0.2s ease-in-out;
`;

const DivisionLineBottom = styled.div`
  height: 100%;
  border-left: 0.1rem solid ${colors.inputOutline};
  width: 0.1rem;

  position: absolute;
  left: -3.2rem;
  top: ${SNAPSHOT_SPACE / 2 + 0.5}rem;
  z-index: 0;
`;

const SnapshotLeftIconWrapper = styled.div`
  position: absolute;
  left: -4.2rem;
  top: 0;
  bottom: 0;
  margin: auto;
  // top: ${SNAPSHOT_SPACE / 2 + 0.5}rem;
  z-index: 2;
  path {
    stroke: ${colors.brand};
  }
  cursor: pointer;
  background-color: white;
`;

const SnapshotBall = styled.div<{
  selected: boolean;
  size: "small" | "medium";
}>`
  width: ${(p) => (p.size === "small" ? "0.8rem" : "1.2rem")};
  height: ${(p) => (p.size === "small" ? "0.8rem" : "1.2rem")};
  border: 0.2rem solid ${colors.inputOutline};
  border-radius: 1.2rem;
  background-color: ${(p) => (p.selected ? colors.primary : colors.hover)};
  position: absolute;
  left: ${(p) => (p.size === "small" ? "-3.75rem" : "-3.95rem")};
  top: ${SNAPSHOT_SPACE / 2 + 0.7}rem;
  cursor: pointer;
  z-index: 2;
`;

const SnapshotAuthorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

const SnapshotInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ColorSvg = styled.div<{ color: string }>`
  svg {
    path {
      stroke: ${(p) => p.color} !important;
    }
  }
`;

const GrayText = styled.p`
  ${typography.contentAndButtons}
  margin: 0.4rem 0;
  color: ${colors.secondaryText};
`;
const BlackText = styled.p`
  ${typography.contentAndButtons}
  margin: 0.4rem 0;
`;

function Author({
  snapshot,
  timestamp,
}: {
  snapshot: SnapshotEvent | GeneralEvent;
  timestamp: number;
}) {
  const user = useGetUserFromIdProjectPage()(snapshot.author);
  return (
    <SnapshotAuthorWrapper>
      <GrayText>{dateToDateTime(new Date(timestamp))}</GrayText>
      {snapshot.author && (
        <UserImageRoundFromId size={1.6} userId={snapshot.author} />
      )}
      {user && (
        <BlackText style={{ textTransform: "capitalize" }}>
          {user.nickname}
        </BlackText>
      )}
    </SnapshotAuthorWrapper>
  );
}

function EventDescription({ snapshot }: { snapshot: SnapshotEvent }) {
  const [open, setOpen] = useState(false);
  const textLines = useMemo(
    () => (snapshot.body ?? "").split("\n").filter((s) => 0 < s.length),
    [snapshot.body],
  );

  const showMoreButton = useMemo(
    () => textLines.length > 1 || (textLines[0] ?? "").length > 40,
    [textLines],
  );

  if (textLines.length === 0) return null;
  return (
    <>
      <DescriptionWrapper open={open}>
        {textLines.map((line, i) => (
          <GrayText
            style={{ lineHeight: "1.7rem", margin: "0" }}
            key={`snapshot-line-${i}`}
          >
            {line}
          </GrayText>
        ))}
      </DescriptionWrapper>
      {showMoreButton && (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setOpen(!open);
          }}
          text={open ? "Show less" : "Show more"}
          buttonType={"text"}
          style={{ padding: "0.6rem 0rem" }}
        />
      )}
    </>
  );
}

function SnapshotBranchElement({
  s: snapshot,
  lastElement,
}: {
  s: SnapshotEvent;
  lastElement: boolean;
}) {
  const { organisationId, projectId, branchId } = useTypedPath(
    "organisationId",
    "projectId",
    "branchId",
  );
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const resetMapControls = useResetMapControls();

  const [duplicating, setDuplicating] = useState(false);
  const duplicateBranch = useDuplicateBranch();

  const [archives, setArchives] = useState(false);
  const isProjectEditor = useRecoilValue(editorAccessProjectSelector);
  const version = useRecoilValue(currentVersionSelector);
  const setVersion = useSetVersion();

  const deleteSnapshot = useDeleteSnapshot();
  const { update: updateSnapshot } = useUpdateSnapshot();
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);

  const isSelected = useMemo(
    () => version === snapshot.version,
    [version, snapshot],
  );

  const navigateTo = useCallback(() => {
    resetMapControls();
    setVersion(snapshot.version);
  }, [setVersion, resetMapControls, snapshot.version]);

  const onArchive = useCallback(async () => {
    if (window.confirm("Are you sure you want to delete this snapshot?")) {
      setArchives(true);
      const promise = deleteSnapshot(snapshot, projectId);
      if (isSelected) {
        setVersion(0);
      }

      await promise;
      setArchives(false);
    }
  }, [deleteSnapshot, snapshot, projectId, isSelected, setVersion]);

  const onDuplicateBranch = useCallback(async () => {
    setDuplicating(true);
    const res = await duplicateBranch(projectId, branchId, snapshot.id);
    if (!res) return;
    const { meta } = res;
    setDuplicating(false);
    resetMapControls();
    setVersion(0);
    searchParams.delete(versionQueryName);
    navigate({
      pathname: `/design/project/${organisationId}/${projectId}/${meta.id}`,
      search: searchParams.toString(),
    });
  }, [
    setVersion,
    branchId,
    organisationId,
    duplicateBranch,
    navigate,
    projectId,
    resetMapControls,
    searchParams,
    snapshot.id,
  ]);

  return (
    <SnapshotWrapper
      selected={isSelected}
      onClick={(e) => {
        e.stopPropagation();
        navigateTo();
      }}
    >
      <SnapshotBox>
        <SnapshotBall selected={isSelected} size={"medium"} />
        {!lastElement && <DivisionLineBottom />}
        <SnapshotHeaderWrapper style={{ gap: "1rem" }}>
          <SnapshotInfoContainer>
            <Row>
              <EditableTextInternalState
                textContainerStyle={{ padding: "0.2rem 0rem" }}
                value={snapshot.title}
                disabled={!isProjectEditor}
                onEnter={(newValue: string) => {
                  updateSnapshot(snapshot, { title: newValue }, projectId);
                }}
                renderText={(text) => {
                  return <SnapshotTitleWrapper>{text}</SnapshotTitleWrapper>;
                }}
              />
              {duplicating && <Spinner />}
              {!duplicating && isProjectEditor && (
                <DotMenu>
                  <MenuItem
                    name={"Edit"}
                    onClick={() => {
                      setModalTypeOpen({
                        modalType: EditBranchSnapshotModalType,
                        metadata: { snapshot },
                      });
                    }}
                    icon={<Pencil />}
                    direction="right"
                  />
                  <MenuItem
                    name={"Restore as new branch"}
                    onClick={onDuplicateBranch}
                    icon={<Reset />}
                    direction="right"
                  />
                  <MenuItem
                    disabled={archives}
                    name={archives ? "Deleting..." : "Delete"}
                    onClick={onArchive}
                    icon={<BinIcon />}
                    direction="right"
                  />
                </DotMenu>
              )}
            </Row>
            <EventDescription snapshot={snapshot} />
            <Author snapshot={snapshot} timestamp={snapshot.createdAt} />
          </SnapshotInfoContainer>
        </SnapshotHeaderWrapper>
      </SnapshotBox>
    </SnapshotWrapper>
  );
}

function generalEventToName(s: GeneralEvent) {
  if (s.title) return s.title;

  switch (s.type) {
    case "cost":
      const count = s.meta.count > 1 ? `(${s.meta.count})` : "";
      return `Cost changed ${count}`;
    case "keySnapshot": {
      const count = s.meta.count > 1 ? `(${s.meta.count})` : "";
      return `Feature changed ${count}`;
    }
    case LayerCollectionVersionConfigTypes.COLLECTION: {
      const count = s.meta.count > 1 ? `(${s.meta.count})` : "";
      const action = s.action === "DELETE" ? "deleted" : "changed";
      return `Collection ${action} ${count}`;
    }
    case LayerCollectionVersionConfigTypes.LAYER: {
      const count = s.meta.count > 1 ? `(${s.meta.count})` : "";
      const action = s.action === "DELETE" ? "deleted" : "changed";
      return `Layer ${action} ${count}`;
    }
    case "turbine": {
      const count = s.meta.count > 1 ? `(${s.meta.count})` : "";
      const action = s.action === "DELETE" ? "deleted" : "changed";
      return `Turbine types ${action} ${count}`;
    }
    case "cable": {
      const count = s.meta.count > 1 ? `(${s.meta.count})` : "";
      return `Cable type changed ${count}`;
    }
    case "projectElementFolder": {
      const count = s.meta.count > 1 ? `(${s.meta.count})` : "";
      const action = s.action === "DELETE" ? "deleted" : "changed";
      return `Project element folder ${action} ${count}`;
    }
    default:
      return "Unknown event";
  }
}

function SnapshotGeneralElement({
  s: snapshot,
  lastElement,
}: {
  s: GeneralEvent;
  lastElement: boolean;
}) {
  const { organisationId, projectId, branchId } = useTypedPath(
    "organisationId",
    "projectId",
    "branchId",
  );
  const resetMapControls = useResetMapControls();
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const setToastMessages = useSetRecoilState(toastMessagesAtom);
  const version = useRecoilValue(currentVersionSelector);
  const setVersion = useSetVersion();
  const navigate = useNavigate();
  const restoreVersionToBranch = useRestoreVersionToBranch();
  const [searchParams] = useSearchParams();

  const isSelected = useMemo(
    () => version === snapshot.version,
    [version, snapshot],
  );

  const isProjectEditor = useRecoilValue(editorAccessProjectSelector);
  const [duplicating, setDuplicating] = useState(false);
  const onRestoreVersionToBranch = useCallback(async () => {
    setDuplicating(true);
    const res = await restoreVersionToBranch(
      projectId,
      branchId,
      snapshot.version,
    );
    if (!res) return;
    const { meta } = res;
    setDuplicating(false);
    resetMapControls();
    setVersion(0);
    searchParams.delete(versionQueryName);
    navigate({
      pathname: `/design/project/${organisationId}/${projectId}/${meta.id}`,
      search: searchParams.toString(),
    });
  }, [
    restoreVersionToBranch,
    setVersion,
    branchId,
    organisationId,
    projectId,
    resetMapControls,
    searchParams,
    navigate,
    snapshot.version,
  ]);

  const onOpenCreateSnapshotFromVersion = useCallback(() => {
    setModalTypeOpen({
      modalType: BranchSnapshotModalType,
      metadata: {
        version: snapshot.version,
        hooks: {
          afterHook: () => {
            setToastMessages((tm) => [
              ...tm,
              {
                text: "Event created from version",
                timeout: 5000,
                type: "success",
              },
            ]);
          },
        },
      },
    });
  }, [setModalTypeOpen, setToastMessages, snapshot.version]);

  const navigateTo = useCallback(() => {
    resetMapControls();
    setVersion(snapshot.version);
  }, [setVersion, resetMapControls, snapshot.version]);

  return (
    <SnapshotWrapper
      selected={isSelected}
      onClick={(e) => {
        e.stopPropagation();
        navigateTo();
      }}
    >
      <SnapshotBox>
        {!lastElement && <DivisionLineBottom />}
        <SnapshotHeaderWrapper style={{ gap: "1rem" }}>
          <SnapshotInfoContainer>
            <Row>
              <EditableTextInternalState
                textContainerStyle={{ padding: "0" }}
                value={generalEventToName(snapshot)}
                disabled={true}
                renderText={(text) => {
                  return <SnapshotTitleWrapper>{text}</SnapshotTitleWrapper>;
                }}
              />
              {duplicating && <Spinner />}
              {!duplicating && isProjectEditor && (
                <DotMenu>
                  <MenuItem
                    name={"Add event from autosave"}
                    onClick={onOpenCreateSnapshotFromVersion}
                    icon={<AddIcon />}
                    direction="right"
                  />
                  <MenuItem
                    name={"Restore as new branch"}
                    onClick={onRestoreVersionToBranch}
                    icon={<Reset />}
                    direction="right"
                  />
                </DotMenu>
              )}
            </Row>
            <Author snapshot={snapshot} timestamp={snapshot.version * 1000} />
          </SnapshotInfoContainer>
        </SnapshotHeaderWrapper>
      </SnapshotBox>
    </SnapshotWrapper>
  );
}

function DateDivider({ date }: { date: DateDividerEvent }) {
  const { projectId, branchId } = useTypedPath("projectId", "branchId");
  const [openMonth, setOpenMonth] = useRecoilState(
    viewAllUnderMonthAtomFamily({
      projectId,
      branchId,
      date: date.date,
    }),
  );

  return (
    <SnapshotWrapper>
      <SnapshotBox>
        <DivisionLineBottom />
        <DatePositionWrapper>
          <DateWrapper>{date.date}</DateWrapper>
        </DatePositionWrapper>
        <Button
          buttonType="text"
          onClick={() => setOpenMonth((cur) => !cur)}
          text={openMonth ? "Hide autosaves" : "View autosaves"}
          size="small"
          style={{ marginLeft: "auto" }}
        />
      </SnapshotBox>
    </SnapshotWrapper>
  );
}

function SnapshotGeneralElements({ group }: { group: GeneralEventGroup }) {
  const { projectId, branchId } = useTypedPath("projectId", "branchId");
  const projectVersion = useRecoilValue(currentVersionSelector);
  const viewAll = useRecoilValue(
    viewAllUnderMonthAtomFamily({
      projectId,
      branchId,
      date: group.date,
    }),
  );
  const [open, setIsOpen] = useState(
    group.events.some((s) => s.version === projectVersion) ?? viewAll,
  );
  useEffect(() => {
    setIsOpen(viewAll);
  }, [viewAll]);

  return (
    <>
      <SnapshotWrapper>
        {!open && (
          <SnapshotBox style={{ padding: 0 }}>
            <SnapshotLeftIconWrapper onClick={() => setIsOpen(!open)}>
              <IconREMSize height={2.2} width={2.2}>
                <ColorSvg
                  style={{ height: "2.2rem" }}
                  color={colors.inputOutline}
                >
                  <JaggedLine />
                </ColorSvg>
              </IconREMSize>
            </SnapshotLeftIconWrapper>
            <DivisionLineBottom />
          </SnapshotBox>
        )}
      </SnapshotWrapper>
      {open &&
        group.events.map((s, i) => (
          <SnapshotGeneralElement key={i} s={s} lastElement={false} />
        ))}
    </>
  );
}

function GroupedSnapshotElement({
  s: event,
  lastElement,
}: {
  s: GroupedEvents;
  lastElement: boolean;
}) {
  if (event.type === "general") {
    return <SnapshotGeneralElements group={event} />;
  }
  if (event.type === "date") return <DateDivider date={event} />;

  return <SnapshotBranchElement s={event} lastElement={lastElement} />;
}

export default VersionHistoryInner;
