import { useToast } from "hooks/useToast";
import { useState } from "react";
import { useRecoilCallback } from "recoil";
import {
  setPublicProjectAccess,
  setPublicProjectGreeting,
  setPublicProjectSetting,
} from "services/projectDataAPIService";
import {
  publicMetadataAtomFamily,
  publicProjectSettingsAtomFamily,
} from "state/projectAPI";
import { scream } from "utils/sentry";

export const projectElementsVisibleKey = "projectElementsVisible";
export const analysisVisibleKey = "analysisVisible";
export const startZoomAreaKey = "startZoomArea";
export const publicCollectionsKey = "public_collections";
export const publicCollectionsToggledByDefaultKey =
  "public_collections_toggled_by_default";
export const publicCollectionsRenamedLayers =
  "public_collections_renamed_layers";

const usePublicModeSettingsCrud = () => {
  const { error } = useToast();
  const [loading, setLoading] = useState(false);
  const updatePublicCollectionSettings = useRecoilCallback(
    ({ set, snapshot }) =>
      async (nodeId: string, newListOfCollectionIds: string[]) => {
        const fallback = await snapshot.getPromise(
          publicProjectSettingsAtomFamily({ projectId: nodeId }),
        );
        set(publicProjectSettingsAtomFamily({ projectId: nodeId }), (curr) => ({
          ...curr,
          [publicCollectionsKey]: newListOfCollectionIds,
        }));
        try {
          setLoading(true);
          await setPublicProjectSetting(nodeId, {
            [publicCollectionsKey]: newListOfCollectionIds,
          });
        } catch (e) {
          set(publicProjectSettingsAtomFamily({ projectId: nodeId }), fallback);
          error("Something went wrong when updating public collection");
          scream("Something went wrong when updating public collection", {
            error,
          });
        } finally {
          setLoading(false);
        }
      },
    [error, setLoading],
  );

  const updatePublicStartZoomSettings = useRecoilCallback(
    ({ set, snapshot }) =>
      async (nodeId: string, startZoomArea: number[][]) => {
        const fallback = await snapshot.getPromise(
          publicProjectSettingsAtomFamily({ projectId: nodeId }),
        );
        set(publicProjectSettingsAtomFamily({ projectId: nodeId }), (curr) => ({
          ...curr,
          [startZoomAreaKey]: startZoomArea,
        }));
        try {
          setLoading(true);
          await setPublicProjectSetting(nodeId, {
            [startZoomAreaKey]: startZoomArea,
          });
        } catch (e) {
          set(publicProjectSettingsAtomFamily({ projectId: nodeId }), fallback);
          error("Something went wrong when updating public starting zoom");
          scream("Something went wrong when updating public starting zoom", {
            error,
          });
        } finally {
          setLoading(false);
        }
      },
    [error, setLoading],
  );

  const updatePublicDefaultToggledCollections = useRecoilCallback(
    ({ set, snapshot }) =>
      async (nodeId: string, newListOfCollectionIds: string[]) => {
        const fallback = await snapshot.getPromise(
          publicProjectSettingsAtomFamily({ projectId: nodeId }),
        );
        set(publicProjectSettingsAtomFamily({ projectId: nodeId }), (curr) => ({
          ...curr,
          [publicCollectionsToggledByDefaultKey]: newListOfCollectionIds,
        }));
        try {
          setLoading(true);
          await setPublicProjectSetting(nodeId, {
            [publicCollectionsToggledByDefaultKey]: newListOfCollectionIds,
          });
        } catch (e) {
          set(publicProjectSettingsAtomFamily({ projectId: nodeId }), fallback);
          error(
            "Something went wrong when updating default toggled collections",
          );
          scream(
            "Something went wrong when updating default toggled collections",
            {
              error,
            },
          );
        } finally {
          setLoading(false);
        }
      },
    [error, setLoading],
  );

  const updatePublicGreeting = useRecoilCallback(
    ({ set, snapshot }) =>
      async (nodeId: string, greetingTitle: string, greetingText: string) => {
        const fallback = await snapshot.getPromise(
          publicProjectSettingsAtomFamily({ projectId: nodeId }),
        );
        set(publicProjectSettingsAtomFamily({ projectId: nodeId }), (curr) => ({
          ...curr,
          greeting_title: greetingTitle,
          greeting_text: greetingText,
        }));
        try {
          setLoading(true);
          await setPublicProjectGreeting(nodeId, greetingTitle, greetingText);
        } catch (e) {
          set(publicProjectSettingsAtomFamily({ projectId: nodeId }), fallback);
          error("Something went wrong when updating greeting text");
          scream("Something went wrong when updating greeting text", {
            error,
          });
        } finally {
          setLoading(false);
        }
      },
    [error, setLoading],
  );

  const togglePublicSetting = useRecoilCallback(
    ({ set, snapshot }) =>
      async (nodeId: string, key: string) => {
        const fallback = await snapshot.getPromise(
          publicProjectSettingsAtomFamily({ projectId: nodeId }),
        );
        const newVal = !(
          (((fallback ?? {}) as any)[key] as boolean | undefined) ?? false
        );
        set(publicProjectSettingsAtomFamily({ projectId: nodeId }), (curr) => ({
          ...curr,
          [key]: newVal,
        }));
        try {
          setLoading(true);
          await setPublicProjectSetting(nodeId, { [key]: newVal });
        } catch (e) {
          set(publicProjectSettingsAtomFamily({ projectId: nodeId }), fallback);
          error("Something went wrong when updating greeting text");
          scream("Something went wrong when updating greeting text", {
            error,
          });
        } finally {
          setLoading(false);
        }
      },
    [error, setLoading],
  );

  const setPublicExternalLayerName = useRecoilCallback(
    ({ set, snapshot }) =>
      async (nodeId: string, layerId: string, layerName: string) => {
        const fallback = await snapshot.getPromise(
          publicProjectSettingsAtomFamily({ projectId: nodeId }),
        );
        set(publicProjectSettingsAtomFamily({ projectId: nodeId }), (curr) => ({
          ...curr,
          [publicCollectionsRenamedLayers]: {
            ...((curr ?? {})[publicCollectionsRenamedLayers] ?? {}),
            [layerId]: layerName,
          },
        }));
        try {
          setLoading(true);
          await setPublicProjectSetting(nodeId, {
            ...fallback,
            [publicCollectionsRenamedLayers]: {
              ...((fallback ?? {})[publicCollectionsRenamedLayers] ?? {}),
              [layerId]: layerName,
            },
          });
        } catch (e) {
          set(publicProjectSettingsAtomFamily({ projectId: nodeId }), fallback);
          error("Something went wrong when updating external layer name");
          scream("Something went wrong when updating external layer name", {
            error,
          });
        } finally {
          setLoading(false);
        }
      },
    [error, setLoading],
  );

  const resetPublicExternalLayerName = useRecoilCallback(
    ({ set, snapshot }) =>
      async (nodeId: string, layerId: string) => {
        const fallback = await snapshot.getPromise(
          publicProjectSettingsAtomFamily({ projectId: nodeId }),
        );
        const updatedSetting = {
          ...fallback,
          [publicCollectionsRenamedLayers]: Object.keys(
            ((fallback ?? {}) as any)[publicCollectionsRenamedLayers],
          ).reduce(
            (acc, key) =>
              key === layerId
                ? acc
                : { ...acc, [key]: ((acc ?? {}) as any)[key] },
            {},
          ),
        };
        set(
          publicProjectSettingsAtomFamily({ projectId: nodeId }),
          updatedSetting,
        );
        try {
          setLoading(true);
          await setPublicProjectSetting(nodeId, updatedSetting);
        } catch (e) {
          set(publicProjectSettingsAtomFamily({ projectId: nodeId }), fallback);
          error("Something went wrong when updating external layer name");
          scream("Something went wrong when updating external layer name", {
            error,
          });
        } finally {
          setLoading(false);
        }
      },
    [error, setLoading],
  );

  const setProjectAsPublic = useRecoilCallback(
    ({ set, snapshot }) =>
      async (nodeId: string, branchId: string, publicMode: boolean) => {
        const fallback = await snapshot.getPromise(
          publicMetadataAtomFamily({ nodeId, branchId }),
        );
        set(publicMetadataAtomFamily({ nodeId, branchId }), (curr) => ({
          ...curr,
          is_public: publicMode,
        }));
        try {
          setLoading(true);
          await setPublicProjectAccess(nodeId, branchId, publicMode);
        } catch (e) {
          set(publicMetadataAtomFamily({ nodeId, branchId }), fallback);
          error("Something went wrong when setting project as public");
          scream("Something went wrong when setting project as public", {
            error,
          });
        } finally {
          setLoading(false);
        }
      },
    [error, setLoading],
  );

  return {
    updatePublicCollectionSettings,
    updatePublicStartZoomSettings,
    updatePublicDefaultToggledCollections,
    updatePublicGreeting,
    togglePublicSetting,
    setPublicExternalLayerName,
    resetPublicExternalLayerName,
    setProjectAsPublic,
    loading,
  };
};

export default usePublicModeSettingsCrud;
