import { ANCHOR_PROPERTY_TYPE } from "@constants/projectMapView";
import {
  AnchorFeature,
  SubstationFeature,
  TurbineFeature,
} from "types/feature";
import { v4 as uuidv4 } from "uuid";
import * as turf from "@turf/turf";
import { bearingAdd } from "utils/geometry";
import { range } from "utils/utils";
import { scream } from "utils/sentry";
import { MultiPolygon, Point, Polygon } from "geojson";

export const makeAnchorPositions = (
  center: turf.Feature<turf.Point>,
  numberOfAnchors: number,
  distance: number,
  zeroBearing: number,
  genDoubles: boolean,
  doublingAngle: number,
  angles?: number[],
): turf.Feature<turf.Point>[] => {
  let _angles = range(0, numberOfAnchors).map(
    (i) => (i * 360) / numberOfAnchors,
  );
  if (angles !== undefined && angles.length === numberOfAnchors) {
    _angles = angles;
    zeroBearing = 0;
  }

  let positions: turf.Feature<turf.Point>[];
  if (genDoubles) {
    positions = range(0, numberOfAnchors).flatMap((i) => {
      const bearing = bearingAdd(zeroBearing, _angles[i]);
      const b1 = bearing + doublingAngle / 2;
      const b2 = bearing - doublingAngle / 2;
      return [
        turf.destination(center, distance, b1),
        turf.destination(center, distance, b2),
      ];
    });
  } else {
    positions = range(0, numberOfAnchors).map((i) => {
      const bearing = bearingAdd(zeroBearing, _angles[i]);
      return turf.destination(center, distance, bearing);
    });
  }
  return positions;
};

export const makeAnchors = (
  turbineOrSubstation: TurbineFeature | SubstationFeature,
  numberOfAnchors: number,
  distance: number,
  zeroBearing: number,
  genDoubles: boolean,
  doublingAngle: number,
  angles?: number[],
  seed?: number,
): AnchorFeature[] => {
  const center = turf.point(turbineOrSubstation.geometry.coordinates);

  if (seed !== undefined)
    zeroBearing = bearingAdd(zeroBearing, Math.sin(seed) * 180);

  const positions = makeAnchorPositions(
    center,
    numberOfAnchors,
    distance,
    zeroBearing,
    genDoubles,
    doublingAngle,
    angles,
  );

  const parkId = turbineOrSubstation.properties.parentIds?.[0];
  if (parkId === undefined)
    throw scream("given turbine didnt have a park id ", {
      turbineOrSubstation,
    });

  return positions.map((pos) => {
    const id = uuidv4();
    return {
      type: "Feature",
      id,
      geometry: pos.geometry,
      properties: {
        id,
        type: ANCHOR_PROPERTY_TYPE,
        parentIds: [parkId],
        name: "Anchor",
      },
    };
  });
};

/**
 * Check that the anchor is outside the given zone, as well as the mooring line
 * in between the turbine and the anchor.
 *
 * Assumes that the mooring line is straight.
 */
export const mooringIntersectsPolygon = (
  turbine: Point,
  anchor: Point,
  polygon: Polygon | MultiPolygon,
): boolean => {
  return (
    0 <
    (turf.lineIntersect(
      turf.lineString([turbine.coordinates, anchor.coordinates]),
      polygon,
    ).features?.length ?? 0)
  );
};
