import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import debounce from "debounce";
import { projectIdSelector } from "../state/pathParams";
import { initializeAndSet } from "../components/Comments/hooks/useReplyReactionCrud";
import {
  CostConfiguration,
  fetchCostConfigurationUsage,
  updateConfiguration,
  _CostConfiguration,
} from "../services/costService";
import {
  costConfigurationTempName,
  costConfigurationsAtomFamily,
  savingCostConfigurationInProgressAtom,
} from "../state/costConfigurations";
import { useToast } from "./useToast";
import { sendWarning } from "utils/sentry";
import { Mixpanel } from "mixpanel";

const saveToDB = async (
  nodeId: string,
  configuration: CostConfiguration,
  onSuccess: () => void,
  onError: (error: Error) => void,
) => {
  return updateConfiguration(
    nodeId,
    configuration.id,
    Object.fromEntries(
      Object.entries(configuration).filter(
        ([key]) => !["id", "createdAt", "lastUpdatedAt"].includes(key),
      ),
    ),
  )
    .then(() => {
      onSuccess();
    })
    .catch(onError);
};

const saveToDbDebounced = debounce(saveToDB, 600);

const useCostConfigurationCrud = () => {
  const projectId = useRecoilValue(projectIdSelector);
  const setIsSaving = useSetRecoilState(savingCostConfigurationInProgressAtom);
  const { success: showSuccess, error: showError } = useToast();

  const save = useRecoilCallback(
    ({ snapshot, set }) =>
      async (configuration: CostConfiguration) => {
        if (!projectId) return;
        setIsSaving(true);

        const usage = await fetchCostConfigurationUsage(
          projectId,
          configuration.id,
        );

        const currentConfigurations = await snapshot.getPromise(
          costConfigurationsAtomFamily({ projectId }),
        );
        const currentConfig = currentConfigurations.find(
          (c) => c.id === configuration.id,
        );

        const { name: _, ...configurationWithoutName } = configuration;
        const { name: __, ...currentConfigWithoutName } = currentConfig || {};

        const changedSomethingElseThanName =
          JSON.stringify(configurationWithoutName) !==
          JSON.stringify(currentConfigWithoutName);

        const updatedConfiguration: CostConfiguration = currentConfig
          ? { ...currentConfig, ...configuration }
          : configuration;

        const parsed =
          await _CostConfiguration.safeParseAsync(updatedConfiguration);
        if (!parsed.success) {
          setIsSaving(false);

          sendWarning("Tried to save invalid cost config", {
            errors: parsed.error.errors.map(({ code, path, message }) => ({
              code,
              path,
              message,
            })),
          });

          return parsed.error.errors
            .slice(0, 1)
            .forEach(({ path, message }) => {
              showError(
                `Invalid configuration: [${path.join(".")}]: ${message}`,
                {
                  timeout: 10_000,
                },
              );
            });
        }

        if (
          !changedSomethingElseThanName ||
          usage.length === 0 ||
          window.confirm(
            `This configuration is used in ${usage.length} branch${
              usage.length > 1 ? "es" : ""
            }, are you sure you want to update it?`,
          )
        ) {
          return saveToDbDebounced(
            projectId,
            updatedConfiguration,
            () => {
              setIsSaving(false);
              set(costConfigurationsAtomFamily({ projectId }), (cur) =>
                cur.map((c) =>
                  c.id === updatedConfiguration.id ? updatedConfiguration : c,
                ),
              );
              Mixpanel.track("Config saved", { type: "cost" });
              showSuccess("Saved", { timeout: 3000 });
            },
            () => {
              setIsSaving(false);
              showError(
                "Something went wrong when saving cost config, try again",
                {
                  timeout: 3000,
                },
              );
            },
          );
        } else {
          setIsSaving(false);
        }
      },
    [projectId, setIsSaving, showSuccess, showError],
  );

  // This method saves the new name to the data base and the atom 'costConfigurationTempName'.
  // the new name is not updated in localConfig or costConfigurationsAtomFamily,
  // because it would result in loosing non-saved values in the config
  const saveName = useRecoilCallback(
    ({ snapshot, set }) =>
      async (id: string, name: string) => {
        if (!projectId) return;
        setIsSaving(true);
        set(
          costConfigurationTempName({
            nodeId: projectId,
          }),
          (cur) => ({
            ...cur,
            [id]: name,
          }),
        );

        const currentConfigurations = await snapshot.getPromise(
          costConfigurationsAtomFamily({ projectId }),
        );

        const currentConfig = currentConfigurations.find((c) => c.id === id);
        if (!currentConfig)
          throw Error("Could not find cost config, config id: " + id);

        const updatedConfiguration: CostConfiguration = {
          ...currentConfig,
          name: name,
        };

        return saveToDbDebounced(
          projectId,
          updatedConfiguration,
          () => {
            setIsSaving(false);
          },
          () => {
            setIsSaving(false);
            set(
              costConfigurationTempName({
                nodeId: projectId,
              }),
              (cur) => ({
                ...cur,
                [id]: undefined,
              }),
            );
            showError("Something went wrong when saving name, try again", {
              timeout: 3000,
            });
          },
        );
      },
    [projectId, setIsSaving, showError],
  );

  // This is used to transfer the new names stored in costConfigurationTempName to costConfigurationsAtomFamily,
  // so that costConfigurationsAtomFamily is up to date with the database
  // This is called when the component is unmounted
  const saveTempNameToLocal = useRecoilCallback(
    ({ set, snapshot }) =>
      async (configurationId: string) => {
        if (!projectId) return;

        const tempNames = await snapshot.getPromise(
          costConfigurationTempName({
            nodeId: projectId,
          }),
        );
        const tempName = tempNames[configurationId];
        if (!tempName) return;

        set(costConfigurationsAtomFamily({ projectId }), (cur) =>
          cur.map((c) =>
            c.id === configurationId ? { ...c, name: tempName } : c,
          ),
        );

        set(costConfigurationTempName({ nodeId: projectId }), (cur) => ({
          ...cur,
          [configurationId]: undefined,
        }));
      },
    [projectId],
  );

  const updateLocal = useRecoilCallback(
    ({ set, snapshot }) =>
      async (configuration: CostConfiguration) => {
        initializeAndSet(
          snapshot,
          set,
          costConfigurationsAtomFamily({ projectId }),
          (cur) => {
            const match = cur.some((c) => c.id === configuration.id);
            if (match) {
              return [...cur].map((c) =>
                c.id === configuration.id ? configuration : c,
              );
            } else {
              return [...cur, configuration];
            }
          },
        );
      },
    [projectId],
  );

  const deleteLocal = useRecoilCallback(
    ({ set, snapshot }) =>
      async (configurationId: string) => {
        initializeAndSet(
          snapshot,
          set,
          costConfigurationsAtomFamily({ projectId }),
          (cur) => {
            return [...cur].filter((c) => c.id !== configurationId);
          },
        );
      },
    [projectId],
  );

  return { save, saveName, updateLocal, deleteLocal, saveTempNameToLocal };
};

export default useCostConfigurationCrud;
