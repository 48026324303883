import React, { useCallback, useMemo, useRef } from "react";
import styled from "styled-components";
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import AddIcon from "@icons/24/Add.svg";
import BinIcon from "@icons/24/Bin.svg";
import ChevronDownIcon from "@icons/14/ChevronDown.svg";
import CopyToProjectIcon from "@icons/14/CopyToProject.svg";
import DuplicateIcon from "@icons/24/DuplicateAlt.svg";
import FilterIcon from "@icons/24/Filter.svg";
import FolderOpenIcon from "@icons/20/FolderMenuOpen.svg";
import FolderOutlineIcon from "@icons/20/FolderMenuClosed.svg";
import LayersIcon from "@icons/24/Layers.svg";
import RemoveIcon from "@icons/24/Remove.svg";
import SearchIcon from "@icons/24/Search.svg";
import SectionIcon from "@icons/24/Section.svg";
import StylingIcon from "@icons/14/Styling.svg";
import Toggle, { ToggleSize } from "../../General/Toggle";
import { spacing1, spacing4 } from "styles/space";
import type { Layer } from "../../../types/layers";
import { LayerCollection, LayerCollectionLayer } from "../LayerSettings/types";
import { EditableText } from "../../General/EditableText";
import { MenuItem } from "../../General/Menu";
import { useTypedPath } from "../../../state/pathParams";
import LayerStyling from "../../LayerStyling/LayerStyling";
import { DotMenu, MenuButtonRef } from "../../General/MenuButton";
import useBooleanState from "../../../hooks/useBooleanState";
import useTextInput from "../../../hooks/useTextInput";
import {
  CollectionItemWrapper,
  CollectionLayersList,
  HideIfNotHoverOrVisible,
} from "../LayerList.style";
import { isCustomUploadedLayer, sortLayers } from "../utils";
import { getAllLayerCollections } from "../Collections/state";
import { useCollectionCrud } from "../Collections/useCollectionCrud";
import {
  layersSettingSelectorFamily,
  layerVisibleAtomFamily,
} from "../LayerSettings/state";
import { colors } from "../../../styles/colors";
import { Ui13RegularOverflow } from "../../ProjectElementsV2/ProjectElementsV2.style";
import { externalLayerFilterPropertyAtom } from "../../../state/layer";
import { layerToSourceId } from "../../../layers/ExternalLayers/utils";
import { LngLatBoundsLike } from "mapbox-gl";
import { Mixpanel } from "../../../mixpanel";
import { mapRefAtom } from "../../../state/map";
import { modalTypeOpenAtom } from "../../../state/modal";
import { CopyCollectionModalType } from "../../CopyCollectionModal/CopyCollectionModal";
import { SkeletonBlock } from "../../Loading/Skeleton";
import { selectedLayerIdsWithCollectionIdAtom } from "../state";
import LayerItem from "./LayerItem";
import useSystemSpecificUnicode from "hooks/useSystemSpecificUnicode";

const CollectionLayerItem = ({
  collection,
  layer,
  editable,
  onToggleMultiSelect,
  onShiftSelect,
  onAddAllToCollectionClick,
}: {
  collection: LayerCollection;
  layer: Layer;
  editable: boolean;
  onToggleMultiSelect: (
    newLayerId: string,
    newCollectionId: string | undefined,
  ) => void;
  onShiftSelect: (layerId: string, collectionId: string | undefined) => void;
  onAddAllToCollectionClick(collection?: LayerCollection): void;
}) => {
  const { projectId } = useTypedPath("projectId");
  const { put: putCollection } = useCollectionCrud();
  const stringToUnicode = useSystemSpecificUnicode();
  const map = useRecoilValue(mapRefAtom);
  const collections = useRecoilValue(getAllLayerCollections({ projectId }));
  const dotMenuRef = useRef<MenuButtonRef>(null);
  const selectedLayerIdsWithCollection = useRecoilValue(
    selectedLayerIdsWithCollectionIdAtom,
  );

  const [externalLayerFilterProperty, setExternalLayerFilterProperty] =
    useRecoilState(externalLayerFilterPropertyAtom);

  const layerSourceId = useMemo(
    () => layerToSourceId(layer, `-${layer.type}`),
    [layer],
  );

  const activeLayerFilters = useMemo(() => {
    return externalLayerFilterProperty[layerSourceId];
  }, [externalLayerFilterProperty, layerSourceId]);

  const addLayerToCollection = useCallback(
    async (_collection: LayerCollection, _layer: LayerCollectionLayer) => {
      await putCollection({
        ..._collection,
        layers: [..._collection.layers, _layer],
      });
    },
    [putCollection],
  );

  const removeLayersFromCollection = useCallback(
    async (layerIds: string[]) => {
      await putCollection({
        ...collection,
        layers: collection.layers.filter((l) => !layerIds.includes(l.id)),
      });
    },
    [collection, putCollection],
  );

  const isSelected = selectedLayerIdsWithCollection.some((row) => {
    return row.layerId === layer.id && row.collectionId === collection.id;
  });

  const isMultiSelect = selectedLayerIdsWithCollection.length > 1;

  return (
    <LayerItem
      layer={layer}
      editable={editable}
      depth={1}
      collectionId={collection.id}
      nameEditable={isCustomUploadedLayer(layer)}
      onToggleMultiSelect={onToggleMultiSelect}
      onShiftSelect={onShiftSelect}
      isSelected={isSelected}
      onContextMenu={(e) => {
        e.preventDefault();
        dotMenuRef.current?.setIsOpen(true);
      }}
      dotMenu={
        editable && (
          <DotMenu
            ref={dotMenuRef}
            side="right"
            buttonStyle={{
              height: "2.2rem",
            }}
          >
            {isSelected && isMultiSelect ? (
              <>
                <MenuItem
                  name="Add all to folder"
                  icon={<AddIcon />}
                  direction="right"
                >
                  {collections.map((collection) => {
                    return (
                      <MenuItem
                        key={collection.id}
                        name={collection.name}
                        icon={<LayersIcon />}
                        onClick={() => onAddAllToCollectionClick(collection)}
                        direction="right"
                      />
                    );
                  })}
                  <MenuItem
                    name="Create new folder"
                    icon={<AddIcon />}
                    onClick={() => onAddAllToCollectionClick()}
                  />
                </MenuItem>

                <MenuItem
                  name={"Deselect"}
                  icon={<SectionIcon />}
                  onClick={() => {
                    onToggleMultiSelect(layer.id, collection.id);
                  }}
                  shortcut={`${stringToUnicode("command")} + Click`}
                />

                <MenuItem
                  name="Remove layers from folder"
                  icon={<RemoveIcon />}
                  onClick={() => {
                    removeLayersFromCollection(
                      selectedLayerIdsWithCollection.map((row) => row.layerId),
                    );
                  }}
                />
              </>
            ) : (
              <>
                <MenuItem
                  name="Set styling"
                  icon={<StylingIcon />}
                  direction="right"
                >
                  <React.Suspense
                    fallback={
                      <SkeletonBlock
                        style={{ width: "20rem", height: "15rem" }}
                      />
                    }
                  >
                    <LayerStyling layer={layer} />
                  </React.Suspense>
                </MenuItem>
                <MenuItem
                  name="Duplicate to folder"
                  icon={<DuplicateIcon />}
                  direction="right"
                >
                  {collections.map((collection) => {
                    const isInCollection =
                      collection.layers.find(
                        (collectionLayer) => collectionLayer.id === layer.id,
                      ) !== undefined;
                    return (
                      <MenuItem
                        key={collection.id}
                        name={collection.name}
                        icon={<LayersIcon />}
                        disabled={isInCollection}
                        onClick={() => {
                          addLayerToCollection(collection, layer);
                        }}
                        direction="right"
                      />
                    );
                  })}
                </MenuItem>
                {layer.bbox && map && (
                  <MenuItem
                    name="Zoom to layer"
                    icon={<SearchIcon />}
                    onClick={() => {
                      map.fitBounds([
                        layer.bbox!.slice(0, 2),
                        layer.bbox!.slice(2, 4),
                      ] as LngLatBoundsLike);
                    }}
                  />
                )}
                <MenuItem
                  name={isSelected ? "Deselect" : "Multiselect"}
                  icon={<SectionIcon />}
                  onClick={() => {
                    onToggleMultiSelect(layer.id, collection.id);
                  }}
                  shortcut={`${stringToUnicode("command")} + Click`}
                />
                {!!activeLayerFilters && (
                  <MenuItem
                    name="Clear filters"
                    icon={<FilterIcon />}
                    onClick={() => {
                      setExternalLayerFilterProperty((curr) => {
                        const clone = { ...curr };
                        delete clone[layerSourceId];
                        return clone;
                      });
                    }}
                  />
                )}
                <MenuItem
                  name="Remove from folder"
                  icon={<RemoveIcon />}
                  onClick={() => removeLayersFromCollection([layer.id])}
                />
              </>
            )}
          </DotMenu>
        )
      }
    />
  );
};

const ExpandArrowWrapper = styled.div<{ open: boolean }>`
  margin-right: 1rem;
  cursor: pointer;
  margin-left: ${spacing1};
  transform: rotate(${({ open }) => (!open ? "-90deg" : "0deg")});
  transition: 0.1s;

  ${({ open }) =>
    !open &&
    `
    svg {
      path {
        fill: ${colors.grey500};
      }
    }`};
`;

const CollectionItemTopRow = styled.div<{
  clickable: boolean;
  allLayersVisible: boolean;
}>`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  cursor: ${({ clickable }) => (clickable ? "pointer" : "unset")};
  border-radius: 4px;
  min-height: 3.6rem;
  padding-left: ${spacing4};
  padding-right: ${spacing4};

  &:hover {
    background-color: ${colors.hover};
  }

  ${({ allLayersVisible }) =>
    !allLayersVisible &&
    `
    ${HideIfNotHoverOrVisible} {
      visibility: hidden;
    }
    
    &:hover {
      ${HideIfNotHoverOrVisible} {
        visibility: inherit;
      }
    }
  `}
`;

const CollectionItem = ({
  collection,
  editable,
  defaultOpen,
  onOpenChange,
  onToggleMultiSelect,
  onShiftSelect,
  onAddAllToCollectionClick,
}: {
  collection: LayerCollection;
  editable: boolean;
  defaultOpen: boolean;
  onOpenChange(collectionId: string, isOpen: boolean): void;
  onToggleMultiSelect(layerId: string, collectionId: string | undefined): void;
  onShiftSelect: (layerId: string, collectionId: string | undefined) => void;
  onAddAllToCollectionClick(collection?: LayerCollection): void;
}) => {
  const [open, toggleOpen] = useBooleanState(defaultOpen);
  const [writtenName, onWrittenNameChange] = useTextInput(collection.name);
  const { projectId } = useTypedPath("projectId");
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const dotMenuRef = useRef<MenuButtonRef>(null);

  const allLayerSettings = useRecoilValue(
    layersSettingSelectorFamily({
      projectId,
      layerIds: collection.layers.map((layer) => layer.id),
    }),
  );

  const allCollectionLayersAreVisible = useMemo(() => {
    return (
      collection.layers.length > 0 &&
      collection.layers.every((layer) => {
        return allLayerSettings.find((setting) => setting.id === layer.id)
          ?.visible;
      })
    );
  }, [allLayerSettings, collection.layers]);

  const {
    put: putCollection,
    remove: removeCollection,
    post: createCollection,
  } = useCollectionCrud();

  const setLayersVisible = useRecoilCallback(
    ({ set }) =>
      (layers: Layer[], visible: boolean) => {
        for (const layer of layers) {
          set(
            layerVisibleAtomFamily({
              projectId,
              layerId: layer.id,
            }),
            visible,
          );
        }
      },
    [projectId],
  );

  const toggleAllCollectionLayersVisible = useCallback(() => {
    setLayersVisible(collection.layers, !allCollectionLayersAreVisible);
  }, [collection.layers, setLayersVisible, allCollectionLayersAreVisible]);

  const onEnterOrCancel = useCallback(() => {
    return putCollection({ ...collection, name: writtenName });
  }, [putCollection, collection, writtenName]);

  const onExpandClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      toggleOpen();
      onOpenChange(collection.id, !open);
    },
    [toggleOpen, open, onOpenChange, collection],
  );

  const sortedCollectionLayers = useMemo(
    () => sortLayers(collection.layers),
    [collection.layers],
  );

  return (
    <CollectionItemWrapper>
      <CollectionItemTopRow
        allLayersVisible={allCollectionLayersAreVisible}
        clickable={true}
        onClick={onExpandClick}
        onContextMenu={(e) => {
          e.preventDefault();
          dotMenuRef.current?.setIsOpen(true);
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center", overflow: "hidden" }}
        >
          <ExpandArrowWrapper open={open}>
            <ChevronDownIcon />
          </ExpandArrowWrapper>
          {open ? (
            <FolderOpenIcon style={{ marginRight: "0.5rem", width: "2rem" }} />
          ) : (
            <FolderOutlineIcon
              style={{ marginRight: "0.5rem", width: "2rem" }}
            />
          )}
          <EditableText
            type="text"
            onEnter={onEnterOrCancel}
            onCancel={onEnterOrCancel}
            onChange={onWrittenNameChange}
            value={writtenName}
            disabled={!editable}
            renderText={() => (
              <Ui13RegularOverflow title={collection.name}>
                {collection.name} ({collection.layers.length})
              </Ui13RegularOverflow>
            )}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <HideIfNotHoverOrVisible>
            {editable && (
              <DotMenu ref={dotMenuRef}>
                <MenuItem
                  name="Duplicate"
                  icon={<DuplicateIcon />}
                  onClick={() =>
                    createCollection({
                      ...collection,
                      name: "Duplicate of " + collection.name,
                    })
                  }
                />
                <MenuItem
                  name="Copy to project"
                  icon={<CopyToProjectIcon />}
                  onClick={() => {
                    Mixpanel.track("Open copy collection modal", {});
                    setModalTypeOpen({
                      modalType: CopyCollectionModalType,
                      metadata: {
                        collectionId: collection.id,
                      },
                    });
                  }}
                />
                <MenuItem
                  name="Delete folder"
                  icon={<BinIcon />}
                  onClick={() => removeCollection(collection)}
                />
              </DotMenu>
            )}
            <Toggle
              size={ToggleSize.SMALL}
              checked={allCollectionLayersAreVisible}
              onChange={toggleAllCollectionLayersVisible}
              title="Toggle folder visibility"
            />
          </HideIfNotHoverOrVisible>
        </div>
      </CollectionItemTopRow>

      {open && sortedCollectionLayers.length > 0 && (
        <CollectionLayersList>
          {sortedCollectionLayers.map((layer) => (
            <CollectionLayerItem
              key={layer.id}
              collection={collection}
              layer={layer}
              onToggleMultiSelect={onToggleMultiSelect}
              onShiftSelect={onShiftSelect}
              onAddAllToCollectionClick={onAddAllToCollectionClick}
              editable={editable}
            />
          ))}
        </CollectionLayersList>
      )}
    </CollectionItemWrapper>
  );
};

export default CollectionItem;
