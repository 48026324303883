import React, { useCallback } from "react";
import { Mixpanel } from "mixpanel";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { colors } from "styles/colors";
import { DotMenu } from "components/General/MenuButton";
import SearchWrapper from "components/Search/SearchWrapper";
import { MenuItem } from "components/General/Menu";
import { PublicModeModalTypeV2 } from "state/configuration";
import CameraIcon from "@icons/24/Camera.svg?react";
import FlagIcon from "@icons/24/WindMeasure.svg?react";
import VersionHistoryIcon from "@icons/14/VersionHistoryAlt.svg?react";
import ArchivedProjectVersions from "@icons/24/ArchivedProjectVersions.svg?react";
import PublicModeAltIcon from "@icons/14/PublicModeAlt.svg?react";
import { IconButtonStyler } from "components/Design/TopbarNavigation/components/RightSide/style";
import { useRecoilValueDef } from "utils/recoil";
import { branchIdSelector, projectIdSelector } from "state/pathParams";
import {
  editorAccessProjectSelector,
  loggedInUserIsInternalSelector,
} from "state/user";
import { modalTypeOpenAtom } from "state/modal";
import { versionHistoryOpenStateAtom } from "components/Design/ProjectHistory/state";
import { lowerRightMenuActiveModeAtom } from "state/layer";
import { TopRightModeActiveAtom } from "components/RightSide/InfoModal/ProjectFeatureInfoModal/state";
import { TopRightMenuOptions } from "@constants/canvas";
import { archivedProjectVersionsOpenStateAtom } from "components/Design/ArchiveProjectVersion/state";

const MiscDotMenu = ({
  toggleFeatureFlagsAreOpen,
  setScreenshotOpen,
}: {
  toggleFeatureFlagsAreOpen(): void;
  setScreenshotOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const isCustomerEditor = useRecoilValue(editorAccessProjectSelector);
  const projectId = useRecoilValueDef(projectIdSelector);
  const branchId = useRecoilValueDef(branchIdSelector);
  const isInternal = useRecoilValue(loggedInUserIsInternalSelector);
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const topRightModeActive = useRecoilValue(TopRightModeActiveAtom);
  const setLowerRightActiveMode = useSetRecoilState(
    lowerRightMenuActiveModeAtom,
  );
  const [versionHistoryOpenState, setVersionHistoryOpenState] = useRecoilState(
    versionHistoryOpenStateAtom,
  );
  const [archivedProjectVersionOpenState, setArchivedProjectVersionOpenState] =
    useRecoilState(archivedProjectVersionsOpenStateAtom);

  const closeAnyOpenModal = useCallback(() => {
    setModalTypeOpen(undefined);
  }, [setModalTypeOpen]);

  return (
    <IconButtonStyler hoverBackgroundColor={colors.grey900}>
      <DotMenu
        color={colors.white}
        hoverColor={colors.blue300}
        hoverType="stroke"
      >
        <SearchWrapper
          title="Screenshot"
          id="search-screenshot"
          tags={["picture", "camera", "download", "export", "snapshot"]}
          icon={<CameraIcon />}
          onSelect={() => {
            closeAnyOpenModal();
            setScreenshotOpen(true);
          }}
        >
          <MenuItem
            name="Screenshot"
            disabled={topRightModeActive === TopRightMenuOptions.view}
            icon={<CameraIcon />}
            onClick={() => {
              closeAnyOpenModal();
              setScreenshotOpen(true);
            }}
          />
        </SearchWrapper>
        {isCustomerEditor && (
          <MenuItem
            name="Public mode"
            icon={<PublicModeAltIcon />}
            onClick={() => {
              setModalTypeOpen({
                modalType: PublicModeModalTypeV2,
                metadata: { selectedMenuId: "public_mode" },
              });
            }}
          />
        )}
        <SearchWrapper
          title="View version history"
          tags={[
            "version",
            "undo",
            "error",
            "timeline",
            "history",
            "change",
            "log",
            "snapshot",
            "event",
            "autosave",
            "save",
          ]}
          id="search-project-history"
          icon={<VersionHistoryIcon />}
          onSelect={() => {
            closeAnyOpenModal();
            setLowerRightActiveMode(undefined);
            setVersionHistoryOpenState(true);
          }}
        >
          <MenuItem
            name="Version history"
            icon={<VersionHistoryIcon />}
            onClick={() => {
              closeAnyOpenModal();
              setLowerRightActiveMode(undefined);
              setVersionHistoryOpenState(true);

              Mixpanel.track(
                `Project version history ${versionHistoryOpenState ? "closed" : "opened"}`,
                {
                  projectId,
                  branchId,
                },
              );
            }}
          />
        </SearchWrapper>
        <SearchWrapper
          title="View Saved Project Versions"
          tags={[
            "version",
            "undo",
            "error",
            "timeline",
            "history",
            "change",
            "log",
            "snapshot",
            "event",
            "autosave",
            "save",
          ]}
          id="search-saved-project-versions"
          icon={<ArchivedProjectVersions />}
          onSelect={() => {
            closeAnyOpenModal();
            setLowerRightActiveMode(undefined);
            setVersionHistoryOpenState(false);
            setArchivedProjectVersionOpenState(true);
          }}
        >
          <MenuItem
            name="Saved project data"
            icon={<ArchivedProjectVersions />}
            onClick={() => {
              closeAnyOpenModal();
              setLowerRightActiveMode(undefined);
              setVersionHistoryOpenState(false);
              setArchivedProjectVersionOpenState(true);

              Mixpanel.track(
                `Saved project version ${archivedProjectVersionOpenState ? "closed" : "opened"}`,
                {
                  projectId,
                  branchId,
                },
              );
            }}
          />
        </SearchWrapper>
        {isInternal && (
          <MenuItem
            name="Feature flags"
            icon={<FlagIcon />}
            onClick={() => {
              closeAnyOpenModal();
              toggleFeatureFlagsAreOpen();
            }}
          />
        )}
      </DotMenu>
    </IconButtonStyler>
  );
};

export default MiscDotMenu;
