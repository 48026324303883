import { atom } from "recoil";
import { TopRightMenuOptions } from "../../../../constants/canvas";

export type TopRightModeType =
  | undefined
  | (typeof TopRightMenuOptions)[keyof typeof TopRightMenuOptions];

export const TopRightModeActiveAtom = atom<TopRightModeType>({
  key: "InfoModalOpenState",
  default: undefined,
});

export const showFeaturePropertiesAtom = atom<boolean>({
  key: "showFeaturePropertiesAtom",
  default: false,
});

export const showMoreFeatureActionsAtom = atom<boolean>({
  key: "showMoreFeatureActionsAtom",
  default: false,
});
