import { useRecoilCallback } from "recoil";
import { UserHintSettings, postUserHintSettings } from "./services";
import { userHintSettingsAtom } from "./state";

export const usePutUserHintSettings = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      async (update: Partial<UserHintSettings>) => {
        const fallback = await snapshot.getPromise(userHintSettingsAtom);
        set(userHintSettingsAtom, (curr) => ({ ...curr, ...update }));
        postUserHintSettings(update)
          .then((res) => set(userHintSettingsAtom, res))
          .catch(() => set(userHintSettingsAtom, fallback));
      },
    [],
  );
};
