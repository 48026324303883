import { useCallback, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import ZoomToComment from "./ZoomToComment";
import { InnerZoomToExistingPark, InnerZoomToParks } from "./ZoomToPark";
import { useRecoilValue } from "recoil";
import { customerProjectAtomFamily } from "../../../state/timeline";
import { parkIdSelector, projectIdSelector } from "../../../state/pathParams";
import {
  ErrorBoundarySilent,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "../../ErrorBoundaries/ErrorBoundaryLocal";
import { ProjectMeta } from "../../../types/api";

//After migrating to SQL we lost some information, tutorialId was one of these things
const isTutorialProject = (projectMeta: ProjectMeta | undefined) =>
  projectMeta?.name === "Learning Vind";

const ZoomToObject = ErrorBoundaryWrapper(
  () => {
    const parkId = useRecoilValue(parkIdSelector);
    const projectId = useRecoilValue(projectIdSelector) ?? "";
    const { hash } = useLocation();
    const projectMeta = useRecoilValue(
      customerProjectAtomFamily({ nodeId: projectId }),
    );

    const [hasZoomed, setHasZoomed] = useState(
      Boolean(hash || !!isTutorialProject(projectMeta)),
    );
    const [hasZoomedIntoParks, sethasZoomedIntoParks] = useState(
      Boolean(hash || !!isTutorialProject(projectMeta)),
    );
    const [searchParams] = useSearchParams();
    const commentId = searchParams.get("commentId");

    const setHasZoomedCallback = useCallback(
      (hasZoomed: boolean) => {
        setHasZoomed(hasZoomed);
        sethasZoomedIntoParks(hasZoomed);
      },
      [setHasZoomed, sethasZoomedIntoParks],
    );

    // Reset hasZoomed value when switching park
    useEffect(() => {
      return () => {
        setHasZoomed(false);
      };
    }, [parkId]);

    if (hasZoomed) {
      return null;
    }

    if (commentId) {
      return <ZoomToComment setHasZoomed={setHasZoomedCallback} />;
    } else {
      if (hasZoomedIntoParks) return null;
      if (parkId) {
        return (
          <InnerZoomToExistingPark
            parkId={parkId}
            setHasZoomed={setHasZoomedCallback}
          />
        );
      }
      return <InnerZoomToParks setHasZoomed={setHasZoomedCallback} />;
    }
  },
  ErrorBoundarySilent,
  ScreamOnError,
);

export default ZoomToObject;
