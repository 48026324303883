import { fetchWithToken } from "services/utils";
import * as spec from "api/customer";
import * as z from "zod";

export const _IntercomSearchResult =
  spec.components.schemas.IntercomSearchResult;

export type IntercomSearchResult = z.infer<typeof _IntercomSearchResult>;

export const _IntercomArticle = spec.components.schemas.IntercomArticle;

export type IntercomArticle = z.infer<typeof _IntercomArticle>;

export const searchIntercomArticles = async (
  phrase: string,
): Promise<IntercomSearchResult> => {
  const json = await fetchWithToken(
    `/api/customer/intercom/search?phrase=${phrase}`,
    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    },
  ).then((response) => response.json());

  return _IntercomSearchResult.parse(json);
};
