/// <reference types="vite-plugin-svgr/client" />
import { SVGWrapper } from "@icons/svgUtils";
import {
  userGroupsMembershipState,
  organisationGroupsState,
} from "components/Organisation/Groups/state";
import useMemberInGroupCrud from "components/Organisation/Groups/useMemberInGroupCrud";
import {
  ContentTableColumn,
  ContentTableRow,
  NoGroupMember,
  NoGroupMemberWrapper,
  TabDescription,
  TextEllipsis,
} from "components/Organisation/OrganisationRightSide/style";
import { useRecoilValue } from "recoil";
import { useTypedPath } from "state/pathParams";
import { adminInOrganisationSelectorFamily } from "state/user";
import { Text, TextSemi } from "styles/typography";
import Trashcan from "@icons/24/Bin.svg?react";
import TeamMeeting from "@icons/24/TeamMeeting.svg?react";
import AddIcon from "@icons/24/Add.svg?react";
import { Column, Row } from "components/General/Layout";
import Button from "components/General/Button";
import { useCallback, useState } from "react";
import { Group } from "components/Organisation/Groups/types";
import UserGroupModal from "../../UserGroupModal";
import { OrganisationUser } from "types/customer";
import { ContentImageWrapper } from "components/GroupImageRound/GroupImageRound";
import { colors } from "styles/colors";
import Tooltip from "components/General/Tooltip";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { IconBtn } from "components/General/Icons";

export function UserGroups({ user }: { user: OrganisationUser }) {
  const { organisationId } = useTypedPath("organisationId");
  const userId = user.user_id;

  const memberships = useRecoilValue(
    userGroupsMembershipState({ organisationId, userId }),
  );
  const { remove, add } = useMemberInGroupCrud();
  const groups = useRecoilValue(organisationGroupsState({ organisationId }));

  const isAdminInOrg = useRecoilValue(
    adminInOrganisationSelectorFamily({ organisationId }),
  );

  const [saveInProgress, setSaveInProgress] = useState<number>(0);
  const [deleteInProgress, setDeleteInProgress] = useState<
    string | undefined
  >();
  const [showGroupSearch, setShowGroupSearch] = useState(false);
  const onSaveMultiple = useCallback(
    async (groups: Group[]) => {
      setShowGroupSearch(false);
      setSaveInProgress(groups.length);
      try {
        await Promise.all(
          groups.map((g) =>
            add(g.id, userId).then(() => setSaveInProgress((cur) => cur - 1)),
          ),
        );
      } catch {
      } finally {
        setSaveInProgress(0);
      }
    },
    [add, userId],
  );

  return (
    <Column style={{ gap: "2.4rem", height: "100%" }}>
      <Row style={{ alignItems: "center" }}>
        <TabDescription>
          {user.nickname} is a member of these groups.
        </TabDescription>
        <div style={{ marginLeft: "auto", position: "relative" }}>
          <Tooltip
            text={`Organisation admin can add ${user.nickname} to groups`}
            disabled={isAdminInOrg}
          >
            <Button
              text="Add groups"
              buttonType="secondary"
              icon={<AddIcon />}
              onClick={() => setShowGroupSearch((cur) => !cur)}
              disabled={saveInProgress > 0 || !isAdminInOrg}
            />
          </Tooltip>
          {showGroupSearch && (
            <div style={{ position: "absolute", right: "0.8rem" }}>
              <UserGroupModal
                existingMemberships={memberships}
                onSave={onSaveMultiple}
              />
            </div>
          )}
        </div>
      </Row>
      <ContentTableColumn>
        {memberships.map((m) => {
          const group = groups.find((g) => g.id === m.group_id);
          return (
            <ContentTableRow
              key={m.group_id}
              disabled={deleteInProgress === m.group_id}
            >
              <SVGWrapper size={1.4}>
                <TeamMeeting />
              </SVGWrapper>
              <TextEllipsis style={{ margin: 0 }}>{group?.name}</TextEllipsis>
              {isAdminInOrg && (
                <IconBtn
                  disabled={deleteInProgress === m.group_id}
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeleteInProgress(m.group_id);
                    remove(m.group_id, m.user_id).finally(() =>
                      setDeleteInProgress(undefined),
                    );
                  }}
                  size={"1.4rem"}
                  style={{
                    marginLeft: "auto",
                  }}
                >
                  <Trashcan />
                </IconBtn>
              )}
            </ContentTableRow>
          );
        })}
        {Array.from({ length: saveInProgress }, (_, index) => (
          <SkeletonBlock
            key={index}
            style={{ height: "4rem", marginTop: "0.8rem" }}
          />
        ))}
      </ContentTableColumn>
      {isAdminInOrg && memberships.length === 0 && (
        <NoGroupMemberWrapper>
          <NoGroupMember>
            <ContentImageWrapper
              size={4}
              color={colors.brand}
              svgColor={"white"}
              style={{ marginBottom: "1.2rem" }}
            >
              <TeamMeeting />
            </ContentImageWrapper>
            <TextSemi>No groups added yet</TextSemi>
            <Text>{"Click ‘Add to Group' above to start adding"}</Text>
          </NoGroupMember>
        </NoGroupMemberWrapper>
      )}
      {!isAdminInOrg && memberships.length === 0 && (
        <NoGroupMemberWrapper>
          <NoGroupMember>
            <ContentImageWrapper
              size={4}
              color={colors.brand}
              svgColor={"white"}
              style={{ marginBottom: "1.2rem" }}
            >
              <TeamMeeting />
            </ContentImageWrapper>
            <TextSemi>No groups added yet</TextSemi>
            <Text>
              {"Organisation Owner or Admins can add users to Groups."}
            </Text>
          </NoGroupMember>
        </NoGroupMemberWrapper>
      )}
    </Column>
  );
}
