import {
  atom,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { Modal, modalTypeOpenAtom } from "../../state/modal";
import FullScreenModal from "../FullScreenModal/FullScreenModal";
import styled from "styled-components";
import { useCallback, useMemo, useState } from "react";
import { currentSelectionArrayAtom } from "../../state/selection";
import { InputDimensioned } from "../General/Input";
import { ProjectFeature } from "../../types/feature";
import Button from "../General/Button";
import Checkbox from "../General/Checkbox";
import Tooltip from "../General/Tooltip";
import { isDefined } from "../../utils/predicates";
import { projectFeaturesSelector } from "../ProjectElements/state";
import { useProjectElementsCrud } from "../ProjectElements/useProjectElementsCrud";
import { Column, ModalFrame } from "../General/Layout";
import { spaceLarge } from "../../styles/space";
import { resetListIfNotAlreadyEmpty } from "../../utils/resetList";
import { bufferSingleFeature } from "../../utils/bufferSingleFeature";
import { Distance } from "components/Units/units";
import { useToast } from "hooks/useToast";

export const BufferModalType = "BufferModal";

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  div {
  }
`;

const unitAtom = atom({
  key: "bufferModalSelectedUnitAtom",
  default: Distance.cannonical,
});

const BufferModalInner = ({
  modal,
}: {
  modal: Modal<typeof BufferModalType>;
}) => {
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const rawSelection = useRecoilValue(currentSelectionArrayAtom);
  const [bufferSize, setBufferSize] = useState<undefined | number>();

  const canvasLayerFeatures = useRecoilValue(projectFeaturesSelector);
  const { update: updateFeatures } = useProjectElementsCrud();

  const [pencilEnds, setPencilEnds] = useState<boolean>(false);

  const setCurrentSelectionArray = useSetRecoilState(currentSelectionArrayAtom);

  const [unit, setUnit] = useRecoilState(unitAtom);
  const { warning } = useToast();

  const selection: undefined | ProjectFeature | ProjectFeature[] =
    useMemo(() => {
      const featureSelection = modal.metadata?.selection;
      if (featureSelection === undefined) {
        const ids = rawSelection;
        const features = ids
          .map((id) => canvasLayerFeatures.find((f) => f.id === id))
          .filter(isDefined);
        if (features.length === 1) return features[0];
        return features;
      }
      if (Array.isArray(featureSelection) && featureSelection.length < 2)
        return featureSelection[0];
      return featureSelection;
    }, [canvasLayerFeatures, modal.metadata, rawSelection]);

  const bufferFeature = useCallback(
    (features: ProjectFeature[]) => {
      if (!bufferSize) {
        return;
      }

      const bufferResults = features.map((f) =>
        bufferSingleFeature(f, bufferSize, pencilEnds),
      );
      const bufferedFeatures = bufferResults.filter(isDefined);
      if (bufferResults.length !== bufferedFeatures.length)
        warning("Some features could not be buffered.");

      updateFeatures({ add: bufferedFeatures });
      setCurrentSelectionArray(resetListIfNotAlreadyEmpty);
      setModalTypeOpen(undefined);
    },
    [
      bufferSize,
      pencilEnds,
      setCurrentSelectionArray,
      setModalTypeOpen,
      updateFeatures,
      warning,
    ],
  );

  return (
    <FullScreenModal>
      <ModalFrame title={"Buffer feature"}>
        <Column style={{ gap: spaceLarge }}>
          <p>
            Create a new polygon around this feature that is the given distance
            away from it.
          </p>
          <p>
            For a smaller polygon (negative buffer), type a negative number.
          </p>

          <InputDimensioned
            unit={unit}
            units={Distance.units}
            onChange={(value) => {
              const cann = Distance.convert(value, { from: unit });
              setBufferSize(cann);
            }}
            onUnitChange={setUnit}
            placeholder="Buffer size"
          />

          {!Array.isArray(selection) &&
            selection.geometry.type === "LineString" &&
            2 <= selection.geometry.coordinates.length && (
              <Tooltip
                text={
                  "Instead of buffering the whole line segment, buffer the middle segments as usual, and narrow in the buffer at the endpoints."
                }
              >
                <Checkbox
                  label="Narrow in towards endpoints"
                  labelPlacement="after"
                  checked={pencilEnds}
                  onChange={(e) => setPencilEnds(e.target.checked)}
                />
              </Tooltip>
            )}

          <ButtonRow>
            <Button
              text="Cancel"
              buttonType="secondary"
              onClick={() => setModalTypeOpen(undefined)}
            />
            <Button
              text="Buffer"
              buttonType="primary"
              onClick={() =>
                bufferFeature(
                  Array.isArray(selection) ? selection : [selection],
                )
              }
            />
          </ButtonRow>
        </Column>
      </ModalFrame>
    </FullScreenModal>
  );
};

const BufferModal = () => {
  const modalTypeOpen = useRecoilValue(modalTypeOpenAtom);
  if (!modalTypeOpen || modalTypeOpen?.modalType !== BufferModalType)
    return null;

  return <BufferModalInner modal={modalTypeOpen} />;
};

export default BufferModal;
