import * as turf from "@turf/turf";
import { Point, Polygon, Position } from "geojson";
import { selectorFamily } from "recoil";
import { CablesNotLinearError } from "types/cables";
import { v4 as uuidv4 } from "uuid";
import { cableChainColor } from "../components/Cabling/CablingMapController/Render";
import {
  CABLE_CHAIN_POLYGON_PROPERTY_TYPE,
  CABLE_PARTITION_POLYGON_PROPERTY_TYPE,
} from "../constants/cabling";
import { UnionFind } from "../lib/UnionFind";
import {
  CableChainFeature,
  CableFeature,
  CablePartitionFeature,
  ProjectFeature,
  SubstationFeature,
  TurbineFeature,
} from "../types/feature";
import {
  pointsAreEqual,
  projectPointToLine,
  sampleLine,
} from "../utils/geometry";
import groupBy from "../utils/groupBy";
import { isDefined, isPointFeature } from "../utils/predicates";
import { scream, sendInfo, sendWarning } from "../utils/sentry";
import {
  fastMax,
  fastMin,
  listCompare,
  movingWindow,
  partition,
} from "../utils/utils";
import {
  getCablesInBranchSelectorFamily,
  getCablesSelectorFamily,
  getSubstationsInBranchSelectorFamily,
  getSubstationsSelectorFamily,
} from "./cable";
import {
  getTurbinesInBranchSelectorFamily,
  getTurbinesSelectorFamily,
} from "./layout";
import { branchIdSelector } from "./pathParams";
import { projectFeatureMap } from "./projectLayers";

export type CablePartition = CablePartitionFeature["properties"];
export type CableChain = CableChainFeature["properties"];

export const cablePartition = (
  cp: CablePartition,
  geometry: Polygon,
): CablePartitionFeature => {
  return {
    type: "Feature",
    id: cp.id,
    geometry,
    properties: cp,
  };
};

export const cableChain = (
  cp: CableChain,
  geometry: Polygon,
): CableChainFeature => {
  return {
    type: "Feature",
    id: cp.id,
    geometry,
    properties: cp,
  };
};

const bufferFeatures = (
  fs: ProjectFeature<Point>[],
  buffer: number,
): Polygon => {
  let hull =
    fs.length === 1
      ? fs[0]
      : fs.length === 2
        ? turf.lineString([
            fs[0].geometry.coordinates,
            fs[1].geometry.coordinates,
          ])
        : turf.convex(turf.featureCollection<Point>(fs));
  if (!hull) {
    if (fs.length <= 2)
      throw sendWarning("Cannot buffer less than 2 points", { fs });
    // https://github.com/Turfjs/turf/issues/2449
    // Check that all points are colinear (common for regular grid turbines).
    // Then, find the endpoints and use the line string between them as the hull.
    const segment: [Position, Position] = [
      fs[0].geometry.coordinates,
      fs[1].geometry.coordinates,
    ];

    const factors = fs
      .map((p) => p.geometry.coordinates)
      .map((pt) => {
        const { point, factor } = projectPointToLine(pt, segment);
        if (!pointsAreEqual(point, pt))
          throw sendWarning("hull failed, but points aren't colinear", {
            fs,
            pt,
          });
        return factor;
      });
    const min = fastMin(factors);
    const max = fastMax(factors);

    const p = sampleLine(segment, min);
    const q = sampleLine(segment, max);
    hull = turf.lineString([p, q]);
  }

  if (!hull) throw sendWarning("hull is null", { fs, buffer });
  const buffered = turf.buffer(hull, buffer, {
    units: "meters",
  });
  if (!buffered) throw scream("failed to buffer hull", { hull, buffer });
  if (buffered.geometry.type === "MultiPolygon")
    throw sendWarning("Buffered convex hull cannot be a multipolygon", {
      buffered,
      hull,
    });
  return buffered.geometry;
};

export const cableChainsInBranchSelectorFamily = selectorFamily<
  CableChain[],
  { parkId: string; branchId: string; allowEmptySubstation?: boolean }
>({
  key: "cableChainsInBranchSelectorFamily",
  get:
    ({ parkId, branchId, allowEmptySubstation }) =>
    ({ get }) => {
      const cables = get(
        getCablesInBranchSelectorFamily({ parkId, branchId }),
      ).filter((c) => !c.properties.redundancy);
      const turbines = get(
        getTurbinesInBranchSelectorFamily({ parkId, branchId }),
      );
      const substations = get(
        getSubstationsInBranchSelectorFamily({ parkId, branchId }),
      );

      const uf = new UnionFind<string>();
      const turbineToSub = new UnionFind<string>();
      for (const c of cables) {
        const { fromId, toId } = c.properties;
        turbineToSub.union(fromId, toId);

        const from = turbines.find((t) => t.id === fromId);
        const to = turbines.find((t) => t.id === toId);

        if (from && to) {
          uf.union(fromId, toId);
        } else if (from) {
          uf.add(fromId);
        } else if (to) {
          uf.add(toId);
        }
      }

      for (const { id } of substations) turbineToSub.makeRoot(id);

      let newChains: CableChain[] = [];
      for (const [cann, group] of uf.groups()) {
        const substation = turbineToSub.find(cann);
        if (
          allowEmptySubstation !== true &&
          !substations.find((s) => s.id === substation)
        )
          continue;
        const chain: CableChain = {
          type: CABLE_CHAIN_POLYGON_PROPERTY_TYPE,
          parentIds: [parkId],
          substation,
          turbines: group,
          id: uuidv4(),
          color: cableChainColor,
        };
        newChains.push(chain);
      }

      return newChains;
    },
});

export const cableChainsSelectorFamily = selectorFamily<
  CableChain[],
  { parkId: string; allowEmptySubstation?: boolean }
>({
  key: "cableChainsSelectorFamily",
  get:
    ({ parkId, allowEmptySubstation }) =>
    ({ get }) => {
      const branchId = get(branchIdSelector);
      if (!branchId) return [];
      return get(
        cableChainsInBranchSelectorFamily({
          parkId,
          branchId,
          allowEmptySubstation,
        }),
      );
    },
});

export const cableChainsFeaturesSelectorFamily = selectorFamily<
  CableChainFeature[],
  { parkId: string }
>({
  key: "cableChainsFeaturesSelectorFamily",
  get:
    (args) =>
    ({ get }) => {
      const cableChains = get(cableChainsSelectorFamily(args));
      return cableChains
        .filter((cc) => 0 < cc.turbines.length)
        .map((cc) => {
          const parkId = cc.parentIds[0];
          const turbines = get(getTurbinesSelectorFamily({ parkId })).filter(
            (f) => cc.turbines.includes(f.id),
          );
          const g = bufferFeatures(turbines, 250);
          return cableChain(cc, g);
        });
    },
});

/** Get cable chains with set labels so that it's possible to identify them. */
export const labelledCableChainsFeaturesSelectorFamily = selectorFamily<
  CableChainFeature[],
  { parkId: string }
>({
  key: "labelledCableChainsFeaturesSelectorFamily",
  get:
    (args) =>
    ({ get }) => {
      // First we sort the chains by substation id, and then
      // for each substation we sort the chains by the angles
      // of the lines from the substation out to the turbines.
      const featureMap = get(projectFeatureMap);
      const cableChains = get(cableChainsSelectorFamily(args));
      const cables = get(getCablesSelectorFamily(args)).filter(
        (c) => !c.properties.redundancy,
      );
      const groups = groupBy(cableChains, (c) => c.substation);
      const subs = Object.keys(groups).sort();

      const orderedChains = subs.flatMap((subId) => {
        const group = groups[subId];
        const angleList = group
          .map<[number[], CableChain] | undefined>((chain) => {
            const orderedCables = orderCablesInChain(chain, cables);
            if (!orderedCables) return;
            const coordinates = cableListCoordinates(
              orderedCables,
              featureMap,
              subId,
            );
            const angles = movingWindow(coordinates).map(([p, q]) => {
              return Math.atan2(q[1] - p[1], q[0] - p[0]);
            });

            return [angles, chain];
          })
          .filter(isDefined);

        const cmp = listCompare((a: number, b: number) => a - b);
        angleList.sort(([a], [b]) => cmp(a, b));

        return angleList.map(([, chain], i) => ({
          ...chain,
          label: (i + 1).toString(),
        }));
      });

      return orderedChains
        .filter((cc) => 0 < cc.turbines.length)
        .map((cc) => {
          const parkId = cc.parentIds[0];
          const turbines = get(getTurbinesSelectorFamily({ parkId })).filter(
            (f) => cc.turbines.includes(f.id),
          );
          const g = bufferFeatures(turbines, 250);
          return cableChain(cc, g);
        });
    },
});

export const cablePartitionsSelectorFamily = selectorFamily<
  CablePartition[],
  { parkId: string }
>({
  key: "cablePartitionsSelectorFamily",
  get:
    ({ parkId }) =>
    ({ get }) => {
      const cables = get(getCablesSelectorFamily({ parkId }));
      const substations = get(getSubstationsSelectorFamily({ parkId }));

      const uf = new UnionFind<string>();
      for (const c of cables) {
        const { fromId, toId } = c.properties;
        uf.union(fromId, toId);
      }
      for (const { id } of substations) {
        uf.add(id);
        uf.makeRoot(id);
      }

      let partitions: CablePartition[] = [];
      for (const [sub, group] of uf.groups()) {
        if (!substations.find((f) => f.id === sub)) continue;
        const turbines = group.filter((f) => f !== sub);
        const partition: CablePartition = {
          type: CABLE_PARTITION_POLYGON_PROPERTY_TYPE,
          parentIds: [parkId],
          substation: sub,
          turbines: turbines,
          id: uuidv4(),
          color: cableChainColor,
        };
        partitions.push(partition);
      }

      return partitions;
    },
});

export const cablePartitionFeaturesSelectorFamily = selectorFamily<
  CablePartitionFeature[],
  { parkId: string }
>({
  key: "cablePartitionsFeatureSelector",
  get:
    (args) =>
    ({ get }) => {
      const cablePartitions = get(cablePartitionsSelectorFamily(args));
      return cablePartitions.map((cp) => {
        const parkId = cp.parentIds?.[0] ?? "";
        const turbines = get(getTurbinesSelectorFamily({ parkId })).filter(
          (t) => cp.turbines.includes(t.id),
        );
        const substations = get(getSubstationsSelectorFamily({ parkId }));
        const substation = substations.find((s) => s.id === cp.substation)!;
        const features: (SubstationFeature | TurbineFeature)[] = [
          ...turbines,
          substation,
        ];
        const g = bufferFeatures(features, 400);
        return cablePartition(cp, g);
      });
    },
});

/**
 * Figure out which order the cables are in the given cable chain.
 * The first cable goes out from the substation, and so on.
 */
export const orderCablesInChain = (
  chain: CableChain,
  allCables: CableFeature[],
): CableFeature[] | undefined => {
  const endpointsInChain = new Set([chain.substation, ...chain.turbines]);

  let cables = allCables.filter(
    (c) =>
      endpointsInChain.has(c.properties.toId) &&
      endpointsInChain.has(c.properties.fromId),
  );
  const _cables = cables;

  // Find one cable at a time; there should be exactly one cable in `cables` that has
  // either endpoint in the `set`.  The first one is the cable going to the substation,
  // the second one (once the first turbine is in `set`), is the second, and so on.
  const set = new Set([chain.substation]);
  let ret: CableFeature[] = [];

  for (let i = 0; i < chain.turbines.length; i++) {
    const [frontier, rest] = partition(
      cables,
      ({ properties: { fromId, toId } }) => set.has(fromId) || set.has(toId),
    );
    if (frontier.length !== 1) {
      sendInfo(new CablesNotLinearError(), {
        _cables,
        cables,
        frontier,
        rest,
      });
      return;
    }
    const cable = frontier[0];
    if (set.has(cable.properties.fromId)) set.add(cable.properties.toId);
    else if (set.has(cable.properties.toId)) set.add(cable.properties.fromId);
    ret.push(cable);
    cables = rest;
  }

  return ret;
};

export const cableListCoordinates = (
  cables: CableFeature[],
  featureMap: Map<string, ProjectFeature>,
  subId: string,
): Position[] => {
  const sub = featureMap.get(subId);
  if (!sub || !isPointFeature(sub)) throw scream("Illegal sub", { sub, subId });
  const ret: Position[] = [sub.geometry.coordinates];
  let lastId = subId;
  for (const cable of cables) {
    const { fromId, toId } = cable.properties;
    if (fromId === lastId) {
      const to = featureMap.get(toId);
      if (!to || !isPointFeature(to))
        throw scream("Illegal feature", { to, toId, cable });
      ret.push(to.geometry.coordinates);
      lastId = toId;
    } else if (toId === lastId) {
      const from = featureMap.get(fromId);
      if (!from || !isPointFeature(from))
        throw scream("Illegal feature", { from, toId, cable });
      ret.push(from.geometry.coordinates);
      lastId = fromId;
    } else {
      throw scream("Cables was not ordered", { cable, cables, lastId });
    }
  }
  return ret;
};
