import styled from "styled-components";
import { colors } from "../../../styles/colors";
import { spacing6 } from "styles/space";
import { typography } from "styles/typography";

export const ITEM_WIDTH = "25rem";

export const ParkComparisonViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.6rem 2.4rem;
  gap: 1rem;
  flex-grow: 0;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: white;
  height: 100%;

  h4 {
    margin: 0;
  }
`;

export const DropZone = styled.div`
  flex-grow: 1;
  height: 100%;
`;

export const BranchNameWrapper = styled.div`
  padding: 0.3rem 0.5rem;
  border-radius: 8px;
  background-color: ${colors.purple};
  color: ${colors.lightText};
  font-weight: bold;
  position: absolute;
  left: 0.8rem;
  bottom: 0.8rem;
  max-width: calc(${ITEM_WIDTH} - 1.6rem);
  box-sizing: border-box;
  z-index: 1;
`;

export const BranchName = styled.div`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ParksWrapper = styled.div`
  display: flex;
  gap: ${spacing6};
  width: 100%;
  overflow-x: auto;
  background-color: white;
`;

export const AttributeHeader = styled.div`
  height: 5rem;
  display: flex;
  align-items: flex-end;
  padding-bottom: 0.8rem;
  box-sizing: border-box;
  overflow: hidden;
`;

export const AttributeValue = styled.span<{
  textRight?: boolean;
  overflowVisible?: boolean;
  warning?: boolean;
}>`
  ${typography.sub2}
  padding: 0.8rem;
  min-height: 36px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  overflow: ${({ overflowVisible }) =>
    overflowVisible ? "visible" : "hidden"};

  ${({ warning }) =>
    warning &&
    `
      &&&&&& { background-color: ${colors.surfaceWarning}; }
    `}

  ${({ textRight }) =>
    textRight &&
    `
    justify-content: flex-end;
    overflow-wrap: anywhere;
  `}

  > * {
    margin: 0;
  }
`;

export const ColumnTemplate = styled.div<{ showRightShadow?: boolean }>`
  display: grid;
  grid-template-rows: 11.5rem 18rem auto;
  width: ${ITEM_WIDTH};
  flex-grow: 0;
  flex-shrink: 0;
  height: fit-content;

  ${AttributeValue}:nth-of-type(2n + 1) {
    background-color: ${colors.focusBackground};
  }

  &.no-scroll {
    overflow-x: hidden !important;
  }

  ${({ showRightShadow }) =>
    showRightShadow &&
    `
    box-shadow: 0 0 12px 0 rgba(51, 51, 51, 0.08);
    clip-path: inset(0px -15px 0px 0px);
  `}
`;
