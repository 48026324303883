import { atom, atomFamily } from "recoil";

export const expandedState = atom({
  key: "menuExpandedState",
  default: true,
});

export const selectedMenuItemState = atomFamily<
  string | undefined,
  { menuId: string; projectId: string }
>({
  key: "selectedMenuItemState",
  default: undefined,
});

export const unsavedSettingsState = atom({
  key: "unsavedSettingsState",
  default: false,
});
