import React, { useState } from "react";

const SourceLogo = ({
  url,
  style,
}: {
  url: string | undefined;
  style?: React.CSSProperties;
}) => {
  const faviconUrl = url
    ? new URL(url).origin.concat("/favicon.ico")
    : undefined;
  const [logoError, setLogoError] = useState(!Boolean(faviconUrl));

  return (
    <>
      {faviconUrl && !logoError && (
        <img
          alt="Source logo"
          src={faviconUrl}
          onLoad={({ currentTarget }) => {
            currentTarget.style.display = "unset";
          }}
          style={{
            display: "none",
            ...style,
          }}
          onError={() => setLogoError(true)}
        />
      )}
    </>
  );
};

export default SourceLogo;
