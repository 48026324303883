import mapboxgl from "mapbox-gl";
import { ProjectFeature } from "../../types/feature";
import { colors } from "../../styles/colors";
import { FeatureVersion } from "../ProjectElements/service";

export const getFeatureChangesBetweenVersions = (
  currentVersion: number,
  hoveredVersion: number,
  featureHistory: FeatureVersion[],
): ProjectFeature[] => {
  const isHoveringNewerVersion = currentVersion < hoveredVersion;
  const isHoveringOlderVersion = currentVersion > hoveredVersion;

  let rowsToProcess: FeatureVersion[] = [];
  if (isHoveringNewerVersion) {
    rowsToProcess = featureHistory.filter(
      (fh) => fh.version > currentVersion && fh.version <= hoveredVersion,
    );
  } else if (isHoveringOlderVersion) {
    rowsToProcess = featureHistory.filter((fh) => fh.version <= hoveredVersion);
  }

  const latestVersions = rowsToProcess
    // Get the latest version of all items
    //Logic is written for ascending order but rowsToProcess is descending, so reverse order before processing
    .reverse()
    .reduce<Record<string, { version: number; feature: ProjectFeature }>>(
      (acc, curr) => {
        for (const feature of curr.features) {
          acc[feature.id] = acc[feature.id] ?? {
            feature: feature,
            version: curr.version,
          };

          if (curr.archivedFeatures.includes(feature.id)) {
            delete acc[feature.id];
          } else if (curr.version > acc[feature.id].version) {
            acc[feature.id] = {
              feature: feature,
              version: curr.version,
            };
          }
        }

        return acc;
      },
      {},
    );

  return Object.values(latestVersions).map((f) => f.feature);
};

export const HOVERED_FEATURE_SOURCE_ID_POLYGON =
  "temp-feature-source-id-polygon";
export const HOVERED_FEATURE_SOURCE_ID_POINT = "temp-feature-source-id-point";
export const HOVERED_FEATURE_SOURCE_ID_LINE = "temp-feature-source-id-line";
export const HOVERED_FEATURE_LAYER_ID_POLYGON = "temp-feature-layer-id-polygon";
export const HOVERED_FEATURE_LAYER_ID_POINT = "temp-feature-layer-id-point";
export const HOVERED_FEATURE_LAYER_ID_LINE = "temp-feature-layer-id-line";

export const paintTemporaryParkFeature: mapboxgl.FillPaint = {
  "fill-color": colors.park,
  "fill-opacity": 0.3,
};

export const paintTemporaryPointFeature: mapboxgl.CirclePaint = {
  "circle-color": colors.park,
  "circle-opacity": 0.3,
  "circle-radius": 5,
};

export const paintTemporaryLineFeature: mapboxgl.LinePaint = {
  "line-color": colors.park,
  "line-opacity": 0.3,
  "line-width": 5,
};
