import { Label } from "components/General/Form";
import styled from "styled-components";
import { colors } from "styles/colors";
import { spaceSmall } from "styles/space";
import SingleTurbine from "@icons/24/SideBarIcons/SingleTurbine.svg?react";
import { typography } from "styles/typography";
import Button from "components/General/Button";
import { modalTypeOpenAtom } from "state/modal";
import UploadIcon from "@icons/24/Upload.svg?react";
import { useSetRecoilState } from "recoil";
import {
  UploadFileType,
  UploadModalType,
} from "components/UploadModal/UploadModal";
import { ProjectConfigModalTypeV2 } from "state/configuration";
import { WIND_MENU_ID } from "components/SettingsV2/ProjectConfiguration/Data/useWindConfiguration";
import { UploadedWindData } from "state/windStatistics";
import { useMemo } from "react";
import { CustomWindFileType, WindDataSource } from "services/metoceanService";
import {
  CustomWindSource,
  MultiCustomWindSource,
  MultipleSourceWindConfiguration,
  WindSourceConfiguration,
  _CustomWindSourceConfiguration,
  _MultipleSourceWindConfiguration,
  _WindSourceConfiguration,
  isMultipleSourceWindConfiguration,
} from "services/windSourceConfigurationService";
import UploadCheckbox from "components/General/UploadCheckbox";
import { SkeletonText } from "components/Loading/Skeleton";
import { PreviewWindSource } from "./PreviewWindSource";
import Radio, { RadioGroup } from "components/General/Radio";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import { Row } from "components/General/Layout";

const NoResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: ${spaceSmall};
  padding: 2.4rem;
  border-radius: 0.4rem;
  background-color: ${colors.blue50};
  color: ${colors.textPrimary};
`;

const ResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

const CheckboxWrapper = styled.div`
  max-height: 22rem;
  overflow-y: auto;
`;

const RoundIconWrapper = styled.div`
  padding: 1.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TurbineIconWrapper = styled(RoundIconWrapper)`
  background-color: ${colors.surfaceBrand};
  svg {
    width: 1.6rem;
    height: 1.6rem;
    path {
      fill: ${colors.iconNegative};
    }
  }
`;

const UploadRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
  width: 100%;
`;

const MethodWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding-top: 1.6rem;
`;

const RadioTitle = styled.div`
  ${typography.body}
`;

const LeftContentWrapper = styled.div`
  width: 35rem;
  padding: 0 1.6rem 0 0;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: -2.4rem;
    right: 0;
    width: 1px;
    height: calc(100% + 2.4rem);
    background-color: ${colors.borderDefault};
  }
`;

const RightContentWrapper = styled.div`
  flex-grow: 1;
`;

const Subtitle = styled.div`
  ${typography.sub1}
`;

export const MultipleTimeSeries = ({
  windDataSets,
  disableActions,
  setLocalWindConfig,
  localWindConfig,
}: {
  windDataSets: UploadedWindData | undefined;
  disableActions: boolean;
  setLocalWindConfig: (w: MultipleSourceWindConfiguration) => void;
  localWindConfig: WindSourceConfiguration;
}) => {
  const uploadedTimeseries = useMemo<UploadedWindData>(() => {
    return [
      ...(windDataSets
        ? windDataSets.filter(
            (d) => !d.type || d.type === CustomWindFileType.TIMESERIES,
          )
        : []),
    ];
  }, [windDataSets]);
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const isFiveTimeSeriesSelected = useMemo(() => {
    return isMultipleSourceWindConfiguration(localWindConfig)
      ? localWindConfig.source.data.length >= 5
      : false;
  }, [localWindConfig]);
  return (
    <ContentWrapper>
      <LeftContentWrapper>
        {windDataSets === undefined ? (
          <SkeletonText text={"Loading timeseries..."} />
        ) : uploadedTimeseries.length === 0 ? (
          <NoResultsWrapper>
            <TurbineIconWrapper>
              <SingleTurbine />
            </TurbineIconWrapper>
            <p>
              <strong>No timeseries to show</strong>
            </p>
            <p>
              Upload timeseries to create a multiple timeseries configuration
            </p>
            <Button
              style={{ padding: "0 2rem" }}
              disabled={disableActions}
              size={"small"}
              text={"Upload"}
              icon={<UploadIcon />}
              onClick={() => {
                setModalTypeOpen({
                  modalType: UploadModalType,
                  metadata: {
                    preSelectedFileType: UploadFileType.WIND_DATA,
                  },
                  backTo: {
                    modalType: ProjectConfigModalTypeV2,
                    metadata: {
                      selectedMenuId: WIND_MENU_ID,
                    },
                  },
                });
              }}
            />
          </NoResultsWrapper>
        ) : (
          <>
            <ResultsWrapper>
              <UploadRow>
                <Subtitle>Uploads</Subtitle>
                <Button
                  style={{ padding: "0 2rem" }}
                  disabled={disableActions}
                  buttonType="secondary"
                  icon={<UploadIcon />}
                  onClick={() => {
                    setModalTypeOpen({
                      modalType: UploadModalType,
                      metadata: {
                        preSelectedFileType: UploadFileType.WIND_DATA,
                      },
                      backTo: {
                        modalType: ProjectConfigModalTypeV2,
                        metadata: {
                          selectedMenuId: WIND_MENU_ID,
                        },
                      },
                    });
                  }}
                />
              </UploadRow>
              <p>Select 2-5 timeseries below</p>
              <CheckboxWrapper>
                <Label>
                  {uploadedTimeseries.map((i) => (
                    <UploadCheckbox
                      key={i.id}
                      onChange={(e) => {
                        if (isFiveTimeSeriesSelected && e.target.checked) {
                          return;
                        }
                        const newSource: CustomWindSource = {
                          id: i.id,
                          longitude: i.data?.lon,
                          latitude: i.data?.lat,
                          type: "custom",
                          height: i.data?.height,
                        };

                        let updatedConfig: WindSourceConfiguration =
                          localWindConfig;

                        // Check if the source is an array
                        if (
                          isMultipleSourceWindConfiguration(localWindConfig)
                        ) {
                          // Check if the source is already in the list
                          const sourceExists = localWindConfig.source.data.some(
                            (source: CustomWindSource) =>
                              source.id === newSource.id,
                          );

                          if (sourceExists) {
                            // If the source is in the list, remove it
                            updatedConfig = {
                              ...localWindConfig,
                              source: {
                                type: "custom_multiple",
                                data: localWindConfig.source.data.filter(
                                  (source) => source.id !== newSource.id,
                                ),
                              },
                            };
                          } else {
                            const multiSource: MultiCustomWindSource = {
                              type: "custom_multiple",
                              data: [...localWindConfig.source.data, newSource],
                            };

                            // If the source is not in the list, add it
                            updatedConfig = {
                              ...localWindConfig,
                              source: multiSource,
                            };
                          }
                        }

                        setLocalWindConfig(
                          _MultipleSourceWindConfiguration.parse(updatedConfig),
                        );
                      }}
                      label={i.name}
                      subLabel={i.data?.height}
                      disabled={
                        (isFiveTimeSeriesSelected &&
                          //Disable unckecked items if there are already 5 selected
                          isMultipleSourceWindConfiguration(localWindConfig) &&
                          !localWindConfig.source.data.some(
                            (source) => source.id === i.id,
                          )) ||
                        //Disable items with different heigth than the first selected
                        (isMultipleSourceWindConfiguration(localWindConfig) &&
                          localWindConfig.source.data.length > 0 &&
                          i.data?.height !==
                            localWindConfig.source.data[0]?.height)
                      }
                      checked={
                        isMultipleSourceWindConfiguration(localWindConfig) &&
                        localWindConfig.source.data.some(
                          (source) => source.id === i.id,
                        )
                      }
                    ></UploadCheckbox>
                  ))}
                </Label>
              </CheckboxWrapper>
            </ResultsWrapper>
            <MethodWrapper>
              <Subtitle>Select method</Subtitle>
              <Row>
                <RadioTitle>Horizontal interpolation</RadioTitle>
                <HelpTooltip text="How to choose the timeseries for each turbine position." />
              </Row>
              <RadioGroup
                style={{ paddingBottom: "1rem", flexDirection: "column" }}
              >
                <Radio label="Nearest" checked={true} onChange={() => {}} />
                <Radio
                  label="Linear (Not available yet)"
                  disabled={true}
                  checked={false}
                  onChange={() => {}}
                />
              </RadioGroup>
              <Row>
                <RadioTitle>Reference wind data</RadioTitle>
                <HelpTooltip text="Which datapoint to use as reference for the park windrose etc." />
              </Row>
              <RadioGroup
                style={{
                  paddingBottom: "1rem",
                  flexDirection: "column",
                }}
              >
                <Radio
                  label="Closest to park center"
                  checked={true}
                  onChange={() => {}}
                />
                <Radio
                  label="Manual (Not available yet)"
                  disabled={true}
                  checked={false}
                  onChange={() => {}}
                />
              </RadioGroup>
            </MethodWrapper>
          </>
        )}
      </LeftContentWrapper>
      <RightContentWrapper>
        {isMultipleSourceWindConfiguration(localWindConfig) &&
        localWindConfig.source.data.length > 0 ? (
          <PreviewWindSource
            selectedDataSourceType={WindDataSource.CUSTOM}
            timeSeries={uploadedTimeseries}
          />
        ) : (
          <NoResultsWrapper>
            <TurbineIconWrapper>
              <SingleTurbine />
            </TurbineIconWrapper>
            <p>
              <strong>No timeseries selected</strong>
            </p>
            <p>
              Select from the list on the left or upload time series to show
              previews
            </p>
          </NoResultsWrapper>
        )}
      </RightContentWrapper>
    </ContentWrapper>
  );
};

export default MultipleTimeSeries;
