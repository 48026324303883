import { colors } from "styles/colors";
import { MenuButton } from "components/General/MenuButton";
import { IconREMSize } from "styles/typography";
import UserImageRound from "components/UserImage/UserImageRound";
import { spacing4, spacing6 } from "styles/space";
import { MenuItem } from "components/General/Menu";
import ArrowDownIcon from "@icons/24/ArrowDown.svg?react";
import LogoutIcon from "@icons/14/Logout.svg?react";
import SinglePersonIcon from "@icons/24/SinglePerson.svg?react";
import { UserProfileModalType } from "components/UserProfileModal/UserProfileModal";
import { LOCAL_STORAGE_TOKEN_KEY, loggedInUserSelector } from "state/user";
import { invalidateToken } from "services/invalidateTokenServiceAPI";
import { IconButtonStyler } from "components/Design/TopbarNavigation/components/RightSide/style";
import React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalTypeOpenAtom } from "state/modal";
import { useAuth0 } from "@auth0/auth0-react";

const ProfileDropdown = () => {
  const user = useRecoilValue(loggedInUserSelector);
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const { logout } = useAuth0();

  if (!user) {
    return null;
  }

  return (
    <IconButtonStyler
      backgroundColor={colors.blue800}
      hoverBackgroundColor={colors.grey900}
    >
      <MenuButton
        icon={
          <IconREMSize height={1} width={1} stroke="white">
            <ArrowDownIcon />
          </IconREMSize>
        }
        iconPosition="right"
        buttonText={<UserImageRound size={2.2} user={user} />}
        divStyle={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
        buttonType="primary"
        buttonStyle={{
          alignItems: "center",
          borderRadius: "2.5rem",
          gap: "1rem",
          border: "none",
          padding: `${spacing4} ${spacing6} ${spacing4} ${spacing4}`,
        }}
      >
        <MenuItem
          name="Profile settings"
          icon={<SinglePersonIcon />}
          onClick={() => {
            setModalTypeOpen({
              modalType: UserProfileModalType,
              metadata: { user },
            });
          }}
        />
        <MenuItem
          name="Logout"
          icon={<LogoutIcon />}
          onClick={async () => {
            localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
            await invalidateToken();
            logout({ returnTo: "https://vind.ai" });
          }}
        />
      </MenuButton>
    </IconButtonStyler>
  );
};

export default ProfileDropdown;
