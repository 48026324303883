import React, { useCallback, useMemo } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import Fuse from "fuse.js";
import { Row } from "components/General/Layout";
import { colors } from "styles/colors";
import { useRecoilValueDef } from "utils/recoil";
import { projectIdSelector } from "state/pathParams";
import AddIcon from "@icons/24/Add.svg";
import SearchIcon from "@icons/24/Search.svg";
import { editorAccessProjectSelector } from "state/user";
import { projectBranchesAtomFamily, useCreateBranch } from "state/timeline";
import useTextInput from "hooks/useTextInput";
import { BranchMeta } from "types/api";
import { MenuFrame } from "components/MenuPopup/CloseableMenuPopup";
import { spacing4, spacing6, spacing7, spacing8 } from "styles/space";
import { SearchInput } from "components/General/Input";
import { useHorizontalResize } from "components/ResizeBar/ResizeBarVertical";
import { IconREMSize, typography } from "styles/typography";
import { useToast } from "hooks/useToast";
import Button from "components/General/Button";
import { scream } from "utils/sentry";
import {
  allBranchesFrameLoadingAtom,
  allBranchesFrameOpenedAtom,
} from "../state";
import useNavigateToBranch from "../useNavigateToBranch";
import BranchesTable from "./BranchesTable";

const RoundIconWrapper = styled.div`
  background-color: ${colors.blue900};
  border-radius: 50%;
  padding: ${spacing6};
  box-sizing: border-box;
  transition: background-color 0.2s;
`;

const NoResultsFound = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.blue50,
        textAlign: "center",
        padding: spacing7,
      }}
    >
      <RoundIconWrapper style={{ marginBottom: spacing6 }}>
        <IconREMSize height={1.6} width={1.6} stroke={colors.white}>
          <SearchIcon />
        </IconREMSize>
      </RoundIconWrapper>
      <p style={{ ...typography.sub2 }}>No results</p>
      <p style={{ ...typography.caption }}>
        We couldn't find any branches matching your search.
      </p>
    </div>
  );
};

const AllBranchesFrame = ({ onExit }: { onExit(): void }) => {
  const nodeId = useRecoilValueDef(projectIdSelector);
  const branchMetaObjects = useRecoilValue(
    projectBranchesAtomFamily({ nodeId }),
  );
  const elementRef = React.useRef<HTMLDivElement>(null);
  useHorizontalResize(elementRef, "--all-branches-frame-width");
  const { create: createBranch } = useCreateBranch();
  const navigateToBranch = useNavigateToBranch();
  const { error: showError } = useToast();
  const editorAccessProject = useRecoilValue(editorAccessProjectSelector);
  const [isLoading, setIsLoading] = useRecoilState(allBranchesFrameLoadingAtom);
  const [searchValue, onSearchValueChange, setSearchValue] = useTextInput("");
  const setBranchFrameVisible = useSetRecoilState(allBranchesFrameOpenedAtom);
  const fuse = useMemo(
    () =>
      new Fuse(branchMetaObjects, {
        keys: ["title"],
        includeScore: true,
        threshold: 0.3,
      }),
    [branchMetaObjects],
  );

  const branchesSearchResult = useMemo<BranchMeta[]>(() => {
    return searchValue.length > 0
      ? fuse.search(searchValue).map((result) => result.item)
      : branchMetaObjects;
  }, [fuse, searchValue, branchMetaObjects]);

  const onCreateBranch = useCallback(async () => {
    setIsLoading(true);
    try {
      const { meta } = await createBranch(nodeId, {
        title: `Branch ${branchMetaObjects.length + 1}`,
      });
      navigateToBranch(meta.id, true);
      setBranchFrameVisible(false);
    } catch (err) {
      if (err instanceof Error) {
        scream(err, {
          nodeId,
        });
      } else {
        scream("Failed to create branch", {
          nodeId,
          err,
        });
      }
      showError("Failed to create branch, please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [
    setBranchFrameVisible,
    branchMetaObjects.length,
    createBranch,
    navigateToBranch,
    nodeId,
    showError,
    setIsLoading,
  ]);

  return (
    <MenuFrame
      ref={elementRef}
      title="All branches"
      onExit={onExit}
      isLoading={isLoading}
      style={{
        position: "fixed",
        top: `calc(calc(var(--top-bar-menu-height) + var(--branch-tab-bar-height)) + ${spacing4})`,
        left: spacing4,
        overflow: "hidden",
        maxHeight: "80vh",
        minWidth: "30rem",
        maxWidth: "60rem",
        width: "var(--all-branches-frame-width, 30rem)",
        resize: "horizontal",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: `0 ${spacing8}`,
          gap: spacing7,
          overflow: "hidden",
          position: "relative",
        }}
      >
        <SearchInput
          style={{ width: "100%" }}
          wrapperDivStyle={{ marginBottom: "" }}
          placeholder="Search for branch"
          value={searchValue}
          onChange={onSearchValueChange}
          onClear={() => {
            setSearchValue("");
          }}
        />
        {searchValue !== "" && branchesSearchResult.length === 0 ? (
          <NoResultsFound />
        ) : (
          <BranchesTable
            enableSorting={searchValue === ""}
            branches={branchesSearchResult}
          />
        )}

        {editorAccessProject && (
          <Row style={{ justifyContent: "flex-end", gap: spacing7 }}>
            <Button
              text="New branch"
              buttonType="primary"
              onClick={onCreateBranch}
              icon={<AddIcon />}
            />
          </Row>
        )}
      </div>
    </MenuFrame>
  );
};

export default AllBranchesFrame;
