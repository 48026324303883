import { useRecoilValue, useSetRecoilState } from "recoil";
import { archivedProjectVersionsAtomFamily } from "../state";
import { ArchivedProjectVersion } from "../types";
import styled from "styled-components";
import { Column, Row } from "components/General/Layout";
import { typography } from "styles/typography";
import { colors } from "styles/colors";
import { dateToDateTime } from "utils/utils";
import UserInfo from "components/Comments/MapModal/UserInfo";
import { Menu, MenuItem } from "components/General/Menu";
import Trashcan from "@icons/24/Bin.svg?react";
import { useRef, useState } from "react";
import { Anchor } from "components/General/Anchor";
import MenuVertical from "@icons/24/MenuVertical.svg?react";
import DownloadIcon from "@icons/24/Download.svg?react";
import LoadingCircle from "@icons/24/Loading-Circle.svg?react";
import AlertCircle from "@icons/24/AlertCircle.svg?react";
import ProjectData from "@icons/24/ProjectData.svg?react";
import { Icon, IconBtn } from "components/General/Icons";
import Button from "components/General/Button";
import { modalTypeOpenAtom } from "state/modal";
import { NewArchivedVersionModalTypeName } from "./NewModal";
import { useDownloadFile } from "./useDownloadFile";
import { useDeleteArchivedVersions } from "../useArchivedVersions";
import { EmptyState } from "components/ValidationWarnings/EmptyState";

export function Inner({
  organisationId,
  projectId,
  branchId,
  parkId,
}: {
  organisationId: string;
  projectId: string;
  branchId: string;
  parkId: string;
}) {
  const versions = useRecoilValue(
    archivedProjectVersionsAtomFamily({ organisationId, projectId, branchId }),
  );
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);

  return (
    <Column style={{ gap: "1.2rem", height: "100%" }}>
      {versions.length === 0 && (
        <EmptyState
          title="No data exports"
          description="Create a new export to download all input and output data for a branch at that specific point in time."
          icon={<ProjectData />}
          style={{ boxSizing: "border-box" }}
        />
      )}
      {versions.map((version) => (
        <VersionItem
          key={version.createdAt + version.author}
          version={version}
        />
      ))}
      <Button
        text="New data export"
        onClick={() =>
          setModalTypeOpen({
            modalType: NewArchivedVersionModalTypeName,
            metadata: { organisationId, projectId, branchId, parkId },
          })
        }
        style={{ marginTop: "auto", marginLeft: "auto" }}
      />
    </Column>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const InnerContainer = styled.div<{
  status: "started" | "completed" | "failed";
}>`
  display: flex;
  flex-direction: column;
  padding: ${(p) =>
    p.status === "completed" ? "1.2rem" : "0.8rem 1.2rem 1.2rem 1.2rem"};
  background-color: ${(p) =>
    p.status === "completed"
      ? colors.surfaceSecondary
      : p.status === "failed"
        ? colors.red50
        : colors.blue50};
  gap: 0.8rem;
  border-radius: ${(p) =>
    p.status === "completed" ? "0.4rem" : "0 0 0.4rem 0.4rem"};
`;

const Name = styled.p`
  ${typography.sub2}
  margin: 0;
`;

const Description = styled.p`
  ${typography.caption}
  margin: 0;
`;

const TextSecondary = styled.p`
  ${typography.caption}
  margin: 0;
  color: ${colors.textSecondary};
`;

function VersionItem({ version }: { version: ArchivedProjectVersion }) {
  const ref = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const { download, inProgress } = useDownloadFile();
  const { _delete } = useDeleteArchivedVersions();

  return (
    <Container>
      {version.status === "started" && (
        <Row
          style={{
            alignItems: "center",
            backgroundColor: colors.blue100,
            borderRadius: "0.4rem 0.4rem 0 0",
            padding: "0.4rem 1.2rem",
          }}
        >
          <Icon
            style={{
              padding: "0.4rem",
              borderRadius: "50%",
              backgroundColor: colors.blue200,
            }}
            size={"1.2rem"}
          >
            <LoadingCircle />
          </Icon>
          <TextSecondary>Export in progress (Est. 1-2min)</TextSecondary>
        </Row>
      )}
      {version.status === "failed" && (
        <Row
          style={{
            alignItems: "center",
            backgroundColor: colors.red100,
            borderRadius: "0.4rem 0.4rem 0 0",
            padding: "0.4rem 1.2rem",
          }}
        >
          <Icon
            style={{
              padding: "0.4rem",
              borderRadius: "50%",
              backgroundColor: colors.red200,
            }}
            size={"1.2rem"}
          >
            <AlertCircle />
          </Icon>
          <TextSecondary>Export failed</TextSecondary>
          <IconBtn
            onClick={() =>
              _delete(
                version.organisationId,
                version.nodeId,
                version.branchId,
                version.id,
              )
            }
            size={"1.4rem"}
            style={{ marginLeft: "auto" }}
            stroke={colors.iconDefault}
          >
            <Trashcan />
          </IconBtn>
        </Row>
      )}
      <InnerContainer status={version.status}>
        <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
          <Name>{version.name}</Name>
          {version.status === "completed" && (
            <IconBtn
              onClick={() => !menuOpen && setMenuOpen(true)}
              size={"1.4rem"}
              //@ts-ignore
              ref={ref}
            >
              <MenuVertical />
            </IconBtn>
          )}
          {menuOpen && (
            <Anchor
              baseRef={ref}
              basePlace="bottomRight"
              floatPlace="topRight"
              anchorStyle={{ zIndex: 1000000 }}
            >
              <Menu
                childRef={menuRef}
                setOpen={setMenuOpen}
                style={{ minWidth: "15rem" }}
              >
                <MenuItem
                  icon={<DownloadIcon />}
                  name="Download"
                  disabled={inProgress}
                  onClick={() => download(version)}
                />
                <MenuItem
                  icon={<Trashcan />}
                  name="Delete"
                  onClick={() => {
                    _delete(
                      version.organisationId,
                      version.nodeId,
                      version.branchId,
                      version.id,
                    );
                  }}
                />
              </Menu>
            </Anchor>
          )}
        </Row>
        <Description>{version.description ?? ""}</Description>
        <Row style={{ alignItems: "center" }}>
          <TextSecondary>
            {dateToDateTime(new Date(version.createdAt))}
          </TextSecondary>
          <UserInfo userId={version.author} size={1.6} />
        </Row>
      </InnerContainer>
    </Container>
  );
}
