import { atom } from "recoil";
import { syncLocalStorage } from "./effects";
import { z } from "zod";

export const slopeAnalysisThresholdsPerParkAtom = atom<{
  [key: string]: [number, number][];
}>({
  key: "slopeAnalysisThresholdsPerParkAtom",
  default: {},
  effects: [
    syncLocalStorage(
      "vind:slope-analysis:thresholds-per-park",
      z.record(z.string(), z.tuple([z.number(), z.number()]).array()),
    ),
  ],
});
