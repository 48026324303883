import drawCableCorridorHelp from "./drawCableCorridorHelp.mp4";
import CloseableHint from "./CloseableHint";

export const drawCableCorridorHelpHintType = "drawCableCorridorHelpHintType";

const DrawCableCorridorHelp = ({
  closeCallback,
  gotItCallback,
}: {
  closeCallback: () => void;
  gotItCallback?: () => void;
}) => {
  return (
    <CloseableHint
      title={"Draw cable corridor"}
      videosrc={drawCableCorridorHelp}
      closeCallback={closeCallback}
      gotItCallback={gotItCallback}
      timeToDissapearMS={10000}
    >
      {`Cable corridors enables inter array cabling to be generated outside the park. Finish by pressing "Enter" or cancel with "Escape"`}
    </CloseableHint>
  );
};

export default DrawCableCorridorHelp;
