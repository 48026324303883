import { colors } from "../styles/colors";
import {
  DEFAULT_CANVAS_LAYER_COLOR,
  DEFAULT_IN_FOCUS_OPACITY,
  DEFAULT_NOT_IN_FOCUS_OPACITY,
  DEFAULT_SELECTED_OPACITY,
  lockedPropertyName,
} from "./canvas";

export const DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE =
  "park-division-exclusion-zone";
export const SUB_AREA_PROPERTY_TYPE = "park-division-inclusion-zone";

export const EXCLUSION_ZONE_COLOR = colors.exclusionZone;
export const EXCLUSION_TURBINES_ZONE_COLOR = colors.orange;
export const SUB_AREA_COLOR = colors.subArea;

// NOTE: see docs `_ExclusionDomain` in feature.ts for these magic numbers.
const exclusion_zone_color_expression = [
  "case",

  ["==", ["get", "domain"], 7],
  colors.exclusionZone,

  ["==", ["get", "domain"], 3],
  colors.exclusionZone2,

  ["==", ["get", "domain"], 5],
  colors.exclusionZone2,

  ["==", ["get", "domain"], 6],
  colors.exclusionZone2,

  ["==", ["get", "domain"], 1],
  colors.exclusionZone3,

  ["==", ["get", "domain"], 2],
  colors.exclusionZone3,

  ["==", ["get", "domain"], 4],
  colors.exclusionZone3,

  EXCLUSION_ZONE_COLOR,
];

export const paintDivisionFeature: mapboxgl.FillPaint = {
  "fill-color": [
    "case",
    ["==", ["get", "type"], SUB_AREA_PROPERTY_TYPE],
    SUB_AREA_COLOR,
    ["==", ["get", "type"], DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE],
    exclusion_zone_color_expression,
    DEFAULT_CANVAS_LAYER_COLOR,
  ],
  "fill-opacity": [
    "case",
    [
      "all",
      ["boolean", ["feature-state", "selected"], false],
      ["!=", ["get", lockedPropertyName], true],
    ],
    0.8,
    ["boolean", ["feature-state", "inFocus"], false],
    0.15,
    0.15,
  ],
};

export const linePaintDivisionFeature: mapboxgl.LinePaint = {
  "line-color": [
    "case",
    ["==", ["get", lockedPropertyName], true],
    colors.lockedFeatureOutline,
    ["==", ["feature-state", "selected"], true],
    "#ffffff",
    ["==", ["get", "type"], SUB_AREA_PROPERTY_TYPE],
    SUB_AREA_COLOR,
    ["==", ["get", "type"], DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE],
    exclusion_zone_color_expression,
    "#000000",
  ],
  "line-width": [
    "case",
    ["==", ["get", lockedPropertyName], true],
    4,
    [
      "all",
      [
        "boolean",
        ["feature-state", "hover"],
        ["feature-state", "selected"],
        false,
      ],
    ],
    4,
    4,
  ],
  "line-opacity": [
    "case",
    [
      "all",
      [
        "boolean",
        ["feature-state", "hover"],
        ["feature-state", "selected"],
        false,
      ],
      ["!=", ["get", lockedPropertyName], true],
    ],
    DEFAULT_SELECTED_OPACITY,
    ["boolean", ["feature-state", "inFocus"], false],
    DEFAULT_IN_FOCUS_OPACITY,
    DEFAULT_NOT_IN_FOCUS_OPACITY,
  ],
};

export const editableParkPolygonDivisionId =
  "editable-park-division-polygon-id";
