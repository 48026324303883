/**
 * This file is generated from spec.yaml.  Do not edit it directly.
 * To regenerate it, run `npm run gen`.
 */
import * as z from "zod";

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Components {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  export namespace Schemas {
    export const SlopeResponseError = z
      .object({
        id: z.string(),
        message: z.string(),
        status: z.literal("failed"),
      })
      .passthrough();
    export const SlopeResponseFinished = z
      .object({
        id: z.string(),
        status: z.literal("finished"),
        url: z.string(),
        usedCustomBathymetry: z.array(z.string()),
      })
      .passthrough();
    export const SlopeResponseStarted = z
      .object({
        id: z.string(),
        status: z.literal("started"),
      })
      .passthrough();
    export const TileResponseError = z
      .object({
        id: z.string(),
        message: z.string(),
        status: z.literal("failed"),
      })
      .passthrough();
    export const TileResponseFinished = z
      .object({
        id: z.string(),
        status: z.literal("finished"),
        url: z.string(),
        usedCustomBathymetry: z.array(z.string()),
      })
      .passthrough();
    export const TileResponseStarted = z
      .object({
        id: z.string(),
        status: z.literal("started"),
      })
      .passthrough();
  }
}
export const components = {
  schemas: {
    SlopeResponseError: Components.Schemas.SlopeResponseError,
    SlopeResponseFinished: Components.Schemas.SlopeResponseFinished,
    SlopeResponseStarted: Components.Schemas.SlopeResponseStarted,
    TileResponseError: Components.Schemas.TileResponseError,
    TileResponseFinished: Components.Schemas.TileResponseFinished,
    TileResponseStarted: Components.Schemas.TileResponseStarted,
  },
};

export const paths = {
  "/api/bathymetry/v2/slope": {
    post: {
      searchParams: z.object({
        output_srs: z.string().optional(),
      }),
      requestBody: {
        "application/json": z
          .object({
            bathymetryIds: z.array(z.string()).optional().default([]),
            /** GeoJSON Polygon */
            polygon: z.object({}).passthrough(),
          })
          .passthrough(),
      },
      responses: {
        200: {
          /** Raster image */
          "image/tif": z.string(),
        },
        404: {
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/bathymetry/v2/slope/{nodeId}": {
    post: {
      searchParams: z.object({
        output_srs: z.string().optional(),
      }),
      pathParams: z.object({
        nodeId: z.string(),
      }),
      requestBody: {
        "application/json": z
          .object({
            bathymetryIds: z.array(z.string()).optional().default([]),
            /** GeoJSON Polygon */
            polygon: z.object({}).passthrough(),
          })
          .passthrough(),
      },
      responses: {
        200: {
          "application/json": z.union([
            Components.Schemas.SlopeResponseFinished,
            Components.Schemas.SlopeResponseStarted,
          ]),
        },
      },
    },
  },
  "/api/bathymetry/v2/tile/{nodeId}/{bbox}": {
    post: {
      pathParams: z.object({
        nodeId: z.string(),
        /** 
    Bounding box of the requested region, in URI encoded format.
    `[1,2,3,4]` becomes '1,2,3,4'.
     */
        bbox: z.array(z.number()),
      }),
      requestBody: {
        "application/json": z
          .object({
            bathymetryIds: z.array(z.string()).optional().default([]),
          })
          .passthrough(),
      },
      responses: {
        200: {
          "application/json": z.union([
            Components.Schemas.TileResponseFinished,
            Components.Schemas.TileResponseStarted,
          ]),
        },
      },
    },
  },
  "/api/bathymetry/v2/tile/{nodeId}/{filename}/{x}/{y}/{z}": {
    get: {
      searchParams: z.object({
        include_land: z.boolean().optional(),
        tilesize: z.number().int().optional(),
      }),
      pathParams: z.object({
        x: z.number().int(),
        y: z.number().int(),
        z: z.number().int(),
        /** This is probably the project id. */
        nodeId: z.string(),
        /** Filename of the uploaded bathymetry. */
        filename: z.string(),
      }),
      responses: {
        200: {
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/bathymetry/v2/tile/{x}/{y}/{z}": {
    get: {
      searchParams: z.object({
        include_land: z.boolean().optional(),
        tilesize: z.number().int().optional(),
        type: z.string().optional(),
      }),
      pathParams: z.object({
        x: z.number().int(),
        y: z.number().int(),
        z: z.number().int(),
      }),
      responses: {
        200: {
          "application/json": z.string(),
        },
      },
    },
  },
};

// Generated using openapi-to-zod v0.1.47
