import { useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { mapRefAtom } from "../../state/map";
import { layerSettingSelectorFamily } from "../../components/LayerList/LayerSettings/state";
import { useTypedPath } from "../../state/pathParams";
import { externalWMTSAnchorId } from "../../components/Mapbox/utils";
import { ExternalDataSourceLinkLayerWithSourceWMTS } from "../../types/layers";
import mapboxgl from "mapbox-gl";
import { getVisibleLayers } from "../../components/LayerList/LayerSettings/utils";
import {
  ErrorBoundarySilent,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "../../components/ErrorBoundaries/ErrorBoundaryLocal";
import { getZoomLevels, layerToSourceId } from "./utils";
import { getWmtsPath, isWMTSLayer } from "../../state/wmts";

const DynamicWMTSLayers = ErrorBoundaryWrapper(
  () => {
    const { projectId } = useTypedPath("projectId");
    const visibleLayers = useRecoilValue(getVisibleLayers({ projectId }));
    const selectedDynamicWMTSLayers = visibleLayers.filter(isWMTSLayer);

    return (
      <>
        {selectedDynamicWMTSLayers.map((layer) => {
          return <DynamicWMTSLayer key={layer.id} layer={layer} />;
        })}
      </>
    );
  },
  ErrorBoundarySilent,
  ScreamOnError,
);

const DynamicWMTSLayer = ErrorBoundaryWrapper(
  ({ layer }: { layer: ExternalDataSourceLinkLayerWithSourceWMTS }) => {
    const map = useRecoilValue(mapRefAtom);
    const { projectId } = useTypedPath("projectId");
    const settings = useRecoilValue(
      layerSettingSelectorFamily({
        projectId,
        layerId: layer.id,
      }),
    );

    const zoomLevels = settings.layerStyle?.zoomLevels;

    const dynamicWMTSLayersId = useMemo(
      () => `dynamic-wmts-layer-${layer.source.name}-${layer.name}`,
      [layer],
    );
    const dynamicWMTSLayersSource = useMemo(
      () => layerToSourceId(layer, "-wmts"),
      [layer],
    );

    const dynamicWMTSLayer: mapboxgl.RasterLayer = useMemo(
      () => ({
        id: dynamicWMTSLayersId,
        type: "raster",
        source: dynamicWMTSLayersSource, // reference the data source
        paint: {},
        ...getZoomLevels(zoomLevels),
      }),
      [dynamicWMTSLayersId, dynamicWMTSLayersSource, zoomLevels],
    );

    useEffect(() => {
      if (!map) return;
      map.addSource(dynamicWMTSLayersSource, {
        type: "raster",
        tiles: [`${getWmtsPath(layer)}/{z}/{y}/{x}`],
        tileSize: 256,
      });
      map.addLayer(
        dynamicWMTSLayer,
        map.getLayer(externalWMTSAnchorId) ? externalWMTSAnchorId : undefined,
      );
      return () => {
        map.removeLayer(dynamicWMTSLayer.id);
        map.removeSource(dynamicWMTSLayersSource);
      };
    }, [
      map,
      dynamicWMTSLayersId,
      layer,
      dynamicWMTSLayer,
      dynamicWMTSLayersSource,
    ]);

    useEffect(() => {
      if (!map) return;

      if (settings.layerStyle?.opacity !== undefined) {
        map.setPaintProperty(
          dynamicWMTSLayer.id,
          "raster-opacity",
          settings.layerStyle.opacity,
        );
      }
    }, [
      map,
      dynamicWMTSLayersId,
      dynamicWMTSLayer,
      layer,
      settings.layerStyle,
    ]);

    return null;
  },
  ErrorBoundarySilent,
  ScreamOnError,
);

export default DynamicWMTSLayers;
