import { ReactNode, useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import mapboxgl, { MapMouseEvent } from "mapbox-gl";
import { mapInteractionSelector, mapRefAtom } from "state/map";
import { Popup } from "components/Mapbox/Popup";
import { StandardBox } from "styles/boxes/Boxes";

const RightClickMenu = ({
  children,
}: {
  children: ({
    mapMouseEvent,
    closeMenu,
  }: {
    mapMouseEvent: MapMouseEvent | undefined;
    closeMenu: () => void;
  }) => ReactNode;
}) => {
  const map = useRecoilValue(mapRefAtom);
  const [mapMouseEvent, setMapMouseEvent] = useState<
    undefined | MapMouseEvent
  >();
  const mapInteraction = useRecoilValue(mapInteractionSelector);

  // If this is undefined, we are not showing a popup.
  const [pos, setPos] = useState<undefined | mapboxgl.LngLatLike>(undefined);

  useEffect(() => {
    if (!map) return;

    const onMouseUp = (e: MapMouseEvent) => {
      if (e.originalEvent.button !== 2) {
        setPos(undefined);
        return;
      }
    };

    const onContextMenu = (e: MapMouseEvent) => {
      if (!mapInteraction.rightClickInMap) {
        setPos(undefined);
        return;
      }

      e.preventDefault();
      e.originalEvent.preventDefault();
      setMapMouseEvent(e);
      setPos(e.lngLat);
    };

    map.on("contextmenu", onContextMenu);
    map.on("mouseup", onMouseUp);

    return () => {
      map.off("contextmenu", onContextMenu);
      map.off("mouseup", onMouseUp);
      setMapMouseEvent(undefined);
      setPos(undefined);
    };
  }, [map, mapInteraction.rightClickInMap]);

  const closeMenu = useCallback(() => {
    setPos(undefined);
  }, [setPos]);

  if (!map || !pos) return null;
  return (
    <Popup map={map} pos={pos} place="topLeft">
      <StandardBox>{children({ mapMouseEvent, closeMenu })}</StandardBox>
    </Popup>
  );
};

export default RightClickMenu;
