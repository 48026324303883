import DropdownButton from "components/General/Dropdown/DropdownButton";
import { DropDownItem } from "components/General/Dropdown/DropdownItems";
import { Label } from "components/General/Form";
import { InputDimensioned } from "components/General/Input";
import { Column, Row } from "components/General/Layout";
import HelpTooltip, {
  ARTICLE_IMPORT_WIND_DATA,
  ARTICLE_WIND_DATASETS,
  ARTICLE_WIND_MEAN_SPEED_CALIBRATION,
  HelpLink,
} from "components/HelpTooltip/HelpTooltip";
import { useMemo } from "react";
import {
  SpatialCalibrationType,
  WindDataSource,
  WindSpeedCalibrationType,
} from "services/metoceanService";
import {
  SingleSourceSpatialCalibrationWindConfiguration,
  _CustomWindSourceConfiguration,
  _SingleSourceSpatialCalibrationWindConfiguration,
  _WindSourceConfiguration,
  isCustomWindSource,
  isMultipleSourceWindConfiguration,
} from "services/windSourceConfigurationService";
import { Text } from "../../SettingsV2/Shared/styles";
import {
  MeanSpeedGridCustom,
  UploadedWindData,
  WRG,
} from "state/windStatistics";
import Button from "components/General/Button";
import UploadIcon from "@icons/24/Upload.svg?react";
import {
  UploadFileType,
  UploadModalType,
} from "components/UploadModal/UploadModal";
import { ProjectConfigModalTypeV2 } from "state/configuration";
import { WIND_MENU_ID } from "components/SettingsV2/ProjectConfiguration/Data/useWindConfiguration";
import Radio, { RadioGroup } from "components/General/Radio";
import { RangeSlider } from "components/General/Slider";
import { useSetRecoilState } from "recoil";
import { modalTypeOpenAtom } from "state/modal";
import { PreviewGrid, PreviewGwa } from "./PreviewWindSource";
import styled from "styled-components";
import { colors } from "styles/colors";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
  width: 100%;
`;

const LeftContentWrapper = styled.div`
  width: 35rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  &::before {
    content: "";
    position: absolute;
    top: -2.4rem;
    right: 0;
    width: 1px;
    height: calc(100% + 2.4rem);
    background-color: ${colors.borderDefault};
  }
`;

const RightContentWrapper = styled.div`
  flex-grow: 1;
`;

const MAX_YEARS = {
  [WindDataSource.ERA5]: 2022,
  [WindDataSource.CERRA]: 2020,
  [WindDataSource.NORA3]: 2022,
  [WindDataSource.BEST]: 2022,
  [WindDataSource.CUSTOM]: 2022,
};

const between = (min: number, max: number) => (n: number) =>
  min <= n && n <= max;

export const SingleTimeSeriesWithCalibration = ({
  dataSetDropdownItems,
  spatialCalibrationDropdownItems,
  localWindConfig,
  disableActions,
  setLocalWindConfig,
  uploadedWindData,
  uploadedWRGGrids,
  uploadedMeanSpeedGrids,
  useSpatialCalibration,
}: {
  dataSetDropdownItems: DropDownItem[];
  spatialCalibrationDropdownItems: DropDownItem[];
  localWindConfig: SingleSourceSpatialCalibrationWindConfiguration;
  disableActions: boolean;
  setLocalWindConfig: (
    w: SingleSourceSpatialCalibrationWindConfiguration,
  ) => void;
  uploadedWindData: UploadedWindData | undefined;
  uploadedWRGGrids: { id: string; wrg: WRG }[] | undefined;
  uploadedMeanSpeedGrids:
    | { id: string; data: MeanSpeedGridCustom | undefined }[]
    | undefined;
  useSpatialCalibration: boolean;
}) => {
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const selectedDataSetName = useMemo(() => {
    return dataSetDropdownItems.find(
      (d) => d.value === localWindConfig.source.id,
    )?.name;
  }, [dataSetDropdownItems, localWindConfig]);

  const selectedUploadedWRGFiles = useMemo(() => {
    if (
      uploadedWRGGrids &&
      localWindConfig.spatial_calibration &&
      localWindConfig.spatial_calibration.type === "custom" &&
      localWindConfig.spatial_calibration.id
    ) {
      const selectedSpatialCalibrationId =
        localWindConfig.spatial_calibration.id;

      return uploadedWRGGrids.filter(
        (u) => u.id === selectedSpatialCalibrationId,
      );
    } else {
      return undefined;
    }
  }, [uploadedWRGGrids, localWindConfig.spatial_calibration]);

  const selectedUploadedMeanSpeedFiles = useMemo(() => {
    if (
      uploadedMeanSpeedGrids &&
      localWindConfig.spatial_calibration &&
      localWindConfig.spatial_calibration.type === "custom" &&
      localWindConfig.spatial_calibration.id
    ) {
      const selectedSpatialCalibrationId =
        localWindConfig.spatial_calibration.id;

      return uploadedMeanSpeedGrids.filter(
        (u) => u.id === selectedSpatialCalibrationId,
      );
    } else {
      return undefined;
    }
  }, [uploadedMeanSpeedGrids, localWindConfig.spatial_calibration]);

  const selectedSpatialCalibrationName = useMemo(() => {
    if (
      localWindConfig.spatial_calibration?.type ===
      SpatialCalibrationType.CUSTOM
    ) {
      return spatialCalibrationDropdownItems.find(
        (d) => d.value === (localWindConfig.spatial_calibration as any)?.["id"],
      )?.name;
    } else {
      return "Global Wind Atlas";
    }
  }, [localWindConfig.spatial_calibration, spatialCalibrationDropdownItems]);

  return (
    <ContentWrapper>
      <LeftContentWrapper>
        <Label htmlFor="not-help-link">
          <Row style={{ alignItems: "center" }}>
            <p>Select dataset</p>
            <HelpLink article={ARTICLE_WIND_DATASETS} />
          </Row>
          <Row
            style={{
              width: "fit-content",
            }}
          >
            <DropdownButton
              items={dataSetDropdownItems}
              style={{
                width: "18rem",
              }}
              disabled={disableActions}
              onSelectItem={(id) => {
                const uploaded = uploadedWindData?.find((d) => d.id === id);

                if (uploaded) {
                  setLocalWindConfig(
                    _SingleSourceSpatialCalibrationWindConfiguration.parse({
                      ...localWindConfig,
                      source: {
                        id,
                        longitude: uploaded.data?.lon,
                        latitude: uploaded.data?.lat,
                      },
                    }),
                  );
                } else {
                  if (id === WindDataSource.BEST) {
                    setLocalWindConfig(
                      _SingleSourceSpatialCalibrationWindConfiguration.parse({
                        ...localWindConfig,
                        calibration: null,
                        source: { id, type: "built_in" },
                      }),
                    );
                  } else {
                    setLocalWindConfig(
                      _SingleSourceSpatialCalibrationWindConfiguration.parse({
                        ...localWindConfig,
                        source: { id, type: "built_in" },
                        time_range: {
                          from_year: localWindConfig.time_range.from_year,
                          to_year: Math.min(
                            MAX_YEARS[id as WindDataSource],
                            localWindConfig.time_range.to_year,
                          ),
                        },
                      }),
                    );
                  }
                }
              }}
              buttonText={selectedDataSetName ?? "Choose dataset"}
              selectedItemValue={
                !isMultipleSourceWindConfiguration(localWindConfig)
                  ? localWindConfig.source.id
                  : ""
              }
            />
            <Button
              style={{ padding: "0 2rem" }}
              disabled={disableActions}
              buttonType="secondary"
              icon={<UploadIcon />}
              text="Upload"
              onClick={() => {
                setModalTypeOpen({
                  modalType: UploadModalType,
                  metadata: {
                    preSelectedFileType: UploadFileType.WIND_DATA,
                  },
                  backTo: {
                    modalType: ProjectConfigModalTypeV2,
                    metadata: {
                      selectedMenuId: WIND_MENU_ID,
                    },
                  },
                });
              }}
            />
            <HelpLink article={ARTICLE_IMPORT_WIND_DATA} />
          </Row>
        </Label>

        <Column style={{ gap: "0.4rem" }}>
          <Row style={{ alignItems: "center" }}>
            <p>Speed calibration</p>
            <HelpTooltip text="Adjust all speeds in the timeseries with this factor." />
            <InputDimensioned
              disabled={disableActions}
              validate={between(-20, 20)}
              step="0.1"
              unit="%"
              type="number"
              value={localWindConfig.calibration?.value ?? 0}
              onChange={(val) => {
                setLocalWindConfig(
                  _SingleSourceSpatialCalibrationWindConfiguration.parse({
                    ...localWindConfig,
                    calibration: {
                      type: WindSpeedCalibrationType.PERCENT,
                      value: val,
                    },
                  }),
                );
              }}
              style={{ width: "6rem" }}
            />
          </Row>
        </Column>

        <Column style={{ gap: "0.4rem" }}>
          <Row style={{ alignItems: "center" }}>
            <Text>Spatial calibration</Text>
            <HelpLink article={ARTICLE_WIND_MEAN_SPEED_CALIBRATION} />
          </Row>
          {useSpatialCalibration && (
            <Row
              style={{
                width: "fit-content",
              }}
            >
              <DropdownButton
                items={spatialCalibrationDropdownItems}
                style={{
                  width: "18rem",
                }}
                disabled={disableActions}
                onSelectItem={(id) => {
                  setLocalWindConfig(
                    _SingleSourceSpatialCalibrationWindConfiguration.parse({
                      ...localWindConfig,
                      spatial_calibration: {
                        type:
                          id !== SpatialCalibrationType.GWA
                            ? SpatialCalibrationType.CUSTOM
                            : SpatialCalibrationType.GWA,
                        id: id,
                        value: "absolute",
                      },
                    }),
                  );
                }}
                buttonText={selectedSpatialCalibrationName ?? ""}
                selectedItemValue={
                  localWindConfig.spatial_calibration?.type ===
                  SpatialCalibrationType.CUSTOM
                    ? localWindConfig.spatial_calibration?.id
                    : SpatialCalibrationType.GWA
                }
              />

              <Button
                style={{ padding: "0 2rem" }}
                disabled={disableActions}
                buttonType="secondary"
                icon={<UploadIcon />}
                text="Upload"
                onClick={() => {
                  setModalTypeOpen({
                    modalType: UploadModalType,
                    metadata: {
                      preSelectedFileType: UploadFileType.WIND_DATA,
                    },
                    backTo: {
                      modalType: ProjectConfigModalTypeV2,
                      metadata: {
                        selectedMenuId: WIND_MENU_ID,
                      },
                    },
                  });
                }}
              />
            </Row>
          )}
          {useSpatialCalibration && (
            <Row>
              <RadioGroup>
                <Radio
                  name="spatial_calibration_absolute"
                  disabled={disableActions}
                  checked={
                    localWindConfig.spatial_calibration?.value === "absolute"
                  }
                  onChange={() => {
                    setLocalWindConfig(
                      _SingleSourceSpatialCalibrationWindConfiguration.parse({
                        ...localWindConfig,
                        spatial_calibration: {
                          ...localWindConfig.spatial_calibration,
                          value: "absolute",
                        },
                      }),
                    );
                  }}
                />
                <p>Absolute</p>
              </RadioGroup>
              <RadioGroup>
                <Radio
                  name="spatial_calibration_relative"
                  disabled={disableActions}
                  checked={
                    localWindConfig.spatial_calibration?.value === "relative"
                  }
                  onChange={() => {
                    setLocalWindConfig(
                      _SingleSourceSpatialCalibrationWindConfiguration.parse({
                        ...localWindConfig,
                        spatial_calibration: {
                          ...localWindConfig.spatial_calibration,
                          value: "relative",
                        },
                      }),
                    );
                  }}
                />
                <p>Relative</p>
              </RadioGroup>
            </Row>
          )}
        </Column>

        {!isMultipleSourceWindConfiguration(localWindConfig) &&
          !isCustomWindSource(localWindConfig.source) && (
            <Label>
              <p>Time range</p>
              <RangeSlider
                disabled={disableActions}
                style={{ width: "30rem" }}
                min={1990}
                max={MAX_YEARS[localWindConfig.source.id]}
                values={[
                  localWindConfig.time_range.from_year,
                  localWindConfig.time_range.to_year,
                ]}
                onChange={(range) =>
                  setLocalWindConfig({
                    ...localWindConfig,
                    time_range: { from_year: range[0], to_year: range[1] },
                  })
                }
                labels
                inside
              />
            </Label>
          )}
      </LeftContentWrapper>
      <RightContentWrapper>
        {localWindConfig.spatial_calibration &&
          localWindConfig.spatial_calibration.type === "custom" && (
            <PreviewGrid
              customWRGFiles={selectedUploadedWRGFiles}
              customMeanSpeedGridFiles={selectedUploadedMeanSpeedFiles}
            />
          )}
        {localWindConfig.spatial_calibration &&
          localWindConfig.spatial_calibration.type === "global_wind_atlas" && (
            <PreviewGwa />
          )}
      </RightContentWrapper>
    </ContentWrapper>
  );
};

export default SingleTimeSeriesWithCalibration;
