/// <reference types="vite-plugin-svgr/client" />
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  useRecoilRefresher_UNSTABLE,
} from "recoil";
import { Mixpanel } from "../../mixpanel";
import AddIcon from "@icons/24/Add.svg?react";
import { modalTypeOpenAtom } from "../../state/modal";
import { useTypedPath } from "../../state/pathParams";
import { dateToDateTime } from "../../utils/utils";
import { useSetVersion } from "../../hooks/useSetVersion";
import UserImageRoundFromId from "../UserImage/UserImageRoundFromId";
import { currentVersionSelector } from "../../state/project";
import { mapRefAtom } from "../../state/map";
import { ProjectFeature } from "../../types/feature";
import {
  isLineStringFeature,
  isPointFeature,
  isPolygonFeature,
} from "../../utils/predicates";
import { bbox } from "../../utils/geometry";
import { SkeletonBlock } from "../Loading/Skeleton";
import { toastMessagesAtom } from "../../state/toast";
import { MenuItem } from "../General/Menu";
import { DotMenu } from "../General/MenuButton";
import { BranchSnapshotModalType } from "../Design/DesignNavigation/TimelineModals";
import {
  projectFeatureSelector,
  projectFeatureVersionsSelector,
} from "../ProjectElements/state";
import {
  ErrorBoundarySilent,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "../ErrorBoundaries/ErrorBoundaryLocal";
import Polygon from "../MapFeatures/Polygon";
import Point from "../MapFeatures/Point";
import LineString from "../MapFeatures/LineString";
import { axisLinesLayerId } from "../GenerateWindTurbines/constants";
import {
  DateText,
  VersionAuthorWrapper,
  VersionRow,
  VersionScrollBody,
} from "./FeatureVersionsPopup.style";
import {
  getFeatureChangesBetweenVersions,
  HOVERED_FEATURE_LAYER_ID_LINE,
  HOVERED_FEATURE_LAYER_ID_POINT,
  HOVERED_FEATURE_LAYER_ID_POLYGON,
  HOVERED_FEATURE_SOURCE_ID_LINE,
  HOVERED_FEATURE_SOURCE_ID_POINT,
  HOVERED_FEATURE_SOURCE_ID_POLYGON,
  paintTemporaryLineFeature,
  paintTemporaryParkFeature,
  paintTemporaryPointFeature,
} from "./utils";
import { openedFeatureIdForVersionHistoryAtom } from "./state";
import { Popup } from "components/Mapbox/Popup";
import { MenuFrame } from "components/MenuPopup/CloseableMenuPopup";

export const FeatureVersionsModalType = "FeatureVersionsModal" as const;

const Author = ({
  author,
  timestamp,
}: {
  author?: string;
  timestamp: number;
}) => {
  // const user = useGetUserFromId()(author);
  return (
    <VersionAuthorWrapper>
      {author && <UserImageRoundFromId size={1.6} userId={author} />}
      <DateText>{dateToDateTime(new Date(timestamp))}</DateText>
      {/*{user && (
        <BlackText style={{ textTransform: "capitalize" }}>
          {user.nickname}
        </BlackText>
      )}*/}
    </VersionAuthorWrapper>
  );
};

const initialStateRenderedFeatures = {
  polygons: [],
  points: [],
  lines: [],
};

const FeatureVersionsPopupInner = ({ featureId }: { featureId: string }) => {
  const { projectId, branchId } = useTypedPath("projectId", "branchId");
  const featureHistory = useRecoilValue(
    projectFeatureVersionsSelector({
      projectId,
      branchId,
      featureId,
    }),
  );
  const resetFeatureHistory = useRecoilRefresher_UNSTABLE(
    projectFeatureVersionsSelector({
      projectId,
      branchId,
      featureId,
    }),
  );
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const setToastMessages = useSetRecoilState(toastMessagesAtom);
  const map = useRecoilValue(mapRefAtom);
  const setVersion = useSetVersion();
  const currentVersion = useRecoilValue(currentVersionSelector);
  const [{ polygons, points, lines }, setRenderedFeatures] = useState<{
    polygons: ProjectFeature[];
    points: ProjectFeature[];
    lines: ProjectFeature[];
  }>(initialStateRenderedFeatures);

  const onHover = useCallback(
    (hoveredVersion: number) => {
      if (!map) {
        return;
      }
      const fixedCurrentVersion =
        currentVersion ?? featureHistory[featureHistory.length - 1].version;

      const featureChanges = getFeatureChangesBetweenVersions(
        fixedCurrentVersion,
        hoveredVersion,
        featureHistory,
      );
      setRenderedFeatures({
        polygons: featureChanges.filter(isPolygonFeature),
        lines: featureChanges.filter(isLineStringFeature),
        points: featureChanges.filter(isPointFeature),
      });
    },
    [currentVersion, featureHistory, map],
  );

  const onMouseLeave = useCallback(() => {
    setRenderedFeatures(initialStateRenderedFeatures);
  }, []);

  const onOpenCreateSnapshotFromVersion = useCallback(
    (versionInSeconds: number) => {
      setModalTypeOpen({
        modalType: BranchSnapshotModalType,
        metadata: {
          version: versionInSeconds,
          hooks: {
            afterHook: () => {
              setToastMessages((tm) => [
                ...tm,
                {
                  text: "Timeline event created from version",
                  timeout: 5000,
                  type: "success",
                },
              ]);
            },
          },
        },
      });
    },
    [setModalTypeOpen, setToastMessages],
  );

  useEffect(() => {
    return () => {
      setVersion(undefined);
      resetFeatureHistory();
    };
  }, [setVersion, resetFeatureHistory]);

  return (
    <>
      <VersionScrollBody style={{ padding: 0 }}>
        {featureHistory.map((history) => {
          const versionInSeconds = history.version; // Math.ceil(history.createdAt / 1000);
          const isCurrentVersion = versionInSeconds === currentVersion;

          return (
            <VersionRow
              key={history.createdAt}
              isSelected={isCurrentVersion}
              onClick={() => {
                setVersion(versionInSeconds);
                setRenderedFeatures(initialStateRenderedFeatures);
                Mixpanel.track("Element history - Set version", {
                  versionInSeconds,
                });
              }}
              onMouseEnter={() => {
                onHover(history.version);
              }}
              onMouseLeave={onMouseLeave}
            >
              <Author author={history.author} timestamp={history.createdAt} />
              <DotMenu>
                <MenuItem
                  name={"Add timeline event from autosave"}
                  onClick={() => {
                    onOpenCreateSnapshotFromVersion(versionInSeconds);
                    Mixpanel.track(
                      "Element history - Open add timeline event modal",
                      {
                        versionInSeconds,
                      },
                    );
                  }}
                  icon={<AddIcon />}
                  direction="right"
                />
              </DotMenu>
            </VersionRow>
          );
        })}
      </VersionScrollBody>
      <Polygon
        features={polygons}
        sourceId={HOVERED_FEATURE_SOURCE_ID_POLYGON}
        layerId={HOVERED_FEATURE_LAYER_ID_POLYGON}
        map={map!}
        paint={paintTemporaryParkFeature}
        beforeLayer={axisLinesLayerId}
      />
      <Point
        features={points}
        sourceId={HOVERED_FEATURE_SOURCE_ID_POINT}
        layerId={HOVERED_FEATURE_LAYER_ID_POINT}
        map={map!}
        paint={paintTemporaryPointFeature}
        beforeLayer={axisLinesLayerId}
      />
      <LineString
        features={lines}
        sourceId={HOVERED_FEATURE_SOURCE_ID_LINE}
        layerId={HOVERED_FEATURE_LAYER_ID_LINE}
        map={map!}
        beforeLayer={axisLinesLayerId}
        paint={paintTemporaryLineFeature}
      />
    </>
  );
};

const FeatureVersionsPopup = ({
  openedFeatureId,
  setOpenedFeatureId,
}: {
  openedFeatureId: string;
  setOpenedFeatureId(id: string | undefined): void;
}) => {
  const map = useRecoilValue(mapRefAtom);
  const feature = useRecoilValue(
    projectFeatureSelector({ featureId: openedFeatureId }),
  );

  const pos = useMemo(() => {
    if (!feature) return { lng: 0, lat: 0 };
    const [, , lng, lat] = bbox(feature);
    return { lng, lat };
  }, [feature]);

  const onClose = useCallback(() => {
    setOpenedFeatureId(undefined);
  }, [setOpenedFeatureId]);

  useEffect(() => {
    Mixpanel.track("Element history popup open", {});
  }, []);

  if (!map) return null;

  return (
    <Popup map={map} pos={pos} place="topLeft">
      <MenuFrame
        title="Element history"
        onExit={onClose}
        style={{ maxHeight: "50vh" }}
      >
        <React.Suspense
          fallback={
            <SkeletonBlock
              style={{
                height: "20rem",
                margin: "1rem",
                boxSizing: "border-box",
                width: "calc(100% - 2rem)",
              }}
            />
          }
        >
          <FeatureVersionsPopupInner featureId={openedFeatureId} />
        </React.Suspense>
      </MenuFrame>
    </Popup>
  );
};

const FeatureVersionsPopupWrapper = () => {
  const [openedFeatureId, setOpenedFeatureId] = useRecoilState(
    openedFeatureIdForVersionHistoryAtom,
  );

  if (!openedFeatureId) {
    return null;
  }

  return (
    <FeatureVersionsPopup
      openedFeatureId={openedFeatureId}
      setOpenedFeatureId={setOpenedFeatureId}
    />
  );
};

export default ErrorBoundaryWrapper(
  FeatureVersionsPopupWrapper,
  ErrorBoundarySilent,
  ScreamOnError,
);
